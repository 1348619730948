import { Fragment } from 'react';
import { PaneType, SectionDefs } from 'src/views/definitionBuilders/types';
import { DateTime } from 'luxon';
import { Link } from 'react-router-dom';
import styles from './MaintainQuote.module.scss';
import TimezoneFormat from 'src/views/components/DateTimeFormat/TimezoneFormat';

type ActivityLogTransaction = Operations.Domain.Queries.GetActivityLog.ActivityLogTransaction;
type ActivityLogDetails = Operations.Domain.Queries.GetActivityLog.ActivityLogDetails;

export default function getActivityLogPanelDef(
  activityLogs: ActivityLogTransaction[],
  quoteId?: string
): SectionDefs {
  return {
    title: 'Activity',
    hidden: !quoteId,
    explicitData: activityLogs,
    panels: [
      {
        panes: [
          {
            paneType: PaneType.repeatingPane,
            itemPanes: [
              {
                paneType: PaneType.customPane,
                dataAddr: 'transactionId',
                render: api => {
                  const log = api.data.parentValue as ActivityLogTransaction;
                  const createdOnDateTime = DateTime.fromISO(log.createdOn);
                  const formattedCreatedOn = createdOnDateTime.toLocaleString(
                    DateTime.DATETIME_MED_WITH_SECONDS
                  );
                  return (
                    <div className={styles['activity-log-for-quote-pane']}>
                      <div className="created-by">{log.createdBy}</div>
                      <div className="created-on">
                        {formattedCreatedOn}{' '}
                        <TimezoneFormat value={createdOnDateTime} blankForDefaultZone />
                      </div>
                      <ul className="log-items list-unstyled">
                        {log.items.map((item: ActivityLogDetails) => {
                          return (
                            <Fragment key={item.activityLogId}>
                              <li className="log-item" key={item.activityLogId}>
                                <div className="description">
                                  {item.description}
                                  {item.sourceAggregateType === 'Quote' &&
                                  item.sourceAggregateId ? (
                                    <div className="log-link">
                                      <Link to={`/sales/quotes/${item.sourceAggregateId}`}>
                                        Go To Quote
                                      </Link>
                                    </div>
                                  ) : null}
                                </div>
                                <div className="comments">
                                  {item.comment.indexOf('\n') >= 0 ? (
                                    item.comment
                                      ?.trim()
                                      .split('\n')
                                      .map((jobLine, jobLineIdx) => (
                                        <Fragment key={jobLineIdx}>
                                          <br />
                                          {jobLine
                                            .replace(':', ',')
                                            .split(',')
                                            .map((jobDetailLine, jobDetailLineIdx) => (
                                              <div key={jobDetailLineIdx}>{jobDetailLine}</div>
                                            ))}
                                        </Fragment>
                                      ))
                                  ) : (
                                    <>{item.comment}</>
                                  )}
                                </div>
                              </li>
                            </Fragment>
                          );
                        })}
                      </ul>
                    </div>
                  );
                },
              },
            ],
          },
        ],
      },
    ],
  };
}
