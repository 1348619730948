import { IAjax } from 'src/infrastructure/ajax';
import { operationsUrls } from 'src/domain/services/apiUrls';

type HolidayItem = Operations.Domain.Queries.ViewHoliday.HolidayItem;
type HolidayType = Operations.Domain.AggregatesModel.HolidayAggregate.HolidayType;
type UpdateHolidayCommand = Operations.Domain.Commands.Holiday.UpdateHoliday.UpdateHolidayCommand;
type CreateHolidayCommand = Operations.Domain.Commands.Holiday.CreateHoliday.CreateHolidayCommand;
type HolidayListItem = Operations.Domain.Queries.ListHolidays.HolidayListItem;
type ListHolidaysQuery = Operations.Domain.Queries.ListHolidays.ListHolidaysQuery;

export default function init(ajax: IAjax) {
  async function listHolidays(query: Partial<ListHolidaysQuery>) {
    const result = await ajax.get(operationsUrls.holidayUrls.listHolidays(query)).toPromise();

    return result.response as Common.Dtos.ListResult<HolidayListItem>;
  }

  function createHoliday(command: CreateHolidayCommand) {
    return ajax
      .post(operationsUrls.holidayUrls.createHoliday, command)
      .toPromise()
      .then<string>(r => r.response);
  }

  function updateHoliday(command: UpdateHolidayCommand) {
    return ajax.put(operationsUrls.holidayUrls.updateHoliday(command.id), command).toPromise();
  }

  function deleteHoliday(holidayId: string) {
    return ajax.httpDelete(operationsUrls.holidayUrls.deleteHoliday(holidayId)).toPromise();
  }

  function viewHoliday(holidayId: string) {
    return ajax
      .get(operationsUrls.holidayUrls.viewHoliday(holidayId))
      .toPromise()
      .then<HolidayItem>(r => r.response);
  }

  function loadHolidayTypes() {
    return ajax
      .get(operationsUrls.holidayUrls.loadHolidayTypes)
      .toPromise()
      .then<HolidayType[]>(r => r.response);
  }

  return {
    holidays: {
      listHolidays,
      createHoliday,
      updateHoliday,
      viewHoliday,
      loadHolidayTypes,
      deleteHoliday,
    },
  };
}
