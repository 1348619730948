import MaintainComponentTypes, { IMaintainComponentTypesProps } from './MaintainComponentTypes';
import inject from 'src/views/injectFromStore';
import Omit from 'src/infrastructure/omit';

const Container = inject<Omit<IMaintainComponentTypesProps, 'mode'>, IMaintainComponentTypesProps>(
  (allStores, p) => ({
    canManageComponentTypes: allStores.rootStore.account.isWorkshopDepartmentMember,
    listComponentTypes: allStores.rootStore.workshop.componentTypes.loadComponentTypeListItems,
    componentTypes: allStores.rootStore.workshop.componentTypes.componentTypeListItems.slice(),
    updateComponentTypes: allStores.rootStore.workshop.componentTypes.updateComponentTypes,
  })
)(MaintainComponentTypes);

export default Container;
