import { DateTime } from 'luxon';
import { PureComponent } from 'react';
import { QuoteStatus, RailBookingStatus } from 'src/api/enums';
import DashboardTileCollection from 'src/views/components/Dashboard/DashboardTileCollection';
import StandardDashboardTile from 'src/views/components/Dashboard/StandardDashboardTile';
import Page from 'src/views/components/Page';
import { IPageDef, PagePrimarySize } from 'src/views/definitionBuilders/types/page';
import { PaneType } from 'src/views/definitionBuilders/types/pane';

type DashboardData = Operations.Domain.Queries.GetAccountingDashboardData.AccountingDashboardData;
type ExtraTypeItem = Operations.Domain.Queries.ViewExtraType.ExtraTypeItem;

export interface IAccountingProps {
  dashboardData?: DashboardData;
  extrasList?: ExtraTypeItem[];
  onLoadData: () => Promise<void>;
  loadExtras: () => Promise<void>;
}

class Accounting extends PureComponent<IAccountingProps> {
  componentDidMount() {
    this.props.onLoadData();
    this.props.loadExtras();
  }

  private readonly getData = () => {
    const { dashboardData } = this.props;
    return { dashboardData };
  };

  private get uncompletedCharterLink() {
    return `/accounting/bookings?hasInvoiceNumber=false&hasSuspendedInvoicing=false&hasTradingAccount=false&orderBy=QuoteView.FirstTripAsDateTime~asc&statuses=${QuoteStatus.Booked}`;
  }

  private get needsVerificationLink() {
    return '/accounting/bookings?hasInvoiceNumber=true&needsVerification=true&orderBy=QuoteView.FirstTripAsDateTime~asc';
  }

  private get uninvoicedBookinNoTradingAccountLink() {
    const today = DateTime.local();
    return `/accounting/bookings?firstTripTo=${today
      .plus({
        months: 1,
      })
      .toISODate()}&hasInvoiceNumber=false&hasTradingAccount=false&orderBy=QuoteView.FirstTripAsDateTime~asc&statuses=${
      QuoteStatus.Booked
    }`;
  }

  private get facilitiesLink() {
    const today = DateTime.local();

    let extrasQuery = this.props.extrasList
      ?.map(e => e.id)
      .reduce((p, c) => p + `&extraTypeIds=${c}`, '');

    return `/accounting/bookings?firstTripTo=${today
      .plus({
        months: 1,
      })
      .toISODate()}${extrasQuery}&hasInvoiceNumber=false&orderBy=QuoteView.FirstTripAsDateTime~asc&statuses=${
      QuoteStatus.Booked
    }`;
  }

  private get bookingsToBeCompletedToday() {
    const today = DateTime.local();
    return `/accounting/bookings?firstTripTo=${today
      .plus({
        days: -1,
      })
      .toISODate()}&orderBy=QuoteView.FirstTripAsDateTime~asc&statuses=${QuoteStatus.Booked}`;
  }

  private get cancelledBookingsWithInvoice() {
    const today = DateTime.local();
    return `/accounting/bookings?firstTripTo=${today
      .plus({
        months: 1,
      })
      .toISODate()}&orderBy=QuoteView.FirstTripAsDateTime~asc&statuses=${
      QuoteStatus.Cancelled
    }&statuses=${QuoteStatus.CancelledInProgress}&hasInvoiceNumber=true`;
  }

  private get railBookingsToBeCompletedTodayLink() {
    const yesterday = DateTime.local()
      .startOf('day')
      .minus({ days: 1 });
    return `/accounting/rail-bookings?firstTripTo=${yesterday.toISODate()}&orderBy=StartJobClockOn~asc&statuses=${
      RailBookingStatus.Booked
    }&statuses=${RailBookingStatus.Cancelled}`;
  }

  private get uninvoicedRailBookingsLink() {
    return `/accounting/rail-bookings?orderBy=StartJobClockOn~asc&statuses=${RailBookingStatus.Booked}&hasInvoiceNumber=false`;
  }

  private get cancelledInProgressBookingsLink() {
    return `/accounting/bookings?statuses=${QuoteStatus.CancelledInProgress}`;
  }

  private get incompleteContractChartersLink() {
    return `/accounting/contract-charter-bookings?isCompleted=false`;
  }

  private readonly getPageDef = (): IPageDef => {
    const { dashboardData } = this.props;

    return {
      primarySize: PagePrimarySize.threeQuarters,
      primarySection: {
        title: 'Accounting',
        panels: [
          {
            panes: [
              {
                paneType: PaneType.customPane,
                render: () => (
                  <>
                    <DashboardTileCollection title="Today" isLoading={!dashboardData}>
                      {dashboardData ? (
                        <>
                          <StandardDashboardTile
                            title="Invoiced Bookings Cancelled"
                            number={dashboardData.cancelledBookingsWithInvoiceNumber}
                            link={this.cancelledBookingsWithInvoice}
                          />
                          <StandardDashboardTile
                            title="Invoiced Bookings with a price change"
                            number={dashboardData.invoicedBookingsNeedingVerification}
                            link={this.needsVerificationLink}
                          />
                          <StandardDashboardTile
                            title="Uninvoiced bookings without trading accounts"
                            number={dashboardData.notInvoicedBookingsWithNoTradeAccountToday}
                            link={this.uninvoicedBookinNoTradingAccountLink}
                          />
                          <StandardDashboardTile
                            title="Bookings with Facilities"
                            number={dashboardData.bookingsWithFacilities}
                            link={this.facilitiesLink}
                          />
                          <StandardDashboardTile
                            title="Bookings to be completed"
                            number={dashboardData.bookingsToBeCompletedToday}
                            link={this.bookingsToBeCompletedToday}
                          />
                          <StandardDashboardTile
                            title="Rail Bookings to be completed"
                            number={dashboardData.railBookingsToBeCompletedToday}
                            link={this.railBookingsToBeCompletedTodayLink}
                          />
                          <StandardDashboardTile
                            title="Cancelled In Progress Bookings"
                            number={dashboardData.cancelledInProgressBookings}
                            link={this.cancelledInProgressBookingsLink}
                          />
                          <StandardDashboardTile
                            title="Incomplete Contract Bookings"
                            number={dashboardData.incompleteContractCharterBookings}
                            link={this.incompleteContractChartersLink}
                          />
                        </>
                      ) : null}
                    </DashboardTileCollection>
                    <DashboardTileCollection title="Future" isLoading={!dashboardData}>
                      {dashboardData ? (
                        <>
                          <StandardDashboardTile
                            title="Uninvoiced bookings without trading accounts"
                            number={dashboardData.notInvoicedBookingsWithNoTradeAccountFuture}
                            link={this.uncompletedCharterLink}
                          />
                          <StandardDashboardTile
                            title="Uninvoiced Rail Bookings"
                            number={dashboardData.unInvoicedRailBookingsFuture}
                            link={this.uninvoicedRailBookingsLink}
                          />
                        </>
                      ) : null}
                    </DashboardTileCollection>
                  </>
                ),
              },
            ],
          },
        ],
      },
    };
  };

  render() {
    return (
      <Page className="accounting-landing-page" data={this.getData()} def={this.getPageDef()} />
    );
  }
}

export default Accounting;
