import { PureComponent } from 'react';
import { InputGroup } from 'reactstrap';
import { DateTime } from 'luxon';
import { IFieldApi } from 'src/views/components/Page/forms/Field';
import { IFieldMeta, IFieldData, IDateFieldDef } from 'src/views/definitionBuilders/types';
import { LabelledFormField } from 'src/views/components/forms';
import FocusWrapper from 'src/views/components/FocusWrapper';
import DateInput, {
  getEditingFormattedDateString,
  parseEditingFormattedDateString,
} from './subfields/DateInput';

interface IDatePageFieldProps {
  fieldDef: IDateFieldDef;
  fieldMeta: IFieldMeta;
  fieldData: IFieldData<string>;
  fieldApi: IFieldApi;
}

interface IDatePageFieldState {
  prevFieldValue: string | undefined; // held in ISO datetime format
  externalValue: string | undefined; // held in ISO datetime format
  internalDateValue: string;
}

class DatePageField extends PureComponent<IDatePageFieldProps, IDatePageFieldState> {
  static getDerivedStateFromProps(
    nextProps: Readonly<IDatePageFieldProps>,
    prevState: IDatePageFieldState
  ) {
    const { fieldData } = nextProps;
    // If the external version of this field is different to what we have, then update using the external value
    // (the react-form value is king, so that external logic can update this field)
    if (fieldData.fieldValue !== prevState.prevFieldValue) {
      // If the external value changed to a different value that this control has, then reset
      // the internal state to sync up. Otherwise do nothing
      // (Eg: so that "2" isn't changed to a full date)
      const internalStateUpdate =
        prevState.externalValue === fieldData.fieldValue
          ? {}
          : DatePageField.getInternalStateFromExternal(
              fieldData.fieldValue,
              nextProps.fieldDef.timezone
            );

      return {
        prevFieldValue: fieldData.fieldValue,
        externalValue: fieldData.fieldValue,
        ...internalStateUpdate,
      };
    }
    return null;
  }

  private static getInternalStateFromExternal(fieldValue: string | undefined, timezone?: string) {
    // The string should be an ISO date or datetime - assume utc unless specified in the string
    const date = fieldValue
      ? DateTime.fromISO(fieldValue, { zone: 'utc' }).setZone(timezone || 'local')
      : undefined;
    const internalDateValue =
      date && date.isValid ? getEditingFormattedDateString(date, timezone) : fieldValue || '';
    return { internalDateValue };
  }

  constructor(props: IDatePageFieldProps) {
    super(props);
    const { fieldData } = props;

    this.state = {
      prevFieldValue: fieldData.fieldValue,
      externalValue: fieldData.fieldValue,
      ...DatePageField.getInternalStateFromExternal(fieldData.fieldValue, props.fieldDef.timezone),
    };
  }

  private readonly handleFieldBlur = () => {
    this.props.fieldApi.setTouched(true);
    this.props.fieldMeta.onBlur && this.props.fieldMeta.onBlur(this.state.externalValue);
  };

  private readonly handleInternalDateChange = (newDateValue: string) => {
    const currentExternalValue = this.state.externalValue;
    const date = parseEditingFormattedDateString(newDateValue, this.props.fieldDef.timezone);
    const externalValue = date && date.isValid ? date.toISODate() : '';
    this.setState({ internalDateValue: newDateValue, externalValue }, () => {
      this.props.fieldApi.setValue(externalValue);

      // Workaround: When a date is picked from the calendar picker, blur is not fired correctly
      if (externalValue !== currentExternalValue) {
        this.props.fieldApi.setTouched(true);
      }
    });
  };

  private readonly getDefaultReadonlyValue = (date: DateTime | undefined) => {
    return date && date.isValid
      ? date.setZone(this.props.fieldDef.timezone || 'local').toLocaleString(DateTime.DATE_MED)
      : '';
  };

  render() {
    const { fieldApi, fieldDef: def, fieldMeta: meta, fieldData: data } = this.props;
    const { internalDateValue } = this.state;
    const { error, touched } = fieldApi;

    const date = parseEditingFormattedDateString(internalDateValue, def.timezone);
    const internalIsInvalid = !!(date && !date.isValid);
    const errorText = internalIsInvalid ? 'The date is invalid' : error;
    const labelText = typeof def.label === 'function' ? def.label(data) : def.label;
    const tooltipText = typeof def.tooltip === 'function' ? def.tooltip(data) : def.tooltip;

    return (
      <LabelledFormField
        className="date-page-field-component"
        readonly={meta.readonly}
        readonlyValue={
          def.formatReadonly ? def.formatReadonly(data) : this.getDefaultReadonlyValue(date)
        }
        readonlyLinkTo={def.linkTo && date ? def.linkTo(data) : undefined}
        mandatory={meta.mandatory}
        hideLabel={meta.hideLabel || !labelText || labelText.trim().length === 0}
        labelValue={labelText}
        tooltipValue={tooltipText}
        noForm={meta.noForm}
        error={touched && errorText}>
        <FocusWrapper onBlur={this.handleFieldBlur}>
          <InputGroup>
            <DateInput
              autoFocus={meta.autoFocus}
              displayAsInvalid={touched && !!errorText}
              value={internalDateValue}
              onChange={this.handleInternalDateChange}
              timezone={def.timezone}
            />
          </InputGroup>
        </FocusWrapper>
      </LabelledFormField>
    );
  }
}

export default DatePageField;
