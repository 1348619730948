import CrudPage, { CrudPageMode, ICrudPageDef } from 'src/views/components/Page/pages/CrudPage';
import { RouteComponentProps } from 'react-router-dom';
import { Component } from 'react';
import {
  PagePrimarySize,
  PaneType,
  FieldType,
  ActionType,
} from 'src/views/definitionBuilders/types';
import { FlagCheckered } from 'src/images/icons';
import { StaffMemberFilter } from 'src/views/components/Page/fields/StaffMemberField';
import { cctvRequestStatusDescription } from 'src/api/enums';
import { CctvRequestStatus } from 'src/api/enums';

type CctvRequestItem = Operations.Domain.Queries.ViewCctvRequest.CctvRequestItem;
type CreateCctvRequestCommand = Operations.Domain.Commands.CctvRequest.CreateCctvRequest.CreateCctvRequestCommand;
type UpdateCctvRequestCommand = Operations.Domain.Commands.CctvRequest.UpdateCctvRequest.UpdateCctvRequestCommand;
type CloseCctvRequestCommand = Operations.Domain.Commands.CctvRequest.CloseCctvRequest.CloseCctvRequestCommand;
type AssetItem = Common.Queries.Workshop.GetSimpleFleetAssetList.SimpleAssetItem;
type CctvRequestStatusEnumeration = Operations.Domain.AggregatesModel.CctvRequestAggregate.CctvRequestStatus;

export interface IMaintainCctvRequestProps {
  mode: CrudPageMode;
  canManageCctvRequests: boolean;

  cctvRequest: CctvRequestItem | undefined;
  loadCctvRequest: (id: string) => Promise<void>;
  createCctvRequest: (command: CreateCctvRequestCommand) => Promise<void>;
  updateCctvRequest: (command: UpdateCctvRequestCommand) => Promise<void>;
  closeCctvRequest: (command: CloseCctvRequestCommand) => Promise<void>;

  fleetAssets: AssetItem[];
  loadFleetAssets: () => Promise<void>;
  loadStaffMembers: () => Promise<void>;
}

interface IMaintainCctvRequestParams {
  id: string;
}

type InternalProps = IMaintainCctvRequestProps & RouteComponentProps<IMaintainCctvRequestParams>;

class MaintainCctvRequest extends Component<InternalProps> {
  componentDidMount() {
    const { loadFleetAssets, loadStaffMembers } = this.props;

    loadFleetAssets();
    loadStaffMembers();
  }

  private get isUpdateMode() {
    return this.props.mode === 'update';
  }

  private get isCreateMode() {
    return this.props.mode === 'create';
  }

  private get CctvRequestId() {
    return this.props.match.params.id;
  }

  private handlePreSubmitForCreate = (cctvRequest: CctvRequestItem): CreateCctvRequestCommand => {
    return {
      assetId: cctvRequest.asset && cctvRequest.asset.id,
      driverStaffMemberId: cctvRequest.driverStaffMember && cctvRequest.driverStaffMember.id,
      cctvSystemInWorkingOrder: cctvRequest.cctvSystemInWorkingOrder,
      cctvViewOn: cctvRequest.cctvViewOn,
      cctvViewDetails: cctvRequest.cctvViewDetails,
      cctvViewStaffMemberId: cctvRequest.cctvViewStaffMember && cctvRequest.cctvViewStaffMember.id,
      tapeRequestedOn: cctvRequest.tapeRequestedOn,
      tapeRequestedByStaffMemberId:
        cctvRequest.tapeRequestedByStaffMember && cctvRequest.tapeRequestedByStaffMember.id,
      tapeRemovedOn: cctvRequest.tapeRemovedOn,
      tapeRemovedByStaffMemberId:
        cctvRequest.tapeRemovedByStaffMember && cctvRequest.tapeRemovedByStaffMember.id,
      codeOfConductNumber: cctvRequest.codeOfConductNumber,
      customerComplaintNumber: cctvRequest.customerComplaintNumber,
      directionOfTravel: cctvRequest.directionOfTravel,
      incidentOn: cctvRequest.incidentOn,
      incidentDetails: cctvRequest.incidentDetails,
      incidentLocation: cctvRequest.incidentLocation,
      passengerPositionOnBus: cctvRequest.passengerPositionOnBus,
      service: cctvRequest.service,
    } as CreateCctvRequestCommand;
  };

  private handlePreSubmitForUpdate = (cctvRequest: CctvRequestItem): UpdateCctvRequestCommand => {
    return {
      id: this.CctvRequestId,
      assetId: cctvRequest.asset && cctvRequest.asset.id,
      driverStaffMemberId: cctvRequest.driverStaffMember && cctvRequest.driverStaffMember.id,
      cctvSystemInWorkingOrder: cctvRequest.cctvSystemInWorkingOrder,
      cctvViewOn: cctvRequest.cctvViewOn,
      cctvViewDetails: cctvRequest.cctvViewDetails,
      cctvViewStaffMemberId: cctvRequest.cctvViewStaffMember && cctvRequest.cctvViewStaffMember.id,
      tapeRequestedOn: cctvRequest.tapeRequestedOn,
      tapeRequestedByStaffMemberId:
        cctvRequest.tapeRequestedByStaffMember && cctvRequest.tapeRequestedByStaffMember.id,
      tapeRemovedOn: cctvRequest.tapeRemovedOn,
      tapeRemovedByStaffMemberId:
        cctvRequest.tapeRemovedByStaffMember && cctvRequest.tapeRemovedByStaffMember.id,
      codeOfConductNumber: cctvRequest.codeOfConductNumber,
      customerComplaintNumber: cctvRequest.customerComplaintNumber,
      directionOfTravel: cctvRequest.directionOfTravel,
      incidentOn: cctvRequest.incidentOn,
      incidentDetails: cctvRequest.incidentDetails,
      incidentLocation: cctvRequest.incidentLocation,
      passengerPositionOnBus: cctvRequest.passengerPositionOnBus,
      service: cctvRequest.service,
    } as UpdateCctvRequestCommand;
  };

  private isCctvRequestClosed(cctvRequestStatus: CctvRequestStatusEnumeration) {
    return cctvRequestStatus && cctvRequestStatus === CctvRequestStatus.Closed;
  }

  private readonly getPageDef = (updating: boolean): ICrudPageDef => {
    const { cctvRequest, canManageCctvRequests } = this.props;
    const editable = !this.isUpdateMode || updating;

    return {
      primarySize: PagePrimarySize.twoThirds,
      primarySection: {
        title:
          !this.isCreateMode && cctvRequest
            ? `CCTV Request ${cctvRequest.cctvRequestNumber}`
            : 'Create a CCTV Request',
        badge:
          !this.isCreateMode && cctvRequest && cctvRequest.cctvRequestStatus
            ? { label: cctvRequestStatusDescription(cctvRequest.cctvRequestStatus) }
            : undefined,
        primaryActions: [
          {
            actions: [
              {
                actionType: ActionType.actionCollection,
                hidden:
                  editable ||
                  !canManageCctvRequests ||
                  (this.props.cctvRequest &&
                    this.isCctvRequestClosed(this.props.cctvRequest.cctvRequestStatus)),
                actionGroups: [
                  {
                    actions: [
                      {
                        actionType: ActionType.actionButton,
                        label: 'Close CCTV Request',
                        icon: <FlagCheckered fixedWidth />,
                        onClick: () =>
                          this.props.closeCctvRequest({ cctvRequestId: this.CctvRequestId }),
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
        panels: [
          {
            panes: [
              {
                paneType: PaneType.formFieldsPane,
                columnCount: 2,
                fields: [
                  {
                    fieldType: FieldType.dateTimeField,
                    label: 'Incident On',
                    dataAddr: 'incidentOn',
                    mandatory: true,
                  },
                  {
                    fieldType: FieldType.textField,
                    label: 'Incident Location',
                    dataAddr: 'incidentLocation',
                  },
                  {
                    fieldType: FieldType.staffMemberField,
                    label: 'Staff Member',
                    dataAddr: 'driverStaffMember',
                    mandatory: true,
                    staffMemberFilter: StaffMemberFilter.hasDriversAuthorisation,
                  },
                  {
                    fieldType: FieldType.assetSelectField,
                    label: 'Vehicle',
                    dataAddr: 'asset',
                    optionItems: this.props.fleetAssets,
                    valueKey: 'id',
                    descriptionKey: 'name',
                    mandatory: true,
                  },
                  {
                    fieldType: FieldType.textField,
                    label: 'Service',
                    dataAddr: 'service',
                  },
                  {
                    fieldType: FieldType.textField,
                    label: 'Direction of travel',
                    dataAddr: 'directionOfTravel',
                  },
                  {
                    fieldType: FieldType.textField,
                    label: 'Passenger position on a bus',
                    dataAddr: 'passengerPositionOnBus',
                  },
                  {
                    fieldType: FieldType.textField,
                    label: 'Customer Complaint number',
                    dataAddr: 'customerComplaintNumber',
                  },
                  {
                    fieldType: FieldType.textField,
                    label: 'Code of Conduct number',
                    dataAddr: 'codeOfConductNumber',
                  },
                ],
              },
              {
                paneType: PaneType.formFieldsPane,
                fields: [
                  {
                    fieldType: FieldType.textAreaField,
                    label: 'Incident details',
                    dataAddr: 'incidentDetails',
                    mandatory: true,
                  },
                ],
              },
              {
                paneType: PaneType.formFieldsPane,
                columnCount: 2,
                fields: [
                  {
                    fieldType: FieldType.dateTimeField,
                    label: 'Tape Requested On',
                    mandatory: true,
                    dataAddr: 'tapeRequestedOn',
                  },
                  {
                    fieldType: FieldType.staffMemberField,
                    label: 'Tape Requested By',
                    mandatory: true,
                    dataAddr: 'tapeRequestedByStaffMember',
                    staffMemberFilter: StaffMemberFilter.active,
                  },
                  {
                    fieldType: FieldType.dateTimeField,
                    label: 'Tape Removed On',
                    dataAddr: 'tapeRemovedOn',
                  },
                  {
                    fieldType: FieldType.staffMemberField,
                    label: 'Tape Removed By',
                    dataAddr: 'tapeRemovedByStaffMember',
                    staffMemberFilter: StaffMemberFilter.active,
                  },
                ],
              },
            ],
          },
          {
            title: 'Observations',
            panes: [
              {
                paneType: PaneType.formFieldsPane,
                columnCount: 2,
                fields: [
                  {
                    fieldType: FieldType.dateTimeField,
                    label: 'CCTV Viewed On',
                    dataAddr: 'cctvViewOn',
                  },
                  {
                    fieldType: FieldType.staffMemberField,
                    label: 'CCTV Viewed By',
                    dataAddr: 'cctvViewStaffMember',
                    staffMemberFilter: StaffMemberFilter.active,
                  },
                  {
                    fieldType: FieldType.yesNoField,
                    label: 'CCTV System in Working Order',
                    dataAddr: 'cctvSystemInWorkingOrder',
                    mandatory: d => d.parentValue.cctvViewStaffMember,
                  },
                ],
              },
              {
                paneType: PaneType.formFieldsPane,
                fields: [
                  {
                    fieldType: FieldType.textAreaField,
                    label: 'Observations from viewing CCTV',
                    dataAddr: 'cctvViewDetails',
                  },
                ],
              },
            ],
          },
        ],
        onFormPreSubmit: this.isUpdateMode
          ? this.handlePreSubmitForUpdate
          : this.handlePreSubmitForCreate,
        onFormSubmit: this.isUpdateMode
          ? this.props.updateCctvRequest
          : this.props.createCctvRequest,
      },
    };
  };

  private readonly loadData = () => {
    return this.props.loadCctvRequest(this.CctvRequestId);
  };

  render() {
    const { mode, cctvRequest, canManageCctvRequests } = this.props;
    return (
      <CrudPage
        def={({ updating }) => this.getPageDef(updating)}
        mode={mode}
        isEditingForbidden={!canManageCctvRequests}
        onLoadData={this.loadData}
        data={cctvRequest}
        createDefaultData={{}}
      />
    );
  }
}

export default MaintainCctvRequest;
