import { saveAs } from 'file-saver';
import { DateTime } from 'luxon';
import { observer } from 'mobx-react';
import { useRootStore } from 'src/domain/entities/RootStoreModel';
import { CheckIcon, ExcelIcon } from 'src/images/icons';
import ListPage, { IListPageDef } from 'src/views/components/Page/pages/ListPage';
import TaskCardItem from 'src/views/components/TaskCard/TaskCardItem';
import { getSubmitCloseModalActionGroupDef } from 'src/views/definitionBuilders/common';
import {
  ActionType,
  IModalDefBuilderApi,
  ISectionDef,
  PagePrimarySize,
  ShellModalSize,
} from 'src/views/definitionBuilders/types';
import { FieldType } from 'src/views/definitionBuilders/types/field';
import { PaneType } from 'src/views/definitionBuilders/types/pane';
import withQueryParams, { IQueryParamsProps } from 'src/views/hocs/withQueryParams';
import PrimaryTitle from 'src/views/components/Page/PrimaryTitle/PrimaryTitle';

type ListCustomersQuery = Operations.Domain.Queries.ListCustomers.ListCustomersQuery;

const ListCustomer: React.FC<IQueryParamsProps<ListCustomersQuery>> = observer(
  (props: IQueryParamsProps<ListCustomersQuery>) => {
    const rootStore = useRootStore();
    const salesModel = rootStore.operations.sales;
    const customersModel = salesModel.customers;
    const accountModel = rootStore.account;

    const getPageDef = (): IListPageDef => {
      return {
        primaryTitle: (
          <PrimaryTitle
            title="Customers"
            link="https://www.notion.so/Customers-32ac3eb0de484db7ad59af00fe1fc46d"></PrimaryTitle>
        ),
        primarySize: PagePrimarySize.full,
        onLoadData: customersModel.listItems,
        externalSearch: true,
        createLink: accountModel.isSalesDepartmentMember
          ? TaskCardItem.sales.createCustomer
          : undefined,
        hasMoreData: customersModel.hasMoreItems,
        primaryFields: [
          {
            fieldType: FieldType.textField,
            dataAddr: 'name',
            label: 'Name',
            linkTo: d => `/sales/customers/${d.parentValue.id}`,
          },
          {
            fieldType: FieldType.textField,
            dataAddr: 'email',
            label: 'Email',
          },
          {
            fieldType: FieldType.textField,
            dataAddr: 'phone',
            label: 'Phone',
          },
          {
            fieldType: FieldType.customField,
            dataAddr: 'hasTradingAccount',
            label: 'Trading Account',
            render: api => {
              return api.data.parentValue.hasTradingAccount ? <CheckIcon /> : null;
            },
          },
        ],
        primaryActions: [
          {
            actions: [
              {
                actionType: ActionType.actionCollection,
                actionGroups: [
                  {
                    actions: [
                      {
                        actionType: ActionType.actionButton,
                        label: 'Export To Excel',
                        icon: <ExcelIcon fixedWidth />,
                        onClick: () => saveExcel(),
                      },
                      {
                        actionType: ActionType.modalActionButton,
                        label: 'Export Quote Conversion and Spending',
                        icon: <ExcelIcon fixedWidth />,
                        modalDef: getQuoteConversionAndSpendingModal(),
                        modalSize: ShellModalSize.half,
                        hidden: !(
                          accountModel.isSalesDepartmentMember ||
                          accountModel.isAccountingDepartmentMember
                        ),
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      };
    };
    const saveExcel = () => {
      const fileName = `Customers_${DateTime.local().toFormat('yyyyMMddHHmm')}.xlsx`;
      return customersModel
        .exportToExcel(props.getQueryParams())
        .then(blob => saveAs(blob, fileName));
    };

    const getQuoteConversionAndSpendingModal = (): ((
      modalDefApi: IModalDefBuilderApi
    ) => ISectionDef) => {
      return _ => ({
        title: 'Export Quote Conversion and Spending',
        asForm: true,
        panels: [
          {
            panes: [
              {
                paneType: PaneType.formFieldsPane,
                fields: [
                  {
                    fieldType: FieldType.dateField,
                    label: 'Quotes From',
                    dataAddr: 'from',
                  },
                  {
                    fieldType: FieldType.dateField,
                    label: 'Quotes To',
                    dataAddr: 'to',
                  },
                  {
                    fieldType: FieldType.yesNoField,
                    label: 'Has Trading Account',
                    dataAddr: 'hasTradingAccount',
                  },
                ],
              },
            ],
          },
        ],
        secondaryActions: [getSubmitCloseModalActionGroupDef('Ok')],
        onFormSubmit: v => {
          const fileName = `CustomersQuoteConversionAndSpending_${DateTime.local().toFormat(
            'yyyyMMddHHmm'
          )}.xlsx`;
          return customersModel
            .exportQuoteConversionRates({
              ...props.getQueryParams(),
              from: v.from,
              to: v.to,
              hasTradingAccount: v.hasTradingAccount,
            } as Operations.Domain.Queries.GetQuoteConversionRate.GetQuoteConversionAndSpendingQuery)
            .then(blob => saveAs(blob, fileName));
        },
      });
    };

    return (
      <ListPage
        className="list-customers-component"
        data={customersModel.items}
        def={getPageDef()}
      />
    );
  }
);
export default withQueryParams(ListCustomer);
