import { UiRouteName } from 'src/domain/baseTypes';
import { IRootStoreModel } from 'src/domain/entities/RootStoreModel';
import UiRoute from 'src/domain/services/buildUiRoutes/UiRoute';

export default function buildUiRoutes(store: IRootStoreModel) {
  const defectCreate = new UiRoute(
    UiRouteName.operationsDefectCreate,
    '/operations/defects/create',
    () => 'Raise a Defect',
    false
  );

  return {
    [UiRouteName.operationsDefectCreate]: defectCreate,
  };
}
