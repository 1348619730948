import { observer } from 'mobx-react';
import styles from './FatigueViolationModal.module.scss';
import { useEffect } from 'react';
import { useRootStore } from 'src/domain/entities/RootStoreModel';
import ShellModal, {
  IShellModalTriggerApi,
  ShellModalSize,
} from 'src/views/components/Shell/ShellModal';
import { fatigueRuleSetTypeDescription } from 'src/api/enums';

interface IFatigueViolationModalProps {}

const FatigueViolationModal: React.FC<IFatigueViolationModalProps> = observer(
  (props: IFatigueViolationModalProps) => {
    const rootStore = useRootStore();
    const model = (rootStore.compliance && rootStore.compliance?.fatigueValidations) || {};

    var api: IShellModalTriggerApi;
    useEffect(() => {
      if (model.hasValidationMessages) api.onShow();
    }, [model.hasValidationMessages]);
    return (
      <ShellModal
        size={ShellModalSize.oneThird}
        renderShowTrigger={renderApi => {
          api = renderApi;
          return <div />;
        }}
        showCloseButton={true}
        onCloseModal={() => model.clearValidationMessages()}>
        {() => (
          <div className={styles.fatigueViolationModal}>
            <h3>Fatigue Resolution</h3>
            {model.validationMessages &&
              model.validationMessages.staffMembers.map((staffMember, s) => {
                const ruleSetCount = staffMember.ruleSets?.length ?? 0;
                const totalMessageCount = staffMember.ruleSets
                  .map(x => x.messages.length)
                  .reduce((prev, curr) => prev + curr, 0);
                return (
                  totalMessageCount > 0 && (
                    <div className={styles.messageCard}>
                      <p>
                        <strong>{staffMember.staffMember.name}</strong> needs additional rest for
                        this allocation to proceed:
                      </p>
                      <div>
                        {staffMember.ruleSets.map((ruleSet, i) => (
                          <div key={s + '_' + i}>
                            <p>
                              <strong>
                                To remain compliant with{' '}
                                {fatigueRuleSetTypeDescription(ruleSet.ruleSetType)} rules
                              </strong>
                            </p>
                            {ruleSet.messages.map((message, m) => (
                              <p
                                key={s + '_' + i + '_' + m}
                                dangerouslySetInnerHTML={{ __html: message.replace('\n', '<br/>') }}
                              />
                            ))}
                            <p>
                              <a
                                href={`/compliance/fatigueDetails?day=${ruleSet.date}&staffMember=${staffMember.staffMember.id}&ruleSetType=${ruleSet.ruleSetType}`}
                                target="_blank"
                                rel="noreferrer">
                                View Current Fatigue Details
                              </a>
                            </p>
                            {i + 1 < ruleSetCount ? (
                              <p>
                                <strong>Or</strong>
                              </p>
                            ) : (
                              <p />
                            )}
                          </div>
                        ))}
                      </div>
                    </div>
                  )
                );
              })}
          </div>
        )}
      </ShellModal>
    );
  }
);

export default FatigueViolationModal;
