import { UiRouteName } from 'src/domain/baseTypes';
import { IRootStoreModel } from 'src/domain/entities/RootStoreModel';
import UiRoute from 'src/domain/services/buildUiRoutes/UiRoute';

export default function buildUiRoutes(store: IRootStoreModel) {
  const taskSubcategoryCreate = new UiRoute(
    UiRouteName.workshopTaskSubcategoryCreate,
    '/workshop/task-subcategories/create',
    () => 'Create Task Subcategory',
    false
  );

  const taskSubcategoryEdit = new UiRoute(
    UiRouteName.workshopTaskSubcategoryEdit,
    '/workshop/task-subcategories/:id',
    () => 'Manage Task Subcategory',
    false
  );

  const taskSubcategoryList = new UiRoute(
    UiRouteName.workshopTaskSubcategoryList,
    '/workshop/task-subcategories',
    () => 'Task Subcategories',
    false,
    false,
    [taskSubcategoryCreate, taskSubcategoryEdit]
  );

  return {
    [UiRouteName.workshopTaskSubcategoryCreate]: taskSubcategoryCreate,
    [UiRouteName.workshopTaskSubcategoryEdit]: taskSubcategoryEdit,
    [UiRouteName.workshopTaskSubcategoryList]: taskSubcategoryList,
  };
}
