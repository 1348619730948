import styles from './SlideInScreen.module.scss';
import cn from 'classnames';
import { TimesIcon } from 'src/images/icons';
import { SingleClickAwareButton } from 'src/views/kioskRoutes/components/presentational/SingleClickAwareButton/SingleClickAwareButton';

export interface ISlideInScreenProps {
  click: () => void;
  show: boolean;
  isRightAligned?: boolean;
  width?: number;
  className?: string;
}

interface ISplideScreenCssProps extends React.CSSProperties {
  '--width': string;
}

const SlideInScreen: React.FunctionComponent<ISlideInScreenProps> = ({
  width,
  click,
  show,
  isRightAligned,
  children,
  className,
}) => {
  const theme: ISplideScreenCssProps = {
    '--width': `${width || 20}%`,
  };
  return (
    <div
      className={cn([
        styles.SlideInScreenComponent,
        ...(show ? [styles.show] : []),
        isRightAligned ? styles.rightAligned : styles.leftAligned,
        className,
      ])}
      style={theme}>
      <div className={cn([styles.closeBtn, isRightAligned ? styles.rightBtn : styles.leftBtn])}>
        <SingleClickAwareButton onClick={click}>
          <TimesIcon />
        </SingleClickAwareButton>
      </div>
      <div className={styles.container}>{show && children}</div>
    </div>
  );
};

export default SlideInScreen;
