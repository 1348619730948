import { PaneType, ActionType, ModalDefBuilder } from 'src/views/definitionBuilders/types';

type StaffMemberItem = People.Domain.Queries.ViewStaffMember.StaffMemberItem;

export default function getMarkActiveModalDef(fields: string[]): ModalDefBuilder {
  return modalDefApi => ({
    title: 'Mark Active',
    asForm: false,
    panels: [
      {
        panes: [
          {
            paneType: PaneType.customPane,
            render: () => {
              const item = modalDefApi.actionData.sectionValue as StaffMemberItem;
              const name = item && item.firstName;
              return (
                <div>
                  <p>{`${name} cannot be marked active unless you provide following fields:`}</p>
                  {fields.map((field, index) => (
                    <p key={index}>{field}</p>
                  ))}
                </div>
              );
            },
          },
        ],
      },
    ],
    secondaryActions: [
      {
        actions: [
          {
            actionType: ActionType.closeModalActionButton,
            label: 'Back',
          },
        ],
      },
    ],
  });
}
