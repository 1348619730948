import styles from './MaintainPurchaseOrderInvoices.module.scss';
import { Component } from 'react';
import { RouteComponentProps, Link } from 'react-router-dom';
import { PagePrimarySize, PaneType, FieldType } from 'src/views/definitionBuilders/types';
import CrudPage, { ICrudPageDef, CrudPageMode } from 'src/views/components/Page/pages/CrudPage';
import { IMatchingPart } from 'src/domain/entities/workshop/parts/PartsModel';
import { IAutocompleteResult } from 'src/domain/baseTypes';
import { isArray } from 'util';
import { multiplyUnitPriceByQuantityArithmeticallySafe } from 'src/infrastructure/mathUtil';
import { formatCurrency } from 'src/infrastructure/formattingUtils';
import { DateFormat } from 'src/views/components/DateFormat';
import {
  calculateInvoiceLinesTotals,
  calculateInvoiceCreditNoteLinesTotals,
} from '../shared/purchaseOrderHelpers';

type PurchaseOrderItem = Workshop.Domain.Queries.Purchasing.PurchaseOrderItem;
type PurchaseOrderInvoice = Workshop.Domain.Queries.Purchasing.GetPurchaseOrderInvoices.PurchaseOrderInvoice;
type InvoiceLine = Workshop.Domain.Queries.Purchasing.GetPurchaseOrderInvoices.InvoiceLine;
type InvoiceLineDetails = Workshop.Domain.Queries.Purchasing.GetPurchaseOrderInvoices.InvoiceLineDetails;
type InvoiceCreditNoteLine = Workshop.Domain.Queries.Purchasing.GetPurchaseOrderInvoices.InvoiceCreditNoteLine;

export interface IMaintainPurchaseOrderInvoicesProps {
  mode: CrudPageMode;
  purchaseOrder: PurchaseOrderItem | undefined;
  loadPurchaseOrder: (id: string) => Promise<void>;
  searchParts: (search: string) => Promise<IAutocompleteResult<IMatchingPart>>;
  loadPurchaseOrderInvoices: (id: string) => Promise<void>;
  purchaseOrderInvoices: PurchaseOrderInvoice[];
}

interface IMaintainPurchaseOrderInvoicesRouteParams {
  purchaseOrderId: string;
  goodsReceivedId: string;
}

type InternalProps = IMaintainPurchaseOrderInvoicesProps &
  RouteComponentProps<IMaintainPurchaseOrderInvoicesRouteParams>;

class MaintainPurchaseOrderInvoices extends Component<InternalProps> {
  private get purchaseOrderId() {
    return this.props.match.params.purchaseOrderId;
  }

  private loadData = () => {
    return Promise.all([
      this.props.loadPurchaseOrderInvoices(this.purchaseOrderId),
      this.props.loadPurchaseOrder(this.purchaseOrderId),
    ]).then(_ => Promise.resolve());
  };

  private readonly getPageDef = (isInEditMode: boolean): ICrudPageDef => {
    const { purchaseOrder } = this.props;

    const getTitle = () => {
      const poPart = `Purchase Order ${(purchaseOrder && purchaseOrder.purchaseOrderNumber) || ''}`;
      return `${poPart}: Invoices`;
    };

    return {
      primarySize: PagePrimarySize.full,
      primarySection: {
        title: getTitle(),
        panels: [
          {
            panes: [
              {
                paneType: PaneType.repeatingPane,
                useHover: false,
                dataAddr: ['invoices'],
                itemPanes: [
                  {
                    paneType: PaneType.formFieldsPane,
                    columnCount: 2,
                    fields: [
                      {
                        fieldType: FieldType.customField,
                        dataAddr: 'invoiceNumber',
                        label: '',
                        render: d => <h2>Invoice: {d.data.fieldValue}</h2>,
                      },
                    ],
                  },
                  {
                    paneType: PaneType.formFieldsPane,
                    columnCount: 2,
                    fields: [
                      {
                        fieldType: FieldType.customField,
                        dataAddr: 'fake',
                        label: '',
                        render: d => <h4>Invoice lines</h4>,
                      },
                    ],
                  },
                  {
                    paneType: PaneType.tablePane,
                    mandatory: true,
                    dataAddr: 'invoiceLines',
                    fields: [
                      {
                        fieldType: FieldType.readonlyField,
                        label: 'Goods received',
                        dataAddr: 'details',
                        columnWidth: '12em',
                        formatReadonly: d => {
                          const data = d.fieldValue as InvoiceLineDetails[];
                          return (
                            <>
                              {data.map((x, i) => (
                                <span key={x.id} className={styles.goodsReceivedDay}>
                                  <Link
                                    to={`/workshop/purchase-orders/${this.purchaseOrderId}/goods-received/${x.id}`}>
                                    <DateFormat value={x.date} />
                                  </Link>
                                  {data.length > 1 ? ` (${x.quantity})` : ''}
                                  {data.length > 1 && i !== data.length - 1 ? ', ' : ''}
                                </span>
                              ))}
                            </>
                          );
                        },
                      },
                      {
                        fieldType: FieldType.selectAsyncField,
                        label: 'Part Number',
                        dataAddr: 'part',
                        valueKey: 'id',
                        descriptionKey: 'partNumber',
                        menuWidth: 'fitContent',
                        columnWidth: '12em',
                        loadOptionItems: this.props.searchParts,
                        optionRenderer: (o: IMatchingPart) => (
                          <div style={{ whiteSpace: 'nowrap' }}>
                            {o.partNumber} - {o.description}
                          </div>
                        ),
                        readonly: true,
                        linkTo: d => `/workshop/parts/${d.parentValue.part.id}`,
                      },
                      {
                        fieldType: FieldType.textField,
                        label: 'Description',
                        dataAddr: 'description',
                        readonly: true,
                      },
                      {
                        fieldType: FieldType.numericField,
                        label: 'Received Qty',
                        dataAddr: 'quantity',
                        columnWidth: '8em',
                        numericConfig: { numericType: 'unsignedDecimal', maxPointDigits: 4 },
                        readonly: true,
                      },
                      {
                        fieldType: FieldType.numericField,
                        label: 'Unit Price',
                        dataAddr: 'unitPrice',
                        columnWidth: '7em',
                        numericConfig: { numericType: 'unsignedDecimal', maxPointDigits: 5 },
                        readonly: true,
                        formatReadonly: 'unitPrice',
                      },
                      {
                        fieldType: FieldType.yesNoField,
                        label: 'Apply GST',
                        dataAddr: 'applyGst',
                        columnWidth: '7em',
                        readonly: true,
                      },
                      {
                        fieldType: FieldType.readonlyField,
                        label: 'Total',
                        columnWidth: '6em',
                        formatReadonly: d => {
                          const line = d.fieldValue as InvoiceLine;
                          if (!line.quantity || !line.unitPrice) {
                            return undefined;
                          }
                          return formatCurrency(
                            multiplyUnitPriceByQuantityArithmeticallySafe(
                              line.quantity,
                              line.unitPrice
                            ).value
                          );
                        },
                      },
                    ],
                  },
                  {
                    paneType: PaneType.customPane,
                    dataAddr: 'invoiceLines',
                    render: api => {
                      const lines = api.data.paneValue as InvoiceLine[];
                      if (!isArray(lines)) {
                        return undefined;
                      }

                      const { gst, subtotal, totalInclGst } = calculateInvoiceLinesTotals(lines);

                      return (
                        <div className={styles.totalsContainer}>
                          <table className={styles.totalsTable}>
                            <tbody>
                              <tr>
                                <td>
                                  <label>Subtotal:</label>
                                </td>
                                <td className={styles.price}>{formatCurrency(subtotal.value)}</td>
                              </tr>
                              <tr>
                                <td>
                                  <label>GST:</label>
                                </td>
                                <td className={styles.price}>{formatCurrency(gst.value)}</td>
                              </tr>
                              <tr>
                                <td>
                                  <label>Total:</label>
                                </td>
                                <td className={styles.price}>
                                  {formatCurrency(totalInclGst.value)}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      );
                    },
                  },
                  {
                    paneType: PaneType.formFieldsPane,
                    dataAddr: 'invoiceCreditNoteLines',
                    hidden: d => {
                      const creditNotes = d.paneValue as InvoiceCreditNoteLine[];
                      return creditNotes.length === 0;
                    },
                    fields: [
                      {
                        fieldType: FieldType.customField,
                        dataAddr: 'fake',
                        label: '',
                        render: d => <h4>Credit Notes</h4>,
                      },
                    ],
                  },
                  {
                    paneType: PaneType.tablePane,
                    dataAddr: 'invoiceCreditNoteLines',
                    hidden: d => {
                      const creditNotes = d.paneValue as InvoiceCreditNoteLine[];
                      return creditNotes.length === 0;
                    },
                    fields: [
                      {
                        fieldType: FieldType.dateField,
                        label: 'Date',
                        dataAddr: 'receivedDate',
                        columnWidth: '12em',
                        readonly: true,
                        linkTo: d =>
                          `/workshop/purchase-orders/${this.purchaseOrderId}/goods-received/${d.parentValue.goodsReceivedId}/returned-part-credits/${d.parentValue.id}`,
                      },
                      {
                        fieldType: FieldType.textField,
                        label: 'Credit Note Number',
                        dataAddr: 'creditNoteNumber',
                        columnWidth: '12em',
                        readonly: true,
                      },
                      {
                        fieldType: FieldType.selectAsyncField,
                        label: 'Part Number',
                        dataAddr: 'part',
                        valueKey: 'id',
                        descriptionKey: 'partNumber',
                        menuWidth: 'fitContent',
                        columnWidth: '12em',
                        loadOptionItems: this.props.searchParts,
                        optionRenderer: (o: IMatchingPart) => (
                          <div style={{ whiteSpace: 'nowrap' }}>
                            {o.partNumber} - {o.description}
                          </div>
                        ),
                        readonly: true,
                        linkTo: d => `/workshop/parts/${d.parentValue.part.id}`,
                      },
                      {
                        fieldType: FieldType.textField,
                        label: 'Description',
                        dataAddr: 'description',
                        readonly: true,
                      },
                      {
                        fieldType: FieldType.numericField,
                        label: 'Returned Qty',
                        dataAddr: 'quantity',
                        columnWidth: '7em',
                        numericConfig: { numericType: 'unsignedDecimal', maxPointDigits: 4 },
                        readonly: true,
                      },
                      {
                        fieldType: FieldType.numericField,
                        label: 'Credit Amount',
                        dataAddr: 'creditAmount',
                        columnWidth: '7em',
                        numericConfig: { numericType: 'unsignedDecimal', maxPointDigits: 5 },
                        readonly: true,
                        formatReadonly: 'unitPrice',
                      },
                      {
                        fieldType: FieldType.yesNoField,
                        label: 'Apply GST',
                        dataAddr: 'applyGst',
                        columnWidth: '7em',
                        readonly: true,
                      },
                      {
                        fieldType: FieldType.readonlyField,
                        label: 'Total',
                        columnWidth: '7em',
                        formatReadonly: d => {
                          const line = d.fieldValue as InvoiceCreditNoteLine;
                          if (!line.quantity || !line.creditAmount) {
                            return undefined;
                          }
                          return formatCurrency(
                            multiplyUnitPriceByQuantityArithmeticallySafe(
                              line.quantity,
                              line.creditAmount
                            ).value
                          );
                        },
                      },
                    ],
                  },
                  {
                    paneType: PaneType.customPane,
                    dataAddr: 'invoiceCreditNoteLines',
                    hidden: d => {
                      const creditNotes = d.paneValue as InvoiceCreditNoteLine[];
                      return creditNotes.length === 0;
                    },
                    render: api => {
                      const lines = api.data.paneValue as InvoiceCreditNoteLine[];
                      if (!isArray(lines)) {
                        return undefined;
                      }

                      const { gst, subtotal, totalInclGst } = calculateInvoiceCreditNoteLinesTotals(
                        lines
                      );

                      return (
                        <div className={styles.totalsContainer}>
                          <table className={styles.totalsTable}>
                            <tbody>
                              <tr>
                                <td>
                                  <label>Subtotal:</label>
                                </td>
                                <td className={styles.price}>{formatCurrency(subtotal.value)}</td>
                              </tr>
                              <tr>
                                <td>
                                  <label>GST:</label>
                                </td>
                                <td className={styles.price}>{formatCurrency(gst.value)}</td>
                              </tr>
                              <tr>
                                <td>
                                  <label>Total:</label>
                                </td>
                                <td className={styles.price}>
                                  {formatCurrency(totalInclGst.value)}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      );
                    },
                  },
                  {
                    paneType: PaneType.formFieldsPane,
                    dataAddr: 'invoiceCreditNoteLines',
                    hidden: d => {
                      const creditNotes = d.paneValue as InvoiceCreditNoteLine[];
                      return creditNotes.length === 0;
                    },
                    fields: [
                      {
                        fieldType: FieldType.customField,
                        dataAddr: 'fake',
                        label: '',
                        render: d => <h4 className={styles.invoiceTotals}>Invoice totals</h4>,
                      },
                    ],
                  },
                  {
                    paneType: PaneType.customPane,
                    dataAddr: 'invoiceCreditNoteLines',
                    hidden: d => {
                      const creditNotes = d.paneValue as InvoiceCreditNoteLine[];
                      return creditNotes.length === 0;
                    },
                    render: api => {
                      const creditLines = api.data.paneValue as InvoiceCreditNoteLine[];
                      if (!isArray(creditLines)) {
                        return undefined;
                      }

                      const {
                        gst: creditsGst,
                        subtotal: creditsSubtotal,
                        totalInclGst: creditsTotalInclGst,
                      } = calculateInvoiceCreditNoteLinesTotals(creditLines);

                      const invoiceLines = (api.data.parentValue as PurchaseOrderInvoice)
                        .invoiceLines;

                      const {
                        gst: invoiceGst,
                        subtotal: invoiceSubtotal,
                        totalInclGst: invoiceTotalInclGst,
                      } = calculateInvoiceLinesTotals(invoiceLines);

                      const gst = invoiceGst.subtract(creditsGst);
                      const subtotal = invoiceSubtotal.subtract(creditsSubtotal);
                      const totalInclGst = invoiceTotalInclGst.subtract(creditsTotalInclGst);

                      return (
                        <div className={styles.totalsContainer}>
                          <table className={styles.totalsTable}>
                            <tbody>
                              <tr>
                                <td>
                                  <label>Subtotal:</label>
                                </td>
                                <td className={styles.price}>{formatCurrency(subtotal.value)}</td>
                              </tr>
                              <tr>
                                <td>
                                  <label>GST:</label>
                                </td>
                                <td className={styles.price}>{formatCurrency(gst.value)}</td>
                              </tr>
                              <tr>
                                <td>
                                  <label>Total:</label>
                                </td>
                                <td className={styles.price}>
                                  {formatCurrency(totalInclGst.value)}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      );
                    },
                  },
                ],
              },
            ],
          },
        ],
      },
    };
  };

  render() {
    const { purchaseOrderInvoices, mode } = this.props;

    return (
      <CrudPage
        def={({ updating }) => this.getPageDef(updating)}
        mode={mode}
        isEditingForbidden
        onLoadData={this.loadData}
        data={{
          invoices: purchaseOrderInvoices,
        }}
        onLoadCreateDefaultData={this.loadData}
      />
    );
  }
}

export default MaintainPurchaseOrderInvoices;
