import styles from './JobFieldFillOut.module.scss';
import { JobFieldLabelTableCell } from 'src/views/kioskRoutes/operationsKiosk/home/PrintDailySummary/components/JobFieldLabelTableCell';

export const JobFieldFillOut: React.FC<{ label: string }> = ({ label }) => (
  <>
    <JobFieldLabelTableCell label={label} />
    <td className={styles.root}>
      <span className={styles.fillOut} />
    </td>
  </>
);
