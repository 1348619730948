import { IAjax } from 'src/infrastructure/ajax';
import { workshopUrls } from 'src/domain/services/apiUrls';

type ListJobTaskSubcategoriesQuery = Workshop.Domain.Queries.JobTaskSubcategories.List.ListJobTaskSubcategoriesQuery;

// tslint:disable-next-line:no-empty
const returnVoid = () => {};

export default function init(ajax: IAjax) {
  function listJobTaskSubcategories(query: Partial<ListJobTaskSubcategoriesQuery>) {
    return ajax
      .get(workshopUrls.taskSubcategoryUrls.listJobTaskSubcategories(query))
      .map(
        r =>
          r.response as Common.Dtos.ListResult<
            Workshop.Domain.Queries.JobTaskSubcategories.JobTaskSubcategoryItem
          >
      );
  }

  function checkForUniqueName(id: number, name: string) {
    return ajax
      .get(workshopUrls.taskSubcategoryUrls.checkForUniqueName(id, name))
      .toPromise()
      .then<Common.Dtos.UniqueNameCheckResultDto>(r => r.response);
  }

  async function createTaskSubcategory(
    commandP: Promise<
      Workshop.Domain.Commands.JobTask.CreateTaskSubcategory.CreateTaskSubcategoryCommand
    >
  ) {
    const command = await commandP;
    return ajax
      .post(workshopUrls.taskSubcategoryUrls.createTaskSubcategory(), command)
      .toPromise()
      .then<string>(r => r.response);
  }

  function getTaskSubcategory(id: number) {
    return ajax
      .get(workshopUrls.taskSubcategoryUrls.getTaskSubcategory(id))
      .toPromise()
      .then<Workshop.Domain.Queries.PartCategory.PartCategoryItem>(r => r.response);
  }

  function updateTaskSubcategory(
    command: Workshop.Domain.Commands.JobTask.UpdateTaskSubcategory.UpdateTaskSubcategoryCommand
  ) {
    return ajax
      .put(workshopUrls.taskSubcategoryUrls.updateTaskSubcategory(command.id), command)
      .toPromise()
      .then(returnVoid);
  }

  return {
    taskSubcategories: {
      listJobTaskSubcategories,
      checkForUniqueName,
      createTaskSubcategory,
      getTaskSubcategory,
      updateTaskSubcategory,
    },
  };
}
