import { FieldDefs, FieldType } from 'src/views/definitionBuilders/types/field';
import ListPage, { IListPageDef } from 'src/views/components/Page/pages/ListPage';
import { ITablePaneFieldExtrasDef, PagePrimarySize } from 'src/views/definitionBuilders/types';
import TaskCardItem from 'src/views/components/TaskCard/TaskCardItem';
import { IListPageLoadDataRequest } from 'src/domain/baseTypes';
import styles from './ListRoles.module.scss';
import { CheckIcon } from 'src/images/icons';
import { getRolePermissionKeyValues, roleHasPermission } from '../roleHelpers';
import { RolePermission } from 'src/api/enums';
import PrimaryTitle from 'src/views/components/Page/PrimaryTitle/PrimaryTitle';

type ListRolesQuery = Common.Queries.People.ListRoles.ListRolesQuery;

export interface IListRolesProps {
  loadRoles: (request: IListPageLoadDataRequest<ListRolesQuery>) => Promise<void>;
  roles: Common.Dtos.ListRoleItem[];
  canManageRoles: boolean;
}
const ListRoles: React.FC<IListRolesProps> = (props: IListRolesProps) => {
  const getPageDef = (): IListPageDef => {
    const { loadRoles } = props;

    return {
      primaryTitle: (
        <PrimaryTitle
          title="Roles"
          link="https://www.notion.so/Roles-9e20751c2f5345119a5ec4824245bf48"></PrimaryTitle>
      ),
      primarySize: PagePrimarySize.full,
      onLoadData: loadRoles,
      externalSearch: true,
      createLink: props.canManageRoles ? TaskCardItem.people.createRole : undefined,
      primaryFields: [
        {
          fieldType: FieldType.textField,
          dataAddr: 'name',
          label: 'Name',
          linkTo: d => `/people/roles/${d.parentValue.id}`,
        },
        ...getPermissionFieldDefinitions(),
      ],
    };
  };

  const getPermissionFieldDefinitions = (): (FieldDefs & ITablePaneFieldExtrasDef)[] => {
    return getRolePermissionKeyValues().map(p => {
      return {
        fieldType: FieldType.yesNoField,
        dataAddr: '-not-used-',
        label: p.description,
        readonly: true,
        columnWidth: '15rem',
        formatReadonly: d => {
          return (
            <div className={styles.tickBox}>
              {roleHasPermission(
                d.parentValue as Common.Dtos.ListRoleItem,
                p.value as RolePermission
              ) ? (
                <CheckIcon />
              ) : null}
            </div>
          );
        },
      } as FieldDefs;
    });
  };

  const { roles } = props;
  const def = getPageDef();
  return <ListPage className="list-roles-component" data={roles} def={def} />;
};

export default ListRoles;
