import { types, flow, getRoot } from 'mobx-state-tree';
import { getAjax } from 'src/domain/services';
import * as queryString from 'query-string';
import { IKioskRootStoreModel } from '../../KioskRootStoreModel';

type ListShiftsQuery = Workshop.Domain.Queries.Shift.ListShifts.ListShiftsQuery;
type ShiftListItem = Workshop.Domain.Queries.Shift.ListShifts.ShiftListItem;

export const WorkshopShiftsModel = types
  .model('WorkshopShiftsModel', {
    shifts: types.array(types.frozen<ShiftListItem>()),
    areWeeksShiftsComplete: types.maybe(types.boolean),
  })
  .views(self => ({
    get needToCompleteTimesheet(): boolean | undefined {
      const root = getRoot<IKioskRootStoreModel>(self);
      var hasTimesheetForThisWeek = root.kiosk.timesheet.prefilledTimesheet === undefined;

      if (hasTimesheetForThisWeek) {
        return undefined;
      }

      return self.areWeeksShiftsComplete;
    },
  }))
  .actions(self => {
    const ajax = getAjax(self);

    const loadShifts = flow(function*(query: Partial<ListShiftsQuery>) {
      self.shifts.clear();
      self.shifts = yield ajax.raw
        .get(`/api/kiosk/workshop/shifts?${queryString.stringify(query)}`)
        .toPromise()
        .then<ShiftListItem[]>(r => r.response.items);
    });

    return {
      loadShifts,
    };
  });
