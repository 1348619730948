import { DateTime } from 'luxon';
import { observer } from 'mobx-react';
import { useEffect, useRef } from 'react';
import { RouteComponentProps } from 'react-router';
import {
  AssetStatus,
  DataFuelStatus,
  DefectSource,
  DefectStatus,
  InvoiceStatus,
  JobState,
  JobTaskStatus,
  LeaveStatus,
  UploadStatus,
  UploadType,
  WorkshopConflictCriteria,
} from 'src/api/enums';
import DashboardTileCollection from 'src/views/components/Dashboard/DashboardTileCollection';
import StandardDashboardTile from 'src/views/components/Dashboard/StandardDashboardTile';
import Page from 'src/views/components/Page';
import PrimaryTitle from 'src/views/components/Page/PrimaryTitle/PrimaryTitle';
import { TaskCardItem, toActionLinkDef } from 'src/views/components/TaskCard';
import {
  ActionType,
  FieldDefs,
  FieldType,
  IPageDef,
  PagePrimarySize,
  PaneType,
  ShellModalSize,
} from 'src/views/definitionBuilders/types';
import withQueryParams, { IQueryParamsProps } from 'src/views/hocs/withQueryParams';

type WorkshopDepotDto = Common.Queries.Workshop.GetWorkshopDepots.WorkshopDepotDto;
type GetWorkshopDashboardDataQuery = Workshop.Domain.Queries.GetWorkshopDashboard.GetWorkshopDashboardDataQuery;
type WorkshopDepot = Common.Queries.Workshop.GetWorkshopDepots.WorkshopDepotDto;

export interface IWorkshopProps {
  canManageDefects: boolean;
  canManageJobs: boolean;
  dashboardData?: Workshop.Domain.Queries.GetWorkshopDashboard.WorkshopDashboardData;
  onLoadData: (query: GetWorkshopDashboardDataQuery) => Promise<void>;
  workshopDepots: Array<WorkshopDepotDto>;
  defaultWorkshopDepot: WorkshopDepot | undefined;
}

type InternalProps = IWorkshopProps &
  RouteComponentProps &
  IQueryParamsProps<{
    workshopDepots?: string[] | string | undefined;
  }>;

const configurationActions = [
  TaskCardItem.assetGroups,
  TaskCardItem.checklists,
  TaskCardItem.workshop.componentTypes,
  TaskCardItem.engines,
  TaskCardItem.owners,
  TaskCardItem.workshop.partCategory,
  TaskCardItem.partsGroup,
  TaskCardItem.workshop.rosterTemplates,
  TaskCardItem.workshop.shiftTemplates,
  TaskCardItem.taskSubcategory,
  TaskCardItem.workshop.techSpecs,
  TaskCardItem.workshop.labourPrices,
  TaskCardItem.workshop.machineryTemplate,
]
  .map(toActionLinkDef)
  .sort((a, b) => a.label.localeCompare(b.label));

const Workshop: React.FC<InternalProps> = observer((props: InternalProps) => {
  const prevLocation = useRef(props.location);
  useEffect(() => {
    loadData();
  }, []);

  useEffect(() => {
    if (props.location === prevLocation.current) return;

    loadData();
  }, [props.location]);

  const loadData = () => {
    const { workshopDepots } = props.getQueryParams();
    props.onLoadData({
      depotIds: getWorkshopDepotsFromQuery(workshopDepots),
    });
  };

  const getWorkshopDepotsFromQuery = (
    workshopDepotsFromQuery: string[] | string | undefined
  ): number[] => {
    switch (typeof workshopDepotsFromQuery) {
      case 'undefined':
        return [];
      case 'string':
        return [Number(workshopDepotsFromQuery)];
      case 'object':
        return workshopDepotsFromQuery.map(s => Number(s));
    }
  };

  const getData = () => {
    const { dashboardData } = props;
    return { dashboardData };
  };

  const getFilterFieldDefs = () => {
    const { workshopDepots } = props;
    return {
      depots: {
        fieldType: FieldType.selectMultiField,
        label: 'Depot',
        dataAddr: 'workshopDepots',
        valueKey: 'id',
        descriptionKey: 'description',
        optionItems: workshopDepots,
        useValueOnly: true,
      } as FieldDefs,
    };
  };

  const getConflictsLink = () => {
    const dateString = DateTime.local().toISODate();
    return `/workshop/shifts?conflicts=${WorkshopConflictCriteria.UnacceptedStaffMember}&dateFrom=${dateString}`;
  };

  const getSelectedDepotFiltersQuery = (
    parameterName: string = 'workshopDepots',
    prependAmpersand: boolean = true
  ): string => {
    const { workshopDepots } = props.getQueryParams();
    const selectedDepots = getWorkshopDepotsFromQuery(workshopDepots);
    return `${(prependAmpersand && selectedDepots.length > 0 && '&') || ''}${selectedDepots
      .map(d => `${parameterName}=${d}`)
      .join('&')}`;
  };

  const getHousingLocationsQueryFromSelectedDepotFilters = (
    parameterName: string = 'assetHousingLocations',
    prependAmpersand: boolean = true
  ): string => {
    const { workshopDepots } = props.getQueryParams();
    const selectedDepotIds = getWorkshopDepotsFromQuery(workshopDepots);
    const housingLocationIds = props.workshopDepots
      .filter(d => selectedDepotIds.includes(d.id))
      .reduce(
        (acc: number[], d: WorkshopDepotDto) => acc.concat(d.housingLocations.map(hl => hl.id)),
        []
      );

    return `${(prependAmpersand && housingLocationIds.length > 0 && '&') ||
      ''}${housingLocationIds.map(d => `${parameterName}=${d}`).join('&')}`;
  };

  const getPageDef = (): IPageDef => {
    const { dashboardData, canManageDefects, canManageJobs, defaultWorkshopDepot } = props;
    const topTasksActions = [
      ...(canManageDefects ? [TaskCardItem.raiseDefect] : []),
      ...(canManageJobs ? [TaskCardItem.createJob] : []),
      TaskCardItem.servicesDueByDate,
      TaskCardItem.servicesDueBykms,
    ].map(toActionLinkDef);
    const filterFieldDefsLookup = getFilterFieldDefs();
    return {
      primarySize: PagePrimarySize.threeQuarters,
      primarySection: {
        title: (
          <PrimaryTitle
            title="Workshop"
            link="https://www.notion.so/Workshop-Module-Overview-c2e806a8c8ee41eeaa3a13378adf930a"></PrimaryTitle>
        ),
        panels: [
          {
            panes: [
              {
                paneType: PaneType.customPane,
                render: () => (
                  <DashboardTileCollection isLoading={!dashboardData || !defaultWorkshopDepot}>
                    {dashboardData ? (
                      <>
                        <StandardDashboardTile
                          title="Unacknowledged Defects"
                          link={`/workshop/defects?statuses=${
                            DefectStatus.DefectCreated
                          }${getHousingLocationsQueryFromSelectedDepotFilters()}`}
                          number={dashboardData.unacknowledgedDefectsCount}
                        />
                        <StandardDashboardTile
                          title="Future Tasks"
                          link={`/workshop/tasks?statuses=${
                            JobTaskStatus.Future
                          }${getHousingLocationsQueryFromSelectedDepotFilters(
                            'assetHousingLocations'
                          )}`}
                          number={dashboardData.futureTasksCount}
                        />
                        <StandardDashboardTile
                          title="Overdue Jobs"
                          link={`/workshop/jobs?jobState=${
                            JobState.Overdue
                          }${getSelectedDepotFiltersQuery()}`}
                          number={dashboardData.overdueJobsCount}
                        />
                        <StandardDashboardTile
                          title="Tasks Requiring Sign-Off"
                          link={`/workshop/tasks?requiresSignOff=true${getHousingLocationsQueryFromSelectedDepotFilters()}`}
                          number={dashboardData.tasksRequiringSignOffCount}
                        />
                        <StandardDashboardTile
                          title="Incomplete Machinery Defects"
                          link={`/workshop/defects?defectSource=${
                            DefectSource.Machinery
                          }&statuses=${DefectStatus.Acknowledged}&statuses=${
                            DefectStatus.DefectCreated
                          }${getHousingLocationsQueryFromSelectedDepotFilters()}`}
                          number={dashboardData.incompleteMachineryDefectsCount}
                        />
                        <StandardDashboardTile
                          title="Vehicles Marked Out of Service"
                          link={`/workshop/tasks?isOutOfService=true`}
                          number={dashboardData.tasksOutOfServiceCount}
                        />
                        <StandardDashboardTile
                          title="Jobs Need Invoicing"
                          link={`/workshop/jobs?invoiceStatuses=${
                            InvoiceStatus.NeedsInvoicing
                          }${getSelectedDepotFiltersQuery()}`}
                          number={dashboardData.jobsNeedingInvoicingCount}
                        />
                        <StandardDashboardTile
                          title="Pending Leave"
                          number={dashboardData.pendingLeaveCount}
                          link={`/people/leaves?leaveStatuses=${LeaveStatus.Pending}&workshopStaff=true`}
                        />
                        <StandardDashboardTile
                          title="Unaccepted Conflicts"
                          number={dashboardData.unacceptedFutureConflicts}
                          link={`${getConflictsLink()}${getSelectedDepotFiltersQuery()}`}
                        />
                        <StandardDashboardTile
                          title="Unbooked HV Inspections Due This Week"
                          number={dashboardData.unbookedCOIsDueWithinAWeek}
                          link={`/workshop/jobs/machinery-schedule?${getSelectedDepotFiltersQuery(
                            'depotId',
                            false
                          ) || `depotId=${props.defaultWorkshopDepot?.id}`}`}
                        />
                        <StandardDashboardTile
                          title="Assets with CUBIC form to be sent"
                          number={dashboardData.assetNeedingCubicFormSent}
                          link={`workshop/assets?hasCubicChanges=true&statuses=${
                            AssetStatus.Active
                          }${getHousingLocationsQueryFromSelectedDepotFilters('housingLocations')}`}
                        />
                        <StandardDashboardTile
                          title="Rebooked tasks - Parts Required"
                          number={dashboardData.futureTasksRebookPartsNeededCount}
                          link={`workshop/tasks?rebookPartsRequired=true&statuses=${
                            JobTaskStatus.Future
                          }${getHousingLocationsQueryFromSelectedDepotFilters()}`}
                        />
                        <StandardDashboardTile
                          title="Failed data fuel entries"
                          number={dashboardData.failedDataFuelEntriesCount}
                          link={`/workshop/assets/datafuel/entries?statusIds=${DataFuelStatus.Failed}`}
                        />
                        <StandardDashboardTile
                          title="Failed data fuel auto uploads"
                          number={dashboardData.failedDataFuelAutoUploadsCount}
                          link={`workshop/assets/dataFuel/uploads?statusIds=${UploadStatus.Failed}&uploadTypeIds=${UploadType.Auto}`}
                        />
                      </>
                    ) : null}
                  </DashboardTileCollection>
                ),
              },
            ],
          },
        ],
        secondaryActions: [
          {
            actions: [
              {
                actionType: ActionType.filterActionButton,
                filterSize: ShellModalSize.oneQuarter,
                defaultValues: {
                  workshopDepots: props.defaultWorkshopDepot?.id,
                },
                filterFields: Object.keys(filterFieldDefsLookup).map(k => filterFieldDefsLookup[k]),
              },
            ],
          },
        ],
      },
      secondarySections: [
        {
          title: 'Navigation',
          panels: [
            {
              title: 'Top Tasks',
              panes: [
                {
                  paneType: PaneType.navListPane,
                  links: topTasksActions,
                },
                {
                  paneType: PaneType.customPane,
                  render: d => (
                    <span>
                      <br />
                      <a href={`/wskiosk/login`} target="_blank" rel="noreferrer">
                        <span>Mechanic's Kiosk</span>
                      </a>
                    </span>
                  ),
                },
              ],
            },
            {
              title: 'Configuration',
              panes: [
                {
                  paneType: PaneType.navListPane,
                  links: configurationActions,
                },
              ],
            },
          ],
        },
      ],
    };
  };

  return <Page className="workshop-component" data={getData()} def={getPageDef()} />;
});
export default withQueryParams(Workshop);
