import * as EmailValidator from 'email-validator';
import { DateTime } from 'luxon';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { ENABLE_ALCO_READINGS, TIMEZONE } from 'src/appSettings';
import { ListPageLoadCause } from 'src/domain/baseTypes';
import { useRootStore } from 'src/domain/entities/RootStoreModel';
import { PauseIcon, PlusIcon } from 'src/images/icons';
import CrudPage, { CrudPageMode, ICrudPageDef } from 'src/views/components/Page/pages/CrudPage';
import {
  ActionType,
  FieldType,
  PagePrimarySize,
  PaneType,
  ShellModalSize,
} from 'src/views/definitionBuilders/types';
import getAddAttachmentPanelDef from 'src/views/routes/people/attachment/getAddAttachmentSectionDef';
import getRolesSectionDef from 'src/views/routes/people/staffMembers/MaintainStaffMember/getRolesSectionDef';
import getConfirmAuthRemovalModalDef from './getConfirmAuthRemovalModalDef';
import getExclusionSectionDef from './getExclusionSectionDef';
import getFileNoteSectionDef from './getFileNoteSectionDef';
import getLeaveSectionDef from './getLeaveSectionDef';
import getLicencesSectionDef from './getLicencesSectionDef';
import getSkillsSectionDef from './getSkillsSectionDef';
import getEquipmentSectionDef from './getEquipmentSectionDef';
import getMarkActiveModalDef from './getMarkActiveModalDef';
import getMarkInactiveModalDef from './getMarkInactiveModalDef';

type ActivityLogTransaction = Operations.Domain.Queries.GetActivityLog.ActivityLogTransaction;
type ActivityLogDetails = Operations.Domain.Queries.GetActivityLog.ActivityLogDetails;
type StaffMemberItem = People.Domain.Queries.ViewStaffMember.StaffMemberItem;
type CreateStaffMemberCommand = People.Domain.Commands.StaffMembers.CreateStaffMember.CreateStaffMemberCommand;
type UpdateStaffMemberCommand = People.Domain.Commands.StaffMembers.UpdateStaffMember.UpdateStaffMemberCommand;
export interface IMaintainStaffMemberProps {
  mode: CrudPageMode;
  route: RouteComponentProps<{ [x: string]: string | undefined }>;
}

const isAlcoReadingEnabled = ENABLE_ALCO_READINGS;

const MaintainStaffMember: React.FC<IMaintainStaffMemberProps> = observer(
  (props: IMaintainStaffMemberProps) => {
    const rootStore = useRootStore();
    const staffMemberModel = rootStore.people.staffMember;
    const staffMember = staffMemberModel.staffMember;
    const canSaveStaffMemberData = rootStore.account.isPeopleDepartmentMember;
    const canMaintainStaffMemberOperationsRelatedItems =
      rootStore.account.isPeopleDepartmentMember ||
      rootStore.account.isOperationsDepartmentMember ||
      rootStore.account.isWorkshopDepartmentMember;
    const canViewStaffMemberAddress = rootStore.account.isPeopleDepartmentMember;
    const equipmentTypes = rootStore.people.equipmentTypes.equipmentTypes.slice();
    const loadEquipmentTypes = rootStore.people.equipmentTypes.loadAllEquipmentTypes;
    const loadStaffMember = staffMemberModel.loadStaffMember;
    const updateStaffMember = staffMemberModel.updateStaffMember;
    const markStaffMemberInactive = staffMemberModel.markStaffMemberInactive;
    const markStaffMemberActive = staffMemberModel.markStaffMemberActive;
    const createStaffMember = staffMemberModel.createStaffMember;
    const checkForUniqueEmployeeId = staffMemberModel.checkForUniqueEmployeeId;
    const checkForUniqueEmail = staffMemberModel.checkForUniqueEmail;

    const loadExclusionsForStaffMember =
      rootStore.operations.exclusionModel.loadExclusionsForStaffMember;
    const exclusionsForStaffMember = rootStore.operations.exclusionModel.exclusionsForStaffMember.slice();
    const createExclusion = rootStore.operations.exclusionModel.createExclusion;
    const deleteExclusion = rootStore.operations.exclusionModel.deleteExclusion;
    const loadFleetAssets = rootStore.assets.loadFleetAssets;
    const fleetAssets = rootStore.assets.fleetBusAssetListItems.slice();
    const staffDepots = rootStore.people.depots.staffDepots.slice();
    const loadStaffDepots = rootStore.people.depots.loadStaffDepots;
    const employmentStatuses = rootStore.people.employmentStatuses.employmentStatuses.slice();
    const loadEmploymentStatuses = rootStore.people.employmentStatuses.loadEmploymentStatuses;
    const employmentContractCodes = rootStore.people.employmentContractCodes.employmentContractCodes.slice();
    const loadEmploymentContractCodes =
      rootStore.people.employmentContractCodes.loadEmploymentContractCodes;
    const loadAllLicenceTypes = rootStore.people.licences.loadAllLicenceTypes;
    const allLicenceTypes = rootStore.people.licences.allLicenceTypes.slice();
    const loadRoles = rootStore.people.roles.listItems;
    const allRoles = rootStore.people.roles.items.slice();
    const attachments = rootStore.peopleAttachments.attachments.slice();
    const addAttachment = rootStore.peopleAttachments.addStaffMemberAttachment;
    const loadAttachmentsForStaffMember =
      rootStore.peopleAttachments.loadStaffMemberAttachmentDetails;
    const downloadAttachmentForStaffMember =
      rootStore.peopleAttachments.downloadStaffMemberAttachment;
    const deleteAttachmentFromStaffMember = rootStore.peopleAttachments.deleteStaffMemberAttachment;
    const updateAttachmentForStaffMember = rootStore.peopleAttachments.updateStaffMemberAttachment;
    const loadAuthorisedDevices = rootStore.operations.authorisedDevices.listAuthorisedDevices;

    const [hideRecur, setHideRecur] = useState<boolean>(false);

    const staffMemberId = props.route.match.params.id!;
    const isUpdateMode = props.mode === 'update';
    const isCreateMode = props.mode === 'create';

    useEffect(() => {
      loadFleetAssets();
      loadAttachmentsForStaffMember;
      loadExclusionsForStaffMember(staffMemberId);
      loadStaffDepots();
      loadEmploymentStatuses();
      loadEmploymentContractCodes();
      loadAllLicenceTypes();
      loadEquipmentTypes();
      loadRoles({
        loadCause: ListPageLoadCause.mount,
        query: { size: 9999 },
      });
      loadAuthorisedDevices({
        size: 9999,
      });
    }, []);

    const handlePreSubmitForCreate = (staffMember: StaffMemberItem): CreateStaffMemberCommand => {
      return {
        firstName: staffMember.firstName,
        lastName: staffMember.lastName,
        dateOfBirth: staffMember.dateOfBirth,
        employeeId: staffMember.employeeId,
        hasDriverAuthorisation: staffMember.hasDriverAuthorisation,
        isWorkshopStaff: staffMember.isWorkshopStaff,
        depot: staffMember.depot.id,
        startDate: staffMember.startDate,
        email: staffMember.email,
        mobile: staffMember.mobile,
        phone: staffMember.phone,
        address: staffMember.address,
        city: staffMember.city,
        state: staffMember.state,
        postcode: staffMember.postcode,
        employmentStatusId: staffMember.employmentStatus.id,
        employmentContractCodeId: staffMember.employmentContractCode.id,
        onProbation: staffMember.onProbation,
        hasFirstAid: staffMember.hasFirstAid,
        nextOfKinName: staffMember.nextOfKinName,
        nextOfKinMobile: staffMember.nextOfKinMobile,
        nextOfKinPhone: staffMember.nextOfKinPhone,
        driverCard: staffMember.driverCard,
        cubicPin: staffMember.cubicPin,
        translinkSerialNumber: staffMember.translinkSerialNumber,
        lockerNumber: staffMember.lockerNumber,
        clothesLocker: staffMember.clothesLocker,
        urbanAmenitiesKey: staffMember.urbanAmenitiesKey,
      };
    };

    const handlePreSubmitForUpdate = (staffMember: StaffMemberItem): UpdateStaffMemberCommand => {
      setHideRecur(false);

      return {
        id: staffMemberId,
        firstName: staffMember.firstName,
        lastName: staffMember.lastName,
        dateOfBirth: staffMember.dateOfBirth,
        employeeId: staffMember.employeeId,
        hasDriverAuthorisation: staffMember.hasDriverAuthorisation,
        isWorkshopStaff: staffMember.isWorkshopStaff,
        depot: staffMember.depot.id,
        startDate: staffMember.startDate,
        email: staffMember.email,
        mobile: staffMember.mobile,
        phone: staffMember.phone,
        address: staffMember.address,
        city: staffMember.city,
        state: staffMember.state,
        postcode: staffMember.postcode,
        employmentStatusId: staffMember.employmentStatus.id,
        employmentContractCodeId: staffMember.employmentContractCode.id,
        onProbation: staffMember.onProbation,
        hasFirstAid: staffMember.hasFirstAid,
        nextOfKinName: staffMember.nextOfKinName,
        nextOfKinMobile: staffMember.nextOfKinMobile,
        nextOfKinPhone: staffMember.nextOfKinPhone,
        driverCard: staffMember.driverCard,
        cubicPin: staffMember.cubicPin,
        translinkSerialNumber: staffMember.translinkSerialNumber,
        lockerNumber: staffMember.lockerNumber,
        clothesLocker: staffMember.clothesLocker,
        urbanAmenitiesKey: staffMember.urbanAmenitiesKey,
      };
    };

    const depotProvided = (staffMember: StaffMemberItem): boolean => {
      const isValid = staffMember.depot !== undefined;
      return isValid;
    };

    const fieldsNeededBeforeMarkingActive = (
      staffMember: StaffMemberItem | undefined
    ): string[] => {
      const fields: string[] = [];
      if (staffMember) {
        if (!depotProvided(staffMember)) {
          fields.push('Depot');
        }
      }
      return fields;
    };

    const getPageDef = (updating: boolean): ICrudPageDef => {
      const primarySectionEditing = isCreateMode || updating;
      const driverAuthRemoved = (item: StaffMemberItem) => {
        const loadedHasDriverAuth = staffMember && staffMember.hasDriverAuthorisation;
        return !!loadedHasDriverAuth && !item.hasDriverAuthorisation;
      };

      return {
        primarySize: PagePrimarySize.half,
        primarySection: {
          title: isUpdateMode ? 'Manage Staff Member' : 'Create a Staff Member',
          badge: {
            label: isUpdateMode ? (staffMember && staffMember.active ? 'Active' : 'Inactive') : '',
          },
          primaryActions:
            isUpdateMode && !updating && canSaveStaffMemberData
              ? [
                  {
                    actions: [
                      {
                        actionType: ActionType.actionCollection,
                        actionGroups: [
                          {
                            actions: [
                              {
                                actionType: ActionType.modalActionButton,
                                label: 'Mark as Inactive',
                                hidden: staffMember && !staffMember.active,
                                modalSize: ShellModalSize.oneQuarter,
                                modalDef: getMarkInactiveModalDef(() =>
                                  markStaffMemberInactive(staffMemberId)
                                ),
                                icon: <PauseIcon />,
                              },
                              {
                                actionType: ActionType.actionButton,
                                label: 'Mark as Active',
                                hidden:
                                  staffMember &&
                                  (staffMember.active ||
                                    fieldsNeededBeforeMarkingActive(staffMember).length !== 0),
                                onClick: () => markStaffMemberActive(staffMemberId),
                                icon: <PlusIcon />,
                              },
                              {
                                actionType: ActionType.modalActionButton,
                                label: 'Mark as Active',
                                hidden:
                                  staffMember &&
                                  (staffMember.active ||
                                    fieldsNeededBeforeMarkingActive(staffMember).length === 0),
                                modalSize: ShellModalSize.oneQuarter,
                                modalDef: getMarkActiveModalDef(
                                  fieldsNeededBeforeMarkingActive(staffMember)
                                ),
                                icon: <PlusIcon />,
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ]
              : [],
          clearStandardSecondaryActions: true,
          secondaryActions: primarySectionEditing
            ? [
                {
                  actions: [
                    {
                      actionType: ActionType.submitActionButton,
                      level: 'primary',
                      suppressConfirmationModal: d =>
                        !isUpdateMode || !driverAuthRemoved(d.sectionValue),
                      confirmationModalSize: ShellModalSize.oneThird,
                      confirmationModalDef: getConfirmAuthRemovalModalDef(),
                    },
                    {
                      actionType: ActionType.resetActionButton,
                    },
                  ],
                },
              ]
            : [],
          panels: [
            {
              panes: [
                {
                  paneType: PaneType.formFieldsPane,
                  columnCount: 2,
                  fields: [
                    {
                      fieldType: FieldType.textField,
                      label: 'First Name',
                      dataAddr: 'firstName',
                      maxLength: 200,
                      mandatory: true,
                    },
                    {
                      fieldType: FieldType.textField,
                      label: 'Last Name',
                      dataAddr: 'lastName',
                      maxLength: 200,
                      mandatory: true,
                    },
                    {
                      fieldType: FieldType.dateField,
                      label: 'Date Of Birth',
                      dataAddr: 'dateOfBirth',
                    },
                    {
                      fieldType: FieldType.yesNoField,
                      label: 'Has First Aid Qualifications',
                      dataAddr: 'hasFirstAid',
                      mandatory: true,
                    },
                  ],
                },
              ],
            },
            {
              title: 'Employment',
              panes: [
                {
                  paneType: PaneType.formFieldsPane,
                  columnCount: 3,
                  fields: [
                    {
                      fieldType: FieldType.textField,
                      label: 'Employee Id',
                      dataAddr: 'employeeId',
                      maxLength: 4,
                      mandatory: true,
                      validateAsync: async d => {
                        if (
                          !d.fieldValue ||
                          (isUpdateMode &&
                            staffMember &&
                            staffMember.employeeId.toUpperCase() === d.fieldValue.toUpperCase())
                        ) {
                          return undefined;
                        }
                        const result = await checkForUniqueEmployeeId(d.fieldValue);
                        return result.nameExists ? `Employee Id is already in use` : undefined;
                      },
                    },
                    {
                      fieldType: FieldType.selectField,
                      label: 'Employment Status',
                      dataAddr: 'employmentStatus',
                      valueKey: 'id',
                      descriptionKey: 'description',
                      optionItems: employmentStatuses,
                      mandatory: true,
                    },
                    {
                      fieldType: FieldType.selectField,
                      label: 'Employment Contract Code',
                      dataAddr: 'employmentContractCode',
                      valueKey: 'id',
                      descriptionKey: 'description',
                      optionItems: employmentContractCodes,
                      mandatory: true,
                    },
                  ],
                },
                {
                  paneType: PaneType.formFieldsPane,
                  columnCount: 2,
                  fields: [
                    {
                      fieldType: FieldType.yesNoField,
                      label: 'On Probation',
                      dataAddr: 'onProbation',
                      mandatory: true,
                    },
                    {
                      fieldType: FieldType.yesNoField,
                      label: 'Driver Authorisation',
                      dataAddr: 'hasDriverAuthorisation',
                      mandatory: true,
                    },
                  ],
                },
                {
                  paneType: PaneType.formFieldsPane,
                  columnCount: 2,
                  fields: [
                    {
                      fieldType: FieldType.yesNoField,
                      label: 'Workshop Staff',
                      dataAddr: 'isWorkshopStaff',
                      mandatory: true,
                    },
                  ],
                },
                {
                  paneType: PaneType.formFieldsPane,
                  columnCount: 2,
                  fields: [
                    {
                      fieldType: FieldType.selectField,
                      dataAddr: 'depot',
                      label: 'Depot',
                      valueKey: 'id',
                      descriptionKey: 'description',
                      mandatory: true,
                      optionItems: staffDepots,
                      validate: d => {
                        const member = d.parentValue as StaffMemberItem;
                        const isValid = !member.active || depotProvided(member);
                        return isValid
                          ? undefined
                          : 'Depot must be specified for active staff member';
                      },
                    },
                    {
                      fieldType: FieldType.dateField,
                      label: 'Employment Start Date',
                      dataAddr: 'startDate',
                    },
                  ],
                },
                {
                  paneType: PaneType.formFieldsPane,
                  columnCount: 3,
                  fields: [
                    {
                      fieldType: FieldType.textField,
                      label: 'Driver Card Number',
                      dataAddr: 'driverCard',
                    },
                    {
                      fieldType: FieldType.textField,
                      label: 'Cubic Pin',
                      dataAddr: 'cubicPin',
                    },
                    {
                      fieldType: FieldType.textField,
                      label: 'Translink Serial Number',
                      dataAddr: 'translinkSerialNumber',
                    },
                    {
                      fieldType: FieldType.textField,
                      label: 'Locker Number',
                      dataAddr: 'lockerNumber',
                    },
                    {
                      fieldType: FieldType.textField,
                      label: 'Clothes Locker',
                      dataAddr: 'clothesLocker',
                    },
                    {
                      fieldType: FieldType.yesNoField,
                      label: 'Urban Amenities Key?',
                      dataAddr: 'urbanAmenitiesKey',
                    },
                  ],
                },
              ],
            },
            {
              title: 'Contact',
              panes: [
                {
                  paneType: PaneType.formFieldsPane,
                  columnCount: 2,
                  fields: [
                    {
                      fieldType: FieldType.textField,
                      label: 'Mobile',
                      dataAddr: 'mobile',
                      maxLength: 20,
                      mandatory: true,
                      digitsOnly: true,
                      linkTo: d => `tel:${d.fieldValue}`,
                    },
                    {
                      fieldType: FieldType.textField,
                      label: 'Phone',
                      dataAddr: 'phone',
                      maxLength: 20,
                      digitsOnly: true,
                      linkTo: d => `tel:${d.fieldValue}`,
                    },
                    {
                      fieldType: FieldType.textField,
                      label: 'Email',
                      dataAddr: 'email',
                      maxLength: 200,
                      mandatory: true,
                      linkTo: d => `mailto:${d.fieldValue}`,
                      validate: email =>
                        !!email.fieldValue && !EmailValidator.validate(email.fieldValue)
                          ? `Email must be a valid email`
                          : undefined,
                      validateAsync: async d => {
                        if (
                          !d.fieldValue ||
                          (isUpdateMode &&
                            staffMember &&
                            staffMember.email.toUpperCase() === d.fieldValue.toUpperCase())
                        ) {
                          return undefined;
                        }
                        const result = await checkForUniqueEmail(d.fieldValue);
                        return result ? 'Email must be unique' : undefined;
                      },
                    },
                  ],
                },
                {
                  paneType: PaneType.formFieldsPane,
                  hidden: () => !canViewStaffMemberAddress,
                  fields: [
                    {
                      fieldType: FieldType.textField,
                      label: 'Address',
                      dataAddr: 'address',
                      maxLength: 200,
                      mandatory: true,
                    },
                  ],
                },
                {
                  paneType: PaneType.formFieldsPane,
                  columnCount: 3,
                  fields: [
                    {
                      fieldType: FieldType.textField,
                      label: 'City',
                      dataAddr: 'city',
                      maxLength: 200,
                      mandatory: true,
                    },
                    {
                      fieldType: FieldType.textField,
                      label: 'State',
                      dataAddr: 'state',
                      maxLength: 20,
                      mandatory: true,
                    },
                    {
                      fieldType: FieldType.textField,
                      label: 'Postcode',
                      dataAddr: 'postcode',
                      maxLength: 4,
                      mandatory: true,
                      digitsOnly: true,
                    },
                  ],
                },
              ],
            },
            {
              title: 'Next of Kin',
              panes: [
                {
                  paneType: PaneType.formFieldsPane,
                  fields: [
                    {
                      fieldType: FieldType.textField,
                      label: 'Name',
                      dataAddr: 'nextOfKinName',
                      maxLength: 200,
                      mandatory: true,
                    },
                  ],
                },
                {
                  paneType: PaneType.formFieldsPane,
                  columnCount: 2,
                  fields: [
                    {
                      fieldType: FieldType.textField,
                      label: 'Mobile',
                      dataAddr: 'nextOfKinMobile',
                      maxLength: 20,
                      mandatory: true,
                      digitsOnly: true,
                      linkTo: d => `tel:${d.fieldValue}`,
                    },
                    {
                      fieldType: FieldType.textField,
                      label: 'Phone',
                      dataAddr: 'nextOfKinPhone',
                      maxLength: 20,
                      digitsOnly: true,
                      linkTo: d => `tel:${d.fieldValue}`,
                    },
                  ],
                },
              ],
            },
          ],
          onFormPreSubmit: isUpdateMode ? handlePreSubmitForUpdate : handlePreSubmitForCreate,
          onFormSubmit: isUpdateMode ? updateStaffMember : createStaffMember,
        },
        secondarySections: isCreateMode
          ? []
          : [
              {
                title: 'Clock Ons',
                panels: [
                  {
                    panes: [
                      {
                        paneType: PaneType.tablePane,
                        dataAddr: 'clockOnTimes',
                        neverEditable: true,
                        fields: [
                          {
                            fieldType: FieldType.dateTimeField,
                            dataAddr: 'clockOn',
                            label: 'Clock On Time',
                          },
                          {
                            fieldType: FieldType.dateTimeField,
                            dataAddr: 'clockOff',
                            label: 'Clock Off Time',
                          },
                          {
                            fieldType: FieldType.textField,
                            dataAddr: 'deviceName',
                            label: 'Device',
                          },
                          {
                            fieldType: FieldType.textField,
                            dataAddr: 'logId',
                            label: 'Alcolizer Log ID',
                            hidden: !isAlcoReadingEnabled,
                            columnWidth: '5rem',
                            columnAutoHide: !isAlcoReadingEnabled,
                          },
                          {
                            fieldType: FieldType.dateTimeField,
                            dataAddr: 'timestamp',
                            label: 'Timestamp',
                            hidden: !isAlcoReadingEnabled,
                            columnAutoHide: !isAlcoReadingEnabled,
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
              getSkillsSectionDef(
                canSaveStaffMemberData,
                staffMemberId,
                staffMember && staffMember.skillSpecs,
                staffMemberModel.updateSkillSpecs,
                primarySectionEditing
              ),
              getLicencesSectionDef(
                canSaveStaffMemberData,
                allLicenceTypes,
                staffMember?.licences,
                staffMemberId,
                staffMemberModel.createLicence,
                staffMemberModel.updateLicence,
                staffMemberModel.deleteLicence,
                primarySectionEditing
              ),
              getLeaveSectionDef(
                canMaintainStaffMemberOperationsRelatedItems,
                TIMEZONE,
                (hide: boolean) => setHideRecur(hide),
                hideRecur,
                staffMemberId,
                staffMemberModel.createLeave,
                staffMemberModel.updateLeave,
                staffMemberModel.deleteLeave,
                primarySectionEditing,
                staffMember && staffMember.leaves
              ),
              getEquipmentSectionDef(
                canSaveStaffMemberData,
                equipmentTypes,
                staffMemberId,
                staffMemberModel.createEquipment,
                staffMemberModel.updateEquipment,
                staffMemberModel.deleteEquipment,
                primarySectionEditing
              ),
              getAddAttachmentPanelDef(
                (staffMember && staffMember.id) || '',
                'staffmember',
                addAttachment,
                loadAttachmentsForStaffMember,
                downloadAttachmentForStaffMember,
                deleteAttachmentFromStaffMember,
                updateAttachmentForStaffMember,
                primarySectionEditing,
                attachments
              ),
              getFileNoteSectionDef(
                canMaintainStaffMemberOperationsRelatedItems,
                staffMemberId,
                staffMemberModel.createFileNote,
                staffMemberModel.updateFileNote,
                primarySectionEditing
              ),
              getExclusionSectionDef(
                canMaintainStaffMemberOperationsRelatedItems,
                fleetAssets,
                exclusionsForStaffMember,
                staffMemberId,
                createExclusion,
                deleteExclusion,
                primarySectionEditing
              ),
              getRolesSectionDef(
                canMaintainStaffMemberOperationsRelatedItems,
                allRoles,
                primarySectionEditing,
                staffMember?.roles,
                staffMemberId,
                staffMemberModel.removeStaffMemberFromRole,
                staffMemberModel.assignStaffMemberToRole
              ),
              {
                title: 'Activity Log',
                dataAddr: 'activityLog',
                panels: [
                  {
                    panes: [
                      {
                        paneType: PaneType.repeatingPane,
                        itemPanes: [
                          {
                            paneType: PaneType.customPane,
                            dataAddr: 'transactionId',
                            render: api => {
                              const log = api.data.parentValue as ActivityLogTransaction;
                              const createdOnDateTime = DateTime.fromISO(
                                log.createdOn
                              ).toLocaleString(DateTime.DATETIME_MED_WITH_SECONDS);
                              return (
                                <div className="activity-log-for-job-pane">
                                  <div className="created-by">{log.createdBy}</div>
                                  <div className="created-on">{createdOnDateTime}</div>
                                  <ul className="log-items list-unstyled">
                                    {log.items.map((item: ActivityLogDetails) => {
                                      return (
                                        <li key={item.activityLogId}>
                                          <div>
                                            <strong>{item.description}</strong>
                                          </div>
                                          <div>
                                            <em>{item.comment}</em>
                                          </div>
                                        </li>
                                      );
                                    })}
                                  </ul>
                                </div>
                              );
                            },
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
      };
    };

    const { mode } = props;
    return (
      <CrudPage
        def={({ updating }) => getPageDef(updating)}
        mode={mode}
        isEditingForbidden={!canSaveStaffMemberData}
        onLoadData={() =>
          Promise.all([
            loadStaffMember(staffMemberId),
            loadAttachmentsForStaffMember({
              aggregateId: staffMemberId,
              aggregateType: 'staffmember',
            }),
          ]).then(() => Promise.resolve())
        }
        data={{ ...staffMember, exclusions: exclusionsForStaffMember }}
        createDefaultData={{}}
      />
    );
  }
);

export default MaintainStaffMember;
