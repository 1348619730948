import { types, flow, getRoot } from 'mobx-state-tree';
import { DateTime, Interval } from 'luxon';
import { getAjax } from 'src/domain/services';
import { getZonedDaysFromInterval, intervalCompare } from 'src/infrastructure/dateUtils';
import { IRootStoreModel } from 'src/domain/entities/RootStoreModel';

type ScheduledJob = Workshop.Domain.Queries.Job.GetScheduledJobs.ScheduledJob;

const monthKeyFormat = 'yyyy-MM';

export interface IScheduledMachineryJob extends ScheduledJob {
  jobInterval: Interval;
}

export const ScheduledMachineryJobsModel = types
  .model('ScheduledMachineryJobsModel', {
    jobsByMonth: types.map(types.array(types.frozen<IScheduledMachineryJob>())),
    loadingJobsByMonth: types.map(types.boolean),
  })
  .views(self => {
    function jobs() {
      // Flatten the jobsByMonth into a map, as jobs may appear in multiple months (when the cross the month boundary)
      // Using Map removes any duplicates
      const flattenedJobs = Array.from(self.jobsByMonth.values()).reduce(
        (m, js) => js.reduce((m2, j) => m.set(j.jobId, j), m),
        new Map<string, IScheduledMachineryJob>()
      );
      return Array.from(flattenedJobs.values());
    }

    return {
      get jobs() {
        return jobs();
      },

      jobsByZonedDay(zone: string) {
        const jobsMap = jobs()
          .map(j =>
            getZonedDaysFromInterval(j.jobInterval, zone).map(d => ({
              job: j,
              day: d.toISODate(),
            }))
          )
          .reduce(
            (m, x) => x.reduce((_, y) => m.set(y.day, [...(m.get(y.day) || []), y.job]), m),
            new Map<string, IScheduledMachineryJob[]>()
          );
        jobsMap.forEach(p => p.sort((a, b) => intervalCompare(a.jobInterval, b.jobInterval)));
        return jobsMap;
      },

      get jobsLoading() {
        return !!Array.from(self.loadingJobsByMonth.keys()).length;
      },
    };
  })
  .actions(self => {
    const ajax = getAjax(self);
    const root = getRoot(self) as IRootStoreModel;

    const loadJobsForMonth = flow(function*(month: DateTime, depotId: number) {
      const monthKey = month.toFormat(monthKeyFormat);
      self.loadingJobsByMonth.set(monthKey, true);

      const start = month.startOf('month').set({ weekday: 1 });
      const end = month.endOf('month').set({ weekday: 7 });
      const domainJobs: ScheduledJob[] = yield ajax.job.listScheduledMachineryJobs(
        start,
        end,
        depotId
      );
      const monthJobs = domainJobs.map(
        j =>
          ({
            ...j,
            jobInterval: Interval.fromDateTimes(
              DateTime.fromISO(j.startDateTime),
              DateTime.fromISO(j.endDateTime)
            ),
          } as IScheduledMachineryJob)
      );

      // @ts-ignore - typing for the set function is not quite correct because we have a map of arrays
      self.jobsByMonth.set(monthKey, monthJobs);
      self.loadingJobsByMonth.delete(monthKey);
    });

    const loadJobs = (year: number, month: number, zone: string, depotId: number) => {
      const start = DateTime.fromObject({ year, month, zone });
      loadJobsForMonth(start, depotId);
    };

    const changeSelectedWorkshopDepotId = (
      selectedWorkshopDepotId: number,
      zonedMonthToDisplay: DateTime
    ) => {
      root.history.push(
        `/workshop/jobs/machinery-schedule?month=${zonedMonthToDisplay.toFormat(
          'yyyy-MM'
        )}&depotId=${selectedWorkshopDepotId}`
      );
    };

    return { loadJobs, changeSelectedWorkshopDepotId };
  });

type IModelType = typeof ScheduledMachineryJobsModel.Type;
export interface IScheduledMachineryJobsModel extends IModelType {}
