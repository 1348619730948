import { DateTime, Interval } from 'luxon';
import { flow, types, cast } from 'mobx-state-tree';
import { getAjax } from 'src/domain/services';

type ScheduledLeave = People.Domain.Queries.ListScheduledLeave.ScheduledLeaveItem;

export interface IScheduledLeave extends ScheduledLeave {
  leaveInterval: Interval;
}

export const ScheduledLeaveModel = types
  .model('ScheduledLeaveModel', {
    leave: types.array(types.frozen<IScheduledLeave>()),
    loadingLeave: false,
  })
  .actions(self => {
    const ajax = getAjax(self);

    const loadLeaveForWeek = flow(function*(
      start: DateTime,
      leaveTypes: number[],
      leaveStatuses: number[],
      staffMemberIds: string[],
      roleIds: string[],
      depotIds: number[]
    ) {
      self.loadingLeave = true;

      const end = start.plus({ days: 7 });
      const domainWeekLeave: Common.Dtos.ListResult<IScheduledLeave> = yield ajax.people.leave.listScheduledLeave(
        {
          startDate: start.toISODate(),
          endDate: end.toISODate(),
          leaveStatuses: leaveStatuses,
          leaveTypes: leaveTypes,
          staffMembers: staffMemberIds,
          roles: roleIds,
          depots: depotIds,
        }
      );
      self.leave = cast(
        domainWeekLeave.items.map(
          j =>
            ({
              ...j,
              leaveInterval: Interval.fromDateTimes(
                DateTime.fromISO(j.leaveStart),
                DateTime.fromISO(j.leaveEnd)
              ),
            } as IScheduledLeave)
        )
      );

      self.loadingLeave = false;
    });

    const loadLeave = (
      weekYear: number,
      weekNumber: number,
      zone: string,
      leaveTypes: number[],
      leaveStatuses: number[],
      staffMemberIds: string[],
      roleIds: string[],
      depotIds: number[]
    ) => {
      const start = DateTime.fromObject({ weekYear, weekNumber, weekday: 1, zone });
      loadLeaveForWeek(start, leaveTypes, leaveStatuses, staffMemberIds, roleIds, depotIds);
    };

    return { loadLeave };
  });

export default ScheduledLeaveModel;
