import { flow, types, getRoot } from 'mobx-state-tree';
import { getAjax } from 'src/domain/services';
import { IAutocompleteResult, NotificationType } from 'src/domain/baseTypes';
import { IRootStoreModel } from 'src/domain/entities/RootStoreModel';
import { buildListPageApiSearchModelTypedQuery } from 'src/domain/modelBuilders/buildListPageApiSearchModel';

type PartListItem = Workshop.Domain.Queries.Parts.PartListItem;
type PartListItemForDropdown = Workshop.Domain.Queries.Parts.ListPartsForDropdown.PartListItemForDropdown;
type PartItem = Workshop.Domain.Queries.Parts.PartItem;
type ListPartsQuery = Workshop.Domain.Queries.Parts.ListParts.ListPartsQuery;
type GenerateStockOnHandExcelQuery = Workshop.Domain.Queries.Parts.GenerateStockOnHandExcel.GenerateStockOnHandExcelQuery;

export interface IMatchingPart extends PartListItemForDropdown {
  searchText: string; // for what is searched when the user types
}

const partsListModel = buildListPageApiSearchModelTypedQuery<ListPartsQuery>()('PartsModel', d =>
  d.ajax.workshop.part.listParts(d.query)
);

const searchPartsModel = types.model('SearchPartsModel').actions(self => {
  const ajax = getAjax(self);
  let lastSearch: string | undefined;
  let lastResult: IAutocompleteResult<IMatchingPart>;

  const searchParts = flow(function*(search: string, includeLatestPrice?: boolean) {
    if (search === lastSearch) {
      return lastResult;
    }
    const result: Common.Dtos.ListResult<PartListItem> = yield ajax.workshop.part
      .listPartsForDropdown({
        search: search,
        size: 10,
        includeLatestPrice: includeLatestPrice === undefined ? false : includeLatestPrice,
      })
      .toPromise();
    lastSearch = search;
    lastResult = {
      options: result.items.map(
        p =>
          ({
            ...p,
            searchText: `${p.partNumber} ${p.description}`,
          } as IMatchingPart)
      ),
    };
    return lastResult;
  });

  return { searchParts };
});

const PartsReportModel = types.model('PartsReportModel', {}).actions(self => {
  const ajax = getAjax(self);
  const root = getRoot(self) as IRootStoreModel;

  const generateStockOnHandExcel: (
    query: Partial<GenerateStockOnHandExcelQuery>
  ) => Promise<Blob> = flow(function*(query) {
    root.notifications.addNotification(`The file is being generated ...`, {
      type: NotificationType.info,
    });

    return yield ajax.workshop.part.generateStockOnHandExcel(query);
  });

  return {
    generateStockOnHandExcel,
  };
});

const findPartsModel = types.model('FindPartsModel', {}).actions(self => {
  const ajax = getAjax(self);

  const findParts = flow(function*(partIds: string[]) {
    if (!partIds || !partIds.length) {
      return { options: [] };
    }
    const parts: PartItem[] = yield ajax.workshop.part.findParts(partIds);
    return { options: parts } as IAutocompleteResult<PartItem>;
  });

  return {
    findParts,
  };
});

export const PartsModel = types.compose(
  partsListModel,
  searchPartsModel,
  PartsReportModel,
  findPartsModel
);
