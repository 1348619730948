import { TechSpecDataType } from 'src/api/enums';
import { IFilterValues } from 'src/views/routes/operations/job/allocations/ganttCalendar/baseTypes';

type AssetItem = Common.Queries.Workshop.GetFleetAssetList.AssetItem;
type TechSpecItem = Common.Dtos.TechSpecItem;

export type TextTechSpecItem = {
  id: number;
  fromDataAddr: string;
  value: string | undefined;
  description: string;
};

export type DropdownTechSpecItem = {
  id: number;
  fromDataAddr: string;
  value: string | undefined;
  description: string;
};

export type BoolTechSpecItem = {
  id: number;
  fromDataAddr: string;
  value: string | undefined;
  description: string;
};

type TextTechSpecValue = {
  id: number;
  value: string | undefined;
};

type DropdownTechSpecValue = {
  id: number;
  value: string | undefined;
};

type BoolTechSpecValue = {
  id: number;
  value: string | undefined;
};

export interface IShortTechSpecRequirement {
  techSpecId: number;
  value: string;
}

export interface IFullTechSpecRequirement extends IShortTechSpecRequirement {
  dataType: TechSpecDataType;
  description: string;
}

export type ISplittedTechSpecRequirements = IBoolTechSpecRequirements & ITextTechSpecRequirements;

export interface IBoolTechSpecRequirements {
  [key: string]: string | unknown;
}

export interface ITextTechSpecRequirements {
  [key: string]: string | unknown;
}

export interface IDropdownTechSpecRequirements {
  [key: string]: string | unknown;
}

export interface ISplittedTechSpecs {
  boolTechSpecs: BoolTechSpecItem[];
  textTechSpecs: TextTechSpecItem[];
  dropdownTechSpecs: DropdownTechSpecItem[];
}

export function doesAssetHaveRequiredBoolTechSpecs(
  asset: AssetItem,
  techSpecRequirements: BoolTechSpecValue[]
): boolean {
  if (!!techSpecRequirements) {
    const result = techSpecRequirements.every(requiredTechSpec => {
      const matchingSkill = asset.opsTechSpecs.find(
        techSpec => techSpec.techSpecId === requiredTechSpec.id
      );
      return (
        !!matchingSkill &&
        requiredTechSpec.value !== undefined &&
        matchingSkill.value === requiredTechSpec.value.toString()
      );
    });
    return result;
  }
  return true;
}

export function doesAssetHaveRequiredTextTechSpecs(
  asset: AssetItem,
  techSpecRequirementsIds: TextTechSpecValue[]
): boolean {
  if (!!techSpecRequirementsIds) {
    const result = techSpecRequirementsIds.every(requiredTechSpec => {
      const matchingSkill = asset.opsTechSpecs.find(
        techSpec => techSpec.techSpecId === requiredTechSpec.id
      );
      return !!matchingSkill && matchingSkill.value === requiredTechSpec.value;
    });
    return result;
  }
  return true;
}

export function doesAssetHaveRequiredDropdownTechSpecs(
  asset: AssetItem,
  techSpecRequirementsIds: DropdownTechSpecValue[]
): boolean {
  if (!!techSpecRequirementsIds) {
    const result = techSpecRequirementsIds.every(requiredTechSpec => {
      const matchingSkill = asset.opsTechSpecs.find(
        techSpec => techSpec.techSpecId === requiredTechSpec.id
      );
      return !!matchingSkill && matchingSkill.value === requiredTechSpec.value;
    });
    return result;
  }
  return true;
}

export function doesAssetHaveRequiredTechSpecs(
  asset: AssetItem,
  techSpecRequirements: IShortTechSpecRequirement[]
): boolean {
  if (!!techSpecRequirements) {
    const result = techSpecRequirements.every(requiredTechSpec => {
      const matchingSkill = asset.opsTechSpecs.find(
        techSpec => techSpec.techSpecId === requiredTechSpec.techSpecId
      );
      return !!matchingSkill && matchingSkill.value === requiredTechSpec.value;
    });
    return result;
  }
  return true;
}

export function getTextTechSpecs<T extends TechSpecItem | IFullTechSpecRequirement>(
  techSpecs: T[]
): TextTechSpecItem[] {
  const textSpecs = techSpecs.filter(t => t.dataType === TechSpecDataType.String);
  const result: TextTechSpecItem[] = textSpecs.map(x => {
    const id = isTechSpecItem(x)
      ? (x.id as number)
      : isFullTechSpecRequirement(x)
      ? x.techSpecId
      : 0;
    const value = isFullTechSpecRequirement(x) ? x.value : undefined;
    return {
      id: id,
      fromDataAddr: `spec-${id}`,
      value: value,
      description: x.description,
    };
  });
  return result;
}

export function getDropdownTechSpecs<T extends TechSpecItem | IFullTechSpecRequirement>(
  techSpecs: T[]
): DropdownTechSpecItem[] {
  const dropdownSpecs = techSpecs.filter(t => t.dataType === TechSpecDataType.Dropdown);
  const result: DropdownTechSpecItem[] = dropdownSpecs.map(x => {
    const id = isTechSpecItem(x)
      ? (x.id as number)
      : isFullTechSpecRequirement(x)
      ? x.techSpecId
      : 0;
    const value = isFullTechSpecRequirement(x) ? x.value : undefined;
    return {
      id: id,
      fromDataAddr: `spec-${id}`,
      value: value,
      description: x.description,
    };
  });
  return result;
}

function isTechSpecItem(item: TechSpecItem | IFullTechSpecRequirement): item is TechSpecItem {
  return (item as TechSpecItem).id !== undefined;
}

function isFullTechSpecRequirement(
  item: TechSpecItem | IFullTechSpecRequirement
): item is IFullTechSpecRequirement {
  return (item as IFullTechSpecRequirement).techSpecId !== undefined;
}

export function getBoolTechSpecs<T extends TechSpecItem | IFullTechSpecRequirement>(
  techSpecs: T[]
): BoolTechSpecItem[] {
  return techSpecs
    .filter(t => t.dataType === TechSpecDataType.Bool)
    .map(x => {
      const id = isTechSpecItem(x)
        ? (x.id as number)
        : isFullTechSpecRequirement(x)
        ? x.techSpecId
        : 0;
      const value = isFullTechSpecRequirement(x) ? x.value : undefined;
      return {
        id: id,
        fromDataAddr: `spec-${id}`,
        value: value,
        description: x.description,
      };
    });
}

export function getTextTechSpecValues(
  techSpecs: TextTechSpecItem[],
  values: IFilterValues | ITextTechSpecRequirements
): TextTechSpecValue[] {
  const textSpecValues = techSpecs
    .map(t => ({
      id: t.id,
      value: values[t.fromDataAddr] as string | undefined,
    }))
    .filter(x => !!x.value);
  return textSpecValues;
}

export function getDropdownTechSpecValues(
  techSpecs: DropdownTechSpecItem[],
  values: IFilterValues | IDropdownTechSpecRequirements
): DropdownTechSpecValue[] {
  const textSpecValues = techSpecs
    .map(t => ({
      id: t.id,
      value: values[t.fromDataAddr] as string | undefined,
    }))
    .filter(x => !!x.value);
  return textSpecValues;
}

export function getBoolTechSpecValues(
  techSpecs: BoolTechSpecItem[],
  values: IFilterValues | ITextTechSpecRequirements
): BoolTechSpecValue[] {
  const boolSpecValues = techSpecs
    .map(t => ({
      id: t.id,
      value: values[t.fromDataAddr] as string | undefined,
    }))
    .filter(x => x.value !== undefined);
  return boolSpecValues;
}

export function splitTechSpecs<T extends TechSpecItem | IFullTechSpecRequirement>(
  techSpecRequirements: T[]
): ISplittedTechSpecs {
  const boolTechSpecs = getBoolTechSpecs(techSpecRequirements || []);
  const textSpecs = getTextTechSpecs(techSpecRequirements || []);
  const dropdownSpecs = getDropdownTechSpecs(techSpecRequirements || []);
  const result = {
    boolTechSpecs: boolTechSpecs,
    textTechSpecs: textSpecs,
    dropdownTechSpecs: dropdownSpecs,
  };
  return result;
}

export function splitTechSpecRequirements(
  techSpecRequirements: IFullTechSpecRequirement[]
): ISplittedTechSpecRequirements {
  const splittedTechSpecs = splitTechSpecs(techSpecRequirements);
  const result = {};
  splittedTechSpecs.boolTechSpecs.forEach(x => {
    result[x.fromDataAddr] = x.value;
  });
  splittedTechSpecs.textTechSpecs.forEach(x => {
    result[x.fromDataAddr] = x.value;
  });
  splittedTechSpecs.dropdownTechSpecs.forEach(x => {
    result[x.fromDataAddr] = x.value;
  });
  return result;
}

export function consolidateTechSpecRequirements(
  splittedTechSpecRequirements: ISplittedTechSpecRequirements,
  splittedTechSpecs: ISplittedTechSpecs
): IShortTechSpecRequirement[] {
  const boolTechSpecValues = getBoolTechSpecValues(
    splittedTechSpecs.boolTechSpecs,
    splittedTechSpecRequirements
  );
  const textSpecValues = getTextTechSpecValues(
    splittedTechSpecs.textTechSpecs,
    splittedTechSpecRequirements
  );
  const dropdownSpecValues = getDropdownTechSpecValues(
    splittedTechSpecs.dropdownTechSpecs,
    splittedTechSpecRequirements
  );
  return boolTechSpecValues
    .map(x => {
      return { techSpecId: x.id, value: x.value?.toString() as string };
    })
    .concat(
      textSpecValues.map(x => {
        return { techSpecId: x.id, value: x.value as string };
      })
    )
    .concat(
      dropdownSpecValues.map(x => {
        return { techSpecId: x.id, value: x.value as string };
      })
    );
}

export function applyTechSpecRequirementsFilter(
  assets: Array<AssetItem>,
  techSpecRequirements: IShortTechSpecRequirement[]
): Array<AssetItem> {
  if (!!techSpecRequirements) {
    return assets.filter(asset =>
      doesAssetHaveRequiredTechSpecs(asset as AssetItem, techSpecRequirements)
    );
  }

  return assets;
}
