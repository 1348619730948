import { types, flow, getRoot } from 'mobx-state-tree';
import { getAjax } from 'src/domain/services/storeEnvironment';
import { IRootStoreModel } from 'src/domain/entities/RootStoreModel';
import { NotificationType } from 'src/domain';
import { IAutocompleteResult } from 'src/domain/baseTypes';

type BoardingPointListItem = Operations.Domain.Queries.SearchBoardingPoint.BoardingPointListItem;
type BoardingPointItem = Operations.Domain.Queries.GetBoardingPoint.BoardingPointItem;
type States = Operations.Domain.AggregatesModel.BoardingPointAggregate.States;
type CreateBoardingPointCommand = Operations.Domain.Commands.BoardingPoint.CreateBoardingPointCommand;
type UpdateBoardingPointCommand = Operations.Domain.Commands.BoardingPoint.UpdateBoardingPointCommand;

export const BoardingPointModel = types
  .model('BoardingPointModel', {
    boardingPoint: types.maybe(types.frozen<BoardingPointItem>()),
    foundBoardingPoints: types.array(types.frozen<BoardingPointItem>()),
    states: types.array(types.frozen<States>()),
  })
  .actions(self => {
    const ajax = getAjax(self);
    const root = getRoot(self) as IRootStoreModel;

    let lastSearch: string | undefined;
    let lastResult: IAutocompleteResult<BoardingPointListItem>;

    const searchBoardingPoints = flow(function*(search: string) {
      if (search === lastSearch) {
        return lastResult;
      }
      const boardingPoints: Common.Dtos.ListResult<BoardingPointListItem> = yield ajax.sales.boardingPoints.searchBoardingPoints(
        search
      );
      lastSearch = search;
      lastResult = {
        options: boardingPoints.items,
      };
      return lastResult;
    });

    const createBoardingPoint = flow(function*(
      command: CreateBoardingPointCommand,
      suppressNavigate?: boolean
    ) {
      const id: string = yield ajax.sales.boardingPoints.createBoardingPoint(command);
      root.notifications.addNotification(`Successfully created boarding point ${command.name}`, {
        type: NotificationType.success,
      });
      self.boardingPoint = undefined;
      if (!suppressNavigate) {
        root.history.push(`/sales/boarding-points/${id}`);
      }
      return id;
    });

    const checkForUniqueName = flow(function*(name: string) {
      return yield ajax.sales.boardingPoints.checkForUniqueName(name);
    });

    const loadBoardingPoint = flow(function*(boardingPointId: string) {
      self.boardingPoint = yield ajax.sales.boardingPoints.viewBoardingPoint(boardingPointId);
    });

    const findBoardingPoints = flow(function*(boardingPointIds: string[]) {
      self.foundBoardingPoints = yield ajax.sales.boardingPoints.findBoardingPoints(
        boardingPointIds
      );
    });

    const updateBoardingPoint = flow(function*(command: UpdateBoardingPointCommand) {
      yield ajax.sales.boardingPoints.updateBoardingPoint(command);

      root.notifications.addNotification(`Successfully updated Boarding Point ${command.name}`, {
        type: NotificationType.success,
      });
      yield loadBoardingPoint(command.id);
    });

    const deleteBoardingPoint = flow(function*(boardingPointId: string) {
      yield ajax.sales.boardingPoints.deleteBoardingPoint(boardingPointId);

      root.notifications.addNotification(`Successfully deleted Boarding Point`, {
        type: NotificationType.success,
      });
      self.boardingPoint = undefined;
      root.history.push(`/sales/boarding-points`);
    });

    const loadStates = flow(function*() {
      self.states = yield ajax.sales.boardingPoints.loadStates();
    });

    return {
      searchBoardingPoints,
      createBoardingPoint,
      checkForUniqueName,
      loadBoardingPoint,
      findBoardingPoints,
      updateBoardingPoint,
      deleteBoardingPoint,
      loadStates,
    };
  });
