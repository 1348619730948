import { TeacherIcon, WarnIcon } from 'src/images/icons';
import { JobType } from 'src/api/enums';
import {
  IModalActionButtonDef,
  ActionType,
  ShellModalSize,
  PaneType,
} from 'src/views/definitionBuilders/types';
import { mayJobTypeHaveRouteTraining } from 'src/views/routes/operations/shared/jobTypeHelpers';

export default function getCreateRouteTrainingNotAllowedButtonDef(
  jobType: JobType
): IModalActionButtonDef {
  return {
    actionType: ActionType.modalActionButton,
    label: 'Create Route Training Job',
    icon: <TeacherIcon fixedWidth />,
    hidden: !mayJobTypeHaveRouteTraining(jobType),
    modalSize: ShellModalSize.oneQuarter,
    modalDef: modalDefApi => ({
      title: 'Create Route Training Job',
      asForm: true,
      panels: [
        {
          panes: [
            {
              paneType: PaneType.customPane,
              render: () => (
                <p>
                  Please enter the Schedule times before creating a Route Training job.
                  <WarnIcon fixedWidth size="lg" />
                </p>
              ),
            },
          ],
        },
      ],
      secondaryActions: [
        {
          actions: [
            {
              actionType: ActionType.closeModalActionButton,
              label: 'Ok',
              level: 'primary',
            },
          ],
        },
      ],
    }),
  };
}
