import { IAjax } from 'src/infrastructure/ajax';
import { workshopUrls } from '../../apiUrls';

type CreateRosterTemplateCommand = Workshop.Domain.Commands.RosterTemplate.CreateRosterTemplate.CreateRosterTemplateCommand;
type UpdateRosterTemplateCommand = Workshop.Domain.Commands.RosterTemplate.UpdateRosterTemplate.UpdateRosterTemplateCommand;
type RosterTemplateItem = Workshop.Domain.Queries.RosterTemplate.GetRosterTemplate.RosterTemplateItem;
type ListRosterTemplatesQuery = Workshop.Domain.Queries.RosterTemplate.ListRosterTemplates.ListRosterTemplatesQuery;
type RosterTemplateListItem = Workshop.Domain.Queries.RosterTemplate.ListRosterTemplates.RosterTemplateListItem;

export default function init(ajax: IAjax) {
  function createRosterTemplate(command: CreateRosterTemplateCommand) {
    return ajax
      .post(workshopUrls.rosterTemplateUrls.createRosterTemplate(), command)
      .toPromise()
      .then<string>(r => r.response);
  }

  function checkForUniqueRosterTemplateName(rosterName: string) {
    return ajax
      .get(workshopUrls.rosterTemplateUrls.checkForUniqueRosterTemplateName(rosterName))
      .toPromise()
      .then<Common.Dtos.UniqueNameCheckResultDto>(r => r.response);
  }

  function updateRosterTemplate(command: UpdateRosterTemplateCommand) {
    return ajax
      .put(workshopUrls.rosterTemplateUrls.updateRosterTemplate(command.rosterTemplateId), command)
      .toPromise();
  }

  function getRosterTemplate(rosterTemplateId: string) {
    return ajax
      .get(workshopUrls.rosterTemplateUrls.getRosterTemplate(rosterTemplateId))
      .toPromise()
      .then<RosterTemplateItem>(r => r.response);
  }

  function deleteRosterTemplate(rosterTemplateId: string) {
    return ajax
      .httpDelete(workshopUrls.rosterTemplateUrls.getRosterTemplate(rosterTemplateId))
      .toPromise();
  }

  function listRosterTemplates(query: Partial<ListRosterTemplatesQuery>) {
    return ajax
      .get(workshopUrls.rosterTemplateUrls.listRosterTemplates(query))
      .map(r => r.response as Common.Dtos.ListResult<RosterTemplateListItem>);
  }

  return {
    rosterTemplate: {
      createRosterTemplate,
      checkForUniqueRosterTemplateName,
      updateRosterTemplate,
      getRosterTemplate,
      deleteRosterTemplate,
      listRosterTemplates,
    },
  };
}
