import { Component } from 'react';
import { FilterIcon } from 'src/images/icons';
import { shallowMemoize } from 'src/infrastructure/memoizeOne';
import { ShellModalSize } from 'src/views/components/Shell/ShellModal';
import {
  IActionMeta,
  ActionType,
  IActionData,
  ISimpleFilterActionButtonDef,
  IActionDef,
  FieldDefs,
  IPanelDef,
  PaneType,
} from 'src/views/definitionBuilders/types';
import ModalActionButton from './ModalActionButton';

interface ISimpleFilterActionButtonProps {
  actionDef: ISimpleFilterActionButtonDef;
  actionMeta: IActionMeta;
  actionData: IActionData;
}

class SimpleFilterActionButton extends Component<ISimpleFilterActionButtonProps> {
  private readonly getDefaultFilterDef = shallowMemoize(
    (filterFields: FieldDefs[]): IPanelDef[] => {
      return [
        {
          panes: [
            {
              paneType: PaneType.formFieldsPane,
              fields: filterFields,
            },
          ],
        },
      ];
    }
  );

  render() {
    const { actionDef, actionMeta, actionData } = this.props;
    return (
      <div className="simple-filter-action-button-component variable-size">
        <ModalActionButton
          actionDef={{
            ...(actionDef as IActionDef),
            actionType: ActionType.modalActionButton,
            label: actionDef.label || 'Filter',
            icon: <FilterIcon />,
            modalSize: actionDef.filterSize || ShellModalSize.oneThird,
            modalDef: defApi => ({
              title: actionDef.label || 'Filter',
              panels: actionDef.filterDef
                ? actionDef.filterDef(defApi)
                : this.getDefaultFilterDef(actionDef.filterFields || []),
              asForm: true,
              suppressLeavePrompt: true,
              explicitData: actionData.actionValue || {},
              onFormSubmit: actionDef.onFormSubmit,
              secondaryActions: [
                {
                  actions: [
                    {
                      actionType: ActionType.submitActionButton,
                      label: 'Apply',
                      level: 'primary',
                    },
                    {
                      actionType: ActionType.resetActionButton,
                      label: 'Default',
                      resetToData: actionDef.onFormReset,
                    },
                  ],
                },
              ],
            }),
          }}
          actionMeta={actionMeta}
          actionData={actionData}
          showCloseButton
        />
      </div>
    );
  }
}

export default SimpleFilterActionButton;
