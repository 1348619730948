import { types, flow, getRoot } from 'mobx-state-tree';
import { getAjax } from 'src/domain/services';
import { IRootStoreModel } from 'src/domain/entities/RootStoreModel';
import { JobPdfModel } from './JobPdfModel';
import { NotificationType } from 'src/domain';

type JobItem = Workshop.Domain.Queries.Job.PrintJobQuery.JobItem;
type JobDetailsDto = Workshop.Domain.Queries.Job.JobDetailsDto;
type JobBusUsage = Workshop.Domain.Queries.GetJobsProgressUsage.JobBusUsageDto;

export const JobModel = types
  .model('JobModel', {
    printJobItem: types.maybe(types.frozen<JobItem>()),
    job: types.maybe(types.frozen<JobDetailsDto>()),
    jobPdf: types.optional(JobPdfModel, {}),
    busUsages: types.array(types.frozen<JobBusUsage>()),
    previousAndNextJobProgresses: types.maybe(
      types.frozen<Common.Queries.GetPreviousAndNextJobProgress.PreviousAndNextJobProgressResult>()
    ),
  })
  .actions(self => {
    const ajax = getAjax(self);
    const root = getRoot(self) as IRootStoreModel;

    const createJob = flow(function*(command: Workshop.Domain.Commands.Job.CreateJobCommand) {
      const id = yield ajax.job.createJob(command);
      self.job = undefined;
      root.history.push(`/workshop/jobs/${id}`);
    });

    const loadPrintJobSheet = flow(function*(jobId: string) {
      self.printJobItem = yield ajax.job.loadPrintJobSheet(jobId);
    });

    const loadJob = flow(function*(id: string) {
      self.job = yield ajax.job.loadJob(id);

      if (self!.job && self.job.jobConflicts && self.job.jobConflicts.length > 0) {
        root.notifications.addNotification('Job conflicts with Operations job.', {
          type: NotificationType.warn,
          autoClose: false,
        });
      }
    });

    const updateJob = flow(function*(command: Workshop.Domain.Commands.Job.UpdateJobCommand) {
      yield ajax.job.updateJob(command);
      yield loadJob(command.jobId);
    });

    const markAsInvoiced = flow(function*(id: string) {
      yield ajax.job.markAsInvoiced(id);
      yield loadJob(id);
    });

    const loadBusUsages = flow(function*(jobId: string) {
      self.busUsages = yield ajax.job.loadBusUsages(jobId);
    });

    const updateBusUsages = flow(function*(
      command: Workshop.Domain.Commands.Job.UpdateWorkshopJobProgressCommand
    ) {
      yield ajax.job.updateBusUsages(command);
      yield loadBusUsages(command.jobId);
    });

    const loadPreviousAndNextJobProgress = flow(function*(jobId: string) {
      self.previousAndNextJobProgresses = yield ajax.job.getPreviousAndNextProgresses(jobId);
    });

    return {
      createJob,
      loadPrintJobSheet,
      loadJob,
      updateJob,
      markAsInvoiced,
      loadBusUsages,
      updateBusUsages,
      loadPreviousAndNextJobProgress,
    };
  });

type IJobModelType = typeof JobModel.Type;
export interface IJobModel extends IJobModelType {}
