import { saveAs } from 'file-saver';
import { DateTime } from 'luxon';
import { observer } from 'mobx-react';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { allPartType, PartType } from 'src/api/enums';
import { useRootStore } from 'src/domain/entities/RootStoreModel';
import { ClipboardIcon, ExcelIcon, ListIcon } from 'src/images/icons';
import { formatNumber } from 'src/infrastructure/formattingUtils';
import { ListPage } from 'src/views/components/Page';
import { IListPageDef } from 'src/views/components/Page/pages/ListPage';
import { TaskCardItem, toActionLinkDef } from 'src/views/components/TaskCard';
import {
  ActionType,
  FieldDefs,
  FieldType,
  IFieldData,
  PagePrimarySize,
} from 'src/views/definitionBuilders/types';
import withQueryParams, { IQueryParamsProps } from 'src/views/hocs/withQueryParams';
import PrimaryTitle from 'src/views/components/Page/PrimaryTitle/PrimaryTitle';

type PartListItem = Workshop.Domain.Queries.Parts.PartListItem;
type GenerateStockOnHandExcelQuery = Workshop.Domain.Queries.Parts.GenerateStockOnHandExcel.GenerateStockOnHandExcelQuery;

const ListParts: React.FC<IQueryParamsProps<GenerateStockOnHandExcelQuery>> = observer(
  (props: IQueryParamsProps<GenerateStockOnHandExcelQuery>) => {
    const rootStore = useRootStore();
    const partsModel = rootStore.workshop.parts;
    const partCategoriesModel = rootStore.workshop.partCategories;
    const supplierModel = rootStore.workshop.supplier;

    useEffect(() => {
      partCategoriesModel.loadPartCategoryListItems();
    }, []);

    const getPageDef = (): IListPageDef => {
      const filterFieldDefs: FieldDefs[] = [
        {
          fieldType: FieldType.yesNoField,
          label: 'Active',
          dataAddr: 'isActive',
        },
        {
          fieldType: FieldType.selectMultiField,
          label: 'Type',
          dataAddr: 'partTypeIds',
          useValueOnly: true,
          valueKey: 'value',
          descriptionKey: 'description',
          optionItems: allPartType,
        },
        {
          fieldType: FieldType.selectMultiField,
          label: 'Category',
          dataAddr: 'categories',
          useValueOnly: true,
          valueKey: 'id',
          descriptionKey: 'description',
          optionItems: partCategoriesModel.partCategoryListItems,
        },
        {
          fieldType: FieldType.textField,
          label: 'Location',
          dataAddr: 'location',
        },
        {
          fieldType: FieldType.selectAsyncMultiField,
          label: 'Preferred Supplier',
          dataAddr: 'preferredSuppliers',
          useValueOnly: true,
          valueKey: 'id',
          descriptionKey: 'name',
          loadOptionItems: supplierModel.searchSuppliers,
          loadItems: (ids, d) => supplierModel.findSuppliers(ids),
          autoload: true,
        },
        {
          fieldType: FieldType.yesNoField,
          label: 'Included In Stocktake',
          dataAddr: 'isIncludedInStocktake',
        },
        {
          fieldType: FieldType.yesNoField,
          label: 'Requires MSDS',
          dataAddr: 'requiresMsds',
        },
        {
          fieldType: FieldType.yesNoField,
          label: 'Has Pending Orders',
          dataAddr: 'hasPendingOrders',
        },
        {
          fieldType: FieldType.selectMultiField,
          label: 'Depot',
          dataAddr: 'workshopDepots',
          optionItems: rootStore.workshopStartup.workshopDepots,
          useValueOnly: true,
          valueKey: 'id',
          descriptionKey: 'description',
        },
      ];

      return {
        primaryTitle: <PrimaryTitle title="Parts"></PrimaryTitle>,
        primarySize: PagePrimarySize.full,
        onLoadData: d => partsModel.listItems({ ...d }),
        externalSearch: true,
        createLink: rootStore.account.isWorkshopDepartmentMember
          ? TaskCardItem.workshop.createPart
          : undefined,
        hasMoreData: partsModel.hasMoreItems,
        primaryActions: [
          {
            actions: [
              {
                actionType: ActionType.actionCollection,
                actionGroups: [
                  {
                    actions: [
                      {
                        ...toActionLinkDef(
                          TaskCardItem.workshop.partsTransactions(
                            DateTime.local(),
                            DateTime.local(),
                            rootStore.workshopStartup.defaultWorkshopDepot?.id
                          )
                        ),
                        icon: <ListIcon fixedWidth />,
                      },
                      {
                        ...toActionLinkDef(
                          TaskCardItem.workshop.partsUsedTransactions(
                            DateTime.local(),
                            DateTime.local(),
                            rootStore.workshopStartup.defaultWorkshopDepot?.id
                          )
                        ),
                        icon: <ListIcon fixedWidth />,
                      },
                      {
                        hidden: !rootStore.account.isWorkshopDepartmentMember,
                        ...toActionLinkDef(
                          TaskCardItem.workshop.recordStocktake(
                            rootStore.workshopStartup.defaultWorkshopDepot?.id || 0
                          )
                        ),
                        icon: <ClipboardIcon fixedWidth />,
                      },
                    ],
                  },
                  {
                    actions: [
                      {
                        actionType: ActionType.actionButton,
                        label: 'Export to Excel',
                        icon: <ExcelIcon fixedWidth />,
                        onClick: downloadStockOnHandExcel,
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
        primaryFields: [
          {
            fieldType: FieldType.textField,
            dataAddr: 'partNumber',
            label: 'Part Number',
            orderBy: 'partNumber',
            linkTo: d => `/workshop/parts/${d.parentValue.id}`,
            columnWidth: '10rem',
          },
          {
            fieldType: FieldType.textField,
            dataAddr: 'description',
            label: 'Description',
            orderBy: 'description',
            columnWidth: 'auto',
          },
          {
            fieldType: FieldType.textField,
            dataAddr: 'partType.description',
            label: 'Type',
            orderBy: 'partType',
            columnWidth: 'auto',
          },
          {
            fieldType: FieldType.textField,
            dataAddr: 'category',
            label: 'Part Category',
            orderBy: 'GeneralLedgerCode',
            columnWidth: '10rem',
          },
          {
            fieldType: FieldType.textField,
            dataAddr: 'preferredSupplier.name',
            label: 'Preferred Supplier',
            hidden: d =>
              !(
                d.parentValue &&
                d.parentValue.preferredSupplier &&
                d.parentValue.preferredSupplier.id
              ),
            linkTo: d => `/workshop/suppliers/${d.parentValue.preferredSupplier.id}`,
            orderBy: 'PreferredSupplierName',
          },
          {
            fieldType: FieldType.readonlyField,
            dataAddr: 'pendingPurchaseOrders',
            label: 'Pending Orders',
            orderBy: 'FirstPendingPONumber',
            columnWidth: '8rem',
            formatReadonly: data => {
              var partListItem = data.parentValue as PartListItem;
              return (
                <div>
                  {partListItem.pendingPurchaseOrders.map((po, i) => (
                    <div key={i}>
                      <Link to={`/workshop/purchase-orders/${po.id}`}>
                        {po.purchaseOrderNumber}
                      </Link>
                    </div>
                  ))}
                </div>
              );
            },
          },
          {
            fieldType: FieldType.readonlyField,
            dataAddr: 'partLocations',
            label: 'Location',
            columnWidth: '10rem',
            formatReadonly: data => formatLocationDataField(data, 'location', 'text'),
          },
          {
            fieldType: FieldType.readonlyField,
            hidden: d => d.parentValue.partType.id === PartType.Service,
            label: 'Current Stock',
            dataAddr: 'partLocations',
            columnWidth: '10rem',
            formatReadonly: data => formatLocationDataField(data, 'stockOnHand', 'number'),
          },
          {
            fieldType: FieldType.readonlyField,
            dataAddr: 'partLocations',
            label: 'Latest Price',
            columnWidth: '10rem',
            formatReadonly: data => formatLocationDataField(data, 'latestPrice', 'currency'),
          },
        ],
        filterAction: {
          filterFields: filterFieldDefs,
          defaultValues: {
            isActive: true,
            workshopDepots: [rootStore.workshopStartup.defaultWorkshopDepot?.id],
          },
        },
      };
    };

    const formatLocationDataField = (
      data: IFieldData<any>,
      propertyName: string,
      format: 'currency' | 'number' | 'text'
    ) => {
      var partListItem = data.parentValue as PartListItem;
      if (!partListItem.partLocations || !partListItem.partLocations[0]) {
        return <div></div>;
      }
      const queryParams = props.getQueryParams();
      const filteredBySingleDepot = queryParams?.workshopDepots
        ? queryParams.workshopDepots.length === 1
        : false;
      const hasOnlyOneWorkshopDepot = rootStore.workshopStartup.workshopDepots
        ? rootStore.workshopStartup.workshopDepots.length === 1
        : false;
      if (!filteredBySingleDepot && !hasOnlyOneWorkshopDepot) {
        return (
          <div>
            {partListItem.partLocations.map((pl, i) => {
              const val = pl[propertyName];
              return (
                <div key={i}>
                  <small>{pl.depotName}</small>
                  <br />
                  {format === 'text' ? (
                    val || <span>&nbsp;</span>
                  ) : (
                    <div className="text-right">
                      {format === 'currency'
                        ? formatNumber(Number.parseFloat(val), 'currency')
                        : val}
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        );
      } else {
        const val = partListItem.partLocations[0][propertyName];
        return (
          <div>
            {val !== undefined
              ? format === 'text'
                ? val
                : format === 'currency'
                ? formatNumber(Number.parseFloat(val), 'currency')
                : val
              : null}
          </div>
        );
      }
    };

    const downloadStockOnHandExcel = () => {
      const date = DateTime.local().toFormat('yyyyMMddHHmm');
      return partsModel.generateStockOnHandExcel(props.getQueryParams()).then(r => {
        saveAs(r, `Parts_Report_${date}.xlsx`);
      });
    };

    return <ListPage className="list-parts-component" data={partsModel.items} def={getPageDef()} />;
  }
);

export default withQueryParams(ListParts);
