import { FieldType, PagePrimarySize } from 'src/views/definitionBuilders/types';
import { ListPage } from 'src/views/components/Page';
import { TaskCardItem } from 'src/views/components/TaskCard';
import { IListPageDef } from 'src/views/components/Page/pages/ListPage';
import PrimaryTitle from 'src/views/components/Page/PrimaryTitle/PrimaryTitle';

export interface IListChecklistsProps {
  canManageChecklists: boolean;
  checklists: Workshop.Domain.Queries.GetChecklist.ChecklistDetails[];
  listChecklists: (includeInactiveChecklists: boolean) => Promise<void>;
}

function getPageDef(props: IListChecklistsProps): IListPageDef {
  return {
    primaryTitle: <PrimaryTitle title="Checklists"></PrimaryTitle>,
    primarySize: PagePrimarySize.full,
    onLoadData: () => props.listChecklists(true),
    createLink: props.canManageChecklists ? TaskCardItem.createChecklist : undefined,
    primaryFields: [
      {
        fieldType: FieldType.textField,
        dataAddr: 'name',
        label: 'Name',
        linkTo: d => `/workshop/checklists/${d.parentValue.id}`,
      },
      {
        fieldType: FieldType.textField,
        dataAddr: 'active',
        label: 'Active',
        formatReadonly: data => (data.fieldValue ? 'Yes' : 'No'),
      },
    ],
  };
}

const ListChecklists: React.FC<IListChecklistsProps> = (props: IListChecklistsProps) => {
  return (
    <ListPage
      className="list-checklists-component"
      data={props.checklists}
      def={getPageDef(props)}
    />
  );
};

export default ListChecklists;
