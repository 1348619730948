import './DateInput.scss';

import cn from 'classnames';
import { Input } from 'reactstrap';
import { DateTime } from 'luxon';
import CalendarPicker from './CalendarPicker';
import { PureComponent } from 'react';

const editingDateFormat = 'dd/MM/yyyy'; // it would be nice to use DateTime.DATE_SHORT but there doesn't appear to be an appropriate parse function

interface IDateInputProps {
  autoFocus?: boolean;
  displayAsInvalid: boolean; // touched && errorText
  placeholder?: string;
  value: string;
  onChange: (newValue: string) => void;
  timezone?: string;
}

class DateInput extends PureComponent<IDateInputProps> {
  private readonly handleInputBlur = () => {
    // Update the internalValue (which is what is displayed to the user) if the current value is a valid date
    // This should convert something like "5/10" to "5/10/2018"
    // Otherwise, leave whatever they have entered as they may want to fix it
    const { value, onChange, timezone } = this.props;
    const date = parseEditingFormattedDateString(value, timezone);
    const newValue = getEditingFormattedDateString(date, timezone);
    if (date && date.isValid && newValue !== value) {
      onChange(newValue);
    }
  };

  private readonly handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.props.onChange(e.target.value);
  };

  private readonly handleCalendarChange = (date: DateTime) => {
    const internalValue = getEditingFormattedDateString(date, this.props.timezone);
    this.props.onChange(internalValue);
  };

  render() {
    const { autoFocus, displayAsInvalid, value, placeholder, timezone } = this.props;
    const date = parseEditingFormattedDateString(value, timezone);
    const currentCalendarDate =
      date && date.isValid ? date : DateTime.local().setZone(timezone || 'local');

    return (
      <>
        <Input
          autoComplete="off"
          type="text"
          className={cn('page-internal-date-input', { invalid: displayAsInvalid })}
          // disabled={disabled}
          autoFocus={autoFocus}
          placeholder={placeholder || 'dd/mm/yyyy'}
          value={value}
          onChange={this.handleChange}
          onBlur={this.handleInputBlur}
        />
        <CalendarPicker
          className="page-internal-date-input-calendar-dropdown input-group-append"
          buttonClassName="calendar-button"
          value={currentCalendarDate}
          onChange={this.handleCalendarChange}
          timezone={timezone}
        />
      </>
    );
  }
}

export default DateInput;

export function getEditingFormattedDateString(
  externalValue: DateTime | undefined,
  timezone?: string
) {
  // Dates are edited in the HQ timezone OR local timezone if not externally provided.
  return externalValue && externalValue.isValid
    ? externalValue.setZone(timezone || 'local').toFormat(editingDateFormat)
    : '';
}

export function parseEditingFormattedDateString(
  originalValue: string | undefined,
  timezone?: string
) {
  if (!originalValue) {
    return undefined;
  }
  // Use regexr.com to look at this regex if needed
  // Extracts out day, month and year as groups
  // Parts that are not provided will be defaulted from the current date
  // Support for "dd/mm/yyyy", "dd-mm-yyyy", "ddmmyyyy", "d/m/yyyy", "dd/mm", "dd", etc.
  const re = /^(\d{1,2})[/-]?(\d{1,2})?[/-]?(\d{4})?$/;
  const matches = re.exec(originalValue);
  if (!matches) {
    return DateTime.invalid('Failed parsing');
  }
  const today = DateTime.local().setZone(timezone || 'local');

  const day = Number.parseInt(matches[1], 10) || today.day;
  const month = Number.parseInt(matches[2], 10) || today.month;
  const year = Number.parseInt(matches[3], 10) || today.year;

  return DateTime.fromObject({ year, month, day, zone: timezone || 'local' });
}
