import { DateTime } from 'luxon';
import { observer } from 'mobx-react';
import {
  allRailBookingStatus,
  RailBookingStatus,
  railBookingStatusDescription,
} from 'src/api/enums';
import { useRootStore } from 'src/domain/entities/RootStoreModel';
import ListPage, { IListPageDef } from 'src/views/components/Page/pages/ListPage';
import PrimaryTitle from 'src/views/components/Page/PrimaryTitle/PrimaryTitle';
import TaskCardItem, { toActionLinkDef } from 'src/views/components/TaskCard/TaskCardItem';
import {
  ActionType,
  FieldDefs,
  FieldType,
  PagePrimarySize,
  PaneType,
} from 'src/views/definitionBuilders/types';

const ListRailBookings: React.FC = observer(() => {
  const rootStore = useRootStore();
  const bookingsModel = rootStore.operations.rail.railBookings;
  const customerModel = rootStore.operations.sales.customer;

  const getPageDef = (): IListPageDef => {
    const createLinks = [
      ...(rootStore.account.isOperationsDepartmentMember
        ? [TaskCardItem.urban.createOperationsJob]
        : []),
    ].map(toActionLinkDef);

    const filterFieldDefs = {
      status: {
        fieldType: FieldType.selectMultiField,
        dataAddr: 'statuses',
        label: 'Status',
        valueKey: 'value',
        descriptionKey: 'description',
        optionItems: allRailBookingStatus.slice(),
        useValueOnly: true,
      } as FieldDefs,
      customer: {
        fieldType: FieldType.selectAsyncField,
        dataAddr: 'customerId',
        label: 'Customer',
        valueKey: 'customerId',
        descriptionKey: 'customerName',
        useValueOnly: true,
        loadOptionItems: customerModel.searchCustomers,
        loadItems: customerModel.findCustomers,
      } as FieldDefs,
      dateFrom: {
        fieldType: FieldType.dateField,
        dataAddr: 'from',
        label: 'Date From',
      } as FieldDefs,
      dateTo: {
        fieldType: FieldType.dateField,
        dataAddr: 'to',
        label: 'Date To',
      } as FieldDefs,
    };

    return {
      primaryTitle: <PrimaryTitle title="Rail Bookings"></PrimaryTitle>,
      primarySize: PagePrimarySize.full,
      onLoadData: bookingsModel.listItems,
      externalSearch: true,
      createLink: rootStore.account.isRailDepartmentMember
        ? TaskCardItem.operations.rail.railBookings.createRailBooking
        : undefined,
      hasMoreData: bookingsModel.hasMoreItems,
      primaryActions: [
        {
          actions: [
            {
              actionType: ActionType.actionCollection,
              actionGroups: [
                {
                  actions: createLinks,
                },
              ],
            },
          ],
        },
      ],
      primaryFields: [
        {
          fieldType: FieldType.textField,
          dataAddr: 'railBookingNumber',
          label: 'Rail Booking Number',
          linkTo: d => `/operations/rail/rail-bookings/${d.parentValue.railBookingId}`,
        },
        {
          fieldType: FieldType.textField,
          dataAddr: 'description',
          label: 'Description',
        },
        {
          fieldType: FieldType.dateTimeField,
          dataAddr: 'startDateTime',
          label: 'Start',
        },
        {
          fieldType: FieldType.dateTimeField,
          dataAddr: 'endDateTime',
          label: 'End',
        },
        {
          fieldType: FieldType.readonlyField,
          dataAddr: 'status',
          label: 'Status',
          formatReadonly: data => {
            return railBookingStatusDescription(data.parentValue.status);
          },
        },
      ],
      filterAction: {
        defaultValues: {
          to: DateTime.local()
            .plus({ days: 30 })
            .toISODate(),
          statuses: [RailBookingStatus.Booked],
          orderBy: 'StartJobClockOn~desc',
        },
        filterFields: Object.keys(filterFieldDefs).map(filters => filterFieldDefs[filters]),
        filterDef: () => [
          {
            panes: [
              {
                paneType: PaneType.formFieldsPane,
                columnCount: 2,
                fields: [filterFieldDefs.dateFrom, filterFieldDefs.dateTo],
              },
              {
                paneType: PaneType.formFieldsPane,
                fields: [filterFieldDefs.customer, filterFieldDefs.status],
              },
            ],
          },
        ],
      },
    };
  };
  return (
    <ListPage
      className="list-rail-bookings-component"
      data={bookingsModel.items}
      def={getPageDef()}
    />
  );
});

export default ListRailBookings;
