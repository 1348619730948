import MaintainTechSpecDropdownOptions, {
  IMaintainTechSpecDropdownOptionsProps,
} from './MaintainTechSpecDropdownOptions';
import inject from 'src/views/injectFromStore';
import Omit from 'src/infrastructure/omit';

const Container = inject<
  Omit<IMaintainTechSpecDropdownOptionsProps, 'mode'>,
  IMaintainTechSpecDropdownOptionsProps
>((allStores, _) => ({
  canManageTechSpecs: allStores.rootStore.account.isWorkshopDepartmentMember,
  techSpecDropdownOptions: allStores.rootStore.workshop.techSpecs.editableDropdownOptions.response,
  getEditableTechSpecDropdownOptions:
    allStores.rootStore.workshop.techSpecs.editableDropdownOptions.getAll,
  updateTechSpecDropdownOptions:
    allStores.rootStore.workshop.techSpecs.editableDropdownOptions.updateAll,
}))(MaintainTechSpecDropdownOptions);

export default Container;
