import { useState } from 'react';
import Select from 'react-select';
import { Button, FormGroup, InputGroup, Label } from 'reactstrap';
import DateInput from 'src/views/components/Page/fields/subfields/DateInput';
import styles from './ReportEntry.module.scss';
import { YesNoToggleButton } from 'src/views/components/Page/fields/YesNoToggleButton';

type AssetItem = Common.Queries.Workshop.GetFleetAssetList.AssetItem;
type CustomerItem = Common.Dtos.CustomerItem;
export interface IReportEntryProps {
  exportAction: (form: IFormState) => Promise<void>;
  title: string;
  fleetAssets: AssetItem[];
  customers?: CustomerItem[];
  type: 'kms' | 'cents';
}
interface IFormState {
  from: string;
  to: string;
  vehicles: AssetItem[];
  customers: CustomerItem[];
  isDetailed: boolean;
}

const ReportEntry: React.FC<IReportEntryProps> = (props: IReportEntryProps) => {
  const initialValues = {
    from: '',
    to: '',
    vehicles: [],
    customers: [],
    isDetailed: true,
  };

  const [form, setForm] = useState<IFormState>(initialValues);
  const fleetAssets = props.fleetAssets.filter(asset => !asset.isDecommissioned);

  return (
    <div className={styles.container}>
      <h2>{props.title}</h2>
      <form autoComplete="off">
        <div className={styles.date}>
          <FormGroup>
            <Label for="from">From</Label>
            <InputGroup>
              <DateInput
                displayAsInvalid={false}
                value={form.from}
                onChange={newVal => setForm({ ...form, from: newVal })}
              />
            </InputGroup>
          </FormGroup>
        </div>
        <div className={styles.date}>
          <FormGroup>
            <Label for="to">To</Label>
            <InputGroup>
              <DateInput
                displayAsInvalid={false}
                value={form.to}
                onChange={newVal => setForm({ ...form, to: newVal })}
              />
            </InputGroup>
          </FormGroup>
        </div>
        <div>
          <FormGroup>
            <Label for="vehicles">Vehicles</Label>
            <InputGroup>
              <Select
                className={styles.vehicles}
                multi
                closeOnSelect={false}
                clearable
                options={fleetAssets}
                valueKey="id"
                labelKey="name"
                value={form.vehicles}
                onChange={newVal => setForm({ ...form, vehicles: newVal as AssetItem[] })}
                matchProp="label"
              />
            </InputGroup>
          </FormGroup>
        </div>
        {props.type === 'kms' ? (
          <div>
            <FormGroup>
              <InputGroup>
                <YesNoToggleButton
                  label="Detailed per Depot"
                  value={form.isDetailed}
                  onChange={newVal => setForm({ ...form, isDetailed: newVal })}
                />
              </InputGroup>
            </FormGroup>
          </div>
        ) : (
          <div>
            <FormGroup>
              <Label for="customers">Customers</Label>
              <InputGroup>
                <Select
                  className={styles.vehicles}
                  multi
                  closeOnSelect={false}
                  clearable
                  options={props.customers}
                  valueKey="customerId"
                  labelKey="customerName"
                  value={form.customers}
                  onChange={newVal => setForm({ ...form, customers: newVal as CustomerItem[] })}
                  matchProp="label"
                />
              </InputGroup>
            </FormGroup>
          </div>
        )}

        <div className={styles.submit}>
          <FormGroup>
            <InputGroup>
              <Button
                className="btn btn-primary"
                type="button"
                onClick={() => props.exportAction(form)}>
                Export
              </Button>
            </InputGroup>
          </FormGroup>
        </div>
      </form>
    </div>
  );
};

export default ReportEntry;
