import { types, flow } from 'mobx-state-tree';
import { getAjax } from 'src/domain/services';

type LabourPriceDetails = Workshop.Domain.Queries.GetLabourPrices.LabourPriceDetails;

export const LabourPriceModel = types
  .model('SupplierModel', {
    labourPrices: types.array(types.frozen<LabourPriceDetails>()),
  })
  .views(self => ({}))
  .actions(self => {
    const ajax = getAjax(self);

    const getLabourPrices = flow(function*() {
      self.labourPrices = yield ajax.workshop.labourPrices.listLabourPrices();
    });

    const updateLabourPrice = flow(function*(
      command: Workshop.Domain.Commands.LabourPrice.UpdateLabourPriceCommand
    ) {
      yield ajax.workshop.labourPrices.updateLabourPrice(command);
      getLabourPrices();
    });

    const createLabourPrice = flow(function*(
      command: Workshop.Domain.Commands.LabourPrice.CreateLabourPriceCommand
    ) {
      yield ajax.workshop.labourPrices.createLabourPrice(command);
      getLabourPrices();
    });

    const deleteLabourPrice = flow(function*(id: string) {
      yield ajax.workshop.labourPrices.deleteLabourPrice(id);
      getLabourPrices();
    });

    return {
      getLabourPrices,
      updateLabourPrice,
      createLabourPrice,
      deleteLabourPrice,
    };
  });

type ILabourPriceModelType = typeof LabourPriceModel;
export interface ILabourPriceModel extends ILabourPriceModelType {}
