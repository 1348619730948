import { types, getRoot, flow } from 'mobx-state-tree';
import { getAjax } from 'src/domain/services';
import { IRootStoreModel } from 'src/domain/entities/RootStoreModel';

type CreateNoteCommand = People.Domain.Commands.Note.CreateNote.CreateNoteCommand;
type UpdateNoteCommand = People.Domain.Commands.Note.UpdateNote.UpdateNoteCommand;
type NoteItem = People.Domain.Queries.ViewNote.NoteItem;

export const NoteModel = types
  .model('NoteModel', {
    note: types.maybe(types.frozen<NoteItem>()),
  })
  .views(self => ({}))
  .actions(self => {
    const ajax = getAjax(self);
    const root = getRoot(self) as IRootStoreModel;

    const createNote = flow(function*(command: CreateNoteCommand) {
      const id = yield ajax.people.note.createNote(command);
      root.history.push(`/people/notes/${id}`);
    });

    const loadNote = flow(function*(noteId: string) {
      self.note = yield ajax.people.note.loadNote(noteId);
    });

    const updateNote = flow(function*(command: UpdateNoteCommand) {
      yield ajax.people.note.updateNote(command);
      yield loadNote(command.id);
    });

    const deleteNote = flow(function*(noteId: string) {
      yield ajax.people.note.deleteNote(noteId);
      root.history.push(`/people/notes?defaultFilter=true`);
    });

    return {
      createNote,
      loadNote,
      updateNote,
      deleteNote,
    };
  });
