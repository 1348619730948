import styles from '../AssetDetails.module.scss';
import Spinner from 'src/views/components/Spinner';
// import { cubicItemDescription } from 'src/api/enums';
import { useEffect, useState } from 'react';
import { observer } from 'mobx-react';

type AssetCubicSummary = Workshop.Domain.Queries.AssetCubic.AssetCubicSummary;
type CubicItem = Workshop.Domain.AggregatesModel.AssetAggregate.CubicItem;
export interface IAssetCubicRegisterDisplayProps {
  assetId: string;
  cubicSummary: AssetCubicSummary | undefined;
  loadCubicRegister: (assetId: string) => Promise<void>;
  cubicItems: CubicItem[] | undefined;
  loadCubicItems: () => Promise<void>;
}

export const AssetCubicRegisterDisplay: React.FC<IAssetCubicRegisterDisplayProps> = observer(
  ({ cubicSummary, loadCubicRegister, assetId, cubicItems, loadCubicItems }) => {
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
      setIsLoading(true);
      loadCubicItems();
      loadCubicRegister(assetId).finally(() => setIsLoading(false));
    }, [assetId]);

    return (
      <Spinner show={isLoading} className={styles.scrollableTabbedArea}>
        <div className={styles.cubicRegister}>
          {isLoading ? (
            <div />
          ) : (
            cubicSummary && (
              <table>
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Serial Number</th>
                  </tr>
                </thead>
                <tbody>
                  {cubicSummary.items.map((item, i) => (
                    <tr key={i}>
                      <td>{cubicItems?.find(c => c.id === item.cubicItemId)?.description ?? ''}</td>
                      <td>{item.serialNumber}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )
          )}
        </div>
      </Spinner>
    );
  }
);

export default AssetCubicRegisterDisplay;
