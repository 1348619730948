import inject from 'src/views/injectFromKioskStore';
import Shifts, { IShiftsProps } from 'src/views/kioskRoutes/workshopKiosk/Shifts/Shifts';

const Container = inject<IShiftsProps>((allStores, p) => ({
  userId: allStores.rootStore.account.id,
  userName: allStores.rootStore.account.name,
  shifts: allStores.rootStore.kiosk.shifts.shifts.slice(),
  loadShifts: allStores.rootStore.kiosk.shifts.loadShifts,
  defaultKioskDepotId: allStores.rootStore.kiosk.workshop.defaultKioskDepotId,
}))(Shifts);

export default Container;
