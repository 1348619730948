import './PageArea.scss';

import cn from 'classnames';
import { Subject } from 'rxjs/Subject';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { Subscription } from 'rxjs/Subscription';
import { Button } from 'reactstrap';
import deepEqual from 'src/infrastructure/deepEqual';
import { distinct } from 'src/infrastructure/arrayUtils';
import { NestedField, get } from 'src/views/components/Page/forms';
import { IFormApi, IFormApiWithoutState } from 'src/views/components/Page/forms/base';
import {
  ISectionDef,
  IActionData,
  IActionGroupDef,
  IFormChange,
  ISubsectionDef,
  SectionDefs,
  isSectionDef,
  DataAddr,
} from 'src/views/definitionBuilders/types';
import PagePanel from './PagePanel';
import PageForm from './PageForm';
import ActionBar from './actions/ActionBar';
import SubsectionContext from './PageSubsectionContext';
import PageNestedItem from './PageNestedItem';
import { SpinnerIcon } from 'src/images/icons';
import { Component, createContext } from 'react';

const INITIAL_FORMVALUES = Symbol();

export interface IModalMeta {
  closeModal: () => void;
}

export const ModalContext = createContext<IModalMeta | undefined>(undefined);

interface IPrimaryActionBarProps {
  className?: string;
  actionGroupDefs?: Array<IActionGroupDef>;
  actionData: IActionData;
  formSubmitting: boolean;
}

const PrimaryActionBar: React.FC<IPrimaryActionBarProps> = (props: IPrimaryActionBarProps) => {
  const { className, formSubmitting } = props;
  return (
    <ActionBar
      className={className}
      {...props}
      actionMeta={{ hideLabel: true, borderless: true, formSubmitting }}
    />
  );
};

interface ISecondaryActionBarProps {
  className?: string;
  actionGroupDefs?: Array<IActionGroupDef>;
  actionData: IActionData;
  formSubmitting: boolean;
}

const SecondaryActionBar: React.FC<ISecondaryActionBarProps> = (
  props: ISecondaryActionBarProps
) => {
  const { className, formSubmitting } = props;
  return (
    <ActionBar
      className={className}
      {...props}
      actionMeta={{ wide: true, formSubmitting }}
      reverseOrder
    />
  );
};
interface ITertiaryActionBarProps {
  className?: string;
  actionGroupDefs?: Array<IActionGroupDef>;
  actionData: IActionData;
  formSubmitting: boolean;
}

const TertiaryActionBar: React.FC<ITertiaryActionBarProps> = (props: ITertiaryActionBarProps) => {
  const { className, formSubmitting } = props;
  return (
    <ActionBar className={className} {...props} actionMeta={{ formSubmitting, hideLabel: true }} />
  );
};

interface IPageAreaProps {
  className?: string;
  sectionDefs: SectionDefs[];
  logicalSubsectionDefs?: ISubsectionDef[];
  parentSectionFormApi?: IFormApi;
  autoFocus?: boolean;
  // tslint:disable-next-line:no-any
  data?: any;
  modalMeta?: IModalMeta;
  showSpinner?: boolean;
}

interface IPageAreaState {
  currentSectionKey: string | null;
}

class PageArea extends Component<IPageAreaProps, IPageAreaState> {
  private readonly _rawFormChangeSubject = new BehaviorSubject<{ formValues: unknown }>({
    formValues: INITIAL_FORMVALUES,
  });
  private readonly _formValuesInitSubject = new BehaviorSubject<{ formValues: unknown }>({
    formValues: INITIAL_FORMVALUES,
  });
  private readonly _formChangeSubject = new Subject<IFormChange>();
  private readonly _subscriptions = new Subscription();

  constructor(props: IPageAreaProps) {
    super(props);

    this.state = {
      currentSectionKey: null,
    };
  }

  static getDerivedStateFromProps(nextProps: Readonly<IPageAreaProps>, prevState: IPageAreaState) {
    const selectedTabValid = nextProps.sectionDefs.some((def, i) => {
      const key = def.key || `${i}`;
      return (
        key === prevState.currentSectionKey &&
        (typeof def.hidden === 'function'
          ? !def.hidden(PageArea.getSectionData(def, nextProps.data))
          : !def.hidden)
      );
    });

    return selectedTabValid ? null : { currentSectionKey: null };
  }

  private readonly getTitle = (section: SectionDefs) => {
    if (!section) {
      return null;
    }

    return typeof section.title === 'function'
      ? section.title({
          sectionValue: this.getSectionData(section),
        })
      : section.title;
  };

  // tslint:disable-next-line:no-any
  private static readonly getSectionData = (section: SectionDefs, data: any) => {
    if (section.explicitData) {
      return section.explicitData;
    }
    if ((Array.isArray(section.dataAddr) && section.dataAddr.length) || section.dataAddr) {
      return get(data, section.dataAddr);
    } else {
      return data;
    }
  };

  componentDidMount() {
    const sub = this._rawFormChangeSubject
      .merge(this._formValuesInitSubject)
      .pairwise()
      .filter(([x, y]) => {
        return (
          x.formValues !== INITIAL_FORMVALUES &&
          y.formValues !== INITIAL_FORMVALUES &&
          !deepEqual(x.formValues, y.formValues)
        );
      })
      .map(([prev, next]) => {
        return {
          oldValues: prev.formValues,
          newValues: next.formValues,
        };
      })
      .subscribe(x => {
        this._formChangeSubject.next(x);
      });
    this._subscriptions.add(sub);
  }

  componentWillUnmount() {
    this._subscriptions.unsubscribe();
  }

  private readonly setInitialFormValuesOnEdit = () => {
    const currentSection = this.getCurrentSectionDef();

    if (currentSection) {
      this._formValuesInitSubject.next({
        formValues: this.getSectionData(currentSection),
      });
    }
  };

  private readonly getFilteredSubsectionDefs = () => {
    return this.props.logicalSubsectionDefs
      ? this.props.logicalSubsectionDefs.filter(d => !this.getSectionHidden(d))
      : [];
  };

  private readonly getSectionData = (section: SectionDefs) => {
    const { data } = this.props;
    return PageArea.getSectionData(section, data);
  };

  private readonly getSectionHidden = (section: SectionDefs) => {
    if (typeof section.hidden === 'function') {
      return section.hidden({
        sectionValue: this.getSectionData(section),
      });
    } else {
      return !!section.hidden;
    }
  };

  private readonly sectionKey = (s: SectionDefs, idx: number) => {
    return s.key || `${idx}`;
  };

  private readonly getCurrentSectionDef = () => {
    const { sectionDefs } = this.props;
    const { currentSectionKey } = this.state;

    if (!sectionDefs || !sectionDefs.length) {
      return null;
    }

    const currentSectionDef =
      sectionDefs.find((s, i) => this.sectionKey(s, i) === currentSectionKey) || sectionDefs[0];

    return this.getSectionHidden(currentSectionDef)
      ? sectionDefs.find(s => !this.getSectionHidden(s)) || null
      : currentSectionDef;
  };

  private readonly handleGetApi = (formApi: IFormApiWithoutState) => {
    const currentSection = this.getCurrentSectionDef();
    if (currentSection) {
      isSectionDef(currentSection) && currentSection.getApi && currentSection.getApi(formApi);
    }
  };

  private readonly handleSectionSelected = (key: string) => {
    this.setState({ currentSectionKey: key });
  };

  // tslint:disable-next-line:no-any
  private readonly getOriginalValues = (currentSection: SectionDefs, values: any) => {
    const data = this.getSectionData(currentSection);
    // Hide the fact that we had to wrap the array in an object
    return Array.isArray(data) && values.items ? values.items : values;
  };

  // tslint:disable-next-line:no-any
  private readonly handlePreSubmit = (values: any, submissionMeta: {} | undefined) => {
    const currentSection = this.getCurrentSectionDef();
    if (!currentSection || !isSectionDef(currentSection)) {
      return values;
    }
    const originalValues = this.getOriginalValues(currentSection, values);

    if (currentSection.onFormPreSubmit) {
      return currentSection.onFormPreSubmit(originalValues, submissionMeta);
    } else {
      return originalValues;
    }
  };

  private readonly handleSubmitSucceeded = async (section: ISectionDef) => {
    const { modalMeta } = this.props;
    if (section.onSubmitSucceeded) {
      await section.onSubmitSucceeded();
    }
    // If modal is a form and it's been successfully submitted, close the modal
    if (modalMeta) {
      modalMeta.closeModal();
    }
  };

  render(): React.ReactNode {
    const {
      className,
      parentSectionFormApi,
      autoFocus,
      modalMeta,
      showSpinner,
      sectionDefs,
    } = this.props;
    const { currentSectionKey } = this.state;

    const logicalSubsectionDefs = this.getFilteredSubsectionDefs();

    const currentSection = this.getCurrentSectionDef();
    if (!sectionDefs || !sectionDefs.length || !currentSection) {
      return null;
    }
    const currentSectionEditing = isSectionDef(currentSection) && currentSection.asForm;
    const currentSectionIsLogicalSubsection = currentSection.isLogicalSubsection;
    if (currentSectionIsLogicalSubsection && logicalSubsectionDefs.length) {
      throw new Error('A logical subsection cannot itself have logical subsections');
    }
    const logicalSubsectionKeys = logicalSubsectionDefs.map(d => d.key);
    if (distinct(logicalSubsectionKeys).length !== logicalSubsectionKeys.length) {
      throw new Error('Logical subsection keys must be unique');
    }

    const data = this.getSectionData(currentSection);

    // react-form converts arrays to objects when set at the top of a form
    const safeData = Array.isArray(data) ? { items: data } : data;
    const sectionTitleTab = (key: string, s: SectionDefs) => {
      const spinner = showSpinner ? (
        <span>
          &nbsp;
          <SpinnerIcon size="xs" />
        </span>
      ) : null;

      if (this.getSectionHidden(s)) {
        return null;
      }

      if (sectionDefs.length > 1 || s.isTab) {
        return (
          <Button
            className="section-title-tab"
            key={key}
            outline
            color="light"
            onClick={() => this.handleSectionSelected(key)}>
            <span className={cn(`${s === currentSection ? 'active' : ''}`, 'tabNav')}>
              {this.getTitle(s)}
              {s.subtitle && <small> {s.subtitle}</small>}
              {spinner}
            </span>
          </Button>
        );
      } else if (s.title === 'Navigation') {
        return (
          <h3 key={key}>
            {this.getTitle(s)}
            {s.subtitle && <small> {s.subtitle}</small>}
            {spinner}
          </h3>
        );
      } else {
        return (
          <h1 key={key}>
            {this.getTitle(s)}
            {s.subtitle ? <small> {s.subtitle}</small> : null}
            {spinner}
          </h1>
        );
      }
    };

    const sectionContent = (
      sectionDef: ISectionDef | ISubsectionDef,
      subsectionDataAddr: DataAddr | undefined
    ) => {
      return (
        <NestedField field={subsectionDataAddr}>
          {fieldApi => {
            const panels =
              typeof sectionDef.panels === 'function'
                ? sectionDef.panels(fieldApi.formApi)
                : sectionDef.panels;
            return panels.map((p, i) => (
              <PagePanel
                key={i}
                panelDef={p}
                panelMeta={{
                  readonly: !currentSectionEditing,
                  autoFocus: (autoFocus && isSectionDef(sectionDef) && i === 0) || false,
                  formChange$: this._formChangeSubject,
                }}
              />
            ));
          }}
        </NestedField>
      );
    };

    const areaContent = (formApi: IFormApi) => {
      const sectionActionsData = (sectionDef: ISectionDef | ISubsectionDef): IActionData => {
        const sectionValue = formApi.getValue(formApi.getFullField(sectionDef.dataAddr));
        return {
          actionValue: sectionValue,
          parentValue: undefined, // As an action has no dataAddr, it cannot have a parent value
          paneValue: undefined,
          panelValue: undefined,
          sectionValue,
        };
      };
      const currentSectionActionsData = sectionActionsData(currentSection);
      return (
        <SubsectionContext.Consumer>
          {ctx => (
            <>
              <div className="page-area-container">
                <header>
                  <div className="section-title">
                    {sectionDefs.map((s, i) => sectionTitleTab(this.sectionKey(s, i), s))}
                  </div>
                  {currentSectionIsLogicalSubsection ? (
                    ctx.renderPhysicalPart(currentSection.key, 'primaryActions', 'primary-actions')
                  ) : (
                    <>
                      {currentSection.badge ? (
                        <div className="section-badge">{currentSection.badge.label}</div>
                      ) : null}
                      <PrimaryActionBar
                        className="primary-actions"
                        actionGroupDefs={currentSection.primaryActions}
                        actionData={currentSectionActionsData}
                        formSubmitting={formApi.submitting}
                      />
                    </>
                  )}
                </header>
                <div className="area-content">
                  {currentSectionIsLogicalSubsection
                    ? ctx.renderPhysicalPart(currentSection.key, 'content')
                    : sectionContent(currentSection, undefined)}
                </div>
                <footer>
                  {currentSectionIsLogicalSubsection ? (
                    ctx.renderPhysicalPart(
                      currentSection.key,
                      'secondaryActions',
                      'secondary-actions'
                    )
                  ) : (
                    <>
                      <SecondaryActionBar
                        className="secondary-actions"
                        actionGroupDefs={currentSection.secondaryActions}
                        actionData={currentSectionActionsData}
                        formSubmitting={formApi.submitting}
                      />
                      <TertiaryActionBar
                        className="tertiary-actions"
                        actionGroupDefs={currentSection.tertiaryActions}
                        actionData={currentSectionActionsData}
                        formSubmitting={formApi.submitting}
                      />
                    </>
                  )}
                </footer>
              </div>
              {// _logically_ render *all* logical subsections here, so that any state is maintained regardless of which section is actually visiable
              logicalSubsectionDefs.map((subsectionDef, i) => {
                const actionsData = sectionActionsData(subsectionDef);
                return (
                  <PageNestedItem
                    key={subsectionDef.key}
                    formApi={formApi}
                    dataAddr={subsectionDef.dataAddr}>
                    {ctx.renderLogicalPart(
                      subsectionDef.key,
                      'primaryActions',
                      <PrimaryActionBar
                        actionGroupDefs={subsectionDef.primaryActions}
                        actionData={actionsData}
                        formSubmitting={formApi.submitting}
                      />
                    )}
                    {ctx.renderLogicalPart(
                      subsectionDef.key,
                      'content',
                      sectionContent(subsectionDef, subsectionDef.dataAddr)
                    )}
                    {ctx.renderLogicalPart(
                      subsectionDef.key,
                      'secondaryActions',
                      <SecondaryActionBar
                        actionGroupDefs={subsectionDef.secondaryActions}
                        actionData={actionsData}
                        formSubmitting={formApi.submitting}
                      />
                    )}
                  </PageNestedItem>
                );
              })}
            </>
          )}
        </SubsectionContext.Consumer>
      );
    };

    const wrappedAreaContent = (formApi: IFormApi) =>
      safeData !== data ? (
        <NestedField field="items">{areaContent(formApi)}</NestedField>
      ) : (
        areaContent(formApi)
      );

    const getForm = () => {
      // using a key for the forms ensures that the form is destroyed/recreated when the current section is changed
      const formKey = currentSectionKey || this.sectionKey(currentSection, 0);
      return currentSectionEditing && isSectionDef(currentSection) ? (
        <PageForm
          key={formKey}
          suppressLeavePrompt={currentSection.suppressLeavePrompt}
          defaultValues={safeData}
          defaultTouchedFields={currentSection.defaultTouchedFields}
          getApi={this.handleGetApi}
          onChange={formState =>
            this._rawFormChangeSubject.next({
              formValues: this.getOriginalValues(currentSection, formState.values),
            })
          }
          onResetRequested={currentSection.onFormResetRequested}
          onPreSubmit={this.handlePreSubmit}
          onSubmit={currentSection.onFormSubmit}
          onSubmitSucceeded={() => this.handleSubmitSucceeded(currentSection)}
          onSubmitFailure={currentSection.onSubmitFailure}
          setInitialFormValuesOnEdit={this.setInitialFormValuesOnEdit}>
          {wrappedAreaContent}
        </PageForm>
      ) : (
        <PageForm
          key={formKey}
          readonly
          values={safeData}
          getApi={this.handleGetApi}
          onChange={() => ({})}
          setInitialFormValuesOnEdit={this.setInitialFormValuesOnEdit}>
          {wrappedAreaContent}
        </PageForm>
      );
    };

    return (
      <section className={cn('page-area-component', className)}>
        <ModalContext.Provider value={modalMeta}>
          {parentSectionFormApi ? wrappedAreaContent(parentSectionFormApi) : getForm()}
        </ModalContext.Provider>
      </section>
    );
  }
}

export default PageArea;
