import { getSubmitCloseModalActionGroupDef } from 'src/views/definitionBuilders/common';
import { PaneType, FieldType, ModalDefBuilder } from 'src/views/definitionBuilders/types';

export default function getDeclineQuoteModalDef(
  onDecline: (reason: string) => Promise<void>
): ModalDefBuilder {
  return modalDefApi => ({
    title: 'Decline Quote',
    asForm: true,
    panels: [
      {
        panes: [
          {
            paneType: PaneType.customPane,
            render: api => (
              <p>Please list any reasons provided by the client for declining the quote.</p>
            ),
          },
          {
            paneType: PaneType.formFieldsPane,
            fields: [
              {
                fieldType: FieldType.textAreaField,
                dataAddr: 'declinedReason',
                label: 'Reason',
                rows: 5,
              },
            ],
          },
        ],
      },
    ],
    secondaryActions: [getSubmitCloseModalActionGroupDef('Decline')],
    onFormSubmit: (values: { declinedReason: string }) => {
      return onDecline(values.declinedReason);
    },
  });
}
