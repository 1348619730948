import { Input } from 'reactstrap';
import {
  parseEditingFormattedTimeString,
  getEditingFormattedTimeString,
} from 'src/views/components/Page/fields/subfields/TimeHelpers';

interface ITimeInputProps {
  value: string;
  onChange: (newValue: string) => void;
  placeholder?: string;
  name?: string;
  allowSeconds?: boolean;
  onBlur?: () => void;
}

const TimeInput: React.FC<ITimeInputProps> = (props: ITimeInputProps) => {
  const handleTimeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    props.onChange(e.target.value);
  };

  const handleInputBlur = () => {
    // Update the internalValue (which is what is displayed to the user) if the current value is a valid time
    // This should convert something like "510" to "05:10"
    // Otherwise, leave whatever they have entered as they may want to fix it
    const { value, onChange, allowSeconds } = props;
    const time = parseEditingFormattedTimeString(value, allowSeconds);
    const newValue = getEditingFormattedTimeString(time, undefined, allowSeconds);
    if (time && time.isValid && newValue !== value) {
      onChange(newValue);
    }

    props.onBlur && props.onBlur();
  };

  const { value, placeholder, name, allowSeconds } = props;
  return (
    <Input
      autoComplete="off"
      className="time"
      type="text"
      name={name}
      placeholder={placeholder || (allowSeconds ? 'HH:MM:SS' : 'HH:MM')}
      value={value}
      onChange={handleTimeChange}
      onBlur={handleInputBlur}
    />
  );
};

export default TimeInput;
