import { getSubmitCloseModalActionGroupDef } from 'src/views/definitionBuilders/common';
import { PaneType, ModalDefBuilder } from 'src/views/definitionBuilders/types';

type StaffMemberItem = People.Domain.Queries.ViewStaffMember.StaffMemberItem;

export default function getMarkInactiveModalDef(onSubmit: () => Promise<void>): ModalDefBuilder {
  return modalDefApi => ({
    title: 'Mark Inactive',
    asForm: true,
    panels: [
      {
        panes: [
          {
            paneType: PaneType.customPane,
            render: () => {
              const item = modalDefApi.actionData.sectionValue as StaffMemberItem;
              const name = item && item.firstName;
              return (
                <div>
                  <p>{`${name} will be removed from assigned future work.`}</p>
                  <p>Are you sure you want to mark this staff member as inactive?</p>
                </div>
              );
            },
          },
        ],
      },
    ],
    secondaryActions: [getSubmitCloseModalActionGroupDef('Ok')],
    onFormSubmit: onSubmit,
  });
}
