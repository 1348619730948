import { types, flow, getRoot } from 'mobx-state-tree';
import { getAjax, NotificationType } from 'src/domain/services';
import { IRootStoreModel } from 'src/domain/entities/RootStoreModel';

type CreateLeaveCommand = People.Domain.Commands.Leaves.CreateLeave.CreateLeaveCommand;
type UpdateLeaveCommand = People.Domain.Commands.Leaves.UpdateLeave.UpdateLeaveCommand;
type LeaveItem = People.Domain.Queries.ViewLeave.LeaveItem;
type ActivityLogTransaction = People.Domain.Queries.GetActivityLog.ActivityLogTransaction;
type LeaveCommandResult = People.Domain.Commands.Leaves.LeaveCommandResult;

export const LeaveModel = types
  .model('LeaveModel', {
    leave: types.maybe(types.frozen<LeaveItem>()),
    activityLogs: types.array(types.frozen<ActivityLogTransaction>()),
  })
  .actions(self => {
    const ajax = getAjax(self);
    const root = getRoot(self) as IRootStoreModel;

    const showUserMessageIfRequired = (result: LeaveCommandResult) => {
      if (result && result.userMessage) {
        root.notifications.addNotification(result.userMessage, {
          type: NotificationType.warn,
          autoClose: false,
        });
      }
    };

    const createLeave = flow(function*(command: CreateLeaveCommand) {
      const result = yield ajax.people.leave.createLeave(command);

      showUserMessageIfRequired(result);

      root.history.push(`/people/leaves/${result.leaveId!}`);
    });

    const loadLeave = flow(function*(leaveId: string) {
      self.leave = yield ajax.raw
        .get(`/api/people/leaves/${leaveId}`)
        .toPromise()
        .then<LeaveItem>(r => r.response);
    });

    const listActivityLogs = flow(function*(leaveId: string) {
      self.activityLogs = yield ajax.raw
        .get(`/api/people/activity-logs?aggregateType=Leave&aggregateId=${leaveId}`)
        .toPromise()
        .then<ActivityLogTransaction[]>(r => {
          return r.response;
        });
    });

    const updateLeave = flow(function*(command: UpdateLeaveCommand) {
      var result = yield ajax.people.leave.updateLeave(command);

      showUserMessageIfRequired(result);

      yield loadLeave(command.leaveId);
      yield listActivityLogs(command.leaveId);
    });

    const deleteLeave = flow(function*(
      leaveId: string,
      deleteRecurrences: boolean,
      applyToFutureRecurringLeave: boolean
    ) {
      var result = yield ajax.people.leave.deleteLeave(
        leaveId,
        deleteRecurrences,
        applyToFutureRecurringLeave
      );

      showUserMessageIfRequired(result);

      root.history.push(`/people/leaves?defaultFilter=true`);
    });

    const approveLeave = flow(function*(
      leaveId: string,
      resetAcknowledgement: boolean,
      applyToFutureRecurringLeave: boolean
    ) {
      var result = yield ajax.people.leave.approveLeave({
        id: leaveId,
        resetAcknowledgement: resetAcknowledgement,
        applyToFutureRecurringLeave: applyToFutureRecurringLeave,
      });

      showUserMessageIfRequired(result);

      yield loadLeave(leaveId);
      yield listActivityLogs(leaveId);
    });

    const declineLeave = flow(function*(
      command: People.Domain.Commands.Leaves.DeclineLeave.DeclineLeaveCommand
    ) {
      var result = yield ajax.people.leave.declineLeave(command);

      showUserMessageIfRequired(result);

      yield loadLeave(command.id);
      yield listActivityLogs(command.id);
    });

    return {
      createLeave,
      updateLeave,
      deleteLeave,
      loadLeave,
      approveLeave,
      declineLeave,
      listActivityLogs,
    };
  });
