import inject from 'src/views/injectFromStore';
import ListAssetGroups, { IListAssetGroupsProps } from './ListAssetGroups';

const Container = inject<IListAssetGroupsProps>((allStores, p) => ({
  canManageAssetGroups: allStores.rootStore.account.isWorkshopDepartmentMember,
  assetGroups: allStores.rootStore.assetGroups.assetGroupsListItems.slice(),
  listAssetGroups: allStores.rootStore.assetGroups.listAssetGroups,
}))(ListAssetGroups);

export default Container;
