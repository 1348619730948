import styles from './Form.module.scss';

import { Field, FieldProps, getIn } from 'formik';
import { Async } from 'react-select';
import cn from 'classnames';

interface IAsyncSelectFieldProps {
  dataLabel?: string;
  readOnlyValue?: string;
  readOnly?: boolean;
  dataAddress: string;
  className?: string;
  dataValue: { id: number | string; description: string };
  loadOptions: (
    search: string
  ) => Promise<{
    options: { id: string; description: string; searchText: string }[];
  }>;
}

const AsyncSelectField: React.FC<IAsyncSelectFieldProps> = ({
  dataLabel,
  dataAddress,
  dataValue,
  loadOptions,
  className,
  readOnly,
  readOnlyValue,
}) => {
  return (
    <div className={cn(styles.fieldContainer, className)}>
      {dataLabel && <div className={styles.fieldLabel}>{dataLabel}</div>}
      {readOnly ? (
        <div className={styles.selectField}>{readOnlyValue}</div>
      ) : (
        <Field name={dataAddress} value={dataValue}>
          {({ field: { name, value }, form }: FieldProps) => {
            const error = getIn(form.errors, dataAddress);
            const touched = getIn(form.touched, dataAddress) || form.submitCount > 0; // https://github.com/jaredpalmer/formik/issues/691#issuecomment-446509600
            return (
              <div className={cn(styles.selectField, !!error && touched ? styles.errorMargin : '')}>
                <Async
                  id="color"
                  multi={false}
                  onChange={v => form.setFieldValue(name, v)}
                  onBlur={() => form.setFieldTouched(name, true)}
                  value={value}
                  matchProp={'any'}
                  valueKey={'partNumber'}
                  labelKey={'description'}
                  matchPos={'any'}
                  ignoreAccents
                  ignoreCase
                  loadOptions={(x: string) => loadOptions(x)}
                  // tslint:disable-next-line: no-any
                  optionRenderer={(o: any) => (
                    <div style={{ whiteSpace: 'nowrap' }}>
                      {o.partNumber} - {o.description}
                    </div>
                  )}
                  menuContainerStyle={{ minWidth: '100%', width: '50rem' }}
                />
                {!!error && touched && <div className={styles.error}>Required</div>}
              </div>
            );
          }}
        </Field>
      )}
    </div>
  );
};

export default AsyncSelectField;
