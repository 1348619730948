import fileSaver from 'file-saver';
import { DateTime } from 'luxon';
import { Fragment, useEffect } from 'react';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import { QuoteStatus } from 'src/api/enums';
import { useRootStore } from 'src/domain/entities/RootStoreModel';
import { CheckIcon, ExcelIcon } from 'src/images/icons';
import HighlightBadge from 'src/views/components/HighlightBadge';
import ExtraDetails from 'src/views/components/operations/ExtraDetails/ExtraDetails';
import ListPage, { IListPageDef } from 'src/views/components/Page/pages/ListPage';
import PrimaryTitle from 'src/views/components/Page/PrimaryTitle/PrimaryTitle';
import { ActionType } from 'src/views/definitionBuilders/types';
import { FieldDefs, FieldType } from 'src/views/definitionBuilders/types/field';
import { PagePrimarySize } from 'src/views/definitionBuilders/types/page';
import { PaneType } from 'src/views/definitionBuilders/types/pane';
import withQueryParams, { IQueryParamsProps } from 'src/views/hocs/withQueryParams';
import InvoicingStatus from '../InvoicingStatus/InvoicingStatus';
import styles from './BookingsForAccounting.module.scss';

type ListBookingsForAccountsQuery = Operations.Domain.Queries.ListBookingsForAccounts.ListBookingsForAccountsQuery;
type BookingItem = Operations.Domain.Queries.ListBookingsForAccounts.BookingItem;

const BookingsForAccounting: React.FC<IQueryParamsProps<ListBookingsForAccountsQuery>> = observer(
  (props: IQueryParamsProps<ListBookingsForAccountsQuery>) => {
    const rootStore = useRootStore();
    const quotesModel = rootStore.operations.sales.quotes;
    const accountingModel = quotesModel.accounting;
    const extrasModel = rootStore.operations.extraTypes;
    const customerModel = rootStore.operations.sales.customer;
    const ignoreFilters = props.getQueryParams().ignoreFilters;

    useEffect(() => {
      quotesModel.loadBookingStatuses();
      quotesModel.loadQuoteTypes();
      extrasModel.list.loadExtraTypes();
      props.updateQueryParams({ ignoreFilters: false });
    }, []);

    const saveExcel = () => {
      const fileName = `Bookings_For_Accounting_${DateTime.local().toFormat('yyyyMMddHHmm')}.xlsx`;
      return accountingModel
        .exportBookingsToExcel(props.getQueryParams())
        .then(blob => fileSaver.saveAs(blob, fileName));
    };

    const getFilterFieldDefs = () => {
      return {
        firstTripFrom: {
          fieldType: FieldType.dateField,
          dataAddr: 'firstTripFrom',
          label: 'First Trip From',
        } as FieldDefs,
        firstTripTo: {
          fieldType: FieldType.dateField,
          dataAddr: 'firstTripTo',
          label: 'First Trip To',
          validate: d => {
            if (!d.fieldValue || !d.parentValue.firstTripFrom) {
              return undefined;
            }
            const from = DateTime.fromISO(d.parentValue.firstTripFrom);
            const to = DateTime.fromISO(d.fieldValue);
            return from > to ? 'First Trip To cannot be earlier than First Trip From' : undefined;
          },
        } as FieldDefs,
        lastTrip: {
          fieldType: FieldType.dateField,
          label: 'Booking Ending On',
          dataAddr: 'lastTrip',
        } as FieldDefs,
        hasInvoiceNumber: {
          fieldType: FieldType.yesNoField,
          label: 'Has Invoice Number',
          dataAddr: 'hasInvoiceNumber',
          useValueOnly: true,
        } as FieldDefs,
        hasTradingAccount: {
          fieldType: FieldType.yesNoField,
          label: 'Has Trading Account',
          dataAddr: 'hasTradingAccount',
          useValueOnly: true,
        } as FieldDefs,
        statuses: {
          fieldType: FieldType.selectMultiField,
          label: 'Status',
          dataAddr: 'statuses',
          useValueOnly: true,
          valueKey: 'id',
          descriptionKey: 'description',
          optionItems: quotesModel.bookingStatuses.slice(),
        } as FieldDefs,
        quoteTypes: {
          fieldType: FieldType.selectMultiField,
          label: 'Quote Type',
          dataAddr: 'quoteTypes',
          useValueOnly: true,
          valueKey: 'id',
          descriptionKey: 'description',
          optionItems: quotesModel.quoteTypes.slice(),
          optionRenderer: d => {
            const tradingCompany = quotesModel.tradingCompanies.find(
              tc => tc.id === d.tradingCompanyId
            );
            if (quotesModel.tradingCompanies.length >= 2) {
              return (
                <span>
                  {d.description}
                  <small>
                    &emsp; - &emsp;
                    {tradingCompany?.code}
                  </small>
                </span>
              );
            } else return <span>{d.description}</span>;
          },
        } as FieldDefs,
        extraTypeIds: {
          fieldType: FieldType.selectMultiField,
          label: 'Facility',
          dataAddr: 'extraTypeIds',
          useValueOnly: true,
          valueKey: 'id',
          descriptionKey: 'description',
          optionItems: extrasModel.list.charterExtraTypes.filter(x => x.showInAccounting),
        } as FieldDefs,
        hasSuspendedInvoicing: {
          fieldType: FieldType.yesNoField,
          label: 'Has invoicing suspended?',
          dataAddr: 'hasSuspendedInvoicing',
          useValueOnly: true,
        } as FieldDefs,
        needsVerification: {
          fieldType: FieldType.yesNoField,
          label: 'Price changed after invoice number added?',
          dataAddr: 'needsVerification',
          useValueOnly: true,
        } as FieldDefs,
        customers: {
          fieldType: FieldType.selectAsyncMultiField,
          dataAddr: 'customerIds',
          label: 'Customer',
          valueKey: 'customerId',
          descriptionKey: 'customerName',
          useValueOnly: true,
          loadOptionItems: customerModel.searchCustomers,
          loadItems: customerModel.findCustomers,
        } as FieldDefs,
        salesRepresentatives: {
          fieldType: FieldType.selectMultiField,
          dataAddr: 'salesRepresentativeIds',
          label: 'Sales Person',
          valueKey: 'id',
          descriptionKey: 'name',
          optionItems: rootStore.operationsStartup.salesPeople.slice(),
          useValueOnly: true,
        } as FieldDefs,
        depots: {
          fieldType: FieldType.selectMultiField,
          dataAddr: 'depots',
          label: 'Depot',
          valueKey: 'id',
          descriptionKey: 'description',
          optionItems: rootStore.operationsStartup.operationsDepots.slice(),
          useValueOnly: true,
        } as FieldDefs,
        ignoreFilters: {
          fieldType: FieldType.yesNoField,
          dataAddr: 'ignoreFilters',
          hidden: true,
          label: '',
          useValueOnly: true,
        } as FieldDefs,
      };
    };

    const getPageDef = (): IListPageDef => {
      const filterFieldDefsLookup = getFilterFieldDefs();
      return {
        primaryTitle: <PrimaryTitle title="Bookings"></PrimaryTitle>,
        onLoadData: accountingModel.listItems,
        externalSearch: true,
        hasMoreData: accountingModel.hasMoreItems,
        primarySize: PagePrimarySize.full,
        minChar: 3,
        ignoreFilters,
        primaryActions: [
          {
            actions: [
              {
                actionType: ActionType.actionCollection,
                actionGroups: [
                  {
                    actions: [
                      {
                        actionType: ActionType.actionButton,
                        label: 'Export To Excel',
                        icon: <ExcelIcon fixedWidth />,
                        onClick: () => saveExcel(),
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
        primaryFields: [
          {
            fieldType: FieldType.textField,
            dataAddr: 'quoteNumber',
            label: 'Booking Number',
            linkTo: d => `/sales/bookings/${d.parentValue.id}`,
            columnWidth: '8em',
            formatReadonly: d => (
              <div>
                {d.parentValue.quoteNumber}
                {d.parentValue.isRecurring && (
                  <span className={styles.circledR} title="(Recurring Booking)" />
                )}
                {d.parentValue.hasSubcontractor && (
                  <span className={styles.circledS} title="(Has Subcontractor)" />
                )}
              </div>
            ),
            orderBy: 'QuoteView.QuoteNumber',
          },
          {
            fieldType: FieldType.textField,
            dataAddr: ['customer', 'customerName'],
            label: 'Customer',
            columnWidth: '10em',
            formatReadonly: d => {
              if (!d.parentValue.customer || !d.parentValue.customer.customerId) {
                return null;
              }

              return (
                <Fragment>
                  <div title={d.fieldValue} className="customer">
                    <Link to={`/sales/customers/${d.parentValue.customer.customerId}`}>
                      {d.fieldValue}
                    </Link>
                  </div>
                  <div>{d.parentValue.customer.contactName}</div>
                  {d.parentValue.hasTradingAccount && (
                    <div>
                      <CheckIcon /> Trading Account{' '}
                    </div>
                  )}
                </Fragment>
              );
            },
            orderBy: 'Customer.Name',
          },
          {
            fieldType: FieldType.dateField,
            dataAddr: 'firstTrip',
            label: 'First Trip',
            orderBy: 'QuoteView.FirstTripAsDateTime',
          },
          {
            fieldType: FieldType.readonlyField,
            dataAddr: ['status', 'description'],
            label: 'Completed',
            formatReadonly: data =>
              data.parentValue.status.id === QuoteStatus.Completed ? <CheckIcon /> : null,
            orderBy: 'QuoteView.StatusId',
          },
          {
            fieldType: FieldType.textField,
            dataAddr: 'purchaseOrderNumber',
            label: 'PO Number',
          },
          {
            fieldType: FieldType.textField,
            dataAddr: 'invoiceNumber',
            label: 'Invoice Number',
            orderBy: 'QuoteView.InvoiceNumber',
            formatReadonly: d =>
              d.parentValue.needsVerification && d.fieldValue ? (
                <HighlightBadge highlight={d.parentValue.needsVerification} value={d.fieldValue} />
              ) : (
                d.fieldValue
              ),
          },
          {
            fieldType: FieldType.customField,
            label: 'Invoicing Suspended',
            dataAddr: 'invoicingSuspended',
            render: d => {
              return <InvoicingStatus suspended={d.data.fieldValue} />;
            },
          },
          {
            fieldType: FieldType.textField,
            dataAddr: 'description',
            label: 'Description',
          },
          {
            fieldType: FieldType.customField,
            label: 'Facilities',
            dataAddr: 'extras',
            render: d => {
              const extras = d.data.fieldValue;
              const id = d.data.parentValue.id;
              return <ExtraDetails extras={extras} id={id} />;
            },
          },
          {
            fieldType: FieldType.readonlyField,
            label: 'Summary ',
            columnWidth: '18em',
            formatReadonly: data => {
              const booking = data.parentValue as BookingItem;
              return (
                <div>
                  {!booking.hasJobs && booking.status.id === QuoteStatus.Booked ? (
                    <span className={styles.noJobsWarning}>No Jobs</span>
                  ) : null}
                  {booking.vehicles.map((v, i) => (
                    <div key={i}>
                      {v.quantity} × {v.vehicleType.description}
                    </div>
                  ))}
                  <div>
                    <small>Total Price: </small>
                    <strong>${booking.totalPrice.toFixed(2)}</strong>
                    <small> (inc. GST)</small>
                  </div>
                </div>
              );
            },
          },
        ],
        filterAction: {
          defaultValues: {
            firstTripTo: DateTime.local()
              .plus({ days: 30 })
              .toISODate(),
            hasTradingAccount: false,
            hasSuspendedInvoicing: false,
            statuses: [QuoteStatus.Booked],
            orderBy: 'QuoteView.FirstTripAsDateTime~asc',
            ignoreFilters: false,
          },
          filterFields: Object.keys(filterFieldDefsLookup).map(k => filterFieldDefsLookup[k]),
          filterDef: () => [
            {
              panes: [
                {
                  paneType: PaneType.formFieldsPane,
                  columnCount: 2,
                  fields: [
                    filterFieldDefsLookup.firstTripFrom,
                    filterFieldDefsLookup.firstTripTo,
                    filterFieldDefsLookup.lastTrip,
                  ],
                },
                {
                  paneType: PaneType.formFieldsPane,
                  fields: [
                    filterFieldDefsLookup.hasInvoiceNumber,
                    filterFieldDefsLookup.hasTradingAccount,
                    filterFieldDefsLookup.hasSuspendedInvoicing,
                    filterFieldDefsLookup.needsVerification,
                    filterFieldDefsLookup.statuses,
                    filterFieldDefsLookup.quoteTypes,
                    filterFieldDefsLookup.customers,
                    filterFieldDefsLookup.salesRepresentatives,
                    filterFieldDefsLookup.depots,
                    filterFieldDefsLookup.extraTypeIds,
                    filterFieldDefsLookup.ignoreFilters,
                  ],
                },
              ],
            },
          ],
        },
      };
    };

    return (
      <ListPage className="accounting-component" data={accountingModel.items} def={getPageDef()} />
    );
  }
);

export default withQueryParams(BookingsForAccounting);
