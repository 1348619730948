import SlideInScreen from '../components/layout/SlideInScreen/SlideInScreen';
import styles from './AssetListSlider.module.scss';
import AssetList from './AssetListContainer';
import { BusIcon } from 'src/images/icons';
import { SingleClickAwareButton } from '../../components/presentational/SingleClickAwareButton/SingleClickAwareButton';

export interface IAssetListSliderProps {
  toggleSlider: () => void;
  isOpen: boolean;
  width: number;
}

export const AssetListSlider: React.FC<IAssetListSliderProps> = ({
  toggleSlider,
  isOpen,
  width,
}) => (
  <>
    <SlideInScreen
      className={styles.assetListSlider}
      click={toggleSlider}
      show={isOpen}
      isRightAligned
      width={width}>
      <AssetList />
    </SlideInScreen>
    <SingleClickAwareButton className={styles.assetList} onClick={toggleSlider}>
      <span>Vehicles</span>
      <BusIcon className={styles.busIcon} />
    </SingleClickAwareButton>
  </>
);

export default AssetListSlider;
