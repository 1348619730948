import { buildLabelledTextarea } from 'src/views/componentBuilders';

const fieldId = 'dailyNoteContent';
const label = 'Content';

const DailyNoteContent = buildLabelledTextarea(fieldId, label, {
  maxLength: 1000,
});

export default DailyNoteContent;
