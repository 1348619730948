import { IAjax } from 'src/infrastructure/ajax';
import { workshopUrls } from 'src/domain/services/apiUrls';

// tslint:disable-next-line:no-empty
const returnVoid = () => {};

export default function init(ajax: IAjax) {
  function listComponentTypes() {
    return ajax
      .get(workshopUrls.componentTypeUrls.listComponentTypes())
      .toPromise()
      .then<Workshop.Domain.Queries.ComponentType.ComponentTypeItem[]>(r => r.response);
  }

  function updateComponentTypes(
    command: Workshop.Domain.Commands.ComponentType.UpdateComponentTypesCommand
  ) {
    return ajax
      .put(workshopUrls.componentTypeUrls.updateComponentTypes(), command)
      .toPromise()
      .then(r => returnVoid());
  }

  return {
    componentTypes: {
      listComponentTypes,
      updateComponentTypes,
    },
  };
}
