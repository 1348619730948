import { types, flow, getRoot, cast } from 'mobx-state-tree';
import { JobStatus, JobType, SwapVehicleType, WarningType } from 'src/api/enums';
import { IRootStoreModel } from 'src/domain/entities/RootStoreModel';
import { getAjax, getBus } from 'src/domain/services/storeEnvironment';
import { operationsUrls } from 'src/domain/services/apiUrls';
import { NotificationType } from 'src/domain/services';
import { Duration } from 'luxon';
import { parseTimeSpan } from 'src/infrastructure/dateUtils';
import { AjaxResponse } from 'rxjs';

type JobItem = Operations.Domain.Queries.ViewJob.JobItem;
type CreateAdhocJobCommand = Operations.Domain.Commands.Job.CreateAdhocJob.CreateAdhocJobCommand;
type CreateAdhocJobResult = Operations.Domain.Commands.Job.CreateAdhocJob.CreateAdhocJobResult;
type UpdateJobCommand = Operations.Domain.Commands.Job.UpdateJob.UpdateJobCommand;
type UpdateJobResult = Operations.Domain.Commands.Job.UpdateJob.UpdateJobResult;
type UpdateScheduledBreakResult = Operations.Domain.Commands.Job.UpdateJobScheduledBreak.UpdateScheduledBreakResult;
type CreateRouteTrainingJobCommand = Operations.Domain.Commands.Job.CreateTrainingJob.CreateRouteTrainingJobCommand;
type CreateRouteTrainingJobResult = Operations.Domain.Commands.Job.CreateTrainingJob.CreateRouteTrainingJobResult;
type ActivityLogTransaction = Operations.Domain.Queries.GetActivityLog.ActivityLogTransaction;
type ContinueToJobCommand = Operations.Domain.Commands.Job.ContinueToJob.ContinueToJobCommand;
type ContinueToJobResult = Operations.Domain.Commands.Job.ContinueToJob.ContinueToJobResult;
type DuplicateJobCommand = Operations.Domain.Commands.Job.DuplicateJob.DuplicateJobCommand;
type SplitJobCommand = Operations.Domain.Commands.Job.SplitJob.SplitJobCommand;
type TotalPaidHoursForJob = Operations.Domain.Queries.GetPaidHoursForJob.TotalPaidHoursForJob;
type QuoteDriverPdfDto = Operations.Domain.Commands.Quote.QuoteDriverPdfDto;
type SwapVehicleCommand = Operations.Domain.Commands.Job.SwapVehicleCommand;

function clearClockOnOffIfRequired(job: JobItem): JobItem {
  const clearClockOn =
    job.jobStatus.id === JobStatus.Incomplete ||
    (job.jobType.id === JobType.Charter && !job.departDepot && !job.isTrainingJob);
  const clearClockOff =
    job.jobStatus.id === JobStatus.Incomplete ||
    (job.jobType.id === JobType.Charter && !job.arriveDepot && !job.isTrainingJob);

  return {
    ...job,
    clockOn: clearClockOn ? '' : job.clockOn,
    clockOff: clearClockOff ? '' : job.clockOff,
  };
}

export const JobItemModel = types
  .model('JobItemModel', {
    job: types.maybe(types.frozen<JobItem>()),
    paidHours: types.maybe(types.frozen<Duration>()),
    activityLogs: types.array(types.frozen<ActivityLogTransaction>()),
    progress: types.maybe(
      types.frozen<Operations.Domain.Queries.GetJobProgress.JobProgressResult>()
    ),
    previousAndNextJobProgresses: types.maybe(
      types.frozen<Common.Queries.GetPreviousAndNextJobProgress.PreviousAndNextJobProgressResult>()
    ),
    driverPdfs: types.array(types.frozen<QuoteDriverPdfDto>()),
    scheduledBreaks: types.maybe(
      types.frozen<Operations.Domain.Queries.GetJobScheduledBreaks.JobScheduledBreaksResult>()
    ),
  })
  .actions(self => {
    const ajax = getAjax(self);
    const root = getRoot(self) as IRootStoreModel;
    const bus = getBus(self);

    const listActivityLogs = flow(function*(jobId: string) {
      self.activityLogs = yield ajax.raw
        .get(
          `/api/operations/activity-logs?aggregateType=Job&aggregateId=${jobId}&includeSourceAggregateMatches=true`
        )
        .toPromise()
        .then<Operations.Domain.Queries.GetActivityLog.ActivityLogTransaction[]>(r => {
          return r.response;
        });
    });

    const loadJobProgress = flow(function*(jobId: string) {
      self.progress = yield ajax.operations.jobs.getProgress(jobId);
    });

    const loadScheduledBreaks = flow(function*(jobId: string) {
      self.scheduledBreaks = yield ajax.operations.jobs.getScheduledBreaks(jobId);
    });

    const loadPreviousAndNextJobProgress = flow(function*(jobId: string) {
      self.previousAndNextJobProgresses = yield ajax.operations.jobs.getPreviousAndNextProgresses(
        jobId
      );
    });

    const updateJobProgress = flow(function*(
      command: Operations.Domain.Commands.Job.UpdateJobProgress.UpdateJobProgressCommand
    ) {
      yield ajax.operations.jobs.updateProgress(command);
      yield loadJobProgress(command.jobId);
    });

    const updateScheduledBreaks = flow(function*(
      command: Operations.Domain.Commands.Job.UpdateJobScheduledBreak.UpdateJobScheduledBreakCommand
    ) {
      const updateResult: UpdateScheduledBreakResult = yield ajax.operations.jobs.updateScheduledBreaks(
        command
      );

      yield refreshJobData(command.jobId);

      const defaultWarnings = updateResult.warnings?.filter(
        warning => warning.type === WarningType.Default
      );

      if (defaultWarnings) {
        bus.showWarnings(defaultWarnings);
      }
    });

    const loadJob = flow(function*(jobId: string) {
      const job: JobItem = yield ajax.operations.jobs.viewJob(jobId);
      self.job = clearClockOnOffIfRequired(job);
      self.driverPdfs = cast([]);
    });

    const clearJob = () => {
      self.job = undefined;
      self.driverPdfs = cast([]);
    };

    const createAdhocJob = flow(function*(command: CreateAdhocJobCommand) {
      const createResult: CreateAdhocJobResult = yield ajax.operations.jobs.createAdhocJob(command);
      root.operations.job.listForAllocations.clearItems();
      root.history.push(`/operations/jobs/${createResult.jobId}`);

      const defaultWarnings = createResult.warnings?.filter(
        warning => warning.type === WarningType.Default
      );

      if (defaultWarnings) {
        bus.showWarnings(defaultWarnings);
      }

      return createResult.warnings?.filter(warning => warning.type !== WarningType.Default) ?? [];
    });

    const getPaidHours = flow(function*(jobId: string) {
      const totalPaidHours: TotalPaidHoursForJob = yield ajax.operations.paidHours.getPaidHoursForJob(
        jobId
      );
      const paidHours = parseTimeSpan(totalPaidHours.hours);
      if (!paidHours.isValid) {
        self.paidHours = undefined;
        throw new Error('Cannot calculate paid hours for a job');
      }
      self.paidHours = paidHours;
    });

    const refreshJobData = flow(function*(jobId: string) {
      yield loadJob(jobId);
      yield getPaidHours(jobId);
      yield listActivityLogs(jobId);
      yield loadScheduledBreaks(jobId);

      root.operations.job.listForAllocations.clearItems();
    });

    const updateJob = flow(function*(command: UpdateJobCommand) {
      const updateResult: UpdateJobResult = yield ajax.operations.jobs.updateJob(command);
      yield refreshJobData(command.id);

      const defaultWarnings = updateResult.warnings?.filter(
        warning => warning.type === WarningType.Default
      );

      if (defaultWarnings) {
        bus.showWarnings(defaultWarnings);
      }

      if (self.job && (self.job.parentJob || (self.job.childJobs && self.job.childJobs.length))) {
        bus.showNotification({
          message:
            'Job has been saved successfully. Note that linked Jobs exist and may also need to be updated.',
          options: { type: NotificationType.warn },
        });
      }

      return updateResult.warnings?.filter(warning => warning.type !== WarningType.Default) ?? [];
    });

    const deleteJob = flow(function*(jobId: string, forceDelete: boolean | undefined) {
      yield ajax.operations.jobs.deleteJob(jobId, forceDelete);

      self.job = undefined;
      root.history.replace('/operations/jobs?defaultFilter=true');
    });

    const cancelJob = flow(function*(jobId: string) {
      yield ajax.operations.jobs.cancelJob(jobId);
      yield refreshJobData(jobId);
      yield loadJobProgress(jobId);
    });

    const duplicateJob = flow(function*(command: DuplicateJobCommand) {
      const duplicateJobId = yield ajax.operations.jobs.duplicateJob(command);
      window.open(`/operations/jobs/${duplicateJobId}`, '_blank');
    });

    const splitJob = flow(function*(command: SplitJobCommand) {
      const newJobId = yield ajax.operations.jobs.splitJob(command);
      root.history.push(`/operations/jobs/${newJobId}`);

      root.operations.job.listForAllocations.clearItems();
    });

    const createRouteTrainingJob = flow(function*(command: CreateRouteTrainingJobCommand) {
      const createResult: CreateRouteTrainingJobResult = yield ajax.raw
        .post(operationsUrls.jobUrls.createRouteTrainingJob(), command)
        .toPromise()
        .then(r => r.response);
      root.history.push(`/operations/jobs/${createResult.jobId}`);
      bus.showWarnings(createResult.warnings);
    });

    const acknowledgeJobOnBehalf = flow(function*(jobId: string, staffMemberId: string) {
      yield ajax.raw
        .put(operationsUrls.jobUrls.acknowledgeOnBehalf(jobId, staffMemberId))
        .toPromise();
      yield loadJob(jobId);
      yield listActivityLogs(jobId);
    });

    const continueToNextJob = flow(function*(changeover: string) {
      if (!self.job) {
        throw new Error('Cannot continue to next job as Job not loaded');
      }
      if (!self.job.continuation.toJob) {
        throw new Error('Cannot continue to next job as Job has no next job');
      }
      const result: AjaxResponse = yield ajax.raw
        .post(operationsUrls.jobUrls.continueToNextJob(self.job.id), {
          jobId: self.job.id,
          nextJobId: self.job.continuation.toJob.jobId,
          changeover,
        } as ContinueToJobCommand)
        .toPromise();

      const continuationResult = result.response as ContinueToJobResult;

      bus.showWarnings(continuationResult.warnings);
      yield loadJob(self.job.id);
      yield getPaidHours(self.job.id);
    });

    const continueFromPrevJob = flow(function*(changeover: string) {
      if (!self.job) {
        throw new Error('Cannot continue from previous job as Job not loaded');
      }
      if (!self.job.continuation.fromJob) {
        throw new Error('Cannot continue from previous job as Job has no previous job');
      }
      const result: AjaxResponse = yield ajax.raw
        .post(operationsUrls.jobUrls.continueToNextJob(self.job.continuation.fromJob.jobId), {
          jobId: self.job.continuation.fromJob.jobId,
          nextJobId: self.job.id,
          changeover,
        } as ContinueToJobCommand)
        .toPromise();

      const continuationResult = result.response as ContinueToJobResult;
      bus.showWarnings(continuationResult.warnings);

      yield loadJob(self.job.id);
      yield getPaidHours(self.job.id);
    });

    const stopContinueToNextJob = flow(function*() {
      if (!self.job) {
        throw new Error('Cannot stop continue to next job as Job not loaded');
      }
      yield ajax.raw.post(operationsUrls.jobUrls.stopContinueToNextJob(self.job.id)).toPromise();
      yield loadJob(self.job.id);
      yield getPaidHours(self.job.id);
    });

    const stopContinueFromPrevJob = flow(function*() {
      if (!self.job) {
        throw new Error('Cannot stop continue from previous job as Job not loaded');
      }
      if (!self.job.continuation.fromJob) {
        throw new Error('Cannot stop continue from previous job as Job has no previous job');
      }
      yield ajax.raw
        .post(operationsUrls.jobUrls.stopContinueToNextJob(self.job.continuation.fromJob.jobId))
        .toPromise();
      yield loadJob(self.job.id);
      yield getPaidHours(self.job.id);
    });

    const exportActivitiesToExcel = flow(function*() {
      if (!self.job) {
        throw new Error('Cannot export activity log as Job not loaded');
      }
      root.notifications.addNotification(`The file is being generated ...`, {
        type: NotificationType.info,
      });

      return yield ajax.raw
        .getFile(
          `/api/operations/jobs/${self.job.id}/export-activities-to-excel?aggregateType=Job&aggregateId=${self.job.id}&includeSourceAggregateMatches=true`
        )
        .toPromise()
        .then<Blob>(r => r.response);
    });

    const loadDriverPdfs = flow(function*() {
      if (!self.job) {
        return;
      }

      self.driverPdfs = yield ajax.raw
        .get(`/api/operations/job/${self.job.id}/driver-pdfs`)
        .toPromise()
        .then<QuoteDriverPdfDto[]>(r => r.response);
    });

    const generateJobFatigueBreaks = flow(function*(jobId: string) {
      yield ajax.operations.jobs.generateJobFatigueBreaks(jobId);
      yield refreshJobData(jobId);
    });

    const swapVehicle = flow(function*(command: SwapVehicleCommand) {
      const newJobId = yield ajax.operations.jobs.swapVehicle(command);

      if (command.swapType === SwapVehicleType.CreateNewJob) {
        window.open(`/operations/jobs/${newJobId}`, '_blank');
      } else {
        yield refreshJobData(command.existingJobId);
      }
    });

    const restoreJob = flow(function*(jobId: string) {
      yield ajax.operations.jobs.restoreJob(jobId);
      yield refreshJobData(jobId);
      yield loadJobProgress(jobId);
    });

    return {
      loadJob,
      createAdhocJob,
      createRouteTrainingJob,
      updateJob,
      splitJob,
      deleteJob,
      cancelJob,
      duplicateJob,
      acknowledgeJobOnBehalf,
      listActivityLogs,
      loadJobProgress,
      updateJobProgress,
      continueToNextJob,
      continueFromPrevJob,
      stopContinueToNextJob,
      stopContinueFromPrevJob,
      getPaidHours,
      exportActivitiesToExcel,
      loadDriverPdfs,
      generateJobFatigueBreaks,
      swapVehicle,
      restoreJob,
      loadPreviousAndNextJobProgress,
      clearJob,
      loadScheduledBreaks,
      updateScheduledBreaks,
    };
  });
