import { buildListPageApiSearchModelTypedQuery } from 'src/domain/modelBuilders/buildListPageApiSearchModel';

type ListGenerateJobsOperationsQuery = Operations.Domain.Queries.ListGenerateJobsOperations.ListGenerateJobsOperationsQuery;

export const ListGenerateJobsOperationsModel = buildListPageApiSearchModelTypedQuery<
  ListGenerateJobsOperationsQuery
>()(
  'ListGenerateJobsOperationsModel',
  d => d.ajax.operations.generateJobsOperations.listGenerateJobsOperations(d.query),
  { size: 25 }
);
