import './Shell.scss';
import { createRef, useState, useEffect, ReactNode } from 'react';
import cn from 'classnames';
import { Route, RouteComponentProps } from 'react-router-dom';
import { Redirect, withRouter } from 'react-router';
import { IS_PRODUCTION, isDevelopment } from 'src/appSettings';
import ShellHeader from './ShellHeader';
import ShellNav from './ShellNav';
import ShellHomeBackground from './ShellHomeBackground';
import { IShellNavItem } from 'src/views/shellNavItems';
import Button from 'reactstrap/lib/Button';
import { INotificationOptions } from 'src/domain/baseTypes';
import { IAccountModel } from 'src/domain/entities/user/AccountModel';
import { Observable, Subscription } from 'rxjs';
import { IOperationsDomainEvent } from 'src/domain/services/storeBus';
import logger from 'src/infrastructure/logging';
import { catchError } from 'rxjs/operators';
import { of } from 'rxjs/observable/of';

type JobWithNoDriverClockedOn = Operations.Domain.Queries.ListJobsWithNoDriverClockedOnQuery.JobWithNoDriverClockedOn;
export interface IShellProps {
  canManageWorkshop: boolean;
  canManageSales: boolean;
  canManageOperationsOrRail: boolean;
  canManagePeopleOrTimesheets: boolean;
  canManageAccounting: boolean;
  canManageCompliance: boolean;

  uiRouteWorkshop: string;
  uiRouteSales: string;
  uiRouteOperations: string;
  uiRoutePeople: string;
  uiRouteAccounting: string;
  uiRouteCompliance: string;

  onSignOut: () => void;
  items?: Array<IShellNavItem>;
  isAuthenticated: boolean | undefined;
  releaseMismatch: boolean;
  refreshSite: () => void;
  showAlertForJobWithNoDriversClockedOn: (event: JobWithNoDriverClockedOn) => void;
  userAccount: IAccountModel;
  addNotification: (message: string, options?: Partial<INotificationOptions>) => void;
  domainEvents: Observable<IOperationsDomainEvent>;
  children?: ReactNode | undefined;
}

type ShellRouteComponentProps = IShellProps & RouteComponentProps<{}>;

const Shell: React.FC<ShellRouteComponentProps> = (props: ShellRouteComponentProps) => {
  const contentsRef = createRef<HTMLDivElement>();
  let domainEventSubscription: Subscription | undefined;

  const [spacerRequired, setSpacerRequired] = useState<boolean>(false);
  const [isNavOpen, setIsNavOpen] = useState<boolean>(true);

  const getNumericValue = (b: boolean) => (b ? 1 : 0);

  const doesUserHaveAccessToOneMenuSectionOnly = () =>
    getNumericValue(props.canManageWorkshop) +
      getNumericValue(props.canManageSales) +
      getNumericValue(props.canManageOperationsOrRail) +
      getNumericValue(props.canManagePeopleOrTimesheets) +
      getNumericValue(props.canManageAccounting) +
      getNumericValue(props.canManageCompliance) ===
    1;

  const getUrlToFirstFullyPermittedSection = () => {
    return (
      (props.canManageWorkshop && `${props.uiRouteWorkshop}?expand=true`) ||
      (props.canManageSales && `${props.uiRouteSales}?expand=true`) ||
      (props.canManageOperationsOrRail && `${props.uiRouteOperations}?expand=true`) ||
      (props.canManagePeopleOrTimesheets && `${props.uiRoutePeople}?expand=true`) ||
      (props.canManageAccounting && `${props.uiRouteAccounting}?expand=true&defaultFilter=true`) ||
      (props.canManageCompliance && `${props.uiRouteCompliance}?expand=true&defaultFilter=true`) ||
      ''
    );
  };

  const updateSpacerRequired = () => {
    // Apply spacers for old-style screens that don't use Page components
    // When no more old screens exist, this logic can be removed
    const req = contentsRef.current
      ? !contentsRef.current.getElementsByClassName('page-area-component').length
      : false;
    if (req !== spacerRequired) {
      setSpacerRequired(req);
    }
  };

  const registerJobsWithNoDriverClockedOnAlert = () => {
    const source$ = props.domainEvents.filter(e => e.eventName === 'JobWithNoDriverClockedOn');
    domainEventSubscription = source$.subscribe(e => {
      logger.info('JobWithNoDriverClockedOn : {@event}', e);
      props.showAlertForJobWithNoDriversClockedOn(e.payload as JobWithNoDriverClockedOn);
    });

    const catch$ = source$.pipe(catchError(err => of(err)));

    catch$.subscribe(e => {
      logger.error(e, { detail: 'JobWithNoDriverClockedOn' });
    });
  };

  useEffect(() => {
    updateSpacerRequired();
    if (props.userAccount.isOperationsDepartmentMember) {
      registerJobsWithNoDriverClockedOnAlert();
    }

    return () => {
      logger.info('Shell unsubscribed from domain events');
      domainEventSubscription?.unsubscribe();
    };
  }, []);

  const { userAccount, onSignOut, releaseMismatch, refreshSite } = props;
  return (
    <div
      className={cn(
        location.pathname === '/' ? 'shell-component shell-component-nonav' : 'shell-component',
        {
          'non-production': !IS_PRODUCTION,
          'development-build': isDevelopment(),
        },
        isNavOpen ? 'openNav' : 'closedNav'
      )}>
      {location.pathname === '/' && doesUserHaveAccessToOneMenuSectionOnly() && (
        <Redirect to={getUrlToFirstFullyPermittedSection()} />
      )}
      {location.pathname === '/' ? <Route path="/" component={ShellHomeBackground} /> : null}
      <ShellHeader id="shell-header-element" className="shell-header" />
      <ShellNav
        className="shell-nav"
        items={props.items}
        isOpen={isNavOpen}
        setIsOpen={setIsNavOpen}
        isAuthenticated={props.isAuthenticated}
        userAccount={userAccount}
        onSignOut={onSignOut}
      />
      <div
        className={cn('shell-contents', { 'use-legacy-styles': spacerRequired })}
        ref={contentsRef}>
        {props.children}
      </div>
      <div id="shell-popup-location" />
      <div id="shell-modal-dom-location" />
      {releaseMismatch && location.pathname !== '/' ? (
        <div className="release-mismatch">
          <span>
            <span>The system has been updated! Please </span>
            <Button className="refresh" onClick={refreshSite}>
              refresh
            </Button>
            <span> to use the latest version.</span>
          </span>
        </div>
      ) : null}
    </div>
  );
};

// TS wants StaticContext...dunno what that is
// @ts-ignore
export default withRouter<ShellRouteComponentProps>(Shell);
