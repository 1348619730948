import { FatigueBreachEventStatus } from 'src/api/enums';
import { CheckIcon } from 'src/images/icons';
import { getSubmitCloseModalActionGroupDef } from 'src/views/definitionBuilders/common';
import {
  ActionDefs,
  ActionType,
  PaneType,
  ShellModalSize,
} from 'src/views/definitionBuilders/types';

export default function getCloseFatigueBreachEventActionButtonDef(
  onCloseFatigueBreachEvent: (breachId: string) => Promise<void>,
  onClose: () => void
): ActionDefs {
  return {
    actionType: ActionType.modalActionButton,
    label: 'Mark as Closed',
    hidden: d => d.parentValue.status !== FatigueBreachEventStatus.Open,
    icon: <CheckIcon />,
    modalSize: ShellModalSize.oneQuarter,
    modalDef: (modalDefApi: any) => ({
      title: 'Mark as Closed',
      asForm: true,
      panels: [
        {
          panes: [
            {
              paneType: PaneType.customPane,
              render: () => <span>Are you sure you want to close this fatigue breach event?</span>,
            },
          ],
        },
      ],
      secondaryActions: [getSubmitCloseModalActionGroupDef('Ok')],
      onFormSubmit: () => {
        const fatigueBreachEventId = modalDefApi.actionData.parentValue.id;
        return onCloseFatigueBreachEvent(fatigueBreachEventId).then(() => onClose());
      },
    }),
  };
}
