import { types, flow } from 'mobx-state-tree';
import { getAjax } from 'src/domain/services/storeEnvironment';
import { apiUrls } from 'src/domain';

type AssetOdometerReadingsByJobDto = Operations.Domain.Queries.ListAssetOdometerReadings.AssetOdometerReadingsByJobDto;
type AssetOdometerReadingDto = Common.Queries.Operations.AssetOdometerReadings.AssetOdometerReadingDto;
type OverrideAssetOdometerReadingsCommand = Operations.Domain.Commands.AssetOdometerReadings.OverrideAssetOdometerReadingsCommand;

export const OperationsAssetOdometerReadingsModel = types
  .model('OperationsAssetOdometerReadingsModel', {
    assetOdometerReadings: types.array(types.frozen<AssetOdometerReadingsByJobDto>()),
    hasMoreData: types.optional(types.boolean, false),
  })
  .actions(self => {
    const ajax = getAjax(self);

    const loadAssetOdometerReadings = flow(function*(
      assetId: string,
      from: string | undefined,
      to: string | undefined
    ) {
      if (assetId !== '') {
        self.assetOdometerReadings = yield ajax.raw
          .get(apiUrls.operationsUrls.assetOdometerReadingsUrls.list(assetId, from, to))
          .toPromise()
          .then<AssetOdometerReadingsByJobDto[]>(r => r.response);
      }
    });

    const submitAssetOdometerReadings = flow(function*(
      assetId: string,
      editedItems: AssetOdometerReadingDto[]
    ) {
      const command: OverrideAssetOdometerReadingsCommand = {
        assetId,
        editedItems,
      };
      yield ajax.raw
        .put(apiUrls.operationsUrls.assetOdometerReadingsUrls.override(assetId), command)
        .toPromise()
        .then();
    });

    return {
      loadAssetOdometerReadings,
      submitAssetOdometerReadings,
    };
  });
