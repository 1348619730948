import { types, flow } from 'mobx-state-tree';
import { getAjax } from 'src/domain/services/storeEnvironment';

type VehicleType = Common.Dtos.VehicleTypeItem;

export const VehicleTypesModel = types
  .model('VehicleTypesModel', {
    vehicleTypes: types.array(types.frozen<VehicleType>()),
  })
  .actions(self => {
    const ajax = getAjax(self);

    const loadVehicleTypes = flow(function*() {
      self.vehicleTypes = yield ajax.operations.vehicleTypes.loadVehicleTypes();
    });

    return {
      loadVehicleTypes,
    };
  });
