import { IAjax } from 'src/infrastructure/ajax';
import { kioskUrls } from 'src/domain/services/apiUrls';

type CubicFaultDetail = Workshop.Domain.AggregatesModel.AssetAggregate.CubicFaultDetail;
type CubicItem = Workshop.Domain.AggregatesModel.AssetAggregate.CubicItem;

export default function init(ajax: IAjax) {
  function loadCubicFaults() {
    return ajax
      .get(kioskUrls.assetCubic.cubicFaultDetails)
      .toPromise()
      .then<CubicFaultDetail[]>(r => r.response);
  }

  function loadCubicItems() {
    return ajax
      .get(kioskUrls.assetCubic.cubicItems)
      .toPromise()
      .then<CubicItem[]>(r => r.response);
  }

  return {
    cubic: {
      loadCubicFaults,
      loadCubicItems,
    },
  };
}
