import { getSubmitCloseModalActionGroupDef } from 'src/views/definitionBuilders/common';
import { PaneType, FieldType, ModalDefBuilder } from 'src/views/definitionBuilders/types';

type CreateStaffMemberEquipmentCommand = People.Domain.Commands.StaffMembers.Equipment.CreateStaffMemberEquipmentCommand;
type UpdateStaffMemberEquipmentCommand = People.Domain.Commands.StaffMembers.Equipment.UpdateStaffMemberEquipmentCommand;
type StaffMemberEquipmentItem = People.Domain.Queries.ViewStaffMember.StaffMemberItem.StaffMemberEquipmentItem;
type EquipmentTypeItem = People.Domain.Queries.EquipmentType.EquipmentTypeItem;

export default function getEquipmentModalDef(
  mode: 'add' | 'edit',
  allEquipmentTypes: EquipmentTypeItem[],
  staffMemberId: string,
  createEquipment: (cmd: CreateStaffMemberEquipmentCommand) => Promise<void>,
  updateEquipment: (cmd: UpdateStaffMemberEquipmentCommand) => Promise<void>
): ModalDefBuilder {
  return modalDefApi => ({
    title: `${mode === 'add' ? 'Add' : 'Edit'} Equipment`,
    asForm: true,
    explicitData: mode === 'add' ? {} : modalDefApi.actionData.paneValue,
    panels: [
      {
        panes: [
          {
            paneType: PaneType.formFieldsPane,
            columnCount: 1,
            fields: [
              {
                fieldType: FieldType.selectField,
                label: 'Equipment Type',
                dataAddr: 'equipmentTypeId',
                optionItems: allEquipmentTypes,
                valueKey: 'id',
                descriptionKey: 'description',
                useValueOnly: true,
                mandatory: true,
                readonly: mode === 'edit',
              },
              {
                fieldType: FieldType.textField,
                label: 'Description',
                dataAddr: 'description',
                mandatory: false,
                maxLength: 200,
                tooltip: 'Record the style, make, model or serial no of the equipment',
              },
              {
                fieldType: FieldType.textField,
                label: 'Size',
                dataAddr: 'size',
                mandatory: false,
                maxLength: 50,
              },
              {
                fieldType: FieldType.numericField,
                label: 'Quantity',
                dataAddr: 'quantity',
                mandatory: true,
              },
              {
                fieldType: FieldType.dateField,
                dataAddr: 'dateIssued',
                label: 'Issued',
                columnWidth: '11em',
                mandatory: true,
              },
            ],
          },
        ],
      },
    ],
    secondaryActions: [getSubmitCloseModalActionGroupDef('Ok')],
    onFormPreSubmit: (
      equipment: StaffMemberEquipmentItem
    ): CreateStaffMemberEquipmentCommand | UpdateStaffMemberEquipmentCommand => {
      return {
        staffMemberId: staffMemberId,
        equipmentId: equipment.id,
        equipmentTypeId: equipment.equipmentTypeId,
        description: equipment.description,
        size: equipment.size,
        quantity: equipment.quantity,
        dateIssued: equipment.dateIssued,
      };
    },
    onFormSubmit: mode === 'add' ? createEquipment : updateEquipment,
  });
}
