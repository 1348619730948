import { DateTime } from 'luxon';
import CompanyLogo from 'src/views/components/CompanyLogo/CompanyLogo';
import styles from './DriverDailyJobSummaryPdfHeader.module.scss';

export const DriverDailyJobSummaryPdfHeader: React.FC<{
  userDisplayName: string;
  date: DateTime;
}> = ({ userDisplayName, date }) => (
  <div className={styles.root}>
    <table>
      <tbody>
        <tr>
          <td>
            <CompanyLogo mode="full" />
          </td>
          <td className={styles.textRight}>
            <h2>Driver Daily Job Summary</h2>
          </td>
        </tr>
      </tbody>
    </table>
    <table>
      <tbody>
        <tr>
          <td className={styles.subtitle}>{userDisplayName}</td>
          <td className={styles.date}>Date {date.toLocaleString(DateTime.DATE_HUGE)}</td>
        </tr>
      </tbody>
    </table>
  </div>
);
