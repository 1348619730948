import * as queryString from 'query-string';
import { buildListPageApiSearchModelTypedQuery } from 'src/domain/modelBuilders/buildListPageApiSearchModel';

type RosterGroupListItem = Operations.Domain.Queries.ListRosterGroups.RosterGroupListItem;
type ListRosterGroupQuery = Operations.Domain.Queries.ListRosterGroups.ListRosterGroupsQuery;

export const ListRosterGroupModel = buildListPageApiSearchModelTypedQuery<
  ListRosterGroupQuery
>()('ListRosterGroupModel', d =>
  d.ajax.raw
    .get(`/api/operations/roster-groups?${queryString.stringify(d.query)}`)
    .map(r => r.response as Common.Dtos.ListResult<RosterGroupListItem>)
);
