import { types, flow } from 'mobx-state-tree';
import { getAjax } from 'src/domain/services';

type RosterListItem = Operations.Domain.Queries.ListRosters.RosterListItem;
type RotatingRosterListItem = Operations.Domain.Queries.ListRotatingRosterStaffMembers.RotatingRosterListItem;

export const RostersModel = types
  .model('RostersModel', {
    rosters: types.array(types.frozen<RosterListItem>()),
    rotatingRosters: types.array(types.frozen<RotatingRosterListItem>()),
  })
  .actions(self => {
    const ajax = getAjax(self);

    const listRosters = flow(function*() {
      self.rosters = yield ajax.urban.rosters.listRosters();
    });

    const listStaffMembersForRotatingRosters = flow(function*(startDate: string) {
      self.rotatingRosters = yield ajax.urban.rosters.listStaffMembersForRotatingRosters(startDate);
    });

    return {
      listRosters,
      listStaffMembersForRotatingRosters,
    };
  });
