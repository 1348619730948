import { IAjax } from 'src/infrastructure/ajax';
import { peopleUrls } from 'src/domain/services/apiUrls';
type GetWeekWithoutTimesheetQuery = People.Domain.Queries.GetWeekWithoutTimesheet.GetWeekWithoutTimesheetQuery;
type TimesheetWeek = People.Domain.Queries.GetWeekWithoutTimesheet.TimesheetWeek;
type TimesheetItem = Common.Dtos.TimesheetItem;
type CreateTimesheetCommand = People.Domain.Commands.Timesheets.CreateTimesheet.CreateTimesheetCommand;
type UpdateTimesheetCommand = People.Domain.Commands.Timesheets.UpdateTimesheet.UpdateTimesheetCommand;
type ListTimesheetsQuery = People.Domain.Queries.ListTimesheets.ListTimesheetsQuery;
type ListDetailedTimesheetsQuery = People.Domain.Queries.ListDetailedTimesheets.ListDetailedTimesheetsQuery;
type ListTimesheetNotesQuery = People.Domain.Queries.ListTimesheetNotes.ListTimesheetNotesQuery;
type ListTimesheetItem = People.Domain.Queries.ListTimesheets.ListTimesheetItem;
type RefreshTimesheetCommand = People.Domain.Commands.Timesheets.RefreshTimesheet.RefreshTimesheetCommand;
type AmendTimesheetCommand = People.Domain.Commands.Timesheets.AmendTimesheet.AmendTimesheetCommand;

export default function init(ajax: IAjax) {
  function getWeekWithoutTimesheet(query: Partial<GetWeekWithoutTimesheetQuery>) {
    return ajax
      .get(peopleUrls.timesheetsUrls.getWeekWithoutTimesheet(query))
      .toPromise()
      .then<TimesheetWeek>(r => r.response);
  }

  function viewTimesheet(timesheetId: string) {
    return ajax
      .get(peopleUrls.timesheetsUrls.viewTimesheet(timesheetId))
      .toPromise()
      .then<TimesheetItem>(r => r.response);
  }

  function createTimesheet(command: CreateTimesheetCommand) {
    return ajax
      .post(peopleUrls.timesheetsUrls.createTimesheet(), command)
      .toPromise()
      .then<string>(r => r.response);
  }

  function updateTimesheet(command: UpdateTimesheetCommand) {
    return ajax
      .put(peopleUrls.timesheetsUrls.updateTimesheet(command.id), command)
      .toPromise()
      .then<string>(r => r.response);
  }

  function amendTimesheet(command: AmendTimesheetCommand) {
    return ajax
      .put(peopleUrls.timesheetsUrls.amendTimesheet(command.id), command)
      .toPromise()
      .then<string>(r => r.response);
  }

  function approveTimesheet(timesheetId: string) {
    return ajax.put(peopleUrls.timesheetsUrls.approveTimesheet(timesheetId)).toPromise();
  }

  function processTimesheet(timesheetId: string) {
    return ajax.put(peopleUrls.timesheetsUrls.processTimesheet(timesheetId)).toPromise();
  }

  function processTimesheets(searchQuery: Partial<ListTimesheetsQuery>) {
    return ajax.put(peopleUrls.timesheetsUrls.processTimesheets(searchQuery)).toPromise();
  }

  function exportTimesheets(query: Partial<ListTimesheetsQuery>) {
    return ajax
      .getFile(peopleUrls.timesheetsUrls.exportTimesheets(query))
      .toPromise()
      .then<Blob>(r => r.response);
  }

  function exportDetailedTimesheets(query: Partial<ListDetailedTimesheetsQuery>) {
    return ajax
      .getFile(peopleUrls.timesheetsUrls.exportDetailedTimesheets(query))
      .toPromise()
      .then<Blob>(r => r.response);
  }

  function exportTimesheetNotes(query: Partial<ListTimesheetNotesQuery>) {
    return ajax
      .getFile(peopleUrls.timesheetsUrls.exportTimesheetNotes(query))
      .toPromise()
      .then<Blob>(r => r.response);
  }

  function refreshTimesheet(command: RefreshTimesheetCommand) {
    return ajax
      .post(peopleUrls.timesheetsUrls.refreshTimesheet(command.timesheetId), command)
      .toPromise();
  }

  function printTimesheet(timesheetId: string) {
    return ajax
      .getFile(peopleUrls.timesheetsUrls.printTimesheet(timesheetId))
      .toPromise()
      .then<Blob>(r => r.response);
  }

  function listTimesheets(query: Partial<ListTimesheetsQuery>) {
    return ajax
      .get(peopleUrls.timesheetsUrls.listTimesheets(query))
      .map(r => r.response as Common.Dtos.ListResult<ListTimesheetItem>);
  }

  function printTimesheets(query: Partial<ListTimesheetsQuery>) {
    return ajax
      .getFile(peopleUrls.timesheetsUrls.printTimesheets(query))
      .toPromise()
      .then<Blob>(r => r.response);
  }

  return {
    timesheet: {
      getWeekWithoutTimesheet,
      viewTimesheet,
      createTimesheet,
      updateTimesheet,
      amendTimesheet,
      listTimesheets,
      approveTimesheet,
      refreshTimesheet,
      processTimesheet,
      processTimesheets,
      printTimesheets,
      printTimesheet,
      exportTimesheets,
      exportDetailedTimesheets,
      exportTimesheetNotes,
    },
  };
}
