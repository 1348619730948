import { Table } from 'reactstrap';
import './PrintJobTaskDetails.scss';

interface IPrintJobTaskDetailsProps {}

const PrintJobTaskDetails: React.FC<IPrintJobTaskDetailsProps> = () => {
  const array = Array(15).fill(0);
  return (
    <div className="print-job-task-details-component">
      <Table>
        <thead>
          <tr>
            <th colSpan={2}>Job Details</th>
            <th colSpan={2}>Mechanic</th>
            <th colSpan={2}>Foreman</th>
          </tr>
          <tr>
            <th>Task Details</th>
            <th className="correction">Correction / Action</th>
            <th className="initials">Initials</th>
            <th className="complete">Complete</th>
            <th className="initials">Initials</th>
            <th className="rebook">Rebook</th>
          </tr>
        </thead>
        <tbody>
          {array.map((a, i) => {
            return (
              <tr key={i}>
                <td />
                <td className="correction" />
                <td className="initials" />
                <td className="complete" />
                <td className="initials" />
                <td className="rebook" />
              </tr>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
};

export default PrintJobTaskDetails;
