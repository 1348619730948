import { UiRouteName } from 'src/domain/baseTypes';
import { IRootStoreModel } from 'src/domain/entities/RootStoreModel';
import UiRoute from 'src/domain/services/buildUiRoutes/UiRoute';

export default function buildUiRoutes(store: IRootStoreModel) {
  const supplierCreate = new UiRoute(
    UiRouteName.workshopSupplierCreate,
    '/workshop/suppliers/create',
    () => 'Create Supplier',
    false
  );

  const manageSupplier = new UiRoute(
    UiRouteName.workshopSupplierEdit,
    '/workshop/suppliers/:id',
    () => 'Manage Supplier',
    false
  );

  const suppliersList = new UiRoute(
    UiRouteName.workshopSupplierList,
    '/workshop/suppliers',
    () => 'Suppliers',
    false,
    true,
    [supplierCreate, manageSupplier]
  );

  const partCreate = new UiRoute(
    UiRouteName.workshopPartCreate,
    '/workshop/parts/create',
    () => 'Create Part',
    false
  );

  const partManage = new UiRoute(
    UiRouteName.workshopPartEdit,
    '/workshop/parts/:id',
    () => 'Manage Part',
    false
  );

  const recordStocktake = new UiRoute(
    UiRouteName.workshopInventoryStocktake,
    '/workshop/parts/stocktake',
    () => 'Stocktake',
    false
  );

  const partTransactionsList = new UiRoute(
    UiRouteName.workshopPartTransactions,
    '/workshop/parts/transactions',
    () => 'Part Transactions',
    false
  );

  const partsList = new UiRoute(
    UiRouteName.workshopPartsList,
    '/workshop/parts',
    () => 'Parts',
    false,
    true,
    [partTransactionsList, recordStocktake, partCreate, partManage]
  );

  const returnedPartCreditCreate = new UiRoute(
    UiRouteName.workshopReturnedPartCreditCreate,
    `/workshop/purchase-orders/:purchaseOrderId/goods-received/:goodsReceivedId/returned-part-credits/create`,
    () => 'Receive Returned Part Credit',
    false
  );

  const returnedPartCreditEdit = new UiRoute(
    UiRouteName.workshopReturnedPartCreditEdit,
    `/workshop/purchase-orders/:purchaseOrderId/goods-received/:goodsReceivedId/returned-part-credits/:creditNoteId`,
    () => 'Returned Part Credit',
    false
  );

  const purchaseOrderGoodsReceivedCreate = new UiRoute(
    UiRouteName.workshopPurchaseOrderGoodsReceivedCreate,
    '/workshop/purchase-orders/:purchaseOrderId/goods-received/create',
    () => 'Receive Goods',
    false
  );

  const purchaseOrderGoodsReceivedEdit = new UiRoute(
    UiRouteName.workshopPurchaseOrderGoodsReceivedEdit,
    '/workshop/purchase-orders/:purchaseOrderId/goods-received/:goodsReceivedId',
    () => 'Goods Received',
    false,
    false,
    [returnedPartCreditEdit, returnedPartCreditCreate]
  );

  const purchaseOrderInvoicesEdit = new UiRoute(
    UiRouteName.workshopPurchaseOrderInvoicesEdit,
    '/workshop/purchase-orders/:purchaseOrderId/invoices',
    () => 'Invoices',
    false
  );

  const purchaseOrderCreate = new UiRoute(
    UiRouteName.workshopPurchaseOrderCreate,
    '/workshop/purchase-orders/create',
    () => 'Create Purchase Order',
    false
  );

  const managePurchaseOrder = new UiRoute(
    UiRouteName.workshopPurchaseOrderEdit,
    '/workshop/purchase-orders/:id',
    () => 'Manage Purchase Order',
    true,
    false,
    [purchaseOrderGoodsReceivedEdit, purchaseOrderGoodsReceivedCreate, purchaseOrderInvoicesEdit]
  );

  const purchaseOrdersList = new UiRoute(
    UiRouteName.workshopPurchaseOrdersList,
    '/workshop/purchase-orders',
    () => 'Purchase Orders',
    false,
    true,
    [purchaseOrderCreate, managePurchaseOrder]
  );

  return {
    [UiRouteName.workshopInventoryStocktake]: recordStocktake,
    [UiRouteName.workshopPartTransactions]: partTransactionsList,
    [UiRouteName.workshopSupplierList]: suppliersList,
    [UiRouteName.workshopSupplierCreate]: supplierCreate,
    [UiRouteName.workshopSupplierEdit]: manageSupplier,
    [UiRouteName.workshopPartsList]: partsList,
    [UiRouteName.workshopPartCreate]: partCreate,
    [UiRouteName.workshopPartEdit]: partManage,
    [UiRouteName.workshopPurchaseOrderCreate]: purchaseOrderCreate,
    [UiRouteName.workshopPurchaseOrdersList]: purchaseOrdersList,
    [UiRouteName.workshopPurchaseOrderEdit]: managePurchaseOrder,
    [UiRouteName.workshopPurchaseOrderGoodsReceivedCreate]: purchaseOrderGoodsReceivedCreate,
    [UiRouteName.workshopPurchaseOrderGoodsReceivedEdit]: purchaseOrderGoodsReceivedEdit,
    [UiRouteName.workshopReturnedPartCreditCreate]: returnedPartCreditCreate,
    [UiRouteName.workshopReturnedPartCreditEdit]: returnedPartCreditEdit,
    [UiRouteName.workshopPurchaseOrderInvoicesEdit]: purchaseOrderInvoicesEdit,
  };
}
