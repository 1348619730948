import React from 'react';
import { FieldInfoTooltip } from '../../FieldInfoTooltip';
import styles from './PrimaryTitle.module.scss';

interface IPrimaryInterfaceProps {
  title: string;
  link?: string;
  notes?: string;
}

const PrimaryTitle: React.FC<IPrimaryInterfaceProps> = (props: IPrimaryInterfaceProps) => {
  return (
    <div className={styles.primaryTitle}>
      <h1>
        {props.title}
        {(props.notes || props.link) && (
          <>
            <FieldInfoTooltip>
              {props.link && (
                <>
                  Click
                  <a href={props.link} target="_blank" rel="noreferrer">
                    &nbsp;here&nbsp;
                  </a>
                  to learn more about {props.title.toLowerCase()}
                  <br></br>
                </>
              )}
              {props.notes && (
                <>
                  <em>Note: {props.notes} </em>
                </>
              )}
            </FieldInfoTooltip>
          </>
        )}
      </h1>
    </div>
  );
};
export default PrimaryTitle;
