import saveAs from 'file-saver';
import { DateTime } from 'luxon';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { allWorkshopConflictCriteria } from 'src/api/enums';
import { ListPageLoadCause } from 'src/domain';
import { useRootStore } from 'src/domain/entities/RootStoreModel';
import { ExcelIcon } from 'src/images/icons';
import { shallowMemoize } from 'src/infrastructure/memoizeOne';
import { DurationFormat } from 'src/views/components/DurationFormat';
import { ListPage } from 'src/views/components/Page';
import { IListPageDef } from 'src/views/components/Page/pages/ListPage';
import { TaskCardItem } from 'src/views/components/TaskCard';
import WorkshopShiftConflictIcon from 'src/views/components/workshop/shiftConflictIcon/WorkshopShiftConflictIcon';
import {
  ActionType,
  FieldDefs,
  FieldType,
  PagePrimarySize,
  PaneType,
  ShellModalSize,
} from 'src/views/definitionBuilders/types';
import { totalJobHours } from 'src/views/routes/operations/urban/urbanHelpers';
import WorkshopShiftStatus from 'src/views/routes/workshop/shifts/listShifts/WorkshopShiftStatus';
import PrimaryTitle from 'src/views/components/Page/PrimaryTitle/PrimaryTitle';

type ShiftListItem = Workshop.Domain.Queries.Shift.ListShifts.ShiftListItem;

const ListShifts: React.FC = observer(() => {
  const rootStore = useRootStore();
  const workshopModel = rootStore.workshop;
  const shiftsModel = workshopModel.shifts;
  const staffMembersModel = rootStore.people.staffMembers;
  const shiftTemplatesModel = workshopModel.shiftTemplates;
  const rostersModel = workshopModel.rosters;
  const workshopStartUpModel = rootStore.workshopStartup;

  const [isLoadingFilters, setIsLoadingFilters] = useState<boolean>(true);

  useEffect(() => {
    Promise.all([
      staffMembersModel.loadMechanics(),
      shiftTemplatesModel.listItems({ loadCause: ListPageLoadCause.mount }),
    ]).then(() => setIsLoadingFilters(false));
  }, []);

  const getFilterFieldDefs = shallowMemoize(
    (mechanics, shiftTemplates, autocompleteRosters, listRosterNames, workshopDepots) => {
      return {
        dateFrom: {
          fieldType: FieldType.dateField,
          label: 'Date From',
          dataAddr: 'dateFrom',
          onBlur: api => {
            api.validateField(['dateTo']);
          },
        } as FieldDefs,
        dateTo: {
          fieldType: FieldType.dateField,
          label: 'Date To',
          dataAddr: 'dateTo',
          validate: d => {
            if (!d.fieldValue || !d.parentValue.dateFrom) {
              return undefined;
            }
            const from = DateTime.fromISO(d.parentValue.dateFrom);
            const to = DateTime.fromISO(d.fieldValue);
            return from > to ? 'Date To cannot be earlier than Date From' : undefined;
          },
        } as FieldDefs,
        staffMembers: {
          fieldType: FieldType.selectMultiField,
          dataAddr: 'staffMemberIds',
          label: 'Staff Member',
          valueKey: 'id',
          descriptionKey: 'name',
          optionItems: mechanics,
          useValueOnly: true,
        } as FieldDefs,
        shiftTemplates: {
          fieldType: FieldType.selectMultiField,
          dataAddr: 'shiftTemplateIds',
          label: 'Shift Name',
          valueKey: 'shiftTemplateId',
          descriptionKey: 'shiftName',
          optionItems: shiftTemplates.slice(),
          useValueOnly: true,
        } as FieldDefs,
        depots: {
          fieldType: FieldType.selectMultiField,
          label: 'Depot',
          dataAddr: 'workshopDepots',
          valueKey: 'id',
          descriptionKey: 'description',
          optionItems: workshopDepots,
          useValueOnly: true,
          onChange: d => {
            const values = { ...d.formValues, rosterIds: undefined };
            d.setFormValues(values);
          },
        } as FieldDefs,
        rosters: {
          fieldType: FieldType.selectAsyncMultiField,
          label: 'Roster',
          dataAddr: 'rosterIds',
          valueKey: 'rosterId',
          descriptionKey: 'rosterName',
          useValueOnly: true,
          getFieldKey: d => d.sectionValue.workshopDepots,
          loadOptionItems: async (s, d) => {
            // the default filter value is a string until the user changes it, when it becomes an array of numbers
            const depots =
              typeof d.parentValue.workshopDepots === 'string'
                ? [Number(d.parentValue.workshopDepots)]
                : d.parentValue.workshopDepots;
            return await autocompleteRosters(s, depots);
          },
          loadItems: listRosterNames,
          optionRenderer: option => {
            return workshopDepots.length > 1 ? (
              <span>
                {option.rosterName} <span className={'small'}>{option.depotDescription}</span>
              </span>
            ) : (
              <span>{option.rosterName}</span>
            );
          },
        } as FieldDefs,
        conflicts: {
          fieldType: FieldType.selectMultiField,
          label: 'Conflict',
          dataAddr: 'conflicts',
          valueKey: 'value',
          descriptionKey: 'description',
          optionItems: allWorkshopConflictCriteria,
          useValueOnly: true,
        } as FieldDefs,
      };
    }
  );

  const getPageDef = (): IListPageDef => {
    const filterFieldDefsLookup = getFilterFieldDefs(
      staffMembersModel.mechanics.slice(),
      shiftTemplatesModel.items.slice(),
      rostersModel.autocompleteRosters,
      rostersModel.listRosterNames,
      workshopStartUpModel.workshopDepots.slice()
    );

    const today = DateTime.local().toISODate();
    return {
      primaryTitle: <PrimaryTitle title="Shifts"></PrimaryTitle>,
      onLoadData: shiftsModel.listItems,
      externalSearch: true,
      createLink: rootStore.account.isWorkshopDepartmentMember
        ? TaskCardItem.workshop.createWorkshopShift
        : undefined,
      hasMoreData: shiftsModel.hasMoreItems,
      primarySize: PagePrimarySize.full,
      filterAction: {
        defaultValues: {
          dateFrom: today,
          workshopDepots: workshopStartUpModel.defaultWorkshopDepot?.id,
        },
        disabled: isLoadingFilters,
        filterSize: ShellModalSize.oneQuarter,
        filterFields: Object.keys(filterFieldDefsLookup).map(k => filterFieldDefsLookup[k]),
        filterDef: () => [
          {
            panes: [
              {
                paneType: PaneType.formFieldsPane,
                columnCount: 2,
                fields: [filterFieldDefsLookup.dateFrom, filterFieldDefsLookup.dateTo],
              },
              {
                paneType: PaneType.formFieldsPane,
                fields: [
                  filterFieldDefsLookup.staffMembers,
                  filterFieldDefsLookup.shiftTemplates,
                  filterFieldDefsLookup.depots,
                  filterFieldDefsLookup.rosters,
                  filterFieldDefsLookup.conflicts,
                ],
              },
            ],
          },
        ],
      },
      primaryActions: [
        {
          actions: [
            {
              actionType: ActionType.actionCollection,
              actionGroups: [
                {
                  actions: [
                    {
                      actionType: ActionType.actionButton,
                      label: 'Export Shifts to Excel',
                      icon: <ExcelIcon fixedWidth />,
                      onClick: () => {
                        const fileName = `Workshop_Shifts_${DateTime.local().toFormat(
                          'yyyyMMddHHmm'
                        )}.xlsx`;
                        shiftsModel.exportShiftsToExcel().then(blob => saveAs(blob, fileName));
                      },
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
      primaryFields: [
        {
          fieldType: FieldType.textField,
          dataAddr: 'shiftNumber',
          label: 'Shift Number',
          formatReadonly: d => {
            const shift = d.parentValue as ShiftListItem;
            return (
              <>
                <WorkshopShiftConflictIcon hasConflicts={shift.hasUnacceptedConflicts} />
                {shift.shiftNumber}
              </>
            );
          },
          linkTo: d => `/workshop/shifts/${d.parentValue.shiftId}`,
        },
        {
          fieldType: FieldType.textField,
          dataAddr: 'shiftName',
          label: 'Shift Name',
          linkTo: d => `/workshop/shift-templates/${d.parentValue.shiftTemplateId}`,
        },
        {
          fieldType: FieldType.textField,
          dataAddr: 'shiftDepot.name',
          label: 'Depot',
        },
        {
          fieldType: FieldType.textField,
          dataAddr: 'rosterName',
          label: 'Roster',
          linkTo: d => `/workshop/rosters/${d.parentValue.rosterId}`,
        },
        {
          fieldType: FieldType.dateTimeField,
          label: 'Shift Commence',
          dataAddr: 'shiftCommence',
        },
        {
          fieldType: FieldType.dateTimeField,
          label: 'Shift End',
          dataAddr: 'shiftEnd',
        },
        {
          fieldType: FieldType.readonlyField,
          label: 'Unpaid Breaks',
          dataAddr: 'unpaidBreaks',
          formatReadonly: d => <DurationFormat value={d.fieldValue} />,
        },
        {
          fieldType: FieldType.readonlyField,
          label: 'Total Shift Hours',
          formatReadonly: data =>
            totalJobHours(data.parentValue.shiftCommence, data.parentValue.shiftEnd),
        },
        {
          fieldType: FieldType.readonlyField,
          label: 'Staff Member',
          dataAddr: ['staffMember', 'staffMemberFullName'],
        },
        {
          fieldType: FieldType.textField,
          label: 'Status',
          dataAddr: 'none',
          formatReadonly: data => {
            const shift = data.parentValue as ShiftListItem;
            return <WorkshopShiftStatus status={shift.progressStatus} />;
          },
        },
      ],
    };
  };

  return workshopStartUpModel.defaultWorkshopDepot ? (
    <ListPage className="list-shifts-component" data={shiftsModel.items} def={getPageDef()} />
  ) : null;
});

export default ListShifts;
