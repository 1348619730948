import inject from 'src/views/injectFromKioskStore';
import CreateDefect, {
  ICreateDefectProps,
} from 'src/views/kioskRoutes/operationsKiosk/CreateDefect/CreateDefect';

const Container = inject<ICreateDefectProps>((allStores, p) => ({
  canUseTimesheets: allStores.rootStore.account.canUseTimesheets,
}))(CreateDefect);

export default Container;
