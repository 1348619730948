import { types } from 'mobx-state-tree';
import { EmploymentContractCodeModel } from './employmentContractCode/EmploymentContractCodeModel';
import { StaffMembersModel } from './staffMember/StaffMembersModel';
import { StaffMemberModel } from './staffMember/StaffMemberModel';
import { NoteModel } from './note/NoteModel';
import { NotesModel } from './note/NotesModel';
import { RoleModel } from './role/RoleModel';
import { RolesModel } from './role/RolesModel';
import { SmsModel } from './sms/SmsModel';
import { ListSmsModel } from './sms/ListSmsModel';
import { LeaveModel } from './leave/LeaveModel';
import { SkillSpecsModel } from 'src/domain/entities/people/staffMember/SkillSpecsModel';
import { LeavesModel } from 'src/domain/entities/people/leave/LeavesModel';
import { ScheduledLeaveModel } from 'src/domain/entities/people/leave/ScheduledLeaveModel';
import { TimesheetsModel } from 'src/domain/entities/people/timesheet/TimesheetsModel';
import { TimesheetModel } from 'src/domain/entities/people/timesheet/TimesheetModel';
import { EquipmentTypesModel } from 'src/domain/entities/people/equipmentTypes/EquipmentTypesModel';
import { StaffDepotModel } from 'src/domain/entities/people/staffDepot/StaffDepotModel';
import { EmploymentStatusModel } from './employmentStatus/EmploymentStatusModel';
import { LicencesModel } from 'src/domain/entities/kiosk/licences/LicencesModel';
import { PeopleDashboardModel } from './peopleDashboard/PeopleDashboardModel';
import { ExternalIdentitiesModel } from './externalIdentity/ExternalIdentitiesModel';
import { TimesheetAllowanceModel } from './timesheetAllowance/TimesheetAllowanceModel';
import { TimesheetAllowancesModel } from './timesheetAllowance/TimesheetAllowancesModel';
import { EquipmentTypeModel } from './equipmentTypes/EquipmentTypeModel';

export const PeopleModel = types.model('PeopleModel', {
  peopleDashboard: types.optional(PeopleDashboardModel, {}),
  staffMembers: types.optional(StaffMembersModel, {}),
  staffMember: types.optional(StaffMemberModel, {}),
  skillSpecs: types.optional(SkillSpecsModel, {}),
  note: types.optional(NoteModel, {}),
  notes: types.optional(NotesModel, {}),
  role: types.optional(RoleModel, {}),
  roles: types.optional(RolesModel, {}),
  timesheetAllowance: types.optional(TimesheetAllowanceModel, {}),
  timesheetAllowances: types.optional(TimesheetAllowancesModel, {}),
  ListSms: types.optional(ListSmsModel, {}),
  Sms: types.optional(SmsModel, {}),
  leave: types.optional(LeaveModel, {}),
  leaves: types.optional(LeavesModel, {}),
  scheduledLeave: types.optional(ScheduledLeaveModel, {}),
  timesheet: types.optional(TimesheetModel, {}),
  timesheets: types.optional(TimesheetsModel, {}),
  equipmentType: types.optional(EquipmentTypeModel, {}),
  equipmentTypes: types.optional(EquipmentTypesModel, {}),
  depots: types.optional(StaffDepotModel, {}),
  employmentStatuses: types.optional(EmploymentStatusModel, {}),
  employmentContractCodes: types.optional(EmploymentContractCodeModel, {}),
  licences: types.optional(LicencesModel, {}),
  externalIdentities: types.optional(ExternalIdentitiesModel, {}),
});

type IPeopleModelType = typeof PeopleModel.Type;
export interface IPeopleModel extends IPeopleModelType {}
