import cn from 'classnames';
import styles from './WeeklyJobSchedule.module.scss';

import { DateTime } from 'luxon';
import { observer } from 'mobx-react';
import { TIMEZONE } from 'src/appSettings';
import { useRootStore } from 'src/domain/entities/RootStoreModel';
import Page from 'src/views/components/Page';
import { IPageDef, PagePrimarySize, PaneType } from 'src/views/definitionBuilders/types';
import Scheduler from './Scheduler/Scheduler';

function getSearch(query: URLSearchParams, newWeek: string, depotId: number) {
  query.set('week', newWeek);
  query.set('depotId', depotId.toString());
  return query.toString();
}

const zone = TIMEZONE;

const WeeklyJobSchedule: React.FC = observer(() => {
  const rootStore = useRootStore();
  const scheduledJobsModel = rootStore.scheduledJobs;
  const workshopModel = rootStore.workshopStartup;

  const query = new URLSearchParams(location.search);
  const selectedWorkshopDepotId = Number(query.get('depotId')) || 0;
  const localIsoWeek =
    query.get('week') ||
    DateTime.utc()
      .setZone(zone)
      .toISOWeekDate();
  const weekToDisplay = DateTime.fromISO(localIsoWeek, { zone });
  const scheduledJobs = scheduledJobsModel.jobs;

  const getPageDef = (): IPageDef => {
    return {
      primarySize: PagePrimarySize.full,
      primarySection: {
        title: 'Weekly Job Schedule',
        subtitle: `${weekToDisplay
          .setLocale('local')
          .toLocaleString(DateTime.DATE_MED)} - ${weekToDisplay
          .plus({ days: 6 })
          .setLocale('local')
          .toLocaleString(DateTime.DATE_MED)}`,
        panels: [
          {
            panes: [
              {
                paneType: PaneType.customPane,
                render: () => {
                  return (
                    workshopModel.defaultWorkshopDepot && (
                      <div className={cn(styles['weekly-job-schedule'])}>
                        <Scheduler
                          nowUtc={DateTime.utc()}
                          showInZone={zone}
                          onWeekChanged={(year, month, depotId) =>
                            scheduledJobsModel.loadJobs(year, month, zone, depotId)
                          }
                          workshopDepots={workshopModel.workshopDepots.slice()}
                          defaultWorkshopDepot={workshopModel.defaultWorkshopDepot}
                          selectedWorkshopDepotId={selectedWorkshopDepotId}
                          weekToDisplay={weekToDisplay}
                          getNavigationLink={(weekToDisplay, depotId) => ({
                            ...location,
                            search: getSearch(query, weekToDisplay, depotId),
                          })}
                          jobs={scheduledJobs}
                          jobsLoading={scheduledJobsModel.jobsForWeekByDayLoading(
                            weekToDisplay.weekYear,
                            weekToDisplay.weekNumber
                          )}
                          changeSelectedWorkshopDepotId={
                            scheduledJobsModel.changeSelectedWorkshopDepotId
                          }
                        />
                      </div>
                    )
                  );
                },
              },
            ],
          },
        ],
      },
    };
  };

  return <Page def={getPageDef()} />;
});

export default WeeklyJobSchedule;
