import inject from 'src/views/injectFromStore';
import Accounting, { IAccountingProps } from './Accounting';

const Container = inject<IAccountingProps>((allStores, p) => ({
  onLoadData: allStores.rootStore.operations.sales.dashboard.loadDashboardData,
  loadExtras: allStores.rootStore.operations.extraTypes.list.loadExtraTypes,
  dashboardData: allStores.rootStore.operations.sales.dashboard.dashboardData,
  extrasList: allStores.rootStore.operations.extraTypes.list.accountingAndQuoteExtraTypes.slice(),
}))(Accounting);

export default Container;
