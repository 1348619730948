import inject from 'src/views/injectFromStore';
import MaintainFatigueBreachEvent, {
  IMaintainFatigueBreachEventProps,
} from './MaintainFatigueBreachEvent';
import Omit from 'src/infrastructure/omit';

const Container = inject<
  Omit<IMaintainFatigueBreachEventProps, 'mode'>,
  IMaintainFatigueBreachEventProps
>((allStores, p) => ({
  canManageFatigueBreachEvents: allStores.rootStore.account.isOperationsDepartmentMember,
  fatigueBreachEvent: allStores.rootStore.compliance.fatigueBreachEvent.fatigueBreachEvent,
  onLoadFatigueBreachEvent:
    allStores.rootStore.compliance.fatigueBreachEvent.loadFatigueBreachEvent,
  onUpdateFatigueBreachEvent:
    allStores.rootStore.compliance.fatigueBreachEvent.updateFatigueBreachEvent,
  onCloseFatigueBreachEvent:
    allStores.rootStore.compliance.fatigueBreachEvent.closeFatigueBreachEvent,
  onInvalidateFatigueBreachEvent:
    allStores.rootStore.compliance.fatigueBreachEvent.invalidateFatigueBreachEvent,
  onReactivateFatigueBreachEvent:
    allStores.rootStore.compliance.fatigueBreachEvent.reactivateFatigueBreachEvent,
  listActivityLogs: allStores.rootStore.compliance.fatigueBreachEvent.listActivityLogs,
  activityLogs: allStores.rootStore.compliance.fatigueBreachEvent.activityLogs.slice(),
  exportFatigueBreachEventToPdf:
    allStores.rootStore.compliance.fatigueBreachEvent.exportFatigueBreachEventToPdf,
  verifyFatigueBreach: allStores.rootStore.compliance.fatigueBreachEvent.verify,
  verifyResponse: allStores.rootStore.compliance.fatigueBreachEvent.verifyFatigueBreachEvent,
  clearVerifyFatigueBreachEvent:
    allStores.rootStore.compliance.fatigueBreachEvent.clearVerifyFatigueBreachEvent,
  loadFatigueBreachEvent: allStores.rootStore.compliance.fatigueBreachEvent.loadFatigueBreachEvent,
}))(MaintainFatigueBreachEvent);

export default Container;
