import { PaneType, FieldType, ModalDefBuilder } from 'src/views/definitionBuilders/types';
import { ChangeState } from 'src/api/enums';
import { getSubmitCloseModalActionGroupDef } from 'src/views/definitionBuilders/common';
import { IGenerateJobsFormRosterItem } from './GenerateJobs';
import { SyncIcon } from 'src/images/icons';
import { formatDateShort } from 'src/domain/dateHelper';

type RosterGroupListItem = Operations.Domain.Queries.ListRosterGroups.RosterGroupListItem;
type RosterListItem = Operations.Domain.Queries.ListRosters.RosterListItem;
type RosterGroupDto = Operations.Domain.Queries.GetRosterGroups.RosterGroupDto;
type RotatingRosterListItem = Operations.Domain.Queries.ListRotatingRosterStaffMembers.RotatingRosterListItem;

export default function getAddRosterGroupModalDef(
  rosterGroups: Array<RosterGroupListItem>,
  rosters: Array<RosterListItem>,
  showRotatingRosterGroups: boolean,
  rotatingRosters: Array<RotatingRosterListItem>
): ModalDefBuilder {
  return modalDefApi => ({
    title: 'Add Roster Group',
    asForm: true,
    panels: [
      {
        panes: [
          {
            paneType: PaneType.formFieldsPane,
            fields: [
              {
                fieldType: FieldType.selectField,
                dataAddr: 'rosterGroupId',
                label: 'Roster Group',
                mandatory: true,
                optionItems: rosterGroups,
                valueKey: 'rosterGroupId',
                descriptionKey: 'name',
                useValueOnly: true,
                optionRenderer: (d: RosterGroupDto) => (
                  <span>
                    {d.name}
                    {d.isRotating && (
                      <small>
                        &emsp; - &emsp;
                        <SyncIcon /> &emsp;
                        {d.rotatingCommencementDate && formatDateShort(d.rotatingCommencementDate)}
                      </small>
                    )}
                  </span>
                ),
                valuesToDisable: d => {
                  const isRotating = rosterGroups
                    .filter(x => x.isRotating)
                    .map(r => r.rosterGroupId);
                  return showRotatingRosterGroups ? [] : [...isRotating, d.fieldValue];
                },
              },
            ],
          },
        ],
      },
    ],
    secondaryActions: [getSubmitCloseModalActionGroupDef('Ok')],
    onFormPreSubmit: v => v.rosterGroupId,
    onFormSubmit: (rosterGroupId: string) => {
      const rosterListItemsForRosterGroup = rosters
        .filter(a => a.rosterGroup && a.rosterGroup.id === rosterGroupId)
        .map(r => {
          const isRotatingRosterGroup = r.rosterGroup && r.rosterGroup.isRotating;
          return {
            changeState: ChangeState.Added,
            roster: r,
            staffMember: isRotatingRosterGroup
              ? rotatingRosters.find(rr => rr.rosterId === r.id)?.staffMember
              : r.staffMember,
          };
        }) as Array<IGenerateJobsFormRosterItem>;

      const rostersAddr = 'rosters';
      const rostersAddedAlready = modalDefApi.parentFormApi.getValue(rostersAddr) as Array<
        IGenerateJobsFormRosterItem
      >;
      const toAdd = rosterListItemsForRosterGroup.filter(
        x => !rostersAddedAlready.some(b => b.roster && b.roster.id === x.roster.id)
      );
      const newRosterListItems = rostersAddedAlready.concat(toAdd);

      const rostersData = [...newRosterListItems];
      modalDefApi.parentFormApi.setValue(rostersAddr, rostersData);
      return Promise.resolve();
    },
  });
}
