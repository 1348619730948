import saveAs from 'file-saver';
import { DateTime } from 'luxon';
import { observer } from 'mobx-react';
import { useEffect } from 'react';
import {
  allDefectRaisedBy,
  allDefectSource,
  DefectStatus as DefectStatusEnum,
} from 'src/api/enums';
import { ListPageLoadCause } from 'src/domain/baseTypes';
import { useRootStore } from 'src/domain/entities/RootStoreModel';
import { CheckIcon, ExcelIcon, TimesIcon } from 'src/images/icons';
import memoizeOne from 'src/infrastructure/memoizeOne';
import ListPage, { IListPageDef } from 'src/views/components/Page/pages/ListPage';
import { TaskCardItem } from 'src/views/components/TaskCard';
import {
  ActionType,
  FieldDefs,
  FieldType,
  PagePrimarySize,
  PaneType,
  ShellModalSize,
} from 'src/views/definitionBuilders/types';
import withQueryParams, { IQueryParamsProps } from 'src/views/hocs/withQueryParams';
import getCloseDefectWithoutFixingModalDef from '../maintainDefect/getCloseDefectWithoutFixingModalDef';
import MarkOutOfService from './MarkOutOfService';
import PrimaryTitle from 'src/views/components/Page/PrimaryTitle/PrimaryTitle';

type ListDefectsQuery = Workshop.Domain.Queries.Defect.ListDefects.ListDefectsQuery;
type CloseDefectWithoutFixingCommand = Workshop.Domain.Commands.Defect.SetDefectClosedWithoutBeingFixedCommand;

const ListDefects: React.FC<IQueryParamsProps<ListDefectsQuery>> = observer(
  (props: IQueryParamsProps<ListDefectsQuery>) => {
    const rootStore = useRootStore();
    const listDefectsModel = rootStore.listDefects;
    const defectModel = rootStore.defect;
    const assetModel = rootStore.asset;
    const assetsModel = rootStore.assets;

    useEffect(() => {
      defectModel.loadDefectStatuses();
      assetsModel.loadAssetListItems();
      assetModel.loadAssetHousedAtLocations();
    }, []);

    const getPrimaryFields = memoizeOne((): IListPageDef['primaryFields'] => {
      return [
        {
          fieldType: FieldType.assetSelectField,
          dataAddr: 'asset',
          label: 'Asset',
          valueKey: 'id',
          descriptionKey: 'name',
        },
        {
          fieldType: FieldType.textField,
          dataAddr: 'defectNumber',
          label: 'Defect Number',
          linkTo: d => `/workshop/defects/${d.parentValue.id}`,
        },
        {
          fieldType: FieldType.textField,
          dataAddr: 'jobTaskNumber',
          label: 'Task Number',
          linkTo: d => `/workshop/tasks/${d.parentValue.jobTaskId}`,
        },
        {
          fieldType: FieldType.textField,
          dataAddr: 'description',
          label: 'Details',
        },
        {
          fieldType: FieldType.readonlyField,
          dataAddr: ['status', 'description'],
          label: 'Status',
        },
        {
          fieldType: FieldType.readonlyField,
          dataAddr: ['raisedBy', 'description'],
          label: 'Raised By',
        },
        {
          fieldType: FieldType.dateField,
          dataAddr: 'createdOn',
          label: 'Raised On',
          readonly: true,
        },
        {
          fieldType: FieldType.readonlyField,
          dataAddr: 'createdBy',
          label: 'Reported By',
        },
        {
          fieldType: FieldType.readonlyField,
          label: 'Out Of Service',
          formatReadonly: d => (
            <MarkOutOfService markOutOfService={d.parentValue.markOutOfService} />
          ),
          columnWidth: '4rem',
        },
        {
          fieldType: FieldType.actionListField,
          hidden: () => !rootStore.account.isWorkshopDepartmentMember,
          dataAddr: '',
          columnWidth: '1px',
          label: 'Actions',
          actionGroups: [
            {
              actions: [
                {
                  actionType: ActionType.actionCollection,
                  actionGroups: [
                    {
                      actions: [
                        {
                          actionType: ActionType.actionButton,
                          label: 'Acknowledge Defect',
                          hidden: d => {
                            return !(d.parentValue.status.id === DefectStatusEnum.DefectCreated);
                          },
                          icon: <CheckIcon fixedWidth />,
                          onClick: d => {
                            defectModel.acknowledgeDefect(d.parentValue.id).then(t =>
                              listDefectsModel.listItems({
                                loadCause: ListPageLoadCause.refresh,
                                query: props.getQueryParams(),
                              })
                            );
                          },
                        },
                        {
                          actionType: ActionType.modalActionButton,
                          label: 'Close Defect Without Fixing',
                          hidden: d =>
                            !(
                              d.parentValue.status.id === DefectStatusEnum.DefectCreated ||
                              d.parentValue.status.id === DefectStatusEnum.Acknowledged
                            ) || d.parentValue.markOutOfService,
                          icon: <TimesIcon fixedWidth />,
                          modalSize: ShellModalSize.oneQuarter,
                          modalDef: api => {
                            const defectId = api.actionData.parentValue.id;
                            return getCloseDefectWithoutFixingModalDef(
                              (command: CloseDefectWithoutFixingCommand) =>
                                defectModel.setDefectClosedWithoutBeingFixed(command).then(t =>
                                  listDefectsModel.listItems({
                                    loadCause: ListPageLoadCause.refresh,
                                    query: props.getQueryParams(),
                                  })
                                ),
                              defectId
                            )(api);
                          },
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
      ];
    });

    const getFilterFieldDefs = () => {
      return {
        asset: {
          fieldType: FieldType.selectMultiField,
          label: 'Asset',
          dataAddr: 'assets',
          useValueOnly: true,
          valueKey: 'id',
          descriptionKey: 'name',
          optionItems: assetsModel.assetListItems.slice(),
        } as FieldDefs,
        status: {
          fieldType: FieldType.selectMultiField,
          label: 'Status',
          dataAddr: 'statuses',
          useValueOnly: true,
          valueKey: 'value',
          descriptionKey: 'label',
          optionItems: defectModel.statuses.slice(),
        } as FieldDefs,
        garagedAt: {
          fieldType: FieldType.selectMultiField,
          label: 'Garaged At',
          dataAddr: 'assetHousingLocations',
          optionItems: assetModel.housedAtLocations.slice(),
          useValueOnly: true,
          valueKey: 'id',
          descriptionKey: 'description',
        } as FieldDefs,
        source: {
          fieldType: FieldType.selectField,
          label: 'Source',
          dataAddr: 'defectSource',
          useValueOnly: true,
          valueKey: 'value',
          descriptionKey: 'description',
          optionItems: allDefectSource,
        } as FieldDefs,
        raisedBy: {
          fieldType: FieldType.selectMultiField,
          label: 'Raised By',
          dataAddr: 'raisedby',
          useValueOnly: true,
          valueKey: 'value',
          descriptionKey: 'description',
          optionItems: allDefectRaisedBy.slice(),
        } as FieldDefs,
        dateFrom: {
          fieldType: FieldType.dateField,
          dataAddr: 'dateFrom',
          label: 'Raised From',
        } as FieldDefs,
        dateTo: {
          fieldType: FieldType.dateField,
          dataAddr: 'dateTo',
          label: 'Raised To',
          validate: d => {
            if (!d.fieldValue || !d.parentValue.dateFrom) {
              return undefined;
            }
            const from = DateTime.fromISO(d.parentValue.dateFrom);
            const to = DateTime.fromISO(d.fieldValue);
            return from > to ? 'Raised To cannot be earlier than Raised From' : undefined;
          },
        } as FieldDefs,
        MarkOutOfService: {
          fieldType: FieldType.yesNoField,
          dataAddr: 'markAsOutOfService',
          label: 'Mark As Out Of Service',
        },
      };
    };

    const getRowKey: IListPageDef['rowKey'] = data => {
      return data.itemValue.id;
    };

    const getPageDef = (): IListPageDef => {
      const filterFieldDefs = getFilterFieldDefs();
      return {
        primaryTitle: <PrimaryTitle title="Defects"></PrimaryTitle>,
        onLoadData: listDefectsModel.listItems,
        externalSearch: true,
        createLink: rootStore.account.isWorkshopDepartmentMember
          ? TaskCardItem.raiseDefect
          : undefined,
        hasMoreData: listDefectsModel.hasMoreItems,
        primaryFields: getPrimaryFields(),
        rowKey: getRowKey,
        primarySize: PagePrimarySize.full,
        primaryActions: [
          {
            actions: [
              {
                actionType: ActionType.actionCollection,
                actionGroups: [
                  {
                    actions: [
                      {
                        actionType: ActionType.actionButton,
                        label: 'Export all data to Excel',
                        icon: <ExcelIcon fixedWidth />,
                        onClick: () => {
                          const fileName = `Defects_${DateTime.local().toFormat(
                            'yyyyMMddHHmm'
                          )}.xlsx`;
                          return listDefectsModel
                            .exportToExcel({
                              ...props.getQueryParams(),
                            })
                            .then(blob => saveAs(blob, fileName));
                        },
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
        filterAction: {
          defaultValues: {
            statuses: [DefectStatusEnum.Acknowledged, DefectStatusEnum.DefectCreated],
          },
          filterFields: Object.keys(filterFieldDefs).map(k => filterFieldDefs[k]) as FieldDefs[],
          filterDef: _ => [
            {
              panes: [
                {
                  paneType: PaneType.formFieldsPane,
                  fields: [filterFieldDefs.dateFrom, filterFieldDefs.dateTo],
                  columnCount: 2,
                },
                {
                  paneType: PaneType.formFieldsPane,
                  fields: [
                    filterFieldDefs.status,
                    filterFieldDefs.asset,
                    filterFieldDefs.garagedAt,
                    filterFieldDefs.raisedBy,
                    filterFieldDefs.source,
                  ],
                },
              ],
            },
          ],
        },
      };
    };

    return (
      <ListPage
        className="list-defects-component"
        data={listDefectsModel.items.slice()}
        def={getPageDef()}
      />
    );
  }
);

export default withQueryParams(ListDefects);
