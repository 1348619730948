import { IAjax } from 'src/infrastructure/ajax';
import { operationsUrls } from 'src/domain/services/apiUrls';

type GetVehicleTypeAllocationDataQuery = Operations.Domain.Queries.GetVehicleTypeAllocationData.GetVehicleTypeAllocationDataQuery;

export default function init(ajax: IAjax) {
  function getVehicleTypeAllocationData(
    fromDate: string,
    toDate: string,
    vehicleTypeIds: string[],
    quoteIdToIgnore: string | undefined,
    depotId: number
  ) {
    const query: GetVehicleTypeAllocationDataQuery = {
      toDate: toDate,
      fromDate: fromDate,
      vehicleTypeIds: vehicleTypeIds,
      quoteIdToIgnore: quoteIdToIgnore,
      depotId: depotId,
    };

    return ajax
      .get(operationsUrls.allocationsUrls.getVehicleTypeAllocationData(query))
      .toPromise()
      .then(r => r.response);
  }

  return {
    getVehicleTypeAllocationData,
  };
}
