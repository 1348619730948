import { DateTime, Duration } from 'luxon';
import { Link, RouteComponentProps } from 'react-router-dom';
import CrudPage, { CrudPageMode, ICrudPageDef } from 'src/views/components/Page/pages/CrudPage';
import { getSubmitCloseModalActionGroupDef } from 'src/views/definitionBuilders/common';
import {
  ActionType,
  FieldType,
  IFieldOnChange,
  IHasChangeState,
  IModalDefBuilderApi,
  INestingPaneDef,
  ModalDefBuilder,
  PagePrimarySize,
  PaneType,
  ShellModalSize,
  WeekSelectCallbackApi,
} from 'src/views/definitionBuilders/types';
import styles from './MaintainTimesheet.module.scss';
type PrefilledTimesheetItem = Common.Dtos.PrefilledTimesheetItem;
type TimesheetWeek = People.Domain.Queries.GetWeekWithoutTimesheet.TimesheetWeek;
type TimesheetItem = Common.Dtos.TimesheetItem;
type TimesheetRecordItem = Common.Dtos.TimesheetRecordItem;
type CreateTimesheetCommand = People.Domain.Commands.Timesheets.CreateTimesheet.CreateTimesheetCommand;
type UpdateTimesheetCommand = People.Domain.Commands.Timesheets.UpdateTimesheet.UpdateTimesheetCommand;
type AmendTimesheetCommand = People.Domain.Commands.Timesheets.AmendTimesheet.AmendTimesheetCommand;
type TimesheetJobItem = Common.Dtos.TimesheetJobItem;
type TimesheetWorkshopShiftItem = Common.Dtos.TimesheetWorkshopShiftItem;
type TimesheetLeaveItem = Common.Dtos.TimesheetLeaveItem;
type TimesheetPublicHolidayItem = Common.Dtos.TimesheetPublicHolidayItem;
type ExtraJobDataItem = Common.Dtos.ExtraJobDataItem;
type TimesheetRecordTotals = Common.Dtos.TimesheetRecordsTotals;
type TimesheetItemForDriver = Common.Dtos.TimesheetItemForDriver;
type PrefilledTimesheetItemForDriver = Common.Dtos.PrefilledTimesheetItemForDriver;

import { observer } from 'mobx-react';
import { ChangeState, getTimesheetStatusDescriptor, TimesheetStatus } from 'src/api/enums';
import { ENABLE_TIMESHEET_OVERRIDE_PAID_HOURS } from 'src/appSettings';
import { ListPageLoadCause } from 'src/domain/baseTypes';
import { useRootStore } from 'src/domain/entities/RootStoreModel';
import { CheckIcon, EditIcon, SyncIcon, WarnIcon } from 'src/images/icons';
import { parseTimeSpan } from 'src/infrastructure/dateUtils';
import { DurationFormat } from 'src/views/components/DurationFormat';
import PageField from 'src/views/components/Page/fields/PageField';
import CostCentre from './CostCentre/CostCentre';
import getActivityLogPanelDef from './getActivityLogPanelDef';
import TimesheetTable from './TimesheetTable/TimesheetTable';
import { getPayPeriodInWeeks } from 'src/domain/dateHelper';

type TimesheetShiftAllowanceItem = Common.Dtos.TimesheetShiftAllowanceItem;

export interface IMaintainTimesheetProps {
  mode: CrudPageMode;
  route: RouteComponentProps<{ [x: string]: string | undefined }>;
}

export interface ITimesheetRecordsTotals {
  totalLeaveHrs: Duration;
  totalWorkedHrs: Duration;
  totalPaidHrs: Duration;
  totalOvertimeHrs: Duration;
  totalPublicHolidayHrs: Duration;
  totalOnCall: number;
}

export const MapTimesheetTotalsToRecordsTotals = (
  totals: TimesheetRecordTotals | undefined
): ITimesheetRecordsTotals => ({
  totalLeaveHrs: totals ? parseTimeSpan(totals.totalLeaveHrs) : Duration.fromMillis(0),
  totalWorkedHrs: totals ? parseTimeSpan(totals.totalWorkedHrs) : Duration.fromMillis(0),
  totalPaidHrs: totals ? parseTimeSpan(totals.totalPaidHrs) : Duration.fromMillis(0),
  totalOvertimeHrs: totals ? parseTimeSpan(totals.totalOvertimeHrs) : Duration.fromMillis(0),
  totalPublicHolidayHrs: totals
    ? parseTimeSpan(totals.totalPublicHolidayHrs)
    : Duration.fromMillis(0),
  totalOnCall: totals?.totalOnCall || 0,
});

export const MapTimesheetItemToRecordsTotals = (
  timesheet:
    | TimesheetItem
    | TimesheetItemForDriver
    | PrefilledTimesheetItem
    | PrefilledTimesheetItemForDriver
    | undefined
): ITimesheetRecordsTotals => {
  return MapTimesheetTotalsToRecordsTotals(timesheet?.allTotals);
};

interface ITimesheetForm extends TimesheetItem {
  timesheetWeek: TimesheetWeek | undefined;
}

type ExtendedTimesheetJobAllowanceItem = Common.Dtos.TimesheetJobAllowanceItem & IHasChangeState;

type ExtendedTimesheetJobItem = TimesheetJobItem & {
  extra: ExtraJobDataItem & {
    overrideReason?: string;
    overrideDriverPaidHours?: string;
    overridePaidHoursReason?: string;
  };
  allowances: ExtendedTimesheetJobAllowanceItem[];
};

type ExtendedTimesheetShiftAllowanceItem = TimesheetShiftAllowanceItem & IHasChangeState;

type ExtendedTimesheetWorkshopShiftItem = TimesheetWorkshopShiftItem & {
  allowances: ExtendedTimesheetShiftAllowanceItem[];
};

type TimesheetRowItem =
  | ExtendedTimesheetJobItem
  | TimesheetLeaveItem
  | ExtendedTimesheetWorkshopShiftItem
  | TimesheetPublicHolidayItem;

const MaintainTimesheet: React.FC<IMaintainTimesheetProps> = observer(
  (props: IMaintainTimesheetProps) => {
    const rootStore = useRootStore();
    const peopleModel = rootStore.people;
    const staffMemberModel = peopleModel.staffMembers;
    const timesheetModel = peopleModel.timesheet;
    const timesheetsModel = peopleModel.timesheets;
    const prefilledTimesheetModel = rootStore.operations.job.prefilledTimesheetItem;
    const depotModel = peopleModel.depots;
    const isCreateMode = props.mode === 'create';
    const isUpdateMode = props.mode === 'update';
    const timesheetId = props.route.match.params.id!;
    const timesheetPrintContent = timesheetModel.timesheetForPrinting;
    const timesheetActivityLogs = timesheetModel.activityLogs.slice();

    const getData = (): Partial<ITimesheetForm> | undefined => {
      if (isCreateMode && prefilledTimesheetModel.prefilledTimesheet) {
        const timesheetItem: Partial<TimesheetItem> = {
          ...prefilledTimesheetModel.prefilledTimesheet,
          records: prefilledTimesheetModel.prefilledTimesheet.records.map(x => {
            const record: TimesheetRecordItem = {
              day: x.day,
              jobs: x.jobs.map(j => {
                const job: TimesheetJobItem = {
                  ...j,
                };
                return job;
              }),
              leaves: x.leaves.map(l => {
                const leave: TimesheetLeaveItem = {
                  ...l,
                  amendedPaidHours: undefined,
                  amendedPaidHoursReason: '',
                };
                return leave;
              }),
              publicHolidays: x.publicHolidays.map(l => {
                const holiday: TimesheetPublicHolidayItem = {
                  ...l,
                  amendedPaidHours: undefined,
                  amendedPaidHoursReason: '',
                };
                return holiday;
              }),
              workshopShifts: x.workshopShifts.map(s => {
                const shift: TimesheetWorkshopShiftItem = {
                  ...s,
                  id: 0,
                  amendedPaidHours: undefined,
                  amendedPaidHoursReason: '',
                };
                return shift;
              }),
              totals: x.totals,
            };
            return record;
          }),
        };

        const timesheetWeek = timesheetsModel.timesheetWeek;
        return {
          ...timesheetItem,
          timesheetWeek,
        };
      }
      if (isUpdateMode && timesheetModel.timesheet && timesheetModel.timesheet.id === timesheetId) {
        return {
          ...timesheetModel.timesheet,
          timesheetWeek: {
            weekStart: timesheetModel.timesheet.weekStart,
            weekEnd: timesheetModel.timesheet.weekEnd,
          },
        };
      }
      return {};
    };

    const handlePreSubmitForCreate = (timesheet: ITimesheetForm): CreateTimesheetCommand => {
      const { staffMemberId, staffMemberNotes, weekStart } = timesheet;
      return {
        staffMemberId: staffMemberId,
        staffMemberNotes: staffMemberNotes,
        weekStart: weekStart,
      };
    };

    const handlePreSubmitForUpdate = (timesheet: ITimesheetForm): UpdateTimesheetCommand => {
      return {
        id: timesheet.id,
        staffMemberNotes: timesheet.staffMemberNotes,
      };
    };

    const isTimesheetJob = (item: TimesheetRowItem | unknown): item is TimesheetJobItem =>
      (item as TimesheetJobItem).jobId !== undefined;
    const isTimesheetLeave = (item: TimesheetRowItem | unknown): item is TimesheetLeaveItem =>
      (item as TimesheetLeaveItem).leaveId !== undefined;
    const isTimesheetHoliday = (
      item: TimesheetRowItem | unknown
    ): item is TimesheetPublicHolidayItem =>
      (item as TimesheetPublicHolidayItem).holidayId !== undefined;
    const isTimesheetWorkshopShift = (
      item: TimesheetRowItem | unknown
    ): item is TimesheetWorkshopShiftItem =>
      (item as TimesheetWorkshopShiftItem).shiftId !== undefined;

    const getAmendPaidHoursModal = (
      day: string,
      timesheetAllowances: Common.Queries.People.TimesheetAllowances.ListTimesheetAllowanceItem[]
    ): ModalDefBuilder => {
      return (modalDefApi: IModalDefBuilderApi) => {
        const hideOverridePaidHours = !ENABLE_TIMESHEET_OVERRIDE_PAID_HOURS;
        const timesheetItem = modalDefApi.actionData.parentValue as TimesheetItem;
        const timesheetRowItem = modalDefApi.actionData.actionValue as TimesheetRowItem;
        if (isTimesheetJob(timesheetRowItem)) {
          const extraJobData = timesheetItem.extraJobsData.find(
            x => x.jobId === timesheetRowItem.jobId
          )!;
          timesheetRowItem.extra = {
            ...extraJobData,
            overrideReason: timesheetRowItem.reasonForCompletionDetailsOverride,
            overrideDriverPaidHours: extraJobData?.dayOverrides?.find(i => i.day.startsWith(day))
              ?.overridePaidHours,
            overridePaidHoursReason: extraJobData?.dayOverrides?.find(i => i.day.startsWith(day))
              ?.overridePaidHoursReason,
          };
          timesheetRowItem.allowances = timesheetItem.timesheetJobAllowanceItems.filter(
            item => item.jobId === timesheetRowItem.jobId && item.date === day
          );
        }
        if (isTimesheetWorkshopShift(timesheetRowItem)) {
          timesheetRowItem.allowances = timesheetItem.timesheetShiftAllowanceItems.filter(
            item => item.shiftId === timesheetRowItem.shiftId && item.date === day
          );
        }
        return {
          title: 'Amend Paid Hours',
          asForm: true,
          explicitData: timesheetRowItem,
          panels: [
            {
              panes: [
                {
                  paneType: PaneType.formFieldsPane,
                  columnCount: 1,
                  hidden: d => !isTimesheetJob(d.parentValue),
                  fields: [
                    {
                      fieldType: FieldType.durationField,
                      dataAddr: ['calculatedWorkingHours'],
                      label: 'Total Paid Hours',
                      readonly: true,
                      hidden: hideOverridePaidHours,
                      formatReadonly: d => <DurationFormat value={d.fieldValue} />,
                    },
                    {
                      fieldType: FieldType.durationField,
                      dataAddr: ['extra', 'overrideDriverPaidHours'],
                      label: 'Override Paid Hours',
                      validate: d => {
                        const duration = parseTimeSpan(d.fieldValue);
                        const isValid = duration.isValid;
                        const overtimeHours = parseTimeSpan(d.paneValue.amendedOvertimeHours);
                        if (
                          d.fieldValue === undefined ||
                          d.fieldValue === null ||
                          d.fieldValue === ''
                        ) {
                          return undefined;
                        }
                        if (duration < overtimeHours) {
                          return 'Paid hours cannot be less then overtime hours';
                        }
                        return !isValid ? 'Invalid duration' : undefined;
                      },
                      hidden: hideOverridePaidHours,
                    },
                    {
                      fieldType: FieldType.textAreaField,
                      dataAddr: ['extra', 'overridePaidHoursReason'],
                      label: 'Override Reason',
                      maxLength: 200,
                      mandatory: d =>
                        d.parentValue.extra && d.parentValue.extra.overrideDriverPaidHours,
                      hidden: d =>
                        !(d.parentValue.extra && d.parentValue.extra.overrideDriverPaidHours),
                    },
                    {
                      fieldType: FieldType.durationField,
                      dataAddr: ['extra', 'overrideDriverUnpaidBreaks'],
                      label: 'Override Unpaid Breaks',
                      validate: d => {
                        if (
                          d.fieldValue === undefined ||
                          d.fieldValue === null ||
                          d.fieldValue === ''
                        ) {
                          return undefined;
                        }
                        const duration = parseTimeSpan(d.fieldValue);
                        const isValid = duration.isValid && duration.hours < 24;

                        return !isValid ? 'Unpaid Breaks cannot exceed 24 hours' : undefined;
                      },
                    },
                    {
                      fieldType: FieldType.textAreaField,
                      dataAddr: ['extra', 'overrideReason'],
                      label: 'Override Reason',
                      maxLength: 200,
                      mandatory: d =>
                        d.parentValue.extra && d.parentValue.extra.overrideDriverUnpaidBreaks,
                      hidden: d =>
                        !(d.parentValue.extra && d.parentValue.extra.overrideDriverUnpaidBreaks),
                    },
                    {
                      fieldType: FieldType.customField,
                      label: '',
                      dataAddr: 'none',
                      hidden: d => {
                        const updated = d.parentValue;
                        const original = timesheetRowItem as ExtendedTimesheetJobItem;
                        const normalizeValue = (value: string | undefined) => {
                          if (value === undefined || value === null || value === '') {
                            return undefined;
                          }
                          return value;
                        };
                        return (
                          normalizeValue(updated.extra.overrideDriverUnpaidBreaks) ===
                            normalizeValue(original.extra.overrideDriverUnpaidBreaks) &&
                          normalizeValue(updated.extra.overrideReason) ===
                            normalizeValue(original.extra.overrideReason)
                        );
                      },
                      render: () => (
                        <>
                          <strong>Note:</strong> Overriding Paid Hours or Unpaid Breaks will refresh
                          the timesheet, keeping all previous amendments.
                        </>
                      ),
                    },
                  ],
                },
                {
                  paneType: PaneType.customPane,
                  render: _ => <hr />,
                },
                {
                  paneType: PaneType.formFieldsPane,
                  columnCount: 1,
                  fields: [
                    {
                      fieldType: FieldType.durationField,
                      dataAddr: 'amendedPaidHours',
                      label: 'Amended Paid Hours',
                      validate: d => {
                        if (
                          d.fieldValue === undefined ||
                          d.fieldValue === null ||
                          d.fieldValue === ''
                        ) {
                          return undefined;
                        }
                        const duration = parseTimeSpan(d.fieldValue);
                        const isValid = duration.isValid && duration.hours < 24;
                        return !isValid ? 'Paid hours cannot exceed 24 hours' : undefined;
                      },
                      hidden: d => !hideOverridePaidHours && isTimesheetJob(d.parentValue),
                    },
                    {
                      fieldType: FieldType.durationField,
                      dataAddr: 'amendedOvertimeHours',
                      label: 'Amended Overtime Hours',
                      hidden: d =>
                        !!d.paneValue.leaveType || d.paneValue.overtimeTotalHours === undefined,
                      validate: d => {
                        let paidHours = parseTimeSpan(d.paneValue.paidHours);
                        let overtimeHours = parseTimeSpan(d.paneValue.overtimeTotalHours);
                        if (d.paneValue.amendedPaidHours) {
                          const amendedPaidHours = parseTimeSpan(d.paneValue.amendedPaidHours);
                          if (amendedPaidHours.isValid) {
                            paidHours = amendedPaidHours;
                          }
                        }
                        if (
                          d.fieldValue === undefined ||
                          d.fieldValue === null ||
                          d.fieldValue === ''
                        ) {
                          if (overtimeHours > paidHours) {
                            return 'Overtime hours cannot be greater than Paid hours';
                          }
                          return undefined;
                        }
                        const amendedOvertimeDuration = parseTimeSpan(d.fieldValue);
                        const isValid =
                          amendedOvertimeDuration.isValid && amendedOvertimeDuration.hours < 24;
                        if (!isValid) {
                          return 'Overtime hours cannot exceed 24 hours';
                        }
                        if (amendedOvertimeDuration > paidHours) {
                          return 'Overtime hours cannot be greater than Paid hours';
                        }
                        return undefined;
                      },
                    },
                    {
                      fieldType: FieldType.textAreaField,
                      dataAddr: 'amendedPaidHoursReason',
                      label: 'Reason',
                      maxLength: 200,
                      mandatory: d =>
                        (d.parentValue.amendedPaidHours && d.parentValue.amendedPaidHours !== '') ||
                        (d.parentValue.amendedOvertimeHours &&
                          d.parentValue.amendedOvertimeHours !== ''),
                    },
                  ],
                },
                getAllowancesPane(timesheetRowItem, timesheetAllowances),
              ],
            },
          ],
          secondaryActions: [getSubmitCloseModalActionGroupDef('Ok')],
          onFormSubmit: v => {
            const item = v as TimesheetRowItem;
            const command: Partial<AmendTimesheetCommand> = {
              id: timesheetId,
              day: day,
            };
            const sharedData = {
              amendedPaidHours: item.amendedPaidHours,
              amendedPaidHoursReason: item.amendedPaidHoursReason,
              staffMemberId: item.staffMemberId,
            };
            if (isTimesheetJob(item)) {
              command.job = {
                ...sharedData,
                jobId: item.jobId,
                amendedOvertimeHours: item.amendedOvertimeHours,
                overrideDriverUnpaidBreaks: item.extra.overrideDriverUnpaidBreaks,
                reasonForCompletionDetailsOverride: !!item.extra.overrideDriverUnpaidBreaks
                  ? item.extra.overrideReason!
                  : '',
                staffMemberId: item.staffMemberId,
                overridePaidHours: item.extra.overrideDriverPaidHours,
                overridePaidHoursReason: !!item.extra.overrideDriverPaidHours
                  ? item.extra.overridePaidHoursReason
                  : '',
                allowances: item.allowances
                  .filter(a => a.changeState !== ChangeState.Deleted)
                  .map(a => {
                    return { allowanceId: a.allowanceId, quantity: a.quantity };
                  }),
              } as People.Domain.Commands.Timesheets.AmendTimesheet.TimesheetJobItem;
            } else if (isTimesheetLeave(item)) {
              command.leave = {
                ...sharedData,
                leaveId: item.leaveId,
              };
            } else if (isTimesheetHoliday(item)) {
              command.publicHoliday = {
                ...sharedData,
                holidayId: item.holidayId,
              };
            } else if (isTimesheetWorkshopShift(item)) {
              command.workshopShift = {
                ...sharedData,
                amendedOvertimeHours: item.amendedOvertimeHours,
                id: item.id,
                shiftId: item.shiftId,
                allowances: item.allowances
                  .filter(a => a.changeState !== ChangeState.Deleted)
                  .map(a => {
                    return { allowanceId: a.allowanceId, quantity: a.quantity };
                  }),
              };
            }
            return Promise.resolve(timesheetModel.amendTimesheet(command as AmendTimesheetCommand));
          },
        };
      };
    };

    const getAllowancesPane = (
      timesheetRowItem: TimesheetRowItem,
      timesheetAllowances: Common.Queries.People.TimesheetAllowances.ListTimesheetAllowanceItem[]
    ): INestingPaneDef => {
      if (isTimesheetWorkshopShift(timesheetRowItem) || isTimesheetJob(timesheetRowItem)) {
        return {
          paneType: PaneType.nestingPane,
          dataAddr: 'allowances',
          panes: [
            {
              paneType: PaneType.tablePane,
              title: 'Allowances',
              dataRequiredForRows: 'paneValue',
              fields: [
                {
                  fieldType: FieldType.selectField,
                  dataAddr: 'allowanceId',
                  label: 'Description',
                  valueKey: 'id',
                  descriptionKey: 'name',
                  useValueOnly: true,
                  optionItems: d => {
                    if (d.fieldValue) {
                      return timesheetAllowances
                        .filter(p => p.active || p.id === d.fieldValue)
                        .sort((a, b) => (a.active === b.active ? 0 : a.active ? -1 : 1));
                    } else {
                      return timesheetAllowances.filter(p => p.active);
                    }
                  },
                  mandatory: true,
                  valuesToDisable: d => {
                    const allowanceItems = d.paneValue as
                      | Common.Dtos.TimesheetJobAllowanceItem[]
                      | Common.Dtos.TimesheetShiftAllowanceItem[];
                    const inactiveAllowances = timesheetAllowances
                      .filter(p => !p.active)
                      .map(p => p.id);
                    return (allowanceItems ? allowanceItems.map(v => v.allowanceId) : []).concat(
                      inactiveAllowances
                    );
                  },
                },
                {
                  fieldType: FieldType.numericField,
                  dataAddr: 'quantity',
                  numericConfig: {
                    numericType: 'unsignedDecimal',
                    maxPointDigits: 2,
                    maxValue: 999.99,
                  },
                  label: 'Quantity',
                  columnWidth: '6em',
                  mandatory: true,
                },
                {
                  fieldType: FieldType.actionListField,
                  columnWidth: '1px',
                  actionGroups: [
                    {
                      actions: [
                        {
                          actionType: ActionType.removeArrayItemActionButton,
                          label: 'Remove Allowance',
                        },
                      ],
                    },
                  ],
                },
              ],
            },
            {
              paneType: PaneType.actionListPane,
              actionGroups: [
                {
                  actions: [
                    {
                      actionType: ActionType.addArrayItemActionButton,
                      label: 'Add Allowance',
                    },
                  ],
                },
              ],
            },
          ],
        };
      } else {
        return {
          paneType: PaneType.nestingPane,
          hidden: true,
          panes: [],
        };
      }
    };

    const getUpdateNotesModal = (): ModalDefBuilder => {
      return () => ({
        title: 'Update Staff Member Notes',
        asForm: true,
        panels: [
          {
            panes: [
              {
                paneType: PaneType.formFieldsPane,
                columnCount: 1,
                fields: [
                  {
                    fieldType: FieldType.textAreaField,
                    label: 'Staff Member Notes',
                    dataAddr: 'staffMemberNotes',
                  },
                ],
              },
            ],
          },
        ],
        secondaryActions: [getSubmitCloseModalActionGroupDef('Ok')],
        onFormPreSubmit: handlePreSubmitForUpdate,
        onFormSubmit: timesheetModel.updateTimesheet,
      });
    };

    const onStaffMemberChanged = async (api: IFieldOnChange<string | undefined>) => {
      const staffMemberId = api.newFieldValue;
      if (staffMemberId) {
        const staffMember = staffMemberModel.allStaffMembers.find(x => x.id === staffMemberId);
        await timesheetsModel.getWeekWithoutTimesheet({
          staffMemberId,
        });
        const timesheetWeek = timesheetsModel.timesheetWeek;

        if (timesheetWeek) {
          await prefilledTimesheetModel.getPrefilledTimesheet({
            staffMemberId,
            weekStart: timesheetWeek.weekStart,
          });
        }
        const newValues = {
          ...api.formValues,
          ...getData(),
          staffMember: staffMember,
        };
        api.setFormValues(newValues);
      }
    };

    const onDateChanged = async (api: IFieldOnChange<TimesheetWeek | undefined>) => {
      const timesheetWeek = api.newFieldValue;

      if (timesheetWeek) {
        const staffMemberId = api.fieldData.paneValue.staffMemberId;
        await prefilledTimesheetModel.getPrefilledTimesheet({
          staffMemberId,
          weekStart: timesheetWeek.weekStart,
        });
        const newValues = {
          ...api.formValues,
          ...getData(),
        };
        api.setFormValues(newValues);
      }
    };

    const onNext = async (api: WeekSelectCallbackApi) => {
      const timesheetWeek = api.fieldData.fieldValue as TimesheetWeek;
      if (timesheetWeek) {
        const staffMemberId = api.fieldData.paneValue.staffMemberId;
        let weekStart = DateTime.fromISO(timesheetWeek.weekStart);

        weekStart = weekStart.plus({
          weeks: getPayPeriodInWeeks(),
        });

        await timesheetsModel.getWeekWithoutTimesheet({
          staffMemberId,
          weekStart: weekStart.toISODate(),
        });
        const newTimesheetWeek = timesheetsModel.timesheetWeek;
        if (newTimesheetWeek) {
          await prefilledTimesheetModel.getPrefilledTimesheet({
            staffMemberId,
            weekStart: newTimesheetWeek.weekStart,
          });
        }
        const newValues = {
          ...api.formValues,
          ...getData(),
        };
        api.setFormValues(newValues);
      }
    };

    const onPrevious = async (api: WeekSelectCallbackApi) => {
      const timesheetWeek = api.fieldData.fieldValue as TimesheetWeek;
      if (timesheetWeek) {
        const staffMemberId = api.fieldData.paneValue.staffMemberId;
        await timesheetsModel.getWeekWithoutTimesheet({
          staffMemberId,
          weekStart: timesheetWeek.weekStart,
          backwards: true,
        });
        const newTimesheetWeek = timesheetsModel.timesheetWeek;
        if (newTimesheetWeek) {
          await prefilledTimesheetModel.getPrefilledTimesheet({
            staffMemberId,
            weekStart: newTimesheetWeek.weekStart,
          });
        }
        const newValues = {
          ...api.formValues,
          ...getData(),
        };
        api.setFormValues(newValues);
      }
    };

    const getPageDef = (updating: boolean): ICrudPageDef => {
      const editable = isCreateMode || updating;
      const amendable = !isCreateMode && !updating;
      const isProcessed =
        isUpdateMode &&
        !!timesheetModel.timesheet &&
        timesheetModel.timesheet.status &&
        timesheetModel.timesheet.status === TimesheetStatus.Processed;
      const isReadOnly = isProcessed;

      return {
        primarySize: PagePrimarySize.threeQuarters,
        primarySection: {
          title: isCreateMode ? 'Create a Timesheet' : 'Manage Timesheet',
          badge:
            isUpdateMode && timesheetModel.timesheet && timesheetModel.timesheet.status
              ? {
                  label: getTimesheetStatusDescriptor(timesheetModel.timesheet.status).description,
                }
              : undefined,
          panels: [
            {
              panes: [
                {
                  paneType: PaneType.formFieldsPane,
                  columnCount: 2,
                  fields: [
                    {
                      fieldType: FieldType.selectField,
                      label: 'Staff Member',
                      dataAddr: 'staffMemberId',
                      optionItems: staffMemberModel.allStaffMembers,
                      valueKey: 'id',
                      linkTo: d => `/people/staff-members/${d.parentValue.staffMemberId}`,
                      descriptionKey: 'name',
                      useValueOnly: true,
                      mandatory: true,
                      readonly: isUpdateMode,
                      includeKey: 'active',
                      onChange: onStaffMemberChanged,
                    },
                    {
                      fieldType: FieldType.weekSelectField,
                      label: 'Date',
                      dataAddr: 'timesheetWeek',
                      hidden: d => !d.panelValue.staffMemberId,
                      readonly: isUpdateMode,
                      onChange: onDateChanged,
                      onNext: onNext,
                      onPrevious: onPrevious,
                    },
                    {
                      fieldType: FieldType.readonlyField,
                      label: 'Employee Id',
                      dataAddr: 'employeeId',
                      hidden: d => !d.panelValue.staffMemberId,
                    },
                    {
                      fieldType: FieldType.readonlyField,
                      label: 'Employment Status',
                      dataAddr: ['employmentStatus', 'description'],
                      hidden: d => !d.panelValue.staffMemberId,
                    },
                    {
                      fieldType: FieldType.selectField,
                      label: 'Depot',
                      dataAddr: 'depot',
                      hidden: d => !d.panelValue.staffMemberId,
                      valueKey: 'id',
                      descriptionKey: 'description',
                      optionItems: depotModel.staffDepots.slice(),
                      useValueOnly: true,
                      readonly: true,
                    },
                  ],
                },
              ],
            },
            {
              title: 'Timesheet Details',
              hidden: d => !d.panelValue.records,
              panes: [
                {
                  paneType: PaneType.customPane,
                  render: api => {
                    const timesheetItem = api.data.paneValue as TimesheetItem;
                    return (
                      <TimesheetTable
                        timesheet={timesheetItem}
                        editable={!isReadOnly && amendable}
                        modalBuilder={day =>
                          getAmendPaidHoursModal(day, peopleModel.timesheetAllowances.items.slice())
                        }
                        paneData={api.data}
                        paneMeta={api.meta}
                      />
                    );
                  },
                },
                {
                  paneType: PaneType.formFieldsPane,
                  columnCount: 1,
                  fields: [
                    {
                      fieldType: FieldType.customField,
                      // named this way because this field does not
                      // have a data address, but a unique one must be set
                      dataAddr: 'none1',
                      label: '',
                      render: d => (
                        <div className={styles.fieldCombo}>
                          <PageField
                            fieldDef={{
                              fieldType: FieldType.textAreaField,
                              readonly: !isCreateMode && (isReadOnly || !amendable),
                              label: 'Staff Member Notes',
                              dataAddr: 'staffMemberNotes',
                            }}
                            fieldMeta={d.meta}
                            paneData={d.data}
                            parentValue={d.data.parentValue}
                          />
                          <div>
                            <label>&nbsp;</label>
                            <PageField
                              fieldDef={{
                                fieldType: FieldType.actionListField,
                                actionGroups: [
                                  {
                                    actions: [
                                      {
                                        actionType: ActionType.modalActionButton,
                                        hidden: isReadOnly || !amendable,
                                        label: 'Edit notes',
                                        icon: <EditIcon />,
                                        modalSize: ShellModalSize.oneThird,
                                        modalDef: getUpdateNotesModal(),
                                      },
                                    ],
                                  },
                                ],
                              }}
                              fieldMeta={d.meta}
                              paneData={d.data}
                              parentValue={d.data.parentValue}
                            />
                          </div>
                        </div>
                      ),
                    },
                  ],
                },
              ],
            },
            {
              title: 'Cost Centre Breakdown',
              hidden: d => !d.panelValue.records,
              panes: [
                {
                  paneType: PaneType.customPane,
                  render: api => {
                    const timesheetItem = api.data.paneValue;
                    return <CostCentre timesheet={timesheetItem} />;
                  },
                },
              ],
            },
            {
              title: 'Timesheet Summary',
              hidden: d => !d.panelValue.records,
              panes: [
                {
                  paneType: PaneType.formFieldsPane,
                  columnCount: 2,
                  fields: [
                    {
                      fieldType: FieldType.readonlyField,
                      label: 'Total Worked Hrs (Hours & Minutes)',
                      formatReadonly: api => {
                        return (
                          <DurationFormat
                            value={MapTimesheetItemToRecordsTotals(api.paneValue).totalWorkedHrs}
                          />
                        );
                      },
                    },
                    {
                      fieldType: FieldType.readonlyField,
                      label: 'Total Worked Hrs (Decimals)',
                      formatReadonly: api => {
                        return (
                          <DurationFormat
                            value={MapTimesheetItemToRecordsTotals(api.paneValue).totalWorkedHrs}
                            showHoursAndMinutes={false}
                            showHoursAndDecimals
                          />
                        );
                      },
                    },
                    {
                      fieldType: FieldType.readonlyField,
                      label: 'Total Overtime Hrs',
                      formatReadonly: api => {
                        return (
                          <DurationFormat
                            value={MapTimesheetItemToRecordsTotals(api.paneValue).totalOvertimeHrs}
                          />
                        );
                      },
                    },
                    {
                      fieldType: FieldType.readonlyField,
                      label: 'Total Overtime Hrs',
                      formatReadonly: api => {
                        return (
                          <DurationFormat
                            value={MapTimesheetItemToRecordsTotals(api.paneValue).totalOvertimeHrs}
                            showHoursAndMinutes={false}
                            showHoursAndDecimals
                          />
                        );
                      },
                    },
                    {
                      fieldType: FieldType.readonlyField,
                      label: 'Total Leave Hrs',
                      formatReadonly: api => {
                        return (
                          <DurationFormat
                            value={MapTimesheetItemToRecordsTotals(api.paneValue).totalLeaveHrs}
                          />
                        );
                      },
                    },
                    {
                      fieldType: FieldType.readonlyField,
                      label: 'Total Leave Hrs',
                      formatReadonly: api => {
                        return (
                          <DurationFormat
                            value={MapTimesheetItemToRecordsTotals(api.paneValue).totalLeaveHrs}
                            showHoursAndMinutes={false}
                            showHoursAndDecimals
                          />
                        );
                      },
                    },
                    {
                      fieldType: FieldType.readonlyField,
                      label: 'Total Public Holiday Hrs',
                      formatReadonly: api => {
                        return (
                          <DurationFormat
                            value={
                              MapTimesheetItemToRecordsTotals(api.paneValue).totalPublicHolidayHrs
                            }
                          />
                        );
                      },
                    },
                    {
                      fieldType: FieldType.readonlyField,
                      label: 'Total Public Holiday Hrs',
                      formatReadonly: api => {
                        return (
                          <DurationFormat
                            value={
                              MapTimesheetItemToRecordsTotals(api.paneValue).totalPublicHolidayHrs
                            }
                            showHoursAndMinutes={false}
                            showHoursAndDecimals
                          />
                        );
                      },
                    },
                    {
                      fieldType: FieldType.readonlyField,
                      label: 'Total Paid Hrs',
                      formatReadonly: api => {
                        return (
                          <DurationFormat
                            value={MapTimesheetItemToRecordsTotals(api.paneValue).totalPaidHrs}
                          />
                        );
                      },
                    },
                    {
                      fieldType: FieldType.readonlyField,
                      label: 'Total Paid Hrs',
                      formatReadonly: api => {
                        return (
                          <DurationFormat
                            value={MapTimesheetItemToRecordsTotals(api.paneValue).totalPaidHrs}
                            showHoursAndMinutes={false}
                            showHoursAndDecimals
                          />
                        );
                      },
                    },
                    {
                      fieldType: FieldType.readonlyField,
                      label: 'Total On Call Days',
                      formatReadonly: api => {
                        return MapTimesheetItemToRecordsTotals(api.paneValue).totalOnCall;
                      },
                    },
                  ],
                },
              ],
            },
          ],
          primaryActions: [
            {
              actions: [
                {
                  actionType: ActionType.actionCollection,
                  hidden: updating || !isUpdateMode,
                  actionGroups: [
                    {
                      actions: [
                        {
                          actionType: ActionType.modalActionButton,
                          label: 'Approve Timesheet',
                          icon: <CheckIcon />,
                          hidden:
                            timesheetModel.timesheet &&
                            timesheetModel.timesheet.status !== TimesheetStatus.Submitted,
                          modalSize: ShellModalSize.oneQuarter,
                          modalDef: () => ({
                            title: 'Approve Timesheet',
                            asForm: true,
                            panels: [
                              {
                                panes: [
                                  {
                                    paneType: PaneType.customPane,
                                    render: api => (
                                      <>
                                        {api.data.paneValue.hasIncompleteJobs && (
                                          <div>
                                            <h4>
                                              <WarnIcon
                                                color="red"
                                                size="lg"
                                                title="Incomplete Jobs Warning"
                                              />{' '}
                                              Incomplete Jobs Warning
                                            </h4>
                                            <p>
                                              <strong>
                                                {' '}
                                                {api.data.paneValue.staffMemberFullName}{' '}
                                              </strong>
                                              has incomplete jobs for the pay period being reviewed.
                                            </p>
                                          </div>
                                        )}

                                        <span>Are you sure you want to approve timesheet?</span>
                                      </>
                                    ),
                                  },
                                ],
                              },
                            ],
                            secondaryActions: [getSubmitCloseModalActionGroupDef('Ok')],
                            onFormSubmit: () => timesheetModel.approveTimesheet(timesheetId),
                          }),
                        },
                        {
                          actionType: ActionType.modalActionButton,
                          label: 'Process Timesheet',
                          icon: <CheckIcon />,
                          hidden:
                            timesheetModel.timesheet &&
                            timesheetModel.timesheet.status !== TimesheetStatus.Approved,
                          modalSize: ShellModalSize.oneQuarter,
                          modalDef: () => ({
                            title: 'Process Timesheet',
                            asForm: true,
                            panels: [
                              {
                                panes: [
                                  {
                                    paneType: PaneType.customPane,
                                    render: api => (
                                      <>
                                        {api.data.paneValue.hasIncompleteJobs && (
                                          <p>
                                            <strong>
                                              {api.data.paneValue.staffMemberFullName}{' '}
                                            </strong>
                                            has incomplete jobs for the pay period being reviewed.
                                          </p>
                                        )}

                                        <span>
                                          Are you sure you want to process timesheet? No further
                                          changes to timesheet will be allowed.
                                        </span>
                                      </>
                                    ),
                                  },
                                ],
                              },
                            ],
                            secondaryActions: [getSubmitCloseModalActionGroupDef('Ok')],
                            onFormSubmit: () => timesheetModel.processTimesheet(timesheetId),
                          }),
                        },
                        {
                          actionType: ActionType.modalActionButton,
                          label: 'Refresh Timesheet',
                          icon: <SyncIcon />,
                          hidden:
                            timesheetModel.timesheet &&
                            !(
                              timesheetModel.timesheet.status === TimesheetStatus.Approved ||
                              timesheetModel.timesheet.status === TimesheetStatus.Submitted
                            ),
                          modalSize: ShellModalSize.oneQuarter,
                          modalDef: () => ({
                            title: 'Refresh Timesheet',
                            asForm: true,
                            panels: [
                              {
                                panes: [
                                  {
                                    paneType: PaneType.customPane,
                                    render: () => (
                                      <>
                                        <p>Are you sure you want to refresh timesheet?</p>
                                        <p>Amendments will be lost</p>
                                        {timesheetModel.timesheet &&
                                        timesheetModel.timesheet.status ===
                                          TimesheetStatus.Approved ? (
                                          <p>Approval will reset</p>
                                        ) : null}
                                      </>
                                    ),
                                  },
                                ],
                              },
                            ],
                            secondaryActions: [getSubmitCloseModalActionGroupDef('Ok')],
                            onFormSubmit: () =>
                              timesheetModel.refreshTimesheet({
                                timesheetId: timesheetId,
                                keepAmendments: false,
                              }),
                          }),
                        },
                        {
                          actionType: ActionType.modalActionButton,
                          label: 'Refresh Timesheet and keep amendments',
                          icon: <SyncIcon />,
                          hidden:
                            timesheetModel.timesheet &&
                            !(
                              timesheetModel.timesheet.status === TimesheetStatus.Approved ||
                              timesheetModel.timesheet.status === TimesheetStatus.Submitted
                            ),
                          modalSize: ShellModalSize.oneQuarter,
                          modalDef: () => ({
                            title: 'Refresh Timesheet and keep amendments',
                            asForm: true,
                            panels: [
                              {
                                panes: [
                                  {
                                    paneType: PaneType.customPane,
                                    render: () => (
                                      <>
                                        <p>Are you sure you want to refresh timesheet?</p>
                                        <p>Amendments will be kept</p>
                                        {timesheetModel.timesheet &&
                                        timesheetModel.timesheet.status ===
                                          TimesheetStatus.Approved ? (
                                          <p>Approval will reset</p>
                                        ) : null}
                                      </>
                                    ),
                                  },
                                ],
                              },
                            ],
                            secondaryActions: [getSubmitCloseModalActionGroupDef('Ok')],
                            onFormSubmit: () =>
                              timesheetModel.refreshTimesheet({
                                timesheetId: timesheetId,
                                keepAmendments: true,
                              }),
                          }),
                        },
                        {
                          actionType: ActionType.printActionButton,
                          label: 'Print Timesheet',
                          hidden: d => {
                            const staffMember = staffMemberModel.allStaffMembers.find(
                              x => x.id === d.actionValue.staffMemberId
                            );
                            return !timesheetModel.timesheet || !staffMember;
                          },
                          loadDataAsync: () =>
                            timesheetModel.getTimesheetForPrinting(timesheetModel.timesheet!.id),
                          printContent: timesheetPrintContent,
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
          clearStandardSecondaryActions: true,
          secondaryActions: editable
            ? [
                {
                  actions: [
                    {
                      actionType: ActionType.submitActionButton,
                      level: 'primary',
                      hidden: isCreateMode || isProcessed,
                    },
                    {
                      actionType: ActionType.submitActionButton,
                      level: 'primary',
                      hidden: d => isUpdateMode || !d.sectionValue || !d.sectionValue.staffMember,
                      confirmationModalSize: ShellModalSize.oneThird,
                      confirmationModalDef: modalDefApi => ({
                        title: 'Timesheet submission',
                        asForm: true,
                        panels: [
                          {
                            panes: [
                              {
                                paneType: PaneType.customPane,
                                render: () => {
                                  return (
                                    <div>
                                      <p>
                                        Are you sure you want to submit this timesheet on{' '}
                                        {modalDefApi.parentFormApi.values.staffMember.name}
                                        's behalf?
                                      </p>
                                      <p>
                                        Please make sure that they are aware of this submission.
                                      </p>
                                    </div>
                                  );
                                },
                              },
                            ],
                          },
                        ],
                        secondaryActions: [getSubmitCloseModalActionGroupDef()],
                        onFormSubmit: modalDefApi.parentFormApi.submitForm,
                      }),
                    },
                    {
                      actionType: ActionType.resetActionButton,
                    },
                  ],
                },
              ]
            : [],
          onFormPreSubmit: handlePreSubmitForCreate,
          onFormSubmit: timesheetModel.createTimesheet,
        },
        secondarySections: [
          {
            title: 'Jobs',
            hidden: api => !api.sectionValue || !api.sectionValue.hasIncompleteJobs,
            panels: [
              {
                panes: [
                  {
                    paneType: PaneType.customPane,
                    render: api => {
                      return (
                        <>
                          <h3>Incomplete Jobs Warning</h3>
                          <br />
                          <p>
                            <strong>{api.data.paneValue.staffMemberFullName}</strong> has incomplete
                            jobs for the pay period being reviewed.
                          </p>
                          <br />

                          <Link
                            to={`/operations/jobs?dateFrom=${api.data.paneValue.weekStart}&dateTo=${api.data.paneValue.weekEnd}&jobStatusIds=1&jobStatusIds=2&jobStatusIds=3&staffMembers=${api.data.paneValue.staffMemberId}`}>
                            View Incomplete Jobs
                          </Link>
                        </>
                      );
                    },
                  },
                ],
              },
            ],
          },
          getActivityLogPanelDef(timesheetId, timesheetActivityLogs),
        ],
      };
    };

    const loadData = () => {
      const promises = [
        staffMemberModel.loadAllStaffMembers(),
        depotModel.loadStaffDepots(),
        peopleModel.timesheetAllowances.listItems({ loadCause: ListPageLoadCause.mount }),
        isUpdateMode ? timesheetModel.viewTimesheet(timesheetId) : Promise.resolve(),
        timesheetModel.listActivityLogs(timesheetId),
      ];
      return Promise.all(promises).then(() => Promise.resolve());
    };

    return (
      <CrudPage
        def={({ updating }) => getPageDef(updating)}
        mode={props.mode}
        onLoadData={loadData}
        onLoadCreateDefaultData={loadData}
        data={getData()}
        createDefaultData={{}}
        isEditingForbidden
      />
    );
  }
);

export default MaintainTimesheet;
