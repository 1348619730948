import inject from 'src/views/injectFromStore';
import CreateOdometerReadings, {
  ICreateOdometerReadingsProps,
} from 'src/views/routes/workshop/assets/odometerReadings/CreateOdometerReadings/CreateOdometerReadings';

const Container = inject<ICreateOdometerReadingsProps>((allStores, _) => ({
  lastOdometerReadings: allStores.rootStore.workshop.assetOdometerReading.lastOdometerReadings,
  loadLastOdometerReadings:
    allStores.rootStore.workshop.assetOdometerReading.loadLastOdometerReadings,
  createOdometerReadings: allStores.rootStore.workshop.assetOdometerReading.createOdometerReadings,
}))(CreateOdometerReadings);

export default Container;
