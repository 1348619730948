import './ScheduledJob.scss';

import Icon from 'src/views/components/workshop/assetIcon/WorkshopAssetIcon';
import { DateTime } from 'luxon';
import { Button } from 'reactstrap';
import { RenderJobInterval } from './PrintDailyRunSheet/PrintDailyRunSheet';
import { getJobTaskCategoryClass } from '../../ScheduleCommon';
import { Link } from 'react-router-dom';

interface IScheduledJobProps {
  job: Workshop.Domain.Queries.GetDailyRunSheet.DailyRunSheetJob;
  day: DateTime;
}

const ScheduledJob: React.FC<IScheduledJobProps> = ({ job, day }) => {
  const hasNotes = job.supplierNames && job.supplierNames.length > 0;
  const isJobOutOfService = job.tasks.some(
    (x: { fitForService?: boolean }) => x.fitForService === false
  );

  return (
    <div className="daily-scheduled-job-component scrollable-item">
      <Button className="job-btn" color="dark">
        <div
          className={`job-container ${getJobTaskCategoryClass(job)} ${
            isJobOutOfService ? 'out-of-service' : ''
          }`}>
          <div className="job-icon">
            <Icon assetSubcategoryId={job.assetSubcategory.id} isLowFloor={false} />
          </div>
          <div className="job-asset">
            {job.assetName}
            {job.assetIsLowFloor ? ' (LF)' : ''}
          </div>
          <div className="job-description">
            <ul>
              {job.tasks.map(t => (
                <li key={t.taskId}>
                  <Link to={`/workshop/tasks/${t.taskId}`}>{t.taskNumber}</Link>
                  {' - '}
                  <span>{t.description}</span>
                </li>
              ))}
            </ul>
          </div>
          <div className="job-time">
            {RenderJobInterval(job.startDateTime, job.endDateTime, day)}
          </div>
          <div className="job-crew">
            <ul>
              {job.staffMembers.map((s, i) => (
                <li key={s.id}>{s.name}</li>
              ))}
            </ul>
          </div>

          <div className="job-contractor">
            {hasNotes
              ? `Supplier${job.supplierNames.length > 1 ? 's' : ''}: ${job.supplierNames.join(
                  ', '
                )}`
              : ''}
          </div>
        </div>
      </Button>
    </div>
  );
};

export default ScheduledJob;
