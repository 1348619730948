import './buildLabelledToggleButton.scss';

import { FormField as makeFormField, FieldApi } from 'react-form';
import LabelledFormField from 'src/views/components/forms/LabelledFormField';
import { applyFormFieldStaticFields } from '.';
import { getDefaultableBoolValue } from './utils';
import { ButtonGroup, Button } from 'reactstrap';
import FocusWrapper from 'src/views/components/FocusWrapper';

type TypeOfKey<T, TKey extends keyof T> = T[TKey];

export interface ILabelledToggleButtonProps<TOption> {
  readonly?: boolean;
  hideLabel?: boolean;
  autofocus?: boolean;
  disabled?: boolean;
  mandatory?: boolean;
  fieldIdPrefix?: Array<string | number>;
  optionItems: TOption[];
  // tslint:disable-next-line:no-any
  onChange?: (newValue: any, oldValue: any) => void;
}

export interface ILabelledToggleButtonBuilderOptions<TOption> {
  defaultReadonly?: boolean;
  decorateReadonlyValue?: (option: TOption | undefined) => React.ReactNode;
  defaultMandatory?: boolean;
}

export interface IDefaultToggleButtonOptionItem {
  value: string | number | boolean;
  label: string;
}

export function buildLabelledToggleButton(
  fieldId: string,
  labelValue: React.ReactNode,
  options: ILabelledToggleButtonBuilderOptions<IDefaultToggleButtonOptionItem> = {}
) {
  return buildLabelledToggleButtonTyped<IDefaultToggleButtonOptionItem>(
    fieldId,
    labelValue,
    options
  )('value', 'label');
}

export function buildLabelledToggleButtonTyped<TOption>(
  fieldId: string,
  labelValue: React.ReactNode,
  options: ILabelledToggleButtonBuilderOptions<TOption> = {}
) {
  // Use a wrapped function, so that devs can just provide the TOption type and have the
  // rest of the types inferred automatically
  return <TValueKey extends keyof TOption>(valueKey: TValueKey, labelKey: keyof TOption) =>
    buildLabelledToggleButtonInternal<TOption, TValueKey>(
      valueKey,
      labelKey,
      fieldId,
      labelValue,
      options
    );
}

function buildLabelledToggleButtonInternal<
  TOption,
  TValueKey extends keyof TOption,
  TValue extends TypeOfKey<TOption, TValueKey> = TypeOfKey<TOption, TValueKey>
>(
  valueKey: TValueKey,
  labelKey: keyof TOption,
  fieldId: string,
  labelValue: React.ReactNode,
  tooltipValue?: React.ReactNode,
  options: ILabelledToggleButtonBuilderOptions<TOption> = {}
) {
  type ExternalProps = ILabelledToggleButtonProps<TOption>;
  type Props = ExternalProps & { fieldApi: FieldApi };

  // Build the labelled text field component
  const LabelledTextInternal: React.FC<Props> = ({
    fieldApi,
    readonly,
    hideLabel,
    autofocus,
    disabled,
    mandatory,
    optionItems,
    onChange,
  }) => {
    const { getValue, getError, getTouched, setValue, setTouched } = fieldApi;
    const rawCurrentValue = getValue() as TValue;
    const currentOption = optionItems.find(o => o[valueKey] === rawCurrentValue);
    const error = getError();
    const touched = getTouched();
    const isMandatory = getDefaultableBoolValue(mandatory, options.defaultMandatory);

    function handleChange(option: TOption) {
      setTouched(true);
      if (onChange) {
        onChange(option[valueKey], rawCurrentValue);
      }
      setValue(option[valueKey]);
    }

    const readonlyValue = options.decorateReadonlyValue
      ? options.decorateReadonlyValue(currentOption)
      : currentOption && currentOption[labelKey];

    return (
      <LabelledFormField
        className="labelled-toggle-button-component"
        readonly={readonly || options.defaultReadonly || false}
        readonlyValue={readonlyValue}
        mandatory={isMandatory}
        hideLabel={hideLabel}
        labelValue={labelValue}
        tooltipValue={tooltipValue}
        error={touched && error}>
        <FocusWrapper onBlur={() => setTouched(true)}>
          <ButtonGroup>
            {optionItems.map((option, idx) => (
              <Button
                autoFocus={autofocus && idx === 0}
                key={idx}
                className="toggle-button"
                color="secondary"
                outline
                onClick={() => handleChange(option)}
                active={currentOption && currentOption[valueKey] === option[valueKey]}>
                {option[labelKey]}
              </Button>
            ))}
          </ButtonGroup>
        </FocusWrapper>
      </LabelledFormField>
    );
  };

  // Make the react-form fieldApi available to the component
  const FormField = makeFormField(LabelledTextInternal);

  // Create a wrapper component so the field's fieldId is already set
  const WrappedField: React.FC<ExternalProps> = props => (
    <FormField {...props} field={[...(props.fieldIdPrefix || []), fieldId]} />
  );

  // Capture the fieldId and labelValue onto the exported component
  const AppliedFormField = applyFormFieldStaticFields(WrappedField, { fieldId, labelValue });

  return AppliedFormField;
}

export default buildLabelledToggleButton;
