import './AddActionLink.scss';

import {
  IActionMeta,
  IActionData,
  IAddActionLinkDef,
  ActionType,
  IActionDef,
} from 'src/views/definitionBuilders/types';
import { Link } from 'react-router-dom';
import ActionButton from './ActionButton';
import { noop } from 'src/infrastructure/typeUtils';

interface IAddActionLinkProps {
  actionDef: IAddActionLinkDef;
  actionMeta: IActionMeta;
  actionData: IActionData;
  renderAsDropdownItem?: boolean;
}

const AddActionLink: React.FC<IAddActionLinkProps> = ({ actionDef, ...rest }) => {
  const getTo = () => {
    if (typeof actionDef.to === 'function') {
      return actionDef.to(rest.actionData);
    } else {
      return actionDef.to;
    }
  };
  return (
    <ActionButton
      {...rest}
      actionDef={{
        ...(actionDef as IActionDef),
        actionType: ActionType.actionButton,
        onClick: actionDef.onClick || noop,
      }}
      tag={p => <Link {...p} type={null} to={getTo()} />}
    />
  );
};

export default AddActionLink;
