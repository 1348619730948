import { useEffect } from 'react';
import { DateTime } from 'luxon';
import { AssetSubcategoryType } from 'src/domain';
import withQueryParams, { IQueryParamsProps } from 'src/views/hocs/withQueryParams';
import { FieldType, FieldDefs } from 'src/views/definitionBuilders/types/field';
import ListPage, { IListPageDef } from 'src/views/components/Page/pages/ListPage';
import { allAssetStatus, AssetStatus, AssetCategory } from 'src/api/enums';
import { PagePrimarySize } from 'src/views/definitionBuilders/types';
import { IListPageLoadDataRequest } from 'src/domain/baseTypes';

type ListUnallocatedAssetsQuery = Workshop.Domain.Queries.ListUnallocatedAssets.ListUnallocatedAssetsQuery;
type UnallocatedAssetListItem = Workshop.Domain.Queries.ListUnallocatedAssets.UnallocatedAssetListItem;
type AssetCategoryAggregate = Workshop.Domain.AggregatesModel.AssetAggregate.AssetCategory;
type AssetSubcategoryAggregate = Workshop.Domain.AggregatesModel.AssetAggregate.AssetSubcategory;
type AssetGroupItem = Workshop.Domain.Queries.AssetGroup.AssetGroupItem;
type AssetHousingLocation = Common.Queries.Workshop.GetAssetHousingLocations.AssetHousingLocationItem;
type EngineItem = Workshop.Domain.Queries.Engine.GetEngineList.EngineItem;

const primaryFields: IListPageDef['primaryFields'] = [
  {
    fieldType: FieldType.textField,
    dataAddr: 'name',
    label: 'Name',
    linkTo: d => `/workshop/assets/${d.parentValue.id}`,
    formatReadonly: data => {
      var asset = data.parentValue as UnallocatedAssetListItem;
      return (
        asset && (
          <span>
            {asset.isDecommissioned
              ? `${asset.name} (Decommissioned on ${DateTime.fromISO(
                  asset.decommissionedOn!
                ).toLocaleString(DateTime.DATE_SHORT)})`
              : asset.name}
          </span>
        )
      );
    },
  },
  {
    fieldType: FieldType.readonlyField,
    dataAddr: ['assetCategory', 'description'],
    label: 'Category',
  },
  {
    fieldType: FieldType.textField,
    dataAddr: ['assetSubcategory', 'description'],
    label: 'Subcategory',
  },
  {
    fieldType: FieldType.textField,
    dataAddr: ['vehicleType', 'description'],
    label: 'Vehicle Type',
  },
  {
    fieldType: FieldType.textField,
    dataAddr: ['assetGroupDescription'],
    label: 'Asset Group',
  },
  {
    fieldType: FieldType.textField,
    dataAddr: ['assetHousingLocation', 'description'],
    label: 'Garaged At',
  },
  {
    fieldType: FieldType.textField,
    dataAddr: 'engineModel',
    label: 'Engine Model',
  },
];

export interface IListUnallocatedAssetProps {
  canManageAssets: boolean;
  assets: UnallocatedAssetListItem[];
  listUnallocatedAssets: (
    request: IListPageLoadDataRequest<ListUnallocatedAssetsQuery>
  ) => Promise<void>;
  hasMoreData: boolean;
  assetCategories: Array<AssetCategoryAggregate>;
  assetSubcategories: Array<AssetSubcategoryAggregate>;
  assetGroups: Array<AssetGroupItem>;
  assetHousingLocations: Array<AssetHousingLocation>;
  engineModels: Array<EngineItem>;

  loadAssetCategories: () => Promise<void>;
  loadSubcategories: () => Promise<void>;
  loadAssetGroups: (includeInactiveAssetGroups?: boolean) => Promise<void>;
  loadAssetHousedAtLocations: () => Promise<void>;
  loadEngines: () => Promise<void>;
}

type InternalProps = IListUnallocatedAssetProps & IQueryParamsProps<ListUnallocatedAssetsQuery>;

const ListUnallocatedAsset: React.FC<InternalProps> = (props: InternalProps) => {
  useEffect(() => {
    props.loadAssetCategories();
    props.loadSubcategories();
    props.loadAssetGroups(false);
    props.loadAssetHousedAtLocations();
    props.loadEngines();
  }, []);

  const getRowKey: IListPageDef['rowKey'] = data => {
    return data.itemValue.id;
  };

  const getPageDef = (): IListPageDef => {
    const { hasMoreData, listUnallocatedAssets } = props;
    const filterFieldDefs: FieldDefs[] = [
      {
        fieldType: FieldType.dateField,
        label: 'Date',
        dataAddr: 'date',
      },
      {
        fieldType: FieldType.selectMultiField,
        label: 'Category',
        dataAddr: 'categories',
        useValueOnly: true,
        valueKey: 'id',
        descriptionKey: 'description',
        optionItems: props.assetCategories,
      },
      {
        fieldType: FieldType.selectMultiField,
        label: 'Subcategory',
        dataAddr: 'subcategories',
        useValueOnly: true,
        valueKey: 'id',
        descriptionKey: 'description',
        optionItems: props.assetSubcategories,
      },
      {
        fieldType: FieldType.selectMultiField,
        label: 'Asset Group',
        dataAddr: 'assetGroups',
        useValueOnly: true,
        valueKey: 'id',
        descriptionKey: 'description',
        optionItems: props.assetGroups,
      },
      {
        fieldType: FieldType.selectMultiField,
        label: 'Garaged At',
        dataAddr: 'housingLocations',
        useValueOnly: true,
        valueKey: 'id',
        descriptionKey: 'description',
        optionItems: props.assetHousingLocations,
      },
      {
        fieldType: FieldType.selectMultiField,
        label: 'Engine Model',
        dataAddr: 'engineModels',
        useValueOnly: true,
        valueKey: 'id',
        descriptionKey: 'model',
        optionItems: props.engineModels,
      },
      {
        fieldType: FieldType.selectMultiField,
        label: 'Status',
        dataAddr: 'statuses',
        useValueOnly: true,
        valueKey: 'value',
        descriptionKey: 'description',
        optionItems: allAssetStatus,
      },
    ];

    const getPrimaryTitle = () => {
      const queryParams = props.getQueryParams();
      const today = DateTime.local()
        .startOf('day')
        .toISODate();
      const queryDay = queryParams && queryParams.date ? queryParams.date : today;

      return queryDay === today
        ? `Unallocated Assets for Today`
        : `Unallocated Assets for ${DateTime.fromISO(queryDay).toLocaleString(DateTime.DATE_MED)}`;
    };

    return {
      primaryTitle: getPrimaryTitle(),
      onLoadData: listUnallocatedAssets,
      externalSearch: true,
      hasMoreData,
      rowKey: getRowKey,
      primaryFields,
      primarySize: PagePrimarySize.full,
      filterAction: {
        defaultValues: {
          statuses: [AssetStatus.Active],
          categories: [AssetCategory.Fleet],
          subcategories: [AssetSubcategoryType.Bus],
          date: DateTime.local()
            .startOf('day')
            .toISODate(),
        },
        filterFields: filterFieldDefs,
      },
    };
  };

  return <ListPage className="list-assets-component" data={props.assets} def={getPageDef()} />;
};

export default withQueryParams(ListUnallocatedAsset);
