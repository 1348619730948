import { types, flow } from 'mobx-state-tree';
import { getAjax } from 'src/domain/services';
import buildListPageApiSearchModel from 'src/domain/modelBuilders/buildListPageApiSearchModel';

type EquipmentTypeItem = People.Domain.Queries.EquipmentType.EquipmentTypeItem;

const ListEquipmentTypesModel = buildListPageApiSearchModel('ListEquipmentTypesModel', d =>
  d.ajax.people.listEquipmentTypes(d.query)
);

const EquipmentTypesOptionsModel = types
  .model('EquipmentTypesModel', {
    equipmentTypes: types.array(types.frozen<EquipmentTypeItem>()),
  })
  .actions(self => {
    const ajax = getAjax(self);

    const loadAllEquipmentTypes = flow(function*() {
      self.equipmentTypes = yield ajax.people.getEquipmentTypes();
    });

    return {
      loadAllEquipmentTypes,
    };
  });

export const EquipmentTypesModel = types.compose(
  ListEquipmentTypesModel,
  EquipmentTypesOptionsModel
);
