import { Component } from 'react';
import Omit from 'src/infrastructure/omit';
import { RouteComponentProps } from 'react-router-dom';
import {
  PagePrimarySize,
  PaneType,
  FieldType,
  ActionType,
  ShellModalSize,
} from 'src/views/definitionBuilders/types';
import {
  getDays,
  getDaysToExclude,
  daySelected,
} from 'src/views/routes/operations/urban/urbanHelpers';
import CrudPage, { CrudPageMode, ICrudPageDef } from 'src/views/components/Page/pages/CrudPage';
import { TrashIcon } from 'src/images/icons';
import { getSubmitCloseModalActionGroupDef } from 'src/views/definitionBuilders/common';
import { IListPageLoadDataRequest, ListPageLoadCause } from 'src/domain/baseTypes';
import { Weekday } from 'src/domain';
import { ChangeState } from 'src/api/enums';
import { DurationFormat } from 'src/views/components/DurationFormat';

type CreateRosterTemplateCommand = Workshop.Domain.Commands.RosterTemplate.CreateRosterTemplate.CreateRosterTemplateCommand;
type UpdateRosterTemplateCommand = Workshop.Domain.Commands.RosterTemplate.UpdateRosterTemplate.UpdateRosterTemplateCommand;
type RosterTemplateItem = Workshop.Domain.Queries.RosterTemplate.GetRosterTemplate.RosterTemplateItem;
type ListShiftTemplatesQuery = Workshop.Domain.Queries.ShiftTemplate.ListShiftTemplates.ListShiftTemplatesQuery;
type ShiftTemplateListItem = Workshop.Domain.Queries.ShiftTemplate.ListShiftTemplates.ShiftTemplateListItem;
type StaffMemberDto = Common.Dtos.StaffMemberDto;
type WorkshopDepot = Common.Queries.Workshop.GetWorkshopDepots.WorkshopDepotDto;

export interface IMaintainRosterTemplate {
  mode: CrudPageMode;
  canManageRosterTemplates: boolean;

  loadRosterTemplate: (id: string) => Promise<void>;
  rosterTemplate: RosterTemplateItem | undefined;

  checkForUniqueRosterTemplateName: (
    rosterName: string
  ) => Promise<Common.Dtos.UniqueNameCheckResultDto>;
  createRosterTemplate: (command: CreateRosterTemplateCommand) => Promise<void>;
  updateRosterTemplate: (command: UpdateRosterTemplateCommand) => Promise<void>;
  deleteRosterTemplate: (rosterTemplateId: string) => Promise<void>;

  listShiftTemplates: (request: IListPageLoadDataRequest<ListShiftTemplatesQuery>) => Promise<void>;
  shiftTemplates: ShiftTemplateListItem[];

  mechanics: StaffMemberDto[];
  loadMechanics: () => Promise<void>;

  workshopDepots: Array<WorkshopDepot>;
  defaultWorkshopDepot: WorkshopDepot | undefined;
}

type IMaintainRosterTemplateForm = Omit<RosterTemplateItem, 'shifts'> & {
  shifts: IMaintainRosterTemplateFormShiftItem[];
};

interface IMaintainRosterTemplateFormShiftItem {
  changeState: ChangeState;
  id: number | undefined;
  shiftTemplate: {
    shiftTemplateId: string;
    shiftName: string;
    monday: boolean;
    tuesday: boolean;
    wednesday: boolean;
    thursday: boolean;
    friday: boolean;
    saturday: boolean;
    sunday: boolean;
  };
  days: Weekday[];
  staffMembers: string[];
}

interface IUpdateRosterTemplateRouteParams {
  id: string;
}

type InternalProps = IMaintainRosterTemplate &
  RouteComponentProps<IUpdateRosterTemplateRouteParams>;

class MaintainRosterTemplate extends Component<InternalProps> {
  componentDidMount() {
    this.props.listShiftTemplates({ loadCause: ListPageLoadCause.mount });
    this.props.loadMechanics();
  }

  private get isUpdateMode() {
    return this.props.mode === 'update';
  }

  private get RosterTemplateId() {
    return this.props.match.params.id;
  }

  private handlePreSubmitForCreate = (
    rosterTemplateForm: IMaintainRosterTemplateForm
  ): CreateRosterTemplateCommand => {
    const command: CreateRosterTemplateCommand = {
      rosterTemplateName: rosterTemplateForm.rosterTemplateName,
      shifts: rosterTemplateForm.shifts
        .filter(x => x.changeState !== ChangeState.Deleted)
        .map(x => {
          return {
            shiftTemplateId: x.shiftTemplate.shiftTemplateId,
            monday: daySelected(x.days, Weekday.mon),
            tuesday: daySelected(x.days, Weekday.tue),
            wednesday: daySelected(x.days, Weekday.wed),
            thursday: daySelected(x.days, Weekday.thu),
            friday: daySelected(x.days, Weekday.fri),
            saturday: daySelected(x.days, Weekday.sat),
            sunday: daySelected(x.days, Weekday.sun),
            staffMemberIds: x.staffMembers,
          };
        }),
      depotId: rosterTemplateForm.depotId,
    };
    return command;
  };

  private handlePreSubmitForUpdate = (
    rosterTemplateForm: IMaintainRosterTemplateForm
  ): UpdateRosterTemplateCommand => {
    const command: UpdateRosterTemplateCommand = {
      rosterTemplateId: rosterTemplateForm.rosterTemplateId,
      rosterTemplateName: rosterTemplateForm.rosterTemplateName,
      shifts: rosterTemplateForm.shifts.map(x => {
        return {
          id: x.id,
          changeState:
            x.changeState === ChangeState.Added || x.changeState === ChangeState.Deleted
              ? x.changeState
              : ChangeState.Modified,
          shiftTemplateId: x.shiftTemplate.shiftTemplateId,
          monday: daySelected(x.days, Weekday.mon),
          tuesday: daySelected(x.days, Weekday.tue),
          wednesday: daySelected(x.days, Weekday.wed),
          thursday: daySelected(x.days, Weekday.thu),
          friday: daySelected(x.days, Weekday.fri),
          saturday: daySelected(x.days, Weekday.sat),
          sunday: daySelected(x.days, Weekday.sun),
          staffMemberIds: x.staffMembers,
        };
      }),
      depotId: rosterTemplateForm.depotId,
    };
    return command;
  };

  private rosterTemplateData(roster: RosterTemplateItem | undefined): IMaintainRosterTemplateForm {
    const { shiftTemplates } = this.props;
    if (!roster || !shiftTemplates) {
      return {} as IMaintainRosterTemplateForm;
    }

    return {
      ...roster,
      shifts: roster.shifts.map(x => {
        const shift: IMaintainRosterTemplateFormShiftItem = {
          changeState: ChangeState.Unchanged,
          id: x.id,
          shiftTemplate: shiftTemplates.find(y => y.shiftTemplateId === x.shiftTemplateId)!,
          days: getDays(x),
          staffMembers: x.staffMembers.map(y => y.staffMemberId),
        };
        return shift;
      }),
    };
  }

  private readonly getPageDef = (readonly: boolean, updating: boolean): ICrudPageDef => {
    const {
      checkForUniqueRosterTemplateName,
      rosterTemplate,
      canManageRosterTemplates,
      mechanics,
      workshopDepots,
    } = this.props;

    return {
      primarySize: PagePrimarySize.full,
      primarySection: {
        title: this.isUpdateMode ? 'Manage Roster Template' : 'Create a Roster Template',
        primaryActions: [
          {
            actions: [
              {
                actionType: ActionType.actionCollection,
                hidden: !readonly || !canManageRosterTemplates,
                actionGroups: [
                  {
                    actions: [
                      {
                        actionType: ActionType.modalActionButton,
                        label: 'Delete Roster Template',
                        icon: <TrashIcon />,
                        hidden: !this.props.rosterTemplate,
                        modalSize: ShellModalSize.oneQuarter,
                        modalDef: modalDefApi => ({
                          title: 'Delete Roster Template',
                          asForm: true,
                          panels: [
                            {
                              panes: [
                                {
                                  paneType: PaneType.customPane,
                                  render: () => (
                                    <div>
                                      <p>Are you sure you want to delete this Roster Template?</p>
                                    </div>
                                  ),
                                },
                              ],
                            },
                          ],
                          secondaryActions: [getSubmitCloseModalActionGroupDef('Delete')],
                          onFormSubmit: () =>
                            this.props.deleteRosterTemplate(this.RosterTemplateId),
                        }),
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
        panels: [
          {
            panes: [
              {
                paneType: PaneType.formFieldsPane,
                columnCount: 2,
                fields: [
                  {
                    fieldType: FieldType.textField,
                    label: 'Roster Template Name',
                    dataAddr: 'rosterTemplateName',
                    maxLength: 50,
                    mandatory: true,
                    validateAsync: async d => {
                      if (
                        !d.fieldValue ||
                        (this.isUpdateMode &&
                          rosterTemplate &&
                          rosterTemplate.rosterTemplateName.toUpperCase() ===
                            d.fieldValue.toUpperCase())
                      ) {
                        return undefined;
                      }
                      const result = await checkForUniqueRosterTemplateName(d.fieldValue);
                      return result.nameExists ? `Roster name is already in use` : undefined;
                    },
                  },
                  {
                    fieldType: FieldType.selectField,
                    dataAddr: 'depotId',
                    label: 'Depot',
                    useValueOnly: true,
                    valueKey: 'id',
                    descriptionKey: 'description',
                    mandatory: true,
                    optionItems: workshopDepots,
                    readonly: workshopDepots.length < 2,
                  },
                ],
              },
            ],
          },
          {
            title: 'Shifts',
            dataAddr: 'shifts',
            panes: [
              {
                paneType: PaneType.tablePane,
                mandatory: true,
                dataRequiredForRows: 'sectionValue',
                fields: [
                  {
                    fieldType: FieldType.selectField,
                    label: 'Shift',
                    dataAddr: 'shiftTemplate',
                    valueKey: 'shiftTemplateId',
                    descriptionKey: 'shiftName',
                    mandatory: true,
                    columnWidth: '200px',
                    optionItems: this.props.shiftTemplates,
                    linkTo: d =>
                      `/workshop/shift-templates/${d.parentValue.shiftTemplate.shiftTemplateId}`,
                    onChange: d => {
                      if (
                        d.fieldData &&
                        d.fieldData.parentValue &&
                        d.fieldData.parentValue.shiftTemplate
                      ) {
                        const shiftTemplate = d.fieldData.parentValue.shiftTemplate;
                        const defaultDays = getDays(shiftTemplate);
                        const daysAddr = [
                          ...d.fieldDataAddr.slice(0, d.fieldDataAddr.length - 1),
                          'days',
                        ];
                        d.setFormValue(daysAddr, defaultDays);
                      }
                    },
                  },
                  {
                    fieldType: FieldType.daysOfWeekField,
                    dataAddr: 'days',
                    label: 'Days of Week',
                    mandatory: true,
                    columnWidth: '100px',
                    useValueOnly: true,
                    readonly: data => !data.parentValue || !data.parentValue.shiftTemplate,
                    valuesToExclude: data => {
                      if (data.parentValue && data.parentValue.shiftTemplate) {
                        return getDaysToExclude(data.parentValue.shiftTemplate);
                      }
                      return [];
                    },
                    formatReadonly: data => {
                      if (data.parentValue && data.parentValue.days) {
                        let displayDays = [];
                        const days = data.parentValue.days;
                        if (days.indexOf(Weekday.mon) !== -1) {
                          displayDays.push('Mon');
                        }
                        if (days.indexOf(Weekday.tue) !== -1) {
                          displayDays.push('Tue');
                        }
                        if (days.indexOf(Weekday.wed) !== -1) {
                          displayDays.push('Wed');
                        }
                        if (days.indexOf(Weekday.thu) !== -1) {
                          displayDays.push('Thu');
                        }
                        if (days.indexOf(Weekday.fri) !== -1) {
                          displayDays.push('Fri');
                        }
                        if (days.indexOf(Weekday.sat) !== -1) {
                          displayDays.push('Sat');
                        }
                        if (days.indexOf(Weekday.sun) !== -1) {
                          displayDays.push('Sun');
                        }
                        return displayDays.join(', ');
                      }
                      return '';
                    },
                  },
                  {
                    fieldType: FieldType.readonlyField,
                    dataAddr: ['shiftTemplate', 'shiftCommence'],
                    label: 'Shift Commence',
                    columnWidth: '50px',
                    formatReadonly: d => <DurationFormat value={d.fieldValue} />,
                  },
                  {
                    fieldType: FieldType.readonlyField,
                    dataAddr: ['shiftTemplate', 'shiftEnd'],
                    label: 'Shift End',
                    columnWidth: '50px',
                    formatReadonly: d => <DurationFormat value={d.fieldValue} />,
                  },
                  {
                    fieldType: FieldType.selectMultiField,
                    dataAddr: ['staffMembers'],
                    label: 'Staff Members',
                    valueKey: 'id',
                    descriptionKey: 'name',
                    optionItems: mechanics.filter(x => x.active),
                    useValueOnly: true,
                    readonly: data => !data.parentValue || !data.parentValue.shiftTemplate,
                  },
                  {
                    fieldType: FieldType.actionListField,
                    dataAddr: '',
                    columnWidth: '1px',
                    actionGroups: [
                      {
                        actions: [
                          {
                            hidden: d => this.isUpdateMode && !updating,
                            actionType: ActionType.removeArrayItemActionButton,
                            label: 'Remove Line',
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
              {
                paneType: PaneType.actionListPane,
                hidden: this.isUpdateMode && !updating,
                actionGroups: [
                  {
                    actions: [
                      {
                        actionType: ActionType.addArrayItemActionButton,
                        label: 'Add Shift',
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
        onFormPreSubmit: this.isUpdateMode
          ? this.handlePreSubmitForUpdate
          : this.handlePreSubmitForCreate,
        onFormSubmit: this.isUpdateMode
          ? this.props.updateRosterTemplate
          : this.props.createRosterTemplate,
      },
    };
  };

  render() {
    const {
      mode,
      loadRosterTemplate,
      rosterTemplate,
      canManageRosterTemplates,
      defaultWorkshopDepot,
    } = this.props;
    return (
      <CrudPage
        def={api => this.getPageDef(api.readonly, api.updating)}
        mode={mode}
        isEditingForbidden={!canManageRosterTemplates}
        onLoadData={() => loadRosterTemplate(this.RosterTemplateId)}
        data={this.rosterTemplateData(rosterTemplate)}
        createDefaultData={{ depotId: defaultWorkshopDepot?.id }}
      />
    );
  }
}

export default MaintainRosterTemplate;
