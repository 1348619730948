import { buildListPageApiSearchModelTypedQuery } from 'src/domain/modelBuilders/buildListPageApiSearchModel';

type ListDataFuelUploadQuery = Workshop.Domain.Queries.DataFuel.ListDataFuelUpload.ListDataFuelUploadQuery;

export const ListDataFuelUploadModel = buildListPageApiSearchModelTypedQuery<
  ListDataFuelUploadQuery
>()('ListDataFuelModel', d => d.ajax.workshop.dataFuel.listDataFuelUploads(d.query)).views(
  self => ({
    get firstFiveUploads() {
      if (self.items && self.items.length > 5) {
        return self.items.slice(0, 5);
      } else {
        return self.items;
      }
    },
  })
);
