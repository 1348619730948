import { Component } from 'react';
import { DateTime } from 'luxon';
import { ButtonGroup } from 'reactstrap';
import { ChevronLeftIcon, ChevronRightIcon } from 'src/images/icons';
import { SingleClickAwareButton } from '../SingleClickAwareButton/SingleClickAwareButton';

export interface IDayNavigationProps {
  onDateChanged: (date: DateTime) => void;
  maxDate?: DateTime;
  minDate?: DateTime;
  date: DateTime;
  size?: 'lg' | 'sm';
  todayText?: string;
  disabled?: boolean;
}

class DayNavigation extends Component<IDayNavigationProps> {
  previousDate() {
    const previousDate = this.props.date.minus({ days: 1 });
    this.props.onDateChanged(previousDate);
  }

  currentDate() {
    this.props.onDateChanged(DateTime.local());
  }

  nextDate() {
    const nextDate = this.props.date.plus({ days: 1 });
    this.props.onDateChanged(nextDate);
  }

  render() {
    const { size, todayText, minDate, maxDate, date } = this.props;

    const minCheck = minDate && date.minus({ days: 1 }) < minDate;
    const maxCheck = maxDate && date.plus({ days: 1 }) > maxDate;

    return (
      <div className="day-navigation-component">
        <ButtonGroup>
          <SingleClickAwareButton
            useReactStrapButton
            disabled={minCheck}
            onClick={() => this.previousDate()}
            size={size || 'sm'}>
            <ChevronLeftIcon size={size || 'sm'} />
          </SingleClickAwareButton>
          <SingleClickAwareButton
            useReactStrapButton
            className="todays-job"
            onClick={() => this.currentDate()}
            size={size || 'sm'}
            disabled={this.props.disabled}>
            {todayText || 'Today'}
          </SingleClickAwareButton>
          <SingleClickAwareButton
            useReactStrapButton
            disabled={maxCheck}
            onClick={() => this.nextDate()}
            size={size || 'sm'}>
            <ChevronRightIcon size={size || 'sm'} />
          </SingleClickAwareButton>
        </ButtonGroup>
      </div>
    );
  }
}

export default DayNavigation;
