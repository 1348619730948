import { IRootStoreModel } from 'src/domain/entities/RootStoreModel';
import { UiRoute } from 'src/domain/services/buildUiRoutes';
import { UiRouteName } from 'src/domain/baseTypes';

export default function buildReportableEventRoutes(store: IRootStoreModel) {
  const reportableEventCreate = new UiRoute(
    UiRouteName.complianceReportableEventCreate,
    '/compliance/reportable-events/create',
    () => 'Create Reportable Event',
    false
  );

  const reportableEventManage = new UiRoute(
    UiRouteName.complianceReportableEventManage,
    '/compliance/reportable-events/:id',
    () => 'Manage Reportable Event',
    false
  );

  const reportableEventList = new UiRoute(
    UiRouteName.complianceReportableEventList,
    '/compliance/reportable-events',
    () => 'Reportable Events',
    false,
    true,
    [reportableEventCreate, reportableEventManage]
  );

  return {
    [UiRouteName.complianceReportableEventList]: reportableEventList,
    [UiRouteName.complianceReportableEventCreate]: reportableEventCreate,
    [UiRouteName.complianceReportableEventManage]: reportableEventManage,
  };
}
