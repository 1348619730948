import styles from './WorkshopShift.module.scss';
import { Component } from 'react';
import { IWorkshopShiftItem } from 'src/domain/entities/operations/job/ListJobsForAllocationsModel';

interface IWorkshopShiftProps {
  shift: IWorkshopShiftItem;
}

export default class WorkshopShift extends Component<IWorkshopShiftProps> {
  render() {
    const { shift } = this.props;
    const description = `WS • ${shift.shiftName}`;
    const allocations = `${shift.staffMemberName}`;
    return (
      <div className={styles.allocationsWorkshopShiftComponent}>
        <div
          className={styles.shiftTime}
          title={[description, allocations].filter(i => !!i).join(', ')}>
          <div className={styles.description}>
            <span className={styles.hideOverflow}>{description}</span>
          </div>
          <div className={styles.allocations}>
            <span className={styles.hideOverflow}>{allocations}</span>
          </div>
        </div>
      </div>
    );
  }
}
