import { types, getRoot, flow } from 'mobx-state-tree';
import { IRootStoreModel } from 'src/domain/entities/RootStoreModel';
import { getAjax } from 'src/domain/services';

type CreateRosterTemplateCommand = Workshop.Domain.Commands.RosterTemplate.CreateRosterTemplate.CreateRosterTemplateCommand;
type UpdateRosterTemplateCommand = Workshop.Domain.Commands.RosterTemplate.UpdateRosterTemplate.UpdateRosterTemplateCommand;
type RosterTemplateItem = Workshop.Domain.Queries.RosterTemplate.GetRosterTemplate.RosterTemplateItem;

export const RosterTemplateModel = types
  .model('RosterTemplateModel', {
    rosterTemplate: types.maybe(types.frozen<RosterTemplateItem>()),
  })
  .actions(self => {
    const ajax = getAjax(self);
    const root = getRoot(self) as IRootStoreModel;

    const createRosterTemplate = flow(function*(command: CreateRosterTemplateCommand) {
      const id = yield ajax.workshop.rosterTemplate.createRosterTemplate(command);
      self.rosterTemplate = undefined;
      root.history.push(`/workshop/roster-templates/${id}`);
    });

    const checkForUniqueRosterTemplateName = flow(function*(rosterName: string) {
      return yield ajax.workshop.rosterTemplate.checkForUniqueRosterTemplateName(rosterName);
    });

    const loadRosterTemplate = flow(function*(rosterTemplateId: string) {
      self.rosterTemplate = yield ajax.workshop.rosterTemplate.getRosterTemplate(rosterTemplateId);
    });

    const updateRosterTemplate = flow(function*(command: UpdateRosterTemplateCommand) {
      yield ajax.workshop.rosterTemplate.updateRosterTemplate(command);
      yield loadRosterTemplate(command.rosterTemplateId);
    });

    const deleteRosterTemplate = flow(function*(rosterTemplateId: string) {
      yield ajax.workshop.rosterTemplate.deleteRosterTemplate(rosterTemplateId);
      self.rosterTemplate = undefined;
      root.history.replace('/workshop/roster-templates');
    });

    return {
      createRosterTemplate,
      checkForUniqueRosterTemplateName,
      loadRosterTemplate,
      updateRosterTemplate,
      deleteRosterTemplate,
    };
  });
