import { DateTime } from 'luxon';
import { WORKING_WEEK_START_DAY, TIMESHEET_PERIOD, TIMESHEET_START_DATE } from 'src/appSettings';
import { FatigueBreakItem } from 'src/views/routes/operations/job/maintainJob/getGenerateFatigueBreaksModalDef';

const weekStartDay = WORKING_WEEK_START_DAY;
const payPeriod = TIMESHEET_PERIOD;

export enum PayPeriod {
  Weekly = 1,
  Fortnightly = 2,
}

export const formatDateTimeMed = (date: string) =>
  DateTime.fromISO(date).toLocaleString(DateTime.DATETIME_MED);

export const formatDateMed = (date: string) =>
  DateTime.fromISO(date).toLocaleString(DateTime.DATE_MED);

export const formatDateShort = (date: string) => DateTime.fromISO(date).toFormat('dd/MM/yyyy');

export const formatDateTimeShort = (date: DateTime) => date.toFormat('dd/MM/yyyy');

export const formatDayMonth = (date: string) => DateTime.fromISO(date).toFormat('d MMM');

export const formatDateISO = (date: string) => DateTime.fromISO(date).toFormat('yyyy-MM-dd');

export const getTodayISODate = () => DateTime.local().toISODate();

export const getTodaySortableDate = () => DateTime.local().toFormat('yyyyMMddHHmm');

export const formatDayOfWeekLong = (weekDay: number) => {
  const dayName = DateTime.fromObject({
    weekday: weekDay,
  }).toLocaleString({ weekday: 'long' });
  return dayName;
};

export const formatDayOfWeekMed = (weekDay: number) => {
  const dayName = DateTime.fromObject({
    weekday: weekDay,
  }).toLocaleString({ weekday: 'short' });
  return dayName;
};

export const formatDayOfWeekShort = (weekDay: number) => {
  const dayName = DateTime.fromObject({
    weekday: weekDay,
  }).toLocaleString({ weekday: 'narrow' });
  return dayName;
};

export const dateRangeOverlaps = (
  startA: DateTime,
  endA: DateTime,
  startB: DateTime,
  endB: DateTime
) => (startA <= endB && endA >= startB ? true : false);

export const getWeekStart = (date: DateTime) => {
  const startDate = DateTime.fromFormat(TIMESHEET_START_DATE, 'dd/MM/yyyy');

  const diff =
    weekStartDay > date.weekday ? weekStartDay - (date.weekday + 7) : weekStartDay - date.weekday;
  const weekStart = date.plus({ days: diff });
  if (payPeriod === PayPeriod.Weekly) {
    return weekStart;
  }

  const daysBetween = Math.floor(startDate.until(date).length('days'));
  const totalDays = isNaN(daysBetween) ? 0 : daysBetween;
  const fortnightNumber = Math.floor(totalDays / 14);

  return startDate.plus({ days: fortnightNumber * 14 });
};

export const getWeekEnd = (date: DateTime) => {
  const weekStart = getWeekStart(date);
  const days = payPeriod === PayPeriod.Weekly ? 6 : 13;
  return weekStart.plus({ days: days });
};

export const getPaidWeekEnd = () => {
  const days = TIMESHEET_PERIOD === PayPeriod.Weekly ? 7 : 14;
  return DateTime.fromObject({
    weekday: weekStartDay,
  }).plus({ days: days }).weekday;
};

export const getPayPeriodInWeeks = () => {
  return payPeriod === PayPeriod.Weekly ? 1 : 2;
};

export const getPayPeriodInDays = () => {
  return payPeriod === PayPeriod.Weekly ? 7 : 14;
};

export const getOverlappingDates = (dates: FatigueBreakItem[]) => {
  let i = 0,
    j = 0;
  let overlappingDates: FatigueBreakItem[] = [];

  if (dates.length === 0 || dates === null) return overlappingDates;

  for (i = 0; i < dates.length - 1; i += 1) {
    for (j = i + 1; j < dates.length; j += 1) {
      if (
        dateRangeOverlaps(
          dates[i].breakStart,
          dates[i].breakEnd,
          dates[j].breakStart,
          dates[j].breakEnd
        )
      ) {
        overlappingDates.push({
          breakStart: dates[j].breakStart,
          breakEnd: dates[j].breakEnd,
        } as FatigueBreakItem);
      }
    }
  }

  return overlappingDates;
};
