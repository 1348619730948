import { types, flow } from 'mobx-state-tree';
import { getAjax } from 'src/domain/services/storeEnvironment';
import { WorkshopJobItemModel } from './WorkshopJobItemModel';
import { TasksModel } from '../mechanic/TasksModel';
import { JobsModel } from '../mechanic/JobsModel';
import { DailyRunSheetModel } from './DailyRunSheetModel';
import { PartsModel } from './PartsModel';
import { WorkshopShiftDetailsModel } from './WorkshopShiftDetailsModel';
import { KioskNoteModel } from './KioskNoteModel';
import { TechSpecsModel } from './TechSpecsModel';
import { DailyNoteModel } from './DailyNoteModel';
import { ShiftModel } from './ShiftModel';

type WorkshopDepotDto = Common.Queries.Workshop.GetWorkshopDepots.WorkshopDepotDto;

const DEVICE_DEPOT_CACHE_KEY = 'currentDepot';
const DEFAULT_INITIAL_DEPOT_ID = 1;

export const WorkshopRootModel = types
  .model('KioskWorkshopRootModel', {
    job: types.optional(WorkshopJobItemModel, {}),
    task: types.optional(TasksModel, {}),
    mechanicJob: types.optional(JobsModel, {}),
    dailyRunSheet: types.optional(DailyRunSheetModel, {}),
    parts: types.optional(PartsModel, {}),
    shiftDetails: types.optional(WorkshopShiftDetailsModel, {}),
    shift: types.optional(ShiftModel, {}),
    kioskNote: types.optional(KioskNoteModel, {}),
    dailyNote: types.optional(DailyNoteModel, {}),
    techSpecs: types.optional(TechSpecsModel, {}),
    depots: types.array(types.frozen<WorkshopDepotDto>()),
    defaultKioskDepotId: types.optional(types.integer, () => getFromCache()),
  })
  .views(self => ({
    get hasMultipleWorkshopDepots() {
      return self.depots && self.depots.length > 1;
    },
    get defaultKioskDepot() {
      return self.depots && self.depots.filter(o => o.id === self.defaultKioskDepotId)[0];
    },
  }))
  .actions(self => {
    const ajax = getAjax(self);

    const saveDeviceDepotToLocalStorage = (depotId: number) => {
      self.defaultKioskDepotId = depotId;

      setCache(depotId);
    };

    const loadKioskDepotFromLocalStorage = () => {
      const cachedDepotId = getFromCache();

      self.defaultKioskDepotId = cachedDepotId;
    };

    const loadWorkshopDepots = flow(function*() {
      loadKioskDepotFromLocalStorage();

      if (!self.depots?.length) {
        self.depots = yield ajax.kiosk.workshop.depots.getDepots();
      }
    });

    return {
      loadWorkshopDepots,
      saveDeviceDepotToLocalStorage,
      loadKioskDepotFromLocalStorage,
    };
  });

function setCache(depotId?: number) {
  if (depotId) {
    localStorage.setItem(DEVICE_DEPOT_CACHE_KEY, depotId.toString());
  } else {
    localStorage.removeItem(DEVICE_DEPOT_CACHE_KEY);
  }
}

function getFromCache(): number {
  let depotId = localStorage.getItem(DEVICE_DEPOT_CACHE_KEY);

  if (!depotId) {
    setCache(DEFAULT_INITIAL_DEPOT_ID);
    depotId = DEFAULT_INITIAL_DEPOT_ID.toString();
  }

  return Number.parseInt(depotId, 10);
}
