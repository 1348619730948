import { IRootStoreModel } from 'src/domain/entities/RootStoreModel';
import { UiRoute } from 'src/domain/services/buildUiRoutes';
import { UiRouteName } from 'src/domain/baseTypes';

export default function buildUiRoutes(store: IRootStoreModel) {
  const charterContractCreate = new UiRoute(
    UiRouteName.operationsCharterContractCreate,
    '/operations/charter-contracts/create',
    () => 'Create Charter Contract',
    false
  );

  const charterContractManage = new UiRoute(
    UiRouteName.operationsCharterContractManage,
    '/operations/charter-contracts/:id',
    () => 'Manage Charter Contract',
    false
  );

  const charterContractList = new UiRoute(
    UiRouteName.operationsCharterContractsList,
    '/operations/charter-contracts',
    () => 'Charter Contracts',
    false,
    true,
    [charterContractCreate, charterContractManage]
  );

  return {
    [UiRouteName.operationsCharterContractsList]: charterContractList,
    [UiRouteName.operationsCharterContractManage]: charterContractManage,
    [UiRouteName.operationsCharterContractCreate]: charterContractCreate,
  };
}
