import { RouteComponentProps } from 'react-router-dom';
import {
  PagePrimarySize,
  PaneType,
  FieldType,
  ISectionDef,
} from 'src/views/definitionBuilders/types';
import CrudPage, { CrudPageMode, ICrudPageDef } from 'src/views/components/Page/pages/CrudPage';
import { observer } from 'mobx-react';
import { useRootStore } from 'src/domain/entities/RootStoreModel';

type EquipmentType = People.Domain.Queries.EquipmentType.EquipmentTypeItem;
type CreateEquipmentTypeCommand = People.Domain.Commands.EquipmentType.CreateEquipmentType.CreateEquipmentTypeCommand;
type UpdateEquipmentTypeCommand = People.Domain.Commands.EquipmentType.UpdateEquipmentType.UpdateEquipmentTypeCommand;

export interface IMaintainEquipmentTypeProps {
  mode: CrudPageMode;
}
interface IUpdateEquipmentTypeRouteParams {
  id: string;
}

type InternalProps = IMaintainEquipmentTypeProps &
  RouteComponentProps<IUpdateEquipmentTypeRouteParams>;

export const MaintainEquipmentType: React.FC<InternalProps> = observer((props: InternalProps) => {
  const rootStore = useRootStore();
  const peopleModel = rootStore.people;
  const equipmentTypeModel = peopleModel.equipmentType;
  const canManageEquipmentTypes = rootStore.account.isPeopleDepartmentMember;
  const isUpdateMode = props.mode === 'update';
  const equipmentTypeId = Number.parseInt(props.match.params.id);

  const handlePreSubmitForCreate = (equipmentType: EquipmentType): CreateEquipmentTypeCommand => {
    return {
      description: equipmentType.description,
    };
  };

  const handleSubmitForCreate = async (command: CreateEquipmentTypeCommand): Promise<void> => {
    await equipmentTypeModel.createEquipmentType(command);
  };

  const handlePreSubmitForUpdate = (equipmentType: EquipmentType): UpdateEquipmentTypeCommand => {
    return {
      id: equipmentType.id,
      description: equipmentType.description,
    };
  };

  const getEquipmentTypeForm = (readonly: boolean): ISectionDef => {
    return {
      dataAddr: 'equipmentType',
      title: isUpdateMode ? 'Manage Equipment Type' : 'Create an Equipment Type',
      panels: [
        {
          panes: [
            {
              paneType: PaneType.formFieldsPane,
              fields: [
                {
                  fieldType: FieldType.textField,
                  label: 'Description',
                  dataAddr: 'description',
                  maxLength: 200,
                  mandatory: true,
                  validateAsync: async d => {
                    if (
                      !d.fieldValue ||
                      (isUpdateMode &&
                        equipmentTypeModel.equipmentType &&
                        equipmentTypeModel.equipmentType.description.toUpperCase() ===
                          d.fieldValue.toUpperCase())
                    ) {
                      return undefined;
                    }
                    const result = await equipmentTypeModel.checkForUniqueDescription(d.fieldValue);
                    return result.nameExists ? `Description is already in use` : undefined;
                  },
                },
              ],
            },
          ],
        },
      ],
      primaryActions: [],
      onFormPreSubmit: isUpdateMode ? handlePreSubmitForUpdate : handlePreSubmitForCreate,
      onFormSubmit: isUpdateMode ? equipmentTypeModel.updateEquipmentType : handleSubmitForCreate,
    };
  };

  const getPageDef = (canManageEquipmentTypes: boolean, readonly: boolean): ICrudPageDef => {
    return {
      primarySize: PagePrimarySize.half,
      primarySection: getEquipmentTypeForm(readonly),
    };
  };
  const equipmentType = equipmentTypeModel.equipmentType;

  return (
    <CrudPage
      def={api => getPageDef(canManageEquipmentTypes, api.readonly)}
      mode={props.mode}
      isEditingForbidden={!canManageEquipmentTypes}
      onLoadData={() =>
        Promise.all([equipmentTypeModel.loadEquipmentType(equipmentTypeId)]).then(() =>
          Promise.resolve()
        )
      }
      data={{
        equipmentType,
      }}
      createDefaultData={{}}
    />
  );
});

export default MaintainEquipmentType;
