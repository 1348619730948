import './AllocationJobPopup.scss';
import cn from 'classnames';
import { DateTime } from 'luxon';
import { JobType, JobStatus } from 'src/api/enums';
import { EditIcon, TrashIcon } from 'src/images/icons';
import {
  IJobItem,
  AllBoundedItemTypes,
} from 'src/domain/entities/operations/job/ListJobsForAllocationsModel';
import { SearchTechSpecValues } from 'src/domain/entities/workshop/asset/AssetsModel';
import {
  ShellModalSize,
  ActionType,
  IActionData,
  PaneType,
  IModalActionButtonDef,
  FieldType,
} from 'src/views/definitionBuilders/types';
import ModalActionButton from 'src/views/components/Page/actions/ModalActionButton';
import ActionButton from 'src/views/components/Page/actions/ActionButton';
import {
  isJobTypeWithoutAsset,
  canJobTypeHaveSubcontractor,
  isJobTypeLinkedToQuote,
  isJobTypeCharter,
} from 'src/views/routes/operations/shared/jobTypeHelpers';
import PopupField from '../../PopupField';
import ContinuationField from '../../ContinuationField';
import { getSwapVehicleModalActionButtonDef } from '../../../maintainJob/panelDefs/getSwapVehicleModalDef';
import { getSubmitCloseModalActionGroupDef } from 'src/views/definitionBuilders/common';
import { IViewGroupFilterValues } from '../GanttCalendar';
import { AssignStaffMemberField, IAssignStaffFieldProps } from './AssignStaffMemberField';
import { AssignVehicleField, IAssignVehicleFieldProps } from './AssignVehicleField';
import { ConflictsPopupField } from './ConflictsPopupField';
import { useRootStore } from 'src/domain/entities/RootStoreModel';

type AllocateStaffMemberCommand = Operations.Domain.Commands.Job.AllocateStaffMember.AllocateStaffMemberCommand;
type AllocateAssetCommand = Operations.Domain.Commands.Job.AllocateAsset.AllocateAssetCommand;
type PaidHoursInPayPeriod = Operations.Domain.Queries.GetPaidHoursInPayPeriodForDay.PaidHoursInPayPeriod;
type SubcontractorItem = Common.Dtos.SubcontractorItem;
type AllocateSubcontractorCommand = Operations.Domain.Commands.Job.AllocateSubcontractor.AllocateSubcontractorCommand;
type AssetSubcategory = Workshop.Domain.AggregatesModel.AssetAggregate.AssetSubcategory;
type AssetHousingLocation = Workshop.Domain.AggregatesModel.AssetAggregate.AssetHousingLocation;
type TechSpecItem = Common.Dtos.TechSpecItem;
type SkillSpecItem = Common.Dtos.SkillSpecItem;
type ExclusionItem = Operations.Domain.Queries.ListExclusions.ExclusionItem;
type TechSpecDropdownOption = Workshop.Domain.Queries.TechSpecs.TechSpecDropdownOptionsItem.TechSpecDropdownOption;
type SwapVehicleCommand = Operations.Domain.Commands.Job.SwapVehicleCommand;
type JobProgressItem = Operations.Domain.Queries.GetJobProgress.JobProgressItem;
type StaffDepotDto = Common.Dtos.StaffDepotDto;
type EmploymentStatusDto = Common.Dtos.EmploymentStatusDto;
type LicenceType = Common.AggregatesModel.People.StaffMemberAggregate.LicenceType;
type VehicleType = Common.Dtos.VehicleTypeItem;
type ListRoleItem = Common.Dtos.ListRoleItem;
type WhichDriver = 'firstDriver' | 'secondDriver';
type FatigueWarning = Operations.Domain.Services.Fatigue.FatigueWarning;

function suppressPropagation(e: { stopPropagation: () => void }) {
  // Stop this event bubbling up the element stack, so that events in the modal do
  // not trigger actions in the underlying GanttCalendar
  e.stopPropagation();
}

interface ISubmissionMeta {
  keepAcknowledgement?: boolean;
}

interface ISubmissionData {
  keepAcknowledgement: boolean;
  assetId: string;
}

export interface IAllocationJobPopupProps {
  canManageJobs: boolean;
  job: IJobItem;
  showInZone: string;
  unfilteredItems: Array<AllBoundedItemTypes>;
  paidHours: Array<PaidHoursInPayPeriod>;
  skillSpecs: SkillSpecItem[];
  staff: IAssignStaffFieldProps['staff'];
  vehicles: IAssignVehicleFieldProps['vehicles'];
  assetSubcategories: AssetSubcategory[];
  assetHousingLocations: AssetHousingLocation[];
  vehicleTypes: VehicleType[];
  techSpecs: TechSpecItem[];
  subcontractors: SubcontractorItem[];
  exclusions: ExclusionItem[];
  allStaffDepots: StaffDepotDto[];
  employmentStatuses: EmploymentStatusDto[];
  roles: ListRoleItem[];
  onAllocateStaffMember: (command: AllocateStaffMemberCommand) => Promise<FatigueWarning[]>;
  onAllocateAsset: (command: AllocateAssetCommand) => Promise<void>;
  onAllocateSubcontractor: (command: AllocateSubcontractorCommand) => Promise<void>;
  onLoadUnfilteredItems: (start: DateTime, end: DateTime) => Promise<void>;
  searchTechSpecValues: SearchTechSpecValues;
  techSpecDropdownOptions: (techSpecId: number) => TechSpecDropdownOption[];
  onSwapVehicle: (command: SwapVehicleCommand) => Promise<void>;
  loadJobProgress: (jobId: string) => Promise<void>;
  jobProgresses: JobProgressItem[] | undefined;
  allLicenceTypes: LicenceType[];
  defaultRolesForDriverAllocations: ListRoleItem[];
  setViewFilters: (filters: { [key: string]: IViewGroupFilterValues }) => void;
  viewFilters: { [key: string]: IViewGroupFilterValues };
}

export const AllocationJobPopup: React.FC<IAllocationJobPopupProps> = ({
  job,
  showInZone,
  unfilteredItems,
  staff,
  onLoadUnfilteredItems,
  paidHours,
  skillSpecs,
  exclusions,
  allStaffDepots,
  employmentStatuses,
  roles,
  allLicenceTypes,
  defaultRolesForDriverAllocations,
  viewFilters,
  onAllocateStaffMember,
  vehicles,
  assetSubcategories,
  assetHousingLocations,
  vehicleTypes,
  techSpecs,
  searchTechSpecValues,
  techSpecDropdownOptions,
  onSwapVehicle,
  loadJobProgress,
  jobProgresses,
  onAllocateSubcontractor,
  subcontractors,
  onAllocateAsset,
  canManageJobs,
}: IAllocationJobPopupProps) => {
  const rootStore = useRootStore();
  const isJobAcknowledged = () => {
    return job && job.jobStatus && job.jobStatus.id === JobStatus.Acknowledged;
  };

  const getAllocateStaffMemberModalDef = (forDriver: WhichDriver): IModalActionButtonDef => {
    const secondDriver = forDriver === 'secondDriver';

    return {
      actionType: ActionType.modalActionButton,
      label: 'Assign Staff Member',
      icon: <EditIcon />,
      modalSize: ShellModalSize.threeQuarters,
      modalDef: () => ({
        title: secondDriver ? 'Assign Second Staff Member' : 'Assign Staff Member',
        subtitle: `for ${job.jobType.description} ${job.shiftName}`,
        asForm: true,
        explicitData: {
          staffMemberId: secondDriver ? job.secondStaffMemberId : job.staffMemberId,
        },
        panels: [
          {
            panes: [
              {
                paneType: PaneType.customPane,
                render: () => (
                  <AssignStaffMemberField
                    showInZone={showInZone}
                    job={job}
                    unfilteredItems={unfilteredItems}
                    staff={staff}
                    paidHours={paidHours}
                    skillSpecs={skillSpecs}
                    exclusions={exclusions}
                    onLoadUnfilteredItems={onLoadUnfilteredItems}
                    allStaffDepots={allStaffDepots}
                    employmentStatuses={employmentStatuses}
                    roles={roles}
                    allLicenceTypes={allLicenceTypes}
                    defaultRolesForDriverAllocations={defaultRolesForDriverAllocations}
                    viewFilters={viewFilters}
                  />
                ),
              },
            ],
          },
        ],
        secondaryActions: [getSubmitCloseModalActionGroupDef('Assign')],
        onFormSubmit: async ({ staffMemberId }: { staffMemberId: string }) => {
          const warnings = await onAllocateStaffMember({
            jobId: job.id,
            staffMemberId,
            asSecondStaffMember: forDriver === 'secondDriver',
          });

          rootStore.compliance.fatigueValidations.setWarningMessages(warnings);
          return;
        },
      }),
    };
  };

  const getAllocateVehicleModalDef = (): IModalActionButtonDef => {
    if (
      (job.hasProgress && job.vehicleSwapJobDetails === null) ||
      (job.hasProgress &&
        job.vehicleSwapJobDetails &&
        job.vehicleSwapJobDetails?.canVehicleSwap &&
        job.vehicleSwapJobDetails?.vehicleSwapToJobId === null)
    ) {
      return getSwapVehicleModalActionButtonDef({
        hidden: false,
        disabled: false,
        currentAsset: job.asset && job.asset.id,
        existingClockOn: job.bounds.start.toISO(),
        existingClockOff: job.bounds.end.toISO(),
        existingJobBreaks: job.unpaidBreaks || '0',
        existingJobId: job.id,
        existingProgresses: jobProgresses,
        fleetAssets: vehicles,
        onSubmit: onSwapVehicle,
        secondStaffMemberId: job.secondStaffMemberId,
        staffMemberId: job.staffMemberId,
        getTechSpecRequirements: () => job.techSpecRequirements,
        loadExistingProgresses: () => loadJobProgress(job.id),
        continuationDetailsForVehicleSwap: job.continuationDetailsForVehicleSwap,
      });
    }

    return {
      actionType: ActionType.modalActionButton,
      label: 'Assign Vehicle',
      icon: <EditIcon />,

      modalSize: ShellModalSize.threeQuarters,
      modalDef: () => ({
        title: 'Assign Vehicle',
        subtitle: `for ${job.jobType.description} ${job.shiftName}`,
        asForm: true,

        explicitData: { assetId: job.asset && job.asset.id },
        panels: [
          {
            panes: [
              {
                paneType: PaneType.customPane,
                render: () => (
                  <AssignVehicleField
                    showInZone={showInZone}
                    job={job}
                    unfilteredItems={unfilteredItems}
                    vehicles={vehicles}
                    exclusions={exclusions}
                    assetSubcategories={assetSubcategories}
                    assetHousingLocations={assetHousingLocations}
                    techSpecs={techSpecs}
                    vehicleTypes={vehicleTypes}
                    searchTechSpecValues={searchTechSpecValues}
                    onLoadUnfilteredItems={onLoadUnfilteredItems}
                    techSpecDropdownOptions={techSpecDropdownOptions}
                    viewFilters={viewFilters}
                    staffMembers={staff}
                  />
                ),
              },
            ],
          },
        ],
        secondaryActions: [
          {
            actions: [
              {
                actionType: ActionType.submitActionButton,
                label: 'Assign & Keep Acknowledgement',
                level: 'primary',
                hidden: !isJobAcknowledged(),
                submissionMeta: { keepAcknowledgement: true },
              },
              ...getSubmitCloseModalActionGroupDef('Assign').actions,
            ],
          },
        ],
        onFormPreSubmit: (
          values: ISubmissionData,
          meta: ISubmissionMeta | undefined
        ): ISubmissionData => {
          return {
            assetId: values.assetId,
            keepAcknowledgement:
              !meta || meta.keepAcknowledgement === undefined
                ? !!values.keepAcknowledgement
                : !!meta.keepAcknowledgement,
          };
        },
        onFormSubmit: (values: ISubmissionData) => {
          return onAllocateAsset({
            jobId: job.id,
            assetId: values.assetId,
            keepAcknowledgement: values.keepAcknowledgement,
          });
        },
      }),
    };
  };

  const getAllocateSubcontractorModalDef = (): IModalActionButtonDef => {
    return {
      actionType: ActionType.modalActionButton,
      label: 'Assign Subcontractor',
      icon: <EditIcon />,
      modalSize: ShellModalSize.oneQuarter,
      modalDef: () => ({
        title: 'Assign Subcontractor',
        asForm: true,
        explicitData: { subcontractor: job.subcontractor },
        panels: [
          {
            panes: [
              {
                paneType: PaneType.formFieldsPane,
                fields: [
                  {
                    fieldType: FieldType.selectField,
                    label: 'Subcontractor',
                    dataAddr: 'subcontractor',
                    optionItems: subcontractors,
                    valueKey: 'id',
                    descriptionKey: 'name',
                  },
                ],
              },
            ],
          },
        ],
        secondaryActions: [getSubmitCloseModalActionGroupDef('Assign')],
        onFormSubmit: (values: { subcontractor: SubcontractorItem }) =>
          onAllocateSubcontractor({
            jobId: job.id,
            subcontractorId: values.subcontractor && values.subcontractor.id,
          }),
      }),
    };
  };

  const renderAllocateActions = (
    hasProgress: boolean,
    isAssigned: boolean,
    modalDef: IModalActionButtonDef,
    unassignLabel: string,
    handleUnassign: () => Promise<void>
  ) => {
    return (
      <span onWheel={suppressPropagation} onMouseDown={suppressPropagation}>
        <ModalActionButton
          actionDef={modalDef}
          actionMeta={{ formSubmitting: false, hideLabel: true, borderless: true }}
          actionData={{} as IActionData}
        />
        {isAssigned && !hasProgress ? (
          <ActionButton
            actionDef={{
              actionType: ActionType.actionButton,
              label: unassignLabel,
              icon: <TrashIcon size="sm" />,
              onClick: handleUnassign,
            }}
            actionMeta={{ formSubmitting: false, hideLabel: true, borderless: true }}
            actionData={{} as IActionData}
          />
        ) : null}
      </span>
    );
  };

  const renderFirstStaffActions = () => {
    return renderStaffActions('firstDriver', j => !!j.staffMemberId);
  };

  const renderSecondStaffActions = () => {
    return renderStaffActions('secondDriver', j => !!j.secondStaffMemberId);
  };

  const renderStaffActions = (forDriver: WhichDriver, isAssigned: (job: IJobItem) => boolean) => {
    if (job.jobStatus.id === JobStatus.Incomplete) {
      return null;
    }
    return renderAllocateActions(
      false, // False because we always want the unassign staff member field to exist
      isAssigned(job),
      getAllocateStaffMemberModalDef(forDriver),
      'Unassign Staff Member',
      () => handleUnassignStaffMember(forDriver)
    );
  };

  const handleUnassignStaffMember = async (forDriver: WhichDriver) => {
    const warnings = await onAllocateStaffMember({
      jobId: job.id,
      staffMemberId: undefined,
      asSecondStaffMember: forDriver === 'secondDriver',
    });

    rootStore.compliance.fatigueValidations.setWarningMessages(warnings);
    return;
  };

  const renderVehicleActions = () => {
    return renderAllocateActions(
      job.hasProgress,
      !!(job.asset && job.asset.id),
      getAllocateVehicleModalDef(),
      'Unassign Vehicle',
      handleUnassignVehicle
    );
  };

  const handleUnassignSubcontractor = () =>
    onAllocateSubcontractor({
      jobId: job.id,
      subcontractorId: undefined,
    });

  const renderSubcontractorActions = () => {
    if (job.jobStatus.id === JobStatus.Incomplete) {
      return null;
    }
    return renderAllocateActions(
      job.hasProgress,
      !!(job.subcontractor && job.subcontractor.id),
      getAllocateSubcontractorModalDef(),
      'Unassign Subcontractor',
      handleUnassignSubcontractor
    );
  };

  const handleUnassignVehicle = () => {
    return onAllocateAsset({
      jobId: job.id,
      assetId: undefined,
      keepAcknowledgement: false,
    });
  };

  const isInContinuation = job.continuation && (job.continuation.fromJob || job.continuation.toJob);
  const hasSubcontractor = canJobTypeHaveSubcontractor(job.jobType.id) && job.hasSubcontractor;
  const withoutAsset = isJobTypeWithoutAsset(job.jobType.id);

  return (
    <section
      className={cn('allocation-job-popup-component', 'allocations-item-popup', {
        'without-asset': withoutAsset,
        'quote-job': isJobTypeLinkedToQuote(job.jobType.id),
        'charter-job': isJobTypeCharter(job.jobType.id),
        'rail-job': job.jobType.id === JobType.Rail,
        'has-subcontractor': hasSubcontractor,
        'has-second-staff-member': job.hasSecondStaffMember,
        'has-trailer': false,
      })}>
      <PopupField
        className="staff-member"
        label="Staff Member"
        value={job.staffMemberName}
        placeholderValue="unassigned"
        valueLinkTo={`/people/staff-members/${job.staffMemberId}`}
        renderActions={canManageJobs ? renderFirstStaffActions : () => null}
        hidden={hasSubcontractor}
      />
      <PopupField
        className="second-staff-member"
        label="Second Staff Member"
        value={job.secondStaffMemberName}
        placeholderValue="unassigned"
        valueLinkTo={`/people/staff-members/${job.secondStaffMemberId}`}
        renderActions={canManageJobs ? renderSecondStaffActions : () => null}
        hidden={hasSubcontractor || !job.hasSecondStaffMember}
      />
      <PopupField
        className="vehicle"
        label="Vehicle"
        value={`${
          job.asset
            ? `${job.asset.name}${job.asset.isDecommissioned ? ' (Decommissioned)' : ''}`
            : ''
        }`}
        placeholderValue="unassigned"
        valueLinkTo={`/workshop/assets/${job.asset && job.asset.id}`}
        renderActions={
          job.isChildJob ||
          !canManageJobs ||
          (job.vehicleSwapJobDetails && job.vehicleSwapJobDetails?.vehicleSwapToJobId !== null)
            ? () => undefined
            : renderVehicleActions
        }
        hidden={withoutAsset || hasSubcontractor}
      />
      <PopupField
        className="subcontractor"
        label="Subcontractor"
        value={`${job.subcontractor ? job.subcontractor.name : ''}`}
        placeholderValue="unassigned"
        valueLinkTo={`/operations/subcontractors/${job.subcontractor && job.subcontractor.id}`}
        renderActions={canManageJobs ? renderSubcontractorActions : () => null}
        hidden={!hasSubcontractor}
      />
      <PopupField
        className="job-number"
        label="Job"
        value={job.jobNumber}
        valueSuffix={
          job.jobStatus.id === JobStatus.Incomplete ? `(${job.jobStatus.description})` : undefined
        }
        valueLinkTo={`/operations/jobs/${job.id}`}
      />
      <PopupField
        className="source-reference"
        label="Shift"
        value={job.shiftName}
        hidden={isJobTypeLinkedToQuote(job.jobType.id)}
      />
      <PopupField
        className="source-reference"
        label="Booking"
        value={job.quoteNumber || ''}
        valueLinkTo={`/operations/bookings-for-ops/${job.quoteId}`}
        hidden={!isJobTypeLinkedToQuote(job.jobType.id)}
      />
      <PopupField className="job-type" label="Job Type" value={job.jobType.description} />
      <PopupField className="job-description" label="Description" value={job.description} />
      <PopupField
        className="job-vehicle-type"
        label="Quoted Vehicle Type"
        value={job.vehicleType && job.vehicleType.description}
        hidden={!isJobTypeCharter(job.jobType.id)}
      />
      <PopupField
        className="job-customer"
        label="Customer"
        value={job.customer && job.customer.customerName}
        valueLinkTo={`/sales/customers/${job.customer && job.customer.customerId}`}
        hidden={!isJobTypeLinkedToQuote(job.jobType.id)}
      />
      <ContinuationField
        className="continue-from"
        label="Continuing From"
        hidden={!isInContinuation}
        job={job.continuation && job.continuation.fromJob}
      />
      <ContinuationField
        className="continue-to"
        label="Continuing To"
        hidden={!isInContinuation}
        job={job.continuation && job.continuation.toJob}
      />
      <PopupField className="notes" label="Notes" value={job.briefNotes} />
      <ConflictsPopupField conflicts={job.conflicts} />
    </section>
  );
};
