import { types, flow } from 'mobx-state-tree';
import { getAjax } from 'src/domain/services';
import { JobItemModel } from './JobItemModel';
import { ListJobsModel } from './ListJobsModel';
import { ListJobsForAllocationsModel } from './ListJobsForAllocationsModel';
import { ListJobsForCharterModel } from 'src/domain/entities/operations/job/ListJobsForCharterModel';
import { PrefilledTimesheetItemModel } from 'src/domain/entities/operations/job/PrefilledTimesheetItemModel';
import { ListJobProgressWarningsModel } from 'src/domain/entities/operations/job/ListJobProgressWarningsModel';

export const JobModel = types
  .model('JobModel', {
    list: types.optional(ListJobsModel, {}),
    listForCharter: types.optional(ListJobsForCharterModel, {}),
    listForAllocations: types.optional(ListJobsForAllocationsModel, {}),
    item: types.optional(JobItemModel, {}),
    prefilledTimesheetItem: types.optional(PrefilledTimesheetItemModel, {}),
    listJobProgressWarnings: types.optional(ListJobProgressWarningsModel, {}),
    jobSubTypes: types.array(
      types.frozen<Operations.Domain.Queries.ListJobSubType.JobSubTypeItem>()
    ),
  })
  .actions(self => {
    const ajax = getAjax(self);

    const dismissProgressWarning = flow(function*(jobId: string) {
      yield ajax.operations.jobs.dismissProgressWarning(jobId);
    });

    const loadJobSubTypes = flow(function*() {
      self.jobSubTypes = yield ajax.operations.jobs.loadJobSubTypes();
    });

    return { dismissProgressWarning, loadJobSubTypes };
  });

type ModelType = typeof JobModel.Type;
export interface IJobModel extends ModelType {}
