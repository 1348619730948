import { IAjax } from 'src/infrastructure/ajax';
import { engineUrls } from 'src/domain/services/apiUrls';

type AggregateUsageDto = Common.Dtos.AggregateUsageDto;
type SetEngineActiveCommand = Workshop.Domain.Commands.Engine.SetEngineActive.SetEngineActiveCommand;
type SetEngineInactiveCommand = Workshop.Domain.Commands.Engine.SetEngineInactive.SetEngineInactiveCommand;

// tslint:disable-next-line:no-empty
const returnVoid = () => {};

export default function init(ajax: IAjax) {
  function listEngines() {
    return ajax
      .get(engineUrls.listEngines())
      .toPromise()
      .then<Workshop.Domain.Queries.Engine.GetEngineList.EngineItem[]>(r => r.response);
  }

  function listEnginesDetails() {
    return ajax
      .get(engineUrls.listEnginesDetails())
      .toPromise()
      .then<Workshop.Domain.Queries.Engine.GetEngine.EngineDetails>(r => r.response);
  }

  function loadServiceTypes() {
    return ajax
      .get(engineUrls.loadServiceTypes())
      .toPromise()
      .then<Workshop.Domain.AggregatesModel.JobTaskAggregate.JobTaskSubcategory[]>(r => r.response);
  }

  function createEngine(command: Workshop.Domain.Commands.Engine.CreateEngineCommand) {
    return ajax
      .post(engineUrls.createEngine(), command)
      .toPromise()
      .then<string>(r => r.response);
  }

  function checkForUniqueModel(model: string) {
    return ajax
      .get(engineUrls.checkForUniqueModel(model))
      .toPromise()
      .then<Common.Dtos.UniqueNameCheckResultDto>(r => r.response);
  }

  function loadEngine(id: string) {
    return ajax
      .get(engineUrls.getEngine(id))
      .toPromise()
      .then<Workshop.Domain.Queries.Engine.GetEngine.EngineDetails>(r => r.response);
  }

  function updateEngine(command: Workshop.Domain.Commands.Engine.UpdateEngineCommand) {
    return ajax
      .put(engineUrls.updateEngine(command.engineId), command)
      .toPromise()
      .then(r => returnVoid());
  }

  function updateEngineSetInactive(command: SetEngineInactiveCommand) {
    return ajax
      .put(engineUrls.updateEngineSetInactive(command.id), command)
      .toPromise()
      .then(r => r.response);
  }

  function updateEngineSetActive(command: SetEngineActiveCommand) {
    return ajax
      .put(engineUrls.updateEngineSetActive(command.id), command)
      .toPromise()
      .then(r => r.response);
  }

  function deleteEngine(id: string) {
    return ajax
      .httpDelete(engineUrls.deleteEngine(id))
      .toPromise()
      .then(r => r.response);
  }

  function getCurrentUsage(id: string) {
    return ajax
      .get(engineUrls.getCurrentUsage(id))
      .toPromise()
      .then<AggregateUsageDto>(r => r.response);
  }

  return {
    engine: {
      listEngines,
      listEnginesDetails,
      loadServiceTypes,
      createEngine,
      checkForUniqueModel,
      loadEngine,
      updateEngine,
      updateEngineSetActive,
      updateEngineSetInactive,
      deleteEngine,
      getCurrentUsage,
    },
  };
}
