import inject from 'src/views/injectFromStore';
import ListPartTransactions, { IListPartTransactionsProps } from './ListPartTransactions';

const Container = inject<IListPartTransactionsProps>((allStores, p) => ({
  canManageParts: allStores.rootStore.account.isWorkshopDepartmentMember,
  canManageStocktakes: allStores.rootStore.account.isWorkshopDepartmentMember,
  listPartTransactions: allStores.rootStore.workshop.partTransactions.listItems,
  parts: allStores.rootStore.workshop.partTransactions.items.slice(),
  categories: allStores.rootStore.workshop.partCategories.partCategoryListItems.slice(),
  hasMoreData: allStores.rootStore.workshop.partTransactions.hasMoreItems,
  downloadUsedPartsExcel: allStores.rootStore.workshop.partTransactions.downloadUsedPartsExcel,
  downloadUsedPartsPdf: allStores.rootStore.workshop.partTransactions.downloadUsedPartsPdf,
  loadCategories: allStores.rootStore.workshop.partCategories.loadPartCategoryListItems,
  searchSuppliers: allStores.rootStore.workshop.supplier.searchSuppliers,
  findSuppliers: allStores.rootStore.workshop.supplier.findSuppliers,
  loadAssets: allStores.rootStore.assets.loadAssetListItems,
  optionAssets: allStores.rootStore.assets.assetListItems.slice(),
  workshopDepots: allStores.rootStore.workshopStartup.workshopDepots.slice(),
  defaultWorkshopDepot: allStores.rootStore.workshopStartup.defaultWorkshopDepot,
  searchParts: allStores.rootStore.workshop.parts.searchParts,
  findParts: allStores.rootStore.workshop.parts.findParts,
}))(ListPartTransactions);

export default Container;
