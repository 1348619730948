import { types, flow, getRoot } from 'mobx-state-tree';
import { IRootStoreModel } from 'src/domain/entities/RootStoreModel';
import { getAjax } from 'src/domain/services/storeEnvironment';
import { NotificationType } from 'src/domain';
import { saveAs } from 'file-saver';

type QuotePdfItem = Operations.Domain.Queries.ListQuotePdfs.QuotePdfItem;

export const QuotePdfsModel = types
  .model('QuotePdfsModel', {
    quotePdfs: types.array(types.frozen<QuotePdfItem>()),
  })
  .actions(self => {
    const ajax = getAjax(self);
    const root = getRoot<IRootStoreModel>(self);

    const loadQuotePdfs = flow(function*(quoteId: string) {
      self.quotePdfs = yield ajax.sales.quote.loadQuotePdfs(quoteId);
    });

    const generatePdf = flow(function*(quoteId: string) {
      root.notifications.addNotification(`The PDF is being generated ...`, {
        type: NotificationType.info,
      });

      yield ajax.sales.quote.generatePdf(quoteId);
      yield loadQuotePdfs(quoteId);
      const latestPdf = self.quotePdfs[0];
      return yield ajax.sales.quote
        .getPdf(quoteId, latestPdf.id)
        .then(result => saveAs(result, latestPdf.filename));
    });

    const getPdf = flow(function*(quoteId: string, quotePdfId: number) {
      return yield ajax.sales.quote.getPdf(quoteId, quotePdfId);
    });

    return {
      generatePdf,
      loadQuotePdfs,
      getPdf,
    };
  });
