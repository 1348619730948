import inject from 'src/views/injectFromStore';
import MaintainGoodsReceived, { IGoodsReceivedProps } from './MaintainGoodsReceived';
import Omit from 'src/infrastructure/omit';

const Container = inject<Omit<IGoodsReceivedProps, 'mode'>, IGoodsReceivedProps>(allStores => ({
  canManagePurchaseOrders: allStores.rootStore.account.isWorkshopDepartmentMember,
  purchaseOrder: allStores.rootStore.workshop.purchaseOrder.purchaseOrder,
  assets: allStores.rootStore.assets.assetListItems.slice(),
  loadPurchaseOrder: allStores.rootStore.workshop.purchaseOrder.loadPurchaseOrder,
  loadAssetListItems: allStores.rootStore.assets.loadAssetListItems,
  createGoodsReceived: allStores.rootStore.workshop.purchaseOrder.createGoodsReceived,
  updateGoodsReceived: allStores.rootStore.workshop.purchaseOrder.updateGoodsReceived,
  searchParts: allStores.rootStore.workshop.parts.searchParts,
  getJobTasksForAsset: allStores.rootStore.jobTask.getJobTasksForAsset,
  onMarkAsExported: allStores.rootStore.workshop.purchaseOrder.markAsExported,
  onRemoveExportedIndicator: allStores.rootStore.workshop.purchaseOrder.removeExportedIndicator,
}))(MaintainGoodsReceived);

export default Container;
