import { getSubmitCloseModalActionGroupDef } from 'src/views/definitionBuilders/common';
import { FieldType, ModalDefBuilder, PaneType } from 'src/views/definitionBuilders/types';

type RailBookingVehicleItem = Operations.Domain.Queries.ViewRailBooking.RailBookingVehicleItem;
type VehicleType = Common.Dtos.VehicleTypeItem;

export default function getMaintainVehicleModalDef(
  vehicleTypes: VehicleType[],
  isEditing: boolean
): ModalDefBuilder {
  return modalDefApi => {
    return {
      title: isEditing ? 'Edit Vehicle' : 'Add Vehicle',
      asForm: true,
      panels: [
        {
          panes: [
            {
              paneType: PaneType.formFieldsPane,
              fields: [
                {
                  fieldType: FieldType.selectField,
                  dataAddr: 'vehicleTypeId',
                  label: 'Vehicle Type',
                  valueKey: 'id',
                  descriptionKey: 'description',
                  optionItems: vehicleTypes,
                  columnWidth: '20em',
                  mandatory: true,
                  useValueOnly: true,
                  valuesToDisable: () =>
                    modalDefApi.parentFormApi.values.railBookingVehicles.map(
                      (vehicle: RailBookingVehicleItem) => vehicle.vehicleTypeId
                    ),
                },
                {
                  fieldType: FieldType.numericField,
                  dataAddr: 'quantity',
                  numericConfig: { numericType: 'unsignedInt' },
                  label: 'Quantity',
                  columnWidth: '6em',
                  mandatory: true,
                },
                {
                  fieldType: FieldType.numericField,
                  dataAddr: 'totalHours',
                  numericConfig: {
                    numericType: 'unsignedDecimal',
                    maxPointDigits: 2,
                    maxValue: 99999,
                  },
                  label: 'Total Hours',
                  columnWidth: '6em',
                  mandatory: true,
                },
                {
                  fieldType: FieldType.numericField,
                  dataAddr: 'totalPrice',
                  numericConfig: {
                    numericType: 'unsignedDecimal',
                    maxPointDigits: 2,
                  },
                  label: 'Total Price',
                  columnWidth: '7em',
                  mandatory: true,
                },
              ],
            },
          ],
        },
      ],
      secondaryActions: [getSubmitCloseModalActionGroupDef()],
      onFormSubmit: values => {
        const vehicles = modalDefApi.parentFormApi.values.railBookingVehicles;
        const dataAddr = modalDefApi.actionData.fieldDataAddr;

        return !isEditing
          ? Promise.resolve(
              modalDefApi.parentFormApi.setValue('railBookingVehicles', [...vehicles, values])
            )
          : Promise.resolve(modalDefApi.parentFormApi.setValue(dataAddr, { ...values }));
      },
    };
  };
}
