import inject from 'src/views/injectFromStore';
import ListDefects, { IListPartsGroupsProps } from './ListPartsGroups';

const Container = inject<IListPartsGroupsProps>((allStores, p) => ({
  canManagePartsGroups: allStores.rootStore.account.isWorkshopDepartmentMember,
  partsGroups: allStores.rootStore.workshop.partsGroups.partsGroups.slice(),
  onListPartsGroups: allStores.rootStore.workshop.partsGroups.listPartsGroups,
}))(ListDefects);

export default Container;
