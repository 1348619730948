import { Component } from 'react';
import { DateTime } from 'luxon';
import PrintDailySummary from 'src/views/kioskRoutes/operationsKiosk/home/PrintDailySummary/PrintDailySummary';
import { groupBy } from 'src/infrastructure/arrayUtils';
import styles from './PrintDailySummaries.module.scss';

export interface IPrintDailySummariesProps {
  jobs: Operations.Domain.Queries.ListDailySummarySheetDetails.DailySummarySheetJobDetailsDto[];
  checks: Operations.Domain.Queries.ListJobsForDriver.PredepartureCheckItem[];
}

class PrintDailySummaries extends Component<IPrintDailySummariesProps> {
  render() {
    return this.props.jobs && this.props.jobs.length > 0 ? (
      // Grouping by staff member id
      Array.from(groupBy(this.props.jobs, j => j.primaryStaffMemberId).entries())
        .sort(([staffMember1Id, jobsByStaffMember1Id], [staffMember2Id, jobsByStaffMember2Id]) =>
          jobsByStaffMember1Id[0].primaryStaffMemberDisplayName.localeCompare(
            jobsByStaffMember2Id[0].primaryStaffMemberDisplayName
          )
        )
        .map(([staffMemberId, jobsByStaffMemberId]) =>
          // Grouping by day
          Array.from(
            groupBy(jobsByStaffMemberId, job => this.getJobDate(job.clockOn).toMillis()).entries()
          ).map(([jobDateTimestamp, jobsByDate], i) => (
            <div className={styles.summaryPage} key={i}>
              <PrintDailySummary
                userDisplayName={jobsByDate[0].primaryStaffMemberDisplayName}
                jobs={jobsByDate}
                date={DateTime.fromMillis(jobDateTimestamp)}
                checks={this.props.checks}
                checkDraft={false}
                checkColumns={3}
              />
            </div>
          ))
        )
    ) : (
      <h1>No Jobs To Export</h1>
    );
  }

  getJobDate = (jobDate: string): DateTime => {
    let date = DateTime.local();
    if (jobDate) {
      date = DateTime.fromISO(jobDate);
    }
    return date.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
  };
}

export default PrintDailySummaries;
