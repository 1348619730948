import { ISchemaRecord } from 'src/views/components/AuditHistory/ISchemaRecord';
import { DateTimeFormat } from 'src/views/components/DateTimeFormat';
import {
  getEditingFormattedTimeString,
  parseEditingFormattedTimeString,
} from 'src/views/components/Page/fields/subfields/TimeHelpers';
import {
  jobStatusDescription,
  adhocReasonDescription,
  progressIdDescription,
  fatigueRuleSetTypeDescription,
} from 'src/api/enums';
import FleetAssetRenderer from 'src/views/components/operations/FleetAssetRenderer/FleetAssetRenderer';
import { Link } from 'react-router-dom';
import { DateFormat } from 'src/views/components/DateFormat';
import { getObject, isEmpty } from 'src/views/components/AuditHistory/AuditHistoryHelpers';
import { noValue } from 'src/views/components/AuditHistory/AuditHistoryField';
import { formatDateTimeMed } from 'src/domain/dateHelper';
import { isJobTypeCharterOrContractCharter } from '../../../shared/jobTypeHelpers';

type AssetItem = Common.Queries.Workshop.GetFleetAssetList.AssetItem;
type StaffMemberDto = Common.Dtos.StaffMemberDto;
type SubcontractorItem = Common.Dtos.SubcontractorItem;
type ExtraType = Operations.Domain.Queries.ViewExtraType.ExtraTypeItem;
type VehicleType = Common.Dtos.VehicleTypeItem;

export default function getSchema(
  depots: Common.Dtos.OperationsDepotDto[],
  fleetAssets: AssetItem[],
  staffMembers: StaffMemberDto[],
  subcontractors: SubcontractorItem[],
  extraTypes: ExtraType[],
  vehicleTypes: VehicleType[],
  jobTypeId?: number
): ISchemaRecord[] {
  const isProgressSoftDeleted = (
    // tslint:disable-next-line:no-any
    changes: any,
    // tslint:disable-next-line:no-any
    address: any[]
  ) => {
    const progress = getObject(address.slice(0, address.length - 1), changes);
    const isDeleted = !!(progress.Deleted && progress.Deleted.c);
    return isDeleted;
  };

  return [
    {
      label: 'Job Status',
      key: 'JobStatusId',
      format: value => jobStatusDescription(value),
    },
    {
      label: 'Is Cancelled',
      key: 'IsCancelled',
      format: d => (d ? 'Yes' : 'No'),
    },
    {
      label: 'Description',
      key: 'Description',
    },
    {
      label: 'Notes',
      key: 'Notes',
    },
    {
      label: 'Vehicle',
      key: 'AssetId',
      format: value => (
        <FleetAssetRenderer asset={fleetAssets.find(x => x.id === value)!} hideAssetInfo addLink />
      ),
    },
    {
      label: 'Staff Member',
      key: 'StaffMemberId',
      format: value =>
        value && staffMembers ? (
          <Link to={`/people/staff-members/${value}`}>
            {staffMembers.find(x => x.id === value)!.name}
          </Link>
        ) : null,
    },
    {
      label: 'Has Second Staff Member?',
      key: 'HasSecondStaffMember',
      format: d => (d ? 'Yes' : 'No'),
    },
    {
      label: 'Second Staff Member',
      key: 'SecondStaffMemberId',
      format: value =>
        value && staffMembers ? (
          <Link to={`/people/staff-members/${value}`}>
            {staffMembers.find(x => x.id === value)!.name}
          </Link>
        ) : null,
    },
    {
      label: 'Has Staff Member Acknowledged?',
      key: 'HasStaffMemberAcknowledged',
      format: d => (d ? 'Yes' : 'No'),
    },
    {
      label: 'Has Second Staff Member Acknowledged?',
      key: 'HasSecondStaffMemberAcknowledged',
      format: d => (d ? 'Yes' : 'No'),
    },
    {
      label: 'Has Subcontractor?',
      key: 'HasSubcontractor',
      format: d => (d ? 'Yes' : 'No'),
    },
    {
      label: 'Subcontractor',
      key: 'SubcontractorId',
      format: value =>
        value && staffMembers ? (
          <Link to={`/operations/subcontractors/${value}`}>
            {subcontractors.find(x => x.id === value)!.name}
          </Link>
        ) : null,
    },
    {
      label: 'Adhoc Reason',
      key: 'AdhocReasonId',
      format: value => adhocReasonDescription(value),
    },
    {
      label: 'Adhoc Reason Details',
      key: 'AdhocReasonDetails',
    },
    {
      label: 'Continuation Start',
      key: 'ContinuationJobStart',
      format: value => <DateTimeFormat value={value} />,
    },
    {
      label: 'Continuing From Job',
      key: 'ContinuingFromJobId',
      format: value => (value ? <Link to={`/operations/jobs/${value}`}>View Job</Link> : null),
    },
    {
      label: 'Continuation Finish',
      key: 'ContinuationJobFinish',
      format: value => <DateTimeFormat value={value} />,
    },
    {
      label: 'Continuing To Job',
      key: 'ContinuingToJobId',
      format: value => (value ? <Link to={`/operations/jobs/${value}`}>View Job</Link> : null),
    },
    {
      label: 'Clock On',
      key: 'ClockOn',
      format: value => <DateTimeFormat value={value} />,
    },
    {
      label: 'Clock On',
      key: 'InitialClockOn',
      format: value => <DateTimeFormat value={value} />,
    },
    {
      label: 'Override Clock On',
      key: 'OverrideClockOn',
      format: value => <DateTimeFormat value={value} />,
    },
    {
      label: 'Depart Depot',
      key: 'DepartDepot',
      format: value => <DateTimeFormat value={value} />,
    },
    {
      label: 'Depart Depot',
      key: 'InitialDepartDepot',
      format: value => <DateTimeFormat value={value} />,
    },
    {
      label: 'Override Depart Depot',
      key: 'OverrideDepartDepot',
      format: value => <DateTimeFormat value={value} />,
    },
    {
      label: 'On Site',
      key: 'OnSite',
      format: value => <DateTimeFormat value={value} />,
    },
    {
      label: 'On Site',
      key: 'InitialOnSite',
      format: value => <DateTimeFormat value={value} />,
    },
    {
      label: 'Override On Site',
      key: 'OverrideOnSite',
      format: value => <DateTimeFormat value={value} />,
    },
    {
      label: 'Shift Commence',
      key: 'ShiftCommence',
      format: value => <DateTimeFormat value={value} />,
    },
    {
      label:
        jobTypeId && isJobTypeCharterOrContractCharter(jobTypeId) ? 'Pick Up' : 'Shift Commence',
      key: 'InitialShiftCommence',
      format: value => <DateTimeFormat value={value} />,
    },
    {
      label: 'Override Shift Commence',
      key: 'OverrideShiftCommence',
      format: value => <DateTimeFormat value={value} />,
    },
    {
      label: 'Shift End',
      key: 'ShiftEnd',
      format: value => <DateTimeFormat value={value} />,
    },
    {
      label: jobTypeId && isJobTypeCharterOrContractCharter(jobTypeId) ? 'Drop Off' : 'Shift End',
      key: 'InitialShiftEnd',
      format: value => <DateTimeFormat value={value} />,
    },
    {
      label: 'Override Shift End',
      key: 'OverrideShiftEnd',
      format: value => <DateTimeFormat value={value} />,
    },
    {
      label: 'Arrive Depot',
      key: 'ArriveDepot',
      format: value => <DateTimeFormat value={value} />,
    },
    {
      label: 'Arrive Depot',
      key: 'InitialArriveDepot',
      format: value => <DateTimeFormat value={value} />,
    },
    {
      label: 'Override Arrive Depot',
      key: 'OverrideArriveDepot',
      format: value => <DateTimeFormat value={value} />,
    },
    {
      label: 'Clock Off',
      key: 'ClockOff',
      format: value => <DateTimeFormat value={value} />,
    },
    {
      label: 'Clock Off',
      key: 'InitialClockOff',
      format: value => <DateTimeFormat value={value} />,
    },
    {
      label: 'Unpaid Breaks',
      key: 'UnpaidBreaks',
      format: value => getEditingFormattedTimeString(parseEditingFormattedTimeString(value)),
    },
    {
      label: 'Departing From Depot',
      key: 'DepartingFromDepotId',
      format: value => (value ? depots.find(x => x.id === value)!.description : null),
    },
    {
      label: 'Departing From Depot In Car',
      key: 'DepartingFromDepotInCar',
      format: d => (d ? 'Yes' : 'No'),
    },
    {
      label: 'Arriving At Depot',
      key: 'ArrivingAtDepotId',
      format: value => (value ? depots.find(x => x.id === value)!.description : null),
    },
    {
      label: 'Arriving At Depot In Car',
      key: 'ArrivingAtDepotInCar',
      format: d => (d ? 'Yes' : 'No'),
    },
    {
      label: 'Fuel',
      key: 'Fuel',
    },
    {
      label: 'Adblue',
      key: 'Adblue',
    },
    {
      label: 'Tolls',
      key: 'Tolls',
      format: d => (d ? 'Yes' : 'No'),
    },
    {
      label: 'Odometer Start',
      key: 'OdometerStart',
    },
    {
      label: 'Odometer End',
      key: 'OdometerEnd',
    },
    {
      label: 'Zero Kms Travelled',
      key: 'ZeroKmsTravelled',
      format: d => (d ? 'Yes' : 'No'),
    },
    {
      label: 'Driver Clocked On',
      key: 'DriverClockedOn',
      format: value => <DateTimeFormat value={value} />,
    },
    {
      label: 'Driver Clocked Off',
      key: 'DriverClockedOff',
      format: value => <DateTimeFormat value={value} />,
    },
    {
      label: 'Driver Unpaid Breaks',
      key: 'DriverUnpaidBreaks',
      format: value => getEditingFormattedTimeString(parseEditingFormattedTimeString(value)),
    },
    {
      label: 'Reason For Difference',
      key: 'ReasonForDifference',
    },
    {
      label: 'Approval Number',
      key: 'ApprovalNumber',
    },
    {
      label: 'Anything To Report',
      key: 'AnythingToReport',
    },
    {
      label: 'Second Driver Clocked On',
      key: 'SecondDriverClockedOn',
      format: value => <DateTimeFormat value={value} />,
    },
    {
      label: 'Second Driver Clocked Off',
      key: 'SecondDriverClockedOff',
      format: value => <DateTimeFormat value={value} />,
    },
    {
      label: 'Override Driver Unpaid Breaks',
      key: 'OverrideDriverUnpaidBreaks',
      format: value => getEditingFormattedTimeString(parseEditingFormattedTimeString(value)),
    },
    {
      label: 'Override Driver Paid Hours',
      key: 'DriverOverridePaidHours',
      format: value => getEditingFormattedTimeString(parseEditingFormattedTimeString(value)),
    },
    {
      label: 'Reason for Override',
      key: 'ReasonForCompletionDetailsOverride',
    },
    {
      label: 'Override Driver Unpaid Breaks',
      key: 'SecondDriverOverrideDriverUnpaidBreaks',
      format: value => getEditingFormattedTimeString(parseEditingFormattedTimeString(value)),
    },
    {
      label: 'Override Second Driver Paid Hours',
      key: 'SecondDriverOverridePaidHours',
      format: value => getEditingFormattedTimeString(parseEditingFormattedTimeString(value)),
    },
    {
      label: 'Second Driver Reason for Override',
      key: 'SecondDriverReasonForCompletionDetailsOverride',
    },
    {
      label: 'Second Driver Reason For Difference',
      key: 'SecondDriverReasonForDifference',
    },
    {
      label: 'Second Driver Anything To Report',
      key: 'SecondDriverAnythingToReport',
    },
    {
      key: 'AttachmentDetails',
      isArray: true,
      sectionLabel: (diff, changes, address) => {
        const attachment = getObject(address, changes);
        const isCreated = isEmpty(attachment.FileName.o);
        const isDeleted = isEmpty(attachment.FileName.c);

        const filename = isEmpty(attachment.FileName.c)
          ? attachment.FileName.o
          : attachment.FileName.c;

        return `${filename}${isCreated ? ' - Added' : isDeleted ? ' - Deleted' : ' - Updated'}`;
      },
      childRecords: [
        {
          label: 'Available on Tablet',
          key: 'AvailableOnTablet',
          format: d => (d ? 'Yes' : 'No'),
        },
        {
          label: 'Available on Kiosk',
          key: 'AvailableOnKiosk',
          format: d => (d ? 'Yes' : 'No'),
        },
      ],
    },
    {
      key: 'JobExtras',
      isArray: true,
      sectionLabel: (_, changes, address) => {
        const extra = getObject(address, changes);

        if (!extra.ExtraTypeId) {
          return `Facilities Unknown`;
        }

        return `Facilities ${
          isEmpty(extra.ExtraTypeId.c)
            ? ` - ${extraTypes.find(x => x.id === extra.ExtraTypeId.o)!.description}`
            : ` - ${extraTypes.find(x => x.id === extra.ExtraTypeId.c)!.description}`
        }`;
      },
      childRecords: [
        {
          label: 'Extra Type',
          key: 'ExtraTypeId',
          format: value => (value ? extraTypes.find(x => x.id === value)!.description : null),
        },
        {
          label: 'Quantity',
          key: 'Quantity',
        },
        {
          label: 'Task Completed By',
          key: 'TaskCompletedBy',
        },
        {
          label: 'Task Completed Notes',
          key: 'TaskCompletedNotes',
        },
        {
          label: 'Task Completed On',
          key: 'TaskCompletedOn',
          format: value => <DateTimeFormat value={value} />,
        },
      ],
    },
    {
      label: 'Needs Verification',
      key: 'NeedsVerification',
      format: d => (d ? 'Yes' : 'No'),
    },
    {
      key: 'JobRoutes',
      sectionLabel: (_, changes, address) => {
        const option = getObject(address, changes);

        if (!option.RouteNumber || !option.Name) {
          return `Route Unknown`;
        }

        return `Routes ${
          isEmpty(option.RouteNumber.c)
            ? `${Number(option.RouteNumber.o) + 1} - ${option.Name.o}`
            : `${Number(option.RouteNumber.c) + 1} - ${option.Name.c}`
        }`;
      },
      isArray: true,
      orderKey: 'RouteNumber',
      childRecords: [
        {
          label: 'Route Number',
          key: 'RouteNumber',
          format: value => value + 1,
        },
        {
          label: 'Date',
          key: 'Date',
          format: value => <DateFormat value={value} />,
        },
        {
          label: 'Name',
          key: 'Name',
        },
        {
          label: 'Address',
          key: 'Address',
        },
        {
          label: 'City',
          key: 'City',
        },
        {
          label: 'State',
          key: 'State',
        },
        {
          label: 'Postcode',
          key: 'PostCode',
        },
        {
          label: 'Arrive',
          key: 'Arrive',
          format: value => getEditingFormattedTimeString(parseEditingFormattedTimeString(value)),
        },
        {
          label: 'Depart',
          key: 'Depart',
          format: value => getEditingFormattedTimeString(parseEditingFormattedTimeString(value)),
        },
        {
          label: 'Notes',
          key: 'Notes',
        },
      ],
    },
    {
      key: 'JobProgresses',
      sectionLabel: (diff, changes, address) => {
        const option = getObject(address, changes);

        if (!option.ProgressId) {
          return `Job Progress - Unknown`;
        }

        return `Job Progress - ${
          isEmpty(option.ProgressId.c)
            ? progressIdDescription(option.ProgressId.o)
            : progressIdDescription(option.ProgressId.c)
        }`;
      },
      isArray: true,
      orderKey: 'OccurredAt',
      childRecords: [
        {
          label: 'Progress',
          key: 'ProgressId',
          showUnchanged: (changes, address) => isProgressSoftDeleted(changes, address),
          format: (value, changes, address, valueType) => {
            return isProgressSoftDeleted(changes, address) && valueType === 'after'
              ? noValue
              : progressIdDescription(value);
          },
        },
        {
          label: 'Staff Member',
          key: 'StaffMemberId',
          showUnchanged: (changes, address) => isProgressSoftDeleted(changes, address),
          format: (value, changes, address, valueType) => {
            const staff = value && staffMembers && staffMembers.find(x => x.id === value);
            return isProgressSoftDeleted(changes, address) && valueType === 'after' ? (
              noValue
            ) : staff ? (
              <Link to={`/people/staff-members/${staff.id}`}>{staff.name}</Link>
            ) : null;
          },
        },
        {
          label: 'Occurred At',
          key: 'OccurredAt',
          showUnchanged: (changes, address) => isProgressSoftDeleted(changes, address),
          format: (value, changes, address, valueType) => {
            return isProgressSoftDeleted(changes, address) && valueType === 'after' ? (
              noValue
            ) : (
              <DateTimeFormat value={value} />
            );
          },
        },
      ],
    },
    {
      label: 'Quoted Vehicle Type',
      key: 'VehicleTypeId',
      format: value => (value ? vehicleTypes.find(x => x.id === value)!.description : null),
    },
    {
      label: 'Fatigue Rule Set',
      key: 'FatigueRuleSetType',
      format: value => fatigueRuleSetTypeDescription(value),
    },
    {
      label: 'Driver Scheduled Breaks',
      key: 'JobScheduledBreaks',
      isArray: true,
      sectionLabel: (_, changes, address) => {
        const breaks = getObject(address, changes);

        if (!isEmpty(breaks.IsSecondDriver.c))
          return breaks.IsSecondDriver.c
            ? 'Driver 2 Scheduled Breaks'
            : 'Driver 1 Scheduled Breaks';

        if (!isEmpty(breaks.IsSecondDriver.o))
          return breaks.IsSecondDriver.o
            ? 'Driver 2 Scheduled Breaks'
            : 'Driver 1 Scheduled Breaks';

        return 'Unknown';
      },
      childRecords: [
        {
          label: 'Start',
          key: 'BreakStart',
          format: value => formatDateTimeMed(value),
        },
        {
          label: 'End',
          key: 'BreakEnd',
          format: value => formatDateTimeMed(value),
        },
      ],
    },
  ];
}
