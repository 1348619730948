import { IAjax } from 'src/infrastructure/ajax';
import { operationsUrls } from 'src/domain/services/apiUrls';
import { Blob } from 'buffer';

type ExportFilters = Operations.Domain.Queries.ContractCharterBooking.ExportContractCharterBookingToExcel.ExportContractCharterBookingToExcelQuery;

export default function init(ajax: IAjax) {
  function listContractCharterBookings(
    query: Partial<
      Operations.Domain.Queries.ContractCharterBooking.ListContractCharterBookings.ListContractCharterBookingsQuery
    >
  ) {
    return ajax
      .get(operationsUrls.contractCharterBookingUrls.listContractCharterBookings(query))
      .map(
        r =>
          r.response as Common.Dtos.ListResult<
            Operations.Domain.Queries.ContractCharterBooking.ListContractCharterBookings.ListContractCharterBookingsQuery
          >
      );
  }

  async function getContractCharterBooking(
    query: Operations.Domain.Queries.ContractCharterBooking.GetContractCharterBooking.GetContractCharterBookingQuery
  ) {
    const result = await ajax
      .get(operationsUrls.contractCharterBookingUrls.getContractCharterBooking(query))
      .toPromise();

    return result.response as Operations.Domain.Queries.ContractCharterBooking.GetContractCharterBooking.ContractCharterBooking;
  }

  function completeContractCharterBooking(
    command: Operations.Domain.Commands.ContractCharterBooking.CompleteContractCharterBooking.CompleteContractCharterBookingCommand
  ) {
    return ajax
      .patch(operationsUrls.contractCharterBookingUrls.completeContractCharterBooking(command))
      .toPromise();
  }

  async function createContractCharterBooking(
    command: Operations.Domain.Commands.ContractCharterBooking.CreateContractCharterBooking.CreateContractCharterBookingCommand
  ) {
    const result = await ajax
      .post(operationsUrls.contractCharterBookingUrls.createContractCharterBooking(), command)
      .toPromise();

    return result.response as string;
  }

  function updateContractCharterBooking(
    command: Operations.Domain.Commands.ContractCharterBooking.UpdateContractCharterBooking.UpdateContractCharterBookingCommand
  ) {
    return ajax
      .put(operationsUrls.contractCharterBookingUrls.updateContractCharterBooking(command), command)
      .toPromise();
  }

  async function exportToExcel(filters: Partial<ExportFilters>) {
    const result = await ajax
      .getFile(operationsUrls.contractCharterBookingUrls.exportToExcel(filters))
      .toPromise();

    return result.response as Blob;
  }

  async function getAvailableJobs(
    query: Operations.Domain.Queries.ContractCharterBooking.GetContractCharterBookingAvailableJobs.GetContractCharterBookingAvailableJobsQuery
  ) {
    const result = await ajax
      .get(operationsUrls.contractCharterBookingUrls.getAvailableJobs(query))
      .toPromise();

    return result.response as Operations.Domain.Queries.ContractCharterBooking.GetContractCharterBookingAvailableJobs.JobItem[];
  }

  return {
    contractCharterBookings: {
      listContractCharterBookings,
      getContractCharterBooking,
      completeContractCharterBooking,
      createContractCharterBooking,
      updateContractCharterBooking,
      exportToExcel,
      getAvailableJobs,
    },
  };
}
