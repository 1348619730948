import { PaneType, ModalDefBuilder, FieldType } from 'src/views/definitionBuilders/types';
import { standardTextSanitisers } from 'src/views/componentBuilders/buildLabelledText';
import * as EmailValidator from 'email-validator';
import { getSubmitCloseModalActionGroupDef } from 'src/views/definitionBuilders/common';

type CreateCustomerCommand = Operations.Domain.Commands.Customer.CreateCustomerCommand;

export default function getCreateCustomerModalDef(
  checkForUniqueCustomerName: (name: string) => Promise<Common.Dtos.UniqueNameCheckResultDto>,
  onCreateCustomer: (command: CreateCustomerCommand) => Promise<string>
): ModalDefBuilder {
  return modalDefApi => ({
    title: 'Create a customer',
    asForm: true,
    panels: [
      {
        panes: [
          {
            paneType: PaneType.formFieldsPane,
            fields: [
              {
                fieldType: FieldType.textField,
                label: 'Name',
                dataAddr: 'name',
                maxLength: 200,
                mandatory: true,
                validateAsync: async d => {
                  if (!d.fieldValue) {
                    return undefined;
                  }
                  const result = await checkForUniqueCustomerName(d.fieldValue);
                  return result.nameExists ? `Name is already in use` : undefined;
                },
              },
            ],
          },
          {
            paneType: PaneType.formFieldsPane,
            columnCount: 2,
            fields: [
              {
                fieldType: FieldType.yesNoField,
                label: 'Does this customer have a trading account?',
                dataAddr: 'hasTradingAccount',
                mandatory: true,
              },
            ],
          },
        ],
      },
      {
        title: 'Organisation Contact Details',
        panes: [
          {
            paneType: PaneType.formFieldsPane,
            columnCount: 2,
            fields: [
              {
                fieldType: FieldType.textField,
                label: 'Phone',
                dataAddr: 'phone',
                maxLength: 20,
                mandatory: true,
                onChange: api =>
                  api.setFormValue(
                    api.fieldDataAddr,
                    standardTextSanitisers.numericSanitiser(api.fieldData.fieldValue as string)
                  ),
              },
              {
                fieldType: FieldType.textField,
                label: 'Fax',
                dataAddr: 'fax',
                maxLength: 20,
                onChange: api =>
                  api.setFormValue(
                    api.fieldDataAddr,
                    standardTextSanitisers.numericSanitiser(api.fieldData.fieldValue as string)
                  ),
              },
            ],
          },
          {
            paneType: PaneType.formFieldsPane,
            fields: [
              {
                fieldType: FieldType.textField,
                label: 'Email',
                dataAddr: 'email',
                maxLength: 200,
                mandatory: true,
                validate: email =>
                  !!email.fieldValue && !EmailValidator.validate(email.fieldValue)
                    ? `Email must be a valid email`
                    : undefined,
              },
              {
                fieldType: FieldType.textField,
                label: 'Website',
                dataAddr: 'website',
                maxLength: 200,
                mandatory: d => d.parentValue.hasTradingAccount,
              },
            ],
          },
        ],
      },
      {
        title: 'Billing address',
        panes: [
          {
            paneType: PaneType.formFieldsPane,
            fields: [
              {
                fieldType: FieldType.textField,
                label: 'Address',
                dataAddr: 'billingAddress',
                maxLength: 200,
                mandatory: d => d.parentValue.hasTradingAccount,
              },
            ],
          },
          {
            paneType: PaneType.formFieldsPane,
            columnCount: 3,
            fields: [
              {
                fieldType: FieldType.textField,
                label: 'City',
                dataAddr: 'billingCity',
                maxLength: 200,
                mandatory: d => d.parentValue.hasTradingAccount,
              },
              {
                fieldType: FieldType.textField,
                label: 'State',
                dataAddr: 'billingState',
                maxLength: 200,
                mandatory: d => d.parentValue.hasTradingAccount,
              },
              {
                fieldType: FieldType.textField,
                label: 'Postcode',
                dataAddr: 'billingPostcode',
                maxLength: 4,
                mandatory: d => d.parentValue.hasTradingAccount,
                onChange: api =>
                  api.setFormValue(
                    api.fieldDataAddr,
                    standardTextSanitisers.numericSanitiser(api.fieldData.fieldValue as string)
                  ),
              },
            ],
          },
        ],
      },
      {
        title: 'Other address',
        panes: [
          {
            paneType: PaneType.formFieldsPane,
            fields: [
              {
                fieldType: FieldType.textField,
                label: 'Address',
                dataAddr: 'otherAddress',
                maxLength: 200,
              },
            ],
          },
          {
            paneType: PaneType.formFieldsPane,
            columnCount: 3,
            fields: [
              {
                fieldType: FieldType.textField,
                label: 'City',
                dataAddr: 'otherCity',
                maxLength: 200,
              },
              {
                fieldType: FieldType.textField,
                label: 'State',
                dataAddr: 'otherState',
                maxLength: 200,
              },
              {
                fieldType: FieldType.textField,
                label: 'Postcode',
                dataAddr: 'otherPostcode',
                maxLength: 4,
                onChange: api =>
                  api.setFormValue(
                    api.fieldDataAddr,
                    standardTextSanitisers.numericSanitiser(api.fieldData.fieldValue as string)
                  ),
              },
            ],
          },
        ],
      },
    ],
    secondaryActions: [getSubmitCloseModalActionGroupDef('Create')],
    onFormSubmit: (values: CreateCustomerCommand) => {
      return onCreateCustomer(values).then(id => {
        modalDefApi.parentFormApi.setValue('customer', {
          customerId: id,
        });
      });
    },
  });
}
