import { types, getRoot, flow } from 'mobx-state-tree';
import { IRootStoreModel } from 'src/domain/entities/RootStoreModel';
import { getAjax } from 'src/domain/services';

type TaskSubcategoryItem = Workshop.Domain.Queries.JobTaskSubcategories.JobTaskSubcategoryItem;
type CreateTaskSubcategoryCommand = Workshop.Domain.Commands.JobTask.CreateTaskSubcategory.CreateTaskSubcategoryCommand;
type UpdateTaskSubcategoryCommand = Workshop.Domain.Commands.JobTask.UpdateTaskSubcategory.UpdateTaskSubcategoryCommand;

export const TaskSubcategoryModel = types
  .model('TaskSubcategoryModel', {
    taskSubcategory: types.maybe(types.frozen<TaskSubcategoryItem>()),
  })
  .actions(self => {
    const ajax = getAjax(self);
    const root = getRoot(self) as IRootStoreModel;

    const createTaskSubcategory = flow(function*(command: Promise<CreateTaskSubcategoryCommand>) {
      const id = yield ajax.workshop.taskSubcategories.createTaskSubcategory(command);
      self.taskSubcategory = undefined;
      root.history.push(`/workshop/task-subcategories/${id}`);
    });

    const checkForUniqueName = flow(function*(id: number, name: string) {
      return yield ajax.workshop.taskSubcategories.checkForUniqueName(id, name);
    });

    const loadTaskSubcategory = flow(function*(taskSubcategoryId: number) {
      self.taskSubcategory = yield ajax.workshop.taskSubcategories.getTaskSubcategory(
        taskSubcategoryId
      );
    });

    const updateTaskSubcategory = flow(function*(commandP: Promise<UpdateTaskSubcategoryCommand>) {
      const command = yield commandP;
      yield ajax.workshop.taskSubcategories.updateTaskSubcategory(command);
      yield loadTaskSubcategory(command.id);
    });

    return {
      createTaskSubcategory,
      checkForUniqueName,
      loadTaskSubcategory,
      updateTaskSubcategory,
    };
  });
