export enum LeaveType {
    AnnualLeave = 1,
    PersonalLeave = 2,
    LeaveWithoutPay = 3,
    LongServiceLeave = 4,
    TimeOffInLieu = 5,
    Fatigue = 6,
    Unavailable = 7
}
export function leaveTypeDescription(value: LeaveType) {
    switch (value) {
        case LeaveType.AnnualLeave:
            return `Annual Leave`;
        case LeaveType.PersonalLeave:
            return `Personal/Sick Leave`;
        case LeaveType.LeaveWithoutPay:
            return `Leave Without Pay`;
        case LeaveType.LongServiceLeave:
            return `Long Service Leave`;
        case LeaveType.TimeOffInLieu:
            return `Time Off In Lieu`;
        case LeaveType.Fatigue:
            return `Fatigue`;
        case LeaveType.Unavailable:
            return `Unavailable`;
    }
}
export interface ILeaveTypeDescriptor { value: LeaveType; name: string; description: string; }
export var allLeaveType: ILeaveTypeDescriptor[] = [
    { value: LeaveType.AnnualLeave, name: `AnnualLeave`, description: `Annual Leave` },
    { value: LeaveType.PersonalLeave, name: `PersonalLeave`, description: `Personal/Sick Leave` },
    { value: LeaveType.LeaveWithoutPay, name: `LeaveWithoutPay`, description: `Leave Without Pay` },
    { value: LeaveType.LongServiceLeave, name: `LongServiceLeave`, description: `Long Service Leave` },
    { value: LeaveType.TimeOffInLieu, name: `TimeOffInLieu`, description: `Time Off In Lieu` },
    { value: LeaveType.Fatigue, name: `Fatigue`, description: `Fatigue` },
    { value: LeaveType.Unavailable, name: `Unavailable`, description: `Unavailable` }
];

export function getLeaveTypeDescriptor(value: LeaveType) {
    switch (value) {
        case LeaveType.AnnualLeave:
            return { value: LeaveType.AnnualLeave, name: `AnnualLeave`, description: `Annual Leave` };
        case LeaveType.PersonalLeave:
            return { value: LeaveType.PersonalLeave, name: `PersonalLeave`, description: `Personal/Sick Leave` };
        case LeaveType.LeaveWithoutPay:
            return { value: LeaveType.LeaveWithoutPay, name: `LeaveWithoutPay`, description: `Leave Without Pay` };
        case LeaveType.LongServiceLeave:
            return { value: LeaveType.LongServiceLeave, name: `LongServiceLeave`, description: `Long Service Leave` };
        case LeaveType.TimeOffInLieu:
            return { value: LeaveType.TimeOffInLieu, name: `TimeOffInLieu`, description: `Time Off In Lieu` };
        case LeaveType.Fatigue:
            return { value: LeaveType.Fatigue, name: `Fatigue`, description: `Fatigue` };
        case LeaveType.Unavailable:
            return { value: LeaveType.Unavailable, name: `Unavailable`, description: `Unavailable` };
    }
}
export enum LeaveStatus {
    Pending = 1,
    Approved = 2,
    Declined = 3
}
export function leaveStatusDescription(value: LeaveStatus) {
    switch (value) {
        case LeaveStatus.Pending:
            return `Pending`;
        case LeaveStatus.Approved:
            return `Approved`;
        case LeaveStatus.Declined:
            return `Declined`;
    }
}
export interface ILeaveStatusDescriptor { value: LeaveStatus; name: string; description: string; }
export var allLeaveStatus: ILeaveStatusDescriptor[] = [
    { value: LeaveStatus.Pending, name: `Pending`, description: `Pending` },
    { value: LeaveStatus.Approved, name: `Approved`, description: `Approved` },
    { value: LeaveStatus.Declined, name: `Declined`, description: `Declined` }
];

export function getLeaveStatusDescriptor(value: LeaveStatus) {
    switch (value) {
        case LeaveStatus.Pending:
            return { value: LeaveStatus.Pending, name: `Pending`, description: `Pending` };
        case LeaveStatus.Approved:
            return { value: LeaveStatus.Approved, name: `Approved`, description: `Approved` };
        case LeaveStatus.Declined:
            return { value: LeaveStatus.Declined, name: `Declined`, description: `Declined` };
    }
}
export enum RolePermission {
    None = 0,
    IsDefaultForDriverAllocation = 1,
    IsSalesPerson = 2,
    IsReportableEventContactPerson = 4,
    IsAfterHoursAlertRecipient = 8
}
export function rolePermissionDescription(value: RolePermission) {
    switch (value) {
        case RolePermission.None:
            return `None`;
        case RolePermission.IsDefaultForDriverAllocation:
            return `Default For Driver Allocations`;
        case RolePermission.IsSalesPerson:
            return `Shown In Sales Person List`;
        case RolePermission.IsReportableEventContactPerson:
            return `Shown In Reportable Event Contact Person List`;
        case RolePermission.IsAfterHoursAlertRecipient:
            return `After Hours Alert Recipient`;
    }
}
export interface IRolePermissionDescriptor { value: RolePermission; name: string; description: string; }
export var allRolePermission: IRolePermissionDescriptor[] = [
    { value: RolePermission.None, name: `None`, description: `None` },
    { value: RolePermission.IsDefaultForDriverAllocation, name: `IsDefaultForDriverAllocation`, description: `Default For Driver Allocations` },
    { value: RolePermission.IsSalesPerson, name: `IsSalesPerson`, description: `Shown In Sales Person List` },
    { value: RolePermission.IsReportableEventContactPerson, name: `IsReportableEventContactPerson`, description: `Shown In Reportable Event Contact Person List` },
    { value: RolePermission.IsAfterHoursAlertRecipient, name: `IsAfterHoursAlertRecipient`, description: `After Hours Alert Recipient` }
];

export function getRolePermissionDescriptor(value: RolePermission) {
    switch (value) {
        case RolePermission.None:
            return { value: RolePermission.None, name: `None`, description: `None` };
        case RolePermission.IsDefaultForDriverAllocation:
            return { value: RolePermission.IsDefaultForDriverAllocation, name: `IsDefaultForDriverAllocation`, description: `Default For Driver Allocations` };
        case RolePermission.IsSalesPerson:
            return { value: RolePermission.IsSalesPerson, name: `IsSalesPerson`, description: `Shown In Sales Person List` };
        case RolePermission.IsReportableEventContactPerson:
            return { value: RolePermission.IsReportableEventContactPerson, name: `IsReportableEventContactPerson`, description: `Shown In Reportable Event Contact Person List` };
        case RolePermission.IsAfterHoursAlertRecipient:
            return { value: RolePermission.IsAfterHoursAlertRecipient, name: `IsAfterHoursAlertRecipient`, description: `After Hours Alert Recipient` };
    }
}
export enum SmsMessageStatus {
    Pending = 0,
    Delivered = 1,
    Failed = 2
}
export function smsMessageStatusDescription(value: SmsMessageStatus) {
    switch (value) {
        case SmsMessageStatus.Pending:
            return `Pending`;
        case SmsMessageStatus.Delivered:
            return `Delivered`;
        case SmsMessageStatus.Failed:
            return `Failed`;
    }
}
export interface ISmsMessageStatusDescriptor { value: SmsMessageStatus; name: string; description: string; }
export var allSmsMessageStatus: ISmsMessageStatusDescriptor[] = [
    { value: SmsMessageStatus.Pending, name: `Pending`, description: `Pending` },
    { value: SmsMessageStatus.Delivered, name: `Delivered`, description: `Delivered` },
    { value: SmsMessageStatus.Failed, name: `Failed`, description: `Failed` }
];

export function getSmsMessageStatusDescriptor(value: SmsMessageStatus) {
    switch (value) {
        case SmsMessageStatus.Pending:
            return { value: SmsMessageStatus.Pending, name: `Pending`, description: `Pending` };
        case SmsMessageStatus.Delivered:
            return { value: SmsMessageStatus.Delivered, name: `Delivered`, description: `Delivered` };
        case SmsMessageStatus.Failed:
            return { value: SmsMessageStatus.Failed, name: `Failed`, description: `Failed` };
    }
}
export enum AuthorisedDeviceApplication {
    Fleet = 1,
    Kiosk = 2
}
export function authorisedDeviceApplicationDescription(value: AuthorisedDeviceApplication) {
    switch (value) {
        case AuthorisedDeviceApplication.Fleet:
            return `Fleet`;
        case AuthorisedDeviceApplication.Kiosk:
            return `Kiosk`;
    }
}
export interface IAuthorisedDeviceApplicationDescriptor { value: AuthorisedDeviceApplication; name: string; description: string; }
export var allAuthorisedDeviceApplication: IAuthorisedDeviceApplicationDescriptor[] = [
    { value: AuthorisedDeviceApplication.Fleet, name: `Fleet`, description: `Fleet` },
    { value: AuthorisedDeviceApplication.Kiosk, name: `Kiosk`, description: `Kiosk` }
];

export function getAuthorisedDeviceApplicationDescriptor(value: AuthorisedDeviceApplication) {
    switch (value) {
        case AuthorisedDeviceApplication.Fleet:
            return { value: AuthorisedDeviceApplication.Fleet, name: `Fleet`, description: `Fleet` };
        case AuthorisedDeviceApplication.Kiosk:
            return { value: AuthorisedDeviceApplication.Kiosk, name: `Kiosk`, description: `Kiosk` };
    }
}
export enum AuthorisedDeviceStatus {
    Unpaired = 1,
    Paired = 2
}
export function authorisedDeviceStatusDescription(value: AuthorisedDeviceStatus) {
    switch (value) {
        case AuthorisedDeviceStatus.Unpaired:
            return `Unpaired`;
        case AuthorisedDeviceStatus.Paired:
            return `Paired`;
    }
}
export interface IAuthorisedDeviceStatusDescriptor { value: AuthorisedDeviceStatus; name: string; description: string; }
export var allAuthorisedDeviceStatus: IAuthorisedDeviceStatusDescriptor[] = [
    { value: AuthorisedDeviceStatus.Unpaired, name: `Unpaired`, description: `Unpaired` },
    { value: AuthorisedDeviceStatus.Paired, name: `Paired`, description: `Paired` }
];

export function getAuthorisedDeviceStatusDescriptor(value: AuthorisedDeviceStatus) {
    switch (value) {
        case AuthorisedDeviceStatus.Unpaired:
            return { value: AuthorisedDeviceStatus.Unpaired, name: `Unpaired`, description: `Unpaired` };
        case AuthorisedDeviceStatus.Paired:
            return { value: AuthorisedDeviceStatus.Paired, name: `Paired`, description: `Paired` };
    }
}
export enum IsoDayOfWeek {
    None = 0,
    Monday = 1,
    Tuesday = 2,
    Wednesday = 3,
    Thursday = 4,
    Friday = 5,
    Saturday = 6,
    Sunday = 7
}
export function isoDayOfWeekDescription(value: IsoDayOfWeek) {
    switch (value) {
        case IsoDayOfWeek.None:
            return `None`;
        case IsoDayOfWeek.Monday:
            return `Monday`;
        case IsoDayOfWeek.Tuesday:
            return `Tuesday`;
        case IsoDayOfWeek.Wednesday:
            return `Wednesday`;
        case IsoDayOfWeek.Thursday:
            return `Thursday`;
        case IsoDayOfWeek.Friday:
            return `Friday`;
        case IsoDayOfWeek.Saturday:
            return `Saturday`;
        case IsoDayOfWeek.Sunday:
            return `Sunday`;
    }
}
export interface IIsoDayOfWeekDescriptor { value: IsoDayOfWeek; name: string; description: string; }
export var allIsoDayOfWeek: IIsoDayOfWeekDescriptor[] = [
    { value: IsoDayOfWeek.None, name: `None`, description: `None` },
    { value: IsoDayOfWeek.Monday, name: `Monday`, description: `Monday` },
    { value: IsoDayOfWeek.Tuesday, name: `Tuesday`, description: `Tuesday` },
    { value: IsoDayOfWeek.Wednesday, name: `Wednesday`, description: `Wednesday` },
    { value: IsoDayOfWeek.Thursday, name: `Thursday`, description: `Thursday` },
    { value: IsoDayOfWeek.Friday, name: `Friday`, description: `Friday` },
    { value: IsoDayOfWeek.Saturday, name: `Saturday`, description: `Saturday` },
    { value: IsoDayOfWeek.Sunday, name: `Sunday`, description: `Sunday` }
];

export function getIsoDayOfWeekDescriptor(value: IsoDayOfWeek) {
    switch (value) {
        case IsoDayOfWeek.None:
            return { value: IsoDayOfWeek.None, name: `None`, description: `None` };
        case IsoDayOfWeek.Monday:
            return { value: IsoDayOfWeek.Monday, name: `Monday`, description: `Monday` };
        case IsoDayOfWeek.Tuesday:
            return { value: IsoDayOfWeek.Tuesday, name: `Tuesday`, description: `Tuesday` };
        case IsoDayOfWeek.Wednesday:
            return { value: IsoDayOfWeek.Wednesday, name: `Wednesday`, description: `Wednesday` };
        case IsoDayOfWeek.Thursday:
            return { value: IsoDayOfWeek.Thursday, name: `Thursday`, description: `Thursday` };
        case IsoDayOfWeek.Friday:
            return { value: IsoDayOfWeek.Friday, name: `Friday`, description: `Friday` };
        case IsoDayOfWeek.Saturday:
            return { value: IsoDayOfWeek.Saturday, name: `Saturday`, description: `Saturday` };
        case IsoDayOfWeek.Sunday:
            return { value: IsoDayOfWeek.Sunday, name: `Sunday`, description: `Sunday` };
    }
}
export enum FatigueRuleType {
    Has_4Days_In_28Days = 0,
    Has_7Hours_In_24Hours = 1,
    Has_24Hours_In_7Days = 2,
    Has_6Nights_In_7Days = 3,
    Has_2Days_Plus2Consecutive_In_14Days = 4,
    Has_10Hours_In_52Hours = 5,
    Has_24Hours_In_7Days_Plus_24hrs_Cumulative = 6,
    Has_5Hours_Stationary_Rest_In_24Hour = 7,
    House_Rule = 8
}
export function fatigueRuleTypeDescription(value: FatigueRuleType) {
    switch (value) {
        case FatigueRuleType.Has_4Days_In_28Days:
            return `28 Day Rule`;
        case FatigueRuleType.Has_7Hours_In_24Hours:
            return `24 Hour Rule`;
        case FatigueRuleType.Has_24Hours_In_7Days:
            return `7 Day Rule`;
        case FatigueRuleType.Has_6Nights_In_7Days:
            return `7 Day Rule`;
        case FatigueRuleType.Has_2Days_Plus2Consecutive_In_14Days:
            return `14 Day Rule`;
        case FatigueRuleType.Has_10Hours_In_52Hours:
            return `52 Hour Rule`;
        case FatigueRuleType.Has_24Hours_In_7Days_Plus_24hrs_Cumulative:
            return `7 Day Rule`;
        case FatigueRuleType.Has_5Hours_Stationary_Rest_In_24Hour:
            return `24 Hour Rule`;
        case FatigueRuleType.House_Rule:
            return `House Rule`;
    }
}
export interface IFatigueRuleTypeDescriptor { value: FatigueRuleType; name: string; description: string; }
export var allFatigueRuleType: IFatigueRuleTypeDescriptor[] = [
    { value: FatigueRuleType.Has_4Days_In_28Days, name: `Has_4Days_In_28Days`, description: `28 Day Rule` },
    { value: FatigueRuleType.Has_7Hours_In_24Hours, name: `Has_7Hours_In_24Hours`, description: `24 Hour Rule` },
    { value: FatigueRuleType.Has_24Hours_In_7Days, name: `Has_24Hours_In_7Days`, description: `7 Day Rule` },
    { value: FatigueRuleType.Has_6Nights_In_7Days, name: `Has_6Nights_In_7Days`, description: `7 Day Rule` },
    { value: FatigueRuleType.Has_2Days_Plus2Consecutive_In_14Days, name: `Has_2Days_Plus2Consecutive_In_14Days`, description: `14 Day Rule` },
    { value: FatigueRuleType.Has_10Hours_In_52Hours, name: `Has_10Hours_In_52Hours`, description: `52 Hour Rule` },
    { value: FatigueRuleType.Has_24Hours_In_7Days_Plus_24hrs_Cumulative, name: `Has_24Hours_In_7Days_Plus_24hrs_Cumulative`, description: `7 Day Rule` },
    { value: FatigueRuleType.Has_5Hours_Stationary_Rest_In_24Hour, name: `Has_5Hours_Stationary_Rest_In_24Hour`, description: `24 Hour Rule` },
    { value: FatigueRuleType.House_Rule, name: `House_Rule`, description: `House Rule` }
];

export function getFatigueRuleTypeDescriptor(value: FatigueRuleType) {
    switch (value) {
        case FatigueRuleType.Has_4Days_In_28Days:
            return { value: FatigueRuleType.Has_4Days_In_28Days, name: `Has_4Days_In_28Days`, description: `28 Day Rule` };
        case FatigueRuleType.Has_7Hours_In_24Hours:
            return { value: FatigueRuleType.Has_7Hours_In_24Hours, name: `Has_7Hours_In_24Hours`, description: `24 Hour Rule` };
        case FatigueRuleType.Has_24Hours_In_7Days:
            return { value: FatigueRuleType.Has_24Hours_In_7Days, name: `Has_24Hours_In_7Days`, description: `7 Day Rule` };
        case FatigueRuleType.Has_6Nights_In_7Days:
            return { value: FatigueRuleType.Has_6Nights_In_7Days, name: `Has_6Nights_In_7Days`, description: `7 Day Rule` };
        case FatigueRuleType.Has_2Days_Plus2Consecutive_In_14Days:
            return { value: FatigueRuleType.Has_2Days_Plus2Consecutive_In_14Days, name: `Has_2Days_Plus2Consecutive_In_14Days`, description: `14 Day Rule` };
        case FatigueRuleType.Has_10Hours_In_52Hours:
            return { value: FatigueRuleType.Has_10Hours_In_52Hours, name: `Has_10Hours_In_52Hours`, description: `52 Hour Rule` };
        case FatigueRuleType.Has_24Hours_In_7Days_Plus_24hrs_Cumulative:
            return { value: FatigueRuleType.Has_24Hours_In_7Days_Plus_24hrs_Cumulative, name: `Has_24Hours_In_7Days_Plus_24hrs_Cumulative`, description: `7 Day Rule` };
        case FatigueRuleType.Has_5Hours_Stationary_Rest_In_24Hour:
            return { value: FatigueRuleType.Has_5Hours_Stationary_Rest_In_24Hour, name: `Has_5Hours_Stationary_Rest_In_24Hour`, description: `24 Hour Rule` };
        case FatigueRuleType.House_Rule:
            return { value: FatigueRuleType.House_Rule, name: `House_Rule`, description: `House Rule` };
    }
}
export enum FatigueBreachEventStatus {
    Open = 1,
    Closed = 2,
    Invalid = 3
}
export function fatigueBreachEventStatusDescription(value: FatigueBreachEventStatus) {
    switch (value) {
        case FatigueBreachEventStatus.Open:
            return `Open`;
        case FatigueBreachEventStatus.Closed:
            return `Closed`;
        case FatigueBreachEventStatus.Invalid:
            return `Invalid`;
    }
}
export interface IFatigueBreachEventStatusDescriptor { value: FatigueBreachEventStatus; name: string; description: string; }
export var allFatigueBreachEventStatus: IFatigueBreachEventStatusDescriptor[] = [
    { value: FatigueBreachEventStatus.Open, name: `Open`, description: `Open` },
    { value: FatigueBreachEventStatus.Closed, name: `Closed`, description: `Closed` },
    { value: FatigueBreachEventStatus.Invalid, name: `Invalid`, description: `Invalid` }
];

export function getFatigueBreachEventStatusDescriptor(value: FatigueBreachEventStatus) {
    switch (value) {
        case FatigueBreachEventStatus.Open:
            return { value: FatigueBreachEventStatus.Open, name: `Open`, description: `Open` };
        case FatigueBreachEventStatus.Closed:
            return { value: FatigueBreachEventStatus.Closed, name: `Closed`, description: `Closed` };
        case FatigueBreachEventStatus.Invalid:
            return { value: FatigueBreachEventStatus.Invalid, name: `Invalid`, description: `Invalid` };
    }
}
export enum FatigueRuleSetType {
    Standard = 1,
    StandardBus = 2,
    TwoUpDrivers = 3
}
export function fatigueRuleSetTypeDescription(value: FatigueRuleSetType) {
    switch (value) {
        case FatigueRuleSetType.Standard:
            return `Standard solo`;
        case FatigueRuleSetType.StandardBus:
            return `Standard Bus`;
        case FatigueRuleSetType.TwoUpDrivers:
            return `Two-up drivers`;
    }
}
export interface IFatigueRuleSetTypeDescriptor { value: FatigueRuleSetType; name: string; description: string; }
export var allFatigueRuleSetType: IFatigueRuleSetTypeDescriptor[] = [
    { value: FatigueRuleSetType.Standard, name: `Standard`, description: `Standard solo` },
    { value: FatigueRuleSetType.StandardBus, name: `StandardBus`, description: `Standard Bus` },
    { value: FatigueRuleSetType.TwoUpDrivers, name: `TwoUpDrivers`, description: `Two-up drivers` }
];

export function getFatigueRuleSetTypeDescriptor(value: FatigueRuleSetType) {
    switch (value) {
        case FatigueRuleSetType.Standard:
            return { value: FatigueRuleSetType.Standard, name: `Standard`, description: `Standard solo` };
        case FatigueRuleSetType.StandardBus:
            return { value: FatigueRuleSetType.StandardBus, name: `StandardBus`, description: `Standard Bus` };
        case FatigueRuleSetType.TwoUpDrivers:
            return { value: FatigueRuleSetType.TwoUpDrivers, name: `TwoUpDrivers`, description: `Two-up drivers` };
    }
}
export enum BreachSeverityLevelType {
    Unknown = 0,
    Minor = 1,
    Substantial = 2,
    Severe = 3,
    Critical = 4
}
export function breachSeverityLevelTypeDescription(value: BreachSeverityLevelType) {
    switch (value) {
        case BreachSeverityLevelType.Unknown:
            return `Unknown`;
        case BreachSeverityLevelType.Minor:
            return `Minor`;
        case BreachSeverityLevelType.Substantial:
            return `Substantial`;
        case BreachSeverityLevelType.Severe:
            return `Severe`;
        case BreachSeverityLevelType.Critical:
            return `Critical`;
    }
}
export interface IBreachSeverityLevelTypeDescriptor { value: BreachSeverityLevelType; name: string; description: string; }
export var allBreachSeverityLevelType: IBreachSeverityLevelTypeDescriptor[] = [
    { value: BreachSeverityLevelType.Unknown, name: `Unknown`, description: `Unknown` },
    { value: BreachSeverityLevelType.Minor, name: `Minor`, description: `Minor` },
    { value: BreachSeverityLevelType.Substantial, name: `Substantial`, description: `Substantial` },
    { value: BreachSeverityLevelType.Severe, name: `Severe`, description: `Severe` },
    { value: BreachSeverityLevelType.Critical, name: `Critical`, description: `Critical` }
];

export function getBreachSeverityLevelTypeDescriptor(value: BreachSeverityLevelType) {
    switch (value) {
        case BreachSeverityLevelType.Unknown:
            return { value: BreachSeverityLevelType.Unknown, name: `Unknown`, description: `Unknown` };
        case BreachSeverityLevelType.Minor:
            return { value: BreachSeverityLevelType.Minor, name: `Minor`, description: `Minor` };
        case BreachSeverityLevelType.Substantial:
            return { value: BreachSeverityLevelType.Substantial, name: `Substantial`, description: `Substantial` };
        case BreachSeverityLevelType.Severe:
            return { value: BreachSeverityLevelType.Severe, name: `Severe`, description: `Severe` };
        case BreachSeverityLevelType.Critical:
            return { value: BreachSeverityLevelType.Critical, name: `Critical`, description: `Critical` };
    }
}
export enum ProgressId {
    NotStarted = 0,
    ChecksCompleted = 1,
    RelocatingToSite = 2,
    OnSite = 3,
    InProgress = 4,
    OnBreak = 5,
    RelocatingToDepot = 6,
    Finished = 7,
    CancelledInProgress = 8
}
export function progressIdDescription(value: ProgressId) {
    switch (value) {
        case ProgressId.NotStarted:
            return `Not Started`;
        case ProgressId.ChecksCompleted:
            return `Checks Completed`;
        case ProgressId.RelocatingToSite:
            return `Relocating To Site`;
        case ProgressId.OnSite:
            return `On Site`;
        case ProgressId.InProgress:
            return `In Progress`;
        case ProgressId.OnBreak:
            return `On Break`;
        case ProgressId.RelocatingToDepot:
            return `Relocating To Depot`;
        case ProgressId.Finished:
            return `Finished`;
        case ProgressId.CancelledInProgress:
            return `Cancelled in progress`;
    }
}
export interface IProgressIdDescriptor { value: ProgressId; name: string; description: string; }
export var allProgressId: IProgressIdDescriptor[] = [
    { value: ProgressId.NotStarted, name: `NotStarted`, description: `Not Started` },
    { value: ProgressId.ChecksCompleted, name: `ChecksCompleted`, description: `Checks Completed` },
    { value: ProgressId.RelocatingToSite, name: `RelocatingToSite`, description: `Relocating To Site` },
    { value: ProgressId.OnSite, name: `OnSite`, description: `On Site` },
    { value: ProgressId.InProgress, name: `InProgress`, description: `In Progress` },
    { value: ProgressId.OnBreak, name: `OnBreak`, description: `On Break` },
    { value: ProgressId.RelocatingToDepot, name: `RelocatingToDepot`, description: `Relocating To Depot` },
    { value: ProgressId.Finished, name: `Finished`, description: `Finished` },
    { value: ProgressId.CancelledInProgress, name: `CancelledInProgress`, description: `Cancelled in progress` }
];

export function getProgressIdDescriptor(value: ProgressId) {
    switch (value) {
        case ProgressId.NotStarted:
            return { value: ProgressId.NotStarted, name: `NotStarted`, description: `Not Started` };
        case ProgressId.ChecksCompleted:
            return { value: ProgressId.ChecksCompleted, name: `ChecksCompleted`, description: `Checks Completed` };
        case ProgressId.RelocatingToSite:
            return { value: ProgressId.RelocatingToSite, name: `RelocatingToSite`, description: `Relocating To Site` };
        case ProgressId.OnSite:
            return { value: ProgressId.OnSite, name: `OnSite`, description: `On Site` };
        case ProgressId.InProgress:
            return { value: ProgressId.InProgress, name: `InProgress`, description: `In Progress` };
        case ProgressId.OnBreak:
            return { value: ProgressId.OnBreak, name: `OnBreak`, description: `On Break` };
        case ProgressId.RelocatingToDepot:
            return { value: ProgressId.RelocatingToDepot, name: `RelocatingToDepot`, description: `Relocating To Depot` };
        case ProgressId.Finished:
            return { value: ProgressId.Finished, name: `Finished`, description: `Finished` };
        case ProgressId.CancelledInProgress:
            return { value: ProgressId.CancelledInProgress, name: `CancelledInProgress`, description: `Cancelled in progress` };
    }
}
export enum RowType {
    Unknown = 0,
    Ops = 1,
    Workshop = 2,
    OdometerRollBack = 3
}
export function rowTypeDescription(value: RowType) {
    switch (value) {
        case RowType.Unknown:
            return `Unknown`;
        case RowType.Ops:
            return `Ops`;
        case RowType.Workshop:
            return `Workshop`;
        case RowType.OdometerRollBack:
            return `OdometerRollBack`;
    }
}
export interface IRowTypeDescriptor { value: RowType; name: string; description: string; }
export var allRowType: IRowTypeDescriptor[] = [
    { value: RowType.Unknown, name: `Unknown`, description: `Unknown` },
    { value: RowType.Ops, name: `Ops`, description: `Ops` },
    { value: RowType.Workshop, name: `Workshop`, description: `Workshop` },
    { value: RowType.OdometerRollBack, name: `OdometerRollBack`, description: `OdometerRollBack` }
];

export function getRowTypeDescriptor(value: RowType) {
    switch (value) {
        case RowType.Unknown:
            return { value: RowType.Unknown, name: `Unknown`, description: `Unknown` };
        case RowType.Ops:
            return { value: RowType.Ops, name: `Ops`, description: `Ops` };
        case RowType.Workshop:
            return { value: RowType.Workshop, name: `Workshop`, description: `Workshop` };
        case RowType.OdometerRollBack:
            return { value: RowType.OdometerRollBack, name: `OdometerRollBack`, description: `OdometerRollBack` };
    }
}
export enum CctvRequestStatus {
    Open = 1,
    Closed = 2
}
export function cctvRequestStatusDescription(value: CctvRequestStatus) {
    switch (value) {
        case CctvRequestStatus.Open:
            return `Open`;
        case CctvRequestStatus.Closed:
            return `Closed`;
    }
}
export interface ICctvRequestStatusDescriptor { value: CctvRequestStatus; name: string; description: string; }
export var allCctvRequestStatus: ICctvRequestStatusDescriptor[] = [
    { value: CctvRequestStatus.Open, name: `Open`, description: `Open` },
    { value: CctvRequestStatus.Closed, name: `Closed`, description: `Closed` }
];

export function getCctvRequestStatusDescriptor(value: CctvRequestStatus) {
    switch (value) {
        case CctvRequestStatus.Open:
            return { value: CctvRequestStatus.Open, name: `Open`, description: `Open` };
        case CctvRequestStatus.Closed:
            return { value: CctvRequestStatus.Closed, name: `Closed`, description: `Closed` };
    }
}
export enum CustomerComplaintStatus {
    Open = 0,
    Closed = 1
}
export function customerComplaintStatusDescription(value: CustomerComplaintStatus) {
    switch (value) {
        case CustomerComplaintStatus.Open:
            return `Open`;
        case CustomerComplaintStatus.Closed:
            return `Closed`;
    }
}
export interface ICustomerComplaintStatusDescriptor { value: CustomerComplaintStatus; name: string; description: string; }
export var allCustomerComplaintStatus: ICustomerComplaintStatusDescriptor[] = [
    { value: CustomerComplaintStatus.Open, name: `Open`, description: `Open` },
    { value: CustomerComplaintStatus.Closed, name: `Closed`, description: `Closed` }
];

export function getCustomerComplaintStatusDescriptor(value: CustomerComplaintStatus) {
    switch (value) {
        case CustomerComplaintStatus.Open:
            return { value: CustomerComplaintStatus.Open, name: `Open`, description: `Open` };
        case CustomerComplaintStatus.Closed:
            return { value: CustomerComplaintStatus.Closed, name: `Closed`, description: `Closed` };
    }
}
export enum ProgressWarningReason {
    NotFinishedOnTablet = 1,
    FinishedAheadOfTime = 2,
    LateRelocatingToSite = 3,
    ZeroKilometresTravelled = 4,
    LateInProgress = 5
}
export function progressWarningReasonDescription(value: ProgressWarningReason) {
    switch (value) {
        case ProgressWarningReason.NotFinishedOnTablet:
            return `Not Finished on tablet`;
        case ProgressWarningReason.FinishedAheadOfTime:
            return `Finished ahead of time`;
        case ProgressWarningReason.LateRelocatingToSite:
            return `Late relocating to site`;
        case ProgressWarningReason.ZeroKilometresTravelled:
            return `Zero kilometres travelled`;
        case ProgressWarningReason.LateInProgress:
            return `Late in progress`;
    }
}
export interface IProgressWarningReasonDescriptor { value: ProgressWarningReason; name: string; description: string; }
export var allProgressWarningReason: IProgressWarningReasonDescriptor[] = [
    { value: ProgressWarningReason.NotFinishedOnTablet, name: `NotFinishedOnTablet`, description: `Not Finished on tablet` },
    { value: ProgressWarningReason.FinishedAheadOfTime, name: `FinishedAheadOfTime`, description: `Finished ahead of time` },
    { value: ProgressWarningReason.LateRelocatingToSite, name: `LateRelocatingToSite`, description: `Late relocating to site` },
    { value: ProgressWarningReason.ZeroKilometresTravelled, name: `ZeroKilometresTravelled`, description: `Zero kilometres travelled` },
    { value: ProgressWarningReason.LateInProgress, name: `LateInProgress`, description: `Late in progress` }
];

export function getProgressWarningReasonDescriptor(value: ProgressWarningReason) {
    switch (value) {
        case ProgressWarningReason.NotFinishedOnTablet:
            return { value: ProgressWarningReason.NotFinishedOnTablet, name: `NotFinishedOnTablet`, description: `Not Finished on tablet` };
        case ProgressWarningReason.FinishedAheadOfTime:
            return { value: ProgressWarningReason.FinishedAheadOfTime, name: `FinishedAheadOfTime`, description: `Finished ahead of time` };
        case ProgressWarningReason.LateRelocatingToSite:
            return { value: ProgressWarningReason.LateRelocatingToSite, name: `LateRelocatingToSite`, description: `Late relocating to site` };
        case ProgressWarningReason.ZeroKilometresTravelled:
            return { value: ProgressWarningReason.ZeroKilometresTravelled, name: `ZeroKilometresTravelled`, description: `Zero kilometres travelled` };
        case ProgressWarningReason.LateInProgress:
            return { value: ProgressWarningReason.LateInProgress, name: `LateInProgress`, description: `Late in progress` };
    }
}
export enum LostOrFound {
    Lost = 0,
    Found = 1
}
export function lostOrFoundDescription(value: LostOrFound) {
    switch (value) {
        case LostOrFound.Lost:
            return `Lost`;
        case LostOrFound.Found:
            return `Found`;
    }
}
export interface ILostOrFoundDescriptor { value: LostOrFound; name: string; description: string; }
export var allLostOrFound: ILostOrFoundDescriptor[] = [
    { value: LostOrFound.Lost, name: `Lost`, description: `Lost` },
    { value: LostOrFound.Found, name: `Found`, description: `Found` }
];

export function getLostOrFoundDescriptor(value: LostOrFound) {
    switch (value) {
        case LostOrFound.Lost:
            return { value: LostOrFound.Lost, name: `Lost`, description: `Lost` };
        case LostOrFound.Found:
            return { value: LostOrFound.Found, name: `Found`, description: `Found` };
    }
}
export enum LostPropertyItemStatus {
    OnHand = 0,
    Reported = 1,
    Collected = 2,
    Disposed = 3
}
export function lostPropertyItemStatusDescription(value: LostPropertyItemStatus) {
    switch (value) {
        case LostPropertyItemStatus.OnHand:
            return `On Hand`;
        case LostPropertyItemStatus.Reported:
            return `Reported`;
        case LostPropertyItemStatus.Collected:
            return `Collected`;
        case LostPropertyItemStatus.Disposed:
            return `Disposed`;
    }
}
export interface ILostPropertyItemStatusDescriptor { value: LostPropertyItemStatus; name: string; description: string; }
export var allLostPropertyItemStatus: ILostPropertyItemStatusDescriptor[] = [
    { value: LostPropertyItemStatus.OnHand, name: `OnHand`, description: `On Hand` },
    { value: LostPropertyItemStatus.Reported, name: `Reported`, description: `Reported` },
    { value: LostPropertyItemStatus.Collected, name: `Collected`, description: `Collected` },
    { value: LostPropertyItemStatus.Disposed, name: `Disposed`, description: `Disposed` }
];

export function getLostPropertyItemStatusDescriptor(value: LostPropertyItemStatus) {
    switch (value) {
        case LostPropertyItemStatus.OnHand:
            return { value: LostPropertyItemStatus.OnHand, name: `OnHand`, description: `On Hand` };
        case LostPropertyItemStatus.Reported:
            return { value: LostPropertyItemStatus.Reported, name: `Reported`, description: `Reported` };
        case LostPropertyItemStatus.Collected:
            return { value: LostPropertyItemStatus.Collected, name: `Collected`, description: `Collected` };
        case LostPropertyItemStatus.Disposed:
            return { value: LostPropertyItemStatus.Disposed, name: `Disposed`, description: `Disposed` };
    }
}
export enum DisposalType {
    Recycled = 0,
    Garbage = 1,
    OpShop = 2
}
export function disposalTypeDescription(value: DisposalType) {
    switch (value) {
        case DisposalType.Recycled:
            return `Recycled`;
        case DisposalType.Garbage:
            return `Garbage`;
        case DisposalType.OpShop:
            return `Op Shop`;
    }
}
export interface IDisposalTypeDescriptor { value: DisposalType; name: string; description: string; }
export var allDisposalType: IDisposalTypeDescriptor[] = [
    { value: DisposalType.Recycled, name: `Recycled`, description: `Recycled` },
    { value: DisposalType.Garbage, name: `Garbage`, description: `Garbage` },
    { value: DisposalType.OpShop, name: `OpShop`, description: `Op Shop` }
];

export function getDisposalTypeDescriptor(value: DisposalType) {
    switch (value) {
        case DisposalType.Recycled:
            return { value: DisposalType.Recycled, name: `Recycled`, description: `Recycled` };
        case DisposalType.Garbage:
            return { value: DisposalType.Garbage, name: `Garbage`, description: `Garbage` };
        case DisposalType.OpShop:
            return { value: DisposalType.OpShop, name: `OpShop`, description: `Op Shop` };
    }
}
export enum RailBookingStatus {
    Booked = 1,
    Completed = 2,
    Cancelled = 3
}
export function railBookingStatusDescription(value: RailBookingStatus) {
    switch (value) {
        case RailBookingStatus.Booked:
            return `Booked`;
        case RailBookingStatus.Completed:
            return `Completed`;
        case RailBookingStatus.Cancelled:
            return `Cancelled`;
    }
}
export interface IRailBookingStatusDescriptor { value: RailBookingStatus; name: string; description: string; }
export var allRailBookingStatus: IRailBookingStatusDescriptor[] = [
    { value: RailBookingStatus.Booked, name: `Booked`, description: `Booked` },
    { value: RailBookingStatus.Completed, name: `Completed`, description: `Completed` },
    { value: RailBookingStatus.Cancelled, name: `Cancelled`, description: `Cancelled` }
];

export function getRailBookingStatusDescriptor(value: RailBookingStatus) {
    switch (value) {
        case RailBookingStatus.Booked:
            return { value: RailBookingStatus.Booked, name: `Booked`, description: `Booked` };
        case RailBookingStatus.Completed:
            return { value: RailBookingStatus.Completed, name: `Completed`, description: `Completed` };
        case RailBookingStatus.Cancelled:
            return { value: RailBookingStatus.Cancelled, name: `Cancelled`, description: `Cancelled` };
    }
}
export enum ReportableEventStatus {
    New = 1,
    Amended = 2,
    Removed = 3
}
export function reportableEventStatusDescription(value: ReportableEventStatus) {
    switch (value) {
        case ReportableEventStatus.New:
            return `New`;
        case ReportableEventStatus.Amended:
            return `Amended`;
        case ReportableEventStatus.Removed:
            return `Removed`;
    }
}
export interface IReportableEventStatusDescriptor { value: ReportableEventStatus; name: string; description: string; }
export var allReportableEventStatus: IReportableEventStatusDescriptor[] = [
    { value: ReportableEventStatus.New, name: `New`, description: `New` },
    { value: ReportableEventStatus.Amended, name: `Amended`, description: `Amended` },
    { value: ReportableEventStatus.Removed, name: `Removed`, description: `Removed` }
];

export function getReportableEventStatusDescriptor(value: ReportableEventStatus) {
    switch (value) {
        case ReportableEventStatus.New:
            return { value: ReportableEventStatus.New, name: `New`, description: `New` };
        case ReportableEventStatus.Amended:
            return { value: ReportableEventStatus.Amended, name: `Amended`, description: `Amended` };
        case ReportableEventStatus.Removed:
            return { value: ReportableEventStatus.Removed, name: `Removed`, description: `Removed` };
    }
}
export enum TimesheetStatus {
    Submitted = 1,
    Approved = 2,
    Processed = 3,
    New = 4
}
export function timesheetStatusDescription(value: TimesheetStatus) {
    switch (value) {
        case TimesheetStatus.Submitted:
            return `Submitted`;
        case TimesheetStatus.Approved:
            return `Approved`;
        case TimesheetStatus.Processed:
            return `Processed`;
        case TimesheetStatus.New:
            return `New`;
    }
}
export interface ITimesheetStatusDescriptor { value: TimesheetStatus; name: string; description: string; }
export var allTimesheetStatus: ITimesheetStatusDescriptor[] = [
    { value: TimesheetStatus.Submitted, name: `Submitted`, description: `Submitted` },
    { value: TimesheetStatus.Approved, name: `Approved`, description: `Approved` },
    { value: TimesheetStatus.Processed, name: `Processed`, description: `Processed` },
    { value: TimesheetStatus.New, name: `New`, description: `New` }
];

export function getTimesheetStatusDescriptor(value: TimesheetStatus) {
    switch (value) {
        case TimesheetStatus.Submitted:
            return { value: TimesheetStatus.Submitted, name: `Submitted`, description: `Submitted` };
        case TimesheetStatus.Approved:
            return { value: TimesheetStatus.Approved, name: `Approved`, description: `Approved` };
        case TimesheetStatus.Processed:
            return { value: TimesheetStatus.Processed, name: `Processed`, description: `Processed` };
        case TimesheetStatus.New:
            return { value: TimesheetStatus.New, name: `New`, description: `New` };
    }
}
export enum DataFuelStatus {
    New = 1,
    Inserted = 2,
    Ignored = 3,
    Failed = 4,
    Duplicate = 5
}
export function dataFuelStatusDescription(value: DataFuelStatus) {
    switch (value) {
        case DataFuelStatus.New:
            return `New`;
        case DataFuelStatus.Inserted:
            return `Inserted`;
        case DataFuelStatus.Ignored:
            return `Ignored`;
        case DataFuelStatus.Failed:
            return `Failed`;
        case DataFuelStatus.Duplicate:
            return `Duplicate`;
    }
}
export interface IDataFuelStatusDescriptor { value: DataFuelStatus; name: string; description: string; }
export var allDataFuelStatus: IDataFuelStatusDescriptor[] = [
    { value: DataFuelStatus.New, name: `New`, description: `New` },
    { value: DataFuelStatus.Inserted, name: `Inserted`, description: `Inserted` },
    { value: DataFuelStatus.Ignored, name: `Ignored`, description: `Ignored` },
    { value: DataFuelStatus.Failed, name: `Failed`, description: `Failed` },
    { value: DataFuelStatus.Duplicate, name: `Duplicate`, description: `Duplicate` }
];

export function getDataFuelStatusDescriptor(value: DataFuelStatus) {
    switch (value) {
        case DataFuelStatus.New:
            return { value: DataFuelStatus.New, name: `New`, description: `New` };
        case DataFuelStatus.Inserted:
            return { value: DataFuelStatus.Inserted, name: `Inserted`, description: `Inserted` };
        case DataFuelStatus.Ignored:
            return { value: DataFuelStatus.Ignored, name: `Ignored`, description: `Ignored` };
        case DataFuelStatus.Failed:
            return { value: DataFuelStatus.Failed, name: `Failed`, description: `Failed` };
        case DataFuelStatus.Duplicate:
            return { value: DataFuelStatus.Duplicate, name: `Duplicate`, description: `Duplicate` };
    }
}
export enum UploadStatus {
    Processed = 1,
    Failed = 2,
    ManuallyFixed = 3
}
export function uploadStatusDescription(value: UploadStatus) {
    switch (value) {
        case UploadStatus.Processed:
            return `Processed`;
        case UploadStatus.Failed:
            return `Failed`;
        case UploadStatus.ManuallyFixed:
            return `Manually Fixed`;
    }
}
export interface IUploadStatusDescriptor { value: UploadStatus; name: string; description: string; }
export var allUploadStatus: IUploadStatusDescriptor[] = [
    { value: UploadStatus.Processed, name: `Processed`, description: `Processed` },
    { value: UploadStatus.Failed, name: `Failed`, description: `Failed` },
    { value: UploadStatus.ManuallyFixed, name: `ManuallyFixed`, description: `Manually Fixed` }
];

export function getUploadStatusDescriptor(value: UploadStatus) {
    switch (value) {
        case UploadStatus.Processed:
            return { value: UploadStatus.Processed, name: `Processed`, description: `Processed` };
        case UploadStatus.Failed:
            return { value: UploadStatus.Failed, name: `Failed`, description: `Failed` };
        case UploadStatus.ManuallyFixed:
            return { value: UploadStatus.ManuallyFixed, name: `ManuallyFixed`, description: `Manually Fixed` };
    }
}
export enum UploadType {
    Manual = 1,
    Auto = 2
}
export function uploadTypeDescription(value: UploadType) {
    switch (value) {
        case UploadType.Manual:
            return `Manual`;
        case UploadType.Auto:
            return `Auto`;
    }
}
export interface IUploadTypeDescriptor { value: UploadType; name: string; description: string; }
export var allUploadType: IUploadTypeDescriptor[] = [
    { value: UploadType.Manual, name: `Manual`, description: `Manual` },
    { value: UploadType.Auto, name: `Auto`, description: `Auto` }
];

export function getUploadTypeDescriptor(value: UploadType) {
    switch (value) {
        case UploadType.Manual:
            return { value: UploadType.Manual, name: `Manual`, description: `Manual` };
        case UploadType.Auto:
            return { value: UploadType.Auto, name: `Auto`, description: `Auto` };
    }
}
export enum TransactionType {
    Purchased = 0,
    Returned = 1,
    Used = 2,
    Adjustment = 3
}
export function transactionTypeDescription(value: TransactionType) {
    switch (value) {
        case TransactionType.Purchased:
            return `Purchased`;
        case TransactionType.Returned:
            return `Returned`;
        case TransactionType.Used:
            return `Used`;
        case TransactionType.Adjustment:
            return `Adjustment`;
    }
}
export interface ITransactionTypeDescriptor { value: TransactionType; name: string; description: string; }
export var allTransactionType: ITransactionTypeDescriptor[] = [
    { value: TransactionType.Purchased, name: `Purchased`, description: `Purchased` },
    { value: TransactionType.Returned, name: `Returned`, description: `Returned` },
    { value: TransactionType.Used, name: `Used`, description: `Used` },
    { value: TransactionType.Adjustment, name: `Adjustment`, description: `Adjustment` }
];

export function getTransactionTypeDescriptor(value: TransactionType) {
    switch (value) {
        case TransactionType.Purchased:
            return { value: TransactionType.Purchased, name: `Purchased`, description: `Purchased` };
        case TransactionType.Returned:
            return { value: TransactionType.Returned, name: `Returned`, description: `Returned` };
        case TransactionType.Used:
            return { value: TransactionType.Used, name: `Used`, description: `Used` };
        case TransactionType.Adjustment:
            return { value: TransactionType.Adjustment, name: `Adjustment`, description: `Adjustment` };
    }
}
export enum PurchaseTransactionType {
    Invoice = 0,
    CreditNote = 1
}
export function purchaseTransactionTypeDescription(value: PurchaseTransactionType) {
    switch (value) {
        case PurchaseTransactionType.Invoice:
            return `Invoice`;
        case PurchaseTransactionType.CreditNote:
            return `Credit Note`;
    }
}
export interface IPurchaseTransactionTypeDescriptor { value: PurchaseTransactionType; name: string; description: string; }
export var allPurchaseTransactionType: IPurchaseTransactionTypeDescriptor[] = [
    { value: PurchaseTransactionType.Invoice, name: `Invoice`, description: `Invoice` },
    { value: PurchaseTransactionType.CreditNote, name: `CreditNote`, description: `Credit Note` }
];

export function getPurchaseTransactionTypeDescriptor(value: PurchaseTransactionType) {
    switch (value) {
        case PurchaseTransactionType.Invoice:
            return { value: PurchaseTransactionType.Invoice, name: `Invoice`, description: `Invoice` };
        case PurchaseTransactionType.CreditNote:
            return { value: PurchaseTransactionType.CreditNote, name: `CreditNote`, description: `Credit Note` };
    }
}
export enum ShiftProgressStatus {
    NotTracked = 1,
    ClockOffInTheFuture = 2,
    IncompleteShift = 3,
    CompleteShift = 4
}
export function shiftProgressStatusDescription(value: ShiftProgressStatus) {
    switch (value) {
        case ShiftProgressStatus.NotTracked:
            return `Not tracked`;
        case ShiftProgressStatus.ClockOffInTheFuture:
            return `Clock off is in the future`;
        case ShiftProgressStatus.IncompleteShift:
            return `Incomplete Shift`;
        case ShiftProgressStatus.CompleteShift:
            return `Complete Shift`;
    }
}
export interface IShiftProgressStatusDescriptor { value: ShiftProgressStatus; name: string; description: string; }
export var allShiftProgressStatus: IShiftProgressStatusDescriptor[] = [
    { value: ShiftProgressStatus.NotTracked, name: `NotTracked`, description: `Not tracked` },
    { value: ShiftProgressStatus.ClockOffInTheFuture, name: `ClockOffInTheFuture`, description: `Clock off is in the future` },
    { value: ShiftProgressStatus.IncompleteShift, name: `IncompleteShift`, description: `Incomplete Shift` },
    { value: ShiftProgressStatus.CompleteShift, name: `CompleteShift`, description: `Complete Shift` }
];

export function getShiftProgressStatusDescriptor(value: ShiftProgressStatus) {
    switch (value) {
        case ShiftProgressStatus.NotTracked:
            return { value: ShiftProgressStatus.NotTracked, name: `NotTracked`, description: `Not tracked` };
        case ShiftProgressStatus.ClockOffInTheFuture:
            return { value: ShiftProgressStatus.ClockOffInTheFuture, name: `ClockOffInTheFuture`, description: `Clock off is in the future` };
        case ShiftProgressStatus.IncompleteShift:
            return { value: ShiftProgressStatus.IncompleteShift, name: `IncompleteShift`, description: `Incomplete Shift` };
        case ShiftProgressStatus.CompleteShift:
            return { value: ShiftProgressStatus.CompleteShift, name: `CompleteShift`, description: `Complete Shift` };
    }
}
export enum WorkshopShiftType {
    Regular = 1,
    OnCall = 2
}
export function workshopShiftTypeDescription(value: WorkshopShiftType) {
    switch (value) {
        case WorkshopShiftType.Regular:
            return `Regular`;
        case WorkshopShiftType.OnCall:
            return `On Call`;
    }
}
export interface IWorkshopShiftTypeDescriptor { value: WorkshopShiftType; name: string; description: string; }
export var allWorkshopShiftType: IWorkshopShiftTypeDescriptor[] = [
    { value: WorkshopShiftType.Regular, name: `Regular`, description: `Regular` },
    { value: WorkshopShiftType.OnCall, name: `OnCall`, description: `On Call` }
];

export function getWorkshopShiftTypeDescriptor(value: WorkshopShiftType) {
    switch (value) {
        case WorkshopShiftType.Regular:
            return { value: WorkshopShiftType.Regular, name: `Regular`, description: `Regular` };
        case WorkshopShiftType.OnCall:
            return { value: WorkshopShiftType.OnCall, name: `OnCall`, description: `On Call` };
    }
}
export enum OnCallPaymentStatus {
    NotApplicable = 1,
    Payable = 2,
    PaidOnPreviousDay = 3
}
export function onCallPaymentStatusDescription(value: OnCallPaymentStatus) {
    switch (value) {
        case OnCallPaymentStatus.NotApplicable:
            return `Not Applicable`;
        case OnCallPaymentStatus.Payable:
            return `Payable`;
        case OnCallPaymentStatus.PaidOnPreviousDay:
            return `Paid on previous day`;
    }
}
export interface IOnCallPaymentStatusDescriptor { value: OnCallPaymentStatus; name: string; description: string; }
export var allOnCallPaymentStatus: IOnCallPaymentStatusDescriptor[] = [
    { value: OnCallPaymentStatus.NotApplicable, name: `NotApplicable`, description: `Not Applicable` },
    { value: OnCallPaymentStatus.Payable, name: `Payable`, description: `Payable` },
    { value: OnCallPaymentStatus.PaidOnPreviousDay, name: `PaidOnPreviousDay`, description: `Paid on previous day` }
];

export function getOnCallPaymentStatusDescriptor(value: OnCallPaymentStatus) {
    switch (value) {
        case OnCallPaymentStatus.NotApplicable:
            return { value: OnCallPaymentStatus.NotApplicable, name: `NotApplicable`, description: `Not Applicable` };
        case OnCallPaymentStatus.Payable:
            return { value: OnCallPaymentStatus.Payable, name: `Payable`, description: `Payable` };
        case OnCallPaymentStatus.PaidOnPreviousDay:
            return { value: OnCallPaymentStatus.PaidOnPreviousDay, name: `PaidOnPreviousDay`, description: `Paid on previous day` };
    }
}
export enum CostGroupEntryType {
    Job = 1,
    Leave = 2,
    PublicHoliday = 3,
    WorkshopShift = 4,
    WorkshopOnCall = 5
}
export function costGroupEntryTypeDescription(value: CostGroupEntryType) {
    switch (value) {
        case CostGroupEntryType.Job:
            return `Job`;
        case CostGroupEntryType.Leave:
            return `Leave`;
        case CostGroupEntryType.PublicHoliday:
            return `PublicHoliday`;
        case CostGroupEntryType.WorkshopShift:
            return `WorkshopShift`;
        case CostGroupEntryType.WorkshopOnCall:
            return `WorkshopOnCall`;
    }
}
export interface ICostGroupEntryTypeDescriptor { value: CostGroupEntryType; name: string; description: string; }
export var allCostGroupEntryType: ICostGroupEntryTypeDescriptor[] = [
    { value: CostGroupEntryType.Job, name: `Job`, description: `Job` },
    { value: CostGroupEntryType.Leave, name: `Leave`, description: `Leave` },
    { value: CostGroupEntryType.PublicHoliday, name: `PublicHoliday`, description: `PublicHoliday` },
    { value: CostGroupEntryType.WorkshopShift, name: `WorkshopShift`, description: `WorkshopShift` },
    { value: CostGroupEntryType.WorkshopOnCall, name: `WorkshopOnCall`, description: `WorkshopOnCall` }
];

export function getCostGroupEntryTypeDescriptor(value: CostGroupEntryType) {
    switch (value) {
        case CostGroupEntryType.Job:
            return { value: CostGroupEntryType.Job, name: `Job`, description: `Job` };
        case CostGroupEntryType.Leave:
            return { value: CostGroupEntryType.Leave, name: `Leave`, description: `Leave` };
        case CostGroupEntryType.PublicHoliday:
            return { value: CostGroupEntryType.PublicHoliday, name: `PublicHoliday`, description: `PublicHoliday` };
        case CostGroupEntryType.WorkshopShift:
            return { value: CostGroupEntryType.WorkshopShift, name: `WorkshopShift`, description: `WorkshopShift` };
        case CostGroupEntryType.WorkshopOnCall:
            return { value: CostGroupEntryType.WorkshopOnCall, name: `WorkshopOnCall`, description: `WorkshopOnCall` };
    }
}
export enum CostGroupDayType {
    Weekday = 1,
    Sat = 2,
    Sun = 3,
    PublicHoliday = 4
}
export function costGroupDayTypeDescription(value: CostGroupDayType) {
    switch (value) {
        case CostGroupDayType.Weekday:
            return `Weekday`;
        case CostGroupDayType.Sat:
            return `Saturday`;
        case CostGroupDayType.Sun:
            return `Sunday`;
        case CostGroupDayType.PublicHoliday:
            return `Public Holiday - Worked`;
    }
}
export interface ICostGroupDayTypeDescriptor { value: CostGroupDayType; name: string; description: string; }
export var allCostGroupDayType: ICostGroupDayTypeDescriptor[] = [
    { value: CostGroupDayType.Weekday, name: `Weekday`, description: `Weekday` },
    { value: CostGroupDayType.Sat, name: `Sat`, description: `Saturday` },
    { value: CostGroupDayType.Sun, name: `Sun`, description: `Sunday` },
    { value: CostGroupDayType.PublicHoliday, name: `PublicHoliday`, description: `Public Holiday - Worked` }
];

export function getCostGroupDayTypeDescriptor(value: CostGroupDayType) {
    switch (value) {
        case CostGroupDayType.Weekday:
            return { value: CostGroupDayType.Weekday, name: `Weekday`, description: `Weekday` };
        case CostGroupDayType.Sat:
            return { value: CostGroupDayType.Sat, name: `Sat`, description: `Saturday` };
        case CostGroupDayType.Sun:
            return { value: CostGroupDayType.Sun, name: `Sun`, description: `Sunday` };
        case CostGroupDayType.PublicHoliday:
            return { value: CostGroupDayType.PublicHoliday, name: `PublicHoliday`, description: `Public Holiday - Worked` };
    }
}
export enum CostGroupEntryTotalType {
    Duration = 1,
    Count = 2
}
export function costGroupEntryTotalTypeDescription(value: CostGroupEntryTotalType) {
    switch (value) {
        case CostGroupEntryTotalType.Duration:
            return `Duration`;
        case CostGroupEntryTotalType.Count:
            return `Count`;
    }
}
export interface ICostGroupEntryTotalTypeDescriptor { value: CostGroupEntryTotalType; name: string; description: string; }
export var allCostGroupEntryTotalType: ICostGroupEntryTotalTypeDescriptor[] = [
    { value: CostGroupEntryTotalType.Duration, name: `Duration`, description: `Duration` },
    { value: CostGroupEntryTotalType.Count, name: `Count`, description: `Count` }
];

export function getCostGroupEntryTotalTypeDescriptor(value: CostGroupEntryTotalType) {
    switch (value) {
        case CostGroupEntryTotalType.Duration:
            return { value: CostGroupEntryTotalType.Duration, name: `Duration`, description: `Duration` };
        case CostGroupEntryTotalType.Count:
            return { value: CostGroupEntryTotalType.Count, name: `Count`, description: `Count` };
    }
}
export enum OvertimeTimeType {
    Normal = 1,
    OvertimeLevelOne = 2,
    OvertimeLevelTwo = 3,
    EarlyOrLate = 4
}
export function overtimeTimeTypeDescription(value: OvertimeTimeType) {
    switch (value) {
        case OvertimeTimeType.Normal:
            return `Normal`;
        case OvertimeTimeType.OvertimeLevelOne:
            return `Overtime`;
        case OvertimeTimeType.OvertimeLevelTwo:
            return `Overtime 2x`;
        case OvertimeTimeType.EarlyOrLate:
            return `Early or Late`;
    }
}
export interface IOvertimeTimeTypeDescriptor { value: OvertimeTimeType; name: string; description: string; }
export var allOvertimeTimeType: IOvertimeTimeTypeDescriptor[] = [
    { value: OvertimeTimeType.Normal, name: `Normal`, description: `Normal` },
    { value: OvertimeTimeType.OvertimeLevelOne, name: `OvertimeLevelOne`, description: `Overtime` },
    { value: OvertimeTimeType.OvertimeLevelTwo, name: `OvertimeLevelTwo`, description: `Overtime 2x` },
    { value: OvertimeTimeType.EarlyOrLate, name: `EarlyOrLate`, description: `Early or Late` }
];

export function getOvertimeTimeTypeDescriptor(value: OvertimeTimeType) {
    switch (value) {
        case OvertimeTimeType.Normal:
            return { value: OvertimeTimeType.Normal, name: `Normal`, description: `Normal` };
        case OvertimeTimeType.OvertimeLevelOne:
            return { value: OvertimeTimeType.OvertimeLevelOne, name: `OvertimeLevelOne`, description: `Overtime` };
        case OvertimeTimeType.OvertimeLevelTwo:
            return { value: OvertimeTimeType.OvertimeLevelTwo, name: `OvertimeLevelTwo`, description: `Overtime 2x` };
        case OvertimeTimeType.EarlyOrLate:
            return { value: OvertimeTimeType.EarlyOrLate, name: `EarlyOrLate`, description: `Early or Late` };
    }
}
export enum SkillSpecCategory {
    Charter = 1,
    Rail = 2,
    Urban = 3
}
export function skillSpecCategoryDescription(value: SkillSpecCategory) {
    switch (value) {
        case SkillSpecCategory.Charter:
            return `Charter`;
        case SkillSpecCategory.Rail:
            return `Rail`;
        case SkillSpecCategory.Urban:
            return `Urban`;
    }
}
export interface ISkillSpecCategoryDescriptor { value: SkillSpecCategory; name: string; description: string; }
export var allSkillSpecCategory: ISkillSpecCategoryDescriptor[] = [
    { value: SkillSpecCategory.Charter, name: `Charter`, description: `Charter` },
    { value: SkillSpecCategory.Rail, name: `Rail`, description: `Rail` },
    { value: SkillSpecCategory.Urban, name: `Urban`, description: `Urban` }
];

export function getSkillSpecCategoryDescriptor(value: SkillSpecCategory) {
    switch (value) {
        case SkillSpecCategory.Charter:
            return { value: SkillSpecCategory.Charter, name: `Charter`, description: `Charter` };
        case SkillSpecCategory.Rail:
            return { value: SkillSpecCategory.Rail, name: `Rail`, description: `Rail` };
        case SkillSpecCategory.Urban:
            return { value: SkillSpecCategory.Urban, name: `Urban`, description: `Urban` };
    }
}
export enum TechSpecDataType {
    String = 1,
    Bool = 2,
    Date = 3,
    Dropdown = 4
}
export function techSpecDataTypeDescription(value: TechSpecDataType) {
    switch (value) {
        case TechSpecDataType.String:
            return `Text`;
        case TechSpecDataType.Bool:
            return `Yes/No`;
        case TechSpecDataType.Date:
            return `Date`;
        case TechSpecDataType.Dropdown:
            return `Dropdown`;
    }
}
export interface ITechSpecDataTypeDescriptor { value: TechSpecDataType; name: string; description: string; }
export var allTechSpecDataType: ITechSpecDataTypeDescriptor[] = [
    { value: TechSpecDataType.String, name: `String`, description: `Text` },
    { value: TechSpecDataType.Bool, name: `Bool`, description: `Yes/No` },
    { value: TechSpecDataType.Date, name: `Date`, description: `Date` },
    { value: TechSpecDataType.Dropdown, name: `Dropdown`, description: `Dropdown` }
];

export function getTechSpecDataTypeDescriptor(value: TechSpecDataType) {
    switch (value) {
        case TechSpecDataType.String:
            return { value: TechSpecDataType.String, name: `String`, description: `Text` };
        case TechSpecDataType.Bool:
            return { value: TechSpecDataType.Bool, name: `Bool`, description: `Yes/No` };
        case TechSpecDataType.Date:
            return { value: TechSpecDataType.Date, name: `Date`, description: `Date` };
        case TechSpecDataType.Dropdown:
            return { value: TechSpecDataType.Dropdown, name: `Dropdown`, description: `Dropdown` };
    }
}
export enum ChangeState {
    Unknown = 0,
    Unchanged = 1,
    Added = 2,
    Modified = 3,
    Deleted = 4,
    Regenerated = 5
}
export function changeStateDescription(value: ChangeState) {
    switch (value) {
        case ChangeState.Unknown:
            return `Unknown`;
        case ChangeState.Unchanged:
            return `Unchanged`;
        case ChangeState.Added:
            return `Added`;
        case ChangeState.Modified:
            return `Modified`;
        case ChangeState.Deleted:
            return `Deleted`;
        case ChangeState.Regenerated:
            return `Regenerated`;
    }
}
export interface IChangeStateDescriptor { value: ChangeState; name: string; description: string; }
export var allChangeState: IChangeStateDescriptor[] = [
    { value: ChangeState.Unknown, name: `Unknown`, description: `Unknown` },
    { value: ChangeState.Unchanged, name: `Unchanged`, description: `Unchanged` },
    { value: ChangeState.Added, name: `Added`, description: `Added` },
    { value: ChangeState.Modified, name: `Modified`, description: `Modified` },
    { value: ChangeState.Deleted, name: `Deleted`, description: `Deleted` },
    { value: ChangeState.Regenerated, name: `Regenerated`, description: `Regenerated` }
];

export function getChangeStateDescriptor(value: ChangeState) {
    switch (value) {
        case ChangeState.Unknown:
            return { value: ChangeState.Unknown, name: `Unknown`, description: `Unknown` };
        case ChangeState.Unchanged:
            return { value: ChangeState.Unchanged, name: `Unchanged`, description: `Unchanged` };
        case ChangeState.Added:
            return { value: ChangeState.Added, name: `Added`, description: `Added` };
        case ChangeState.Modified:
            return { value: ChangeState.Modified, name: `Modified`, description: `Modified` };
        case ChangeState.Deleted:
            return { value: ChangeState.Deleted, name: `Deleted`, description: `Deleted` };
        case ChangeState.Regenerated:
            return { value: ChangeState.Regenerated, name: `Regenerated`, description: `Regenerated` };
    }
}
export enum IncidentType {
    FailingToPayFare = 0,
    FailingToPresentBusPass = 1,
    DistractingTheDriver = 2,
    FailingToRemainSeated = 3,
    NotDoingAsDirected = 4,
    DestructionOfProperty = 5,
    BullyingOtherPassengers = 6,
    DrinkingOrEating = 7,
    Spitting = 8,
    FoulLanguage = 9,
    IndecentBehaviour = 10,
    Smoking = 11,
    RepeatedOffence = 12,
    VerbalAbuse = 13,
    FirstAidTreatment = 14,
    Other = 15
}
export function incidentTypeDescription(value: IncidentType) {
    switch (value) {
        case IncidentType.FailingToPayFare:
            return `Failing To Pay Fare`;
        case IncidentType.FailingToPresentBusPass:
            return `Failing To Present Bus Pass`;
        case IncidentType.DistractingTheDriver:
            return `Distracting The Driver`;
        case IncidentType.FailingToRemainSeated:
            return `Failing To Remain Seated`;
        case IncidentType.NotDoingAsDirected:
            return `Not Doing As Directed`;
        case IncidentType.DestructionOfProperty:
            return `Destruction Of Property`;
        case IncidentType.BullyingOtherPassengers:
            return `Bullying Other Passengers`;
        case IncidentType.DrinkingOrEating:
            return `Drinking Or Eating`;
        case IncidentType.Spitting:
            return `Spitting`;
        case IncidentType.FoulLanguage:
            return `Foul Language`;
        case IncidentType.IndecentBehaviour:
            return `Indecent Behaviour`;
        case IncidentType.Smoking:
            return `Smoking`;
        case IncidentType.RepeatedOffence:
            return `Repeated Offence`;
        case IncidentType.VerbalAbuse:
            return `Verbal Abuse`;
        case IncidentType.FirstAidTreatment:
            return `First Aid Treatment`;
        case IncidentType.Other:
            return `Other`;
    }
}
export interface IIncidentTypeDescriptor { value: IncidentType; name: string; description: string; }
export var allIncidentType: IIncidentTypeDescriptor[] = [
    { value: IncidentType.FailingToPayFare, name: `FailingToPayFare`, description: `Failing To Pay Fare` },
    { value: IncidentType.FailingToPresentBusPass, name: `FailingToPresentBusPass`, description: `Failing To Present Bus Pass` },
    { value: IncidentType.DistractingTheDriver, name: `DistractingTheDriver`, description: `Distracting The Driver` },
    { value: IncidentType.FailingToRemainSeated, name: `FailingToRemainSeated`, description: `Failing To Remain Seated` },
    { value: IncidentType.NotDoingAsDirected, name: `NotDoingAsDirected`, description: `Not Doing As Directed` },
    { value: IncidentType.DestructionOfProperty, name: `DestructionOfProperty`, description: `Destruction Of Property` },
    { value: IncidentType.BullyingOtherPassengers, name: `BullyingOtherPassengers`, description: `Bullying Other Passengers` },
    { value: IncidentType.DrinkingOrEating, name: `DrinkingOrEating`, description: `Drinking Or Eating` },
    { value: IncidentType.Spitting, name: `Spitting`, description: `Spitting` },
    { value: IncidentType.FoulLanguage, name: `FoulLanguage`, description: `Foul Language` },
    { value: IncidentType.IndecentBehaviour, name: `IndecentBehaviour`, description: `Indecent Behaviour` },
    { value: IncidentType.Smoking, name: `Smoking`, description: `Smoking` },
    { value: IncidentType.RepeatedOffence, name: `RepeatedOffence`, description: `Repeated Offence` },
    { value: IncidentType.VerbalAbuse, name: `VerbalAbuse`, description: `Verbal Abuse` },
    { value: IncidentType.FirstAidTreatment, name: `FirstAidTreatment`, description: `First Aid Treatment` },
    { value: IncidentType.Other, name: `Other`, description: `Other` }
];

export function getIncidentTypeDescriptor(value: IncidentType) {
    switch (value) {
        case IncidentType.FailingToPayFare:
            return { value: IncidentType.FailingToPayFare, name: `FailingToPayFare`, description: `Failing To Pay Fare` };
        case IncidentType.FailingToPresentBusPass:
            return { value: IncidentType.FailingToPresentBusPass, name: `FailingToPresentBusPass`, description: `Failing To Present Bus Pass` };
        case IncidentType.DistractingTheDriver:
            return { value: IncidentType.DistractingTheDriver, name: `DistractingTheDriver`, description: `Distracting The Driver` };
        case IncidentType.FailingToRemainSeated:
            return { value: IncidentType.FailingToRemainSeated, name: `FailingToRemainSeated`, description: `Failing To Remain Seated` };
        case IncidentType.NotDoingAsDirected:
            return { value: IncidentType.NotDoingAsDirected, name: `NotDoingAsDirected`, description: `Not Doing As Directed` };
        case IncidentType.DestructionOfProperty:
            return { value: IncidentType.DestructionOfProperty, name: `DestructionOfProperty`, description: `Destruction Of Property` };
        case IncidentType.BullyingOtherPassengers:
            return { value: IncidentType.BullyingOtherPassengers, name: `BullyingOtherPassengers`, description: `Bullying Other Passengers` };
        case IncidentType.DrinkingOrEating:
            return { value: IncidentType.DrinkingOrEating, name: `DrinkingOrEating`, description: `Drinking Or Eating` };
        case IncidentType.Spitting:
            return { value: IncidentType.Spitting, name: `Spitting`, description: `Spitting` };
        case IncidentType.FoulLanguage:
            return { value: IncidentType.FoulLanguage, name: `FoulLanguage`, description: `Foul Language` };
        case IncidentType.IndecentBehaviour:
            return { value: IncidentType.IndecentBehaviour, name: `IndecentBehaviour`, description: `Indecent Behaviour` };
        case IncidentType.Smoking:
            return { value: IncidentType.Smoking, name: `Smoking`, description: `Smoking` };
        case IncidentType.RepeatedOffence:
            return { value: IncidentType.RepeatedOffence, name: `RepeatedOffence`, description: `Repeated Offence` };
        case IncidentType.VerbalAbuse:
            return { value: IncidentType.VerbalAbuse, name: `VerbalAbuse`, description: `Verbal Abuse` };
        case IncidentType.FirstAidTreatment:
            return { value: IncidentType.FirstAidTreatment, name: `FirstAidTreatment`, description: `First Aid Treatment` };
        case IncidentType.Other:
            return { value: IncidentType.Other, name: `Other`, description: `Other` };
    }
}
export enum IncidentSeen {
    All = 0,
    Some = 1,
    None = 2
}
export function incidentSeenDescription(value: IncidentSeen) {
    switch (value) {
        case IncidentSeen.All:
            return `All`;
        case IncidentSeen.Some:
            return `Some`;
        case IncidentSeen.None:
            return `None`;
    }
}
export interface IIncidentSeenDescriptor { value: IncidentSeen; name: string; description: string; }
export var allIncidentSeen: IIncidentSeenDescriptor[] = [
    { value: IncidentSeen.All, name: `All`, description: `All` },
    { value: IncidentSeen.Some, name: `Some`, description: `Some` },
    { value: IncidentSeen.None, name: `None`, description: `None` }
];

export function getIncidentSeenDescriptor(value: IncidentSeen) {
    switch (value) {
        case IncidentSeen.All:
            return { value: IncidentSeen.All, name: `All`, description: `All` };
        case IncidentSeen.Some:
            return { value: IncidentSeen.Some, name: `Some`, description: `Some` };
        case IncidentSeen.None:
            return { value: IncidentSeen.None, name: `None`, description: `None` };
    }
}
export enum BehaviourBreachCategory {
    LifeThreateningBehaviour = 0,
    DangerousDestructiveBehaviour = 1,
    UnsafeBehaviour = 2,
    IrresponsibleBehaviour = 3
}
export function behaviourBreachCategoryDescription(value: BehaviourBreachCategory) {
    switch (value) {
        case BehaviourBreachCategory.LifeThreateningBehaviour:
            return `4 - Life Threatening Behaviour`;
        case BehaviourBreachCategory.DangerousDestructiveBehaviour:
            return `3 - Dangerous/Destructive Behaviour`;
        case BehaviourBreachCategory.UnsafeBehaviour:
            return `2 - Unsafe Behaviour`;
        case BehaviourBreachCategory.IrresponsibleBehaviour:
            return `1 - Irresponsible Behaviour`;
    }
}
export interface IBehaviourBreachCategoryDescriptor { value: BehaviourBreachCategory; name: string; description: string; }
export var allBehaviourBreachCategory: IBehaviourBreachCategoryDescriptor[] = [
    { value: BehaviourBreachCategory.LifeThreateningBehaviour, name: `LifeThreateningBehaviour`, description: `4 - Life Threatening Behaviour` },
    { value: BehaviourBreachCategory.DangerousDestructiveBehaviour, name: `DangerousDestructiveBehaviour`, description: `3 - Dangerous/Destructive Behaviour` },
    { value: BehaviourBreachCategory.UnsafeBehaviour, name: `UnsafeBehaviour`, description: `2 - Unsafe Behaviour` },
    { value: BehaviourBreachCategory.IrresponsibleBehaviour, name: `IrresponsibleBehaviour`, description: `1 - Irresponsible Behaviour` }
];

export function getBehaviourBreachCategoryDescriptor(value: BehaviourBreachCategory) {
    switch (value) {
        case BehaviourBreachCategory.LifeThreateningBehaviour:
            return { value: BehaviourBreachCategory.LifeThreateningBehaviour, name: `LifeThreateningBehaviour`, description: `4 - Life Threatening Behaviour` };
        case BehaviourBreachCategory.DangerousDestructiveBehaviour:
            return { value: BehaviourBreachCategory.DangerousDestructiveBehaviour, name: `DangerousDestructiveBehaviour`, description: `3 - Dangerous/Destructive Behaviour` };
        case BehaviourBreachCategory.UnsafeBehaviour:
            return { value: BehaviourBreachCategory.UnsafeBehaviour, name: `UnsafeBehaviour`, description: `2 - Unsafe Behaviour` };
        case BehaviourBreachCategory.IrresponsibleBehaviour:
            return { value: BehaviourBreachCategory.IrresponsibleBehaviour, name: `IrresponsibleBehaviour`, description: `1 - Irresponsible Behaviour` };
    }
}
export enum CustomerComplaintCategory {
    StaffIssues = 0,
    ServiceRelated = 1,
    VehicleIssues = 2,
    OtherPassengerBehaviours = 3,
    MissedServices = 4,
    Injury = 5
}
export function customerComplaintCategoryDescription(value: CustomerComplaintCategory) {
    switch (value) {
        case CustomerComplaintCategory.StaffIssues:
            return `Staff Issues`;
        case CustomerComplaintCategory.ServiceRelated:
            return `Service Related`;
        case CustomerComplaintCategory.VehicleIssues:
            return `Vehicle Issues`;
        case CustomerComplaintCategory.OtherPassengerBehaviours:
            return `Other Passenger Behaviours`;
        case CustomerComplaintCategory.MissedServices:
            return `Missed Services`;
        case CustomerComplaintCategory.Injury:
            return `Injury`;
    }
}
export interface ICustomerComplaintCategoryDescriptor { value: CustomerComplaintCategory; name: string; description: string; }
export var allCustomerComplaintCategory: ICustomerComplaintCategoryDescriptor[] = [
    { value: CustomerComplaintCategory.StaffIssues, name: `StaffIssues`, description: `Staff Issues` },
    { value: CustomerComplaintCategory.ServiceRelated, name: `ServiceRelated`, description: `Service Related` },
    { value: CustomerComplaintCategory.VehicleIssues, name: `VehicleIssues`, description: `Vehicle Issues` },
    { value: CustomerComplaintCategory.OtherPassengerBehaviours, name: `OtherPassengerBehaviours`, description: `Other Passenger Behaviours` },
    { value: CustomerComplaintCategory.MissedServices, name: `MissedServices`, description: `Missed Services` },
    { value: CustomerComplaintCategory.Injury, name: `Injury`, description: `Injury` }
];

export function getCustomerComplaintCategoryDescriptor(value: CustomerComplaintCategory) {
    switch (value) {
        case CustomerComplaintCategory.StaffIssues:
            return { value: CustomerComplaintCategory.StaffIssues, name: `StaffIssues`, description: `Staff Issues` };
        case CustomerComplaintCategory.ServiceRelated:
            return { value: CustomerComplaintCategory.ServiceRelated, name: `ServiceRelated`, description: `Service Related` };
        case CustomerComplaintCategory.VehicleIssues:
            return { value: CustomerComplaintCategory.VehicleIssues, name: `VehicleIssues`, description: `Vehicle Issues` };
        case CustomerComplaintCategory.OtherPassengerBehaviours:
            return { value: CustomerComplaintCategory.OtherPassengerBehaviours, name: `OtherPassengerBehaviours`, description: `Other Passenger Behaviours` };
        case CustomerComplaintCategory.MissedServices:
            return { value: CustomerComplaintCategory.MissedServices, name: `MissedServices`, description: `Missed Services` };
        case CustomerComplaintCategory.Injury:
            return { value: CustomerComplaintCategory.Injury, name: `Injury`, description: `Injury` };
    }
}
export enum CustomerComplaintSeverityLevel {
    Information = 0,
    Low = 1,
    Medium = 2,
    High = 3
}
export function customerComplaintSeverityLevelDescription(value: CustomerComplaintSeverityLevel) {
    switch (value) {
        case CustomerComplaintSeverityLevel.Information:
            return `1 - Information`;
        case CustomerComplaintSeverityLevel.Low:
            return `2 - Low`;
        case CustomerComplaintSeverityLevel.Medium:
            return `3 - Medium`;
        case CustomerComplaintSeverityLevel.High:
            return `4 - High`;
    }
}
export interface ICustomerComplaintSeverityLevelDescriptor { value: CustomerComplaintSeverityLevel; name: string; description: string; }
export var allCustomerComplaintSeverityLevel: ICustomerComplaintSeverityLevelDescriptor[] = [
    { value: CustomerComplaintSeverityLevel.Information, name: `Information`, description: `1 - Information` },
    { value: CustomerComplaintSeverityLevel.Low, name: `Low`, description: `2 - Low` },
    { value: CustomerComplaintSeverityLevel.Medium, name: `Medium`, description: `3 - Medium` },
    { value: CustomerComplaintSeverityLevel.High, name: `High`, description: `4 - High` }
];

export function getCustomerComplaintSeverityLevelDescriptor(value: CustomerComplaintSeverityLevel) {
    switch (value) {
        case CustomerComplaintSeverityLevel.Information:
            return { value: CustomerComplaintSeverityLevel.Information, name: `Information`, description: `1 - Information` };
        case CustomerComplaintSeverityLevel.Low:
            return { value: CustomerComplaintSeverityLevel.Low, name: `Low`, description: `2 - Low` };
        case CustomerComplaintSeverityLevel.Medium:
            return { value: CustomerComplaintSeverityLevel.Medium, name: `Medium`, description: `3 - Medium` };
        case CustomerComplaintSeverityLevel.High:
            return { value: CustomerComplaintSeverityLevel.High, name: `High`, description: `4 - High` };
    }
}
export enum CustomerComplaintRequiredness {
    Yes = 0,
    No = 1,
    Completed = 2
}
export function customerComplaintRequirednessDescription(value: CustomerComplaintRequiredness) {
    switch (value) {
        case CustomerComplaintRequiredness.Yes:
            return `Yes`;
        case CustomerComplaintRequiredness.No:
            return `No`;
        case CustomerComplaintRequiredness.Completed:
            return `Completed`;
    }
}
export interface ICustomerComplaintRequirednessDescriptor { value: CustomerComplaintRequiredness; name: string; description: string; }
export var allCustomerComplaintRequiredness: ICustomerComplaintRequirednessDescriptor[] = [
    { value: CustomerComplaintRequiredness.Yes, name: `Yes`, description: `Yes` },
    { value: CustomerComplaintRequiredness.No, name: `No`, description: `No` },
    { value: CustomerComplaintRequiredness.Completed, name: `Completed`, description: `Completed` }
];

export function getCustomerComplaintRequirednessDescriptor(value: CustomerComplaintRequiredness) {
    switch (value) {
        case CustomerComplaintRequiredness.Yes:
            return { value: CustomerComplaintRequiredness.Yes, name: `Yes`, description: `Yes` };
        case CustomerComplaintRequiredness.No:
            return { value: CustomerComplaintRequiredness.No, name: `No`, description: `No` };
        case CustomerComplaintRequiredness.Completed:
            return { value: CustomerComplaintRequiredness.Completed, name: `Completed`, description: `Completed` };
    }
}
export enum PredepartureCheckChoice {
    Yes = 1,
    No = 2,
    NotApplicable = 3
}
export function predepartureCheckChoiceDescription(value: PredepartureCheckChoice) {
    switch (value) {
        case PredepartureCheckChoice.Yes:
            return `Yes`;
        case PredepartureCheckChoice.No:
            return `No`;
        case PredepartureCheckChoice.NotApplicable:
            return `N/A`;
    }
}
export interface IPredepartureCheckChoiceDescriptor { value: PredepartureCheckChoice; name: string; description: string; }
export var allPredepartureCheckChoice: IPredepartureCheckChoiceDescriptor[] = [
    { value: PredepartureCheckChoice.Yes, name: `Yes`, description: `Yes` },
    { value: PredepartureCheckChoice.No, name: `No`, description: `No` },
    { value: PredepartureCheckChoice.NotApplicable, name: `NotApplicable`, description: `N/A` }
];

export function getPredepartureCheckChoiceDescriptor(value: PredepartureCheckChoice) {
    switch (value) {
        case PredepartureCheckChoice.Yes:
            return { value: PredepartureCheckChoice.Yes, name: `Yes`, description: `Yes` };
        case PredepartureCheckChoice.No:
            return { value: PredepartureCheckChoice.No, name: `No`, description: `No` };
        case PredepartureCheckChoice.NotApplicable:
            return { value: PredepartureCheckChoice.NotApplicable, name: `NotApplicable`, description: `N/A` };
    }
}
export enum WarningType {
    Default = 0,
    DriverManagedFatigue = 1
}
export function warningTypeDescription(value: WarningType) {
    switch (value) {
        case WarningType.Default:
            return `Default`;
        case WarningType.DriverManagedFatigue:
            return `DriverManagedFatigue`;
    }
}
export interface IWarningTypeDescriptor { value: WarningType; name: string; description: string; }
export var allWarningType: IWarningTypeDescriptor[] = [
    { value: WarningType.Default, name: `Default`, description: `Default` },
    { value: WarningType.DriverManagedFatigue, name: `DriverManagedFatigue`, description: `DriverManagedFatigue` }
];

export function getWarningTypeDescriptor(value: WarningType) {
    switch (value) {
        case WarningType.Default:
            return { value: WarningType.Default, name: `Default`, description: `Default` };
        case WarningType.DriverManagedFatigue:
            return { value: WarningType.DriverManagedFatigue, name: `DriverManagedFatigue`, description: `DriverManagedFatigue` };
    }
}
export enum ShiftTranslinkDirection {
    Unknown = 0,
    Inbound = 1,
    Outbound = 2,
    AsRequired = 3
}
export function shiftTranslinkDirectionDescription(value: ShiftTranslinkDirection) {
    switch (value) {
        case ShiftTranslinkDirection.Unknown:
            return `Unknown`;
        case ShiftTranslinkDirection.Inbound:
            return `Inbound`;
        case ShiftTranslinkDirection.Outbound:
            return `Outbound`;
        case ShiftTranslinkDirection.AsRequired:
            return `As Required`;
    }
}
export interface IShiftTranslinkDirectionDescriptor { value: ShiftTranslinkDirection; name: string; description: string; }
export var allShiftTranslinkDirection: IShiftTranslinkDirectionDescriptor[] = [
    { value: ShiftTranslinkDirection.Unknown, name: `Unknown`, description: `Unknown` },
    { value: ShiftTranslinkDirection.Inbound, name: `Inbound`, description: `Inbound` },
    { value: ShiftTranslinkDirection.Outbound, name: `Outbound`, description: `Outbound` },
    { value: ShiftTranslinkDirection.AsRequired, name: `AsRequired`, description: `As Required` }
];

export function getShiftTranslinkDirectionDescriptor(value: ShiftTranslinkDirection) {
    switch (value) {
        case ShiftTranslinkDirection.Unknown:
            return { value: ShiftTranslinkDirection.Unknown, name: `Unknown`, description: `Unknown` };
        case ShiftTranslinkDirection.Inbound:
            return { value: ShiftTranslinkDirection.Inbound, name: `Inbound`, description: `Inbound` };
        case ShiftTranslinkDirection.Outbound:
            return { value: ShiftTranslinkDirection.Outbound, name: `Outbound`, description: `Outbound` };
        case ShiftTranslinkDirection.AsRequired:
            return { value: ShiftTranslinkDirection.AsRequired, name: `AsRequired`, description: `As Required` };
    }
}
export enum SwapVehicleType {
    Unknown = 0,
    DeleteExistingProgress = 1,
    CreateNewJob = 2
}
export function swapVehicleTypeDescription(value: SwapVehicleType) {
    switch (value) {
        case SwapVehicleType.Unknown:
            return `Unknown`;
        case SwapVehicleType.DeleteExistingProgress:
            return `DeleteExistingProgress`;
        case SwapVehicleType.CreateNewJob:
            return `CreateNewJob`;
    }
}
export interface ISwapVehicleTypeDescriptor { value: SwapVehicleType; name: string; description: string; }
export var allSwapVehicleType: ISwapVehicleTypeDescriptor[] = [
    { value: SwapVehicleType.Unknown, name: `Unknown`, description: `Unknown` },
    { value: SwapVehicleType.DeleteExistingProgress, name: `DeleteExistingProgress`, description: `DeleteExistingProgress` },
    { value: SwapVehicleType.CreateNewJob, name: `CreateNewJob`, description: `CreateNewJob` }
];

export function getSwapVehicleTypeDescriptor(value: SwapVehicleType) {
    switch (value) {
        case SwapVehicleType.Unknown:
            return { value: SwapVehicleType.Unknown, name: `Unknown`, description: `Unknown` };
        case SwapVehicleType.DeleteExistingProgress:
            return { value: SwapVehicleType.DeleteExistingProgress, name: `DeleteExistingProgress`, description: `DeleteExistingProgress` };
        case SwapVehicleType.CreateNewJob:
            return { value: SwapVehicleType.CreateNewJob, name: `CreateNewJob`, description: `CreateNewJob` };
    }
}
export enum JobBulkUpdateType {
    NoBulkUpdate = 0,
    JobForTripsFromSameBooking = 1,
    JobsForTripsFromSameAndFutureRecurringBookings = 2
}
export function jobBulkUpdateTypeDescription(value: JobBulkUpdateType) {
    switch (value) {
        case JobBulkUpdateType.NoBulkUpdate:
            return `NoBulkUpdate`;
        case JobBulkUpdateType.JobForTripsFromSameBooking:
            return `JobForTripsFromSameBooking`;
        case JobBulkUpdateType.JobsForTripsFromSameAndFutureRecurringBookings:
            return `JobsForTripsFromSameAndFutureRecurringBookings`;
    }
}
export interface IJobBulkUpdateTypeDescriptor { value: JobBulkUpdateType; name: string; description: string; }
export var allJobBulkUpdateType: IJobBulkUpdateTypeDescriptor[] = [
    { value: JobBulkUpdateType.NoBulkUpdate, name: `NoBulkUpdate`, description: `NoBulkUpdate` },
    { value: JobBulkUpdateType.JobForTripsFromSameBooking, name: `JobForTripsFromSameBooking`, description: `JobForTripsFromSameBooking` },
    { value: JobBulkUpdateType.JobsForTripsFromSameAndFutureRecurringBookings, name: `JobsForTripsFromSameAndFutureRecurringBookings`, description: `JobsForTripsFromSameAndFutureRecurringBookings` }
];

export function getJobBulkUpdateTypeDescriptor(value: JobBulkUpdateType) {
    switch (value) {
        case JobBulkUpdateType.NoBulkUpdate:
            return { value: JobBulkUpdateType.NoBulkUpdate, name: `NoBulkUpdate`, description: `NoBulkUpdate` };
        case JobBulkUpdateType.JobForTripsFromSameBooking:
            return { value: JobBulkUpdateType.JobForTripsFromSameBooking, name: `JobForTripsFromSameBooking`, description: `JobForTripsFromSameBooking` };
        case JobBulkUpdateType.JobsForTripsFromSameAndFutureRecurringBookings:
            return { value: JobBulkUpdateType.JobsForTripsFromSameAndFutureRecurringBookings, name: `JobsForTripsFromSameAndFutureRecurringBookings`, description: `JobsForTripsFromSameAndFutureRecurringBookings` };
    }
}
export enum JobAttachmentSource {
    Shift = 0,
    Quote = 1,
    RailTemplateShift = 3
}
export function jobAttachmentSourceDescription(value: JobAttachmentSource) {
    switch (value) {
        case JobAttachmentSource.Shift:
            return `Shift`;
        case JobAttachmentSource.Quote:
            return `Quote`;
        case JobAttachmentSource.RailTemplateShift:
            return `RailTemplateShift`;
    }
}
export interface IJobAttachmentSourceDescriptor { value: JobAttachmentSource; name: string; description: string; }
export var allJobAttachmentSource: IJobAttachmentSourceDescriptor[] = [
    { value: JobAttachmentSource.Shift, name: `Shift`, description: `Shift` },
    { value: JobAttachmentSource.Quote, name: `Quote`, description: `Quote` },
    { value: JobAttachmentSource.RailTemplateShift, name: `RailTemplateShift`, description: `RailTemplateShift` }
];

export function getJobAttachmentSourceDescriptor(value: JobAttachmentSource) {
    switch (value) {
        case JobAttachmentSource.Shift:
            return { value: JobAttachmentSource.Shift, name: `Shift`, description: `Shift` };
        case JobAttachmentSource.Quote:
            return { value: JobAttachmentSource.Quote, name: `Quote`, description: `Quote` };
        case JobAttachmentSource.RailTemplateShift:
            return { value: JobAttachmentSource.RailTemplateShift, name: `RailTemplateShift`, description: `RailTemplateShift` };
    }
}
export enum ContactedVia {
    Email = 0,
    Phone = 1
}
export function contactedViaDescription(value: ContactedVia) {
    switch (value) {
        case ContactedVia.Email:
            return `Email`;
        case ContactedVia.Phone:
            return `Phone`;
    }
}
export interface IContactedViaDescriptor { value: ContactedVia; name: string; description: string; }
export var allContactedVia: IContactedViaDescriptor[] = [
    { value: ContactedVia.Email, name: `Email`, description: `Email` },
    { value: ContactedVia.Phone, name: `Phone`, description: `Phone` }
];

export function getContactedViaDescriptor(value: ContactedVia) {
    switch (value) {
        case ContactedVia.Email:
            return { value: ContactedVia.Email, name: `Email`, description: `Email` };
        case ContactedVia.Phone:
            return { value: ContactedVia.Phone, name: `Phone`, description: `Phone` };
    }
}
export enum HistoryType {
    Amended = 1,
    Removed = 2
}
export function historyTypeDescription(value: HistoryType) {
    switch (value) {
        case HistoryType.Amended:
            return `Amended`;
        case HistoryType.Removed:
            return `Removed`;
    }
}
export interface IHistoryTypeDescriptor { value: HistoryType; name: string; description: string; }
export var allHistoryType: IHistoryTypeDescriptor[] = [
    { value: HistoryType.Amended, name: `Amended`, description: `Amended` },
    { value: HistoryType.Removed, name: `Removed`, description: `Removed` }
];

export function getHistoryTypeDescriptor(value: HistoryType) {
    switch (value) {
        case HistoryType.Amended:
            return { value: HistoryType.Amended, name: `Amended`, description: `Amended` };
        case HistoryType.Removed:
            return { value: HistoryType.Removed, name: `Removed`, description: `Removed` };
    }
}
export enum JobChange {
    Unchanged = 1,
    DriverChange = 2,
    VehicleChange = 3,
    CompleteChange = 4
}
export function jobChangeDescription(value: JobChange) {
    switch (value) {
        case JobChange.Unchanged:
            return `Unchanged`;
        case JobChange.DriverChange:
            return `DriverChange`;
        case JobChange.VehicleChange:
            return `VehicleChange`;
        case JobChange.CompleteChange:
            return `CompleteChange`;
    }
}
export interface IJobChangeDescriptor { value: JobChange; name: string; description: string; }
export var allJobChange: IJobChangeDescriptor[] = [
    { value: JobChange.Unchanged, name: `Unchanged`, description: `Unchanged` },
    { value: JobChange.DriverChange, name: `DriverChange`, description: `DriverChange` },
    { value: JobChange.VehicleChange, name: `VehicleChange`, description: `VehicleChange` },
    { value: JobChange.CompleteChange, name: `CompleteChange`, description: `CompleteChange` }
];

export function getJobChangeDescriptor(value: JobChange) {
    switch (value) {
        case JobChange.Unchanged:
            return { value: JobChange.Unchanged, name: `Unchanged`, description: `Unchanged` };
        case JobChange.DriverChange:
            return { value: JobChange.DriverChange, name: `DriverChange`, description: `DriverChange` };
        case JobChange.VehicleChange:
            return { value: JobChange.VehicleChange, name: `VehicleChange`, description: `VehicleChange` };
        case JobChange.CompleteChange:
            return { value: JobChange.CompleteChange, name: `CompleteChange`, description: `CompleteChange` };
    }
}
export enum JobWarningType {
    NoStaffMember = 0,
    NoVehicle = 1,
    NoSubcontractor = 2,
    ConflictedStaffMember = 3,
    ConflictedVehicle = 4
}
export function jobWarningTypeDescription(value: JobWarningType) {
    switch (value) {
        case JobWarningType.NoStaffMember:
            return `No Staff Member`;
        case JobWarningType.NoVehicle:
            return `No Vehicle`;
        case JobWarningType.NoSubcontractor:
            return `No Subcontractor`;
        case JobWarningType.ConflictedStaffMember:
            return `Staff Member Conflict`;
        case JobWarningType.ConflictedVehicle:
            return `Vehicle Conflict`;
    }
}
export interface IJobWarningTypeDescriptor { value: JobWarningType; name: string; description: string; }
export var allJobWarningType: IJobWarningTypeDescriptor[] = [
    { value: JobWarningType.NoStaffMember, name: `NoStaffMember`, description: `No Staff Member` },
    { value: JobWarningType.NoVehicle, name: `NoVehicle`, description: `No Vehicle` },
    { value: JobWarningType.NoSubcontractor, name: `NoSubcontractor`, description: `No Subcontractor` },
    { value: JobWarningType.ConflictedStaffMember, name: `ConflictedStaffMember`, description: `Staff Member Conflict` },
    { value: JobWarningType.ConflictedVehicle, name: `ConflictedVehicle`, description: `Vehicle Conflict` }
];

export function getJobWarningTypeDescriptor(value: JobWarningType) {
    switch (value) {
        case JobWarningType.NoStaffMember:
            return { value: JobWarningType.NoStaffMember, name: `NoStaffMember`, description: `No Staff Member` };
        case JobWarningType.NoVehicle:
            return { value: JobWarningType.NoVehicle, name: `NoVehicle`, description: `No Vehicle` };
        case JobWarningType.NoSubcontractor:
            return { value: JobWarningType.NoSubcontractor, name: `NoSubcontractor`, description: `No Subcontractor` };
        case JobWarningType.ConflictedStaffMember:
            return { value: JobWarningType.ConflictedStaffMember, name: `ConflictedStaffMember`, description: `Staff Member Conflict` };
        case JobWarningType.ConflictedVehicle:
            return { value: JobWarningType.ConflictedVehicle, name: `ConflictedVehicle`, description: `Vehicle Conflict` };
    }
}
export enum AuditLogChangeType {
    Added = 1,
    Deleted = 2,
    Modified = 3
}
export function auditLogChangeTypeDescription(value: AuditLogChangeType) {
    switch (value) {
        case AuditLogChangeType.Added:
            return `Added`;
        case AuditLogChangeType.Deleted:
            return `Deleted`;
        case AuditLogChangeType.Modified:
            return `Modified`;
    }
}
export interface IAuditLogChangeTypeDescriptor { value: AuditLogChangeType; name: string; description: string; }
export var allAuditLogChangeType: IAuditLogChangeTypeDescriptor[] = [
    { value: AuditLogChangeType.Added, name: `Added`, description: `Added` },
    { value: AuditLogChangeType.Deleted, name: `Deleted`, description: `Deleted` },
    { value: AuditLogChangeType.Modified, name: `Modified`, description: `Modified` }
];

export function getAuditLogChangeTypeDescriptor(value: AuditLogChangeType) {
    switch (value) {
        case AuditLogChangeType.Added:
            return { value: AuditLogChangeType.Added, name: `Added`, description: `Added` };
        case AuditLogChangeType.Deleted:
            return { value: AuditLogChangeType.Deleted, name: `Deleted`, description: `Deleted` };
        case AuditLogChangeType.Modified:
            return { value: AuditLogChangeType.Modified, name: `Modified`, description: `Modified` };
    }
}
export enum ParentJobRelationship {
    RouteTraining = 1
}
export function parentJobRelationshipDescription(value: ParentJobRelationship) {
    switch (value) {
        case ParentJobRelationship.RouteTraining:
            return `Route Training`;
    }
}
export interface IParentJobRelationshipDescriptor { value: ParentJobRelationship; name: string; description: string; }
export var allParentJobRelationship: IParentJobRelationshipDescriptor[] = [
    { value: ParentJobRelationship.RouteTraining, name: `RouteTraining`, description: `Route Training` }
];

export function getParentJobRelationshipDescriptor(value: ParentJobRelationship) {
    switch (value) {
        case ParentJobRelationship.RouteTraining:
            return { value: ParentJobRelationship.RouteTraining, name: `RouteTraining`, description: `Route Training` };
    }
}
export enum FatigueStatus {
    Ok = 1,
    Warning = 2,
    DriverManaged = 3,
    Violation = 4
}
export function fatigueStatusDescription(value: FatigueStatus) {
    switch (value) {
        case FatigueStatus.Ok:
            return `Ok`;
        case FatigueStatus.Warning:
            return `Warning`;
        case FatigueStatus.DriverManaged:
            return `Driver Managed`;
        case FatigueStatus.Violation:
            return `Violation`;
    }
}
export interface IFatigueStatusDescriptor { value: FatigueStatus; name: string; description: string; }
export var allFatigueStatus: IFatigueStatusDescriptor[] = [
    { value: FatigueStatus.Ok, name: `Ok`, description: `Ok` },
    { value: FatigueStatus.Warning, name: `Warning`, description: `Warning` },
    { value: FatigueStatus.DriverManaged, name: `DriverManaged`, description: `Driver Managed` },
    { value: FatigueStatus.Violation, name: `Violation`, description: `Violation` }
];

export function getFatigueStatusDescriptor(value: FatigueStatus) {
    switch (value) {
        case FatigueStatus.Ok:
            return { value: FatigueStatus.Ok, name: `Ok`, description: `Ok` };
        case FatigueStatus.Warning:
            return { value: FatigueStatus.Warning, name: `Warning`, description: `Warning` };
        case FatigueStatus.DriverManaged:
            return { value: FatigueStatus.DriverManaged, name: `DriverManaged`, description: `Driver Managed` };
        case FatigueStatus.Violation:
            return { value: FatigueStatus.Violation, name: `Violation`, description: `Violation` };
    }
}
export enum FileNoteType {
    Compliment = 1,
    Reprimand = 2,
    General = 3,
    Accidents = 4,
    Incidents = 5,
    TrafficOffence = 6
}
export function fileNoteTypeDescription(value: FileNoteType) {
    switch (value) {
        case FileNoteType.Compliment:
            return `Compliment`;
        case FileNoteType.Reprimand:
            return `Reprimand`;
        case FileNoteType.General:
            return `General`;
        case FileNoteType.Accidents:
            return `Accidents`;
        case FileNoteType.Incidents:
            return `Incidents`;
        case FileNoteType.TrafficOffence:
            return `Traffic Offence`;
    }
}
export interface IFileNoteTypeDescriptor { value: FileNoteType; name: string; description: string; }
export var allFileNoteType: IFileNoteTypeDescriptor[] = [
    { value: FileNoteType.Compliment, name: `Compliment`, description: `Compliment` },
    { value: FileNoteType.Reprimand, name: `Reprimand`, description: `Reprimand` },
    { value: FileNoteType.General, name: `General`, description: `General` },
    { value: FileNoteType.Accidents, name: `Accidents`, description: `Accidents` },
    { value: FileNoteType.Incidents, name: `Incidents`, description: `Incidents` },
    { value: FileNoteType.TrafficOffence, name: `TrafficOffence`, description: `Traffic Offence` }
];

export function getFileNoteTypeDescriptor(value: FileNoteType) {
    switch (value) {
        case FileNoteType.Compliment:
            return { value: FileNoteType.Compliment, name: `Compliment`, description: `Compliment` };
        case FileNoteType.Reprimand:
            return { value: FileNoteType.Reprimand, name: `Reprimand`, description: `Reprimand` };
        case FileNoteType.General:
            return { value: FileNoteType.General, name: `General`, description: `General` };
        case FileNoteType.Accidents:
            return { value: FileNoteType.Accidents, name: `Accidents`, description: `Accidents` };
        case FileNoteType.Incidents:
            return { value: FileNoteType.Incidents, name: `Incidents`, description: `Incidents` };
        case FileNoteType.TrafficOffence:
            return { value: FileNoteType.TrafficOffence, name: `TrafficOffence`, description: `Traffic Offence` };
    }
}
export enum LicenceExpiryPeriod {
    Within10Days = 0,
    Month = 1,
    Quarter = 2,
    Exactly10Days = 3
}
export function licenceExpiryPeriodDescription(value: LicenceExpiryPeriod) {
    switch (value) {
        case LicenceExpiryPeriod.Within10Days:
            return `Within 10 Days`;
        case LicenceExpiryPeriod.Month:
            return `Month`;
        case LicenceExpiryPeriod.Quarter:
            return `Quarter`;
        case LicenceExpiryPeriod.Exactly10Days:
            return `Exactly 10 Days`;
    }
}
export interface ILicenceExpiryPeriodDescriptor { value: LicenceExpiryPeriod; name: string; description: string; }
export var allLicenceExpiryPeriod: ILicenceExpiryPeriodDescriptor[] = [
    { value: LicenceExpiryPeriod.Within10Days, name: `Within10Days`, description: `Within 10 Days` },
    { value: LicenceExpiryPeriod.Month, name: `Month`, description: `Month` },
    { value: LicenceExpiryPeriod.Quarter, name: `Quarter`, description: `Quarter` },
    { value: LicenceExpiryPeriod.Exactly10Days, name: `Exactly10Days`, description: `Exactly 10 Days` }
];

export function getLicenceExpiryPeriodDescriptor(value: LicenceExpiryPeriod) {
    switch (value) {
        case LicenceExpiryPeriod.Within10Days:
            return { value: LicenceExpiryPeriod.Within10Days, name: `Within10Days`, description: `Within 10 Days` };
        case LicenceExpiryPeriod.Month:
            return { value: LicenceExpiryPeriod.Month, name: `Month`, description: `Month` };
        case LicenceExpiryPeriod.Quarter:
            return { value: LicenceExpiryPeriod.Quarter, name: `Quarter`, description: `Quarter` };
        case LicenceExpiryPeriod.Exactly10Days:
            return { value: LicenceExpiryPeriod.Exactly10Days, name: `Exactly10Days`, description: `Exactly 10 Days` };
    }
}
export enum ComponentTypeServiceIntervalType {
    Day = 1,
    Km = 2
}
export function componentTypeServiceIntervalTypeDescription(value: ComponentTypeServiceIntervalType) {
    switch (value) {
        case ComponentTypeServiceIntervalType.Day:
            return `Day`;
        case ComponentTypeServiceIntervalType.Km:
            return `Km`;
    }
}
export interface IComponentTypeServiceIntervalTypeDescriptor { value: ComponentTypeServiceIntervalType; name: string; description: string; }
export var allComponentTypeServiceIntervalType: IComponentTypeServiceIntervalTypeDescriptor[] = [
    { value: ComponentTypeServiceIntervalType.Day, name: `Day`, description: `Day` },
    { value: ComponentTypeServiceIntervalType.Km, name: `Km`, description: `Km` }
];

export function getComponentTypeServiceIntervalTypeDescriptor(value: ComponentTypeServiceIntervalType) {
    switch (value) {
        case ComponentTypeServiceIntervalType.Day:
            return { value: ComponentTypeServiceIntervalType.Day, name: `Day`, description: `Day` };
        case ComponentTypeServiceIntervalType.Km:
            return { value: ComponentTypeServiceIntervalType.Km, name: `Km`, description: `Km` };
    }
}
export enum ActionType {
    Done = 0,
    Rebook = 2,
    RebookPartsRequired = 3,
    WorkInProgress = 4
}
export function actionTypeDescription(value: ActionType) {
    switch (value) {
        case ActionType.Done:
            return `Done`;
        case ActionType.Rebook:
            return `Rebook`;
        case ActionType.RebookPartsRequired:
            return `Rebook - Parts Required`;
        case ActionType.WorkInProgress:
            return `Work in Progress`;
    }
}
export interface IActionTypeDescriptor { value: ActionType; name: string; description: string; }
export var allActionType: IActionTypeDescriptor[] = [
    { value: ActionType.Done, name: `Done`, description: `Done` },
    { value: ActionType.Rebook, name: `Rebook`, description: `Rebook` },
    { value: ActionType.RebookPartsRequired, name: `RebookPartsRequired`, description: `Rebook - Parts Required` },
    { value: ActionType.WorkInProgress, name: `WorkInProgress`, description: `Work in Progress` }
];

export function getActionTypeDescriptor(value: ActionType) {
    switch (value) {
        case ActionType.Done:
            return { value: ActionType.Done, name: `Done`, description: `Done` };
        case ActionType.Rebook:
            return { value: ActionType.Rebook, name: `Rebook`, description: `Rebook` };
        case ActionType.RebookPartsRequired:
            return { value: ActionType.RebookPartsRequired, name: `RebookPartsRequired`, description: `Rebook - Parts Required` };
        case ActionType.WorkInProgress:
            return { value: ActionType.WorkInProgress, name: `WorkInProgress`, description: `Work in Progress` };
    }
}
export enum JobTaskState {
    Unchanged = 0,
    Future = 1,
    Scheduled = 2,
    Complete = 3
}
export function jobTaskStateDescription(value: JobTaskState) {
    switch (value) {
        case JobTaskState.Unchanged:
            return `Unchanged`;
        case JobTaskState.Future:
            return `Future`;
        case JobTaskState.Scheduled:
            return `Scheduled`;
        case JobTaskState.Complete:
            return `Complete`;
    }
}
export interface IJobTaskStateDescriptor { value: JobTaskState; name: string; description: string; }
export var allJobTaskState: IJobTaskStateDescriptor[] = [
    { value: JobTaskState.Unchanged, name: `Unchanged`, description: `Unchanged` },
    { value: JobTaskState.Future, name: `Future`, description: `Future` },
    { value: JobTaskState.Scheduled, name: `Scheduled`, description: `Scheduled` },
    { value: JobTaskState.Complete, name: `Complete`, description: `Complete` }
];

export function getJobTaskStateDescriptor(value: JobTaskState) {
    switch (value) {
        case JobTaskState.Unchanged:
            return { value: JobTaskState.Unchanged, name: `Unchanged`, description: `Unchanged` };
        case JobTaskState.Future:
            return { value: JobTaskState.Future, name: `Future`, description: `Future` };
        case JobTaskState.Scheduled:
            return { value: JobTaskState.Scheduled, name: `Scheduled`, description: `Scheduled` };
        case JobTaskState.Complete:
            return { value: JobTaskState.Complete, name: `Complete`, description: `Complete` };
    }
}
export enum DefectSource {
    Internal = 1,
    Machinery = 2
}
export function defectSourceDescription(value: DefectSource) {
    switch (value) {
        case DefectSource.Internal:
            return `Internal`;
        case DefectSource.Machinery:
            return `Machinery`;
    }
}
export interface IDefectSourceDescriptor { value: DefectSource; name: string; description: string; }
export var allDefectSource: IDefectSourceDescriptor[] = [
    { value: DefectSource.Internal, name: `Internal`, description: `Internal` },
    { value: DefectSource.Machinery, name: `Machinery`, description: `Machinery` }
];

export function getDefectSourceDescriptor(value: DefectSource) {
    switch (value) {
        case DefectSource.Internal:
            return { value: DefectSource.Internal, name: `Internal`, description: `Internal` };
        case DefectSource.Machinery:
            return { value: DefectSource.Machinery, name: `Machinery`, description: `Machinery` };
    }
}
export enum AssetStatus {
    Active = 1,
    Decommissioned = 2
}
export function assetStatusDescription(value: AssetStatus) {
    switch (value) {
        case AssetStatus.Active:
            return `Active`;
        case AssetStatus.Decommissioned:
            return `Decommissioned`;
    }
}
export interface IAssetStatusDescriptor { value: AssetStatus; name: string; description: string; }
export var allAssetStatus: IAssetStatusDescriptor[] = [
    { value: AssetStatus.Active, name: `Active`, description: `Active` },
    { value: AssetStatus.Decommissioned, name: `Decommissioned`, description: `Decommissioned` }
];

export function getAssetStatusDescriptor(value: AssetStatus) {
    switch (value) {
        case AssetStatus.Active:
            return { value: AssetStatus.Active, name: `Active`, description: `Active` };
        case AssetStatus.Decommissioned:
            return { value: AssetStatus.Decommissioned, name: `Decommissioned`, description: `Decommissioned` };
    }
}
export enum JobState {
    Active = 0,
    Complete = 1,
    Overdue = 2
}
export function jobStateDescription(value: JobState) {
    switch (value) {
        case JobState.Active:
            return `Active`;
        case JobState.Complete:
            return `Complete`;
        case JobState.Overdue:
            return `Overdue`;
    }
}
export interface IJobStateDescriptor { value: JobState; name: string; description: string; }
export var allJobState: IJobStateDescriptor[] = [
    { value: JobState.Active, name: `Active`, description: `Active` },
    { value: JobState.Complete, name: `Complete`, description: `Complete` },
    { value: JobState.Overdue, name: `Overdue`, description: `Overdue` }
];

export function getJobStateDescriptor(value: JobState) {
    switch (value) {
        case JobState.Active:
            return { value: JobState.Active, name: `Active`, description: `Active` };
        case JobState.Complete:
            return { value: JobState.Complete, name: `Complete`, description: `Complete` };
        case JobState.Overdue:
            return { value: JobState.Overdue, name: `Overdue`, description: `Overdue` };
    }
}
export enum CreditNoteType {
    ReturnedPart = 1
}
export function creditNoteTypeDescription(value: CreditNoteType) {
    switch (value) {
        case CreditNoteType.ReturnedPart:
            return `Returned Part`;
    }
}
export interface ICreditNoteTypeDescriptor { value: CreditNoteType; name: string; description: string; }
export var allCreditNoteType: ICreditNoteTypeDescriptor[] = [
    { value: CreditNoteType.ReturnedPart, name: `ReturnedPart`, description: `Returned Part` }
];

export function getCreditNoteTypeDescriptor(value: CreditNoteType) {
    switch (value) {
        case CreditNoteType.ReturnedPart:
            return { value: CreditNoteType.ReturnedPart, name: `ReturnedPart`, description: `Returned Part` };
    }
}
export enum InvoiceType {
    GoodsReceived = 0,
    CreditNote = 1
}
export function invoiceTypeDescription(value: InvoiceType) {
    switch (value) {
        case InvoiceType.GoodsReceived:
            return `Goods Received`;
        case InvoiceType.CreditNote:
            return `Credit Note`;
    }
}
export interface IInvoiceTypeDescriptor { value: InvoiceType; name: string; description: string; }
export var allInvoiceType: IInvoiceTypeDescriptor[] = [
    { value: InvoiceType.GoodsReceived, name: `GoodsReceived`, description: `Goods Received` },
    { value: InvoiceType.CreditNote, name: `CreditNote`, description: `Credit Note` }
];

export function getInvoiceTypeDescriptor(value: InvoiceType) {
    switch (value) {
        case InvoiceType.GoodsReceived:
            return { value: InvoiceType.GoodsReceived, name: `GoodsReceived`, description: `Goods Received` };
        case InvoiceType.CreditNote:
            return { value: InvoiceType.CreditNote, name: `CreditNote`, description: `Credit Note` };
    }
}
export enum LicenceClass {
    Car = 1,
    LightRigid = 2,
    MediumRigid = 3,
    HeavyRigid = 4,
    HeavyCombination = 5,
    MultiCombination = 6,
    Hire = 7,
    General = 8,
    Restricted = 9
}
export function licenceClassDescription(value: LicenceClass) {
    switch (value) {
        case LicenceClass.Car:
            return `C Car`;
        case LicenceClass.LightRigid:
            return `LR Light rigid`;
        case LicenceClass.MediumRigid:
            return `MR Medium rigid`;
        case LicenceClass.HeavyRigid:
            return `HR Heavy rigid`;
        case LicenceClass.HeavyCombination:
            return `HC Heavy combination`;
        case LicenceClass.MultiCombination:
            return `MC Multi-combination`;
        case LicenceClass.Hire:
            return `BHTX Booked Hire/Taxi`;
        case LicenceClass.General:
            return `GENR General`;
        case LicenceClass.Restricted:
            return `RSTR Restricted`;
    }
}
export interface ILicenceClassDescriptor { value: LicenceClass; name: string; description: string; }
export var allLicenceClass: ILicenceClassDescriptor[] = [
    { value: LicenceClass.Car, name: `Car`, description: `C Car` },
    { value: LicenceClass.LightRigid, name: `LightRigid`, description: `LR Light rigid` },
    { value: LicenceClass.MediumRigid, name: `MediumRigid`, description: `MR Medium rigid` },
    { value: LicenceClass.HeavyRigid, name: `HeavyRigid`, description: `HR Heavy rigid` },
    { value: LicenceClass.HeavyCombination, name: `HeavyCombination`, description: `HC Heavy combination` },
    { value: LicenceClass.MultiCombination, name: `MultiCombination`, description: `MC Multi-combination` },
    { value: LicenceClass.Hire, name: `Hire`, description: `BHTX Booked Hire/Taxi` },
    { value: LicenceClass.General, name: `General`, description: `GENR General` },
    { value: LicenceClass.Restricted, name: `Restricted`, description: `RSTR Restricted` }
];

export function getLicenceClassDescriptor(value: LicenceClass) {
    switch (value) {
        case LicenceClass.Car:
            return { value: LicenceClass.Car, name: `Car`, description: `C Car` };
        case LicenceClass.LightRigid:
            return { value: LicenceClass.LightRigid, name: `LightRigid`, description: `LR Light rigid` };
        case LicenceClass.MediumRigid:
            return { value: LicenceClass.MediumRigid, name: `MediumRigid`, description: `MR Medium rigid` };
        case LicenceClass.HeavyRigid:
            return { value: LicenceClass.HeavyRigid, name: `HeavyRigid`, description: `HR Heavy rigid` };
        case LicenceClass.HeavyCombination:
            return { value: LicenceClass.HeavyCombination, name: `HeavyCombination`, description: `HC Heavy combination` };
        case LicenceClass.MultiCombination:
            return { value: LicenceClass.MultiCombination, name: `MultiCombination`, description: `MC Multi-combination` };
        case LicenceClass.Hire:
            return { value: LicenceClass.Hire, name: `Hire`, description: `BHTX Booked Hire/Taxi` };
        case LicenceClass.General:
            return { value: LicenceClass.General, name: `General`, description: `GENR General` };
        case LicenceClass.Restricted:
            return { value: LicenceClass.Restricted, name: `Restricted`, description: `RSTR Restricted` };
    }
}
export enum LicenceCondition {
    A = 1,
    B = 2
}
export function licenceConditionDescription(value: LicenceCondition) {
    switch (value) {
        case LicenceCondition.A:
            return `A Automatic transmission only`;
        case LicenceCondition.B:
            return `B Synchromesh gearbox only`;
    }
}
export interface ILicenceConditionDescriptor { value: LicenceCondition; name: string; description: string; }
export var allLicenceCondition: ILicenceConditionDescriptor[] = [
    { value: LicenceCondition.A, name: `A`, description: `A Automatic transmission only` },
    { value: LicenceCondition.B, name: `B`, description: `B Synchromesh gearbox only` }
];

export function getLicenceConditionDescriptor(value: LicenceCondition) {
    switch (value) {
        case LicenceCondition.A:
            return { value: LicenceCondition.A, name: `A`, description: `A Automatic transmission only` };
        case LicenceCondition.B:
            return { value: LicenceCondition.B, name: `B`, description: `B Synchromesh gearbox only` };
    }
}
export enum BusUsageStatus {
    NotUsed = 1,
    Started = 2,
    Finished = 3
}
export function busUsageStatusDescription(value: BusUsageStatus) {
    switch (value) {
        case BusUsageStatus.NotUsed:
            return `Not used`;
        case BusUsageStatus.Started:
            return `Started`;
        case BusUsageStatus.Finished:
            return `Finished`;
    }
}
export interface IBusUsageStatusDescriptor { value: BusUsageStatus; name: string; description: string; }
export var allBusUsageStatus: IBusUsageStatusDescriptor[] = [
    { value: BusUsageStatus.NotUsed, name: `NotUsed`, description: `Not used` },
    { value: BusUsageStatus.Started, name: `Started`, description: `Started` },
    { value: BusUsageStatus.Finished, name: `Finished`, description: `Finished` }
];

export function getBusUsageStatusDescriptor(value: BusUsageStatus) {
    switch (value) {
        case BusUsageStatus.NotUsed:
            return { value: BusUsageStatus.NotUsed, name: `NotUsed`, description: `Not used` };
        case BusUsageStatus.Started:
            return { value: BusUsageStatus.Started, name: `Started`, description: `Started` };
        case BusUsageStatus.Finished:
            return { value: BusUsageStatus.Finished, name: `Finished`, description: `Finished` };
    }
}
export enum PartType {
    Part = 1,
    Service = 2
}
export function partTypeDescription(value: PartType) {
    switch (value) {
        case PartType.Part:
            return `Part`;
        case PartType.Service:
            return `Service`;
    }
}
export interface IPartTypeDescriptor { value: PartType; name: string; description: string; }
export var allPartType: IPartTypeDescriptor[] = [
    { value: PartType.Part, name: `Part`, description: `Part` },
    { value: PartType.Service, name: `Service`, description: `Service` }
];

export function getPartTypeDescriptor(value: PartType) {
    switch (value) {
        case PartType.Part:
            return { value: PartType.Part, name: `Part`, description: `Part` };
        case PartType.Service:
            return { value: PartType.Service, name: `Service`, description: `Service` };
    }
}
export enum BatchOperationStatus {
    Scheduled = 1,
    InProgress = 2,
    Succeeded = 3,
    Failed = 4,
    Deleted = 5
}
export function batchOperationStatusDescription(value: BatchOperationStatus) {
    switch (value) {
        case BatchOperationStatus.Scheduled:
            return `Scheduled`;
        case BatchOperationStatus.InProgress:
            return `In Progress`;
        case BatchOperationStatus.Succeeded:
            return `Succeeded`;
        case BatchOperationStatus.Failed:
            return `Failed`;
        case BatchOperationStatus.Deleted:
            return `Deleted`;
    }
}
export interface IBatchOperationStatusDescriptor { value: BatchOperationStatus; name: string; description: string; }
export var allBatchOperationStatus: IBatchOperationStatusDescriptor[] = [
    { value: BatchOperationStatus.Scheduled, name: `Scheduled`, description: `Scheduled` },
    { value: BatchOperationStatus.InProgress, name: `InProgress`, description: `In Progress` },
    { value: BatchOperationStatus.Succeeded, name: `Succeeded`, description: `Succeeded` },
    { value: BatchOperationStatus.Failed, name: `Failed`, description: `Failed` },
    { value: BatchOperationStatus.Deleted, name: `Deleted`, description: `Deleted` }
];

export function getBatchOperationStatusDescriptor(value: BatchOperationStatus) {
    switch (value) {
        case BatchOperationStatus.Scheduled:
            return { value: BatchOperationStatus.Scheduled, name: `Scheduled`, description: `Scheduled` };
        case BatchOperationStatus.InProgress:
            return { value: BatchOperationStatus.InProgress, name: `InProgress`, description: `In Progress` };
        case BatchOperationStatus.Succeeded:
            return { value: BatchOperationStatus.Succeeded, name: `Succeeded`, description: `Succeeded` };
        case BatchOperationStatus.Failed:
            return { value: BatchOperationStatus.Failed, name: `Failed`, description: `Failed` };
        case BatchOperationStatus.Deleted:
            return { value: BatchOperationStatus.Deleted, name: `Deleted`, description: `Deleted` };
    }
}
export enum States {
    AustralianCapitalTerritory = 1,
    NewSouthWales = 2,
    NorthernTerritory = 3,
    Queensland = 4,
    SouthAustralia = 5,
    Tasmania = 6,
    Victoria = 7,
    WesternAustralia = 8
}
export function statesDescription(value: States) {
    switch (value) {
        case States.AustralianCapitalTerritory:
            return `ACT`;
        case States.NewSouthWales:
            return `NSW`;
        case States.NorthernTerritory:
            return `NT`;
        case States.Queensland:
            return `QLD`;
        case States.SouthAustralia:
            return `SA`;
        case States.Tasmania:
            return `TAS`;
        case States.Victoria:
            return `VIC`;
        case States.WesternAustralia:
            return `WA`;
    }
}
export interface IStatesDescriptor { value: States; name: string; description: string; }
export var allStates: IStatesDescriptor[] = [
    { value: States.AustralianCapitalTerritory, name: `AustralianCapitalTerritory`, description: `ACT` },
    { value: States.NewSouthWales, name: `NewSouthWales`, description: `NSW` },
    { value: States.NorthernTerritory, name: `NorthernTerritory`, description: `NT` },
    { value: States.Queensland, name: `Queensland`, description: `QLD` },
    { value: States.SouthAustralia, name: `SouthAustralia`, description: `SA` },
    { value: States.Tasmania, name: `Tasmania`, description: `TAS` },
    { value: States.Victoria, name: `Victoria`, description: `VIC` },
    { value: States.WesternAustralia, name: `WesternAustralia`, description: `WA` }
];

export function getStatesDescriptor(value: States) {
    switch (value) {
        case States.AustralianCapitalTerritory:
            return { value: States.AustralianCapitalTerritory, name: `AustralianCapitalTerritory`, description: `ACT` };
        case States.NewSouthWales:
            return { value: States.NewSouthWales, name: `NewSouthWales`, description: `NSW` };
        case States.NorthernTerritory:
            return { value: States.NorthernTerritory, name: `NorthernTerritory`, description: `NT` };
        case States.Queensland:
            return { value: States.Queensland, name: `Queensland`, description: `QLD` };
        case States.SouthAustralia:
            return { value: States.SouthAustralia, name: `SouthAustralia`, description: `SA` };
        case States.Tasmania:
            return { value: States.Tasmania, name: `Tasmania`, description: `TAS` };
        case States.Victoria:
            return { value: States.Victoria, name: `Victoria`, description: `VIC` };
        case States.WesternAustralia:
            return { value: States.WesternAustralia, name: `WesternAustralia`, description: `WA` };
    }
}
export enum ConflictCriteria {
    UnacceptedStaffMember = 1,
    UnacceptedVehicle = 2,
    AcceptedDriver = 3,
    AcceptedVehicle = 4
}
export function conflictCriteriaDescription(value: ConflictCriteria) {
    switch (value) {
        case ConflictCriteria.UnacceptedStaffMember:
            return `Unaccepted Staff Member`;
        case ConflictCriteria.UnacceptedVehicle:
            return `Unaccepted Vehicle`;
        case ConflictCriteria.AcceptedDriver:
            return `Accepted Staff Member`;
        case ConflictCriteria.AcceptedVehicle:
            return `Accepted Vehicle`;
    }
}
export interface IConflictCriteriaDescriptor { value: ConflictCriteria; name: string; description: string; }
export var allConflictCriteria: IConflictCriteriaDescriptor[] = [
    { value: ConflictCriteria.UnacceptedStaffMember, name: `UnacceptedStaffMember`, description: `Unaccepted Staff Member` },
    { value: ConflictCriteria.UnacceptedVehicle, name: `UnacceptedVehicle`, description: `Unaccepted Vehicle` },
    { value: ConflictCriteria.AcceptedDriver, name: `AcceptedDriver`, description: `Accepted Staff Member` },
    { value: ConflictCriteria.AcceptedVehicle, name: `AcceptedVehicle`, description: `Accepted Vehicle` }
];

export function getConflictCriteriaDescriptor(value: ConflictCriteria) {
    switch (value) {
        case ConflictCriteria.UnacceptedStaffMember:
            return { value: ConflictCriteria.UnacceptedStaffMember, name: `UnacceptedStaffMember`, description: `Unaccepted Staff Member` };
        case ConflictCriteria.UnacceptedVehicle:
            return { value: ConflictCriteria.UnacceptedVehicle, name: `UnacceptedVehicle`, description: `Unaccepted Vehicle` };
        case ConflictCriteria.AcceptedDriver:
            return { value: ConflictCriteria.AcceptedDriver, name: `AcceptedDriver`, description: `Accepted Staff Member` };
        case ConflictCriteria.AcceptedVehicle:
            return { value: ConflictCriteria.AcceptedVehicle, name: `AcceptedVehicle`, description: `Accepted Vehicle` };
    }
}
export enum ConflictStatus {
    Unaccepted = 1,
    Accepted = 2
}
export function conflictStatusDescription(value: ConflictStatus) {
    switch (value) {
        case ConflictStatus.Unaccepted:
            return `Unaccepted`;
        case ConflictStatus.Accepted:
            return `Accepted`;
    }
}
export interface IConflictStatusDescriptor { value: ConflictStatus; name: string; description: string; }
export var allConflictStatus: IConflictStatusDescriptor[] = [
    { value: ConflictStatus.Unaccepted, name: `Unaccepted`, description: `Unaccepted` },
    { value: ConflictStatus.Accepted, name: `Accepted`, description: `Accepted` }
];

export function getConflictStatusDescriptor(value: ConflictStatus) {
    switch (value) {
        case ConflictStatus.Unaccepted:
            return { value: ConflictStatus.Unaccepted, name: `Unaccepted`, description: `Unaccepted` };
        case ConflictStatus.Accepted:
            return { value: ConflictStatus.Accepted, name: `Accepted`, description: `Accepted` };
    }
}
export enum ConflictType {
    StaffMember = 1,
    Vehicle = 2,
    WorkshopJobAsset = 3,
    PeopleLeave = 4,
    VehicleMarkedOutOfService = 5,
    DriverLicence = 6,
    DriverAuthorisation = 7
}
export function conflictTypeDescription(value: ConflictType) {
    switch (value) {
        case ConflictType.StaffMember:
            return `Staff Member`;
        case ConflictType.Vehicle:
            return `Vehicle`;
        case ConflictType.WorkshopJobAsset:
            return `Workshop Job Asset`;
        case ConflictType.PeopleLeave:
            return `Staff Leave`;
        case ConflictType.VehicleMarkedOutOfService:
            return `Vehicle marked out of service`;
        case ConflictType.DriverLicence:
            return `Driver Licence`;
        case ConflictType.DriverAuthorisation:
            return `Driver Authorisation`;
    }
}
export interface IConflictTypeDescriptor { value: ConflictType; name: string; description: string; }
export var allConflictType: IConflictTypeDescriptor[] = [
    { value: ConflictType.StaffMember, name: `StaffMember`, description: `Staff Member` },
    { value: ConflictType.Vehicle, name: `Vehicle`, description: `Vehicle` },
    { value: ConflictType.WorkshopJobAsset, name: `WorkshopJobAsset`, description: `Workshop Job Asset` },
    { value: ConflictType.PeopleLeave, name: `PeopleLeave`, description: `Staff Leave` },
    { value: ConflictType.VehicleMarkedOutOfService, name: `VehicleMarkedOutOfService`, description: `Vehicle marked out of service` },
    { value: ConflictType.DriverLicence, name: `DriverLicence`, description: `Driver Licence` },
    { value: ConflictType.DriverAuthorisation, name: `DriverAuthorisation`, description: `Driver Authorisation` }
];

export function getConflictTypeDescriptor(value: ConflictType) {
    switch (value) {
        case ConflictType.StaffMember:
            return { value: ConflictType.StaffMember, name: `StaffMember`, description: `Staff Member` };
        case ConflictType.Vehicle:
            return { value: ConflictType.Vehicle, name: `Vehicle`, description: `Vehicle` };
        case ConflictType.WorkshopJobAsset:
            return { value: ConflictType.WorkshopJobAsset, name: `WorkshopJobAsset`, description: `Workshop Job Asset` };
        case ConflictType.PeopleLeave:
            return { value: ConflictType.PeopleLeave, name: `PeopleLeave`, description: `Staff Leave` };
        case ConflictType.VehicleMarkedOutOfService:
            return { value: ConflictType.VehicleMarkedOutOfService, name: `VehicleMarkedOutOfService`, description: `Vehicle marked out of service` };
        case ConflictType.DriverLicence:
            return { value: ConflictType.DriverLicence, name: `DriverLicence`, description: `Driver Licence` };
        case ConflictType.DriverAuthorisation:
            return { value: ConflictType.DriverAuthorisation, name: `DriverAuthorisation`, description: `Driver Authorisation` };
    }
}
export enum HolidayType {
    PublicHoliday = 1,
    SchoolHoliday = 2
}
export function holidayTypeDescription(value: HolidayType) {
    switch (value) {
        case HolidayType.PublicHoliday:
            return `Public Holiday`;
        case HolidayType.SchoolHoliday:
            return `School Holiday`;
    }
}
export interface IHolidayTypeDescriptor { value: HolidayType; name: string; description: string; }
export var allHolidayType: IHolidayTypeDescriptor[] = [
    { value: HolidayType.PublicHoliday, name: `PublicHoliday`, description: `Public Holiday` },
    { value: HolidayType.SchoolHoliday, name: `SchoolHoliday`, description: `School Holiday` }
];

export function getHolidayTypeDescriptor(value: HolidayType) {
    switch (value) {
        case HolidayType.PublicHoliday:
            return { value: HolidayType.PublicHoliday, name: `PublicHoliday`, description: `Public Holiday` };
        case HolidayType.SchoolHoliday:
            return { value: HolidayType.SchoolHoliday, name: `SchoolHoliday`, description: `School Holiday` };
    }
}
export enum AdhocReason {
    VehicleReplacement = 1,
    DriverReplacement = 2,
    VehicleRelocation = 3,
    Other = 4,
    Cleaning = 5,
    Training = 6
}
export function adhocReasonDescription(value: AdhocReason) {
    switch (value) {
        case AdhocReason.VehicleReplacement:
            return `Vehicle Replacement`;
        case AdhocReason.DriverReplacement:
            return `Driver Replacement`;
        case AdhocReason.VehicleRelocation:
            return `Vehicle Relocation`;
        case AdhocReason.Other:
            return `Other`;
        case AdhocReason.Cleaning:
            return `Cleaning`;
        case AdhocReason.Training:
            return `Training`;
    }
}
export interface IAdhocReasonDescriptor { value: AdhocReason; name: string; description: string; }
export var allAdhocReason: IAdhocReasonDescriptor[] = [
    { value: AdhocReason.VehicleReplacement, name: `VehicleReplacement`, description: `Vehicle Replacement` },
    { value: AdhocReason.DriverReplacement, name: `DriverReplacement`, description: `Driver Replacement` },
    { value: AdhocReason.VehicleRelocation, name: `VehicleRelocation`, description: `Vehicle Relocation` },
    { value: AdhocReason.Other, name: `Other`, description: `Other` },
    { value: AdhocReason.Cleaning, name: `Cleaning`, description: `Cleaning` },
    { value: AdhocReason.Training, name: `Training`, description: `Training` }
];

export function getAdhocReasonDescriptor(value: AdhocReason) {
    switch (value) {
        case AdhocReason.VehicleReplacement:
            return { value: AdhocReason.VehicleReplacement, name: `VehicleReplacement`, description: `Vehicle Replacement` };
        case AdhocReason.DriverReplacement:
            return { value: AdhocReason.DriverReplacement, name: `DriverReplacement`, description: `Driver Replacement` };
        case AdhocReason.VehicleRelocation:
            return { value: AdhocReason.VehicleRelocation, name: `VehicleRelocation`, description: `Vehicle Relocation` };
        case AdhocReason.Other:
            return { value: AdhocReason.Other, name: `Other`, description: `Other` };
        case AdhocReason.Cleaning:
            return { value: AdhocReason.Cleaning, name: `Cleaning`, description: `Cleaning` };
        case AdhocReason.Training:
            return { value: AdhocReason.Training, name: `Training`, description: `Training` };
    }
}
export enum FatigueBreakSource {
    Tablet = 1,
    MixTelematics = 2,
    Other = 3
}
export function fatigueBreakSourceDescription(value: FatigueBreakSource) {
    switch (value) {
        case FatigueBreakSource.Tablet:
            return `Tablet`;
        case FatigueBreakSource.MixTelematics:
            return `MixTelematics`;
        case FatigueBreakSource.Other:
            return `Other`;
    }
}
export interface IFatigueBreakSourceDescriptor { value: FatigueBreakSource; name: string; description: string; }
export var allFatigueBreakSource: IFatigueBreakSourceDescriptor[] = [
    { value: FatigueBreakSource.Tablet, name: `Tablet`, description: `Tablet` },
    { value: FatigueBreakSource.MixTelematics, name: `MixTelematics`, description: `MixTelematics` },
    { value: FatigueBreakSource.Other, name: `Other`, description: `Other` }
];

export function getFatigueBreakSourceDescriptor(value: FatigueBreakSource) {
    switch (value) {
        case FatigueBreakSource.Tablet:
            return { value: FatigueBreakSource.Tablet, name: `Tablet`, description: `Tablet` };
        case FatigueBreakSource.MixTelematics:
            return { value: FatigueBreakSource.MixTelematics, name: `MixTelematics`, description: `MixTelematics` };
        case FatigueBreakSource.Other:
            return { value: FatigueBreakSource.Other, name: `Other`, description: `Other` };
    }
}
export enum JobStatus {
    Incomplete = 1,
    NotAcknowledged = 2,
    Acknowledged = 3,
    Completed = 4
}
export function jobStatusDescription(value: JobStatus) {
    switch (value) {
        case JobStatus.Incomplete:
            return `Incomplete`;
        case JobStatus.NotAcknowledged:
            return `Not Acknowledged`;
        case JobStatus.Acknowledged:
            return `Acknowledged`;
        case JobStatus.Completed:
            return `Completed`;
    }
}
export interface IJobStatusDescriptor { value: JobStatus; name: string; description: string; }
export var allJobStatus: IJobStatusDescriptor[] = [
    { value: JobStatus.Incomplete, name: `Incomplete`, description: `Incomplete` },
    { value: JobStatus.NotAcknowledged, name: `NotAcknowledged`, description: `Not Acknowledged` },
    { value: JobStatus.Acknowledged, name: `Acknowledged`, description: `Acknowledged` },
    { value: JobStatus.Completed, name: `Completed`, description: `Completed` }
];

export function getJobStatusDescriptor(value: JobStatus) {
    switch (value) {
        case JobStatus.Incomplete:
            return { value: JobStatus.Incomplete, name: `Incomplete`, description: `Incomplete` };
        case JobStatus.NotAcknowledged:
            return { value: JobStatus.NotAcknowledged, name: `NotAcknowledged`, description: `Not Acknowledged` };
        case JobStatus.Acknowledged:
            return { value: JobStatus.Acknowledged, name: `Acknowledged`, description: `Acknowledged` };
        case JobStatus.Completed:
            return { value: JobStatus.Completed, name: `Completed`, description: `Completed` };
    }
}
export enum JobType {
    Urban = 1,
    SchoolService = 2,
    AsRequired = 3,
    Rail = 4,
    Workshop = 5,
    Charter = 6,
    Cleaning = 7,
    Operations = 8,
    RailMarshaller = 9,
    RailSupervisor = 10,
    RailCustomerService = 11,
    GeneralTraining = 12,
    Yard = 13,
    CharterStaged = 17,
    DriverRelocation = 18,
    VehicleRelocation = 19,
    OperationsSupervisor = 20,
    RailEmergency = 21,
    TestDrive = 22,
    Breakdown = 23,
    VehicleUnavailable = 24,
    Office = 25,
    ContractCharter = 26
}
export function jobTypeDescription(value: JobType) {
    switch (value) {
        case JobType.Urban:
            return `Urban`;
        case JobType.SchoolService:
            return `School Service`;
        case JobType.AsRequired:
            return `As Required`;
        case JobType.Rail:
            return `Rail`;
        case JobType.Workshop:
            return `Workshop`;
        case JobType.Charter:
            return `Charter`;
        case JobType.Cleaning:
            return `Cleaning`;
        case JobType.Operations:
            return `Operations`;
        case JobType.RailMarshaller:
            return `Rail Marshaller`;
        case JobType.RailSupervisor:
            return `Rail Supervisor`;
        case JobType.RailCustomerService:
            return `Rail Customer Service`;
        case JobType.GeneralTraining:
            return `General Training`;
        case JobType.Yard:
            return `Yard`;
        case JobType.CharterStaged:
            return `Charter Staged`;
        case JobType.DriverRelocation:
            return `Driver Relocation`;
        case JobType.VehicleRelocation:
            return `Vehicle Relocation`;
        case JobType.OperationsSupervisor:
            return `Operations Supervisor`;
        case JobType.RailEmergency:
            return `Rail Emergency`;
        case JobType.TestDrive:
            return `Test Drive`;
        case JobType.Breakdown:
            return `Breakdown`;
        case JobType.VehicleUnavailable:
            return `Vehicle Unavailable`;
        case JobType.Office:
            return `Office`;
        case JobType.ContractCharter:
            return `Contract Charter`;
    }
}
export interface IJobTypeDescriptor { value: JobType; name: string; description: string; }
export var allJobType: IJobTypeDescriptor[] = [
    { value: JobType.Urban, name: `Urban`, description: `Urban` },
    { value: JobType.SchoolService, name: `SchoolService`, description: `School Service` },
    { value: JobType.AsRequired, name: `AsRequired`, description: `As Required` },
    { value: JobType.Rail, name: `Rail`, description: `Rail` },
    { value: JobType.Workshop, name: `Workshop`, description: `Workshop` },
    { value: JobType.Charter, name: `Charter`, description: `Charter` },
    { value: JobType.Cleaning, name: `Cleaning`, description: `Cleaning` },
    { value: JobType.Operations, name: `Operations`, description: `Operations` },
    { value: JobType.RailMarshaller, name: `RailMarshaller`, description: `Rail Marshaller` },
    { value: JobType.RailSupervisor, name: `RailSupervisor`, description: `Rail Supervisor` },
    { value: JobType.RailCustomerService, name: `RailCustomerService`, description: `Rail Customer Service` },
    { value: JobType.GeneralTraining, name: `GeneralTraining`, description: `General Training` },
    { value: JobType.Yard, name: `Yard`, description: `Yard` },
    { value: JobType.CharterStaged, name: `CharterStaged`, description: `Charter Staged` },
    { value: JobType.DriverRelocation, name: `DriverRelocation`, description: `Driver Relocation` },
    { value: JobType.VehicleRelocation, name: `VehicleRelocation`, description: `Vehicle Relocation` },
    { value: JobType.OperationsSupervisor, name: `OperationsSupervisor`, description: `Operations Supervisor` },
    { value: JobType.RailEmergency, name: `RailEmergency`, description: `Rail Emergency` },
    { value: JobType.TestDrive, name: `TestDrive`, description: `Test Drive` },
    { value: JobType.Breakdown, name: `Breakdown`, description: `Breakdown` },
    { value: JobType.VehicleUnavailable, name: `VehicleUnavailable`, description: `Vehicle Unavailable` },
    { value: JobType.Office, name: `Office`, description: `Office` },
    { value: JobType.ContractCharter, name: `ContractCharter`, description: `Contract Charter` }
];

export function getJobTypeDescriptor(value: JobType) {
    switch (value) {
        case JobType.Urban:
            return { value: JobType.Urban, name: `Urban`, description: `Urban` };
        case JobType.SchoolService:
            return { value: JobType.SchoolService, name: `SchoolService`, description: `School Service` };
        case JobType.AsRequired:
            return { value: JobType.AsRequired, name: `AsRequired`, description: `As Required` };
        case JobType.Rail:
            return { value: JobType.Rail, name: `Rail`, description: `Rail` };
        case JobType.Workshop:
            return { value: JobType.Workshop, name: `Workshop`, description: `Workshop` };
        case JobType.Charter:
            return { value: JobType.Charter, name: `Charter`, description: `Charter` };
        case JobType.Cleaning:
            return { value: JobType.Cleaning, name: `Cleaning`, description: `Cleaning` };
        case JobType.Operations:
            return { value: JobType.Operations, name: `Operations`, description: `Operations` };
        case JobType.RailMarshaller:
            return { value: JobType.RailMarshaller, name: `RailMarshaller`, description: `Rail Marshaller` };
        case JobType.RailSupervisor:
            return { value: JobType.RailSupervisor, name: `RailSupervisor`, description: `Rail Supervisor` };
        case JobType.RailCustomerService:
            return { value: JobType.RailCustomerService, name: `RailCustomerService`, description: `Rail Customer Service` };
        case JobType.GeneralTraining:
            return { value: JobType.GeneralTraining, name: `GeneralTraining`, description: `General Training` };
        case JobType.Yard:
            return { value: JobType.Yard, name: `Yard`, description: `Yard` };
        case JobType.CharterStaged:
            return { value: JobType.CharterStaged, name: `CharterStaged`, description: `Charter Staged` };
        case JobType.DriverRelocation:
            return { value: JobType.DriverRelocation, name: `DriverRelocation`, description: `Driver Relocation` };
        case JobType.VehicleRelocation:
            return { value: JobType.VehicleRelocation, name: `VehicleRelocation`, description: `Vehicle Relocation` };
        case JobType.OperationsSupervisor:
            return { value: JobType.OperationsSupervisor, name: `OperationsSupervisor`, description: `Operations Supervisor` };
        case JobType.RailEmergency:
            return { value: JobType.RailEmergency, name: `RailEmergency`, description: `Rail Emergency` };
        case JobType.TestDrive:
            return { value: JobType.TestDrive, name: `TestDrive`, description: `Test Drive` };
        case JobType.Breakdown:
            return { value: JobType.Breakdown, name: `Breakdown`, description: `Breakdown` };
        case JobType.VehicleUnavailable:
            return { value: JobType.VehicleUnavailable, name: `VehicleUnavailable`, description: `Vehicle Unavailable` };
        case JobType.Office:
            return { value: JobType.Office, name: `Office`, description: `Office` };
        case JobType.ContractCharter:
            return { value: JobType.ContractCharter, name: `ContractCharter`, description: `Contract Charter` };
    }
}
export enum CostType {
    Kilometers = 1,
    Contract = 2
}
export function costTypeDescription(value: CostType) {
    switch (value) {
        case CostType.Kilometers:
            return `Kilometers`;
        case CostType.Contract:
            return `Contract`;
    }
}
export interface ICostTypeDescriptor { value: CostType; name: string; description: string; }
export var allCostType: ICostTypeDescriptor[] = [
    { value: CostType.Kilometers, name: `Kilometers`, description: `Kilometers` },
    { value: CostType.Contract, name: `Contract`, description: `Contract` }
];

export function getCostTypeDescriptor(value: CostType) {
    switch (value) {
        case CostType.Kilometers:
            return { value: CostType.Kilometers, name: `Kilometers`, description: `Kilometers` };
        case CostType.Contract:
            return { value: CostType.Contract, name: `Contract`, description: `Contract` };
    }
}
export enum QuoteStatus {
    Quoted = 1,
    Booked = 2,
    Declined = 3,
    Cancelled = 4,
    Completed = 5,
    CancelledInProgress = 6
}
export function quoteStatusDescription(value: QuoteStatus) {
    switch (value) {
        case QuoteStatus.Quoted:
            return `Quoted`;
        case QuoteStatus.Booked:
            return `Booked`;
        case QuoteStatus.Declined:
            return `Declined`;
        case QuoteStatus.Cancelled:
            return `Cancelled`;
        case QuoteStatus.Completed:
            return `Completed`;
        case QuoteStatus.CancelledInProgress:
            return `Cancelled In Progress`;
    }
}
export interface IQuoteStatusDescriptor { value: QuoteStatus; name: string; description: string; }
export var allQuoteStatus: IQuoteStatusDescriptor[] = [
    { value: QuoteStatus.Quoted, name: `Quoted`, description: `Quoted` },
    { value: QuoteStatus.Booked, name: `Booked`, description: `Booked` },
    { value: QuoteStatus.Declined, name: `Declined`, description: `Declined` },
    { value: QuoteStatus.Cancelled, name: `Cancelled`, description: `Cancelled` },
    { value: QuoteStatus.Completed, name: `Completed`, description: `Completed` },
    { value: QuoteStatus.CancelledInProgress, name: `CancelledInProgress`, description: `Cancelled In Progress` }
];

export function getQuoteStatusDescriptor(value: QuoteStatus) {
    switch (value) {
        case QuoteStatus.Quoted:
            return { value: QuoteStatus.Quoted, name: `Quoted`, description: `Quoted` };
        case QuoteStatus.Booked:
            return { value: QuoteStatus.Booked, name: `Booked`, description: `Booked` };
        case QuoteStatus.Declined:
            return { value: QuoteStatus.Declined, name: `Declined`, description: `Declined` };
        case QuoteStatus.Cancelled:
            return { value: QuoteStatus.Cancelled, name: `Cancelled`, description: `Cancelled` };
        case QuoteStatus.Completed:
            return { value: QuoteStatus.Completed, name: `Completed`, description: `Completed` };
        case QuoteStatus.CancelledInProgress:
            return { value: QuoteStatus.CancelledInProgress, name: `CancelledInProgress`, description: `Cancelled In Progress` };
    }
}
export enum ShiftType {
    Urban = 1,
    SchoolService = 2,
    AsRequired = 3,
    Cleaning = 7,
    Operations = 8,
    Yard = 13,
    Office = 25,
    ContractCharter = 26
}
export function shiftTypeDescription(value: ShiftType) {
    switch (value) {
        case ShiftType.Urban:
            return `Urban`;
        case ShiftType.SchoolService:
            return `School Service`;
        case ShiftType.AsRequired:
            return `As Required`;
        case ShiftType.Cleaning:
            return `Cleaning`;
        case ShiftType.Operations:
            return `Operations`;
        case ShiftType.Yard:
            return `Yard`;
        case ShiftType.Office:
            return `Office`;
        case ShiftType.ContractCharter:
            return `Contract Charter`;
    }
}
export interface IShiftTypeDescriptor { value: ShiftType; name: string; description: string; }
export var allShiftType: IShiftTypeDescriptor[] = [
    { value: ShiftType.Urban, name: `Urban`, description: `Urban` },
    { value: ShiftType.SchoolService, name: `SchoolService`, description: `School Service` },
    { value: ShiftType.AsRequired, name: `AsRequired`, description: `As Required` },
    { value: ShiftType.Cleaning, name: `Cleaning`, description: `Cleaning` },
    { value: ShiftType.Operations, name: `Operations`, description: `Operations` },
    { value: ShiftType.Yard, name: `Yard`, description: `Yard` },
    { value: ShiftType.Office, name: `Office`, description: `Office` },
    { value: ShiftType.ContractCharter, name: `ContractCharter`, description: `Contract Charter` }
];

export function getShiftTypeDescriptor(value: ShiftType) {
    switch (value) {
        case ShiftType.Urban:
            return { value: ShiftType.Urban, name: `Urban`, description: `Urban` };
        case ShiftType.SchoolService:
            return { value: ShiftType.SchoolService, name: `SchoolService`, description: `School Service` };
        case ShiftType.AsRequired:
            return { value: ShiftType.AsRequired, name: `AsRequired`, description: `As Required` };
        case ShiftType.Cleaning:
            return { value: ShiftType.Cleaning, name: `Cleaning`, description: `Cleaning` };
        case ShiftType.Operations:
            return { value: ShiftType.Operations, name: `Operations`, description: `Operations` };
        case ShiftType.Yard:
            return { value: ShiftType.Yard, name: `Yard`, description: `Yard` };
        case ShiftType.Office:
            return { value: ShiftType.Office, name: `Office`, description: `Office` };
        case ShiftType.ContractCharter:
            return { value: ShiftType.ContractCharter, name: `ContractCharter`, description: `Contract Charter` };
    }
}
export enum DriverMovement {
    Nothing = 1,
    ClockedOn = 2,
    NotClockedOn = 3
}
export function driverMovementDescription(value: DriverMovement) {
    switch (value) {
        case DriverMovement.Nothing:
            return `Nothing`;
        case DriverMovement.ClockedOn:
            return `Clocked On`;
        case DriverMovement.NotClockedOn:
            return `Not Clocked On`;
    }
}
export interface IDriverMovementDescriptor { value: DriverMovement; name: string; description: string; }
export var allDriverMovement: IDriverMovementDescriptor[] = [
    { value: DriverMovement.Nothing, name: `Nothing`, description: `Nothing` },
    { value: DriverMovement.ClockedOn, name: `ClockedOn`, description: `Clocked On` },
    { value: DriverMovement.NotClockedOn, name: `NotClockedOn`, description: `Not Clocked On` }
];

export function getDriverMovementDescriptor(value: DriverMovement) {
    switch (value) {
        case DriverMovement.Nothing:
            return { value: DriverMovement.Nothing, name: `Nothing`, description: `Nothing` };
        case DriverMovement.ClockedOn:
            return { value: DriverMovement.ClockedOn, name: `ClockedOn`, description: `Clocked On` };
        case DriverMovement.NotClockedOn:
            return { value: DriverMovement.NotClockedOn, name: `NotClockedOn`, description: `Not Clocked On` };
    }
}
export enum AssetCategory {
    Fleet = 1,
    Plant = 2
}
export function assetCategoryDescription(value: AssetCategory) {
    switch (value) {
        case AssetCategory.Fleet:
            return `Fleet`;
        case AssetCategory.Plant:
            return `Plant`;
    }
}
export interface IAssetCategoryDescriptor { value: AssetCategory; name: string; description: string; }
export var allAssetCategory: IAssetCategoryDescriptor[] = [
    { value: AssetCategory.Fleet, name: `Fleet`, description: `Fleet` },
    { value: AssetCategory.Plant, name: `Plant`, description: `Plant` }
];

export function getAssetCategoryDescriptor(value: AssetCategory) {
    switch (value) {
        case AssetCategory.Fleet:
            return { value: AssetCategory.Fleet, name: `Fleet`, description: `Fleet` };
        case AssetCategory.Plant:
            return { value: AssetCategory.Plant, name: `Plant`, description: `Plant` };
    }
}
export enum WorkshopConflictCriteria {
    UnacceptedStaffMember = 1,
    AcceptedStaffMember = 2
}
export function workshopConflictCriteriaDescription(value: WorkshopConflictCriteria) {
    switch (value) {
        case WorkshopConflictCriteria.UnacceptedStaffMember:
            return `Unaccepted Staff Member`;
        case WorkshopConflictCriteria.AcceptedStaffMember:
            return `Accepted Staff Member`;
    }
}
export interface IWorkshopConflictCriteriaDescriptor { value: WorkshopConflictCriteria; name: string; description: string; }
export var allWorkshopConflictCriteria: IWorkshopConflictCriteriaDescriptor[] = [
    { value: WorkshopConflictCriteria.UnacceptedStaffMember, name: `UnacceptedStaffMember`, description: `Unaccepted Staff Member` },
    { value: WorkshopConflictCriteria.AcceptedStaffMember, name: `AcceptedStaffMember`, description: `Accepted Staff Member` }
];

export function getWorkshopConflictCriteriaDescriptor(value: WorkshopConflictCriteria) {
    switch (value) {
        case WorkshopConflictCriteria.UnacceptedStaffMember:
            return { value: WorkshopConflictCriteria.UnacceptedStaffMember, name: `UnacceptedStaffMember`, description: `Unaccepted Staff Member` };
        case WorkshopConflictCriteria.AcceptedStaffMember:
            return { value: WorkshopConflictCriteria.AcceptedStaffMember, name: `AcceptedStaffMember`, description: `Accepted Staff Member` };
    }
}
export enum WorkshopConflictStatus {
    Unaccepted = 1,
    Accepted = 2
}
export function workshopConflictStatusDescription(value: WorkshopConflictStatus) {
    switch (value) {
        case WorkshopConflictStatus.Unaccepted:
            return `Unaccepted`;
        case WorkshopConflictStatus.Accepted:
            return `Accepted`;
    }
}
export interface IWorkshopConflictStatusDescriptor { value: WorkshopConflictStatus; name: string; description: string; }
export var allWorkshopConflictStatus: IWorkshopConflictStatusDescriptor[] = [
    { value: WorkshopConflictStatus.Unaccepted, name: `Unaccepted`, description: `Unaccepted` },
    { value: WorkshopConflictStatus.Accepted, name: `Accepted`, description: `Accepted` }
];

export function getWorkshopConflictStatusDescriptor(value: WorkshopConflictStatus) {
    switch (value) {
        case WorkshopConflictStatus.Unaccepted:
            return { value: WorkshopConflictStatus.Unaccepted, name: `Unaccepted`, description: `Unaccepted` };
        case WorkshopConflictStatus.Accepted:
            return { value: WorkshopConflictStatus.Accepted, name: `Accepted`, description: `Accepted` };
    }
}
export enum WorkshopConflictType {
    StaffMember = 1,
    PeopleLeave = 2
}
export function workshopConflictTypeDescription(value: WorkshopConflictType) {
    switch (value) {
        case WorkshopConflictType.StaffMember:
            return `Staff Member`;
        case WorkshopConflictType.PeopleLeave:
            return `Staff Leave`;
    }
}
export interface IWorkshopConflictTypeDescriptor { value: WorkshopConflictType; name: string; description: string; }
export var allWorkshopConflictType: IWorkshopConflictTypeDescriptor[] = [
    { value: WorkshopConflictType.StaffMember, name: `StaffMember`, description: `Staff Member` },
    { value: WorkshopConflictType.PeopleLeave, name: `PeopleLeave`, description: `Staff Leave` }
];

export function getWorkshopConflictTypeDescriptor(value: WorkshopConflictType) {
    switch (value) {
        case WorkshopConflictType.StaffMember:
            return { value: WorkshopConflictType.StaffMember, name: `StaffMember`, description: `Staff Member` };
        case WorkshopConflictType.PeopleLeave:
            return { value: WorkshopConflictType.PeopleLeave, name: `PeopleLeave`, description: `Staff Leave` };
    }
}
export enum DefectRaisedBy {
    Operations = 1,
    Workshop = 2,
    Driver = 3
}
export function defectRaisedByDescription(value: DefectRaisedBy) {
    switch (value) {
        case DefectRaisedBy.Operations:
            return `Operations`;
        case DefectRaisedBy.Workshop:
            return `Workshop`;
        case DefectRaisedBy.Driver:
            return `Driver`;
    }
}
export interface IDefectRaisedByDescriptor { value: DefectRaisedBy; name: string; description: string; }
export var allDefectRaisedBy: IDefectRaisedByDescriptor[] = [
    { value: DefectRaisedBy.Operations, name: `Operations`, description: `Operations` },
    { value: DefectRaisedBy.Workshop, name: `Workshop`, description: `Workshop` },
    { value: DefectRaisedBy.Driver, name: `Driver`, description: `Driver` }
];

export function getDefectRaisedByDescriptor(value: DefectRaisedBy) {
    switch (value) {
        case DefectRaisedBy.Operations:
            return { value: DefectRaisedBy.Operations, name: `Operations`, description: `Operations` };
        case DefectRaisedBy.Workshop:
            return { value: DefectRaisedBy.Workshop, name: `Workshop`, description: `Workshop` };
        case DefectRaisedBy.Driver:
            return { value: DefectRaisedBy.Driver, name: `Driver`, description: `Driver` };
    }
}
export enum DefectStatus {
    DefectCreated = 2,
    Closed = 4,
    Acknowledged = 8
}
export function defectStatusDescription(value: DefectStatus) {
    switch (value) {
        case DefectStatus.DefectCreated:
            return `Defect Created`;
        case DefectStatus.Closed:
            return `Closed`;
        case DefectStatus.Acknowledged:
            return `Acknowledged`;
    }
}
export interface IDefectStatusDescriptor { value: DefectStatus; name: string; description: string; }
export var allDefectStatus: IDefectStatusDescriptor[] = [
    { value: DefectStatus.DefectCreated, name: `DefectCreated`, description: `Defect Created` },
    { value: DefectStatus.Closed, name: `Closed`, description: `Closed` },
    { value: DefectStatus.Acknowledged, name: `Acknowledged`, description: `Acknowledged` }
];

export function getDefectStatusDescriptor(value: DefectStatus) {
    switch (value) {
        case DefectStatus.DefectCreated:
            return { value: DefectStatus.DefectCreated, name: `DefectCreated`, description: `Defect Created` };
        case DefectStatus.Closed:
            return { value: DefectStatus.Closed, name: `Closed`, description: `Closed` };
        case DefectStatus.Acknowledged:
            return { value: DefectStatus.Acknowledged, name: `Acknowledged`, description: `Acknowledged` };
    }
}
export enum InvoiceStatus {
    NoInvoiceRequired = 1,
    NeedsInvoicing = 2,
    Invoiced = 3
}
export function invoiceStatusDescription(value: InvoiceStatus) {
    switch (value) {
        case InvoiceStatus.NoInvoiceRequired:
            return `No Invoice Required`;
        case InvoiceStatus.NeedsInvoicing:
            return `Needs Invoicing`;
        case InvoiceStatus.Invoiced:
            return `Invoiced`;
    }
}
export interface IInvoiceStatusDescriptor { value: InvoiceStatus; name: string; description: string; }
export var allInvoiceStatus: IInvoiceStatusDescriptor[] = [
    { value: InvoiceStatus.NoInvoiceRequired, name: `NoInvoiceRequired`, description: `No Invoice Required` },
    { value: InvoiceStatus.NeedsInvoicing, name: `NeedsInvoicing`, description: `Needs Invoicing` },
    { value: InvoiceStatus.Invoiced, name: `Invoiced`, description: `Invoiced` }
];

export function getInvoiceStatusDescriptor(value: InvoiceStatus) {
    switch (value) {
        case InvoiceStatus.NoInvoiceRequired:
            return { value: InvoiceStatus.NoInvoiceRequired, name: `NoInvoiceRequired`, description: `No Invoice Required` };
        case InvoiceStatus.NeedsInvoicing:
            return { value: InvoiceStatus.NeedsInvoicing, name: `NeedsInvoicing`, description: `Needs Invoicing` };
        case InvoiceStatus.Invoiced:
            return { value: InvoiceStatus.Invoiced, name: `Invoiced`, description: `Invoiced` };
    }
}
export enum JobTaskCategory {
    Defect = 1,
    General = 2,
    Service = 3,
    PreMachineryInspection = 4,
    MachineryInspection = 5,
    PreventativeMaintenanceService = 6
}
export function jobTaskCategoryDescription(value: JobTaskCategory) {
    switch (value) {
        case JobTaskCategory.Defect:
            return `Defect`;
        case JobTaskCategory.General:
            return `General`;
        case JobTaskCategory.Service:
            return `Service`;
        case JobTaskCategory.PreMachineryInspection:
            return `Pre-machinery Inspection`;
        case JobTaskCategory.MachineryInspection:
            return `Machinery Inspection`;
        case JobTaskCategory.PreventativeMaintenanceService:
            return `Preventative Maintenance Service`;
    }
}
export interface IJobTaskCategoryDescriptor { value: JobTaskCategory; name: string; description: string; }
export var allJobTaskCategory: IJobTaskCategoryDescriptor[] = [
    { value: JobTaskCategory.Defect, name: `Defect`, description: `Defect` },
    { value: JobTaskCategory.General, name: `General`, description: `General` },
    { value: JobTaskCategory.Service, name: `Service`, description: `Service` },
    { value: JobTaskCategory.PreMachineryInspection, name: `PreMachineryInspection`, description: `Pre-machinery Inspection` },
    { value: JobTaskCategory.MachineryInspection, name: `MachineryInspection`, description: `Machinery Inspection` },
    { value: JobTaskCategory.PreventativeMaintenanceService, name: `PreventativeMaintenanceService`, description: `Preventative Maintenance Service` }
];

export function getJobTaskCategoryDescriptor(value: JobTaskCategory) {
    switch (value) {
        case JobTaskCategory.Defect:
            return { value: JobTaskCategory.Defect, name: `Defect`, description: `Defect` };
        case JobTaskCategory.General:
            return { value: JobTaskCategory.General, name: `General`, description: `General` };
        case JobTaskCategory.Service:
            return { value: JobTaskCategory.Service, name: `Service`, description: `Service` };
        case JobTaskCategory.PreMachineryInspection:
            return { value: JobTaskCategory.PreMachineryInspection, name: `PreMachineryInspection`, description: `Pre-machinery Inspection` };
        case JobTaskCategory.MachineryInspection:
            return { value: JobTaskCategory.MachineryInspection, name: `MachineryInspection`, description: `Machinery Inspection` };
        case JobTaskCategory.PreventativeMaintenanceService:
            return { value: JobTaskCategory.PreventativeMaintenanceService, name: `PreventativeMaintenanceService`, description: `Preventative Maintenance Service` };
    }
}
export enum JobTaskCompletionStatus {
    Done = 1,
    Rebooked = 2
}
export function jobTaskCompletionStatusDescription(value: JobTaskCompletionStatus) {
    switch (value) {
        case JobTaskCompletionStatus.Done:
            return `Done`;
        case JobTaskCompletionStatus.Rebooked:
            return `Rebooked`;
    }
}
export interface IJobTaskCompletionStatusDescriptor { value: JobTaskCompletionStatus; name: string; description: string; }
export var allJobTaskCompletionStatus: IJobTaskCompletionStatusDescriptor[] = [
    { value: JobTaskCompletionStatus.Done, name: `Done`, description: `Done` },
    { value: JobTaskCompletionStatus.Rebooked, name: `Rebooked`, description: `Rebooked` }
];

export function getJobTaskCompletionStatusDescriptor(value: JobTaskCompletionStatus) {
    switch (value) {
        case JobTaskCompletionStatus.Done:
            return { value: JobTaskCompletionStatus.Done, name: `Done`, description: `Done` };
        case JobTaskCompletionStatus.Rebooked:
            return { value: JobTaskCompletionStatus.Rebooked, name: `Rebooked`, description: `Rebooked` };
    }
}
export enum JobTaskStatus {
    Future = 1,
    Scheduled = 2,
    InProgress = 3,
    Completed = 4,
    Cancelled = 5
}
export function jobTaskStatusDescription(value: JobTaskStatus) {
    switch (value) {
        case JobTaskStatus.Future:
            return `Future`;
        case JobTaskStatus.Scheduled:
            return `Scheduled`;
        case JobTaskStatus.InProgress:
            return `In Progress`;
        case JobTaskStatus.Completed:
            return `Completed`;
        case JobTaskStatus.Cancelled:
            return `Cancelled`;
    }
}
export interface IJobTaskStatusDescriptor { value: JobTaskStatus; name: string; description: string; }
export var allJobTaskStatus: IJobTaskStatusDescriptor[] = [
    { value: JobTaskStatus.Future, name: `Future`, description: `Future` },
    { value: JobTaskStatus.Scheduled, name: `Scheduled`, description: `Scheduled` },
    { value: JobTaskStatus.InProgress, name: `InProgress`, description: `In Progress` },
    { value: JobTaskStatus.Completed, name: `Completed`, description: `Completed` },
    { value: JobTaskStatus.Cancelled, name: `Cancelled`, description: `Cancelled` }
];

export function getJobTaskStatusDescriptor(value: JobTaskStatus) {
    switch (value) {
        case JobTaskStatus.Future:
            return { value: JobTaskStatus.Future, name: `Future`, description: `Future` };
        case JobTaskStatus.Scheduled:
            return { value: JobTaskStatus.Scheduled, name: `Scheduled`, description: `Scheduled` };
        case JobTaskStatus.InProgress:
            return { value: JobTaskStatus.InProgress, name: `InProgress`, description: `In Progress` };
        case JobTaskStatus.Completed:
            return { value: JobTaskStatus.Completed, name: `Completed`, description: `Completed` };
        case JobTaskStatus.Cancelled:
            return { value: JobTaskStatus.Cancelled, name: `Cancelled`, description: `Cancelled` };
    }
}
export enum OnHoldStatus {
    NotAwaitingParts = 1,
    AwaitingParts = 2,
    PartsBox = 3
}
export function onHoldStatusDescription(value: OnHoldStatus) {
    switch (value) {
        case OnHoldStatus.NotAwaitingParts:
            return `Not awaiting parts`;
        case OnHoldStatus.AwaitingParts:
            return `Awaiting parts`;
        case OnHoldStatus.PartsBox:
            return `Parts box`;
    }
}
export interface IOnHoldStatusDescriptor { value: OnHoldStatus; name: string; description: string; }
export var allOnHoldStatus: IOnHoldStatusDescriptor[] = [
    { value: OnHoldStatus.NotAwaitingParts, name: `NotAwaitingParts`, description: `Not awaiting parts` },
    { value: OnHoldStatus.AwaitingParts, name: `AwaitingParts`, description: `Awaiting parts` },
    { value: OnHoldStatus.PartsBox, name: `PartsBox`, description: `Parts box` }
];

export function getOnHoldStatusDescriptor(value: OnHoldStatus) {
    switch (value) {
        case OnHoldStatus.NotAwaitingParts:
            return { value: OnHoldStatus.NotAwaitingParts, name: `NotAwaitingParts`, description: `Not awaiting parts` };
        case OnHoldStatus.AwaitingParts:
            return { value: OnHoldStatus.AwaitingParts, name: `AwaitingParts`, description: `Awaiting parts` };
        case OnHoldStatus.PartsBox:
            return { value: OnHoldStatus.PartsBox, name: `PartsBox`, description: `Parts box` };
    }
}
export enum PurchaseOrderStatus {
    New = 1,
    Approved = 2,
    GoodsReceived = 3,
    Complete = 4,
    Cancelled = 5,
    AwaitingCredit = 6
}
export function purchaseOrderStatusDescription(value: PurchaseOrderStatus) {
    switch (value) {
        case PurchaseOrderStatus.New:
            return `New`;
        case PurchaseOrderStatus.Approved:
            return `Approved`;
        case PurchaseOrderStatus.GoodsReceived:
            return `Goods Received`;
        case PurchaseOrderStatus.Complete:
            return `Complete`;
        case PurchaseOrderStatus.Cancelled:
            return `Cancelled`;
        case PurchaseOrderStatus.AwaitingCredit:
            return `Awaiting Credit`;
    }
}
export interface IPurchaseOrderStatusDescriptor { value: PurchaseOrderStatus; name: string; description: string; }
export var allPurchaseOrderStatus: IPurchaseOrderStatusDescriptor[] = [
    { value: PurchaseOrderStatus.New, name: `New`, description: `New` },
    { value: PurchaseOrderStatus.Approved, name: `Approved`, description: `Approved` },
    { value: PurchaseOrderStatus.GoodsReceived, name: `GoodsReceived`, description: `Goods Received` },
    { value: PurchaseOrderStatus.Complete, name: `Complete`, description: `Complete` },
    { value: PurchaseOrderStatus.Cancelled, name: `Cancelled`, description: `Cancelled` },
    { value: PurchaseOrderStatus.AwaitingCredit, name: `AwaitingCredit`, description: `Awaiting Credit` }
];

export function getPurchaseOrderStatusDescriptor(value: PurchaseOrderStatus) {
    switch (value) {
        case PurchaseOrderStatus.New:
            return { value: PurchaseOrderStatus.New, name: `New`, description: `New` };
        case PurchaseOrderStatus.Approved:
            return { value: PurchaseOrderStatus.Approved, name: `Approved`, description: `Approved` };
        case PurchaseOrderStatus.GoodsReceived:
            return { value: PurchaseOrderStatus.GoodsReceived, name: `GoodsReceived`, description: `Goods Received` };
        case PurchaseOrderStatus.Complete:
            return { value: PurchaseOrderStatus.Complete, name: `Complete`, description: `Complete` };
        case PurchaseOrderStatus.Cancelled:
            return { value: PurchaseOrderStatus.Cancelled, name: `Cancelled`, description: `Cancelled` };
        case PurchaseOrderStatus.AwaitingCredit:
            return { value: PurchaseOrderStatus.AwaitingCredit, name: `AwaitingCredit`, description: `Awaiting Credit` };
    }
}
