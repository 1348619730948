import { complianceUrls } from 'src/domain/services/apiUrls';
import { IAjax } from 'src/infrastructure/ajax';

type FatigueBreachEventItem = Operations.Domain.Queries.FatigueBreachEvent.FatigueBreachEventItem;
type UpdateFatigueBreachEventCommand = Operations.Domain.Commands.FatigueBreachEvent.UpdateFatigueBreachEvent.UpdateFatigueBreachEventCommand;
type InvalidateFatigueBreachEventCommand = Operations.Domain.Commands.FatigueBreachEvent.InvalidateFatigueBreachEvent.InvalidateFatigueBreachEventCommand;
type ReactivateFatigueBreachEventCommand = Operations.Domain.Commands.FatigueBreachEvent.ReactivateFatigueBreachEvent.ReactivateFatigueBreachEventCommand;
type VerifyFatigueBreachEventResponse = Operations.Domain.Commands.FatigueBreachEvent.VerifyFatigueBreachEvent.VerifyFatigueBreachEventResponse;

export default function init(ajax: IAjax) {
  function loadFatigueBreachEvents(
    query: Partial<Operations.Domain.Queries.FatigueBreachEvent.ListFatigueBreachEventsQuery>
  ) {
    return ajax
      .get(complianceUrls.fatigueBreachEventUrls.loadFatigueBreachEvents(query))
      .map(
        r =>
          r.response as Common.Dtos.ListResult<
            Operations.Domain.Queries.FatigueBreachEvent.ListFatigueBreachEventItem
          >
      );
  }

  function loadFatigueBreachEvent(fatigueBreachEventId: string) {
    return ajax
      .get(complianceUrls.fatigueBreachEventUrls.loadFatigueBreachEvent(fatigueBreachEventId))
      .toPromise()
      .then<FatigueBreachEventItem>(r => r.response);
  }

  function verifyFatigueBreachEvent(fatigueBreachEventId: string) {
    return ajax
      .post(complianceUrls.fatigueBreachEventUrls.verifyFatigueBreachEvent(fatigueBreachEventId))
      .toPromise()
      .then<VerifyFatigueBreachEventResponse>(r => r.response);
  }

  function updateFatigueBreachEvent(command: UpdateFatigueBreachEventCommand) {
    return ajax
      .put(complianceUrls.fatigueBreachEventUrls.updateFatigueBreachEvent(command.id), command)
      .toPromise();
  }

  function closeFatigueBreachEvent(fatigueBreachEventId: string) {
    return ajax
      .put(complianceUrls.fatigueBreachEventUrls.closeFatigueBreachEvent(fatigueBreachEventId))
      .toPromise();
  }

  function invalidateFatigueBreachEvent(command: InvalidateFatigueBreachEventCommand) {
    return ajax
      .put(complianceUrls.fatigueBreachEventUrls.invalidateFatigueBreachEvent(command.id), command)
      .toPromise();
  }

  function reactivateFatigueBreachEvent(command: ReactivateFatigueBreachEventCommand) {
    return ajax
      .put(complianceUrls.fatigueBreachEventUrls.reactivateFatigueBreachEvent(command.id), command)
      .toPromise();
  }

  function exportFatigueBreachEventToPdf(fatigueBreachEventId: string) {
    return ajax
      .getFile(
        complianceUrls.fatigueBreachEventUrls.exportFatigueBreachEventToPdf(fatigueBreachEventId)
      )
      .toPromise()
      .then<Blob>(r => r.response);
  }

  function exportAllFatigueBreachEventToExcel(
    query: Partial<Operations.Domain.Queries.ListAllQuotes.ListAllQuotesQuery>
  ) {
    return ajax
      .getFile(complianceUrls.fatigueBreachEventUrls.exportAllFatigueBreachEventsToExcel(query))
      .toPromise()
      .then<Blob>(r => r.response);
  }

  return {
    fatigueBreachEvent: {
      loadFatigueBreachEvents,
      loadFatigueBreachEvent,
      updateFatigueBreachEvent,
      closeFatigueBreachEvent,
      invalidateFatigueBreachEvent,
      reactivateFatigueBreachEvent,
      exportFatigueBreachEventToPdf,
      exportAllFatigueBreachEventToExcel,
      verifyFatigueBreachEvent,
    },
  };
}
