import styles from './CreateDefect.module.scss';

import RelatedDefectsList from 'src/views/kioskRoutes/components/connected/RelatedDefectList/RelatedDefectListContainer';
import Header from '../../components/presentational/Header/Header';
import CreateDefectForm from '../../components/connected/CreateDefectForm/CreateDefectFormContainer';

export interface ICreateDefectProps {
  canUseTimesheets: boolean | undefined;
}

const CreateDefect: React.FC<ICreateDefectProps> = props => {
  return (
    <div className={styles.createDefectComponent}>
      <Header title="Raise Defect" to="/kiosk" canUseTimesheets={props.canUseTimesheets} />
      <div className={styles.form}>
        <div className={styles.createDefects}>
          <CreateDefectForm />
        </div>
        <div className={styles.relatedDefects}>
          <RelatedDefectsList />
        </div>
      </div>
    </div>
  );
};

export default CreateDefect;
