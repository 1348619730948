import { Link } from 'react-router-dom';

interface IAssetItem {
  vehicleTypeDescription?: string;
  isDecommissioned: boolean;
  name: string;
  id: string;
}

export interface IFleetAssetRendererProps {
  asset: IAssetItem;
  hideAssetInfo?: boolean;
  addLink?: boolean;
}

function addAssetLink(assetId: string, component: JSX.Element) {
  return <Link to={`/workshop/assets/${assetId}`}>{component}</Link>;
}

const FleetAssetRenderer: React.FC<IFleetAssetRendererProps> = ({
  asset,
  hideAssetInfo,
  addLink,
}) => {
  if (asset === null || asset === undefined) {
    return null;
  }
  const component = (
    <span className="text-nowrap">
      <span>
        {asset.name}
        {asset.isDecommissioned ? ' (Decommissioned)' : ''}
      </span>
      {hideAssetInfo ? null : (
        <>
          &emsp;
          <small>{asset.vehicleTypeDescription}</small>
        </>
      )}
    </span>
  );
  return addLink ? addAssetLink(asset.id, component) : component;
};

export default FleetAssetRenderer;
