import saveAs from 'file-saver';
import { operationsUrls } from 'src/domain/services/apiUrls';
import { IAjax } from 'src/infrastructure/ajax';

export default function init(ajax: IAjax) {
  function createRailTemplate(
    command: Operations.Domain.Commands.RailTemplate.CreateRailTemplate.CreateRailTemplateCommand
  ) {
    return ajax
      .post(operationsUrls.railTemplateUrls.createRailTemplate(), command)
      .toPromise()
      .then<string>(r => r.response);
  }

  function checkForUniqueRailTemplateName(name: string) {
    return ajax
      .get(operationsUrls.railTemplateUrls.checkForUnqiueRailTemplateName(name))
      .toPromise()
      .then<Common.Dtos.UniqueNameCheckResultDto>(r => r.response);
  }

  function updateRailTemplate(
    command: Operations.Domain.Commands.RailTemplate.UpdateRailTemplate.UpdateRailTemplateCommand
  ) {
    return ajax
      .put(operationsUrls.railTemplateUrls.updateRailTemplate(command.id), command)
      .toPromise();
  }

  function viewRailTemplate(railTemplateId: string) {
    return ajax
      .get(operationsUrls.railTemplateUrls.viewRailTemplate(railTemplateId))
      .toPromise()
      .then<Operations.Domain.Queries.ViewRailTemplate.RailTemplateItem>(r => r.response);
  }

  function generateShiftAttachmentId(
    cmd: Operations.Domain.Commands.RailTemplate.GenerateShiftAttachmentId.GenerateShiftAttachmentIdCommand
  ) {
    return ajax
      .post(operationsUrls.railTemplateUrls.generateShiftAttachmentId(), cmd)
      .toPromise()
      .then<string>(r => r.response as string);
  }

  function downloadAttachment(attachmentId: string, fileName: string) {
    return ajax
      .getFile(operationsUrls.railTemplateUrls.downloadAttachment(attachmentId))
      .toPromise()
      .then(r => {
        saveAs(r.response, fileName);
      });
  }

  function listRailTemplates(
    request: Partial<Operations.Domain.Queries.ListRailTemplates.ListRailTemplatesQuery>
  ) {
    return ajax
      .get(operationsUrls.railTemplateUrls.listRailTemplates(request))
      .map(
        r =>
          r.response as Common.Dtos.ListResult<
            Operations.Domain.Queries.ListRailTemplates.RailTemplateListItem
          >
      );
  }

  function listRailTemplateShifts() {
    return ajax
      .get(operationsUrls.railTemplateUrls.listRailTemplateShifts())
      .toPromise()
      .then<Operations.Domain.Queries.ListRailTemplateShifts.RailTemplateShiftListItem[]>(
        r => r.response
      );
  }

  function deleteRailTemplate(railTemplateId: string) {
    return ajax
      .httpDelete(operationsUrls.railTemplateUrls.deleteRailTemplate(railTemplateId))
      .toPromise();
  }

  function restoreRailTemplate(railTemplateId: string) {
    return ajax
      .put(operationsUrls.railTemplateUrls.restoreRailTemplate(railTemplateId))
      .toPromise();
  }

  return {
    railTemplates: {
      listRailTemplates,
      createRailTemplate,
      checkForUniqueRailTemplateName,
      updateRailTemplate,
      viewRailTemplate,
      listRailTemplateShifts,
      deleteRailTemplate,
      restoreRailTemplate,
      generateShiftAttachmentId,
      downloadAttachment,
    },
  };
}
