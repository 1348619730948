import { types, flow, getRoot } from 'mobx-state-tree';
import { getAjax } from 'src/domain/services/storeEnvironment';
import { buildListPageApiSearchModelTypedQuery } from 'src/domain/modelBuilders/buildListPageApiSearchModel';
import { ListPageLoadCause, NotificationType } from 'src/domain';
import { IRootStoreModel } from 'src/domain/entities/RootStoreModel';
type GetWeekWithoutTimesheetQuery = People.Domain.Queries.GetWeekWithoutTimesheet.GetWeekWithoutTimesheetQuery;
type TimesheetWeek = People.Domain.Queries.GetWeekWithoutTimesheet.TimesheetWeek;
type ListTimesheetsQuery = People.Domain.Queries.ListTimesheets.ListTimesheetsQuery;
type ListDetailedTimesheetsQuery = People.Domain.Queries.ListDetailedTimesheets.ListDetailedTimesheetsQuery;
type ListTimesheetNotesQuery = People.Domain.Queries.ListTimesheetNotes.ListTimesheetNotesQuery;

const TimesheetsListModel = buildListPageApiSearchModelTypedQuery<
  ListTimesheetsQuery
>()('TimesheetsListModel', d => d.ajax.people.timesheet.listTimesheets(d.query), { size: 50 });
const TimesheetsQueryModel = types
  .model('TimesheetQueryMode', {
    timesheetWeek: types.maybe(types.frozen<TimesheetWeek>()),
  })
  .actions(self => {
    const ajax = getAjax(self);

    const getWeekWithoutTimesheet = (query: Partial<GetWeekWithoutTimesheetQuery>) =>
      flow(function*() {
        const timesheetWeek: TimesheetWeek = yield ajax.people.timesheet.getWeekWithoutTimesheet(
          query
        );
        self.timesheetWeek = timesheetWeek;
      })();

    return {
      getWeekWithoutTimesheet,
    };
  });

const TimesheetBulkPrintModel = types
  .model('TimesheetBulkPrintModel', {
    timesheetsForPrinting: types.maybe(types.frozen<Blob>()),
  })
  .actions(self => {
    const ajax = getAjax(self);
    const root = getRoot(self) as IRootStoreModel;

    const getTimesheetsForPrinting = (query: Partial<ListTimesheetsQuery>) =>
      flow(function*() {
        root.notifications.addNotification(
          `The file is being generated. Please wait...This can take some time.`,
          {
            type: NotificationType.info,
            autoClose: false,
          }
        );
        self.timesheetsForPrinting = yield ajax.people.timesheet.printTimesheets(query);
      })();

    return {
      getTimesheetsForPrinting,
    };
  });

export const TimesheetsModel = types
  .compose(TimesheetsListModel, TimesheetsQueryModel, TimesheetBulkPrintModel)
  .actions(self => {
    const ajax = getAjax(self);
    const root = getRoot(self) as IRootStoreModel;

    const processTimesheets = (query: Partial<ListTimesheetsQuery>) =>
      flow(function*() {
        yield ajax.people.timesheet.processTimesheets(query);
        yield self.listItems({
          query,
          loadCause: ListPageLoadCause.search,
        });
      })();

    const exportTimesheets = (query: Partial<ListTimesheetsQuery>): Promise<Blob> =>
      flow(function*() {
        root.notifications.addNotification(`The file is being generated, please wait...`, {
          type: NotificationType.info,
        });
        return yield ajax.people.timesheet.exportTimesheets(query);
      })();

    const exportDetailedTimesheets = (query: Partial<ListDetailedTimesheetsQuery>): Promise<Blob> =>
      flow(function*() {
        root.notifications.addNotification(`The file is being generated, please wait...`, {
          type: NotificationType.info,
        });
        return yield ajax.people.timesheet.exportDetailedTimesheets(query);
      })();

    const exportTimesheetNotes = (query: Partial<ListTimesheetNotesQuery>): Promise<Blob> =>
      flow(function*() {
        root.notifications.addNotification(`The file is being generated, please wait...`, {
          type: NotificationType.info,
        });
        return yield ajax.people.timesheet.exportTimesheetNotes(query);
      })();

    return {
      processTimesheets,
      exportTimesheets,
      exportDetailedTimesheets,
      exportTimesheetNotes,
    };
  });
