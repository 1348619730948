import { types, flow } from 'mobx-state-tree';
import { getAjax } from 'src/domain/services/storeEnvironment';
import { complianceUrls } from 'src/domain/services/apiUrls';

type ComplianceDashboardData = Operations.Domain.Queries.GetComplianceDashboard.ComplianceDashboardData;

export const ComplianceDashboardModel = types
  .model('ComplianceDashboardModel', {
    dashboardData: types.maybe(types.frozen<ComplianceDashboardData>()),
  })
  .actions(self => {
    const ajax = getAjax(self);

    const loadDashboardData = flow(function*() {
      self.dashboardData = yield ajax.raw
        .get(complianceUrls.dashboardUrls.loadDashboard)
        .toPromise()
        .then<ComplianceDashboardData>(r => r.response);
    });

    return {
      loadDashboardData,
    };
  });
