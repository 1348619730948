import { useState } from 'react';
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import cn from 'classnames';

import styles from './TabHolder.module.scss';

export interface ITabProps {
  title: string | JSX.Element;
  contents: JSX.Element;
}

interface ITabHolderProps {
  givenTabs: ITabProps[];
}

const TabHolder: React.FC<ITabHolderProps> = ({ givenTabs }) => {
  const [activeTabIndex, setActiveTabIndex] = useState(0);

  if (!givenTabs || givenTabs.length === 0) {
    return null;
  }

  return (
    <div className={styles.tabContainer}>
      <Nav tabs>
        {givenTabs.map((tab, idx) => (
          <NavItem className={cn(styles.tab, activeTabIndex === idx && styles.myActive)} key={idx}>
            <NavLink
              className={cn(styles.tabHeader, activeTabIndex === idx && styles.myActive)}
              onClick={() => {
                setActiveTabIndex(idx);
              }}
              onKeyPress={() => {
                setActiveTabIndex(idx);
              }}
              tabIndex={0}>
              {tab.title}
            </NavLink>
          </NavItem>
        ))}
      </Nav>
      <TabContent className={styles.content} activeTab={activeTabIndex}>
        <TabPane className={styles.pane} tabId={activeTabIndex}>
          {givenTabs[activeTabIndex].contents}
        </TabPane>
      </TabContent>
    </div>
  );
};

export default TabHolder;
