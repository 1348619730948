import {
  FieldType,
  IFieldData,
  ModalDefBuilder,
  PaneType,
} from 'src/views/definitionBuilders/types';
import { IFile } from 'src/views/components/Page/fields/MultiFilePageField';
import { AddAttachmentData } from 'src/domain/entities/workshop/attachment/WorkshopAttachmentModel';
import {
  AggregatesWithAttachments,
  LoadAttachmentsQuery,
  maxFileSizeInMegaBytes,
} from 'src/views/components/Attachment/attachmentHelper';
import { getSubmitCloseModalActionGroupDef } from 'src/views/definitionBuilders/common';
import { DateTime } from 'luxon';

type AddAttachmentFormData = {
  file: IFile;
  fileName: string;
  aggregateType: string;
  attachmentExpiryDateTo?: string | undefined;
  attachmentExpiryDateFrom?: string | undefined;
  isAvailableOnDriversTablet: boolean;
};

export default function getAddAttachmentModalDef(
  aggregateId: string,
  aggregateType: AggregatesWithAttachments,
  onSubmit: (data: AddAttachmentData) => Promise<void>,
  onSubmitSucceed: (query: LoadAttachmentsQuery) => Promise<void>
): ModalDefBuilder {
  return api => ({
    title: 'Add Attachment',
    asForm: true,
    panels: [
      {
        panes: [
          {
            paneType: PaneType.formFieldsPane,
            fields: [
              {
                fieldType: FieldType.fileField,
                label: 'Attachment',
                mandatory: true,
                dataAddr: 'file',
                validate: fapi => {
                  const fapiTyping = fapi as IFieldData<IFile>;
                  if (fapiTyping.fieldValue && fapiTyping.fieldValue.file) {
                    if (fapiTyping.fieldValue.file.type !== 'application/pdf') {
                      return 'Invalid file type. Please select a pdf';
                    }
                    const maxSizeInBytes = maxFileSizeInMegaBytes * 1024 * 1024;
                    if (fapiTyping.fieldValue.file.size > maxSizeInBytes) {
                      return `${(fapiTyping.fieldValue.file.size / 1024 / 1024).toFixed(
                        2
                      )}MB attachment too large. Attachments must be under 20MB`;
                    }
                  }

                  return undefined;
                },
                onChange: fapi => {
                  fapi.setFormValue(
                    ['fileName'],
                    (fapi.formValues.file && fapi.formValues.file.name) || ''
                  );
                },
              },
              {
                fieldType: FieldType.textField,
                label: 'File name',
                mandatory: true,
                dataAddr: 'fileName',
                hidden: fapi => !fapi.parentValue.file,
                validate: fapi => {
                  if (fapi.fieldValue && !fapi.fieldValue.endsWith('.pdf')) {
                    return 'Please ensure the filename ends with .pdf';
                  }
                  return undefined;
                },
              },
              {
                fieldType: FieldType.dateField,
                label: 'Valid From',
                dataAddr: 'attachmentExpiryDateFrom',
                hidden: aggregateType !== 'supplier',
                onBlur: api => {
                  if (!api.formValues.attachmentExpiryDateTo) {
                    api.setFormValue(['attachmentExpiryDateTo'], api.fieldData.fieldValue);
                  }
                  api.validateField(['attachmentExpiryDateTo']);
                },
              },
              {
                fieldType: FieldType.dateField,
                tooltip:
                  'Automated emails are sent to the workshop team at the start of each month listing any supplier attachments whose valid to date is within the next 45 days.',
                label: 'Valid To',
                dataAddr: 'attachmentExpiryDateTo',
                hidden: aggregateType !== 'supplier',
                validate: d => {
                  if (!d.fieldValue || !d.parentValue.attachmentExpiryDateFrom) {
                    return undefined;
                  }
                  const from = DateTime.fromISO(d.parentValue.attachmentExpiryDateFrom);
                  const to = DateTime.fromISO(d.fieldValue);
                  return from > to ? 'Valid To cannot be earlier than Valid From' : undefined;
                },
              },
              {
                fieldType: FieldType.yesNoField,
                tooltip:
                  "Specify if this attachment should appear for driver's to view in the tablet",
                label: "Available on Driver's Tablet?",
                dataAddr: 'isAvailableOnDriversTablet',
                hidden: fapi => !fapi.parentValue.file || aggregateType === 'supplier',
                mandatory: true,
              },
            ],
          },
        ],
      },
    ],
    secondaryActions: [getSubmitCloseModalActionGroupDef('Ok')],
    onFormSubmit: (values: AddAttachmentFormData) => {
      return onSubmit({
        aggregateId: aggregateId,
        aggregateType: aggregateType,
        fileName: values.fileName,
        file: values.file,
        attachmentExpiryDateTo: values.attachmentExpiryDateTo,
        attachmentExpiryDateFrom: values.attachmentExpiryDateFrom,
        isAvailableOnDriversTablet: values.isAvailableOnDriversTablet,
      });
    },
    onSubmitSucceeded: () => onSubmitSucceed({ aggregateId, aggregateType }),
  });
}
