import { types, flow, getRoot } from 'mobx-state-tree';
import { IRootStoreModel } from 'src/domain/entities/RootStoreModel';
import { getAjax } from 'src/domain/services';
import {
  ISetActiveCommand,
  ISetInactiveCommand,
} from 'src/views/components/ActivationAndDeletionActionsButtons/getActivationAndDeletionActionButtons';

type AggregateUsageDto = Common.Dtos.AggregateUsageDto;
type SetEngineActiveCommand = Workshop.Domain.Commands.Engine.SetEngineActive.SetEngineActiveCommand;
type SetEngineInactiveCommand = Workshop.Domain.Commands.Engine.SetEngineInactive.SetEngineInactiveCommand;
type EngineDetails = Workshop.Domain.Queries.Engine.GetEngine.EngineDetails;
type EngineItem = Workshop.Domain.Queries.Engine.GetEngineList.EngineItem;
type JobTaskSubcategoryListItem = Workshop.Domain.Queries.GetTaskCategories.JobTaskSubcategoryListItem;
type JobTaskCategoryListItem = Workshop.Domain.Queries.GetTaskCategories.JobTaskCategoryListItem;

export const EngineModel = types
  .model('EngineModel', {
    engineListItems: types.array(types.frozen<EngineItem>()),
    engineDetailsListItems: types.array(types.frozen<EngineDetails>()),
    serviceTypes: types.array(types.frozen<JobTaskSubcategoryListItem>()),
    engine: types.maybe(types.frozen<EngineDetails>()),
    engineUsage: types.maybe(types.frozen<AggregateUsageDto>()),
  })
  .actions(self => {
    const ajax = getAjax(self);
    const root = getRoot(self) as IRootStoreModel;

    const listEngines = flow(function*() {
      self.engineListItems = yield ajax.engine.listEngines();
    });

    const listEnginesDetails = flow(function*() {
      self.engineDetailsListItems = yield ajax.engine.listEnginesDetails();
    });

    const loadServiceTypes = flow(function*() {
      const categories: JobTaskCategoryListItem[] = yield ajax.assetGroup.getJobTaskCategories();
      const serviceCategory = categories.find(
        (c: JobTaskCategoryListItem) => c.description === 'Service'
      );
      const subCategories = serviceCategory ? serviceCategory.subcategories : [];
      self.serviceTypes.replace(subCategories);
    });

    const createEngine = flow(function*(
      command: Workshop.Domain.Commands.Engine.CreateEngineCommand
    ) {
      const id = yield ajax.engine.createEngine(command);
      self.engine = undefined;
      root.history.push(`/workshop/engines/${id}`);
    });

    const checkForUniqueModel = flow(function*(model: string) {
      return yield ajax.engine.checkForUniqueModel(model);
    });

    const loadEngine = flow(function*(id: string) {
      self.engine = yield ajax.engine.loadEngine(id);
    });

    const updateEngine = flow(function*(
      command: Workshop.Domain.Commands.Engine.UpdateEngineCommand
    ) {
      yield ajax.engine.updateEngine(command);
      yield loadEngine(command.engineId);
    });

    const setEngineInactive = flow(function*(command: ISetInactiveCommand) {
      const setEngineInactiveCommand: SetEngineInactiveCommand = {
        id: command.id,
      };
      yield ajax.engine.updateEngineSetInactive(setEngineInactiveCommand);
    });

    const setEngineActive = flow(function*(command: ISetActiveCommand) {
      const setEngineActiveCommand: SetEngineActiveCommand = {
        id: command.id,
      };
      yield ajax.engine.updateEngineSetActive(setEngineActiveCommand);
    });

    const deleteEngine = flow(function*(id: string) {
      yield ajax.engine.deleteEngine(id);

      self.engine = undefined;
      self.engineUsage = undefined;

      root.history.push(`/workshop/engines`);
    });

    const getEngineUsage = flow(function*(id: string) {
      self.engineUsage = yield ajax.engine.getCurrentUsage(id);
    });

    return {
      listEngines,
      listEnginesDetails,
      loadServiceTypes,
      createEngine,
      checkForUniqueModel,
      loadEngine,
      updateEngine,
      setEngineInactive,
      setEngineActive,
      deleteEngine,
      getEngineUsage,
    };
  });
