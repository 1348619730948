import { DateTime } from 'luxon';

type DayManagerComponent = {
  props: {
    showInZone: string;
    getNow?: () => DateTime;
    onChangeCurrentDay?: (currentDay: DateTime) => void;
  };
  getDay: () => string | undefined;
  handleDayChange: (value: DateTime) => void;
};

export default class DayManager {
  private readonly _component: DayManagerComponent;

  constructor(component: DayManagerComponent) {
    this._component = component;
  }

  get currentDay() {
    const day = this._component.getDay();
    return day ? DateTime.fromISO(day) : this.today;
  }

  get now() {
    return (
      (this._component.props.getNow && this._component.props.getNow()) ||
      DateTime.local()
    ).setZone(this._component.props.showInZone);
  }

  get today() {
    return this.now.startOf('day');
  }

  get currentDayOffsetFromToday() {
    return this.currentDay.diff(this.today).as('days');
  }

  readonly persistCurrentDay = () => {
    this.setCurrentDay(this.currentDay);
  };

  readonly setCurrentDay = (value: DateTime) => {
    const dayLogicallyChanged = !this.currentDay.equals(value);
    this._component.handleDayChange(value);
    if (dayLogicallyChanged) {
      this.fireOnChangeCurrentDay(value);
    }
  };

  private readonly fireOnChangeCurrentDay = (value: DateTime) => {
    this._component.props.onChangeCurrentDay && this._component.props.onChangeCurrentDay(value);
  };
}
