import ListPage, { IListPageDef } from 'src/views/components/Page/pages/ListPage';
import { FieldType } from 'src/views/definitionBuilders/types/field';
import TaskCardItem from 'src/views/components/TaskCard/TaskCardItem';
import { PagePrimarySize } from 'src/views/definitionBuilders/types';
import { IListPageLoadDataRequest } from 'src/domain/baseTypes';
import { CheckIcon } from 'src/images/icons';
import PrimaryTitle from 'src/views/components/Page/PrimaryTitle/PrimaryTitle';

export interface IListPartCategoriesProps {
  canManagePartCategories: boolean;
  listPartCategories: (request: IListPageLoadDataRequest) => Promise<void>;
  partCategories: Workshop.Domain.Queries.PartCategory.PartCategoryItem[];
  hasMoreData: boolean;
}

function getPageDef(props: IListPartCategoriesProps): IListPageDef {
  return {
    primaryTitle: (
      <PrimaryTitle
        title="Part Categories"
        link="https://www.notion.so/Part-Categories-66b3889eadae48f08bb2194abffc4ac6"></PrimaryTitle>
    ),
    primarySize: PagePrimarySize.full,
    onLoadData: props.listPartCategories,
    externalSearch: true,
    hasMoreData: props.hasMoreData,
    createLink: props.canManagePartCategories
      ? TaskCardItem.workshop.createPartCategory
      : undefined,
    primaryFields: [
      {
        fieldType: FieldType.textField,
        dataAddr: 'description',
        label: 'Description',
        linkTo: d => `/workshop/part-categories/${d.parentValue.id}`,
      },
      {
        fieldType: FieldType.textField,
        dataAddr: 'isActive',
        label: 'Active',
        formatReadonly: data => (data.fieldValue ? <CheckIcon /> : undefined),
      },
    ],
    filterAction: {
      filterFields: [
        {
          fieldType: FieldType.yesNoField,
          label: 'Active',
          dataAddr: 'isActive',
        },
      ],
      defaultValues: {
        isActive: true,
      },
    },
  };
}

const ListPartCategories: React.FC<IListPartCategoriesProps> = (
  props: IListPartCategoriesProps
) => {
  return (
    <ListPage
      className="list-PartCategories-component"
      data={props.partCategories}
      def={getPageDef(props)}
    />
  );
};

export default ListPartCategories;
