import { Component } from 'react';
import { PagePrimarySize, IPageDef, PaneType } from 'src/views/definitionBuilders/types';
import Page from 'src/views/components/Page';
import TaskCardItem, { toActionLinkDef } from 'src/views/components/TaskCard/TaskCardItem';
import DashboardTileCollection from 'src/views/components/Dashboard/DashboardTileCollection';
import StandardDashboardTile from 'src/views/components/Dashboard/StandardDashboardTile';
import PrimaryTitle from 'src/views/components/Page/PrimaryTitle/PrimaryTitle';

export interface IComplianceProps {
  dashboardData?: Operations.Domain.Queries.GetComplianceDashboard.ComplianceDashboardData;
  onLoadData: () => Promise<void>;
}

const topTasksActions = [
  TaskCardItem.operations.compliance.reportableEvent.createReportableEvent,
  TaskCardItem.operations.compliance.codeOfConduct.createCodeOfConduct,
  TaskCardItem.operations.compliance.lostProperty.createLostPropertyItem,
  TaskCardItem.operations.compliance.customerComplaint.createCustomerComplaint,
  TaskCardItem.operations.compliance.cctvRequest.createCctvRequest,
].map(toActionLinkDef);

class Compliance extends Component<IComplianceProps> {
  componentDidMount() {
    this.props.onLoadData();
  }

  private readonly getData = () => {
    const { dashboardData } = this.props;
    return { dashboardData };
  };

  private readonly getPageDef = (): IPageDef => {
    const { dashboardData } = this.props;
    return {
      primarySize: PagePrimarySize.threeQuarters,
      primarySection: {
        title: (
          <PrimaryTitle
            title="Compliance"
            link="https://www.notion.so/Compliance-Overview-c0397be3adf5463ca0e62950375e8fc6"></PrimaryTitle>
        ),
        panels: [
          {
            panes: [
              {
                paneType: PaneType.customPane,
                render: () => (
                  <DashboardTileCollection isLoading={!dashboardData}>
                    {dashboardData ? (
                      <>
                        <StandardDashboardTile
                          title="Items To Be Disposed"
                          link="/compliance/lost-property-items?toBeDisposed=true"
                          number={dashboardData.itemsToBeDisposed}
                        />
                        <StandardDashboardTile
                          title="Open Fatigue Breach Events"
                          link="/compliance/fatigue-breach-events?statuses=1"
                          number={dashboardData.openFatigueBreachEvents}
                        />
                      </>
                    ) : null}
                  </DashboardTileCollection>
                ),
              },
            ],
          },
        ],
      },
      secondarySections: [
        {
          title: 'Navigation',
          panels: [
            {
              title: 'Top Tasks',
              panes: [
                {
                  paneType: PaneType.navListPane,
                  links: topTasksActions,
                },
              ],
            },
          ],
        },
      ],
    };
  };

  render() {
    return <Page data={this.getData()} def={this.getPageDef()} />;
  }
}

export default Compliance;
