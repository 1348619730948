import { Component } from 'react';
import { WarnIcon } from 'src/images/icons';

export interface IInvoicingStatusProps {
  suspended: boolean;
}
class InvoicingStatus extends Component<IInvoicingStatusProps> {
  render() {
    return this.props.suspended ? (
      <span>
        <WarnIcon /> Suspended
      </span>
    ) : (
      <span />
    );
  }
}

export default InvoicingStatus;
