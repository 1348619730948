import inject from 'src/views/injectFromStore';
import ListDataFuelEntries, { IListDataFuelEntriesProps } from './ListDataFuelEntries';

const Container = inject<IListDataFuelEntriesProps>((allStores, p) => ({
  listDataFuelEntries: allStores.rootStore.workshop.dataFuel.listItems,
  dataFuelEntries: allStores.rootStore.workshop.dataFuel.items.slice(),
  hasMoreData: allStores.rootStore.workshop.dataFuel.hasMoreItems,
  uploadedFiles: allStores.rootStore.workshop.dataFuelUploads.firstFiveUploads.slice(),
  loadUploadedFiles: allStores.rootStore.workshop.dataFuelUploads.listItems,
  uploadFile: allStores.rootStore.workshop.dataFuel.uploadFile,
  updateEntry: allStores.rootStore.workshop.dataFuel.updateEntry,
  assets: allStores.rootStore.assets.assetListItems.slice(),
  loadAssets: allStores.rootStore.assets.loadAssetListItems,
  staffMembers: allStores.rootStore.people.staffMembers.allStaffMembers.slice(),
  loadAllStaffMembers: allStores.rootStore.people.staffMembers.loadAllStaffMembers,
  ignoredAssets: allStores.rootStore.workshop.dataFuel.ignoredAssets.slice(),
  loadIgnoredAssets: allStores.rootStore.workshop.dataFuel.loadIgnoredAssets,
  updateIgnoredAssets: allStores.rootStore.workshop.dataFuel.updateIgnoredAssets,
  canManageWorkshop: allStores.rootStore.account.isWorkshopDepartmentMember,
}))(ListDataFuelEntries);

export default Container;
