import { ChangeState, CostType } from 'src/api/enums';
import { SearchTechSpecValues } from 'src/domain/entities/workshop/asset/AssetsModel';
import {
  ISplittedTechSpecs,
  consolidateTechSpecRequirements,
} from 'src/domain/entities/workshop/techSpecs/TechSpecsHelpers';
import { isDefined } from 'src/infrastructure/typeUtils';
import { getSubmitCloseModalActionGroupDef } from 'src/views/definitionBuilders/common';
import {
  FieldType,
  IFieldOnChange,
  ModalDefBuilder,
  PaneType,
} from 'src/views/definitionBuilders/types';
import { getSkillSpecRequirementFieldDefs } from '../../../shared/getSkillSpecRequirementFieldDefs';
import { getTechSpecRequirementFieldDefs } from '../../../shared/getTechSpecRequirementFieldDefs';
import { consolidateSkillSpecRequirements } from 'src/domain/entities/people/staffMember/SkillSpecsHelpers';
import { precisionRound } from 'src/infrastructure/mathUtil';

type VehicleType = Common.Dtos.VehicleTypeItem;
type Option = Operations.Domain.Queries.ViewQuote.Option;
type Vehicle = Operations.Domain.Queries.ViewQuote.Vehicle;
type HourlyRateItem = Operations.Domain.Queries.ListHourlyRates.HourlyRateItem;
type Route = Operations.Domain.Queries.ViewQuote.Route;
type TechSpecDropdownOption = Workshop.Domain.Queries.TechSpecs.TechSpecDropdownOptionsItem.TechSpecDropdownOption;
type SkillSpecs = Common.Dtos.SkillSpecItem;

export default function getMaintainVehicleModalDef(
  isEditing: boolean,
  vehicleTypes: VehicleType[],
  hourlyRates: Array<HourlyRateItem>,
  isContractPriceManuallyManaged: boolean,
  costTypeId: number,
  splittedTechSpecs: ISplittedTechSpecs,
  searchTechSpecValues: SearchTechSpecValues,
  techSpecDropdownOptions: (techSpecId: number) => TechSpecDropdownOption[],
  skillSpecRequirements: SkillSpecs[],
  route?: Route[]
): ModalDefBuilder {
  return modalDefApi => {
    return {
      title: isEditing ? 'Edit Vehicle' : 'Add Vehicle',
      asForm: true,
      dataAddr: 'vehicles',
      explicitData: isEditing
        ? {
            ...modalDefApi.actionData.actionValue,
            changeState: ChangeState.Unchanged,
          }
        : undefined,
      panels: [
        {
          panes: [
            {
              paneType: PaneType.formFieldsPane,
              hidden: costTypeId !== CostType.Contract,
              columnCount: 4,
              fields: [
                {
                  fieldType: FieldType.selectField,
                  dataAddr: 'vehicleType',
                  label: 'Vehicle Type',
                  valueKey: 'id',
                  descriptionKey: 'description',
                  optionItems: vehicleTypes,
                  mandatory: true,
                  valuesToDisable: () => {
                    const vehicles = modalDefApi.parentFormApi.getValue('vehicles');

                    return (vehicles && vehicles?.length
                      ? vehicles.map((v: Vehicle) => v.vehicleType && v.vehicleType.id)
                      : []
                    ).filter(isDefined);
                  },
                },
                {
                  fieldType: FieldType.numericField,
                  dataAddr: 'quantity',
                  numericConfig: { numericType: 'unsignedInt' },
                  label: 'Quantity',
                  columnWidth: '10em',
                  mandatory: true,
                  onChange: fapi => calculateContractVehiclePrice(fapi),
                },
                {
                  fieldType: FieldType.numericField,
                  dataAddr: 'pricePerVehicle',
                  numericConfig: { numericType: 'unsignedDecimal', maxPointDigits: 4 },
                  label: 'Price',
                  columnWidth: '10em',
                  mandatory: true,
                  hidden: isContractPriceManuallyManaged,
                  columnAutoHide: isContractPriceManuallyManaged,
                  onChange: fapi => calculateContractVehiclePrice(fapi),
                },
                {
                  fieldType: FieldType.numericField,
                  dataAddr: 'price',
                  label: 'Total Price',
                  columnWidth: '10em',
                  readonly: true,
                  hidden: isContractPriceManuallyManaged,
                  columnAutoHide: isContractPriceManuallyManaged,
                },
              ],
            },
            {
              paneType: PaneType.formFieldsPane,
              hidden: costTypeId !== CostType.Kilometers,
              columnCount: 3,
              fields: [
                {
                  fieldType: FieldType.selectField,
                  dataAddr: 'vehicleType',
                  label: 'Vehicle Type',
                  valueKey: 'id',
                  descriptionKey: 'description',
                  optionItems: vehicleTypes,
                  columnWidth: '20em',
                  mandatory: true,
                  valuesToDisable: () => {
                    const vehicles = modalDefApi.parentFormApi.getValue('vehicles');

                    return (vehicles && vehicles?.length
                      ? vehicles.map((v: Vehicle) => v.vehicleType && v.vehicleType.id)
                      : []
                    ).filter(isDefined);
                  },
                  onChange: fapi => {
                    const option = modalDefApi.parentFormApi.values as Option;
                    const vehicle = fapi.fieldData.parentValue as Vehicle;

                    const price = calculateVehiclePrice(
                      fapi.newFieldValue,
                      vehicle.quantity,
                      vehicle.bufferPrice,
                      option.distance,
                      option.durationWeekday,
                      option.durationSaturday,
                      option.durationSunday,
                      option.durationPublicHoliday,
                      hourlyRates,
                      option.durationWeekdayWaiting,
                      option.durationSaturdayWaiting,
                      option.durationSundayWaiting,
                      option.durationPublicHolidayWaiting,
                      route
                    );
                    const priceDataAddr = [...fapi.fieldDataAddr];
                    priceDataAddr.pop();
                    priceDataAddr.push('price');

                    fapi.setFormValue(priceDataAddr, price);
                  },
                },
                {
                  fieldType: FieldType.numericField,
                  dataAddr: 'quantity',
                  numericConfig: { numericType: 'unsignedInt' },
                  label: 'Quantity',
                  columnWidth: '6em',
                  mandatory: true,
                  onChange: fapi => {
                    const option = modalDefApi.parentFormApi.values as Option;
                    const vehicle = fapi.fieldData.parentValue as Vehicle;

                    const price = calculateVehiclePrice(
                      vehicle.vehicleType,
                      fapi.newFieldValue,
                      vehicle.bufferPrice,
                      option.distance,
                      option.durationWeekday,
                      option.durationSaturday,
                      option.durationSunday,
                      option.durationPublicHoliday,
                      hourlyRates,
                      option.durationWeekdayWaiting,
                      option.durationSaturdayWaiting,
                      option.durationSundayWaiting,
                      option.durationPublicHolidayWaiting,
                      route
                    );
                    const priceDataAddr = [...fapi.fieldDataAddr];
                    priceDataAddr.pop();
                    priceDataAddr.push('price');

                    fapi.setFormValue(priceDataAddr, price);
                  },
                },
                {
                  fieldType: FieldType.numericField,
                  dataAddr: 'bufferPrice',
                  numericConfig: { numericType: 'unsignedDecimal', maxPointDigits: 4 },
                  label: 'Buffer Price',
                  columnWidth: '7em',
                  onChange: fapi => {
                    const option = modalDefApi.parentFormApi.values as Option;
                    const vehicle = fapi.fieldData.parentValue as Vehicle;

                    const price = calculateVehiclePrice(
                      vehicle.vehicleType,
                      vehicle.quantity,
                      fapi.newFieldValue,
                      option.distance,
                      option.durationWeekday,
                      option.durationSaturday,
                      option.durationSunday,
                      option.durationPublicHoliday,
                      hourlyRates,
                      option.durationWeekdayWaiting,
                      option.durationSaturdayWaiting,
                      option.durationSundayWaiting,
                      option.durationPublicHolidayWaiting,
                      route
                    );
                    const priceDataAddr = [...fapi.fieldDataAddr];
                    priceDataAddr.pop();
                    priceDataAddr.push('price');

                    fapi.setFormValue(priceDataAddr, price);
                  },
                },
                {
                  fieldType: FieldType.readonlyField,
                  dataAddr: 'price',
                  label: 'Price',
                  columnWidth: '7em',
                },
                {
                  fieldType: FieldType.numericField,
                  dataAddr: 'overridePrice',
                  numericConfig: { numericType: 'unsignedDecimal', maxPointDigits: 4 },
                  label: 'Override Price',
                  columnWidth: '7em',
                },
                {
                  fieldType: FieldType.textField,
                  dataAddr: 'overrideReason',
                  label: 'Override Reason',
                  maxLength: 200,
                  validate: d => {
                    return !!d.parentValue.overridePrice && !d.parentValue.overrideReason?.trim()
                      ? 'Override Reason must be specified when price is overridden'
                      : undefined;
                  },
                },
              ],
            },
          ],
        },
        {
          title: 'Staff Member Requirements',
          panes: [
            {
              paneType: PaneType.formFieldsPane,
              columnCount: 2,
              fields: [...getSkillSpecRequirementFieldDefs(skillSpecRequirements)],
            },
          ],
        },
        {
          title: 'Vehicle Requirements',
          panes: [
            {
              paneType: PaneType.formFieldsPane,
              columnCount: 3,
              fields: getTechSpecRequirementFieldDefs(
                splittedTechSpecs,
                searchTechSpecValues,
                techSpecDropdownOptions
              ),
            },
          ],
        },
      ],
      secondaryActions: [getSubmitCloseModalActionGroupDef()],
      onFormSubmit: v => {
        const dataAddr = modalDefApi.parentFormApi.getFullField();

        return isEditing
          ? Promise.resolve(
              modalDefApi.parentFormApi.setValue(dataAddr, {
                ...v,
                skillSpecRequirements: consolidateSkillSpecRequirements(v),
                techSpecRequirements: consolidateTechSpecRequirements(v, splittedTechSpecs),
                changeState:
                  v.changeState === ChangeState.Added ? ChangeState.Added : ChangeState.Modified,
              })
            )
          : Promise.resolve(
              modalDefApi.parentFormApi.addValue(dataAddr, {
                ...v,
                skillSpecRequirements: consolidateSkillSpecRequirements(v),
                techSpecRequirements: consolidateTechSpecRequirements(v, splittedTechSpecs),
                changeState: ChangeState.Added,
              })
            );
      },
    };
  };
}

function getRateForVehicle(vehicleType: VehicleType | undefined, distance: number | undefined) {
  let price = 0;
  if (vehicleType && distance) {
    const rates = vehicleType.vehicleTypeRates;
    if (rates.length === 1) {
      price = rates[0].price;
      return price;
    }

    for (const rate of rates) {
      if (
        distance >= rate.kilometerStart &&
        (rate.kilometerEnd === null || rate.kilometerEnd === undefined)
      ) {
        price = rate.price;
        break;
      }

      if (distance >= rate.kilometerStart && rate.kilometerEnd && distance <= rate.kilometerEnd) {
        price = rate.price;
        break;
      }
    }
  }

  return price;
}

export function calculateVehiclePrice(
  vehicleType: VehicleType | undefined,
  quantity: number | undefined,
  bufferPrice: number | undefined,
  distanceValue: number | undefined,
  durationWeekdayValue: number | undefined,
  durationSaturdayValue: number | undefined,
  durationSundayValue: number | undefined,
  durationPublicHolidayValue: number | undefined,
  hourlyRates: Array<HourlyRateItem>,
  durationWeekdayValueWaiting?: number,
  durationSaturdayValueWaiting?: number,
  durationSundayValueWaiting?: number,
  durationPublicHolidayValueWaiting?: number,
  route?: Route[]
) {
  let hourlyRate;
  const firstTripDate = route ? route[0].date : '0001-01-01';

  for (let i = 0; i < hourlyRates.length; i++) {
    if (firstTripDate >= hourlyRates[i].startDate && firstTripDate <= hourlyRates[i].endDate) {
      hourlyRate = hourlyRates[i];
    }
  }

  const weekdayHourlyRate = hourlyRate?.weekdayRate ?? 0;
  const saturdayHourlyRate = hourlyRate?.saturdayRate ?? 0;
  const sundayHourlyRate = hourlyRate?.sundayRate ?? 0;
  const publicHolidayHourlyRate = hourlyRate?.publicHolidayRate ?? 0;
  const weekdayWaitingHourlyRate = hourlyRate?.weekdayWaitingRate ?? 0;
  const saturdayWaitingHourlyRate = hourlyRate?.saturdayWaitingRate ?? 0;
  const sundayWaitingHourlyRate = hourlyRate?.sundayWaitingRate ?? 0;
  const publicHolidayWaitingHourlyRate = hourlyRate?.publicHolidayWaitingRate ?? 0;

  const vehicleRate = getRateForVehicle(vehicleType, distanceValue);
  const bufferPriceNumber = Number(bufferPrice) || 0;

  if (!!vehicleType) {
    const pricePerVehicle =
      bufferPriceNumber +
      roundPriceToNearest10Dollars(vehicleRate * (distanceValue || 0)) +
      roundPriceToNearest10Dollars(
        weekdayHourlyRate * (durationWeekdayValue || 0) +
          saturdayHourlyRate * (durationSaturdayValue || 0) +
          sundayHourlyRate * (durationSundayValue || 0) +
          publicHolidayHourlyRate * (durationPublicHolidayValue || 0) +
          weekdayWaitingHourlyRate * (durationWeekdayValueWaiting || 0) +
          saturdayWaitingHourlyRate * (durationSaturdayValueWaiting || 0) +
          sundayWaitingHourlyRate * (durationSundayValueWaiting || 0) +
          publicHolidayWaitingHourlyRate * (durationPublicHolidayValueWaiting || 0)
      );
    const price = (quantity || 0) * pricePerVehicle;

    return price;
  }

  return 0;
}

function roundPriceToNearest10Dollars(pricePerVehicle: number) {
  // Vehicles are rounded to the nearest $10 prior to multiplication by the quantity
  return precisionRound(pricePerVehicle, -1);
}

function hasValue(value?: number) {
  return value !== undefined && value !== null;
}

function calculateContractVehiclePrice(api: IFieldOnChange<number>) {
  const vehicle = api.fieldData.parentValue;

  let price = undefined;
  if (vehicle && hasValue(vehicle.quantity) && hasValue(vehicle.pricePerVehicle)) {
    price = vehicle.quantity * vehicle.pricePerVehicle;
  }
  const priceDataAddr = [...api.fieldDataAddr];
  priceDataAddr.pop();
  priceDataAddr.push('price');

  api.setFormValue(priceDataAddr, price);
}
