import { useEffect, useState } from 'react';
import { PopoverProps } from 'reactstrap';
import Popover from 'reactstrap/lib/Popover';

// Related to #3244 where Popover would fail to find the target element in DOM
// Known bug, proper solution is to manage state, but I was unable to get that to work correctly.
// For reference, I couldn't get it to respect isOpen. Clicking would occasionally open it, but randomly.
// Also I see we use it as described above elsewhere, but I believe this area would still have the issue as it's DOM can be replaced instead of destroyed.
export const RendersafePopover: React.FC<PopoverProps> = ({
  target,
  className,
  flip,
  fade,
  isOpen,
  toggle,
  placement,
  children,
}) => {
  // This mess is because this version of Popper seems to have a bug where changing the content doesn't change the location of the popper.
  // The below essentially destroys and recreates the element when the target changes.
  const [stateIsOpen, setStateIsOpen] = useState(true);
  useEffect(() => {
    setStateIsOpen(false);

    setTimeout(() => {
      setStateIsOpen(true);
    }, 10);
  }, [target]);

  if (!document.getElementById(target as string)) {
    return null;
  }

  if (!stateIsOpen) {
    return null;
  }

  return (
    <Popover
      className={className}
      flip={flip}
      fade={fade}
      isOpen={isOpen}
      placement={placement}
      toggle={toggle}
      target={target}
      positionFixed={false}>
      {children}
    </Popover>
  );
};
