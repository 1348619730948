import { createContext } from 'react';
import Omit from 'src/infrastructure/omit';

export type FieldName = string | number | Array<string | number>;

// tslint:disable:no-any
export interface IFormApiWithoutState {
  getValue: (field?: FieldName) => any;
  setValue: (field: FieldName, value: any) => void;
  addValue: (field: FieldName, value: any) => void;
  removeValue: (field: FieldName, index: number) => void;
  swapValues: (field: FieldName, index: number, destIndex: number) => void;
  valuesChanged: () => boolean;
  getError: (field: FieldName) => string | undefined;
  clearAllErrors: () => void;
  hasAnyErrors: () => boolean;
  getAllErrors: () => string[];
  validate: (field: FieldName) => void;
  validateAll: () => Promise<boolean>;
  resetAll: (newValues?: {}) => void;
  setAllValues: (values: {}) => void;
  submitForm: (sumissionMeta?: {}) => Promise<void>;
  getTouched: (field: FieldName) => boolean;
  setTouched: (field: FieldName, touched?: boolean) => void;
}

export interface IFormApi extends IFormApiWithoutState {
  getFullField: (field?: FieldName) => Array<string | number>;

  values: any;
  submits: number;
  submitting: boolean;
  submitted: boolean;
}
// tslint:enable:no-any

export type ValidateFunc = (value: unknown) => undefined | string;
export type ValidateAsyncFunc = (value: unknown) => undefined | Promise<undefined | string>;

export interface IRfsContext {
  formApi: IFormApi;
  parentPath: Array<string | number>;
  errors: Map<string, string>;
  registerField: (
    id: symbol,
    fullFieldName: FieldName,
    validate: undefined | ValidateFunc,
    validateAsync: undefined | ValidateAsyncFunc
  ) => void;
  unregisterField: (id: symbol) => void;
}

// Default is only needed when consumers are used without a provider, which we won't do for now
const defaultRfsContext = {
  formApi: {} as IFormApi,
  parentPath: [],
  errors: new Map<string, string>(),
  registerField: () => undefined,
  unregisterField: () => undefined,
};

export const RfsContext = createContext<IRfsContext>(defaultRfsContext);

export function withRfsContext<T extends { rfsContext: IRfsContext }>(
  component: React.ComponentType<T>
): React.FC<Omit<T, 'rfsContext'>> {
  const Component = component;
  const ComponentWithFormContext = (props: Omit<T, 'rfsContext'>) => (
    <RfsContext.Consumer>
      {ctx => <Component {...({ ...props, rfsContext: ctx } as T)} />}
    </RfsContext.Consumer>
  );
  return ComponentWithFormContext;
}

export function withFormApi<T extends { formApi: IFormApi }>(
  component: React.ComponentType<T>
): React.FC<Omit<T, 'formApi'>> {
  const Component = component;
  const ComponentWithFormApi = (props: Omit<T, 'formApi'>) => (
    <RfsContext.Consumer>
      {ctx => <Component {...({ ...props, formApi: ctx.formApi } as T)} />}
    </RfsContext.Consumer>
  );
  return ComponentWithFormApi;
}

// Only pick out a subset of the formApi, to allow PureComponents to optimise to just what they need
export function withSelectedFormApi<TSelectedProps extends {}>(
  selector: (formApi: IFormApi) => TSelectedProps
): <T extends TSelectedProps>(
  component: React.ComponentType<T>
) => React.FC<Omit<T, keyof TSelectedProps>> {
  const func = <T extends TSelectedProps>(component: React.ComponentType<T>) => {
    const Component = component;
    const ComponentWithFormApi = (props: Omit<T, keyof TSelectedProps>) => (
      <RfsContext.Consumer>
        {ctx => <Component {...({ ...props, ...selector(ctx.formApi) } as T)} />}
      </RfsContext.Consumer>
    );
    return ComponentWithFormApi;
  };
  return func;
}
