import inject from 'src/views/injectFromStore';
import PrintDailyRunSheet, { IPrintDailyRunSheetProps } from './PrintDailyRunSheet';

interface IInjectedProps {
  dailyRunSheetItem: IPrintDailyRunSheetProps['dailyRunSheetItem'];
  loadDailyRunSheet: IPrintDailyRunSheetProps['loadDailyRunSheet'];
  dailyRunSheetIsLoading: IPrintDailyRunSheetProps['dailyRunSheetIsLoading'];
}

const Container = inject<IInjectedProps, IPrintDailyRunSheetProps>((allStores, p) => ({
  dailyRunSheetItem: allStores.rootStore.jobs.dailyRunSheetItem,
  loadDailyRunSheet: allStores.rootStore.jobs.loadDailyRunSheet,
  dailyRunSheetIsLoading: allStores.rootStore.jobs.dailyRunSheetIsLoading,
}))(PrintDailyRunSheet);

export default Container;
