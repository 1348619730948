import styles from './WorkshopShiftProgressStatusIconLegend.module.scss';
import cn from 'classnames';

import { allShiftProgressStatus, ShiftProgressStatus } from 'src/api/enums';
import WorkshopShiftProgressStatusIcon from '../shiftProgressIcon/WorkshopShiftProgressStatusIcon';

type StyleOption = 'forRoster' | 'forDailySchedule' | undefined;

interface IWorkshopShiftProgressStatusIconLegendProps {
  showWarningsOnly?: boolean;
  styleOption?: StyleOption;
}

function getLegend(showWarningsOnly: boolean | undefined, style: StyleOption): JSX.Element {
  const all = allShiftProgressStatus.filter(
    x =>
      x.value !== ShiftProgressStatus.NotTracked &&
      x.value !== ShiftProgressStatus.ClockOffInTheFuture &&
      (!showWarningsOnly || x.value === ShiftProgressStatus.IncompleteShift)
  );
  const allIcons = all.map(x => {
    return {
      icon: (
        <WorkshopShiftProgressStatusIcon status={x.value} showWarningsOnly={showWarningsOnly} />
      ),
      description: x.description,
    };
  });

  const legendStyle = style && style === 'forDailySchedule' ? styles.dailySchedule : styles.roster;
  return (
    <div className={cn(legendStyle, styles.legend)}>
      {allIcons &&
        allIcons.map((i, index) => {
          return (
            <div key={index} className={styles.legendItem}>
              <span className={styles.icon}>{i.icon}</span> {i.description}
            </div>
          );
        })}
    </div>
  );
}

const WorkshopShiftProgressStatusIconLegend: React.FC<IWorkshopShiftProgressStatusIconLegendProps> = ({
  showWarningsOnly,
  styleOption,
}) => getLegend(showWarningsOnly, styleOption);

export default WorkshopShiftProgressStatusIconLegend;
