import { DateTime, Duration } from 'luxon';
import { Link } from 'react-router-dom';
import { PaneType, ModalDefBuilder, FieldType } from 'src/views/definitionBuilders/types';
import { getPropertyName } from 'src/infrastructure/typeUtils';
import { findFirstTrip } from './utils/quoteHelpers';
import PageField from 'src/views/components/Page/fields/PageField';
import { NestedField } from 'src/views/components/Page/forms';
import { getSubmitCloseModalActionGroupDef } from 'src/views/definitionBuilders/common';

type Repeat = Operations.Domain.Queries.ViewQuote.Repeat;
type QuoteItem = Operations.Domain.Queries.ViewQuote.QuoteItem;

interface IOption {
  id: number;
  skipTripsUpdate: boolean;
  display: string;
}

interface IModalModel {
  repeats: (Repeat & {
    saveAllMeta: IOption;
  })[];
}

export default function getUpdateFutureQuotesModalDef(
  futureRepeats: Repeat[],
  quoteItem: QuoteItem | undefined,
  onSave: (skipFutureQuoteTripUpdate: string[]) => Promise<void>
): ModalDefBuilder {
  return modalDefApi => {
    const originalFirstTripDate = quoteItem && findFirstTrip(quoteItem.trips);
    const updatedFirstTripDate =
      modalDefApi &&
      findFirstTrip(modalDefApi.parentFormApi.getValue(getPropertyName<QuoteItem>('trips')));
    const firstTripDateChanged =
      !!originalFirstTripDate &&
      !!updatedFirstTripDate &&
      originalFirstTripDate !== updatedFirstTripDate;

    let offsetDays = Duration.fromObject({});
    if (firstTripDateChanged) {
      offsetDays = DateTime.fromISO(updatedFirstTripDate!).diff(
        DateTime.fromISO(originalFirstTripDate!)
      );
    }

    const formatDate = (date: DateTime): string => {
      return date.toLocaleString(DateTime.DATE_HUGE);
    };

    const optionsMap: {
      linkedQuoteId: string;
      options: IOption[];
    }[] = futureRepeats.map(repeat => {
      return {
        linkedQuoteId: repeat.linkedQuoteId!,
        options: [
          {
            id: 0,
            skipTripsUpdate: false,
            display: `Move to ${formatDate(DateTime.fromISO(repeat.repeatDate).plus(offsetDays))}`,
          },
          {
            id: 1,
            skipTripsUpdate: true,
            display: `Don't update any trips (only basic booking data)`,
          },
        ],
      };
    });

    const explicitData: IModalModel = {
      repeats: futureRepeats.map(x => {
        return {
          ...x,
          saveAllMeta: optionsMap
            .find(o => o.linkedQuoteId === x.linkedQuoteId)!
            .options.find(o => !o.skipTripsUpdate)!,
        };
      }),
    };

    return {
      title: 'Update All Future Quotes',
      asForm: true,
      explicitData: explicitData,
      panels: [
        {
          panes: [
            {
              paneType: PaneType.customPane,
              render: api => {
                const repeats = (api.data.paneValue as IModalModel).repeats;
                return (
                  <div>
                    <p>
                      <span>This will update the current booking, and also the following </span>
                      <span>{repeats.length}</span>
                      <span> bookings. Any individual changes will be overridden.</span>
                    </p>
                    <div>
                      {repeats.map((repeat, index) => (
                        <div key={repeat.id}>
                          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <span>{formatDate(DateTime.fromISO(repeat.repeatDate))}</span>
                            <Link to={`/sales/bookings/${repeat.linkedQuoteId}`}>
                              Booking {repeat.linkedQuoteNumber} (
                              {repeat.linkedQuoteStatus && repeat.linkedQuoteStatus.description})
                            </Link>
                          </div>
                          {firstTripDateChanged ? (
                            <div>
                              <NestedField field={[getPropertyName<IModalModel>('repeats'), index]}>
                                <PageField
                                  fieldDef={{
                                    fieldType: FieldType.selectField,
                                    dataAddr: 'saveAllMeta',
                                    label: 'Update Action',
                                    optionItems: optionsMap.find(
                                      x => x.linkedQuoteId === repeat.linkedQuoteId
                                    )!.options,
                                    descriptionKey: 'display',
                                    valueKey: 'id',
                                    useOptionRendererAsValueRenderer: true,
                                    mandatory: true,
                                  }}
                                  fieldMeta={{ formChange$: api.meta.formChange$, hideLabel: true }}
                                  paneData={api.data}
                                  parentValue={api.data.parentValue}
                                />
                              </NestedField>
                            </div>
                          ) : (
                            undefined
                          )}
                        </div>
                      ))}
                    </div>
                  </div>
                );
              },
            },
          ],
        },
      ],
      secondaryActions: [getSubmitCloseModalActionGroupDef('Save All')],
      onFormSubmit: v => {
        const repeats = (v as IModalModel).repeats;
        const skipRepeatTripUpdate = (firstTripDateChanged ? repeats : [])
          .filter(x => x.saveAllMeta.skipTripsUpdate)
          .map(x => x.linkedQuoteId!);
        return onSave(skipRepeatTripUpdate);
      },
    };
  };
}
