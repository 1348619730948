import { ReactComponent as Blob } from 'src/images/blob.svg';
import { INTRO_VIDEO, INTRO_VIDEO_FORMAT } from 'src/appSettings';

export const ShellHomeBackground = () => (
  <div className="ShellBackground">
    <video id="background-video" autoPlay loop muted playsInline>
      <source src={INTRO_VIDEO} type={INTRO_VIDEO_FORMAT} />
      Your browser does not support the video tag.
    </video>

    <Blob />
  </div>
);

export default ShellHomeBackground;
