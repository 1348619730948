import styles from './Card.module.scss';

import cn from 'classnames';

interface ICardProps {
  className?: string;
}

const Card: React.FC<ICardProps> = ({ children, className }) => (
  <div className={cn(styles.card, className)}>{children}</div>
);

export default Card;
