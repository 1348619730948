import { Component } from 'react';
import { FormFeedback } from 'reactstrap';
import { IFormApi } from 'src/views/components/Page/forms/base';
import { IFieldApi } from 'src/views/components/Page/forms/Field';
interface IErrorPageFieldProps {
  formApi: IFormApi;
  fieldApi: IFieldApi;
}

class ErrorPageField extends Component<IErrorPageFieldProps> {
  render() {
    const { formApi, fieldApi } = this.props;
    return formApi.submits && fieldApi.error ? (
      <FormFeedback style={{ display: 'initial' }}>{fieldApi.error}</FormFeedback>
    ) : null;
  }
}

export default ErrorPageField;
