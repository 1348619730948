import { Component } from 'react';
import { IFieldApi } from 'src/views/components/Page/forms/Field';
import {
  IFieldData,
  IFieldDef,
  IListFieldDefBase,
  IFieldMeta,
} from 'src/views/definitionBuilders/types';

interface IListFieldBaseProps {
  // tslint:disable-next-line:no-any
  fieldDef: IFieldDef & IListFieldDefBase<any>;
  // tslint:disable-next-line:no-any
  fieldData: IFieldData<any>;
  fieldMeta: IFieldMeta;
  fieldApi: IFieldApi;
}

abstract class ListFieldBase<TProps extends IListFieldBaseProps, TState = {}> extends Component<
  TProps,
  TState
> {
  protected readonly getValuesToExclude = () => {
    const { fieldDef } = this.props;
    const values =
      typeof fieldDef.valuesToExclude === 'function'
        ? fieldDef.valuesToExclude(this.props.fieldData)
        : fieldDef.valuesToExclude;
    return values || [];
  };

  protected readonly getValuesToDisable = () => {
    const { fieldDef } = this.props;
    const values =
      typeof fieldDef.valuesToDisable === 'function'
        ? fieldDef.valuesToDisable(this.props.fieldData)
        : fieldDef.valuesToDisable;
    return values || [];
  };

  protected readonly handleBlur = () => {
    this.props.fieldApi.setTouched(true);
    this.props.fieldMeta.onBlur && this.props.fieldMeta.onBlur(this.props.fieldData.fieldValue);
  };
}

export default ListFieldBase;
