import { FieldDefs, FieldType } from 'src/views/definitionBuilders/types/field';
import ListPage, { IListPageDef } from 'src/views/components/Page/pages/ListPage';
import { PagePrimarySize } from 'src/views/definitionBuilders/types';
import TaskCardItem from 'src/views/components/TaskCard/TaskCardItem';
import { IListPageLoadDataRequest } from 'src/domain/baseTypes';
import PrimaryTitle from 'src/views/components/Page/PrimaryTitle/PrimaryTitle';

type ListTimesheetAllowancesQuery = Common.Queries.People.TimesheetAllowances.ListTimesheetAllowancesQuery;

export interface IListTimesheetAllowancesProps {
  loadTimesheetAllowances: (
    request: IListPageLoadDataRequest<ListTimesheetAllowancesQuery>
  ) => Promise<void>;
  timesheetallowances: Common.Queries.People.TimesheetAllowances.ListTimesheetAllowanceItem[];
  canManageTimesheetAllowances: boolean;
}

const ListTimesheetAllowances: React.FC<IListTimesheetAllowancesProps> = (
  props: IListTimesheetAllowancesProps
) => {
  const getFilterFieldDefs = () => {
    return {
      active: {
        fieldType: FieldType.yesNoField,
        label: 'Active',
        dataAddr: 'active',
        useValueOnly: true,
      } as FieldDefs,
    };
  };
  const getPageDef = (): IListPageDef => {
    const { loadTimesheetAllowances } = props;
    const filterFieldDefsLookup = getFilterFieldDefs();

    return {
      primaryTitle: (
        <PrimaryTitle
          title="Timesheet Allowances"
          link="https://www.notion.so/Timesheet-Allowances-e3e05439d5d8489e8cd2f137b5803adf"></PrimaryTitle>
      ),
      primarySize: PagePrimarySize.full,
      onLoadData: loadTimesheetAllowances,
      externalSearch: true,
      createLink: props.canManageTimesheetAllowances
        ? TaskCardItem.people.createTimesheetAllowance
        : undefined,
      primaryFields: [
        {
          fieldType: FieldType.textField,
          dataAddr: 'name',
          label: 'Name',
          linkTo: d => `/people/timesheet-allowances/${d.parentValue.id}`,
        },
        {
          fieldType: FieldType.textField,
          dataAddr: 'payrollCode',
          label: 'Payroll Code',
        },
      ],
      filterAction: {
        defaultValues: {
          active: true,
        },
        filterFields: Object.keys(filterFieldDefsLookup).map(k => filterFieldDefsLookup[k]),
      },
    };
  };

  const { timesheetallowances } = props;
  const def = getPageDef();
  return (
    <ListPage className="list-timesheetallowances-component" data={timesheetallowances} def={def} />
  );
};

export default ListTimesheetAllowances;
