import { IAjax } from 'src/infrastructure/ajax';
import { kioskUrls } from 'src/domain/services/apiUrls';

type WorkshopDepot = Workshop.Domain.AggregatesModel.AssetAggregate.AssetHousingLocation;

export default function init(ajax: IAjax) {
  function getDepots() {
    return ajax
      .getWithoutAppHeaders(kioskUrls.workshopUrls.loadDepots())
      .toPromise()
      .then<WorkshopDepot[]>(r => r.response);
  }

  return {
    depots: {
      getDepots,
    },
  };
}
