import { flow, types } from 'mobx-state-tree';
import { getAjax } from 'src/domain/services';
import { operationsUrls } from 'src/domain/services/apiUrls';

type OperationsStartUpDto = Operations.Domain.Queries.GetOperationsStartUpData.OperationsStartUpDto;
type StaffMemberName = Common.Queries.GetStaffMemberNames.StaffMemberName;
type OperationsDepotDto = Common.Dtos.OperationsDepotDto;
type ListRoleItem = Common.Dtos.ListRoleItem;

export const OperationsStartUpModel = types
  .model('OperationsStartUpModel', {
    operationsDepots: types.array(types.frozen<OperationsDepotDto>()),
    salesPeople: types.array(types.frozen<StaffMemberName>()),
    defaultOperationsDepot: types.maybe(types.frozen<OperationsDepotDto>()),
    defaultSalesRepresentativeId: types.maybe(types.string),
    defaultRolesForDriverAllocations: types.array(types.frozen<ListRoleItem>()),
  })
  .actions(self => {
    const ajax = getAjax(self);

    const loadStartUpData = flow(function*() {
      const response = yield ajax.raw
        .get(operationsUrls.startUpUrls.getStartUpData)
        .toPromise()
        .then<OperationsStartUpDto>(r => r.response as OperationsStartUpDto);

      self.defaultSalesRepresentativeId = response.defaultSalesRepresentativeId || undefined;
      self.operationsDepots = response.operationsDepots;
      self.defaultOperationsDepot = response.operationsDepots.find(
        (d: OperationsDepotDto) => d.id === response.loggedInStaffMemberDefaultDepotId
      );
      self.salesPeople = response.salesPeopleNames;
      self.defaultRolesForDriverAllocations = response.defaultRolesForDriverAllocations;
    });

    return { loadStartUpData };
  });
