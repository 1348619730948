import './MaintainOwner.scss';
import { RouteComponentProps } from 'react-router-dom';
import {
  PagePrimarySize,
  PaneType,
  FieldType,
  ActionType,
  ShellModalSize,
  IModalDefBuilderApi,
  ISectionDef,
} from 'src/views/definitionBuilders/types';
import CrudPage, { CrudPageMode, ICrudPageDef } from 'src/views/components/Page/pages/CrudPage';
import * as EmailValidator from 'email-validator';
import { PlusIcon, EditIcon, TrashIcon } from 'src/images/icons';
import ActionBar from 'src/views/components/Page/actions/ActionBar';
import { standardTextSanitisers } from 'src/views/componentBuilders';
import { DateTime } from 'luxon';
import { getSubmitCloseModalActionGroupDef } from 'src/views/definitionBuilders/common';
import getCanOwnerBeDeletedModalDef from './GetCanOwnerBeDeletedModalDef';
import { observer } from 'mobx-react';
import { useRootStore } from 'src/domain/entities/RootStoreModel';

type OwnerItem = Workshop.Domain.Queries.Owners.OwnerItem;
type CreateOwnerCommand = Workshop.Domain.Commands.Owner.CreateOwnerCommand;
type UpdateOwnerCommand = Workshop.Domain.Commands.Owner.UpdateOwnerCommand;
type OwnerContact = Workshop.Domain.AggregatesModel.OwnerAggregate.OwnerContact;
type CreateOwnerContactCommand = Workshop.Domain.Commands.Owner.CreateOwnerContactCommand;
type UpdateOwnerContactCommand = Workshop.Domain.Commands.Owner.UpdateOwnerContactCommand;
type OwnerNote = Workshop.Domain.AggregatesModel.OwnerAggregate.OwnerNote;
type CreateOwnerNoteCommand = Workshop.Domain.Commands.Owner.CreateOwnerNoteCommand;
type UpdateOwnerNoteCommand = Workshop.Domain.Commands.Owner.UpdateOwnerNoteCommand;

export interface IMaintainOwnerProps {
  mode: CrudPageMode;
}

interface IUpdateOwnerRouteParams {
  id: string;
}

type InternalProps = IMaintainOwnerProps & RouteComponentProps<IUpdateOwnerRouteParams>;

const MaintainOwner: React.FC<InternalProps> = observer((props: InternalProps) => {
  const rootStore = useRootStore();
  const accountModel = rootStore.account;
  const ownerModel = rootStore.workshop.owner;
  const ownerObject = ownerModel.owner;
  const onLoadOwner = ownerModel.loadOwner;
  const onUpdateOwner = ownerModel.updateOwner;
  const onCreateOwner = ownerModel.createOwner;
  const checkForUniqueOwnerName = ownerModel.checkForUniqueName;
  const onCreateOwnerContact = ownerModel.createOwnerContact;
  const onUpdateOwnerContact = ownerModel.updateOwnerContact;
  const onCreateOwnerNote = ownerModel.createOwnerNote;
  const onUpdateOwnerNote = ownerModel.updateOwnerNote;
  const deleteOwner = ownerModel.deleteOwner;
  const canDeleteOwner = ownerModel.canDeleteOwner;
  const loadCanDeleteOwner = ownerModel.loadCanDeleteOwner;
  const setOwnerActive = ownerModel.setOwnerActive;
  const setOwnerInactive = ownerModel.setOwnerInactive;
  const canManageOwners = accountModel.isWorkshopDepartmentMember;

  const isUpdateMode = props.mode === 'update';
  const ownerId = props.match.params.id;

  const handlePreSubmitForCreate = (owner: OwnerItem): CreateOwnerCommand => {
    return {
      name: owner.name,
      billingAddress: owner.billingAddress,
      billingCity: owner.billingCity,
      billingPostcode: owner.billingPostcode,
      billingState: owner.billingState,
      email: owner.email,
      fax: owner.fax,
      otherAddress: owner.otherAddress,
      otherCity: owner.otherCity,
      otherPostcode: owner.otherPostcode,
      otherState: owner.otherState,
      phone: owner.phone,
      website: owner.website,
      isBillable: owner.isBillable,
    };
  };

  const handlePreSubmitForUpdate = (owner: OwnerItem): UpdateOwnerCommand => {
    return {
      id: ownerId,
      name: owner.name,
      billingAddress: owner.billingAddress,
      billingCity: owner.billingCity,
      billingPostcode: owner.billingPostcode,
      billingState: owner.billingState,
      email: owner.email,
      fax: owner.fax,
      otherAddress: owner.otherAddress,
      otherCity: owner.otherCity,
      otherPostcode: owner.otherPostcode,
      otherState: owner.otherState,
      phone: owner.phone,
      website: owner.website,
      isBillable: owner.isBillable,
    };
  };

  const getPageDef = (readonly: boolean): ICrudPageDef => {
    return {
      primarySize: PagePrimarySize.half,
      primarySection: {
        title: isUpdateMode ? 'Manage Owner' : 'Create an Owner',
        badge: ownerObject && !ownerObject.isActive ? { label: 'Inactive' } : undefined,
        primaryActions: [
          {
            actions: [
              {
                actionType: ActionType.actionCollection,
                hidden: !isUpdateMode,
                actionGroups: [
                  {
                    actions: [
                      {
                        actionType: ActionType.modalActionButton,
                        label: 'Delete Owner',
                        icon: <TrashIcon />,
                        hidden: !ownerObject || !ownerObject.isActive,
                        modalSize: ShellModalSize.oneQuarter,
                        modalDef: getCanOwnerBeDeletedModalDef(
                          ownerId,
                          canDeleteOwner!,
                          deleteOwner,
                          setOwnerInactive
                        ),
                      },
                      {
                        actionType: ActionType.modalActionButton,
                        label: 'Mark as Active',
                        icon: <PlusIcon />,
                        hidden: !ownerObject || ownerObject.isActive,
                        modalSize: ShellModalSize.oneQuarter,
                        modalDef: () => ({
                          title: 'Mark as Active',
                          asForm: true,
                          panels: [
                            {
                              title: '',
                              panes: [
                                {
                                  paneType: PaneType.customPane,
                                  render: () => (
                                    <div>
                                      <p>
                                        Once active, the owner will be searchable and will be
                                        available for use throughout the application.
                                      </p>
                                      <p>Are you sure you want to mark this owner as active?</p>
                                    </div>
                                  ),
                                },
                              ],
                            },
                          ],
                          secondaryActions: [getSubmitCloseModalActionGroupDef('Mark active')],
                          onFormSubmit: () => setOwnerActive(ownerId),
                        }),
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
        panels: [
          {
            panes: [
              {
                paneType: PaneType.formFieldsPane,
                columnCount: 1,
                fields: [
                  {
                    fieldType: FieldType.textField,
                    label: 'Name',
                    dataAddr: 'name',
                    maxLength: 255,
                    mandatory: true,
                    validateAsync: async d => {
                      if (
                        !d.fieldValue ||
                        (isUpdateMode &&
                          ownerObject &&
                          ownerObject.name.toUpperCase() === d.fieldValue.toUpperCase())
                      ) {
                        return undefined;
                      }

                      const result = await checkForUniqueOwnerName(d.fieldValue);
                      return result.nameExists ? `Name is already in use` : undefined;
                    },
                  },
                ],
              },
              {
                paneType: PaneType.formFieldsPane,
                columnCount: 1,
                fields: [
                  {
                    fieldType: FieldType.yesNoField,
                    label: 'Jobs Require Billing',
                    dataAddr: 'isBillable',
                    mandatory: true,
                    tooltip:
                      'Setting this to Yes indicates that new tasks created for assets that belong to this owner are billable.',
                  },
                ],
              },
            ],
          },
          {
            title: 'Organisation Contact Details',
            panes: [
              {
                paneType: PaneType.formFieldsPane,
                columnCount: 1,
                fields: [
                  {
                    fieldType: FieldType.textField,
                    label: 'Phone',
                    dataAddr: 'phone',
                    maxLength: 20,
                  },
                ],
              },
              {
                paneType: PaneType.formFieldsPane,
                columnCount: 1,
                fields: [
                  {
                    fieldType: FieldType.textField,
                    label: 'Fax',
                    dataAddr: 'fax',
                    maxLength: 20,
                  },
                ],
              },
              {
                paneType: PaneType.formFieldsPane,
                columnCount: 1,
                fields: [
                  {
                    fieldType: FieldType.textField,
                    label: 'Email',
                    dataAddr: 'email',
                    maxLength: 200,
                    validate: d => {
                      return !!d.fieldValue && !EmailValidator.validate(d.fieldValue)
                        ? `${d.fieldValue} must be a valid email`
                        : undefined;
                    },
                  },
                ],
              },
              {
                paneType: PaneType.formFieldsPane,
                columnCount: 1,
                fields: [
                  {
                    fieldType: FieldType.textField,
                    label: 'Website',
                    dataAddr: 'website',
                    maxLength: 200,
                  },
                ],
              },
            ],
          },
          {
            title: 'Billing Address',
            panes: [
              {
                paneType: PaneType.formFieldsPane,
                columnCount: 1,
                fields: [
                  {
                    fieldType: FieldType.textField,
                    label: 'Street',
                    dataAddr: 'billingAddress',
                    maxLength: 200,
                  },
                ],
              },
              {
                paneType: PaneType.formFieldsPane,
                columnCount: 1,
                fields: [
                  {
                    fieldType: FieldType.textField,
                    label: 'City',
                    dataAddr: 'billingCity',
                    maxLength: 20,
                  },
                ],
              },
              {
                paneType: PaneType.formFieldsPane,
                columnCount: 2,
                fields: [
                  {
                    fieldType: FieldType.textField,
                    label: 'State',
                    dataAddr: 'billingState',
                    maxLength: 20,
                  },
                  {
                    fieldType: FieldType.textField,
                    label: 'Postcode',
                    dataAddr: 'billingPostcode',
                    maxLength: 20,
                  },
                ],
              },
            ],
          },
          {
            title: 'Other Address',
            panes: [
              {
                paneType: PaneType.formFieldsPane,
                columnCount: 1,
                fields: [
                  {
                    fieldType: FieldType.textField,
                    label: 'Street',
                    dataAddr: 'otherAddress',
                    maxLength: 200,
                  },
                ],
              },
              {
                paneType: PaneType.formFieldsPane,
                columnCount: 1,
                fields: [
                  {
                    fieldType: FieldType.textField,
                    label: 'City',
                    dataAddr: 'otherCity',
                    maxLength: 20,
                  },
                ],
              },
              {
                paneType: PaneType.formFieldsPane,
                columnCount: 2,
                fields: [
                  {
                    fieldType: FieldType.textField,
                    label: 'State',
                    dataAddr: 'otherState',
                    maxLength: 20,
                  },
                  {
                    fieldType: FieldType.textField,
                    label: 'Postcode',
                    dataAddr: 'otherPostcode',
                    maxLength: 20,
                  },
                ],
              },
            ],
          },
        ],
        onFormPreSubmit: isUpdateMode ? handlePreSubmitForUpdate : handlePreSubmitForCreate,
        onFormSubmit: isUpdateMode ? onUpdateOwner : onCreateOwner,
      },
      secondarySections: !isUpdateMode
        ? []
        : [
            {
              title: 'Contacts',
              panels: [
                {
                  panes: [
                    {
                      paneType: PaneType.repeatingPane,
                      dataAddr: 'contacts',
                      itemPanes: [
                        {
                          paneType: PaneType.customPane,
                          render: api => {
                            const contact = api.data.paneValue as OwnerContact;
                            return (
                              <div className="owner-maintenance-contact-item">
                                <div className="details">
                                  <div>
                                    <strong>{`${contact.firstName} ${contact.lastName ||
                                      ''}`}</strong>
                                  </div>
                                  {contact.phone ? (
                                    <div>
                                      <label>P</label> {contact.phone}
                                    </div>
                                  ) : null}
                                  {contact.mobile ? (
                                    <div>
                                      <label>M</label> {contact.mobile}
                                    </div>
                                  ) : null}
                                  {contact.fax ? (
                                    <div>
                                      <label>F</label> {contact.fax}
                                    </div>
                                  ) : null}
                                  {contact.email ? (
                                    <div>
                                      <label>E</label> {contact.email}
                                    </div>
                                  ) : null}
                                </div>
                                {canManageOwners && (
                                  <div className="edit">
                                    <ActionBar
                                      actionGroupDefs={[
                                        {
                                          actions: [
                                            {
                                              actionType: ActionType.modalActionButton,
                                              label: 'Edit',
                                              hidden: !readonly,
                                              icon: <EditIcon size="sm" />,
                                              modalSize: ShellModalSize.oneThird,
                                              modalDef: getContactModalDef('edit'),
                                            },
                                          ],
                                        },
                                      ]}
                                      actionMeta={{
                                        formSubmitting: false,
                                        hideLabel: true,
                                        borderless: true,
                                      }}
                                      actionData={{ ...api.data, actionValue: undefined }}
                                    />
                                  </div>
                                )}
                              </div>
                            );
                          },
                        },
                      ],
                    },
                  ],
                },
              ],
              secondaryActions: canManageOwners
                ? [
                    {
                      actions: [
                        {
                          actionType: ActionType.modalActionButton,
                          label: 'Add Contact',
                          hidden: !readonly,
                          icon: <PlusIcon />,
                          modalSize: ShellModalSize.oneThird,
                          modalDef: getContactModalDef('add'),
                        },
                      ],
                    },
                  ]
                : [],
            },
            {
              title: 'Notes',
              panels: [
                {
                  panes: [
                    {
                      paneType: PaneType.repeatingPane,
                      dataAddr: 'notes',
                      itemPanes: [
                        {
                          paneType: PaneType.customPane,
                          render: api => {
                            const note = api.data.paneValue as OwnerNote;
                            return (
                              <div className="owner-maintenance-note-item">
                                <div className="author">{note.lastModifiedBy}</div>
                                <div className="date">
                                  {DateTime.fromISO(note.lastModifiedOn).toLocaleString(
                                    DateTime.DATETIME_MED
                                  )}
                                </div>
                                {canManageOwners && (
                                  <div className="edit">
                                    <ActionBar
                                      actionGroupDefs={[
                                        {
                                          actions: [
                                            {
                                              actionType: ActionType.modalActionButton,
                                              label: 'Edit',
                                              hidden: !readonly,
                                              icon: <EditIcon size="sm" />,
                                              modalSize: ShellModalSize.oneThird,
                                              modalDef: getNoteModalDef('edit'),
                                            },
                                          ],
                                        },
                                      ]}
                                      actionMeta={{
                                        formSubmitting: false,
                                        hideLabel: true,
                                        borderless: true,
                                      }}
                                      actionData={{ ...api.data, actionValue: undefined }}
                                    />
                                  </div>
                                )}
                                <div className="note-details">{note.details}</div>
                              </div>
                            );
                          },
                        },
                      ],
                    },
                  ],
                },
              ],
              secondaryActions: canManageOwners
                ? [
                    {
                      actions: [
                        {
                          actionType: ActionType.modalActionButton,
                          label: 'Add Note',
                          hidden: !readonly,
                          icon: <PlusIcon />,
                          modalSize: ShellModalSize.oneThird,
                          modalDef: getNoteModalDef('add'),
                        },
                      ],
                    },
                  ]
                : [],
            },
          ],
    };
  };

  const getContactModalDef = (
    mode: 'add' | 'edit'
  ): ((modalDefApi: IModalDefBuilderApi) => ISectionDef) => {
    return modalDefApi => ({
      title: mode === 'add' ? 'Add Contact' : 'Edit Contact',
      asForm: true,
      explicitData: mode === 'add' ? {} : modalDefApi.actionData.paneValue,
      panels: [
        {
          panes: [
            {
              paneType: PaneType.formFieldsPane,
              columnCount: 2,
              fields: [
                {
                  fieldType: FieldType.textField,
                  mandatory: true,
                  label: 'First Name',
                  dataAddr: 'firstName',
                  maxLength: 200,
                },
                {
                  fieldType: FieldType.textField,
                  label: 'Last Name',
                  dataAddr: 'lastName',
                  maxLength: 200,
                },
              ],
            },
          ],
        },
        {
          title: 'Contact Details',
          panes: [
            {
              paneType: PaneType.formFieldsPane,
              fields: [
                {
                  fieldType: FieldType.textField,
                  label: 'Phone',
                  dataAddr: 'phone',
                  maxLength: 20,
                  onChange: api =>
                    api.setFormValue(
                      api.fieldDataAddr,
                      standardTextSanitisers.numericSanitiser(api.fieldData.fieldValue as string)
                    ),
                },
                {
                  fieldType: FieldType.textField,
                  label: 'Mobile',
                  dataAddr: 'mobile',
                  maxLength: 20,
                  onChange: api =>
                    api.setFormValue(
                      api.fieldDataAddr,
                      standardTextSanitisers.numericSanitiser(api.fieldData.fieldValue as string)
                    ),
                },
                {
                  fieldType: FieldType.textField,
                  label: 'Fax',
                  dataAddr: 'fax',
                  maxLength: 20,
                  onChange: api =>
                    api.setFormValue(
                      api.fieldDataAddr,
                      standardTextSanitisers.numericSanitiser(api.fieldData.fieldValue as string)
                    ),
                },
                {
                  fieldType: FieldType.textField,
                  label: 'Email',
                  dataAddr: 'email',
                  maxLength: 200,
                  validate: d => {
                    return !!d.fieldValue && !EmailValidator.validate(d.fieldValue)
                      ? `${d.fieldValue} must be a valid email`
                      : undefined;
                  },
                },
              ],
            },
          ],
        },
      ],
      secondaryActions: [getSubmitCloseModalActionGroupDef('Ok')],
      onFormPreSubmit: (
        contact: OwnerContact
      ): CreateOwnerContactCommand | UpdateOwnerContactCommand => {
        let cmd = {
          ownerId: ownerId,
          email: contact.email,
          fax: contact.fax,
          firstName: contact.firstName,
          lastName: contact.lastName,
          mobile: contact.mobile,
          phone: contact.phone,
        };

        return mode === 'add'
          ? cmd
          : {
              ...cmd,
              contactId: contact.id,
            };
      },
      onFormSubmit: mode === 'add' ? onCreateOwnerContact : onUpdateOwnerContact,
    });
  };

  const getNoteModalDef = (
    mode: 'add' | 'edit'
  ): ((modalDefApi: IModalDefBuilderApi) => ISectionDef) => {
    return modalDefApi => ({
      title: mode === 'add' ? 'Add Note' : 'Edit Note',
      asForm: true,
      explicitData: mode === 'add' ? {} : modalDefApi.actionData.paneValue,
      panels: [
        {
          panes: [
            {
              paneType: PaneType.formFieldsPane,
              fields: [
                {
                  fieldType: FieldType.textAreaField,
                  mandatory: true,
                  label: 'Details',
                  dataAddr: 'details',
                },
              ],
            },
          ],
        },
      ],
      secondaryActions: [getSubmitCloseModalActionGroupDef('Ok')],
      onFormPreSubmit: (note: OwnerNote): CreateOwnerNoteCommand | UpdateOwnerNoteCommand => {
        return mode === 'add'
          ? {
              ownerId: ownerId,
              details: note.details,
            }
          : {
              noteId: note.id,
              ownerId: ownerId,
              details: note.details,
            };
      },
      onFormSubmit: mode === 'add' ? onCreateOwnerNote : onUpdateOwnerNote,
    });
  };

  return (
    <CrudPage
      def={api => getPageDef(api.readonly)}
      mode={props.mode}
      isEditingForbidden={!canManageOwners}
      onLoadData={() =>
        Promise.all([onLoadOwner(ownerId), loadCanDeleteOwner(ownerId)]).then(() =>
          Promise.resolve()
        )
      }
      data={ownerObject}
      createDefaultData={{}}
    />
  );
});

export default MaintainOwner;
