import { IAjax } from 'src/infrastructure/ajax';
import { operationsUrls } from 'src/domain/services/apiUrls';

export default function init(ajax: IAjax) {
  function searchBoardingPoints(search: string) {
    return ajax
      .get(operationsUrls.boardingPointUrls.searchBoardingPoints(search))
      .toPromise()
      .then<
        Common.Dtos.ListResult<Operations.Domain.Queries.SearchBoardingPoint.BoardingPointListItem>
      >(r => r.response);
  }

  function createBoardingPoint(
    command: Operations.Domain.Commands.BoardingPoint.CreateBoardingPointCommand
  ) {
    return ajax
      .post(operationsUrls.boardingPointUrls.createBoardingPoint(), command)
      .toPromise()
      .then<string>(r => r.response);
  }

  function checkForUniqueName(name: string) {
    return ajax
      .get(operationsUrls.boardingPointUrls.checkForUniqueName(name))
      .toPromise()
      .then<Common.Dtos.UniqueNameCheckResultDto>(r => r.response);
  }

  function listBoardingPoints(page: number, size: number, search: string) {
    return ajax
      .get(operationsUrls.boardingPointUrls.listBoardingPoints(page, size, search))
      .map(
        r =>
          r.response as Common.Dtos.ListResult<
            Operations.Domain.Queries.ListBoardingPoints.BoardingPointItem
          >
      );
  }

  function updateBoardingPoint(
    command: Operations.Domain.Commands.BoardingPoint.UpdateBoardingPointCommand
  ) {
    return ajax
      .put(operationsUrls.boardingPointUrls.updateBoardingPoint(command.id), command)
      .toPromise();
  }

  function deleteBoardingPoint(boardingPointId: string) {
    return ajax
      .httpDelete(operationsUrls.boardingPointUrls.deleteBoardingPoint(boardingPointId))
      .toPromise();
  }

  function viewBoardingPoint(boardingPointId: string) {
    return ajax
      .get(operationsUrls.boardingPointUrls.viewBoardingPoint(boardingPointId))
      .toPromise()
      .then<Operations.Domain.Queries.GetBoardingPoint.BoardingPointItem>(r => r.response);
  }

  function findBoardingPoints(boardingPointIds: string[]) {
    return ajax
      .get(operationsUrls.boardingPointUrls.findBoardingPoints(boardingPointIds))
      .toPromise()
      .then<Operations.Domain.Queries.GetBoardingPoint.BoardingPointItem>(r => r.response);
  }

  function loadStates() {
    return ajax
      .get(operationsUrls.boardingPointUrls.loadStates())
      .toPromise()
      .then<Operations.Domain.AggregatesModel.BoardingPointAggregate.States[]>(r => r.response);
  }

  return {
    boardingPoints: {
      searchBoardingPoints,
      createBoardingPoint,
      checkForUniqueName,
      listBoardingPoints,
      updateBoardingPoint,
      deleteBoardingPoint,
      viewBoardingPoint,
      findBoardingPoints,
      loadStates,
    },
  };
}
