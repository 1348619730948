import { Component } from 'react';
import { RouteComponentProps, Link } from 'react-router-dom';
import {
  PagePrimarySize,
  PaneType,
  FieldType,
  ActionType,
  ShellModalSize,
  IModalDefBuilderApi,
  ISectionDef,
  ITabDef,
  FieldDefs,
  SectionDefs,
  ITablePaneFieldExtrasDef,
} from 'src/views/definitionBuilders/types';
import CrudPage, { CrudPageMode, ICrudPageDef } from 'src/views/components/Page/pages/CrudPage';
import { OpenBoxIcon, TrashIcon, PauseIcon, PlusIcon, ListIcon } from 'src/images/icons';
import { getSubmitCloseModalActionGroupDef } from 'src/views/definitionBuilders/common';
import { IAutocompleteResult } from 'src/domain/baseTypes';
import getCurrentUsageTabDef from './getCurrentUsageTabDef';
import { isDefined } from 'src/infrastructure/typeUtils';
import { allPartType, PartType } from 'src/api/enums';

type PartItem = Workshop.Domain.Queries.Parts.PartItem;
type PartCategoryItem = Workshop.Domain.Queries.PartCategory.PartCategoryItem;
type CreatePartCommand = Workshop.Domain.Commands.Part.CreatePart.CreatePartCommand;
type UpdatePartCommand = Workshop.Domain.Commands.Part.UpdatePart.UpdatePartCommand;
type CreateStockAdjustmentCommand = Workshop.Domain.Commands.Part.CreateStockAdjustment.CreateStockAdjustmentCommand;
type ReturnedTransaction = Workshop.Domain.Queries.Parts.ReturnedTransaction;
type SupplierListItem = Workshop.Domain.Queries.Suppliers.SupplierListItem;
type PartUsage = Workshop.Domain.Queries.Parts.GetPartUsage.PartUsage;
type UpdatePartSetActiveCommand = Workshop.Domain.Commands.Part.SetPartActive.SetPartActiveCommand;
type UpdatePartSetInactiveCommand = Workshop.Domain.Commands.Part.SetPartInactive.SetPartInactiveCommand;
type WorkshopDepot = Common.Queries.Workshop.GetWorkshopDepots.WorkshopDepotDto;
type PartLocationItem = Workshop.Domain.Queries.Parts.PartLocationItem;

export interface IMaintainPartProps {
  mode: CrudPageMode;
  canManageParts: boolean;

  onLoadPart: (id: string) => Promise<void>;
  onLoadPartUsage: (id: string) => Promise<void>;
  partUsage: PartUsage | undefined;
  part: PartItem | undefined;
  partCategories: PartCategoryItem[];

  checkForUniquePartNumber: (partNumber: string) => Promise<Common.Dtos.UniqueNameCheckResultDto>;
  onCreatePart: (command: CreatePartCommand) => Promise<void>;
  onUpdatePart: (command: UpdatePartCommand) => Promise<void>;
  setPartActive: (command: UpdatePartSetActiveCommand) => Promise<void>;
  setPartInactive: (command: UpdatePartSetInactiveCommand) => Promise<void>;
  loadPartCategories: () => Promise<void>;

  onCreateStockAdjustment: (command: CreateStockAdjustmentCommand) => Promise<void>;

  deletePart: (partId: string) => Promise<void>;

  searchSuppliers: (search: string) => Promise<IAutocompleteResult<SupplierListItem>>;
  findSuppliers: (ids: string[]) => Promise<IAutocompleteResult<SupplierListItem>>;

  workshopDepots: Array<WorkshopDepot>;
  defaultWorkshopDepot: WorkshopDepot | undefined;
}

interface IUpdatePartRouteParams {
  id: string;
}

type InternalProps = IMaintainPartProps & RouteComponentProps<IUpdatePartRouteParams>;

class MaintainPart extends Component<InternalProps> {
  private get isUpdateMode() {
    return this.props.mode === 'update';
  }

  private get isCreateMode() {
    return this.props.mode === 'create';
  }

  componentDidMount() {
    this.props.loadPartCategories();
    this.props.onLoadPartUsage(this.PartId);
  }

  private get PartId() {
    return this.props.match.params.id;
  }

  private handlePreSubmitForCreate = (part: PartItem): CreatePartCommand => {
    return {
      partNumber: part.partNumber,
      description: part.description,
      partCategoryId: part.partCategory.id,
      includeInStocktake: part.includeInStocktake,
      requiresMsds: part.requiresMsds,
      preferredSupplierId: part.preferredSupplierId,
      msdsExpiryDate: part.requiresMsds ? part.msdsExpiryDate : undefined,
      minStockLevel: part.minStockOnHand,
      maxStockLevel: part.maxStockOnHand,
      partTypeId: part.partType.id,
      locations: part.locations.map(pl => ({
        depotId: pl.depotId,
        location: part.partType.id === PartType.Part ? pl.location : '',
      })),
    };
  };

  private handlePreSubmitForUpdate = (part: PartItem): UpdatePartCommand => {
    return {
      id: part.id,
      partNumber: part.partNumber,
      description: part.description,
      partCategoryId: part.partCategory.id,
      includeInStocktake: part.includeInStocktake,
      requiresMsds: part.requiresMsds,
      preferredSupplierId: part.preferredSupplierId,
      msdsExpiryDate: part.requiresMsds ? part.msdsExpiryDate : undefined,
      minStockLevel: part.minStockOnHand,
      maxStockLevel: part.maxStockOnHand,
      partTypeId: part.partType.id,
      locations: part.locations.map(pl => ({
        depotId: pl.depotId,
        location: pl.location,
      })),
    };
  };

  private isService = (
    partType: Common.AggregatesModel.Workshop.PartAggregate.PartType | undefined
  ): boolean => (partType && partType.id === PartType.Service ? true : false);

  private get partCategories(): Array<PartCategoryItem> {
    if (this.isCreateMode || this.isUpdateMode) {
      return this.props.partCategories.filter(a => a.isActive);
    }
    return this.props.partCategories;
  }

  private readonly getPageDef = (readonly: boolean): ICrudPageDef => {
    const {
      checkForUniquePartNumber,
      part: loadedPart,
      canManageParts,
      searchSuppliers,
      findSuppliers,
    } = this.props;

    const showMakeInactiveMenuOption =
      this.props.part && !this.props.part.inactive && this.props.partUsage;
    const showDeleteMenuOption =
      this.props.part && this.props.partUsage && this.props.partUsage.canBeDeleted;

    const title = this.isUpdateMode ? 'Manage Part' : 'Create a Part';
    return {
      primarySize: PagePrimarySize.half,
      primarySection: {
        title: d => title,
        badge: this.props.part && this.props.part.inactive ? { label: 'Inactive' } : undefined,
        primaryActions: [
          {
            actions: [
              {
                actionType: ActionType.actionCollection,
                hidden: !readonly || !canManageParts,
                actionGroups: [
                  {
                    actions: [
                      {
                        actionType: ActionType.modalActionButton,
                        label: 'Add Stock Adjustment',
                        hidden: this.isService(this.props.part?.partType),
                        icon: <OpenBoxIcon fixedWidth />,
                        modalSize: ShellModalSize.oneThird,
                        modalDef: this.getStockAdjustmentModalDef(),
                      },
                      {
                        actionType: ActionType.modalActionButton,
                        label: 'Delete Part',
                        icon: <TrashIcon />,
                        hidden: !showDeleteMenuOption,
                        modalSize: ShellModalSize.oneQuarter,
                        modalDef: modalDefApi => ({
                          title: 'Delete Part',
                          asForm: true,
                          panels: [
                            {
                              panes: [
                                {
                                  paneType: PaneType.customPane,
                                  render: () => (
                                    <div>
                                      <p>Are you sure you want to delete this part?</p>
                                    </div>
                                  ),
                                },
                              ],
                            },
                          ],
                          secondaryActions: [getSubmitCloseModalActionGroupDef('Delete')],
                          onFormSubmit: () => this.props.deletePart(this.PartId),
                        }),
                      },
                      {
                        actionType: ActionType.modalActionButton,
                        label: 'Mark as Inactive',
                        icon: <PauseIcon />,
                        hidden: !showMakeInactiveMenuOption,
                        modalSize: ShellModalSize.oneQuarter,
                        modalDef: modalDefApi => ({
                          title: 'Mark as Inactive',
                          asForm: true,
                          panels: [
                            {
                              title: '',
                              panes: [
                                {
                                  paneType: PaneType.customPane,
                                  render: () =>
                                    !this.props.partUsage ||
                                    this.props.partUsage.canBeMadeInactive ? (
                                      <div>
                                        <p>Are you sure you want to mark this part as inactive?</p>
                                      </div>
                                    ) : (
                                      <div>
                                        <p>
                                          This part cannot be made <i>inactive</i> as it is
                                          currently being used in the application.
                                        </p>
                                        <p>Please check the 'Current Usage' tab for details.</p>
                                        <p>
                                          A part can only be made inactive when it is no longer in
                                          active use.
                                        </p>
                                      </div>
                                    ),
                                },
                              ],
                            },
                          ],
                          secondaryActions:
                            this.props.partUsage && this.props.partUsage.canBeMadeInactive
                              ? [getSubmitCloseModalActionGroupDef('Make inactive')]
                              : [
                                  {
                                    actions: [
                                      {
                                        actionType: ActionType.closeModalActionButton,
                                        label: 'Cancel',
                                      },
                                    ],
                                  },
                                ],
                          onFormSubmit: () =>
                            this.props.setPartInactive({
                              id: this.PartId,
                            }),
                        }),
                      },
                      {
                        actionType: ActionType.modalActionButton,
                        label: 'Mark as Active',
                        icon: <PlusIcon />,
                        hidden: !this.props.part || !this.props.part.inactive,
                        modalSize: ShellModalSize.oneQuarter,
                        modalDef: modalDefApi => ({
                          title: 'Mark as Active',
                          asForm: true,
                          panels: [
                            {
                              title: '',
                              panes: [
                                {
                                  paneType: PaneType.customPane,
                                  render: () => (
                                    <div>
                                      <p>
                                        Once active, the part will be searchable and will be
                                        available for use throughout the application.
                                      </p>
                                      <p>Are you sure you want to mark this part as active?</p>
                                    </div>
                                  ),
                                },
                              ],
                            },
                          ],
                          secondaryActions: [getSubmitCloseModalActionGroupDef('Make active')],
                          onFormSubmit: () =>
                            this.props.setPartActive({
                              id: this.PartId,
                            }),
                        }),
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
        panels: [
          {
            panes: [
              {
                paneType: PaneType.formFieldsPane,
                columnCount: 2,
                fields: [
                  {
                    fieldType: FieldType.selectField,
                    label: 'Part Type',
                    dataAddr: ['partType', 'id'],
                    valueKey: 'value',
                    useValueOnly: true,
                    descriptionKey: 'description',
                    mandatory: true,
                    optionItems: allPartType,
                    readonly: readonly || this.isUpdateMode,
                  },
                  {
                    fieldType: FieldType.textField,
                    label: 'Part Number',
                    dataAddr: 'partNumber',
                    maxLength: 50,
                    mandatory: true,
                    validateAsync: async d => {
                      if (
                        !d.fieldValue ||
                        (this.isUpdateMode &&
                          loadedPart &&
                          loadedPart.partNumber.toUpperCase() === d.fieldValue.toUpperCase())
                      ) {
                        return undefined;
                      }
                      const result = await checkForUniquePartNumber(d.fieldValue);
                      return result.nameExists ? `Part number is already in use` : undefined;
                    },
                  },
                ],
              },
              {
                paneType: PaneType.formFieldsPane,
                fields: [
                  {
                    fieldType: FieldType.textField,
                    label: 'Description',
                    dataAddr: 'description',
                    maxLength: 200,
                    mandatory: true,
                  },
                ],
              },
              {
                paneType: PaneType.formFieldsPane,
                columnCount: 2,
                fields: [
                  {
                    fieldType: FieldType.selectField,
                    label: 'Part Category',
                    dataAddr: 'partCategory',
                    valueKey: 'id',
                    descriptionKey: 'description',
                    mandatory: true,
                    optionItems: this.partCategories,
                    formatReadonly: d => {
                      if (!d.fieldValue) {
                        return undefined;
                      }

                      const data = d.fieldValue as PartCategoryItem;
                      return (
                        <Link to={`/workshop/part-categories/${data.id}`}>{data.description}</Link>
                      );
                    },
                  },
                  {
                    fieldType: FieldType.yesNoField,
                    label: 'Include In Stocktake',
                    dataAddr: 'includeInStocktake',
                    mandatory: true,
                    hidden: d => {
                      const partValue = d.panelValue as PartItem;
                      return this.isService(partValue.partType);
                    },
                  },
                ],
              },
              {
                paneType: PaneType.formFieldsPane,
                columnCount: 2,
                hidden: d => {
                  const partValue = d.panelValue as PartItem;
                  return this.isService(partValue.partType);
                },
                fields: [
                  {
                    fieldType: FieldType.yesNoField,
                    label: 'Requires MSDS',
                    dataAddr: 'requiresMsds',
                    mandatory: true,
                  },
                  {
                    fieldType: FieldType.dateField,
                    label: 'MSDS Expiry Date',
                    dataAddr: 'msdsExpiryDate',
                    hidden: d => d.parentValue && !d.parentValue.requiresMsds,
                  },
                ],
              },
              {
                paneType: PaneType.formFieldsPane,
                columnCount: 2,
                fields: [
                  {
                    fieldType: FieldType.selectAsyncField,
                    label: 'Preferred Supplier',
                    dataAddr: 'preferredSupplierId',
                    useValueOnly: true,
                    valueKey: 'id',
                    descriptionKey: 'name',
                    loadOptionItems: searchSuppliers,
                    loadItems: (ids, d) => findSuppliers(ids),
                    autoload: true,
                  },
                ],
              },
              {
                paneType: PaneType.formFieldsPane,
                columnCount: 2,
                fields: [
                  {
                    fieldType: FieldType.numericField,
                    numericConfig: {
                      numericType: 'signedDecimal',
                      maxPointDigits: 4,
                    },
                    label: 'Min Stock On Hand',
                    dataAddr: 'minStockOnHand',
                    hidden: d => {
                      const partValue = d.panelValue as PartItem;
                      return this.isService(partValue.partType);
                    },
                    onBlur: api => api.validateField(['maxStockOnHand']),
                  },
                  {
                    fieldType: FieldType.numericField,
                    numericConfig: {
                      numericType: 'signedDecimal',
                      maxPointDigits: 4,
                    },
                    label: 'Max Stock On Hand',
                    dataAddr: 'maxStockOnHand',
                    hidden: d => {
                      const partValue = d.panelValue as PartItem;
                      return this.isService(partValue.partType);
                    },
                    validate: d => {
                      // Type system complains a lot
                      const field = !isDefined(d.fieldValue) ? '' : d.fieldValue.toString();
                      const min = parseInt(d.parentValue.minStockOnHand, 10);
                      const max = parseInt(field, 10);

                      if (isNaN(min) || isNaN(max)) {
                        return undefined;
                      }
                      return min > max ? 'Max stock cannot be less than min stock' : undefined;
                    },
                  },
                ],
              },
            ],
          },
          {
            title: 'Locations',
            dataAddr: 'locations',
            panes: [
              {
                paneType: PaneType.tablePane,
                fields: [
                  {
                    fieldType: FieldType.readonlyField,
                    dataAddr: 'depotName',
                    label: 'Store',
                  },
                  this.isService(this.props.part?.partType)
                    ? null
                    : {
                        fieldType: FieldType.textField,
                        label: 'Location',
                        dataAddr: 'location',
                        maxLength: 50,
                      },
                  this.isService(this.props.part?.partType)
                    ? null
                    : {
                        fieldType: FieldType.numericField,
                        numericConfig: {
                          numericType: 'signedDecimal',
                          maxPointDigits: 4,
                        },
                        readonly: true,
                        label: 'Stock On Hand',
                        dataAddr: 'stockOnHand',
                      },
                  {
                    fieldType: FieldType.numericField,
                    readonly: true,
                    label: 'Latest Price',
                    dataAddr: 'latestPrice',
                    formatReadonly: 'unitPrice',
                  },
                ]
                  .filter(x => !!x)
                  .map(x => x as FieldDefs & ITablePaneFieldExtrasDef),
              },
            ],
          },
        ],
        onFormPreSubmit: this.isUpdateMode
          ? this.handlePreSubmitForUpdate
          : this.handlePreSubmitForCreate,
        onFormSubmit: this.isUpdateMode ? this.props.onUpdatePart : this.props.onCreatePart,
      },
      secondarySections: !this.isUpdateMode
        ? []
        : ([
            {
              title: 'Transactions',
              panels: [
                {
                  panes: [
                    {
                      paneType: PaneType.tabPane,
                      tabs: [
                        {
                          title: 'Purchases',
                          panes: [
                            {
                              paneType: PaneType.tablePane,
                              neverEditable: true,
                              noRowsMessage: 'No current transactions',
                              dataAddr: 'purchasedTransactions',
                              fields: [
                                {
                                  fieldType: FieldType.dateField,
                                  columnWidth: '7em',
                                  label: 'Date',
                                  dataAddr: 'occurredDate',
                                  linkTo: d =>
                                    `/workshop/purchase-orders/${d.parentValue.purchaseOrderId}/goods-received/${d.parentValue.goodsReceivedId}`,
                                },
                                {
                                  fieldType: FieldType.textField,
                                  label: 'Supplier',
                                  dataAddr: 'supplier',
                                },
                                {
                                  fieldType: FieldType.textField,
                                  label: 'Invoice',
                                  dataAddr: 'invoiceNumber',
                                },
                                {
                                  fieldType: FieldType.numericField,
                                  label: 'Price',
                                  dataAddr: 'price',
                                  formatReadonly: 'unitPrice',
                                },
                                {
                                  fieldType: FieldType.numericField,
                                  label: 'Quantity',
                                  dataAddr: 'quantity',
                                },
                              ] as FieldDefs[],
                            },
                          ],
                        },
                        {
                          title: 'Usages',
                          panes: [
                            {
                              paneType: PaneType.tablePane,
                              neverEditable: true,
                              noRowsMessage: 'No current transactions',
                              dataAddr: 'usedTransactions',
                              fields: [
                                {
                                  fieldType: FieldType.dateField,
                                  columnWidth: '7em',
                                  label: 'Date',
                                  dataAddr: 'occurredDate',
                                },
                                {
                                  fieldType: FieldType.textField,
                                  label: 'Asset',
                                  dataAddr: 'assetName',
                                  linkTo: d => `/workshop/assets/${d.parentValue.assetId}`,
                                },
                                {
                                  fieldType: FieldType.textField,
                                  label: 'Job Task',
                                  dataAddr: 'jobTaskNumber',
                                  linkTo: d => `/workshop/tasks/${d.parentValue.jobTaskId}`,
                                },
                                {
                                  fieldType: FieldType.numericField,
                                  label: 'Quantity',
                                  dataAddr: 'quantity',
                                },
                              ] as FieldDefs[],
                            },
                          ],
                        },
                        {
                          title: 'Returned',
                          panes: [
                            {
                              paneType: PaneType.tablePane,
                              neverEditable: true,
                              noRowsMessage: 'No current transactions',
                              dataAddr: 'returnedTransactions',
                              fields: [
                                {
                                  fieldType: FieldType.dateField,
                                  columnWidth: '7em',
                                  label: 'Date',
                                  dataAddr: 'occurredDate',
                                },
                                {
                                  fieldType: FieldType.textField,
                                  label: 'Asset',
                                  dataAddr: 'assetName',
                                  linkTo: d => `/workshop/assets/${d.parentValue.assetId}`,
                                },
                                {
                                  fieldType: FieldType.textField,
                                  label: 'Job Task',
                                  dataAddr: 'jobTaskNumber',
                                  linkTo: d => `/workshop/tasks/${d.parentValue.jobTaskId}`,
                                },
                                {
                                  fieldType: FieldType.textField,
                                  label: 'Credit Note',
                                  dataAddr: 'creditNoteNumber',
                                  linkTo: d => {
                                    const outgoing = d.parentValue as ReturnedTransaction;
                                    return !outgoing.creditNoteId
                                      ? null
                                      : `/workshop/purchase-orders/${outgoing.purchaseOrderId}/goods-received/${outgoing.goodsReceivedId}/returned-part-credits/${outgoing.creditNoteId}`;
                                  },
                                },
                                {
                                  fieldType: FieldType.numericField,
                                  label: 'Quantity',
                                  dataAddr: 'quantity',
                                },
                              ] as FieldDefs[],
                            },
                          ],
                        },
                      ].filter(d => d !== undefined) as ITabDef[],
                    },
                    {
                      paneType: PaneType.actionListPane,
                      actionGroups: [
                        {
                          actions: [
                            {
                              actionType: ActionType.actionLink,
                              label: 'View All Transactions',
                              icon: <ListIcon />,
                              to: `/workshop/parts/transactions?partIds=${this.PartId}`,
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
            this.props.part && !this.props.part.inactive
              ? getCurrentUsageTabDef(this.props.partUsage, this.props.part)
              : undefined,
          ].filter(d => d !== undefined) as SectionDefs[]),
    };
  };

  private readonly getStockAdjustmentModalDef = (): ((
    modalDefApi: IModalDefBuilderApi
  ) => ISectionDef) => {
    const { workshopDepots, defaultWorkshopDepot } = this.props;
    return modalDefApi => ({
      title: 'Add Stock Adjustment',
      asForm: true,
      explicitData: { depotId: defaultWorkshopDepot?.id },
      panels: [
        {
          panes: [
            {
              paneType: PaneType.formFieldsPane,
              fields: [
                {
                  fieldType: FieldType.selectField,
                  dataAddr: 'depotId',
                  label: 'Depot',
                  useValueOnly: true,
                  valueKey: 'id',
                  descriptionKey: 'description',
                  mandatory: true,
                  optionItems: workshopDepots,
                  readonly: workshopDepots.length < 2,
                },
                {
                  fieldType: FieldType.textField,
                  mandatory: true,
                  maxLength: 200,
                  label: 'Reason',
                  dataAddr: 'reason',
                },
                {
                  fieldType: FieldType.numericField,
                  numericConfig: {
                    numericType: 'signedDecimal',
                    maxPointDigits: 4,
                  },
                  label: 'Quantity',
                  dataAddr: 'quantity',
                },
                {
                  fieldType: FieldType.numericField,
                  label: 'Price',
                  dataAddr: 'price',
                  numericConfig: {
                    numericType: 'unsignedDecimal',
                    maxPointDigits: 5,
                  },
                  validate: item => {
                    if (item.fieldValue) {
                      return item.fieldValue > 0 ? undefined : 'Price must be greater than 0';
                    } else {
                      return item.panelValue.quantity ? undefined : 'Quantity or Price must be set';
                    }
                  },
                },
              ],
            },
          ],
        },
      ],
      secondaryActions: [getSubmitCloseModalActionGroupDef('Ok')],
      onFormPreSubmit: (cmd): CreateStockAdjustmentCommand => {
        return {
          ...cmd,
          quantity: cmd.quantity ? cmd.quantity : 0,
          partId: this.PartId,
          depotId: cmd.depotId,
        };
      },
      onFormSubmit: this.props.onCreateStockAdjustment,
    });
  };

  private mergePartLocationsWithDepot = (workshopDepots: WorkshopDepot[]): PartLocationItem[] => {
    let defaultLocations: PartLocationItem[] = workshopDepots.map(wd => ({
      depotId: wd.id,
      depotName: wd.description,
      location: '',
    }));

    return defaultLocations;
  };

  render() {
    const { mode, onLoadPart, part, canManageParts, workshopDepots } = this.props;
    const defaultData =
      mode === 'create' ? { locations: this.mergePartLocationsWithDepot(workshopDepots) } : {};

    return workshopDepots.length ? (
      <CrudPage
        def={api => this.getPageDef(api.readonly)}
        mode={mode}
        isEditingForbidden={!canManageParts}
        onLoadData={() => onLoadPart(this.PartId)}
        data={part}
        createDefaultData={defaultData}
      />
    ) : null;
  }
}

export default MaintainPart;
