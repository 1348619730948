import { types, getRoot, flow } from 'mobx-state-tree';
import { IRootStoreModel } from 'src/domain/entities/RootStoreModel';
import { getAjax } from 'src/domain/services';

type CreateShiftCommand = Workshop.Domain.Commands.Shift.CreateShift.CreateShiftCommand;
type UpdateShiftCommand = Workshop.Domain.Commands.Shift.UpdateShift.UpdateShiftCommand;
type ShiftItem = Workshop.Domain.Queries.Shift.GetShift.ShiftItem;

export const ShiftModel = types
  .model('ShiftModel', {
    shift: types.maybe(types.frozen<ShiftItem>()),
  })
  .actions(self => {
    const ajax = getAjax(self);
    const root = getRoot(self) as IRootStoreModel;

    const createShift = flow(function*(command: CreateShiftCommand) {
      const id = yield ajax.workshop.shift.createShift(command);
      self.shift = undefined;
      root.history.push(`/workshop/shifts/${id}`);
    });

    const loadShift = flow(function*(shiftId: string) {
      self.shift = yield ajax.workshop.shift.getShift(shiftId);
    });

    const updateShift = flow(function*(command: UpdateShiftCommand) {
      yield ajax.workshop.shift.updateShift(command);
      yield loadShift(command.id);
    });

    const deleteShift = flow(function*(shiftId: string) {
      yield ajax.workshop.shift.deleteShift(shiftId);
      self.shift = undefined;
      root.history.replace('/workshop/shifts');
    });

    return {
      createShift,
      loadShift,
      updateShift,
      deleteShift,
    };
  });
