import BusImage from 'src/images/Paint_Dent_1500_12.3-1.png';
import styles from './VehicleScratchesTable.module.scss';

export const VehicleScratchesTable: React.FC = () => (
  <table className={styles.root}>
    <tbody>
      <tr>
        <td className={styles.centredHeading}>Please Mark Scratches And Dents</td>
      </tr>
      <tr>
        <td className={styles.busImage}>
          <img src={BusImage} alt="Bus" />
        </td>
      </tr>
    </tbody>
  </table>
);
