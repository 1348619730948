import { UiRouteName } from 'src/domain/baseTypes';
import { IRootStoreModel } from 'src/domain/entities/RootStoreModel';
import UiRoute from 'src/domain/services/buildUiRoutes/UiRoute';

export default function buildUiRoutes(store: IRootStoreModel) {
  const jobCreate = new UiRoute(
    UiRouteName.workshopJobCreate,
    '/workshop/jobs/create',
    () => 'Create Job',
    false
  );

  const jobSchedule = new UiRoute(
    UiRouteName.workshopJobSchedule,
    '/workshop/jobs/schedule',
    () => 'Daily Schedule',
    true
  );

  const weeklyJobSchedule = new UiRoute(
    UiRouteName.workshopWeeklyJobSchedule,
    '/workshop/jobs/weekly-schedule',
    () => 'Weekly Job Schedule',
    false
  );

  const machineryJobSchedule = new UiRoute(
    UiRouteName.workshopMachineryJobSchedule,
    '/workshop/jobs/machinery-schedule',
    () => 'HV Machinery Schedule',
    true
  );

  const jobEdit = new UiRoute(
    UiRouteName.workshopJobEdit,
    '/workshop/jobs/:id',
    () => 'Manage Job',
    false
  );

  const jobsList = new UiRoute(
    UiRouteName.workshopJobsList,
    '/workshop/jobs',
    () => 'Jobs',
    true,
    false,
    [jobCreate, jobEdit]
  );

  return {
    [UiRouteName.workshopJobCreate]: jobCreate,
    [UiRouteName.workshopJobSchedule]: jobSchedule,
    [UiRouteName.workshopWeeklyJobSchedule]: weeklyJobSchedule,
    [UiRouteName.workshopMachineryJobSchedule]: machineryJobSchedule,
    [UiRouteName.workshopJobsList]: jobsList,
    [UiRouteName.workshopJobEdit]: jobEdit,
  };
}
