import { PaneType, ModalDefBuilder, FieldType } from 'src/views/definitionBuilders/types';
import { JobTaskCategory } from 'src/api/enums';
import { DateTime } from 'luxon';
import { WarnIcon } from 'src/images/icons';
import { getSubmitCloseModalActionGroupDef } from 'src/views/definitionBuilders/common';

type CancelJobTaskCommand = Workshop.Domain.Commands.JobTask.CancelJobTaskCommand;
type JobDetails = Workshop.Domain.Queries.GetJobTask.JobDetails;

export default function getCancelJobTaskModalDef(
  onCancel: (command: CancelJobTaskCommand) => Promise<void>,
  jobTaskId: string,
  jobTask: Pick<Workshop.Domain.Queries.GetJobTask.JobTaskDetails, 'category'> | undefined,
  jobDetails: Pick<JobDetails, 'startDateTime'> | undefined
): ModalDefBuilder {
  const startDateTime = jobDetails && DateTime.fromISO(jobDetails.startDateTime);
  const showWarning =
    jobTask &&
    jobTask.category.id === JobTaskCategory.MachineryInspection &&
    startDateTime &&
    startDateTime < DateTime.local().plus({ hours: 48 });
  return modalDefApi => ({
    title: 'Cancel Task',
    asForm: true,
    explicitData: {},
    panels: [
      {
        panes: [
          {
            paneType: PaneType.customPane,
            render: api => (
              <p className="cancel-job-task-warning">
                <WarnIcon fixedWidth size="lg" />
                Warning - this machinery inspection is due within 48 hours
              </p>
            ),
            hidden: !showWarning,
          },
          {
            paneType: PaneType.customPane,
            render: api => <p>Please provide the reason that you want to cancel this task</p>,
          },
          {
            paneType: PaneType.formFieldsPane,
            fields: [
              {
                fieldType: FieldType.textField,
                dataAddr: 'cancelReason',
                label: 'Reason',
                mandatory: true,
                maxLength: 200,
              },
            ],
          },
        ],
      },
    ],
    secondaryActions: [getSubmitCloseModalActionGroupDef('Ok')],
    onFormSubmit: (values: { cancelReason: string }) => {
      const args = {
        jobTaskId: jobTaskId,
        reason: values.cancelReason,
      };
      return onCancel(args);
    },
  });
}
