import { types, flow } from 'mobx-state-tree';
import { getAjax } from 'src/domain/services';
import { IViewGroupFilterValues } from 'src/views/routes/operations/job/allocations/ganttCalendar/GanttCalendar';

type VehicleTypeAllocationsByDayDto = Operations.Domain.Queries.GetVehicleTypeAllocationData.VehicleTypeAllocationsByDayDto;

export const AllocationsModel = types
  .model('AllocationsModel', {
    vehicleTypeAllocations: types.array(types.frozen<VehicleTypeAllocationsByDayDto>()),
    vehicleTypeAllocationDataForQuoteBooking: types.array(
      types.frozen<VehicleTypeAllocationsByDayDto>()
    ),
    viewFilters: types.maybe(types.frozen<any>()),
  })
  .actions(self => {
    const ajax = getAjax(self);

    const getVehicleTypeAllocationData = flow(function*(
      fromDate: string,
      toDate: string,
      vehicleTypeIds: string[],
      quoteIdToIgnore: string | undefined,
      depotId: number
    ) {
      self.vehicleTypeAllocations = yield loadAllocationData(
        fromDate,
        toDate,
        vehicleTypeIds,
        quoteIdToIgnore,
        depotId
      );
    });

    const getAllocationDataForQuoteBooking = flow(function*(
      fromDate: string,
      toDate: string,
      vehicleTypeIds: string[],
      depotId: number
    ) {
      self.vehicleTypeAllocationDataForQuoteBooking = yield loadAllocationData(
        fromDate,
        toDate,
        vehicleTypeIds,
        undefined,
        depotId
      );
    });

    const loadAllocationData = (
      fromDate: string,
      toDate: string,
      vehicleTypeIds: string[],
      quoteIdToIgnore: string | undefined,
      depotId: number
    ) =>
      ajax.operations.allocations.getVehicleTypeAllocationData(
        fromDate,
        toDate,
        vehicleTypeIds,
        quoteIdToIgnore,
        depotId
      );

    const setViewFilter = (filterChange: { [key: string]: IViewGroupFilterValues }) => {
      self.viewFilters = filterChange;
    };

    return {
      getVehicleTypeAllocationData,
      getAllocationDataForQuoteBooking,
      setViewFilter,
    };
  });
