import * as History from 'history';
import { DateTime } from 'luxon';
import { JobType } from 'src/api/enums';
import { isDevelopment } from 'src/appSettings';
import {
  CalculatorIcon,
  ClipboardCheckIcon,
  CodeIcon,
  DollarSignIcon,
  StreamIcon,
  UsersIcon,
  WrenchIcon,
} from 'src/images/icons';
import {
  RailJobTypesThatHaveRailBooking,
  JobTypesThatMayHaveRailBooking,
} from 'src/views/routes/operations/shared/jobTypeHelpers';

type WorkshopDepot = Common.Queries.Workshop.GetWorkshopDepots.WorkshopDepotDto;

export interface IShellNavItem {
  label: string;
  to?: History.LocationDescriptor;
  newWindow?: boolean;
  navItems?: Array<IShellNavItem>;
  icon?: React.ReactNode;
}

export const getShellNavItems = (
  canViewTimesheets: boolean,
  canViewReports: boolean,
  canViewSms: boolean,
  defaultWorkshopDepot?: WorkshopDepot
): Array<IShellNavItem> =>
  [
    {
      label: 'Workshop',
      to: '/workshop?defaultFilter=true',
      icon: <WrenchIcon />,
      navItems: [
        {
          label: 'Assets',
          to: '/workshop/assets?defaultFilter=true',
        },
        {
          label: 'Defects',
          to: '/workshop/defects?defaultFilter=true',
        },
        {
          label: 'Tasks',
          to: '/workshop/tasks?defaultFilter=true',
        },
        {
          label: 'Jobs',
          to: `/workshop/jobs?defaultFilter=true`,
          navItems: [
            {
              label: 'Daily Schedule',
              to: `/workshop/jobs/schedule?${
                defaultWorkshopDepot ? 'depotId=' + defaultWorkshopDepot.id : ''
              }&day=${DateTime.local()
                .startOf('day')
                .toFormat('yyyy-MM-dd')}`,
            },
            {
              label: 'Weekly Schedule',
              to: `/workshop/jobs/weekly-schedule?week=${DateTime.local()
                .startOf('day')
                .set({ weekday: 1 })
                .toISOWeekDate()}${
                defaultWorkshopDepot ? '&depotId=' + defaultWorkshopDepot.id : ''
              }`,
            },
            {
              label: 'HV Machinery Schedule',
              to: `/workshop/jobs/machinery-schedule?month=${DateTime.local()
                .startOf('day')
                .toFormat('yyyy-MM')}${
                defaultWorkshopDepot ? '&depotId=' + defaultWorkshopDepot.id : ''
              }`,
            },
          ],
        },
        {
          label: 'Parts',
          to: '/workshop/parts?defaultFilter=true',
        },
        {
          label: 'Suppliers',
          to: '/workshop/suppliers',
        },
        {
          label: 'Purchase Orders',
          to: '/workshop/purchase-orders?defaultFilter=true',
          navItems: [
            {
              label: 'Purchase Transactions',
              to: '/workshop/purchase-transactions?defaultFilter=true',
            },
          ],
        },
        {
          label: 'Rosters',
          to: '/workshop/rosters?defaultFilter=true',
        },
        {
          label: 'Shifts',
          to: '/workshop/shifts?defaultFilter=true',
        },
      ],
    },
    {
      label: 'Sales',
      to: `/sales`,
      icon: <DollarSignIcon />,
      navItems: [
        { label: 'Quotes', to: '/sales/quotes?defaultFilter=true' },
        { label: 'Customers', to: '/sales/customers' },
        { label: 'Boarding Points', to: '/sales/boarding-points' },
      ],
    },
    {
      label: 'Operations',
      to: '/operations',
      icon: <StreamIcon />,
      navItems: [
        {
          label: 'Daily Event Log',
          to: '/operations/daily-event-log?defaultFilter=true',
        },
        {
          label: 'Jobs',
          to: '/operations/jobs?defaultFilter=true',
          navItems: [
            { label: 'Allocations', to: '/operations/jobs/allocations' },
            {
              label: 'Urban Diary',
              to: `/operations/jobs?dateFrom=${DateTime.local().toISODate()}&dateTo=${DateTime.local().toISODate()}&jobTypeIds=${
                JobType.Urban
              }&jobTypeIds=${JobType.AsRequired}`,
            },
            {
              label: 'School Service Diary',
              to: `/operations/jobs?dateFrom=${DateTime.local().toISODate()}&dateTo=${DateTime.local().toISODate()}&jobTypeIds=${
                JobType.SchoolService
              }`,
            },
            {
              label: 'Charter Diary',
              to: `/operations/charter-diary?defaultFilter=true`,
            },
          ],
        },
        { label: 'Tasks', to: '/operations/tasks' },
        { label: 'Shifts', to: '/operations/shifts' },
        { label: 'Rosters', to: '/operations/rosters' },
        {
          label: 'Rail Diary',
          to: `/operations/jobs?dateFrom=${DateTime.local().toISODate()}&dateTo=${DateTime.local().toISODate()}${RailJobTypesThatHaveRailBooking.concat(
            JobTypesThatMayHaveRailBooking
          )
            .map(x => `&jobTypeIds=${x}`)
            .join('')}`,
          navItems: [
            {
              label: 'Rail Templates',
              to: '/operations/rail/rail-templates?defaultFilter=true',
            },
            {
              label: 'Rail Bookings',
              to: '/operations/rail/rail-bookings?defaultFilter=true',
            },
          ],
        },
      ],
    },
    {
      label: 'People',
      to: '/people',
      icon: <UsersIcon />,
      navItems: [
        { label: 'Staff Members', to: '/people/staff-members?defaultFilter=true' },
        {
          label: 'Notes',
          to: `/people/notes?defaultFilter=true`,
        },
        ...(canViewSms ? [{ label: 'SMS', to: `/people/sms?defaultFilter=true` }] : []),
        {
          label: 'Leave',
          to: `/people/leaves?defaultFilter=true`,
          navItems: [
            {
              label: 'Leave Calendar',
              to: `/people/leaves/calendar?week=${DateTime.local()
                .startOf('day')
                .set({ weekday: 1 })
                .toISOWeekDate()}`,
            },
          ],
        },
        ...(canViewTimesheets
          ? [{ label: 'Timesheets', to: `/people/timesheets?defaultFilter=true` }]
          : []),
      ],
    },
    {
      label: 'Accounting',
      to: `/accounting`,
      icon: <CalculatorIcon />,
      navItems: canViewReports
        ? [
            {
              label: 'Bookings',
              to: '/accounting/bookings?defaultFilter=true',
            },
            {
              label: 'Contract Bookings',
              to: '/accounting/contract-charter-bookings?defaultFilter=true',
            },
            {
              label: 'Rail Bookings',
              to: '/accounting/rail-bookings?defaultFilter=true',
            },
            { label: 'Reporting', to: '/accounting/reporting' },
          ]
        : [],
    },
    {
      label: 'Compliance',
      to: `/compliance`,
      icon: <ClipboardCheckIcon />,
      navItems: [
        {
          label: 'Reportable Events',
          to: '/compliance/reportable-events?defaultFilter=true',
        },
        {
          label: 'Code of Conducts',
          to: '/compliance/code-of-conducts?defaultFilter=true',
        },
        {
          label: 'Lost Property Items',
          to: `/compliance/lost-property-items?defaultFilter=true`,
        },
        {
          label: 'Customer Complaints',
          to: `/compliance/customer-complaints?defaultFilter=true`,
        },
        {
          label: 'CCTV Requests',
          to: `/compliance/cctv-requests?defaultFilter=true`,
        },
        {
          label: 'Fatigue',
          to: `/compliance/fatigueDetails?day=${DateTime.local().toISODate()}`,
          navItems: [
            {
              label: 'Breach Events',
              to: `/compliance/fatigue-breach-events?defaultFilter=true`,
            },
          ],
        },
      ],
    },
  ].concat(
    isDevelopment()
      ? [
          {
            label: 'Documentation',
            to: `/documentation`,
            icon: <CodeIcon />,
            navItems: [
              {
                label: 'Page Layout',
                to: `/documentation/page-layout`,
              },
              {
                label: 'List Page',
                to: `/documentation/list-page`,
              },
              {
                label: 'Crud Page',
                to: `/documentation/crud-page`,
              },
              {
                label: 'Actions',
                to: `/documentation/actions`,
              },
              {
                label: 'Forms',
                to: `/documentation/forms`,
              },
              {
                label: 'Modals',
                to: `/documentation/modals`,
              },
            ],
          },
        ]
      : []
  );

export const shellKioskNavItems: Array<IShellNavItem> = [
  {
    label: 'Home',
    to: '/kiosk',
  },
];
