import { types, flow } from 'mobx-state-tree';
import { getAjax } from 'src/domain/services';

type TechSpecDropdownOptionsItem = Workshop.Domain.Queries.TechSpecs.TechSpecDropdownOptionsItem;

export const TechSpecsModel = types
  .model('TechSpecsModel', {
    techSpecsDropdownOptions: types.array(types.frozen<TechSpecDropdownOptionsItem>()),
  })
  .actions(self => {
    const ajax = getAjax(self);

    const loadTechSpecsDropdownOptions = flow(function*() {
      self.techSpecsDropdownOptions = yield ajax.raw
        .get(`/api/kiosk/workshop/tech-specs/dropdown-options`)
        .toPromise()
        .then<TechSpecDropdownOptionsItem[]>(r => r.response);
    });

    return {
      loadTechSpecsDropdownOptions,
    };
  });
