import { types, flow } from 'mobx-state-tree';
import { getAjax } from 'src/domain/services/storeEnvironment';
import { QuoteItemModel } from './QuoteItemModel';
import { ListBookingsModel } from './BookingsListModel';
import { QuotePdfsModel } from './QuotePdfsModel';
import { AccountingModel } from './AccountingModel';
import { QuotesForCustomerModel } from './QuotesForCustomerModel';
import { AllQuotesListModel } from './AllQuotesListModel';
import { BookingForOpsItemModel } from './BookingForOpsItemModel';

type QuoteTypeDto = Common.Dtos.QuoteTypeDto;
type QuoteStatus = Operations.Domain.AggregatesModel.QuoteAggregate.QuoteStatus;
type TradingCompanyDto = Common.Dtos.TradingCompanyDto;

export const QuotesModel = types
  .model('QuotesModel', {
    allQuotesList: types.optional(AllQuotesListModel, {}),
    quotesList: types.optional(AllQuotesListModel, {}),
    bookingsList: types.optional(ListBookingsModel, {}),
    quoteItem: types.optional(QuoteItemModel, {}),
    bookingForOpsItem: types.optional(BookingForOpsItemModel, {}),
    pdfs: types.optional(QuotePdfsModel, {}),
    accounting: types.optional(AccountingModel, {}),
    quotesForCustomer: types.optional(QuotesForCustomerModel, {}),
    quoteTypes: types.array(types.frozen<QuoteTypeDto>()),
    bookingStatuses: types.array(types.frozen<QuoteStatus>()),
    tradingCompanies: types.array(types.frozen<TradingCompanyDto>()),
    defaultTradingCompany: types.maybe(types.frozen<TradingCompanyDto>()),
  })
  .views(self => ({
    get quoteTypesForDropdown() {
      return self.quoteTypes.filter(q => q.isActive);
    },
  }))
  .actions(self => {
    const ajax = getAjax(self);

    const loadQuoteTypes = flow(function*() {
      self.quoteTypes = yield ajax.sales.quote.loadQuoteTypes();
    });

    const loadTradingCompanies = flow(function*() {
      self.tradingCompanies = yield ajax.sales.quote.loadTradingCompanies();
      self.defaultTradingCompany = self.tradingCompanies.find(f => f.isDefault);
    });

    const loadBookingStatuses = flow(function*() {
      self.bookingStatuses = yield ajax.sales.quote.loadBookingStatuses();
    });

    const getQuoteDriverPdf = flow(function*(pdfId: number) {
      return yield ajax.raw
        .getFile(`/api/operations/quotes/pdf/${pdfId}`)
        .toPromise()
        .then<Blob>(r => r.response);
    });

    const getTripDriverPdf = flow(function*(pdfId: number) {
      return yield ajax.raw
        .getFile(`/api/operations/quotes/trips/pdf/${pdfId}`)
        .toPromise()
        .then<Blob>(r => r.response);
    });

    return {
      loadQuoteTypes,
      loadBookingStatuses,
      getQuoteDriverPdf,
      getTripDriverPdf,
      loadTradingCompanies,
    };
  });
