import './RouteGroups.scss';

import cn from 'classnames';
import { DateTime } from 'luxon';
import { TIME_24_SIMPLE_HOURCYCLE_23 } from 'src/infrastructure/dateUtils';
import { ShiftTranslinkDirection } from 'src/api/enums';

type ViewJobShiftRouteGroupItem = Operations.Domain.Queries.ViewJob.JobShiftRouteGroupItem;
type ListJobShiftRouteGroupItem = Operations.Domain.Queries.ListRailInstructionsForDriver.JobShiftRouteGroupItem;
type ViewJobShiftRouteItem = Operations.Domain.Queries.ViewJob.JobShiftRouteItem;
type ListJobShiftRouteItem = Operations.Domain.Queries.ListRailInstructionsForDriver.JobShiftRouteItem;
type JobShiftRouteGroupItem = ViewJobShiftRouteGroupItem | ListJobShiftRouteGroupItem;
type JobShiftRouteItem = ViewJobShiftRouteItem | (ListJobShiftRouteItem & { pax?: number });
type RouteGroup = Operations.Domain.Queries.ViewJob.JobShiftRouteGroupItem;

export interface IPax {
  routeGroup: number;
  routeId: number;
  count?: number;
  depart: string | undefined;
  name: string;
  desto: string;
  routeName: string;
  direction: ShiftTranslinkDirection;
}

export interface IRouteGroupsProps {
  routeGroups: JobShiftRouteGroupItem[];
  printing?: boolean;
  hasSubcontractor?: boolean;
}

export function formatTime(duration: string | undefined) {
  if (!duration) {
    return '';
  }

  return DateTime.fromISO(duration).toLocaleString(TIME_24_SIMPLE_HOURCYCLE_23);
}

export function getMaxRuns(routeGroups: JobShiftRouteGroupItem[]) {
  return (
    Math.max(
      ...routeGroups
        .reduce((acc, rg) => [...acc, ...rg.routes], [] as JobShiftRouteItem[])
        .map((r: JobShiftRouteItem) => r.runNumber)
    ) + 1
  );
}

export function sortRoutes(routes: JobShiftRouteItem[]) {
  return routes.sort((a, b) => {
    if (a.runNumber !== b.runNumber) {
      return a.runNumber - b.runNumber;
    }

    return a.routeNumber - b.routeNumber;
  });
}

export function getMax(
  routes: JobShiftRouteItem[],
  valueFunction: (route: JobShiftRouteItem) => number
) {
  const routeValues = routes.length > 0 ? routes.map(valueFunction) : [0];
  return Math.max(...routeValues) + 1;
}

export const buildPax = (routeGroups: RouteGroup[]): IPax[] => {
  const pax: IPax[] = [];

  routeGroups.forEach((rg, rgNumber) => {
    const maxRoutes = getMax(rg.routes, r => r.routeNumber);
    const routes: Array<JobShiftRouteItem[]> = Array(maxRoutes).fill([]);
    const sortedRoutes = sortRoutes(rg.routes);
    sortedRoutes.forEach(val => {
      routes[val.routeNumber] = [...routes[val.routeNumber], val];
    });

    const firstRoute = routes[0];
    return firstRoute.forEach(x =>
      pax.push({
        count: x.pax,
        depart: x.depart,
        routeGroup: rgNumber,
        routeId: x.id,
        desto: rg.desto,
        name: rg.name,
        routeName: x.name,
        direction: rg.translinkDirection,
      })
    );
  });

  return pax;
};

const RouteGroups: React.FC<IRouteGroupsProps> = ({ routeGroups, printing, hasSubcontractor }) => {
  return (
    <div className="route-groups-component">
      {' '}
      {!printing ? <h3 className="title">Shift Details</h3> : null}
      {routeGroups.map((rg, rgNumber) => {
        const maxRuns = getMaxRuns(routeGroups);
        const maxRoutes = getMax(rg.routes, r => r.routeNumber);
        const routes: Array<JobShiftRouteItem[]> = Array(maxRoutes).fill([]);
        const sortedRoutes = sortRoutes(rg.routes);
        sortedRoutes.forEach(val => {
          routes[val.routeNumber] = [...routes[val.routeNumber], val];
        });
        return (
          <div key={rg.id} className="route-group">
            <div className="route-info">
              <h4 className="name">{!!rg.name ? `Route - ${rg.name}` : ''}</h4>
              <h4 className="desto">
                {!!rg.desto && !hasSubcontractor ? `Desto - ${rg.desto}` : ''}
              </h4>
            </div>
            <div className="route-table">
              <table className="table table-borderless table-striped table-sm">
                <thead>
                  <tr>
                    <th>Location</th>
                    {Array(maxRuns)
                      .fill(0)
                      .map((r, i) => {
                        return (
                          <th key={i}>
                            {routes.filter(x => !!x[i] && !!x[i].depart).length > 0
                              ? i + 1 + rgNumber + (routeGroups.length - 1) * i
                              : null}
                          </th>
                        );
                      })}
                  </tr>
                </thead>
                <tbody>
                  {routes.map((route: JobShiftRouteItem[], i) => {
                    const data = [<td key={'location-' + i}>{route[0].name}</td>];
                    route.forEach((run, runNumber) => {
                      data.push(<td key={run.id}>{formatTime(run.depart)}</td>);
                    });
                    return <tr key={i}>{data}</tr>;
                  })}
                  {printing ? (
                    <tr>
                      <td className="passenger-numbers">Pax Carried Per Run</td>
                      {Array(maxRuns)
                        .fill(0)
                        .map((r, i) => {
                          return (
                            <td
                              key={i}
                              className={cn('passenger-numbers', {
                                'passenger-numbers-data':
                                  routes.filter(x => !!x[i].depart).length > 0,
                              })}>
                              <div />
                            </td>
                          );
                        })}
                    </tr>
                  ) : null}
                </tbody>
              </table>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default RouteGroups;
