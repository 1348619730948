import inject from 'src/views/injectFromStore';
import ListRosterTemplates, { IListRosterTemplatesProps } from './ListRosterTemplates';

const Container = inject<IListRosterTemplatesProps>((allStores, p) => ({
  canManageRosterTemplates: allStores.rootStore.account.isWorkshopDepartmentMember,
  listRosterTemplates: allStores.rootStore.workshop.rosterTemplates.listItems,
  rosterTemplates: allStores.rootStore.workshop.rosterTemplates.items.slice(),
  hasMoreData: allStores.rootStore.workshop.rosterTemplates.hasMoreItems,
}))(ListRosterTemplates);

export default Container;
