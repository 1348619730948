import './AuditHistoryRecord.scss';
import { Component } from 'react';
import cn from 'classnames';
import { DateTime } from 'luxon';
import { ArrowRightIcon } from 'src/images/icons';
import { ISchemaRecord } from 'src/views/components/AuditHistory/ISchemaRecord';
import AuditHistoryField from 'src/views/components/AuditHistory/AuditHistoryField';

export interface IAuditHistoryRecordProps {
  // tslint:disable-next-line:no-any
  beforeValue: any;
  // tslint:disable-next-line:no-any
  afterValue: any;
  // tslint:disable-next-line:no-any
  changes: any;
  // tslint:disable-next-line:no-any
  address: any[];
  schema: ISchemaRecord;
  recordDate: DateTime;
}

class AuditHistoryRecord extends Component<IAuditHistoryRecordProps> {
  render() {
    const { beforeValue, afterValue, schema, changes, address, recordDate } = this.props;
    var shouldHighlight = schema.highlight ? schema.highlight(address, recordDate) : false;
    return (
      <div className={cn('audit-history-record', { highlight: shouldHighlight })}>
        {schema.label ? schema.label : schema.key}:{' '}
        <AuditHistoryField
          value={beforeValue}
          schema={schema}
          changes={changes}
          address={address}
          valueType="before"
        />{' '}
        <ArrowRightIcon />{' '}
        <AuditHistoryField
          value={afterValue}
          schema={schema}
          changes={changes}
          address={address}
          valueType="after"
        />
      </div>
    );
  }
}

export default AuditHistoryRecord;
