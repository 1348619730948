import { DateTime } from 'luxon';
import { IncompleteJobs } from '../../components/presentational/IncompleteJobs/IncompleteJobs';
import Leave from '../../operationsKiosk/home/Leaves/Leaves';
import { AlertCard } from '../../components/presentational/Alerts/AlertCard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWarning } from '@fortawesome/free-solid-svg-icons';
import memoizeOne from 'src/infrastructure/memoizeOne';
import Notes from '../../components/presentational/Notes/Notes';
import { ExpiredLicences } from '../../components/presentational/ExpiredLicences/ExpiredLicences';

type DriverNotes = People.Domain.Queries.ListNotesForDriver.DriverNotes;

interface IAdditionalHomeCards {
  notes: DriverNotes[];
  approvedLeaves: Common.Dtos.LeaveDto[];
  expiredLicences: People.Domain.Queries.ListUpcomingLicenceExpirationsForStaffMember.LicenceExpirationForStaffMember[];
  incompleteShifts: Workshop.Domain.Queries.ListIncompleteShiftsForMechanic.IncompleteShiftForMechanic[];
  needToCompleteTimesheet: boolean | undefined;
  timezone: string;
  styles: { [key: string]: string };
  markNotesReadForDriverOnDayHandler: (date: DateTime) => Promise<void>;
  date: DateTime;
}

const getStaffTimesheetReminder = memoizeOne(
  (date: DateTime, needToCompleteTimesheet: boolean | undefined) => {
    return (
      <AlertCard
        showAlert={needToCompleteTimesheet}
        heading="Timesheet"
        message="Please view and submit your timesheet"
        link="/wskiosk/timesheet"
        linkText="View Timesheet"
        icon={<FontAwesomeIcon icon={faWarning} />}
        iconColor="#ffa02b"
      />
    );
  }
);
const getNotesAlert = memoizeOne(
  (
    notes: People.Domain.Queries.ListNotesForDriver.DriverNotes[],
    markNotesReadForDriverOnDayHandler,
    date
  ) => {
    return (
      <Notes
        notes={notes}
        markNotesReadForDriverOnDayHandler={markNotesReadForDriverOnDayHandler}
        date={date}
      />
    );
  }
);

export const AdditionalHomeCards = ({
  notes,
  approvedLeaves,
  expiredLicences,
  incompleteShifts,
  needToCompleteTimesheet,
  timezone,
  styles,
  markNotesReadForDriverOnDayHandler,
  date,
}: IAdditionalHomeCards) => {
  if (
    (!notes || notes.length === 0) &&
    (!approvedLeaves || approvedLeaves.length === 0) &&
    (!expiredLicences || expiredLicences.length === 0) &&
    (!incompleteShifts || incompleteShifts.length === 0)
  ) {
    return null;
  }

  return (
    <div className={styles.cardContainer}>
      {getStaffTimesheetReminder(date, needToCompleteTimesheet)}
      {expiredLicences.length > 0 && (
        <ExpiredLicences licences={expiredLicences} timezone={timezone} />
      )}
      {incompleteShifts && incompleteShifts.length > 0 && (
        <IncompleteJobs
          incompleteWork={incompleteShifts}
          aggregateType={'shift'}
          timezone={timezone}
        />
      )}
      {getNotesAlert(notes, markNotesReadForDriverOnDayHandler, date)}
      {approvedLeaves && approvedLeaves.length ? (
        <Leave leaves={approvedLeaves} timezone={timezone} link="/wskiosk/leave" />
      ) : null}
    </div>
  );
};
