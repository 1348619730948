import { ModalDefBuilder, PaneType, FieldType } from 'src/views/definitionBuilders/types';
import { getSubmitCloseModalActionGroupDef } from 'src/views/definitionBuilders/common';
import { DateTimeFormat } from 'src/views/components/DateTimeFormat';

type TaskItem = Operations.Domain.Queries.GetTasksForDay.TasksForDay.TaskItem;
type UpdateTaskCommand = Operations.Domain.Commands.JobTask.UpdateTaskCommand;

export default function getUpdateTaskModalDef(
  updateTask: (command: UpdateTaskCommand) => Promise<void>
): ModalDefBuilder {
  return parentApi => {
    const currentTask = parentApi.actionData.parentValue as TaskItem;
    const taskCurrentlyComplete = currentTask.isComplete;
    const originalNotes = currentTask.completedNotes;
    return {
      title: 'Update Task',
      asForm: true,
      explicitData: parentApi.actionData.parentValue,
      panels: [
        {
          panes: [
            {
              paneType: PaneType.customPane,
              render: api => {
                const task = api.data.paneValue as TaskItem;
                return (
                  <h3>
                    {currentTask.extraType.description}
                    {': '}
                    <small>
                      {task.quoteNumber}, Trip {task.tripNumber + 1}
                    </small>
                  </h3>
                );
              },
            },
            {
              paneType: PaneType.customPane,
              hidden: !taskCurrentlyComplete,
              render: api => {
                const task = api.data.paneValue as TaskItem;
                const when = <DateTimeFormat value={task.completedOn} />;
                return (
                  <p>
                    Task completed by <strong>{task.completedBy}</strong> on <strong>{when}</strong>
                  </p>
                );
              },
            },
            {
              paneType: PaneType.formFieldsPane,
              fields: [
                {
                  fieldType: FieldType.yesNoField,
                  label: 'Task Complete',
                  dataAddr: 'isComplete',
                  mandatory: true,
                  onChange: api => {
                    if (taskCurrentlyComplete && (api.fieldData.paneValue as TaskItem).isComplete) {
                      api.setFormValue(['completedNotes'], originalNotes);
                    }
                  },
                },
                {
                  fieldType: FieldType.textField,
                  label: 'Notes',
                  dataAddr: 'completedNotes',
                  maxLength: 200,
                  readonly: d => taskCurrentlyComplete && (d.paneValue as TaskItem).isComplete,
                },
              ],
            },
          ],
        },
      ],
      secondaryActions: [getSubmitCloseModalActionGroupDef()],
      onFormSubmit: (task: TaskItem) => {
        return updateTask({
          quoteId: task.quoteId,
          tripNumber: task.tripNumber,
          jobId: task.jobId,
          extraTypeId: task.extraType.id,
          isComplete: task.isComplete,
          completedNotes: task.completedNotes,
        });
      },
    };
  };
}
