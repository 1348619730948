import { DateTime } from 'luxon';
import './Acknowledgement.scss';
import {
  getJobStatusDescriptor,
  ProgressId,
  progressIdDescription,
  JobStatus,
} from 'src/api/enums';
import { ErrorIcon } from 'src/images/icons';
import { UncontrolledTooltip } from 'reactstrap';

interface IAcknowledgeProps {
  id: string | number;
  clockOn: string;
  jobStatus: Operations.Domain.AggregatesModel.JobAggregate.JobStatus;
  jobProgress: Common.AggregatesModel.Operations.JobAggregate.ProgressId;
  unexpectedProgressMessage?: string;
  enableProgressWarning?: boolean;
  isVehicleSwapped: boolean;
}

const Acknowledgement = ({
  id,
  clockOn,
  jobStatus,
  jobProgress,
  unexpectedProgressMessage,
  enableProgressWarning,
  isVehicleSwapped,
}: IAcknowledgeProps) => {
  const isGreaterThanSevenDaysInFuture =
    DateTime.fromISO(clockOn) >= DateTime.local().plus({ days: 7 });
  if (isGreaterThanSevenDaysInFuture) {
    return null;
  }

  const tooltipId = 'tablet-progress-warning-icon' + id;

  const progressWarnings =
    enableProgressWarning && unexpectedProgressMessage ? (
      <>
        <span id={tooltipId}>
          <ErrorIcon />
        </span>
        <UncontrolledTooltip target={tooltipId}>{unexpectedProgressMessage}</UncontrolledTooltip>
      </>
    ) : null;

  return (
    <div className={'wrapper'}>
      {jobStatus.id === JobStatus.Completed || jobProgress === ProgressId.NotStarted ? (
        <span className={`acknowledgement-component ${getJobStatusDescriptor(jobStatus.id).name}`}>
          {jobStatus.description}
        </span>
      ) : isVehicleSwapped ? (
        <span className="acknowledgement-component">Vehicle Swapped</span>
      ) : (
        <span className={`acknowledgement-component jobprogress`}>
          {progressIdDescription(jobProgress)}
        </span>
      )}
      {!isVehicleSwapped && progressWarnings}
    </div>
  );
};

export default Acknowledgement;
