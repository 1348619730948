import { Input } from 'reactstrap';
import { LabelledFormField } from 'src/views/components/forms';
import { ITextFieldDef, IFieldMeta, IFieldData } from 'src/views/definitionBuilders/types';
import { Subject } from 'rxjs/Subject';
import { Subscription } from 'rxjs/Subscription';
import { IFieldApi } from 'src/views/components/Page/forms/Field';
import { PureComponent } from 'react';

interface ITextPageFieldProps {
  fieldApi: IFieldApi;
  fieldDef: ITextFieldDef;
  fieldMeta: IFieldMeta;
  fieldData: IFieldData<string>;
}

interface ITextPageFieldState {
  bufferedValue: string | undefined;
}

class TextPageField extends PureComponent<ITextPageFieldProps, ITextPageFieldState> {
  private readonly _subscriptions = new Subscription();
  private readonly _changeBuffer = new Subject<string>();

  constructor(props: ITextPageFieldProps) {
    super(props);
    this.state = {
      bufferedValue: undefined,
    };
  }

  componentDidMount() {
    // Buffer calls to fieldApi.setValue as doing so currently triggers a whole page refresh
    // which can make text fields unresponsive on large pages
    this._subscriptions.add(
      this._changeBuffer
        .debounceTime(250)
        .distinctUntilChanged()
        .subscribe(v => {
          this.props.fieldApi.setValue(v);
          this.setState({ bufferedValue: undefined });
        })
    );
  }

  componentWillUnmount() {
    this._subscriptions.unsubscribe();
  }

  private readonly handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { fieldDef } = this.props;
    const value = fieldDef.digitsOnly ? e.target.value.replace(/[^\d]/g, '') : e.target.value;
    this._changeBuffer.next(value);
    this.setState({ bufferedValue: value });
  };

  private readonly handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    this.props.fieldApi.setTouched(true);
    this.props.fieldMeta.onBlur && this.props.fieldMeta.onBlur(this.props.fieldData.fieldValue);
  };

  render() {
    const { fieldApi, fieldDef: def, fieldMeta: meta, fieldData: data } = this.props;
    const { bufferedValue } = this.state;
    const { error, touched } = fieldApi;
    const currentValue = (bufferedValue === undefined ? data.fieldValue : bufferedValue) || '';
    const safeError = touched && error;
    const labelText = typeof def.label === 'function' ? def.label(data) : def.label;
    const tooltipText = typeof def.tooltip === 'function' ? def.tooltip(data) : def.tooltip;
    return (
      <LabelledFormField
        className="text-page-field-component"
        readonly={meta.readonly || false}
        readonlyValue={def.formatReadonly ? def.formatReadonly(data) : currentValue}
        readonlyLinkTo={def.linkTo && currentValue ? def.linkTo(data) : undefined}
        mandatory={meta.mandatory}
        hideLabel={meta.hideLabel}
        labelValue={labelText}
        tooltipValue={tooltipText}
        noForm={meta.noForm}
        error={safeError}>
        <Input
          type="text"
          className={!safeError ? '' : 'invalid'}
          // disabled={disabled}
          autoFocus={meta.autoFocus}
          value={currentValue}
          onChange={this.handleChange}
          maxLength={def.maxLength}
          onBlur={this.handleBlur}
          autoComplete="off"
          placeholder={def.placeholder}
        />
      </LabelledFormField>
    );
  }
}

export default TextPageField;
