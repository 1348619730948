import './Spinner.scss';

import cn from 'classnames';
import { SpinnerIcon } from 'src/images/icons';

interface ISpinnerProps {
  show?: boolean;
  className?: string;
  spinnerClassName?: string;
}

const Spinner: React.FC<ISpinnerProps> = ({ show, className, spinnerClassName, children }) => (
  <div className={cn('spinner-component', className)}>
    <div className={cn(spinnerClassName, `spinner-container ${show ? 'visible' : 'hidden'}`)}>
      <div className="spinner-content">
        <SpinnerIcon size="2x" />
      </div>
    </div>
    {children}
  </div>
);

export default Spinner;
