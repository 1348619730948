import './MaintainJob.scss';
import { updatedDiff } from 'deep-object-diff';
import { LocationDescriptorObject } from 'history';
import { DateTime, Duration } from 'luxon';
import { useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import {
  AdhocReason,
  allAdhocReason,
  ChangeState,
  getShiftTranslinkDirectionDescriptor,
  JobBulkUpdateType,
  JobStatus,
  JobType,
  ProgressId,
  ShiftTranslinkDirection,
  ShiftType,
} from 'src/api/enums';
import {
  ENABLE_TIMESHEET_OVERRIDE_PAID_HOURS,
  ENABLE_SHOW_WAITING_TIME,
  JOB_CLOCK_ON_MINS_BUFFER,
  TIMEZONE,
} from 'src/appSettings';
import { ListPageLoadCause } from 'src/domain/baseTypes';
import { getStateTimezone } from 'src/domain/entities/operations/sales/boardingPoint/StateTimezones';
import {
  consolidateSkillSpecRequirements,
  consolidateSkillSpecRequirementsIds,
  ISplittedSkillSpecRequirements,
  splitSkillSpecRequirements,
} from 'src/domain/entities/people/staffMember/SkillSpecsHelpers';
import {
  consolidateTechSpecRequirements,
  IShortTechSpecRequirement,
  ISplittedTechSpecRequirements,
  splitTechSpecRequirements,
  splitTechSpecs,
} from 'src/domain/entities/workshop/techSpecs/TechSpecsHelpers';
import { EditIcon, ExcelIcon, InfoIcon } from 'src/images/icons';
import { dateIsAfter, validateDateTimeIsNotLessThan } from 'src/infrastructure/dateUtils';
import deepEqual from 'src/infrastructure/deepEqual';
import Omit from 'src/infrastructure/omit';
import { isDefined } from 'src/infrastructure/typeUtils';
import { DateTimeFormat } from 'src/views/components/DateTimeFormat';
import { DurationFormat } from 'src/views/components/DurationFormat';
import getProgressSectionDef from 'src/views/components/getProgressSectionDef';
import { buildPax } from 'src/views/components/operations/RouteGroups/RouteGroups';
import PageField from 'src/views/components/Page/fields/PageField';
import {
  getEditingFormattedTimeString,
  parseEditingFormattedTimeString,
} from 'src/views/components/Page/fields/subfields/TimeHelpers';
import { IFormApi, IFormApiWithoutState } from 'src/views/components/Page/forms/base';
import CrudPage, {
  CrudPageMode,
  ICrudPageDef,
  UpdateType,
} from 'src/views/components/Page/pages/CrudPage';
import { getSubmitCloseModalActionGroupDef } from 'src/views/definitionBuilders/common';
import {
  ActionType,
  FieldDefs,
  FieldType,
  IFieldData,
  IFieldOnChange,
  IHasChangeState,
  IModalDefBuilderApi,
  INestingPaneDef,
  PagePrimarySize,
  PaneType,
  ShellModalSize,
} from 'src/views/definitionBuilders/types';
import getAcknowledgeJobOnBehalfButtonDef from 'src/views/routes/operations/job/maintainJob/getAcknowledgeJobOnBehalfButtonDef';
import getContinuationsSectionDef from 'src/views/routes/operations/job/maintainJob/getContinuationsSectionDef';
import getLinkedJobsSectionDef from 'src/views/routes/operations/job/maintainJob/getLinkedJobsSectionDef';
import getActivityLogPanelDef from 'src/views/routes/operations/job/maintainJob/panelDefs/getActivityLogPanelDef';
import getAuditHistoryPanelDef from 'src/views/routes/operations/job/maintainJob/panelDefs/getAuditHistoryPanelDef';
import getShiftRoutesPaneDef from 'src/views/routes/operations/shared/getShiftRoutesPaneDef';
import { getSkillSpecRequirementFieldDefs } from 'src/views/routes/operations/shared/getSkillSpecRequirementFieldDefs';
import {
  getSkipFatigueActionGroupDef,
  ISkipFatigueValidationSubmissionMeta,
} from 'src/views/routes/operations/shared/getSkipFatigueActionGroupDef';
import { getTechSpecRequirementFieldDefs } from 'src/views/routes/operations/shared/getTechSpecRequirementFieldDefs';
import getTripRoutesPaneDef from 'src/views/routes/operations/shared/getTripRoutesPaneDef';
import {
  canJobTypeHaveSubcontractor,
  doesRequireClockOnOffTimesOnly,
  canJobTypeHaveSecondDriver,
  getStaffMemberFilterForJobType,
  isJobTypeCharter,
  isJobTypeLinkedToQuote,
  isJobTypeWithoutAsset,
  isRailJobType,
  isJobTypeDriverRelocation,
  isJobTypeContractCharter,
  isJobTypeCharterOrContractCharter,
  doesJobTypeHaveBooking,
  doesNotRequireAssetCompletionDetails,
  isJobTypeAnyCharter,
  isJobTypeCharterOrCharterStaged,
} from 'src/views/routes/operations/shared/jobTypeHelpers';
import {
  formattedWorkingJobHours,
  totalJobHours,
  workingJobHours,
  parseDuration,
} from 'src/views/routes/operations/urban/urbanHelpers';
import getCancelJobButtonDef from './getCancelJobButtonDef';
import getConflictsSectionDef from './getConflictsSectionDef';
import getCreateRouteTrainingJobButtonDef from './getCreateRouteTrainingJobButtonDef';
import getDeleteJobButtonDef from './getDeleteJobButtonDef';
import getDuplicateJobButtonDef from './getDuplicateJobButtonDef';
import getFatigueBreaksPaneDef from './getFatigueBreaksPaneDef';
import { MaintainJobCustomUpdateModes } from './MaintainJobCustomUpdateModes';
import getJobDetailPanelDef from './panelDefs/getJobDetailPanelDef';
import {
  getSwapVehicleModalActionButtonDef,
  IVehicleSwapModalActionButtonProps,
} from './panelDefs/getSwapVehicleModalDef';
import { Subscription } from 'rxjs';
import getCreateRouteTrainingNotAllowedButtonDef from './getCreateRouteTrainingNotAllowedButtonDef';
import { useRootStore } from 'src/domain/entities/RootStoreModel';
import { getBus } from 'src/domain/services';
import { observer } from 'mobx-react';
import { b64toBlob } from 'src/domain/blobHelper';
import humanizeDuration from 'src/infrastructure/humanizeDuration';
import saveAs from 'file-saver';
import getScheduledBreaksSectionDef from './getScheduledBreaksSectionDef';

type JobItem = Operations.Domain.Queries.ViewJob.JobItem;
type JobExtraItem = Operations.Domain.Queries.ViewJob.JobExtraItem;
type JobRouteItem = Operations.Domain.Queries.ViewJob.JobRouteItem;
type JobTypeEnumeration = Operations.Domain.AggregatesModel.JobAggregate.JobType;
type CreateAdhocJobCommand = Operations.Domain.Commands.Job.CreateAdhocJob.CreateAdhocJobCommand;
type UpdateJobCommand = Operations.Domain.Commands.Job.UpdateJob.UpdateJobCommand;
type AssetItem = Common.Queries.Workshop.GetFleetAssetList.AssetItem;
type ShiftListItem = Operations.Domain.Queries.ListShifts.ShiftListItem;
type ShiftSelectListItem = Operations.Domain.Queries.GetShiftsByCharterContract.ShiftSelectListItem;
type RailTemplateShiftItem = Operations.Domain.Queries.ViewRailTemplate.RailTemplateShiftItem;
type BoardingPointListItem = Operations.Domain.Queries.SearchBoardingPoint.BoardingPointListItem;
type ShiftTypeEnumeration = Operations.Domain.AggregatesModel.ShiftAggregate.ShiftType;
type QuoteItem = Operations.Domain.Queries.ViewQuote.QuoteItem;
type Trip = Operations.Domain.Queries.ViewQuote.Trip;
type StaffMemberDto = Common.Dtos.StaffMemberDto;
type JobShiftRouteGroupItem = Operations.Domain.Commands.Job.UpdateJob.UpdateJobCommand.JobShiftRouteGroupItem;
type JobShiftRouteItem = Operations.Domain.Commands.Job.UpdateJob.UpdateJobCommand.JobShiftRouteItem;
type FatigueBreakItem = Operations.Domain.Queries.ViewJob.FatigueBreakItem;
type OperationsJobConflictsUpdatedEvent = Operations.Domain.Events.ConflictsUpdated.OperationsJobConflictsUpdatedEvent;
type SwapVehicleCommand = Operations.Domain.Commands.Job.SwapVehicleCommand;

type PaxTableRow = {
  name: string;
  id: string;
  direction: ShiftTranslinkDirection;
  labels: string[];
  counts: (number | undefined)[];
};

type MaintainJobForm = Omit<JobItem, 'routeGroups'> &
  ISplittedSkillSpecRequirements &
  ISplittedTechSpecRequirements & {
    shiftName: string | ShiftListItem | RailTemplateShiftItem; // string from api, objects from select field
    railShiftName: string;
    contractShiftName: string;
    jobRoutes: Array<Operations.Domain.Queries.ViewJob.JobRouteItem & IHasChangeState>;
    jobExtras: Array<Operations.Domain.Queries.ViewJob.JobExtraItem & IHasChangeState>;
    quote?: QuoteItem;
    trip?: Trip;
    hasSubcontractor: boolean;
    routeGroups: IMaintainJobShiftRouteGroup[];
    runCount?: number;
  } & {
    pax: PaxTableRow[];
  };

export interface IMaintainJobShiftRouteGroup {
  routes: IMaintainJobShiftRoute[];
  desto: string;
  name: string;
  changeState: ChangeState;
  translinkDirection: ShiftTranslinkDirection;
}

interface IMaintainJobShiftRoute {
  changeState: ChangeState;
  location: BoardingPointListItem;
  depart: string[];
}

export interface IMaintainJobProps {
  mode: CrudPageMode;
  redirect: (url: string | LocationDescriptorObject) => void;
}

interface IMaintainJobRouteParams {
  id: string;
}

type ISubmissionMeta = ISkipFatigueValidationSubmissionMeta & {
  keepAcknowledgement?: boolean;
  bulkUpdate?: JobBulkUpdateType;
  bulkUpdateAllocations?: boolean;
  bulkUpdateAssets?: boolean;
};

type InternalProps = IMaintainJobProps & RouteComponentProps<IMaintainJobRouteParams>;

const AssetKey = 'asset';
const hideOverridePaidHours = !ENABLE_TIMESHEET_OVERRIDE_PAID_HOURS;
const hideWaitingTimeField = !ENABLE_SHOW_WAITING_TIME;

const MaintainJob: React.FC<InternalProps> = observer((props: InternalProps) => {
  const [primaryFormApi, setFormApi] = useState<IFormApiWithoutState | undefined>(undefined);
  const [shiftOptions, setShiftOptions] = useState<ShiftSelectListItem[]>();
  const [shiftListOptions, setShiftListOptions] = useState<ShiftListItem[]>();

  let domainEventSubscription: Subscription | undefined;
  let onShiftChanged = false;
  const jobDurationLimitInMonths = 6;

  const rootStore = useRootStore();
  const domainEvents = getBus(rootStore).operationsDomainEvent$;

  const canSkipFatigueValidation = rootStore.account.isAdminUser;
  const jobModel = rootStore.operations.job.item;
  const assetsModel = rootStore.assets;
  const exclusionModel = rootStore.operations.exclusionModel;
  const depotModel = rootStore.operations.depot;
  const conflictModel = rootStore.operations.conflict;
  const urbanModel = rootStore.operations.urban;
  const railModel = rootStore.operations.rail;
  const boardingPointModel = rootStore.operations.sales.boardingPoint;
  const bookingsListModel = rootStore.operations.sales.quotes.bookingsList;
  const quoteModel = rootStore.operations.sales.quotes.quoteItem;
  const vehicleTypesModel = rootStore.operations.vehicleTypes;
  const extraTypesModel = rootStore.operations.extraTypes;
  const staffMembersModel = rootStore.people.staffMembers;
  const subcontractorsModel = rootStore.operations.subcontractors;
  const skillSpecsModel = rootStore.people.skillSpecs;
  const techSpecsModel = rootStore.workshop.techSpecs;
  const contractModel = rootStore.operations.charterContracts;
  const scheduledBreaks = jobModel.scheduledBreaks;
  const loadJobSubTypes = rootStore.operations.job.loadJobSubTypes;
  const jobSubTypes = rootStore.operations.job.jobSubTypes.slice();
  const jobProgress = jobModel.progress?.progress.slice();
  const loadJobProgress = rootStore.operations.job.item.loadJobProgress;
  const isUpdateMode = props.mode === 'update';
  const isCreateMode = props.mode === 'create';
  const currentJob = jobModel.job;
  const isAdhoc = !isUpdateMode ? true : !!currentJob && currentJob.adhoc;
  const jobId = props.match.params.id;
  const isRailJob = !!currentJob && isRailJobType(currentJob.jobType);
  const canManageJobs = rootStore.account.isOperationsDepartmentMember;
  const searchableContracts = contractModel.list.items.map(contract => ({
    ...contract,
    searchable: `${contract.name} - ${contract.customer.customerName}`,
  }));

  const exportActivitiesToExcel = () => {
    const fileName = `Job_Activities_${DateTime.local().toFormat('yyyyMMddHHmm')}.xlsx`;
    return jobModel.exportActivitiesToExcel().then((blob: Blob) => saveAs(blob, fileName));
  };

  const mapRouteGroupsToCommand = (
    runCount: number,
    routeGroups: IMaintainJobShiftRouteGroup[],
    pax: PaxTableRow[]
  ): JobShiftRouteGroupItem[] => {
    if (!routeGroups) {
      return [];
    }
    return routeGroups
      .filter(x => x.changeState !== ChangeState.Deleted)
      .map(rg => {
        let routes: JobShiftRouteItem[] = [];
        rg.routes
          .filter(x => x.changeState !== ChangeState.Deleted)
          .forEach((route, routeNumber) => {
            for (let i = 0; i < runCount; i++) {
              const depart = route.depart && i < route.depart.length ? route.depart[i] : undefined;
              const labelDepart = getDepart(depart);

              const routePaxItems = pax.find(
                x => x.name === route.location.name && x.labels.includes(labelDepart)
              );
              const routePaxIndex =
                routePaxItems &&
                depart &&
                routePaxItems.labels.findIndex(x => x === depart.substring(0, depart.length - 3));
              const routePax =
                routePaxItems &&
                routePaxItems.counts[routePaxIndex === undefined ? -1 : routePaxIndex];

              routes.push({
                address: route.location.address,
                city: route.location.city,
                depart: depart,
                name: route.location.name,
                notes: route.location.notes,
                postcode: route.location.postcode,
                runNumber: i,
                routeNumber: routeNumber,
                state: route.location.state,
                pax: routePax,
              });
            }
          });
        return {
          desto: rg.desto,
          name: rg.name,
          routes: routes,
          translinkDirection: rg.translinkDirection,
        };
      });
  };
  const handlePreSubmitForUpdate = (
    job: MaintainJobForm,
    meta: ISubmissionMeta | undefined
  ): UpdateJobCommand => {
    const isCharterOrCharterStagedJob = isJobTypeCharterOrCharterStaged(job.jobType.id);
    const isTrainingJob = job.isTrainingJob;
    const getChangeState = (
      li: JobExtraItem | JobRouteItem | FatigueBreakItem,
      originals: any[]
    ) => {
      if (li.changeState === ChangeState.Added || li.changeState === ChangeState.Deleted) {
        return li.changeState;
      }
      const original =
        originals &&
        originals.find((x: JobExtraItem | JobRouteItem | FatigueBreakItem) => x.id === li.id);

      return deepEqual(li, original, { strict: true })
        ? ChangeState.Unchanged
        : ChangeState.Modified;
    };

    return {
      railBookingId: job.railBookingId,
      assetId: job.asset && job.asset.id,
      hasSubcontractor: job.hasSubcontractor,
      subcontractorId: job.subcontractor && job.subcontractor.id,
      departDepot: job.departDepot,
      arriveDepot: job.arriveDepot,
      description: job.description,
      id: jobId,
      staffMemberId: job.staffMember && job.staffMember.id,
      hasSecondStaffMember: job.hasSecondStaffMember,
      secondStaffMemberId: job.secondStaffMember && job.secondStaffMember.id,
      clockOn:
        isCharterOrCharterStagedJob && !isTrainingJob ? job.clockOn || job.onSite! : job.clockOn,
      clockOff:
        isCharterOrCharterStagedJob && !isTrainingJob
          ? job.clockOff || job.shiftEnd!
          : job.clockOff,
      onSite: job.onSite,
      departingFromDepotId: isJobTypeWithoutAsset(job.jobType.id)
        ? undefined
        : job.departingFromDepot.id,
      departingFromDepotInCar: job.departingFromDepotInCar,
      shiftCommence: job.shiftCommence,
      shiftEnd: job.shiftEnd,
      arrivingAtDepotId: isJobTypeWithoutAsset(job.jobType.id) ? undefined : job.arrivingAtDepot.id,
      arrivingAtDepotInCar: job.arrivingAtDepotInCar,
      notes: job.notes,
      unpaidBreaks: job.unpaidBreaks,
      odometerStart: job.zeroKmsTravelled ? undefined : job.odometerStart,
      odometerEnd: job.zeroKmsTravelled ? undefined : job.odometerEnd,
      driverClockedOn: job.driverClockedOn,
      driverClockedOff: job.driverClockedOff,
      driverUnpaidBreaks: job.driverUnpaidBreaks,
      driverWaitingTime: job.driverWaitingTime,
      reasonForDifference: job.reasonForDifference,
      approvalNumber: job.approvalNumber,
      anythingToReport: job.anythingToReport,
      tolls: job.tolls,
      secondDriverClockedOn: job.secondDriverClockedOn,
      secondDriverClockedOff: job.secondDriverClockedOff,
      secondDriverReasonForDifference: job.secondDriverReasonForDifference,
      secondDriverAnythingToReport: job.secondDriverAnythingToReport,
      keepAcknowledgement: !!meta?.keepAcknowledgement,
      bulkUpdate: !!(meta && meta.bulkUpdate) ? meta.bulkUpdate : JobBulkUpdateType.NoBulkUpdate,
      bulkUpdateAllocations:
        meta &&
        meta.bulkUpdate &&
        meta.bulkUpdate === JobBulkUpdateType.JobsForTripsFromSameAndFutureRecurringBookings &&
        meta.bulkUpdateAllocations !== undefined
          ? meta.bulkUpdateAllocations
          : undefined,
      bulkUpdateAssets:
        meta &&
        meta.bulkUpdate &&
        meta.bulkUpdate === JobBulkUpdateType.JobsForTripsFromSameAndFutureRecurringBookings &&
        meta.bulkUpdateAssets !== undefined
          ? meta.bulkUpdateAssets
          : undefined,
      adhocReasonId: isAdhoc ? job.adhocReason.id : undefined,
      adhocReasonDetails: isAdhoc ? job.adhocReasonDetails : '',
      jobRoutes: job.jobRoutes.map(j => ({
        id: j.id,
        boardingPointId:
          // location can be a BoardingPointListItem if a new boarding point was selected
          j.location.boardingPointId ??
          (j.location as Operations.Domain.Queries.SearchBoardingPoint.BoardingPointListItem).id,
        name: j.location.name,
        date: j.date,
        address: j.location.address,
        city: j.location.city,
        state: j.location.state,
        postcode: j.location.postcode,
        notes: j.location.notes,
        arrive: j.arrive,
        depart: j.depart,
        changeState: getChangeState(j, currentJob!.jobRoutes),
      })),
      jobExtras: job.jobExtras.map(e => ({
        extraTypeId: e.extraType.id,
        quantity: e.quantity,
        changeState: getChangeState(e, currentJob!.jobExtras),
      })),
      routeGroups: mapRouteGroupsToCommand(job.runCount!, job.routeGroups, job.pax),
      skipPredepartureChecks: job.skipPredepartureChecks,
      skillSpecRequirements: consolidateSkillSpecRequirements(job),
      techSpecRequirements: consolidateTechSpecRequirements(job, splittedTechSpecs),
      fatigueBreaks: job.fatigueBreaks.map(b => ({
        id: b.id,
        staffMemberId: b.staffMemberId,
        fatigueBreakStart: b.fatigueBreakStart,
        fatigueBreakEnd: b.fatigueBreakEnd,
        fatigueBreakSourceId: b.fatigueBreakSource.id,
        notes: b.notes,
        ignored: b.ignored,
        changeState: getChangeState(b, currentJob!.fatigueBreaks),
      })),
      skipFatigueValidation: !!(meta && meta.skipFatigueValidation),
      wheelchairPax: job.wheelchairPax,
      overrideDriverUnpaidBreaks: job.overrideDriverUnpaidBreaks,
      reasonForCompletionDetailsOverride: !!job.overrideDriverUnpaidBreaks
        ? job.reasonForCompletionDetailsOverride
        : '',
      vehicleTypeId: job.vehicleType && job.vehicleType.id,
      secondDriverApprovalNumber: job.secondDriverApprovalNumber,
      secondDriverUnpaidBreaks: job.secondDriverUnpaidBreaks,
      secondDriverOverrideDriverUnpaidBreaks: job.secondDriverOverrideDriverUnpaidBreaks,
      secondDriverReasonForCompletionDetailsOverride: !!job.secondDriverOverrideDriverUnpaidBreaks
        ? job.secondDriverReasonForCompletionDetailsOverride
        : '',
      zeroKmsTravelled: !!job.zeroKmsTravelled,
      jobSubTypeId: job.jobSubType?.id,
    };
  };

  const handlePreSubmitForCreate = (
    job: MaintainJobForm,
    meta: ISubmissionMeta | undefined
  ): CreateAdhocJobCommand => {
    const isWithoutAsset = isJobTypeWithoutAsset(job.jobType.id);
    const isCharterOrCharterStagedJob = isJobTypeCharterOrCharterStaged(job.jobType.id);
    const isTrainingJob = job.isTrainingJob;

    return {
      assetId: isWithoutAsset ? undefined : job.asset && job.asset.id,
      hasSubcontractor: job.hasSubcontractor,
      subcontractorId: job.subcontractor && job.subcontractor.id,
      departDepot:
        isWithoutAsset || job.jobType.id === JobType.Workshop ? undefined : job.departDepot,
      arriveDepot:
        isWithoutAsset || job.jobType.id === JobType.Workshop ? undefined : job.arriveDepot,
      description: job.description,
      jobTypeId: job.jobType.id,
      jobSubTypeId: job.jobSubType?.id,
      shiftName:
        job.shiftName === undefined
          ? job.railShiftName
          : typeof job.shiftName === 'string'
          ? job.shiftName
          : (job.shiftName as ShiftListItem | RailTemplateShiftItem).shiftName,
      railBookingId: job.railBookingId,
      staffMemberId: job.staffMember && job.staffMember.id,
      hasSecondStaffMember: job.hasSecondStaffMember,
      secondStaffMemberId: job.secondStaffMember && job.secondStaffMember.id,
      clockOn:
        isCharterOrCharterStagedJob && !isTrainingJob ? job.clockOn || job.onSite! : job.clockOn,
      clockOff:
        isCharterOrCharterStagedJob && !isTrainingJob
          ? job.clockOff || job.shiftEnd!
          : job.clockOff,
      departingFromDepotId: isWithoutAsset ? undefined : job.departingFromDepot.id,
      departingFromDepotInCar: job.departingFromDepotInCar,
      onSite: job.onSite,
      shiftCommence:
        isWithoutAsset || job.jobType.id === JobType.Workshop ? undefined : job.shiftCommence,
      shiftEnd: isWithoutAsset || job.jobType.id === JobType.Workshop ? undefined : job.shiftEnd,
      arrivingAtDepotId: isWithoutAsset ? undefined : job.arrivingAtDepot.id,
      arrivingAtDepotInCar: job.arrivingAtDepotInCar,
      notes: job.notes,
      unpaidBreaks: job.unpaidBreaks || '00:00',
      adhocReasonId: job.adhocReason.id,
      adhocReasonDetails: job.adhocReasonDetails,
      quoteId: job.quote && job.quote.id,
      tripNumber: job.trip && job.trip.tripNumber - 1,
      vehicleTypeId: job.vehicleType && job.vehicleType.id,
      jobRoutes:
        job.jobRoutes &&
        job.jobRoutes
          .filter((x: IHasChangeState) => x.changeState !== ChangeState.Deleted)
          .map(x => {
            return {
              address: x.location.address,
              arrive: x.arrive,
              city: x.location.city,
              date: x.date,
              depart: x.depart,
              name: x.location.name,
              notes: x.location.notes,
              postcode: x.location.postcode,
              state: x.location.state,
              boardingPointId:
                (x.location as Operations.Domain.Queries.SearchBoardingPoint.BoardingPointListItem)
                  .id ?? x.location.boardingPointId,
            };
          }),
      jobExtras:
        job.jobExtras &&
        job.jobExtras
          .filter((e: IHasChangeState) => e.changeState !== ChangeState.Deleted)
          .map(e => ({ extraTypeId: e.extraType.id, quantity: e.quantity })),
      routeGroups: mapRouteGroupsToCommand(job.runCount!, job.routeGroups, []),
      skipPredepartureChecks: job.skipPredepartureChecks,
      skillSpecRequirements: consolidateSkillSpecRequirements(job),
      techSpecRequirements: consolidateTechSpecRequirements(job, splittedTechSpecs),
      skipFatigueValidation: !!(meta && meta.skipFatigueValidation),
      charterContractId: job.charterContract && job.charterContract.id,
    };
  };

  const requiresClockOnOffTimesOnly = (jobType: JobTypeEnumeration, isTrainingJob: boolean) => {
    if (isTrainingJob) {
      return true;
    }
    if (!jobType) {
      return false;
    }
    return doesRequireClockOnOffTimesOnly(jobType.id);
  };

  const allowSecondDriver = (jobType: JobTypeEnumeration) => {
    if (!jobType) {
      return false;
    }
    return canJobTypeHaveSecondDriver(jobType.id);
  };

  const shiftRequiresClockOnOffTimesOnly = (shiftType: ShiftTypeEnumeration) => {
    if (!shiftType) {
      return false;
    }
    return (
      shiftType.id === ShiftType.AsRequired ||
      shiftType.id === ShiftType.Cleaning ||
      shiftType.id === ShiftType.Operations ||
      shiftType.id === ShiftType.Yard ||
      shiftType.id === ShiftType.Office
    );
  };

  const isStatusAcknowledged = !!(
    currentJob &&
    currentJob.jobStatus &&
    currentJob.jobStatus.id === JobStatus.Acknowledged
  );

  const getMaxValue = (
    routesGroups: Operations.Domain.Queries.ViewJob.JobShiftRouteGroupItem[],
    valueFunction: (route: Operations.Domain.Queries.ViewJob.JobShiftRouteItem) => number
  ) => {
    const routes = routesGroups.reduce(
      (acc: Operations.Domain.Queries.ViewJob.JobShiftRouteItem[], val) => {
        return acc.concat(val.routes);
      },
      []
    );
    return getMax(routes, valueFunction);
  };

  const getMax = (
    routes: Operations.Domain.Queries.ViewJob.JobShiftRouteItem[],
    valueFunction: (route: Operations.Domain.Queries.ViewJob.JobShiftRouteItem) => number
  ) => {
    const routeValues = routes.length > 0 ? routes.map(valueFunction) : [0];
    return Math.max(...routeValues) + 1;
  };

  const mapRoutesToData = (
    routeGroups: Operations.Domain.Queries.ViewJob.JobShiftRouteGroupItem[]
  ): IMaintainJobShiftRouteGroup[] => {
    const maxRunNumber = getMaxValue(routeGroups, r => r.runNumber);
    return routeGroups.map(rg => {
      const routeGroup = {
        ...rg,
        changeState: ChangeState.Unchanged,
        routes: Array.from({ length: getMax(rg.routes, r => r.routeNumber) }, () => {
          return {
            location: {
              address: '',
              name: '',
              city: '',
              postcode: '',
              state: '',
              notes: '',
              id: '',
            },
            depart: Array.from({ length: maxRunNumber }, () => '') as string[],
            changeState: ChangeState.Unchanged,
          };
        }),
      };
      rg.routes.forEach(r => {
        const departArray = routeGroup.routes[r.routeNumber].depart;
        departArray[r.runNumber] = r.depart as string;
        const routeData = {
          location: {
            address: r.address,
            name: r.name,
            city: r.city,
            postcode: r.postcode,
            state: r.state,
            notes: r.notes,
            boardingPointId: r.boardingPointId,
            id: '',
          },
          depart: [...departArray],
          changeState: ChangeState.Unchanged,
        };
        routeGroup.routes[r.routeNumber] = routeData;
      });
      return routeGroup;
    });
  };

  const staffMembersHaveBeenChanged = (updatedJob: JobItem) => {
    const originalJob = currentJob;
    if (!originalJob) {
      return true;
    }

    const origStaffId = originalJob.staffMember && originalJob.staffMember.id;
    const updatedStaffId = updatedJob.staffMember && updatedJob.staffMember.id;
    const origSecStaffId = originalJob.secondStaffMember && originalJob.secondStaffMember.id;
    const updatedSecStaffId = updatedJob.secondStaffMember && updatedJob.secondStaffMember.id;

    return (
      origStaffId !== updatedStaffId ||
      originalJob.hasSecondStaffMember !== updatedJob.hasSecondStaffMember ||
      origSecStaffId !== updatedSecStaffId
    );
  };

  const getDepart = (depart: string | undefined) => {
    let label = depart || '';
    label = label.substring(0, label.length - 3);
    return label;
  };

  const getData = (): Partial<MaintainJobForm> | undefined => {
    if (!isUpdateMode) {
      return {
        adhoc: true,
        hasSubcontractor: false,
        runCount: 1,
      };
    }

    if (currentJob && currentJob.id === jobId) {
      const pax = buildPax(currentJob.routeGroups);

      const mappedPax = pax.reduce((paxTable, ipax) => {
        const existing = paxTable.find(t => t.name === ipax.routeName && t.id === ipax.desto);
        const depart = getDepart(ipax.depart);

        if (existing) {
          existing.counts.push(ipax.count);
          existing.labels.push(depart);
        } else {
          paxTable.push({
            name: ipax.routeName,
            id: ipax.desto,
            labels: [depart],
            counts: [ipax.count],
            direction: ipax.direction,
          });
        }

        return paxTable;
      }, [] as PaxTableRow[]);

      return {
        ...currentJob,
        ...splitSkillSpecRequirements(currentJob.skillSpecRequirements),
        ...splitTechSpecRequirements(currentJob.techSpecRequirements),
        skipPredepartureChecks: !!currentJob.skipPredepartureChecks,
        contractShiftName: isJobTypeContractCharter(currentJob.jobType.id)
          ? currentJob.shiftName
          : undefined,
        runCount: getMaxValue(currentJob.routeGroups, r => r.runNumber),
        routeGroups: mapRoutesToData(currentJob.routeGroups),
        bulkUpdateAllocations: false,
        bulkUpdateAssets: false,
        wheelchairPax: currentJob.wheelchairPax,
        pax: mappedPax,
        jobExtras: currentJob.jobExtras,
        zeroKmsTravelled:
          currentJob.zeroKmsTravelled === null ? false : currentJob.zeroKmsTravelled,
        scheduledBreaks,
      };
    }
    return undefined;
  };

  const splittedTechSpecs = splitTechSpecs(techSpecsModel.forRequirements.items);

  const canJobBeCancelled = (job: JobItem) => {
    return (
      isJobTypeLinkedToQuote(job.jobType.id) &&
      job.jobStatus.id !== JobStatus.Completed &&
      !job.isCancelled &&
      job.jobProgressItems.length > 0 &&
      job.jobProgressItems.every(
        x => x.progress !== ProgressId.Finished && x.progress !== ProgressId.CancelledInProgress
      )
    );
  };

  const buildSwapVehicleModal = (
    job: JobItem,
    getTechSpecRequirements: IVehicleSwapModalActionButtonProps['getTechSpecRequirements'],
    swapVehicle: (command: SwapVehicleCommand) => Promise<void>,
    editing: boolean
  ) => {
    return getSwapVehicleModalActionButtonDef({
      hidden: !isUpdateMode,
      disabled: editing,
      existingClockOn:
        job.continuation?.jobStart || job.vehicleSwapJobDetails?.start || job.clockOn,
      existingClockOff:
        job.continuation?.jobFinish ||
        (job.vehicleSwapJobDetails && job.vehicleSwapJobDetails?.vehicleSwapToJobId !== null)
          ? job.vehicleSwapJobDetails?.finish ?? job.clockOff
          : job.clockOff,
      existingJobId: job.id,
      existingJobBreaks: job.unpaidBreaks || '00:00',
      className: 'swap-vehicle-container',
      onSubmit: command => swapVehicle(command).then(() => loadJobProgress(command.existingJobId)),
      fleetAssets: assetsModel.fleetAssetListItems,
      staffMemberId: job.staffMember?.id,
      secondStaffMemberId: job.secondStaffMember?.id,
      getTechSpecRequirements: getTechSpecRequirements,
      existingProgresses: jobProgress,
      currentAsset: job?.asset?.id,
      continuationDetailsForVehicleSwap: job?.continuationDetailsForVehicleSwap,
    });
  };

  const getActionsDef = (
    job: JobItem | undefined,
    getVehicleSwapTechSpecRequirements: (api: IModalDefBuilderApi) => IShortTechSpecRequirement[],
    swapVehicle: (command: SwapVehicleCommand) => Promise<void>,
    editing: boolean
  ) => {
    const isIncompleteCharter =
      job?.jobStatus?.id === JobStatus.Incomplete && isJobTypeCharter(job.jobType.id);
    const duplicateJobButtonActionDef = {
      actions: [
        getDuplicateJobButtonDef(
          jobId,
          isAdhoc,
          !!job && isJobTypeCharter(job.jobType.id),
          (!!job && !!job.parentJob) || (!!job && !!job.vehicleSwapJobDetails?.canDuplicate),
          !!jobProgress?.length,
          jobModel.duplicateJob
        ),
        job &&
        job.jobStatus.id !== JobStatus.Completed &&
        !job.hasSubcontractor &&
        !job.isTrainingJob &&
        !isIncompleteCharter
          ? getCreateRouteTrainingJobButtonDef(
              jobId,
              job.jobType.id,
              jobModel.createRouteTrainingJob,
              job.asset && job.asset.id
            )
          : undefined,
        job && isIncompleteCharter
          ? getCreateRouteTrainingNotAllowedButtonDef(job.jobType.id)
          : undefined,
        job &&
        job.jobStatus.id === JobStatus.NotAcknowledged &&
        !!job.staffMember &&
        !job.hasStaffMemberAcknowledged
          ? getAcknowledgeJobOnBehalfButtonDef(
              job.id,
              job.staffMember.id,
              job.staffMember.name,
              jobModel.acknowledgeJobOnBehalf
            )
          : undefined,
        job &&
        job.jobStatus.id === JobStatus.NotAcknowledged &&
        !!job.secondStaffMember &&
        !job.hasSecondStaffMemberAcknowledged
          ? getAcknowledgeJobOnBehalfButtonDef(
              job.id,
              job.secondStaffMember.id,
              job.secondStaffMember.name,
              jobModel.acknowledgeJobOnBehalf
            )
          : undefined,
      ].filter(isDefined),
    };

    const deleteJobButtonActionDef = {
      actions: [
        getDeleteJobButtonDef(
          job ? job.childJobs.length : 0,
          !!job && job.canBeDeleted,
          (forceDelete: boolean | undefined) => jobModel.deleteJob(jobId, forceDelete)
        ),
      ],
    };

    const cancelJobButtonActionDef = {
      actions: [
        getCancelJobButtonDef(
          job ? job.childJobs.length : 0,
          !!job && !!job.continuation && !!job.continuation.toJob,
          () => jobModel.cancelJob(jobId)
        ),
      ],
    };

    const swapVehicleDef = (j: JobItem) => ({
      actions: [buildSwapVehicleModal(j, getVehicleSwapTechSpecRequirements, swapVehicle, editing)],
    });

    const actionsDef = [duplicateJobButtonActionDef];
    if (job && canJobBeCancelled(job)) {
      actionsDef.push(cancelJobButtonActionDef);
    }
    actionsDef.push(deleteJobButtonActionDef);

    if (
      (job && job.jobProgressItems.length > 0 && job.vehicleSwapJobDetails === null) ||
      (job &&
        job.jobProgressItems.length > 0 &&
        job.vehicleSwapJobDetails?.canVehicleSwap &&
        job.vehicleSwapJobDetails?.vehicleSwapToJobId === null)
    ) {
      actionsDef.push(swapVehicleDef(job));
    }

    return actionsDef;
  };

  const isMandatoryGivenVehicleChangeAllowed = (original?: JobItem, current?: JobItem) => {
    if (original && current) {
      // SkipPredepartureChecks has defaults automatically set.
      // Need to be able to ignore it for now
      const { skipPredepartureChecks: originalSkip, ...originalRest } = original;
      const { skipPredepartureChecks: currentSkip, ...currentRest } = current;

      // Default when no setting is made
      const hasPredepartureChanged = !(
        (originalSkip === null && currentSkip === false) ||
        originalSkip === currentSkip
      );

      if (hasPredepartureChanged) {
        return true;
      }

      const updates = updatedDiff(originalRest, currentRest);
      const updatedKeys = Object.keys(updates);
      if (updatedKeys.length === 1 && updatedKeys[0] === AssetKey) {
        return false;
      } else if (updatedKeys.length > 1 && updatedKeys.includes(AssetKey)) {
        // Changing a variable, then changing it back causes the diff library to keep the item but set its value to undefined
        // As long as all the other "changes" are undefined, nothing has actually changed
        return !updatedKeys
          .filter(k => k !== AssetKey)
          .map(k => updates[k])
          .every(t => t === undefined);
      }
    }
    return true;
  };

  const downloadDriverPdf = (pdfId: number, name: string): Promise<void> => {
    if (!jobModel.driverPdfs || jobModel.driverPdfs.length === 0) {
      return jobModel.loadDriverPdfs().then(() => downloadDriverPdf(pdfId, name));
    } else {
      const pdf = jobModel.driverPdfs.find(x => x.id === pdfId);
      if (pdf) {
        const data = b64toBlob(pdf.data, 'application/pdf');
        saveAs(data, name);
      }
      return Promise.resolve();
    }
  };

  const getPaxPaneDef = (formApi: IFormApi): INestingPaneDef => {
    const pax: MaintainJobForm['pax'] | undefined = formApi.getValue('pax');
    const counts = (pax && pax[0] && pax[0].counts) || [];
    const { job } = jobModel;

    const mandatory = job && job.jobStatus.id === JobStatus.Completed ? true : false;
    const unbreakeableSpace = '\u00A0';

    const fields: FieldDefs[] = counts.map((p, i) => {
      return {
        fieldType: FieldType.numericField,
        mandatory: d => mandatory && !!d.parentValue.labels[i],
        readonly: d => !d.parentValue.labels[i],
        numericConfig: { numericType: 'unsignedInt', minValue: 0 },
        label: d => d.parentValue.labels[i] || unbreakeableSpace,
        dataAddr: ['counts', i],
      } as FieldDefs;
    });

    return {
      paneType: PaneType.nestingPane,
      enableHorizontalScroll: true,
      panes: [
        {
          paneType: PaneType.repeatingPane,
          useHover: false,
          enableHorizontalScroll: true,
          dataAddr: 'pax',
          itemPanes: [
            {
              paneType: PaneType.customPane,
              render: d => {
                const row = d.data.paneValue as PaxTableRow;
                return <h4>{getShiftTranslinkDirectionDescriptor(row.direction).description}</h4>;
              },
            },
            {
              paneType: PaneType.formFieldsPane,
              columnCount: counts.length + 1,
              fields: [
                {
                  fieldType: FieldType.readonlyField,
                  label: 'Starting Point',
                  dataAddr: 'name',
                  formatReadonly: d => <div className="startingpoint">{d.fieldValue}</div>,
                },
                ...fields,
              ],
            },
          ],
        },
        {
          paneType: PaneType.formFieldsPane,
          columnCount: 3,
          fields: [
            {
              fieldType: FieldType.numericField,
              mandatory: mandatory,
              numericConfig: { numericType: 'unsignedInt', minValue: 0 },
              dataAddr: 'wheelchairPax',
              label: 'How many wheelchair passengers were carried?',
            } as FieldDefs,
          ],
        },
      ],
    };
  };

  const getPageDef = (
    updating: boolean,
    updateType: UpdateType,
    beginCustomEditing: (updateType: UpdateType) => void
  ): ICrudPageDef => {
    const editable = !isUpdateMode || updating;
    const isTrainingJob = currentJob?.isTrainingJob;

    domainEventSubscription?.unsubscribe();
    if (currentJob && !editable) {
      domainEventSubscription = domainEvents
        .filter(
          e =>
            e.eventName === 'OperationsJobConflictsUpdatedEvent' &&
            (e.payload as OperationsJobConflictsUpdatedEvent).jobIds.some(item => item === jobId)
        )
        .subscribe(() => {
          jobModel.loadJob(jobId);
        });
    }
    const isUpdatingRecurringUpdateMode =
      updating && updateType === MaintainJobCustomUpdateModes.RecurringUpdateMode;
    const completionDetailsNotRequired =
      currentJob && currentJob.quoteType?.dontRequireDriverWorkingHoursCompletionDetails;

    const isInContinuation = !!(currentJob && currentJob.continuation.continuationId);
    const isContinuingFrom = !!(currentJob && currentJob.continuation.jobStart);
    const isContinuingTo = !!(currentJob && currentJob.continuation.jobFinish);
    const vehicleSwappedFrom = !!(
      currentJob &&
      currentJob.isVehicleSwapped &&
      currentJob.vehicleSwapJobDetails?.start &&
      currentJob.vehicleSwapJobDetails.vehicleSwapFromJobId !== null
    );
    const vehicleSwappedTo = !!(
      currentJob &&
      currentJob.isVehicleSwapped &&
      currentJob.vehicleSwapJobDetails?.finish &&
      currentJob.vehicleSwapJobDetails.vehicleSwapToJobId !== null
    );

    const driverClockedOn =
      currentJob && currentJob.driverClockedOn
        ? DateTime.fromISO(currentJob.driverClockedOn)
        : undefined;
    const secondDriverClockedOn =
      currentJob && currentJob.secondDriverClockedOn
        ? DateTime.fromISO(currentJob.secondDriverClockedOn)
        : undefined;
    let minDate = driverClockedOn;
    if (driverClockedOn && secondDriverClockedOn && secondDriverClockedOn < driverClockedOn) {
      minDate = secondDriverClockedOn;
    }

    const driverClockedOff =
      currentJob && currentJob.driverClockedOff
        ? DateTime.fromISO(currentJob.driverClockedOff)
        : undefined;
    const secondDriverClockedOff =
      currentJob && currentJob.secondDriverClockedOff
        ? DateTime.fromISO(currentJob.secondDriverClockedOff)
        : undefined;
    let maxDate = driverClockedOff;
    if (driverClockedOff && secondDriverClockedOff && driverClockedOff < secondDriverClockedOff) {
      maxDate = secondDriverClockedOff;
    }

    const prepareShiftEnd = (values: any): any => {
      const jobTypeId = values.jobTypeId ?? jobModel?.job?.jobType.id;
      if (
        (jobTypeId !== JobType.Charter && jobTypeId !== JobType.ContractCharter) ||
        !values.shiftEnd ||
        !values.jobRoutes?.length
      ) {
        return values;
      }

      const lastRoute = values.jobRoutes?.filter(
        (route: JobRouteItem) => route.changeState !== ChangeState.Deleted
      )[values.jobRoutes.length - 1];

      if (!lastRoute?.arrive) {
        return values;
      }

      const shiftEnd = DateTime.fromISO(`${lastRoute.date}T${lastRoute.arrive}`, {
        zone: getStateTimezone(lastRoute),
      }).toISO();

      return { ...values, shiftEnd: shiftEnd };
    };

    const scheduleDateTimeWithOriginal = (
      d: IFieldData<string>,
      original: string | undefined,
      zone?: string,
      relocation?: boolean
    ) => {
      const dataAddr = d.fieldDataAddr[0];
      const clockOnFormatted = <DateTimeFormat value={d.fieldValue} timezone={zone ?? TIMEZONE} />;
      const originalFormatted = <DateTimeFormat value={original} timezone={zone ?? TIMEZONE} />;

      const originalDateTime = original && DateTime.fromISO(original);
      const currentDateTime = d.fieldValue && DateTime.fromISO(d.fieldValue);
      const hasOverride =
        !!originalDateTime && !!currentDateTime && !currentDateTime.equals(originalDateTime);

      const departDepotDateTime = DateTime.fromISO(d.paneValue.departDepot);
      const arriveDepotDateTime = DateTime.fromISO(d.paneValue.arriveDepot);

      if (
        relocation &&
        ((currentJob && isJobTypeCharterOrCharterStaged(currentJob.jobType.id)) ||
          isJobTypeCharterOrCharterStaged(primaryFormApi?.getValue(['jobType', 'id'])))
      ) {
        let relocationTime: string;
        switch (dataAddr) {
          case 'onSite':
            relocationTime =
              departDepotDateTime && currentDateTime
                ? humanizeDuration(currentDateTime.diff(departDepotDateTime).milliseconds)
                : '';
            break;
          case 'shiftEnd':
            relocationTime =
              arriveDepotDateTime && currentDateTime
                ? humanizeDuration(arriveDepotDateTime.diff(currentDateTime).milliseconds)
                : '';
            break;
          case 'shiftCommence':
            relocationTime =
              departDepotDateTime && currentDateTime
                ? humanizeDuration(currentDateTime.diff(departDepotDateTime).milliseconds)
                : '';
            break;
          default:
            relocationTime = '';
            break;
        }

        return (
          <div>
            {clockOnFormatted}
            <div className="schedule-original">
              <em>Relocation Time: </em>
              {relocationTime === '' || relocationTime === '0 seconds' ? ' - ' : relocationTime}
              {hasOverride && (
                <>
                  <br />
                  <em>Original: </em>
                  {originalFormatted}
                </>
              )}
            </div>
          </div>
        );
      } else if (!hasOverride) {
        return clockOnFormatted;
      } else {
        return (
          <div>
            {clockOnFormatted}
            <div className="schedule-original">
              <em>Original: </em>
              {originalFormatted}
            </div>
          </div>
        );
      }
    };

    const jobTitle = (
      <div className="maintain-job-title">
        Job {currentJob?.jobNumber}
        {/* Only show the badge when isDriverManaged and 
        has no fatigue breaks and no scheduled breaks*/}
        {currentJob?.isDriverManaged && !currentJob?.fatigueBreaks?.length && (
          <span className="driver-managed-pill">Driver Managed Fatigue</span>
        )}
      </div>
    );

    const firstRouteZone = getStateTimezone(currentJob?.jobRoutes[0]?.location);
    const lastRouteZone = getStateTimezone(
      currentJob?.jobRoutes
        ?.filter(route => route.changeState !== ChangeState.Deleted)
        ?.slice(-1)[0]?.location
    );
    const canUpdateAllForTrip =
      !isUpdateMode ||
      !currentJob ||
      currentJob.isTrainingJob ||
      !isJobTypeCharter(currentJob.jobType.id) ||
      !currentJob.jobsInTripCount ||
      currentJob.jobsInTripCount <= 1 ||
      !!currentJob.splitJobSiblings.length ||
      currentJob.nonSplitJobsInTripCount <= 1 ||
      currentJob.isVehicleSwapped;

    return {
      primarySize: PagePrimarySize.twoThirds,
      primarySection: {
        title: isUpdateMode ? jobTitle : 'Create Adhoc Job',
        badge:
          currentJob && currentJob.jobStatus && !currentJob.hasSubcontractor
            ? {
                label: `${currentJob.jobStatus.description}${
                  currentJob.needsVerification ? ' - Needs Verification' : ''
                }${currentJob.isCancelled ? ' - Cancelled' : ''}`,
              }
            : undefined,
        getApi: api => setFormApi(api),
        primaryActions: canManageJobs
          ? [
              {
                actions: [
                  {
                    actionType: ActionType.actionCollection,
                    hidden: updating || !isUpdateMode,
                    actionGroups: [
                      {
                        actions: [
                          {
                            actionType: ActionType.actionButton,
                            label: 'Edit all future recurring jobs',
                            hidden: d =>
                              !(d.actionValue as JobItem).quoteId ||
                              !(d.actionValue as JobItem).isRecurringJob,
                            icon: <EditIcon />,
                            onClick: () =>
                              beginCustomEditing(MaintainJobCustomUpdateModes.RecurringUpdateMode),
                          },
                          {
                            actionType: ActionType.actionButton,
                            label: 'Export Job Activities to Excel',
                            icon: <ExcelIcon fixedWidth />,
                            onClick: exportActivitiesToExcel,
                          },
                        ],
                      },
                      {
                        actions: [
                          {
                            actionType: ActionType.actionButton,
                            label: 'Reinstate Cancelled Job',
                            hidden: d =>
                              !d.actionValue.isCancelled && d.actionValue.quoteId !== undefined,
                            onClick: () => jobModel.restoreJob(jobId),
                          },
                        ],
                      },
                      ...getActionsDef(
                        currentJob,
                        api =>
                          consolidateTechSpecRequirements(
                            api.actionData.actionValue,
                            splittedTechSpecs
                          ),
                        jobModel.swapVehicle,
                        updating
                      ),
                    ],
                  },
                ],
              },
            ]
          : [],
        clearStandardSecondaryActions: true,
        secondaryActions:
          editable && canManageJobs
            ? !(updateType === MaintainJobCustomUpdateModes.RecurringUpdateMode)
              ? [
                  {
                    actions: [
                      {
                        actionType: ActionType.submitActionButton,
                        label: 'Update All For Trip',
                        level: 'primary',
                        hidden: canUpdateAllForTrip,
                        confirmationModalSize: ShellModalSize.oneThird,
                        confirmationModalDef: modalDefApi => ({
                          title: 'Update All Jobs For This Trip',
                          asForm: true,
                          panels: [
                            {
                              panes: [
                                {
                                  paneType: PaneType.customPane,
                                  render: () => {
                                    return (
                                      <div>
                                        <p>
                                          All Jobs for this Trip will be updated to match this Job.
                                        </p>
                                        <p>
                                          Any custom change in the individual Jobs will be
                                          overwritten, except:
                                        </p>
                                        <p>
                                          ○ Staff Member Requirements <br></br>○ Vehicle
                                          Requirements <br></br>○ Driver and vehicle allocations
                                        </p>
                                        {currentJob!.splitJobsInTripCount > 0 ? (
                                          <p>
                                            Note that this action does not affect split jobs, of
                                            which there are {currentJob!.splitJobsInTripCount} in
                                            this trip.
                                          </p>
                                        ) : null}
                                        <p>
                                          <span>Are you sure you want to update all </span>
                                          <span>{currentJob!.nonSplitJobsInTripCount} </span>
                                          <span>Jobs?</span>
                                        </p>
                                      </div>
                                    );
                                  },
                                },
                              ],
                            },
                          ],
                          secondaryActions: [
                            getSubmitCloseModalActionGroupDef(
                              `Update ${currentJob!.nonSplitJobsInTripCount} Jobs`
                            ),
                          ],
                          onFormSubmit: () => {
                            return modalDefApi.parentFormApi.submitForm({
                              bulkUpdate: JobBulkUpdateType.JobForTripsFromSameBooking,
                            });
                          },
                        }),
                        subActionGroups: getSkipFatigueActionGroupDef<ISubmissionMeta>(
                          canSkipFatigueValidation,
                          {
                            label: 'Update All For Trip and Skip Fatigue Validation',
                            meta: {
                              bulkUpdate: JobBulkUpdateType.JobForTripsFromSameBooking,
                            },
                            customRender: () => {
                              return (
                                <div>
                                  <p>
                                    All Jobs for this Trip will be updated to match this Job. Any
                                    custom change in the individual Jobs will be overwritten, except
                                    for the allocated vehicle and driver.
                                  </p>
                                  <p>
                                    The fatigue validation for all jobs will be skipped for this
                                    update. This skip will be recorded in job activity log.
                                  </p>
                                  <p>
                                    <span>Are you sure you want to update all </span>
                                    <span>{currentJob!.jobsInTripCount} </span>
                                    <span>Jobs and skip fatigue validation?</span>
                                  </p>
                                </div>
                              );
                            },
                          }
                        ),
                      },
                      {
                        actionType: ActionType.submitActionButton,
                        label: 'Submit & Keep Acknowledgement',
                        level: 'primary',
                        hidden: d =>
                          staffMembersHaveBeenChanged(d.actionValue as JobItem) ||
                          !isStatusAcknowledged,
                        submissionMeta: { keepAcknowledgement: true },
                        subActionGroups: getSkipFatigueActionGroupDef<ISubmissionMeta>(
                          canSkipFatigueValidation,
                          {
                            label: 'Submit, Keep Acknowledgement and Skip Fatigue Validation',
                            meta: {
                              keepAcknowledgement: true,
                            },
                          }
                        ),
                      },
                      {
                        actionType: ActionType.submitActionButton,
                        level: 'primary',
                        subActionGroups: getSkipFatigueActionGroupDef<ISubmissionMeta>(
                          canSkipFatigueValidation
                        ),
                      },
                      {
                        actionType: ActionType.resetActionButton,
                      },
                    ],
                  },
                ]
              : [
                  {
                    actions: [
                      {
                        actionType: ActionType.submitActionButton,
                        label: 'Update all future jobs',
                        level: 'primary',
                        hidden: _ => !isUpdateMode,
                        confirmationModalSize: ShellModalSize.oneThird,
                        confirmationModalDef: modalDefApi => ({
                          title: 'Update All Jobs For This Trip And Recurring Trips',
                          asForm: true,
                          panels: [
                            {
                              panes: [
                                {
                                  paneType: PaneType.customPane,
                                  render: () => {
                                    return (
                                      <div>
                                        <p>
                                          All jobs for this trip and future trips from recurring
                                          bookings will be updated to match this job. Any custom
                                          change in the individual jobs will be overwritten, except
                                          for the allocated vehicle and driver unless specified
                                          differently below.
                                        </p>
                                        <p>
                                          <span>Are you sure you want to update all jobs?</span>
                                        </p>
                                      </div>
                                    );
                                  },
                                },
                                {
                                  paneType: PaneType.formFieldsPane,
                                  columnCount: 1,
                                  fields: [
                                    {
                                      fieldType: FieldType.yesNoField,
                                      label:
                                        'Overwrite driver/subcontractor allocations in future jobs?',
                                      dataAddr: 'bulkUpdateAllocations',
                                      mandatory: true,
                                    },
                                    {
                                      fieldType: FieldType.yesNoField,
                                      label: 'Overwrite vehicle allocations in future jobs?',
                                      dataAddr: 'bulkUpdateAssets',
                                      mandatory: true,
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                          secondaryActions: [
                            getSubmitCloseModalActionGroupDef(`Update all future jobs`),
                          ],
                          onFormSubmit: values => {
                            return modalDefApi.parentFormApi.submitForm({
                              bulkUpdate:
                                JobBulkUpdateType.JobsForTripsFromSameAndFutureRecurringBookings,
                              bulkUpdateAllocations: values.bulkUpdateAllocations,
                              bulkUpdateAssets: values.bulkUpdateAssets,
                            });
                          },
                        }),
                        subActionGroups: [
                          {
                            actions: [
                              {
                                actionType: ActionType.modalActionButton,
                                label: 'Update All For Recurring Trip and Skip Fatigue Validation',
                                modalSize: ShellModalSize.oneThird,
                                modalDef: modalDefApi => ({
                                  title:
                                    'Update All For Recurring Trip and Skip Fatigue Validation',
                                  asForm: true,
                                  panels: [
                                    {
                                      panes: [
                                        {
                                          paneType: PaneType.customPane,
                                          render: () => {
                                            return (
                                              <div>
                                                <p>
                                                  All jobs for this trip and future trips from
                                                  recurring bookings will be updated to match this
                                                  job. Any custom change in the individual jobs will
                                                  be overwritten, except for the allocated vehicle
                                                  and driver unless specified differently below.
                                                </p>
                                                <p>
                                                  The fatigue validation for all jobs will be
                                                  skipped for this update. This skip will be
                                                  recorded in job activity log.
                                                </p>
                                                <p>
                                                  <span>
                                                    Are you sure you want to update all jobs?
                                                  </span>
                                                </p>
                                              </div>
                                            );
                                          },
                                        },
                                        {
                                          paneType: PaneType.formFieldsPane,
                                          columnCount: 1,
                                          fields: [
                                            {
                                              fieldType: FieldType.yesNoField,
                                              label:
                                                'Overwrite driver/subcontractor allocations in future jobs?',
                                              dataAddr: 'bulkUpdateAllocations',
                                              mandatory: true,
                                            },
                                            {
                                              fieldType: FieldType.yesNoField,
                                              label:
                                                'Overwrite vehicle allocations in future jobs?',
                                              dataAddr: 'bulkUpdateAssets',
                                              mandatory: true,
                                            },
                                          ],
                                        },
                                      ],
                                    },
                                  ],
                                  secondaryActions: [
                                    getSubmitCloseModalActionGroupDef(`Update all future jobs`),
                                  ],
                                  onFormSubmit: values => {
                                    return modalDefApi.parentFormApi.submitForm({
                                      skipFatigueValidation: true,
                                      bulkUpdate:
                                        JobBulkUpdateType.JobsForTripsFromSameAndFutureRecurringBookings,
                                      bulkUpdateAllocations: values.bulkUpdateAllocations,
                                      bulkUpdateAssets: values.bulkUpdateAssets,
                                    });
                                  },
                                }),
                              },
                            ],
                          },
                        ],
                      },
                      {
                        actionType: ActionType.resetActionButton,
                      },
                    ],
                  },
                ]
            : [],
        panels: formApi => [
          getJobDetailPanelDef(
            isUpdateMode,
            isCreateMode,
            () => updateType,
            shiftRequiresClockOnOffTimesOnly,
            railModel.railTemplatesForDropdown.items,
            railModel.railTemplate.loadRailTemplate,
            railModel.railTemplate.railTemplate,
            railModel.railBookings.bookedDropdownRailBookings,
            railModel.railBookings.allDropdownRailBookings,
            urbanModel.shift.getShift,
            bookingsListModel.searchBookings,
            quoteModel.item,
            quoteModel.getQuote,
            currentJob?.railBookingId,
            downloadDriverPdf,
            searchableContracts,
            urbanModel.shifts.getShiftsByContract,
            urbanModel.shifts.getShifts,
            shiftOptions,
            setShiftOptions,
            shiftListOptions,
            setShiftListOptions,
            () => {
              onShiftChanged = true;
            },
            jobSubTypes
          ),
          {
            title: 'Allocations',
            panes: [
              {
                paneType: PaneType.formFieldsPane,
                columnCount: 2,
                fields: [
                  {
                    fieldType: FieldType.yesNoField,
                    label: 'Has Subcontractor?',
                    dataAddr: 'hasSubcontractor',
                    mandatory: true,
                    hidden: d =>
                      d.panelValue.jobType && !canJobTypeHaveSubcontractor(d.panelValue.jobType.id),
                    onChange: (api: IFieldOnChange<boolean>) => {
                      if (api.newFieldValue) {
                        api.setFormValues({
                          ...api.formValues,
                          asset: undefined,
                          staffMember: undefined,
                          hasSecondStaffMember: false,
                          secondStaffMember: undefined,
                        });
                      } else {
                        api.setFormValues({
                          ...api.formValues,
                          subcontractor: undefined,
                        });
                      }
                    },
                  },
                  {
                    fieldType: FieldType.selectField,
                    label: 'Subcontractor',
                    dataAddr: 'subcontractor',
                    optionItems: subcontractorsModel.allSubcontractors,
                    valueKey: 'id',
                    descriptionKey: 'name',
                    hidden: d =>
                      (d.panelValue.jobType &&
                        !canJobTypeHaveSubcontractor(d.panelValue.jobType.id)) ||
                      !d.panelValue.hasSubcontractor,
                    linkTo: (d: IFieldData<AssetItem>) =>
                      d.fieldValue ? `/operations/subcontractors/${d.fieldValue.id}` : undefined,
                  },
                ],
              },
              {
                paneType: PaneType.formFieldsPane,
                columnCount: 2,
                fields: [
                  {
                    fieldType: FieldType.customField,
                    dataAddr: 'none1',
                    label: '',
                    hidden: d =>
                      isJobTypeDriverRelocation(
                        (d.paneValue as JobItem).jobType && (d.paneValue as JobItem).jobType.id
                      ),
                    render: p => {
                      const panelJob = p.data.panelValue as JobItem;
                      if (!panelJob) {
                        return null;
                      } else {
                        const hasProgress =
                          p.data.panelValue.jobProgressItems &&
                          p.data.panelValue.jobProgressItems.length > 0;
                        return (
                          <div className="field-combo">
                            <PageField
                              fieldDef={{
                                fieldType: FieldType.assetSelectField,
                                label: 'Vehicle',
                                dataAddr: AssetKey,
                                optionItems: assetsModel.fleetAssetListItems,

                                valueKey: 'id',
                                descriptionKey: 'name',
                                tooltip: hasProgress
                                  ? 'This job has tablet progresses. Use "Swap Vehicle" in the triple dot menu to change vehicle'
                                  : undefined,
                                hidden: d =>
                                  (d.panelValue.jobType &&
                                    isJobTypeWithoutAsset(d.panelValue.jobType.id)) ||
                                  d.panelValue.hasSubcontractor,
                                readonly: d =>
                                  d.panelValue.isTrainingJob ||
                                  (d.panelValue.jobProgressItems &&
                                    d.panelValue.jobProgressItems.length > 0),
                                staffMemberId: d =>
                                  d.parentValue.staffMember && d.parentValue.staffMember.id,
                                secondStaffMemberId: d =>
                                  d.parentValue.secondStaffMember &&
                                  d.parentValue.secondStaffMember.id,
                                techSpecRequirements: d =>
                                  consolidateTechSpecRequirements(d.parentValue, splittedTechSpecs),
                              }}
                              fieldMeta={p.meta}
                              paneData={p.data}
                              parentValue={p.data.parentValue}
                            />
                            {hasProgress && editable && (
                              <div>
                                <label>&nbsp;</label>
                                <div className="has-tablet-progress-warning">
                                  Job has Tablet Progress. Use 'Swap Vehicle in progress' in the
                                  Triple Dot Menu.
                                </div>
                              </div>
                            )}
                          </div>
                        );
                      }
                    },
                  },
                  {
                    fieldType: FieldType.selectField,
                    label: d =>
                      `${
                        !isJobTypeContractCharter(d.panelValue.jobType && d.panelValue.jobType.id)
                          ? 'Quoted'
                          : ''
                      } Vehicle Type`,
                    dataAddr: 'vehicleType',
                    optionItems: vehicleTypesModel.vehicleTypes,
                    valueKey: 'id',
                    descriptionKey: 'description',
                    mandatory: true,
                    readonly: d => isJobTypeContractCharter(d.paneValue.jobType.id),
                    hidden: d =>
                      !isJobTypeCharterOrContractCharter(
                        (d.paneValue as JobItem).jobType && (d.paneValue as JobItem).jobType.id
                      ),
                  },
                ],
              },
              {
                paneType: PaneType.formFieldsPane,
                columnCount: 2,
                fields: [
                  {
                    fieldType: FieldType.staffMemberField,
                    label: 'Staff Member',
                    dataAddr: 'staffMember',
                    hidden: d => !d.panelValue.jobType || d.panelValue.hasSubcontractor,
                    staffMemberFilter: d => getStaffMemberFilterForJobType(d.panelValue.jobType.id),
                    assetId: d => d.parentValue.asset && d.parentValue.asset.id,
                    skillSpecRequirements: d => consolidateSkillSpecRequirementsIds(d.parentValue),
                    assetLicenceClassId: d => d.parentValue.asset?.licenceClassId,
                  },
                  {
                    fieldType: FieldType.yesNoField,
                    label: 'Two-up job? (Please Note: Two-up fatigue rules will apply)',
                    dataAddr: 'hasSecondStaffMember',
                    mandatory: true,
                    hidden: d => {
                      const j = d.panelValue as JobItem;
                      return j.hasSubcontractor || !allowSecondDriver(j.jobType);
                    },
                    onChange: (api: IFieldOnChange<boolean>) => {
                      if (!api.newFieldValue) {
                        api.setFormValue(['secondStaffMember'], undefined);
                      }
                    },
                  },
                  {
                    fieldType: FieldType.staffMemberField,
                    label: 'Second Staff Member',
                    dataAddr: 'secondStaffMember',
                    hidden: d => {
                      const j = d.panelValue as JobItem;
                      return !j.hasSecondStaffMember;
                    },
                    staffMemberFilter: d =>
                      getStaffMemberFilterForJobType(
                        d.panelValue.jobType && d.panelValue.jobType.id
                      ),
                    assetId: d => d.parentValue.asset && d.parentValue.asset.id,
                    skillSpecRequirements: d => consolidateSkillSpecRequirementsIds(d.parentValue),
                    assetLicenceClassId: d => d.parentValue.asset?.licenceClassId,
                    validate: d => {
                      const secondary = d.fieldValue as StaffMemberDto | undefined;
                      const primary = d.parentValue.staffMember as StaffMemberDto | undefined;
                      return primary && secondary && primary.id === secondary.id
                        ? 'The same Staff Member cannot be allocated twice'
                        : undefined;
                    },
                  },
                ],
              },
            ],
          },
          {
            title: 'Booking',
            hidden: d => {
              const j = d.panelValue as JobItem;
              return !j || !doesJobTypeHaveBooking(j.jobType && j.jobType.id);
            },
            panes: [
              {
                paneType: PaneType.formFieldsPane,
                columnCount: 4,
                hidden: d => isJobTypeContractCharter(d.paneValue.jobType.id),
                fields: [
                  {
                    fieldType: FieldType.readonlyField,
                    label: 'Booking Number',
                    dataAddr: 'quoteNumber',
                    linkTo: d => `/operations/bookings-for-ops/${d.paneValue.quoteId}`,
                  },
                  {
                    fieldType: FieldType.readonlyField,
                    label: 'Quote Type',
                    dataAddr: ['quoteType', 'description'],
                  },
                  {
                    fieldType: FieldType.selectField,
                    label: 'Trip Number',
                    dataAddr: 'tripNumber',
                    valueKey: 'value',
                    descriptionKey: 'label',
                    useValueOnly: true,
                    mandatory: true,
                    readonly: true,
                    optionItems: d => {
                      return new Array(d.paneValue.tripCount).fill(null).map((_, i) => ({
                        value: i,
                        label: `${i + 1} of ${d.paneValue.tripCount}`,
                      }));
                    },
                  },
                  {
                    fieldType: FieldType.readonlyField,
                    label: 'Trip Passengers',
                    dataAddr: 'tripPassengerCount',
                  },
                ],
              },
              {
                paneType: PaneType.formFieldsPane,
                columnCount: 2,
                hidden: d => isJobTypeContractCharter(d.paneValue.jobType.id),
                fields: [
                  {
                    fieldType: FieldType.readonlyField,
                    label: 'Customer',
                    dataAddr: ['charterCustomer', 'name'],
                    linkTo: d => {
                      return `/sales/customers/${d.paneValue.charterCustomer &&
                        d.paneValue.charterCustomer.id}`;
                    },
                  },
                  {
                    fieldType: FieldType.readonlyField,
                    label: 'Contact',
                    dataAddr: ['charterCustomer', 'contactName'],
                  },
                ],
              },
              {
                paneType: PaneType.formFieldsPane,
                hidden: d => isJobTypeContractCharter(d.paneValue.jobType.id),
                fields: [
                  {
                    fieldType: FieldType.readonlyField,
                    label: 'Selected Trip Option',
                    formatReadonly: d => {
                      const option =
                        d.sectionValue && (d.sectionValue as JobItem).selectedOptionSummary;
                      if (!option) {
                        return null;
                      }
                      const value = [option.vehicleSummary, option.extraSummary]
                        .filter(x => !!x)
                        .join(' • ');
                      return (
                        <span>
                          {option.title}: <strong>{value}</strong>
                        </span>
                      );
                    },
                  },
                ],
              },
              {
                paneType: PaneType.nestingPane,
                hidden: d =>
                  !d.panelValue ||
                  !isJobTypeAnyCharter(
                    (d.panelValue as JobItem).jobType && (d.panelValue as JobItem).jobType.id
                  ),
                dataAddr: 'jobExtras',
                panes: [
                  {
                    paneType: PaneType.tablePane,
                    title: 'Facilities',
                    dataRequiredForRows: 'paneValue',
                    noRowsMessage: 'This Job has no required facilities',
                    fields: [
                      {
                        fieldType: FieldType.selectField,
                        dataAddr: 'extraType',
                        label: 'Facility Type',
                        valueKey: 'id',
                        descriptionKey: 'description',
                        optionItems: extraTypesModel.list.charterExtraTypes,
                        mandatory: true,
                        readonly: d => d.fieldValue?.id,
                        valuesToExclude: () =>
                          extraTypesModel.list.charterExtraTypes
                            .filter(t => !t.isActive)
                            .map(t => t.id),
                        valuesToDisable: d => {
                          const extras = d.paneValue as JobExtraItem[] | undefined;
                          return (extras
                            ? extras.map(v => v.extraType && v.extraType.id)
                            : []
                          ).filter(isDefined);
                        },
                      },
                      {
                        fieldType: FieldType.numericField,
                        dataAddr: 'quantity',
                        numericConfig: { numericType: 'unsignedInt' },
                        label: 'Quantity for this Job',
                        mandatory: true,
                        readonly:
                          editable &&
                          updateType === MaintainJobCustomUpdateModes.RecurringUpdateMode,
                        columnWidth: '12em',
                      },
                      {
                        fieldType: FieldType.actionListField,
                        hidden:
                          !editable ||
                          updateType === MaintainJobCustomUpdateModes.RecurringUpdateMode,
                        columnWidth: '1px',
                        actionGroups: [
                          {
                            actions: [
                              {
                                actionType: ActionType.removeArrayItemActionButton,
                                label: 'Remove Facility',
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    paneType: PaneType.actionListPane,
                    hidden:
                      !editable || updateType === MaintainJobCustomUpdateModes.RecurringUpdateMode,
                    actionGroups: [
                      {
                        actions: [
                          {
                            actionType: ActionType.addArrayItemActionButton,
                            label: 'Add Facility',
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
              getTripRoutesPaneDef('jobRoutes', {
                editable:
                  !isTrainingJob &&
                  editable &&
                  updateType !== MaintainJobCustomUpdateModes.RecurringUpdateMode,
                states: boardingPointModel.states,
                initialArrival: 'initialArrivalMandatory',
                searchBoardingPoints: boardingPointModel.searchBoardingPoints,
                boardingPoints: boardingPointModel.foundBoardingPoints,
                checkForUniqueBoardingPointName: boardingPointModel.checkForUniqueName,
                onCreateBoardingPoint: cmd => boardingPointModel.createBoardingPoint(cmd, true),
                splitJob:
                  updateType !== MaintainJobCustomUpdateModes.RecurringUpdateMode
                    ? jobModel.splitJob
                    : undefined,
                onArriveChange: (value, row) => {
                  var addr = row === 'first' ? 'onSite' : 'shiftEnd';
                  primaryFormApi && primaryFormApi.setValue(addr, value && value.toISO());
                },
                onDepartChange: (value, row) => {
                  if (row === 'first') {
                    primaryFormApi &&
                      primaryFormApi.setValue('shiftCommence', value && value.toISO());
                  }
                },
                onArriveValidate: (_, datetime, row) => {
                  const departDepot = primaryFormApi && primaryFormApi.getValue('departDepot');

                  const originalDepartDepot =
                    primaryFormApi && primaryFormApi.getValue('originalDepartDepot');

                  const originalOnSite =
                    primaryFormApi && primaryFormApi.getValue('originalOnSite');
                  const ignoreValidation = !!originalDepartDepot || !!originalOnSite;

                  if (row === 'first' && datetime && departDepot && !ignoreValidation) {
                    return validateDateTimeIsNotLessThan(
                      datetime.toISO(),
                      'On site',
                      departDepot,
                      'depart depot'
                    );
                  }
                  return undefined;
                },
                depots: depotModel.depots,
                hidden:
                  formApi.values &&
                  formApi.values.jobType &&
                  !isJobTypeAnyCharter(formApi.values.jobType.id),
                isTrainingJob,
              }),
            ],
          },
          {
            title: 'Shift Details',
            hidden: d =>
              !d.panelValue ||
              !d.panelValue.jobType ||
              (d.panelValue.jobType && !isRailJobType(d.panelValue.jobType)),
            panes: [
              getShiftRoutesPaneDef('routeGroups', {
                formApi,
                states: boardingPointModel.states,
                searchBoardingPoints: boardingPointModel.searchBoardingPoints,
                checkForUniqueBoardingPointName: boardingPointModel.checkForUniqueName,
                onCreateBoardingPoint: cmd => boardingPointModel.createBoardingPoint(cmd, true),
                depots: depotModel.depots,
                updating:
                  updating && updateType !== MaintainJobCustomUpdateModes.RecurringUpdateMode,
              }),
            ],
          },
          {
            title: 'Adhoc',
            hidden: () => !isAdhoc,
            panes: [
              {
                paneType: PaneType.formFieldsPane,
                columnCount: 2,
                fields: [
                  {
                    fieldType: FieldType.selectField,
                    label: 'Adhoc Reason',
                    dataAddr: ['adhocReason', 'id'],
                    valueKey: 'value',
                    descriptionKey: 'description',
                    useValueOnly: true,
                    mandatory: true,
                    optionItems: allAdhocReason,
                    readonly: isUpdatingRecurringUpdateMode,
                  },
                  {
                    fieldType: FieldType.textField,
                    label: 'Adhoc Reason Details',
                    dataAddr: 'adhocReasonDetails',
                    mandatory: d =>
                      d.parentValue.adhocReason &&
                      d.parentValue.adhocReason.id === AdhocReason.Other,
                    readonly: isUpdatingRecurringUpdateMode,
                  },
                ],
              },
            ],
          },
          {
            title: 'Schedule',
            panes: [
              {
                paneType: PaneType.customPane,
                hidden: !updating || !isInContinuation,
                render: () => {
                  return (
                    <div className="inline-info">
                      <InfoIcon /> Remove any continuation links to allow job times to be changed
                    </div>
                  );
                },
              },
              {
                paneType: PaneType.formFieldsPane,
                columnCount: 3,
                fields: [
                  {
                    fieldType: FieldType.dateTimeField,
                    label: 'Start',
                    dataAddr: 'continuation.jobStart',
                    timezone: firstRouteZone,
                    readonly: true,
                    hidden: !isContinuingFrom,
                  },
                  {
                    fieldType: FieldType.dateTimeField,
                    label: 'Start',
                    dataAddr: 'vehicleSwapJobDetails.start',
                    timezone: firstRouteZone,
                    readonly: true,
                    hidden: !vehicleSwappedFrom,
                  },
                  {
                    fieldType: FieldType.dateTimeField,
                    label: 'Clock On',
                    dataAddr: 'clockOn',
                    timezone: TIMEZONE,
                    mandatory: d =>
                      isMandatoryGivenVehicleChangeAllowed(currentJob, d.parentValue as JobItem),
                    hidden: isContinuingFrom || vehicleSwappedFrom,
                    readonly: isInContinuation || currentJob?.isVehicleSwapped,
                    formatReadonly: d =>
                      scheduleDateTimeWithOriginal(d, d.panelValue.originalClockOn),
                  },
                  {
                    fieldType: FieldType.dateTimeField,
                    label: 'Depart Depot',
                    dataAddr: 'departDepot',
                    timezone: TIMEZONE,
                    mandatory: d =>
                      isMandatoryGivenVehicleChangeAllowed(currentJob, d.parentValue as JobItem),
                    hidden: d =>
                      isContinuingFrom ||
                      vehicleSwappedFrom ||
                      requiresClockOnOffTimesOnly(d.panelValue.jobType, d.panelValue.isTrainingJob),
                    readonly: isInContinuation,
                    validate: d =>
                      requiresClockOnOffTimesOnly(d.panelValue.jobType, d.panelValue.isTrainingJob)
                        ? undefined
                        : validateDateTimeIsNotLessThan(
                            d.parentValue.departDepot,
                            'Depart depot',
                            d.parentValue.clockOn,
                            'clock on'
                          ),
                    onChange: api => {
                      if (onShiftChanged) {
                        onShiftChanged = false;
                        return;
                      }
                      const departDepot = DateTime.fromISO(api.newFieldValue);
                      const shiftClockOn = api.formValues.shiftName?.clockOn;

                      if (departDepot.isValid && !shiftClockOn) {
                        api.setFormValue(
                          ['clockOn'],
                          departDepot.minus(
                            Duration.fromObject({ minutes: JOB_CLOCK_ON_MINS_BUFFER })
                          )
                        );
                      }
                    },
                    formatReadonly: d =>
                      scheduleDateTimeWithOriginal(d, d.panelValue.originalDepartDepot),
                  },
                  {
                    fieldType: FieldType.dateTimeField,
                    label: 'On Site',
                    dataAddr: 'onSite',
                    timezone: firstRouteZone,
                    mandatory: true,
                    hidden: d =>
                      !isJobTypeAnyCharter(d.parentValue.jobType && d.parentValue.jobType.id),
                    readonly: d =>
                      isInContinuation ||
                      isJobTypeAnyCharter(d.parentValue.jobType && d.parentValue.jobType.id),
                    validate: d =>
                      !isJobTypeAnyCharter(d.parentValue.jobType && d.parentValue.jobType.id)
                        ? undefined
                        : validateDateTimeIsNotLessThan(
                            d.parentValue.onSite,
                            'On site',
                            d.parentValue.departDepot,
                            'depart depot'
                          ),
                    formatReadonly: d =>
                      scheduleDateTimeWithOriginal(
                        d,
                        d.panelValue.originalOnSite,
                        firstRouteZone,
                        true
                      ),
                  },
                  {
                    fieldType: FieldType.dateTimeField,
                    label: d =>
                      isJobTypeAnyCharter(d.parentValue.jobType && d.parentValue.jobType.id)
                        ? 'Pick Up'
                        : 'Shift Commence',
                    dataAddr: 'shiftCommence',
                    timezone: firstRouteZone,
                    hidden: d =>
                      requiresClockOnOffTimesOnly(d.paneValue.jobType, d.panelValue.isTrainingJob),
                    readonly: d =>
                      isInContinuation ||
                      isJobTypeAnyCharter(d.parentValue.jobType && d.parentValue.jobType.id),
                    mandatory: true,
                    validate: d => {
                      return requiresClockOnOffTimesOnly(
                        d.paneValue.jobType,
                        d.panelValue.isTrainingJob
                      )
                        ? undefined
                        : isJobTypeAnyCharter(d.parentValue.jobType && d.parentValue.jobType.id)
                        ? validateDateTimeIsNotLessThan(
                            d.parentValue.shiftCommence,
                            'Shift commence',
                            d.parentValue.onSite,
                            'on site'
                          )
                        : validateDateTimeIsNotLessThan(
                            d.parentValue.shiftCommence,
                            'Shift commence',
                            d.parentValue.departDepot,
                            'depart depot'
                          );
                    },
                    formatReadonly: d =>
                      scheduleDateTimeWithOriginal(
                        d,
                        d.panelValue.originalShiftCommence,
                        firstRouteZone,
                        true
                      ),
                  },
                ],
              },
              {
                paneType: PaneType.formFieldsPane,
                columnCount: 3,
                fields: [
                  {
                    fieldType: FieldType.dateTimeField,
                    label: d =>
                      isJobTypeAnyCharter(d.paneValue.jobType && d.paneValue.jobType.id)
                        ? 'Drop Off'
                        : 'Shift End',
                    dataAddr: 'shiftEnd',
                    timezone: lastRouteZone,
                    mandatory: true,
                    hidden: d =>
                      requiresClockOnOffTimesOnly(d.paneValue.jobType, d.panelValue.isTrainingJob),
                    readonly: d =>
                      isInContinuation ||
                      isJobTypeAnyCharter(d.paneValue.jobType && d.paneValue.jobType.id),
                    validate: d =>
                      requiresClockOnOffTimesOnly(d.paneValue.jobType, d.panelValue.isTrainingJob)
                        ? undefined
                        : validateDateTimeIsNotLessThan(
                            d.parentValue.shiftEnd,
                            'Shift end',
                            d.parentValue.shiftCommence,
                            'shift commence'
                          ),
                    formatReadonly: d =>
                      scheduleDateTimeWithOriginal(
                        d,
                        d.panelValue.originalShiftEnd,
                        lastRouteZone,
                        true
                      ),
                  },
                  {
                    fieldType: FieldType.dateTimeField,
                    label: 'Arrive Depot',
                    dataAddr: 'arriveDepot',
                    timezone: TIMEZONE,
                    mandatory: d =>
                      isMandatoryGivenVehicleChangeAllowed(currentJob, d.parentValue as JobItem),
                    hidden: d =>
                      isContinuingTo ||
                      vehicleSwappedTo ||
                      requiresClockOnOffTimesOnly(d.paneValue.jobType, d.panelValue.isTrainingJob),
                    readonly: isInContinuation,
                    validate: d =>
                      requiresClockOnOffTimesOnly(d.paneValue.jobType, d.panelValue.isTrainingJob)
                        ? undefined
                        : validateDateTimeIsNotLessThan(
                            d.parentValue.arriveDepot,
                            'Arrive depot',
                            d.parentValue.shiftEnd,
                            'shift end'
                          ),
                    formatReadonly: d =>
                      scheduleDateTimeWithOriginal(d, d.panelValue.originalArriveDepot),
                  },
                  {
                    fieldType: FieldType.dateTimeField,
                    label: 'Clock Off',
                    dataAddr: 'clockOff',
                    timezone: TIMEZONE,
                    mandatory: d =>
                      isMandatoryGivenVehicleChangeAllowed(currentJob, d.parentValue as JobItem),
                    hidden: isContinuingTo || vehicleSwappedTo,
                    readonly: isInContinuation,
                    validate: d => {
                      const clockTimesOnly = requiresClockOnOffTimesOnly(
                        d.paneValue.jobType,
                        d.panelValue.isTrainingJob
                      );
                      const validateOffTime = validateDateTimeIsNotLessThan(
                        d.parentValue.clockOff,
                        'Clock off',
                        clockTimesOnly ? d.parentValue.clockOn : d.parentValue.arriveDepot,
                        clockTimesOnly ? 'Clock on' : 'Arrive depot'
                      );
                      if (validateOffTime) {
                        return validateOffTime;
                      }

                      const clockOn = DateTime.fromISO(d.parentValue.clockOn);
                      const clockOff = DateTime.fromISO(d.parentValue.clockOff);
                      const clockOnTimeDiff = clockOff.diff(clockOn, ['months']);

                      if (clockOnTimeDiff.months > jobDurationLimitInMonths) {
                        return 'Job length cannot exceed six months';
                      }

                      return DateTime.fromISO(d.parentValue.clockOn).equals(
                        DateTime.fromISO(d.parentValue.clockOff)
                      )
                        ? 'Total time cannot be zero'
                        : undefined;
                    },
                    formatReadonly: d =>
                      scheduleDateTimeWithOriginal(d, d.panelValue.originalClockOff),
                  },
                  {
                    fieldType: FieldType.dateTimeField,
                    label: 'Finish',
                    dataAddr: 'continuation.jobFinish',
                    timezone: lastRouteZone,
                    readonly: true,
                    hidden: !isContinuingTo,
                  },
                  {
                    fieldType: FieldType.dateTimeField,
                    label: 'Finish',
                    dataAddr: ['vehicleSwapJobDetails', 'finish'],
                    timezone: lastRouteZone,
                    readonly: true,
                    hidden: !vehicleSwappedTo,
                  },
                ],
              },
              {
                paneType: PaneType.formFieldsPane,
                columnCount: 3,
                fields: [
                  {
                    fieldType: FieldType.readonlyField,
                    label: 'Total Time',
                    formatReadonly: data => {
                      const j = data.parentValue as JobItem;
                      const continuation = j.continuation || {};
                      const vehicleSwap = j.vehicleSwapJobDetails || {};
                      const start =
                        vehicleSwap.start ||
                        continuation.jobStart ||
                        j.clockOn ||
                        j.onSite ||
                        j.shiftCommence;
                      const finish =
                        vehicleSwap.finish || continuation.jobFinish || j.clockOff || j.shiftEnd;

                      return totalJobHours(start, finish);
                    },
                  },
                  {
                    fieldType: FieldType.durationField,
                    label: 'Unpaid Breaks',
                    dataAddr: 'unpaidBreaks',
                    mandatory: true,
                    formatReadonly: d =>
                      getEditingFormattedTimeString(parseEditingFormattedTimeString(d.fieldValue)),
                    validate: data => {
                      const j = data.parentValue as JobItem;
                      const continuation = j.continuation || {};
                      const vehicleSwap = j.vehicleSwapJobDetails || {};
                      const start =
                        vehicleSwap.start ||
                        continuation.jobStart ||
                        j.clockOn ||
                        j.onSite ||
                        j.shiftCommence;
                      const finish =
                        vehicleSwap.finish || continuation.jobFinish || j.clockOff || j.shiftEnd;
                      const workingHours = workingJobHours(start, finish, j.unpaidBreaks);
                      return !workingHours || workingHours.valueOf() <= 0
                        ? 'Unpaid breaks must be less than total time'
                        : undefined;
                    },
                  },
                  {
                    fieldType: FieldType.readonlyField,
                    label: 'Working Time',
                    formatReadonly: data => {
                      const j = data.parentValue as JobItem;
                      const continuation = j.continuation || {};
                      const vehicleSwap = j.vehicleSwapJobDetails || {};
                      const start =
                        (vehicleSwap.start ?? j.clockOn) ||
                        continuation.jobStart ||
                        j.clockOn ||
                        j.onSite ||
                        j.shiftCommence;
                      const finish =
                        vehicleSwap.finish || continuation.jobFinish || j.clockOff || j.shiftEnd;
                      return formattedWorkingJobHours(start, finish, j.unpaidBreaks);
                    },
                  },
                  {
                    fieldType: FieldType.readonlyField,
                    label: 'Paid Time',
                    hidden: updating,
                    formatReadonly: () => {
                      return jobModel.paidHours &&
                        jobModel.paidHours.isValid &&
                        currentJob &&
                        currentJob.id === jobId ? (
                        <DurationFormat value={jobModel.paidHours} />
                      ) : (
                        undefined
                      );
                    },
                  },
                ],
              },
            ],
          },
          {
            title: 'Vehicle Movements',
            hidden: d => !d.panelValue.jobType || isJobTypeWithoutAsset(d.panelValue.jobType.id),
            panes: [
              {
                paneType: PaneType.formFieldsPane,
                columnCount: 4,
                fields: [
                  {
                    fieldType: FieldType.selectField,
                    label: 'Departing From Depot',
                    dataAddr: 'departingFromDepot',
                    valueKey: 'id',
                    descriptionKey: 'description',
                    mandatory: true,
                    optionItems: depotModel.depots,
                  },
                  {
                    fieldType: FieldType.yesNoField,
                    label: 'Departing From Depot In Car',
                    dataAddr: 'departingFromDepotInCar',
                    mandatory: true,
                  },
                  {
                    fieldType: FieldType.selectField,
                    label: 'Arriving At Depot',
                    dataAddr: 'arrivingAtDepot',
                    valueKey: 'id',
                    descriptionKey: 'description',
                    mandatory: true,
                    optionItems: depotModel.depots,
                  },
                  {
                    fieldType: FieldType.yesNoField,
                    label: 'Arriving At Depot In Car',
                    dataAddr: 'arrivingAtDepotInCar',
                    mandatory: true,
                  },
                ],
              },
            ],
          },
          {
            title: d =>
              d.panelValue.hasSecondStaffMember
                ? 'Completion Details - Driver 1'
                : 'Completion Details',
            hidden: d => !isUpdateMode || d.panelValue.hasSubcontractor,
            panes: [
              {
                paneType: PaneType.formFieldsPane,
                columnCount: 3,
                fields: [
                  {
                    fieldType: FieldType.yesNoField,
                    label: 'Zero Kms Travelled',
                    dataAddr: 'zeroKmsTravelled',
                    mandatory: true,
                    hidden: d =>
                      !d.panelValue.jobType ||
                      doesNotRequireAssetCompletionDetails(
                        d.panelValue.jobType.id,
                        d.panelValue.isTrainingJob
                      ),
                    readonly: isUpdatingRecurringUpdateMode,
                  },
                ],
              },
              {
                paneType: PaneType.formFieldsPane,
                columnCount: 3,
                fields: [
                  {
                    fieldType: FieldType.numericField,
                    label: 'Odometer Start',
                    dataAddr: 'odometerStart',
                    hidden: d =>
                      !d.panelValue.jobType ||
                      doesNotRequireAssetCompletionDetails(
                        d.panelValue.jobType.id,
                        d.panelValue.isTrainingJob
                      ),
                    numericConfig: { numericType: 'unsignedInt', maxValue: 9999999 },
                    formatReadonly: d =>
                      !!d.panelValue.zeroKmsTravelled ? null : d.panelValue.odometerStart,
                    readonly: d => isUpdatingRecurringUpdateMode || d.panelValue.zeroKmsTravelled,
                  },
                  {
                    fieldType: FieldType.numericField,
                    label: 'Odometer End',
                    dataAddr: 'odometerEnd',
                    hidden: d =>
                      !d.panelValue.jobType ||
                      doesNotRequireAssetCompletionDetails(
                        d.panelValue.jobType.id,
                        d.panelValue.isTrainingJob
                      ),
                    numericConfig: { numericType: 'unsignedInt', maxValue: 9999999 },
                    validate: d =>
                      !!d.parentValue.odometerEnd &&
                      d.parentValue.odometerStart &&
                      d.parentValue.odometerEnd < d.parentValue.odometerStart
                        ? 'Odometer end cannot be less than odometer start'
                        : undefined,
                    formatReadonly: d =>
                      !!d.panelValue.zeroKmsTravelled ? null : d.panelValue.odometerEnd,
                    readonly: d => isUpdatingRecurringUpdateMode || d.panelValue.zeroKmsTravelled,
                  },
                  {
                    fieldType: FieldType.readonlyField,
                    label: 'Kms Travelled',
                    hidden: d =>
                      !d.panelValue.jobType ||
                      doesNotRequireAssetCompletionDetails(
                        d.panelValue.jobType.id,
                        d.panelValue.isTrainingJob
                      ),
                    formatReadonly: d => {
                      if (d.parentValue.zeroKmsTravelled) {
                        return null;
                      }

                      if (!!d.parentValue.odometerStart && !!d.parentValue.odometerEnd) {
                        return d.parentValue.odometerEnd - d.parentValue.odometerStart;
                      }

                      return undefined;
                    },
                  },
                  {
                    fieldType: FieldType.numericField,
                    label: 'Odometer Start (tablet)',
                    dataAddr: 'tabletOdometerStart',
                    readonly: true,
                    hidden: d =>
                      !d.panelValue.jobType ||
                      doesNotRequireAssetCompletionDetails(
                        d.panelValue.jobType.id,
                        d.panelValue.isTrainingJob
                      ),
                    numericConfig: { numericType: 'unsignedInt', maxValue: 9999999 },
                  },
                  {
                    fieldType: FieldType.numericField,
                    label: 'Odometer End (tablet)',
                    dataAddr: 'tabletOdometerEnd',
                    readonly: true,
                    hidden: d =>
                      !d.panelValue.jobType ||
                      doesNotRequireAssetCompletionDetails(
                        d.panelValue.jobType.id,
                        d.panelValue.isTrainingJob
                      ),
                    numericConfig: { numericType: 'unsignedInt', maxValue: 9999999 },
                    validate: d =>
                      !!d.parentValue.tabletOdometerEnd &&
                      d.parentValue.tabletOdometerStart &&
                      d.parentValue.tabletOdometerEnd < d.parentValue.tabletOdometerStart
                        ? 'Odometer end cannot be less than odometer start'
                        : undefined,
                  },
                  {
                    fieldType: FieldType.readonlyField,
                    label: 'Kms Travelled (tablet)',
                    readonly: true,
                    hidden: d =>
                      !d.panelValue.jobType ||
                      doesNotRequireAssetCompletionDetails(
                        d.panelValue.jobType.id,
                        d.panelValue.isTrainingJob
                      ),
                    formatReadonly: d => {
                      if (!!d.parentValue.zeroKmsTravelled) {
                        return null;
                      }

                      if (
                        !!d.parentValue.tabletOdometerStart &&
                        !!d.parentValue.tabletOdometerEnd
                      ) {
                        return d.parentValue.tabletOdometerEnd - d.parentValue.tabletOdometerStart;
                      }

                      return undefined;
                    },
                  },
                  {
                    fieldType: FieldType.dateTimeField,
                    label: 'Driver Clocked On',
                    dataAddr: 'driverClockedOn',
                    timezone: TIMEZONE,
                    readonly: isUpdatingRecurringUpdateMode,
                  },
                  {
                    fieldType: FieldType.dateTimeField,
                    label: 'Driver Clock Off',
                    dataAddr: 'driverClockedOff',
                    timezone: TIMEZONE,
                    validate: d =>
                      dateIsAfter(d.parentValue.driverClockedOn, d.parentValue.driverClockedOff)
                        ? 'Driver clocked off cannot be less than driver clocked on'
                        : undefined,
                    readonly: isUpdatingRecurringUpdateMode,
                  },
                  {
                    fieldType: FieldType.durationField,
                    label: 'Driver Unpaid Breaks',
                    dataAddr: 'driverUnpaidBreaks',
                    formatReadonly: d =>
                      getEditingFormattedTimeString(parseEditingFormattedTimeString(d.fieldValue)),
                    validate: data => {
                      if (data.parentValue.driverClockedOn && data.parentValue.driverClockedOff) {
                        const unpaidBreaks =
                          data.parentValue.driverUnpaidBreaks || data.parentValue.unpaidBreaks;
                        const workingHours = workingJobHours(
                          data.parentValue.driverClockedOn,
                          data.parentValue.driverClockedOff,
                          unpaidBreaks
                        );
                        return !workingHours || workingHours.valueOf() <= 0
                          ? 'Unpaid breaks must be less than driver total time'
                          : undefined;
                      }
                      return undefined;
                    },
                    hidden: completionDetailsNotRequired,
                    readonly: isUpdatingRecurringUpdateMode,
                  },
                  {
                    fieldType: FieldType.readonlyField,
                    label: 'Driver Reported Working Hours',
                    formatReadonly: d =>
                      formattedWorkingJobHours(
                        d.parentValue.driverClockedOn,
                        d.parentValue.driverClockedOff,
                        d.parentValue.driverUnpaidBreaks
                      ),
                    hidden: completionDetailsNotRequired,
                  },
                  {
                    fieldType: FieldType.textField,
                    label: 'Reason for Difference',
                    dataAddr: 'reasonForDifference',
                    readonly: isUpdatingRecurringUpdateMode,
                  },
                  {
                    fieldType: FieldType.textField,
                    label: 'Approval Number',
                    dataAddr: 'approvalNumber',
                    maxLength: 200,
                    hidden: completionDetailsNotRequired,
                    readonly: isUpdatingRecurringUpdateMode,
                  },

                  {
                    fieldType: FieldType.durationField,
                    label: 'Override Driver Unpaid Breaks',
                    dataAddr: 'overrideDriverUnpaidBreaks',
                    formatReadonly: d =>
                      getEditingFormattedTimeString(parseEditingFormattedTimeString(d.fieldValue)),
                    validate: data => {
                      const formData = data.parentValue as JobItem;
                      if (
                        formData.driverClockedOn &&
                        formData.driverClockedOff &&
                        formData.overrideDriverUnpaidBreaks
                      ) {
                        const unpaidBreaks =
                          formData.overrideDriverUnpaidBreaks || formData.unpaidBreaks;
                        const workingHours = workingJobHours(
                          formData.driverClockedOn,
                          formData.driverClockedOff,
                          unpaidBreaks
                        );
                        return !workingHours || workingHours.valueOf() <= 0
                          ? 'Unpaid breaks must be less than driver total time'
                          : undefined;
                      }
                      return undefined;
                    },
                    hidden: completionDetailsNotRequired,
                    readonly: isUpdatingRecurringUpdateMode,
                  },
                  {
                    fieldType: FieldType.textField,
                    label: 'Reason for Override',
                    dataAddr: 'reasonForCompletionDetailsOverride',
                    maxLength: 200,
                    mandatory: d => !!d.parentValue.overrideDriverUnpaidBreaks,
                    hidden: completionDetailsNotRequired,
                    formatReadonly: d =>
                      !d.parentValue.overrideDriverUnpaidBreaks ? '' : d.fieldValue,
                    readonly: d =>
                      isUpdatingRecurringUpdateMode || !d.parentValue.overrideDriverUnpaidBreaks,
                  },
                  {
                    fieldType: FieldType.readonlyField,
                    label: 'Actual Driver Working Hours',
                    formatReadonly: d =>
                      formattedWorkingJobHours(
                        d.parentValue.driverClockedOn,
                        d.parentValue.driverClockedOff,
                        d.parentValue.overrideDriverUnpaidBreaks || d.parentValue.driverUnpaidBreaks
                      ),
                    hidden: completionDetailsNotRequired,
                  },
                  {
                    fieldType: FieldType.readonlyField,
                    label: 'Override Driver Paid Hours',
                    readonly: true,
                    hidden: hideOverridePaidHours,
                    formatReadonly: d => (
                      <DurationFormat value={(d.parentValue as JobItem).driverOverridePaidHours} />
                    ),
                  },
                  {
                    fieldType: FieldType.customField,
                    dataAddr: 'none',
                    render: () => <div />,
                  },
                  {
                    fieldType: FieldType.yesNoField,
                    label: 'Tolls',
                    dataAddr: 'tolls',
                    hidden: d =>
                      !d.panelValue.jobType ||
                      doesNotRequireAssetCompletionDetails(
                        d.panelValue.jobType.id,
                        d.panelValue.isTrainingJob
                      ),
                    readonly: isUpdatingRecurringUpdateMode,
                  },
                  {
                    fieldType: FieldType.durationField,
                    label: 'Driver Waiting Time',
                    dataAddr: 'driverWaitingTime',
                    formatReadonly: d =>
                      getEditingFormattedTimeString(parseEditingFormattedTimeString(d.fieldValue)),
                    hidden: d => {
                      return (
                        hideWaitingTimeField ||
                        d.panelValue.hasSecondStaffMember ||
                        !isJobTypeCharterOrContractCharter(
                          d.paneValue.jobType && d.paneValue.jobType.id
                        ) ||
                        Boolean(completionDetailsNotRequired)
                      );
                    },
                    validate: data => {
                      const formData = data.parentValue as JobItem;
                      if (
                        formData.driverClockedOn &&
                        formData.driverClockedOff &&
                        formData.driverWaitingTime
                      ) {
                        const unpaidBreaks =
                          formData.overrideDriverUnpaidBreaks || formData.driverUnpaidBreaks;
                        const workingHours = workingJobHours(
                          formData.driverClockedOn,
                          formData.driverClockedOff,
                          unpaidBreaks
                        );
                        const waiting = parseDuration(formData.driverWaitingTime);
                        if (!waiting.isValid) {
                          return undefined;
                        }
                        return !workingHours || workingHours.valueOf() <= waiting.valueOf()
                          ? 'Waiting time must be less than driver total time'
                          : undefined;
                      }
                      return undefined;
                    },

                    readonly: isUpdatingRecurringUpdateMode,
                  },
                ],
              },
              {
                paneType: PaneType.formFieldsPane,
                fields: [
                  {
                    fieldType: FieldType.textAreaField,
                    label: 'Anything to Report',
                    dataAddr: 'anythingToReport',
                    readonly: isUpdatingRecurringUpdateMode,
                  },
                ],
              },
            ],
          },
          {
            title: 'Completion Details - Driver 2',
            hidden: d =>
              !currentJob ||
              !currentJob.hasSecondStaffMember ||
              !isUpdateMode ||
              d.panelValue.hasSubcontractor,
            panes: [
              {
                paneType: PaneType.formFieldsPane,
                columnCount: 3,
                hidden: !currentJob || !currentJob.hasSecondStaffMember,
                fields: [
                  {
                    fieldType: FieldType.dateTimeField,
                    label: 'Driver Clocked On',
                    dataAddr: 'secondDriverClockedOn',
                    timezone: TIMEZONE,
                    readonly: isUpdatingRecurringUpdateMode,
                  },
                  {
                    fieldType: FieldType.dateTimeField,
                    label: 'Driver Clock Off',
                    dataAddr: 'secondDriverClockedOff',
                    timezone: TIMEZONE,
                    validate: d =>
                      dateIsAfter(
                        d.parentValue.secondDriverClockedOn,
                        d.parentValue.secondDriverClockedOff
                      )
                        ? 'Driver clocked off cannot be less than driver clocked on'
                        : undefined,
                    readonly: isUpdatingRecurringUpdateMode,
                  },
                  {
                    fieldType: FieldType.durationField,
                    label: 'Driver Unpaid Breaks',
                    dataAddr: 'secondDriverUnpaidBreaks',
                    formatReadonly: d =>
                      getEditingFormattedTimeString(parseEditingFormattedTimeString(d.fieldValue)),
                    validate: data => {
                      const formData = data.parentValue as JobItem;
                      if (formData.secondDriverClockedOn && formData.secondDriverClockedOff) {
                        const unpaidBreaks =
                          formData.secondDriverUnpaidBreaks || formData.unpaidBreaks;
                        const workingHours = workingJobHours(
                          formData.secondDriverClockedOn,
                          formData.secondDriverClockedOff,
                          unpaidBreaks
                        );
                        return !workingHours || workingHours.valueOf() <= 0
                          ? 'Unpaid breaks must be less than driver total time'
                          : undefined;
                      }
                      return undefined;
                    },
                    hidden: completionDetailsNotRequired,
                    readonly: isUpdatingRecurringUpdateMode,
                  },
                  {
                    fieldType: FieldType.readonlyField,
                    label: 'Driver Reported Working Hours',
                    formatReadonly: d =>
                      formattedWorkingJobHours(
                        d.parentValue.secondDriverClockedOn,
                        d.parentValue.secondDriverClockedOff,
                        d.parentValue.secondDriverUnpaidBreaks
                      ),
                    hidden: completionDetailsNotRequired,
                  },
                  {
                    fieldType: FieldType.textField,
                    label: 'Reason for Difference',
                    dataAddr: 'secondDriverReasonForDifference',
                    readonly: isUpdatingRecurringUpdateMode,
                  },
                  {
                    fieldType: FieldType.textField,
                    label: 'Approval Number',
                    dataAddr: 'secondDriverApprovalNumber',
                    maxLength: 200,
                    hidden: completionDetailsNotRequired,
                    readonly: isUpdatingRecurringUpdateMode,
                  },

                  {
                    fieldType: FieldType.durationField,
                    label: 'Override Driver Unpaid Breaks',
                    dataAddr: 'secondDriverOverrideDriverUnpaidBreaks',
                    formatReadonly: d =>
                      getEditingFormattedTimeString(parseEditingFormattedTimeString(d.fieldValue)),
                    validate: data => {
                      const formData = data.parentValue as JobItem;
                      if (
                        formData.secondDriverClockedOn &&
                        formData.secondDriverClockedOff &&
                        formData.secondDriverOverrideDriverUnpaidBreaks
                      ) {
                        const unpaidBreaks =
                          formData.secondDriverOverrideDriverUnpaidBreaks || formData.unpaidBreaks;
                        const workingHours = workingJobHours(
                          formData.secondDriverClockedOn,
                          formData.secondDriverClockedOff,
                          unpaidBreaks
                        );
                        return !workingHours || workingHours.valueOf() <= 0
                          ? 'Unpaid breaks must be less than driver total time'
                          : undefined;
                      }
                      return undefined;
                    },
                    hidden: completionDetailsNotRequired,
                    readonly: isUpdatingRecurringUpdateMode,
                  },
                  {
                    fieldType: FieldType.textField,
                    label: 'Reason for Override',
                    dataAddr: 'secondDriverReasonForCompletionDetailsOverride',
                    maxLength: 200,
                    mandatory: d => !!d.parentValue.secondDriverOverrideDriverUnpaidBreaks,
                    hidden: completionDetailsNotRequired,
                    formatReadonly: d =>
                      !d.parentValue.secondDriverOverrideDriverUnpaidBreaks ? '' : d.fieldValue,
                    readonly: d =>
                      isUpdatingRecurringUpdateMode ||
                      !d.parentValue.secondDriverOverrideDriverUnpaidBreaks,
                  },
                  {
                    fieldType: FieldType.readonlyField,
                    label: 'Actual Driver Working Hours',
                    formatReadonly: d =>
                      formattedWorkingJobHours(
                        d.parentValue.secondDriverClockedOn,
                        d.parentValue.secondDriverClockedOff,
                        d.parentValue.secondDriverOverrideDriverUnpaidBreaks ||
                          d.parentValue.secondDriverUnpaidBreaks
                      ),
                    hidden: completionDetailsNotRequired,
                  },
                  {
                    fieldType: FieldType.readonlyField,
                    label: 'Override Driver Paid Hours',
                    readonly: true,
                    formatReadonly: d => (
                      <DurationFormat
                        value={(d.parentValue as JobItem).secondDriverOverridePaidHours}
                      />
                    ),
                    hidden: hideOverridePaidHours || completionDetailsNotRequired,
                  },
                ],
              },
              {
                paneType: PaneType.formFieldsPane,
                fields: [
                  {
                    fieldType: FieldType.textAreaField,
                    label: 'Anything to Report',
                    dataAddr: 'secondDriverAnythingToReport',
                    readonly: isUpdatingRecurringUpdateMode,
                  },
                ],
              },
            ],
          },
          {
            title: 'Passengers Carried Per Run',
            hidden: !isRailJob,
            panes: [getPaxPaneDef(formApi)],
          },
          {
            title: 'Fatigue Breaks Recorded in the Tablet',
            hidden: d =>
              !d.panelValue.jobType ||
              doesNotRequireAssetCompletionDetails(
                d.panelValue.jobType.id,
                d.panelValue.isTrainingJob
              ) ||
              !isUpdateMode,
            panes: [
              getFatigueBreaksPaneDef('fatigueBreaks', {
                editable: editable,
                staffMembers: staffMembersModel.allStaffMembers,
                minDate: minDate,
                maxDate: maxDate,
                isJobCompleted:
                  (currentJob && currentJob.jobStatus.id === JobStatus.Completed) || false,
              }),
            ],
          },
          {
            title: 'Staff Member Requirements',
            panes: [
              {
                paneType: PaneType.formFieldsPane,
                columnCount: 2,
                fields: [
                  ...getSkillSpecRequirementFieldDefs(skillSpecsModel.forRequirements.items),
                ],
              },
            ],
          },
          {
            title: 'Vehicle Requirements',
            hidden: d => !d.panelValue.jobType || isJobTypeWithoutAsset(d.panelValue.jobType.id),
            panes: [
              {
                paneType: PaneType.formFieldsPane,
                columnCount: 3,
                fields: getTechSpecRequirementFieldDefs(
                  splittedTechSpecs,
                  assetsModel.searchTechSpecValues,
                  techSpecsModel.techSpecDropdownOptions
                ),
              },
            ],
          },
        ],
        onFormPreSubmit: isUpdateMode ? handlePreSubmitForUpdate : handlePreSubmitForCreate,
        onFormSubmit: async values => {
          const warnings = isUpdateMode
            ? await jobModel.updateJob(prepareShiftEnd(values))
            : await jobModel.createAdhocJob(prepareShiftEnd(values));

          rootStore.compliance.fatigueValidations.setWarningMessages(warnings);
        },
      },
      secondarySections: [
        getConflictsSectionDef(
          canManageJobs,
          jobId,
          conflictModel.acceptConflict,
          conflictModel.cancelAcceptanceConflict,
          () => jobModel.loadJob(jobId)
        ),
        getAuditHistoryPanelDef(
          jobId,
          depotModel.depots,
          assetsModel.fleetAssetIncludingDecommissionedListItems,
          staffMembersModel.allStaffMembers,
          subcontractorsModel.allSubcontractors,
          extraTypesModel.list.charterExtraTypes,
          vehicleTypesModel.vehicleTypes,
          currentJob?.jobType.id
        ),
        getActivityLogPanelDef(jobId, jobModel.activityLogs),
        getContinuationsSectionDef(
          currentJob,
          {
            continueToNextJob: jobModel.continueToNextJob,
            continueFromPrevJob: jobModel.continueFromPrevJob,
            stopContinueToNextJob: jobModel.stopContinueToNextJob,
            stopContinueFromPrevJob: jobModel.stopContinueFromPrevJob,
          },
          updating,
          isCreateMode
        ),
        getProgressSectionDef(
          jobProgress ?? [],
          jobId,
          currentJob && currentJob.asset && currentJob.asset.id,
          false,
          !currentJob || isJobTypeWithoutAsset(currentJob.jobType.id)
        ),
        getLinkedJobsSectionDef(currentJob),
        getScheduledBreaksSectionDef(
          {
            jobId: currentJob?.id,
            jobTypeId: currentJob?.jobType.id,
            nonSplitJobsInTripCount: currentJob?.nonSplitJobsInTripCount ?? 0,
            splitJobsInTripCount: currentJob?.splitJobsInTripCount ?? 0,
          },
          scheduledBreaks,
          updating,
          jobModel.job?.hasSecondStaffMember ?? false,
          jobModel.updateScheduledBreaks,
          canUpdateAllForTrip,
          (currentJob &&
            doesNotRequireAssetCompletionDetails(
              currentJob.jobType.id,
              currentJob.isTrainingJob
            )) ||
            isCreateMode ||
            !jobId,
          currentJob?.jobRoutes
        ),
      ],
    };
  };

  const loadJob = () => {
    return (props.mode !== 'create' ? jobModel.loadJob(jobId) : Promise.resolve()).then(() => {
      const boardingPointIds = (currentJob?.jobRoutes || [])
        .filter(r => r.location.boardingPointId)
        .map(r => r.location.boardingPointId as string);
      boardingPointModel.findBoardingPoints(boardingPointIds);
    });
  };

  const loadData = () => {
    const promises = [
      vehicleTypesModel.loadVehicleTypes(),
      loadJob(),
      isUpdateMode ? jobModel.listActivityLogs(jobId) : Promise.resolve(),
      isUpdateMode ? loadJobProgress(jobId) : Promise.resolve(),
      isUpdateMode ? jobModel.getPaidHours(jobId) : Promise.resolve(),
      isUpdateMode ? jobModel.loadScheduledBreaks(jobId) : Promise.resolve(),

      assetsModel.loadFleetAssets(),
      loadJobSubTypes(),
      exclusionModel.loadExclusions(),
      subcontractorsModel.loadAllSubcontractors(),
      depotModel.loadDepots(),
      railModel.railTemplatesForDropdown.listItems({
        loadCause: ListPageLoadCause.mount,
        query: { deleted: false },
      }),
      railModel.railBookings.loadDropdownRailBookings(),
      boardingPointModel.loadStates(),
      extraTypesModel.list.loadExtraTypes(),
      staffMembersModel.loadAllStaffMembers(),
      skillSpecsModel.forRequirements.getAll(),
      techSpecsModel.forRequirements.getAll(),
      techSpecsModel.getTechSpecDropdownsOptions({ forOperations: true }),
      contractModel.list.listItems({
        loadCause: ListPageLoadCause.mount,
      }),
    ];
    return Promise.all(promises).then(() => Promise.resolve());
  };

  return (
    <CrudPage
      // key is used to identify this CrudPage is used for this `jobid`.
      // In case the `jobid` changed e.g. click on linked job from secondery section,
      // the CrudPage component will be recreated and bind to the new clicked job.
      // So the `updating` status will reset to `false`
      key={jobId}
      className="maintain-job-component"
      def={({ updating, updateType, beginCustomEditing }) =>
        getPageDef(updating || !isUpdateMode, updateType, beginCustomEditing)
      }
      onLoadData={loadData}
      onLoadCreateDefaultData={loadData}
      mode={props.mode}
      isEditingForbidden={!canManageJobs}
      data={getData()}
      createDefaultData={{
        skipPredepartureChecks: false,
        zeroKmsTravelled: false,
      }}
    />
  );
});

export default MaintainJob;
