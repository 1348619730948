import { Component } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import {
  PagePrimarySize,
  PaneType,
  FieldType,
  ActionType,
  ShellModalSize,
} from 'src/views/definitionBuilders/types';
import {
  validateTime,
  getDays,
  daySelected,
  workshopWorkingHours,
} from 'src/views/routes/operations/urban/urbanHelpers';
import {
  parseEditingFormattedTimeString,
  getEditingFormattedTimeString,
} from 'src/views/components/Page/fields/subfields/TimeHelpers';
import CrudPage, { CrudPageMode, ICrudPageDef } from 'src/views/components/Page/pages/CrudPage';
import { TrashIcon } from 'src/images/icons';
import { getSubmitCloseModalActionGroupDef } from 'src/views/definitionBuilders/common';
import { Weekday } from 'src/domain';
import { DurationFormat } from 'src/views/components/DurationFormat';
import { allWorkshopShiftType } from 'src/api/enums';

type CreateShiftTemplateCommand = Workshop.Domain.Commands.ShiftTemplate.CreateShiftTemplate.CreateShiftTemplateCommand;
type UpdateShiftTemplateCommand = Workshop.Domain.Commands.ShiftTemplate.UpdateShiftTemplate.UpdateShiftTemplateCommand;
type ShiftTemplateItem = Workshop.Domain.Queries.ShiftTemplate.GetShiftTemplate.ShiftTemplateItem;

export interface IMaintainShiftTemplate {
  mode: CrudPageMode;
  canManageShiftTemplates: boolean;

  loadShiftTemplate: (id: string) => Promise<void>;
  shiftTemplate: ShiftTemplateItem | undefined;

  checkForUniqueShiftTemplateName: (
    shiftName: string
  ) => Promise<Common.Dtos.UniqueNameCheckResultDto>;
  createShiftTemplate: (command: CreateShiftTemplateCommand) => Promise<void>;
  updateShiftTemplate: (command: UpdateShiftTemplateCommand) => Promise<void>;

  deleteShiftTemplate: (shiftTemplateId: string) => Promise<void>;
}

type IMaintainShiftTemplateForm = ShiftTemplateItem & {
  days: number[];
};

interface IUpdateShiftTemplateRouteParams {
  id: string;
}

type InternalProps = IMaintainShiftTemplate & RouteComponentProps<IUpdateShiftTemplateRouteParams>;

class MaintainShiftTemplate extends Component<InternalProps> {
  private get isUpdateMode() {
    return this.props.mode === 'update';
  }

  private get ShiftTemplateId() {
    return this.props.match.params.id;
  }

  private handlePreSubmitForCreate = (
    shiftTemplateForm: IMaintainShiftTemplateForm
  ): CreateShiftTemplateCommand => {
    const command: CreateShiftTemplateCommand = {
      shiftName: shiftTemplateForm.shiftName,
      shiftCommence: shiftTemplateForm.shiftCommence,
      shiftEnd: shiftTemplateForm.shiftEnd,
      unpaidBreaks: shiftTemplateForm.unpaidBreaks,
      monday: daySelected(shiftTemplateForm.days, Weekday.mon),
      tuesday: daySelected(shiftTemplateForm.days, Weekday.tue),
      wednesday: daySelected(shiftTemplateForm.days, Weekday.wed),
      thursday: daySelected(shiftTemplateForm.days, Weekday.thu),
      friday: daySelected(shiftTemplateForm.days, Weekday.fri),
      saturday: daySelected(shiftTemplateForm.days, Weekday.sat),
      sunday: daySelected(shiftTemplateForm.days, Weekday.sun),
      shiftType: shiftTemplateForm.shiftType,
    };
    return command;
  };

  private handlePreSubmitForUpdate = (
    shiftTemplateForm: IMaintainShiftTemplateForm
  ): UpdateShiftTemplateCommand => {
    const command: UpdateShiftTemplateCommand = {
      id: shiftTemplateForm.id,
      shiftName: shiftTemplateForm.shiftName,
      shiftCommence: shiftTemplateForm.shiftCommence,
      shiftEnd: shiftTemplateForm.shiftEnd,
      unpaidBreaks: shiftTemplateForm.unpaidBreaks,
      monday: daySelected(shiftTemplateForm.days, Weekday.mon),
      tuesday: daySelected(shiftTemplateForm.days, Weekday.tue),
      wednesday: daySelected(shiftTemplateForm.days, Weekday.wed),
      thursday: daySelected(shiftTemplateForm.days, Weekday.thu),
      friday: daySelected(shiftTemplateForm.days, Weekday.fri),
      saturday: daySelected(shiftTemplateForm.days, Weekday.sat),
      sunday: daySelected(shiftTemplateForm.days, Weekday.sun),
      shiftType: shiftTemplateForm.shiftType,
    };
    return command;
  };

  private shiftTemplateData(shift: ShiftTemplateItem | undefined): IMaintainShiftTemplateForm {
    if (!shift) {
      return {} as IMaintainShiftTemplateForm;
    }

    return {
      ...shift,
      days: getDays(shift),
    };
  }

  private readonly getPageDef = (readonly: boolean): ICrudPageDef => {
    const { checkForUniqueShiftTemplateName, shiftTemplate, canManageShiftTemplates } = this.props;

    return {
      primarySize: PagePrimarySize.full,
      primarySection: {
        title: this.isUpdateMode ? 'Manage Shift Template' : 'Create a Shift Template',
        primaryActions: [
          {
            actions: [
              {
                actionType: ActionType.actionCollection,
                hidden: !readonly || !canManageShiftTemplates,
                actionGroups: [
                  {
                    actions: [
                      {
                        actionType: ActionType.modalActionButton,
                        label: 'Delete Shift Template',
                        icon: <TrashIcon />,
                        hidden: !this.props.shiftTemplate,
                        modalSize: ShellModalSize.oneQuarter,
                        modalDef: modalDefApi => ({
                          title: 'Delete Shift Template',
                          asForm: true,
                          panels: [
                            {
                              panes: [
                                {
                                  paneType: PaneType.customPane,
                                  render: () => (
                                    <div>
                                      <p>Are you sure you want to delete this Shift Template?</p>
                                    </div>
                                  ),
                                },
                              ],
                            },
                          ],
                          secondaryActions: [getSubmitCloseModalActionGroupDef('Delete')],
                          onFormSubmit: () => this.props.deleteShiftTemplate(this.ShiftTemplateId),
                        }),
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
        panels: [
          {
            panes: [
              {
                paneType: PaneType.formFieldsPane,
                columnCount: 2,
                fields: [
                  {
                    fieldType: FieldType.textField,
                    label: 'Shift Name',
                    dataAddr: 'shiftName',
                    maxLength: 50,
                    mandatory: true,
                    validateAsync: async d => {
                      if (
                        !d.fieldValue ||
                        (this.isUpdateMode &&
                          shiftTemplate &&
                          shiftTemplate.shiftName.toUpperCase() === d.fieldValue.toUpperCase())
                      ) {
                        return undefined;
                      }
                      const result = await checkForUniqueShiftTemplateName(d.fieldValue);
                      return result.nameExists ? `Shift name is already in use` : undefined;
                    },
                  },
                  {
                    fieldType: FieldType.selectField,
                    label: 'Shift type',
                    dataAddr: 'shiftType',
                    valueKey: 'value',
                    descriptionKey: 'description',
                    optionItems: allWorkshopShiftType,
                    useValueOnly: true,
                    mandatory: true,
                  },
                ],
              },
              {
                paneType: PaneType.formFieldsPane,
                columnCount: 4,
                fields: [
                  {
                    fieldType: FieldType.timeField,
                    label: 'Shift Commence',
                    dataAddr: 'shiftCommence',
                    mandatory: true,
                    validate: d => validateTime(d.fieldValue),
                    formatReadonly: d => <DurationFormat value={d.fieldValue} />,
                  },
                  {
                    fieldType: FieldType.timeField,
                    label: 'Shift End',
                    dataAddr: 'shiftEnd',
                    mandatory: true,
                    validate: d => validateTime(d.fieldValue),
                    formatReadonly: d => <DurationFormat value={d.fieldValue} />,
                  },
                  {
                    fieldType: FieldType.durationField,
                    label: 'Unpaid Breaks',
                    dataAddr: 'unpaidBreaks',
                    mandatory: true,
                    validate: d => {
                      const timeValidation = validateTime(d.fieldValue);
                      if (!!timeValidation) {
                        return timeValidation;
                      }

                      const hoursValidation = workshopWorkingHours(
                        d.parentValue.shiftCommence,
                        d.parentValue.shiftEnd,
                        d.parentValue.unpaidBreaks
                      );

                      if (!!hoursValidation && hoursValidation.as('minutes') <= 0) {
                        return 'Unpaid breaks must be less than total time';
                      }

                      return undefined;
                    },
                    formatReadonly: d =>
                      getEditingFormattedTimeString(parseEditingFormattedTimeString(d.fieldValue)),
                  },
                  {
                    fieldType: FieldType.readonlyField,
                    label: 'Working Time',
                    formatReadonly: data => {
                      const hours = workshopWorkingHours(
                        data.parentValue.shiftCommence,
                        data.parentValue.shiftEnd,
                        data.parentValue.unpaidBreaks
                      );
                      if (!!hours) {
                        return <DurationFormat value={hours} />;
                      }
                      return undefined;
                    },
                  },
                ],
              },
              {
                paneType: PaneType.formFieldsPane,
                fields: [
                  {
                    fieldType: FieldType.daysOfWeekField,
                    dataAddr: 'days',
                    label: 'Days of Week',
                    mandatory: true,
                    useValueOnly: true,
                  },
                ],
              },
            ],
          },
        ],
        onFormPreSubmit: this.isUpdateMode
          ? this.handlePreSubmitForUpdate
          : this.handlePreSubmitForCreate,
        onFormSubmit: this.isUpdateMode
          ? this.props.updateShiftTemplate
          : this.props.createShiftTemplate,
      },
    };
  };

  render() {
    const { mode, loadShiftTemplate, shiftTemplate, canManageShiftTemplates } = this.props;
    return (
      <CrudPage
        def={api => this.getPageDef(api.readonly)}
        mode={mode}
        isEditingForbidden={!canManageShiftTemplates}
        onLoadData={() => loadShiftTemplate(this.ShiftTemplateId)}
        data={this.shiftTemplateData(shiftTemplate)}
        createDefaultData={{}}
      />
    );
  }
}

export default MaintainShiftTemplate;
