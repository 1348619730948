import { IAjax } from 'src/infrastructure/ajax';
import { peopleUrls } from 'src/domain/services/apiUrls';

const returnVoid = () => {};

export default function init(ajax: IAjax) {
  function listEquipmentTypes(
    query: Partial<People.Domain.Queries.EquipmentType.GetEquipmentTypeListQuery>
  ) {
    return ajax
      .get(peopleUrls.staffMemberUrls.equipmentTypes.listEquipmentTypes(query))
      .map(
        r =>
          r.response as Common.Dtos.ListResult<
            People.Domain.Queries.EquipmentType.EquipmentTypeItem
          >
      );
  }

  function getEquipmentTypes() {
    return ajax
      .get(peopleUrls.staffMemberUrls.equipmentTypes.getAll())
      .toPromise()
      .then<People.Domain.Queries.EquipmentType.EquipmentTypeItem[]>(r => {
        return r.response;
      });
  }

  function checkForUniqueDescription(description: string) {
    return ajax
      .get(peopleUrls.staffMemberUrls.equipmentTypes.checkForUniqueDescription(description))
      .toPromise()
      .then<Common.Dtos.UniqueNameCheckResultDto>(r => r.response);
  }

  function createEquipmentType(
    command: People.Domain.Commands.EquipmentType.CreateEquipmentType.CreateEquipmentTypeCommand
  ) {
    return ajax
      .post(peopleUrls.staffMemberUrls.equipmentTypes.createEquipmentType(), command)
      .toPromise()
      .then<string>(r => r.response);
  }

  function getEquipmentType(id: number) {
    return ajax
      .get(peopleUrls.staffMemberUrls.equipmentTypes.getEquipmentType(id))
      .toPromise()
      .then<People.Domain.Queries.EquipmentType.EquipmentTypeItem>(r => r.response);
  }

  function updateEquipmentType(
    command: People.Domain.Commands.EquipmentType.UpdateEquipmentType.UpdateEquipmentTypeCommand
  ) {
    return ajax
      .put(peopleUrls.staffMemberUrls.equipmentTypes.updateEquipmentType(command.id), command)
      .toPromise()
      .then(r => returnVoid());
  }

  function deleteEquipmentType(id: number) {
    return ajax
      .httpDelete(peopleUrls.staffMemberUrls.equipmentTypes.deleteEquipmentType(id))
      .toPromise()
      .then(r => returnVoid());
  }

  return {
    getEquipmentTypes,
    listEquipmentTypes,
    getEquipmentType,
    checkForUniqueDescription,
    createEquipmentType,
    updateEquipmentType,
    deleteEquipmentType,
  };
}
