import { DateTime, Interval } from 'luxon';
import { flow, types } from 'mobx-state-tree';
import { HolidayType } from 'src/api/enums';
import { getAjax } from 'src/domain/services';

type HolidayListItem = Operations.Domain.Queries.ListHolidays.HolidayListItem;

export interface IScheduledHoliday extends HolidayListItem {
  holidayInterval: Interval;
}

export const HolidaysModel = types
  .model('HolidaysModel', {
    holidays: types.array(types.frozen<IScheduledHoliday>()),
    publicHolidays: types.array(types.frozen<HolidayListItem>()),
  })
  .actions(self => {
    const ajax = getAjax(self);

    const loadHolidays = flow(function*(start: DateTime, depots: number[]) {
      self.holidays = yield ajax.operations.holidays
        .listHolidays({
          dateFrom: start.toISODate(),
          dateTo: start.plus({ days: 6 }).toISODate(),
          depotIds: depots,
          size: 9999,
        })
        .then(r =>
          r.items.map(
            h =>
              ({
                ...h,
                endDate: DateTime.fromISO(h.endDate)
                  .plus({ days: 1 }) // endDate is the last full day of the holiday
                  .toISO(),
                holidayInterval: Interval.fromDateTimes(
                  DateTime.fromISO(h.startDate),
                  DateTime.fromISO(h.endDate).plus({ days: 1 })
                ),
              } as IScheduledHoliday)
          )
        );
    });

    const loadPublicHolidays = flow(function*(start: DateTime, end: DateTime) {
      self.publicHolidays = yield ajax.operations.holidays
        .listHolidays({
          dateFrom: start.toISODate(),
          dateTo: end.toISODate(),
          depotIds: [],
          holidayTypeIds: [HolidayType.PublicHoliday],
          size: 9999,
        })
        .then(r => r.items as HolidayListItem[]);
    });

    const getPublicHolidays = flow(function*(start: DateTime, end: DateTime) {
      return yield ajax.operations.holidays
        .listHolidays({
          dateFrom: start.toISODate(),
          dateTo: end.toISODate(),
          depotIds: [],
          holidayTypeIds: [HolidayType.PublicHoliday],
          size: 9999,
        })
        .then(r => r.items as HolidayListItem[]);
    });

    return {
      loadHolidays,
      loadPublicHolidays,
      getPublicHolidays,
    };
  });
