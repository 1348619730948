import { buildListPageApiSearchModelTypedQuery } from 'src/domain/modelBuilders/buildListPageApiSearchModel';
import { types, getRoot, flow } from 'mobx-state-tree';
import { getAjax, NotificationType } from 'src/domain/services';
import { IRootStoreModel } from 'src/domain/entities/RootStoreModel';
import { operationsUrls } from 'src/domain/services/apiUrls';
import * as queryString from 'query-string';
import { DateTime } from 'luxon';
import { JobStatus } from 'src/api/enums';
import { cast } from 'mobx-state-tree';

type DailySummarySheetDetailsDto = Operations.Domain.Queries.ListDailySummarySheetDetails.DailySummarySheetDetailsDto;
type DailySummarySheetJobDetailsDto = Operations.Domain.Queries.ListDailySummarySheetDetails.DailySummarySheetJobDetailsDto;
type CharterDailyDiaryDetails = Operations.Domain.Queries.ListCharterDailyDiaryDetails.CharterDailyDiaryDetails;
type RailInstructions = Operations.Domain.Queries.ListRailInstructionsForDriver.RailInstructions;
type CharterInstructions = Operations.Domain.Queries.ListCharterInstructionsForDriver.CharterInstructions;
type ListJobItem = Operations.Domain.Queries.ListJobs.ListJobItem;
type JobWithNoDriverClockedOn = Operations.Domain.Queries.ListJobsWithNoDriverClockedOnQuery.JobWithNoDriverClockedOn;
type JobForRailBookingDropdown = Operations.Domain.Queries.GetExistingJobsForRailBookingDropdown.JobForRailBookingDropdown;
type PredepartureCheckItem = Operations.Domain.Queries.ListJobsForDriver.PredepartureCheckItem;
type ListDailySummarySheetDetailsQuery = Operations.Domain.Queries.ListDailySummarySheetDetails.ListDailySummarySheetDetailsQuery;

const JobsListModel = buildListPageApiSearchModelTypedQuery<
  Operations.Domain.Queries.ListJobs.ListJobsQuery
>()('JobsListModel', d => d.ajax.operations.jobs.listJobs(d.query), { size: 250 }).views(self => ({
  get jobWithClockOnClosestToCurrentTime(): ListJobItem | undefined {
    const reducer = (previous: ListJobItem, current: ListJobItem): ListJobItem => {
      const previousDiff = Math.abs(DateTime.fromISO(previous.clockOn).diffNow('second').seconds);
      const currentDiff = Math.abs(DateTime.fromISO(current.clockOn).diffNow('second').seconds);

      return previousDiff < currentDiff
        ? previous
        : previousDiff === currentDiff
        ? previous
        : current;
    };

    if (self.items && self.items.length > 0) {
      const nonHiddenItems = self.items.filter(
        job => job.clockOn && !(job.jobStatus.id === JobStatus.Incomplete || job.isContinuingFrom)
      );

      if (nonHiddenItems.length > 0) {
        return nonHiddenItems.reduce(reducer);
      }
    }

    return undefined;
  },
}));

const ListExistingJobsForRailBookingDropdownModel = types
  .model('ListExistingJobsForRailBookingDropdown', {
    existingJobsForRailBookingDropdown: types.array(types.frozen<JobForRailBookingDropdown>()),
  })
  .actions(self => {
    const ajax = getAjax(self);

    const loadExistingJobsForRailBookingDropdown = flow(function*() {
      self.existingJobsForRailBookingDropdown = yield ajax.operations.jobs.getExistingJobsForRailBookingDropdown();
    });

    return {
      loadExistingJobsForRailBookingDropdown,
    };
  });

const ListJobsWithNoDriverClockedOnModel = types
  .model('ListJobsWithNoDriverClockedOnModel', {
    jobsWithNoDriverClockedOn: types.maybe(types.frozen<JobWithNoDriverClockedOn[]>()),
    jobsAlreadyAlertedFor: types.array(types.number),
  })
  .actions(self => {
    const root = getRoot(self) as IRootStoreModel;

    const showAlertForJobWithNoDriversClockedOn = (job: JobWithNoDriverClockedOn) => {
      if (!self.jobsAlreadyAlertedFor.includes(job.jobNumber)) {
        if (!job.isStaffMemberClockedOn) {
          root.notifications.addNotification(
            `${job.staffMemberName} hasn't clocked on for job ${
              job.jobNumber
            } starting at ${DateTime.fromISO(job.jobClockOnTime).toLocaleString({
              hour: 'numeric',
              minute: 'numeric',
              hour12: false,
            })}`,
            { type: NotificationType.critical }
          );
        }

        if (job.hasSecondDriver && !job.isSecondStaffMemberClockedOn) {
          root.notifications.addNotification(
            `${job.secondStaffMemberName} hasn't clocked on for job ${
              job.jobNumber
            } starting at ${DateTime.fromISO(job.jobClockOnTime).toLocaleString({
              hour: 'numeric',
              minute: 'numeric',
              hour12: false,
            })}`,
            { type: NotificationType.critical }
          );
        }

        self.jobsAlreadyAlertedFor.push(job.jobNumber);
      }
    };

    return { showAlertForJobWithNoDriversClockedOn };
  });

const ExportJobsModel = types
  .model('ExportJobsModel', {
    railInstructions: types.maybe(types.frozen<RailInstructions>()),
    charterInstructions: types.maybe(types.frozen<CharterInstructions>()),
    charterDailyDiaryDetails: types.maybe(
      types.frozen<Common.Dtos.ListResult<CharterDailyDiaryDetails>>()
    ),
  })
  .actions(self => {
    const ajax = getAjax(self);
    const root = getRoot(self) as IRootStoreModel;

    const exportToExcel = flow(function*(
      query: Partial<Operations.Domain.Queries.ListJobs.ListJobsQuery>
    ) {
      root.notifications.addNotification(`The file is being generated ...`, {
        type: NotificationType.info,
      });

      return yield ajax.operations.jobs.exportJobsToExcel(query);
    });

    const exportVehicleReallocationsToExcel = flow(function*(
      query: Partial<Operations.Domain.Queries.ListJobs.ListJobsQuery>
    ) {
      root.notifications.addNotification(`The file is being generated ...`, {
        type: NotificationType.info,
      });

      return yield ajax.operations.jobs.exportVehicleReallocationsToExcel(query);
    });

    const exportJobHoursToExcel = flow(function*(
      query: Partial<Operations.Domain.Queries.ListJobs.ListJobsQuery>
    ) {
      root.notifications.addNotification(`The file is being generated ...`, {
        type: NotificationType.info,
      });

      return yield ajax.operations.jobs.exportJobHoursToExcel(query);
    });

    const exportTripsTakenToExcel = flow(function*(
      query: Partial<Operations.Domain.Queries.ExportTripsTakenToExcel.TripsTakenQuery>
    ) {
      root.notifications.addNotification(`The file is being generated ...`, {
        type: NotificationType.info,
      });

      return yield ajax.operations.jobs.exportTripsTakenToExcel(query);
    });

    const exportJobWithPaidHoursToExcel = flow(function*(
      query: Partial<Operations.Domain.Queries.ListJobs.ListJobsQuery>
    ) {
      root.notifications.addNotification(
        `The file is being generated. This may take a few minutes. You may want to consider limiting the export to not more than a month.`,
        {
          type: NotificationType.info,
        }
      );

      return yield ajax.operations.jobs.exportJobWithPaidHoursToExcel(query);
    });

    const exportExceededShiftHoursToExcel = flow(function*(
      query: Partial<Operations.Domain.Queries.ListJobs.ListJobsQuery>
    ) {
      root.notifications.addNotification(`The file is being generated ...`, {
        type: NotificationType.info,
      });

      return yield ajax.raw
        .getFile(`/api/operations/jobs/exceeded-shift-hours/excel?${queryString.stringify(query)}`)
        .toPromise()
        .then<Blob>(r => r.response);
    });

    const exportToPdf = flow(function*(
      query: Partial<Operations.Domain.Queries.ListJobs.ListJobsQuery>,
      byDate: Boolean
    ) {
      root.notifications.addNotification(`The file is being generated ...`, {
        type: NotificationType.info,
      });

      return yield ajax.operations.jobs.exportJobsToPdf(query, byDate);
    });

    const loadRailInstructions = flow(function*(
      query: Partial<Operations.Domain.Queries.ListJobs.ListJobsQuery>
    ) {
      self.railInstructions = yield ajax.raw
        .get(operationsUrls.jobUrls.exportRailInstructions(query))
        .toPromise()
        .then(r => r.response as RailInstructions);
    });

    const loadCharterInstructions = flow(function*(
      query: Partial<Operations.Domain.Queries.ListJobs.ListJobsQuery>
    ) {
      self.charterInstructions = yield ajax.raw
        .get(operationsUrls.jobUrls.exportCharterInstructions(query))
        .toPromise()
        .then(r => r.response as CharterInstructions);
    });

    const loadCharterDailyDiaryDetails = flow(function*(
      query: Partial<Operations.Domain.Queries.ListJobs.ListJobsQuery>
    ) {
      self.charterDailyDiaryDetails = yield ajax.raw
        .get(operationsUrls.jobUrls.exportCharterDailyDiaryReport(query))
        .toPromise()
        .then<Common.Dtos.ListResult<CharterDailyDiaryDetails>>(r => r.response);
    });

    return {
      exportToExcel,
      exportTripsTakenToExcel,
      exportJobWithPaidHoursToExcel,
      exportExceededShiftHoursToExcel,
      exportToPdf,
      loadRailInstructions,
      loadCharterInstructions,
      exportVehicleReallocationsToExcel,
      exportJobHoursToExcel,
      loadCharterDailyDiaryDetails,
    };
  });

const ListDriverJobsModel = types
  .model('ListDriverJobsModel', {
    driverJobs: types.array(types.frozen<DailySummarySheetJobDetailsDto>()),
    predepartureChecks: types.array(types.frozen<PredepartureCheckItem>()),
  })
  .actions(self => {
    const ajax = getAjax(self);

    const loadJobsForDriver = flow(function*(query: Partial<ListDailySummarySheetDetailsQuery>) {
      self.driverJobs.clear();
      self.predepartureChecks.clear();

      const jobsForDriver: DailySummarySheetDetailsDto = yield ajax.operations.jobs.listDriverJobs(
        query
      );
      self.driverJobs = cast(jobsForDriver.jobDetails);
      self.predepartureChecks = cast(jobsForDriver.predepartureChecks);
    });

    return {
      loadJobsForDriver,
    };
  });

export const ListJobsModel = types.compose(
  JobsListModel,
  ExportJobsModel,
  ListJobsWithNoDriverClockedOnModel,
  ListExistingJobsForRailBookingDropdownModel,
  ListDriverJobsModel
);
