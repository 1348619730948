import {
  PagePrimarySize,
  PaneType,
  FieldType,
  IModalDefBuilderApi,
  ISectionDef,
  IPageDef,
  ActionType,
  ShellModalSize,
} from 'src/views/definitionBuilders/types';
import { useEffect, useState } from 'react';
import { useRootStore } from 'src/domain/entities/RootStoreModel';
import { observer } from 'mobx-react';
import { getSubmitCloseModalActionGroupDef } from 'src/views/definitionBuilders/common';
import Page from 'src/views/components/Page';
import { formatDateShort } from 'src/domain/dateHelper';
import { EditIcon, PlusIcon, TrashIcon } from 'src/images/icons';
import PrimaryTitle from 'src/views/components/Page/PrimaryTitle/PrimaryTitle';

type LabourPriceDetails = Workshop.Domain.Queries.GetLabourPrices.LabourPriceDetails;
type UpdateLabourPriceCommand = Workshop.Domain.Commands.LabourPrice.UpdateLabourPriceCommand;
type CreateLabourPriceCommand = Workshop.Domain.Commands.LabourPrice.CreateLabourPriceCommand;

interface IFormData {
  externalPrices: LabourPriceDetails[];
  internalPrices: LabourPriceDetails[];
}

export const MaintainLabourPrices: React.FC = observer(() => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const rootStore = useRootStore();
  const workshopModel = rootStore.workshop;
  const labourPriceModel = workshopModel.labourPrice;

  const _beginningOfTime = '0001-01-01';
  const _endOfTime = '9999-12-31';

  useEffect(() => {
    setIsLoading(true);
    labourPriceModel.getLabourPrices().then(() => setIsLoading(false));
  }, []);

  const getData = (): IFormData => {
    const labourPrices = labourPriceModel.labourPrices;
    const internalPrices = (labourPrices && labourPrices.filter(l => l.isInternalPrice)) || [];
    const externalPrices = (labourPrices && labourPrices.filter(l => !l.isInternalPrice)) || [];

    return {
      externalPrices,
      internalPrices,
    };
  };

  const getLabourPriceModalDef = (
    mode: 'add' | 'edit',
    isInternalPrice: boolean
  ): ((modalDefApi: IModalDefBuilderApi) => ISectionDef) => {
    return modalDefApi => ({
      title: mode === 'add' ? 'Add Labour Price' : 'Edit Labour Price',
      asForm: true,
      explicitData: mode === 'add' ? {} : modalDefApi.actionData.paneValue,
      panels: [
        {
          panes: [
            {
              paneType: PaneType.formFieldsPane,
              fields: [
                {
                  fieldType: FieldType.dateField,
                  mandatory: true,
                  label: 'Start Date',
                  dataAddr: 'startDate',
                  readonly: d => d.fieldValue === _beginningOfTime,
                  formatReadonly: _ => 'Beginning of Time',
                },
                {
                  fieldType: FieldType.numericField,
                  label: 'Price',
                  numericConfig: { numericType: 'unsignedDecimal', maxPointDigits: 2 },
                  dataAddr: 'price',
                  mandatory: true,
                },
              ],
            },
          ],
        },
      ],
      secondaryActions: [getSubmitCloseModalActionGroupDef('Ok')],
      onFormPreSubmit: (
        labourPrice: LabourPriceDetails
      ): CreateLabourPriceCommand | UpdateLabourPriceCommand => {
        let cmd = {
          startDate: labourPrice.startDate,
          isInternalPrice: isInternalPrice,
          price: labourPrice.price,
        };

        return mode === 'add'
          ? cmd
          : {
              ...cmd,
              id: labourPrice.id,
            };
      },
      onFormSubmit:
        mode === 'add' ? labourPriceModel.createLabourPrice : labourPriceModel.updateLabourPrice,
    });
  };

  const getPageDef = (): IPageDef => {
    return {
      primarySize: PagePrimarySize.threeQuarters,
      primarySection: {
        title: (
          <PrimaryTitle
            title="Manage Labour Prices"
            link="https://www.notion.so/Labour-Prices-947ad09f10ab49829ece133a5852cdf9"></PrimaryTitle>
        ),
        panels: [
          {
            panes: [
              {
                title: 'Internal Labour Prices',
                dataAddr: 'internalPrices',
                paneType: PaneType.tablePane,
                fields: [
                  {
                    fieldType: FieldType.textField,
                    dataAddr: 'startDate',
                    label: 'Start Date',
                    formatReadonly: d =>
                      d.fieldValue && d.fieldValue !== _beginningOfTime
                        ? formatDateShort(d.fieldValue)
                        : 'Beginning of Time',
                  },
                  {
                    fieldType: FieldType.textField,
                    dataAddr: 'endDate',
                    label: 'End Date',
                    formatReadonly: d =>
                      d.fieldValue && d.fieldValue !== _endOfTime
                        ? formatDateShort(d.fieldValue)
                        : '∞',
                  },
                  {
                    fieldType: FieldType.textField,
                    dataAddr: 'price',
                    label: 'Price',
                    formatReadonly: d => d.fieldValue && `$${d.fieldValue}`,
                  },

                  {
                    fieldType: FieldType.actionListField,
                    columnWidth: '1px',
                    nowrap: true,
                    actionGroups: [
                      {
                        actions: [
                          {
                            actionType: ActionType.modalActionButton,
                            label: 'Edit',
                            icon: <EditIcon />,
                            modalSize: ShellModalSize.oneThird,
                            modalDef: getLabourPriceModalDef('edit', true),
                          },
                          {
                            actionType: ActionType.modalActionButton,
                            label: 'Delete',
                            hidden: _ => getData().internalPrices.length === 1,
                            icon: <TrashIcon size="sm" />,
                            modalSize: ShellModalSize.oneThird,
                            modalDef: _ => ({
                              title: () => 'Delete Labour Price',
                              asForm: true,
                              panels: [
                                {
                                  panes: [
                                    {
                                      paneType: PaneType.customPane,
                                      render: () => (
                                        <p>Are you sure you want to delete this labour price?</p>
                                      ),
                                    },
                                  ],
                                },
                              ],
                              secondaryActions: [getSubmitCloseModalActionGroupDef('Ok')],
                              onFormSubmit: d => labourPriceModel.deleteLabourPrice(d.id),
                            }),
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
              {
                paneType: PaneType.actionListPane,
                actionGroups: [
                  {
                    actions: [
                      {
                        actionType: ActionType.modalActionButton,
                        label: 'Add Internal Price',
                        icon: <PlusIcon />,
                        modalSize: ShellModalSize.oneThird,
                        modalDef: getLabourPriceModalDef('add', true),
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            title: 'External Labour Prices',
            dataAddr: 'externalPrices',
            panes: [
              {
                paneType: PaneType.tablePane,
                fields: [
                  {
                    fieldType: FieldType.textField,
                    dataAddr: 'startDate',
                    label: 'Start Date',
                    formatReadonly: d =>
                      d.fieldValue && d.fieldValue !== _beginningOfTime
                        ? formatDateShort(d.fieldValue)
                        : 'Beginning of Time',
                  },
                  {
                    fieldType: FieldType.textField,
                    dataAddr: 'endDate',
                    label: 'End Date',
                    formatReadonly: d =>
                      d.fieldValue && d.fieldValue !== _endOfTime
                        ? formatDateShort(d.fieldValue)
                        : '∞',
                  },
                  {
                    fieldType: FieldType.textField,
                    dataAddr: 'price',
                    label: 'Price',
                    formatReadonly: d => d.fieldValue && `$${d.fieldValue}`,
                  },

                  {
                    fieldType: FieldType.actionListField,
                    columnWidth: '1px',
                    nowrap: true,
                    actionGroups: [
                      {
                        actions: [
                          {
                            actionType: ActionType.modalActionButton,
                            label: 'Edit',
                            icon: <EditIcon />,
                            modalSize: ShellModalSize.oneThird,
                            modalDef: getLabourPriceModalDef('edit', false),
                          },
                          {
                            actionType: ActionType.modalActionButton,
                            label: 'Delete',
                            hidden: _ => getData().externalPrices.length === 1,
                            icon: <TrashIcon size="sm" />,
                            modalSize: ShellModalSize.oneThird,
                            modalDef: _ => ({
                              title: () => 'Delete Labour Price',
                              asForm: true,
                              panels: [
                                {
                                  panes: [
                                    {
                                      paneType: PaneType.customPane,
                                      render: () => (
                                        <p>Are you sure you want to delete this labour price?</p>
                                      ),
                                    },
                                  ],
                                },
                              ],
                              secondaryActions: [getSubmitCloseModalActionGroupDef('Ok')],
                              onFormSubmit: d => labourPriceModel.deleteLabourPrice(d.id),
                            }),
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
              {
                paneType: PaneType.actionListPane,
                actionGroups: [
                  {
                    actions: [
                      {
                        actionType: ActionType.modalActionButton,
                        label: 'Add External Price',
                        icon: <PlusIcon />,
                        modalSize: ShellModalSize.oneThird,
                        modalDef: getLabourPriceModalDef('add', false),
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    };
  };

  return <Page def={getPageDef()} data={getData()} showPrimarySectionSpinner={isLoading} />;
});

export default MaintainLabourPrices;
