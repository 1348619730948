import { saveAs } from 'file-saver';
import { DateTime } from 'luxon';
import { observer } from 'mobx-react';
import { useCallback, useEffect, useState } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { ChangeState, CostType, QuoteStatus, costTypeDescription } from 'src/api/enums';
import { ENABLE_SHOW_WAITING_TIME } from 'src/appSettings';
import { useRootStore } from 'src/domain/entities/RootStoreModel';
import {
  ISplittedSkillSpecRequirements,
  splitSkillSpecRequirements,
} from 'src/domain/entities/people/staffMember/SkillSpecsHelpers';
import {
  ISplittedTechSpecRequirements,
  splitTechSpecRequirements,
  splitTechSpecs,
} from 'src/domain/entities/workshop/techSpecs/TechSpecsHelpers';
import {
  BanIcon,
  CalendarIcon,
  CheckIcon,
  CopyIcon,
  DownloadIcon,
  EditIcon,
  FilePdfIcon,
  PlusIcon,
  SuccessIcon,
  TimesIcon,
  TrashIcon,
  UndoIcon,
  WarnIcon,
} from 'src/images/icons';
import {
  LocalAllocationData,
  getAggregatedAllocationDataFromTripsByDate,
  getAllUniqueVehicleTypeIdsFromTrips,
  getDaysInPeriodInclusiveAsStrings,
  getToAndFromDatesFromTrips,
  mergeVehicleAllocationData,
} from 'src/infrastructure/allocationUtils';
import { getBase64 } from 'src/infrastructure/fileUtil';
import { formatToFixed } from 'src/infrastructure/mathUtil';
import memoizeOne from 'src/infrastructure/memoizeOne';
import Omit from 'src/infrastructure/omit';
import AuditHistory from 'src/views/components/AuditHistory/operations/AuditHistoryContainer';
import { IFile } from 'src/views/components/Page/fields/MultiFilePageField';
import PageField from 'src/views/components/Page/fields/PageField';
import { IFormApiWithoutState } from 'src/views/components/Page/forms/base';
import CrudPage, { CrudPageMode, ICrudPageDef } from 'src/views/components/Page/pages/CrudPage';
import { getSubmitCloseModalActionGroupDef } from 'src/views/definitionBuilders/common';
import {
  ActionType,
  FieldType,
  IFieldData,
  IHasChangeState,
  PagePrimarySize,
  PaneType,
  ShellModalSize,
} from 'src/views/definitionBuilders/types';
import getActivityLogPanelDef from 'src/views/routes/operations/sales/quote/maintainQuote/getActivityLogPanelDef';
import getCompleteQuoteModalDef from 'src/views/routes/operations/sales/quote/maintainQuote/getCompleteQuoteModalDef';
import getTripRoutesPaneDef from 'src/views/routes/operations/shared/getTripRoutesPaneDef';
import { isNullOrUndefined } from 'util';
import { dateRangeOverlaps, formatDateShort } from '../../../../../../domain/dateHelper';
import RepeatGenerationPane from '../../../../../components/RepeatGenerationPane';
import getSchema from '../../../bookingsForOps/AuditHistorySchema';
import getSeparators from '../../../bookingsForOps/AuditHistorySeparators';
import styles from './MaintainQuote.module.scss';
import getAllowInvoicingQuoteModalDef from './getAllowInvoicingQuoteModalDef';
import getBookQuoteModalDef from './getBookQuoteModalDef';
import { getCancelBookingModalDef } from './getCancelBookingModalDef';
import getCreateCustomerContactModalDef from './getCreateCustomerContactModalDef';
import getCreateCustomerModalDef from './getCreateCustomerModalDef';
import getDeclineQuoteModalDef from './getDeclineQuoteModalDef';
import getDuplicateQuoteModalDef from './getDuplicateQuoteModalDef';
import getMaintainOptionModalDef from './getMaintainOptionModalDef';
import getMarkQuoteAsReviewedModalDef from './getMarkQuoteAsReviewedModalDef';
import getResetQuoteReviewedModalDef from './getResetQuoteReviewedModalDef';
import getSuspendInvoicingQuoteModalDef from './getSuspendInvoicingQuoteModalDef';
import getUpdateFutureQuotesModalDef from './getUpdateFutureQuotesModalDef';
import { findFirstTrip, getFutureRepeats, isQuote } from './utils/quoteHelpers';
import { priceExGst } from './utils/quoteMaths';
import { PartialRepeat } from './utils/repeatDatesMaths';

type CreateCustomerCommand = Operations.Domain.Commands.Customer.CreateCustomerCommand;
type CreateBoardingPointCommand = Operations.Domain.Commands.BoardingPoint.CreateBoardingPointCommand;
type QuoteItem = Operations.Domain.Queries.ViewQuote.QuoteItem;
type JobItem = Operations.Domain.Queries.ViewQuote.JobItem;
type CreateQuoteCommand = Operations.Domain.Commands.Quote.CreateQuoteCommand;
type UpdateQuoteCommand = Operations.Domain.Commands.Quote.UpdateQuote.UpdateQuoteCommand;
type QuoteTypeDto = Common.Dtos.QuoteTypeDto;
type Trip = Operations.Domain.Queries.ViewQuote.Trip;
type Option = Operations.Domain.Queries.ViewQuote.Option;
type Route = Operations.Domain.Queries.ViewQuote.Route;
type Vehicle = Operations.Domain.Queries.ViewQuote.Vehicle;
type Extra = Operations.Domain.Queries.ViewQuote.Extra;
type Repeat = Operations.Domain.Queries.ViewQuote.Repeat;
type QuotePdfItem = Operations.Domain.Queries.ListQuotePdfs.QuotePdfItem;
type SelectedOption = Operations.Domain.AggregatesModel.QuoteAggregate.SelectedOption;
type QuoteDriverPdfDto = Operations.Domain.Commands.Quote.QuoteDriverPdfDto;
type CustomerNote = Operations.Domain.AggregatesModel.CustomerAggregate.CustomerNote;
type HolidayListItem = Operations.Domain.Queries.ListHolidays.HolidayListItem;

type OverAllocationMessages = {
  [key: number]: Array<{
    toDate: string;
    fromDate: string;
    overallocationMessage: string;
  }>;
}[];

const emptyTrip = {
  routes: [{ changeState: ChangeState.Added }, { changeState: ChangeState.Added }],
  options: [],
};
const emptyQuote = { trips: [emptyTrip], repeats: [] };

type QuoteDto = ISplittedSkillSpecRequirements &
  ISplittedTechSpecRequirements &
  Omit<QuoteItem, 'driverPdfs' | 'trips'> & { driverPdfs: IFile[] } & {
    trips: {
      description: string;
      driverPdfs: Operations.Domain.Commands.Quote.QuoteDriverPdfDto[];
      id: number;
      note: string;
      options: {
        costTypeId: number;
        distance?: number;
        durationPublicHoliday?: number;
        durationPublicHolidayWaiting?: number;
        durationSaturday?: number;
        durationSaturdayWaiting?: number;
        durationSunday?: number;
        durationSundayWaiting?: number;
        durationWeekday?: number;
        durationWeekdayWaiting?: number;
        extras: Operations.Domain.Queries.ViewQuote.Extra[];
        id: number;
        optionNumber: number;
        overrideTotalGst?: number;
        overrideTotalPriceExGst?: number;
        selected?: boolean;
        vehicles: IQuoteOptionVehicle[];
      }[];
      passengers?: number;
      routes: Operations.Domain.Queries.ViewQuote.Route[];
      tripNumber: number;
    }[];
  };

type IQuoteOptionVehicle = ISplittedSkillSpecRequirements &
  ISplittedTechSpecRequirements & {
    bufferPrice?: number;
    changeState: Common.ChangeState;
    id: number;
    overridePrice?: number;
    overrideReason: string;
    price: number;
    pricePerVehicle?: number;
    quantity: number;
    skillSpecRequirements: Operations.Domain.Queries.ViewQuote.QuoteVehicleSkillSpecRequirementItem[];
    techSpecRequirements: Operations.Domain.Queries.ViewQuote.QuoteVehicleTechSpecRequirementItem[];
    vehicleType: Common.Dtos.VehicleTypeItem;
  };

interface ISubmissionMeta {
  updateAll?: boolean;
  skipRepeatTripUpdate?: string[];
}

interface IMapQuoteOptions {
  isCreate: boolean;
  skipRepeatTripUpdate: string[];
}

interface IPublicHolidayDetail {
  date: string;
  name: string;
}
export interface IPublicHolidayOverlapItem {
  tripNumber: number;
  holidays: IPublicHolidayDetail[];
}

export interface IPublicHolidayOverlapTripItem {
  tripNumber: number;
  dates: string[];
}

type IMapQuoteUserOptions = Partial<IMapQuoteOptions>;

export interface IMaintainQuoteProps {
  mode: CrudPageMode;
  route: RouteComponentProps<{ [x: string]: string | undefined }>;
}

const bookableStatuses = [QuoteStatus.Quoted, QuoteStatus.Declined];
const bookedStatuses = [
  QuoteStatus.Booked,
  QuoteStatus.Cancelled,
  QuoteStatus.Completed,
  QuoteStatus.CancelledInProgress,
];

const allocatedStatuses = [QuoteStatus.Booked, QuoteStatus.Completed];

const MaintainQuote: React.FC<IMaintainQuoteProps> = observer((props: IMaintainQuoteProps) => {
  const [formApi, setFormApi] = useState<IFormApiWithoutState | undefined>(undefined);
  const isUpdateMode = props.mode === 'update';

  const showWaitingField = ENABLE_SHOW_WAITING_TIME;
  const rootStore = useRootStore();
  const sales = rootStore.operations.sales;
  const quoteId = props.route.match.params.id;
  const loadQuote = sales.quotes.quoteItem.loadQuote;
  const currentQuoteItem = sales.quotes.quoteItem.item;
  const quote = currentQuoteItem?.id === quoteId ? currentQuoteItem : undefined;
  const noQuoteFailure = () => Promise.reject('No Quote loaded');
  const canManageQuotes = rootStore.account.isSalesDepartmentMember;
  const canVerifyInvoiceNumber = rootStore.account.isAccountingDepartmentMember;
  const getPublicHolidays = rootStore.operations.holidays.getPublicHolidays;

  const onCreateQuote = sales.quotes.quoteItem.createQuote;
  const onUpdateQuote = (
    promise: Promise<Operations.Domain.Commands.Quote.UpdateQuote.UpdateQuoteCommand>
  ) =>
    quote
      ? sales.quotes.quoteItem.updateQuote(
          promise,
          isQuote(quote.status.id),
          getFutureRepeats(quote).length
        )
      : noQuoteFailure();
  const onGeneratePdf = () => (quote ? sales.quotes.pdfs.generatePdf(quote.id) : noQuoteFailure());
  const onDeclineQuote = (reason: string) =>
    quote ? sales.quotes.quoteItem.declineQuote({ quoteId: quote.id, reason }) : noQuoteFailure();
  const onCompleteQuote = () =>
    quote ? sales.quotes.quoteItem.completeQuote(quote.id) : noQuoteFailure();
  const onSuspendInvoicingQuote = () =>
    quote ? sales.quotes.quoteItem.suspendInvoicingQuote(quote.id) : noQuoteFailure();
  const onAllowInvoicingQuote = () =>
    quote ? sales.quotes.quoteItem.allowInvoicingQuote(quote.id) : noQuoteFailure();
  const onMarkQuoteAsReviewed = () =>
    quote ? sales.quotes.quoteItem.markQuoteAsReviewed(quote.id) : noQuoteFailure();
  const onResetQuoteReviewed = () =>
    quote ? sales.quotes.quoteItem.resetQuoteReviewed(quote.id) : noQuoteFailure();
  const onDuplicateQuote = () =>
    quote ? sales.quotes.quoteItem.duplicateQuote({ quoteId: quote.id }) : noQuoteFailure();
  const onBookQuote = (selectedOptions: SelectedOption[]) =>
    quote
      ? sales.quotes.quoteItem.bookQuote({ quoteId: quote.id, selectedOptions })
      : noQuoteFailure();
  const onVerifyQuote = () =>
    quote ? sales.quotes.quoteItem.verifyBooking(quote.id) : noQuoteFailure();
  const onCancelBooking = (
    cancellationReason: string,
    cancelFutureBookingRecurrences: boolean,
    forceInProgressCancel: boolean
  ) =>
    quote
      ? sales.quotes.quoteItem.cancelBooking({
          quoteId: quote.id,
          cancellationReason,
          jobs: quote.jobs,
          cancelFutureBookingRecurrences,
          forceInProgressCancel,
        })
      : noQuoteFailure();

  const searchCustomers = sales.customer.searchCustomers;
  const findCustomers = sales.customer.findCustomers;
  const searchContactsForCustomer = sales.customer.searchContactsForCustomer;
  const findContactsForCustomer = sales.customer.findContactsForCustomer;
  const onCreateCustomerContact = sales.customer.createContact;

  const loadTradingCompanies = sales.quotes.loadTradingCompanies;
  const loadQuoteTypes = sales.quotes.loadQuoteTypes;
  const quoteTypesForDropdown = sales.quotes.quoteTypesForDropdown.slice();
  const allQuoteTypes = sales.quotes.quoteTypes.slice();
  const tradingCompanies = sales.quotes.tradingCompanies.slice();

  const searchBoardingPoints = sales.boardingPoint.searchBoardingPoints;
  const findBoardingPoints = sales.boardingPoint.findBoardingPoints;
  const boardingPoints = sales.boardingPoint.foundBoardingPoints.slice();
  const checkForUniqueBoardingPointName = sales.boardingPoint.checkForUniqueName;
  const onCreateBoardingPoint = (cmd: CreateBoardingPointCommand) =>
    sales.boardingPoint.createBoardingPoint(cmd, true);
  const onCreateCustomer = (cmd: CreateCustomerCommand) => sales.customer.createCustomer(cmd, true);
  const checkForUniqueCustomerName = rootStore.operations.sales.customer.checkForUniqueName;
  const vehicleTypes = rootStore.operations.vehicleTypes.vehicleTypes.slice();
  const loadVehicleTypes = rootStore.operations.vehicleTypes.loadVehicleTypes;

  const extraTypes = rootStore.operations.extraTypes.list.charterExtraTypes.slice();
  const loadExtraTypes = rootStore.operations.extraTypes.list.loadExtraTypes;

  const states = sales.boardingPoint.states.slice();
  const loadStates = sales.boardingPoint.loadStates;

  const quotePdfs = sales.quotes.pdfs.quotePdfs.slice();
  const loadQuotePdfs = sales.quotes.pdfs.loadQuotePdfs;
  const getPdf = sales.quotes.pdfs.getPdf;

  const listActivityLogs = sales.quotes.quoteItem.listActivityLogs;
  const activityLogs = sales.quotes.quoteItem.activityLogs.slice();

  const hourlyRates = rootStore.operations.sales.hourlyRates.hourlyRates.slice();
  const loadHourlyRates = rootStore.operations.sales.hourlyRates.listHourlyRates;
  const getQuoteDriverPdf = rootStore.operations.sales.quotes.getQuoteDriverPdf;
  const getTripDriverPdf = rootStore.operations.sales.quotes.getTripDriverPdf;
  const jobsForCancellingQuote = rootStore.operations.sales.quotes.quoteItem.jobsForCancellingQuote;
  const loadJobsForCancellingQuote =
    rootStore.operations.sales.quotes.quoteItem.loadJobsForCancellingQuote;
  const getVehicleTypeAllocationData =
    rootStore.operations.allocations.getVehicleTypeAllocationData;
  const vehicleTypeAllocationData = rootStore.operations.allocations.vehicleTypeAllocations.slice();
  const getAllocationDataForQuoteBooking =
    rootStore.operations.allocations.getAllocationDataForQuoteBooking;
  const vehicleTypeAllocationDataForQuoteBooking = rootStore.operations.allocations.vehicleTypeAllocationDataForQuoteBooking.slice();
  const needsVerificationStartDateItem =
    sales.quotes.bookingForOpsItem.needsVerificationStartDateItem;
  const salesRepresentatives = rootStore.operationsStartup.salesPeople;
  const operationsDepots = rootStore.operationsStartup.operationsDepots;
  const defaultOperationsDepot = rootStore.operationsStartup.defaultOperationsDepot;
  const defaultSalesRepresentativeId = rootStore.operationsStartup.defaultSalesRepresentativeId;
  const defaultTradingCompany = sales.quotes.defaultTradingCompany;
  const searchTechSpecValues = rootStore.assets.searchTechSpecValues;
  const techSpecsModel = rootStore.workshop.techSpecs;
  const loadTechSpecs = techSpecsModel.forRequirements.getAll;
  const techSpecs = techSpecsModel.forRequirements.items.slice();
  const skillSpecs = rootStore.people.skillSpecs.forRequirements.items.slice();
  const loadSkillSpecs = rootStore.people.skillSpecs.forRequirements.getAll;
  const splittedTechSpecs = splitTechSpecs(techSpecs);

  const today = DateTime.local();

  const routes = quote?.trips[0].routes;
  const earliestTrip = () => {
    const earliestTripRoute = quote?.trips
      .map(trip => {
        return {
          tripNumber: trip.tripNumber,
          earliestDate: trip.routes[0].date,
        };
      })
      .reduce((acc, curr) => (curr.earliestDate < acc.earliestDate ? curr : acc));

    const earliestTripNumber = earliestTripRoute?.tripNumber ?? 0;

    return quote?.trips[earliestTripNumber].routes;
  };

  const [originalRepeats, setOriginalRepeats] = useState<Repeat[] | undefined>(undefined);
  const [tripRoute, setTripRoute] = useState<Route[] | undefined>(earliestTrip());
  const [tripHasPublicHoliday, setTripHasPublicHoliday] = useState<boolean>(false);
  const [publicHolidayOverlaps, setPublicHolidayOverlaps] = useState<IPublicHolidayOverlapItem[]>(
    []
  );
  const [publicHolidays, setPublicHolidays] = useState<HolidayListItem[]>([]);
  const [depotId, setDepotId] = useState<number>(
    quote?.operationsDepotId ?? currentQuoteItem?.operationsDepotId ?? operationsDepots[0].id
  );

  const getVehicleAllocations = (trips: Trip[], operationsDepotId: number) => {
    const { toDate, fromDate } = getToAndFromDatesFromTrips(trips);
    if (toDate && fromDate) {
      getVehicleTypeAllocationData(
        fromDate,
        toDate,
        getAllUniqueVehicleTypeIdsFromTrips(trips),
        currentQuoteItem?.id ?? quoteId,
        operationsDepotId
      );
    }
  };

  useEffect(() => {
    if (quote) {
      setTripRoute(earliestTrip());
    }
  }, [routes]);

  const getHolidays = useCallback(async () => {
    let response = await getPublicHolidays(today.minus({ years: 1 }), today.plus({ years: 2 }));
    response = await response;
    setPublicHolidays(response);
  }, []);

  useEffect(() => {
    getHolidays();
  }, [getHolidays]);

  useEffect(() => {
    if (quoteId) {
      loadQuote(quoteId);
      setOriginalRepeats(quote?.repeats);
      sales.quotes.bookingForOpsItem.getNeedsVerificationStartDate(quoteId);

      const boardingPointIds = new Set<string>();
      (
        quote?.trips.map(t => {
          return t.routes;
        }) || []
      ).forEach((routes: Route[]) =>
        routes.forEach(route => {
          if (route.location.boardingPointId) {
            boardingPointIds.add(route.location.boardingPointId);
          }
        })
      );

      findBoardingPoints(Array.from(boardingPointIds.values()));
      loadQuotePdfs(quoteId);
      listActivityLogs(quoteId);
    }
  }, [quoteId]);

  useEffect(() => {
    loadTradingCompanies();
    loadQuoteTypes();
    loadVehicleTypes();
    loadExtraTypes();
    loadStates();
    loadHourlyRates();
    loadSkillSpecs();
    loadTechSpecs();
    techSpecsModel.getTechSpecDropdownsOptions({ forOperations: true });

    setDepotId(quote?.operationsDepotId ?? quote?.operationsDepotId ?? operationsDepots[0].id);

    if (props.mode === 'create') {
      sales.quotes.quoteItem.clearQuote();
    }
  }, []);

  useEffect(() => {
    if (currentQuoteItem && props.mode !== 'create') {
      let overlappedDates: IPublicHolidayOverlapItem[] = [];
      const tripRoutes = currentQuoteItem.trips.map((t: Trip) => ({
        tripNumber: t.tripNumber,
        dates: t.routes.map((r: Route) => r.date),
      })) as IPublicHolidayOverlapTripItem[];

      tripRoutes?.forEach(trip => {
        const distinctDates = [...new Set(trip.dates)] as string[];
        const holidayDates = [...new Set(publicHolidays.map(h => h.startDate))];
        const overlapDates = distinctDates.filter(d => holidayDates.includes(d));

        const item = {
          tripNumber: trip.tripNumber,
          holidays: publicHolidays
            ?.filter(h => overlapDates.includes(h.startDate))
            .map(h => ({
              date: publicHolidays.find(ph => ph.id === h.id)?.startDate,
              name: h.description,
            })),
        } as IPublicHolidayOverlapItem;

        if (overlapDates.length) overlappedDates.push(item);

        holidayDates.forEach(date => {
          const dateOverlaps = dateRangeOverlaps(
            DateTime.fromISO(trip.dates[0]),
            DateTime.fromISO(trip.dates[trip.dates.length - 1] ?? trip.dates[0]),
            DateTime.fromISO(date),
            DateTime.fromISO(date)
          );

          if (dateOverlaps && !overlapDates.includes(date)) {
            const holidayDetail = publicHolidays?.find(ph => ph.startDate === date);

            const item = {
              tripNumber: trip.tripNumber,
              holidays: [
                {
                  date: holidayDetail?.startDate,
                  name: holidayDetail?.description,
                },
              ],
            } as IPublicHolidayOverlapItem;
            overlappedDates.push(item);
          }
        });

        if (overlappedDates.length > 0) {
          setTripHasPublicHoliday(true);
          setPublicHolidayOverlaps([...new Set(overlappedDates)]);
        } else {
          setTripHasPublicHoliday(false);
          setPublicHolidayOverlaps([]);
        }

        if (currentQuoteItem?.trips.some(t => t.options && t.options.length > 0)) {
          setDepotId(currentQuoteItem.operationsDepotId);
          getVehicleAllocations(currentQuoteItem.trips, currentQuoteItem.operationsDepotId);
        }
      });
    }
  }, [currentQuoteItem]);

  const canBeBooked = () => {
    const status = quote && quote.status && quote.status.id;
    return !!(status && bookableStatuses.some(s => s === status));
  };

  const hasBeenBooked = () => {
    const status = quote && quote.status && quote.status.id;
    return !!(status && bookedStatuses.some(s => s === status));
  };

  const hasBeenAllocated = () => {
    const status = quote && quote.status && quote.status.id;
    return !!(status && allocatedStatuses.some(s => s === status));
  };

  const sourceQuote = quote?.sourceQuote;

  const isContractPriceManuallyManaged = memoizeOne((quote: Partial<QuoteItem> | undefined) => {
    return !!(quote && quote.quoteType && quote.quoteType.isContractPriceManuallyManaged);
  });

  const handlePreSubmitForCreate = async (q: QuoteDto): Promise<CreateQuoteCommand> => {
    return await mapQuote(q, { isCreate: true });
  };

  const handlePreSubmitForUpdate = async (
    q: QuoteDto,
    meta: ISubmissionMeta | undefined
  ): Promise<UpdateQuoteCommand> => {
    const mappedQuote = await mapQuote(q, {
      skipRepeatTripUpdate: meta && meta.skipRepeatTripUpdate,
    });

    return {
      ...mappedQuote,
      updateAll: !!(meta && meta.updateAll),
    };
  };

  const getQuoteDriverPdfDto = async (file: IFile) => {
    if (file.file) {
      return {
        id: undefined,
        attachmentId: undefined,
        name: file.name,
        data: await getBase64(file.file),
        changeState: ChangeState.Added,
      } as QuoteDriverPdfDto;
    }
    return {
      id: file.id,
      data: '',
      name: file.name,
      changeState: file.changeState,
      attachmentId: file.attachmentId,
    } as QuoteDriverPdfDto;
  };

  const getPdfs = async (pdfs: IFile[]): Promise<QuoteDriverPdfDto[]> => {
    if (!pdfs || pdfs.length === 0) {
      return Promise.resolve([]);
    }

    return Promise.all(pdfs.map(d => getQuoteDriverPdfDto(d)));
  };

  const mapQuote = async (q: QuoteDto, options: IMapQuoteUserOptions = {}) => {
    const consolidatedOptions: IMapQuoteOptions = {
      isCreate: options.isCreate === undefined ? false : options.isCreate,
      skipRepeatTripUpdate:
        options.skipRepeatTripUpdate === undefined ? [] : options.skipRepeatTripUpdate,
    };
    const formFiles = await getPdfs(q.driverPdfs);
    const properFormFiles = formFiles.filter(t => !!t).map(d => d as QuoteDriverPdfDto);

    const tripFilePromises = q.trips.map(async t => {
      const pdfs = await getPdfs(t.driverPdfs);
      return { trip: t, files: pdfs };
    });

    const tripFiles = new Map<Trip, QuoteDriverPdfDto[]>();
    (await Promise.all(tripFilePromises)).forEach(x => tripFiles.set(x.trip, x.files));

    const mapped = {
      id: q.id,
      customerId: q.customer.customerId,
      contactId: q.contact && q.contact.contactId,
      quoteTypeId: q.quoteType.id,
      description: q.description,
      invoiceNumber: q.invoiceNumber,
      purchaseOrderNumber: q.purchaseOrderNumber,
      cancellationReason: q.cancellationReason,
      trips: q.trips.map(trip => {
        const properTripFiles = tripFiles
          .get(trip)!
          .filter(t => !!t)
          .map(d => d as QuoteDriverPdfDto);

        return {
          id: trip.id,
          description: trip.description,
          note: trip.note,
          passengers: trip.passengers,
          changeState: (trip as IHasChangeState).changeState || ChangeState.Unchanged,
          routes: trip.routes.map(route => {
            const location = route.location;
            const changeState = (route as IHasChangeState).changeState || ChangeState.Unchanged;
            if (changeState === ChangeState.Deleted) {
              return {
                id: route.id,
                changeState: changeState,
                date: DateTime.local().toISODate(),
                boardingPointId: undefined,
                name: '',
                address: '',
                city: '',
                state: '',
                postcode: '',
                notes: '',
                arrive: undefined,
                depart: undefined,
              };
            } else {
              return {
                id: route.id,
                changeState: changeState,
                date: route.date,
                boardingPointId:
                  // location can be a BoardingPointListItem if a new boarding point was selected
                  location.boardingPointId ||
                  (location as Operations.Domain.Queries.SearchBoardingPoint.BoardingPointListItem)
                    .id,
                name: location.name,
                address: location.address,
                city: location.city,
                state: location.state,
                postcode: location.postcode,
                notes: location.notes,
                arrive: route.arrive,
                depart: route.depart,
              };
            }
          }),
          options: (Array.isArray(trip.options) ? trip.options : []).map(option => {
            const isKmsBased = option.costTypeId === CostType.Kilometers;
            return {
              id: option.id,
              changeState: (option as IHasChangeState).changeState || ChangeState.Unchanged,
              selected: option.selected,
              costTypeId: option.costTypeId,
              durationWeekday: isKmsBased ? option.durationWeekday : undefined,
              durationSaturday: isKmsBased ? option.durationSaturday : undefined,
              durationSunday: isKmsBased ? option.durationSunday : undefined,
              durationPublicHoliday: isKmsBased ? option.durationPublicHoliday : undefined,
              durationWeekdayWaiting:
                isKmsBased && showWaitingField ? option.durationWeekdayWaiting : undefined,
              durationSaturdayWaiting:
                isKmsBased && showWaitingField ? option.durationSaturdayWaiting : undefined,
              durationSundayWaiting:
                isKmsBased && showWaitingField ? option.durationSundayWaiting : undefined,
              durationPublicHolidayWaiting: isKmsBased
                ? option.durationPublicHolidayWaiting
                : undefined,
              distance: isKmsBased ? option.distance : undefined,
              vehicles: option.vehicles
                .filter(
                  v =>
                    !consolidatedOptions.isCreate ||
                    (v as IHasChangeState).changeState !== ChangeState.Deleted
                )
                .map(vehicle => ({
                  id: vehicle.id,
                  changeState: (vehicle as IHasChangeState).changeState || ChangeState.Unchanged,
                  vehicleTypeId: vehicle.vehicleType.id,
                  quantity: vehicle.quantity,
                  bufferPrice: vehicle.bufferPrice,
                  price: vehicle.price,
                  overridePrice: isKmsBased ? vehicle.overridePrice : undefined,
                  overrideReason: isKmsBased ? vehicle.overrideReason : '',
                  pricePerVehicle: isKmsBased ? undefined : vehicle.pricePerVehicle,
                  skillSpecRequirements: vehicle.skillSpecRequirements,
                  techSpecRequirements: vehicle.techSpecRequirements,
                })),
              extras: (Array.isArray(option.extras) ? option.extras : []).map(extra => ({
                id: extra.id,
                changeState: (extra as IHasChangeState).changeState || ChangeState.Unchanged,
                extraTypeId: extra.extraType.id,
                price: extra.price,
                quantity: extra.quantity,
                overridePrice: extra.overridePrice,
              })),
              overrideTotalPriceExGst: option.overrideTotalPriceExGst,
              overrideTotalGst: option.overrideTotalGst,
            };
          }),
          driverPdfs: properTripFiles,
        };
      }),
      customerNote: q.customerNote,
      internalNote: q.internalNote,
      driverNote: q.driverNote,
      repeats: (Array.isArray(q.repeats) ? q.repeats : []).map((d: Repeat & IHasChangeState) => {
        const repeat = {
          id: d.id,
          changeState: d.changeState || ChangeState.Unchanged,
          repeatDate: d.repeatDate,
          linkedQuoteId: d.linkedQuoteId,
          cancelledReason: d.cancelledReason,
        };
        if (consolidatedOptions.isCreate) {
          return repeat;
        }
        const repeatUpdate = {
          ...repeat,
          skipTripsUpdate:
            !!repeat.linkedQuoteId &&
            !!consolidatedOptions.skipRepeatTripUpdate.find(id => id === repeat.linkedQuoteId!),
        };
        return repeatUpdate;
      }),
      driverPdfs: properFormFiles,
      salesRepresentativeId: q.salesRepresentative.id,
      operationsDepotId: q.operationsDepotId,
    };

    return mapped;
  };

  const downloadQuoteDriverPdf = (pdfId: number, name: string) => {
    return getQuoteDriverPdf(pdfId).then(x => saveAs(x, name));
  };

  const downloadTripDriverPdf = (pdfId: number, name: string) => {
    return getTripDriverPdf(pdfId).then(x => saveAs(x, name));
  };

  const getOverallocationMessageData = (
    mergedVehicleAllocationData: LocalAllocationData,
    trips: Trip[]
  ): { overallocatedVehicleFound: boolean; overallocationMessages: OverAllocationMessages } => {
    let overallocatedVehicleFound = false;
    const overallocationMessages: {
      [key: number]: Array<{
        toDate: string;
        fromDate: string;
        overallocationMessage: string;
      }>;
    }[] = [];

    for (let i = 0; i < trips.length; i++) {
      const trip = trips[i];
      const firstTripDate = DateTime.fromISO(trip.routes[0].date);
      const lastTripDate = DateTime.fromISO(trip.routes[trip.routes.length - 1].date);

      if (firstTripDate.isValid && lastTripDate.isValid) {
        overallocationMessages[i] = {
          [i]: [],
        };

        const datesInRange = getDaysInPeriodInclusiveAsStrings(firstTripDate, lastTripDate);

        for (const option of trip.options) {
          for (const dateString of datesInRange) {
            for (const vehicle of option.vehicles) {
              const currentSavedAllocationData = vehicleTypeAllocationData.find(
                vta => vta.day === dateString
              );

              if (currentSavedAllocationData === undefined) continue;

              const vehicleAllocationData = currentSavedAllocationData.items.find(
                v => v.vehicleTypeId === vehicle.vehicleType.id
              );

              if (!vehicleAllocationData) continue;

              const totalPotentialAllocationsForVehicle =
                mergedVehicleAllocationData[dateString][vehicle.vehicleType.id].numberBookedOnDay +
                mergedVehicleAllocationData[dateString][vehicle.vehicleType.id].numberQuotedOnDay;

              const operationDepot =
                operationsDepots.find(depot => depot.id === depotId) ?? operationsDepots[0];

              if (
                totalPotentialAllocationsForVehicle > vehicleAllocationData.numberVehiclesAtDepot
              ) {
                overallocatedVehicleFound = true;

                const overallocationVehicle =
                  mergedVehicleAllocationData[dateString][vehicle.vehicleType.id];
                const overallocationMessage = `${vehicle.vehicleType.description} - ${totalPotentialAllocationsForVehicle} potentially required (${overallocationVehicle.numberQuotedOnDay} quoted, ${overallocationVehicle.numberBookedOnDay} booked), ${vehicleAllocationData.numberVehiclesAtDepot} in fleet at ${operationDepot.description} Depot`;
                const thisTripsOverallocationRecords =
                  overallocationMessages[overallocationMessages.length - 1][i] || [];
                const previousRecord =
                  overallocationMessages[i][i][thisTripsOverallocationRecords.length - 1];

                if (
                  previousRecord &&
                  previousRecord.overallocationMessage === overallocationMessage
                ) {
                  previousRecord.toDate = dateString;
                  continue;
                }

                thisTripsOverallocationRecords.push({
                  toDate: dateString,
                  fromDate: dateString,
                  overallocationMessage: overallocationMessage,
                });
              }
            }
          }
        }
      }
    }

    return { overallocatedVehicleFound, overallocationMessages };
  };

  const getPageDef = (updating: boolean): ICrudPageDef => {
    const editable = !isUpdateMode || updating;
    const entityName = hasBeenBooked() ? 'Booking' : 'Quote';
    const futureRepeats =
      currentQuoteItem && hasBeenBooked() ? getFutureRepeats(currentQuoteItem) : [];
    const jobs = currentQuoteItem ? currentQuoteItem.jobs : [];

    const hasInProgressJobs = (quote: QuoteItem) => quote.jobs.some(j => j.hasJobProgress);

    return {
      primarySize: PagePrimarySize.twoThirds,
      primarySection: {
        title: isUpdateMode
          ? `${entityName} ${(currentQuoteItem && currentQuoteItem.quoteNumber) || ''}`
          : 'Create a Quote',
        badge:
          currentQuoteItem && currentQuoteItem.status
            ? {
                label: currentQuoteItem.invoicingSuspended
                  ? `${currentQuoteItem.status.description} - Invoicing suspended`
                  : currentQuoteItem.status.description,
              }
            : undefined,
        getApi: api => setFormApi(api),
        primaryActions: [
          {
            actions: [
              {
                actionType: ActionType.actionCollection,
                hidden: editable,
                actionGroups: [
                  {
                    actions: [
                      {
                        actionType: ActionType.modalActionButton,
                        label: 'Book',
                        icon: <CheckIcon fixedWidth />,
                        modalSize: ShellModalSize.oneThird,
                        modalDef: getBookQuoteModalDef(
                          currentQuoteItem!,
                          onBookQuote,
                          vehicleTypeAllocationDataForQuoteBooking
                        ),
                        hidden: !canBeBooked() || !canManageQuotes,
                        onOpenModal: _ => {
                          const trips = currentQuoteItem?.trips ?? [];
                          const { toDate, fromDate } = getToAndFromDatesFromTrips(trips);

                          const vehicleTypeIds: Array<string> = getAllUniqueVehicleTypeIdsFromTrips(
                            trips
                          );

                          return getAllocationDataForQuoteBooking(
                            fromDate,
                            toDate,
                            vehicleTypeIds,
                            currentQuoteItem?.operationsDepotId ?? operationsDepots[0].id
                          );
                        },
                      },
                      {
                        actionType: ActionType.modalActionButton,
                        label: 'Decline',
                        icon: <TimesIcon fixedWidth />,
                        modalSize: ShellModalSize.oneQuarter,
                        modalDef: getDeclineQuoteModalDef(onDeclineQuote),
                        hidden:
                          !currentQuoteItem ||
                          !canManageQuotes ||
                          currentQuoteItem.status.id !== QuoteStatus.Quoted,
                      },
                      {
                        actionType: ActionType.modalActionButton,
                        label: 'Mark as reviewed',
                        icon: <SuccessIcon />,
                        modalSize: ShellModalSize.oneQuarter,
                        modalDef: getMarkQuoteAsReviewedModalDef(onMarkQuoteAsReviewed),
                        hidden: !currentQuoteItem || !canManageQuotes || currentQuoteItem.reviewed,
                      },
                      {
                        actionType: ActionType.modalActionButton,
                        label: 'Reset reviewed',
                        icon: <UndoIcon />,
                        modalSize: ShellModalSize.oneQuarter,
                        modalDef: getResetQuoteReviewedModalDef(onResetQuoteReviewed),
                        hidden: !currentQuoteItem || !canManageQuotes || !currentQuoteItem.reviewed,
                      },
                      {
                        actionType: ActionType.actionButton,
                        label: 'Verify Price',
                        icon: <SuccessIcon fixedWidth />,
                        onClick: onVerifyQuote,
                        hidden:
                          !currentQuoteItem ||
                          !currentQuoteItem.needsVerification ||
                          !canVerifyInvoiceNumber,
                      },
                      {
                        actionType: ActionType.modalActionButton,
                        label: 'Complete',
                        icon: <CheckIcon fixedWidth />,
                        modalSize: ShellModalSize.oneQuarter,
                        modalDef: getCompleteQuoteModalDef(onCompleteQuote),
                        hidden: () =>
                          !currentQuoteItem || !canManageQuotes || !currentQuoteItem.canBeCompleted,
                      },
                      {
                        actionType: ActionType.modalActionButton,
                        label: 'Suspend invoicing',
                        icon: <BanIcon />,
                        modalSize: ShellModalSize.oneQuarter,
                        modalDef: getSuspendInvoicingQuoteModalDef(onSuspendInvoicingQuote),
                        hidden:
                          !currentQuoteItem ||
                          !canManageQuotes ||
                          currentQuoteItem.invoicingSuspended ||
                          currentQuoteItem.status.id !== QuoteStatus.Booked ||
                          (!isNullOrUndefined(currentQuoteItem.invoiceNumber) &&
                            currentQuoteItem.invoiceNumber.trim() !== ''),
                      },
                      {
                        actionType: ActionType.modalActionButton,
                        label: 'Allow invoicing',
                        icon: <SuccessIcon />,
                        modalSize: ShellModalSize.oneQuarter,
                        modalDef: getAllowInvoicingQuoteModalDef(onAllowInvoicingQuote),
                        hidden:
                          !currentQuoteItem ||
                          !currentQuoteItem.invoicingSuspended ||
                          !canManageQuotes,
                      },
                      {
                        actionType: ActionType.modalActionButton,
                        label: `Cancel${
                          currentQuoteItem && hasInProgressJobs(currentQuoteItem)
                            ? ' in Progress'
                            : ''
                        }`,
                        icon: <TimesIcon fixedWidth />,
                        modalSize: ShellModalSize.half,

                        onOpenModal: async () =>
                          await loadJobsForCancellingQuote(currentQuoteItem!.id, false),
                        modalDef: getCancelBookingModalDef(
                          currentQuoteItem!,
                          jobsForCancellingQuote,
                          onCancelBooking
                        ),
                        hidden:
                          !currentQuoteItem ||
                          currentQuoteItem.status.id !== QuoteStatus.Booked ||
                          !canManageQuotes,
                      },
                      {
                        actionType: ActionType.modalActionButton,
                        label: 'Cancel in Progress',
                        icon: <TimesIcon fixedWidth />,
                        modalSize: ShellModalSize.half,

                        onOpenModal: async () =>
                          await loadJobsForCancellingQuote(currentQuoteItem!.id, true),
                        modalDef: getCancelBookingModalDef(
                          currentQuoteItem!,
                          jobsForCancellingQuote,
                          onCancelBooking,
                          true
                        ),
                        hidden:
                          !currentQuoteItem ||
                          currentQuoteItem.status.id !== QuoteStatus.Booked ||
                          hasInProgressJobs(currentQuoteItem) ||
                          !canManageQuotes,
                      },
                    ],
                  },
                  {
                    actions: [
                      {
                        actionType: ActionType.actionButton,
                        label: `Generate ${
                          currentQuoteItem &&
                          (currentQuoteItem.status.id === QuoteStatus.Cancelled ||
                            currentQuoteItem.status.id === QuoteStatus.CancelledInProgress)
                            ? 'CANCELLED'
                            : ''
                        } PDF`,
                        icon: <FilePdfIcon fixedWidth />,
                        onClick: generatePdf,
                      },
                      {
                        hidden: () => !canManageQuotes,
                        actionType: ActionType.modalActionButton,
                        label: `Duplicate ${hasBeenBooked() ? 'Booking' : 'Quote'}`,
                        icon: <CopyIcon fixedWidth />,
                        modalSize: ShellModalSize.oneQuarter,
                        modalDef: getDuplicateQuoteModalDef(hasBeenBooked(), onDuplicateQuote),
                      },
                    ],
                  },
                  {
                    actions: [
                      {
                        actionType: ActionType.actionLink,
                        label: 'View in Operations',
                        icon: <CalendarIcon />,
                        hidden: !hasBeenBooked(),
                        to: `/operations/bookings-for-ops/${currentQuoteItem &&
                          currentQuoteItem.id}`,
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
        clearStandardSecondaryActions: true,
        secondaryActions: editable
          ? [
              {
                actions: [
                  {
                    actionType: ActionType.modalActionButton,
                    label: 'Update All Future Bookings',
                    level: 'primary',
                    hidden: !futureRepeats.length,
                    modalSize: ShellModalSize.oneThird,
                    modalDef: getUpdateFutureQuotesModalDef(
                      futureRepeats,
                      currentQuoteItem,
                      (skipRepeatTripUpdate: string[]) => {
                        if (!formApi) {
                          return Promise.reject('No form api found');
                        }
                        return Promise.resolve(
                          formApi.submitForm({
                            updateAll: true,
                            skipRepeatTripUpdate: skipRepeatTripUpdate,
                          })
                        );
                      }
                    ),
                  },
                  {
                    actionType: ActionType.submitActionButton,
                    level: 'primary',
                  },
                  {
                    actionType: ActionType.resetActionButton,
                  },
                ],
              },
            ]
          : [],
        panels: [
          {
            panes: [
              {
                paneType: PaneType.formFieldsPane,
                columnCount: 2,
                fields: [
                  {
                    fieldType: FieldType.customField,
                    // named this way because this customer field does not
                    // have a data address, but a unique one must be set
                    dataAddr: 'none1',
                    label: '',
                    render: d => (
                      <div className={styles.fieldCombo}>
                        <PageField
                          fieldDef={{
                            fieldType: FieldType.selectAsyncField,
                            dataAddr: 'customer',
                            label: 'Customer',
                            valueKey: 'customerId',
                            descriptionKey: 'customerName',
                            mandatory: true,
                            linkTo: df => `/sales/customers/${df.fieldValue.customerId}`,
                            onChange: f => {
                              if (f.getFormValue(['contact'])) {
                                f.setFormValue(['contact'], undefined);
                              }
                            },
                            loadOptionItems: searchCustomers,
                            loadItems: findCustomers,
                          }}
                          fieldMeta={d.meta}
                          paneData={d.data}
                          parentValue={d.data.parentValue}
                        />
                        <div>
                          <label>&nbsp;</label>
                          <PageField
                            fieldDef={{
                              fieldType: FieldType.actionListField,
                              actionGroups: [
                                {
                                  actions: [
                                    {
                                      actionType: ActionType.modalActionButton,
                                      hidden: !editable,
                                      label: 'Create new customer',
                                      icon: <PlusIcon />,
                                      modalSize: ShellModalSize.oneThird,
                                      modalDef: getCreateCustomerModalDef(
                                        checkForUniqueCustomerName,
                                        onCreateCustomer
                                      ),
                                    },
                                  ],
                                },
                              ],
                            }}
                            fieldMeta={d.meta}
                            paneData={d.data}
                            parentValue={d.data.parentValue}
                          />
                        </div>
                      </div>
                    ),
                  },
                  {
                    fieldType: FieldType.customField,
                    // named this way because this customer field does not
                    // have a data address, but a unique one must be set
                    dataAddr: 'none2',
                    label: '',
                    render: d => (
                      <div className={styles.fieldCombo}>
                        <PageField
                          fieldDef={{
                            fieldType: FieldType.selectAsyncField,
                            dataAddr: 'contact',
                            label: 'Contact',
                            valueKey: 'contactId',
                            descriptionKey: 'contactName',
                            getFieldKey: df =>
                              df.paneValue.customer && df.paneValue.customer.customerId,
                            loadOptionItems: (s, df) =>
                              searchContactsForCustomer(
                                df.paneValue.customer && df.paneValue.customer.customerId,
                                s
                              ),
                            loadItems: (ids, df) =>
                              findContactsForCustomer(
                                df.paneValue.customer && df.paneValue.customer.customerId,
                                ids
                              ),
                            readonly: df => !df.parentValue.customer,
                            autoload: true,
                          }}
                          fieldMeta={d.meta}
                          paneData={d.data}
                          parentValue={d.data.parentValue}
                        />
                        <div>
                          <label>&nbsp;</label>
                          <PageField
                            fieldDef={{
                              fieldType: FieldType.actionListField,
                              actionGroups: [
                                {
                                  actions: [
                                    {
                                      actionType: ActionType.modalActionButton,
                                      hidden: !editable,
                                      disabled: df => !df.parentValue.customer,
                                      label: 'Create customer contact',
                                      icon: <PlusIcon />,
                                      modalSize: ShellModalSize.oneThird,
                                      modalDef: getCreateCustomerContactModalDef(
                                        onCreateCustomerContact
                                      ),
                                    },
                                  ],
                                },
                              ],
                            }}
                            fieldMeta={d.meta}
                            paneData={d.data}
                            parentValue={d.data.parentValue}
                          />
                        </div>
                      </div>
                    ),
                  },
                  {
                    fieldType: FieldType.selectField,
                    dataAddr: 'salesRepresentative',
                    label: 'Sales Person',
                    valueKey: 'id',
                    descriptionKey: 'name',
                    mandatory: true,
                    optionItems: salesRepresentatives,
                  },
                  {
                    fieldType: FieldType.selectField,
                    dataAddr: 'operationsDepotId',
                    label: 'Depot',
                    valueKey: 'id',
                    descriptionKey: 'description',
                    mandatory: true,
                    optionItems: operationsDepots,
                    readonly: operationsDepots.length < 2,
                    useValueOnly: true,
                    onChange: api => {
                      api.setFormValue(['operationsDepotId'], api.fieldData.fieldValue);
                      setDepotId(api.fieldData.fieldValue);

                      if (
                        api.formValues.trips.some((t: Trip) => t.options && t.options.length > 0)
                      ) {
                        getVehicleAllocations(api.formValues.trips, api.fieldData.fieldValue);
                      }
                    },
                  },
                  {
                    fieldType: FieldType.selectField,
                    dataAddr: 'tradingCompany',
                    label: 'Trading Company',
                    valueKey: 'id',
                    descriptionKey: 'name',
                    mandatory: true,
                    optionItems: tradingCompanies,
                    onChange: api => {
                      const quoteType = api.getFormValue(['quoteType']) as QuoteTypeDto | undefined;
                      if (quoteType && quoteType.tradingCompanyId !== api.newFieldValue.id) {
                        api.setFormValue(['quoteType'], undefined);
                      }
                    },
                    hidden: tradingCompanies.length === 1,
                  },
                  {
                    fieldType: FieldType.selectField,
                    dataAddr: 'quoteType',
                    label: 'Quote Type',
                    valueKey: 'id',
                    descriptionKey: 'description',
                    mandatory: true,
                    optionItems: quoteTypesForDropdown,
                    valuesToExclude: d =>
                      quoteTypesForDropdown
                        .filter(t => t.tradingCompanyId !== d.parentValue.tradingCompany?.id)
                        .map(t => t.id),
                    onChange: api => {
                      const oldIsAwayTour = !!(
                        api.oldFieldValue && api.oldFieldValue.isContractPriceManuallyManaged
                      );
                      const newIsAwayTour = !!(
                        api.newFieldValue && api.newFieldValue.isContractPriceManuallyManaged
                      );
                      if (oldIsAwayTour !== newIsAwayTour) {
                        const trips = (api.getFormValue(['trips']) || []) as Trip[];
                        const newTrips = trips.map(t => ({
                          ...t,
                          options: t.options.map((o: Option & IHasChangeState) => ({
                            ...o,
                            changeState: ChangeState.Deleted,
                            overrideTotalPriceExGst: undefined,
                            overrideTotalGst: undefined,
                          })),
                        }));
                        api.setFormValue(['trips'], newTrips);
                      }
                    },
                  },
                  {
                    fieldType: FieldType.customField,
                    dataAddr: 'none',
                    label: '', // Apparently we have to specify this, but it's not shown for a customField anyway 🤔.
                    hidden: d =>
                      !(
                        d &&
                        d.parentValue &&
                        d.parentValue.quoteType &&
                        d.parentValue.quoteType.isContractPriceManuallyManaged
                      ),
                    render: () => (
                      <div className={styles['extended-tour-quote-custom-message']}>
                        <span>Please Note: </span>
                        <span>
                          All costs &amp; GST associated with "Extended Tour" bookings and extras
                          are calculated outside of the system and do not update automatically. If
                          this is not an Extended Tour but the driver is staying away, please use
                          the 'Away' quote type.
                        </span>
                      </div>
                    ),
                  },
                ],
              },
              {
                paneType: PaneType.formFieldsPane,
                fields: [
                  {
                    fieldType: FieldType.textField,
                    dataAddr: 'description',
                    label:
                      'Booking Description (Shown on all trips unless a Trip Specific Description is entered)',
                    mandatory: true,
                  },
                  {
                    fieldType: FieldType.textField,
                    dataAddr: 'cancellationReason',
                    label: 'Reason for Cancellation',
                    hidden: () =>
                      !currentQuoteItem || currentQuoteItem.status.id !== QuoteStatus.Cancelled,
                  },
                ],
              },
              {
                paneType: PaneType.formFieldsPane,
                fields: [
                  {
                    fieldType: FieldType.multiFileField,
                    dataAddr: 'driverPdfs',
                    label: `Booking Attachments`,
                    multiple: true,
                    downloadOnClick: downloadQuoteDriverPdf,
                  },
                ],
              },
            ],
          },
          {
            hidden: !hasBeenBooked(),
            panes: [
              {
                paneType: PaneType.formFieldsPane,
                columnCount: 2,
                fields: [
                  {
                    fieldType: FieldType.textField,
                    dataAddr: 'invoiceNumber',
                    label: 'Invoice Number',
                    maxLength: 200,
                    readonly: (d: IFieldData<string>) =>
                      (d.parentValue as QuoteItem).invoicingSuspended,
                  },
                  {
                    fieldType: FieldType.textField,
                    dataAddr: 'purchaseOrderNumber',
                    label: 'Purchase Order Number',
                    maxLength: 200,
                  },
                ],
              },
            ],
          },
          {
            dataAddr: 'trips',
            panes: [
              {
                paneType: PaneType.repeatingTabPane,
                getItemTitle: d =>
                  d.panelValue.filter((x: IHasChangeState) => x.changeState !== ChangeState.Deleted)
                    .length <= 1
                    ? 'Trip'
                    : `Trip ${d.itemIndex + 1}`,
                mandatory: true,
                itemPanes: [
                  {
                    paneType: PaneType.actionListPane,
                    hidden: d => !editable || isContractPriceManuallyManaged(d.sectionValue),
                    actionGroups: [
                      {
                        actions: [
                          {
                            actionType: ActionType.addArrayItemActionButton,
                            label: 'Add Trip',
                            newItemData: emptyTrip,
                          },
                          {
                            actionType: ActionType.addArrayItemActionButton,
                            label: 'Duplicate Trip',
                            icon: <CopyIcon />,
                            getNewItemData: d => {
                              const trips = d.panelValue as Array<Trip>;
                              const index = trips.indexOf(d.actionValue);
                              const trip = trips[index];
                              const duplicatedTrip: Trip = {
                                ...trip,
                                driverPdfs: (trip.driverPdfs || []).map(pdf => {
                                  return {
                                    ...pdf,
                                    changeState: ChangeState.Added,
                                  };
                                }),
                              };
                              return duplicatedTrip;
                            },
                          },
                          {
                            actionType: ActionType.modalActionButton,
                            label: 'Remove Trip',
                            icon: <TrashIcon />,
                            modalSize: ShellModalSize.oneQuarter,
                            hidden: d =>
                              d.panelValue.filter(
                                (x: IHasChangeState) => x.changeState !== ChangeState.Deleted
                              ).length <= 1,
                            modalDef: modalDefApi => ({
                              title: 'Remove Trip',
                              asForm: true,
                              panels: [
                                {
                                  panes: [
                                    {
                                      paneType: PaneType.customPane,
                                      render: () => {
                                        return (
                                          <div>
                                            <p>Are you sure you want to remove this trip?</p>
                                          </div>
                                        );
                                      },
                                    },
                                  ],
                                },
                              ],
                              secondaryActions: [getSubmitCloseModalActionGroupDef()],
                              onFormSubmit: () => {
                                const deletedTripIndex = modalDefApi.actionData.panelValue.indexOf(
                                  modalDefApi.actionData.actionValue
                                );
                                const deletedTrip = modalDefApi.actionData
                                  .actionValue as IHasChangeState;
                                const updatedItems = [...modalDefApi.actionData.panelValue];
                                const deletedRow = {
                                  ...deletedTrip,
                                  changeState: ChangeState.Deleted,
                                };

                                if (deletedTrip.changeState === ChangeState.Added) {
                                  updatedItems.splice(deletedTripIndex, 1);
                                } else {
                                  updatedItems.splice(deletedTripIndex, 1, deletedRow);
                                }
                                modalDefApi.parentFormApi.setValue('trips', updatedItems);

                                const { toDate, fromDate } = getToAndFromDatesFromTrips(
                                  currentQuoteItem?.trips
                                );
                                const vehicleTypeIds: Array<string> = getAllUniqueVehicleTypeIdsFromTrips(
                                  currentQuoteItem!.trips
                                );
                                return Promise.resolve(
                                  getVehicleTypeAllocationData(
                                    fromDate,
                                    toDate,
                                    vehicleTypeIds,
                                    currentQuoteItem?.id,
                                    depotId
                                  )
                                );
                              },
                            }),
                          },
                        ],
                      },
                    ],
                  },
                  {
                    paneType: PaneType.formFieldsPane,
                    columnCount: 2,
                    fields: [
                      {
                        fieldType: FieldType.textField,
                        dataAddr: 'description',
                        label: a => `Trip ${(a.fieldDataAddr[1] as number) + 1} Description`,
                        maxLength: 200,
                      },
                      {
                        fieldType: FieldType.numericField,
                        numericConfig: { numericType: 'unsignedInt' },
                        dataAddr: 'passengers',
                        label: 'Passengers',
                      },
                    ],
                  },
                  {
                    paneType: PaneType.formFieldsPane,
                    fields: [
                      {
                        fieldType: FieldType.multiFileField,
                        label: a => `Trip ${(a.fieldDataAddr[1] as number) + 1} Attachments`,
                        dataAddr: 'driverPdfs',
                        downloadOnClick: downloadTripDriverPdf,
                        multiple: true,
                      },
                    ],
                  },
                  {
                    paneType: PaneType.formFieldsPane,
                    fields: [
                      {
                        fieldType: FieldType.textAreaField,
                        dataAddr: 'note',
                        label: a => `Trip ${(a.fieldDataAddr[1] as number) + 1} Notes`,
                        rows: 3,
                      },
                      {
                        fieldType: FieldType.readonlyField,
                        label: 'All Trips Driver Notes',
                        formatReadonly: data => {
                          return data.sectionValue.driverNote;
                        },
                      },
                    ],
                  },
                  getTripRoutesPaneDef('routes', {
                    editable,
                    states,
                    initialArrival: 'initialArrivalOptional',
                    searchBoardingPoints,
                    boardingPoints: boardingPoints,
                    getVehicleTypeAllocationData,
                    checkForUniqueBoardingPointName,
                    onCreateBoardingPoint,
                    quoteId: quote?.id,
                    setTripRoute,
                    depotId,
                    publicHolidays: publicHolidays,
                    overlappingPublicHolidays: publicHolidayOverlaps,
                    setPublicHolidayOverlaps,
                    tripHasPublicHoliday,
                    setTripHasPublicHoliday,
                    getPublicHolidays,
                  }),
                  {
                    paneType: PaneType.nestingPane,
                    dataAddr: 'options',
                    panes: [
                      {
                        paneType: PaneType.tablePane,
                        title: 'Options',
                        dataRequiredForRows: 'sectionValue',
                        rowSelectedKey: hasBeenBooked() ? 'selected' : undefined,
                        rowSelectionMode: 'single',
                        mandatory: hasBeenBooked(),
                        validate: d => {
                          return !hasBeenBooked() ||
                            (d.paneValue as Array<Option & IHasChangeState>).some(
                              o => !!o.selected && o.changeState !== ChangeState.Deleted
                            )
                            ? undefined
                            : 'An option must be selected';
                        },
                        fields: [
                          {
                            fieldType: FieldType.readonlyField,
                            label: 'Option',
                            formatReadonly: d => {
                              const options = d.paneValue as Array<Option>;
                              const idx = options.indexOf(d.parentValue);
                              return idx + 1;
                            },
                            hidden: d => isContractPriceManuallyManaged(d.sectionValue),
                            columnAutoHide: d => isContractPriceManuallyManaged(d.sectionValue),
                          },
                          {
                            fieldType: FieldType.readonlyField,
                            dataAddr: 'costTypeId',
                            label: 'Cost Type',
                            formatReadonly: d => {
                              const ct = costTypeDescription(d.fieldValue as number);
                              const option = d.parentValue as Option;
                              if (option.costTypeId === CostType.Kilometers) {
                                const kms = option.distance && option.distance.toLocaleString();
                                const hours = (
                                  Number.parseInt(d.parentValue.durationWeekday || 0, 10) +
                                  Number.parseInt(d.parentValue.durationSaturday || 0, 10) +
                                  Number.parseInt(d.parentValue.durationSunday || 0, 10) +
                                  Number.parseInt(d.parentValue.durationPublicHoliday || 0, 10) +
                                  (showWaitingField
                                    ? Number.parseInt(d.parentValue.durationWeekdayWaiting || 0, 10)
                                    : 0) +
                                  (showWaitingField
                                    ? Number.parseInt(
                                        d.parentValue.durationSaturdayWaiting || 0,
                                        10
                                      )
                                    : 0) +
                                  (showWaitingField
                                    ? Number.parseInt(d.parentValue.durationSundayWaiting || 0, 10)
                                    : 0) +
                                  (showWaitingField
                                    ? Number.parseInt(
                                        d.parentValue.durationPublicHolidayWaiting || 0,
                                        10
                                      )
                                    : 0)
                                ).toLocaleString();
                                return `${ct} (${kms} kms, ${hours} hrs)`;
                              }
                              return ct;
                            },
                          },
                          {
                            fieldType: FieldType.readonlyField,
                            dataAddr: 'vehicles',
                            label: 'Vehicles',
                            formatReadonly: d =>
                              (d.fieldValue as Vehicle[])
                                .filter(
                                  v => (v as IHasChangeState).changeState !== ChangeState.Deleted
                                )
                                .map(v => `${v.quantity} × ${v.vehicleType.description}`)
                                .join('\n'),
                          },
                          {
                            fieldType: FieldType.readonlyField,
                            dataAddr: 'extras',
                            label: 'Extras',
                            formatReadonly: d =>
                              d.fieldValue && Array.isArray(d.fieldValue)
                                ? (d.fieldValue as Extra[])
                                    .filter(
                                      e =>
                                        (e as IHasChangeState).changeState !== ChangeState.Deleted
                                    )
                                    .map(e => `${e.quantity} × ${e.extraType.description}`)
                                    .join('\n')
                                : null,
                          },
                          {
                            fieldType: FieldType.readonlyField,
                            label: 'Price ex. GST',
                            formatReadonly: d => {
                              const option = d.parentValue as Option;
                              if (option.overrideTotalPriceExGst) {
                                return `$${formatToFixed(option.overrideTotalPriceExGst, 2)}`;
                              }
                              if (isContractPriceManuallyManaged(d.sectionValue)) {
                                return '';
                              }

                              const price = priceExGst(
                                (option.vehicles &&
                                  option.vehicles.filter(
                                    x => x.changeState !== ChangeState.Deleted
                                  )) ||
                                  [],
                                (option.extras &&
                                  option.extras.filter(
                                    x => x.changeState !== ChangeState.Deleted
                                  )) ||
                                  []
                              );
                              return `$${price}`;
                            },
                          },
                          {
                            fieldType: FieldType.readonlyField,
                            label: 'GST',
                            columnWidth: '8em',
                            hidden: d => !isContractPriceManuallyManaged(d.sectionValue),
                            columnAutoHide: d => !isContractPriceManuallyManaged(d.sectionValue),
                            formatReadonly: d => {
                              const option = d.parentValue as Option;
                              return option.overrideTotalGst
                                ? `$${formatToFixed(option.overrideTotalGst, 2)}`
                                : '';
                            },
                          },
                          {
                            fieldType: FieldType.actionListField,
                            columnWidth: '1px',
                            hidden: !editable,
                            nowrap: true,
                            actionGroups: [
                              {
                                actions: [
                                  {
                                    actionType: ActionType.modalActionButton,
                                    label: 'Edit Option',
                                    icon: <EditIcon />,
                                    modalSize: ShellModalSize.threeQuarters,
                                    modalDef: api =>
                                      getMaintainOptionModalDef(
                                        'edit',
                                        vehicleTypes,
                                        extraTypes,
                                        hourlyRates,
                                        hasBeenBooked(),
                                        isContractPriceManuallyManaged,
                                        getVehicleTypeAllocationData,
                                        depotId,
                                        splittedTechSpecs,
                                        searchTechSpecValues,
                                        techSpecsModel.techSpecDropdownOptions,
                                        techSpecs,
                                        skillSpecs,
                                        tripRoute,
                                        quoteId
                                      )(api),
                                  },
                                  {
                                    actionType: ActionType.removeArrayItemActionButton,
                                    label: 'Remove Option',
                                  },
                                ],
                              },
                            ],
                          },
                        ],
                      },
                      {
                        paneType: PaneType.actionListPane,
                        hidden: d => {
                          const options = d.paneValue as Option[] | undefined;
                          return (
                            !editable ||
                            !!(
                              options &&
                              options.filter(
                                (o: Option & IHasChangeState) =>
                                  o.changeState !== ChangeState.Deleted
                              ).length &&
                              isContractPriceManuallyManaged(d.sectionValue)
                            )
                          );
                        },
                        actionGroups: [
                          {
                            actions: [
                              {
                                actionType: ActionType.modalActionButton,
                                label: 'Add Option',
                                icon: <PlusIcon />,
                                modalSize: ShellModalSize.threeQuarters,
                                modalDef: api =>
                                  getMaintainOptionModalDef(
                                    'add',
                                    vehicleTypes,
                                    extraTypes,
                                    hourlyRates,
                                    hasBeenBooked(),
                                    isContractPriceManuallyManaged,
                                    getVehicleTypeAllocationData,
                                    depotId,
                                    splittedTechSpecs,
                                    searchTechSpecValues,
                                    techSpecsModel.techSpecDropdownOptions,
                                    techSpecs,
                                    skillSpecs,
                                    api.actionData.parentValue.routes,
                                    quoteId
                                  )(api),
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            panes: [
              {
                paneType: PaneType.formFieldsPane,
                fields: [
                  {
                    fieldType: FieldType.errorField,
                    dataAddr: 'recurPanelChanged',
                    validate: d => {
                      return d.fieldValue === true
                        ? 'Recurrences need to be regenerated or cleared'
                        : undefined;
                    },
                  },
                ],
              },
            ],
          },
        ],
        onFormPreSubmit: isUpdateMode ? handlePreSubmitForUpdate : handlePreSubmitForCreate,
        onFormSubmit: isUpdateMode ? onUpdateQuote : onCreateQuote,
      },
      secondarySections: [
        {
          title: 'Notes',
          isLogicalSubsection: true,
          key: 'notes-section',
          panels: [
            {
              panes: [
                {
                  paneType: PaneType.formFieldsPane,
                  fields: [
                    {
                      fieldType: FieldType.textAreaField,
                      dataAddr: ['customer', 'customerNotes'],
                      label: 'Customer File Notes',
                      readonly: true,
                      formatReadonly: api => {
                        const customerNotes =
                          api.fieldValue && ((api.fieldValue as unknown) as CustomerNote[]);
                        const sortedNotes =
                          customerNotes &&
                          customerNotes.sort(function(a, b) {
                            return (
                              DateTime.fromISO(b.lastModifiedOn).valueOf() -
                              DateTime.fromISO(a.lastModifiedOn).valueOf()
                            );
                          });
                        return (
                          sortedNotes && sortedNotes.map((n: CustomerNote) => <p>{n.details}</p>)
                        );
                      },
                    },
                    {
                      fieldType: FieldType.textAreaField,
                      dataAddr: 'customerNote',
                      label: 'Customer Quote Notes',
                      rows: 6,
                    },
                    {
                      fieldType: FieldType.textAreaField,
                      dataAddr: 'internalNote',
                      label: 'Internal Notes',
                      rows: 6,
                    },
                    {
                      fieldType: FieldType.textAreaField,
                      dataAddr: 'driverNote',
                      label:
                        'Driver Notes (Shown on all trips, in addition to any Trip Specific notes)',
                      rows: 6,
                    },
                  ],
                },
                {
                  paneType: PaneType.customPane,
                  render: api => {
                    const trips = api.data.sectionValue.trips;
                    if (!trips || !vehicleTypeAllocationData) return null;
                    const { fromDate, toDate } = getToAndFromDatesFromTrips(trips);

                    const formattedFromDate = DateTime.fromISO(fromDate);
                    const formattedToDate = DateTime.fromISO(toDate);

                    if (
                      !fromDate ||
                      !toDate ||
                      !formattedFromDate.isValid ||
                      !formattedToDate.isValid
                    )
                      return null;

                    const mergedVehicleAllocationData = mergeVehicleAllocationData(
                      getAggregatedAllocationDataFromTripsByDate(
                        trips,
                        formattedFromDate,
                        formattedToDate,
                        hasBeenAllocated(),
                        currentQuoteItem && currentQuoteItem.status.id === QuoteStatus.Booked
                      ),
                      vehicleTypeAllocationData
                    );

                    const {
                      overallocatedVehicleFound,
                      overallocationMessages,
                    } = getOverallocationMessageData(mergedVehicleAllocationData, trips);

                    return (
                      <>
                        <h5 className={styles.paddingTop}>
                          Potential Overallocations{' '}
                          {overallocatedVehicleFound ? (
                            <WarnIcon color="orange" title="Vehicle types may be overallocated " />
                          ) : null}
                        </h5>
                        {overallocatedVehicleFound ? (
                          overallocationMessages.map((o, index: number) => {
                            return (
                              <div key={`potential_oa_${index}`}>
                                {!!o[index].length ? (
                                  <>
                                    <h6>Trip {index + 1}</h6>
                                    {o[index].map((r, i) => {
                                      return (
                                        <div key={`potential_oa_${index}_${i}`}>
                                          {r.toDate && r.fromDate && r.toDate === r.fromDate ? (
                                            <small>
                                              <span className={styles.textFaded}>
                                                {new Date(r.toDate).toLocaleDateString(undefined, {
                                                  day: '2-digit',
                                                  month: '2-digit',
                                                  year: 'numeric',
                                                })}
                                              </span>
                                              {': '}
                                              {r.overallocationMessage}
                                            </small>
                                          ) : (
                                            <small>
                                              <span className={styles.textFaded}>
                                                {new Date(r.fromDate).toLocaleDateString(
                                                  undefined,
                                                  {
                                                    day: '2-digit',
                                                    month: '2-digit',
                                                  }
                                                )}{' '}
                                                -{' '}
                                                {new Date(r.toDate).toLocaleDateString(undefined, {
                                                  day: '2-digit',
                                                  month: '2-digit',
                                                })}
                                                {': '}
                                              </span>
                                              {r.overallocationMessage}
                                            </small>
                                          )}
                                        </div>
                                      );
                                    })}
                                  </>
                                ) : null}
                              </div>
                            );
                          })
                        ) : (
                          <small>No potential overallocations found.</small>
                        )}
                      </>
                    );
                  },
                },
                {
                  paneType: PaneType.customPane,
                  render: () => (
                    <>
                      <h5 className={styles.paddingTop}>
                        Public Holidays{' '}
                        {tripHasPublicHoliday && (
                          <WarnIcon color="orange" title="Quote contains public holidays" />
                        )}
                      </h5>
                      <div>
                        {tripHasPublicHoliday ? (
                          publicHolidayOverlaps?.map(
                            d =>
                              !!d.holidays.length && (
                                <div key={d.tripNumber}>
                                  <h6>Trip {d.tripNumber + 1}</h6>
                                  {d.holidays.map((d, i) => (
                                    <div key={`${d.date}_${i} `}>
                                      <small>
                                        <span className={styles.textFaded}>
                                          {formatDateShort(d.date)}
                                        </span>
                                        : {d.name}
                                      </small>
                                    </div>
                                  ))}
                                </div>
                              )
                          )
                        ) : (
                          <small>No public holidays found.</small>
                        )}
                      </div>
                    </>
                  ),
                },
              ],
            },
          ],
        },
        {
          title: 'Recur',
          isLogicalSubsection: true,
          key: 'recur-section',
          panels: formApi => [
            {
              panes: [
                {
                  paneType: PaneType.customPane,
                  hidden: !editable || hasBeenBooked(),
                  render: api => {
                    const quote = api.data.sectionValue as QuoteItem;
                    const firstTripDateString = quote && quote.trips && findFirstTrip(quote.trips);
                    const firstTripDate = firstTripDateString
                      ? DateTime.fromISO(firstTripDateString)
                      : undefined;
                    return (
                      <RepeatGenerationPane
                        paneData={api.data}
                        readonly={api.meta.readonly}
                        periodStart={firstTripDate}
                        onDatesGenerated={dates => {
                          formApi.setValue('repeats', dates);
                          formApi.setValue('recurPanelChanged', false); // Reset flag as recurrences are now up-to-date
                        }}
                        useTimezoneAwareCalculations={false}
                        timezone={'local'}
                        isRecurPanelChanged={isChanged => {
                          formApi.setValue('recurPanelChanged', isChanged);
                        }}
                        onReset={() => {
                          formApi.setValue('repeats', originalRepeats);
                          formApi.setValue('recurPanelChanged', false);
                        }}
                      />
                    );
                  },
                },
                {
                  paneType: PaneType.customPane,
                  hidden: !editable || !sourceQuote || !hasBeenBooked(),
                  render: () => (
                    <p>
                      <small>To make changes to recurrances, use the Original Booking.</small>
                    </p>
                  ),
                },
                {
                  paneType: PaneType.formFieldsPane,
                  hidden: !sourceQuote || !hasBeenBooked(),
                  fields: [
                    {
                      fieldType: FieldType.readonlyField,
                      label: 'Original Booking',
                      formatReadonly: () =>
                        sourceQuote ? (
                          <div>
                            <div>
                              {DateTime.fromISO(sourceQuote.firstTrip).toLocaleString(
                                DateTime.DATE_HUGE
                              )}
                            </div>
                            <div>
                              {
                                <small>
                                  <Link to={`/sales/bookings/${sourceQuote.id}`}>
                                    {`Booking ${sourceQuote.quoteNumber} (${sourceQuote.status.description})`}
                                  </Link>
                                </small>
                              }
                            </div>
                          </div>
                        ) : null,
                    },
                  ],
                },
                {
                  paneType: PaneType.nestingPane,
                  dataAddr: 'repeats',
                  panes: [
                    {
                      paneType: PaneType.customPane,
                      hidden: d => d.paneValue && d.paneValue.length,
                      render: () => (
                        <strong style={{ display: 'inline-block', marginBottom: '1em' }}>
                          Trips will not recur
                        </strong>
                      ),
                    },
                    {
                      paneType: PaneType.customPane,
                      hidden: d => !d.paneValue || !d.paneValue.length,
                      render: () => (
                        <strong style={{ display: 'inline-block', marginBottom: '1em' }}>
                          Recurring On
                        </strong>
                      ),
                    },
                    {
                      paneType: PaneType.repeatingPane,
                      dataRequiredForRows: 'paneValue',
                      hidden: d => !d.paneValue || !d.paneValue.length,
                      useHover: false,
                      useDarkStyle: false,
                      itemPanes: [
                        {
                          paneType: PaneType.formFieldsPane,
                          fields: [
                            {
                              fieldType: FieldType.dateField,
                              dataAddr: 'repeatDate',
                              mandatory: true,
                              label: d =>
                                !!(
                                  (d.parentValue as PartialRepeat).id ||
                                  (d.parentValue as PartialRepeat).generated
                                )
                                  ? ''
                                  : 'Recurring On',
                              readonly: d =>
                                !!(
                                  (d.parentValue as PartialRepeat).id ||
                                  (d.parentValue as PartialRepeat).generated
                                ),
                              formatReadonly: d => {
                                const repeat: Repeat = d.parentValue;
                                const status = repeat.linkedQuoteStatus
                                  ? repeat.linkedQuoteStatus.description
                                  : undefined;
                                return d.fieldValue ? (
                                  <div>
                                    <div>
                                      {DateTime.fromISO(d.fieldValue).toLocaleString(
                                        DateTime.DATE_HUGE
                                      )}
                                    </div>
                                    <div>
                                      {currentQuoteItem &&
                                      repeat.linkedQuoteId === currentQuoteItem.id ? (
                                        <div>
                                          <small>(This booking)</small>
                                        </div>
                                      ) : repeat.linkedQuoteId ? (
                                        <small>
                                          <Link to={`/sales/bookings/${repeat.linkedQuoteId}`}>
                                            {`Booking ${repeat.linkedQuoteNumber} (${status})`}
                                          </Link>
                                        </small>
                                      ) : null}
                                    </div>
                                  </div>
                                ) : null;
                              },
                            },
                            {
                              fieldType: FieldType.textField,
                              dataAddr: 'cancelledReason',
                              label: 'Cancellation Reason',
                              mandatory: true,
                              maxLength: 256,
                              readonly: fd => {
                                const repeat: Repeat = fd.parentValue as Repeat;
                                return (
                                  repeat &&
                                  repeat.linkedQuoteStatus &&
                                  repeat.linkedQuoteStatus.id === QuoteStatus.Cancelled
                                );
                              },
                              hidden: fd => {
                                const repeat: Repeat = fd.parentValue as Repeat;
                                const changeState =
                                  (repeat as IHasChangeState).changeState || ChangeState.Unchanged;
                                const show =
                                  repeat &&
                                  repeat.linkedQuoteStatus &&
                                  ((changeState === ChangeState.Deleted && editable) ||
                                    repeat.linkedQuoteStatus.id === QuoteStatus.Cancelled);

                                return !show;
                              },
                            },
                          ],
                        },
                        {
                          paneType: PaneType.actionListPane,
                          columnWidth: '1px',
                          hidden: fd => {
                            const repeat: Repeat = fd.parentValue as Repeat;
                            const hideActionGroup = !editable || !!sourceQuote;
                            const hideRemoveDateAction = !!repeat.linkedQuoteId;
                            const hideCancelBookingAction = !(
                              repeat.linkedQuoteStatus &&
                              repeat.linkedQuoteStatus.id === QuoteStatus.Booked
                            );
                            return (
                              (hideRemoveDateAction && hideCancelBookingAction) || hideActionGroup
                            );
                          },
                          actionGroups: [
                            {
                              actions: [
                                {
                                  actionType: ActionType.removeArrayItemActionButton,
                                  label: 'Remove Date',
                                  hidden: d => {
                                    const repeat = d.parentValue as Repeat;
                                    return !!repeat.linkedQuoteId;
                                  },
                                },
                                {
                                  actionType: ActionType.removeArrayItemActionButton,
                                  label: 'Cancel Booking',
                                  icon: <BanIcon />,
                                  hidden: d => {
                                    const repeat = d.parentValue as Repeat;
                                    return !(
                                      repeat.linkedQuoteStatus &&
                                      repeat.linkedQuoteStatus.id === QuoteStatus.Booked
                                    );
                                  },
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                    {
                      paneType: PaneType.actionListPane,
                      hidden: !editable || !!sourceQuote,
                      actionGroups: [
                        {
                          actions: [
                            {
                              actionType: ActionType.addArrayItemActionButton,
                              label: 'Add Explicit Date',
                              icon: <PlusIcon />,
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          title: 'PDFs',
          explicitData: quotePdfs || [],
          hidden: props.mode === 'create',
          panels: [
            {
              panes: [
                {
                  paneType: PaneType.repeatingPane,
                  noItemsMessage: `No PDFs have been generated for this ${entityName}`,
                  itemPanes: [
                    {
                      paneType: PaneType.customPane,
                      render: api => {
                        const pdf = api.data.parentValue as QuotePdfItem;
                        const date = DateTime.fromISO(pdf.createdOn);
                        return (
                          <div>
                            <div>
                              <strong>
                                <FilePdfIcon /> {pdf.filename}
                              </strong>
                            </div>
                            <div className={styles['pdf-downloads']}>
                              <span>Generated </span>
                              <strong>{date.toLocaleString(DateTime.DATETIME_MED)}</strong>
                              <span> by </span>
                              <strong>{pdf.createdBy}</strong>
                            </div>
                          </div>
                        );
                      },
                    },
                    {
                      paneType: PaneType.actionListPane,
                      actionGroups: [
                        {
                          actions: [
                            {
                              actionType: ActionType.actionButton,
                              label: 'Download',
                              icon: <DownloadIcon />,
                              onClick: d => downloadPdf(d.parentValue),
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        getActivityLogPanelDef(activityLogs, quoteId),
        {
          title: 'History',
          hidden: !quoteId,
          panels: [
            {
              panes: [
                {
                  paneType: PaneType.customPane,
                  render: () => (
                    <AuditHistory
                      aggregateId={quoteId!}
                      minimumVersion={1}
                      schema={getSchema(
                        findCustomers,
                        findContactsForCustomer,
                        vehicleTypes,
                        extraTypes,
                        needsVerificationStartDateItem,
                        allQuoteTypes,
                        skillSpecs,
                        techSpecs
                      )}
                      separators={getSeparators(needsVerificationStartDateItem)}
                    />
                  ),
                },
              ],
            },
          ],
        },
        {
          title: 'Jobs',
          explicitData: jobs || [],
          hidden: props.mode === 'create' || jobs.length === 0,
          panels: [
            {
              panes: [
                {
                  paneType: PaneType.tablePane,
                  neverEditable: true,
                  fields: [
                    {
                      fieldType: FieldType.readonlyField,
                      dataAddr: 'jobNumber',
                      label: 'Job Number',
                      formatReadonly: d => {
                        const job = d.parentValue as JobItem;
                        return `${job.jobNumber}${job.isCancelled ? ' (Cancelled)' : ''}`;
                      },
                      linkTo: d => `/operations/jobs/${d.parentValue.jobId}`,
                    },
                    {
                      fieldType: FieldType.readonlyField,
                      dataAddr: 'tripNumber',
                      label: 'Trip No',
                      formatReadonly: d => d.fieldValue + 1,
                    },
                    {
                      fieldType: FieldType.assetSelectField,
                      label: 'Vehicle',
                      dataAddr: 'asset',
                      valueKey: 'id',
                      descriptionKey: 'name',
                      formatReadonly: d => {
                        if (d.parentValue.hasSubcontractor) {
                          if (d.parentValue.subcontractor && d.parentValue.subcontractor.id) {
                            return (
                              <Link
                                to={`/operations/subcontractors/${d.parentValue.subcontractor.id}`}>
                                {d.parentValue.subcontractor.name}
                              </Link>
                            );
                          }
                          return null;
                        } else {
                          if (d.parentValue.asset && d.parentValue.asset.id) {
                            return (
                              <Link to={`/workshop/assets/${d.parentValue.asset.id}`}>
                                {d.parentValue.asset.name}
                              </Link>
                            );
                          }
                          return null;
                        }
                      },
                    },
                    {
                      fieldType: FieldType.readonlyField,
                      label: 'Staff Member',
                      dataAddr: 'staffMemberName',
                      formatReadonly: d => {
                        const job = d.parentValue as JobItem;
                        const traineeContent = job.isTrainingJob ? ' (Trainee)' : '';
                        return (
                          <>
                            {job.staffMemberName ? (
                              <div>
                                <Link to={`/people/staff-members/${job.staffMemberId}`}>
                                  {job.staffMemberName}
                                </Link>
                                {traineeContent}
                              </div>
                            ) : null}
                            {job.secondStaffMemberName ? (
                              <div>
                                <Link to={`/people/staff-members/${job.secondStaffMemberId}`}>
                                  {job.secondStaffMemberName}
                                </Link>
                                {traineeContent}
                              </div>
                            ) : null}
                          </>
                        );
                      },
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    };
  };

  const generatePdf = () => {
    if (!quote) {
      return;
    }
    return onGeneratePdf();
  };

  const downloadPdf = (pdf: QuotePdfItem) => {
    if (quote) {
      return getPdf(quote && quote.id, pdf.id).then(r => {
        saveAs(r, `${pdf.filename}`);
      });
    }
    return Promise.resolve();
  };

  const getQuote = () => {
    if (currentQuoteItem) {
      return {
        ...currentQuoteItem,
        tradingCompany: tradingCompanies.find(
          p => p.id === currentQuoteItem.quoteType.tradingCompanyId
        ),
        repeats: currentQuoteItem.repeats.map(repeat => {
          return {
            ...repeat,
            newValue: undefined,
          };
        }),
        trips: currentQuoteItem.trips.map(trip => {
          return {
            ...trip,
            options: trip.options.map(option => {
              return {
                ...option,
                vehicles: option.vehicles.map(vehicle => {
                  return {
                    ...vehicle,
                    ...splitSkillSpecRequirements(vehicle.skillSpecRequirements),
                    ...splitTechSpecRequirements(vehicle.techSpecRequirements),
                  };
                }),
              };
            }),
          };
        }),
      };
    }

    //Loading a default for a select field
    const defaultTradingCo = tradingCompanies.find(f => f.isDefault);
    if (props.mode === 'create' && defaultTradingCo) {
      formApi?.setValue('tradingCompany', defaultTradingCo);
    }

    return currentQuoteItem;
  };

  return (
    <CrudPage
      key={quoteId}
      def={api => getPageDef(api.updating)}
      mode={props.mode}
      isEditingForbidden={!canManageQuotes}
      data={getQuote()}
      createDefaultData={{
        ...emptyQuote,
        operationsDepotId: defaultOperationsDepot?.id,
        salesRepresentativeId: defaultSalesRepresentativeId,
        tradingCompany: defaultTradingCompany,
      }}
      className={styles['maintain-quote']}
    />
  );
});

export default MaintainQuote;
