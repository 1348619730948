import { IAjax } from 'src/infrastructure/ajax';
import { operationsUrls } from 'src/domain/services/apiUrls';

export default function init(ajax: IAjax) {
  function listRailBookingsForAccounts(
    query: Partial<
      Operations.Domain.Queries.ListRailBookingsForAccounts.ListRailBookingsForAccountsQuery
    >
  ) {
    return ajax
      .get(operationsUrls.railBookingUrls.listRailBookingsForAccounts(query))
      .map(
        r =>
          r.response as Common.Dtos.ListResult<
            Operations.Domain.Queries.ListRailBookingsForAccounts.RailBookingItemForAccounts
          >
      );
  }

  return {
    railBookingsForAccounting: {
      listRailBookingsForAccounts,
    },
  };
}
