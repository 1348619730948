import './JobItem.scss';
import { DateTime } from 'luxon';
import { Link } from 'react-router-dom';
import { JobStatus, JobType } from 'src/api/enums';
import { TIMEZONE } from 'src/appSettings';
import {
  ArrowRightIcon,
  BusIcon,
  CarIcon,
  ClipboardIcon,
  FilePdfIcon,
  PaperclipIcon,
} from 'src/images/icons';
import { DateTimeFormat } from 'src/views/components/DateTimeFormat';
import { DurationFormat } from 'src/views/components/DurationFormat';
import PrintButton from 'src/views/components/PrintButton';
import JobFormatter from 'src/views/kioskRoutes/components/presentational/JobFormatter/JobFormatter';
import { SingleClickAwareButton } from 'src/views/kioskRoutes/components/presentational/SingleClickAwareButton/SingleClickAwareButton';
import { NoteModal } from 'src/views/kioskRoutes/operationsKiosk/home/JobItem/NoteModal';
import { isJobTypeWithoutAsset } from 'src/views/routes/operations/shared/jobTypeHelpers';
import { ContinuationIndicator } from './ContinuationIndicator';
import { ParentJobRelationship } from 'src/api/enums';

type DriverJob = Operations.Domain.Queries.ListJobsForDriver.DriverJob;
type JobAttachmentPdfDto = Common.Dtos.JobAttachmentPdfDto;

export interface IJobItemProps {
  date: DateTime;
  job: DriverJob;
  acknowledgeJob: (jobId: string, date: DateTime) => Promise<void>;
  isMechanic?: boolean;
  isSecondaryDriver: boolean;
  companyHasDriversApp: boolean;
  getJobAttachment: (attachmentId: string) => Promise<void>;
  attachment?: JobAttachmentPdfDto;
}

const VehicleDisplay = ({ job }: { job: DriverJob }) => {
  return (
    <div className="vehicle-display">
      <BusIcon />
      <strong>{job.assetName ? job.assetName : 'None'}</strong>
    </div>
  );
};

const CleanerFormatDateTime = ({
  label,
  date,
  kioskDate,
  timezone,
  hidden,
}: {
  label: string;
  date: string | undefined;
  kioskDate: DateTime;
  timezone: string;
  hidden?: boolean;
}) => {
  return hidden ? null : (
    <div className="job-time-item">
      <span>{label}</span>
      <strong>
        <DateTimeFormat value={date} previousValue={kioskDate} timezone={timezone} />
      </strong>
    </div>
  );
};

const UnpaidBreakItem = ({
  label,
  value,
  hidden,
}: {
  label: string;
  value: string | undefined;
  hidden: boolean;
}) => {
  return value && value !== '' && !hidden ? (
    <div className="unpaid-item">
      <span>{label}</span>
      <strong>
        <DurationFormat value={value} />
      </strong>
    </div>
  ) : null;
};

const PaxItem = ({ label, value }: { label: string; value: number | undefined }) => {
  return value && value > 0 ? (
    <div className="unpaid-item">
      <span>{label}</span>
      <strong>{value} </strong>
    </div>
  ) : null;
};

const JobTrainees = ({ job }: { job: DriverJob }) => {
  if (job.childJobs.length < 1) return null;
  const relevantJobs = job.childJobs.filter(
    cj => cj.jobRelationshipId === ParentJobRelationship.RouteTraining
  );

  const traineeCountInfo = (
    <>
      You have {<span className="bold">{relevantJobs.length}</span>} trainee
      {relevantJobs.length > 1 ? 's' : ''} on this job:{' '}
    </>
  );

  return (
    <div className="job-trainees">
      {traineeCountInfo}{' '}
      <span className="bold">{relevantJobs.map(j => j.staffMemberName).join(', ')}</span>
    </div>
  );
};

const JobTrainer = ({ job }: { job: DriverJob }) => {
  if (!job.parentJob) {
    return null;
  }

  return (
    <div className="job-trainer">
      <span className="bold">{job.parentJob.staffMemberName}</span> is training you on this job.
    </div>
  );
};

const VehicleJobTimes = ({ job, kioskDate }: { job: DriverJob; kioskDate: DateTime }) => (
  <div className="job-times">
    {job.vehicleSwapJobDetails?.start ? (
      <CleanerFormatDateTime
        label={'Start'}
        date={job.vehicleSwapJobDetails.start}
        kioskDate={kioskDate}
        timezone={job.firstRouteTimeZone}
      />
    ) : (
      <>
        <CleanerFormatDateTime
          label={'Clock On'}
          date={job.clockOn}
          kioskDate={kioskDate}
          timezone={job.firstRouteTimeZone}
        />
        <CleanerFormatDateTime
          label={'Depart Depot'}
          date={job.departDepot}
          kioskDate={kioskDate}
          timezone={TIMEZONE}
          hidden={!job.departDepot}
        />
      </>
    )}
    {job.vehicleSwapJobDetails?.finish ? (
      <CleanerFormatDateTime
        label={'Finish'}
        date={job.vehicleSwapJobDetails.finish}
        kioskDate={kioskDate}
        timezone={job.lastRouteTimeZone}
      />
    ) : (
      <>
        <CleanerFormatDateTime
          label={'Arrive Depot'}
          date={job.arriveDepot}
          kioskDate={kioskDate}
          timezone={TIMEZONE}
          hidden={!job.arriveDepot}
        />
        <CleanerFormatDateTime
          label={'Estimated Clock Off'}
          date={job.clockOff}
          kioskDate={kioskDate}
          timezone={TIMEZONE}
        />
      </>
    )}
  </div>
);

const JobTimes = ({ job, kioskDate }: { job: DriverJob; kioskDate: DateTime }) => (
  <div className="job-times">
    {job.isContinuingFrom ? (
      <CleanerFormatDateTime
        label={'Start'}
        date={job.start}
        kioskDate={kioskDate}
        timezone={job.firstRouteTimeZone}
      />
    ) : (
      <>
        <CleanerFormatDateTime
          label={'Clock On'}
          date={job.clockOn}
          kioskDate={kioskDate}
          timezone={job.firstRouteTimeZone}
        />
        <CleanerFormatDateTime
          label={'Depart Depot'}
          date={job.departDepot}
          kioskDate={kioskDate}
          timezone={TIMEZONE}
          hidden={!job.departDepot}
        />
      </>
    )}
    {job.isContinuingTo ? (
      <CleanerFormatDateTime
        label={'Finish'}
        date={job.finish}
        kioskDate={kioskDate}
        timezone={job.lastRouteTimeZone}
      />
    ) : (
      <>
        <CleanerFormatDateTime
          label={'Arrive Depot'}
          date={job.arriveDepot}
          kioskDate={kioskDate}
          timezone={TIMEZONE}
          hidden={!job.arriveDepot}
        />
        <CleanerFormatDateTime
          label={'Estimated Clock Off'}
          date={job.clockOff}
          kioskDate={kioskDate}
          timezone={TIMEZONE}
        />
      </>
    )}
  </div>
);

const VehicleMovements = ({ job }: { job: DriverJob }) =>
  // Some jobs don't need/have assets and thus don't show this.
  isJobTypeWithoutAsset(job.jobType.id) ? null : (
    <div className="vehicle-movements">
      <div className="depots">
        <span className="direction">
          Departing in {job.departingFromDepotInCar ? 'Car' : 'Bus'}
        </span>
        <div>{job.departingFromDepot?.description || 'Unknown'}</div>
      </div>
      {job.departingFromDepotInCar ? <CarIcon /> : <BusIcon />}
      <ArrowRightIcon />
      {job.arrivingAtDepotInCar ? <CarIcon /> : <BusIcon />}
      <div className="depots">
        <span className="direction">Arriving in {job.arrivingAtDepotInCar ? 'Car' : 'Bus'}</span>
        <div>{job.arrivingAtDepot?.description || 'Unknown'}</div>
      </div>
    </div>
  );

const JobNotes = ({ job }: { job: DriverJob }) =>
  job.notes ? (
    <div className="job-notes">
      <h4>
        <ClipboardIcon /> <span>Notes</span>{' '}
      </h4>
      <p>{job.notes}</p>
      <NoteModal notes={job.notes} />
    </div>
  ) : null;

const FurtherInfo = ({ job }: { job: DriverJob }) => {
  if (job.accompanyingJobs.length < 1) return null;

  const accompanyingItems = job.accompanyingJobs.map((aj, i) => {
    return (
      <div key={`accomp-item-${i}`}>
        <span className={aj.assetName ? '' : 'not-set'}>{aj.assetName || 'Not set'}</span>
        <span className={aj.staffMemberName ? '' : 'not-set'}>
          {aj.staffMemberName || 'Not set'}
        </span>
        <span className={aj.subcontractorName ? '' : 'not-set'}>
          {aj.subcontractorName || 'Not set'}
        </span>
      </div>
    );
  });

  return (
    <div className="job-further-info">
      <h4>Further Info</h4>
      <p>
        Other vehicles attached to this trip:{' '}
        <span className="bold">{job.accompanyingJobs.length}</span>
      </p>
      <div className={'accompanying-vehicles-info'}>
        <div>
          <span className="bold">Vehicle</span>
          <span className="bold">Driver</span>
          <span className="bold">Subcontractor</span>
        </div>
        {accompanyingItems}
      </div>
    </div>
  );
};

const JobPdfs = ({
  job,
  getJobAttachemnt,
  attachment,
}: {
  job: DriverJob;
  getJobAttachemnt: (attachmentId: string) => Promise<void>;
  attachment?: JobAttachmentPdfDto;
}) =>
  job.driverPdfs.length > 0 ? (
    <div className="job-attachments">
      <h4>
        <PaperclipIcon />
        <span>Attachments</span>
      </h4>
      <div className="attachments">
        {job.driverPdfs.map((pdf, i) => (
          <PrintButton
            key={i}
            printContent={attachment?.data}
            loadDataAsync={() =>
              pdf.attachmentId ? getJobAttachemnt(pdf.attachmentId) : Promise.resolve()
            }>
            <FilePdfIcon /> {pdf.name}
          </PrintButton>
        ))}
      </div>
    </div>
  ) : null;

const UnpaidBreaks = ({ job }: { job: DriverJob }) => (
  <div className="unpaid-section">
    <UnpaidBreakItem
      label="Unpaid Breaks"
      value={job.unpaidBreaks}
      hidden={job.jobType.id === JobType.SchoolService}
    />
    <PaxItem label="Passengers" value={job.passengers} />
  </div>
);

const getDisabledAcknowledgementText = (companyHasDriversApp: boolean) =>
  companyHasDriversApp
    ? 'Acknowledgement has been disabled for this job due to recent leave. Please acknowledge via the app or by calling operations the day before.'
    : 'Acknowledgement has been disabled for this job due to recent leave. Please call Operations before 3pm the day before to acknowledge this job.';

export const JobItem = ({
  date,
  job,
  acknowledgeJob,
  isMechanic,
  isSecondaryDriver,
  companyHasDriversApp,
  getJobAttachment,
  attachment,
}: IJobItemProps) => {
  const now = DateTime.local();

  const clockOnHasPast = DateTime.fromISO(job.clockOn) <= now;
  const canActuallyAcknowledge = isSecondaryDriver
    ? job.secondaryDriverCanAcknowledge
    : job.primaryDriverCanAcknowledge;
  const jobEndsOnCurrentDay = date.hasSame(DateTime.fromISO(job.clockOff), 'day');

  return (
    <>
      <ContinuationIndicator
        isContinuation={job.isContinuingFrom}
        jobStartDate={DateTime.fromISO(job.clockOn)}
        currentDate={date.startOf('day')}></ContinuationIndicator>
      <li className="job-item-component">
        <div className="job-summary">
          <div className="job-name">
            <JobFormatter job={job} />
            <VehicleDisplay job={job} />
          </div>
          <VehicleMovements job={job} />
        </div>
        <JobTrainees job={job} />
        <JobTrainer job={job} />
        {job.isVehicleSwapped ? (
          <VehicleJobTimes job={job} kioskDate={date} />
        ) : (
          <JobTimes job={job} kioskDate={date} />
        )}
        <UnpaidBreaks job={job} />
        <JobNotes job={job} />
        <FurtherInfo job={job} />
        <JobPdfs job={job} attachment={attachment} getJobAttachemnt={getJobAttachment} />
        <div className="job-action">
          {job.jobStatus.id === JobStatus.NotAcknowledged &&
          !job.hasEnteredCompletionDetails &&
          (isSecondaryDriver
            ? !job.secondaryDriverHasAcknowledged
            : !job.primaryDriverHasAcknowledged) ? (
            canActuallyAcknowledge ? (
              <SingleClickAwareButton
                useReactStrapButton
                size="lg"
                type="button"
                className="acknowledge"
                onClick={() => acknowledgeJob(job.id, date)}>
                Acknowledge
              </SingleClickAwareButton>
            ) : (
              <div className="ack-leave-conflict-message">
                {getDisabledAcknowledgementText(companyHasDriversApp)}
              </div>
            )
          ) : null}
          {(isSecondaryDriver
            ? job.secondaryDriverHasAcknowledged
            : job.primaryDriverHasAcknowledged) &&
          !job.hasEnteredCompletionDetails &&
          clockOnHasPast ? (
            isMechanic ? (
              <div className="mechanic-message">Enter shift details from Ops Kiosk</div>
            ) : (
              jobEndsOnCurrentDay && (
                <Link to={`/kiosk/complete-job/${job.id}`} className="btn btn-lg complete">
                  Enter Shift Details
                </Link>
              )
            )
          ) : null}
          {job.hasEnteredCompletionDetails && clockOnHasPast ? (
            isMechanic ? (
              <div className="mechanic-message">View shift details from Ops Kiosk</div>
            ) : (
              <Link to={`/kiosk/complete-job/${job.id}/view`} className="btn btn-lg view">
                View Shift Details
              </Link>
            )
          ) : null}
        </div>
      </li>
    </>
  );
};
