import { PureComponent } from 'react';
import { withSelectedFormApi } from 'src/views/components/Page/forms';
import ActionBar from 'src/views/components/Page/actions/ActionBar';
import {
  IActionListFieldDef,
  IFieldMeta,
  IFieldData,
  IActionData,
} from 'src/views/definitionBuilders/types';

interface IActionListPageFieldProps {
  fieldDef: IActionListFieldDef;
  fieldMeta: IFieldMeta;
  fieldData: IFieldData<unknown>;
  submitting: boolean;
}

class ActionListPageField extends PureComponent<IActionListPageFieldProps> {
  render() {
    const { fieldDef: def, fieldMeta: meta, fieldData, submitting } = this.props;
    const data = {
      ...fieldData,
      actionValue: fieldData.fieldValue,
    } as IActionData;
    return (
      <div className="action-list-page-field-component">
        <ActionBar
          actionGroupDefs={def.actionGroups}
          actionMeta={{
            hideLabel: true,
            size: 'sm',
            removeArrayItem: meta.removeArrayItem,
            formSubmitting: submitting,
            borderless: true,
          }}
          actionData={data}
          nowrap={def.nowrap}
        />
      </div>
    );
  }
}

export default withSelectedFormApi(formApi => ({
  submitting: formApi.submitting,
}))(ActionListPageField);
