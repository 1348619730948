import { UiRouteName } from 'src/domain/baseTypes';
import { IRootStoreModel } from 'src/domain/entities/RootStoreModel';
import UiRoute from 'src/domain/services/buildUiRoutes/UiRoute';

export default function buildUiRoutes(store: IRootStoreModel) {
  const maintainComponentTypes = new UiRoute(
    UiRouteName.workshopMaintainComponentTypes,
    '/workshop/component-types',
    () => 'Component Types',
    true
  );

  return {
    [UiRouteName.workshopMaintainComponentTypes]: maintainComponentTypes,
  };
}
