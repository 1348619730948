import { UiRouteName } from 'src/domain/baseTypes';
import { IRootStoreModel } from 'src/domain/entities/RootStoreModel';
import UiRoute from 'src/domain/services/buildUiRoutes/UiRoute';

export default function buildUiRoutes(store: IRootStoreModel) {
  const partCategoryCreate = new UiRoute(
    UiRouteName.workshopPartCategoryCreate,
    '/workshop/part-categories/create',
    () => 'Create Part Category',
    false
  );

  const managePartCategory = new UiRoute(
    UiRouteName.workshopPartCategoryEdit,
    '/workshop/part-categories/:id',
    () => 'Manage Part Category',
    false
  );

  const partCategoriesList = new UiRoute(
    UiRouteName.workshopPartCategoriesList,
    '/workshop/part-categories',
    () => 'Part Categories',
    true,
    false,
    [managePartCategory, partCategoryCreate]
  );

  return {
    [UiRouteName.workshopPartCategoriesList]: partCategoriesList,
    [UiRouteName.workshopPartCategoryCreate]: partCategoryCreate,
    [UiRouteName.workshopPartCategoryEdit]: managePartCategory,
  };
}
