import deepEqual from 'deep-equal';
import { observer } from 'mobx-react';
import { useEffect } from 'react';
import { ChangeState, JobTaskCategory } from 'src/api/enums';
import { useRootStore } from 'src/domain/entities/RootStoreModel';
import { ChevronDownIcon, ChevronUpIcon } from 'src/images/icons';
import memoizeOne from 'src/infrastructure/memoizeOne';
import CrudPage, { CrudPageMode, ICrudPageDef } from 'src/views/components/Page/pages/CrudPage';
import { ActionType, PagePrimarySize, PaneType } from 'src/views/definitionBuilders/types';
import { FieldType } from 'src/views/definitionBuilders/types/field';

type MachineryScheduleTemplateItem = Workshop.Domain.Queries.MachineryScheduleTemplate.MachineryScheduleTemplateItem;
type UpdateMachineryScheduleTemplateCommand = Workshop.Domain.Commands.MachineryScheduleTemplate.UpdateMachineryScheduleTemplateCommand;
interface IMachineryScheduleForm {
  id: string;
  jobTaskCategory: {
    id: number;
    jobTaskId: number;
    description: string;
    jobTaskSubcategory?: {
      id?: number;
      description?: string;
    };
  };
  checklist?: { id: string; description: string };
  description: string;
  order: number;
  changeState: ChangeState;
}

export interface IMachineryScheduleTemplatesProps {
  mode: CrudPageMode;
}

const MaintainMachineryScheduleTemplate: React.FC<IMachineryScheduleTemplatesProps> = observer(
  (props: IMachineryScheduleTemplatesProps) => {
    const rootStore = useRootStore();
    const loadMachinerySchedule =
      rootStore.workshop.machineryScheduleTemplate.loadMachinerySchedule;
    const updateMachinerySchedule =
      rootStore.workshop.machineryScheduleTemplate.updateMachinerySchedule;
    const machinerySchedule = rootStore.workshop.machineryScheduleTemplate.item.slice();
    const loadChecklists = rootStore.workshop.checklists.load;
    const checklists = rootStore.workshop.checklists.checklists.slice();
    const loadJobTaskCategory = rootStore.jobTask.loadJobTaskCategories;
    const machineryScheduleTemplateCategoryOptions =
      rootStore.jobTask.machineryScheduleTemplateCategoryOptions;

    const handlePreSubmit = (
      values: IMachineryScheduleForm[]
    ): UpdateMachineryScheduleTemplateCommand => {
      const originalMachinerySchedule = machinerySchedule?.map((m: any) => ({
        id: m.id,
        jobTaskCategory: {
          id: m.jobTaskCategory.id,
          jobTaskId: m.jobTaskCategory.jobTaskId,
          description: m.jobTaskCategory.description,
          jobTaskSubcategory: {
            id: m.jobTaskSubcategory?.id,
            description: m.jobTaskSubcategory?.description,
          },
        },
        checklistId: m.checklist?.id,
        description: m.description,
        order: m.order,
        changeState: ChangeState.Unchanged,
      }));

      const getChangeState = (item: IMachineryScheduleForm, currentIndex: number) => {
        if (item.changeState === ChangeState.Added || item.changeState === ChangeState.Deleted) {
          return item.changeState;
        }
        const originalIndex = originalMachinerySchedule.findIndex(
          (ms: IMachineryScheduleForm) => ms.id === item.id
        );

        if (originalIndex < 0) {
          throw new Error('Cannot find original Machinery Schedule Template item');
        }
        const originalItem = machinerySchedule[originalIndex];

        return currentIndex === originalIndex && deepEqual(item, originalItem)
          ? ChangeState.Unchanged
          : ChangeState.Modified;
      };

      return {
        machineryScheduleItems: values.map((v, i) => ({
          id: v.id,
          jobTaskId: v.jobTaskCategory.jobTaskId ?? v.jobTaskCategory.id,
          jobTaskSubcategoryId: v.jobTaskCategory.jobTaskSubcategory?.id,
          checklistId: v.checklist?.id,
          description: v.description,
          changeState: getChangeState(v, i),
        })),
      } as UpdateMachineryScheduleTemplateCommand;
    };

    useEffect(() => {
      loadMachinerySchedule();
      loadChecklists();
      loadJobTaskCategory();
    }, []);

    const getPageDef = memoizeOne(
      (updating: boolean): ICrudPageDef => {
        return {
          primarySize: PagePrimarySize.threeQuarters,
          primarySection: {
            title: 'Machinery Template',
            panels: [
              {
                panes: [
                  {
                    paneType: PaneType.tablePane,
                    dataRequiredForRows: 'paneValue',
                    fields: [
                      {
                        fieldType: FieldType.selectField,
                        dataAddr: 'jobTaskCategory',
                        label: 'Job Task',
                        valueKey: 'id',
                        descriptionKey: 'description',
                        mandatory: true,
                        useOptionRendererAsValueRenderer: true,
                        readonly: d => d.fieldDataAddr[1] === 0,
                        optionItems: machineryScheduleTemplateCategoryOptions,
                        formatReadonly: d => (
                          <span>
                            {d.fieldValue?.description}{' '}
                            {d.fieldValue?.jobTaskSubcategory?.description && (
                              <small> - {d.fieldValue?.jobTaskSubcategory?.description}</small>
                            )}
                          </span>
                        ),
                        optionRenderer: d => (
                          <span>
                            {d.description}{' '}
                            {d.jobTaskSubcategory?.id && (
                              <small> - {d.jobTaskSubcategory?.description}</small>
                            )}
                          </span>
                        ),
                      },
                      {
                        fieldType: FieldType.selectField,
                        dataAddr: 'checklist',
                        label: 'Checklist',
                        valueKey: 'id',
                        descriptionKey: 'name',
                        optionItems: checklists,
                      },
                      {
                        fieldType: FieldType.textField,
                        dataAddr: 'description',
                        label: 'Description',
                        maxLength: 200,
                        mandatory: true,
                      },
                      {
                        fieldType: FieldType.actionListField,
                        hidden: !updating,
                        columnWidth: '8rem',
                        actionGroups: [
                          {
                            actions: [
                              {
                                actionType: ActionType.moveArrayItemActionButton,
                                label: 'Move up',
                                icon: <ChevronUpIcon />,
                                moveDirection: 'prev',
                                hidden: d =>
                                  d.actionValue.jobTaskCategory?.id ===
                                    JobTaskCategory.MachineryInspection ||
                                  (d.paneValue as MachineryScheduleTemplateItem[]).indexOf(
                                    d.parentValue
                                  ) === 1,
                              },
                              {
                                actionType: ActionType.moveArrayItemActionButton,
                                label: 'Move down',
                                icon: <ChevronDownIcon />,
                                moveDirection: 'next',
                                hidden: d =>
                                  d.actionValue.jobTaskCategory?.id ===
                                    JobTaskCategory.MachineryInspection ||
                                  (d.paneValue as MachineryScheduleTemplateItem[]).indexOf(
                                    d.parentValue
                                  ) ===
                                    (d.paneValue as MachineryScheduleTemplateItem[]).length - 1,
                              },
                              {
                                actionType: ActionType.removeArrayItemActionButton,
                                label: 'Remove Task',
                                hidden: d =>
                                  d.actionValue.jobTaskCategory?.id ===
                                  JobTaskCategory.MachineryInspection,
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    paneType: PaneType.actionListPane,
                    actionGroups: [
                      {
                        actions: [
                          {
                            actionType: ActionType.addArrayItemActionButton,
                            hidden: !updating,
                            label: 'Add Task',
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
            onFormPreSubmit: handlePreSubmit,
            onFormSubmit: updateMachinerySchedule,
          },
        };
      }
    );

    return (
      <CrudPage
        data={machinerySchedule}
        def={({ updating }) => getPageDef(updating)}
        mode={props.mode}
      />
    );
  }
);

export default MaintainMachineryScheduleTemplate;
