import { DateTime } from 'luxon';

type VehicleTypeAllocationItem = Operations.Domain.Queries.GetVehicleTypeAllocationData.VehicleTypeAllocationItem;
type VehicleTypeAllocationsByDayDto = Operations.Domain.Queries.GetVehicleTypeAllocationData.VehicleTypeAllocationsByDayDto;
type RouteItem = Operations.Domain.Queries.ViewBookingForOps.OpsBookingItem.RouteItem;
type VehicleTypeItem = Common.Dtos.VehicleTypeItem;
type VehicleTypeItemPartial = Pick<VehicleTypeItem, 'id' | 'description'>;
type VehiclePartial = { quantity: number; vehicleType: VehicleTypeItemPartial };
type OptionPartial = { vehicles: VehiclePartial[]; selected?: boolean };
type RoutePartial = Pick<RouteItem, 'date'>;
type RoutesAndOptions = { routes: RoutePartial[]; options: OptionPartial[] }[];
export type LocalAllocationData = {
  [day: string]: {
    [vehicleTypeId: string]: { numberBookedOnDay: number; numberQuotedOnDay: number };
  };
};

export const getDaysInPeriodInclusiveAsStrings = (
  fromDate: DateTime,
  toDate: DateTime
): string[] => {
  const days = [];

  // Setting limit otherwise it crashes when it needs to loop through so many days
  const diff = toDate.diff(fromDate, 'years').years;
  if (fromDate.isValid && toDate.isValid && diff <= 5) {
    let current = fromDate;

    while (current <= toDate) {
      days.push(current.toFormat('yyyy-MM-dd'));
      current = current.plus({ days: 1 });
    }
  }

  return days;
};

export const getAggregatedAllocationDataFromTripsByDate = (
  allQuoteTrips: RoutesAndOptions,
  fromDate: DateTime,
  toDate: DateTime,
  selectedOptionOnly: boolean,
  isBooked?: boolean
) => {
  const results: LocalAllocationData = {};

  for (let trip of allQuoteTrips) {
    const tripResult = {};
    if (fromDate.isValid && toDate.isValid) {
      if (trip.routes && trip.routes.length > 1 && trip.options && trip.options.length > 0) {
        const vehicleTypeIds: string[] = getAllUniqueVehicleTypeIdsFromTrips([trip], isBooked);
        const days = getDaysInPeriodInclusiveAsStrings(fromDate, toDate);
        const routeFromDate = trip.routes[0].date;
        const routeToDate = trip.routes[trip.routes.length - 1].date;

        for (let day of days) {
          if (!(routeFromDate <= day && day <= routeToDate)) continue;

          if (!(day in tripResult)) {
            tripResult[day] = {};
          }

          let optionsToConsider = trip.options.filter(opt => !selectedOptionOnly || opt.selected);

          for (let option of optionsToConsider) {
            for (let vehicle of option.vehicles) {
              if (
                !tripResult[day][vehicle.vehicleType.id] ||
                (tripResult[day][vehicle.vehicleType.id] &&
                  vehicle.quantity >
                    tripResult[day][vehicle.vehicleType.id].numberBookedOnDay +
                      tripResult[day][vehicle.vehicleType.id].numberQuotedOnDay)
              ) {
                tripResult[day][vehicle.vehicleType.id] = {
                  numberBookedOnDay: isBooked ? vehicle.quantity : 0,
                  numberQuotedOnDay: !isBooked ? vehicle.quantity : 0,
                };
              }
            }
          }
        }

        for (let day of days) {
          if (!(routeFromDate <= day && day <= routeToDate)) continue;
          if (!(day in results)) {
            results[day] = tripResult[day];
            continue;
          }

          for (let vehicleTypeId of vehicleTypeIds) {
            if (!!results[day][vehicleTypeId]) {
              results[day][vehicleTypeId] = {
                numberBookedOnDay:
                  results[day][vehicleTypeId].numberBookedOnDay ??
                  0 + tripResult[day][vehicleTypeId].numberBookedOnDay ??
                  0,
                numberQuotedOnDay:
                  results[day][vehicleTypeId].numberQuotedOnDay ??
                  0 + tripResult[day][vehicleTypeId].numberQuotedOnDay ??
                  0,
              };
            } else {
              results[day][vehicleTypeId] = tripResult[day][vehicleTypeId];
            }
          }
        }
      }
    }
  }

  return results;
};

export const mergeVehicleAllocationData = (
  localAllocationData: LocalAllocationData,
  quotedAndBookedVehicleAllocationData: VehicleTypeAllocationsByDayDto[]
): LocalAllocationData => {
  const result: LocalAllocationData = { ...localAllocationData };

  for (const data of quotedAndBookedVehicleAllocationData) {
    if (!result[data.day]) result[data.day] = {};
    data.items.forEach((i: VehicleTypeAllocationItem) => {
      if (!!result[data.day][i.vehicleTypeId]) {
        result[data.day][i.vehicleTypeId] = {
          numberBookedOnDay:
            result[data.day][i.vehicleTypeId].numberBookedOnDay + i.numberBookedOnDay,
          numberQuotedOnDay:
            result[data.day][i.vehicleTypeId].numberQuotedOnDay + i.numberQuotedOnDay,
        };
      } else {
        result[data.day][i.vehicleTypeId] = {
          numberBookedOnDay: i.numberBookedOnDay,
          numberQuotedOnDay: i.numberQuotedOnDay,
        };
      }
    });
  }

  return result;
};

export const getAllUniqueVehicleTypeIdsFromTrips = (
  trips: RoutesAndOptions,
  isBooked: boolean = false
) => {
  const vehicleTypeIds: Array<string> = [];
  if (isBooked) {
    trips.forEach(t =>
      t.options
        .filter(o => o.selected)
        .forEach(o =>
          o.vehicles.forEach(v => {
            !vehicleTypeIds.includes(v.vehicleType.id) && vehicleTypeIds.push(v.vehicleType.id);
          })
        )
    );
  } else {
    trips.forEach(t =>
      t.options.forEach(o =>
        o.vehicles.forEach(v => {
          !vehicleTypeIds.includes(v.vehicleType.id) && vehicleTypeIds.push(v.vehicleType.id);
        })
      )
    );
  }

  return vehicleTypeIds;
};

export const getToAndFromDatesFromTrips = (
  trips: RoutesAndOptions | undefined
): { toDate: string; fromDate: string } => {
  let fromDate = '';
  let toDate = '';

  if (trips && trips.length) {
    trips.forEach(t => {
      if (t.routes.length > 1) {
        const tripFrom = t.routes[0].date;
        const tripTo = t.routes[t.routes.length - 1].date;

        const formattedFromDate = DateTime.fromISO(tripFrom);
        const formattedToDate = DateTime.fromISO(tripTo);

        if (formattedFromDate.isValid && formattedToDate.isValid) {
          fromDate = (!!tripFrom && fromDate === '') || tripFrom < fromDate ? tripFrom : fromDate;
          toDate = toDate === '' || tripTo > toDate ? tripTo : toDate;
        }
      }
    });
  }

  return { fromDate: fromDate, toDate: toDate };
};
