import cn from 'classnames';
import { DateTime, Duration } from 'luxon';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import FormGroup from 'reactstrap/lib/FormGroup';
import Input from 'reactstrap/lib/Input';
import Label from 'reactstrap/lib/Label';
import { TimesheetStatus, getTimesheetStatusDescriptor } from 'src/api/enums';
import {
  formatDateTimeShort,
  formatDayMonth,
  getPayPeriodInDays,
  getPayPeriodInWeeks,
  getWeekStart,
} from 'src/domain/dateHelper';
import { useKioskRootStore } from 'src/domain/entities/KioskRootStoreModel';
import KblModal from 'src/views/components/KblModal';
import TimesheetTable from 'src/views/kioskRoutes//components/presentational/Timesheet/TimesheetTable/TimesheetTable';
import Header from 'src/views/kioskRoutes/components/presentational/Header/Header';
import PrintTimesheet from 'src/views/kioskRoutes/components/presentational/Timesheet/PrintTimesheet/PrintTimesheet';
import { SingleClickAwareButton } from '../../components/presentational/SingleClickAwareButton/SingleClickAwareButton';
import WeekNavigation from '../../components/presentational/WeekNavigation/WeekNavigation';
import './Timesheet.scss';

const Timesheet: React.FC = observer(() => {
  const rootStore = useKioskRootStore();
  const getTimesheetForDriver = rootStore.kiosk.timesheet.getTimesheetForDriver;
  const timesheetWeekStart = rootStore.kiosk.timesheet.timesheetWeekStart;
  const currentWeekStart = getWeekStart(DateTime.local());
  const week = Duration.fromObject({ days: getPayPeriodInDays() - 1 });
  const userDisplayName = rootStore.account.checkedName;
  const timesheet = rootStore.kiosk.timesheet.timesheet;
  const prefilledTimesheet = rootStore.kiosk.timesheet.prefilledTimesheet;
  const createTimesheetForDriver = rootStore.kiosk.timesheet.createTimesheetForDriver;
  const refreshTimesheetForDriver = rootStore.kiosk.timesheet.refreshTimesheetForDriver;
  const updateTimesheetForDriver = rootStore.kiosk.timesheet.updateTimesheetForDriver;
  const canUseTimesheets = rootStore.account.canUseTimesheets;
  const isWorkshopKiosk = rootStore.isWorkshopKiosk;
  const home = rootStore.home;
  const loadTimesheetForDriverForPrinting =
    rootStore.kiosk.timesheet.loadTimesheetForDriverForPrinting;
  const timesheetForPrinting = rootStore.kiosk.timesheet.timesheetForPrinting;

  const [dates, setDates] = useState<DateTime[]>([currentWeekStart, currentWeekStart.plus(week)]);
  const [driverNotes, setDriverNotes] = useState<string>('');
  const [isUpdating, setIsUpdating] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);

  useEffect(() => {
    getTimesheetForDriver(dates[0]);
  }, []);

  useEffect(() => {
    getTimesheetForDriver(dates[0]);
  }, [dates]);

  const isUpdateMode = !!timesheet;
  const isCreateMode = !timesheet;

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  const createTimesheet = () => {
    if (timesheetWeekStart) {
      return createTimesheetForDriver({
        weekStart: timesheetWeekStart.toISODate(),
        staffMemberNotes: driverNotes,
      });
    } else {
      return Promise.resolve();
    }
  };

  const updateTimesheet = () => {
    if (timesheet) {
      return updateTimesheetForDriver(driverNotes).then(_ => {
        stopEdit(false);
      });
    } else {
      stopEdit(true);
      return Promise.resolve();
    }
  };

  const refreshTimesheet = () => {
    return refreshTimesheetForDriver().then(_ => toggleModal());
  };

  const startEdit = () => {
    setIsUpdating(true);
  };

  const stopEdit = (resetNotes: boolean) => {
    setIsUpdating(false);
    setDriverNotes(resetNotes ? (timesheet && timesheet.staffMemberNotes) || '' : driverNotes);
  };

  const timesheetActionButtons = () => {
    return (
      <div className="timesheet-action-buttons">
        <div className="submit" hidden={isCreateMode || isUpdating}>
          <div className="button-group">
            <SingleClickAwareButton
              useReactStrapButton
              type="button"
              size="lg"
              onClick={toggleModal}
              hidden={isCreateMode || timesheet!.status === TimesheetStatus.Processed}
              className="btn button-secondary">
              Refresh Timesheet
            </SingleClickAwareButton>
            <SingleClickAwareButton
              useReactStrapButton
              type="button"
              size="lg"
              onClick={() => startEdit()}
              hidden={isCreateMode || timesheet!.status === TimesheetStatus.Processed}
              className="btn">
              Edit
            </SingleClickAwareButton>
          </div>
        </div>
        <div className="submit" hidden={isCreateMode || !isUpdating}>
          <div className="button-group">
            <SingleClickAwareButton
              useReactStrapButton
              type="button"
              size="lg"
              color="outline-secondary"
              onClick={() => stopEdit(true)}
              className="button-half-width">
              Cancel
            </SingleClickAwareButton>
            <SingleClickAwareButton
              useReactStrapButton
              type="button"
              size="lg"
              className="button-half-width"
              onClick={() => updateTimesheet()}>
              Submit
            </SingleClickAwareButton>
          </div>
        </div>

        <div className="submit" hidden={isUpdateMode}>
          <SingleClickAwareButton
            useReactStrapButton
            type="button"
            size="lg"
            onClick={() => createTimesheet()}>
            Submit
          </SingleClickAwareButton>
        </div>
      </div>
    );
  };

  return (
    <div className="timesheet-component">
      <Header
        title={`Timesheets for ${userDisplayName}`}
        to="/kiosk"
        canUseTimesheets={canUseTimesheets}
      />

      <div className="timesheet-details">
        <div className="timesheet-date">
          <div>
            <h2>
              {formatDayMonth(dates[0].toISODate())} -{' '}
              {formatDayMonth(dates[dates.length - 1].toISODate())}
            </h2>
            <span
              className={cn(
                'timesheet-status',
                timesheet?.status
                  ? getTimesheetStatusDescriptor(timesheet?.status).name
                  : 'notSubmitted'
              )}>
              {timesheet?.status
                ? getTimesheetStatusDescriptor(timesheet?.status).name
                : 'Not Submitted'}
            </span>
          </div>

          <div className="print-btn">
            {timesheet && (
              <PrintTimesheet
                loadDataAsync={loadTimesheetForDriverForPrinting}
                timesheetForPrinting={timesheetForPrinting}
              />
            )}
          </div>
        </div>

        <div className="timesheet-weekNav">
          <WeekNavigation
            onChangeBack={() => {
              setDates([
                dates[0].plus({ weeks: -getPayPeriodInWeeks() }),
                dates[dates.length - 1].plus({ weeks: -getPayPeriodInWeeks() }),
              ]);
            }}
            onChangeForward={() => {
              setDates([
                dates[0].plus({ weeks: getPayPeriodInWeeks() }),
                dates[dates.length - 1].plus({ weeks: getPayPeriodInWeeks() }),
              ]);
            }}
            onChangeToday={() => {
              setDates([
                currentWeekStart,
                currentWeekStart.plus({ days: getPayPeriodInDays() - 1 }),
              ]);
            }}
            date={dates[0]}
            disabled={formatDateTimeShort(dates[0]) === formatDateTimeShort(currentWeekStart)}
          />
        </div>

        <div className="timesheet-table">
          {timesheet ? (
            <TimesheetTable timesheet={timesheet} isWorkshopKiosk={!!isWorkshopKiosk} home={home} />
          ) : prefilledTimesheet ? (
            <TimesheetTable
              timesheet={prefilledTimesheet}
              isWorkshopKiosk={!!isWorkshopKiosk}
              home={home}
            />
          ) : (
            <span className="no-timesheets">No timesheets for this week</span>
          )}
        </div>
        <div className="timesheet-notes">
          <FormGroup>
            <Label for="driverNotes">Please add further information if needed</Label>
            <Input
              autoComplete="off"
              type="textarea"
              name="driverNotes"
              id="driverNotes"
              readOnly={isUpdateMode && !isUpdating}
              onChange={event => setDriverNotes(event.target.value)}
              value={driverNotes || ''}
              className="driver-notes-area"
            />
          </FormGroup>
        </div>
      </div>

      <div className="timesheet-links">{timesheetActionButtons()}</div>
      <KblModal
        className="modal-lg"
        isOpen={showModal}
        toggle={toggleModal}
        header="Refresh timesheet"
        modalBody={
          <div className="refresh-timesheet-modal-component">
            <span>Are you sure you want to refresh the timesheet?</span>
          </div>
        }
        modalFooter={
          <>
            <SingleClickAwareButton
              useReactStrapButton
              color="outline-secondary"
              onClick={toggleModal}>
              Cancel
            </SingleClickAwareButton>
            <SingleClickAwareButton useReactStrapButton color="primary" onClick={refreshTimesheet}>
              Refresh timesheet
            </SingleClickAwareButton>{' '}
          </>
        }
      />
    </div>
  );
});

export default Timesheet;
