import { IAjax } from 'src/infrastructure/ajax';
import { operationsUrls } from 'src/domain/services/apiUrls';

export default function init(ajax: IAjax) {
  function loadVehicleTypes() {
    return ajax
      .get(operationsUrls.vehicleTypesUrls.loadVehicleTypes())
      .toPromise()
      .then<Common.Dtos.VehicleTypeItem[]>(r => r.response);
  }
  function createVehicleType(
    command: Operations.Domain.Commands.VehicleType.CreateVehicleTypeCommand
  ) {
    return ajax
      .post('/api/operations/vehicle-types/create', command)
      .toPromise()
      .then<string>(r => r.response);
  }

  function updateVehicleType(
    command: Operations.Domain.Commands.VehicleType.UpdateVehicleTypeCommand
  ) {
    return ajax.put(`/api/operations/vehicle-types/${command.vehicleTypeId}`, command).toPromise();
  }

  function checkForUniqueVehicleTypeDescription(description: string, isUpdating: boolean) {
    return ajax
      .get(`/api/operations/vehicle-types/check-unique/${description}/${isUpdating}`)
      .toPromise()
      .then<Common.Dtos.UniqueNameCheckResultDto>(r => r.response);
  }

  function viewVehicleType(vehicleTypeId: string) {
    return ajax
      .get(`/api/operations/vehicle-types/${vehicleTypeId}`)
      .toPromise()
      .then<Common.Dtos.VehicleTypeItem>(r => r.response);
  }

  return {
    vehicleTypes: {
      loadVehicleTypes,
      createVehicleType,
      updateVehicleType,
      viewVehicleType,
      checkForUniqueVehicleTypeDescription,
    },
  };
}
