import { buildListPageApiSearchModelTypedQuery } from 'src/domain/modelBuilders/buildListPageApiSearchModel';
import { getAjax, NotificationType } from 'src/domain/services';
import { getRoot, flow } from 'mobx-state-tree';
import { IRootStoreModel } from 'src/domain/entities/RootStoreModel';
import * as queryString from 'query-string';

type ListOperationsDiaryEntriesQuery = Operations.Domain.Queries.ListOperationsDiaryEntries.ListOperationsDiaryEntriesQuery;

export const OperationsDiaryEntriesModel = buildListPageApiSearchModelTypedQuery<
  ListOperationsDiaryEntriesQuery
>()('OperationsDiaryEntriesModel', d =>
  d.ajax.operations.operationsDiary.listOperationsDiaryEntries(d.query)
).actions(self => {
  const ajax = getAjax(self);
  const root = getRoot(self) as IRootStoreModel;

  const exportToExcel = flow(function*(query: Partial<ListOperationsDiaryEntriesQuery>) {
    root.notifications.addNotification(`The file is being generated ...`, {
      type: NotificationType.info,
    });

    return yield ajax.raw
      .getFile(`/api/operations/daily-event-log/excel?${queryString.stringify(query)}`)
      .toPromise()
      .then<Blob>(r => r.response);
  });

  return {
    exportToExcel,
  };
});
