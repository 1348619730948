import { observer } from 'mobx-react-lite';
import { useRootStore } from 'src/domain/entities/RootStoreModel';
import ListPage, { IListPageDef } from 'src/views/components/Page/pages/ListPage';
import TaskCardItem from 'src/views/components/TaskCard/TaskCardItem';
import { PagePrimarySize } from 'src/views/definitionBuilders/types';
import { FieldType } from 'src/views/definitionBuilders/types/field';
import PrimaryTitle from 'src/views/components/Page/PrimaryTitle/PrimaryTitle';

const ListBoardingPoint: React.FC = observer(() => {
  const rootStore = useRootStore();
  const salesModel = rootStore.operations.sales;
  const boardingPointModel = salesModel.boardingPoints;
  const accountModel = rootStore.account;

  const getPageDef = (): IListPageDef => {
    return {
      primaryTitle: (
        <PrimaryTitle
          title="Boarding Points"
          link="https://www.notion.so/Defining-Boarding-Points-26cb39da732e47e6ad1cc245e1812289"></PrimaryTitle>
      ),
      primarySize: PagePrimarySize.full,
      onLoadData: boardingPointModel.listItems,
      externalSearch: true,
      createLink: accountModel.isSalesDepartmentMember
        ? TaskCardItem.sales.createBoardingPoint
        : undefined,
      hasMoreData: boardingPointModel.hasMoreItems,
      primaryFields: [
        {
          fieldType: FieldType.textField,
          dataAddr: 'name',
          label: 'Name',
          linkTo: d => `/sales/boarding-points/${d.parentValue.id}`,
        },
        {
          fieldType: FieldType.textField,
          dataAddr: 'address',
          label: 'Address',
        },
        {
          fieldType: FieldType.textField,
          dataAddr: 'city',
          label: 'City',
        },
        {
          fieldType: FieldType.textField,
          dataAddr: 'state',
          label: 'State',
        },
        {
          fieldType: FieldType.textField,
          dataAddr: 'postcode',
          label: 'Postcode',
        },
        {
          fieldType: FieldType.textField,
          dataAddr: 'notes',
          label: 'Notes',
        },
      ],
    };
  };

  return (
    <ListPage
      className="list-boarding-point-component"
      data={boardingPointModel.items}
      def={getPageDef()}
    />
  );
});

export default ListBoardingPoint;
