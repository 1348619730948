import { ActionType, ModalDefBuilder } from '../../../../../../definitionBuilders/types/action';
import { PaneType } from '../../../../../../definitionBuilders/types/pane';
import { FieldType } from '../../../../../../definitionBuilders/types/field';
import { getSubmitCloseModalActionGroupDef } from 'src/views/definitionBuilders/common';
import { IMachineryScheduleOption } from '../../../../../../../domain/entities/workshop/jobTask/JobTaskModel';
import { JobTaskCategory } from 'src/api/enums';
import { DateTime } from 'luxon';

type MachineryScheduleTemplateItem = Workshop.Domain.Queries.MachineryScheduleTemplate.MachineryScheduleTemplateItem;
type ScheduleMachineryJobTaskCommand = Workshop.Domain.Commands.JobTask.ScheduleMachineryJobTaskCommand;
type JobTaskSchedule = Workshop.Domain.Commands.JobTask.JobTaskSchedule;
type ChecklistDetails = Workshop.Domain.Queries.GetChecklist.ChecklistDetails;
type AssetId = Workshop.Domain.Queries.GetAssetsWithInspectionsDue.UpcomingCertificateOfInspectionAsset['assetId'];
type Depots = Common.Queries.Workshop.GetWorkshopDepots.WorkshopDepotDto;

interface MachineryScheduleTask {
  jobTaskCategory: {
    id: number;
    description: string;
    jobTaskSubcategory?: {
      id: number;
      description: string;
    };
  };
  startDateTime: string;
  endDateTime: string;
  checklist?: {
    id: string;
  };
  description: string;
  depot: number;
}

export default function getScheduleMachineryJobTaskModalDef(
  machineryScheduleTemplate: any,
  machineryScheduleTemplateCategoryOptions: IMachineryScheduleOption[],
  checklists: ChecklistDetails[],
  onSubmit: (command: ScheduleMachineryJobTaskCommand) => Promise<void>,
  assetId: AssetId,
  depots: Array<Depots>,
  defaultDepot: number
): ModalDefBuilder {
  return () => {
    return {
      title: 'Schedule Machinery',
      asForm: true,
      explicitData: { machineryScheduleTemplate },
      panels: [
        {
          panes: [
            {
              paneType: PaneType.nestingPane,
              dataAddr: 'machineryScheduleTemplate',
              panes: [
                {
                  paneType: PaneType.tablePane,
                  title: 'Jobs',
                  dataRequiredForRows: 'paneValue',
                  fields: [
                    {
                      fieldType: FieldType.selectField,
                      dataAddr: 'jobTaskCategory',
                      label: 'Job Task',
                      valueKey: 'id',
                      descriptionKey: 'description',
                      mandatory: true,
                      useOptionRendererAsValueRenderer: true,
                      readonly: d => d.fieldDataAddr[1] === 0,
                      optionItems: machineryScheduleTemplateCategoryOptions,
                      columnWidth: '10rem',
                      formatReadonly: d => {
                        return (
                          <span>
                            {d.fieldValue?.description}{' '}
                            {d.fieldValue?.jobTaskSubcategory?.description && (
                              <small> - {d.fieldValue?.jobTaskSubcategory?.description}</small>
                            )}
                          </span>
                        );
                      },
                      optionRenderer: d => (
                        <span>
                          {d.description}{' '}
                          {d.jobTaskSubcategory?.id && (
                            <small> - {d.jobTaskSubcategory?.description}</small>
                          )}
                        </span>
                      ),
                      valuesToExclude: d =>
                        d.paneValue
                          .map((m: MachineryScheduleTemplateItem) => m.jobTaskCategory?.id)
                          .filter(
                            (m: JobTaskCategory) => m === JobTaskCategory.MachineryInspection
                          ),
                    },
                    {
                      fieldType: FieldType.dateTimeField,
                      dataAddr: ['startDateTime'],
                      label: 'Start',
                      mandatory: true,
                      formatReadonly: d =>
                        DateTime.fromISO(d.fieldValue!)
                          .toLocal()
                          .toLocaleString(DateTime.DATETIME_SHORT),
                      columnWidth: '10rem',
                      validate: d => {
                        const row0 = d.paneValue as MachineryScheduleTemplateItem[][0];
                        const machineryRowStartTime = row0?.[0]?.startDateTime;
                        const startDateTime = DateTime.fromISO(d.fieldValue!);
                        const endDateTime = DateTime.fromISO(d.parentValue.endDateTime);
                        if (startDateTime > endDateTime) {
                          return 'Start date/time must be before end date/time';
                        }
                        if (!machineryRowStartTime) {
                          return;
                        } else if (startDateTime > DateTime.fromISO(machineryRowStartTime)) {
                          return 'Job task cannot be scheduled after machinery inspection';
                        }
                        return undefined;
                      },
                    },
                    {
                      fieldType: FieldType.dateTimeField,
                      dataAddr: ['endDateTime'],
                      label: 'End',
                      mandatory: true,
                      formatReadonly: d =>
                        DateTime.fromISO(d.fieldValue!)
                          .toLocal()
                          .toLocaleString(DateTime.DATETIME_SHORT),
                      columnWidth: '10rem',
                      validate: d => {
                        const startDateTime = DateTime.fromISO(d.parentValue.startDateTime);
                        const endDateTime = DateTime.fromISO(d.fieldValue!);

                        if (!startDateTime.isValid || !endDateTime.isValid) {
                          console.error('Invalid date format.');
                          return 'Invalid date format';
                        }
                        if (startDateTime > endDateTime) {
                          return 'End date/time must be after start date/time';
                        }
                        if (endDateTime.valueOf() === startDateTime.valueOf()) {
                          return 'Start and end times cannot be the same';
                        }
                        return undefined;
                      },
                    },
                    {
                      fieldType: FieldType.selectField,
                      dataAddr: 'checklist',
                      label: 'Checklist',
                      valueKey: 'id',
                      descriptionKey: 'name',
                      optionItems: checklists,
                      columnWidth: '8rem',
                    },
                    {
                      fieldType: FieldType.textField,
                      dataAddr: 'description',
                      label: 'Description',
                      maxLength: 200,
                      mandatory: true,
                      columnWidth: '15rem',
                    },
                    {
                      fieldType: FieldType.selectField,
                      dataAddr: 'depot',
                      label: 'Depot',
                      valueKey: 'id',
                      descriptionKey: 'description',
                      optionItems: depots,
                      useValueOnly: true,
                      columnWidth: '8rem',
                      mandatory: true,
                    },
                    {
                      fieldType: FieldType.actionListField,
                      columnWidth: '1px',
                      hidden: d =>
                        d.fieldValue.jobTaskCategory?.id === JobTaskCategory.MachineryInspection,
                      actionGroups: [
                        {
                          actions: [
                            {
                              actionType: ActionType.removeArrayItemActionButton,
                              label: 'Remove Task',
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
                {
                  paneType: PaneType.actionListPane,
                  actionGroups: [
                    {
                      actions: [
                        {
                          actionType: ActionType.addArrayItemActionButton,
                          label: 'Add Task',
                          getNewItemData: _ => {
                            return { depot: defaultDepot };
                          },
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
      secondaryActions: [getSubmitCloseModalActionGroupDef('Submit')],
      onFormSubmit: (values: { machineryScheduleTemplate: MachineryScheduleTask[] }) => {
        const jobTasks: JobTaskSchedule[] = values.machineryScheduleTemplate.map(task => ({
          jobTaskCategoryId: task.jobTaskCategory.id,
          jobTaskSubcategoryId: task.jobTaskCategory.jobTaskSubcategory?.id,
          startDateTime: DateTime.fromISO(task.startDateTime).toISO(),
          endDateTime: DateTime.fromISO(task.endDateTime).toISO(),
          checklistId: task.checklist?.id,
          description: task.description,
          depotId: task.depot,
        }));

        const command: ScheduleMachineryJobTaskCommand = {
          assetId: assetId,
          jobTasks: jobTasks,
        };
        return onSubmit(command);
      },
    };
  };
}
