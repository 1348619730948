import { IAjax } from 'src/infrastructure/ajax';
import { kioskUrls } from 'src/domain/services/apiUrls';
import { DateTime } from 'luxon';

type TimesheetItemForDriver = Common.Dtos.TimesheetItemForDriver;
type PrefilledTimesheetItemForDriver = Common.Dtos.PrefilledTimesheetItemForDriver;
type CreateTimesheetForDriverCommand = People.Domain.Commands.Timesheets.CreateTimesheetForDriver.CreateTimesheetForDriverCommand;
type UpdateTimesheetForDriverCommand = People.Domain.Commands.Timesheets.UpdateTimesheetForDriver.UpdateTimesheetForDriverCommand;
type GetTimesheetReminderQuery = People.Domain.Queries.GetTimesheetReminder.GetTimesheetReminderQuery;

export default function init(ajax: IAjax) {
  function getTimesheetsForDriver(dateFrom: DateTime, dateTo: DateTime) {
    return ajax
      .get(kioskUrls.timesheetUrls.getTimesheetsForDriver(dateFrom, dateTo))
      .toPromise()
      .then<string>(r => r.response);
  }

  function getTimesheetForDriver(timesheetId: string) {
    return ajax
      .get(kioskUrls.timesheetUrls.getTimesheetForDriver(timesheetId))
      .toPromise()
      .then<TimesheetItemForDriver>(r => r.response);
  }

  function getPrefilledTimesheetForDriver(date: DateTime) {
    return ajax
      .get(kioskUrls.timesheetUrls.getPrefilledTimesheetForDriver(date))
      .toPromise()
      .then<PrefilledTimesheetItemForDriver>(r => r.response);
  }

  function createTimesheetForDriver(command: CreateTimesheetForDriverCommand) {
    return ajax
      .post(kioskUrls.timesheetUrls.createTimesheetForDriver(), command)
      .toPromise()
      .then<string>(r => r.response);
  }

  function refreshTimesheetForDriver(timesheetId: string) {
    return ajax
      .put(kioskUrls.timesheetUrls.refreshTimesheetForDriver(timesheetId))
      .toPromise()
      .then(r => r.response);
  }

  function updateTimesheetForDriver(command: UpdateTimesheetForDriverCommand) {
    return ajax
      .put(kioskUrls.timesheetUrls.updateTimesheetForDriver(command.id), command)
      .toPromise()
      .then<string>(r => r.response);
  }

  function printTimesheetForDriver(timesheetId: string) {
    return ajax
      .getFile(kioskUrls.timesheetUrls.printTimesheetForDriver(timesheetId))
      .toPromise()
      .then<Blob>(r => r.response);
  }

  function getTimesheetReminder(query: Partial<GetTimesheetReminderQuery>) {
    return ajax
      .get(kioskUrls.timesheetUrls.getTimesheetReminder(query))
      .toPromise()
      .then<boolean>(r => r.response);
  }

  function logTimesheetReminderWasDisplayed(date: DateTime) {
    return ajax
      .post(kioskUrls.timesheetUrls.logTimesheetReminderWasDisplayed(date))
      .toPromise()
      .then<void>(r => {});
  }

  function logTimesheetReminderWasViewed(date: DateTime) {
    return ajax
      .post(kioskUrls.timesheetUrls.logTimesheetReminderWasViewed(date))
      .toPromise()
      .then<void>(r => {});
  }

  return {
    timesheets: {
      getTimesheetsForDriver,
      getTimesheetForDriver,
      createTimesheetForDriver,
      getPrefilledTimesheetForDriver,
      refreshTimesheetForDriver,
      updateTimesheetForDriver,
      printTimesheetForDriver,
      getTimesheetReminder,
      logTimesheetReminderWasDisplayed,
      logTimesheetReminderWasViewed,
    },
  };
}
