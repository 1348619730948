import { IListPageLoadDataRequest } from 'src/domain/baseTypes';
import { IListPageDef } from 'src/views/components/Page/pages/ListPage';
import { ListPage } from 'src/views/components/Page';
import { FieldType, PagePrimarySize } from 'src/views/definitionBuilders/types';
import { TaskCardItem } from 'src/views/components/TaskCard';
import { getDisplayDays, totalHours } from 'src/views/routes/operations/urban/urbanHelpers';
import { DurationFormat } from 'src/views/components/DurationFormat';
import PrimaryTitle from 'src/views/components/Page/PrimaryTitle/PrimaryTitle';

type ListShiftTemplatesQuery = Workshop.Domain.Queries.ShiftTemplate.ListShiftTemplates.ListShiftTemplatesQuery;
type ShiftTemplateListItem = Workshop.Domain.Queries.ShiftTemplate.ListShiftTemplates.ShiftTemplateListItem;

export interface IListShiftTemplatesProps {
  canManageShiftTemplates: boolean;

  listShiftTemplates: (request: IListPageLoadDataRequest<ListShiftTemplatesQuery>) => Promise<void>;
  shiftTemplates: ShiftTemplateListItem[];
  hasMoreData: boolean;
}

const ListShiftTemplates: React.FC<IListShiftTemplatesProps> = (
  props: IListShiftTemplatesProps
) => {
  const getPageDef = (): IListPageDef => {
    return {
      primaryTitle: <PrimaryTitle title="Shifts Templates"></PrimaryTitle>,
      primarySize: PagePrimarySize.full,
      onLoadData: props.listShiftTemplates,
      externalSearch: true,
      createLink: props.canManageShiftTemplates
        ? TaskCardItem.workshop.createShiftTemplate
        : undefined,
      hasMoreData: props.hasMoreData,
      primaryFields: [
        {
          fieldType: FieldType.textField,
          dataAddr: 'shiftName',
          label: 'Shift Name',
          linkTo: d => `/workshop/shift-templates/${d.parentValue.shiftTemplateId}`,
        },
        {
          fieldType: FieldType.readonlyField,
          label: 'Shift Commence',
          dataAddr: 'shiftCommence',
          formatReadonly: d => <DurationFormat value={d.fieldValue} />,
        },
        {
          fieldType: FieldType.readonlyField,
          label: 'Shift End',
          dataAddr: 'shiftEnd',
          formatReadonly: d => <DurationFormat value={d.fieldValue} />,
        },
        {
          fieldType: FieldType.readonlyField,
          label: 'Unpaid Breaks',
          dataAddr: 'unpaidBreaks',
          formatReadonly: d => <DurationFormat value={d.fieldValue} />,
        },
        {
          fieldType: FieldType.readonlyField,
          label: 'Total Shift Hours',
          formatReadonly: data =>
            totalHours(data.parentValue.shiftCommence, data.parentValue.shiftEnd),
        },
        {
          fieldType: FieldType.readonlyField,
          label: 'Days',
          formatReadonly: data => getDisplayDays(data.parentValue).join(', '),
        },
      ],
    };
  };

  return (
    <ListPage
      className="list-shift-templates-component"
      data={props.shiftTemplates}
      def={getPageDef()}
    />
  );
};

export default ListShiftTemplates;
