import { PaneType, FieldType, IPanelDef, IFieldOnChange } from 'src/views/definitionBuilders/types';
import { LeaveType, LeaveStatus, ILeaveTypeDescriptor } from 'src/api/enums';
import { DateTime } from 'luxon';
import { formatDuration } from 'src/infrastructure/formattingUtils';

type LeaveDto = Common.Dtos.LeaveDto;
type LeaveItem = People.Domain.Queries.ViewLeave.LeaveItem;

import styles from 'src/views/routes/people/LeaveMessage.module.scss';
import { StaffMemberFilter } from 'src/views/components/Page/fields/StaffMemberField';
import { ENABLE_SHOW_OVERSEAS_TRAVEL_FIELD } from 'src/appSettings';

const formatLeaveDuration = (leave: LeaveItem): string => {
  const start = DateTime.fromISO(leave.start);
  const end = DateTime.fromISO(leave.end);
  if (start.isValid && end.isValid) {
    const duration = end.diff(start);
    return formatDuration(duration);
  }
  return '';
};

export const getLeaveEditPanelDef = (
  staffMemberId: string | undefined,
  isUpdateMode: boolean,
  allLeaveType: ILeaveTypeDescriptor[],
  timezone: string,
  hideRecurCallback: (hide: boolean) => void,
  uneditedLeaveAccessor?: () => LeaveItem | LeaveDto | undefined
): IPanelDef => {
  // There's an issue with the onChange closure that calls this function.
  // If instead of passing in the accessor function, I just passed leave in directly, it's always undefined inside the onChange.
  // Reasons unknown, hours of time wasted to little avail.
  // Essentially, the onChange closure is old, not being refreshed when the props change.
  const updateRecurVisibility = (d: IFieldOnChange<string> | IFieldOnChange<boolean>) => {
    const start = d.formValues.start;
    const end = d.formValues.end;
    const selectFullDays = d.formValues.selectFullDays;

    if (!isUpdateMode) {
      // Always clear repeats when the dates change as they won't be correct/valid anymore.
      d.setFormValue(['repeats'], []);
      return;
    }

    const pristineLeave = uneditedLeaveAccessor && uneditedLeaveAccessor();

    if (!pristineLeave || !pristineLeave.start || !pristineLeave.end || !start || !end) {
      return undefined;
    }
    const uneditedStartAsDate = DateTime.fromISO(pristineLeave.start, { zone: timezone });
    const uneditedEndAsDate = DateTime.fromISO(pristineLeave.end, { zone: timezone });
    const newStartAsDate = DateTime.fromISO(start, { zone: timezone });
    const newEndAsDate = DateTime.fromISO(end, { zone: timezone });

    const hasSelectFullDaysChanged = selectFullDays !== pristineLeave.selectFullDays;

    const hasStartDateChanged = !uneditedStartAsDate
      .startOf('day')
      .equals(newStartAsDate.startOf('day'));
    const hasEndDateChanged = !uneditedEndAsDate.startOf('day').equals(newEndAsDate.startOf('day'));

    const anyExistingRepeats = pristineLeave.repeats && pristineLeave.repeats.length > 0;
    const hideRecur =
      anyExistingRepeats && (hasSelectFullDaysChanged || hasStartDateChanged || hasEndDateChanged);

    hideRecurCallback(hideRecur);

    if (hideRecur) {
      // Dates clear repeats, but not times
      d.setFormValue(['repeats'], []);
    }

    return undefined;
  };
  const showOverseasTravelField = ENABLE_SHOW_OVERSEAS_TRAVEL_FIELD;

  return {
    panes: [
      {
        paneType: PaneType.formFieldsPane,
        columnCount: 2,
        hidden: !!staffMemberId,
        fields: [
          {
            fieldType: FieldType.staffMemberField,
            label: 'Staff Member',
            dataAddr: 'staffMember',
            mandatory: true,
            readonly: isUpdateMode,
            staffMemberFilter: d => StaffMemberFilter.active,
          },
        ],
      },
      {
        paneType: PaneType.formFieldsPane,
        columnCount: 2,
        fields: [
          {
            fieldType: FieldType.selectField,
            mandatory: true,
            label: 'Leave Type',
            dataAddr: 'type',
            optionItems: allLeaveType,
            valueKey: 'value',
            descriptionKey: 'description',
            useValueOnly: true,
            onChange: d => {
              const isFatigue = (d.fieldData.fieldValue as LeaveType) === LeaveType.Fatigue;
              d.setFormValue(['resetAcknowledgement'], isFatigue ? false : null);
            },
          },
        ],
      },
      {
        paneType: PaneType.formFieldsPane,
        columnCount: 2,
        fields: [
          {
            fieldType: FieldType.yesNoField,
            mandatory: true,
            label: 'Select Full Days',
            dataAddr: 'selectFullDays',
            onChange: d => {
              const start = DateTime.fromISO(d.formValues.start, { zone: timezone });
              const end = DateTime.fromISO(d.formValues.end, { zone: timezone });
              const selectFullDays = d.newFieldValue;

              const toFormValue = (date: DateTime, useFullDays: boolean) =>
                useFullDays ? date.toISODate() : date.toISO();

              if (start.isValid) {
                d.setFormValue(['start'], toFormValue(start.startOf('day'), selectFullDays));
              }
              if (end.isValid) {
                d.setFormValue(['end'], toFormValue(end.startOf('day'), selectFullDays));
              }

              updateRecurVisibility(d);
            },
          },
          {
            fieldType: FieldType.yesNoField,
            label: 'Reset Job Acknowledgements',
            dataAddr: 'resetAcknowledgement',
            mandatory: true,
          },
        ],
      },
      {
        paneType: PaneType.formFieldsPane,
        columnCount: 3,
        hidden: d => !!d.parentValue.selectFullDays,
        fields: [
          {
            fieldType: FieldType.dateTimeField,
            label: 'Start',
            dataAddr: 'start',
            mandatory: true,
            timezone: timezone,
            onChange: updateRecurVisibility,
          },
          {
            fieldType: FieldType.dateTimeField,
            label: 'End',
            dataAddr: 'end',
            mandatory: true,
            timezone: timezone,
            onChange: updateRecurVisibility,
            validate: d => {
              const leaveItem = d.parentValue as LeaveItem;
              return DateTime.fromISO(leaveItem.end, { zone: timezone }) <=
                DateTime.fromISO(leaveItem.start, { zone: timezone })
                ? 'End must be after Start'
                : undefined;
            },
          },
          {
            fieldType: FieldType.readonlyField,
            label: 'Duration',
            formatReadonly: d => {
              return formatLeaveDuration(d.parentValue);
            },
          },
        ],
      },
      {
        paneType: PaneType.formFieldsPane,
        hidden: d => !d.parentValue.selectFullDays,
        columnCount: 3,
        fields: [
          {
            fieldType: FieldType.dateField,
            label: 'First Day Of Leave',
            dataAddr: 'start',
            mandatory: true,
            timezone: timezone,
            onChange: updateRecurVisibility,
          },
          {
            fieldType: FieldType.dateField,
            label: 'Returning To Work On',
            dataAddr: 'end',
            mandatory: true,
            timezone: timezone,
            onChange: updateRecurVisibility,
            validate: d => {
              const leaveItem = d.parentValue as LeaveItem;
              return DateTime.fromISO(leaveItem.end) <= DateTime.fromISO(leaveItem.start)
                ? 'Returning To Work On must be after First Day Of Leave'
                : undefined;
            },
          },
          {
            fieldType: FieldType.readonlyField,
            label: 'Duration',
            formatReadonly: d => {
              return formatLeaveDuration(d.parentValue);
            },
          },
        ],
      },
      {
        paneType: PaneType.formFieldsPane,
        fields: [
          {
            fieldType: FieldType.customField,
            dataAddr: 'none',
            label: ' ',
            hidden: d => d.parentValue.type === LeaveType.Fatigue,
            render: api => {
              return (
                <div>
                  <div className={styles['maintain-leave-custom-message']}>
                    <label>Please Note: </label> All details of this leave will be emailed to the
                    staff member involved.
                  </div>
                </div>
              );
            },
          },
        ],
      },
      {
        paneType: PaneType.formFieldsPane,
        columnCount: 3,
        fields: [
          {
            fieldType: FieldType.yesNoField,
            label: 'Will this leave involve overseas travel?',
            dataAddr: 'overseasTravel',
            mandatory: true,
            hidden: d =>
              !showOverseasTravelField ||
              d.parentValue.type === LeaveType.Fatigue ||
              d.parentValue.type === LeaveType.TimeOffInLieu,
          },
        ],
      },
      {
        paneType: PaneType.formFieldsPane,
        fields: [
          {
            fieldType: FieldType.textAreaField,
            label: 'Description:',
            dataAddr: 'description',
          },
          {
            fieldType: FieldType.readonlyField,
            label: 'Decline Reason',
            dataAddr: 'declineReason',
            hidden: d => d.parentValue.status !== LeaveStatus.Declined,
          },
          {
            fieldType: FieldType.errorField,
            dataAddr: 'recurPanelChanged',
            validate: d => {
              const hasRecurPanelChanged = d.fieldValue === true;
              return hasRecurPanelChanged ? 'You need to Generate Dates to proceed' : undefined;
            },
          },
        ],
      },
    ],
  } as IPanelDef;
};
