import { Table } from 'reactstrap';
import './PrintJobTaskLabour.scss';
import { DateFormat } from 'src/views/components/DateFormat';
import { Fragment } from 'react';

interface IPrintJobTaskLabourProps {
  labour: Workshop.Domain.Queries.Job.PrintJobQuery.JobTaskItemLabour[];
}

const PrintJobTaskLabour: React.FC<IPrintJobTaskLabourProps> = ({ labour }) => {
  const blankLabour: Workshop.Domain.Queries.Job.PrintJobQuery.JobTaskItemLabour = {
    id: 0,
    name: '',
    date: undefined,
    hours: undefined,
  };
  const arrayLabour = labour.slice();
  arrayLabour.push(blankLabour, blankLabour, blankLabour);
  const isEven = (num: number) => {
    return num % 2 === 0;
  };
  if (!isEven(arrayLabour.length)) {
    arrayLabour.push(blankLabour);
  }
  const rows = [];
  for (var i = 0; i < arrayLabour.length; i += 2) {
    rows.push(arrayLabour.slice(i, i + 2));
  }
  return (
    <div className="print-job-task-labour-component">
      <Table>
        <thead>
          <tr>
            <th colSpan={6}>Mechanic</th>
          </tr>
          <tr>
            <th>Name</th>
            <th className="date">Date</th>
            <th className="hours">Hours</th>
            <th>Name</th>
            <th className="date">Date</th>
            <th className="hours">Hours</th>
          </tr>
        </thead>
        <tbody>
          {rows.map((cols, ind) => {
            return (
              <tr key={ind}>
                {cols.map((col, k) => {
                  return (
                    <Fragment key={k}>
                      <td>{col.name}</td>
                      <td className="date">
                        <DateFormat value={col.date} />
                      </td>
                      <td className="hours">{col.hours}</td>
                    </Fragment>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
};

export default PrintJobTaskLabour;
