import { DateTime } from 'luxon';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { ChangeState } from 'src/api/enums';
import { Weekday } from 'src/domain';
import { ListPageLoadCause } from 'src/domain/baseTypes';
import { useRootStore } from 'src/domain/entities/RootStoreModel';
import { IWeekBounds } from 'src/views/components/Page/fields/WeekSelectField';
import { IFormApiWithoutState } from 'src/views/components/Page/forms/base';
import CrudPage, { ICrudPageDef } from 'src/views/components/Page/pages/CrudPage';
import {
  ActionType,
  FieldType,
  IHasChangeState,
  PagePrimarySize,
  PaneType,
  WeekSelectCallbackApi,
} from 'src/views/definitionBuilders/types';
import {
  daySelected,
  getDays,
  getDaysToExclude,
} from 'src/views/routes/operations/urban/urbanHelpers';

type CreateRosterCommand = Workshop.Domain.Commands.Roster.CreateRoster.CreateRosterCommand;
type RosterTemplateListItem = Workshop.Domain.Queries.RosterTemplate.ListRosterTemplates.RosterTemplateListItem;
type RosterWeek = Workshop.Domain.Queries.Roster.GetWeekWithoutRoster.RosterWeek;

type ICreateRosterForm = {
  shifts: (ICreateRosterFormShiftItem & IHasChangeState)[];
  rosterWeek: IWeekBounds | undefined;
  rosterTemplate: RosterTemplateListItem;
  depotId: number;
};

interface ICreateRosterFormShiftItem {
  shiftTemplate: {
    shiftTemplateId: string;
    shiftName: string;
    shiftCommence: string;
    shiftEnd: string;
    monday: boolean;
    tuesday: boolean;
    wednesday: boolean;
    thursday: boolean;
    friday: boolean;
    saturday: boolean;
    sunday: boolean;
  };
  shiftCommence: string;
  shiftEnd: string;
  days: Weekday[];
  staffMembers: string[];
}

const CreateRoster: React.FC = observer(() => {
  let primaryFormApi: IFormApiWithoutState | undefined;
  const rootStore = useRootStore();
  const canManageRosters = rootStore.account.isWorkshopDepartmentMember;

  const createRoster = rootStore.workshop.roster.createRoster;

  const listShiftTemplates = rootStore.workshop.shiftTemplates.listItems;
  const shiftTemplates = rootStore.workshop.shiftTemplates.items.slice();

  const listRosterTemplates = rootStore.workshop.rosterTemplates.listItems;
  const rosterTemplates = rootStore.workshop.rosterTemplates.items.slice();

  const mechanics = rootStore.people.staffMembers.mechanics.slice();
  const loadMechanics = rootStore.people.staffMembers.loadMechanics;

  const getWeekWithoutRosterQuery = rootStore.workshop.rosters.getWeekWithoutRoster;

  const workshopDepots = rootStore.workshopStartup.workshopDepots.slice();
  const defaultWorkshopDepot = rootStore.workshopStartup.defaultWorkshopDepot;
  const [depotId, setDepotId] = useState<number | undefined>(defaultWorkshopDepot?.id);

  useEffect(() => {
    listShiftTemplates({ loadCause: ListPageLoadCause.mount });
    listRosterTemplates({ loadCause: ListPageLoadCause.mount });
    loadMechanics();
  }, []);

  const handlePreSubmitForCreate = (rosterForm: ICreateRosterForm): CreateRosterCommand => {
    const command: CreateRosterCommand = {
      rosterTemplateName: rosterForm.rosterTemplate.rosterTemplateName,
      weekStart: rosterForm.rosterWeek!.weekStart,
      shifts: rosterForm.shifts
        .filter(x => !x.changeState || x.changeState !== ChangeState.Deleted)
        .map(x => {
          return {
            shiftTemplateId: x.shiftTemplate.shiftTemplateId,
            shiftCommence: x.shiftCommence,
            shiftEnd: x.shiftEnd,
            monday: daySelected(x.days, Weekday.mon),
            tuesday: daySelected(x.days, Weekday.tue),
            wednesday: daySelected(x.days, Weekday.wed),
            thursday: daySelected(x.days, Weekday.thu),
            friday: daySelected(x.days, Weekday.fri),
            saturday: daySelected(x.days, Weekday.sat),
            sunday: daySelected(x.days, Weekday.sun),
            staffMemberIds: x.staffMembers,
          };
        }),
      depotId: rosterForm.depotId,
    };
    return command;
  };

  const onInitWeek = async (api: WeekSelectCallbackApi) => {
    const rosterWeek = api.fieldData.fieldValue as IWeekBounds;
    const selectedDepotId = (api.formValues as ICreateRosterForm).depotId;

    if (!rosterWeek && selectedDepotId) {
      const newRosterWeek: RosterWeek = await getWeekWithoutRosterQuery({
        depotId: selectedDepotId,
      });
      const newValues: ICreateRosterForm = {
        ...api.formValues,
        rosterWeek: newRosterWeek,
      };
      api.setFormValues(newValues);
    }
  };

  const onNextWeek = async (api: WeekSelectCallbackApi) => {
    const rosterWeek = api.fieldData.fieldValue as IWeekBounds;
    const selectedDepotId = (api.formValues as ICreateRosterForm).depotId;

    if (rosterWeek) {
      let weekStart = DateTime.fromISO(rosterWeek.weekStart);
      weekStart = weekStart.plus({
        weeks: 1,
      });
      const newRosterWeek: RosterWeek = await getWeekWithoutRosterQuery({
        weekStart: weekStart.toISODate(),
        depotId: selectedDepotId,
      });
      const newValues: ICreateRosterForm = {
        ...api.formValues,
        rosterWeek: newRosterWeek,
      };
      api.setFormValues(newValues);
    }
  };

  const onPreviousWeek = async (api: WeekSelectCallbackApi) => {
    const rosterWeek = api.fieldData.fieldValue as IWeekBounds;
    const selectedDepotId = (api.formValues as ICreateRosterForm).depotId;

    if (rosterWeek) {
      const newRosterWeek = await getWeekWithoutRosterQuery({
        weekStart: rosterWeek.weekStart,
        backwards: true,
        depotId: selectedDepotId,
      });
      const newValues: ICreateRosterForm = {
        ...api.formValues,
        rosterWeek: newRosterWeek,
      };
      api.setFormValues(newValues);
    }
  };

  const getPageDef = (): ICrudPageDef => {
    return {
      primarySize: PagePrimarySize.full,
      primarySection: {
        title: 'Create a Roster',
        getApi: api => (primaryFormApi = api),
        panels: [
          {
            panes: [
              {
                paneType: PaneType.formFieldsPane,
                columnCount: 2,
                fields: [
                  {
                    fieldType: FieldType.selectField,
                    dataAddr: 'depotId',
                    label: 'Depot',
                    useValueOnly: true,
                    valueKey: 'id',
                    descriptionKey: 'description',
                    mandatory: true,
                    optionItems: workshopDepots,
                    readonly: workshopDepots.length < 2,
                    onChange: async d => {
                      const selectedWorkshopDepotId = d.fieldData.fieldValue;
                      primaryFormApi?.setValue(['shifts'], []);
                      primaryFormApi?.setValue(['rosterTemplate'], null);
                      setDepotId(selectedWorkshopDepotId);
                      primaryFormApi?.setValue(
                        ['rosterWeek'],
                        depotId
                          ? await getWeekWithoutRosterQuery({
                              depotId,
                            })
                          : null
                      );
                    },
                  },
                ],
              },
            ],
          },
          {
            panes: [
              {
                paneType: PaneType.formFieldsPane,
                columnCount: 2,
                fields: [
                  {
                    fieldType: FieldType.weekSelectField,
                    label: 'Week',
                    dataAddr: 'rosterWeek',
                    onNext: onNextWeek,
                    onPrevious: onPreviousWeek,
                    onInit: onInitWeek,
                  },
                  {
                    fieldType: FieldType.selectField,
                    label: 'Roster Template',
                    dataAddr: 'rosterTemplate',
                    valueKey: 'rosterTemplateId',
                    descriptionKey: 'rosterTemplateName',
                    mandatory: true,
                    optionItems: rosterTemplates,
                    valuesToExclude: () =>
                      rosterTemplates
                        .filter(t => t.depotId !== depotId)
                        .map(t => t.rosterTemplateId),
                    onChange: d => {
                      if (
                        d.fieldData &&
                        d.fieldData.parentValue &&
                        d.fieldData.parentValue.rosterTemplate
                      ) {
                        const rosterTemplate = d.fieldData.parentValue
                          .rosterTemplate as RosterTemplateListItem;
                        const shifts = rosterTemplate.shifts.map(x => {
                          const { staffMembers, ...shiftTemplate } = x;
                          const formShift: ICreateRosterFormShiftItem = {
                            shiftTemplate: shiftTemplate,
                            shiftCommence: shiftTemplate.shiftCommence,
                            shiftEnd: shiftTemplate.shiftEnd,
                            days: getDays(shiftTemplate),
                            staffMembers: staffMembers.map(y => y.staffMemberId),
                          };
                          return formShift;
                        });
                        d.setFormValue(['shifts'], shifts);
                      }
                    },
                  },
                ],
              },
            ],
          },
          {
            title: 'Shifts',
            dataAddr: 'shifts',
            panes: [
              {
                paneType: PaneType.tablePane,
                mandatory: true,
                dataRequiredForRows: 'sectionValue',
                fields: [
                  {
                    fieldType: FieldType.selectField,
                    label: 'Shift',
                    dataAddr: 'shiftTemplate',
                    valueKey: 'shiftTemplateId',
                    descriptionKey: 'shiftName',
                    mandatory: true,
                    columnWidth: '200px',
                    optionItems: shiftTemplates,
                    linkTo: d =>
                      `/workshop/shift-templates/${d.parentValue.shiftTemplate.shiftTemplateId}`,
                    onChange: d => {
                      if (
                        d.fieldData &&
                        d.fieldData.parentValue &&
                        d.fieldData.parentValue.shiftTemplate
                      ) {
                        const shiftTemplate = d.fieldData.parentValue.shiftTemplate;
                        const defaultDays = getDays(shiftTemplate);
                        const daysAddr = [
                          ...d.fieldDataAddr.slice(0, d.fieldDataAddr.length - 1),
                          'days',
                        ];
                        d.setFormValue(daysAddr, defaultDays);
                        const shiftCommenceAddr = [
                          ...d.fieldDataAddr.slice(0, d.fieldDataAddr.length - 1),
                          'shiftCommence',
                        ];
                        d.setFormValue(shiftCommenceAddr, shiftTemplate.shiftCommence);
                        const shiftEndAddr = [
                          ...d.fieldDataAddr.slice(0, d.fieldDataAddr.length - 1),
                          'shiftEnd',
                        ];
                        d.setFormValue(shiftEndAddr, shiftTemplate.shiftEnd);
                      }
                    },
                  },
                  {
                    fieldType: FieldType.daysOfWeekField,
                    dataAddr: 'days',
                    label: 'Days of Week',
                    mandatory: true,
                    columnWidth: '100px',
                    useValueOnly: true,
                    readonly: data => !data.parentValue || !data.parentValue.shiftTemplate,
                    valuesToExclude: data => {
                      if (data.parentValue && data.parentValue.shiftTemplate) {
                        return getDaysToExclude(data.parentValue.shiftTemplate);
                      }
                      return [];
                    },
                    formatReadonly: data => {
                      if (data.parentValue && data.parentValue.days) {
                        let displayDays = [];
                        const days = data.parentValue.days;
                        if (days.indexOf(Weekday.mon) !== -1) {
                          displayDays.push('Mon');
                        }
                        if (days.indexOf(Weekday.tue) !== -1) {
                          displayDays.push('Tue');
                        }
                        if (days.indexOf(Weekday.wed) !== -1) {
                          displayDays.push('Wed');
                        }
                        if (days.indexOf(Weekday.thu) !== -1) {
                          displayDays.push('Thu');
                        }
                        if (days.indexOf(Weekday.fri) !== -1) {
                          displayDays.push('Fri');
                        }
                        if (days.indexOf(Weekday.sat) !== -1) {
                          displayDays.push('Sat');
                        }
                        if (days.indexOf(Weekday.sun) !== -1) {
                          displayDays.push('Sun');
                        }
                        return displayDays.join(', ');
                      }
                      return '';
                    },
                  },
                  {
                    fieldType: FieldType.timeField,
                    dataAddr: ['shiftCommence'],
                    label: 'Shift Commence',
                    mandatory: true,
                    readonly: data => !data.parentValue || !data.parentValue.shiftTemplate,
                    columnWidth: '100px',
                  },
                  {
                    fieldType: FieldType.timeField,
                    dataAddr: ['shiftEnd'],
                    label: 'Shift End',
                    mandatory: true,
                    readonly: data => !data.parentValue || !data.parentValue.shiftTemplate,
                    columnWidth: '100px',
                  },
                  {
                    fieldType: FieldType.selectMultiField,
                    dataAddr: ['staffMembers'],
                    label: 'Staff Members',
                    valueKey: 'id',
                    descriptionKey: 'name',
                    optionItems: mechanics.filter(x => x.active),
                    useValueOnly: true,
                    mandatory: true,
                    readonly: data => !data.parentValue || !data.parentValue.shiftTemplate,
                  },
                  {
                    fieldType: FieldType.actionListField,
                    dataAddr: '',
                    columnWidth: '1px',
                    actionGroups: [
                      {
                        actions: [
                          {
                            actionType: ActionType.removeArrayItemActionButton,
                            label: 'Remove Line',
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
              {
                paneType: PaneType.actionListPane,
                actionGroups: [
                  {
                    actions: [
                      {
                        actionType: ActionType.addArrayItemActionButton,
                        label: 'Add Shift',
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
        onFormPreSubmit: handlePreSubmitForCreate,
        onFormSubmit: createRoster,
      },
    };
  };

  return defaultWorkshopDepot ? (
    <CrudPage
      def={getPageDef}
      isEditingForbidden={!canManageRosters}
      mode="create"
      createDefaultData={{
        depotId: defaultWorkshopDepot.id,
      }}
      onCancel={async () => {
        if (defaultWorkshopDepot) {
          primaryFormApi?.setValue(
            'rosterWeek',
            await getWeekWithoutRosterQuery({
              depotId: defaultWorkshopDepot.id,
            })
          );
        }
      }}
    />
  ) : null;
});

export default CreateRoster;
