import './WorkshopJob.scss';
import { Component } from 'react';
import { IWorkshopJobItem } from 'src/domain/entities/operations/job/ListJobsForAllocationsModel';

const descriptionPrefix = 'W • Maintenance';

interface IWorkshopJobProps {
  job: IWorkshopJobItem;
}

export default class WorkshopJob extends Component<IWorkshopJobProps> {
  render() {
    const { job } = this.props;
    const description = `${descriptionPrefix} • ${job.jobNumber}`;
    return (
      <div className="allocations-workshop-job-component" title={description}>
        <div className="description">{description}</div>
      </div>
    );
  }
}
