import { Component } from 'react';
import memoizeOne from 'src/infrastructure/memoizeOne';
import {
  IFieldMeta,
  IFieldData,
  FieldType,
  IToggleButtonFieldDef,
  IYesNoFieldDef,
} from 'src/views/definitionBuilders/types';
import { IFieldApi } from 'src/views/components/Page/forms/Field';
import ToggleButtonPageField from './ToggleButtonPageField';

const options = [
  { value: true, description: 'Yes' },
  { value: false, description: 'No' },
];

interface IYesNoPageFieldProps {
  fieldApi: IFieldApi;
  fieldDef: IYesNoFieldDef;
  fieldMeta: IFieldMeta;
  // tslint:disable-next-line:no-any
  fieldData: IFieldData<boolean>;
}

class YesNoPageField extends Component<IYesNoPageFieldProps> {
  private readonly getFieldDef = memoizeOne(
    (def: IYesNoFieldDef): IToggleButtonFieldDef => {
      return {
        ...def,
        fieldType: FieldType.toggleButtonField,
        valueKey: 'value',
        descriptionKey: 'description',
        optionItems: options,
        useValueOnly: true,
      };
    }
  );

  render() {
    const { fieldDef: def } = this.props;
    const toggleButtonFieldDef = this.getFieldDef(def);
    return <ToggleButtonPageField {...this.props} fieldDef={toggleButtonFieldDef} />;
  }
}

export default YesNoPageField;
