import { observer } from 'mobx-react';
import {
  AuthorisedDeviceApplication,
  AuthorisedDeviceStatus,
  allAuthorisedDeviceApplication,
  allAuthorisedDeviceStatus,
  authorisedDeviceApplicationDescription,
} from 'src/api/enums';
import { ENABLE_ALCO_READINGS } from 'src/appSettings';
import { ListPageLoadCause } from 'src/domain/baseTypes';
import { useRootStore } from 'src/domain/entities/RootStoreModel';
import { BanIcon, CheckIcon, DeviceIcon, KeyIcon, PlusIcon, TrashIcon } from 'src/images/icons';
import PrimaryTitle from 'src/views/components/Page/PrimaryTitle/PrimaryTitle';
import ListPage, { IListPageDef } from 'src/views/components/Page/pages/ListPage';
import { getSubmitCloseModalActionGroupDef } from 'src/views/definitionBuilders/common';
import {
  ActionType,
  IModalActionButtonDef,
  PagePrimarySize,
  PaneType,
  ShellModalSize,
} from 'src/views/definitionBuilders/types';
import { FieldType } from 'src/views/definitionBuilders/types/field';
import withQueryParams, { IQueryParamsProps } from 'src/views/hocs/withQueryParams';
import './ListAuthorisedDevices.scss';
const alcoReadingEnabled = ENABLE_ALCO_READINGS;

type ListAuthorisedDevicesQuery = Operations.Domain.Queries.AuthorisedDevices.ListAuthorisedDevices.ListAuthorisedDevicesQuery;

const ListAuthorisedDevices: React.FC<IQueryParamsProps<ListAuthorisedDevicesQuery>> = observer(
  (props: IQueryParamsProps<ListAuthorisedDevicesQuery>) => {
    const rootStore = useRootStore();
    const canManageAlcoReadingDevices = rootStore.account.isAdminUser;

    const loadAuthorisedDevices = rootStore.operations.authorisedDevices.list.listItems;
    const authorisedDevices = rootStore.operations.authorisedDevices.list.items.slice();
    const hasMoreData = rootStore.operations.authorisedDevices.list.hasMoreItems;
    const getNameExists = rootStore.operations.authorisedDevices.getNameExists;
    const createDevice = rootStore.operations.authorisedDevices.createDevice;
    const generateAuthorisationCode =
      rootStore.operations.authorisedDevices.generateAuthorisationCode;
    const revokeAuthorisation = rootStore.operations.authorisedDevices.revokeAuthorisation;
    const deleteDevice = rootStore.operations.authorisedDevices.deleteDevice;
    const enableClockOnTest = rootStore.operations.authorisedDevices.enableAlcoReading;
    const diableClockOnTest = rootStore.operations.authorisedDevices.disableAlcoReading;

    const getCreateDeviceModalActionDef = (): IModalActionButtonDef => {
      return {
        actionType: ActionType.modalActionButton,
        label: 'Add New Device',
        icon: <PlusIcon />,
        level: 'primary',
        modalSize: ShellModalSize.oneQuarter,
        modalDef: () => ({
          title: 'Add New Device',
          asForm: true,
          panels: [
            {
              panes: [
                {
                  paneType: PaneType.formFieldsPane,
                  fields: [
                    {
                      fieldType: FieldType.textField,
                      dataAddr: 'name',
                      tooltip: 'For Fleet devices, prefix your device name with Tablet',
                      label: 'Device Name',
                      mandatory: true,
                      validateAsync: async d => {
                        if (!d.fieldValue) {
                          return undefined;
                        }
                        const exists = await getNameExists(d.fieldValue);
                        return exists ? `Name is already in use` : undefined;
                      },
                    },
                    {
                      fieldType: FieldType.selectField,
                      dataAddr: 'application',
                      label: 'Application',
                      mandatory: true,
                      valueKey: 'value',
                      descriptionKey: 'description',
                      useValueOnly: true,
                      optionItems: allAuthorisedDeviceApplication,
                    },
                  ],
                },
              ],
            },
          ],
          secondaryActions: [getSubmitCloseModalActionGroupDef()],
          onFormSubmit: v =>
            createDevice(v).then(r => {
              loadAuthorisedDevices({
                query: { ...props.getQueryParams() },
                loadCause: ListPageLoadCause.refresh,
              });
            }),
        }),
      };
    };

    const getGenerateCodeModalActionDef = (): IModalActionButtonDef => {
      return {
        actionType: ActionType.modalActionButton,
        label: 'Generate New Authorisation Code',
        icon: <KeyIcon />,
        level: 'primary',
        modalSize: ShellModalSize.oneThird,
        modalDef: modalDefApi => ({
          title: 'Generate New Authorisation Code',
          asForm: true,
          panels: formApi => [
            {
              panes: [
                {
                  paneType: PaneType.formFieldsPane,
                  fields: [
                    {
                      fieldType: FieldType.customField,
                      label: '',
                      dataAddr: 'fake',
                      render: () => (
                        <>
                          <p>
                            To authorise "<strong>{modalDefApi.actionData.actionValue.name}</strong>
                            ", generate an Authorisation Code below, then enter it on the device to
                            be authorised. Generating a new code will invalidate any previous codes.
                          </p>
                          <p>Please note that the code will expire in 5 minutes.</p>
                        </>
                      ),
                    },
                  ],
                },
                {
                  paneType: PaneType.actionListPane,
                  actionGroups: [
                    {
                      actions: [
                        {
                          actionType: ActionType.actionButton,
                          label: 'Generate Authorisation Code',
                          icon: <DeviceIcon />,
                          level: 'primary',
                          onClick: async () => {
                            const deviceId = modalDefApi.actionData.actionValue.id;
                            if (deviceId) {
                              const code = await generateAuthorisationCode(deviceId);
                              formApi.setValue('code', code);
                            }
                          },
                        },
                      ],
                    },
                  ],
                },
                {
                  paneType: PaneType.formFieldsPane,
                  fields: [
                    {
                      fieldType: FieldType.readonlyField,
                      label: 'Authorisation Code',
                      formatReadonly: () => (
                        <span className="list-authorised-devices-authorisation-code">
                          {formApi.getValue('code') || ''}
                        </span>
                      ),
                      hidden: () => !formApi.getValue('code'),
                    },
                    {
                      fieldType: FieldType.customField,
                      label: '',
                      dataAddr: 'fake',
                      hidden: () => !formApi.getValue('code'),
                      render: () => (
                        <p>
                          Make a note of this code, as <strong>it cannot be displayed again</strong>{' '}
                          once this page is closed.
                        </p>
                      ),
                    },
                  ],
                },
              ],
            },
          ],
          secondaryActions: [
            {
              actions: [
                {
                  actionType: ActionType.closeModalActionButton,
                  label: 'Close',
                },
              ],
            },
          ],
        }),
      };
    };

    const getDeleteDeviceModalActionDef = (): IModalActionButtonDef => {
      return {
        actionType: ActionType.modalActionButton,
        label: 'Delete Device',
        icon: <TrashIcon />,
        modalSize: ShellModalSize.oneQuarter,
        modalDef: modalDefApi => ({
          title: 'Delete Device',
          asForm: true,
          panels: [
            {
              panes: [
                {
                  paneType: PaneType.formFieldsPane,
                  fields: [
                    {
                      fieldType: FieldType.customField,
                      label: '',
                      dataAddr: 'fake',
                      render: () => (
                        <p>
                          The Authorised Device "
                          <strong>{modalDefApi.actionData.actionValue.name}</strong>" will be
                          deleted.{' '}
                          {modalDefApi.actionData.actionValue.status ===
                          AuthorisedDeviceStatus.Paired ? (
                            <span>
                              The paired device will no longer be authorised to sign in until it is
                              paired again.
                            </span>
                          ) : null}
                        </p>
                      ),
                    },
                  ],
                },
              ],
            },
          ],
          secondaryActions: [getSubmitCloseModalActionGroupDef('Delete')],
          onFormSubmit: () => {
            const deviceId = modalDefApi.actionData.actionValue.id;
            return deleteDevice(deviceId).then(() => {
              loadAuthorisedDevices({
                query: { ...props.getQueryParams() },
                loadCause: ListPageLoadCause.refresh,
              });
            });
          },
        }),
      };
    };
    const getPageDef = (): IListPageDef => {
      return {
        primaryTitle: (
          <PrimaryTitle
            title="Authorised Devices"
            link="https://central-ops.notion.site/Authorised-Devices-d509cc348e854ca7baee4089b629d365"
            notes="If a device is inactive for more than 30 days. Re-authorisation will be required"></PrimaryTitle>
        ),
        onLoadData: loadAuthorisedDevices,
        externalSearch: true,
        hasMoreData: hasMoreData,
        primarySize: PagePrimarySize.full,
        primaryFields: [
          {
            fieldType: FieldType.textField,
            dataAddr: 'name',
            label: 'Device Name',
            orderBy: 'Name',
          },
          {
            fieldType: FieldType.selectField,
            dataAddr: 'application',
            label: 'Application',
            valueKey: 'value',
            descriptionKey: 'description',
            useValueOnly: true,
            optionItems: allAuthorisedDeviceApplication,
            orderBy: 'Application',
          },
          {
            fieldType: FieldType.selectField,
            dataAddr: 'status',
            label: 'Status',
            valueKey: 'value',
            descriptionKey: 'description',
            useValueOnly: true,
            optionItems: allAuthorisedDeviceStatus,
            orderBy: 'Status',
          },
          {
            fieldType: FieldType.textField,
            dataAddr: 'lastReleaseId',
            label: 'Release Id',
            orderBy: 'LastReleaseId',
          },
          {
            fieldType: FieldType.textField,
            dataAddr: 'lastReleaseName',
            label: 'Release Name',
            orderBy: 'LastReleaseName',
          },
          {
            fieldType: FieldType.textField,
            dataAddr: 'lastAssetRegistrationNumber',
            label: 'Last Asset',
            orderBy: 'LastAssetRegistrationNumber',
          },
          {
            fieldType: FieldType.textField,
            dataAddr: 'lastStaffMemberName',
            label: 'Last User',
            orderBy: 'LastStaffMemberName',
          },
          {
            fieldType: FieldType.dateTimeField,
            dataAddr: 'lastSeenOn',
            label: 'Last Seen On',
            orderBy: 'LastSeenOn',
          },
          {
            fieldType: FieldType.actionListField,
            actionGroups: [
              {
                actions: [
                  getGenerateCodeModalActionDef(),
                  {
                    actionType: ActionType.actionCollection,
                    actionGroups: [
                      {
                        actions: [
                          getRevokeAuthorisationModalActionDef(),

                          {
                            actionType: ActionType.modalActionButton,
                            label: 'Enable Clock On Breath Test',
                            icon: <CheckIcon />,
                            hidden: d =>
                              !canManageAlcoReadingDevices ||
                              !alcoReadingEnabled ||
                              d.actionValue.application !== AuthorisedDeviceApplication.Kiosk ||
                              d.actionValue.isAlcoReadingEnabled,
                            modalSize: ShellModalSize.oneQuarter,
                            modalDef: modalDefApi => ({
                              title: 'Enable Clock On Breath Test',
                              asForm: true,
                              panels: [
                                {
                                  panes: [
                                    {
                                      paneType: PaneType.formFieldsPane,
                                      fields: [
                                        {
                                          fieldType: FieldType.customField,
                                          label: '',
                                          dataAddr: 'fake',
                                          render: () => (
                                            <p>
                                              Enabling this for "
                                              <strong>
                                                {modalDefApi.actionData.actionValue.name}
                                              </strong>
                                              " will require all staff to conduct a breath test
                                              prior to clocking on.
                                            </p>
                                          ),
                                        },
                                      ],
                                    },
                                  ],
                                },
                              ],
                              secondaryActions: [getSubmitCloseModalActionGroupDef('Enable')],
                              onFormSubmit: () => {
                                const deviceId = modalDefApi.actionData.actionValue.id;
                                return enableClockOnTest(deviceId).then(() => {
                                  loadAuthorisedDevices({
                                    query: { ...props.getQueryParams() },
                                    loadCause: ListPageLoadCause.refresh,
                                  });
                                });
                              },
                            }),
                          },
                          {
                            actionType: ActionType.modalActionButton,
                            label: 'Bypass Clock On Breath Test',
                            icon: <BanIcon />,
                            hidden: d =>
                              !canManageAlcoReadingDevices ||
                              !alcoReadingEnabled ||
                              d.actionValue.application !== AuthorisedDeviceApplication.Kiosk ||
                              !d.actionValue.isAlcoReadingEnabled,
                            modalSize: ShellModalSize.oneQuarter,
                            modalDef: modalDefApi => ({
                              title: 'Bypass Clock On Breath Test',
                              asForm: true,
                              panels: [
                                {
                                  panes: [
                                    {
                                      paneType: PaneType.formFieldsPane,
                                      fields: [
                                        {
                                          fieldType: FieldType.customField,
                                          label: '',
                                          dataAddr: 'fake',
                                          render: () => (
                                            <p>
                                              Disabling this for "
                                              <strong>
                                                {modalDefApi.actionData.actionValue.name}
                                              </strong>
                                              " will mean that staff will no longer be required to
                                              conduct a breath test prior to clocking on.
                                            </p>
                                          ),
                                        },
                                      ],
                                    },
                                  ],
                                },
                              ],
                              secondaryActions: [getSubmitCloseModalActionGroupDef('Ok')],
                              onFormSubmit: () => {
                                const deviceId = modalDefApi.actionData.actionValue.id;
                                return diableClockOnTest(deviceId).then(() => {
                                  loadAuthorisedDevices({
                                    query: { ...props.getQueryParams() },
                                    loadCause: ListPageLoadCause.refresh,
                                  });
                                });
                              },
                            }),
                          },
                        ],
                      },
                      {
                        actions: [getDeleteDeviceModalActionDef()],
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
        primaryActions: [
          {
            actions: [getCreateDeviceModalActionDef()],
          },
        ],
      };
    };

    const getRevokeAuthorisationModalActionDef = (): IModalActionButtonDef => {
      return {
        actionType: ActionType.modalActionButton,
        label: 'Revoke Authorisation',
        icon: <BanIcon />,
        hidden: d => d.actionValue.status === AuthorisedDeviceStatus.Unpaired,
        modalSize: ShellModalSize.oneQuarter,
        modalDef: modalDefApi => ({
          title: 'Revoke Authorisation',
          asForm: true,
          panels: [
            {
              panes: [
                {
                  paneType: PaneType.formFieldsPane,
                  fields: [
                    {
                      fieldType: FieldType.customField,
                      label: '',
                      dataAddr: 'fake',
                      render: () => (
                        <p>
                          Revoking the authorisation to "
                          <strong>{modalDefApi.actionData.actionValue.name}</strong>" will prevent
                          it from being used for the{' '}
                          {authorisedDeviceApplicationDescription(
                            modalDefApi.actionData.actionValue.application
                          )}{' '}
                          application <strong>immedately</strong>.
                        </p>
                      ),
                    },
                  ],
                },
              ],
            },
          ],
          secondaryActions: [getSubmitCloseModalActionGroupDef('Revoke')],
          onFormSubmit: () => {
            const deviceId = modalDefApi.actionData.actionValue.id;
            return revokeAuthorisation(deviceId).then(() => {
              loadAuthorisedDevices({
                query: { ...props.getQueryParams() },
                loadCause: ListPageLoadCause.refresh,
              });
            });
          },
        }),
      };
    };

    return (
      <ListPage
        className="list-authorised-devices-component"
        data={authorisedDevices}
        def={getPageDef()}
      />
    );
  }
);

export default withQueryParams(ListAuthorisedDevices);
