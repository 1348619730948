import { RouteComponentProps } from 'react-router-dom';
import {
  PagePrimarySize,
  PaneType,
  FieldType,
  ActionType,
  IHasChangeState,
} from 'src/views/definitionBuilders/types';
import CrudPage, { CrudPageMode, ICrudPageDef } from 'src/views/components/Page/pages/CrudPage';
import { ChangeState } from 'src/api/enums';
import deepEqual from 'deep-equal';
import { TrashIcon, UndoIcon } from 'src/images/icons';
import { useState } from 'react';
import IconButton from 'src/views/kioskRoutes/components/presentational/IconButton/IconButton';
import styles from './maintainVehicleType.module.scss';

type VehicleTypeItem = Common.Dtos.VehicleTypeItem;
type VehicleTypeRate = Common.AggregatesModel.VehicleTypeAggregate.VehicleTypeRate;
type CreateVehicleTypeCommand = Operations.Domain.Commands.VehicleType.CreateVehicleTypeCommand;
type UpdateVehicleTypeCommand = Operations.Domain.Commands.VehicleType.UpdateVehicleTypeCommand;
type RateItem = Operations.Domain.Commands.VehicleType.UpdateVehicleTypeCommand.RateItem;

export interface IMaintainVehicleTypeProps {
  mode: CrudPageMode;
  canManageVehicleTypes: boolean;

  loadVehicleType: (id: string) => Promise<void>;
  vehicleType: VehicleTypeItem | undefined;

  createVehicleType: (command: CreateVehicleTypeCommand) => Promise<void>;
  updateVehicleType: (command: UpdateVehicleTypeCommand) => Promise<void>;
  checkForUniqueVehicleTypeDescription: (
    description: string,
    isUpdating: boolean
  ) => Promise<Common.Dtos.UniqueNameCheckResultDto>;
}

interface IUpdateVehicleTypeRouteParams {
  id: string;
}

type InternalProps = IMaintainVehicleTypeProps & RouteComponentProps<IUpdateVehicleTypeRouteParams>;

export const MaintainVehicleType: React.FC<InternalProps> = (props: InternalProps) => {
  const isUpdateMode = props.mode === 'update';
  const VehicleTypeId = props.match.params.id;
  const [hasDuplicateStart, setHasDuplicateStart] = useState<boolean>(false);

  const handlePreSubmitForCreate = (part: VehicleTypeItem): CreateVehicleTypeCommand => {
    return {
      description: part.description,
      notes: part.notes,
      rates: part.vehicleTypeRates,
    };
  };

  const handlePreSubmitForUpdate = (part: VehicleTypeItem): UpdateVehicleTypeCommand => {
    const originalRateItems = props.vehicleType && props.vehicleType.vehicleTypeRates;
    const getChangeState = (i: RateItem & IHasChangeState) => {
      if (i.changeState === ChangeState.Deleted) {
        return ChangeState.Deleted;
      }

      const originalItem = originalRateItems && originalRateItems.find(x => x.id === i.id);

      if (i.changeState === ChangeState.Added || originalItem === undefined) {
        return ChangeState.Added;
      }

      if (!originalItem) {
        throw new Error('Cannot find original Rate Item');
      }
      return deepEqual(i, originalItem) ? ChangeState.Unchanged : ChangeState.Modified;
    };

    return {
      vehicleTypeId: part.id,
      description: part.description,
      notes: part.notes,
      rates: part.vehicleTypeRates.map(v => ({
        id: v.id,
        vehicleTypeId: part.id,
        price: v.price,
        kilometerStart: v.kilometerStart,
        kilometerEnd: v.kilometerEnd,
        changeState: getChangeState(v as RateItem),
      })),
    };
  };

  const checkDuplicates = (newRates: VehicleTypeRate[]) => {
    const kilometerStarts = newRates.map(r => r.kilometerStart);
    const hasDuplicates = new Set(kilometerStarts).size !== kilometerStarts.length;
    setHasDuplicateStart(hasDuplicates);
    return hasDuplicates;
  };

  const updateRanges = (rates: Array<VehicleTypeRate & IHasChangeState>) => {
    const newRates = rates
      .filter(r => r.changeState !== ChangeState.Deleted)
      .sort((a: VehicleTypeRate, b: VehicleTypeRate) => a.kilometerStart - b.kilometerStart);

    for (let i = 0; i < newRates.length; i++) {
      if (!newRates[i + 1]) {
        newRates[i] = {
          ...newRates[i],
          kilometerEnd: undefined,
        };
        break;
      }

      newRates[i] = { ...newRates[i], kilometerEnd: newRates[i + 1].kilometerStart - 1 };
    }

    checkDuplicates(newRates);
    return [...newRates, ...rates.filter(r => r.changeState === ChangeState.Deleted)];
  };

  const getPageDef = (mode: CrudPageMode, updating: boolean): ICrudPageDef => {
    return {
      primarySize: PagePrimarySize.half,
      primarySection: {
        title: isUpdateMode ? 'Manage Vehicle Type' : 'Create a Vehicle Type',
        primaryActions: [],
        panels: [
          {
            panes: [
              {
                paneType: PaneType.formFieldsPane,
                fields: [
                  {
                    fieldType: FieldType.textField,
                    label: 'Vehicle Type',
                    dataAddr: 'description',
                    maxLength: 200,
                    mandatory: true,
                    validateAsync: async d => {
                      if (
                        !d.fieldValue ||
                        (isUpdateMode &&
                          props.vehicleType &&
                          props.vehicleType.description.toUpperCase() ===
                            d.fieldValue.toUpperCase())
                      ) {
                        return undefined;
                      }

                      const result = await props.checkForUniqueVehicleTypeDescription(
                        d.fieldValue,
                        isUpdateMode
                      );
                      return result.nameExists ? `Vehicle type is already in use` : undefined;
                    },
                  },
                  {
                    fieldType: FieldType.textAreaField,
                    label: 'Notes',
                    dataAddr: 'notes',
                    maxLength: 1024,
                  },
                ],
              },
            ],
          },
          {
            title: 'Rates',
            dataAddr: 'vehicleTypeRates',
            panes: [
              {
                paneType: PaneType.tablePane,
                mandatory: false,
                dataRequiredForRows: 'paneValue',
                fields: [
                  {
                    fieldType: FieldType.numericField,
                    label: 'From KM',
                    dataAddr: 'kilometerStart',
                    mandatory: true,
                    orderBy: 'kilometerStart',
                    formatReadonly: api => api.fieldValue,
                    readonly: api => api.fieldDataAddr[1] === 0 && api.fieldValue === 0,
                    onBlur: api => {
                      api.setFormValue(
                        ['vehicleTypeRates'],
                        updateRanges(api.formValues['vehicleTypeRates'])
                      );
                    },
                    validate: api => {
                      if (api.fieldValue && api.fieldValue <= 0) {
                        return 'KM Range must be a positive number';
                      }

                      if (hasDuplicateStart) {
                        return 'Start kilometer value must be unqiue';
                      }

                      return undefined;
                    },
                  },
                  {
                    fieldType: FieldType.numericField,
                    label: 'Rate',
                    dataAddr: 'price',
                    mandatory: true,
                    formatReadonly: 'currency',
                    onBlur: api => {
                      api.setFormValue(
                        ['vehicleTypeRates'],
                        updateRanges(api.formValues['vehicleTypeRates'])
                      );
                    },
                    numericConfig: {
                      numericType: 'unsignedDecimal',
                      maxPointDigits: 2,
                    },
                  },
                  {
                    fieldType: FieldType.textField,
                    readonly: true,
                    label: 'KM Range',
                    dataAddr: 'kilometerEnd',
                    formatReadonly: d => {
                      const isDeleted = d.parentValue.changeState === ChangeState.Deleted;

                      return d.fieldValue && !isDeleted ? (
                        <span>
                          {d.parentValue.kilometerStart} - {d.fieldValue}
                        </span>
                      ) : (
                        <>
                          {d.parentValue.kilometerStart === 0 &&
                          d.parentValue.kilometerEnd === 0 ? (
                            <span>
                              {d.parentValue.kilometerStart} - {d.fieldValue}
                            </span>
                          ) : (
                            !isDeleted && <span>{d.parentValue.kilometerStart} +</span>
                          )}
                        </>
                      );
                    },
                  },
                  {
                    fieldType: FieldType.customField,
                    dataAddr: 'fake',
                    columnWidth: '1px',
                    hidden: d => {
                      const index = d['fieldDataAddr'][1];
                      return (
                        index === 0 ||
                        (isUpdateMode && !updating) ||
                        (d.parentValue && d.parentValue.changeState === ChangeState.Unchanged)
                      );
                    },
                    render: x => {
                      return (
                        <IconButton
                          onClick={() => {
                            x.meta.removeArrayItem!();
                            // Remove doesn't happen immediately
                            setTimeout(() => {
                              x.formApi.setValue(
                                ['vehicleTypeRates'],
                                updateRanges(x.formApi.getValue('vehicleTypeRates'))
                              );
                            }, 0);
                          }}
                          icon={
                            x.data.parentValue.changeState === ChangeState.Deleted ? (
                              <UndoIcon className={styles.deleteRateIcon} />
                            ) : (
                              <TrashIcon className={styles.deleteRateIcon} />
                            )
                          }
                        />
                      );
                    },
                  },
                ],
              },
              {
                paneType: PaneType.actionListPane,
                hidden: isUpdateMode && !updating,
                actionGroups: [
                  {
                    actions: [
                      {
                        actionType: ActionType.addArrayItemActionButton,
                        label: 'Add Rate Range',
                        newItemData: {
                          kilometerEnd: undefined,
                        },
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
        onFormPreSubmit: isUpdateMode ? handlePreSubmitForUpdate : handlePreSubmitForCreate,
        onFormSubmit: isUpdateMode ? props.updateVehicleType : props.createVehicleType,
      },
      secondarySections: [],
    };
  };

  const { mode, loadVehicleType, vehicleType, canManageVehicleTypes } = props;
  return (
    <CrudPage
      def={({ updating }) => getPageDef(mode, updating)}
      mode={mode}
      isEditingForbidden={!canManageVehicleTypes}
      onLoadData={() => loadVehicleType(VehicleTypeId)}
      data={vehicleType}
      createDefaultData={{
        vehicleTypeRates: [
          {
            kilometerStart: 0,
          },
        ],
      }}
    />
  );
};

export default MaintainVehicleType;
