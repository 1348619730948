import { types, flow, getRoot } from 'mobx-state-tree';
import { getAjax } from 'src/domain/services';
import { complianceUrls } from 'src/domain/services/apiUrls';
import { IRootStoreModel } from 'src/domain/entities/RootStoreModel';
import { NotificationType } from 'src/domain';

type UpdateFatigueBreachEventCommand = Operations.Domain.Commands.FatigueBreachEvent.UpdateFatigueBreachEvent.UpdateFatigueBreachEventCommand;
type InvalidateFatigueBreachEventCommand = Operations.Domain.Commands.FatigueBreachEvent.InvalidateFatigueBreachEvent.InvalidateFatigueBreachEventCommand;
type ReactivateFatigueBreachEventCommand = Operations.Domain.Commands.FatigueBreachEvent.ReactivateFatigueBreachEvent.ReactivateFatigueBreachEventCommand;
type FatigueBreachEventItem = Operations.Domain.Queries.FatigueBreachEvent.FatigueBreachEventItem;
type ActivityLogTransaction = Operations.Domain.Queries.GetActivityLog.ActivityLogTransaction;
type VerifyFatigueBreachEventResponse = Operations.Domain.Commands.FatigueBreachEvent.VerifyFatigueBreachEvent.VerifyFatigueBreachEventResponse;

export const FatigueBreachEventModel = types
  .model('FatigueBreachEventModel', {
    fatigueBreachEvent: types.maybe(types.frozen<FatigueBreachEventItem>()),
    verifyFatigueBreachEvent: types.maybe(types.frozen<VerifyFatigueBreachEventResponse>()),
    activityLogs: types.array(types.frozen<ActivityLogTransaction>()),
  })
  .actions(self => {
    const ajax = getAjax(self);
    const root = getRoot(self) as IRootStoreModel;

    const loadFatigueBreachEvent = flow(function*(fatigueBreachEventId: string) {
      self.fatigueBreachEvent = yield ajax.compliance.fatigueBreachEvent.loadFatigueBreachEvent(
        fatigueBreachEventId
      );
      self.verifyFatigueBreachEvent = undefined;
    });

    const updateFatigueBreachEvent = flow(function*(command: UpdateFatigueBreachEventCommand) {
      yield ajax.compliance.fatigueBreachEvent.updateFatigueBreachEvent(command);
      yield loadFatigueBreachEvent(command.id);
    });

    const closeFatigueBreachEvent = flow(function*(fatigueBreachEventId: string) {
      yield ajax.compliance.fatigueBreachEvent.closeFatigueBreachEvent(fatigueBreachEventId);
      yield loadFatigueBreachEvent(fatigueBreachEventId);
    });

    const verify = flow(function*(fatigueBreachEventId: string) {
      self.verifyFatigueBreachEvent = yield ajax.compliance.fatigueBreachEvent.verifyFatigueBreachEvent(
        fatigueBreachEventId
      );
    });

    const clearVerifyFatigueBreachEvent = () => {
      self.verifyFatigueBreachEvent = undefined;
    };

    const invalidateFatigueBreachEvent = flow(function*(
      command: InvalidateFatigueBreachEventCommand
    ) {
      yield ajax.compliance.fatigueBreachEvent.invalidateFatigueBreachEvent(command);
      yield loadFatigueBreachEvent(command.id);
      yield listActivityLogs(command.id);
      self.verifyFatigueBreachEvent = undefined;
    });

    const reactivateFatigueBreachEvent = flow(function*(
      command: ReactivateFatigueBreachEventCommand
    ) {
      yield ajax.compliance.fatigueBreachEvent.reactivateFatigueBreachEvent(command);
      yield loadFatigueBreachEvent(command.id);
      yield listActivityLogs(command.id);
    });

    const listActivityLogs = flow(function*(fatigueBreachEventId: string) {
      self.activityLogs = yield ajax.raw
        .get(
          complianceUrls.fatigueBreachEventUrls.loadFatigueBreachEventActivityLogs(
            fatigueBreachEventId
          )
        )
        .toPromise()
        .then<Operations.Domain.Queries.GetActivityLog.ActivityLogTransaction[]>(r => {
          return r.response;
        });
    });

    const exportFatigueBreachEventToPdf = flow(function*(fatigueBreachEventId: string) {
      return yield ajax.compliance.fatigueBreachEvent.exportFatigueBreachEventToPdf(
        fatigueBreachEventId
      );
    });

    const exportToExcel = flow(function*(
      query: Partial<Operations.Domain.Queries.FatigueBreachEvent.ListFatigueBreachEventsQuery>
    ) {
      root.notifications.addNotification(`The file is being generated...`, {
        type: NotificationType.info,
      });

      return yield ajax.compliance.fatigueBreachEvent.exportAllFatigueBreachEventToExcel(query);
    });

    return {
      loadFatigueBreachEvent,
      updateFatigueBreachEvent,
      closeFatigueBreachEvent,
      invalidateFatigueBreachEvent,
      reactivateFatigueBreachEvent,
      listActivityLogs,
      exportFatigueBreachEventToPdf,
      exportToExcel,
      verify,
      clearVerifyFatigueBreachEvent,
    };
  });
