import { types, flow, getRoot } from 'mobx-state-tree';
import { IRootStoreModel } from 'src/domain/entities/RootStoreModel';
import { getAjax } from 'src/domain/services/storeEnvironment';
import { NotificationType } from 'src/domain';

export const AssetReportsModel = types.model('AssetPdfModel', {}).actions(self => {
  const ajax = getAjax(self);
  const root = getRoot<IRootStoreModel>(self);

  const generateAssetJobReport = flow(function*(
    query: Workshop.Domain.Queries.AssetJobReport.AssetJobReportQuery
  ) {
    root.notifications.addNotification(`The file is being generated ...`, {
      type: NotificationType.info,
    });
    return yield ajax.asset.generateAssetJobReport(query);
  });

  const generateAssetKmsReport = flow(function*(assetId: string, from: string, to: string) {
    root.notifications.addNotification(`The file is being generated ...`, {
      type: NotificationType.info,
    });
    return yield ajax.asset.generateAssetKmsReport(assetId, from, to);
  });

  const generateAssetReport = flow(function*(assetId: string) {
    root.notifications.addNotification(`The file is being generated ...`, {
      type: NotificationType.info,
    });
    return yield ajax.raw
      .getFile(`/api/workshop/assets/${assetId}/pdf`)
      .toPromise()
      .then<Blob>(r => r.response);
  });

  return {
    generateAssetJobReport,
    generateAssetReport,
    generateAssetKmsReport,
  };
});
