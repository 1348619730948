import styles from './CommentsTable.module.scss';

interface ICommentsTableProps {
  heightInCm: number;
}

export const CommentsTable: React.FC<ICommentsTableProps> = ({ heightInCm }) => (
  <table className={styles.root} style={{ height: `1cm` }}>
    <tbody>
      <tr>
        <td>Comments</td>
      </tr>
      <tr>
        <td className={styles.comments} style={{ height: `${heightInCm - 0.78}cm` }} />
      </tr>
    </tbody>
  </table>
);
