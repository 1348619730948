import { observer } from 'mobx-react';
import { DateTime } from 'luxon';
import { ChangeState, JobStatus } from 'src/api/enums';
import { useRootStore } from 'src/domain/entities/RootStoreModel';
import { CheckIcon, PlusIcon, RefreshIcon } from 'src/images/icons';
import { IFormApi } from 'src/views/components/Page/forms/base';
import CrudPage, { CrudPageMode, ICrudPageDef } from 'src/views/components/Page/pages/CrudPage';
import {
  ActionDefs,
  ActionType,
  FieldDefs,
  FieldType,
  IModalDefBuilderApi,
  PagePrimarySize,
  PaneType,
  ShellModalSize,
  ISectionDef,
  ITablePaneDef,
  IActionListPaneDef,
  IPaneData,
  IHasChangeState,
} from 'src/views/definitionBuilders/types';
import PriceTotals from 'src/views/routes/operations/rail/maintainRailBooking/PriceTotals';
import JobStatusBadge from './JobStatusBadge';
import ExtraDetails from 'src/views/components/operations/ExtraDetails/ExtraDetails';
import { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
type GetContractCharterBooking = Operations.Domain.Queries.ContractCharterBooking.GetContractCharterBooking.ContractCharterBooking;
type GetContractCharterBookingJob = Operations.Domain.Queries.ContractCharterBooking.GetContractCharterBooking.ContractCharterBooking.Job;
type GetContractCharterBookingExtra = Operations.Domain.Queries.ContractCharterBooking.GetContractCharterBooking.ContractCharterBooking.Extra;
type ExtraTypeItem = Operations.Domain.Queries.ViewExtraType.ExtraTypeItem;
type GetContractCharterBookingVehicle = Operations.Domain.Queries.ContractCharterBooking.GetContractCharterBooking.ContractCharterBooking.Vehicle;
type CreateContractCharterBookingCommand = Operations.Domain.Commands.ContractCharterBooking.CreateContractCharterBooking.CreateContractCharterBookingCommand;
type UpdateContractCharterBookingCommand = Operations.Domain.Commands.ContractCharterBooking.UpdateContractCharterBooking.UpdateContractCharterBookingCommand;
type ListJobItem = Operations.Domain.Queries.ListJobs.ListJobItem;
type ShiftSelectListItem = Operations.Domain.Queries.GetShiftsByCharterContract.ShiftSelectListItem;
interface IMaintainContractCharterBookingProps {
  mode: CrudPageMode;
  route: RouteComponentProps<{ [x: string]: string | undefined }>;
}

const MaintainContractCharterBooking: React.FC<IMaintainContractCharterBookingProps> = observer(
  (props: IMaintainContractCharterBookingProps) => {
    const rootStore = useRootStore();
    const contractCharterBookings = rootStore.operations.contractCharterBookings;
    const charterContractsByCustomer = rootStore.operations.charterContracts.charterContractsByCustomer.slice();
    const shiftsModel = rootStore.operations.urban.shifts;
    const extraTypes = rootStore.operations.extraTypes.list.items.slice();
    const [shifts, setShifts] = useState<ShiftSelectListItem[] | undefined>(undefined);
    const contractCharterBookingId = props.route.match.params.id!;

    const vehicleSubtotal = (data: IPaneData) =>
      (data.parentValue?.vehicles as GetContractCharterBookingVehicle[])
        ?.map(v => Number(v?.totalPrice ?? 0))
        ?.reduce((agg, val) => agg + val, 0) ?? 0;

    const extraSubtotal = (data: IPaneData) =>
      (data.parentValue?.extras as GetContractCharterBookingExtra[])
        ?.map(v => Number(v?.totalPrice ?? 0))
        ?.reduce((agg, val) => agg + val, 0) ?? 0;

    const isComplete =
      props.mode !== 'create' &&
      (contractCharterBookings.contractCharterBooking?.isComplete ?? false);

    const isUpdateMode = props.mode === 'update';

    useEffect(() => {
      if (isUpdateMode && contractCharterBookings.contractCharterBooking?.contract.id) {
        contractCharterBookings.getAvailableJobs(
          contractCharterBookings.contractCharterBooking.contract.id
        );

        shiftsModel
          .getShiftsByContract(contractCharterBookings.contractCharterBooking.contract.id)
          .then(r => setShifts(r));
      }
    }, [contractCharterBookings.contractCharterBooking?.contract.id]);

    useEffect(() => {
      if (contractCharterBookings.contractCharterBooking?.customer.customerId) {
        rootStore.operations.charterContracts.getCharterContractsByCustomer(
          contractCharterBookings.contractCharterBooking?.customer.customerId
        );
      }
    }, [contractCharterBookings.contractCharterBooking?.customer.customerId]);

    useEffect(() => {
      if (contractCharterBookingId) {
        contractCharterBookings.load(contractCharterBookingId);
      }
    }, [contractCharterBookingId]);

    useEffect(() => {
      rootStore.operations.extraTypes.list.loadExtraTypes();
      rootStore.operations.vehicleTypes.loadVehicleTypes();
    }, []);

    const formSubmit = async (values: any, mode: CrudPageMode, view: boolean) => {
      if (view || mode === 'view') return;

      switch (mode) {
        case 'create':
          return await contractCharterBookings.create({
            contractId: values.contract.id,
            customerId: values.customer.customerId,
            description: values.description,
            invoiceNumber: values.invoiceNumber,
            purchaseOrderNumber: values.purchaseOrderNumber,
          } as CreateContractCharterBookingCommand);
        case 'update':
          await contractCharterBookings.update({
            contractCharterBookingId: values.contractCharterBookingId,
            description: values.description,
            invoiceNumber: values.invoiceNumber,
            purchaseOrderNumber: values.purchaseOrderNumber,
            jobIds: values.jobs
              .filter((job: IHasChangeState) => job.changeState !== ChangeState.Deleted)
              .map((job: { job: { jobId: number } }) => job.job.jobId),
            extras: values.extras
              .filter((extra: IHasChangeState) => extra.changeState !== ChangeState.Deleted)
              .map(
                (extra: {
                  extraTypeId: string;
                  quantity: number;
                  totalHours: number;
                  totalPrice: number;
                }) =>
                  ({
                    extraTypeId: extra.extraTypeId,
                    quantity: extra.quantity,
                    totalHours: extra.totalHours,
                    totalPrice: extra.totalPrice,
                  } as Operations.Domain.Commands.ContractCharterBooking.UpdateContractCharterBooking.UpdateContractCharterBookingCommand.Extra)
              ),
            vehicles: values.vehicles
              .filter((vehicle: IHasChangeState) => vehicle.changeState !== ChangeState.Deleted)
              .map(
                (vehicle: {
                  vehicleTypeId: string;
                  quantity: number;
                  totalHours: number;
                  totalPrice: number;
                }) =>
                  ({
                    vehicleTypeId: vehicle.vehicleTypeId,
                    quantity: vehicle.quantity,
                    totalHours: vehicle.totalHours,
                    totalPrice: vehicle.totalPrice,
                  } as Operations.Domain.Commands.ContractCharterBooking.UpdateContractCharterBooking.UpdateContractCharterBookingCommand.Vehicle)
              ),
          } as UpdateContractCharterBookingCommand);
          if (values.contract.charterContractId) {
            await contractCharterBookings.getAvailableJobs(values.contract.charterContractId);
          }
          break;
      }
    };

    const actionDefs = {
      completeBooking: (readonly: boolean) =>
        ({
          actionType: ActionType.modalActionButton,
          label: 'Complete Booking',
          icon: <CheckIcon fixedWidth />,
          modalSize: ShellModalSize.oneQuarter,
          modalDef: () => ({
            title: 'Complete Contract Booking',
            asForm: true,
            panels: [
              {
                panes: [
                  {
                    paneType: PaneType.customPane,
                    render: () => <p>Are you sure you want to complete this Contract Booking?</p>,
                  },
                ],
              },
            ],
            secondaryActions: [
              {
                actions: [
                  {
                    actionType: ActionType.submitActionButton,
                    label: 'Ok',
                    level: 'primary',
                  },
                  {
                    actionType: ActionType.closeModalActionButton,
                    label: 'Cancel',
                  },
                ],
              },
            ],
            onFormSubmit: async () =>
              contractCharterBookingId &&
              (await contractCharterBookings.complete(contractCharterBookingId)),
          }),
          hidden: (props.mode !== 'view' && !readonly) || isComplete,
        } as ActionDefs),
    };

    const fieldDefs = {
      customer: {
        fieldType: FieldType.selectAsyncField,
        dataAddr: 'customer.customerId',
        label: 'Customer',
        valueKey: 'customerId',
        descriptionKey: 'customerName',
        useValueOnly: true,
        readonly: props.mode !== 'create',
        mandatory: true,
        linkTo: d => `/sales/customers/${d.parentValue.customer.customerId}`,
        loadOptionItems: rootStore.operations.sales.customer.searchCustomers,
        loadItems: rootStore.operations.sales.customer.findCustomers,
        onChange: async data => {
          if (data.newFieldValue) {
            await rootStore.operations.charterContracts.getCharterContractsByCustomer(
              data.newFieldValue
            );

            if (data.getFormValue(['contract', 'id'])) {
              data.setFormValue(['contract', 'id'], undefined);
            }
          }
        },
      } as FieldDefs,
      contract: (mode: CrudPageMode, readonly: boolean): FieldDefs => ({
        fieldType: FieldType.selectField,
        dataAddr: ['contract', 'id'],
        label: 'Contract',
        linkTo: d => `/operations/charter-contracts/${d.parentValue.contract.id}`,
        valueKey: 'id',
        descriptionKey: 'name',
        mandatory: true,
        useValueOnly: true,
        optionItems: charterContractsByCustomer,
        formatReadonly: d => d.parentValue.contract?.name,
        readonly: df =>
          readonly || mode === 'view' || mode === 'update' || !df?.paneValue?.customer?.customerId,
      }),
      description: {
        fieldType: FieldType.textField,
        label: 'Description',
        dataAddr: 'description',
        maxLength: 200,
        mandatory: true,
      } as FieldDefs,
      purchaseOrderNumber: {
        fieldType: FieldType.textField,
        label: 'Purchase Order',
        dataAddr: 'purchaseOrderNumber',
        maxLength: 200,
      } as FieldDefs,
      invoiceNumber: {
        fieldType: FieldType.textField,
        label: 'Invoice Number',
        dataAddr: 'invoiceNumber',
        maxLength: 200,
        mandatory: isComplete,
      } as FieldDefs,
    };

    const panes = {
      jobs: (readonly: boolean) =>
        ({
          paneType: PaneType.tablePane,
          dataRequiredForRows: 'paneValue',
          title: d => `Linked Jobs (${d.panelValue.jobs?.length ?? 0} linked)`,
          validate: f => {
            if (
              f.parentValue.isComplete &&
              (f.paneValue ?? []).length &&
              !(f.paneValue ?? [])
                .map((j: { job: ListJobItem | GetContractCharterBookingJob }) => j.job)
                .every(
                  (job: ListJobItem | GetContractCharterBookingJob) =>
                    (job?.hasSubcontractor ?? false) || job?.jobStatus?.id === JobStatus.Completed
                )
            ) {
              return 'Completed bookings may only have jobs which either have a sub contractor or are completed';
            }
            return undefined;
          },
          fields: [
            {
              fieldType: FieldType.selectField,
              label: 'Job',
              dataAddr: 'job',
              valueKey: 'jobId',
              descriptionKey: 'jobNumber',
              mandatory: true,
              columnWidth: '20rem',
              optionItems: contractCharterBookings.availableJobs?.filter(
                job => !job.contractCharterBookingId
              ),
              optionRenderer: (d: ListJobItem) => (
                <span>
                  {d.jobNumber} - {d.shiftName}
                </span>
              ),
              readonly: f => f.fieldValue?.jobId,
              valuesToExclude: f => [
                ...(f.paneValue ?? []).map((v: { job: { jobId: string } }) => v?.job?.jobId),
                ...(contractCharterBookings.contractCharterBooking?.isComplete
                  ? contractCharterBookings.availableJobs
                      .filter(
                        job => !job.hasSubcontractor && job.jobStatus.id !== JobStatus.Completed
                      )
                      .map(job => job.jobId)
                  : []),
              ],
              linkTo: d => `/operations/jobs/${d.parentValue?.job?.jobId}`,
            },
            {
              fieldType: FieldType.textField,
              dataAddr: ['job', 'shiftName'],
              label: 'Shift Name',
              readonly: true,
            },
            {
              fieldType: FieldType.textField,
              dataAddr: ['job', 'staffMember', 'name'],
              label: 'Staff Member',
              readonly: true,
              linkTo: d => `/people/staff-members/${d.parentValue?.job?.staffMember?.id}`,
            },
            {
              fieldType: FieldType.textField,
              dataAddr: ['job', 'asset', 'name'],
              label: 'Vehicle',
              readonly: true,
              linkTo: d => `/workshop/assets/${d.parentValue?.job?.asset?.id}`,
            },
            {
              fieldType: FieldType.customField,
              label: 'Facilities',
              dataAddr: ['job', 'extras'],
              render: d => {
                const extras =
                  d.data.fieldValue?.filter(
                    (extra: { extraType: ExtraTypeItem }) => extra?.extraType?.showInAccounting
                  ) ?? [];
                const id = d.data.parentValue.id;
                return <ExtraDetails extras={extras} id={id} />;
              },
            },
            {
              fieldType: FieldType.durationField,
              dataAddr: ['job', 'jobDuration'],
              label: 'Total Worked Hours',
              readonly: true,
            },
            {
              fieldType: FieldType.readonlyField,
              label: 'Kms Travelled',
              dataAddr: ['job', 'kmsTravelled'],
            },
            {
              fieldType: FieldType.customField,
              dataAddr: ['job'],
              label: 'Job Status',
              columnWidth: '1px',
              render: d =>
                d.data.parentValue.hasSubcontract ? null : (
                  <JobStatusBadge jobStatus={d.data.parentValue?.job?.jobStatus} />
                ),
            },
            {
              fieldType: FieldType.actionListField,
              columnWidth: '1px',
              actionGroups: [
                {
                  actions: [
                    {
                      hidden: () => props.mode !== 'update' || readonly,
                      actionType: ActionType.removeArrayItemActionButton,
                      label: 'Remove Line',
                    },
                  ],
                },
              ],
            },
          ],
        } as ITablePaneDef),
      jobActions: (readonly: boolean) =>
        ({
          paneType: PaneType.actionListPane,
          hidden: () => props.mode !== 'update' || readonly,
          actionGroups: [
            {
              actions: [
                {
                  actionType: ActionType.addArrayItemActionButton,
                  label: 'Add job',
                  disabled: readonly,
                },
                {
                  actionType: ActionType.modalActionButton,
                  label: 'Add multiple jobs',
                  level: 'primary',
                  icon: <PlusIcon />,
                  disabled: readonly,
                  modalSize: ShellModalSize.oneThird,
                  modalDef: linkMultipleModalDef,
                },
              ],
            },
          ],
        } as IActionListPaneDef),
      vehicles: (readonly: boolean) =>
        ({
          paneType: PaneType.tablePane,
          dataRequiredForRows: 'paneValue',
          title: 'Vehicles',
          fields: [
            {
              fieldType: FieldType.selectField,
              dataAddr: 'vehicleTypeId',
              label: 'Vehicle Type',
              valueKey: 'id',
              descriptionKey: 'description',
              useValueOnly: true,
              columnWidth: '20rem',
              mandatory: true,
              dataRequiredForRows: 'paneValue',
              optionItems: rootStore.operations.vehicleTypes.vehicleTypes,
              valuesToDisable: p =>
                p.paneValue?.map((v: { vehicleTypeId: string }) => v.vehicleTypeId) ?? [],
            },
            {
              fieldType: FieldType.numericField,
              dataAddr: 'quantity',
              label: 'Quantity',
              numericConfig: { numericType: 'unsignedInt' },
            },
            {
              fieldType: FieldType.numericField,
              dataAddr: 'totalHours',
              label: 'Total Hours',
              numericConfig: { numericType: 'unsignedDecimal', maxPointDigits: 2 },
            },
            {
              fieldType: FieldType.numericField,
              dataAddr: 'totalPrice',
              label: 'Total Price',
              numericConfig: { numericType: 'unsignedDecimal', maxPointDigits: 2 },
            },
            {
              fieldType: FieldType.actionListField,
              columnWidth: '1px',
              actionGroups: [
                {
                  actions: [
                    {
                      hidden: () => props.mode !== 'update' || readonly,
                      actionType: ActionType.removeArrayItemActionButton,
                      label: 'Remove Line',
                    },
                  ],
                },
              ],
            },
          ],
        } as ITablePaneDef),
      vehicleActions: (readonly: boolean) =>
        ({
          paneType: PaneType.actionListPane,
          hidden: () => props.mode !== 'update' || readonly,
          actionGroups: [
            {
              actions: [
                {
                  actionType: ActionType.addArrayItemActionButton,
                  label: 'Add Vehicle Type',
                  disabled: readonly,
                },
              ],
            },
          ],
        } as IActionListPaneDef),
      extras: (readonly: boolean) =>
        ({
          paneType: PaneType.tablePane,
          title: 'Extras',
          dataRequiredForRows: 'paneValue',
          fields: [
            {
              fieldType: FieldType.selectField,
              label: 'Extra Type',
              dataAddr: ['extraTypeId'],
              valueKey: 'id',
              descriptionKey: 'description',
              useValueOnly: true,
              columnWidth: '20rem',
              mandatory: true,
              optionItems: extraTypes,
              valueRenderer: v => {
                const extraType = extraTypes.find(e => e.id === v.id);
                return extraType?.description;
              },
              valuesToDisable: p =>
                p.paneValue?.map((v: { extraTypeId: string }) => v.extraTypeId) ?? [],
              valuesToExclude: extraTypes.filter(t => !t.isActive).map(t => t.id),
            },
            {
              fieldType: FieldType.numericField,
              dataAddr: 'quantity',
              label: 'Quantity',
              numericConfig: { numericType: 'unsignedInt' },
            },
            {
              fieldType: FieldType.numericField,
              dataAddr: 'totalHours',
              label: 'Total Hours',
              numericConfig: { numericType: 'unsignedDecimal', maxPointDigits: 2 },
            },
            {
              fieldType: FieldType.numericField,
              dataAddr: 'totalPrice',
              label: 'Total Price',
              numericConfig: { numericType: 'unsignedDecimal', maxPointDigits: 2 },
            },
            {
              fieldType: FieldType.actionListField,
              columnWidth: '1px',
              actionGroups: [
                {
                  actions: [
                    {
                      hidden: () => props.mode !== 'update' || readonly,
                      actionType: ActionType.removeArrayItemActionButton,
                      label: 'Remove Line',
                    },
                  ],
                },
              ],
            },
          ],
        } as ITablePaneDef),
      extraActions: (readonly: boolean) =>
        ({
          paneType: PaneType.actionListPane,
          hidden: () => props.mode !== 'update' || readonly,
          actionGroups: [
            {
              actions: [
                {
                  actionType: ActionType.addArrayItemActionButton,
                  label: 'Add Extra',
                  disabled: readonly,
                },
                {
                  actionType: ActionType.modalActionButton,
                  label: 'Import Extras From Jobs',
                  disabled: readonly,
                  level: 'primary',
                  hidden: d => !d.parentValue.jobs.length || d.parentValue.extras.length,
                  icon: <PlusIcon />,
                  modalSize: ShellModalSize.oneThird,
                  modalDef: importExtrasFromJobsModalDef,
                },
                {
                  actionType: ActionType.modalActionButton,
                  label: 'Refresh',
                  disabled: readonly,
                  level: 'primary',
                  hidden: d => !d.parentValue.jobs.length || !d.parentValue.extras.length,
                  icon: <RefreshIcon />,
                  modalSize: ShellModalSize.oneThird,
                  modalDef: refreshExtrasFromJobsModalDef,
                },
              ],
            },
          ],
        } as IActionListPaneDef),
    };

    const addManyJobs = async (
      formApi: IFormApi,
      start?: DateTime,
      end?: DateTime,
      shifts?: ShiftSelectListItem[]
    ) => {
      const jobs = formApi.getValue(['jobs']) as {
        job: GetContractCharterBookingJob & { id: string };
      }[];

      const added = { changeState: ChangeState.Added } as IHasChangeState;
      const addJobs =
        contractCharterBookings.availableJobs
          ?.filter(item => !item.contractCharterBookingId)
          .filter(
            item =>
              !(
                jobs.filter(job => job?.job?.jobId).map(job => job.job.jobId ?? job.job.id) ?? []
              ).some(id => item.jobId === id)
          )
          .filter(
            item =>
              (!start || !item.clockOn || DateTime.fromISO(item.clockOn) >= start) &&
              (!end || !item.clockOff || DateTime.fromISO(item.clockOff) <= end)
          )
          .map(item => ({
            job: { ...item },
            ...added,
          })) ?? [];

      const addByShiftName = addJobs.filter(item =>
        shifts?.some(shift => item.job.shiftName === shift.name)
      );
      const jobsToAdd = !shifts?.length ? addJobs : addByShiftName;

      formApi.setValue(['jobs'], [...jobs.filter(job => job?.job?.jobId), ...jobsToAdd]);
    };

    const linkMultipleModalDef = (api: IModalDefBuilderApi): ISectionDef => {
      return {
        title: 'Add Multiple Jobs',
        asForm: true,
        panels: [
          {
            panes: [
              {
                paneType: PaneType.formFieldsPane,
                columnCount: 1,
                fields: [
                  {
                    fieldType: FieldType.dateField,
                    label: 'Start Date',
                    dataAddr: 'startDate',
                    mandatory: true,
                  },
                  {
                    fieldType: FieldType.dateField,
                    label: 'End Date',
                    dataAddr: 'endDate',
                    mandatory: true,
                    validate: f =>
                      !f.fieldValue ||
                      !f.parentValue.startDate ||
                      DateTime.fromISO(f.fieldValue) >= DateTime.fromISO(f.parentValue.startDate)
                        ? undefined
                        : 'End Date cannot be before Start Date',
                  },
                  {
                    fieldType: FieldType.selectMultiField,
                    label: 'Shift Name',
                    dataAddr: 'shifts',
                    optionItems: shifts,
                    valueKey: 'id',
                    descriptionKey: 'name',
                  },
                ],
              },
            ],
          },
        ],
        secondaryActions: [
          {
            actions: [
              {
                actionType: ActionType.submitActionButton,
                label: 'Ok',
                level: 'primary',
              },
              {
                actionType: ActionType.closeModalActionButton,
                label: 'Cancel',
              },
            ],
          },
        ],
        onFormSubmit: d =>
          addManyJobs(
            api.parentFormApi,
            d.startDate ? DateTime.fromISO(d.startDate) : undefined,
            d.endDate ? DateTime.fromISO(d.endDate).plus({ days: 1 }) : undefined,
            d.shifts
          ),
      } as ISectionDef;
    };

    const addExtrasToBooking = (api: IFormApi) => {
      // Using type any as it can't register job in jobs as it's an object
      const jobs = api.values.jobs.map((job: any) => job.job);
      const jobsValues: GetContractCharterBookingJob[] = Object.values(jobs);
      const jobExtras = jobsValues
        .flatMap(job => job.extras)
        .filter(extra => extra.extraType.showInAccounting === true);

      const extrasToAdd = jobExtras.map(extra => ({
        extraTypeId: extra.extraType.id,
        description: extra.extraType.description,
        totalPrice: !!extra.overridePrice
          ? extra.overridePrice
          : !!extra.unitPrice
          ? extra.unitPrice * extra.quantity
          : extra.price * extra.quantity,
        totalHours: 0,
        quantity: extra.quantity,
      })) as GetContractCharterBookingExtra[];

      const combinedExtras = Array.from(
        extrasToAdd
          .reduce((acc, curr) => {
            const val =
              acc.get(curr.extraTypeId) ??
              ({
                extraTypeId: curr.extraTypeId,
                description: curr.description,
                totalPrice: 0,
                totalHours: 0,
                quantity: 0,
              } as GetContractCharterBookingExtra);

            acc.set(curr.extraTypeId, {
              ...val,
              totalPrice:
                curr.totalPrice || val.totalPrice
                  ? (val.totalPrice ?? 0) + (curr.totalPrice ?? 0)
                  : undefined,
              quantity:
                curr.quantity || val.quantity
                  ? (val.quantity ?? 0) + (curr.quantity ?? 0)
                  : undefined,
            });

            return acc;
          }, new Map<string, GetContractCharterBookingExtra>())
          .values()
      ).map(value => ({
        ...value,
        totalPrice: value.totalPrice?.toFixed(2),
      }));

      api.setValue(['extras'], [...combinedExtras]);
      return Promise.resolve();
    };

    const importExtrasFromJobsModalDef = (api: IModalDefBuilderApi): ISectionDef => {
      return {
        title: 'Import Extras',
        asForm: true,
        panels: [
          {
            panes: [
              {
                paneType: PaneType.customPane,
                render: () => (
                  <p>
                    Extras marked as "For Accounting" will be imported from the jobs, into the
                    booking
                  </p>
                ),
              },
            ],
          },
        ],
        secondaryActions: [
          {
            actions: [
              {
                actionType: ActionType.submitActionButton,
                label: 'Ok',
                level: 'primary',
              },
              {
                actionType: ActionType.closeModalActionButton,
                label: 'Cancel',
              },
            ],
          },
        ],
        onFormSubmit: () => addExtrasToBooking(api.parentFormApi),
      } as ISectionDef;
    };

    const refreshExtrasFromJobsModalDef = (api: IModalDefBuilderApi): ISectionDef => {
      return {
        title: 'Refresh Extras',
        asForm: true,
        panels: [
          {
            panes: [
              {
                paneType: PaneType.customPane,
                render: () => (
                  <p>
                    This will remove all extras currently attached to this booking. Extras marked as
                    "For Accounting" will be imported from the jobs, into the booking
                  </p>
                ),
              },
            ],
          },
        ],
        secondaryActions: [
          {
            actions: [
              {
                actionType: ActionType.submitActionButton,
                label: 'Ok',
                level: 'primary',
              },
              {
                actionType: ActionType.closeModalActionButton,
                label: 'Cancel',
              },
            ],
          },
        ],
        onFormSubmit: () => addExtrasToBooking(api.parentFormApi),
      } as ISectionDef;
    };

    const getPageDef = (readonly: boolean): ICrudPageDef => ({
      primarySize: PagePrimarySize.full,
      primarySection: {
        badge: isComplete
          ? {
              label: 'Completed',
            }
          : undefined,
        title: d =>
          props.mode === 'create'
            ? 'Create a Contract Booking'
            : `Contract Booking ${d.sectionValue.bookingNumber}`,
        primaryActions: [
          {
            actions: [
              {
                actionType: ActionType.actionCollection,
                actionGroups: [
                  {
                    actions: [actionDefs.completeBooking(readonly)],
                  },
                ],
              },
            ],
          },
        ],
        panels: [
          {
            panes: [
              {
                paneType: PaneType.formFieldsPane,
                columnCount: 2,
                fields: [fieldDefs.customer, fieldDefs.contract(props.mode, readonly)],
              },
              {
                paneType: PaneType.formFieldsPane,
                fields: [fieldDefs.description],
              },
              {
                paneType: PaneType.formFieldsPane,
                columnCount: 2,
                fields: [fieldDefs.purchaseOrderNumber, fieldDefs.invoiceNumber],
              },
              {
                paneType: PaneType.nestingPane,
                hidden: props.mode === 'create',
                dataAddr: 'jobs',
                panes: [panes.jobs(readonly), panes.jobActions(readonly)],
              },
              {
                paneType: PaneType.nestingPane,
                hidden: props.mode === 'create',
                dataAddr: 'vehicles',
                panes: [panes.vehicles(readonly), panes.vehicleActions(readonly)],
              },
              {
                paneType: PaneType.customPane,
                hidden: props.mode === 'create',
                render: api => <PriceTotals subtotal={vehicleSubtotal(api.data)} />,
              },
              {
                paneType: PaneType.nestingPane,
                hidden: props.mode === 'create',
                dataAddr: 'extras',
                panes: [panes.extras(readonly), panes.extraActions(readonly)],
              },
              {
                paneType: PaneType.customPane,
                hidden: props.mode === 'create',
                render: api => <PriceTotals subtotal={extraSubtotal(api.data)} />,
              },
            ],
          },
        ],
        onFormSubmit: df => formSubmit(df, props.mode, readonly),
      },
      secondarySections: [],
    });

    const mapData = (booking?: GetContractCharterBooking) => {
      if (!booking) {
        return {};
      }

      return {
        ...booking,
        contract: {
          ...booking.contract,
          id: booking.contract.id,
        },
        jobs: booking.jobs.map(j => ({
          job: j,
        })),
      };
    };

    return (
      <CrudPage
        key={contractCharterBookingId}
        def={api => getPageDef(api.readonly)}
        mode={props.mode}
        data={mapData(contractCharterBookings.contractCharterBooking)}
        createDefaultData={{}}
      />
    );
  }
);

export default MaintainContractCharterBooking;
