import { UiRouteName } from 'src/domain/baseTypes';
import { IRootStoreModel } from 'src/domain/entities/RootStoreModel';
import UiRoute from 'src/domain/services/buildUiRoutes/UiRoute';

export default function buildUiRoutes(store: IRootStoreModel) {
  const attachmentCreate = new UiRoute(
    UiRouteName.workshopAttachmentCreate,
    '/workshop/asset-attachments/create',
    () => 'Create Attachment',
    false
  );

  const attachmentEdit = new UiRoute(
    UiRouteName.workshopAttachmentEdit,
    '/workshop/asset-attachments/:id',
    () => 'Manage Attachment',
    false
  );

  const attachmentsList = new UiRoute(
    UiRouteName.workshopAttachmentsList,
    '/workshop/asset-attachments',
    () => 'Asset Attachments',
    false,
    false,
    [attachmentCreate, attachmentEdit]
  );

  return {
    [UiRouteName.workshopAttachmentsList]: attachmentsList,
    [UiRouteName.workshopAttachmentCreate]: attachmentCreate,
    [UiRouteName.workshopAttachmentEdit]: attachmentEdit,
  };
}
