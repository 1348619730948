import styles from './AssetDetails.module.scss';
import { FormattedDateTime } from 'src/views/kioskRoutes/components/presentational/FormattedDateTime/FormattedDateTime';
import { DateTime } from 'luxon';
import Button from 'reactstrap/lib/Button';
import { ChevronLeftIcon, ChevronRightIcon } from 'src/images/icons';
import { useState } from 'react';

type NextOpsJobForAsset = Common.Queries.Workshop.GetNextOpsJobForAsset.NextOpsJob;
type AssetDetailsData = Workshop.Domain.Queries.GetAsset.AssetDetails;

const NextJobCard: React.FC<{ nextJobs: NextOpsJobForAsset[] | undefined }> = ({ nextJobs }) => {
  const [displayedJob, setDisplayedJob] = useState(0);

  const jobsCount = nextJobs ? nextJobs.length : 0;

  const onPrevious = () => {
    if (displayedJob > 0) {
      setDisplayedJob(displayedJob - 1);
    }
  };
  const onNext = () => {
    if (displayedJob < jobsCount - 1) {
      setDisplayedJob(displayedJob + 1);
    }
  };

  const previousDisabled = displayedJob === 0;
  const nextDisabled = jobsCount === 0 || displayedJob === jobsCount - 1;
  const job = nextJobs && nextJobs[displayedJob];

  return job ? (
    <div className={styles.nextJobContainer}>
      <Button
        title="Previous"
        outline
        onClick={onPrevious}
        className={styles.previousButton}
        disabled={previousDisabled}>
        <ChevronLeftIcon />
      </Button>
      <div className={styles.nextJob}>
        <span>Next Ops Job: </span>
        <strong>{job.jobType.description}</strong>
        <span> at </span>
        <FormattedDateTime
          dateTime={job.clockOn}
          className={styles.nextJobTime}
          referenceDateTime={DateTime.local()}
        />{' '}
        -{' '}
        <FormattedDateTime
          dateTime={job.clockOff}
          className={styles.nextJobTime}
          referenceDateTime={job.clockOn}
        />
      </div>
      <Button
        title="Next"
        outline
        onClick={onNext}
        className={styles.nextButton}
        disabled={nextDisabled}>
        <ChevronRightIcon />
      </Button>
    </div>
  ) : null;
};

export interface IAssetInfoBannerProps {
  asset?: AssetDetailsData;
  nextOpsJobsForAsset: NextOpsJobForAsset[] | undefined;
}

export const AssetInfoBanner: React.FC<IAssetInfoBannerProps> = ({ asset, nextOpsJobsForAsset }) =>
  asset ? (
    <div className={styles.assetArea}>
      <div>
        {asset.name && (
          <>
            <b>Name</b>
            {asset.name}
          </>
        )}
      </div>

      <div>
        {asset.registrationNumber && (
          <>
            <b>Rego</b>
            {asset.registrationNumber}
          </>
        )}
      </div>

      <div className={styles.hide}>
        {asset.engineModel && (
          <>
            <b>Engine Model</b>
            {asset.engineModel.model}
          </>
        )}
      </div>

      <div>
        <b>Garaged At</b>
        {asset.housingLocation.description}
      </div>
      <NextJobCard nextJobs={nextOpsJobsForAsset} />
    </div>
  ) : (
    <div />
  );
