import inject from 'src/views/injectFromStore';
import Omit from 'src/infrastructure/omit';
import MaintainVehicleType, { IMaintainVehicleTypeProps } from './maintainVehicleType';

const Container = inject<Omit<IMaintainVehicleTypeProps, 'mode'>, IMaintainVehicleTypeProps>(
  (allStores, p) => ({
    canManageVehicleTypes: allStores.rootStore.account.isSalesDepartmentMember,
    vehicleType: Object.assign({}, allStores.rootStore.operations.vehicleType.vehicleType),
    loadVehicleType: allStores.rootStore.operations.vehicleType.loadVehicleType,
    updateVehicleType: allStores.rootStore.operations.vehicleType.updateVehicleType,
    createVehicleType: allStores.rootStore.operations.vehicleType.createVehicleType,
    checkForUniqueVehicleTypeDescription:
      allStores.rootStore.operations.vehicleType.checkForUniqueVehicleTypeDescription,
  })
)(MaintainVehicleType);

export default Container;
