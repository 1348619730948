import { UiRouteName } from 'src/domain/baseTypes';
import { IRootStoreModel } from 'src/domain/entities/RootStoreModel';
import UiRoute from 'src/domain/services/buildUiRoutes/UiRoute';

export default function buildUiRoutes(store: IRootStoreModel) {
  const maintainSkillSpecs = new UiRoute(
    UiRouteName.peopleSkillSpecsEdit,
    '/people/skill-specs',
    () => 'Skill Specs',
    false
  );

  return {
    [UiRouteName.peopleSkillSpecsEdit]: maintainSkillSpecs,
  };
}
