import { IRootStoreModel } from 'src/domain/entities/RootStoreModel';
import { UiRoute } from 'src/domain/services/buildUiRoutes';
import { UiRouteName } from 'src/domain/baseTypes';

export default function buildUiRoutes(store: IRootStoreModel) {
  const extraTypeCreate = new UiRoute(
    UiRouteName.salesExtraTypeCreate,
    '/sales/extra-types/create',
    () => 'Create Extra',
    true
  );

  const extraTypeUpdate = new UiRoute(
    UiRouteName.salesExtraTypeUpdate,
    '/sales/extra-types/:id',
    () => 'Manage Extra',
    true
  );

  const extraTypesList = new UiRoute(
    UiRouteName.salesExtraTypeList,
    '/sales/extra-types',
    () => 'Extras',
    true,
    false,
    [extraTypeCreate, extraTypeUpdate]
  );

  return {
    [UiRouteName.salesExtraTypeList]: extraTypesList,
    [UiRouteName.salesExtraTypeCreate]: extraTypeCreate,
    [UiRouteName.salesExtraTypeUpdate]: extraTypeUpdate,
  };
}
