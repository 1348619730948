import './MaintainPurchaseOrder.scss';
import { Component } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { saveAs } from 'file-saver';
import { DateTime } from 'luxon';
import {
  ChangeState,
  PurchaseOrderStatus,
  JobTaskStatus,
  allInvoiceType,
  InvoiceType,
} from 'src/api/enums';
import {
  CheckIcon,
  FilePdfIcon,
  BanIcon,
  MultipleBoxesIcon,
  UndoIcon,
  FileIcon,
} from 'src/images/icons';
import deepEqual from 'src/infrastructure/deepEqual';
import { IAutocompleteResult } from 'src/domain/baseTypes';
import { IMatchingPart } from 'src/domain/entities/workshop/parts/PartsModel';
import {
  PagePrimarySize,
  PaneType,
  FieldType,
  ActionType,
  ShellModalSize,
} from 'src/views/definitionBuilders/types';
import CrudPage, { CrudPageMode, ICrudPageDef } from 'src/views/components/Page/pages/CrudPage';
import { sortJobTaskListItemsForPurchaseOrders } from 'src/views/components/workshop/tasks/sortJobTaskListItemsForPurchaseOrders';
import { getSubmitCloseModalActionGroupDef } from 'src/views/definitionBuilders/common';

type PurchaseOrderItem = Workshop.Domain.Queries.Purchasing.PurchaseOrderItem;
type PurchaseOrderLineItem = Workshop.Domain.Queries.Purchasing.PurchaseOrderLineItem;
type CreatePurchaseOrderCommand = Workshop.Domain.Commands.Purchasing.CreatePurchaseOrderCommand;
type UpdatePurchaseOrderCommand = Workshop.Domain.Commands.Purchasing.UpdatePurchaseOrderCommand;
type SupplierListItem = Workshop.Domain.Queries.Suppliers.SupplierListItem;
type AssetHousingLocation = Workshop.Domain.AggregatesModel.AssetAggregate.AssetHousingLocation;
type PartListItem = Workshop.Domain.Queries.Parts.PartListItem;
type ContactItem = Workshop.Domain.Queries.Suppliers.GetContactsForSupplier.ContactItem;
type AssetItem = Workshop.Domain.Queries.AssetItem;
type JobTaskListItem = Workshop.Domain.Queries.JobTask.JobTaskListItem;
type AggregatePurchaseOrderLineItem = Workshop.Domain.Queries.Purchasing.AggregatePurchaseOrderLineItem;
type ActivityLogTransaction = Workshop.Domain.Queries.ActivityLog.ActivityLogTransaction;
type ActivityLogDetails = Workshop.Domain.Queries.ActivityLog.ActivityLogDetails;

const emptyLine = { changeState: ChangeState.Added };
const emptyPurchaseOrder = { purchaseOrderLines: [emptyLine] };

export interface IMaintainPurchaseOrderProps {
  mode: CrudPageMode;
  canManagePurchaseOrders: boolean;
  purchaseOrder: PurchaseOrderItem | undefined;
  deliveryLocations: Array<AssetHousingLocation>;
  contactsForSupplier: Array<ContactItem>;
  assets: AssetItem[];
  activityLogs: ActivityLogTransaction[];
  onLoadPurchaseOrder: (id: string) => Promise<void>;
  onCreatePurchaseOrder: (command: CreatePurchaseOrderCommand) => Promise<void>;
  onUpdatePurchaseOrder: (command: UpdatePurchaseOrderCommand) => Promise<void>;
  onApprovePurchaseOrder: (id: string) => Promise<void>;
  onreinstatePurchaseOrder: (id: string) => Promise<void>;
  onCancelPurchaseOrder: (id: string) => Promise<void>;
  onCompletePurchaseOrderLine: (
    purchaseOrderId: string,
    purchaseOrderLineId: number
  ) => Promise<void>;
  onCompletePurchaseOrder: (id: string) => Promise<void>;
  onGeneratePdf: (purchaseOrderId: string) => Promise<Blob>;
  searchSuppliers: (search: string) => Promise<IAutocompleteResult<SupplierListItem>>;
  searchParts: (search: string) => Promise<IAutocompleteResult<IMatchingPart>>;
  loadDeliveryLocations: () => Promise<void>;
  listContactsForSupplier: (supplierId: string) => Promise<void>;
  loadAssetListItems: () => Promise<void>;
  getJobTasksForAsset: (assetId: string) => Promise<JobTaskListItem[]>;
  clearActivityLog: () => void;
}

interface IMaintainPurchaseOrderState {
  jobTasksForAssetMap: { [key: string]: JobTaskListItem[] };
}

interface IUpdatePurchaseOrderRouteParams {
  id: string;
}

type InternalProps = IMaintainPurchaseOrderProps &
  RouteComponentProps<IUpdatePurchaseOrderRouteParams>;

class MaintainPurchaseOrder extends Component<InternalProps, IMaintainPurchaseOrderState> {
  constructor(props: InternalProps) {
    super(props);
    this.state = { jobTasksForAssetMap: {} };
  }

  private get isUpdateMode() {
    return this.props.mode === 'update';
  }

  private get purchaseOrderId() {
    return this.props.match.params.id;
  }

  private loadCreateDefaultData = () => {
    this.props.clearActivityLog();
    return Promise.all([
      this.props.loadDeliveryLocations(),
      this.props.loadAssetListItems(),
    ]).then(r => Promise.resolve());
  };

  private readonly approvePurchaseOrder = () => {
    return this.props.onApprovePurchaseOrder(this.purchaseOrderId);
  };

  private readonly reinstatePurchaseOrder = () => {
    return this.props.onreinstatePurchaseOrder(this.purchaseOrderId);
  };

  private readonly generatePdf = () => {
    const fileName = `PO_${
      this.props.purchaseOrder!.purchaseOrderNumber
    }_${DateTime.local().toFormat('yyyyMMddHHmm')}.pdf`;
    return this.props.onGeneratePdf(this.purchaseOrderId).then(blob => saveAs(blob, fileName));
  };

  private loadData = () => {
    return Promise.all([
      this.props.onLoadPurchaseOrder(this.purchaseOrderId).then(() => {
        if (!this.props.purchaseOrder) {
          return;
        }
        if (this.props.purchaseOrder.supplier) {
          this.props.listContactsForSupplier(this.props.purchaseOrder.supplier.id);
        }
        this.props.purchaseOrder.purchaseOrderLines
          .map(l => l.asset && l.asset.id)
          .filter(assetId => assetId)
          .forEach(assetId => {
            this.props.getJobTasksForAsset(assetId).then(r => {
              const jobTasksForAssetMap = {
                ...this.state.jobTasksForAssetMap,
                [assetId]: r,
              };
              this.setState({ jobTasksForAssetMap });
            });
          });
      }),
      this.props.loadDeliveryLocations(),
      this.props.loadAssetListItems(),
    ]).then(r => Promise.resolve());
  };

  private get isCancellable() {
    return (
      this.props.purchaseOrder &&
      (this.props.purchaseOrder.status.id === PurchaseOrderStatus.New ||
        this.props.purchaseOrder.status.id === PurchaseOrderStatus.Approved)
    );
  }

  private handlePreSubmitForCreate = (a: PurchaseOrderItem): CreatePurchaseOrderCommand => {
    return {
      supplierId: a.supplier.id,
      deliveryInstructions: a.deliveryInstructions,
      deliveryLocationId: a.deliveryLocation.id,
      expectedDeliveryDate: a.expectedDeliveryDate,
      supplierContactId: a.contact ? a.contact.id : undefined,
      department: 'Workshop',
      notes: a.notes,
      externalNotes: a.externalNotes,
      purchaseOrderLines: a.purchaseOrderLines.map(line => {
        return {
          partId: line.part ? line.part.id : undefined,
          description: line.description,
          quantity: line.quantity,
          assetId: line.asset ? line.asset.id : undefined,
          jobTaskId: line.jobTask ? line.jobTask.id : undefined,
        };
      }),
    };
  };

  private handlePreSubmitForUpdate = (a: PurchaseOrderItem): UpdatePurchaseOrderCommand => {
    const originalPurchaseOrder = this.props.purchaseOrder;
    const getChangeState = (li: PurchaseOrderLineItem) => {
      if (li.changeState === ChangeState.Added || li.changeState === ChangeState.Deleted) {
        return li.changeState;
      }
      const originalLineItem =
        originalPurchaseOrder && originalPurchaseOrder.purchaseOrderLines.find(x => x.id === li.id);
      if (!originalLineItem) {
        throw new Error('Cannot find original Purchase Order');
      }
      return deepEqual(li, originalLineItem) ? ChangeState.Unchanged : ChangeState.Modified;
    };
    return {
      id: this.purchaseOrderId,
      deliveryInstructions: a.deliveryInstructions,
      deliveryLocationId: a.deliveryLocation.id,
      expectedDeliveryDate: a.expectedDeliveryDate,
      supplierContactId: a.contact ? a.contact.id : undefined,
      supplierId: a.supplier.id,
      department: 'Workshop',
      notes: a.notes,
      externalNotes: a.externalNotes,
      purchaseOrderLines: a.purchaseOrderLines.map(line => {
        return {
          id: line.id,
          partId: line.part ? line.part.id : undefined,
          description: line.description,
          quantity: line.quantity,
          assetId: line.asset ? line.asset.id : undefined,
          jobTaskId: line.jobTask ? line.jobTask.id : undefined,
          changeState: getChangeState(line),
        };
      }),
    };
  };

  private readonly getPageDef = (mode: CrudPageMode, updating: boolean): ICrudPageDef => {
    const {
      purchaseOrder,
      searchSuppliers,
      deliveryLocations,
      searchParts,
      listContactsForSupplier,
      contactsForSupplier,
      getJobTasksForAsset,
      canManagePurchaseOrders,
    } = this.props;
    const purchaseOrderNumber = purchaseOrder && purchaseOrder.purchaseOrderNumber;
    const editable = !this.isUpdateMode || updating;

    const isUpdatingAndIsNotNew =
      this.isUpdateMode && !!purchaseOrder && purchaseOrder.status.id !== PurchaseOrderStatus.New;

    const hasZeroQuantity = (line: PurchaseOrderLineItem) =>
      line.quantity != null && line.quantity.toString() === '0';

    return {
      primarySize: PagePrimarySize.twoThirds,
      primarySection: {
        title: this.isUpdateMode
          ? `Purchase Order ${purchaseOrderNumber || ''}`
          : 'Create a Purchase Order',
        badge:
          !this.isUpdateMode || !purchaseOrder
            ? undefined
            : { label: purchaseOrder.status.description },
        primaryActions: [
          {
            actions: [
              {
                actionType: ActionType.actionCollection,
                hidden: editable,
                actionGroups: [
                  {
                    actions: [
                      {
                        actionType: ActionType.actionButton,
                        label: 'Approve',
                        icon: <CheckIcon fixedWidth />,
                        onClick: this.approvePurchaseOrder,
                        hidden:
                          !purchaseOrder ||
                          !canManagePurchaseOrders ||
                          purchaseOrder.status.id !== PurchaseOrderStatus.New,
                      },
                      {
                        actionType: ActionType.actionButton,
                        label: 'Reinstate',
                        icon: <UndoIcon fixedWidth />,
                        onClick: this.reinstatePurchaseOrder,
                        hidden:
                          !purchaseOrder ||
                          !canManagePurchaseOrders ||
                          purchaseOrder.status.id !== PurchaseOrderStatus.Cancelled,
                      },
                      {
                        actionType: ActionType.modalActionButton,
                        label: 'Complete',
                        icon: <CheckIcon fixedWidth />,
                        hidden:
                          !purchaseOrder ||
                          !canManagePurchaseOrders ||
                          (purchaseOrder.status.id !== PurchaseOrderStatus.GoodsReceived &&
                            purchaseOrder.status.id !== PurchaseOrderStatus.AwaitingCredit),
                        modalSize: ShellModalSize.oneQuarter,
                        modalDef: modalDefApi => ({
                          title: 'Complete Purchase Order',
                          asForm: true,
                          panels: [
                            {
                              panes: [
                                {
                                  paneType: PaneType.customPane,
                                  render: _ => (
                                    <p>
                                      Are you sure you want to mark this purchase order as complete?
                                    </p>
                                  ),
                                },
                              ],
                            },
                          ],
                          secondaryActions: [getSubmitCloseModalActionGroupDef('Ok')],
                          onFormSubmit: () =>
                            this.props.onCompletePurchaseOrder(this.purchaseOrderId),
                        }),
                      },
                      {
                        actionType: ActionType.actionLink,
                        label: 'Receive Goods',
                        icon: <MultipleBoxesIcon fixedWidth />,
                        to: `/workshop/purchase-orders/${this.purchaseOrderId}/goods-received/create`,
                        hidden:
                          !purchaseOrder ||
                          !canManagePurchaseOrders ||
                          purchaseOrder.status.id === PurchaseOrderStatus.New ||
                          purchaseOrder.status.id === PurchaseOrderStatus.Cancelled,
                      },
                      {
                        actionType: ActionType.modalActionButton,
                        modalSize: ShellModalSize.oneQuarter,
                        label: 'Cancel',
                        icon: <BanIcon fixedWidth />,
                        hidden: !this.isCancellable || !canManagePurchaseOrders,
                        modalDef: _ => ({
                          title: 'Cancel Purchase Order',
                          asForm: true,
                          panels: [
                            {
                              panes: [
                                {
                                  paneType: PaneType.customPane,
                                  render: __ => (
                                    <p>Are you sure you want to cancel this purchase order?</p>
                                  ),
                                },
                              ],
                            },
                          ],
                          secondaryActions: [getSubmitCloseModalActionGroupDef('Ok')],
                          onFormSubmit: () =>
                            this.props.onCancelPurchaseOrder(this.purchaseOrderId),
                        }),
                      },
                      {
                        actionType: ActionType.actionButton,
                        label: 'Generate PDF',
                        icon: <FilePdfIcon fixedWidth />,
                        onClick: this.generatePdf,
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
        panels: [
          {
            panes: [
              {
                paneType: PaneType.formFieldsPane,
                columnCount: 2,
                fields: [
                  {
                    fieldType: FieldType.selectAsyncField,
                    dataAddr: 'supplier',
                    label: 'Supplier',
                    valueKey: 'id',
                    descriptionKey: 'name',
                    mandatory: true,
                    autoload: true,
                    readonly: isUpdatingAndIsNotNew,
                    loadOptionItems: searchSuppliers,
                    linkTo: d => `/workshop/suppliers/${d.parentValue.supplier.id}`,
                    onChange: api => {
                      listContactsForSupplier(
                        api.newFieldValue && (api.newFieldValue as SupplierListItem).id
                      );
                      const values = { ...api.formValues, contact: undefined };
                      api.setFormValues(values);
                    },
                  },
                  {
                    fieldType: FieldType.selectField,
                    dataAddr: 'contact',
                    label: 'Contact',
                    valueKey: 'id',
                    descriptionKey: 'contactName',
                    optionItems: contactsForSupplier,
                    readonly: d => !d.parentValue.supplier || isUpdatingAndIsNotNew,
                  },
                  {
                    fieldType: FieldType.selectField,
                    dataAddr: 'deliveryLocation',
                    label: 'Deliver To',
                    valueKey: 'id',
                    descriptionKey: 'description',
                    readonly: isUpdatingAndIsNotNew,
                    mandatory: true,
                    optionItems: deliveryLocations,
                  },
                  {
                    fieldType: FieldType.dateField,
                    dataAddr: 'expectedDeliveryDate',
                    label: 'Expected Delivery Date',
                    readonly: isUpdatingAndIsNotNew,
                  },
                ],
              },
              {
                paneType: PaneType.formFieldsPane,
                fields: [
                  {
                    fieldType: FieldType.textAreaField,
                    dataAddr: 'deliveryInstructions',
                    label: 'Delivery Instructions',
                  },
                ],
              },
              {
                paneType: PaneType.formFieldsPane,
                fields: [
                  {
                    fieldType: FieldType.textAreaField,
                    label: 'External Notes',
                    dataAddr: 'externalNotes',
                  },
                ],
              },
              {
                paneType: PaneType.formFieldsPane,
                fields: [
                  {
                    fieldType: FieldType.textAreaField,
                    label: 'Internal Notes',
                    dataAddr: 'notes',
                  },
                ],
              },
            ],
          },
          {
            title: 'Lines',
            dataAddr: 'purchaseOrderLines',
            hidden:
              this.isUpdateMode &&
              !updating &&
              (!purchaseOrder ||
                purchaseOrder.status.id === PurchaseOrderStatus.GoodsReceived ||
                purchaseOrder.status.id === PurchaseOrderStatus.Complete),
            panes: [
              {
                paneType: PaneType.tablePane,
                mandatory: true,
                fields: [
                  {
                    fieldType: FieldType.selectAsyncField,
                    label: 'Part Number',
                    dataAddr: 'part',
                    valueKey: 'id',
                    descriptionKey: 'partNumber',
                    menuWidth: 'fitContent',
                    columnWidth: '12em',
                    readonly: isUpdatingAndIsNotNew,
                    mandatory: false,
                    loadOptionItems: searchParts,
                    optionRenderer: (o: IMatchingPart) => (
                      <div style={{ whiteSpace: 'nowrap' }}>
                        {o.partNumber} - {o.description}
                      </div>
                    ),
                    linkTo: d => `/workshop/parts/${d.parentValue.part.id}`,
                    onChange: api => {
                      const selectedPart = api.newFieldValue as PartListItem;
                      if (selectedPart && selectedPart.description) {
                        const lineAddr = [...api.fieldDataAddr];
                        lineAddr.pop();
                        lineAddr.push('description');
                        api.setFormValue(lineAddr, selectedPart.description);
                      }

                      if (!selectedPart?.id) {
                        api.setFormValue(
                          [api.fieldDataAddr[0], api.fieldDataAddr[1], 'asset'],
                          undefined
                        );
                        api.setFormValue(
                          [api.fieldDataAddr[0], api.fieldDataAddr[1], 'jobTask'],
                          undefined
                        );
                      }
                    },
                  },
                  {
                    fieldType: FieldType.textField,
                    label: 'Description',
                    dataAddr: 'description',
                    mandatory: true,
                  },
                  {
                    fieldType: FieldType.numericField,
                    label: 'Quantity',
                    dataAddr: 'quantity',
                    columnWidth: '7em',
                    numericConfig: { numericType: 'unsignedDecimal', maxPointDigits: 4 },
                    readonly: isUpdatingAndIsNotNew,
                    mandatory: true,
                    validate: d =>
                      hasZeroQuantity(d.parentValue) ? 'Quantity cannot be 0' : undefined,
                  },
                  {
                    fieldType: FieldType.assetSelectField,
                    label: 'Asset',
                    dataAddr: 'asset',
                    columnWidth: '10em',
                    valueKey: 'id',
                    descriptionKey: 'name',
                    optionItems: this.props.assets,
                    mandatory: false,
                    readonly: d => !d.parentValue.part,
                    onChange: api => {
                      const assetId = api.newFieldValue && (api.newFieldValue as AssetItem).id;
                      getJobTasksForAsset(assetId).then(r => {
                        const jobTasksForAssetMap = {
                          ...this.state.jobTasksForAssetMap,
                          [assetId]: r,
                        };
                        this.setState({ jobTasksForAssetMap });
                      });
                      api.setFormValue(
                        [api.fieldDataAddr[0], api.fieldDataAddr[1], 'jobTask'],
                        undefined
                      );
                    },
                  },
                  {
                    fieldType: FieldType.selectField,
                    label: 'Task',
                    dataAddr: 'jobTask',
                    columnWidth: '10em',
                    valueKey: 'id',
                    descriptionKey: 'jobTaskNumber',
                    linkTo: d => `/workshop/tasks/${d.parentValue.jobTask.id}`,
                    menuWidth: 'fitContent',
                    optionItems: d => {
                      const item = d.parentValue as PurchaseOrderLineItem;
                      const assetId = item && item.asset && item.asset.id;
                      return sortJobTaskListItemsForPurchaseOrders(
                        this.state.jobTasksForAssetMap[assetId]
                      );
                    },
                    valuesToDisable: d => {
                      const item = d.parentValue as PurchaseOrderLineItem;
                      const assetId = item && item.asset && item.asset.id;
                      if (!this.state.jobTasksForAssetMap[assetId]) {
                        return [];
                      }
                      return this.state.jobTasksForAssetMap[assetId]
                        .filter(a => a.status.id === JobTaskStatus.Cancelled)
                        .map(a => a.id);
                    },
                    mandatory: d =>
                      this.isUpdateMode &&
                      purchaseOrder &&
                      d.parentValue.asset &&
                      (purchaseOrder.status.id === PurchaseOrderStatus.Approved ||
                        purchaseOrder.status.id === PurchaseOrderStatus.GoodsReceived),
                    readonly: d => !d.parentValue.asset,
                    optionRenderer: (o: JobTaskListItem) => (
                      <div style={{ whiteSpace: 'nowrap' }}>
                        <strong>{o.jobTaskNumber}</strong>
                        <small>
                          &nbsp; • {o.category.description} • {o.status.description}
                        </small>
                      </div>
                    ),
                  },
                  {
                    fieldType: FieldType.actionListField,
                    dataAddr: '',
                    columnWidth: '1px',
                    actionGroups: [
                      {
                        actions: [
                          {
                            hidden: isUpdatingAndIsNotNew || (this.isUpdateMode && !updating),
                            actionType: ActionType.removeArrayItemActionButton,
                            label: 'Remove Line',
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
              {
                paneType: PaneType.actionListPane,
                actionGroups: [
                  {
                    actions: [
                      {
                        hidden: isUpdatingAndIsNotNew || (this.isUpdateMode && !updating),
                        actionType: ActionType.addArrayItemActionButton,
                        label: 'Add Part/Service Provider',
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            title: 'Lines',
            dataAddr: 'aggregateReceivedLines',
            hidden:
              editable ||
              !purchaseOrder ||
              (purchaseOrder.status.id !== PurchaseOrderStatus.GoodsReceived &&
                purchaseOrder.status.id !== PurchaseOrderStatus.Complete),
            panes: [
              {
                paneType: PaneType.tablePane,
                neverEditable: true,
                fields: [
                  {
                    fieldType: FieldType.textField,
                    label: 'Part Number',
                    dataAddr: ['part', 'partNumber'],
                    readonly: true,
                    linkTo: d => `/workshop/parts/${d.parentValue.part.id}`,
                  },
                  {
                    fieldType: FieldType.textField,
                    label: 'Description',
                    dataAddr: 'description',
                    readonly: true,
                  },
                  {
                    fieldType: FieldType.numericField,
                    label: 'Ordered Qty',
                    dataAddr: 'orderedQuantity',
                    numericConfig: { numericType: 'unsignedDecimal', maxPointDigits: 4 },
                    readonly: true,
                  },
                  {
                    fieldType: FieldType.numericField,
                    label: 'Received Qty',
                    dataAddr: 'receivedQuantity',
                    numericConfig: { numericType: 'unsignedDecimal', maxPointDigits: 4 },
                    readonly: true,
                  },
                  {
                    fieldType: FieldType.numericField,
                    label: 'Returned Qty',
                    dataAddr: 'returnedQuantity',
                    numericConfig: { numericType: 'unsignedDecimal', maxPointDigits: 4 },
                    readonly: true,
                  },
                  {
                    fieldType: FieldType.assetSelectField,
                    label: 'Asset',
                    dataAddr: 'asset',
                    valueKey: 'id',
                    descriptionKey: 'name',
                    readonly: true,
                  },
                  {
                    fieldType: FieldType.textField,
                    label: 'Task',
                    dataAddr: ['jobTask', 'jobTaskNumber'],
                    readonly: true,
                    linkTo: d => `/workshop/tasks/${d.parentValue.jobTask.id}`,
                  },
                  {
                    fieldType: FieldType.readonlyField,
                    label: 'Complete',
                    dataAddr: 'complete',
                    formatReadonly: d => {
                      const aggReceivedLine = d.parentValue as AggregatePurchaseOrderLineItem;
                      return aggReceivedLine && aggReceivedLine.complete ? (
                        <CheckIcon />
                      ) : (
                        undefined
                      );
                    },
                  },
                  {
                    fieldType: FieldType.actionListField,
                    columnWidth: '1px',
                    actionGroups: [
                      {
                        actions: [
                          {
                            actionType: ActionType.modalActionButton,
                            modalSize: ShellModalSize.oneQuarter,
                            label: 'Manually complete line',
                            icon: <CheckIcon />,
                            hidden: d =>
                              (purchaseOrder &&
                                purchaseOrder.status.id !== PurchaseOrderStatus.GoodsReceived) ||
                              d.parentValue.complete,
                            modalDef: modalDefApi => ({
                              title: 'Manually Complete Line',
                              asForm: true,
                              panels: [
                                {
                                  panes: [
                                    {
                                      paneType: PaneType.customPane,
                                      render: _ => (
                                        <p>Are you sure you want to mark this line as complete?</p>
                                      ),
                                    },
                                  ],
                                },
                              ],
                              secondaryActions: [getSubmitCloseModalActionGroupDef('Ok')],
                              onFormSubmit: () => {
                                const aggReceivedLine = modalDefApi.actionData
                                  .actionValue as AggregatePurchaseOrderLineItem;
                                return this.props.onCompletePurchaseOrderLine(
                                  this.purchaseOrderId,
                                  aggReceivedLine.purchaseOrderLineId!
                                );
                              },
                            }),
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
        onFormPreSubmit: this.isUpdateMode
          ? this.handlePreSubmitForUpdate
          : this.handlePreSubmitForCreate,
        onFormSubmit: this.isUpdateMode
          ? this.props.onUpdatePurchaseOrder
          : this.props.onCreatePurchaseOrder,
      },
      secondarySections: !this.isUpdateMode
        ? []
        : [
            {
              title: 'Transactions',
              dataAddr: 'invoiceHistory',
              hidden:
                purchaseOrder &&
                purchaseOrder.status.id !== PurchaseOrderStatus.GoodsReceived &&
                purchaseOrder.status.id !== PurchaseOrderStatus.AwaitingCredit &&
                purchaseOrder.status.id !== PurchaseOrderStatus.Complete,
              panels: [
                {
                  panes: [
                    {
                      paneType: PaneType.tablePane,
                      neverEditable: true,
                      fields: [
                        {
                          fieldType: FieldType.dateField,
                          label: 'Date',
                          dataAddr: 'receivedDate',
                          linkTo:
                            purchaseOrder && purchaseOrder.id
                              ? d => {
                                  if (d.parentValue.type === InvoiceType.GoodsReceived) {
                                    return `/workshop/purchase-orders/${purchaseOrder.id}/goods-received/${d.parentValue.goodsReceivedId}`;
                                  } else if (d.parentValue.type === InvoiceType.CreditNote) {
                                    return `/workshop/purchase-orders/${purchaseOrder.id}/goods-received/${d.parentValue.goodsReceivedId}/returned-part-credits/${d.parentValue.creditNoteId}`;
                                  }
                                  return undefined;
                                }
                              : undefined,
                        },
                        {
                          fieldType: FieldType.selectField,
                          dataAddr: 'type',
                          label: 'Type',
                          optionItems: allInvoiceType,
                          valueKey: 'value',
                          descriptionKey: 'description',
                          useValueOnly: true,
                          readonly: true,
                        },
                        {
                          fieldType: FieldType.textField,
                          label: 'Number',
                          dataAddr: 'number',
                        },
                      ],
                    },
                    {
                      paneType: PaneType.actionListPane,
                      actionGroups: [
                        {
                          actions: [
                            {
                              actionType: ActionType.actionLink,
                              label: 'View details',
                              icon: <FileIcon />,
                              to: purchaseOrder?.id
                                ? `/workshop/purchase-orders/${purchaseOrder.id}/invoices`
                                : '',
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
            {
              title: 'Activity',
              explicitData: this.props.activityLogs,
              panels: [
                {
                  panes: [
                    {
                      paneType: PaneType.repeatingPane,
                      itemPanes: [
                        {
                          paneType: PaneType.customPane,
                          dataAddr: 'transactionId',
                          render: api => {
                            const log = api.data.parentValue as ActivityLogTransaction;
                            const createdOnDateTime = DateTime.fromISO(
                              log.createdOn
                            ).toLocaleString(DateTime.DATETIME_MED_WITH_SECONDS);
                            return (
                              <div className="activity-log-for-purchase-order-pane">
                                <div className="created-by">{log.createdBy}</div>
                                <div className="created-on">{createdOnDateTime}</div>
                                <ul className="log-items list-unstyled">
                                  {log.items &&
                                    log.items.map((item: ActivityLogDetails, index) => {
                                      return (
                                        <li className="log-item" key={item.activityLogId}>
                                          <div className="description">{item.description}</div>
                                          <div className="comments">{item.comment}</div>
                                        </li>
                                      );
                                    })}
                                </ul>
                              </div>
                            );
                          },
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
    };
  };

  render() {
    const { mode, purchaseOrder, canManagePurchaseOrders } = this.props;
    const newMode =
      mode === 'update' &&
      purchaseOrder &&
      purchaseOrder.status.id === PurchaseOrderStatus.Cancelled
        ? 'view'
        : mode;
    return (
      <CrudPage
        def={({ updating }) => this.getPageDef(mode, updating)}
        mode={newMode}
        isEditingForbidden={!canManagePurchaseOrders}
        onLoadData={this.loadData}
        data={purchaseOrder}
        createDefaultData={emptyPurchaseOrder}
        onLoadCreateDefaultData={this.loadCreateDefaultData}
      />
    );
  }
}

export default MaintainPurchaseOrder;
