import { IAjax } from 'src/infrastructure/ajax';

export default function init(ajax: IAjax) {
  function loadExtraTypes() {
    return ajax
      .get('/api/operations/extra-types/all')
      .toPromise()
      .then<Operations.Domain.Queries.ViewExtraType.ExtraTypeItem[]>(r => r.response);
  }

  return {
    extraTypes: {
      loadExtraTypes,
    },
  };
}
