import { Table } from 'reactstrap';
import { DateTimeFormat } from 'src/views/components/DateTimeFormat';
import PrintJobTask from '../PrintJobTask/PrintJobTask';
import styles from './PrintJobSheetHeader.module.scss';

interface IPrintJobSheetHeaderProps {
  job: Workshop.Domain.Queries.Job.PrintJobQuery.JobItem;
  task: Workshop.Domain.Queries.Job.PrintJobQuery.JobTaskItem;
}

const PrintJobSheetHeader: React.FC<IPrintJobSheetHeaderProps> = ({ job, task }) => (
  <div className={styles.printJobSheetHeaderComponent}>
    <Table>
      <tbody>
        <tr>
          <td className={styles.headerAsset}>{job.assetName}</td>
        </tr>
        <tr>
          <td className={styles.headerText}>Job No: {job.jobNumber}</td>
          <td className={styles.headerText}>
            Starts at: <DateTimeFormat value={job.startDateTime} />
          </td>
        </tr>
        <tr>
          <td className={styles.headerText}>Registration number: {job.registrationNumber}</td>
          <td className={styles.headerText}>
            Ends at: <DateTimeFormat value={job.endDateTime} />
          </td>
        </tr>
        <tr>
          <td className={styles.headerText}>Engine Model: {job.engineModel}</td>
          {/* Empty item for formatting */}
          <td className={styles.headerText} />
        </tr>
        <tr>
          <td className={styles.headerText}>Kms: ________________________</td>
        </tr>
        {!!task.supplierName ? (
          <tr>
            <td className={styles.headerText}>Contractor: {task.supplierName}</td>
          </tr>
        ) : null}
      </tbody>
    </Table>
    <PrintJobTask task={task} />
  </div>
);

export default PrintJobSheetHeader;
