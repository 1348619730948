import { DateTime } from 'luxon';
import { TIME_24_SIMPLE_HOURCYCLE_23 } from 'src/infrastructure/dateUtils';

export const FormattedDateTime: React.FC<{
  className?: string;
  referenceDateTime?: DateTime | string;
  dateTime: DateTime | string;
  hideTime?: boolean;
}> = ({ className, referenceDateTime, dateTime, hideTime }) => {
  const givenDate =
    typeof dateTime === typeof DateTime
      ? (dateTime as DateTime)
      : DateTime.fromISO(dateTime as string);

  const refDate = referenceDateTime
    ? typeof referenceDateTime === typeof DateTime
      ? (referenceDateTime as DateTime)
      : DateTime.fromISO(referenceDateTime as string)
    : DateTime.local();

  return (
    <span className={className}>
      {givenDate.toLocaleString(
        refDate.month === givenDate.month && refDate.day === givenDate.day
          ? TIME_24_SIMPLE_HOURCYCLE_23
          : hideTime
          ? { ...DateTime.DATETIME_MED, hour12: false, minute: undefined, hour: undefined }
          : { ...DateTime.DATETIME_MED, hour12: false }
      )}
    </span>
  );
};
