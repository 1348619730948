import ListPage, { IListPageDef } from 'src/views/components/Page/pages/ListPage';
import { IListPageLoadDataRequest } from 'src/domain/baseTypes';
import { FieldType } from 'src/views/definitionBuilders/types/field';
import TaskCardItem from 'src/views/components/TaskCard/TaskCardItem';
import { PagePrimarySize } from 'src/views/definitionBuilders/types';
import PrimaryTitle from 'src/views/components/Page/PrimaryTitle/PrimaryTitle';

export interface IListSubcontractorProps {
  canManageSubcontractors: boolean;
  listSubcontractors: (request: IListPageLoadDataRequest) => Promise<void>;
  subcontractors: Operations.Domain.Queries.ListSubcontractors.SubcontractorItem[];
  hasMoreData: boolean;
}

function getPageDef(props: IListSubcontractorProps): IListPageDef {
  return {
    primaryTitle: (
      <PrimaryTitle
        title="Subcontractors"
        link="https://www.notion.so/Subcontractors-e4e43a694b364ea39121fc4bb68884a7"></PrimaryTitle>
    ),
    primarySize: PagePrimarySize.full,
    onLoadData: props.listSubcontractors,
    externalSearch: true,
    createLink: props.canManageSubcontractors
      ? TaskCardItem.operations.subcontractors.createSubcontractor
      : undefined,
    hasMoreData: props.hasMoreData,
    primaryFields: [
      {
        fieldType: FieldType.textField,
        dataAddr: 'name',
        label: 'Name',
        linkTo: d => `/operations/subcontractors/${d.parentValue.id}`,
      },
      {
        fieldType: FieldType.textField,
        dataAddr: 'phoneNumber',
        label: 'Phone',
      },
    ],
  };
}

const ListSubcontractor: React.FC<IListSubcontractorProps> = (props: IListSubcontractorProps) => {
  return <ListPage data={props.subcontractors} def={getPageDef(props)} />;
};

export default ListSubcontractor;
