import deepEqual from 'deep-equal';
import { observer } from 'mobx-react';
import { useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { ChangeState, ShiftTranslinkDirection } from 'src/api/enums';
import { useRootStore } from 'src/domain/entities/RootStoreModel';
import {
  ISplittedSkillSpecRequirements,
  consolidateSkillSpecRequirements,
  splitSkillSpecRequirements,
} from 'src/domain/entities/people/staffMember/SkillSpecsHelpers';
import {
  ISplittedTechSpecRequirements,
  consolidateTechSpecRequirements,
  splitTechSpecRequirements,
  splitTechSpecs,
} from 'src/domain/entities/workshop/techSpecs/TechSpecsHelpers';
import { CopyIcon, EditIcon, PlusIcon, TrashIcon, UndoIcon } from 'src/images/icons';
import CrudPage, { CrudPageMode, ICrudPageDef } from 'src/views/components/Page/pages/CrudPage';
import { getSubmitCloseModalActionGroupDef } from 'src/views/definitionBuilders/common';
import {
  ActionType,
  FieldType,
  IHasChangeState,
  PagePrimarySize,
  PaneType,
  ShellModalSize,
} from 'src/views/definitionBuilders/types';
import getDuplicateRailTemplateModalDef from 'src/views/routes/operations/rail/maintainRailTemplate/getDuplicateRailTemplateModalDef';
import getMaintainShiftModalDef from 'src/views/routes/operations/rail/maintainRailTemplate/getMaintainShiftModalDef';

type CreateRailTemplateCommand = Operations.Domain.Commands.RailTemplate.CreateRailTemplate.CreateRailTemplateCommand;
type UpdateRailTemplateCommand = Operations.Domain.Commands.RailTemplate.UpdateRailTemplate.UpdateRailTemplateCommand;
type SimpleAssetItem = Common.Queries.Workshop.GetSimpleFleetAssetList.SimpleAssetItem;
type BoardingPointListItem = Operations.Domain.Queries.SearchBoardingPoint.BoardingPointListItem;
type CreateBoardingPointCommand = Operations.Domain.Commands.BoardingPoint.CreateBoardingPointCommand;
type RailTemplateShiftRouteItem = Operations.Domain.Commands.RailTemplate.CreateRailTemplate.RailTemplateShiftRouteItem;
type RailTemplateShiftRouteGroupItem = Operations.Domain.Commands.RailTemplate.CreateRailTemplate.RailTemplateShiftRouteGroupItem;
type RailTemplateShiftAttachmentDto = Operations.Domain.Commands.RailTemplate.RailTemplateShiftAttachmentDto;
export interface IMaintainRailTemplateProps {
  mode: CrudPageMode;
  route: RouteComponentProps<{ [x: string]: string | undefined }>;
}

interface IMaintainRailTemplateForm {
  name: string;
  notes: string;
  shifts: IMaintainRailTemplateShift[];
}

type IMaintainRailTemplateShift = ISplittedSkillSpecRequirements &
  ISplittedTechSpecRequirements & {
    id?: number;
    changeState: ChangeState;
    arrivingAtDepot: Common.Dtos.OperationsDepotDto;
    arrivingAtDepotInCar: boolean;
    asset: SimpleAssetItem;
    clockOff: string;
    clockOn: string;
    departingFromDepot: Common.Dtos.OperationsDepotDto;
    departingFromDepotInCar: boolean;
    departDepot: string;
    arriveDepot: string;
    description: string;
    shiftCommence: string;
    shiftEnd: string;
    shiftName: string;
    unpaidBreaks: string;
    notes: string;
    runCount: number;
    routeGroups: IMaintainRailTemplateShiftRouteGroup[];
    attachmentDetails: RailTemplateShiftAttachmentDto[];
  };

interface IMaintainRailTemplateShiftRouteGroup {
  routes: IMaintainRailTemplateShiftRoute[];
  desto: string;
  name: string;
  changeState: ChangeState;
  translinkDirection: ShiftTranslinkDirection;
}

interface IMaintainRailTemplateShiftRoute {
  changeState: ChangeState;
  location: BoardingPointListItem;
  depart: string[];
}

const MaintainRailTemplate: React.FC<IMaintainRailTemplateProps> = observer(
  (props: IMaintainRailTemplateProps) => {
    const rootStore = useRootStore();
    const canManageRailTemplates = rootStore.account.isRailDepartmentMember;
    const currentRailTemplate = rootStore.operations.rail.railTemplate.railTemplate;
    const loadRailTemplate = rootStore.operations.rail.railTemplate.loadRailTemplate;
    const updateRailTemplate = rootStore.operations.rail.railTemplate.updateRailTemplate;
    const createRailTemplate = rootStore.operations.rail.railTemplate.createRailTemplate;
    const duplicateRailTemplate = rootStore.operations.rail.railTemplate.duplicateRailTemplate;
    const checkForUniqueRailTemplateName =
      rootStore.operations.rail.railTemplate.checkForUniqueRailTemplateName;
    const depots = rootStore.operationsStartup.operationsDepots.slice();
    const fleetAssets = rootStore.assets.fleetAssetListItems.slice();
    const loadFleetAssets = rootStore.assets.loadFleetAssets;
    const states = rootStore.operations.sales.boardingPoint.states.slice();
    const loadStates = rootStore.operations.sales.boardingPoint.loadStates;
    const searchBoardingPoints = rootStore.operations.sales.boardingPoint.searchBoardingPoints;
    const checkForUniqueBoardingPointName =
      rootStore.operations.sales.boardingPoint.checkForUniqueName;
    const onCreateBoardingPoint = (cmd: CreateBoardingPointCommand) =>
      rootStore.operations.sales.boardingPoint.createBoardingPoint(cmd, true);
    const skillSpecs = rootStore.people.skillSpecs.forRequirements.items.slice();
    const loadSkillSpecs = rootStore.people.skillSpecs.forRequirements.getAll;
    const techSpecs = rootStore.workshop.techSpecs.forRequirements.items.slice();
    const loadTechSpecs = rootStore.workshop.techSpecs.forRequirements.getAll;
    const searchTechSpecValues = rootStore.assets.searchTechSpecValues;
    const getTechSpecDropdownsOptions = rootStore.workshop.techSpecs.getTechSpecDropdownsOptions;
    const techSpecDropdownOptions = rootStore.workshop.techSpecs.techSpecDropdownOptions;
    const deleteRailTemplate = rootStore.operations.rail.railTemplate.deleteRailTemplate;
    const restoreRailTemplate = rootStore.operations.rail.railTemplate.restoreRailTemplate;
    const generateShiftAttachmentId =
      rootStore.operations.rail.railTemplate.generateShiftAttachmentId;
    const downloadAttachment = rootStore.operations.rail.railTemplate.downloadAttachment;

    useEffect(() => {
      loadStates();
      loadFleetAssets();
      loadSkillSpecs();
      loadTechSpecs();
      getTechSpecDropdownsOptions();
    }, []);

    const isUpdateMode = props.mode === 'update';
    const railTemplateId = props.route.match.params.id!;
    const splittedTechSpecs = splitTechSpecs(techSpecs);

    const getChangeState = (attachment: RailTemplateShiftAttachmentDto) => {
      const originalAttachments = currentRailTemplate?.shifts.flatMap(s =>
        s.attachmentDetails.map(a => ({
          id: a.id,
          shiftId: a.railTemplateShiftId,
          attachmentId: a.attachmentId,
          fileName: a.fileName,
          availableOnTablet: a.availableOnTablet,
          availableOnKiosk: a.availableOnKiosk,
          updateCurrentJobs: false,
          changeState: ChangeState.Unchanged,
        }))
      );
      if (
        attachment.changeState === ChangeState.Added ||
        attachment.changeState === ChangeState.Deleted
      ) {
        return attachment.changeState;
      }
      const originalAttachment = originalAttachments?.find(
        (shiftAttachment: RailTemplateShiftAttachmentDto) => shiftAttachment.id === attachment.id
      );

      return deepEqual(attachment, originalAttachment, { strict: true })
        ? ChangeState.Unchanged
        : ChangeState.Modified;
    };

    const handlePreSubmitForCreate = (
      railTemplate: IMaintainRailTemplateForm
    ): CreateRailTemplateCommand => {
      return {
        name: railTemplate.name,
        notes: railTemplate.notes,
        shifts: railTemplate.shifts
          .filter(x => x.changeState !== ChangeState.Deleted)
          .map(shift => {
            return {
              arrivingAtDepotId: shift.arrivingAtDepot.id,
              arrivingAtDepotInCar: shift.arrivingAtDepotInCar,
              assetId: shift.asset && shift.asset.id,
              clockOff: shift.clockOff,
              clockOn: shift.clockOn,
              departingFromDepotId: shift.departingFromDepot.id,
              departingFromDepotInCar: shift.departingFromDepotInCar,
              departDepot: shift.departDepot,
              arriveDepot: shift.arriveDepot,
              description: shift.description,
              shiftCommence: shift.shiftCommence,
              shiftEnd: shift.shiftEnd,
              shiftName: shift.shiftName,
              unpaidBreaks: shift.unpaidBreaks,
              notes: shift.notes,
              routeGroups: mapRoutesToCommand(shift.runCount, shift.routeGroups),
              skillSpecRequirements: consolidateSkillSpecRequirements(shift),
              techSpecRequirements: consolidateTechSpecRequirements(shift, splittedTechSpecs),
              attachmentDetails:
                shift.attachmentDetails?.map(
                  ad =>
                    ({
                      attachmentId: ad.attachmentId,
                      fileName: ad.fileName,
                      availableOnTablet: ad.availableOnTablet,
                      availableOnKiosk: ad.availableOnKiosk,
                      updateCurrentJobs: ad.updateCurrentJobs,
                      changeState: ChangeState.Added,
                    } as RailTemplateShiftAttachmentDto)
                ) ?? [],
            };
          }),
      } as CreateRailTemplateCommand;
    };

    const handlePreSubmitForUpdate = (
      railTemplate: IMaintainRailTemplateForm
    ): UpdateRailTemplateCommand => {
      return {
        id: railTemplateId,
        name: railTemplate.name,
        notes: railTemplate.notes,
        shifts: railTemplate.shifts.map(shift => {
          return {
            id: shift.id,
            arrivingAtDepotId: shift.arrivingAtDepot.id,
            arrivingAtDepotInCar: shift.arrivingAtDepotInCar,
            assetId: shift.asset && shift.asset.id,
            clockOff: shift.clockOff,
            clockOn: shift.clockOn,
            departingFromDepotId: shift.departingFromDepot.id,
            departingFromDepotInCar: shift.departingFromDepotInCar,
            departDepot: shift.departDepot,
            arriveDepot: shift.arriveDepot,
            description: shift.description,
            shiftCommence: shift.shiftCommence,
            shiftEnd: shift.shiftEnd,
            shiftName: shift.shiftName,
            unpaidBreaks: shift.unpaidBreaks,
            notes: shift.notes,
            routeGroups: mapRoutesToCommand(shift.runCount, shift.routeGroups),
            skillSpecRequirements: consolidateSkillSpecRequirements(shift),
            techSpecRequirements: consolidateTechSpecRequirements(shift, splittedTechSpecs),
            attachmentDetails:
              shift.attachmentDetails?.map(
                ad =>
                  ({
                    id: ad.id,
                    shiftId: shift.id,
                    attachmentId: ad.attachmentId,
                    fileName: ad.fileName,
                    availableOnTablet: ad.availableOnTablet,
                    availableOnKiosk: ad.availableOnKiosk,
                    updateCurrentJobs: ad.updateCurrentJobs,
                    changeState: getChangeState(ad),
                  } as RailTemplateShiftAttachmentDto)
              ) ?? [],
            changeState: shift.changeState,
          };
        }),
      } as UpdateRailTemplateCommand;
    };

    const mapRoutesToCommand = (
      runCount: number,
      routeGroups: IMaintainRailTemplateShiftRouteGroup[]
    ): RailTemplateShiftRouteGroupItem[] => {
      if (!routeGroups) {
        return [];
      }
      return routeGroups
        .filter(x => x.changeState !== ChangeState.Deleted)
        .map(rg => {
          let routes: RailTemplateShiftRouteItem[] = [];
          rg.routes
            .filter(x => x.changeState !== ChangeState.Deleted)
            .forEach((route, routeNumber) => {
              for (let i = 0; i < runCount; i++) {
                const depart =
                  i < ((route.depart && route.depart.length) || 0) ? route.depart[i] : undefined;
                routes.push({
                  address: route.location.address,
                  city: route.location.city,
                  depart: depart,
                  name: route.location.name,
                  notes: route.location.notes,
                  postcode: route.location.postcode,
                  runNumber: i,
                  routeNumber: routeNumber,
                  state: route.location.state,
                });
              }
            });
          return {
            name: rg.name,
            desto: rg.desto,
            routes: routes,
            translinkDirection: rg.translinkDirection,
          };
        });
    };

    const getMaxValue = (
      routesGroups: Operations.Domain.Queries.ViewRailTemplate.RailTemplateShiftRouteGroupItem[],
      valueFunction: (
        route: Operations.Domain.Queries.ViewRailTemplate.RailTemplateShiftRouteItem
      ) => number
    ) => {
      const routes = routesGroups.reduce(
        (acc: Operations.Domain.Queries.ViewRailTemplate.RailTemplateShiftRouteItem[], val) => {
          return acc.concat(val.routes);
        },
        []
      );
      return getMax(routes, valueFunction);
    };

    const getMax = (
      routes: Operations.Domain.Queries.ViewRailTemplate.RailTemplateShiftRouteItem[],
      valueFunction: (
        route: Operations.Domain.Queries.ViewRailTemplate.RailTemplateShiftRouteItem
      ) => number
    ) => {
      const routeValues = routes.length > 0 ? routes.map(valueFunction) : [0];
      return Math.max(...routeValues) + 1;
    };

    const mapRoutesToData = (
      routeGroups: Operations.Domain.Queries.ViewRailTemplate.RailTemplateShiftRouteGroupItem[]
    ): IMaintainRailTemplateShiftRouteGroup[] => {
      const maxRunNumber = getMaxValue(routeGroups, r => r.runNumber);
      return routeGroups.map(rg => {
        const routeGroup: IMaintainRailTemplateShiftRouteGroup = {
          ...rg,
          changeState: ChangeState.Unchanged,
          routes: Array.from({ length: getMax(rg.routes, r => r.routeNumber) }, () => {
            return {
              location: {
                address: '',
                name: '',
                city: '',
                postcode: '',
                state: '',
                notes: '',
                id: '',
              },
              depart: Array.from({ length: maxRunNumber }, () => '') as string[],
              changeState: ChangeState.Unchanged,
            };
          }),
        };

        rg.routes.forEach(r => {
          const departArray = routeGroup.routes[r.routeNumber].depart;
          departArray[r.runNumber] = r.depart as string;
          const routeData = {
            location: {
              address: r.address,
              name: r.name,
              city: r.city,
              postcode: r.postcode,
              state: r.state,
              notes: r.notes,
              id: '',
            },
            depart: [...departArray],
            changeState: ChangeState.Unchanged,
          };
          routeGroup.routes[r.routeNumber] = routeData;
        });
        return routeGroup;
      });
    };

    const railTemplateData = (): IMaintainRailTemplateForm => {
      if (!isUpdateMode || !currentRailTemplate || !currentRailTemplate.id) {
        return {} as IMaintainRailTemplateForm;
      }

      return {
        name: currentRailTemplate.name,
        notes: currentRailTemplate.notes,
        shifts: currentRailTemplate.shifts.map(shift => {
          return {
            ...splitSkillSpecRequirements(shift.skillSpecRequirements),
            ...splitTechSpecRequirements(shift.techSpecRequirements),
            id: shift.id,
            changeState: ChangeState.Unchanged,
            arrivingAtDepot: shift.arrivingAtDepot,
            arrivingAtDepotInCar: shift.arrivingAtDepotInCar,
            asset: shift.asset,
            clockOff: shift.clockOff,
            clockOn: shift.clockOn,
            departingFromDepot: shift.departingFromDepot,
            departingFromDepotInCar: shift.departingFromDepotInCar,
            departDepot: shift.departDepot,
            arriveDepot: shift.arriveDepot,
            description: shift.description,
            shiftCommence: shift.shiftCommence,
            shiftEnd: shift.shiftEnd,
            shiftName: shift.shiftName,
            unpaidBreaks: shift.unpaidBreaks,
            notes: shift.notes,
            runCount: getMaxValue(shift.routeGroups, r => r.runNumber),
            routeGroups: mapRoutesToData(shift.routeGroups),
            attachmentDetails: shift.attachmentDetails?.map(ad => ({
              ...ad,
              updateCurrentJobs: false,
              changeState: ChangeState.Unchanged,
            })),
          };
        }),
      };
    };

    const getPageDef = (updating: boolean): ICrudPageDef => {
      const editable = !isUpdateMode || updating;
      return {
        primarySize: PagePrimarySize.full,
        primarySection: {
          title: isUpdateMode ? 'Manage Rail Template' : 'Create a Rail Template',
          badge:
            currentRailTemplate && currentRailTemplate.deleted
              ? {
                  label: 'Deleted',
                }
              : undefined,
          primaryActions: canManageRailTemplates
            ? [
                {
                  actions: [
                    {
                      actionType: ActionType.actionCollection,
                      hidden: editable,
                      actionGroups: [
                        {
                          actions: [
                            {
                              actionType: ActionType.modalActionButton,
                              label: `Duplicate Rail Template`,
                              icon: <CopyIcon fixedWidth />,
                              hidden: currentRailTemplate && currentRailTemplate.deleted,
                              modalSize: ShellModalSize.oneQuarter,
                              modalDef: getDuplicateRailTemplateModalDef(() =>
                                duplicateRailTemplate(railTemplateId)
                              ),
                            },
                            {
                              actionType: ActionType.actionButton,
                              label: 'Restore Rail Template',
                              icon: <UndoIcon />,
                              hidden: currentRailTemplate && !currentRailTemplate.deleted,
                              onClick: () => restoreRailTemplate(railTemplateId),
                            },
                            {
                              actionType: ActionType.modalActionButton,
                              label: 'Delete Rail Template',
                              icon: <TrashIcon />,
                              hidden: currentRailTemplate && currentRailTemplate.deleted,
                              modalSize: ShellModalSize.oneQuarter,
                              modalDef: () => ({
                                title: 'Delete Rail Template',
                                asForm: true,
                                panels: [
                                  {
                                    panes: [
                                      {
                                        paneType: PaneType.formFieldsPane,
                                        fields: [
                                          {
                                            fieldType: FieldType.customField,
                                            dataAddr: 'fake',
                                            render: () => (
                                              <span>
                                                Are you sure you want to delete this Rail Template?
                                              </span>
                                            ),
                                          },
                                        ],
                                      },
                                    ],
                                  },
                                ],
                                secondaryActions: [getSubmitCloseModalActionGroupDef('Ok')],
                                onFormSubmit: () => deleteRailTemplate(railTemplateId),
                              }),
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ]
            : [],
          panels: [
            {
              panes: [
                {
                  paneType: PaneType.formFieldsPane,
                  fields: [
                    {
                      fieldType: FieldType.textField,
                      label: 'Name',
                      dataAddr: 'name',
                      maxLength: 200,
                      mandatory: true,
                      validateAsync: async d => {
                        if (
                          !d.fieldValue ||
                          (isUpdateMode &&
                            currentRailTemplate &&
                            currentRailTemplate.name.toUpperCase() === d.fieldValue.toUpperCase())
                        ) {
                          return undefined;
                        }
                        const result = await checkForUniqueRailTemplateName(d.fieldValue);
                        return result.nameExists ? `Name is already in use` : undefined;
                      },
                    },
                    {
                      fieldType: FieldType.textAreaField,
                      label: 'Notes',
                      dataAddr: 'notes',
                    },
                  ],
                },
                {
                  paneType: PaneType.nestingPane,
                  dataAddr: 'shifts',
                  panes: [
                    {
                      paneType: PaneType.tablePane,
                      title: 'Shifts',
                      mandatory: true,
                      neverEditable: true,
                      fields: [
                        {
                          fieldType: FieldType.actionListField,
                          columnWidth: editable ? '130px' : '1px',
                          hidden: !editable,
                          actionGroups: [
                            {
                              actions: [
                                {
                                  actionType: ActionType.modalActionButton,
                                  label: 'Edit Shift',
                                  icon: <EditIcon fixedWidth />,
                                  disabled: d =>
                                    ((d.parentValue || {}) as IHasChangeState).changeState ===
                                    ChangeState.Deleted,
                                  modalSize: ShellModalSize.threeQuarters,
                                  modalDef: getMaintainShiftModalDef(
                                    'edit',
                                    depots,
                                    fleetAssets,
                                    states,
                                    skillSpecs,
                                    splittedTechSpecs,
                                    searchBoardingPoints,
                                    checkForUniqueBoardingPointName,
                                    onCreateBoardingPoint,
                                    searchTechSpecValues,
                                    techSpecDropdownOptions,
                                    generateShiftAttachmentId,
                                    downloadAttachment
                                  ),
                                },
                                {
                                  actionType: ActionType.modalActionButton,
                                  label: 'Duplicate Shift',
                                  icon: <CopyIcon fixedWidth />,
                                  disabled: d =>
                                    ((d.parentValue || {}) as IHasChangeState).changeState ===
                                    ChangeState.Deleted,
                                  modalSize: ShellModalSize.threeQuarters,
                                  modalDef: getMaintainShiftModalDef(
                                    'add-duplicate',
                                    depots,
                                    fleetAssets,
                                    states,
                                    skillSpecs,
                                    splittedTechSpecs,
                                    searchBoardingPoints,
                                    checkForUniqueBoardingPointName,
                                    onCreateBoardingPoint,
                                    searchTechSpecValues,
                                    techSpecDropdownOptions,
                                    generateShiftAttachmentId,
                                    downloadAttachment
                                  ),
                                },
                                {
                                  actionType: ActionType.removeArrayItemActionButton,
                                  label: 'Remove Shift',
                                },
                              ],
                            },
                          ],
                        },
                        {
                          fieldType: FieldType.readonlyField,
                          label: 'Shift Name',
                          dataAddr: 'shiftName',
                        },
                        {
                          fieldType: FieldType.readonlyField,
                          label: 'Description',
                          dataAddr: 'description',
                        },
                        {
                          fieldType: FieldType.timeField,
                          label: 'Clock On',
                          dataAddr: 'clockOn',
                          readonly: true,
                        },
                        {
                          fieldType: FieldType.timeField,
                          label: 'Depart Depot',
                          dataAddr: 'departDepot',
                          readonly: true,
                        },
                        {
                          fieldType: FieldType.timeField,
                          label: 'Shift Commence',
                          dataAddr: 'shiftCommence',
                          readonly: true,
                        },
                        {
                          fieldType: FieldType.timeField,
                          label: 'Clock Off',
                          dataAddr: 'clockOff',
                          readonly: true,
                        },
                        {
                          fieldType: FieldType.assetSelectField,
                          label: 'Vehicle',
                          dataAddr: 'asset',
                          optionItems: fleetAssets,
                          valueKey: 'id',
                          descriptionKey: 'name',
                          readonly: true,
                        },
                      ],
                    },
                    {
                      paneType: PaneType.actionListPane,
                      hidden: !editable,
                      actionGroups: [
                        {
                          actions: [
                            {
                              actionType: ActionType.modalActionButton,
                              label: 'Add Shift',
                              icon: <PlusIcon />,
                              modalSize: ShellModalSize.threeQuarters,
                              modalDef: getMaintainShiftModalDef(
                                'add',
                                depots,
                                fleetAssets,
                                states,
                                skillSpecs,
                                splittedTechSpecs,
                                searchBoardingPoints,
                                checkForUniqueBoardingPointName,
                                onCreateBoardingPoint,
                                searchTechSpecValues,
                                techSpecDropdownOptions,
                                generateShiftAttachmentId,
                                downloadAttachment
                              ),
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
          onFormPreSubmit: isUpdateMode ? handlePreSubmitForUpdate : handlePreSubmitForCreate,
          onFormSubmit: isUpdateMode ? updateRailTemplate : createRailTemplate,
        },
      };
    };

    return (
      <CrudPage
        key={railTemplateId}
        def={({ updating }) => getPageDef(updating)}
        mode={props.mode}
        isEditingForbidden={
          !canManageRailTemplates || (currentRailTemplate && currentRailTemplate.deleted)
        }
        onLoadData={() => loadRailTemplate(railTemplateId)}
        data={railTemplateData()}
        createDefaultData={{ attachmentDetails: [] }}
      />
    );
  }
);

export default MaintainRailTemplate;
