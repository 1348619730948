import inject from 'src/views/injectFromKioskStore';
import { IJobDetailsProps, JobDetails } from './JobDetails';

const Container = inject<IJobDetailsProps>((allStores, p) => ({
  job: allStores.rootStore.kiosk.workshop.job.job,
  loadJob: allStores.rootStore.kiosk.workshop.job.loadJob,
  extendJob: allStores.rootStore.kiosk.workshop.job.extendJob,
  loggedInStaffMemberId: allStores.rootStore.account.id,
  goBack: allStores.rootStore.history.history!.goBack,
  nextOpsJobForAsset: allStores.rootStore.kiosk.asset.nextOpsJobForAsset,
  loadNextOpsJobsForAsset: allStores.rootStore.kiosk.asset.loadNextOpsJobsForAsset,
  jobToPrint: allStores.rootStore.kiosk.workshop.job.printJobItem,
  loadPrintJobSheet: allStores.rootStore.kiosk.workshop.job.loadPrintJobSheet,

  loadFutureTasks: allStores.rootStore.kiosk.asset.jobsTasks.loadFutureTasks,
  loadScheduledTasks: allStores.rootStore.kiosk.asset.jobsTasks.loadScheduledTasks,
  loadStartedTasks: allStores.rootStore.kiosk.asset.jobsTasks.loadStartedTasks,

  deviceDepotId: allStores.rootStore.kiosk.workshop.defaultKioskDepotId,
}))(JobDetails);

export default Container;
