import { IAjax } from 'src/infrastructure/ajax';
import { kioskUrls } from 'src/domain/services/apiUrls';

export default function init(ajax: IAjax) {
  function clockOn(command: People.Domain.Commands.Kiosk.ClockOn.ClockOn.ClockOnCommand) {
    return ajax
      .post(kioskUrls.clockUrls.clockOn(), command)
      .toPromise()
      .then<void>(r => Promise.resolve());
  }

  function clockOff(command: People.Domain.Commands.Kiosk.ClockOn.ClockOff.ClockOffCommand) {
    return ajax
      .put(kioskUrls.clockUrls.clockOff(), command)
      .toPromise()
      .then<void>(r => Promise.resolve());
  }

  function loadStaffMemberStatus(staffMemberId: string) {
    return ajax
      .get(kioskUrls.clockUrls.loadStaffMemberStatus(staffMemberId))
      .toPromise()
      .then<People.Domain.Queries.GetStaffMemberClockStatus.StaffMemberClockStatus>(
        r => r.response
      );
  }

  function loadStaffMemberTestResult(staffMemberId: string) {
    return ajax
      .get(kioskUrls.clockUrls.loadStaffMemberTestResult(staffMemberId))
      .toPromise()
      .then<Common.Dtos.AlcolizerTestResultDto>(r => r.response);
  }

  return {
    clockOn: {
      clockOn,
      clockOff,
      loadStaffMemberStatus,
      loadStaffMemberTestResult,
    },
  };
}
