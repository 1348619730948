import { IAjax } from 'src/infrastructure/ajax';
import { kioskUrls } from 'src/domain/services/apiUrls';

type ShiftDetails = Workshop.Domain.Queries.GetShiftDetailForKiosk.ShiftDetails;
type CompleteShiftCommand = Workshop.Domain.Commands.Shift.CompleteShift.CompleteShiftCommand;

export default function init(ajax: IAjax) {
  function completeShift(command: CompleteShiftCommand) {
    return ajax
      .put(kioskUrls.workshopUrls.shifts.completeShift(command.shiftId), command)
      .toPromise()
      .then(r => Promise.resolve());
  }

  function loadShift(shiftId: string) {
    return ajax
      .get(kioskUrls.workshopUrls.shifts.loadShift(shiftId))
      .toPromise()
      .then<ShiftDetails>(r => r.response);
  }

  return {
    shift: {
      loadShift,
      completeShift,
    },
  };
}
