import './ActionButton.scss';

import cn from 'classnames';
import { Button, DropdownItem } from 'reactstrap';
import { IActionButtonDef, IActionMeta, IActionData } from 'src/views/definitionBuilders/types';
import { ModalContext, IModalMeta } from 'src/views/components/Page/PageArea';
import { SpinnerIcon } from 'src/images/icons';
import { Component } from 'react';

interface IActionButtonProps {
  className?: string;
  actionDef: IActionButtonDef;
  actionMeta: IActionMeta;
  actionData: IActionData;
  renderAsDropdownItem?: boolean;
  tag?: React.ReactType;
}

interface IActionButtonState {
  clickInProgress: boolean;
}

class ActionButton extends Component<IActionButtonProps, IActionButtonState> {
  constructor(props: IActionButtonProps) {
    super(props);
    this.state = {
      clickInProgress: false,
    };
  }

  private readonly getDisabled = () => {
    const { actionDef, actionData } = this.props;

    if (typeof actionDef.disabled === 'function') {
      return actionDef.disabled(actionData);
    } else {
      return actionDef.disabled;
    }
  };

  private readonly handleClick = (modalMeta: IModalMeta | undefined) => {
    const { actionDef, actionData } = this.props;
    const { clickInProgress } = this.state;

    if (clickInProgress) {
      return;
    }

    const clickResult = actionDef.onClick(actionData);
    const postClick = modalMeta && actionDef.closeModal ? modalMeta.closeModal : undefined;

    // Handle when an action returns a promise
    if (clickResult && clickResult.then) {
      this.setState({ clickInProgress: true }, () =>
        clickResult
          .then(() => {
            this.setState({ clickInProgress: false });
            postClick && postClick();
          })
          .catch(e => {
            this.setState({ clickInProgress: false });
          })
      );
    } else {
      postClick && postClick();
    }
  };

  render() {
    const { className, actionDef, actionMeta: meta, renderAsDropdownItem, tag } = this.props;
    const { clickInProgress } = this.state;
    const showAsOutline = meta.wide && actionDef.level !== 'primary';
    const disabled = this.getDisabled();

    const icon = clickInProgress ? (
      <SpinnerIcon />
    ) : actionDef.icon ? (
      <span className="icon-wrapper">{actionDef.icon}</span>
    ) : null;
    const label =
      (meta.hideLabel || meta.circular) && !renderAsDropdownItem ? null : actionDef.label;
    const colour = meta.danger
      ? 'danger'
      : !actionDef.level || actionDef.level === 'default'
      ? 'secondary'
      : actionDef.level;
    const ddColourClassName = `dropdown-item-${colour}`;

    return (
      <ModalContext.Consumer>
        {modalMeta =>
          renderAsDropdownItem ? (
            <DropdownItem
              className={cn('action-button-component', className, ddColourClassName)}
              tag={tag}
              title={actionDef.label}
              disabled={disabled}
              onClick={() => this.handleClick(modalMeta)}>
              {icon} {label}
            </DropdownItem>
          ) : (
            <Button
              className={cn('action-button-component', className, actionDef.className, {
                borderless: meta.borderless,
                'show-label': !meta.hideLabel && !meta.circular,
                circular: meta.circular,
              })}
              tag={tag}
              type="button"
              title={actionDef.label}
              disabled={disabled}
              size={meta.size}
              onClick={() => this.handleClick(modalMeta)}
              outline={meta.danger ? false : meta.borderless || showAsOutline}
              color={colour}>
              {icon} {label}
            </Button>
          )
        }
      </ModalContext.Consumer>
    );
  }
}

export default ActionButton;
