import { PaneType, SectionDefs } from 'src/views/definitionBuilders/types';
import AuditHistory from 'src/views/components/AuditHistory/workshop/AuditHistoryContainer';
import getSchema from 'src/views/routes/workshop/jobs/maintainJob/AuditHistorySchema';

type StaffMemberDto = Common.Dtos.StaffMemberDto;

export default function getAuditHistoryPanelDef(
  aggregateId: string | undefined,
  staffMembers: StaffMemberDto[]
): SectionDefs {
  return {
    title: 'History',
    hidden: !aggregateId,
    panels: [
      {
        panes: [
          {
            paneType: PaneType.customPane,
            render: api => {
              if (!aggregateId) {
                return undefined;
              }
              return (
                <AuditHistory
                  aggregateId={aggregateId}
                  minimumVersion={2}
                  schema={getSchema(staffMembers)}
                />
              );
            },
          },
        ],
      },
    ],
  };
}
