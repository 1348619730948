import { types, getRoot, flow } from 'mobx-state-tree';
import { IRootStoreModel } from 'src/domain/entities/RootStoreModel';
import { getAjax, NotificationType } from 'src/domain/services';
import { partsUrls } from 'src/domain/services/apiUrls';

type CreatePartCommand = Workshop.Domain.Commands.Part.CreatePart.CreatePartCommand;
type UpdatePartCommand = Workshop.Domain.Commands.Part.UpdatePart.UpdatePartCommand;
type UpdatePartSetActiveCommand = Workshop.Domain.Commands.Part.SetPartActive.SetPartActiveCommand;
type UpdatePartSetInactiveCommand = Workshop.Domain.Commands.Part.SetPartInactive.SetPartInactiveCommand;
type CreateStockAdjustmentCommand = Workshop.Domain.Commands.Part.CreateStockAdjustment.CreateStockAdjustmentCommand;
type RecordStocktakeCommand = Workshop.Domain.Commands.Part.RecordStocktake.RecordStocktakeCommand;
type ListStocktakePartsQuery = Workshop.Domain.Queries.Parts.GetStocktakePartsList.ListStocktakePartsQuery;
type PartItem = Workshop.Domain.Queries.Parts.PartItem;
type PartUsage = Workshop.Domain.Queries.Parts.GetPartUsage.PartUsage;

export const PartModel = types
  .model('PartModel', {
    part: types.maybe(types.frozen<PartItem>()),
    partUsage: types.maybe(types.frozen<PartUsage>()),
  })
  .actions(self => {
    const ajax = getAjax(self);
    const root = getRoot(self) as IRootStoreModel;

    const createPart = flow(function*(command: CreatePartCommand) {
      const id = yield ajax.workshop.part.createPart(command);
      self.part = undefined;
      root.history.push(`/workshop/parts/${id}`);
    });

    const checkForUniquePartNumber = flow(function*(partNumber: string) {
      return yield ajax.workshop.part.checkForUniquePartNumber(partNumber);
    });

    const loadPart = flow(function*(partId: string) {
      self.part = yield ajax.workshop.part.getPart(partId);
    });

    const loadPartUsage = flow(function*(partId: string) {
      self.partUsage = yield ajax.workshop.part.getPartUsage(partId);
    });

    const updatePart = flow(function*(command: UpdatePartCommand) {
      yield ajax.workshop.part.updatePart(command);
      yield loadPart(command.id);
      root.history.push(`/workshop/parts/${self.part!.id}`);
    });

    const setPartActive = flow(function*(command: UpdatePartSetActiveCommand) {
      yield ajax.workshop.part.updatePartSetActive(command);
      yield loadPart(command.id);
      yield loadPartUsage(command.id);
    });

    const setPartInactive = flow(function*(command: UpdatePartSetInactiveCommand) {
      yield ajax.workshop.part.updatePartSetInactive(command);
      yield loadPart(command.id);
      yield loadPartUsage(command.id);
    });

    const createStockAdjustment = flow(function*(command: CreateStockAdjustmentCommand) {
      yield ajax.workshop.part.createStockAdjustment(command);
      yield loadPart(command.partId);
    });

    const recordStocktake = flow(function*(command: RecordStocktakeCommand) {
      yield ajax.workshop.part.recordStocktake(command);
    });

    const generateStocktakePdf = flow(function*(query: Partial<ListStocktakePartsQuery>) {
      root.notifications.addNotification(`The PDF is being generated ...`, {
        type: NotificationType.info,
      });

      return yield ajax.workshop.part.generateStocktakePdf(query);
    });

    const generateStocktakeExcel = flow(function*(query: Partial<ListStocktakePartsQuery>) {
      root.notifications.addNotification(`The file is being generated ...`, {
        type: NotificationType.info,
      });

      return yield ajax.workshop.part.generateStocktakeExcel(query);
    });

    const deletePart = flow(function*(partId: string) {
      yield ajax.raw.httpDelete(partsUrls.deletePart(partId)).toPromise();

      self.part = undefined;

      root.history.replace(`/workshop/parts`);
    });

    return {
      createPart,
      checkForUniquePartNumber,
      loadPart,
      updatePart,
      setPartActive,
      setPartInactive,
      loadPartUsage,
      createStockAdjustment,
      recordStocktake,
      generateStocktakePdf,
      generateStocktakeExcel,
      deletePart,
    };
  });
