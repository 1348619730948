import styles from './EventDetailsPopover.module.scss';
import cn from 'classnames';

import { PopoverProps } from 'reactstrap/lib/Popover';
import { IScheduleEvent } from './ScheduleEvent';
import { RendersafePopover } from '../RendersafePopover/RendersafePopover';
import { PopoverBody } from 'reactstrap';

interface IEventDetailsPopoverProps {
  target: PopoverProps['target'];
  event: IScheduleEvent | undefined;
  className?: string;
  onClose: () => void;
}

const EventDetailsPopover: React.FC<IEventDetailsPopoverProps> = ({
  target,
  event,
  className,
  onClose,
}) => {
  if (!event) {
    return <></>;
  }

  return (
    <RendersafePopover
      key={event.eventId}
      className={cn(styles['event-details-popover'], className || '')}
      placement="right-end"
      isOpen
      flip={false}
      fade={false}
      target={target}
      toggle={onClose}>
      <PopoverBody>{event.eventCalloutBody}</PopoverBody>
    </RendersafePopover>
  );
};

export default EventDetailsPopover;
