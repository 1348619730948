import { Component } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import CrudPage, { CrudPageMode, ICrudPageDef } from 'src/views/components/Page/pages/CrudPage';
import {
  PagePrimarySize,
  PaneType,
  FieldType,
  ActionType,
  PaneDefs,
  IPaneData,
} from 'src/views/definitionBuilders/types';
import { DateTime } from 'luxon';
import {
  allProgressId,
  allBusUsageStatus,
  BusUsageStatus,
  ChangeState,
  ProgressId,
} from 'src/api/enums';
import {
  Chunk,
  chunkJob,
  chunkPrevNextJob,
  doChunksOverlap,
  isDateInInterval,
} from 'src/views/components/maintainJobChunker';

type StaffMemberDto = Common.Dtos.StaffMemberDto;
type UpdateJobProgressCommand =
  | Operations.Domain.Commands.Job.UpdateJobProgress.UpdateJobProgressCommand
  | Workshop.Domain.Commands.Job.UpdateWorkshopJobProgressCommand;
type JobProgressType =
  | Operations.Domain.Queries.GetJobProgress.JobProgressItem
  | Workshop.Domain.Queries.GetJobsProgressUsage.JobBusUsageDto;
type JobProgresses = JobProgressType[];
type AssetItem = Common.Queries.Workshop.GetFleetAssetList.AssetItem;
type PreviousAndNextJobProgressResult = Common.Queries.GetPreviousAndNextJobProgress.PreviousAndNextJobProgressResult;

export type MappedProgress = JobProgressType & {
  progress: number;
  isWorkshop?: boolean;
};

export interface IMaintainJobProgressProps {
  mode: CrudPageMode;
  canManageJobProgress: boolean;
  loadStaffMembers: () => Promise<void>;
  staffMembers: StaffMemberDto[];
  loadJobProgress: (jobId: string) => Promise<void>;
  loadJob: (jobId: string) => Promise<void>;
  updateJobProgress: (command: UpdateJobProgressCommand) => Promise<void>;
  jobProgresses?: JobProgresses;
  fleetAssets: AssetItem[];
  loadFleetAssets: () => Promise<void>;
  jobNumber: number | undefined;
  jobStatus: number | undefined;
  isWorkshop: boolean;
  loadPreviousAndNextJobProgress: (jobId: string) => Promise<void>;
  previousAndNextJobProgresses: PreviousAndNextJobProgressResult | undefined;
}

interface IMaintainJobProgressRouteParams {
  id: string;
}

interface IForm {
  asset: { id: string };
  staffMember: { id: string };
  changeState: Common.ChangeState;
  jobProgressId?: number;
  occurredAt: string;
  progress: Common.AggregatesModel.Operations.JobAggregate.ProgressId;
}

type InternalProps = IMaintainJobProgressProps &
  RouteComponentProps<IMaintainJobProgressRouteParams>;

class MaintainJobProgress extends Component<InternalProps> {
  private get jobId() {
    return this.props.match.params.id;
  }

  componentDidMount() {
    this.props.loadStaffMembers();
    this.props.loadFleetAssets();
    this.props.loadJob(this.jobId);
  }

  private handlePreSubmitForCreate = (progresses: IForm[]): UpdateJobProgressCommand => {
    const command: UpdateJobProgressCommand = {
      jobId: this.jobId,
      jobProgressUpdates: progresses.map(p => ({
        ...p,
        assetId: p.asset.id,
        jobProgressId: p.jobProgressId,
        staffMemberId: p.staffMember ? p.staffMember.id : '',
      })),
    };
    return command;
  };

  private handleSubmitForCreate = (command: UpdateJobProgressCommand) => {
    return this.props.updateJobProgress(command);
  };

  private readonly doJobsOverlap = (
    currentValue: DateTime,
    current: (MappedProgress & { changeState: ChangeState })[],
    prevChunks: Chunk[],
    nextChunks: Chunk[]
  ): string | undefined => {
    const onlyValidProgresses = current.filter(x => x.changeState !== ChangeState.Deleted);
    const currentChunks = chunkJob(onlyValidProgresses);

    const chunksOverlappingPreviousJob = doChunksOverlap(currentChunks, prevChunks);
    if (chunksOverlappingPreviousJob.length > 0) {
      // Probably need the stupid ValueOf check as well for end checks
      const isThisRowInOverlap = chunksOverlappingPreviousJob.some(x =>
        isDateInInterval(x, currentValue)
      );
      if (isThisRowInOverlap) {
        return 'This progress overlaps the previous job';
      }
    }

    const chunksOverlappingNextJob = doChunksOverlap(currentChunks, nextChunks);
    if (chunksOverlappingNextJob.length > 0) {
      // Probably need the stupid ValueOf check as well for end checks
      const isThisRowInOverlap = chunksOverlappingNextJob.some(x =>
        isDateInInterval(x, currentValue)
      );
      if (isThisRowInOverlap) {
        return 'This progress overlaps the next job';
      }
    }

    return undefined;
  };

  private readonly getPageDef = (updating: boolean): ICrudPageDef => {
    const isReadOnly = (parentValue: {
      jobProgressId: string | undefined;
      busUsageStatusId: string | undefined;
    }) => !!parentValue.jobProgressId || !!parentValue.busUsageStatusId;

    const isPrevHidden = (d: IPaneData) => !d.sectionValue.previous;
    const isNextHidden = (d: IPaneData) => !d.sectionValue.next;

    const nextChunks = chunkPrevNextJob(this.props.previousAndNextJobProgresses?.next);
    const prevChunks = chunkPrevNextJob(this.props.previousAndNextJobProgresses?.previous);

    const getTablePane = (readonly?: boolean, isPrevNext?: boolean): PaneDefs => ({
      paneType: PaneType.tablePane,
      title: isPrevNext ? undefined : 'Job Progress Entries',
      dataRequiredForRows: 'paneValue',
      fields: [
        {
          fieldType: FieldType.dateTimeField,
          dataAddr: ['occurredAt'],
          label: 'Date',
          showSeconds: true,
          formatReadonly: d => {
            const createdOnDateTime = DateTime.fromISO(d.fieldValue!).toLocaleString(
              DateTime.DATETIME_MED_WITH_SECONDS
            );

            return <span>{createdOnDateTime}</span>;
          },
          readonly: d => readonly || isReadOnly(d.parentValue),
          columnWidth: '17rem',
          validate: d => {
            const value = d.fieldValue && DateTime.fromISO(d.fieldValue);
            if (!value || !value.isValid) {
              return undefined;
            }

            const overlapMessage = this.doJobsOverlap(value, d.paneValue, prevChunks, nextChunks);

            if (overlapMessage) {
              return overlapMessage;
            }

            const inFuture =
              value.diffNow('milliseconds').milliseconds < 0
                ? undefined
                : 'Date cannot not be in the future';

            return inFuture;
          },
        },
        {
          fieldType: FieldType.selectField,
          label: 'Progress',
          dataAddr: 'progress',
          mandatory: true,
          optionItems: d => {
            let isWorkshop = this.props.isWorkshop;
            if (isPrevNext) {
              isWorkshop = d.parentValue.isWorkshop;
            }
            return isWorkshop
              ? allBusUsageStatus
              : allProgressId.filter(p => p.value !== ProgressId.NotStarted);
          },
          descriptionKey: 'description',
          valueKey: 'value',
          useValueOnly: true,
          readonly: d => readonly || isReadOnly(d.parentValue),
          valuesToExclude: d => {
            let isWorkshop = this.props.isWorkshop;
            if (isPrevNext) {
              isWorkshop = d.parentValue.isWorkshop;
            }
            return isWorkshop ? [BusUsageStatus.NotUsed] : []; // None for Ops
          },
        },
        {
          fieldType: FieldType.selectField,
          label: 'Staff Member',
          dataAddr: 'staffMember',
          optionItems: this.props.staffMembers,
          valueKey: 'id',
          descriptionKey: 'name',
          mandatory: true,
          readonly: d => readonly || isReadOnly(d.parentValue),
          menuWidth: 'fitContent',
        },
        {
          fieldType: FieldType.assetSelectField,
          label: 'Vehicle',
          dataAddr: 'asset',
          optionItems: this.props.fleetAssets,
          valueKey: 'id',
          descriptionKey: 'name',
          readonly: d => readonly || isReadOnly(d.parentValue),
          menuWidth: 210,
        },
        {
          fieldType: FieldType.textField,
          dataAddr: ['odometer'],
          label: 'Odo',
          columnWidth: '1px',
          readonly: true,
        },
        {
          fieldType: FieldType.actionListField,
          columnWidth: '1px',
          hidden: readonly === true,
          actionGroups: [
            {
              actions: [
                {
                  actionType: ActionType.removeArrayItemActionButton,
                  label: 'Remove Progress',
                  hidden: !updating,
                },
              ],
            },
          ],
        },
      ],
    });

    return {
      primarySize: PagePrimarySize.half,
      primarySection: {
        title: `Job ${this.props.jobNumber}`,
        panels: [
          {
            panes: [
              {
                paneType: PaneType.nestingPane,
                dataAddr: 'progresses',
                panes: [
                  { ...getTablePane() },
                  {
                    paneType: PaneType.actionListPane,
                    actionGroups: [
                      {
                        actions: [
                          {
                            actionType: ActionType.addArrayItemActionButton,
                            label: 'Add Progress',
                            hidden: !updating,
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
        onFormPreSubmit: d => this.handlePreSubmitForCreate(d.progresses),
        onFormSubmit: this.handleSubmitForCreate,
      },
      secondarySections: [
        {
          title: 'Surrounding Jobs',

          panels: [
            {
              title: 'Previous Job',
              dataAddr: 'previous',
              panes: [
                {
                  paneType: PaneType.customPane,
                  hidden: d => !isPrevHidden(d),
                  render: d => (
                    <span>
                      No Previous Job found for allocated asset. Please check an asset is allocated
                      to this job.
                    </span>
                  ),
                },
                {
                  paneType: PaneType.formFieldsPane,
                  columnCount: 2,
                  hidden: d => isPrevHidden(d),
                  fields: [
                    {
                      fieldType: FieldType.textField,
                      dataAddr: 'jobNumber',
                      label: 'Job Number',
                      linkTo: d =>
                        `/${d.paneValue.isWorkshop ? 'workshop' : 'operations'}/jobs/${
                          d.paneValue.jobId
                        }`,
                    },
                    {
                      fieldType: FieldType.textField,
                      dataAddr: 'description',
                      label: 'Description',
                    },
                  ],
                },
                {
                  paneType: PaneType.nestingPane,
                  hidden: d => isPrevHidden(d),
                  dataAddr: 'progresses',
                  panes: [getTablePane(true, true)],
                },
              ],
            },
            {
              panes: [
                {
                  paneType: PaneType.customPane,
                  render: d => <hr />,
                },
              ],
            },
            {
              title: 'Subsequent Job',
              dataAddr: 'next',
              panes: [
                {
                  paneType: PaneType.customPane,
                  hidden: d => !isNextHidden(d),
                  render: d => (
                    <span>
                      No Previous Job found for allocated asset. Please check an asset is allocated
                      to this job.
                    </span>
                  ),
                },
                {
                  paneType: PaneType.formFieldsPane,
                  columnCount: 2,
                  hidden: d => isNextHidden(d),
                  fields: [
                    {
                      fieldType: FieldType.textField,
                      dataAddr: 'jobNumber',
                      label: 'Job Number',
                      linkTo: d =>
                        `/${d.paneValue.isWorkshop ? 'workshop' : 'operations'}/jobs/${
                          d.paneValue.jobId
                        }`,
                    },
                    {
                      fieldType: FieldType.textField,
                      dataAddr: 'description',
                      label: 'Description',
                    },
                  ],
                },
                {
                  paneType: PaneType.nestingPane,
                  hidden: d => isNextHidden(d),
                  dataAddr: 'progresses',
                  panes: [getTablePane(true, true)],
                },
              ],
            },
          ],
        },
      ],
    };
  };

  render() {
    const { mode, jobProgresses, loadJobProgress, loadPreviousAndNextJobProgress } = this.props;

    // For typing later, despite passing it in :(
    function isWorkshopProgress(
      item: JobProgressType
    ): item is Workshop.Domain.Queries.GetJobsProgressUsage.JobBusUsageDto {
      return (
        (item as Workshop.Domain.Queries.GetJobsProgressUsage.JobBusUsageDto).busUsageStatusId !==
        undefined
      );
    }

    let progresses: MappedProgress[] =
      (jobProgresses &&
        jobProgresses.map(x => ({
          ...x,
          progress: isWorkshopProgress(x) ? x.busUsageStatusId : x.progress,
          isWorkshop: isWorkshopProgress(x),
        }))) ||
      [];

    return (
      <CrudPage
        def={({ updating }) => this.getPageDef(updating)}
        mode={mode}
        onLoadData={() =>
          Promise.all([
            loadPreviousAndNextJobProgress(this.jobId),
            loadJobProgress(this.jobId),
          ]).then()
        }
        data={{ progresses, ...this.props.previousAndNextJobProgresses }}
      />
    );
  }
}

export default MaintainJobProgress;
