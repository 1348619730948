import { saveAs } from 'file-saver';
import { DateTime, Duration } from 'luxon';
import { observer } from 'mobx-react';
import { useEffect } from 'react';
import {
  allCustomerComplaintCategory,
  allCustomerComplaintSeverityLevel,
  allCustomerComplaintStatus,
  allJobType,
  CustomerComplaintStatus,
  customerComplaintStatusDescription,
} from 'src/api/enums';
import { useRootStore } from 'src/domain/entities/RootStoreModel';
import { ExcelIcon, WarnIcon } from 'src/images/icons';
import memoizeOne from 'src/infrastructure/memoizeOne';
import { DateFormat } from 'src/views/components/DateFormat';
import ListPage, { IListPageDef } from 'src/views/components/Page/pages/ListPage';
import PrimaryTitle from 'src/views/components/Page/PrimaryTitle/PrimaryTitle';
import { TaskCardItem } from 'src/views/components/TaskCard';
import {
  ActionType,
  FieldDefs,
  FieldType,
  IFieldData,
  PagePrimarySize,
  PaneType,
} from 'src/views/definitionBuilders/types';
import withQueryParams, { IQueryParamsProps } from 'src/views/hocs/withQueryParams';

type ListCustomerComplaintQuery = Operations.Domain.Queries.ListCustomerComplaint.ListCustomerComplaintQuery;
type ListCustomerComplaintItem = Operations.Domain.Queries.ListCustomerComplaint.ListCustomerComplaintItem;

const ListCustomerComplaint: React.FC<IQueryParamsProps<ListCustomerComplaintQuery>> = observer(
  (props: IQueryParamsProps<ListCustomerComplaintQuery>) => {
    const rootStore = useRootStore();
    const complaintModel = rootStore.compliance.customerComplaint.list;
    const assetsModel = rootStore.assets;
    const staffModel = rootStore.people.staffMembers;

    useEffect(() => {
      assetsModel.loadFleetAssets();
      staffModel.loadAllStaffMemberNames({});
    }, []);

    const saveSpreadsheet = () => {
      const fileName = `Customer_Complaints_${DateTime.local().toFormat('yyyyMMddHHmm')}.xlsx`;
      return complaintModel
        .exportToExcel(props.getQueryParams())
        .then(blob => saveAs(blob, fileName));
    };

    const isOverdue = (customerComplaint: ListCustomerComplaintItem) => {
      var date = DateTime.fromISO(customerComplaint.createdOn);
      const now = DateTime.local();
      const threeDays = Duration.fromObject({ days: 3 }).as('days');
      return (
        customerComplaint.status === CustomerComplaintStatus.Open &&
        now.diff(date).as('days') > threeDays
      );
    };

    const getFilterFieldDefs = () => {
      return {
        dateFrom: {
          fieldType: FieldType.dateField,
          dataAddr: 'dateFrom',
          label: 'Date From',
        } as FieldDefs,
        dateTo: {
          fieldType: FieldType.dateField,
          dataAddr: 'dateTo',
          label: 'Date To',
          validate: d => {
            if (!d.fieldValue || !d.parentValue.dateFrom) {
              return undefined;
            }
            const from = DateTime.fromISO(d.parentValue.dateFrom);
            const to = DateTime.fromISO(d.fieldValue);
            return from > to ? 'Date To cannot be earlier than Date From' : undefined;
          },
        } as FieldDefs,
        drivers: {
          fieldType: FieldType.selectMultiField,
          dataAddr: 'drivers',
          label: 'Staff Member',
          valueKey: 'id',
          descriptionKey: 'name',
          optionItems: staffModel.allStaffMemberNames.slice(),
          useValueOnly: true,
        } as FieldDefs,
        categories: {
          fieldType: FieldType.selectMultiField,
          dataAddr: 'categories',
          label: 'Category',
          valueKey: 'value',
          descriptionKey: 'description',
          optionItems: allCustomerComplaintCategory.slice(),
          useValueOnly: true,
        } as FieldDefs,
        typesOfService: {
          fieldType: FieldType.selectMultiField,
          dataAddr: 'typesOfService',
          label: 'Type of Service',
          valueKey: 'value',
          descriptionKey: 'description',
          optionItems: allJobType.slice(),
          useValueOnly: true,
        } as FieldDefs,
        severityLevels: {
          fieldType: FieldType.selectMultiField,
          dataAddr: 'severityLevels',
          label: 'Severity Level',
          valueKey: 'value',
          descriptionKey: 'description',
          optionItems: allCustomerComplaintSeverityLevel.slice(),
          useValueOnly: true,
        } as FieldDefs,
        statuses: {
          fieldType: FieldType.selectMultiField,
          dataAddr: 'statuses',
          label: 'Status',
          valueKey: 'value',
          descriptionKey: 'description',
          optionItems: allCustomerComplaintStatus.slice(),
          useValueOnly: true,
        } as FieldDefs,
        isOverdue: {
          fieldType: FieldType.yesNoField,
          dataAddr: 'isOverdue',
          label: 'Overdue',
        } as FieldDefs,
        callerName: {
          fieldType: FieldType.textField,
          dataAddr: 'callerName',
          label: `Caller's Name`,
        } as FieldDefs,
        details: {
          fieldType: FieldType.textField,
          dataAddr: 'details',
          label: 'Details',
        } as FieldDefs,
      };
    };
    const getPageDef = memoizeOne(
      (hasMoreData: boolean): IListPageDef => {
        const filterFieldDefsLookup = getFilterFieldDefs();
        return {
          primaryTitle: (
            <PrimaryTitle
              title="Customer Complaints"
              link="https://www.notion.so/Customer-Complaints-fde977ecaf4a4e95bf0342f52df888ab"></PrimaryTitle>
          ),
          onLoadData: complaintModel.listItems,
          externalSearch: true,
          createLink: rootStore.account.isOperationsDepartmentMember
            ? TaskCardItem.operations.compliance.customerComplaint.createCustomerComplaint
            : undefined,
          primarySize: PagePrimarySize.full,
          hasMoreData: hasMoreData,
          rowKey: d => {
            return d.itemValue.id;
          },
          primaryActions: [
            {
              actions: [
                {
                  actionType: ActionType.actionCollection,
                  actionGroups: [
                    {
                      actions: [
                        {
                          actionType: ActionType.actionButton,
                          label: 'Export to Excel',
                          icon: <ExcelIcon fixedWidth />,
                          onClick: () => saveSpreadsheet(),
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
          primaryFields: [
            {
              fieldType: FieldType.textField,
              dataAddr: 'complaintNumber',
              label: 'Id',
              linkTo: d => `/compliance/customer-complaints/${d.parentValue.id}`,
              formatReadonly: (d: IFieldData<string>) => (
                <span>
                  {isOverdue(d.parentValue) ? <WarnIcon color="red" /> : undefined}
                  {d.fieldValue}
                </span>
              ),
            },
            {
              fieldType: FieldType.dateTimeField,
              dataAddr: 'complaintDateTime',
              label: 'Date of Complaint',
              orderBy: 'ComplaintDateTime',
              formatReadonly: d => <DateFormat value={d.fieldValue} />,
            },
            {
              fieldType: FieldType.textField,
              dataAddr: 'category',
              label: 'Category',
              orderBy: 'Category',
            },
            {
              fieldType: FieldType.textField,
              dataAddr: 'typeOfService',
              label: 'Type of Service',
              orderBy: 'JobTypeId',
            },
            {
              fieldType: FieldType.staffMemberField,
              dataAddr: 'staffMember',
              label: 'Staff Member',
            },
            {
              fieldType: FieldType.assetSelectField,
              valueKey: 'id',
              descriptionKey: 'name',
              dataAddr: 'asset',
              label: 'Bus',
            },
            {
              fieldType: FieldType.textField,
              dataAddr: 'severityLevel',
              label: 'Severity Level',
              orderBy: 'SeverityLevel',
            },
            {
              fieldType: FieldType.textField,
              dataAddr: 'status',
              label: 'Status',
              orderBy: 'Status',
              formatReadonly: d => (
                <span>
                  {customerComplaintStatusDescription(
                    (d.parentValue.status as CustomerComplaintStatus)!
                  )}
                </span>
              ),
            },
            {
              fieldType: FieldType.readonlyField,
              dataAddr: 'createdBy',
              label: 'Created By',
            },
          ],
          filterAction: {
            defaultValues: {
              statuses: [CustomerComplaintStatus.Open],
            },
            filterFields: Object.keys(filterFieldDefsLookup).map(k => filterFieldDefsLookup[k]),
            filterDef: filterDefApi => [
              {
                panes: [
                  {
                    paneType: PaneType.formFieldsPane,
                    columnCount: 2,
                    fields: [filterFieldDefsLookup.dateFrom, filterFieldDefsLookup.dateTo],
                  },
                  {
                    paneType: PaneType.formFieldsPane,
                    fields: [
                      filterFieldDefsLookup.categories,
                      filterFieldDefsLookup.typesOfService,
                    ],
                  },
                  {
                    paneType: PaneType.formFieldsPane,
                    fields: [
                      filterFieldDefsLookup.severityLevels,
                      filterFieldDefsLookup.drivers,
                      filterFieldDefsLookup.statuses,
                      filterFieldDefsLookup.isOverdue,
                      filterFieldDefsLookup.callerName,
                      filterFieldDefsLookup.details,
                    ],
                  },
                ],
              },
            ],
          },
        };
      }
    );

    const def = getPageDef(complaintModel.hasMoreItems);
    return <ListPage data={complaintModel.items} def={def} />;
  }
);

export default withQueryParams(ListCustomerComplaint);
