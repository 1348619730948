import { Link } from 'react-router-dom';
import styles from './DashboardTile.module.scss';

interface IDashboardTileProps {
  title?: string;
  content: React.ReactNode;
  linkTo?: string;
}

const DashboardTile: React.FC<IDashboardTileProps> = ({
  title,
  content,
  linkTo,
}: IDashboardTileProps) => {
  var titleBit = <h4 className={styles.title}>{title}</h4>;
  var contentBit = <div className={styles.container}>{content}</div>;

  return linkTo ? (
    <Link className={styles.tile} to={linkTo}>
      {titleBit}
      {contentBit}
    </Link>
  ) : (
    <div className={styles.tile}>
      {titleBit}
      {contentBit}
    </div>
  );
};

export default DashboardTile;
