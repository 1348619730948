import * as queryString from 'query-string';
import { buildListPageApiSearchModelTypedQuery } from 'src/domain/modelBuilders/buildListPageApiSearchModel';

type AuthorisedDeviceItem = Operations.Domain.Queries.AuthorisedDevices.ListAuthorisedDevices.AuthorisedDeviceItem;
type ListAuthorisedDevicesQuery = Operations.Domain.Queries.AuthorisedDevices.ListAuthorisedDevices.ListAuthorisedDevicesQuery;

export const ListAuthorisedDevicesModel = buildListPageApiSearchModelTypedQuery<
  ListAuthorisedDevicesQuery
>()('ListAuthorisedDevicesModel', d =>
  d.ajax.raw
    .get(`/api/operations/authorised-devices?${queryString.stringify(d.query)}`)
    .map(r => r.response as Common.Dtos.ListResult<AuthorisedDeviceItem>)
);
