import { IAjax } from 'src/infrastructure/ajax';
import { workshopUrls } from 'src/domain/services/apiUrls';

type CreateGoodsReceivedCommand = Workshop.Domain.Commands.Purchasing.CreateGoodsReceivedCommand;
type UpdateGoodsReceivedCommand = Workshop.Domain.Commands.Purchasing.UpdateGoodsReceivedCommand;
type CreateReturnedPartCreditCommand = Workshop.Domain.Commands.Purchasing.CreateReturnedPartCreditCommand;
type UpdateReturnedPartCreditCommand = Workshop.Domain.Commands.Purchasing.UpdateReturnedPartCreditCommand;
type GeneratePurchaseOrderBudgetReportExcelQuery = Workshop.Domain.Queries.Purchasing.GeneratePurchaseOrderBudgetReportExcel.GeneratePurchaseOrderBudgetReportExcelQuery;
type PurchaseOrderInvoice = Workshop.Domain.Queries.Purchasing.GetPurchaseOrderInvoices.PurchaseOrderInvoice;
type ListPurchaseOrdersForDropdownQuery = Workshop.Domain.Queries.Purchasing.ListPurchaseOrdersForDropdown.ListPurchaseOrdersForDropdownQuery;
type PurchaseOrderItemForDropdown = Workshop.Domain.Queries.Purchasing.ListPurchaseOrdersForDropdown.PurchaseOrderItemForDropdown;
type ExportPurchaseTransactionsToExcelQuery = Workshop.Domain.Queries.Purchasing.ExportPurchaseTransactions.ExportPurchaseTransactionsToExcelQuery;

// tslint:disable-next-line:no-empty
const returnVoid = () => {};

export default function init(ajax: IAjax) {
  function createPurchaseOrder(
    command: Workshop.Domain.Commands.Purchasing.CreatePurchaseOrderCommand
  ) {
    return ajax
      .post(workshopUrls.purchaseOrderUrls.createPurchaseOrder(), command)
      .toPromise()
      .then<string>(r => r.response);
  }

  function getPurchaseOrder(id: string) {
    return ajax
      .get(workshopUrls.purchaseOrderUrls.getPurchaseOrder(id))
      .toPromise()
      .then<Workshop.Domain.Queries.Purchasing.PurchaseOrderItem>(r => r.response);
  }

  function listPurchaseOrders(
    query: Partial<
      Workshop.Domain.Queries.Purchasing.GetPurchaseOrderList.GetPurchaseOrderListQuery
    >
  ) {
    return ajax
      .get(workshopUrls.purchaseOrderUrls.listPurchaseOrders(query))
      .map(
        r =>
          r.response as Common.Dtos.ListResult<
            Workshop.Domain.Queries.Purchasing.PurchaseOrderListItem
          >
      );
  }

  function updatePurchaseOrder(
    command: Workshop.Domain.Commands.Purchasing.UpdatePurchaseOrderCommand
  ) {
    return ajax
      .put(workshopUrls.purchaseOrderUrls.updatePurchaseOrder(command.id), command)
      .toPromise()
      .then(r => returnVoid());
  }

  function approvePurchaseOrder(id: string) {
    return ajax
      .post(workshopUrls.purchaseOrderUrls.approvePurchaseOrder(id))
      .toPromise()
      .then(r => returnVoid());
  }

  function reinstatePurchaseOrder(id: string) {
    return ajax
      .post(workshopUrls.purchaseOrderUrls.reinstatePurchaseOrder(id))
      .toPromise()
      .then(r => returnVoid());
  }

  function cancelPurchaseOrder(id: string) {
    return ajax
      .post(workshopUrls.purchaseOrderUrls.cancelPurchaseOrder(id))
      .toPromise()
      .then(r => returnVoid());
  }

  function completePurchaseOrder(id: string) {
    return ajax
      .post(workshopUrls.purchaseOrderUrls.completePurchaseOrder(id))
      .toPromise()
      .then(r => returnVoid());
  }

  function createGoodsReceived(command: CreateGoodsReceivedCommand) {
    return ajax
      .post(workshopUrls.purchaseOrderUrls.createGoodsReceived(command.purchaseOrderId), command)
      .toPromise()
      .then<number>(r => r.response);
  }

  function updateGoodsReceived(command: UpdateGoodsReceivedCommand) {
    return ajax
      .put(
        workshopUrls.purchaseOrderUrls.updateGoodsReceived(
          command.purchaseOrderId,
          command.goodsReceivedId
        ),
        command
      )
      .toPromise()
      .then(r => returnVoid());
  }

  function completePurchaseOrderLine(purchaseOrderId: string, purchaseOrderLineId: number) {
    return ajax
      .post(
        workshopUrls.purchaseOrderUrls.completePurchaseOrderLine(
          purchaseOrderId,
          purchaseOrderLineId
        )
      )
      .toPromise()
      .then(_ => returnVoid());
  }

  function generatePdf(purchaseOrderId: string) {
    return ajax
      .getFile(workshopUrls.purchaseOrderUrls.generatePdf(purchaseOrderId))
      .toPromise()
      .then<Blob>(r => r.response);
  }

  function createReturnedPartCredit(command: CreateReturnedPartCreditCommand) {
    return ajax
      .post(
        workshopUrls.purchaseOrderUrls.createReturnedPartCredit(
          command.purchaseOrderId,
          command.goodsReceivedId
        ),
        command
      )
      .toPromise()
      .then<number>(r => r.response);
  }

  function deleteReturnedPartCredit(
    purchaseOrderId: string,
    goodsReceivedId: number,
    creditNoteId: number,
    deleteReason: string
  ) {
    return ajax
      .httpDelete(
        workshopUrls.purchaseOrderUrls.deleteReturnedPartCredit(
          purchaseOrderId,
          goodsReceivedId,
          creditNoteId
        ),
        { purchaseOrderId, goodsReceivedId, creditNoteId, deleteReason }
      )
      .toPromise()
      .then(_ => returnVoid());
  }

  function updateReturnedPartCredit(command: UpdateReturnedPartCreditCommand) {
    return ajax
      .put(
        workshopUrls.purchaseOrderUrls.updateReturnedPartCredit(
          command.purchaseOrderId,
          command.goodsReceivedId,
          command.creditNoteId
        ),
        command
      )
      .toPromise()
      .then(_ => returnVoid());
  }

  function generatePurchaseOrderBudgetReport(query: GeneratePurchaseOrderBudgetReportExcelQuery) {
    return ajax
      .getFile(workshopUrls.purchaseOrderUrls.generatePurchaseOrderBudgetReport(query))
      .toPromise()
      .then<Blob>(r => r.response);
  }

  function exportPurchaseTransactionsToExcel(
    query: Partial<ExportPurchaseTransactionsToExcelQuery>
  ) {
    return ajax
      .getFile(workshopUrls.purchaseOrderUrls.exportPurchaseTransactionsToExcel(query))
      .toPromise()
      .then<Blob>(r => r.response);
  }

  function getPurchaseOrderInvoices(id: string) {
    return ajax
      .get(workshopUrls.purchaseOrderUrls.getPurchaseOrderInvoices(id))
      .toPromise()
      .then<PurchaseOrderInvoice>(r => r.response);
  }

  function listPurchaseOrdersForDropdown(query: Partial<ListPurchaseOrdersForDropdownQuery>) {
    return ajax
      .get(workshopUrls.purchaseOrderUrls.listPurchaseOrdersForDropdown(query))
      .map(r => r.response as Common.Dtos.ListResult<PurchaseOrderItemForDropdown>);
  }

  function findPurchaseOrders(purchaseOrderIds: string[]) {
    return ajax
      .get(workshopUrls.purchaseOrderUrls.findPurchaseOrders({ purchaseOrderIds }))
      .toPromise()
      .then<PurchaseOrderItemForDropdown[]>(r => r.response);
  }

  function listPurchaseTransactions(
    query: Partial<
      Workshop.Domain.Queries.Purchasing.GetPurchaseTransactionsList.GetPurchaseTransactionsListQuery
    >
  ) {
    return ajax
      .get(workshopUrls.purchaseOrderUrls.listPurchaseTransactions(query))
      .map(
        r =>
          r.response as Common.Dtos.ListResult<
            Workshop.Domain.Queries.Purchasing.GetPurchaseTransactionsList.PurchaseTransactionsListItem
          >
      );
  }

  function markGoodsReceivedAsExported(purchaseOrderId: string, goodsReceivedId: number) {
    return ajax
      .post(
        workshopUrls.purchaseOrderUrls.markGoodsReceivedAsExported(purchaseOrderId, goodsReceivedId)
      )
      .toPromise()
      .then(_ => returnVoid());
  }

  function removeExportedIndicatorFromGoodsReceived(
    purchaseOrderId: string,
    goodsReceivedId: number
  ) {
    return ajax
      .post(
        workshopUrls.purchaseOrderUrls.removeExportedIndicatorFromGoodsReceived(
          purchaseOrderId,
          goodsReceivedId
        )
      )
      .toPromise()
      .then(_ => returnVoid());
  }

  function markCreditNoteAsExported(
    purchaseOrderId: string,
    goodsReceivedId: number,
    creditNoteId: number
  ) {
    return ajax
      .post(
        workshopUrls.purchaseOrderUrls.markCreditNoteAsExported(
          purchaseOrderId,
          goodsReceivedId,
          creditNoteId
        )
      )
      .toPromise()
      .then(_ => returnVoid());
  }

  function removeExportedIndicatorFromCreditNote(
    purchaseOrderId: string,
    goodsReceivedId: number,
    creditNoteId: number
  ) {
    return ajax
      .post(
        workshopUrls.purchaseOrderUrls.removeExportedIndicatorFromCreditNote(
          purchaseOrderId,
          goodsReceivedId,
          creditNoteId
        )
      )
      .toPromise()
      .then(_ => returnVoid());
  }

  return {
    purchaseOrders: {
      listPurchaseOrders,
      createPurchaseOrder,
      getPurchaseOrder,
      updatePurchaseOrder,
      approvePurchaseOrder,
      reinstatePurchaseOrder,
      cancelPurchaseOrder,
      completePurchaseOrder,
      createGoodsReceived,
      updateGoodsReceived,
      completePurchaseOrderLine,
      generatePdf,
      createReturnedPartCredit,
      updateReturnedPartCredit,
      generatePurchaseOrderBudgetReport,
      getPurchaseOrderInvoices,
      deleteReturnedPartCredit,
      listPurchaseOrdersForDropdown,
      findPurchaseOrders,
      listPurchaseTransactions,
      exportPurchaseTransactionsToExcel,
      markGoodsReceivedAsExported,
      removeExportedIndicatorFromGoodsReceived,
      markCreditNoteAsExported,
      removeExportedIndicatorFromCreditNote,
    },
  };
}
