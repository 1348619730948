import { PaneType, SectionDefs } from 'src/views/definitionBuilders/types';
import { DateTime } from 'luxon';
import { Link } from 'react-router-dom';
import TimezoneFormat from 'src/views/components/DateTimeFormat/TimezoneFormat';

type ActivityLogTransaction = Operations.Domain.Queries.GetActivityLog.ActivityLogTransaction;
type ActivityLogDetails = Operations.Domain.Queries.GetActivityLog.ActivityLogDetails;

interface IActivityLogLinkProps {
  jobId: string;
  activityLog: ActivityLogDetails;
}

function getAggregateLink(aggregateType: string, aggregateId?: string) {
  if (!aggregateId) {
    return null;
  }

  switch (aggregateType) {
    case 'Job':
      return `/operations/jobs/${aggregateId}`;
    case 'Shift':
      return `/operations/shifts/${aggregateId}`;
    case 'RailTemplate':
      return `/operations/rail/rail-templates/${aggregateId}`;
    case 'Quote':
      return `/operations/bookings-for-ops/${aggregateId}`;
    default:
      return null;
  }
}

function getAggregateTypeDescription(aggregateType: string) {
  switch (aggregateType) {
    case 'Job':
      return 'Job';
    case 'Shift':
      return 'Shift';
    case 'RailTemplate':
      return 'Rail Template';
    case 'Quote':
      return 'Booking';
    default:
      return null;
  }
}

const ActivityLogLink: React.FC<IActivityLogLinkProps> = ({ jobId, activityLog }) => {
  const link = getAggregateLink(activityLog.aggregateType, activityLog.aggregateId);

  if (link !== null && jobId !== activityLog.aggregateId) {
    return <Link to={link}>Go To {getAggregateTypeDescription(activityLog.aggregateType)}</Link>;
  }

  const sourceLink = getAggregateLink(
    activityLog.sourceAggregateType,
    activityLog.sourceAggregateId
  );
  if (sourceLink !== null) {
    return (
      <Link to={sourceLink}>
        Go To Source {getAggregateTypeDescription(activityLog.sourceAggregateType)}
      </Link>
    );
  }

  return null;
};

export default function getActivityLogPanelDef(
  jobId: string,
  activityLogs: ActivityLogTransaction[]
): SectionDefs {
  return {
    title: 'Activity',
    hidden: !jobId,
    explicitData: activityLogs,
    panels: [
      {
        panes: [
          {
            paneType: PaneType.repeatingPane,
            itemPanes: [
              {
                paneType: PaneType.customPane,
                dataAddr: 'transactionId',
                render: api => {
                  const log = api.data.parentValue as ActivityLogTransaction;
                  const createdOnDateTime = DateTime.fromISO(log.createdOn);
                  const formattedCreatedOn = createdOnDateTime.toLocaleString(
                    DateTime.DATETIME_MED_WITH_SECONDS
                  );
                  const linedComment = (item: ActivityLogDetails) => item.comment.split('\n');
                  return (
                    <div className="activity-log-for-job-pane">
                      <div className="created-by">{log.createdBy}</div>
                      <div className="created-on">
                        {formattedCreatedOn}{' '}
                        <TimezoneFormat value={createdOnDateTime} blankForDefaultZone />
                      </div>
                      <ul className="log-items list-unstyled">
                        {log.items.map((item: ActivityLogDetails) => {
                          return (
                            <li key={item.activityLogId}>
                              <div>
                                <strong>{item.description}</strong>
                              </div>
                              <div>
                                {linedComment(item).map((c, idx) => (
                                  <em key={idx}>{c}</em>
                                ))}
                              </div>
                              <ActivityLogLink jobId={jobId} activityLog={item} />
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  );
                },
              },
            ],
          },
        ],
      },
    ],
  };
}
