import { IAjax } from 'src/infrastructure/ajax';
import {
  AggregatesWithAttachments,
  AggregateTypeToUrlSegment,
  getFileNameFromResponse,
  SwapAttachmentOrderData,
} from 'src/views/components/Attachment/attachmentHelper';
import { apiUrls } from 'src/domain';
import saveAs from 'file-saver';

type DeleteAttachmentCommand = Common.Attachments.DeleteAttachmentCommandBase;
type UpdateAttachmentCommand = Common.Attachments.UpdateAttachmentCommandBase;
type AttachmentDto = Workshop.Domain.Queries.Attachment.WorkshopAttachmentDetailsDto;
type AddAttachmentCommand = Common.Attachments.AddAttachmentCommandBase;

export default function init(ajax: IAjax) {
  function addAttachment(command: AddAttachmentCommand, aggregateType: AggregatesWithAttachments) {
    return ajax
      .post(
        apiUrls.workshopAttachmentUrls.addAttachment(
          command.aggregateId,
          AggregateTypeToUrlSegment[aggregateType]
        ),
        command
      )
      .toPromise();
  }

  function loadAttachments(aggregateId: string, aggregateType: AggregatesWithAttachments) {
    return ajax
      .get(
        apiUrls.workshopAttachmentUrls.loadAttachments(
          aggregateId,
          AggregateTypeToUrlSegment[aggregateType]
        )
      )
      .toPromise()
      .then<AttachmentDto>(r => r.response);
  }

  function downloadAttachment(
    attachmentDetailsId: number,
    aggregateId: string,
    aggregateType: AggregatesWithAttachments
  ) {
    return ajax
      .getFile(
        apiUrls.workshopAttachmentUrls.downloadAttachment(
          attachmentDetailsId,
          aggregateId,
          AggregateTypeToUrlSegment[aggregateType]
        )
      )
      .toPromise()
      .then(r => {
        const fileName = getFileNameFromResponse(r);
        saveAs(r.response, fileName);
      });
  }

  function deleteAttachment(
    command: DeleteAttachmentCommand,
    aggregateType: AggregatesWithAttachments
  ) {
    return ajax
      .httpDelete(
        apiUrls.workshopAttachmentUrls.deleteAttachment(
          command.attachmentDetailsId,
          command.aggregateId,
          AggregateTypeToUrlSegment[aggregateType]
        ),
        command
      )
      .toPromise();
  }

  function updateAttachment(
    command: UpdateAttachmentCommand,
    aggregateType: AggregatesWithAttachments
  ) {
    return ajax
      .put(
        apiUrls.workshopAttachmentUrls.updateAttachment(
          command.attachmentDetailsId,
          command.aggregateId,
          AggregateTypeToUrlSegment[aggregateType]
        ),
        command
      )
      .toPromise();
  }

  function swapAttachmentOrder(command: SwapAttachmentOrderData) {
    return ajax
      .put(
        apiUrls.workshopAttachmentUrls.swapAttachments(
          command.aggregateId,
          AggregateTypeToUrlSegment[command.aggregateType]
        ),
        command
      )
      .toPromise();
  }

  return {
    attachments: {
      addAttachment,
      updateAttachment,
      deleteAttachment,
      loadAttachments,
      downloadAttachment,
      swapAttachmentOrder,
    },
  };
}
