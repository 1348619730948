import {
  allLicenceClass,
  allLicenceCondition,
  LicenceClass as LicenceClassEnum,
} from 'src/api/enums';
import { GetDAAndDL } from 'src/views/components/LicenceTypesHelper';
import { getSubmitCloseModalActionGroupDef } from 'src/views/definitionBuilders/common';
import { PaneType, FieldType, ModalDefBuilder } from 'src/views/definitionBuilders/types';

type CreateStaffMemberLicenceCommand = People.Domain.Commands.StaffMembers.CreateStaffMemberLicence.CreateStaffMemberLicenceCommand;
type UpdateStaffMemberLicenceCommand = People.Domain.Commands.StaffMembers.UpdateStaffMemberLicence.UpdateStaffMemberLicenceCommand;
type StaffMemberLicenceItem = People.Domain.Queries.ViewStaffMember.StaffMemberItem.StaffMemberLicenceItem;
type LicenceClass = Common.AggregatesModel.People.StaffMemberAggregate.LicenceClass;
type LicenceCondition = Common.AggregatesModel.People.StaffMemberAggregate.LicenceCondition;
type LicenceType = Common.AggregatesModel.People.StaffMemberAggregate.LicenceType;

export const AUTH_CLASSES = [
  LicenceClassEnum.Hire,
  LicenceClassEnum.General,
  LicenceClassEnum.Restricted,
];

export const getLicenceClasses = (allLicenceTypes: LicenceType[]) => {
  const { DA, DL } = GetDAAndDL(allLicenceTypes);
  return allLicenceClass.map(c => ({
    id: c.value,
    description: c.description,
    classCode: '',
    licenceTypeId: AUTH_CLASSES.some(a => a === c.value) ? DA?.id || 0 : DL?.id || 0,
  }));
};

export default function getLicenceModalDef(
  mode: 'add' | 'edit',
  allLicenceTypes: LicenceType[],
  licences: StaffMemberLicenceItem[] | undefined,
  staffMemberId: string,
  createLicence: (cmd: CreateStaffMemberLicenceCommand) => Promise<void>,
  updateLicence: (cmd: UpdateStaffMemberLicenceCommand) => Promise<void>
): ModalDefBuilder {
  const currentLicenceTypeIds = licences ? licences.map(l => l.licenceTypeId) : [];

  const { DL } = GetDAAndDL(allLicenceTypes);

  const allClasses: Array<LicenceClass> = getLicenceClasses(allLicenceTypes);

  const allConditions: Array<LicenceCondition> = allLicenceCondition.map(c => ({
    id: c.value,
    conditionCode: '',
    description: c.description,
    licenceTypeId: DL?.id || 0,
  }));

  return modalDefApi => ({
    title: `${mode === 'add' ? 'Add' : 'Edit'} Licence`,
    asForm: true,
    explicitData: mode === 'add' ? {} : modalDefApi.actionData.paneValue,
    panels: [
      {
        panes: [
          {
            paneType: PaneType.formFieldsPane,
            columnCount: 3,
            fields: [
              {
                fieldType: FieldType.selectField,
                label: 'Licence Type',
                dataAddr: 'licenceTypeId',
                optionItems: allLicenceTypes,
                valueKey: 'id',
                descriptionKey: 'description',
                useValueOnly: true,
                mandatory: true,
                readonly: mode === 'edit',
                valuesToDisable: currentLicenceTypeIds,
                onChange: api => {
                  const licencePath = api.fieldDataAddr.slice(0, -1);
                  api.setFormValue([...licencePath, 'licenceClass'], undefined);
                  api.setFormValue([...licencePath, 'conditions'], []);
                },
              },
              {
                fieldType: FieldType.selectField,
                label: 'Licence Class',
                dataAddr: 'licenceClass',
                optionItems: d =>
                  allClasses.filter(c => c.licenceTypeId === d.parentValue.licenceTypeId),
                valueKey: 'id',
                descriptionKey: 'description',
                mandatory: true,
                hidden: d =>
                  !allClasses.filter(c => c.licenceTypeId === d.parentValue.licenceTypeId).length,
              },
              {
                fieldType: FieldType.selectMultiField,
                label: 'Conditions',
                dataAddr: 'conditions',
                optionItems: d =>
                  allConditions.filter(c => c.licenceTypeId === d.parentValue.licenceTypeId),
                valueKey: 'id',
                descriptionKey: 'description',
                hidden: d =>
                  !allConditions.filter(c => c.licenceTypeId === d.parentValue.licenceTypeId)
                    .length,
              },
            ],
          },
          {
            paneType: PaneType.formFieldsPane,
            columnCount: 3,
            fields: [
              {
                fieldType: FieldType.textField,
                label: 'Licence Number',
                dataAddr: 'licenceNumber',
                mandatory: true,
                maxLength: 50,
                validate: d => {
                  if (!d.fieldValue) {
                    return undefined;
                  }
                  if (d.fieldValue.match(/\s+/)) {
                    return 'Licence Number may not contain spaces';
                  }
                  return undefined;
                },
              },
              {
                fieldType: FieldType.dateField,
                label: 'Expiry',
                dataAddr: 'expiry',
                mandatory: d =>
                  d.parentValue.licenceTypeId &&
                  allLicenceTypes.find(l => l.id === d.parentValue.licenceTypeId)!.requiresExpiry,
              },
            ],
          },
          {
            paneType: PaneType.formFieldsPane,
            fields: [
              {
                fieldType: FieldType.textAreaField,
                label: 'Notes',
                dataAddr: 'notes',
              },
            ],
          },
        ],
      },
    ],
    secondaryActions: [getSubmitCloseModalActionGroupDef('Ok')],
    onFormPreSubmit: (
      licence: StaffMemberLicenceItem
    ): CreateStaffMemberLicenceCommand | UpdateStaffMemberLicenceCommand => {
      return {
        staffMemberId: staffMemberId,
        licenceId: licence.id,
        licenceTypeId: licence.licenceTypeId,
        licenceClassId: licence.licenceClass && licence.licenceClass.id,
        licenceNumber: licence.licenceNumber,
        expiry: licence.expiry,
        notes: licence.notes,
        conditions: licence.conditions && licence.conditions.map(c => c.id),
      };
    },
    onFormSubmit: mode === 'add' ? createLicence : updateLicence,
  });
}
