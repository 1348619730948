import { types, getRoot, flow } from 'mobx-state-tree';
import { getAjax } from 'src/domain/services';
import { IRootStoreModel } from 'src/domain/entities/RootStoreModel';

type CreateRoleCommand = People.Domain.Commands.Role.CreateRole.CreateRoleCommand;
type UpdateRoleCommand = People.Domain.Commands.Role.UpdateRole.UpdateRoleCommand;
type RoleItem = People.Domain.Queries.Role.RoleItem;
type StaffMemberName = Common.Queries.GetStaffMemberNames.StaffMemberName;
type AssignStaffMembersToRoleCommand = People.Domain.Commands.Role.AssignStaffMembersToRole.AssignStaffMembersToRoleCommand;

export const RoleModel = types
  .model('RoleModel', {
    role: types.maybe(types.frozen<RoleItem>()),
    staffMembers: types.array(types.frozen<StaffMemberName>()),
  })
  .actions(self => {
    const ajax = getAjax(self);
    const root = getRoot(self) as IRootStoreModel;

    const createRole = flow(function*(command: CreateRoleCommand) {
      const id = yield ajax.people.role.createRole(command);
      root.history.push(`/people/roles/${id}`);
    });

    const loadRole = flow(function*(roleId: string) {
      self.role = yield ajax.people.role.loadRole(roleId);
    });

    const updateRole = flow(function*(command: UpdateRoleCommand) {
      yield ajax.people.role.updateRole(command);
      yield loadRole(command.id);
    });

    const deleteRole = flow(function*(roleId: string) {
      yield ajax.people.role.deleteRole(roleId);
      root.history.push(`/people/roles?defaultFilter=true`);
    });

    const checkForUniqueName = flow(function*(name: string) {
      return yield ajax.people.role.checkForUniqueName(name);
    });

    const listStaffMembers = flow(function*(roleId: string) {
      self.staffMembers = yield ajax.people.role.listStaffMembers(roleId);
    });

    const assignStaffMembersToRole = flow(function*(command: AssignStaffMembersToRoleCommand) {
      yield ajax.people.role.assignStaffMembersToRole(command);
      yield listStaffMembers(command.roleId);
    });

    return {
      createRole,
      checkForUniqueName,
      loadRole,
      updateRole,
      deleteRole,
      listStaffMembers,
      assignStaffMembersToRole,
    };
  });
