import { DateTime } from 'luxon';
import {
  allJobStatus,
  getJobTypeDescriptor,
  allProgressWarningReason,
  JobStatus,
} from 'src/api/enums';
import memoizeOne from 'src/infrastructure/memoizeOne';
import { ListPageLoadCause, AssetSubcategoryType } from 'src/domain';
import {
  FieldType,
  FieldDefs,
  PaneType,
  PagePrimarySize,
  ActionType,
} from 'src/views/definitionBuilders/types';
import ListPage, { IListPageDef } from 'src/views/components/Page/pages/ListPage';
import withQueryParams, { IQueryParamsProps } from 'src/views/hocs/withQueryParams';
import { ExcelIcon, CheckIcon } from 'src/images/icons';
import { Link } from 'react-router-dom';
import { DateTimeFormat } from 'src/views/components/DateTimeFormat';
import { JobTypesThatCanHaveProgressWarnings } from 'src/views/routes/operations/shared/jobTypeHelpers';
import Acknowledgement from '../listJob/Acknowledgement';
import ProgressWarningBadge from './ProgressWarningBadge';
import './ListJobProgressWarnings.scss';
import { IListPageLoadDataRequest } from 'src/domain/baseTypes';
import { useEffect, useState } from 'react';
import saveAs from 'file-saver';
import PrimaryTitle from 'src/views/components/Page/PrimaryTitle/PrimaryTitle';

type ListJobProgressWarningsQuery = Operations.Domain.Queries.ListJobProgressWarnings.ListJobProgressWarningsQuery;
type JobProgressWarningItem = Operations.Domain.Queries.ListJobProgressWarnings.JobProgressWarningItem;
type StaffMemberDto = Common.Dtos.StaffMemberDto;
type AssetItem = Common.Queries.Workshop.GetFleetAssetList.AssetItem;

const getPrimaryFields = (
  dismissProgressWarning: (jobId: string) => Promise<void>,
  recordsBeingDismissed: { [key: string]: boolean }
): IListPageDef['primaryFields'] => {
  return [
    {
      fieldType: FieldType.readonlyField,
      dataAddr: 'jobNumber',
      orderBy: 'JobNumber',
      label: 'Job',
      columnWidth: '1px',
      linkTo: d => `/operations/jobs/${d.parentValue.jobId}`,
      formatReadonly: d => {
        const job = d.parentValue as JobProgressWarningItem;
        return `${job.jobNumber}${job.isCancelled ? ' (Cancelled)' : ''}`;
      },
    },
    {
      fieldType: FieldType.readonlyField,
      dataAddr: 'jobType',
      label: 'Job Type',
      formatReadonly: d => {
        const job = d.parentValue as JobProgressWarningItem;
        const jobTypeDescriptor = getJobTypeDescriptor(job.jobType.id);
        return jobTypeDescriptor.description;
      },
    },
    {
      fieldType: FieldType.textField,
      dataAddr: 'shiftName',
      label: 'Shift',
      orderBy: 'ShiftName',
      columnWidth: '1px',
    },
    {
      fieldType: FieldType.textField,
      dataAddr: 'description',
      label: 'Description',
      columnWidth: '15em',
    },
    {
      fieldType: FieldType.dateTimeField,
      dataAddr: 'clockOn',
      label: 'Clock On',
      orderBy: 'ClockOn',
      formatReadonly: d => {
        return <DateTimeFormat value={d.fieldValue} />;
      },
      hidden: d => {
        const job = d.parentValue as JobProgressWarningItem;
        return job.isContinuingFrom || job.isVehicleSwappedFrom;
      },
    },
    {
      fieldType: FieldType.dateTimeField,
      dataAddr: 'departDepot',
      label: 'Depart',
      orderBy: 'DepartDepot',
      formatReadonly: d => (
        <DateTimeFormat value={d.fieldValue} previousValue={d.parentValue.clockOn} />
      ),
      hidden: d =>
        (d.parentValue as JobProgressWarningItem).isContinuingFrom ||
        (d.parentValue as JobProgressWarningItem).isVehicleSwappedFrom,
    },
    {
      fieldType: FieldType.dateTimeField,
      dataAddr: 'shiftCommence',
      label: 'Commence',
      orderBy: 'ShiftCommence',
      columnWidth: '1px',
      formatReadonly: d => (
        <DateTimeFormat
          value={d.fieldValue}
          previousValue={
            (d.parentValue as JobProgressWarningItem).isContinuingFrom ||
            (d.parentValue as JobProgressWarningItem).isVehicleSwappedFrom
              ? undefined
              : d.parentValue.departDepot
          }
        />
      ),
    },
    {
      fieldType: FieldType.assetSelectField,
      label: 'Vehicle',
      dataAddr: 'asset',
      valueKey: 'id',
      descriptionKey: 'name',
      columnWidth: '1px',
      formatReadonly: d => {
        const job = d.parentValue as JobProgressWarningItem;
        if (job.asset && job.asset.id) {
          return <span>{job.asset.name}</span>;
        }
        return null;
      },
    },
    {
      fieldType: FieldType.readonlyField,
      label: 'Staff Member',
      dataAddr: 'staffMemberName',
      formatReadonly: d => {
        const job = d.parentValue as JobProgressWarningItem;
        return (
          <>
            {d.fieldValue ? (
              <div>
                <Link to={`/people/staff-members/${d.parentValue.staffMemberId}`}>
                  {d.fieldValue}
                </Link>
              </div>
            ) : null}
            {job.secondStaffMemberId ? (
              <div>
                <Link to={`/people/staff-members/${job.secondStaffMemberId}`}>
                  {job.secondStaffMemberName}
                </Link>
              </div>
            ) : null}
          </>
        );
      },
    },
    {
      fieldType: FieldType.readonlyField,
      label: 'Job Status',
      formatReadonly: d => {
        const job = d.parentValue as JobProgressWarningItem;
        return (
          <Acknowledgement
            id={job.jobNumber}
            clockOn={job.clockOn}
            jobStatus={job.jobStatus}
            jobProgress={job.latestJobProgressId}
            isVehicleSwapped={job.isVehicleSwapped}
          />
        );
      },
    },
    {
      fieldType: FieldType.readonlyField,
      label: ' Progress Warnings',
      formatReadonly: d => {
        const job = d.parentValue as JobProgressWarningItem;
        return job.progressWarningReasons.map(x => {
          return <ProgressWarningBadge key={`${job.jobId}_${x}`} warningReason={x} />;
        });
      },
    },
    {
      fieldType: FieldType.actionListField,
      dataAddr: '',
      columnWidth: '1px',
      label: 'Dismiss',
      actionGroups: [
        {
          actions: [
            {
              actionType: ActionType.actionButton,
              label: 'Dismiss',
              hidden: d => d.parentValue.progressWarningDismissed,
              disabled: d => recordsBeingDismissed[d.parentValue.jobId],
              icon: <CheckIcon fixedWidth />,
              className: 'dismiss',
              onClick: props => {
                dismissProgressWarning(props.parentValue.jobId);
              },
            },
          ],
        },
      ],
    },
  ];
};

export interface IListJobProgressWarningsProps {
  canManageJobs: boolean;
  listJobProgressWarnings: (
    request: IListPageLoadDataRequest<ListJobProgressWarningsQuery>
  ) => Promise<void>;
  jobProgressWarnings: JobProgressWarningItem[];
  hasMoreData: boolean;
  fleetAssets: AssetItem[];
  loadFleetAssets: () => Promise<void>;

  activeStaffMembers: StaffMemberDto[];
  loadAllStaffMembers: () => Promise<void>;

  exportToExcel: (query: Partial<ListJobProgressWarningsQuery>) => Promise<Blob>;
  dismissProgressWarning: (jobId: string) => Promise<void>;
}

type InternalProps = IListJobProgressWarningsProps &
  IQueryParamsProps<ListJobProgressWarningsQuery>;

const ListJobProgressWarnings: React.FC<InternalProps> = (props: InternalProps) => {
  const [isLoadingFilters, setIsLoadingFilters] = useState<boolean>(false);
  const [recordsBeingDismissed, setRecordsBeingDismissed] = useState<{ [key: string]: boolean }>(
    {}
  );

  useEffect(() => {
    setIsLoadingFilters(true);
    Promise.all([props.loadAllStaffMembers(), props.loadFleetAssets()]).then(() =>
      setIsLoadingFilters(false)
    );
  }, []);

  const saveSpreadsheet = () => {
    const fileName = `Progress_Warnings_${DateTime.local().toFormat('yyyyMMddHHmm')}.xlsx`;
    return props.exportToExcel(props.getQueryParams()).then(blob => saveAs(blob, fileName));
  };

  const getRowKey: IListPageDef['rowKey'] = data => {
    return data.itemValue.id;
  };

  const getPageDef = memoizeOne(
    (
      listJobs: IListJobProgressWarningsProps['listJobProgressWarnings'],
      hasMoreData: boolean,
      isLoadingFilters,
      primaryFieldDefinitions: IListPageDef['primaryFields']
    ): IListPageDef => {
      const today = DateTime.local().toISODate();
      const yesterday = DateTime.local()
        .plus({
          days: -1,
        })
        .toISODate();
      return {
        primaryTitle: <PrimaryTitle title="Jobs Progress Warnings"></PrimaryTitle>,
        onLoadData: listJobs,
        externalSearch: true,
        primarySize: PagePrimarySize.full,
        hasMoreData: hasMoreData,
        rowKey: getRowKey,
        primaryActions: [
          {
            actions: [
              {
                actionType: ActionType.actionCollection,
                actionGroups: [
                  {
                    actions: [
                      {
                        actionType: ActionType.actionButton,
                        label: 'Export all data to Excel',
                        icon: <ExcelIcon fixedWidth />,
                        onClick: saveSpreadsheet,
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
        primaryFields: primaryFieldDefinitions,
        filterAction: {
          disabled: isLoadingFilters,
          defaultValues: {
            dateTo: today,
            dateFrom: yesterday,
          },
          filterFields: Object.keys(filterFieldDefsLookup).map(k => filterFieldDefsLookup[k]),
          filterDef: filterDefApi => [
            {
              panes: [
                {
                  paneType: PaneType.formFieldsPane,
                  fields: [filterFieldDefsLookup.jobType, filterFieldDefsLookup.jobStatus],
                },
                {
                  paneType: PaneType.formFieldsPane,
                  columnCount: 2,
                  fields: [filterFieldDefsLookup.dateFrom, filterFieldDefsLookup.dateTo],
                },
                {
                  paneType: PaneType.formFieldsPane,
                  fields: [
                    filterFieldDefsLookup.staffMembers,
                    filterFieldDefsLookup.vehicles,
                    filterFieldDefsLookup.warningReasons,
                    filterFieldDefsLookup.showDismissedWarnings,
                  ],
                },
              ],
            },
          ],
        },
      };
    }
  );

  const {
    jobProgressWarnings,
    listJobProgressWarnings,
    hasMoreData,
    activeStaffMembers,
    fleetAssets,
    dismissProgressWarning,
  } = props;

  const filterFieldDefsLookup = {
    jobType: {
      fieldType: FieldType.selectMultiField,
      dataAddr: 'jobTypeIds',
      label: 'Job Type',
      valueKey: 'value',
      descriptionKey: 'description',
      optionItems: JobTypesThatCanHaveProgressWarnings,
      useValueOnly: true,
    } as FieldDefs,
    jobStatus: {
      fieldType: FieldType.selectMultiField,
      dataAddr: 'jobStatusIds',
      label: 'Job Status',
      valueKey: 'value',
      descriptionKey: 'description',
      optionItems: allJobStatus.filter(
        x => x.value === JobStatus.Acknowledged || x.value === JobStatus.Completed
      ),
      useValueOnly: true,
    } as FieldDefs,
    dateFrom: {
      fieldType: FieldType.dateField,
      dataAddr: 'dateFrom',
      label: 'Date From',
      onBlur: api => {
        if (!api.formValues.dateTo) {
          api.setFormValue(['dateTo'], api.fieldData.fieldValue);
        }
        api.validateField(['dateTo']);
      },
    } as FieldDefs,
    dateTo: {
      fieldType: FieldType.dateField,
      dataAddr: 'dateTo',
      label: 'Date To',
      validate: d => {
        if (!d.fieldValue || !d.parentValue.dateFrom) {
          return undefined;
        }
        const from = DateTime.fromISO(d.parentValue.dateFrom);
        const to = DateTime.fromISO(d.fieldValue);
        return from > to ? 'Date To cannot be earlier than Date From' : undefined;
      },
    } as FieldDefs,
    staffMembers: {
      fieldType: FieldType.selectMultiField,
      dataAddr: 'staffMembers',
      label: 'Staff Members',
      valueKey: 'id',
      descriptionKey: 'name',
      optionItems: activeStaffMembers,
      useValueOnly: true,
    } as FieldDefs,
    vehicles: {
      fieldType: FieldType.selectMultiField,
      dataAddr: 'vehicles',
      label: 'Vehicles',
      valueKey: 'id',
      descriptionKey: 'name',
      optionItems: () => {
        if (!fleetAssets) {
          return fleetAssets;
        }
        return fleetAssets.filter(x => x.subcategoryId === AssetSubcategoryType.Bus);
      },
      useValueOnly: true,
    } as FieldDefs,
    warningReasons: {
      fieldType: FieldType.selectMultiField,
      dataAddr: 'warningReasons',
      label: 'Progress Warning Reasons',
      valueKey: 'value',
      descriptionKey: 'description',
      optionItems: allProgressWarningReason,
      useValueOnly: true,
    } as FieldDefs,
    showDismissedWarnings: {
      fieldType: FieldType.yesNoField,
      dataAddr: 'showDismissedWarnings',
      label: 'Progress Warning Has Been Dismissed',
      valueKey: 'value',
    } as FieldDefs,
  };

  const dismissAndRefresh = (jobId: string) => {
    setRecordsBeingDismissed({ ...recordsBeingDismissed, [jobId]: true });

    return dismissProgressWarning(jobId).then(r => {
      props
        .listJobProgressWarnings({
          query: { ...props.getQueryParams() },
          loadCause: ListPageLoadCause.refresh,
        })
        .finally(() => setRecordsBeingDismissed({ ...recordsBeingDismissed, [jobId]: false }));
    });
  };

  const def = getPageDef(
    listJobProgressWarnings,
    hasMoreData,
    isLoadingFilters,
    getPrimaryFields(dismissAndRefresh, recordsBeingDismissed)
  );

  return (
    <ListPage
      className="list-job-progress-warnings-component"
      data={jobProgressWarnings}
      def={def}
    />
  );
};

export default withQueryParams(ListJobProgressWarnings);
