import { types, flow } from 'mobx-state-tree';
import { getAjax } from 'src/domain/services/storeEnvironment';
import { DateTime } from 'luxon';
import { WorkshopShiftType } from 'src/api/enums';

type WorkshopShiftDetailsDto = Workshop.Domain.Queries.GetShiftDetails.WorkshopShiftDetailsDto;
type ShiftDetails = Workshop.Domain.Queries.GetShiftDetailForKiosk.ShiftDetails;

export const WorkshopShiftDetailsModel = types
  .model('WorkshopShiftDetailsModel', {
    workshopShiftDetails: types.array(types.frozen<WorkshopShiftDetailsDto>()),
    shiftDetail: types.maybe(types.frozen<ShiftDetails>()),
  })
  .views(self => ({
    get closestClockOff() {
      const now = DateTime.local();
      const closests = self.workshopShiftDetails
        .filter(x => DateTime.fromISO(x.start) <= now && x.shiftType !== WorkshopShiftType.OnCall)
        .map(x => ({
          item: x,
          diffNow:
            DateTime.fromISO(x.end) <= now
              ? 0
              : Math.abs(DateTime.fromISO(x.end).diff(now, 'minutes').minutes),
        }))
        .sort(x => x.diffNow);

      if (closests && closests.length > 0) {
        return closests[0].item;
      }

      return undefined;
    },

    get closestClockOn() {
      const now = DateTime.local();
      const closests = self.workshopShiftDetails
        .filter(x => x.shiftType !== WorkshopShiftType.OnCall)
        .map(x => ({
          item: x,
          diffNow: Math.abs(DateTime.fromISO(x.start).diff(now, 'minutes').minutes),
        }))
        .sort(x => x.diffNow);

      if (closests && closests.length > 0) {
        return closests[0].item;
      }

      return undefined;
    },
  }))
  .actions(self => {
    const ajax = getAjax(self);

    const loadShiftDetails = flow(function*(date?: DateTime) {
      self.workshopShiftDetails = yield ajax.raw
        .get(`/api/kiosk/workshop/shift-for-staff${date ? `?date=${date.toISODate()}` : ''}`)
        .toPromise()
        .then<WorkshopShiftDetailsDto[]>(r => r.response);
    });

    return {
      loadShiftDetails,
    };
  });
