import { Component } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import {
  PagePrimarySize,
  PaneType,
  FieldType,
  ActionType,
} from 'src/views/definitionBuilders/types';
import CrudPage, { CrudPageMode, ICrudPageDef } from 'src/views/components/Page/pages/CrudPage';
import {
  allCustomerComplaintCategory,
  allCustomerComplaintSeverityLevel,
  allCustomerComplaintRequiredness,
  CustomerComplaintStatus,
  getJobTypeDescriptor,
  ICustomerComplaintStatusDescriptor,
  getCustomerComplaintStatusDescriptor,
  CustomerComplaintCategory,
} from 'src/api/enums';
import { CustomerComplaintJobTypes } from 'src/views/routes/operations/shared/jobTypeHelpers';
import { FlagCheckered } from 'src/images/icons';
import { StaffMemberFilter } from 'src/views/components/Page/fields/StaffMemberField';

type AssetItem = Common.Queries.Workshop.GetSimpleFleetAssetList.SimpleAssetItem;
type CustomerComplaintItem = Operations.Domain.Queries.ViewCustomerComplaint.CustomerComplaintItem;
type CreateCustomerComplaintCommand = Operations.Domain.Commands.CustomerComplaint.CreateCustomerComplaint.CreateCustomerComplaintCommand;
type UpdateCustomerComplaintCommand = Operations.Domain.Commands.CustomerComplaint.UpdateCustomerComplaint.UpdateCustomerComplaintCommand;
type CloseCustomerComplaintCommand = Operations.Domain.Commands.CustomerComplaint.CloseCustomerComplaint.CloseCustomerComplaintCommand;

export interface IMaintainCustomerComplaintProps {
  mode: CrudPageMode;
  canManageCustomerComplaints: boolean;

  customerComplaint: CustomerComplaintItem | undefined;
  loadCustomerComplaint: (id: string) => Promise<void>;
  createCustomerComplaint: (command: CreateCustomerComplaintCommand) => Promise<void>;
  updateCustomerComplaint: (command: UpdateCustomerComplaintCommand) => Promise<void>;
  closeCustomerComplaint: (command: CloseCustomerComplaintCommand) => Promise<void>;
  loadStaffMembers: () => Promise<void>;
  fleetAssets: AssetItem[];
  loadFleetAssets: () => Promise<void>;
}

interface IMaintainCustomerComplaintParams {
  id: string;
}

type InternalProps = IMaintainCustomerComplaintProps &
  RouteComponentProps<IMaintainCustomerComplaintParams>;

class MaintainCustomerComplaint extends Component<InternalProps> {
  componentDidMount() {
    this.props.loadStaffMembers();
    this.props.loadFleetAssets();
  }

  private get isComplaintClosed() {
    return (
      this.props.customerComplaint &&
      this.props.customerComplaint.status === CustomerComplaintStatus.Closed
    );
  }

  private get isUpdateMode() {
    return this.props.mode === 'update';
  }

  private get CustomerComplaintId() {
    return this.props.match.params.id;
  }

  private get status(): ICustomerComplaintStatusDescriptor | undefined {
    if (this.props.customerComplaint) {
      return getCustomerComplaintStatusDescriptor(this.props.customerComplaint.status);
    }
    return undefined;
  }

  private closeComplaint() {
    if (this.isComplaintClosed) {
      return;
    }
    this.props.closeCustomerComplaint({ id: this.CustomerComplaintId });
  }

  private handlePreSubmitForCreate = (
    item: CustomerComplaintItem
  ): CreateCustomerComplaintCommand => {
    return {
      staffMemberId: item.staffMember ? item.staffMember.id : undefined,
      assetId: item.asset ? item.asset.id : undefined,
      callerName: item.callerName,
      callerPhoneNumber: item.callerPhoneNumber,
      complaintDateTime: item.complaintDateTime,
      category: item.category,
      jobTypeId: item.jobTypeId,
      serviceNumber: item.serviceNumber,
      severityLevel: item.severityLevel,
      actionTaken: item.actionTaken,
      details: item.details,
      cctvRequired: item.cctvRequired,
      dda: item.dda,
      driverInterviewRequired: item.driverInterviewRequired,
      investigator: item.investigator ? item.investigator.id : undefined,
      filedAs: item.filedAs,
    };
  };

  private handlePreSubmitForUpdate = (
    item: CustomerComplaintItem
  ): UpdateCustomerComplaintCommand => {
    return {
      id: item.id,
      complaintNumber: item.complaintNumber,
      staffMemberId: item.staffMember ? item.staffMember.id : undefined,
      assetId: item.asset ? item.asset.id : undefined,
      callerName: item.callerName,
      callerPhoneNumber: item.callerPhoneNumber,
      complaintDateTime: item.complaintDateTime,
      category: item.category,
      jobTypeId: item.jobTypeId,
      serviceNumber: item.serviceNumber,
      severityLevel: item.severityLevel,
      actionTaken: item.actionTaken,
      details: item.details,
      cctvRequired: item.cctvRequired,
      dda: item.dda,
      driverInterviewRequired: item.driverInterviewRequired,
      investigator: item.investigator ? item.investigator.id : undefined,
      filedAs: item.filedAs,
      status: item.status,
    };
  };

  private readonly getPageDef = (updating: boolean): ICrudPageDef => {
    const editable = !this.isUpdateMode || updating;
    const { canManageCustomerComplaints } = this.props;
    return {
      primarySize: PagePrimarySize.threeQuarters,
      primarySection: {
        title: this.isUpdateMode
          ? `Customer Complaint ${this.props.customerComplaint &&
              this.props.customerComplaint.complaintNumber}`
          : 'Create a Customer Complaint',
        badge:
          this.isUpdateMode && this.status
            ? {
                label: this.status.description,
              }
            : undefined,
        primaryActions: [
          {
            actions: [
              {
                actionType: ActionType.actionCollection,
                hidden: editable || this.isComplaintClosed || !canManageCustomerComplaints,
                actionGroups: [
                  {
                    actions: [
                      {
                        actionType: ActionType.actionButton,
                        label: 'Close Customer Complaint',
                        icon: <FlagCheckered fixedWidth />,
                        onClick: () => this.closeComplaint(),
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
        panels: [
          {
            panes: [
              {
                paneType: PaneType.formFieldsPane,
                columnCount: 2,
                fields: [
                  {
                    fieldType: FieldType.selectField,
                    label: 'Category',
                    dataAddr: 'category',
                    valueKey: 'value',
                    descriptionKey: 'description',
                    mandatory: true,
                    optionItems: allCustomerComplaintCategory,
                    useValueOnly: true,
                  },
                  {
                    fieldType: FieldType.textField,
                    label: `Caller's Name`,
                    dataAddr: 'callerName',
                    mandatory: true,
                  },
                  {
                    fieldType: FieldType.textField,
                    label: `Caller's Telephone Number`,
                    dataAddr: 'callerPhoneNumber',
                    mandatory: true,
                    maxLength: 10,
                    digitsOnly: true,
                  },
                  {
                    fieldType: FieldType.dateTimeField,
                    label: 'Complaint Date And Time',
                    dataAddr: 'complaintDateTime',
                    mandatory: true,
                  },
                  {
                    fieldType: FieldType.assetSelectField,
                    label: 'Vehicle',
                    dataAddr: 'asset',
                    optionItems: this.props.fleetAssets,
                    valueKey: 'id',
                    descriptionKey: 'name',
                    mandatory: d =>
                      d.parentValue.category !== CustomerComplaintCategory.StaffIssues &&
                      d.parentValue.category !== CustomerComplaintCategory.ServiceRelated,
                  },
                  {
                    fieldType: FieldType.staffMemberField,
                    label: 'Staff Member',
                    dataAddr: 'staffMember',
                    mandatory: d =>
                      d.parentValue.category !== CustomerComplaintCategory.VehicleIssues &&
                      d.parentValue.category !== CustomerComplaintCategory.ServiceRelated,
                    staffMemberFilter: d => StaffMemberFilter.hasDriversAuthorisation,
                  },
                  {
                    fieldType: FieldType.textField,
                    label: 'Service Number',
                    dataAddr: 'serviceNumber',
                  },
                  {
                    fieldType: FieldType.selectField,
                    label: 'Type of Service',
                    dataAddr: 'jobTypeId',
                    valueKey: 'value',
                    descriptionKey: 'description',
                    mandatory: d =>
                      d.parentValue.category !== CustomerComplaintCategory.StaffIssues,
                    optionItems: CustomerComplaintJobTypes.map(jobType =>
                      getJobTypeDescriptor(jobType)
                    ),
                    useValueOnly: true,
                  },
                  {
                    fieldType: FieldType.yesNoField,
                    label: 'Wheelchair or DDA Related',
                    dataAddr: 'dda',
                    mandatory: true,
                  },
                  {
                    fieldType: FieldType.selectField,
                    label: 'Severity Level',
                    dataAddr: 'severityLevel',
                    mandatory: true,
                    optionItems: allCustomerComplaintSeverityLevel,
                    descriptionKey: 'description',
                    valueKey: 'value',
                    useValueOnly: true,
                  },
                ],
              },
            ],
          },
          {
            panes: [
              {
                paneType: PaneType.formFieldsPane,
                fields: [
                  {
                    fieldType: FieldType.textAreaField,
                    label: 'Details',
                    dataAddr: 'details',
                    mandatory: true,
                  },
                ],
              },
            ],
          },
          {
            title: 'Investigation Details',
            panes: [
              {
                paneType: PaneType.formFieldsPane,
                columnCount: 2,
                fields: [
                  {
                    fieldType: FieldType.staffMemberField,
                    label: 'Investigator',
                    dataAddr: 'investigator',
                  },
                  {
                    fieldType: FieldType.toggleButtonField,
                    label: 'Was Interview With Driver Required?',
                    dataAddr: 'driverInterviewRequired',
                    optionItems: allCustomerComplaintRequiredness,
                    valueKey: 'value',
                    descriptionKey: 'description',
                    useValueOnly: true,
                  },
                  {
                    fieldType: FieldType.toggleButtonField,
                    label: 'Was CCTV Footage Required?',
                    dataAddr: 'cctvRequired',
                    optionItems: allCustomerComplaintRequiredness,
                    valueKey: 'value',
                    descriptionKey: 'description',
                    useValueOnly: true,
                  },
                ],
              },
            ],
          },
          {
            panes: [
              {
                paneType: PaneType.formFieldsPane,
                fields: [
                  {
                    fieldType: FieldType.textAreaField,
                    label: 'Details of Action Taken',
                    dataAddr: 'actionTaken',
                  },
                  {
                    fieldType: FieldType.textField,
                    label: 'Filed As',
                    dataAddr: 'filedAs',
                    placeholder: 'Location e.g. S:\\OPERATIONS\\Conduct\\5-4-2019\\Jim-Jones',
                    validate: d => {
                      const pattern = new RegExp('^[a-zA-Z]:[\\\\/]+.*[\\\\/]+.*');
                      const value = d.fieldValue;
                      return !value || pattern.test(value)
                        ? undefined
                        : 'Must enter a valid path e.g. S:/OPERATIONS/Conduct/5-4-2019/Jim-Jones';
                    },
                  },
                ],
              },
            ],
          },
          {
            panes: [
              {
                paneType: PaneType.formFieldsPane,
                fields: [
                  {
                    fieldType: FieldType.readonlyField,
                    label: 'Created By',
                    dataAddr: 'createdBy',
                  },
                ],
              },
            ],
          },
        ],
        onFormPreSubmit: this.isUpdateMode
          ? this.handlePreSubmitForUpdate
          : this.handlePreSubmitForCreate,
        onFormSubmit: this.isUpdateMode
          ? this.props.updateCustomerComplaint
          : this.props.createCustomerComplaint,
      },
    };
  };

  render() {
    const {
      mode,
      loadCustomerComplaint,
      customerComplaint,
      canManageCustomerComplaints,
    } = this.props;
    return (
      <CrudPage
        def={({ updating }) => this.getPageDef(updating)}
        mode={mode}
        isEditingForbidden={!canManageCustomerComplaints}
        onLoadData={() => loadCustomerComplaint(this.CustomerComplaintId)}
        data={customerComplaint}
        createDefaultData={{}}
      />
    );
  }
}

export default MaintainCustomerComplaint;
