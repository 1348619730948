import { PureComponent } from 'react';
import { Button } from 'reactstrap';
import LabelledField from 'src/views/components/LabelledField';
import { IWeekSelectFieldDef, IFieldMeta, IFieldData } from 'src/views/definitionBuilders/types';
import { DateFormat } from 'src/views/components/DateFormat';
import { ChevronRightIcon, ChevronLeftIcon } from 'src/images/icons';
import { IFormApi } from 'src/views/components/Page/forms/base';

export interface IWeekBounds {
  weekEnd: string;
  weekStart: string;
}

interface IWeekSelectFieldProps {
  fieldDef: IWeekSelectFieldDef;
  fieldMeta: IFieldMeta;
  fieldData: IFieldData<IWeekBounds>;
  formApi: IFormApi;
}

class WeekSelectField extends PureComponent<IWeekSelectFieldProps> {
  componentDidMount() {
    this.onInit();
  }

  private readonly formatDefault = (timesheetWeek: IWeekBounds | undefined) => {
    return timesheetWeek ? (
      <span>
        <DateFormat value={timesheetWeek.weekStart} /> -{' '}
        <DateFormat value={timesheetWeek.weekEnd} />
      </span>
    ) : (
      ''
    );
  };

  private readonly onPrevious = () => {
    const { fieldDef: def, fieldData, formApi } = this.props;
    def.onPrevious({
      fieldData,
      formValues: formApi.values,
      setFormValues: formApi.setAllValues,
    });
  };

  private readonly onNext = () => {
    const { fieldDef: def, fieldData, formApi } = this.props;
    def.onNext({
      fieldData,
      formValues: formApi.values,
      setFormValues: formApi.setAllValues,
    });
  };

  private readonly onInit = () => {
    const { fieldDef: def, fieldData, formApi } = this.props;
    def.onInit &&
      def.onInit({
        fieldData,
        formValues: formApi.values,
        setFormValues: formApi.setAllValues,
      });
  };

  render() {
    const { fieldDef: def, fieldMeta: meta, fieldData: data } = this.props;
    const labelText = typeof def.label === 'function' ? def.label(data) : def.label;
    const tooltipText = typeof def.tooltip === 'function' ? def.tooltip(data) : def.tooltip;
    return (
      <LabelledField
        labelValue={labelText}
        tooltipValue={tooltipText}
        hideLabel={meta.hideLabel}
        noForm={meta.noForm}
        readonly={meta.readonly}
        readonlyValue={
          def.formatReadonly ? def.formatReadonly(data) : this.formatDefault(data.fieldValue)
        }
        readonlyLinkTo={def.linkTo && data.fieldValue ? def.linkTo(data) : undefined}>
        <div style={{ display: 'flex' }}>
          <Button title="Previous" outline onClick={this.onPrevious}>
            <ChevronLeftIcon />
          </Button>
          <div
            className="field-content"
            style={{ width: '100%', margin: '0 2px', textAlign: 'center' }}>
            {this.formatDefault(data.fieldValue)}
          </div>
          <Button title="Next" outline onClick={this.onNext}>
            <ChevronRightIcon />
          </Button>
        </div>
      </LabelledField>
    );
  }
}

export default WeekSelectField;
