import { IRootStoreModel } from 'src/domain/entities/RootStoreModel';
import { UiRoute } from 'src/domain/services/buildUiRoutes';
import { UiRouteName } from 'src/domain/baseTypes';

export default function buildUiRoutes(store: IRootStoreModel) {
  const customersCreate = new UiRoute(
    UiRouteName.salesCustomerCreate,
    '/sales/customers/create',
    () => 'Create Customer',
    false
  );

  const customersEdit = new UiRoute(
    UiRouteName.salesCustomerEdit,
    '/sales/customers/:id',
    () => 'Manage Customer',
    false
  );

  const customersList = new UiRoute(
    UiRouteName.salesCustomerList,
    '/sales/customers',
    () => 'Customers',
    true,
    false,
    [customersCreate, customersEdit]
  );

  return {
    [UiRouteName.salesCustomerList]: customersList,
    [UiRouteName.salesCustomerCreate]: customersCreate,
    [UiRouteName.salesCustomerEdit]: customersEdit,
  };
}
