import { types, flow, addMiddleware, Instance } from 'mobx-state-tree';
import { globalErrorHandlerMiddleware } from 'src/domain/middlewares/globalErrorHandler';

import { AccountModel } from './user/AccountModel';
import { HistoryModel } from './app/HistoryModel';
import { IStoreEnvironment, createAjax, createBus, hubConnection } from 'src/domain/services/';
import { NotificationsModel } from './app/NotificationsModel';
import { JobModel } from './workshop/job/JobModel';
import { JobsModel } from './workshop/job/JobsModel';
import { ScheduledJobsModel } from './workshop/job/ScheduledJobsModel';
import { ScheduledMachineryJobsModel } from './workshop/job/ScheduledMachineryJobsModel';
import { AssetsModel } from './workshop/asset/AssetsModel';
import { AssetModel } from './workshop/asset/AssetModel';
import { AssetCubicRegisterModel } from './workshop/asset/AssetCubicRegisterModel';
import { ListAssetsModel } from './workshop/asset/ListAssetsModel';
import { DefectModel } from './workshop/defect/DefectModel';
import { ListDefectsModel } from './workshop/defect/ListDefectsModel';
import { AssetGroupModel } from './workshop/asset/AssetGroupModel';
import { AssetGroupsModel } from './workshop/asset/assetGroups/AssetGroupsModel';
import { JobTaskModel } from './workshop/jobTask/JobTaskModel';
import { WorkshopModel } from './workshop/WorkshopModel';
import { KBL_RELEASE_ID } from 'src/appSettings';
import { OperationsModel } from './operations/OperationsModel';
import { PeopleModel } from './people/PeopleModel';
import { UnallocatedAssetsListModel } from './workshop/asset/ListUnallocatedAssetsModel';
import { ComplianceModel } from './compliance/ComplianceModel';
import { OperationsAssetOdometerReadingsModel } from './operations/asset/OperationsAssetOdometerReadingsModel';
import { PeopleAttachmentModel } from 'src/domain/entities/people/attachment/PeopleAttachmentModel';
import { WorkshopAttachmentModel } from 'src/domain/entities/workshop/attachment/WorkshopAttachmentModel';
import { OperationsAttachmentModel } from 'src/domain/entities/operations/attachment/OperationsAttachmentModel';
import { WorkshopStartUpModel } from 'src/domain/entities/app/WorkshopStartUpModel';
import { DocumentationModel } from './documentation/DocumentationModel';
import { PagePrimarySize } from 'src/views/definitionBuilders/types';
import { OperationsStartUpModel } from 'src/domain/entities/app/OperationsStartUpModel';
import { useContext } from 'react';
import { RootStoreContext } from 'src/views/App';

export const rootStoreInitialState = {};

const RootStoreModel = types
  .model('RootStoreModel', {
    history: types.optional(HistoryModel, {}),
    notifications: types.optional(NotificationsModel, {}),
    account: types.optional(AccountModel, {}),
    job: types.optional(JobModel, {}),
    jobs: types.optional(JobsModel, {}),
    jobTask: types.optional(JobTaskModel, {}),
    scheduledJobs: types.optional(ScheduledJobsModel, {}),
    scheduledMachineryJobs: types.optional(ScheduledMachineryJobsModel, {}),
    assets: types.optional(AssetsModel, {}),
    asset: types.optional(AssetModel, {}),
    assetOdometerReadings: types.optional(OperationsAssetOdometerReadingsModel, {}),
    assetCubicRegister: types.optional(AssetCubicRegisterModel, {}),
    listAssets: types.optional(ListAssetsModel, {}),
    listUnallocatedAssets: types.optional(UnallocatedAssetsListModel, {}),
    defect: types.optional(DefectModel, {}),
    listDefects: types.optional(ListDefectsModel, {}),
    assetGroup: types.optional(AssetGroupModel, {}),
    assetGroups: types.optional(AssetGroupsModel, {}),
    workshop: types.optional(WorkshopModel, {}),
    operations: types.optional(OperationsModel, {}),
    compliance: types.optional(ComplianceModel, {}),
    people: types.optional(PeopleModel, {}),
    releaseMismatch: types.optional(types.boolean, false),
    workshopStartup: types.optional(WorkshopStartUpModel, {}),
    workshopAttachments: types.optional(WorkshopAttachmentModel, {}),
    operationsAttachments: types.optional(OperationsAttachmentModel, {}),
    peopleAttachments: types.optional(PeopleAttachmentModel, {}),
    documentation: types.optional(DocumentationModel, { primaryPageSize: PagePrimarySize.half }),
    operationsStartup: types.optional(OperationsStartUpModel, {}),
  })
  .actions(self => {
    const globalErrorHandlerDisposer = addMiddleware(self, globalErrorHandlerMiddleware);

    const afterCreate = flow(function*() {
      if (self.account.isAuthenticated === undefined) {
        yield self.account.loadProfile();
      }
    });

    function beforeDestroy() {
      globalErrorHandlerDisposer();
    }

    const checkRelease = (serverReleaseId: string | null) => {
      self.releaseMismatch = !!serverReleaseId && KBL_RELEASE_ID !== serverReleaseId;
    };

    const refreshSite = () => {
      location.reload();
    };

    const refreshSiteIfRequired = () => {
      if (self.releaseMismatch) {
        refreshSite();
      }
    };

    const initialiseWorkshopStartUpModel = flow(function*() {
      yield self.workshopStartup.init();
    });

    const initialiseOperationsStartUpModel = flow(function*() {
      yield self.operationsStartup.loadStartUpData();
    });

    return {
      afterCreate,
      beforeDestroy,
      checkRelease,
      refreshSite,
      refreshSiteIfRequired,
      initialiseWorkshopStartUpModel,
      initialiseOperationsStartUpModel,
    };
  });

export interface IRootStoreModel extends Instance<typeof RootStoreModel> {}

export async function getDefaultStore(initialStateMerge?: {}) {
  let store: IRootStoreModel;
  const env: IStoreEnvironment = {
    ajax: createAjax(
      () => store,
      r => store.checkRelease(r.xhr.getResponseHeader('kbl-release-id'))
    ),
    bus: createBus(),
    signals: hubConnection(),
  };
  const initState = { ...rootStoreInitialState, ...(initialStateMerge || {}) };
  store = RootStoreModel.create(initState, env);
  await store.initialiseWorkshopStartUpModel();
  await store.initialiseOperationsStartUpModel();
  return store;
}

export function useRootStore(): IRootStoreModel {
  const store = useContext(RootStoreContext);
  if (store === null) {
    throw new Error('Store cannot be null, please add a context provider');
  }
  return store as IRootStoreModel;
}
