import { workshopUrls } from 'src/domain/services/apiUrls';
import { IAjax } from 'src/infrastructure/ajax';

type MachineryScheduleTemplateItem = Workshop.Domain.Queries.MachineryScheduleTemplate.MachineryScheduleTemplateItem;

const returnVoid = () => {};

export default function init(ajax: IAjax) {
  function loadMachinerySchedule() {
    return ajax
      .get(workshopUrls.machineryScheduleUrls.loadMachineryScheduleTemplate())
      .toPromise()
      .then<MachineryScheduleTemplateItem[]>(r => r.response);
  }

  function updateMachinerySchedule(
    command: Workshop.Domain.Commands.MachineryScheduleTemplate.UpdateMachineryScheduleTemplateCommand
  ) {
    return ajax
      .put(workshopUrls.machineryScheduleUrls.updateMachineryScheduleTemplate(), command)
      .toPromise()
      .then(r => returnVoid());
  }

  return {
    machineryScheduleTemplate: {
      loadMachinerySchedule,
      updateMachinerySchedule,
    },
  };
}
