import './JobSchedule.scss';

import { RouteComponentProps } from 'react-router';
import DaySchedule from './Scheduler/DaySchedule';
import { DateTime } from 'luxon';
import Page from 'src/views/components/Page';
import { IPageDef, PagePrimarySize, PaneType } from 'src/views/definitionBuilders/types';
import { useRootStore } from 'src/domain/entities/RootStoreModel';
import { observer } from 'mobx-react';
import { TIMEZONE } from 'src/appSettings';

const dayQueryParamName = 'day';
const depotQueryParamName = 'depotId';
const zone = TIMEZONE;

type InternalProps = RouteComponentProps<{}>;

const JobSchedule: React.FC<InternalProps> = observer((props: InternalProps) => {
  const rootStore = useRootStore();
  const notesModel = rootStore.workshop.dailyNotes;
  const jobsModel = rootStore.jobs;
  const workshopModel = rootStore.workshopStartup;
  const query = new URLSearchParams(location.search);
  const selectedWorkshopDepotId = Number(query.get('depotId')) || 0;
  const queryDay = query.get('day');
  const localDay = queryDay
    ? DateTime.fromISO(queryDay, { zone })
    : DateTime.utc()
        .setZone(zone)
        .startOf('day');

  const localNow = DateTime.utc().setZone(zone);
  const localNowMidnight = localNow.startOf('day');
  const currentTimeUnit = localDay.diff(localNowMidnight, 'day').days;
  const isToday = currentTimeUnit === 0;

  const isLoading = jobsModel.dailyRunSheetIsLoading;

  const changeCurrentDay = (day: DateTime, depotId: number) => {
    const { location, history } = props;
    query.set(dayQueryParamName, day.toISODate());
    query.set(depotQueryParamName, depotId.toString());
    history.replace({ ...location, search: query.toString() });
  };

  const handleLoadData = async (day: DateTime, depotId: number) => {
    changeCurrentDay(day, depotId);
    const promises = [
      notesModel.loadDailyNotesForDay(day, depotId),
      jobsModel.loadDailyRunSheet(day, depotId),
    ];
    return await Promise.all(promises).then(() => Promise.resolve());
  };

  const getPageDef = (): IPageDef => {
    return {
      primarySize: PagePrimarySize.full,
      primarySection: {
        title: 'Daily Schedule ',
        subtitle: localDay.toLocaleString(DateTime.DATE_HUGE) + (isToday ? ' (Today)' : ''),
        panels: [
          {
            panes: [
              {
                paneType: PaneType.customPane,
                render: () => {
                  return (
                    workshopModel.defaultWorkshopDepot && (
                      <div className="job-schedule-component">
                        <DaySchedule
                          currentLocalDay={localDay}
                          dailyRunSheetItem={jobsModel.dailyRunSheetItem}
                          jobsLoading={isLoading}
                          onLoadData={handleLoadData}
                          canManageDailyNotes={rootStore.account.isWorkshopDepartmentMember}
                          dailyNote={notesModel.dailyNote}
                          dailyNoteLoading={notesModel.dailyNoteLoading}
                          onAddDailyNote={notesModel.addDailyNote}
                          onUpdateDailyNote={notesModel.updateDailyNote}
                          workshopDepots={workshopModel.workshopDepots.slice()}
                          defaultWorkshopDepot={workshopModel.defaultWorkshopDepot!}
                          selectedWorkshopDepotId={selectedWorkshopDepotId}
                        />
                      </div>
                    )
                  );
                },
              },
            ],
          },
        ],
      },
    };
  };
  return <Page def={getPageDef()} />;
});

export default JobSchedule;
