import { buildListPageApiSearchModelTypedQuery } from 'src/domain/modelBuilders/buildListPageApiSearchModel';
import { types, flow } from 'mobx-state-tree';
import { getAjax } from 'src/domain/services';

type ExternalIdentityProvider = Common.AggregatesModel.People.ExternalIdentityAggregate.ExternalIdentityProvider;
type UpdateExternalIdentityCommand = People.Domain.Commands.ExternalIdentity.UpdateExternalIdentity.UpdateExternalIdentityCommand;
export const ListExternalIdentitiesModel = buildListPageApiSearchModelTypedQuery<
  People.Domain.Queries.ListExternalIdentities.ListExternalIdentitiesQuery
>()('ListExternalIdentitiesModel', d =>
  d.ajax.people.externalIdentity.loadExternalIdentities(d.query)
);

const ExternalIdentityModel = types
  .model('ExternalIdentityProvidersModel', {
    externalIdentityProviders: types.array(types.frozen<ExternalIdentityProvider>()),
  })
  .actions(self => {
    const ajax = getAjax(self);

    const updateExternalIdentity = flow(function*(command: UpdateExternalIdentityCommand) {
      yield ajax.people.externalIdentity.updateExternalIdentity(command);
    });

    const deleteExternalIdentity = flow(function*(externalIdentityId: string) {
      yield ajax.people.externalIdentity.deleteExternalIdentity(externalIdentityId);
    });

    const loadExternalIdentityProviders = flow(function*() {
      self.externalIdentityProviders = yield ajax.people.externalIdentity.loadIdentityProviders();
    });

    return {
      loadExternalIdentityProviders,
      updateExternalIdentity,
      deleteExternalIdentity,
    };
  });

export const ExternalIdentitiesModel = types.compose(
  ListExternalIdentitiesModel,
  ExternalIdentityModel
);
