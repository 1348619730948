import { getSubmitCloseModalActionGroupDef } from 'src/views/definitionBuilders/common';
import { PaneType, ModalDefBuilder } from 'src/views/definitionBuilders/types';

type StaffMemberItem = People.Domain.Queries.ViewStaffMember.StaffMemberItem;

export default function getConfirmAuthRemovalModalDef(): ModalDefBuilder {
  return modalDefApi => ({
    title: 'Confirm Authorisation Removal',
    asForm: true,
    panels: [
      {
        panes: [
          {
            paneType: PaneType.customPane,
            render: () => {
              const item = modalDefApi.actionData.sectionValue as StaffMemberItem;
              const name = item && item.firstName;
              return (
                <div>
                  <p>{`${name} will be removed from assigned future driving work.`}</p>
                  <p>Are you sure you want to remove Driver Authorisation?</p>
                </div>
              );
            },
          },
        ],
      },
    ],
    secondaryActions: [getSubmitCloseModalActionGroupDef('Ok')],
    onFormSubmit: modalDefApi.parentFormApi.submitForm,
  });
}
