import './ActionBar.scss';
import { Component } from 'react';
import cn from 'classnames';
import ButtonToolbar from 'reactstrap/lib/ButtonToolbar';
import {
  IActionGroupDef,
  isActionButtonDef,
  isActionCollectionDef,
  isSubmitActionButtonDef,
  isResetActionButtonDef,
  isSearchActionButtonDef,
  isPrintActionButtonDef,
  IActionData,
  IActionMeta,
  isRemoveArrayItemActionButtonDef,
  isAddArrayItemActionButtonDef,
  isActionLinkDef,
  isAddActionLinkDef,
  isMoveArrayItemActionButtonDef,
  isModalActionButtonDef,
  isCloseModalActionButtonDef,
  isFilterActionButtonDef,
  isPresetFilterCollectionDef,
  isCalendarButtonDef,
  ActionDefs,
} from 'src/views/definitionBuilders/types';
import ActionButton from './ActionButton';
import SearchActionButton from './SearchActionButton';
import PrintActionButton from './PrintActionButton';
import ActionCollectionButton from './ActionCollectionButton';
import SubmitActionButton from './SubmitActionButton';
import ResetActionButton from './ResetActionButton';
import RemoveArrayItemActionButton from './RemoveArrayItemActionButton';
import AddArrayItemActionButton from './AddArrayItemActionButton';
import ActionLink from './ActionLink';
import MoveArrayItemActionButton from './MoveArrayItemActionButton';
import ModalActionButton from './ModalActionButton';
import CloseModalActionButton from './CloseModalActionButton';
import FilterActionButton from './FilterActionButton';
import PresetFilterCollection from 'src/views/components/Page/actions/PresetFilterCollection';
import CalendarButton from 'src/views/components/Page/actions/CalendarButton';
import AddActionLink from 'src/views/components/Page/actions/AddActionLink';

interface IActionBarProps {
  className?: string;
  actionGroupDefs?: Array<IActionGroupDef>;
  actionMeta: IActionMeta;
  actionData: IActionData;
  reverseOrder?: boolean;
  nowrap?: boolean;
}

class ActionBar extends Component<IActionBarProps> {
  render() {
    const { className, actionGroupDefs, actionMeta, actionData, reverseOrder, nowrap } = this.props;
    if (!actionGroupDefs || !actionGroupDefs.length) {
      return null;
    }

    const allActions = actionGroupDefs.reduce(
      (acc, g) => [...acc, ...g.actions],
      [] as ActionDefs[]
    );

    return (
      <div className={cn('action-bar-component', className)}>
        <ButtonToolbar
          className={cn('action-bar-toolbar', {
            'reverse-order': reverseOrder,
            'wide-actions': actionMeta.wide,
            nowrap,
          })}>
          {allActions.map((actionDef, i) => {
            if (
              typeof actionDef.hidden === 'function'
                ? actionDef.hidden(actionData)
                : actionDef.hidden
            ) {
              return null;
            }
            const key = i;
            if (isActionButtonDef(actionDef)) {
              return (
                <ActionButton
                  key={key}
                  actionDef={actionDef}
                  actionMeta={actionMeta}
                  actionData={actionData}
                />
              );
            } else if (isActionLinkDef(actionDef)) {
              return (
                <ActionLink
                  key={key}
                  actionDef={actionDef}
                  actionMeta={actionMeta}
                  actionData={actionData}
                />
              );
            } else if (isAddActionLinkDef(actionDef)) {
              return (
                <AddActionLink
                  key={key}
                  actionDef={actionDef}
                  actionMeta={actionMeta}
                  actionData={actionData}
                />
              );
            } else if (isActionCollectionDef(actionDef)) {
              return (
                <ActionCollectionButton
                  key={key}
                  actionDef={actionDef}
                  actionMeta={actionMeta}
                  actionData={actionData}
                />
              );
            } else if (isSubmitActionButtonDef(actionDef)) {
              return (
                <SubmitActionButton
                  key={key}
                  actionDef={actionDef}
                  actionMeta={actionMeta}
                  actionData={actionData}
                />
              );
            } else if (isResetActionButtonDef(actionDef)) {
              return (
                <ResetActionButton
                  key={key}
                  actionDef={actionDef}
                  actionMeta={actionMeta}
                  actionData={actionData}
                />
              );
            } else if (isSearchActionButtonDef(actionDef)) {
              return <SearchActionButton key={key} actionDef={actionDef} actionMeta={actionMeta} />;
            } else if (isAddArrayItemActionButtonDef(actionDef)) {
              return (
                <AddArrayItemActionButton
                  key={key}
                  actionDef={actionDef}
                  actionMeta={actionMeta}
                  actionData={actionData}
                />
              );
            } else if (isRemoveArrayItemActionButtonDef(actionDef)) {
              return (
                <RemoveArrayItemActionButton
                  key={key}
                  actionDef={actionDef}
                  actionMeta={actionMeta}
                  actionData={actionData}
                />
              );
            } else if (isMoveArrayItemActionButtonDef(actionDef)) {
              return (
                <MoveArrayItemActionButton
                  key={key}
                  actionDef={actionDef}
                  actionMeta={actionMeta}
                  actionData={actionData}
                />
              );
            } else if (isModalActionButtonDef(actionDef)) {
              return (
                <ModalActionButton
                  key={key}
                  actionDef={actionDef}
                  actionMeta={actionMeta}
                  actionData={actionData}
                />
              );
            } else if (isCloseModalActionButtonDef(actionDef)) {
              return (
                <CloseModalActionButton
                  key={key}
                  actionDef={actionDef}
                  actionMeta={actionMeta}
                  actionData={actionData}
                />
              );
            } else if (isFilterActionButtonDef(actionDef)) {
              return (
                <FilterActionButton
                  key={key}
                  actionDef={actionDef}
                  actionMeta={actionMeta}
                  actionData={actionData}
                />
              );
            } else if (isPrintActionButtonDef(actionDef)) {
              return (
                <PrintActionButton
                  key={key}
                  actionDef={actionDef}
                  actionMeta={actionMeta}
                  actionData={actionData}
                />
              );
            } else if (isPresetFilterCollectionDef(actionDef)) {
              return (
                <PresetFilterCollection
                  key={key}
                  actionDef={actionDef}
                  actionMeta={actionMeta}
                  actionData={actionData}
                />
              );
            } else if (isCalendarButtonDef(actionDef)) {
              return <CalendarButton key={key} actionDef={actionDef} />;
            }
            // tslint:disable-next-line:no-any
            throw new Error('Action Type not supported: ' + (actionDef as any).actionType);
          })}
        </ButtonToolbar>
      </div>
    );
  }
}

export default ActionBar;
