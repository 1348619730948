import {
  ActionType,
  FieldType,
  IActionData,
  PagePrimarySize,
  PaneType,
} from 'src/views/definitionBuilders/types';

import { DateTimeFormat } from 'src/views/components/DateTimeFormat';
import withQueryParams, { IQueryParamsProps } from 'src/views/hocs/withQueryParams';

import { DateTime } from 'luxon';
import Input from 'reactstrap/lib/Input';
import { getProgressIdDescriptor } from 'src/api/enums';
import { formatToFixedWithMinMax } from 'src/infrastructure/mathUtil';
import { isDefined } from 'src/infrastructure/typeUtils';
import { IFormApiWithoutState } from 'src/views/components/Page/forms/base';
import CrudPage, { ICrudPageDef } from 'src/views/components/Page/pages/CrudPage';
import './ListAssetOdometerReadings.scss';
import PrimaryTitle from 'src/views/components/Page/PrimaryTitle/PrimaryTitle';

type AssetOdometerReadingsByJobDto = Operations.Domain.Queries.ListAssetOdometerReadings.AssetOdometerReadingsByJobDto;
type AssetOdometerReadingDto = Common.Queries.Operations.AssetOdometerReadings.AssetOdometerReadingDto;
type AssetItem = Common.Queries.Workshop.GetFleetAssetList.AssetItem;

export interface IListAssetOdometerReadingsProps {
  loadAssetOdometerReadings: (
    assetId: string,
    from: string | undefined,
    to: string | undefined
  ) => Promise<void>;
  assetOdometerReadings: AssetOdometerReadingsByJobDto[];
  onSubmit: (assetId: string, editedItems: AssetOdometerReadingDto[]) => Promise<void>;
  assets: AssetItem[];
  loadAssets: () => Promise<void>;
}

type StateOdoReading = AssetOdometerReadingDto & {
  originalValue: number | undefined;
};
type StateOdoReadingByJob = AssetOdometerReadingsByJobDto & {
  mappedOdometerReadings: StateOdoReading[];
  kmDifferenceFromPrevious: number | undefined;
};

type InternalProps = IListAssetOdometerReadingsProps &
  IQueryParamsProps<{ id: string; from: string; to: string }>;

type PageDefProps = Pick<InternalProps, 'loadAssetOdometerReadings' | 'assets'> & {
  onPresubmit: (values: StateOdoReadingByJob[]) => AssetOdometerReadingDto[];
  onSubmit: (values: AssetOdometerReadingDto[]) => Promise<void>;
  updating: boolean;
  assetId: string;
  from: string;
  to: string;
};

function getPageDef({
  onPresubmit,
  onSubmit,
  updating,
  assetId,
  from,
  to,
  loadAssetOdometerReadings,
  assets,
}: PageDefProps): ICrudPageDef {
  const selectedAsset = assets && assets.find(a => a.id === assetId);
  const asset = { id: assetId, name: selectedAsset && selectedAsset.name };

  const onOdoChanged = (
    formApi: IFormApiWithoutState,
    evt: React.ChangeEvent<HTMLInputElement>,
    odoGroupIndex: number,
    odoProgressIndex: number,
    odoProgressesLength: number,
    fieldDataAddr: React.ReactText[]
  ) => {
    let newValue = Number.parseFloat(evt.target.value);
    if (isNaN(newValue)) {
      newValue = 0;
    }

    const allItems = formApi.getValue(fieldDataAddr[0]) as StateOdoReadingByJob[];
    const thisOne = allItems[odoGroupIndex];
    const next = allItems[odoGroupIndex + 1];
    let updateOwnKmDiff: boolean | undefined = undefined;
    let diff: number | undefined = undefined;
    if (odoProgressIndex === 0 && odoGroupIndex !== 0) {
      updateOwnKmDiff = true;
      const previous = allItems[odoGroupIndex - 1].mappedOdometerReadings;
      const lastInPrevious = previous[previous.length - 1];
      const lastInPreviousReading = lastInPrevious.odometerReading || 0;

      diff = newValue - lastInPreviousReading;
    } else if (
      odoProgressIndex === odoProgressesLength - 1 &&
      odoGroupIndex !== allItems.length - 1
    ) {
      updateOwnKmDiff = false;
      const firstInNext = next.mappedOdometerReadings[0];
      const firstInNextReading = firstInNext.odometerReading || 0;

      diff = firstInNextReading - newValue;
    }

    const updatedThisOne: StateOdoReadingByJob = {
      ...thisOne,
      kmDifferenceFromPrevious: updateOwnKmDiff === true ? diff : thisOne.kmDifferenceFromPrevious,
      mappedOdometerReadings: thisOne.mappedOdometerReadings.map((item, i) => ({
        ...item,
        odometerReading: i === odoProgressIndex ? newValue : item.odometerReading,
      })),
    };

    const updatedNext: StateOdoReadingByJob = next && {
      ...next,
      kmDifferenceFromPrevious: updateOwnKmDiff === false ? diff : next.kmDifferenceFromPrevious,
    };

    formApi.setValue(
      fieldDataAddr[0],
      allItems.map((item, idx) =>
        idx === odoGroupIndex ? updatedThisOne : idx === odoGroupIndex + 1 ? updatedNext : item
      )
    );
  };

  const getFilters = (d: IActionData) => {
    const newAsset = d.actionValue.asset.id;
    const newFrom = DateTime.fromISO(d.actionValue.from).toFormat('yyyy-MM-dd');
    const newTo = DateTime.fromISO(d.actionValue.to).toFormat('yyyy-MM-dd');
    return { newAsset, newFrom, newTo };
  };

  return {
    primarySize: PagePrimarySize.threeQuarters,
    secondarySections: [
      {
        title: 'Filtering',
        isLogicalSubsection: false,
        asForm: true,
        suppressLeavePrompt: true,
        explicitData: { asset, from, to },
        key: 'none',
        hidden: updating,
        secondaryActions: [
          {
            actions: [
              {
                actionType: ActionType.actionLink,
                label: 'Apply Filter',
                level: 'primary',
                disabled: d => {
                  const { newAsset, newFrom, newTo } = getFilters(d);
                  return (
                    newAsset === '' ||
                    newFrom === 'Invalid DateTime' ||
                    newTo === 'Invalid DateTime'
                  );
                },
                onClick: d => {
                  const { newAsset, newFrom, newTo } = getFilters(d);
                  return loadAssetOdometerReadings(newAsset, newFrom, newTo);
                },
                to: d => {
                  const { newAsset, newFrom, newTo } = getFilters(d);
                  return `/operations/asset/odometer-readings?id=${newAsset}&from=${newFrom}&to=${newTo}`;
                },
              },
            ],
          },
        ],
        panels: [
          {
            panes: [
              {
                paneType: PaneType.formFieldsPane,
                fields: [
                  {
                    fieldType: FieldType.assetSelectField,
                    dataAddr: 'asset',
                    descriptionKey: 'name',
                    valueKey: 'id',
                    label: 'Asset',
                    mandatory: true,
                    optionItems: assets,
                    hidden: !assets || assets.length === 0,
                  },
                  {
                    fieldType: FieldType.dateField,
                    mandatory: true,
                    dataAddr: 'from',
                    label: 'From',
                  },
                  {
                    fieldType: FieldType.dateField,
                    mandatory: true,
                    dataAddr: 'to',
                    label: 'To',
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
    primarySection: {
      title: (
        <PrimaryTitle
          title="Assets Odometer Readings"
          notes="This page can be used to overwrite the vehicle odometer readings received from
        your GPS tracking provider."></PrimaryTitle>
      ),
      asForm: true,
      onFormSubmit: onSubmit,
      onFormPreSubmit: onPresubmit,
      panels: formApi => [
        {
          panes: [
            {
              paneType: PaneType.tablePane,
              neverEditable: false,
              fields: [
                {
                  fieldType: FieldType.textField,
                  dataAddr: 'jobNumber',
                  label: 'Job',
                  readonly: true,
                  linkTo: d =>
                    `/${d.parentValue.isWorkshop ? 'workshop' : 'operations'}/jobs/${
                      d.parentValue.jobId
                    }`,
                },
                {
                  fieldType: FieldType.textField,
                  dataAddr: 'jobDescription',
                  label: 'Job Description',
                  readonly: true,
                },
                {
                  fieldType: FieldType.customField,
                  dataAddr: 'mappedOdometerReadings',
                  label: 'Odometer Readings',
                  render: d => {
                    const odos = d.data.fieldValue as StateOdoReading[];
                    const odoGroupIndex = d.data.fieldDataAddr[1] as number;
                    const hasUnknownKms =
                      isDefined(d.data.parentValue.kmDifferenceFromPrevious) &&
                      Math.abs(d.data.parentValue.kmDifferenceFromPrevious) > 15;

                    return (
                      <table className="odo-readings">
                        <tbody>
                          {odos.map((odo, idx) => {
                            const unknownKmsStyle = idx === 0 && hasUnknownKms ? 'unknown-kms' : '';
                            const missingKmsStyle =
                              isDefined(odo.odometerReading) && odo.odometerReading > 0
                                ? ''
                                : 'missing';
                            return (
                              <tr key={idx} className={unknownKmsStyle || missingKmsStyle}>
                                <td className="progressid">
                                  <span>{getProgressIdDescriptor(odo.progress).description}</span>
                                </td>
                                <td className="occurred-ats">
                                  <span className="progress-occurred">
                                    <strong>Tablet Progress:</strong>{' '}
                                    <DateTimeFormat value={odo.progressOccurredAt} />
                                  </span>
                                  <span className="odo-occurred">
                                    <strong>Mix Odo:</strong>{' '}
                                    <DateTimeFormat value={odo.telemetryOdoOccurredAt} />
                                  </span>
                                </td>
                                <td className="odo">
                                  <Input
                                    disabled={!updating}
                                    type="number"
                                    value={odo.odometerReading || ''}
                                    onChange={evt =>
                                      onOdoChanged(
                                        formApi,
                                        evt,
                                        odoGroupIndex,
                                        idx,
                                        odos.length,
                                        d.data.fieldDataAddr
                                      )
                                    }
                                  />
                                  <div className="stateful-odos-area">
                                    {odo.telemetryOdometerReading &&
                                      odo.telemetryOdometerReading !== odo.odometerReading && (
                                        <span className="mix-odo">
                                          <strong>Mix:</strong> {odo.telemetryOdometerReading}
                                        </span>
                                      )}
                                    {odo.originalValue !== odo.odometerReading && (
                                      <span className="original-odo">
                                        <strong>Before Edit:</strong> {odo.originalValue}
                                      </span>
                                    )}
                                  </div>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    );
                  },
                },
                {
                  fieldType: FieldType.customField,
                  dataAddr: 'none2',
                  columnWidth: '5rem',
                  label: 'Difference',
                  render: d => {
                    const kmDifference = d.data.parentValue.kmDifferenceFromPrevious;
                    if (kmDifference === 0 || !isDefined(kmDifference)) {
                      return null;
                    }
                    return <div>{formatToFixedWithMinMax(kmDifference, 0, 3)}km</div>;
                  },
                },
              ],
            },
          ],
        },
      ],
    },
  };
}

const ListAssetOdometerReadings: React.FC<InternalProps> = (props: InternalProps) => {
  const params = props.getQueryParams();

  const assetId = params.id !== undefined ? params.id : '';
  const fromDate = params.from;
  const toDate = params.to;

  const mapOdometerReadings = () => {
    let lastOdoOfSection: number | undefined = undefined;
    if (
      props.assetOdometerReadings &&
      props.assetOdometerReadings.length > 0 &&
      props.assetOdometerReadings[0].assetId !== assetId
    ) {
      // this makes sure the stale data from previous asset is not shown when the page loads
      return undefined;
    }
    const mapping: StateOdoReadingByJob[] = props.assetOdometerReadings.map(x => {
      let firstOdoOfSection: number | undefined = undefined;
      const ordered = [...x.odometerReadings].sort((first, second) => {
        const firstISO = DateTime.fromISO(first.progressOccurredAt);
        const secondISO = DateTime.fromISO(second.progressOccurredAt);
        const result = firstISO.diff(secondISO).seconds > 0 ? -1 : 1;
        return result;
      });

      const entry: StateOdoReadingByJob = {
        ...x,
        mappedOdometerReadings: ordered.map(o => {
          if (firstOdoOfSection === undefined) {
            firstOdoOfSection = o.odometerReading || 0;
          }

          if (lastOdoOfSection === undefined) {
            lastOdoOfSection = o.odometerReading || 0;
          }

          const odo: StateOdoReading = {
            ...o,
            originalValue: o.odometerReading,
          };
          return odo;
        }),
        kmDifferenceFromPrevious: (firstOdoOfSection || 0) - (lastOdoOfSection || 0),
      };

      // Update for use next section
      const lastOdo = x.odometerReadings.slice(-1)[0].odometerReading;
      lastOdoOfSection = lastOdo || 0;
      return entry;
    });

    return mapping;
  };

  const onPresubmit: PageDefProps['onPresubmit'] = values => {
    return values
      .map(x => x.mappedOdometerReadings)
      .reduce((agg, x) => agg.concat(x), [])
      .filter(x => x.originalValue !== x.odometerReading);
  };

  const onSubmit: PageDefProps['onSubmit'] = values =>
    props
      .onSubmit(assetId || '', values)
      .then(() => props.loadAssetOdometerReadings(assetId, fromDate, toDate));

  return (
    <CrudPage
      className="list-asset-odometer-readings"
      data={mapOdometerReadings()}
      def={({ updating }) =>
        getPageDef({
          onPresubmit: onPresubmit,
          onSubmit: onSubmit,
          updating,
          assetId: assetId,
          from: fromDate || '',
          to: toDate || '',
          loadAssetOdometerReadings: props.loadAssetOdometerReadings,
          assets: props.assets,
        })
      }
      onLoadData={() =>
        Promise.all([
          props.loadAssetOdometerReadings(assetId, fromDate, toDate),
          props.loadAssets(),
        ]).then(() => undefined)
      }
      mode={'update'}
      isEditingForbidden={assetId === ''}
    />
  );
};

export default withQueryParams(ListAssetOdometerReadings);
