import { IAjax } from 'src/infrastructure/ajax';
import { checklistUrls } from 'src/domain/services/apiUrls';

type AggregateUsageDto = Common.Dtos.AggregateUsageDto;
type SetChecklistInactiveCommand = Workshop.Domain.Commands.Checklist.SetChecklistInactive.SetChecklistInactiveCommand;
type SetChecklistActiveCommand = Workshop.Domain.Commands.Checklist.SetChecklistActive.SetChecklistActiveCommand;

// tslint:disable-next-line:no-empty
const returnVoid = () => {};

export default function init(ajax: IAjax) {
  function listChecklists(includeInactive: boolean) {
    return ajax
      .get(checklistUrls.listChecklists(includeInactive))
      .toPromise()
      .then<Common.Dtos.ListResult<Workshop.Domain.Queries.GetChecklist.ChecklistDetails>>(r => {
        return r.response.items;
      });
  }

  function getChecklist(id: string) {
    return ajax
      .get(checklistUrls.getChecklist(id))
      .toPromise()
      .then<Workshop.Domain.Queries.GetChecklist.ChecklistDetails>(r => r.response);
  }

  function getChecklistAssetGroups(id: string) {
    return ajax
      .get(checklistUrls.getChecklistAssetGroups(id))
      .toPromise()
      .then<Workshop.Domain.Queries.GetChecklistAssetGroups.ChecklistAssetGroup[]>(r => r.response);
  }

  function createChecklist(
    command: Workshop.Domain.Commands.Checklist.CreateChecklist.CreateChecklistCommand
  ) {
    return ajax
      .post(checklistUrls.createChecklist(), command)
      .toPromise()
      .then<string>(r => r.response);
  }

  function updateChecklist(
    command: Workshop.Domain.Commands.Checklist.UpdateChecklist.UpdateChecklistCommand
  ) {
    return ajax
      .put(checklistUrls.updateChecklist(command.id), command)
      .toPromise()
      .then(r => returnVoid());
  }

  function checkForUniqueChecklistName(checklistName: string) {
    return ajax
      .get(checklistUrls.checkForUniqueCheckListName(checklistName))
      .toPromise()
      .then<Common.Dtos.UniqueNameCheckResultDto>(r => r.response);
  }

  function getSubchecklists() {
    return ajax
      .get(checklistUrls.getSubchecklists())
      .toPromise()
      .then<Workshop.Domain.AggregatesModel.ChecklistAggregate.Subchecklist[]>(r => r.response);
  }

  function getSubchecklistItemsForASubchecklist(subchecklistId: string) {
    return ajax
      .get(checklistUrls.getSubchecklistItemsForASubchecklist(subchecklistId))
      .toPromise()
      .then<Workshop.Domain.Queries.GetSubchecklistItems.SubchecklistItem[]>(r => r.response);
  }

  function checkForUniqueSubchecklistName(subchecklistName: string) {
    return ajax
      .get(checklistUrls.checkForUniqueSubchecklistName(subchecklistName))
      .toPromise()
      .then<Common.Dtos.UniqueNameCheckResultDto>(r => r.response);
  }

  function searchSubchecklistItems(searchText: string) {
    return ajax
      .get(checklistUrls.searchSubchecklistItems(searchText))
      .toPromise()
      .then<Workshop.Domain.Queries.SearchSubchecklistItems.SearchSubchecklistItemsResult>(
        r => r.response
      );
  }

  function updateChecklistSetInactive(command: SetChecklistInactiveCommand) {
    return ajax
      .put(checklistUrls.updateChecklistSetInactive(command.id), command)
      .toPromise()
      .then(r => r.response);
  }

  function updateChecklistSetActive(command: SetChecklistActiveCommand) {
    return ajax
      .put(checklistUrls.updateChecklistSetActive(command.id), command)
      .toPromise()
      .then(r => r.response);
  }

  function deleteChecklist(id: string) {
    return ajax
      .httpDelete(checklistUrls.deleteChecklist(id))
      .toPromise()
      .then(r => r.response);
  }

  function getCurrentUsage(id: string) {
    return ajax
      .get(checklistUrls.getCurrentUsage(id))
      .toPromise()
      .then<AggregateUsageDto>(r => r.response);
  }

  return {
    checklist: {
      listChecklists,
      getChecklist,
      getChecklistAssetGroups,
      getSubchecklists,
      createChecklist,
      updateChecklist,
      checkForUniqueChecklistName,
      getSubchecklistItemsForASubchecklist,
      checkForUniqueSubchecklistName,
      searchSubchecklistItems,
      updateChecklistSetInactive,
      updateChecklistSetActive,
      deleteChecklist,
      getCurrentUsage,
    },
  };
}
