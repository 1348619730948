import './DriverMovement.scss';
import { getDriverMovementDescriptor } from 'src/api/enums';

interface IDriverMovementProps {
  driverMovement: Operations.Domain.Queries.ListJobs.DriverMovement;
}

const DriverMovement: React.FC<IDriverMovementProps> = ({ driverMovement }) => {
  return (
    <span
      className={`driver-movement-component ${
        getDriverMovementDescriptor(driverMovement.id).name
      }`}>
      {driverMovement.description}
    </span>
  );
};

export default DriverMovement;
