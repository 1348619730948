import './ActionCollectionButton.scss';

import cn from 'classnames';
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { MoreHorizontalIcon } from 'src/images/icons';
import {
  isActionButtonDef,
  isActionLinkDef,
  IActionCollectionDef,
  IActionMeta,
  IActionData,
  isModalActionButtonDef,
  isRemoveArrayItemActionButtonDef,
  ActionType,
  IActionDef,
  isPrintActionButtonDef,
  isAddArrayItemActionButtonDef,
} from 'src/views/definitionBuilders/types';
import { Subject } from 'rxjs/Subject';
import ModalActionButton from './ModalActionButton';
import RemoveArrayItemActionButton from './RemoveArrayItemActionButton';
import ActionButton from './ActionButton';
import ActionLink from './ActionLink';
import PrintActionButton, {
  DefaultPrintIcon,
} from 'src/views/components/Page/actions/PrintActionButton';
import AddArrayItemActionButton from 'src/views/components/Page/actions/AddArrayItemActionButton';
import { Component, createRef } from 'react';

const defaultLabel = 'More';

interface IActionCollectionButtonProps {
  className?: string;
  actionDef: IActionCollectionDef;
  actionMeta: IActionMeta;
  actionData: IActionData;
  splitWithNode?: React.ReactNode;
}

class ActionCollectionButton extends Component<IActionCollectionButtonProps> {
  private readonly getDisabled = () => {
    const { actionDef, actionData } = this.props;

    if (typeof actionDef.disabled === 'function') {
      return actionDef.disabled(actionData);
    } else {
      return actionDef.disabled;
    }
  };

  private readonly getDropDownItemsContent = () => {
    const { actionDef: collectionActionDef, actionMeta, actionData } = this.props;
    const modals: Array<React.ReactNode> = [];
    const prints: Array<React.ReactNode> = [];

    const groups = collectionActionDef.actionGroups
      .map((g, i) =>
        g.actions
          .map((def, j) => {
            const key = `${i}-${j}`;
            if (typeof def.hidden === 'function' ? def.hidden(actionData) : def.hidden) {
              return null;
            } else if (isActionButtonDef(def)) {
              return (
                <ActionButton
                  key={key}
                  renderAsDropdownItem
                  actionDef={def}
                  actionMeta={actionMeta}
                  actionData={actionData}
                />
              );
            } else if (isActionLinkDef(def)) {
              return (
                <ActionLink
                  key={key}
                  renderAsDropdownItem
                  actionDef={def}
                  actionMeta={actionMeta}
                  actionData={actionData}
                />
              );
            } else if (isRemoveArrayItemActionButtonDef(def)) {
              return (
                <RemoveArrayItemActionButton
                  key={key}
                  renderAsDropdownItem
                  actionDef={def}
                  actionMeta={actionMeta}
                  actionData={actionData}
                />
              );
            } else if (isAddArrayItemActionButtonDef(def)) {
              return (
                <AddArrayItemActionButton
                  key={key}
                  renderAsDropdownItem
                  actionDef={def}
                  actionMeta={actionMeta}
                  actionData={actionData}
                />
              );
            } else if (isModalActionButtonDef(def)) {
              // As the rx producer and consumer live and die together, unsubscribe should not be necessary
              const modalObs = new Subject<IActionData>();

              const modalNode = (
                <ModalActionButton
                  key={key + '-modal'}
                  actionDef={def}
                  actionMeta={actionMeta}
                  actionData={actionData}
                  onBuildExternalTrigger={api =>
                    modalObs.subscribe(() => {
                      api.onShow();
                      def.onOpenModal &&
                        def.onOpenModal(actionData.parentValue, actionData.actionValue);
                    })
                  }
                />
              );
              modals.push(modalNode);

              const triggerNode = (
                <ActionButton
                  key={key}
                  renderAsDropdownItem
                  actionDef={{
                    ...(def as IActionDef),
                    actionType: ActionType.actionButton,
                    onClick: () => modalObs.next(actionData),
                  }}
                  actionMeta={actionMeta}
                  actionData={actionData}
                />
              );
              return triggerNode;
            } else if (isPrintActionButtonDef(def)) {
              const printRef = createRef<PrintActionButton>();

              const printNode = (
                <PrintActionButton
                  key={key + '-print'}
                  ref={printRef}
                  actionDef={def}
                  actionMeta={actionMeta}
                  actionData={actionData}
                />
              );
              prints.push(printNode);

              return (
                <ActionButton
                  key={key}
                  renderAsDropdownItem
                  actionDef={{
                    ...(def as IActionDef),
                    actionType: ActionType.actionButton,
                    onClick: () => {
                      printRef.current && printRef.current.trigger();
                    },
                    icon: <DefaultPrintIcon fixedWidth />,
                  }}
                  actionMeta={actionMeta}
                  actionData={actionData}
                />
              );
            }
            throw new Error('Action type not yet implemented: ' + def.actionType);
          })
          .filter(item => !!item)
      )
      .filter(g => g.length);

    const getDivider = (i: number) => <DropdownItem key={`${i}-divider`} divider />;
    const items = groups.reduce((acc, g, i) => [...acc, getDivider(i), ...g], []).slice(1);

    return {
      items,
      modals: <>{modals}</>,
      prints: <div className="action-collection-button-component-print-buttons">{prints}</div>,
    };
  };

  render() {
    const { className, actionDef, actionMeta: meta, splitWithNode } = this.props;
    const disabled = this.getDisabled();
    // `modals` should not render anything into the dom (portals are used) but need to be separate
    // from the drop down items so that they are not destroyed when the drop down closes.
    const { items, modals, prints } = this.getDropDownItemsContent();
    const colour = meta.danger
      ? 'danger'
      : !actionDef.level || actionDef.level === 'default'
      ? 'secondary'
      : actionDef.level;
    return items.length === 0 ? null : (
      <>
        <UncontrolledDropdown
          className={cn('action-collection-button-component', className)}
          {...{ group: !!splitWithNode }}>
          {splitWithNode || null}
          <DropdownToggle
            className="collection-toggle"
            title={actionDef.label || defaultLabel}
            disabled={disabled}
            size={meta.size}
            color={colour}
            outline={meta.danger ? false : meta.borderless}
            style={meta.borderless ? { border: 'none' } : undefined}>
            {actionDef.icon ? actionDef.icon : <MoreHorizontalIcon />}
            {meta.hideLabel ? null : actionDef.label || defaultLabel}
          </DropdownToggle>
          <DropdownMenu>{items}</DropdownMenu>
        </UncontrolledDropdown>
        {modals}
        {prints}
      </>
    );
  }
}

export default ActionCollectionButton;
