import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  InputGroup,
  InputGroupAddon,
  Label,
} from 'reactstrap';
import { Subscription } from 'rxjs/Subscription';
import { Subject } from 'rxjs/Subject';
import {
  ActionType,
  ISearchActionButtonDef,
  IActionMeta,
  IActionData,
} from 'src/views/definitionBuilders/types/action';
import ActionButton from './ActionButton';
import { SearchIcon, TimesIcon } from 'src/images/icons';
import withQueryParams, { IQueryParamsProps } from 'src/views/hocs/withQueryParams';
import { Component } from 'react';
import './SearchActionButton.scss';
interface ISearchActionButtonProps {
  actionDef: ISearchActionButtonDef;
  actionMeta: IActionMeta;
}

interface ISearchActionButtonState {
  isExpanded: boolean;
  bufferedValue: string | undefined;
  ignoreFilters?: boolean;
  isFilterExpanded: boolean;
}

type InternalProps = ISearchActionButtonProps &
  IQueryParamsProps<{ search: string; ignoreFilters: boolean }>;

class SearchActionButton extends Component<InternalProps, ISearchActionButtonState> {
  private readonly _subscriptions = new Subscription();
  private readonly _changeBuffer = new Subject<string>();

  constructor(props: InternalProps) {
    super(props);
    this.state = {
      isExpanded: !!this.props.getQueryParams().search,
      bufferedValue: undefined,
      ignoreFilters: false,
      isFilterExpanded: false,
    };
  }

  componentDidMount() {
    this.setState({ ignoreFilters: this.props.getQueryParams().ignoreFilters ?? false });
    this._subscriptions.add(
      this._changeBuffer
        .debounceTime(500)
        .distinctUntilChanged()
        .subscribe(search => {
          this.search(search);
        })
    );
  }

  componentWillUnmount() {
    this._subscriptions.unsubscribe();
  }

  private readonly handleStartSearch = () => {
    this.setState({ isExpanded: true });
  };

  private readonly handleStopSearch = () => {
    this.setState({ isExpanded: false });
    if (this.props.getQueryParams().ignoreFilters !== undefined) {
      this.setState({ isFilterExpanded: false });
      this.setState({ ignoreFilters: false });
      this.props.updateQueryParams({ ignoreFilters: false });
    }
    this.setState({ bufferedValue: undefined });
    this.props.updateQueryParams({ search: '' });
  };

  private readonly handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const search = e.target.value;
    this.setState({ bufferedValue: search });
    if (this.state.ignoreFilters !== undefined) {
      if (search.length >= (this.props.actionDef.minChar ?? 0)) {
        this.setState({ isFilterExpanded: true });
      }

      if (search.length < (this.props.actionDef.minChar ?? 0)) {
        this.setState({ isFilterExpanded: false });
      }
    }

    this._changeBuffer.next(search);
  };

  private readonly search = (search: string) => {
    if (
      (search === '' && !this.state.ignoreFilters) ||
      search.length >= (this.props.actionDef.minChar ?? 0)
    ) {
      this.setState({ bufferedValue: undefined });
      this.props.updateQueryParams({ search });
    }
  };

  render() {
    const { actionDef, actionMeta, getQueryParams } = this.props;
    const { isExpanded, bufferedValue } = this.state;
    const currentSearch =
      bufferedValue === undefined ? getQueryParams().search || '' : bufferedValue;
    const showExpanded = isExpanded || !!currentSearch;

    const searchButton = (
      <ActionButton
        actionDef={{
          actionType: ActionType.actionButton,
          label: isExpanded ? 'Clear' : 'Search',
          icon: isExpanded ? <TimesIcon /> : <SearchIcon />,
          onClick: showExpanded ? this.handleStopSearch : this.handleStartSearch,
          disabled: actionDef.disabled,
          hidden: actionDef.hidden,
        }}
        actionMeta={actionMeta}
        actionData={{} as IActionData} // This component doesn't use the action button's actionData
      />
    );

    if (!showExpanded) {
      return searchButton;
    }

    return (
      <div
        tabIndex={0}
        onBlur={event => {
          if (event.currentTarget.contains(event.relatedTarget as Node)) {
            return;
          }
          this.setState({ isFilterExpanded: false });
        }}>
        <InputGroup className={'search-input'}>
          {this.props.actionDef.ignoreFilters !== undefined ? (
            <Dropdown
              isOpen={this.state.isFilterExpanded}
              toggle={() => {
                if (currentSearch.length >= (this.props.actionDef.minChar ?? 0)) {
                  this.setState({ isFilterExpanded: true });
                }
              }}
              direction={'down'}>
              <DropdownToggle>
                <Input
                  autoComplete="off"
                  autoFocus
                  placeholder="type to filter"
                  onChange={this.handleSearchChange}
                  value={currentSearch}
                />
              </DropdownToggle>
              <DropdownMenu className={'apply-filters-menu'}>
                <DropdownItem
                  onClick={() => {
                    this.setState({ ignoreFilters: !this.state.ignoreFilters });
                    this.props.updateQueryParams({ ignoreFilters: !this.state.ignoreFilters });
                  }}>
                  <Input
                    addon
                    aria-label="Checkbox for applying filters"
                    type="checkbox"
                    checked={this.state.ignoreFilters?.toString() === 'true' ? true : false}
                    readOnly
                  />
                  <Label check>Ignore Filters</Label>
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          ) : (
            <Input
              autoComplete="off"
              autoFocus
              placeholder="type to filter"
              onChange={this.handleSearchChange}
              value={currentSearch}
            />
          )}

          <InputGroupAddon addonType="append">{searchButton}</InputGroupAddon>
        </InputGroup>
      </div>
    );
  }
}

export default withQueryParams(SearchActionButton);
