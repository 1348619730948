import { UiRouteName } from 'src/domain/baseTypes';
import { IRootStoreModel } from 'src/domain/entities/RootStoreModel';
import UiRoute from 'src/domain/services/buildUiRoutes/UiRoute';

export default function buildUiRoutes(store: IRootStoreModel) {
  const maintainTechSpecDropdownOptions = new UiRoute(
    UiRouteName.workshopTechSpecDropdownOptionsEdit,
    '/workshop/tech-specs/:id/dropdown-options',
    () => 'Manage Dropdown Options',
    false
  );

  const maintainTechSpecs = new UiRoute(
    UiRouteName.workshopTechSpecsEdit,
    '/workshop/tech-specs',
    () => 'Tech Specs',
    false,
    false,
    [maintainTechSpecDropdownOptions]
  );

  return {
    [UiRouteName.workshopTechSpecsEdit]: maintainTechSpecs,
    [UiRouteName.workshopTechSpecDropdownOptionsEdit]: maintainTechSpecDropdownOptions,
  };
}
