import inject from 'src/views/injectFromKioskStore';
import { AssetList, IAssetListProps } from './AssetList';

const container = inject<IAssetListProps>((allStores, p) => ({
  assets: allStores.rootStore.kiosk.asset.sortedAssets.slice(),
  loadAllAssets: allStores.rootStore.kiosk.asset.loadAssets,
  loadNextOpsJobForAllAssets: allStores.rootStore.kiosk.asset.loadNextOpsJobForAllAssets,
  nextOpsJobForAllAssets: allStores.rootStore.kiosk.asset.nextOpsJobForAllAssets,
}))(AssetList);

export default container;
