import { IAuditHistoryRowSeparator } from 'src/views/components/AuditHistory/IAuditHistoryRowSeparator';
import { DateTime } from 'luxon';
type NeedsVerificationStartDateItem = Operations.Domain.Queries.GetNeedsVerificationStartDate.NeedsVerificationStartDateItem;

export default function getSeparators(
  changesForVerification: NeedsVerificationStartDateItem | undefined
): IAuditHistoryRowSeparator[] {
  if (changesForVerification) {
    return [
      {
        auditLogId: changesForVerification.auditLogId,
        label: `Changes above need verification`,
      },
      {
        auditLogId: changesForVerification.auditLogId,
        label: `since ${DateTime.fromISO(
          changesForVerification.needsVerificationStartDate
        ).toLocaleString(DateTime.DATETIME_MED)}`,
      },
    ];
  }
  return [];
}
