import { Component } from 'react';
import { Collapse } from 'reactstrap';
import { PlusIcon, MinusIcon } from 'src/images/icons';
import './AuditHistorySection.scss';

export interface IAuditHistorySectionProps {
  label: string;
}

interface IAuditHistorySectionState {
  open: boolean;
}

class AuditHistorySection extends Component<IAuditHistorySectionProps, IAuditHistorySectionState> {
  constructor(props: IAuditHistorySectionProps) {
    super(props);
    this.state = {
      open: true,
    };
  }

  toggle() {
    this.setState({ open: !this.state.open });
  }

  render() {
    const { open } = this.state;
    const { children, label } = this.props;
    return (
      <div className="audit-history-section-component">
        <div>
          <strong onClick={() => this.toggle()}>
            <span className="icon">{open ? <MinusIcon /> : <PlusIcon />}</span> {label}
          </strong>
        </div>
        <Collapse isOpen={open}>{children}</Collapse>
      </div>
    );
  }
}

export default AuditHistorySection;
