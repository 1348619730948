import { DateTime } from 'luxon';
import { Link } from 'react-router-dom';
import { isNumeric } from 'rxjs/util/isNumeric';
import {
  costTypeDescription,
  quoteStatusDescription,
  skillSpecCategoryDescription,
} from 'src/api/enums';
import { IAutocompleteResult } from 'src/domain/baseTypes';
import { formatCurrency } from 'src/infrastructure/formattingUtils';
import { buildSubProperty, getCapitilizedPropertyName } from 'src/infrastructure/typeUtils';
import { getObject, isEmpty } from 'src/views/components/AuditHistory/AuditHistoryHelpers';
import { ISchemaRecord } from 'src/views/components/AuditHistory/ISchemaRecord';
import { DateFormat } from 'src/views/components/DateFormat';
import {
  getEditingFormattedTimeString,
  parseEditingFormattedTimeString,
} from 'src/views/components/Page/fields/subfields/TimeHelpers';

type VehicleType = Common.Dtos.VehicleTypeItem;
type ExtraType = Operations.Domain.Queries.ViewExtraType.ExtraTypeItem;
type NeedsVerificationStartDateItem = Operations.Domain.Queries.GetNeedsVerificationStartDate.NeedsVerificationStartDateItem;
type QuoteItem = Operations.Domain.Queries.ViewQuote.QuoteItem;
type ContactItem = Operations.Domain.Queries.ViewQuote.ContactItem;
type Customer = Operations.Domain.Queries.ViewQuote.Customer;
type Trip = Operations.Domain.Queries.ViewQuote.Trip;
type Option = Operations.Domain.Queries.ViewQuote.Option;
type Vehicle = Operations.Domain.Queries.ViewQuote.Vehicle;
type Extra = Operations.Domain.Queries.ViewQuote.Extra;
type CustomerItem = Operations.Domain.Queries.SearchCustomers.CustomerItem;
type QuoteTypeDto = Common.Dtos.QuoteTypeDto;
type SkillSpecs = Common.Dtos.SkillSpecItem;
type TechSpec = Common.Dtos.TechSpecItem;

interface OptionWeekend extends Operations.Domain.Queries.ViewQuote.Option {
  durationWeekend?: number;
}

export default function getSchema(
  findCustomers: (ids: string[]) => Promise<IAutocompleteResult<CustomerItem>>,
  findContactsForCustomer: (
    customerId: string | undefined,
    ids: number[]
  ) => Promise<IAutocompleteResult<ContactItem>>,
  vehicleTypes: Array<VehicleType>,
  extraTypes: Array<ExtraType>,
  changesForVerification: NeedsVerificationStartDateItem | undefined,
  quoteTypes: Array<QuoteTypeDto>,
  skillSpecs: SkillSpecs[],
  techSpecs: TechSpec[]
): ISchemaRecord[] {
  const membersToIgnore = [
    getCapitilizedPropertyName<QuoteItem>('id'),
    getCapitilizedPropertyName<ContactItem>('contactId'),
    getCapitilizedPropertyName<Customer>('customerId'),
    getCapitilizedPropertyName<QuoteItem>('description'),
    getCapitilizedPropertyName<QuoteItem>('driverNote'),
    getCapitilizedPropertyName<QuoteItem>('internalNote'),
    getCapitilizedPropertyName<QuoteItem>('customerNote'),
    getCapitilizedPropertyName<QuoteItem>('quoteType') + 'Id',
    getCapitilizedPropertyName<QuoteItem>('quoteType'),
    getCapitilizedPropertyName<QuoteItem>('repeats'),
    getCapitilizedPropertyName<QuoteItem>('invoiceNumber'),
    getCapitilizedPropertyName<QuoteItem>('purchaseOrderNumber'),
    buildSubProperty([
      getCapitilizedPropertyName<QuoteItem>('trips'),
      getCapitilizedPropertyName<Trip>('passengers'),
    ]),
    buildSubProperty([
      getCapitilizedPropertyName<QuoteItem>('trips'),
      getCapitilizedPropertyName<Trip>('description'),
    ]),
    buildSubProperty([
      getCapitilizedPropertyName<QuoteItem>('trips'),
      getCapitilizedPropertyName<Trip>('options'),
      getCapitilizedPropertyName<Option>('vehicles'),
      getCapitilizedPropertyName<Vehicle>('price'),
    ]),
    buildSubProperty([
      getCapitilizedPropertyName<QuoteItem>('trips'),
      getCapitilizedPropertyName<Trip>('options'),
      getCapitilizedPropertyName<Option>('vehicles'),
      getCapitilizedPropertyName<Vehicle>('bufferPrice'),
    ]),
    buildSubProperty([
      getCapitilizedPropertyName<QuoteItem>('trips'),
      getCapitilizedPropertyName<Trip>('options'),
      getCapitilizedPropertyName<Option>('vehicles'),
      getCapitilizedPropertyName<Vehicle>('overridePrice'),
    ]),
    buildSubProperty([
      getCapitilizedPropertyName<QuoteItem>('trips'),
      getCapitilizedPropertyName<Trip>('options'),
      getCapitilizedPropertyName<Option>('vehicles'),
      getCapitilizedPropertyName<Vehicle>('overrideReason'),
    ]),
    buildSubProperty([
      getCapitilizedPropertyName<QuoteItem>('trips'),
      getCapitilizedPropertyName<Trip>('options'),
      getCapitilizedPropertyName<Option>('vehicles'),
      getCapitilizedPropertyName<Vehicle>('pricePerVehicle'),
    ]),
    buildSubProperty([
      getCapitilizedPropertyName<QuoteItem>('trips'),
      getCapitilizedPropertyName<Trip>('options'),
      getCapitilizedPropertyName<Option>('extras'),
      getCapitilizedPropertyName<Extra>('price'),
    ]),
    buildSubProperty([
      getCapitilizedPropertyName<QuoteItem>('trips'),
      getCapitilizedPropertyName<Trip>('options'),
      getCapitilizedPropertyName<Option>('extras'),
      getCapitilizedPropertyName<Extra>('overridePrice'),
    ]),
    buildSubProperty([
      getCapitilizedPropertyName<QuoteItem>('trips'),
      getCapitilizedPropertyName<Trip>('options'),
      getCapitilizedPropertyName<Option>('durationWeekday'),
    ]),
    buildSubProperty([
      getCapitilizedPropertyName<QuoteItem>('trips'),
      getCapitilizedPropertyName<Trip>('options'),
      getCapitilizedPropertyName<OptionWeekend>('durationWeekend'),
    ]),
    buildSubProperty([
      getCapitilizedPropertyName<QuoteItem>('trips'),
      getCapitilizedPropertyName<Trip>('options'),
      getCapitilizedPropertyName<Option>('durationSaturday'),
    ]),
    buildSubProperty([
      getCapitilizedPropertyName<QuoteItem>('trips'),
      getCapitilizedPropertyName<Trip>('options'),
      getCapitilizedPropertyName<Option>('durationSunday'),
    ]),
    buildSubProperty([
      getCapitilizedPropertyName<QuoteItem>('trips'),
      getCapitilizedPropertyName<Trip>('options'),
      getCapitilizedPropertyName<Option>('durationPublicHoliday'),
    ]),
  ];
  const cutOffDate: DateTime | undefined =
    changesForVerification && changesForVerification.needsVerificationStartDate
      ? DateTime.fromISO(changesForVerification.needsVerificationStartDate)
      : undefined;
  // tslint:disable-next-line:no-any
  const highlight = (address: any[], recordDate: DateTime) => {
    const fullKey = address.filter(x => !isNumeric(x)).join('.');
    return (
      !membersToIgnore.some(y => y.startsWith(fullKey)) &&
      cutOffDate !== undefined &&
      recordDate >= cutOffDate
    );
  };
  return [
    {
      key: 'InvoiceNumber',
      label: 'Invoice Number',
      highlight,
    },
    {
      key: 'PurchaseOrderNumber',
      label: 'Purchase Order Number',
      highlight,
    },
    {
      key: 'CustomerId',
      label: 'Customer',
      highlight,
      format: value => {
        return findCustomers([value]).then(r => (
          <Link to={`/sales/customers/${value}`}>{r.options[0].customerName}</Link>
        ));
      },
    },
    {
      key: 'ContactId',
      label: 'Contact',
      highlight,
      format: (value, changes, address, valueType) => {
        const customerAddress = ['CustomerId'];
        const customerIdRecord = getObject(customerAddress, changes);
        const customerId = valueType === 'before' ? customerIdRecord.o : customerIdRecord.c;
        return findContactsForCustomer(customerId, [value]).then(r => {
          return r.options[0].contactName;
        });
      },
    },
    {
      key: 'QuoteTypeId',
      label: 'Quote Type',
      highlight,
      format: value => {
        const quoteType = quoteTypes && quoteTypes.find(x => x.id === value);
        return Promise.resolve((quoteType && quoteType.description) || value);
      },
    },
    {
      key: 'Description',
      highlight,
    },
    {
      key: 'StatusId',
      label: 'Status',
      highlight,
      format: value => Promise.resolve(quoteStatusDescription(value)),
    },
    {
      key: 'CustomerNote',
      label: 'Customer Note',
      highlight,
    },
    {
      key: 'InternalNote',
      label: 'Internal Note',
      highlight,
    },
    {
      key: 'DriverNote',
      label: 'Driver Note',
      highlight,
    },
    {
      key: 'DeclinedReason',
      label: 'Declined Reason',
      highlight,
    },
    {
      key: 'Repeats',
      highlight,
      isArray: true,
      childRecords: [
        {
          key: 'LinkedQuoteId',
          label: 'Linked Quote',
          highlight,
          format: value =>
            Promise.resolve(
              <Link to={`/operations/bookings-for-ops/${value}`}>Go To Booking</Link>
            ),
        },
        {
          key: 'RepeatDateAsDateTime',
          label: 'Repeat Date',
          highlight,
          format: value => Promise.resolve(<DateFormat value={value} />),
        },
      ],
    },
    {
      key: 'Trips',
      label: 'Trips',
      highlight,
      isArray: true,
      sectionLabel: (diff, changes, address) => {
        const trip = getObject(address, changes);

        if (!trip.TripNumber) {
          return `Trip Number Unknown`;
        }

        return `Trip ${Number(isEmpty(trip.TripNumber.c) ? trip.TripNumber.o : trip.TripNumber.c) +
          1}`;
      },
      orderKey: 'TripNumber',
      childRecords: [
        {
          key: 'Passengers',
          highlight,
        },
        {
          key: 'Description',
          highlight,
        },
        {
          key: 'Note',
          highlight,
        },
        {
          key: 'Routes',
          highlight,
          isArray: true,
          orderKey: 'RouteNumber',
          sectionLabel: (diff, changes, address) => {
            const route = getObject(address, changes);

            if (!route.RouteNumber) {
              return `Route Number Unknown`;
            }

            return `Route ${Number(
              isEmpty(route.RouteNumber.c) ? route.RouteNumber.o : route.RouteNumber.c
            ) + 1}`;
          },
          childRecords: [
            {
              key: 'DateAsDateTime',
              label: 'Date',
              highlight,
              format: value => Promise.resolve(<DateFormat value={value} />),
            },
            {
              key: 'Date',
              label: 'Date',
              highlight,
              format: value => Promise.resolve(<DateFormat value={value} />),
            },
            {
              key: 'RouteNumber',
              label: 'Route Number',
              highlight,
              format: value => value + 1,
            },
            {
              key: 'Name',
              highlight,
            },
            {
              key: 'Address',
              highlight,
            },
            {
              key: 'City',
              highlight,
            },
            {
              key: 'State',
              highlight,
            },
            {
              key: 'Postcode',
              highlight,
            },
            {
              key: 'Notes',
              highlight,
            },
            {
              key: 'Arrive',
              highlight,
              format: value =>
                Promise.resolve(
                  getEditingFormattedTimeString(parseEditingFormattedTimeString(value))
                ),
            },
            {
              key: 'Depart',
              highlight,
              format: value =>
                Promise.resolve(
                  getEditingFormattedTimeString(parseEditingFormattedTimeString(value))
                ),
            },
          ],
        },
        {
          key: 'Options',
          highlight,
          isArray: true,
          orderKey: 'OptionNumber',
          sectionLabel: (diff, changes, address) => {
            const option = getObject(address, changes);

            if (!option.OptionNumber) {
              return `Option Number Unknown`;
            }

            return `Option ${Number(
              isEmpty(option.OptionNumber.c) ? option.OptionNumber.o : option.OptionNumber.c
            ) + 1}`;
          },
          childRecords: [
            {
              key: 'CostTypeId',
              label: 'Cost Type',
              highlight,
              format: d => Promise.resolve(costTypeDescription(d)),
            },
            {
              key: 'OverrideTotalPriceExGst',
              label: 'Override Total Price Ex Gst',
              highlight,
              format: value => Promise.resolve(formatCurrency(value)),
            },
            {
              key: 'OverrideTotalGst',
              label: 'Override Total Gst',
              highlight,
              format: value => Promise.resolve(formatCurrency(value)),
            },
            {
              key: 'Distance',
              highlight,
            },
            {
              key: 'DurationWeekday',
              label: 'Duration Weekday',
              highlight,
            },
            {
              key: 'DurationWeekend',
              label: 'Duration Weekend',
              highlight,
            },
            {
              key: 'DurationSaturday',
              label: 'Duration Saturday',
              highlight,
            },
            {
              key: 'DurationSunday',
              label: 'Duration Sunday',
              highlight,
            },
            {
              key: 'DurationPublicHoliday',
              label: 'Duration Public Holiday',
              highlight,
            },
            {
              key: 'Selected',
              format: d => Promise.resolve(d ? 'Yes' : 'No'),
              highlight,
            },
            {
              key: 'Vehicles',
              highlight,
              isArray: true,
              sectionLabel: (diff, changes, address) => {
                return `Vehicle ${Number(address.pop()) + 1}`;
              },
              childRecords: [
                {
                  key: 'VehicleTypeId',
                  label: 'Vehicle Type',
                  highlight,
                  format: value => {
                    const vehicle = vehicleTypes && vehicleTypes.find(x => x.id === value);
                    return Promise.resolve((vehicle && vehicle.description) || value);
                  },
                },
                {
                  key: 'Quantity',
                  highlight,
                },
                {
                  key: 'Price',
                  highlight,
                  format: value => Promise.resolve(formatCurrency(value)),
                },
                {
                  key: 'BufferPrice',
                  label: 'Buffer Price',
                  highlight,
                  format: value => Promise.resolve(formatCurrency(value)),
                },
                {
                  key: 'OverridePrice',
                  label: 'Override Price',
                  highlight,
                  format: value => Promise.resolve(formatCurrency(value)),
                },
                {
                  key: 'OverrideReason',
                  label: 'Override Reason',
                  highlight,
                  format: value => Promise.resolve(formatCurrency(value)),
                },
                {
                  key: 'SkillSpecRequirements',
                  label: 'Skill Spec Requirements',
                  highlight,
                  isArray: true,
                  childRecords: [
                    {
                      key: 'SkillSpecId',
                      label: 'Skill Spec',
                      highlight,
                      format: value => {
                        const skillSpec = skillSpecs && skillSpecs.find(x => x.id === value);

                        return `${
                          skillSpec ? skillSpecCategoryDescription(skillSpec.category) : ''
                        } - ${skillSpec?.description}`;
                      },
                    },
                  ],
                },
                {
                  key: 'TechSpecRequirements',
                  label: 'Tech Spec Requirements',
                  highlight,
                  isArray: true,
                  showUnchanged: true,
                  sectionLabel: (diff, changes, address) => {
                    return `Tech Spec ${Number(address.pop()) + 1}`;
                  },
                  childRecords: [
                    {
                      key: 'TechSpecId',
                      label: 'Tech Spec',
                      highlight,
                      showUnchanged: true,
                      format: (diff, changes, address, valueType) => {
                        const techSpec = getObject(address.slice(0, address.length - 1), changes);
                        const techSpecItem =
                          techSpecs &&
                          techSpecs.find(
                            x => x.id === techSpec.TechSpecId?.c ?? techSpec.TechSpecId?.o
                          );

                        return techSpecItem?.description ?? 'Unknown';
                      },
                    },
                    {
                      key: 'Value',
                      label: 'Value',
                      highlight,
                      showUnchanged: true,
                      format: value => {
                        if (value) {
                          switch (typeof value) {
                            case 'boolean':
                              return `${value === true ? ' Yes' : ' No'}`;
                            case 'string':
                              return `${
                                value === 'true' ? ' Yes' : value === 'false' ? 'No' : value
                              }`;
                            default:
                              return `${value}`;
                          }
                        }
                        return value;
                      },
                    },
                  ],
                },
              ],
            },
            {
              key: 'Extras',
              highlight,
              isArray: true,
              sectionLabel: (diff, changes, address) => {
                return `Extra ${Number(address.pop()) + 1}`;
              },
              childRecords: [
                {
                  key: 'ExtraTypeId',
                  label: 'Extra Type',
                  highlight,
                  format: value => {
                    const extra = extraTypes && extraTypes.find(x => x.id === value);
                    return Promise.resolve((extra && extra.description) || value);
                  },
                },
                {
                  key: 'Quantity',
                  highlight,
                },
                {
                  key: 'Price',
                  highlight,
                  format: value => Promise.resolve(formatCurrency(value)),
                },
                {
                  key: 'OverridePrice',
                  label: 'Override Price',
                  highlight,
                  format: value => Promise.resolve(formatCurrency(value)),
                },
              ],
            },
          ],
        },
      ],
    },
  ];
}
