import inject from 'src/views/injectFromStore';
import MaintainRole, { IMaintainRoleProps } from './MaintainRole';
import Omit from 'src/infrastructure/omit';

const Container = inject<Omit<IMaintainRoleProps, 'mode'>, IMaintainRoleProps>((allStores, p) => ({
  canManageRoles: allStores.rootStore.account.isPeopleDepartmentMember,
  role: allStores.rootStore.people.role.role,
  onLoadRole: allStores.rootStore.people.role.loadRole,
  onUpdateRole: allStores.rootStore.people.role.updateRole,
  onCreateRole: allStores.rootStore.people.role.createRole,
  onDeleteRole: allStores.rootStore.people.role.deleteRole,
  checkForUniqueRoleName: allStores.rootStore.people.role.checkForUniqueName,
  listStaffMembersForRole: allStores.rootStore.people.role.listStaffMembers,
  assignStaffMembersToRole: allStores.rootStore.people.role.assignStaffMembersToRole,
  assignedStaffMembers: allStores.rootStore.people.role.staffMembers.slice(),
  removeStaffMemberFromRole: allStores.rootStore.people.staffMember.removeStaffMemberFromRole,
  loadAllStaffMembers: allStores.rootStore.people.staffMembers.loadAllStaffMembers,
  activeStaffMembers: allStores.rootStore.people.staffMembers.activeStaffMembers.slice(),
}))(MaintainRole);

export default Container;
