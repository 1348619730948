import styles from '../AssetDetails.module.scss';
import Spinner from 'src/views/components/Spinner';
import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';

type PartTransactionItem = Workshop.Domain.Queries.GetPartTransactions.PartTransactionItem;

export interface IAssetPartsUsedListDisplayProps {
  assetId: string;
  partsUsed?: PartTransactionItem[];
  loadPartsUsedForAsset: (assetId: string) => Promise<void>;
  deviceDepotId: number;
}

export const AssetPartsUsedListDisplay: React.FC<IAssetPartsUsedListDisplayProps> = ({
  assetId,
  partsUsed,
  loadPartsUsedForAsset,
  deviceDepotId,
}) => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true);
    loadPartsUsedForAsset(assetId).finally(() => setIsLoading(false));
  }, [assetId]);

  return (
    <Spinner show={isLoading} className={styles.scrollableTabbedArea}>
      <div className={styles.partsUsedList}>
        {isLoading ? (
          <div />
        ) : !partsUsed || partsUsed.length === 0 ? (
          <div>No parts have been used in tasks for this asset.</div>
        ) : (
          <table>
            <thead>
              <tr>
                <th>Part No.</th>
                <th>Description</th>
                <th>Qty</th>
                <th>Location</th>
                <th>Task</th>
              </tr>
            </thead>
            <tbody>
              {partsUsed
                .filter(p => p.location.depotId === deviceDepotId)
                .map((p, i) => (
                  <tr key={i}>
                    <td>{p.partNumber}</td>
                    <td>{p.description}</td>
                    <td>{p.quantity}</td>
                    <td>{p.location.location}</td>
                    <td>
                      <Link to={`/wskiosk/task/${p.taskId}`}>{p.taskNumber}</Link>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        )}
      </div>
    </Spinner>
  );
};
