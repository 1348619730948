import { WarnIcon } from 'src/images/icons';
import memoizeOne from 'src/infrastructure/memoizeOne';
import { FieldType, IReadonlyFieldDef } from 'src/views/definitionBuilders/types';
import ContinuationsIndicator from './ContinuationsIndicator';

interface IJobLike {
  jobNumber: number;
  isCancelled: boolean;
  needsVerification?: boolean;
  isContinuingFrom: boolean;
  isContinuingTo: boolean;
}

type GetReadonlyJobNumberFieldDef = <TFieldParent extends IJobLike>(
  jobIdKey: keyof TFieldParent
) => IReadonlyFieldDef;

export const getReadonlyJobNumberFieldDef = memoizeOne<GetReadonlyJobNumberFieldDef>(
  <TFieldParent extends IJobLike>(jobIdKey: keyof TFieldParent): IReadonlyFieldDef => {
    return {
      fieldType: FieldType.readonlyField,
      dataAddr: 'jobNumber',
      label: 'Job',
      formatReadonly: d => {
        const job = d.parentValue as TFieldParent;
        return (
          <span>
            {job.needsVerification ? <WarnIcon color="red" title="Job needs verification" /> : null}
            {d.fieldValue}
            {job.isCancelled ? <>&nbsp;(Cancelled)</> : null}
            {job.isContinuingFrom || job.isContinuingTo ? <>&nbsp;</> : null}
            <ContinuationsIndicator job={job} />
          </span>
        );
      },
      linkTo: d => `/operations/jobs/${d.parentValue[jobIdKey]}`,
    };
  }
);
