import { PureComponent } from 'react';
import LabelledField from 'src/views/components/LabelledField';
import { IReadonlyFieldDef, IFieldMeta, IFieldData } from 'src/views/definitionBuilders/types';

interface IReadonlyPageFieldProps {
  fieldDef: IReadonlyFieldDef;
  fieldMeta: IFieldMeta;
  fieldData: IFieldData<unknown>;
}

class ReadonlyPageField extends PureComponent<IReadonlyPageFieldProps> {
  render() {
    const { fieldDef: def, fieldMeta: meta, fieldData: data } = this.props;
    const safeValue = data.fieldValue === 0 || data.fieldValue ? data.fieldValue : '';
    const labelText = typeof def.label === 'function' ? def.label(data) : def.label;
    const tooltipText = typeof def.tooltip === 'function' ? def.tooltip(data) : def.tooltip;
    return (
      <LabelledField
        labelValue={labelText}
        tooltipValue={tooltipText}
        hideLabel={meta.hideLabel}
        noForm={meta.noForm}
        readonly
        readonlyValue={def.formatReadonly ? def.formatReadonly(data) : `${safeValue}`}
        readonlyLinkTo={def.linkTo && data.fieldValue ? def.linkTo(data) : undefined}
      />
    );
  }
}

export default ReadonlyPageField;
