import { types } from 'mobx-state-tree';
import { INotificationOptions, NotificationType } from 'src/domain/baseTypes';
import { getBus } from 'src/domain/services/';
import { ReplaySubject } from 'rxjs/ReplaySubject';
import { Observable } from 'rxjs/Observable';

export interface INotification {
  message: string;
  options: INotificationOptions;
}

const NotificationsModelBase = types.model('NotificationsModel', {}).extend(self => {
  const notification$ = new ReplaySubject<INotification>(10, 5000); // Buffer up to 10 notifications for 5sec

  function addNotification(message: string, options?: Partial<INotificationOptions>) {
    notification$.next({
      message,
      options: Object.assign({}, { type: NotificationType.error, autoClose: undefined }, options),
    });
  }

  return {
    views: {
      get notification$() {
        return notification$ as Observable<INotification>;
      },
    },
    actions: {
      addNotification,
    },
  };
});

type INotificationsModelType = typeof NotificationsModelBase.Type;
export interface INotificationsModel extends INotificationsModelType {}

export const NotificationsModel = NotificationsModelBase.actions(self => {
  const bus = getBus(self);

  function afterCreate() {
    bus.showNotification$.subscribe(n => self.addNotification(n.message, n.options));
  }

  return { afterCreate };
});
