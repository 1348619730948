import inject from 'src/views/injectFromStore';
import Omit from 'src/infrastructure/omit';
import MaintainPart, { IMaintainPartProps } from './maintainPart';

const Container = inject<Omit<IMaintainPartProps, 'mode'>, IMaintainPartProps>((allStores, p) => ({
  canManageParts: allStores.rootStore.account.isWorkshopDepartmentMember,
  part: allStores.rootStore.workshop.part.part,
  partCategories: allStores.rootStore.workshop.partCategories.partCategoryListItems.slice(),
  onLoadPart: allStores.rootStore.workshop.part.loadPart,
  onUpdatePart: allStores.rootStore.workshop.part.updatePart,
  setPartActive: allStores.rootStore.workshop.part.setPartActive,
  setPartInactive: allStores.rootStore.workshop.part.setPartInactive,
  onCreatePart: allStores.rootStore.workshop.part.createPart,
  onLoadPartUsage: allStores.rootStore.workshop.part.loadPartUsage,
  partUsage: allStores.rootStore.workshop.part.partUsage,
  checkForUniquePartNumber: allStores.rootStore.workshop.part.checkForUniquePartNumber,
  loadPartCategories: allStores.rootStore.workshop.partCategories.loadPartCategoryListItems,
  onCreateStockAdjustment: allStores.rootStore.workshop.part.createStockAdjustment,
  deletePart: allStores.rootStore.workshop.part.deletePart,
  searchSuppliers: allStores.rootStore.workshop.supplier.searchSuppliers,
  findSuppliers: allStores.rootStore.workshop.supplier.findSuppliers,
  checklists: allStores.rootStore.workshop.partChecklists.checklists.slice(),
  loadChecklists: allStores.rootStore.workshop.partChecklists.loadChecklists,
  workshopDepots: allStores.rootStore.workshopStartup.workshopDepots.slice(),
  defaultWorkshopDepot: allStores.rootStore.workshopStartup.defaultWorkshopDepot,
}))(MaintainPart);

export default Container;
