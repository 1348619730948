import { IAjax } from 'src/infrastructure/ajax';
import { operationsUrls } from 'src/domain/services/apiUrls';

type TotalPaidHoursForJob = Operations.Domain.Queries.GetPaidHoursForJob.TotalPaidHoursForJob;

export default function init(ajax: IAjax) {
  function getPaidHoursForJob(jobId: string) {
    return ajax
      .get(operationsUrls.paidHoursUrls.getPaidHoursForJob(jobId))
      .toPromise()
      .then<TotalPaidHoursForJob>(r => r.response);
  }

  return {
    paidHours: {
      getPaidHoursForJob,
    },
  };
}
