import { Component } from 'react';
import {
  PaneType,
  FieldType,
  ActionType,
  ShellModalSize,
} from 'src/views/definitionBuilders/types';
import { ListPage } from 'src/views/components/Page';
import { TaskCardItem, toActionLinkDef } from 'src/views/components/TaskCard';
import { CalendarIcon } from 'src/images/icons';
import withQueryParams, { IQueryParamsProps } from 'src/views/hocs/withQueryParams';
import { IListPageDef } from 'src/views/components/Page/pages/ListPage';
import { IListPageLoadDataRequest } from 'src/domain/baseTypes';
import getScheduleServiceModalDef from '../serviceHistory/getScheduleServiceModalDef';
import { ScheduleServiceArgs } from 'src/domain/entities/workshop/asset/AssetModel';
import PrimaryTitle from 'src/views/components/Page/PrimaryTitle/PrimaryTitle';

type AssetServiceDueListItem = Workshop.Domain.Queries.AssetServices.AssetServiceDueListItem;
type GetAssetServiceDueListQuery = Workshop.Domain.Queries.AssetServices.GetAssetServiceDueList.GetAssetServiceDueListQuery;
type AssetCategory = Workshop.Domain.AggregatesModel.AssetAggregate.AssetCategory;
type AssetSubcategory = Workshop.Domain.AggregatesModel.AssetAggregate.AssetSubcategory;
type AssetHousingLocation = Common.Queries.Workshop.GetAssetHousingLocations.AssetHousingLocationItem;
type WorkshopDepot = Common.Queries.Workshop.GetWorkshopDepots.WorkshopDepotDto;
type JobSummaryDto = Workshop.Domain.Queries.Job.JobSummaryDto;

export interface IServicesDueByDateProps {
  canManageAssets: boolean;
  servicesDueByDate: AssetServiceDueListItem[];
  getServicesDue: (request: IListPageLoadDataRequest<GetAssetServiceDueListQuery>) => Promise<void>;
  assetCategories: Array<AssetCategory>;
  assetSubcategories: Array<AssetSubcategory>;
  assetHousingLocations: Array<AssetHousingLocation>;
  loadAssetCategories: () => Promise<void>;
  loadSubcategories: () => Promise<void>;
  loadAssetHousedAtLocations: () => Promise<void>;
  onSchedulePreventativeMaintenanceService: (command: ScheduleServiceArgs) => Promise<void>;
  workshopDepots: Array<WorkshopDepot>;
  defaultWorkshopDepot: WorkshopDepot | undefined;
  futureJobs: Array<JobSummaryDto>;
  onLoadAssetFutureJobs: (id: string) => Promise<void>;
}

function getPageDef(props: IServicesDueByDateProps): IListPageDef {
  const {
    canManageAssets,
    getServicesDue,
    assetCategories,
    assetSubcategories,
    assetHousingLocations,
    onSchedulePreventativeMaintenanceService,
    workshopDepots,
    defaultWorkshopDepot,
    futureJobs,
    onLoadAssetFutureJobs,
  } = props;
  const topTasksActions = [
    ...(canManageAssets ? [TaskCardItem.createAsset] : []),
    TaskCardItem.odometerReadings,
    TaskCardItem.servicesDueBykms,
  ].map(toActionLinkDef);
  return {
    primaryTitle: <PrimaryTitle title="Services Due (Days)"></PrimaryTitle>,
    onLoadData: getServicesDue,
    externalSearch: true,
    primaryFields: [
      {
        fieldType: FieldType.textField,
        dataAddr: 'assetName',
        label: 'Asset',
        linkTo: d => `/workshop/assets/${d.parentValue.assetId}`,
      },
      {
        fieldType: FieldType.textField,
        dataAddr: ['assetGroup.description'],
        label: 'Asset Group',
      },
      {
        fieldType: FieldType.dateField,
        dataAddr: ['nextServiceDueDate'],
        label: 'Due At',
      },
      {
        fieldType: FieldType.numericField,
        dataAddr: ['nextServiceDueInDays'],
        label: 'Due In (Days)',
      },
      {
        fieldType: FieldType.textField,
        dataAddr: ['serviceTypeDescription'],
        label: 'Next Service Type',
      },
      {
        fieldType: FieldType.numericField,
        dataAddr: ['serviceIntervalDays'],
        label: 'Service Interval',
      },
      {
        fieldType: FieldType.actionListField,
        label: 'Schedule',
        dataAddr: '',
        actionGroups: [
          {
            actions: [
              {
                hidden: d => !d.parentValue.isAssetComponent,
                actionType: ActionType.modalActionButton,
                label: 'Schedule Service',
                icon: <CalendarIcon />,
                modalSize: ShellModalSize.oneThird,
                onOpenModal: parent => {
                  onLoadAssetFutureJobs(parent.assetId);
                },
                modalDef: api =>
                  getScheduleServiceModalDef(
                    onSchedulePreventativeMaintenanceService,
                    futureJobs,
                    undefined,
                    api.actionData.parentValue.assetId,
                    api.actionData.parentValue.assetComponentId,
                    workshopDepots,
                    defaultWorkshopDepot
                  )(api),
              },
              {
                hidden: d => d.parentValue.isAssetComponent,
                actionType: ActionType.actionLink,
                label: 'Schedule Service',
                icon: <CalendarIcon />,
                to: data => `/workshop/assets/${data.parentValue.assetId}/service-schedule`,
              },
            ],
          },
        ],
      },
    ],
    filterAction: {
      filterFields: [
        {
          fieldType: FieldType.selectMultiField,
          label: 'Category',
          dataAddr: 'categories',
          useValueOnly: true,
          valueKey: 'id',
          descriptionKey: 'description',
          optionItems: assetCategories,
        },
        {
          fieldType: FieldType.selectMultiField,
          label: 'Subcategory',
          dataAddr: 'subcategories',
          useValueOnly: true,
          valueKey: 'id',
          descriptionKey: 'description',
          optionItems: assetSubcategories,
        },
        {
          fieldType: FieldType.selectMultiField,
          label: 'Garaged At',
          dataAddr: 'housingLocations',
          useValueOnly: true,
          valueKey: 'id',
          descriptionKey: 'description',
          optionItems: assetHousingLocations,
        },
        {
          fieldType: FieldType.yesNoField,
          dataAddr: 'isPreventativeMaintenanceServicesOnly',
          label: 'Preventative Maintenance Services',
        },
      ],
    },
    secondarySections: [
      {
        title: 'Navigation',
        panels: [
          {
            title: 'Top Tasks',
            panes: [
              {
                paneType: PaneType.navListPane,
                links: topTasksActions,
              },
            ],
          },
        ],
      },
    ],
  };
}

class ServicesDueByDate extends Component<
  IServicesDueByDateProps & IQueryParamsProps<GetAssetServiceDueListQuery>
> {
  componentDidMount() {
    this.props.loadAssetCategories();
    this.props.loadSubcategories();
    this.props.loadAssetHousedAtLocations();
  }

  render() {
    return (
      <ListPage
        className="list-services-due-component"
        data={this.props.servicesDueByDate}
        def={getPageDef(this.props)}
      />
    );
  }
}

export default withQueryParams(ServicesDueByDate);
