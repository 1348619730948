import { types, flow, getRoot } from 'mobx-state-tree';
import { IRootStoreModel } from 'src/domain/entities/RootStoreModel';
import { getAjax } from 'src/domain/services/storeEnvironment';
import { NotificationType } from 'src/domain';

export const JobPdfModel = types.model('JobPdfModel', {}).actions(self => {
  const ajax = getAjax(self);
  const root = getRoot<IRootStoreModel>(self);

  const generateJobWorkOrderReport = flow(function*(jobId: string, internalUse: boolean) {
    root.notifications.addNotification(`The file is being generated ...`, {
      type: NotificationType.info,
    });
    return yield ajax.job.generateJobWorkOrderReport(jobId, internalUse);
  });

  return {
    generateJobWorkOrderReport,
  };
});
