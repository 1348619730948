import inject from 'src/views/injectFromStore';
import ListRoles, { IListRolesProps } from './ListRoles';

const Container = inject<IListRolesProps>((allStores, p) => ({
  canManageRoles: allStores.rootStore.account.isPeopleDepartmentMember,
  loadRoles: allStores.rootStore.people.roles.listItems,
  roles: allStores.rootStore.people.roles.items.slice(),
}))(ListRoles);

export default Container;
