import { IAjax } from 'src/infrastructure/ajax';
import { peopleUrls } from 'src/domain/services/apiUrls';

type NoteItem = People.Domain.Queries.ViewNote.NoteItem;
type UpdateNoteCommand = People.Domain.Commands.Note.UpdateNote.UpdateNoteCommand;

export default function init(ajax: IAjax) {
  function createNote(command: People.Domain.Commands.Note.CreateNote.CreateNoteCommand) {
    return ajax
      .post(peopleUrls.noteUrls.createNote(), command)
      .toPromise()
      .then<string>(r => r.response);
  }

  function loadNotes(query: Partial<People.Domain.Queries.ListNotes.ListNotesQuery>) {
    return ajax
      .get(peopleUrls.noteUrls.loadNotes(query))
      .map(r => r.response as Common.Dtos.ListResult<People.Domain.Queries.ListNotes.ListNoteItem>);
  }

  function loadNote(noteId: string) {
    return ajax
      .get(peopleUrls.noteUrls.loadNote(noteId))
      .toPromise()
      .then<NoteItem>(r => r.response);
  }

  function updateNote(command: UpdateNoteCommand) {
    return ajax.put(peopleUrls.noteUrls.updateNote(command.id), command).toPromise();
  }

  function deleteNote(noteId: string) {
    return ajax.httpDelete(peopleUrls.noteUrls.deleteNote(noteId)).toPromise();
  }

  return {
    note: {
      createNote,
      loadNotes,
      loadNote,
      updateNote,
      deleteNote,
    },
  };
}
