import { IRootStoreModel } from 'src/domain/entities/RootStoreModel';
import buildOwners from './owners';
import buildEngines from './engines';
import buildAssetGroups from './assetGroups';
import buildPartsGroups from './partsGroups';
import buildPartCategories from './partCategories';
import buildComponentTypes from './componentTypes';
import buildTechSpecs from './techSpecs';
import buildTaskSubcategories from './taskSubcategories';
import buildLabourPrices from './labourPrices';
import buildMachineryScheduleTemplate from './machinerySchedule';
import buildAssetAttachments from './assetAttachments';

export default function buildUiRoutes(store: IRootStoreModel) {
  const owners = buildOwners(store);
  const engines = buildEngines(store);
  const assetGroups = buildAssetGroups(store);
  const partsGroups = buildPartsGroups(store);
  const partCategories = buildPartCategories(store);
  const componentTypes = buildComponentTypes(store);
  const techSpecs = buildTechSpecs(store);
  const taskSubcategories = buildTaskSubcategories(store);
  const labourPrices = buildLabourPrices(store);
  const machineryScheduleTemplate = buildMachineryScheduleTemplate(store);
  const assetAttachments = buildAssetAttachments(store);

  return {
    ...owners,
    ...engines,
    ...assetGroups,
    ...partsGroups,
    ...partCategories,
    ...componentTypes,
    ...techSpecs,
    ...taskSubcategories,
    ...labourPrices,
    ...machineryScheduleTemplate,
    ...assetAttachments,
  };
}
