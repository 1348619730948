import { PlusIcon, EditIcon, TrashIcon } from 'src/images/icons';
import {
  PaneType,
  FieldType,
  ActionType,
  ShellModalSize,
  ISectionDef,
} from 'src/views/definitionBuilders/types';
import getEquipmentModalDef from './getEquipmentModalDef';
import { getSubmitCloseModalActionGroupDef } from 'src/views/definitionBuilders/common';

type StaffMemberEquipmentItem = People.Domain.Queries.ViewStaffMember.StaffMemberItem.StaffMemberEquipmentItem;
type EquipmentTypeItem = People.Domain.Queries.EquipmentType.EquipmentTypeItem;
type CreateStaffMemberEquipmentCommand = People.Domain.Commands.StaffMembers.Equipment.CreateStaffMemberEquipmentCommand;
type UpdateStaffMemberEquipmentCommand = People.Domain.Commands.StaffMembers.Equipment.UpdateStaffMemberEquipmentCommand;
type DeleteStaffMemberEquipmentCommand = People.Domain.Commands.StaffMembers.Equipment.DeleteStaffMemberEquipmentCommand;

export default function getEquipmentSectionDef(
  canSave: boolean,
  allEquipmentTypes: EquipmentTypeItem[],
  staffMemberId: string,
  createEquipment: (cmd: CreateStaffMemberEquipmentCommand) => Promise<void>,
  updateEquipment: (cmd: UpdateStaffMemberEquipmentCommand) => Promise<void>,
  deleteEquipment: (cmd: DeleteStaffMemberEquipmentCommand) => Promise<void>,
  primarySectionEditing: boolean
): ISectionDef {
  return {
    title: 'Equipment',
    panels: [
      {
        panes: [
          {
            paneType: PaneType.tablePane,
            dataAddr: 'equipment',
            neverEditable: true,
            fields: [
              {
                fieldType: FieldType.readonlyField,
                dataAddr: 'equipmentTypeId',
                label: 'Type',
                formatReadonly: d =>
                  allEquipmentTypes.find(x => x.id === d.fieldValue)?.description,
              },
              {
                fieldType: FieldType.textField,
                dataAddr: 'description',
                label: 'Description',
              },
              {
                fieldType: FieldType.textField,
                dataAddr: 'size',
                label: 'Size',
              },
              {
                fieldType: FieldType.textField,
                dataAddr: 'quantity',
                label: 'Qty',
              },
              {
                fieldType: FieldType.dateField,
                dataAddr: 'dateIssued',
                label: 'Issued',
              },
              {
                fieldType: FieldType.actionListField,
                columnWidth: '1px',
                hidden: () => !canSave || primarySectionEditing,
                actionGroups: [
                  {
                    actions: [
                      {
                        actionType: ActionType.modalActionButton,
                        label: 'Edit Equipment',
                        icon: <EditIcon />,
                        modalSize: ShellModalSize.half,
                        modalDef: getEquipmentModalDef(
                          'edit',
                          allEquipmentTypes,
                          staffMemberId,
                          createEquipment,
                          updateEquipment
                        ),
                      },
                      {
                        actionType: ActionType.modalActionButton,
                        icon: <TrashIcon />,
                        label: 'Delete Equipment',
                        modalSize: ShellModalSize.oneQuarter,
                        modalDef: modalDefApi => ({
                          title: 'Delete Equipment',
                          asForm: true,
                          panels: [
                            {
                              panes: [
                                {
                                  paneType: PaneType.customPane,
                                  render: _ => (
                                    <p>Are you sure you want to delete this equipment?</p>
                                  ),
                                },
                              ],
                            },
                          ],
                          secondaryActions: [getSubmitCloseModalActionGroupDef('Ok')],
                          onFormSubmit: () => {
                            const equipment = modalDefApi.actionData
                              .parentValue as StaffMemberEquipmentItem;
                            return deleteEquipment({
                              staffMemberId: staffMemberId,
                              equipmentId: equipment.id,
                            });
                          },
                        }),
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
    secondaryActions: [
      {
        actions: [
          {
            hidden: () => !canSave || primarySectionEditing,
            actionType: ActionType.modalActionButton,
            label: 'Add Equipment',
            icon: <PlusIcon />,
            level: 'primary',
            modalSize: ShellModalSize.half,
            modalDef: getEquipmentModalDef(
              'add',
              allEquipmentTypes,
              staffMemberId,
              createEquipment,
              updateEquipment
            ),
          },
        ],
      },
    ],
  };
}
