import { IFieldApi } from 'src/views/components/Page/forms/Field';
import {
  IFieldData,
  IFieldDef,
  IListFieldDefBase,
  IFieldMeta,
  IListSyncFieldDefBase,
} from 'src/views/definitionBuilders/types';
import ListFieldBase from './ListFieldBase';

interface IListSyncFieldBaseProps {
  // tslint:disable-next-line:no-any
  fieldDef: IFieldDef & IListFieldDefBase<any> & IListSyncFieldDefBase;
  // tslint:disable-next-line:no-any
  fieldData: IFieldData<any>;
  fieldMeta: IFieldMeta;
  fieldApi: IFieldApi;
}

abstract class ListSyncFieldBase<
  TProps extends IListSyncFieldBaseProps,
  TState = {}
> extends ListFieldBase<TProps, TState> {
  protected readonly items = () => {
    const { fieldDef: def, fieldData } = this.props;
    const optionItems =
      (typeof def.optionItems === 'function' ? def.optionItems(fieldData) : def.optionItems) || [];
    const valuesToDisable = this.getValuesToDisable();
    const optsToExclude = this.getValuesToExclude();
    const optionItemsItemsExcluded = optionItems.filter(
      o => !optsToExclude.some(e => o[def.valueKey] === e)
    );

    return valuesToDisable.length
      ? optionItemsItemsExcluded.map(o => ({
          ...o,
          disabled: valuesToDisable.some(v => o[def.valueKey] === v),
        }))
      : optionItemsItemsExcluded;
  };
}

export default ListSyncFieldBase;
