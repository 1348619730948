import { DateTime } from 'luxon';
import { observer } from 'mobx-react';
import { useEffect } from 'react';
import Select from 'react-select';
import { fatigueRuleSetTypeDescription } from 'src/api/enums';
import { useRootStore } from 'src/domain/entities/RootStoreModel';
import { AngleLeft, AngleRight } from 'src/images/icons';
import { LabelledFormField } from 'src/views/components/forms';
import Page from 'src/views/components/Page';
import {
  ActionType,
  IPageDef,
  PagePrimarySize,
  PaneType,
} from 'src/views/definitionBuilders/types';
import withQueryParams, { IQueryParamsProps } from 'src/views/hocs/withQueryParams';
import { toJS } from 'mobx';
import FatigueDetailsRuleCard from './FatigueDetailsRuleCard';
import { CardGroup } from 'reactstrap';
import styles from './FatigueDetails.module.scss';

interface IFatigueDetailsProps {}

type InternalProps = IFatigueDetailsProps &
  IQueryParamsProps<{
    day: string;
    staffMember?: string;
    ruleSetType?: string;
  }>;
type GetFatigueDetailsQuery = Operations.Domain.Queries.GetFatigueDetails.GetFatigueDetailsQuery;

const FatigueDetails: React.FC<InternalProps> = observer((props: InternalProps) => {
  const rootStore = useRootStore();
  const model = rootStore.compliance.fatigueDetails;
  const staffMembersModel = rootStore.people.staffMembers;
  const staffMembers = staffMembersModel.allStaffMemberNames.slice();
  const { day, staffMember } = props.getQueryParams();

  if (day) model.setSelectedDate(DateTime.fromISO(day));
  model.setStaffMember(staffMember);

  const setCurrentDay = (newDay: DateTime) => {
    props.updateQueryParams({ day: newDay.toISODate() });
  };

  const selectStaffMember = (newStaffMember: Common.Dtos.StaffMemberDto) => {
    props.updateQueryParams({ staffMember: newStaffMember?.id });
  };

  const handleNext = () => {
    if (model.selectedDate) setCurrentDay(model.selectedDate.plus({ days: 1 }));
  };

  const handlePrev = () => {
    if (model.selectedDate) setCurrentDay(model.selectedDate.plus({ days: -1 }));
  };

  useEffect(() => {
    staffMembersModel.loadAllStaffMemberNames({});
  }, []);

  useEffect(() => {
    const query = toJS({
      day: model.selectedDate?.toISODate(),
      staffMemberId: model.staffMember as string,
    }) as GetFatigueDetailsQuery;
    if (model.selectedDate && model.staffMember) model.loadFatigue(query);
  }, [model.selectedDate, model.staffMember]);

  const getPageDef = (): IPageDef => {
    return {
      primarySize: PagePrimarySize.full,
      primarySection: {
        title: `Fatigue ${model.selectedDate?.toLocaleString(DateTime.DATE_HUGE)}`,
        primaryActions: [
          {
            actions: [
              {
                actionType: ActionType.actionButton,
                label: 'Previous Day',
                icon: <AngleLeft size="lg" />,
                onClick: () => handlePrev(),
              },
              {
                actionType: ActionType.calendarButton,
                onChange: setCurrentDay,
                value: model.selectedDate,
              },
              {
                actionType: ActionType.actionButton,
                label: 'Next Day',
                icon: <AngleRight size="lg" />,
                onClick: () => handleNext(),
              },
            ],
          },
        ],
        secondaryActions: [],
        panels: [
          {
            panes: [
              {
                paneType: PaneType.customPane,
                render: d => {
                  return (
                    <>
                      <div>
                        <div className={styles.staffMemberSelection}>
                          {staffMembers && staffMembers.length > 0 && (
                            <LabelledFormField labelValue="Staff Member">
                              <Select
                                options={staffMembers}
                                clearable={true}
                                valueKey="id"
                                labelKey="name"
                                value={model.staffMember}
                                onChange={sm => selectStaffMember(sm as Common.Dtos.StaffMemberDto)}
                              />
                            </LabelledFormField>
                          )}
                        </div>
                        {model.details && (
                          <div className={styles.staffMemberHeader}>
                            <span>
                              <strong>
                                {staffMembers.find(s => s.id === model.staffMember)?.name}{' '}
                              </strong>
                            </span>
                            {!model.isFutureDateSelected && <span>Currently working under </span>}
                            {model.isFutureDateSelected && <span>Last worked ruleset was </span>}
                            <span>
                              {fatigueRuleSetTypeDescription(model.details.currentDriverRuleSet)}{' '}
                              hours
                            </span>
                          </div>
                        )}
                      </div>

                      {model.details && (
                        <div className={styles.fatigueReportContainer}>
                          {model.details.fatigueRulesetRuleReport.map((report, i) => (
                            <>
                              <div className={styles.fatigueRuleSetType}>
                                {fatigueRuleSetTypeDescription(report.ruleSetType)}
                              </div>

                              <CardGroup className={styles.fatigueDetailsRules}>
                                {report.fatigueRuleReports.map(r => (
                                  <FatigueDetailsRuleCard key={i} ruleDetail={r} />
                                ))}
                              </CardGroup>
                            </>
                          ))}
                        </div>
                      )}
                    </>
                  );
                },
              },
            ],
          },
        ],
      },
    };
  };

  return (
    <Page
      className={styles.fatigueDetailsComponent}
      def={getPageDef()}
      data={model}
      showPrimarySectionSpinner={model.isDataLoading}
    />
  );
});

export default withQueryParams(FatigueDetails);
