import { PaneType, FieldType, ModalDefBuilder } from 'src/views/definitionBuilders/types';
import { DateTime } from 'luxon';
import { ScheduleServiceArgs } from 'src/domain/entities/workshop/asset/AssetModel';
import IntervalFormat from 'src/views/components/IntervalFormat/IntervalFormat';
import { getSubmitCloseModalActionGroupDef } from 'src/views/definitionBuilders/common';

type AssetPlanItem = Workshop.Domain.Queries.AssetServices.GetAssetServicePlan.AssetPlanItem;
type JobSummaryDto = Workshop.Domain.Queries.Job.JobSummaryDto;
type WorkshopDepot = Common.Queries.Workshop.GetWorkshopDepots.WorkshopDepotDto;

const scheduleTypeItems = [
  { value: 'attach', label: 'Attach to Existing Job' },
  { value: 'create', label: 'Create New Job' },
];

interface IDisplayableJob {
  title: string;
}

export default function getScheduleServiceModalDef(
  onSchedule: (args: ScheduleServiceArgs) => Promise<void>,
  assetJobs: Array<JobSummaryDto>,
  serviceScheduleId: number | undefined,
  assetId: string | undefined,
  assetComponentId: number | undefined,
  workshopDepots: Array<WorkshopDepot>,
  defaultWorkshopDepot: WorkshopDepot | undefined
): ModalDefBuilder {
  return modalDefApi => ({
    title: 'Schedule Service',
    asForm: true,
    explicitData: {
      scheduleToggle: 'attach',
      depotId: defaultWorkshopDepot?.id,
    },
    panels: [
      {
        panes: [
          {
            paneType: PaneType.formFieldsPane,
            fields: [
              {
                fieldType: FieldType.toggleButtonField,
                dataAddr: 'scheduleToggle',
                optionItems: scheduleTypeItems,
                label: '',
                valueKey: 'value',
                descriptionKey: 'label',
                useValueOnly: true,
              },
            ],
          },
          {
            paneType: PaneType.formFieldsPane,
            hidden: d => d.parentValue.scheduleToggle !== 'attach',
            fields: [
              {
                fieldType: FieldType.selectField,
                label: 'Selected Job',
                dataAddr: 'selectedJobId',
                valueKey: 'jobId',
                descriptionKey: 'jobNumber',
                useValueOnly: true,
                mandatory: true,
                optionItems: d =>
                  assetJobs.map((x, idx) => {
                    return {
                      ...x,
                      title: `Job Number: ${x.jobNumber}\u2003•\u2003${x.depotDescription}`,
                    };
                  }),
                optionRenderer: (j: JobSummaryDto & IDisplayableJob) => (
                  <div>
                    <div>
                      <strong>{j.title}</strong>
                    </div>
                    <div>
                      <IntervalFormat startValue={j.startDateTime} endValue={j.endDateTime} />
                    </div>
                    <p style={{ whiteSpace: 'pre-wrap' }}>{j.jobDetails}</p>
                  </div>
                ),
                valueRenderer: (j: JobSummaryDto & IDisplayableJob) => (
                  <span>
                    <strong>#{j.jobNumber}</strong>
                    <span>&emsp;•&emsp;{j.depotDescription}&emsp;•&emsp;</span>
                    <span>
                      <IntervalFormat startValue={j.startDateTime} endValue={j.endDateTime} />
                    </span>
                  </span>
                ),
              },
            ],
          },
          {
            paneType: PaneType.formFieldsPane,
            hidden: d => d.parentValue.scheduleToggle !== 'create',
            columnCount: 2,
            fields: [
              {
                fieldType: FieldType.dateTimeField,
                label: 'Start',
                dataAddr: 'startDateTime',
                mandatory: true,
              },
              {
                fieldType: FieldType.dateTimeField,
                label: 'End',
                dataAddr: 'endDateTime',
                mandatory: true,
                validate: d =>
                  DateTime.fromISO(d.parentValue.endDateTime) <=
                  DateTime.fromISO(d.parentValue.startDateTime)
                    ? 'End must be after Start'
                    : undefined,
              },
            ],
          },
          {
            paneType: PaneType.formFieldsPane,
            hidden: d => d.parentValue.scheduleToggle !== 'create',
            columnCount: 1,
            fields: [
              {
                fieldType: FieldType.selectField,
                dataAddr: 'depotId',
                label: 'Depot',
                useValueOnly: true,
                valueKey: 'id',
                descriptionKey: 'description',
                mandatory: true,
                optionItems: workshopDepots,
                readonly: workshopDepots.length < 2,
              },
            ],
          },
        ],
      },
    ],
    secondaryActions: [getSubmitCloseModalActionGroupDef('Schedule')],
    onFormSubmit: (values: {
      startDateTime: string;
      endDateTime: string;
      selectedJobId: string;
      depotId: number;
    }) => {
      const item: AssetPlanItem = modalDefApi.actionData.parentValue;
      const id = serviceScheduleId || item.id;
      const args: ScheduleServiceArgs = {
        assetComponentId: assetComponentId === undefined ? 0 : assetComponentId,
        assetId: assetId === undefined ? '' : assetId,
        assetServiceScheduleId: id,
        jobId: values.selectedJobId,
        startDateTime: values.startDateTime,
        endDateTime: values.endDateTime,
        depotId: values.depotId,
      };
      return onSchedule(args);
    },
  });
}
