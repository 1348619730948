import { Table } from 'reactstrap';
import './PrintJobTaskParts.scss';

interface IPrintJobTaskPartsProps {
  parts: Workshop.Domain.Queries.Job.PrintJobQuery.JobTaskItemPart[];
  depotId?: number;
}

const PrintJobTaskParts: React.FC<IPrintJobTaskPartsProps> = ({ parts, depotId }) => {
  const blankPart: Workshop.Domain.Queries.Job.PrintJobQuery.JobTaskItemPart = {
    id: 0,
    partNumber: '',
    description: '',
    partLocations: [],
    quantity: 0,
  };
  var arrayParts = parts.slice();
  const array = Array(10).fill(blankPart);
  arrayParts = arrayParts.concat(array);
  return (
    <div className="print-job-task-parts-component">
      <Table>
        <thead>
          <tr>
            <th>Part No</th>
            <th className="location">Location</th>
            <th className="quantity">Quantity</th>
          </tr>
        </thead>
        <tbody>
          {arrayParts.map((part, i) => {
            return (
              <tr key={i}>
                <td className="partDescription">
                  {!!part.partNumber ? (
                    <div>
                      {part.partNumber} - {part.description}
                    </div>
                  ) : null}
                </td>
                <td className="location">
                  {depotId ? part.partLocations?.find(pl => pl.depotId === depotId)?.location : ''}
                </td>
                <td className="quantity">{part.quantity === 0 ? '' : part.quantity}</td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
};

export default PrintJobTaskParts;
