import { buildListPageApiSearchModelTypedQuery } from 'src/domain/modelBuilders/buildListPageApiSearchModel';
import { NotificationType } from 'src/domain/baseTypes';
import { getAjax } from 'src/domain/services';
import { getRoot, flow } from 'mobx-state-tree';
import { IRootStoreModel } from 'src/domain/entities/RootStoreModel';
import * as queryString from 'query-string';

type ListShiftsQuery = Workshop.Domain.Queries.Shift.ListShifts.ListShiftsQuery;

const ListShiftsModel = buildListPageApiSearchModelTypedQuery<ListShiftsQuery>()(
  'ListShiftsModel',
  d => d.ajax.workshop.shift.listShifts(d.query)
).actions(self => {
  const ajax = getAjax(self);
  const root = getRoot(self) as IRootStoreModel;

  const exportShiftsToExcel = flow(function*() {
    root.notifications.addNotification(`The file is being generated...`, {
      type: NotificationType.info,
    });
    return yield ajax.raw
      .getFile(`/api/workshop/shifts/excel?${queryString.stringify(self.query)}`)
      .toPromise()
      .then<Blob>(r => r.response);
  });

  return {
    exportShiftsToExcel,
  };
});

export const ShiftsModel = ListShiftsModel;
