import { types, flow } from 'mobx-state-tree';
import { getAjax } from 'src/domain/services/storeEnvironment';

type OutOfServiceJobTaskListItem = Workshop.Domain.Queries.JobTask.ListOutOfServiceJobTasksForKiosk.OutOfServiceJobTaskListItem;

export const JobTaskModel = types
  .model('JobTaskModel', {
    outOfServiceJobTasks: types.array(types.frozen<OutOfServiceJobTaskListItem>()),
  })
  .actions(self => {
    const ajax = getAjax(self);

    const loadOutOfServiceJobTasks = flow(function*(depotId: number) {
      self.outOfServiceJobTasks.clear();
      self.outOfServiceJobTasks = yield ajax.raw
        .get(`/api/kiosk/jobtasks/out-of-service/${depotId}`)
        .toPromise()
        .then<OutOfServiceJobTaskListItem[]>(r => r.response);
    });

    return {
      loadOutOfServiceJobTasks,
    };
  });
