import inject from 'src/views/injectFromStore';
import ListTimesheetAllowances, { IListTimesheetAllowancesProps } from './ListTimesheetAllowances';

const Container = inject<IListTimesheetAllowancesProps>((allStores, p) => ({
  canManageTimesheetAllowances: allStores.rootStore.account.isPeopleDepartmentMember,
  loadTimesheetAllowances: allStores.rootStore.people.timesheetAllowances.listItems,
  timesheetallowances: allStores.rootStore.people.timesheetAllowances.items.slice(),
}))(ListTimesheetAllowances);

export default Container;
