import { Component } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { ChangeState } from 'src/api/enums';
import deepEqual from 'src/infrastructure/deepEqual';
import {
  ActionType,
  FieldType,
  PagePrimarySize,
  PaneType,
} from 'src/views/definitionBuilders/types';
import CrudPage, { CrudPageMode, ICrudPageDef } from 'src/views/components/Page/pages/CrudPage';
import getFullServiceCycleSectionDef from './getFullServiceCycleSectionDef';
import {
  getActivationAndDeletionActionButtons,
  ISetActiveCommand,
  ISetInactiveCommand,
} from 'src/views/components/ActivationAndDeletionActionsButtons/getActivationAndDeletionActionButtons';

type AssetGroupUsage = Common.Dtos.AggregateUsageDto;
type UniqueNameCheckResultDto = Common.Dtos.UniqueNameCheckResultDto;
type AssetGroupDetails = Workshop.Domain.Queries.AssetGroup.AssetGroupDetails;
type CreateAssetGroupCommand = Workshop.Domain.Commands.AssetGroup.CreateAssetGroupCommand;
type UpdateAssetGroupCommand = Workshop.Domain.Commands.AssetGroup.UpdateAssetGroupCommand;
type AssetGroupServiceIntervalDetails = Workshop.Domain.Queries.AssetGroup.AssetGroupServiceIntervalDetails;
type JobTaskSubcategory = Workshop.Domain.Queries.GetTaskCategories.JobTaskSubcategoryListItem;
type ChecklistDetails = Workshop.Domain.Queries.GetChecklist.ChecklistDetails;
type ServiceCycleItem = Workshop.Domain.Queries.AssetGroup.GetAssetGroupFullServiceCycle.ServiceCycleItem;

const defaultAssetGroup = {
  serviceIntervals: [],
};

export interface IMaintainAssetGroupProps {
  mode: CrudPageMode;
  canManageAssetGroups: boolean;
  onLoadAssetGroup: (id: string) => Promise<void>;
  assetGroup: AssetGroupDetails | undefined;
  serviceTypes: Array<JobTaskSubcategory>;
  checklists: Array<ChecklistDetails>;
  onCheckForUniqueName: (name: string) => Promise<UniqueNameCheckResultDto>;
  onCreateAssetGroup: (command: CreateAssetGroupCommand) => Promise<void>;
  onUpdateAssetGroup: (command: UpdateAssetGroupCommand) => Promise<void>;
  onLoadServiceTypes: () => Promise<void>;
  onLoadChecklists: (includeInactiveChecklists: boolean) => Promise<void>;
  fullServiceCycle: Array<ServiceCycleItem>;
  loadAssetGroupFullServiceCycle: (assetGroupId: string) => Promise<void>;
  getAssetGroupUsage: (assetGroupId: string) => Promise<void>;
  assetGroupUsage: AssetGroupUsage | undefined;
  setAssetGroupInactive: (command: ISetInactiveCommand) => Promise<void>;
  setAssetGroupActive: (command: ISetActiveCommand) => Promise<void>;
  deleteAssetGroup: (assetGroupId: string) => Promise<void>;
}

interface IUpdateAssetGroupRouteParams {
  id: string;
}

type InternalProps = IMaintainAssetGroupProps & RouteComponentProps<IUpdateAssetGroupRouteParams>;

class MaintainAssetGroup extends Component<InternalProps> {
  componentDidMount() {
    this.props.onLoadServiceTypes();
    this.props.onLoadChecklists(true);
    this.props.getAssetGroupUsage(this.assetGroupId);
  }

  private get isUpdateMode() {
    return this.props.mode === 'update';
  }

  private get isCreateMode() {
    return this.props.mode === 'create';
  }

  private get assetGroupId() {
    return this.props.match.params.id;
  }

  private handlePreSubmitForCreate = (values: AssetGroupDetails): CreateAssetGroupCommand => {
    return {
      description: values.description,
      serviceIntervals: values.serviceIntervals.map(item => {
        return {
          checklistId: item.checklistId,
          jobTaskSubcategoryId: item.jobTaskSubcategoryId,
          serviceIntervalDays: item.serviceIntervalDays,
          serviceIntervalKms: item.serviceIntervalKms,
        } as Workshop.Domain.Commands.AssetGroup.CreateAssetGroupServiceInterval;
      }),
    } as Workshop.Domain.Commands.AssetGroup.CreateAssetGroupCommand;
  };

  private handlePreSubmitForUpdate = (values: AssetGroupDetails): UpdateAssetGroupCommand => {
    const originalAssetGroup = this.props.assetGroup;
    const getChangeState = (si: AssetGroupServiceIntervalDetails) => {
      if (si.changeState === ChangeState.Added || si.changeState === ChangeState.Deleted) {
        return si.changeState;
      }
      const originalServiceInterval =
        originalAssetGroup && originalAssetGroup.serviceIntervals.find(x => x.id === si.id);
      if (!originalServiceInterval) {
        throw new Error('Cannot find original Service Interval');
      }
      return deepEqual(si, originalServiceInterval) ? ChangeState.Unchanged : ChangeState.Modified;
    };
    return {
      assetGroupId: values.id,
      description: values.description,
      items: values.serviceIntervals.map(x => ({
        id: x.id,
        checklistId: x.checklistId,
        jobTaskSubcategoryId: x.jobTaskSubcategoryId,
        serviceIntervalDays: x.serviceIntervalDays,
        serviceIntervalKms: x.serviceIntervalKms,
        changeState: getChangeState(x),
      })),
    };
  };

  private readonly getSmallestServiceInterval = (
    serviceIntervals: AssetGroupServiceIntervalDetails[]
  ) => {
    const activeServices = serviceIntervals
      ? serviceIntervals.filter(x => x.changeState !== ChangeState.Deleted)
      : [];

    return activeServices.length
      ? activeServices.reduce(
          (min, interval) =>
            (interval.serviceIntervalDays &&
              min.serviceIntervalDays &&
              interval.serviceIntervalDays < min.serviceIntervalDays) ||
            (interval.serviceIntervalDays && !min.serviceIntervalDays)
              ? interval
              : min,
          activeServices[0]
        )
      : undefined;
  };

  private readonly getPageDef = (mode: CrudPageMode, updating: boolean): ICrudPageDef => {
    const {
      assetGroup,
      serviceTypes,
      checklists,
      onCheckForUniqueName,
      onCreateAssetGroup,
      onUpdateAssetGroup,
      assetGroupUsage,
      deleteAssetGroup,
      setAssetGroupActive,
      setAssetGroupInactive,
    } = this.props;

    const fullServiceCycle = this.isUpdateMode && !updating ? this.props.fullServiceCycle : [];

    return {
      primarySize: PagePrimarySize.twoThirds,
      primarySection: {
        title: this.isUpdateMode ? 'Manage Asset Group' : 'Create an Asset Group',
        badge:
          !this.isUpdateMode || assetGroup?.isActive
            ? undefined
            : {
                label: 'Inactive',
              },
        primaryActions:
          !updating && !this.isCreateMode
            ? [
                {
                  actions: [
                    {
                      actionType: ActionType.actionCollection,
                      actionGroups: [
                        {
                          actions: getActivationAndDeletionActionButtons(
                            this.assetGroupId,
                            'Asset Group',
                            assetGroup?.isActive,
                            setAssetGroupActive,
                            setAssetGroupInactive,
                            deleteAssetGroup,
                            assetGroupUsage,
                            this.props.onLoadAssetGroup
                          ),
                        },
                      ],
                    },
                  ],
                },
              ]
            : [],
        panels: [
          {
            panes: [
              {
                paneType: PaneType.formFieldsPane,
                fields: [
                  {
                    fieldType: FieldType.textField,
                    label: 'Description',
                    dataAddr: 'description',
                    maxLength: 255,
                    mandatory: true,
                    validateAsync: async d => {
                      if (
                        !d.fieldValue ||
                        (this.isUpdateMode &&
                          assetGroup &&
                          assetGroup.description.toUpperCase() === d.fieldValue.toUpperCase())
                      ) {
                        return undefined;
                      }
                      const result = await onCheckForUniqueName(d.fieldValue);
                      return result.nameExists ? `Description is already in use` : undefined;
                    },
                  },
                ],
              },
            ],
          },
          {
            title: 'Service Intervals',
            dataAddr: 'serviceIntervals',
            panes: [
              {
                paneType: PaneType.tablePane,
                dataRequiredForRows: 'panelValue',
                mandatory: true,
                validate: d => {
                  const items = (d.panelValue as AssetGroupServiceIntervalDetails[]).filter(
                    i => i.changeState !== ChangeState.Deleted
                  );
                  const distinctServiceTypes = new Set(items.map(i => i.jobTaskSubcategoryId));
                  return distinctServiceTypes.size !== items.length
                    ? 'Each service type can only be defined once'
                    : undefined;
                },
                fields: [
                  {
                    fieldType: FieldType.selectField,
                    label: 'Service Type',
                    dataAddr: 'jobTaskSubcategoryId',
                    useValueOnly: true,
                    valueKey: 'id',
                    descriptionKey: 'description',
                    mandatory: true,
                    optionItems: serviceTypes,
                    valuesToDisable: d =>
                      (d.panelValue as AssetGroupServiceIntervalDetails[])
                        .filter(x => x.changeState !== ChangeState.Deleted)
                        .map(x => x.jobTaskSubcategoryId),
                    columnWidth: '12em',
                  },
                  {
                    fieldType: FieldType.numericField,
                    label: 'Interval (Days)',
                    dataAddr: 'serviceIntervalDays',
                    mandatory: true,
                    columnWidth: '10em',
                    validate: d => {
                      if (!d.fieldValue) {
                        return undefined;
                      }
                      const smallestService = this.getSmallestServiceInterval(d.panelValue);
                      return smallestService &&
                        smallestService !== d.parentValue &&
                        smallestService.serviceIntervalDays &&
                        d.fieldValue % smallestService.serviceIntervalDays > 0
                        ? 'Interval (Days) must be increments of the smallest service'
                        : undefined;
                    },
                  },
                  {
                    fieldType: FieldType.numericField,
                    label: 'Interval (kms)',
                    dataAddr: 'serviceIntervalKms',
                    columnWidth: '10em',
                    validate: d => {
                      if (!d.fieldValue) {
                        return undefined;
                      }
                      const smallestService = this.getSmallestServiceInterval(d.panelValue);
                      return smallestService &&
                        smallestService !== d.parentValue &&
                        smallestService.serviceIntervalKms &&
                        d.fieldValue % smallestService.serviceIntervalKms > 0
                        ? 'Interval (kms) must be increments of the smallest service'
                        : undefined;
                    },
                  },
                  {
                    fieldType: FieldType.selectField,
                    label: 'Checklist',
                    dataAddr: 'checklistId',
                    useValueOnly: true,
                    valueKey: 'id',
                    descriptionKey: 'name',
                    mandatory: true,
                    optionItems: d => checklists.filter(o => o.active || o.id === d.fieldValue),
                    linkTo: d => `/workshop/checklists/${d.fieldValue}`,
                  },
                  {
                    fieldType: FieldType.actionListField,
                    dataAddr: '',
                    columnWidth: '1px',
                    actionGroups: [
                      {
                        actions: [
                          {
                            hidden: this.isUpdateMode && !updating,
                            actionType: ActionType.removeArrayItemActionButton,
                            label: 'Remove Service Interval',
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
              {
                paneType: PaneType.actionListPane,
                hidden: this.isUpdateMode && !updating,
                actionGroups: [
                  {
                    actions: [
                      {
                        actionType: ActionType.addArrayItemActionButton,
                        label: 'Add Service Interval',
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
        onFormPreSubmit: this.isUpdateMode
          ? this.handlePreSubmitForUpdate
          : this.handlePreSubmitForCreate,
        onFormSubmit: this.isUpdateMode ? onUpdateAssetGroup : onCreateAssetGroup,
      },
      secondarySections: [getFullServiceCycleSectionDef(fullServiceCycle)],
    };
  };

  render() {
    const {
      mode,
      assetGroup,
      onLoadAssetGroup,
      canManageAssetGroups,
      loadAssetGroupFullServiceCycle,
    } = this.props;
    return (
      <CrudPage
        def={({ updating }) => this.getPageDef(mode, updating)}
        mode={mode}
        isEditingForbidden={!canManageAssetGroups}
        onLoadData={() => {
          return Promise.all([
            onLoadAssetGroup(this.assetGroupId),
            loadAssetGroupFullServiceCycle(this.assetGroupId),
          ]).then(() => Promise.resolve());
        }}
        data={assetGroup}
        createDefaultData={defaultAssetGroup}
      />
    );
  }
}

export default MaintainAssetGroup;
