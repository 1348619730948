import { FieldType } from 'src/views/definitionBuilders/types/field';
import ListPage, { IListPageDef } from 'src/views/components/Page/pages/ListPage';
import { PagePrimarySize } from 'src/views/definitionBuilders/types';
import { observer } from 'mobx-react';
import { useRootStore } from 'src/domain/entities/RootStoreModel';
import { TaskCardItem } from 'src/views/components/TaskCard';
import withQueryParams, { IQueryParamsProps } from 'src/views/hocs/withQueryParams';
import { BasicSearch } from 'src/domain/baseTypes';
import { Link } from 'react-router-dom';
import styles from './ListRosterGroups.module.scss';
import { formatDateShort } from 'src/domain/dateHelper';
import { SyncIcon } from 'src/images/icons';
import PrimaryTitle from 'src/views/components/Page/PrimaryTitle/PrimaryTitle';

const ListRosterGroups: React.FC<IQueryParamsProps<BasicSearch>> = observer(props => {
  const rootStore = useRootStore();
  const model = rootStore.operations.urban.rosterGroups;

  const getPageDef = (props: IQueryParamsProps<BasicSearch>): IListPageDef => {
    return {
      primaryTitle: (
        <PrimaryTitle
          title="Roster Groups"
          link="https://central-ops.notion.site/Roster-Groups-0fa01cbaeb7041abbf7ac5d7bfffa08f"></PrimaryTitle>
      ),
      primarySize: PagePrimarySize.full,
      onLoadData: model.list.listItems,
      createLink: TaskCardItem.createRosterGroup,
      externalSearch: true,
      hasMoreData: model.list.hasMoreItems,
      primaryFields: [
        {
          fieldType: FieldType.textField,
          orderBy: 'Name',
          dataAddr: 'name',
          label: 'Name',
          formatReadonly: d => (
            <Link to={`/operations/roster-groups/${d.parentValue.rosterGroupId}`}>
              {d.parentValue.name}
              {d.parentValue.isRotating && (
                <span>
                  &emsp; - &emsp;
                  <SyncIcon />
                </span>
              )}
            </Link>
          ),
        },
        {
          fieldType: FieldType.textField,
          dataAddr: 'rotatingCommencementDate',
          label: 'Commencement Date',
          formatReadonly: d => {
            return (
              d.parentValue.rotatingCommencementDate &&
              formatDateShort(d.parentValue.rotatingCommencementDate)
            );
          },
        },
      ],
      filterAction: {
        defaultValues: {
          orderBy: 'Name~asc',
        },
        filterFields: [],
      },
    };
  };

  return (
    <ListPage
      className={styles.rosterGroups}
      data={model.list.items.slice()}
      def={getPageDef(props)}
    />
  );
});

export default withQueryParams(ListRosterGroups);
