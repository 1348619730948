import { IAjax } from 'src/infrastructure/ajax';
import { peopleUrls } from 'src/domain/services/apiUrls';

export default function init(ajax: IAjax) {
  function getStaffDepots() {
    return ajax
      .get(peopleUrls.staffMemberUrls.staffDepots.getAll())
      .toPromise()
      .then<Common.Dtos.StaffDepotDto[]>(r => r.response);
  }

  return {
    staffDepots: {
      getStaffDepots,
    },
  };
}
