import { useState } from 'react';
import { ButtonGroup, Button } from 'reactstrap';
import FocusWrapper from 'src/views/components/FocusWrapper';
import { LabelledFormField } from 'src/views/components/forms';

interface IToggleButtonProps {
  label: string;
  readonly?: boolean;
  mandatory?: boolean;
  hideLabel?: boolean;
  value?: boolean;
  onChange: (e: boolean) => void;
}
export const YesNoToggleButton = (props: IToggleButtonProps) => {
  const [val, setVal] = useState<boolean>(true);
  return (
    <LabelledFormField
      className="toggle-button-page-field-component"
      readonly={props.readonly}
      mandatory={props.mandatory}
      hideLabel={props.hideLabel}
      labelValue={props.label}>
      <FocusWrapper>
        <div>
          <ButtonGroup className="toggle-button-group">
            <Button
              className="toggle-button"
              color="secondary"
              outline
              active={val === true}
              onClick={() => {
                props.onChange(true);
                setVal(true);
              }}>
              Yes
            </Button>
            <Button
              className="toggle-button"
              color="secondary"
              outline
              active={val === false}
              onClick={() => {
                props.onChange(false);
                setVal(false);
              }}>
              No
            </Button>
          </ButtonGroup>
        </div>
      </FocusWrapper>
    </LabelledFormField>
  );
};
