import shallowEqual from 'shallowequal';
import memoizeOne from 'src/infrastructure/memoizeOne';
import { makePathArray } from './utils';
import { IRfsContext, RfsContext, IFormApi, FieldName } from './base';
import { Component } from 'react';

interface INestedFieldProps {
  field?: FieldName;
  // tslint:disable-next-line: no-any
  validate?: (values: any) => undefined | string;
  children: ((api: INestedFieldApi) => React.ReactNode) | React.ReactNode;
}

interface INestedFieldApi {
  formApi: IFormApi;
  error: undefined | string;
}

export class NestedField extends Component<INestedFieldProps> {
  static contextType = RfsContext;
  context!: React.ContextType<typeof RfsContext>;

  private readonly _id = Symbol();

  componentDidMount() {
    this.context.registerField(
      this._id,
      this.getFullFieldName(this.context, this.props.field),
      this.props.validate,
      undefined
    );
  }

  componentDidUpdate(prevProps: Readonly<INestedFieldProps>) {
    if (
      !shallowEqual(this.props.field, prevProps.field) ||
      this.props.validate !== prevProps.validate
    ) {
      this.context.registerField(
        this._id,
        this.getFullFieldName(this.context, this.props.field),
        this.props.validate,
        undefined
      );
    }
  }

  componentWillUnmount() {
    this.context.unregisterField(this._id);
  }

  getFieldIsSet(field: FieldName | undefined) {
    return !!field || field === 0;
  }

  private readonly getFullFieldName = memoizeOne(
    (context: IRfsContext, field: FieldName | undefined) => {
      return this.getFieldIsSet(field)
        ? [...context.parentPath, ...makePathArray(field)]
        : context.parentPath;
    }
  );

  private readonly getUpdatedContext = memoizeOne(
    (context: IRfsContext, field: FieldName | undefined): IRfsContext => {
      const parentPath = this.getFullFieldName(context, field);
      return this.getFieldIsSet(field)
        ? {
            ...context,
            formApi: {
              ...context.formApi,
              getFullField: (f?: FieldName) => [...parentPath, ...makePathArray(f)],
            },
            parentPath,
          }
        : context;
    }
  );

  render() {
    const { field, children } = this.props;
    const ctx = this.getUpdatedContext(this.context, field);
    const error = ctx.formApi.getError(ctx.parentPath);
    const renderedChildren =
      typeof children === 'function'
        ? (children as Function)({ formApi: ctx.formApi, error })
        : children;
    return ctx === this.context ? (
      renderedChildren
    ) : (
      <RfsContext.Provider value={ctx}>{renderedChildren}</RfsContext.Provider>
    );
  }
}
