import { IMiddlewareHandler } from 'mobx-state-tree';
import { IRootStoreModel } from 'src/domain/entities/RootStoreModel';
import { AjaxError } from 'rxjs/observable/dom/AjaxObservable';
import logger from 'src/infrastructure/logging';

const messages = {
  unknownError: 'An unknown error occurred. Please contact your system administrator.',
  apiNotFound: 'The connection to the server failed. Please contact your system administrator.',
  apiNotAuthenticated: 'You are not currently signed in. Please refresh the browser to continue.',
  apiNotAuthorized:
    "You don't have sufficient permissions to perform this operation. Please contact your system administrator.",
  apiServerError: 'The server has experienced an error. Please contact your system administrator.',
  apiBadRequest: 'The server has rejected your request. Please contact your system administrator.',
  apiDisconnected: 'The server is not responding. Please contact your system administrator.',
};

// Middleware docs: https://github.com/mobxjs/mobx-state-tree/blob/master/docs/middleware.md#call-attributes
export const globalErrorHandlerMiddleware: IMiddlewareHandler = (call, next, abort) => {
  // Catch any unhandled exceptions from _async_ actions here.
  // As action calls are nested (ie. actions can call actions) this will catch a thrown error asap, rather
  // than waiting until it is thrown in the initial action to be called. This is because the initial action
  // may _not_ be in the "exception throw stack", if for example, an action triggers a state change, which
  // then causes a React rerender, that then calls an action, that throws.
  // This approach still has the same behaviour - the code after the failing sub-action call will not be
  // executed - but the out actions will not be able to catch the error in a try/catch.
  // This should not be a problem in the majority of (all?) cases. But if it ever is, then there are a few options
  // available:
  // - use a `.catch()` on the end of the sub action calls to convert errors to non-errors and handle the
  //   success/failure in data.
  // - Middleware can see the arguments passed to an action, so one could be used to flag that an exception should
  //   not be caught in this case.
  // - Middleware can see the state of the tree, so state could be kept to indicate that action "123" should not
  //   have it's errors automatically caught.
  if (call.type === 'flow_throw') {
    const root = call.tree as IRootStoreModel;
    const error = call.args.length && call.args[0];

    // Avoid double-handling the same error
    if (!error || !error.handledGlobally) {
      // Handle the error
      if (!error) {
        reportError(root, messages.unknownError);
      } else if (error instanceof AjaxError) {
        handleAjaxError(root, error);
      } else if (error instanceof Error) {
        handleGeneralError(root, error);
      } else {
        handleUntypedError(root, error);
      }

      error && (error.handledGlobally = true);
    }
  }
  return next(call);
};

function reportError(root: IRootStoreModel, message: string, error?: Error) {
  const log = logger;
  if (error) {
    log.error(error, { message });
  } else {
    log.error(new Error(message));
  }
  root.notifications.addNotification(message);
}

async function handleAjaxError(root: IRootStoreModel, error: AjaxError) {
  let errorMessage = '';
  let errorResponse: any = {};
  // Look for a specific message in the response body first
  if (error.response && error.response.message) {
    errorResponse = error.response;
    errorMessage = errorResponse.message;
  } else if (error.responseType.toLowerCase() === 'blob') {
    try {
      errorResponse = JSON.parse(await error.response.text());
      errorMessage = errorResponse.message;
    } catch {}
  }

  if (errorResponse && errorResponse.additionalContext) {
    if (errorResponse.additionalContext.details) {
      root.compliance.fatigueValidations.setValidationMessages(
        errorResponse.additionalContext.details
      );
      return;
    }
  }

  if (errorMessage) {
    reportError(root, errorMessage, error);
    return;
  }

  // Handle standard errors with standard messages
  switch (error.status) {
    case 400: {
      reportError(root, messages.apiBadRequest, error);
      break;
    }
    case 401: {
      if (window.location.pathname.startsWith('/kiosk')) {
        window.location.assign(`/kiosk/login`);
        break;
      }
      if (window.location.pathname.startsWith('/wskiosk')) {
        window.location.assign(`/wskiosk/login`);
        break;
      }
      reportError(root, messages.apiNotAuthenticated, error);
      break;
    }
    case 403: {
      if (window.location.pathname.startsWith('/kiosk')) {
        window.location.assign(`/kiosk/login`);
        break;
      }
      if (window.location.pathname.startsWith('/wskiosk')) {
        window.location.assign(`/wskiosk/login`);
        break;
      }
      reportError(root, messages.apiNotAuthorized, error);
      break;
    }
    case 404: {
      reportError(root, messages.apiNotFound, error);
      break;
    }
    case 500: {
      reportError(root, messages.apiServerError, error);
      break;
    }
    case 0: {
      reportError(root, messages.apiDisconnected, error);
      break;
    }
    default: {
      // Last resort is to use the error message
      reportError(root, error.message, error);
      break;
    }
  }
}

function handleGeneralError(root: IRootStoreModel, error: Error) {
  reportError(root, error.message, error);
}

// tslint:disable-next-line:no-any
function handleUntypedError(root: IRootStoreModel, error: any) {
  // Error is unrecognised, so look for standard messages, or just try to coerse to a string
  const message = error.message || error + '';
  reportError(root, message, error);
}
