import * as queryString from 'query-string';
import { buildListPageApiSearchModelTypedQuery } from 'src/domain/modelBuilders/buildListPageApiSearchModel';

type AssetAttachmentItem = Common.Dtos.AssetAttachmentsDto;
type GetAssetAttachmentsQuery = Workshop.Domain.Queries.Attachment.GetAssetAttachmentsList.GetAssetAttachmentsListQuery;

export const ListAssetAttachmentsModel = buildListPageApiSearchModelTypedQuery<
  GetAssetAttachmentsQuery
>()('ListAssetAttachmentsModel', d =>
  d.ajax.raw
    .get(`/api/workshop/assets-attachments?${queryString.stringify(d.query)}`)
    .map(r => r.response as Common.Dtos.ListResult<AssetAttachmentItem>)
);
