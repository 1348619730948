import { DateTime } from 'luxon';
import { flow, types } from 'mobx-state-tree';
import { IAutocompleteResult } from 'src/domain/baseTypes';
import { AssetSubcategoryType } from 'src/domain/enums';
import { buildListPageApiSearchModelTypedQuery } from 'src/domain/modelBuilders/buildListPageApiSearchModel';
import { getAjax } from 'src/domain/services';
import { assetUrls } from 'src/domain/services/apiUrls';

type GetAssetsWithInspectionsDueQuery = Workshop.Domain.Queries.GetAssetsWithInspectionsDue.GetAssetsWithInspectionsDueQuery;
type AssetItem = Workshop.Domain.Queries.AssetItem;
type UpcomingCertificateOfInspectionAsset = Workshop.Domain.Queries.GetAssetsWithInspectionsDue.UpcomingCertificateOfInspectionAsset;
type AssetOdometerReadingItem = Workshop.Domain.Queries.GetAssetOdometerReadings.AssetOdometerReadingItem;
type JobSummaryDto = Workshop.Domain.Queries.Job.JobSummaryDto;
type AssetServiceHistoryItem = Workshop.Domain.Queries.AssetServices.GetAssetServicePlan.AssetServiceHistoryItem;
type FleetAssetItem = Common.Queries.Workshop.GetFleetAssetList.AssetItem;
type JobTaskListItem = Workshop.Domain.Queries.JobTask.JobTaskListItem;
type TechSpecValueItem = Workshop.Domain.Queries.SearchAssetTechSpecValues.TechSpecValueItem;
type GetAssetServiceDueListQuery = Workshop.Domain.Queries.AssetServices.GetAssetServiceDueList.GetAssetServiceDueListQuery;
type AssetFuelLocation = Workshop.Domain.Queries.ListAssetFuelLocations.AssetFuelLocationItem;

export type SearchTechSpecValues = (
  search: string,
  techSpecId: number
) => Promise<IAutocompleteResult<{ value: string }>>;

const ServicesDueAssetsListModel = buildListPageApiSearchModelTypedQuery<
  GetAssetServiceDueListQuery
>()('ServicesDueAssetsListModel', d => d.ajax.asset.getServicesDue(d.query)).views(self => ({
  get servicesDueByDate() {
    return self.items
      .filter(x => x.nextServiceDueInDays && x.nextServiceDueInDays < 30)
      .sort((a, b) =>
        a.nextServiceDueInDays && b.nextServiceDueInDays
          ? a.nextServiceDueInDays - b.nextServiceDueInDays
          : 0
      );
  },
  get servicesDueByKms() {
    return self.items
      .filter(x => x.nextServiceDueInKms && x.nextServiceDueInKms < 5000)
      .sort((a, b) =>
        a.nextServiceDueInKms && b.nextServiceDueInKms
          ? a.nextServiceDueInKms - b.nextServiceDueInKms
          : 0
      );
  },
}));

const BaseAssetsModel = types
  .model('AssetsModel', {
    assetListItems: types.array(types.frozen<AssetItem>()),
    assetListItemsWithInspectionsDue: types.array(
      types.frozen<UpcomingCertificateOfInspectionAsset>()
    ),
    assetOdometerReadings: types.array(types.frozen<AssetOdometerReadingItem>()),
    futureJobs: types.array(types.frozen<JobSummaryDto>()),
    tasks: types.array(types.frozen<JobTaskListItem>()),
    serviceHistoryItems: types.array(types.frozen<AssetServiceHistoryItem>()),
    fleetAssetIncludingDecommissionedListItems: types.array(types.frozen<FleetAssetItem>()),
    assetFuelLocations: types.array(types.frozen<AssetFuelLocation>()),
  })
  .views(self => ({
    get fleetAssetListItems() {
      return self.fleetAssetIncludingDecommissionedListItems.filter(
        x => x.isDecommissioned === false
      );
    },
    get fleetBusAssetListItems() {
      return self.fleetAssetIncludingDecommissionedListItems.filter(
        x => x.isDecommissioned === false && x.subcategoryId === AssetSubcategoryType.Bus
      );
    },
  }))
  .actions(self => {
    const ajax = getAjax(self);

    const loadAssetListItems = flow(function*() {
      self.assetListItems = yield ajax.asset.getAssetsList();
    });

    const loadAssetsWithInspectionsDue = flow(function*(query: GetAssetsWithInspectionsDueQuery) {
      self.assetListItemsWithInspectionsDue = yield ajax.asset.listAssetsWithInspectionsDue(query);
    });

    const loadAssetOdometerReadings = flow(function*(date: DateTime) {
      self.assetOdometerReadings = yield ajax.asset.loadAssetOdometerReadings(date);
    });

    const getFutureJobsForAsset = flow(function*(id: string) {
      self.futureJobs = yield ajax.asset.getFutureJobsForAsset(id);
    });

    const getTasksForAsset = flow(function*(id: string) {
      self.tasks = yield ajax.asset.getTasksForAsset(id);
    });

    const loadServiceHistory = flow(function*(id: string) {
      self.serviceHistoryItems = yield ajax.asset.loadServiceHistory(id);
    });

    const loadFleetAssets = flow(function*() {
      self.fleetAssetIncludingDecommissionedListItems = yield ajax.asset.loadFleetAssets(true);
    });

    const loadAssetFuelLocations = flow(function*() {
      self.assetFuelLocations = yield ajax.asset.loadAssetFuelLocations();
    });

    const getOwner = flow(function*(assetId: string) {
      return yield ajax.asset.getOwner(assetId);
    });

    const searchTechSpecValues: SearchTechSpecValues = flow(function*(
      search: string,
      techSpecId: number
    ) {
      var options = yield ajax.raw
        .get(assetUrls.searchTechSpecValues(search, techSpecId))
        .toPromise()
        .then<TechSpecValueItem[]>(r => r.response);
      return { options };
    });

    const clearTasksForAsset = () => {
      self.tasks.clear();
    };

    return {
      loadAssetListItems,
      loadAssetOdometerReadings,
      loadAssetsWithInspectionsDue,
      loadFleetAssets,
      loadAssetFuelLocations,
      getFutureJobsForAsset,
      loadServiceHistory,
      getTasksForAsset,
      getOwner,
      searchTechSpecValues,
      clearTasksForAsset,
    };
  });

type IAssetsModelType = typeof AssetsModel.Type;
export interface IAssetsModel extends IAssetsModelType {}

export const AssetsModel = types.compose(ServicesDueAssetsListModel, BaseAssetsModel);
