import { DateTime } from 'luxon';
import { assetUrls } from 'src/domain/services/apiUrls';
import { IAjax } from 'src/infrastructure/ajax';
import Omit from 'src/infrastructure/omit';

type GetAssetsWithInspectionsDueQuery = Workshop.Domain.Queries.GetAssetsWithInspectionsDue.GetAssetsWithInspectionsDueQuery;
type AssetCategory = Workshop.Domain.AggregatesModel.AssetAggregate.AssetCategory;
type AssetSubcategory = Workshop.Domain.AggregatesModel.AssetAggregate.AssetSubcategory;
type AssetHousingLocation = Common.Queries.Workshop.GetAssetHousingLocations.AssetHousingLocationItem;
type UnallocatedAssetListItem = Workshop.Domain.Queries.ListUnallocatedAssets.UnallocatedAssetListItem;
type CreateAssetAdHocFuelCommand = Workshop.Domain.Commands.Asset.CreateAssetAdHocFuel.CreateAssetAdHocFuelCommand;
type UpdateAssetAdHocFuelCommand = Workshop.Domain.Commands.Asset.UpdateAssetAdHocFuel.UpdateAssetAdHocFuelCommand;

// tslint:disable-next-line:no-empty
const returnVoid = () => {};

export default function init(ajax: IAjax) {
  function getAssetsList() {
    return ajax
      .get(assetUrls.getAssetsList())
      .toPromise()
      .then<Workshop.Domain.Queries.AssetItem[]>(r => {
        return r.response;
      });
  }

  function listAssets(query: Partial<Workshop.Domain.Queries.ListAssets.ListAssetsQuery>) {
    return ajax
      .get(assetUrls.listAssets(query))
      .map(
        r => r.response as Common.Dtos.ListResult<Workshop.Domain.Queries.ListAssets.AssetListItem>
      );
  }

  function listUnallocatedAssets(
    query: Partial<Workshop.Domain.Queries.ListUnallocatedAssets.ListUnallocatedAssetsQuery>
  ) {
    return ajax
      .get(assetUrls.listUnallocatedAssets(query))
      .map(r => r.response as Common.Dtos.ListResult<UnallocatedAssetListItem>);
  }

  function getServicesDue(
    query: Partial<
      Workshop.Domain.Queries.AssetServices.GetAssetServiceDueList.GetAssetServiceDueListQuery
    >
  ) {
    return ajax
      .get(assetUrls.getServicesDue(query))
      .map(
        r =>
          r.response as Common.Dtos.ListResult<
            Workshop.Domain.Queries.AssetServices.AssetServiceDueListItem
          >
      );
  }

  function listAssetsWithInspectionsDue(query: GetAssetsWithInspectionsDueQuery) {
    return ajax
      .get(assetUrls.listAssetsWithInspectionsDue(query))
      .toPromise()
      .then<
        Workshop.Domain.Queries.GetAssetsWithInspectionsDue.UpcomingCertificateOfInspectionAsset[]
      >(r => {
        return r.response;
      });
  }

  function loadAssetOdometerReadings(date: DateTime) {
    return ajax
      .get(assetUrls.loadAssetOdometerReadings(date))
      .toPromise()
      .then<Workshop.Domain.Queries.GetAssetOdometerReadings.AssetOdometerReadingItem[]>(r => {
        return r.response;
      });
  }

  function loadFleetAssets(includeDecommissioned: boolean) {
    return ajax
      .get(assetUrls.loadFleetAssets(includeDecommissioned))
      .toPromise()
      .then<Common.Queries.Workshop.GetFleetAssetList.AssetItem[]>(r => {
        return r.response;
      });
  }

  function loadAssetFuelLocations() {
    return ajax
      .get(assetUrls.loadAssetFuelLocations())
      .toPromise()
      .then<Workshop.Domain.Queries.ListAssetFuelLocations.AssetFuelLocationItem[]>(r => {
        return r.response;
      });
  }

  function createAsset(command: Workshop.Domain.Commands.Asset.CreateAssetCommand) {
    return ajax
      .post(assetUrls.createAsset(), command)
      .toPromise()
      .then<string>(r => r.response);
  }

  function getAssetCategories() {
    return ajax
      .get(assetUrls.getAssetCategories())
      .toPromise()
      .then<AssetCategory[]>(r => r.response);
  }

  function getAssetHousingLocations() {
    return ajax
      .get(assetUrls.getAssetHousingLocations())
      .toPromise()
      .then<AssetHousingLocation[]>(r => r.response);
  }

  function getAssetSubcategories() {
    return ajax
      .get(assetUrls.getAssetSubcategories())
      .toPromise()
      .then<AssetSubcategory[]>(r => r.response);
  }

  function checkForUniqueName(name: string) {
    return ajax
      .get(assetUrls.checkForUniqueName(name))
      .toPromise()
      .then<Common.Dtos.UniqueNameCheckResultDto>(r => r.response);
  }

  function checkForUniqueRegistrationNumber(registrationNumber: string) {
    return ajax
      .get(assetUrls.checkForUniqueRegistrationNumber(registrationNumber))
      .toPromise()
      .then<Common.Dtos.UniqueNameCheckResultDto>(r => r.response);
  }

  function getAssetServicePlan(assetId: string) {
    return ajax
      .get(assetUrls.getAssetServicePlan(assetId))
      .toPromise()
      .then<Workshop.Domain.Queries.AssetServices.GetAssetServicePlan.AssetServicePlan>(
        r => r.response
      );
  }

  function getAssetComponents(assetId: string) {
    return ajax
      .get(assetUrls.getAssetComponents(assetId))
      .toPromise()
      .then<Workshop.Domain.Queries.AssetComponents.AssetComponentsItems>(r => r.response);
  }

  function getAssetParts(assetId: string) {
    return ajax
      .get(assetUrls.getAssetParts(assetId))
      .toPromise()
      .then<Workshop.Domain.Queries.AssetParts.AssetParts>(r => r.response);
  }

  function getAssetFuels(
    assetId: string,
    includeTabletData: boolean,
    maxResults: number,
    includeDataFuel?: boolean
  ) {
    return ajax
      .get(assetUrls.getAssetFuels(assetId, includeTabletData, maxResults, includeDataFuel))
      .toPromise()
      .then<Workshop.Domain.Queries.GetAssetFuel.AssetFuelItem[]>(r => r.response?.fuels ?? []);
  }

  function cancelAssetService(
    command: Workshop.Domain.Commands.AssetServiceSchedule.CancelAssetServiceCommand
  ) {
    return ajax
      .put(assetUrls.cancelAssetService(command.assetId), command)
      .toPromise()
      .then(r => returnVoid());
  }

  function getAsset(id: string) {
    return ajax
      .get(assetUrls.getAsset(id))
      .toPromise()
      .then<Workshop.Domain.Queries.GetAsset.AssetDetails>(r => r.response);
  }

  function getFutureJobsForAsset(id: string) {
    return ajax
      .get(assetUrls.getFutureJobsForAsset(id))
      .toPromise()
      .then<Workshop.Domain.Queries.Job.JobSummaryDto[]>(r => r.response);
  }

  function getOwner(assetId: string) {
    return ajax
      .get(assetUrls.getOwner(assetId))
      .toPromise()
      .then<Workshop.Domain.Queries.Owners.OwnerListItem | undefined>(r => r.response);
  }

  function getTasksForAsset(id: string) {
    return ajax
      .get(assetUrls.getTasksForAsset(id))
      .toPromise()
      .then<Workshop.Domain.Queries.JobTask.JobTaskListItem[]>(r => r.response);
  }

  function loadServiceHistory(id: string) {
    return ajax
      .get(assetUrls.loadServiceHistory(id))
      .toPromise()
      .then<Workshop.Domain.Queries.AssetServices.GetAssetServicePlan.AssetServiceHistoryItem[]>(
        r => r.response
      );
  }

  function updateAsset(command: Workshop.Domain.Commands.Asset.UpdateAssetCommand) {
    return ajax
      .put(assetUrls.updateAsset(command.assetId), command)
      .toPromise()
      .then(r => returnVoid());
  }

  function resetAssetServiceSchedule(
    command: Omit<
      Workshop.Domain.Commands.AssetServiceSchedule.ResetAssetServiceScheduleCommand,
      'lastServiceCycle'
    > & {
      lastServiceCycle: Workshop.Domain.Services.ServiceCycleItem | undefined;
    }
  ) {
    return ajax
      .put(assetUrls.resetAssetServiceSchedule(command.assetId), command)
      .toPromise()
      .then(r => returnVoid());
  }

  function updateServicePlan(
    command: Workshop.Domain.Commands.AssetServicePlan.UpdateAssetServicePlanCommand
  ) {
    return ajax
      .put(assetUrls.updateServicePlan(command.assetId), command)
      .toPromise()
      .then(r => returnVoid());
  }

  function updateComponents(
    command: Workshop.Domain.Commands.AssetComponents.UpdateAssetComponentsCommand
  ) {
    return ajax
      .put(assetUrls.updateComponents(command.assetId), command)
      .toPromise()
      .then(r => returnVoid());
  }

  function updateParts(command: Workshop.Domain.Commands.AssetPart.UpdateAssetPartCommand) {
    return ajax
      .put(assetUrls.updateParts(command.assetId), command)
      .toPromise()
      .then(r => returnVoid());
  }

  function getDefectsForAsset(assetId: string) {
    return ajax
      .get(assetUrls.getDefectsForAsset(assetId))
      .toPromise()
      .then<Workshop.Domain.Queries.ListAssets.AssetListItem[]>(r => {
        return r.response;
      });
  }

  function getNextServiceForAsset(assetId: string) {
    return ajax
      .get(assetUrls.getNextServiceForAsset(assetId))
      .toPromise()
      .then<Workshop.Domain.Queries.AssetServices.GetAssetNextServiceDue.AssetNextServiceDueItem>(
        r => r.response
      );
  }

  function getFutureTasksForAsset(assetId: string) {
    return ajax
      .get(assetUrls.getFutureTasksForAsset(assetId))
      .toPromise()
      .then<Workshop.Domain.Queries.GetFutureTaskForAsset.FutureTaskForAsset[]>(r => r.response);
  }

  function getScheduledTasksForAsset(assetId: string) {
    return ajax
      .get(assetUrls.getScheduledTasksForAsset(assetId))
      .toPromise()
      .then<Workshop.Domain.Queries.GetScheduledTaskForAsset.ScheduledTaskForAsset[]>(
        r => r.response
      );
  }

  function generateAssetJobReport(
    query: Workshop.Domain.Queries.AssetJobReport.AssetJobReportQuery
  ) {
    return ajax
      .getFile(assetUrls.generateAssetJobReport(query))
      .toPromise()
      .then<Blob>(r => r.response);
  }

  function generateAssetKmsReport(assetId: string, from: string, to: string) {
    return ajax
      .getFile(assetUrls.generateAssetKmsReport(assetId, from, to))
      .toPromise()
      .then<Blob>(r => r.response);
  }

  function exportAssetsToExcel(query: Partial<Workshop.Domain.Queries.ListAssets.ListAssetsQuery>) {
    return ajax
      .getFile(assetUrls.exportAssetsToExcel(query))
      .toPromise()
      .then<Blob>(r => r.response);
  }

  function exportAssetsComponentsToExcel(
    query: Partial<Workshop.Domain.Queries.ListAssets.ListAssetsQuery>
  ) {
    return ajax
      .getFile(assetUrls.exportAssetsComponentsToExcel(query))
      .toPromise()
      .then<Blob>(r => r.response);
  }

  function exportAssetServicesToExcel(
    query: Partial<Workshop.Domain.Queries.ListAssets.ListAssetsQuery>
  ) {
    return ajax
      .getFile(assetUrls.exportAssetServicesToExcel(query))
      .toPromise()
      .then<Blob>(r => r.response);
  }

  function exportAssetsFuelsToExcel(
    query: Workshop.Domain.Queries.ExportAssetFuelToExcel.ExportAssetFuelToExcelQuery
  ) {
    return ajax
      .getFile(assetUrls.exportAssetsFuelsToExcel(query))
      .toPromise()
      .then<Blob>(r => r.response);
  }

  function exportAssetsConsumptionToExcel(
    query: Workshop.Domain.Queries.ExportFuelConsumptionReportToExcel.ExportFuelConsumptionReportToExcelQuery
  ) {
    return ajax
      .getFile(assetUrls.exportFuelConsumptionReportToExcel(query))
      .toPromise()
      .then<Blob>(r => r.response);
  }

  function exportAssetMaintenanceCostsToExcel(
    query: Partial<Workshop.Domain.Queries.ListAssets.ListAssetsQuery>
  ) {
    return ajax
      .getFile(assetUrls.exportAssetMaintenanceCostsToExcel(query))
      .toPromise()
      .then<Blob>(r => r.response);
  }

  function exportAssetPartRegisterToExcel(
    query: Partial<Workshop.Domain.Queries.ListAssets.ListAssetsQuery>
  ) {
    return ajax
      .getFile(assetUrls.exportAssetPartRegisterToExcel(query))
      .toPromise()
      .then<Blob>(r => r.response);
  }

  function decommission(assetId: string) {
    return ajax
      .post(assetUrls.decommission(assetId))
      .toPromise()
      .then(_ => returnVoid());
  }

  function createAdHocFuel(command: CreateAssetAdHocFuelCommand) {
    return ajax
      .post(assetUrls.addFuel(command.assetId), command)
      .toPromise()
      .then(_ => returnVoid());
  }

  function updateAdHocFuel(command: UpdateAssetAdHocFuelCommand) {
    return ajax
      .put(assetUrls.updateFuel(command.assetId), command)
      .toPromise()
      .then(_ => returnVoid());
  }

  return {
    asset: {
      getAssetsList,
      listAssets,
      listUnallocatedAssets,
      listAssetsWithInspectionsDue,
      loadAssetOdometerReadings,
      loadFleetAssets,
      loadAssetFuelLocations,
      createAsset,
      getAssetCategories,
      getAssetHousingLocations,
      getAssetSubcategories,
      checkForUniqueName,
      checkForUniqueRegistrationNumber,
      getAssetServicePlan,
      getAssetComponents,
      getAssetParts,
      getAssetFuels,
      getAsset,
      getFutureJobsForAsset,
      getServicesDue,
      cancelAssetService,
      updateAsset,
      resetAssetServiceSchedule,
      updateServicePlan,
      updateComponents,
      updateParts,
      getDefectsForAsset,
      getFutureTasksForAsset,
      getScheduledTasksForAsset,
      getNextServiceForAsset,
      loadServiceHistory,
      getTasksForAsset,
      generateAssetJobReport,
      getOwner,
      exportAssetsToExcel,
      exportAssetsComponentsToExcel,
      decommission,
      generateAssetKmsReport,
      exportAssetServicesToExcel,
      exportAssetPartRegisterToExcel,
      createAdHocFuel,
      updateAdHocFuel,
      exportAssetsFuelsToExcel,
      exportAssetsConsumptionToExcel,
      exportAssetMaintenanceCostsToExcel,
    },
  };
}
