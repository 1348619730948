import { Component } from 'react';
import { ChangeState } from 'src/api/enums';
import { PlusIcon } from 'src/images/icons';
import { withFormApi } from 'src/views/components/Page/forms';
import { IFormApi } from 'src/views/components/Page/forms/base';
import {
  IActionMeta,
  IActionData,
  ActionType,
  IAddArrayItemActionButtonDef,
  IActionDef,
  IHasChangeState,
} from 'src/views/definitionBuilders/types';
import ActionButton from './ActionButton';

interface IAddArrayItemActionButtonProps {
  formApi: IFormApi;
  actionDef: IAddArrayItemActionButtonDef;
  actionMeta: IActionMeta;
  actionData: IActionData;
  renderAsDropdownItem?: boolean;
}

class AddArrayItemActionButton extends Component<IAddArrayItemActionButtonProps> {
  private readonly findArrayDataAddr = () => {
    const { formApi } = this.props;
    const dataAddr = formApi.getFullField();
    const origDataAddr = [...dataAddr];

    // We might already be at the array, so check
    if (Array.isArray(formApi.getValue(dataAddr))) {
      return dataAddr;
    }

    // Back up the path until we get to a number (which means we're at a collection)
    while (dataAddr.length && typeof dataAddr[dataAddr.length - 1] !== 'number') {
      dataAddr.pop();
    }
    if (!dataAddr.length) {
      // No array in path, so assume an array is wanted here
      return origDataAddr;
    }
    dataAddr.pop(); // Remove the index number
    return dataAddr;
  };

  private readonly handleClick = () => {
    const { formApi, actionDef, actionData } = this.props;
    const newItem =
      (actionDef.getNewItemData ? actionDef.getNewItemData(actionData) : actionDef.newItemData) ||
      {};
    (newItem as IHasChangeState).changeState = ChangeState.Added;

    const dataAddr = this.findArrayDataAddr();
    formApi.addValue(dataAddr, newItem);
    // Currently adding an item does not revalidate the field, and `addValue` is async,
    // so use setTimeout to cause the validate after the new item has been applied
    setTimeout(() => {
      formApi.validate(formApi.getFullField());

      // Add does not happen immediately, so use setTimeout then reread directly from props to get the latest values
      const { actionDef: lastestDef, actionData: latestData } = this.props;
      lastestDef.postClick && lastestDef.postClick(latestData);
    }, 0);
  };

  render() {
    const { actionDef, actionMeta: meta, actionData, renderAsDropdownItem } = this.props;
    return (
      <ActionButton
        actionDef={{
          ...(actionDef as IActionDef),
          actionType: ActionType.actionButton,
          icon: actionDef.icon || <PlusIcon />,
          level: actionDef.level || 'primary',
          onClick: this.handleClick,
        }}
        actionMeta={meta}
        actionData={actionData}
        renderAsDropdownItem={renderAsDropdownItem}
      />
    );
  }
}

export default withFormApi(AddArrayItemActionButton);
