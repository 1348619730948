import { IAjax } from 'src/infrastructure/ajax';
import { workshopUrls } from 'src/domain/services/apiUrls';

export default function init(ajax: IAjax) {
  function acceptConflict(
    command: Operations.Domain.Commands.Conflict.AcceptConflict.AcceptConflictCommand
  ) {
    return ajax
      .put(workshopUrls.conflictUrls.acceptConflict(command.conflictId), command)
      .toPromise();
  }

  function cancelAcceptanceConflict(conflictId: string) {
    return ajax.put(workshopUrls.conflictUrls.cancelAcceptanceConflict(conflictId)).toPromise();
  }

  function generateConflicts() {
    return ajax.get(`/api/workshop/generate-conflicts`).toPromise();
  }

  return {
    conflicts: {
      acceptConflict,
      cancelAcceptanceConflict,
      generateConflicts,
    },
  };
}
