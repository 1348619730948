import saveAs from 'file-saver';
import { DateTime } from 'luxon';
import { observer } from 'mobx-react';
import { useEffect } from 'react';
import { allReportableEventStatus, reportableEventStatusDescription } from 'src/api/enums';
import { BasicSearch } from 'src/domain/baseTypes';
import { useRootStore } from 'src/domain/entities/RootStoreModel';
import { ExcelIcon } from 'src/images/icons';
import { DateTimeFormat } from 'src/views/components/DateTimeFormat';
import ListPage, { IListPageDef } from 'src/views/components/Page/pages/ListPage';
import PrimaryTitle from 'src/views/components/Page/PrimaryTitle/PrimaryTitle';
import TaskCardItem from 'src/views/components/TaskCard/TaskCardItem';
import { ActionType, PagePrimarySize, PaneType } from 'src/views/definitionBuilders/types';
import { FieldDefs, FieldType } from 'src/views/definitionBuilders/types/field';
import withQueryParams, { IQueryParamsProps } from 'src/views/hocs/withQueryParams';
import { DisplayReportableEventCategory } from 'src/views/routes/compliance/reportableEvent/DisplayReportableEventCategory';

type ReportableEvent = Operations.Domain.Queries.ListReportableEvents.ReportableEventDto;
type ReportableEventCategory = Operations.Domain.Queries.ListReportableEvents.ReportableEventCategoryDto;

const ListReportableEvents: React.FC<IQueryParamsProps<BasicSearch>> = observer(
  (props: IQueryParamsProps<BasicSearch>) => {
    const rootStore = useRootStore();
    const reportableEventsModel = rootStore.compliance.reportableEvent.list;
    const reportableEventModel = rootStore.compliance.reportableEvent.item;
    const staffModel = rootStore.people.staffMembers;
    useEffect(() => {
      Promise.all([
        staffModel.loadReportableEventContacts(),
        rootStore.compliance.reportableEvent.item.loadAllContracts(),
      ]);
    }, []);

    const saveSpreadsheet = () => {
      const fileName = `Reportable_Events_${DateTime.local().toFormat('yyyyMMddHHmm')}.xlsx`;
      return reportableEventsModel
        .exportToExcel(props.getQueryParams())
        .then(blob => saveAs(blob, fileName));
    };

    const getPageDef = (): IListPageDef => {
      const filterFieldDefsLookup = getFilterFieldDefs();
      return {
        primaryTitle: <PrimaryTitle title="Reportable Events"></PrimaryTitle>,
        onLoadData: reportableEventsModel.listItems,
        externalSearch: true,
        createLink: rootStore.account.isOperationsDepartmentMember
          ? TaskCardItem.operations.compliance.reportableEvent.createReportableEvent
          : undefined,
        hasMoreData: reportableEventsModel.hasMoreItems,
        primarySize: PagePrimarySize.full,
        primaryActions: [
          {
            actions: [
              {
                actionType: ActionType.actionCollection,
                actionGroups: [
                  {
                    actions: [
                      {
                        actionType: ActionType.actionButton,
                        label: 'Export to Excel',
                        icon: <ExcelIcon fixedWidth />,
                        onClick: () => saveSpreadsheet(),
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
        primaryFields: [
          {
            fieldType: FieldType.textField,
            dataAddr: 'eventIdentifier',
            label: 'RE Number',
            orderBy: 'eventIdentifier',
            linkTo: d => `/compliance/reportable-events/${d.parentValue.id}`,
            columnWidth: '10rem',
          },
          {
            fieldType: FieldType.textField,
            dataAddr: 'status',
            label: 'Status',
            orderBy: 'status',
            readonly: true,
            formatReadonly: d => {
              const item = d.parentValue as ReportableEvent;
              return reportableEventStatusDescription(item.status);
            },
          },
          {
            fieldType: FieldType.dateTimeField,
            dataAddr: 'eventStart',
            label: 'Event Start',
            orderBy: 'eventStart',
            columnWidth: '10rem',
            formatReadonly: d => (
              <DateTimeFormat value={d.fieldValue} previousValue={DateTime.local()} />
            ),
          },
          {
            fieldType: FieldType.dateTimeField,
            dataAddr: 'eventEnd',
            label: 'Event Finish',
            orderBy: 'eventEnd',
            columnWidth: '10rem',
            formatReadonly: d => (
              <DateTimeFormat value={d.fieldValue} previousValue={DateTime.local()} />
            ),
          },
          {
            fieldType: FieldType.textField,
            dataAddr: 'categories',
            label: 'Category',
            orderBy: 'categoryId',
            formatReadonly: d => {
              const item = d.parentValue as ReportableEvent;
              if (!item || !item.categories) {
                return undefined;
              }

              return item.categories.map(c => (
                <DisplayReportableEventCategory key={c.code} category={c} />
              ));
            },
          },
          {
            fieldType: FieldType.staffMemberField,
            dataAddr: 'contactStaffMember',
            label: 'Contact',
          },
        ],
        filterAction: {
          defaultValues: {
            dateFrom: DateTime.local().toISODate(),
            dateTo: DateTime.local().toISODate(),
            orderBy: 'eventIdentifier~desc',
          },
          filterFields: Object.keys(filterFieldDefsLookup).map(k => filterFieldDefsLookup[k]),
          filterDef: () => [
            {
              panes: [
                {
                  paneType: PaneType.formFieldsPane,
                  columnCount: 2,
                  fields: [filterFieldDefsLookup.dateFrom, filterFieldDefsLookup.dateTo],
                },
                {
                  paneType: PaneType.formFieldsPane,
                  fields: [
                    filterFieldDefsLookup.statuses,
                    filterFieldDefsLookup.contracts,
                    filterFieldDefsLookup.categories,
                    filterFieldDefsLookup.contacts,
                  ],
                },
              ],
            },
          ],
        },
      };
    };

    const getFilterFieldDefs = (): { [key: string]: FieldDefs } => {
      return {
        dateFrom: {
          fieldType: FieldType.dateField,
          dataAddr: 'dateFrom',
          label: 'Occurred From',
          onBlur: api => {
            if (!api.formValues.dateTo) {
              api.setFormValue(['dateTo'], api.fieldData.fieldValue);
            }
            api.validateField(['dateTo']);
          },
        } as FieldDefs,
        dateTo: {
          fieldType: FieldType.dateField,
          dataAddr: 'dateTo',
          label: 'Occurred To',
          validate: d => {
            if (!d.fieldValue || !d.parentValue.dateFrom) {
              return undefined;
            }
            const from = DateTime.fromISO(d.parentValue.dateFrom);
            const to = DateTime.fromISO(d.fieldValue);
            return from > to ? 'Date To cannot be earlier than Date From' : undefined;
          },
        } as FieldDefs,
        contracts: {
          fieldType: FieldType.selectField,
          label: 'Contract',
          dataAddr: 'reportableEventContract',
          valueKey: 'id',
          descriptionKey: 'name',
          useValueOnly: true,
          optionItems: reportableEventModel.contracts.slice(),
          onChange: api => {
            if (api.oldFieldValue) {
              const values = { ...api.formValues, categories: undefined };
              api.setFormValues(values);
            }
          },
        } as FieldDefs,
        categories: {
          fieldType: FieldType.selectAsyncMultiField,
          dataAddr: 'categories',
          label: 'Category',
          valueKey: 'code',
          descriptionKey: 'description',
          useValueOnly: true,
          autoload: true,
          getFieldKey: d => d.sectionValue.reportableEventContract,
          optionRenderer: o => (
            <DisplayReportableEventCategory category={o as ReportableEventCategory} />
          ),
          loadOptionItems: (_, d) =>
            reportableEventModel.loadCategoriesForContract(d.sectionValue.reportableEventContract),
          loadItems: (ids, d) =>
            reportableEventModel.findCategoriesForContract(
              d.sectionValue.reportableEventContract,
              ids
            ),
          readonly: d =>
            !(d.paneValue.reportableEventContract && d.parentValue.reportableEventContract),
        } as FieldDefs,
        contacts: {
          fieldType: FieldType.selectMultiField,
          dataAddr: 'contacts',
          label: 'Contact Staff Member',
          valueKey: 'id',
          descriptionKey: 'name',
          optionItems: staffModel.reportableEventContacts.slice(),
          useValueOnly: true,
        } as FieldDefs,
        statuses: {
          fieldType: FieldType.selectMultiField,
          dataAddr: 'statuses',
          label: 'Status',
          valueKey: 'value',
          descriptionKey: 'description',
          useValueOnly: true,
          optionItems: allReportableEventStatus.slice(),
          formatReadonly: d => {
            const item = d.parentValue as ReportableEvent;
            return reportableEventStatusDescription(item.status);
          },
        } as FieldDefs,
      };
    };

    return <ListPage data={reportableEventsModel.items} def={getPageDef()} />;
  }
);

export default withQueryParams(ListReportableEvents);
