import { buildListPageApiSearchModelTypedQuery } from 'src/domain/modelBuilders/buildListPageApiSearchModel';
import { ISelectFilterableByDifferentKey } from 'src/domain/baseTypes';
import { RailBookingStatus } from 'src/api/enums';
import { types, flow } from 'mobx-state-tree';
import { getAjax } from 'src/domain/services/storeEnvironment';

type RailBookingForDropdownItem = Operations.Domain.Queries.GetRailBookingsForDropdown.RailBookingForDropdownItem;

const listRailBookingsModel = buildListPageApiSearchModelTypedQuery<
  Operations.Domain.Queries.ListRailBookings.ListRailBookingsQuery
>()('ListRailBookingsModel', d => d.ajax.operations.rail.railBookings.listRailBookings(d.query), {
  size: 250,
});

const dropdownRailBookingsModel = types
  .model('DropdownRailBookingsModel', {
    allDropdownRailBookings: types.array(
      types.frozen<ISelectFilterableByDifferentKey<RailBookingForDropdownItem>>()
    ),
  })
  .actions(self => {
    const ajax = getAjax(self);

    const loadDropdownRailBookings = () =>
      flow(function*() {
        self.allDropdownRailBookings = yield ajax.operations.rail.railBookings
          .getRailBookingsForDropdown()
          .then(list =>
            list.map(b => ({ ...b, searchable: `${b.railBookingNumber} - ${b.description}` }))
          );
      })();

    return {
      loadDropdownRailBookings,
    };
  })
  .views(self => ({
    get bookedDropdownRailBookings(): ISelectFilterableByDifferentKey<
      RailBookingForDropdownItem
    >[] {
      return self.allDropdownRailBookings.filter(r => r.status === RailBookingStatus.Booked);
    },
  }));

export const RailBookingsModel = types.compose(listRailBookingsModel, dropdownRailBookingsModel);
