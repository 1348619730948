import './maintainBookingForOps.scss';
import { useEffect } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { DateTime } from 'luxon';
import { allJobStatus, QuoteStatus } from 'src/api/enums';
import {
  ActionType,
  FieldType,
  PagePrimarySize,
  PaneType,
  ShellModalSize,
} from 'src/views/definitionBuilders/types';
import CrudPage, { ICrudPageDef } from 'src/views/components/Page/pages/CrudPage';
import getTripRoutesPaneDef from 'src/views/routes/operations/shared/getTripRoutesPaneDef';
import { DateTimeFormat } from 'src/views/components/DateTimeFormat';
import AuditHistory from 'src/views/components/AuditHistory/operations/AuditHistoryContainer';
import getSchema from 'src/views/routes/operations/bookingsForOps/AuditHistorySchema';
import { getReadonlyJobNumberFieldDef } from 'src/views/routes/operations/shared/commonFieldDefBuilders';
import getSeparators from './AuditHistorySeparators';
import { getSubmitCloseModalActionGroupDef } from 'src/views/definitionBuilders/common';
import { observer } from 'mobx-react';
import { useRootStore } from 'src/domain/entities/RootStoreModel';

type OpsBookingItem = Operations.Domain.Queries.ViewBookingForOps.OpsBookingItem;
type JobItem = Operations.Domain.Queries.ViewBookingForOps.OpsBookingItem.JobItem;
type TripItem = Operations.Domain.Queries.ViewBookingForOps.OpsBookingItem.TripItem;
type RepeatItem = Operations.Domain.Queries.ViewBookingForOps.OpsBookingItem.RepeatItem;
export interface IMaintainBookingForOpsProps {
  route: RouteComponentProps<{ [x: string]: string | undefined }>;
}

const RepeatItemComponent: React.FC<{
  bookingDateString: string;
  quoteId: string;
  status: string | undefined;
  linkedQuoteId: string | undefined;
  linkedQuoteNumber: number | undefined;
}> = ({ bookingDateString, quoteId, status, linkedQuoteNumber, linkedQuoteId }) => {
  return (
    <div className="regenerate-future-booking-jobs-item">
      <div>{DateTime.fromISO(bookingDateString).toLocaleString(DateTime.DATE_HUGE)}</div>
      <div>
        {linkedQuoteId === quoteId ? (
          <div>
            <small>(This booking)</small>
          </div>
        ) : linkedQuoteId ? (
          <small>
            <Link to={`/operations/bookings-for-ops/${linkedQuoteId}`}>
              {`Booking ${linkedQuoteNumber} (${status})`}
            </Link>
          </small>
        ) : null}
      </div>
    </div>
  );
};

const MaintainBookingForOps: React.FC<IMaintainBookingForOpsProps> = observer(
  (props: IMaintainBookingForOpsProps) => {
    const rootStore = useRootStore();
    const bookingForOpsItem = rootStore.operations.sales.quotes.bookingForOpsItem;
    const customerModel = rootStore.operations.sales.customer;
    const canManageBookings = rootStore.account.isOperationsDepartmentMember;
    const booking = bookingForOpsItem.item;
    const loadBooking = bookingForOpsItem.loadBookingForOps;
    const verifyJobs = bookingForOpsItem.verifyJobs;
    const regenerateJobs = bookingForOpsItem.regenerateJobs;
    const findCustomers = customerModel.findCustomers;
    const findContactsForCustomer = customerModel.findContactsForCustomer;
    const vehicleTypes = rootStore.operations.vehicleTypes.vehicleTypes.slice();
    const loadVehicleTypes = rootStore.operations.vehicleTypes.loadVehicleTypes;

    const extraTypes = rootStore.operations.extraTypes.list.charterExtraTypes.slice();
    const loadExtraTypes = rootStore.operations.extraTypes.list.loadExtraTypes;
    const loadTechSpecs = rootStore.workshop.techSpecs.forRequirements.getAll;
    const techSpecs = rootStore.workshop.techSpecs.forRequirements.items.slice();
    const skillSpecs = rootStore.people.skillSpecs.forRequirements.items.slice();
    const loadSkillSpecs = rootStore.people.skillSpecs.forRequirements.getAll;
    const needsVerificationStartDateItem = bookingForOpsItem.needsVerificationStartDateItem;
    const getNeedsVerificationStartDate = bookingForOpsItem.getNeedsVerificationStartDate;

    const allQuoteType = rootStore.operations.sales.quotes.quoteTypes.slice();
    const loadQuoteTypes = rootStore.operations.sales.quotes.loadQuoteTypes;
    const quoteId = props.route.match.params.id!;

    useEffect(() => {
      loadVehicleTypes();
      loadExtraTypes();
      loadTechSpecs();
      loadSkillSpecs();
      loadQuoteTypes();
    }, []);

    useEffect(() => {
      if (quoteId) {
        getNeedsVerificationStartDate(quoteId);
      }
    }, [quoteId]);

    const getPageDef = (): ICrudPageDef => {
      const bookingDay =
        booking &&
        booking.trips.reduce((d, t) => {
          const date = DateTime.fromISO(t.routes[0].date);
          return d && d < date ? d : date;
        }, undefined as DateTime | undefined);
      const tripsCount = booking ? booking.trips.length : 0;
      const notesCount = booking
        ? [booking.customerNote, booking.internalNote, booking.driverNote].filter(n => !!n).length
        : 0;
      const recurCount = booking ? booking.repeats.length : 0;
      return {
        primarySize: PagePrimarySize.twoThirds,
        primarySection: {
          title: `Manage Booking ${booking ? booking.quoteNumber : ''}`,
          primaryActions: [
            {
              actions: [
                {
                  actionType: ActionType.actionCollection,
                  actionGroups: [
                    {
                      actions: [
                        {
                          actionType: ActionType.actionLink,
                          label: 'View in Sales',
                          hidden: !booking,
                          to: `/sales/bookings/${booking && booking.id}`,
                        },
                        {
                          actionType: ActionType.actionLink,
                          label: 'View in Allocations',
                          hidden: !booking,
                          to: `/operations/jobs/allocations?day=${bookingDay &&
                            bookingDay.toISODate()}&quotes=${booking && booking.id}`,
                        },
                      ],
                    },
                    {
                      actions: [
                        {
                          hidden: () =>
                            !canManageBookings ||
                            !booking ||
                            booking.status.id !== QuoteStatus.Booked,
                          actionType: ActionType.modalActionButton,
                          label: 'Regenerate Jobs',
                          modalSize: ShellModalSize.oneQuarter,
                          modalDef: _ => ({
                            title: 'Regenerate Jobs',
                            asForm: true,
                            panels: [
                              {
                                panes: [
                                  {
                                    paneType: PaneType.customPane,
                                    render: () => {
                                      return (
                                        <div>
                                          <p>Are you sure you want to regenerate jobs?</p>
                                          <p>
                                            Jobs from the selected trip will be deleted and
                                            recreated.
                                          </p>
                                        </div>
                                      );
                                    },
                                  },
                                  {
                                    paneType: PaneType.formFieldsPane,
                                    fields: [
                                      {
                                        fieldType: FieldType.selectMultiField,
                                        label: 'Trips to regenerate',
                                        dataAddr: 'selectedTripNumbers',
                                        mandatory: true,
                                        valueKey: 'value',
                                        descriptionKey: 'description',
                                        useValueOnly: true,
                                        optionItems: booking
                                          ? booking.trips.map(t => ({
                                              value: t.tripNumber,
                                              description: `Trip ${t.tripNumber + 1}`,
                                            }))
                                          : [],
                                      },
                                      {
                                        fieldType: FieldType.yesNoField,
                                        label: 'Regenerate jobs from future recurred bookings?',
                                        dataAddr: 'regenerateFutureBookings',
                                        mandatory: true,
                                        hidden: d => {
                                          const bookingItem = d.panelValue as OpsBookingItem & {
                                            regenerateFutureBookings: boolean;
                                          };
                                          return bookingItem.repeats.length === 0;
                                        },
                                      },
                                    ],
                                  },
                                  {
                                    paneType: PaneType.customPane,
                                    render: d => {
                                      const bookingItem = d.data.panelValue as OpsBookingItem & {
                                        regenerateFutureBookings: boolean;
                                      };

                                      const repeats = bookingItem.repeats.filter(
                                        (repeat: RepeatItem) =>
                                          !!repeat.linkedQuoteNumber &&
                                          repeat.linkedQuoteNumber > bookingItem.quoteNumber
                                      );

                                      if (!repeats || repeats.length === 0) {
                                        return null;
                                      }

                                      return bookingItem.regenerateFutureBookings ? (
                                        <>
                                          <p>
                                            Jobs from the selected trip will be deleted and
                                            recreated from the following recurred bookings.
                                          </p>
                                          {repeats.map((repeat, index) => {
                                            return (
                                              <div
                                                key={
                                                  `regen_${repeat.linkedQuoteId}` ||
                                                  `regen_booking_${index}`
                                                }>
                                                <RepeatItemComponent
                                                  bookingDateString={repeat.repeatDate}
                                                  linkedQuoteId={repeat.linkedQuoteId}
                                                  linkedQuoteNumber={repeat.linkedQuoteNumber}
                                                  quoteId={bookingItem.id}
                                                  status={repeat.linkedQuoteStatus.description}
                                                />
                                                <hr />
                                              </div>
                                            );
                                          })}
                                        </>
                                      ) : null;
                                    },
                                  },
                                ],
                              },
                            ],
                            secondaryActions: [getSubmitCloseModalActionGroupDef('Ok')],
                            onFormSubmit: (
                              f: OpsBookingItem & {
                                selectedTripNumbers: number[] | undefined;
                                regenerateFutureBookings: boolean;
                              }
                            ) =>
                              regenerateJobs(
                                quoteId,
                                f.selectedTripNumbers,
                                f.regenerateFutureBookings
                              ),
                          }),
                        },
                        {
                          actionType: ActionType.modalActionButton,
                          label: 'Verify Jobs',
                          modalSize: ShellModalSize.oneQuarter,
                          hidden: booking && booking.jobs.every(x => !x.needsVerification),
                          modalDef: modalDefApi => ({
                            title: 'Verify Jobs',
                            asForm: true,
                            panels: [
                              {
                                panes: [
                                  {
                                    paneType: PaneType.customPane,
                                    render: () => {
                                      return (
                                        <div>
                                          <p>
                                            Changes in the bookings are not auto-updated in the
                                            jobs. Please make sure that all jobs are up to date with
                                            the booking.
                                          </p>
                                          <p>
                                            Are you sure you want to verify that all jobs are up to
                                            date?
                                          </p>
                                        </div>
                                      );
                                    },
                                  },
                                ],
                              },
                            ],
                            secondaryActions: [getSubmitCloseModalActionGroupDef('Ok')],
                            onFormSubmit: () => verifyJobs(quoteId),
                          }),
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
          panels: [
            {
              title: 'Jobs',
              panes: [
                {
                  paneType: PaneType.tablePane,
                  dataAddr: 'jobs',
                  neverEditable: true,
                  fields: [
                    getReadonlyJobNumberFieldDef<JobItem>('jobId'),
                    {
                      fieldType: FieldType.numericField,
                      dataAddr: 'tripNumber',
                      label: 'Trip',
                      formatReadonly: d => (d.fieldValue || 0) + 1,
                    },
                    {
                      fieldType: FieldType.dateTimeField,
                      dataAddr: 'onSite',
                      label: 'On Site',
                      formatReadonly: d => <DateTimeFormat value={d.fieldValue} />,
                    },
                    {
                      fieldType: FieldType.dateTimeField,
                      dataAddr: 'shiftCommence',
                      label: 'Pick Up',
                      formatReadonly: d => (
                        <DateTimeFormat value={d.fieldValue} previousValue={d.parentValue.onSite} />
                      ),
                    },
                    {
                      fieldType: FieldType.dateTimeField,
                      dataAddr: 'shiftEnd',
                      label: 'Drop Off',
                      formatReadonly: d => (
                        <DateTimeFormat
                          value={d.fieldValue}
                          previousValue={d.parentValue.shiftCommence}
                        />
                      ),
                    },
                    {
                      fieldType: FieldType.assetSelectField,
                      label: 'Vehicle',
                      dataAddr: 'asset',
                      valueKey: 'id',
                      descriptionKey: 'name',
                      formatReadonly: d => {
                        if (d.parentValue.hasSubcontractor) {
                          if (d.parentValue.subcontractor && d.parentValue.subcontractor.id) {
                            return (
                              <Link
                                to={`/operations/subcontractors/${d.parentValue.subcontractor.id}`}>
                                {d.parentValue.subcontractor.name}
                              </Link>
                            );
                          }
                          return null;
                        } else {
                          if (d.parentValue.asset && d.parentValue.asset.id) {
                            return (
                              <Link to={`/workshop/assets/${d.parentValue.asset.id}`}>
                                {d.parentValue.asset.name}
                              </Link>
                            );
                          }
                          return null;
                        }
                      },
                    },
                    {
                      fieldType: FieldType.readonlyField,
                      label: 'Staff Member',
                      dataAddr: 'staffMember',
                      formatReadonly: d => {
                        const job = d.parentValue as JobItem;
                        const traineeContent = job.isTrainingJob ? ' (Trainee)' : '';
                        return (
                          <>
                            {job.staffMember ? (
                              <div>
                                <Link to={`/people/staff-members/${job.staffMember.id}`}>
                                  {job.staffMember.name}
                                </Link>
                                {traineeContent}
                              </div>
                            ) : null}
                            {job.secondStaffMember ? (
                              <div>
                                <Link to={`/people/staff-members/${job.secondStaffMember.id}`}>
                                  {job.secondStaffMember.name}
                                </Link>
                                {traineeContent}
                              </div>
                            ) : null}
                          </>
                        );
                      },
                    },
                    {
                      fieldType: FieldType.selectField,
                      label: 'Status',
                      dataAddr: 'jobStatus',
                      valueKey: 'id',
                      descriptionKey: 'description',
                      optionItems: allJobStatus,
                    },
                  ],
                },
              ],
            },
          ],
        },
        secondarySections: [
          {
            title: 'Details',
            panels: [
              {
                panes: [
                  {
                    paneType: PaneType.formFieldsPane,
                    fields: [
                      {
                        fieldType: FieldType.textField,
                        dataAddr: 'customer.customerName',
                        label: 'Customer',
                        linkTo: d =>
                          `/sales/customers/${
                            (d.sectionValue as OpsBookingItem).customer.customerId
                          }`,
                      },
                      {
                        fieldType: FieldType.textField,
                        dataAddr: 'description',
                        label: 'Description',
                      },
                      {
                        fieldType: FieldType.selectField,
                        dataAddr: 'quoteType',
                        label: 'Booking Type',
                        valueKey: 'id',
                        descriptionKey: 'description',
                        optionItems: allQuoteType,
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            title: 'Trips',
            dataAddr: 'trips',
            subtitle: `[${tripsCount}]`,
            panels: [
              {
                panes: [
                  {
                    paneType: PaneType.repeatingTabPane,
                    getItemTitle: d => `Trip ${(d.itemValue as TripItem).tripNumber + 1}`,
                    itemPanes: [
                      {
                        paneType: PaneType.formFieldsPane,
                        fields: [
                          {
                            fieldType: FieldType.numericField,
                            label: 'Passengers',
                            dataAddr: 'passengers',
                          },
                          {
                            fieldType: FieldType.readonlyField,
                            label: 'Selected Trip Option',
                            formatReadonly: d => {
                              const option = (d.parentValue as TripItem).selectedOption;
                              const vehicleSummary = option.vehicles
                                .map(v => `${v.quantity} × ${v.vehicleType.description}`)
                                .join(', ');
                              const extraSummary = option.extras
                                .map(e => `${e.quantity} × ${e.extraType.description}`)
                                .join(', ');
                              return (
                                <div className="maintain-booking-for-ops-selected-option">
                                  <div>Vehicles:</div>
                                  <div>
                                    <strong>{vehicleSummary}</strong>
                                  </div>
                                  <div>Extras:</div>
                                  <div>
                                    <strong>{extraSummary}</strong>
                                  </div>
                                </div>
                              );
                            },
                          },
                        ],
                      },
                      getTripRoutesPaneDef('routes', { neverEditable: true }),
                    ],
                  },
                ],
              },
            ],
          },
          {
            title: 'Notes',
            subtitle: `[${notesCount}]`,
            panels: [
              {
                panes: [
                  {
                    paneType: PaneType.formFieldsPane,
                    fields: [
                      {
                        fieldType: FieldType.textAreaField,
                        label: 'Customer Notes',
                        dataAddr: 'customerNote',
                      },
                      {
                        fieldType: FieldType.textAreaField,
                        label: 'Internal Notes',
                        dataAddr: 'internalNote',
                      },
                      {
                        fieldType: FieldType.textAreaField,
                        label: 'Driver Notes',
                        dataAddr: 'driverNote',
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            title: 'History',
            panels: [
              {
                panes: [
                  {
                    paneType: PaneType.customPane,
                    render: () => (
                      <AuditHistory
                        aggregateId={quoteId}
                        minimumVersion={1}
                        schema={getSchema(
                          findCustomers,
                          findContactsForCustomer,
                          vehicleTypes,
                          extraTypes,
                          needsVerificationStartDateItem,
                          allQuoteType,
                          skillSpecs,
                          techSpecs
                        )}
                        separators={getSeparators(needsVerificationStartDateItem)}
                      />
                    ),
                  },
                ],
              },
            ],
          },
          {
            title: 'Recur',
            subtitle: `[${recurCount}]`,
            hidden: () => !recurCount,
            panels: [
              {
                panes: [
                  {
                    paneType: PaneType.formFieldsPane,
                    hidden: !booking || !booking.sourceQuote,
                    fields: [
                      {
                        fieldType: FieldType.readonlyField,
                        label: 'Original Booking',
                        formatReadonly: _ => {
                          const sourceQuote = booking && booking.sourceQuote;
                          return sourceQuote ? (
                            <div>
                              <div>
                                {DateTime.fromISO(sourceQuote.firstTrip).toLocaleString(
                                  DateTime.DATE_HUGE
                                )}
                              </div>
                              <div>
                                {
                                  <small>
                                    <Link to={`/operations/bookings-for-ops/${sourceQuote.id}`}>
                                      {`Booking ${sourceQuote.quoteNumber} (${sourceQuote.status.description})`}
                                    </Link>
                                  </small>
                                }
                              </div>
                            </div>
                          ) : null;
                        },
                      },
                    ],
                  },
                  {
                    paneType: PaneType.tablePane,
                    dataAddr: 'repeats',
                    dataRequiredForRows: 'paneValue',
                    fields: [
                      {
                        fieldType: FieldType.dateField,
                        dataAddr: 'repeatDate',
                        label: 'Recurring On',
                        readonly: true,
                        formatReadonly: d => {
                          const repeat: RepeatItem = d.parentValue;
                          const status = repeat.linkedQuoteStatus
                            ? repeat.linkedQuoteStatus.description
                            : undefined;
                          return (
                            <RepeatItemComponent
                              bookingDateString={repeat.repeatDate}
                              linkedQuoteId={repeat.linkedQuoteId}
                              linkedQuoteNumber={repeat.linkedQuoteNumber}
                              quoteId={quoteId}
                              status={status}
                            />
                          );
                        },
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      };
    };

    return (
      <CrudPage
        key={quoteId}
        def={getPageDef()}
        mode="view"
        onLoadData={() => loadBooking(quoteId)}
        data={{ ...booking, regenerateFutureBookings: true }}
      />
    );
  }
);
export default MaintainBookingForOps;
