import saveAs from 'file-saver';
import { DateTime } from 'luxon';
import { observer } from 'mobx-react';
import { useEffect } from 'react';
import { Subscription } from 'rxjs';
import { interval } from 'rxjs/observable/interval';
import { allPurchaseTransactionType, PurchaseTransactionType } from 'src/api/enums';
import { ListPageLoadCause } from 'src/domain/baseTypes';
import { useRootStore } from 'src/domain/entities/RootStoreModel';
import { getBus } from 'src/domain/services';
import { CheckIcon, ExcelIcon } from 'src/images/icons';
import ListPage, { IListPageDef } from 'src/views/components/Page/pages/ListPage';
import { getSubmitCloseModalActionGroupDef } from 'src/views/definitionBuilders/common';
import {
  ActionType,
  ISectionDef,
  PagePrimarySize,
  ShellModalSize,
} from 'src/views/definitionBuilders/types';
import { FieldDefs, FieldType } from 'src/views/definitionBuilders/types/field';
import { PaneType } from 'src/views/definitionBuilders/types/pane';
import withQueryParams, { IQueryParamsProps } from 'src/views/hocs/withQueryParams';
import PrimaryTitle from 'src/views/components/Page/PrimaryTitle/PrimaryTitle';

type ExportPurchaseTransactionsToExcelQuery = Workshop.Domain.Queries.Purchasing.ExportPurchaseTransactions.ExportPurchaseTransactionsToExcelQuery;

const ListPurchaseTransactions: React.FC<IQueryParamsProps<
  ExportPurchaseTransactionsToExcelQuery
>> = observer((props: IQueryParamsProps<ExportPurchaseTransactionsToExcelQuery>) => {
  let domainEventSubscription: Subscription | undefined;
  const rootStore = useRootStore();
  const domainEvents = getBus(rootStore).operationsDomainEvent$;
  const purchaseOrderModel = rootStore.workshop.purchaseOrder;
  const listPurchaseTransactionsModel = rootStore.workshop.listPurchaseTransactions;
  const supplierModel = rootStore.workshop.supplier;

  useEffect(() => {
    purchaseOrderModel.loadDeliveryLocations();

    return () => {
      domainEventSubscription?.unsubscribe();
    };
  }, []);

  const exportToExcel = (markAsExported: boolean) => {
    const routeParams = props.getQueryParams();
    routeParams.markAsExported = markAsExported;

    return listPurchaseTransactionsModel.exportPurchaseTransactionsToExcel(routeParams).then(r => {
      saveAs(r, `PurchaseTransactionsExcelExport.xlsx`);
    });
  };

  const getPageDef = (): IListPageDef => {
    domainEventSubscription?.unsubscribe();
    domainEventSubscription = domainEvents
      .filter(e => e.eventName === 'PurchaseTransactionMarkedAsExportedSignalrEvent')
      .throttle(e => interval(2000))
      .subscribe(e => {
        const routeParams = props.getQueryParams();
        listPurchaseTransactionsModel.listItems({
          loadCause: ListPageLoadCause.search,
          query: { ...routeParams },
        });
      });

    const filterFieldDefs = {
      startDate: {
        fieldType: FieldType.dateField,
        dataAddr: 'startDate',
        label: 'Start Date',
        onBlur: api => {
          api.validateField(['endDate']);
        },
      } as FieldDefs,
      endDate: {
        fieldType: FieldType.dateField,
        dataAddr: 'endDate',
        label: 'End Date',
        validate: d => {
          if (!d.fieldValue || !d.parentValue.startDate) {
            return undefined;
          }
          const from = DateTime.fromISO(d.parentValue.startDate);
          const to = DateTime.fromISO(d.fieldValue);
          return from > to ? 'Start Date must be earlier than End Date' : undefined;
        },
      } as FieldDefs,
      supplier: {
        fieldType: FieldType.selectAsyncMultiField,
        label: 'Supplier',
        dataAddr: 'suppliers',
        useValueOnly: true,
        valueKey: 'id',
        descriptionKey: 'name',
        loadOptionItems: supplierModel.searchSuppliers,
        loadItems: (ids, _) => supplierModel.findSuppliers(ids),
        autoload: true,
      } as FieldDefs,
      deliveryLocations: {
        fieldType: FieldType.selectMultiField,
        label: 'Delivery To',
        dataAddr: 'deliveryLocations',
        useValueOnly: true,
        valueKey: 'id',
        descriptionKey: 'description',
        optionItems: purchaseOrderModel.deliveryLocations.slice(),
      } as FieldDefs,
      exported: {
        fieldType: FieldType.yesNoField,
        dataAddr: 'exported',
        label: 'Exported',
      } as FieldDefs,
    };

    const getModalDef = (modalTitle: string): ISectionDef => {
      return {
        title: modalTitle,
        asForm: true,
        panels: [
          {
            panes: [
              {
                paneType: PaneType.formFieldsPane,
                columnCount: 1,
                fields: [
                  {
                    fieldType: FieldType.yesNoField,
                    label: 'Do you want to mark these records as exported',
                    dataAddr: 'markAsExported',
                    mandatory: true,
                  },
                ],
              },
            ],
          },
        ],
        secondaryActions: [getSubmitCloseModalActionGroupDef('Ok')],
        onFormSubmit: v => {
          return exportToExcel(v.markAsExported);
        },
      };
    };

    return {
      primaryTitle: <PrimaryTitle title="Purchase Transactions"></PrimaryTitle>,
      primarySize: PagePrimarySize.full,
      onLoadData: listPurchaseTransactionsModel.listItems,
      externalSearch: true,
      hasMoreData: listPurchaseTransactionsModel.hasMoreItems,
      primaryActions: [
        {
          actions: [
            {
              actionType: ActionType.actionCollection,
              actionGroups: [
                {
                  actions: [
                    {
                      actionType: ActionType.modalActionButton,
                      label: 'Export To Excel',
                      icon: <ExcelIcon fixedWidth />,
                      modalSize: ShellModalSize.oneQuarter,
                      modalDef: _ => getModalDef('Export To Excel'),
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
      primaryFields: [
        {
          fieldType: FieldType.selectField,
          dataAddr: 'transactionType',
          label: 'Type',
          optionItems: allPurchaseTransactionType,
          valueKey: 'value',
          descriptionKey: 'description',
          useValueOnly: true,
          readonly: true,
        },
        {
          fieldType: FieldType.textField,
          dataAddr: 'transactionNumber',
          label: 'Number',
          linkTo: d => {
            if (d.parentValue.transactionType === PurchaseTransactionType.Invoice) {
              return `/workshop/purchase-orders/${d.parentValue.purchaseOrderId}/goods-received/${d.parentValue.goodsReceivedId}`;
            } else if (d.parentValue.transactionType === PurchaseTransactionType.CreditNote) {
              return `/workshop/purchase-orders/${d.parentValue.purchaseOrderId}/goods-received/${d.parentValue.goodsReceivedId}/returned-part-credits/${d.parentValue.creditNoteId}`;
            }
            return undefined;
          },
        },
        {
          fieldType: FieldType.dateField,
          dataAddr: 'transactionDate',
          label: 'Date',
        },
        {
          fieldType: FieldType.textField,
          dataAddr: 'purchaseOrderNumber',
          label: 'PO Number',
          linkTo: d => `/workshop/purchase-orders/${d.parentValue.purchaseOrderId}`,
        },
        {
          fieldType: FieldType.readonlyField,
          label: 'PO Status',
          dataAddr: ['status', 'description'],
        },
        {
          fieldType: FieldType.textField,
          dataAddr: 'supplierName',
          label: 'Supplier',
        },
        {
          fieldType: FieldType.customField,
          dataAddr: 'exported',
          label: 'Exported',
          render: api => (api.data.parentValue.exported ? <CheckIcon /> : null),
        },
      ],
      filterAction: {
        filterFields: Object.keys(filterFieldDefs).map(filters => filterFieldDefs[filters]),
        filterDef: _ => [
          {
            panes: [
              {
                paneType: PaneType.formFieldsPane,
                columnCount: 2,
                fields: [filterFieldDefs.startDate, filterFieldDefs.endDate],
              },
              {
                paneType: PaneType.formFieldsPane,
                fields: [
                  filterFieldDefs.supplier,
                  filterFieldDefs.deliveryLocations,
                  filterFieldDefs.exported,
                ],
              },
            ],
          },
        ],
      },
    };
  };

  return (
    <ListPage
      className="list-purchase-transactions-component"
      data={listPurchaseTransactionsModel.items}
      def={getPageDef()}
    />
  );
});

export default withQueryParams(ListPurchaseTransactions);
