import { types, flow, getRoot } from 'mobx-state-tree';
import { getAjax } from 'src/domain/services/storeEnvironment';
import { IRootStoreModel } from 'src/domain/entities/RootStoreModel';
import { ListRosterGroupModel } from './ListRosterGroupModel';

type RosterGroup = Operations.Domain.Queries.GetRosterGroups.RosterGroupDto;
type RosterGroupItem = Operations.Domain.Queries.ViewRosterGroup.RosterGroupItem;
type CreateRosterGroupCommand = Operations.Domain.Commands.Roster.CreateRosterGroup.CreateRosterGroupCommand;

export const RosterGroupModel = types
  .model('RosterGroupModel', {
    rosterGroup: types.maybe(types.frozen<RosterGroupItem>()),
    rosterGroups: types.array(types.frozen<RosterGroup>()),
    list: types.optional(ListRosterGroupModel, {}),
  })
  .actions(self => {
    const ajax = getAjax(self);
    const root = getRoot(self) as IRootStoreModel;

    const createRosterGroup = flow(function*(command: CreateRosterGroupCommand) {
      const id = yield ajax.urban.rosterGroups.createRosterGroup(command);
      self.rosterGroup = undefined;
      root.history.push(`/operations/roster-groups/${id}`);
    });

    const loadRosterGroup = flow(function*(rosterGroupId: string) {
      self.rosterGroup = yield ajax.urban.rosterGroups.viewRosterGroup(rosterGroupId);
    });

    const updateRosterGroup = flow(function*(
      command: Operations.Domain.Commands.Roster.UpdateRosterGroup.UpdateRosterGroupCommand
    ) {
      yield ajax.urban.rosterGroups.updateRosterGroup(command);
      yield loadRosterGroup(command.id);
    });

    const listRosterGroups = flow(function*() {
      self.rosterGroups = yield ajax.urban.rosterGroups.listRosterGroups();
    });

    const deleteRosterGroup = flow(function*(rosterGroupId: string) {
      yield ajax.urban.rosterGroups.deleteRosterGroup(rosterGroupId);
      self.rosterGroup = undefined;
      root.history.replace('/operations/roster-groups');
    });

    const checkForUniqueRosterGroupName = flow(function*(name: string) {
      return yield ajax.urban.rosterGroups.checkForUniqueRosterGroupName(name);
    });

    return {
      createRosterGroup,
      loadRosterGroup,
      updateRosterGroup,
      deleteRosterGroup,
      listRosterGroups,
      checkForUniqueRosterGroupName,
    };
  });
