import { fileNoteTypeDescription } from 'src/api/enums';
import { PlusIcon, EditIcon } from 'src/images/icons';
import {
  PaneType,
  FieldType,
  ActionType,
  ShellModalSize,
  ISectionDef,
} from 'src/views/definitionBuilders/types';
import getFileNoteModalDef from './getFileNoteModalDef';

type CreateStaffMemberFileNoteCommand = People.Domain.Commands.StaffMembers.CreateStaffMemberFileNote.CreateStaffMemberFileNoteCommand;
type UpdateStaffMemberFileNoteCommand = People.Domain.Commands.StaffMembers.UpdateStaffMemberFileNote.UpdateStaffMemberFileNoteCommand;

export default function getLicencesSectionDef(
  canSave: boolean,
  staffMemberId: string,
  createFileNote: (cmd: CreateStaffMemberFileNoteCommand) => Promise<void>,
  updateFileNote: (cmd: UpdateStaffMemberFileNoteCommand) => Promise<void>,
  primarySectionEditing: boolean
): ISectionDef {
  return {
    title: 'File Notes',
    panels: [
      {
        panes: [
          {
            paneType: PaneType.tablePane,
            dataAddr: 'fileNotes',
            neverEditable: true,
            fields: [
              {
                fieldType: FieldType.readonlyField,
                dataAddr: 'fileNoteType',
                label: 'Type',
                formatReadonly: d => fileNoteTypeDescription(d.fieldValue),
              },
              {
                fieldType: FieldType.readonlyField,
                dataAddr: 'content',
                label: 'Content',
              },
              {
                fieldType: FieldType.readonlyField,
                dataAddr: 'createdBy',
                label: 'Added By',
              },
              {
                fieldType: FieldType.dateTimeField,
                dataAddr: 'createdOn',
                label: 'Created On',
              },
              {
                fieldType: FieldType.actionListField,
                columnWidth: '1px',
                hidden: () => !canSave || primarySectionEditing,
                actionGroups: [
                  {
                    actions: [
                      {
                        actionType: ActionType.modalActionButton,
                        label: 'Edit File Note',
                        icon: <EditIcon />,
                        modalSize: ShellModalSize.half,
                        modalDef: getFileNoteModalDef(
                          'edit',
                          staffMemberId,
                          createFileNote,
                          updateFileNote
                        ),
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
    secondaryActions: [
      {
        actions: [
          {
            hidden: () => !canSave || primarySectionEditing,
            actionType: ActionType.modalActionButton,
            label: 'Add File Note',
            icon: <PlusIcon />,
            level: 'primary',
            modalSize: ShellModalSize.half,
            modalDef: getFileNoteModalDef('add', staffMemberId, createFileNote, updateFileNote),
          },
        ],
      },
    ],
  };
}
