import styles from './Login.module.scss';
import SplitScreen from '../components/layout/SplitScreen/SplitScreen';
import KeyPad from '../../components/presentational/KeyPad/KeyPad';
import { DateTime } from 'luxon';
import { useInterval } from 'src/views/hooks/useInterval';
import { RunsheetSlider } from '../Runsheet/RunsheetSlider';
import { useState } from 'react';
import { INotificationOptions } from 'src/domain/baseTypes';
import DepotSelector from '../DepotSelector/DepotSelector';

type WorkshopDepotDto = Common.Queries.Workshop.GetWorkshopDepots.WorkshopDepotDto;

export interface ILoginProps {
  signIn: (employeeId: string) => Promise<void>;
  deviceDepot?: WorkshopDepotDto;
  hasMultipleWorkshopDepots: boolean;
  depots: WorkshopDepotDto[];
  saveDeviceDepotToLocalStorage: (depotId: number) => void;
  addNotification: (message: string, options?: Partial<INotificationOptions>) => void;
}

const Login: React.FC<ILoginProps> = ({
  signIn,
  deviceDepot,
  hasMultipleWorkshopDepots,
  depots,
  saveDeviceDepotToLocalStorage,
  addNotification,
}) => {
  const [now, setNow] = useState(DateTime.local());
  const [runsheetOpen, setRunSheetOpen] = useState(false);
  const [changeDepotScreenOpen, setChangeDepotScreenOpen] = useState(false);
  useInterval(() => setNow(DateTime.local()), 15000);

  return (
    <SplitScreen
      left={
        <div className={styles.left}>
          <h1>{now.toLocaleString(DateTime.DATETIME_MED)}</h1>
        </div>
      }
      right={
        <div className={styles.right}>
          <div className={styles.topBar}>
            <DepotSelector
              depot={deviceDepot}
              depots={depots}
              saveDeviceDepotToLocalStorage={saveDeviceDepotToLocalStorage}
              disabled={!hasMultipleWorkshopDepots}
              toggleSlider={() => setChangeDepotScreenOpen(!changeDepotScreenOpen)}
              isOpen={changeDepotScreenOpen}
              addNotification={addNotification}
            />
            <RunsheetSlider
              deviceDepot={deviceDepot}
              toggleSlider={() => setRunSheetOpen(!runsheetOpen)}
              isOpen={runsheetOpen}
              width={50}
            />
          </div>
          <div className={styles.main}>
            <h2>Enter Employee ID</h2>
            <KeyPad submit={signIn} pinLength={4} />
          </div>
        </div>
      }
    />
  );
};

export default Login;
