import buildListPageApiSearchModel from 'src/domain/modelBuilders/buildListPageApiSearchModel';
import { getAjax } from 'src/domain/services';
import { getRoot, flow, types } from 'mobx-state-tree';
import { IRootStoreModel } from 'src/domain/entities/RootStoreModel';
import { NotificationType } from 'src/domain';
import * as queryString from 'query-string';
import { workshopUrls } from 'src/domain/services/apiUrls';

const ListSuppliersModel = buildListPageApiSearchModel('ListSuppliersModel', d =>
  d.ajax.workshop.supplier.listSuppliers(d.page, 100, d.query.search || '')
);

const ExportSuppliersModel = types.model('ExportSuppliersModel').actions(self => {
  const ajax = getAjax(self);
  const root = getRoot(self) as IRootStoreModel;

  const exportToExcel = flow(function*(
    query: Partial<Workshop.Domain.Queries.Suppliers.GetSuppliers.ListSuppliersQuery>
  ) {
    root.notifications.addNotification(`The file is being generated ...`, {
      type: NotificationType.info,
    });

    return yield ajax.raw
      .getFile(`/api/workshop/suppliers/excel?${queryString.stringify(query)}`)
      .toPromise()
      .then<Blob>(r => r.response);
  });

  const exportAttachmentsToExcel = flow(function*() {
    root.notifications.addNotification(`The file is being generated ...`, {
      type: NotificationType.info,
    });

    return yield ajax.raw
      .getFile('/api/workshop/suppliers/attachments/excel')
      .toPromise()
      .then<Blob>(r => r.response);
  });

  const exportNotesToExcel = flow(function*() {
    root.notifications.addNotification(`The file is being generated ...`, {
      type: NotificationType.info,
    });

    return yield ajax.raw
      .getFile(workshopUrls.supplierUrls.exportSupplierNotes())
      .toPromise()
      .then<Blob>(r => r.response);
  });

  return {
    exportToExcel,
    exportAttachmentsToExcel,
    exportNotesToExcel,
  };
});

export const SuppliersModel = types.compose(ListSuppliersModel, ExportSuppliersModel);
