import './index.scss';
import './rxjsImports';
import 'react-calendar/dist/Calendar.css';

import * as ReactDOM from 'react-dom';
import { BrowserRouter, RouteComponentProps } from 'react-router-dom';
import configureLuxon from 'src/infrastructure/configureLuxon';
import { getDefaultStore, NotificationType } from 'src/domain/';
import App from 'src/views/App';
import { IKioskRootStoreModel, getKioskStore } from 'src/domain/entities/KioskRootStoreModel';
import Routes from 'src/views/routes';
import opsKioskRoutes from 'src/views/kioskRoutes/operationsKiosk';
import wsKioskRoutes from 'src/views/kioskRoutes/workshopKiosk';
import { isDevelopment } from 'src/appSettings';
import makeInspectable from 'mobx-devtools-mst';
import { connectReduxDevtools } from 'mst-middlewares';
import { IRootStoreModel } from './domain/entities/RootStoreModel';
import { getBus, getSignalHub } from './domain/services';
import logger from './infrastructure/logging';
import 'react-calendar/dist/Calendar.css';

let store: IRootStoreModel | IKioskRootStoreModel;

async function init() {
  // Polyfill URLSearchParams for Edge, but only download the package if necessary
  if (!window.URLSearchParams) {
    await import('url-search-params-polyfill');
  }

  // tslint:disable-next-line:no-any
  let routes: React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any>;

  if (window.location.pathname.startsWith('/kiosk')) {
    store = await getKioskStore();
    routes = opsKioskRoutes;
  } else if (window.location.pathname.startsWith('/wskiosk')) {
    store = await getKioskStore();
    routes = wsKioskRoutes;
  } else {
    store = await getDefaultStore();
    routes = Routes;
  }

  let bus = getBus(store);
  let hub = getSignalHub(store);

  hub.on('OperationsDomainEvent', (name, data) => {
    logger.info('Hub Event OperationsDomainEvent : {name} {@data}', { name, data });
    bus.operationsDomainEvent({ eventName: name, payload: data });
  });
  hub.start();

  if (isDevelopment()) {
    connectReduxDevtools(require('remotedev'), store);
    store = makeInspectable(store) as typeof store;
  }

  // Failing to configure Luxon will not stop the app from booting
  await configureLuxon(logger).catch(e =>
    store.notifications.addNotification(e.message, { type: NotificationType.warn })
  );

  ReactDOM.render(
    <App rootStore={store} router={BrowserRouter} routes={routes} />,
    document.getElementById('root') as HTMLElement
  );
}

init().catch(e => {
  console.error(e);
  logger.error(e);
  // If the init fails, we want to know about it
  // tslint:disable-next-line:no-console
  console.error('Error in init:', e);
});
