import styles from './Task.module.scss';
import './Task.scss';

import { Formik, Form, FieldArray } from 'formik';
import {
  allActionType,
  ChangeState,
  allOnHoldStatus,
  actionTypeDescription,
  JobTaskCategory,
  JobTaskStatus,
} from 'src/api/enums';
import { DateTime } from 'luxon';
import { OnHoldIcon } from '../components/presentational/OnHoldIcon';
import SelectField from '../components/form/SelectField';
import AsyncSelectField from '../components/form/AsyncSelectField';
import TextField from '../components/form/TextField';
import NumberField from '../components/form/NumberField';
import DeleteField from '../components/form/DeleteField';
import ToggleField from '../components/form/ToggleField';
import Spinner from 'src/views/components/Spinner';
import cn from 'classnames';
import * as Yup from 'yup';
import { FormattedDateTime } from '../../components/presentational/FormattedDateTime/FormattedDateTime';
import DayNavigation from '../../components/presentational/DayNavigation/DayNavigation';
import { SingleClickAwareButton } from '../../components/presentational/SingleClickAwareButton/SingleClickAwareButton';
import Button from 'reactstrap/lib/Button';
import { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { useKioskRootStore } from 'src/domain/entities/KioskRootStoreModel';
import { ENABLE_SHOW_CUBIC_REGISTER } from 'src/appSettings';

type JobTaskDetails = Workshop.Domain.Queries.GetJobTask.JobTaskDetails;
type CompletionDetails = Workshop.Domain.Queries.GetJobTask.CompletionDetailsItem;
type AssetCubicItem = Workshop.Domain.Commands.AssetCubic.AssetCubicSummaryItem;
type JobTaskLabourItem = Workshop.Domain.Queries.GetJobTask.JobTaskLabourItem;
type AssetItem = Workshop.Domain.Queries.AssetItem;
interface IExtendedAssetCubicItem extends AssetCubicItem {
  newSerialNumber?: string;
}

interface IExtendedAssetItem extends AssetItem {
  assetCubicSummaryItems: IExtendedAssetCubicItem[];
}

const DServiceSubcategoryId = 16;
const CleaningSubCategoryId = 169;
const showCubicRegisterTab = ENABLE_SHOW_CUBIC_REGISTER;
export interface ITaskProps {
  id: string;
  readonly?: boolean;
  afterSubmit?: () => void;
}

const Task: React.FC<ITaskProps> = observer(({ id, readonly, afterSubmit }) => {
  const rootStore = useKioskRootStore();
  const task = rootStore.kiosk.workshop.task.taskDetails as JobTaskDetails & {
    cubicChanges?: boolean;
    asset: IExtendedAssetItem;
    assetCubicChanges?: AssetCubicItem[];
  };
  const getTask = rootStore.kiosk.workshop.task.getTask;
  const updateTask = rootStore.kiosk.workshop.task.updateTask;
  const workshopStaff = rootStore.kiosk.staff.workshopStaff.slice();
  const allStaff = rootStore.kiosk.staff.activeStaffMembers.slice();
  const loadAllStaffMembers = rootStore.kiosk.staff.loadAllStaffMembers;
  const searchParts = rootStore.kiosk.workshop.parts.searchParts;
  const loggedInStaffMemberId = rootStore.account.id;
  const startNow = rootStore.kiosk.workshop.task.startNow;
  const setToInProgress = rootStore.kiosk.workshop.task.setToInProgress;
  const nextOpsJobForAsset = rootStore.kiosk.asset.nextOpsJobForAsset;
  const loadNextOpsJobsForAsset = rootStore.kiosk.asset.loadNextOpsJobsForAsset;
  const updateDate = rootStore.kiosk.updateDate;
  const date = rootStore.kiosk.date;
  const cubicFaultDetails = rootStore.kiosk.cubicRegister.activeCubicFaultDetails.slice();
  const loadCubicFaultDetails = rootStore.kiosk.cubicRegister.loadCubicFaultDetails;
  const deviceDepotId = rootStore.kiosk.workshop.defaultKioskDepotId;
  const cubicItems = rootStore.kiosk.cubicRegister.activeCubicItems.slice();
  const loadCubicItems = rootStore.kiosk.cubicRegister.loadCubicItems;
  const [isLoading, setIsLoading] = useState(!!task);

  useEffect(() => {
    if (!task || task.id !== id) {
      getTask(id).then(() => setIsLoading(false));
    } else if (!nextOpsJobForAsset || nextOpsJobForAsset.assetId !== task.asset.id) {
      loadNextOpsJobsForAsset(task.asset.id);
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  }, [id]);

  useEffect(() => {
    loadCubicFaultDetails();
    loadAllStaffMembers();
    loadCubicItems();
  }, []);

  useEffect(() => {
    if (!readonly && task && task.job) {
      const endDate = DateTime.fromISO(task.job.endDateTime).startOf('day');
      const startDate = DateTime.fromISO(task.job.startDateTime).startOf('day');

      const isDateBeforeStart = date.startOf('day').diff(startDate, 'days').days < 0;
      const isDateAfterEnd = date.startOf('day').diff(endDate, 'days').days > 0;

      if (isDateBeforeStart || isDateAfterEnd) {
        updateDate(endDate);
      }
    }
  }, [task]);

  const OnHoldStatusDisplay = () => {
    const found = task && allOnHoldStatus.find(x => x.description === task.onHoldStatus);
    if (found && task) {
      return (
        <div className={styles.onHoldStatus}>
          <OnHoldIcon onHoldStatus={found.value as number} />
          <p>{task.onHoldStatus}</p>
        </div>
      );
    }

    return null;
  };

  const getValidationSchema = () => {
    const issueShape = Yup.string().when('changeState', {
      is: d => d !== ChangeState.Deleted,
      then: Yup.string().required('Required'),
    });

    const workDoneByShape = Yup.string().when('changeState', {
      is: d => d !== ChangeState.Deleted,
      then: Yup.string().required('Required'),
    });

    const taskAssetHasOdometer = task && task.asset.subcategory.hasOdometer;
    const shape = {
      parts: Yup.array().of(
        Yup.object().shape({
          part: Yup.object()
            .nullable()
            .when('changeState', {
              is: d => d !== ChangeState.Deleted,
              then: Yup.object().required('Required'),
            }),
          quantity: Yup.number()
            .transform(v => (isNaN(v) ? 0 : v))
            .when('changeState', {
              is: d => d !== ChangeState.Deleted,
              then: Yup.number()
                .positive('Must be positive')
                .required('Required'),
            }),
        })
      ),
      labour: Yup.array()
        .of(
          Yup.object().shape({
            hours: Yup.number()
              .nullable()
              .max(8, 'Max 8 hrs'),
            staffMemberId: Yup.string()
              .nullable()
              .when('changeState', {
                is: d => d !== ChangeState.Deleted,
                then: Yup.string().required('Required'),
              }),
          })
        )
        .when('fitForService', {
          is: d => d === false || d === true,
          then: Yup.array()
            .test('non-zero-hours-for-logged-in-user', 'Please enter your hours', function(value) {
              return !!(
                value.length &&
                value.filter(
                  (v: JobTaskLabourItem) =>
                    v.changeState !== ChangeState.Deleted &&
                    v.staffMemberId === loggedInStaffMemberId &&
                    v.hours &&
                    v.hours > 0
                ).length
              );
            })
            .test('non-zero-hours-for-other-mechanics', 'Please enter missing hours', function(
              value
            ) {
              return (
                value.length &&
                value.filter(
                  (v: JobTaskLabourItem) =>
                    v.changeState !== ChangeState.Deleted &&
                    v.staffMemberId !== loggedInStaffMemberId &&
                    (!v.hours || v.hours === 0)
                ).length === 0
              );
            }),
        })
        .test('duplication-test', 'Staff duplicated on same day', l =>
          l
            .filter((el: { changeState: ChangeState }) => el.changeState !== ChangeState.Deleted)
            .every(
              (el: { date: string; staffMemberId: string }) =>
                l.filter(
                  (el2: { changeState: ChangeState; date: string; staffMemberId: string }) =>
                    el.date === el2.date &&
                    el.staffMemberId === el2.staffMemberId &&
                    el2.changeState !== ChangeState.Deleted
                ).length === 1
            )
        ),
      completionDetails: Yup.array()
        .when('fitForService', {
          is: d => d !== undefined && d !== null,
          then: Yup.array().of(
            Yup.object().shape({
              issue: issueShape,
              action: Yup.number().when('changeState', {
                is: d => d.changeState !== ChangeState.Deleted,
                then: Yup.number().required('Required'),
              }),
              workDoneBy: workDoneByShape,
              workDone: Yup.string().required('Required'),
            })
          ),
          otherwise: Yup.array().of(
            Yup.object().shape({
              issue: issueShape,
              action: Yup.number().when('changeState', {
                is: d => d.changeState !== ChangeState.Deleted,
                then: Yup.number().nullable(),
              }),
              workDoneBy: workDoneByShape,
            })
          ),
        })
        .when('fitForService', {
          is: d => d === false || d === true,
          then: Yup.array().test('at-least-one-undeleted', 'one-required', function(value) {
            return !!(
              value.length &&
              value.filter((v: CompletionDetails) => v.changeState !== ChangeState.Deleted).length
            );
          }),
        }),

      odometerReading: Yup.number()
        .transform(v => (isNaN(v) ? 0 : v))
        .nullable()
        .when('fitForService', {
          is: f => f === true && taskAssetHasOdometer,
          then: Yup.number().required('Required'),
        })
        .positive('Must be positive')
        .integer('No decimals'),
      roadTestDone: Yup.boolean().nullable(),
      brakeTestDone: Yup.boolean().nullable(),
      servicedAsPerChecklist: Yup.boolean().nullable(),
      valveAdjustmentDone: Yup.boolean().nullable(),
      cubicChanges: Yup.boolean().nullable(),
      asset: Yup.object().nullable(),
    };

    if (
      task &&
      taskAssetHasOdometer &&
      (task.category.id === JobTaskCategory.PreMachineryInspection ||
        task.category.id === JobTaskCategory.MachineryInspection ||
        task.category.id === JobTaskCategory.Service)
    ) {
      shape.odometerReading = Yup.number()
        .transform(v => (isNaN(v) ? 0 : v))
        .positive('Must be positive')
        .integer('No decimals')
        .required('Required');
    }

    if (
      task &&
      (task.category.id === JobTaskCategory.PreMachineryInspection ||
        task.category.id === JobTaskCategory.Service)
    ) {
      shape.roadTestDone = Yup.boolean()
        .nullable()
        .when('fitForService', {
          is: d => d === false || d === true,
          then: Yup.boolean().required('Required'),
        });
      shape.brakeTestDone = Yup.boolean()
        .nullable()
        .when('fitForService', {
          is: d => d === false || d === true,
          then: Yup.boolean().required('Required'),
        });
    }

    if (task && task.asset.assetCubicSummaryItems) {
      shape.cubicChanges = Yup.boolean()
        .nullable()
        .when('fitForService', {
          is: d => d === false || d === true,
          then: Yup.boolean().required('Required'),
        });

      shape.asset = Yup.object()
        .when('cubicChanges', {
          is: d => d === true,
          then: Yup.object().shape({
            assetCubicSummaryItems: Yup.array()
              .of(
                Yup.object<IExtendedAssetCubicItem>().shape({
                  cubicFaultDetailId: Yup.number()
                    .nullable()
                    .when('newSerialNumber', {
                      is: d => d && d.length > 0,
                      then: Yup.number().required('Required'),
                    }),
                })
              )
              .test('at-least-one', 'one-required', function(value) {
                return !!(
                  value &&
                  value.filter(
                    (i: IExtendedAssetCubicItem) => i.jobTaskId === task!.id || i.newSerialNumber
                  ).length
                );
              }),
          }),
        })
        .required();
    }

    if (
      task &&
      (task.category.id === JobTaskCategory.MachineryInspection ||
        task.category.id === JobTaskCategory.Service ||
        task.category.id === JobTaskCategory.PreMachineryInspection) &&
      task.checklistId
    ) {
      shape.servicedAsPerChecklist = Yup.boolean()
        .nullable()
        .when('fitForService', {
          is: d => d === false || d === true,
          then: Yup.boolean().required('Required'),
        });
    }

    if (task && task.subcategoryId === DServiceSubcategoryId) {
      shape.valveAdjustmentDone = Yup.boolean()
        .nullable()
        .when('fitForService', {
          is: d => d === false || d === true,
          then: Yup.boolean().required('Required'),
        });
    }

    return Yup.object().shape(shape);
  };

  const isReadonly =
    readonly ||
    !task ||
    task.status.id === JobTaskStatus.Cancelled ||
    task.status.id === JobTaskStatus.Completed;

  const [showStartNowWarning, setShowStartNowWarning] = useState(false);

  const handleStartNow = (): Promise<void> => {
    if (!task) {
      return Promise.resolve();
    }

    if (
      !showStartNowWarning &&
      nextOpsJobForAsset &&
      DateTime.fromISO(nextOpsJobForAsset.clockOn) < DateTime.local().plus({ minutes: 15 }) &&
      DateTime.fromISO(nextOpsJobForAsset.clockOff) > DateTime.local()
    ) {
      setShowStartNowWarning(true);
      return Promise.resolve();
    } else {
      return startNow(task.id, deviceDepotId).then(_ => setShowStartNowWarning(false));
    }
  };

  const handleSetToInProgress = (): Promise<void> => {
    if (!task) {
      return Promise.resolve();
    }
    return setToInProgress(task.id);
  };

  const staffList = !task
    ? []
    : task.subcategoryId === CleaningSubCategoryId
    ? allStaff
    : workshopStaff;

  const isMultiDay =
    (task &&
      task.job &&
      Math.abs(
        DateTime.fromISO(task.job.endDateTime).diff(
          DateTime.fromISO(task.job.startDateTime),
          'days'
        ).days
      ) >= 1) ||
    false;

  return (
    <div className={cn(styles.task, 'wsktask')}>
      {task && !isLoading ? (
        <Formik
          enableReinitialize
          validateOnChange
          initialValues={{
            ...task,
            cubicChanges:
              task &&
              task.asset.assetCubicSummaryItems &&
              task.asset.assetCubicSummaryItems.filter(i => i.jobTaskId === task.id).length > 0
                ? true
                : false,
          }}
          validationSchema={getValidationSchema()}
          validateOnBlur
          onSubmit={values => {
            values.labour.forEach(l => {
              var tl = task.labour.find(t => t.id === l.id);
              if (
                l.changeState !== ChangeState.Deleted &&
                tl &&
                (tl.hours !== l.hours || tl.staffMemberId !== l.staffMemberId)
              ) {
                l.changeState = ChangeState.Modified;
              }
            });

            values.completionDetails.forEach(l => {
              var tl = task.completionDetails.find(t => t.id === l.id);
              if (
                l.changeState !== ChangeState.Deleted &&
                tl &&
                (tl.issue !== l.issue ||
                  tl.action !== l.action ||
                  tl.workDone !== l.workDone ||
                  tl.workDoneBy !== l.workDoneBy)
              ) {
                l.changeState = ChangeState.Modified;
              }
            });

            values.parts.forEach(l => {
              var tl = task.parts.find(t => t.id === l.id);
              if (l.changeState !== ChangeState.Deleted && tl && tl.quantity !== l.quantity) {
                l.changeState = ChangeState.Modified;
              }
            });

            setIsLoading(true);
            updateTask({
              ...values,
              depotId: deviceDepotId,
              jobTaskId: task.id,
              updateDate: DateTime.local().toISO(),
              completionDetails: values.completionDetails.map(c => ({
                ...c,
                workDoneBy: c.workDoneBy!,
              })),
              parts: values.parts
                .filter(p => !!p.part)
                .map(p => ({
                  ...p,
                  partId: p.part.id,
                  description: p.description || p.part.description,
                })),
              labour: values.labour
                .filter(m => !!m.staffMemberId)
                .map(m => ({ ...m, staffMemberId: m.staffMemberId })),
              fitForService: values.fitForService,
              assetCubicChanges: values.cubicChanges
                ? values.asset.assetCubicSummaryItems
                    .filter(
                      (i: IExtendedAssetCubicItem) =>
                        i.newSerialNumber && i.serialNumber !== i.newSerialNumber
                    )
                    .map(
                      i =>
                        ({
                          ...i,
                          serialNumber: i.newSerialNumber,
                          jobTaskId: task.id,
                        } as AssetCubicItem)
                    )
                : [],
            })
              .then(() => {
                if (afterSubmit) {
                  afterSubmit();
                }
              })
              .then(() => setIsLoading(false));
          }}>
          {({ values, isSubmitting, errors, submitCount }) => {
            const renderOdometerReadingField =
              values.asset.subcategory.hasOdometer &&
              (!isReadonly || (values && values.odometerReading));

            return (
              <Form>
                <div className={styles.heading}>
                  <div>
                    <h1 className={styles.currentTask}>Task {task.jobTaskNumber}</h1>
                    {task.defect && task.defect.sourceTask && (
                      <h3 className={styles.sourceTask}>
                        Original Task {task.defect.sourceTask.taskNumber}
                      </h3>
                    )}
                  </div>
                  {task.status.id === JobTaskStatus.Future && (
                    <>
                      {showStartNowWarning ? (
                        <div className={styles.extensionWarning}>
                          <span>
                            Starting this task now will conflict with an operations job. Contact
                            Operations if you decide to go ahead.
                          </span>
                          <div>
                            <SingleClickAwareButton onClick={handleStartNow}>
                              Start Now
                            </SingleClickAwareButton>
                            <SingleClickAwareButton onClick={() => setShowStartNowWarning(false)}>
                              Cancel
                            </SingleClickAwareButton>
                          </div>
                        </div>
                      ) : (
                        <SingleClickAwareButton onClick={handleStartNow}>
                          Start Now
                        </SingleClickAwareButton>
                      )}
                    </>
                  )}
                  {task.status.id !== JobTaskStatus.Future && (
                    <div className={styles.jobBoundsContainer}>
                      <div>
                        {task.status.id === JobTaskStatus.Scheduled && (
                          <SingleClickAwareButton onClick={handleSetToInProgress}>
                            Set To In Progress
                          </SingleClickAwareButton>
                        )}
                        <FormattedDateTime dateTime={task.job.startDateTime} />
                        <span> - </span>
                        <FormattedDateTime
                          dateTime={task.job.endDateTime}
                          referenceDateTime={task.job.startDateTime}
                        />
                      </div>
                      {isMultiDay && (
                        <DayNavigation
                          date={date}
                          onDateChanged={updateDate}
                          todayText={date.toLocaleString(DateTime.DATE_MED)}
                          minDate={DateTime.fromISO(task.job.startDateTime).startOf('day')}
                          maxDate={DateTime.fromISO(task.job.endDateTime).endOf('day')}
                        />
                      )}
                    </div>
                  )}
                </div>
                <OnHoldStatusDisplay />
                <div className={styles.formContainer}>
                  <div className={styles.description}>
                    <p>{task.description}</p>
                  </div>
                  {renderOdometerReadingField && (
                    <div className={styles.odometerReading}>
                      <h2>Odometer Reading</h2>
                      <NumberField
                        isWide
                        dataAddress={'odometerReading'}
                        dataValue={values.odometerReading}
                        readOnly={isReadonly}
                      />
                    </div>
                  )}
                  <div className={styles.formSection}>
                    <h2>Details</h2>
                    <FieldArray
                      name={'completionDetails'}
                      render={arrayHelpers => (
                        <div className={styles.completionDetails}>
                          {values.completionDetails.length ? (
                            <>
                              {values.completionDetails.map((d, idx) => (
                                <div
                                  className={cn(
                                    styles.item,
                                    d.changeState === ChangeState.Deleted ? styles.deleted : ''
                                  )}
                                  key={idx}>
                                  <div className={styles.inputFields}>
                                    <TextField
                                      dataAddress={`completionDetails.${idx}.issue`}
                                      dataValue={values.completionDetails[idx].issue}
                                      dataLabel={'Issue'}
                                      readOnly={isReadonly}
                                      className={styles.breakText}
                                    />
                                    <TextField
                                      dataAddress={`completionDetails.${idx}.workDone`}
                                      dataValue={values.completionDetails[idx].workDone}
                                      dataLabel={'Work Done'}
                                      readOnly={isReadonly}
                                      className={styles.breakText}
                                    />
                                    <div className={styles.row}>
                                      <SelectField
                                        dataLabel={'Action'}
                                        dataAddress={`completionDetails.${idx}.action`}
                                        options={allActionType.map(a => ({
                                          id: a.value,
                                          name: a.description,
                                        }))}
                                        dataValue={
                                          d.action
                                            ? {
                                                id: d.action,
                                                name: actionTypeDescription(d.action),
                                              }
                                            : undefined
                                        }
                                        className={styles.selectField}
                                        readOnly={isReadonly}
                                      />
                                      {task.status.id !== JobTaskStatus.Completed &&
                                        task.status.id !== JobTaskStatus.Cancelled && (
                                          <SelectField
                                            dataLabel={'Staff Member'}
                                            dataAddress={`completionDetails.${idx}.workDoneBy`}
                                            options={staffList.filter(
                                              o => o.active || d.workDoneBy === o.id
                                            )}
                                            dataValue={staffList.find(m => m.id === d.workDoneBy)}
                                            className={styles.selectField}
                                            readOnly={isReadonly}
                                          />
                                        )}
                                    </div>
                                  </div>
                                  <DeleteField
                                    readOnly={isReadonly}
                                    dataAddress={`completionDetails.${idx}.changeState`}
                                    deleteFunc={d.id ? undefined : () => arrayHelpers.remove(idx)}
                                  />
                                </div>
                              ))}
                            </>
                          ) : (
                            <div className={styles.emptyList}>
                              No details have been entered for this task.
                            </div>
                          )}
                          {submitCount > 0 && errors.completionDetails === 'one-required' && (
                            <div className={styles.errorMessage}>
                              At least one entry is required
                            </div>
                          )}
                          {!isReadonly && (
                            <div className={styles.buttonContainer}>
                              <SingleClickAwareButton
                                useReactStrapButton
                                onClick={() =>
                                  arrayHelpers.push({
                                    changeState: ChangeState.Added,
                                    issue: null,
                                    action: null,
                                    staffMember: null,
                                    workDoneBy: loggedInStaffMemberId,
                                  })
                                }>
                                + Add Details
                              </SingleClickAwareButton>
                            </div>
                          )}
                        </div>
                      )}
                    />
                  </div>

                  <div className={styles.formSection}>
                    <h2>Parts</h2>
                    <FieldArray
                      name={'parts'}
                      render={arrayHelpers => (
                        <div className={styles.parts}>
                          {values.parts.length ? (
                            <>
                              <div className={styles.labelRow}>
                                <div className={styles.selectLabel}>Part</div>
                                <div className={styles.textLabel}>Description</div>
                                <div className={styles.numberLabel}>Quantity</div>
                                <div className={styles.deleteLabel} />
                              </div>
                              {values.parts.map((p, i) => (
                                <div
                                  className={cn(
                                    styles.item,
                                    p.changeState === ChangeState.Deleted ? styles.deleted : ''
                                  )}
                                  key={i}>
                                  <div className={styles.row}>
                                    <div
                                      className={cn(
                                        styles.partNumber,
                                        isReadonly || !!(p && p.part && p.part.id)
                                          ? styles.readOnlyPartNumber
                                          : ''
                                      )}>
                                      <AsyncSelectField
                                        dataAddress={`parts.${i}.part`}
                                        loadOptions={searchParts}
                                        dataValue={p.part}
                                        className={cn(styles.selectField, styles.breakText)}
                                        readOnly={isReadonly || !!(p && p.part && p.part.id)}
                                        readOnlyValue={p && p.part && p.part.partNumber}
                                      />
                                      {values.parts[i].part && (
                                        <small className={cn(styles.location, styles.breakText)}>
                                          {
                                            values.parts[i].part.partLocations?.find(
                                              pl => pl.depotId === deviceDepotId
                                            )?.location
                                          }
                                        </small>
                                      )}
                                    </div>
                                    <TextField
                                      readOnly
                                      dataValue={
                                        values.parts[i].part ? values.parts[i].part.description : ''
                                      }
                                      dataAddress={`parts.${i}.part.description`}
                                      className={styles.breakText}
                                    />
                                    <NumberField
                                      dataAddress={`parts.${i}.quantity`}
                                      dataValue={values.parts[i].quantity}
                                      readOnly={isReadonly || (p && !p.mechanicCanDelete)}
                                      allowDecimal
                                      maxPointDigits={4}
                                    />
                                  </div>
                                  <DeleteField
                                    dataAddress={`parts.${i}.changeState`}
                                    readOnly={isReadonly || (p && !p.mechanicCanDelete)}
                                    deleteFunc={p.id ? undefined : () => arrayHelpers.remove(i)}
                                  />
                                </div>
                              ))}
                            </>
                          ) : (
                            <div className={styles.emptyList}>
                              There are no parts associated with this task.
                            </div>
                          )}
                          {!isReadonly && (
                            <div className={styles.buttonContainer}>
                              <SingleClickAwareButton
                                useReactStrapButton
                                onClick={() =>
                                  arrayHelpers.push({
                                    changeState: ChangeState.Added,
                                    quantity: null,
                                    part: null,
                                    mechanicCanDelete: true,
                                  })
                                }>
                                + Add Part
                              </SingleClickAwareButton>
                            </div>
                          )}
                        </div>
                      )}
                    />
                  </div>
                  {task.status.id !== JobTaskStatus.Completed &&
                    task.status.id !== JobTaskStatus.Cancelled && (
                      <div>
                        <h2>Labour</h2>
                        <div className={styles.labour}>
                          <FieldArray
                            name={'labour'}
                            render={arrayHelpers => (
                              <>
                                {values.labour.length ? (
                                  <>
                                    <div className={styles.labelRow}>
                                      <div className={styles.selectLabel}>Staff Member</div>
                                      <div className={styles.numberLabel}>Hours</div>
                                      <div className={styles.deleteLabel} />
                                    </div>
                                    {values.labour.map((l, i) => {
                                      return (
                                        <div
                                          className={cn(
                                            styles.item,
                                            l.changeState === ChangeState.Deleted
                                              ? styles.deleted
                                              : ''
                                          )}
                                          key={i}>
                                          <div className={styles.added}>
                                            {!l.date ? (
                                              'TBA'
                                            ) : DateTime.fromISO(l.date)
                                                .startOf('day')
                                                .diff(DateTime.local().startOf('day'), 'day')
                                                .days !== 0 ? (
                                              l.changeState === ChangeState.Added ? (
                                                <>
                                                  <strong>Adding for: </strong>
                                                  <FormattedDateTime dateTime={l.date!} hideTime />
                                                </>
                                              ) : (
                                                <FormattedDateTime dateTime={l.date!} hideTime />
                                              )
                                            ) : (
                                              <span>Today</span>
                                            )}
                                          </div>

                                          <div className={styles.row}>
                                            <SelectField
                                              dataAddress={`labour.${i}.staffMemberId`}
                                              options={staffList.filter(
                                                o =>
                                                  o.active ||
                                                  (l.staffMember && l.staffMemberId === o.id)
                                              )}
                                              dataValue={l.staffMember}
                                              className={styles.selectField}
                                              readOnly={isReadonly}
                                            />
                                            <NumberField
                                              dataAddress={`labour.${i}.hours`}
                                              dataValue={values.labour[i].hours}
                                              readOnly={isReadonly}
                                              allowDecimal
                                            />
                                          </div>
                                          <DeleteField
                                            dataAddress={`labour.${i}.changeState`}
                                            readOnly={isReadonly}
                                            deleteFunc={
                                              l.id ? undefined : () => arrayHelpers.remove(i)
                                            }
                                          />
                                        </div>
                                      );
                                    })}
                                  </>
                                ) : (
                                  <div className={styles.emptyList}>
                                    There is no labour entry for this task.
                                  </div>
                                )}
                                {!isReadonly && (
                                  <div className={styles.buttonContainer}>
                                    <SingleClickAwareButton
                                      useReactStrapButton
                                      onClick={() =>
                                        arrayHelpers.push({
                                          changeState: ChangeState.Added,
                                          date: date.toISODate(),
                                          staffMemberId: null,
                                          staffMember: null,
                                        })
                                      }>
                                      + Add Labour
                                    </SingleClickAwareButton>
                                    {typeof arrayHelpers.form.errors.labour === 'string' && (
                                      <div className={styles.errorMessage}>
                                        {arrayHelpers.form.errors.labour}
                                      </div>
                                    )}
                                  </div>
                                )}
                              </>
                            )}
                          />
                        </div>
                      </div>
                    )}
                  {showCubicRegisterTab &&
                    values &&
                    ((!isReadonly &&
                      values.asset.assetCubicSummaryItems &&
                      values.asset.assetCubicSummaryItems.length) ||
                      values.cubicChanges) && (
                      <div className={styles.inputFields}>
                        <div className={styles.row}>
                          <ToggleField
                            dataAddress={'cubicChanges'}
                            trueText={'Yes'}
                            falseText={'No'}
                            dataLabel={'CUBIC changed?'}
                            readOnly={isReadonly}
                          />
                        </div>
                        {values.cubicChanges && (
                          <>
                            <h2>Cubic Changes</h2>
                            <FieldArray
                              name={'asset.assetCubicSummaryItems'}
                              render={() => (
                                <div className={styles.cubicChanges}>
                                  {values.asset.assetCubicSummaryItems &&
                                  values.asset.assetCubicSummaryItems.length ? (
                                    <>
                                      {values.asset.assetCubicSummaryItems
                                        .filter((acsi: IExtendedAssetCubicItem) => acsi.isActive)
                                        .map((p, i) => (
                                          <div className={cn(styles.item)} key={i}>
                                            <div className={cn(styles.inputFields)}>
                                              <TextField
                                                readOnly
                                                dataLabel={'Name'}
                                                dataValue={
                                                  values.asset.assetCubicSummaryItems![i]
                                                    .cubicItemId
                                                    ? cubicItems.find(
                                                        c =>
                                                          c.id ===
                                                          values.asset.assetCubicSummaryItems![i]
                                                            .cubicItemId
                                                      )?.description ?? ''
                                                    : ''
                                                }
                                                dataAddress={`asset.assetCubicSummaryItems.${i}.cubicItem`}
                                              />
                                              <div className={styles.row}>
                                                <TextField
                                                  className={
                                                    values.asset.assetCubicSummaryItems![i]
                                                      .jobTaskId === task.id
                                                      ? styles.sameTask
                                                      : undefined
                                                  }
                                                  dataLabel={'Current Serial Number'}
                                                  dataAddress={`asset.assetCubicSummaryItems.${i}.serialNumber`}
                                                  dataValue={
                                                    values.asset.assetCubicSummaryItems![i]
                                                      .serialNumber || 'N/A'
                                                  }
                                                  readOnly
                                                />
                                                <TextField
                                                  dataLabel={'New Serial Number'}
                                                  dataAddress={`asset.assetCubicSummaryItems.${i}.newSerialNumber`}
                                                  dataValue={
                                                    values.asset.assetCubicSummaryItems![i]
                                                      .newSerialNumber || ''
                                                  }
                                                  readOnly={isReadonly}
                                                />
                                              </div>
                                              <SelectField
                                                dataLabel={'Fault Details'}
                                                dataAddress={`asset.assetCubicSummaryItems.${i}.cubicFaultDetailId`}
                                                options={cubicFaultDetails.map(d => ({
                                                  id: d.id,
                                                  name: d.description,
                                                }))}
                                                dataValue={cubicFaultDetails
                                                  .map(d => ({
                                                    id: d.id,
                                                    name: d.description,
                                                  }))
                                                  .find(
                                                    c =>
                                                      c.id ===
                                                      values.asset.assetCubicSummaryItems![i]
                                                        .cubicFaultDetailId
                                                  )}
                                                className={styles.selectField}
                                                readOnly={isReadonly}
                                              />
                                            </div>
                                          </div>
                                        ))}
                                    </>
                                  ) : (
                                    <div className={styles.emptyList}>
                                      There are no cubic components associated with this task.
                                    </div>
                                  )}
                                  {submitCount > 0 &&
                                    errors.asset &&
                                    errors.asset.assetCubicSummaryItems === 'one-required' && (
                                      <div className={styles.errorMessage}>
                                        At least one entry is required
                                      </div>
                                    )}
                                </div>
                              )}
                            />
                          </>
                        )}
                      </div>
                    )}
                  {(!isReadonly ||
                    (values &&
                      values.fitForService !== null &&
                      values.fitForService !== undefined)) && (
                    <div className={styles.inputFields}>
                      <div className={styles.row}>
                        <ToggleField
                          dataAddress={'fitForService'}
                          trueText={'OK for Service'}
                          falseText={'Unfit for Service'}
                          dataLabel={'Is the asset fit for service?'}
                          readOnly={isReadonly}
                        />
                      </div>
                      {values &&
                        values.fitForService !== null &&
                        values.fitForService !== undefined &&
                        (task.category.id === JobTaskCategory.Service ||
                          task.category.id === JobTaskCategory.PreMachineryInspection ||
                          (task.category.id === JobTaskCategory.MachineryInspection &&
                            task.checklistId)) && (
                          <div className={styles.row}>
                            {(task.category.id === JobTaskCategory.Service ||
                              task.category.id === JobTaskCategory.PreMachineryInspection) && (
                              <>
                                <ToggleField
                                  dataAddress={'roadTestDone'}
                                  dataLabel={'Road Test Done'}
                                  falseText={'No'}
                                  trueText={'Yes'}
                                  readOnly={isReadonly}
                                />
                                <ToggleField
                                  dataAddress={'brakeTestDone'}
                                  dataLabel={'Brake Test Done'}
                                  falseText={'No'}
                                  trueText={'Yes'}
                                  readOnly={isReadonly}
                                />
                              </>
                            )}
                            {task.checklistId && (
                              <ToggleField
                                dataAddress={'servicedAsPerChecklist'}
                                dataLabel={'Serviced As Per Checklist'}
                                falseText={'No'}
                                trueText={'Yes'}
                                readOnly={isReadonly}
                              />
                            )}
                          </div>
                        )}
                      {values &&
                        values.fitForService !== null &&
                        values.fitForService !== undefined &&
                        task.subcategoryId === DServiceSubcategoryId && (
                          <div className={styles.row}>
                            <ToggleField
                              dataAddress={'valveAdjustmentDone'}
                              dataLabel={'Valve Adjustment Done'}
                              falseText={'No'}
                              trueText={'Yes'}
                              readOnly={isReadonly}
                            />
                          </div>
                        )}
                    </div>
                  )}
                  {!isReadonly && (
                    <div className={cn(styles.buttonContainer, styles.submitButtonContainer)}>
                      {isSubmitting ? (
                        <Spinner show className={styles.loadingSpinner} />
                      ) : (
                        <Button
                          type="submit"
                          className={cn(
                            styles.submitButton,
                            Object.keys(errors).length && submitCount > 0 ? styles.error : ''
                          )}>
                          {Object.keys(errors).length && submitCount > 0
                            ? 'The form contains errors'
                            : values.fitForService
                            ? 'Complete Task'
                            : 'Save Changes'}
                        </Button>
                      )}
                    </div>
                  )}
                </div>
              </Form>
            );
          }}
        </Formik>
      ) : (
        <div className={styles.spinnerContainer}>
          <Spinner show className={styles.loadingSpinner} />
        </div>
      )}
    </div>
  );
});

export default Task;
