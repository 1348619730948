import 'react-select/dist/react-select.css';
import './SelectPageField.scss';
import Select, { OnChangeHandler } from 'react-select';
import { nonStrictEqual } from 'src/infrastructure/nonStrictEqual';
import { IFieldApi } from 'src/views/components/Page/forms/Field';
import { LabelledFormField } from 'src/views/components/forms';
import { IFieldMeta, IFieldData, ISelectMultiFieldDef } from 'src/views/definitionBuilders/types';
import { isDefined } from 'src/infrastructure/typeUtils';
import SelectSyncFieldBase from './fieldBases/SelectSyncFieldBase';
import deepEqual from 'src/infrastructure/deepEqual';

interface ISelectMultiPageFieldProps {
  fieldDef: ISelectMultiFieldDef;
  fieldMeta: IFieldMeta;
  // tslint:disable-next-line:no-any
  fieldData: IFieldData<Array<any>>;
  fieldApi: IFieldApi;
}

class SelectMultiPageField extends SelectSyncFieldBase<ISelectMultiPageFieldProps> {
  // tslint:disable-next-line:no-any
  shouldComponentUpdate(nextProps: any, nextState: any) {
    var shouldRender =
      !deepEqual(nextProps.fieldData, this.props.fieldData, { strict: true }) ||
      !deepEqual(nextProps.fieldDef, this.props.fieldData, { strict: true });
    return shouldRender;
  }

  // tslint:disable-next-line:no-any
  private readonly getCurrentItems = (): Array<any> => {
    const { fieldDef: def, fieldData: data } = this.props;

    // Any falsie value except zero is considered nothing
    if (!data.fieldValue && data.fieldValue !== 0) {
      return [];
    }

    // Be nice and accept single values not in an array - this is helpful for things like page filters
    const fieldValue = Array.isArray(data.fieldValue) ? data.fieldValue : [data.fieldValue];

    // If the field is "value only", attempt to find a corresponding option items
    if (def.useValueOnly) {
      return fieldValue
        .map(v => this.items().find(i => nonStrictEqual(i[def.valueKey], v)) || undefined)
        .filter(isDefined);
    }

    return fieldValue;
  };

  private readonly handleChange: OnChangeHandler = selectedOptions => {
    const { fieldDef: def, fieldApi } = this.props;
    if (Array.isArray(selectedOptions)) {
      const newValue =
        def.useValueOnly && selectedOptions.length
          ? selectedOptions.map(o => o[def.valueKey])
          : selectedOptions;
      fieldApi.setValue(newValue);
    } else {
      fieldApi.setValue(undefined);
    }
  };

  render() {
    const { fieldApi, fieldDef: def, fieldMeta: meta, fieldData: data } = this.props;
    const { error, touched } = fieldApi;
    const currentItems = this.getCurrentItems();
    const valueRenderer = def.useOptionRendererAsValueRenderer
      ? def.optionRenderer
      : def.valueRenderer;

    // As this field can have a value which is an object, then errors can be added to "lower" levels,
    // in which case `error` will be an object rather than a string
    const safeError = typeof error === 'string' ? error : undefined;
    const labelText = typeof def.label === 'function' ? def.label(data) : def.label;
    const tooltipText = typeof def.tooltip === 'function' ? def.tooltip(data) : def.tooltip;
    return (
      <LabelledFormField
        className="select-multi-page-field-component"
        readonly={meta.readonly}
        readonlyValue={
          def.formatReadonly
            ? def.formatReadonly(data)
            : currentItems.map(item => item[def.descriptionKey]).join(', ')
        }
        readonlyLinkTo={def.linkTo && currentItems.length ? def.linkTo(data) : undefined}
        mandatory={meta.mandatory}
        hideLabel={meta.hideLabel}
        labelValue={labelText}
        tooltipValue={tooltipText}
        noForm={meta.noForm}
        error={touched && safeError}>
        <Select
          className={!(touched && safeError) ? '' : 'is-invalid'}
          // disabled={disabled}
          autoFocus={meta.autoFocus}
          multi
          closeOnSelect={false}
          clearable={!meta.mandatory}
          options={this.items()}
          filterOptions={this.handleFilterOptions}
          valueKey={def.valueKey}
          labelKey={def.descriptionKey}
          value={currentItems}
          onChange={this.handleChange}
          matchProp="label"
          onBlur={this.handleBlur}
          valueRenderer={valueRenderer}
          optionRenderer={def.optionRenderer}
          placeholder={def.placeholder}
          menuContainerStyle={
            def.menuWidth && def.menuWidth === 'fitContent'
              ? { minWidth: '100%', width: 'fit-content' }
              : undefined
          }
        />
      </LabelledFormField>
    );
  }
}

export default SelectMultiPageField;
