import { DateTime, Duration } from 'luxon';

export function getEditingFormattedTimeString(
  externalValue: DateTime | Duration | undefined,
  timezone?: string,
  includeSeconds?: boolean
) {
  if (!externalValue) {
    return '';
  }
  const format = includeSeconds ? 'HH:mm:ss' : 'HH:mm';
  if (isDateTime(externalValue)) {
    // Times are edited in the timezone of the browser
    return externalValue.setZone(timezone || 'local').toFormat(format);
  }
  return externalValue.toFormat(format.toLowerCase());
}

export function parseEditingFormattedTimeString(
  originalValue: string | undefined,
  allowSeconds?: boolean
) {
  if (!originalValue) {
    return undefined;
  }

  // Use regexr.com to look at this regex if needed
  // Extracts out hours and minutes as groups
  // Support for "hh:mm", "h:mm", "hhmm", 'hmm', 'h', 'hh'
  const re = /^(\d{1,2})[:]?(\d{2})?[:]?(\d{2})?$/;
  const matches = re.exec(originalValue);
  if (!matches) {
    return Duration.invalid('Failed parsing');
  }

  const hours = Number.parseInt(matches[1], 10);
  const minutes = Number.parseInt(matches[2] || '0', 10);
  const seconds = allowSeconds ? Number.parseInt(matches[3] || '0', 10) : undefined;

  return Duration.fromObject({ hours, minutes, seconds });
}

export function parseEditingFormattedDurationString(originalValue: string | undefined) {
  if (!originalValue) {
    return undefined;
  }

  // Use regexr.com to look at this regex if needed
  // Extracts out hours and minutes as groups
  // Support for "hh:mm", "h:mm", "hhmm", 'hmm', 'h', 'hh'
  // If seconds are included they are ignored
  const re = /^(\d+)[:]?(\d{2})?[:]?(\d{2})?$/;
  const matches = re.exec(originalValue);
  if (!matches) {
    return Duration.invalid('Failed parsing');
  }

  const hours = Number.parseInt(matches[1], 10);
  const minutes = Number.parseInt(matches[2] || '0', 10);

  return Duration.fromObject({ hours, minutes });
}

function isDateTime(value: DateTime | Duration | undefined): value is DateTime {
  // Use isInDST field for detecting DateTimes
  return value ? 'toJSDate' in value : false;
}
