import { buildListPageApiSearchModelTypedQuery } from 'src/domain/modelBuilders/buildListPageApiSearchModel';
import { types, flow } from 'mobx-state-tree';
import { getAjax } from 'src/domain/services';

type RoleItem = People.Domain.Queries.Role.RoleItem;

export const RolesListModel = buildListPageApiSearchModelTypedQuery<
  Common.Queries.People.ListRoles.ListRolesQuery
>()('RolesListModel', d => d.ajax.people.role.loadRoles(d.query));

const RolesOptionsModel = types
  .model('RolesModel', {
    roles: types.array(types.frozen<RoleItem>()),
  })
  .actions(self => {
    const ajax = getAjax(self);

    const loadAllRoles = flow(function*() {
      self.roles = yield ajax.people.role.getRoles();
    });

    return {
      loadAllRoles,
    };
  });

export const RolesModel = types.compose(RolesOptionsModel, RolesListModel);
