import { IAjax } from 'src/infrastructure/ajax';
import { workshopUrls } from 'src/domain/services/apiUrls';

// tslint:disable-next-line:no-empty
const returnVoid = () => {};

export default function init(ajax: IAjax) {
  function listPartCategories(
    query: Partial<Workshop.Domain.Queries.PartCategory.GetPartCategories.GetPartCategoryListQuery>
  ) {
    return ajax
      .get(workshopUrls.partCategoryUrls.listPartCategories(query))
      .map(
        r =>
          r.response as Common.Dtos.ListResult<
            Workshop.Domain.Queries.PartCategory.PartCategoryItem
          >
      );
  }

  function listAllPartCategories() {
    return ajax
      .get(workshopUrls.partCategoryUrls.listAllPartCategories())
      .toPromise()
      .then<Workshop.Domain.Queries.PartCategory.PartCategoryItem[]>(r => r.response);
  }

  function checkForUniqueName(name: string) {
    return ajax
      .get(workshopUrls.partCategoryUrls.checkForUniqueName(name))
      .toPromise()
      .then<Common.Dtos.UniqueNameCheckResultDto>(r => r.response);
  }

  function createPartCategory(
    command: Workshop.Domain.Commands.Part.CreatePartCategory.CreatePartCategoryCommand
  ) {
    return ajax
      .post(workshopUrls.partCategoryUrls.createPartCategory(), command)
      .toPromise()
      .then<string>(r => r.response);
  }

  function getPartCategory(id: number) {
    return ajax
      .get(workshopUrls.partCategoryUrls.getPartCategory(id))
      .toPromise()
      .then<Workshop.Domain.Queries.PartCategory.PartCategoryItem>(r => r.response);
  }

  function updatePartCategory(
    command: Workshop.Domain.Commands.Part.UpdatePartCategory.UpdatePartCategoryCommand
  ) {
    return ajax
      .put(workshopUrls.partCategoryUrls.updatePartCategory(command.id), command)
      .toPromise()
      .then(r => returnVoid());
  }

  function canDeletePartCategory(id: number) {
    return ajax
      .get(workshopUrls.partCategoryUrls.canDeletePartCategory(id))
      .toPromise()
      .then<Workshop.Domain.Queries.GetCanPartCategoryBeDeleted.GetCanPartCategoryBeDeletedResult>(
        r => r.response
      );
  }

  function deletePartCategory(id: number) {
    return ajax
      .httpDelete(workshopUrls.partCategoryUrls.deletePartCategory(id))
      .toPromise()
      .then(r => returnVoid());
  }

  function updatePartCategorySetActive(id: number) {
    return ajax
      .put(workshopUrls.partCategoryUrls.updatePartCategorySetActive(id))
      .toPromise()
      .then(r => returnVoid());
  }

  function updatePartCategorySetInactive(id: number) {
    return ajax
      .put(workshopUrls.partCategoryUrls.updatePartCategorySetInactive(id))
      .toPromise()
      .then(r => returnVoid());
  }

  return {
    partCategories: {
      listPartCategories,
      checkForUniqueName,
      createPartCategory,
      getPartCategory,
      updatePartCategory,
      canDeletePartCategory,
      deletePartCategory,
      updatePartCategorySetActive,
      updatePartCategorySetInactive,
      listAllPartCategories,
    },
  };
}
