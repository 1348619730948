import { IAjax } from 'src/infrastructure/ajax';
import { kioskUrls } from 'src/domain/services/apiUrls';

type ListPartsForDropdownQuery = Workshop.Domain.Queries.Parts.ListPartsForDropdown.ListPartsForDropdownQuery;
type PartListItemForDropdown = Workshop.Domain.Queries.Parts.ListPartsForDropdown.PartListItemForDropdown;

export default function init(ajax: IAjax) {
  function listPartsForDropdown(query: Partial<ListPartsForDropdownQuery>) {
    return ajax
      .get(kioskUrls.workshopUrls.parts.listPartsForDropdown(query))
      .map(r => r.response as Common.Dtos.ListResult<PartListItemForDropdown>);
  }

  return {
    parts: {
      listPartsForDropdown,
    },
  };
}
