import { DateTime } from 'luxon';
import { IAjax } from 'src/infrastructure/ajax';
import { jobUrls } from 'src/domain/services/apiUrls';

// tslint:disable-next-line:no-empty
const returnVoid = () => {};

export default function init(ajax: IAjax) {
  function listJobs(query: Partial<Workshop.Domain.Queries.Job.ListJobs.ListJobsQuery>) {
    return ajax
      .get(jobUrls.listJobs(query))
      .map(r => r.response as Common.Dtos.ListResult<Workshop.Domain.Queries.Job.JobSummaryDto>);
  }

  function getJobsForAsset(assetId: string) {
    return ajax
      .get(jobUrls.getJobsForAsset(assetId))
      .toPromise()
      .then<Workshop.Domain.Queries.Job.GetJobsForAsset.JobsForAsset>(r => {
        return r.response;
      });
  }

  function listScheduledJobs(start: DateTime, end: DateTime, depotId: number) {
    return ajax
      .get(jobUrls.listScheduledJobs(start, end, depotId))
      .toPromise()
      .then<Workshop.Domain.Queries.Job.GetScheduledJobs.ScheduledJob[]>(r => r.response);
  }

  function listScheduledMachineryJobs(start: DateTime, end: DateTime, depotId: number) {
    return ajax
      .get(jobUrls.listScheduledMachineryJobs(start, end, depotId))
      .toPromise()
      .then<Workshop.Domain.Queries.Job.GetScheduledJobs.ScheduledJob[]>(r => r.response);
  }

  function createJob(command: Workshop.Domain.Commands.Job.CreateJobCommand): Promise<string> {
    return ajax
      .post(jobUrls.createJob(), command)
      .toPromise()
      .then<string>(r => r.response);
  }

  function loadPrintJobSheet(jobId: string) {
    return ajax
      .get(jobUrls.loadPrintJobSheet(jobId))
      .toPromise()
      .then<Workshop.Domain.Queries.Job.PrintJobQuery.JobItem>(r => {
        return r.response;
      });
  }

  function loadDailyRunSheet(date: DateTime, depotId: number) {
    return ajax
      .get(jobUrls.loadDailyRunSheet(date, depotId))
      .toPromise()
      .then<Workshop.Domain.Queries.GetDailyRunSheet.DailyRunSheetItem>(r => r.response);
  }

  function loadJob(id: string) {
    return ajax
      .get(jobUrls.loadJob(id))
      .toPromise()
      .then<Workshop.Domain.Queries.Job.JobDetailsDto>(r => r.response);
  }

  function updateJob(command: Workshop.Domain.Commands.Job.UpdateJobCommand) {
    return ajax
      .put(jobUrls.updateJob(command.jobId), command)
      .toPromise()
      .then(r => returnVoid());
  }

  function generateJobWorkOrderReport(jobId: string, internalUse: boolean) {
    return ajax
      .getFile(jobUrls.generateJobWorkOrderReport(jobId, internalUse))
      .toPromise()
      .then<Blob>(r => r.response);
  }

  function markAsInvoiced(id: string) {
    return ajax
      .post(jobUrls.markAsInvoiced(id))
      .toPromise()
      .then(r => returnVoid());
  }

  function loadBusUsages(id: string) {
    return ajax
      .get(jobUrls.loadBusUsages(id))
      .toPromise()
      .then<Workshop.Domain.Queries.GetJobsProgressUsage.JobBusUsageDto[]>(r => r.response);
  }

  function updateBusUsages(command: Workshop.Domain.Commands.Job.UpdateWorkshopJobProgressCommand) {
    return ajax
      .put(jobUrls.updateBusUsages(command), command)
      .toPromise()
      .then(r => returnVoid());
  }

  function getPreviousAndNextProgresses(jobId: string) {
    return ajax
      .get(jobUrls.getPreviousAndNextProgresses(jobId))
      .toPromise()
      .then<Common.Queries.GetPreviousAndNextJobProgress.PreviousAndNextJobProgressResult>(
        r => r.response
      );
  }

  return {
    job: {
      listJobs,
      listScheduledJobs,
      listScheduledMachineryJobs,
      createJob,
      getJobsForAsset,
      loadPrintJobSheet,
      loadDailyRunSheet,
      loadJob,
      updateJob,
      generateJobWorkOrderReport,
      markAsInvoiced,
      loadBusUsages,
      updateBusUsages,
      getPreviousAndNextProgresses,
    },
  };
}
