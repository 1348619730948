import { IRootStoreModel } from 'src/domain/entities/RootStoreModel';
import { UiRoute } from 'src/domain/services/buildUiRoutes';
import { UiRouteName } from 'src/domain/baseTypes';

export default function buildUiRoutes(store: IRootStoreModel) {
  const shiftCreate = new UiRoute(
    UiRouteName.urbanShiftCreate,
    '/operations/shifts/create',
    () => 'Create Shift',
    false
  );

  const shiftManage = new UiRoute(
    UiRouteName.urbanShiftManage,
    '/operations/shifts/:id',
    () => 'Manage Shift',
    false
  );

  const shiftList = new UiRoute(
    UiRouteName.urbanShiftList,
    '/operations/shifts',
    () => 'Shifts',
    false,
    true,
    [shiftCreate, shiftManage]
  );

  return {
    [UiRouteName.urbanShiftList]: shiftList,
    [UiRouteName.urbanShiftManage]: shiftManage,
    [UiRouteName.urbanShiftCreate]: shiftCreate,
  };
}
