import './FatigueDetailBreakDown.scss';
import { DateTime, Duration } from 'luxon';
import { fatigueDateTimeFormat } from './FatigueDetailsRuleCard';

type RestPeriod = Operations.Domain.Queries.GetFatigueDetails.RestPeriod;

interface IFatigueDetailBreakDownProps {
  breakdown: Operations.Domain.Queries.GetFatigueDetails.GetFatigueDetailsQueryResponse.FatigueRuleDetailedBreakDown[];
}

const FatigueDetailBreakDown: React.FC<IFatigueDetailBreakDownProps> = (
  props: IFatigueDetailBreakDownProps
) => {
  const { breakdown } = props;
  return (
    <div className="fatigue-detail-breakdown">
      {breakdown.map((section, idx) => (
        <div key={idx}>
          <h6>{section.label}</h6>
          <ul className="list-unstyled">
            {section.restPeriods.map((restPeriod: RestPeriod, rpIdx: number) => {
              const startDate = DateTime.fromISO(restPeriod.start);
              const endDate = DateTime.fromISO(restPeriod.end);
              const duration = Duration.fromISO(restPeriod.durationIso);
              return (
                <li key={section.label + rpIdx}>
                  <span>{startDate.toFormat(fatigueDateTimeFormat)} - </span>
                  <span>{endDate.toFormat(fatigueDateTimeFormat)} </span>
                  <span>({duration.toFormat('hh:mm')} hrs)</span>
                </li>
              );
            })}
          </ul>
        </div>
      ))}
    </div>
  );
};

export default FatigueDetailBreakDown;
