import { IAjax } from 'src/infrastructure/ajax';
import { activityLogUrls } from 'src/domain/services/apiUrls';

export default function init(ajax: IAjax) {
  function getActivityLogForDefect(defectId: string) {
    return ajax
      .get(activityLogUrls.getActivityLogForDefect(defectId))
      .toPromise()
      .then<Workshop.Domain.Queries.ActivityLog.ActivityLogTransaction[]>(r => {
        return r.response;
      });
  }

  function getActivityLogForPurchaseOrder(purchaseOrderId: string) {
    return ajax
      .get(activityLogUrls.getActivityLogForPurchaseOrder(purchaseOrderId))
      .toPromise()
      .then<Workshop.Domain.Queries.ActivityLog.ActivityLogTransaction[]>(r => {
        return r.response;
      });
  }

  function getActivityLogForJobTask(jobTaskId: string) {
    return ajax
      .get(activityLogUrls.getActivityLogForJobTask(jobTaskId))
      .toPromise()
      .then<Workshop.Domain.Queries.ActivityLog.ActivityLogTransaction[]>(r => {
        return r.response;
      });
  }

  return {
    activityLog: {
      getActivityLogForDefect,
      getActivityLogForPurchaseOrder,
      getActivityLogForJobTask,
    },
  };
}
