import { types, flow, getRoot } from 'mobx-state-tree';
import { getAjax } from 'src/domain/services/storeEnvironment';
import { DateTime } from 'luxon';
import { NotificationType } from 'src/domain';
import { IKioskRootStoreModel } from '../../KioskRootStoreModel';
type TimesheetItemForDriver = Common.Dtos.TimesheetItemForDriver;
type PrefilledTimesheetItemForDriver = Common.Dtos.PrefilledTimesheetItemForDriver;
type ListTimesheetItem = People.Domain.Queries.ListTimesheetsForDriver.ListTimesheetItem;
type CreateTimesheetForDriverCommand = People.Domain.Commands.Timesheets.CreateTimesheetForDriver.CreateTimesheetForDriverCommand;
type GetTimesheetReminderQuery = People.Domain.Queries.GetTimesheetReminder.GetTimesheetReminderQuery;

export const TimesheetModel = types
  .model('TimesheetModel', {
    timesheet: types.maybe(types.frozen<TimesheetItemForDriver>()),
    prefilledTimesheet: types.maybe(types.frozen<PrefilledTimesheetItemForDriver>()),
    timesheets: types.array(types.frozen<ListTimesheetItem>()),
    timesheetWeekStart: types.maybe(types.frozen<DateTime>()),
    timesheetForPrinting: types.maybe(types.frozen<Blob>()),
    showReminder: types.maybe(types.frozen<boolean>()),
  })
  .actions(self => {
    const ajax = getAjax(self);
    const root = getRoot(self) as IKioskRootStoreModel;

    const getTimesheetForDriver = flow(function*(date: DateTime) {
      const rangeStart = date.plus({ weeks: -6 });
      const rangeEnd = date.plus({ weeks: 6 });

      self.timesheets = yield ajax.kiosk.timesheets.getTimesheetsForDriver(rangeStart, rangeEnd);
      const timesheet = self.timesheets.find(
        x => DateTime.fromISO(x.weekStart).toISODate() === date.toISODate()
      );
      if (!timesheet) {
        self.timesheet = undefined;
        self.prefilledTimesheet = yield ajax.kiosk.timesheets.getPrefilledTimesheetForDriver(date);
      } else {
        self.timesheet = yield ajax.kiosk.timesheets.getTimesheetForDriver(timesheet.id);
        self.prefilledTimesheet = undefined;
      }
      self.timesheetWeekStart = date;
    });

    const createTimesheetForDriver = flow(function*(command: CreateTimesheetForDriverCommand) {
      yield ajax.kiosk.timesheets.createTimesheetForDriver(command);
      yield getTimesheetForDriver(DateTime.fromISO(command.weekStart));
      root.notifications.addNotification('Timesheet successfully submitted', {
        type: NotificationType.success,
      });
    });

    const refreshTimesheetForDriver = flow(function*() {
      yield ajax.kiosk.timesheets.refreshTimesheetForDriver(self.timesheet!.id);
      yield getTimesheetForDriver(DateTime.fromISO(self.timesheet!.weekStart));
      root.notifications.addNotification('Timesheet successfully refreshed', {
        type: NotificationType.success,
      });
    });

    const updateTimesheetForDriver = flow(function*(driverNotes: string) {
      yield ajax.kiosk.timesheets.updateTimesheetForDriver({
        id: self.timesheet!.id,
        staffMemberNotes: driverNotes,
      });
      yield getTimesheetForDriver(DateTime.fromISO(self.timesheet!.weekStart));
      root.notifications.addNotification('Timesheet successfully updated', {
        type: NotificationType.success,
      });
    });

    const loadTimesheetForDriverForPrinting = flow(function*() {
      self.timesheetForPrinting = yield ajax.kiosk.timesheets.printTimesheetForDriver(
        self.timesheet!.id
      );
    });

    const getTimesheetReminder = flow(function*(query: Partial<GetTimesheetReminderQuery>) {
      self.showReminder = false;
      self.showReminder = yield ajax.kiosk.timesheets.getTimesheetReminder(query);
    });

    const logTimesheetReminderWasDisplayed = flow(function*(date: DateTime) {
      yield ajax.kiosk.timesheets.logTimesheetReminderWasDisplayed(date);
    });

    const logTimesheetReminderWasViewed = flow(function*(date: DateTime) {
      yield ajax.kiosk.timesheets.logTimesheetReminderWasViewed(date);
    });

    return {
      getTimesheetForDriver,
      createTimesheetForDriver,
      refreshTimesheetForDriver,
      updateTimesheetForDriver,
      loadTimesheetForDriverForPrinting,
      getTimesheetReminder,
      logTimesheetReminderWasDisplayed,
      logTimesheetReminderWasViewed,
    };
  });
