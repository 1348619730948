import { Component } from 'react';
import {
  IActionMeta,
  IActionData,
  ActionType,
  IRemoveArrayItemActionButtonDef,
  IHasChangeState,
} from 'src/views/definitionBuilders/types';
import ActionButton from './ActionButton';
import { TrashIcon, UndoIcon } from 'src/images/icons';
import { ChangeState } from 'src/api/enums';

interface IRemoveArrayItemActionButtonProps {
  actionDef: IRemoveArrayItemActionButtonDef;
  actionMeta: IActionMeta;
  actionData: IActionData;
  renderAsDropdownItem?: boolean;
}

class RemoveArrayItemActionButton extends Component<IRemoveArrayItemActionButtonProps> {
  private readonly handleClick = () => {
    const { actionMeta: meta } = this.props;
    if (!meta.removeArrayItem) {
      throw new Error('Remove array item function is not set');
    }
    meta.removeArrayItem();

    // Remove does not happen immediately, so use setTimeout then reread directly from props to get the latest values
    setTimeout(() => {
      const { actionDef, actionData } = this.props;
      actionDef.postClick && actionDef.postClick(actionData);
    }, 0);
  };

  private readonly isItemDeleted = () => {
    const { actionData } = this.props;
    return ((actionData.parentValue || {}) as IHasChangeState).changeState === ChangeState.Deleted;
  };

  private readonly getIcon = () => {
    const { actionDef } = this.props;
    return this.isItemDeleted() ? (
      <UndoIcon fixedWidth />
    ) : (
      actionDef.icon || <TrashIcon fixedWidth />
    );
  };

  private readonly getLabel = () => {
    const { actionDef } = this.props;
    return this.isItemDeleted() ? `${actionDef.label} (undo)` : actionDef.label;
  };

  render() {
    const { actionDef, actionMeta: meta, actionData, renderAsDropdownItem } = this.props;
    const { postClick, ...remainingActionDef } = actionDef;
    return (
      <ActionButton
        actionDef={{
          ...remainingActionDef,
          actionType: ActionType.actionButton,
          label: this.getLabel(),
          icon: this.getIcon(),
          onClick: this.handleClick,
        }}
        actionMeta={meta}
        actionData={actionData}
        renderAsDropdownItem={renderAsDropdownItem}
      />
    );
  }
}

export default RemoveArrayItemActionButton;
