import { DateTime } from 'luxon';
import { ChangeState } from 'src/api/enums';
import { FatigueBreaks } from 'src/domain';
import { formatDateMed, getOverlappingDates } from 'src/domain/dateHelper';
import { FieldInfoTooltip } from 'src/views/components/FieldInfoTooltip';
import { getSubmitCloseModalActionGroupDef } from 'src/views/definitionBuilders/common';
import {
  FieldDefs,
  FieldType,
  ModalDefBuilder,
  PaneType,
} from 'src/views/definitionBuilders/types';

type JobRouteItem = Operations.Domain.Queries.ViewJob.JobRouteItem;
type JobScheduledBreakItem = Operations.Domain.Queries.GetJobScheduledBreaks.JobScheduledBreakItem;

export interface FatigueBreakItem {
  breakStart: DateTime;
  breakEnd: DateTime;
  changeState: ChangeState;
}
export default function getGenerateFatigueBreaksModalDef(
  dataAddr: string,
  jobRoutes?: JobRouteItem[]
): ModalDefBuilder {
  return modalDefApi => {
    let dates: DateTime[] = [];

    const getDates = (startDate: DateTime, endDate: DateTime) => {
      let currentDate = startDate;
      while (currentDate <= endDate) {
        dates.push(currentDate);
        currentDate = currentDate.plus({ days: 1 });
      }
      return dates;
    };
    const firstRouteDate = jobRoutes && DateTime.fromISO(jobRoutes[0].date);
    const lastRouteDate = jobRoutes && DateTime.fromISO(jobRoutes[jobRoutes.length - 1].date);

    const allDates = getDates(firstRouteDate!, lastRouteDate!);

    const getBreakOptions = (index: number) => {
      return [
        {
          description: 'Full Day',
          value: FatigueBreaks.FullDay,
        },
        {
          description: '5 Hours Rest',
          value: FatigueBreaks.FiveHoursRest,
        },
        {
          description: '10 Hour Overnight Rest',
          value: FatigueBreaks.NightRest,
          isDisabled: allDates.length === index + 1,
        },
      ];
    };

    const fatigueToggleButtonField = (label: string, index: number) =>
      ({
        fieldType: FieldType.toggleButtonField,
        label: formatDateMed(label),
        dataAddr: index.toString(),
        optionItems: getBreakOptions(index),
        descriptionKey: 'description',
        valueKey: 'value',
        useValueOnly: true,
        validate: d => {
          const selected = Object.entries(d.paneValue).filter(d => d[0] !== 'jobRoutes');
          const undefinedValues = selected.map(e => e[1] === undefined).filter(e => e === false);

          if (allDates.length > 0 && (selected.length === 0 || !undefinedValues.length)) {
            return 'Must have selected option for at least one';
          }

          return undefined;
        },
      } as FieldDefs);

    return {
      asForm: true,
      title: () => (
        <h1 className="scheduled-fatigue-breaks-title">
          Scheduled Fatigue Breaks
          <FieldInfoTooltip>
            <p>
              <strong>Predefined break periods</strong>
              <br />
              Full Day: Midnight to Midnight
              <br />
              5 Hours Rest: Midnight to 05:00
              <br />
              Night Rest: 22:00 to 08:00
            </p>
          </FieldInfoTooltip>
        </h1>
      ),
      explicitData: {
        jobRoutes: jobRoutes,
      },
      dataAddr: 'fatigueBreaks',
      panels: [
        {
          panes: [
            {
              paneType: PaneType.formFieldsPane,
              fields: [
                {
                  fieldType: FieldType.customField,
                  label: '',
                  dataAddr: 'fake',
                  render: () => (
                    <>
                      <span>Please indicate the breaks to be scheduled each day:</span>
                      <br />
                    </>
                  ),
                },
                ...allDates.map((date, index) => fatigueToggleButtonField(date.toString(), index)),
              ],
            },
          ],
        },
      ],
      onFormSubmit: values => {
        const newValues = allDates.map((date, index) => {
          let breakStart;
          let breakEnd;

          const selectedBreak = values[index];
          const selectedDate = date;

          switch (selectedBreak) {
            case FatigueBreaks.FullDay:
              breakStart = selectedDate;
              breakEnd = selectedDate.plus({ days: 1 });
              break;
            case FatigueBreaks.FiveHoursRest:
              breakStart = selectedDate;
              breakEnd = selectedDate.plus({ hours: 5 });
              break;
            case FatigueBreaks.NightRest:
              breakStart = selectedDate.plus({ hours: 22 });
              breakEnd = selectedDate.plus({ days: 1, hours: 8 });
              break;
            default:
              return {};
          }

          return {
            breakStart,
            breakEnd,
            changeState: ChangeState.Added,
          };
        });

        const filteredNewValues = newValues.filter(value => Object.keys(value).length !== 0);
        let newBreaks = [...filteredNewValues] as FatigueBreakItem[];
        const overlappingDates = getOverlappingDates(newBreaks);

        overlappingDates.map(date => {
          const index = newBreaks.findIndex(breakItem => breakItem.breakStart === date.breakStart);

          if (index !== -1) {
            newBreaks[index - 1].breakEnd = date.breakEnd;
            newBreaks.splice(index, 1);
          }

          return newBreaks;
        });

        const currentBreaks = modalDefApi.parentFormApi.getValue(dataAddr);
        const filteredBreaks = currentBreaks.filter(
          (b: JobScheduledBreakItem) => b.changeState !== ChangeState.Deleted
        );

        modalDefApi.parentFormApi.setValue(dataAddr, newBreaks.concat(filteredBreaks));
        modalDefApi.parentFormApi.validate(dataAddr);
        return Promise.resolve();
      },

      secondaryActions: [getSubmitCloseModalActionGroupDef('Ok')],
    };
  };
}
