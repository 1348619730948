import { DateTime } from 'luxon';
import { AlertCard } from '../Alerts/AlertCard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStickyNote } from '@fortawesome/free-regular-svg-icons';
import React from 'react';
import styles from './Notes.module.scss';

type DriverNotes = People.Domain.Queries.ListNotesForDriver.DriverNotes;

interface INotesProps {
  notes: DriverNotes[];
  markNotesReadForDriverOnDayHandler: (date: DateTime) => Promise<void>;
  date: DateTime;
}

export const formatMessageText = (notes: DriverNotes[]) => {
  if (notes.length === 0) {
    return '';
  }

  return (
    <>
      {notes.map((noteGroup, g) => (
        <>
          {g > 0 && <hr />}
          <div className={styles.addedBy}>Added by {noteGroup.createdBy}</div>
          <div className={styles.noteContainer} key={g}>
            {noteGroup.notes.map((note, n) => {
              return (
                <div className={styles.content} key={n}>
                  {n > 0 && <hr />}
                  <p>{note.content}</p>
                </div>
              );
            })}
          </div>
        </>
      ))}
    </>
  );
};

const Notes: React.FC<INotesProps> = ({ notes, markNotesReadForDriverOnDayHandler, date }) => {
  return (
    <>
      <AlertCard
        showAlert={notes.length > 0}
        heading="Driver Notes"
        message={formatMessageText(notes)}
        link={''}
        linkText=""
        action={() => markNotesReadForDriverOnDayHandler(date)}
        actionText="Mark All As Read"
        icon={<FontAwesomeIcon icon={faStickyNote} />}
        iconColor="#f3b200"
      />
    </>
  );
};

export default Notes;
