import { types } from 'mobx-state-tree';
import { ListReportableEventsModel } from './ListReportableEventsModel';
import { SingleReportableEventModel } from './SingleReportableEventModel';

export const ReportableEventModel = types.model('ReportableEventModel', {
  list: types.optional(ListReportableEventsModel, {}),
  item: types.optional(SingleReportableEventModel, {}),
});
type ModelType = typeof ReportableEventModel.Type;
export interface IReportableEventModel extends ModelType {}
