import {
  ActionDefs,
  ActionType,
  PaneType,
  ShellModalSize,
} from 'src/views/definitionBuilders/types';
import { PauseIcon, PlusIcon, TrashIcon } from 'src/images/icons';
import { getSubmitCloseModalActionGroupDef } from 'src/views/definitionBuilders/common';

type AggregateUsageDto = Common.Dtos.AggregateUsageDto;

export interface ISetInactiveCommand {
  id: string;
}

export interface ISetActiveCommand {
  id: string;
}

export const getActivationAndDeletionActionButtons = (
  aggregateId: string,
  aggregateType: string,
  aggregateIsActive: boolean | undefined,
  markActiveHandler: (command: ISetActiveCommand) => Promise<void>,
  markInactiveHandler: (command: ISetInactiveCommand) => Promise<void>,
  deleteHandler: (aggregateId: string) => Promise<void>,
  aggregateUsage: AggregateUsageDto | undefined,
  loadAggregate: (id: string) => Promise<void>
): Array<ActionDefs> => {
  return !!aggregateUsage
    ? [
        {
          actionType: ActionType.modalActionButton,
          modalSize: ShellModalSize.oneQuarter,
          label: 'Mark as Inactive',
          hidden: !aggregateIsActive || !aggregateUsage.canBeMadeInactive,
          icon: <PauseIcon />,
          modalDef: _ => ({
            title: 'Mark as Inactive',
            asForm: true,
            panels: [
              {
                panes: [
                  {
                    paneType: PaneType.customPane,
                    render: () => (
                      <div>
                        <p>
                          Marking this {aggregateType} inactive will mean it won't be available when
                          filtering or creating something new.
                        </p>
                        <p>{aggregateType}s are able to be reactivated once inactive.</p>
                        <p>Please click Mark as Inactive below to confirm.</p>
                      </div>
                    ),
                  },
                ],
              },
            ],
            secondaryActions: [getSubmitCloseModalActionGroupDef('Mark as Inactive')],
            onFormSubmit: _ =>
              markInactiveHandler({ id: aggregateId }).then(_ => loadAggregate(aggregateId)),
          }),
        },
        {
          actionType: ActionType.modalActionButton,
          modalSize: ShellModalSize.oneQuarter,
          label: 'Mark as Active',
          icon: <PlusIcon />,
          hidden: aggregateIsActive,
          modalDef: _ => ({
            title: 'Mark as Active',
            asForm: true,
            panels: [
              {
                panes: [
                  {
                    paneType: PaneType.customPane,
                    render: () => (
                      <div>
                        <p>
                          Reactivating this {aggregateType} will include it in all lists, filters,
                          and options where {aggregateType}s appear.
                        </p>

                        <p>Please click Mark as Active below to confirm</p>
                      </div>
                    ),
                  },
                ],
              },
            ],
            secondaryActions: [getSubmitCloseModalActionGroupDef('Mark as Active')],
            onFormSubmit: _ =>
              markActiveHandler({ id: aggregateId }).then(_ => loadAggregate(aggregateId)),
          }),
        },
        {
          actionType: ActionType.modalActionButton,
          modalSize: ShellModalSize.oneQuarter,
          label: `Delete ${aggregateType}`,
          icon: <TrashIcon />,
          hidden: !aggregateUsage.canBeDeleted,
          modalDef: _ => ({
            title: `Delete ${aggregateType}`,
            asForm: true,
            panels: [
              {
                panes: [
                  {
                    paneType: PaneType.customPane,
                    render: () => (
                      <div>
                        <p>You are about to permanently delete this {aggregateType}.</p>
                        <p>Click Delete below to confirm.</p>
                      </div>
                    ),
                  },
                ],
              },
            ],
            secondaryActions: [getSubmitCloseModalActionGroupDef(`Delete`)],
            onFormSubmit: _ => deleteHandler(aggregateId),
          }),
        },
      ]
    : [];
};
