import { types, flow } from 'mobx-state-tree';
import { getAjax } from 'src/domain/services';
import { partsUrls } from 'src/domain/services/apiUrls';

type ChecklistItem = Workshop.Domain.Queries.Parts.GetChecklistsForPart.ChecklistItem;

export const PartChecklistModel = types
  .model('PartChecklistModel', {
    checklists: types.array(types.frozen<ChecklistItem>()),
  })
  .actions(self => {
    const ajax = getAjax(self);

    const loadChecklists = flow(function*(partId: string) {
      self.checklists = yield ajax.raw
        .get(partsUrls.loadChecklists(partId))
        .toPromise()
        .then<ChecklistItem[]>(r => {
          return r.response;
        });
    });

    return {
      loadChecklists,
    };
  });
