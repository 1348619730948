import styles from './JobSchedulePopover.module.scss';

import { DateTime, Interval } from 'luxon';
import { IScheduledMachineryJob } from 'src/domain/entities/workshop/job/ScheduledMachineryJobsModel';
import { IScheduledJob } from 'src/domain/entities/workshop/job/ScheduledJobsModel';
import { Link } from 'react-router-dom';
import { DateTimeFormatSettings } from 'src/views/components/DateTimeFormat/DateTimeFormat';

interface IJobSchedulePopoverProps {
  job: IScheduledJob | IScheduledMachineryJob;
}

const JobInterval: React.FC<{ interval: Interval }> = ({ interval: i }) => {
  const sameDay = i.start.startOf('day').equals(i.end.startOf('day'));
  const format: typeof DateTimeFormatSettings = {
    month: sameDay ? undefined : 'short',
    day: sameDay ? undefined : 'numeric',
    hour: '2-digit',
    minute: '2-digit',
  };
  return (
    <span>
      {i.start.toLocaleString(format)} - {i.end.toLocaleString(format)}
    </span>
  );
};

const JobSchedulePopover: React.FC<IJobSchedulePopoverProps> = ({ job }) => (
  <>
    <strong>
      Job <Link to={`/workshop/jobs/${job.jobId}`}>{job.jobNumber}</Link> for Asset{' '}
      <Link to={`/workshop/assets/${job.assetId}`}>{job.assetName}</Link>
    </strong>
    <div>
      <JobInterval interval={job.jobInterval} />
    </div>
    <strong>{job.assetRemoteLocationCode || null}</strong>
    <section className={styles.tasks}>
      <strong>Tasks:</strong>
      <ul>
        {job.tasks.map(t => (
          <li key={t.id}>
            <div className={styles.description}>
              <Link to={`/workshop/tasks/${t.id}`}>{t.jobTaskNumber}</Link> <i>{t.description}</i>
            </div>
            <div className={styles.category}>
              <span>
                [{t.category.description}
                {t.category.description !== t.subcategory.description
                  ? ` - ${t.subcategory.description}`
                  : null}
                ]
              </span>
            </div>
            {t.staff.length
              ? t.staff.map(s => (
                  <div key={`${t.id}_${s.name}`}>
                    <i>
                      {s.day
                        ? `[${DateTime.fromISO(s.day!).toLocaleString(DateTime.DATE_MED)}] ${
                            s.name
                          }`
                        : s.name}
                    </i>
                  </div>
                ))
              : null}
          </li>
        ))}
      </ul>
    </section>
  </>
);

export default JobSchedulePopover;
