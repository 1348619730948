import { inject as mobxInject, IWrappedComponent } from 'mobx-react';
import { IReactComponent } from 'mobx-react/dist/types/IReactComponent';
import { IStoresToProps } from 'mobx-react/dist/types/IStoresToProps';
import { Minus } from './minusTypeGenerator';

// Intended differences from regular inject
// 1. Make context type optional
// 2. Return a react component using a props interface that is only the non-bound props, rather than all props,
//    so that any props set from the injection function are no longer required when using the resulting component
function typedInject<S, E, I = E, C = {}, X = Minus<I, E>>(
  fn: IStoresToProps<S, X, E, C>
): <T extends IReactComponent>(
  target: T
) => IReactComponent<X> & IWrappedComponent<IReactComponent<X>> {
  return mobxInject<S, X, E, C>(fn) as <T extends IReactComponent>(
    target: T
  ) => IReactComponent<X> & IWrappedComponent<IReactComponent<X>>;
}

// We always want the same Stores interface in our app, so this is a generic builder
export function injectBuilder<TStores>() {
  return function<
    TInjected,
    TComponent = TInjected,
    TContext = {},
    TStoresP = TStores,
    X = Minus<TComponent, TInjected>
  >(fn: IStoresToProps<TStoresP, X, TInjected, TContext>) {
    return typedInject<TStoresP, TInjected, TComponent, TContext, X>(fn);
  };
}

export default typedInject;
