import { types, flow, getRoot } from 'mobx-state-tree';
import { getAjax } from 'src/domain/services/storeEnvironment';
import { IRootStoreModel } from 'src/domain/entities/RootStoreModel';

type CctvRequestItem = Operations.Domain.Queries.ViewCctvRequest.CctvRequestItem;

export const CctvRequestItemModel = types
  .model('CctvRequestItemModel', {
    cctvRequest: types.maybe(types.frozen<CctvRequestItem>()),
  })
  .actions(self => {
    const ajax = getAjax(self);
    const root = getRoot(self) as IRootStoreModel;

    const loadCctvRequest = flow(function*(cctvRequestId: string) {
      self.cctvRequest = yield ajax.raw
        .get(`/api/compliance/cctv-requests/${cctvRequestId}`)
        .toPromise()
        .then(r => r.response);
    });

    const createCctvRequest = flow(function*(
      command: Operations.Domain.Commands.CctvRequest.CreateCctvRequest.CreateCctvRequestCommand
    ) {
      const cctvRequestId = yield ajax.raw
        .post(`/api/compliance/cctv-requests/create`, command)
        .toPromise()
        .then(r => r.response);
      root.history.push(`/compliance/cctv-requests/${cctvRequestId}`);
    });

    const updateCctvRequest = flow(function*(
      command: Operations.Domain.Commands.CctvRequest.UpdateCctvRequest.UpdateCctvRequestCommand
    ) {
      yield ajax.raw.put(`/api/compliance/cctv-requests/${command.id}`, command).toPromise();
      yield loadCctvRequest(command.id);
    });

    const closeCctvRequest = flow(function*(
      command: Operations.Domain.Commands.CctvRequest.CloseCctvRequest.CloseCctvRequestCommand
    ) {
      yield ajax.raw
        .put(`/api/compliance/cctv-requests/${command.cctvRequestId}/close`, command)
        .toPromise();
      yield loadCctvRequest(command.cctvRequestId);
    });

    return {
      loadCctvRequest,
      createCctvRequest,
      updateCctvRequest,
      closeCctvRequest,
    };
  });
