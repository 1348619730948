import { ENABLE_DRAFT_PDF_WATERMARK } from 'src/appSettings';
import './DraftWatermark.scss';

interface IDraftWatermarkProps {
  shouldBeVisible: boolean;
  className?: string;
}

const DraftWatermark: React.FC<IDraftWatermarkProps> = ({ shouldBeVisible, className }) => {
  return shouldBeVisible && ENABLE_DRAFT_PDF_WATERMARK ? (
    <div className={className}>
      <div className="draft-watermark-component">
        <div className="title">draft</div>
        <div className="subtitle">Please print your paperwork on the day of the job</div>
      </div>
    </div>
  ) : null;
};

export default DraftWatermark;
