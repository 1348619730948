import { useEffect } from 'react';
import { PagePrimarySize, PaneType, IPageDef } from 'src/views/definitionBuilders/types';
import TaskCardItem, { toActionLinkDef } from 'src/views/components/TaskCard/TaskCardItem';
import Page from 'src/views/components/Page';
import DashboardTileCollection from 'src/views/components/Dashboard/DashboardTileCollection';
import StandardDashboardTile from 'src/views/components/Dashboard/StandardDashboardTile';
import { DateTime } from 'luxon';
import { observer } from 'mobx-react';
import { useRootStore } from 'src/domain/entities/RootStoreModel';

const configurationActions = [
  TaskCardItem.sales.listVehicleTypes,
  TaskCardItem.sales.listExtraTypes,
  TaskCardItem.sales.maintainHourlyRates,
  TaskCardItem.operations.holidays.listHolidays,
  TaskCardItem.operations.charterContracts.listCharterContracts,
]
  .map(toActionLinkDef)
  .sort((a, b) => a.label.localeCompare(b.label));

const Sales: React.FC = observer(() => {
  const rootStore = useRootStore();
  const accountModel = rootStore.account;
  const dashboardModel = rootStore.operations.sales.salesDashboard;
  const dashboardData = dashboardModel.dashboardData;

  useEffect(() => {
    dashboardModel.loadDashboardData();
  }, []);

  const upcomingQuotesLink = () => {
    const today = DateTime.local()
      .endOf('day')
      .toISODate();
    const sevenDays = DateTime.local()
      .plus({ days: 7 })
      .toISODate();
    return `/sales/quotes?status=1&tripFrom=${today}&tripTo=${sevenDays}`;
  };

  const bookingsTodayLink = () => {
    const today = DateTime.local()
      .endOf('day')
      .toISODate();
    return `/sales/quotes?status=2&tripFrom=${today}&tripTo=${today}`;
  };

  const bookingsTomorrowLink = () => {
    const tomorrow = DateTime.local()
      .plus({ days: 1 })
      .toISODate();
    return `/sales/quotes?status=2&tripFrom=${tomorrow}&tripTo=${tomorrow}`;
  };

  const getPageDef = (): IPageDef => {
    const topTasksActions = [
      ...(accountModel.isSalesDepartmentMember ? [TaskCardItem.sales.createQuote] : []),
      ...(accountModel.isSalesDepartmentMember ? [TaskCardItem.sales.createCustomer] : []),
    ].map(toActionLinkDef);

    return {
      primarySize: PagePrimarySize.threeQuarters,
      primarySection: {
        title: 'Sales',
        panels: [
          {
            panes: [
              {
                paneType: PaneType.customPane,
                render: () => (
                  <DashboardTileCollection isLoading={!dashboardData}>
                    {dashboardData ? (
                      <>
                        <StandardDashboardTile
                          title="Outstanding quotes in the next 7 days"
                          number={dashboardData.upcomingQuotesCount}
                          link={upcomingQuotesLink()}
                        />
                        <StandardDashboardTile
                          title="Bookings today"
                          number={dashboardData.bookingsTodayCount}
                          link={bookingsTodayLink()}
                        />
                        <StandardDashboardTile
                          title="Bookings tomorrow"
                          number={dashboardData.bookingsTomorrowCount}
                          link={bookingsTomorrowLink()}
                        />
                      </>
                    ) : null}
                  </DashboardTileCollection>
                ),
              },
            ],
          },
        ],
      },
      secondarySections: [
        {
          title: 'Navigation',
          panels: [
            {
              title: 'Top Tasks',
              panes: [
                {
                  paneType: PaneType.navListPane,
                  links: topTasksActions,
                },
              ],
            },
            {
              title: 'Configuration',
              panes: [
                {
                  paneType: PaneType.navListPane,
                  links: configurationActions,
                },
              ],
            },
          ],
        },
      ],
    };
  };

  return <Page data={dashboardData} def={getPageDef()} />;
});

export default Sales;
