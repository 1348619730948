import { ICustomPaneDef, IPaneData, IPaneMeta } from 'src/views/definitionBuilders/types';

interface ICustomPagePaneProps {
  paneDef: ICustomPaneDef;
  paneData: IPaneData;
  paneMeta: IPaneMeta;
}

const CustomPagePane: React.FC<ICustomPagePaneProps> = ({
  paneDef,
  paneData: data,
  paneMeta: meta,
}) => {
  return <div className="custom-page-pane-component">{paneDef.render({ data, meta })}</div>;
};

export default CustomPagePane;
