import { FieldDefs, FieldType } from 'src/views/definitionBuilders/types';
import { SearchTechSpecValues } from 'src/domain/entities/workshop/asset/AssetsModel';
import { getTechSpecRequirementFieldDefs } from 'src/views/routes/operations/shared/getTechSpecRequirementFieldDefs';
import { splitTechSpecs } from 'src/domain/entities/workshop/techSpecs/TechSpecsHelpers';
import { getLicenceClasses } from 'src/views/routes/people/staffMembers/MaintainStaffMember/getLicenceModalDef';
import { getSkillSpecRequirementFieldDefs } from 'src/views/routes/operations/shared/getSkillSpecRequirementFieldDefs';

type VehicleType = Common.Dtos.VehicleTypeItem;
type AssetSubcategory = Workshop.Domain.AggregatesModel.AssetAggregate.AssetSubcategory;
type AssetHousingLocation = Workshop.Domain.AggregatesModel.AssetAggregate.AssetHousingLocation;
type TechSpecItem = Common.Dtos.TechSpecItem;
type TechSpecDropdownOption = Workshop.Domain.Queries.TechSpecs.TechSpecDropdownOptionsItem.TechSpecDropdownOption;
type StaffDepotDto = Common.Dtos.StaffDepotDto;
type EmploymentStatusDto = Common.Dtos.EmploymentStatusDto;
type LicenceType = Common.AggregatesModel.People.StaffMemberAggregate.LicenceType;
type ListRoleItem = Common.Dtos.ListRoleItem;
type LicenceClass = Common.AggregatesModel.People.StaffMemberAggregate.LicenceClass;
type SkillSpecItem = Common.Dtos.SkillSpecItem;

export const getStaffAndVehiclePillFilterDefs = (
  subcategories: AssetSubcategory[],
  assetHousingLocations: AssetHousingLocation[],
  techSpecs: TechSpecItem[],
  vehicleTypes: VehicleType[],
  searchTechSpecValues: SearchTechSpecValues,
  techSpecDropdownOptions: (techSpecId: number) => TechSpecDropdownOption[],
  allStaffDepots: StaffDepotDto[],
  employmentStatuses: EmploymentStatusDto[],
  roles: ListRoleItem[],
  allLicenceTypes: LicenceType[],
  skillSpecs: SkillSpecItem[]
): FieldDefs[] => {
  return [
    ...getVehicleFilterPillDefs(
      subcategories,
      assetHousingLocations,
      techSpecs,
      vehicleTypes,
      searchTechSpecValues,
      techSpecDropdownOptions
    ),
    ...getStaffFilterPillDefs(
      allStaffDepots,
      employmentStatuses,
      roles,
      allLicenceTypes,
      skillSpecs
    ),
  ];
};

export const getVehicleFilterPillDefs = (
  subcategories: AssetSubcategory[],
  assetHousingLocations: AssetHousingLocation[],
  techSpecs: TechSpecItem[],
  vehicleTypes: VehicleType[],
  searchTechSpecValues: SearchTechSpecValues,
  techSpecDropdownOptions: (techSpecId: number) => TechSpecDropdownOption[]
): FieldDefs[] => {
  return [
    {
      fieldType: FieldType.selectMultiField,
      label: 'Asset Subcategory',
      dataAddr: 'subcategoryIds',
      valueKey: 'id',
      descriptionKey: 'description',
      useValueOnly: true,
      optionItems: subcategories,
    },
    {
      fieldType: FieldType.selectMultiField,
      label: 'Garaged At',
      dataAddr: 'housingLocationIds',
      useValueOnly: true,
      valueKey: 'id',
      descriptionKey: 'description',
      optionItems: assetHousingLocations,
    },
    {
      fieldType: FieldType.selectMultiField,
      label: 'Vehicle Type',
      dataAddr: 'vehicleTypeIds',
      valueKey: 'id',
      descriptionKey: 'description',
      useValueOnly: true,
      optionItems: vehicleTypes,
    },
    ...getTechSpecRequirementFieldDefs(
      splitTechSpecs(techSpecs),
      searchTechSpecValues,
      techSpecDropdownOptions
    ),
  ];
};

export const getStaffFilterPillDefs = (
  allStaffDepots: StaffDepotDto[],
  employmentStatuses: EmploymentStatusDto[],
  roles: ListRoleItem[],
  allLicenceTypes: LicenceType[],
  skillSpecs: SkillSpecItem[]
): FieldDefs[] => {
  const allClasses: Array<LicenceClass> = getLicenceClasses(allLicenceTypes);
  return [
    {
      fieldType: FieldType.selectField,
      label: 'Licence Type',
      dataAddr: 'licenceTypeId',
      optionItems: allLicenceTypes,
      valueKey: 'id',
      descriptionKey: 'description',
      useValueOnly: true,
    },
    {
      fieldType: FieldType.yesNoField,
      label: 'Driver Authorisation',
      dataAddr: 'hasDriverAuthorisation',
    },
    {
      fieldType: FieldType.yesNoField,
      label: 'Workshop Staff',
      dataAddr: 'isWorkshopStaff',
    },
    {
      fieldType: FieldType.selectMultiField,
      label: 'Employment Status',
      dataAddr: 'employmentStatus',
      valueKey: 'id',
      descriptionKey: 'description',
      optionItems: employmentStatuses,
      useValueOnly: true,
    },
    {
      fieldType: FieldType.selectMultiField,
      label: 'Role',
      dataAddr: 'roles',
      valueKey: 'id',
      descriptionKey: 'name',
      optionItems: roles,
      useValueOnly: true,
    },
    {
      fieldType: FieldType.selectMultiField,
      label: 'Depot',
      dataAddr: 'depot',
      valueKey: 'id',
      descriptionKey: 'description',
      optionItems: allStaffDepots,
      useValueOnly: true,
    },
    {
      fieldType: FieldType.selectField,
      label: 'Licence Type',
      dataAddr: 'licenceTypeId',
      optionItems: allLicenceTypes,
      valueKey: 'id',
      descriptionKey: 'description',
      useValueOnly: true,
    },
    {
      fieldType: FieldType.selectMultiField,
      label: 'Licence Class',
      dataAddr: 'licenceClassIds',
      optionItems: d => allClasses.filter(c => c.licenceTypeId === d.parentValue.licenceTypeId),
      valueKey: 'id',
      descriptionKey: 'description',
      useValueOnly: true,
    },
    ...getSkillSpecRequirementFieldDefs(skillSpecs),
  ];
};
