import './ScheduledJob.scss';

import { DateTime } from 'luxon';
import { Button } from 'reactstrap';
import Icon from 'src/views/components/workshop/assetIcon/WorkshopAssetIcon';
import { IScheduledMachineryJob } from 'src/domain/entities/workshop/job/ScheduledMachineryJobsModel';
import { getDaysBetweenCount } from 'src/infrastructure/dateUtils';
import { TIMEZONE } from 'src/appSettings';
import { appInsights } from 'src/appInsights';
import { SeverityLevel } from '@microsoft/applicationinsights-web';

export interface IScheduledJobProps {
  job: IScheduledMachineryJob;
  day: DateTime;
  isSelected: boolean;
  isInMorePopup?: boolean;
  onJobSelected: (job: IScheduledMachineryJob, targetId: string) => void;
}

const ScheduledJob: React.FC<IScheduledJobProps> = ({
  job,
  day,
  isSelected,
  isInMorePopup,
  onJobSelected,
}) => {
  const dayInZone = DateTime.fromObject({
    year: day.year,
    month: day.month,
    day: day.day,
    zone: TIMEZONE,
  });

  const jobStartInZone = job.jobInterval.start.setZone(TIMEZONE);
  const dayIndex = getDaysBetweenCount(dayInZone, jobStartInZone);
  const intDayIndex = Math.floor(dayIndex);

  if (intDayIndex !== dayIndex) {
    appInsights.trackException(
      {
        error: Error('Unexpected job start and day start alignment'),
        severityLevel: SeverityLevel.Error,
      },
      {
        job,
        day,
        dayInZone,
        jobStartInZone,
        TIMEZONE,
        dayIndex,
        intDayIndex,
      }
    );
  }

  const id = `job-${job.jobId}-${intDayIndex}-${isInMorePopup ? 'more' : ''}`;
  const jobDescription =
    intDayIndex > 0
      ? `${job.assetName} (continued)`
      : `${job.assetName} @ ${job.jobInterval.start.toLocaleString(
          DateTime.TIME_SIMPLE
        )} - ${job.jobInterval.end.toLocaleString(DateTime.TIME_SIMPLE)}`;
  const jobTaskCatClass = job.hasMachineryInspectionTask
    ? 'machinery-inspection-category'
    : job.hasPreMachineryInspectionTask
    ? 'pre-machinery-inspection-category'
    : 'cleaning-category';
  const hasCrew = job.tasks.some(
    o => o.staff && o.staff.length > 0 && o.staff.some(s => !s.day || s.day === day.toISODate())
  );
  const crew = hasCrew
    ? Array.from(
        new Set(
          job.tasks
            .map(o => o.staff)
            .reduce((x, y) => x.concat(y))
            .filter(o => !o.day || o.day === day.toISODate())
            .map(o => o.name)
        )
      ).join(', ')
    : undefined;
  return (
    <div id={id} className="machinery-scheduled-job-component" title={jobDescription}>
      <Button
        className="job-btn"
        color="dark"
        onClickCapture={e => {
          e.preventDefault();
          e.stopPropagation();
          onJobSelected(job, id);
        }}>
        <div className={`job-container ${jobTaskCatClass} ${isSelected ? 'selected' : ''}`}>
          <div className="job-icon">
            <Icon assetSubcategoryId={job.assetSubcategory.id} isLowFloor={job.assetIsLowFloor} />
          </div>
          <div className="job-description">{jobDescription}</div>
          <div className="job-has-labour" title={crew}>
            {hasCrew ? '🔧' : null}
          </div>
        </div>
      </Button>
    </div>
  );
};

export default ScheduledJob;
