import { buildListPageApiSearchModelTypedQuery } from 'src/domain/modelBuilders/buildListPageApiSearchModel';
import { getAjax, NotificationType } from 'src/domain/services';
import { getRoot, flow } from 'mobx-state-tree';
import { IRootStoreModel } from 'src/domain/entities/RootStoreModel';

type ListRailBookingsForAccountsQuery = Operations.Domain.Queries.ListRailBookingsForAccounts.ListRailBookingsForAccountsQuery;

export const RailBookingsForAccountsModel = buildListPageApiSearchModelTypedQuery<
  ListRailBookingsForAccountsQuery
>()('RailBookingsForAccountsModel', d =>
  d.ajax.sales.railBookingsForAccounting.listRailBookingsForAccounts(d.query)
).actions(self => {
  const ajax = getAjax(self);
  const root = getRoot(self) as IRootStoreModel;

  const exportRailBookingsToExcel = flow(function*(
    query: Partial<ListRailBookingsForAccountsQuery>
  ) {
    root.notifications.addNotification(`The file is being generated ...`, {
      type: NotificationType.info,
    });

    return yield ajax.accounting.exportRailBookingsToExcel(query);
  });
  return {
    exportRailBookingsToExcel,
  };
});
