import { DateTime } from 'luxon';
import { Component } from 'react';
import {
  IJobItem,
  AllBoundedItemTypes,
} from 'src/domain/entities/operations/job/ListJobsForAllocationsModel';
import {
  mapSkillSpecIds,
  applySkillSpecRequirementsFilter,
} from 'src/domain/entities/people/staffMember/SkillSpecsHelpers';
import { NestedField } from 'src/views/components/Page/forms';
import { doesJobTypeRequireDriversAuthorisation } from '../../../../shared/jobTypeHelpers';
import { renderItem, getItemUiKey } from '../../AllocationsBoundedItemHelper';
import GanttCalendar from '..';
import { IGanttView, IGanttCalendarDayItem, IGanttCalendarGroupItem } from '../baseTypes';
import { getStaffFilterPillDefs } from '../staffAndVehiclePillFilterDefs';
import { getStaffView } from '../../views';
import { IViewGroupFilterValues } from '../GanttCalendar';

type StaffMemberDto = Common.Dtos.StaffMemberDto;
type PaidHoursInPayPeriod = Operations.Domain.Queries.GetPaidHoursInPayPeriodForDay.PaidHoursInPayPeriod;
type SkillSpecItem = Common.Dtos.SkillSpecItem;
type ExclusionItem = Operations.Domain.Queries.ListExclusions.ExclusionItem;
type StaffDepotDto = Common.Dtos.StaffDepotDto;
type EmploymentStatusDto = Common.Dtos.EmploymentStatusDto;
type LicenceType = Common.AggregatesModel.People.StaffMemberAggregate.LicenceType;
type ListRoleItem = Common.Dtos.ListRoleItem;

export interface IAssignStaffFieldProps {
  job: IJobItem;
  showInZone: string;
  unfilteredItems: Array<AllBoundedItemTypes>;
  staff: Array<StaffMemberDto>;
  paidHours: Array<PaidHoursInPayPeriod>;
  skillSpecs: SkillSpecItem[];
  exclusions: ExclusionItem[];
  employmentStatuses: EmploymentStatusDto[];
  roles: ListRoleItem[];
  onLoadUnfilteredItems: (start: DateTime, end: DateTime) => Promise<void>;
  allStaffDepots: StaffDepotDto[];
  allLicenceTypes: LicenceType[];
  defaultRolesForDriverAllocations: ListRoleItem[];
  viewFilters: { [key: string]: IViewGroupFilterValues };
}

interface IAssignStaffMemberFieldState {
  viewStaffFilters: { [key: string]: IViewGroupFilterValues };
}

export class AssignStaffMemberField extends Component<
  IAssignStaffFieldProps,
  IAssignStaffMemberFieldState
> {
  constructor(props: IAssignStaffFieldProps) {
    super(props);
    this.state = {
      viewStaffFilters: {
        staffMember: {
          search: props.viewFilters ? props.viewFilters.staffMember.search : '',
          filters: props.viewFilters ? props.viewFilters.staffMember.filters : {},
        },
      },
    };
  }
  componentDidMount() {
    const { job, onLoadUnfilteredItems } = this.props;
    onLoadUnfilteredItems(job.bounds.start.minus({ days: 1 }), job.bounds.end.plus({ days: 1 }));
  }

  private readonly handleFilter = (change: Partial<IViewGroupFilterValues>) => {
    const newFiltering = {
      ...this.state.viewStaffFilters,
      staffMember: {
        filters: this.state.viewStaffFilters ? this.state.viewStaffFilters.staffMember.filters : {},
        search: this.state.viewStaffFilters ? this.state.viewStaffFilters.staffMember.search : '',
        ...change,
      },
    };
    this.setState({ viewStaffFilters: newFiltering });
  };

  // tslint:disable-next-line:no-any
  private readonly handleValidate = (v: any) => {
    return !v || typeof v !== 'string' ? 'A Staff Member must be selected' : undefined;
  };

  render() {
    const {
      showInZone,
      job,
      unfilteredItems,
      staff,
      paidHours,
      skillSpecs,
      exclusions,
      allStaffDepots,
      employmentStatuses,
      roles,
      allLicenceTypes,
      defaultRolesForDriverAllocations,
    } = this.props;
    let filteredStaff = staff.filter(x => x.active || x.id === job.staffMemberId);
    if (doesJobTypeRequireDriversAuthorisation(job.jobType.id)) {
      filteredStaff = filteredStaff.filter(x => x.hasDriversAuthorisation);
    }
    if (job.asset && job.asset.id) {
      filteredStaff = filteredStaff.filter(
        x =>
          exclusions.findIndex(e => e.asset.id === job.asset.id && e.staffMember.id === x.id) ===
            -1 &&
          x.licences.findIndex(l => l.licenceClass?.id < (job.asset.licenceClassId ?? 0)) === -1
      );
    }
    if (job.skillSpecRequirements && job.skillSpecRequirements.length) {
      const skillSpecRequirementsIds = mapSkillSpecIds(job.skillSpecRequirements);
      filteredStaff = applySkillSpecRequirementsFilter(filteredStaff, skillSpecRequirementsIds);
    }
    return (
      <NestedField field="staffMemberId" validate={this.handleValidate}>
        <GanttCalendar
          className="assign-staff-component"
          showInZone={showInZone}
          items={unfilteredItems}
          minRows={10}
          rowHeightPx={40}
          dayWidthPx={1500}
          minZoomFactor={1}
          maxZoomFactor={8}
          highlightBounds={job.bounds}
          renderItem={renderItem}
          renderItemPopup={() => null}
          getItemUiKey={getItemUiKey}
          ganttViews={{
            staffMember: (getStaffView(
              filteredStaff,
              allStaffDepots,
              [],
              paidHours,
              skillSpecs,
              employmentStatuses,
              roles,
              allLicenceTypes,
              'excludeUngroupedItems',
              'selectable',
              defaultRolesForDriverAllocations.map(o => o.id)
            ) as unknown) as IGanttView<IGanttCalendarDayItem, IGanttCalendarGroupItem>,
          }}
          excludeUngroupedView
          filterDefsForSubFilterPills={getStaffFilterPillDefs(
            allStaffDepots,
            employmentStatuses,
            roles,
            allLicenceTypes,
            skillSpecs
          )}
          pillContainerId={'staff-member-allocation-popover-filter-pills'}
          viewFilters={this.state.viewStaffFilters}
          handleFilteringChange={this.handleFilter}
        />
      </NestedField>
    );
  }
}
