import inject from 'src/views/injectFromStore';
import ListAssetsWithUnknownKms, {
  IListAssetsWithUnknownKmsProps,
} from './ListAssetsWithUnknownKms';

const Container = inject<IListAssetsWithUnknownKmsProps>((allStores, p) => {
  return {
    loadAssetsWithUnknownKms: allStores.rootStore.operations.assetsWithUnknownKms.listItems,
    assetsWithUnknownKms: allStores.rootStore.operations.assetsWithUnknownKms.items.slice(),
    dismissUnknownKms: allStores.rootStore.operations.assetsWithUnknownKms.dismissUnknownKms,
    hasMoreData: allStores.rootStore.operations.assetsWithUnknownKms.hasMoreItems,
  };
})(ListAssetsWithUnknownKms);

export default Container;
