import { IRootStoreModel } from 'src/domain/entities/RootStoreModel';
import { UiRoute } from 'src/domain/services/buildUiRoutes';
import { UiRouteName } from 'src/domain/baseTypes';

export default function buildUiRoutes(store: IRootStoreModel) {
  const lostPropertyCreate = new UiRoute(
    UiRouteName.complianceLostPropertyCreate,
    '/compliance/lost-property-items/create',
    () => 'Create Lost Property Item',
    true
  );

  const lostPropertyUpdate = new UiRoute(
    UiRouteName.complianceLostPropertyUpdate,
    '/compliance/lost-property-items/:id',
    () => 'Manage Lost Property Item',
    true
  );

  const lostPropertyList = new UiRoute(
    UiRouteName.complianceLostPropertyList,
    '/compliance/lost-property-items',
    () => 'Lost Property Items',
    true,
    false,
    [lostPropertyCreate, lostPropertyUpdate]
  );

  return {
    [UiRouteName.complianceLostPropertyCreate]: lostPropertyCreate,
    [UiRouteName.complianceLostPropertyUpdate]: lostPropertyUpdate,
    [UiRouteName.complianceLostPropertyList]: lostPropertyList,
  };
}
