import { getEnv, IStateTreeNode } from 'mobx-state-tree';
import { IStoreBus } from './storeBus';
import { IDomainAjax } from './domainAjax/domainAjax';

export interface IStoreEnvironment {
  ajax: IDomainAjax;
  bus: IStoreBus;
  signals: signalR.HubConnection;
}

export function getAjax(model: IStateTreeNode) {
  return (getEnv(model) as IStoreEnvironment).ajax;
}

export function getBus(model: IStateTreeNode) {
  return (getEnv(model) as IStoreEnvironment).bus;
}

export function getSignalHub(model: IStateTreeNode) {
  return (getEnv(model) as IStoreEnvironment).signals;
}
