import { types, flow, getRoot } from 'mobx-state-tree';
import { getAjax } from 'src/domain/services';
import { IRootStoreModel } from 'src/domain/entities/RootStoreModel';
import {
  ISetActiveCommand,
  ISetInactiveCommand,
} from 'src/views/components/ActivationAndDeletionActionsButtons/getActivationAndDeletionActionButtons';

type SetAssetGroupInactiveCommand = Workshop.Domain.Commands.AssetGroup.SetAssetGroupInactiveCommand;
type AssetGroupUsage = Common.Dtos.AggregateUsageDto;
type JobTaskCategory = Workshop.Domain.Queries.GetTaskCategories.JobTaskCategoryListItem;
type JobTaskSubcategory = Workshop.Domain.Queries.GetTaskCategories.JobTaskSubcategoryListItem;
type ChecklistDetails = Workshop.Domain.Queries.GetChecklist.ChecklistDetails;
type AssetGroupDetails = Workshop.Domain.Queries.AssetGroup.AssetGroupDetails;
type ServiceCycleItem = Workshop.Domain.Queries.AssetGroup.GetAssetGroupFullServiceCycle.ServiceCycleItem;

export const AssetGroupModel = types
  .model('AssetGroupModel', {
    serviceTypes: types.array(types.frozen<JobTaskSubcategory>()),
    checklists: types.array(types.frozen<ChecklistDetails>()),
    assetGroup: types.maybe(types.frozen<AssetGroupDetails>()),
    fullServiceCycle: types.array(types.frozen<ServiceCycleItem>()),
    assetGroupUsage: types.maybe(types.frozen<AssetGroupUsage>()),
  })
  .actions(self => {
    const ajax = getAjax(self);
    const root = getRoot(self) as IRootStoreModel;

    const loadServiceTypes = flow(function*() {
      const categories: JobTaskCategory[] = yield ajax.assetGroup.getJobTaskCategories();
      const serviceCategory = categories.find((c: JobTaskCategory) => c.description === 'Service');
      const subCategories = serviceCategory ? serviceCategory.subcategories : [];
      self.serviceTypes.replace(subCategories);
    });

    const loadChecklists = flow(function*(includeInactive) {
      const checklists: Common.Dtos.ListResult<ChecklistDetails> = yield ajax.assetGroup.getChecklists(
        includeInactive
      );
      self.checklists.replace(checklists.items);
    });

    const createAssetGroup = flow(function*(
      command: Workshop.Domain.Commands.AssetGroup.CreateAssetGroupCommand
    ) {
      const id = yield ajax.assetGroup.createAssetGroup(command);
      self.assetGroup = undefined;
      root.history.push(`/workshop/asset-groups/${id}`);
    });

    const checkForUniqueName = flow(function*(name: string) {
      return yield ajax.assetGroup.checkForUniqueName(name);
    });

    const loadAssetGroup = flow(function*(id: string) {
      self.assetGroup = yield ajax.assetGroup.loadAssetGroup(id);
    });

    const loadAssetGroupForAsset = flow(function*(assetId: string) {
      self.assetGroup = yield ajax.assetGroup.loadAssetGroupForAsset(assetId);
    });

    const loadAssetGroupFullServiceCycle = flow(function*(assetGroupId: string) {
      self.fullServiceCycle = yield ajax.assetGroup.loadAssetGroupFullServiceCycle(assetGroupId);
    });

    const loadAssetGroupFullServiceCycleForAsset = flow(function*(assetId: string) {
      const assetGroup = yield ajax.assetGroup.loadAssetGroupForAsset(assetId);
      self.fullServiceCycle.clear;
      if (assetGroup) {
        self.fullServiceCycle = yield ajax.assetGroup.loadAssetGroupFullServiceCycle(assetGroup.id);
      }
    });

    const updateAssetGroup = flow(function*(
      command: Workshop.Domain.Commands.AssetGroup.UpdateAssetGroupCommand
    ) {
      yield ajax.assetGroup.updateAssetGroup(command);
      yield loadAssetGroup(command.assetGroupId);
      yield loadAssetGroupFullServiceCycle(command.assetGroupId);
    });

    const getAssetGroupUsage = flow(function*(assetGroupId: string) {
      self.assetGroupUsage = yield ajax.assetGroup.getAssetGroupUsage(assetGroupId);
    });

    const setAssetGroupInactive = flow(function*(command: ISetInactiveCommand) {
      const setAssetGroupInactiveCommand: SetAssetGroupInactiveCommand = { id: command.id };
      yield ajax.assetGroup.updateAssetGroupSetInactive(setAssetGroupInactiveCommand);
    });

    const setAssetGroupActive = flow(function*(command: ISetActiveCommand) {
      const setAssetGroupActiveCommand: SetAssetGroupInactiveCommand = { id: command.id };
      yield ajax.assetGroup.updateAssetGroupSetActive(setAssetGroupActiveCommand);
    });

    const deleteAssetGroup = flow(function*(assetGroupId: string) {
      yield ajax.assetGroup.deleteAssetGroup(assetGroupId);

      self.assetGroup = undefined;
      self.assetGroupUsage = undefined;

      root.history.push(`/workshop/asset-groups`);
    });

    return {
      loadServiceTypes,
      loadChecklists,
      createAssetGroup,
      checkForUniqueName,
      loadAssetGroup,
      updateAssetGroup,
      loadAssetGroupForAsset,
      loadAssetGroupFullServiceCycle,
      loadAssetGroupFullServiceCycleForAsset,
      getAssetGroupUsage,
      setAssetGroupInactive,
      setAssetGroupActive,
      deleteAssetGroup,
    };
  });
