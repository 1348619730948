import ListOwners, { IListOwnersProps } from './ListOwners';
import inject from 'src/views/injectFromStore';

const Container = inject<IListOwnersProps>((allStores, p) => ({
  canManageOwners: allStores.rootStore.account.isWorkshopDepartmentMember,
  listOwners: allStores.rootStore.workshop.owners.listItems,
  owners: allStores.rootStore.workshop.owners.items.slice(),
  hasMoreData: allStores.rootStore.workshop.owners.hasMoreItems,
}))(ListOwners);

export default Container;
