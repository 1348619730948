import { CopyIcon } from 'src/images/icons';
import { allAdhocReason, AdhocReason } from 'src/api/enums';
import {
  IModalActionButtonDef,
  ActionType,
  ShellModalSize,
  PaneType,
  FieldType,
} from 'src/views/definitionBuilders/types';
import { getSubmitCloseModalActionGroupDef } from 'src/views/definitionBuilders/common';

type DuplicateJobCommand = Operations.Domain.Commands.Job.DuplicateJob.DuplicateJobCommand;

export default function getDuplicateJobButtonDef(
  jobId: string,
  isAdHocJob: boolean,
  isCharterJob: boolean,
  isChildJob: boolean,
  hasProgress: boolean,
  duplicateJob: (command: DuplicateJobCommand) => Promise<void>
): IModalActionButtonDef {
  const hideAdhoc = !isAdHocJob;
  return {
    actionType: ActionType.modalActionButton,
    label: 'Duplicate Job',
    icon: <CopyIcon fixedWidth />,
    hidden: isChildJob,
    modalSize: ShellModalSize.oneQuarter,
    modalDef: modalDefApi => ({
      title: 'Duplicate Job',
      asForm: true,
      panels: [
        {
          panes: [
            {
              paneType: PaneType.customPane,
              render: () => (
                <>
                  {hasProgress && (
                    <>
                      <b>This job has tablet progress.</b>
                      <p>
                        If you are duplicating this job for the purpose of a Vehicle Swap, use "Swap
                        Vehicle In Progress" in the Triple Dot Menu.
                      </p>
                      <hr />
                    </>
                  )}
                  <p>
                    Are you sure you want to create a duplicate of this{' '}
                    {hideAdhoc ? null : 'ad hoc'} job?
                  </p>
                </>
              ),
            },
            {
              paneType: PaneType.formFieldsPane,
              hidden: d => hideAdhoc,
              fields: [
                {
                  fieldType: FieldType.selectField,
                  label: 'Adhoc Reason',
                  dataAddr: ['adhocReason', 'id'],
                  valueKey: 'value',
                  descriptionKey: 'description',
                  useValueOnly: true,
                  mandatory: true,
                  optionItems: allAdhocReason,
                },
                {
                  fieldType: FieldType.textField,
                  label: 'Adhoc Reason Details',
                  dataAddr: 'adhocReasonDetails',
                  mandatory: d =>
                    d.parentValue.adhocReason && d.parentValue.adhocReason.id === AdhocReason.Other,
                },
              ],
            },
          ],
        },
      ],
      secondaryActions: [getSubmitCloseModalActionGroupDef('Duplicate')],
      onFormSubmit: d => {
        return duplicateJob({
          jobId,
          adhocReasonId: hideAdhoc ? undefined : d.adhocReason.id,
          adhocReasonDetails: hideAdhoc ? undefined : d.adhocReasonDetails,
        });
      },
    }),
  };
}
