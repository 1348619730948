import { DateTime, Duration } from 'luxon';
import { JobStatus, ChangeState } from 'src/api/enums';
import {
  PaneType,
  FieldType,
  IModalDefBuilderApi,
  ISectionDef,
  IHasChangeState,
  ICustomPaneDef,
} from 'src/views/definitionBuilders/types';
import getTripRoutesPaneDef, {
  IEditableOptions,
  isEditableOptions,
  INeverEditableOptions,
} from 'src/views/routes/operations/shared/getTripRoutesPaneDef';
import deepEqual from 'src/infrastructure/deepEqual';
import { JOB_CLOCK_ON_MINS_BUFFER } from 'src/appSettings';
import { getSubmitCloseModalActionGroupDef } from 'src/views/definitionBuilders/common';

type JobRouteItem = Operations.Domain.Queries.ViewJob.JobRouteItem;
type SplitJobCommand = Operations.Domain.Commands.Job.SplitJob.SplitJobCommand;

function validateDateTimeIsNotLessThan(
  first: string,
  firstLabel: string,
  second: string,
  secondLabel: string
) {
  return !!first && !!second && DateTime.fromISO(first) < DateTime.fromISO(second)
    ? `${firstLabel} cannot be less than ${secondLabel}`
    : undefined;
}

export function getSplitJobModalDef(
  modalDefApi: IModalDefBuilderApi,
  jobRouteIndex: number,
  options: IEditableOptions | INeverEditableOptions
): ISectionDef {
  const splitJob = isEditableOptions(options) ? options.splitJob : undefined;
  const depots = isEditableOptions(options) ? options.depots : [];
  const states = (isEditableOptions(options) && options.states) || [];
  const searchBoardingPoints = isEditableOptions(options)
    ? options.searchBoardingPoints
    : () => Promise.resolve({ options: [] });

  const getExistingJobRoutes = (jobRoutes: JobRouteItem[]) => {
    return jobRoutes.map((r, i) => {
      return {
        ...r,
        changeState: i < jobRouteIndex + 1 ? ChangeState.Unchanged : ChangeState.Deleted,
      };
    });
  };

  const getSplitJobRoutes = (jobRoutes: JobRouteItem[]) => {
    return jobRoutes.slice(jobRouteIndex, jobRoutes.length);
  };

  const getScheduleAndVehicleMovementsHint = (): ICustomPaneDef => {
    return {
      paneType: PaneType.customPane,
      hidden: modalDefApi.parentFormApi.values.jobStatus.id !== JobStatus.Incomplete,
      render: () => {
        return (
          <>
            🛈{' '}
            <em>
              Schedule and Vehicle Movements fields are not available while splitting incomplete
              jobs
            </em>
          </>
        );
      },
    };
  };

  return {
    title: 'Split Job',
    asForm: true,
    explicitData: {
      existingJobRoutes: getExistingJobRoutes(modalDefApi.actionData.paneValue),
      splitJobRoutes: getSplitJobRoutes(modalDefApi.actionData.paneValue),
      existingDescription: modalDefApi.parentFormApi.values.description,
      splitDescription: modalDefApi.parentFormApi.values.description,
    },
    panels: [
      {
        title: 'Job 1',
        panes: [
          {
            paneType: PaneType.formFieldsPane,
            fields: [
              {
                fieldType: FieldType.textField,
                label: 'Job 1 Description',
                dataAddr: 'existingDescription',
                mandatory: true,
              },
            ],
          },
          getScheduleAndVehicleMovementsHint(),
          getTripRoutesPaneDef('existingJobRoutes', {
            editable: true,
            initialArrival: 'initialArrivalMandatory',
            title: 'Routes',
            states,
            searchBoardingPoints,
            boardingPoints: isEditableOptions(options) ? options.boardingPoints : [],
          }),
          {
            paneType: PaneType.formFieldsPane,
            hidden: modalDefApi.parentFormApi.values.jobStatus.id === JobStatus.Incomplete,
            columnCount: 3,
            fields: [
              {
                fieldType: FieldType.dateTimeField,
                label: 'Arrive Depot',
                dataAddr: 'arriveDepot',
                mandatory: true,
              },
              {
                fieldType: FieldType.dateTimeField,
                label: 'Clock Off',
                dataAddr: 'clockOff',
                mandatory: true,
                validate: d =>
                  validateDateTimeIsNotLessThan(
                    d.parentValue.clockOff,
                    'Clock off',
                    d.parentValue.arriveDepot,
                    'Arrive depot'
                  ),
              },
              {
                fieldType: FieldType.durationField,
                label: 'Unpaid Breaks',
                dataAddr: 'unpaidBreaks',
                mandatory: true,
              },
              {
                fieldType: FieldType.selectField,
                label: 'Arriving At Depot',
                dataAddr: 'arrivingAtDepot',
                valueKey: 'id',
                descriptionKey: 'description',
                mandatory: true,
                optionItems: depots,
              },
              {
                fieldType: FieldType.yesNoField,
                label: 'Arriving At Depot In Car',
                dataAddr: 'arrivingAtDepotInCar',
                mandatory: true,
              },
            ],
          },
        ],
      },
      {
        title: 'Job 2',
        panes: [
          {
            paneType: PaneType.formFieldsPane,
            fields: [
              {
                fieldType: FieldType.textField,
                label: 'Job 2 Description',
                dataAddr: 'splitDescription',
                mandatory: true,
              },
            ],
          },
          getScheduleAndVehicleMovementsHint(),
          {
            paneType: PaneType.formFieldsPane,
            hidden: modalDefApi.parentFormApi.values.jobStatus.id === JobStatus.Incomplete,
            columnCount: 3,
            fields: [
              {
                fieldType: FieldType.dateTimeField,
                label: 'Clock On',
                dataAddr: 'clockOn',
                mandatory: true,
              },
              {
                fieldType: FieldType.dateTimeField,
                label: 'Depart Depot',
                dataAddr: 'departDepot',
                mandatory: true,
                validate: d =>
                  validateDateTimeIsNotLessThan(
                    d.parentValue.departDepot,
                    'Depart depot',
                    d.parentValue.clockOn,
                    'clock on'
                  ),
                onChange: api => {
                  var departDepot = DateTime.fromISO(api.newFieldValue);
                  if (departDepot.isValid) {
                    api.setFormValue(
                      ['clockOn'],
                      departDepot.minus(Duration.fromObject({ minutes: JOB_CLOCK_ON_MINS_BUFFER }))
                    );
                  }
                },
              },
              {
                fieldType: FieldType.durationField,
                label: 'Unpaid Breaks',
                dataAddr: 'splitUnpaidBreaks',
                mandatory: true,
              },
              {
                fieldType: FieldType.selectField,
                label: 'Departing From Depot',
                dataAddr: 'departingFromDepot',
                valueKey: 'id',
                descriptionKey: 'description',
                mandatory: true,
                optionItems: depots,
              },
              {
                fieldType: FieldType.yesNoField,
                label: 'Departing From Depot In Car',
                dataAddr: 'departingFromDepotInCar',
                mandatory: true,
              },
            ],
          },
          getTripRoutesPaneDef('splitJobRoutes', {
            editable: true,
            initialArrival: 'initialArrivalMandatory',
            title: 'Split Routes',
            states,
            searchBoardingPoints,
            boardingPoints: isEditableOptions(options) ? options.boardingPoints : [],
          }),
        ],
      },
    ],
    secondaryActions: [getSubmitCloseModalActionGroupDef('Ok')],
    // tslint:disable-next-line:no-any
    onFormPreSubmit: (command: any): SplitJobCommand => {
      const getChangeState = (li: JobRouteItem, originals: JobRouteItem[]) => {
        if (li.changeState === ChangeState.Added || li.changeState === ChangeState.Deleted) {
          return li.changeState;
        }
        const original = originals && originals.find((x: JobRouteItem) => x.id === li.id);

        return deepEqual(li, original, { strict: true })
          ? ChangeState.Unchanged
          : ChangeState.Modified;
      };
      const mapJobRoutes = (
        jobRoute: Array<JobRouteItem & IHasChangeState>
      ): Operations.Domain.Commands.Job.SplitJob.JobRouteItem[] => {
        return jobRoute.map(r => {
          return {
            id: r.id,
            address: r.location.address,
            arrive: r.arrive,
            city: r.location.city,
            date: r.date,
            depart: r.depart,
            boardingPointId:
              // location can be a BoardingPointListItem if a new boarding point was selected
              r.location.boardingPointId ||
              (r.location as Operations.Domain.Queries.SearchBoardingPoint.BoardingPointListItem)
                .id,
            name: r.location.name,
            notes: r.location.notes,
            postcode: r.location.postcode,
            state: r.location.state,
            changeState: getChangeState(r, modalDefApi.actionData.paneValue),
            routeNumber: r.routeNumber,
          };
        });
      };
      return {
        jobId: modalDefApi.parentFormApi.values.id,
        existingJob: {
          arriveDepot: command.arriveDepot,
          clockOff: command.clockOff,
          unpaidBreaks: command.unpaidBreaks,
          arrivingAtDepotId: command.arrivingAtDepot && command.arrivingAtDepot.id,
          arrivingAtDepotInCar: command.arrivingAtDepotInCar,
          existingDescription: command.existingDescription,
          jobRoutes: mapJobRoutes(command.existingJobRoutes),
        },
        splitJob: {
          clockOn: command.clockOn,
          departDepot: command.departDepot,
          unpaidBreaks: command.splitUnpaidBreaks,
          departingFromDepotId: command.departingFromDepot && command.departingFromDepot.id,
          departingFromDepotInCar: command.departingFromDepotInCar,
          description: command.splitDescription,
          jobRoutes: mapJobRoutes(command.splitJobRoutes),
        },
      };
    },
    onFormSubmit: splitJob,
  };
}
