import './PrintCharterInstructionsContent.scss';

import { DateTime } from 'luxon';
import CompanyLogo from 'src/views/components/CompanyLogo/CompanyLogo';
import { DateFormat } from 'src/views/components/DateFormat';
import LocationFormat from 'src/views/components/operations/LocationFormat/LocationFormat';
import DraftWatermark from 'src/views/components/operations/DraftWatermark/DraftWatermark';
import { TIME_24_SIMPLE_HOURCYCLE_23 } from 'src/infrastructure/dateUtils';
import { getTimezoneCommonName } from 'src/views/components/DateTimeFormat/TimezoneFormat';
import { getStateTimezone } from 'src/domain/entities/operations/sales/boardingPoint/StateTimezones';

type CharterJob = Operations.Domain.Queries.ListCharterInstructionsForDriver.CharterJob;

export interface IPrintCharterInstructionsContentProps {
  charterInstructions:
    | Operations.Domain.Queries.ListCharterInstructionsForDriver.CharterInstructions
    | undefined;
}

function formatTime(datetime: string | undefined, zone?: string) {
  if (!datetime) {
    return '';
  }
  const dt = DateTime.fromISO(datetime, { zone: zone });
  return `${dt.toLocaleString(TIME_24_SIMPLE_HOURCYCLE_23)} ${getTimezoneCommonName(dt, true)}`;
}

function showWatermark(job: Operations.Domain.Queries.ListCharterInstructionsForDriver.CharterJob) {
  const now = DateTime.local().startOf('day');
  const clockOn = DateTime.fromISO(job.clockOn).startOf('day');
  return !job.hasSubcontractor && clockOn > now;
}

function doesJobEndOnDifferentDay(job: CharterJob) {
  const clockOn = DateTime.fromISO(job.clockOn);
  const clockOff = DateTime.fromISO(job.clockOff);
  return !clockOn.hasSame(clockOff, 'day');
}

const PaxItem = ({ value }: { value: number | undefined }) => {
  return value && value > 0 ? (
    <div className="pax-section">
      <span>Passengers</span>
      {value}
    </div>
  ) : null;
};

const PrintCharterInstructionsContent: React.FC<IPrintCharterInstructionsContentProps> = ({
  charterInstructions,
}) => {
  return (
    <div className="print-charter-instructions-content-component">
      {charterInstructions && charterInstructions.jobs.length > 0 ? (
        charterInstructions.jobs.map(job => {
          const firstRouteZone = getStateTimezone(job.routes[0]?.location);
          return (
            <div className="job" key={job.id}>
              <DraftWatermark shouldBeVisible={showWatermark(job)} className="watermark" />
              <table>
                <tbody>
                  <tr>
                    <td>
                      <CompanyLogo mode="full" />
                    </td>
                    <td className="text-right">
                      <h2>Driver's Instructions</h2>
                      {job.bookingNumber && <h2>B{job.bookingNumber}</h2>}
                    </td>
                  </tr>
                </tbody>
              </table>
              <div className="job-info">
                <table>
                  <tbody>
                    <tr>
                      <td>
                        {job.hasSubcontractor ? (
                          <>
                            <strong>Subcontractor:</strong>{' '}
                            {job.subcontractor && job.subcontractor.name}
                          </>
                        ) : (
                          <>
                            <strong>Driver:</strong> {job.staffMember && job.staffMember.name}
                          </>
                        )}
                      </td>
                      <td>
                        <strong>Date of Charter:</strong> <DateFormat value={job.clockOn} />
                        {doesJobEndOnDifferentDay(job) ? (
                          <>
                            {' '}
                            To <DateFormat value={job.clockOff} />
                          </>
                        ) : null}
                      </td>
                      <td>
                        <strong>
                          {job.isContinuingFrom || job.isVehicleSwappedFrom ? 'Start' : 'Clock On'}:
                        </strong>{' '}
                        {job.isContinuingFrom
                          ? formatTime(job.continuationJobStart, firstRouteZone)
                          : job.isVehicleSwappedFrom
                          ? formatTime(job.vehicleSwapStart, firstRouteZone)
                          : formatTime(job.clockOn)}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        {job.hasSubcontractor ? null : (
                          <>
                            <strong>Vehicle:</strong> {job.assetName}
                          </>
                        )}
                      </td>
                      {job.quoteType && (
                        <td>
                          <strong>Charter Type:</strong> {job.quoteType.description}
                        </td>
                      )}
                      <td>
                        {job.isContinuingFrom || job.isVehicleSwappedFrom ? null : (
                          <span>
                            <strong>Depart Depot:</strong> {formatTime(job.departDepot)}
                          </span>
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={3}>
                        <strong>Special Requirements: </strong>
                        <span>
                          {job.extras
                            .map(e => `${e.quantity} × ${e.extraType.description}`)
                            .join(', ')}
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="description">
                <table>
                  <tbody>
                    <tr>
                      <td>
                        <strong>Description:</strong> {job.description}
                      </td>
                    </tr>
                  </tbody>
                </table>
                <hr />
              </div>
              {job.charterCustomer && (
                <div className="customer">
                  <table>
                    <tbody>
                      <tr>
                        <td>
                          <strong>Customer:</strong> {job.charterCustomer.name}
                        </td>
                        <td>
                          <strong>Contact:</strong> {job.charterCustomer.contactName}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>Phone:</strong> {job.charterCustomer.customerPhone}
                        </td>
                        <td>
                          <strong>Phone:</strong> {job.charterCustomer.contactPhone}
                        </td>
                      </tr>
                      <tr>
                        <td />
                        <td>
                          <strong>Mobile:</strong> {job.charterCustomer.contactMobile}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <hr />
                </div>
              )}
              {job.isContinuingFrom && (
                <div>
                  <strong>Continuing from previous job</strong>
                  <hr />
                </div>
              )}
              {job.isVehicleSwappedFrom && (
                <div>
                  <strong>Vehicle swapped from previous job</strong>
                  <hr />
                </div>
              )}
              <div className="routes">
                <h3>Routes</h3>
                <table>
                  <thead>
                    <tr>
                      <th className="date">Date</th>
                      <th>Location</th>
                      <th className="arrive">Arrive</th>
                      <th className="depart">Depart</th>
                    </tr>
                  </thead>
                  <tbody>
                    {job.routes.map(route => (
                      <tr key={route.id}>
                        <td className="date">
                          <DateFormat value={route.date} />
                        </td>
                        <td>
                          <LocationFormat value={route.location} />
                        </td>
                        <td className="arrive">{formatTime(route.arrive)}</td>
                        <td className="depart">{formatTime(route.depart)}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <hr />
              </div>
              {job.isContinuingTo && (
                <div>
                  <strong>Continuing to next job</strong>
                  <hr />
                </div>
              )}
              <div className="further-info">
                <h3>Further Info:</h3>
                <PaxItem value={job.passengers} />
                <p className="notes">{job.notes}</p>
                {job.hasSecondStaffMember && (
                  <p>
                    Duties for this job will be split between yourself and{' '}
                    <strong>
                      {(job.secondStaffMember && job.secondStaffMember.name) || 'another driver'}
                    </strong>
                    .
                  </p>
                )}
                {job.bookingNumber && (
                  <p className="accompanying-total">
                    There are {job.accompanying.length} other vehicles attached to this trip.
                    {job.accompanying.length === 0 ? null : ' Details are:'}
                  </p>
                )}
                {job.accompanying.length === 0 ? null : (
                  <table className="accompanying-jobs">
                    <thead>
                      <th>Vehicle</th>
                      <th>Driver</th>
                      <th>Subcontractor</th>
                    </thead>
                    <tbody>
                      {job.accompanying.map(a => (
                        <tr key={a.jobId}>
                          <td>{a.assetName || <em className="muted">not set</em>}</td>
                          <td>{a.staffMemberName || <em className="muted">not set</em>}</td>
                          <td>{a.subcontractorName || <em className="muted">not set</em>}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                )}
              </div>
              <div className="anything-to-report">
                <div className="report">Anything to report?</div>
                <div className="signature">
                  I certify that this information is true and correct. Signature:
                </div>
              </div>
              <div className="completion-details">
                <table>
                  <tbody>
                    <tr>
                      <td>Tolls Yes/No</td>
                      <td>&nbsp;</td>
                      <td>
                        {job.isContinuingFrom || job.isVehicleSwappedFrom ? 'Start' : 'Clock On'}
                      </td>
                      <td>&nbsp;</td>
                      <td>Break Start</td>
                      <td>&nbsp;</td>
                    </tr>
                    <tr>
                      <td>Kms Finish</td>
                      <td />
                      <td>
                        {job.isContinuingTo || job.isVehicleSwappedTo ? 'Finish' : 'Clock Off'}
                      </td>
                      <td />
                      <td>Break Finish</td>
                      <td />
                    </tr>
                    <tr>
                      <td>Kms Start</td>
                      <td />
                      <td>Total Hours</td>
                      <td />
                      <td>Total Breaks</td>
                      <td />
                    </tr>
                    <tr>
                      <td>Total</td>
                      <td />
                      <td>Fuel Used</td>
                      <td />
                      <td>Num Of Pass</td>
                      <td />
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="printed">
                Printed {DateTime.local().toLocaleString(DateTime.DATETIME_MED)}
              </div>
            </div>
          );
        })
      ) : (
        <h1>No Jobs To Export</h1>
      )}
    </div>
  );
};

export default PrintCharterInstructionsContent;
