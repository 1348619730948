import { types, flow } from 'mobx-state-tree';
import { getAjax } from 'src/domain/services';

type AssetPartSubCategoryItem = Workshop.Domain.Queries.AssetPartCategory.AssetPartSubCategoryItem;

export const AssetPartSubCategoriesModel = types
  .model('AssetPartSubCategoriesModel', {
    assetPartSubCategoryListItems: types.array(types.frozen<AssetPartSubCategoryItem>()),
  })
  .actions(self => {
    const ajax = getAjax(self);

    const loadAssetPartSubCategoryListItems = flow(function*() {
      self.assetPartSubCategoryListItems = yield ajax.workshop.assetPartSubCategories.listAssetPartSubCategories();
    });

    return {
      loadAssetPartSubCategoryListItems,
    };
  });

type IAssetPartSubCategoriesModelType = typeof AssetPartSubCategoriesModel.Type;
export interface IAssetPartSubCategoriessModel extends IAssetPartSubCategoriesModelType {}
