import inject from 'src/views/injectFromStore';
import ListProcessDriverAuthorization, {
  IListProcessDriverAuthorizationProps,
} from './ListProcessDriverAuthorization';

const Container = inject<IListProcessDriverAuthorizationProps>((allStores, p) => ({
  listProcessDriverAuthorizations:
    allStores.rootStore.operations.bulkDriverAuthorizationUpdateOperation.list.listItems,
  lastUpdated:
    allStores.rootStore.operations.bulkDriverAuthorizationUpdateOperation.list.lastUpdated,
  bulkDriverAuthorizationUpdateOperations: allStores.rootStore.operations.bulkDriverAuthorizationUpdateOperation.list.items.slice(),
  hasMoreData:
    allStores.rootStore.operations.bulkDriverAuthorizationUpdateOperation.list.hasMoreItems,
  downloadAttachmentForBatch:
    allStores.rootStore.operationsAttachments.downloadOperationsAttachment,
}))(ListProcessDriverAuthorization);

export default Container;
