import inject from 'src/views/injectFromStore';
import ListEngines, { IListEngineProps } from './ListEngines';

const Container = inject<IListEngineProps>((allStores, p) => ({
  canManageEngines: allStores.rootStore.account.isWorkshopDepartmentMember,
  enginesDetails: allStores.rootStore.workshop.engine.engineDetailsListItems.slice(),
  loadEnginesDetails: allStores.rootStore.workshop.engine.listEnginesDetails,
}))(ListEngines);

export default Container;
