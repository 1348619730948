import { ErrorIcon } from 'src/images/icons';
import { DateTime } from 'luxon';
import styles from './RelatedDefectList.module.scss';

type DefectListItem = Workshop.Domain.Queries.Defect.DefectListItem;

export interface IRelatedDefectListProps {
  relatedDefects: DefectListItem[];
}

const RelatedDefectsList: React.FC<IRelatedDefectListProps> = props => (
  <div className={styles.relatedDefects}>
    <h5>Outstanding Defects</h5>
    <div className={styles.relatedDefectList}>
      {props.relatedDefects.map(d => {
        return (
          <div className={styles.defectContainer} key={d.id}>
            <div className={styles.defectTitle}>
              <span>
                <ErrorIcon />
                {d.createdBy}
              </span>
              <span>{DateTime.fromISO(d.createdOn).toLocaleString(DateTime.DATE_SHORT)}</span>
            </div>
            <p className={styles.defectDescription}>{d.description}</p>
          </div>
        );
      })}
    </div>
  </div>
);

export default RelatedDefectsList;
