import inject from 'src/views/injectFromStore';
import MaintainJobTask, { IMaintainJobTaskProps } from './MaintainTask';
import Omit from 'src/infrastructure/omit';

const Container = inject<Omit<IMaintainJobTaskProps, 'mode'>, IMaintainJobTaskProps>(
  (allStores, p) => ({
    canManageTasks: allStores.rootStore.account.isWorkshopDepartmentMember,
    jobTask: allStores.rootStore.jobTask.jobTaskDetails,
    jobToPrint: allStores.rootStore.job.printJobItem,
    loadPrintJobSheet: allStores.rootStore.job.loadPrintJobSheet,
    activityLogs: allStores.rootStore.jobTask.activityLogs.slice(),
    assets: allStores.rootStore.assets.assetListItems.slice(),
    checklists: allStores.rootStore.assetGroup.checklists.slice(),
    suppliers: allStores.rootStore.jobTask.supplierOptions.slice(),
    allStaffMembers: allStores.rootStore.people.staffMembers.activeStaffMembers.slice(),
    workshopStaff: allStores.rootStore.people.staffMembers.mechanics.slice(),
    partsGroups: allStores.rootStore.workshop.partsGroups.partsGroups.slice(),
    activeJobs: allStores.rootStore.jobTask.activeJobs.slice(),
    jobDetails: allStores.rootStore.jobTask.jobDetails,
    nextService: allStores.rootStore.asset.nextService,
    futureTasks: allStores.rootStore.asset.futureTasks.slice(),
    scheduledTasks: allStores.rootStore.asset.scheduledTasks.slice(),
    loadJobTask: allStores.rootStore.jobTask.getJobTask,
    loadActivityLog: allStores.rootStore.jobTask.getActivityLog,
    clearActivityLog: allStores.rootStore.jobTask.clearActivityLog,
    loadAssetListItems: allStores.rootStore.assets.loadAssetListItems,
    createAdhocJobTask: allStores.rootStore.jobTask.createAdhocJobTask,
    updateJobTask: allStores.rootStore.jobTask.edit,
    jobTaskSubcategories: allStores.rootStore.jobTask.jobTaskSubcategoryOptions,
    loadChecklists: allStores.rootStore.assetGroup.loadChecklists,
    loadSuppliers: allStores.rootStore.jobTask.loadSuppliers,
    loadJobTaskCategories: allStores.rootStore.jobTask.loadJobTaskCategories,
    searchParts: allStores.rootStore.workshop.parts.searchParts,
    loadStaffMembers: allStores.rootStore.people.staffMembers.loadMechanics,
    loadAllStaffMembers: allStores.rootStore.people.staffMembers.loadAllStaffMembers,
    loadPartsGroups: allStores.rootStore.workshop.partsGroups.listPartsGroups,
    onScheduleJobTask: allStores.rootStore.jobTask.scheduleFutureJobTask,
    onScheduleJobTaskWithoutReload: allStores.rootStore.jobTask.scheduleFutureJobTaskWithoutReload,
    onScheduleService: allStores.rootStore.jobTask.scheduleServiceJobTaskWithoutReload,
    onCancelJobTask: allStores.rootStore.jobTask.cancelJobTask,
    onCancelJobTaskWithoutReload: allStores.rootStore.jobTask.cancelJobTaskWithoutReload,
    onLoadActiveJobs: allStores.rootStore.jobTask.getActiveJobs,
    onUnscheduleJobTask: allStores.rootStore.jobTask.unscheduleFutureJobTask,
    onUnscheduleJobTaskWithoutReload:
      allStores.rootStore.jobTask.unscheduleFutureJobTaskWithoutReload,
    onCompleteJobTask: allStores.rootStore.jobTask.completeJobTask,
    onSetToInProgress: allStores.rootStore.jobTask.setToInProgress,
    getJobDetailsForTask: allStores.rootStore.jobTask.getJobDetailsForTask,
    getPartsGroupParts: allStores.rootStore.jobTask.getPartsGroupParts,
    loadAssetWithAssociatedInfo: allStores.rootStore.asset.loadAssetWithAssociatedInfo,
    clearJobTasks: allStores.rootStore.jobTask.clear,
    clearAssetFutureTasks: allStores.rootStore.asset.clearAll,
    clearNextService: allStores.rootStore.asset.clearNextService,
    cubicFaultDetails: allStores.rootStore.assetCubicRegister.cubicFaultDetails.slice(),
    activeCubicFaultDetails: allStores.rootStore.assetCubicRegister.activeCubicFaultDetails.slice(),
    onLoadCubicFaultDetails: allStores.rootStore.assetCubicRegister.loadCubicFaultDetails,
    cubicItems: allStores.rootStore.assetCubicRegister.cubicItems.slice(),
    onLoadCubicItems: allStores.rootStore.assetCubicRegister.loadCubicItems,
    asset: allStores.rootStore.asset.asset,
    assetComponents: allStores.rootStore.asset.assetComponents,
    lastOdometerReading: allStores.rootStore.workshop.assetOdometerReading.lastOdometerReading,
    loadLastOdometerReading:
      allStores.rootStore.workshop.assetOdometerReading.loadLastOdometerReading,
    onLoadAssetComponents: allStores.rootStore.asset.loadAssetComponents,
    onUpdateAssetComponents: allStores.rootStore.asset.updateAssetComponents,

    attachments: allStores.rootStore.workshopAttachments.attachments.slice(),
    clearAttachments: allStores.rootStore.workshopAttachments.clearWorkshopAttachments,
    addAttachmentToTask: allStores.rootStore.workshopAttachments.addWorkshopAttachment,
    loadAttachmentsForTask: allStores.rootStore.workshopAttachments.loadWorkshopAttachmentDetails,
    downloadAttachmentForTask: allStores.rootStore.workshopAttachments.downloadWorkshopAttachment,
    deleteAttachmentFromTask: allStores.rootStore.workshopAttachments.deleteWorkshopAttachment,
    updateAttachmentForTask: allStores.rootStore.workshopAttachments.updateWorkshopAttachment,
    closeJobTaskWithoutFixing: allStores.rootStore.jobTask.closeJobTaskWithoutFixing,

    workshopDepots: allStores.rootStore.workshopStartup.workshopDepots.slice(),
    defaultWorkshopDepot: allStores.rootStore.workshopStartup.defaultWorkshopDepot,
    fullServiceCycle: allStores.rootStore.assetGroup.fullServiceCycle.slice(),
    loadAssetGroupFullServiceCycleForAsset:
      allStores.rootStore.assetGroup.loadAssetGroupFullServiceCycleForAsset,
  })
)(MaintainJobTask);

export default Container;
