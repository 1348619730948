import { types } from 'mobx-state-tree';
import { DailyNotesModel } from './dailyNotes/DailyNotesModel';
import { PartsGroupModel } from './parts/PartsGroupModel';
import { PartsGroupsModel } from './parts/PartsGroupsModel';
import { PartsModel } from './parts/PartsModel';
import { PartTransactionsModel } from './parts/PartTransactionsModel';
import { SupplierModel } from './supplier/SupplierModel';
import { SuppliersModel } from './supplier/SuppliersModel';
import { OwnerModel } from './owner/OwnerModel';
import { OwnersModel } from './owner/OwnersModel';
import { PartCategoriesModel } from './parts/PartCategoriesModel';
import { AssetPartCategoriesModel } from './asset/AssetPartCategoriesModel';
import { AssetPartSubCategoriesModel } from './asset/AssetPartSubCategoriesModel';
import { PartCategoryModel } from './parts/PartCategoryModel';
import { PartChecklistModel } from './parts/PartChecklistModel';
import { ComponentTypesModel } from './componentTypes/ComponentTypesModel';
import { PartModel } from './parts/PartModel';
import { ChecklistsModel } from './checklist/ChecklistsModel';
import { PurchaseOrderModel } from './purchaseOrder/PurchaseOrderModel';
import { ListPurchaseOrdersModel } from './purchaseOrder/ListPurchaseOrdersModel';
import { ListPurchaseTransactionsModel } from './purchaseOrder/ListPurchaseTransactionsModel';
import { EngineModel } from './engine/EngineModel';
import { InventoryModel } from './inventory/InventoryModel';
import { ListTasksModel } from 'src/domain/entities/workshop/jobTask/ListTasksModel';
import { ListOutOfServiceTasksModel } from 'src/domain/entities/workshop/jobTask/ListOutOfServiceTasksModel';
import { ListJobsModel } from 'src/domain/entities/workshop/job/ListJobsModel';
import { TechSpecsModel } from 'src/domain/entities/workshop/techSpecs/TechSpecsModel';
import { AssetOdometerReadingsModel } from 'src/domain/entities/workshop/asset/AssetOdometerReadingsModel';
import { AssetOdometerReadingModel } from 'src/domain/entities/workshop/asset/AssetOdometerReadingModel';
import { WorkshopDashboardModel } from './dashboard/WorkshopDashboardModel';
import { ShiftTemplateModel } from 'src/domain/entities/workshop/shiftTemplates/ShiftTemplateModel';
import { ShiftTemplatesModel } from 'src/domain/entities/workshop/shiftTemplates/ShiftTemplatesModel';
import { RosterTemplateModel } from 'src/domain/entities/workshop/rosterTemplates/RosterTemplateModel';
import { RosterTemplatesModel } from 'src/domain/entities/workshop/rosterTemplates/RosterTemplatesModel';
import { ShiftModel } from 'src/domain/entities/workshop/shifts/ShiftModel';
import { ShiftsModel } from 'src/domain/entities/workshop/shifts/ShiftsModel';
import { RosterModel } from 'src/domain/entities/workshop/rosters/RosterModel';
import { RostersModel } from 'src/domain/entities/workshop/rosters/RostersModel';
import { AuditLogsModel } from './auditLogs/AuditLogsModel';
import { ConflictModel } from 'src/domain/entities/workshop/conflicts/ConflictModel';
import { ListDataFuelUploadModel } from 'src/domain/entities/workshop/dataFuel/ListDataFuelUploadModel';
import { DataFuelModel } from 'src/domain/entities/workshop/dataFuel/ListDataFuelModel';
import { TaskSubcategoriesModel } from './jobTask/TaskSubcategoriesModel';
import { TaskSubcategoryModel } from './jobTask/TaskSubcategoryModel';
import { LabourPriceModel } from './labourPrice/LabourPriceModel';
import { MachineryScheduleTemplateModel } from './machineryScheduleTemplate/MachineryScheduleTemplateModel';
import { ListAssetAttachmentsModel } from './attachment/ListAssetAttachmentsModel';

export const WorkshopModel = types.model('WorkshopModel', {
  auditLogs: types.optional(AuditLogsModel, {}),
  dailyNotes: types.optional(DailyNotesModel, {}),
  checklists: types.optional(ChecklistsModel, {}),
  partsGroup: types.optional(PartsGroupModel, {}),
  partsGroups: types.optional(PartsGroupsModel, {}),
  partCategories: types.optional(PartCategoriesModel, {}),
  assetPartCategories: types.optional(AssetPartCategoriesModel, {}),
  assetPartSubCategories: types.optional(AssetPartSubCategoriesModel, {}),
  partCategory: types.optional(PartCategoryModel, {}),
  part: types.optional(PartModel, {}),
  parts: types.optional(PartsModel, {}),
  partTransactions: types.optional(PartTransactionsModel, {}),
  partChecklists: types.optional(PartChecklistModel, {}),
  supplier: types.optional(SupplierModel, {}),
  suppliers: types.optional(SuppliersModel, {}),
  owner: types.optional(OwnerModel, {}),
  owners: types.optional(OwnersModel, {}),
  componentTypes: types.optional(ComponentTypesModel, {}),
  purchaseOrder: types.optional(PurchaseOrderModel, {}),
  listPurchaseOrders: types.optional(ListPurchaseOrdersModel, {}),
  listPurchaseTransactions: types.optional(ListPurchaseTransactionsModel, {}),
  engine: types.optional(EngineModel, {}),
  inventory: types.optional(InventoryModel, {}),
  listTasks: types.optional(ListTasksModel, {}),
  listOutOfServiceTasks: types.optional(ListOutOfServiceTasksModel, {}),
  listJobs: types.optional(ListJobsModel, {}),
  techSpecs: types.optional(TechSpecsModel, {}),
  assetOdometerReadings: types.optional(AssetOdometerReadingsModel, {}),
  assetOdometerReading: types.optional(AssetOdometerReadingModel, {}),
  dashboard: types.optional(WorkshopDashboardModel, {}),
  shiftTemplate: types.optional(ShiftTemplateModel, {}),
  shiftTemplates: types.optional(ShiftTemplatesModel, {}),
  rosterTemplate: types.optional(RosterTemplateModel, {}),
  rosterTemplates: types.optional(RosterTemplatesModel, {}),
  shift: types.optional(ShiftModel, {}),
  shifts: types.optional(ShiftsModel, {}),
  roster: types.optional(RosterModel, {}),
  rosters: types.optional(RostersModel, {}),
  conflicts: types.optional(ConflictModel, {}),
  dataFuel: types.optional(DataFuelModel, {}),
  dataFuelUploads: types.optional(ListDataFuelUploadModel, {}),
  taskSubcategories: types.optional(TaskSubcategoriesModel, {}),
  taskSubcategory: types.optional(TaskSubcategoryModel, {}),
  labourPrice: types.optional(LabourPriceModel, {}),
  machineryScheduleTemplate: types.optional(MachineryScheduleTemplateModel, {}),
  listAssetAttachments: types.optional(ListAssetAttachmentsModel, {}),
});

type IWorkshopModelType = typeof WorkshopModel.Type;
export interface IWorkshopModel extends IWorkshopModelType {}
