import { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import CrudPage, { CrudPageMode, ICrudPageDef } from 'src/views/components/Page/pages/CrudPage';
import {
  PagePrimarySize,
  PaneType,
  FieldType,
  ActionType,
  IActionData,
} from 'src/views/definitionBuilders/types';
import { ShellModalSize } from 'src/views/components/Shell/ShellModal';
import { TrashIcon, PlusIcon } from 'src/images/icons';
import { getSubmitCloseModalActionGroupDef } from 'src/views/definitionBuilders/common';
import { IFormApiWithoutState } from 'src/views/components/Page/forms/base';
import getAddStaffMembersByRoleModalDef from '../../common/getAddStaffMembersByRoleModalDef';

type StaffMemberDto = Common.Dtos.StaffMemberDto;
type CreateNoteCommand = People.Domain.Commands.Note.CreateNote.CreateNoteCommand;
type UpdateNoteCommand = People.Domain.Commands.Note.UpdateNote.UpdateNoteCommand;
type NoteItem = People.Domain.Queries.ViewNote.NoteItem;
type RoleItem = People.Domain.Queries.Role.RoleItem;

export interface IMaintainNoteProps {
  canManageStaffMemberNotes: boolean;
  mode: CrudPageMode;

  loadStaffMembers: () => Promise<void>;
  staffMembers: StaffMemberDto[];
  activeStaffMembers: StaffMemberDto[];
  allStaffWithDriverAuthorisation: StaffMemberDto[];
  createNote: (command: CreateNoteCommand) => Promise<void>;

  loadNote: (id: string) => Promise<void>;
  note: NoteItem | undefined;
  updateNote: (command: UpdateNoteCommand) => Promise<void>;
  deleteNote: (noteId: string) => Promise<void>;
  loadAllRoles: () => Promise<void>;
  roles: RoleItem[];
}

interface INoteForm {
  content: string;
  day: string;
  staffMembers: string[];
}

interface IMaintainNoteRouteParams {
  id: string;
}

type InternalProps = IMaintainNoteProps & RouteComponentProps<IMaintainNoteRouteParams>;

const MaintainNote: React.FC<InternalProps> = (props: InternalProps) => {
  const [formApi, setFormApi] = useState<IFormApiWithoutState | undefined>(undefined);

  useEffect(() => {
    props.loadStaffMembers();
  }, []);

  const isCreateMode = props.mode === 'create';
  const isUpdateMode = props.mode === 'update';
  const noteId = props.match.params.id;

  const handlePreSubmitForCreate = (note: INoteForm): CreateNoteCommand => {
    return {
      content: note.content,
      day: note.day,
      staffMembers: note.staffMembers,
    };
  };

  const handlePreSubmitForUpdate = (note: NoteItem): UpdateNoteCommand => {
    return {
      id: noteId,
      content: note.content,
      day: note.day,
      staffMembers: note.staffMembers,
    };
  };

  const addStaffMembers = (data: IActionData, staffMembersToAdd: StaffMemberDto[]) => {
    let loadedStaffMembers = data.panelValue?.staffMembers ?? [];

    let loadedStaffMembersDtos = loadedStaffMembers.map((id: string) => {
      return {
        id: id,
      };
    }) as StaffMemberDto[];

    loadedStaffMembersDtos = loadedStaffMembersDtos.concat(staffMembersToAdd);

    const targetIds = loadedStaffMembersDtos && new Set(loadedStaffMembersDtos.map(x => x.id));
    formApi!.setValue(['staffMembers'], Array.from(targetIds));
  };

  const getPageDef = (updating: boolean): ICrudPageDef => {
    const { canManageStaffMemberNotes } = props;

    return {
      primarySize: PagePrimarySize.threeQuarters,
      primarySection: {
        title: isCreateMode ? 'Create a Note' : 'Manage Note',
        getApi: api => setFormApi(api),
        primaryActions: [
          {
            actions: [
              {
                actionType: ActionType.actionCollection,
                hidden: updating || !isUpdateMode || !canManageStaffMemberNotes,
                actionGroups: [
                  {
                    actions: [
                      {
                        actionType: ActionType.modalActionButton,
                        label: 'Delete Note',
                        icon: <TrashIcon />,
                        modalSize: ShellModalSize.oneQuarter,
                        modalDef: modalDefApi => ({
                          title: 'Delete Note',
                          asForm: true,
                          panels: [
                            {
                              panes: [
                                {
                                  paneType: PaneType.customPane,
                                  render: () => <span>Are you sure you want to delete note ?</span>,
                                },
                              ],
                            },
                          ],
                          secondaryActions: [getSubmitCloseModalActionGroupDef('Ok')],
                          onFormSubmit: () => props.deleteNote(noteId),
                        }),
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],

        panels: [
          {
            panes: [
              {
                paneType: PaneType.formFieldsPane,
                columnCount: 2,
                fields: [
                  {
                    fieldType: FieldType.dateField,
                    label: 'Date',
                    dataAddr: 'day',
                    mandatory: true,
                  },
                ],
              },
              {
                paneType: PaneType.formFieldsPane,
                columnCount: 1,
                fields: [
                  {
                    fieldType: FieldType.textAreaField,
                    label: 'Notes',
                    dataAddr: 'content',
                    mandatory: true,
                  },
                  {
                    fieldType: FieldType.selectMultiField,
                    label: 'Staff Member',
                    dataAddr: 'staffMembers',
                    optionItems: props.staffMembers,
                    valueKey: 'id',
                    descriptionKey: 'name',
                    useValueOnly: true,
                    mandatory: true,
                    includeKey: 'active',
                  },
                  {
                    fieldType: FieldType.readonlyField,
                    label: 'Added by',
                    dataAddr: 'createdBy',
                    hidden: isCreateMode,
                  },
                ],
              },
              {
                paneType: PaneType.actionListPane,
                hidden: updating || isUpdateMode,
                actionGroups: [
                  {
                    actions: [
                      {
                        actionType: ActionType.actionButton,
                        label: 'Add All Staff',
                        level: 'primary',
                        icon: <PlusIcon />,
                        onClick: data => addStaffMembers(data, props.activeStaffMembers),
                      },
                      {
                        actionType: ActionType.actionButton,
                        label: 'Add All Drivers',
                        level: 'primary',
                        icon: <PlusIcon />,
                        onClick: data =>
                          addStaffMembers(data, props.allStaffWithDriverAuthorisation),
                      },
                      {
                        actionType: ActionType.modalActionButton,
                        label: 'Add Staff By Role',
                        level: 'primary',
                        icon: <PlusIcon />,
                        modalSize: ShellModalSize.oneQuarter,
                        modalDef: getAddStaffMembersByRoleModalDef(
                          props.roles,
                          props.activeStaffMembers,
                          formApi
                        ),
                        onOpenModal: _ => props.loadAllRoles(),
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
        onFormPreSubmit: isCreateMode ? handlePreSubmitForCreate : handlePreSubmitForUpdate,
        onFormSubmit: isCreateMode ? props.createNote : props.updateNote,
      },
    };
  };

  const { mode, loadNote, note, canManageStaffMemberNotes } = props;
  return (
    <CrudPage
      def={({ updating }) => getPageDef(updating)}
      mode={mode}
      isEditingForbidden={!canManageStaffMemberNotes}
      onLoadData={() => loadNote(noteId)}
      data={note}
      createDefaultData={{ activeStaffMembers: [] }}
    />
  );
};

export default MaintainNote;
