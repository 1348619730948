import inject from 'src/views/injectFromStore';
import Home, { IHomeProps } from './Home';

const Container = inject<IHomeProps>((allStores, p) => ({
  uiRouteWorkshop: allStores.rootStore.history.uiRoutes.workshop.pathPattern,
  uiRouteSales: allStores.rootStore.history.uiRoutes.sales.pathPattern,
  uiRouteOperations: allStores.rootStore.history.uiRoutes.operations.pathPattern,
  uiRoutePeople: allStores.rootStore.history.uiRoutes.people.pathPattern,
  uiRouteAccounting: allStores.rootStore.history.uiRoutes.accounting.pathPattern,
  uiRouteCompliance: allStores.rootStore.history.uiRoutes.compliance.pathPattern,
  uiRouteDocumentation: allStores.rootStore.history.uiRoutes.documentation.pathPattern,
  workshopDefaultId: allStores.rootStore.workshopStartup.defaultWorkshopDepot?.id,
}))(Home);

export default Container;
