import { types, flow, getRoot } from 'mobx-state-tree';
import { getAjax } from 'src/domain/services/storeEnvironment';
import { IRootStoreModel } from 'src/domain/entities/RootStoreModel';

type RosterItem = Operations.Domain.Queries.ViewRoster.RosterItem;
type CreateRosterCommand = Operations.Domain.Commands.Roster.CreateRoster.CreateRosterCommand;

export const RosterModel = types
  .model('RostertModel', {
    roster: types.maybe(types.frozen<RosterItem>()),
  })
  .actions(self => {
    const ajax = getAjax(self);
    const root = getRoot(self) as IRootStoreModel;

    const createRoster = flow(function*(command: CreateRosterCommand) {
      const id = yield ajax.urban.rosters.createRoster(command);
      self.roster = undefined;
      root.history.push(`/operations/rosters/${id}`);
    });

    const checkForUniqueRosterNumber = flow(function*(rosterNumber: string) {
      return yield ajax.urban.rosters.checkForUniqueRosterNumber(rosterNumber);
    });

    const loadRoster = flow(function*(rosterId: string) {
      self.roster = yield ajax.urban.rosters.viewRoster(rosterId);
    });

    const updateRoster = flow(function*(
      command: Operations.Domain.Commands.Roster.UpdateRoster.UpdateRosterCommand
    ) {
      yield ajax.urban.rosters.updateRoster(command);
      yield loadRoster(command.id);
    });

    const deleteRoster = flow(function*(rosterId: string) {
      yield ajax.urban.rosters.deleteRoster(rosterId);

      self.roster = undefined;
      root.history.push(`/operations/rosters?defaultFilter=true`);
    });

    return {
      createRoster,
      checkForUniqueRosterNumber,
      loadRoster,
      updateRoster,
      deleteRoster,
    };
  });
