import { DateTime } from 'luxon';
import { observer } from 'mobx-react-lite';
import { Link } from 'react-router-dom';
import { allSmsMessageStatus, SmsMessageStatus } from 'src/api/enums';
import { useRootStore } from 'src/domain/entities/RootStoreModel';
import { CheckIcon, ErrorIcon } from 'src/images/icons';
import memoizeOne from 'src/infrastructure/memoizeOne';
import { FieldInfoTooltip } from 'src/views/components/FieldInfoTooltip';
import ListPage, { IListPageDef } from 'src/views/components/Page/pages/ListPage';
import TaskCardItem from 'src/views/components/TaskCard/TaskCardItem';
import {
  FieldDefs,
  FieldType,
  PagePrimarySize,
  PaneType,
} from 'src/views/definitionBuilders/types';
import './ListSms.scss';

type ListSmsItem = Common.Sms.ListSmsItem;
type StaffMemberItem = Common.Sms.StaffMemberItem;

const ErrorTooltip = () => (
  <FieldInfoTooltip className="tooltipicon" icon={<ErrorIcon color="red" />}>
    Failed
  </FieldInfoTooltip>
);

const SuccessTooltip = () => (
  <FieldInfoTooltip className="tooltipicon" icon={<CheckIcon color="green" />}>
    Delivered
  </FieldInfoTooltip>
);

const ListSms: React.FC = observer(() => {
  const rootStore = useRootStore();
  const smsModel = rootStore.people.ListSms;

  const getFilterFieldDefs = memoizeOne(() => {
    return {
      dateFrom: {
        fieldType: FieldType.dateField,
        label: 'Date From',
        dataAddr: 'dateFrom',
        onBlur: api => {
          if (!api.formValues.dateTo) {
            api.setFormValue(['dateTo'], api.fieldData.fieldValue);
          }
          api.validateField(['dateTo']);
        },
      } as FieldDefs,
      dateTo: {
        fieldType: FieldType.dateField,
        label: 'Date To',
        dataAddr: 'dateTo',
        validate: d => {
          if (!d.fieldValue || !d.parentValue.dateFrom) {
            return undefined;
          }
          const from = DateTime.fromISO(d.parentValue.dateFrom);
          const to = DateTime.fromISO(d.fieldValue);
          return from > to ? 'Date To cannot be earlier than Date From' : undefined;
        },
      } as FieldDefs,
      status: {
        fieldType: FieldType.selectMultiField,
        label: 'Status',
        dataAddr: 'status',
        optionItems: allSmsMessageStatus.slice(),
        descriptionKey: 'description',
        valueKey: 'value',
        useValueOnly: true,
      } as FieldDefs,
    };
  });

  const generateSmsGroups = (staffMembers: StaffMemberItem[]) => {
    const success: StaffMemberItem[] = [];
    const pending: StaffMemberItem[] = [];
    const failed: StaffMemberItem[] = [];
    const notified: StaffMemberItem[] = [];

    staffMembers.forEach(member => {
      if (member.status === SmsMessageStatus.Delivered) {
        success.push(member);
      } else if (member.status === SmsMessageStatus.Pending) {
        pending.push(member);
      } else if (member.status === SmsMessageStatus.Failed) {
        if (member.staffMemberNotifiedDueToFailure) {
          notified.push(member);
        } else {
          failed.push(member);
        }
      }
    });

    return {
      success: success,
      pending: pending,
      failed: failed,
      notified: notified,
    };
  };

  const getPageDef = memoizeOne(
    (): IListPageDef => {
      const filterFieldDefsLookup = getFilterFieldDefs();
      const today = DateTime.local().toISODate();
      const endOfToday = DateTime.local()
        .endOf('day')
        .toISODate();
      return {
        primaryTitle: 'SMS',
        primarySize: PagePrimarySize.full,
        onLoadData: smsModel.listItems,
        externalSearch: true,
        createLink: rootStore.account.canCreateOrUpdateRecords
          ? TaskCardItem.people.createSms
          : undefined,
        hasMoreData: smsModel.hasMoreItems,

        primaryFields: [
          {
            fieldType: FieldType.dateField,
            dataAddr: 'sentOn',
            label: 'Sent On',
            linkTo: d => `/people/sms/${d.parentValue.id}`,
            columnWidth: '10em',
          },
          {
            fieldType: FieldType.textAreaField,
            dataAddr: 'content',
            label: 'Content',
          },
          {
            fieldType: FieldType.readonlyField,
            dataAddr: 'staffMembers',
            label: 'Delivery Status',
            formatReadonly: data => {
              const Sms = data.parentValue as ListSmsItem;
              const groups = generateSmsGroups(Sms.staffMembers);
              const renderGroup = (
                group: StaffMemberItem[],
                titleLines: string[],
                tooltip?: 'error' | 'success' | undefined
              ) => {
                return (
                  <>
                    {group.length ? (
                      <>
                        {titleLines.map((title, i) => (
                          <div key={i}>
                            <strong>{title}</strong>
                          </div>
                        ))}
                        {group.map(f => (
                          <div key={f.id}>
                            {tooltip === 'success' ? (
                              <SuccessTooltip />
                            ) : tooltip === 'error' ? (
                              <ErrorTooltip />
                            ) : null}
                            <Link to={`/people/staff-members/${f.id}`}>{f.fullName}</Link>
                          </div>
                        ))}
                      </>
                    ) : null}
                  </>
                );
              };
              return (
                <div>
                  {renderGroup(groups.failed, ['SMS Failed', 'Please make contact'], 'error')}
                  {renderGroup(groups.pending, ['Pending'])}
                  {renderGroup(groups.success, ['Successful'], 'success')}
                  {renderGroup(
                    groups.notified,
                    ['SMS Failed, Staff member has been contacted'],
                    'success'
                  )}
                </div>
              );
            },
            columnWidth: '15em',
          },
          {
            fieldType: FieldType.readonlyField,
            dataAddr: 'createdBy',
            label: 'Added by',
            columnWidth: '12em',
          },
        ],
        filterAction: {
          defaultValues: {
            dateFrom: today,
            dateTo: endOfToday,
          },
          filterFields: Object.keys(filterFieldDefsLookup).map(k => filterFieldDefsLookup[k]),
          filterDef: filterDefApi => [
            {
              panes: [
                {
                  paneType: PaneType.formFieldsPane,
                  columnCount: 2,
                  fields: [filterFieldDefsLookup.dateFrom, filterFieldDefsLookup.dateTo],
                },
                {
                  paneType: PaneType.formFieldsPane,
                  fields: [filterFieldDefsLookup.status],
                },
              ],
            },
          ],
        },
      };
    }
  );

  return <ListPage className="list-Sms-component" data={smsModel.items} def={getPageDef()} />;
});

export default ListSms;
