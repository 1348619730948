import { Component } from 'react';
import { Subject } from 'rxjs/Subject';
import { Subscription } from 'rxjs/Subscription';

interface IFocusWrapperProps {
  onFocus?: () => void;
  onBlur?: () => void;
}

class FocusWrapper extends Component<IFocusWrapperProps> {
  private readonly focusBlurEvent$ = new Subject<'focus' | 'blur'>();
  private readonly subscription = new Subscription();

  componentDidMount() {
    this.subscription.add(
      this.focusBlurEvent$
        .buffer(this.focusBlurEvent$.debounceTime(0)) // when tabbing between inner fields, the blur/focus happen together
        .subscribe(buffer => {
          const focuses = buffer.filter(i => i === 'focus');
          const blurs = buffer.filter(i => i === 'blur');
          // If we had more blurs, then we've left the containing div
          if (focuses.length < blurs.length) {
            this.props.onBlur && this.props.onBlur();
          }
          // If we had more focuses, then we've entered the containing div
          if (focuses.length > blurs.length) {
            this.props.onFocus && this.props.onFocus();
          }
        })
    );
  }

  componentWillUnmount() {
    this.subscription.unsubscribe();
  }

  render() {
    const { children } = this.props;
    return (
      <div
        onFocusCapture={e => this.focusBlurEvent$.next('focus')}
        onBlurCapture={e => this.focusBlurEvent$.next('blur')}>
        {children}
      </div>
    );
  }
}

export default FocusWrapper;
