import { PaneType, ModalDefBuilder, FieldType } from 'src/views/definitionBuilders/types';
import * as EmailValidator from 'email-validator';
import { standardTextSanitisers } from 'src/views/componentBuilders/buildLabelledText';
import { getSubmitCloseModalActionGroupDef } from 'src/views/definitionBuilders/common';

type CreateCustomerContactCommand = Operations.Domain.Commands.Customer.CreateCustomerContactCommand;

export default function getCreateCustomerContactModalDef(
  onCreateCustomerContact: (command: CreateCustomerContactCommand) => Promise<number>
): ModalDefBuilder {
  return modalDefApi => ({
    title: 'Create a Customer Contact',
    asForm: true,
    panels: [
      {
        panes: [
          {
            paneType: PaneType.formFieldsPane,
            fields: [
              {
                fieldType: FieldType.textField,
                label: 'Title',
                dataAddr: 'title',
                maxLength: 20,
              },
              {
                fieldType: FieldType.textField,
                label: 'First Name',
                dataAddr: 'firstName',
                maxLength: 200,
                mandatory: true,
              },
              {
                fieldType: FieldType.textField,
                label: 'Last Name',
                dataAddr: 'lastName',
                maxLength: 200,
              },
              {
                fieldType: FieldType.textField,
                label: 'Phone',
                dataAddr: 'phone',
                maxLength: 20,
                onChange: api =>
                  api.setFormValue(
                    api.fieldDataAddr,
                    standardTextSanitisers.numericSanitiser(api.fieldData.fieldValue as string)
                  ),
              },
              {
                fieldType: FieldType.textField,
                label: 'Fax',
                dataAddr: 'fax',
                maxLength: 20,
                onChange: api =>
                  api.setFormValue(
                    api.fieldDataAddr,
                    standardTextSanitisers.numericSanitiser(api.fieldData.fieldValue as string)
                  ),
              },
              {
                fieldType: FieldType.textField,
                label: 'Mobile',
                dataAddr: 'mobile',
                maxLength: 20,
                onChange: api =>
                  api.setFormValue(
                    api.fieldDataAddr,
                    standardTextSanitisers.numericSanitiser(api.fieldData.fieldValue as string)
                  ),
              },
              {
                fieldType: FieldType.textField,
                label: 'Email',
                dataAddr: 'email',
                maxLength: 200,
                validate: email =>
                  !!email.fieldValue && !EmailValidator.validate(email.fieldValue)
                    ? `Email must be a valid email`
                    : undefined,
              },
            ],
          },
        ],
      },
    ],
    secondaryActions: [getSubmitCloseModalActionGroupDef('Create')],
    onFormSubmit: (values: CreateCustomerContactCommand) => {
      return onCreateCustomerContact({
        ...values,
        customerId: modalDefApi.parentFormApi.getValue('customer').customerId,
      }).then(id => {
        modalDefApi.parentFormApi.setValue('contact', {
          contactId: id,
          requiresLoad: true,
        });
      });
    },
  });
}
