import Card from './Card';
import styles from './Card.module.scss';

interface IButtonCardProps {
  className?: string;
  onClick: () => void;
}

const ButtonCard: React.FC<IButtonCardProps> = ({ children, className, onClick }) => (
  <div onClick={onClick}>
    <Card className={className}>
      <div className={styles.buttonCard}>{children}</div>
    </Card>
  </div>
);

export default ButtonCard;
