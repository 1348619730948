import { saveAs } from 'file-saver';
import { DateTime } from 'luxon';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { allAssetStatus, AssetStatus } from 'src/api/enums';
import { useRootStore } from 'src/domain/entities/RootStoreModel';
import { ExcelIcon } from 'src/images/icons';
import ListPage, { IListPageDef } from 'src/views/components/Page/pages/ListPage';
import { TaskCardItem, toActionLinkDef } from 'src/views/components/TaskCard';
import { ActionType, ShellModalSize } from 'src/views/definitionBuilders/types';
import { FieldDefs, FieldType } from 'src/views/definitionBuilders/types/field';
import { PaneType } from 'src/views/definitionBuilders/types/pane';
import withQueryParams, { IQueryParamsProps } from 'src/views/hocs/withQueryParams';
import PrimaryTitle from 'src/views/components/Page/PrimaryTitle/PrimaryTitle';
import getFuelConsumptionReportModalDef from './getExportFuelConsumptionModalDef';
import getFuelReportReportModalDef from './getExportFuelReportModalDef';
import getExportAssetCostReportModalDef from './getExportAssetCostReportModalDef';

type ListAssetsQuery = Workshop.Domain.Queries.ListAssets.ListAssetsQuery;
type AssetListItem = Workshop.Domain.Queries.ListAssets.AssetListItem;
type ExportAssetFuelToExcelQuery = Workshop.Domain.Queries.ExportAssetFuelToExcel.ExportAssetFuelToExcelQuery;
type ExportFuelConsumptionToExcelQuery = Workshop.Domain.Queries.ExportFuelConsumptionReportToExcel.ExportFuelConsumptionReportToExcelQuery;
type Owners = Workshop.Domain.Queries.Owners.GetAllOwners.OwnerSelectListItem;
type Depots = Common.Queries.Workshop.GetWorkshopDepots.WorkshopDepotDto;
type AssetItem = Workshop.Domain.Queries.AssetItem;

const primaryFields: IListPageDef['primaryFields'] = [
  {
    fieldType: FieldType.textField,
    dataAddr: 'name',
    label: 'Name',
    linkTo: d => `/workshop/assets/${d.parentValue.id}`,
    formatReadonly: data => {
      var asset = data.parentValue as AssetListItem;
      return (
        asset && (
          <span>
            {asset.isDecommissioned
              ? `${asset.name} (Decommissioned on ${DateTime.fromISO(
                  asset.decommissionedOn!
                ).toLocaleString(DateTime.DATE_SHORT)})`
              : asset.name}
          </span>
        )
      );
    },
  },
  {
    fieldType: FieldType.readonlyField,
    dataAddr: ['assetCategory', 'description'],
    label: 'Category',
  },
  {
    fieldType: FieldType.textField,
    dataAddr: ['assetSubcategory', 'description'],
    label: 'Subcategory',
  },
  {
    fieldType: FieldType.textField,
    dataAddr: ['vehicleType', 'description'],
    label: 'Vehicle Type',
  },
  {
    fieldType: FieldType.textField,
    dataAddr: ['assetGroupDescription'],
    label: 'Asset Group',
  },
  {
    fieldType: FieldType.textField,
    dataAddr: ['assetHousingLocation', 'description'],
    label: 'Garaged At',
  },
  {
    fieldType: FieldType.textField,
    dataAddr: 'engineModel',
    label: 'Engine Model',
  },
];

const ListAsset: React.FC<IQueryParamsProps<ListAssetsQuery>> = observer(
  (props: IQueryParamsProps<ListAssetsQuery>) => {
    const rootStore = useRootStore();
    const workshopModel = rootStore.workshop;
    const listAssetModel = rootStore.listAssets;
    const assetModel = rootStore.asset;
    const assetsModel = rootStore.assets;
    const [isLoadingFilters, setIsLoadingFilters] = useState<boolean>(true);

    useEffect(() => {
      Promise.all([
        assetModel.loadAssetCategories(),
        assetModel.loadAssetSubcategories(),
        rootStore.assetGroups.listAssetGroups(),
        assetModel.loadAssetHousedAtLocations(),
        workshopModel.engine.listEngines(),
        workshopModel.owners.loadAllOwners(),
        assetsModel.loadAssetFuelLocations(),
        assetsModel.loadAssetListItems(),
      ]).then(() => setIsLoadingFilters(false));
    }, []);

    const saveSpreadsheet = () => {
      const fileName = `Assets_${DateTime.local().toFormat('yyyyMMddHHmm')}.xlsx`;
      return listAssetModel
        .exportToExcel(props.getQueryParams())
        .then(blob => saveAs(blob, fileName));
    };

    const saveComponentsSpreadsheet = () => {
      const fileName = `Assets_Components_${DateTime.local().toFormat('yyyyMMddHHmm')}.xlsx`;
      return listAssetModel
        .exportComponentsToExcel(props.getQueryParams())
        .then(blob => saveAs(blob, fileName));
    };

    const saveServicesSpreadsheet = () => {
      const fileName = `Assets_Service_Details_${DateTime.local().toFormat('yyyyMMddHHmm')}.xlsx`;
      return listAssetModel
        .exportServicesToExcel(props.getQueryParams())
        .then(blob => saveAs(blob, fileName));
    };

    const savePartsSpreadsheet = () => {
      const fileName = `Assets_Oils_Filters_Belts_${DateTime.local().toFormat(
        'yyyyMMddHHmm'
      )}.xlsx`;
      return listAssetModel
        .exportAssetPartRegisterToExcel(props.getQueryParams())
        .then(blob => saveAs(blob, fileName));
    };

    const saveFuelsSpreadsheet = (query: ExportAssetFuelToExcelQuery) => {
      const fileName = `Assets_Fuels_${DateTime.local().toFormat('yyyyMMddHHmm')}.xlsx`;
      return listAssetModel.exportFuelsToExcel(query).then(blob => saveAs(blob, fileName));
    };

    const saveConsumptionSpreadsheet = async (query: ExportFuelConsumptionToExcelQuery) => {
      const fileName = `Assets_Fuel_Consumption_${DateTime.local().toFormat('yyyyMMddHHmm')}.xlsx`;
      return listAssetModel
        .exportFuelConsumptionsToExcel(query)
        .then(blob => saveAs(blob, fileName));
    };

    const saveMaintenanceSpreadsheet = async (dateFrom: string, dateTo: string) => {
      const fileName = `Assets_Maintenance_Costs_${DateTime.local().toFormat('yyyyMMddHHmm')}.xlsx`;
      const query = {
        ...props.getQueryParams(),
        dateFrom: dateFrom,
        dateTo: dateTo,
      };

      return listAssetModel
        .exportAssetMaintenanceCostsToExcelQuery(query)
        .then(blob => saveAs(blob, fileName));
    };

    const saveAssetCubicsSpreadsheet = () => {
      const fileName = `Asset_Cubics_${DateTime.local().toFormat('yyyyMMddHHmm')}.xlsx`;
      return rootStore.assetCubicRegister
        .generateExcelForAllAssets()
        .then(blob => saveAs(blob, fileName));
    };

    const getRowKey: IListPageDef['rowKey'] = data => {
      return data.itemValue.id;
    };

    const assetItems = assetsModel.assetListItems.map(asset => ({
      id: asset.id,
      name: asset.name,
    })) as AssetItem[];

    const owners = workshopModel.owners.allOwners.map(owner => ({
      id: owner.id,
      name: owner.name,
    })) as Owners[];

    const depots = assetModel.housedAtLocations.map(depot => ({
      id: depot.id,
      description: depot.description,
    })) as Depots[];

    const getPageDef = (): IListPageDef => {
      const secondarySections: IListPageDef['secondarySections'] = [
        {
          title: 'Navigation',
          panels: [
            {
              title: 'Top Tasks',
              panes: [
                {
                  paneType: PaneType.navListPane,
                  links: [
                    TaskCardItem.workshop.assetAttachments,
                    TaskCardItem.odometerReadings,
                    TaskCardItem.workshop.dataFuel,
                    TaskCardItem.unallocatedAssets,
                  ].map(toActionLinkDef),
                },
              ],
            },
          ],
        },
      ];
      const filterFieldDefs: FieldDefs[] = [
        {
          fieldType: FieldType.selectMultiField,
          label: 'Category',
          dataAddr: 'categories',
          useValueOnly: true,
          valueKey: 'id',
          descriptionKey: 'description',
          optionItems: assetModel.categories,
        },
        {
          fieldType: FieldType.selectMultiField,
          label: 'Subcategory',
          dataAddr: 'subcategories',
          useValueOnly: true,
          valueKey: 'id',
          descriptionKey: 'description',
          optionItems: assetModel.subcategories.slice(),
        },
        {
          fieldType: FieldType.selectMultiField,
          label: 'Asset Group',
          dataAddr: 'assetGroups',
          useValueOnly: true,
          valueKey: 'id',
          descriptionKey: 'description',
          optionItems: rootStore.assetGroups.activeAssetGroups.slice(),
        },
        {
          fieldType: FieldType.selectMultiField,
          label: 'Garaged At',
          dataAddr: 'housingLocations',
          useValueOnly: true,
          valueKey: 'id',
          descriptionKey: 'description',
          optionItems: assetModel.housedAtLocations.slice(),
        },
        {
          fieldType: FieldType.selectMultiField,
          label: 'Engine Model',
          dataAddr: 'engineModels',
          useValueOnly: true,
          valueKey: 'id',
          descriptionKey: 'model',
          optionItems: workshopModel.engine.engineListItems.slice(),
        },
        {
          fieldType: FieldType.selectMultiField,
          label: 'Status',
          dataAddr: 'statuses',
          useValueOnly: true,
          valueKey: 'value',
          descriptionKey: 'description',
          optionItems: allAssetStatus,
        },
        {
          fieldType: FieldType.yesNoField,
          dataAddr: 'hasCubicChanges',
          label: 'With Cubic Form to be sent',
        },
        {
          fieldType: FieldType.selectMultiField,
          label: 'Owner',
          dataAddr: 'owners',
          useValueOnly: true,
          valueKey: 'id',
          descriptionKey: 'name',
          optionItems: workshopModel.owners.allOwners.slice(),
        },
      ];

      return {
        primaryTitle: (
          <PrimaryTitle
            title="Assets"
            link="https://www.notion.so/Assets-75928b0be69140b0adb58161aa0d692e"></PrimaryTitle>
        ),
        onLoadData: listAssetModel.listItems,
        externalSearch: true,
        createLink: rootStore.account.isWorkshopDepartmentMember
          ? TaskCardItem.createAsset
          : undefined,
        hasMoreData: listAssetModel.hasMoreItems,
        rowKey: getRowKey,
        primaryActions: [
          {
            actions: [
              {
                actionType: ActionType.actionCollection,
                actionGroups: [
                  {
                    actions: [
                      {
                        actionType: ActionType.actionButton,
                        label: 'Export to Excel',
                        icon: <ExcelIcon fixedWidth />,
                        onClick: saveSpreadsheet,
                      },
                      {
                        actionType: ActionType.actionButton,
                        label: 'Export Oils, Filters & Belts to Excel',
                        icon: <ExcelIcon fixedWidth />,
                        onClick: savePartsSpreadsheet,
                      },
                      {
                        actionType: ActionType.actionButton,
                        label: 'Export Service Details to Excel',
                        icon: <ExcelIcon fixedWidth />,
                        onClick: saveServicesSpreadsheet,
                      },
                      {
                        actionType: ActionType.actionButton,
                        label: 'Export CUBIC Info for All Assets',
                        icon: <ExcelIcon fixedWidth />,
                        onClick: saveAssetCubicsSpreadsheet,
                      },
                      {
                        actionType: ActionType.actionButton,
                        label: 'Export Components Register to Excel',
                        icon: <ExcelIcon fixedWidth />,
                        onClick: saveComponentsSpreadsheet,
                      },
                      {
                        actionType: ActionType.modalActionButton,
                        label: 'Export Fuel Report to Excel',
                        icon: <ExcelIcon fixedWidth />,
                        modalSize: ShellModalSize.oneQuarter,
                        modalDef: getFuelReportReportModalDef(
                          assetItems,
                          owners,
                          depots,
                          saveFuelsSpreadsheet
                        ),
                      },
                      {
                        actionType: ActionType.modalActionButton,
                        label: 'Export Fuel Consumption Report to Excel',
                        icon: <ExcelIcon fixedWidth />,
                        modalSize: ShellModalSize.oneQuarter,
                        modalDef: getFuelConsumptionReportModalDef(
                          assetItems,
                          saveConsumptionSpreadsheet
                        ),
                      },
                      {
                        actionType: ActionType.modalActionButton,
                        label: 'Export Maintenance Costs Report to Excel',
                        icon: <ExcelIcon fixedWidth />,
                        modalSize: ShellModalSize.oneQuarter,
                        modalDef: getExportAssetCostReportModalDef(saveMaintenanceSpreadsheet),
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
        primaryFields,
        filterAction: {
          disabled: isLoadingFilters,
          defaultValues: {
            statuses: [AssetStatus.Active],
          },
          filterFields: filterFieldDefs,
        },
        secondarySections,
      };
    };
    return (
      <ListPage
        className="list-assets-component"
        data={listAssetModel.items.slice()}
        def={getPageDef()}
      />
    );
  }
);

export default withQueryParams(ListAsset);
