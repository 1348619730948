import { Component } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import {
  PagePrimarySize,
  PaneType,
  FieldType,
  ShellModalSize,
  ISectionDef,
  PaneDefs,
  FieldDefs,
} from 'src/views/definitionBuilders/types';
import CrudPage, { CrudPageMode, ICrudPageDef } from 'src/views/components/Page/pages/CrudPage';
import { ActionType } from 'src/views/definitionBuilders/types';

import isURL from 'validator/lib/isURL';
import { DateTime } from 'luxon';
import humanizeDuration from 'humanize-duration';
import { getSubmitCloseModalActionGroupDef } from 'src/views/definitionBuilders/common';
import { validateDateTimeIsNotLessThan } from 'src/infrastructure/dateUtils';
import { EditIcon, TrashIcon } from 'src/images/icons';
import { historyTypeDescription, HistoryType } from 'src/api/enums';
import { DisplayReportableEventCategory } from 'src/views/routes/compliance/reportableEvent/DisplayReportableEventCategory';
import { DisplayNote } from '../DisplayNote';
import { DisplayTransLinkCategoryWithExplanation } from '../DisplayTransLinkCategoryWithExplanation';
import { IAutocompleteResult } from 'src/domain/baseTypes';
import { IFormApiWithoutState } from 'src/views/components/Page/forms/base';

type ReportableEvent = Operations.Domain.Queries.ListReportableEvents.ReportableEventDto;
type ReportableEventCategory = Operations.Domain.Queries.ListReportableEvents.ReportableEventCategoryDto;
type ReportableEventContract = Operations.Domain.Queries.ListAllReportableEventContracts.ReportableEventContractDto;
type ReportableEventHistory = Operations.Domain.Queries.ListReportableEvents.ReportableEventHistoryDto;
type StaffMemberDto = Common.Dtos.StaffMemberDto;
type StaffMemberName = Common.Dtos.StaffMemberNameDto;
type CreateReportableEventCommand = Operations.Domain.Commands.ReportableEvent.CreateReportableEvent.CreateReportableEventCommand;
type UpdateReportableEventCommand = Operations.Domain.Commands.ReportableEvent.UpdateReportableEvent.UpdateReportableEventCommand;
type UpdateReportableEventVehicleInformationCommand = Operations.Domain.Commands.ReportableEvent.UpdateReportableEventVehicleInformation.UpdateReportableEventVehicleInformationCommand;
type UpdateReportableEventInternalInformationCommand = Operations.Domain.Commands.ReportableEvent.UpdateReportableEventInternalInformation.UpdateReportableEventInternalInformationCommand;
type AssetItem = Common.Queries.Workshop.GetSimpleFleetAssetList.SimpleAssetItem;

export interface IMaintainReportableEventProps {
  mode: CrudPageMode;
  canManageReportableEvents: boolean;

  loadReportableEvent: (id: string) => Promise<void>;
  reportableEvent: ReportableEvent | undefined;
  createReportableEvent: (command: CreateReportableEventCommand) => Promise<void>;
  updateReportableEvent: (command: UpdateReportableEventCommand) => Promise<void>;
  updateReportableEventVehicleInformation: (
    command: UpdateReportableEventVehicleInformationCommand
  ) => Promise<void>;
  updateReportableEventInternalInformation: (
    command: UpdateReportableEventInternalInformationCommand
  ) => Promise<void>;

  loadCategoriesForContract: (
    contractId: string
  ) => Promise<IAutocompleteResult<ReportableEventCategory[]>>;
  loadAllContracts: () => Promise<void>;
  categories: ReportableEventCategory[];
  contracts: ReportableEventContract[];
  reportableEventContacts: StaffMemberName[];
  loadReportableEventContacts: () => Promise<void>;
  loadStaffMembers: () => Promise<void>;
  staffMembers: StaffMemberDto[];

  assets: AssetItem[];
  loadAssetListItems: () => Promise<void>;
  clearReportableEvent: () => void;
}

interface IMaintainReportableEventParams {
  id: string;
}

type InternalProps = IMaintainReportableEventProps &
  RouteComponentProps<IMaintainReportableEventParams>;

const emptyGuid = '00000000-0000-0000-0000-000000000000';

class MaintainReportableEvent extends Component<InternalProps> {
  private primaryFormApi: IFormApiWithoutState | undefined;

  async componentDidMount() {
    await Promise.all([
      this.props.clearReportableEvent(),
      this.props.loadStaffMembers(),
      this.props.loadReportableEventContacts(),
      this.props.loadAssetListItems(),
      this.props.loadAllContracts(),
    ]).then(_ => {
      if (this.props.contracts.length === 1) {
        this.primaryFormApi?.setValue(['reportableEventContractId'], this.props.contracts[0].id);
      }
    });
  }

  private get isUpdateMode() {
    return this.props.mode === 'update';
  }

  private get ReportableEventId() {
    return this.props.match.params.id;
  }

  private handlePreSubmitForCreate = (
    item: ReportableEvent & {
      // TODO: Update the base object and remove these
      contractCategories: ReportableEventCategory[];
    }
  ): CreateReportableEventCommand => {
    return {
      ...item,
      affectedStaffMemberId: item.affectedStaffMemberId ?? undefined,
      affectsMultipleStaffMembers: item.affectedStaffMemberId === emptyGuid,

      affectedAssetSwappedOut: item.affectedAssetSwappedOut === true,
      affectedAssetSwappedOutForAssetId: item.affectedAssetSwappedOutForAsset?.id ?? undefined,
      affectedAssetId: item.affectedAsset?.id ?? undefined,
      affectsMultipleAssets: item.affectedAsset?.id === emptyGuid,

      reportableEventContractId: item.reportableEventContractId,
      categories: item.contractCategories?.map((o: ReportableEventCategory) => o.code),
      contactStaffMemberId: item.contactStaffMember.id,
      internalNotes: item.internalNotes,
      internalDocumentLink: item.internalDocumentLink,
    };
  };

  private readonly getCategoriesSectionDef = (
    categories: ReportableEventCategory[]
  ): ISectionDef => {
    return {
      title: 'Categories',
      explicitData: categories,
      isTab: true,
      hidden: !categories || categories.length === 0,
      panels: [
        {
          title: '',
          panes: [
            {
              paneType: PaneType.repeatingPane,
              itemPanes: [
                {
                  paneType: PaneType.customPane,
                  render: d => {
                    const category = d.data.paneValue as ReportableEventCategory;
                    return <DisplayTransLinkCategoryWithExplanation category={category} />;
                  },
                },
              ],
            },
          ],
        },
      ],
    };
  };

  private readonly getAmendmentsSectionDef = (reportableEvent: ReportableEvent): ISectionDef => {
    return {
      title: 'Activity',
      explicitData: reportableEvent,
      panels: [
        {
          title: '',
          panes: [
            {
              paneType: PaneType.repeatingPane,
              hidden: !history || history.length === 0,
              useHover: false,
              dataAddr: 'history',
              itemPanes: [
                {
                  paneType: PaneType.formFieldsPane,
                  fields: [
                    {
                      fieldType: FieldType.textField,
                      dataAddr: 'createdBy',
                      readonly: true,
                      label: d => {
                        const h = d.paneValue as ReportableEventHistory;
                        return `${historyTypeDescription(h.historyType)} on ${DateTime.fromISO(
                          h.createdOn
                        ).toLocaleString(DateTime.DATETIME_MED)}`;
                      },
                      formatReadonly: d => {
                        const h = d.paneValue as ReportableEventHistory;
                        return `By ${h.createdBy}`;
                      },
                    },
                    {
                      fieldType: FieldType.dateTimeField,
                      label: 'Event Finish',
                      dataAddr: 'eventFinishAt',
                      readonly: true,
                    },
                    {
                      fieldType: FieldType.textField,
                      dataAddr: 'serviceNoticeUrl',
                      readonly: true,
                      label: 'Service Notice Url',
                      formatReadonly: d => {
                        const h = d.paneValue as ReportableEventHistory;
                        return h.serviceNoticeUrl;
                      },
                    },
                    {
                      fieldType: FieldType.textAreaField,
                      dataAddr: 'historyText',
                      readonly: true,
                      label: 'Details',
                    },
                  ],
                },
              ],
            },
            {
              paneType: PaneType.customPane,
              hidden: history && history.length > 0,
              render: () => (
                <div>
                  No changes to this reportable event have been submitted to the contract operator.
                </div>
              ),
            },
            {
              paneType: PaneType.formFieldsPane,
              fields: [
                {
                  fieldType: FieldType.textField,
                  dataAddr: 'createdBy',
                  readonly: true,
                  label: d => {
                    const re = d.paneValue as ReportableEvent;
                    return `Created on ${DateTime.fromISO(re.createdOn).toLocaleString(
                      DateTime.DATETIME_MED
                    )}`;
                  },
                  formatReadonly: d => {
                    const re = d.paneValue as ReportableEvent;
                    return `By ${re.createdBy}`;
                  },
                },
              ],
            },
          ],
        },
      ],
    };
  };

  private readonly getFinishDatePaneDef = (isRemoval: boolean, eventStartAt?: string): PaneDefs => {
    return {
      paneType: PaneType.formFieldsPane,
      fields: [
        {
          fieldType: FieldType.dateTimeField,
          label: 'Event Finish',
          dataAddr: 'amendedEventFinishAt',
          mandatory: isRemoval,
          tooltip: isRemoval
            ? 'Enter when this event will finish or is estimated to finish. Otherwise, leave blank if not known.'
            : 'Please enter when this event finished.',
          validate: d =>
            validateDateTimeIsNotLessThan(
              d.parentValue.eventFinishAt,
              'Event finish',
              eventStartAt,
              'event start'
            ),
        },
      ],
    };
  };

  private readonly getServiceUrlPaneDef = (): PaneDefs => {
    return {
      paneType: PaneType.formFieldsPane,
      fields: [
        {
          fieldType: FieldType.textField,
          label: 'Service Notice Url',
          dataAddr: 'amendedServiceNoticeUrl',
          maxLength: 500,
          validate: d =>
            !!d.fieldValue &&
            !isURL(d.fieldValue, {
              protocols: ['https'],
              require_protocol: true,
            })
              ? `Must be a valid https url `
              : undefined,
          mandatory: false,
          tooltip:
            'If known, please copy & paste the URL from the operator website of the service notice you are requesting to be amended.',
        },
      ],
    };
  };

  private readonly getAdditionalInfoPaneDef = (): PaneDefs => {
    return {
      paneType: PaneType.formFieldsPane,
      columnCount: 1,
      fields: [
        {
          fieldType: FieldType.textAreaField,
          label: 'Additional Information',
          dataAddr: 'additionalInformation',
          mandatory: true,
          maxLength: 2000,
          rows: 5,
          tooltip:
            'Provide all the relevant information about how this reportable event has changed.',
        },
      ],
    };
  };

  private readonly getAffectedStaffFieldDef = (): FieldDefs => {
    return {
      fieldType: FieldType.selectField,
      label: 'Affected Staff Member',
      dataAddr: 'affectedStaffMemberId',
      useValueOnly: true,
      optionItems: item =>
        [
          {
            id: emptyGuid,
            name: 'Affects Multiple Drivers',
          },
        ].concat(
          this.props.staffMembers.filter(
            o => (o.hasDriversAuthorisation && o.active === true) || o.id === item.fieldValue
          )
        ),
      valueKey: 'id',
      descriptionKey: 'name',
      mandatory: true,
      onChange: api =>
        api.setFormValue(
          'affectsMultipleStaffMembers' as any,
          api.fieldData.fieldValue === emptyGuid
        ),
    };
  };

  private readonly getAffectedAssetFieldDef = (): FieldDefs => {
    return {
      fieldType: FieldType.assetSelectField,
      label: 'Affected Vehicle',
      dataAddr: 'affectedAsset',
      optionItems: [
        {
          id: emptyGuid,
          name: 'Affects Multiple Vehicles',
        },
      ].concat(this.props.assets),
      valueKey: 'id',
      descriptionKey: 'name',
      mandatory: true,
      linkTo: d =>
        d.fieldValue.id === emptyGuid || !d.fieldValue
          ? undefined
          : `/workshop/assets/${d.fieldValue.id}`,
      onChange: api => {
        if (api.fieldData.fieldValue.id === emptyGuid) {
          api.setFormValue('affectedAssetSwappedOut' as any, undefined);
          api.setFormValue('affectedAssetSwappedOutForAsset' as any, undefined);
        }
        api.setFormValue(
          'affectsMultipleAssets' as any,
          api.fieldData?.fieldValue?.id === emptyGuid
        );
      },
    };
  };

  private readonly getInternalInfoPaneDefs = (columns: number): PaneDefs[] => {
    return [
      {
        paneType: PaneType.formFieldsPane,
        columnCount: columns,
        fields: [
          {
            fieldType: FieldType.textAreaField,
            label: 'Internal Notes',
            dataAddr: 'internalNotes',
            mandatory: false,
            rows: 3,
          },
          {
            fieldType: FieldType.textField,
            label: 'Internal Document Link',
            dataAddr: 'internalDocumentLink',
            mandatory: false,
            formatReadonly: d => {
              //if info is a url, then display it as a url link
              const pattern = new RegExp(
                '((http|https)://)(www.)?[a-zA-Z0-9@:%._\\+~#?&//=]{2,256}\\.[a-z]{2,6}\\b([-a-zA-Z0-9@:%._\\+~#?&//=]*)'
              );
              const value = d.fieldValue;
              if (!value || pattern.test(value)) {
                return <a href={d.fieldValue}>{d.fieldValue}</a>;
              } else {
                return d.fieldValue;
              }
            },
          },
        ],
      },
    ];
  };

  private readonly getSpareVehicleInformationPaneDefs = (columns: number): PaneDefs[] => {
    return [
      {
        paneType: PaneType.formFieldsPane,
        columnCount: 1,
        fields: [
          {
            fieldType: FieldType.customField,
            render: () => (
              <DisplayNote text={'Spare Vehicle information is for internal use only.'} />
            ),
            dataAddr: 'placeholder5',
            label: 'Spare Vehicle Information',
          },
        ],
      },
      {
        paneType: PaneType.formFieldsPane,
        columnCount: columns,
        fields: [
          {
            fieldType: FieldType.yesNoField,
            dataAddr: 'affectedAssetSwappedOut',
            label: 'Was the vehicle swapped out?',
            mandatory: true,
            onChange: api => {
              if (api.fieldData.fieldValue !== true) {
                api.setFormValue('affectedAssetSwappedOutForAsset' as any, undefined);
              }
            },
          },
          {
            fieldType: FieldType.assetSelectField,
            label: 'Replacement Vehicle',
            dataAddr: 'affectedAssetSwappedOutForAsset',
            optionItems: this.props.assets,
            valueKey: 'id',
            descriptionKey: 'name',
            hidden: d => d?.parentValue?.affectedAssetSwappedOut !== true,
            mandatory: d => d?.parentValue?.affectedAssetSwappedOut === true,
            validate: d =>
              d.fieldValue?.id === d?.parentValue?.affectedAsset?.id
                ? 'Replacement vehicle cannot be the same as the affected vehicle.'
                : undefined,
          },
        ],
      },
    ];
  };

  private readonly getAmendmentDefaults = ():
    | { amendedEventFinishAt?: string; amendedServiceNoticeUrl?: string }
    | undefined => {
    const { reportableEvent } = this.props;

    if (!reportableEvent) {
      return { amendedEventFinishAt: undefined, amendedServiceNoticeUrl: undefined };
    }

    if (!reportableEvent.history) {
      return {
        amendedEventFinishAt: reportableEvent.eventFinishAt,
        amendedServiceNoticeUrl: undefined,
      };
    }

    let previousEventFinish = undefined;
    let previousServiceNoticeUrl = undefined;

    for (let h = 0; h < reportableEvent.history.length; h++) {
      const amendment = reportableEvent.history[h];
      if (!previousEventFinish && amendment.eventFinishAt) {
        previousEventFinish = amendment.eventFinishAt;
      }

      if (
        !previousServiceNoticeUrl &&
        amendment.serviceNoticeUrl &&
        amendment.serviceNoticeUrl.trim().length > 0
      ) {
        previousServiceNoticeUrl = amendment.serviceNoticeUrl;
      }
    }

    if (!previousEventFinish) {
      previousEventFinish = reportableEvent.eventFinishAt;
    }

    return {
      amendedEventFinishAt: previousEventFinish,
      amendedServiceNoticeUrl: previousServiceNoticeUrl,
    };
  };

  private readonly hasAmendedEventFinish = (): boolean => {
    if (
      !this.isUpdateMode ||
      !this.props.reportableEvent ||
      !this.props.reportableEvent.history ||
      this.props.reportableEvent.history.length === 0 ||
      !this.props.reportableEvent.history[0].eventFinishAt
    ) {
      return false;
    } else {
      return true;
    }
  };

  private readonly getPageDef = (mode: CrudPageMode, updating: boolean): ICrudPageDef => {
    const {
      categories,
      loadCategoriesForContract,
      contracts,
      createReportableEvent,
      reportableEvent,
      reportableEventContacts,
      canManageReportableEvents,
    } = this.props;
    const explicitData =
      mode === 'create'
        ? {}
        : {
            ...this.props.reportableEvent,
            affectedAsset:
              this.props.reportableEvent?.affectsMultipleAssets === true
                ? { id: emptyGuid, name: 'Affects Multiple Vehicles' }
                : this.props.reportableEvent?.affectedAsset,
            affectedStaffMemberId:
              this.props.reportableEvent?.affectsMultipleStaffMembers === true
                ? emptyGuid
                : this.props.reportableEvent?.affectedStaffMemberId,
          };

    return {
      primarySize: PagePrimarySize.twoThirds,
      primarySection: {
        explicitData: explicitData,
        title: this.isUpdateMode ? 'Reportable Event ' : 'Create a Reportable Event',
        getApi: api => (this.primaryFormApi = api),
        subtitle: this.isUpdateMode
          ? reportableEvent &&
            reportableEvent.eventIdentifier &&
            '  ' + reportableEvent.eventIdentifier
          : undefined,
        primaryActions:
          reportableEvent && canManageReportableEvents
            ? [
                {
                  actions: [
                    {
                      actionType: ActionType.actionCollection,
                      actionGroups: [
                        {
                          actions: [
                            {
                              actionType: ActionType.modalActionButton,
                              label: 'Amend this Reportable Event...',
                              icon: <EditIcon />,
                              hidden:
                                !reportableEvent ||
                                !canManageReportableEvents ||
                                reportableEvent.hasBeenRemoved,
                              modalSize: ShellModalSize.oneThird,
                              modalDef: modalDefApi => ({
                                title: `Save Amendment Request`,
                                asForm: true,
                                explicitData: this.getAmendmentDefaults(),
                                panels: [
                                  {
                                    panes: [
                                      {
                                        paneType: PaneType.customPane,
                                        render: () => (
                                          <p>
                                            <b>
                                              Please provide the information regarding the update to
                                              this reportable event below.
                                            </b>
                                          </p>
                                        ),
                                      },
                                      this.getFinishDatePaneDef(
                                        false,
                                        reportableEvent.eventStartAt
                                      ),
                                      this.getServiceUrlPaneDef(),
                                      this.getAdditionalInfoPaneDef(),
                                    ],
                                  },
                                ],
                                secondaryActions: [
                                  getSubmitCloseModalActionGroupDef(`Save Update`),
                                ],
                                onFormSubmit: d => {
                                  return this.props.updateReportableEvent({
                                    reportableEventId: reportableEvent.id,
                                    serviceNoticeUrl: d.amendedServiceNoticeUrl,
                                    eventFinishAt: d.amendedEventFinishAt,
                                    historyText: d.additionalInformation,
                                    historyType: HistoryType.Amended,
                                  });
                                },
                              }),
                            },
                            {
                              actionType: ActionType.modalActionButton,
                              label: 'Amend Vehicle Information...',
                              icon: <EditIcon />,
                              hidden:
                                !reportableEvent ||
                                !canManageReportableEvents ||
                                reportableEvent.hasBeenRemoved,
                              modalSize: ShellModalSize.oneThird,
                              modalDef: modalDefApi => ({
                                title: `Amend Vehicle Information`,
                                asForm: true,
                                explicitData: explicitData,
                                panels: [
                                  {
                                    panes: [
                                      {
                                        paneType: PaneType.formFieldsPane,
                                        columnCount: 1,
                                        fields: [this.getAffectedAssetFieldDef()],
                                      },
                                      {
                                        paneType: PaneType.nestingPane,
                                        hidden: d =>
                                          d?.sectionValue?.affectsMultipleAssets !== false,
                                        panes: [...this.getSpareVehicleInformationPaneDefs(1)],
                                      },
                                    ],
                                  },
                                ],
                                secondaryActions: [
                                  getSubmitCloseModalActionGroupDef(`Save Update`),
                                ],
                                onFormSubmit: d => {
                                  return this.props.updateReportableEventVehicleInformation({
                                    reportableEventId: reportableEvent.id,
                                    affectedAssetSwappedOut: d.affectedAssetSwappedOut === true,
                                    affectedAssetSwappedOutForAssetId:
                                      d.affectedAssetSwappedOutForAsset?.id ?? undefined,
                                    affectedAssetId: d.affectedAsset?.id ?? undefined,
                                    affectsMultipleAssets: d.affectedAsset?.id === emptyGuid,
                                  });
                                },
                              }),
                            },
                            {
                              actionType: ActionType.modalActionButton,
                              label: 'Amend Internal Information...',
                              icon: <EditIcon />,
                              hidden: !reportableEvent || !canManageReportableEvents,
                              modalSize: ShellModalSize.oneThird,
                              modalDef: modalDefApi => ({
                                title: `Amend Internal Information`,
                                asForm: true,
                                explicitData: explicitData,
                                panels: [
                                  {
                                    panes: [
                                      {
                                        paneType: PaneType.nestingPane,
                                        columnCount: 1,
                                        panes: [...this.getInternalInfoPaneDefs(1)],
                                      },
                                    ],
                                  },
                                ],
                                secondaryActions: [
                                  getSubmitCloseModalActionGroupDef(`Save Update`),
                                ],
                                onFormSubmit: d => {
                                  return this.props.updateReportableEventInternalInformation({
                                    reportableEventId: reportableEvent.id,
                                    internalNotes: d.internalNotes,
                                    internalDocumentLink: d.internalDocumentLink,
                                  });
                                },
                              }),
                            },
                            {
                              actionType: ActionType.modalActionButton,
                              label: 'Remove this Reportable Event...',
                              icon: <TrashIcon />,
                              hidden:
                                !reportableEvent ||
                                !canManageReportableEvents ||
                                reportableEvent.hasBeenRemoved,
                              modalSize: ShellModalSize.oneThird,
                              modalDef: modalDefApi => ({
                                title: `Save Removal Request`,
                                asForm: true,
                                explicitData: this.getAmendmentDefaults(),
                                panels: [
                                  {
                                    panes: [
                                      {
                                        paneType: PaneType.customPane,
                                        render: () => (
                                          <p>
                                            <b>
                                              Please provide information regarding the removal of
                                              this reportable event below.
                                            </b>
                                          </p>
                                        ),
                                      },
                                      this.getFinishDatePaneDef(true, reportableEvent.eventStartAt),
                                      this.getServiceUrlPaneDef(),
                                      this.getAdditionalInfoPaneDef(),
                                    ],
                                  },
                                ],
                                secondaryActions: [
                                  getSubmitCloseModalActionGroupDef(`Save Removal`),
                                ],
                                onFormSubmit: d => {
                                  return this.props.updateReportableEvent({
                                    reportableEventId: reportableEvent.id,
                                    serviceNoticeUrl: d.amendedServiceNoticeUrl,
                                    eventFinishAt: d.amendedEventFinishAt,
                                    historyText: d.additionalInformation,
                                    historyType: HistoryType.Removed,
                                  });
                                },
                              }),
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ]
            : [],
        panels: [
          {
            title: 'Details',
            panes: [
              {
                paneType: PaneType.formFieldsPane,
                columnCount: 1,
                fields: [
                  {
                    fieldType: FieldType.customField,
                    render: () => (
                      <DisplayNote
                        text={'This reportable event has been changed since it was first created.'}
                      />
                    ),
                    dataAddr: 'placeholder2',
                    label: '',
                    hidden: d =>
                      !reportableEvent ||
                      !reportableEvent.history ||
                      reportableEvent.history.length === 0,
                  },
                ],
              },
              {
                paneType: PaneType.formFieldsPane,
                columnCount: this.hasAmendedEventFinish() ? 3 : 2,
                fields: [
                  {
                    fieldType: FieldType.selectField,
                    label: 'Contract',
                    dataAddr: 'reportableEventContractId',
                    optionItems: contracts,
                    valueKey: 'id',
                    descriptionKey: 'name',
                    useValueOnly: true,
                    mandatory: true,
                    readonly: contracts.length === 1,
                    onChange: api => {
                      if (api.oldFieldValue) {
                        const values = { ...api.formValues, categories: undefined };
                        api.setFormValues(values);
                      }
                    },
                  },
                  {
                    fieldType: FieldType.selectAsyncMultiField,
                    label: 'Categories*',
                    dataAddr: 'contractCategories',
                    valueKey: 'code',
                    descriptionKey: 'description',
                    useValueOnly: false,
                    validate: d => {
                      const value = d.paneValue.contractCategories as Array<
                        ReportableEventCategory
                      >;
                      // Trying to use the mandatory property on the field itself here seems to prevent the value being set
                      if (value === null || value === undefined || value.length === 0)
                        return 'Categories must be specified';
                      return undefined;
                    },
                    autoload: true,
                    useOptionRendererAsValueRenderer: true,
                    getFieldKey: d => d.sectionValue.reportableEventContractId,
                    loadOptionItems: (_, d) =>
                      loadCategoriesForContract(d.sectionValue.reportableEventContractId).then(
                        r => {
                          if (r.options && r.options.length === 1) {
                            this.primaryFormApi?.setValue(['contractCategories'], r.options[0]);
                          }
                          return { options: r.options };
                        }
                      ),
                    readonly: d => !d.parentValue.reportableEventContractId,
                    optionRenderer: o => (
                      <DisplayReportableEventCategory category={o as ReportableEventCategory} />
                    ),
                    formatReadonly: d => {
                      const item = d.parentValue as ReportableEvent;
                      if (!item || !item.categories) {
                        return undefined;
                      }

                      return item.categories.map(c => (
                        <DisplayReportableEventCategory
                          key={c.code}
                          category={c as ReportableEventCategory}
                        />
                      ));
                    },
                  },
                  {
                    fieldType: FieldType.dateTimeField,
                    label: 'Event Start',
                    dataAddr: 'eventStartAt',
                    mandatory: d => {
                      const item = d.parentValue as ReportableEvent;
                      return !item.eventFinishAt;
                    },
                  },
                  {
                    fieldType: FieldType.dateTimeField,
                    label: 'Event Finish (If known)',
                    dataAddr: 'eventFinishAt',
                    validate: d =>
                      validateDateTimeIsNotLessThan(
                        d.parentValue.eventFinishAt,
                        'Event finish',
                        d.parentValue.eventStartAt,
                        'event start'
                      ),
                  },
                  {
                    fieldType: FieldType.dateTimeField,
                    label: 'Latest Updated Event Finish',
                    dataAddr: 'latestEventFinishAt',
                    readonly: true,
                    hidden: !this.hasAmendedEventFinish(),
                  },
                ],
              },
              {
                paneType: PaneType.formFieldsPane,
                columnCount: 2,
                fields: [
                  {
                    fieldType: FieldType.textField,
                    label: 'Event Duration',
                    dataAddr: 'placeholder3',
                    readonly: true,
                    formatReadonly: d => {
                      const item = d.parentValue as ReportableEvent;
                      if (!item.eventStartAt || !item.eventFinishAt) {
                        return undefined;
                      }
                      const diffInMilliseconds = DateTime.fromISO(item.eventFinishAt).diff(
                        DateTime.fromISO(item.eventStartAt)
                      ).milliseconds;
                      return humanizeDuration(diffInMilliseconds);
                    },
                  },
                  {
                    fieldType: FieldType.selectField,
                    label: 'Contact Person for this Event',
                    dataAddr: 'contactStaffMember',
                    optionItems: reportableEventContacts,
                    valueKey: 'id',
                    descriptionKey: 'name',
                    useValueOnly: false,
                    mandatory: true,
                  },
                ],
              },
              {
                paneType: PaneType.formFieldsPane,
                columnCount: 2,
                fields: [
                  {
                    fieldType: FieldType.textField,
                    label: 'Location of Event',
                    dataAddr: 'location',
                    mandatory: false,
                    maxLength: 200,
                  },
                ],
              },
              {
                paneType: PaneType.formFieldsPane,
                columnCount: 2,
                fields: [this.getAffectedStaffFieldDef(), this.getAffectedAssetFieldDef()],
              },
              {
                paneType: PaneType.formFieldsPane,
                columnCount: 1,
                fields: [
                  {
                    fieldType: FieldType.textAreaField,
                    label: 'Services Impacted',
                    dataAddr: 'servicesImpacted',
                    mandatory: false,
                    maxLength: 200,
                    rows: 4,
                    tooltip:
                      'List routes and trips that are impacted by this event. Please include all relevant information regarding Route Number, Direction of Travel, Trip start time and Trip start location for each affected service.',
                  },
                ],
              },
              {
                paneType: PaneType.formFieldsPane,
                columnCount: 1,
                fields: [
                  {
                    fieldType: FieldType.textAreaField,
                    label: 'Information for Incoming Customer Inquiry',
                    dataAddr: 'customerInformation',
                    mandatory: false,
                    maxLength: 2000,
                    rows: 6,
                    tooltip:
                      'If applicable, please provide Call Centre Staff with any additional information that may assist them when dealing with Incoming Customer Inquiries about this event.',
                  },
                ],
              },
              {
                paneType: PaneType.formFieldsPane,
                columnCount: 1,
                fields: [
                  {
                    fieldType: FieldType.textAreaField,
                    label: 'Other Relevant Information',
                    dataAddr: 'otherInformation',
                    mandatory: false,
                    maxLength: 2000,
                    rows: 6,
                    tooltip:
                      'Provide any additional information that may assist Call Centre Staff.',
                  },
                ],
              },
            ],
          },
          {
            title: 'Internal Information',
            panes: this.getInternalInfoPaneDefs(1),
          },
          {
            title: 'Spare Vehicle Information',
            hidden: d => d?.sectionValue?.affectsMultipleAssets !== false,
            panes: this.getSpareVehicleInformationPaneDefs(2),
          },
        ],
        secondaryActions: this.isUpdateMode
          ? []
          : [
              {
                actions: [
                  {
                    actionType: ActionType.submitActionButton,
                    level: 'primary',
                    confirmationModalSize: ShellModalSize.oneQuarter,
                    confirmationModalDef: modalDefApi => ({
                      title: 'Confirmation',
                      asForm: true,
                      panels: [
                        {
                          panes: [
                            {
                              paneType: PaneType.customPane,
                              render: () => {
                                return (
                                  <div>
                                    <p>
                                      Please ensure that all of the entered information for this
                                      reportable event is correct.
                                    </p>
                                  </div>
                                );
                              },
                            },
                          ],
                        },
                      ],
                      secondaryActions: [getSubmitCloseModalActionGroupDef('Submit')],
                      onFormSubmit: values => {
                        return modalDefApi.parentFormApi.submitForm();
                      },
                    }),
                  },
                  {
                    actionType: ActionType.resetActionButton,
                    level: 'default',
                  },
                ],
              },
            ],
        onFormPreSubmit: this.handlePreSubmitForCreate,
        onFormSubmit: createReportableEvent,
        clearStandardSecondaryActions: true,
      },
      secondarySections:
        this.isUpdateMode && reportableEvent
          ? [
              this.getAmendmentsSectionDef(reportableEvent),
              this.getCategoriesSectionDef(categories),
            ]
          : [this.getCategoriesSectionDef(categories)],
    };
  };

  render() {
    const { mode, loadReportableEvent, reportableEvent } = this.props;

    return (
      <CrudPage
        def={({ updating }) => this.getPageDef(mode, updating)}
        mode={mode}
        isEditingForbidden
        onLoadData={() => loadReportableEvent(this.ReportableEventId)}
        data={reportableEvent}
        createDefaultData={{}}
      />
    );
  }
}

export default MaintainReportableEvent;
