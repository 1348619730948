import { buildListPageApiSearchModelTypedQuery } from 'src/domain/modelBuilders/buildListPageApiSearchModel';
import { NotificationType } from 'src/domain';
import { IRootStoreModel } from 'src/domain/entities/RootStoreModel';
import { getAjax } from 'src/domain/services';
import { flow, getRoot, types } from 'mobx-state-tree';

type ListDataFuelQuery = Workshop.Domain.Queries.DataFuel.ListDataFuel.ListDataFuelQuery;
type UpdateDataFuelEntryCommand = Workshop.Domain.Commands.DataFuel.UpdateDataFuelEntry.UpdateDataFuelEntryCommand;
type DataFuelIgnoredAssetDto = Workshop.Domain.Queries.DataFuel.IgnoredDataFuel.DataFuelIgnoredAssetDto;
type UpdateDataFuelAssetsIgnoredCommand = Workshop.Domain.Commands.DataFuel.IgnoredAssets.UpdateDataFuelAssetsIgnoredCommand;

const ListDataFuelModel = buildListPageApiSearchModelTypedQuery<ListDataFuelQuery>()(
  'ListDataFuelModel',
  d => d.ajax.workshop.dataFuel.listDataFuel(d.query),
  {
    size: 250,
    equalityComparer: (a, b) => a.id === b.id,
  }
).actions(self => {
  const ajax = getAjax(self);
  const root = getRoot(self) as IRootStoreModel;

  const uploadFile = flow(function*(file: string | Blob) {
    root.notifications.addNotification(`The file is being uploaded ...`, {
      type: NotificationType.info,
    });

    yield ajax.workshop.dataFuel.uploadDataFuel(file);
    return;
  });

  const updateEntry = flow(function*(command: UpdateDataFuelEntryCommand) {
    yield ajax.workshop.dataFuel.updateDataFuel(command);
  });

  const markUploadAsFixed = flow(function*(uploadId: string) {
    yield ajax.workshop.dataFuel.markUploadAsFixed(uploadId);
  });

  return {
    uploadFile,
    updateEntry,
    markUploadAsFixed,
  };
});

const DataFuelIgnoredAssetsModel = types
  .model('DataFuelIgnoredAssetsModel', {
    ignoredAssets: types.array(types.frozen<DataFuelIgnoredAssetDto>()),
  })
  .actions(self => {
    const ajax = getAjax(self);

    const loadIgnoredAssets = flow(function*() {
      self.ignoredAssets = yield ajax.workshop.dataFuel.loadIgnoredAssets();
    });

    const updateIgnoredAssets = flow(function*(query: UpdateDataFuelAssetsIgnoredCommand) {
      yield ajax.workshop.dataFuel.updateIgnoredAssets(query);
    });

    return {
      loadIgnoredAssets,
      updateIgnoredAssets,
    };
  });

export const DataFuelModel = types.compose(ListDataFuelModel, DataFuelIgnoredAssetsModel);
