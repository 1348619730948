import { IAjax } from 'src/infrastructure/ajax';
import { defectUrls } from 'src/domain/services/apiUrls';

export default function init(ajax: IAjax) {
  function exportDefectsToExcel(query: Partial<Operations.Domain.Queries.ListJobs.ListJobsQuery>) {
    return ajax
      .getFile(defectUrls.exportDefectsToExcel(query))
      .toPromise()
      .then<Blob>(r => r.response);
  }
  return {
    defects: {
      exportDefectsToExcel,
    },
  };
}
