import {
  AggregatesWithAttachments,
  LoadAttachmentsQuery,
} from 'src/views/components/Attachment/attachmentHelper';
import { getSubmitCloseModalActionGroupDef } from 'src/views/definitionBuilders/common';
import { ModalDefBuilder, PaneType } from 'src/views/definitionBuilders/types';

export type DeleteAttachmentFormData = {
  attachmentDetailsId: number;
  fileName?: string;
  aggregateType: AggregatesWithAttachments;
  aggregateId: string;
};

export default function getDeleteAttachmentModalDef(
  attachmentData: DeleteAttachmentFormData,
  onSubmit: (command: DeleteAttachmentFormData) => Promise<void>,
  onSubmitSucceed: (query: LoadAttachmentsQuery) => Promise<void>
): ModalDefBuilder {
  return _ => ({
    title: `Delete Attachment`,
    asForm: true,
    panels: [
      {
        panes: [
          {
            paneType: PaneType.customPane,
            render: () => {
              return `You are about to delete ${attachmentData.fileName}. Please note: this
                action cannot be undone. Click Delete below to confirm.`;
            },
          },
        ],
      },
    ],
    secondaryActions: [getSubmitCloseModalActionGroupDef('Delete')],
    onFormSubmit: (values: { attachmentDetailsId: number }) => {
      return onSubmit({
        attachmentDetailsId: attachmentData.attachmentDetailsId,
        aggregateId: attachmentData.aggregateId,
        aggregateType: attachmentData.aggregateType,
      });
    },
    onSubmitSucceeded: () =>
      onSubmitSucceed({
        aggregateId: attachmentData.aggregateId,
        aggregateType: attachmentData.aggregateType,
      }),
  });
}
