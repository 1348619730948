import { IAjax } from 'src/infrastructure/ajax';
import { kioskUrls } from 'src/domain/services/apiUrls';

type QuoteDriverPdfDto = Operations.Domain.Commands.Quote.QuoteDriverPdfDto;
type JobAttachmentPdfDto = Common.Dtos.JobAttachmentPdfDto;

export default function init(ajax: IAjax) {
  function loadJobsForDriver(date: string) {
    return ajax
      .get(kioskUrls.jobUrls.loadJobsForDriver(date))
      .toPromise()
      .then<Operations.Domain.Queries.ListJobsForDriver.JobsForDriver>(r => r.response);
  }

  function loadCharterInstructionsForDriver(date: string) {
    return ajax
      .get(kioskUrls.jobUrls.loadCharterInstructionsForDriver(date))
      .toPromise()
      .then<Operations.Domain.Queries.ListCharterInstructionsForDriver.CharterInstructions>(
        r => r.response
      );
  }

  function acknowledgeJob(jobId: string) {
    return ajax
      .put(kioskUrls.jobUrls.acknowledgeJob(jobId))
      .toPromise()
      .then(r => Promise.resolve());
  }

  function completeJob(
    command: Operations.Domain.Commands.Kiosk.Job.CompleteJob.CompleteJobCommand
  ) {
    return ajax
      .put(kioskUrls.jobUrls.completeJob(command.jobId), command)
      .toPromise()
      .then(r => Promise.resolve());
  }

  function loadJob(jobId: string) {
    return ajax
      .get(kioskUrls.jobUrls.loadJob(jobId))
      .toPromise()
      .then<Operations.Domain.Queries.ViewJob.JobItem>(r => r.response);
  }

  function loadDriverPdfs(jobId: string) {
    return ajax
      .get(kioskUrls.jobUrls.loadDriverPdfs(jobId))
      .toPromise()
      .then<QuoteDriverPdfDto[]>(r => r.response);
  }

  function getDriverJobAttachment(attachmentId: string) {
    return ajax
      .get(kioskUrls.jobUrls.getDriverJobAttachment(attachmentId))
      .toPromise()
      .then<JobAttachmentPdfDto>(r => r.response);
  }

  return {
    jobs: {
      loadJobsForDriver,
      loadCharterInstructionsForDriver,
      acknowledgeJob,
      completeJob,
      loadJob,
      loadDriverPdfs,
      getDriverJobAttachment,
    },
  };
}
