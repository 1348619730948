import inject from 'src/views/injectFromStore';
import MaintainPurchaseOrder, { IMaintainPurchaseOrderProps } from './MaintainPurchaseOrder';
import Omit from 'src/infrastructure/omit';

const Container = inject<Omit<IMaintainPurchaseOrderProps, 'mode'>, IMaintainPurchaseOrderProps>(
  (allStores, p) => ({
    canManagePurchaseOrders: allStores.rootStore.account.isWorkshopDepartmentMember,
    purchaseOrder: allStores.rootStore.workshop.purchaseOrder.purchaseOrder,
    deliveryLocations: allStores.rootStore.workshop.purchaseOrder.deliveryLocations.slice(),
    contactsForSupplier: allStores.rootStore.workshop.supplier.contactsForSupplier.slice(),
    assets: allStores.rootStore.assets.assetListItems.slice(),
    activityLogs: allStores.rootStore.workshop.purchaseOrder.activityLogs.slice(),
    onLoadPurchaseOrder: allStores.rootStore.workshop.purchaseOrder.loadPurchaseOrder,
    onUpdatePurchaseOrder: allStores.rootStore.workshop.purchaseOrder.updatePurchaseOrder,
    onApprovePurchaseOrder: allStores.rootStore.workshop.purchaseOrder.approvePurchaseOrder,
    onreinstatePurchaseOrder: allStores.rootStore.workshop.purchaseOrder.reinstatePurchaseOrder,
    onCancelPurchaseOrder: allStores.rootStore.workshop.purchaseOrder.cancelPurchaseOrder,
    onCreatePurchaseOrder: allStores.rootStore.workshop.purchaseOrder.createPurchaseOrder,
    onCompletePurchaseOrderLine:
      allStores.rootStore.workshop.purchaseOrder.completePurchaseOrderLine,
    onCompletePurchaseOrder: allStores.rootStore.workshop.purchaseOrder.completePurchaseOrder,
    onGeneratePdf: allStores.rootStore.workshop.purchaseOrder.generatePdf,
    searchSuppliers: allStores.rootStore.workshop.supplier.searchSuppliers,
    searchParts: allStores.rootStore.workshop.parts.searchParts,
    loadDeliveryLocations: allStores.rootStore.workshop.purchaseOrder.loadDeliveryLocations,
    listContactsForSupplier: allStores.rootStore.workshop.supplier.listContactsForSupplier,
    loadAssetListItems: allStores.rootStore.assets.loadAssetListItems,
    getJobTasksForAsset: allStores.rootStore.jobTask.getJobTasksForAsset,
    clearActivityLog: allStores.rootStore.workshop.purchaseOrder.clearActivityLog,
  })
)(MaintainPurchaseOrder);

export default Container;
