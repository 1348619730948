import { PaneType, SectionDefs } from 'src/views/definitionBuilders/types';
import AuditHistory from 'src/views/components/AuditHistory/operations/AuditHistoryContainer';
import getSchema from 'src/views/routes/operations/job/maintainJob/panelDefs/AuditHistorySchema';

type AssetItem = Common.Queries.Workshop.GetFleetAssetList.AssetItem;
type StaffMemberDto = Common.Dtos.StaffMemberDto;
type SubcontractorItem = Common.Dtos.SubcontractorItem;
type ExtraType = Operations.Domain.Queries.ViewExtraType.ExtraTypeItem;
type VehicleType = Common.Dtos.VehicleTypeItem;

export default function getAuditHistoryPanelDef(
  jobId: string,
  depots: Common.Dtos.OperationsDepotDto[],
  fleetAssets: AssetItem[],
  staffMembers: StaffMemberDto[],
  subcontractors: SubcontractorItem[],
  extraTypes: ExtraType[],
  vehicleTypes: VehicleType[],
  jobTypeId?: number
): SectionDefs {
  return {
    title: 'History',
    hidden: !jobId,
    panels: [
      {
        panes: [
          {
            paneType: PaneType.customPane,
            render: () => (
              <AuditHistory
                aggregateId={jobId}
                minimumVersion={2}
                schema={getSchema(
                  depots,
                  fleetAssets,
                  staffMembers,
                  subcontractors,
                  extraTypes,
                  vehicleTypes,
                  jobTypeId
                )}
              />
            ),
          },
        ],
      },
    ],
  };
}
