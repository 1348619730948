import inject from 'src/views/injectFromStore';
import ServiceHistory, { IServiceHistoryProps } from './ServiceHistory';
import Omit from 'src/infrastructure/omit';

const Container = inject<Omit<IServiceHistoryProps, 'mode'>, IServiceHistoryProps>(
  (allStores, p) => ({
    canManageServiceHistory: allStores.rootStore.account.isWorkshopDepartmentMember,
    asset: allStores.rootStore.asset.asset,

    servicePlan: allStores.rootStore.asset.assetServicePlan,
    serviceTypes: allStores.rootStore.workshop.engine.serviceTypes.slice(),
    futureJobs: allStores.rootStore.assets.futureJobs.slice(),
    onLoadAsset: allStores.rootStore.asset.loadAsset,
    onLoadAssetServicePlan: allStores.rootStore.asset.loadAssetServicePlan,
    onLoadServiceTypes: allStores.rootStore.workshop.engine.loadServiceTypes,
    onLoadAssetFutureJobs: allStores.rootStore.assets.getFutureJobsForAsset,
    onUpdateServicePlan: allStores.rootStore.asset.updateServicePlan,
    onResetSchedule: allStores.rootStore.asset.resetAssetServiceSchedule,
    onCancelService: allStores.rootStore.asset.cancelAssetService,
    onScheduleService: allStores.rootStore.asset.scheduleService,
    fullServiceCycle: allStores.rootStore.assetGroup.fullServiceCycle.slice(),
    loadAssetGroupFullServiceCycle: allStores.rootStore.assetGroup.loadAssetGroupFullServiceCycle,
    workshopDepots: allStores.rootStore.workshopStartup.workshopDepots.slice(),
    defaultWorkshopDepot: allStores.rootStore.workshopStartup.defaultWorkshopDepot,
  })
)(ServiceHistory);

export default Container;
