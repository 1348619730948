import inject from 'src/views/injectFromKioskStore';
import PrintCharterInstructions, {
  IPrintCharterInstructionsProps,
} from 'src/views/kioskRoutes/operationsKiosk/home/PrintCharterInstructions';

const Container = inject<IPrintCharterInstructionsProps>((allStores, p) => ({
  date: allStores.rootStore.kiosk.date,
  charterInstructions: allStores.rootStore.kiosk.jobs.charterInstructions,
  loadCharterInstructionsForDriver: allStores.rootStore.kiosk.jobs.loadCharterInstructionsForDriver,
}))(PrintCharterInstructions);

export default Container;
