import { Component } from 'react';
import { FieldType } from 'src/views/definitionBuilders/types/field';
import CrudPage, { ICrudPageDef } from 'src/views/components/Page/pages/CrudPage';
import { PaneType } from 'src/views/definitionBuilders/types';
import { precisionRound } from 'src/infrastructure/mathUtil';

export interface ICreateOdometerReadingsProps {
  lastOdometerReadings?: Workshop.Domain.Queries.GetLastAssetOdometerReadings.AssetOdometerReadings;
  loadLastOdometerReadings: () => Promise<void>;
  createOdometerReadings: (
    cmd: Workshop.Domain.Commands.Asset.CreateAssetOdometerReading.CreateAssetOdometerReadingCommand
  ) => Promise<void>;
}

interface IFormItem
  extends Workshop.Domain.Queries.GetLastAssetOdometerReadings.AssetOdometerReadingItem {
  odometerReading?: number;
}

interface IForm {
  assetOdometerReadingItems: IFormItem[];
}

class CreateOdometerReadings extends Component<ICreateOdometerReadingsProps> {
  private readonly handlePreSubmit = (
    values: IForm
  ): Workshop.Domain.Commands.Asset.CreateAssetOdometerReading.CreateAssetOdometerReadingCommand => {
    const odometerReadings: Workshop.Domain.Commands.Asset.CreateAssetOdometerReading.OdometerReadingItem[] = values.assetOdometerReadingItems
      .filter(x => !!x.odometerReading)
      .map(v => {
        return {
          assetId: v.assetId,
          odometerReading: v.odometerReading!,
        };
      });

    return {
      odometerReadings,
    } as Workshop.Domain.Commands.Asset.CreateAssetOdometerReading.CreateAssetOdometerReadingCommand;
  };

  private readonly getPageDef = (): ICrudPageDef => {
    return {
      primarySection: {
        title: 'Create Odometer Readings',
        key:
          (this.props.lastOdometerReadings &&
            this.props.lastOdometerReadings.assetOdometerReadingItems.length.toString()) ||
          '0',
        panels: [
          {
            panes: [
              {
                paneType: PaneType.tablePane,
                dataAddr: 'assetOdometerReadingItems',
                dataRequiredForRows: 'paneValue',
                rowKey: d => d.itemValue.assetId,
                fields: [
                  {
                    fieldType: FieldType.readonlyField,
                    dataAddr: 'name',
                    label: 'Asset',
                  },
                  {
                    fieldType: FieldType.readonlyField,
                    dataAddr: 'lastOdometer',
                    label: 'Last Kms',
                    formatReadonly: d => precisionRound(d.fieldValue, 2),
                  },
                  {
                    fieldType: FieldType.numericField,
                    dataAddr: 'odometerReading',
                    label: 'Odometer Reading',
                    numericConfig: { numericType: 'unsignedInt' },
                  },
                ],
              },
            ],
          },
        ],
        onFormPreSubmit: this.handlePreSubmit,
        onFormSubmit: this.props.createOdometerReadings,
      },
    };
  };

  render() {
    return (
      <CrudPage
        def={() => this.getPageDef()}
        mode="create"
        onLoadCreateDefaultData={() => this.props.loadLastOdometerReadings()}
        createDefaultData={this.props.lastOdometerReadings}
      />
    );
  }
}

export default CreateOdometerReadings;
