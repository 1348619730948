import { getSubmitCloseModalActionGroupDef } from 'src/views/definitionBuilders/common';
import {
  FieldType,
  IFieldOnChange,
  ModalDefBuilder,
  PaneType,
} from 'src/views/definitionBuilders/types';

type ExtraTypeItem = Operations.Domain.Queries.ViewExtraType.ExtraTypeItem;
type RailBookingExtraItem = Operations.Domain.Queries.ViewRailBooking.RailBookingExtraItem;

function calculateGst(
  api: IFieldOnChange<number>,
  extraTypes: Operations.Domain.Queries.ViewExtraType.ExtraTypeItem[]
) {
  const extra = api.fieldData.parentValue;
  const extraType = extraTypes.find(e => e.id === extra.extraTypeId);
  const isTaxable = extraType?.isTaxable ?? true;

  let gst = undefined;
  if (extra && extra.totalPrice) {
    gst = isTaxable ? extra.totalPrice * 0.1 : 0;
  }
  const gstDataAddr = [...api.fieldDataAddr];
  gstDataAddr.pop();
  gstDataAddr.push('totalGst');
  api.setFormValue(gstDataAddr, gst);
}

export default function getMaintainExtrasModalDef(
  isUpdating: boolean,
  extraTypes: ExtraTypeItem[],
  isEditing: boolean
): ModalDefBuilder {
  return modalDefApi => {
    return {
      title: isEditing ? 'Edit Extra' : 'Add Extra',
      asForm: true,
      explicitData: isEditing
        ? {
            ...modalDefApi.actionData.actionValue,
          }
        : { undefined, totalGst: 0 },
      panels: [
        {
          panes: [
            {
              paneType: PaneType.formFieldsPane,
              fields: [
                {
                  fieldType: FieldType.selectField,
                  dataAddr: 'extraTypeId',
                  label: 'Extra Type',
                  valueKey: 'id',
                  descriptionKey: 'description',
                  optionItems: extraTypes,
                  columnWidth: '20em',
                  mandatory: true,
                  useValueOnly: true,
                  valueRenderer: v => {
                    const extraType = extraTypes.find(e => e.id === v.id);
                    return extraType?.description ?? v.description;
                  },
                  valuesToDisable: () =>
                    modalDefApi.parentFormApi.values.railBookingExtras.map(
                      (extra: RailBookingExtraItem) => extra.extraTypeId
                    ),
                  valuesToExclude: extraTypes.filter(t => !t.isActive).map(t => t.id),
                },
                {
                  fieldType: FieldType.numericField,
                  dataAddr: 'quantity',
                  numericConfig: { numericType: 'unsignedInt' },
                  label: 'Quantity',
                  columnWidth: '6em',
                  mandatory: true,
                },
                {
                  fieldType: FieldType.numericField,
                  dataAddr: 'totalHours',
                  numericConfig: {
                    numericType: 'unsignedDecimal',
                    maxPointDigits: 2,
                    maxValue: 99999,
                  },
                  label: 'Total Hours',
                  columnWidth: '6em',
                  mandatory: true,
                },
                {
                  fieldType: FieldType.numericField,
                  dataAddr: 'totalPrice',
                  numericConfig: { numericType: 'unsignedDecimal', maxPointDigits: 2 },
                  label: 'Total Price',
                  columnWidth: '7em',
                  mandatory: true,
                  onChange: fapi => {
                    calculateGst(fapi, extraTypes);
                  },
                },
                {
                  fieldType: FieldType.numericField,
                  hidden: true,
                  readonly: true,
                  dataAddr: 'totalGst',
                  numericConfig: { numericType: 'unsignedDecimal', maxPointDigits: 2 },
                  label: 'GST',
                  columnWidth: '7em',
                  mandatory: true,
                },
              ],
            },
          ],
        },
      ],
      secondaryActions: [getSubmitCloseModalActionGroupDef()],
      onFormSubmit: values => {
        const extras = modalDefApi.parentFormApi.values.railBookingExtras;
        const dataAddr = modalDefApi.actionData.fieldDataAddr;
        return !isEditing
          ? Promise.resolve(
              modalDefApi.parentFormApi.setValue('railBookingExtras', [...extras, values])
            )
          : Promise.resolve(modalDefApi.parentFormApi.setValue(dataAddr, { ...values }));
      },
    };
  };
}
