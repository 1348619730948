import { types, flow } from 'mobx-state-tree';
import { getAjax } from 'src/domain/services/storeEnvironment';
import { accountingUrls } from 'src/domain/services/apiUrls';

type AccountingDashboardData = Operations.Domain.Queries.GetAccountingDashboardData.AccountingDashboardData;

export const AccountingDashboardModel = types
  .model('AccountingDashboardModel', {
    dashboardData: types.maybe(types.frozen<AccountingDashboardData>()),
  })
  .actions(self => {
    const ajax = getAjax(self);

    const loadDashboardData = flow(function*() {
      self.dashboardData = yield ajax.raw
        .get(accountingUrls.dashboardUrls.loadDashboard)
        .toPromise()
        .then<AccountingDashboardData>(r => r.response);
    });

    return {
      loadDashboardData,
    };
  });
