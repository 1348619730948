import { types, flow, getRoot } from 'mobx-state-tree';
import { getAjax } from 'src/domain/services/storeEnvironment';
import { IRootStoreModel } from 'src/domain/entities/RootStoreModel';
import { NotificationType } from 'src/domain';

type TimesheetItem = Common.Dtos.TimesheetItem;
type CreateTimesheetCommand = People.Domain.Commands.Timesheets.CreateTimesheet.CreateTimesheetCommand;
type UpdateTimesheetCommand = People.Domain.Commands.Timesheets.UpdateTimesheet.UpdateTimesheetCommand;
type RefreshTimesheetCommand = People.Domain.Commands.Timesheets.RefreshTimesheet.RefreshTimesheetCommand;
type AmendTimesheetCommand = People.Domain.Commands.Timesheets.AmendTimesheet.AmendTimesheetCommand;
type ActivityLogTransaction = People.Domain.Queries.GetActivityLog.ActivityLogTransaction;

export const TimesheetModel = types
  .model('TimesheetModel', {
    timesheet: types.maybe(types.frozen<TimesheetItem>()),
    timesheetForPrinting: types.maybe(types.frozen<Blob>()),
    activityLogs: types.array(types.frozen<ActivityLogTransaction>()),
  })
  .actions(self => {
    const ajax = getAjax(self);
    const root = getRoot(self) as IRootStoreModel;

    const viewTimesheet = (timesheetId: string) =>
      flow(function*() {
        const timesheet: TimesheetItem = yield ajax.people.timesheet.viewTimesheet(timesheetId);
        self.timesheet = timesheet;
      })();

    const createTimesheet = (command: CreateTimesheetCommand) =>
      flow(function*() {
        const timesheetId: string = yield ajax.people.timesheet.createTimesheet(command);
        root.history.push(`/people/timesheets/${timesheetId}`);
      })();

    const updateTimesheet = (command: UpdateTimesheetCommand) =>
      flow(function*() {
        yield ajax.people.timesheet.updateTimesheet(command);
        yield viewTimesheet(command.id);
        yield listActivityLogs(command.id);
      })();

    const amendTimesheet = (command: AmendTimesheetCommand) =>
      flow(function*() {
        yield ajax.people.timesheet.amendTimesheet(command);
        yield viewTimesheet(command.id);
        yield listActivityLogs(command.id);
      })();

    const approveTimesheet = (timesheetId: string) =>
      flow(function*() {
        yield ajax.people.timesheet.approveTimesheet(timesheetId);
        yield viewTimesheet(timesheetId);
        yield listActivityLogs(timesheetId);
      })();

    const processTimesheet = (timesheetId: string) =>
      flow(function*() {
        yield ajax.people.timesheet.processTimesheet(timesheetId);
        yield viewTimesheet(timesheetId);
        yield listActivityLogs(timesheetId);
      })();

    const refreshTimesheet = (command: RefreshTimesheetCommand) =>
      flow(function*() {
        yield ajax.people.timesheet.refreshTimesheet(command);
        yield viewTimesheet(command.timesheetId);
        yield listActivityLogs(command.timesheetId);
      })();

    const getTimesheetForPrinting = (timesheetId: string) =>
      flow(function*() {
        root.notifications.addNotification(`The file is being generated ...`, {
          type: NotificationType.info,
        });
        self.timesheetForPrinting = yield ajax.people.timesheet.printTimesheet(timesheetId);
      })();

    const listActivityLogs = flow(function*(timesheetId: string) {
      self.activityLogs = yield ajax.raw
        .get(`/api/people/activity-logs?aggregateType=Timesheet&aggregateId=${timesheetId}`)
        .toPromise()
        .then<ActivityLogTransaction[]>(r => {
          return r.response;
        });
    });

    return {
      viewTimesheet,
      createTimesheet,
      updateTimesheet,
      amendTimesheet,
      approveTimesheet,
      refreshTimesheet,
      processTimesheet,
      getTimesheetForPrinting,
      listActivityLogs,
    };
  });
