import { flow, getRoot, types } from 'mobx-state-tree';
import { IRootStoreModel } from 'src/domain/entities/RootStoreModel';
import { buildListPageApiSearchModelTypedQuery } from 'src/domain/modelBuilders/buildListPageApiSearchModel';
import { getAjax, NotificationType } from 'src/domain/services';

type ListContractCharterBookingsQuery = Operations.Domain.Queries.ContractCharterBooking.ListContractCharterBookings.ListContractCharterBookingsQuery;
type ContractCharterBooking = Operations.Domain.Queries.ContractCharterBooking.GetContractCharterBooking.ContractCharterBooking;
type CreateContractCharterBookingCommand = Operations.Domain.Commands.ContractCharterBooking.CreateContractCharterBooking.CreateContractCharterBookingCommand;
type UpdateContractCharterBookingCommand = Operations.Domain.Commands.ContractCharterBooking.UpdateContractCharterBooking.UpdateContractCharterBookingCommand;
type ExportToExcelFilters = Operations.Domain.Queries.ContractCharterBooking.ExportContractCharterBookingToExcel.ExportContractCharterBookingToExcelQuery;
type AvailableJobsItem = Operations.Domain.Queries.ContractCharterBooking.GetContractCharterBookingAvailableJobs.JobItem;

const contractCharterBookingsListModel = buildListPageApiSearchModelTypedQuery<
  ListContractCharterBookingsQuery
>()('ContractCharterBookingsModel', d =>
  d.ajax.operations.contractCharterBookings.listContractCharterBookings(d.query)
);

const contractCharterBookingModel = types
  .model('ContractCharterBookingModel', {
    contractCharterBooking: types.maybe(types.frozen<ContractCharterBooking>()),
    recentContractCharterBookingsForCustomer: types.array(types.frozen<ContractCharterBooking>()),
    recentContractCharterBookingsForContract: types.array(types.frozen<ContractCharterBooking>()),
  })
  .actions(self => {
    const ajax = getAjax(self);
    const root = getRoot(self) as IRootStoreModel;

    const load = flow(function*(contractCharterBookingId: string) {
      self.contractCharterBooking = yield ajax.operations.contractCharterBookings.getContractCharterBooking(
        { contractCharterBookingId }
      );
    });

    const complete = flow(function*(contractCharterBookingId: string) {
      yield ajax.operations.contractCharterBookings.completeContractCharterBooking({
        contractCharterBookingId,
      });
      yield load(contractCharterBookingId);
    });

    const create = flow(function*(command: CreateContractCharterBookingCommand) {
      const id = yield ajax.operations.contractCharterBookings.createContractCharterBooking(
        command
      );
      root.history.push(`/accounting/contract-charter-bookings/${id}`);
      self.contractCharterBooking = undefined;
    });

    const update = flow(function*(command: UpdateContractCharterBookingCommand) {
      yield ajax.operations.contractCharterBookings.updateContractCharterBooking(command);
      yield load(command.contractCharterBookingId);
    });

    const exportToExcel = flow(function*(filters: Partial<ExportToExcelFilters>) {
      root.notifications.addNotification(`The file is being generated ...`, {
        type: NotificationType.info,
      });

      return yield ajax.operations.contractCharterBookings.exportToExcel(filters);
    });

    const getRecentCharterContractBookingsForCustomer = flow(function*(customerId: string) {
      self.recentContractCharterBookingsForCustomer = yield ajax.operations.contractCharterBookings
        .listContractCharterBookings({
          customerIds: [customerId],
          orderBy: 'BookingNumber~desc',
          size: 25,
        })
        .toPromise()
        .then(r => r.items);
    });

    const getRecentCharterContractBookingsForContract = flow(function*(contractId: string) {
      self.recentContractCharterBookingsForContract = yield ajax.operations.contractCharterBookings
        .listContractCharterBookings({
          contractIds: [contractId],
          orderBy: 'BookingNumber~desc',
          size: 25,
        })
        .toPromise()
        .then(r => r.items);
    });

    return {
      load,
      complete,
      create,
      update,
      exportToExcel,
      getRecentCharterContractBookingsForCustomer,
      getRecentCharterContractBookingsForContract,
    };
  });

const availableJobs = types
  .model('availableJobsModel', {
    availableJobs: types.array(types.frozen<AvailableJobsItem>()),
  })
  .actions(self => {
    const ajax = getAjax(self);

    const getAvailableJobs = flow(function*(charterContractId: string) {
      self.availableJobs = yield ajax.operations.contractCharterBookings.getAvailableJobs({
        charterContractId,
      });
    });

    return { getAvailableJobs };
  });

export const ContractCharterBookingsModel = types.compose(
  contractCharterBookingsListModel,
  contractCharterBookingModel,
  availableJobs
);
