import buildListPageApiSearchModel from 'src/domain/modelBuilders/buildListPageApiSearchModel';
import { getAjax } from 'src/domain/services';
import { getRoot, flow, types } from 'mobx-state-tree';
import { IRootStoreModel } from 'src/domain/entities/RootStoreModel';
import { NotificationType } from 'src/domain';
import * as queryString from 'query-string';

const ListCustomersModel = buildListPageApiSearchModel('ListCustomersModel', d =>
  d.ajax.sales.customer.listCustomer(d.page, 20, d.query.search || '')
);

const ExportCustomersModel = types.model('ExportCustomersModel').actions(self => {
  const ajax = getAjax(self);
  const root = getRoot(self) as IRootStoreModel;

  const exportToExcel = flow(function*(
    query: Partial<Operations.Domain.Queries.ListCustomers.ListCustomersQuery>
  ) {
    root.notifications.addNotification(`The file is being generated ...`, {
      type: NotificationType.info,
    });

    return yield ajax.raw
      .getFile(`/api/operations/customers/excel?${queryString.stringify(query)}`)
      .toPromise()
      .then<Blob>(r => r.response);
  });

  const exportQuoteConversionRates = flow(function*(
    query: Partial<
      Operations.Domain.Queries.GetQuoteConversionRate.GetQuoteConversionAndSpendingQuery
    >
  ) {
    root.notifications.addNotification(`The file is being generated ...`, {
      type: NotificationType.info,
    });

    return yield ajax.raw
      .getFile(`/api/operations/customers/quote-conversion?${queryString.stringify(query)}`)
      .toPromise()
      .then<Blob>(r => r.response);
  });

  return {
    exportToExcel,
    exportQuoteConversionRates,
  };
});

export const CustomersModel = types.compose(ListCustomersModel, ExportCustomersModel);
