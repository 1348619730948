import { formatCurrency } from 'src/infrastructure/formattingUtils';
import './PriceTotals.scss';
export interface IPriceTotalsProps {
  subtotal: number;
  gstTotal?: number | undefined;
}

const PriceTotals: React.FC<IPriceTotalsProps> = ({ subtotal, gstTotal }) => {
  const gst = gstTotal !== undefined ? gstTotal : subtotal * 0.1;
  const total = subtotal + gst;
  return (
    <table className="price-totals-component">
      <tbody>
        <tr>
          <td>
            <label>Subtotal:</label>
          </td>
          <td>{formatCurrency(subtotal)}</td>
        </tr>
        <tr>
          <td>
            <label>GST:</label>
          </td>
          <td>{formatCurrency(gst)}</td>
        </tr>
        <tr>
          <td>
            <label>Total:</label>
          </td>
          <td>{formatCurrency(total)}</td>
        </tr>
      </tbody>
    </table>
  );
};

export default PriceTotals;
