import './FieldLabel.scss';

import { Label, LabelProps } from 'reactstrap';

interface IFieldLabelProps extends LabelProps {
  mandatory?: boolean;
}

const FieldLabel: React.FC<IFieldLabelProps> = ({ className, mandatory, children, ...rest }) => (
  <Label {...rest} className={`${className} field-label-component ${mandatory ? 'mandatory' : ''}`}>
    {children}
  </Label>
);

export default FieldLabel;
