import { IRootStoreModel } from 'src/domain/entities/RootStoreModel';
import { UiRoute } from 'src/domain/services/buildUiRoutes';
import { UiRouteName } from 'src/domain/baseTypes';

export default function buildUiRoutes(store: IRootStoreModel) {
  const createTimesheet = new UiRoute(
    UiRouteName.peopleTimesheetCreate,
    '/people/timesheets/create',
    () => 'Create Timesheet',
    false
  );

  const timesheetManage = new UiRoute(
    UiRouteName.peopleTimesheetManage,
    '/people/timesheets/:id',
    () => 'Manage Timesheet',
    false
  );

  const notesList = new UiRoute(
    UiRouteName.peopleTimesheetList,
    '/people/timesheets',
    () => 'Timesheets',
    false,
    true,
    [createTimesheet, timesheetManage]
  );

  return {
    [UiRouteName.peopleTimesheetCreate]: createTimesheet,
    [UiRouteName.peopleTimesheetManage]: timesheetManage,
    [UiRouteName.peopleTimesheetList]: notesList,
  };
}
