import './AuditHistory.scss';

import { ISchemaRecord } from 'src/views/components/AuditHistory/ISchemaRecord';
import AuditHistoryRow from 'src/views/components/AuditHistory/AuditHistoryRow';
import { IAuditHistoryRowSeparator } from './IAuditHistoryRowSeparator';
import { Separator } from '../Separator';
import { useEffect } from 'react';

type AuditLogItem = Operations.Domain.Queries.ListAuditLog.AuditLogItem;

export interface IAuditHistoryProps {
  aggregateId: string;
  minimumVersion: number;
  schema: ISchemaRecord[];
  auditLogs: AuditLogItem[];
  separators?: IAuditHistoryRowSeparator[];
  loadAuditLogs: (aggregateId: string, minimumVersion: number) => Promise<void>;
}

const AuditHistory: React.FC<IAuditHistoryProps> = (props: IAuditHistoryProps) => {
  const { auditLogs, schema, separators } = props;

  useEffect(() => {
    props.loadAuditLogs(props.aggregateId, props.minimumVersion);
  }, [props.aggregateId, props.minimumVersion]);

  return (
    <div className="audit-history-component">
      {auditLogs && auditLogs.length > 0 ? (
        auditLogs.map(auditLog => (
          <div key={auditLog.id}>
            <AuditHistoryRow auditLog={auditLog} schema={schema} />
            {separators && separators.some(x => x.auditLogId === auditLog.id)
              ? separators
                  .filter(x => x.auditLogId === auditLog.id)
                  .map((separator, index) => (
                    <Separator
                      key={`sep-${auditLog.id}-${index}`}
                      label={separator.label}
                      className="audit-history-separator"
                    />
                  ))
              : undefined}
          </div>
        ))
      ) : (
        <p>No changes have been made since it was created</p>
      )}
    </div>
  );
};

export default AuditHistory;
