import './MaintainFatigueBreachEvent.scss';
import { RouteComponentProps } from 'react-router-dom';
import {
  PagePrimarySize,
  PaneType,
  FieldType,
  ActionType,
  ShellModalSize,
} from 'src/views/definitionBuilders/types';
import CrudPage, { CrudPageMode, ICrudPageDef } from 'src/views/components/Page/pages/CrudPage';
import {
  FatigueBreachEventStatus,
  fatigueRuleSetTypeDescription,
  fatigueRuleTypeDescription,
  getFatigueBreachEventStatusDescriptor,
  breachSeverityLevelTypeDescription,
} from 'src/api/enums';
import { BanIcon, CheckIcon, ExternalLinkIcon, FilePdfIcon } from 'src/images/icons';
import { getSubmitCloseModalActionGroupDef } from 'src/views/definitionBuilders/common';
import getActivityLogPanelDef from 'src/views/routes/compliance/fatigueBreachEvents/MaintainFatigueBreachEvent/getActivityLogPanelDef';
import getJobsPanelDef from 'src/views/routes/compliance/fatigueBreachEvents/MaintainFatigueBreachEvent/getJobsPanelDef';
import { DateTime } from 'luxon';
import getVerifyFatigueBreachEventActionButtonDef from '../getVerifyFatigueBreachEventActionButtonDef';
import saveAs from 'file-saver';

type FatigueBreachEventItem = Operations.Domain.Queries.FatigueBreachEvent.FatigueBreachEventItem;
type UpdateFatigueBreachEventCommand = Operations.Domain.Commands.FatigueBreachEvent.UpdateFatigueBreachEvent.UpdateFatigueBreachEventCommand;
type InvalidateFatigueBreachEventCommand = Operations.Domain.Commands.FatigueBreachEvent.InvalidateFatigueBreachEvent.InvalidateFatigueBreachEventCommand;
type ReactivateFatigueBreachEventCommand = Operations.Domain.Commands.FatigueBreachEvent.ReactivateFatigueBreachEvent.ReactivateFatigueBreachEventCommand;
type ActivityLogTransaction = Operations.Domain.Queries.GetActivityLog.ActivityLogTransaction;
type VerifyFatigueBreachEventResponse = Operations.Domain.Commands.FatigueBreachEvent.VerifyFatigueBreachEvent.VerifyFatigueBreachEventResponse;

export interface IMaintainFatigueBreachEventProps {
  mode: CrudPageMode;
  canManageFatigueBreachEvents: boolean;

  onLoadFatigueBreachEvent: (id: string) => Promise<void>;
  fatigueBreachEvent: FatigueBreachEventItem | undefined;

  onUpdateFatigueBreachEvent: (command: UpdateFatigueBreachEventCommand) => Promise<void>;
  onCloseFatigueBreachEvent: (fatigueBreachEventId: string) => Promise<void>;
  onInvalidateFatigueBreachEvent: (command: InvalidateFatigueBreachEventCommand) => Promise<void>;
  onReactivateFatigueBreachEvent: (command: ReactivateFatigueBreachEventCommand) => Promise<void>;

  listActivityLogs: (jobId: string) => Promise<void>;
  activityLogs: ActivityLogTransaction[];

  exportFatigueBreachEventToPdf: (fatigueBreachEventId: string) => Promise<Blob>;
  verifyResponse?: VerifyFatigueBreachEventResponse;
  verifyFatigueBreach: (fatigueBreachEventId: string) => Promise<void>;
  clearVerifyFatigueBreachEvent: () => void;
  loadFatigueBreachEvent: (fatigueBreachEventId: string) => Promise<void>;
}

interface IUpdateFatigueBreachEventRouteParams {
  id: string;
}

type InternalProps = IMaintainFatigueBreachEventProps &
  RouteComponentProps<IUpdateFatigueBreachEventRouteParams>;

export const MaintainFatigueBreachEvent: React.FC<InternalProps> = (props: InternalProps) => {
  const isUpdateMode = props.mode === 'update';

  const fatigueBreachEventId = props.match.params.id;

  const handlePreSubmitForUpdate = (
    fatigueBreachEvent: FatigueBreachEventItem
  ): UpdateFatigueBreachEventCommand => {
    return {
      id: fatigueBreachEventId,
      comments: fatigueBreachEvent.comments,
    };
  };

  const saveFatigueBreachEventPdf = (id: string) => {
    const fileName = `Fatigue_Breach_Event_${DateTime.local().toFormat('yyyyMMddHHmm')}.pdf`;
    return props.exportFatigueBreachEventToPdf(id).then(blob => saveAs(blob, fileName));
  };

  const getPageDef = (updating: boolean): ICrudPageDef => {
    const {
      fatigueBreachEvent: loadedFatigueBreachEvent,
      canManageFatigueBreachEvents,
      activityLogs,
    } = props;
    return {
      primarySize: PagePrimarySize.half,
      primarySection: {
        title: isUpdateMode
          ? `Fatigue Breach Event ${
              loadedFatigueBreachEvent ? loadedFatigueBreachEvent.fatigueBreachEventNumber : ''
            }`
          : '',
        badge: {
          label:
            (loadedFatigueBreachEvent &&
              getFatigueBreachEventStatusDescriptor(loadedFatigueBreachEvent.status).description) ??
            '',
        },
        primaryActions: [
          {
            actions: [
              {
                actionType: ActionType.actionCollection,
                hidden: updating || !isUpdateMode || !canManageFatigueBreachEvents,
                actionGroups: [
                  {
                    actions: [
                      {
                        actionType: ActionType.actionButton,
                        label: 'Export to PDF',
                        icon: <FilePdfIcon fixedWidth />,
                        onClick: () => {
                          const id = props.fatigueBreachEvent?.id;
                          if (!id) return;
                          saveFatigueBreachEventPdf(id);
                        },
                      },
                      {
                        actionType: ActionType.actionLink,
                        label: 'View Fatigue Breach Details',
                        icon: <ExternalLinkIcon fixedWidth />,
                        to: `/compliance/fatigueDetails?day=${props.fatigueBreachEvent?.date}&staffMember=${props.fatigueBreachEvent?.staffMember.id}`,
                      },
                    ],
                  },
                  {
                    actions: [
                      {
                        actionType: ActionType.modalActionButton,
                        label: 'Mark as Closed',
                        hidden:
                          loadedFatigueBreachEvent &&
                          loadedFatigueBreachEvent.status !== FatigueBreachEventStatus.Open,
                        icon: <CheckIcon />,
                        modalSize: ShellModalSize.oneQuarter,
                        modalDef: modalDefApi => ({
                          title: 'Mark as Closed',
                          asForm: true,
                          panels: [
                            {
                              panes: [
                                {
                                  paneType: PaneType.customPane,
                                  render: () => (
                                    <span>
                                      Are you sure you want to close this fatigue breach event ?
                                    </span>
                                  ),
                                },
                              ],
                            },
                          ],
                          secondaryActions: [getSubmitCloseModalActionGroupDef('Ok')],
                          onFormSubmit: () => props.onCloseFatigueBreachEvent(fatigueBreachEventId),
                        }),
                      },
                      {
                        actionType: ActionType.modalActionButton,
                        label: 'Mark as Invalid',
                        hidden:
                          loadedFatigueBreachEvent &&
                          loadedFatigueBreachEvent.status !== FatigueBreachEventStatus.Open,
                        icon: <BanIcon />,
                        modalSize: ShellModalSize.oneQuarter,
                        modalDef: () => ({
                          title: 'Mark as Invalid',
                          asForm: true,
                          panels: [
                            {
                              panes: [
                                {
                                  paneType: PaneType.formFieldsPane,
                                  fields: [
                                    {
                                      fieldType: FieldType.textAreaField,
                                      label:
                                        'Please enter a reason for marking this fatigue breach event as invalid',
                                      mandatory: true,
                                      maxLength: 2000,
                                      showCounter: true,
                                      dataAddr: 'invalidReason',
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                          secondaryActions: [getSubmitCloseModalActionGroupDef('Ok')],
                          onFormSubmit: props.onInvalidateFatigueBreachEvent,
                        }),
                      },
                      {
                        actionType: ActionType.modalActionButton,
                        label: 'Reactivate',
                        hidden:
                          loadedFatigueBreachEvent &&
                          loadedFatigueBreachEvent.status !== FatigueBreachEventStatus.Invalid,
                        icon: <CheckIcon />,
                        modalSize: ShellModalSize.oneQuarter,
                        modalDef: () => ({
                          title: 'Reactivate',
                          asForm: true,
                          panels: [
                            {
                              panes: [
                                {
                                  paneType: PaneType.customPane,
                                  render: () => (
                                    <p>Are you sure you want to reactivate this breach event?</p>
                                  ),
                                },
                              ],
                            },
                          ],
                          secondaryActions: [getSubmitCloseModalActionGroupDef('Ok')],
                          onFormSubmit: props.onReactivateFatigueBreachEvent,
                        }),
                      },

                      getVerifyFatigueBreachEventActionButtonDef(
                        props.verifyFatigueBreach,
                        props.onInvalidateFatigueBreachEvent,
                        () => {},
                        () => {
                          props.loadFatigueBreachEvent(fatigueBreachEventId);
                          props.listActivityLogs(fatigueBreachEventId);
                          props.clearVerifyFatigueBreachEvent();
                        },
                        props.verifyResponse
                      ),
                    ],
                  },
                ],
              },
            ],
          },
        ],
        panels: [
          {
            panes: [
              {
                paneType: PaneType.formFieldsPane,
                columnCount: 2,
                fields: [
                  {
                    fieldType: FieldType.readonlyField,
                    label: 'Staff Member',
                    dataAddr: 'staffMember.name',
                    linkTo: d => `/people/staff-members/${d.parentValue.staffMember.id}`,
                  },
                  {
                    fieldType: FieldType.dateField,
                    dataAddr: 'breachDate',
                    label: 'Breach Date',
                    readonly: true,
                  },
                  {
                    fieldType: FieldType.readonlyField,
                    dataAddr: 'fatigueRuleSetType',
                    label: 'Breached Ruleset',
                    formatReadonly: data =>
                      fatigueRuleSetTypeDescription(data.parentValue.fatigueRuleSetType),
                  },
                  {
                    fieldType: FieldType.readonlyField,
                    dataAddr: 'fatigueRuleType',
                    label: 'Breached Rule',
                    formatReadonly: data =>
                      fatigueRuleTypeDescription(data.parentValue.fatigueRuleType),
                  },
                  {
                    fieldType: FieldType.readonlyField,
                    label: 'Breach Severity',
                    dataAddr: 'breachSeverityLevelType',
                    formatReadonly: data =>
                      breachSeverityLevelTypeDescription(data.parentValue.breachSeverityLevelType),
                  },
                  {
                    fieldType: FieldType.readonlyField,
                    label: 'Job Number',
                    dataAddr: 'job.jobNumber',
                    linkTo: d => `/operations/jobs/${d.parentValue.job.id}`,
                    formatReadonly: data => {
                      return data.parentValue.job && data.parentValue.job.jobNumber
                        ? data.parentValue.job.jobNumber
                        : 'Deleted';
                    },
                  },
                ],
              },
              {
                paneType: PaneType.formFieldsPane,
                columnCount: 1,
                fields: [
                  {
                    fieldType: FieldType.textAreaField,
                    label: 'Comments',
                    dataAddr: 'comments',
                  },
                ],
              },
            ],
          },
        ],
        onFormPreSubmit: handlePreSubmitForUpdate,
        onFormSubmit: props.onUpdateFatigueBreachEvent,
      },
      secondarySections: [
        getActivityLogPanelDef(fatigueBreachEventId, activityLogs),
        getJobsPanelDef(
          fatigueBreachEventId,
          loadedFatigueBreachEvent?.history,
          fatigueBreachEvent?.deletedJobsCount
        ),
      ],
    };
  };

  const { mode, fatigueBreachEvent, canManageFatigueBreachEvents } = props;

  return (
    <CrudPage
      def={({ updating }) => getPageDef(updating)}
      mode={mode}
      isEditingForbidden={!canManageFatigueBreachEvents}
      onLoadData={() => {
        return Promise.all([
          props.onLoadFatigueBreachEvent(fatigueBreachEventId),
          props.listActivityLogs(fatigueBreachEventId),
        ]).then(() => Promise.resolve());
      }}
      data={fatigueBreachEvent}
      createDefaultData={{}}
    />
  );
};

export default MaintainFatigueBreachEvent;
