import { types, flow } from 'mobx-state-tree';
import { getAjax } from 'src/domain/services';

type AssetPartCategoryItem = Workshop.Domain.Queries.AssetPartCategory.AssetPartCategoryItem;

export const AssetPartCategoriesModel = types
  .model('AssetPartCategoriesModel', {
    assetPartCategoryListItems: types.array(types.frozen<AssetPartCategoryItem>()),
  })
  .actions(self => {
    const ajax = getAjax(self);

    const loadAssetPartCategoryListItems = flow(function*() {
      self.assetPartCategoryListItems = yield ajax.workshop.assetPartCategories.listAssetPartCategories();
    });

    return {
      loadAssetPartCategoryListItems,
    };
  });

type IAssetPartCategoriesModelType = typeof AssetPartCategoriesModel.Type;
export interface IAssetPartCategoriessModel extends IAssetPartCategoriesModelType {}
