import { PaneType, SectionDefs } from 'src/views/definitionBuilders/types';
import { DateTime } from 'luxon';

type ActivityLogTransaction = People.Domain.Queries.GetActivityLog.ActivityLogTransaction;
type ActivityLogDetails = People.Domain.Queries.GetActivityLog.ActivityLogDetails;

export default function getActivityLogPanelDef(
  timesheetId: string,
  activityLogs: ActivityLogTransaction[]
): SectionDefs {
  return {
    title: 'Activity',
    hidden: !timesheetId,
    isTab: true,
    explicitData: activityLogs.filter(x => x.items.some(y => y.aggregateId === timesheetId)),
    panels: [
      {
        panes: [
          {
            paneType: PaneType.repeatingPane,
            itemPanes: [
              {
                paneType: PaneType.customPane,
                dataAddr: 'transactionId',
                render: api => {
                  const log = api.data.parentValue as ActivityLogTransaction;
                  const createdOnDateTime = DateTime.fromISO(log.createdOn).toLocaleString(
                    DateTime.DATETIME_MED_WITH_SECONDS
                  );
                  return (
                    <div className="activity-log-for-timesheet-pane">
                      <div className="created-by">{log.createdBy}</div>
                      <div className="created-on">{createdOnDateTime}</div>
                      <ul className="log-items list-unstyled">
                        {log.items.map((item: ActivityLogDetails) => {
                          return (
                            <li key={item.activityLogId}>
                              <div>
                                <strong>
                                  {item.description === 'Timesheet submitted' &&
                                  log.createdBy === 'System'
                                    ? 'Timesheet auto submitted'
                                    : item.description}
                                </strong>
                              </div>
                              <div>
                                <em>{item.comment}</em>
                              </div>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  );
                },
              },
            ],
          },
        ],
      },
    ],
  };
}
