import styles from './Form.module.scss';

import { Field, FieldProps, getIn } from 'formik';
import cn from 'classnames';

interface ITextAreaFieldProps {
  readOnly?: boolean;
  dataValue: string;
  dataAddress: string;
  className?: string;
  dataLabel?: string;
  rows?: number;
  cols?: number;
}

const TextAreaField: React.FC<ITextAreaFieldProps> = ({
  readOnly,
  dataValue,
  dataAddress,
  dataLabel,
  className,
  rows,
  cols,
}) => (
  <div className={cn(styles.fieldContainer, className)}>
    {dataLabel && <div className={styles.fieldLabel}>{dataLabel}</div>}
    {readOnly ? (
      <div className={cn(styles.readOnly, styles.textField)}>{dataValue}</div>
    ) : (
      <Field name={dataAddress}>
        {({ field, form, meta }: FieldProps) => {
          const error = getIn(form.errors, dataAddress);
          const touched = getIn(form.touched, dataAddress) || form.submitCount > 0; // https://github.com/jaredpalmer/formik/issues/691#issuecomment-446509600

          return (
            <div className={!!error && touched ? styles.errorMargin : ''}>
              <textarea rows={rows || 5} cols={cols || 80} className={styles.textField} {...field}>
                {dataValue || ''}
              </textarea>
              {touched && error && <div className={styles.error}>Required</div>}
            </div>
          );
        }}
      </Field>
    )}
  </div>
);

export default TextAreaField;
