import { ISectionDef, PaneType, FieldType, ActionType } from 'src/views/definitionBuilders/types';
import { DateTime } from 'luxon';
import { progressIdDescription, busUsageStatusDescription } from 'src/api/enums';
import { EditIcon, BusIcon } from 'src/images/icons';
import TimezoneFormat from 'src/views/components/DateTimeFormat/TimezoneFormat';

type ProgressItem = Partial<Operations.Domain.Queries.GetJobProgress.JobProgressItem> &
  Partial<Workshop.Domain.Queries.GetJobsProgressUsage.JobBusUsageDto>;

const titleText = 'Progress';

export default function getProgressSectionDef(
  progress: ProgressItem[],
  jobId: string,
  assetId: string | undefined,
  isWorkshop?: boolean,
  isHidden?: boolean | undefined
): ISectionDef {
  const occurredAt = progress && progress.length > 0 && progress[0].occurredAt;
  const occurredAtDateTime = occurredAt ? DateTime.fromISO(occurredAt) : DateTime.local();
  const from = occurredAtDateTime.minus({ days: 3 }).toFormat('yyyy-MM-dd');
  const to = occurredAtDateTime.plus({ days: 3 }).toFormat('yyyy-MM-dd');
  const mapped = isWorkshop
    ? progress.map(x => ({ ...x, progress: x.busUsageStatusId }))
    : progress;

  return {
    title: titleText,
    hidden: isHidden || !jobId,
    explicitData: {
      progress: mapped,
    },
    panels: [
      {
        panes: [
          {
            paneType: PaneType.tablePane,
            dataAddr: 'progress',
            title: 'Tablet Progress',
            neverEditable: true,
            fields: [
              {
                fieldType: FieldType.textField,
                dataAddr: ['occurredAt'],
                label: 'Date',
                formatReadonly: d => {
                  const createdOnDateTime = DateTime.fromISO(d.fieldValue!).toLocaleString(
                    DateTime.DATETIME_MED
                  );

                  return (
                    <span>
                      {createdOnDateTime}{' '}
                      <TimezoneFormat value={DateTime.fromISO(d.fieldValue!)} blankForDefaultZone />
                    </span>
                  );
                },
                columnWidth: '8rem',
              },
              {
                fieldType: FieldType.textField,
                dataAddr: ['progress'],
                label: 'Progress',
                formatReadonly: d => {
                  return (
                    <span>
                      {d.fieldValue
                        ? isWorkshop
                          ? busUsageStatusDescription(parseInt(d.fieldValue, 10))
                          : progressIdDescription(parseInt(d.fieldValue, 10))
                        : ''}
                    </span>
                  );
                },
              },
              {
                fieldType: FieldType.textField,
                dataAddr: ['odometer'],
                label: 'Odo',
                columnWidth: '1px',
              },
              {
                fieldType: FieldType.textField,
                dataAddr: ['staffMember', 'name'],
                label: 'Name',
                columnWidth: '10rem',
              },
            ],
          },
          {
            paneType: PaneType.actionListPane,
            actionGroups: [
              {
                actions: [
                  {
                    actionType: ActionType.actionLink,
                    label: 'Edit Progress',
                    icon: <EditIcon />,
                    to: `/${isWorkshop ? 'workshop' : 'operations'}/jobs/${jobId}/progress`,
                  },
                  {
                    actionType: ActionType.actionLink,
                    label: 'View Asset Odometer Readings',
                    icon: <BusIcon />,
                    to: `/operations/asset/odometer-readings?id=${assetId}&from=${from}&to=${to}`,
                    hidden: !assetId || !isWorkshop,
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  };
}
