import { types, flow, getRoot } from 'mobx-state-tree';
import { getAjax } from 'src/domain/services/storeEnvironment';
import { IRootStoreModel } from 'src/domain/entities/RootStoreModel';
import { NotificationType } from 'src/domain';

type OperationsDiaryEntry = Operations.Domain.Queries.ListOperationsDiaryEntries.OperationsDiaryEntryDto;
type OperationsDiaryEntrySubject = Operations.Domain.Queries.ListOperationsDiaryEntries.OperationsDiaryEntrySubjectDto;
type CreateOperationsDiaryEntryCommand = Operations.Domain.Commands.OperationsDiaryEntry.CreateOperationsDiaryEntry.CreateOperationsDiaryEntryCommand;
type UpdateOperationsDiaryEntryCommand = Operations.Domain.Commands.OperationsDiaryEntry.UpdateOperationsDiaryEntry.UpdateOperationsDiaryEntryCommand;
type UpdateOperationsDiaryEntrySubjectCommand = Operations.Domain.Commands.OperationsDiaryEntrySubject.UpdateOperationsDiaryEntrySubjectCommand;

export const DriverReportSubjectId = '8cf883ee-f4df-45e2-8d56-701e3c089ce6';

const NonAllowedSubjectsForCreate = [DriverReportSubjectId];

export const OperationsDiaryEntryModel = types
  .model('OperationsDiaryEntryModel', {
    operationsDiaryEntry: types.maybe(types.frozen<OperationsDiaryEntry>()),
    operationsDiaryEntrySubjects: types.array(types.frozen<OperationsDiaryEntrySubject>()),
  })
  .views(self => ({
    get creatableOperationsDiaryEntrySubjects() {
      return self.operationsDiaryEntrySubjects.filter(
        x => !NonAllowedSubjectsForCreate.includes(x.id)
      );
    },
  }))
  .actions(self => {
    const ajax = getAjax(self);
    const root = getRoot(self) as IRootStoreModel;

    const loadOperationsDiaryEntry = flow(function*(id: string) {
      self.operationsDiaryEntry = yield ajax.operations.operationsDiary.viewOperationsDiaryEntry(
        id
      );
    });

    const loadAllOperationsDiaryEntrySubjects = flow(function*() {
      self.operationsDiaryEntrySubjects = yield ajax.operations.operationsDiary.loadAllOperationsDiaryEntrySubjects();
    });

    const updateAllOperationsDiaryEntrySubjects = flow(function*(
      command: UpdateOperationsDiaryEntrySubjectCommand
    ) {
      yield ajax.operations.operationsDiary.updateAllOperationsDiaryEntrySubjects(command);

      root.notifications.addNotification(`Successfully updated daily event log entry subjects`, {
        type: NotificationType.success,
      });
      yield loadAllOperationsDiaryEntrySubjects();
    });

    const createOperationsDiaryEntry = flow(function*(command: CreateOperationsDiaryEntryCommand) {
      const id = yield ajax.operations.operationsDiary.createOperationsDiaryEntry(command);
      root.notifications.addNotification(`Successfully created daily event log entry`, {
        type: NotificationType.success,
      });
      self.operationsDiaryEntry = undefined;
      root.history.push(`/operations/daily-event-log/${id}`);
    });

    const updateOperationsDiaryEntry = flow(function*(command: UpdateOperationsDiaryEntryCommand) {
      yield ajax.operations.operationsDiary.updateOperationsDiaryEntry(command);

      root.notifications.addNotification(`Successfully updated daily event log entry`, {
        type: NotificationType.success,
      });
      yield loadOperationsDiaryEntry(command.id);
    });

    const deleteOperationsDiaryEntry = flow(function*(id: string) {
      yield ajax.operations.operationsDiary.deleteOperationsDiaryEntry(id);

      root.notifications.addNotification(`Successfully deleted daily event log entry`, {
        type: NotificationType.success,
      });
      self.operationsDiaryEntry = undefined;
      root.history.push(`/operations/daily-event-log/`);
    });

    const acknowledgeOperationsDiaryEntry = flow(function*(id: string) {
      yield ajax.operations.operationsDiary.acknowledgeOperationsDiaryEntry(id);
      yield loadOperationsDiaryEntry(id);
    });

    return {
      loadOperationsDiaryEntry,
      createOperationsDiaryEntry,
      updateOperationsDiaryEntry,
      deleteOperationsDiaryEntry,
      loadAllOperationsDiaryEntrySubjects,
      updateAllOperationsDiaryEntrySubjects,
      acknowledgeOperationsDiaryEntry,
    };
  });
