import { IRootStoreModel } from 'src/domain/entities/RootStoreModel';
import { UiRoute } from 'src/domain/services/buildUiRoutes';
import { UiRouteName } from 'src/domain/baseTypes';

export default function buildUiRoutes(store: IRootStoreModel) {
  const jobCreateGenerateJobsOperation = new UiRoute(
    UiRouteName.operationsJobCreateGenerateJobsOption,
    '/operations/generate-jobs-operations/create',
    () => 'Generate New Jobs',
    false
  );

  const jobGenerateJobsOperationDetails = new UiRoute(
    UiRouteName.operationsJobGenerateJobsDetails,
    '/operations/generate-jobs-operations/:id',
    () => 'Manage Generate Jobs',
    false
  );

  const jobListGenerateJobsOperation = new UiRoute(
    UiRouteName.operationsJobListGenerateJobsOptions,
    '/operations/generate-jobs-operations',
    () => 'Generate Jobs',
    false,
    false,
    [jobCreateGenerateJobsOperation, jobGenerateJobsOperationDetails]
  );

  return {
    [UiRouteName.operationsJobCreateGenerateJobsOption]: jobCreateGenerateJobsOperation,
    [UiRouteName.operationsJobListGenerateJobsOptions]: jobListGenerateJobsOperation,
    [UiRouteName.operationsJobGenerateJobsDetails]: jobGenerateJobsOperationDetails,
  };
}
