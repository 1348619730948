import { DateTime } from 'luxon';
import { ButtonGroup, Button } from 'reactstrap';
import { PlusIcon, MinusIcon } from 'src/images/icons';
import CalendarPicker from 'src/views/components/Page/fields/subfields/CalendarPicker';
import { IGanttCalendarDayItem } from './baseTypes';
import GanttViewSwitcher, { IGanttViewSwitcherProps } from './subcomponents/GanttViewSwitcher';

interface ICalendarHeaderProps<T extends IGanttCalendarDayItem> {
  today: DateTime;
  day: DateTime;
  hideDayTitle: boolean;
  ganttViews: IGanttViewSwitcherProps<T>['ganttViews'];
  excludeUngroupedView: boolean;
  currentView: IGanttViewSwitcherProps<T>['currentView'];
  onViewChange: IGanttViewSwitcherProps<T>['onViewChange'];
  onDayChange: (day: DateTime) => void;
  canZoomIn: boolean;
  canZoomOut: boolean;
  onZoomIn: () => void;
  onZoomOut: () => void;
  pillContainerId: string;
}

export const CalendarHeader: <T extends IGanttCalendarDayItem>(
  props: ICalendarHeaderProps<T>
) => React.ReactElement<ICalendarHeaderProps<T>> = props => {
  const {
    today,
    day,
    hideDayTitle,
    onDayChange,
    ganttViews,
    excludeUngroupedView,
    currentView,
    onViewChange,
    canZoomIn,
    canZoomOut,
    onZoomIn,
    onZoomOut,
    pillContainerId,
  } = props;
  return (
    <div className="calendar-header">
      {!hideDayTitle && <h2 className="day-title">{day.toLocaleString(DateTime.DATE_HUGE)}</h2>}
      <GanttViewSwitcher
        className="view-switcher"
        ganttViews={ganttViews}
        excludeUngroupedView={excludeUngroupedView}
        currentView={currentView}
        onViewChange={onViewChange}
      />

      <div className="view-area" id={pillContainerId}>
        {/* This div serves as a portal that other components can inject into. */}
      </div>

      <CalendarPicker
        className="day-picker"
        buttonClassName="day-picker-button"
        today={today}
        value={day}
        onChange={onDayChange}
      />
      <ButtonGroup className="zoom-controls">
        <Button outline title="Zoom in" disabled={!canZoomIn} onClick={onZoomIn}>
          <PlusIcon />
        </Button>
        <Button outline title="Zoom out" disabled={!canZoomOut} onClick={onZoomOut}>
          <MinusIcon />
        </Button>
      </ButtonGroup>
    </div>
  );
};
