import { ModalDefBuilder, PaneType, FieldType } from 'src/views/definitionBuilders/types';
import { getSubmitCloseModalActionGroupDef } from 'src/views/definitionBuilders/common';

type ExportAssetFuelToExcelQuery = Workshop.Domain.Queries.ExportAssetFuelToExcel.ExportAssetFuelToExcelQuery;
type AssetItem = Workshop.Domain.Queries.AssetItem;
type Owners = Workshop.Domain.Queries.Owners.GetAllOwners.OwnerSelectListItem;
type Depots = Common.Queries.Workshop.GetWorkshopDepots.WorkshopDepotDto;

interface ExportFuelReport {
  from: string;
  to: string;
  assetIds: string[];
  assetFuelLocationIdIds: number[];
  ownerIds: string[];
  onlyTabletData: boolean;
}

export default function getFuelReportReportModalDef(
  assets: AssetItem[],
  owners: Owners[],
  depots: Depots[],
  onSubmit: (command: ExportAssetFuelToExcelQuery) => Promise<void>
): ModalDefBuilder {
  return () => {
    return {
      title: 'Export Fuel Report to Excel',
      asForm: true,
      panels: [
        {
          panes: [
            {
              paneType: PaneType.nestingPane,
              panes: [
                {
                  paneType: PaneType.formFieldsPane,
                  dataRequiredForRows: 'paneValue',
                  fields: [
                    {
                      fieldType: FieldType.dateField,
                      label: 'Start Date',
                      dataAddr: 'from',
                      mandatory: true,
                    },
                    {
                      fieldType: FieldType.dateField,
                      label: 'End Date',
                      dataAddr: 'to',
                      mandatory: true,
                    },
                    {
                      fieldType: FieldType.selectMultiField,
                      label: 'Assets',
                      dataAddr: 'assetIds',
                      useValueOnly: true,
                      valueKey: 'id',
                      descriptionKey: 'name',
                      optionItems: assets,
                    },
                    {
                      fieldType: FieldType.selectMultiField,
                      label: 'Owner',
                      dataAddr: 'owners',
                      useValueOnly: true,
                      valueKey: 'id',
                      descriptionKey: 'name',
                      optionItems: owners,
                    },
                    {
                      fieldType: FieldType.selectField,
                      label: 'Source',
                      dataAddr: 'source',
                      optionItems: [
                        { id: false, name: 'DataFuel' },
                        { id: true, name: 'Tablet' },
                      ],
                      descriptionKey: 'name',
                      valueKey: 'id',
                      useValueOnly: true,
                    },
                    {
                      fieldType: FieldType.selectField,
                      label: 'Location',
                      dataAddr: 'assetFuelLocations',
                      optionItems: depots,
                      descriptionKey: 'description',
                      valueKey: 'id',
                      useValueOnly: true,
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
      secondaryActions: [getSubmitCloseModalActionGroupDef('Submit')],
      onFormSubmit: (values: ExportFuelReport) => {
        const command: ExportAssetFuelToExcelQuery = {
          from: values.from,
          to: values.to,
          assetIds: values.assetIds,
          assetFuelLocationIdIds: values.assetFuelLocationIdIds,
          ownerIds: values.ownerIds,
          onlyTabletData: values.onlyTabletData,
        };
        return onSubmit(command);
      },
    };
  };
}
