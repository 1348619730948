import { IRootStoreModel } from 'src/domain/entities/RootStoreModel';
import { UiRoute } from 'src/domain/services/buildUiRoutes';
import { UiRouteName } from 'src/domain/baseTypes';

export default function buildUiRoutes(store: IRootStoreModel) {
  const rosterGroupCreate = new UiRoute(
    UiRouteName.urbanRosterCreate,
    '/operations/roster-groups/create',
    () => 'Create Roster Group',
    false
  );

  const rosterGroupManage = new UiRoute(
    UiRouteName.urbanRosterManage,
    '/operations/roster-groups/:id',
    () => 'Manage Roster Group',
    false
  );

  const rosterGroupList = new UiRoute(
    UiRouteName.urbanRosterGroupList,
    '/operations/roster-groups',
    () => 'Roster Groups',
    false,
    true,
    [rosterGroupCreate, rosterGroupManage]
  );

  return {
    [UiRouteName.urbanRosterGroupList]: rosterGroupList,
    [UiRouteName.urbanRosterGroupManage]: rosterGroupManage,
    [UiRouteName.urbanRosterGroupCreate]: rosterGroupCreate,
  };
}
