import { Route, Switch, Redirect } from 'react-router-dom';
import ErrorBoundary from 'src/views/components/ErrorBoundary';
import Home from 'src/views/kioskRoutes/operationsKiosk/home/Home';
import AuthoriseDevice from 'src/views/kioskRoutes/components/connected/AuthoriseDevice/AuthoriseDeviceContainer';
import Login from 'src/views/kioskRoutes/operationsKiosk/login/LoginContainer';
import LoadingIndicator from 'src/views/components/FullPageLoadingIndicator';
import KioskShell from 'src/views/kioskRoutes/operationsKiosk/components/layout/KioskShell/KioskShell';
import DriverDeclaration from 'src/views/kioskRoutes/operationsKiosk/DriverDeclaration/DriverDeclaration';
import CompleteJob from './CompleteJob/CompleteJob';
import Menu from 'src/views/kioskRoutes/operationsKiosk/Menu/Menu';
import Leave from 'src/views/kioskRoutes/operationsKiosk/Leave/LeaveContainer';
import CreateDefect from 'src/views/kioskRoutes/operationsKiosk/CreateDefect/CreateDefectContainer';
import Timesheet from 'src/views/kioskRoutes/operationsKiosk/Timesheet/Timesheet';
import CreateLeave from './CreateLeave/CreateLeaveContainer';

interface IAuthedRoutesProps {
  isClockedOn: boolean;
}

const AuthedRoutes: React.FC<IAuthedRoutesProps> = ({ isClockedOn }) => {
  if (!isClockedOn) {
    return (
      <Switch>
        <Route exact path="/kiosk/driver-declaration" component={DriverDeclaration} />
        <Redirect to="/kiosk/driver-declaration" />
      </Switch>
    );
  }

  return (
    <Switch>
      <Route exact path="/kiosk" component={Home} />
      <Route
        exact
        path="/kiosk/complete-job/:id"
        render={p => <CompleteJob {...p} mode="create" />}
      />
      <Route
        exact
        path="/kiosk/complete-job/:id/view"
        render={p => <CompleteJob {...p} mode="view" />}
      />
      <Route exact path="/kiosk/menu" component={Menu} />
      <Route exact path="/kiosk/leave" component={Leave} />
      <Route exact path="/kiosk/leave/create" component={CreateLeave} />
      <Route exact path="/kiosk/defect/create" component={CreateDefect} />
      <Route exact path="/kiosk/timesheet" component={Timesheet} />

      <Redirect to="/kiosk" />
    </Switch>
  );
};

const AnonRoutes: React.FC = () => (
  <Switch>
    <Route exact path="/kiosk/authorise-device" component={AuthoriseDevice} />
    <Route exact path="/kiosk/login" component={Login} />
    <Redirect to="/kiosk/login" />
  </Switch>
);

export interface IRoutesProps extends IAuthedRoutesProps {
  isAuthenticated: boolean | undefined;
  isClockedOn: boolean;
}

const Routes: React.FC<IRoutesProps> = ({ isAuthenticated, isClockedOn }) => {
  const getRoutes = () => {
    switch (isAuthenticated) {
      case true:
        return <AuthedRoutes isClockedOn={isClockedOn} />;
      case false:
        return <AnonRoutes />;
      default:
        return <LoadingIndicator isLoading />;
    }
  };

  return (
    <KioskShell>
      <ErrorBoundary>{getRoutes()}</ErrorBoundary>
    </KioskShell>
  );
};

export default Routes;
