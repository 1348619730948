import { getSubmitCloseModalActionGroupDef } from 'src/views/definitionBuilders/common';
import { PaneType, ModalDefBuilder } from 'src/views/definitionBuilders/types';

export default function getMarkQuoteAsReviewedModalDef(
  onFormSubmit: () => Promise<void>
): ModalDefBuilder {
  return modalDefApi => ({
    title: 'Mark as Reviewed',
    asForm: true,
    panels: [
      {
        panes: [
          {
            paneType: PaneType.customPane,
            render: api => <p>Are you sure you wish to mark this quote as reviewed?</p>,
          },
        ],
      },
    ],
    secondaryActions: [getSubmitCloseModalActionGroupDef('Ok')],
    onFormSubmit: onFormSubmit,
  });
}
