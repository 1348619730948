import { types, flow, getRoot } from 'mobx-state-tree';
import { getAjax } from 'src/domain/services/storeEnvironment';
import { IKioskRootStoreModel } from '../../KioskRootStoreModel';

type ShiftDetails = Workshop.Domain.Queries.GetShiftDetailForKiosk.ShiftDetails;
type CompleteShiftCommand = Workshop.Domain.Commands.Shift.CompleteShift.CompleteShiftCommand;

export const ShiftModel = types
  .model('ShiftModel', {
    shiftDetail: types.maybe(types.frozen<ShiftDetails>()),
  })
  .actions(self => {
    const ajax = getAjax(self);
    const root = getRoot<IKioskRootStoreModel>(self);

    const loadShiftDetail = flow(function*(shiftId: string) {
      self.shiftDetail = yield ajax.kiosk.workshop.shift.loadShift(shiftId);
      return self.shiftDetail;
    });

    const completeShift = flow(function*(command: CompleteShiftCommand) {
      yield ajax.kiosk.workshop.shift.completeShift(command);
      root.history.push(`/wskiosk`);
    });

    return {
      loadShiftDetail,
      completeShift,
    };
  });
