import { types } from 'mobx-state-tree';
import { CctvRequestItemModel } from './CctvRequestItemModel';
import { ListCctvRequestModel } from './ListCctvRequestModel';

export const CctvRequestModel = types.model('CctvRequestModel', {
  item: types.optional(CctvRequestItemModel, {}),
  list: types.optional(ListCctvRequestModel, {}),
});
type ModelType = typeof CctvRequestModel.Type;
export interface ICctvRequestModel extends ModelType {}
