import { Duration } from 'luxon';

export const numberFormat: Intl.NumberFormatOptions = {
  minimumFractionDigits: 0,
  // arbitrarily large number to prevent rounding
  maximumFractionDigits: 8,
};

function currencyFormat(
  minimumFractionDigits: number,
  maximumFractionDigits: number
): Intl.NumberFormatOptions {
  return {
    style: 'currency',
    currency: 'AUD',
    minimumFractionDigits,
    maximumFractionDigits,
  };
}

export function formatNumber(value?: number, format?: 'currency' | 'unitPrice' | undefined) {
  return (
    value != null &&
    value.toLocaleString(
      'en-AU', // has to be set explicitly otherwise an additional 'A' prefix is added for currencies
      format && format === 'currency'
        ? currencyFormat(2, 2)
        : format && format === 'unitPrice'
        ? currencyFormat(2, 5)
        : numberFormat
    )
  );
}

export function formatCurrency(value?: number) {
  return formatNumber(value, 'currency');
}

export function formatDuration(value?: Duration) {
  if (value == null) {
    return '';
  }

  const normalized = Duration.fromObject({
    days: value.days,
    hours: value.hours,
    minutes: value.as('minutes'),
  }).normalize();
  const dayFormat = `d 'Day${normalized.days > 1 ? 's' : ''}'`;
  const hourFormat = `h 'Hour${normalized.hours > 1 ? 's' : ''}'`;
  const minuteFormat = `m 'Minute${normalized.minutes > 1 ? 's' : ''}'`;

  const format =
    (normalized.days >= 1 ? `${dayFormat} ` : '') +
    (normalized.hours >= 1 ? `${hourFormat} ` : '') +
    (normalized.minutes >= 1 ? `${minuteFormat} ` : '');

  return normalized.toFormat(format.trim());
}

export function formatDurationAsHoursAndMinutes(value?: Duration) {
  if (value == null) {
    return '00:00';
  }

  const denormalized = value.shiftTo('hours', 'minutes');

  const format = (denormalized.hours > 99 ? 'h' : '') + 'hh:mm';

  return denormalized.toFormat(format);
}

export function formatDurationAsHoursAndDecimals(value?: Duration) {
  if (value == null) {
    return '0.00';
  }

  const denormalized = value.shiftTo('hours', 'minutes');

  const minutes = ((denormalized.minutes * 100) / 60).toFixed();

  const format = `${denormalized.hours < 10 ? '0' : ''}${denormalized.hours}.${
    minutes.length === 1 ? '0' : ''
  }${minutes}`;

  return format;
}
