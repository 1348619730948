import './TimesheetDayTotals.scss';
import { DurationFormat } from 'src/views/components/DurationFormat';
import { ITimesheetRecordsTotals } from 'src/views/routes/people/timesheet/MaintainTimesheet/MaintainTimesheet';

export interface ITimesheetDayTotalsProps {
  summary: ITimesheetRecordsTotals;
}

const TimesheetDayTotals: React.FC<ITimesheetDayTotalsProps> = ({ summary }) => {
  return (
    <div className="timesheet-day-totals-component">
      <table className="timesheet-day-totals-table">
        <tbody>
          {summary.totalWorkedHrs.valueOf() > 0 ? (
            <tr>
              <td>
                <label>Total worked hrs:</label>
              </td>
              <td>
                <DurationFormat value={summary.totalWorkedHrs} showHoursAndDecimals />
              </td>
            </tr>
          ) : (
            undefined
          )}
          {summary.totalLeaveHrs.valueOf() > 0 ? (
            <tr>
              <td>
                <label>Total leave hrs:</label>
              </td>
              <td>
                <DurationFormat value={summary.totalLeaveHrs} showHoursAndDecimals />
              </td>
            </tr>
          ) : (
            undefined
          )}
          {summary.totalPublicHolidayHrs.valueOf() > 0 ? (
            <tr>
              <td>
                <label>Total public holiday hrs:</label>
              </td>
              <td>
                <DurationFormat value={summary.totalPublicHolidayHrs} showHoursAndDecimals />
              </td>
            </tr>
          ) : (
            undefined
          )}
          <tr>
            <td>
              <label>Total paid hrs:</label>
            </td>
            <td>
              <DurationFormat value={summary.totalPaidHrs} showHoursAndDecimals />
            </td>
          </tr>
          {summary.totalOnCall > 0 && (
            <tr>
              <td>
                <label>Total paid on call days:</label>
              </td>
              <td>{summary.totalOnCall}</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default TimesheetDayTotals;
