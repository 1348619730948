import { buildListPageApiSearchModelTypedQuery } from 'src/domain/modelBuilders/buildListPageApiSearchModel';

type ListRailTemplatesQuery = Operations.Domain.Queries.ListRailTemplates.ListRailTemplatesQuery;

export const RailTemplatesForDropdownModel = buildListPageApiSearchModelTypedQuery<
  ListRailTemplatesQuery
>()(
  'RailTemplatesForDropdownModel',
  d => d.ajax.operations.rail.railTemplates.listRailTemplates(d.query),
  { size: 9999 }
);
