import { TeacherIcon } from 'src/images/icons';
import { JobType } from 'src/api/enums';
import {
  IModalActionButtonDef,
  ActionType,
  ShellModalSize,
  PaneType,
  FieldType,
} from 'src/views/definitionBuilders/types';
import { getSubmitCloseModalActionGroupDef } from 'src/views/definitionBuilders/common';
import {
  getStaffMemberFilterForJobType,
  mayJobTypeHaveRouteTraining,
} from 'src/views/routes/operations/shared/jobTypeHelpers';

type CreateRouteTrainingJobCommand = Operations.Domain.Commands.Job.CreateTrainingJob.CreateRouteTrainingJobCommand;

export default function getCreateRouteTrainingJobButtonDef(
  jobId: string,
  jobType: JobType,
  createTrainingJob: (command: CreateRouteTrainingJobCommand) => Promise<void>,
  assetId?: string
): IModalActionButtonDef {
  return {
    actionType: ActionType.modalActionButton,
    label: 'Create Route Training Job',
    icon: <TeacherIcon fixedWidth />,
    hidden: !mayJobTypeHaveRouteTraining(jobType),
    modalSize: ShellModalSize.oneQuarter,
    modalDef: () => ({
      title: 'Create Route Training Job',
      asForm: true,
      explicitData: { jobType },
      panels: [
        {
          panes: [
            {
              paneType: PaneType.customPane,
              render: () => <p>Are you sure you want to create a route training job?</p>,
            },
            {
              paneType: PaneType.formFieldsPane,
              fields: [
                {
                  fieldType: FieldType.staffMemberField,
                  label: 'Trainee Staff Member',
                  dataAddr: 'staffMember',
                  staffMemberFilter: d => getStaffMemberFilterForJobType(d.panelValue.jobType.id),
                  assetId: assetId,
                },
              ],
            },
          ],
        },
      ],
      secondaryActions: [getSubmitCloseModalActionGroupDef()],
      onFormSubmit: d => {
        return createTrainingJob({
          parentJobId: jobId,
          allocatedStaffMemberId: d.staffMember ? d.staffMember.id : undefined,
        });
      },
    }),
  };
}
