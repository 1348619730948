import styles from './IconButton.module.scss';
import { SingleClickAwareButton } from '../SingleClickAwareButton/SingleClickAwareButton';
import cn from 'classnames';

interface IIconButtonProps {
  onClick: () => void;
  icon: React.ReactNode;
  label?: string;
  color?: 'light' | 'dark';
}

function IconButton({ onClick, icon, label, color }: IIconButtonProps) {
  return (
    <SingleClickAwareButton
      onClick={onClick}
      className={cn(styles.iconButton, color && styles[color])}>
      {icon}
      {label ? <span>{label}</span> : null}
    </SingleClickAwareButton>
  );
}

export default IconButton;
