import { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import {
  PagePrimarySize,
  PaneType,
  FieldType,
  IFieldData,
  ActionType,
  ShellModalSize,
} from 'src/views/definitionBuilders/types';
import CrudPage, { CrudPageMode, ICrudPageDef } from 'src/views/components/Page/pages/CrudPage';
import { Weekday } from 'src/domain';
import {
  parseEditingFormattedTimeString,
  getEditingFormattedTimeString,
} from 'src/views/components/Page/fields/subfields/TimeHelpers';
import {
  validateTime,
  workingHours,
  getDays,
  daySelected,
} from 'src/views/routes/operations/urban/urbanHelpers';
import { TrashIcon } from 'src/images/icons';
import { isShiftTypeWithoutAsset } from 'src/views/routes/operations/shared/shiftTypeHelpers';
import { ShiftType } from 'src/api/enums';
import { getSkillSpecRequirementFieldDefs } from 'src/views/routes/operations/shared/getSkillSpecRequirementFieldDefs';
import {
  ISplittedSkillSpecRequirements,
  consolidateSkillSpecRequirements,
  splitSkillSpecRequirements,
} from 'src/domain/entities/people/staffMember/SkillSpecsHelpers';
import {
  ISplittedTechSpecRequirements,
  consolidateTechSpecRequirements,
  splitTechSpecs,
  splitTechSpecRequirements,
} from 'src/domain/entities/workshop/techSpecs/TechSpecsHelpers';
import { getTechSpecRequirementFieldDefs } from 'src/views/routes/operations/shared/getTechSpecRequirementFieldDefs';
import getAddAttachmentPanelDef from 'src/views/routes/operations/attachment/getAddAttachmentPanelDef';
import { getSubmitCloseModalActionGroupDef } from 'src/views/definitionBuilders/common';
import { observer } from 'mobx-react';
import { useRootStore } from 'src/domain/entities/RootStoreModel';
import { IPanelDef } from '../../../../../../views/definitionBuilders/types/panel';
import { PaneDefs } from '../../../../../../views/definitionBuilders/types/pane';
import { IAutocompleteResult } from 'src/domain/baseTypes';
import getExtraPanelDef from './getExtraPanelDef';
import getContractShiftSchedulePaneDef from './getContractShiftSchedulePaneDef';
import getRoutesPanelDef from './getRoutesPanelDef';
import { IFormApiWithoutState } from 'src/views/components/Page/forms/base';
import { ChangeState } from 'src/api/enums';

type ShiftItem = Operations.Domain.Queries.ViewShift.ShiftItem;
type ContractShiftRoute = Operations.Domain.Queries.ViewShift.ShiftRoute & {
  changeState: ChangeState;
};
type ContractShiftExtra = Operations.Domain.Queries.ViewShift.ShiftExtra & {
  changeState: ChangeState;
};
type CreateShiftCommand = Operations.Domain.Commands.Shift.CreateShift.CreateShiftCommand;
type ShiftRouteItem = Operations.Domain.Commands.Shift.ShiftRouteItem;
type ShiftExtraItem = Operations.Domain.Commands.Shift.ShiftExtraItem;
type UpdateShiftCommand = Operations.Domain.Commands.Shift.UpdateShift.UpdateShiftCommand;
type CustomerItem = Operations.Domain.Queries.SearchCustomers.CustomerItem;
type CharterContractByCustomerItem = Operations.Domain.Queries.GetCharterContractByCustomer.CharterContractItem;
type VehicleType = Common.Dtos.VehicleTypeSelectListItem;
type CreateBoardingPointCommand = Operations.Domain.Commands.BoardingPoint.CreateBoardingPointCommand;
type JobSubType = Operations.Domain.Queries.ListJobSubType.JobSubTypeItem;
type Depot = Common.Dtos.OperationsDepotDto;
export interface IMaintainShiftProps {
  mode: CrudPageMode;
  route: RouteComponentProps<{ [x: string]: string | undefined }>;
}

type IMaintainShiftForm = ISplittedSkillSpecRequirements &
  ISplittedTechSpecRequirements & {
    departDepot?: string;
    arriveDepot?: string;
    description: string;
    shiftName: string;
    shiftType: Operations.Domain.AggregatesModel.ShiftAggregate.ShiftType;
    clockOn: string;
    departingFromDepot: Common.Dtos.OperationsDepotDto;
    departingFromDepotInCar?: boolean;
    shiftCommence?: string;
    shiftEnd?: string;
    onSite?: string;
    arrivingAtDepot: Common.Dtos.OperationsDepotDto;
    arrivingAtDepotInCar?: boolean;
    notes: string;
    skipPredepartureChecks?: boolean;
    runsOnPublicHoliday?: boolean;
    clockOff: string;
    unpaidBreaks: string;
    days: number[];
    asset: Common.Queries.Workshop.GetSimpleFleetAssetList.SimpleAssetItem;
    customer: { customerId: string; customerName: string };
    contract: { id: string; name: string };
    vehicleType: VehicleType;
    jobSubType: JobSubType;
    depot: Depot;
    routes: ContractShiftRoute[];
    extras: ContractShiftExtra[];
  };

const MaintainShift: React.FC<IMaintainShiftProps> = observer((props: IMaintainShiftProps) => {
  const [primaryFormApi, setFormApi] = useState<IFormApiWithoutState | undefined>(undefined);
  const rootStore = useRootStore();
  const isUpdateMode = props.mode === 'update';
  const isCreateMode = props.mode === 'create';
  const shiftId = isUpdateMode ? props.route.match.params.id! : '';

  const canManageShifts = rootStore.account.isOperationsDepartmentMember;
  const operationsModel = rootStore.operations;
  const urbanModel = operationsModel.urban;
  const sales = rootStore.operations.sales;
  const shiftModel = urbanModel.shift;
  const shift = Object.assign({}, urbanModel.shift.shift);
  const attachmentsModel = rootStore.operationsAttachments;
  const assetsModel = rootStore.assets;
  const depots = rootStore.operationsStartup.operationsDepots.slice();
  const defaultDepot = rootStore.operationsStartup.defaultOperationsDepot;

  const skillSpecs = rootStore.people.skillSpecs.forRequirements.items.slice();
  const loadSkillSpecs = rootStore.people.skillSpecs.forRequirements.getAll;
  const techSpecs = rootStore.workshop.techSpecs.forRequirements.items.slice();
  const loadTechSpecs = rootStore.workshop.techSpecs.forRequirements.getAll;
  const getTechSpecDropdownsOptions = rootStore.workshop.techSpecs.getTechSpecDropdownsOptions;
  const techSpecDropdownOptions = rootStore.workshop.techSpecs.techSpecDropdownOptions;
  const searchCustomers = sales.customer.searchCustomers;
  const getChartContractsByCustomer =
    operationsModel.charterContracts.getCharterContractsByCustomer;
  const charterContractsByCustomer = operationsModel.charterContracts.charterContractsByCustomer.slice();
  const boardingPointModel = sales.boardingPoint;
  const getVehicleTypeAllocationData = operationsModel.allocations.getVehicleTypeAllocationData;
  const extraTypes = operationsModel.extraTypes.list.charterExtraTypes.slice();
  const loadExtraTypes = operationsModel.extraTypes.list.loadExtraTypes;
  const vehicleTypes = operationsModel.vehicleTypes.vehicleTypes.slice();
  const loadVehicleTypes = operationsModel.vehicleTypes.loadVehicleTypes;
  const loadJobSubTypes = operationsModel.job.loadJobSubTypes;
  const jobSubTypes = operationsModel.job.jobSubTypes.slice();
  const addAttachment = attachmentsModel.addOperationsAttachment;
  const loadAttachment = attachmentsModel.loadOperationsAttachmentDetails;
  const downloadAttachment = attachmentsModel.downloadOperationsAttachment;
  const deleteAttachment = attachmentsModel.deleteOperationsAttachment;
  const updateAttachment = attachmentsModel.updateOperationsAttachment;
  const attachments = attachmentsModel.attachments.slice();
  const swapAttachments = attachmentsModel.swapOperationsAttachment;

  const [selectedShiftType, setSelectedShiftType] = useState<ShiftType | null>(null);

  const setFieldValue = (field: string, value: any) => {
    if (primaryFormApi) {
      primaryFormApi.setValue(field, value);
    }
  };

  const filterJobSubTypes = (shiftTypeId: number) =>
    jobSubTypes.filter(subtype => subtype.jobTypeId === shiftTypeId);

  const isShiftTypeContractCharter = (shiftTypeId: number) =>
    shiftTypeId === ShiftType.ContractCharter;
  const isShiftTypeSchool = (shiftTypeId: number) =>
    shiftTypeId === ShiftType.SchoolService || shiftTypeId === ShiftType.Urban;
  const isShiftTypeUrban = (shiftTypeId: number) => shiftTypeId === ShiftType.Urban;

  useEffect(() => {
    setFieldValue('jobSubType', null);
  }, [selectedShiftType]);

  const handlePreSubmitForCreate = async (
    shift: IMaintainShiftForm
  ): Promise<CreateShiftCommand> => {
    return {
      description: shift.description,
      shiftName: shift.shiftName,
      shiftTypeId: shift.shiftType.id,
      clockOn: shift.clockOn,
      departDepot: isShiftTypeWithoutAsset(shift.shiftType.id) ? undefined : shift.departDepot,
      arriveDepot: isShiftTypeWithoutAsset(shift.shiftType.id) ? undefined : shift.arriveDepot,
      clockOff: shift.clockOff,
      onSite: isShiftTypeContractCharter(shift.shiftType.id) ? shift.onSite : undefined,
      unpaidBreaks: shift.unpaidBreaks,
      departingFromDepotId: isShiftTypeWithoutAsset(shift.shiftType.id)
        ? undefined
        : shift.departingFromDepot.id,
      departingFromDepotInCar: isShiftTypeWithoutAsset(shift.shiftType.id)
        ? undefined
        : shift.departingFromDepotInCar,
      shiftCommence:
        isShiftTypeSchool(shift.shiftType.id) ||
        isShiftTypeUrban(shift.shiftType.id) ||
        isShiftTypeContractCharter(shift.shiftType.id)
          ? shift.shiftCommence
          : undefined,
      shiftEnd:
        isShiftTypeSchool(shift.shiftType.id) ||
        isShiftTypeUrban(shift.shiftType.id) ||
        isShiftTypeContractCharter(shift.shiftType.id)
          ? shift.shiftEnd
          : undefined,
      arrivingAtDepotId: isShiftTypeWithoutAsset(shift.shiftType.id)
        ? undefined
        : shift.arrivingAtDepot.id,
      arrivingAtDepotInCar: isShiftTypeWithoutAsset(shift.shiftType.id)
        ? undefined
        : shift.arrivingAtDepotInCar,
      notes: shift.notes,
      monday: daySelected(shift.days, Weekday.mon),
      tuesday: daySelected(shift.days, Weekday.tue),
      wednesday: daySelected(shift.days, Weekday.wed),
      thursday: daySelected(shift.days, Weekday.thu),
      friday: daySelected(shift.days, Weekday.fri),
      saturday: daySelected(shift.days, Weekday.sat),
      sunday: daySelected(shift.days, Weekday.sun),
      assetId: shift.asset && shift.asset.id,
      skipPredepartureChecks: !!shift.skipPredepartureChecks,
      runsOnPublicHoliday: shift.runsOnPublicHoliday,
      skillSpecRequirements: consolidateSkillSpecRequirements(shift),
      techSpecRequirements: consolidateTechSpecRequirements(shift, splittedTechSpecs),
      charterContractId: shift.contract && (shift.contract as CharterContractByCustomerItem)?.id,
      vehicleTypeId: shift.vehicleType && (shift.vehicleType as VehicleType)?.id,
      jobSubTypeId: shift.jobSubType?.id,
      depotId: shift.depot.id,
      routes: isShiftTypeContractCharter(shift.shiftType.id)
        ? shift.routes &&
          (shift.routes
            .filter(a => a.changeState !== ChangeState.Deleted)
            .map(route => {
              const location = route.location;
              return {
                boardingPointId: location.id,
                name: location.name,
                address: location.address,
                city: location.city,
                state: location.state,
                postcode: location.postcode,
                notes: location.notes,
                arrive: route.arrive,
                depart: route.depart,
              };
            }) as ShiftRouteItem[])
        : [],
      extras: isShiftTypeContractCharter(shift.shiftType.id)
        ? shift.extras &&
          ((Array.isArray(shift.extras) ? shift.extras : [])
            .filter(a => a.changeState !== ChangeState.Deleted)
            .map(extra => ({
              extraTypeId: extra.extraType.id,
              price: extra.price,
              quantity: extra.quantity,
              overridePrice: extra.overridePrice,
              unitPrice: !!extra.overridePrice ? undefined : extra.unitPrice,
            })) as ShiftExtraItem[])
        : [],
    };
  };

  const handlePreSubmitForUpdate = async (
    shift: IMaintainShiftForm
  ): Promise<UpdateShiftCommand> => {
    return {
      id: shiftId,
      departDepot: shift.departDepot,
      arriveDepot: shift.arriveDepot,
      description: shift.description,
      shiftName: shift.shiftName,
      shiftTypeId: shift.shiftType.id,
      clockOn: shift.clockOn,
      onSite: shift.onSite,
      departingFromDepotId: isShiftTypeWithoutAsset(shift.shiftType.id)
        ? undefined
        : shift.departingFromDepot.id,
      departingFromDepotInCar: shift.departingFromDepotInCar,
      shiftCommence: shift.shiftCommence,
      shiftEnd: shift.shiftEnd,
      arrivingAtDepotId: isShiftTypeWithoutAsset(shift.shiftType.id)
        ? undefined
        : shift.arrivingAtDepot.id,
      arrivingAtDepotInCar: shift.arrivingAtDepotInCar,
      notes: shift.notes,
      clockOff: shift.clockOff,
      unpaidBreaks: shift.unpaidBreaks,
      monday: daySelected(shift.days, Weekday.mon),
      tuesday: daySelected(shift.days, Weekday.tue),
      wednesday: daySelected(shift.days, Weekday.wed),
      thursday: daySelected(shift.days, Weekday.thu),
      friday: daySelected(shift.days, Weekday.fri),
      saturday: daySelected(shift.days, Weekday.sat),
      sunday: daySelected(shift.days, Weekday.sun),
      assetId: shift.asset && shift.asset.id,
      skipPredepartureChecks: !!shift.skipPredepartureChecks,
      runsOnPublicHoliday: !!shift.runsOnPublicHoliday,
      skillSpecRequirements: consolidateSkillSpecRequirements(shift),
      techSpecRequirements: consolidateTechSpecRequirements(shift, splittedTechSpecs),
      vehicleTypeId: shift.vehicleType && (shift.vehicleType as VehicleType)?.id,
      jobSubTypeId: shift.jobSubType?.id,
      depotId: shift.depot.id,
      routes:
        shift.routes &&
        (shift.routes
          .filter(a => a.changeState !== ChangeState.Deleted)
          .map(route => {
            const location = route.location;
            return {
              boardingPointId: location.id,
              name: location.name,
              address: location.address,
              city: location.city,
              state: location.state,
              postcode: location.postcode,
              notes: location.notes,
              arrive: route.arrive,
              depart: route.depart,
            };
          }) as ShiftRouteItem[]),
      extras:
        shift.extras &&
        ((Array.isArray(shift.extras) ? shift.extras : [])
          .filter(a => a.changeState !== ChangeState.Deleted)
          .map(extra => ({
            extraTypeId: extra.extraType.id,
            price: extra.price,
            quantity: extra.quantity,
            overridePrice: extra.overridePrice,
            unitPrice: !!extra.overridePrice ? undefined : extra.unitPrice,
          })) as ShiftExtraItem[]),
    };
  };

  const shiftData = (shift: ShiftItem | undefined): IMaintainShiftForm => {
    if (!shift) {
      return { skipPredepartureChecks: false } as IMaintainShiftForm;
    }
    return {
      ...splitSkillSpecRequirements(shift.skillSpecRequirements),
      ...splitTechSpecRequirements(shift.techSpecRequirements),
      departDepot: shift.departDepot,
      arriveDepot: shift.arriveDepot,
      description: shift.description,
      shiftName: shift.shiftName,
      shiftType: shift.shiftType,
      clockOn: shift.clockOn,
      clockOff: shift.clockOff,
      onSite: shift.onSite,
      departingFromDepot: shift.departingFromDepot,
      departingFromDepotInCar: shift.departingFromDepotInCar,
      shiftCommence: shift.shiftCommence,
      shiftEnd: shift.shiftEnd,
      arrivingAtDepot: shift.arrivingAtDepot,
      arrivingAtDepotInCar: shift.arrivingAtDepotInCar,
      notes: shift.notes,
      unpaidBreaks: shift.unpaidBreaks,
      days: getDays(shift),
      asset: shift.asset,
      skipPredepartureChecks: !!shift.skipPredepartureChecks,
      runsOnPublicHoliday: shift.runsOnPublicHoliday,
      customer: shift.contract && shift.contract.customer,
      contract: shift.contract && shift.contract,
      vehicleType: shift.vehicleType,
      jobSubType: shift.jobSubType,
      depot: shift.depot,
      routes: shift.routes && (shift.routes as ContractShiftRoute[]),
      extras: shift.extras && (shift.extras as ContractShiftExtra[]),
    };
  };

  const isEmptyOrWithoutAsset = (data: IFieldData<string>) => {
    return !data.panelValue.shiftType || isShiftTypeWithoutAsset(data.panelValue.shiftType.id);
  };

  const splittedTechSpecs = splitTechSpecs(techSpecs);

  const getDetailsPanel = (
    isUpdateMode: boolean,
    shiftName: string,
    searchCustomers: (search: string) => Promise<IAutocompleteResult<CustomerItem>>,
    getChartContractsByCustomer: (customerId: string) => Promise<void>,
    charterContractsByCustomer: CharterContractByCustomerItem[]
  ): IPanelDef => {
    const getContractChartFields = (): PaneDefs => {
      let fields: PaneDefs = {
        paneType: PaneType.formFieldsPane,
        columnCount: 2,
        hidden: d => !isShiftTypeContractCharter(d.panelValue.shiftType?.id),
        fields: [
          {
            fieldType: FieldType.selectAsyncField,
            dataAddr: 'customer',
            label: 'Customer',
            valueKey: 'customerId',
            descriptionKey: 'customerName',
            mandatory: true,
            loadOptionItems: searchCustomers,
            readonly: isUpdateMode,
            onChange: data => {
              if (data.newFieldValue && data.newFieldValue.customerId) {
                if (data.getFormValue(['contract'])) {
                  data.setFormValue(['contract'], undefined);
                }
                getChartContractsByCustomer(data.newFieldValue.customerId);
              }
            },
          },
          {
            fieldType: FieldType.selectField,
            dataAddr: 'contract',
            label: 'Contract',
            valueKey: 'id',
            descriptionKey: 'name',
            mandatory: true,
            readonly: data => !data.parentValue.customer || isUpdateMode,
            optionItems: charterContractsByCustomer,
          },
        ],
      };

      return fields;
    };

    const panel: IPanelDef = {
      title: 'Details',
      panes: [
        {
          paneType: PaneType.formFieldsPane,
          columnCount: 2,
          fields: [
            {
              fieldType: FieldType.textField,
              label: 'Shift Name',
              dataAddr: 'shiftName',
              maxLength: 200,
              mandatory: true,
              validateAsync: async d => {
                if (
                  !d.fieldValue ||
                  (isUpdateMode &&
                    shiftName &&
                    shiftName.toUpperCase() === d.fieldValue.toUpperCase())
                ) {
                  return undefined;
                }
                const result = await shiftModel.checkForUniqueShiftName(d.fieldValue);
                return result.nameExists ? `Shift name is already in use` : undefined;
              },
            },
            {
              fieldType: FieldType.selectField,
              dataAddr: 'shiftType',
              label: 'Shift Type',
              valueKey: 'id',
              descriptionKey: 'description',
              optionItems: shiftModel.shiftTypes.slice(),
              mandatory: true,
              readonly: isUpdateMode,
              onChange: data => {
                setSelectedShiftType(data.fieldData.parentValue.shiftType?.id);
              },
            },
            {
              fieldType: FieldType.selectField,
              dataAddr: 'jobSubType',
              label: 'Job Subtype',
              valueKey: 'id',
              descriptionKey: 'description',
              optionItems: data => {
                if (!!data.parentValue.shiftType) {
                  var filteredJobSubTypes = filterJobSubTypes(data.parentValue.shiftType?.id);
                  return filteredJobSubTypes;
                } else {
                  return jobSubTypes;
                }
              },
              hidden: api => filterJobSubTypes(api.parentValue.shiftType?.id).length === 0,
            },
          ],
        },
        getContractChartFields(),
        {
          paneType: PaneType.formFieldsPane,
          columnCount: 2,
          fields: [
            {
              fieldType: FieldType.textField,
              label: 'Description',
              dataAddr: 'description',
              mandatory: true,
              maxLength: 200,
            },
            {
              fieldType: FieldType.selectField,
              dataAddr: 'depot',
              label: 'Depot',
              valueKey: 'id',
              descriptionKey: 'description',
              optionItems: depots,
              readonly: depots.length === 1,
              mandatory: true,
            },
          ],
        },
        {
          paneType: PaneType.formFieldsPane,
          fields: [
            {
              fieldType: FieldType.daysOfWeekField,
              dataAddr: 'days',
              label: 'Days of Week',
              mandatory: true,
              useValueOnly: true,
            },
          ],
        },
        {
          paneType: PaneType.formFieldsPane,
          columnCount: 2,
          fields: [
            {
              fieldType: FieldType.yesNoField,
              label: 'Skip Predeparture Checks',
              dataAddr: 'skipPredepartureChecks',
              mandatory: true,
            },
            {
              fieldType: FieldType.yesNoField,
              label: 'Runs on a Public Holiday',
              dataAddr: 'runsOnPublicHoliday',
              tooltip:
                'Select whether or not a job should be generated if a public holiday falls on one of the selected days of the week',
              mandatory: true,
            },
          ],
        },
        {
          paneType: PaneType.formFieldsPane,
          fields: [
            {
              fieldType: FieldType.textAreaField,
              label: 'Notes',
              dataAddr: 'notes',
            },
          ],
        },
      ],
    };

    return panel;
  };

  const getSchedulePanel = (): IPanelDef => {
    const panel: IPanelDef = {
      title: 'Schedule',
      hidden: d => isShiftTypeContractCharter(d.panelValue.shiftType?.id),
      panes: [
        {
          paneType: PaneType.formFieldsPane,
          columnCount: 3,
          fields: [
            {
              fieldType: FieldType.timeField,
              label: 'Clock On',
              dataAddr: 'clockOn',
              mandatory: true,
              validate: d => validateTime(d.fieldValue),
            },
            {
              fieldType: FieldType.timeField,
              label: 'Depart Depot',
              dataAddr: 'departDepot',
              mandatory: true,
              hidden: d => isEmptyOrWithoutAsset(d),
              validate: d => validateTime(d.fieldValue),
            },
            {
              fieldType: FieldType.timeField,
              label: 'Shift Commence',
              dataAddr: 'shiftCommence',
              mandatory: true,
              hidden: d => isEmptyOrWithoutAsset(d),
              validate: d => validateTime(d.fieldValue),
            },
            {
              fieldType: FieldType.timeField,
              label: 'Shift End',
              dataAddr: 'shiftEnd',
              mandatory: true,
              hidden: d => isEmptyOrWithoutAsset(d),
              validate: d => validateTime(d.fieldValue),
            },

            {
              fieldType: FieldType.timeField,
              label: 'Arrive Depot',
              dataAddr: 'arriveDepot',
              mandatory: true,
              hidden: d => isEmptyOrWithoutAsset(d),
              validate: d => validateTime(d.fieldValue),
            },
            {
              fieldType: FieldType.timeField,
              label: 'Clock Off',
              dataAddr: 'clockOff',
              mandatory: true,
              validate: d => validateTime(d.fieldValue),
              tooltip: data =>
                data.parentValue.arriveDepot > data.parentValue.clockOff
                  ? 'Clock Off will be on the following day if the time is less than Arrive Depot'
                  : undefined,
            },
            {
              fieldType: FieldType.durationField,
              label: 'Unpaid Breaks',
              dataAddr: 'unpaidBreaks',
              mandatory: true,
              validate: d => {
                const timeValidation = validateTime(d.fieldValue);
                if (!!timeValidation) {
                  return timeValidation;
                }

                const hoursValidation = workingHours(
                  d.parentValue.clockOn,
                  d.parentValue.clockOff,
                  d.parentValue.unpaidBreaks
                );

                if (!!hoursValidation && hoursValidation.as('minutes') < 0) {
                  return 'Paid time cannot be less than zero';
                }

                return undefined;
              },
              formatReadonly: d =>
                getEditingFormattedTimeString(parseEditingFormattedTimeString(d.fieldValue)),
            },
            {
              fieldType: FieldType.readonlyField,
              label: 'Paid Time',
              formatReadonly: data => {
                const hours = workingHours(
                  data.parentValue.clockOn,
                  data.parentValue.clockOff,
                  data.parentValue.unpaidBreaks
                );
                if (!!hours) {
                  return hours.toFormat('hh:mm');
                }
                return undefined;
              },
            },
          ],
        },
      ],
    };

    return panel;
  };

  const getVehicleMovementsPanel = (): IPanelDef => {
    const panel: IPanelDef = {
      title: 'Vehicle Movements',
      hidden: d => !d.panelValue.shiftType || isShiftTypeWithoutAsset(d.panelValue.shiftType.id),
      panes: [
        {
          paneType: PaneType.formFieldsPane,
          columnCount: 4,
          fields: [
            {
              fieldType: FieldType.selectField,
              label: 'Departing From Depot',
              dataAddr: 'departingFromDepot',
              valueKey: 'id',
              descriptionKey: 'description',
              mandatory: true,
              optionItems: depots,
            },
            {
              fieldType: FieldType.yesNoField,
              label: 'Departing From Depot In Car',
              dataAddr: 'departingFromDepotInCar',
              mandatory: true,
            },
            {
              fieldType: FieldType.selectField,
              label: 'Arriving At Depot',
              dataAddr: 'arrivingAtDepot',
              valueKey: 'id',
              descriptionKey: 'description',
              mandatory: true,
              optionItems: depots,
            },
            {
              fieldType: FieldType.yesNoField,
              label: 'Arriving At Depot In Car',
              dataAddr: 'arrivingAtDepotInCar',
              mandatory: true,
            },
          ],
        },
      ],
    };

    return panel;
  };

  const getAllocationsPanel = (): IPanelDef => {
    const panel: IPanelDef = {
      title: 'Allocations',
      hidden: d => !d.panelValue.shiftType || isShiftTypeWithoutAsset(d.panelValue.shiftType.id),
      panes: [
        {
          paneType: PaneType.formFieldsPane,
          columnCount: 2,
          fields: [
            {
              fieldType: FieldType.assetSelectField,
              label: 'Vehicle',
              dataAddr: 'asset',
              optionItems: assetsModel.fleetAssetListItems.slice(),
              valueKey: 'id',
              descriptionKey: 'name',
              techSpecRequirements: d =>
                consolidateTechSpecRequirements(d.parentValue, splittedTechSpecs),
            },
          ],
        },
      ],
    };

    return panel;
  };

  const getStaffMemberRequirementsPanel = (): IPanelDef => {
    const panel: IPanelDef = {
      title: 'Staff Member Requirements',
      panes: [
        {
          paneType: PaneType.formFieldsPane,
          columnCount: 2,
          fields: [...getSkillSpecRequirementFieldDefs(skillSpecs)],
        },
      ],
    };

    return panel;
  };

  const getVehicleRequirementsPanel = (): IPanelDef => {
    const panel: IPanelDef = {
      title: 'Vehicle Requirements',
      hidden: d => !d.panelValue.shiftType || isShiftTypeWithoutAsset(d.panelValue.shiftType.id),
      panes: [
        {
          paneType: PaneType.formFieldsPane,
          columnCount: 3,
          fields: getTechSpecRequirementFieldDefs(
            splittedTechSpecs,
            assetsModel.searchTechSpecValues,
            techSpecDropdownOptions
          ),
        },
      ],
    };

    return panel;
  };

  const getVehicleTypeField = (vehicleTypes: VehicleType[]): IPanelDef => {
    const panel: IPanelDef = {
      title: 'Vehicle Type',
      hidden: d => !isShiftTypeContractCharter(d.panelValue.shiftType?.id),
      panes: [
        {
          paneType: PaneType.formFieldsPane,
          columnCount: 1,
          fields: [
            {
              fieldType: FieldType.selectField,
              dataAddr: 'vehicleType',
              label: 'Vehicle Type',
              valueKey: 'id',
              descriptionKey: 'description',
              optionItems: vehicleTypes,
              mandatory: true,
            },
          ],
        },
      ],
    };

    return panel;
  };

  const getPageDef = (isUpdating: boolean): ICrudPageDef => {
    const editable = !isUpdateMode || isUpdating;

    return {
      primarySize: PagePrimarySize.twoThirds,
      primarySection: {
        title: isUpdateMode ? 'Manage Shift' : 'Create a Shift',
        getApi: api => setFormApi(api),
        primaryActions: !editable
          ? [
              {
                actions: [
                  {
                    hidden: () => !canManageShifts,
                    actionType: ActionType.actionCollection,
                    actionGroups: [
                      {
                        actions: [
                          {
                            actionType: ActionType.modalActionButton,
                            label: 'Delete Shift',
                            icon: <TrashIcon />,
                            modalSize: ShellModalSize.oneQuarter,
                            modalDef: () => ({
                              title: 'Delete Shift',
                              asForm: true,
                              panels: [
                                {
                                  panes: [
                                    {
                                      paneType: PaneType.customPane,
                                      render: () => (
                                        <span>
                                          This will delete shift and remove it from all rosters. Are
                                          you sure you want to delete shift?
                                        </span>
                                      ),
                                    },
                                  ],
                                },
                              ],
                              secondaryActions: [getSubmitCloseModalActionGroupDef('Ok')],
                              onFormSubmit: () => shiftModel.deleteShift(shiftId),
                            }),
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ]
          : [],
        panels: [
          getDetailsPanel(
            isUpdateMode,
            shift.shiftName,
            searchCustomers,
            getChartContractsByCustomer,
            charterContractsByCustomer
          ),
          getRoutesPanelDef(
            editable,
            boardingPointModel.states.slice(),
            boardingPointModel.searchBoardingPoints,
            boardingPointModel.foundBoardingPoints.slice(),
            getVehicleTypeAllocationData,
            boardingPointModel.checkForUniqueName,
            (cmd: CreateBoardingPointCommand) => boardingPointModel.createBoardingPoint(cmd, true),
            primaryFormApi,
            shift.departingFromDepot?.id ?? (depots && depots[0] && depots[0].id) ?? undefined
          ),
          getVehicleTypeField(vehicleTypes),
          getExtraPanelDef(extraTypes, editable, 'shift'),
          getContractShiftSchedulePaneDef(),
          getSchedulePanel(),
          getVehicleMovementsPanel(),
          getAllocationsPanel(),
          getStaffMemberRequirementsPanel(),
          getVehicleRequirementsPanel(),
        ],
        onFormPreSubmit: isUpdateMode ? handlePreSubmitForUpdate : handlePreSubmitForCreate,
        onFormSubmit: isUpdateMode ? shiftModel.updateShift : shiftModel.createShift,
      },
      secondarySections: !isCreateMode
        ? [
            getAddAttachmentPanelDef(
              (shift && shift.id) || '',
              'shift',
              addAttachment,
              loadAttachment,
              downloadAttachment,
              deleteAttachment,
              updateAttachment,
              attachments,
              swapAttachments
            ),
          ]
        : [],
    };
  };

  const loadData = () => {
    const promises = [
      shiftModel.loadShiftTypes(),
      assetsModel.loadFleetAssets(),
      loadSkillSpecs(),
      loadTechSpecs(),
      getTechSpecDropdownsOptions(),
      boardingPointModel.loadStates(),
      loadExtraTypes(),
      loadVehicleTypes(),
      loadJobSubTypes(),
      isUpdateMode ? shiftModel.loadShift(shiftId) : Promise.resolve(),
      isUpdateMode
        ? attachmentsModel.loadOperationsAttachmentDetails({
            aggregateId: shiftId,
            aggregateType: 'shift',
          })
        : Promise.resolve(),
    ];
    return Promise.all(promises).then(() => Promise.resolve());
  };

  const { mode } = props;
  return (
    <CrudPage
      def={api => getPageDef(api.updating)}
      mode={mode}
      isEditingForbidden={!canManageShifts}
      onLoadData={loadData}
      onLoadCreateDefaultData={loadData}
      data={shiftData(shift)}
      createDefaultData={{
        clockOn: undefined,
        depot: defaultDepot,
        departDepot: undefined,
        arriveDepot: undefined,
        clockOff: undefined,
        unpaidBreaks: undefined,
        skipPredepartureChecks: false,
        routes: [{ changeState: ChangeState.Added }, { changeState: ChangeState.Added }],
      }}
    />
  );
});

export default MaintainShift;
