import { Component } from 'react';
import { INestingPaneDef, IPaneData, IPaneMeta } from 'src/views/definitionBuilders/types';
import PagePane from './PagePane';
import cn from 'classnames';

import './NestingPagePane.scss';

interface INestingPagePaneProps {
  paneDef: INestingPaneDef;
  paneData: IPaneData;
  paneMeta: IPaneMeta;
}

class NestingPagePane extends Component<INestingPagePaneProps> {
  render() {
    const { paneDef, paneData, paneMeta } = this.props;
    return (
      <div
        className={cn('nesting-page-pane-component', {
          'horizontal-scroll': paneDef.enableHorizontalScroll,
          'space-between-panes-horizontally': paneDef.spaceBetweenPanesHorizontally,
          'space-between-panes-vertically': paneDef.spaceBetweenPanesVertically,
        })}>
        {paneDef.panes.map((p, subPaneIdx) => (
          <PagePane
            key={subPaneIdx}
            paneDef={p}
            paneMeta={paneMeta}
            panelData={paneData}
            parentValue={paneData.parentValue}
          />
        ))}
      </div>
    );
  }
}

export default NestingPagePane;
