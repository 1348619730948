import styles from './Form.module.scss';

import { Field, getIn, FieldProps } from 'formik';
import cn from 'classnames';

interface IToggleFieldProps {
  dataAddress: string;
  className?: string;
  trueText: string;
  falseText: string;
  dataLabel?: string;
  readOnly?: boolean;
}

const ToggleField: React.FC<IToggleFieldProps> = ({
  dataAddress,
  className,
  trueText,
  falseText,
  dataLabel,
  readOnly,
}) => (
  <div className={cn(styles.fieldContainer, className)}>
    {dataLabel && <div className={styles.fieldLabel}>{dataLabel}</div>}
    <Field name={dataAddress}>
      {({ field, form, meta }: FieldProps) => {
        const error = getIn(form.errors, dataAddress);
        const touched = getIn(form.touched, dataAddress) || form.submitCount > 0; // https://github.com/jaredpalmer/formik/issues/691#issuecomment-446509600

        return (
          <div>
            <div className={styles.toggleField}>
              <div
                onClick={() => {
                  if (!readOnly) {
                    form.setFieldTouched(dataAddress);
                    form.setFieldValue(dataAddress, field.value === true ? undefined : true);
                  }
                }}
                className={cn(styles.trueButton, field.value ? styles.selected : '')}>
                {trueText}
              </div>
              <div
                onClick={() => {
                  if (!readOnly) {
                    form.setFieldTouched(dataAddress);
                    form.setFieldValue(dataAddress, field.value === false ? undefined : false);
                  }
                }}
                className={cn(styles.falseButton, field.value === false ? styles.selected : '')}>
                {falseText}
              </div>
            </div>
            {(form.submitCount > 0 || touched) && error && (
              <div className={styles.error}>Required</div>
            )}
          </div>
        );
      }}
    </Field>
  </div>
);

export default ToggleField;
