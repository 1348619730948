import { DateTime, Info } from 'luxon';
import { observer } from 'mobx-react';
import { useEffect } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { ChangeState } from 'src/api/enums';
import { Weekday } from 'src/domain';
import { ListPageLoadCause } from 'src/domain/baseTypes';
import { useRootStore } from 'src/domain/entities/RootStoreModel';
import { TrashIcon } from 'src/images/icons';
import Omit from 'src/infrastructure/omit';
import { IWeekBounds } from 'src/views/components/Page/fields/WeekSelectField';
import CrudPage, { ICrudPageDef } from 'src/views/components/Page/pages/CrudPage';
import { TaskCardItem, toActionLinkDef } from 'src/views/components/TaskCard';
import WorkshopShiftConflictIcon from 'src/views/components/workshop/shiftConflictIcon/WorkshopShiftConflictIcon';
import WorkshopShiftProgressStatusIcon from 'src/views/components/workshop/shiftProgressIcon/WorkshopShiftProgressStatusIcon';
import WorkshopShiftProgressStatusIconLegend from 'src/views/components/workshop/shiftProgressIconLegend/WorkshopShiftProgressStatusIconLegend';
import { getSubmitCloseModalActionGroupDef } from 'src/views/definitionBuilders/common';
import {
  ActionType,
  FieldDefs,
  FieldType,
  IHasChangeState,
  PagePrimarySize,
  PaneType,
  ShellModalSize,
} from 'src/views/definitionBuilders/types';
import {
  daySelected,
  getDays,
  getDaysToExclude,
} from 'src/views/routes/operations/urban/urbanHelpers';
import { ConsolidatedRosterShifts } from '../shared/ConsolidatedRosterShifts';
import styles from './MaintainRoster.module.scss';

type UpdateRosterCommand = Workshop.Domain.Commands.Roster.UpdateRoster.UpdateRosterCommand;
type RosterItem = Workshop.Domain.Queries.Roster.GetRoster.RosterItem;
type RosterConsolidatedShiftItem = Workshop.Domain.Queries.Roster.GetRoster.RosterConsolidatedShiftItem;
type ShiftItem = Workshop.Domain.Queries.Roster.GetRoster.RosterConsolidatedStaffMemberWeekItem.ShiftItem;
type RosterConsolidatedStaffMemberWeekItem = Workshop.Domain.Queries.Roster.GetRoster.RosterConsolidatedStaffMemberWeekItem;

type IMaintainRosterForm = Omit<RosterItem, 'editableShifts' | 'readOnlyShifts'> & {
  rosterWeek: IWeekBounds;
  editableShifts: (RosterConsolidatedShiftItem &
    IHasChangeState & {
      days: Weekday[];
    })[];
  readOnlyShifts: (RosterConsolidatedShiftItem & {
    days: Weekday[];
  })[];
};

interface IMaintainRosterProps {
  route: RouteComponentProps<{ [x: string]: string | undefined }>;
}

const MaintainRoster: React.FC<IMaintainRosterProps> = observer((props: IMaintainRosterProps) => {
  const rootStore = useRootStore();
  const canManageRosters = rootStore.account.isWorkshopDepartmentMember;

  const loadRoster = rootStore.workshop.roster.loadRoster;
  const roster = rootStore.workshop.roster.roster;
  const updateRoster = rootStore.workshop.roster.updateRoster;
  const deleteRoster = rootStore.workshop.roster.deleteRoster;

  const listShiftTemplates = rootStore.workshop.shiftTemplates.listItems;
  const shiftTemplates = rootStore.workshop.shiftTemplates.items.slice();

  const mechanics = rootStore.people.staffMembers.mechanics.slice();
  const loadMechanics = rootStore.people.staffMembers.loadMechanics;
  useEffect(() => {
    listShiftTemplates({ loadCause: ListPageLoadCause.mount, query: { size: 9999 } });
    loadMechanics();
  }, []);

  const rosterId = props.route.match.params.id!;

  const handlePreSubmitForUpdate = (rosterForm: IMaintainRosterForm): UpdateRosterCommand => {
    const command: UpdateRosterCommand = {
      rosterId: rosterForm.rosterId,
      existingShifts: rosterForm.shifts,
      updatedShifts: rosterForm.editableShifts
        .filter(x => !x.changeState || x.changeState !== ChangeState.Deleted)
        .map(x => {
          const { staffMembers, days, ...shifts } = x;
          return {
            ...shifts,
            monday: daySelected(days, Weekday.mon),
            tuesday: daySelected(days, Weekday.tue),
            wednesday: daySelected(days, Weekday.wed),
            thursday: daySelected(days, Weekday.thu),
            friday: daySelected(days, Weekday.fri),
            saturday: daySelected(days, Weekday.sat),
            sunday: daySelected(days, Weekday.sun),
            staffMemberIds: staffMembers.map(y => y.staffMemberId),
          };
        })
        .concat(
          rosterForm.readOnlyShifts.map(x => {
            const { staffMembers, days, ...shifts } = x;
            return {
              ...shifts,
              monday: daySelected(days, Weekday.mon),
              tuesday: daySelected(days, Weekday.tue),
              wednesday: daySelected(days, Weekday.wed),
              thursday: daySelected(days, Weekday.thu),
              friday: daySelected(days, Weekday.fri),
              saturday: daySelected(days, Weekday.sat),
              sunday: daySelected(days, Weekday.sun),
              staffMemberIds: staffMembers.map(y => y.staffMemberId),
            };
          })
        ),
    };
    return command;
  };

  const daysOfWeek = (): FieldDefs[] => {
    const days = Info.weekdays();
    const startingDay = roster && DateTime.fromFormat(roster?.weekStart, 'yyyy-MM-dd').weekdayLong;
    const startIndex = days.indexOf(startingDay!);
    const shiftedDaysOfWeek = [...days.slice(startIndex), ...days.slice(0, startIndex)];

    return shiftedDaysOfWeek.map(day => ({
      fieldType: FieldType.readonlyField,
      label: day,
      dataAddr: day.toLocaleLowerCase(),
      formatReadonly: (d: any) => shiftsForDisplay(d.fieldValue),
    }));
  };

  const rosterData = (roster: RosterItem | undefined): IMaintainRosterForm => {
    if (!roster || !shiftTemplates) {
      return {} as IMaintainRosterForm;
    }

    return {
      ...roster,
      rosterWeek: {
        weekStart: roster.weekStart,
        weekEnd: roster.weekEnd,
      },
      editableShifts: roster.editableShifts.map(x => {
        return {
          ...x,
          days: getDays(x),
        };
      }),
      readOnlyShifts: roster.readOnlyShifts.map(x => {
        return {
          ...x,
          days: getDays(x),
        };
      }),
    };
  };

  const getPageDef = (readonly: boolean, updating: boolean): ICrudPageDef => {
    return {
      primarySize: PagePrimarySize.full,
      primarySection: {
        title: 'Manage Roster',
        primaryActions: [
          {
            actions: [
              {
                actionType: ActionType.actionCollection,
                hidden: !readonly,
                actionGroups: [
                  {
                    actions: [
                      toActionLinkDef(TaskCardItem.workshop.viewWorkshopRosterChanges(rosterId)),
                      {
                        actionType: ActionType.modalActionButton,
                        label: 'Delete Roster',
                        icon: <TrashIcon />,
                        hidden: !canManageRosters || !roster,
                        modalSize: ShellModalSize.oneQuarter,
                        modalDef: () => ({
                          title: 'Delete Roster',
                          asForm: true,
                          panels: [
                            {
                              panes: [
                                {
                                  paneType: PaneType.customPane,
                                  render: () => (
                                    <div>
                                      <p>Are you sure you want to delete this Roster Template?</p>
                                    </div>
                                  ),
                                },
                              ],
                            },
                          ],
                          secondaryActions: [getSubmitCloseModalActionGroupDef('Delete')],
                          onFormSubmit: () => deleteRoster(rosterId),
                        }),
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
        panels: [
          {
            panes: [
              {
                paneType: PaneType.formFieldsPane,
                columnCount: 2,
                fields: [
                  {
                    fieldType: FieldType.textField,
                    label: 'Depot',
                    dataAddr: 'depotDescription',
                    readonly: true,
                  },
                ],
              },
            ],
          },
          {
            panes: [
              {
                paneType: PaneType.formFieldsPane,
                columnCount: 2,
                fields: [
                  {
                    fieldType: FieldType.weekSelectField,
                    label: 'Week',
                    dataAddr: 'rosterWeek',
                    onNext: () => undefined,
                    onPrevious: () => undefined,
                    readonly: true,
                  },
                  {
                    fieldType: FieldType.textField,
                    label: 'Roster Template',
                    dataAddr: 'rosterTemplateName',
                    readonly: true,
                  },
                  {
                    fieldType: FieldType.readonlyField,
                    label: 'Shifts',
                    dataAddr: 'consolidatedShifts',
                    formatReadonly: data => {
                      const consolidatedShifts = data.fieldValue as RosterConsolidatedShiftItem[];
                      return <ConsolidatedRosterShifts consolidatedShifts={consolidatedShifts} />;
                    },
                  },
                ],
              },
            ],
          },
          {
            title: 'Summary',
            hidden: updating,
            panes: [
              {
                paneType: PaneType.formFieldsPane,
                columnCount: 2,
                fields: [
                  {
                    fieldType: FieldType.readonlyField,
                    label: '',
                    dataAddr: 'none',
                    formatReadonly: () => <WorkshopShiftProgressStatusIconLegend />,
                  },
                ],
              },
              {
                paneType: PaneType.tablePane,
                mandatory: true,
                dataAddr: 'staffMemberWeeks',
                dataRequiredForRows: 'paneValue',
                rowKey: d => (d.itemValue as RosterConsolidatedStaffMemberWeekItem).staffMemberId,
                fields: [
                  {
                    fieldType: FieldType.readonlyField,
                    label: 'Staff Member',
                    dataAddr: 'staffMemberFullName',
                    linkTo: d => `/people/staff-members/${d.parentValue.staffMemberId}`,
                  },
                  ...daysOfWeek(),
                ],
              },
            ],
          },
          {
            title: 'Shifts',
            hidden: !updating,
            panes: [
              {
                paneType: PaneType.formFieldsPane,
                columnCount: 1,
                hidden: d =>
                  !(d.parentValue as IMaintainRosterForm) ||
                  !(d.parentValue as IMaintainRosterForm).readOnlyShifts ||
                  (d.parentValue as IMaintainRosterForm).readOnlyShifts.length === 0,
                fields: [
                  {
                    fieldType: FieldType.customField,
                    label: '',
                    dataAddr: 'none',
                    render: () => (
                      <div className={styles.note}>
                        <span>Please Note: </span>
                        <span>
                          Some shifts can't be edited because they have already started or have some
                          completion details provided.
                        </span>
                      </div>
                    ),
                  },
                  {
                    fieldType: FieldType.readonlyField,
                    label: '',
                    dataAddr: 'readOnlyShifts',
                    formatReadonly: data => {
                      const consolidatedShifts = data.fieldValue as RosterConsolidatedShiftItem[];
                      return <ConsolidatedRosterShifts consolidatedShifts={consolidatedShifts} />;
                    },
                  },
                ],
              },
            ],
          },
          {
            dataAddr: 'editableShifts',
            hidden: !updating,
            panes: [
              {
                paneType: PaneType.tablePane,
                mandatory: false,
                hidden: d => {
                  return (
                    !(d.parentValue as RosterConsolidatedStaffMemberWeekItem[]) ||
                    (d.parentValue as RosterConsolidatedStaffMemberWeekItem[]).length === 0
                  );
                },
                dataRequiredForRows: 'sectionValue',
                fields: [
                  {
                    fieldType: FieldType.selectField,
                    label: 'Shift',
                    dataAddr: 'shiftTemplateId',
                    valueKey: 'shiftTemplateId',
                    descriptionKey: 'shiftName',
                    mandatory: true,
                    columnWidth: '200px',
                    optionItems: shiftTemplates,
                    useValueOnly: true,
                    linkTo: d => `/workshop/shift-templates/${d.fieldValue}`,
                    onChange: d => {
                      if (
                        d.fieldData &&
                        d.fieldData.parentValue &&
                        d.fieldData.parentValue.shiftTemplateId &&
                        shiftTemplates
                      ) {
                        const shiftTemplate = shiftTemplates.find(
                          x => x.shiftTemplateId === d.fieldData.parentValue.shiftTemplateId
                        )!;
                        const defaultDays = getDays(shiftTemplate);
                        const daysAddr = [
                          ...d.fieldDataAddr.slice(0, d.fieldDataAddr.length - 1),
                          'days',
                        ];
                        d.setFormValue(daysAddr, defaultDays);
                        const shiftCommenceAddr = [
                          ...d.fieldDataAddr.slice(0, d.fieldDataAddr.length - 1),
                          'shiftCommence',
                        ];
                        d.setFormValue(shiftCommenceAddr, shiftTemplate.shiftCommence);
                        const shiftEndAddr = [
                          ...d.fieldDataAddr.slice(0, d.fieldDataAddr.length - 1),
                          'shiftEnd',
                        ];
                        d.setFormValue(shiftEndAddr, shiftTemplate.shiftEnd);
                      }
                    },
                  },
                  {
                    fieldType: FieldType.daysOfWeekField,
                    dataAddr: 'days',
                    label: 'Days of Week',
                    mandatory: true,
                    columnWidth: '100px',
                    useValueOnly: true,
                    readonly: data => !data.parentValue || !data.parentValue.shiftTemplateId,
                    valuesToExclude: data => {
                      if (data.parentValue && data.parentValue.shiftTemplateId && shiftTemplates) {
                        const shiftTemplate = shiftTemplates.find(
                          x => x.shiftTemplateId === data.parentValue.shiftTemplateId
                        );
                        const days = data.parentValue.days || [];
                        return getDaysToExclude({
                          monday: shiftTemplate!.monday || daySelected(days, Weekday.mon),
                          tuesday: shiftTemplate!.tuesday || daySelected(days, Weekday.tue),
                          wednesday: shiftTemplate!.wednesday || daySelected(days, Weekday.wed),
                          thursday: shiftTemplate!.thursday || daySelected(days, Weekday.thu),
                          friday: shiftTemplate!.friday || daySelected(days, Weekday.fri),
                          saturday: shiftTemplate!.saturday || daySelected(days, Weekday.sat),
                          sunday: shiftTemplate!.sunday || daySelected(days, Weekday.sun),
                        });
                      }
                      return [];
                    },
                    formatReadonly: data => {
                      if (data.parentValue && data.parentValue.days) {
                        let displayDays = [];
                        const days = data.parentValue.days;
                        if (daySelected(days, Weekday.mon)) {
                          displayDays.push('Mon');
                        }
                        if (daySelected(days, Weekday.tue)) {
                          displayDays.push('Tue');
                        }
                        if (daySelected(days, Weekday.wed)) {
                          displayDays.push('Wed');
                        }
                        if (daySelected(days, Weekday.thu)) {
                          displayDays.push('Thu');
                        }
                        if (daySelected(days, Weekday.fri)) {
                          displayDays.push('Fri');
                        }
                        if (daySelected(days, Weekday.sat)) {
                          displayDays.push('Sat');
                        }
                        if (daySelected(days, Weekday.sun)) {
                          displayDays.push('Sun');
                        }
                        return displayDays.join(', ');
                      }
                      return '';
                    },
                  },
                  {
                    fieldType: FieldType.timeField,
                    dataAddr: 'shiftCommence',
                    label: 'Shift Commence',
                    mandatory: true,
                    readonly: data => !data.parentValue || !data.parentValue.shiftTemplateId,
                    columnWidth: '50px',
                  },
                  {
                    fieldType: FieldType.timeField,
                    dataAddr: 'shiftEnd',
                    label: 'Shift End',
                    mandatory: true,
                    readonly: data => !data.parentValue || !data.parentValue.shiftTemplateId,
                    columnWidth: '50px',
                  },
                  {
                    fieldType: FieldType.selectMultiField,
                    dataAddr: ['staffMembers'],
                    label: 'Staff Members',
                    valueKey: 'staffMemberId',
                    descriptionKey: 'staffMemberFullName',
                    optionItems: mechanics
                      .filter(x => x.active)
                      .map(x => {
                        return { staffMemberFullName: x.name, staffMemberId: x.id };
                      }),
                    mandatory: true,
                    readonly: data => !data.parentValue || !data.parentValue.shiftTemplateId,
                  },
                  {
                    fieldType: FieldType.actionListField,
                    dataAddr: '',
                    columnWidth: '1px',
                    actionGroups: [
                      {
                        actions: [
                          {
                            actionType: ActionType.removeArrayItemActionButton,
                            label: 'Remove Line',
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
              {
                paneType: PaneType.actionListPane,
                actionGroups: [
                  {
                    actions: [
                      {
                        actionType: ActionType.addArrayItemActionButton,
                        label: 'Add Shift',
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
        onFormPreSubmit: handlePreSubmitForUpdate,
        onFormSubmit: updateRoster,
      },
    };
  };

  const shiftsForDisplay = (shifts: ShiftItem[]): JSX.Element | undefined => {
    return (
      <>
        {shifts &&
          shifts.map((x, index, arr) => {
            return (
              <div key={x.shiftId}>
                <Link to={`/workshop/shifts/${x.shiftId}`}>
                  <WorkshopShiftConflictIcon hasConflicts={x.hasUnacceptedConflicts} />
                  <WorkshopShiftProgressStatusIcon status={x.progressStatus} />
                  &nbsp;{x.shiftName.trim()}
                </Link>
                {index === arr.length - 1 ? (
                  <></>
                ) : (
                  <>
                    ,<br />
                  </>
                )}
              </div>
            );
          })}
      </>
    );
  };

  return (
    <CrudPage
      def={api => getPageDef(api.readonly, api.updating)}
      mode="update"
      isEditingForbidden={!canManageRosters}
      onLoadData={() => loadRoster(rosterId)}
      data={rosterData(roster)}
    />
  );
});

export default MaintainRoster;
