import { Component } from 'react';
import { DateTime } from 'luxon';
import { Observable } from 'rxjs/Observable';
import { PlusIcon } from 'src/images/icons';
import { Form } from 'src/views/components/Page/forms';
import {
  PaneType,
  FieldType,
  ActionType,
  INestingPaneDef,
  IPaneData,
  IPaneMeta,
} from 'src/views/definitionBuilders/types';
import PagePane from 'src/views/components/Page/panes/PagePane';
import {
  RepeatOnWeekdays,
  OnMonthFreq,
  PartialRepeat,
  generateWeeksWithTimeAwareness,
  generateWeeksWithDateOnly,
  generateMonthsWithTimeAwareness,
  generateMonthsWithDateOnly,
  generateDaysWithTimeAwareness,
  generateDaysWithDateOnly,
} from '../routes/operations/sales/quote/maintainQuote/utils/repeatDatesMaths';
import { IFormApiWithoutState } from './Page/forms/base';

interface IGenerateDates {
  repeatStartingOn: string;
  repeatEndingOn: string;
  repeatEvery: number;
  repeatTimePeriod: TimePeriod;
  repeatOnWeek: Array<RepeatOnWeekdays>;
  repeatOnMonth: OnMonthFreq;
}

type TimePeriod = 'days' | 'weeks' | 'months';

interface IMonthTimePeriodItem {
  value: OnMonthFreq;
  label: string;
  example: string;
}

const monthPeriodOnOptionItems: Array<IMonthTimePeriodItem> = [
  { value: 'monthDate', label: 'The same date', example: 'The 15th of the month' },
  { value: 'weekDay', label: 'The same week & day', example: 'Second Monday of the month' },
];

const getPane = (
  start: DateTime | undefined,
  timezone: string,
  disableWeekDayPicker: boolean | undefined,
  onFieldChange: () => void,
  onReset: () => void
): INestingPaneDef => ({
  paneType: PaneType.nestingPane,
  panes: [
    {
      paneType: PaneType.formFieldsPane,
      fields: [
        {
          fieldType: FieldType.errorField,
          dataAddr: 'startingOn',
          validate: d =>
            !start || !start.isValid ? 'Please ensure Start Date exists and is valid' : undefined,
        },
      ],
    },
    {
      paneType: PaneType.formFieldsPane,
      columnCount: 2,
      fields: [
        {
          fieldType: FieldType.readonlyField,
          label: 'Starting On',
          formatReadonly: d => {
            return start ? start.setZone(timezone).toLocaleString(DateTime.DATE_HUGE) : null;
          },
        },
        {
          fieldType: FieldType.dateField,
          dataAddr: 'repeatEndingOn',
          label: 'Ending On',
          mandatory: true,
          timezone: timezone,
          validate: d => {
            onFieldChange(); // Issues with onChange :(
            return start &&
              d.fieldValue &&
              DateTime.fromISO(d.fieldValue, { zone: timezone }) <= start
              ? 'Ending On must be after Starting On'
              : undefined;
          },
        },
        {
          fieldType: FieldType.numericField,
          numericConfig: { numericType: 'unsignedInt' },
          dataAddr: 'repeatEvery',
          label: 'Recur Every',
          mandatory: true,
          validate: d => {
            onFieldChange(); // Hack until we can figure out what's wrong with onChange
            return undefined;
          },
        },
        {
          fieldType: FieldType.selectField,
          dataAddr: 'repeatTimePeriod',
          label: 'Period',
          valueKey: 'value',
          descriptionKey: 'label',
          useValueOnly: true,
          optionItems: [
            { value: 'days' as TimePeriod, label: 'Days' },
            { value: 'weeks' as TimePeriod, label: 'Weeks' },
            { value: 'months' as TimePeriod, label: 'Months' },
          ],
          validate: d => {
            onFieldChange(); // Hack until we can figure out what's wrong with onChange
            return undefined;
          },
          mandatory: true,
        },
      ],
    },
    {
      paneType: PaneType.formFieldsPane,
      fields: [
        {
          fieldType: FieldType.daysOfWeekField,
          dataAddr: 'repeatOnWeek',
          label: 'On',
          mandatory: true,
          useValueOnly: true,
          hidden: d =>
            disableWeekDayPicker ||
            !d.parentValue ||
            (d.parentValue.repeatTimePeriod as TimePeriod) !== 'weeks',
        },
        {
          fieldType: FieldType.selectField,
          dataAddr: 'repeatOnMonth',
          label: 'On',
          valueKey: 'value',
          descriptionKey: 'label',
          optionItems: monthPeriodOnOptionItems,
          useValueOnly: true,
          mandatory: true,
          hidden: d =>
            !d.parentValue || (d.parentValue.repeatTimePeriod as TimePeriod) !== 'months',
          optionRenderer: o => (
            <span style={{ display: 'flex', justifyContent: 'space-between' }}>
              <span>{o.label}</span>
              <small>E.g. {o.example}</small>
            </span>
          ),
        },
      ],
    },
    {
      paneType: PaneType.actionListPane,
      actionGroups: [
        {
          actions: [
            {
              actionType: ActionType.submitActionButton,
              label: 'Generate Dates',
              icon: <PlusIcon />,
            },
            {
              actionType: ActionType.resetActionButton,
              label: 'Reset',
              onResetAction: onReset,
            },
          ],
        },
      ],
    },
  ],
});

interface IRepeatGenerationPaneProps {
  paneData: IPaneData;
  readonly: boolean;
  periodStart: DateTime | undefined;
  periodEnd?: DateTime;
  onDatesGenerated: (dates: Array<PartialRepeat>) => void;
  disableWeekDayPicker?: boolean;
  useTimezoneAwareCalculations: boolean;
  timezone: string;
  isRecurPanelChanged: (isChanged: boolean) => void;
  onReset: () => void;
}

class RepeatGenerationPane extends Component<IRepeatGenerationPaneProps> {
  private _formApi: IFormApiWithoutState | undefined;

  componentDidUpdate(prevProps: Readonly<IRepeatGenerationPaneProps>) {
    const { periodStart } = this.props;
    const firstTripDateEqual =
      (!periodStart && !prevProps.periodStart) ||
      (periodStart && prevProps.periodStart && periodStart.equals(prevProps.periodStart));
    if (this._formApi && !firstTripDateEqual) {
      this._formApi.validate('startingOn');
    }
  }

  private readonly onFieldChange = () => {
    let isChanged = this._formApi && this._formApi.valuesChanged();
    if (isChanged === undefined) {
      isChanged = true;
    }

    this.props.isRecurPanelChanged(isChanged);
  };

  private readonly handleSubmit = (
    values: IGenerateDates,
    timezone: string,
    useTimezoneAwareCalculations: boolean
  ) => {
    const { periodStart, periodEnd, onDatesGenerated } = this.props;

    if (
      periodStart &&
      periodStart.isValid &&
      (useTimezoneAwareCalculations ? periodEnd && periodEnd.isValid : true)
    ) {
      const sequenceEnd = DateTime.fromISO(values.repeatEndingOn, { zone: timezone });
      const items: Array<PartialRepeat> = [];

      switch (values.repeatTimePeriod) {
        case 'weeks':
          var repeatOnWeek = values.repeatOnWeek || [periodStart.weekday];
          items.push(
            ...(useTimezoneAwareCalculations
              ? generateWeeksWithTimeAwareness(
                  periodStart,
                  periodEnd!,
                  sequenceEnd,
                  repeatOnWeek,
                  values.repeatEvery
                )
              : generateWeeksWithDateOnly(
                  periodStart,
                  sequenceEnd,
                  repeatOnWeek,
                  values.repeatEvery
                ))
          );
          break;
        case 'months':
          items.push(
            ...(useTimezoneAwareCalculations
              ? generateMonthsWithTimeAwareness(
                  periodStart,
                  periodEnd!,
                  sequenceEnd,
                  values.repeatOnMonth,
                  values.repeatEvery
                )
              : generateMonthsWithDateOnly(
                  periodStart,
                  sequenceEnd,
                  values.repeatOnMonth,
                  values.repeatEvery
                ))
          );
          break;
        case 'days':
          items.push(
            ...(useTimezoneAwareCalculations
              ? generateDaysWithTimeAwareness(
                  periodStart,
                  periodEnd!,
                  sequenceEnd,
                  values.repeatEvery
                )
              : generateDaysWithDateOnly(periodStart, sequenceEnd, values.repeatEvery))
          );
          break;
        default:
          throw new Error('Repeat time period value is not supported: ' + values.repeatTimePeriod);
      }

      onDatesGenerated(items);
    }

    return Promise.resolve();
  };

  private readonly handleGetApi = (formApi: IFormApiWithoutState) => {
    this._formApi = formApi;
  };

  render() {
    const {
      paneData,
      readonly,
      periodStart,
      disableWeekDayPicker,
      useTimezoneAwareCalculations,
      timezone,
      onReset,
    } = this.props;
    const meta: IPaneMeta = {
      readonly,
      autoFocus: false,
      formChange$: new Observable(), // Not used
    };

    const safeTimezone = useTimezoneAwareCalculations ? timezone : 'local';

    return (
      <Form
        onSubmit={d => this.handleSubmit(d, safeTimezone, useTimezoneAwareCalculations)}
        getApi={this.handleGetApi}>
        {formApi => (
          <PagePane
            paneDef={getPane(
              periodStart,
              safeTimezone,
              disableWeekDayPicker,
              this.onFieldChange,
              onReset
            )}
            paneMeta={meta}
            panelData={paneData}
            parentValue={formApi.values}
          />
        )}
      </Form>
    );
  }
}

export default RepeatGenerationPane;
