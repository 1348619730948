import { IRootStoreModel } from 'src/domain/entities/RootStoreModel';
import { UiRoute } from 'src/domain/services/buildUiRoutes';
import { UiRouteName } from 'src/domain/baseTypes';

export default function buildUiRoutes(store: IRootStoreModel) {
  const processDriverAuthorizationList = new UiRoute(
    UiRouteName.operationsProcessDriverAuthorizationList,
    '/operations/process-driver-authorization',
    () => 'Process DA Results',
    true
  );

  const processResultsFile = new UiRoute(
    UiRouteName.operationsJobCreateGenerateJobsOption,
    '/operations/process-driver-authorization/create',
    () => 'Process Results File',
    false
  );

  return {
    [UiRouteName.operationsProcessDriverAuthorizationList]: processDriverAuthorizationList,
    [UiRouteName.operationsProcessResultsFile]: processResultsFile,
  };
}
