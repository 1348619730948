import Header from '../../components/presentational/Header/Header';
import styles from './MechanicDeclaration.module.scss';
import { FormattedDateTime } from '../../components/presentational/FormattedDateTime/FormattedDateTime';
import { SingleClickAwareButton } from '../../components/presentational/SingleClickAwareButton/SingleClickAwareButton';
import { COMPANY_NAME } from 'src/appSettings';

import { observer } from 'mobx-react';
import { useKioskRootStore } from 'src/domain/entities/KioskRootStoreModel';
import { useEffect, useState } from 'react';
import { Spinner } from 'reactstrap/lib';
import { ENABLE_ALCO_READINGS } from 'src/appSettings';

const checkAlcoReading = ENABLE_ALCO_READINGS;
const MechanicDeclaration: React.FC = observer(() => {
  const rootStore = useKioskRootStore();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const clockOn = rootStore.kiosk.clockOn.clockOn;

  const isClockedOn = rootStore.kiosk.clockOn.isClockedOn;
  const loadStaffMemberStatus = rootStore.kiosk.clockOn.loadStaffMemberStatus;

  const testResult = rootStore.kiosk.clockOn.testResult;
  const loadAlcolizerTestResult = rootStore.kiosk.clockOn.loadAlcolizerTestResult;
  const staffMemberId = rootStore.account.id;
  const isAlcoReadingEnabledDevice = rootStore.account.isAlcoReadingEnabledDevice;

  const loadShiftDetails = rootStore.kiosk.workshop.shiftDetails.loadShiftDetails;
  const shiftDetails = rootStore.kiosk.workshop.shiftDetails.closestClockOn;

  useEffect(() => {
    if (staffMemberId) {
      loadStaffMemberStatus(staffMemberId);
      loadShiftDetails();
      if (!isClockedOn && checkAlcoReading && isAlcoReadingEnabledDevice) {
        setIsLoading(true);
        loadAlcolizerTestResult(staffMemberId).then(() => setIsLoading(false));
      }
    }
  }, []);

  return (
    <div className={styles.mechanicDeclarationComponent}>
      <Header title="Clock On Confirmation" hideMenu />
      <div className={styles.declaration}>
        {checkAlcoReading && isAlcoReadingEnabledDevice ? (
          <>
            {isLoading ? (
              <div className="loading">
                <Spinner />
              </div>
            ) : (
              <>
                {checkAlcoReading &&
                  (testResult?.exception === null ? (
                    <h1>
                      No Alcolizer results found within the last hour. Please conduct a test before
                      clocking on.
                    </h1>
                  ) : testResult?.exception ? (
                    <h1>
                      A postive result was returned. Please advise Ops that you are not fit for
                      work.
                    </h1>
                  ) : (
                    <h1>
                      {`By Clocking on I am declaring that I am FIT FOR WORK and that I will adhere to all
  ${COMPANY_NAME} Employee Policies. I also declare I will obey all relevant state road traffic
  rules.`}
                    </h1>
                  ))}
              </>
            )}
          </>
        ) : (
          <>
            <h1>
              {`By Clocking on I am declaring that I am FIT FOR WORK and that I will adhere to all
          ${COMPANY_NAME} Employee Policies. I also declare I will obey all relevant state road
          traffic rules.`}
            </h1>
          </>
        )}
      </div>
      <div>
        <h3>Shift Details</h3>
        {shiftDetails ? (
          <>
            <h4>{shiftDetails.name}</h4>
            <div>
              <FormattedDateTime dateTime={shiftDetails.start} />
              <span> - </span>
              <FormattedDateTime dateTime={shiftDetails.end} />
            </div>
          </>
        ) : (
          <span>No shift found for this time</span>
        )}
        <div className={styles.accept}>
          <SingleClickAwareButton
            disabled={isLoading}
            useReactStrapButton
            type="button"
            size="lg"
            block
            onClick={() =>
              checkAlcoReading &&
              isAlcoReadingEnabledDevice &&
              (testResult?.exception === true || testResult?.exception === null)
                ? rootStore.account.signOutKiosk()
                : clockOn({
                    staffMemberId: staffMemberId as string,
                    logId: testResult?.logId,
                    timestamp: testResult?.timestamp,
                  })
            }>
            OK
          </SingleClickAwareButton>
        </div>
      </div>
    </div>
  );
});

export default MechanicDeclaration;
