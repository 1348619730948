import { types, getRoot, flow } from 'mobx-state-tree';
import { IRootStoreModel } from 'src/domain/entities/RootStoreModel';
import { getAjax } from 'src/domain/services';

type PartCategoryItem = Workshop.Domain.Queries.PartCategory.PartCategoryItem;
type CreatePartCategoryCommand = Workshop.Domain.Commands.Part.CreatePartCategory.CreatePartCategoryCommand;
type UpdatePartCategoryCommand = Workshop.Domain.Commands.Part.UpdatePartCategory.UpdatePartCategoryCommand;
type GetCanPartCategoryBeDeletedResult = Workshop.Domain.Queries.GetCanPartCategoryBeDeleted.GetCanPartCategoryBeDeletedResult;

export const PartCategoryModel = types
  .model('PartCategoryModel', {
    partCategory: types.maybe(types.frozen<PartCategoryItem>()),
    canDeletePartCategory: types.maybe(types.frozen<GetCanPartCategoryBeDeletedResult>()),
  })
  .actions(self => {
    const ajax = getAjax(self);
    const root = getRoot(self) as IRootStoreModel;

    const createPartCategory = flow(function*(command: CreatePartCategoryCommand) {
      const id = yield ajax.workshop.partCategories.createPartCategory(command);
      self.partCategory = undefined;
      root.history.push(`/workshop/part-categories/${id}`);
    });

    const checkForUniqueName = flow(function*(name: string) {
      return yield ajax.workshop.partCategories.checkForUniqueName(name);
    });

    const loadPartCategory = flow(function*(partCategoryId: number) {
      self.partCategory = yield ajax.workshop.partCategories.getPartCategory(partCategoryId);
    });

    const loadCanDeletePartCategory = flow(function*(partCategoryId: number) {
      self.canDeletePartCategory = yield ajax.workshop.partCategories.canDeletePartCategory(
        partCategoryId
      );
    });

    const updatePartCategory = flow(function*(command: UpdatePartCategoryCommand) {
      yield ajax.workshop.partCategories.updatePartCategory(command);
      yield loadPartCategory(command.id);
    });

    const deletePartCategory = flow(function*(partCategoryId: number) {
      yield ajax.workshop.partCategories.deletePartCategory(partCategoryId);
      self.partCategory = undefined;
      root.history.push(`/workshop/part-categories`);
    });

    const setPartCategoryActive = flow(function*(partCategoryId: number) {
      yield ajax.workshop.partCategories.updatePartCategorySetActive(partCategoryId);
      yield loadPartCategory(partCategoryId);
    });

    const setPartCategoryInactive = flow(function*(partCategoryId: number) {
      yield ajax.workshop.partCategories.updatePartCategorySetInactive(partCategoryId);
      yield loadPartCategory(partCategoryId);
    });

    return {
      createPartCategory,
      checkForUniqueName,
      loadPartCategory,
      updatePartCategory,
      loadCanDeletePartCategory,
      deletePartCategory,
      setPartCategoryActive,
      setPartCategoryInactive,
    };
  });
