import styles from './AnonymousHeader.module.scss';
import { DateTime } from 'luxon';
import { useInterval } from 'src/views/hooks/useInterval';
import CompanyLogo from 'src/views/components/CompanyLogo/CompanyLogo';
import { useState } from 'react';

const AnonymousHeader: React.FC = () => {
  const [now, setNow] = useState(DateTime.local());
  useInterval(() => setNow(DateTime.local()), 1000);

  return (
    <div className={styles.root}>
      <div className={styles.logo}>
        <CompanyLogo mode="kiosk" />
      </div>
      <div className={styles.date}>
        <h2>{now.toLocaleString(DateTime.TIME_SIMPLE)}</h2>
        <h3>{now.toLocaleString(DateTime.DATE_HUGE)}</h3>
      </div>
    </div>
  );
};

export default AnonymousHeader;
