import { UiRouteName } from 'src/domain/baseTypes';
import { IRootStoreModel } from 'src/domain/entities/RootStoreModel';
import UiRoute from 'src/domain/services/buildUiRoutes/UiRoute';

export default function buildUiRoutes(store: IRootStoreModel) {
  const createJobTask = new UiRoute(
    UiRouteName.workshopJobTasksCreate,
    '/workshop/tasks/create',
    () => `Create Task`,
    false
  );

  const maintainJobTask = new UiRoute(
    UiRouteName.workshopJobTasksMaintain,
    '/workshop/tasks/:id',
    () => `Manage Task`,
    false
  );
  const tasksList = new UiRoute(
    UiRouteName.workshopTasksList,
    '/workshop/tasks',
    () => 'Tasks',
    true,
    false,
    [createJobTask, maintainJobTask]
  );

  return {
    [UiRouteName.workshopTasksList]: tasksList,
    [UiRouteName.workshopJobTasksCreate]: createJobTask,
    [UiRouteName.workshopJobTasksMaintain]: maintainJobTask,
  };
}
