import { IAjax } from 'src/infrastructure/ajax';
import { workshopUrls } from '../../apiUrls';

type CreateShiftTemplateCommand = Workshop.Domain.Commands.ShiftTemplate.CreateShiftTemplate.CreateShiftTemplateCommand;
type UpdateShiftTemplateCommand = Workshop.Domain.Commands.ShiftTemplate.UpdateShiftTemplate.UpdateShiftTemplateCommand;
type ShiftTemplateItem = Workshop.Domain.Queries.ShiftTemplate.GetShiftTemplate.ShiftTemplateItem;
type ListShiftTemplatesQuery = Workshop.Domain.Queries.ShiftTemplate.ListShiftTemplates.ListShiftTemplatesQuery;
type ShiftTemplateListItem = Workshop.Domain.Queries.ShiftTemplate.ListShiftTemplates.ShiftTemplateListItem;

export default function init(ajax: IAjax) {
  function createShiftTemplate(command: CreateShiftTemplateCommand) {
    return ajax
      .post(workshopUrls.shiftTemplateUrls.createShiftTemplate(), command)
      .toPromise()
      .then<string>(r => r.response);
  }

  function checkForUniqueShiftTemplateName(shiftName: string) {
    return ajax
      .get(workshopUrls.shiftTemplateUrls.checkForUniqueShiftTemplateName(shiftName))
      .toPromise()
      .then<Common.Dtos.UniqueNameCheckResultDto>(r => r.response);
  }

  function updateShiftTemplate(command: UpdateShiftTemplateCommand) {
    return ajax
      .put(workshopUrls.shiftTemplateUrls.updateShiftTemplate(command.id), command)
      .toPromise();
  }

  function getShiftTemplate(shiftTemplateId: string) {
    return ajax
      .get(workshopUrls.shiftTemplateUrls.getShiftTemplate(shiftTemplateId))
      .toPromise()
      .then<ShiftTemplateItem>(r => r.response);
  }

  function deleteShiftTemplate(shiftTemplateId: string) {
    return ajax
      .httpDelete(workshopUrls.shiftTemplateUrls.getShiftTemplate(shiftTemplateId))
      .toPromise();
  }

  function listShiftTemplates(query: Partial<ListShiftTemplatesQuery>) {
    return ajax
      .get(workshopUrls.shiftTemplateUrls.listShiftTemplates(query))
      .map(r => r.response as Common.Dtos.ListResult<ShiftTemplateListItem>);
  }

  return {
    shiftTemplate: {
      createShiftTemplate,
      checkForUniqueShiftTemplateName,
      updateShiftTemplate,
      getShiftTemplate,
      deleteShiftTemplate,
      listShiftTemplates,
    },
  };
}
