import inject from 'src/views/injectFromStore';
import Omit from 'src/infrastructure/omit';
import MaintainSms, { IMaintainSmsProps } from './MaintainSms';

const Container = inject<Omit<IMaintainSmsProps, 'mode'>, IMaintainSmsProps>((allStores, p) => ({
  canManageStaffMemberSms:
    allStores.rootStore.account.isAccountingDepartmentMember ||
    allStores.rootStore.account.isOperationsDepartmentMember ||
    allStores.rootStore.account.isPeopleDepartmentMember ||
    allStores.rootStore.account.isRailDepartmentMember ||
    allStores.rootStore.account.isSalesDepartmentMember ||
    allStores.rootStore.account.isTimesheetsDepartmentMember ||
    allStores.rootStore.account.isWorkshopDepartmentMember,
  staffMembers: allStores.rootStore.people.staffMembers.allStaffMembers.slice(),
  activeStaffMembers: allStores.rootStore.people.staffMembers.activeStaffMembers.slice(),
  allStaffWithDriverAuthorisation: allStores.rootStore.people.staffMembers.allStaffWithDriverAuthorisation.slice(),
  loadStaffMembers: allStores.rootStore.people.staffMembers.loadAllStaffMembers,
  createSms: allStores.rootStore.people.Sms.createSms,
  loadSms: allStores.rootStore.people.Sms.loadSms,
  sms: allStores.rootStore.people.Sms.Sms,
  dismissSms: allStores.rootStore.people.Sms.dismissSms,
  loadAllRoles: allStores.rootStore.people.roles.loadAllRoles,
  roles: allStores.rootStore.people.roles.roles.slice(),
}))(MaintainSms);

export default Container;
