import { PaneType, FieldType, ModalDefBuilder } from 'src/views/definitionBuilders/types';
import { getSubmitCloseModalActionGroupDef } from 'src/views/definitionBuilders/common';
import MaintainRole from './MaintainRole';

export default function getAddStaffMemberModelDef(
  this: InstanceType<typeof MaintainRole>
): ModalDefBuilder {
  return modalDefApi => ({
    title: 'Add Staff Member',
    asForm: true,
    panels: [
      {
        panes: [
          {
            paneType: PaneType.formFieldsPane,
            fields: [
              {
                fieldType: FieldType.selectMultiField,
                dataAddr: 'staffMemberIds',
                label: 'Staff Member',
                mandatory: true,
                optionItems: this.props.activeStaffMembers,
                valueKey: 'id',
                descriptionKey: 'name',
                useValueOnly: true,
                valuesToExclude: d => {
                  const staffMembersIds = this.props.assignedStaffMembers.map(s => s.id);
                  return staffMembersIds;
                },
              },
            ],
          },
        ],
      },
    ],
    secondaryActions: [getSubmitCloseModalActionGroupDef('Ok')],
    onFormPreSubmit: v => v.staffMemberIds,
    onFormSubmit: (staffMemberIds: string[]) => {
      return this.props.assignStaffMembersToRole({
        roleId: this.props.role!.id,
        staffMemberIds: staffMemberIds,
      });
    },
  });
}
