import { types } from 'mobx-state-tree';
import { ClockOnModel } from './clockOn/ClockOnModel';
import { JobsModel } from './driver/JobsModel';
import { JobItemModel } from 'src/domain/entities/kiosk/driver/JobItemModel';
import { NotesModel } from './driver/NotesModel';
import { DateTime } from 'luxon';
import { LeavesModel } from 'src/domain/entities/kiosk/driver/LeavesModel';
import { DefectModel } from 'src/domain/entities/kiosk/defect/DefectModel';
import { AssetModel } from 'src/domain/entities/kiosk/asset/AssetModel';
import { StaffMembersModel } from 'src/domain/entities/kiosk/staff/StaffMembersModel';
import { TimesheetModel } from 'src/domain/entities/kiosk/timesheet/TimesheetModel';
import { WorkshopRootModel } from './workshop/WorkshopRootModel';
import { WorkshopShiftsModel } from './workshop/WorkshopShiftsModel';
import { AssetCubicRegisterModel } from './workshop/AssetCubicRegisterModel';
import { JobTaskModel } from './jobtask/JobTaskModel';
import { LicencesModel } from 'src/domain/entities/kiosk/licences/LicencesModel';
import { ShiftModel } from './workshop/ShiftModel';

export const KioskModel = types
  .model('KioskModel', {
    clockOn: types.optional(ClockOnModel, {}),
    leaves: types.optional(LeavesModel, {}),
    jobs: types.optional(JobsModel, {}),
    job: types.optional(JobItemModel, {}),
    notes: types.optional(NotesModel, {}),
    date: types.optional(types.frozen<DateTime>(), () => DateTime.local()),
    defect: types.optional(DefectModel, {}),
    asset: types.optional(AssetModel, {}),
    timesheet: types.optional(TimesheetModel, {}),
    workshop: types.optional(WorkshopRootModel, {}),
    staff: types.optional(StaffMembersModel, {}),
    shifts: types.optional(WorkshopShiftsModel, {}),
    cubicRegister: types.optional(AssetCubicRegisterModel, {}),
    jobTask: types.optional(JobTaskModel, {}),
    licences: types.optional(LicencesModel, {}),
    shift: types.optional(ShiftModel, {}),
  })

  .actions(self => {
    const updateDate = (date: DateTime) => {
      self.date = date;
    };

    return {
      updateDate,
    };
  });

type IKioskModelType = typeof KioskModel.Type;
export interface IKioskModel extends IKioskModelType {}
