import './ShiftsCalendar.scss';
import styles from './ShiftsCalendar.module.scss';

import Calendar from 'react-calendar';
import { DateTime } from 'luxon';
import cn from 'classnames';
import { CalendarTileProperties } from '../../components/presentational/Leave/LeaveCalendar/LeaveCalendar';

type ShiftListItem = Workshop.Domain.Queries.Shift.ListShifts.ShiftListItem;

interface IShiftsCalendarProps {
  shifts: ShiftListItem[];
  activeStartDateChanged: (date: DateTime) => void;
  defaultKioskDepotId: number;
}

const mapShiftNumberToClassName = (shiftName: string, shifts: string[]): string => {
  if (shiftName.indexOf('On call') >= 0) {
    return 'oncall';
  }

  const s = Array.from(new Set(shifts));

  switch (s.indexOf(shiftName)) {
    case 0:
      return 'zero';
    case 1:
      return 'one';
    case 2:
      return 'two';
    case 3:
      return 'three';
    case 4:
      return 'four';
    case 5:
      return 'five';
    case 6:
      return 'six';
    default:
      return 'zero';
  }
};

function getTileClassName(tileProperties: CalendarTileProperties, shifts: ShiftListItem[]) {
  let classNames: string[] = ['tile'];
  if (!shifts || shifts.length === 0) {
    return classNames;
  }
  if (tileProperties.view !== 'month') {
    return classNames;
  }
  const date = DateTime.fromJSDate(tileProperties.date).startOf('day');
  const endDate = date.endOf('day');

  if (date.hasSame(DateTime.local(), 'day')) {
    classNames.push('today-tile');
  }

  const ShiftsForDate = shifts.filter(
    x => DateTime.fromISO(x.shiftCommence) <= endDate && DateTime.fromISO(x.shiftEnd) > date
  );
  if (ShiftsForDate.length > 0) {
    classNames = [
      ...classNames,
      ...ShiftsForDate.map(sn =>
        mapShiftNumberToClassName(
          `${sn.shiftName}_${sn.shiftDepot.id}`,
          shifts.map(s => `${s.shiftName}_${s.shiftDepot.id}`)
        )
      ),
    ];
  }
  return classNames;
}

const ShiftsCalendar: React.FC<IShiftsCalendarProps> = ({
  shifts,
  defaultKioskDepotId,
  activeStartDateChanged,
}) => {
  const shiftSummaries: {
    [key: string]: {
      shiftName: string;
      shiftId: number;
      depotId: number;
      depotName: string;
    };
  } = shifts.reduce((m, s) => {
    const key = `${s.shiftName}-${s.shiftDepot.id}`;
    return m[key]
      ? m
      : {
          ...m,
          [key]: {
            shiftName: s.shiftName,
            shiftId: s.shiftId,
            depotId: s.shiftDepot.id,
            depotName: s.shiftDepot.name,
          },
        };
  }, {});

  const shiftKeys = Object.keys(shiftSummaries);
  const shiftNamesForStyling =
    shiftKeys && shiftKeys.length > 0
      ? Array.from(
          new Set(
            shiftKeys.map(s => {
              const shift = shiftSummaries[s];
              return `${shift.shiftName}_${shift.depotId}`;
            })
          )
        )
      : [];

  return (
    <div className={styles.shiftsCalendar}>
      <div className={'shifts-calendar-component'}>
        <Calendar
          showNeighboringMonth={false}
          tileClassName={tileProperties => getTileClassName(tileProperties, shifts)}
          onClickMonth={date => activeStartDateChanged(DateTime.fromJSDate(date))}
          onClickDay={date => activeStartDateChanged(DateTime.fromJSDate(date))}
          onActiveStartDateChange={props =>
            activeStartDateChanged(DateTime.fromJSDate(props.activeStartDate as Date))
          }
        />
      </div>
      <div className={styles.legend}>
        {shiftKeys &&
          shiftKeys.length > 0 &&
          shiftKeys.map(s => {
            const shift = shiftSummaries[s];
            return (
              <div key={shift.shiftId} className={'shifts-calendar-component'}>
                <span
                  className={cn(
                    'shiftsbox',
                    mapShiftNumberToClassName(
                      `${shift.shiftName}_${shift.depotId}`,
                      shiftNamesForStyling
                    )
                  )}
                />{' '}
                <span>{shift.shiftName} </span>
                {shift.depotId !== defaultKioskDepotId ? (
                  <span>
                    <small>({shift.depotName})</small>
                  </span>
                ) : null}
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default ShiftsCalendar;
