import { Component } from 'react';
import { withFormApi } from 'src/views/components/Page/forms';
import { IFormApi } from 'src/views/components/Page/forms/base';
import {
  IActionMeta,
  IActionData,
  ActionType,
  IMoveArrayItemActionButtonDef,
  IActionDef,
} from 'src/views/definitionBuilders/types';
import ActionButton from './ActionButton';

interface IMoveArrayItemActionButtonProps {
  formApi: IFormApi;
  actionDef: IMoveArrayItemActionButtonDef;
  actionMeta: IActionMeta;
  actionData: IActionData;
}

class MoveArrayItemActionButton extends Component<IMoveArrayItemActionButtonProps> {
  private readonly findArrayDataAddr = () => {
    const { formApi } = this.props;
    const dataAddr = formApi.getFullField();

    // Back up the path until we get to a number (which means we're at a collection)
    while (dataAddr.length && typeof dataAddr[dataAddr.length - 1] !== 'number') {
      dataAddr.pop();
    }
    if (!dataAddr.length) {
      throw new Error('Cannot find array in action button path');
    }
    return dataAddr;
  };

  private readonly handleClick = (d: IActionData) => {
    const { formApi, actionDef } = this.props;

    const namePath = this.findArrayDataAddr();
    const idx = namePath.pop() as number;
    const itemsCount = formApi.getValue(namePath).length;

    let destIdx = actionDef.moveDirection === 'prev' ? idx - 1 : idx + 1;
    if (destIdx < 0) {
      destIdx = 0;
    } else if (destIdx >= itemsCount) {
      destIdx = itemsCount - 1;
    }

    formApi.swapValues(namePath, idx, destIdx);

    // Move does not happen immediately, so use setTimeout then reread directly from props to get the latest values
    setTimeout(() => {
      const { actionDef: latestDef, actionData: latestData } = this.props;
      latestDef.postClick && latestDef.postClick(latestData);
    }, 0);
  };

  render() {
    const { actionDef, actionMeta: meta, actionData } = this.props;
    return (
      <ActionButton
        actionDef={{
          ...(actionDef as IActionDef),
          actionType: ActionType.actionButton,
          icon: actionDef.icon,
          level: actionDef.level,
          onClick: this.handleClick,
        }}
        actionMeta={meta}
        actionData={actionData}
      />
    );
  }
}

export default withFormApi(MoveArrayItemActionButton);
