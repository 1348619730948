import { DateTime } from 'luxon';
import { observer } from 'mobx-react-lite';
import { Button } from 'reactstrap';
import { useRootStore } from 'src/domain/entities/RootStoreModel';
import { EditIcon, PlusIcon } from 'src/images/icons';
import PageArea from 'src/views/components/Page/PageArea';
import ShellModal, {
  IShellModalTriggerApi,
  ShellModalSize,
} from 'src/views/components/Shell/ShellModal';
import { getSubmitCloseModalActionGroupDef } from 'src/views/definitionBuilders/common';
import { FieldType, PaneType } from 'src/views/definitionBuilders/types';
import { DailyNoteContent } from './fields';

type AddDailyNoteCommand = Workshop.Domain.Commands.DailyNote.AddDailyNote.AddDailyNoteCommand;
type UpdateDailyNoteCommand = Workshop.Domain.Commands.DailyNote.UpdateDailyNote.UpdateDailyNoteCommand;
type DailyNoteItem = Workshop.Domain.Queries.DailyNote.GetDailyNotes.DailyNoteItem;

const emptyDefaultFormData = { [DailyNoteContent.fieldId]: '' };
type FormData = typeof emptyDefaultFormData;

export interface IDailyNoteProps {
  canManageDailyNotes: boolean;
  localDay: DateTime;
  dailyNote?: DailyNoteItem;
  dailyNoteLoading: boolean;
  workshopDepotId: number;
  onAddDailyNote: (cmd: AddDailyNoteCommand) => Promise<void>;
  onUpdateDailyNote: (cmd: UpdateDailyNoteCommand) => Promise<void>;
}

const DailyNote: React.FC<IDailyNoteProps> = observer((props: IDailyNoteProps) => {
  const rootStore = useRootStore();
  const jobsModel = rootStore.jobs;
  const handleUpdateClick = (api: IShellModalTriggerApi) => {
    api.onShow();
  };

  const handleSubmit = async (values: FormData) => {
    const { dailyNote, localDay, onUpdateDailyNote, onAddDailyNote, workshopDepotId } = props;
    const content = values[DailyNoteContent.fieldId];
    if (dailyNote) {
      await onUpdateDailyNote({
        id: dailyNote.id,
        content,
        depotId: workshopDepotId,
      });
      await jobsModel.loadDailyRunSheet(localDay, workshopDepotId);
    } else {
      // If content is just whitespace, then we don't need to add anything
      if (content.trim()) {
        await onAddDailyNote({
          day: localDay.toISODate(),
          content,
          depotId: workshopDepotId,
        });
        await jobsModel.loadDailyRunSheet(localDay, workshopDepotId);
      } else {
        return Promise.resolve();
      }
    }
  };

  const { dailyNote, dailyNoteLoading, canManageDailyNotes, localDay } = props;
  const formData: FormData = {
    [DailyNoteContent.fieldId]: (dailyNote && dailyNote.content) || '',
  };

  return (
    <div>
      <h4>Daily Note</h4>

      <div className="labelled-field-component labelled-form-field-component labelled-textarea-component form-field form-group">
        <div className="field-content">{dailyNote && dailyNote.content}</div>
      </div>

      <ShellModal
        size={ShellModalSize.oneThird}
        renderShowTrigger={api => {
          const openModal = () => handleUpdateClick(api);
          return dailyNoteLoading || !canManageDailyNotes ? null : (
            <Button outline className="update-note-action" size="sm" onClick={openModal}>
              {dailyNote && dailyNote.content && dailyNote.content.trim().length > 0 ? (
                <>
                  <EditIcon fixedWidth /> Edit Daily Note
                </>
              ) : (
                <>
                  <PlusIcon fixedWidth /> Add Daily Note
                </>
              )}
            </Button>
          );
        }}>
        {(api: IShellModalTriggerApi) => (
          <div style={{ position: 'relative', height: '100%' }}>
            <PageArea
              className="modal-area"
              sectionDefs={[
                {
                  title: `Daily Note`,
                  subtitle: `${localDay.toLocaleString(DateTime.DATE_HUGE)}`,
                  explicitData: formData,
                  asForm: true,
                  panels: [
                    {
                      panes: [
                        {
                          paneType: PaneType.formFieldsPane,
                          fields: [
                            {
                              fieldType: FieldType.textAreaField,
                              label: 'Details',
                              dataAddr: DailyNoteContent.fieldId,
                              mandatory: false,
                              rows: 10,
                              maxLength: 1000,
                            },
                          ],
                        },
                      ],
                    },
                  ],
                  secondaryActions: [getSubmitCloseModalActionGroupDef('Save')],
                  onFormSubmit: handleSubmit,
                },
              ]}
              data={{}}
              modalMeta={{ closeModal: api.onClose }}
              autoFocus
            />
          </div>
        )}
      </ShellModal>
    </div>
  );
});

export default DailyNote;
