import { Component } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { DateTime } from 'luxon';
import { PagePrimarySize, PaneType, FieldType } from 'src/views/definitionBuilders/types';
import CrudPage, { CrudPageMode, ICrudPageDef } from 'src/views/components/Page/pages/CrudPage';
import {
  allDisposalType,
  allLostOrFound,
  allLostPropertyItemStatus,
  LostPropertyItemStatus,
  LostOrFound,
} from 'src/api/enums';
import { standardTextSanitisers } from 'src/views/componentBuilders';

type LostPropertyItemType = Operations.Domain.Queries.ViewLostPropertyItem.LostPropertyItem;
type CreateLostPropertyItemCommand = Operations.Domain.Commands.LostProperty.CreateLostPropertyItem.CreateLostPropertyItemCommand;
type UpdateLostPropertyItemCommand = Operations.Domain.Commands.LostProperty.UpdateLostPropertyItem.UpdateLostPropertyItemCommand;
type AssetItem = Common.Queries.Workshop.GetSimpleFleetAssetList.SimpleAssetItem;

export interface IMaintainLostPropertyItemProps {
  mode: CrudPageMode;
  canManageLostPropertyItem: boolean;

  loadLostPropertyItem: (id: string) => Promise<void>;
  LostPropertyItem: LostPropertyItemType | undefined;
  createLostPropertyItem: (command: CreateLostPropertyItemCommand) => Promise<void>;
  updateLostPropertyItem: (command: UpdateLostPropertyItemCommand) => Promise<void>;
  assets: AssetItem[];
  loadAssetListItems: () => Promise<void>;
}

interface IMaintainLostPropertyItemParams {
  id: string;
}

type InternalProps = IMaintainLostPropertyItemProps &
  RouteComponentProps<IMaintainLostPropertyItemParams>;

class MaintainLostPropertyItem extends Component<InternalProps> {
  componentDidMount() {
    this.props.loadAssetListItems();
  }

  private get isUpdateMode() {
    return this.props.mode === 'update';
  }

  private get LostPropertyItemId() {
    return this.props.match.params.id;
  }

  private handlePreSubmitForCreate = (
    item: LostPropertyItemType
  ): CreateLostPropertyItemCommand => {
    return {
      assetId: item.asset ? item.asset.id : undefined,
      status: item.status,
      foundOn: item.foundOn,
      service: item.service,
      description: item.description,
      note: item.note,
      lostOrFound: item.lostOrFound,
      moneyValue: item.moneyValue,

      ownerAddress: item.ownerAddress,
      ownerEmail: item.ownerEmail,
      ownerName: item.ownerName,
      ownerPhone: item.ownerPhone,
      collectedOn: item.collectedOn,

      disposalType: item.disposalType,
      disposedOn: item.disposedOn,
      contactDetails: item.contactDetails,
    };
  };

  private handlePreSubmitForUpdate = (
    item: LostPropertyItemType
  ): UpdateLostPropertyItemCommand => {
    return {
      id: this.LostPropertyItemId,
      assetId: item.asset ? item.asset.id : undefined,
      status: item.status,
      foundOn: item.foundOn,
      service: item.service,
      description: item.description,
      note: item.note,
      lostOrFound: item.lostOrFound,
      moneyValue: item.moneyValue,

      ownerAddress: item.ownerAddress,
      ownerEmail: item.ownerEmail,
      ownerName: item.ownerName,
      ownerPhone: item.ownerPhone,
      collectedOn: item.collectedOn,

      disposalType: item.disposalType,
      disposedOn: item.disposedOn,
      contactDetails: item.contactDetails,
    };
  };

  private readonly validateDateTimeIsNotAfterToday = (first: string, firstLabel: string) => {
    return !!first && DateTime.fromISO(first) > DateTime.local()
      ? `${firstLabel} cannot be after today.`
      : undefined;
  };

  private readonly getPageDef = (updating: boolean): ICrudPageDef => {
    return {
      primarySize: PagePrimarySize.threeQuarters,
      primarySection: {
        title: this.isUpdateMode
          ? ` Lost Property Item ${this.props.LostPropertyItem &&
              this.props.LostPropertyItem.lostPropertyItemNumber}`
          : 'Create a Lost Property Item',
        panels: [
          {
            panes: [
              {
                paneType: PaneType.formFieldsPane,
                columnCount: 2,
                fields: [
                  {
                    fieldType: FieldType.dateField,
                    label: 'Found/Lost On',
                    dataAddr: 'foundOn',
                    mandatory: true,
                    validate: d =>
                      this.validateDateTimeIsNotAfterToday(d.parentValue.foundOn, 'Found/Lost On'),
                  },
                  {
                    fieldType: FieldType.selectField,
                    label: 'Lost or Found?',
                    dataAddr: 'lostOrFound',
                    valueKey: 'value',
                    descriptionKey: 'description',
                    useValueOnly: true,
                    optionItems: allLostOrFound,
                    mandatory: true,
                  },
                  {
                    fieldType: FieldType.selectField,
                    label: 'Status',
                    dataAddr: 'status',
                    valueKey: 'value',
                    descriptionKey: 'description',
                    useValueOnly: true,
                    optionItems: allLostPropertyItemStatus,
                    validate: d =>
                      d.parentValue.lostOrFound === LostOrFound.Found &&
                      d.fieldValue === LostPropertyItemStatus.Reported
                        ? "A found item cannot have the status 'Reported'"
                        : undefined,
                    mandatory: true,
                  },
                  {
                    fieldType: FieldType.numericField,
                    label: 'Money Value',
                    dataAddr: 'moneyValue',
                    numericConfig: { numericType: 'unsignedDecimal', maxPointDigits: 2 },
                  },
                  {
                    fieldType: FieldType.assetSelectField,
                    label: 'Vehicle',
                    dataAddr: 'asset',
                    valueKey: 'id',
                    descriptionKey: 'name',
                    optionItems: this.props.assets,
                  },
                  {
                    fieldType: FieldType.textField,
                    label: 'Owner Name',
                    dataAddr: 'ownerName',
                  },
                ],
              },
              {
                paneType: PaneType.formFieldsPane,
                columnCount: 1,
                fields: [
                  {
                    fieldType: FieldType.textField,
                    label: 'Service',
                    dataAddr: 'service',
                  },
                  {
                    fieldType: FieldType.textAreaField,
                    label: 'Description',
                    dataAddr: 'description',
                    mandatory: true,
                  },
                  {
                    fieldType: FieldType.textAreaField,
                    label: 'Notes',
                    dataAddr: 'note',
                  },
                  {
                    fieldType: FieldType.textAreaField,
                    label: 'Contact Details',
                    dataAddr: 'contactDetails',
                    mandatory: d => d.parentValue.lostOrFound === LostOrFound.Lost,
                    hidden: d => d.parentValue.lostOrFound !== LostOrFound.Lost,
                  },
                ],
              },
            ],
          },
          {
            title: 'Collection Details',
            hidden: d =>
              (d.sectionValue as LostPropertyItemType).status !== LostPropertyItemStatus.Collected,
            panes: [
              {
                paneType: PaneType.formFieldsPane,
                columnCount: 2,
                fields: [
                  {
                    fieldType: FieldType.textField,
                    label: 'Name',
                    dataAddr: 'ownerName',
                    maxLength: 200,
                    mandatory: d => d.parentValue.status === LostPropertyItemStatus.Collected,
                  },
                  {
                    fieldType: FieldType.textField,
                    label: 'Phone Number',
                    dataAddr: 'ownerPhone',
                    maxLength: 15,
                    onChange: api =>
                      api.setFormValue(
                        api.fieldDataAddr,
                        standardTextSanitisers.numericSanitiser(api.fieldData.fieldValue as string)
                      ),
                    validate: d =>
                      d.parentValue.status === LostPropertyItemStatus.Collected &&
                      !d.fieldValue &&
                      !d.parentValue.ownerAddress &&
                      !d.parentValue.ownerEmail
                        ? 'Please provide at least one contact option.'
                        : undefined,
                  },
                  {
                    fieldType: FieldType.textAreaField,
                    label: 'Address',
                    dataAddr: 'ownerAddress',
                    maxLength: 500,
                    validate: d =>
                      d.parentValue.status === LostPropertyItemStatus.Collected &&
                      !d.fieldValue &&
                      !d.parentValue.ownerEmail &&
                      !d.parentValue.ownerPhone
                        ? 'Please provide at least one contact option.'
                        : undefined,
                  },
                  {
                    fieldType: FieldType.textField,
                    label: 'Email Address',
                    dataAddr: 'ownerEmail',
                    maxLength: 30,
                    validate: d =>
                      d.parentValue.status === LostPropertyItemStatus.Collected &&
                      !d.fieldValue &&
                      !d.parentValue.ownerAddress &&
                      !d.parentValue.ownerPhone
                        ? 'Please provide at least one contact option.'
                        : undefined,
                  },
                  {
                    fieldType: FieldType.dateField,
                    label: 'Collected On',
                    dataAddr: 'collectedOn',
                    mandatory: d => d.parentValue.status === LostPropertyItemStatus.Collected,
                    validate: d =>
                      this.validateDateTimeIsNotAfterToday(
                        d.parentValue.collectedOn,
                        'Collected On'
                      ),
                  },
                ],
              },
            ],
          },
          {
            title: 'Disposal Details',
            hidden: d =>
              (d.sectionValue as LostPropertyItemType).status !== LostPropertyItemStatus.Disposed,
            panes: [
              {
                paneType: PaneType.formFieldsPane,
                columnCount: 2,
                fields: [
                  {
                    fieldType: FieldType.selectField,
                    label: 'Disposal Type',
                    dataAddr: 'disposalType',
                    valueKey: 'value',
                    descriptionKey: 'description',
                    useValueOnly: true,
                    optionItems: allDisposalType,
                    mandatory: d => d.parentValue.status === LostPropertyItemStatus.Disposed,
                  },
                  {
                    fieldType: FieldType.dateField,
                    label: 'Disposed On',
                    dataAddr: 'disposedOn',
                    mandatory: d => d.parentValue.status === LostPropertyItemStatus.Disposed,
                    validate: d =>
                      this.validateDateTimeIsNotAfterToday(d.parentValue.disposedOn, 'Disposed On'),
                  },
                ],
              },
            ],
          },
        ],
        onFormPreSubmit: this.isUpdateMode
          ? this.handlePreSubmitForUpdate
          : this.handlePreSubmitForCreate,
        onFormSubmit: this.isUpdateMode
          ? this.props.updateLostPropertyItem
          : this.props.createLostPropertyItem,
      },
    };
  };

  render() {
    const { mode, loadLostPropertyItem, LostPropertyItem, canManageLostPropertyItem } = this.props;
    return (
      <CrudPage
        def={({ updating }) => this.getPageDef(updating)}
        mode={mode}
        isEditingForbidden={!canManageLostPropertyItem}
        onLoadData={() => loadLostPropertyItem(this.LostPropertyItemId)}
        data={LostPropertyItem}
        createDefaultData={{}}
      />
    );
  }
}

export default MaintainLostPropertyItem;
