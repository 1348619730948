import { types, getRoot, flow } from 'mobx-state-tree';
import { IAutocompleteResult } from 'src/domain/baseTypes';
import { IRootStoreModel } from 'src/domain/entities/RootStoreModel';
import { getAjax, NotificationType } from 'src/domain/services';

type CreatePurchaseOrderCommand = Workshop.Domain.Commands.Purchasing.CreatePurchaseOrderCommand;
type UpdatePurchaseOrderCommand = Workshop.Domain.Commands.Purchasing.UpdatePurchaseOrderCommand;
type AssetHousingLocation = Workshop.Domain.AggregatesModel.AssetAggregate.AssetHousingLocation;
type PurchaseOrderItem = Workshop.Domain.Queries.Purchasing.PurchaseOrderItem;
type ActivityLogTransaction = Workshop.Domain.Queries.ActivityLog.ActivityLogTransaction;
type CreateGoodsReceivedCommand = Workshop.Domain.Commands.Purchasing.CreateGoodsReceivedCommand;
type UpdateGoodsReceivedCommand = Workshop.Domain.Commands.Purchasing.UpdateGoodsReceivedCommand;
type CreateReturnedPartCreditCommand = Workshop.Domain.Commands.Purchasing.CreateReturnedPartCreditCommand;
type UpdateReturnedPartCreditCommand = Workshop.Domain.Commands.Purchasing.UpdateReturnedPartCreditCommand;
type PurchaseOrderInvoice = Workshop.Domain.Queries.Purchasing.GetPurchaseOrderInvoices.PurchaseOrderInvoice;
type PurchaseOrderItemForDropdown = Workshop.Domain.Queries.Purchasing.ListPurchaseOrdersForDropdown.PurchaseOrderItemForDropdown;

interface IDepartment {
  value: string;
}

export const PurchaseOrderModel = types
  .model('PurchaseOrderModel', {
    purchaseOrder: types.maybe(types.frozen<PurchaseOrderItem>()),
    purchaseOrderInvoices: types.array(types.frozen<PurchaseOrderInvoice>()),
    deliveryLocations: types.array(types.frozen<AssetHousingLocation>()),
    departments: types.array(types.frozen<IDepartment>()),
    activityLogs: types.array(types.frozen<ActivityLogTransaction>()),
  })
  .actions(self => {
    const ajax = getAjax(self);
    const root = getRoot(self) as IRootStoreModel;
    let lastSearch: string | undefined;
    let lastResult: IAutocompleteResult<PurchaseOrderItemForDropdown>;

    const createPurchaseOrder = flow(function*(command: CreatePurchaseOrderCommand) {
      const id = yield ajax.workshop.purchaseOrders.createPurchaseOrder(command);
      self.purchaseOrder = undefined;
      root.history.push(`/workshop/purchase-orders/${id}`);
    });

    const loadDeliveryLocations = flow(function*() {
      // HACK - this is a temporary solution until owners are removed as a valid asset housing location
      const allLocations: AssetHousingLocation[] = yield ajax.asset.getAssetHousingLocations();
      const ownerItem = allLocations.find(i => i.description === 'Owner');
      if (ownerItem) {
        const idx = allLocations.indexOf(ownerItem);
        allLocations.splice(idx, 1);
      }
      self.deliveryLocations.replace(allLocations);
    });

    const clearActivityLog = () => {
      self.activityLogs.clear();
    };

    const getActivityLog = flow(function*(purchaseOrderId: string) {
      self.activityLogs = yield ajax.workshop.activityLog.getActivityLogForPurchaseOrder(
        purchaseOrderId
      );
    });

    const loadPurchaseOrder = flow(function*(purchaseOrderId: string) {
      self.purchaseOrder = yield ajax.workshop.purchaseOrders.getPurchaseOrder(purchaseOrderId);
      yield getActivityLog(purchaseOrderId);
    });

    const updatePurchaseOrder = flow(function*(command: UpdatePurchaseOrderCommand) {
      yield ajax.workshop.purchaseOrders.updatePurchaseOrder(command);
      yield loadPurchaseOrder(command.id);
    });

    const approvePurchaseOrder = flow(function*(purchaseOrderId: string) {
      yield ajax.workshop.purchaseOrders.approvePurchaseOrder(purchaseOrderId);
      yield loadPurchaseOrder(purchaseOrderId);
    });

    const reinstatePurchaseOrder = flow(function*(purchaseOrderId: string) {
      yield ajax.workshop.purchaseOrders.reinstatePurchaseOrder(purchaseOrderId);
      yield loadPurchaseOrder(purchaseOrderId);
    });

    const cancelPurchaseOrder = flow(function*(purchaseOrderId: string) {
      yield ajax.workshop.purchaseOrders.cancelPurchaseOrder(purchaseOrderId);
      yield loadPurchaseOrder(purchaseOrderId);
    });

    const completePurchaseOrder = flow(function*(purchaseOrderId: string) {
      yield ajax.workshop.purchaseOrders.completePurchaseOrder(purchaseOrderId);
      yield loadPurchaseOrder(purchaseOrderId);
    });

    const generatePdf = flow(function*(purchaseOrderId: string) {
      root.notifications.addNotification(`The PDF is being generated ...`, {
        type: NotificationType.info,
      });

      return yield ajax.workshop.purchaseOrders.generatePdf(purchaseOrderId);
    });

    const createGoodsReceived = flow(function*(command: CreateGoodsReceivedCommand) {
      const id = yield ajax.workshop.purchaseOrders.createGoodsReceived(command);
      root.history.push(
        `/workshop/purchase-orders/${command.purchaseOrderId}/goods-received/${id}`
      );
    });

    const updateGoodsReceived = flow(function*(command: UpdateGoodsReceivedCommand) {
      yield ajax.workshop.purchaseOrders.updateGoodsReceived(command);
      yield loadPurchaseOrder(command.purchaseOrderId);
    });

    const completePurchaseOrderLine = flow(function*(
      purchaseOrderId: string,
      purchaseOrderLineId: number
    ) {
      yield ajax.workshop.purchaseOrders.completePurchaseOrderLine(
        purchaseOrderId,
        purchaseOrderLineId
      );
      yield loadPurchaseOrder(self.purchaseOrder!.id);
    });

    const createReturnedPartCredit = flow(function*(command: CreateReturnedPartCreditCommand) {
      const id = yield ajax.workshop.purchaseOrders.createReturnedPartCredit(command);
      root.history.push(
        `/workshop/purchase-orders/${command.purchaseOrderId}/goods-received/${command.goodsReceivedId}/returned-part-credits/${id}`
      );
    });

    const updateReturnedPartCredit = flow(function*(command: UpdateReturnedPartCreditCommand) {
      yield ajax.workshop.purchaseOrders.updateReturnedPartCredit(command);
      yield loadPurchaseOrder(command.purchaseOrderId);
    });

    const deleteReturnedPartCredit = flow(function*(
      purchaseOrderId: string,
      goodsReceivedId: number,
      creditNoteId: number,
      deleteReason: string
    ) {
      yield ajax.workshop.purchaseOrders.deleteReturnedPartCredit(
        purchaseOrderId,
        goodsReceivedId,
        creditNoteId,
        deleteReason
      );
      yield loadPurchaseOrder(purchaseOrderId);
      root.notifications.addNotification(`Successfully deleted Returned Credit Part`, {
        type: NotificationType.success,
      });
      root.history.push(`/workshop/purchase-orders/${purchaseOrderId}`);
    });

    const loadPurchaseOrderInvoices = flow(function*(purchaseOrderId: string) {
      self.purchaseOrderInvoices = yield ajax.workshop.purchaseOrders.getPurchaseOrderInvoices(
        purchaseOrderId
      );
    });

    const searchPurchaseOrders = flow(function*(search: string) {
      if (search === lastSearch) {
        return lastResult;
      }

      const result: Common.Dtos.ListResult<PurchaseOrderItemForDropdown> = yield ajax.workshop.purchaseOrders
        .listPurchaseOrdersForDropdown({
          search: search,
          size: 10,
        })
        .toPromise();

      lastSearch = search;
      lastResult = {
        options: result.items,
      };

      return lastResult;
    });

    const findPurchaseOrders = flow(function*(purchaseOrderIds: string[]) {
      if (!purchaseOrderIds || !purchaseOrderIds.length) {
        return { options: [] };
      }

      const purchaseOrders: PurchaseOrderItemForDropdown[] = yield ajax.workshop.purchaseOrders.findPurchaseOrders(
        purchaseOrderIds
      );

      return { options: purchaseOrders } as IAutocompleteResult<PurchaseOrderItemForDropdown>;
    });

    const markAsExported = flow(function*(purchaseOrderId: string, goodsReceivedId: number) {
      yield ajax.workshop.purchaseOrders.markGoodsReceivedAsExported(
        purchaseOrderId,
        goodsReceivedId
      );
      yield loadPurchaseOrder(self.purchaseOrder!.id);
    });

    const removeExportedIndicator = flow(function*(
      purchaseOrderId: string,
      goodsReceivedId: number
    ) {
      yield ajax.workshop.purchaseOrders.removeExportedIndicatorFromGoodsReceived(
        purchaseOrderId,
        goodsReceivedId
      );
      yield loadPurchaseOrder(self.purchaseOrder!.id);
    });

    const markCreditNoteAsExported = flow(function*(
      purchaseOrderId: string,
      goodsReceivedId: number,
      creditNoteId: number
    ) {
      yield ajax.workshop.purchaseOrders.markCreditNoteAsExported(
        purchaseOrderId,
        goodsReceivedId,
        creditNoteId
      );
      yield loadPurchaseOrder(self.purchaseOrder!.id);
    });

    const removeExportedIndicatorFromCreditNote = flow(function*(
      purchaseOrderId: string,
      goodsReceivedId: number,
      creditNoteId: number
    ) {
      yield ajax.workshop.purchaseOrders.removeExportedIndicatorFromCreditNote(
        purchaseOrderId,
        goodsReceivedId,
        creditNoteId
      );
      yield loadPurchaseOrder(self.purchaseOrder!.id);
    });

    return {
      createPurchaseOrder,
      loadPurchaseOrder,
      updatePurchaseOrder,
      approvePurchaseOrder,
      reinstatePurchaseOrder,
      cancelPurchaseOrder,
      completePurchaseOrder,
      createGoodsReceived,
      updateGoodsReceived,
      completePurchaseOrderLine,
      loadDeliveryLocations,
      clearActivityLog,
      generatePdf,
      createReturnedPartCredit,
      updateReturnedPartCredit,
      deleteReturnedPartCredit,
      loadPurchaseOrderInvoices,
      searchPurchaseOrders,
      findPurchaseOrders,
      markAsExported,
      removeExportedIndicator,
      markCreditNoteAsExported,
      removeExportedIndicatorFromCreditNote,
    };
  });
