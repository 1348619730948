import { types, flow } from 'mobx-state-tree';
import { getAjax } from 'src/domain/services';

type AssetGroupDetails = Workshop.Domain.Queries.AssetGroup.AssetGroupDetails;

export const AssetGroupsModel = types
  .model('AssetGroupsModel', {
    assetGroupsListItems: types.array(types.frozen<AssetGroupDetails>()),
  })
  .views(self => ({
    get activeAssetGroups() {
      return self.assetGroupsListItems.filter(a => a.isActive);
    },
  }))
  .actions(self => {
    const ajax = getAjax(self);

    const listAssetGroups = flow(function*(includeInactiveAssetGroups?: boolean) {
      self.assetGroupsListItems = yield ajax.assetGroups.listAssetGroups(
        includeInactiveAssetGroups ?? false
      );
    });

    return {
      listAssetGroups,
    };
  });
