import { RouteComponentProps } from 'react-router-dom';
import {
  PagePrimarySize,
  PaneType,
  FieldType,
  ActionType,
  ShellModalSize,
} from 'src/views/definitionBuilders/types';
import CrudPage, { CrudPageMode, ICrudPageDef } from 'src/views/components/Page/pages/CrudPage';
import { TrashIcon, PlusIcon } from 'src/images/icons';
import getCanPartCategoryBeDeletedModalDef from './GetCanPartCategoryBeDeletedModalDef';
import { observer } from 'mobx-react';
import { useRootStore } from 'src/domain/entities/RootStoreModel';
import { getSubmitCloseModalActionGroupDef } from 'src/views/definitionBuilders/common';

type PartCategoryItem = Workshop.Domain.Queries.PartCategory.PartCategoryItem;
type CreatePartCategoryCommand = Workshop.Domain.Commands.Part.CreatePartCategory.CreatePartCategoryCommand;
type UpdatePartCategoryCommand = Workshop.Domain.Commands.Part.UpdatePartCategory.UpdatePartCategoryCommand;

export interface IMaintainPartCategoryProps {
  mode: CrudPageMode;
}

interface IUpdatePartCategoryRouteParams {
  id: string;
}

type InternalProps = IMaintainPartCategoryProps &
  RouteComponentProps<IUpdatePartCategoryRouteParams>;

const MaintainPartCategory: React.FC<InternalProps> = observer((props: InternalProps) => {
  const rootStore = useRootStore();
  const accountModel = rootStore.account;
  const partCategoryModel = rootStore.workshop.partCategory;
  const canManagePartCategories = accountModel.isWorkshopDepartmentMember;
  const onLoadPartCategory = partCategoryModel.loadPartCategory;
  const partCategory = partCategoryModel.partCategory;
  const checkForUniquePartCategoryName = partCategoryModel.checkForUniqueName;
  const onCreatePartCategory = partCategoryModel.createPartCategory;
  const onUpdatePartCategory = partCategoryModel.updatePartCategory;
  const deletePartCategory = partCategoryModel.deletePartCategory;
  const canDeletePartCategory = partCategoryModel.canDeletePartCategory;
  const loadCanDeletePartCategory = partCategoryModel.loadCanDeletePartCategory;
  const setPartCategoryActive = partCategoryModel.setPartCategoryActive;
  const setPartCategoryInactive = partCategoryModel.setPartCategoryInactive;

  const isUpdateMode = props.mode === 'update';
  const partCategoryId = Number.parseInt(props.match.params.id);

  const handlePreSubmitForCreate = (item: PartCategoryItem): CreatePartCategoryCommand => {
    return {
      description: item.description,
      generalLedgerCode: item.generalLedgerCode,
    };
  };

  const handlePreSubmitForUpdate = (item: PartCategoryItem): UpdatePartCategoryCommand => {
    return {
      id: partCategoryId,
      description: item.description,
      generalLedgerCode: item.generalLedgerCode,
    };
  };

  const getPageDef = (): ICrudPageDef => {
    return {
      primarySize: PagePrimarySize.half,
      primarySection: {
        title: isUpdateMode ? 'Manage Part Category' : 'Create a Part Category',
        badge: partCategory && !partCategory.isActive ? { label: 'Inactive' } : undefined,
        primaryActions: [
          {
            actions: [
              {
                actionType: ActionType.actionCollection,
                hidden: !isUpdateMode,
                actionGroups: [
                  {
                    actions: [
                      {
                        actionType: ActionType.modalActionButton,
                        label: 'Delete Part Category',
                        icon: <TrashIcon />,
                        hidden: !partCategory || !partCategory.isActive,
                        modalSize: ShellModalSize.oneQuarter,
                        modalDef: getCanPartCategoryBeDeletedModalDef(
                          partCategoryId,
                          canDeletePartCategory!,
                          deletePartCategory,
                          setPartCategoryInactive
                        ),
                      },
                      {
                        actionType: ActionType.modalActionButton,
                        label: 'Mark as Active',
                        icon: <PlusIcon />,
                        hidden: !partCategory || partCategory.isActive,
                        modalSize: ShellModalSize.oneQuarter,
                        modalDef: modalDefApi => ({
                          title: 'Mark as Active',
                          asForm: true,
                          panels: [
                            {
                              title: '',
                              panes: [
                                {
                                  paneType: PaneType.customPane,
                                  render: () => (
                                    <div>
                                      <p>
                                        Once active, the part category will be searchable and will
                                        be available for use throughout the application.
                                      </p>
                                      <p>
                                        Are you sure you want to mark this part category as active?
                                      </p>
                                    </div>
                                  ),
                                },
                              ],
                            },
                          ],
                          secondaryActions: [getSubmitCloseModalActionGroupDef('Mark active')],
                          onFormSubmit: () => setPartCategoryActive(partCategoryId),
                        }),
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
        panels: [
          {
            panes: [
              {
                paneType: PaneType.formFieldsPane,
                columnCount: 1,
                fields: [
                  {
                    fieldType: FieldType.textField,
                    label: 'Description',
                    dataAddr: 'description',
                    maxLength: 200,
                    mandatory: true,
                    validateAsync: async d => {
                      if (
                        !d.fieldValue ||
                        (isUpdateMode &&
                          partCategory &&
                          partCategory.description.toUpperCase() === d.fieldValue.toUpperCase())
                      ) {
                        return undefined;
                      }
                      const result = await checkForUniquePartCategoryName(d.fieldValue);
                      return result.nameExists ? `Description is already in use` : undefined;
                    },
                  },
                ],
              },
            ],
          },
          {
            panes: [
              {
                paneType: PaneType.formFieldsPane,
                columnCount: 1,
                fields: [
                  {
                    fieldType: FieldType.textField,
                    label: 'GL Code',
                    dataAddr: 'generalLedgerCode',
                    maxLength: 200,
                  },
                ],
              },
            ],
          },
        ],
        onFormPreSubmit: isUpdateMode ? handlePreSubmitForUpdate : handlePreSubmitForCreate,
        onFormSubmit: isUpdateMode ? onUpdatePartCategory : onCreatePartCategory,
      },
    };
  };

  return (
    <CrudPage
      def={getPageDef()}
      mode={props.mode}
      isEditingForbidden={!canManagePartCategories}
      onLoadData={() =>
        Promise.all([
          onLoadPartCategory(partCategoryId),
          loadCanDeletePartCategory(partCategoryId),
        ]).then(() => Promise.resolve())
      }
      data={partCategory}
      createDefaultData={{}}
    />
  );
});

export default MaintainPartCategory;
