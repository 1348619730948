import { withSelectedFormApi } from 'src/views/components/Page/forms';
import {
  IActionListPaneDef,
  IPaneData,
  IActionData,
  IPaneMeta,
} from 'src/views/definitionBuilders/types';
import ActionBar from 'src/views/components/Page/actions/ActionBar';
import { memo } from 'react';

interface IActionListPagePaneProps {
  paneDef: IActionListPaneDef;
  paneMeta: IPaneMeta;
  paneData: IPaneData;
  submitting: boolean;
}

const ActionListPagePane: React.FC<IActionListPagePaneProps> = memo(
  ({ paneDef, paneMeta, paneData, submitting }) => {
    const actionData: IActionData = {
      ...paneData,
      actionValue: paneData.paneValue,
    };
    return (
      <div className="action-list-page-pane-component">
        <ActionBar
          actionGroupDefs={paneDef.actionGroups}
          actionData={actionData}
          actionMeta={{
            removeArrayItem: paneMeta.removeArrayItem,
            borderless: true,
            formSubmitting: submitting,
          }}
        />
      </div>
    );
  }
);

export default withSelectedFormApi(formApi => ({
  submitting: formApi.submitting,
}))(ActionListPagePane);
