import { PlusIcon, EditIcon, TrashIcon } from 'src/images/icons';
import {
  PaneType,
  FieldType,
  ActionType,
  ShellModalSize,
  ISectionDef,
} from 'src/views/definitionBuilders/types';
import getLicenceModalDef from './getLicenceModalDef';
import { getSubmitCloseModalActionGroupDef } from 'src/views/definitionBuilders/common';

type StaffMemberLicenceItem = People.Domain.Queries.ViewStaffMember.StaffMemberItem.StaffMemberLicenceItem;
type LicenceCondition = Common.AggregatesModel.People.StaffMemberAggregate.LicenceCondition;
type LicenceType = Common.AggregatesModel.People.StaffMemberAggregate.LicenceType;
type CreateStaffMemberLicenceCommand = People.Domain.Commands.StaffMembers.CreateStaffMemberLicence.CreateStaffMemberLicenceCommand;
type UpdateStaffMemberLicenceCommand = People.Domain.Commands.StaffMembers.UpdateStaffMemberLicence.UpdateStaffMemberLicenceCommand;
type DeleteStaffMemberLicenceCommand = People.Domain.Commands.StaffMembers.DeleteStaffMemberLicence.DeleteStaffMemberLicenceCommand;

export default function getLicencesSectionDef(
  canSave: boolean,
  allLicenceTypes: LicenceType[],
  licences: StaffMemberLicenceItem[] | undefined,
  staffMemberId: string,
  createLicence: (cmd: CreateStaffMemberLicenceCommand) => Promise<void>,
  updateLicence: (cmd: UpdateStaffMemberLicenceCommand) => Promise<void>,
  deleteLicence: (cmd: DeleteStaffMemberLicenceCommand) => Promise<void>,
  primarySectionEditing: boolean
): ISectionDef {
  return {
    title: 'Licences',
    panels: [
      {
        panes: [
          {
            paneType: PaneType.tablePane,
            dataAddr: 'licences',
            neverEditable: true,
            fields: [
              {
                fieldType: FieldType.readonlyField,
                dataAddr: 'licenceTypeId',
                label: 'Type',
                formatReadonly: d => allLicenceTypes.find(x => x.id === d.fieldValue)?.description,
              },
              {
                fieldType: FieldType.readonlyField,
                dataAddr: 'licenceClass',
                label: 'Class (Conditions)',
                formatReadonly: d => {
                  if (!d.fieldValue) {
                    return '―';
                  }
                  const classCode = d.fieldValue ? d.fieldValue.classCode : '';
                  const classDescr = d.fieldValue ? d.fieldValue.description : '';
                  const conditions = (d.parentValue.conditions || []) as LicenceCondition[];
                  const condCodes = conditions.map(c => c.conditionCode).join(', ');
                  const condDescrs = conditions.map(c => c.description).join(', ');
                  return conditions.length ? (
                    <span title={`${classDescr} (${condDescrs})`}>
                      {classCode} ({condCodes})
                    </span>
                  ) : (
                    <span title={classDescr}>{classCode}</span>
                  );
                },
              },
              {
                fieldType: FieldType.textField,
                dataAddr: 'licenceNumber',
                label: 'Licence Number',
              },
              {
                fieldType: FieldType.dateField,
                dataAddr: 'expiry',
                label: 'Expiry',
              },
              {
                fieldType: FieldType.actionListField,
                columnWidth: '1px',
                hidden: () => !canSave || primarySectionEditing,
                actionGroups: [
                  {
                    actions: [
                      {
                        actionType: ActionType.modalActionButton,
                        label: 'Edit Licence',
                        icon: <EditIcon />,
                        modalSize: ShellModalSize.half,
                        modalDef: getLicenceModalDef(
                          'edit',
                          allLicenceTypes,
                          licences,
                          staffMemberId,
                          createLicence,
                          updateLicence
                        ),
                      },
                      {
                        actionType: ActionType.modalActionButton,
                        icon: <TrashIcon />,
                        label: 'Delete Licence',
                        modalSize: ShellModalSize.oneQuarter,
                        modalDef: modalDefApi => ({
                          title: 'Delete Licence',
                          asForm: true,
                          panels: [
                            {
                              panes: [
                                {
                                  paneType: PaneType.customPane,
                                  render: _ => <p>Are you sure you want to delete this licence?</p>,
                                },
                              ],
                            },
                          ],
                          secondaryActions: [getSubmitCloseModalActionGroupDef('Ok')],
                          onFormSubmit: () => {
                            const licence = modalDefApi.actionData
                              .parentValue as StaffMemberLicenceItem;
                            return deleteLicence({
                              staffMemberId: staffMemberId,
                              licenceId: licence.id,
                            });
                          },
                        }),
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
    secondaryActions: [
      {
        actions: [
          {
            hidden: () => !canSave || primarySectionEditing,
            actionType: ActionType.modalActionButton,
            label: 'Add Licence',
            icon: <PlusIcon />,
            level: 'primary',
            modalSize: ShellModalSize.half,
            modalDef: getLicenceModalDef(
              'add',
              allLicenceTypes,
              licences,
              staffMemberId,
              createLicence,
              updateLicence
            ),
          },
        ],
      },
    ],
  };
}
