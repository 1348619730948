import { IRootStoreModel } from 'src/domain/entities/RootStoreModel';
import { UiRoute } from 'src/domain/services/buildUiRoutes';
import { UiRouteName } from 'src/domain/baseTypes';

export default function buildOperationsDiaryRoutes(store: IRootStoreModel) {
  const operationsDiaryEntryCreate = new UiRoute(
    UiRouteName.operationsOperationsDiaryEntryCreate,
    '/operations/daily-event-log/create',
    () => 'Create Daily Event Log Entry',
    false
  );

  const operationsDiaryEntryManage = new UiRoute(
    UiRouteName.operationsOperationsDiaryEntryUpdate,
    '/operations/daily-event-log/:id',
    () => 'Manage Daily Event Log Entry',
    false
  );

  const operationsDiaryEntryList = new UiRoute(
    UiRouteName.operationsOperationsDiaryEntryList,
    '/operations/daily-event-log',
    () => 'Daily Event Log',
    false,
    true,
    [operationsDiaryEntryCreate, operationsDiaryEntryManage]
  );

  const maintainOperationsDiaryEntrySubjects = new UiRoute(
    UiRouteName.operationsMaintainOperationsDiaryEntrySubjects,
    '/operations/daily-event-log-subjects',
    () => 'Maintain Daily Event Log Subjects',
    true
  );

  return {
    [UiRouteName.operationsOperationsDiaryEntryList]: operationsDiaryEntryList,
    [UiRouteName.operationsOperationsDiaryEntryCreate]: operationsDiaryEntryCreate,
    [UiRouteName.operationsOperationsDiaryEntryUpdate]: operationsDiaryEntryManage,
    [UiRouteName.operationsMaintainOperationsDiaryEntrySubjects]: maintainOperationsDiaryEntrySubjects,
  };
}
