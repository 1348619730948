import { PaneType, FieldType, ModalDefBuilder } from 'src/views/definitionBuilders/types';
import { isDefined } from 'src/infrastructure/typeUtils';
import { getSubmitCloseModalActionGroupDef } from 'src/views/definitionBuilders/common';

type ServiceCycleItem = Workshop.Domain.Queries.AssetGroup.GetAssetGroupFullServiceCycle.ServiceCycleItem;

export default function getResetScheduleModalDef(
  fullServiceCycle: Array<ServiceCycleItem>,
  onReset: (
    lastServiceDate: string,
    lastServiceKms: number,
    lastServiceCycle: ServiceCycleItem | undefined
  ) => Promise<void>
): ModalDefBuilder {
  const fullServiceCycleForSelect = fullServiceCycle.map(x => {
    return {
      ...x,
      key: `${x.serviceType.description}-${x.dueDays || 0}-${x.dueKms || 0}`,
    };
  });

  return modalDefApi => ({
    title: 'Reset Schedule',
    asForm: true,
    explicitData: { fullServiceCycle },
    panels: [
      {
        hidden: d => !d.panelValue.length,
        dataAddr: 'fullServiceCycle',
        panes: [
          {
            paneType: PaneType.tablePane,
            neverEditable: true,
            mandatory: false,
            fields: [
              {
                fieldType: FieldType.textField,
                label: 'Service Type',
                dataAddr: ['serviceType', 'description'],
                columnWidth: '12em',
                readonly: true,
              },
              {
                fieldType: FieldType.numericField,
                label: 'Days Due',
                dataAddr: 'dueDays',
                columnWidth: '9em',
                readonly: true,
              },
              {
                fieldType: FieldType.numericField,
                label: 'Kms Due',
                dataAddr: 'dueKms',
                columnWidth: '9em',
                readonly: true,
              },
            ],
          },
        ],
      },
      {
        panes: [
          {
            paneType: PaneType.customPane,
            render: api => (
              <p>
                Please note, this will clear all upcoming planned services and will re-generate the
                service schedule
              </p>
            ),
          },
          {
            paneType: PaneType.formFieldsPane,
            fields: [
              {
                fieldType: FieldType.selectField,
                label: 'Last Service',
                dataAddr: 'serviceCycleItem',
                valueKey: 'key',
                descriptionKey: 'none',
                optionItems: fullServiceCycleForSelect,
                optionRenderer: (o: ServiceCycleItem) => (
                  <div>
                    <span>{`${o.serviceType.description}`}</span>
                    {(isDefined(o.dueDays) || isDefined(o.dueKms)) && (
                      <>
                        &emsp;
                        <small>
                          {`${isDefined(o.dueDays) ? `${o.dueDays} days` : ''}${
                            isDefined(o.dueDays) && isDefined(o.dueKms) ? ' - ' : ''
                          }${isDefined(o.dueKms) ? `${o.dueKms} kms` : ''}`}
                        </small>
                      </>
                    )}
                  </div>
                ),
                useOptionRendererAsValueRenderer: true,
              },
              {
                fieldType: FieldType.dateField,
                dataAddr: 'startingDate',
                label: 'Last Service Date',
                mandatory: true,
              },
              {
                fieldType: FieldType.numericField,
                dataAddr: 'startingKms',
                label: 'Last Service Kms',
                maxLength: 7,
                numericConfig: { numericType: 'unsignedInt', minValue: 0 },
              },
            ],
          },
        ],
      },
    ],
    secondaryActions: [getSubmitCloseModalActionGroupDef('Reset Service Schedule')],
    onFormSubmit: (values: {
      startingDate: string;
      startingKms: number;
      serviceCycleItem: ServiceCycleItem;
    }) => {
      return onReset(values.startingDate, values.startingKms, values.serviceCycleItem);
    },
  });
}
