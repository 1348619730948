import { getSubmitCloseModalActionGroupDef } from 'src/views/definitionBuilders/common';
import { PaneType, ModalDefBuilder } from 'src/views/definitionBuilders/types';

export default function getCompleteQuoteModalDef(onComplete: () => Promise<void>): ModalDefBuilder {
  return modalDefApi => {
    const totalPrice = modalDefApi.actionData.actionValue.totalPrice;
    return {
      title: 'Complete Booking',
      asForm: true,
      panels: [
        {
          panes: [
            {
              paneType: PaneType.customPane,
              render: api => (
                <>
                  {totalPrice === 0 && (
                    <h1>
                      Please Note: This booking has zero value associated with it. Please confirm
                      that this booking should be completed with a zero value.
                    </h1>
                  )}
                  <p>Are you sure you wish to complete this booking?</p>
                </>
              ),
            },
          ],
        },
      ],
      secondaryActions: [getSubmitCloseModalActionGroupDef('Complete')],
      onFormSubmit: onComplete,
    };
  };
}
