import { Fragment } from 'react';
import { Table } from 'reactstrap';
import './PrintJobTaskChecklist.scss';

interface IPrintJobTaskChecklistProps {
  checklist: Workshop.Domain.Queries.Job.PrintJobQuery.JobTaskItemChecklist;
}

const PrintJobTaskChecklist: React.FC<IPrintJobTaskChecklistProps> = ({ checklist }) => {
  const renderBody = (
    items: Workshop.Domain.Queries.Job.PrintJobQuery.JobTaskItemSubchecklistItem[]
  ) => {
    var tempArray = [];
    for (var index = 0; index < items.length; index += 3) {
      var chunk = items.slice(index, index + 3);
      tempArray.push(chunk);
    }
    return tempArray.map((group, i) => {
      return (
        <tr key={i}>
          {group.map((groupItem, j) => {
            return (
              <Fragment key={j}>
                <td className="description">
                  <div>{groupItem.description}</div>
                  {groupItem.partNumber && (
                    <div>
                      <strong>Part Number:</strong> {groupItem.partNumber}
                    </div>
                  )}
                  {groupItem.quantity && (
                    <div>
                      <strong>Quantity</strong> {groupItem.quantity}
                    </div>
                  )}
                </td>
                <td className="initials" />
                <td className="spacer" />
              </Fragment>
            );
          })}
        </tr>
      );
    });
  };

  if (!checklist) {
    return null;
  }

  return (
    <div className="print-job-task-checklist-component">
      <h3 className="checklist-name">Checklist: {checklist.name}</h3>
      {checklist.subchecklists.map((subchecklist, i) => {
        return (
          <div key={subchecklist.id}>
            <h4 className="subchecklist-name">{subchecklist.name}</h4>
            <Table>
              <tbody>{renderBody(subchecklist.items)}</tbody>
            </Table>
          </div>
        );
      })}
    </div>
  );
};

export default PrintJobTaskChecklist;
