import { IRootStoreModel } from 'src/domain/entities/RootStoreModel';
import { UiRoute } from 'src/domain/services/buildUiRoutes';
import { UiRouteName } from 'src/domain/baseTypes';

export default function buildUiRoutes(store: IRootStoreModel) {
  const vehicleTypeCreate = new UiRoute(
    UiRouteName.salesVehicleTypeCreate,
    '/sales/vehicle-types/create',
    () => 'Create Vehicle Type',
    false
  );

  const vehicleTypeManage = new UiRoute(
    UiRouteName.salesVehicleTypeManage,
    '/sales/vehicle-types/:id',
    () => 'Manage Vehicle Type',
    false
  );

  const vehicleTypesList = new UiRoute(
    UiRouteName.salesVehicleTypeList,
    '/sales/vehicle-types',
    () => 'Vehicles Types',
    true,
    false,
    [vehicleTypeCreate, vehicleTypeManage]
  );

  return {
    [UiRouteName.salesVehicleTypeList]: vehicleTypesList,
    [UiRouteName.salesVehicleTypeCreate]: vehicleTypeCreate,
    [UiRouteName.salesVehicleTypeManage]: vehicleTypeManage,
  };
}
