// tslint:disable:no-any

import * as History from 'history';
import { Observable } from 'rxjs/Observable';
import { HandlerRendererResult } from 'react-select';
import Omit from 'src/infrastructure/omit';
import { IAutocompleteResult } from 'src/domain/baseTypes';
import { IFieldApi } from 'src/views/components/Page/forms/Field';
import { IFormChange } from './panel';
import { DataAddr } from './page';
import { IActionGroupDef } from './action';
import { StaffMemberFilter } from 'src/views/components/Page/fields/StaffMemberField';
import { IShortTechSpecRequirement } from 'src/domain/entities/workshop/techSpecs/TechSpecsHelpers';
import { IFile } from 'src/views/components/Page/fields/MultiFilePageField';
import { IFormApi } from 'src/views/components/Page/forms/base';
import { LicenceClass } from 'src/api/enums';

export enum FieldType {
  customField = 'customField',
  readonlyField = 'readonlyField',
  errorField = 'errorField',
  textField = 'textField',
  textAreaField = 'textAreaField',
  numericField = 'numericField',
  toggleButtonField = 'toggleButtonField',
  toggleMultiButtonField = 'toggleMultiButtonField',
  selectField = 'selectField',
  selectCreatableField = 'selectCreatableField',
  selectMultiField = 'selectMultiField',
  selectAsyncField = 'selectAsyncField',
  selectCreatableAsyncField = 'selectCreatableAsyncField',
  selectAsyncMultiField = 'selectAsyncMultiField',
  dateField = 'dateField',
  dateTimeField = 'datetimeField',
  timeField = 'timeField',
  durationField = 'durationField',
  actionListField = 'actionListField',
  yesNoField = 'yesNoField',
  daysOfWeekField = 'daysOfWeekField',
  staffMemberField = 'staffMemberField',
  assetSelectField = 'assetSelectField',
  weekSelectField = 'weekSelectField',
  fileField = 'fileField',
  multiFileField = 'multiFileField',
}

// Use Partial<IFieldDef> so that we can always check for the presence of the standard properties
export type FieldDefs =
  | (Partial<IFieldDef> &
      (
        | ICustomFieldDef
        | IReadonlyFieldDef
        | IErrorFieldDef
        | ITextFieldDef
        | ITextAreaFieldDef
        | INumericFieldDef
        | IToggleButtonFieldDef
        | IToggleMultiButtonFieldDef
        | ISelectFieldDef
        | ISelectCreatableFieldDef
        | ISelectMultiFieldDef
        | ISelectAsyncFieldDef
        | ISelectCreatableAsyncFieldDef
        | ISelectAsyncMultiFieldDef
        | IDateFieldDef
        | IDateTimeFieldDef
        | ITimeFieldDef
        | IDurationFieldDef
        | IActionListFieldDef
        | IYesNoFieldDef
        | IDaysOfWeekFieldDef
        | IStaffMemberFieldDef
        | IAssetSelectFieldDef
        | IWeekSelectFieldDef
        | IFileFieldDef
      ))
  | IMultiFileFieldDef;

export interface IFieldData<T> {
  fieldDataAddr: Array<string | number>;
  fieldValue: T | undefined; // even mandatory fields may be empty
  parentValue: any;
  paneValue: any;
  panelValue: any;
  sectionValue: any;
}
export type FieldCallback<T, TResult> = (data: IFieldData<T>) => TResult;

export interface IFieldDef {
  dataAddr: DataAddr;
  label: string | FieldCallback<any, string>;
  tooltip?: string | React.ReactNode | FieldCallback<any, string | undefined>;
}
export interface IFieldOnChange<T> {
  oldFieldValue: T;
  newFieldValue: T;
  /** The standard field data object */
  fieldData: IFieldData<T>;
  /** The current values of the form */
  formValues: any;
  /** The data address of this field */
  fieldDataAddr: Array<string | number>;
  /** Set the values of the whole form */
  setFormValues: (newValues: any) => void;
  /** Set the value of the specified field */
  setFormValue: (field: Array<string | number>, value: any) => void;
  /** Get the value of the specified field */
  getFormValue: (field: Array<string | number>) => any;
  /** Trigger validation of the specified field */
  validateField: (field: Array<string | number>) => void;
}

export interface IFieldFunctionsDef<T> {
  hidden?: boolean | FieldCallback<T, boolean>;
  readonly?: boolean | FieldCallback<T, boolean>;
  mandatory?: boolean | FieldCallback<T, boolean>;
  formatReadonly?: FieldCallback<T, React.ReactNode>;
  linkTo?: FieldCallback<T, History.LocationDescriptor | undefined>;
  getSearchText?: FieldCallback<T, string | undefined>;
  validate?: FieldCallback<T, string | undefined>;
  validateAsync?: FieldCallback<T, Promise<string | undefined>>;
  onChange?: (api: IFieldOnChange<T>) => void;
  onBlur?: (api: {
    fieldData: IFieldData<T>;
    formValues: any;
    setFormValues: (newValues: any) => void;
    setFormValue: (field: Array<string | number>, value: any) => void;
    validateField: (field: Array<string | number>) => void;
  }) => void;
}

export interface IFieldMeta {
  readonly?: boolean;
  mandatory?: boolean;
  hideLabel?: boolean;
  noForm?: boolean;
  autoFocus?: boolean;
  formChange$: Observable<IFormChange>;
  removeArrayItem?: () => void;
  arrayItemRemoved?: boolean;
  onBlur?: (fieldValue: any) => void;
}

// -----------------------------------------------------

interface IKeyableField<T> {
  getFieldKey?: (data: IFieldData<T>) => string; // Explicitly define when the field is destroyed/created vs updated
}

// common definition items for fields that show a list of options
export interface IListFieldDefBase<T> {
  valueKey: string;
  descriptionKey: string;
  includeKey?: string;
  valuesToExclude?: Array<any> | FieldCallback<T, Array<any>>;
  valuesToDisable?: Array<any> | FieldCallback<T, Array<any>>;
  useValueOnly?: boolean;
}

// common definition items for fields that show a select field
export interface ISelectFieldDefBase<T> extends IListFieldDefBase<T> {
  optionRenderer?: (option: any) => HandlerRendererResult;
  valueRenderer?: (option: any) => HandlerRendererResult;
  useOptionRendererAsValueRenderer?: boolean;
  placeholder?: string;
  menuWidth?: 'fieldWidth' | 'fitContent' | number; // "fieldWidth" is the default
}

export interface IListSyncFieldDefBase {
  optionItems?: Array<any> | FieldCallback<any, Array<any>>;
}

// -----------------------------------------------------

export interface ICustomFieldDef extends Omit<IFieldDef, 'label'>, IFieldFunctionsDef<any> {
  fieldType: FieldType.customField;
  label?: IFieldDef['label'];
  render: (api: {
    data: IFieldData<any>;
    meta: IFieldMeta;
    fieldApi: IFieldApi;
    formApi: IFormApi;
  }) => React.ReactNode;
}

export function isCustomFieldDef(def: FieldDefs): def is ICustomFieldDef {
  return def.fieldType === FieldType.customField;
}

// -----------------------------------------------------

export interface IReadonlyFieldDef
  extends Omit<IFieldDef, 'dataAddr'>,
    Omit<IFieldFunctionsDef<any>, 'readonly' | 'validate' | 'validateAsync' | 'mandatory'> {
  fieldType: FieldType.readonlyField;
  dataAddr?: DataAddr;
}

export function isReadonlyFieldDef(def: FieldDefs): def is IReadonlyFieldDef {
  return def.fieldType === FieldType.readonlyField;
}

// -----------------------------------------------------

export interface IErrorFieldDef
  extends Pick<IFieldDef, 'dataAddr'>,
    Pick<IFieldFunctionsDef<any>, 'hidden' | 'validate'> {
  fieldType: FieldType.errorField;
}

export function isErrorFieldDef(def: FieldDefs): def is IErrorFieldDef {
  return def.fieldType === FieldType.errorField;
}

// -----------------------------------------------------

export interface ITextFieldDef extends IFieldDef, IFieldFunctionsDef<string> {
  fieldType: FieldType.textField;
  maxLength?: number;
  digitsOnly?: boolean;
  placeholder?: string;
}

export function isTextFieldDef(def: FieldDefs): def is ITextFieldDef {
  return def.fieldType === FieldType.textField;
}

// -----------------------------------------------------

export interface IFileFieldDef extends IFieldDef, IFieldFunctionsDef<IFile> {
  fieldType: FieldType.fileField;
  maxLength?: number;
  digitsOnly?: boolean;
}

export function isFileFieldDef(def: FieldDefs): def is ITextFieldDef {
  return def.fieldType === FieldType.fileField;
}

export interface IMultiFileFieldDef extends IFieldDef, IFieldFunctionsDef<string> {
  fieldType: FieldType.multiFileField;
  multiple?: boolean;
  downloadOnClick: (itemId: number, name: string) => Promise<void>;
}

export function isMultiFileFieldDef(def: FieldDefs): def is ITextFieldDef {
  return def.fieldType === FieldType.multiFileField;
}

// -----------------------------------------------------

export interface ITextAreaFieldDef extends IFieldDef, IFieldFunctionsDef<string> {
  fieldType: FieldType.textAreaField;
  maxLength?: number;
  rows?: number;
  showCounter?: boolean;
}

export function isTextAreaFieldDef(def: FieldDefs): def is ITextAreaFieldDef {
  return def.fieldType === FieldType.textAreaField;
}

// -----------------------------------------------------

export interface INumericFieldDef
  extends IFieldDef,
    Omit<IFieldFunctionsDef<number>, 'formatReadonly'> {
  fieldType: FieldType.numericField;
  formatReadonly?: FieldCallback<number, React.ReactNode> | 'currency' | 'unitPrice';
  numericConfig?:
    | { numericType: 'unsignedInt'; maxValue?: number; minValue?: number; maxTotalDigits?: number }
    | { numericType: 'signedInt'; maxValue?: number; minValue?: number; maxTotalDigits?: number }
    | {
        numericType: 'unsignedDecimal';
        maxValue?: number;
        maxPointDigits?: number;
        maxTotalDigits?: number;
      }
    | {
        numericType: 'signedDecimal';
        maxValue?: number;
        maxPointDigits?: number;
        maxTotalDigits?: number;
      };
}

export function isNumericFieldDef(def: FieldDefs): def is INumericFieldDef {
  return def.fieldType === FieldType.numericField;
}

// -----------------------------------------------------

export interface IToggleButtonFieldDef
  extends IFieldDef,
    IFieldFunctionsDef<any>,
    IListFieldDefBase<any>,
    IListSyncFieldDefBase {
  fieldType: FieldType.toggleButtonField;
  isDisabled?: boolean;
}

export function isToggleButtonFieldDef(def: FieldDefs): def is IToggleButtonFieldDef {
  return def.fieldType === FieldType.toggleButtonField;
}

// -----------------------------------------------------

export interface IToggleMultiButtonFieldDef
  extends IFieldDef,
    IFieldFunctionsDef<Array<any>>,
    IListFieldDefBase<Array<any>>,
    IListSyncFieldDefBase {
  fieldType: FieldType.toggleMultiButtonField;
}

export function isToggleMultiButtonFieldDef(def: FieldDefs): def is IToggleMultiButtonFieldDef {
  return def.fieldType === FieldType.toggleMultiButtonField;
}

// -----------------------------------------------------

export interface ISelectFieldDef
  extends IFieldDef,
    IFieldFunctionsDef<any>,
    ISelectFieldDefBase<any>,
    IListSyncFieldDefBase {
  fieldType: FieldType.selectField;
}

export function isSelectFieldDef(def: FieldDefs): def is ISelectFieldDef {
  return def.fieldType === FieldType.selectField;
}

// -----------------------------------------------------

export interface ISelectCreatableFieldDef
  extends IFieldDef,
    IFieldFunctionsDef<any>,
    ISelectFieldDefBase<any>,
    IListSyncFieldDefBase {
  fieldType: FieldType.selectCreatableField;
  promptTextCreator?: (label: string) => string;
}

export function isSelectCreatableFieldDef(def: FieldDefs): def is ISelectCreatableFieldDef {
  return def.fieldType === FieldType.selectCreatableField;
}

// -----------------------------------------------------

export interface ISelectMultiFieldDef
  extends IFieldDef,
    IFieldFunctionsDef<Array<any>>,
    ISelectFieldDefBase<Array<any>>,
    IListSyncFieldDefBase {
  fieldType: FieldType.selectMultiField;
}

export function isSelectMultiFieldDef(def: FieldDefs): def is ISelectMultiFieldDef {
  return def.fieldType === FieldType.selectMultiField;
}

// -----------------------------------------------------

export interface ISelectAsyncFieldDef
  extends IFieldDef,
    IFieldFunctionsDef<any>,
    ISelectFieldDefBase<any>,
    IKeyableField<any> {
  fieldType: FieldType.selectAsyncField;
  loadOptionItems: (input: string, data: IFieldData<any>) => Promise<IAutocompleteResult<any>>;
  autoload?: boolean; // Should the load options be triggered when the control is first mounted
  loadItems?: (keys: Array<any>, data: IFieldData<any>) => Promise<IAutocompleteResult<any>>;
}

export function isSelectAsyncFieldDef(def: FieldDefs): def is ISelectAsyncFieldDef {
  return def.fieldType === FieldType.selectAsyncField;
}

// -----------------------------------------------------

export interface ISelectCreatableAsyncFieldDef
  extends IFieldDef,
    IFieldFunctionsDef<any>,
    ISelectFieldDefBase<any>,
    IKeyableField<any> {
  fieldType: FieldType.selectCreatableAsyncField;
  loadOptionItems: (input: string, data: IFieldData<any>) => Promise<IAutocompleteResult<any>>;
  autoload?: boolean; // Should the load options be triggered when the control is first mounted
  loadItems?: (keys: Array<any>, data: IFieldData<any>) => Promise<IAutocompleteResult<any>>;
  promptTextCreator?: (label: string) => string;
}

export function isSelectCreatableAsyncFieldDef(
  def: FieldDefs
): def is ISelectCreatableAsyncFieldDef {
  return def.fieldType === FieldType.selectCreatableAsyncField;
}

// -----------------------------------------------------

export interface ISelectAsyncMultiFieldDef
  extends IFieldDef,
    IFieldFunctionsDef<any>,
    ISelectFieldDefBase<any>,
    IKeyableField<any> {
  fieldType: FieldType.selectAsyncMultiField;
  loadOptionItems: (input: string, data: IFieldData<any>) => Promise<IAutocompleteResult<any>>;
  autoload?: boolean; // Should the load options be triggered when the control is first mounted
  loadItems?: (keys: Array<any>, data: IFieldData<any>) => Promise<IAutocompleteResult<any>>;
}

export function isSelectAsyncMultiFieldDef(def: FieldDefs): def is ISelectAsyncMultiFieldDef {
  return def.fieldType === FieldType.selectAsyncMultiField;
}

// -----------------------------------------------------

export interface IDateFieldDef extends IFieldDef, IFieldFunctionsDef<string> {
  fieldType: FieldType.dateField;
  timezone?: string;
}

export function isDateFieldDef(def: FieldDefs): def is IDateFieldDef {
  return def.fieldType === FieldType.dateField;
}

// -----------------------------------------------------

export interface IDateTimeFieldDef extends IFieldDef, IFieldFunctionsDef<string> {
  fieldType: FieldType.dateTimeField;
  timezone?: string;
  showSeconds?: boolean;
}

export function isDateTimeFieldDef(def: FieldDefs): def is IDateTimeFieldDef {
  return def.fieldType === FieldType.dateTimeField;
}

// -----------------------------------------------------

export interface ITimeFieldDef extends IFieldDef, IFieldFunctionsDef<string> {
  fieldType: FieldType.timeField;
}

export function isTimeFieldDef(def: FieldDefs): def is ITimeFieldDef {
  return def.fieldType === FieldType.timeField;
}

// -----------------------------------------------------

export interface IDurationFieldDef extends IFieldDef, IFieldFunctionsDef<string> {
  fieldType: FieldType.durationField;
}

export function isDurationFieldDef(def: FieldDefs): def is IDurationFieldDef {
  return def.fieldType === FieldType.durationField;
}

// -----------------------------------------------------

export interface IActionListFieldDef
  extends Omit<IFieldDef, 'label' | 'tooltip' | 'dataAddr'>,
    Pick<IFieldFunctionsDef<any>, 'getSearchText' | 'hidden'> {
  fieldType: FieldType.actionListField;
  dataAddr?: DataAddr;
  label?: string;
  actionGroups: Array<IActionGroupDef>;
  nowrap?: boolean;
}

export function isActionListFieldDef(def: FieldDefs): def is IActionListFieldDef {
  return def.fieldType === FieldType.actionListField;
}

// -----------------------------------------------------

export interface IYesNoFieldDef extends IFieldDef, IFieldFunctionsDef<boolean> {
  fieldType: FieldType.yesNoField;
}

export function isYesNoFieldDef(def: FieldDefs): def is IYesNoFieldDef {
  return def.fieldType === FieldType.yesNoField;
}

// -----------------------------------------------------

export interface IDaysOfWeekFieldDef
  extends IFieldDef,
    IFieldFunctionsDef<number[]>,
    Pick<IListFieldDefBase<number[]>, 'useValueOnly' | 'valuesToExclude' | 'valuesToDisable'> {
  fieldType: FieldType.daysOfWeekField;
}

export function isDaysOfWeekFieldDef(def: FieldDefs): def is IDaysOfWeekFieldDef {
  return def.fieldType === FieldType.daysOfWeekField;
}

// -----------------------------------------------------

export interface IStaffMemberFieldDef extends IFieldDef, IFieldFunctionsDef<any> {
  fieldType: FieldType.staffMemberField;
  staffMemberFilter?: StaffMemberFilter | FieldCallback<any, StaffMemberFilter>;
  assetId?: string | FieldCallback<any, string>;
  skillSpecRequirements?: number[] | FieldCallback<any, number[]>;
  assetLicenceClassId?: number | FieldCallback<any, number>;
}

export function isStaffMemberFieldDef(def: FieldDefs): def is IStaffMemberFieldDef {
  return def.fieldType === FieldType.staffMemberField;
}

// -----------------------------------------------------

export interface IAssetSelectFieldDef
  extends IFieldDef,
    IFieldDef,
    IFieldFunctionsDef<any>,
    ISelectFieldDefBase<any>,
    IListSyncFieldDefBase {
  fieldType: FieldType.assetSelectField;
  hideAssetInfo?: boolean;
  staffMemberId?: string | FieldCallback<any, string>;
  secondStaffMemberId?: string | FieldCallback<any, string>;
  techSpecRequirements?:
    | IShortTechSpecRequirement[]
    | FieldCallback<any, IShortTechSpecRequirement[]>;
  licenceClass?: LicenceClass;
}

export function isAssetFieldDef(def: FieldDefs): def is IAssetSelectFieldDef {
  return def.fieldType === FieldType.assetSelectField;
}

export type WeekSelectCallbackApi = {
  fieldData: IFieldData<any>;
  formValues: any;
  setFormValues: (newValues: any) => void;
};
export type WeekSelectCallback = (api: WeekSelectCallbackApi) => void;
export interface IWeekSelectFieldDef extends IFieldDef, IFieldFunctionsDef<any> {
  fieldType: FieldType.weekSelectField;
  onPrevious: WeekSelectCallback;
  onNext: WeekSelectCallback;
  onInit?: WeekSelectCallback;
}

export function isWeekSelectFieldDef(def: FieldDefs): def is IWeekSelectFieldDef {
  return def.fieldType === FieldType.weekSelectField;
}
