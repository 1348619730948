import { Component } from 'react';
import { DateTime } from 'luxon';
import './PrintRailInstructions.scss';
import PrintButton from 'src/views/components/PrintButton';
import PrintRailInstructionsContent from 'src/views/components/operations/PrintRailInstructionsContent/PrintRailInstructionsContent';
import { PrintIcon } from 'src/images/icons';
import { TIME_24_SIMPLE_HOURCYCLE_23 } from 'src/infrastructure/dateUtils';

export interface IPrintRailInstructionsProps {
  date: DateTime;
  railInstructions:
    | Operations.Domain.Queries.ListRailInstructionsForDriver.RailInstructions
    | undefined;
  loadRailInstructionsForDriver: (date: DateTime) => Promise<void>;
}

class PrintRailInstructions extends Component<IPrintRailInstructionsProps> {
  formatTime(datetime: string | undefined) {
    if (!datetime) {
      return '';
    }
    return DateTime.fromISO(datetime).toLocaleString(TIME_24_SIMPLE_HOURCYCLE_23);
  }

  doesJobEndOnDifferentDay(
    job: Operations.Domain.Queries.ListCharterInstructionsForDriver.CharterJob
  ) {
    const clockOn = DateTime.fromISO(job.clockOn);
    const clockOff = DateTime.fromISO(job.clockOff);
    return !clockOn.hasSame(clockOff, 'day');
  }

  render() {
    return (
      <PrintButton
        className="print-button"
        size="lg"
        outline
        loadDataAsync={() => this.props.loadRailInstructionsForDriver(this.props.date)}
        printContent={() => (
          <PrintRailInstructionsContent railInstructions={this.props.railInstructions} />
        )}>
        <PrintIcon />
        Print Rail Instructions
      </PrintButton>
    );
  }
}

export default PrintRailInstructions;
