import { UiRouteName } from 'src/domain/baseTypes';
import { matchPath } from 'react-router';
import { Location } from 'history';

export default class UiRoute {
  constructor(
    readonly name: UiRouteName,
    readonly pathPattern: string,
    readonly title: () => string,
    readonly showInSidebarMenu: boolean,
    readonly addDefaultFilterToLinkUrl: boolean = false,
    readonly subRoutes: Array<UiRoute> = []
  ) {}

  getSidebarMenuSubRoutes = () => {
    return this.subRoutes.filter(r => r.showInSidebarMenu);
  };

  // tslint:disable-next-line:no-any
  buildPath = (args?: any) => {
    // TODO: use proper creation from template - next version of react-router should have a lib for this!
    let pattern = this.pathPattern;
    if (!pattern.includes('?') && !pattern.includes(':')) {
      pattern += '?defaultFilter=true';
    }
    return pattern;
  };

  matchPath = (location: Location): Array<UiRoute> => {
    // TODO: Handle routes that require params properly - currently the params from the "match" object are ignored
    // we'd need a way to capture them so that when the ui builds the breadcrumb they are reused when required.
    const match = matchPath(location.pathname, { path: this.pathPattern, exact: false });
    if (match) {
      for (let o of this.subRoutes) {
        const subMatches = o.matchPath(location);
        // Take the first match - the order of subroutes is significant!!
        if (subMatches.length) {
          return [this, ...subMatches];
        }
      }
      return [this];
    }
    return [];
  };
}
