import { types, flow } from 'mobx-state-tree';
import { DateTime } from 'luxon';
import { getAjax } from 'src/domain/services/storeEnvironment';

type DriverNotes = People.Domain.Queries.ListNotesForDriver.DriverNotes;

export const NotesModel = types
  .model('NotesModel', {
    notes: types.array(types.frozen<DriverNotes>()),
  })
  .actions(self => {
    const ajax = getAjax(self);

    const loadNotesForDriver = flow(function*(date: DateTime) {
      self.notes.clear();
      self.notes = yield ajax.kiosk.notes.loadNotesForDriver(date.toISODate());
    });

    const markNotesReadForDriverOnDay = flow(function*(date: DateTime) {
      yield ajax.kiosk.notes.markNotesReadForDriverOnDay(date.toISODate());
      self.notes.clear();
    });

    return {
      loadNotesForDriver,
      markNotesReadForDriverOnDay,
    };
  });
