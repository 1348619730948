export interface ILocationFormatProps {
  value: Operations.Domain.Queries.ViewJob.JobRouteLocationItem;
}

const LocationFormat: React.FC<ILocationFormatProps> = ({ value }) => {
  if (!value) {
    return null;
  }

  const address = `${value.address || ''} ${value.city || ''} ${value.state ||
    ''} ${value.postcode || ''}`.trim();

  return (
    <div>
      <div>
        {value.name} {address.length > 0 ? `- ${address}` : null}
      </div>
      <small>{value.notes}</small>
    </div>
  );
};

export default LocationFormat;
