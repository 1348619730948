import './MarkOutOfServiceJobPopup.scss';

import { Component } from 'react';
import { IMarkOutOfServiceWorkshopJobItem } from 'src/domain/entities/operations/job/ListJobsForAllocationsModel';
import { IntervalFormat } from 'src/views/components/IntervalFormat';
import PopupField from './PopupField';

interface IMarkOutOfServiceJobPopupProps {
  markOutOfServiceJob: IMarkOutOfServiceWorkshopJobItem;
}

class MarkOutServiceJobPopup extends Component<IMarkOutOfServiceJobPopupProps> {
  render() {
    const { markOutOfServiceJob } = this.props;
    return (
      <section className={`mark-out-of-service-popup-component allocations-item-popup`}>
        <PopupField
          className="job-number"
          label="Job"
          value={markOutOfServiceJob.id}
          valueLinkTo={`/workshop/job/${markOutOfServiceJob.id}`}
        />
        <PopupField
          className="job-timing"
          label="Out of service between"
          value={
            <IntervalFormat
              startValue={markOutOfServiceJob.begin}
              endValue={markOutOfServiceJob.end}
            />
          }
        />
      </section>
    );
  }
}

export default MarkOutServiceJobPopup;
