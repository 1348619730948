import MaintainSubjects, { IMaintainSubjectsProps } from './MaintainSubjects';
import inject from 'src/views/injectFromStore';
import Omit from 'src/infrastructure/omit';

const Container = inject<Omit<IMaintainSubjectsProps, 'mode'>, IMaintainSubjectsProps>(
  (allStores, p) => ({
    canManageOpsDiary: allStores.rootStore.account.isOperationsDepartmentMember,
    loadAllOperationsDiaryEntrySubjects:
      allStores.rootStore.operations.operationsDiaryEntry.loadAllOperationsDiaryEntrySubjects,
    updateAllOperationsDiaryEntrySubjects:
      allStores.rootStore.operations.operationsDiaryEntry.updateAllOperationsDiaryEntrySubjects,
    operationsDiaryEntrySubjects: allStores.rootStore.operations.operationsDiaryEntry.operationsDiaryEntrySubjects.slice(),
  })
)(MaintainSubjects);

export default Container;
