import './WorkshopAssetIcon.scss';

import InlineSvg from 'react-inlinesvg';
import busIcon from 'src/images/workshop/bus.svg';
import busLowFloorIcon from 'src/images/workshop/bus_lowfloor.svg';
import buildingIcon from 'src/images/workshop/building.svg';
import carIcon from 'src/images/workshop/car.svg';
import forkliftIcon from 'src/images/workshop/forklift.svg';
import golfBuggyIcon from 'src/images/workshop/golf_buggy.svg';
import workshopEquipmentIcon from 'src/images/workshop/workshop_equipment.svg';
import trailerIcon from 'src/images/workshop/trailer.svg';

function getIcon(assetSubcategoryId: number, isLowFloor: boolean): string | undefined {
  // In tests the imported svg paths will be undefined
  if (isLowFloor) {
    return busLowFloorIcon;
  }
  switch (assetSubcategoryId) {
    case 1:
      return busIcon;
    case 2:
      return buildingIcon;
    case 3:
      return carIcon;
    case 4:
      return forkliftIcon;
    case 5:
      return golfBuggyIcon;
    case 6:
      return workshopEquipmentIcon;
    case 7:
      return trailerIcon;
    default:
      return busIcon;
  }
}

interface IWorkshopAssetIconProps {
  assetSubcategoryId: number;
  isLowFloor: boolean;
}

const WorkshopAssetIcon: React.FC<IWorkshopAssetIconProps> = ({
  assetSubcategoryId,
  isLowFloor,
}) => (
  <div className="workshop-asset-icon-component">
    <InlineSvg className="icon-image" src={getIcon(assetSubcategoryId, isLowFloor) || ''} />
  </div>
);

export default WorkshopAssetIcon;
