import { Component } from 'react';
import { IListPageLoadDataRequest } from 'src/domain/baseTypes';
import { ListPage } from 'src/views/components/Page';
import {
  FieldType,
  FieldDefs,
  ActionType,
  PagePrimarySize,
} from 'src/views/definitionBuilders/types';
import { DateTime } from 'luxon';
import { AssetCategory } from 'src/api/enums';
import { saveAs } from 'file-saver';
import withQueryParams, { IQueryParamsProps } from 'src/views/hocs/withQueryParams';
import { ExcelIcon } from 'src/images/icons';
import { TaskCardItem } from 'src/views/components/TaskCard';
import { precisionRound } from 'src/infrastructure/mathUtil';
import { IListPageDef } from 'src/views/components/Page/pages/ListPage';
import PrimaryTitle from 'src/views/components/Page/PrimaryTitle/PrimaryTitle';

type AssetOdometerReadingItem = Workshop.Domain.Queries.GetAssetOdometerReadings.AssetOdometerReadingItem;
type GetAssetOdometerReadingsQuery = Workshop.Domain.Queries.GetAssetOdometerReadings.GetAssetOdometerReadingsQuery;
type AssetCategoryLookup = Workshop.Domain.AggregatesModel.AssetAggregate.AssetCategory;
type AssetSubcategory = Workshop.Domain.AggregatesModel.AssetAggregate.AssetSubcategory;
type AssetHousingLocation = Common.Queries.Workshop.GetAssetHousingLocations.AssetHousingLocationItem;

export interface IOdometerReadingsProps {
  canManageOdometerReadings: boolean;
  odometerReadings: AssetOdometerReadingItem[];
  listOdometerReadings: (
    request: IListPageLoadDataRequest<GetAssetOdometerReadingsQuery>
  ) => Promise<void>;
  exportOdometerReadings: (request: Partial<GetAssetOdometerReadingsQuery>) => Promise<Blob>;
  hasMoreData: boolean;
  loadAssetHousedAtLocations: () => Promise<void>;
  loadAssetCategories: () => Promise<void>;
  loadSubcategories: () => Promise<void>;
  assetCategories: Array<AssetCategoryLookup>;
  assetSubcategories: Array<AssetSubcategory>;
  assetHousingLocations: Array<AssetHousingLocation>;
}

class OdometerReadings extends Component<
  IOdometerReadingsProps & IQueryParamsProps<GetAssetOdometerReadingsQuery>
> {
  private readonly AssetSubcategoryBus = 1;
  private readonly AssetHousingLocationOwner = 3; // System defined reference lookup

  componentDidMount() {
    this.props.loadAssetHousedAtLocations();
    this.props.loadAssetCategories();
    this.props.loadSubcategories();
  }

  private readonly saveSpreadsheet = () => {
    const fileName = `Odometer_Readings_${DateTime.local().toFormat('yyyyMMddHHmm')}.xlsx`;
    return this.props
      .exportOdometerReadings(this.props.getQueryParams())
      .then(blob => saveAs(blob, fileName));
  };

  private readonly getFilterFieldDefs = (): FieldDefs[] => {
    return [
      {
        fieldType: FieldType.dateField,
        label: 'Start Date',
        dataAddr: 'startDate',
        mandatory: true,
      },
      {
        fieldType: FieldType.dateField,
        label: 'End Date',
        dataAddr: 'endDate',
        mandatory: true,
        validate: d => {
          if (!d.fieldValue || !d.parentValue.startDate) {
            return undefined;
          }
          const start = DateTime.fromISO(d.parentValue.startDate);
          const end = DateTime.fromISO(d.fieldValue);
          return start > end ? 'End Date cannot be earlier than Start Date' : undefined;
        },
      },
      {
        fieldType: FieldType.selectMultiField,
        label: 'Category',
        dataAddr: 'categories',
        useValueOnly: true,
        valueKey: 'id',
        descriptionKey: 'description',
        optionItems: this.props.assetCategories,
      },
      {
        fieldType: FieldType.selectMultiField,
        label: 'Subcategory',
        dataAddr: 'subcategories',
        useValueOnly: true,
        valueKey: 'id',
        descriptionKey: 'description',
        optionItems: this.props.assetSubcategories,
      },
      {
        fieldType: FieldType.selectMultiField,
        label: 'Garaged At',
        dataAddr: 'housingLocations',
        useValueOnly: true,
        valueKey: 'id',
        descriptionKey: 'description',
        optionItems: this.props.assetHousingLocations,
      },
      {
        fieldType: FieldType.yesNoField,
        label: 'Is Translink Vehicle?',
        dataAddr: 'isTranslinkVehicle',
        useValueOnly: true,
      } as FieldDefs,
      {
        fieldType: FieldType.yesNoField,
        label: 'Is Decommissioned?',
        dataAddr: 'isDecommissionedVehicle',
        useValueOnly: true,
      } as FieldDefs,
    ];
  };

  private getPageDef(): IListPageDef {
    const { hasMoreData, listOdometerReadings } = this.props;
    const filterFieldDefs = this.getFilterFieldDefs();
    const today = DateTime.local().toISODate();
    const yesterday = DateTime.local()
      .minus({ days: 1 })
      .toISODate();

    const defaultHousingLocations = this.props.assetHousingLocations
      .filter(x => x.id !== this.AssetHousingLocationOwner)
      .map(x => x.id);

    return {
      primaryTitle: <PrimaryTitle title="Daily Odometer Readings"></PrimaryTitle>,
      primarySize: PagePrimarySize.full,
      onLoadData: listOdometerReadings,
      externalSearch: true,
      createLink: this.props.canManageOdometerReadings
        ? TaskCardItem.createOdometerReadings
        : undefined,
      hasMoreData,
      primaryActions: [
        {
          actions: [
            {
              actionType: ActionType.actionCollection,
              actionGroups: [
                {
                  actions: [
                    {
                      actionType: ActionType.actionButton,
                      label: 'Export to Excel',
                      icon: <ExcelIcon fixedWidth />,
                      onClick: this.saveSpreadsheet,
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
      primaryFields: [
        {
          fieldType: FieldType.textField,
          dataAddr: 'name',
          label: 'Asset',
          orderBy: 'Name',
          linkTo: d => `/workshop/assets/${d.parentValue.assetId}`,
        },
        {
          fieldType: FieldType.readonlyField,
          dataAddr: 'endOdometer',
          label: 'End Odometer',
          orderBy: 'EndOdometer',
          formatReadonly: d => precisionRound(d.fieldValue, 2),
        },
        {
          fieldType: FieldType.readonlyField,
          dataAddr: 'startOdometer',
          label: 'Start Odometer',
          orderBy: 'StartOdometer',
          formatReadonly: d => precisionRound(d.fieldValue, 2),
        },
        {
          fieldType: FieldType.readonlyField,
          dataAddr: 'kmsTravelled',
          label: 'KMs Travelled',
          orderBy: 'KmsTravelled',
          formatReadonly: d => precisionRound(d.fieldValue, 2),
        },
      ],
      filterAction: {
        defaultValues: {
          endDate: today,
          startDate: yesterday,
          categories: [AssetCategory.Fleet],
          subcategories: [this.AssetSubcategoryBus],
          housingLocations: defaultHousingLocations,
          isDecommissionedVehicle: false,
        },
        filterFields: filterFieldDefs,
      },
    };
  }

  render() {
    return (
      <ListPage
        className="list-odometer-readings-component"
        data={this.props.odometerReadings}
        def={this.getPageDef()}
      />
    );
  }
}

export default withQueryParams(OdometerReadings);
