import { types } from 'mobx-state-tree';
import { PagePrimarySize } from 'src/views/definitionBuilders/types/page';

export const DocumentationModel = types
  .model('DocumentationModel', {
    primaryPageSize: types.maybe(types.frozen<PagePrimarySize>()),
    enablePageSpinner: types.maybe(types.boolean),
  })
  .views(self => ({}))
  .actions(self => {
    const setPrimaryPageSize = (size: PagePrimarySize) => {
      self.primaryPageSize = size;
    };

    const togglePageSpinner = () => {
      self.enablePageSpinner = !!!self.enablePageSpinner;
    };

    return { setPrimaryPageSize, togglePageSpinner };
  });
