import { IAjax } from 'src/infrastructure/ajax';
import { operationsUrls } from 'src/domain/services/apiUrls';

type HourlyRateItem = Operations.Domain.Queries.ListHourlyRates.HourlyRateItem;
type UpdateHourlyRatesCommand = Operations.Domain.Commands.HourlyRate.UpdateHourlyRatesCommand;
type CreateHourlyRatesCommand = Operations.Domain.Commands.HourlyRate.CreateHourlyRatesCommand;

export default function init(ajax: IAjax) {
  function listHourlyRates() {
    return ajax
      .get(operationsUrls.hourlyRatesUrls.listHourlyRates())
      .toPromise()
      .then<HourlyRateItem[]>(r => r.response);
  }

  function updateHourlyRates(command: UpdateHourlyRatesCommand) {
    return ajax.put(operationsUrls.hourlyRatesUrls.updateHourlyRates(command), command).toPromise();
  }

  function createHourlyRates(command: CreateHourlyRatesCommand) {
    return ajax.post(operationsUrls.hourlyRatesUrls.createHourlyRates(), command).toPromise();
  }

  function deleteHourlyRates(id: string) {
    return ajax.httpDelete(operationsUrls.hourlyRatesUrls.deleteHourlyRates(id)).toPromise();
  }

  return {
    hourlyRates: {
      listHourlyRates,
      updateHourlyRates,
      createHourlyRates,
      deleteHourlyRates,
    },
  };
}
