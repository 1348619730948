import { types, getRoot, flow } from 'mobx-state-tree';
import { getAjax } from 'src/domain/services';
import { IRootStoreModel } from 'src/domain/entities/RootStoreModel';

type CreateSmsCommand = People.Domain.Commands.Sms.CreateSms.CreateSmsCommand;
type SmsItem = People.Domain.Queries.Sms.ViewSms.SmsItem;

export const SmsModel = types
  .model('SmsModel', {
    Sms: types.maybe(types.frozen<SmsItem>()),
  })
  .actions(self => {
    const ajax = getAjax(self);
    const root = getRoot(self) as IRootStoreModel;

    const createSms = flow(function*(command: CreateSmsCommand) {
      const id = yield ajax.people.Sms.createSms(command);
      root.history.push(`/people/sms/${id}`);
    });

    const loadSms = flow(function*(SmsId: string) {
      self.Sms = yield ajax.people.Sms.loadSms(SmsId);
    });

    const dismissSms = flow(function*(smsId: string, smsStaffMemberId: string) {
      yield ajax.people.Sms.dismissSms(smsId, smsStaffMemberId);
      yield loadSms(smsId);
    });

    return {
      createSms,
      loadSms,
      dismissSms,
    };
  });
