import { types, flow } from 'mobx-state-tree';
import { getAjax } from 'src/domain/services/storeEnvironment';

type FutureTaskForAsset = Workshop.Domain.Queries.GetFutureTaskForAsset.FutureTaskForAsset;
type ScheduledTaskForAsset = Workshop.Domain.Queries.GetScheduledTaskForAsset.ScheduledTaskForAsset;
type CompletedJobForAssetForKioskItem = Workshop.Domain.Queries.GetCompletedJobsForAssetForKiosk.CompletedJobForAssetForKioskItem;
type StartedTaskForAsset = Workshop.Domain.Queries.GetStartedTasksForAsset.StartedTaskForAsset;

export const AssetJobsTasksModel = types
  .model('AssetJobsTasksModel', {
    futureTasks: types.array(types.frozen<FutureTaskForAsset>()),
    scheduledTasks: types.array(types.frozen<ScheduledTaskForAsset>()),
    startedTasks: types.array(types.frozen<StartedTaskForAsset>()),
    jobs: types.array(types.frozen<CompletedJobForAssetForKioskItem>()),
  })
  .actions(self => {
    const ajax = getAjax(self);

    const loadFutureTasks = flow(function*(assetId: string) {
      self.futureTasks = yield ajax.raw
        .get(`/api/kiosk/workshop/asset/${assetId}/future-tasks`)
        .toPromise()
        .then<FutureTaskForAsset[]>(r => r.response);
    });

    const loadScheduledTasks = flow(function*(assetId: string) {
      self.scheduledTasks = yield ajax.raw
        .get(`/api/kiosk/workshop/asset/${assetId}/scheduled-tasks`)
        .toPromise()
        .then<ScheduledTaskForAsset>(r => r.response);
    });

    const loadStartedTasks = flow(function*(assetId: string) {
      self.startedTasks = yield ajax.raw
        .get(`/api/kiosk/workshop/asset/${assetId}/started-tasks`)
        .toPromise()
        .then<StartedTaskForAsset>(r => r.response);
    });

    const loadFutureAndScheduledAndStartedTasks = flow(function*(assetId: string) {
      yield loadFutureTasks(assetId);
      yield loadScheduledTasks(assetId);
      yield loadStartedTasks(assetId);
    });

    const loadCompletedJobs = flow(function*(assetId: string) {
      self.jobs = yield ajax.raw
        .get(`/api/kiosk/workshop/asset/${assetId}/completed-jobs`)
        .toPromise()
        .then<CompletedJobForAssetForKioskItem>(r => r.response);
    });

    return {
      loadFutureTasks,
      loadScheduledTasks,
      loadStartedTasks,
      loadCompletedJobs,
      loadFutureAndScheduledAndStartedTasks,
    };
  });
