export const NODE_ENV = process.env.NODE_ENV;

export const IS_PRODUCTION = (process.env.REACT_APP_IS_PRODUCTION || '').toLowerCase() === 'true';

// The application currently runs in a single timezone
export const TIMEZONE = process.env.REACT_APP_TIMEZONE || 'Australia/Brisbane';

export const KBL_RELEASE_ID = process.env.REACT_APP_KBL_RELEASE_ID || '-1';
export const KBL_RELEASE_NAME = process.env.REACT_APP_KBL_RELEASE_NAME || 'not-set';

export const INTRO_VIDEO = process.env.REACT_APP_INTRO_VIDEO || '';
export const INTRO_VIDEO_FORMAT = process.env.REACT_APP_INTRO_VIDEO_FORMAT || '';

export const isDevelopment = () => NODE_ENV === 'development';

export const COMPANY_ID = (process.env.REACT_APP_COMPANY_ID || 'KBL').toUpperCase(); // KBL or PS or CC or DEMO or WBT or WBG or DBS
export const COMPANY_NAME = process.env.REACT_APP_COMPANY_NAME || 'Kangaroo Bus Lines';

export const RENDER_SMS_NAV_ELEMENTS =
  (process.env.REACT_APP_RENDER_SMS_NAV_ELEMENTS || '').toLowerCase() === 'true';

export const ENABLE_DRAFT_PDF_WATERMARK =
  (process.env.REACT_APP_ENABLE_DRAFT_PDF_WATERMARK || '').toLowerCase() === 'true';

export const JOB_CLOCK_ON_MINS_BUFFER = Number(process.env.REACT_APP_JOB_CLOCK_ON_MINS_BUFFER || 0);

export const RENDER_DRIVER_INSTRUCTIONS_JSON_EXPORT_OPTION =
  (process.env.REACT_APP_RENDER_DRIVER_INSTRUCTIONS_JSON_EXPORT_OPTION || '').toLowerCase() ===
  'true';

export const CHANGE_DEPOT_PIN = process.env.REACT_APP_CHANGE_DEPOT_PIN || '4505';

export const APP_INSIGHTS_INSTRUMENTATION_KEY =
  process.env.REACT_APP_APP_INSIGHTS_INSTRUMENTATION_KEY || '';
export const APP_INSIGHTS_ROLE_NAME =
  process.env.REACT_APP_APP_INSIGHTS_ROLE_NAME || 'Local Development';

export const ENABLE_TIMESHEET_OVERRIDE_PAID_HOURS =
  (process.env.REACT_APP_ENABLE_TIMESHEET_OVERRIDE_PAID_HOURS || '').toLowerCase() === 'true';

export const KNOWLEDGE_BASE_LINK =
  process.env.REACT_APP_KNOWLEDGE_BASE_LINK ||
  'https://central-ops.notion.site/Knowledge-Base-4ae10e6e1a21452db4e9e2d19eb560e9';

export const ENABLE_SHOW_OVERSEAS_TRAVEL_FIELD =
  (process.env.REACT_APP_ENABLE_SHOW_OVERSEAS_TRAVEL_FIELD || '').toLowerCase() === 'true';

export const ENABLE_SHOW_CUBIC_REGISTER =
  (process.env.REACT_APP_ENABLE_SHOW_CUBIC_REGISTER || '').toLowerCase() === 'true';

export const ENABLE_SHOW_WAITING_TIME =
  (process.env.REACT_APP_ENABLE_SHOW_WAITING_TIME || '').toLowerCase() === 'true';

export const WORKING_WEEK_START_DAY = Number(
  process.env.REACT_APP_SET_WORKING_WEEK_START_DAY || '1'
);

export const ENABLE_ALCO_READINGS =
  (process.env.REACT_APP_ENABLE_ALCO_READINGS || '').toLowerCase() === 'true';

export const TIMESHEET_PERIOD = Number(process.env.REACT_APP_TIMESHEET_PERIOD || '1');

export const TIMESHEET_START_DATE = process.env.REACT_APP_TIMESHEET_START_DATE || '';

export const ENABLE_PARTS_REGISTER =
  (process.env.REACT_APP_ENABLE_PARTS_REGISTER || '').toLowerCase() === 'true';
