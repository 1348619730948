import { IRootStoreModel } from 'src/domain/entities/RootStoreModel';
import { UiRoute } from 'src/domain/services/buildUiRoutes';
import { UiRouteName } from 'src/domain/baseTypes';

export default function buildUiRoutes(store: IRootStoreModel) {
  const externalIdentityList = new UiRoute(
    UiRouteName.peopleExternalIdentityList,
    '/people/external-identities',
    () => 'External Identities',
    false,
    false,
    []
  );

  return {
    [UiRouteName.peopleExternalIdentityList]: externalIdentityList,
  };
}
