import { IAjax } from 'src/infrastructure/ajax';
import { operationsUrls } from 'src/domain/services/apiUrls';

type GenerateJobsOperationDetails = Operations.Domain.Queries.GetGenerateJobsOperation.GenerateJobsOperationDetails;

export default function init(ajax: IAjax) {
  function createGenerateJobsOperation(
    command: Operations.Domain.Commands.Job.GenerateJobs.GenerateJobsCommand
  ) {
    return ajax
      .post(operationsUrls.scheduleJobsOperationUrls.createGenerateJobsOperation(), command)
      .toPromise()
      .then<string>(r => r.response);
  }

  function listGenerateJobsOperations(
    query: Partial<
      Operations.Domain.Queries.ListGenerateJobsOperations.ListGenerateJobsOperationsQuery
    >
  ) {
    return ajax
      .get(operationsUrls.scheduleJobsOperationUrls.listGenerateJobsOperations(query))
      .map(
        r =>
          r.response as Common.Dtos.ListResult<
            Operations.Domain.Queries.ListGenerateJobsOperations.GenerateJobsOperationListItem
          >
      );
  }

  function acknowledgeFailedGenerateJobsOperation(generateJobsOperationId: string) {
    return ajax
      .put(
        operationsUrls.scheduleJobsOperationUrls.acknowledgeFailedGenerateJobsOperation(
          generateJobsOperationId
        )
      )
      .toPromise()
      .then<string>(r => r.response);
  }

  function deleteGeneratedJobs(generateJobsOperationId: string) {
    return ajax
      .httpDelete(
        operationsUrls.scheduleJobsOperationUrls.deleteGeneratedJobs(generateJobsOperationId)
      )
      .toPromise()
      .then<string>(r => r.response);
  }

  function getGenerateJobsOperationDetails(id: string) {
    return ajax
      .get(operationsUrls.scheduleJobsOperationUrls.getGenerateJobsOperationDetails(id))
      .toPromise()
      .then<GenerateJobsOperationDetails>(r => r.response);
  }

  return {
    generateJobsOperations: {
      createGenerateJobsOperation,
      listGenerateJobsOperations,
      acknowledgeFailedGenerateJobsOperation,
      deleteGeneratedJobs,
      getGenerateJobsOperationDetails,
    },
  };
}
