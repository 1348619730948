import styles from './DriverSignatureTable.module.scss';

export const DriverSignatureTable: React.FC = () => (
  <table className={styles.root}>
    <tbody>
      <tr>
        <td>Please hand in at end of day</td>
        <td className={styles.sign}>
          Signed <span className={styles.signature} />
        </td>
      </tr>
    </tbody>
  </table>
);
