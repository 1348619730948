import { IScheduledJob } from 'src/domain/entities/workshop/job/ScheduledJobsModel';

export function getJobTaskCategoryClass(
  job: IScheduledJob | Workshop.Domain.Queries.GetDailyRunSheet.DailyRunSheetJob
) {
  if (job.hasMachineryInspectionTask) {
    return 'machinery-inspection-category';
  } else if (job.hasPreMachineryInspectionTask) {
    return 'pre-machinery-inspection-category';
  } else if (job.assetIsLowFloor) {
    return 'low-floor-category';
  } else if (job.hasServiceTask) {
    return 'service-category';
  } else {
    return 'regular-category';
  }
}

export interface IScheduleLegendProps {
  full: boolean;
}
export const ScheduleLegend: React.FC<IScheduleLegendProps> = ({ full }) => {
  return (
    <div className="workshop-jobs-schedule-legend ">
      <div className="legend-item">
        <div className="legend-box machinery" /> <span className="legend-key">Machinery</span>
      </div>
      <div className="legend-item">
        <div className="legend-box pre-machinery" />{' '}
        <span className="legend-key">Pre-Machinery</span>
      </div>
      {full === true ? (
        <>
          <div className="legend-item">
            <div className="legend-box low-floor" /> <span className="legend-key">Low-Floor</span>
          </div>
          <div className="legend-item">
            <div className="legend-box service" /> <span className="legend-key">Service</span>
          </div>
          <div className="legend-item">
            <div className="legend-box out-of-service" />
            <span className="legend-key">Out of Service</span>
          </div>
        </>
      ) : null}
      <div className="legend-item">
        <div className="legend-box other" />
        <span className="legend-key">Other</span>
      </div>
    </div>
  );
};
