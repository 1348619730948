import { types } from 'mobx-state-tree';
import { ShiftModel } from './shift/ShiftModel';
import { ShiftsModel } from './shift/ShiftsModel';
import { RosterModel } from './roster/RosterModel';
import { RostersModel } from './roster/RostersModel';
import { RosterGroupModel } from './rosterGroup/RosterGroupModel';

export const UrbanModel = types.model('UrbanModel', {
  shift: types.optional(ShiftModel, {}),
  shifts: types.optional(ShiftsModel, {}),
  roster: types.optional(RosterModel, {}),
  rosters: types.optional(RostersModel, {}),
  rosterGroups: types.optional(RosterGroupModel, {}),
});

type IUrbanModelType = typeof UrbanModel.Type;
export interface IUrbanModel extends IUrbanModelType {}
