import saveAs from 'file-saver';
import { DateTime } from 'luxon';
import { observer } from 'mobx-react-lite';
import naturalCompare from 'natural-compare';
import { useEffect } from 'react';
import { BasicSearch } from 'src/domain/baseTypes';
import { useRootStore } from 'src/domain/entities/RootStoreModel';
import { ExcelIcon } from 'src/images/icons';
import { DateTimeFormat } from 'src/views/components/DateTimeFormat';
import ListPage, { IListPageDef } from 'src/views/components/Page/pages/ListPage';
import PrimaryTitle from 'src/views/components/Page/PrimaryTitle/PrimaryTitle';
import TaskCardItem from 'src/views/components/TaskCard/TaskCardItem';
import { ActionType, PagePrimarySize, PaneType } from 'src/views/definitionBuilders/types';
import { FieldDefs, FieldType } from 'src/views/definitionBuilders/types/field';
import withQueryParams, { IQueryParamsProps } from 'src/views/hocs/withQueryParams';

type OperationsDiaryEntry = Operations.Domain.Queries.ListOperationsDiaryEntries.OperationsDiaryEntryDto;
type AssetItem = Common.Queries.Workshop.GetFleetAssetList.AssetItem;

const ListOperationsDiary: React.FC<IQueryParamsProps<BasicSearch>> = observer(
  (props: IQueryParamsProps<BasicSearch>) => {
    const rootStore = useRootStore();
    const operationsModel = rootStore.operations;
    const entriesModel = operationsModel.operationsDiaryEntries;
    const entryModel = operationsModel.operationsDiaryEntry;
    const assetsModel = rootStore.assets;
    const staffModel = rootStore.people.staffMembers;

    useEffect(() => {
      assetsModel.loadFleetAssets();
      staffModel.loadAllStaffMemberNames({});
      entryModel.loadAllOperationsDiaryEntrySubjects();
    }, []);

    const saveSpreadsheet = () => {
      const fileName = `Daily_Event_Log_Entries_${DateTime.local().toFormat('yyyyMMddHHmm')}.xlsx`;
      return entriesModel
        .exportToExcel(props.getQueryParams())
        .then(blob => saveAs(blob, fileName));
    };

    const getFilterFieldDefs = {
      dateFrom: {
        fieldType: FieldType.dateField,
        dataAddr: 'dateFrom',
        label: 'Occurred From',
        onBlur: api => {
          if (!api.formValues.dateTo) {
            api.setFormValue(['dateTo'], api.fieldData.fieldValue);
          }
          api.validateField(['dateTo']);
        },
      } as FieldDefs,
      dateTo: {
        fieldType: FieldType.dateField,
        dataAddr: 'dateTo',
        label: 'Occurred To',
        validate: d => {
          if (!d.fieldValue || !d.parentValue.dateFrom) {
            return undefined;
          }
          const from = DateTime.fromISO(d.parentValue.dateFrom);
          const to = DateTime.fromISO(d.fieldValue);
          return from > to ? 'Date To cannot be earlier than Date From' : undefined;
        },
      } as FieldDefs,
      subjects: {
        fieldType: FieldType.selectMultiField,
        dataAddr: 'subjects',
        label: 'Subject',
        valueKey: 'id',
        descriptionKey: 'description',
        optionItems: entryModel.operationsDiaryEntrySubjects.slice(),
        useValueOnly: true,
      } as FieldDefs,
      vehicles: {
        fieldType: FieldType.selectMultiField,
        dataAddr: 'assets',
        label: 'Vehicle',
        valueKey: 'id',
        descriptionKey: 'name',
        optionItems: assetsModel.fleetAssetListItems.sort((a: AssetItem, b: AssetItem) => {
          return naturalCompare(a.name, b.name);
        }),
        useValueOnly: true,
      } as FieldDefs,
      drivers: {
        fieldType: FieldType.selectMultiField,
        dataAddr: 'drivers',
        label: 'Staff Member',
        valueKey: 'id',
        descriptionKey: 'name',
        optionItems: staffModel.allStaffMemberNames.slice(),
        useValueOnly: true,
      } as FieldDefs,
      driverReports: {
        fieldType: FieldType.yesNoField,
        dataAddr: 'acknowledged',
        label: 'Acknowledged',
      } as FieldDefs,
    };

    const getPageDef = (): IListPageDef => {
      const filterFieldDefsLookup = getFilterFieldDefs;
      return {
        primaryTitle: <PrimaryTitle title="Daily Event Log"></PrimaryTitle>,
        primarySize: PagePrimarySize.full,
        onLoadData: entriesModel.listItems,
        externalSearch: true,
        createLink: rootStore.account.isOperationsDepartmentMember
          ? TaskCardItem.operations.operationsDiary.createOperationsDiaryEntry
          : undefined,
        hasMoreData: entriesModel.hasMoreItems,
        primaryActions: [
          {
            actions: [
              {
                actionType: ActionType.actionCollection,
                actionGroups: [
                  {
                    actions: [
                      {
                        actionType: ActionType.actionButton,
                        label: 'Export to Excel',
                        icon: <ExcelIcon fixedWidth />,
                        onClick: () => saveSpreadsheet(),
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
        primaryFields: [
          {
            fieldType: FieldType.textField,
            dataAddr: 'diaryEntryNumber',
            label: 'No.',
            orderBy: 'entry.diaryEntryNumber',
            linkTo: d => `/operations/daily-event-log/${d.parentValue.id}`,
          },
          {
            fieldType: FieldType.dateTimeField,
            dataAddr: 'occurredOn',
            label: 'Occurred',
            orderBy: 'entry.OccurredOn',
            formatReadonly: d => (
              <DateTimeFormat value={d.fieldValue} previousValue={DateTime.local()} />
            ),
          },
          {
            fieldType: FieldType.selectField,
            label: 'Subject',
            dataAddr: 'subject',
            optionItems: [],
            valueKey: 'id',
            descriptionKey: 'description',
            orderBy: 'subject.Description',
          },
          {
            fieldType: FieldType.assetSelectField,
            label: 'Vehicle',
            dataAddr: 'asset',
            valueKey: 'id',
            descriptionKey: 'name',
            formatReadonly: d => {
              const entry = d.parentValue as OperationsDiaryEntry;
              if (entry.asset && entry.asset.id) {
                return <span>{entry.asset.name}</span>;
              }
              return null;
            },
          },
          {
            fieldType: FieldType.textField,
            dataAddr: 'route',
            label: 'Route/Service',
            orderBy: 'entry.Route',
          },
          {
            fieldType: FieldType.staffMemberField,
            dataAddr: 'driver',
            label: 'Staff Member',
          },
          {
            fieldType: FieldType.textField,
            dataAddr: 'createdByName',
            label: 'Entered By',
            orderBy: 'entry.CreatedBy',
          },
        ],
        filterAction: {
          defaultValues: {
            dateFrom: DateTime.local().toISODate(),
          },
          filterFields: Object.keys(filterFieldDefsLookup).map(k => filterFieldDefsLookup[k]),
          filterDef: _ => [
            {
              panes: [
                {
                  paneType: PaneType.formFieldsPane,
                  columnCount: 2,
                  fields: [filterFieldDefsLookup.dateFrom, filterFieldDefsLookup.dateTo],
                },
                {
                  paneType: PaneType.formFieldsPane,
                  fields: [
                    filterFieldDefsLookup.subjects,
                    filterFieldDefsLookup.vehicles,
                    filterFieldDefsLookup.drivers,
                    filterFieldDefsLookup.driverReports,
                  ],
                },
              ],
            },
          ],
        },
      };
    };

    return <ListPage data={entriesModel.items} def={getPageDef()} />;
  }
);

export default withQueryParams(ListOperationsDiary);
