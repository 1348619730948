import { types, flow } from 'mobx-state-tree';
import { getAjax } from 'src/domain/services/storeEnvironment';
import { operationsUrls } from 'src/domain/services/apiUrls';

type SalesDashboardData = Operations.Domain.Queries.GetSalesDashboard.SalesDashboardData;

export const SalesDashboardModel = types
  .model('SalesDashboardModel', {
    dashboardData: types.maybe(types.frozen<SalesDashboardData>()),
  })
  .actions(self => {
    const ajax = getAjax(self);

    const loadDashboardData = flow(function*() {
      self.dashboardData = yield ajax.raw
        .get(operationsUrls.salesDashboardUrls.loadDashboard)
        .toPromise()
        .then<SalesDashboardData>(r => r.response);
    });

    return {
      loadDashboardData,
    };
  });
