import {
  FieldDefs,
  FieldType,
  ISelectAsyncFieldDef,
  ISelectFieldDef,
  IYesNoFieldDef,
} from 'src/views/definitionBuilders/types';
import { SearchTechSpecValues } from 'src/domain/entities/workshop/asset/AssetsModel';
import {
  BoolTechSpecItem,
  DropdownTechSpecItem,
  ISplittedTechSpecs,
  TextTechSpecItem,
} from 'src/domain/entities/workshop/techSpecs/TechSpecsHelpers';

type TechSpecDropdownOption = Workshop.Domain.Queries.TechSpecs.TechSpecDropdownOptionsItem.TechSpecDropdownOption;

function getBoolTechSpecRequirementFieldDef(boolTechSpecs: BoolTechSpecItem[]): FieldDefs[] {
  return boolTechSpecs.map(
    t =>
      ({
        fieldType: FieldType.yesNoField,
        label: t.description,
        dataAddr: `spec-${t.id}`,
      } as IYesNoFieldDef)
  );
}

function getTextTechSpecRequirementFieldDef(
  textTechSpecs: TextTechSpecItem[],
  searchTechSpecValues: SearchTechSpecValues
): FieldDefs[] {
  return textTechSpecs.map(
    t =>
      ({
        fieldType: FieldType.selectAsyncField,
        label: t.description,
        dataAddr: `spec-${t.id}`,
        valueKey: 'value',
        descriptionKey: 'value',
        useValueOnly: true,
        loadOptionItems: search => searchTechSpecValues(search, t.id as number),
      } as ISelectAsyncFieldDef)
  );
}

function getDropdownTechSpecRequirementFieldDef(
  dropdownTechSpecs: DropdownTechSpecItem[],
  techSpecDropdownOptions: (techSpecId: number) => TechSpecDropdownOption[]
): FieldDefs[] {
  return dropdownTechSpecs.map(
    t =>
      ({
        fieldType: FieldType.selectField,
        label: t.description,
        dataAddr: `spec-${t.id}`,
        valueKey: 'id',
        descriptionKey: 'description',
        useValueOnly: true,
        optionItems: _ => {
          return techSpecDropdownOptions(t.id);
        },
      } as ISelectFieldDef)
  );
}

export function getTechSpecRequirementFieldDefs(
  splitTechSpecs: ISplittedTechSpecs,
  searchTechSpecValues: SearchTechSpecValues,
  techSpecDropdownOptions: (techSpecId: number) => TechSpecDropdownOption[]
): FieldDefs[] {
  const boolFieldDefs = getBoolTechSpecRequirementFieldDef(splitTechSpecs.boolTechSpecs);
  const textFieldDefs = getTextTechSpecRequirementFieldDef(
    splitTechSpecs.textTechSpecs,
    searchTechSpecValues
  );
  const dropdownFieldDefs = getDropdownTechSpecRequirementFieldDef(
    splitTechSpecs.dropdownTechSpecs,
    techSpecDropdownOptions
  );
  return [...boolFieldDefs, ...textFieldDefs, ...dropdownFieldDefs];
}
