import inject from 'src/views/injectFromKioskStore';
import Login, { ILoginProps } from './Login';

const Container = inject<ILoginProps>((allStores, p) => ({
  signIn: allStores.rootStore.account.signInKiosk,
  deviceDepot: allStores.rootStore.kiosk.workshop.defaultKioskDepot,
  hasMultipleWorkshopDepots: allStores.rootStore.kiosk.workshop.hasMultipleWorkshopDepots,
  depots: allStores.rootStore.kiosk.workshop.depots.slice(),
  addNotification: allStores.rootStore.notifications.addNotification,
  saveDeviceDepotToLocalStorage: allStores.rootStore.kiosk.workshop.saveDeviceDepotToLocalStorage,
}))(Login);

export default Container;
