import inject from 'src/views/injectFromKioskStore';
import CreateTask, { ICreateTaskProps } from './CreateTask';

const Container = inject<ICreateTaskProps>((allStores, p) => ({
  assets: allStores.rootStore.kiosk.asset.sortedAssets.slice(),
  loadAssets: allStores.rootStore.kiosk.asset.loadAssets,
  futureTasksForAsset: allStores.rootStore.kiosk.asset.jobsTasks.futureTasks.slice(),
  scheduledTasksForAsset: allStores.rootStore.kiosk.asset.jobsTasks.scheduledTasks.slice(),
  startedTasksForAsset: allStores.rootStore.kiosk.asset.jobsTasks.startedTasks.slice(),
  loadFutureAndScheduledAndStartedTasks:
    allStores.rootStore.kiosk.asset.jobsTasks.loadFutureAndScheduledAndStartedTasks,
  createTask: allStores.rootStore.kiosk.workshop.task.createTask,
}))(CreateTask);

export default Container;
