import { DateTime } from 'luxon';
import { ButtonGroup } from 'reactstrap';
import { ChevronLeftIcon, ChevronRightIcon } from 'src/images/icons';
import { SingleClickAwareButton } from '../SingleClickAwareButton/SingleClickAwareButton';

export interface IWeekNavigationProps {
  onChangeForward: () => void;
  onChangeBack: () => void;
  onChangeToday: () => void;
  date: DateTime;
  disabled?: boolean;
}

const WeekNavigation: React.FC<IWeekNavigationProps> = (props: IWeekNavigationProps) => {
  return (
    <div>
      <ButtonGroup>
        <SingleClickAwareButton
          useReactStrapButton
          onClick={() => props.onChangeBack()}
          outline
          size={'lg'}>
          <ChevronLeftIcon size={'lg'} />
        </SingleClickAwareButton>
        <SingleClickAwareButton
          useReactStrapButton
          className="this-week"
          onClick={() => props.onChangeToday()}
          outline
          size={'lg'}
          disabled={props.disabled}>
          View Current Timesheet
        </SingleClickAwareButton>
        <SingleClickAwareButton
          useReactStrapButton
          onClick={() => props.onChangeForward()}
          outline
          size={'lg'}>
          <ChevronRightIcon size={'lg'} />
        </SingleClickAwareButton>
      </ButtonGroup>
    </div>
  );
};

export default WeekNavigation;
