import { types, flow } from 'mobx-state-tree';
import { getAjax } from 'src/domain/services';

type ShiftListItem = Operations.Domain.Queries.ListShifts.ShiftListItem;

export interface IShiftListItem extends ShiftListItem {
  searchText: string;
}

export const ShiftsModel = types
  .model('ShiftsModel', {
    shifts: types.array(types.frozen<IShiftListItem>()),
  })
  .actions(self => {
    const ajax = getAjax(self);

    const listShifts = flow(function*(
      query?: Operations.Domain.Queries.ListShifts.ListShiftsQuery
    ) {
      const shifts = yield ajax.urban.shifts.listShifts(query);
      self.shifts = shifts.map((p: ShiftListItem) => ({
        ...p,
        searchText: `${p.shiftName} - ${p.shiftType.description} - ${p.description}`,
      }));
    });

    const getShifts = flow(function*(query?: Operations.Domain.Queries.ListShifts.ListShiftsQuery) {
      return yield ajax.urban.shifts.listShifts(query);
    });

    const getShiftsByContract = flow(function*(id: string) {
      return yield ajax.urban.shifts.getShiftsByContracts(id);
    });

    return {
      listShifts,
      getShiftsByContract,
      getShifts,
    };
  });
