import styles from './Home.module.scss';

import { AssetCategory } from 'src/api/enums';
import { AssetSubcategoryType } from 'src/domain';
import { ToolsIcon, BusIcon, CarIcon } from 'src/images/icons';
import cn from 'classnames';

export const AssetName: React.FC<{
  categoryId: number;
  subCategoryId: number;
  assetName: string;
  outOfService: boolean;
  isLowFloor: boolean;
}> = ({ categoryId, subCategoryId, assetName, outOfService, isLowFloor }) => {
  return (
    <div
      className={cn(
        styles.busName,
        outOfService ? styles.outOfService : null,
        isLowFloor ? styles.lowFloor : null
      )}>
      {categoryId === AssetCategory.Plant ? (
        <ToolsIcon />
      ) : subCategoryId === AssetSubcategoryType.Bus ? (
        <BusIcon />
      ) : subCategoryId === AssetSubcategoryType.Car ? (
        <CarIcon />
      ) : null}
      <span>
        <b>{assetName}</b>
      </span>
    </div>
  );
};
