import { DateTime, Interval } from 'luxon';
import { types, flow } from 'mobx-state-tree';
import { Observable } from 'rxjs/Observable';
import { Subject } from 'rxjs/Subject';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import deepEqual from 'src/infrastructure/deepEqual';
import { isDefined } from 'src/infrastructure/typeUtils';
import { TIMEZONE } from 'src/appSettings';
import { NotificationType } from 'src/domain/services';
import { getAjax, getBus } from 'src/domain/services/storeEnvironment';
import { WarningType } from 'src/api/enums';
import { getWeekStart } from 'src/domain/dateHelper';

type JobItem = Operations.Domain.Queries.ListJobsForAllocations.JobItem;
type ListJobsForAllocationsQuery = Operations.Domain.Queries.ListJobsForAllocations.ListJobsForAllocationsQuery;
type AllocateStaffMemberCommand = Operations.Domain.Commands.Job.AllocateStaffMember.AllocateStaffMemberCommand;
type AllocateStaffMemberResult = Operations.Domain.Commands.Job.AllocateStaffMember.AllocateStaffMemberResult;
type AllocateAssetCommand = Operations.Domain.Commands.Job.AllocateAsset.AllocateAssetCommand;
type JobWarning = Operations.Domain.Queries.GetJobWarnings.JobWarning;
type WorkshopJobItem = Operations.Domain.Queries.GetExternalItemsForAllocations.ExternalItems.WorkshopJobItem;
type PeopleLeaveItem = Operations.Domain.Queries.GetExternalItemsForAllocations.ExternalItems.PeopleLeaveItem;
type MarkOutOfServiceWorkshopJobItem = Operations.Domain.Queries.GetExternalItemsForAllocations.ExternalItems.MarkOutOfServiceWorkshopJobItem;
type WorkshopShiftItem = Operations.Domain.Queries.GetExternalItemsForAllocations.ExternalItems.WorkshopShiftItem;
type PaidHoursInPayPeriod = Operations.Domain.Queries.GetPaidHoursInPayPeriodForDay.PaidHoursInPayPeriod;
type AllocateSubcontractorCommand = Operations.Domain.Commands.Job.AllocateSubcontractor.AllocateSubcontractorCommand;
type OperationsJobConflictsUpdatedEvent = Operations.Domain.Events.ConflictsUpdated.OperationsJobConflictsUpdatedEvent;

export interface IAllocationsBoundedItem {
  isOperationsJob?: boolean;
  isWorkshopJob?: boolean;
  isPeopleLeave?: boolean;
  isMarkOutOfServiceItem?: boolean;
  isWorkshopShift?: boolean;
  id: string;
  bounds: Interval;
}

export interface IJobItem extends JobItem, IAllocationsBoundedItem {
  isOperationsJob: boolean;
  bounds: Interval;
  depotBounds: Interval;
  shiftBounds: Interval;
}

export interface IWorkshopJobItem extends WorkshopJobItem, IAllocationsBoundedItem {
  isWorkshopJob: boolean;
  bounds: Interval;
}

export interface IMarkOutOfServiceWorkshopJobItem
  extends MarkOutOfServiceWorkshopJobItem,
    IAllocationsBoundedItem {
  isMarkOutOfServiceItem: boolean;
  bounds: Interval;
}

export interface IPeopleLeaveItem extends PeopleLeaveItem, IAllocationsBoundedItem {
  isPeopleLeave: boolean;
  bounds: Interval;
}

export interface IWorkshopShiftItem extends WorkshopShiftItem, IAllocationsBoundedItem {
  isWorkshopShift: boolean;
  bounds: Interval;
}

export type AllBoundedItemTypes =
  | IJobItem
  | IWorkshopJobItem
  | IPeopleLeaveItem
  | IMarkOutOfServiceWorkshopJobItem
  | IWorkshopShiftItem;

export function isOperationsJob(item: IAllocationsBoundedItem): item is IJobItem {
  return !!item.isOperationsJob;
}

export function isWorkshopJob(item: IAllocationsBoundedItem): item is IWorkshopJobItem {
  return !!item.isWorkshopJob;
}

export function isMarkOutOfServiceItem(
  item: IAllocationsBoundedItem
): item is IMarkOutOfServiceWorkshopJobItem {
  return !!item.isMarkOutOfServiceItem;
}

export function isPeopleLeave(item: IAllocationsBoundedItem): item is IPeopleLeaveItem {
  return !!item.isPeopleLeave;
}

export function isWorkshopShift(item: IAllocationsBoundedItem): item is IWorkshopShiftItem {
  return !!item.isWorkshopShift;
}

interface ILoadedJobs {
  start: DateTime;
  jobs: IJobItem[];
  warnings: JobWarning[];
  workshopItems: IWorkshopJobItem[];
  leaveItems: IPeopleLeaveItem[];
  markOutOfServiceItems: IMarkOutOfServiceWorkshopJobItem[];
  workshopShiftItems: IWorkshopShiftItem[];
}

function flattenMapValues<T>(mapValues: IterableIterator<T[]>, getId: (item: T) => string) {
  // Flatten into a map first to remove any duplicates (by id)
  const flattenedJobs = Array.from(mapValues).reduce(
    (map, jobs) => jobs.reduce((_, j) => map.set(getId(j), j), map),
    new Map<string, T>()
  );
  return Array.from(flattenedJobs.values());
}

export const ListJobsForAllocationsModel = types
  .model('ListJobsForAllocationsModel', {
    jobsByDay: types.map(types.array(types.frozen<IJobItem>())),
    warningsByDay: types.map(types.array(types.frozen<JobWarning>())),
    workshopItemsByDay: types.map(types.array(types.frozen<IWorkshopJobItem>())),
    markOutOfServiceJobItemsByDay: types.map(
      types.array(types.frozen<IMarkOutOfServiceWorkshopJobItem>())
    ),
    leaveItemsByDay: types.map(types.array(types.frozen<IPeopleLeaveItem>())),
    paidHoursByPayPeriod: types.map(types.array(types.frozen<PaidHoursInPayPeriod>())),
    unfilteredItems: types.array(types.frozen<AllBoundedItemTypes>()),
    workshopShiftItemsByDay: types.map(types.array(types.frozen<IWorkshopShiftItem>())),
    isLoadingJobsForRange: types.optional(types.boolean, false),
  })
  .volatile(() => ({
    isLoadingIds: [] as symbol[],
  }))
  .views(self => ({
    get jobs() {
      // Flatten the jobsByDay as jobs may appear in multiple days (when the cross the day boundary)
      return flattenMapValues(self.jobsByDay.values(), i => i.id);
    },
    get workshopItems() {
      return flattenMapValues(self.workshopItemsByDay.values(), i => i.id);
    },
    get leaveItems() {
      return flattenMapValues(self.leaveItemsByDay.values(), i => i.id);
    },
    get MarkOutOfServiceJobItems() {
      return flattenMapValues(self.markOutOfServiceJobItemsByDay.values(), i => i.id);
    },
    get isDataLoading() {
      return self.isLoadingJobsForRange || !!self.isLoadingIds.length;
    },
    get workshopShiftItems() {
      return flattenMapValues(self.workshopShiftItemsByDay.values(), i => i.id);
    },
  }))
  .actions(self => {
    const _addLoadId = (loadId: symbol) => {
      self.isLoadingIds.push(loadId);
      self.isLoadingJobsForRange = !!self.isLoadingIds.length;
    };

    const _clearLoadId = (loadId: symbol) => {
      self.isLoadingIds = self.isLoadingIds.filter(id => id !== loadId);
      self.isLoadingJobsForRange = !!self.isLoadingIds.length;
    };

    return { _addLoadId, _clearLoadId };
  })
  .actions(self => {
    function _clearJobs() {
      self.jobsByDay.clear();
      self.workshopItemsByDay.clear();
      self.leaveItemsByDay.clear();
      self.markOutOfServiceJobItemsByDay.clear();
      self.workshopShiftItemsByDay.clear();
    }

    function _setJobsForDay({
      start,
      jobs,
      warnings,
      workshopItems,
      leaveItems,
      markOutOfServiceItems,
      workshopShiftItems,
    }: ILoadedJobs) {
      const day = start.toISODate();
      self.jobsByDay.set(day, jobs);
      self.warningsByDay.set(day, warnings);
      self.workshopItemsByDay.set(day, workshopItems);
      self.leaveItemsByDay.set(day, leaveItems);
      self.markOutOfServiceJobItemsByDay.set(day, markOutOfServiceItems);
      self.workshopShiftItemsByDay.set(day, workshopShiftItems);
    }

    function _setPaidHoursForPayPeriod(startOfWeekKey: string, paidHours: PaidHoursInPayPeriod[]) {
      self.paidHoursByPayPeriod.set(startOfWeekKey, paidHours);
    }

    return { _clearJobs, _setJobsForDay, _setPaidHoursForPayPeriod: _setPaidHoursForPayPeriod };
  })
  .actions(self => {
    const ajax = getAjax(self);
    const bus = getBus(self);
    const paidHoursSyncTimes = new Map<string, DateTime>();

    const querySubject = new BehaviorSubject<Partial<ListJobsForAllocationsQuery>>({});
    const clearAllSubject = new Subject<void>();
    const loadJobsSubject = new Subject<DateTime>();
    const resetCacheSubject = new Subject<void>();
    const loadJobsWeekCacheTimeMs = 30000;
    const subscription = Observable.merge(querySubject, clearAllSubject)
      .distinctUntilChanged<Partial<ListJobsForAllocationsQuery> | void>(deepEqual)
      .do(self._clearJobs)
      .filter(isDefined)
      // Use switchMap to cancel/ignore any in-progress requests and to clear the caches when the
      // filter changes, as they're no longer relevant
      .switchMap(query =>
        loadJobsSubject
          // Let the jobs for different weeks be retrieved at the same time
          .groupBy(
            d => d.toISODate(),
            d => d
          )
          // Only update data for the same date & filter when cache time has passed
          .flatMap(grp$ =>
            grp$.throttle(d =>
              Observable.merge(resetCacheSubject, Observable.interval(loadJobsWeekCacheTimeMs))
            )
          )
          .flatMap(day => loadItemsForRange(day.startOf('day'), day.endOf('day'), query))
      )
      .subscribe(self._setJobsForDay);

    function loadItemsForRange(
      start: DateTime,
      end: DateTime,
      filter: Partial<ListJobsForAllocationsQuery>
    ): Observable<ILoadedJobs> {
      const fromTo = {
        jobsFrom: start.toUTC().toISO(),
        jobsTo: end.toUTC().toISO(),
      };

      const loadId = Symbol();
      self._addLoadId(loadId);

      return ajax.operations.jobs
        .listJobsForAllocations({
          ...filter,
          ...fromTo,
        })
        .zip(
          ajax.operations.jobs.getWarnings(fromTo),
          ajax.operations.jobs.getExternalItemsForAllocations({
            from: fromTo.jobsFrom,
            to: fromTo.jobsTo,
          })
        )
        .map(([jobs, warnings, externalItems]) => {
          const item: ILoadedJobs = {
            start,
            jobs: jobs.map(job => ({
              ...job,
              isOperationsJob: true,
              bounds: Interval.fromDateTimes(
                DateTime.fromISO(job.jobBegin),
                DateTime.fromISO(job.jobEnd)
              ),
              depotBounds: Interval.fromDateTimes(
                DateTime.fromISO(
                  job.vehicleSwapJobDetails?.departDepot &&
                    job.vehicleSwapJobDetails?.departDepot < job.departDepot
                    ? job.vehicleSwapJobDetails?.departDepot ?? job.departDepot
                    : job.departDepot
                ),
                DateTime.fromISO(
                  job.vehicleSwapJobDetails?.start &&
                    job.vehicleSwapJobDetails.start > job.arriveDepot
                    ? job.arriveDepot
                    : job.vehicleSwapJobDetails?.arriveDepot ?? job.arriveDepot
                )
              ),
              shiftBounds: job.isVehicleSwapped
                ? Interval.fromDateTimes(
                    DateTime.fromISO(
                      job.vehicleSwapJobDetails?.start &&
                        job.vehicleSwapJobDetails.start > job.shiftBegin &&
                        job.vehicleSwapJobDetails.start < job.shiftEnd
                        ? job.vehicleSwapJobDetails?.start
                        : job.shiftBegin
                    ),
                    DateTime.fromISO(job.shiftEnd)
                  )
                : Interval.fromDateTimes(
                    DateTime.fromISO(job.shiftBegin),
                    DateTime.fromISO(job.shiftEnd)
                  ),
            })),
            warnings,
            workshopItems: externalItems.workshopJobItems.map(w => ({
              ...w,
              isWorkshopJob: true,
              bounds: Interval.fromDateTimes(DateTime.fromISO(w.begin), DateTime.fromISO(w.end)),
            })),
            leaveItems: externalItems.peopleLeaveItems.map(l => ({
              ...l,
              isPeopleLeave: true,
              bounds: Interval.fromDateTimes(DateTime.fromISO(l.begin), DateTime.fromISO(l.end)),
            })),
            markOutOfServiceItems: externalItems.markOutOfServiceWorkshopJobItems.map(d => ({
              ...d,
              isMarkOutOfServiceItem: true,
              bounds: Interval.fromDateTimes(DateTime.fromISO(d.begin), DateTime.fromISO(d.end)),
            })),
            workshopShiftItems: externalItems.workshopShiftItems.map(w => ({
              ...w,
              isWorkshopShift: true,
              bounds: Interval.fromDateTimes(DateTime.fromISO(w.begin), DateTime.fromISO(w.end)),
            })),
          };
          const invalidJobs = item.jobs.filter(
            j => !j.bounds.isValid || !j.depotBounds.isValid || !j.shiftBounds.isValid
          );

          if (invalidJobs.length) {
            throw new Error(
              `${invalidJobs.length} jobs for ${start.toISODate()} have invalid times`
            );
          }

          return item;
        })
        .catch(e => {
          bus.showNotification({
            message: `The retrieval of Jobs for ${start.toLocaleString(DateTime.DATE_MED)} failed`,
            options: { type: NotificationType.error },
          });

          return Observable.empty<ILoadedJobs>();
        })
        .finally(() => {
          self._clearLoadId(loadId);
        });
    }

    const clearItems = () => {
      clearAllSubject.next();
    };

    const loadItems = (
      day: DateTime,
      bufferDays: number,
      filter: Partial<ListJobsForAllocationsQuery> | undefined,
      clearLoadedJobs: boolean
    ) => {
      clearLoadedJobs && clearItems();
      filter && querySubject.next(filter);

      // Ask for the main day's jobs first, as we want to display these first
      const start = day.setZone(TIMEZONE);
      loadJobsSubject.next(start);

      // Retrieve buffer days data moving outwards from the main day
      new Array(bufferDays).fill(null).forEach((_, i) => {
        const days = i + 1;
        const after = start.plus({ days });
        loadJobsSubject.next(after);
        const before = start.minus({ days });
        loadJobsSubject.next(before);
      });

      loadPaidHoursInPayPeriodForDay(day);
    };

    const loadUnfilteredItems = flow(function*(start: DateTime, end: DateTime) {
      self.unfilteredItems = yield loadItemsForRange(start, end, {})
        .map(result => [
          ...result.jobs,
          ...result.workshopItems,
          ...result.leaveItems,
          ...result.markOutOfServiceItems,
          ...result.workshopShiftItems,
        ])
        .toPromise();
    });

    function refreshJobs(predicate: (job: IJobItem) => boolean) {
      resetPaidHoursCache();
      Array.from(self.jobsByDay.keys())
        .filter(k => {
          const jobs = self.jobsByDay.get(k);
          return jobs && jobs.some(predicate);
        })
        .map(k => DateTime.fromISO(k, { zone: TIMEZONE }))
        .forEach(day => {
          resetCacheSubject.next();
          loadItems(day, 0, undefined, false);
        });
    }

    const allocateStaffMember = flow(function*(command: AllocateStaffMemberCommand) {
      var allocateResult: AllocateStaffMemberResult = yield ajax.operations.jobs.allocateStaffMember(
        command
      );
      refreshJobs(j => j.id === command.jobId);

      const defaultWarnings = allocateResult.warnings?.filter(
        warning => warning.type === WarningType.Default
      );

      if (defaultWarnings) {
        bus.showWarnings(defaultWarnings);
      }

      return allocateResult.warnings?.filter(warning => warning.type !== WarningType.Default) ?? [];
    });

    const allocateAsset = flow(function*(command: AllocateAssetCommand) {
      yield ajax.operations.jobs.allocateAsset(command);
      refreshJobs(j => j.id === command.jobId);
    });

    const allocateSubcontractor = flow(function*(command: AllocateSubcontractorCommand) {
      yield ajax.raw
        .post(`/api/operations/jobs/${command.jobId}/allocate-subcontractor`, command)
        .toPromise();
      refreshJobs(j => j.id === command.jobId);
    });

    Observable.merge(bus.conflictAccepted$, bus.conflictAcceptanceCancelled$).subscribe(e => {
      refreshJobs(j => (j.conflicts.opsConflicts || []).some(c => c.conflictId === e.conflictId));
    });
    const domainEventSubscription = bus.operationsDomainEvent$
      .filter(e => e.eventName === 'OperationsJobConflictsUpdatedEvent')
      .subscribe(e => {
        refreshJobs(j =>
          (e.payload as OperationsJobConflictsUpdatedEvent).jobIds.some(c => c === j.id)
        );
      });

    const beforeDestroy = () => {
      subscription.unsubscribe();
      domainEventSubscription.unsubscribe();
    };

    async function loadPaidHoursInPayPeriodForDay(date: DateTime) {
      const startOfWeek = getWeekStart(date);
      const startOfWeekKey = startOfWeek.toISODate();
      const now = DateTime.local();
      let paidHours: PaidHoursInPayPeriod[] | undefined;
      const lastSyncTime = paidHoursSyncTimes.get(startOfWeekKey);
      if (lastSyncTime) {
        if (Math.abs(lastSyncTime.diff(now).milliseconds) < loadJobsWeekCacheTimeMs) {
          const cachedResult = self.paidHoursByPayPeriod.get(startOfWeekKey);
          if (cachedResult) {
            paidHours = cachedResult;
          }
        }
      }
      if (!paidHours) {
        paidHours = await ajax.compliance.fatigue
          .getPaidHoursInPayPeriodForDay(startOfWeek)
          .toPromise();
        paidHoursSyncTimes.set(startOfWeekKey, now);
      }

      self._setPaidHoursForPayPeriod(startOfWeekKey, paidHours);
    }

    function resetPaidHoursCache() {
      paidHoursSyncTimes.clear();
    }

    return {
      beforeDestroy,
      loadItems,
      loadUnfilteredItems,
      clearItems,
      allocateStaffMember,
      allocateAsset,
      allocateSubcontractor,
    };
  });
