import { TrashIcon, PlusIcon } from 'src/images/icons';
import {
  PaneType,
  FieldType,
  ActionType,
  ShellModalSize,
  ISectionDef,
} from 'src/views/definitionBuilders/types';
import MaintainRole from './MaintainRole';
import getAddStaffMemberModelDef from './getAddStaffMemberModelDef';
import { getSubmitCloseModalActionGroupDef } from 'src/views/definitionBuilders/common';

type StaffMemberName = Common.Queries.GetStaffMemberNames.StaffMemberName;

export default function getStaffMembersSectionDef(
  this: InstanceType<typeof MaintainRole>
): ISectionDef {
  return {
    title: 'Staff Members',
    panels: [
      {
        panes: [
          {
            paneType: PaneType.tablePane,
            dataAddr: 'staffMembers',
            neverEditable: true,
            fields: [
              {
                fieldType: FieldType.textField,
                dataAddr: 'name',
                label: 'Staff Member',
                linkTo: d => `/people/staff-members/${d.parentValue.id}`,
              },
              {
                fieldType: FieldType.actionListField,
                columnWidth: '1px',
                actionGroups: [
                  {
                    actions: [
                      {
                        actionType: ActionType.modalActionButton,
                        icon: <TrashIcon />,
                        label: 'Remove Staff Member',
                        modalSize: ShellModalSize.oneQuarter,
                        modalDef: modalDefApi => ({
                          title: 'Remove Staff Member',
                          asForm: true,
                          panels: [
                            {
                              panes: [
                                {
                                  paneType: PaneType.customPane,
                                  render: api => (
                                    <div>
                                      <p>Are you sure you want to remove:</p>
                                      <p>{api.data.parentValue.name}</p>
                                    </div>
                                  ),
                                },
                              ],
                            },
                          ],
                          secondaryActions: [getSubmitCloseModalActionGroupDef('Ok')],
                          onFormSubmit: async () => {
                            const staffMember = modalDefApi.actionData
                              .parentValue as StaffMemberName;

                            await this.props.removeStaffMemberFromRole({
                              staffMemberId: staffMember.id,
                              roleId: this.props.role!.id,
                            });
                            await this.props.listStaffMembersForRole(this.props.role!.id);
                          },
                        }),
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
    secondaryActions: [
      {
        actions: [
          {
            actionType: ActionType.modalActionButton,
            label: 'Add Staff Member',
            icon: <PlusIcon />,
            level: 'primary',
            modalSize: ShellModalSize.half,
            modalDef: getAddStaffMemberModelDef.call(this),
            onOpenModal: _ => this.props.loadAllStaffMembers(),
          },
        ],
      },
    ],
  };
}
