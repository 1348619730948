import './Separator.scss';

export interface ISeparatorProps {
  label: string;
  className?: string;
}

const Separator: React.FC<ISeparatorProps> = ({ label, className }) => {
  if (!label) {
    return null;
  }

  return (
    <div className={`${className} separator`}>
      <span>{label}</span>
    </div>
  );
};

export default Separator;
