import Select from 'react-select';
import { ICustomAction } from 'src/views/components/ActionBar/ActionBar';
import './WorkshopDepotSelect.scss';

type WorkshopDepot = Common.Queries.Workshop.GetWorkshopDepots.WorkshopDepotDto;

export interface IWorkshopDepotSelectProps {
  workshopDepots: Array<WorkshopDepot>;
  selectedWorkshopDepotId: number;
  onChange: (newValue: any) => void;
}

const WorkshopDepotSelect: React.FC<IWorkshopDepotSelectProps> = ({
  workshopDepots,
  selectedWorkshopDepotId,
  onChange,
}) => (
  <div id="depot-dropdown">
    <Select
      disabled={workshopDepots.length < 2}
      className="depot-dropdown"
      options={workshopDepots}
      value={selectedWorkshopDepotId}
      valueKey="id"
      labelKey="description"
      onChange={onChange}
      clearable={false}
    />
  </div>
);

export const getWorkshopDepotSelectAction = (
  workshopDepots: Array<WorkshopDepot>,
  selectedWorkshopDepotId: number,
  onChange: (newValue: any) => void
): ICustomAction => ({
  content: (
    <WorkshopDepotSelect
      workshopDepots={workshopDepots}
      selectedWorkshopDepotId={selectedWorkshopDepotId}
      onChange={onChange}
    />
  ),
});

export default WorkshopDepotSelect;
