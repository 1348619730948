import styles from './CreateTask.module.scss';
import './CreateTask.scss';

import SplitScreen from '../components/layout/SplitScreen/SplitScreen';
import WithMenu from '../menu/WithMenu';
import SelectField from '../components/form/SelectField';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { AssetScheduledAndFutureTasksDisplay } from '../AssetDetails/Tabs/AssetScheduledAndFutureTasksDisplay';
import TextAreaField from '../components/form/TextAreaField';
import cn from 'classnames';
import Button from 'reactstrap/lib/Button';
import Spinner from 'src/views/components/Spinner';
import { useEffect, useState } from 'react';

type AssetItem = Workshop.Domain.Queries.AssetItem;
type FutureTaskForAsset = Workshop.Domain.Queries.GetFutureTaskForAsset.FutureTaskForAsset;
type ScheduledTaskForAsset = Workshop.Domain.Queries.GetScheduledTaskForAsset.ScheduledTaskForAsset;
type StartedTaskForAsset = Workshop.Domain.Queries.GetStartedTasksForAsset.StartedTaskForAsset;
type CreateTaskCommand = Workshop.Domain.Commands.JobTask.KioskCreateAdhocJobTaskCommand;

export interface ICreateTaskProps {
  assets: AssetItem[];
  loadAssets: () => Promise<void>;
  futureTasksForAsset?: FutureTaskForAsset[];
  scheduledTasksForAsset?: ScheduledTaskForAsset[];
  startedTasksForAsset?: StartedTaskForAsset[];
  loadFutureAndScheduledAndStartedTasks: (assetId: string) => Promise<void>;
  createTask: (command: CreateTaskCommand) => Promise<void>;
}

const CreateTask: React.FC<ICreateTaskProps> = ({
  loadAssets,
  assets,
  futureTasksForAsset,
  scheduledTasksForAsset,
  startedTasksForAsset,
  loadFutureAndScheduledAndStartedTasks,
  createTask,
}) => {
  const [asset, setAsset] = useState<{ id: string; name: string }>();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    loadAssets();
  }, []);

  const left = (
    <div className={styles.relatedTasks}>
      <WithMenu>
        {asset && (
          <>
            <h1>KBL{asset.name}</h1>
            <h2>Future/Scheduled Tasks</h2>
            <AssetScheduledAndFutureTasksDisplay
              assetId={asset.id}
              futureTasksForAsset={futureTasksForAsset}
              scheduledTasksForAsset={scheduledTasksForAsset}
              startedTasksForAsset={startedTasksForAsset}
              loadFutureAndScheduledAndStartedTasks={loadFutureAndScheduledAndStartedTasks}
            />
          </>
        )}
      </WithMenu>
    </div>
  );

  const getValidationSchema = () => {
    const shape = {
      asset: Yup.string().required(),
      description: Yup.string()
        .trim()
        .required(),
    };
    return Yup.object().shape(shape);
  };

  const initialValues: { asset: string | undefined; description: string } = {
    asset: undefined,
    description: '',
  };

  const right = (
    <div className={cn(styles.createTasks, 'wsktask')}>
      <h1>Create New Task</h1>
      {isLoading ? (
        <div className={styles.spinnerContainer}>
          <Spinner show className={styles.loadingSpinner} />
        </div>
      ) : (
        <Formik
          enableReinitialize
          validateOnChange
          initialValues={initialValues}
          validationSchema={getValidationSchema()}
          validateOnBlur
          onSubmit={values => {
            setIsLoading(true);
            createTask({ assetId: values.asset!, description: values.description }).then(() =>
              setIsLoading(false)
            );
          }}>
          {({ values, isSubmitting, errors, submitCount }) => {
            return (
              <Form>
                <div className={styles.formContainer}>
                  <SelectField
                    dataLabel={'Select Asset'}
                    options={assets}
                    dataAddress={'asset'}
                    onChangeHook={v =>
                      v ? setAsset({ id: v!.id as string, name: v!.name }) : undefined
                    }
                  />
                  <TextAreaField
                    dataValue={values.description}
                    dataLabel={'Task Description'}
                    dataAddress={'description'}
                  />
                  <div className={cn(styles.buttonContainer, styles.submitButtonContainer)}>
                    {isSubmitting ? (
                      <Spinner show className={styles.loadingSpinner} />
                    ) : (
                      <Button
                        type="submit"
                        className={cn(
                          styles.submitButton,
                          Object.keys(errors).length && submitCount > 0 ? styles.error : ''
                        )}>
                        {Object.keys(errors).length && submitCount > 0
                          ? 'The form contains errors'
                          : 'Create Task'}
                      </Button>
                    )}
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>
      )}
    </div>
  );

  return (
    <div className={styles.createTaskComponent}>
      <SplitScreen left={left} right={right} />
    </div>
  );
};

export default CreateTask;
