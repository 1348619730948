import { types, flow } from 'mobx-state-tree';
import { getAjax } from 'src/domain/services';
import { ChecklistModel } from './ChecklistModel';
import { SubchecklistsModel } from './SubchecklistsModel';
import { SubchecklistItemsModel } from './SubchecklistItemsModel';
import { ChecklistAssetGroupsModel } from './ChecklistAssetGroupsModel';

type ChecklistDetails = Workshop.Domain.Queries.GetChecklist.ChecklistDetails;
type UniqueNameCheckResultDto = Common.Dtos.UniqueNameCheckResultDto;

export const ChecklistsModel = types
  .model('ChecklistsModel', {
    checklist: types.optional(ChecklistModel, {}),
    checklistAssetGroups: types.optional(ChecklistAssetGroupsModel, {}),
    subchecklists: types.optional(SubchecklistsModel, {}),
    subchecklistItems: types.optional(SubchecklistItemsModel, {}),
    checklists: types.array(types.frozen<ChecklistDetails>()),
  })
  .actions(self => {
    const ajax = getAjax(self);

    const load = flow(function*() {
      self.checklists = yield ajax.checklist.listChecklists(true);
    });

    const checkForUniqueChecklistName = flow(function*(checklistName: string) {
      const dto: UniqueNameCheckResultDto = yield ajax.checklist.checkForUniqueChecklistName(
        checklistName
      );
      return dto;
    });

    return {
      load,
      checkForUniqueChecklistName,
    };
  });
