import './TablePagePane.scss';

import cn from 'classnames';
import { Table, FormFeedback, Button } from 'reactstrap';
import { ChangeState } from 'src/api/enums';
import { CheckIcon } from 'src/images/icons';
import memoizeOne, {
  getShallowMemoizedObjectFunc,
  shallowMemoize,
} from 'src/infrastructure/memoizeOne';
import { except } from 'src/infrastructure/arrayUtils';
import withQueryParams, { IQueryParamsProps } from 'src/views/hocs/withQueryParams';
import { NestedField, withFormApi } from 'src/views/components/Page/forms';
import Spinner from 'src/views/components/Spinner';
import {
  ITablePaneDef,
  isActionListFieldDef,
  IPaneData,
  IPaneMeta,
  IHasChangeState,
  FieldDefs,
  IFieldFunctionsDef,
  IFieldData,
  FieldType,
  isReadonlyFieldDef,
} from 'src/views/definitionBuilders/types';
import PageField, {
  fieldCanBeFocused,
  isMandatory,
  isHidden,
  getFieldData,
} from 'src/views/components/Page/fields/PageField';
import { FieldInfoTooltip } from '../../FieldInfoTooltip';
import { IFormApi } from 'src/views/components/Page/forms/base';
import { Component, PureComponent } from 'react';

const EMPTY_OVERRIDES_ARRAY: Array<FieldDefs | null> = [];
const SIMPLEFIELD_VALID_DEF_KEYS = ['fieldType', 'label', 'dataAddr', 'readonly', 'orderBy'];

interface ISimpleFieldProps {
  value: unknown;
  neverEditable?: boolean;
}

class SimpleField extends PureComponent<ISimpleFieldProps> {
  render() {
    const { value, neverEditable } = this.props;
    if (value === undefined || value === null) {
      return null;
    }
    return (
      <div
        className={cn('simple-field-component labelled-field-component form-group', {
          'no-form-field': neverEditable,
          'form-field': !neverEditable,
        })}>
        <div className="field-content">
          {typeof value === 'string' || typeof value === 'number' ? value : '<unrenderable value>'}
        </div>
      </div>
    );
  }
}

interface ITablePagePaneRowProps {
  paneData: IPaneData;
  // tslint:disable-next-line:no-any
  rowData: any;
  rowIdx: number;
  focusableRow: boolean;
  tableReadonly: boolean;
  formChange$: IPaneMeta['formChange$'];
  rowSelectedKey: ITablePaneDef['rowSelectedKey'];
  rowSelectionMode: ITablePaneDef['rowSelectionMode'];
  neverEditable: ITablePaneDef['neverEditable'];
  fields: ITablePaneDef['fields'];
  fieldOverrides: Array<FieldDefs | null>;
  getFieldValueAndAddr: (
    def: FieldDefs,
    rowIdx: number
  ) => {
    fieldValue: unknown;
    fieldDataAddr: React.ReactText[];
  };
  onRemoveRow: (rowIdx: number) => void;
  onSelectRow: (rowIdx: number) => void;
}

class TablePagePaneRow extends PureComponent<ITablePagePaneRowProps> {
  private _mounted = false;

  componentDidMount() {
    this._mounted = true;
  }

  private readonly handleRemoveRow = () => {
    this.props.onRemoveRow(this.props.rowIdx);
  };

  private readonly handleSelectRow = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      this.props.onSelectRow(this.props.rowIdx);
    }
  };

  private readonly getFieldMeta = memoizeOne(
    (
      readonly: boolean,
      autoFocus: boolean,
      neverEditable: boolean | undefined,
      formChange$: IPaneMeta['formChange$'],
      removed: boolean
    ) => {
      return {
        readonly,
        autoFocus,
        hideLabel: true,
        noForm: neverEditable,
        formChange$,
        removeArrayItem: this.handleRemoveRow,
        arrayItemRemoved: removed,
      };
    }
  );

  private readonly isSimpleField = (fieldDef: FieldDefs, rowReadonly: boolean) => {
    if (
      fieldDef.fieldType === FieldType.textField ||
      fieldDef.fieldType === FieldType.numericField ||
      fieldDef.fieldType === FieldType.readonlyField ||
      fieldDef.fieldType === FieldType.textAreaField
    ) {
      const asDefFuncs = fieldDef as IFieldFunctionsDef<unknown>;
      if (!rowReadonly && asDefFuncs.readonly !== true) {
        return false;
      }
      const invalidKeys = except(Object.keys(fieldDef), SIMPLEFIELD_VALID_DEF_KEYS);
      return !invalidKeys.length;
    }
    return false;
  };

  render() {
    const {
      paneData,
      rowData,
      rowIdx,
      focusableRow,
      tableReadonly,
      getFieldValueAndAddr,
      formChange$,
      rowSelectedKey,
      rowSelectionMode,
      neverEditable,
      fields,
      fieldOverrides,
    } = this.props;
    const removed = (rowData as IHasChangeState).changeState === ChangeState.Deleted;
    const readonly = removed ? true : tableReadonly;
    const rowSelected = rowSelectedKey ? !removed && !!rowData[rowSelectedKey] : undefined;
    const rowFields = fields.map((f, i) => fieldOverrides[i] || f);

    // We should only be autofocusing when a row is being created.
    // The idea is that when a user manually adds a row, it gets the focus.
    const fieldToFocus =
      !this._mounted &&
      focusableRow &&
      rowFields.find(fieldDef => {
        // Only check the first row for efficiency
        const { fieldValue, fieldDataAddr } = getFieldValueAndAddr(fieldDef, rowIdx);
        const fieldData = getFieldData(paneData, rowData, fieldValue, fieldDataAddr);
        const defFuncs = fieldDef as IFieldFunctionsDef<unknown>;
        return fieldCanBeFocused(
          defFuncs.readonly || isReadonlyFieldDef(fieldDef),
          defFuncs.hidden,
          fieldData
        );
      });

    return (
      <tr
        className={cn({
          'row-not-selected': rowSelected === false,
          'row-readonly': readonly,
        })}>
        <NestedField field={rowIdx}>
          {rowSelectedKey ? (
            <td className="row-selected-cell">
              {!readonly && rowSelectionMode === 'single' ? (
                <input
                  autoComplete="off"
                  type="radio"
                  checked={!!rowSelected}
                  onChange={this.handleSelectRow}
                />
              ) : rowSelected ? (
                <CheckIcon />
              ) : null}
            </td>
          ) : null}
          {rowFields.map((fieldDef, i) => {
            const isActionList = isActionListFieldDef(fieldDef);
            return (
              <td key={i} className={`${isActionList ? 'right-align' : ''}`}>
                {this.isSimpleField(fieldDef, readonly) ? (
                  <SimpleField
                    value={getFieldValueAndAddr(fieldDef, rowIdx).fieldValue}
                    neverEditable={neverEditable}
                  />
                ) : (
                  <PageField
                    fieldDef={fieldDef}
                    fieldMeta={this.getFieldMeta(
                      readonly,
                      fieldDef === fieldToFocus,
                      neverEditable,
                      formChange$,
                      removed
                    )}
                    paneData={paneData}
                    parentValue={rowData}
                  />
                )}
              </td>
            );
          })}
        </NestedField>
      </tr>
    );
  }
}

interface ITablePagePaneProps extends IQueryParamsProps<{ orderBy: string }> {
  formApi: IFormApi;
  paneDef: ITablePaneDef;
  paneData: IPaneData;
  paneMeta: IPaneMeta;
}

class TablePagePane extends Component<ITablePagePaneProps> {
  private _isMounted = false;

  componentDidMount() {
    this._isMounted = true;
  }

  getSortOrderClassName(column: string) {
    const currentParams = this.props.getQueryParams();
    const currentOrderBy = currentParams.orderBy;
    var splitCurrentOrderBy = currentOrderBy && currentOrderBy.split('~', 2);
    if (
      !splitCurrentOrderBy ||
      splitCurrentOrderBy.length !== 2 ||
      splitCurrentOrderBy[0] !== column
    ) {
      return undefined;
    }
    const direction = splitCurrentOrderBy[1];
    if (direction === 'asc') {
      return 'sort-ascending';
    }
    return 'sort-descending';
  }

  handleClick = (orderByColumn: string) => {
    const currentParams = this.props.getQueryParams();
    const currentOrderBy = currentParams.orderBy;
    var splitCurrentOrderBy = currentOrderBy && currentOrderBy.split('~', 2);
    let newOrderBy: string;
    if (
      !splitCurrentOrderBy ||
      splitCurrentOrderBy.length !== 2 ||
      splitCurrentOrderBy[0] !== orderByColumn ||
      (splitCurrentOrderBy[0] === orderByColumn && splitCurrentOrderBy[1] === 'desc')
    ) {
      newOrderBy = orderByColumn + '~asc';
    } else {
      newOrderBy = orderByColumn + '~desc';
    }
    this.props.updateQueryParams({ orderBy: newOrderBy });
  };

  private readonly getRows = () => {
    const { paneValue } = this.props.paneData;
    const { hideRemovedRows } = this.props.paneDef;
    if (Array.isArray(paneValue)) {
      if (hideRemovedRows) {
        return paneValue.filter(x => !x.changeState || x.changeState !== ChangeState.Deleted);
      }
      return paneValue;
    }
    return [];
  };

  private readonly handleRemoveItem = (rowIdx: number) => {
    const { formApi, paneDef } = this.props;

    const removeFromArray = () => formApi.removeValue(formApi.getFullField(), rowIdx);

    if (paneDef.deleteRemovedItems) {
      removeFromArray();
      return;
    }

    const rows = this.getRows();
    const row = rows[rowIdx] as IHasChangeState;
    if (row.changeState === ChangeState.Added) {
      removeFromArray();
      return;
    }

    const updatedItems = [...rows];
    const updatedRow = {
      ...row,
      changeState:
        row.changeState === ChangeState.Deleted ? ChangeState.Modified : ChangeState.Deleted,
    };
    updatedItems.splice(rowIdx, 1, updatedRow);

    formApi.setValue(formApi.getFullField(), updatedItems);
  };

  private readonly handleSelectItem = (rowIdx: number) => {
    const rows = this.getRows();
    const newRows = rows.map((o, i) => ({
      ...o,
      selected: i === rowIdx,
    }));
    this.props.formApi.setValue(this.props.formApi.getFullField(), newRows);
  };

  // tslint:disable-next-line:no-any
  private readonly handleValidate = (value: any[]) => {
    const { paneDef: def, paneData } = this.props;

    const mandatoryValidate = def.mandatory
      ? (v: Array<IHasChangeState>) =>
          !v || !v.length || v.every(x => x.changeState === ChangeState.Deleted)
            ? `At least one item is required`
            : undefined
      : undefined;

    return def.mandatory || def.validate
      ? (mandatoryValidate && mandatoryValidate(value)) || (def.validate && def.validate(paneData))
      : undefined;
  };

  private readonly getPaneDataForRows = (): IPaneData => {
    const { paneDef, paneData } = this.props;
    const { paneValue, panelValue, sectionValue } = paneData;
    switch (paneDef.dataRequiredForRows) {
      case 'paneValue':
        return this.getPaneDataForRowsObject(paneValue);
      case 'panelValue':
        return this.getPaneDataForRowsObject(paneValue, panelValue);
      case 'sectionValue':
        return this.getPaneDataForRowsObject(paneValue, panelValue, sectionValue);
      case 'none':
      default:
        return this.getPaneDataForRowsObject();
    }
  };

  private readonly getPaneDataForRowsObject = memoizeOne(
    (paneValue?: unknown, panelValue?: unknown, sectionValue?: unknown): IPaneData => {
      return {
        parentValue: undefined,
        paneValue,
        panelValue,
        sectionValue,
      };
    }
  );

  private readonly getColumnDefs = shallowMemoize(
    (rows: unknown[], paneData: IPaneData, paneDef: ITablePaneDef) => {
      const result = paneDef.fields.filter(f => {
        const autoHide =
          typeof f.columnAutoHide === 'function' ? f.columnAutoHide(paneData) : f.columnAutoHide;
        return (
          !autoHide ||
          rows.some((rowData, i) => {
            const { fieldValue, fieldDataAddr } = this.getFieldValueAndAddr(f, i);
            return !isHidden(f.hidden, getFieldData(paneData, rowData, fieldValue, fieldDataAddr));
          })
        );
      });
      // Put an additional cache on the result, as the cache for getColumnDefs is busted whenever any row changes,
      // but it rarely actually changes the column defs
      return this.getColumnDefsResultCache(result);
    }
  );

  private readonly getColumnDefsResultCache = getShallowMemoizedObjectFunc<
    ITablePaneDef['fields']
  >();

  private readonly getFieldValueAndAddr = (def: FieldDefs, rowIdx: number) => {
    const { formApi } = this.props;
    const addr =
      def.dataAddr === undefined ? [] : Array.isArray(def.dataAddr) ? def.dataAddr : [def.dataAddr];
    const fieldDataAddr = formApi.getFullField([rowIdx, ...addr]);
    const fieldValue = formApi.getValue(fieldDataAddr);
    return { fieldValue, fieldDataAddr };
  };

  tooltip = shallowMemoize(
    val => val && <FieldInfoTooltip className="field-tooltip">{val}</FieldInfoTooltip>
  );

  render() {
    const { formApi, paneDef, paneData, paneMeta } = this.props;
    const rows = this.getRows();
    const paneDataForRows = this.getPaneDataForRows();
    const columnDefs = this.getColumnDefs(rows, paneData, paneDef);
    return (
      <div
        className={cn('table-page-pane-component', {
          'collapse-rows': paneDef.hideRowsWhileLoading && paneDef.loading,
        })}>
        <NestedField validate={this.handleValidate}>
          {fieldApi => (
            <>
              {paneDef.title ? (
                <h4 className="table-title">
                  {typeof paneDef.title === 'function' ? paneDef.title(paneData) : paneDef.title}
                </h4>
              ) : null}
              {paneDef.tableDescription ? (
                <span className="table-description">{paneDef.tableDescription}</span>
              ) : null}
              <Table
                hover
                style={{ tableLayout: paneDef.tableLayout || 'auto' }}
                borderless
                striped={paneDef.neverEditable}>
                <colgroup>
                  {paneDef.rowSelectedKey ? <col /> : null}
                  {columnDefs.map((f, i) => (
                    <col
                      key={i}
                      style={{
                        width: f.columnWidth || undefined,
                        minWidth: f.minColumnWidth || undefined,
                      }}
                    />
                  ))}
                </colgroup>
                <thead>
                  <tr>
                    {paneDef.rowSelectedKey ? <th /> : null}
                    {columnDefs.map((f, i) => {
                      const isActionList = isActionListFieldDef(f);
                      // tslint:disable-next-line:no-any
                      const defFuncs = f as IFieldFunctionsDef<any>;
                      const mandatory =
                        typeof defFuncs.mandatory === 'function' ||
                        (defFuncs.mandatory && typeof defFuncs.readonly === 'function')
                          ? rows.some((rowData, idx) => {
                              const { fieldValue, fieldDataAddr } = this.getFieldValueAndAddr(
                                f,
                                idx
                              );
                              return isMandatory(
                                defFuncs.mandatory,
                                defFuncs.readonly,
                                getFieldData(paneData, rowData, fieldValue, fieldDataAddr)
                              );
                            })
                          : isMandatory(
                              defFuncs.mandatory,
                              defFuncs.readonly,
                              {} as IFieldData<unknown>
                            );

                      const orderBy = f.orderBy;

                      let fieldData: IFieldData<unknown> | undefined;
                      if (rows.length > 0) {
                        const { fieldValue, fieldDataAddr } = this.getFieldValueAndAddr(f, 0);
                        fieldData = getFieldData(paneData, rows[0], fieldValue, fieldDataAddr);
                      }

                      const labelText =
                        typeof f.label === 'function' ? fieldData && f.label(fieldData) : f.label;

                      const tooltipText =
                        typeof f.tooltip === 'function'
                          ? fieldData && f.tooltip(fieldData)
                          : f.tooltip;

                      return (
                        <th
                          key={i}
                          className={`${isActionList ? 'right-align' : ''}`}
                          style={{ minWidth: f.minColumnWidth || undefined }}>
                          {orderBy ? (
                            <Button
                              outline
                              className={this.getSortOrderClassName(orderBy)}
                              onClick={() => this.handleClick(orderBy)}
                              title={'Order by ' + labelText}>
                              {labelText}
                            </Button>
                          ) : (
                            <div
                              className={
                                'label-with-tooltip' + (f.labelAlignRight ? ' align-right' : '')
                              }>
                              <div className={`${mandatory ? 'mandatory' : ''}`}>{labelText}</div>
                              {this.tooltip(tooltipText)}
                            </div>
                          )}
                        </th>
                      );
                    })}
                  </tr>
                </thead>
                <tbody>
                  {rows.map((rowData, rowIdx) => {
                    const rowKey =
                      (paneDef.rowKey && paneDef.rowKey({ ...paneData, itemValue: rowData })) ||
                      rowIdx;

                    const fieldOverrides = paneDef.fieldRowOverrides
                      ? paneDef.fieldRowOverrides({ ...paneData, itemValue: rowData })
                      : EMPTY_OVERRIDES_ARRAY;

                    const focusableRow =
                      this._isMounted && // Default rows should not be focused, even when they're status "added"
                      !paneMeta.readonly &&
                      rowIdx === rows.length - 1 &&
                      rowData.changeState &&
                      rowData.changeState === ChangeState.Added;

                    return (
                      <TablePagePaneRow
                        key={rowKey}
                        paneData={paneDataForRows}
                        rowData={rowData}
                        rowIdx={rowIdx}
                        focusableRow={focusableRow}
                        tableReadonly={paneMeta.readonly}
                        getFieldValueAndAddr={this.getFieldValueAndAddr}
                        formChange$={paneMeta.formChange$}
                        rowSelectedKey={paneDef.rowSelectedKey}
                        rowSelectionMode={paneDef.rowSelectionMode}
                        neverEditable={paneDef.neverEditable}
                        fields={columnDefs}
                        fieldOverrides={fieldOverrides}
                        onRemoveRow={this.handleRemoveItem}
                        onSelectRow={this.handleSelectItem}
                      />
                    );
                  })}
                </tbody>
              </Table>
              {!paneMeta.readonly && formApi.submits > 0 && fieldApi.error ? (
                <FormFeedback className="table-errors">{fieldApi.error}</FormFeedback>
              ) : null}
              {paneDef.loading ? (
                <Spinner show>
                  <div style={{ height: '3em' }} />
                </Spinner>
              ) : null}
              {(paneMeta.readonly || paneDef.neverEditable) && !paneDef.loading && !rows.length ? (
                <p className="no-rows-message">
                  <em>{paneDef.noRowsMessage || 'There are no items to display'}</em>
                </p>
              ) : null}
            </>
          )}
        </NestedField>
      </div>
    );
  }
}

export default withFormApi(withQueryParams(TablePagePane));
