import { isDevelopment } from 'src/appSettings';
import deepEqual from 'src/infrastructure/deepEqual';
import { Form } from 'src/views/components/Page/forms';
import Spinner from 'src/views/components/Spinner';
import { RouteContext } from 'src/views/App';
import { IFormApi, IFormApiWithoutState } from 'src/views/components/Page/forms/base';
import { IFormProps } from 'src/views/components/Page/forms/Form';
import { Component } from 'react';

export interface IPageFormProps {
  readonly?: boolean;
  values?: IFormProps['values'];
  defaultValues?: IFormProps['defaultValues'];
  defaultTouchedFields?: IFormProps['defaultTouchedFields'];
  suppressLeavePrompt?: boolean;
  getApi?: IFormProps['getApi'];
  // tslint:disable-next-line:no-any
  onChange: (formState: any) => void;
  onResetRequested?: (api: IFormApi, cancelReset: () => void) => void;
  onPreSubmit?: IFormProps['preSubmit'];
  onSubmit?: (
    // tslint:disable-next-line:no-any
    values: any,
    formApi: IFormApi
  ) => Promise<void>;
  onSubmitSucceeded?: () => Promise<void>;
  // tslint:disable-next-line:no-any
  onSubmitFailure?: (formErrors: string[], submitError: any) => void;
  children: ((formApi: IFormApi) => React.ReactNode) | React.ReactNode;
  setInitialFormValuesOnEdit?: () => void;
}

export const PageFormTestSpy: React.FC<{ formApi: IFormApi }> = ({ formApi }) => null;

class PageForm extends Component<IPageFormProps> {
  static contextType = RouteContext;
  context!: React.ContextType<typeof RouteContext>;

  private readonly _id = Symbol();

  componentWillUnmount() {
    this.context && this.context.setPagePrompt(this._id, false);
  }

  private readonly handleGetApi = (api: IFormApiWithoutState) => {
    this.props.getApi && this.props.getApi(api);
  };

  private readonly handleSubmit: IFormProps['onSubmit'] = async (values, formApi) => {
    if (this.props.onSubmit) {
      await this.props.onSubmit(values, formApi);
      this.props.onSubmitSucceeded && (await this.props.onSubmitSucceeded());
    }
  };

  private readonly handleSubmitFailure = (formErrors: string[], submitError: unknown) => {
    if (this.props.onSubmitFailure) {
      this.props.onSubmitFailure(formErrors, submitError);
    }

    if (isDevelopment()) {
      console.error('PageForm Submit errors', { formErrors, submitError });
    }
  };

  private readonly compareValues = (defaultValues: {}, values: {}) => {
    return deepEqual(defaultValues || {}, values);
  };

  render() {
    const {
      readonly,
      values,
      defaultValues,
      defaultTouchedFields,
      suppressLeavePrompt,
      onResetRequested,
      onChange,
      onPreSubmit,
      onSubmit,
      children,
    } = this.props;
    return (
      <Form
        readonly={readonly}
        values={values}
        defaultValues={defaultValues}
        defaultTouchedFields={defaultTouchedFields}
        getApi={this.handleGetApi}
        onChange={onChange}
        onCompareValues={this.compareValues}
        preSubmit={onPreSubmit}
        onSubmit={onSubmit && this.handleSubmit}
        onSubmitFailure={this.handleSubmitFailure}
        onResetAll={onResetRequested}
        setInitialFormValuesOnEdit={this.props.setInitialFormValuesOnEdit}>
        {formApi => {
          this.context &&
            this.context.setPagePrompt(
              this._id,
              !readonly && !suppressLeavePrompt && formApi.valuesChanged() && !formApi.submitted
            );

          return (
            <div className="page-form-component">
              <Spinner className="form-spinner" show={formApi.submitting}>
                <PageFormTestSpy formApi={formApi} />
                {children && typeof children === 'function'
                  ? (children as Function)(formApi)
                  : children}
              </Spinner>
            </div>
          );
        }}
      </Form>
    );
  }
}

export default PageForm;
