import { buildListPageApiSearchModelTypedQuery } from 'src/domain/modelBuilders/buildListPageApiSearchModel';

type ListRosterTemplatesQuery = Workshop.Domain.Queries.RosterTemplate.ListRosterTemplates.ListRosterTemplatesQuery;

const ListRosterTemplatesModel = buildListPageApiSearchModelTypedQuery<ListRosterTemplatesQuery>()(
  'ListRosterTemplatesModel',
  d => d.ajax.workshop.rosterTemplate.listRosterTemplates(d.query),
  {
    size: 9999,
  }
);

export const RosterTemplatesModel = ListRosterTemplatesModel;
