import { buildListPageApiSearchModelTypedQuery } from 'src/domain/modelBuilders/buildListPageApiSearchModel';
import { types, flow, getRoot } from 'mobx-state-tree';
import { getAjax } from 'src/domain/services/storeEnvironment';
import { IAutocompleteResult, NotificationType } from 'src/domain/baseTypes';
import { IRootStoreModel } from 'src/domain/entities/RootStoreModel';

type ListRostersQuery = Workshop.Domain.Queries.Roster.ListRosters.ListRostersQuery;
type RosterAutocompleteListItem = Workshop.Domain.Queries.Roster.AutocompleteListRosters.RosterAutocompleteListItem;
type GetWeekWithoutRosterQuery = Workshop.Domain.Queries.Roster.GetWeekWithoutRoster.GetWeekWithoutRosterQuery;
type RosterWeek = Workshop.Domain.Queries.Roster.GetWeekWithoutRoster.RosterWeek;
type RosterNameListItem = Workshop.Domain.Queries.Roster.ListRosterNames.RosterNameListItem;

const ListRostersModel = buildListPageApiSearchModelTypedQuery<
  ListRostersQuery
>()('ListRostersModel', d => d.ajax.workshop.roster.listRosters(d.query));

const AutocompleteRostersModel = types.model('AutocompleteRostersModel', {}).actions(self => {
  const ajax = getAjax(self);

  let lastRostersAutocompleteSearch: string | undefined;
  let lastRostersAutocompleteDepotIds: number[] = [];
  let lastRosterAutocompleteSearchResult:
    | IAutocompleteResult<RosterAutocompleteListItem>
    | undefined;

  const autocompleteRosters = flow(function*(search: string, depotIds: number[]) {
    if (
      search === lastRostersAutocompleteSearch &&
      depotIds.length === lastRostersAutocompleteDepotIds.length &&
      lastRostersAutocompleteDepotIds.every((depotId: number) => depotIds.includes(depotId))
    ) {
      return lastRosterAutocompleteSearchResult || { options: [] };
    }
    const rosters: Common.Dtos.ListResult<RosterAutocompleteListItem> = yield ajax.workshop.roster
      .autocompleteListRosters({
        page: 1,
        size: 10,
        search,
        depotIds,
      })
      .toPromise();
    lastRostersAutocompleteSearch = search;
    lastRostersAutocompleteDepotIds = depotIds;
    lastRosterAutocompleteSearchResult = {
      options: rosters.items,
    };
    return lastRosterAutocompleteSearchResult;
  });

  const listRosterNames = flow(function*(rosterIds: string[]) {
    if (!rosterIds || !rosterIds.length) {
      return { options: [] };
    }
    const rosters: RosterNameListItem[] = yield ajax.workshop.roster
      .listRosterNames({
        rosterIds: rosterIds,
      })
      .toPromise();

    const result: IAutocompleteResult<RosterNameListItem> = { options: rosters };
    return result;
  });

  return {
    autocompleteRosters,
    listRosterNames,
  };
});

const QueryRostersModel = types.model('QueryRostersModel', {}).actions(self => {
  const ajax = getAjax(self);
  const root = getRoot(self) as IRootStoreModel;

  const getWeekWithoutRoster = flow(function*(query: Partial<GetWeekWithoutRosterQuery>) {
    const weekWithoutRoster: RosterWeek = yield ajax.workshop.roster.getWeekWithoutRoster(query);
    return weekWithoutRoster;
  });

  const exportToExcel: (query: Partial<ListRostersQuery>) => Promise<Blob> = flow(function*(
    query: Partial<ListRostersQuery>
  ) {
    root.notifications.addNotification(`The file is being generated ...`, {
      type: NotificationType.info,
    });

    return yield ajax.workshop.roster.exportRostersToExcel(query);
  });

  return {
    getWeekWithoutRoster,
    exportToExcel,
  };
});

export const RostersModel = types.compose(
  ListRostersModel,
  AutocompleteRostersModel,
  QueryRostersModel
);
