import inject from 'src/views/injectFromStore';
import Workshop, { IWorkshopProps } from './Workshop';

const Container = inject<IWorkshopProps>((allStores, p) => ({
  canManageDefects: allStores.rootStore.account.isWorkshopDepartmentMember,
  canManageJobs: allStores.rootStore.account.isWorkshopDepartmentMember,
  dashboardData: allStores.rootStore.workshop.dashboard.dashboardData,
  onLoadData: allStores.rootStore.workshop.dashboard.loadDashboardData,
  workshopDepots: allStores.rootStore.workshopStartup.workshopDepots.slice(),
  defaultWorkshopDepot: allStores.rootStore.workshopStartup.defaultWorkshopDepot,
}))(Workshop);

export default Container;
