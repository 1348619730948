import './PrintActionButton.scss';

import cn from 'classnames';
import PrintButton from 'src/views/components/PrintButton';
import { PrintIcon } from 'src/images/icons';
import {
  IPrintActionButtonDef,
  IActionData,
  IActionMeta,
} from 'src/views/definitionBuilders/types';
import { Component, createRef } from 'react';

export const DefaultPrintIcon = PrintIcon;

interface IPrintActionButtonProps {
  actionDef: IPrintActionButtonDef;
  actionMeta: IActionMeta;
  actionData: IActionData;
}

class PrintActionButton extends Component<IPrintActionButtonProps> {
  private readonly _ref = createRef<PrintButton>();

  public readonly trigger = () => {
    this._ref.current && this._ref.current.trigger();
  };

  private readonly getDisabled = () => {
    const { actionDef, actionData } = this.props;

    if (typeof actionDef.disabled === 'function') {
      return actionDef.disabled(actionData);
    } else {
      return actionDef.disabled;
    }
  };

  render() {
    const { actionDef, actionMeta: meta, actionData } = this.props;
    const loadDataAsync = actionDef.loadDataAsync
      ? () => (actionDef.loadDataAsync ? actionDef.loadDataAsync(actionData) : Promise.resolve())
      : undefined;
    const disabled = this.getDisabled();

    const isBlob = actionDef.printContent instanceof Blob;

    return (
      <PrintButton
        ref={this._ref}
        className={cn('print-action-button-component', {
          borderless: meta.borderless,
        })}
        title={actionDef.label}
        loadDataAsync={loadDataAsync}
        printContent={
          isBlob
            ? (actionDef.printContent as Blob)
            : () =>
                actionDef.printContent &&
                (actionDef.printContent as (actionData: IActionData) => React.ReactNode)(actionData)
        }
        printTitle={actionDef.printTitle}
        disabled={disabled}
        size={meta.size}
        outline={meta.borderless}
        color={actionDef.level === 'primary' ? 'primary' : 'secondary'}>
        <PrintIcon />
        &nbsp;
        {meta.hideLabel ? null : actionDef.label || 'Print'}
      </PrintButton>
    );
  }
}
export default PrintActionButton;
