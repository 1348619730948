import inject from 'src/views/injectFromStore';
import ListChecklists, { IListChecklistsProps } from './ListChecklists';

const Container = inject<IListChecklistsProps>((allStores, p) => ({
  canManageChecklists: allStores.rootStore.account.isWorkshopDepartmentMember,
  checklists: allStores.rootStore.workshop.checklists.checklists.slice(),
  listChecklists: allStores.rootStore.workshop.checklists.load,
}))(ListChecklists);

export default Container;
