import ListPage, { IListPageDef } from 'src/views/components/Page/pages/ListPage';
import { IListPageLoadDataRequest } from 'src/domain/baseTypes';
import { FieldType } from 'src/views/definitionBuilders/types/field';
import TaskCardItem from 'src/views/components/TaskCard/TaskCardItem';
import { PagePrimarySize } from 'src/views/definitionBuilders/types';
import { CheckIcon } from 'src/images/icons';
import PrimaryTitle from 'src/views/components/Page/PrimaryTitle/PrimaryTitle';

export interface IListOwnersProps {
  canManageOwners: boolean;
  listOwners: (request: IListPageLoadDataRequest) => Promise<void>;
  owners: Workshop.Domain.Queries.Owners.OwnerListItem[];
  hasMoreData: boolean;
}

function getPageDef(props: IListOwnersProps): IListPageDef {
  return {
    primaryTitle: (
      <PrimaryTitle
        title="Owners"
        link="https://www.notion.so/Owners-6cc698cc051e47d9b71b8b26d9ba6bbd"></PrimaryTitle>
    ),
    primarySize: PagePrimarySize.full,
    onLoadData: props.listOwners,
    externalSearch: true,
    createLink: props.canManageOwners ? TaskCardItem.workshop.createOwner : undefined,
    hasMoreData: props.hasMoreData,
    filterAction: {
      filterFields: [
        {
          fieldType: FieldType.yesNoField,
          label: 'Active',
          dataAddr: 'isActive',
        },
      ],
      defaultValues: {
        isActive: true,
      },
    },
    primaryFields: [
      {
        fieldType: FieldType.textField,
        dataAddr: 'name',
        label: 'Name',
        linkTo: d => `/workshop/owners/${d.parentValue.id}`,
      },
      {
        fieldType: FieldType.textField,
        dataAddr: 'email',
        label: 'Email',
      },
      {
        fieldType: FieldType.textField,
        dataAddr: 'phone',
        label: 'Phone',
      },
      {
        fieldType: FieldType.customField,
        dataAddr: 'isBillable',
        label: 'Jobs Require Billing',
        render: api => {
          return api.data.parentValue.isBillable ? <CheckIcon /> : null;
        },
      },
      {
        fieldType: FieldType.textField,
        dataAddr: 'isActive',
        label: 'Active',
        formatReadonly: data => (data.fieldValue ? <CheckIcon /> : undefined),
      },
    ],
  };
}

const ListOwners: React.FC<IListOwnersProps> = (props: IListOwnersProps) => {
  return <ListPage className="list-owners-component" data={props.owners} def={getPageDef(props)} />;
};

export default ListOwners;
