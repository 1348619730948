import { buildListPageApiSearchModelTypedQuery } from 'src/domain/modelBuilders/buildListPageApiSearchModel';
import { types, getRoot, flow } from 'mobx-state-tree';
import { getAjax, NotificationType } from 'src/domain/services';
import { IRootStoreModel } from 'src/domain/entities/RootStoreModel';

type ListJobProgressWarningsQuery = Operations.Domain.Queries.ListJobProgressWarnings.ListJobProgressWarningsQuery;

const JobProgressWarningsListModel = buildListPageApiSearchModelTypedQuery<
  ListJobProgressWarningsQuery
>()('JobProgressWarningsListModel', d => d.ajax.operations.jobs.listJobProgressWarnings(d.query), {
  size: 50,
});

const ExportJobProgressWarningsModel = types
  .model('ExportJobProgressWarningsModel', {})
  .actions(self => {
    const ajax = getAjax(self);
    const root = getRoot(self) as IRootStoreModel;

    const exportToExcel = flow(function*(query: Partial<ListJobProgressWarningsQuery>) {
      root.notifications.addNotification(`The file is being generated ...`, {
        type: NotificationType.info,
      });

      return yield ajax.operations.jobs.exportJobProgressWarningsToExcel(query);
    });

    return {
      exportToExcel,
    };
  });

export const ListJobProgressWarningsModel = types.compose(
  JobProgressWarningsListModel,
  ExportJobProgressWarningsModel
);
