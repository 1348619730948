import MaintainTechSpecs, { IMaintainTechSpecsProps } from './MaintainTechSpecs';
import inject from 'src/views/injectFromStore';
import Omit from 'src/infrastructure/omit';

const Container = inject<Omit<IMaintainTechSpecsProps, 'mode'>, IMaintainTechSpecsProps>(
  (allStores, _) => ({
    canManageTechSpecs: allStores.rootStore.account.isWorkshopDepartmentMember,
    techSpecs: allStores.rootStore.workshop.techSpecs.items.slice(),
    getTechSpecs: allStores.rootStore.workshop.techSpecs.getAll,
    updateTechSpecs: allStores.rootStore.workshop.techSpecs.updateAll,
    clearTechSpecRequirements: allStores.rootStore.workshop.techSpecs.clearTechSpecRequirements,
    canClearTechSpecRequirements: allStores.rootStore.account.isAdminUser,
    onCheckForUniqueTechSpecName: allStores.rootStore.workshop.techSpecs.checkForUniqueTechSpecName,
  })
)(MaintainTechSpecs);

export default Container;
