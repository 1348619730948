import { saveAs } from 'file-saver';
import { DateTime } from 'luxon';
import { observer } from 'mobx-react';
import { useEffect } from 'react';
import { useRootStore } from 'src/domain/entities/RootStoreModel';
import { ExcelIcon } from 'src/images/icons';
import memoizeOne from 'src/infrastructure/memoizeOne';
import ListPage, { IListPageDef } from 'src/views/components/Page/pages/ListPage';
import PrimaryTitle from 'src/views/components/Page/PrimaryTitle/PrimaryTitle';
import { TaskCardItem } from 'src/views/components/TaskCard';
import {
  ActionType,
  FieldDefs,
  FieldType,
  PagePrimarySize,
  PaneType,
} from 'src/views/definitionBuilders/types';
import withQueryParams, { IQueryParamsProps } from 'src/views/hocs/withQueryParams';

type ListCodeOfConductQuery = Operations.Domain.Queries.ListCodeOfConduct.ListCodeOfConductQuery;

const ListCodeOfConduct: React.FC<IQueryParamsProps<ListCodeOfConductQuery>> = observer(
  (props: IQueryParamsProps<ListCodeOfConductQuery>) => {
    const rootStore = useRootStore();
    const conductModel = rootStore.compliance.codeOfConduct.list;
    const assetsModel = rootStore.assets;
    const staffModel = rootStore.people.staffMembers;

    useEffect(() => {
      assetsModel.loadFleetAssets();
      staffModel.loadAllStaffMemberNames({});
    }, []);

    const saveSpreadsheet = () => {
      const fileName = `Code_Of_Conduct_${DateTime.local().toFormat('yyyyMMddHHmm')}.xlsx`;
      return conductModel
        .exportToExcel(props.getQueryParams())
        .then(blob => saveAs(blob, fileName));
    };

    const getFilterFieldDefs = () => {
      return {
        dateFrom: {
          fieldType: FieldType.dateField,
          dataAddr: 'dateFrom',
          label: 'Date From',
        } as FieldDefs,
        dateTo: {
          fieldType: FieldType.dateField,
          dataAddr: 'dateTo',
          label: 'Date To',
          validate: d => {
            if (!d.fieldValue || !d.parentValue.dateFrom) {
              return undefined;
            }
            const from = DateTime.fromISO(d.parentValue.dateFrom);
            const to = DateTime.fromISO(d.fieldValue);
            return from > to ? 'Date To cannot be earlier than Date From' : undefined;
          },
        } as FieldDefs,
        staffMembers: {
          fieldType: FieldType.selectMultiField,
          dataAddr: 'staffMembers',
          label: 'Staff Member',
          valueKey: 'id',
          descriptionKey: 'name',
          optionItems: staffModel.allStaffMemberNames.slice(),
          useValueOnly: true,
        } as FieldDefs,
        vehicles: {
          fieldType: FieldType.selectMultiField,
          dataAddr: 'assets',
          label: 'Vehicle',
          valueKey: 'id',
          descriptionKey: 'name',
          optionItems: assetsModel.fleetAssetListItems.slice(),
          useValueOnly: true,
        } as FieldDefs,
      };
    };
    const getPageDef = memoizeOne(
      (hasMoreData: boolean): IListPageDef => {
        const filterFieldDefsLookup = getFilterFieldDefs();
        return {
          primaryTitle: <PrimaryTitle title="Code Of Conducts"></PrimaryTitle>,
          onLoadData: conductModel.listItems,
          externalSearch: true,
          createLink: rootStore.account.isOperationsDepartmentMember
            ? TaskCardItem.operations.compliance.codeOfConduct.createCodeOfConduct
            : undefined,
          primarySize: PagePrimarySize.full,
          hasMoreData: hasMoreData,
          rowKey: d => {
            return d.itemValue.id;
          },
          primaryActions: [
            {
              actions: [
                {
                  actionType: ActionType.actionCollection,
                  actionGroups: [
                    {
                      actions: [
                        {
                          actionType: ActionType.actionButton,
                          label: 'Export to Excel',
                          icon: <ExcelIcon fixedWidth />,
                          onClick: () => saveSpreadsheet(),
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
          primaryFields: [
            {
              fieldType: FieldType.textField,
              dataAddr: 'codeOfConductNumber',
              label: 'Number',
              linkTo: d => `/compliance/code-of-conducts/${d.parentValue.id}`,
              orderBy: 'CodeOfConductNumber',
            },
            {
              fieldType: FieldType.dateTimeField,
              dataAddr: 'incidentDateTime',
              label: 'Incident Date Time',
              orderBy: 'IncidentDateTime',
            },
            {
              fieldType: FieldType.assetSelectField,
              valueKey: 'id',
              descriptionKey: 'name',
              dataAddr: 'asset',
              label: 'Vehicle',
            },
            {
              fieldType: FieldType.staffMemberField,
              dataAddr: 'staffMember',
              label: 'Staff Member',
            },
            {
              fieldType: FieldType.readonlyField,
              label: 'Students Involved',
              formatReadonly: d => {
                return d.parentValue.students.map(
                  (s: Operations.Domain.Queries.ListCodeOfConduct.Student, i: number) => {
                    return (
                      <div key={i}>
                        {s.name} - {s.school}
                      </div>
                    );
                  }
                );
              },
            },
          ],
          filterAction: {
            defaultValues: {
              orderBy: 'IncidentDateTime~desc',
            },
            filterFields: Object.keys(filterFieldDefsLookup).map(k => filterFieldDefsLookup[k]),
            filterDef: filterDefApi => [
              {
                panes: [
                  {
                    paneType: PaneType.formFieldsPane,
                    columnCount: 2,
                    fields: [filterFieldDefsLookup.dateFrom, filterFieldDefsLookup.dateTo],
                  },
                  {
                    paneType: PaneType.formFieldsPane,
                    fields: [filterFieldDefsLookup.vehicles, filterFieldDefsLookup.staffMembers],
                  },
                ],
              },
            ],
          },
        };
      }
    );

    const def = getPageDef(conductModel.hasMoreItems);
    return <ListPage data={conductModel.items} def={def} />;
  }
);

export default withQueryParams(ListCodeOfConduct);
