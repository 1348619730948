import { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import CrudPage, { CrudPageMode, ICrudPageDef } from 'src/views/components/Page/pages/CrudPage';
import {
  PagePrimarySize,
  PaneType,
  FieldType,
  ActionType,
  IActionData,
} from 'src/views/definitionBuilders/types';
import { DateTimeFormat } from 'src/views/components/DateTimeFormat';
import { SmsMessageStatus, allSmsMessageStatus, smsMessageStatusDescription } from 'src/api/enums';
import { CheckIcon, TimesIcon, PlusIcon } from 'src/images/icons';
import { IFormApiWithoutState } from 'src/views/components/Page/forms/base';
import { ShellModalSize } from 'src/views/components/Shell/ShellModal';
import getAddStaffMembersByRoleModalDef from '../../common/getAddStaffMembersByRoleModalDef';

type StaffMemberDto = Common.Dtos.StaffMemberDto;
type CreateSmsCommand = People.Domain.Commands.Sms.CreateSms.CreateSmsCommand;
type SmsItem = People.Domain.Queries.Sms.ViewSms.SmsItem;
type RoleItem = People.Domain.Queries.Role.RoleItem;

export interface IMaintainSmsProps {
  canManageStaffMemberSms: boolean;
  mode: CrudPageMode;

  loadStaffMembers: () => Promise<void>;
  staffMembers: StaffMemberDto[];
  activeStaffMembers: StaffMemberDto[];
  allStaffWithDriverAuthorisation: StaffMemberDto[];
  createSms: (command: CreateSmsCommand) => Promise<void>;

  loadSms: (id: string) => Promise<void>;
  sms: SmsItem | undefined;
  dismissSms: (smsId: string, smsStaffMemberId: string) => Promise<void>;
  loadAllRoles: () => Promise<void>;
  roles: RoleItem[];
}

interface ISmsForm {
  content: string;
  day: string;
  staffMembers: string[];
}

interface IMaintainSmsRouteParams {
  id: string;
}

type InternalProps = IMaintainSmsProps & RouteComponentProps<IMaintainSmsRouteParams>;

const MaintainSms: React.FC<InternalProps> = (props: InternalProps) => {
  const [formApi, setFormApi] = useState<IFormApiWithoutState | undefined>(undefined);

  useEffect(() => {
    props.loadStaffMembers();
  }, []);

  const isCreateMode = props.mode === 'create';
  const SmsId = props.match.params.id;
  const isUpdateMode = props.mode === 'update';

  const handlePreSubmitForCreate = (Sms: ISmsForm): CreateSmsCommand => {
    return {
      content: Sms.content,
      staffMembers: Sms.staffMembers,
    };
  };

  const addStaffMembers = (data: IActionData, staffMembersToAdd: StaffMemberDto[]) => {
    let loadedStaffMembers = data.panelValue?.staffMembers ?? [];

    let loadedStaffMembersDtos = loadedStaffMembers.map((id: string) => {
      return {
        id: id,
      };
    }) as StaffMemberDto[];

    loadedStaffMembersDtos = loadedStaffMembersDtos.concat(staffMembersToAdd);

    const targetIds = loadedStaffMembersDtos && new Set(loadedStaffMembersDtos.map(x => x.id));
    formApi!.setValue(['staffMembers'], Array.from(targetIds));
  };

  const getPageDef = (updating: boolean): ICrudPageDef => {
    return {
      primarySize: PagePrimarySize.threeQuarters,
      primarySection: {
        title: isCreateMode ? 'Send SMS' : 'View SMS',
        getApi: api => setFormApi(api),
        panels: [
          {
            panes: [
              {
                paneType: PaneType.formFieldsPane,
                columnCount: 1,
                fields: [
                  {
                    fieldType: FieldType.textAreaField,
                    label: 'SMS',
                    dataAddr: 'content',
                    mandatory: isCreateMode,
                    maxLength: 320,
                    showCounter: true,
                  },
                  {
                    fieldType: FieldType.selectMultiField,
                    label: 'Staff Member',
                    dataAddr: 'staffMembers',
                    optionItems: props.staffMembers,
                    valueKey: 'id',
                    descriptionKey: 'name',
                    useValueOnly: true,
                    mandatory: isCreateMode,
                    includeKey: 'active',
                    hidden: !isCreateMode,
                    formatReadonly: ms => (
                      <div>
                        {ms &&
                          ms.fieldValue &&
                          ms.fieldValue.map((m, idx) => (
                            <span key={idx} style={m.smsSucceeded ? {} : { color: 'red' }}>
                              {idx !== 0 ? ', ' : ''}
                              {m.name}
                              {m.smsSucceeded ? '' : ' (failed to send)'}
                            </span>
                          ))}
                      </div>
                    ),
                  },
                  {
                    fieldType: FieldType.readonlyField,
                    label: 'Added by',
                    dataAddr: 'createdBy',
                    hidden: isCreateMode,
                  },
                  {
                    fieldType: FieldType.readonlyField,
                    label: 'Sent',
                    dataAddr: 'sentOn',
                    formatReadonly: d => <DateTimeFormat value={d.fieldValue} />,
                    hidden: isCreateMode,
                  },
                ],
              },
              {
                paneType: PaneType.tablePane,
                dataAddr: 'staffMembers',
                neverEditable: true,
                hidden: isCreateMode,
                fields: [
                  {
                    fieldType: FieldType.textField,
                    dataAddr: 'name',
                    label: 'Name',
                  },
                  {
                    fieldType: FieldType.selectField,
                    readonly: true,
                    label: 'Status',
                    dataAddr: 'status',
                    optionItems: allSmsMessageStatus,
                    descriptionKey: 'description',
                    valueKey: 'value',
                    formatReadonly: a => {
                      const status = a.fieldValue as SmsMessageStatus;
                      return (
                        <>
                          <span>{smsMessageStatusDescription(status)}</span>
                        </>
                      );
                    },
                  },
                  {
                    fieldType: FieldType.dateTimeField,
                    dataAddr: 'statusUpdated',
                    label: 'Last Updated',
                  },
                  {
                    fieldType: FieldType.customField,
                    dataAddr: 'staffMemberNotifiedDueToFailure',
                    label: 'Recipient Notified',
                    columnWidth: '5em',
                    render: d => {
                      const staffMemberNotifiedDueToFailure = d.data.fieldValue as
                        | boolean
                        | undefined;
                      const status = d.data.parentValue.status as SmsMessageStatus | undefined;
                      if (staffMemberNotifiedDueToFailure === true) {
                        return <CheckIcon style={{ width: '4em' }} color="green" />;
                      } else if (status === SmsMessageStatus.Delivered) {
                        return null;
                      } else if (status === SmsMessageStatus.Failed) {
                        return <TimesIcon style={{ width: '4em' }} color="red" />;
                      }

                      return null;
                    },
                  },
                  {
                    fieldType: FieldType.actionListField,
                    label: 'Actions',
                    columnWidth: '5em',
                    hidden: d =>
                      (d.parentValue.status as SmsMessageStatus | undefined) ===
                        SmsMessageStatus.Delivered || d.parentValue.staffMemberNotifiedDueToFailure,
                    actionGroups: [
                      {
                        actions: [
                          {
                            actionType: ActionType.actionButton,
                            label: 'Mark as notified',
                            onClick: d => props.dismissSms(SmsId, d.parentValue.id),
                            icon: <CheckIcon style={{ width: '4em' }} color="green" />,
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
              {
                paneType: PaneType.actionListPane,
                hidden: updating || isUpdateMode,
                actionGroups: [
                  {
                    actions: [
                      {
                        actionType: ActionType.actionButton,
                        label: 'Add All Staff',
                        level: 'primary',
                        icon: <PlusIcon />,
                        onClick: data => addStaffMembers(data, props.activeStaffMembers),
                      },
                      {
                        actionType: ActionType.actionButton,
                        label: 'Add All Drivers',
                        level: 'primary',
                        icon: <PlusIcon />,
                        onClick: data =>
                          addStaffMembers(data, props.allStaffWithDriverAuthorisation),
                      },
                      {
                        actionType: ActionType.modalActionButton,
                        label: 'Add Staff By Role',
                        level: 'primary',
                        icon: <PlusIcon />,
                        modalSize: ShellModalSize.oneQuarter,
                        modalDef: getAddStaffMembersByRoleModalDef(
                          props.roles,
                          props.activeStaffMembers,
                          formApi
                        ),
                        onOpenModal: _ => props.loadAllRoles(),
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
        onFormPreSubmit: handlePreSubmitForCreate,
        onFormSubmit: props.createSms,
      },
    };
  };

  const { mode, loadSms, sms } = props;
  return (
    <CrudPage
      def={({ updating }) => getPageDef(updating)}
      mode={mode}
      isEditingForbidden
      onLoadData={() => loadSms(SmsId)}
      data={sms}
      createDefaultData={{ activeStaffMembers: [] }}
    />
  );
};

export default MaintainSms;
