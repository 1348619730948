import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

interface IKblModalProps {
  header: string;
  modalBody: React.ReactNode;
  modalFooter?: React.ReactNode;
  isOpen: boolean;
  toggle?: () => void;
  className?: string;
  modalClassName?: string;
}

const KblModal: React.FC<IKblModalProps> = ({
  header,
  modalBody,
  modalFooter,
  isOpen,
  toggle,
  className,
  modalClassName,
}) => (
  <div>
    <Modal
      modalClassName={modalClassName}
      className={`${className} kbl-modal-component`}
      isOpen={isOpen}
      toggle={toggle}>
      <ModalHeader>{header}</ModalHeader>
      <ModalBody>{modalBody}</ModalBody>
      {modalFooter ? <ModalFooter>{modalFooter}</ModalFooter> : null}
    </Modal>
  </div>
);

export default KblModal;
