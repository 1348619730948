import { buildListPageApiSearchModelTypedQuery } from 'src/domain/modelBuilders/buildListPageApiSearchModel';
import * as queryString from 'query-string';
import { types, getRoot, flow } from 'mobx-state-tree';
import { getAjax } from 'src/domain/services';
import { NotificationType } from 'src/domain';
import { IRootStoreModel } from 'src/domain/entities/RootStoreModel';

const CustomerComplaintListModel = buildListPageApiSearchModelTypedQuery<
  Operations.Domain.Queries.ListJobs.ListJobsQuery
>()('CustomerComplaintListModel', d =>
  d.ajax.raw
    .get(`/api/compliance/customer-complaints?${queryString.stringify(d.query)}`)
    .map(
      r =>
        r.response as Common.Dtos.ListResult<
          Operations.Domain.Queries.ListCustomerComplaint.ListCustomerComplaintItem
        >
    )
);

const ExportCustomerComplaintModel = types.model('ExportCustomerComplaintModel').actions(self => {
  const ajax = getAjax(self);
  const root = getRoot(self) as IRootStoreModel;

  const exportToExcel = flow(function*(
    query: Partial<Operations.Domain.Queries.ListCustomerComplaint.ListCustomerComplaintQuery>
  ) {
    root.notifications.addNotification(`The file is being generated ...`, {
      type: NotificationType.info,
    });

    return yield ajax.raw
      .getFile(`/api/compliance/customer-complaints/excel?${queryString.stringify(query)}`)
      .toPromise()
      .then<Blob>(r => r.response);
  });

  return {
    exportToExcel,
  };
});

export const ListCustomerComplaintModel = types.compose(
  CustomerComplaintListModel,
  ExportCustomerComplaintModel
);
