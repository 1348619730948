import { types, flow } from 'mobx-state-tree';
import { getAjax } from 'src/domain/services';

type ComponentTypeItem = Workshop.Domain.Queries.ComponentType.ComponentTypeItem;
type UpdateComponentTypesCommand = Workshop.Domain.Commands.ComponentType.UpdateComponentTypesCommand;

export const ComponentTypesModel = types
  .model('ComponentTypesModel', {
    componentTypeListItems: types.array(types.frozen<ComponentTypeItem>()),
  })
  .actions(self => {
    const ajax = getAjax(self);

    const loadComponentTypeListItems = flow(function*() {
      self.componentTypeListItems = yield ajax.workshop.componentTypes.listComponentTypes();
    });

    const updateComponentTypes = flow(function*(command: UpdateComponentTypesCommand) {
      yield ajax.workshop.componentTypes.updateComponentTypes(command);
      yield loadComponentTypeListItems();
    });

    return {
      loadComponentTypeListItems,
      updateComponentTypes,
    };
  });

type IComponentTypesModelType = typeof ComponentTypesModel.Type;
export interface IComponentTypesModel extends IComponentTypesModelType {}
