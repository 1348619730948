import buildListPageApiSearchModel from 'src/domain/modelBuilders/buildListPageApiSearchModel';
import { types, flow } from 'mobx-state-tree';
import { getAjax } from 'src/domain/services';

type SubcontractorItem = Common.Dtos.SubcontractorItem;

const SubcontractorsListModel = buildListPageApiSearchModel('SubcontractorsListModel', d =>
  d.ajax.raw
    .get(
      `/api/operations/subcontractors?page=${d.page}&size=${d.query.size}&search=${d.query.search ||
        ''}`
    )
    .map(
      r =>
        r.response as Common.Dtos.ListResult<
          Operations.Domain.Queries.ListSubcontractors.SubcontractorItem
        >
    )
);

const ListAllSubcontractorsModel = types
  .model('ListAllSubcontractorsModel', {
    allSubcontractors: types.array(types.frozen<SubcontractorItem>()),
  })
  .actions(self => {
    const ajax = getAjax(self);

    const loadAllSubcontractors = flow(function*() {
      self.allSubcontractors = yield ajax.raw
        .get(`/api/operations/subcontractors/all`)
        .toPromise()
        .then<SubcontractorItem[]>(r => {
          return r.response;
        });
    });

    return {
      loadAllSubcontractors,
    };
  });

export const SubcontractorsModel = types.compose(
  SubcontractorsListModel,
  ListAllSubcontractorsModel
);
