import { types, flow } from 'mobx-state-tree';
import { getAjax } from 'src/domain/services';
import { operationsUrls } from 'src/domain/services/apiUrls';

type TasksForDay = Operations.Domain.Queries.GetTasksForDay.TasksForDay;
type UpdateTaskCommand = Operations.Domain.Commands.JobTask.UpdateTaskCommand;

export const JobTaskModel = types
  .model('JobTaskModel', {
    tasksForDay: types.maybe(types.frozen<TasksForDay>()),
  })
  .actions(self => {
    const ajax = getAjax(self);

    const loadTasksForDay = flow(function*(day: string) {
      const tasksForDay = yield ajax.raw
        .get(operationsUrls.jobTaskUrls.getTasksForDay(day))
        .toPromise()
        .then(r => r.response);
      self.tasksForDay = tasksForDay;
    });

    const updateTask = flow(function*(command: UpdateTaskCommand) {
      yield ajax.raw
        .put(operationsUrls.jobTaskUrls.updateTask(), command)
        .toPromise()
        .then(r => r.response);

      if (self.tasksForDay) {
        yield loadTasksForDay(self.tasksForDay.day);
      }
    });

    return { loadTasksForDay, updateTask };
  });
