import { FieldType, ModalDefBuilder, PaneType } from 'src/views/definitionBuilders/types';
import Spinner from 'src/views/components/Spinner';
import { getSubmitCloseModalActionGroupDef } from 'src/views/definitionBuilders/common';

type RailBookingItem = Operations.Domain.Queries.ViewRailBooking.RailBookingItem;
type JobForCancellingRailBooking = Operations.Domain.Queries.GetJobsForCancellingRailBooking.JobsForCancellingRailBookingDto.JobForCancellingRailBooking;
type JobsForCancellingRailBookingDto = Operations.Domain.Queries.GetJobsForCancellingRailBooking.JobsForCancellingRailBookingDto;

export const getCancelRailBookingModalDef = (
  railBooking: RailBookingItem | undefined,
  jobsForCancellingRailBooking: JobsForCancellingRailBookingDto | undefined,
  onCancelRailBooking: (cancellationReason: string) => Promise<void>
): ModalDefBuilder => {
  if (
    !railBooking ||
    !jobsForCancellingRailBooking ||
    railBooking.id !== jobsForCancellingRailBooking.railBookingId
  ) {
    return api => ({
      title: 'Loading',
      panels: [
        {
          panes: [
            {
              paneType: PaneType.customPane,
              render: d => <Spinner show />,
            },
          ],
        },
      ],
    });
  }

  const ignoredJobs = jobsForCancellingRailBooking.jobsToIgnore;
  const deletingJobs = jobsForCancellingRailBooking.jobsToDelete;
  const cancelInProgress = jobsForCancellingRailBooking.jobsToCancelInProgress;

  const enhancedRailBooking: RailBookingItem & {
    ignoredJobs: JobForCancellingRailBooking[];
    cancelInProgress: JobForCancellingRailBooking[];
    deletingJobs: JobForCancellingRailBooking[];
  } = {
    ...railBooking,
    ignoredJobs,
    cancelInProgress,
    deletingJobs,
  };

  const inProgressBooking =
    (ignoredJobs && ignoredJobs.length > 0) || (cancelInProgress && cancelInProgress.length > 0);

  return modalDefApi => ({
    title: inProgressBooking ? 'Cancel Booking In Progress' : 'Cancel Booking',
    explicitData: { ...enhancedRailBooking, cancelFutureBookingRecurrences: false },
    asForm: true,
    panels: [
      {
        panes: [
          {
            paneType: PaneType.customPane,
            render: api => inProgressBooking || <span>This Rail Booking will be Cancelled.</span>,
          },
          {
            paneType: PaneType.formFieldsPane,
            fields: [
              {
                fieldType: FieldType.textAreaField,
                label: 'Reason for Cancellation',
                dataAddr: 'cancellationReason',
                mandatory: true,
                maxLength: 200,
              },
            ],
          },
        ],
      },
      {
        panes: [
          {
            paneType: PaneType.nestingPane,
            spaceBetweenPanesVertically: true,
            panes: [
              {
                paneType: PaneType.tablePane,
                title: 'Jobs being Cancelled In Progress',
                dataAddr: 'cancelInProgress',
                noRowsMessage: 'No jobs will be cancelled',
                neverEditable: true,
                tableDescription: 'These jobs have been started and will be Cancelled In Progress.',
                fields: [
                  {
                    fieldType: FieldType.readonlyField,
                    dataAddr: 'jobNumber',
                    label: 'Job Number',
                    readonly: true,
                    linkTo: d => `/operations/jobs/${d.parentValue.id}`,
                  },
                  {
                    fieldType: FieldType.textField,
                    dataAddr: 'staffMemberName',
                    label: 'Staff Member',
                    readonly: true,
                  },
                  {
                    fieldType: FieldType.textField,
                    dataAddr: 'secondStaffMemberName',
                    label: 'Second Staff Member',
                    readonly: true,
                    formatReadonly: d => <span>{d.fieldValue || 'None Allocated'}</span>,
                  },
                  {
                    fieldType: FieldType.dateTimeField,
                    dataAddr: 'clockOn',
                    label: 'Clock On',
                    readonly: true,
                  },
                ],
              },
              {
                paneType: PaneType.tablePane,
                title: 'Jobs being Deleted',
                dataAddr: 'deletingJobs',
                noRowsMessage: 'No jobs will be deleted ',
                neverEditable: true,
                tableDescription: 'These jobs have not been started and will be deleted.',
                fields: [
                  {
                    fieldType: FieldType.readonlyField,
                    dataAddr: 'jobNumber',
                    label: 'Job Number',
                    linkTo: d => `/operations/jobs/${d.parentValue.id}`,
                  },
                  {
                    fieldType: FieldType.textField,
                    dataAddr: 'staffMemberName',
                    label: 'Staff Member',
                    readonly: true,
                    formatReadonly: d => <span>{d.fieldValue || 'None Allocated'}</span>,
                  },
                  {
                    fieldType: FieldType.textField,
                    dataAddr: 'secondStaffMemberName',
                    label: 'Second Staff Member',
                    readonly: true,
                    formatReadonly: d => <span>{d.fieldValue || 'None Allocated'}</span>,
                  },
                  {
                    fieldType: FieldType.dateTimeField,
                    dataAddr: 'clockOn',
                    label: 'Clock On',
                    readonly: true,
                  },
                ],
              },
              {
                paneType: PaneType.tablePane,
                title: 'Jobs being Ignored',
                dataAddr: 'ignoredJobs',
                noRowsMessage: 'No jobs',
                neverEditable: true,
                tableDescription:
                  'These jobs have been completed or already cancelled and will not be modified.',
                fields: [
                  {
                    fieldType: FieldType.readonlyField,
                    dataAddr: 'jobNumber',
                    label: 'Job Number',
                    readonly: true,
                    linkTo: d => `/operations/jobs/${d.parentValue.jobId}`,
                  },
                  {
                    fieldType: FieldType.textField,
                    dataAddr: 'staffMemberName',
                    label: 'Staff Member',
                    readonly: true,
                    formatReadonly: d => <span>{d.fieldValue || 'None Allocated'}</span>,
                  },
                  {
                    fieldType: FieldType.dateTimeField,
                    dataAddr: 'clockOn',
                    label: 'Clock On',
                    readonly: true,
                  },
                  {
                    fieldType: FieldType.readonlyField,
                    dataAddr: 'isCancelled',
                    label: 'Status',
                    formatReadonly: d => (
                      <span>{d.fieldValue === true ? 'Cancelled' : 'Completed'}</span>
                    ),
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
    secondaryActions: [getSubmitCloseModalActionGroupDef('Ok')],
    onFormSubmit: d => onCancelRailBooking(d.cancellationReason),
  });
};
