import { Component } from 'react';
import { RouteComponentProps, Link } from 'react-router-dom';
import {
  PagePrimarySize,
  PaneType,
  FieldType,
  ActionType,
  ShellModalSize,
  IModalDefBuilderApi,
  ISectionDef,
} from 'src/views/definitionBuilders/types';
import CrudPage, { CrudPageMode, ICrudPageDef } from 'src/views/components/Page/pages/CrudPage';
import { IMatchingPart } from 'src/domain/entities/workshop/parts/PartsModel';
import { IAutocompleteResult } from 'src/domain/baseTypes';
import { ChangeState } from 'src/api/enums';
import deepEqual from 'src/infrastructure/deepEqual';
import { FilePdfIcon, ChevronUpIcon, ChevronDownIcon } from 'src/images/icons';
import { DateTime } from 'luxon';
import {
  getActivationAndDeletionActionButtons,
  ISetInactiveCommand,
} from 'src/views/components/ActivationAndDeletionActionsButtons/getActivationAndDeletionActionButtons';
import { getSubmitCloseModalActionGroupDef } from 'src/views/definitionBuilders/common';
import saveAs from 'file-saver';

type AggregateUsageDto = Common.Dtos.AggregateUsageDto;
type CreatePartsGroupCommand = Workshop.Domain.Commands.Part.CreatePartsGroup.CreatePartsGroupCommand;
type UpdatePartsGroupCommand = Workshop.Domain.Commands.Part.UpdatePartsGroup.UpdatePartsGroupCommand;
type PartsGroupItem = Workshop.Domain.Queries.GetPartsGroup.PartsGroupItem;
type PartItem = Workshop.Domain.Queries.GetPartsGroup.PartsGroupItem.PartItem;
type EngineItem = Workshop.Domain.Queries.GetPartsGroupEngines.EngineItem;
type WorkshopDepot = Common.Queries.Workshop.GetWorkshopDepots.WorkshopDepotDto;
type ExportPartsGroupToPdfQuery = Workshop.Domain.Queries.Parts.GeneratePartsGroupPdf.ExportPartsGroupToPdfQuery;

export interface IMaintainPartsGroupProps {
  mode: CrudPageMode;
  canManagePartsGroups: boolean;
  partsGroup: PartsGroupItem | undefined;
  onLoadPartsGroup: (id: string) => Promise<void>;
  checkForUniquePartsGroupName: (name: string) => Promise<boolean>;
  onCreatePartsGroup: (command: CreatePartsGroupCommand) => Promise<void>;
  onUpdatePartsGroup: (command: UpdatePartsGroupCommand) => Promise<void>;
  searchParts: (search: string) => Promise<IAutocompleteResult<IMatchingPart>>;
  exportToPdf: (query: Partial<ExportPartsGroupToPdfQuery>) => Promise<Blob>;
  engines: EngineItem[];
  onLoadPartsGroupEngines: (id: string) => Promise<void>;
  setPartsGroupInactive: (command: ISetInactiveCommand) => Promise<void>;
  setPartsGroupActive: (command: ISetInactiveCommand) => Promise<void>;
  deletePartsGroup: (id: string) => Promise<void>;
  getPartsGroupUsage: (id: string) => Promise<void>;
  partsGroupUsage: AggregateUsageDto | undefined;
  workshopDepots: Array<WorkshopDepot>;
}

interface IUpdatePartsGroupRouteParams {
  id: string;
}

type InternalProps = IMaintainPartsGroupProps & RouteComponentProps<IUpdatePartsGroupRouteParams>;

class MaintainPartsGroup extends Component<InternalProps> {
  private get isUpdateMode() {
    return this.props.mode === 'update';
  }

  private get isCreateMode() {
    return this.props.mode === 'create';
  }

  private get partsGroupId() {
    return this.props.match.params.id;
  }

  private readonly savePdf = async (groupName: string, housingLocationIds: number[]) => {
    if (!this.props.partsGroup) {
      return;
    }

    const partsGroupId = this.props.partsGroup.id;
    const fileName = `PartsGroup_${groupName}_${DateTime.local().toFormat('yyyyMMddHHmm')}.pdf`;
    return await this.props
      .exportToPdf({ partsGroupId, housingLocationIds })
      .then(blob => saveAs(blob, fileName));
  };

  private handlePreSubmitForCreate = (partsGroup: PartsGroupItem): CreatePartsGroupCommand => {
    return {
      name: partsGroup.name,
      description: partsGroup.description,
      items: partsGroup.items.map((item, idx) => {
        return {
          partId: item.part.id,
          quantity: item.quantity,
          order: idx,
        };
      }),
    };
  };

  private handlePreSubmitForUpdate = (partsGroup: PartsGroupItem): UpdatePartsGroupCommand => {
    const originalPartsGroup = this.props.partsGroup;
    const getPartChangeState = (i: PartItem) => {
      if (i.changeState === ChangeState.Added || i.changeState === ChangeState.Deleted) {
        return i.changeState;
      }
      const originalItem = originalPartsGroup && originalPartsGroup.items.find(x => x.id === i.id);
      if (!originalItem) {
        throw new Error('Cannot find original Parts Group Part');
      }
      return deepEqual(i, originalItem) ? ChangeState.Unchanged : ChangeState.Modified;
    };
    return {
      id: partsGroup.id,
      name: partsGroup.name,
      description: partsGroup.description,
      items: partsGroup.items.map((item, idx) => {
        return {
          id: item.id,
          partId: item.part.id,
          quantity: item.quantity,
          changeState: getPartChangeState(item),
          order: idx,
        };
      }),
    };
  };

  private readonly getPdfExportModal = (
    groupName: string
  ): ((modalDefApi: IModalDefBuilderApi) => ISectionDef) => {
    return modalDefApi => ({
      title: 'Export',
      asForm: true,
      panels: [
        {
          panes: [
            {
              paneType: PaneType.customPane,
              render: api => (
                <p>Choose the depot to display the relevant locations on the export.</p>
              ),
            },
            {
              paneType: PaneType.formFieldsPane,
              fields: [
                {
                  fieldType: FieldType.selectMultiField,
                  label: 'Depot',
                  dataAddr: 'workshopDepots',
                  optionItems: this.props.workshopDepots,
                  useValueOnly: true,
                  valueKey: 'id',
                  descriptionKey: 'description',
                },
              ],
            },
          ],
        },
      ],
      secondaryActions: [getSubmitCloseModalActionGroupDef('Ok')],
      onFormSubmit: v => this.savePdf(groupName, v.workshopDepots),
    });
  };

  private readonly getPageDef = (updating: boolean): ICrudPageDef => {
    const {
      checkForUniquePartsGroupName,
      partsGroup: loadedPartsGroup,
      canManagePartsGroups,
      workshopDepots,
    } = this.props;
    const partsGroupName = loadedPartsGroup && loadedPartsGroup.name;
    return {
      primarySize: PagePrimarySize.threeQuarters,
      primarySection: {
        title: this.isUpdateMode ? `Manage Parts Group` : 'Create a Parts Group',
        badge:
          !this.isUpdateMode || loadedPartsGroup?.active
            ? undefined
            : {
                label: 'Inactive',
              },
        primaryActions:
          !updating && !this.isCreateMode
            ? [
                {
                  actions: [
                    {
                      hidden: () => !canManagePartsGroups,
                      actionType: ActionType.actionCollection,
                      actionGroups: [
                        {
                          actions: [
                            {
                              actionType: ActionType.actionButton,
                              label: 'Export',
                              hidden:
                                (this.isUpdateMode && updating) || workshopDepots?.length !== 1,
                              icon: <FilePdfIcon fixedWidth />,
                              onClick: _ => {
                                if (!this.props.partsGroup) {
                                  return;
                                }

                                return this.savePdf(this.props.partsGroup.name, [
                                  workshopDepots[0].id,
                                ]);
                              },
                            },
                            {
                              actionType: ActionType.modalActionButton,
                              label: 'Export',
                              hidden: workshopDepots?.length < 2,
                              icon: <FilePdfIcon fixedWidth />,
                              modalDef: this.getPdfExportModal(partsGroupName ?? ''),
                              modalSize: ShellModalSize.oneThird,
                            },
                            ...getActivationAndDeletionActionButtons(
                              this.partsGroupId,
                              'Parts Group',
                              this.props.partsGroup?.active,
                              this.props.setPartsGroupActive,
                              this.props.setPartsGroupInactive,
                              this.props.deletePartsGroup,
                              this.props.partsGroupUsage,
                              this.props.onLoadPartsGroup
                            ),
                          ],
                        },
                      ],
                    },
                  ],
                },
              ]
            : [],
        panels: [
          {
            panes: [
              {
                paneType: PaneType.formFieldsPane,
                fields: [
                  {
                    fieldType: FieldType.textField,
                    label: 'Name',
                    dataAddr: 'name',
                    maxLength: 50,
                    mandatory: true,
                    validateAsync: async d => {
                      if (
                        !d.fieldValue ||
                        (this.isUpdateMode &&
                          loadedPartsGroup &&
                          loadedPartsGroup.name.toUpperCase() === d.fieldValue.toUpperCase())
                      ) {
                        return undefined;
                      }
                      const result = await checkForUniquePartsGroupName(d.fieldValue);
                      return result ? 'This name is already in use' : undefined;
                    },
                  },
                  {
                    fieldType: FieldType.textField,
                    label: 'Description',
                    dataAddr: 'description',
                    maxLength: 200,
                    mandatory: true,
                  },
                ],
              },
            ],
          },
          {
            title: 'Parts',
            dataAddr: 'items',
            panes: [
              {
                paneType: PaneType.tablePane,
                mandatory: true,
                dataRequiredForRows: 'paneValue',
                validate: d => {
                  if (!d.panelValue) {
                    return undefined;
                  }
                  const items = (d.panelValue as PartItem[]).filter(
                    i => i.changeState !== ChangeState.Deleted
                  );
                  const distinctParts = new Set(items.map(i => i.part && i.part.id));
                  return distinctParts.size !== items.length
                    ? 'Each part can only be defined once'
                    : undefined;
                },
                fields: [
                  {
                    fieldType: FieldType.selectAsyncField,
                    label: 'Part Number',
                    dataAddr: 'part',
                    valueKey: 'id',
                    descriptionKey: 'partNumber',
                    mandatory: true,
                    loadOptionItems: this.props.searchParts,
                    valuesToDisable: d => {
                      const items = (d.paneValue as PartItem[])
                        .filter(x => x.changeState !== ChangeState.Deleted)
                        .map(x => x.part && x.part.id);
                      return items;
                    },
                    optionRenderer: (o: IMatchingPart) => (
                      <div
                        style={{
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                        }}>
                        <span>
                          {o.partNumber} - {o.description}
                        </span>
                      </div>
                    ),
                    useOptionRendererAsValueRenderer: true,
                    formatReadonly: d => {
                      var part = d.parentValue.part as IMatchingPart;
                      if (!part) {
                        return;
                      }

                      return (
                        <span>
                          <Link to={'/workshop/parts/' + part.id}>{part.partNumber}</Link>
                          <span> - {part.description}</span>
                        </span>
                      );
                    },
                  },
                  {
                    fieldType: FieldType.numericField,
                    label: 'Quantity',
                    dataAddr: 'quantity',
                    columnWidth: '11em',
                    numericConfig: {
                      numericType: 'unsignedDecimal',
                      maxPointDigits: 4,
                    },
                    mandatory: true,
                    validate: d =>
                      d.fieldValue && Number.parseFloat(d.fieldValue.toString()) === 0
                        ? 'Quantity cannot be zero'
                        : undefined,
                  },
                  {
                    fieldType: FieldType.actionListField,
                    dataAddr: '',
                    columnWidth: '120px',
                    hidden: _ => this.isUpdateMode && !updating,
                    columnAutoHide: true,
                    actionGroups: [
                      {
                        actions: [
                          {
                            actionType: ActionType.moveArrayItemActionButton,
                            label: 'Move up',
                            icon: <ChevronUpIcon />,
                            moveDirection: 'prev',
                            hidden: d =>
                              (this.isUpdateMode && !updating) ||
                              (d.paneValue as Array<{}>).indexOf(d.parentValue) === 0,
                          },
                          {
                            actionType: ActionType.moveArrayItemActionButton,
                            label: 'Move down',
                            icon: <ChevronDownIcon />,
                            moveDirection: 'next',
                            hidden: d =>
                              (this.isUpdateMode && !updating) ||
                              (d.paneValue as Array<{}>).indexOf(d.parentValue) ===
                                (d.paneValue as Array<{}>).length - 1,
                          },
                          {
                            hidden: _ => this.isUpdateMode && !updating,
                            actionType: ActionType.removeArrayItemActionButton,
                            label: 'Remove Line',
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
              {
                paneType: PaneType.actionListPane,
                hidden: this.isUpdateMode && !updating,
                actionGroups: [
                  {
                    actions: [
                      {
                        actionType: ActionType.addArrayItemActionButton,
                        label: 'Add Part',
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
        onFormPreSubmit: this.isUpdateMode
          ? this.handlePreSubmitForUpdate
          : this.handlePreSubmitForCreate,
        onFormSubmit: this.isUpdateMode
          ? this.props.onUpdatePartsGroup
          : this.props.onCreatePartsGroup,
      },
      secondarySections: [
        {
          title: 'Linked Engines',
          explicitData: this.props.engines,
          isTab: true,
          panels: [
            {
              panes: [
                {
                  paneType: PaneType.tablePane,
                  fields: [
                    {
                      fieldType: FieldType.textField,
                      dataAddr: 'model',
                      label: 'Engine Model',
                      linkTo: d => `/workshop/engines/${d.parentValue.id}`,
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    };
  };

  getData = () => {
    return Promise.all([
      this.props.onLoadPartsGroup(this.partsGroupId),
      this.props.onLoadPartsGroupEngines(this.partsGroupId),
      this.props.getPartsGroupUsage(this.partsGroupId),
    ]).then(() => {
      return;
    });
  };

  render() {
    const { mode, partsGroup, canManagePartsGroups } = this.props;
    return (
      <CrudPage
        def={({ updating }) => this.getPageDef(updating)}
        mode={mode}
        isEditingForbidden={!canManagePartsGroups}
        onLoadData={() => this.getData()}
        data={partsGroup}
        createDefaultData={{}}
      />
    );
  }
}

export default MaintainPartsGroup;
