import { types, flow, getRoot } from 'mobx-state-tree';
import { NotificationType, getAjax } from 'src/domain/services';
import { ListGenerateJobsOperationsModel } from './ListGenerateJobsOperationsModel';
import { IRootStoreModel } from 'src/domain/entities/RootStoreModel';

type GenerateJobsCommand = Operations.Domain.Commands.Job.GenerateJobs.GenerateJobsCommand;

type GenerateJobsOperationDetails = Operations.Domain.Queries.GetGenerateJobsOperation.GenerateJobsOperationDetails;
const returnVoid = () => {};
export const GenerateJobsOperationModel = types
  .model('GenerateJobsOperationModel', {
    list: types.optional(ListGenerateJobsOperationsModel, {}),
    item: types.maybe(types.frozen<GenerateJobsOperationDetails>()),
  })
  .actions(self => {
    const ajax = getAjax(self);
    const root = getRoot(self) as IRootStoreModel;

    const createGenerateJobsOperation = flow(function*(command: GenerateJobsCommand) {
      yield ajax.operations.generateJobsOperations.createGenerateJobsOperation(command);
      root.history.push(`/operations/generate-jobs-operations`);
    });

    const acknowledgeFailedGenerateJobsOperation = flow(function*(generateJobsOperationId: string) {
      yield ajax.operations.generateJobsOperations.acknowledgeFailedGenerateJobsOperation(
        generateJobsOperationId
      );
    });

    const getGenerateJobsOperationDetails = flow(function*(id: string) {
      self.item = yield ajax.operations.generateJobsOperations.getGenerateJobsOperationDetails(id);
    });

    const deleteGeneratedJobs = flow(function*(
      generateJobsOperationId: string,
      batchNumber: string
    ) {
      root.notifications.addNotification(`Jobs are being deleted ...`, {
        type: NotificationType.info,
      });
      yield ajax.operations.generateJobsOperations
        .deleteGeneratedJobs(generateJobsOperationId)
        .then(returnVoid);
      root.history.push(`/operations/generate-jobs-operations`);
      root.notifications.addNotification(`Jobs have been deleted from batch  ${batchNumber}`, {
        type: NotificationType.success,
      });
    });

    return {
      createGenerateJobsOperation,
      acknowledgeFailedGenerateJobsOperation,
      getGenerateJobsOperationDetails,
      deleteGeneratedJobs,
    };
  });
