import inject from 'src/views/injectFromStore';
import ListExternalIdentities, { IListExternalIdentitiesProps } from './ListExternalIdentities';

const Container = inject<IListExternalIdentitiesProps>((allStores, p) => ({
  canManageExternalIdentities: allStores.rootStore.account.canAdministerExternalIdentities,
  loadExternalIdentityProviders:
    allStores.rootStore.people.externalIdentities.loadExternalIdentityProviders,
  externalIdentityProviders: allStores.rootStore.people.externalIdentities.externalIdentityProviders.slice(),
  staffMembers: allStores.rootStore.people.staffMembers.allStaffMembers.slice(),
  loadStaffMembers: allStores.rootStore.people.staffMembers.loadAllStaffMembers,
  loadExternalIdentities: allStores.rootStore.people.externalIdentities.listItems,
  externalIdentities: allStores.rootStore.people.externalIdentities.items.slice(),
  updateExternalIdentity: allStores.rootStore.people.externalIdentities.updateExternalIdentity,
  deleteExternalIdentity: allStores.rootStore.people.externalIdentities.deleteExternalIdentity,
  hasMoreData: allStores.rootStore.people.externalIdentities.hasMoreItems,
}))(ListExternalIdentities);

export default Container;
