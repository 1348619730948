import { IAjax } from 'src/infrastructure/ajax';
import { workshopUrls } from 'src/domain/services/apiUrls';

// tslint:disable-next-line:no-empty
const returnVoid = () => {};

export default function init(ajax: IAjax) {
  function listOwners(query: Partial<Workshop.Domain.Queries.Owners.GetOwners.ListOwnersQuery>) {
    return ajax
      .get(workshopUrls.ownerUrls.listOwners(query))
      .map(r => r.response as Common.Dtos.ListResult<Workshop.Domain.Queries.Owners.OwnerListItem>);
  }

  function listAllOwners() {
    return ajax
      .get(workshopUrls.ownerUrls.listAllOwners())
      .map(r => r.response as Workshop.Domain.Queries.Owners.GetAllOwners.OwnerSelectListItem[]);
  }

  function checkForUniqueName(name: string) {
    return ajax
      .get(workshopUrls.ownerUrls.checkForUniqueName(name))
      .toPromise()
      .then<Common.Dtos.UniqueNameCheckResultDto>(r => r.response);
  }

  function createOwner(command: Workshop.Domain.Commands.Owner.CreateOwnerCommand) {
    return ajax
      .post(workshopUrls.ownerUrls.createOwner(), command)
      .toPromise()
      .then<string>(r => r.response);
  }

  function getOwner(id: string) {
    return ajax
      .get(workshopUrls.ownerUrls.getOwner(id))
      .toPromise()
      .then<Workshop.Domain.Queries.GetAsset.AssetDetails>(r => r.response);
  }

  function canDeleteOwner(id: string) {
    return ajax
      .get(workshopUrls.ownerUrls.canDeleteOwner(id))
      .toPromise()
      .then<Workshop.Domain.Queries.Owners.GetCanOwnerBeDeleted.GetCanOwnerBeDeletedQueryResult>(
        r => r.response
      );
  }

  function updateOwner(command: Workshop.Domain.Commands.Owner.UpdateOwnerCommand) {
    return ajax
      .put(workshopUrls.ownerUrls.updateOwner(command.id), command)
      .toPromise()
      .then(r => returnVoid());
  }

  function createOwnerContact(command: Workshop.Domain.Commands.Owner.CreateOwnerContactCommand) {
    return ajax
      .post(workshopUrls.ownerUrls.createOwnerContact(command.ownerId), command)
      .toPromise()
      .then(r => returnVoid());
  }

  function updateOwnerContact(command: Workshop.Domain.Commands.Owner.UpdateOwnerContactCommand) {
    return ajax
      .put(workshopUrls.ownerUrls.updateOwnerContact(command.ownerId, command.contactId), command)
      .toPromise()
      .then(r => returnVoid());
  }

  function listContactsForOwner(ownerId: string) {
    return ajax
      .get(workshopUrls.ownerUrls.listContactsForOwner(ownerId))
      .toPromise()
      .then<Workshop.Domain.Queries.Owners.GetContactsForOwner.ContactItem>(r => r.response);
  }

  function createOwnerNote(command: Workshop.Domain.Commands.Owner.CreateOwnerNoteCommand) {
    return ajax
      .post(workshopUrls.ownerUrls.createOwnerNote(command.ownerId), command)
      .toPromise()
      .then(r => returnVoid());
  }

  function updateOwnerNote(command: Workshop.Domain.Commands.Owner.UpdateOwnerNoteCommand) {
    return ajax
      .put(workshopUrls.ownerUrls.updateOwnerNote(command.ownerId, command.noteId), command)
      .toPromise()
      .then(r => returnVoid());
  }

  function deleteOwner(ownerId: string) {
    return ajax
      .httpDelete(workshopUrls.ownerUrls.deleteOwner(ownerId))
      .toPromise()
      .then(r => returnVoid());
  }

  function updateOwnerSetActive(ownerId: string) {
    return ajax
      .put(workshopUrls.ownerUrls.updateOwnerSetActive(ownerId))
      .toPromise()
      .then(r => returnVoid());
  }

  function updateOwnerSetInactive(ownerId: string) {
    return ajax
      .put(workshopUrls.ownerUrls.updateOwnerSetInactive(ownerId))
      .toPromise()
      .then(r => returnVoid());
  }

  return {
    owner: {
      listOwners,
      listAllOwners,
      checkForUniqueName,
      createOwner,
      getOwner,
      updateOwner,
      createOwnerContact,
      updateOwnerContact,
      listContactsForOwner,
      createOwnerNote,
      updateOwnerNote,
      deleteOwner,
      canDeleteOwner,
      updateOwnerSetActive,
      updateOwnerSetInactive,
    },
  };
}
