import { buildListPageApiSearchModelTypedQuery } from 'src/domain/modelBuilders/buildListPageApiSearchModel';

type ListShiftTemplatesQuery = Workshop.Domain.Queries.ShiftTemplate.ListShiftTemplates.ListShiftTemplatesQuery;

const ListShiftTemplatesModel = buildListPageApiSearchModelTypedQuery<ListShiftTemplatesQuery>()(
  'ListShiftTemplatesModel',
  d => d.ajax.workshop.shiftTemplate.listShiftTemplates(d.query),
  {
    size: 9999,
  }
);

export const ShiftTemplatesModel = ListShiftTemplatesModel;
