import inject from 'src/views/injectFromStore';
import ListJobProgressWarnings, { IListJobProgressWarningsProps } from './ListJobProgressWarnings';

const Container = inject<IListJobProgressWarningsProps>((allStores, p) => ({
  canManageJobs: allStores.rootStore.account.isOperationsDepartmentMember,
  listJobProgressWarnings: allStores.rootStore.operations.job.listJobProgressWarnings.listItems,
  jobProgressWarnings: allStores.rootStore.operations.job.listJobProgressWarnings.items.slice(),
  hasMoreData: allStores.rootStore.operations.job.listJobProgressWarnings.hasMoreItems,
  fleetAssets: allStores.rootStore.assets.fleetAssetListItems.slice(),
  loadFleetAssets: allStores.rootStore.assets.loadFleetAssets,
  activeStaffMembers: allStores.rootStore.people.staffMembers.activeStaffMembers.slice(),
  loadAllStaffMembers: allStores.rootStore.people.staffMembers.loadAllStaffMembers,
  exportToExcel: allStores.rootStore.operations.job.listJobProgressWarnings.exportToExcel,
  dismissProgressWarning: allStores.rootStore.operations.job.dismissProgressWarning,
}))(ListJobProgressWarnings);

export default Container;
