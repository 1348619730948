import styles from './getRosterDiffPaneDef.module.scss';
import { getDisplayDays } from 'src/views/routes/operations/urban/urbanHelpers';
import { AmendedHoursFormat } from 'src/views/components/AmendedHoursFormat';
import { NotEqualsIcon, PlusIcon, MinusIcon } from 'src/images/icons';
import { ChangeState } from 'src/api/enums';
import { ITablePaneDef, PaneType, FieldType } from 'src/views/definitionBuilders/types';
type RosterChangesConsolidatedShiftItem = Workshop.Domain.Queries.Roster.GetRosterChanges.RosterChangesItem.RosterChangesConsolidatedShiftItem;

const daysForDisplay = (shift: RosterChangesConsolidatedShiftItem): string => {
  if (shift) {
    const displayDays = getDisplayDays(shift);
    return displayDays.join(', ');
  }
  return '';
};

const staffMembersForDisplay = (shift: RosterChangesConsolidatedShiftItem): string => {
  if (shift && shift.staffMembers) {
    const displayMembers = shift.staffMembers.map(x => x.staffMemberFullName);
    return displayMembers.join(', ');
  }
  return '';
};

const DiffIcon: React.FC<{ changeState: ChangeState }> = ({ changeState }) => {
  switch (changeState) {
    case ChangeState.Modified:
      return (
        <span className={styles.modified}>
          <NotEqualsIcon />
        </span>
      );
    case ChangeState.Added:
      return (
        <span className={styles.added}>
          <PlusIcon />
        </span>
      );
    case ChangeState.Deleted:
      return (
        <span className={styles.deleted}>
          <MinusIcon />
        </span>
      );
    default:
      return <></>;
  }
};

export default function getRosterDiffPaneDef(diffDataAddr: string): ITablePaneDef {
  return {
    paneType: PaneType.tablePane,
    dataAddr: diffDataAddr,
    fields: [
      {
        fieldType: FieldType.customField,
        dataAddr: 'changeState',
        label: '',
        render: d => <DiffIcon changeState={d.data.fieldValue} />,
      },
      {
        fieldType: FieldType.customField,
        dataAddr: 'shiftName',
        label: 'Name',
        render: d => <strong>{d.data.fieldValue}</strong>,
      },
      {
        fieldType: FieldType.customField,
        dataAddr: 'none',
        label: 'Times',
        render: d => {
          const consolidatedShift = d.data.parentValue as RosterChangesConsolidatedShiftItem;
          return (
            <div className={styles.timesContainer}>
              <AmendedHoursFormat
                hours={consolidatedShift.originalShiftCommence || consolidatedShift.shiftCommence}
                amendedHours={consolidatedShift.shiftCommence}
              />
              <div className={styles.to}>to</div>
              <AmendedHoursFormat
                hours={consolidatedShift.originalShiftEnd || consolidatedShift.shiftEnd}
                amendedHours={consolidatedShift.shiftEnd}
              />
            </div>
          );
        },
      },
      {
        fieldType: FieldType.customField,
        dataAddr: 'none',
        label: 'Breaks',
        render: d => {
          const consolidatedShift = d.data.parentValue as RosterChangesConsolidatedShiftItem;
          return (
            <AmendedHoursFormat
              hours={consolidatedShift.originalUnpaidBreaks || consolidatedShift.unpaidBreaks}
              amendedHours={consolidatedShift.unpaidBreaks}
            />
          );
        },
      },
      {
        fieldType: FieldType.customField,
        dataAddr: 'none',
        label: 'Days',
        render: d => {
          const consolidatedShift = d.data.parentValue as RosterChangesConsolidatedShiftItem;
          return daysForDisplay(consolidatedShift);
        },
      },
      {
        fieldType: FieldType.customField,
        dataAddr: 'none',
        label: 'Staff Members',
        render: d => {
          const consolidatedShift = d.data.parentValue as RosterChangesConsolidatedShiftItem;
          return staffMembersForDisplay(consolidatedShift);
        },
      },
    ],
  };
}
