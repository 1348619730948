import { flow, types } from 'mobx-state-tree';
import { getAjax } from 'src/domain/services';

type MachineryScheduleTemplateItem = Workshop.Domain.Queries.MachineryScheduleTemplate.MachineryScheduleTemplateItem;

export const MachineryScheduleTemplateModel = types
  .model('MachineryScheduleTemplateModel', {
    item: types.array(types.frozen<MachineryScheduleTemplateItem[]>()),
  })
  .actions(self => {
    const ajax = getAjax(self);

    const loadMachinerySchedule = flow(function*() {
      self.item = yield ajax.workshop.machineryScheduleTemplate.loadMachinerySchedule();
    });

    const updateMachinerySchedule = flow(function*(
      command: Workshop.Domain.Commands.MachineryScheduleTemplate.UpdateMachineryScheduleTemplateCommand
    ) {
      yield ajax.workshop.machineryScheduleTemplate.updateMachinerySchedule(command);
      loadMachinerySchedule();
    });

    return {
      loadMachinerySchedule,
      updateMachinerySchedule,
    };
  });
