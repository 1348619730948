import './CreateLeave.scss';
import CreateLeaveForm from 'src/views/kioskRoutes/components/connected/CreateLeaveForm/CreateLeaveFormContainer';
import Header from '../../components/presentational/Header/Header';

export interface ICreateLeaveProps {
  canUseTimesheets: boolean | undefined;
}

const CreateLeave: React.FC<ICreateLeaveProps> = props => (
  <div className="create-leave-component">
    <Header title="Request Leave" to="/kiosk" canUseTimesheets={props.canUseTimesheets} />
    <CreateLeaveForm />
  </div>
);

export default CreateLeave;
