import { ICalendarButtonDef } from 'src/views/definitionBuilders/types';
import CalendarPicker from 'src/views/components/Page/fields/subfields/CalendarPicker';

interface ICalendarButtonProps {
  actionDef: ICalendarButtonDef;
}

const CalendarButton: React.FC<ICalendarButtonProps> = (props: ICalendarButtonProps) => {
  const { onChange, today, value } = props.actionDef;
  return <CalendarPicker onChange={onChange} today={today} value={value} />;
};

export default CalendarButton;
