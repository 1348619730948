import { types, flow } from 'mobx-state-tree';
import { getAjax } from 'src/domain/services';
type EmploymentContractCodeDto = Common.Dtos.EmploymentContractCodeDto;

export const EmploymentContractCodeModel = types
  .model('EmploymentContractCodeModel', {
    employmentContractCodes: types.array(types.frozen<EmploymentContractCodeDto>()),
  })
  .actions(self => {
    const ajax = getAjax(self);

    const loadEmploymentContractCodes = flow(function*() {
      self.employmentContractCodes = yield ajax.people.employmentContractCodes.getEmploymentContractCodes();
    });

    return {
      loadEmploymentContractCodes,
    };
  });

type IEmploymentContractCodeModelType = typeof EmploymentContractCodeModel.Type;
export interface IEmploymentContractCodeModel extends IEmploymentContractCodeModelType {}
