import {
  ISectionDef,
  PaneType,
  FieldType,
  ActionType,
  ShellModalSize,
} from 'src/views/definitionBuilders/types';
import { EditIcon } from 'src/images/icons';
import getCreateScheduledBreakModalDef from './getCreateScheduledBreakModalDef';
import { formatDateTimeMed } from 'src/domain/dateHelper';
import { formattedWorkingJobHours } from '../../urban/urbanHelpers';

type JobScheduledBreaksResult = Operations.Domain.Queries.GetJobScheduledBreaks.JobScheduledBreaksResult;
type UpdateJobScheduledBreakCommand = Operations.Domain.Commands.Job.UpdateJobScheduledBreak.UpdateJobScheduledBreakCommand;
export type JobProps = {
  jobId: string | undefined;
  jobTypeId?: number;
  nonSplitJobsInTripCount: number;
  splitJobsInTripCount: number;
};

const titleText = 'Scheduled Breaks';

export default function getScheduledBreaksSectionDef(
  jobProps: JobProps,
  jobScheduledBreaks: JobScheduledBreaksResult | undefined,
  updating: boolean,
  hasTwoDrivers: boolean,
  updateScheduledBreaks: (command: UpdateJobScheduledBreakCommand) => Promise<void>,
  canUpdateAllForTrip: boolean,
  hidden: boolean,
  jobRoutes?: Operations.Domain.Queries.ViewJob.JobRouteItem[]
): ISectionDef {
  return {
    title: titleText,
    explicitData: {
      driverOne: jobScheduledBreaks?.scheduledBreaksDriver1 ?? [],
      driverTwo: jobScheduledBreaks?.scheduledBreaksDriver2 ?? [],
    },
    hidden,
    panels: [
      {
        panes: [
          {
            paneType: PaneType.tablePane,
            title: hasTwoDrivers ? 'Driver 1 Scheduled Breaks' : undefined,
            neverEditable: true,
            dataAddr: 'driverOne',
            fields: [
              {
                fieldType: FieldType.textField,
                dataAddr: ['breakStart'],
                label: 'Start',
                formatReadonly: data => {
                  if (!data.fieldValue) return undefined;

                  return formatDateTimeMed(data.fieldValue);
                },
              },
              {
                fieldType: FieldType.textField,
                dataAddr: ['breakEnd'],
                label: 'End',
                formatReadonly: data => {
                  if (!data.fieldValue) return undefined;

                  return formatDateTimeMed(data.fieldValue);
                },
              },
              {
                fieldType: FieldType.textField,
                dataAddr: ['totalBreakDuration'],
                label: 'Duration',
                formatReadonly: data => {
                  if (!data.fieldValue) return undefined;

                  return formattedWorkingJobHours(
                    data.parentValue.breakStart,
                    data.parentValue.breakEnd,
                    '00:00'
                  );
                },
              },
            ],
          },
          {
            paneType: PaneType.tablePane,
            hidden: !hasTwoDrivers,
            dataAddr: 'driverTwo',
            title: 'Driver 2 Scheduled Breaks',
            neverEditable: true,
            fields: [
              {
                fieldType: FieldType.textField,
                dataAddr: ['breakStart'],
                label: 'Start',
                formatReadonly: data => {
                  if (!data.fieldValue) return undefined;

                  return formatDateTimeMed(data.fieldValue);
                },
              },
              {
                fieldType: FieldType.textField,
                dataAddr: ['breakEnd'],
                label: 'End',
                formatReadonly: data => {
                  if (!data.fieldValue) return undefined;

                  return formatDateTimeMed(data.fieldValue);
                },
              },
              {
                fieldType: FieldType.textField,
                dataAddr: ['totalBreakDuration'],
                label: 'Duration',
                formatReadonly: data => {
                  if (!data.fieldValue) return undefined;
                  return formattedWorkingJobHours(
                    data.parentValue.breakStart,
                    data.parentValue.breakEnd,
                    '00:00'
                  );
                },
              },
            ],
          },
        ],
      },
    ],
    secondaryActions: [
      {
        actions: [
          {
            actionType: ActionType.modalActionButton,
            label: 'Edit Breaks',
            hidden: updating,
            icon: <EditIcon />,
            modalSize: ShellModalSize.twoThirds,
            modalDef: getCreateScheduledBreakModalDef(
              jobProps,
              jobScheduledBreaks,
              hasTwoDrivers,
              updateScheduledBreaks,
              canUpdateAllForTrip,
              jobRoutes
            ),
          },
        ],
      },
    ],
  };
}
