import { PaneType, FieldType, ModalDefBuilder } from 'src/views/definitionBuilders/types';
import { ChangeState } from 'src/api/enums';
import { DateTime } from 'luxon';
import { getSubmitCloseModalActionGroupDef } from 'src/views/definitionBuilders/common';

type PartsGroupItem = Workshop.Domain.Queries.GetPartsGroups.PartsGroupItem;
type PartItem = Workshop.Domain.Queries.GetPartsGroupParts.PartItem;
type JobTaskPartItem = Workshop.Domain.Queries.GetJobTask.JobTaskPartItem;
type PartSelectListItem = Workshop.Domain.Queries.GetJobTask.JobTaskPartItem.PartSelectListItem;

export default function getAddPartsGroupModalDef(
  partsGroups: Array<PartsGroupItem>,
  getPartsGroupParts: (partsGroupId: string) => Promise<PartItem[]>
): ModalDefBuilder {
  return modalDefApi => ({
    title: 'Add Parts Group',
    asForm: true,
    panels: [
      {
        panes: [
          {
            paneType: PaneType.formFieldsPane,
            fields: [
              {
                fieldType: FieldType.selectField,
                dataAddr: 'partsGroup',
                label: 'Parts Group',
                mandatory: true,
                optionItems: partsGroups,
                valueKey: 'id',
                descriptionKey: 'name',
                optionRenderer: (o: PartsGroupItem) => (
                  <div style={{ whiteSpace: 'nowrap' }}>
                    {o.name} - {o.description}
                  </div>
                ),
                useOptionRendererAsValueRenderer: true,
              },
            ],
          },
        ],
      },
    ],
    secondaryActions: [getSubmitCloseModalActionGroupDef('Ok')],
    onFormPreSubmit: v => v.partsGroup.id,
    onFormSubmit: (partsGroupId: string) => {
      return getPartsGroupParts(partsGroupId).then(partItems => {
        const newJobTaskParts = partItems.map(p => {
          return {
            changeState: ChangeState.Added,
            description: p.description,
            part: {
              id: p.id,
              description: p.description,
              partNumber: p.partNumber,
              latestPrice: p.latestPrice,
            } as PartSelectListItem,
            quantity: p.quantity,
            unitPrice: p.latestPrice,
            usedDate: DateTime.local().toISODate(),
          } as JobTaskPartItem;
        });
        const partsAddr = 'parts';
        const partsData = [...modalDefApi.parentFormApi.getValue(partsAddr), ...newJobTaskParts];
        modalDefApi.parentFormApi.setValue(partsAddr, partsData);
        return Promise.resolve();
      });
    },
  });
}
