import { IAjax } from 'src/infrastructure/ajax';
import { operationsUrls } from 'src/domain/services/apiUrls';

type BulkDriverAuthorizationUpdateOperationDetails = Operations.Domain.Queries.GetBulkDriverAuthorizationUpdateOperation.BulkDriverAuthorizationUpdateOperationDetails;

export default function init(ajax: IAjax) {
  function createBulkDriverAuthorizationUpdateOperation(file: string | Blob) {
    const formData = new FormData();
    formData.append('csvFile', file);
    return ajax
      .postFile(
        operationsUrls.scheduleJobsOperationUrls.createBulkDriverAuthorizationUpdateOperation(),
        formData
      )
      .toPromise()
      .then<string>(r => r.response);
  }

  function listBulkDriverAuthorizationUpdateOperations(
    query: Partial<
      Operations.Domain.Queries.ListBulkDriverAuthorizationUpdateOperations.ListBulkDriverAuthorizationUpdateOperationsQuery
    >
  ) {
    return ajax
      .get(
        operationsUrls.scheduleJobsOperationUrls.listBulkDriverAuthorizationUpdateOperations(query)
      )
      .map(
        r =>
          r.response as Common.Dtos.ListResult<
            Operations.Domain.Queries.ListBulkDriverAuthorizationUpdateOperations.BulkDriverAuthorizationUpdateOperationListItem
          >
      );
  }

  function getBulkDriverAuthorizationUpdateOperationDetails(id: string) {
    return ajax
      .get(
        operationsUrls.scheduleJobsOperationUrls.getBulkDriverAuthorizationUpdateOperationDetails(
          id
        )
      )
      .toPromise()
      .then<BulkDriverAuthorizationUpdateOperationDetails>(r => r.response);
  }

  return {
    bulkDriverAuthorizationUpdateOperation: {
      createBulkDriverAuthorizationUpdateOperation,
      listBulkDriverAuthorizationUpdateOperations,
      getBulkDriverAuthorizationUpdateOperationDetails,
    },
  };
}
