import { Link } from 'react-router-dom';
import PopupField from './PopupField';

type ContinuationJob = Operations.Domain.Queries.ListJobsForAllocations.JobItem.ContinuationJob;

export interface IContinuationFieldProps {
  className?: string;
  label: string;
  hidden?: boolean;
  job?: ContinuationJob;
}

const ContinuationField: React.FC<IContinuationFieldProps> = ({
  className,
  label,
  hidden,
  job,
}) => {
  return (
    <PopupField
      className={className}
      label={label}
      value={
        <div className="continuation-field-component">
          {job ? (
            <span>
              <span>{job.jobType.description}: </span>
              <Link to={`/operations/jobs/${job.jobId}`}>{job.description}</Link>
            </span>
          ) : (
            <em>None</em>
          )}
        </div>
      }
      hidden={hidden}
    />
  );
};

export default ContinuationField;
