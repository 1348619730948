import { IAjax } from 'src/infrastructure/ajax';
import { workshopUrls } from 'src/domain/services/apiUrls';

type ListDataFuelQuery = Workshop.Domain.Queries.DataFuel.ListDataFuel.ListDataFuelQuery;
type DataFuelListItem = Workshop.Domain.Queries.DataFuel.ListDataFuel.DataFuelListItem;
type ListDataFuelUploadQuery = Workshop.Domain.Queries.DataFuel.ListDataFuelUpload.ListDataFuelUploadQuery;
type DataFuelUploadListItem = Workshop.Domain.Queries.DataFuel.ListDataFuelUpload.DataFuelUploadListItem;
type UpdateDataFuelEntryCommand = Workshop.Domain.Commands.DataFuel.UpdateDataFuelEntry.UpdateDataFuelEntryCommand;
type UpdateDataFuelAssetsIgnoredCommand = Workshop.Domain.Commands.DataFuel.IgnoredAssets.UpdateDataFuelAssetsIgnoredCommand;

// tslint:disable-next-line:no-empty
const returnVoid = () => {};

export default function init(ajax: IAjax) {
  function listDataFuel(query: Partial<ListDataFuelQuery>) {
    return ajax
      .get(workshopUrls.dataFuel.list(query))
      .map(r => r.response as Common.Dtos.ListResult<DataFuelListItem>);
  }
  function listDataFuelUploads(query: Partial<ListDataFuelUploadQuery>) {
    return ajax
      .get(workshopUrls.dataFuel.listUploads(query))
      .map(r => r.response as Common.Dtos.ListResult<DataFuelUploadListItem>);
  }
  function uploadDataFuel(file: string | Blob) {
    const formData = new FormData();
    formData.append('body', file);

    return ajax
      .postFile(workshopUrls.dataFuel.uploadFile(), formData)
      .toPromise()
      .then(returnVoid);
  }

  function updateDataFuel(command: UpdateDataFuelEntryCommand) {
    return ajax
      .put(workshopUrls.dataFuel.updateDataFuel(command.id), command)
      .toPromise()
      .then(returnVoid);
  }

  function markUploadAsFixed(uploadId: string) {
    return ajax
      .put(workshopUrls.dataFuel.markUploadAsFixed(uploadId))
      .toPromise()
      .then(returnVoid);
  }

  function loadIgnoredAssets() {
    return ajax
      .get(workshopUrls.dataFuel.listIgnoredAssets)
      .toPromise()
      .then(r => r.response);
  }

  function updateIgnoredAssets(query: UpdateDataFuelAssetsIgnoredCommand) {
    return ajax
      .post(workshopUrls.dataFuel.updateIgnoredAssets(query), query)
      .toPromise()
      .then(returnVoid);
  }

  return {
    dataFuel: {
      listDataFuel,
      listDataFuelUploads,
      uploadDataFuel,
      updateDataFuel,
      markUploadAsFixed,
      loadIgnoredAssets,
      updateIgnoredAssets,
    },
  };
}
