import { precisionRound } from 'src/infrastructure/mathUtil';

export interface IPriceField {
  price: number;
  overridePrice?: number;
}

export interface ITaxablePriceField extends IPriceField {
  extraType: Operations.Domain.Queries.ViewExtraType.ExtraTypeItem;
}

function sum(values: IPriceField[]) {
  if (!values || !values.length) {
    return 0;
  }
  return values.reduce(
    (acc, value) =>
      acc +
      (isNumber(value.overridePrice) ? Number(value.overridePrice) : Number(value.price) || 0),
    0
  );
}

function isNumber(n: number | undefined): n is number {
  return n === 0 || !!n;
}

export function priceExGst(vehicles: IPriceField[], extras: ITaxablePriceField[]) {
  return sum(vehicles) + sum(extras);
}

export function addGst(price: number) {
  return precisionRound(price * 1.1, 2);
}

export function priceIncGst(vehicles: IPriceField[], extras: ITaxablePriceField[]) {
  const taxableExtras = extras.filter(e => e.extraType.isTaxable);
  const nonTaxableExtras = extras.filter(e => !e.extraType.isTaxable);

  const taxableSubtotal = sum(vehicles) + sum(taxableExtras);
  const nonTaxableSubtotal = sum(nonTaxableExtras);

  return addGst(taxableSubtotal) + nonTaxableSubtotal;
}
