import { FieldType, FieldDefs } from 'src/views/definitionBuilders/types';
import {
  splitSkillSpecs,
  ISplittedSkillSpecRequirements,
} from 'src/domain/entities/people/staffMember/SkillSpecsHelpers';
import { getPropertyName } from 'src/infrastructure/typeUtils';

type SkillSpecItem = Common.Dtos.SkillSpecItem;

export function getSkillSpecRequirementFieldDefs(skillSpecs: SkillSpecItem[]): FieldDefs[] {
  const groupedSkillSpecs = splitSkillSpecs(skillSpecs);
  return [
    {
      fieldType: FieldType.selectMultiField,
      label: 'Charter Skill Specs',
      dataAddr: getPropertyName<ISplittedSkillSpecRequirements>('requiredCharterSkillSpecIds'),
      useValueOnly: true,
      valueKey: getPropertyName<SkillSpecItem>('id'),
      descriptionKey: getPropertyName<SkillSpecItem>('description'),
      optionItems: groupedSkillSpecs.charterSkillSpecs,
    },
    {
      fieldType: FieldType.selectMultiField,
      label: 'Rail Skill Specs',
      dataAddr: getPropertyName<ISplittedSkillSpecRequirements>('requiredRailSkillSpecIds'),
      useValueOnly: true,
      valueKey: getPropertyName<SkillSpecItem>('id'),
      descriptionKey: getPropertyName<SkillSpecItem>('description'),
      optionItems: groupedSkillSpecs.railSkillSpecs,
    },
    {
      fieldType: FieldType.selectMultiField,
      label: 'Urban Skill Specs',
      dataAddr: getPropertyName<ISplittedSkillSpecRequirements>('requiredUrbanSkillSpecIds'),
      useValueOnly: true,
      valueKey: getPropertyName<SkillSpecItem>('id'),
      descriptionKey: getPropertyName<SkillSpecItem>('description'),
      optionItems: groupedSkillSpecs.urbanSkillSpecs,
    },
  ];
}
