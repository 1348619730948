import './FormFieldsPagePane.scss';
import { Component } from 'react';
import memoizeOne from 'src/infrastructure/memoizeOne';
import { IFormFieldsPaneDef, IPaneData, IPaneMeta } from 'src/views/definitionBuilders/types';
import PageField from 'src/views/components/Page/fields/PageField';
import { fieldNameAsKey } from 'src/views/components/Page/forms';

interface IFormFieldsPagePaneProps {
  paneDef: IFormFieldsPaneDef;
  paneData: IPaneData;
  paneMeta: IPaneMeta;
}

class FormFieldsPagePane extends Component<IFormFieldsPagePaneProps> {
  private readonly _metadataBuilders = new Map<React.ReactText, typeof getFieldMetadata>();

  render() {
    const { paneDef, paneData, paneMeta } = this.props;
    return (
      <div
        className="form-field-page-pane-component"
        style={{ gridTemplateColumns: '1fr '.repeat(paneDef.columnCount || 1) }}>
        {paneDef.fields.map((fieldDef, i) => {
          const key = fieldDef.dataAddr ? fieldNameAsKey(fieldDef.dataAddr) : i;
          const builder = this._metadataBuilders.get(key) || memoizeOne(getFieldMetadata);
          !this._metadataBuilders.has(key) && this._metadataBuilders.set(key, builder);

          return (
            <PageField
              key={key}
              fieldDef={fieldDef}
              fieldMeta={builder(i, paneMeta.readonly, paneMeta.autoFocus, paneMeta.formChange$)}
              paneData={paneData}
              parentValue={paneData.paneValue}
            />
          );
        })}
      </div>
    );
  }
}

export default FormFieldsPagePane;

function getFieldMetadata(
  idx: number,
  readonly: boolean,
  autoFocus: boolean,
  formChange$: IPaneMeta['formChange$']
) {
  return {
    readonly,
    hideLabel: false,
    noForm: false,
    autoFocus: autoFocus && idx === 0,
    formChange$,
  };
}
