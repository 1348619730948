import { Route, Switch, Redirect } from 'react-router-dom';
import ErrorBoundary from 'src/views/components/ErrorBoundary';
import Home from 'src/views/kioskRoutes/workshopKiosk/home/Home';
import AuthoriseDevice from 'src/views/kioskRoutes/components/connected/AuthoriseDevice/AuthoriseDeviceContainer';
import Login from 'src/views/kioskRoutes/workshopKiosk/login/LoginContainer';
import LoadingIndicator from 'src/views/components/FullPageLoadingIndicator';
import WorkshopKioskShell from 'src/views/kioskRoutes/workshopKiosk/components/layout/WorkshopKioskShell/WorkshopKioskShell';
import MechanicDeclaration from 'src/views/kioskRoutes/workshopKiosk/mechanicDeclaration/MechanicDeclaration';
import Menu from 'src/views/kioskRoutes/workshopKiosk/menu/Menu';
import Leave from './Leave/LeaveContainer';
import CreateDefect from './CreateDefect/CreateDefectContainer';
import JobDetails from './JobDetails/JobDetailsContainer';
import Timesheet from './Timesheet/Timesheet';
import TaskPage from './task/TaskPage';
import AssetPage from './Asset/AssetContainer';
import Shifts from './Shifts/ShiftsContainer';
import CreateTask from './CreateTask/CreateTaskContainer';
import CompleteShift from './Shifts/CompleteShift';

interface IAuthedRoutesProps {
  isClockedOn: boolean;
}

const AuthedRoutes: React.FC<IAuthedRoutesProps> = ({ isClockedOn }) => {
  if (!isClockedOn) {
    return (
      <Switch>
        <Route exact path="/wskiosk/mechanic-declaration" component={MechanicDeclaration} />;
        <Redirect to="/wskiosk/mechanic-declaration" />
      </Switch>
    );
  }

  return (
    <Switch>
      <Route exact path="/wskiosk" component={Home} />
      <Route exact path="/wskiosk/menu" component={Menu} />
      <Route exact path="/wskiosk/leave" component={Leave} />
      <Route exact path="/wskiosk/shifts" component={Shifts} />
      <Route exact path="/wskiosk/timesheet" component={Timesheet} />
      <Route exact path="/wskiosk/defect/create" component={CreateDefect} />
      <Route exact path="/wskiosk/task/create" component={CreateTask} />
      <Route exact path="/wskiosk/job/:id" component={JobDetails} />
      <Route exact path="/wskiosk/task/:id" component={TaskPage} />
      {/* Just go to the TaskPage for now, this might change */}
      <Route exact path="/wskiosk/defect/:id" component={TaskPage} />
      <Route exact path="/wskiosk/asset/:id" component={AssetPage} />
      <Route
        exact
        path="/wskiosk/complete-shift/:id"
        render={p => <CompleteShift mode="create" {...p} />}
      />
      <Route
        exact
        path="/wskiosk/complete-shift/:id/view"
        render={p => <CompleteShift mode="view" {...p} />}
      />
      <Redirect to="/wskiosk" />
    </Switch>
  );
};

const AnonRoutes: React.FC = () => (
  <Switch>
    <Route exact path="/wskiosk/authorise-device" component={AuthoriseDevice} />
    <Route exact path="/wskiosk/login" component={Login} />

    <Redirect to="/wskiosk/login" />
  </Switch>
);

export interface IRoutesProps extends IAuthedRoutesProps {
  isAuthenticated: boolean | undefined;
  isClockedOn: boolean;
}

const Routes: React.FC<IRoutesProps> = ({ isAuthenticated, isClockedOn }) => {
  const getRoutes = () => {
    switch (isAuthenticated) {
      case true:
        return <AuthedRoutes isClockedOn={isClockedOn} />;
      case false:
        return <AnonRoutes />;
      default:
        return <LoadingIndicator isLoading />;
    }
  };

  return (
    <WorkshopKioskShell>
      <ErrorBoundary>{getRoutes()}</ErrorBoundary>
    </WorkshopKioskShell>
  );
};

export default Routes;
