import { useEffect, useState } from 'react';
import styles from './Timesheet.module.scss';
import SplitScreen from '../components/layout/SplitScreen/SplitScreen';
import WithMenu from '../menu/WithMenu';
import { DateTime, Duration } from 'luxon';
import { TimesheetStatus, getTimesheetStatusDescriptor } from 'src/api/enums';
import TimesheetTable from 'src/views/kioskRoutes//components/presentational/Timesheet/TimesheetTable/TimesheetTable';
import PrintTimesheet from 'src/views/kioskRoutes/components/presentational/Timesheet/PrintTimesheet/PrintTimesheet';
import FormGroup from 'reactstrap/lib/FormGroup';
import Label from 'reactstrap/lib/Label';
import Input from 'reactstrap/lib/Input';
import cn from 'classnames';
import KblModal from 'src/views/components/KblModal';
import { SingleClickAwareButton } from '../../components/presentational/SingleClickAwareButton/SingleClickAwareButton';
import { observer } from 'mobx-react';
import { useKioskRootStore } from 'src/domain/entities/KioskRootStoreModel';
import {
  formatDateTimeShort,
  formatDayMonth,
  getPayPeriodInDays,
  getPayPeriodInWeeks,
  getWeekStart,
} from 'src/domain/dateHelper';
import WeekNavigation from '../../components/presentational/WeekNavigation/WeekNavigation';

const Timesheet: React.FC = observer(() => {
  const rootStore = useKioskRootStore();
  const timesheet = rootStore.kiosk.timesheet.timesheet;
  const prefilledTimesheet = rootStore.kiosk.timesheet.prefilledTimesheet;
  const timesheetWeekStart = rootStore.kiosk.timesheet.timesheetWeekStart;
  const createTimesheetForDriver = rootStore.kiosk.timesheet.createTimesheetForDriver;
  const refreshTimesheetForDriver = rootStore.kiosk.timesheet.refreshTimesheetForDriver;
  const updateTimesheetForDriver = rootStore.kiosk.timesheet.updateTimesheetForDriver;
  const isWorkshopKiosk = rootStore.isWorkshopKiosk;
  const home = rootStore.home;
  const loadTimesheetForDriverForPrinting =
    rootStore.kiosk.timesheet.loadTimesheetForDriverForPrinting;
  const week = Duration.fromObject({ days: getPayPeriodInDays() - 1 });
  const timesheetForPrinting = rootStore.kiosk.timesheet.timesheetForPrinting;
  const getTimesheetForDriver = rootStore.kiosk.timesheet.getTimesheetForDriver;
  const currentWeekStart = getWeekStart(DateTime.local());
  const [driverNotes, setDriverNotes] = useState<string>('');
  const [isUpdating, setIsUpdating] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [dates, setDates] = useState<DateTime[]>([currentWeekStart, currentWeekStart.plus(week)]);

  const isUpdateMode = !!timesheet;
  const isCreateMode = !timesheet;

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  useEffect(() => {
    getTimesheetForDriver(dates[0]);
  }, []);

  useEffect(() => {
    getTimesheetForDriver(dates[0]);
  }, [dates]);

  const createTimesheet = () => {
    if (timesheetWeekStart) {
      return createTimesheetForDriver({
        weekStart: timesheetWeekStart.toISODate(),
        staffMemberNotes: driverNotes,
      });
    } else {
      return Promise.resolve();
    }
  };

  const updateTimesheet = () => {
    if (timesheet) {
      return updateTimesheetForDriver(driverNotes).then(_ => {
        stopEdit(false);
      });
    } else {
      stopEdit(true);
      return Promise.resolve();
    }
  };

  const refreshTimesheet = () => {
    return refreshTimesheetForDriver().then(_ => toggleModal());
  };

  const startEdit = () => {
    setIsUpdating(true);
  };

  const stopEdit = (resetNotes: boolean) => {
    setIsUpdating(false);
    setDriverNotes(resetNotes ? (timesheet && timesheet.staffMemberNotes) || '' : driverNotes);
  };

  const timesheetActionButtons = () => {
    return (
      <div className={styles.timesheetActionButtons}>
        <div hidden={isCreateMode || isUpdating}>
          <div className={styles.buttonGroup}>
            <SingleClickAwareButton
              useReactStrapButton
              type="button"
              size="lg"
              onClick={() => toggleModal()}
              hidden={isCreateMode || timesheet!.status === TimesheetStatus.Processed}
              className={cn(styles.btn, styles.buttonSecondary)}>
              Refresh Timesheet
            </SingleClickAwareButton>
            <SingleClickAwareButton
              useReactStrapButton
              type="button"
              size="lg"
              onClick={() => startEdit()}
              hidden={isCreateMode || timesheet!.status === TimesheetStatus.Processed}
              className={styles.btn}>
              Edit
            </SingleClickAwareButton>
          </div>
        </div>
        <div hidden={isCreateMode || !isUpdating}>
          <div className={styles.buttonGroup}>
            <SingleClickAwareButton
              useReactStrapButton
              type="button"
              size="lg"
              color="outline-secondary"
              onClick={() => stopEdit(true)}
              className={styles.btn}>
              CANCEL
            </SingleClickAwareButton>
            <SingleClickAwareButton
              useReactStrapButton
              type="button"
              size="lg"
              className={styles.btn}
              onClick={() => updateTimesheet()}>
              SUBMIT
            </SingleClickAwareButton>
          </div>
        </div>

        <div hidden={isUpdateMode}>
          <SingleClickAwareButton
            useReactStrapButton
            type="button"
            size="lg"
            onClick={() => createTimesheet()}
            className={styles.btn}>
            Submit
          </SingleClickAwareButton>
        </div>
      </div>
    );
  };

  const left = (
    <div className={styles.left}>
      <WithMenu width={30} color="dark">
        <div className={styles.weekNav}>
          <WeekNavigation
            onChangeBack={() => {
              setDates([
                dates[0].plus({ weeks: -getPayPeriodInWeeks() }),
                dates[dates.length - 1].plus({ weeks: -getPayPeriodInWeeks() }),
              ]);
            }}
            onChangeForward={() => {
              setDates([
                dates[0].plus({ weeks: getPayPeriodInWeeks() }),
                dates[dates.length - 1].plus({ weeks: getPayPeriodInWeeks() }),
              ]);
            }}
            onChangeToday={() => {
              setDates([
                currentWeekStart,
                currentWeekStart.plus({ days: getPayPeriodInDays() - 1 }),
              ]);
            }}
            date={dates[0]}
            disabled={formatDateTimeShort(dates[0]) === formatDateTimeShort(currentWeekStart)}
          />
        </div>
        <div className={styles.timesheetDate}>
          <h2>
            {formatDayMonth(dates[0].toISODate())} -{' '}
            {formatDayMonth(dates[dates.length - 1].toISODate())}
          </h2>
          <span
            className={cn(
              styles.timesheetStatus,
              timesheet?.status
                ? styles[getTimesheetStatusDescriptor(timesheet?.status).name]
                : styles.notSubmitted
            )}>
            {timesheet?.status
              ? getTimesheetStatusDescriptor(timesheet?.status).name
              : 'Not Submitted'}
          </span>
        </div>
        {timesheet && (
          <PrintTimesheet
            loadDataAsync={loadTimesheetForDriverForPrinting}
            timesheetForPrinting={timesheetForPrinting}
          />
        )}
      </WithMenu>
    </div>
  );

  const right = (
    <div className={styles.right}>
      <div className={styles.rightMain}>
        <div className={styles.timesheetTable}>
          {timesheet ? (
            <TimesheetTable timesheet={timesheet} isWorkshopKiosk={!!isWorkshopKiosk} home={home} />
          ) : prefilledTimesheet ? (
            <TimesheetTable
              timesheet={prefilledTimesheet}
              isWorkshopKiosk={!!isWorkshopKiosk}
              home={home}
            />
          ) : (
            <span className={styles.noTimesheets}>No timesheets for this week</span>
          )}
        </div>
        <div className={styles.timesheetNotes}>
          <FormGroup>
            <Label for="driverNotes">Please add further information if needed</Label>
            <Input
              autoComplete="off"
              type="textarea"
              name="driverNotes"
              id="driverNotes"
              readOnly={isUpdateMode && !isUpdating}
              onChange={event => setDriverNotes(event.target.value)}
              value={driverNotes || ''}
              className={styles.driverNotesArea}
            />
          </FormGroup>
        </div>
        <div className={styles.timesheetLinks}>{timesheetActionButtons()}</div>
      </div>
    </div>
  );

  return (
    <div className={styles.timesheetComponent}>
      <SplitScreen left={left} right={right} leftWidth={30} />
      <KblModal
        className="modal-lg"
        isOpen={showModal}
        toggle={() => toggleModal()}
        header="Refresh timesheet"
        modalBody={
          <div className="refresh-timesheet-modal-component">
            <span>Are you sure you want to refresh the timesheet?</span>
          </div>
        }
        modalFooter={
          <>
            <SingleClickAwareButton
              useReactStrapButton
              color="outline-secondary"
              onClick={() => toggleModal()}>
              Cancel
            </SingleClickAwareButton>
            <SingleClickAwareButton
              useReactStrapButton
              color="primary"
              onClick={() => refreshTimesheet()}>
              Refresh timesheet
            </SingleClickAwareButton>{' '}
          </>
        }
      />
    </div>
  );
});
export default Timesheet;
