import { IRootStoreModel } from 'src/domain/entities/RootStoreModel';
import { UiRoute } from 'src/domain/services/buildUiRoutes';
import { UiRouteName } from 'src/domain/baseTypes';

export default function buildUiRoutes(store: IRootStoreModel) {
  const operationsTasksForDay = new UiRoute(
    UiRouteName.operationsTasksForDay,
    '/operations/tasks/',
    () => 'Tasks',
    false
  );

  return {
    [UiRouteName.operationsTasksForDay]: operationsTasksForDay,
  };
}
