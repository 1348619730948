import { FieldType, FieldDefs } from 'src/views/definitionBuilders/types/field';
import ListPage, { IListPageDef } from 'src/views/components/Page/pages/ListPage';
import { PagePrimarySize, PaneType } from 'src/views/definitionBuilders/types';
import TaskCardItem from 'src/views/components/TaskCard/TaskCardItem';
import { allHolidayType } from 'src/api/enums';
import { DateTime } from 'luxon';
import PrimaryTitle from 'src/views/components/Page/PrimaryTitle/PrimaryTitle';
import { observer } from 'mobx-react';
import { useRootStore } from 'src/domain/entities/RootStoreModel';

const ListHoliday: React.FC = observer(() => {
  const rootStore = useRootStore();
  const canManageHoliday =
    rootStore.account.isOperationsDepartmentMember || rootStore.account.isSalesDepartmentMember;
  const loadHolidays = rootStore.operations.holidaysListPage.listItems;
  const holidays = rootStore.operations.holidaysListPage.items.slice();
  const depots = rootStore.operationsStartup.operationsDepots.slice();

  const filterFieldDefs = {
    holidayType: {
      fieldType: FieldType.selectMultiField,
      dataAddr: 'holidayTypeIds',
      label: 'Holiday Type',
      valueKey: 'value',
      descriptionKey: 'description',
      optionItems: allHolidayType,
      useValueOnly: true,
    } as FieldDefs,
    depot: {
      fieldType: FieldType.selectMultiField,
      dataAddr: 'depotIds',
      label: 'Depot',
      valueKey: 'id',
      descriptionKey: 'description',
      optionItems: depots,
      useValueOnly: true,
    } as FieldDefs,
    dateFrom: {
      fieldType: FieldType.dateField,
      dataAddr: 'dateFrom',
      label: 'Date From',
      onBlur: api => {
        if (!api.formValues.dateTo) {
          api.setFormValue(['dateTo'], api.fieldData.fieldValue);
        }
        api.validateField(['dateTo']);
      },
    } as FieldDefs,
    dateTo: {
      fieldType: FieldType.dateField,
      dataAddr: 'dateTo',
      label: 'Date To',
      validate: d => {
        if (!d.fieldValue || !d.parentValue.dateFrom) {
          return undefined;
        }
        const from = DateTime.fromISO(d.parentValue.dateFrom);
        const to = DateTime.fromISO(d.fieldValue);
        return from > to ? 'Date To cannot be earlier than Date From' : undefined;
      },
    } as FieldDefs,
  };

  const getPageDef = (): IListPageDef => {
    const filterFieldDefsLookup = filterFieldDefs;

    const start = DateTime.local()
      .startOf('year')
      .toISODate();
    const end = DateTime.local()
      .startOf('year')
      .plus({ years: 1 })
      .plus({ days: -1 })
      .toISODate();

    return {
      primaryTitle: (
        <PrimaryTitle
          title="Holidays"
          link="https://www.notion.so/Public-School-Holidays-e16d53a597c94b89ae10af80bff15b76"></PrimaryTitle>
      ),
      primarySize: PagePrimarySize.full,
      onLoadData: loadHolidays,
      externalSearch: true,
      createLink: canManageHoliday ? TaskCardItem.operations.holidays.createHoliday : undefined,
      primaryFields: [
        {
          fieldType: FieldType.textField,
          dataAddr: 'description',
          label: 'Description',
          linkTo: d => `/operations/holidays/${d.parentValue.id}`,
        },
        {
          fieldType: FieldType.dateField,
          dataAddr: 'startDate',
          label: 'Start',
        },
        {
          fieldType: FieldType.dateField,
          dataAddr: 'endDate',
          label: 'End',
        },
        {
          fieldType: FieldType.textField,
          dataAddr: ['holidayType', 'description'],
          label: 'Holiday Type',
        },
        {
          fieldType: FieldType.selectMultiField,
          label: 'Depots',
          dataAddr: 'holidayDepots',
          valueKey: 'id',
          descriptionKey: 'description',
          optionItems: depots,
        },
      ],
      filterAction: {
        defaultValues: {
          dateFrom: start,
          dateTo: end,
        },
        filterFields: Object.keys(filterFieldDefsLookup).map(k => filterFieldDefsLookup[k]),
        filterDef: _ => [
          {
            panes: [
              {
                paneType: PaneType.formFieldsPane,
                fields: [filterFieldDefsLookup.holidayType, filterFieldDefsLookup.depot],
              },
              {
                paneType: PaneType.formFieldsPane,
                columnCount: 2,
                fields: [filterFieldDefsLookup.dateFrom, filterFieldDefsLookup.dateTo],
              },
            ],
          },
        ],
      },
    };
  };

  const def = getPageDef();
  return <ListPage className="list-holiday-component" data={holidays} def={def} />;
});

export default ListHoliday;
