import inject from 'src/views/injectFromKioskStore';
import CreateDefectForm, { ICreateDefectFormProps } from './CreateDefectForm';

const Container = inject<ICreateDefectFormProps>((allStores, p) => ({
  createDefect: allStores.rootStore.kiosk.defect.createDefect,
  assets: allStores.rootStore.kiosk.asset.sortedAssets.slice(),
  loadAssets: allStores.rootStore.kiosk.asset.loadAssets,
  loadRelatedDefects: allStores.rootStore.kiosk.defect.loadOpenRelatedDefectsToAsset,
  isWorkshopKiosk: allStores.rootStore.isWorkshopKiosk,
}))(CreateDefectForm);

export default Container;
