import { groupBy } from 'src/infrastructure/arrayUtils';
import { SkillSpecCategory } from 'src/api/enums';

type SkillSpecItem = Common.Dtos.SkillSpecItem;
type StaffMemberDto = Common.Dtos.StaffMemberDto;

interface IShortSkillSpecRequirement {
  skillSpecId: number;
}

interface IFullSkillSpecRequirement extends IShortSkillSpecRequirement {
  category: SkillSpecCategory;
}

export interface ISplittedSkillSpecRequirements {
  requiredCharterSkillSpecIds: number[];
  requiredRailSkillSpecIds: number[];
  requiredUrbanSkillSpecIds: number[];
}

export interface ISplittedSkillSpecs<T extends SkillSpecItem | IFullSkillSpecRequirement> {
  charterSkillSpecs: T[];
  railSkillSpecs: T[];
  urbanSkillSpecs: T[];
}

function mapSkillSpecId(requirement: IShortSkillSpecRequirement) {
  return requirement.skillSpecId;
}

export function mapSkillSpecIds(requirements: IShortSkillSpecRequirement[]) {
  return requirements.map(mapSkillSpecId);
}

export function consolidateSkillSpecIds(requirementsGroups: IShortSkillSpecRequirement[][]) {
  if (!requirementsGroups) {
    return [];
  }

  const allSkillSpecIds = requirementsGroups
    .reduce<IShortSkillSpecRequirement[]>((acc, cur) => {
      return acc.concat(cur || []);
    }, [])
    .map(mapSkillSpecId);

  const uniqueSkillSpecIds = Array.from(new Set(allSkillSpecIds));
  return uniqueSkillSpecIds;
}

export function splitSkillSpecRequirements(
  skillSpecRequirements: IFullSkillSpecRequirement[]
): ISplittedSkillSpecRequirements {
  const groupedSkillSpecs = splitSkillSpecs(skillSpecRequirements);
  const requiredCharterSkillSpecIds = groupedSkillSpecs.charterSkillSpecs.map(mapSkillSpecId);
  const requiredRailSkillSpecIds = groupedSkillSpecs.railSkillSpecs.map(mapSkillSpecId);
  const requiredUrbanSkillSpecIds = groupedSkillSpecs.urbanSkillSpecs.map(mapSkillSpecId);
  return {
    requiredCharterSkillSpecIds,
    requiredRailSkillSpecIds,
    requiredUrbanSkillSpecIds,
  };
}

export function applySkillSpecRequirementsFilter(
  staffMembers: StaffMemberDto[],
  skillSpecRequirementsIds: number[]
): StaffMemberDto[] {
  if (!!skillSpecRequirementsIds) {
    return staffMembers.filter(member =>
      doesMemberHaveRequiredSkillSpecs(member, skillSpecRequirementsIds)
    );
  }

  return staffMembers;
}

export function doesMemberHaveRequiredSkillSpecs(
  member: StaffMemberDto,
  skillSpecRequirementsIds: number[]
): boolean {
  if (!!skillSpecRequirementsIds) {
    var result = skillSpecRequirementsIds.every(requiredSkillSpecId => {
      const matchingSkill = member.skillSpecs.find(
        memberSkill => memberSkill.skillSpecId === requiredSkillSpecId
      );
      return !!matchingSkill && !!matchingSkill.value;
    });
    return result;
  }
  return true;
}

export function splitSkillSpecs<T extends SkillSpecItem | IFullSkillSpecRequirement>(
  skillSpecItems: T[]
): ISplittedSkillSpecs<T> {
  const groupedSkillSpecs = groupBy(skillSpecItems || [], s => s.category);
  const charterSkillSpecs = groupedSkillSpecs.get(SkillSpecCategory.Charter) || [];
  const railSkillSpecs = groupedSkillSpecs.get(SkillSpecCategory.Rail) || [];
  const urbanSkillSpecs = groupedSkillSpecs.get(SkillSpecCategory.Urban) || [];
  return {
    charterSkillSpecs,
    railSkillSpecs,
    urbanSkillSpecs,
  };
}

export function consolidateSkillSpecRequirementsIds(
  splittedSkillSpecs: ISplittedSkillSpecRequirements
): number[] {
  if (!splittedSkillSpecs) {
    return [];
  }

  return (splittedSkillSpecs.requiredCharterSkillSpecIds || [])
    .concat(splittedSkillSpecs.requiredRailSkillSpecIds || [])
    .concat(splittedSkillSpecs.requiredUrbanSkillSpecIds || []);
}

export function consolidateSkillSpecRequirements(
  splittedSkillSpecs: ISplittedSkillSpecRequirements
): IShortSkillSpecRequirement[] {
  const consolidatedIds = consolidateSkillSpecRequirementsIds(splittedSkillSpecs);
  return consolidatedIds.map(x => {
    return { skillSpecId: x };
  });
}
