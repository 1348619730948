import { types } from 'mobx-state-tree';
import { ComplianceDashboardModel } from './complianceDashboard/ComplianceDashboardModel';
import { CodeOfConductModel } from './codeOfConduct/CodeOfConductModel';
import { CustomerComplaintModel } from './customerComplaint/CustomerComplaintModel';
import { CctvRequestModel } from './cctvRequest/CctvRequestModel';
import { LostPropertyModel } from './lostProperty/LostPropertyModel';
import { ReportableEventModel } from './reportableEvents/ReportableEventModel';
import { FatigueBreachEventsModel } from './fatigueBreachEvent/FatigueBreachEventsModel';
import { FatigueBreachEventModel } from './fatigueBreachEvent/FatigueBreachEventModel';
import { FatigueDetailsModel } from './fatigue/FatigueDetailsModel';
import { DateTime } from 'luxon';
import { FatigueValidationsModel } from './fatigue/FatigueValidationsModel';

export const ComplianceModel = types.model('ComplianceModel', {
  complianceDashboard: types.optional(ComplianceDashboardModel, {}),
  codeOfConduct: types.optional(CodeOfConductModel, {}),
  customerComplaint: types.optional(CustomerComplaintModel, {}),
  cctvRequest: types.optional(CctvRequestModel, {}),
  lostProperty: types.optional(LostPropertyModel, {}),
  reportableEvent: types.optional(ReportableEventModel, {}),
  fatigueBreachEvents: types.optional(FatigueBreachEventsModel, {}),
  fatigueBreachEvent: types.optional(FatigueBreachEventModel, {}),
  fatigueDetails: types.optional(FatigueDetailsModel, {
    selectedDate: DateTime.now().startOf('day'),
  }),
  fatigueValidations: types.optional(FatigueValidationsModel, {}),
});

type IComplianceModelType = typeof ComplianceModel.Type;
export interface IComplianceModel extends IComplianceModelType {}
