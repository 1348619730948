import { DateTime } from 'luxon';
import { QuoteStatus } from 'src/api/enums';
import { isDefined } from 'src/infrastructure/typeUtils';

export function findFirstTrip<
  TTrip extends { routes: Array<TRoute> },
  TRoute extends { date?: string }
>(trips: TTrip[]) {
  const allRoutes = (Array.isArray(trips) ? trips : [])
    .filter(trip => !!trip.routes)
    .reduce((acc, trip) => [...acc, ...trip.routes], [] as Array<TRoute>);
  const sortedRouteDates = allRoutes
    .map(route => route.date)
    .filter(isDefined)
    .sort((a, b) => DateTime.fromISO(a).valueOf() - DateTime.fromISO(b).valueOf());

  return sortedRouteDates.length ? sortedRouteDates[0] : undefined;
}

export function getFutureRepeats(quoteItem: Operations.Domain.Queries.ViewQuote.QuoteItem) {
  const firstTrip = findFirstTrip(quoteItem.trips) as string;
  return quoteItem.repeats.filter(repeat => repeat.repeatDate > firstTrip);
}

export function isQuote(status: QuoteStatus) {
  return [QuoteStatus.Quoted, QuoteStatus.Declined].indexOf(status) !== -1;
}

export function isBooking(status: QuoteStatus) {
  return [QuoteStatus.Booked, QuoteStatus.Cancelled, QuoteStatus.Completed].indexOf(status) !== -1;
}
