import { IRootStoreModel } from 'src/domain/entities/RootStoreModel';
import { UiRoute } from 'src/domain/services/buildUiRoutes';
import { UiRouteName } from 'src/domain/baseTypes';

export default function buildUiRoutes(store: IRootStoreModel) {
  const subcontractorCreate = new UiRoute(
    UiRouteName.operationsSubcontractorCreate,
    '/operations/subcontractors/create',
    () => 'Create Subcontractor',
    false
  );

  const subcontractorUpdate = new UiRoute(
    UiRouteName.operationsSubcontractorUpdate,
    '/operations/subcontractors/:id',
    () => 'Manage Subcontractor',
    false
  );

  const subcontractorList = new UiRoute(
    UiRouteName.operationsSubcontractorList,
    '/operations/subcontractors',
    () => 'Subcontractors',
    true,
    false,
    [subcontractorCreate, subcontractorUpdate]
  );

  return {
    [UiRouteName.operationsSubcontractorList]: subcontractorList,
    [UiRouteName.operationsSubcontractorCreate]: subcontractorCreate,
    [UiRouteName.operationsSubcontractorUpdate]: subcontractorUpdate,
  };
}
