import './CrudPage.scss';
import { Component, useEffect } from 'react';
import cn from 'classnames';
import { Modal, ModalBody, ModalFooter, Button } from 'reactstrap';
import {
  IPageDef,
  PagePrimarySize,
  ActionType,
  IActionGroupDef,
  PaneType,
  IPanelDef,
  ISectionDef,
} from 'src/views/definitionBuilders/types';
import Page from 'src/views/components/Page';
import { EditIcon } from 'src/images/icons';
import { IFormApi } from 'src/views/components/Page/forms/base';
import { useHistory, useLocation } from 'react-router-dom';
import { RootStoreContext } from 'src/views/App';

interface ICancelConfirmationProps {
  isOpen: boolean;
  onConfirm: () => void;
  onReject: () => void;
  mode: CrudPageMode;
}

const CancelConfirmation: React.FC<ICancelConfirmationProps> = (
  props: ICancelConfirmationProps
) => {
  const { isOpen, onConfirm, onReject, mode } = props;

  let confirmButtonRef: HTMLElement | null = null;
  let history = useHistory();
  let location = useLocation();

  useEffect(() => {
    if (confirmButtonRef) {
      confirmButtonRef.focus();
    }
  }, []);

  const handleGoBack = () => {
    if (location.key === '' || !location.key) {
      history.push('/');
    } else {
      history.goBack();
    }
  };

  return (
    <Modal isOpen={isOpen} className="cancel-confirmation-component">
      <ModalBody>Your unsaved changes will be discarded.</ModalBody>
      <ModalFooter className="confirmation-footer">
        <Button
          color="primary"
          onClick={mode === 'create' ? handleGoBack : onConfirm}
          innerRef={e => (confirmButtonRef = e)}>
          Discard changes
        </Button>
        <Button outline onClick={onReject}>
          Keep changes
        </Button>
      </ModalFooter>
    </Modal>
  );
};

interface ICrudPagePrimarySection extends ISectionDef {
  submitSubActionGroups?: Array<IActionGroupDef>;
  clearStandardSecondaryActions?: boolean;
}

export interface ICrudPageDef extends IPageDef {
  primarySection: ICrudPagePrimarySection;
}

export type CrudPageMode = 'create' | 'update' | 'view';
export type UpdateType = 'default' | string;

export interface ICrudPageApi {
  updating: boolean;
  updateType: UpdateType;
  beginCustomEditing: (updateType: UpdateType) => void;
  readonly: boolean;
}

export interface ICrudPageProps<T> {
  className?: string;
  def: ICrudPageDef | ((api: ICrudPageApi) => ICrudPageDef);
  mode: CrudPageMode;
  isEditingForbidden?: boolean;
  data?: T;
  onLoadData?: () => Promise<void>;
  // tslint:disable-next-line:no-any - we want to be able to specify any portion of T (not just the top layer) so Partial<T> doesn't cut it
  createDefaultData?: any;
  onLoadCreateDefaultData?: () => Promise<void>;
  onCancel?: () => void;
}

interface ICrudPageState {
  dataLoading: boolean;
  dataLoadFailed: boolean;
  updating: boolean;
  updatingType: UpdateType;
  performFormReset?: () => void;
}

class CrudPage<T> extends Component<ICrudPageProps<T>, ICrudPageState> {
  static contextType = RootStoreContext;
  context!: React.ContextType<typeof RootStoreContext>;

  // tslint:disable-next-line:no-any
  static getDerivedStateFromProps(nextProps: ICrudPageProps<any>, prevState: ICrudPageState) {
    if (!nextProps.mode) {
      // It's easy to accidentially miss setting mode when the page is set as a react-router route component
      throw new Error('Page Mode must be set');
    }
    return null;
  }

  constructor(props: ICrudPageProps<T>) {
    super(props);
    this.state = {
      dataLoading: !!this.loadDataProp,
      dataLoadFailed: false,
      updating: false,
      updatingType: 'default',
      performFormReset: undefined,
    };
  }

  private get isUpdateMode() {
    return this.props.mode === 'update';
  }

  private get isCreateMode() {
    return this.props.mode === 'create';
  }

  private get isViewMode() {
    return this.props.mode === 'view';
  }

  private get canEdit() {
    return this.props.isEditingForbidden !== true;
  }

  private get loadDataProp() {
    const { onLoadData, onLoadCreateDefaultData } = this.props;
    if (this.isViewMode) {
      return onLoadData;
    }
    return this.isUpdateMode ? onLoadData : onLoadCreateDefaultData;
  }

  private beginEditing = () => {
    this.beginCustomEditing('default');
  };

  private beginCustomEditing = (updateType: UpdateType) => {
    if (this.canEdit) {
      this.setState({ updating: true, updatingType: updateType });
    }
  };

  private stopEditing = () => {
    this.setState({ updating: false });
    return Promise.resolve();
  };

  private handleCancelRequested = (api: IFormApi, cancelReset: () => void) => {
    const history = this.context?.history.history;
    const location = history?.location;
    if (this.isCreateMode && !api.valuesChanged()) {
      if (location?.key === '' || !location?.key) {
        history?.push('/');
      } else {
        history?.goBack();
      }
    } else {
      if (!this.state.performFormReset && api.valuesChanged()) {
        cancelReset();
        this.setState({ performFormReset: api.resetAll });
      } else {
        this.stopEditing();
      }
      this.props.onCancel && this.props.onCancel();
    }
  };

  private hideCancelConfirm = () => {
    this.setState({ performFormReset: undefined });
  };

  private readonly getPageDef = (def: ICrudPageDef): IPageDef => {
    const { dataLoading, updating, performFormReset } = this.state;
    const primarySectionEditing = this.isCreateMode || updating;

    const getPrimaryActions = (): Array<IActionGroupDef> => {
      return !this.isViewMode && !primarySectionEditing && this.canEdit
        ? [
            {
              actions: [
                {
                  actionType: ActionType.actionButton,
                  label: 'Edit',
                  icon: <EditIcon />,
                  onClick: this.beginEditing,
                },
              ],
            },
          ]
        : [];
    };

    const getSecondaryActions = (): Array<IActionGroupDef> => {
      return primarySectionEditing
        ? [
            {
              actions: [
                {
                  actionType: ActionType.submitActionButton,
                  level: 'primary',
                  subActionGroups: def.primarySection.submitSubActionGroups,
                  disabled: def.primarySection.submitButtonDisabled,
                },
                {
                  actionType: ActionType.resetActionButton,
                },
              ],
            },
          ]
        : [];
    };

    const {
      submitSubActionGroups,
      clearStandardSecondaryActions,
      ...standardPrimarySectionDef
    } = def.primarySection;

    const updatedDef: IPageDef = {
      ...def,
      primarySize: def.primarySize || PagePrimarySize.half,
      primarySection: {
        suppressLeavePrompt: true,
        ...standardPrimarySectionDef,
        asForm: primarySectionEditing,
        primaryActions: dataLoading
          ? []
          : getPrimaryActions().concat(def.primarySection.primaryActions || []),
        secondaryActions: dataLoading
          ? []
          : (clearStandardSecondaryActions ? [] : getSecondaryActions()).concat(
              def.primarySection.secondaryActions || []
            ),
        panels: api =>
          (typeof def.primarySection.panels === 'function'
            ? def.primarySection.panels(api)
            : def.primarySection.panels
          ).concat(
            getCrudFeaturesPanel(
              !!performFormReset,
              () => {
                performFormReset && performFormReset();
                this.stopEditing();
                this.hideCancelConfirm();
              },
              this.hideCancelConfirm,
              this.props.mode
            )
          ),
        onFormResetRequested: this.handleCancelRequested,
        onSubmitSucceeded: this.stopEditing,
      },
    };

    return updatedDef;
  };

  private readonly getFailedLoadPageDef = (def: ICrudPageDef): IPageDef => {
    return {
      primarySection: {
        title: def.primarySection.title,
        panels: [
          {
            panes: [
              {
                paneType: PaneType.customPane,
                render: () => (
                  <p>
                    Unfortunately something went wrong. Please try refreshing the page or contact
                    your system administrator.
                  </p>
                ),
              },
            ],
          },
        ],
      },
    };
  };

  private loadData = async () => {
    if (!this.loadDataProp) {
      return;
    }
    try {
      !this.state.dataLoading && this.setState({ dataLoading: true });
      await this.loadDataProp();
      this.setState({ dataLoadFailed: false });
    } catch {
      this.setState({ dataLoadFailed: true });
    } finally {
      this.setState({ dataLoading: false });
    }
  };

  componentDidMount() {
    this.loadData();
  }

  componentDidUpdate(prevProps: Readonly<ICrudPageProps<T>>, prevState: Readonly<ICrudPageState>) {
    if (prevProps.mode !== this.props.mode) {
      // The mode has changed, so reload the data as we've probably jumped from create to view/update
      this.loadData();
    }
  }

  shouldComponentUpdate(
    nextProps: Readonly<ICrudPageProps<T>>,
    nextState: Readonly<ICrudPageState>
  ) {
    if (nextState.dataLoading) {
      // While we're loading data, don't bother updating the dom as it'll just have to change
      return false;
    }

    return true;
  }

  render() {
    const { className, def, data, createDefaultData } = this.props;
    const { updating, updatingType, dataLoadFailed, dataLoading } = this.state;
    const readonly = this.isViewMode || (this.isUpdateMode && !updating);
    const beginCustomEditing = this.beginCustomEditing;
    const resolvedDef =
      typeof def === 'function'
        ? def({
            updating,
            readonly,
            updateType: updatingType,
            beginCustomEditing,
          })
        : def;
    return (
      <Page
        className={cn('crud-page-component', className)}
        data={this.isCreateMode ? createDefaultData : data}
        def={dataLoadFailed ? this.getFailedLoadPageDef(resolvedDef) : this.getPageDef(resolvedDef)}
        showPrimarySectionSpinner={dataLoading}
      />
    );
  }
}

export default CrudPage;

function getCrudFeaturesPanel(
  isOpen: boolean,
  onConfirm: () => void,
  onReject: () => void,
  mode: CrudPageMode
): IPanelDef {
  return {
    panes: [
      {
        paneType: PaneType.customPane,
        hidden: !isOpen,
        render: () => (
          <CancelConfirmation
            isOpen={isOpen}
            onReject={onReject}
            onConfirm={onConfirm}
            mode={mode}
          />
        ),
      },
    ],
  };
}
