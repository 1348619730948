import './PagePanel.scss';
import { Component } from 'react';
import { getShallowMemoizedObjectFunc } from 'src/infrastructure/memoizeOne';
import { withFormApi } from 'src/views/components/Page/forms';
import { IPanelDef, IPanelData, IPanelMeta, IPaneMeta } from 'src/views/definitionBuilders/types';
import PagePane from './panes/PagePane';
import PageNestedItem from './PageNestedItem';
import { IFormApi } from 'src/views/components/Page/forms/base';

interface IPagePanelProps {
  formApi: IFormApi;
  panelDef: IPanelDef;
  panelMeta: IPanelMeta;
}

class PagePanel extends Component<IPagePanelProps> {
  private readonly getPanelData = () => {
    const { formApi, panelDef: def } = this.props;
    const data: IPanelData = {
      panelValue: formApi.getValue(formApi.getFullField(def.dataAddr)),
      sectionValue: formApi.getValue(formApi.getFullField()),
    };
    return data;
  };

  private readonly getIsHidden = () => {
    const { panelDef: def } = this.props;
    if (typeof def.hidden === 'function') {
      return def.hidden(this.getPanelData());
    } else {
      return !!def.hidden;
    }
  };

  private getUnfocusedPaneMeta = (readonly: boolean, formChange$: IPanelMeta['formChange$']) => {
    return this.memoizeUnfocusedPaneMeta({
      readonly,
      formChange$,
      autoFocus: false,
    });
  };
  private readonly memoizeUnfocusedPaneMeta = getShallowMemoizedObjectFunc<IPaneMeta>();

  private readonly getFocusedPaneMeta = (
    readonly: boolean,
    formChange$: IPanelMeta['formChange$']
  ) => {
    return this.memoizeFocusedPaneMeta({
      readonly,
      formChange$,
      autoFocus: true,
    });
  };
  private readonly memoizeFocusedPaneMeta = getShallowMemoizedObjectFunc<IPaneMeta>();

  private readonly getTitle = () => {
    const { panelDef: def } = this.props;
    if (typeof def.title === 'function') {
      return def.title(this.getPanelData());
    } else {
      return def.title;
    }
  };

  render() {
    const { formApi, panelDef: def, panelMeta: meta } = this.props;
    if (this.getIsHidden()) {
      return null;
    }
    const panelData = this.getPanelData();
    const title = this.getTitle();
    return (
      <div className="page-panel-component">
        {title && <h4>{title}</h4>}
        <PageNestedItem dataAddr={def.dataAddr} formApi={formApi}>
          <div className="panel-panes">
            {def.panes.map((p, i) => {
              const autoFocus = meta.autoFocus && i === 0; // Only the first pane should be autofocused
              const metaBuilder = autoFocus ? this.getFocusedPaneMeta : this.getUnfocusedPaneMeta;
              return (
                <PagePane
                  key={i}
                  paneDef={p}
                  paneMeta={metaBuilder(meta.readonly, meta.formChange$)}
                  panelData={panelData}
                  parentValue={panelData.panelValue}
                />
              );
            })}
          </div>
        </PageNestedItem>
      </div>
    );
  }
}

export default withFormApi(PagePanel);
