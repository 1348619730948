import inject from 'src/views/injectFromStore';
import Omit from 'src/infrastructure/omit';
import MaintainPartsGroup, { IMaintainPartsGroupProps } from './MaintainPartsGroup';

const Container = inject<Omit<IMaintainPartsGroupProps, 'mode'>, IMaintainPartsGroupProps>(
  (allStores, p) => ({
    canManagePartsGroups: allStores.rootStore.account.isWorkshopDepartmentMember,
    partsGroup: allStores.rootStore.workshop.partsGroup.partsGroup,
    onLoadPartsGroup: allStores.rootStore.workshop.partsGroup.loadPartsGroup,
    engines: allStores.rootStore.workshop.partsGroup.engines.slice(),
    onLoadPartsGroupEngines: allStores.rootStore.workshop.partsGroup.loadEngines,
    onUpdatePartsGroup: allStores.rootStore.workshop.partsGroup.updatePartsGroup,
    onCreatePartsGroup: allStores.rootStore.workshop.partsGroup.createPartsGroup,
    checkForUniquePartsGroupName: allStores.rootStore.workshop.partsGroup.nameExists,
    searchParts: allStores.rootStore.workshop.parts.searchParts,
    exportToPdf: allStores.rootStore.workshop.partsGroup.exportToPdf,
    setPartsGroupInactive: allStores.rootStore.workshop.partsGroup.setPartsGroupInactive,
    setPartsGroupActive: allStores.rootStore.workshop.partsGroup.setPartsGroupActive,
    deletePartsGroup: allStores.rootStore.workshop.partsGroup.deletePartsGroup,
    getPartsGroupUsage: allStores.rootStore.workshop.partsGroup.getPartsGroupUsage,
    partsGroupUsage: allStores.rootStore.workshop.partsGroup.partsGroupUsage,
    workshopDepots: allStores.rootStore.workshopStartup.workshopDepots.slice(),
  })
)(MaintainPartsGroup);

export default Container;
