import './PrintButton.scss';

import { createPortal } from 'react-dom';
import { Button, ButtonProps } from 'reactstrap';
import * as parser from 'html-react-parser';
import Spinner from 'reactstrap/lib/Spinner';
import { Component, createRef } from 'react';
import { b64toBlob } from 'src/domain/blobHelper';

interface IFrameProps extends React.IframeHTMLAttributes<HTMLIFrameElement> {
  head?: React.ReactNode;
  onMountComplete: () => void;
  onAfterPrint: () => void;
}

class Frame extends Component<IFrameProps> {
  private iframeRef = createRef<HTMLIFrameElement>();

  get iframeContentDocument() {
    return this.iframeRef.current && this.iframeRef.current.contentDocument;
  }

  get iframeContentWindow() {
    return this.iframeRef.current && this.iframeRef.current.contentWindow;
  }

  print = () => {
    this.iframeContentWindow && this.iframeContentWindow.print();
  };

  private handleLoad = () => {
    this.iframeContentWindow &&
      this.iframeContentWindow.addEventListener('afterprint', this.handleAfterPrint);
    this.props.onMountComplete();
  };

  private handlePrintViewReady = () => {
    // Wait for content to load before attaching the afterprint handler otherwise it doesn't always work
    // https://stackoverflow.com/questions/57756283/why-an-iframe-after-print-event-listener-is-fired-after-i-print-in-the-main-wind
    this.iframeContentWindow && this.iframeContentWindow.addEventListener('load', this.handleLoad);
  };

  private handleAfterPrint = () => {
    // this handler exists so that we bind the event listener to a local function
    this.props.onAfterPrint();
  };

  componentDidMount() {
    window.document.addEventListener('printViewLoaded', this.handlePrintViewReady);

    // The render method depends on the iframe ref, so trigger a rerender after mount to render completely
    this.forceUpdate(() => {
      if (this.iframeRef.current && this.iframeContentDocument) {
        if (this.props.src) {
          this.handlePrintViewReady();
        } else {
          // Chrome doesn't fire iframe events to the parent window's js, so inject a script to raise a custom event
          const script = this.iframeContentDocument.createElement('script');
          script.innerHTML = `document.addEventListener('readystatechange', function() {
                if (document.readyState === 'complete') {
                    window.parent.document.dispatchEvent(new CustomEvent('printViewLoaded'));
                }
            })`;
          this.iframeContentDocument.body.appendChild(script);

          // Reset the contents of the iframe so that the `readystate` cycle is reset, so that we
          // can tell when the css has been applied.
          this.iframeRef.current.srcdoc = this.iframeContentDocument.documentElement.outerHTML;
        }
      }
    });
  }

  componentWillUnmount() {
    this.iframeContentWindow &&
      this.iframeContentWindow.removeEventListener('afterprint', this.handleAfterPrint);
    this.iframeContentWindow &&
      this.iframeContentWindow.removeEventListener('load', this.handleLoad);
    window.document.removeEventListener('printViewLoaded', this.handlePrintViewReady);
  }

  render() {
    const { children, head, onMountComplete, onAfterPrint, src, ...rest } = this.props;
    return src ? (
      <iframe title="print button" {...rest} ref={this.iframeRef} src={this.props.src} />
    ) : (
      <iframe title="print button" {...rest} ref={this.iframeRef}>
        {this.iframeContentDocument && head && createPortal(head, this.iframeContentDocument.head)}
        {this.iframeContentDocument && createPortal(children, this.iframeContentDocument.body)}
      </iframe>
    );
  }
}

interface IPrintButtonProps extends ButtonProps {
  loadDataAsync?: () => Promise<void>;
  printContent: (() => React.ReactNode) | Blob | string | undefined;
  printTitle?: string;
}

interface IPrintButtonState {
  renderPrintView: boolean;
  isLoading: boolean;
}

class PrintButton extends Component<IPrintButtonProps, IPrintButtonState> {
  private frameRef = createRef<Frame>();

  constructor(props: IPrintButtonProps) {
    super(props);
    this.state = {
      renderPrintView: false,
      isLoading: false,
    };
  }

  private readonly handlePrintClick = () => {
    if (this.props.loadDataAsync) {
      this.setState({ isLoading: true });
      this.props
        .loadDataAsync()
        .then(() => this.renderPrintView())
        .finally(() => this.setState({ isLoading: false }));
    } else {
      this.renderPrintView();
    }
  };

  public readonly trigger = this.handlePrintClick;

  private readonly renderPrintView = () => {
    if (this.state.renderPrintView) {
      // For browsers (safari) that don't support the afterprint event, toggle the flag to recreate the frame
      this.setState({ renderPrintView: false }, () => this.setState({ renderPrintView: true }));
    } else {
      this.setState({ renderPrintView: true });
    }
  };

  private readonly handleAfterPrint = () => {
    !!this.state.renderPrintView && setTimeout(() => this.setState({ renderPrintView: false }));
  };

  private readonly triggerBrowserPrint = () => {
    this.frameRef.current && this.frameRef.current.print();
  };

  render() {
    const { printTitle, printContent, children, loadDataAsync, ...rest } = this.props; // LoadDataAsync here so it's not in ...rest and thus in DOM
    const { renderPrintView, isLoading } = this.state;
    const buildHead = () => {
      // We want all the tags from the head (except title) so that all the same styles are applied
      const headInnerHtmlSanitised = Array.from(document.head.children)
        .filter(c => c.tagName !== 'TITLE')
        .map(c => c.outerHTML)
        .join('');
      return (
        <>
          <title>{printTitle}</title>
          {parser(headInnerHtmlSanitised)}
        </>
      );
    };

    const frameStyle: React.CSSProperties = { visibility: 'hidden', position: 'fixed' };

    let isDataBlob = printContent instanceof Blob;
    let dataBlob = printContent as Blob;

    if (typeof printContent === 'string') {
      dataBlob = b64toBlob(printContent as string, 'application/pdf');
      isDataBlob = true;
    }

    const blobUrl = isDataBlob && dataBlob ? URL.createObjectURL(dataBlob) : undefined;

    const dataFrame = renderPrintView && (
      <Frame
        ref={this.frameRef}
        onMountComplete={this.triggerBrowserPrint}
        onAfterPrint={this.handleAfterPrint}
        style={frameStyle}
        src={blobUrl}
      />
    );

    const reactFrame = renderPrintView && (
      <Frame
        ref={this.frameRef}
        onMountComplete={this.triggerBrowserPrint}
        onAfterPrint={this.handleAfterPrint}
        head={buildHead()}
        style={frameStyle}>
        <table className="print-button-component-print-container">
          <tbody>
            <tr>
              <td>{printContent && !isDataBlob && (printContent as () => React.ReactNode)()}</td>
            </tr>
          </tbody>
        </table>
      </Frame>
    );

    const targetFrame = isDataBlob && dataBlob ? dataFrame : reactFrame;

    return (
      <span className="print-button-component">
        <Button {...rest} onClick={this.handlePrintClick}>
          {children || 'Print'}
          {isLoading && <Spinner />}
        </Button>
        {renderPrintView && targetFrame}
      </span>
    );
  }
}

export default PrintButton;
