import {
  breachSeverityLevelTypeDescription,
  FatigueBreachEventStatus,
  fatigueRuleSetTypeDescription,
} from 'src/api/enums';
import { InfoIcon, SpinnerIcon, VerifyIcon } from 'src/images/icons';
import { getSubmitCloseModalActionGroupDef } from 'src/views/definitionBuilders/common';
import {
  ActionDefs,
  ActionType,
  IActionData,
  IModalDefBuilderApi,
  PaneType,
  ShellModalSize,
} from 'src/views/definitionBuilders/types';

type InvalidateFatigueBreachEventCommand = Operations.Domain.Commands.FatigueBreachEvent.InvalidateFatigueBreachEvent.InvalidateFatigueBreachEventCommand;

export default function getVerifyFatigueBreachEventActionButtonDef(
  verifyFatigueBreach: (fatigueBreachEventId: string) => Promise<void>,
  onInvalidateFatigueBreachEvent: (
    command: Operations.Domain.Commands.FatigueBreachEvent.InvalidateFatigueBreachEvent.InvalidateFatigueBreachEventCommand
  ) => Promise<void>,
  onInvalidated: () => void,
  onClose: () => void,
  verifyResponse:
    | Operations.Domain.Commands.FatigueBreachEvent.VerifyFatigueBreachEvent.VerifyFatigueBreachEventResponse
    | undefined
): ActionDefs {
  return {
    actionType: ActionType.modalActionButton,
    label: 'Verify',
    hidden: (d: IActionData) => {
      return (d.parentValue ?? d.actionValue).status !== FatigueBreachEventStatus.Open;
    },
    icon: <VerifyIcon />,
    modalSize: ShellModalSize.oneQuarter,
    onOpenModal: (d, a) => verifyFatigueBreach((d ?? a).id),
    onCloseModal: () => onClose(),
    modalDef: (modalDefApi: IModalDefBuilderApi) => {
      const fatigueBreachEventData =
        modalDefApi.actionData.parentValue ?? modalDefApi.actionData.actionValue;
      return {
        title: `Verify Breach ${fatigueBreachEventData.fatigueBreachEventNumber}`,
        asForm: true,
        panels: [
          {
            panes: [
              {
                paneType: PaneType.customPane,
                render: () => {
                  switch (verifyResponse?.isValid) {
                    case true:
                      return (
                        <>
                          <p>
                            This breach of{' '}
                            <span>
                              {fatigueRuleSetTypeDescription(
                                fatigueBreachEventData.fatigueRuleSetType
                              )}
                            </span>{' '}
                            ruleset still exists.
                          </p>
                          {verifyResponse.severityChanged && (
                            <p>
                              <InfoIcon className="inline-info" />
                              <span> The breach severity level has been adjusted from </span>
                              <strong>
                                {breachSeverityLevelTypeDescription(
                                  verifyResponse.originalSeverity
                                )}
                              </strong>{' '}
                              to{' '}
                              <strong>
                                {breachSeverityLevelTypeDescription(verifyResponse.newSeverity)}
                              </strong>
                            </p>
                          )}
                        </>
                      );
                    case false:
                      return (
                        <p>
                          It has been determined that this is no longer a breach, it can be
                          invalidated.
                        </p>
                      );
                    case undefined:
                    default:
                      return <p>Verifying...</p>;
                  }
                },
              },
              {
                paneType: PaneType.customPane,
                render: () => (
                  <span>
                    &nbsp;
                    <SpinnerIcon size="1x" />
                  </span>
                ),
                hidden: verifyResponse?.isValid !== undefined,
              },
            ],
          },
        ],
        secondaryActions:
          verifyResponse?.isValid || verifyResponse?.isValid === undefined
            ? [
                {
                  actions: [
                    {
                      actionType: ActionType.closeModalActionButton,
                      label: 'Close',
                    },
                  ],
                },
              ]
            : [getSubmitCloseModalActionGroupDef('Invalidate')],
        onFormSubmit: () => {
          const fatigueBreachEventId = (
            modalDefApi.actionData.parentValue ?? modalDefApi.actionData.actionValue
          ).id;
          const command: InvalidateFatigueBreachEventCommand = {
            id: fatigueBreachEventId,
            invalidReason: 'Re-evaluated as no longer a breach',
          };
          return onInvalidateFatigueBreachEvent(command).then(onInvalidated);
        },
      };
    },
  };
}
