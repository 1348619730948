import { Table } from 'reactstrap';
import styles from './PrintJobTask.module.scss';

interface IPrintJobTaskProps {
  task: Workshop.Domain.Queries.Job.PrintJobQuery.JobTaskItem;
}

const PrintJobTask: React.FC<IPrintJobTaskProps> = ({ task }) => (
  <div className={styles.printJobTaskComponent}>
    <Table>
      <thead>
        <tr>
          <th>Task {task.taskNumber}</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td className={styles.taskDescription}>{task.description}</td>
        </tr>
      </tbody>
    </Table>
  </div>
);

export default PrintJobTask;
