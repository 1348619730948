import { types, getRoot, Instance, IAnyStateTreeNode } from 'mobx-state-tree';
import * as queryString from 'query-string';
import buildUiRoutes, { UiRoute } from 'src/domain/services/buildUiRoutes';
import { History, Location, Action, LocationDescriptorObject } from 'history';
import { IRootStoreModel } from '../RootStoreModel';
import logger from 'src/infrastructure/logging';

function getTypedRoot(self: IAnyStateTreeNode): IRootStoreModel {
  return getRoot<IRootStoreModel>(self);
}

export const HistoryModel = types
  .model('HistoryModel', {
    currentUiRoutes: types.array(types.frozen<UiRoute>()),
  })
  .volatile(self => ({
    uiRoutes: buildUiRoutes(getTypedRoot(self)),
    history: null as History | null,
  }))
  .actions(self => ({
    _updateCurrentUiRoutes() {
      self.currentUiRoutes.replace(
        self.history ? self.uiRoutes.home.matchPath(self.history.location) : []
      );
    },
  }))
  .extend(self => {
    let unregisterListener: () => void | null;

    function setHistory(h: History) {
      const log = logger;
      if (unregisterListener) {
        unregisterListener();
      }
      self.history = h;
      log.debug('User route set to {pathname}{search}', {
        pathname: h.location.pathname,
        search: h.location.search,
      });
      self._updateCurrentUiRoutes();

      unregisterListener = h.listen((location: Location, action: Action) => {
        log.debug('User route changed to {pathname}{search}', {
          pathname: h.location.pathname,
          search: h.location.search,
        });
        self._updateCurrentUiRoutes();
      });
    }

    function push(urlOrloc: string | LocationDescriptorObject) {
      if (!self.history) {
        throw new Error('History has not been initialised');
      }
      // typeof check for typescript
      if (typeof urlOrloc === 'string') {
        self.history.push(urlOrloc);
      } else {
        self.history.push(urlOrloc);
      }
    }

    function replace(urlOrloc: string | LocationDescriptorObject) {
      if (!self.history) {
        throw new Error('History has not been initialised');
      }
      // typeof check for typescript
      if (typeof urlOrloc === 'string') {
        self.history.replace(urlOrloc);
      } else {
        self.history.replace(urlOrloc);
      }
    }

    function getQueryParams() {
      return self.history ? queryString.parse(self.history.location.search) : {};
    }

    function updateQueryParams<T extends {} = {}>(params: T) {
      const updatedParams = { ...getQueryParams() };
      // Convert any falsie values (except zero or explicit false) to undefined so they don't show in the url
      Object.keys(params)
        .map(k => ({ k, v: params[k] }))
        .forEach(x => (updatedParams[x.k] = !!x.v || x.v === 0 || x.v === false ? x.v : undefined));
      self.history && self.history.replace({ search: queryString.stringify(updatedParams) });
    }

    return {
      views: {
        get currentLocation(): Location | null {
          return self.history && self.history.location;
        },
        getQueryParams,
      },
      actions: { setHistory, push, replace, updateQueryParams },
    };
  });

export interface IHistoryModel extends Instance<typeof HistoryModel> {}

export type UiRoutes = ReturnType<typeof buildUiRoutes>;
