import { types, getRoot, flow } from 'mobx-state-tree';
import { IRootStoreModel } from 'src/domain/entities/RootStoreModel';
import { getAjax } from 'src/domain/services';

type CreateOwnerCommand = Workshop.Domain.Commands.Owner.CreateOwnerCommand;
type UpdateOwnerCommand = Workshop.Domain.Commands.Owner.UpdateOwnerCommand;
type CreateOwnerContactCommand = Workshop.Domain.Commands.Owner.CreateOwnerContactCommand;
type UpdateOwnerContactCommand = Workshop.Domain.Commands.Owner.UpdateOwnerContactCommand;
type CreateOwnerNoteCommand = Workshop.Domain.Commands.Owner.CreateOwnerNoteCommand;
type UpdateOwnerNoteCommand = Workshop.Domain.Commands.Owner.UpdateOwnerNoteCommand;
type ContactItem = Workshop.Domain.Queries.Owners.GetContactsForOwner.ContactItem;
type OwnerItem = Workshop.Domain.Queries.Owners.OwnerItem;
type GetCanOwnerBeDeletedQueryResult = Workshop.Domain.Queries.Owners.GetCanOwnerBeDeleted.GetCanOwnerBeDeletedQueryResult;

export const OwnerModel = types
  .model('OwnerModel', {
    owner: types.maybe(types.frozen<OwnerItem>()),
    canDeleteOwner: types.maybe(types.frozen<GetCanOwnerBeDeletedQueryResult>()),
    contactsForOwner: types.array(types.frozen<ContactItem>()),
  })
  .actions(self => {
    const ajax = getAjax(self);
    const root = getRoot(self) as IRootStoreModel;

    const createOwner = flow(function*(command: CreateOwnerCommand) {
      const id = yield ajax.workshop.owner.createOwner(command);
      self.owner = undefined;
      root.history.push(`/workshop/owners/${id}`);
    });

    const checkForUniqueName = flow(function*(name: string) {
      return yield ajax.workshop.owner.checkForUniqueName(name);
    });

    const loadOwner = flow(function*(ownerId: string) {
      self.owner = yield ajax.workshop.owner.getOwner(ownerId);
    });

    const loadCanDeleteOwner = flow(function*(ownerId: string) {
      self.canDeleteOwner = yield ajax.workshop.owner.canDeleteOwner(ownerId);
    });

    const updateOwner = flow(function*(command: UpdateOwnerCommand) {
      yield ajax.workshop.owner.updateOwner(command);
      yield loadOwner(command.id);
    });

    const createOwnerContact = flow(function*(command: CreateOwnerContactCommand) {
      yield ajax.workshop.owner.createOwnerContact(command);
      loadOwner(command.ownerId);
    });

    const updateOwnerContact = flow(function*(command: UpdateOwnerContactCommand) {
      yield ajax.workshop.owner.updateOwnerContact(command);
      loadOwner(command.ownerId);
    });

    const listContactsForOwner = flow(function*(ownerId: string) {
      if (!ownerId) {
        self.contactsForOwner.replace([]);
      } else {
        const contacts: ContactItem[] = yield ajax.workshop.owner.listContactsForOwner(ownerId);
        self.contactsForOwner.replace(contacts);
      }
    });

    const createOwnerNote = flow(function*(command: CreateOwnerNoteCommand) {
      yield ajax.workshop.owner.createOwnerNote(command);
      loadOwner(command.ownerId);
    });

    const updateOwnerNote = flow(function*(command: UpdateOwnerNoteCommand) {
      yield ajax.workshop.owner.updateOwnerNote(command);
      loadOwner(command.ownerId);
    });

    const deleteOwner = flow(function*(ownerId: string) {
      yield ajax.workshop.owner.deleteOwner(ownerId);
      self.owner = undefined;
      root.history.push(`/workshop/owners`);
    });

    const setOwnerActive = flow(function*(ownerId: string) {
      yield ajax.workshop.owner.updateOwnerSetActive(ownerId);
      yield loadOwner(ownerId);
    });

    const setOwnerInactive = flow(function*(ownerId: string) {
      yield ajax.workshop.owner.updateOwnerSetInactive(ownerId);
      yield loadOwner(ownerId);
    });

    return {
      createOwner,
      checkForUniqueName,
      loadOwner,
      updateOwner,
      createOwnerContact,
      updateOwnerContact,
      listContactsForOwner,
      createOwnerNote,
      updateOwnerNote,
      deleteOwner,
      loadCanDeleteOwner,
      setOwnerActive,
      setOwnerInactive,
    };
  });
