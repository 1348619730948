import './AuditHistoryRow.scss';
import { Component } from 'react';
import { DateTime } from 'luxon';
import { Collapse } from 'reactstrap';
import cn from 'classnames';
import { DateTimeFormat } from 'src/views/components/DateTimeFormat';
import { PlusIcon, MinusIcon } from 'src/images/icons';
import { ISchemaRecord } from 'src/views/components/AuditHistory/ISchemaRecord';
import {
  renderDiffChanges,
  getChanges,
  orderChanges,
} from 'src/views/components/AuditHistory/AuditHistoryHelpers';

type AuditLogItem = Operations.Domain.Queries.ListAuditLog.AuditLogItem;

export interface IAuditHistoryRowProps {
  auditLog: AuditLogItem;
  schema: ISchemaRecord[];
}

interface IAuditHistoryRowState {
  open: boolean;
}

class AuditHistoryRow extends Component<IAuditHistoryRowProps, IAuditHistoryRowState> {
  constructor(props: IAuditHistoryRowProps) {
    super(props);
    this.state = {
      open: true,
    };
  }

  toggle() {
    this.setState({ open: !this.state.open });
  }

  render() {
    const { auditLog, schema } = this.props;
    const { open } = this.state;

    const changes = orderChanges(getChanges(auditLog.changeSnapshot), schema);
    const changesToRender = renderDiffChanges(
      changes,
      changes,
      schema,
      [],
      DateTime.fromISO(auditLog.createdOn)
    );

    if (!changesToRender || changesToRender.length === 0) {
      return null;
    }

    return (
      <div className="audit-history-row-component">
        <Collapse isOpen={open}>
          <div className="close-tab" onClick={() => this.toggle()}>
            <MinusIcon />
          </div>
          <div className="changes">{changesToRender}</div>
        </Collapse>
        <div className={cn('audit-info', { open })}>
          {open ? null : (
            <div className="open-tab" onClick={() => this.toggle()}>
              <PlusIcon />
            </div>
          )}
          <div className="created-by">Modified By - {auditLog.createdBy}</div>
          <div className="created-on">
            Modified On - <DateTimeFormat value={auditLog.createdOn} />
          </div>
        </div>
      </div>
    );
  }
}

export default AuditHistoryRow;
