import { DateTime } from 'luxon';
import { TIMEZONE } from 'src/appSettings';
import cn from 'classnames';

export const getTimezoneCommonName = (
  value: DateTime,
  blankForDefaultZone: boolean = false
): string => {
  // get `value`'s timezone short name if it is in a different timezone to the default TIMEZONE, or
  // the local time doesn't match the default TIMEZONE
  const hqDate = value.setZone(TIMEZONE);
  const localDate = value.toLocal();

  return blankForDefaultZone && value.offset === hqDate.offset && localDate.offset === hqDate.offset
    ? ''
    : value.offset === 600
    ? 'AEST'
    : value.offset === 660
    ? 'AEDT'
    : value.offset === 570
    ? 'ACST'
    : value.offset === 630
    ? 'ACDT'
    : value.offset === 525
    ? 'ACWST'
    : value.offset === 480
    ? 'AWST'
    : value.offsetNameShort;
};

const TimezoneFormat: React.FC<{
  value: DateTime;
  blankForDefaultZone?: boolean;
  className?: string;
}> = ({ value, blankForDefaultZone, className }) => {
  return <span className={cn(className)}>{getTimezoneCommonName(value, blankForDefaultZone)}</span>;
};

export default TimezoneFormat;
