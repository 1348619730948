import inject from 'src/views/injectFromStore';
import Omit from 'src/infrastructure/omit';
import { MaintainRoster, IMaintainRosterProps } from './MaintainRoster';

const Container = inject<Omit<IMaintainRosterProps, 'mode'>, IMaintainRosterProps>(
  (allStores, p) => ({
    canManageRosters: allStores.rootStore.account.isOperationsDepartmentMember,
    roster: Object.assign({}, allStores.rootStore.operations.urban.roster.roster),
    loadRoster: allStores.rootStore.operations.urban.roster.loadRoster,
    updateRoster: allStores.rootStore.operations.urban.roster.updateRoster,
    createRoster: allStores.rootStore.operations.urban.roster.createRoster,
    deleteRoster: allStores.rootStore.operations.urban.roster.deleteRoster,
    checkForUniqueRosterNumber:
      allStores.rootStore.operations.urban.roster.checkForUniqueRosterNumber,
    listShifts: allStores.rootStore.operations.urban.shifts.listShifts,
    shifts: allStores.rootStore.operations.urban.shifts.shifts.slice(),
    loadStaffMembers: allStores.rootStore.people.staffMembers.loadAllStaffMembers,
    loadRosterGroups: allStores.rootStore.operations.urban.rosterGroups.listRosterGroups,
    rosterGroups: allStores.rootStore.operations.urban.rosterGroups.rosterGroups.slice(),
  })
)(MaintainRoster);

export default Container;
