import { TrashIcon } from 'src/images/icons';
import {
  ActionType,
  ShellModalSize,
  PaneType,
  IModalActionButtonDef,
  FieldType,
} from 'src/views/definitionBuilders/types';

export default function getDeleteJobButtonDef(
  childJobCount: number,
  canBeDeleted: boolean,
  deleteJob: (forceDelete: boolean | undefined) => Promise<void>
): IModalActionButtonDef {
  return {
    actionType: ActionType.modalActionButton,
    label: 'Delete Job',
    icon: <TrashIcon />,
    modalSize: ShellModalSize.oneQuarter,
    modalDef: modalDefApi => ({
      title: 'Delete Job',
      asForm: true,
      panels: [
        {
          panes: [
            {
              paneType: PaneType.customPane,
              render: d => (
                <div>
                  {childJobCount ? (
                    <p>
                      <strong>Note: </strong>
                      <span>
                        This will also delete the {childJobCount}{' '}
                        {childJobCount === 1 ? 'linked job' : 'linked jobs'}.
                      </span>
                    </p>
                  ) : null}
                  {!canBeDeleted ? (
                    <p>
                      <strong>Note: </strong>
                      <span>
                        This job contains progress/completion details data that might be important.
                        Are you sure you want to delete this job regardless?
                      </span>
                    </p>
                  ) : (
                    <p>Are you sure you want to delete this job?</p>
                  )}
                </div>
              ),
            },
            {
              paneType: PaneType.formFieldsPane,
              hidden: canBeDeleted,
              fields: [
                {
                  fieldType: FieldType.yesNoField,
                  label: "I'm sure I want to delete this job",
                  dataAddr: 'forceDelete',
                },
              ],
            },
          ],
        },
      ],
      secondaryActions: [
        {
          actions: [
            {
              actionType: ActionType.submitActionButton,
              label: 'Delete',
              level: 'primary',
              disabled: d => {
                const disabled = !canBeDeleted && (!d.actionValue || !d.actionValue.forceDelete);
                return disabled;
              },
            },
            {
              actionType: ActionType.closeModalActionButton,
              label: 'Cancel',
            },
          ],
        },
      ],
      onFormSubmit: d => deleteJob(d.forceDelete),
    }),
  };
}
