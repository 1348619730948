import { IRootStoreModel } from 'src/domain/entities/RootStoreModel';
import { UiRoute } from 'src/domain/services/buildUiRoutes';
import { UiRouteName } from 'src/domain/baseTypes';

export default function buildUiRoutes(store: IRootStoreModel) {
  const bookingForOpsManage = new UiRoute(
    UiRouteName.operationsBookingForOpsManage,
    '/operations/bookings-for-ops/:id',
    () => 'Manage Booking',
    false
  );

  return {
    [UiRouteName.operationsBookingForOpsManage]: bookingForOpsManage,
  };
}
