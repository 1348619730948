import { UiRouteName } from 'src/domain/baseTypes';
import { IRootStoreModel } from 'src/domain/entities/RootStoreModel';
import UiRoute from 'src/domain/services/buildUiRoutes/UiRoute';

export default function buildUiRoutes(store: IRootStoreModel) {
  const createShiftTemplate = new UiRoute(
    UiRouteName.workshopShiftTemplateCreate,
    '/workshop/shift-templates/create',
    () => `Create Shift Template`,
    false
  );

  const manageShiftTemplate = new UiRoute(
    UiRouteName.workshopShiftTemplateManage,
    '/workshop/shift-templates/:id',
    () => `Manage Shift Template`,
    false
  );

  const shiftTemplatesList = new UiRoute(
    UiRouteName.workshopShiftTemplateList,
    '/workshop/shift-templates',
    () => 'Shift Templates',
    true,
    false,
    [createShiftTemplate, manageShiftTemplate]
  );

  return {
    [UiRouteName.workshopShiftTemplateList]: shiftTemplatesList,
    [UiRouteName.workshopShiftTemplateCreate]: createShiftTemplate,
    [UiRouteName.workshopShiftTemplateManage]: manageShiftTemplate,
  };
}
