import { types, flow } from 'mobx-state-tree';
import { getAjax } from 'src/domain/services/storeEnvironment';
import { workshopUrls } from 'src/domain/services/apiUrls';

type GetWorkshopDashboardDataQuery = Workshop.Domain.Queries.GetWorkshopDashboard.GetWorkshopDashboardDataQuery;
type WorkshopDashboardData = Workshop.Domain.Queries.GetWorkshopDashboard.WorkshopDashboardData;

export const WorkshopDashboardModel = types
  .model('WorkshopDashboardModel', {
    dashboardData: types.maybe(types.frozen<WorkshopDashboardData>()),
  })
  .actions(self => {
    const ajax = getAjax(self);

    const loadDashboardData = flow(function*(query: GetWorkshopDashboardDataQuery) {
      self.dashboardData = yield ajax.raw
        .get(workshopUrls.dashboardUrls.loadDashboard(query.depotIds))
        .toPromise()
        .then<WorkshopDashboardData>(r => r.response);
    });

    return {
      loadDashboardData,
    };
  });
