import { IAjax } from 'src/infrastructure/ajax';
import { operationsUrls } from 'src/domain/services/apiUrls';

// tslint:disable-next-line:no-empty
const returnVoid = () => {};

export default function init(ajax: IAjax) {
  function listCustomer(page: number, size: number, search: string) {
    return ajax
      .get(operationsUrls.customerUrls.listCustomer(page, size, search))
      .map(
        r =>
          r.response as Common.Dtos.ListResult<Operations.Domain.Queries.ListCustomers.CustomerItem>
      );
  }

  function loadCustomers() {
    return ajax
      .get(operationsUrls.customerUrls.listCustomers())
      .toPromise()
      .then(r => r.response as Common.Dtos.CustomerItem[]);
  }

  function searchCustomers(search: string) {
    return ajax
      .get(operationsUrls.customerUrls.searchCustomers(search))
      .toPromise()
      .then<Common.Dtos.ListResult<Operations.Domain.Queries.SearchCustomers.CustomerItem>>(
        r => r.response
      );
  }

  function findCustomers(ids: string[]) {
    return ajax
      .get(operationsUrls.customerUrls.findCustomers(ids))
      .toPromise()
      .then<Common.Dtos.ListResult<Operations.Domain.Queries.SearchCustomers.CustomerItem>>(
        r => r.response
      );
  }

  function createCustomer(command: Operations.Domain.Commands.Customer.CreateCustomerCommand) {
    return ajax
      .post(operationsUrls.customerUrls.createCustomer(), command)
      .toPromise()
      .then<string>(r => r.response);
  }

  function createContact(
    command: Operations.Domain.Commands.Customer.CreateCustomerContactCommand
  ) {
    return ajax
      .post(operationsUrls.customerUrls.createContact(command.customerId), command)
      .toPromise()
      .then<number>(r => r.response);
  }

  function editContact(command: Operations.Domain.Commands.Customer.UpdateCustomerContactCommand) {
    return ajax
      .put(operationsUrls.customerUrls.editContact(command.customerId, command.contactId), command)
      .toPromise()
      .then(r => returnVoid());
  }

  function deleteContact(
    command: Operations.Domain.Commands.Customer.DeleteCustomerContactCommand
  ) {
    return ajax
      .httpDelete(operationsUrls.customerUrls.deleteContact(command.customerId, command.contactId))
      .toPromise()
      .then(r => returnVoid());
  }

  function deleteCustomer(command: Operations.Domain.Commands.Customer.DeleteCustomerCommand) {
    return ajax
      .httpDelete(operationsUrls.customerUrls.deleteCustomer(command.customerId))
      .toPromise()
      .then(r => returnVoid());
  }

  function editCustomer(command: Operations.Domain.Commands.Customer.UpdateCustomerCommand) {
    return ajax
      .put(operationsUrls.customerUrls.editCustomer(command.id), command)
      .toPromise()
      .then(r => returnVoid());
  }

  function checkForUniqueName(name: string) {
    return ajax
      .get(operationsUrls.customerUrls.checkForUniqueName(name))
      .toPromise()
      .then<Common.Dtos.UniqueNameCheckResultDto>(r => r.response);
  }

  function viewCustomer(customerId: string) {
    return ajax
      .get(operationsUrls.customerUrls.viewCustomer(customerId))
      .toPromise()
      .then<Operations.Domain.Queries.ViewCustomer.CustomerItem>(r => r.response);
  }

  function searchContactsForCustomer(customerId: string, search: string) {
    return ajax
      .get(operationsUrls.customerUrls.searchContactsForCustomer(customerId, search))
      .toPromise()
      .then<Operations.Domain.Queries.SearchContactsForCustomer.ContactItem>(r => r.response);
  }

  function findContactsForCustomer(customerId: string, ids: number[]) {
    return ajax
      .get(operationsUrls.customerUrls.findContactsForCustomer(customerId, ids))
      .toPromise()
      .then<Operations.Domain.Queries.FindContactsForCustomer.ContactItem>(r => r.response);
  }

  function generateBookingsAndQuotesReport(
    query: Operations.Domain.Queries.GenerateUpcomingBookingsReportForCustomer.GenerateUpcomingBookingsReportForCustomerQuery
  ) {
    return ajax
      .getFile(operationsUrls.customerUrls.generateBookingsAndQuotesReport(query))
      .toPromise()
      .then<Blob>(r => r.response);
  }

  function createCustomerNote(
    command: Operations.Domain.Commands.Customer.CreateCustomerNoteCommand
  ) {
    return ajax
      .post(operationsUrls.customerUrls.createNote(command.customerId), command)
      .toPromise()
      .then(r => returnVoid());
  }

  function updateCustomerNote(
    command: Operations.Domain.Commands.Customer.UpdateCustomerNoteCommand
  ) {
    return ajax
      .put(operationsUrls.customerUrls.editNote(command.customerId, command.noteId), command)
      .toPromise()
      .then(r => returnVoid());
  }

  function deleteCustomerNote(customerId: string, noteId: number) {
    return ajax.httpDelete(operationsUrls.customerUrls.deleteNote(customerId, noteId)).toPromise();
  }

  return {
    customer: {
      listCustomer,
      searchCustomers,
      findCustomers,
      createCustomer,
      checkForUniqueName,
      viewCustomer,
      editCustomer,
      createContact,
      editContact,
      deleteContact,
      deleteCustomer,
      searchContactsForCustomer,
      findContactsForCustomer,
      generateBookingsAndQuotesReport,
      createCustomerNote,
      updateCustomerNote,
      deleteCustomerNote,
      loadCustomers,
    },
  };
}
