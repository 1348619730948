import { ITabProps } from '../../../components/presentational/TabHolder/TabHolder';
import { AssetScheduledAndFutureTasksDisplay } from './AssetScheduledAndFutureTasksDisplay';
import { AssetCompletedJobsDisplay } from './AssetCompletedJobsDisplay';
import { AssetPartsRegisterDisplay } from './AssetPartsRegisterDisplay';
import { AssetDetailsDisplay } from './AssetDetailsDisplay';
import { AssetTechSpecsDisplay } from './AssetTechSpecsDisplay';
import { AssetComponentRegisterDisplay } from './AssetComponentRegisterDisplay';
import { AssetPartsUsedListDisplay } from './AssetPartsUsedListDisplay';
import { AssetCubicRegisterDisplay } from './AssetCubicRegisterDisplay';
import { ENABLE_SHOW_CUBIC_REGISTER } from 'src/appSettings';
import { ENABLE_PARTS_REGISTER } from 'src/appSettings';

type AssetDetailsData = Workshop.Domain.Queries.GetAsset.AssetDetails;
type FutureTaskForAsset = Workshop.Domain.Queries.GetFutureTaskForAsset.FutureTaskForAsset;
type ScheduledTaskForAsset = Workshop.Domain.Queries.GetScheduledTaskForAsset.ScheduledTaskForAsset;
type CompletedJobForAsset = Workshop.Domain.Queries.GetCompletedJobsForAssetForKiosk.CompletedJobForAssetForKioskItem;
type JobDetailsForKioskDto = Workshop.Domain.Queries.Job.GetJobDetailsForKiosk.JobDetailsForKioskDto;
type StartedTaskForAsset = Workshop.Domain.Queries.GetStartedTasksForAsset.StartedTaskForAsset;
type AssetPartItem = Workshop.Domain.Queries.AssetParts.AssetPartItem;
type AssetComponentItem = Workshop.Domain.Queries.AssetComponents.AssetComponentItem;
type AssetOdometerReadingItem = Workshop.Domain.Queries.GetLastAssetOdometerReadings.AssetOdometerReadingItem;
type PartTransactionItem = Workshop.Domain.Queries.GetPartTransactions.PartTransactionItem;
type AssetCubicSummary = Workshop.Domain.Queries.AssetCubic.AssetCubicSummary;
type TechSpecDropdownOptionsItem = Workshop.Domain.Queries.TechSpecs.TechSpecDropdownOptionsItem;
type CubicItem = Workshop.Domain.AggregatesModel.AssetAggregate.CubicItem;

export const getTabProps = (
  loadFutureAndScheduledAndStartedTasks: (assetId: string) => Promise<void>,
  loadCompletedJobs: (assetId: string) => Promise<void>,
  loadPartsRegisterForAsset: (assetId: string) => Promise<void>,
  loadPartsUsedForAsset: (assetId: string) => Promise<void>,
  loadComponentRegisterForAsset: (assetId: string) => Promise<void>,
  loadLastOdometerReading: (assetId: string) => Promise<void>,
  assetId: string,
  futureTasksForAsset: FutureTaskForAsset[] | undefined,
  scheduledTasksForAsset: ScheduledTaskForAsset[] | undefined,
  startedTasksForAsset: StartedTaskForAsset[] | undefined,
  addToCurrentJob: ((taskId: string) => Promise<void>) | undefined,
  currentTaskId: string | undefined,
  currentJob: JobDetailsForKioskDto | undefined,
  jobsForAsset: CompletedJobForAsset[] | undefined,
  asset: AssetDetailsData | undefined,
  parts: AssetPartItem[] | undefined,
  partsUsed: PartTransactionItem[] | undefined,
  components: AssetComponentItem[] | undefined,
  cubicSummary: AssetCubicSummary | undefined,
  loadCubicRegister: (assetId: string) => Promise<void>,
  techSpecsDropdownOptions: TechSpecDropdownOptionsItem[] | undefined,
  loadTechSpecsDropdownOptions: () => Promise<void>,
  deviceDepotId: number,
  cubicItems: CubicItem[] | undefined,
  loadCubicItems: () => Promise<void>,
  lastOdometerReading?: AssetOdometerReadingItem
): ITabProps[] => {
  const showPartsRegisterTab = ENABLE_PARTS_REGISTER;
  const showCubicRegisterTab = ENABLE_SHOW_CUBIC_REGISTER;
  const tabs = [
    {
      title: (
        <>
          <div>Future/Sched.</div>
          <div>Tasks</div>
        </>
      ),
      contents: (
        <AssetScheduledAndFutureTasksDisplay
          assetId={assetId}
          futureTasksForAsset={futureTasksForAsset}
          scheduledTasksForAsset={scheduledTasksForAsset}
          startedTasksForAsset={startedTasksForAsset}
          loadFutureAndScheduledAndStartedTasks={loadFutureAndScheduledAndStartedTasks}
          addToCurrentJob={
            addToCurrentJob
              ? (taskId: string) =>
                  addToCurrentJob(taskId).then(() => loadFutureAndScheduledAndStartedTasks(assetId))
              : undefined
          }
          currentTaskId={currentTaskId}
          currentJob={currentJob}
        />
      ),
    },
    {
      title: (
        <>
          <div>Completed</div>
          <div>Jobs</div>
        </>
      ),
      contents: (
        <AssetCompletedJobsDisplay
          assetId={assetId}
          jobsForAsset={jobsForAsset}
          loadCompletedJobs={loadCompletedJobs}
        />
      ),
    },
    {
      title: (
        <>
          <div>Asset</div>
          <div>Details</div>
        </>
      ),
      contents: <AssetDetailsDisplay asset={asset} />,
    },
    {
      title: (
        <>
          <div>Tech</div>
          <div>Specs</div>
        </>
      ),
      contents: (
        <AssetTechSpecsDisplay
          asset={asset}
          techSpecsDropdownOptions={techSpecsDropdownOptions}
          loadTechSpecsDropdownOptions={loadTechSpecsDropdownOptions}
        />
      ),
    },
    {
      title: (
        <>
          <div>Component</div>
          <div>Register</div>
        </>
      ),
      contents: (
        <AssetComponentRegisterDisplay
          assetId={assetId}
          components={components}
          lastOdometerReading={lastOdometerReading}
          loadComponentRegisterForAsset={loadComponentRegisterForAsset}
          loadLastOdometerReading={loadLastOdometerReading}
        />
      ),
    },
    {
      title: (
        <>
          <div>Parts</div>
          <div>Used List</div>
        </>
      ),
      contents: (
        <AssetPartsUsedListDisplay
          assetId={assetId}
          partsUsed={partsUsed}
          loadPartsUsedForAsset={loadPartsUsedForAsset}
          deviceDepotId={deviceDepotId}
        />
      ),
    },
  ];
  if (showPartsRegisterTab) {
    tabs.push({
      title: (
        <>
          <div>Part</div>
          <div>Register</div>
        </>
      ),
      contents: (
        <AssetPartsRegisterDisplay
          assetId={assetId}
          parts={parts}
          loadPartsRegisterForAsset={loadPartsRegisterForAsset}
          deviceDepotId={deviceDepotId}
        />
      ),
    });
  }
  if (showCubicRegisterTab && asset?.isTranslinkVehicle) {
    tabs.push({
      title: (
        <>
          <div>Cubic</div>
          <div>Register</div>
        </>
      ),
      contents: (
        <AssetCubicRegisterDisplay
          assetId={assetId}
          loadCubicRegister={loadCubicRegister}
          cubicSummary={cubicSummary}
          cubicItems={cubicItems}
          loadCubicItems={loadCubicItems}
        />
      ),
    });
  }
  return tabs;
};
