import 'react-select/dist/react-select.css';
import './SelectPageField.scss';
import { ReplaySubject } from 'rxjs/ReplaySubject';
import { Subscription } from 'rxjs/Subscription';
import { IAutocompleteResult } from 'src/domain/baseTypes';
import deepEqual from 'src/infrastructure/deepEqual';
import { Async, OnChangeHandler } from 'react-select';
import { IFieldApi } from 'src/views/components/Page/forms/Field';
import { LabelledFormField } from 'src/views/components/forms';
import {
  IFieldMeta,
  IFieldData,
  ISelectAsyncMultiFieldDef,
} from 'src/views/definitionBuilders/types';
import SelectAsyncFieldBase from './fieldBases/SelectAsyncFieldBase';

const loadOptionsDebounceMs = 500;

interface ISelectMultiAsyncPageFieldProps {
  fieldDef: ISelectAsyncMultiFieldDef;
  fieldMeta: IFieldMeta;
  // tslint:disable-next-line:no-any
  fieldData: IFieldData<Array<any>>;
  fieldApi: IFieldApi;
}

interface ISelectAsyncMultiPageFieldState {
  isLoadingItems: boolean;
  // tslint:disable-next-line:no-any
  items: any[] | undefined;
}

class SelectAsyncMultiPageField extends SelectAsyncFieldBase<
  ISelectMultiAsyncPageFieldProps,
  ISelectAsyncMultiPageFieldState
> {
  private readonly load$ = new ReplaySubject<() => Promise<void>>(1);
  private readonly rxSubscriptions = new Subscription();

  constructor(props: ISelectMultiAsyncPageFieldProps) {
    super(props);
    this.state = {
      isLoadingItems: false,
      items: undefined,
    };
  }

  componentDidMount() {
    this.rxSubscriptions.add(this.load$.debounceTime(loadOptionsDebounceMs).subscribe(f => f()));
    this.loadItems();
  }

  componentWillUnmount() {
    this.rxSubscriptions.unsubscribe();
  }

  componentDidUpdate(
    prevProps: Readonly<ISelectMultiAsyncPageFieldProps>,
    prevState: Readonly<ISelectAsyncMultiPageFieldState>
  ) {
    this.loadItems();
  }

  loadItems() {
    const { fieldDef: def, fieldData } = this.props;
    const { isLoadingItems } = this.state;
    const currentItems = this.getCurrentItems();

    if (
      currentItems &&
      currentItems.some(i => i.requiresLoad) &&
      !isLoadingItems &&
      def.loadItems
    ) {
      // Need to async load the item to get the description
      const itemValues = currentItems.map(i => i[def.valueKey]);
      def
        .loadItems(itemValues, fieldData)
        .catch(e => ({ options: [] }))
        .then(r => r.options)
        .then(i => this.setState({ isLoadingItems: false, items: i }));

      this.setState({ isLoadingItems: true, items: undefined });
    }
  }

  loadOptions = (search: string) => {
    const { fieldDef, fieldData } = this.props;
    const valuesToDisable = this.getValuesToDisable();
    // tslint:disable-next-line:no-any
    const setDisabledItems = (opts: any) =>
      valuesToDisable && valuesToDisable.length
        ? opts.map((o: {}) => ({
            ...o,
            disabled: valuesToDisable.some(v => o[fieldDef.valueKey] === v),
          }))
        : opts;
    // tslint:disable-next-line:no-any
    return new Promise<IAutocompleteResult<any>>((resolve, reject) => {
      const f = () =>
        fieldDef
          .loadOptionItems(search, fieldData)
          .then(result => {
            const newResult = {
              ...result,
              options: setDisabledItems(result.options),
            };
            resolve(newResult);
          })
          .catch(reject);
      this.load$.next(f);
    });
  };

  // tslint:disable-next-line:no-any
  private readonly getCurrentItems = (): Array<any> => {
    const { fieldDef: def, fieldData: data } = this.props;

    // Any falsie value except zero or an explicit false is considered nothing
    if (!data.fieldValue && data.fieldValue !== 0 && data.fieldValue !== false) {
      return [];
    }

    // Be nice and accept single values not in an array - this is helpful for things like page filters
    const fieldValue = Array.isArray(data.fieldValue) ? data.fieldValue : [data.fieldValue];

    // If the field is "value only", attempt to find a corresponding option items
    if (def.useValueOnly) {
      if (def.loadItems) {
        const { items } = this.state;
        // Use `strict: false` so that string version of numbers matches
        // With filters, where data is held in the url, everything is a string
        if (items) {
          const newFieldValue = [...fieldValue];
          const newItems = [...items];
          if (
            deepEqual(newFieldValue.sort(), newItems.map(i => i[def.valueKey]).sort(), {
              strict: false,
            })
          ) {
            return items;
          }
        }
        return fieldValue.map(v => ({
          [def.valueKey]: v,
          [def.descriptionKey]: 'Loading ...',
          requiresLoad: true,
        }));
      }

      return fieldValue.map(v => ({ [def.valueKey]: v, [def.descriptionKey]: v }));
    }

    return fieldValue;
  };

  private readonly handleChange: OnChangeHandler = selectedOptions => {
    const { fieldDef: def, fieldApi } = this.props;
    if (Array.isArray(selectedOptions)) {
      const newValue =
        def.useValueOnly && selectedOptions.length
          ? selectedOptions.map(o => o[def.valueKey])
          : selectedOptions;
      fieldApi.setValue(newValue);
    } else {
      fieldApi.setValue(undefined);
    }
  };

  render() {
    const { fieldApi, fieldDef: def, fieldMeta: meta, fieldData: data } = this.props;
    const { error, touched } = fieldApi;
    const currentItems = this.getCurrentItems();
    const valueRenderer = def.useOptionRendererAsValueRenderer
      ? def.optionRenderer
      : def.valueRenderer;

    // As this field can have a value which is an object, then errors can be added to "lower" levels,
    // in which case `error` will be an object rather than a string
    const safeError = typeof error === 'string' ? error : undefined;
    const labelText = typeof def.label === 'function' ? def.label(data) : def.label;
    const tooltipText = typeof def.tooltip === 'function' ? def.tooltip(data) : def.tooltip;
    return (
      <LabelledFormField
        key={(def.getFieldKey && def.getFieldKey(data)) || undefined}
        className="select-async-multi-page-field-component"
        readonly={meta.readonly}
        readonlyValue={
          def.formatReadonly
            ? def.formatReadonly(data)
            : currentItems && valueRenderer
            ? valueRenderer(currentItems)
            : currentItems && currentItems[def.descriptionKey]
        }
        readonlyLinkTo={def.linkTo && currentItems ? def.linkTo(data) : undefined}
        mandatory={meta.mandatory}
        hideLabel={meta.hideLabel}
        labelValue={labelText}
        tooltipValue={tooltipText}
        noForm={meta.noForm}
        error={touched && safeError}>
        <Async
          className={!(touched && safeError) ? '' : 'is-invalid'}
          multi
          closeOnSelect={false}
          // disabled={disabled}
          autoFocus={meta.autoFocus}
          clearable={!meta.mandatory}
          autoload={!!def.autoload}
          loadOptions={this.loadOptions}
          filterOptions={this.handleFilterOptions}
          valueKey={def.valueKey}
          labelKey={def.descriptionKey}
          value={currentItems}
          onChange={this.handleChange}
          matchProp="label"
          onBlur={this.handleBlur}
          valueRenderer={valueRenderer}
          optionRenderer={def.optionRenderer}
          placeholder={def.placeholder}
          menuContainerStyle={
            def.menuWidth && def.menuWidth === 'fitContent'
              ? { minWidth: '100%', width: 'fit-content' }
              : undefined
          }
        />
      </LabelledFormField>
    );
  }
}

export default SelectAsyncMultiPageField;
