import './Status.scss';
import { cctvRequestStatusDescription, getCctvRequestStatusDescriptor } from 'src/api/enums';

interface IStatusProps {
  cctvRequestStatus: Operations.Domain.AggregatesModel.CctvRequestAggregate.CctvRequestStatus;
}

const Status: React.FC<IStatusProps> = ({ cctvRequestStatus }) => {
  return (
    <span className={`status-component ${getCctvRequestStatusDescriptor(cctvRequestStatus).name}`}>
      {cctvRequestStatusDescription(cctvRequestStatus)}
    </span>
  );
};

export default Status;
