import { saveAs } from 'file-saver';
import { DateTime } from 'luxon';
import { observer } from 'mobx-react';
import { useEffect, useRef, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { ChangeState, TechSpecDataType } from 'src/api/enums';
import { ENABLE_SHOW_CUBIC_REGISTER } from 'src/appSettings';
import { useRootStore } from 'src/domain/entities/RootStoreModel';
import { AssetCategoryType, AssetSubcategoryType } from 'src/domain/enums';
import { BanIcon, ExcelIcon, FilePdfIcon, SpinnerIcon } from 'src/images/icons';
import deepEqual from 'src/infrastructure/deepEqual';
import Omit from 'src/infrastructure/omit';
import { GetDAAndDL } from 'src/views/components/LicenceTypesHelper';
import DatePageField from 'src/views/components/Page/fields/DatePageField';
import SelectPageField from 'src/views/components/Page/fields/SelectPageField';
import TextPageField from 'src/views/components/Page/fields/TextPageField';
import YesNoPageField from 'src/views/components/Page/fields/YesNoPageField';
import CrudPage, { CrudPageMode, ICrudPageDef } from 'src/views/components/Page/pages/CrudPage';
import { getSubmitCloseModalActionGroupDef } from 'src/views/definitionBuilders/common';
import {
  ActionType,
  FieldType,
  PagePrimarySize,
  PaneType,
  SectionDefs,
  ShellModalSize,
} from 'src/views/definitionBuilders/types';
import { getLicenceClasses } from 'src/views/routes/people/staffMembers/MaintainStaffMember/getLicenceModalDef';
import './MaintainAsset.scss';
import { getAssetSecondarySectionDefs } from './getAssetSecondarySectionDefs';

type AssetDetails = Workshop.Domain.Queries.GetAsset.AssetDetails;
type CreateAssetCommand = Workshop.Domain.Commands.Asset.CreateAssetCommand;
type UpdateAssetCommand = Workshop.Domain.Commands.Asset.UpdateAssetCommand;
type AssetSubcategory = Workshop.Domain.AggregatesModel.AssetAggregate.AssetSubcategory;
type OwnerSelectListItem = Workshop.Domain.Queries.Owners.GetAllOwners.OwnerSelectListItem;
type LicenceClass = Common.AggregatesModel.People.StaffMemberAggregate.LicenceClass;

type AssetTechSpecItem = Workshop.Domain.Queries.GetAsset.AssetTechSpecItem;

type NullableTechSpec = Omit<Workshop.Domain.Commands.Asset.CrudAssetTechSpecItem, 'value'> & {
  value: string | null;
};

export interface IMaintainAssetProps {
  mode: CrudPageMode;
  route: RouteComponentProps<{ [x: string]: string | undefined }>;
}

const fuelEntries: number = 100;

const CAN_BE_REGISTERED_SUBCATEGORY_IDS = [
  AssetSubcategoryType.Bus,
  AssetSubcategoryType.Car,
  AssetSubcategoryType.Trailer,
];
const showCubicRegisterTab = ENABLE_SHOW_CUBIC_REGISTER;

const MaintainAsset: React.FC<IMaintainAssetProps> = observer((props: IMaintainAssetProps) => {
  const rootStore = useRootStore();
  const canManageAssets = rootStore.account.isWorkshopDepartmentMember;
  const asset = rootStore.asset.asset;
  const assetCategories = rootStore.asset.categories.slice();
  const assetSubcategories = rootStore.asset.subcategories.slice();
  const assetHousingLocations = rootStore.asset.housedAtLocations.slice();
  const assetGroups = rootStore.assetGroups.assetGroupsListItems.slice();
  const engineModels = rootStore.workshop.engine.engineListItems.slice();
  const defects = rootStore.asset.defects.slice();
  const futureJobs = rootStore.assets.futureJobs.slice();
  const tasks = rootStore.assets.tasks.slice();
  const serviceHistoryItems = rootStore.assets.serviceHistoryItems.slice();
  const vehicleTypes = rootStore.asset.vehicleTypes.vehicleTypes.slice();
  const jobsItem = rootStore.jobs.jobsForAsset;
  const assetGroup = rootStore.assetGroup.assetGroup;
  const nextService = rootStore.asset.nextService;
  const attachments = rootStore.workshopAttachments.attachments.slice();
  const fleetSubcategories = rootStore.asset.fleetSubcategories.slice();

  const onLoadAsset = rootStore.asset.loadAsset;
  const onUpdateAsset = rootStore.asset.updateAsset;
  const onCreateAsset = rootStore.asset.createAsset;

  const loadAssetCategories = rootStore.asset.loadAssetCategories;
  const loadAssetHousedAtLocations = rootStore.asset.loadAssetHousedAtLocations;
  const loadSubcategories = rootStore.asset.loadAssetSubcategories;
  const loadEngines = rootStore.workshop.engine.listEngines;
  const loadAssetGroups = rootStore.assetGroups.listAssetGroups;
  const owners = rootStore.workshop.owners.allOwners.slice();

  const checkForUniqueAssetName = rootStore.asset.checkForUniqueName;

  const checkForUniqueAssetRegistrationNumber = rootStore.asset.checkForUniqueRegistrationNumber;
  const getDefectsForAsset = rootStore.asset.getDefectsForAsset;
  const loadJobsForAsset = rootStore.jobs.loadJobsForAsset;
  const loadTasksForAsset = rootStore.assets.getTasksForAsset;
  const loadAssetGroupForAsset = rootStore.assetGroup.loadAssetGroupForAsset;
  const loadNextServiceDue = rootStore.asset.getNextService;
  const onScheduleService = rootStore.asset.scheduleService;
  const onLoadAssetFutureJobs = rootStore.assets.getFutureJobsForAsset;
  const onCancelService = rootStore.asset.cancelAssetService;
  const loadServiceHistory = rootStore.assets.loadServiceHistory;
  const generateAssetJobReport = rootStore.asset.assetReports.generateAssetJobReport;

  const onDecommissionAsset = rootStore.asset.decommission;

  const loadVehicleTypes = rootStore.asset.vehicleTypes.loadVehicleTypes;

  const generateAssetReport = rootStore.asset.assetReports.generateAssetReport;
  const generateAssetKmsReport = rootStore.asset.assetReports.generateAssetKmsReport;
  const assetFuels = rootStore.asset.assetFuels.slice();
  const loadAssetFuels = rootStore.asset.loadAssetFuels;
  const assetFuelLocations = rootStore.assets.assetFuelLocations.slice();
  const loadAssetFuelLocations = rootStore.assets.loadAssetFuelLocations;

  const staffMembers = rootStore.people.staffMembers.activeStaffMembers.slice();
  const loadStaffMembers = rootStore.people.staffMembers.loadAllStaffMembers;

  const createAdHocFuel = rootStore.asset.createAdHocFuel;
  const updateAdHocFuel = rootStore.asset.updateAdHocFuel;
  const getTechSpecDropdownsOptions = rootStore.workshop.techSpecs.getTechSpecDropdownsOptions;
  const techSpecDropdownOptions = rootStore.workshop.techSpecs.techSpecDropdownOptions;

  const addAssetAttachment = rootStore.workshopAttachments.addAssetAttachment;
  const loadAttachmentsForAsset = rootStore.workshopAttachments.loadWorkshopAttachmentDetails;
  const downloadAttachmentForAsset = rootStore.workshopAttachments.downloadWorkshopAttachment;
  const deleteAttachmentFromAsset = rootStore.workshopAttachments.deleteWorkshopAttachment;
  const updateAttachmentForAsset = rootStore.workshopAttachments.updateAssetAttachment;

  const loadAllLicenceTypes = rootStore.people.licences.loadAllLicenceTypes;
  const allLicenceTypes = rootStore.people.licences.allLicenceTypes.slice();

  const workshopDepots = rootStore.workshopStartup.workshopDepots.slice();
  const defaultWorkshopDepot = rootStore.workshopStartup.defaultWorkshopDepot;
  const [includeTabletData, setIncludeTabletData] = useState<boolean>(false);
  const [, setIsAssetDataLoading] = useState<boolean | undefined>(undefined);
  const [isOtherDataLoading, setIsOtherDataLoading] = useState<boolean | undefined>(undefined);
  const prevParams = useRef(props.route.match.params.id);

  const assetCanBeRegistered = (subcategory: AssetSubcategory) => {
    return subcategory && CAN_BE_REGISTERED_SUBCATEGORY_IDS.includes(subcategory.id);
  };

  const isUpdateMode = props.mode === 'update';
  const isCreateMode = props.mode === 'create';
  const assetId = props.route.match.params.id!;

  useEffect(() => {
    loadAssetSpecificData();
    loadOtherData();
    loadAllLicenceTypes();
  }, []);

  useEffect(() => {
    if (prevParams.current !== assetId) {
      loadAssetSpecificData();
    }
  }, [assetId]);

  const loadAssetSpecificData = () => {
    if (assetId) {
      setIsAssetDataLoading(true);

      Promise.all([
        getDefectsForAsset(assetId),
        loadJobsForAsset(assetId),
        loadTasksForAsset(assetId),
        loadAssetGroupForAsset(assetId),
        loadNextServiceDue(assetId),
        onLoadAssetFutureJobs(assetId),
        loadServiceHistory(assetId),
        loadAssetFuels(assetId, includeTabletData, fuelEntries, true),
        loadAttachmentsForAsset({ aggregateId: assetId, aggregateType: 'asset' }),
      ]).finally(() => setIsAssetDataLoading(false));
    }
  };

  const loadOtherData = () => {
    setIsOtherDataLoading(true);

    Promise.all([
      loadStaffMembers(),
      loadAssetFuelLocations(),
      loadVehicleTypes(),
      loadAssetHousedAtLocations(),
      loadAssetCategories(),
      loadSubcategories(),
      loadEngines(),
      loadAssetGroups(false),
      getTechSpecDropdownsOptions(),
    ]).finally(() => setIsOtherDataLoading(false));
  };

  const filteredOwners = (): Array<OwnerSelectListItem> => {
    if (isCreateMode || isUpdateMode) {
      return owners.filter(a => a.isActive);
    }
    return owners;
  };

  const downloadAssetJobReport = (startDate: string, endDate: string, internalUse: boolean) => {
    const date = DateTime.local().toFormat('yyyyMMddHHmm');
    return generateAssetJobReport({
      assetId: assetId,
      startDate: startDate,
      endDate: endDate,
      internalUse: internalUse,
    }).then(r => {
      saveAs(r, `Asset_Job_Detailed_Parts_And_Labour_Report_${date}.pdf`);
    });
  };

  const downloadAssetKmsReport = (from: string, to: string) => {
    const date = DateTime.local().toFormat('yyyyMMddHHmm');
    return generateAssetKmsReport(assetId, from, to).then(r => {
      saveAs(r, `Asset_${asset ? asset.name : ''}_Kms_JobType_Report_${date}.xlsx`);
    });
  };

  const downloadAssetReport = () => {
    const date = DateTime.local().toFormat('yyyyMMddHHmm');
    return generateAssetReport(assetId).then(r => {
      saveAs(r, `Asset_Report_${date}.pdf`);
    });
  };

  const handlePreSubmitForCreate = (a: AssetDetails): CreateAssetCommand => {
    return {
      assetCategoryId: a.category.id,
      assetGroupId: a.assetGroup ? a.assetGroup.id : undefined,
      certificateOfInspectionExpiryDate: a.hasCertificateOfInspection
        ? a.certificateOfInspectionExpiryDate
        : undefined,
      certificateOfInspectionNumber: a.hasCertificateOfInspection
        ? a.certificateOfInspectionNumber
        : '',
      engineId: a.engineModel ? a.engineModel.id : undefined,
      ownerId: a.owner.id,
      firstRegisteredDate: a.firstRegisteredDate,
      hasCertificateOfInspection: a.hasCertificateOfInspection,
      housingLocationId: a.housingLocation.id,
      name: a.name,
      subcategoryId: a.subcategory.id,
      isLowFloor: a.subcategory.id === AssetSubcategoryType.Bus ? a.isLowFloor || false : false,
      registrationDueDate: a.registrationDueDate,
      registrationNumber: a.registrationNumber,
      seatCapacity: a.seatCapacity,
      vehicleTypeId: a.vehicleType ? a.vehicleType.id : undefined,
      licenceClassId: a.licenceClass?.id,
      isFatigueRegulatedVehicle:
        a.subcategory.id === AssetSubcategoryType.Bus
          ? a.isFatigueRegulatedVehicle || false
          : false,
    };
  };

  const handlePreSubmitForUpdate = (
    a: AssetDetails
  ): Omit<UpdateAssetCommand, 'techSpecs'> & { techSpecs: NullableTechSpec[] } => {
    const getChangeState = (techSpec: AssetTechSpecItem) => {
      const originalSpec =
        asset &&
        asset.techSpecs &&
        asset.techSpecs.find((ts: any) => ts.id != null && ts.id === techSpec.id);

      return techSpec.changeState === ChangeState.Added || originalSpec === undefined
        ? ChangeState.Added
        : deepEqual(techSpec, originalSpec)
        ? ChangeState.Unchanged
        : ChangeState.Modified;
    };

    return {
      rowVersion: a.rowVersion,
      assetId: assetId,
      assetGroupId: a.assetGroup ? a.assetGroup.id : undefined,
      certificateOfInspectionExpiryDate: a.hasCertificateOfInspection
        ? a.certificateOfInspectionExpiryDate
        : undefined,
      certificateOfInspectionNumber: a.hasCertificateOfInspection
        ? a.certificateOfInspectionNumber
        : '',
      engineId: a.engineModel ? a.engineModel.id : undefined,
      ownerId: a.owner.id,
      firstRegisteredDate: a.firstRegisteredDate,
      hasCertificateOfInspection: a.hasCertificateOfInspection,
      housingLocationId: a.housingLocation.id,
      name: a.name,
      subcategoryId: a.subcategory.id,
      isLowFloor: a.subcategory.id === AssetSubcategoryType.Bus ? a.isLowFloor || false : false,
      registrationDueDate: a.registrationDueDate,
      registrationNumber: a.registrationNumber,
      seatCapacity: a.seatCapacity,
      vehicleTypeId: a.vehicleType ? a.vehicleType.id : undefined,
      licenceClassId: a.licenceClass?.id,
      isFatigueRegulatedVehicle:
        a.subcategory.id === AssetSubcategoryType.Bus && !!a.isFatigueRegulatedVehicle
          ? a.isFatigueRegulatedVehicle
          : false,
      techSpecs: a.techSpecs.map(v => {
        return {
          ...v,
          value:
            v.value === null || v.value === undefined || v.value === '' ? null : v.value.toString(),
          changeState: getChangeState(v),
        };
      }),
    };
  };

  const loadingSecondarySectionDefs = (): SectionDefs[] => {
    return [
      {
        title: '',
        panels: [
          {
            panes: [
              {
                paneType: PaneType.customPane,
                render: () => (
                  <>
                    <SpinnerIcon fixedWidth size="1x" />
                    <span> Loading asset data...</span>
                  </>
                ),
              },
            ],
          },
        ],
      },
    ];
  };

  const getPageDef = (mode: CrudPageMode, updating: boolean): ICrudPageDef => {
    const { DL } = GetDAAndDL(allLicenceTypes);
    const dlClasses: Array<LicenceClass> = getLicenceClasses(allLicenceTypes).filter(
      l => l.licenceTypeId === DL?.id ?? 1
    );

    return {
      primarySize: PagePrimarySize.half,
      primarySection: {
        title: isUpdateMode ? 'Manage Asset' : 'Create an Asset',
        badge:
          !asset || !asset.isDecommissioned || isCreateMode
            ? undefined
            : {
                label: `Decommissioned on ${DateTime.fromISO(
                  asset.decommissionedOn!
                ).toLocaleString(DateTime.DATE_SHORT)}`,
              },
        primaryActions: [
          {
            actions: [
              {
                actionType: ActionType.actionCollection,
                hidden: updating || !isUpdateMode,
                actionGroups: [
                  {
                    actions: [
                      {
                        actionType: ActionType.modalActionButton,
                        label: 'Generate Asset Job Detailed Parts And Labour Report',
                        icon: <FilePdfIcon fixedWidth />,
                        modalSize: ShellModalSize.oneThird,
                        modalDef: () => ({
                          title: 'Generate Asset Job Detailed Parts And Labour Report',
                          asForm: true,
                          panels: [
                            {
                              panes: [
                                {
                                  paneType: PaneType.formFieldsPane,
                                  columnCount: 2,
                                  fields: [
                                    {
                                      fieldType: FieldType.dateField,
                                      label: 'Start Date',
                                      dataAddr: 'startDate',
                                      mandatory: true,
                                    },
                                    {
                                      fieldType: FieldType.dateField,
                                      label: 'End Date',
                                      dataAddr: 'endDate',
                                      mandatory: true,
                                      validate: d =>
                                        DateTime.fromISO(d.parentValue.endDate) <
                                        DateTime.fromISO(d.parentValue.startDate)
                                          ? 'End must be after Start'
                                          : undefined,
                                    },
                                  ],
                                },
                                {
                                  paneType: PaneType.formFieldsPane,
                                  fields: [
                                    {
                                      fieldType: FieldType.yesNoField,
                                      label: 'Internal Use',
                                      dataAddr: 'internalUse',
                                      mandatory: true,
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                          secondaryActions: [getSubmitCloseModalActionGroupDef('Ok')],
                          onFormSubmit: v =>
                            downloadAssetJobReport(v.startDate, v.endDate, v.internalUse),
                        }),
                      },
                      {
                        actionType: ActionType.modalActionButton,
                        label: 'Generate KMs Report',
                        icon: <ExcelIcon fixedWidth />,
                        modalSize: ShellModalSize.oneThird,
                        modalDef: () => ({
                          title: 'Generate KMs Report',
                          asForm: true,
                          panels: [
                            {
                              panes: [
                                {
                                  paneType: PaneType.formFieldsPane,
                                  columnCount: 2,
                                  fields: [
                                    {
                                      fieldType: FieldType.dateField,
                                      label: 'From',
                                      dataAddr: 'from',
                                      mandatory: true,
                                    },
                                    {
                                      fieldType: FieldType.dateField,
                                      label: 'To',
                                      dataAddr: 'to',
                                      mandatory: true,
                                      validate: d =>
                                        DateTime.fromISO(d.parentValue.to) <
                                        DateTime.fromISO(d.parentValue.from)
                                          ? 'To must be after Start'
                                          : undefined,
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                          secondaryActions: [getSubmitCloseModalActionGroupDef('Ok')],
                          onFormSubmit: v => {
                            return downloadAssetKmsReport(v.from, v.to);
                          },
                        }),
                      },
                      {
                        actionType: ActionType.actionButton,
                        label: 'Generate Asset Report',
                        icon: <FilePdfIcon fixedWidth />,
                        onClick: () => downloadAssetReport(),
                      },
                      {
                        actionType: ActionType.modalActionButton,
                        label: 'Decommission',
                        icon: <BanIcon fixedWidth />,
                        hidden: !asset || asset.isDecommissioned || !canManageAssets,
                        modalSize: ShellModalSize.oneQuarter,
                        modalDef: _ => ({
                          title: 'Decommission Asset',
                          asForm: true,
                          panels: [
                            {
                              panes: [
                                {
                                  paneType: PaneType.customPane,
                                  render: __ => (
                                    <div>
                                      <p>
                                        All upcoming service schedules and inspections will be
                                        cleared and creating defects, tasks, jobs or purchase orders
                                        won't be possible for this asset.
                                      </p>
                                      <p>Are you sure you want to decommission this asset?</p>
                                    </div>
                                  ),
                                },
                              ],
                            },
                          ],
                          secondaryActions: [getSubmitCloseModalActionGroupDef('Ok')],
                          onFormSubmit: () => onDecommissionAsset(assetId),
                        }),
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
        panels: [
          {
            panes: [
              {
                paneType: PaneType.formFieldsPane,
                columnCount: 1,
                fields: [
                  {
                    fieldType: FieldType.textField,
                    label: 'Name',
                    dataAddr: 'name',
                    maxLength: 255,
                    mandatory: true,
                    validateAsync: async d => {
                      if (
                        !d.fieldValue ||
                        (isUpdateMode &&
                          asset &&
                          asset.name.toUpperCase() === d.fieldValue.toUpperCase())
                      ) {
                        return undefined;
                      }
                      const result = await checkForUniqueAssetName(d.fieldValue);
                      return result.nameExists ? `Name is already in use` : undefined;
                    },
                  },
                ],
              },
            ],
          },
          {
            panes: [
              {
                paneType: PaneType.formFieldsPane,
                columnCount: 2,
                fields: [
                  {
                    fieldType: FieldType.selectField,
                    dataAddr: 'category',
                    label: 'Category',
                    valueKey: 'id',
                    descriptionKey: 'description',
                    mandatory: true,
                    readonly: isUpdateMode,
                    optionItems: assetCategories,
                    tooltip: isUpdateMode
                      ? ''
                      : 'Assigning the asset to the Fleet category specifies that this asset is a revenue-generating asset that can be allocated to operations jobs such as urban route services, school services, and charters. Assigning the asset to the Plant category specifies that the asset is not a revenue-generating asset and is only used by your organisation for tracking services and repairs by the workshop team.',
                    onChange: api => {
                      if (api.newFieldValue && api.newFieldValue.id === AssetCategoryType.Plant) {
                        const newValues: AssetDetails = {
                          ...api.formValues,
                          subcategory: null,
                          vehicleType: null,
                          firstRegisteredDate: null,
                          hasCertificateOfInspection: null,
                          registrationDueDate: null,
                          registrationNumber: null,
                          certificateOfInspectionExpiryDate: null,
                          certificateOfInspectionNumber: null,
                        };
                        api.setFormValues(newValues);
                      }
                    },
                  },
                  {
                    fieldType: FieldType.selectField,
                    dataAddr: 'subcategory',
                    label: 'Subcategory',
                    valueKey: 'id',
                    descriptionKey: 'description',
                    mandatory: true,
                    optionItems: api => {
                      if (
                        api.paneValue &&
                        api.paneValue.category &&
                        api.paneValue.category.id === AssetCategoryType.Fleet
                      ) {
                        return fleetSubcategories;
                      }
                      return assetSubcategories;
                    },
                    onChange: api => {
                      if (api.newFieldValue && api.newFieldValue.id !== AssetSubcategoryType.Bus) {
                        const newValues: AssetDetails = {
                          ...api.formValues,
                          isLowFloor: false,
                        };
                        api.setFormValues(newValues);
                      }
                    },
                  },
                ],
              },

              {
                paneType: PaneType.formFieldsPane,
                columnCount: 2,
                fields: [
                  {
                    hidden: d =>
                      !d.paneValue ||
                      !d.paneValue.category ||
                      d.paneValue.category.id !== AssetCategoryType.Fleet,
                    fieldType: FieldType.selectField,
                    dataAddr: 'vehicleType',
                    label: 'Vehicle type',
                    valueKey: 'id',
                    descriptionKey: 'description',
                    optionItems: vehicleTypes,
                    tooltip:
                      'When allocating vehicles to charter jobs, the operations team will be able to align the required vehicle based on the category or class that has been quoted.',
                  },
                  {
                    hidden: d =>
                      !d.paneValue ||
                      !d.paneValue.category ||
                      d.paneValue.category.id !== AssetCategoryType.Fleet,
                    fieldType: FieldType.selectField,
                    dataAddr: 'licenceClass',
                    label: 'Licence Class',
                    valueKey: 'id',
                    descriptionKey: 'description',
                    optionItems: dlClasses,
                  },
                  {
                    hidden: d =>
                      !d.paneValue ||
                      !d.paneValue.subcategory ||
                      d.paneValue.subcategory.id !== AssetSubcategoryType.Bus,
                    fieldType: FieldType.yesNoField,
                    dataAddr: 'isLowFloor',
                    label: 'Is low floor',
                    mandatory: true,
                  },
                ],
              },
            ],
          },
          {
            panes: [
              {
                paneType: PaneType.formFieldsPane,
                columnCount: 2,
                fields: [
                  {
                    fieldType: FieldType.selectField,
                    dataAddr: 'owner',
                    label: 'Owner',
                    valueKey: 'id',
                    descriptionKey: 'name',
                    optionItems: filteredOwners,
                    mandatory: true,
                    linkTo: d => `/workshop/owners/${d.parentValue.owner.id}`,
                  },
                  {
                    fieldType: FieldType.selectField,
                    dataAddr: 'housingLocation',
                    label: 'Garaged at',
                    valueKey: 'id',
                    descriptionKey: 'description',
                    mandatory: true,
                    optionItems: assetHousingLocations,
                    tooltip:
                      "Indicate where this asset is currently housed. Selecting 'Owner' indicates that the asset is housed at the owner's premises.",
                  },
                  {
                    fieldType: FieldType.selectField,
                    dataAddr: 'assetGroup',
                    label: 'Asset Group',
                    valueKey: 'id',
                    descriptionKey: 'description',
                    optionItems: assetGroups,
                    tooltip: 'Indicates the service plan that the asset belongs to.',
                    linkTo: d => `/workshop/asset-groups/${d.parentValue.assetGroup.id}`,
                  },
                  {
                    fieldType: FieldType.selectField,
                    dataAddr: 'engineModel',
                    label: 'Engine Model',
                    valueKey: 'id',
                    descriptionKey: 'model',
                    optionItems: engineModels,
                    tooltip: 'Determines the parts required when scheduling services.',
                    linkTo: d => `/workshop/engines/${d.parentValue.engineModel.id}`,
                  },

                  {
                    hidden: d =>
                      !d.paneValue ||
                      !d.paneValue.subcategory ||
                      d.paneValue.subcategory.id !== AssetSubcategoryType.Bus,
                    fieldType: FieldType.yesNoField,
                    dataAddr: 'isFatigueRegulatedVehicle',
                    label: 'Fatigue Regulated Vehicle',
                    mandatory: true,
                    tooltip:
                      'Indicates if this asset is a heavy vehicle and subject to fatigue regulations.',
                  },
                  {
                    hidden: d =>
                      !d.panelValue ||
                      !d.panelValue.category ||
                      d.panelValue.category.id !== AssetCategoryType.Fleet,
                    fieldType: FieldType.numericField,
                    numericConfig: { numericType: 'unsignedInt', minValue: 0 },
                    label: 'Seat Capacity',
                    dataAddr: 'seatCapacity',
                    mandatory: true,
                    tooltip:
                      'The order in which assets are shown in operations views is based on the seat capacity entered here.',
                  },
                ],
              },
            ],
          },
          {
            hidden: d => !d.panelValue || !assetCanBeRegistered(d.panelValue.subcategory),
            panes: [
              {
                paneType: PaneType.formFieldsPane,
                columnCount: 2,
                fields: [
                  {
                    fieldType: FieldType.textField,
                    label: 'Registration Number',
                    dataAddr: 'registrationNumber',
                    maxLength: 10,
                    mandatory: true,
                    validateAsync: async d => {
                      if (
                        !d.fieldValue ||
                        (isUpdateMode &&
                          asset &&
                          asset.registrationNumber?.toUpperCase() === d.fieldValue.toUpperCase())
                      ) {
                        return undefined;
                      }
                      const result = await checkForUniqueAssetRegistrationNumber(d.fieldValue);
                      return result.nameExists
                        ? `Registration Number is already in use`
                        : undefined;
                    },
                  },
                ],
              },
              {
                paneType: PaneType.formFieldsPane,
                columnCount: 2,
                fields: [
                  {
                    fieldType: FieldType.dateField,
                    label: 'First Registered',
                    dataAddr: 'firstRegisteredDate',
                    mandatory: true,
                  },
                  {
                    fieldType: FieldType.dateField,
                    label: 'Registration Due',
                    dataAddr: 'registrationDueDate',
                    mandatory: true,
                  },
                ],
              },
            ],
          },
          {
            hidden: d => !d.panelValue || !assetCanBeRegistered(d.panelValue.subcategory),
            panes: [
              {
                paneType: PaneType.formFieldsPane,
                fields: [
                  {
                    fieldType: FieldType.yesNoField,
                    dataAddr: 'hasCertificateOfInspection',
                    label: 'Does this asset need heavy vehicle inspections',
                    mandatory: true,
                    onChange: api => {
                      if (!api.newFieldValue) {
                        const newValues: AssetDetails = {
                          ...api.formValues,
                          certificateOfInspectionExpiryDate: null,
                          certificateOfInspectionNumber: null,
                        };
                        api.setFormValues(newValues);
                      }
                    },
                    tooltip:
                      'Referred to as certificate of inspection (COI) in QLD or HVIS in other states.',
                  },
                ],
              },
              {
                paneType: PaneType.formFieldsPane,
                columnCount: 2,
                hidden: d => !d.paneValue.hasCertificateOfInspection,
                fields: [
                  {
                    fieldType: FieldType.textField,
                    label: 'Inspection No',
                    dataAddr: 'certificateOfInspectionNumber',
                    maxLength: 11,
                    mandatory: true,
                    tooltip: 'For QLD this is the certificate of inspection (COI) number.',
                  },
                  {
                    fieldType: FieldType.dateField,
                    label: 'Inspection Expiry Date',
                    dataAddr: 'certificateOfInspectionExpiryDate',
                    mandatory: true,
                    tooltip: 'For QLD this is the certificate of inspection (COI) expiry date.',
                  },
                ],
              },
            ],
          },
          {
            title: 'Tech Specs',
            hidden: !isUpdateMode,
            dataAddr: 'techSpecs',
            panes: [
              {
                paneType: PaneType.tablePane,
                fields: [
                  {
                    fieldType: FieldType.readonlyField,
                    dataAddr: 'techSpecDescription',
                    label: 'Description',
                  },
                  {
                    fieldType: FieldType.customField,
                    dataAddr: 'value',
                    label: 'Value',
                    render: api => {
                      const spec = api.data.parentValue as AssetTechSpecItem;
                      switch (spec.techSpecDataType) {
                        case TechSpecDataType.String:
                          return (
                            <TextPageField
                              fieldDef={{
                                fieldType: FieldType.textField,
                                dataAddr: 'value',
                                label: '',
                              }}
                              fieldMeta={api.meta}
                              fieldData={api.data}
                              fieldApi={api.fieldApi}
                            />
                          );
                        case TechSpecDataType.Bool:
                          return (
                            <YesNoPageField
                              fieldDef={{
                                fieldType: FieldType.yesNoField,
                                dataAddr: 'value',
                                label: '',
                              }}
                              fieldMeta={api.meta}
                              fieldData={api.data}
                              fieldApi={api.fieldApi}
                            />
                          );
                        case TechSpecDataType.Date:
                          return (
                            <DatePageField
                              fieldDef={{
                                fieldType: FieldType.dateField,
                                dataAddr: 'value',
                                label: '',
                              }}
                              fieldMeta={api.meta}
                              fieldData={api.data}
                              fieldApi={api.fieldApi}
                            />
                          );
                        case TechSpecDataType.Dropdown:
                          return (
                            <SelectPageField
                              fieldDef={{
                                fieldType: FieldType.selectField,
                                dataAddr: 'value',
                                label: '',
                                optionItems: () => {
                                  return techSpecDropdownOptions(spec.techSpecId);
                                },
                                valueKey: 'id',
                                descriptionKey: 'description',
                                useValueOnly: true,
                              }}
                              fieldMeta={api.meta}
                              fieldData={api.data}
                              fieldApi={api.fieldApi}
                            />
                          );
                        default:
                          throw new Error(`${spec.techSpecDataType} is not supported.`);
                      }
                    },
                  },
                ],
              },
            ],
          },
        ],
        onFormPreSubmit: isUpdateMode ? handlePreSubmitForUpdate : handlePreSubmitForCreate,
        onFormSubmit: isUpdateMode ? onUpdateAsset : onCreateAsset,
      },
      secondarySections: isUpdateMode
        ? isOtherDataLoading === false
          ? getAssetSecondarySectionDefs(
              defects,
              jobsItem,
              tasks,
              assetId,
              nextService,
              serviceHistoryItems,
              canManageAssets,
              onScheduleService,
              futureJobs,
              workshopDepots,
              defaultWorkshopDepot,
              onCancelService,
              showCubicRegisterTab,
              asset,
              assetFuels,
              fuelEntries,
              updateAdHocFuel,
              includeTabletData,
              staffMembers,
              assetFuelLocations,
              createAdHocFuel,
              loadAssetFuels,
              setIncludeTabletData,
              addAssetAttachment,
              loadAttachmentsForAsset,
              downloadAttachmentForAsset,
              deleteAttachmentFromAsset,
              updateAttachmentForAsset,
              attachments,
              assetGroup
            )
          : loadingSecondarySectionDefs()
        : [],
    };
  };

  return (
    <CrudPage
      def={({ updating, readonly }) => getPageDef(props.mode, updating)}
      mode={props.mode}
      isEditingForbidden={!canManageAssets}
      onLoadData={() => onLoadAsset(assetId)}
      data={asset}
      createDefaultData={{ asset: {} }}
    />
  );
});

export default MaintainAsset;
