import { types, flow, getRoot } from 'mobx-state-tree';
import { getAjax } from 'src/domain/services/storeEnvironment';
import { IRootStoreModel } from 'src/domain/entities/RootStoreModel';
import { NotificationType } from 'src/domain';

type ActivityLogTransaction = Operations.Domain.Queries.GetActivityLog.ActivityLogTransaction;
type QuoteItem = Operations.Domain.Queries.ViewQuote.QuoteItem;
type JobsForCancellingQuoteDto = Operations.Domain.Queries.GetJobsForCancellingQuote.JobsForCancellingQuoteDto;

export const QuoteItemModel = types
  .model('QuoteItemModel', {
    item: types.maybe(types.frozen<QuoteItem>()),
    activityLogs: types.array(types.frozen<ActivityLogTransaction>()),
    jobsForCancellingQuote: types.maybe(types.frozen<JobsForCancellingQuoteDto>()),
  })
  .actions(self => {
    const ajax = getAjax(self);
    const root = getRoot(self) as IRootStoreModel;

    const listActivityLogs = flow(function*(quoteId: string) {
      self.activityLogs = yield ajax.raw
        .get(
          `/api/operations/activity-logs?aggregateType=Quote&aggregateId=${quoteId}&includeSourceAggregateMatches=false`
        )
        .toPromise()
        .then<Operations.Domain.Queries.GetActivityLog.ActivityLogTransaction[]>(r => {
          return r.response;
        });
    });

    const createQuote = flow(function*(
      promise: Promise<Operations.Domain.Commands.Quote.CreateQuoteCommand>
    ) {
      const command = yield promise;
      const id = yield ajax.sales.quote.createQuote(command);
      root.notifications.addNotification(`Successfully created quote`, {
        type: NotificationType.success,
      });
      self.item = undefined;
      root.history.push(`/sales/quotes/${id}`);
      yield loadQuote(id);
    });

    const clearQuote = () => {
      self.item = undefined;
    };

    const loadQuote = flow(function*(quoteId: string) {
      self.item = yield ajax.sales.quote.viewQuote(quoteId);
    });

    const getQuote = flow(function*(quoteId: string) {
      yield loadQuote(quoteId);
      return self.item as QuoteItem;
    });

    const updateQuote = flow(function*(
      promise: Promise<Operations.Domain.Commands.Quote.UpdateQuote.UpdateQuoteCommand>,
      isQuote: boolean,
      otherBookings: number
    ) {
      const command = yield promise;
      yield ajax.sales.quote.updateQuote(command);
      let text = isQuote ? 'quote' : 'booking';
      text =
        command.updateAll && otherBookings > 0
          ? `${text} and ${otherBookings} future bookings`
          : text;
      root.notifications.addNotification(`Successfully updated ${text}`, {
        type: NotificationType.success,
      });
      yield loadQuote(command.id);
      yield listActivityLogs(command.id);
    });

    const bookQuote = flow(function*(
      command: Operations.Domain.Commands.Quote.BookQuote.BookQuoteCommand
    ) {
      yield ajax.sales.quote.bookQuote(command);
      root.notifications.addNotification(`Successfully booked quote`, {
        type: NotificationType.success,
      });
      yield loadQuote(command.quoteId);
      yield listActivityLogs(command.quoteId);
    });

    const verifyBooking = flow(function*(quoteId: string) {
      yield ajax.sales.quote.verifyBooking(quoteId);
      root.notifications.addNotification(`Successfully verified booking`, {
        type: NotificationType.success,
      });
      yield loadQuote(quoteId);
      yield listActivityLogs(quoteId);
    });

    const cancelBooking = flow(function*(
      command: Operations.Domain.Commands.Quote.CancelBooking.CancelBookingCommand
    ) {
      yield ajax.sales.quote.cancelBooking(command);
      root.notifications.addNotification(`Successfully cancelled booking`, {
        type: NotificationType.success,
      });
      yield loadQuote(command.quoteId);
      yield listActivityLogs(command.quoteId);
    });

    const declineQuote = flow(function*(
      command: Operations.Domain.Commands.Quote.DeclineQuoteCommand
    ) {
      yield ajax.sales.quote.declineQuote(command);
      root.notifications.addNotification(`Successfully declined quote`, {
        type: NotificationType.success,
      });
      yield loadQuote(command.quoteId);
      yield listActivityLogs(command.quoteId);
    });

    const completeQuote = flow(function*(quoteId: string) {
      yield ajax.raw.put(`/api/operations/quotes/${quoteId}/complete`).toPromise();
      root.notifications.addNotification(`Successfully completed booking`, {
        type: NotificationType.success,
      });
      yield loadQuote(quoteId);
      yield listActivityLogs(quoteId);
    });

    const allowInvoicingQuote = flow(function*(quoteId: string) {
      yield ajax.raw.put(`/api/operations/quotes/${quoteId}/allow-invoicing`).toPromise();
      root.notifications.addNotification(`Successfully allowed booking for invoicing`, {
        type: NotificationType.success,
      });
      yield loadQuote(quoteId);
      yield listActivityLogs(quoteId);
    });

    const suspendInvoicingQuote = flow(function*(quoteId: string) {
      yield ajax.raw.put(`/api/operations/quotes/${quoteId}/suspend-invoicing`).toPromise();
      root.notifications.addNotification(`Successfully suspended booking from invoicing`, {
        type: NotificationType.success,
      });
      yield loadQuote(quoteId);
      yield listActivityLogs(quoteId);
    });

    const markQuoteAsReviewed = flow(function*(quoteId: string) {
      yield ajax.raw.put(`/api/operations/quotes/${quoteId}/mark-reviewed`).toPromise();
      root.notifications.addNotification(`Successfully marked quote as reviewed`, {
        type: NotificationType.success,
      });
      yield loadQuote(quoteId);
      yield listActivityLogs(quoteId);
    });

    const resetQuoteReviewed = flow(function*(quoteId: string) {
      yield ajax.raw.put(`/api/operations/quotes/${quoteId}/reset-reviewed`).toPromise();
      root.notifications.addNotification(`Successfully updated quote as unreviewed`, {
        type: NotificationType.success,
      });
      yield loadQuote(quoteId);
      yield listActivityLogs(quoteId);
    });

    const duplicateQuote = flow(function*(
      command: Operations.Domain.Commands.Quote.DuplicateQuote.DuplicateQuoteCommand
    ) {
      const duplicatedQuoteId = yield ajax.sales.quote.duplicateQuote(command);
      root.notifications.addNotification(`Successfully duplicated quote`, {
        type: NotificationType.success,
      });

      root.history.push(`/sales/quotes/${duplicatedQuoteId}`);
      yield loadQuote(duplicatedQuoteId);
      yield listActivityLogs(duplicatedQuoteId);
    });

    const loadJobsForCancellingQuote = flow(function*(
      quoteId: string,
      forceJobsToCancelInProgress: boolean
    ) {
      self.jobsForCancellingQuote = yield ajax.sales.quote.loadJobsForCancellingQuote(
        quoteId,
        forceJobsToCancelInProgress
      );
    });

    return {
      createQuote,
      loadQuote,
      getQuote,
      clearQuote,
      updateQuote,
      declineQuote,
      completeQuote,
      suspendInvoicingQuote,
      allowInvoicingQuote,
      markQuoteAsReviewed,
      resetQuoteReviewed,
      bookQuote,
      verifyBooking,
      cancelBooking,
      duplicateQuote,
      listActivityLogs,
      loadJobsForCancellingQuote,
    };
  });
