import { saveAs } from 'file-saver';
import { DateTime } from 'luxon';
import { observer } from 'mobx-react';
import { useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { Link } from 'react-router-dom';
import { allConflictCriteria, allJobStatus, allProgressId, JobStatus } from 'src/api/enums';
import { ListPageLoadCause } from 'src/domain';
import { IAutocompleteResult } from 'src/domain/baseTypes';
import { useRootStore } from 'src/domain/entities/RootStoreModel';
import { ClockIcon, ExcelIcon, FilePdfIcon } from 'src/images/icons';
import memoizeOne, { shallowMemoize } from 'src/infrastructure/memoizeOne';
import { DateTimeFormat } from 'src/views/components/DateTimeFormat';
import { PrintCharterDailyDiaries } from 'src/views/components/operations/PrintCharterDailyDiaries/PrintCharterDailyDiaries';
import PrintCharterInstructionsContent from 'src/views/components/operations/PrintCharterInstructionsContent/PrintCharterInstructionsContent';
import PrintDailySummaries from 'src/views/components/operations/PrintDailySummaries/PrintDailySummaries';
import PrintRailInstructionsContent from 'src/views/components/operations/PrintRailInstructionsContent/PrintRailInstructionsContent';
import ListPage, { IListPageDef } from 'src/views/components/Page/pages/ListPage';
import PrimaryTitle from 'src/views/components/Page/PrimaryTitle/PrimaryTitle';
import { getSubmitCloseModalActionGroupDef } from 'src/views/definitionBuilders/common';
import {
  ActionType,
  FieldDefs,
  FieldType,
  IModalDefBuilderApi,
  ISectionDef,
  PagePrimarySize,
  PaneType,
  ShellModalSize,
} from 'src/views/definitionBuilders/types';
import withQueryParams, { IQueryParamsProps } from 'src/views/hocs/withQueryParams';
import { getReadonlyJobNumberFieldDef } from 'src/views/routes/operations/shared/commonFieldDefBuilders';
import {
  doesJobTypeHaveRailBooking,
  isJobTypeLinkedToQuote,
  mayJobTypeHaveRailBooking,
  SortedJobTypes,
} from 'src/views/routes/operations/shared/jobTypeHelpers';
import Acknowledgement from './Acknowledgement';
import DriverMovement from './DriverMovement';

type ListJobsQuery = Operations.Domain.Queries.ListJobs.ListJobsQuery;
type ListJobItem = Operations.Domain.Queries.ListJobs.ListJobItem;
type StaffMemberName = Common.Dtos.StaffMemberNameDto;
type SubcontractorItem = Common.Dtos.SubcontractorItem;
type AssetItem = Common.Queries.Workshop.GetFleetAssetList.AssetItem;
type RailBookingListItem = Operations.Domain.Queries.ListRailBookings.RailBookingListItem;
type ListBookingItem = Operations.Domain.Queries.ListBookings.ListBookingItem;
type QuoteItem = Operations.Domain.Queries.GetQuotes.QuoteItem;
type AssetHousingLocation = Common.Queries.Workshop.GetAssetHousingLocations.AssetHousingLocationItem;
type StaffDepotDto = Common.Dtos.StaffDepotDto;
type Depots = Common.Dtos.OperationsDepotDto;

interface ISimpleShiftListItem {
  shiftName: string;
  searchText: string;
}

const ListJob: React.FC<IQueryParamsProps<ListJobsQuery>> = observer(
  (props: IQueryParamsProps<ListJobsQuery>) => {
    const rootStore = useRootStore();
    const operationsModel = rootStore.operations;
    const assetsModel = rootStore.assets;
    const assetModel = rootStore.asset;
    const staffModel = rootStore.people.staffMembers;
    const jobModel = operationsModel.job.list;
    const subcontractorModel = operationsModel.subcontractors;
    const [isLoadingFilters, setIsLoadingFilters] = useState<boolean>(true);
    const jobWithClockOnClosestToCurrentTimeRef = useRef<DateTimeFormat>(null);
    const depots = rootStore.operationsStartup.operationsDepots.slice();

    useEffect(() => {
      Promise.all([
        assetsModel.loadFleetAssets(),
        staffModel.loadAllStaffMemberNames({ active: true }),
        subcontractorModel.loadAllSubcontractors(),
        operationsModel.urban.shifts.listShifts(),
        operationsModel.rail.railTemplateShifts.listRailTemplateShifts(),
        operationsModel.rail.railBookings.listItems({ loadCause: ListPageLoadCause.mount }),
        assetModel.loadAssetHousedAtLocations(),
        rootStore.people.depots.loadStaffDepots(),
      ]).then(() => {
        props.updateQueryParams({ ignoreFilters: false });
        setIsLoadingFilters(false);
      });
    }, []);

    const ignoreFilters = props.getQueryParams().ignoreFilters;

    const saveSpreadsheet = () => {
      const fileName = `Jobs_${DateTime.local().toFormat('yyyyMMddHHmm')}.xlsx`;
      return jobModel
        .exportToExcel(props.getQueryParams())
        .then((blob: Blob) => saveAs(blob, fileName));
    };

    const exportVehicleReallocationsToExcel = () => {
      const fileName = `VehicleReallocations_${DateTime.local().toFormat('yyyyMMddHHmm')}.xlsx`;
      return jobModel
        .exportVehicleReallocationsToExcel(props.getQueryParams())
        .then((blob: Blob) => saveAs(blob, fileName));
    };

    const exportJobHoursToExcel = () => {
      const fileName = `JobsHours_${DateTime.local().toFormat('yyyyMMddHHmm')}.xlsx`;
      return jobModel
        .exportJobHoursToExcel(props.getQueryParams())
        .then((blob: Blob) => saveAs(blob, fileName));
    };

    const saveJobWithPaidHoursSpreadsheet = () => {
      const fileName = `Jobs_${DateTime.local().toFormat('yyyyMMddHHmm')}.xlsx`;
      return jobModel
        .exportJobWithPaidHoursToExcel(props.getQueryParams())
        .then((blob: Blob) => saveAs(blob, fileName));
    };

    const saveExceededShiftHoursSpreadsheet = () => {
      const fileName = `Jobs_Exceeded_Shift_Hours_${DateTime.local().toFormat(
        'yyyyMMddHHmm'
      )}.xlsx`;
      return jobModel
        .exportExceededShiftHoursToExcel(props.getQueryParams())
        .then((blob: Blob) => saveAs(blob, fileName));
    };

    const savePdf = () => {
      const fileName = `Jobs_${DateTime.local().toFormat('yyyyMMddHHmm')}.pdf`;
      return jobModel
        .exportToPdf(props.getQueryParams(), true)
        .then((blob: Blob) => saveAs(blob, fileName));
    };

    const saveDetailedPdf = () => {
      const fileName = `Jobs_By_Driver_${DateTime.local().toFormat('yyyyMMddHHmm')}.pdf`;
      return jobModel
        .exportToPdf(props.getQueryParams(), false)
        .then((blob: Blob) => saveAs(blob, fileName));
    };

    const scrollToJobClosestToCurrentTime = () => {
      const element = ReactDOM.findDOMNode(
        jobWithClockOnClosestToCurrentTimeRef.current
      ) as HTMLElement;

      if (element != null) {
        element.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
          inline: 'center',
        });
      }
    };

    const getTakenTripsToExcelModal = (): ((modalDefApi: IModalDefBuilderApi) => ISectionDef) => {
      return () => ({
        title: 'Export Taken Trips To Excel',
        asForm: true,
        panels: [
          {
            panes: [
              {
                paneType: PaneType.formFieldsPane,
                fields: [
                  {
                    fieldType: FieldType.dateField,
                    label: 'From',
                    dataAddr: 'from',
                    mandatory: true,
                  },
                  {
                    fieldType: FieldType.dateField,
                    label: 'To',
                    dataAddr: 'to',
                    mandatory: true,
                    validate: d => {
                      if (!d.fieldValue || !d.parentValue.from) {
                        return undefined;
                      }
                      const from = DateTime.fromISO(d.parentValue.from);
                      const to = DateTime.fromISO(d.fieldValue);
                      return from > to ? 'Date To cannot be earlier than Date From' : undefined;
                    },
                  },
                ],
              },
            ],
          },
        ],
        secondaryActions: [getSubmitCloseModalActionGroupDef('Ok')],
        onFormSubmit: v => {
          const fileName = `Trips_Taken_${DateTime.local().toFormat('yyyyMMddHHmm')}.xlsx`;
          return jobModel
            .exportTripsTakenToExcel({
              ...props.getQueryParams(),
              startDate: v.from,
              endDate: v.to,
            } as Operations.Domain.Queries.ExportTripsTakenToExcel.TripsTakenQuery)
            .then(blob => saveAs(blob, fileName));
        },
      });
    };

    const getPrimaryFields = (): IListPageDef['primaryFields'] => {
      return [
        getReadonlyJobNumberFieldDef<ListJobItem>('id'),
        {
          fieldType: FieldType.textField,
          dataAddr: 'shiftName',
          label: 'Shift',
          orderBy: 'job.ShiftName',
        },
        {
          fieldType: FieldType.readonlyField,
          label: 'Booking',
          columnWidth: '1px',
          formatReadonly: data => {
            const jobTypeId = data.parentValue.jobType.id;
            if (
              doesJobTypeHaveRailBooking(jobTypeId) ||
              (mayJobTypeHaveRailBooking(jobTypeId) && !!data.parentValue.railBookingId)
            ) {
              return (
                <Link to={`/operations/rail/rail-bookings/${data.parentValue.railBookingId}`}>
                  {data.parentValue.railBookingNumber}
                </Link>
              );
            }

            if (isJobTypeLinkedToQuote(jobTypeId)) {
              return (
                <Link to={`/operations/bookings-for-ops/${data.parentValue.quoteId}`}>
                  {data.parentValue.quoteNumber}
                </Link>
              );
            }

            return null;
          },
        },
        {
          fieldType: FieldType.textField,
          dataAddr: 'customerName',
          label: 'Customer / Description',
          columnWidth: '15em',
          formatReadonly: d => (
            <>
              <div title={d.fieldValue} className="customer">
                <Link to={`/sales/customers/${d.parentValue.customerId}`}>{d.fieldValue}</Link>
              </div>
              <div title={d.parentValue.description} className="description">
                {d.parentValue.description}
              </div>
            </>
          ),
        },
        {
          fieldType: FieldType.dateTimeField,
          dataAddr: 'clockOn',
          label: 'Clock On',
          orderBy: 'job.ClockOn',
          formatReadonly: d => {
            const thisJob = d.parentValue as ListJobItem;

            if (
              jobModel.jobWithClockOnClosestToCurrentTime &&
              jobModel.jobWithClockOnClosestToCurrentTime.id === thisJob.id
            ) {
              return (
                <DateTimeFormat value={d.fieldValue} ref={jobWithClockOnClosestToCurrentTimeRef} />
              );
            }
            return <DateTimeFormat value={d.fieldValue} />;
          },
          hidden: d => {
            const job = d.parentValue as ListJobItem;
            // Incomplete jobs need to have their dates provided by operations staff
            return job.jobStatus.id === JobStatus.Incomplete || job.isContinuingFrom;
          },
        },
        {
          fieldType: FieldType.dateTimeField,
          dataAddr: 'departDepot',
          label: 'Depart',
          orderBy: 'job.DepartDepot',
          columnWidth: '1px',
          formatReadonly: d => (
            <DateTimeFormat value={d.fieldValue} previousValue={d.parentValue.clockOn} />
          ),
          hidden: d => (d.parentValue as ListJobItem).isContinuingFrom,
        },
        {
          fieldType: FieldType.dateTimeField,
          dataAddr: 'shiftCommence',
          label: 'Commence',
          orderBy: 'job.ShiftCommence',
          columnWidth: '1px',
          formatReadonly: d => (
            <DateTimeFormat
              value={d.fieldValue}
              previousValue={
                (d.parentValue as ListJobItem).isContinuingFrom
                  ? undefined
                  : d.parentValue.departDepot
              }
              timezone={(d.parentValue as ListJobItem).firstRouteTimeZone}
            />
          ),
        },
        {
          fieldType: FieldType.assetSelectField,
          label: 'Vehicle',
          dataAddr: 'asset',
          valueKey: 'id',
          descriptionKey: 'name',
          columnWidth: '1px',
          formatReadonly: d => {
            if (d.parentValue.hasSubcontractor) {
              if (d.parentValue.subcontractor && d.parentValue.subcontractor.id) {
                return (
                  <Link to={`/operations/subcontractors/${d.parentValue.subcontractor.id}`}>
                    {d.parentValue.subcontractor.name}
                  </Link>
                );
              }
              return 'Subcontractor';
            } else {
              if (d.parentValue.asset && d.parentValue.asset.id) {
                return <span>{d.parentValue.originalAssetName || d.parentValue.asset.name}</span>;
              }
              return null;
            }
          },
        },
        {
          fieldType: FieldType.readonlyField,
          label: 'Staff Member',
          dataAddr: 'staffMemberName',
          formatReadonly: d => {
            const job = d.parentValue as ListJobItem;
            return (
              <div style={{ maxWidth: '10rem', wordBreak: 'break-word' }}>
                {d.fieldValue ? (
                  <div>
                    <Link to={`/people/staff-members/${d.parentValue.staffMemberId}`}>
                      {d.fieldValue}
                    </Link>
                  </div>
                ) : null}
                {job.secondStaffMemberId ? (
                  <div>
                    <Link to={`/people/staff-members/${job.secondStaffMemberId}`}>
                      {job.secondStaffMemberName}
                    </Link>
                  </div>
                ) : null}
                {job.linkedStaffMemberNames ? <div>({job.linkedStaffMemberNames})</div> : null}
              </div>
            );
          },
        },
        {
          fieldType: FieldType.readonlyField,
          label: 'Job Status',
          columnWidth: '1px',
          formatReadonly: d =>
            d.parentValue.hasSubcontractor ? null : (
              <Acknowledgement
                id={d.parentValue.jobNumber}
                clockOn={d.parentValue.clockOn}
                jobStatus={d.parentValue.jobStatus}
                jobProgress={d.parentValue.latestJobProgressId}
                unexpectedProgressMessage={d.parentValue.unexpectedProgressMessage}
                enableProgressWarning
                isVehicleSwapped={d.parentValue.isVehicleSwapped}
              />
            ),
        },
        {
          fieldType: FieldType.readonlyField,
          label: 'Driver Status',
          columnWidth: '1px',
          formatReadonly: d => <DriverMovement driverMovement={d.parentValue.driverMovement} />,
        },
      ];
    };

    const getFilterFieldDefs = shallowMemoize(
      (
        activeStaffMembers: StaffMemberName[],
        fleetAssets: AssetItem[],
        subcontractors: SubcontractorItem[],
        shiftNames: ISimpleShiftListItem[],
        railBookings: RailBookingListItem[],
        searchBookings: (search: string) => Promise<IAutocompleteResult<ListBookingItem>>,
        findQuotes: (ids: string[]) => Promise<IAutocompleteResult<QuoteItem>>,
        assetHousingLocations: AssetHousingLocation[],
        staffDepots: StaffDepotDto[],
        depots: Depots[]
      ) => {
        return {
          jobType: {
            fieldType: FieldType.selectMultiField,
            dataAddr: 'jobTypeIds',
            label: 'Job Type',
            valueKey: 'value',
            descriptionKey: 'description',
            optionItems: SortedJobTypes.slice(),
            useValueOnly: true,
          } as FieldDefs,
          jobStatus: {
            fieldType: FieldType.selectMultiField,
            dataAddr: 'jobStatusIds',
            label: 'Job Status',
            valueKey: 'value',
            descriptionKey: 'description',
            optionItems: allJobStatus.slice(),
            useValueOnly: true,
          } as FieldDefs,
          jobProgress: {
            fieldType: FieldType.selectMultiField,
            dataAddr: 'jobProgressIds',
            label: 'Job Progress',
            valueKey: 'value',
            descriptionKey: 'description',
            optionItems: allProgressId.slice(),
            useValueOnly: true,
          } as FieldDefs,
          isDriverManaged: {
            fieldType: FieldType.yesNoField,
            dataAddr: 'IsDriverManaged',
            label: 'Driver Managed Fatigue',
          } as FieldDefs,
          needsVerification: {
            fieldType: FieldType.yesNoField,
            dataAddr: 'needsVerification',
            label: 'Needs Verification',
          } as FieldDefs,
          isCancelled: {
            fieldType: FieldType.yesNoField,
            dataAddr: 'isCancelled',
            label: 'Cancelled',
          } as FieldDefs,
          isTrainingJob: {
            fieldType: FieldType.yesNoField,
            dataAddr: 'IsTrainingJob',
            label: 'Training',
          } as FieldDefs,
          dateFrom: {
            fieldType: FieldType.dateField,
            dataAddr: 'dateFrom',
            label: 'Date From',
            onBlur: api => {
              if (!api.formValues.dateTo) {
                api.setFormValue(['dateTo'], api.fieldData.fieldValue);
              }
              api.validateField(['dateTo']);
            },
          } as FieldDefs,
          dateTo: {
            fieldType: FieldType.dateField,
            dataAddr: 'dateTo',
            label: 'Date To',
            validate: d => {
              if (!d.fieldValue || !d.parentValue.dateFrom) {
                return undefined;
              }
              const from = DateTime.fromISO(d.parentValue.dateFrom);
              const to = DateTime.fromISO(d.fieldValue);
              return from > to ? 'Date To cannot be earlier than Date From' : undefined;
            },
          } as FieldDefs,
          hasStaffMember: {
            fieldType: FieldType.yesNoField,
            dataAddr: 'hasStaffMember',
            label: 'All Staff Members Allocated',
          } as FieldDefs,
          staffMembers: {
            fieldType: FieldType.selectMultiField,
            dataAddr: 'staffMembers',
            label: 'Staff Member',
            valueKey: 'id',
            descriptionKey: 'name',
            optionItems: activeStaffMembers.slice(),
            useValueOnly: true,
          } as FieldDefs,
          staffMemberDepots: {
            fieldType: FieldType.selectMultiField,
            dataAddr: 'staffMemberDepots',
            label: 'Staff Member Depot',
            valueKey: 'id',
            descriptionKey: 'description',
            optionItems: staffDepots.slice(),
            useValueOnly: true,
          } as FieldDefs,
          hasVehicle: {
            fieldType: FieldType.yesNoField,
            dataAddr: 'hasVehicle',
            label: 'Vehicle Allocated',
          } as FieldDefs,
          vehicles: {
            fieldType: FieldType.selectMultiField,
            dataAddr: 'vehicles',
            label: 'Vehicle',
            valueKey: 'id',
            descriptionKey: 'name',
            optionItems: fleetAssets.slice(),
            useValueOnly: true,
          } as FieldDefs,
          garagedAt: {
            fieldType: FieldType.selectMultiField,
            label: 'Garaged At',
            dataAddr: 'housingLocations',
            useValueOnly: true,
            valueKey: 'id',
            descriptionKey: 'description',
            optionItems: assetHousingLocations.slice(),
          } as FieldDefs,
          departingFromDepot: {
            fieldType: FieldType.selectMultiField,
            label: 'Departing From Depot',
            dataAddr: 'departingFromDepotId',
            useValueOnly: true,
            valueKey: 'id',
            descriptionKey: 'description',
            optionItems: depots.slice(),
          } as FieldDefs,
          subcontractors: {
            fieldType: FieldType.selectMultiField,
            dataAddr: 'subcontractors',
            label: 'Subcontractor',
            valueKey: 'id',
            descriptionKey: 'name',
            optionItems: subcontractors.slice(),
            useValueOnly: true,
          } as FieldDefs,
          shiftNames: {
            fieldType: FieldType.selectMultiField,
            dataAddr: 'shiftNames',
            label: 'Shift',
            valueKey: 'shiftName',
            descriptionKey: 'searchText',
            optionItems: shiftNames.slice(),
            useValueOnly: true,
          } as FieldDefs,
          conflicts: {
            fieldType: FieldType.selectMultiField,
            label: 'Conflicts',
            dataAddr: 'conflicts',
            valueKey: 'value',
            descriptionKey: 'description',
            optionItems: allConflictCriteria.slice(),
            useValueOnly: true,
          } as FieldDefs,
          exceededShiftHours: {
            fieldType: FieldType.yesNoField,
            label: 'Exceeded Shift Hours',
            dataAddr: 'exceededShiftHours',
            useValueOnly: true,
          } as FieldDefs,
          railBookings: {
            fieldType: FieldType.selectMultiField,
            label: 'Rail Booking',
            dataAddr: 'railBookings',
            valueKey: 'railBookingId',
            descriptionKey: 'railBookingNumber',
            useOptionRendererAsValueRenderer: true,
            optionRenderer: d => (
              <span>
                {d.railBookingNumber} - {d.description}
              </span>
            ),
            optionItems: railBookings.slice(),
            useValueOnly: true,
          } as FieldDefs,
          quotes: {
            fieldType: FieldType.selectAsyncMultiField,
            dataAddr: 'quotes',
            label: 'Charter Booking',
            valueKey: 'id',
            descriptionKey: 'quoteNumber',
            useValueOnly: true,
            loadOptionItems: searchBookings,
            loadItems: findQuotes,
            optionRenderer: d => (
              <span>
                {d.quoteNumber} - {d.description}
              </span>
            ),
            useOptionRendererAsValueRenderer: true,
          } as FieldDefs,
          ignoreFilters: {
            fieldType: FieldType.yesNoField,
            dataAddr: 'ignoreFilters',
            hidden: true,
            label: '',
            useValueOnly: true,
          } as FieldDefs,
        };
      }
    );

    const getRowKey: IListPageDef['rowKey'] = data => {
      return data.itemValue.id;
    };

    const getPageDef = memoizeOne(
      (): IListPageDef => {
        const today = DateTime.local().toISODate();
        const filterFieldDefsLookup = getFilterFieldDefs(
          staffModel.allStaffMemberNames,
          assetsModel.fleetAssetListItems,
          subcontractorModel.allSubcontractors,
          operationsModel.urban.shifts.shifts
            .slice()
            .map(v => ({ shiftName: v.shiftName, searchText: v.searchText }))
            .concat(
              operationsModel.rail.railTemplateShifts.railTemplateShifts
                .map(v => v.shiftName)
                .filter((v, i, s) => s.indexOf(v) === i)
                .map(v => ({
                  shiftName: v,
                  searchText: `${v} - Rail`,
                }))
            ),
          operationsModel.rail.railBookings.items.slice(),
          operationsModel.sales.quotes.bookingsList.searchBookings,
          operationsModel.sales.quotes.bookingsList.findQuotes,
          assetModel.housedAtLocations.slice(),
          rootStore.people.depots.staffDepots.slice(),
          depots
        );
        return {
          primaryTitle: <PrimaryTitle title="Jobs"></PrimaryTitle>,
          onLoadData: jobModel.listItems,
          externalSearch: true,
          primarySize: PagePrimarySize.full,
          hasMoreData: jobModel.hasMoreItems,
          rowKey: getRowKey,
          minChar: 2,
          ignoreFilters,
          primaryActions: [
            {
              actions: [
                {
                  actionType: ActionType.actionButton,
                  label: 'Scroll to job closest to current time',
                  icon: <ClockIcon fixedWidth />,
                  onClick: scrollToJobClosestToCurrentTime,
                },
                {
                  actionType: ActionType.actionCollection,
                  actionGroups: [
                    {
                      actions: [
                        {
                          actionType: ActionType.printActionButton,
                          label: 'Print Rail Instructions Sheets',
                          loadDataAsync: () =>
                            jobModel.loadRailInstructions(props.getQueryParams()),
                          printContent: () => (
                            <PrintRailInstructionsContent
                              railInstructions={jobModel.railInstructions}
                            />
                          ),
                        },
                        {
                          actionType: ActionType.printActionButton,
                          label: 'Print Charter Instructions Sheets',
                          loadDataAsync: () =>
                            jobModel.loadCharterInstructions(props.getQueryParams()),
                          printContent: () => (
                            <PrintCharterInstructionsContent
                              charterInstructions={jobModel.charterInstructions}
                            />
                          ),
                        },
                        {
                          actionType: ActionType.printActionButton,
                          label: 'Print Daily Summary Sheets',
                          loadDataAsync: () => jobModel.loadJobsForDriver(props.getQueryParams()),
                          printContent: () => (
                            <PrintDailySummaries
                              jobs={jobModel.driverJobs.slice()}
                              checks={jobModel.predepartureChecks.slice()}
                            />
                          ),
                        },
                        {
                          actionType: ActionType.printActionButton,
                          label: 'Print Charter Daily Diary Report',
                          loadDataAsync: _ =>
                            jobModel.loadCharterDailyDiaryDetails(props.getQueryParams()),
                          printContent: _ =>
                            jobModel.charterDailyDiaryDetails && (
                              <PrintCharterDailyDiaries
                                jobs={jobModel.charterDailyDiaryDetails.items}
                              />
                            ),
                        },
                      ],
                    },
                    {
                      actions: [
                        {
                          actionType: ActionType.actionButton,
                          label: 'Generate Daily Run Sheets',
                          icon: <FilePdfIcon fixedWidth />,
                          onClick: savePdf,
                        },
                        {
                          actionType: ActionType.actionButton,
                          label: 'Generate Daily Run Sheets By Driver',
                          icon: <FilePdfIcon fixedWidth />,
                          onClick: saveDetailedPdf,
                        },
                      ],
                    },
                    {
                      actions: [
                        {
                          actionType: ActionType.actionButton,
                          label: 'Export all data to Excel',
                          icon: <ExcelIcon fixedWidth />,
                          onClick: saveSpreadsheet,
                        },
                        {
                          actionType: ActionType.modalActionButton,
                          label: 'Export Trips Taken to Excel',
                          icon: <ExcelIcon fixedWidth />,
                          modalDef: getTakenTripsToExcelModal(),
                          modalSize: ShellModalSize.oneThird,
                        },
                        {
                          actionType: ActionType.actionButton,
                          label: 'Generate Exceeded Shift Hours Report',
                          icon: <ExcelIcon fixedWidth />,
                          onClick: saveExceededShiftHoursSpreadsheet,
                        },
                        {
                          actionType: ActionType.actionButton,
                          label: 'Export Job including Paid Hours to Excel',
                          icon: <ExcelIcon fixedWidth />,
                          onClick: saveJobWithPaidHoursSpreadsheet,
                        },
                        {
                          actionType: ActionType.actionButton,
                          label: 'Export Job Hours to Excel',
                          icon: <ExcelIcon fixedWidth />,
                          onClick: exportJobHoursToExcel,
                        },
                        {
                          actionType: ActionType.actionButton,
                          label: 'Export Vehicle Reallocations to Excel',
                          icon: <ExcelIcon fixedWidth />,
                          onClick: exportVehicleReallocationsToExcel,
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
          primaryFields: getPrimaryFields(),
          filterAction: {
            disabled: isLoadingFilters,
            defaultValues: {
              dateFrom: today,
              dateTo: today,
              ignoreFilters: false,
            },
            filterFields: Object.keys(filterFieldDefsLookup).map(k => filterFieldDefsLookup[k]),
            filterDef: () => [
              {
                panes: [
                  {
                    paneType: PaneType.formFieldsPane,
                    columnCount: 2,
                    fields: [filterFieldDefsLookup.dateFrom, filterFieldDefsLookup.dateTo],
                  },
                  {
                    paneType: PaneType.formFieldsPane,
                    fields: [
                      filterFieldDefsLookup.staffMembers,
                      filterFieldDefsLookup.staffMemberDepots,
                      filterFieldDefsLookup.vehicles,
                      filterFieldDefsLookup.shiftNames,
                      filterFieldDefsLookup.jobType,
                      filterFieldDefsLookup.quotes,
                      filterFieldDefsLookup.railBookings,
                      filterFieldDefsLookup.subcontractors,
                      filterFieldDefsLookup.jobStatus,
                      filterFieldDefsLookup.jobProgress,
                      filterFieldDefsLookup.garagedAt,
                      filterFieldDefsLookup.departingFromDepot,
                      filterFieldDefsLookup.conflicts,
                      filterFieldDefsLookup.isDriverManaged,
                      filterFieldDefsLookup.needsVerification,
                      filterFieldDefsLookup.isCancelled,
                      filterFieldDefsLookup.isTrainingJob,
                      filterFieldDefsLookup.exceededShiftHours,
                      filterFieldDefsLookup.hasStaffMember,
                      filterFieldDefsLookup.hasVehicle,
                      filterFieldDefsLookup.ignoreFilters,
                    ],
                  },
                ],
              },
            ],
          },
        };
      }
    );

    return (
      <ListPage
        className="list-job-component"
        data={jobModel.items.slice()}
        def={getPageDef()}
        autoRefresh
        lastUpdated={jobModel.lastUpdated}
      />
    );
  }
);

export default withQueryParams(ListJob);
