import { Component } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import {
  PagePrimarySize,
  PaneType,
  FieldType,
  ActionType,
  ShellModalSize,
} from 'src/views/definitionBuilders/types';
import CrudPage, { CrudPageMode, ICrudPageDef } from 'src/views/components/Page/pages/CrudPage';
import { StaffMemberFilter } from 'src/views/components/Page/fields/StaffMemberField';
import { TrashIcon, CheckIcon } from 'src/images/icons';
import { getSubmitCloseModalActionGroupDef } from 'src/views/definitionBuilders/common';
import { DriverReportSubjectId } from 'src/domain/entities/operations/opsDiary/OperationsDiaryEntryModel';

type OperationsDiaryEntry = Operations.Domain.Queries.ListOperationsDiaryEntries.OperationsDiaryEntryDto;
type OperationsDiaryEntrySubject = Operations.Domain.Queries.ListOperationsDiaryEntries.OperationsDiaryEntrySubjectDto;
type CreateOperationsDiaryEntryCommand = Operations.Domain.Commands.OperationsDiaryEntry.CreateOperationsDiaryEntry.CreateOperationsDiaryEntryCommand;
type UpdateOperationsDiaryEntryCommand = Operations.Domain.Commands.OperationsDiaryEntry.UpdateOperationsDiaryEntry.UpdateOperationsDiaryEntryCommand;
type AssetItem = Common.Queries.Workshop.GetSimpleFleetAssetList.SimpleAssetItem;

export interface IMaintainOperationsDiaryEntryProps {
  mode: CrudPageMode;
  canManageOperationsDiaryEntry: boolean;

  loadOperationsDiaryEntry: (id: string) => Promise<void>;
  operationsDiaryEntry: OperationsDiaryEntry | undefined;
  createOperationsDiaryEntry: (command: CreateOperationsDiaryEntryCommand) => Promise<void>;
  updateOperationsDiaryEntry: (command: UpdateOperationsDiaryEntryCommand) => Promise<void>;
  deleteOperationsDiaryEntry: (id: string) => Promise<void>;

  loadAllOperationsDiaryEntrySubjects: () => Promise<void>;
  operationsDiaryEntrySubjects: OperationsDiaryEntrySubject[];

  loadStaffMembers: () => Promise<void>;
  assets: AssetItem[];
  loadAssetListItems: () => Promise<void>;

  acknowledgeEntry: (id: string) => Promise<void>;
}

interface IMaintainOperationsDiaryEntryParams {
  id: string;
}

type InternalProps = IMaintainOperationsDiaryEntryProps &
  RouteComponentProps<IMaintainOperationsDiaryEntryParams>;

class MaintainOperationsDiaryEntry extends Component<InternalProps> {
  componentDidMount() {
    this.props.loadStaffMembers();
    this.props.loadAssetListItems();
    this.props.loadAllOperationsDiaryEntrySubjects();
  }

  private get isUpdateMode() {
    return this.props.mode === 'update';
  }

  private get OperationsDiaryEntryId() {
    return this.props.match.params.id;
  }

  private handlePreSubmitForCreate = (
    item: OperationsDiaryEntry
  ): CreateOperationsDiaryEntryCommand => {
    return {
      occurredOn: item.occurredOn,
      assetId: item.asset && item.asset.id ? item.asset.id : undefined,
      driverStaffMemberId: item.driver && item.driver.id ? item.driver.id : undefined,
      subjectId: item.subject.id,
      comments: item.comments,
      route: item.route,
    };
  };

  private handlePreSubmitForUpdate = (
    item: OperationsDiaryEntry
  ): UpdateOperationsDiaryEntryCommand => {
    return {
      id: this.OperationsDiaryEntryId,
      occurredOn: item.occurredOn,
      assetId: item.asset && item.asset.id ? item.asset.id : undefined,
      driverStaffMemberId: item.driver && item.driver.id ? item.driver.id : undefined,
      subjectId: item.subject.id,
      comments: item.comments,
      route: item.route,
    };
  };

  private readonly getPageDef = (updating: boolean): ICrudPageDef => {
    return {
      primarySize: PagePrimarySize.threeQuarters,
      primarySection: {
        title: d => {
          const entry = d.sectionValue as OperationsDiaryEntry;

          return this.isUpdateMode
            ? `Daily Event Log Entry ${this.props.operationsDiaryEntry &&
                this.props.operationsDiaryEntry.diaryEntryNumber}` +
                (entry.acknowledged ? ' ✔' : '')
            : 'Create a Daily Event Log Entry';
        },
        primaryActions: this.isUpdateMode
          ? [
              {
                actions: [
                  {
                    actionType: ActionType.actionCollection,
                    actionGroups: [
                      {
                        actions: [
                          {
                            actionType: ActionType.modalActionButton,
                            label: 'Delete Daily Event Log Entry',
                            icon: <TrashIcon />,
                            hidden:
                              !this.props.operationsDiaryEntry ||
                              !this.props.canManageOperationsDiaryEntry,
                            modalSize: ShellModalSize.oneQuarter,
                            modalDef: modalDefApi => ({
                              title: 'Delete Daily Event Log Entry',
                              asForm: true,
                              panels: [
                                {
                                  panes: [
                                    {
                                      paneType: PaneType.customPane,
                                      render: () => (
                                        <div>
                                          <p>
                                            Are you sure you want to delete this event log entry?
                                          </p>
                                        </div>
                                      ),
                                    },
                                  ],
                                },
                              ],
                              secondaryActions: [getSubmitCloseModalActionGroupDef('Delete')],
                              onFormSubmit: () =>
                                this.props.deleteOperationsDiaryEntry(this.OperationsDiaryEntryId),
                            }),
                          },
                          {
                            actionType: ActionType.actionButton,
                            label: 'Acknowledge',
                            icon: <CheckIcon />,
                            hidden: d => {
                              const entry = d.actionValue as OperationsDiaryEntry;
                              if (!entry || !entry.subject) {
                                return true;
                              }

                              const hidden =
                                entry.acknowledged || entry.subject.id !== DriverReportSubjectId;

                              return hidden;
                            },
                            onClick: () => this.props.acknowledgeEntry(this.OperationsDiaryEntryId),
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ]
          : [],
        panels: [
          {
            panes: [
              {
                paneType: PaneType.formFieldsPane,
                columnCount: 2,
                fields: [
                  {
                    fieldType: FieldType.dateTimeField,
                    label: 'Occurred On',
                    dataAddr: 'occurredOn',
                    mandatory: true,
                  },
                  {
                    fieldType: FieldType.textField,
                    label: 'Job',
                    dataAddr: 'jobNumber',
                    readonly: true,
                    hidden: d => !d.fieldValue,
                    linkTo: d => {
                      const entry = d.parentValue as OperationsDiaryEntry;
                      if (!entry || !entry.jobId) {
                        return undefined;
                      }

                      return `/operations/jobs/${entry.jobId}`;
                    },
                  },
                ],
              },
              {
                paneType: PaneType.formFieldsPane,
                columnCount: 2,
                fields: [
                  {
                    fieldType: FieldType.selectField,
                    label: 'Subject',
                    dataAddr: 'subject',
                    valueKey: 'id',
                    descriptionKey: 'description',
                    useValueOnly: false,
                    optionItems: this.props.operationsDiaryEntrySubjects,
                    mandatory: true,
                  },
                  {
                    fieldType: FieldType.textField,
                    label: 'Route/Service',
                    dataAddr: 'route',
                    mandatory: false,
                    readonly: d => {
                      const entry = d.parentValue as OperationsDiaryEntry;
                      return !!entry.jobId;
                    },
                  },
                ],
              },
              {
                paneType: PaneType.formFieldsPane,
                columnCount: 2,
                fields: [
                  {
                    fieldType: FieldType.staffMemberField,
                    label: 'Staff Member',
                    dataAddr: 'driver',
                    mandatory: false,
                    readonly: d => {
                      const entry = d.parentValue as OperationsDiaryEntry;
                      return !!entry.jobId;
                    },
                    staffMemberFilter: d => StaffMemberFilter.active,
                  },
                  {
                    fieldType: FieldType.assetSelectField,
                    label: 'Vehicle',
                    dataAddr: 'asset',
                    valueKey: 'id',
                    descriptionKey: 'name',
                    mandatory: false,
                    optionItems: this.props.assets,
                    readonly: d => {
                      const entry = d.parentValue as OperationsDiaryEntry;
                      return !!entry.jobId;
                    },
                  },
                ],
              },
              {
                paneType: PaneType.formFieldsPane,
                fields: [
                  {
                    fieldType: FieldType.textAreaField,
                    label: 'Comments',
                    dataAddr: 'comments',
                    mandatory: true,
                    maxLength: 4000,
                    rows: 8,
                  },
                ],
              },
            ],
          },
        ],
        onFormPreSubmit: this.isUpdateMode
          ? this.handlePreSubmitForUpdate
          : this.handlePreSubmitForCreate,
        onFormSubmit: this.isUpdateMode
          ? this.props.updateOperationsDiaryEntry
          : this.props.createOperationsDiaryEntry,
      },
    };
  };

  render() {
    const {
      mode,
      loadOperationsDiaryEntry,
      operationsDiaryEntry,
      canManageOperationsDiaryEntry,
    } = this.props;
    return (
      <CrudPage
        def={({ updating }) => this.getPageDef(updating)}
        mode={mode}
        isEditingForbidden={!canManageOperationsDiaryEntry}
        onLoadData={() => loadOperationsDiaryEntry(this.OperationsDiaryEntryId)}
        data={operationsDiaryEntry}
        createDefaultData={{}}
      />
    );
  }
}

export default MaintainOperationsDiaryEntry;
