import { IRootStoreModel } from 'src/domain/entities/RootStoreModel';
import { UiRoute } from 'src/domain/services/buildUiRoutes';
import { UiRouteName } from 'src/domain/baseTypes';

export default function buildUiRoutes(store: IRootStoreModel) {
  const rosterCreate = new UiRoute(
    UiRouteName.urbanRosterCreate,
    '/operations/rosters/create',
    () => 'Create Roster',
    false
  );

  const rosterManage = new UiRoute(
    UiRouteName.urbanRosterManage,
    '/operations/rosters/:id',
    () => 'Manage Roster',
    false
  );

  const rosterList = new UiRoute(
    UiRouteName.urbanRosterList,
    '/operations/rosters',
    () => 'Rosters',
    false,
    true,
    [rosterCreate, rosterManage]
  );

  return {
    [UiRouteName.urbanRosterList]: rosterList,
    [UiRouteName.urbanRosterManage]: rosterManage,
    [UiRouteName.urbanRosterCreate]: rosterCreate,
  };
}
