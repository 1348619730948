import { types, flow, getRoot } from 'mobx-state-tree';
import { getAjax, getBus } from 'src/domain/services/storeEnvironment';
import { IRootStoreModel } from 'src/domain/entities/RootStoreModel';
import { NotificationType } from 'src/domain';

type RailBookingItem = Operations.Domain.Queries.ViewRailBooking.RailBookingItem;
type CreateRailBookingCommand = Operations.Domain.Commands.RailBooking.CreateRailBooking.CreateRailBookingCommand;
type CreateRailBookingResult = Operations.Domain.Commands.RailBooking.CreateRailBooking.CreateRailBookingResult;
type UpdateRailBookingCommand = Operations.Domain.Commands.RailBooking.UpdateRailBooking.UpdateRailBookingCommand;
type UpdateRailBookingResult = Operations.Domain.Commands.RailBooking.UpdateRailBooking.UpdateRailBookingResult;
type CancelRailBookingCommand = Operations.Domain.Commands.RailBooking.CancelRailBooking.CancelRailBookingCommand;
type JobsForCancellingRailBookingDto = Operations.Domain.Queries.GetJobsForCancellingRailBooking.JobsForCancellingRailBookingDto;

export const RailBookingModel = types
  .model('RailBookingModel', {
    railBooking: types.maybe(types.frozen<RailBookingItem>()),
    jobsForCancellingRailBooking: types.maybe(types.frozen<JobsForCancellingRailBookingDto>()),
  })
  .actions(self => {
    const ajax = getAjax(self);
    const bus = getBus(self);
    const root = getRoot(self) as IRootStoreModel;

    const createRailBooking = flow(function*(command: CreateRailBookingCommand) {
      const result: CreateRailBookingResult = yield ajax.operations.rail.railBookings.createRailBooking(
        command
      );
      self.railBooking = undefined;
      root.history.push(`/operations/rail/rail-bookings/${result.railBookingId}`);
      bus.showWarnings(result.warnings);
    });

    const loadRailBooking = flow(function*(railBookingId: string) {
      self.railBooking = yield ajax.operations.rail.railBookings.viewRailBooking(railBookingId);
    });

    const updateRailBooking = flow(function*(command: UpdateRailBookingCommand) {
      const result: UpdateRailBookingResult = yield ajax.operations.rail.railBookings.updateRailBooking(
        command
      );
      yield loadRailBooking(command.id);
      bus.showWarnings(result.warnings);
    });

    const deleteRailBooking = flow(function*(railBookingId: string) {
      yield ajax.operations.rail.railBookings.deleteRailBooking(railBookingId);
      root.history.push(`/operations/rail/rail-bookings?defaultFilter=true`);
    });

    const cancelRailBooking = flow(function*(command: CancelRailBookingCommand) {
      yield ajax.operations.rail.railBookings.cancelRailBooking(command);
      root.history.push(`/operations/rail/rail-bookings?defaultFilter=true`);
      root.notifications.addNotification('Successfully cancelled rail booking', {
        type: NotificationType.success,
      });
      yield loadRailBooking(command.railBookingId);
    });

    const loadJobsForCancellingRailBooking = flow(function*(railBookingId: string) {
      self.jobsForCancellingRailBooking = yield ajax.operations.rail.railBookings.loadJobsForCancellingRailBooking(
        railBookingId
      );
    });

    const completeRailBooking = flow(function*(railBookingId: string) {
      yield ajax.operations.rail.railBookings.completeRailBooking(railBookingId);
      root.history.push(`/operations/rail/rail-bookings?defaultFilter=true`);
    });

    const downloadAttachment = flow(function*(attachmentId: string, fileName: string) {
      yield ajax.operations.rail.railBookings.downloadAttachment(attachmentId, fileName);
    });

    return {
      createRailBooking,
      loadRailBooking,
      updateRailBooking,
      deleteRailBooking,
      cancelRailBooking,
      completeRailBooking,
      loadJobsForCancellingRailBooking,
      downloadAttachment,
    };
  });
