import inject from 'src/views/injectFromStore';
import Omit from 'src/infrastructure/omit';
import MaintainCustomerComplaint, {
  IMaintainCustomerComplaintProps,
} from 'src/views/routes/compliance/customerComplaint/maintainCustomerComplaint/MaintainCustomerComplaint';

const Container = inject<
  Omit<IMaintainCustomerComplaintProps, 'mode'>,
  IMaintainCustomerComplaintProps
>((allStores, p) => ({
  canManageCustomerComplaints: allStores.rootStore.account.isOperationsDepartmentMember,
  customerComplaint: Object.assign(
    {},
    allStores.rootStore.compliance.customerComplaint.item.customerComplaint
  ),
  loadCustomerComplaint:
    allStores.rootStore.compliance.customerComplaint.item.loadCustomerComplaint,
  updateCustomerComplaint:
    allStores.rootStore.compliance.customerComplaint.item.updateCustomerComplaint,
  closeCustomerComplaint:
    allStores.rootStore.compliance.customerComplaint.item.closeCustomerComplaint,
  createCustomerComplaint:
    allStores.rootStore.compliance.customerComplaint.item.createCustomerComplaint,
  loadStaffMembers: allStores.rootStore.people.staffMembers.loadAllStaffMembers,
  fleetAssets: allStores.rootStore.assets.fleetAssetListItems.slice(),
  loadFleetAssets: allStores.rootStore.assets.loadFleetAssets,
}))(MaintainCustomerComplaint);

export default Container;
