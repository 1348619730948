import { IRootStoreModel } from 'src/domain/entities/RootStoreModel';
import { UiRoute } from 'src/domain/services/buildUiRoutes';
import { UiRouteName } from 'src/domain/baseTypes';

export default function buildUiRoutes(store: IRootStoreModel) {
  const driverAppUserCreate = new UiRoute(
    UiRouteName.peopleDriverAppUserCreate,
    '/people/driver-app/create',
    () => 'Create a Staff Member',
    true
  );

  return {
    [UiRouteName.peopleDriverAppUserCreate]: driverAppUserCreate,
  };
}
