import { types, flow } from 'mobx-state-tree';
import { getAjax } from 'src/domain/services';

type ExclusionItem = Operations.Domain.Queries.ListExclusions.ExclusionItem;
type CreateExclusionCommand = Operations.Domain.Commands.Exclusion.CreateExclusion.CreateExclusionCommand;

export const ExclusionModel = types
  .model('ExclusionModel', {
    exclusions: types.array(types.frozen<ExclusionItem>()),
    exclusionsForStaffMember: types.array(types.frozen<ExclusionItem>()),
  })
  .actions(self => {
    const ajax = getAjax(self);

    const loadExclusions = flow(function*() {
      self.exclusions = yield ajax.raw
        .get(`/api/operations/exclusions`)
        .toPromise()
        .then(r => r.response);
    });

    const loadExclusionsForStaffMember = flow(function*(staffMemberId: string) {
      self.exclusionsForStaffMember = yield ajax.raw
        .get(`/api/operations/exclusions?staffMemberIds=${staffMemberId}`)
        .toPromise()
        .then(r => r.response);
    });

    const createExclusion = flow(function*(command: CreateExclusionCommand) {
      yield ajax.raw
        .post(`/api/operations/exclusions/create`, command)
        .toPromise()
        .then(r => r.response);
      yield loadExclusionsForStaffMember(command.staffMemberId);
    });

    const deleteExclusion = flow(function*(exclusionId: string, staffMemberId: string) {
      yield ajax.raw
        .httpDelete(`/api/operations/exclusions/${exclusionId}`)
        .toPromise()
        .then(r => r.response);
      yield loadExclusionsForStaffMember(staffMemberId);
    });

    return {
      loadExclusions,
      loadExclusionsForStaffMember,
      createExclusion,
      deleteExclusion,
    };
  });
