import './IncompleteJobs.scss';
import { DateTime } from 'luxon';
import { AlertCard } from 'src/views/kioskRoutes/components/presentational/Alerts/AlertCard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWarning } from '@fortawesome/free-solid-svg-icons';
import memoizeOne from 'src/infrastructure/memoizeOne';

type IncompleteJobForDriver = Operations.Domain.Queries.ListIncompleteJobsForDriver.IncompleteJobForDriver;
type IncompleteShiftForMechanic = Workshop.Domain.Queries.ListIncompleteShiftsForMechanic.IncompleteShiftForMechanic;

const getIncompleteJobsAlert = memoizeOne(
  (
    incompleteWork: IncompleteJobForDriver[] | IncompleteShiftForMechanic[],
    aggregateType: 'shift' | 'job',
    timezone: string
  ) => {
    const hasMoreThanOneType = incompleteWork.length > 1;
    const clockOff =
      aggregateType === 'job'
        ? (incompleteWork[0] as IncompleteJobForDriver).clockOff
        : (incompleteWork[0] as IncompleteShiftForMechanic).shiftEnd;

    const message = hasMoreThanOneType
      ? `You have more than one incomplete ${aggregateType} in the last 7 days. Please complete the ${aggregateType}s by entering your completion details.`
      : `You have an incomplete ${aggregateType} on ${DateTime.fromISO(clockOff, {
          zone: timezone,
        }).toLocaleString(
          DateTime.DATE_MED
        )}. Please complete the ${aggregateType} by entering your completion details.`;

    return (
      <AlertCard
        showAlert={incompleteWork.length > 0}
        heading="Incomplete Jobs"
        message={message}
        icon={<FontAwesomeIcon icon={faWarning} />}
        iconColor="#ffa02b"
      />
    );
  }
);

export const IncompleteJobs = ({
  incompleteWork,
  aggregateType,
  timezone,
}: {
  incompleteWork: IncompleteJobForDriver[] | IncompleteShiftForMechanic[];
  aggregateType: 'shift' | 'job';
  timezone: string;
}) => {
  return getIncompleteJobsAlert(incompleteWork, aggregateType, timezone);
};
