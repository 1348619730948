import { IAjax } from 'src/infrastructure/ajax';
import { operationsUrls } from 'src/domain/services/apiUrls';

export default function init(ajax: IAjax) {
  function loadDepots() {
    return ajax
      .get(operationsUrls.depotUrls.loadDepots())
      .toPromise()
      .then<Common.Dtos.OperationsDepotDto[]>(r => r.response);
  }

  return {
    depots: {
      loadDepots,
    },
  };
}
