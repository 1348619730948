import inject from 'src/views/injectFromStore';
import Omit from 'src/infrastructure/omit';
import MaintainCctvRequest, {
  IMaintainCctvRequestProps,
} from 'src/views/routes/compliance/cctvRequest/maintainCctvRequest/MaintainCctvRequest';

const Container = inject<Omit<IMaintainCctvRequestProps, 'mode'>, IMaintainCctvRequestProps>(
  (allStores, p) => ({
    canManageCctvRequests: allStores.rootStore.account.isOperationsDepartmentMember,
    cctvRequest: allStores.rootStore.compliance.cctvRequest.item.cctvRequest,
    loadCctvRequest: allStores.rootStore.compliance.cctvRequest.item.loadCctvRequest,
    updateCctvRequest: allStores.rootStore.compliance.cctvRequest.item.updateCctvRequest,
    createCctvRequest: allStores.rootStore.compliance.cctvRequest.item.createCctvRequest,
    closeCctvRequest: allStores.rootStore.compliance.cctvRequest.item.closeCctvRequest,
    fleetAssets: allStores.rootStore.assets.fleetAssetListItems.slice(),
    loadFleetAssets: allStores.rootStore.assets.loadFleetAssets,
    loadStaffMembers: allStores.rootStore.people.staffMembers.loadAllStaffMembers,
  })
)(MaintainCctvRequest);

export default Container;
