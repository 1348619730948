import { observer } from 'mobx-react-lite';
import { useRootStore } from 'src/domain/entities/RootStoreModel';
import ListPage, { IListPageDef } from 'src/views/components/Page/pages/ListPage';
import TaskCardItem from 'src/views/components/TaskCard/TaskCardItem';
import { FieldType, PagePrimarySize } from 'src/views/definitionBuilders/types';
import PrimaryTitle from 'src/views/components/Page/PrimaryTitle/PrimaryTitle';

const ListRailTemplate: React.FC = observer(() => {
  const rootStore = useRootStore();
  const canManageRailTemplates = rootStore.account.isRailDepartmentMember;
  const railModel = rootStore.operations.rail.railTemplates;

  const getPageDef = (): IListPageDef => {
    return {
      primaryTitle: <PrimaryTitle title="Rail Template"></PrimaryTitle>,
      primarySize: PagePrimarySize.full,
      onLoadData: railModel.listItems,
      externalSearch: true,
      createLink: canManageRailTemplates
        ? TaskCardItem.operations.rail.railTemplates.createRailTemplate
        : undefined,
      hasMoreData: railModel.hasMoreItems,
      primaryFields: [
        {
          fieldType: FieldType.textField,
          dataAddr: 'name',
          label: 'Name',
          linkTo: d => `/operations/rail/rail-templates/${d.parentValue.railTemplateId}`,
        },
      ],
      filterAction: {
        defaultValues: {
          deleted: false,
        },
        filterFields: [
          {
            fieldType: FieldType.yesNoField,
            label: 'Deleted?',
            dataAddr: 'deleted',
          },
        ],
      },
    };
  };

  return (
    <ListPage className="list-rail-template-component" data={railModel.items} def={getPageDef()} />
  );
});

export default ListRailTemplate;
