import './Notifications.scss';
import { ToastContainer, toast, style, ToastType, ToastOptions } from 'react-toastify';
import { css } from 'glamor';
import { Observable } from 'rxjs/Observable';
import { NotificationType } from 'src/domain/';
import { SuccessIcon, InfoIcon, WarnIcon, ErrorIcon } from 'src/images/icons';
import { INotification } from 'src/domain/entities/app/NotificationsModel';
import { INotificationOptions } from 'src/domain/baseTypes';
import { Component } from 'react';
import { distinctUntilChanged, tap } from 'rxjs/operators';
import FatigueViolationModal from '../routes/operations/shared/FatigueViolationModal';
import FatigueWarningModal from '../routes/operations/shared/FatigueWarningModal';

export interface INotificationProps {
  notification: INotification;
}

export const Notification: React.FC<INotificationProps> = ({ notification: n }) => (
  <div className="notification">
    <div className="notification-icon">{notificationTypeToIcon(n.options.type)}</div>
    <div>
      <span className="notification-message">{n.message}</span>
    </div>
  </div>
);

export interface INotificationsProps {
  notification$: Observable<INotification>;
}

class Notifications extends Component<INotificationsProps> {
  constructor(props: INotificationsProps) {
    super(props);

    let dismissed = false;
    props.notification$
      .pipe(distinctUntilChanged((prev, curr) => !dismissed && prev.message === curr.message))
      .pipe(
        tap(() => {
          dismissed = false;
        })
      )
      .subscribe(n =>
        toast(
          <Notification notification={n} />,
          notificationOptionsToToastOptions(n.options, () => {
            dismissed = true;
          })
        )
      );
  }

  render() {
    return (
      <>
        <div className="notifications-component">
          <ToastContainer position={toast.POSITION.TOP_CENTER} autoClose={5000} />
        </div>
        <FatigueViolationModal />
        <FatigueWarningModal />
      </>
    );
  }
}

export default Notifications;

const successColour = '#E1F1F8';
const infoColour = '#F6F7F8';
const warnColour = '#F8E71C';
const errorColour = '#F78F1C';
const criticalColour = '#FF0000';

const successTextColour = '#5B6B7C';
const infoTextColour = successTextColour;
const warnTextColour = '#15387F';
const errorTextColour = '#ffffff';
const criticalTextColour = '#ffffff';

const standardStyles = {
  margin: '8px 200px',
  borderRadius: '5px',
  boxShadow: '0 2px 4px 0 #8494A5',
};

function notificationOptionsToToastOptions(
  options: INotificationOptions,
  onCloseHandler: () => void
): ToastOptions {
  switch (options.type) {
    case NotificationType.info: {
      return {
        type: toast.TYPE.INFO as ToastType,
        autoClose: options.autoClose,
        className: css({
          ...standardStyles,
          color: infoTextColour,
        }),
        progressClassName: css({
          backgroundColor: infoTextColour,
        }),
        onClose: onCloseHandler,
      };
    }
    case NotificationType.success: {
      return {
        type: toast.TYPE.SUCCESS as ToastType,
        autoClose: options.autoClose,
        className: css({
          ...standardStyles,
          color: successTextColour,
        }),
        progressClassName: css({
          backgroundColor: successTextColour,
        }),
        onClose: onCloseHandler,
      };
    }
    case NotificationType.warn: {
      return {
        type: toast.TYPE.WARNING as ToastType,
        autoClose: options.autoClose,
        className: css({
          ...standardStyles,
          color: warnTextColour,
        }),
        progressClassName: css({
          backgroundColor: warnTextColour,
        }),
        onClose: onCloseHandler,
      };
    }
    case NotificationType.critical: {
      return {
        type: toast.TYPE.ERROR as ToastType,
        className: css({
          ...standardStyles,
          color: criticalTextColour,
          backgroundColor: criticalColour,
          fontSize: '1.5rem',
        }),
        progressClassName: css({
          backgroundColor: criticalTextColour,
        }),
        autoClose: false,
        hideProgressBar: true,
        onClose: onCloseHandler,
      };
    }
    default: {
      return {
        type: toast.TYPE.ERROR as ToastType,
        autoClose: false,
        className: css({
          ...standardStyles,
          color: errorTextColour,
        }),
        progressClassName: css({
          backgroundColor: errorTextColour,
        }),
        onClose: onCloseHandler,
      };
    }
  }
}

function notificationTypeToIcon(type: NotificationType) {
  switch (type) {
    case NotificationType.info: {
      return <InfoIcon fixedWidth size="lg" />;
    }
    case NotificationType.success: {
      return <SuccessIcon fixedWidth size="lg" />;
    }
    case NotificationType.warn: {
      return <WarnIcon fixedWidth size="lg" />;
    }
    default: {
      return <ErrorIcon fixedWidth size="lg" />;
    }
  }
}

style({
  width: '100vw',
  colorInfo: infoColour,
  colorSuccess: successColour,
  colorWarning: warnColour,
  colorError: errorColour,
  TOP_CENTER: {
    top: 0,
    marginLeft: 0,
  },
});
