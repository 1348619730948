import Omit from 'src/infrastructure/omit';

export enum NotificationType {
  error = 'error',
  warn = 'warn',
  info = 'info',
  success = 'success',
  critical = 'critical',
}

export interface INotificationOptions {
  type: NotificationType;
  autoClose: number | false | undefined;
}

export enum UiRouteName {
  home = 'home',
  workshop = 'workshop',
  workshopAssetsView = 'workshopAssetsView',
  workshopUnallocatedAssets = 'workshopUnallocatedAssets',
  workshopAssetsOdometerReadings = 'workshopAssetsOdometerReadings',
  workshopAssetsOdometerReadingsCreate = 'workshopAssetsOdometerReadingsCreate',
  workshopAssetCreate = 'workshopAssetCreate',
  workshopAssetEdit = 'workshopAssetEdit',
  workshopJobCreate = 'workshopJobCreate',
  workshopJobEdit = 'workshopJobEdit',
  workshopJobsList = 'workshopJobsList',
  workshopTasksList = 'workshopTasksList',
  workshopOutOfServiceTasksList = 'workshopOutOfServiceTasksList',
  workshopFutureTasksByAsset = 'workshopFutureTasksByAsset',
  workshopJobsHistory = 'workshopJobsHistory',
  workshopJobSchedule = 'workshopJobSchedule',
  workshopWeeklyJobSchedule = 'workshopWeeklyJobSchedule',
  workshopMachineryJobSchedule = 'workshopMachineryJobSchedule',
  workshopDefectCreate = 'workshopDefectCreate',
  workshopDefectEdit = 'workshopDefectEdit',
  workshopDefectsList = 'workshopDefectsList',
  workshopAssetGroupCreate = 'workshopAssetGroupCreate',
  workshopAssetGroupEdit = 'workshopAssetGroupEdit',
  workshopDefectTriage = 'workshopDefectTriage',
  workshopDefectView = 'workshopDefectView',
  workshopEngineList = 'workshopEngineList',
  workshopEngineEdit = 'workshopEngineEdit',
  workshopEngineCreate = 'workshopEngineCreate',
  workshopAttachmentCreate = 'workshopAttachmentCreate',
  workshopAttachmentEdit = 'workshopAttachmentEdit',
  workshopAttachmentsList = 'workshopAttachmentsList',
  workshopChecklistCreate = 'workshopChecklistCreate',
  workshopChecklistEdit = 'workshopChecklistEdit',
  workshopChecklistsView = 'workshopChecklistsView',
  workshopAssetGroupsList = 'workshopAssetGroupsList',
  workshopPartTransactions = 'workshopPartsTransactions',
  workshopPartsGroupCreate = 'workshopPartsGroupCreate',
  workshopPartsGroupEdit = 'workshopPartsGroupEdit',
  workshopPartsGroupsList = 'workshopPartsGroupsList',
  workshopPartCategoriesList = 'workshopPartCategoriesList',
  workshopPartCategoryCreate = 'workshopPartCategoryCreate',
  workshopPartCategoryEdit = 'workshopPartCategoryEdit',
  workshopMaintainComponentTypes = 'workshopMaintainComponentTypes',
  workshopMaintainLabourPrices = 'workshopMaintainLabourPrices',
  workshopComponentTypeCreate = 'workshopComponentTypeCreate',
  workshopComponentTypeEdit = 'workshopComponentTypeEdit',
  workshopTechSpecsEdit = 'workshopTechSpecsEdit',
  workshopTechSpecDropdownOptionsEdit = 'workshopTechSpecDropdownOptionsEdit',
  workshopPartsList = 'workshopPartsList',
  workshopPartCreate = 'workshopPartCreate',
  workshopPartEdit = 'workshopPartEdit',
  workshopInventoryStocktake = 'workshopInventoryStocktake',
  workshopJobTasksCreate = 'workshopJobTasksCreate',
  workshopJobTasksMaintain = 'workshopJobTasksMaintain',
  workshopTaskSubcategoryCreate = 'workshopTaskSubcategoryCreate',
  workshopTaskSubcategoryEdit = 'workshopTaskSubcategoryEdit',
  workshopTaskSubcategoryList = 'workshopTaskSubcategoriesList',
  workshopAssetComponentsRegister = 'workshopAssetComponentsRegister',
  workshopAssetPartsRegister = 'workshopAssetPartsRegister',
  workshopAssetCubicRegister = 'workshopAssetCubicRegister',
  workshopAssetServiceSchedule = 'workshopAssetServiceSchedule',
  workshopAssetServicesDueByDate = 'workshopAssetServicesDueByDate',
  workshopAssetServicesDueByKms = 'workshopAssetServicesDueByKms',
  workshopMaintainJobProgress = 'workshopMaintainJobProgress',
  workshopSupplierList = 'workshopSupplierList',
  workshopSupplierCreate = 'workshopSupplierCreate',
  workshopSupplierEdit = 'workshopSupplierEdit',
  workshopOwnerList = 'workshopOwnerList',
  workshopOwnerCreate = 'workshopOwnerCreate',
  workshopOwnerEdit = 'workshopOwnerEdit',
  workshopPurchaseOrderCreate = 'workshopPurchaseOrderCreate',
  workshopPurchaseOrdersList = 'workshopPurchaseOrdersList',
  workshopPurchaseTransactionsList = 'workshopPurchaseTransactionsList',
  workshopPurchaseOrderEdit = 'workshopPurchaseOrderEdit',
  workshopPurchaseOrderGoodsReceivedCreate = 'workshopPurchaseOrderGoodsReceivedCreate',
  workshopPurchaseOrderGoodsReceivedEdit = 'workshopPurchaseOrderGoodsReceivedEdit',
  workshopReturnedPartCreditCreate = 'workshopReturnedPartCreditCreate',
  workshopReturnedPartCreditEdit = 'workshopReturnedPartCreditEdit',
  workshopPurchaseOrderInvoicesEdit = 'workshopPurchaseOrderInvoicesEdit',
  workshopShiftTemplateCreate = 'workshopShiftTemplateCreate',
  workshopShiftTemplateManage = 'workshopShiftTemplateManage',
  workshopShiftTemplateList = 'workshopShiftTemplateList',
  workshopRosterTemplateCreate = 'workshopRosterTemplateCreate',
  workshopRosterTemplateManage = 'workshopRosterTemplateManage',
  workshopRosterTemplateList = 'workshopRosterTemplateList',
  workshopShiftCreate = 'workshopShiftCreate',
  workshopShiftManage = 'workshopShiftManage',
  workshopShiftList = 'workshopShiftList',
  workshopRosterCreate = 'workshopRosterCreate',
  workshopRosterManage = 'workshopRosterManage',
  workshopRosterViewChanges = 'workshopRosterViewChanges',
  workshopRosterList = 'workshopRosterList',
  workshopDataFuelEntriesList = 'workshopDataFuelEntriesList',
  workshopDataFuelUploadsList = 'workshopDataFuelUploadsList',
  workshopMachineryScheduleTemplate = 'workshopMachineryScheduleTemplate',
  sales = 'sales',
  salesCustomerList = 'salesCustomerList',
  salesCustomerCreate = 'salesCustomerCreate',
  salesCustomerEdit = 'salesCustomerEdit',
  salesQuoteList = 'salesQuoteList',
  salesQuoteCreate = 'salesQuoteCreate',
  salesQuoteUpdate = 'salesQuoteUpdate',
  salesBookingUpdate = 'salesBookingUpdate',
  operationsHolidayList = 'operationsHolidayList',
  operationsHolidayCreate = 'operationsHolidayCreate',
  operationsHolidayManage = 'operationsHolidayManage',
  salesVehicleTypeList = 'salesVehicleTypeList',
  salesVehicleTypeCreate = 'salesVehicleTypeCreate',
  salesVehicleTypeManage = 'salesVehicleTypeManage',
  salesQuoteAccounting = 'salesQuoteAccounting',
  salesRailBookingsForAccounting = 'salesRailBookingsForAccounting',
  salesBookingsForAccounting = 'salesBookingsForAccounting',
  salesBoardingPointList = 'salesBoardingPointList',
  salesBoardingPointCreate = 'salesBoardingPointCreate',
  salesBoardingPointManage = 'salesBoardingPointManage',
  salesHourlyRateManage = 'salesHourlyRateManage',
  operations = 'operations',
  urbanShiftList = 'urbanShiftList',
  urbanShiftCreate = 'urbanShiftCreate',
  urbanShiftManage = 'urbanShiftManage',
  urbanRosterList = 'urbanRosterList',
  urbanRosterCreate = 'urbanRosterCreate',
  urbanRosterManage = 'urbanRosterManage',
  urbanRosterGroupList = 'urbanRosterGroupList',
  urbanRosterGroupCreate = 'urbanRosterGroupCreate',
  urbanRosterGroupManage = 'urbanRosterGroupManage',
  operationsJobList = 'operationsJobList',
  operationsJobProgressWarningsList = 'operationsJobProgressWarningsList',
  operationsJobForCharterList = 'operationsJobForCharterList',
  operationsJobCreateGenerateJobsOption = 'operationsJobCreateGenerateJobsOption',
  operationsJobListGenerateJobsOptions = 'operationsJobListGenerateJobsOptions',
  operationsJobGenerateJobsDetails = 'operationsJobGenerateJobsDetails',
  operationsJobAllocations = 'operationsJobAllocations',
  operationsJobCreate = 'operationsJobCreate',
  operationsJobUpdate = 'operationsJobUpdate',
  operationsJobProgressCreate = 'operationsJobProgressCreate',
  operationsJobProgressUpdate = 'operationsJobProgressUpdate',
  compliance = 'compliance',
  complianceCodeOfConductList = 'complianceCodeOfConductList',
  complianceCodeOfConductCreate = 'complianceCodeOfConductCreate',
  complianceCodeOfConductUpdate = 'complianceCodeOfConductUpdate',
  complianceLostPropertyList = 'complianceLostPropertyList',
  complianceLostPropertyCreate = 'complianceLostPropertyCreate',
  complianceLostPropertyUpdate = 'complianceLostPropertyUpdate',
  complianceCustomerComplaintCreate = 'complianceCustomerComplaintCreate',
  complianceCustomerComplaintList = 'complianceCustomerComplaintList',
  complianceCustomerComplaintUpdate = 'complianceCustomerComplaintUpdate',
  complianceCctvRequestCreate = 'complianceCctvRequestCreate',
  complianceCctvRequestUpdate = 'complianceCctvRequestUpdate',
  complianceCctvRequestList = 'complianceCctvRequestList',
  complianceReportableEventList = 'complianceReportableEventList',
  complianceReportableEventCreate = 'complianceReportableEventCreate',
  complianceReportableEventManage = 'complianceReportableEventManage',
  complianceFatigueBreachEventList = 'complianceFatigueBreachEventList',
  complianceFatigueBreachEventManage = 'complianceFatigueBreachEventManage',
  people = 'people',
  peopleStaffMemberList = 'peopleStaffMemberList',
  peopleStaffMemberCreate = 'peopleStaffMemberCreate',
  peopleStaffMemberManage = 'peopleStaffMemberManage',
  peopleLeaveList = 'peopleLeaveList',
  peopleLeaveCreate = 'peopleLeaveCreate',
  peopleLeaveManage = 'peopleLeaveManage',
  peopleLeaveCalendar = 'peopleLeaveCalendar',
  peopleTimesheetCreate = 'peopleTimesheetCreate',
  peopleTimesheetManage = 'peopleTimesheetManage',
  peopleTimesheetList = 'peopleTimesheetList',
  peopleDriverAppUserCreate = 'peopleDriverAppUserCreate',
  peopleSkillSpecsEdit = 'peopleSkillSpecsEdit',
  operationsRailTemplateList = 'operationsRailTemplateList',
  operationsRailTemplateCreate = 'operationsRailTemplateCreate',
  operationsRailTemplateManage = 'operationsRailTemplateManage',
  operationsRailBookingList = 'operationsRailBookingList',
  operationsRailBookingCreate = 'operationsRailBookingCreate',
  operationsRailBookingManage = 'operationsRailBookingManage',
  operationsBookingForOpsManage = 'operationsBookingForOpsManage',
  operationsTasksForDay = 'operationsTasksForDay',
  peopleNoteCreate = 'peopleNoteCreate',
  peopleNoteList = 'peopleNoteList',
  peopleNoteManage = 'peopleNoteManage',
  peopleRoleCreate = 'peopleRoleCreate',
  peopleRoleList = 'peopleRoleList',
  peopleRoleManage = 'peopleRoleManage',
  peopleTimesheetAllowanceCreate = 'peopleTimesheetAllowanceCreate',
  peopleTimesheetAllowanceList = 'peopleTimesheetAllowanceList',
  peopleTimesheetAllowanceManage = 'peopleTimesheetAllowanceManage',
  peopleExternalIdentityList = 'peopleExternalIdentityList',
  peopleSmsCreate = 'peopleSmsCreate',
  peopleSmsList = 'peopleSmsList',
  peopleSmsManage = 'peopleSmsManage',
  peopleEquipmentTypesList = 'peopleEquipmentTypesList',
  peopleEquipmentTypeCreate = 'peopleEquipmentTypeCreate',
  peopleEquipmentTypeEdit = 'peopleEquipmentTypeEdit',
  complianceFatigueDetails = 'complianceFatigueDetails',
  operationsSubcontractorList = 'operationsSubcontractorList',
  operationsSubcontractorCreate = 'operationsSubcontractorCreate',
  operationsSubcontractorUpdate = 'operationsSubcontractorUpdate',
  operationsDefectCreate = 'operationsDefectCreate',
  operationsAuthorisedDevicesList = 'operationsAuthorisedDevicesList',
  operationsOperationsDiaryEntryCreate = 'operationsOperationsDiaryEntryCreate',
  operationsOperationsDiaryEntryUpdate = 'operationsOperationsDiaryEntryUpdate',
  operationsOperationsDiaryEntryList = 'operationsOperationsDiaryEntryList',
  operationsMaintainOperationsDiaryEntrySubjects = 'operationsMaintainOperationsDiaryEntrySubjects',
  salesExtraTypeList = 'salesExtraTypeList',
  salesExtraTypeCreate = 'salesExtraTypeCreate',
  salesExtraTypeUpdate = 'salesExtraTypeUpdate',
  reporting = 'reporting',
  workshopAssetComponents = 'workshopAssetComponents',
  operationsListAssetOdometerReadings = 'operationsListAssetOdometerReadings',
  assetsWithUnknownKmsList = 'assetsWithUnknownKmsList',
  knowledgeBase = 'knowledgeBase',
  accounting = 'accounting',
  accountingQuotes = 'accountingQuotes',
  accountingRailBookings = 'accountingRailBookings',
  accountingBookings = 'accountingBookings',
  accountingContractCharterBookings = 'accountingContractCharterBookings',
  accountingCreateContractCharterBookings = 'accountingCreateContractCharterBookings',
  accountingUpdateContractCharterBookings = 'accountingUpdateContractCharterBookings',
  documentation = 'documentation',
  documentationPageLayout = 'documentationPageLayout',
  documentationListPage = 'documentationListPage',
  documentationCrudPage = 'documentationCrudPage',
  documentationForms = 'documentationForms',
  documentationActions = 'documentationActions',
  documentationModals = 'documentationModals',
  operationsProcessDriverAuthorizationList = 'operationsProcessDriverAuthorization',
  operationsProcessResultsFile = 'operationsProcessResultsFile',
  operationsCharterContractCreate = 'operationsCharterContractCreate',
  operationsCharterContractManage = 'operationsCharterContractManage',
  operationsCharterContractsList = 'operationsCharterContractsList',
}

export function isUiRouteName(x: unknown): x is UiRouteName {
  return typeof x === 'string' && x in UiRouteName;
}

export interface IAutocompleteResult<TOption> {
  options: Array<TOption>;
}

export enum ListPageLoadCause {
  mount = 'mount',
  search = 'search',
  moreRequested = 'moreRequested',
  refresh = 'refresh',
}

export type BasicSearch = Omit<Common.BasicSearchQuery, 'size' | 'page'>;

export interface IListPageLoadDataRequest<T extends BasicSearch = BasicSearch> {
  loadCause: ListPageLoadCause;
  query?: Partial<T>;
}

export interface IPaginationPageLoadDataRequest<T extends BasicSearch = BasicSearch> {
  page: number;
  query: Partial<T>;
}

export type ISelectFilterableByDifferentKey<T extends {}> = T & {
  searchable?: string;
};
