import { ISectionDef, SectionDefs } from './section';

export type DataAddr = string | Array<string>;

export enum PagePrimarySize {
  half = 'half',
  sevenTwelves = 'seventwelves',
  twoThirds = 'twoThirds',
  threeQuarters = 'threeQuarters',
  full = 'full',
}

export interface IPageDef {
  primarySection: ISectionDef;
  primarySize?: PagePrimarySize;
  secondarySections?: Array<SectionDefs>;
}

export interface IHasChangeState {
  changeState?: Common.ChangeState;
}
