import { FieldDefs, FieldType } from 'src/views/definitionBuilders/types';
import styles from './getJobTaskDetailFields.module.scss';
import { Link } from 'react-router-dom';

type JobTaskDetailsDto = Workshop.Domain.Queries.Job.JobTaskDetailsDto;

export function getJobTaskDescriptionsFieldDef(): FieldDefs {
  return {
    fieldType: FieldType.customField,
    dataAddr: 'tasks',
    label: 'Tasks',
    render: d => {
      const tasks = d.data.fieldValue as JobTaskDetailsDto[];

      const anyCompletionDetails = (task: JobTaskDetailsDto) =>
        task.completionDetails && task.completionDetails.length > 0;

      return (
        <div className={styles['job-task-list']}>
          <ul>
            {tasks.map(o => (
              <li key={`job-task-${o.taskNumber}`}>
                <div className={styles.description}>
                  <Link to={`/workshop/tasks/${o.id}`}>{o.taskNumber}</Link> {o.description.trim()}
                </div>
                {anyCompletionDetails(o)
                  ? o.completionDetails.map((c, idx) => (
                      <p key={idx} className={styles.extraCompletionDetails}>
                        - {c}
                      </p>
                    ))
                  : null}
              </li>
            ))}
          </ul>
        </div>
      );
    },
  };
}

export function getJobTaskCrewFieldDef(): FieldDefs {
  return {
    fieldType: FieldType.customField,
    dataAddr: 'tasks',
    label: 'Crew',
    render: d => {
      const tasks = d.data.fieldValue as JobTaskDetailsDto[];

      const distinctStaffNames = tasks
        .map(t => t.labour)
        .reduce((x, y) => x.concat(y))
        .map(l => l.staffMemberFullName)
        .filter((staffName, idx, arr) => arr.findIndex(o => o === staffName) === idx)
        .sort();

      return (
        <div className={styles['job-staff-list']}>
          <ul>
            {distinctStaffNames.length > 0 ? (
              distinctStaffNames.map((s, idx) => <li key={`${s.trim()}-${idx}`}>{s.trim()}</li>)
            ) : (
              <li>
                <i>None</i>
              </li>
            )}
          </ul>
        </div>
      );
    },
  };
}
