import { getBus } from 'src/domain/services';
import inject from 'src/views/injectFromStore';
import ListJobForCharter, {
  IListJobForCharterProps,
} from 'src/views/routes/operations/job/listJobForCharter/ListJobForCharter';

const Container = inject<IListJobForCharterProps>((allStores, p) => {
  const jobModel = allStores.rootStore.operations.job;
  const assetsModel = allStores.rootStore.assets;
  const staffMembersModel = allStores.rootStore.people.staffMembers;
  const customerModel = allStores.rootStore.operations.sales.customer;
  const vehicleTypesModel = allStores.rootStore.operations.vehicleTypes;
  const listModel = allStores.rootStore.operations.job.listForAllocations;
  const subcontractorsModel = allStores.rootStore.operations.subcontractors;
  const exclusionModel = allStores.rootStore.operations.exclusionModel;
  const sales = allStores.rootStore.operations.sales;
  const domainEvents = getBus(allStores.rootStore).operationsDomainEvent$;

  return {
    canManageJobs: allStores.rootStore.account.isOperationsDepartmentMember,
    listJobs: jobModel.listForCharter.listItems,
    refreshJobs: jobModel.listForCharter.refreshItems,
    warnAboutConflicts: jobModel.listForCharter.warnAboutConflicts,
    jobs: jobModel.listForCharter.items.slice(),
    hasMoreData: jobModel.listForCharter.hasMoreItems,
    fleetAssets: assetsModel.fleetAssetListItems.slice(),
    loadFleetAssets: assetsModel.loadFleetAssets,
    subcontractors: subcontractorsModel.allSubcontractors.slice(),
    loadSubcontractors: subcontractorsModel.loadAllSubcontractors,
    staffMembers: staffMembersModel.drivers.slice(),
    loadStaffMembers: staffMembersModel.loadDrivers,
    loadExclusions: exclusionModel.loadExclusions,
    exclusions: exclusionModel.exclusions.slice(),
    searchCustomers: customerModel.searchCustomers,
    findCustomers: customerModel.findCustomers,
    searchContactsForCustomer: customerModel.searchContactsForCustomer,
    findContactsForCustomer: customerModel.findContactsForCustomer,
    vehicleTypes: vehicleTypesModel.vehicleTypes.slice(),
    loadVehicleTypes: vehicleTypesModel.loadVehicleTypes,
    searchBookings: allStores.rootStore.operations.sales.quotes.bookingsList.searchBookings,
    findQuotes: allStores.rootStore.operations.sales.quotes.bookingsList.findQuotes,
    exportToExcel: allStores.rootStore.operations.job.list.exportToExcel,
    exportCharterJobsToPdf: jobModel.listForCharter.exportCharterJobsToPdf,
    onAllocateStaffMember: listModel.allocateStaffMember,
    onAllocateAsset: listModel.allocateAsset,
    onAllocateSubcontractor: listModel.allocateSubcontractor,
    loadCharterInstructions: jobModel.listForCharter.loadCharterInstructions,
    charterInstructions: jobModel.listForCharter.charterInstructions,
    jobWithClockOnClosestToNow: jobModel.listForCharter.jobWithClockOnClosestToCurrentTime,
    quoteTypesForDropdown: sales.quotes.quoteTypesForDropdown.slice(),
    loadQuoteTypes: sales.quotes.loadQuoteTypes,
    assetHousingLocations: allStores.rootStore.asset.housedAtLocations.slice(),
    loadAssetHousedAtLocations: allStores.rootStore.asset.loadAssetHousedAtLocations,
    listRailBookings: allStores.rootStore.operations.rail.railBookings.listItems,
    railBookings: allStores.rootStore.operations.rail.railBookings.items.slice(),
    listRailShifts: allStores.rootStore.operations.rail.railTemplateShifts.listRailTemplateShifts,
    railShifts: allStores.rootStore.operations.rail.railTemplateShifts.railTemplateShifts.slice(),
    loadRailInstructions: allStores.rootStore.operations.job.list.loadRailInstructions,
    railInstructions: allStores.rootStore.operations.job.list.railInstructions,
    swapVehicle: allStores.rootStore.operations.job.item.swapVehicle,
    jobProgress: allStores.rootStore.operations.job.item.progress,
    loadJobProgress: allStores.rootStore.operations.job.item.loadJobProgress,
    loadCharterDailyDiaryDetails:
      allStores.rootStore.operations.job.list.loadCharterDailyDiaryDetails,
    charterDailyDiaryDetails: allStores.rootStore.operations.job.list.charterDailyDiaryDetails,
    domainEvents: domainEvents,
    tradingCompanies: allStores.rootStore.operations.sales.quotes.tradingCompanies.slice(),
    loadTradingCompanies: sales.quotes.loadTradingCompanies,
    staffDepots: allStores.rootStore.people.depots.staffDepots.slice(),
    loadStaffDepots: allStores.rootStore.people.depots.loadStaffDepots,
  };
})(ListJobForCharter);

export default Container;
