import { flow, getRoot, types } from 'mobx-state-tree';
import { getAjax } from 'src/domain/services/storeEnvironment';
import { NotificationType } from 'src/domain';
import { IRootStoreModel } from 'src/domain/entities/RootStoreModel';

type ReportableEvent = Operations.Domain.Queries.ListReportableEvents.ReportableEventDto;
type ReportableEventCategory = Operations.Domain.Queries.ListReportableEvents.ReportableEventCategoryDto;
type ReportableEventContract = Operations.Domain.Queries.ListAllReportableEventContracts.ReportableEventContractDto;
type CreateReportableEventCommand = Operations.Domain.Commands.ReportableEvent.CreateReportableEvent.CreateReportableEventCommand;
type UpdateReportableEventCommand = Operations.Domain.Commands.ReportableEvent.UpdateReportableEvent.UpdateReportableEventCommand;
type UpdateReportableEventVehicleInformationCommand = Operations.Domain.Commands.ReportableEvent.UpdateReportableEventVehicleInformation.UpdateReportableEventVehicleInformationCommand;
type UpdateReportableEventInternalInformationCommand = Operations.Domain.Commands.ReportableEvent.UpdateReportableEventInternalInformation.UpdateReportableEventInternalInformationCommand;

export const SingleReportableEventModel = types
  .model('SingleReportableEventModel', {
    reportableEvent: types.maybe(types.frozen<ReportableEvent>()),
    categories: types.array(types.frozen<ReportableEventCategory>()),
    contracts: types.array(types.frozen<ReportableEventContract>()),
  })
  .actions(self => {
    const ajax = getAjax(self);
    const root = getRoot(self) as IRootStoreModel;

    const loadReportableEvent = flow(function*(id: string) {
      self.reportableEvent = undefined;
      self.reportableEvent = yield ajax.compliance.reportableEvent.viewReportableEvent(id);
    });

    const clearReportableEvent = () => {
      self.reportableEvent = undefined;
    };

    const loadCategoriesForContract = flow(function*(reportableEventContractId: string) {
      const result = yield ajax.compliance.reportableEvent.loadCategoriesForContract(
        reportableEventContractId
      );
      self.categories = result;

      return { options: result };
    });

    const findCategoriesForContract = flow(function*(
      reportableEventContractId: string | undefined,
      ids: any[]
    ) {
      if (!reportableEventContractId) {
        yield internalLoadAllContracts();
        return { options: [] };
      } else {
        const result: ReportableEventCategory[] = yield ajax.compliance.reportableEvent.loadCategoriesForContract(
          reportableEventContractId
        );
        const codes = ids.map(Number);
        const contracts = result.filter(c => codes.includes(c.code));
        return { options: contracts };
      }
    });

    const internalLoadAllContracts = () => ajax.compliance.reportableEvent.loadAllContracts();

    const loadAllContracts = flow(function*() {
      self.categories.clear();
      self.contracts = yield internalLoadAllContracts();
    });

    const getAllContractsAsAutoCompleteResult = flow(function*() {
      const res = yield internalLoadAllContracts();
      return { options: res };
    });

    const createReportableEvent = flow(function*(command: CreateReportableEventCommand) {
      const id = yield ajax.compliance.reportableEvent.createReportableEvent(command);
      root.notifications.addNotification(`Successfully saved the reportable event`, {
        type: NotificationType.success,
      });
      self.reportableEvent = undefined;
      root.history.push(`/compliance/reportable-events/${id}`);
    });

    const updateReportableEvent = flow(function*(command: UpdateReportableEventCommand) {
      const id = yield ajax.compliance.reportableEvent.updateReportableEvent(command);
      root.notifications.addNotification(`Successfully saved the reportable event`, {
        type: NotificationType.success,
      });
      yield loadReportableEvent(id);
    });

    const updateReportableEventVehicleInformation = flow(function*(
      command: UpdateReportableEventVehicleInformationCommand
    ) {
      const id = yield ajax.compliance.reportableEvent.updateReportableEventVehicleInformation(
        command
      );
      root.notifications.addNotification(
        `Successfully amended the reportable event vehicle information`,
        {
          type: NotificationType.success,
        }
      );
      yield loadReportableEvent(id);
    });

    const updateReportableEventInternalInformation = flow(function*(
      command: UpdateReportableEventInternalInformationCommand
    ) {
      const id = yield ajax.compliance.reportableEvent.updateReportableEventInternalInformation(
        command
      );
      root.notifications.addNotification(
        `Successfully amended the reportable event internal information`,
        {
          type: NotificationType.success,
        }
      );
      yield loadReportableEvent(id);
    });

    return {
      loadReportableEvent,
      createReportableEvent,
      updateReportableEvent,
      updateReportableEventVehicleInformation,
      updateReportableEventInternalInformation,
      loadAllContracts,
      getAllContractsAsAutoCompleteResult,
      loadCategoriesForContract,
      findCategoriesForContract,
      clearReportableEvent,
    };
  });
