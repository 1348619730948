import { IAjax } from 'src/infrastructure/ajax';
import { operationsUrls } from 'src/domain/services/apiUrls';

type NeedsVerificationStartDateItem = Operations.Domain.Queries.GetNeedsVerificationStartDate.NeedsVerificationStartDateItem;
type JobsForCancellingQuoteDto = Operations.Domain.Queries.GetJobsForCancellingQuote.JobsForCancellingQuoteDto;

// tslint:disable-next-line:no-empty
const returnVoid = () => {};

export default function init(ajax: IAjax) {
  function loadQuoteTypes() {
    return ajax
      .get(operationsUrls.quoteUrls.loadQuoteTypes())
      .toPromise()
      .then<Common.Dtos.QuoteTypeDto[]>(r => r.response);
  }

  function loadTradingCompanies() {
    return ajax
      .get(operationsUrls.tradingCompaniesUrls.list)
      .toPromise()
      .then<Common.Dtos.TradingCompanyDto[]>(r => r.response);
  }

  function loadBookingStatuses() {
    return ajax
      .get(operationsUrls.quoteUrls.loadBookingStatuses())
      .toPromise()
      .then<Operations.Domain.AggregatesModel.QuoteAggregate.QuoteStatus[]>(r => r.response);
  }

  function createQuote(command: Operations.Domain.Commands.Quote.CreateQuoteCommand) {
    return ajax
      .post(operationsUrls.quoteUrls.createQuote(), command)
      .toPromise()
      .then<string>(r => r.response);
  }

  function updateQuote(command: Operations.Domain.Commands.Quote.UpdateQuote.UpdateQuoteCommand) {
    return ajax
      .put(operationsUrls.quoteUrls.updateQuote(command.id), command)
      .toPromise()
      .then(r => returnVoid());
  }

  function bookQuote(command: Operations.Domain.Commands.Quote.BookQuote.BookQuoteCommand) {
    return ajax
      .put(operationsUrls.quoteUrls.bookQuote(command.quoteId), command)
      .toPromise()
      .then(r => returnVoid());
  }

  function verifyBooking(quoteId: string) {
    return ajax
      .put(operationsUrls.quoteUrls.verifyBooking(quoteId))
      .toPromise()
      .then(r => returnVoid());
  }

  function cancelBooking(
    command: Operations.Domain.Commands.Quote.CancelBooking.CancelBookingCommand
  ) {
    return ajax
      .put(operationsUrls.quoteUrls.cancelBooking(command.quoteId), command)
      .toPromise()
      .then(r => returnVoid());
  }

  function loadQuotesForCustomer(customerId: string) {
    return ajax
      .get(operationsUrls.quoteUrls.loadQuotesForCustomer(customerId))
      .toPromise()
      .then<Operations.Domain.Queries.ListQuotesForCustomer.QuoteForCustomer[]>(r => r.response);
  }

  function loadBookingsForCustomer(customerId: string) {
    return ajax
      .get(operationsUrls.quoteUrls.loadBookingsForCustomer(customerId))
      .toPromise()
      .then<Operations.Domain.Queries.ListBookingsForCustomer.BookingForCustomer[]>(
        r => r.response
      );
  }

  function loadRailBookingsForCustomer(customerId: string) {
    return ajax
      .get(operationsUrls.quoteUrls.loadRailBookingsForCustomer(customerId))
      .toPromise()
      .then<Operations.Domain.Queries.ListRailBookingsForCustomer.RailBookingForCustomer[]>(
        r => r.response
      );
  }

  function generatePdf(quoteId: string) {
    return ajax
      .get(operationsUrls.quoteUrls.generatePdf(quoteId))
      .toPromise()
      .then(r => returnVoid());
  }

  function getLatestPdf(quoteId: string) {
    return ajax
      .getFile(operationsUrls.quoteUrls.getLatestPdf(quoteId))
      .toPromise()
      .then<Blob>(r => r.response);
  }

  function loadQuotePdfs(quoteId: string) {
    return ajax
      .get(operationsUrls.quoteUrls.loadQuotePdfs(quoteId))
      .toPromise()
      .then<Operations.Domain.Queries.ListQuotePdfs.QuotePdfItem[]>(r => r.response);
  }

  function getPdf(quoteId: string, quotePdfId: number) {
    return ajax
      .getFile(operationsUrls.quoteUrls.getPdf(quoteId, quotePdfId))
      .toPromise()
      .then<Blob>(r => r.response);
  }

  function viewQuote(quoteId: string) {
    return ajax
      .get(operationsUrls.quoteUrls.viewQuote(quoteId))
      .toPromise()
      .then<Operations.Domain.Queries.ViewQuote.QuoteItem>(r => r.response);
  }

  function listAllQuotes(
    query: Partial<Operations.Domain.Queries.ListAllQuotes.ListAllQuotesQuery>
  ) {
    return ajax
      .get(operationsUrls.quoteUrls.listAllQuotes(query))
      .map(
        r => r.response as Common.Dtos.ListResult<Operations.Domain.Queries.ListAllQuotes.QuoteItem>
      );
  }

  function exportAllQuotesToExcel(
    query: Partial<Operations.Domain.Queries.ListAllQuotes.ListAllQuotesQuery>
  ) {
    return ajax
      .getFile(operationsUrls.quoteUrls.exportAllQuotesToExcel(query))
      .toPromise()
      .then<Blob>(r => r.response);
  }

  function listBookings(page: number, size: number, search: string, status: number[]) {
    return ajax
      .get(operationsUrls.quoteUrls.listBookings({ page, size, search, status }))
      .map(
        r =>
          r.response as Common.Dtos.ListResult<
            Operations.Domain.Queries.ListBookings.ListBookingItem
          >
      );
  }

  function findQuotes(quoteIds: string[]) {
    return ajax
      .get(operationsUrls.quoteUrls.findQuotes(quoteIds))
      .toPromise()
      .then<Operations.Domain.Queries.GetQuotes.QuoteItem[]>(r => r.response);
  }

  function listBookingsForAccounts(
    query: Partial<Operations.Domain.Queries.ListBookingsForAccounts.ListBookingsForAccountsQuery>
  ) {
    return ajax
      .get(operationsUrls.quoteUrls.listBookingsForAccounts(query))
      .map(
        r =>
          r.response as Common.Dtos.ListResult<
            Operations.Domain.Queries.ListBookingsForAccounts.BookingItem
          >
      );
  }

  function declineQuote(command: Operations.Domain.Commands.Quote.DeclineQuoteCommand) {
    return ajax
      .put(operationsUrls.quoteUrls.declineQuote(command.quoteId), command)
      .toPromise()
      .then(r => returnVoid());
  }

  function duplicateQuote(
    command: Operations.Domain.Commands.Quote.DuplicateQuote.DuplicateQuoteCommand
  ) {
    return ajax
      .post(operationsUrls.quoteUrls.duplicateQuote(command.quoteId), command)
      .toPromise()
      .then(r => r.response);
  }

  function viewBookingForOps(quoteId: string) {
    return ajax
      .get(operationsUrls.quoteUrls.viewBookingForOps(quoteId))
      .toPromise()
      .then<Operations.Domain.Queries.ViewBookingForOps.OpsBookingItem>(r => r.response);
  }

  function verifyJobs(quoteId: string) {
    return ajax
      .put(operationsUrls.quoteUrls.verifyJobs(quoteId))
      .toPromise()
      .then(r => returnVoid());
  }

  function getNeedsVerificationStartDate(quoteId: string) {
    return ajax
      .get(operationsUrls.quoteUrls.getNeedsVerificationStartDate(quoteId))
      .toPromise()
      .then<NeedsVerificationStartDateItem>(r => r.response);
  }

  function regenerateJobs(
    quoteId: string,
    tripNumbers: number[] | undefined,
    regenerateFutureBookings: boolean
  ) {
    return ajax
      .put(operationsUrls.quoteUrls.regenerateJobs(quoteId), {
        quoteId,
        tripNumbers,
        regenerateFutureBookings,
      })
      .toPromise()
      .then(_ => returnVoid());
  }

  function loadJobsForCancellingQuote(quoteId: string, forceJobsToCancelInProgress: boolean) {
    return ajax
      .get(
        operationsUrls.quoteUrls.loadJobsForCancellingQuote(quoteId, forceJobsToCancelInProgress)
      )
      .toPromise()
      .then<JobsForCancellingQuoteDto>(r => r.response);
  }

  return {
    quote: {
      loadQuoteTypes,
      createQuote,
      generatePdf,
      getLatestPdf,
      loadQuotePdfs,
      getPdf,
      viewQuote,
      listAllQuotes,
      exportAllQuotesToExcel,
      updateQuote,
      declineQuote,
      bookQuote,
      verifyBooking,
      listBookings,
      cancelBooking,
      duplicateQuote,
      listBookingsForAccounts,
      loadBookingStatuses,
      loadBookingsForCustomer,
      loadQuotesForCustomer,
      viewBookingForOps,
      findQuotes,
      verifyJobs,
      regenerateJobs,
      getNeedsVerificationStartDate,
      loadJobsForCancellingQuote,
      loadRailBookingsForCustomer,
      loadTradingCompanies,
    },
  };
}
