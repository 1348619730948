export function isDefined<T>(value: T | undefined | null | void): value is T {
  return value !== undefined && value !== null;
}

// tslint:disable-next-line:no-empty
export const noop = () => {};

// tslint:disable-next-line:no-any
export function isPromise<T>(value: Promise<T> | any): value is Promise<T> {
  return value && !!value.then && !!value.catch;
}

// tslint:disable-next-line:no-any
export const capitalize = (s: string): string => {
  return s.charAt(0).toUpperCase() + s.slice(1);
};

export function getPropertyName<T>(key: keyof T): string {
  if (typeof key !== 'string') {
    return '';
  }
  return key;
}

export function getCapitilizedPropertyName<T>(key: keyof T): string {
  return capitalize(getPropertyName(key));
}

export const buildSubProperty = (address: string[]): string => {
  return address.join('.');
};
