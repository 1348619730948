import './MaintainSupplier.scss';
import { RouteComponentProps } from 'react-router-dom';
import {
  PagePrimarySize,
  PaneType,
  FieldType,
  ActionType,
  ShellModalSize,
  IModalDefBuilderApi,
  ISectionDef,
} from 'src/views/definitionBuilders/types';
import CrudPage, { CrudPageMode, ICrudPageDef } from 'src/views/components/Page/pages/CrudPage';
import * as EmailValidator from 'email-validator';
import { PlusIcon, EditIcon, ExternalLinkIcon, TrashIcon } from 'src/images/icons';
import ActionBar from 'src/views/components/Page/actions/ActionBar';
import { standardTextSanitisers } from 'src/views/componentBuilders';
import { DateTime } from 'luxon';
import { getSubmitCloseModalActionGroupDef } from 'src/views/definitionBuilders/common';
import { PurchaseOrderStatus } from 'src/api/enums';
import getAddAttachmentPanelDef from 'src/views/routes/workshop/attachment/getAddAttachmentPanelDef';
import { UpdateAttachmentFormData } from 'src/views/routes/workshop/attachment/getUpdateAttachmentModalDef';
import { DeleteAttachmentFormData } from 'src/views/routes/workshop/attachment/getDeleteAttachmentModalDef';
import {
  DownloadAttachmentQuery,
  LoadAttachmentsQuery,
} from 'src/views/components/Attachment/attachmentHelper';
import { AddAttachmentData } from 'src/domain/entities/workshop/attachment/WorkshopAttachmentModel';

type SupplierItem = Workshop.Domain.Queries.Suppliers.SupplierItem;
type CreateSupplierCommand = Workshop.Domain.Commands.Supplier.CreateSupplierCommand;
type UpdateSupplierCommand = Workshop.Domain.Commands.Supplier.UpdateSupplierCommand;
type SupplierContact = Workshop.Domain.Queries.Suppliers.GetContactsForSupplier.ContactItem;
type CreateSupplierContactCommand = Workshop.Domain.Commands.Supplier.CreateSupplierContactCommand;
type UpdateSupplierContactCommand = Workshop.Domain.Commands.Supplier.UpdateSupplierContactCommand;
type DeleteSupplierContactCommand = Workshop.Domain.Commands.Supplier.DeleteSupplierContactCommand;
type SupplierNote = Workshop.Domain.AggregatesModel.SupplierAggregate.SupplierNote;
type CreateSupplierNoteCommand = Workshop.Domain.Commands.Supplier.CreateSupplierNoteCommand;
type UpdateSupplierNoteCommand = Workshop.Domain.Commands.Supplier.UpdateSupplierNoteCommand;
type DeleteSupplierNoteCommand = Workshop.Domain.Commands.Supplier.DeleteSupplierNoteCommand;
type GetPurchaseOrderListQuery = Workshop.Domain.Queries.Purchasing.GetPurchaseOrderList.GetPurchaseOrderListQuery;
type PurchaseOrderListItem = Workshop.Domain.Queries.Purchasing.PurchaseOrderListItem;
type AttachmentDto = Workshop.Domain.Queries.Attachment.WorkshopAttachmentDetailsDto;

export interface IMaintainSupplierProps {
  mode: CrudPageMode;
  canManageSuppliers: boolean;

  onLoadSupplier: (id: string) => Promise<void>;
  supplier: SupplierItem | undefined;

  checkForUniqueSupplierName: (name: string) => Promise<Common.Dtos.UniqueNameCheckResultDto>;
  onCreateSupplier: (command: CreateSupplierCommand) => Promise<void>;
  onUpdateSupplier: (command: UpdateSupplierCommand) => Promise<void>;

  onCreateSupplierContact: (command: CreateSupplierContactCommand) => Promise<void>;
  onUpdateSupplierContact: (command: UpdateSupplierContactCommand) => Promise<void>;
  deleteSupplierContactCommand: (command: DeleteSupplierContactCommand) => Promise<void>;

  onCreateSupplierNote: (command: CreateSupplierNoteCommand) => Promise<void>;
  onUpdateSupplierNote: (command: UpdateSupplierNoteCommand) => Promise<void>;
  onDeleteSupplierNote: (command: DeleteSupplierNoteCommand) => Promise<void>;

  purchaseOrdersForSupplier: PurchaseOrderListItem[];
  listPurchaseOrdersForSupplier: (query: Partial<GetPurchaseOrderListQuery>) => Promise<void>;
  attachments: Array<AttachmentDto>;
  loadAttachmentsForSupplier: (query: LoadAttachmentsQuery) => Promise<void>;
  addAttachment: (data: AddAttachmentData) => Promise<void>;
  downloadAttachmentForSupplier: (query: DownloadAttachmentQuery) => Promise<void>;
  deleteAttachmentFromSupplier: (command: DeleteAttachmentFormData) => Promise<void>;
  updateAttachmentForSupplier: (command: UpdateAttachmentFormData) => Promise<void>;
}

interface IUpdateSupplierRouteParams {
  id: string;
}

type InternalProps = IMaintainSupplierProps & RouteComponentProps<IUpdateSupplierRouteParams>;

export const MaintainSupplier: React.FC<InternalProps> = ({
  match,
  mode,
  supplier,
  canManageSuppliers,
  purchaseOrdersForSupplier,
  listPurchaseOrdersForSupplier,
  onLoadSupplier,
  checkForUniqueSupplierName,
  onCreateSupplier,
  onUpdateSupplier,
  onCreateSupplierContact,
  onUpdateSupplierContact,
  onCreateSupplierNote,
  onUpdateSupplierNote,
  onDeleteSupplierNote,
  addAttachment,
  loadAttachmentsForSupplier,
  downloadAttachmentForSupplier,
  deleteAttachmentFromSupplier,
  updateAttachmentForSupplier,
  attachments,
  deleteSupplierContactCommand,
}: InternalProps) => {
  const isUpdateMode = mode === 'update';
  const supplierId = match.params.id;

  const handlePreSubmitForCreate = (supplier: SupplierItem): CreateSupplierCommand => {
    return {
      name: supplier.name,
      billingAddress: supplier.billingAddress,
      billingCity: supplier.billingCity,
      billingPostcode: supplier.billingPostcode,
      billingState: supplier.billingState,
      email: supplier.email,
      fax: supplier.fax,
      otherAddress: supplier.otherAddress,
      otherCity: supplier.otherCity,
      otherPostcode: supplier.otherPostcode,
      otherState: supplier.otherState,
      phone: supplier.phone,
      website: supplier.website,
    };
  };

  const handlePreSubmitForUpdate = (supplier: SupplierItem): UpdateSupplierCommand => {
    return {
      id: supplierId,
      name: supplier.name,
      billingAddress: supplier.billingAddress,
      billingCity: supplier.billingCity,
      billingPostcode: supplier.billingPostcode,
      billingState: supplier.billingState,
      email: supplier.email,
      fax: supplier.fax,
      otherAddress: supplier.otherAddress,
      otherCity: supplier.otherCity,
      otherPostcode: supplier.otherPostcode,
      otherState: supplier.otherState,
      phone: supplier.phone,
      website: supplier.website,
    };
  };

  const getPageDef = (readonly: boolean): ICrudPageDef => {
    return {
      primarySize: PagePrimarySize.half,
      primarySection: {
        title: isUpdateMode ? 'Manage Supplier' : 'Create a Supplier',
        panels: [
          {
            panes: [
              {
                paneType: PaneType.formFieldsPane,
                columnCount: 1,
                fields: [
                  {
                    fieldType: FieldType.textField,
                    label: 'Name',
                    dataAddr: 'name',
                    maxLength: 255,
                    mandatory: true,
                    validateAsync: async d => {
                      if (
                        !d.fieldValue ||
                        (isUpdateMode &&
                          supplier &&
                          supplier.name.toUpperCase() === d.fieldValue.toUpperCase())
                      ) {
                        return undefined;
                      }
                      const result = await checkForUniqueSupplierName(d.fieldValue);
                      return result.nameExists ? `Name is already in use` : undefined;
                    },
                  },
                ],
              },
            ],
          },
          {
            title: 'Organisation Contact Details',
            panes: [
              {
                paneType: PaneType.formFieldsPane,
                columnCount: 1,
                fields: [
                  {
                    fieldType: FieldType.textField,
                    label: 'Phone',
                    dataAddr: 'phone',
                    maxLength: 20,
                  },
                ],
              },
              {
                paneType: PaneType.formFieldsPane,
                columnCount: 1,
                fields: [
                  {
                    fieldType: FieldType.textField,
                    label: 'Fax',
                    dataAddr: 'fax',
                    maxLength: 20,
                  },
                ],
              },
              {
                paneType: PaneType.formFieldsPane,
                columnCount: 1,
                fields: [
                  {
                    fieldType: FieldType.textField,
                    label: 'Email',
                    dataAddr: 'email',
                    maxLength: 200,
                    validate: d => {
                      return !!d.fieldValue && !EmailValidator.validate(d.fieldValue)
                        ? `${d.fieldValue} must be a valid email`
                        : undefined;
                    },
                  },
                ],
              },
              {
                paneType: PaneType.formFieldsPane,
                columnCount: 1,
                fields: [
                  {
                    fieldType: FieldType.textField,
                    label: 'Website',
                    dataAddr: 'website',
                    maxLength: 200,
                  },
                ],
              },
            ],
          },
          {
            title: 'Billing Address',
            panes: [
              {
                paneType: PaneType.formFieldsPane,
                columnCount: 1,
                fields: [
                  {
                    fieldType: FieldType.textField,
                    label: 'Street',
                    dataAddr: 'billingAddress',
                    maxLength: 200,
                  },
                ],
              },
              {
                paneType: PaneType.formFieldsPane,
                columnCount: 1,
                fields: [
                  {
                    fieldType: FieldType.textField,
                    label: 'City',
                    dataAddr: 'billingCity',
                    maxLength: 20,
                  },
                ],
              },
              {
                paneType: PaneType.formFieldsPane,
                columnCount: 2,
                fields: [
                  {
                    fieldType: FieldType.textField,
                    label: 'State',
                    dataAddr: 'billingState',
                    maxLength: 20,
                  },
                  {
                    fieldType: FieldType.textField,
                    label: 'Postcode',
                    dataAddr: 'billingPostcode',
                    maxLength: 20,
                  },
                ],
              },
            ],
          },
          {
            title: 'Other Address',
            panes: [
              {
                paneType: PaneType.formFieldsPane,
                columnCount: 1,
                fields: [
                  {
                    fieldType: FieldType.textField,
                    label: 'Street',
                    dataAddr: 'otherAddress',
                    maxLength: 200,
                  },
                ],
              },
              {
                paneType: PaneType.formFieldsPane,
                columnCount: 1,
                fields: [
                  {
                    fieldType: FieldType.textField,
                    label: 'City',
                    dataAddr: 'otherCity',
                    maxLength: 20,
                  },
                ],
              },
              {
                paneType: PaneType.formFieldsPane,
                columnCount: 2,
                fields: [
                  {
                    fieldType: FieldType.textField,
                    label: 'State',
                    dataAddr: 'otherState',
                    maxLength: 20,
                  },
                  {
                    fieldType: FieldType.textField,
                    label: 'Postcode',
                    dataAddr: 'otherPostcode',
                    maxLength: 20,
                  },
                ],
              },
            ],
          },
        ],
        onFormPreSubmit: isUpdateMode ? handlePreSubmitForUpdate : handlePreSubmitForCreate,
        onFormSubmit: isUpdateMode ? onUpdateSupplier : onCreateSupplier,
      },
      secondarySections: !isUpdateMode
        ? []
        : [
            {
              title: 'Contacts',
              panels: [
                {
                  panes: [
                    {
                      paneType: PaneType.repeatingPane,
                      dataAddr: 'contacts',
                      itemPanes: [
                        {
                          paneType: PaneType.customPane,
                          render: api => {
                            const contact = api.data.paneValue as SupplierContact;
                            return (
                              <div className="supplier-maintenance-contact-item">
                                <div className="details">
                                  <div>
                                    <strong>{`${contact.firstName} ${contact.lastName ||
                                      ''}`}</strong>
                                  </div>
                                  {contact.phone ? (
                                    <div>
                                      <label>P</label> {contact.phone}
                                    </div>
                                  ) : null}
                                  {contact.mobile ? (
                                    <div>
                                      <label>M</label> {contact.mobile}
                                    </div>
                                  ) : null}
                                  {contact.fax ? (
                                    <div>
                                      <label>F</label> {contact.fax}
                                    </div>
                                  ) : null}
                                  {contact.email ? (
                                    <div>
                                      <label>E</label> {contact.email}
                                    </div>
                                  ) : null}
                                </div>
                                {canManageSuppliers && (
                                  <div className="edit">
                                    <ActionBar
                                      actionGroupDefs={[
                                        {
                                          actions: [
                                            {
                                              actionType: ActionType.modalActionButton,
                                              label: 'Edit',
                                              hidden: !readonly,
                                              icon: <EditIcon size="sm" />,
                                              modalSize: ShellModalSize.oneThird,
                                              modalDef: getContactModalDef('edit'),
                                            },
                                            {
                                              actionType: ActionType.modalActionButton,
                                              label: 'Delete',
                                              hidden: item => !item.paneValue.hasLinkedEntity,
                                              icon: <TrashIcon size="sm" />,
                                              modalSize: ShellModalSize.oneThird,
                                              modalDef: modalDefApi => ({
                                                title: () => 'Delete Contact',
                                                asForm: true,
                                                panels: [
                                                  {
                                                    panes: [
                                                      {
                                                        paneType: PaneType.customPane,
                                                        render: () => (
                                                          <p>
                                                            Are you sure you want to delete this
                                                            contact?
                                                          </p>
                                                        ),
                                                      },
                                                    ],
                                                  },
                                                ],
                                                secondaryActions: [
                                                  getSubmitCloseModalActionGroupDef('Ok'),
                                                ],
                                                onFormSubmit: () => {
                                                  return deleteSupplierContactCommand({
                                                    supplierId:
                                                      modalDefApi.actionData.sectionValue.id,
                                                    contactId:
                                                      modalDefApi.actionData.parentValue.id,
                                                  });
                                                },
                                              }),
                                            },
                                          ],
                                        },
                                      ]}
                                      actionMeta={{
                                        formSubmitting: false,
                                        hideLabel: true,
                                        borderless: true,
                                      }}
                                      actionData={{ ...api.data, actionValue: undefined }}
                                    />
                                  </div>
                                )}
                              </div>
                            );
                          },
                        },
                      ],
                    },
                  ],
                },
              ],
              secondaryActions: canManageSuppliers
                ? [
                    {
                      actions: [
                        {
                          actionType: ActionType.modalActionButton,
                          label: 'Add Contact',
                          hidden: !readonly,
                          icon: <PlusIcon />,
                          modalSize: ShellModalSize.oneThird,
                          modalDef: getContactModalDef('add'),
                        },
                      ],
                    },
                  ]
                : [],
            },
            {
              title: 'Purchases',
              dataAddr: 'purchaseOrdersForSupplier',
              panels: [
                {
                  panes: [
                    {
                      paneType: PaneType.tablePane,
                      neverEditable: true,
                      fields: [
                        {
                          fieldType: FieldType.textField,
                          label: 'PO Number',
                          dataAddr: 'purchaseOrderNumber',
                          linkTo: d => `/workshop/purchase-orders/${d.parentValue.id}`,
                        },
                        {
                          fieldType: FieldType.dateField,
                          label: 'Created Date',
                          dataAddr: 'dateCreated',
                          orderBy: 'dateCreated',
                        },
                        {
                          fieldType: FieldType.readonlyField,
                          label: 'Status',
                          dataAddr: ['status', 'description'],
                          formatReadonly: d => d.fieldValue,
                        },
                      ],
                    },
                    {
                      paneType: PaneType.actionListPane,
                      actionGroups: [
                        {
                          actions: [
                            {
                              actionType: ActionType.actionLink,
                              label: 'View All Purchases',
                              icon: <ExternalLinkIcon />,
                              to: `/workshop/purchase-orders?suppliers=${supplierId}`,
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
            {
              title: 'Notes',
              panels: [
                {
                  panes: [
                    {
                      paneType: PaneType.repeatingPane,
                      dataAddr: 'notes',
                      itemPanes: [
                        {
                          paneType: PaneType.customPane,
                          render: api => {
                            const note = api.data.paneValue as SupplierNote;
                            return (
                              <div className="supplier-maintenance-note-item">
                                <div className="author">{note.lastModifiedBy}</div>
                                <div className="date">
                                  {DateTime.fromISO(note.lastModifiedOn).toLocaleString(
                                    DateTime.DATETIME_MED
                                  )}
                                </div>
                                {canManageSuppliers && (
                                  <div className="edit">
                                    <ActionBar
                                      actionGroupDefs={[
                                        {
                                          actions: [
                                            {
                                              actionType: ActionType.modalActionButton,
                                              label: 'Edit',
                                              hidden: !readonly,
                                              icon: <EditIcon size="sm" />,
                                              modalSize: ShellModalSize.oneThird,
                                              modalDef: getNoteModalDef('edit'),
                                            },
                                            {
                                              actionType: ActionType.modalActionButton,
                                              label: 'Delete',
                                              hidden: !readonly,
                                              icon: <TrashIcon size="sm" />,
                                              modalSize: ShellModalSize.oneThird,
                                              modalDef: modalDefApi => ({
                                                title: _ => 'Delete Note',
                                                asForm: true,
                                                panels: [
                                                  {
                                                    panes: [
                                                      {
                                                        paneType: PaneType.customPane,
                                                        render: _ => (
                                                          <p>
                                                            Are you sure you want to delete this
                                                            note?
                                                          </p>
                                                        ),
                                                      },
                                                    ],
                                                  },
                                                ],
                                                secondaryActions: [
                                                  getSubmitCloseModalActionGroupDef('Ok'),
                                                ],
                                                onFormSubmit: _ =>
                                                  onDeleteSupplierNote({
                                                    supplierId:
                                                      modalDefApi.actionData.parentValue.supplierId,
                                                    noteId: modalDefApi.actionData.parentValue.id,
                                                  }),
                                              }),
                                            },
                                          ],
                                        },
                                      ]}
                                      actionMeta={{
                                        formSubmitting: false,
                                        hideLabel: true,
                                        borderless: true,
                                      }}
                                      actionData={{ ...api.data, actionValue: undefined }}
                                    />
                                  </div>
                                )}
                                <div className="note-details">{note.details}</div>
                              </div>
                            );
                          },
                        },
                      ],
                    },
                  ],
                },
              ],
              secondaryActions: canManageSuppliers
                ? [
                    {
                      actions: [
                        {
                          actionType: ActionType.modalActionButton,
                          label: 'Add Note',
                          hidden: !readonly,
                          icon: <PlusIcon />,
                          modalSize: ShellModalSize.oneThird,
                          modalDef: getNoteModalDef('add'),
                        },
                      ],
                    },
                  ]
                : [],
            },
            getAddAttachmentPanelDef(
              (supplier && supplier.id) || '',
              'supplier',
              addAttachment,
              loadAttachmentsForSupplier,
              downloadAttachmentForSupplier,
              deleteAttachmentFromSupplier,
              updateAttachmentForSupplier,
              attachments
            ),
          ],
    };
  };

  const getContactModalDef = (
    mode: 'add' | 'edit'
  ): ((modalDefApi: IModalDefBuilderApi) => ISectionDef) => {
    return modalDefApi => ({
      title: mode === 'add' ? 'Add Contact' : 'Edit Contact',
      asForm: true,
      explicitData: mode === 'add' ? {} : modalDefApi.actionData.paneValue,
      panels: [
        {
          panes: [
            {
              paneType: PaneType.formFieldsPane,
              columnCount: 2,
              fields: [
                {
                  fieldType: FieldType.textField,
                  mandatory: true,
                  label: 'First Name',
                  dataAddr: 'firstName',
                  maxLength: 200,
                },
                {
                  fieldType: FieldType.textField,
                  label: 'Last Name',
                  dataAddr: 'lastName',
                  maxLength: 200,
                },
              ],
            },
          ],
        },
        {
          title: 'Contact Details',
          panes: [
            {
              paneType: PaneType.formFieldsPane,
              fields: [
                {
                  fieldType: FieldType.textField,
                  label: 'Phone',
                  dataAddr: 'phone',
                  maxLength: 20,
                  onChange: api =>
                    api.setFormValue(
                      api.fieldDataAddr,
                      standardTextSanitisers.numericSanitiser(api.fieldData.fieldValue as string)
                    ),
                },
                {
                  fieldType: FieldType.textField,
                  label: 'Mobile',
                  dataAddr: 'mobile',
                  maxLength: 20,
                  onChange: api =>
                    api.setFormValue(
                      api.fieldDataAddr,
                      standardTextSanitisers.numericSanitiser(api.fieldData.fieldValue as string)
                    ),
                },
                {
                  fieldType: FieldType.textField,
                  label: 'Fax',
                  dataAddr: 'fax',
                  maxLength: 20,
                  onChange: api =>
                    api.setFormValue(
                      api.fieldDataAddr,
                      standardTextSanitisers.numericSanitiser(api.fieldData.fieldValue as string)
                    ),
                },
                {
                  fieldType: FieldType.textField,
                  label: 'Email',
                  dataAddr: 'email',
                  maxLength: 200,
                  validate: d => {
                    return !!d.fieldValue && !EmailValidator.validate(d.fieldValue)
                      ? `${d.fieldValue} must be a valid email`
                      : undefined;
                  },
                },
              ],
            },
          ],
        },
      ],
      secondaryActions: [getSubmitCloseModalActionGroupDef('Ok')],
      onFormPreSubmit: (
        contact: SupplierContact
      ): CreateSupplierContactCommand | UpdateSupplierContactCommand => {
        let cmd = {
          supplierId: supplierId,
          email: contact.email,
          fax: contact.fax,
          firstName: contact.firstName,
          lastName: contact.lastName,
          mobile: contact.mobile,
          phone: contact.phone,
        };

        return mode === 'add'
          ? cmd
          : {
              ...cmd,
              contactId: contact.id,
            };
      },
      onFormSubmit: mode === 'add' ? onCreateSupplierContact : onUpdateSupplierContact,
    });
  };

  const getNoteModalDef = (
    mode: 'add' | 'edit'
  ): ((modalDefApi: IModalDefBuilderApi) => ISectionDef) => {
    return modalDefApi => ({
      title: mode === 'add' ? 'Add Note' : 'Edit Note',
      asForm: true,
      explicitData: mode === 'add' ? {} : modalDefApi.actionData.paneValue,
      panels: [
        {
          panes: [
            {
              paneType: PaneType.formFieldsPane,
              fields: [
                {
                  fieldType: FieldType.textAreaField,
                  mandatory: true,
                  label: 'Details',
                  dataAddr: 'details',
                },
              ],
            },
          ],
        },
      ],
      secondaryActions: [getSubmitCloseModalActionGroupDef('Ok')],
      onFormPreSubmit: (
        note: SupplierNote
      ): CreateSupplierNoteCommand | UpdateSupplierNoteCommand => {
        return mode === 'add'
          ? {
              supplierId: supplierId,
              details: note.details,
            }
          : {
              noteId: note.id,
              supplierId: supplierId,
              details: note.details,
            };
      },
      onFormSubmit: mode === 'add' ? onCreateSupplierNote : onUpdateSupplierNote,
    });
  };

  return (
    <CrudPage
      def={api => getPageDef(api.readonly)}
      mode={mode}
      isEditingForbidden={!canManageSuppliers}
      onLoadData={() =>
        Promise.all([
          onLoadSupplier(supplierId),
          loadAttachmentsForSupplier({ aggregateId: supplierId, aggregateType: 'supplier' }),
          listPurchaseOrdersForSupplier({
            suppliers: [supplierId],
            statuses: [
              PurchaseOrderStatus.Approved,
              PurchaseOrderStatus.AwaitingCredit,
              PurchaseOrderStatus.GoodsReceived,
              PurchaseOrderStatus.New,
            ],
            size: 100,
            orderBy: 'dateCreated',
          }),
        ]).then(() => Promise.resolve())
      }
      data={{ ...supplier, purchaseOrdersForSupplier }}
      createDefaultData={{}}
    />
  );
};
