import { UiRouteName } from 'src/domain/baseTypes';
import { IRootStoreModel } from 'src/domain/entities/RootStoreModel';
import UiRoute from 'src/domain/services/buildUiRoutes/UiRoute';

export default function buildUiRoutes(store: IRootStoreModel) {
  const createShift = new UiRoute(
    UiRouteName.workshopShiftCreate,
    '/workshop/shifts/create',
    () => `Create Shift`,
    false
  );

  const manageShift = new UiRoute(
    UiRouteName.workshopShiftManage,
    '/workshop/shifts/:id',
    () => `Manage Shift`,
    false
  );

  const shiftsList = new UiRoute(
    UiRouteName.workshopShiftList,
    '/workshop/shifts',
    () => 'Shifts',
    false,
    true,
    [createShift, manageShift]
  );

  return {
    [UiRouteName.workshopShiftList]: shiftsList,
    [UiRouteName.workshopShiftCreate]: createShift,
    [UiRouteName.workshopShiftManage]: manageShift,
  };
}
