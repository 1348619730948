import { ModalDefBuilder, PaneType, FieldType } from 'src/views/definitionBuilders/types';
import { getSubmitCloseModalActionGroupDef } from 'src/views/definitionBuilders/common';

export default function getExportAssetCostReportModalDef(
  onSubmit: (datefrom: string, dateTo: string) => Promise<void>
): ModalDefBuilder {
  return () => {
    return {
      title: 'Export Asset Maintenance Costs Report',
      asForm: true,
      panels: [
        {
          panes: [
            {
              paneType: PaneType.nestingPane,
              panes: [
                {
                  paneType: PaneType.formFieldsPane,
                  dataRequiredForRows: 'paneValue',
                  fields: [
                    {
                      fieldType: FieldType.dateField,
                      label: 'Start Date',
                      dataAddr: 'dateFrom',
                      mandatory: true,
                    },
                    {
                      fieldType: FieldType.dateField,
                      label: 'End Date',
                      dataAddr: 'dateTo',
                      mandatory: true,
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
      secondaryActions: [getSubmitCloseModalActionGroupDef('Submit')],
      onFormSubmit: values => {
        return onSubmit(values.dateFrom, values.dateTo);
      },
    };
  };
}
