import { IRootStoreModel } from 'src/domain/entities/RootStoreModel';
import { UiRoute } from 'src/domain/services/buildUiRoutes';
import { UiRouteName } from 'src/domain/baseTypes';

export default function buildUiRoutes(store: IRootStoreModel) {
  const cctvRequestCreate = new UiRoute(
    UiRouteName.complianceCctvRequestCreate,
    '/compliance/cctv-requests/create',
    () => 'Create CCTV Request',
    true
  );

  const cctvRequestUpdate = new UiRoute(
    UiRouteName.complianceCctvRequestUpdate,
    '/compliance/cctv-requests/:id',
    () => 'Manage CCTV Request',
    true
  );

  const cctvRequestList = new UiRoute(
    UiRouteName.complianceCctvRequestList,
    '/compliance/cctv-requests',
    () => 'CCTV Requests',
    true,
    false,
    [cctvRequestCreate, cctvRequestUpdate]
  );

  return {
    [UiRouteName.complianceCctvRequestCreate]: cctvRequestCreate,
    [UiRouteName.complianceCctvRequestUpdate]: cctvRequestUpdate,
    [UiRouteName.complianceCctvRequestList]: cctvRequestList,
  };
}
