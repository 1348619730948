import './DaySchedule.scss';

import { useEffect } from 'react';
import { DateTime, Interval } from 'luxon';
import { ChevronLeftIcon, ChevronRightIcon, SpinnerIcon } from 'src/images/icons';
import ScheduledJob from './ScheduledJob';
import PrintDailyRunSheet from './PrintDailyRunSheet/PrintDailyRunSheetContainer';
import { ScheduleLegend } from '../../ScheduleCommon';
import { RenderRoster } from './PrintDailyRunSheet/PrintDailyRunSheet';
import { TIMEZONE } from 'src/appSettings';
import { Link } from 'react-router-dom';
import WorkshopShiftProgressStatusIconLegend from 'src/views/components/workshop/shiftProgressIconLegend/WorkshopShiftProgressStatusIconLegend';
import ActionBar, {
  IButtonAction,
  ILinkAction,
  ICustomAction,
} from 'src/views/components/ActionBar/ActionBar';
import DailyNote from '../DailyNote';
import { getWorkshopDepotSelectAction } from 'src/views/routes/workshop/jobs/jobSchedule/Scheduler/WorkshopDepotSelect';

type AddDailyNoteCommand = Workshop.Domain.Commands.DailyNote.AddDailyNote.AddDailyNoteCommand;
type UpdateDailyNoteCommand = Workshop.Domain.Commands.DailyNote.UpdateDailyNote.UpdateDailyNoteCommand;
type DailyNoteItem = Workshop.Domain.Queries.DailyNote.GetDailyNotes.DailyNoteItem;
type WorkshopDepot = Common.Queries.Workshop.GetWorkshopDepots.WorkshopDepotDto;
type DailyRunSheetItem = Workshop.Domain.Queries.GetDailyRunSheet.DailyRunSheetItem;

export interface IDayScheduleProps {
  currentLocalDay: DateTime;
  dailyRunSheetItem: DailyRunSheetItem | undefined;
  jobsLoading: boolean;
  onLoadData: (currentDay: DateTime, depotId: number) => Promise<void>;

  canManageDailyNotes: boolean;
  dailyNote?: DailyNoteItem;
  dailyNoteLoading: boolean;
  onAddDailyNote: (cmd: AddDailyNoteCommand) => Promise<void>;
  onUpdateDailyNote: (cmd: UpdateDailyNoteCommand) => Promise<void>;

  workshopDepots: Array<WorkshopDepot>;
  defaultWorkshopDepot: WorkshopDepot;
  selectedWorkshopDepotId: number;
}

const DaySchedule: React.FC<IDayScheduleProps> = (props: IDayScheduleProps) => {
  const handleCurrentDayChange = async (newDay: DateTime, depotId: number) => {
    await props.onLoadData(newDay, depotId);
  };

  useEffect(() => {
    const dayChange = async () => {
      await handleCurrentDayChange(
        props.currentLocalDay,
        props.selectedWorkshopDepotId || props.defaultWorkshopDepot.id
      );
    };

    dayChange();
  }, []);

  const {
    currentLocalDay,
    dailyRunSheetItem,
    jobsLoading,
    canManageDailyNotes,
    dailyNoteLoading,
    onAddDailyNote,
    onUpdateDailyNote,
    dailyNote,
    workshopDepots,
    selectedWorkshopDepotId,
  } = props;

  return (
    <div className="scheduler-component">
      <div className="timeline-scroller-component">
        <div className="control-panel">
          <div>
            <ActionBar
              groups={getActionGroups(
                {
                  onShowToday: () => {
                    handleCurrentDayChange(
                      DateTime.local().startOf('day'),
                      selectedWorkshopDepotId
                    );
                  },
                  onShowNextTimeUnit: () => {
                    handleCurrentDayChange(
                      currentLocalDay.plus({ days: 1 }),
                      selectedWorkshopDepotId
                    );
                  },
                  onShowPrevTimeUnit: () => {
                    handleCurrentDayChange(
                      currentLocalDay.minus({ days: 1 }),
                      selectedWorkshopDepotId
                    );
                  },
                  onDepotChanged: (selectedDepot: WorkshopDepot) => {
                    if (selectedDepot) {
                      handleCurrentDayChange(currentLocalDay, selectedDepot.id);
                    }
                  },
                },
                currentLocalDay,
                workshopDepots,
                selectedWorkshopDepotId
              )}
            />
            <span className={`scheduler-loading ${jobsLoading ? 'visible' : ''}`}>
              <SpinnerIcon fixedWidth />
              <small className="loading-text">Loading ...</small>
            </span>
            <ScheduleLegend full />
          </div>
        </div>
        <div className="panels">
          <div className="scheduler-jobs">
            {!props.jobsLoading && dailyRunSheetItem ? (
              dailyRunSheetItem.jobs
                .filter(job =>
                  Interval.fromDateTimes(
                    DateTime.fromISO(job.startDateTime).toLocal(),
                    DateTime.fromISO(job.endDateTime).toLocal()
                  ).overlaps(Interval.fromDateTimes(currentLocalDay, currentLocalDay.endOf('day')))
                )
                .map(job => <ScheduledJob key={job.jobId} job={job} day={currentLocalDay} />)
            ) : (
              <span className={`scheduler-loading ${jobsLoading ? 'visible' : ''}`}>
                <SpinnerIcon fixedWidth />
                <small className="loading-text">Loading ...</small>
              </span>
            )}
          </div>
          <div className="side-panel">
            <div className="title-section">
              <h4>
                Roster
                {dailyRunSheetItem &&
                  dailyRunSheetItem.roster &&
                  dailyRunSheetItem.roster.rosterDetails && (
                    <>
                      {' '}
                      <Link
                        to={`/workshop/rosters/${dailyRunSheetItem.roster.rosterDetails.rosterId}`}>
                        {dailyRunSheetItem.roster.rosterDetails.rosterName}
                      </Link>
                    </>
                  )}
              </h4>
              <WorkshopShiftProgressStatusIconLegend
                showWarningsOnly
                styleOption="forDailySchedule"
              />
            </div>
            {jobsLoading ? (
              <span className={`scheduler-loading ${jobsLoading ? 'visible' : ''}`}>
                <SpinnerIcon fixedWidth />
                <small className="loading-text">Loading ...</small>
              </span>
            ) : (
              <div>
                <div className="roster">
                  {dailyRunSheetItem &&
                    dailyRunSheetItem.roster &&
                    RenderRoster(currentLocalDay, dailyRunSheetItem.roster, true)}
                </div>
                <DailyNote
                  canManageDailyNotes={canManageDailyNotes}
                  localDay={currentLocalDay}
                  dailyNote={dailyNote}
                  dailyNoteLoading={dailyNoteLoading}
                  onAddDailyNote={onAddDailyNote}
                  onUpdateDailyNote={onUpdateDailyNote}
                  workshopDepotId={selectedWorkshopDepotId}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

interface IActions {
  onShowToday: () => void;
  onShowNextTimeUnit: () => void;
  onShowPrevTimeUnit: () => void;
  onDepotChanged: (newValue: any) => void;
}

function getActionGroups(
  actions: IActions,
  currentDay: DateTime,
  workshopDepots: Array<WorkshopDepot>,
  selectedWorkshopDepotId: number
) {
  const alreadyOnToday =
    currentDay.toISODate() ===
    DateTime.utc()
      .setZone(TIMEZONE)
      .startOf('day')
      .toISODate();
  const prevAction: IButtonAction = {
    label: <ChevronLeftIcon size="sm" />,
    size: 'sm',
    onClick: actions.onShowPrevTimeUnit,
  };
  const todayAction: IButtonAction = {
    label: 'Go to Today',
    size: 'sm',
    onClick: actions.onShowToday,
    disabled: alreadyOnToday,
  };
  const nextAction: IButtonAction = {
    label: <ChevronRightIcon size="sm" />,
    size: 'sm',
    onClick: actions.onShowNextTimeUnit,
  };
  const dayViewLink: ILinkAction = {
    label: 'Daily Schedule',
    size: 'sm',
    outline: true,
    disabled: true,
    to: `/workshop/jobs/schedule`,
  };
  const weekViewLink: ILinkAction = {
    label: 'View Weekly Schedule',
    size: 'sm',
    to: `/workshop/jobs/weekly-schedule?week=${currentDay
      .set({ weekday: 1 })
      .toISOWeekDate()}&depotId=${selectedWorkshopDepotId}`,
  };
  const monthViewLink: ILinkAction = {
    label: 'View HV Machinery Schedule',
    size: 'sm',
    to: `/workshop/jobs/machinery-schedule?month=${currentDay.toFormat(
      'yyyy-MM'
    )}&depotId=${selectedWorkshopDepotId}`,
  };
  const workshopDepotName =
    workshopDepots.find(d => d.id === selectedWorkshopDepotId)?.description || '';
  const printDailyRunSheetLink: ICustomAction = {
    content: (
      <PrintDailyRunSheet
        date={currentDay}
        workshopDepotId={selectedWorkshopDepotId}
        workshopDepotName={workshopDepotName}
      />
    ),
  };
  const depotDropdown = getWorkshopDepotSelectAction(
    workshopDepots,
    selectedWorkshopDepotId,
    actions.onDepotChanged
  );
  return [
    [prevAction, todayAction, nextAction],
    [depotDropdown],
    [dayViewLink, weekViewLink, monthViewLink],
    [printDailyRunSheetLink],
  ];
}

export default DaySchedule;
