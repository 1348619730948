import { FieldType } from 'src/views/definitionBuilders/types/field';
import ListPage, { IListPageDef } from 'src/views/components/Page/pages/ListPage';
import { PagePrimarySize } from 'src/views/definitionBuilders/types';
import { observer } from 'mobx-react';
import { useRootStore } from 'src/domain/entities/RootStoreModel';
import { TaskCardItem } from 'src/views/components/TaskCard';
import withQueryParams, { IQueryParamsProps } from 'src/views/hocs/withQueryParams';
import { BasicSearch } from 'src/domain/baseTypes';
import { Link } from 'react-router-dom';
import { formatDateShort } from 'src/domain/dateHelper';
import PrimaryTitle from 'src/views/components/Page/PrimaryTitle/PrimaryTitle';

const ListCharterContracts: React.FC<IQueryParamsProps<BasicSearch>> = observer(props => {
  const rootStore = useRootStore();
  const model = rootStore.operations.charterContracts;

  const getPageDef = (): IListPageDef => {
    return {
      primaryTitle: <PrimaryTitle title="Charter Contracts"></PrimaryTitle>,
      primarySize: PagePrimarySize.full,
      onLoadData: model.list.listItems,
      createLink: TaskCardItem.operations.charterContracts.createCharterContract,
      externalSearch: true,
      hasMoreData: model.list.hasMoreItems,
      primaryFields: [
        {
          fieldType: FieldType.textField,
          orderBy: 'Name',
          dataAddr: 'name',
          label: 'Name',
          formatReadonly: d => (
            <Link to={`/operations/charter-contracts/${d.parentValue.id}`}>
              {d.parentValue.name}
            </Link>
          ),
        },
        {
          fieldType: FieldType.textField,
          dataAddr: 'description',
          label: 'Description',
        },
        {
          fieldType: FieldType.textField,
          dataAddr: ['customer', 'customerName'],
          label: 'Customer Name',
          linkTo: d => `/sales/customers/${d.parentValue.customer.customerId}`,
        },
        {
          fieldType: FieldType.textField,
          dataAddr: 'startDate',
          label: 'Start Date',
          formatReadonly: d => d.parentValue.startDate && formatDateShort(d.parentValue.startDate),
        },
        {
          fieldType: FieldType.textField,
          dataAddr: 'endDate',
          label: 'End Date',
          formatReadonly: d => d.parentValue.endDate && formatDateShort(d.parentValue.endDate),
        },
      ],
    };
  };

  return <ListPage data={model.list.items.slice()} def={getPageDef()} />;
});

export default withQueryParams(ListCharterContracts);
