import { types, getRoot, flow } from 'mobx-state-tree';
import { IRootStoreModel } from 'src/domain/entities/RootStoreModel';
import { getAjax } from 'src/domain/services';
import { IAutocompleteResult } from 'src/domain/baseTypes';
import * as queryString from 'query-string';

type CreateSupplierCommand = Workshop.Domain.Commands.Supplier.CreateSupplierCommand;
type UpdateSupplierCommand = Workshop.Domain.Commands.Supplier.UpdateSupplierCommand;
type CreateSupplierContactCommand = Workshop.Domain.Commands.Supplier.CreateSupplierContactCommand;
type UpdateSupplierContactCommand = Workshop.Domain.Commands.Supplier.UpdateSupplierContactCommand;
type DeleteSupplierContactCommand = Workshop.Domain.Commands.Supplier.DeleteSupplierContactCommand;
type CreateSupplierNoteCommand = Workshop.Domain.Commands.Supplier.CreateSupplierNoteCommand;
type UpdateSupplierNoteCommand = Workshop.Domain.Commands.Supplier.UpdateSupplierNoteCommand;
type DeleteSupplierNoteCommand = Workshop.Domain.Commands.Supplier.DeleteSupplierNoteCommand;
type ContactItem = Workshop.Domain.Queries.Suppliers.GetContactsForSupplier.ContactItem;
type SupplierListItem = Workshop.Domain.Queries.Suppliers.SupplierListItem;
type ListResult<T> = Common.Dtos.ListResult<T>;
type SupplierItem = Workshop.Domain.Queries.Suppliers.SupplierItem;
type PurchaseOrderListItem = Workshop.Domain.Queries.Purchasing.PurchaseOrderListItem;
type GetPurchaseOrderListQuery = Workshop.Domain.Queries.Purchasing.GetPurchaseOrderList.GetPurchaseOrderListQuery;

export const SupplierModel = types
  .model('SupplierModel', {
    supplier: types.maybe(types.frozen<SupplierItem>()),
    contactsForSupplier: types.array(types.frozen<ContactItem>()),
    purchaseOrdersForSupplier: types.array(types.frozen<PurchaseOrderListItem>()),
  })
  .views(self => ({}))
  .actions(self => {
    const ajax = getAjax(self);
    const root = getRoot(self) as IRootStoreModel;

    const createSupplier = flow(function*(command: CreateSupplierCommand) {
      const id = yield ajax.workshop.supplier.createSupplier(command);
      self.supplier = undefined;
      root.history.push(`/workshop/suppliers/${id}`);
    });

    const checkForUniqueName = flow(function*(name: string) {
      return yield ajax.workshop.supplier.checkForUniqueName(name);
    });

    const loadSupplier = flow(function*(supplierId: string) {
      self.supplier = yield ajax.workshop.supplier.getSupplier(supplierId);
    });

    const updateSupplier = flow(function*(command: UpdateSupplierCommand) {
      yield ajax.workshop.supplier.updateSupplier(command);
      yield loadSupplier(command.id);
    });

    let lastSearch: string | undefined;
    let lastResult: IAutocompleteResult<SupplierListItem>;

    const searchSuppliers = flow(function*(search: string) {
      if (search === lastSearch) {
        return lastResult;
      }
      const suppliers: ListResult<SupplierListItem> = yield ajax.workshop.supplier.searchSuppliers(
        search
      );
      lastSearch = search;
      lastResult = {
        options: suppliers.items,
      };
      return lastResult;
    });

    const findSuppliers = flow(function*(ids: string[]) {
      const suppliers: SupplierListItem[] = yield ajax.workshop.supplier.findSuppliers(ids);
      return { options: suppliers } as IAutocompleteResult<SupplierListItem>;
    });

    const createSupplierContact = flow(function*(command: CreateSupplierContactCommand) {
      yield ajax.workshop.supplier.createSupplierContact(command);
      loadSupplier(command.supplierId);
    });

    const updateSupplierContact = flow(function*(command: UpdateSupplierContactCommand) {
      yield ajax.workshop.supplier.updateSupplierContact(command);
      loadSupplier(command.supplierId);
    });

    const deleteSupplierContact = flow(function*(command: DeleteSupplierContactCommand) {
      yield ajax.workshop.supplier.deleteSupplierContact(command);
      loadSupplier(command.supplierId);
    });

    const listContactsForSupplier = flow(function*(supplierId: string) {
      if (!supplierId) {
        self.contactsForSupplier.replace([]);
      } else {
        const contacts: ContactItem[] = yield ajax.workshop.supplier.listContactsForSupplier(
          supplierId
        );
        self.contactsForSupplier.replace(contacts);
      }
    });

    const createSupplierNote = flow(function*(command: CreateSupplierNoteCommand) {
      yield ajax.workshop.supplier.createSupplierNote(command);
      loadSupplier(command.supplierId);
    });

    const updateSupplierNote = flow(function*(command: UpdateSupplierNoteCommand) {
      yield ajax.workshop.supplier.updateSupplierNote(command);
      loadSupplier(command.supplierId);
    });

    const deleteSupplierNote = flow(function*(command: DeleteSupplierNoteCommand) {
      yield ajax.workshop.supplier.deleteSupplierNote(command);
      loadSupplier(command.supplierId);
    });

    const listPurchaseOrdersForSupplier = flow(function*(
      query: Partial<GetPurchaseOrderListQuery>
    ) {
      self.purchaseOrdersForSupplier.clear();
      self.purchaseOrdersForSupplier = yield ajax.raw
        .get(`/api/workshop/purchase-orders?${queryString.stringify(query)}`)
        .toPromise()
        .then<PurchaseOrderListItem[]>(r => r.response.items);
    });

    return {
      createSupplier,
      checkForUniqueName,
      loadSupplier,
      updateSupplier,
      searchSuppliers,
      findSuppliers,
      createSupplierContact,
      updateSupplierContact,
      listContactsForSupplier,
      createSupplierNote,
      updateSupplierNote,
      listPurchaseOrdersForSupplier,
      deleteSupplierContact,
      deleteSupplierNote,
    };
  });
