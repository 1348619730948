import { types, flow } from 'mobx-state-tree';
import { getAjax } from 'src/domain/services/storeEnvironment';

type LicenceType = Common.AggregatesModel.People.StaffMemberAggregate.LicenceType;

export const LicencesModel = types
  .model('LicencesModel', {
    allLicenceTypes: types.array(types.frozen<LicenceType>()),
  })
  .actions(self => {
    const ajax = getAjax(self);

    const loadAllLicenceTypes = flow(function*() {
      self.allLicenceTypes = yield ajax.raw
        .get('/api/people/licence-types')
        .toPromise()
        .then<LicenceType[]>(r => r.response);
    });

    return {
      loadAllLicenceTypes,
    };
  });
