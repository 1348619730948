import { DateTime } from 'luxon';
import {
  AggregatesWithAttachments,
  LoadAttachmentsQuery,
} from 'src/views/components/Attachment/attachmentHelper';
import { getSubmitCloseModalActionGroupDef } from 'src/views/definitionBuilders/common';
import { FieldType, ModalDefBuilder, PaneType } from 'src/views/definitionBuilders/types';

export type UpdateAttachmentFormData = {
  attachmentDetailsId: number;
  aggregateId: string;
  aggregateType: AggregatesWithAttachments;
  fileName: string;
  attachmentExpiryDateTo: string | undefined;
  attachmentExpiryDateFrom: string | undefined;
  isAvailableOnDriversTablet: boolean;
};

export default function getUpdateAttachmentModalDef(
  attachmentData: UpdateAttachmentFormData,
  onSubmit: (data: UpdateAttachmentFormData) => Promise<void>,
  onSubmitSucceed: (query: LoadAttachmentsQuery) => Promise<void>,
  aggregateType: AggregatesWithAttachments
): ModalDefBuilder {
  return api => ({
    title: `Edit Attachment`,
    asForm: true,
    explicitData: attachmentData,
    panels: [
      {
        panes: [
          {
            paneType: PaneType.formFieldsPane,
            fields: [
              {
                fieldType: FieldType.textField,
                label: 'File name',
                mandatory: true,
                dataAddr: 'fileName',
                validate: fapi => {
                  if (fapi.fieldValue && !fapi.fieldValue.endsWith('.pdf')) {
                    return 'Please ensure the filename ends with .pdf';
                  }

                  if (
                    aggregateType &&
                    aggregateType !== 'supplier' &&
                    aggregateType !== 'asset' &&
                    fapi.fieldValue === attachmentData.fileName
                  ) {
                    return 'File name must be different from current name';
                  }

                  if (fapi.fieldValue && fapi.fieldValue.trim() === '.pdf') {
                    return '\'.pdf\' is not a valid file name';
                  }
                  return undefined;
                },
              },
              {
                fieldType: FieldType.dateField,
                label: 'Valid From',
                dataAddr: 'attachmentExpiryDateFrom',
                hidden: aggregateType !== 'supplier',
                onBlur: api => {
                  if (!api.formValues.attachmentExpiryDateTo) {
                    api.setFormValue(['attachmentExpiryDateTo'], api.fieldData.fieldValue);
                  }
                  api.validateField(['attachmentExpiryDateTo']);
                },
              },
              {
                fieldType: FieldType.dateField,
                label: 'Valid To',
                dataAddr: 'attachmentExpiryDateTo',
                hidden: aggregateType !== 'supplier',
                validate: d => {
                  if (!d.fieldValue || !d.parentValue.attachmentExpiryDateFrom) {
                    return undefined;
                  }
                  const from = DateTime.fromISO(d.parentValue.attachmentExpiryDateFrom);
                  const to = DateTime.fromISO(d.fieldValue);
                  return from > to ? 'Valid To cannot be earlier than Valid From' : undefined;
                },
              },
              {
                fieldType: FieldType.yesNoField,
                label: "Available on Driver's Tablet?",
                dataAddr: 'isAvailableOnDriversTablet',
                mandatory: true,
                hidden: aggregateType !== 'asset',
                tooltip:
                  "Specify if this attachment should appear for driver's to view in the tablet",
              },
            ],
          },
        ],
      },
    ],
    secondaryActions: [getSubmitCloseModalActionGroupDef('Ok')],
    onFormSubmit: (values: UpdateAttachmentFormData) => {
      return onSubmit({
        attachmentDetailsId: attachmentData.attachmentDetailsId,
        aggregateId: attachmentData.aggregateId,
        aggregateType: attachmentData.aggregateType,
        fileName: values.fileName,
        attachmentExpiryDateTo: values.attachmentExpiryDateTo,
        attachmentExpiryDateFrom: values.attachmentExpiryDateFrom,
        isAvailableOnDriversTablet: values.isAvailableOnDriversTablet,
      });
    },
    onSubmitSucceeded: () =>
      onSubmitSucceed({
        aggregateId: attachmentData.aggregateId,
        aggregateType: attachmentData.aggregateType,
      }),
  });
}
