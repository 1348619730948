import { DateTime } from 'luxon';
import './PrintRailInstructionsContent.scss';
import { DateTimeFormat } from 'src/views/components/DateTimeFormat';
import RouteGroups from 'src/views/components/operations/RouteGroups/RouteGroups';
import DraftWatermark from 'src/views/components/operations/DraftWatermark/DraftWatermark';
import CompanyLogo from 'src/views/components/CompanyLogo/CompanyLogo';
import { DateFormat } from 'src/views/components/DateFormat';

export interface IPrintRailInstructionsContentProps {
  railInstructions:
    | Operations.Domain.Queries.ListRailInstructionsForDriver.RailInstructions
    | undefined;
}

function showWatermark(job: Operations.Domain.Queries.ListRailInstructionsForDriver.RailJob) {
  const now = DateTime.local().startOf('day');
  const clockOn = DateTime.fromISO(job.clockOn).startOf('day');
  return !job.hasSubcontractor && clockOn > now;
}

const PrintRailInstructionsContent: React.FC<IPrintRailInstructionsContentProps> = ({
  railInstructions,
}) => {
  return (
    <div className="print-rail-instructions-content-component">
      {railInstructions && railInstructions.jobs.length > 0 ? (
        railInstructions.jobs.map(job => {
          return (
            <div className="job" key={job.id}>
              <DraftWatermark shouldBeVisible={showWatermark(job)} className="watermark" />
              <table>
                <tbody>
                  <tr>
                    <td>
                      <CompanyLogo mode="full" />
                    </td>
                    <td className="text-right">
                      <h2>Rail Instructions</h2>
                      <h2>{job.shiftName}</h2>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div className="job-info">
                <table>
                  <tbody>
                    <tr>
                      <td>
                        {job.hasSubcontractor ? (
                          <>
                            <strong>Subcontractor:</strong> {job.subcontractorName}
                          </>
                        ) : (
                          <>
                            <strong>Driver:</strong> {job.staffMemberName}
                          </>
                        )}
                      </td>
                      <td>
                        <strong>Clock On:</strong>{' '}
                        {job.hasSubcontractor ? (
                          <DateFormat value={job.clockOn} />
                        ) : (
                          <DateTimeFormat value={job.clockOn} />
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        {job.hasSubcontractor ? null : (
                          <>
                            <strong>Vehicle:</strong> {job.assetName}
                          </>
                        )}
                      </td>
                      <td>
                        {job.hasSubcontractor ? null : (
                          <>
                            <strong>Depart Depot:</strong>{' '}
                            <DateTimeFormat value={job.departDepot} previousValue={job.clockOn} />
                          </>
                        )}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="route-groups">
                <RouteGroups
                  routeGroups={job.routeGroups}
                  printing
                  hasSubcontractor={job.hasSubcontractor}
                />
              </div>
              {job.notes ? <div className="notes">{job.notes}</div> : null}
              <div className="printed">
                Printed {DateTime.local().toLocaleString(DateTime.DATETIME_MED)}
              </div>
            </div>
          );
        })
      ) : (
        <h1>No Jobs To Export</h1>
      )}
    </div>
  );
};

export default PrintRailInstructionsContent;
