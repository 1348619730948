import {
  FieldType,
  IFieldData,
  ModalDefBuilder,
  PaneType,
} from 'src/views/definitionBuilders/types';
import { IFile } from 'src/views/components/Page/fields/MultiFilePageField';
import { AddAttachmentData } from 'src/domain/entities/people/attachment/PeopleAttachmentModel';
import {
  AggregatesWithAttachments,
  LoadAttachmentsQuery,
  maxFileSizeInMegaBytes,
} from 'src/views/components/Attachment/attachmentHelper';
import { getSubmitCloseModalActionGroupDef } from 'src/views/definitionBuilders/common';

type AddAttachmentFormData = {
  file: IFile;
  fileName: string;
  aggregateType: string;
};
const maxSizeInBytes = maxFileSizeInMegaBytes * 1024 * 1024;

export default function getAddAttachmentModalDef(
  aggregateId: string,
  aggregateType: AggregatesWithAttachments,
  onSubmit: (data: AddAttachmentData) => Promise<void>,
  onSubmitSucceed: (query: LoadAttachmentsQuery) => Promise<void>
): ModalDefBuilder {
  return _ => ({
    title: 'Add Attachment',
    asForm: true,
    panels: [
      {
        panes: [
          {
            paneType: PaneType.formFieldsPane,
            fields: [
              {
                fieldType: FieldType.fileField,
                label: 'Attachment',
                mandatory: true,
                dataAddr: 'file',
                validate: fieldData => {
                  const fieldDataiTyping = fieldData as IFieldData<IFile>;
                  if (fieldDataiTyping.fieldValue && fieldDataiTyping.fieldValue.file) {
                    if (fieldDataiTyping.fieldValue?.file?.type !== 'application/pdf') {
                      return 'Invalid file type. Please select a pdf';
                    }
                    if (fieldDataiTyping.fieldValue?.file?.size > maxSizeInBytes) {
                      return `${(fieldDataiTyping.fieldValue.file.size / 1024 / 1024).toFixed(
                        2
                      )}MB attachment too large. Attachments must be under 20MB`;
                    }
                  }

                  return undefined;
                },
                onChange: data => {
                  data.setFormValue(
                    ['fileName'],
                    (data.formValues.file && data.formValues.file.name) || ''
                  );
                },
              },
              {
                fieldType: FieldType.textField,
                label: 'File name',
                mandatory: true,
                dataAddr: 'fileName',
                hidden: data => !data.parentValue.file,
                validate: data => {
                  if (data.fieldValue && !data.fieldValue.endsWith('.pdf')) {
                    return 'Please ensure the filename ends with .pdf';
                  }
                  if (data.fieldValue && data.fieldValue.trim() === '.pdf') {
                    return '\'.pdf\' is not a valid file name';
                  }
                  return undefined;
                },
              },
            ],
          },
        ],
      },
    ],
    secondaryActions: [getSubmitCloseModalActionGroupDef('Ok')],
    onFormSubmit: (values: AddAttachmentFormData) => {
      return onSubmit({
        aggregateId: aggregateId,
        aggregateType: aggregateType,
        fileName: values.fileName,
        file: values.file,
      });
    },
    onSubmitSucceeded: () => onSubmitSucceed({ aggregateId, aggregateType }),
  });
}
