import Page from 'src/views/components/Page/Page';
import { IPageDef, PagePrimarySize } from 'src/views/definitionBuilders/types';

const getPageDef = () => {
  return {
    primarySection: {
      title: 'Todo',
      panels: [],
    },
    primarySize: PagePrimarySize.full,
    secondarySections: [],
  } as IPageDef;
};

const Todo: React.FC = () => <Page className="documentation-todo" def={getPageDef()} />;

export default Todo;
