import getExtraPaneDef from 'src/views/routes/operations/shared/getExtraPaneDef';
import { IPanelDef } from '../../../../../../views/definitionBuilders/types/panel';
import { ShiftType } from 'src/api/enums';

type ExtraType = Operations.Domain.Queries.ViewExtraType.ExtraTypeItem;

export default function getExtraPanel(
  extraTypes: ExtraType[],
  editable: boolean,
  aggregateType: 'quote' | 'shift'
): IPanelDef {
  const panel: IPanelDef = {
    hidden: data => {
      return !(
        data.panelValue.shiftType && data.panelValue.shiftType.id === ShiftType.ContractCharter
      );
    },
    panes: [getExtraPaneDef(extraTypes, false, _ => false, editable, aggregateType)],
  };

  return panel;
}
