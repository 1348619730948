import { DateTime } from 'luxon';
import memoizeOne from 'memoize-one';
import { groupByFlat } from 'src/infrastructure/arrayUtils';
import {
  IGanttView,
  IGanttCalendarGroupItem,
  IGanttCalendarDayItem,
  ISizedGroup,
  IPlacedItem,
} from '../baseTypes';
import { IViewGroupFilterValues } from '../GanttCalendar';

type PlacementManagerComponent<T> = {
  props: {
    filterItemsForUngroupedView?: (item: T, index: number) => boolean;
    ganttViews?: { [key: string]: IGanttView<T, IGanttCalendarGroupItem> };
    getItemUiKey: (item: T) => string;
  };
  getView: () => string | undefined;
  getRelevantItems: () => Array<T>;
};

export default class PlacementManager<T extends IGanttCalendarDayItem> {
  private readonly _component: PlacementManagerComponent<T>;

  constructor(component: PlacementManagerComponent<T>) {
    this._component = component;
  }

  readonly getGanttView = () => {
    const { ganttViews } = this._component.props;
    const view = this._component.getView();
    return view && ganttViews ? ganttViews[view] : undefined;
  };

  /** Determine how many rows each group needs, and the row for each item */
  private readonly getPlacedGroups = memoizeOne(
    (
      items: Array<T>,
      filterItemsForUngroupedView: ((item: T, index: number) => boolean) | undefined,
      ganttView: IGanttView<T, IGanttCalendarGroupItem> | undefined
    ) => {
      const groups = ganttView ? ganttView.groups : [{ id: undefined }];
      const grouper = ganttView
        ? (i: T) => ganttView.getItemGroup(i, ganttView.groups)
        : () => [undefined];

      const filter = ganttView ? ganttView.filterItemsForView : filterItemsForUngroupedView;
      const filteredItems = filter ? items.filter(filter) : items;

      // Use the grouper to group the items
      const groupsWithItems = Array.from(groupByFlat(filteredItems, grouper).entries()).map(
        ([group, groupedItems]) => {
          const lastPlacedItemsByRow = [] as T[];
          const placedItems = groupedItems
            .sort((a, b) => a.bounds.start.valueOf() - b.bounds.start.valueOf()) // Sort by the start time
            .map(item => {
              // Find the first row where this item does not overlap with the last placed one
              const idx = lastPlacedItemsByRow.findIndex(i => !item.bounds.overlaps(i.bounds));
              // If we overlap with all items, then we're adding a new row
              const rowIndex = idx === -1 ? lastPlacedItemsByRow.length : idx;
              lastPlacedItemsByRow[rowIndex] = item;
              return Object.assign({}, item, { rowIndex, groupId: group && group.id });
            });

          const rowsUsed = lastPlacedItemsByRow.length;
          const placedGroup = group ? { ...group, rowsUsed } : { id: undefined, rowsUsed };

          return { placedGroup, placedItems };
        }
      );

      // Create an item for every group (we want an empty row if a group has no items)
      return groups.map(
        g =>
          groupsWithItems.find(x => x.placedGroup.id === g.id) || {
            placedGroup: { ...g, rowsUsed: 1 },
            placedItems: [],
          }
      );
    }
  );

  /** Find the group ids that match the current search and filters for the view */
  private readonly getMatchingGroupIds = memoizeOne(
    (filtering: IViewGroupFilterValues, view: IGanttView<T, IGanttCalendarGroupItem>) => {
      const searched = filtering.search ? view.search(filtering.search, view.groups) : view.groups;
      const filtered = view.applyFilters
        ? view.applyFilters(filtering.filters, searched)
        : searched;
      return filtered.map(g => g.id);
    }
  );

  readonly getSizedGroups = (
    filtering: IViewGroupFilterValues | undefined
  ): Array<IGanttCalendarGroupItem & ISizedGroup> => {
    const { filterItemsForUngroupedView } = this._component.props;
    const items = this._component.getRelevantItems();
    const ganttView = this.getGanttView();
    const matchingGroupIds =
      ganttView && this.getMatchingGroupIds(filtering || { search: '', filters: {} }, ganttView);
    return this.getPlacedGroups(items, filterItemsForUngroupedView, ganttView)
      .map(g => g.placedGroup)
      .filter(g => (matchingGroupIds ? matchingGroupIds.some(id => id === g.id) : true))
      .reduce(
        (acc, g) => {
          acc.groups.push({ ...g, rowIndex: acc.offset });
          acc.offset += g.rowsUsed;
          return acc;
        },
        { offset: 0, groups: [] as Array<IGanttCalendarGroupItem & ISizedGroup> }
      ).groups;
  };

  // This assigns items to rows - perhaps it can duplicate the item when it's needed in two places?
  // The grouper can currenly only return one group for an item - maybe it needs to be able to return an array?
  readonly getPlacedItemsByDay = (
    day: DateTime,
    includePriorItems: boolean,
    filtering: IViewGroupFilterValues | undefined
  ): Array<T & IPlacedItem> => {
    const { filterItemsForUngroupedView, getItemUiKey } = this._component.props;
    const items = this._component.getRelevantItems();
    const ganttView = this.getGanttView();
    const matchingGroupIds =
      ganttView && this.getMatchingGroupIds(filtering || { search: '', filters: {} }, ganttView);
    return this.getPlacedGroups(items, filterItemsForUngroupedView, ganttView)
      .filter(g => (matchingGroupIds ? matchingGroupIds.some(id => id === g.placedGroup.id) : true))
      .reduce(
        (acc, g) => {
          g.placedItems
            .filter(i =>
              includePriorItems
                ? i.bounds.start.startOf('day') <= day
                : i.bounds.start.hasSame(day, 'day')
            )
            .map(i =>
              Object.assign({}, i, {
                rowIndex: i.rowIndex + acc.offset,
                placementKey: `${getItemUiKey(i)}:${i.groupId || 'ungrouped'}`,
              } as IPlacedItem)
            )
            .forEach(i => acc.result.push(i));

          acc.offset += g.placedGroup.rowsUsed;
          return acc;
        },
        { offset: 0, result: [] as Array<T & IPlacedItem> }
      ).result;
  };
}
