import { DateTime } from 'luxon';
import { useEffect, useState } from 'react';
import { Button } from 'reactstrap';
import FormGroup from 'reactstrap/lib/FormGroup';
import { allDataFuelStatus, allUploadStatus, DataFuelStatus } from 'src/api/enums';
import { ListPageLoadCause } from 'src/domain';
import { IListPageLoadDataRequest } from 'src/domain/baseTypes';
import { EditIcon, ListIcon, ToolsIcon } from 'src/images/icons';
import ListPage, { IListPageDef } from 'src/views/components/Page/pages/ListPage';
import Spinner from 'src/views/components/Spinner';
import { getSubmitCloseModalActionGroupDef } from 'src/views/definitionBuilders/common';
import {
  ActionType,
  PagePrimarySize,
  PaneType,
  ShellModalSize,
} from 'src/views/definitionBuilders/types';
import { FieldDefs, FieldType } from 'src/views/definitionBuilders/types/field';
import withQueryParams, { IQueryParamsProps } from 'src/views/hocs/withQueryParams';
import PrimaryTitle from 'src/views/components/Page/PrimaryTitle/PrimaryTitle';

type ListDataFuelQuery = Workshop.Domain.Queries.DataFuel.ListDataFuel.ListDataFuelQuery;
type ListDataFuelUploadQuery = Workshop.Domain.Queries.DataFuel.ListDataFuelUpload.ListDataFuelUploadQuery;
type DataFuelListItem = Workshop.Domain.Queries.DataFuel.ListDataFuel.DataFuelListItem;
type DataFuelUploadListItem = Workshop.Domain.Queries.DataFuel.ListDataFuelUpload.DataFuelUploadListItem;
type AssetItem = Workshop.Domain.Queries.AssetItem;
type StaffMemberDto = Common.Dtos.StaffMemberDto;
type UpdateDataFuelEntryCommand = Workshop.Domain.Commands.DataFuel.UpdateDataFuelEntry.UpdateDataFuelEntryCommand;
type DataFuelIgnoredAssetDto = Workshop.Domain.Queries.DataFuel.IgnoredDataFuel.DataFuelIgnoredAssetDto;
type UpdateDataFuelAssetsIgnoredCommand = Workshop.Domain.Commands.DataFuel.IgnoredAssets.UpdateDataFuelAssetsIgnoredCommand;

export interface IListDataFuelEntriesProps {
  listDataFuelEntries: (request: IListPageLoadDataRequest<ListDataFuelQuery>) => Promise<void>;
  dataFuelEntries: DataFuelListItem[];
  hasMoreData: boolean;
  uploadedFiles: DataFuelUploadListItem[];
  loadUploadedFiles: (query: IListPageLoadDataRequest<ListDataFuelUploadQuery>) => Promise<void>;
  uploadFile: (file: string | Blob) => Promise<void>;
  updateEntry: (command: UpdateDataFuelEntryCommand) => Promise<void>;
  assets: Array<AssetItem>;
  loadAssets: () => Promise<void>;
  staffMembers: StaffMemberDto[];
  loadAllStaffMembers: () => Promise<void>;
  ignoredAssets: DataFuelIgnoredAssetDto[] | undefined;
  loadIgnoredAssets: () => Promise<void>;
  updateIgnoredAssets: (query: UpdateDataFuelAssetsIgnoredCommand) => Promise<void>;
  canManageWorkshop: boolean;
}

type InternalProps = IListDataFuelEntriesProps & IQueryParamsProps<ListDataFuelQuery>;

const ListDataFuelEntries: React.FC<InternalProps> = (props: InternalProps) => {
  const [file, setFile] = useState<File | undefined>(undefined);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isEditingIgnoredAssets, setIsEditingIgnoredAssets] = useState<boolean>(false);

  useEffect(() => {
    Promise.all([
      props.loadAllStaffMembers(),
      props.loadUploadedFiles({ loadCause: ListPageLoadCause.mount }),
      props.loadAssets(),
      props.loadIgnoredAssets(),
    ]);
  }, []);

  const submit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (file) {
      setIsLoading(true);

      props
        .uploadFile(file)
        .then(r => {
          props.loadUploadedFiles({
            loadCause: ListPageLoadCause.refresh,
          });
        })
        .then(r => {
          props.listDataFuelEntries({
            query: { ...props.getQueryParams() },
            loadCause: ListPageLoadCause.refresh,
          });
        })
        .then(_ => {
          setFile(undefined);
          setIsLoading(false);
        });
    }
  };

  const getFilterFieldDefs = () => {
    return {
      dateFrom: {
        fieldType: FieldType.dateField,
        label: 'Date From',
        dataAddr: 'dateFrom',
        onBlur: api => {
          api.validateField(['dateTo']);
        },
      } as FieldDefs,
      dateTo: {
        fieldType: FieldType.dateField,
        label: 'Date To',
        dataAddr: 'dateTo',
        validate: d => {
          if (!d.fieldValue || !d.parentValue.dateFrom) {
            return undefined;
          }
          const from = DateTime.fromISO(d.parentValue.dateFrom);
          const to = DateTime.fromISO(d.fieldValue);
          return from > to ? 'Date To cannot be earlier than Date From' : undefined;
        },
      } as FieldDefs,
      status: {
        fieldType: FieldType.selectMultiField,
        dataAddr: 'statusIds',
        label: 'Status',
        valueKey: 'value',
        descriptionKey: 'description',
        optionItems: allDataFuelStatus,
        useValueOnly: true,
      } as FieldDefs,
      assets: {
        fieldType: FieldType.selectMultiField,
        label: 'Asset',
        dataAddr: 'assets',
        useValueOnly: true,
        valueKey: 'id',
        descriptionKey: 'name',
        optionItems: props.assets,
      } as FieldDefs,
      staffMembers: {
        fieldType: FieldType.selectMultiField,
        label: 'Staff Members',
        dataAddr: 'staffMembers',
        useValueOnly: true,
        valueKey: 'id',
        descriptionKey: 'name',
        optionItems: props.staffMembers,
      } as FieldDefs,
    };
  };

  const getPageDef = (): IListPageDef => {
    const filterFieldDefsLookup = getFilterFieldDefs();
    const today = DateTime.local().toISODate();
    const { uploadedFiles, ignoredAssets } = props;

    return {
      primaryTitle: (
        <PrimaryTitle
          title="Data Fuel Entries"
          link="https://www.notion.so/central-ops/Set-up-Guide-for-Datafuel-e46f28d0903e442a95dfd29d481655b8"></PrimaryTitle>
      ),
      onLoadData: props.listDataFuelEntries,
      externalSearch: true,
      hasMoreData: props.hasMoreData,
      primarySize: PagePrimarySize.twoThirds,
      rowKey: d => d.itemValue.id,
      filterAction: {
        defaultValues: {
          dateFrom: today,
        },
        filterSize: ShellModalSize.oneQuarter,
        filterFields: Object.keys(filterFieldDefsLookup).map(k => filterFieldDefsLookup[k]),
        filterDef: filterDefApi => [
          {
            panes: [
              {
                paneType: PaneType.formFieldsPane,
                columnCount: 2,
                fields: [filterFieldDefsLookup.dateFrom, filterFieldDefsLookup.dateTo],
              },
              {
                paneType: PaneType.formFieldsPane,
                columnCount: 1,
                fields: [filterFieldDefsLookup.status],
              },
              {
                paneType: PaneType.formFieldsPane,
                columnCount: 1,
                fields: [filterFieldDefsLookup.assets],
              },
              {
                paneType: PaneType.formFieldsPane,
                columnCount: 1,
                fields: [filterFieldDefsLookup.staffMembers],
              },
            ],
          },
        ],
      },
      primaryActions: [],
      primaryFields: [
        {
          fieldType: FieldType.textField,
          dataAddr: 'fleetNumber',
          label: 'Fleet Number',
          orderBy: 'entry.FleetNumber',
        },
        {
          fieldType: FieldType.selectField,
          label: 'Linked Asset',
          dataAddr: 'assetId',
          columnWidth: '10em',
          valueKey: 'id',
          descriptionKey: 'name',
          useValueOnly: true,
          optionItems: props.assets,
        },
        {
          fieldType: FieldType.textField,
          dataAddr: 'employeeId',
          label: 'Employee Id',
          orderBy: 'entry.EmployeeId',
        },
        {
          fieldType: FieldType.selectField,
          label: 'Linked Staff',
          dataAddr: 'staffMemberId',
          columnWidth: '10em',
          valueKey: 'id',
          descriptionKey: 'name',
          useValueOnly: true,
          optionItems: props.staffMembers,
        },
        {
          fieldType: FieldType.numericField,
          dataAddr: 'diesel',
          label: 'Diesel',
        },
        {
          fieldType: FieldType.numericField,
          dataAddr: 'adblue',
          label: 'Adblue',
        },
        {
          fieldType: FieldType.dateTimeField,
          dataAddr: 'occurredAt',
          label: 'Occurred At',
          orderBy: 'entry.OccurredAt',
        },
        {
          fieldType: FieldType.selectField,
          dataAddr: 'status',
          label: 'Status',
          valueKey: 'value',
          descriptionKey: 'description',
          optionItems: allDataFuelStatus,
          useValueOnly: true,
          orderBy: 'entry.Status',
        },
        {
          fieldType: FieldType.actionListField,
          columnWidth: '1px',
          nowrap: true,
          actionGroups: [
            {
              actions: [
                {
                  actionType: ActionType.modalActionButton,
                  modalSize: ShellModalSize.oneThird,
                  label: 'Fix',
                  icon: <ToolsIcon fixedWidth />,
                  hidden: d => d.parentValue.status === DataFuelStatus.Inserted,
                  modalDef: d => ({
                    title: 'Fix Data Fuel Entry',
                    asForm: true,
                    panels: [
                      {
                        panes: [
                          {
                            paneType: PaneType.formFieldsPane,
                            columnCount: 1,
                            fields: [
                              {
                                fieldType: FieldType.yesNoField,

                                mandatory: true,
                                dataAddr: 'ignore',
                                label: 'Ignore Entry',
                              },
                              {
                                fieldType: FieldType.readonlyField,
                                dataAddr: 'uploadId',
                                label: 'Upload Id',
                                hidden: true,
                              },
                              {
                                fieldType: FieldType.readonlyField,
                                dataAddr: 'id',
                                label: 'Id',
                                hidden: true,
                              },
                              {
                                fieldType: FieldType.readonlyField,
                                dataAddr: 'fleetNumber',
                                label: 'Fleet Number',
                              },
                              {
                                fieldType: FieldType.selectField,
                                label: 'Linked Asset',
                                dataAddr: 'assetId',
                                useValueOnly: true,
                                valueKey: 'id',
                                descriptionKey: 'name',
                                optionItems: props.assets,
                                mandatory: t => !t.parentValue.ignore,
                                hidden: t => t.parentValue.ignore,
                              },
                              {
                                fieldType: FieldType.readonlyField,
                                dataAddr: 'employeeId',
                                label: 'Employee Id',
                              },
                              {
                                fieldType: FieldType.selectField,
                                label: 'Linked Staff',
                                dataAddr: 'staffMemberId',
                                valueKey: 'id',
                                columnWidth: '10em',
                                descriptionKey: 'name',
                                useValueOnly: true,
                                optionItems: props.staffMembers,
                                mandatory: t => !t.parentValue.ignore,
                                hidden: t => t.parentValue.ignore,
                              },
                            ],
                          },
                        ],
                      },
                    ],
                    secondaryActions: [getSubmitCloseModalActionGroupDef('Submit')],
                    onFormSubmit: v => {
                      return props.updateEntry(v).then(r => {
                        props.listDataFuelEntries({
                          query: { ...props.getQueryParams() },
                          loadCause: ListPageLoadCause.refresh,
                        });
                      });
                    },
                  }),
                },
              ],
            },
          ],
        },
      ],
      secondarySections: [
        {
          title: 'Upload Files',
          isLogicalSubsection: false,

          explicitData: { uploadedFiles },
          panels: [
            {
              title: 'Upload new File',
              panes: [
                {
                  paneType: PaneType.customPane,
                  render: api => {
                    return (
                      <form onSubmit={e => submit(e)}>
                        <FormGroup>
                          {file ? (
                            <div>{file.name}</div>
                          ) : (
                            <input
                              type="file"
                              accept={'.csv'}
                              onChange={e => setFile(e.target.files![0])}
                            />
                          )}
                        </FormGroup>
                        <FormGroup>
                          <Spinner show={isLoading}>
                            {file && !isLoading && <Button type="submit">Upload</Button>}
                          </Spinner>
                        </FormGroup>
                      </form>
                    );
                  },
                },
              ],
            },
            {
              title: 'Uploaded Files',
              panes: [
                {
                  paneType: PaneType.tablePane,
                  neverEditable: true,
                  dataAddr: 'uploadedFiles',
                  fields: [
                    {
                      fieldType: FieldType.textField,
                      dataAddr: 'fileName',
                      label: 'File',
                    },
                    {
                      fieldType: FieldType.textField,
                      dataAddr: 'loadedBy',
                      label: 'Loaded By',
                    },
                    {
                      fieldType: FieldType.dateTimeField,
                      dataAddr: 'loadedAt',
                      label: 'Loaded At',
                    },
                    {
                      fieldType: FieldType.selectField,
                      dataAddr: 'status',
                      label: 'Status',
                      valueKey: 'value',
                      descriptionKey: 'description',
                      optionItems: allUploadStatus,
                      useValueOnly: true,
                    },
                  ],
                },
                {
                  paneType: PaneType.actionListPane,
                  actionGroups: [
                    {
                      actions: [
                        {
                          actionType: ActionType.actionLink,
                          label: 'All Uploads',
                          icon: <ListIcon />,
                          to: `/workshop/assets/datafuel/uploads`,
                          hidden: !uploadedFiles || !uploadedFiles.length,
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          title: 'Ignored Assets',
          explicitData: { ignoredAssets: ignoredAssets || [] },
          asForm: true,
          onFormSubmit: q => {
            setIsEditingIgnoredAssets(false);

            return props.updateIgnoredAssets(q).then(x => props.loadIgnoredAssets());
          },
          secondaryActions:
            props.canManageWorkshop && isEditingIgnoredAssets
              ? [
                  {
                    actions: [
                      {
                        actionType: ActionType.submitActionButton,
                        level: 'primary',
                      },
                      {
                        actionType: ActionType.resetActionButton,
                        onResetAction: () => setIsEditingIgnoredAssets(false),
                      },
                    ],
                  },
                ]
              : [],
          panels: [
            {
              dataAddr: 'ignoredAssets',
              panes: [
                {
                  paneType: PaneType.tablePane,
                  deleteRemovedItems: true,
                  dataRequiredForRows: 'panelValue',
                  neverEditable: !props.canManageWorkshop,
                  fields: [
                    {
                      fieldType: FieldType.textField,
                      label: 'Description',
                      dataAddr: 'description',
                      mandatory: true,
                      readonly: !props.canManageWorkshop || !isEditingIgnoredAssets,
                      validate: d => {
                        if (!d.panelValue) {
                          return undefined;
                        }
                        const ignoredAssets = d.panelValue as DataFuelIgnoredAssetDto[];
                        return ignoredAssets.filter(
                          x => x.description?.toUpperCase() === d.fieldValue?.toUpperCase()
                        ).length > 1
                          ? 'This value already exists'
                          : undefined;
                      },
                    },
                    {
                      fieldType: FieldType.actionListField,
                      dataAddr: '',
                      columnWidth: '1px',
                      hidden: !props.canManageWorkshop || !isEditingIgnoredAssets,
                      actionGroups: [
                        {
                          actions: [
                            {
                              actionType: ActionType.removeArrayItemActionButton,
                              label: 'Remove Line',
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
                {
                  paneType: PaneType.actionListPane,
                  hidden: !props.canManageWorkshop || isEditingIgnoredAssets,
                  actionGroups: [
                    {
                      actions: [
                        {
                          actionType: ActionType.actionButton,
                          label: 'Edit Ignored Assets',
                          icon: <EditIcon />,
                          onClick: d => setIsEditingIgnoredAssets(true),
                        },
                      ],
                    },
                  ],
                },
                {
                  paneType: PaneType.actionListPane,
                  hidden: !props.canManageWorkshop || !isEditingIgnoredAssets,
                  actionGroups: [
                    {
                      actions: [
                        {
                          actionType: ActionType.addArrayItemActionButton,
                          label: 'Add',
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    };
  };

  return (
    <ListPage data={props.dataFuelEntries.map(e => ({ ...e, ignore: false }))} def={getPageDef()} />
  );
};

export default withQueryParams(ListDataFuelEntries);
