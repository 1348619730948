import { DateTime } from 'luxon';

export interface IDateFormatProps {
  value?: DateTime | string;
  short?: boolean;
  timezone?: string;
}

const DateFormat: React.FC<IDateFormatProps> = ({ value, short, timezone }) => {
  if (value === null || value === undefined) {
    return null;
  }

  if (typeof value === 'string') {
    value = DateTime.fromISO(value, { zone: timezone || 'local' });
  }

  return <span>{value.toLocaleString(short ? DateTime.DATE_SHORT : DateTime.DATE_MED)}</span>;
};

export default DateFormat;
