import styles from './CheckField.module.scss';

export const CheckField: React.FC<{ label: string }> = ({ label }) => (
  <>
    <td className={styles.alignRight}>{label}</td>
    <td className={styles.alignRight}>
      <span className={styles.checkbox} />
    </td>
  </>
);
