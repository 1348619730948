import { useState } from 'react';
import Textarea from '../../components/presentational/Form/Textarea/Textarea';
import { FormattedDateTime } from '../../components/presentational/FormattedDateTime/FormattedDateTime';
import { SingleClickAwareButton } from '../../components/presentational/SingleClickAwareButton/SingleClickAwareButton';
import styles from './Home.module.scss';

type KioskNoteDto = Workshop.Domain.Queries.GetKioskNote.KioskNoteDto;
type CreateOrUpdateKioskNoteCommand = Workshop.Domain.Commands.KioskNote.CreateOrUpdateKioskNoteCommand;

export const KioskNote: React.FC<{
  kioskNote: KioskNoteDto;
  createOrUpdateKioskNote: (command: CreateOrUpdateKioskNoteCommand) => Promise<void>;
  loadKioskNote: (depotId: number) => Promise<void>;
  deviceDepotId: number;
}> = ({ kioskNote, createOrUpdateKioskNote, loadKioskNote, deviceDepotId }) => {
  const [note, setNote] = useState(kioskNote.note || '');

  return (
    <div className={styles.mechanicNote}>
      <div>
        <span>
          Mechanic Note{' '}
          {kioskNote.note && (
            <span>
              by{' '}
              <strong>
                {kioskNote.author} @ <FormattedDateTime dateTime={kioskNote.authoredOn!} />
              </strong>
            </span>
          )}
        </span>
      </div>

      <div className={styles.kioskNoteArea}>
        <Textarea
          inputClassName={styles.kioskNoteInput}
          errors=""
          dataAddr="KioskNote"
          label=""
          value={note}
          onChange={e => setNote(e)}
        />
        <SingleClickAwareButton
          useReactStrapButton
          className={styles.updateNoteButton}
          onClick={() =>
            createOrUpdateKioskNote({
              id: kioskNote && kioskNote.id,
              note: note,
              depotId: deviceDepotId,
            }).then(() => loadKioskNote(deviceDepotId))
          }>
          Save Note
        </SingleClickAwareButton>
      </div>
    </div>
  );
};
