import deepEqual from 'src/infrastructure/deepEqual';
import { ISchemaRecord } from 'src/views/components/AuditHistory/ISchemaRecord';
import { isEmpty } from 'src/views/components/AuditHistory/AuditHistoryHelpers';
import { Component } from 'react';

export interface IAuditHistoryFieldProps {
  schema: ISchemaRecord;
  // tslint:disable-next-line:no-any
  value: any;
  // tslint:disable-next-line:no-any
  changes: any;
  // tslint:disable-next-line:no-any
  address: any[];
  valueType: 'before' | 'after';
}

interface IAuditHistoryFieldState {
  field: React.ReactNode;
}

export const noValue = <i>(no value)</i>;
class AuditHistoryField extends Component<IAuditHistoryFieldProps, IAuditHistoryFieldState> {
  constructor(props: IAuditHistoryFieldProps) {
    super(props);
    this.state = {
      field: null,
    };
  }

  async componentDidMount() {
    const { value, schema } = this.props;
    await this.renderChanges(value, schema);
  }

  async componentDidUpdate(prevProps: IAuditHistoryFieldProps) {
    if (!deepEqual(prevProps, this.props)) {
      const { value, schema } = this.props;
      await this.renderChanges(value, schema);
    }
  }

  // tslint:disable-next-line:no-any
  async formatValue() {
    const { value, schema, changes, address, valueType } = this.props;
    if (isEmpty(value)) {
      return noValue;
    }

    return schema.format
      ? await schema.format(value, changes, address, valueType)
      : Promise.resolve(value);
  }

  // tslint:disable-next-line:no-any
  async renderChanges(value: any, schema: ISchemaRecord) {
    const formattedValue = await this.formatValue();
    this.setState({ field: formattedValue });
  }

  render() {
    const { field } = this.state;
    return field;
  }
}

export default AuditHistoryField;
