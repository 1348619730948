import inject from 'src/views/injectFromStore';
import ServicesDueByDate, { IServicesDueByDateProps } from './ServicesDueByDate';

const Container = inject<IServicesDueByDateProps>((allStores, p) => ({
  canManageAssets: allStores.rootStore.account.isWorkshopDepartmentMember,
  getServicesDue: allStores.rootStore.assets.listItems,
  servicesDueByDate: allStores.rootStore.assets.servicesDueByDate.slice(),
  loadAssetCategories: allStores.rootStore.asset.loadAssetCategories,
  loadSubcategories: allStores.rootStore.asset.loadAssetSubcategories,
  loadAssetHousedAtLocations: allStores.rootStore.asset.loadAssetHousedAtLocations,
  assetCategories: allStores.rootStore.asset.categories.slice(),
  assetSubcategories: allStores.rootStore.asset.subcategories.slice(),
  assetHousingLocations: allStores.rootStore.asset.housedAtLocations.slice(),
  onSchedulePreventativeMaintenanceService:
    allStores.rootStore.asset.schedulePreventativeMaintenanceService,
  workshopDepots: allStores.rootStore.workshopStartup.workshopDepots.slice(),
  defaultWorkshopDepot: allStores.rootStore.workshopStartup.defaultWorkshopDepot,
  futureJobs: allStores.rootStore.assets.futureJobs.slice(),
  onLoadAssetFutureJobs: allStores.rootStore.assets.getFutureJobsForAsset,
}))(ServicesDueByDate);

export default Container;
