import { Link } from 'react-router-dom';
import { SingleClickAwareButton } from '../../../components/presentational/SingleClickAwareButton/SingleClickAwareButton';
import { FormattedDateTimeWithoutYear } from '../../../components/presentational/FormattedDateTimeWithoutYear/FormattedDateTimeWithoutYear';
import Spinner from 'src/views/components/Spinner';
import styles from '../AssetDetails.module.scss';
import cn from 'classnames';
import { useEffect, useState } from 'react';

type FutureTaskForAsset = Workshop.Domain.Queries.GetFutureTaskForAsset.FutureTaskForAsset;
type ScheduledTaskForAsset = Workshop.Domain.Queries.GetScheduledTaskForAsset.ScheduledTaskForAsset;
type JobDetailsForKioskDto = Workshop.Domain.Queries.Job.GetJobDetailsForKiosk.JobDetailsForKioskDto;
type StartedTaskForAsset = Workshop.Domain.Queries.GetStartedTasksForAsset.StartedTaskForAsset;

export interface IAssetScheduledAndFutureTasksDisplayProps {
  assetId: string;
  currentTaskId?: string;
  futureTasksForAsset?: FutureTaskForAsset[];
  scheduledTasksForAsset?: ScheduledTaskForAsset[];
  startedTasksForAsset?: StartedTaskForAsset[];
  currentJob?: JobDetailsForKioskDto;
  addToCurrentJob?: (taskId: string) => Promise<void>;
  loadFutureAndScheduledAndStartedTasks: (assetId: string) => Promise<void>;
  taskJobNumber?: number;
  taskJobId?: string;
}

export const TaskRow: React.FC<{
  taskId: string;
  taskNumber: number;
  taskDescription: string;
  taskTypeName: 'Future' | 'Scheduled' | 'Started';
  taskScheduledStartDateTime?: string;
  currentTaskId?: string;
  currentJob?: JobDetailsForKioskDto;
  addToCurrentJob?: (taskId: string) => Promise<void>;
  taskJobId?: string;
}> = ({
  taskId,
  taskNumber,
  taskDescription,
  taskTypeName,
  taskScheduledStartDateTime,
  currentTaskId,
  currentJob,
  addToCurrentJob,
  taskJobId,
}) => {
  const taskAlreadyInView = !(
    (!currentTaskId || currentTaskId !== taskId) &&
    (!currentJob || !currentJob.jobTasks.some(x => x.id === taskId))
  );

  return (
    <div className={cn(styles.task, taskAlreadyInView ? styles.currentTask : null)}>
      <div>
        {taskAlreadyInView ? (
          <>{taskNumber}</>
        ) : taskJobId ? (
          // Tasks are readonly using the /wskiosk/task link
          // Confusion when looking to add labour to a "job"
          // Users would click the task number and expect to be able to put info in
          // Can only be done via Job area, so take user there if exists
          <Link to={`/wskiosk/job/${taskJobId}`}>{taskNumber}</Link>
        ) : (
          <Link to={`/wskiosk/task/${taskId}`}>{taskNumber}</Link>
        )}
      </div>
      <div>{taskDescription}</div>
      <div>{taskTypeName}</div>
      {addToCurrentJob ? (
        <SingleClickAwareButton
          className={styles.addButton}
          onClick={() => addToCurrentJob(taskId)}>
          + Add
        </SingleClickAwareButton>
      ) : taskScheduledStartDateTime ? (
        <FormattedDateTimeWithoutYear dateTime={taskScheduledStartDateTime} />
      ) : null}
    </div>
  );
};

export const AssetScheduledAndFutureTasksDisplay: React.FC<IAssetScheduledAndFutureTasksDisplayProps> = ({
  assetId,
  futureTasksForAsset,
  scheduledTasksForAsset,
  startedTasksForAsset,
  addToCurrentJob,
  currentTaskId,
  currentJob,
  loadFutureAndScheduledAndStartedTasks,
}) => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true);
    loadFutureAndScheduledAndStartedTasks(assetId).then(() => setIsLoading(false));
  }, [assetId]);

  const noData =
    (!futureTasksForAsset || futureTasksForAsset.length === 0) &&
    (!startedTasksForAsset || startedTasksForAsset.length === 0) &&
    (!scheduledTasksForAsset || scheduledTasksForAsset.length === 0);

  return (
    <Spinner show={isLoading} className={styles.scrollableTabbedArea}>
      <div className={styles.futureAndScheduled}>
        {isLoading ? (
          <div />
        ) : noData ? (
          <span>No tasks or scheduled jobs were found for this asset.</span>
        ) : (
          <>
            {futureTasksForAsset &&
              futureTasksForAsset.map((task, idx) => (
                <TaskRow
                  key={idx}
                  taskId={task.id}
                  taskNumber={task.jobTaskNumber}
                  taskDescription={task.description}
                  taskTypeName={'Future'}
                  currentJob={currentJob}
                  currentTaskId={currentTaskId}
                  addToCurrentJob={addToCurrentJob}
                />
              ))}
            {startedTasksForAsset &&
              startedTasksForAsset.map((task, idx) => (
                <TaskRow
                  key={idx}
                  taskId={task.id}
                  taskNumber={task.jobTaskNumber}
                  taskDescription={task.description}
                  taskTypeName={'Started'}
                  currentJob={currentJob}
                  currentTaskId={currentTaskId}
                  taskScheduledStartDateTime={task.scheduledStartDateTime}
                  taskJobId={task.jobId}
                />
              ))}
            {scheduledTasksForAsset &&
              scheduledTasksForAsset.map((task, idx) => (
                <TaskRow
                  key={idx}
                  taskId={task.id}
                  taskNumber={task.jobTaskNumber}
                  taskDescription={task.description}
                  taskTypeName={'Scheduled'}
                  taskScheduledStartDateTime={task.scheduledStartDateTime}
                  currentJob={currentJob}
                  currentTaskId={currentTaskId}
                  taskJobId={task.jobId}
                />
              ))}
          </>
        )}
      </div>
    </Spinner>
  );
};
