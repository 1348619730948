import { DateTime } from 'luxon';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import {
  ConflictCriteria,
  BatchOperationStatus,
  JobStatus,
  LeaveStatus,
  SmsMessageStatus,
} from 'src/api/enums';
import { RENDER_SMS_NAV_ELEMENTS } from 'src/appSettings';
import { DriverReportSubjectId } from 'src/domain/entities/operations/opsDiary/OperationsDiaryEntryModel';
import { useRootStore } from 'src/domain/entities/RootStoreModel';
import DashboardTileCollection from 'src/views/components/Dashboard/DashboardTileCollection';
import StandardDashboardTile from 'src/views/components/Dashboard/StandardDashboardTile';
import { DateTimeFormatSettings } from 'src/views/components/DateTimeFormat/DateTimeFormat';
import Page from 'src/views/components/Page';
import TaskCardItem, { toActionLinkDef } from 'src/views/components/TaskCard/TaskCardItem';
import { IPageDef, PagePrimarySize, PaneType } from 'src/views/definitionBuilders/types';

const Operations: React.FC = observer(() => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const rootStore = useRootStore();
  const accountModel = rootStore.account;
  const dashboardModel = rootStore.operations.dashboard;
  const dashboardData = dashboardModel.dashboardData;

  useEffect(() => {
    setIsLoading(true);
    dashboardModel.loadDashboardData().then(() => setIsLoading(false));
  }, []);

  const today = DateTime.local().startOf('day');
  const tomorrow = today.plus({ days: 1 });
  const yesterday = today.minus({ days: 1 }).toISODate();
  const sevenDaysAgo = today.minus({ days: 7 }).toISODate();
  const twoWeeksAgo = today.minus({ weeks: 2 }).toISODate();
  const sevenDays = today.plus({ days: 7 }).toISODate();

  const pendingLeaveLink = `/people/leaves?leaveStatuses=${LeaveStatus.Pending}&workshopStaff=false`;

  const notAcknowledgeJobsLink = (date: DateTime) => {
    var dateString = date.toISODate();
    const targetRoute = `/operations/jobs?dateFrom=${dateString}&dateTo=${dateString}&jobStatusIds=${JobStatus.NotAcknowledged}`;
    return targetRoute;
  };

  const notAcknowledgeJobsForTomorrowLink = notAcknowledgeJobsLink(tomorrow);
  const notAcknowledgeJobsForTodayLink = notAcknowledgeJobsLink(today);
  const incompleteTaskLink = (date: DateTime) => `/operations/tasks?day=${date.toISODate()}`;
  const incompleteTaskForTodayLink = incompleteTaskLink(today);
  const incompleteTaskForTomorrowLink = incompleteTaskLink(tomorrow);

  const unallocatedStaffMemberLink = (date: DateTime) => {
    const dateString = date.toISODate();
    return `/operations/jobs?dateFrom=${dateString}&dateTo=${dateString}&hasStaffMember=false`;
  };
  const unallocatedStaffMemberForTodayLink = unallocatedStaffMemberLink(today);
  const unallocatedStaffMemberForTomorrowLink = unallocatedStaffMemberLink(tomorrow);

  const unallocatedAssetLink = (date: DateTime) => {
    const dateString = date.toISODate();
    return `/operations/jobs?dateFrom=${dateString}&dateTo=${dateString}&hasVehicle=false`;
  };
  const unallocatedAssetForTodayLink = unallocatedAssetLink(today);
  const unallocatedAssetForTomorrowLink = unallocatedAssetLink(tomorrow);
  const notCompletedJobsLink = `/operations/jobs?dateFrom=${yesterday}&dateTo=${yesterday}&jobStatusIds=${JobStatus.NotAcknowledged}&jobStatusIds=${JobStatus.Incomplete}&jobStatusIds=${JobStatus.Acknowledged}`;
  const exceededShiftHoursLink = `/operations/jobs?dateFrom=${yesterday}&dateTo=${yesterday}&exceededShiftHours=true`;
  const jobRequiringVerificationLink = `/operations/jobs?dateFrom=${today.toISODate()}&dateTo=${sevenDays}&needsVerification=true`;
  const jobProgressWarningsLink = `/operations/progress-warnings?dateFrom=${twoWeeksAgo}&dateTo=${today.toISODate()}&showDismissedWarnings=false`;
  const driverReportsLink = `/operations/daily-event-log?dateFrom=${sevenDaysAgo}&dateTo=${today.toISODate()}&subjects=${DriverReportSubjectId}&acknowledged=false`;
  const conflictedJobsLink = `operations/jobs?conflicts=${
    ConflictCriteria.UnacceptedVehicle
  }&conflicts=${
    ConflictCriteria.UnacceptedStaffMember
  }&dateFrom=${today.toISODate()}&dateTo=${today.toISODate()}`;
  const unacknowledgedFailedGenerateJobsOperationsLink = `operations/generate-jobs-operations?failAcknowledged=false&generateJobsStatusIds=${BatchOperationStatus.Failed}`;
  const failedOrUndeliveredSmsLink = `/people/sms?dateFrom=${sevenDaysAgo}&dateTo=${today.toISODate()}&status=${
    SmsMessageStatus.Pending
  }&status=${SmsMessageStatus.Failed}`;
  const assetsWithUnknownKmsLink = `/operations/assets-unknown-kms/list?defaultFilter=true`;
  const jobsWithDriverManagedFatigueLink = `/operations/jobs?IsDriverManaged=true&dateFrom=${today.toISODate()}&dateTo=${sevenDays}`;

  const getPageDef = (): IPageDef => {
    const topTasksActions = [
      ...(accountModel.isOperationsDepartmentMember
        ? [TaskCardItem.urban.listGenerateJobsOperations]
        : []),
      ...(accountModel.isOperationsDepartmentMember
        ? [TaskCardItem.urban.createOperationsJob]
        : []),
      ...(accountModel.isOperationsDepartmentMember
        ? [TaskCardItem.operations.raiseOperationsDefect]
        : []),
      TaskCardItem.assetsWithUnknownKms,
      TaskCardItem.operations.listProcessDriverAuthorization,
    ].map(toActionLinkDef);
    const configurationActions = [
      TaskCardItem.operations.maintanOperationsDiaryEntrySubjects,
      TaskCardItem.operations.holidays.listHolidays,
      TaskCardItem.operations.subcontractors.listSubcontractor,
      TaskCardItem.operations.overwriteTabletOdometerReadings,
      TaskCardItem.operations.listRosterGroups,
      TaskCardItem.operations.listAuthorisedDevices,
      TaskCardItem.operations.charterContracts.listCharterContracts,
    ]
      .map(toActionLinkDef)
      .sort((a, b) => a.label.localeCompare(b.label));

    return {
      primarySize: PagePrimarySize.threeQuarters,
      primarySection: {
        title: 'Operations',
        badge: {
          label: `Last Updated: ${dashboardModel.lastUpdated?.toLocaleString(
            DateTimeFormatSettings
          )}`,
        },
        panels: [
          {
            panes: [
              {
                paneType: PaneType.customPane,
                render: () => (
                  <>
                    <DashboardTileCollection title="Today" isLoading={!dashboardData}>
                      {dashboardData ? (
                        <>
                          {RENDER_SMS_NAV_ELEMENTS && (
                            <StandardDashboardTile
                              title="Undelivered Sms"
                              number={dashboardData.undeliveredSmsOlderThanSomeTime}
                              link={failedOrUndeliveredSmsLink}
                            />
                          )}
                          <StandardDashboardTile
                            title="Jobs Not Acknowledged"
                            number={dashboardData.notAcknowledgeJobsForTodayCount}
                            link={notAcknowledgeJobsForTodayLink}
                          />
                          <StandardDashboardTile
                            title="Jobs With Unallocated Staff Member"
                            number={dashboardData.unallocatedStaffMemberJobsForTodayCount}
                            link={unallocatedStaffMemberForTodayLink}
                          />
                          <StandardDashboardTile
                            title="Jobs With Unallocated Vehicle"
                            number={dashboardData.unallocatedAssetJobsForTodayCount}
                            link={unallocatedAssetForTodayLink}
                          />
                          <StandardDashboardTile
                            title="Incomplete Charter Tasks"
                            number={dashboardData.incompleteTasksForTodayCount}
                            link={incompleteTaskForTodayLink}
                          />
                          <StandardDashboardTile
                            title="Jobs with Conflicts"
                            number={dashboardData.conflictedJobsCount}
                            link={conflictedJobsLink}
                          />
                        </>
                      ) : null}
                    </DashboardTileCollection>
                    <DashboardTileCollection title="Tomorrow" isLoading={!dashboardData}>
                      {dashboardData ? (
                        <>
                          <StandardDashboardTile
                            title="Jobs Not Acknowledged"
                            number={dashboardData.notAcknowledgeJobsForTomorrowCount}
                            link={notAcknowledgeJobsForTomorrowLink}
                          />
                          <StandardDashboardTile
                            title="Jobs With Unallocated Staff Member"
                            number={dashboardData.unallocatedStaffMemberJobsForTomorrowCount}
                            link={unallocatedStaffMemberForTomorrowLink}
                          />
                          <StandardDashboardTile
                            title="Jobs With Unallocated Vehicle"
                            number={dashboardData.unallocatedAssetJobsForTomorrowCount}
                            link={unallocatedAssetForTomorrowLink}
                          />
                          <StandardDashboardTile
                            title="Incomplete Charter Tasks"
                            number={dashboardData.incompleteTasksForTomorrowCount}
                            link={incompleteTaskForTomorrowLink}
                          />
                        </>
                      ) : null}
                    </DashboardTileCollection>
                    <DashboardTileCollection title="Other" isLoading={!dashboardData}>
                      {dashboardData ? (
                        <>
                          <StandardDashboardTile
                            title="Driver Managed Fatigue Jobs"
                            number={dashboardData.jobsWithDriverManagedFatigue}
                            link={jobsWithDriverManagedFatigueLink}
                          />
                          <StandardDashboardTile
                            title="Pending Leave"
                            number={dashboardData.pendingLeaveCount}
                            link={pendingLeaveLink}
                          />
                          <StandardDashboardTile
                            title="Jobs Not Completed"
                            number={dashboardData.notCompleteJobsCount}
                            link={notCompletedJobsLink}
                          />
                          <StandardDashboardTile
                            title="Exceeded Shift Hours"
                            number={dashboardData.exceededShiftHoursJobsCount}
                            link={exceededShiftHoursLink}
                          />
                          <StandardDashboardTile
                            title="Jobs requiring verification"
                            number={dashboardData.jobsRequiringVerificationInNextSevenDaysCount}
                            link={jobRequiringVerificationLink}
                          />
                          <StandardDashboardTile
                            title="Jobs with progress warnings from last 2 weeks"
                            number={dashboardData.jobsWithProgressWarningCount}
                            link={jobProgressWarningsLink}
                          />
                          <StandardDashboardTile
                            title="Jobs with Driver Reports from last 7 days"
                            number={
                              dashboardData.jobsWithUnacknowledgedDriverReportsInLastSevenDaysCount
                            }
                            link={driverReportsLink}
                          />
                          <StandardDashboardTile
                            title="Failed Generate Jobs Operations"
                            number={dashboardData.unacknowledgedFailedGenerateJobsOperations}
                            link={unacknowledgedFailedGenerateJobsOperationsLink}
                          />
                          <StandardDashboardTile
                            title="Assets With Unknown Kms"
                            number={dashboardData.assetsWithUnknownKmsCount}
                            link={assetsWithUnknownKmsLink}
                          />
                        </>
                      ) : null}
                    </DashboardTileCollection>
                  </>
                ),
              },
            ],
          },
        ],
      },
      secondarySections: [
        {
          title: 'Navigation',
          panels: [
            {
              title: 'Top Tasks',
              panes: [
                {
                  paneType: PaneType.navListPane,
                  links: topTasksActions,
                },
                {
                  paneType: PaneType.customPane,
                  render: d => (
                    <span>
                      <br />
                      <a href={`/kiosk/login`} target="_blank" rel="noreferrer">
                        <span>Driver's Kiosk</span>
                      </a>
                    </span>
                  ),
                },
              ],
            },
            {
              title: 'Configuration',
              panes: [
                {
                  paneType: PaneType.navListPane,
                  links: configurationActions,
                },
              ],
            },
          ],
        },
      ],
    };
  };

  return (
    <Page
      className="operations-landing-page"
      data={dashboardData}
      def={getPageDef()}
      showPrimarySectionSpinner={isLoading}
    />
  );
});

export default Operations;
