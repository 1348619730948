import { DateTime } from 'luxon';
import { IAjax } from 'src/infrastructure/ajax';
import { dailyNotesUrls } from 'src/domain/services/apiUrls';

type DailyNoteItem = Workshop.Domain.Queries.DailyNote.GetDailyNotes.DailyNoteItem;
type AddDailyNoteCommand = Workshop.Domain.Commands.DailyNote.AddDailyNote.AddDailyNoteCommand;
type UpdateDailyNoteCommand = Workshop.Domain.Commands.DailyNote.UpdateDailyNote.UpdateDailyNoteCommand;

export default function init(ajax: IAjax) {
  function getDailyNotes(day: DateTime, depotId: number) {
    return ajax
      .get(dailyNotesUrls.getDailyNotes(day, depotId))
      .toPromise()
      .then<DailyNoteItem>(r => r.response);
  }

  function createDailyNote(cmd: AddDailyNoteCommand) {
    return ajax
      .post(dailyNotesUrls.createDailyNote(), cmd)
      .toPromise()
      .then<DailyNoteItem>(r => ({ ...cmd, id: r.response }));
  }

  function updateDailyNote(cmd: UpdateDailyNoteCommand) {
    return ajax
      .put(dailyNotesUrls.updateDailyNote(cmd.id), cmd)
      .toPromise()
      .then<void>(r => r.response);
  }

  return {
    workshopDailyNotes: {
      getDailyNotes,
      createDailyNote,
      updateDailyNote,
    },
  };
}
