import { getSubmitCloseModalActionGroupDef } from 'src/views/definitionBuilders/common';
import { FieldType, ModalDefBuilder, PaneType } from 'src/views/definitionBuilders/types';

type StaffMemberDto = Common.Dtos.StaffMemberDto;
type CreateAssetAdHocFuelCommand = Workshop.Domain.Commands.Asset.CreateAssetAdHocFuel.CreateAssetAdHocFuelCommand;
type AssetFuelLocation = Workshop.Domain.Queries.ListAssetFuelLocations.AssetFuelLocationItem;

export const getCreateFuelModalDef = (
  staffMembers: StaffMemberDto[],
  assetFuelLocations: AssetFuelLocation[],
  createAdHocFuel: (command: CreateAssetAdHocFuelCommand) => Promise<void>,
  assetId: string
): ModalDefBuilder => {
  return () => {
    return {
      title: 'Add Fuel Entry',
      asForm: true,
      onFormPreSubmit: (form: CreateAssetAdHocFuelCommand): CreateAssetAdHocFuelCommand => {
        return {
          ...form,
          assetId: assetId,
        };
      },
      onFormSubmit: createAdHocFuel,
      panels: [
        {
          panes: [
            {
              paneType: PaneType.formFieldsPane,
              fields: [],
            },
            {
              paneType: PaneType.formFieldsPane,
              columnCount: 2,
              fields: [
                {
                  fieldType: FieldType.dateTimeField,
                  label: 'Pumped At',
                  dataAddr: 'pumpedTime',
                  mandatory: true,
                },
                {
                  fieldType: FieldType.numericField,
                  label: 'Odometer Reading',
                  dataAddr: 'odometerReading',
                  mandatory: true,
                },
                {
                  fieldType: FieldType.numericField,
                  label: 'Fuel Added (L)',
                  dataAddr: 'fuel',
                  numericConfig: { maxPointDigits: 4, numericType: 'signedDecimal' },
                  validate: api => {
                    if (api.fieldValue && api.fieldValue <= 0) {
                      return 'Fuel must be a positive number';
                    }

                    return api.fieldValue || api.parentValue.adblue
                      ? undefined
                      : 'Fuel or AdBlue is required';
                  },
                },
                {
                  fieldType: FieldType.numericField,
                  label: 'AdBlue Added (L)',
                  dataAddr: 'adblue',
                  numericConfig: { maxPointDigits: 4, numericType: 'signedDecimal' },
                  validate: api => {
                    if (api.fieldValue && api.fieldValue <= 0) {
                      return 'AdBlue must be a positive number';
                    }

                    return api.fieldValue || api.parentValue.fuel
                      ? undefined
                      : 'Fuel or AdBlue is required';
                  },
                },
                {
                  fieldType: FieldType.selectField,
                  label: 'Staff Member',
                  dataAddr: 'staffMemberId',
                  optionItems: staffMembers,
                  linkTo: d => `/people/staff-members/${d.parentValue.staffMemberId}`,
                  descriptionKey: 'name',
                  valueKey: 'id',
                  useValueOnly: true,
                  mandatory: true,
                },
                {
                  fieldType: FieldType.selectField,
                  label: 'Location',
                  dataAddr: 'assetFuelLocationId',
                  optionItems: assetFuelLocations,
                  descriptionKey: 'description',
                  valueKey: 'id',
                  useValueOnly: true,
                  mandatory: true,
                },
              ],
            },
          ],
        },
      ],
      secondaryActions: [getSubmitCloseModalActionGroupDef('Submit')],
    };
  };
};
