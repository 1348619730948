import { types, flow } from 'mobx-state-tree';
import { getAjax } from 'src/domain/services';

type ChecklistAssetGroup = Workshop.Domain.Queries.GetChecklistAssetGroups.ChecklistAssetGroup;

export const ChecklistAssetGroupsModel = types
  .model('ChecklistAssetGroupsModel', {
    items: types.array(types.frozen<ChecklistAssetGroup>()),
  })
  .actions(self => {
    const ajax = getAjax(self);

    const load = flow(function*(checklistId: string) {
      self.items = yield ajax.checklist.getChecklistAssetGroups(checklistId);
    });

    return {
      load,
    };
  });
