import { IAjax } from 'src/infrastructure/ajax';
import { complianceUrls } from 'src/domain/services/apiUrls';

type FatigueNoteItem = Operations.Domain.Queries.GetFatigueNoteById.FatigueNoteItem;
type CreateFatigueNoteCommand = Operations.Domain.Commands.FatigueNote.CreateFatigueNote.CreateFatigueNoteCommand;
type UpdateFatigueNoteCommand = Operations.Domain.Commands.FatigueNote.UpdateFatigueNote.UpdateFatigueNoteCommand;

export default function init(ajax: IAjax) {
  function getFatigueNote(id: string) {
    return ajax
      .get(complianceUrls.fatigueNoteUrls.getFatigueNote(id))
      .toPromise()
      .then<FatigueNoteItem>(r => r.response as FatigueNoteItem);
  }

  function createFatigueNote(command: CreateFatigueNoteCommand) {
    return ajax
      .post(complianceUrls.fatigueNoteUrls.createFatigueNote(), command)
      .toPromise()
      .then<string>(r => r.response as string);
  }

  function updateFatigueNote(command: UpdateFatigueNoteCommand) {
    return ajax
      .put(complianceUrls.fatigueNoteUrls.updateFatigueNote(), command)
      .toPromise()
      .then<string>(r => r.response as string);
  }

  function deleteFatigueNote(id: string) {
    return ajax.httpDelete(complianceUrls.fatigueNoteUrls.deleteFatigueNote(id)).toPromise();
  }

  return {
    fatigueNotes: {
      getFatigueNote,
      createFatigueNote,
      updateFatigueNote,
      deleteFatigueNote,
    },
  };
}
