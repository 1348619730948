import { flow, types, getRoot } from 'mobx-state-tree';
import { getAjax } from 'src/domain/services';
import { NotificationType } from 'src/domain/baseTypes';
import { IRootStoreModel } from 'src/domain/entities/RootStoreModel';
import { buildListPageApiSearchModelTypedQuery } from 'src/domain/modelBuilders/buildListPageApiSearchModel';

type PartTransactionItem = Workshop.Domain.Queries.GetPartTransactions.PartTransactionItem;
type GetPartTransactionsListQuery = Workshop.Domain.Queries.GetPartTransactions.GetPartTransactionsQuery;
type GetUsedPartsPdfReportQuery = Workshop.Domain.Queries.GetUsedPartsPdfReport.GetUsedPartsPdfReportQuery;

export interface IMatchingPart extends PartTransactionItem {
  searchText: string; // for what is searched when the user types
}

const partsListModel = buildListPageApiSearchModelTypedQuery<
  GetPartTransactionsListQuery
>()('PartListTransactionsModel', d =>
  d.ajax.workshop.partTransactions.listPartTransactions(d.query)
);

const UsedPartsReportModel = types.model('PartsReportModel', {}).actions(self => {
  const ajax = getAjax(self);
  const root = getRoot(self) as IRootStoreModel;

  const downloadUsedPartsExcel: (
    query: Partial<GetPartTransactionsListQuery>
  ) => Promise<Blob> = flow(function*(query: Partial<GetPartTransactionsListQuery>) {
    root.notifications.addNotification(`The file is being generated ...`, {
      type: NotificationType.info,
    });

    return yield ajax.workshop.partTransactions.downloadUsedPartsExcel(query);
  });

  const downloadUsedPartsPdf: (query: Partial<GetUsedPartsPdfReportQuery>) => Promise<Blob> = flow(
    function*(query: Partial<GetUsedPartsPdfReportQuery>) {
      root.notifications.addNotification(`The file is being generated ...`, {
        type: NotificationType.info,
      });

      return yield ajax.workshop.partTransactions.downloadUsedPartsPdf(query);
    }
  );

  return {
    downloadUsedPartsExcel,
    downloadUsedPartsPdf,
  };
});

export const PartTransactionsModel = types.compose(partsListModel, UsedPartsReportModel);
