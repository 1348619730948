import { Subject } from 'rxjs/Subject';
import { Observable } from 'rxjs/Observable';
import { getPayPeriodInDays } from 'src/domain/dateHelper';

const PRIMARY_MOUSE_BUTTON = 1;

type ScrollManagerComponent = {
  readonly dayWidthPx: number;
  readonly dayElementCount: number;
  setDayElementCount: (count: number) => void;
  forceUpdate: (callBack?: () => void) => void;
};

export default class ScrollManager {
  private readonly _dayContainerObserver: MutationObserver;
  private readonly _currentDayChangeSubject = new Subject<number>();
  private readonly _jumpPxSubject = new Subject<number>();

  private readonly _component: ScrollManagerComponent;
  private _dayWidthPx: number = 0;
  private _dayElementCount: number = 0;

  private _prevCentredDayIdx: number = 0;
  private _viewportClipperRef: HTMLDivElement | null = null;
  private _viewportRef: HTMLDivElement | null = null;
  private _dayContainerRef: HTMLDivElement | null = null;

  constructor(component: ScrollManagerComponent) {
    this._component = component;
    this._dayContainerObserver = new MutationObserver(this.hideScrollbar);
  }

  get centredDayIdx() {
    // Index of the day that's currently at the centre of the viewport
    return Math.floor(this.scrollDayRatio || 0);
  }

  get currentDayChangeStream() {
    return this._currentDayChangeSubject.asObservable();
  }

  readonly updateFromComponent = () => {
    this._dayWidthPx = this._component.dayWidthPx;
    this._dayElementCount = this._component.dayElementCount;

    this.updateDayElementCount();
  };

  readonly setViewportClipperRef = (ref: HTMLDivElement | null) => {
    this._viewportClipperRef = ref;
    if (this._viewportClipperRef) {
      this.hideScrollbar();
      this.configureClickDrag(this._viewportClipperRef);
    }
  };

  readonly setViewportRef = (ref: HTMLDivElement | null) => {
    this._viewportRef = ref;

    this.updateDayElementCount();
  };

  readonly setDayContainerRef = (ref: HTMLDivElement | null) => {
    if (this._dayContainerRef === ref) {
      return;
    }

    this._dayContainerRef = ref;

    if (this._dayContainerRef) {
      // Only observe some events for efficiency - this can be expanded if not working
      this._dayContainerObserver.observe(this._dayContainerRef, { childList: true, subtree: true });
    }
  };

  private readonly updateDayElementCount = () => {
    // Could potentially listen for element size changes and recalculate this
    if (this._viewportRef) {
      // Work out how many day elements should be rendered.
      // Fewer means more jumping and thus more rendering
      // More means more dom elements
      const daysInViewport = Math.ceil(this._viewportRef.clientWidth / this._dayWidthPx);
      let dayElementsCount = daysInViewport * 3; // a viewport's width either side
      // Always use an odd number so that the scroll center is midday of a day;
      dayElementsCount = dayElementsCount % 2 === 0 ? dayElementsCount + 1 : dayElementsCount;
      // For a narrow viewport, render a minimum of 7 days

      const newCount =
        dayElementsCount < getPayPeriodInDays() ? getPayPeriodInDays() : dayElementsCount;

      if (this._dayElementCount !== newCount) {
        this._dayElementCount = newCount;
        this._component.setDayElementCount(newCount);
      }
    }
  };

  readonly scrollOccurred = (e: React.UIEvent<HTMLDivElement>) => {
    // Notify listeners if scroll has crossed a day boundary
    const centreIdx = this.centredDayIdx;
    if (this._prevCentredDayIdx !== centreIdx) {
      this._currentDayChangeSubject.next(centreIdx - this._prevCentredDayIdx);
      this._prevCentredDayIdx = centreIdx;
    }

    if (this.isTriggeringInfinitePast) {
      this.jumpScrollToMiddleDay();
      return;
    } else if (this.isTriggeringInfiniteFuture) {
      this.jumpScrollToMiddleDay();
      return;
    }
  };

  readonly scrollForward24hours = () => {
    this.currentLeftPx = this.currentLeftPx + this._dayWidthPx;
  };

  readonly scrollBackward24hours = () => {
    this.currentLeftPx = this.currentLeftPx - this._dayWidthPx;
  };

  readonly jumpScrollToMiddle = (middayOffsetPercentage: number) => {
    // Jump to the middle (noon) of the middle day element, offset by middayOffsetPercentage
    // middayOffsetPercentage is between -0.5 and 0.5 (zero means the middle)
    let jumpPx = this.middleLeftPx - this.currentLeftPx;

    if (middayOffsetPercentage) {
      const offsetJumpPx = middayOffsetPercentage * this._dayWidthPx;
      jumpPx += offsetJumpPx;
    }

    jumpPx = Math.floor(jumpPx);

    if (jumpPx) {
      // Jump, so update _prevCentredDayIdx so that it don't trigger a currentDayChange when scroll next fires
      this._prevCentredDayIdx = this.middleIdxOffset;

      this.currentLeftPx += jumpPx;
      this._jumpPxSubject.next(jumpPx);

      // Force an update so that the days to be rendered is calculated again to match the jump
      this._component.forceUpdate();
    }
  };

  readonly getMiddayOffsetPercentage = () => {
    // Get a value between -0.5 and 0.5 meaning the percentage offset from the centre of the day
    const dayRatio = this.scrollDayRatio;
    if (dayRatio === undefined || this.currentLeftPx === 0) {
      // We want to be centred by default, so if we have no ratio or the current position is fully to
      // the left (meaning we're still setting up) then return zero.
      return 0;
    }

    const percentage = dayRatio - Math.floor(dayRatio);
    return percentage - 0.5;
  };

  private readonly jumpScrollToMiddleDay = () => {
    // Jump to the middle day element, but keep the relative position in the day unchanged
    this.jumpScrollToMiddle(this.getMiddayOffsetPercentage());
  };

  private get currentLeftPx() {
    return this._viewportRef ? this._viewportRef.scrollLeft : 0;
  }
  private set currentLeftPx(value: number) {
    this._viewportRef && (this._viewportRef.scrollLeft = Math.floor(value));
  }

  private get maxLeftPx() {
    // The maximum value of "scrollLeft" for the viewport
    return this._viewportRef ? this._viewportRef.scrollWidth - this._viewportRef.clientWidth : 0;
  }

  private get middleLeftPx() {
    return Math.floor(this.maxLeftPx / 2);
  }

  private get middleIdxOffset() {
    // The offset to find the day element that is the "middle" one
    return Math.floor(this._dayElementCount / 2);
  }

  private get scrollDayRatio() {
    // When there are no day elements, then the ratio does not exist yet
    if (this._dayElementCount === 0) {
      return undefined;
    }

    // The "percentage" across the current day element for the centre of the viewport.
    // Eg: "2.4" means that the centre of the viewport is 40% across the third day element.
    return this._viewportRef
      ? (this.currentLeftPx + this._viewportRef.clientWidth / 2) / this._dayWidthPx
      : 0;
  }

  private get isTriggeringInfinitePast() {
    return this.currentLeftPx < this._dayWidthPx;
  }

  private get isTriggeringInfiniteFuture() {
    return this.currentLeftPx > this.maxLeftPx - this._dayWidthPx;
  }

  private readonly hideScrollbar = () => {
    if (this._viewportClipperRef && this._dayContainerRef) {
      // Explicitly set the viewport container height to hide the horizontal scrollbar from view
      this._viewportClipperRef.style.height = `${this._dayContainerRef.clientHeight}px`;
    }
  };

  private getEventButtons(e: MouseEvent) {
    return e.buttons === undefined ? e.which : e.buttons; // Safari doesn't support "buttons"
  }

  private readonly configureClickDrag = (e: HTMLElement) => {
    // Lazy executor
    const getCurrentLeft = () => this.currentLeftPx;

    // Prevent Edge from firing mouse events for non-mouse events (so that our click-drag code does not fire)
    const pointerDownSubscription = Observable.fromEvent<PointerEvent>(e, 'pointerdown', {
      capture: false,
    })
      .filter(pd => pd.pointerType !== 'mouse')
      .subscribe(pd => pd.preventDefault());

    const pointerMoveSubscription = Observable.fromEvent<PointerEvent>(document, 'pointermove', {
      capture: false,
    })
      .filter(pd => pd.pointerType !== 'mouse')
      .subscribe(pd => pd.preventDefault());

    // Only listen to mouse-downs that have bubbled to the passed-in element to start the click-drag
    const start$ = Observable.fromEvent<MouseEvent>(e, 'mousedown', { capture: false })
      .share()
      .throttleTime(15)
      .filter(click => !click.defaultPrevented)
      .filter(click => !click.target || !(click.target as Element).closest('button')); // Ignore button clicks

    // Watch all mouse-move events that bubble to the document level
    const move$ = Observable.fromEvent<MouseEvent>(document, 'mousemove', {
      capture: false,
    })
      .share()
      .throttleTime(15)
      .filter(move => !move.defaultPrevented);

    // We want a click-drag to end when the user is no longer holding the left mouse button,
    // even if they release when the mouse is outside of the browser
    const end$ = move$
      .distinctUntilChanged((a, b) => this.getEventButtons(a) === this.getEventButtons(b))
      .filter(move => this.getEventButtons(move) !== PRIMARY_MOUSE_BUTTON)
      .do(() => e.classList.remove('click-drag-active'));

    // A total of jumps made
    const jump$ = this._jumpPxSubject.startWith(0).scan((acc, jump) => acc + jump, 0);

    // Create stream of what the left position needs to be
    const newLeftPx$ = start$.switchMap(start =>
      move$
        .takeUntil(end$)
        .do(move => move.preventDefault()) // stop any other behaviour
        .withLatestFrom(jump$) // Feed in any jumps that happen during the drag
        .scan(
          (acc, pair) => {
            const [evt, jump] = pair;
            return {
              ...acc,
              totalChange: start.clientX - evt.clientX,
              totalJumpPx: jump,
            };
          },
          {
            initLeft: getCurrentLeft(),
            totalChange: 0,
            totalJumpPx: 0,
          }
        )
        .map(x => x.initLeft + x.totalChange + x.totalJumpPx)
        .do(x => e.classList.add('click-drag-active'))
    );
    const positionSubscription = newLeftPx$.subscribe(newLeftPx => {
      this.currentLeftPx = newLeftPx;
    });

    return [pointerDownSubscription, pointerMoveSubscription, positionSubscription];
  };
}
