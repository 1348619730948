import { Component } from 'react';
import { IPageDef, PagePrimarySize, PaneType } from 'src/views/definitionBuilders/types';
import FormGroup from 'reactstrap/lib/FormGroup';
import Spinner from 'src/views/components/Spinner';
import { Button } from 'reactstrap';
import Page from 'src/views/components/Page';

export interface IProcessResultsFileProps {
  createBulkDriverAuthorizationUpdateOperation: (file: string | Blob) => Promise<void>;
}

interface IProcessResultsFileState {
  file?: File;
  isLoading: boolean;
}

class ProcessResultsFile extends Component<IProcessResultsFileProps, IProcessResultsFileState> {
  constructor(props: IProcessResultsFileProps) {
    super(props);
    this.state = { file: undefined, isLoading: false };
  }

  setFile(e: React.ChangeEvent<HTMLInputElement>) {
    this.setState({ file: e.target.files![0] });
  }

  submit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    if (this.state.file) {
      this.setState({ isLoading: true });

      this.props
        .createBulkDriverAuthorizationUpdateOperation(this.state.file)
        .then(_ => this.setState({ file: undefined, isLoading: false }));
    }
  }

  private readonly getPageDef = (): IPageDef => {
    return {
      primarySize: PagePrimarySize.full,
      primarySection: {
        title: 'Process Results File',
        panels: [
          {
            title: 'Upload new File',
            panes: [
              {
                paneType: PaneType.customPane,
                render: api => {
                  return (
                    <form onSubmit={e => this.submit(e)}>
                      <FormGroup>
                        {this.state.file ? (
                          <div>{this.state.file.name}</div>
                        ) : (
                          <input type="file" accept={'.csv'} onChange={e => this.setFile(e)} />
                        )}
                      </FormGroup>
                      <FormGroup>
                        <Spinner show={this.state.isLoading}>
                          {this.state.file && !this.state.isLoading && (
                            <Button type="submit">Upload</Button>
                          )}
                        </Spinner>
                      </FormGroup>
                    </form>
                  );
                },
              },
            ],
          },
        ],
        onFormSubmit: d => {
          return this.props.createBulkDriverAuthorizationUpdateOperation(d);
        },
      },
    };
  };

  render() {
    return <Page def={this.getPageDef()} data={{}} />;
  }
}

export default ProcessResultsFile;
