import saveAs from 'file-saver';
import { DateTime } from 'luxon';
import { useEffect } from 'react';
import {
  allFatigueBreachEventStatus,
  allFatigueRuleType,
  FatigueBreachEventStatus,
  fatigueBreachEventStatusDescription,
  fatigueRuleSetTypeDescription,
  fatigueRuleTypeDescription,
} from 'src/api/enums';
import { BasicSearch, ListPageLoadCause } from 'src/domain/baseTypes';
import { useRootStore } from 'src/domain/entities/RootStoreModel';
import { ExcelIcon, FilePdfIcon } from 'src/images/icons';
import ListPage, { IListPageDef } from 'src/views/components/Page/pages/ListPage';
import { ActionType, PagePrimarySize, PaneType } from 'src/views/definitionBuilders/types';
import { FieldDefs, FieldType } from 'src/views/definitionBuilders/types/field';
import withQueryParams, { IQueryParamsProps } from 'src/views/hocs/withQueryParams';
import getVerifyFatigueBreachEventActionButtonDef from '../getVerifyFatigueBreachEventActionButtonDef';
import getCloseFatigueBreachEventActionButtonDef from './getCloseFatigueBreachEventActionButtonDef';
import { TIMEZONE as timezone } from 'src/appSettings';
import { observer } from 'mobx-react-lite';
import PrimaryTitle from 'src/views/components/Page/PrimaryTitle/PrimaryTitle';

type StaffMemberDto = Common.Dtos.StaffMemberDto;

const ListFatigueBreachEvents: React.FC<IQueryParamsProps<BasicSearch>> = observer(
  (props: IQueryParamsProps<BasicSearch>) => {
    const rootStore = useRootStore();
    const fatigueEventModel = rootStore.compliance.fatigueBreachEvent;
    const fatigueEventsModel = rootStore.compliance.fatigueBreachEvents;
    const staffModel = rootStore.people.staffMembers;

    useEffect(() => {
      staffModel.loadAllStaffMembers();
    }, []);

    const getFilterFieldDefs = (
      timezone: string,
      activeStaffMembers: StaffMemberDto[]
    ): { [key: string]: FieldDefs } => {
      return {
        start: {
          fieldType: FieldType.dateField,
          dataAddr: 'dateFrom',
          label: 'From',
          timezone: timezone,
          onBlur: api => {
            api.validateField(['dateFrom']);
          },
        } as FieldDefs,
        end: {
          fieldType: FieldType.dateField,
          dataAddr: 'dateTo',
          label: 'To',
          timezone: timezone,
          validate: d => {
            if (!d.fieldValue || !d.parentValue.dateFrom) {
              return undefined;
            }
            const from = DateTime.fromISO(d.parentValue.dateFrom, { zone: timezone });
            const to = DateTime.fromISO(d.fieldValue, { zone: timezone });
            return from > to ? 'To cannot be earlier than From' : undefined;
          },
        } as FieldDefs,
        staffMemberIds: {
          fieldType: FieldType.selectMultiField,
          dataAddr: 'staffMemberIds',
          label: 'Staff Member',
          valueKey: 'id',
          descriptionKey: 'name',
          optionItems: activeStaffMembers.slice(),
          useValueOnly: true,
        } as FieldDefs,
        rules: {
          fieldType: FieldType.selectMultiField,
          dataAddr: 'fatigueRuleTypes',
          label: 'Rule',
          valueKey: 'value',
          descriptionKey: 'description',
          optionItems: allFatigueRuleType.slice(),
          useValueOnly: true,
        } as FieldDefs,
        statuses: {
          fieldType: FieldType.selectMultiField,
          dataAddr: 'statuses',
          label: 'Status',
          valueKey: 'value',
          descriptionKey: 'description',
          optionItems: allFatigueBreachEventStatus.slice(),
          useValueOnly: true,
        } as FieldDefs,
      };
    };

    function getPageDef(hasMoreData: boolean): IListPageDef {
      const filterFieldDefsLookup = getFilterFieldDefs(timezone, staffModel.allStaffMembers);

      const saveFatigueBreachEventPdf = (id: string) => {
        const fileName = `Fatigue_Breach_Event_${DateTime.local().toFormat('yyyyMMddHHmm')}.pdf`;
        return fatigueEventModel
          .exportFatigueBreachEventToPdf(id)
          .then(blob => saveAs(blob, fileName));
      };

      const saveExcel = () => {
        const fileName = `Fatigue_Breach_Events_${DateTime.local().toFormat('yyyyMMddHHmm')}.xlsx`;
        return fatigueEventModel
          .exportToExcel(props.getQueryParams())
          .then(blob => saveAs(blob, fileName));
      };

      return {
        primaryTitle: (
          <PrimaryTitle
            title="Fatigue Breach Events"
            link="https://www.notion.so/Fatigue-Breach-Events-358abce823d94506a0c7ee1cb1cee235"></PrimaryTitle>
        ),
        primarySize: PagePrimarySize.full,
        onLoadData: fatigueEventsModel.listItems,
        hasMoreData: hasMoreData,
        externalSearch: true,
        primaryActions: [
          {
            actions: [
              {
                actionType: ActionType.actionCollection,
                actionGroups: [
                  {
                    actions: [
                      {
                        actionType: ActionType.actionButton,
                        label: 'Export to Excel',
                        icon: <ExcelIcon fixedWidth />,
                        onClick: () => saveExcel(),
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
        primaryFields: [
          {
            fieldType: FieldType.textField,
            dataAddr: 'fatigueBreachEventNumber',
            label: 'Breach Event Number',
            linkTo: d => `/compliance/fatigue-breach-events/${d.parentValue.id}`,
          },
          {
            fieldType: FieldType.textField,
            dataAddr: 'staffMemberName.name',
            label: 'Staff Member',
            linkTo: d => `/people/staff-members/${d.parentValue.staffMemberName.id}`,
          },
          {
            fieldType: FieldType.readonlyField,
            dataAddr: 'fatigueRuleType',
            label: 'Rule',
            formatReadonly: data => fatigueRuleTypeDescription(data.parentValue.fatigueRuleType),
          },
          {
            fieldType: FieldType.readonlyField,
            dataAddr: 'fatigueRuleSetType',
            label: 'Ruleset',
            formatReadonly: data =>
              fatigueRuleSetTypeDescription(data.parentValue.fatigueRuleSetType),
          },
          {
            fieldType: FieldType.dateField,
            dataAddr: 'breachDate',
            label: 'Breach Date',
          },
          {
            fieldType: FieldType.readonlyField,
            dataAddr: 'job.jobNumber',
            label: 'Job Number',
            linkTo: d => `/operations/jobs/${d.parentValue.job.id}`,
            formatReadonly: data => {
              return data.parentValue.job.jobNumber === null
                ? 'Deleted'
                : data.parentValue.job.jobNumber;
            },
          },
          {
            fieldType: FieldType.textField,
            dataAddr: 'status',
            label: 'Status',
            formatReadonly: data => fatigueBreachEventStatusDescription(data.parentValue.status),
          },
          {
            fieldType: FieldType.actionListField,
            hidden: () => !rootStore.account.isOperationsDepartmentMember,
            dataAddr: '',
            columnWidth: '1px',
            label: 'Actions',
            actionGroups: [
              {
                actions: [
                  {
                    actionType: ActionType.actionCollection,
                    actionGroups: [
                      {
                        actions: [
                          {
                            actionType: ActionType.actionButton,
                            label: 'Export to PDF',
                            icon: <FilePdfIcon />,
                            onClick: d => {
                              const id = d.parentValue.id;
                              saveFatigueBreachEventPdf(id);
                            },
                          },
                          getCloseFatigueBreachEventActionButtonDef(
                            fatigueEventModel.closeFatigueBreachEvent,
                            () => {
                              fatigueEventsModel.listItems({
                                query: { ...props.getQueryParams() },
                                loadCause: ListPageLoadCause.refresh,
                              });
                            }
                          ),
                          getVerifyFatigueBreachEventActionButtonDef(
                            fatigueEventModel.verify,
                            fatigueEventModel.invalidateFatigueBreachEvent,
                            () => {
                              fatigueEventsModel.listItems({
                                query: { ...props.getQueryParams() },
                                loadCause: ListPageLoadCause.refresh,
                              });
                            },
                            fatigueEventModel.clearVerifyFatigueBreachEvent,
                            fatigueEventModel.verifyFatigueBreachEvent
                          ),
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
        filterAction: {
          defaultValues: {
            statuses: [FatigueBreachEventStatus.Open],
          },
          filterFields: Object.keys(filterFieldDefsLookup).map(
            filters => filterFieldDefsLookup[filters]
          ),
          filterDef: _ => [
            {
              panes: [
                {
                  paneType: PaneType.formFieldsPane,
                  columnCount: 2,
                  fields: [filterFieldDefsLookup.start, filterFieldDefsLookup.end],
                },
                {
                  paneType: PaneType.formFieldsPane,
                  fields: [
                    filterFieldDefsLookup.staffMemberIds,
                    filterFieldDefsLookup.statuses,
                    filterFieldDefsLookup.rules,
                  ],
                },
              ],
            },
          ],
        },
      };
    }

    return (
      <ListPage
        className="list-fatiguebreachevents-component"
        data={fatigueEventsModel.items.slice()}
        def={getPageDef(fatigueEventsModel.hasMoreItems)}
      />
    );
  }
);

export default withQueryParams(ListFatigueBreachEvents);
