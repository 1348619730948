import { PaneType, SectionDefs } from 'src/views/definitionBuilders/types';
import { DateTime } from 'luxon';
import TimezoneFormat from 'src/views/components/DateTimeFormat/TimezoneFormat';

type ActivityLogTransaction = Operations.Domain.Queries.GetActivityLog.ActivityLogTransaction;
type ActivityLogDetails = Operations.Domain.Queries.GetActivityLog.ActivityLogDetails;

export default function getActivityLogPanelDef(
  fatigueBreachEventId: string,
  activityLogs: ActivityLogTransaction[]
): SectionDefs {
  return {
    title: 'Activity',
    hidden: !fatigueBreachEventId,
    explicitData: activityLogs,
    panels: [
      {
        panes: [
          {
            paneType: PaneType.repeatingPane,
            itemPanes: [
              {
                paneType: PaneType.customPane,
                dataAddr: 'transactionId',
                render: api => {
                  const log = api.data.parentValue as ActivityLogTransaction;
                  const createdOnDateTime = DateTime.fromISO(log.createdOn);
                  const formattedCreatedOn = createdOnDateTime.toLocaleString(
                    DateTime.DATETIME_MED_WITH_SECONDS
                  );
                  const linedComment = (item: ActivityLogDetails) => item.comment.split('\n');
                  return (
                    <div className="activity-log-for-breach-event-pane">
                      <div className="created-by">{log.createdBy}</div>
                      <div className="created-on">
                        {formattedCreatedOn}{' '}
                        <TimezoneFormat value={createdOnDateTime} blankForDefaultZone />
                      </div>
                      <ul className="log-items list-unstyled">
                        {log.items.map((item: ActivityLogDetails) => {
                          return (
                            <li key={item.activityLogId}>
                              <div>
                                <strong>{item.description}</strong>
                              </div>
                              <div>
                                {linedComment(item).map((c, idx) => (
                                  <em key={idx}>{c}</em>
                                ))}
                              </div>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  );
                },
              },
            ],
          },
        ],
      },
    ],
  };
}
