import MaintainTechSpecs, { IMaintainSkillSpecsProps } from './MaintainSkillSpecs';
import inject from 'src/views/injectFromStore';
import Omit from 'src/infrastructure/omit';

const Container = inject<Omit<IMaintainSkillSpecsProps, 'mode'>, IMaintainSkillSpecsProps>(
  (allStores, _) => ({
    skillSpecs: allStores.rootStore.people.skillSpecs.items.slice(),
    getSkillSpecs: allStores.rootStore.people.skillSpecs.getAll,
    updateSkillSpecs: allStores.rootStore.people.skillSpecs.updateAll,
    clearSkillSpecRequirements: allStores.rootStore.people.skillSpecs.clearSkillSpecRequirements,
    canClearSkillSpecRequirements: allStores.rootStore.account.isAdminUser,
  })
)(MaintainTechSpecs);

export default Container;
