import { Component } from 'react';
import { getShallowMemoizedObjectFunc } from 'src/infrastructure/memoizeOne';
import { withFormApi } from 'src/views/components/Page/forms';
import { IFormApi } from 'src/views/components/Page/forms/base';
import {
  PaneDefs,
  isCustomPaneDef,
  isTablePaneDef,
  isNavListPaneDef,
  isActionListPaneDef,
  IPaneData,
  isFormFieldsPaneDef,
  IPaneMeta,
  IPanelData,
  isRepeatingPaneDef,
  isNestingPaneDef,
  isRepeatingTabPaneDef,
  isTabPaneDef,
  isPaginationPaneDef,
} from 'src/views/definitionBuilders/types';
import CustomPagePane from './CustomPagePane';
import TablePagePane from './TablePagePane';
import NavListPagePane from './NavListPagePane';
import ActionListPagePane from './ActionListPagePane';
import FormFieldsPagePane from './FormFieldsPagePane';
import PageNestedItem from '../PageNestedItem';
import RepeatingPagePane from './RepeatingPagePane';
import NestingPagePane from './NestingPagePane';
import RepeatingTabPagePane from './RepeatingTabPagePane';
import TabPagePane from './TabPagePane';
import PaginationPagePane from './PaginationPagePane';

interface IPagePaneProps {
  formApi: IFormApi;
  paneDef: PaneDefs;
  paneMeta: IPaneMeta;
  panelData: IPanelData;
  // tslint:disable-next-line:no-any
  parentValue: any;
}

class PagePane extends Component<IPagePaneProps> {
  private readonly getPaneData = () => {
    const { formApi, paneDef: def, panelData, parentValue } = this.props;
    const data: IPaneData = {
      paneValue: formApi.getValue(formApi.getFullField(def.dataAddr)),
      parentValue: parentValue || panelData.panelValue,
      panelValue: panelData.panelValue,
      sectionValue: panelData.sectionValue,
    };
    return this.memoizePaneData(data);
  };
  private readonly memoizePaneData = getShallowMemoizedObjectFunc<IPaneData>();

  private readonly getPane = (): JSX.Element => {
    const { paneDef, paneMeta: meta } = this.props;
    const data = this.getPaneData();

    if (isCustomPaneDef(paneDef)) {
      return <CustomPagePane paneDef={paneDef} paneData={data} paneMeta={meta} />;
    } else if (isFormFieldsPaneDef(paneDef)) {
      return <FormFieldsPagePane paneDef={paneDef} paneData={data} paneMeta={meta} />;
    } else if (isTablePaneDef(paneDef)) {
      return <TablePagePane paneDef={paneDef} paneData={data} paneMeta={meta} />;
    } else if (isPaginationPaneDef(paneDef)) {
      return <PaginationPagePane paneDef={paneDef} paneData={data} paneMeta={meta} />;
    } else if (isRepeatingPaneDef(paneDef)) {
      return <RepeatingPagePane paneDef={paneDef} paneData={data} paneMeta={meta} />;
    } else if (isRepeatingTabPaneDef(paneDef)) {
      return <RepeatingTabPagePane paneDef={paneDef} paneData={data} paneMeta={meta} />;
    } else if (isTabPaneDef(paneDef)) {
      return <TabPagePane paneDef={paneDef} paneData={data} paneMeta={meta} />;
    } else if (isNestingPaneDef(paneDef)) {
      return <NestingPagePane paneDef={paneDef} paneData={data} paneMeta={meta} />;
    } else if (isNavListPaneDef(paneDef)) {
      return <NavListPagePane paneDef={paneDef} />;
    } else if (isActionListPaneDef(paneDef)) {
      return <ActionListPagePane paneDef={paneDef} paneData={data} paneMeta={meta} />;
    } else {
      throw new Error('Pane type not recognised');
    }
  };

  private get isHidden() {
    const { paneDef: def } = this.props;
    if (typeof def.hidden === 'function') {
      return def.hidden(this.getPaneData());
    } else {
      return !!def.hidden;
    }
  }

  render() {
    const { formApi, paneDef: def } = this.props;
    if (this.isHidden) {
      return null;
    }
    return (
      <PageNestedItem dataAddr={def.dataAddr} formApi={formApi}>
        {this.getPane()}
      </PageNestedItem>
    );
  }
}

export default withFormApi(PagePane);
