import { CheckIcon } from 'src/images/icons';
import { getSubmitCloseModalActionGroupDef } from 'src/views/definitionBuilders/common';
import {
  ActionType,
  ShellModalSize,
  PaneType,
  IModalActionButtonDef,
} from 'src/views/definitionBuilders/types';

export default function getAcknowledgeJobOnBehalfButtonDef(
  jobId: string,
  staffMemberId: string,
  staffMemberName: string,
  acknowledgeJobOnBehalf: (jobId: string, staffMemberId: string) => Promise<void>
): IModalActionButtonDef {
  return {
    actionType: ActionType.modalActionButton,
    label: `Acknowledge for ${staffMemberName}`,
    icon: <CheckIcon />,
    modalSize: ShellModalSize.oneQuarter,
    modalDef: modalDefApi => ({
      title: 'Acknowledge Job On Behalf',
      asForm: true,
      panels: [
        {
          panes: [
            {
              paneType: PaneType.customPane,
              render: () => (
                <>
                  <p>
                    Are you sure you want to acknowledge this job on {staffMemberName}
                    's behalf?
                  </p>
                  <p>Please make sure that they are aware of this job.</p>
                </>
              ),
            },
          ],
        },
      ],
      secondaryActions: [getSubmitCloseModalActionGroupDef('Acknowledge')],
      onFormSubmit: () => acknowledgeJobOnBehalf(jobId, staffMemberId),
    }),
  };
}
