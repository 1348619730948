import cn from 'classnames';
import styles from './Scheduler.module.scss';

import { LocationDescriptorObject } from 'history';
import { DateTime } from 'luxon';
import { useEffect, useRef, useState } from 'react';
import { IScheduledJob } from 'src/domain/entities/workshop/job/ScheduledJobsModel';
import { ChevronLeftIcon, ChevronRightIcon, SpinnerIcon } from 'src/images/icons';
import { getZonedDaysFromInterval, intervalCompare } from 'src/infrastructure/dateUtils';
import ActionBar from 'src/views/components/ActionBar';
import { ILinkAction } from 'src/views/components/ActionBar/ActionBar';
import { IScheduleEvent } from 'src/views/components/Scheduler/ScheduleEvent';
import WeeklySchedule from 'src/views/components/Scheduler/WeeklySchedule';
import WorkshopAssetIcon from 'src/views/components/workshop/assetIcon/WorkshopAssetIcon';
import { getWorkshopDepotSelectAction } from 'src/views/routes/workshop/jobs/jobSchedule/Scheduler/WorkshopDepotSelect';
import JobSchedulePopover from '../../jobSchedule/JobSchedulePopover';
import { getJobTaskCategoryClass, ScheduleLegend } from '../../ScheduleCommon';

type WorkshopDepot = Common.Queries.Workshop.GetWorkshopDepots.WorkshopDepotDto;

interface IControlPanelProps {
  zonedNow: DateTime;
  zonedWeekToDisplay: DateTime;
  getNavigationLink: (weekToDisplay: string, depotId: number) => LocationDescriptorObject;
  workshopDepots: Array<WorkshopDepot>;
  selectedWorkshopDepotId: number;
  changeSelectedWorkshopDepotId: (
    selectedWorkshopDepotId: number,
    zonedWeekToDisplay: DateTime
  ) => void;
}

const ControlPanel: React.FC<IControlPanelProps> = ({
  zonedNow,
  zonedWeekToDisplay,
  getNavigationLink,
  workshopDepots,
  selectedWorkshopDepotId,
  changeSelectedWorkshopDepotId,
}) => {
  const prev = zonedWeekToDisplay.minus({ weeks: 1 });
  const next = zonedWeekToDisplay.plus({ weeks: 1 });
  const alreadyOnThisWeek = zonedWeekToDisplay.weekNumber === zonedNow.weekNumber;
  const prevAction: ILinkAction = {
    label: <ChevronLeftIcon size="sm" />,
    size: 'sm',
    to: getNavigationLink(prev.toISOWeekDate(), selectedWorkshopDepotId),
  };
  const todayAction: ILinkAction = {
    label: 'Go to This Week',
    size: 'sm',
    to: getNavigationLink(zonedNow.set({ weekday: 1 }).toISOWeekDate(), selectedWorkshopDepotId),
    disabled: alreadyOnThisWeek,
  };
  const nextAction: ILinkAction = {
    label: <ChevronRightIcon size="sm" />,
    size: 'sm',
    to: getNavigationLink(next.toISOWeekDate(), selectedWorkshopDepotId),
  };
  const dayViewLink: ILinkAction = {
    label: 'View Daily Schedule',
    size: 'sm',
    to: `/workshop/jobs/schedule?depotId=${selectedWorkshopDepotId}`,
  };
  const weekViewLink: ILinkAction = {
    label: 'Weekly Schedule',
    size: 'sm',
    outline: true,
    disabled: true,
    to: `/workshop/jobs/weekly-schedule?week=${zonedWeekToDisplay
      .set({ weekday: 1 })
      .toISOWeekDate()}&depotId=${selectedWorkshopDepotId}`,
  };
  const monthViewLink: ILinkAction = {
    label: 'View HV Machinery Schedule',
    size: 'sm',
    to: `/workshop/jobs/machinery-schedule?month=${zonedWeekToDisplay.toFormat(
      'yyyy-MM'
    )}&depotId=${selectedWorkshopDepotId}`,
  };
  const depotDropdown = getWorkshopDepotSelectAction(
    workshopDepots,
    selectedWorkshopDepotId,
    (selectedDepot: WorkshopDepot) => {
      if (selectedDepot) {
        changeSelectedWorkshopDepotId(selectedDepot.id, zonedWeekToDisplay);
      }
    }
  );
  return (
    <ActionBar
      groups={[
        [prevAction, todayAction, nextAction],
        [depotDropdown],
        [dayViewLink, weekViewLink, monthViewLink],
      ]}
    />
  );
};

export interface ISchedulerProps {
  showInZone: string;
  nowUtc: DateTime;
  jobs: IScheduledJob[];
  jobsLoading: boolean;
  weekToDisplay: DateTime;
  getNavigationLink: (weekToDisplay: string, depotId: number) => LocationDescriptorObject;
  onWeekChanged: (weekYear: number, weekNumber: number, depotId: number) => void;
  workshopDepots: Array<WorkshopDepot>;
  defaultWorkshopDepot: WorkshopDepot;
  selectedWorkshopDepotId: number;
  changeSelectedWorkshopDepotId: (
    selectedWorkshopDepotId: number,
    zonedWeekToDisplay: DateTime
  ) => void;
}

const Scheduler: React.FC<ISchedulerProps> = (props: ISchedulerProps) => {
  const [selectedEventId, setSelectedEventId] = useState<string | undefined>(undefined);
  const prevWeek = useRef(props.weekToDisplay);
  const prevDepotId = useRef(props.selectedWorkshopDepotId);

  const zonedNow = props.nowUtc.setZone(props.showInZone);

  useEffect(() => {
    if (!props.selectedWorkshopDepotId) return;

    props.onWeekChanged(
      props.weekToDisplay.weekYear,
      props.weekToDisplay.weekNumber,
      props.selectedWorkshopDepotId
    );
  }, []);

  useEffect(() => {
    if (
      weekToDisplay.weekYear !== prevWeek.current.weekYear ||
      weekToDisplay.weekNumber !== prevWeek.current.weekNumber ||
      selectedWorkshopDepotId !== prevDepotId.current
    ) {
      props.onWeekChanged(
        props.weekToDisplay.weekYear,
        props.weekToDisplay.weekNumber,
        props.selectedWorkshopDepotId
      );
    }
  }, [props.selectedWorkshopDepotId, props.weekToDisplay.weekYear, props.weekToDisplay.weekNumber]);

  const isJobOutOfService = (job: IScheduledJob) =>
    job.tasks.some((x: { fitForService?: boolean }) => x.fitForService === false);

  const getJobDescription = (job: IScheduledJob, day: DateTime) =>
    !day.hasSame(job.jobInterval.start, 'day')
      ? `${job.assetName} (continued)`
      : `${job.assetName} @ ${job.jobInterval.start.toLocaleString(DateTime.TIME_SIMPLE)}`;

  const getHasCrew = (job: IScheduledJob, day: DateTime) =>
    job.tasks.some(
      o => o.staff && o.staff.length > 0 && o.staff.some(s => !s.day || s.day === day.toISODate())
    );

  const getCrew = (job: IScheduledJob, day: DateTime) =>
    getHasCrew(job, day)
      ? Array.from(
          new Set(
            job.tasks
              .map(o => o.staff)
              .reduce((x, y) => x.concat(y))
              .filter(o => !o.day || o.day === day.toISODate())
              .map(o => o.name)
          )
        ).join(', ')
      : undefined;

  const getDayLink = (date: string) =>
    `/workshop/jobs/schedule?day=${date}&depotId=${props.selectedWorkshopDepotId}`;

  const getEventBody = (job: IScheduledJob, day: DateTime) => (
    <div
      className={cn(
        styles['event-item'],
        styles[getJobTaskCategoryClass(job)],
        isJobOutOfService(job) ? styles['out-of-service'] : '',
        job.jobId === selectedEventId ? styles['selected'] : ''
      )}>
      <div className={cn(styles['event-icon'])}>
        <WorkshopAssetIcon
          assetSubcategoryId={job.assetSubcategory.id}
          isLowFloor={job.assetIsLowFloor}
        />
      </div>
      <div className={cn(styles['event-description'])}>{getJobDescription(job, day)}</div>
      <div className={cn(styles['event-indicator'])} title={getCrew(job, day)}>
        {getHasCrew(job, day) ? '🔧' : null}
      </div>
    </div>
  );

  const jobsToScheduleEventItems = () => {
    const scheduledItemMap = props.jobs
      .map(j =>
        getZonedDaysFromInterval(j.jobInterval, props.showInZone).map(d => ({
          job: j,
          day: d.toISODate(),
        }))
      )
      .reduce(
        (m, x) =>
          x.reduce(
            (_, y) =>
              m.set(y.day, [
                ...(m.get(y.day) || []),
                {
                  eventId: y.job.jobId,
                  eventTitle: y.job.assetName,
                  eventBody: getEventBody(y.job, DateTime.fromISO(y.day)),
                  eventCalloutBody: JobSchedulePopover({ job: y.job }),
                  eventCssClass: y.job.assetName,
                  eventStartDateTime: DateTime.fromISO(y.job.startDateTime),
                  eventEndDateTime: DateTime.fromISO(y.job.endDateTime),
                  eventInterval: y.job.jobInterval,
                },
              ]),
            m
          ),
        new Map<string, IScheduleEvent[]>()
      );

    scheduledItemMap.forEach(p =>
      p.sort((a, b) => intervalCompare(a.eventInterval, b.eventInterval))
    );

    return scheduledItemMap;
  };

  const {
    weekToDisplay,
    getNavigationLink,
    jobsLoading,
    workshopDepots,
    selectedWorkshopDepotId,
    changeSelectedWorkshopDepotId,
  } = props;
  return (
    <div className="weekly-job-scheduler-component">
      <div>
        <ControlPanel
          zonedNow={zonedNow}
          zonedWeekToDisplay={weekToDisplay}
          getNavigationLink={getNavigationLink}
          workshopDepots={workshopDepots}
          selectedWorkshopDepotId={selectedWorkshopDepotId}
          changeSelectedWorkshopDepotId={changeSelectedWorkshopDepotId}
        />
        <span className={cn(styles['scheduler-loading'], jobsLoading ? styles['visible'] : '')}>
          <SpinnerIcon fixedWidth />
          <small className="loading-text">Loading ...</small>
        </span>
        <ScheduleLegend full />
      </div>
      <hr />
      <WeeklySchedule
        now={props.nowUtc}
        weekToDisplay={weekToDisplay}
        timezone={props.showInZone}
        getDayLink={getDayLink}
        scheduleEvents={jobsToScheduleEventItems()}
        onEventSelected={event => setSelectedEventId(event?.eventId)}></WeeklySchedule>
    </div>
  );
};

export default Scheduler;
