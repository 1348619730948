import cn from 'classnames';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import { ConflictStatus, ConflictType } from 'src/api/enums';
import { useRootStore } from 'src/domain/entities/RootStoreModel';
import { BusIcon, CheckIcon, UndoIcon, UserIcon } from 'src/images/icons';
import { IntervalFormat } from 'src/views/components/IntervalFormat';
import ModalActionButton from 'src/views/components/Page/actions/ModalActionButton';
import { getSubmitCloseModalActionGroupDef } from 'src/views/definitionBuilders/common';
import {
  ActionType,
  IActionData,
  IModalActionButtonDef,
  PaneType,
  ShellModalSize,
} from 'src/views/definitionBuilders/types';
import './ConflictFieldItem.scss';

type OpsConflictItem = Operations.Domain.Queries.ViewJob.OpsConflictItem;
export interface IConflictFieldItemProps {
  conflict: OpsConflictItem;
}

function suppressPropagation(e: { stopPropagation: () => void }) {
  // Stop this event bubbling up the element stack, so that events in the modal do
  // not trigger actions in the underlying GanttCalendar
  e.stopPropagation();
}

export const ConflictFieldItem: React.FC<IConflictFieldItemProps> = observer(
  ({ conflict }: IConflictFieldItemProps) => {
    const rootStore = useRootStore();
    const canManageJobs = rootStore.account.isOperationsDepartmentMember;
    const onAcceptConflict = rootStore.operations.conflict.acceptConflict;
    const onCancelAcceptance = rootStore.operations.conflict.cancelAcceptanceConflict;

    const getAcceptConflictModalDef = (): IModalActionButtonDef => {
      return {
        actionType: ActionType.modalActionButton,
        label: 'Accept Conflict',
        icon: <CheckIcon />,
        modalSize: ShellModalSize.oneQuarter,
        modalDef: () => ({
          title: 'Accept Conflict',
          asForm: true,
          panels: [
            {
              panes: [
                {
                  paneType: PaneType.customPane,
                  render: () => (
                    <>
                      <p>Are you sure you want to accept the conflict?</p>
                    </>
                  ),
                },
              ],
            },
          ],
          secondaryActions: [getSubmitCloseModalActionGroupDef('Ok')],
          onFormSubmit: () => {
            return onAcceptConflict({
              conflictId: conflict.conflictId,
            });
          },
        }),
      };
    };

    const getCancelAcceptanceModalDef = (): IModalActionButtonDef => {
      return {
        actionType: ActionType.modalActionButton,
        label: 'Cancel Acceptance',
        icon: <UndoIcon />,
        modalSize: ShellModalSize.oneQuarter,
        modalDef: () => ({
          title: 'Cancel Acceptance',
          asForm: true,
          panels: [
            {
              panes: [
                {
                  paneType: PaneType.customPane,
                  render: () => 'Are you sure you want to cancel your acceptance?',
                },
              ],
            },
          ],
          secondaryActions: [getSubmitCloseModalActionGroupDef('Ok')],
          onFormSubmit: () => {
            return onCancelAcceptance(conflict.conflictId);
          },
        }),
      };
    };

    const accepted = conflict.conflictStatus.id === ConflictStatus.Accepted;
    return (
      <li className={cn('conflict-field-item conflict-item', { accepted })}>
        <span
          className="conflict-type-icon"
          title={`${conflict.conflictType.description} (${conflict.conflictStatus.description})`}>
          {conflict.conflictType.id === ConflictType.StaffMember ? <UserIcon /> : <BusIcon />}
        </span>
        <span className="conflict-summary">
          <Link to={`/operations/jobs/${conflict.jobId}`}>
            {conflict.jobType.abbreviation} {conflict.shiftName}
          </Link>
        </span>
        <IntervalFormat
          className="conflict-interval"
          startValue={conflict.conflictStart}
          endValue={conflict.conflictEnd}
        />
        <span
          className="conflict-actions"
          onWheel={suppressPropagation}
          onMouseDown={suppressPropagation}>
          {canManageJobs &&
            (accepted ? (
              <ModalActionButton
                actionDef={getCancelAcceptanceModalDef()}
                actionMeta={{ formSubmitting: false, hideLabel: true, borderless: true }}
                actionData={{} as IActionData}
              />
            ) : (
              <ModalActionButton
                actionDef={getAcceptConflictModalDef()}
                actionMeta={{ formSubmitting: false, hideLabel: true, borderless: true }}
                actionData={{} as IActionData}
              />
            ))}
        </span>
      </li>
    );
  }
);
