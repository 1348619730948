import { IAjax } from 'src/infrastructure/ajax';
import { peopleUrls } from 'src/domain/services/apiUrls';

type UpdateExternalIdentityCommand = People.Domain.Commands.ExternalIdentity.UpdateExternalIdentity.UpdateExternalIdentityCommand;

export default function init(ajax: IAjax) {
  function loadExternalIdentities(
    query: Partial<People.Domain.Queries.ListExternalIdentities.ListExternalIdentitiesQuery>
  ) {
    return ajax
      .get(peopleUrls.externalIdentityUrls.loadExternalIdentities(query))
      .map(
        r =>
          r.response as Common.Dtos.ListResult<
            People.Domain.Queries.ListExternalIdentities.ListExternalIdentityItem
          >
      );
  }

  function loadIdentityProviders() {
    return ajax
      .get(peopleUrls.externalIdentityUrls.loadExternalIdentityProviders())
      .map(
        r =>
          r.response as Common.AggregatesModel.People.ExternalIdentityAggregate.ExternalIdentityProvider[]
      )
      .toPromise();
  }

  function updateExternalIdentity(command: UpdateExternalIdentityCommand) {
    return ajax
      .put(peopleUrls.externalIdentityUrls.updateExternalIdentity(command.id), command)
      .toPromise();
  }

  function deleteExternalIdentity(noteId: string) {
    return ajax
      .httpDelete(peopleUrls.externalIdentityUrls.deleteExternalIdentity(noteId))
      .toPromise();
  }

  return {
    externalIdentity: {
      loadExternalIdentities,
      loadIdentityProviders,
      updateExternalIdentity,
      deleteExternalIdentity,
    },
  };
}
