import { ShiftProgressStatus, shiftProgressStatusDescription } from 'src/api/enums';
import React from 'react';
import cn from 'classnames';
import styles from './WorkshopShiftStatus.module.scss';

interface IWorkshopShiftStatusProps {
  status: ShiftProgressStatus;
}

const WorkshopShiftStatus: React.FC<IWorkshopShiftStatusProps> = ({ status }) => {
  if (status === ShiftProgressStatus.IncompleteShift) {
    return (
      <span className={cn(styles.warningShiftStatus)}>
        {shiftProgressStatusDescription(status)}
      </span>
    );
  }
  if (status === ShiftProgressStatus.CompleteShift) {
    return (
      <span className={cn(styles.completeShiftStatus)}>
        {shiftProgressStatusDescription(status)}
      </span>
    );
  }
  return <></>;
};

export default WorkshopShiftStatus;
