import { ISchemaRecord } from './ISchemaRecord';
import { ChangeState } from 'src/api/enums';

import AuditHistoryRecord from './AuditHistoryRecord';
import AuditHistorySection from './AuditHistorySection';
import { DateTime } from 'luxon';

// tslint:disable-next-line:no-any
export function isEmpty(value: any) {
  return value === undefined || value === null || value === '';
}

// tslint:disable-next-line:no-any
export function getObject(address: any[], object: any): any {
  if (address.length === 0 || isEmpty(object)) {
    return object;
  }
  let addr = [...address].reverse();
  let key = addr.pop();
  return getObject(addr.reverse(), object[key]);
}

export function getChanges(changes: string) {
  const object = JSON.parse(changes);
  return object;
}

// tslint:disable-next-line:no-any
export function sort(a: any, b: any, schema: ISchemaRecord) {
  const aObject = a[schema.orderKey!];
  const bObject = b[schema.orderKey!];

  const aValue = aObject ? (isEmpty(aObject.c) ? aObject.o : aObject.c) : undefined;
  const bValue = bObject ? (isEmpty(bObject.c) ? bObject.o : bObject.c) : undefined;

  if (aValue < bValue) {
    return -1;
  }

  if (aValue > bValue) {
    return 1;
  }

  return 0;
}

export function renderDiffRecord(
  // tslint:disable-next-line:no-any
  value: any,
  // tslint:disable-next-line:no-any
  changes: any,
  // tslint:disable-next-line:no-any
  address: any[],
  schema: ISchemaRecord,
  key: number,
  recordDate: DateTime
) {
  const changeState = value.s;
  let showUnchanged = false;
  if (schema.showUnchanged !== undefined) {
    showUnchanged =
      typeof schema.showUnchanged === 'function'
        ? schema.showUnchanged(changes, [...address])
        : schema.showUnchanged;
  }
  if (!showUnchanged && changeState === ChangeState.Unchanged) {
    return null;
  }
  const beforeValue = value.o;
  const afterValue = value.c;

  const isBeforeEmpty = isEmpty(beforeValue);
  const isAfterEmpty = isEmpty(afterValue);

  if (isBeforeEmpty && isAfterEmpty) {
    return null;
  }

  // Check if dates have changed ignoring timezone differences
  const beforeDate = DateTime.fromISO(value.o);
  const afterDate = DateTime.fromISO(value.c);

  if (
    // @ts-ignore
    !beforeDate.invalid &&
    // @ts-ignore
    !afterDate.invalid &&
    beforeDate.toString() === afterDate.toString()
  ) {
    return null;
  }

  return (
    <AuditHistoryRecord
      key={key}
      beforeValue={beforeValue}
      afterValue={afterValue}
      changes={changes}
      address={address}
      schema={schema}
      recordDate={recordDate}
    />
  );
}

// tslint:disable-next-line:no-any
export function orderChanges(changes: any, schema: ISchemaRecord[]) {
  let sortedChanges = {};
  const keys = Object.keys(changes);
  for (let i = 0; i < keys.length; i++) {
    const key = keys[i];
    const keySchema = schema.find(s => s.key === key) as ISchemaRecord;
    if (!keySchema || !keySchema.orderKey) {
      sortedChanges[key] = changes[key];
      continue;
    }
    const sortObject = changes[key] as [];
    // tslint:disable-next-line:no-any
    sortObject.sort((a: any, b: any) => sort(a, b, keySchema));

    if (keySchema.childRecords) {
      const childKeys = Object.keys(sortObject);
      for (let j = 0; j < childKeys.length; j++) {
        const childChanges = sortObject[childKeys[j]];
        const childSchema = keySchema.childRecords!;
        sortObject[childKeys[j]] = orderChanges(childChanges, childSchema);
      }
    }

    sortedChanges[key] = sortObject;
  }
  return sortedChanges;
}

// tslint:disable-next-line:no-any
export function renderDiffChanges(
  // tslint:disable-next-line:no-any
  changes: any,
  // tslint:disable-next-line:no-any
  allChanges: any,
  schema: ISchemaRecord[],
  // tslint:disable-next-line:no-any
  address: any[],
  recordDate: DateTime
) {
  if (!changes || !schema) {
    return null;
  }

  let records: React.ReactNode[] = [];

  for (let i = 0; i < schema.length; i++) {
    const child = schema[i];
    const hasChange = Object.keys(changes).indexOf(child.key) !== -1;
    if (!hasChange) {
      continue;
    }
    if (child.isArray) {
      let diff = changes[child.key];
      Object.keys(diff).forEach(key => {
        let arrayDiff = diff[key];
        const addr = [...address, child.key, key];
        let label = child.label || child.key;
        if (child.sectionLabel) {
          label =
            typeof child.sectionLabel === 'function'
              ? child.sectionLabel(diff, allChanges, [...addr])
              : child.sectionLabel;
        }
        const diffRender = renderDiffChanges(
          arrayDiff,
          allChanges,
          child.childRecords as ISchemaRecord[],
          [...addr],
          recordDate
        );
        if (diffRender && diffRender.length > 0) {
          records.push(
            <AuditHistorySection label={label} key={addr.join('.')}>
              {diffRender}
            </AuditHistorySection>
          );
        }
      });
    } else {
      const addr = [...address, child.key];
      const value = getObject([...addr], allChanges);
      records.push(renderDiffRecord(value, allChanges, addr, child, i, recordDate));
    }
  }

  return records.filter(x => !!x);
}
