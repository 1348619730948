import styles from './SplitScreen.module.scss';
import cn from 'classnames';

interface ISplitScreenProps {
  left: React.ReactNode;
  right: React.ReactNode;
  leftWidth?: number;
}

interface ISplitScreenCssProps extends React.CSSProperties {
  '--leftWidth': string;
  '--rightWidth': string;
}

const SplitScreen = ({ left, right, leftWidth }: ISplitScreenProps) => {
  const theme: ISplitScreenCssProps = {
    '--leftWidth': `${leftWidth || 50}%`,
    '--rightWidth': `${100 - (leftWidth || 50)}%`,
  };

  return (
    <div className={styles.splitScreen}>
      <div className={cn([styles.screen, styles.left])} style={theme}>
        {left}
      </div>
      <div className={cn([styles.screen, styles.right])} style={theme}>
        {right}
      </div>
    </div>
  );
};

export default SplitScreen;
