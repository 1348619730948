import { IAjax } from 'src/infrastructure/ajax';
import { authUrls } from '../apiUrls';

type StaffMemberDetails = Common.Queries.GetStaffMemberDetails.StaffMemberDetails;
type GetKioskUserNameAndEmailByEmployeeIdQuery = Common.Queries.GetKioskUserNameAndEmailByEmployeeId.GetKioskUserNameAndEmailByEmployeeIdQuery;

export default function init(ajax: IAjax) {
  function getProfile() {
    return ajax
      .get(authUrls.profileUrl())
      .toPromise()
      .then<Common.Authentication.CopClaimsUser>(r => r.response);
  }

  function getKioskProfile() {
    return ajax.get(authUrls.kioskProfileUrl()).toPromise();
  }

  function signInKiosk(query: GetKioskUserNameAndEmailByEmployeeIdQuery) {
    return ajax
      .post(authUrls.kioskSignInUrl(), query)
      .toPromise()
      .then<void>(() => Promise.resolve());
  }

  function signOutKiosk() {
    return ajax
      .post(authUrls.kioskSignOutUrl())
      .toPromise()
      .then<void>(() => Promise.resolve());
  }

  function getStaffMemberDetails(employeeId: string) {
    return ajax
      .get(authUrls.kioskStaffMemberDetailsUrl(employeeId))
      .toPromise()
      .then<StaffMemberDetails>(r => r.response);
  }

  return {
    user: {
      getProfile,
      getKioskProfile,
      signInKiosk,
      signOutKiosk,
      getStaffMemberDetails,
    },
  };
}
