import { types, flow } from 'mobx-state-tree';
import { getAjax } from 'src/domain/services';

type CubicFaultDetail = Workshop.Domain.AggregatesModel.AssetAggregate.CubicFaultDetail;
type CubicItem = Workshop.Domain.AggregatesModel.AssetAggregate.CubicItem;

export const AssetCubicRegisterModel = types
  .model('AssetCubicRegisterModel', {
    cubicFaultDetails: types.array(types.frozen<CubicFaultDetail>()),
    cubicItems: types.array(types.frozen<CubicItem>()),
  })
  .views(self => ({
    get activeCubicItems() {
      return self.cubicItems.filter(c => c.isActive);
    },

    get activeCubicFaultDetails() {
      return self.cubicFaultDetails.filter(c => c.isActive);
    },
  }))
  .actions(self => {
    const ajax = getAjax(self);

    const loadCubicFaultDetails = flow(function*() {
      self.cubicFaultDetails = yield ajax.kiosk.cubic.loadCubicFaults();
    });

    const loadCubicItems = flow(function*() {
      self.cubicItems = yield ajax.kiosk.cubic.loadCubicItems();
    });

    return {
      loadCubicFaultDetails,
      loadCubicItems,
    };
  });
