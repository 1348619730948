import { types, flow } from 'mobx-state-tree';
import { getAjax } from 'src/domain/services';

type SubchecklistType = Workshop.Domain.AggregatesModel.ChecklistAggregate.Subchecklist;
type UniqueNameCheckResultDto = Common.Dtos.UniqueNameCheckResultDto;

export const SubchecklistsModel = types
  .model('SubchecklistsModel', {
    items: types.array(types.frozen<SubchecklistType>()),
  })
  .actions(self => {
    const ajax = getAjax(self);

    const load = flow(function*() {
      self.items = yield ajax.checklist.getSubchecklists();
    });

    const checkForUniqueSubchecklistName = flow(function*(subchecklistName: string) {
      const dto: UniqueNameCheckResultDto = yield ajax.checklist.checkForUniqueSubchecklistName(
        subchecklistName
      );
      return dto;
    });

    return {
      load,
      checkForUniqueSubchecklistName,
    };
  });
