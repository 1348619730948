import styles from './Form.module.scss';

import { Field, FieldProps, getIn } from 'formik';
import Select from 'react-select';
import cn from 'classnames';

type Option = { id: string | number; name: string };

interface ISelectFieldProps {
  dataLabel?: string;
  dataAddress: string;
  options: Option[];
  dataValue?: Option;
  className?: string;
  readOnly?: boolean;
  clearable?: boolean;
  searchable?: boolean;
  onChangeHook?: (v?: Option) => void;
}

const SelectField: React.FC<ISelectFieldProps> = ({
  dataLabel,
  dataAddress,
  dataValue,
  options,
  className,
  readOnly,
  clearable,
  searchable,
  onChangeHook,
}) => (
  <div className={cn(styles.fieldContainer, className)}>
    {dataLabel && <div className={styles.fieldLabel}>{dataLabel}</div>}
    {readOnly ? (
      <div className={styles.selectField}>{dataValue && dataValue.name}</div>
    ) : (
      <Field name={dataAddress} value={dataValue && dataValue.id}>
        {({ field: { name, value }, form }: FieldProps) => {
          const error = getIn(form.errors, dataAddress);
          const touched = getIn(form.touched, dataAddress) || form.submitCount > 0; // https://github.com/jaredpalmer/formik/issues/691#issuecomment-446509600

          return (
            <div className={cn(styles.selectField, !!error && touched ? styles.errorMargin : '')}>
              <Select
                clearable={clearable}
                searchable={searchable}
                options={options}
                multi={false}
                onChange={v => {
                  if (onChangeHook) {
                    onChangeHook(v ? (v as Option) : undefined);
                  }
                  return form.setFieldValue(
                    name,
                    v ? (v as { name: string; id: string | number }).id : undefined
                  );
                }}
                onBlur={() => form.setFieldTouched(name, true)}
                value={value}
                matchProp={'label'}
                valueKey={'id'}
                labelKey={'name'}
              />
              {!!error && touched && <div className={styles.error}>Required</div>}
            </div>
          );
        }}
      </Field>
    )}
  </div>
);

export default SelectField;
