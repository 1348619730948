import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { useRootStore } from 'src/domain/entities/RootStoreModel';
import CrudPage, { CrudPageMode, ICrudPageDef } from 'src/views/components/Page/pages/CrudPage';
import {
  ActionType,
  FieldType,
  ITablePaneDef,
  PagePrimarySize,
  PaneType,
} from 'src/views/definitionBuilders/types';
import PageField from 'src/views/components/Page/fields/PageField';
import { CheckIcon, ExternalLinkIcon } from 'src/images/icons';

interface IMaintainCharterContractProps {
  mode: CrudPageMode;
  route: RouteComponentProps<{ [x: string]: string | undefined }>;
}

type CharterContractItem = Operations.Domain.Queries.GetCharterContract.CharterContractItem;
type CreateCharterContractCommand = Operations.Domain.Commands.CharterContract.CreateCharterContract.CreateCharterContractCommand;
type UpdateCharterContractCommand = Operations.Domain.Commands.CharterContract.UpdateCharterContract.UpdateCharterContractCommand;
type ShiftSelectListItem = Operations.Domain.Queries.GetShiftsByCharterContract.ShiftSelectListItem;

export const MaintainCharterContract: React.FC<IMaintainCharterContractProps> = observer(
  (props: IMaintainCharterContractProps) => {
    const charterContractId = props.route.match.params.id;
    const rootStore = useRootStore();
    const isUpdateMode = props.mode === 'update';
    const canManage =
      rootStore.account.isOperationsDepartmentMember || rootStore.account.isSalesDepartmentMember;

    const customersModel = rootStore.operations.sales.customer;
    const charterContractModel = rootStore.operations.charterContracts;
    const charterContract = charterContractModel.charterContract;
    const contractBookingsModel = rootStore.operations.contractCharterBookings;
    const recentContractBookingsForContract = contractBookingsModel.recentContractCharterBookingsForContract.slice();
    const shiftsModel = rootStore.operations.urban.shifts;
    const [shifts, setShifts] = useState<ShiftSelectListItem[] | undefined>(undefined);

    useEffect(() => {
      if (charterContractId) {
        charterContractModel.loadCharterContract(charterContractId);
        contractBookingsModel.getRecentCharterContractBookingsForContract(charterContractId);
        shiftsModel.getShiftsByContract(charterContractId).then(r => setShifts(r));
      }
    }, [charterContractId]);

    const handlePreSubmitForCreate = (
      values: CharterContractItem
    ): CreateCharterContractCommand => {
      return {
        name: values.name,
        description: values.description,
        internalNotes: values.internalNotes,
        customerId: values.customerId,
        startDate: values.startDate,
        endDate: values.endDate,
      };
    };

    const handlePreSubmitForUpdate = (
      values: CharterContractItem
    ): UpdateCharterContractCommand => {
      return {
        id: values.id,
        name: values.name,
        description: values.description,
        internalNotes: values.internalNotes,
        customerId: values.customerId,
        startDate: values.startDate,
        endDate: values.endDate,
      };
    };

    const getContractBookingsForContractPane = (): ITablePaneDef => {
      return {
        paneType: PaneType.tablePane,
        dataAddr: 'recentContractBookingsForContract',
        neverEditable: true,
        fields: [
          {
            fieldType: FieldType.textField,
            label: 'Booking Number',
            dataAddr: 'bookingNumber',
            linkTo: d => `/accounting/contract-charter-bookings/${d.parentValue.id}`,
            minColumnWidth: '10rem',
          },
          {
            fieldType: FieldType.dateField,
            label: 'First Job',
            dataAddr: 'firstJobDate',
            minColumnWidth: '10rem',
          },
          {
            fieldType: FieldType.textField,
            label: 'Description',
            dataAddr: 'description',
          },
          {
            fieldType: FieldType.textField,
            label: 'Completed',
            dataAddr: 'isComplete',
            formatReadonly: d => d.fieldValue && <CheckIcon />,
          },
        ],
      };
    };

    const getShiftsForContractPane = (): ITablePaneDef => {
      return {
        paneType: PaneType.tablePane,
        dataAddr: 'shifts',
        neverEditable: true,
        fields: [
          {
            fieldType: FieldType.textField,
            label: 'Name',
            dataAddr: 'name',
            linkTo: d => `/operations/shifts/${d.parentValue.id}`,
            minColumnWidth: '10rem',
          },
          {
            fieldType: FieldType.textField,
            label: 'Description',
            dataAddr: 'description',
          },
        ],
      };
    };

    const getPageDef = (): ICrudPageDef => {
      return {
        primarySize: PagePrimarySize.half,
        primarySection: {
          dataAddr: 'charterContract',
          title: isUpdateMode ? 'Manage Charter Contract' : 'Create a Charter Contract',
          primaryActions: [],
          panels: [
            {
              panes: [
                {
                  paneType: PaneType.formFieldsPane,
                  fields: [
                    {
                      fieldType: FieldType.textField,
                      label: 'Name',
                      dataAddr: 'name',
                      maxLength: 200,
                      mandatory: true,
                      validateAsync: async d => {
                        if (
                          !d.fieldValue ||
                          (isUpdateMode &&
                            charterContract &&
                            charterContract.name.toUpperCase() === d.fieldValue.toUpperCase())
                        ) {
                          return undefined;
                        }

                        const result = await charterContractModel.checkForUniqueName(d.fieldValue);
                        return result.nameExists
                          ? `Charter Contract Name is already in use`
                          : undefined;
                      },
                    },
                    {
                      fieldType: FieldType.textField,
                      label: 'Description',
                      dataAddr: 'description',
                      maxLength: 200,
                      mandatory: true,
                    },
                    {
                      fieldType: FieldType.customField,
                      dataAddr: 'customer',
                      label: '',
                      render: d => (
                        <div>
                          <PageField
                            fieldDef={{
                              fieldType: FieldType.selectAsyncField,
                              dataAddr: 'customerId',
                              label: 'Customer',
                              valueKey: 'customerId',
                              descriptionKey: 'customerName',
                              useValueOnly: true,
                              readonly: isUpdateMode,
                              mandatory: true,
                              linkTo: d => `/sales/customers/${d.parentValue.customerId}`,
                              loadOptionItems: customersModel.searchCustomers,
                              loadItems: customersModel.findCustomers,
                            }}
                            fieldMeta={d.meta}
                            paneData={d.data}
                            parentValue={d.data.parentValue}
                          />
                        </div>
                      ),
                    },
                    {
                      fieldType: FieldType.textField,
                      label: 'Internal Notes',
                      dataAddr: 'internalNotes',
                    },
                  ],
                },
                {
                  paneType: PaneType.formFieldsPane,
                  columnCount: 2,
                  fields: [
                    {
                      fieldType: FieldType.dateField,
                      label: 'Start Date',
                      dataAddr: 'startDate',
                    },
                    {
                      fieldType: FieldType.dateField,
                      label: 'End Date',
                      dataAddr: 'endDate',
                      validate: d =>
                        !!d.parentValue.startDate &&
                        !!d.parentValue.endDate &&
                        d.parentValue.endDate < d.parentValue.startDate
                          ? 'End date cannot be before start date'
                          : undefined,
                    },
                  ],
                },
              ],
            },
          ],
          onFormPreSubmit: isUpdateMode ? handlePreSubmitForUpdate : handlePreSubmitForCreate,
          onFormSubmit: isUpdateMode
            ? charterContractModel.updateCharterContract
            : charterContractModel.createCharterContract,
        },
        secondarySections: isUpdateMode
          ? [
              {
                title: 'Contract Bookings',
                hidden: !recentContractBookingsForContract,
                panels: [
                  {
                    panes: [
                      getContractBookingsForContractPane(),
                      {
                        paneType: PaneType.actionListPane,
                        actionGroups: [
                          {
                            actions: [
                              {
                                actionType: ActionType.actionLink,
                                label: 'View all Contract Bookings',
                                icon: <ExternalLinkIcon />,
                                to: `/accounting/contract-charter-bookings?contractIds=${charterContractId}&orderBy=BookingNumber~desc`,
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
              {
                title: 'Shifts',
                panels: [
                  {
                    panes: [getShiftsForContractPane()],
                  },
                ],
              },
            ]
          : [],
      };
    };

    return (
      <CrudPage
        def={getPageDef()}
        mode={props.mode}
        data={{ charterContract, recentContractBookingsForContract, shifts }}
        createDefaultData={{}}
        isEditingForbidden={!canManage}
      />
    );
  }
);
