import {
  getEditingFormattedTimeString,
  parseEditingFormattedTimeString,
} from 'src/views/components/Page/fields/subfields/TimeHelpers';
import { Duration, DateTime } from 'luxon';
import { Weekday } from 'src/domain';

export function parseDuration(value: string) {
  return parseEditingFormattedTimeString(value) as Duration;
}

function totalHoursCalc(startTime: string, stopTime: string) {
  let start = parseDuration(startTime);
  let stop = parseDuration(stopTime);
  if (stop.as('minutes') < start.as('minutes')) {
    stop = stop.plus(Duration.fromObject({ days: 1 }));
  }
  return stop.minus(start);
}

function workshopTotalHoursCalc(startTime: string, stopTime: string) {
  let start = parseDuration(startTime);
  let stop = parseDuration(stopTime);
  if (stop.as('minutes') <= start.as('minutes')) {
    stop = stop.plus(Duration.fromObject({ days: 1 }));
  }
  return stop.minus(start);
}

export function totalHours(startTime: string, stopTime: string) {
  if (!startTime || !stopTime) {
    return undefined;
  }
  return getEditingFormattedTimeString(totalHoursCalc(startTime, stopTime));
}

export function workingHours(startTime: string, stopTime: string, unpaidBreaks: string) {
  if (!startTime || !stopTime || !unpaidBreaks) {
    return undefined;
  }
  const hours = totalHoursCalc(startTime, stopTime);
  const unpaid = parseDuration(unpaidBreaks);
  return hours.minus(unpaid);
}

export function workshopWorkingHours(startTime: string, stopTime: string, unpaidBreaks: string) {
  if (!startTime || !stopTime || !unpaidBreaks) {
    return undefined;
  }
  const hours = workshopTotalHoursCalc(startTime, stopTime);
  const unpaid = parseDuration(unpaidBreaks);
  return hours.minus(unpaid);
}

function jobHours(startDateTime: string, stopDateTime: string) {
  const start = roundDownToNearestMinute(DateTime.fromISO(startDateTime));
  const stop = roundDownToNearestMinute(DateTime.fromISO(stopDateTime));
  return stop.diff(start);
}

function roundDownToNearestMinute(dateTime: DateTime) {
  return DateTime.fromObject({
    year: dateTime.year,
    month: dateTime.month,
    day: dateTime.day,
    hour: dateTime.hour,
    minute: dateTime.minute,
    zone: dateTime.zone,
  });
}

export function totalJobHours(startDateTime: string | undefined, stopDateTime: string | undefined) {
  if (!startDateTime || !stopDateTime) {
    return undefined;
  }
  return getEditingFormattedTimeString(jobHours(startDateTime, stopDateTime));
}

export function formattedWorkingJobHours(
  startDateTime: string | undefined,
  stopDateTime: string | undefined,
  unpaidBreaks: string | undefined
) {
  const duration = workingJobHours(startDateTime, stopDateTime, unpaidBreaks);
  if (!duration) {
    return undefined;
  }
  return getEditingFormattedTimeString(duration);
}

export function workingShiftHours(
  startDateTime: string | undefined,
  stopDateTime: string | undefined,
  unpaidBreaks: string | undefined,
  shiftDays: IShiftDays | undefined
) {
  if (!startDateTime || !stopDateTime || !unpaidBreaks || !shiftDays) {
    return undefined;
  }
  const workingTime = workingHours(startDateTime, stopDateTime, unpaidBreaks);
  if (!workingTime) {
    return undefined;
  }
  return workingTime.mapUnits(hours => hours * countShiftDays(shiftDays));
}

export function workingJobHours(
  startDateTime: string | undefined,
  stopDateTime: string | undefined,
  unpaidBreaks: string | undefined
) {
  if (!startDateTime || !stopDateTime || !unpaidBreaks) {
    return undefined;
  }

  const hours = jobHours(startDateTime, stopDateTime);
  const unpaid = parseDuration(unpaidBreaks);
  if (!unpaid.isValid) {
    return undefined;
  }

  const workingTime = hours.minus(unpaid);

  if (!workingTime.isValid || workingTime.valueOf() < 0) {
    return undefined;
  }

  return workingTime;
}

export function validateTime(value: string | undefined) {
  if (!value) {
    return undefined;
  }
  const minutesInDay = 1440;
  const duration = parseDuration(value);
  if (duration.as('minutes') >= minutesInDay) {
    return 'Cannot be more than 24 hours';
  }
  return undefined;
}

export function getDisplayDays(shift: IShiftDays) {
  const days: string[] = [];
  if (shift.monday) {
    days.push('Mon');
  }
  if (shift.tuesday) {
    days.push('Tue');
  }
  if (shift.wednesday) {
    days.push('Wed');
  }
  if (shift.thursday) {
    days.push('Thu');
  }
  if (shift.friday) {
    days.push('Fri');
  }
  if (shift.saturday) {
    days.push('Sat');
  }
  if (shift.sunday) {
    days.push('Sun');
  }
  return days;
}

interface IShiftDays {
  monday: boolean;
  tuesday: boolean;
  wednesday: boolean;
  thursday: boolean;
  friday: boolean;
  saturday: boolean;
  sunday: boolean;
}

export function countShiftDays(shift: IShiftDays): number {
  const shiftValues = Object.values(shift);
  const shiftCount = shiftValues.reduce((a, c) => a + (c === true ? 1 : 0), 0);
  return shiftCount;
}

export function getDays(shift: IShiftDays): Weekday[] {
  const days: Weekday[] = [];
  if (shift.monday) {
    days.push(Weekday.mon);
  }
  if (shift.tuesday) {
    days.push(Weekday.tue);
  }
  if (shift.wednesday) {
    days.push(Weekday.wed);
  }
  if (shift.thursday) {
    days.push(Weekday.thu);
  }
  if (shift.friday) {
    days.push(Weekday.fri);
  }
  if (shift.saturday) {
    days.push(Weekday.sat);
  }
  if (shift.sunday) {
    days.push(Weekday.sun);
  }
  return days;
}

export const daySelected = (values: number[], day: Weekday): boolean => {
  return values.findIndex(value => value === day) !== -1;
};

export function getDaysToExclude(shift: IShiftDays): Weekday[] {
  const days: Weekday[] = [];
  if (!shift.monday) {
    days.push(Weekday.mon);
  }
  if (!shift.tuesday) {
    days.push(Weekday.tue);
  }
  if (!shift.wednesday) {
    days.push(Weekday.wed);
  }
  if (!shift.thursday) {
    days.push(Weekday.thu);
  }
  if (!shift.friday) {
    days.push(Weekday.fri);
  }
  if (!shift.saturday) {
    days.push(Weekday.sat);
  }
  if (!shift.sunday) {
    days.push(Weekday.sun);
  }
  return days;
}
