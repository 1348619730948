import styles from '../AssetDetails.module.scss';
import { AssetField } from './AssetField';

type AssetDetailsData = Workshop.Domain.Queries.GetAsset.AssetDetails;

export const AssetDetailsDisplay: React.FC<{ asset?: AssetDetailsData }> = ({ asset }) => {
  return asset ? (
    <div>
      <div className={styles.assetDetails}>
        <div className={styles.row}>
          <AssetField label="Category" text={asset.category && asset.category.description} />
          <AssetField
            label="Subcategory"
            text={asset.subcategory && asset.subcategory.description}
          />
        </div>
        <div className={styles.row}>
          <AssetField
            label="Vehicle Type"
            text={asset.vehicleType && asset.vehicleType.description}
          />
          <AssetField label="Is Low Floor" text={asset.isLowFloor ? 'Yes' : 'No'} />
        </div>
        <div className={styles.row}>
          <AssetField label="Name" text={asset.name} />
          <AssetField label="Owner" text={asset.owner && asset.owner.name} />
        </div>
        <div className={styles.row}>
          <AssetField
            label="Garaged at"
            text={asset.housingLocation && asset.housingLocation.description}
          />
          <AssetField label="Asset Group" text={asset.assetGroup && asset.assetGroup.description} />
        </div>
        <div className={styles.row}>
          <AssetField label="Engine Model" text={asset.engineModel && asset.engineModel.model} />
        </div>
        <div className={styles.row}>
          <AssetField label="Seat Capacity" text={asset.seatCapacity} />
        </div>
      </div>
    </div>
  ) : (
    <div>No Asset</div>
  );
};
