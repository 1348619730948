import './ShellProfile.scss';
import { SignOutIcon, BookIcon } from 'src/images/icons';
import Button from 'reactstrap/lib/Button';
import { KNOWLEDGE_BASE_LINK } from 'src/appSettings';

interface IShellProfileProps {
  className?: string;
  userDisplayName: string;
  userEmail?: string;
  onSignOut: () => void;
  isNavOpen: boolean;
}

export const ShellProfile: React.FC<IShellProfileProps> = ({
  className,
  userDisplayName,
  userEmail,
  onSignOut,
  isNavOpen,
}) => {
  const getFirstLetters = (str: string) =>
    str
      .split(' ')
      .map(word => word[0])
      .join('');

  const username = isNavOpen ? userDisplayName : getFirstLetters(userDisplayName);
  return (
    <>
      <div className={`shell-knowledgeBase-component nav-section ${className || ''}`}>
        <a className="nav-item" href={KNOWLEDGE_BASE_LINK} target="_blank" rel="noreferrer">
          <BookIcon />
          <span> Knowledge Base</span>
        </a>
        <p title={userEmail}>{username}</p>
      </div>
      <div className={`shell-profile-component nav-section ${className || ''}`}>
        <Button onClick={onSignOut} title="Sign Out">
          <SignOutIcon />
          <span>Log out</span>
        </Button>
      </div>
    </>
  );
};

export default ShellProfile;
