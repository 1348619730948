import { ActionType, IActionGroupDef, ActionDefs } from './types';

export function getSubmitCloseModalActionGroupDef(primaryLabel?: string): IActionGroupDef {
  return {
    actions: getSubmitCloseModalActionDefs(primaryLabel),
  };
}

export function getSubmitCloseModalActionDefs(primaryLabel?: string): Array<ActionDefs> {
  return [
    {
      actionType: ActionType.submitActionButton,
      label: primaryLabel || 'Ok',
      level: 'primary',
    },
    {
      actionType: ActionType.closeModalActionButton,
      label: 'Cancel',
    },
  ];
}
