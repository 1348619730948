import Spinner from 'src/views/components/Spinner';
import styles from './DashboardTileCollection.module.scss';

export interface IDashboardTileCollectionProps {
  isLoading: boolean;
  title?: string;
  children: React.ReactNodeArray | React.ReactNode;
}

const DashboardTileCollection = ({ isLoading, title, children }: IDashboardTileCollectionProps) => (
  <Spinner show={isLoading} className={styles.dashboardTileCollection}>
    <div>
      <h2>{title}</h2>
    </div>
    <div className={styles.tiles}>{children}</div>
  </Spinner>
);

export default DashboardTileCollection;
