import styles from './CompleteShift.module.scss';
import { observer } from 'mobx-react';
import { RouteComponentProps } from 'react-router-dom';
import Header from '../../components/presentational/Header/Header';
import { useKioskRootStore } from 'src/domain/entities/KioskRootStoreModel';
import { useEffect, useState } from 'react';
import { FormGroup, Label, Input, FormFeedback, Button } from 'reactstrap';
import { DateTime } from 'luxon';
import { DateTimeFormatSettings } from 'src/views/components/DateTimeFormat/DateTimeFormat';
import TimeInput from 'src/views/components/Page/fields/subfields/TimeInput';
import DateTimeInput from 'src/views/components/calendar/DateTimeInput';
import { formattedWorkingJobHours } from 'src/views/routes/operations/urban/urbanHelpers';
import cn from 'classnames';
import {
  parseEditingFormattedDurationString,
  getEditingFormattedTimeString,
} from 'src/views/components/Page/fields/subfields/TimeHelpers';
import React from 'react';
import { parseEditingFormattedTimeString } from '../../../components/Page/fields/subfields/TimeHelpers';

type ShiftDetails = Workshop.Domain.Queries.GetShiftDetailForKiosk.ShiftDetails;

interface ICompleteShiftProps {
  mode: 'create' | 'view';
}

interface ICompleteShiftRouteParams {
  id: string;
}

type InternalProps = ICompleteShiftProps & RouteComponentProps<ICompleteShiftRouteParams>;

interface ICompleteShiftFormState {
  id: string;
  clockOn: string;
  clockOff: string;
  totalBreaks: string;
  workingTime: string;
  overtimeReason: string;
}

const initialCompleteShiftFormState: ICompleteShiftFormState = {
  id: '',
  clockOn: '',
  clockOff: '',
  totalBreaks: '',
  workingTime: '',
  overtimeReason: '',
};

const CompleteShift: React.FC<InternalProps> = observer((props: InternalProps) => {
  const rootStore = useKioskRootStore();

  const shiftModel = rootStore.kiosk.workshop.shift;
  const loadShift = shiftModel.loadShiftDetail;
  const shift = shiftModel.shiftDetail;
  const shiftId = props.match.params.id;

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [errors, setErrors] = useState<any>({});
  const [shiftForm, setShiftForm] = useState<ICompleteShiftFormState>(
    initialCompleteShiftFormState
  );

  const getTime = (time: string | undefined) => {
    const duration = parseEditingFormattedTimeString(time);
    if (!duration || !duration.isValid) {
      return '';
    }
    return getEditingFormattedTimeString(duration);
  };

  useEffect(() => {
    loadShift(shiftId).then((currentShift: ShiftDetails | undefined) => {
      if (currentShift) {
        if (isReadOnly) {
          setShiftForm({
            ...initialCompleteShiftFormState,
            id: currentShift.shiftId,
            clockOn: currentShift.clockOn ?? currentShift.shiftStart,
            clockOff: currentShift.clockOff ?? currentShift.shiftEnd,
            totalBreaks: getTime(currentShift.totalBreaks),
            overtimeReason: currentShift.overtimeReason,
            workingTime: calculateWorkingTime(
              currentShift.clockOn ?? currentShift.shiftStart,
              currentShift.clockOff ?? currentShift.shiftEnd,
              getTime(currentShift.totalBreaks)
            ),
          });
        } else {
          setShiftForm({
            id: currentShift.shiftId,
            clockOn: currentShift.shiftStart,
            clockOff: currentShift.shiftEnd,
            totalBreaks: getTime(currentShift.unpaidBreaks),
            overtimeReason: currentShift.overtimeReason,
            workingTime: calculateWorkingTime(
              currentShift.shiftStart,
              currentShift.shiftEnd,
              getTime(currentShift.unpaidBreaks)
            ),
          });
        }
      }
    });
  }, []);

  const calculateWorkingTime = (
    clockOn: string | undefined,
    clockOff: string | undefined,
    totalBreaks: string | undefined
  ) => {
    return formattedWorkingJobHours(clockOn, clockOff, totalBreaks || '00:00') || '';
  };

  const isReadOnly = props.mode === 'view';

  const validateClockOff = () => {
    const clockOnDateTime = DateTime.fromISO(shiftForm.clockOn);
    const clockOffDateTime = DateTime.fromISO(shiftForm.clockOff);
    if (!clockOffDateTime.isValid) {
      return 'Clock off is required';
    }

    if (clockOnDateTime.isValid && clockOffDateTime.isValid && clockOffDateTime < clockOnDateTime) {
      return 'Clock off cannot be less than clock on';
    }

    return undefined;
  };

  const validateTotalBreaks = () => {
    const clockOnDateTime = DateTime.fromISO(shiftForm.clockOn);
    const clockOffDateTime = DateTime.fromISO(shiftForm.clockOff);
    const totalBreaksDuration = parseEditingFormattedDurationString(shiftForm.totalBreaks);
    if (
      totalBreaksDuration &&
      totalBreaksDuration.isValid &&
      clockOnDateTime.isValid &&
      clockOffDateTime.isValid &&
      clockOffDateTime.diff(clockOnDateTime).as('minutes') < totalBreaksDuration.as('minutes')
    ) {
      return 'Total breaks cannot be greater than total time';
    }

    return undefined;
  };

  const areHoursChanged = () => {
    if (!shift) {
      return false;
    }

    const plannedWorkingTime = parseEditingFormattedDurationString(
      calculateWorkingTime(shift.shiftStart, shift.shiftEnd, shift.unpaidBreaks)
    );

    const currentWorkingTime = parseEditingFormattedDurationString(
      calculateWorkingTime(shiftForm.clockOn, shiftForm.clockOff, shiftForm.totalBreaks)
    );

    return !!plannedWorkingTime && !!currentWorkingTime && plannedWorkingTime < currentWorkingTime;
  };

  const validate = () => {
    return {
      clockOn: !DateTime.fromISO(shiftForm.clockOn).isValid ? 'Clock On is required' : undefined,
      clockOff: validateClockOff(),
      totalBreaks: validateTotalBreaks(),
      overtimeReason:
        areHoursChanged() && !shiftForm.overtimeReason ? 'Overtime reason is required' : undefined,
    };
  };

  const validateForm = (updateFields?: string[]) => {
    let errors = validate();
    if (updateFields && updateFields.length > 0) {
      Object.keys(errors).forEach(key => {
        if (updateFields.indexOf(key) === -1) {
          errors[key] = undefined;
        }
      });
    }

    setErrors(errors);

    const msgs = Object.keys(errors)
      .map(key => {
        return errors[key];
      })

      .filter(val => !!val);

    return msgs.length === 0;
  };

  const handleSubmit = (event: React.FormEvent<EventTarget>) => {
    event.preventDefault();
    if (!validateForm()) {
      return;
    }

    setIsSubmitting(true);

    const shiftDetail = {
      shiftId: shiftForm.id,
      clockOn: shiftForm.clockOn,
      clockOff: shiftForm.clockOff,
      totalBreaks: shiftForm.totalBreaks,
      overtimeReason: shiftForm.overtimeReason,
    };

    shiftModel.completeShift(shiftDetail).finally(() => setIsSubmitting(false));
  };
  return (
    <>
      <Header title={`${shift?.name}`} to="/wskiosk" hideMenu />
      <form className={styles.completeShiftForm} onSubmit={values => handleSubmit(values)}>
        <div>
          <div className={styles.time}>
            <div className={styles.timeTitle}>
              <h3>Time</h3>
            </div>
            <div className={styles.clockOn}>
              <FormGroup>
                <Label for="clockOn">Clock On</Label>
                {isReadOnly ? (
                  <Input
                    disabled
                    type="text"
                    className={styles.readOnly}
                    name="clockOn"
                    id="clockOn"
                    value={DateTime.fromISO(shiftForm.clockOn).toLocaleString(
                      DateTimeFormatSettings
                    )}
                  />
                ) : (
                  <DateTimeInput
                    onChange={value => {
                      setShiftForm({
                        ...shiftForm,
                        clockOn: value,
                        workingTime: calculateWorkingTime(
                          value,
                          shiftForm.clockOff,
                          shiftForm.totalBreaks
                        ),
                      });
                    }}
                    value={shiftForm.clockOn}
                  />
                )}
                <FormFeedback className={styles.error}>{errors.clockOn}</FormFeedback>
              </FormGroup>
            </div>
            <div className={styles.clockOff}>
              <FormGroup>
                <Label for="clockOffOrFinish">Clock Off</Label>
                {isReadOnly ? (
                  <Input
                    disabled
                    type="text"
                    name="clockOff"
                    id="clockOff"
                    className={styles.readOnly}
                    value={DateTime.fromISO(shiftForm.clockOff).toLocaleString(
                      DateTimeFormatSettings
                    )}
                  />
                ) : (
                  <DateTimeInput
                    onChange={value => {
                      setShiftForm({
                        ...shiftForm,
                        clockOff: value,
                        workingTime: calculateWorkingTime(
                          shiftForm.clockOn,
                          value,
                          shiftForm.totalBreaks
                        ),
                      });
                    }}
                    value={shiftForm.clockOff}
                  />
                )}
                <FormFeedback className={styles.error}>{errors.clockOff}</FormFeedback>
              </FormGroup>
            </div>

            <div className={styles.totalBreaks}>
              <FormGroup>
                <Label for="totalBreaks">Total Breaks</Label>
                {isReadOnly ? (
                  <Input
                    disabled
                    type="text"
                    name="totalBreaks"
                    id="totalBreaks"
                    className={styles.readOnly}
                    value={shiftForm.totalBreaks ?? '00:00'}
                  />
                ) : (
                  <TimeInput
                    // allowSeconds={false}
                    onChange={value => {
                      setShiftForm({
                        ...shiftForm,
                        totalBreaks: value,
                        workingTime: calculateWorkingTime(
                          shiftForm.clockOn,
                          shiftForm.clockOff,
                          value
                        ),
                      });
                    }}
                    value={shiftForm.totalBreaks ?? '00:00'}
                  />
                )}
                <FormFeedback className={styles.error}>{errors.totalBreaks}</FormFeedback>
              </FormGroup>
            </div>
            <div className={styles.workingTime}>
              <FormGroup>
                <Label for="workingTime">Working Time</Label>
                <Input
                  type="text"
                  name="workingTime"
                  id="workingTime"
                  className={styles.readOnly}
                  value={shiftForm.workingTime}
                  disabled
                />
              </FormGroup>
            </div>
          </div>

          {areHoursChanged() && (
            <div className={styles.reasonForDifference}>
              <h3>Reason for time difference</h3>
              <p>
                Hours entered are greater than allocated hours. Please provide a reason for that
                difference.
              </p>

              <FormGroup>
                <Label for="overtimeReason">Reason For Difference*</Label>
                <Input
                  disabled={isReadOnly}
                  className={cn({ readonly: isReadOnly })}
                  type="text"
                  name="overtimeReason"
                  id="overtimeReason"
                  onChange={e => {
                    setShiftForm({ ...shiftForm, overtimeReason: e.currentTarget.value });
                  }}
                  value={shiftForm.overtimeReason}
                />
                {errors.reasonForDifference && (
                  <FormFeedback className={styles.error}>{errors.overtimeReason}</FormFeedback>
                )}
              </FormGroup>
            </div>
          )}

          {!isReadOnly && (
            <div className={styles.submit}>
              <Button disabled={isSubmitting} color="primary" type="submit" size="lg">
                Submit
              </Button>
            </div>
          )}
        </div>
      </form>
    </>
  );
});

export default CompleteShift;
