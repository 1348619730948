import './Textarea.scss';

import { Input, FormGroup, Label, FormFeedback } from 'reactstrap';

interface ITextareaProps {
  label: string;
  dataAddr: string;
  onChange: (value: string) => void;
  value: string;
  errors: string;
  labelClassName?: string;
  inputClassName?: string;
}

const Textarea: React.FC<ITextareaProps> = ({
  label,
  dataAddr,
  onChange,
  value,
  errors,
  inputClassName,
  labelClassName,
}) => {
  return (
    <FormGroup className="textarea-component">
      {label && (
        <Label for={dataAddr} className={labelClassName}>
          {label}
        </Label>
      )}
      <Input
        className={inputClassName}
        autoComplete="off"
        type="textarea"
        name={dataAddr}
        id={dataAddr}
        onChange={event => onChange(event.target.value)}
        value={value}
      />
      <FormFeedback>{errors}</FormFeedback>
    </FormGroup>
  );
};

export default Textarea;
