import { types, flow, getRoot } from 'mobx-state-tree';
import { getAjax } from 'src/domain/services/storeEnvironment';
import { NotificationType } from 'src/domain';
import { IKioskRootStoreModel } from '../../KioskRootStoreModel';
type DefectListItem = Workshop.Domain.Queries.Defect.DefectListItem;
type RecentDefectItem = Workshop.Domain.Queries.Defect.GetRecentDefectsForDepot.RecentDefectItem;

// tslint:disable-next-line:no-empty
const returnVoid = () => {};

export const DefectModel = types
  .model('DefectModel', {
    defects: types.array(types.frozen<DefectListItem>()),
    recentDefects: types.array(types.frozen<RecentDefectItem>()),
  })
  .actions(self => {
    const ajax = getAjax(self);
    const root = getRoot(self) as IKioskRootStoreModel;

    const loadOpenRelatedDefectsToAsset = flow(function*(assetId?: string) {
      self.defects.clear();
      if (assetId) {
        self.defects = yield ajax.raw
          .get(`/api/kiosk/assets/${assetId}/defects`)
          .toPromise()
          .then<DefectListItem[]>(r => r.response);
      }
    });

    const createDefect = flow(function*(
      command: Workshop.Domain.Commands.Defect.CreateDefectFromDriver.CreateDefectFromDriverCommand
    ) {
      yield ajax.raw
        .post(`/api/kiosk/defects`, command)
        .toPromise()
        .then<void>(r => returnVoid());
      root.notifications.addNotification('Defect successfully raised', {
        type: NotificationType.info,
      });
    });

    const loadRecentDefects = flow(function*(max: number, depotId: number) {
      self.recentDefects.clear();
      self.recentDefects = yield ajax.raw
        .get(`/api/kiosk/defects/recent/${depotId}?maxResults=${max}`)
        .toPromise()
        .then<RecentDefectItem[]>(r => r.response);
    });

    return {
      loadOpenRelatedDefectsToAsset,
      createDefect,
      loadRecentDefects,
    };
  });
