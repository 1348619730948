import { types, flow } from 'mobx-state-tree';
import { getAjax } from 'src/domain/services';
import { DateTime } from 'luxon';

type JobsForAsset = Workshop.Domain.Queries.Job.GetJobsForAsset.JobsForAsset;
type DailyRunSheetItem = Workshop.Domain.Queries.GetDailyRunSheet.DailyRunSheetItem;
type JobSummaryDto = Workshop.Domain.Queries.Job.JobSummaryDto;

export interface IJobListItem {
  assetId: string;
  assetName: string;
  endDateTime: DateTime;
  jobId: string;
  jobNumber: number;
  startDateTime: DateTime;
}

export const JobsModel = types
  .model('JobsModel', {
    jobListItems: types.array(types.frozen<IJobListItem>()),
    jobsForAsset: types.maybe(types.frozen<JobsForAsset>()),
    dailyRunSheetItem: types.maybe(types.frozen<DailyRunSheetItem>()),
    overdueJobsList: types.array(types.frozen<JobSummaryDto>()),
    dailyRunSheetIsLoading: types.optional(types.boolean, false),
  })
  .actions(self => {
    const ajax = getAjax(self);

    const loadJobsForAsset = flow(function*(assetId: string) {
      self.jobsForAsset = yield ajax.job.getJobsForAsset(assetId);
    });

    const loadDailyRunSheet = flow(function*(date: DateTime, depotId: number) {
      try {
        self.dailyRunSheetIsLoading = true;
        self.dailyRunSheetItem = yield ajax.job.loadDailyRunSheet(date.startOf('day'), depotId);
      } finally {
        self.dailyRunSheetIsLoading = false;
      }
    });

    return { loadJobsForAsset, loadDailyRunSheet };
  });

type IJobsModelType = typeof JobsModel.Type;
export interface IJobsModel extends IJobsModelType {}
