import styles from './JobsList.module.scss';
import { JobFieldFillOut } from 'src/views/kioskRoutes/operationsKiosk/home/PrintDailySummary/components/JobFieldFillOut';

const Job: React.FC = () => {
  return (
    <div className={styles.job}>
      <table>
        <tbody>
          <tr>
            <JobFieldFillOut label="Clock On" />
            <JobFieldFillOut label="Depart Depot" />
            <JobFieldFillOut label="Job Type" />
          </tr>
          <tr>
            <JobFieldFillOut label="Shift Name" />
            <JobFieldFillOut label="Route / Customer" />
            <JobFieldFillOut label="Veh Num" />
          </tr>
          <tr>
            <JobFieldFillOut label="Odom Start" />
            <JobFieldFillOut label="Odom Finish" />
            <JobFieldFillOut label="Total Kms" />
          </tr>
          <tr>
            <JobFieldFillOut label="Start Time" />
            <JobFieldFillOut label="Finish Time" />
            <JobFieldFillOut label="Total Time" />
          </tr>
          <tr>
            <JobFieldFillOut label="Fuel" />
            <JobFieldFillOut label="Adblue" />
            <JobFieldFillOut label="Total Breaks" />
          </tr>
        </tbody>
      </table>
    </div>
  );
};

const EmptyJobsTable: React.FC = () => {
  return (
    <>
      <Job />
      <Job />
    </>
  );
};

export default EmptyJobsTable;
