import { DateTime } from 'luxon';
import { ChangeState, JobBulkUpdateType } from 'src/api/enums';
import { getOverlappingDates } from 'src/domain/dateHelper';
import { PlusIcon } from 'src/images/icons';
import { validateDateTimeIsNotLessThan } from 'src/infrastructure/dateUtils';
import deepEqual from 'src/infrastructure/deepEqual';
import { getSubmitCloseModalActionGroupDef } from 'src/views/definitionBuilders/common';
import {
  ActionType,
  FieldType,
  IPanelDef,
  ModalDefBuilder,
  PaneType,
  ShellModalSize,
} from 'src/views/definitionBuilders/types';
import { formattedWorkingJobHours } from 'src/views/routes/operations/urban/urbanHelpers';
import { isJobTypeCharterOrContractCharter } from '../../shared/jobTypeHelpers';
import getGenerateFatigueBreaksModalDef from './getGenerateFatigueBreaksModalDef';
import { JobProps } from './getScheduledBreaksSectionDef';

type JobScheduledBreaksResult = Operations.Domain.Queries.GetJobScheduledBreaks.JobScheduledBreaksResult;
type UpdateJobScheduledBreakCommand = Operations.Domain.Commands.Job.UpdateJobScheduledBreak.UpdateJobScheduledBreakCommand;
type JobScheduledBreakItem = Operations.Domain.Queries.GetJobScheduledBreaks.JobScheduledBreakItem;
type JobScheduledBreakItemUpdate = Operations.Domain.Commands.Job.UpdateJobScheduledBreak.JobScheduledBreakItem;

export interface ScheduledBreaksForm {
  driverOne: JobScheduledBreakItem[];
  driverTwo: JobScheduledBreakItem[];
}

export default function getCreateScheduledBreakModalDef(
  jobProps: JobProps,
  jobScheduledBreaks: JobScheduledBreaksResult | undefined,
  hasTwoDrivers: boolean,
  updateScheduledBreaks: (command: UpdateJobScheduledBreakCommand) => Promise<void>,
  canUpdateAllForTrip: boolean,
  jobRoutes?: Operations.Domain.Queries.ViewJob.JobRouteItem[]
): ModalDefBuilder {
  return () => {
    const getChangeState = (
      jobScheduleBreak: JobScheduledBreakItem,
      originalJobScheduledBreaks: JobScheduledBreakItem[]
    ) => {
      if (
        jobScheduleBreak.changeState === ChangeState.Added ||
        jobScheduleBreak.changeState === ChangeState.Deleted
      ) {
        return jobScheduleBreak.changeState;
      }
      const originalJobScheduleBreak = originalJobScheduledBreaks?.find(
        (breakItem: JobScheduledBreakItem) => breakItem.id === jobScheduleBreak.id
      );

      return deepEqual(jobScheduleBreak, originalJobScheduleBreak, { strict: true })
        ? ChangeState.Unchanged
        : ChangeState.Modified;
    };

    return {
      title: 'Scheduled Breaks',
      asForm: true,
      explicitData: {
        driverOne: jobScheduledBreaks?.scheduledBreaksDriver1 ?? [],
        driverTwo: jobScheduledBreaks?.scheduledBreaksDriver2 ?? [],
      } as ScheduledBreaksForm,
      onFormPreSubmit: (
        form: ScheduledBreaksForm,
        submissionMeta: any
      ): UpdateJobScheduledBreakCommand => {
        let driverOne = (form.driverOne ?? []).map((breakItem: JobScheduledBreakItem) => {
          return {
            ...breakItem,
            changeState: getChangeState(
              breakItem,
              jobScheduledBreaks?.scheduledBreaksDriver1 ?? []
            ),
            isSecondDriver: false,
          } as JobScheduledBreakItemUpdate;
        });
        let driverTwo = (form.driverTwo ?? []).map((breakItem: JobScheduledBreakItem) => {
          return {
            ...breakItem,
            changeState: getChangeState(
              breakItem,
              jobScheduledBreaks?.scheduledBreaksDriver2 ?? []
            ),
            isSecondDriver: true,
          } as JobScheduledBreakItemUpdate;
        });

        return {
          jobId: jobProps.jobId,
          bulkUpdate: submissionMeta?.bulkUpdate ?? JobBulkUpdateType.NoBulkUpdate,
          jobScheduledBreakItems: driverOne.concat(driverTwo),
        } as UpdateJobScheduledBreakCommand;
      },
      onFormSubmit: (data: UpdateJobScheduledBreakCommand) => {
        return updateScheduledBreaks(data);
      },
      panels: [
        getBreakPanelDef(
          'driverOne',
          hasTwoDrivers ? 'Driver 1' : undefined,
          jobProps,
          false,
          jobRoutes
        ),
        getBreakPanelDef('driverTwo', 'Driver 2', jobProps, !hasTwoDrivers, jobRoutes),
      ],
      secondaryActions: [
        {
          actions: [
            {
              actionType: ActionType.submitActionButton,
              label: 'Update All For Trip',
              level: 'primary',
              hidden: canUpdateAllForTrip,
              confirmationModalSize: ShellModalSize.oneThird,
              confirmationModalDef: modalDefApi => ({
                title: 'Update All Jobs For This Trip',
                asForm: true,
                panels: [
                  {
                    panes: [
                      {
                        paneType: PaneType.customPane,
                        render: () => {
                          return (
                            <div>
                              <p>
                                Scheduled breaks for all Jobs on this Trip will be updated to match
                                this job. Any custom change in the individual Jobs will be
                                overwritten.
                              </p>
                              {jobProps.splitJobsInTripCount > 0 ? (
                                <p>
                                  Note that this action does not affect split jobs, of which there
                                  are {jobProps.splitJobsInTripCount} in this trip.
                                </p>
                              ) : null}
                              <p>
                                Are you sure you want to update all{' '}
                                {jobProps.nonSplitJobsInTripCount} Jobs?
                              </p>
                            </div>
                          );
                        },
                      },
                    ],
                  },
                ],
                secondaryActions: [
                  getSubmitCloseModalActionGroupDef(
                    `Update ${jobProps.nonSplitJobsInTripCount} Jobs`
                  ),
                ],
                onFormSubmit: () => {
                  return modalDefApi.parentFormApi.submitForm({
                    bulkUpdate: JobBulkUpdateType.JobForTripsFromSameBooking,
                  });
                },
              }),
            },
          ],
        },
        getSubmitCloseModalActionGroupDef('Submit'),
      ],
    };
  };
}

function getBreakPanelDef(
  dataAddr: string,
  title: string | undefined,
  jobProps: JobProps,
  hidden?: boolean,
  jobRoutes?: Operations.Domain.Queries.ViewJob.JobRouteItem[]
) {
  return {
    dataAddr: dataAddr,
    hidden: hidden,
    panes: [
      {
        paneType: PaneType.tablePane,
        title: title,
        dataRequiredForRows: 'sectionValue',
        validate: d => {
          const values = d.paneValue
            .filter((d: JobScheduledBreakItem) => d.changeState !== ChangeState.Deleted)
            .map((d: JobScheduledBreakItem) => ({
              breakStart: DateTime.fromISO(d.breakStart),
              breakEnd: DateTime.fromISO(d.breakEnd),
              changeState: d.changeState,
            }));

          const overlappingDates = getOverlappingDates(values);

          if (overlappingDates.length) {
            return 'Breaks cannot overlap with any other breaks';
          }

          return undefined;
        },
        fields: [
          {
            fieldType: FieldType.dateTimeField,
            dataAddr: ['breakStart'],
            label: 'Start',
            mandatory: true,
          },
          {
            fieldType: FieldType.dateTimeField,
            dataAddr: ['breakEnd'],
            label: 'End',
            mandatory: true,
            validate: d => {
              const lessThanValidation = validateDateTimeIsNotLessThan(
                d.fieldValue,
                'Break End',
                d.parentValue.breakStart,
                'Break Start'
              );

              if (lessThanValidation) {
                return lessThanValidation;
              }

              return undefined;
            },
          },
          {
            fieldType: FieldType.textField,
            dataAddr: ['totalBreakDuration'],
            label: 'Duration',
            readonly: true,
            formatReadonly: data => {
              if (!data.parentValue.breakStart && !data.parentValue.breakEnd) return undefined;

              return formattedWorkingJobHours(
                data.parentValue.breakStart,
                data.parentValue.breakEnd,
                '00:00'
              );
            },
          },
          {
            fieldType: FieldType.actionListField,
            columnWidth: '1px',
            actionGroups: [
              {
                actions: [
                  {
                    actionType: ActionType.removeArrayItemActionButton,
                    label: 'Remove Break',
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        paneType: PaneType.actionListPane,
        actionGroups: [
          {
            actions: [
              {
                actionType: ActionType.addArrayItemActionButton,
                label: 'Add Break',
              },
              {
                actionType: ActionType.modalActionButton,
                label: 'Add Predefined Fatigue Breaks',
                hidden:
                  jobProps?.jobTypeId && !isJobTypeCharterOrContractCharter(jobProps.jobTypeId),
                icon: <PlusIcon />,
                level: 'primary',
                modalSize: ShellModalSize.oneThird,
                modalDef: getGenerateFatigueBreaksModalDef(dataAddr, jobRoutes),
              },
            ],
          },
        ],
      },
    ],
  } as IPanelDef;
}
