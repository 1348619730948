import inject from 'src/views/injectFromStore';
import Omit from 'src/infrastructure/omit';
import MaintainShiftTemplate, { IMaintainShiftTemplate } from './MaintainShiftTemplate';

const Container = inject<Omit<IMaintainShiftTemplate, 'mode'>, IMaintainShiftTemplate>(
  (allStores, p) => ({
    canManageShiftTemplates: allStores.rootStore.account.isWorkshopDepartmentMember,

    loadShiftTemplate: allStores.rootStore.workshop.shiftTemplate.loadShiftTemplate,
    shiftTemplate: allStores.rootStore.workshop.shiftTemplate.shiftTemplate,
    checkForUniqueShiftTemplateName:
      allStores.rootStore.workshop.shiftTemplate.checkForUniqueShiftTemplateName,
    createShiftTemplate: allStores.rootStore.workshop.shiftTemplate.createShiftTemplate,
    updateShiftTemplate: allStores.rootStore.workshop.shiftTemplate.updateShiftTemplate,

    deleteShiftTemplate: allStores.rootStore.workshop.shiftTemplate.deleteShiftTemplate,
  })
)(MaintainShiftTemplate);

export default Container;
