import { buildListPageApiSearchModelTypedQuery } from 'src/domain/modelBuilders/buildListPageApiSearchModel';
import { getAjax, NotificationType } from 'src/domain/services';
import { getRoot, flow } from 'mobx-state-tree';
import * as queryString from 'query-string';
import { IRootStoreModel } from 'src/domain/entities/RootStoreModel';

type ListReportableEventsQuery = Operations.Domain.Queries.ListReportableEvents.ListReportableEventsQuery;
type ListReportableEventsForExcelQuery = Operations.Domain.Queries.ListReportableEventsForExcel.ListReportableEventsForExcelQuery;

export const ListReportableEventsModel = buildListPageApiSearchModelTypedQuery<
  ListReportableEventsQuery
>()('ListReportableEventsModel', d =>
  d.ajax.compliance.reportableEvent.listReportableEvents(d.query)
).actions(self => {
  const ajax = getAjax(self);
  const root = getRoot(self) as IRootStoreModel;

  const exportToExcel = flow(function*(query: Partial<ListReportableEventsForExcelQuery>) {
    root.notifications.addNotification(`The file is being generated ...`, {
      type: NotificationType.info,
    });

    return yield ajax.raw
      .getFile(`/api/compliance/reportable-events/excel?${queryString.stringify(query)}`)
      .toPromise()
      .then<Blob>(r => r.response);
  });

  return {
    exportToExcel,
  };
});
