import inject from 'src/views/injectFromStore';
import Shell, { IShellProps } from './Shell';
import { getShellNavItems } from 'src/views/shellNavItems';
import { RENDER_SMS_NAV_ELEMENTS } from 'src/appSettings';
import { getBus } from 'src/domain/services';

const Container = inject<IShellProps>((allStores, p) => ({
  onSignOut: allStores.rootStore.account.signOut,

  items: getShellNavItems(
    allStores.rootStore.account.isTimesheetsDepartmentMember,
    allStores.rootStore.account.isAccountingDepartmentMember,
    RENDER_SMS_NAV_ELEMENTS,
    allStores.rootStore.workshopStartup.defaultWorkshopDepot
  ),

  canManageWorkshop: allStores.rootStore.account.isWorkshopDepartmentMember,
  canManageSales: allStores.rootStore.account.isSalesDepartmentMember,
  canManageOperationsOrRail:
    allStores.rootStore.account.isOperationsDepartmentMember ||
    allStores.rootStore.account.isRailDepartmentMember,
  canManagePeopleOrTimesheets:
    allStores.rootStore.account.isPeopleDepartmentMember ||
    allStores.rootStore.account.isTimesheetsDepartmentMember,
  canManageAccounting: allStores.rootStore.account.isAccountingDepartmentMember,
  canManageCompliance: allStores.rootStore.account.isOperationsDepartmentMember,

  uiRouteWorkshop: allStores.rootStore.history.uiRoutes.workshop.pathPattern,
  uiRouteSales: allStores.rootStore.history.uiRoutes.sales.pathPattern,
  uiRouteOperations: allStores.rootStore.history.uiRoutes.operations.pathPattern,
  uiRoutePeople: allStores.rootStore.history.uiRoutes.peopleStaffMemberList.pathPattern,
  uiRouteAccounting: allStores.rootStore.history.uiRoutes.accounting.pathPattern,
  uiRouteCompliance: allStores.rootStore.history.uiRoutes.compliance.pathPattern,

  isAuthenticated: allStores.rootStore.account.isAuthenticated,
  releaseMismatch: allStores.rootStore.releaseMismatch,
  refreshSite: allStores.rootStore.refreshSite,
  showAlertForJobWithNoDriversClockedOn:
    allStores.rootStore.operations.job.list.showAlertForJobWithNoDriversClockedOn,
  addNotification: allStores.rootStore.notifications.addNotification,
  userAccount: allStores.rootStore.account,
  domainEvents: getBus(allStores.rootStore).operationsDomainEvent$,
}))(Shell);

export default Container;
