import { flow, types } from 'mobx-state-tree';
import { getAjax } from 'src/domain/services/storeEnvironment';
import { RailTemplateShiftsModel } from './railTemplates/railTemplateShiftsModel';
import { RailTemplatesModel } from 'src/domain/entities/operations/rail/railTemplates/RailTemplatesModel';
import { RailTemplatesForDropdownModel } from 'src/domain/entities/operations/rail/railTemplates/RailTemplatesForDropdownModel';
import { RailTemplateModel } from 'src/domain/entities/operations/rail/railTemplates/RailTemplateModel';
import { RailBookingsModel } from 'src/domain/entities/operations/rail/railBookings/RailBookingsModel';
import { RailBookingModel } from 'src/domain/entities/operations/rail/railBookings/RailBookingModel';

type RailBookingContractTypeDto = Common.Dtos.RailBookingContractTypeDto;

export const RailModel = types
  .model('RailModel', {
    railTemplateShifts: types.optional(RailTemplateShiftsModel, {}),
    railTemplates: types.optional(RailTemplatesModel, {}),
    railTemplatesForDropdown: types.optional(RailTemplatesForDropdownModel, {}),
    railTemplate: types.optional(RailTemplateModel, {}),
    railBookings: types.optional(RailBookingsModel, {}),
    railBooking: types.optional(RailBookingModel, {}),
    railBookingContractTypes: types.array(types.frozen<RailBookingContractTypeDto>()),
  })
  .actions(self => {
    const ajax = getAjax(self);

    const loadRailBookingContractTypes = flow(function*() {
      self.railBookingContractTypes = yield ajax.operations.rail.railBookings.loadRailBookingContractTypes();
    });

    return {
      loadRailBookingContractTypes,
    };
  });

type IRailModelType = typeof RailModel.Type;
export interface IRailModel extends IRailModelType {}
