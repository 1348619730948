import inject from 'src/views/injectFromStore';
import MaintainJobProgress, {
  IMaintainJobProgressProps,
} from '../../../../components/MaintainJobProgress';
import Omit from 'src/infrastructure/omit';
import { RouteComponentProps } from 'react-router';
import { JobStatus } from 'src/api/enums';

type WorkshopJobProgressCommand = Workshop.Domain.Commands.Job.UpdateWorkshopJobProgressCommand;
type UpdateJobProgressCommand =
  | Operations.Domain.Commands.Job.UpdateJobProgress.UpdateJobProgressCommand
  | Workshop.Domain.Commands.Job.UpdateWorkshopJobProgressCommand;

const Container = inject<
  Omit<IMaintainJobProgressProps, 'mode'>,
  IMaintainJobProgressProps & RouteComponentProps<{ id: string }>
>((allStores, p) => {
  return {
    canManageJobProgress:
      allStores.rootStore.account.isOperationsDepartmentMember ||
      allStores.rootStore.account.isWorkshopDepartmentMember,
    staffMembers: allStores.rootStore.people.staffMembers.allStaffMembers.slice(),
    loadStaffMembers: allStores.rootStore.people.staffMembers.loadAllStaffMembers,

    loadJob: allStores.rootStore.job.loadJob,
    loadJobProgress: allStores.rootStore.job.loadBusUsages,
    updateJobProgress: (command: UpdateJobProgressCommand) =>
      allStores.rootStore.job.updateBusUsages(command as WorkshopJobProgressCommand),
    fleetAssets: allStores.rootStore.assets.fleetAssetListItems.slice(),
    loadFleetAssets: allStores.rootStore.assets.loadFleetAssets,
    jobProgresses: allStores.rootStore.job.busUsages.slice(),
    jobNumber: allStores.rootStore.job.job && allStores.rootStore.job.job.jobNumber,
    jobStatus: JobStatus.Completed,
    isWorkshop: true,
    loadPreviousAndNextJobProgress: allStores.rootStore.job.loadPreviousAndNextJobProgress,
    previousAndNextJobProgresses: allStores.rootStore.job.previousAndNextJobProgresses,
  };
})(MaintainJobProgress);

export default Container;
