import inject from 'src/views/injectFromStore';
import MaintainOperationsDiaryEntry, {
  IMaintainOperationsDiaryEntryProps,
} from './MaintainOperationsDiaryEntry';
import Omit from 'src/infrastructure/omit';

const Container = inject<
  Omit<IMaintainOperationsDiaryEntryProps, 'mode'>,
  IMaintainOperationsDiaryEntryProps
>((allStores, p) => ({
  canManageOperationsDiaryEntry: allStores.rootStore.account.isOperationsDepartmentMember,
  operationsDiaryEntry: Object.assign(
    {},
    allStores.rootStore.operations.operationsDiaryEntry.operationsDiaryEntry
  ),
  loadOperationsDiaryEntry:
    allStores.rootStore.operations.operationsDiaryEntry.loadOperationsDiaryEntry,
  updateOperationsDiaryEntry:
    allStores.rootStore.operations.operationsDiaryEntry.updateOperationsDiaryEntry,
  createOperationsDiaryEntry:
    allStores.rootStore.operations.operationsDiaryEntry.createOperationsDiaryEntry,
  deleteOperationsDiaryEntry:
    allStores.rootStore.operations.operationsDiaryEntry.deleteOperationsDiaryEntry,

  loadAllOperationsDiaryEntrySubjects:
    allStores.rootStore.operations.operationsDiaryEntry.loadAllOperationsDiaryEntrySubjects,
  operationsDiaryEntrySubjects: allStores.rootStore.operations.operationsDiaryEntry.creatableOperationsDiaryEntrySubjects.slice(),

  loadStaffMembers: allStores.rootStore.people.staffMembers.loadAllStaffMembers,
  assets: allStores.rootStore.assets.fleetAssetListItems.slice(),
  loadAssetListItems: allStores.rootStore.assets.loadFleetAssets,
  acknowledgeEntry:
    allStores.rootStore.operations.operationsDiaryEntry.acknowledgeOperationsDiaryEntry,
}))(MaintainOperationsDiaryEntry);

export default Container;
