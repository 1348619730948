import './VehicleCountIndicator.scss';

type AssetAttachmentItem = Common.Dtos.AssetNameDto;

interface IShowVehicleCountIndicatorProps {
  vehicleCount: number;
  asset: AssetAttachmentItem;
}

const VehicleCountIndicator = ({ vehicleCount, asset }: IShowVehicleCountIndicatorProps) => {
  if (vehicleCount === 1) {
    return <a href={`/workshop/assets/${asset.id}`}>{asset.name}</a>;
  }
  return <div className={`vehicleCountIndicator-component`}>{vehicleCount} Vehicles</div>;
};

export default VehicleCountIndicator;
