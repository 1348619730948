import './KioskShell.scss';
import { PureComponent } from 'react';
import inject from 'src/views/injectFromKioskStore';
import { SingleClickAwareButton } from 'src/views/kioskRoutes/components/presentational/SingleClickAwareButton/SingleClickAwareButton';

export interface IKioskShellProps {
  releaseMismatch: boolean;
  refreshSite: () => void;
}

class KioskShell extends PureComponent<IKioskShellProps> {
  render() {
    const { releaseMismatch, refreshSite, children } = this.props;
    return (
      <div className="kiosk-shell-component">
        <div className="shell-contents">{children}</div>
        {releaseMismatch ? (
          <div className="release-mismatch">
            <span>
              <span>The system has been updated! Please </span>
              <SingleClickAwareButton useReactStrapButton className="refresh" onClick={refreshSite}>
                refresh
              </SingleClickAwareButton>
              <span> to use the latest version.</span>
            </span>
          </div>
        ) : null}
      </div>
    );
  }
}

const KioskShellContainer = inject<IKioskShellProps>((allStores, p) => ({
  releaseMismatch: allStores.rootStore.releaseMismatch,
  refreshSite: allStores.rootStore.refreshSite,
}))(KioskShell);

export default KioskShellContainer;
