import { memo } from 'react';
import { Link } from 'react-router-dom';
import { parentJobRelationshipDescription } from 'src/api/enums';
import { ISectionDef, PaneType } from 'src/views/definitionBuilders/types';
import { IntervalFormat } from 'src/views/components/IntervalFormat';
import { groupBy } from 'src/infrastructure/arrayUtils';

type ParentJob = Operations.Domain.Queries.ViewJob.ParentJob;
type ChildJob = Operations.Domain.Queries.ViewJob.ChildJob;
type JobItem = Operations.Domain.Queries.ViewJob.JobItem;
type SplitJobSibling = Operations.Domain.Queries.ViewJob.SplitJobSibling;

const LinkedJob = memo(({ job }: { job: ParentJob | ChildJob | SplitJobSibling | undefined }) => {
  if (!job) {
    return null;
  }
  return (
    <span>
      <Link to={`/operations/jobs/${job.jobId}`}>
        <IntervalFormat startValue={job.clockOn} endValue={job.clockOff} />
      </Link>
      &ensp;
      <span>
        (
        {job.staffMember ? (
          <Link to={`/people/staff-members/${job.staffMember.id}`}>{job.staffMember.name}</Link>
        ) : (
          <span>unassigned</span>
        )}
        )
      </span>
    </span>
  );
});

export default function getLinkedJobsSectionDef(job: JobItem | undefined): ISectionDef {
  const parentJob = job && job.parentJob;
  const childJobs = (job && job.childJobs) || [];
  const splitSiblings = (job && job.splitJobSiblings.filter(j => j.jobId !== job.id)) || [];

  const linkedJobCount = (parentJob ? 1 : 0) + childJobs.length + splitSiblings.length;
  const childJobGroups = Array.from(
    groupBy(childJobs, j => j.parentJobRelationshipId).entries()
  ).map(([relationshipId, jobs]) => ({ relationshipId, jobs }));

  return {
    title: 'Linked',
    subtitle: `[${linkedJobCount}]`,
    hidden: !linkedJobCount,
    explicitData: { parentJob, childJobGroups, splitSiblings },
    panels: [
      {
        title: `${parentJob &&
          parentJobRelationshipDescription(parentJob.parentJobRelationshipId)} (Primary Job)`,
        dataAddr: 'parentJob',
        hidden: d => !d.panelValue,
        panes: [
          {
            paneType: PaneType.customPane,
            render: api => <LinkedJob job={api.data.paneValue} />,
          },
        ],
      },
      {
        dataAddr: 'childJobGroups',
        hidden: d => !d.panelValue.length,
        panes: [
          {
            paneType: PaneType.repeatingPane,
            itemPanes: [
              {
                paneType: PaneType.customPane,
                render: api => (
                  <h2>{parentJobRelationshipDescription(api.data.paneValue.relationshipId)}</h2>
                ),
              },
              {
                paneType: PaneType.repeatingPane,
                dataAddr: 'jobs',
                itemPanes: [
                  {
                    paneType: PaneType.customPane,
                    render: api => <LinkedJob job={api.data.paneValue} />,
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        dataAddr: 'splitSiblings',
        hidden: d => !d.panelValue.length,
        panes: [
          {
            paneType: PaneType.customPane,
            render: () => <h2>Split Job Siblings</h2>,
          },
          {
            paneType: PaneType.repeatingPane,
            itemPanes: [
              {
                paneType: PaneType.customPane,
                render: api => <LinkedJob job={api.data.paneValue} />,
              },
            ],
          },
        ],
      },
    ],
  };
}
