import { flow } from 'mobx-state-tree';
import { getAjax } from 'src/domain/services';
import { buildListPageApiSearchModelTypedQuery } from 'src/domain/modelBuilders/buildListPageApiSearchModel';
import { operationsUrls } from 'src/domain/services/apiUrls';

type AssetJobProgressWithUnknownKmsDto = Operations.Domain.Queries.ListAssetJobProgressWithUnknownKms.AssetJobProgressWithUnknownKmsDto;

type ListAssetJobProgressWithUnknownKmsQuery = Operations.Domain.Queries.ListAssetJobProgressWithUnknownKms.ListAssetJobProgressWithUnknownKmsQuery;

export const AssetsWithUnknownKmsModel = buildListPageApiSearchModelTypedQuery<
  ListAssetJobProgressWithUnknownKmsQuery
>()('AssetsWithUnknownKmsModel', d =>
  d.ajax.raw
    .get(operationsUrls.assetsWithUnknownKmsUrls.loadAssetsWithUnknownKms(d.query))
    .map(r => r.response as Common.Dtos.ListResult<AssetJobProgressWithUnknownKmsDto>)
).actions(self => {
  const ajax = getAjax(self);

  const dismissUnknownKms = flow(function*(id: string) {
    yield ajax.raw
      .post(operationsUrls.assetsWithUnknownKmsUrls.dismissUnknownKms(id))
      .toPromise()
      .then();
  });

  return { dismissUnknownKms };
});
