import { IFile } from 'src/views/components/Page/fields/MultiFilePageField';
import { AddAttachmentData } from 'src/domain/entities/operations/attachment/OperationsAttachmentModel';
import {
  AggregatesWithAttachments,
  LoadAttachmentsQuery,
  maxFileSizeInMegaBytes,
} from 'src/views/components/Attachment/attachmentHelper';
import {
  FieldDefs,
  FieldType,
  IFieldData,
  ModalDefBuilder,
  PaneType,
} from 'src/views/definitionBuilders/types';
import { getSubmitCloseModalActionGroupDef } from 'src/views/definitionBuilders/common';

type AddAttachmentFormData = {
  file: IFile;
  fileName: string;
  aggregateId: string;
  aggregateType: string;
  availableOnTablet: boolean;
  availableOnKiosk: boolean;
  updateCurrentJobs: boolean;
};

const tabletFileSizeMegaBytesWarningThreshold = 2;

export const updateCurrentJobsDef = (hideBasedOnFile: boolean): FieldDefs[] => {
  return [
    {
      fieldType: FieldType.yesNoField,
      label: 'Update current jobs?',
      mandatory: true,
      dataAddr: 'updateCurrentJobs',
      hidden: fapi => hideBasedOnFile && !fapi.parentValue.file,
    },
    {
      fieldType: FieldType.customField,
      label: '',
      dataAddr: 'fake2',
      hidden: fapi => fapi.parentValue.updateCurrentJobs !== true,
      readonly: true,
      render: () => <p>This will update all existing jobs.</p>,
    },
    {
      fieldType: FieldType.customField,
      label: '',
      dataAddr: 'fake3',
      hidden: fapi => fapi.parentValue.updateCurrentJobs !== false,
      readonly: true,
      render: () => <p>This will update the shift and will not affect any existing jobs.</p>,
    },
  ];
};

export default function getAddAttachmentModalDef(
  aggregateId: string,
  aggregateType: AggregatesWithAttachments,
  onSubmit: (data: AddAttachmentData) => Promise<void>,
  onSubmitSucceed: (query: LoadAttachmentsQuery) => Promise<void>,
  tabletAttachmentAlreadyExists: boolean
): ModalDefBuilder {
  return api => ({
    title: 'Add Attachment',
    asForm: true,
    panels: [
      {
        panes: [
          {
            paneType: PaneType.formFieldsPane,
            fields: [
              {
                fieldType: FieldType.fileField,
                label: 'Attachment',
                mandatory: true,
                dataAddr: 'file',
                validate: fapi => {
                  const fapiTyping = fapi as IFieldData<IFile>;
                  if (fapiTyping.fieldValue && fapiTyping.fieldValue.file) {
                    if (fapiTyping.fieldValue.file.type !== 'application/pdf') {
                      return 'Invalid file type. Please select a pdf';
                    }
                    const maxSizeInBytes = maxFileSizeInMegaBytes * 1024 * 1024;
                    if (fapiTyping.fieldValue.file.size > maxSizeInBytes) {
                      return `${(fapiTyping.fieldValue.file.size / 1024 / 1024).toFixed(
                        2
                      )}MB attachment too large. Attachments must be under 20MB`;
                    }
                  }

                  return undefined;
                },
                onChange: fapi => {
                  fapi.setFormValue(
                    ['fileName'],
                    (fapi.formValues.file && fapi.formValues.file.name) || ''
                  );
                },
              },
              {
                fieldType: FieldType.textField,
                label: 'File name',
                mandatory: true,
                dataAddr: 'fileName',
                hidden: fapi => !fapi.parentValue.file,
                validate: fapi => {
                  if (fapi.fieldValue && !fapi.fieldValue.endsWith('.pdf')) {
                    return 'Please ensure the filename ends with .pdf';
                  }
                  return undefined;
                },
              },
              {
                fieldType: FieldType.yesNoField,
                label: "Available on Driver's Tablet?",
                mandatory: true,
                dataAddr: 'availableOnTablet',
                hidden: fapi => !fapi.parentValue.file,
              },
              {
                fieldType: FieldType.customField,
                label: '',
                dataAddr: 'fake',
                hidden: fapi => {
                  return (
                    !fapi.panelValue.file ||
                    !fapi.panelValue.availableOnTablet ||
                    !tabletAttachmentAlreadyExists
                  );
                },
                readonly: true,
                render: api => (
                  <p>
                    Multiple tablet PDFs can be a distraction for the driver and could be slow to
                    download if not optimised correctly. Please consider if this PDF is necessary
                    for display on the driver's tablet.
                  </p>
                ),
              },
              {
                fieldType: FieldType.customField,
                label: '',
                dataAddr: 'fake1',
                hidden: fapi =>
                  !fapi.panelValue.file ||
                  !fapi.panelValue.file.file ||
                  !fapi.panelValue.availableOnTablet ||
                  fapi.panelValue.file.file.size <
                    tabletFileSizeMegaBytesWarningThreshold * 1024 * 1024,
                readonly: true,
                render: fapi => (
                  <p>
                    This PDF is {(fapi.data.panelValue.file.file.size / 1024 / 1024).toFixed(2)}Mb
                    in size. Please consider if this PDF could be optimised to reduce its file size.
                  </p>
                ),
              },
              {
                fieldType: FieldType.yesNoField,
                label: "Available on Driver's Kiosk & App?",
                mandatory: true,
                dataAddr: 'availableOnKiosk',
                hidden: fapi => !fapi.parentValue.file,
              },
              ...updateCurrentJobsDef(true),
            ],
          },
        ],
      },
    ],
    secondaryActions: [getSubmitCloseModalActionGroupDef('Add')],
    onFormSubmit: (values: AddAttachmentFormData) => {
      return onSubmit({
        aggregateId: aggregateId,
        aggregateType: aggregateType,
        fileName: values.fileName,
        file: values.file,
        availableOnTablet: values.availableOnTablet,
        availableOnKiosk: values.availableOnKiosk,
        updateCurrentJobs: values.updateCurrentJobs,
      });
    },
    onSubmitSucceeded: () => onSubmitSucceed({ aggregateId, aggregateType }),
  });
}
