import { Component } from 'react';
import { FieldType, IFieldData, PagePrimarySize } from 'src/views/definitionBuilders/types';
import { ListPage } from 'src/views/components/Page';
import { TaskCardItem } from 'src/views/components/TaskCard';
import { Link } from 'react-router-dom';
import { IListPageDef } from 'src/views/components/Page/pages/ListPage';
import PrimaryTitle from 'src/views/components/Page/PrimaryTitle/PrimaryTitle';

type EngineDetails = Workshop.Domain.Queries.Engine.GetEngine.EngineDetails;
type EnginePartsGroupsDetails = Workshop.Domain.Queries.Engine.GetEngine.EnginePartsGroupsDetails;

export interface IListEngineProps {
  canManageEngines: boolean;
  loadEnginesDetails: () => Promise<void>;
  enginesDetails: EngineDetails[];
}

function getPageDef(props: IListEngineProps): IListPageDef {
  return {
    primaryTitle: (
      <PrimaryTitle
        title="Engines"
        link="https://www.notion.so/Engines-0d6f73d4212e4120856c443ff601479a"></PrimaryTitle>
    ),
    primarySize: PagePrimarySize.full,
    onLoadData: () => props.loadEnginesDetails(),
    createLink: props.canManageEngines ? TaskCardItem.createEngine : undefined,
    primaryFields: [
      {
        fieldType: FieldType.textField,
        dataAddr: 'model',
        label: 'Model',
        linkTo: d => `/workshop/engines/${d.parentValue.id}`,
        columnWidth: '50%',
      },
      {
        fieldType: FieldType.customField,
        dataAddr: 'partsGroups',
        label: 'Service',
        render: d => {
          const detail = d.data as IFieldData<EnginePartsGroupsDetails[]>;

          return (
            <div>
              {detail &&
                detail.fieldValue &&
                detail.fieldValue.map((i, idx) => (
                  <div key={idx}>{i.jobTaskSubcategoryDescription}</div>
                ))}
            </div>
          );
        },
      },
      {
        fieldType: FieldType.customField,
        dataAddr: 'partsGroups',
        label: 'Parts Group',
        getSearchText: d => {
          const detail = d as IFieldData<EnginePartsGroupsDetails[]>;
          return detail && detail.fieldValue && detail.fieldValue.map(y => y.partsGroupName).join();
        },
        render: d => {
          const detail = d.data as IFieldData<EnginePartsGroupsDetails[]>;

          return (
            <div>
              {detail &&
                detail.fieldValue &&
                detail.fieldValue.map((i, idx) => (
                  <div key={idx}>
                    <Link to={`/workshop/parts-groups/${i.partsGroupId}`}>{i.partsGroupName}</Link>
                  </div>
                ))}
            </div>
          );
        },
      },
      {
        fieldType: FieldType.textField,
        dataAddr: 'isActive',
        label: 'Active',
        formatReadonly: data => (data.fieldValue ? 'Yes' : 'No'),
      },
    ],
  };
}

class ListEngines extends Component<IListEngineProps> {
  render() {
    return (
      <ListPage
        className="list-engines-component"
        data={this.props.enginesDetails}
        def={getPageDef(this.props)}
      />
    );
  }
}

export default ListEngines;
