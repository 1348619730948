import { DateTime } from 'luxon';
import { observer } from 'mobx-react';
import { useEffect } from 'react';
import {
  allAssetStatus,
  allJobState,
  allJobTaskCategory,
  InvoiceStatus,
  JobState,
} from 'src/api/enums';
import { useRootStore } from 'src/domain/entities/RootStoreModel';
import { IntervalFormat } from 'src/views/components/IntervalFormat';
import { ListPage } from 'src/views/components/Page';
import { IListPageDef } from 'src/views/components/Page/pages/ListPage';
import { TaskCardItem } from 'src/views/components/TaskCard';
import {
  ActionType,
  FieldDefs,
  FieldType,
  PagePrimarySize,
  PaneType,
} from 'src/views/definitionBuilders/types';
import { getJobTaskCrewFieldDef, getJobTaskDescriptionsFieldDef } from './getJobTaskDetailFields';
import PrintJobSheet, { jobSheetPdfHeader } from './PrintJobSheet/PrintJobSheet';
import PrimaryTitle from 'src/views/components/Page/PrimaryTitle/PrimaryTitle';

const allInvoiceStatus = [
  { value: 1, description: `No Invoice Required` },
  { value: 2, description: `Needs Invoicing` },
  { value: 3, description: `Invoiced` },
];

const ListJobs: React.FC = observer(() => {
  const rootStore = useRootStore();
  const listJobsModel = rootStore.workshop.listJobs;
  const jobModel = rootStore.job;
  const assetModel = rootStore.asset;
  const assetsModel = rootStore.assets;

  useEffect(() => {
    assetsModel.loadAssetListItems();
    assetModel.loadAssetHousedAtLocations();
  }, []);

  const getFilterFieldDefs = () => {
    return {
      assets: {
        fieldType: FieldType.selectMultiField,
        label: 'Asset',
        dataAddr: 'assets',
        useValueOnly: true,
        valueKey: 'id',
        descriptionKey: 'name',
        optionItems: assetsModel.assetListItems.slice(),
      } as FieldDefs,
      jobState: {
        fieldType: FieldType.selectField,
        label: 'State',
        dataAddr: 'jobState',
        useValueOnly: true,
        valueKey: 'value',
        descriptionKey: 'description',
        optionItems: allJobState,
      } as FieldDefs,
      dateFrom: {
        fieldType: FieldType.dateField,
        dataAddr: 'dateFrom',
        label: 'Date From',
        onBlur: api => {
          if (!api.formValues.dateTo) {
            api.setFormValue(['dateTo'], api.fieldData.fieldValue);
          }
          api.validateField(['dateTo']);
        },
      } as FieldDefs,
      dateTo: {
        fieldType: FieldType.dateField,
        dataAddr: 'dateTo',
        label: 'Date To',
        validate: d => {
          if (!d.fieldValue || !d.parentValue.dateFrom) {
            return undefined;
          }
          const from = DateTime.fromISO(d.parentValue.dateFrom);
          const to = DateTime.fromISO(d.fieldValue);
          return from > to ? 'Date To cannot be earlier than Date From' : undefined;
        },
      } as FieldDefs,
      taskCategories: {
        fieldType: FieldType.selectMultiField,
        label: 'Category',
        dataAddr: 'taskCategories',
        useValueOnly: true,
        valueKey: 'value',
        descriptionKey: 'description',
        optionItems: allJobTaskCategory,
      } as FieldDefs,
      invoiceStatuses: {
        fieldType: FieldType.selectMultiField,
        label: 'Invoice Status',
        dataAddr: 'invoiceStatuses',
        useValueOnly: true,
        valueKey: 'value',
        descriptionKey: 'description',
        optionItems: allInvoiceStatus,
      } as FieldDefs,
      garagedAt: {
        fieldType: FieldType.selectMultiField,
        label: 'Garaged At',
        dataAddr: 'assetHousingLocations',
        optionItems: assetModel.housedAtLocations.slice(),
        useValueOnly: true,
        valueKey: 'id',
        descriptionKey: 'description',
      } as FieldDefs,
      workshopDepots: {
        fieldType: FieldType.selectMultiField,
        label: 'Depot',
        dataAddr: 'workshopDepots',
        optionItems: rootStore.workshopStartup.workshopDepots.slice(),
        useValueOnly: true,
        valueKey: 'id',
        descriptionKey: 'description',
      } as FieldDefs,
      assetStatus: {
        fieldType: FieldType.selectField,
        label: 'Asset Status',
        dataAddr: 'assetStatus',
        useValueOnly: true,
        valueKey: 'value',
        descriptionKey: 'description',
        optionItems: allAssetStatus.slice(),
      } as FieldDefs,
    };
  };

  const getPageDef = (): IListPageDef => {
    const filterFieldDefsLookup = getFilterFieldDefs();
    return {
      primaryTitle: <PrimaryTitle title="Jobs"></PrimaryTitle>,
      onLoadData: listJobsModel.listItems,
      externalSearch: true,
      createLink: rootStore.account.isWorkshopDepartmentMember ? TaskCardItem.createJob : undefined,
      primarySize: PagePrimarySize.full,
      hasMoreData: listJobsModel.hasMoreItems,
      primaryFields: [
        {
          fieldType: FieldType.textField,
          dataAddr: 'assetName',
          label: 'Asset',
          linkTo: d => `/workshop/assets/${d.parentValue.assetId}`,
          columnWidth: '1rem',
        },
        {
          fieldType: FieldType.textField,
          dataAddr: 'jobNumber',
          label: 'Job Number',
          linkTo: d => `/workshop/jobs/${d.parentValue.jobId}`,
          columnWidth: '1rem',
        },
        getJobTaskDescriptionsFieldDef(),
        getJobTaskCrewFieldDef(),
        {
          fieldType: FieldType.customField,
          dataAddr: 'startDateTime',
          label: 'Booking Time',
          render: api => {
            return (
              <IntervalFormat
                startValue={api.data.parentValue.startDateTime}
                endValue={api.data.parentValue.endDateTime}
                includeYear
              />
            );
          },
        },
        {
          fieldType: FieldType.textField,
          dataAddr: ['invoiceStatus', 'description'],
          label: 'Invoicing',
          hidden: d =>
            d.parentValue.invoiceStatus &&
            d.parentValue.invoiceStatus.id === InvoiceStatus.NoInvoiceRequired,
        },
        {
          fieldType: FieldType.actionListField,
          dataAddr: '',
          columnWidth: '1px',
          label: 'Actions',
          actionGroups: [
            {
              actions: [
                {
                  actionType: ActionType.actionCollection,
                  actionGroups: [
                    {
                      actions: [
                        {
                          actionType: ActionType.printActionButton,
                          label: 'Print Job Sheet',
                          printTitle:
                            jobModel.printJobItem && jobSheetPdfHeader(jobModel.printJobItem),
                          printContent: () =>
                            jobModel.printJobItem ? (
                              <PrintJobSheet job={jobModel.printJobItem} />
                            ) : null,
                          loadDataAsync: d => jobModel.loadPrintJobSheet(d.parentValue.jobId),
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
      filterAction: {
        defaultValues: {
          jobState: JobState.Overdue,
          workshopDepots: [rootStore.workshopStartup.defaultWorkshopDepot?.id],
        },
        filterFields: Object.keys(filterFieldDefsLookup).map(k => filterFieldDefsLookup[k]),
        filterDef: _ => [
          {
            panes: [
              {
                paneType: PaneType.formFieldsPane,
                fields: [filterFieldDefsLookup.assets, filterFieldDefsLookup.jobState],
              },
              {
                paneType: PaneType.formFieldsPane,
                columnCount: 2,
                fields: [filterFieldDefsLookup.dateFrom, filterFieldDefsLookup.dateTo],
              },
              {
                paneType: PaneType.formFieldsPane,
                fields: [
                  filterFieldDefsLookup.taskCategories,
                  filterFieldDefsLookup.garagedAt,
                  filterFieldDefsLookup.invoiceStatuses,
                  filterFieldDefsLookup.workshopDepots,
                  filterFieldDefsLookup.assetStatus,
                ],
              },
            ],
          },
        ],
      },
    };
  };

  return <ListPage className="list-jobs-component" data={listJobsModel.items} def={getPageDef()} />;
});

export default ListJobs;
