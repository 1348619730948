// -- Sanitisers --
const maxInt32Str = '2147483647';
const maxInt32 = Number.parseInt(maxInt32Str, 10);
const minInt32Str = '-2147483648';
const minInt32 = Number.parseInt(minInt32Str, 10);
const maxDecimal = Number.MAX_SAFE_INTEGER;

const unsignedIntegerSanitiserOptionsDefaults = {
  maxValue: maxInt32,
};
type UnsignedIntegerSanitiserOptions = typeof unsignedIntegerSanitiserOptionsDefaults;

function unsignedIntegerSanitiser(
  value: string,
  options: Partial<UnsignedIntegerSanitiserOptions> = {}
): number | undefined {
  // Only positive integers, capped at a valid int32
  const opts = { ...unsignedIntegerSanitiserOptionsDefaults, ...options };
  const filtered = value.replace(/\D/, '').substr(0, maxInt32Str.length);
  const num = (value && Number.parseInt(filtered, 10)) || undefined;
  if (num && num > opts.maxValue) {
    return opts.maxValue;
  } else {
    return num;
  }
}

// This sanitiser can return a string type, because when typing a negative number, we need to at some point
// have a value of "-" - converting this to a number type removes the hyphen.
const signedIntegerSanitiserOptionsDefaults = {
  maxValue: maxInt32,
  minValue: minInt32,
};
type SignedIntegerSanitiserOptions = typeof signedIntegerSanitiserOptionsDefaults;

function signedIntegerSanitiser(
  value: string,
  options: Partial<SignedIntegerSanitiserOptions> = {}
): number | string | undefined {
  // Only integers, capped at a valid int32
  const opts = { ...signedIntegerSanitiserOptionsDefaults, ...options };
  const filteredCoarse = value.replace(/[^\d-]/, '').substr(0, minInt32Str.length);
  const firstHyphenMatch = filteredCoarse.match(/^-/);
  const firstHyphenIndex = firstHyphenMatch ? firstHyphenMatch.index : -1;
  const filtered = filteredCoarse.replace(/-/g, (match, offset: number) =>
    offset === firstHyphenIndex ? match : ''
  );

  const num = Number.parseInt(filtered, 10);
  if (num > opts.maxValue) {
    return opts.maxValue;
  } else if (num < opts.minValue) {
    return opts.minValue;
  } else {
    return num;
  }
}

// This sanitiser can return a string type, because when typing a decimal number, we need to at some point
// have a value of "3." or "3.00"  - converting this to a number type strips decimal points and trailing zeros.
const unsignedDecimialSanitiserOptionsDefaults = {
  maxValue: maxDecimal,
  maxPointDigits: undefined as number | undefined,
  maxTotalDigits: undefined as number | undefined,
};
type UnsignedDecimialSanitiserOptions = typeof unsignedDecimialSanitiserOptionsDefaults;

function unsignedDecimialSanitiser(
  value: string,
  options: Partial<UnsignedDecimialSanitiserOptions> = {}
): number | string | undefined {
  // Only positive decimal, capped at a valid .net decimal maxvalue
  const opts = { ...unsignedDecimialSanitiserOptionsDefaults, ...options };
  // Strip out all except digits and only one dot
  const filteredCoarse = value.replace(/[^\d.]/g, '');
  const firstDotMatch = filteredCoarse.match(/\./);
  const firstDotIndex = firstDotMatch ? firstDotMatch.index : -1;
  const filtered = filteredCoarse.replace(/\./g, (match, offset: number) =>
    offset === firstDotIndex ? match : ''
  );
  // Ensure that we don't have too many digits after the decimal point
  const resultPointsChecked = filtered.replace(/\.\d*/, match => {
    return opts.maxPointDigits && match.length > opts.maxPointDigits + 1
      ? match.substr(0, opts.maxPointDigits + 1)
      : match;
  });

  // Ensure that we don't have too many digits total
  // (parseFloat can have trouble after 15 because floats, so use that as a max limit)
  const maxTotalDigits = Math.min(opts.maxTotalDigits || 15, 15);
  const totalDigitCount = resultPointsChecked.replace(/[^\d]/, '').length;
  const digitsToRemove = totalDigitCount - maxTotalDigits;
  const result =
    digitsToRemove > 0 ? resultPointsChecked.slice(0, -digitsToRemove) : resultPointsChecked;

  // Ensure our number is not too big
  if (Number.parseFloat(result) > opts.maxValue) {
    return opts.maxValue;
  }

  return result;
}

function numericSanitiser(value: string): string {
  return value && value.replace(/[^\d]/g, '');
}

export const standardTextSanitisers = {
  unsignedIntegerSanitiser,
  signedIntegerSanitiser,
  unsignedDecimialSanitiser,
  numericSanitiser,
};
