import styles from './AssetList.module.scss';
import Spinner from 'src/views/components/Spinner';
import { Input } from 'reactstrap';
import { Link } from 'react-router-dom';
import { BusIcon, CarIcon } from 'src/images/icons';
import { FormattedDateTime } from '../../components/presentational/FormattedDateTime/FormattedDateTime';
import { AssetSubcategoryType } from 'src/domain';
import { DateTime } from 'luxon';
import { useEffect, useState } from 'react';

type AssetItem = Workshop.Domain.Queries.AssetItem;
type NextOpsJobForAsset = Common.Queries.Workshop.GetNextOpsJobForAsset.NextOpsJob;

export interface IAssetListProps {
  assets: AssetItem[];
  loadAllAssets: () => Promise<void>;
  loadNextOpsJobForAllAssets: () => Promise<void>;
  nextOpsJobForAllAssets?: NextOpsJobForAsset[];
}

export const AssetList: React.FC<IAssetListProps> = ({
  assets,
  loadAllAssets,
  loadNextOpsJobForAllAssets,
  nextOpsJobForAllAssets,
}) => {
  const [isLoading, setIsLoading] = useState(!assets);
  const [searchText, setSearchText] = useState('');

  useEffect(() => {
    if (!assets || assets.length === 0) {
      setIsLoading(true);

      Promise.all([loadAllAssets(), loadNextOpsJobForAllAssets()]).finally(() => {
        setIsLoading(false);
      });
    }
  });

  const lowerCaseSearchText = searchText.toLocaleLowerCase();
  const filteredAssets = searchText
    ? assets.filter(
        x =>
          x.name.toLocaleLowerCase().includes(lowerCaseSearchText) ||
          (x.registrationNumber &&
            x.registrationNumber.toLocaleLowerCase().includes(lowerCaseSearchText)) ||
          x.housingLocation.description.toLocaleLowerCase().includes(lowerCaseSearchText)
      )
    : assets;

  const nextJob = (assetId: string): NextOpsJobForAsset | undefined =>
    nextOpsJobForAllAssets && nextOpsJobForAllAssets.find(o => o.assetId === assetId);

  return (
    assets && (
      <div className={styles.assetList}>
        <h1>All Vehicles</h1>
        <Input
          type="text"
          placeholder={'Search Assets...'}
          value={searchText}
          onChange={e => setSearchText(e.target.value)}
        />
        <Spinner show={isLoading} className={styles.loadingSpinner}>
          <div className={styles.assets}>
            {filteredAssets.map((asset, idx) => (
              <Link to={`/wskiosk/asset/${asset.id}`} key={idx} className={styles.asset}>
                <span>
                  {asset.subCategoryId === AssetSubcategoryType.Bus && <BusIcon />}
                  {asset.subCategoryId === AssetSubcategoryType.Car && <CarIcon />}{' '}
                  <strong>{asset.registrationNumber}</strong>
                </span>
                <span>{asset.name}</span>
                <div className={styles.nextOpsJob}>
                  {nextJob(asset.id) && (
                    <>
                      <div>Next Ops Job:</div>
                      <strong>{nextJob(asset.id)!.description}</strong>{' '}
                      <FormattedDateTime
                        dateTime={nextJob(asset.id)!.clockOn}
                        className={styles.nextJobTime}
                        referenceDateTime={DateTime.local()}
                      />{' '}
                      -{' '}
                      <FormattedDateTime
                        dateTime={nextJob(asset.id)!.clockOff}
                        className={styles.nextJobTime}
                        referenceDateTime={nextJob(asset.id)!.clockOn}
                      />
                    </>
                  )}
                </div>
                <div className={styles.assetDetails}>
                  <div>{asset.housingLocation.description}</div>
                </div>
              </Link>
            ))}
          </div>
        </Spinner>
      </div>
    )
  );
};
