import { IAjax } from 'src/infrastructure/ajax';
import { defectUrls } from 'src/domain/services/apiUrls';

export default function init(ajax: IAjax) {
  function createDefect(command: Workshop.Domain.Commands.Defect.CreateDefectCommand) {
    return ajax
      .post(defectUrls.createDefect(), command)
      .toPromise()
      .then<string>(r => r.response);
  }

  function markOutOfServiceDefect(
    command: Workshop.Domain.Commands.Defect.MarkOutOfServiceDefectCommand
  ) {
    return ajax
      .post(defectUrls.markOutOfServiceDefect(), command)
      .toPromise()
      .then<string>(r => r.response);
  }

  function getDefectStatuses() {
    return ajax
      .get(defectUrls.getDefectStatuses())
      .toPromise()
      .then<Workshop.Domain.AggregatesModel.DefectAggregate.DefectStatus[]>(r => r.response);
  }

  function listDefects(
    query: Partial<Workshop.Domain.Queries.Defect.ListDefects.ListDefectsQuery>
  ) {
    return ajax
      .get(defectUrls.listDefects(query))
      .map(
        r => r.response as Common.Dtos.ListResult<Workshop.Domain.Queries.Defect.DefectListItem>
      );
  }

  function getDefect(defectId: string) {
    return ajax
      .get(defectUrls.getDefect(defectId))
      .toPromise()
      .then<Workshop.Domain.Queries.Defect.GetDefectItem.DefectItem>(r => r.response);
  }

  function getTasksForDefect(defectId: string) {
    return ajax
      .get(defectUrls.getTasksForDefect(defectId))
      .toPromise()
      .then<Workshop.Domain.Queries.JobTask.JobTaskListItem[]>(r => {
        return r.response;
      });
  }

  function acknowledgeDefect(defectId: string) {
    return ajax.post(defectUrls.acknowledgeDefect(defectId)).toPromise();
  }

  function setDefectClosedWithoutBeingFixed(
    command: Workshop.Domain.Commands.Defect.SetDefectClosedWithoutBeingFixedCommand
  ) {
    return ajax.post(defectUrls.closeDefectWithoutFixing(command.defectId), command).toPromise();
  }

  return {
    defect: {
      createDefect,
      markOutOfServiceDefect,
      listDefects,
      getDefectStatuses,
      getTasksForDefect,
      getDefect,
      acknowledgeDefect,
      setDefectClosedWithoutBeingFixed,
    },
  };
}
