import './SubmitActionButton.scss';
import { Component } from 'react';
import cn from 'classnames';
import { DropdownToggle, DropdownMenu, DropdownItem, UncontrolledDropdown } from 'reactstrap';
import {
  ISubmitActionButtonDef,
  IActionMeta,
  IActionData,
  ActionType,
  IActionDef,
  ShellModalSize,
} from 'src/views/definitionBuilders/types';
import { ErrorIcon, ChevronUpIcon } from 'src/images/icons';
import { IFormApi } from 'src/views/components/Page/forms/base';
import { withFormApi } from 'src/views/components/Page/forms';
import ModalActionButton from './ModalActionButton';
import ActionButton from './ActionButton';
import ActionCollectionButton from './ActionCollectionButton';

const maxErrorsToList = 5;

interface ISubmitActionButtonProps {
  formApi: IFormApi;
  actionDef: ISubmitActionButtonDef;
  actionMeta: IActionMeta;
  actionData: IActionData;
}

interface ISubmitActionButtonState {
  modalSuppressed: boolean;
}

class SubmitActionButton extends Component<ISubmitActionButtonProps, ISubmitActionButtonState> {
  constructor(props: ISubmitActionButtonProps) {
    super(props);
    this.state = {
      modalSuppressed: false,
    };
  }

  private readonly getDisabled = () => {
    const { actionDef, actionData } = this.props;

    if (typeof actionDef.disabled === 'function') {
      return actionDef.disabled(actionData);
    } else {
      return actionDef.disabled;
    }
  };

  private readonly handleSubmitClick = () => {
    const { formApi, actionDef } = this.props;
    formApi.submitForm(actionDef.submissionMeta);
  };

  private readonly suppressOpen = async () => {
    const valid = await this.props.formApi.validateAll();
    !valid && this.setState({ modalSuppressed: true });
    return !valid;
  };

  render() {
    const { formApi, actionDef, actionMeta, actionData } = this.props;
    const label = actionDef.label || 'Submit';
    const disabled = this.getDisabled() || actionMeta.formSubmitting;
    const showAsFailedSubmit =
      (formApi.submits > 0 || this.state.modalSuppressed) && formApi.hasAnyErrors();

    // Don't show invalid submit buttons as borderless, as it doesn't work well for the errors dropdowntoggle
    const actionButtonMeta = {
      ...actionMeta,
      danger: showAsFailedSubmit,
      borderless: showAsFailedSubmit ? false : actionMeta.borderless,
    };

    const getSubmitButton = () => {
      const confirmationModalDef = actionDef.confirmationModalDef;
      const suppress =
        typeof actionDef.suppressConfirmationModal === 'function'
          ? actionDef.suppressConfirmationModal(actionData)
          : actionDef.suppressConfirmationModal;
      if (confirmationModalDef && !suppress) {
        return (
          <ModalActionButton
            className={cn('submit-button')}
            actionDef={{
              ...(actionDef as IActionDef),
              actionType: ActionType.modalActionButton,
              label,
              disabled,
              modalDef: confirmationModalDef,
              modalSize: actionDef.confirmationModalSize || ShellModalSize.oneQuarter,
            }}
            actionMeta={actionButtonMeta}
            actionData={actionData}
            suppressOpen={this.suppressOpen}
          />
        );
      }

      return (
        <ActionButton
          className={cn('submit-button')}
          actionDef={{
            ...(actionDef as IActionDef),
            actionType: ActionType.actionButton,
            label,
            disabled,
            onClick: this.handleSubmitClick,
          }}
          actionMeta={actionButtonMeta}
          actionData={actionData}
        />
      );
    };

    const allErrors = formApi.getAllErrors();
    const extraErrors = allErrors.length > maxErrorsToList ? allErrors.length - maxErrorsToList : 0;
    const errors = extraErrors ? allErrors.slice(0, maxErrorsToList) : allErrors;
    const subActionGroups =
      actionDef.subActionGroups &&
      (typeof actionDef.subActionGroups === 'function'
        ? actionDef.subActionGroups(formApi)
        : actionDef.subActionGroups);
    return (
      <div
        className={cn('submit-action-button-component btn-group', { failed: showAsFailedSubmit })}>
        {subActionGroups && subActionGroups.some(g => !!g.actions.length) ? (
          <ActionCollectionButton
            className="split-submit-button"
            actionDef={{
              ...(actionDef as IActionDef),
              actionType: ActionType.actionCollection,
              disabled,
              actionGroups: subActionGroups,
              icon: <ChevronUpIcon />,
            }}
            actionMeta={{ ...actionButtonMeta, hideLabel: true }}
            actionData={actionData}
            splitWithNode={getSubmitButton()}
          />
        ) : (
          getSubmitButton()
        )}
        {showAsFailedSubmit ? (
          <UncontrolledDropdown className="submit-errors" {...{ direction: 'up' }}>
            <DropdownToggle
              disabled={disabled}
              color={
                showAsFailedSubmit
                  ? 'danger'
                  : actionDef.level === 'default'
                  ? 'secondary'
                  : actionDef.level
              }>
              <ErrorIcon size="sm" />
            </DropdownToggle>
            <DropdownMenu>
              {errors.map((e, i) => (
                <DropdownItem key={i} className="error-item" disabled>
                  {e}
                </DropdownItem>
              ))}
              {extraErrors ? (
                <DropdownItem disabled>{`... and ${extraErrors} more`}</DropdownItem>
              ) : null}
            </DropdownMenu>
          </UncontrolledDropdown>
        ) : null}
      </div>
    );
  }
}

export default withFormApi(SubmitActionButton);
