import ListPage, { IListPageDef } from 'src/views/components/Page/pages/ListPage';
import { FieldType } from 'src/views/definitionBuilders/types/field';
import TaskCardItem from 'src/views/components/TaskCard/TaskCardItem';
import { PagePrimarySize } from 'src/views/definitionBuilders/types';
import PrimaryTitle from 'src/views/components/Page/PrimaryTitle/PrimaryTitle';
import { observer } from 'mobx-react';
import { useRootStore } from 'src/domain/entities/RootStoreModel';
import { Link } from 'react-router-dom';
import withQueryParams, { IQueryParamsProps } from 'src/views/hocs/withQueryParams';

type ListEquipmentTypesQuery = People.Domain.Queries.EquipmentType.GetEquipmentTypeListQuery;

const ListEquipmentTypes: React.FC<IQueryParamsProps<ListEquipmentTypesQuery>> = observer(
  (props: IQueryParamsProps<ListEquipmentTypesQuery>) => {
    const rootStore = useRootStore();
    const model = rootStore.people.equipmentTypes;

    const getPageDef = (): IListPageDef => {
      return {
        primaryTitle: <PrimaryTitle title="Equipment Types" />,
        primarySize: PagePrimarySize.full,
        onLoadData: model.listItems,
        createLink: TaskCardItem.people.equipmentTypes.createEquipmentType,
        hasMoreData: model.hasMoreItems,
        primaryFields: [
          {
            fieldType: FieldType.textField,
            dataAddr: 'description',
            label: 'Description',
            formatReadonly: d => (
              <Link to={`/people/equipment-types/${d.parentValue.id}`}>
                {d.parentValue.description}
              </Link>
            ),
          },
        ],
      };
    };

    return (
      <ListPage className="list-equipment-types-component" data={model.items} def={getPageDef()} />
    );
  }
);

export default withQueryParams(ListEquipmentTypes);
