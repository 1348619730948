import styles from './Home.module.scss';

import cn from 'classnames';
import { DateTime } from 'luxon';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';
import { Subject } from 'rxjs/Subject';
import { TIMEZONE } from 'src/appSettings';
import { useKioskRootStore } from 'src/domain/entities/KioskRootStoreModel';
import {
  ArrowRightIcon,
  BanIcon,
  CheckIcon,
  ErrorIcon,
  HomeIcon,
  SignOutIcon,
  UserIcon,
  WarehouseIcon,
} from 'src/images/icons';
import { DateTimeFormat } from 'src/views/components/DateTimeFormat';
import Spinner from 'src/views/components/Spinner';
import { DurationFormat } from '../../../components/DurationFormat';
import DayNavigation from '../../components/presentational/DayNavigation/DayNavigation';
import IconButton from '../../components/presentational/IconButton/IconButton';
import { JobItem } from '../../operationsKiosk/home/JobItem/JobItem';
import AssetListSlider from '../AssetList/AssetListSlider';
import DepotBadge from '../DepotSelector/DepotBadge';
import RunsheetSlider from '../Runsheet/RunsheetSlider';
import SplitScreen from '../components/layout/SplitScreen/SplitScreen';
import LinkedCard from '../components/presentational/LinkedCard';
import { OnHoldIcon } from '../components/presentational/OnHoldIcon';
import WithMenu from '../menu/WithMenu';
import { AdditionalHomeCards } from './AdditionalHomeCards';
import { AssetName } from './AssetName';
import { DailyNote } from './DailyNote';
import { KioskNote } from './KioskNote';
import { formatDateTimeShort } from 'src/domain/dateHelper';

type JobSummary = Workshop.Domain.Queries.Job.GetJobsForMechanicAndDate.JobSummary;
type ShiftDetails = Workshop.Domain.Queries.GetShiftDetails.WorkshopShiftDetailsDto;

const recentDefectCount = 20;
const futureTaskCount = 100;

export const AlternateDepotBadge = ({
  targetDepotName,
  targetDepotId,
  defaultKioskDepotId,
  homeIcon,
  className,
}: {
  targetDepotName: string;
  targetDepotId: number;
  defaultKioskDepotId?: number;
  homeIcon?: boolean;
  className?: string;
}) => {
  const show = defaultKioskDepotId && targetDepotId !== defaultKioskDepotId;

  return show ? (
    <div className={cn(styles.depot, className)}>
      <div>
        <span>{targetDepotName}</span> {!homeIcon ? <WarehouseIcon /> : <HomeIcon />}
      </div>
    </div>
  ) : null;
};

const Home: React.FC = observer(() => {
  const rootStore = useKioskRootStore();
  const staffMemberId = rootStore.account.id;
  const status = rootStore.kiosk.clockOn.status;
  const loadStaffMemberStatus = rootStore.kiosk.clockOn.loadStaffMemberStatus;
  const userDisplayName = rootStore.account.checkedName;
  const onSignOut = rootStore.account.signOutKiosk;
  const date = rootStore.kiosk.date;
  const updateDate = rootStore.kiosk.updateDate;
  const jobs = rootStore.kiosk.workshop.mechanicJob.jobs.slice();
  const loadJobs = rootStore.kiosk.workshop.mechanicJob.loadJobsForMechanic;
  const notes = rootStore.kiosk.notes.notes.slice();
  const notesModel = rootStore.kiosk.notes;
  const loadNotesForDriver = rootStore.kiosk.notes.loadNotesForDriver;
  const futureTasks = rootStore.kiosk.workshop.task.futureTasks.slice();
  const loadFutureTasks = rootStore.kiosk.workshop.task.loadFutureTasks;
  const recentDefects = rootStore.kiosk.defect.recentDefects.slice();
  const loadRecentDefects = rootStore.kiosk.defect.loadRecentDefects;
  const outOfServiceJobTasks = rootStore.kiosk.jobTask.outOfServiceJobTasks.slice();
  const loadOutOfServiceJobTasks = rootStore.kiosk.jobTask.loadOutOfServiceJobTasks;
  const approvedLeaves = rootStore.kiosk.leaves.approvedLeaves.slice();
  const loadLeavesForDriver = rootStore.kiosk.leaves.loadLeavesForDriver;
  const shiftDetails = rootStore.kiosk.workshop.shiftDetails.workshopShiftDetails;
  const loadShiftDetails = rootStore.kiosk.workshop.shiftDetails.loadShiftDetails;
  const driverJobs = rootStore.kiosk.jobs.jobs.slice();
  const loadDriverJobs = rootStore.kiosk.jobs.loadJobsForDriver;
  const acknowledgeJob = rootStore.kiosk.jobs.acknowledgeJob;
  const kioskNote = rootStore.kiosk.workshop.kioskNote.kioskNote;
  const loadKioskNote = rootStore.kiosk.workshop.kioskNote.loadKioskNote;
  const createOrUpdateKioskNote = rootStore.kiosk.workshop.kioskNote.createOrUpdateKioskNote;
  const dailyNote = rootStore.kiosk.workshop.dailyNote.dailyNote;
  const loadDailyNote = rootStore.kiosk.workshop.dailyNote.loadDailyNote;
  const overdueJobs = rootStore.kiosk.workshop.mechanicJob.overdueJobs.slice();
  const loadOverdueJobs = rootStore.kiosk.workshop.mechanicJob.loadOverdueJobsForMechanic;
  const needToCompleteTimesheet = rootStore.kiosk.timesheet.showReminder;
  const getTimesheetReminder = rootStore.kiosk.timesheet.getTimesheetReminder;
  const timezone = TIMEZONE;
  const expiredLicences = rootStore.account.expiredLicences.slice();
  const loadExpiredLicences = rootStore.account.loadExpiredLicences;
  const deviceDepot = rootStore.kiosk.workshop.defaultKioskDepot;
  const deviceDepotId = rootStore.kiosk.workshop.defaultKioskDepotId;
  const companyHasDriversApp = rootStore.account.companyHasDriversApp;
  const getJobAttachment = rootStore.kiosk.job.getJobAttachment;
  const attachment = rootStore.kiosk.job.attachment;
  const incompleteShifts = rootStore.account.incompleteShifts.slice();
  const loadIncompleteShifts = rootStore.account.loadIncompleteShifts;
  const clockOff = rootStore.kiosk.clockOn.clockOff;
  const now = DateTime.local();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isRunSheetOpen, setIsRunSheetOpen] = useState<boolean>(false);
  const [isAssetListOpen, setIsAssetListOpen] = useState<boolean>(false);
  const dateChangeDebounce = new Subject<DateTime>();

  useEffect(() => {
    loadStaffMemberStatus(staffMemberId!);
    loadOverdueJobs(deviceDepotId);
    loadKioskNote(deviceDepotId);
    loadExpiredLicences();
    loadRecentDefects(recentDefectCount, deviceDepotId).then(() =>
      loadFutureTasks(futureTaskCount, deviceDepotId)
    );
    loadOutOfServiceJobTasks(deviceDepotId);
    loadIncompleteShifts();
  }, []);

  useEffect(() => {
    loadWhenDateChanges(date);

    dateChangeDebounce
      .debounceTime(500)
      .distinctUntilChanged()
      .subscribe(e => {
        loadWhenDateChanges(e);
      });
  }, [date]);

  const onDateChanged = (date: DateTime) => {
    updateDate(date);
    setIsLoading(true);
    dateChangeDebounce.next(date);
  };

  const loadWhenDateChanges = (date: DateTime) => {
    setIsLoading(true);
    const from = date.startOf('day').plus({ minutes: 1 });
    const to = date.endOf('day');
    Promise.all([
      getTimesheetReminder({
        day: date.toISODate(),
        isMechanic: true,
      }),
      loadLeavesForDriver(from, to),
      loadNotesForDriver(date),
      loadJobs(date, deviceDepotId),
      loadDailyNote(date, deviceDepotId),
      loadShiftDetails(date),
      loadDriverJobs(date),
    ]).then(() => setIsLoading(false));
  };

  const renderDateOrTime = (isoDate: string) => {
    const dateTime = DateTime.fromISO(isoDate);
    return dateTime.toFormat(dateTime.day === date.day ? 'HH:mm' : 'dd MMM');
  };

  const showEnterShiftDetails = (shift: ShiftDetails) => {
    const shiftStartDay = DateTime.fromISO(shift.start).day;
    const shiftStartEnd = DateTime.fromISO(shift.end).day;

    if (shiftStartDay !== shiftStartEnd) {
      if (!shift.clockOn && !shift.clockOff && date.hasSame(DateTime.fromISO(shift.end), 'day')) {
        return true;
      } else {
        return false;
      }
    }
    if (
      !shift.clockOn &&
      !shift.clockOff &&
      date.hasSame(DateTime.fromISO(shift.start), 'day') &&
      DateTime.fromISO(shift.start) <= now
    ) {
      return true;
    }
    return false;
  };

  const showViewShiftDetails = (shift: ShiftDetails) => {
    const shiftStartDay = DateTime.fromISO(shift.start).day;
    const shiftStartEnd = DateTime.fromISO(shift.end).day;

    if (shiftStartDay !== shiftStartEnd) {
      if (shift.clockOff && date.hasSame(DateTime.fromISO(shift.clockOff), 'day')) {
        return true;
      } else {
        return false;
      }
    }
    if (shift.clockOn && shift.clockOff && DateTime.fromISO(shift.clockOn) <= now) {
      return true;
    }
    return false;
  };

  const left = (
    <div className={styles.left}>
      <div className={styles.menuBar}>
        <WithMenu color="light">
          <div className={styles.dateScroller}>
            <DayNavigation
              onDateChanged={onDateChanged}
              maxDate={DateTime.local().plus({ days: 7 })}
              date={date}
              size="lg"
              todayText="View Today"
              disabled={formatDateTimeShort(date) === formatDateTimeShort(DateTime.local())}
            />
          </div>
        </WithMenu>
      </div>
      <div className={styles.welcomeMessage}>
        <h1>Welcome, {userDisplayName}</h1>
        {status?.isClockedOn ? null : <div>You are not clocked on</div>}
      </div>
      <div className={styles.message}>
        <div className={styles.transientContentContainer}>
          {shiftDetails ? (
            <Spinner spinnerClassName={styles.shiftSpinner} show={isLoading}>
              <div className={styles.shifts}>
                {shiftDetails.map((shift, idx) => (
                  <div key={idx} className={styles.shiftDetails}>
                    <div className={styles.shiftName}>
                      <h4>{shift.name}</h4>
                      <AlternateDepotBadge
                        targetDepotId={shift.shiftDepotId}
                        targetDepotName={shift.shiftDepotName}
                        defaultKioskDepotId={deviceDepotId}
                        className={styles.shiftDepot}
                      />
                    </div>
                    <div className={styles.details}>
                      <div className={styles.bounds}>
                        <div className={styles.shiftTitle}>
                          <span>Shift Start</span>
                          <span>Shift End</span>
                          <span>Unpaid Breaks</span>
                        </div>
                        <div className={styles.shiftTimes}>
                          <DateTimeFormat value={shift.start} timezone={timezone} />
                          <DateTimeFormat value={shift.end} timezone={timezone} />
                          <DurationFormat value={shift.unpaidBreaks} />
                        </div>
                      </div>
                    </div>{' '}
                    {shift.workmates && shift.workmates.length > 0 && (
                      <div className={styles.workmates}>
                        <h5>
                          <UserIcon /> Workmates
                        </h5>
                        <p>{shift.workmates.join(',  ')}</p>
                      </div>
                    )}
                    <div className={styles.action}>
                      {showViewShiftDetails(shift) && (
                        <Link
                          to={`/wskiosk/complete-shift/${shift.shiftId}/view`}
                          className={styles.view}>
                          <Button size="lg" type="button">
                            View Shift Details
                          </Button>
                        </Link>
                      )}

                      {showEnterShiftDetails(shift) && (
                        <Link to={`/wskiosk/complete-shift/${shift.shiftId}`}>
                          <Button size="lg" type="button">
                            Enter Shift Details
                          </Button>
                        </Link>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </Spinner>
          ) : (
            <span>No shifts found at this time</span>
          )}
          {kioskNote && (
            <KioskNote
              kioskNote={kioskNote}
              createOrUpdateKioskNote={createOrUpdateKioskNote}
              loadKioskNote={loadKioskNote}
              deviceDepotId={deviceDepotId}
            />
          )}
          <DailyNote dailyNote={dailyNote} styles={styles} />
          <AdditionalHomeCards
            notes={notes}
            approvedLeaves={approvedLeaves}
            expiredLicences={expiredLicences}
            incompleteShifts={incompleteShifts}
            needToCompleteTimesheet={needToCompleteTimesheet}
            timezone={timezone}
            styles={styles}
            markNotesReadForDriverOnDayHandler={notesModel.markNotesReadForDriverOnDay}
            date={date}
          />
        </div>
      </div>
      <div className={styles.buttonBar}>
        <IconButton icon={<SignOutIcon />} onClick={() => onSignOut()} label={'Log Out'} />
        {status?.isClockedOn ? (
          <Button
            size="lg"
            type="button"
            onClick={() => clockOff({ id: status.clockId as string })}>
            Clock Off
          </Button>
        ) : (
          <Link to="/wskiosk/driver-declaration">
            <Button size="lg" type="button">
              Clock On
            </Button>
          </Link>
        )}
      </div>
    </div>
  );

  const WSJobDisplay = ({ job, timeClassName }: { job: JobSummary; timeClassName?: string }) => {
    return (
      <LinkedCard className={styles.card} to={`/wskiosk/job/${job.jobId}`}>
        <div className={styles.cardContent}>
          <div className={styles.asset}>
            <AssetName
              categoryId={job.asset.assetCategoryId}
              subCategoryId={job.asset.assetSubCategoryId}
              assetName={job.asset.name}
              outOfService={job.asset.outOfService}
              isLowFloor={job.asset.isLowFloor}
            />
          </div>
          <div className={styles.jobTasks}>
            {job.tasks.map((t, idx) => (
              <div key={idx} className={styles.jobTaskDetailsContainer}>
                <span>{t.description}</span>
                <OnHoldIcon onHoldStatus={t.onHoldStatus} className={styles.onHoldIcons} />
                {t.fitForService !== null ? (
                  t.fitForService === true ? (
                    <div className={styles.fit}>
                      <CheckIcon />
                    </div>
                  ) : (
                    <div className={styles.unfit}>
                      <BanIcon />
                    </div>
                  )
                ) : (
                  <span className={styles.notDone} />
                )}
              </div>
            ))}
          </div>
          <span className={cn(styles.jobBounds, timeClassName)}>
            <div>{renderDateOrTime(job.start)}</div>
            <div>-</div>
            <div>{renderDateOrTime(job.end)}</div>
          </span>
        </div>
        <ArrowRightIcon />
      </LinkedCard>
    );
  };

  const myJobs = jobs.filter(j => !overdueJobs.some(o => o.jobId === j.jobId));

  const right = (
    <div className={styles.right}>
      <div className={styles.topBar}>
        <div>
          <div className={styles.currentDate}>{date.toLocaleString(DateTime.DATE_HUGE)}</div>
          <div className={styles.depotBadge}>
            {deviceDepot && <DepotBadge depotDescription={deviceDepot.description} />}
          </div>
        </div>
        <div className={styles.sliderButtons}>
          <AssetListSlider
            isOpen={isAssetListOpen}
            width={50}
            toggleSlider={() => setIsAssetListOpen(!isAssetListOpen)}
          />
          <RunsheetSlider
            deviceDepot={deviceDepot}
            isOpen={isRunSheetOpen}
            toggleSlider={() => setIsRunSheetOpen(!isRunSheetOpen)}
            width={50}
            date={date}
          />
        </div>
      </div>
      <div className={styles.lists}>
        {overdueJobs.length > 0 && (
          <div className={styles.cardContainer}>
            <h3 className={styles.overdueTitle}>
              <ErrorIcon className={styles.overdueIcon} />
              My Overdue Jobs
            </h3>
            {overdueJobs.map((job, idx) => (
              <WSJobDisplay timeClassName={styles.overdueTimes} key={idx} job={job} />
            ))}
          </div>
        )}
        {driverJobs.length > 0 && (
          <div>
            <h3>My Driver Jobs</h3>
            {driverJobs.map((job, idx) => (
              <JobItem
                key={idx}
                acknowledgeJob={acknowledgeJob}
                date={date}
                job={job}
                isMechanic
                isSecondaryDriver={staffMemberId === job.secondaryStaffMemberId}
                companyHasDriversApp={companyHasDriversApp}
                attachment={attachment}
                getJobAttachment={getJobAttachment}
              />
            ))}
          </div>
        )}
        <div className={styles.cardContainer}>
          <h3>My Jobs</h3>
          {myJobs.length ? (
            myJobs.map((j, i) => <WSJobDisplay key={i} job={j} />)
          ) : (
            <div className={styles.emptyList}>No tasks have been assigned to you.</div>
          )}
        </div>
        {outOfServiceJobTasks.length > 0 && (
          <div className={styles.cardContainer}>
            <h3>Vehicles marked out of service</h3>
            {outOfServiceJobTasks.map((j, i) => (
              <LinkedCard key={i} className={styles.card} to={`/wskiosk/job/${j.jobId}`}>
                <div className={styles.cardContent}>
                  <div className={styles.asset}>
                    <AssetName
                      categoryId={j.assetCategoryId}
                      subCategoryId={j.assetSubCategoryId}
                      assetName={j.assetNumber}
                      outOfService
                      isLowFloor={j.isLowFloor}
                    />
                  </div>

                  <span className={styles.longText}>{j.description}</span>
                </div>
                <ArrowRightIcon />
              </LinkedCard>
            ))}
          </div>
        )}
        <div className={styles.cardContainer}>
          <h3>Last {recentDefectCount} Unacknowledged Defects</h3>
          {recentDefects.length ? (
            recentDefects.map((j, i) => (
              <LinkedCard key={i} className={styles.card} to={`/wskiosk/defect/${j.jobTaskId}`}>
                <div className={styles.cardContent}>
                  <div className={styles.asset}>
                    <AssetName
                      categoryId={j.assetCategoryId}
                      subCategoryId={j.assetSubCategoryId}
                      assetName={j.assetNumber}
                      outOfService={j.outOfService}
                      isLowFloor={j.isLowFloor}
                    />
                  </div>

                  <span className={styles.longText}>{j.description}</span>
                </div>
                <ArrowRightIcon />
              </LinkedCard>
            ))
          ) : (
            <div className={styles.emptyList}>There are no unacknowledged defects.</div>
          )}
        </div>
        <div className={styles.cardContainer}>
          <h3>Last {futureTaskCount} Future Tasks</h3>
          {futureTasks.length ? (
            futureTasks.map((j, i) => (
              <LinkedCard key={i} className={styles.card} to={`/wskiosk/task/${j.id}`}>
                <div className={cn(styles.cardContent, styles.futureTasks)}>
                  <div className={styles.asset}>
                    <AssetName
                      categoryId={j.asset.category.id}
                      subCategoryId={j.asset.subCategoryId}
                      assetName={j.asset.name}
                      outOfService={j.outOfService}
                      isLowFloor={j.asset.isLowFloor}
                    />
                  </div>
                  <span className={styles.longText}>{j.description}</span>
                  <OnHoldIcon onHoldStatus={j.onHoldStatus} className={styles.onHoldIcons} />
                </div>
                <ArrowRightIcon />
              </LinkedCard>
            ))
          ) : (
            <div className={styles.emptyList}>There are no future tasks.</div>
          )}
        </div>
      </div>
    </div>
  );

  return (
    <div className={styles.homeComponent}>
      <SplitScreen left={left} right={right} />
    </div>
  );
});

export default Home;
