import styles from './Form.module.scss';

import { Field, FieldProps, getIn } from 'formik';
import cn from 'classnames';
import {
  unsignedIntegerSanitiser,
  unsignedDecimalSanitiser,
} from 'src/views/components/Page/fields/NumericPageField';

interface INumberFieldProps {
  dataAddress: string;
  dataValue?: number;
  className?: string;
  dataLabel?: string;
  isWide?: boolean;
  readOnly?: boolean;
  allowDecimal?: boolean;
  maxPointDigits?: number;
}

const NumberField: React.FC<INumberFieldProps> = ({
  readOnly,
  dataAddress,
  dataLabel,
  className,
  isWide,
  dataValue,
  allowDecimal,
  maxPointDigits,
}) => (
  <div className={cn(styles.fieldContainer, styles.autoWidthContainer, className)}>
    {dataLabel && <div className={styles.fieldLabel}>{dataLabel}</div>}
    {readOnly ? (
      <div className={cn(styles.readOnly, styles.numberField)}>{dataValue}</div>
    ) : (
      <Field name={dataAddress}>
        {({ field, form, meta }: FieldProps) => {
          const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
            const value = allowDecimal
              ? unsignedDecimalSanitiser(e.target.value, { maxPointDigits: maxPointDigits || 2 })
              : unsignedIntegerSanitiser(e.target.value, { minValue: 0 });
            form.setFieldValue(dataAddress, !value ? undefined : value);
          };

          const error = getIn(form.errors, dataAddress);
          const touched = getIn(form.touched, dataAddress) || form.submitCount > 0; // https://github.com/jaredpalmer/formik/issues/691#issuecomment-446509600

          return (
            <div
              className={cn(
                styles.numberField,
                isWide ? styles.numberFieldWide : '',
                !!error && touched ? styles.errorMargin : ''
              )}>
              <input type="text" {...field} onChange={onChange} value={dataValue || ''} />
              {touched && error && <div className={styles.error}>{error}</div>}
            </div>
          );
        }}
      </Field>
    )}
  </div>
);

export default NumberField;
