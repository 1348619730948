import { ChangeState, JobType } from 'src/api/enums';
import { mapSkillSpecIds } from 'src/domain/entities/people/staffMember/SkillSpecsHelpers';
import { StaffMemberFilter } from 'src/views/components/Page/fields/StaffMemberField';
import { getSubmitCloseModalActionGroupDef } from 'src/views/definitionBuilders/common';
import {
  ActionType,
  FieldType,
  IFieldData,
  IFieldOnChange,
  ModalDefBuilder,
  PaneType,
  ShellModalSize,
} from 'src/views/definitionBuilders/types';
import { getReadonlyJobNumberFieldDef } from '../../shared/commonFieldDefBuilders';
import { DateTime } from 'luxon';
import { formatDateTimeMed } from 'src/domain/dateHelper';
import { DownloadIcon, EditIcon, PlusIcon } from 'src/images/icons';
import getAddAttachmentModalDef from './getAddAttachmentModalDef';

type RailBookingJobItem = Operations.Domain.Queries.ViewRailBooking.RailBookingJobItem;
type AssetItem = Common.Queries.Workshop.GetFleetAssetList.AssetItem;
type SubcontractorItem = Common.Dtos.SubcontractorItem;
type GenerateShiftAttachmentIdCommand = Operations.Domain.Commands.RailTemplate.GenerateShiftAttachmentId.GenerateShiftAttachmentIdCommand;

export default function getMaintainJobModalDef(
  isUpdating: boolean,
  fleetAssets: AssetItem[],
  subcontractors: SubcontractorItem[],
  getAttachmentId: (query: GenerateShiftAttachmentIdCommand) => Promise<string>,
  downloadAttachment: (attachmentId: string, fileName: string) => Promise<void>
): ModalDefBuilder {
  return modalDefApi => {
    return {
      title: 'Edit Job',
      asForm: true,
      panels: [
        {
          panes: [
            {
              paneType: PaneType.formFieldsPane,
              columnCount: 2,
              fields: [
                getReadonlyJobNumberFieldDef<RailBookingJobItem>('jobId'),
                {
                  fieldType: FieldType.readonlyField,
                  label: 'Shift Name',
                  dataAddr: 'shiftName',
                },
                {
                  fieldType: FieldType.staffMemberField,
                  label: 'Staff Member',
                  dataAddr: 'staffMember',
                  hidden: d => d.parentValue.hasSubcontractor,
                  staffMemberFilter: d => {
                    return d.parentValue.jobType.id === JobType.Rail
                      ? StaffMemberFilter.hasDriversAuthorisation
                      : StaffMemberFilter.active;
                  },
                  assetId: d => d.parentValue.asset && d.parentValue.asset.id,
                  skillSpecRequirements: d => {
                    return mapSkillSpecIds(
                      (d.parentValue as RailBookingJobItem).skillSpecRequirements
                    );
                  },
                  assetLicenceClassId: d => d.parentValue.asset?.licenceClassId,
                  onChange: (api: IFieldOnChange<SubcontractorItem>) => {
                    if (api.newFieldValue) {
                      const addr = api.fieldDataAddr;
                      addr.pop();
                      api.setFormValue([...addr, 'subcontractor'], undefined);
                    }
                  },
                },
                {
                  fieldType: FieldType.assetSelectField,
                  label: 'Vehicle',
                  dataAddr: 'asset',
                  optionItems: fleetAssets,
                  valueKey: 'id',
                  descriptionKey: 'name',
                  columnWidth: '10em',
                  hideAssetInfo: true,
                  tooltip: d =>
                    d.parentValue.isChildJob || d.parentValue.hasProgress
                      ? "To change the vehicle on a job with progress, open the Job's page and use Vehicle Swap in the triple dot menu"
                      : undefined,
                  staffMemberId: (d: any) =>
                    d.parentValue.staffMember && d.parentValue.staffMember.id,
                  hidden: (d: IFieldData<AssetItem>) =>
                    (d.parentValue.jobType &&
                      d.parentValue.jobType.id === JobType.RailCustomerService) ||
                    d.parentValue.hasSubcontractor,
                  readonly: d => d.parentValue.isChildJob || d.parentValue.hasProgress,
                  techSpecRequirements: d =>
                    (d.parentValue as RailBookingJobItem).techSpecRequirements,
                },
                {
                  fieldType: FieldType.selectField,
                  label: 'Subcontractor',
                  dataAddr: 'subcontractor',
                  optionItems: subcontractors,
                  valueKey: 'id',
                  descriptionKey: 'name',
                  columnWidth: '15em',
                  hidden: d => !d.parentValue.hasSubcontractor,
                  onChange: (api: IFieldOnChange<SubcontractorItem>) => {
                    if (api.newFieldValue) {
                      const addr = api.fieldDataAddr;
                      addr.pop();
                      api.setFormValue([...addr, 'asset'], undefined);
                      api.setFormValue([...addr, 'staffMember'], undefined);
                    }
                  },
                  linkTo: (d: IFieldData<AssetItem>) =>
                    d.fieldValue ? `/operations/subcontractors/${d.fieldValue.id}` : undefined,
                },
              ],
            },
          ],
        },
        {
          title: 'Attachments',
          panes: [
            {
              paneType: PaneType.tablePane,
              dataAddr: 'attachmentDetails',
              fields: [
                {
                  fieldType: FieldType.textField,
                  dataAddr: ['fileName'],
                  readonly: true,
                  label: 'File Name',
                },
                {
                  fieldType: FieldType.yesNoField,
                  readonly: true,
                  dataAddr: ['availableOnTablet'],
                  label: 'Available On Tablet',
                },
                {
                  fieldType: FieldType.yesNoField,
                  dataAddr: ['availableOnKiosk'],
                  label: 'Available On Kiosk',
                  readonly: true,
                },

                {
                  fieldType: FieldType.dateTimeField,
                  dataAddr: ['createdOn'],
                  label: 'Uploaded',
                  readonly: true,
                  formatReadonly: d => {
                    const date = d.fieldValue
                      ? formatDateTimeMed(d.fieldValue)
                      : formatDateTimeMed(DateTime.local().toString());

                    return `${date} ${
                      d.parentValue.createdBy ? `by ${d.parentValue.createdBy}` : ''
                    }`;
                  },
                },
                {
                  fieldType: FieldType.actionListField,
                  columnWidth: '1px',
                  nowrap: true,
                  actionGroups: [
                    {
                      actions: [
                        {
                          actionType: ActionType.actionButton,
                          label: `Download`,
                          icon: <DownloadIcon />,
                          onClick: d => {
                            downloadAttachment(d.actionValue.attachmentId, d.actionValue.fileName);
                          },
                          hidden: d =>
                            d.actionValue.changeState === ChangeState.Added ||
                            d.actionValue.changeState === ChangeState.Modified,
                        },
                        {
                          actionType: ActionType.modalActionButton,
                          label: 'Edit',
                          icon: <EditIcon />,
                          modalSize: ShellModalSize.oneThird,
                          hidden: d => d.actionValue.changeState === ChangeState.Deleted,
                          modalDef: api => ({
                            title: 'Edit Attachment',
                            asForm: true,
                            dataAddr: 'attachmentDetails',
                            explicitData: {
                              id: api.actionData.actionValue.id,
                              attachmentId: api.actionData.actionValue.attachmentId,
                              file: api.actionData.actionValue.file,
                              fileName: api.actionData.actionValue.fileName,
                              availableOnTablet: api.actionData.actionValue.availableOnTablet,
                              availableOnKiosk: api.actionData.actionValue.availableOnKiosk,
                              createdOn: api.actionData.actionValue.createdOn,
                              changeState: ChangeState.Unchanged,
                            },
                            panels: [
                              {
                                panes: [
                                  {
                                    paneType: PaneType.formFieldsPane,
                                    fields: [
                                      {
                                        fieldType: FieldType.textField,
                                        label: 'File name',
                                        mandatory: true,
                                        dataAddr: 'fileName',
                                        validate: fapi => {
                                          if (
                                            fapi.fieldValue &&
                                            !fapi.fieldValue.endsWith('.pdf')
                                          ) {
                                            return 'Please ensure the filename ends with .pdf';
                                          }
                                          if (
                                            fapi.fieldValue &&
                                            fapi.fieldValue.trim() === '.pdf'
                                          ) {
                                            return '\'.pdf\' is not a valid file name';
                                          }
                                          return undefined;
                                        },
                                      },
                                      {
                                        fieldType: FieldType.yesNoField,
                                        label: "Available on Driver's Tablet?",
                                        mandatory: true,
                                        dataAddr: 'availableOnTablet',
                                      },
                                      {
                                        fieldType: FieldType.customField,
                                        label: '',
                                        dataAddr: 'fake',
                                        hidden: fapi => !fapi.panelValue.availableOnTablet,
                                        readonly: true,
                                        render: () => (
                                          <p>
                                            Multiple tablet PDFs can be a distraction for the driver
                                            and could be slow to download if not optimised
                                            correctly. Please consider if this PDF is necessary for
                                            display on the driver's tablet.
                                          </p>
                                        ),
                                      },
                                      {
                                        fieldType: FieldType.yesNoField,
                                        label: "Available on Driver's Kiosk & App?",
                                        mandatory: true,
                                        dataAddr: 'availableOnKiosk',
                                      },
                                      {
                                        fieldType: FieldType.textField,
                                        dataAddr: ['attachmentId'],
                                        hidden: true,
                                        label: '',
                                        columnWidth: '1px',
                                      },
                                    ],
                                  },
                                ],
                              },
                            ],
                            secondaryActions: [getSubmitCloseModalActionGroupDef('Ok')],
                            onFormSubmit: values => {
                              const dataAddr = api.actionData.fieldDataAddr;
                              const val = {
                                ...values,
                                attachmentId: values.attachmentId,
                                fileName: values.fileName,
                                file: values.file,
                                availableOnTablet: values.availableOnTablet,
                                availableOnKiosk: values.availableOnKiosk,
                                changeState: ChangeState.Modified,
                              };

                              api.parentFormApi.setValue(dataAddr, val);
                            },
                          }),
                        },
                        {
                          actionType: ActionType.removeArrayItemActionButton,
                          label: 'Remove Attachment',
                        },
                      ],
                    },
                  ],
                },
              ],
            },
            {
              paneType: PaneType.actionListPane,
              actionGroups: [
                {
                  actions: [
                    {
                      actionType: ActionType.modalActionButton,
                      icon: <PlusIcon />,
                      label: 'Add Attachment',
                      modalSize: ShellModalSize.oneThird,
                      modalDef: getAddAttachmentModalDef(getAttachmentId),
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
      secondaryActions: [getSubmitCloseModalActionGroupDef()],
      onFormSubmit: values => {
        const dataAddr = modalDefApi.actionData.fieldDataAddr;

        return Promise.resolve(modalDefApi.parentFormApi.setValue(dataAddr, { ...values }));
      },
    };
  };
}
