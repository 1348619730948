import { useState } from 'react';
import { createPortal } from 'react-dom';
import { Button } from 'reactstrap';

import styles from './NoteModal.module.scss';

export const NoteModal = ({ notes }: { notes: string }) => {
  const [visible, setVisible] = useState(false);

  return visible ? (
    createPortal(
      <div className={styles.noteModal}>
        <div className={styles.notePane}>
          <h4>Notes</h4>
          <p>{notes}</p>
          <Button onClick={d => setVisible(false)}>Close</Button>
        </div>
      </div>,
      document.getElementById('root') as HTMLElement
    )
  ) : (
    <Button onClick={d => setVisible(true)}>View Notes</Button>
  );
};
