import { PaneType, SectionDefs, FieldType } from 'src/views/definitionBuilders/types';
import { IMatchingPart } from 'src/domain/entities/workshop/parts/PartsModel';
import { IAutocompleteResult } from 'src/domain/baseTypes';

type AssetOdometerReadingItem = Workshop.Domain.Queries.GetLastAssetOdometerReadings.AssetOdometerReadingItem;

export default function getComponentRegisterPanelDef(
  lastOdometerReading: AssetOdometerReadingItem | undefined,
  searchParts: (search: string) => Promise<IAutocompleteResult<IMatchingPart>>
): SectionDefs {
  return {
    title: 'Component Register',
    key: 'component-register',
    isLogicalSubsection: true,

    panels: [
      {
        title: 'Serviced by Date',
        dataAddr: 'servicedByDate',
        hidden: d => !d.panelValue,
        panes: [
          {
            paneType: PaneType.tablePane,
            noRowsMessage: 'No components found',
            mandatory: false,
            fields: [
              {
                fieldType: FieldType.textField,
                label: 'Component',
                dataAddr: ['componentType', 'description'],
                columnWidth: '8em',
                readonly: true,
              },
              {
                fieldType: FieldType.selectAsyncField,
                label: 'Part Number',
                dataAddr: 'part',
                autoload: false,
                valueKey: 'id',
                descriptionKey: 'partNumber',
                menuWidth: 'fitContent',
                columnWidth: '8em',
                linkTo: d => `/workshop/parts/${d.fieldValue.id}`,
                loadOptionItems: searchParts,
                optionRenderer: (o: IMatchingPart) => (
                  <div style={{ whiteSpace: 'nowrap' }}>
                    {o.partNumber} - {o.description}
                  </div>
                ),
              },
              {
                fieldType: FieldType.textField,
                label: 'Serial No',
                dataAddr: 'serialNumber',
                mandatory: false,
                maxLength: 200,
                columnWidth: '5em',
              },
              {
                fieldType: FieldType.dateField,
                label: 'Last Service Date',
                dataAddr: 'lastServiceDate',
                columnWidth: '5em',
              },
              {
                fieldType: FieldType.readonlyField,
                label: 'Service Every x days',
                dataAddr: ['componentType', 'serviceIntervalDays'],
                columnWidth: '5em',
              },
            ],
          },
        ],
      },
      {
        title: 'Serviced by Kms',
        dataAddr: 'servicedByKm',
        hidden: d => !d.panelValue,
        panes: [
          {
            paneType: PaneType.formFieldsPane,
            columnCount: 3,
            fields: [
              {
                fieldType: FieldType.textField,
                dataAddr: 'none',
                label: 'Last Odometer reading',
                readonly: true,
                formatReadonly: () => {
                  const lastOdometer = lastOdometerReading && lastOdometerReading.lastOdometer;
                  const sanitised = lastOdometer ? lastOdometer.toFixed(0) : undefined;
                  return sanitised;
                },
              },
            ],
          },
          {
            paneType: PaneType.tablePane,
            noRowsMessage: 'No components found',
            mandatory: false,
            fields: [
              {
                fieldType: FieldType.textField,
                label: 'Component',
                dataAddr: ['componentType', 'description'],
                readonly: true,
                columnWidth: '8em',
              },
              {
                fieldType: FieldType.selectAsyncField,
                label: 'Part Number',
                dataAddr: 'part',
                autoload: false,
                valueKey: 'id',
                descriptionKey: 'partNumber',
                menuWidth: 'fitContent',
                columnWidth: '8em',
                linkTo: d => `/workshop/parts/${d.fieldValue.id}`,
                loadOptionItems: searchParts,
                optionRenderer: (o: IMatchingPart) => (
                  <div style={{ whiteSpace: 'nowrap' }}>
                    {o.partNumber} - {o.description}
                  </div>
                ),
              },
              {
                fieldType: FieldType.textField,
                label: 'Serial No',
                dataAddr: 'serialNumber',
                columnWidth: '5em',
                mandatory: false,
                maxLength: 200,
              },
              {
                fieldType: FieldType.numericField,
                label: 'Last Service Kms',
                dataAddr: 'lastServiceKms',
                columnWidth: '5em',
              },
              {
                fieldType: FieldType.readonlyField,
                label: 'Service Every x kms',
                dataAddr: ['componentType', 'serviceIntervalKms'],
                columnWidth: '5em',
              },
            ],
          },
        ],
      },
    ],
  };
}
