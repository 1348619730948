import styles from './Leave.module.scss';
import { Component } from 'react';
import { DateTime } from 'luxon';
import LeaveCalendar from 'src/views/kioskRoutes/components/presentational/Leave/LeaveCalendar/LeaveCalendar';
import LeaveLegend from 'src/views/kioskRoutes/components/presentational/Leave/LeaveLegend/LeaveLegend';
import LeaveTable from 'src/views/kioskRoutes/components/presentational/Leave/LeaveTable/LeaveTable';
import SplitScreen from '../../workshopKiosk/components/layout/SplitScreen/SplitScreen';
import WithMenu from '../menu/WithMenu';
import SlideInScreen from '../components/layout/SlideInScreen/SlideInScreen';
import CreateLeave from '../../components/connected/CreateLeaveForm/CreateLeaveFormContainer';
import { SingleClickAwareButton } from '../../components/presentational/SingleClickAwareButton/SingleClickAwareButton';

type LeaveDto = Common.Dtos.LeaveDto;

export interface ILeaveProps {
  leaves: LeaveDto[];
  loadLeavesForDriver: (from: DateTime, to: DateTime) => Promise<void>;
  userDisplayName: string;
  canUseTimesheets: boolean | undefined;
  timezone: string;
}

export interface ILeaveState {
  isOpened: boolean;
  currentCalendarDate: DateTime;
}

class Leave extends Component<ILeaveProps, ILeaveState> {
  constructor(props: ILeaveProps) {
    super(props);
    this.state = {
      isOpened: false,
      currentCalendarDate: DateTime.local().setZone(this.props.timezone),
    };
    this.handleToggle = this.handleToggle.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  handleToggle() {
    this.setState(prevState => {
      return { isOpened: !prevState.isOpened };
    });
  }

  handleClose() {
    this.setState({ isOpened: false });
  }

  componentDidMount() {
    this.loadLeave(this.state.currentCalendarDate);
  }

  loadLeave(date: DateTime) {
    const from = date.startOf('month');
    const to = date.endOf('month');
    this.props.loadLeavesForDriver(from, to);
  }

  afterSubmitAction() {
    this.loadLeave(this.state.currentCalendarDate);
    this.handleToggle();
  }

  render() {
    const left = (
      <div className={styles.left}>
        <WithMenu width={30}>
          <div className={styles.calendar}>
            <LeaveCalendar
              leaves={this.props.leaves}
              activeStartDateChanged={date => {
                this.setState({ currentCalendarDate: date });
                this.loadLeave(date);
              }}
              timezone={this.props.timezone}
            />
          </div>
          <LeaveLegend />
        </WithMenu>
      </div>
    );

    const right = (
      <div className={styles.right}>
        <SlideInScreen
          click={this.handleClose}
          show={this.state.isOpened}
          isRightAligned
          width={70}>
          <CreateLeave afterSubmitAction={() => this.afterSubmitAction()} />
        </SlideInScreen>
        <div className={styles.rightMain}>
          <div className={styles.leaveTable}>
            <h1>Leave for {this.state.currentCalendarDate.toFormat('MMMM yyyy')}</h1>
            {this.props.leaves && this.props.leaves.length > 0 ? (
              <LeaveTable leaves={this.props.leaves} timezone={this.props.timezone} />
            ) : (
              <div className={styles.noLeave}>
                You have no leave in {this.state.currentCalendarDate.toFormat('MMMM yyyy')}
              </div>
            )}
          </div>
          <div>
            <SingleClickAwareButton
              useReactStrapButton
              size="lg"
              type="button"
              onClick={this.handleToggle}>
              Request Leave
            </SingleClickAwareButton>
          </div>
        </div>
      </div>
    );

    return (
      <div className={styles.leaveComponent}>
        <SplitScreen left={left} right={right} leftWidth={30} />
      </div>
    );
  }
}

export default Leave;
