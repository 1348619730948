import { useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import deepEqual from 'src/infrastructure/deepEqual';
import { ChangeState, ComponentTypeServiceIntervalType } from 'src/api/enums';
import CrudPage, { CrudPageMode, ICrudPageDef } from 'src/views/components/Page/pages/CrudPage';
import { isDefined } from 'src/infrastructure/typeUtils';
import {
  PagePrimarySize,
  PaneType,
  FieldType,
  IHasChangeState,
  ActionType,
  ShellModalSize,
} from 'src/views/definitionBuilders/types';
import { IMatchingPart } from 'src/domain/entities/workshop/parts/PartsModel';
import { IAutocompleteResult } from 'src/domain/baseTypes';
import { DateTime } from 'luxon';
import { DateFormat } from 'src/views/components/DateFormat';
import { CalendarIcon } from 'src/images/icons';
import { getHumanizedDueTextByKms, getHumanizedDueTextByDate } from './componentRegisterHelpers';
import getScheduleServiceModalDef from '../serviceHistory/getScheduleServiceModalDef';
import { ScheduleServiceArgs } from 'src/domain/entities/workshop/asset/AssetModel';
import PrimaryTitle from 'src/views/components/Page/PrimaryTitle/PrimaryTitle';

type UpdateAssetComponentsCommand = Workshop.Domain.Commands.AssetComponents.UpdateAssetComponentsCommand;
type AssetComponentsItems = Workshop.Domain.Queries.AssetComponents.AssetComponentsItems;
type AssetComponentItem = Workshop.Domain.Queries.AssetComponents.AssetComponentItem;
type ComponentTypeItem = Workshop.Domain.Queries.ComponentType.ComponentTypeItem;
type AssetDetails = Workshop.Domain.Queries.GetAsset.AssetDetails;
type AssetOdometerReadingItem = Workshop.Domain.Queries.GetLastAssetOdometerReadings.AssetOdometerReadingItem;
type PartsGroupItem = Workshop.Domain.Queries.GetPartsGroups.PartsGroupItem;
type ChecklistDetails = Workshop.Domain.Queries.GetChecklist.ChecklistDetails;
type WorkshopDepot = Common.Queries.Workshop.GetWorkshopDepots.WorkshopDepotDto;
type JobSummaryDto = Workshop.Domain.Queries.Job.JobSummaryDto;

export interface IComponentsRegisterProps {
  mode: CrudPageMode;
  canManageComponents: boolean;
  asset: AssetDetails | undefined;
  assetComponents: AssetComponentsItems | undefined;
  componentTypes: Array<ComponentTypeItem>;
  checklists: ChecklistDetails[];
  partsGroups: PartsGroupItem[];
  lastOdometerReading: AssetOdometerReadingItem | undefined;
  onLoadAsset: (id: string) => Promise<void>;
  onLoadAssetComponents: (id: string) => Promise<void>;
  onLoadComponentTypes: () => Promise<void>;
  onUpdateAssetComponents: (command: UpdateAssetComponentsCommand) => Promise<void>;
  searchParts: (search: string) => Promise<IAutocompleteResult<IMatchingPart>>;
  loadLastOdometerReading: (id: string) => Promise<void>;
  loadChecklists: (includeInactiveChecklists: boolean) => Promise<void>;
  loadPartsGroups: (includeInactivePartsGroups: boolean) => Promise<void>;
  onSchedulePreventativeMaintenanceService: (command: ScheduleServiceArgs) => Promise<void>;
  workshopDepots: Array<WorkshopDepot>;
  defaultWorkshopDepot: WorkshopDepot | undefined;
  futureJobs: Array<JobSummaryDto>;
}

interface IComponentsRegisterRouteParams {
  id: string;
}

type InternalProps = IComponentsRegisterProps & RouteComponentProps<IComponentsRegisterRouteParams>;

interface IFormData {
  asset: AssetDetails | undefined;
  servicedByKm: AssetComponentItem[];
  servicedByDate: AssetComponentItem[];
}

export const ComponentsRegister: React.FC<InternalProps> = (props: InternalProps) => {
  const assetId = props.match.params.id;

  useEffect(() => {
    Promise.all([
      props.onLoadAssetComponents(assetId),
      props.loadLastOdometerReading(assetId),
      props.onLoadComponentTypes(),
      props.loadChecklists(false),
      props.loadPartsGroups(false),
    ]);
  }, [assetId]);

  const getData = (): IFormData => {
    const { asset, assetComponents } = props;
    const assetByKm =
      (assetComponents &&
        assetComponents.components &&
        assetComponents.components.filter(
          x => x.componentType.serviceIntervalType === ComponentTypeServiceIntervalType.Km
        )) ||
      [];

    const assetByDate =
      (assetComponents &&
        assetComponents.components &&
        assetComponents.components.filter(
          x => x.componentType.serviceIntervalType === ComponentTypeServiceIntervalType.Day
        )) ||
      [];

    return {
      asset,
      servicedByKm: assetByKm,
      servicedByDate: assetByDate,
    };
  };

  const handlePreSubmitForUpdate = (a: IFormData): UpdateAssetComponentsCommand => {
    const originalAssetComponents = props.assetComponents && props.assetComponents.components;
    const getChangeState = (i: AssetComponentItem & IHasChangeState) => {
      if (i.changeState === ChangeState.Deleted) {
        return ChangeState.Deleted;
      }

      const originalItem =
        originalAssetComponents && originalAssetComponents.find(x => x.id === i.id);

      if (i.changeState === ChangeState.Added || originalItem === undefined) {
        return ChangeState.Added;
      }

      if (!originalItem) {
        throw new Error('Cannot find original Asset Component Item');
      }
      return deepEqual(i, originalItem) ? ChangeState.Unchanged : ChangeState.Modified;
    };

    return {
      assetId: assetId,
      components: a.servicedByDate.concat(a.servicedByKm).map(x => ({
        id: x.id,
        componentTypeId: x.componentType.id!,
        partId: x.part && x.part.id,
        serialNumber: x.serialNumber,
        dueDate: x.dueDate,
        dueKms: x.dueKms,
        lastServiceDate: x.lastServiceDate,
        lastServiceKms: x.lastServiceKms,
        serviceIntervalDays: x.componentType.serviceIntervalDays,
        serviceIntervalKms: x.componentType.serviceIntervalKms,
        changeState: getChangeState(x),
        checklistId: x.checklistId,
        partsGroupId: x.partsGroupId,
      })),
    };
  };

  const getPageDef = (mode: CrudPageMode, updating: boolean): ICrudPageDef => {
    const {
      asset,
      componentTypes,
      onUpdateAssetComponents,
      lastOdometerReading,
      checklists,
      partsGroups,
      onSchedulePreventativeMaintenanceService,
      workshopDepots,
      defaultWorkshopDepot,
      futureJobs,
    } = props;
    const assetName = asset && asset.name;
    return {
      primarySize: PagePrimarySize.full,
      primarySection: {
        title: (
          <PrimaryTitle
            title={`${assetName} - Components Register`}
            link="https://www.notion.so/central-ops/Preventative-Maintenance-d3bbabea26a94a9780bd570eb3e65fd6"></PrimaryTitle>
        ),
        panels: [
          {
            title: 'Serviced by Date',
            dataAddr: 'servicedByDate',
            panes: [
              {
                paneType: PaneType.tablePane,
                noRowsMessage: 'No components found',
                dataRequiredForRows: 'sectionValue',
                fields: [
                  {
                    fieldType: FieldType.selectField,
                    label: 'Component',
                    dataAddr: ['componentType', 'id'],
                    useValueOnly: true,
                    valueKey: 'id',
                    descriptionKey: 'description',
                    menuWidth: 'fitContent',
                    mandatory: true,
                    onChange: api => {
                      const serviceInterval = componentTypes.find(
                        c => c.id === api.fieldData.fieldValue
                      )?.serviceIntervalDays;

                      api.setFormValue(
                        [
                          'servicedByDate',
                          api.fieldDataAddr[1],
                          'componentType',
                          'serviceIntervalDays',
                        ],
                        serviceInterval
                      );
                    },
                    optionItems: componentTypes.filter(
                      c => c.serviceIntervalType === ComponentTypeServiceIntervalType.Day
                    ),
                    valuesToDisable: d => {
                      return (d.panelValue as Array<AssetComponentItem & IHasChangeState>)
                        .map(s => s.componentType && s.componentType.id)
                        .filter(isDefined);
                    },
                    columnWidth: '12em',
                  },
                  {
                    fieldType: FieldType.textField,
                    label: 'Serial No',
                    dataAddr: 'serialNumber',
                    mandatory: false,
                    maxLength: 200,
                    columnWidth: '5em',
                  },
                  {
                    fieldType: FieldType.selectAsyncField,
                    label: 'Part Number',
                    dataAddr: 'part',
                    autoload: true,
                    valueKey: 'id',
                    descriptionKey: 'partNumber',
                    menuWidth: 'fitContent',
                    columnWidth: '8em',
                    linkTo: d => `/workshop/parts/${d.fieldValue.id}`,
                    loadOptionItems: props.searchParts,
                    optionRenderer: (o: IMatchingPart) => (
                      <div style={{ whiteSpace: 'nowrap' }}>
                        {o.partNumber} - {o.description}
                      </div>
                    ),
                  },
                  {
                    fieldType: FieldType.selectField,
                    label: 'Parts Group',
                    dataAddr: 'partsGroupId',
                    useValueOnly: true,
                    valueKey: 'id',
                    descriptionKey: 'name',
                    menuWidth: 'fitContent',
                    optionItems: partsGroups,
                    columnWidth: '8em',
                  },
                  {
                    fieldType: FieldType.selectField,
                    label: 'Checklist',
                    dataAddr: 'checklistId',
                    useValueOnly: true,
                    valueKey: 'id',
                    descriptionKey: 'name',
                    menuWidth: 'fitContent',
                    optionItems: checklists,
                    columnWidth: '8em',
                  },
                  {
                    fieldType: FieldType.dateField,
                    label: 'Last Service Date',
                    dataAddr: 'lastServiceDate',
                    columnWidth: '5em',
                  },
                  {
                    fieldType: FieldType.numericField,
                    label: 'Service Every x days',
                    numericConfig: { numericType: 'unsignedInt', maxTotalDigits: 4 },
                    dataAddr: ['componentType', 'serviceIntervalDays'],
                    columnWidth: '5em',
                  },
                  {
                    fieldType: FieldType.readonlyField,
                    label: 'Recommended next service',
                    dataAddr: 'none',
                    columnWidth: '5em',
                    formatReadonly: d => {
                      const assetComponent = d.parentValue as AssetComponentItem;
                      if (!assetComponent.lastServiceDate) {
                        return undefined;
                      }
                      const lastServiceDate = DateTime.fromISO(assetComponent.lastServiceDate);
                      if (!lastServiceDate.isValid) {
                        return undefined;
                      }
                      const nextRecommendedDate = lastServiceDate.plus({
                        days: assetComponent.componentType.serviceIntervalDays!,
                      });
                      return <DateFormat value={nextRecommendedDate} />;
                    },
                  },
                  {
                    fieldType: FieldType.dateField,
                    label: 'Due Date',
                    dataAddr: 'dueDate',
                    columnWidth: '5em',
                  },
                  {
                    fieldType: FieldType.readonlyField,
                    label: 'Status',
                    dataAddr: 'none',
                    columnWidth: '5em',
                    formatReadonly: d => {
                      const assetComponent = d.parentValue as AssetComponentItem;
                      return getHumanizedDueTextByDate(assetComponent);
                    },
                  },
                  {
                    fieldType: FieldType.actionListField,
                    columnWidth: '1px',
                    actionGroups: [
                      {
                        actions: [
                          {
                            hidden: updating,
                            actionType: ActionType.modalActionButton,
                            label: 'Schedule Service',
                            icon: <CalendarIcon />,
                            modalSize: ShellModalSize.oneThird,
                            modalDef: api =>
                              getScheduleServiceModalDef(
                                onSchedulePreventativeMaintenanceService,
                                futureJobs,
                                undefined,
                                asset!.id,
                                api.actionData.parentValue.id,
                                workshopDepots,
                                defaultWorkshopDepot
                              )(api),
                          },
                          {
                            actionType: ActionType.removeArrayItemActionButton,
                            label: 'Remove Asset Component',
                            hidden: !updating,
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
              {
                paneType: PaneType.actionListPane,
                actionGroups: [
                  {
                    actions: [
                      {
                        actionType: ActionType.addArrayItemActionButton,
                        label: 'Add Asset Component',
                        hidden: !updating,
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            title: 'Serviced by Kms',
            dataAddr: 'servicedByKm',
            panes: [
              {
                paneType: PaneType.formFieldsPane,
                columnCount: 3,
                fields: [
                  {
                    fieldType: FieldType.textField,
                    dataAddr: 'none',
                    label: 'Last Odometer reading',
                    readonly: true,
                    formatReadonly: d => {
                      const lastOdometer = lastOdometerReading && lastOdometerReading.lastOdometer;
                      const sanitised = lastOdometer ? lastOdometer.toFixed(0) : undefined;
                      return sanitised;
                    },
                  },
                ],
              },
              {
                paneType: PaneType.tablePane,
                noRowsMessage: 'No components found',
                mandatory: false,
                dataRequiredForRows: 'sectionValue',
                fields: [
                  {
                    fieldType: FieldType.selectField,
                    label: 'Component',
                    dataAddr: ['componentType', 'id'],
                    useValueOnly: true,
                    valueKey: 'id',
                    descriptionKey: 'description',
                    menuWidth: 'fitContent',
                    mandatory: true,
                    optionItems: componentTypes.filter(
                      c => c.serviceIntervalType === ComponentTypeServiceIntervalType.Km
                    ),
                    onChange: api => {
                      const serviceInterval = componentTypes.find(
                        c => c.id === api.fieldData.fieldValue
                      )?.serviceIntervalKms;

                      api.setFormValue(
                        [
                          'servicedByKm',
                          api.fieldDataAddr[1],
                          'componentType',
                          'serviceIntervalKms',
                        ],
                        serviceInterval
                      );
                    },
                    valuesToDisable: d => {
                      return (d.panelValue as Array<AssetComponentItem>)
                        .map(s => s.componentType && s.componentType.id)
                        .filter(isDefined);
                    },
                    columnWidth: '12em',
                  },
                  {
                    fieldType: FieldType.textField,
                    label: 'Serial No',
                    dataAddr: 'serialNumber',
                    columnWidth: '5em',
                    mandatory: false,
                    maxLength: 200,
                  },
                  {
                    fieldType: FieldType.selectAsyncField,
                    label: 'Part Number',
                    dataAddr: 'part',
                    autoload: true,
                    valueKey: 'id',
                    descriptionKey: 'partNumber',
                    menuWidth: 'fitContent',
                    columnWidth: '8em',
                    linkTo: d => `/workshop/parts/${d.fieldValue.id}`,
                    loadOptionItems: props.searchParts,
                    optionRenderer: (o: IMatchingPart) => (
                      <div style={{ whiteSpace: 'nowrap' }}>
                        {o.partNumber} - {o.description}
                      </div>
                    ),
                  },
                  {
                    fieldType: FieldType.selectField,
                    label: 'Parts Group',
                    dataAddr: 'partsGroupId',
                    useValueOnly: true,
                    valueKey: 'id',
                    descriptionKey: 'name',
                    menuWidth: 'fitContent',
                    optionItems: partsGroups,
                    columnWidth: '8em',
                  },
                  {
                    fieldType: FieldType.selectField,
                    label: 'Checklist',
                    dataAddr: 'checklistId',
                    useValueOnly: true,
                    valueKey: 'id',
                    descriptionKey: 'name',
                    menuWidth: 'fitContent',
                    optionItems: checklists,
                    columnWidth: '8em',
                  },
                  {
                    fieldType: FieldType.numericField,
                    label: 'Last Service Kms',
                    dataAddr: 'lastServiceKms',
                    columnWidth: '5em',
                  },
                  {
                    fieldType: FieldType.numericField,
                    label: 'Service Every x kms',
                    numericConfig: { numericType: 'unsignedInt', maxTotalDigits: 7 },
                    dataAddr: ['componentType', 'serviceIntervalKms'],
                    columnWidth: '5em',
                  },
                  {
                    fieldType: FieldType.readonlyField,
                    label: 'Recommended next service',
                    dataAddr: 'none',
                    columnWidth: '5em',
                    formatReadonly: d => {
                      const assetComponent = d.parentValue as AssetComponentItem;
                      if (!assetComponent.lastServiceKms) {
                        return undefined;
                      }
                      const lastServiceKms = assetComponent.lastServiceKms;
                      const nextRecommendedKms =
                        lastServiceKms + assetComponent.componentType.serviceIntervalKms!;
                      return nextRecommendedKms;
                    },
                  },
                  {
                    fieldType: FieldType.numericField,
                    label: 'Due Kms',
                    dataAddr: 'dueKms',
                    columnWidth: '5em',
                  },
                  {
                    fieldType: FieldType.readonlyField,
                    label: 'Status',
                    dataAddr: 'none',
                    columnWidth: '5em',
                    formatReadonly: d => {
                      const assetComponent = d.parentValue as AssetComponentItem;
                      return getHumanizedDueTextByKms(assetComponent, lastOdometerReading);
                    },
                  },
                  {
                    fieldType: FieldType.actionListField,
                    columnWidth: '1px',
                    actionGroups: [
                      {
                        actions: [
                          {
                            hidden: updating,
                            actionType: ActionType.modalActionButton,
                            label: 'Schedule Service',
                            icon: <CalendarIcon />,
                            modalSize: ShellModalSize.oneThird,
                            modalDef: api =>
                              getScheduleServiceModalDef(
                                onSchedulePreventativeMaintenanceService,
                                futureJobs,
                                undefined,
                                asset!.id,
                                api.actionData.parentValue.id,
                                workshopDepots,
                                defaultWorkshopDepot
                              )(api),
                          },
                          {
                            actionType: ActionType.removeArrayItemActionButton,
                            label: 'Remove Asset Component',
                            hidden: !updating,
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
              {
                paneType: PaneType.actionListPane,
                hidden: !updating,
                actionGroups: [
                  {
                    actions: [
                      {
                        actionType: ActionType.addArrayItemActionButton,
                        label: 'Add Asset Component',
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
        onFormPreSubmit: handlePreSubmitForUpdate,
        onFormSubmit: onUpdateAssetComponents,
      },
    };
  };

  const { onLoadAsset, canManageComponents } = props;
  const mode = 'update';
  return (
    <CrudPage
      def={({ updating }) => getPageDef(mode, updating)}
      mode={mode}
      isEditingForbidden={!canManageComponents}
      onLoadData={() => onLoadAsset(assetId)}
      data={getData()}
    />
  );
};
