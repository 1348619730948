import { types, flow } from 'mobx-state-tree';
import { getAjax } from 'src/domain/services';

type StaffMemberDto = Common.Dtos.StaffMemberDto;

export const StaffMembersModel = types
  .model('StaffMembersModel', {
    mechanics: types.array(types.frozen<StaffMemberDto>()),
    allStaffMembers: types.array(types.frozen<StaffMemberDto>()),
  })
  .views(self => ({
    get activeStaffMembers() {
      return self.allStaffMembers.filter(x => x.active);
    },
    get workshopStaff() {
      return self.allStaffMembers.filter(x => x.isWorkshopStaff);
    },
  }))
  .actions(self => {
    const ajax = getAjax(self);

    const loadAllStaffMembers = flow(function*() {
      self.allStaffMembers = yield ajax.raw
        .get(`/api/kiosk/workshop/people`)
        .toPromise()
        .then<StaffMemberDto[]>(r => r.response);
    });

    return {
      loadAllStaffMembers,
    };
  });
