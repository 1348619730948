import { IRootStoreModel } from 'src/domain/entities/RootStoreModel';
import { UiRoute } from 'src/domain/services/buildUiRoutes';
import { UiRouteName } from 'src/domain/baseTypes';

export default function buildUiRoutes(store: IRootStoreModel) {
  const roleCreate = new UiRoute(
    UiRouteName.peopleRoleCreate,
    '/people/roles/create',
    () => 'Create Role',
    false
  );

  const roleManage = new UiRoute(
    UiRouteName.peopleRoleManage,
    '/people/roles/:id',
    () => 'Manage Role',
    false
  );

  const rolesList = new UiRoute(
    UiRouteName.peopleRoleList,
    '/people/roles',
    () => 'Roles',
    false,
    true,
    [roleCreate, roleManage]
  );

  return {
    [UiRouteName.peopleRoleCreate]: roleCreate,
    [UiRouteName.peopleRoleList]: rolesList,
    [UiRouteName.peopleRoleManage]: roleManage,
  };
}
