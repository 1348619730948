import { Component } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Minus } from 'src/infrastructure/minusTypeGenerator';
import { RouteContext } from 'src/views/App';

// Inject the standard route props into the wrapped component
function withRouteProps<P extends Partial<RouteComponentProps<{}>>>(
  WrappedComponent: React.ComponentType<P>
) {
  return class extends Component<Minus<P, RouteComponentProps<{}>>> {
    render() {
      // Using RouteContext rather than react-router's Route to access the router props
      // means that when the route changes, the wrapped component is re-rendered, even
      // if its parents are not.
      return (
        <RouteContext.Consumer>
          {rp =>
            rp ? <WrappedComponent {...(({ ...this.props, ...rp } as unknown) as P)} /> : null
          }
        </RouteContext.Consumer>
      );
    }
  };
}

export default withRouteProps;
