import './GanttSidebar.scss';

import cn from 'classnames';
import { useEffect, useState } from 'react';
import { Input, InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap';
import { Subject } from 'rxjs/Subject';
import { Subscription } from 'rxjs/Subscription';
import { SearchIcon } from 'src/images/icons';
import SimpleFilterActionButton from 'src/views/components/Page/actions/SimpleFilterActionButton';
import {
  ActionType,
  FieldDefs,
  FilterDefBuilder,
  IActionData,
} from 'src/views/definitionBuilders/types';
import { IFilterValues, IGanttCalendarGroupItem, ISizedGroup } from '../baseTypes';
import { IViewGroupFilterValues } from '../GanttCalendar';

interface IGanttSidebarProps {
  className?: string;
  rowHeightPx: number;
  renderGroup: (group: IGanttCalendarGroupItem | undefined) => React.ReactNode;
  sizedGroups: Array<IGanttCalendarGroupItem & ISizedGroup>;
  filterTitle?: string;
  filterFields?: FieldDefs[];
  filterDef?: FilterDefBuilder;
  groupFilterValues: IViewGroupFilterValues | undefined;
  onSearchChange: (searchValue: string) => void;
  onFilter?: (filterValues: IFilterValues) => void;
  onFilterReset?: () => Object;
}

const GanttSidebar: React.FC<IGanttSidebarProps> = (props: IGanttSidebarProps) => {
  const valueSubject = new Subject<string>();
  const subscriptions = new Subscription();

  const [searchValue, setSearchValue] = useState<string>(
    (props.groupFilterValues && props.groupFilterValues.search) || ''
  );

  const sub = valueSubject
    .debounceTime(300)
    .distinctUntilChanged()
    .subscribe(value => props.onSearchChange(value));
  subscriptions.add(sub);

  useEffect(() => {
    return () => {
      subscriptions.unsubscribe();
    };
  }, []);

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setSearchValue(value);
    valueSubject.next(value);
  };

  const handleFilterSubmit = (values: IFilterValues) => {
    props.onFilter && props.onFilter(values);
    return Promise.resolve();
  };

  const handleFilterReset = () => {
    const { onFilterReset } = props;
    if (!onFilterReset) {
      throw new Error('Filter cannot be reset if onFilterReset is not supplied');
    }
    return onFilterReset();
  };

  const containsFilters = (filters: IFilterValues) => {
    return Object.keys(filters).filter(k => {
      const value = filters[k];
      return Array.isArray(value) ? value.length : !!value || value === 0 || value === false;
    }).length;
  };

  const {
    className,
    rowHeightPx,
    renderGroup,
    sizedGroups,
    groupFilterValues,
    filterTitle,
    filterFields,
    filterDef,
  } = props;
  const currentFilters = (groupFilterValues && groupFilterValues.filters) || {};

  return (
    <div className={cn('gantt-sidebar-component', className)}>
      <div className="groups-list" style={{ gridAutoRows: `${rowHeightPx}px` }}>
        {sizedGroups.map(g => (
          <div
            key={g.id || ''}
            className="group-item"
            style={{ gridArea: `${g.rowIndex + 1} / 1` }}>
            {g.id ? renderGroup(g) : renderGroup(undefined)}
          </div>
        ))}
      </div>
      <div className="search">
        <InputGroup>
          <Input value={searchValue} autoFocus onChange={handleSearchChange} autoComplete="off" />
          <InputGroupAddon addonType="append">
            <InputGroupText>
              <SearchIcon />
            </InputGroupText>
            {filterFields || filterDef ? (
              <SimpleFilterActionButton
                actionDef={{
                  label: filterTitle,
                  actionType: ActionType.simpleFilterActionButton,
                  filterFields,
                  filterDef,
                  onFormSubmit: handleFilterSubmit,
                  onFormReset: handleFilterReset,
                }}
                actionMeta={{
                  formSubmitting: false,
                  hideLabel: true,
                  borderless: !containsFilters(currentFilters),
                }}
                actionData={{ actionValue: currentFilters } as IActionData}
              />
            ) : null}
          </InputGroupAddon>
        </InputGroup>
      </div>
    </div>
  );
};

export default GanttSidebar;
