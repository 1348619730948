import { allJobType, getJobTypeDescriptor, JobType } from 'src/api/enums';
import { StaffMemberFilter } from 'src/views/components/Page/fields/StaffMemberField';

type JobTypeEnumeration = Operations.Domain.AggregatesModel.JobAggregate.JobType;

export function isJobTypeWithoutAsset(jobTypeId: number): boolean {
  return (
    [
      JobType.AsRequired,
      JobType.Cleaning,
      JobType.Operations,
      JobType.Office,
      JobType.RailCustomerService,
      JobType.GeneralTraining,
      JobType.Yard,
      JobType.DriverRelocation,
    ].indexOf(jobTypeId) !== -1
  );
}

export function canJobTypeHaveSubcontractor(jobTypeId: number): boolean {
  return (
    [
      JobType.Rail,
      JobType.RailCustomerService,
      JobType.RailEmergency,
      JobType.RailMarshaller,
      JobType.RailSupervisor,
      JobType.Charter,
      JobType.ContractCharter,
      JobType.OperationsSupervisor,
    ].indexOf(jobTypeId) !== -1
  );
}

export function canJobTypeHaveSecondDriver(jobTypeId: number): boolean {
  return (
    [
      JobType.Rail,
      JobType.Charter,
      JobType.CharterStaged,
      JobType.RailEmergency,
      JobType.VehicleRelocation,
      JobType.ContractCharter,
    ].indexOf(jobTypeId) !== -1
  );
}

export function doesRequireClockOnOffTimesOnly(jobTypeId: number): boolean {
  return (
    isJobTypeWithoutAsset(jobTypeId) ||
    [
      JobType.AsRequired,
      JobType.Workshop,
      JobType.Cleaning,
      JobType.Operations,
      JobType.RailMarshaller,
      JobType.RailSupervisor,
      JobType.RailCustomerService,
      JobType.GeneralTraining,
      JobType.Yard,
      JobType.DriverRelocation,
      JobType.VehicleRelocation,
      JobType.OperationsSupervisor,
      JobType.RailEmergency,
      JobType.TestDrive,
      JobType.Breakdown,
      JobType.VehicleUnavailable,
      JobType.Office,
    ].indexOf(jobTypeId) !== -1
  );
}

export function doesNotRequireAssetCompletionDetails(
  jobTypeId: number,
  isTrainingJob: boolean
): boolean {
  return (
    isTrainingJob ||
    isJobTypeWithoutAsset(jobTypeId) ||
    [
      JobType.RailMarshaller,
      JobType.RailSupervisor,
      JobType.RailCustomerService,
      JobType.GeneralTraining,
      JobType.Yard,
      JobType.DriverRelocation,
      JobType.OperationsSupervisor,
      JobType.TestDrive,
      JobType.Breakdown,
    ].indexOf(jobTypeId) !== -1
  );
}

export const RailJobTypesThatHaveRailBooking = [JobType.Rail];

export const JobTypesThatCanHaveProgressWarnings = [
  JobType.Urban,
  JobType.SchoolService,
  JobType.Rail,
  JobType.Charter,
  JobType.CharterStaged,
  JobType.VehicleRelocation,
  JobType.RailEmergency,
  JobType.TestDrive,
  JobType.Breakdown,
  JobType.ContractCharter,
].map(j => getJobTypeDescriptor(j));

export const JobTypesThatMayHaveRailBooking = [
  JobType.RailEmergency,
  JobType.VehicleRelocation,
  JobType.DriverRelocation,
  JobType.RailSupervisor,
  JobType.RailMarshaller,
  JobType.RailCustomerService,
];

export const CustomerComplaintJobTypes = [
  JobType.Urban,
  JobType.SchoolService,
  JobType.Charter,
  JobType.Rail,
  JobType.ContractCharter,
];

export const JobTypesThatMayHaveRouteTraining = [
  JobType.Urban,
  JobType.SchoolService,
  JobType.Charter,
  JobType.Rail,
  JobType.ContractCharter,
];

export const DoesNotRequireShiftName = [
  JobType.RailSupervisor,
  JobType.RailMarshaller,
  JobType.RailCustomerService,
  JobType.RailEmergency,
];

export const JobTypesWithoutOverrunReason = [JobType.RailEmergency];

export const JobTypesThatRequireApprovalNumber = [JobType.SchoolService, JobType.Urban];

export function doesJobTypeHaveRailBooking(jobTypeId: number): boolean {
  return RailJobTypesThatHaveRailBooking.indexOf(jobTypeId) !== -1;
}

export function mayJobTypeHaveRailBooking(jobTypeId: number): boolean {
  return JobTypesThatMayHaveRailBooking.indexOf(jobTypeId) !== -1;
}

export function doesNotRequireShiftName(jobTypeId: number): boolean {
  return DoesNotRequireShiftName.indexOf(jobTypeId) !== -1;
}

export function mayJobTypeHaveRouteTraining(jobTypeId: number): boolean {
  return JobTypesThatMayHaveRouteTraining.indexOf(jobTypeId) !== -1;
}

export function isJobTypeCharter(jobTypeId: number): boolean {
  return jobTypeId === JobType.Charter;
}

export function isJobTypeCharterOrContractCharter(jobTypeId: number): boolean {
  return jobTypeId === JobType.Charter || jobTypeId === JobType.ContractCharter;
}

export function isJobTypeAnyCharter(jobTypeId: number): boolean {
  return (
    jobTypeId === JobType.Charter ||
    jobTypeId === JobType.ContractCharter ||
    jobTypeId === JobType.CharterStaged
  );
}

export function isJobTypeCharterOrCharterStaged(jobTypeId: number): boolean {
  return jobTypeId === JobType.Charter || jobTypeId === JobType.CharterStaged;
}

export function isJobTypeContractCharter(jobTypeId: number): boolean {
  return jobTypeId === JobType.ContractCharter;
}

export function isJobTypeDriverRelocation(jobTypeId: number): boolean {
  return jobTypeId === JobType.DriverRelocation;
}

export function isRailJobType(jobType: JobTypeEnumeration) {
  return jobType && jobType.id === JobType.Rail;
}

export function isUrbanJobType(jobType: JobTypeEnumeration) {
  return jobType && jobType.id === JobType.Urban;
}

export function isOperationJobType(jobType: JobTypeEnumeration) {
  return jobType?.id === JobType.Operations;
}

export function isJobTypeLinkedToQuote(jobTypeId: number): boolean {
  return (
    [
      JobType.Charter,
      JobType.CharterStaged,
      JobType.DriverRelocation,
      JobType.VehicleRelocation,
      JobType.OperationsSupervisor,
    ].indexOf(jobTypeId) !== -1
  );
}

export function doesJobTypeHaveBooking(jobTypeId: number): boolean {
  return (
    [
      JobType.Charter,
      JobType.CharterStaged,
      JobType.ContractCharter,
      JobType.DriverRelocation,
      JobType.VehicleRelocation,
      JobType.OperationsSupervisor,
    ].indexOf(jobTypeId) !== -1
  );
}

export function doesJobTypeRequireDriversAuthorisation(jobTypeId: number) {
  const TypesRequiringDriverAuthorisation = [
    JobType.AsRequired,
    JobType.Charter,
    JobType.Rail,
    JobType.CharterStaged,
    JobType.RailEmergency,
    JobType.SchoolService,
    JobType.Urban,
    JobType.ContractCharter,
  ];
  return TypesRequiringDriverAuthorisation.indexOf(jobTypeId) > -1;
}

export function doesRailJobTypeRequirePax(jobTypeId: number) {
  const paxRailTypes = [JobType.Rail];

  return paxRailTypes.indexOf(jobTypeId) !== -1;
}

export function getStaffMemberFilterForJobType(jobTypeId: number) {
  if (doesJobTypeRequireDriversAuthorisation(jobTypeId)) {
    return StaffMemberFilter.hasDriversAuthorisation;
  }
  return StaffMemberFilter.active;
}

export function jobTypeRequiresApprovalNumber(jobTypeId: number) {
  return JobTypesThatRequireApprovalNumber.includes(jobTypeId);
}

export function jobTypeRequiresOverrunReason(jobeTypeId: number) {
  return !JobTypesWithoutOverrunReason.includes(jobeTypeId);
}

export const CharterDiaryJobTypes = [
  getJobTypeDescriptor(JobType.Charter),
  getJobTypeDescriptor(JobType.CharterStaged),
  getJobTypeDescriptor(JobType.Rail),
  getJobTypeDescriptor(JobType.RailSupervisor),
  getJobTypeDescriptor(JobType.RailCustomerService),
  getJobTypeDescriptor(JobType.RailMarshaller),
  getJobTypeDescriptor(JobType.RailEmergency),
  getJobTypeDescriptor(JobType.ContractCharter),
].sort((a, b) => a.description.localeCompare(b.description));

export const SortedJobTypes = allJobType.sort((a, b) => a.description.localeCompare(b.description));
