import { Component } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import deepEqual from 'src/infrastructure/deepEqual';
import { ChangeState } from 'src/api/enums';
import CrudPage, { CrudPageMode, ICrudPageDef } from 'src/views/components/Page/pages/CrudPage';
import {
  PagePrimarySize,
  PaneType,
  FieldType,
  ActionType,
  IHasChangeState,
} from 'src/views/definitionBuilders/types';
import { IAutocompleteResult } from 'src/domain/baseTypes';
import { IMatchingPart } from 'src/domain/entities/workshop/parts/PartsModel';

type UpdatePartsCommand = Workshop.Domain.Commands.AssetPart.UpdateAssetPartCommand;
type AssetParts = Workshop.Domain.Queries.AssetParts.AssetParts;
type AssetPartItem = Workshop.Domain.Queries.AssetParts.AssetPartItem;
type AssetPartCategoryItem = Workshop.Domain.Queries.AssetPartCategory.AssetPartCategoryItem;
type AssetPartSubCategoryItem = Workshop.Domain.Queries.AssetPartCategory.AssetPartSubCategoryItem;
type AssetDetails = Workshop.Domain.Queries.GetAsset.AssetDetails;

export interface IPartsRegisterProps {
  mode: CrudPageMode;
  canManagePartsRegister: boolean;
  asset: AssetDetails | undefined;
  assetParts: AssetParts | undefined;
  categories: AssetPartCategoryItem[];
  subCategories: AssetPartSubCategoryItem[];
  onLoadAsset: (id: string) => Promise<void>;
  onLoadAssetPartCategories: () => Promise<void>;
  onLoadAssetPartSubCategories: () => Promise<void>;
  searchParts: (search: string) => Promise<IAutocompleteResult<IMatchingPart>>;
  onLoadAssetParts: (id: string) => Promise<void>;
  onUpdateAssetParts: (command: UpdatePartsCommand) => Promise<void>;
}

interface IPartsRegisterRouteParams {
  id: string;
}

interface IPartsRegisterPageState {
  updating: boolean;
}

type InternalProps = IPartsRegisterProps & RouteComponentProps<IPartsRegisterRouteParams>;
interface ICategoryPart {
  name: string;
  hasCategory: boolean;
  parts: (AssetPartItem &
    IHasChangeState & {
      subCategoryName: string;
      subCategory: AssetPartSubCategoryItem;
    })[];
}

class PartsRegister extends Component<InternalProps, IPartsRegisterPageState> {
  componentDidMount() {
    this.props.onLoadAssetParts(this.assetId);
    this.props.onLoadAssetPartCategories();
    this.props.onLoadAssetPartSubCategories();
  }

  private get assetId() {
    return this.props.match.params.id;
  }

  private readonly getData = () => {
    const { asset, assetParts, categories, subCategories } = this.props;
    const categoryParts = [
      ...categories.map(c => ({
        name: c.description,
        hasCategory: true,
        parts: subCategories
          .filter(sc => sc.category.id === c.id)
          .map(sc => ({
            subCategoryName: sc.description,
            subCategory: sc,
            ...(assetParts
              ? assetParts.parts
                  .filter(p => p.subCategory && p.subCategory.id === sc.id)
                  .map(p => ({ ...p, subCategoryDescription: sc.description }))[0]
              : {}),
          })),
      })),
      {
        name: 'Other',
        hasCategory: false,
        parts: assetParts ? assetParts.parts.filter(p => !p.subCategory) : [],
        assetParts,
      },
    ];

    return { asset, categoryParts, assetParts };
  };

  private handlePreSubmitForUpdate = (a: {
    asset: AssetDetails;
    assetParts: AssetParts;
    categoryParts: ICategoryPart[];
  }): UpdatePartsCommand => {
    const originalAssetParts = this.props.assetParts;
    const getChangeState = (i: AssetPartItem & IHasChangeState) => {
      if (i.changeState === ChangeState.Added || i.changeState === ChangeState.Deleted) {
        return i.changeState;
      }
      if (!i.id) {
        return ChangeState.Added;
      }
      const originalItem = originalAssetParts && originalAssetParts.parts.find(x => x.id === i.id);
      if (!originalItem) {
        throw new Error('Cannot find original Part Item');
      }
      return deepEqual(i, originalItem) ? ChangeState.Unchanged : ChangeState.Modified;
    };
    const parts = a.categoryParts
      .map(c => c.parts)
      .reduce((x, b) => x.concat(b), [])
      .filter(p => !!p.part);
    return {
      assetId: this.assetId,
      partItems: parts.map(x => ({
        id: x.id,
        partId: x.part.id,
        subCategoryId: x.subCategory ? x.subCategory.id : undefined,
        changeState: getChangeState(x),
        quantity: x.part.quantity,
      })),
    };
  };

  private readonly getPageDef = (mode: CrudPageMode, updating: boolean): ICrudPageDef => {
    const { asset, onUpdateAssetParts } = this.props;
    const assetName = asset && asset.name;
    return {
      primarySize: PagePrimarySize.twoThirds,
      primarySection: {
        title: `${assetName || ''} - Parts Register`,
        onFormPreSubmit: this.handlePreSubmitForUpdate,
        onFormSubmit: onUpdateAssetParts,
        panels: [
          {
            panes: [
              {
                paneType: PaneType.repeatingPane,
                dataAddr: ['categoryParts'],
                useHover: false,
                itemPanes: [
                  {
                    paneType: PaneType.customPane,
                    render: api => <h1>{api.data.paneValue.name}</h1>,
                  },
                  {
                    dataAddr: ['parts'],
                    paneType: PaneType.tablePane,
                    noRowsMessage: 'No parts found',
                    dataRequiredForRows: 'paneValue',
                    fields: [
                      {
                        fieldType: FieldType.textField,
                        label: '',
                        readonly: true,
                        dataAddr: 'subCategoryName',
                        columnAutoHide: true,
                        columnWidth: '16em',
                        hidden: d => !d.parentValue.subCategory,
                      },
                      {
                        fieldType: FieldType.selectAsyncField,
                        label: 'Part Number',
                        dataAddr: 'part',
                        valueKey: 'id',
                        descriptionKey: 'partNumber',
                        menuWidth: 'fitContent',
                        columnWidth: '12em',
                        linkTo: d => `/workshop/parts/${d.fieldValue.id}`,
                        mandatory: d => !d.parentValue.subCategory,
                        loadOptionItems: this.props.searchParts,
                        optionRenderer: (o: IMatchingPart) => (
                          <div style={{ whiteSpace: 'nowrap' }}>
                            {o.partNumber} - {o.description}
                          </div>
                        ),
                      },
                      {
                        fieldType: FieldType.numericField,
                        label: 'Qty',
                        dataAddr: ['part', 'quantity'],
                        hidden: d => !d.parentValue.part,
                        numericConfig: {
                          numericType: 'unsignedDecimal',
                          maxPointDigits: 4,
                        },
                        columnWidth: '8em',
                        mandatory: true,
                        validate: item =>
                          item.fieldValue && item.fieldValue > 0
                            ? undefined
                            : 'Qty must be greater than 0',
                      },
                      {
                        fieldType: FieldType.textField,
                        label: 'Description',
                        readonly: true,
                        dataAddr: ['part', 'description'],
                      },
                      {
                        fieldType: FieldType.actionListField,
                        dataAddr: '',
                        columnWidth: '1px',
                        actionGroups: [
                          {
                            actions: [
                              {
                                hidden: d =>
                                  (!d.parentValue.part && d.parentValue.hasCategory) || !updating,
                                actionType: ActionType.removeArrayItemActionButton,
                                label: 'Remove',
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    paneType: PaneType.actionListPane,
                    hidden: d => d.parentValue.hasCategory || !updating,
                    dataAddr: ['parts'],
                    actionGroups: [
                      {
                        actions: [
                          {
                            actionType: ActionType.addArrayItemActionButton,
                            label: 'Add',
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    };
  };

  render() {
    const { onLoadAsset, canManagePartsRegister } = this.props;
    const mode = 'update';
    return (
      <CrudPage
        def={({ updating }) => this.getPageDef(mode, updating)}
        mode={mode}
        isEditingForbidden={!canManagePartsRegister}
        onLoadData={() => onLoadAsset(this.assetId)}
        data={this.getData()}
      />
    );
  }
}

export default PartsRegister;
