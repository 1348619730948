import './MarkOutOfServiceJob.scss';
import { Component } from 'react';
import { IMarkOutOfServiceWorkshopJobItem } from 'src/domain/entities/operations/job/ListJobsForAllocationsModel';

const descriptionPrefix = 'W • Out Of Service';

interface IMarkOutOfServiceJobProps {
  markOutOfService: IMarkOutOfServiceWorkshopJobItem;
}

export default class MarkOutOfServiceJob extends Component<IMarkOutOfServiceJobProps> {
  render() {
    const { markOutOfService } = this.props;
    const description = `${descriptionPrefix} • ${markOutOfService.defectNumber}`;
    return (
      <div className="allocations-mark-out-of-service-component" title={description}>
        <div className="description">{description}</div>
      </div>
    );
  }
}
