import { types, flow, getRoot } from 'mobx-state-tree';
import { getAjax } from 'src/domain/services';
import { IAutocompleteResult, NotificationType } from 'src/domain/baseTypes';
import { IRootStoreModel } from 'src/domain/entities/RootStoreModel';
import {
  ISetActiveCommand,
  ISetInactiveCommand,
} from 'src/views/components/ActivationAndDeletionActionsButtons/getActivationAndDeletionActionButtons';

type AggregateUsageDto = Common.Dtos.AggregateUsageDto;
type SetPartsGroupActiveCommand = Workshop.Domain.Commands.Part.SetPartsGroupActive.SetPartsGroupActiveCommand;
type SetPartsGroupInactiveCommand = Workshop.Domain.Commands.Part.SetPartsGroupInactive.SetPartsGroupInactiveCommand;
type MatchingPartsGroup = Workshop.Domain.Queries.SearchPartGroups.MatchingPartsGroup;
type PartsGroupItem = Workshop.Domain.Queries.GetPartsGroup.PartsGroupItem;
type CreatePartsGroupCommand = Workshop.Domain.Commands.Part.CreatePartsGroup.CreatePartsGroupCommand;
type UpdatePartsGroupCommand = Workshop.Domain.Commands.Part.UpdatePartsGroup.UpdatePartsGroupCommand;
type EngineItem = Workshop.Domain.Queries.GetPartsGroupEngines.EngineItem;
type ExportPartsGroupToPdfQuery = Workshop.Domain.Queries.Parts.GeneratePartsGroupPdf.ExportPartsGroupToPdfQuery;

export interface IMatchingPartsGroup extends MatchingPartsGroup {
  searchText: string;
}

export const PartsGroupModel = types
  .model('PartsGroupModel', {
    partsGroup: types.maybe(types.frozen<PartsGroupItem>()),
    engines: types.array(types.frozen<EngineItem>()),
    partsGroupUsage: types.maybe(types.frozen<AggregateUsageDto>()),
  })
  .actions(self => {
    const ajax = getAjax(self);
    const root = getRoot(self) as IRootStoreModel;

    let lastSearch: string | undefined;
    let lastResult: IAutocompleteResult<MatchingPartsGroup>;

    const searchPartsGroups = flow(function*(search: string) {
      if (search === lastSearch) {
        return lastResult;
      }
      const partsGroups: Array<MatchingPartsGroup> = yield ajax.workshop.part.searchPartsGroups(
        search
      );
      lastSearch = search;
      lastResult = {
        options: partsGroups.map(p => ({ ...p, searchText: `${p.name} ${p.description}` })),
      };
      return lastResult;
    });

    const createPartsGroup = flow(function*(command: CreatePartsGroupCommand) {
      const id = yield ajax.workshop.part.createPartsGroup(command);
      self.partsGroup = undefined;
      root.history.push(`/workshop/parts-groups/${id}`);
    });

    const nameExists = flow(function*(name: string) {
      const exists: boolean = yield ajax.workshop.part.partsGroupNameExists(name);
      return exists;
    });

    const loadPartsGroup = flow(function*(partsGroupId: string) {
      self.partsGroup = yield ajax.workshop.part.getPartsGroup(partsGroupId);
    });

    const loadEngines = flow(function*(partsGroupId: string) {
      self.engines = yield ajax.workshop.part.getPartsGroupEngines(partsGroupId);
    });

    const updatePartsGroup = flow(function*(cmd: UpdatePartsGroupCommand) {
      yield ajax.workshop.part.updatePartsGroup(cmd);
      yield loadPartsGroup(cmd.id);
    });

    const exportToPdf = flow(function*(query: Partial<ExportPartsGroupToPdfQuery>) {
      root.notifications.addNotification(`The file is being generated ...`, {
        type: NotificationType.info,
      });

      return yield ajax.workshop.part.exportPartsGroupToPdf(query);
    });

    const setPartsGroupInactive = flow(function*(command: ISetInactiveCommand) {
      const setPartsGroupInactiveCommand: SetPartsGroupInactiveCommand = {
        id: command.id,
      };
      yield ajax.workshop.part.updatePartsGroupSetInactive(setPartsGroupInactiveCommand);
    });

    const setPartsGroupActive = flow(function*(command: ISetActiveCommand) {
      const setPartsGroupActiveCommand: SetPartsGroupActiveCommand = {
        id: command.id,
      };
      yield ajax.workshop.part.updatePartsGroupSetActive(setPartsGroupActiveCommand);
    });

    const deletePartsGroup = flow(function*(id: string) {
      yield ajax.workshop.part.deletePartsGroup(id);

      self.partsGroup = undefined;
      self.partsGroupUsage = undefined;
      root.history.push(`/workshop/parts-groups`);
    });

    const getPartsGroupUsage = flow(function*(id: string) {
      self.partsGroupUsage = yield ajax.workshop.part.getPartsGroupUsage(id);
    });

    return {
      createPartsGroup,
      nameExists,
      loadPartsGroup,
      updatePartsGroup,
      searchPartsGroups,
      exportToPdf,
      loadEngines,
      setPartsGroupInactive,
      setPartsGroupActive,
      deletePartsGroup,
      getPartsGroupUsage,
    };
  });

type IPartsGroupModelType = typeof PartsGroupModel.Type;
export interface IPartsGroupModel extends IPartsGroupModelType {}
