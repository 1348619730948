import { types, flow } from 'mobx-state-tree';
import { getAjax } from 'src/domain/services/storeEnvironment';
import { operationsUrls } from 'src/domain/services/apiUrls';
import { DateTime } from 'luxon';

type OperationsDashboardData = Operations.Domain.Queries.GetOperationsDashboard.OperationsDashboardData;

export const OperationsDashboardModel = types
  .model('OperationsDashboardModel', {
    dashboardData: types.maybe(types.frozen<OperationsDashboardData>()),
    lastUpdated: types.maybe(types.frozen<DateTime>()),
  })
  .actions(self => {
    const ajax = getAjax(self);

    const loadDashboardData = flow(function*() {
      self.dashboardData = yield ajax.raw
        .get(operationsUrls.dashboardUrls.loadDashboard)
        .toPromise()
        .then<OperationsDashboardData>(r => r.response);
      self.lastUpdated = DateTime.local();
    });

    return {
      loadDashboardData,
    };
  });
