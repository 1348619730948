import { types, flow, getRoot } from 'mobx-state-tree';
import { getAjax } from 'src/domain/services/storeEnvironment';
import { IRootStoreModel } from 'src/domain/entities/RootStoreModel';

type RailTemplateItem = Operations.Domain.Queries.ViewRailTemplate.RailTemplateItem;
type CreateRailTemplateCommand = Operations.Domain.Commands.RailTemplate.CreateRailTemplate.CreateRailTemplateCommand;
type GenerateShiftAttachmentIdCommand = Operations.Domain.Commands.RailTemplate.GenerateShiftAttachmentId.GenerateShiftAttachmentIdCommand;

export const RailTemplateModel = types
  .model('RailTemplateModel', {
    railTemplate: types.maybe(types.frozen<RailTemplateItem>()),
  })
  .actions(self => {
    const ajax = getAjax(self);
    const root = getRoot(self) as IRootStoreModel;

    const createRailTemplate = flow(function*(command: CreateRailTemplateCommand) {
      const id = yield ajax.operations.rail.railTemplates.createRailTemplate(command);
      self.railTemplate = undefined;
      root.history.push(`/operations/rail/rail-templates/${id}`);
    });

    const checkForUniqueRailTemplateName = flow(function*(name: string) {
      return yield ajax.operations.rail.railTemplates.checkForUniqueRailTemplateName(name);
    });

    const loadRailTemplate = flow(function*(railTemplateId: string) {
      self.railTemplate = yield ajax.operations.rail.railTemplates.viewRailTemplate(railTemplateId);
    });

    const getRailTemplate = flow(function*(railTemplateId: string) {
      return yield ajax.operations.rail.railTemplates.viewRailTemplate(railTemplateId);
    });

    const generateShiftAttachmentId = flow(function*(query: GenerateShiftAttachmentIdCommand) {
      const id: string = yield ajax.operations.rail.railTemplates.generateShiftAttachmentId(query);
      return id;
    });

    const downloadAttachment = flow(function*(attachmentId: string, fileName: string) {
      yield ajax.operations.rail.railTemplates.downloadAttachment(attachmentId, fileName);
    });

    const updateRailTemplate = flow(function*(
      command: Operations.Domain.Commands.RailTemplate.UpdateRailTemplate.UpdateRailTemplateCommand
    ) {
      yield ajax.operations.rail.railTemplates.updateRailTemplate(command);
      yield loadRailTemplate(command.id);
    });

    const duplicateRailTemplate = flow(function*(railTemplateId: string) {
      const duplicatedRailTemplateId = yield ajax.raw
        .put(`/api/operations/rail-templates/${railTemplateId}/duplicate`)
        .toPromise()
        .then(r => r.response);
      self.railTemplate = undefined;
      root.history.push(`/operations/rail/rail-templates/${duplicatedRailTemplateId}`);
    });

    const deleteRailTemplate = flow(function*(railTemplateId: string) {
      yield ajax.operations.rail.railTemplates.deleteRailTemplate(railTemplateId);
      self.railTemplate = undefined;
      root.history.push(`/operations/rail/rail-templates?defaultFilter=true`);
    });

    const restoreRailTemplate = flow(function*(railTemplateId: string) {
      yield ajax.operations.rail.railTemplates.restoreRailTemplate(railTemplateId);
      yield loadRailTemplate(railTemplateId);
      root.history.push(`/operations/rail/rail-templates/${railTemplateId}`);
    });

    return {
      createRailTemplate,
      checkForUniqueRailTemplateName,
      loadRailTemplate,
      getRailTemplate,
      updateRailTemplate,
      duplicateRailTemplate,
      deleteRailTemplate,
      restoreRailTemplate,
      generateShiftAttachmentId,
      downloadAttachment,
    };
  });
