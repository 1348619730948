import { buildListPageApiSearchModelTypedQuery } from 'src/domain/modelBuilders/buildListPageApiSearchModel';
import { types, getRoot, flow } from 'mobx-state-tree';
import { getAjax, NotificationType } from 'src/domain/services';
import { IRootStoreModel } from 'src/domain/entities/RootStoreModel';
import { operationsUrls } from 'src/domain/services/apiUrls';
import { leaveTypeDescription } from 'src/api/enums';
import { DateTime } from 'luxon';
import { JobStatus } from 'src/api/enums';

type CharterInstructions = Operations.Domain.Queries.ListCharterInstructionsForDriver.CharterInstructions;
type ListJobItem = Operations.Domain.Queries.ListJobsForCharter.ListJobItem;

const JobsForCharterListModel = buildListPageApiSearchModelTypedQuery<
  Operations.Domain.Queries.ListJobs.ListJobsQuery
>()('JobsForCharterListModel', d => d.ajax.operations.jobs.listJobsForCharter(d.query), {
  size: 250,
  equalityComparer: (a, b) => a.id === b.id,
}).views(self => ({
  get jobWithClockOnClosestToCurrentTime(): ListJobItem | undefined {
    const reducer = (previous: ListJobItem, current: ListJobItem): ListJobItem => {
      const previousDiff = Math.abs(DateTime.fromISO(previous.clockOn).diffNow('second').seconds);
      const currentDiff = Math.abs(DateTime.fromISO(current.clockOn).diffNow('second').seconds);

      return previousDiff < currentDiff
        ? previous
        : previousDiff === currentDiff
        ? previous
        : current;
    };

    if (self.items && self.items.length > 0) {
      const nonHiddenItems = self.items.filter(
        job => job.clockOn && !(job.jobStatus.id === JobStatus.Incomplete || job.isContinuingFrom)
      );

      if (nonHiddenItems.length > 0) {
        return nonHiddenItems.reduce(reducer);
      }
    }

    return undefined;
  },
}));

const ExtendedJobsForCharterListModel = types
  .model('ExtendedJobsForCharterListModel', {})
  .actions(self => {
    const ajax = getAjax(self);
    const root = getRoot(self) as IRootStoreModel;

    const loadJobConflicts = (id: string) => ajax.operations.jobs.getJobConflicts(id);

    const warnAboutConflicts = async (id: string) => {
      const jobConflicts = await loadJobConflicts(id);

      if (jobConflicts.conflictsCount > 0) {
        const conflicts = [
          ...jobConflicts.opsConflicts.map(c => `${c.conflictType.description} (${c.description})`),
          ...jobConflicts.peopleLeaveConflicts.map(
            c => `${c.conflictType.description} (${leaveTypeDescription(c.leaveTypeId)})`
          ),
          ...jobConflicts.vehicleMarkedOutOfServiceConflicts.map(c => c.conflictType.description),
          ...jobConflicts.workshopJobConflicts.map(c => c.conflictType.description),
        ].join(', ');

        root.notifications.addNotification(
          `This job has ${jobConflicts.conflictsCount} conflicts on ${conflicts}`
        );
      }
    };

    return {
      warnAboutConflicts,
    };
  });

const ExportJobsForCharterModel = types
  .model('ExportJobsForCharterModel', {
    charterInstructions: types.maybe(types.frozen<CharterInstructions>()),
  })
  .actions(self => {
    const ajax = getAjax(self);
    const root = getRoot(self) as IRootStoreModel;

    const exportCharterJobsToPdf = flow(function*(
      query: Partial<Operations.Domain.Queries.ListJobsForCharter.ListJobsForCharterQuery>
    ) {
      root.notifications.addNotification(`The file is being generated ...`, {
        type: NotificationType.info,
      });

      return yield ajax.operations.jobs.exportCharterJobsToPdf(query);
    });

    const loadCharterInstructions = flow(function*(
      query: Partial<Operations.Domain.Queries.ListJobsForCharter.ListJobsForCharterQuery>
    ) {
      self.charterInstructions = yield ajax.raw
        .get(operationsUrls.jobUrls.exportCharterInstructionsForCharter(query))
        .toPromise()
        .then(r => r.response as CharterInstructions);
    });

    return {
      exportCharterJobsToPdf,
      loadCharterInstructions,
    };
  });

export const ListJobsForCharterModel = types.compose(
  JobsForCharterListModel,
  ExportJobsForCharterModel,
  ExtendedJobsForCharterListModel
);
