import { DateTime, Interval } from 'luxon';
import { ChangeState } from 'src/api/enums';
import { IHasChangeState } from 'src/views/definitionBuilders/types';

type BasicRepeat = { id: number; repeatDate: string; start?: string; end?: string };

export enum RepeatOnWeekdays {
  mon = 1,
  tue,
  wed,
  thu,
  fri,
  sat,
  sun,
}

export type OnMonthFreq = 'monthDate' | 'weekDay';

export type PartialRepeat = Partial<BasicRepeat> & IHasChangeState & { generated?: boolean };

function createRepeat(date: string, start?: string, end?: string): PartialRepeat {
  return {
    repeatDate: date,
    start: start,
    end: end,
    changeState: ChangeState.Added,
    generated: true,
  };
}

function convertToIsoStringWithCorrectOffset(dateTime: DateTime): string {
  const offSet = DateTime.local().zone.formatOffset(DateTime.local().toMillis(), 'short');
  return `${dateTime.toISO({ includeOffset: false })}${offSet}`;
}

export function generateDaysWithTimeAwareness(
  start: DateTime,
  end: DateTime,
  sequenceEnd: DateTime,
  repeatEvery: number
) {
  const items: PartialRepeat[] = [];
  const repeatInterval = Interval.fromDateTimes(start, sequenceEnd);
  const dayCount = repeatInterval.length('days');
  const leaveInterval = Interval.fromDateTimes(start.startOf('day'), end.startOf('day'));
  const repeatDaysCount = leaveInterval.length('days');
  for (let k = 0; k <= dayCount; k = k + repeatEvery) {
    const repeatStart = start
      .startOf('day')
      .plus({ days: k, hours: start.hour, minutes: start.minute });
    const repeatEnd = start
      .startOf('day')
      .plus({ days: k + repeatDaysCount, hours: end.hour, minutes: end.minute });
    items.push(
      createRepeat(
        convertToIsoStringWithCorrectOffset(repeatStart),
        convertToIsoStringWithCorrectOffset(repeatStart),
        convertToIsoStringWithCorrectOffset(repeatEnd)
      )
    );
  }
  return items.filter(x => x.repeatDate !== convertToIsoStringWithCorrectOffset(start));
}

export function generateWeeksWithTimeAwareness(
  start: DateTime,
  end: DateTime,
  sequenceEnd: DateTime,
  repeatOnWeekday: RepeatOnWeekdays[],
  repeatEvery: number
) {
  const items: PartialRepeat[] = [];
  const repeatInterval = Interval.fromDateTimes(start, sequenceEnd.plus({ days: 1 }));
  const daysSet = new Set(repeatOnWeekday);
  const realStart = Array.from(Array(7), (_, idx) => start.plus({ days: idx })).filter(d =>
    daysSet.has(d.weekday)
  )[0];
  const weekStart = realStart.startOf('week');
  let wCurrent = weekStart;
  const leaveInterval = Interval.fromDateTimes(start.startOf('day'), end.startOf('day'));
  const repeatDaysCount = leaveInterval.length('days');
  do {
    for (var i = 0; i < 7; i++) {
      const repeatStart = wCurrent.plus({ days: i, hours: start.hour, minutes: start.minute });
      const repeatEnd = wCurrent.plus({
        days: i + repeatDaysCount,
        hours: end.hour,
        minutes: end.minute,
      });
      if (repeatInterval.contains(repeatStart) && daysSet.has(repeatStart.weekday)) {
        items.push(createRepeat(repeatStart.toISO(), repeatStart.toISO(), repeatEnd.toISO()));
      }
    }
    wCurrent = wCurrent.plus({ weeks: repeatEvery });
  } while (wCurrent <= sequenceEnd);
  return items.filter(x => x.repeatDate !== start.toISO());
}

export function generateMonthsWithTimeAwareness(
  start: DateTime,
  end: DateTime,
  sequenceEnd: DateTime,
  repeatOnMonth: OnMonthFreq,
  repeatEvery: number
) {
  const items: PartialRepeat[] = [];
  const leaveInterval = Interval.fromDateTimes(start.startOf('day'), end.startOf('day'));
  const repeatDaysCount = leaveInterval.length('days');
  if (repeatOnMonth === 'monthDate') {
    let current = start;
    let currentEnd = end;

    do {
      items.push(createRepeat(current.toISO(), current.toISO(), currentEnd.toISO()));
      current = current.plus({ months: repeatEvery });
      // Adding months attempts to help if the day of month does exist in the next month
      // So check if we're on the correct day number
      if (current.day !== start.day) {
        // Attempt to reset the day number - if the day number is not valid, it will change the month
        const dayCheck = current.set({ day: start.day });
        if (dayCheck.month === current.month) {
          current = dayCheck;
        }
      }
      currentEnd = current.startOf('day').plus({
        days: repeatDaysCount,
        hours: end.hour,
        minutes: end.minute,
      });
    } while (current <= sequenceEnd);
  } else {
    let current = start;
    let forecast = start;
    let currentEnd = end;
    let addNext = true;
    const dayOccurredInMonthCount = Math.ceil(start.day / 7) - 1; // eg 3rd Wed in the month = 2 (zero indexed)
    do {
      if (addNext) {
        items.push(createRepeat(forecast.toISO(), forecast.toISO(), currentEnd.toISO()));
      }
      current = current
        .plus({ months: repeatEvery })
        .startOf('month')
        .plus({ hours: start.hour, minutes: start.minute });
      // Make sure we move forwards in time when setting the day of the week
      const weeksToAdd = (current.weekday > start.weekday ? 1 : 0) + dayOccurredInMonthCount;
      forecast = current.plus({ weeks: weeksToAdd }).set({ weekday: start.weekday });
      // If we've been pushed into the next month, then the month doesn't have that particular day, so skip
      addNext = forecast.month === current.month;
      currentEnd = forecast.startOf('day').plus({
        days: repeatDaysCount,
        hours: end.hour,
        minutes: end.minute,
      });
    } while (current <= sequenceEnd);
  }
  return items.filter(x => x.repeatDate !== start.toISO());
}

export function generateDaysWithDateOnly(start: DateTime, end: DateTime, repeatEvery: number) {
  const items: PartialRepeat[] = [];
  const interval = Interval.fromDateTimes(start, end);
  const dayCount = interval.length('days');
  for (var k = 0; k <= dayCount; k = k + repeatEvery) {
    items.push(createRepeat(start.plus({ days: k }).toISODate()));
  }
  return items.filter(x => x.repeatDate !== start.toISODate());
}

export function generateWeeksWithDateOnly(
  start: DateTime,
  end: DateTime,
  repeatOnWeekday: RepeatOnWeekdays[],
  repeatEvery: number
) {
  const items: PartialRepeat[] = [];
  const interval = Interval.fromDateTimes(start, end.plus({ days: 1 }));
  const daysSet = new Set(repeatOnWeekday);
  const realStart = Array.from(Array(7), (_, idx) => start.plus({ days: idx })).filter(d =>
    daysSet.has(d.weekday)
  )[0];
  const weekStart = realStart.startOf('week');
  let wCurrent = weekStart;
  do {
    for (var i = 0; i < 7; i++) {
      const day = wCurrent.plus({ days: i });
      if (interval.contains(day) && daysSet.has(day.weekday)) {
        items.push(createRepeat(day.toISODate()));
      }
    }
    wCurrent = wCurrent.plus({ weeks: repeatEvery });
  } while (wCurrent <= end);
  return items.filter(x => x.repeatDate !== start.toISODate());
}

export function generateMonthsWithDateOnly(
  start: DateTime,
  end: DateTime,
  repeatOnMonth: OnMonthFreq,
  repeatEvery: number
) {
  const items: PartialRepeat[] = [];
  if (repeatOnMonth === 'monthDate') {
    let current = start;
    do {
      items.push(createRepeat(current.toISODate()));

      current = current.plus({ months: repeatEvery });
      // Adding months attempts to help if the day of month does exist in the next month
      // So check if we're on the correct day number
      if (current.day !== start.day) {
        // Attempt to reset the day number - if the day number is not valid, it will change the month
        const dayCheck = current.set({ day: start.day });
        if (dayCheck.month === current.month) {
          current = dayCheck;
        }
      }
    } while (current <= end);
  } else {
    let current = start;
    let forecast = start;
    let addNext = true;
    const dayOccurredInMonthCount = Math.ceil(start.day / 7) - 1; // eg 3rd Wed in the month = 2 (zero indexed)

    do {
      if (addNext) {
        items.push(createRepeat(forecast.toISODate()));
      }
      current = current.plus({ months: repeatEvery }).startOf('month');
      // Make sure we move forwards in time when setting the day of the week
      const weeksToAdd = (current.weekday > start.weekday ? 1 : 0) + dayOccurredInMonthCount;
      forecast = current.plus({ weeks: weeksToAdd }).set({ weekday: start.weekday });
      // If we've been pushed into the next month, then the month doesn't have that particular day, so skip
      addNext = forecast.month === current.month;
    } while (current <= end);
  }
  return items.filter(x => x.repeatDate !== start.toISODate());
}
