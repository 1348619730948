import { Component } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import deepEqual from 'src/infrastructure/deepEqual';
import { ChangeState, JobTaskStatus } from 'src/api/enums';
import { UndoIcon, BanIcon, CalendarIcon } from 'src/images/icons';
import {
  CancelAssetServiceArgs,
  ScheduleServiceArgs,
} from 'src/domain/entities/workshop/asset/AssetModel';
import CrudPage, { CrudPageMode, ICrudPageDef } from 'src/views/components/Page/pages/CrudPage';
import {
  PagePrimarySize,
  PaneType,
  FieldType,
  ActionType,
  ShellModalSize,
  IHasChangeState,
} from 'src/views/definitionBuilders/types';
import getResetScheduleModalDef from './getResetScheduleModalDef';
import getCancelServiceModalDef from './getCancelServiceModalDef';
import getScheduleServiceModalDef from './getScheduleServiceModalDef';
import getFullServiceCycleSectionDef from '../../assetGroups/maintainAssetGroup/getFullServiceCycleSectionDef';

type UpdateAssetServicePlanCommand = Workshop.Domain.Commands.AssetServicePlan.UpdateAssetServicePlanCommand;
type AssetServicePlan = Workshop.Domain.Queries.AssetServices.GetAssetServicePlan.AssetServicePlan;
type AssetPlanItem = Workshop.Domain.Queries.AssetServices.GetAssetServicePlan.AssetPlanItem;
type JobTaskSubcategory = Workshop.Domain.Queries.GetTaskCategories.JobTaskSubcategoryListItem;
type AssetDetails = Workshop.Domain.Queries.GetAsset.AssetDetails;
type JobSummaryDto = Workshop.Domain.Queries.Job.JobSummaryDto;
type ServiceCycleItem = Workshop.Domain.Queries.AssetGroup.GetAssetGroupFullServiceCycle.ServiceCycleItem;
type WorkshopDepot = Common.Queries.Workshop.GetWorkshopDepots.WorkshopDepotDto;

export interface IServiceHistoryProps {
  mode: CrudPageMode;
  canManageServiceHistory: boolean;
  asset: AssetDetails | undefined;
  servicePlan: AssetServicePlan | undefined;
  serviceTypes: Array<JobTaskSubcategory>;
  futureJobs: Array<JobSummaryDto>;
  onLoadAsset: (id: string) => Promise<void>;
  onLoadAssetServicePlan: (id: string) => Promise<void>;
  onLoadAssetFutureJobs: (id: string) => Promise<void>;
  onLoadServiceTypes: () => Promise<void>;
  onUpdateServicePlan: (command: UpdateAssetServicePlanCommand) => Promise<void>;
  onResetSchedule: (
    startingDate: string,
    startingKms: number,
    lastServiceCycle: ServiceCycleItem | undefined
  ) => Promise<void>;
  onCancelService: (args: CancelAssetServiceArgs) => Promise<void>;
  onScheduleService: (command: ScheduleServiceArgs) => Promise<void>;
  fullServiceCycle: Array<ServiceCycleItem>;
  loadAssetGroupFullServiceCycle: (assetGroupId: string) => Promise<void>;
  workshopDepots: Array<WorkshopDepot>;
  defaultWorkshopDepot: WorkshopDepot | undefined;
}

interface IServiceHistoryRouteParams {
  id: string;
}

interface IServiceHistoryPageState {
  updating: boolean;
}

type InternalProps = IServiceHistoryProps & RouteComponentProps<IServiceHistoryRouteParams>;

class ServiceHistory extends Component<InternalProps, IServiceHistoryPageState> {
  componentDidMount() {
    this.props.onLoadAssetServicePlan(this.assetId);
    this.props.onLoadServiceTypes();
    this.props.onLoadAssetFutureJobs(this.assetId);
  }

  private get assetId() {
    return this.props.match.params.id;
  }

  private readonly getData = () => {
    const { asset, servicePlan } = this.props;
    return { asset, servicePlan };
  };

  private handlePreSubmitForUpdate = (a: {
    asset: AssetDetails;
    servicePlan: AssetServicePlan;
  }): UpdateAssetServicePlanCommand => {
    const originalPlan = this.props.servicePlan;
    const getChangeState = (i: AssetPlanItem & IHasChangeState) => {
      if (i.changeState === ChangeState.Added || i.changeState === ChangeState.Deleted) {
        return i.changeState;
      }
      const originalItem = originalPlan && originalPlan.planItems.find(x => x.id === i.id);
      if (!originalItem) {
        throw new Error('Cannot find original Plan Item');
      }
      return deepEqual(i, originalItem) ? ChangeState.Unchanged : ChangeState.Modified;
    };
    return {
      assetId: this.assetId,
      servicePlanItems: a.servicePlan.planItems.map(x => ({
        id: x.id,
        jobTaskSubcategoryId: x.serviceType.id,
        dueDate: x.dueDate,
        dueKms: x.dueKms ? x.dueKms : undefined,
        changeState: getChangeState(x),
      })),
    };
  };

  private readonly getPageDef = (mode: CrudPageMode, updating: boolean): ICrudPageDef => {
    const {
      asset,
      serviceTypes,
      onUpdateServicePlan,
      onResetSchedule,
      onCancelService,
      onScheduleService,
      futureJobs,
      canManageServiceHistory,
      fullServiceCycle,
      workshopDepots,
      defaultWorkshopDepot,
    } = this.props;
    const assetName = asset && asset.name;
    const editable = updating;
    return {
      primarySize: PagePrimarySize.twoThirds,
      primarySection: {
        title: `${assetName || ''} - Service History`,
        primaryActions: [
          {
            actions: [
              {
                actionType: ActionType.actionCollection,
                hidden: editable || !canManageServiceHistory,
                actionGroups: [
                  {
                    actions: [
                      {
                        actionType: ActionType.modalActionButton,
                        label: 'Reset Schedule',
                        icon: <UndoIcon fixedWidth />,
                        modalSize: ShellModalSize.oneThird,
                        modalDef: getResetScheduleModalDef(fullServiceCycle, onResetSchedule),
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
        panels: [
          {
            title: 'Upcoming Services',
            dataAddr: ['servicePlan', 'planItems'],
            panes: [
              {
                paneType: PaneType.tablePane,
                noRowsMessage: 'No upcoming services found',
                mandatory: false,
                fields: [
                  {
                    fieldType: FieldType.selectField,
                    label: 'Service Type',
                    dataAddr: ['serviceType', 'id'],
                    useValueOnly: true,
                    valueKey: 'id',
                    descriptionKey: 'description',
                    mandatory: true,
                    optionItems: serviceTypes,
                    columnWidth: '12em',
                  },
                  {
                    fieldType: FieldType.dateField,
                    label: 'Date Due',
                    dataAddr: 'dueDate',
                    mandatory: true,
                    columnWidth: '10em',
                  },
                  {
                    fieldType: FieldType.numericField,
                    label: 'Kms Due',
                    dataAddr: 'dueKms',
                    columnWidth: '9em',
                  },
                  {
                    fieldType: FieldType.textField,
                    label: 'Task',
                    readonly: true,
                    dataAddr: 'jobTaskNumber',
                    columnWidth: '9em',
                    hidden: d => !d.fieldValue,
                    linkTo: d => `/workshop/tasks/${d.parentValue.jobTaskId}`,
                  },
                  {
                    fieldType: FieldType.textField,
                    label: 'Status',
                    readonly: true,
                    dataAddr: ['taskStatus', 'description'],
                    hidden: d => !d.fieldValue,
                  },
                  {
                    fieldType: FieldType.actionListField,
                    hidden: () => !canManageServiceHistory,
                    dataAddr: '',
                    columnWidth: '1px',
                    actionGroups: [
                      {
                        actions: [
                          {
                            hidden: d => {
                              const item = d.parentValue as AssetPlanItem;
                              return (
                                !updating ||
                                (item.taskStatus && item.taskStatus.id === JobTaskStatus.Scheduled)
                              );
                            },
                            actionType: ActionType.removeArrayItemActionButton,
                            label: 'Remove Service',
                          },
                          {
                            hidden: d => {
                              const item = d.parentValue as AssetPlanItem;
                              return (
                                updating ||
                                (item.taskStatus && item.taskStatus.id === JobTaskStatus.Scheduled)
                              );
                            },
                            actionType: ActionType.modalActionButton,
                            label: 'Schedule Service',
                            icon: <CalendarIcon />,
                            modalSize: ShellModalSize.oneThird,
                            modalDef: getScheduleServiceModalDef(
                              onScheduleService,
                              futureJobs,
                              undefined,
                              undefined,
                              undefined,
                              workshopDepots,
                              defaultWorkshopDepot
                            ),
                          },
                          {
                            hidden: d => {
                              const item = d.parentValue as AssetPlanItem;
                              return (
                                updating ||
                                (item.taskStatus && item.taskStatus.id === JobTaskStatus.Scheduled)
                              );
                            },
                            actionType: ActionType.modalActionButton,
                            label: 'Cancel Service',
                            icon: <BanIcon />,
                            modalSize: ShellModalSize.oneThird,
                            modalDef: getCancelServiceModalDef(onCancelService, undefined),
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
              {
                paneType: PaneType.actionListPane,
                hidden: !updating,
                actionGroups: [
                  {
                    actions: [
                      {
                        actionType: ActionType.addArrayItemActionButton,
                        label: 'Add Service',
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            title: 'History',
            dataAddr: ['servicePlan', 'historyItems'],
            panes: [
              {
                paneType: PaneType.tablePane,
                neverEditable: true,
                noRowsMessage: 'No historical services found',
                mandatory: false,
                fields: [
                  {
                    fieldType: FieldType.textField,
                    label: 'Service Type',
                    dataAddr: ['serviceType', 'description'],
                    columnWidth: '12em',
                    readonly: true,
                  },
                  {
                    fieldType: FieldType.dateField,
                    label: 'Date Done',
                    dataAddr: 'doneDate',
                    columnWidth: '9em',
                    readonly: true,
                  },
                  {
                    fieldType: FieldType.numericField,
                    label: 'Kms Due',
                    dataAddr: 'dueKms',
                    columnWidth: '9em',
                    readonly: true,
                  },
                  {
                    fieldType: FieldType.numericField,
                    label: 'Kms Done',
                    dataAddr: 'doneKms',
                    columnWidth: '9em',
                    readonly: true,
                  },
                  {
                    fieldType: FieldType.textField,
                    label: 'Task',
                    readonly: true,
                    dataAddr: 'jobTaskNumber',
                    columnWidth: '9em',
                    hidden: d => !d.fieldValue,
                    linkTo: d => `/workshop/tasks/${d.parentValue.jobTaskId}`,
                  },
                  {
                    fieldType: FieldType.textField,
                    label: 'Status',
                    readonly: true,
                    dataAddr: 'historyStatus',
                  },
                ],
              },
            ],
          },
          {
            title: 'Cancelled service tasks',
            dataAddr: ['servicePlan', 'outsideOfPlanItems'],
            hidden: d => !d.panelValue,
            panes: [
              {
                paneType: PaneType.tablePane,
                neverEditable: true,
                noRowsMessage: 'Cancelled service tasks',
                mandatory: false,
                fields: [
                  {
                    fieldType: FieldType.textField,
                    label: 'Service Type',
                    dataAddr: ['serviceType', 'description'],
                    columnWidth: '12em',
                    readonly: true,
                  },
                  {
                    fieldType: FieldType.dateField,
                    label: 'Date Done',
                    dataAddr: 'doneDate',
                    columnWidth: '9em',
                    readonly: true,
                  },
                  {
                    fieldType: FieldType.numericField,
                    label: 'Kms Done',
                    dataAddr: 'doneKms',
                    columnWidth: '9em',
                    readonly: true,
                  },
                  {
                    fieldType: FieldType.textField,
                    label: 'Task',
                    readonly: true,
                    dataAddr: 'jobTaskNumber',
                    columnWidth: '9em',
                    hidden: d => !d.fieldValue,
                    linkTo: d => `/workshop/tasks/${d.parentValue.jobTaskId}`,
                  },
                  {
                    fieldType: FieldType.textField,
                    label: 'Status',
                    readonly: true,
                    dataAddr: ['taskStatus', 'description'],
                  },
                ],
              },
            ],
          },
        ],
        onFormPreSubmit: this.handlePreSubmitForUpdate,
        onFormSubmit: onUpdateServicePlan,
      },
      secondarySections: [getFullServiceCycleSectionDef(this.props.fullServiceCycle)],
    };
  };

  render() {
    const { onLoadAsset, canManageServiceHistory, loadAssetGroupFullServiceCycle } = this.props;
    const mode = 'update';
    return (
      <CrudPage
        def={({ updating }) => this.getPageDef(mode, updating)}
        mode={mode}
        isEditingForbidden={!canManageServiceHistory}
        onLoadData={() =>
          onLoadAsset(this.assetId).then(() => {
            const assetGroupId =
              this.props.asset && this.props.asset.assetGroup && this.props.asset.assetGroup.id;
            if (assetGroupId) {
              return loadAssetGroupFullServiceCycle(assetGroupId);
            }
            return Promise.resolve();
          })
        }
        data={this.getData()}
      />
    );
  }
}

export default ServiceHistory;
