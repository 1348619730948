import { Component } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import escapeRegex from 'escape-string-regexp';
import { DateTime, Duration } from 'luxon';
import { interval } from 'rxjs/observable/interval';
import { Subscription } from 'rxjs/Subscription';
import { PlusIcon } from 'src/images/icons';
import { ListPageLoadCause } from 'src/domain';
import { IListPageLoadDataRequest } from 'src/domain/baseTypes';
import { BasicSearch } from 'src/domain/baseTypes';
import Omit from 'src/infrastructure/omit';
import { get, makePathArray } from 'src/views/components/Page/forms';
import {
  IPageDef,
  PagePrimarySize,
  FieldDefs,
  ActionType,
  PaneType,
  DataAddr,
  IActionGroupDef,
  ITablePaneFieldExtrasDef,
  IFilterActionButtonDef,
  ISectionBadgeDef,
  ITablePaneDef,
  IPaneData,
} from 'src/views/definitionBuilders/types';
import Page from 'src/views/components/Page';
import { getDataFromAddr, getFieldSearchText } from 'src/views/definitionBuilders/utils/fieldUtils';
import withRouteProps from 'src/views/hocs/withRouteProps';
import withQueryParams, { IQueryParamsProps } from 'src/views/hocs/withQueryParams';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { empty } from 'rxjs/observable/empty';
import { getAutoRefreshText } from 'src/views/components/AutoRefresh/autoRefreshHelper';
import { ITaskCard } from 'src/views/components/TaskCard/TaskCard';

export interface IListPageDef<TQuery extends BasicSearch = BasicSearch>
  extends Omit<IPageDef, 'primarySection'> {
  primaryTitle: string | React.ReactNode;
  primaryFields: Array<FieldDefs & ITablePaneFieldExtrasDef>;
  fieldRowOverrides?: ITablePaneDef['fieldRowOverrides'];
  primaryDataAddr?: DataAddr;
  primaryActions?: Array<IActionGroupDef>;
  badge?: ISectionBadgeDef;
  secondaryActions?: Array<IActionGroupDef>;
  filterAction?: Omit<IFilterActionButtonDef, 'actionType'>;
  noRowsMessage?: string;
  // tslint:disable-next-line:no-any
  rowKey?: (data: IPaneData & { itemValue: any }) => string | number;
  externalSearch?: boolean; // Indicates that the data will be filtered before it is passed to the page (disables default searching, and fires onLoadData instead)
  // externalSearch is also for allowing filtering.
  createLink?: ITaskCard;
  hasMoreData?: boolean; // The user will be able to cause `onLoadData` to be fired when they get to the end of the list
  minChar?: number;
  ignoreFilters?: boolean;
  onLoadData: (request: IListPageLoadDataRequest<TQuery>) => Promise<void>;
}

export interface IListPageProps<TQuery extends BasicSearch> {
  className?: string;
  // tslint:disable-next-line:no-any
  data: Array<any>;
  def: IListPageDef<TQuery>;
  autoRefresh?: boolean;
  lastUpdated?: DateTime;
}

interface IListPageState {
  dataLoading: boolean;
  loadCause: ListPageLoadCause | undefined;
  autoRefreshController: BehaviorSubject<boolean>;
}

type InternalProps<TQuery extends BasicSearch> = IListPageProps<TQuery> &
  RouteComponentProps<{}> &
  IQueryParamsProps<TQuery>;

// tslint:disable-next-line:no-any
class ListPage<TQuery extends BasicSearch> extends Component<
  InternalProps<TQuery>,
  IListPageState
> {
  private readonly refreshInterval = Duration.fromMillis(30000);
  private readonly subscriptions = new Subscription();

  constructor(props: InternalProps<TQuery>) {
    super(props);
    this.state = {
      // The first thing list pages do is load data, so initialise with `dataLoading` true
      // to avoid unnecessary additional renders
      dataLoading: true,
      loadCause: ListPageLoadCause.mount,
      autoRefreshController: new BehaviorSubject<boolean>(false),
    };
  }

  componentDidMount() {
    this.loadData(ListPageLoadCause.mount);

    if (this.props.autoRefresh) {
      const refreshDataTimer = interval(this.refreshInterval.as('milliseconds'));
      const badgeTimer = interval(1000);

      this.subscriptions.add(
        this.state.autoRefreshController
          .switchMap(isPaused => (isPaused ? empty() : refreshDataTimer))
          .subscribe(_ => this.loadData(ListPageLoadCause.refresh))
      );
      this.subscriptions.add(
        this.state.autoRefreshController
          .switchMap(isPaused => (isPaused ? empty() : badgeTimer))
          .map(() => getAutoRefreshText(this.refreshInterval, this.props.lastUpdated))
          .distinctUntilChanged()
          .subscribe(() => this.forceUpdate())
      );
    }
  }

  componentWillUnmount() {
    this.subscriptions.unsubscribe();
  }

  shouldComponentUpdate(
    nextProps: Readonly<InternalProps<TQuery>>,
    nextState: Readonly<IListPageState>
  ) {
    const searchChanged =
      this.props.def.externalSearch && this.props.location.search !== nextProps.location.search;
    if (!searchChanged && this.state.dataLoading && nextState.dataLoading) {
      // Prevent any rerenders while data is loading, as it'll just have to rerender soon anyway
      // unless the search in the url has been updated
      return false;
    }

    return true;
  }

  componentDidUpdate(prevProps: Readonly<InternalProps<TQuery>>) {
    // Don't load data if the previous props had defaultFilters.
    // That load will be taken care of in the mount.
    if (
      this.props.def.externalSearch &&
      this.props.location.search !== prevProps.location.search &&
      (!prevProps.location.search.includes('?defaultFilter=true') ||
        this.props.location.search.includes('search=') ||
        (!this.props.location.search.includes('search=') &&
          prevProps.location.search.includes('search=')))
    ) {
      this.loadData(ListPageLoadCause.search);
    }
  }

  // tslint:disable-next-line:no-any
  private readonly getFieldsFilter = (row: any, fields: Array<FieldDefs>, searchRegex: RegExp) => {
    // tslint:disable-next-line:no-any
    const getFieldSearchValue = (def: FieldDefs) => {
      const fieldValue = getDataFromAddr(def.dataAddr, row);
      const sectionValue = get(this.props.data, this.props.def.primaryDataAddr);
      return getFieldSearchText(def, {
        fieldDataAddr: makePathArray([this.props.def.primaryDataAddr, def.dataAddr]),
        fieldValue,
        parentValue: row,
        paneValue: sectionValue,
        panelValue: sectionValue,
        sectionValue: sectionValue,
      });
    };

    return fields.some(fieldDef => {
      const searchValue = getFieldSearchValue(fieldDef);
      return searchValue ? searchValue.search(searchRegex) > -1 : false;
    });
  };

  private getPrimaryActionGroup(addLink: ITaskCard | undefined) {
    const { minChar, ignoreFilters } = this.props.def;
    return {
      actions: addLink
        ? [
            {
              actionType: ActionType.searchActionButton,
              minChar: minChar,
              ignoreFilters,
            },
            {
              actionType: ActionType.addActionLink,
              label: addLink.name,
              icon: <PlusIcon fixedWidth />,
              to: addLink.link,
            },
          ]
        : [
            {
              actionType: ActionType.searchActionButton,
              minChar: minChar,
              ignoreFilters,
            },
          ],
    } as IActionGroupDef;
  }

  private readonly getPageDef = (): IPageDef => {
    const { def, autoRefresh } = this.props;
    const { dataLoading, loadCause } = this.state;
    return {
      primarySize: def.primarySize || PagePrimarySize.threeQuarters,
      primarySection: {
        title: def.primaryTitle,
        dataAddr: def.primaryDataAddr,
        badge:
          def.badge || autoRefresh
            ? {
                label: `Last Updated: ${getAutoRefreshText(
                  this.refreshInterval,
                  this.props.lastUpdated
                )}`,
              }
            : undefined,
        panels: [
          {
            panes: [
              {
                paneType: PaneType.tablePane,
                fields: def.primaryFields,
                loading: dataLoading,
                hideRowsWhileLoading: loadCause === 'search',
                noRowsMessage: def.noRowsMessage,
                fieldRowOverrides: def.fieldRowOverrides,
                rowKey: def.rowKey,
                neverEditable: true,
              },
              {
                paneType: PaneType.actionListPane,
                hidden: !def.hasMoreData || dataLoading,
                actionGroups: [
                  {
                    actions: [
                      {
                        actionType: ActionType.actionButton,
                        label: 'Load More',
                        level: 'primary',
                        icon: <PlusIcon />,
                        onClick: this.loadDataMoreRequested,
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
        primaryActions: [this.getPrimaryActionGroup(def.createLink), ...(def.primaryActions || [])],
        secondaryActions: [
          {
            actions: def.filterAction
              ? [
                  {
                    ...def.filterAction,
                    actionType: ActionType.filterActionButton,
                    onOpenModal: () => {
                      this.state.autoRefreshController.next(true);
                    },
                    onCloseModal: () => {
                      this.state.autoRefreshController.next(false);
                    },
                  },
                ]
              : [],
          },
          ...(def.secondaryActions || []),
        ],
      },
      secondarySections: def.secondarySections,
    };
  };

  private loadDataMoreRequested = async () => {
    return this.loadData(ListPageLoadCause.moreRequested);
  };

  private loadData = async (loadCause: ListPageLoadCause) => {
    try {
      if (!this.state.dataLoading || this.state.loadCause !== loadCause) {
        this.setState({ dataLoading: true, loadCause });
      }
      await this.props.def.onLoadData({
        query: this.props.getQueryParams(),
        loadCause,
      });
    } finally {
      this.setState({ dataLoading: false, loadCause: undefined });
    }
  };

  private readonly getInternallyFilteredData = () => {
    const { def, data } = this.props;
    const { search } = this.props.getQueryParams();

    if (!search) {
      return data;
    }

    const searchRegex = new RegExp(escapeRegex(search || ''), 'i');
    return data.filter(d => this.getFieldsFilter(d, def.primaryFields, searchRegex));
  };

  render() {
    const { className, def, data } = this.props;
    return (
      <Page
        className={`list-page-component ${className ? className : ''}`}
        data={def.externalSearch ? data : this.getInternallyFilteredData()}
        def={this.getPageDef()}
      />
    );
  }
}

export default withRouteProps(withQueryParams(ListPage));
