import './App.scss';
import { Provider } from 'mobx-react';
import { Route, RouteComponentProps, Prompt } from 'react-router-dom';
import HistoryInjector from './components/HistoryInjector';
import Notifications from './components/NotificationsContainer';
import { IKioskRootStoreModel } from 'src/domain/entities/KioskRootStoreModel';
import { IRootStoreModel } from 'src/domain/entities/RootStoreModel';
import { Component, createContext, createRef } from 'react';
import { AppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { AppInsightsErrorBoundary } from '@microsoft/applicationinsights-react-js';
import { reactPlugin } from '../appInsights';

class PagePrompt extends Component {
  private readonly _ids = new Set<symbol>();

  private get when() {
    return !!this._ids.size;
  }

  public readonly setPagePrompt = (id: symbol, prompt: boolean) => {
    const oldWhen = this.when;
    if (prompt) {
      this._ids.add(id);
    } else {
      this._ids.delete(id);
    }
    if (oldWhen !== this.when) {
      // react-router Prompt component doesn't handle showing when the browser is closing, so use beforeunload directly
      const setEventListener = this.when ? window.addEventListener : window.removeEventListener;
      setEventListener('beforeunload', this.handleBeforeUnload);

      setTimeout(() => {
        this.forceUpdate();
      });
    }
  };

  private readonly handleBeforeUnload = (event: BeforeUnloadEvent) => {
    // Cancel the event as stated by the standard.
    event.preventDefault();
    // Chrome requires returnValue to be set.
    event.returnValue = '';
  };

  render() {
    return (
      <Prompt
        when={this.when}
        message="You are about to navigate away from the page. You will lose any changes you have made. Are you sure you want to leave?"
      />
    );
  }
}

interface IRouteContext extends RouteComponentProps<{}> {
  setPagePrompt: (id: symbol, enablePrompt: boolean) => void;
}

export const RouteContext = createContext<IRouteContext | undefined>(undefined);

export const RootStoreContext = createContext<IRootStoreModel | IKioskRootStoreModel | undefined>(
  undefined
);

const RootStoreProvider = RootStoreContext.Provider;

export interface IAppProps {
  rootStore: IRootStoreModel | IKioskRootStoreModel;
  router: React.ComponentType;
  routes: React.ComponentType<any>;
}

class App extends Component<IAppProps> {
  private readonly _pagePromptRef = createRef<PagePrompt>();

  private readonly setPagePrompt = (id: symbol, prompt: boolean) => {
    this._pagePromptRef.current && this._pagePromptRef.current.setPagePrompt(id, prompt);
  };

  render() {
    const Router = this.props.router;
    const Routes = this.props.routes;
    const setPagePrompt = this.setPagePrompt;
    return (
      <AppInsightsContext.Provider value={reactPlugin}>
        <AppInsightsErrorBoundary
          onError={() => <h1>An unhandled error occured.</h1>}
          appInsights={reactPlugin}>
          <div className="App">
            <Provider rootStore={this.props.rootStore}>
              <RootStoreProvider value={this.props.rootStore}>
                <div>
                  <Router>
                    <div>
                      <Route
                        render={ps => (
                          <HistoryInjector
                            {...ps}
                            setHistory={this.props.rootStore.history.setHistory}
                          />
                        )}
                      />
                      <Route>
                        {props => (
                          // @ts-ignore
                          <RouteContext.Provider value={{ ...props, setPagePrompt }}>
                            <Routes />
                          </RouteContext.Provider>
                        )}
                      </Route>
                      <PagePrompt ref={this._pagePromptRef} />
                    </div>
                  </Router>
                  <Notifications />
                </div>
              </RootStoreProvider>
            </Provider>
          </div>
        </AppInsightsErrorBoundary>
      </AppInsightsContext.Provider>
    );
  }
}

export default App;
