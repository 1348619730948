import { buildListPageApiSearchModelTypedQuery } from 'src/domain/modelBuilders/buildListPageApiSearchModel';
import { flow, getRoot } from 'mobx-state-tree';
import * as queryString from 'query-string';
import { getAjax, NotificationType } from 'src/domain/services';
import { IRootStoreModel } from 'src/domain/entities/RootStoreModel';

export const LeavesModel = buildListPageApiSearchModelTypedQuery<
  People.Domain.Queries.ListLeave.ListLeaveQuery
>()('LeavesModel', d =>
  d.ajax.raw
    .get(`/api/people/leaves?${queryString.stringify(d.query)}`)
    .map(r => r.response as Common.Dtos.ListResult<People.Domain.Queries.ListLeave.LeaveItem>)
).actions(self => {
  const ajax = getAjax(self);
  const root = getRoot(self) as IRootStoreModel;
  const exportToExcel = flow(function*(
    query: Partial<People.Domain.Queries.ListLeave.ListLeaveQuery>
  ) {
    root.notifications.addNotification(`The file is being generated ...`, {
      type: NotificationType.info,
    });

    return yield ajax.raw
      .getFile(`/api/people/leaves/excel?${queryString.stringify(query)}`)
      .toPromise()
      .then<Blob>(r => r.response);
  });

  return {
    exportToExcel,
  };
});
