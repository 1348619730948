import { DateTime } from 'luxon';
import { types, flow } from 'mobx-state-tree';
import { IListPageLoadDataRequest } from 'src/domain/baseTypes';
import { ListPageLoadCause } from 'src/domain';
import { Subject } from 'rxjs/Subject';
import { Observable } from 'rxjs/Observable';
import { getAjax } from 'src/domain/services';

const defaultPageSize = 25;

interface IListRequestData<T> {
  ajax: ReturnType<typeof getAjax>;
  page: number;
  query: Partial<T>;
}

interface ISearchSubjectPayload<TQuery, TItem> {
  requestId: symbol;
  page: number;
  size: number;
  query: Partial<TQuery>;
  currentItems: Array<TItem>;
}

interface ISearchSubjectResult<TItem> {
  requestId: symbol;
  page: number;
  hasMore: boolean;
  results: TItem[];
  retrievedAt: DateTime;
}

export function buildListPageApiSearchModelTypedQuery<TQuery extends Common.BasicSearchQuery>() {
  return <TItem>(
    modelName: string,
    getList: (data: IListRequestData<TQuery>) => Observable<Common.Dtos.ListResult<TItem>>,
    options?: { size?: number; equalityComparer?: (value: TItem, value2: TItem) => boolean }
  ) => buildListPageApiSearchModel<TQuery, TItem>(modelName, getList, options);
}

export function buildListPageApiSearchModel<TQuery extends Common.BasicSearchQuery, TItem>(
  modelName: string,
  getList: (data: IListRequestData<TQuery>) => Observable<Common.Dtos.ListResult<TItem>>,
  options?: { size?: number; equalityComparer?: (value: TItem, value2: TItem) => boolean }
) {
  const size = (options && options.size) || defaultPageSize;
  const equalityComparer = (options && options.equalityComparer) || ((a, b) => a === b);
  return types
    .model(modelName, {
      items: types.array(types.frozen<TItem>()),
      hasMoreItems: types.optional(types.boolean, true),
      size: types.optional(types.literal(size), size),
      page: types.optional(types.number, 0),
      query: types.optional(types.frozen<Partial<TQuery>>(), {}),
      lastUpdated: types.maybe(types.frozen<DateTime>()),
    })
    .actions(self => {
      const ajax = getAjax(self);

      let lastAppliedRequestId: symbol | undefined;

      const searchSubject = new Subject<ISearchSubjectPayload<TQuery, TItem>>();
      const searchResult$: Observable<ISearchSubjectResult<TItem>> = searchSubject
        // Only keep waiting for the latest getList result (cancel older ones)
        .switchMap(r =>
          getList({
            ajax,
            page: r.page,
            query: Object.assign({}, r.query, { page: r.page, size: r.size }),
          }).map(response => ({
            requestId: r.requestId,
            page: r.page,
            hasMore: response.hasMore,
            results: r.currentItems.concat(response.items),
            retrievedAt: DateTime.local(),
          }))
        )
        .share(); // we only want one copy of this

      const refreshItems = flow(function*(
        request: IListPageLoadDataRequest<TQuery>,
        objectsToRemove?: any[]
      ) {
        const searchPromise = searchResult$.take(1).toPromise();
        searchSubject.next({
          requestId: Symbol(),
          page: 1,
          size: self.size,
          query: request.query || {},
          currentItems: [],
        });
        const result: ISearchSubjectResult<TItem> = yield searchPromise;

        if (lastAppliedRequestId !== result.requestId) {
          lastAppliedRequestId = result.requestId;

          if (result.results.length === 0 && objectsToRemove) {
            objectsToRemove.forEach(r => {
              const index = self.items.findIndex(f => equalityComparer(f, r));
              if (index > -1) {
                let itemToRemove = self.items[index];
                self.items.remove(itemToRemove);
              }
            });
          }

          result.results.forEach(r => {
            const index = self.items.findIndex(f => equalityComparer(f, r));
            if (index > -1) {
              self.items[index] = r as Extract<TItem, object>;
            }
          });
          self.lastUpdated = result.retrievedAt;
        }
      });

      const listItems = flow(function*(request: IListPageLoadDataRequest<TQuery>) {
        const searchPromise = searchResult$.take(1).toPromise();

        if (request.loadCause === ListPageLoadCause.refresh) {
          searchSubject.next({
            requestId: Symbol(),
            page: 1,
            size: size * self.page,
            query: self.query,
            currentItems: [],
          });
        } else {
          const addPage = request.loadCause === ListPageLoadCause.moreRequested;
          if (addPage) {
            self.page = self.page + 1;
          } else {
            self.page = 1;

            // Typescript doesn't think we can assign to query. STNValue issues, not sure how to fix it.
            // @ts-ignore
            self.query = request.query || {};
          }

          searchSubject.next({
            requestId: Symbol(),
            page: self.page,
            size,
            query: self.query,
            currentItems: addPage ? self.items.slice() : [],
          });
        }
        const result: ISearchSubjectResult<TItem> = yield searchPromise;

        if (lastAppliedRequestId !== result.requestId) {
          lastAppliedRequestId = result.requestId;
          self.items.replace(result.results as Extract<TItem, object>[]);
          self.hasMoreItems = result.hasMore;
          self.lastUpdated = result.retrievedAt;
        }
      });

      return { listItems, refreshItems };
    });
}

export default buildListPageApiSearchModel;
