import { types, flow } from 'mobx-state-tree';
import { getAjax } from 'src/domain/services';

type PartsGroupItem = Workshop.Domain.Queries.GetPartsGroups.PartsGroupItem;

export const PartsGroupsModel = types
  .model('PartsGroupsModel', {
    partsGroups: types.array(types.frozen<PartsGroupItem>()),
  })
  .actions(self => {
    const ajax = getAjax(self);

    const listPartsGroups = flow(function*(includeInactivePartsGroups: boolean) {
      self.partsGroups = yield ajax.workshop.part.listPartsGroups(includeInactivePartsGroups);
    });

    const getPartsGroupParts = flow(function*(partsGroupId: string) {
      return yield ajax.workshop.part.getPartsGroupParts(partsGroupId);
    });

    return {
      listPartsGroups,
      getPartsGroupParts,
    };
  });

type IPartsGroupsModelType = typeof PartsGroupsModel.Type;
export interface IPartsGroupsModel extends IPartsGroupsModelType {}
