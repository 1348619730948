import './PrintTimesheet.scss';
import PrintButton from 'src/views/components/PrintButton';
import { PrintIcon } from 'src/images/icons';

const PrintTimesheet: React.FC<{
  loadDataAsync: () => Promise<void>;
  timesheetForPrinting: Blob | undefined;
}> = ({ loadDataAsync, timesheetForPrinting }) => {
  return (
    <PrintButton
      className="print-timesheet-button"
      size="lg"
      outline
      loadDataAsync={loadDataAsync}
      printContent={timesheetForPrinting}>
      <PrintIcon className="print-timesheet-icon" />
      Print Timesheet
    </PrintButton>
  );
};

export default PrintTimesheet;
