import styles from './CreateDefect.module.scss';

import RelatedDefectsList from 'src/views/kioskRoutes/components/connected/RelatedDefectList/RelatedDefectListContainer';
import CreateDefectForm from '../../components/connected/CreateDefectForm/CreateDefectFormContainer';
import SplitScreen from '../components/layout/SplitScreen/SplitScreen';
import WithMenu from '../menu/WithMenu';

export interface ICreateDefectProps {
  canUseTimesheets: boolean | undefined;
}

const CreateDefect: React.FC<ICreateDefectProps> = props => {
  const left = (
    <div className={styles.relatedDefects}>
      <WithMenu>
        <RelatedDefectsList />
      </WithMenu>
    </div>
  );

  const right = (
    <div className={styles.createDefects}>
      <h5>Raise New Defect</h5>
      <CreateDefectForm />
    </div>
  );

  return (
    <div className={styles.createDefectComponent}>
      <SplitScreen left={left} right={right} />
    </div>
  );
};

export default CreateDefect;
