import { formatToFixed } from 'src/infrastructure/mathUtil';
import { PaneType, FieldType, ModalDefBuilder } from 'src/views/definitionBuilders/types';
import { priceExGst, priceIncGst } from './utils/quoteMaths';
import { QuoteStatus } from 'src/api/enums';
import { Link } from 'react-router-dom';
import { DateTimeFormat } from 'src/views/components/DateTimeFormat';
import { getDaysInPeriodInclusiveAsStrings } from 'src/infrastructure/allocationUtils';
import { DateTime } from 'luxon';
import { WarnIcon } from 'src/images/icons';
import { getSubmitCloseModalActionGroupDef } from 'src/views/definitionBuilders/common';

type VehicleTypeAllocationsByDayDto = Operations.Domain.Queries.GetVehicleTypeAllocationData.VehicleTypeAllocationsByDayDto;
type Trip = Operations.Domain.Queries.ViewQuote.Trip;
type Option = Operations.Domain.Queries.ViewQuote.Option;
type SelectedOption = Operations.Domain.AggregatesModel.QuoteAggregate.SelectedOption;
type QuoteItem = Operations.Domain.Queries.ViewQuote.QuoteItem;
type Repeat = Operations.Domain.Queries.ViewQuote.Repeat;
type ModalViewModel = {
  quote: QuoteItem;
  trips: Array<Trip>;
};

const getNumberOfNonBookedQuotes = (quote: QuoteItem) => {
  const numberOfNonBookedRepeats = quote.repeats
    ? quote.repeats.filter(
        r => r.linkedQuoteStatus && r.linkedQuoteStatus.id !== QuoteStatus.Booked
      ).length
    : 0;
  const numberOfNonBookedOriginalQuotes =
    getOriginalQuote(quote).status.id !== QuoteStatus.Booked ? 1 : 0;

  return numberOfNonBookedRepeats + numberOfNonBookedOriginalQuotes;
};

const getOriginalQuote = (quote: QuoteItem) => (quote.sourceQuote ? quote.sourceQuote : quote);

interface IDisplayableOption {
  title: string;
  gstIncPrice: string;
  gstExPrice: string;
  vehicleSummary: string;
  extraSummary: string;
  vehicleOverallocationMessage?: string;
}

interface IBookableTrip extends Trip {
  selectedOptionId?: number;
}

export default function getBookQuoteModalDef(
  quote: QuoteItem,
  onBook: (seletedOptions: SelectedOption[]) => Promise<void>,
  vehicleTypeAllocationData: Array<VehicleTypeAllocationsByDayDto>
): ModalDefBuilder {
  const loadedTrips = (quote && quote.trips) || [];
  const updatedTrips: Array<IBookableTrip> = loadedTrips.map(t =>
    t.options.length === 1 ? { ...t, selectedOptionId: t.options[0].id } : t
  );

  const getTripOptionSelectData = (
    option: Option,
    tripDays: string[]
  ): {
    overallocationMessage: string | undefined;
    vehicleSummaries: string[];
  } => {
    const potentiallyOverallocatedVehicleDescriptions: Array<string> = [];
    const vehicleSummaries: Array<string> = [];

    option.vehicles.forEach(v => {
      vehicleSummaries.push(`${v.quantity} × ${v.vehicleType.description}`);
      tripDays.forEach(day => {
        const allocationItem = vehicleTypeAllocationData.find(r => r.day === day);
        if (allocationItem) {
          const vehicleTypeAllocationRecord = allocationItem.items.find(
            i => i.vehicleTypeId === v.vehicleType.id
          );
          if (vehicleTypeAllocationRecord) {
            vehicleTypeAllocationRecord.numberBookedOnDay +
              vehicleTypeAllocationRecord.numberQuotedOnDay >
              vehicleTypeAllocationRecord.numberVehiclesAtDepot &&
              !potentiallyOverallocatedVehicleDescriptions.includes(v.vehicleType.description) &&
              potentiallyOverallocatedVehicleDescriptions.push(v.vehicleType.description);
          }
        }
      });
    });

    // return just the string here
    return {
      overallocationMessage: potentiallyOverallocatedVehicleDescriptions.length
        ? `${potentiallyOverallocatedVehicleDescriptions.join(
            ', '
          )} may be overquoted on the requested date(s).`
        : undefined,
      vehicleSummaries: vehicleSummaries,
    };
  };

  return () => ({
    title: 'Book Quote',
    explicitData: { quote, trips: updatedTrips } as ModalViewModel,
    asForm: true,
    panels: [
      {
        panes: [
          {
            paneType: PaneType.customPane,
            hidden: () => getNumberOfNonBookedQuotes(quote) <= 1,
            render: () => (
              <p>
                <strong style={{ display: 'inline-block', marginTop: '1em', marginBottom: '1em' }}>
                  Please bear in mind that there are recurring bookings associated with the booking
                  and they must be re-booked manually if needed.
                </strong>
              </p>
            ),
          },
          {
            paneType: PaneType.nestingPane,
            dataAddr: 'quote.repeats',
            hidden: () => getNumberOfNonBookedQuotes(quote) <= 1,
            panes: [
              {
                paneType: PaneType.formFieldsPane,
                fields: [
                  {
                    fieldType: FieldType.readonlyField,
                    label: 'Original Booking',
                    hidden: () => !quote.sourceQuote || getNumberOfNonBookedQuotes(quote) <= 1,
                    formatReadonly: () => {
                      const sourceQuote = quote.sourceQuote;
                      return sourceQuote ? (
                        <div>
                          <div>
                            <DateTimeFormat value={sourceQuote.firstTrip} />
                          </div>
                          <div>
                            <small>
                              <Link to={`/sales/bookings/${sourceQuote.id}`}>
                                {`Booking ${sourceQuote.quoteNumber} (${sourceQuote.status.description})`}
                              </Link>
                            </small>
                          </div>
                        </div>
                      ) : null;
                    },
                  },
                ],
              },
              {
                paneType: PaneType.tablePane,
                dataRequiredForRows: 'paneValue',
                hidden: d => !d.paneValue || !d.paneValue.length,
                fields: [
                  {
                    fieldType: FieldType.dateField,
                    dataAddr: 'repeatDate',
                    label: 'Associated Recurring Bookings',
                    mandatory: true,
                    readonly: d => true,
                    formatReadonly: d => {
                      const repeat: Repeat = d.parentValue;
                      const status = repeat.linkedQuoteStatus
                        ? repeat.linkedQuoteStatus.description
                        : undefined;
                      return d.fieldValue ? (
                        <div>
                          <div>
                            <DateTimeFormat value={d.fieldValue} />
                          </div>
                          <div>
                            {quote && repeat.linkedQuoteId === quote.id ? (
                              <div>
                                <small>(This booking)</small>
                              </div>
                            ) : repeat.linkedQuoteId ? (
                              <small>
                                <Link to={`/sales/quotes/${repeat.linkedQuoteId}`}>
                                  {`Booking ${repeat.linkedQuoteNumber} (${status})`}
                                </Link>
                              </small>
                            ) : null}
                          </div>
                        </div>
                      ) : null;
                    },
                  },
                ],
              },
            ],
          },
          {
            paneType: PaneType.customPane,
            render: () => {
              const isWarningPresented = getNumberOfNonBookedQuotes(quote) > 1;
              const style = isWarningPresented ? { marginTop: '1em' } : undefined;
              return (
                <div style={style}>
                  {loadedTrips.length > 1 ? (
                    <p>Select an option for each trip.</p>
                  ) : (
                    <p>Select an option for the trip.</p>
                  )}
                </div>
              );
            },
          },
          {
            paneType: PaneType.repeatingPane,
            dataAddr: 'trips',
            itemPanes: [
              {
                paneType: PaneType.customPane,
                render: api => {
                  const trip = api.data.paneValue as Trip;
                  const trips = (api.data.sectionValue as ModalViewModel).trips;
                  const idx = trips.indexOf(trip);
                  return <h3>{`Trip ${trips.length > 1 ? idx + 1 : ''}`}</h3>;
                },
              },
              {
                paneType: PaneType.formFieldsPane,
                fields: [
                  {
                    fieldType: FieldType.selectField,
                    dataAddr: 'selectedOptionId',
                    label: 'Selected Option',
                    valueKey: 'id',
                    descriptionKey: 'id',
                    useValueOnly: true,
                    readonly: d => (d.paneValue as Trip).options.length === 1,
                    optionItems: d => {
                      const trip = d.paneValue as Trip;
                      const tripDays = getDaysInPeriodInclusiveAsStrings(
                        DateTime.fromJSDate(new Date(trip.routes[0].date)),
                        DateTime.fromJSDate(new Date(trip.routes[trip.routes.length - 1].date))
                      );
                      return trip.options.map((o, idx) => {
                        const gstExPrice = formatToFixed(
                          o.overrideTotalPriceExGst ?? priceExGst(o.vehicles, o.extras),
                          2
                        );
                        const gstIncPrice = formatToFixed(
                          o.overrideTotalPriceExGst
                            ? Number(o.overrideTotalPriceExGst) + (Number(o.overrideTotalGst) || 0)
                            : priceIncGst(o.vehicles, o.extras),
                          2
                        );

                        const { overallocationMessage, vehicleSummaries } = getTripOptionSelectData(
                          o,
                          tripDays
                        );

                        return {
                          ...o,
                          title: `Option ${idx + 1}`,
                          gstIncPrice,
                          gstExPrice,
                          vehicleSummary: vehicleSummaries.join(', '),
                          extraSummary: o.extras
                            .map(e => `${e.quantity} × ${e.extraType.description}`)
                            .join(', '),
                          vehicleOverallocationMessage: overallocationMessage,
                        };
                      });
                    },
                    mandatory: true,
                    optionRenderer: (o: Option & IDisplayableOption) => (
                      <div>
                        <div>
                          <strong>{o.title}</strong>
                          {o.vehicleOverallocationMessage ? (
                            <WarnIcon title={o.vehicleOverallocationMessage} color="orange" />
                          ) : null}
                        </div>
                        <div>
                          <span>Total:</span>
                          <span>
                            &emsp;
                            <strong>${o.gstIncPrice}</strong>
                            <small> (inc. GST)</small>
                          </span>
                          <span>
                            &emsp;
                            <strong>${o.gstExPrice}</strong>
                            <small> (ex. GST)</small>
                          </span>
                        </div>
                        <div>
                          <small>{o.vehicleSummary}</small>
                        </div>
                        <div>
                          <small>{o.extraSummary}</small>
                        </div>
                      </div>
                    ),
                    valueRenderer: (o: Option & IDisplayableOption) => (
                      <span>
                        <strong>{o.title}</strong>
                        {o.vehicleOverallocationMessage ? (
                          <WarnIcon title={o.vehicleOverallocationMessage} color="orange" />
                        ) : null}
                        <span>
                          &emsp;$
                          {o.gstIncPrice}
                        </span>
                        <small>
                          &emsp;
                          {o.vehicleSummary}
                          &emsp;
                          {o.extraSummary}
                        </small>
                      </span>
                    ),
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
    secondaryActions: [getSubmitCloseModalActionGroupDef('Book')],
    onFormSubmit: (data: ModalViewModel) => {
      const tripsWithOptions = data.trips as Array<IBookableTrip>;
      return onBook(
        tripsWithOptions.map(t => ({ tripId: t.id, optionId: t.selectedOptionId || 0 }))
      );
    },
  });
}
