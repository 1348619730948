import { UiRouteName } from 'src/domain/baseTypes';
import { IRootStoreModel } from 'src/domain/entities/RootStoreModel';
import UiRoute from 'src/domain/services/buildUiRoutes/UiRoute';

export default function buildUiRoutes(store: IRootStoreModel) {
  const ownerCreate = new UiRoute(
    UiRouteName.workshopOwnerCreate,
    '/workshop/owners/create',
    () => 'Create Owner',
    false
  );

  const manageOwner = new UiRoute(
    UiRouteName.workshopOwnerEdit,
    '/workshop/owners/:id',
    () => 'Manage Owner',
    false
  );

  const ownersList = new UiRoute(
    UiRouteName.workshopOwnerList,
    '/workshop/owners',
    () => 'Owners',
    false,
    true,
    [ownerCreate, manageOwner]
  );
  return {
    [UiRouteName.workshopOwnerList]: ownersList,
    [UiRouteName.workshopOwnerCreate]: ownerCreate,
    [UiRouteName.workshopOwnerEdit]: manageOwner,
  };
}
