import { types, flow, getRoot, applySnapshot } from 'mobx-state-tree';
import { getAjax, NotificationType } from 'src/domain/services/';
import { AjaxResponse, AjaxError } from 'rxjs/observable/dom/AjaxObservable';
import { DateTime } from 'luxon';
import { IKioskRootStoreModel } from 'src/domain/entities/KioskRootStoreModel';
import logger from 'src/infrastructure/logging';
import { appInsights } from 'src/appInsights';

type CopClaimsUser = Common.Authentication.CopClaimsUser;
type StaffMemberDetails = Common.Queries.GetStaffMemberDetails.StaffMemberDetails;
type AuthoriseDeviceResponseDto = WebApi.Controllers.Kiosk.AuthoriseDeviceController.AuthoriseDeviceResponseDto;
type LicenceExpirationForStaffMember = People.Domain.Queries.ListUpcomingLicenceExpirationsForStaffMember.LicenceExpirationForStaffMember;
type IncompleteJobForDriver = Operations.Domain.Queries.ListIncompleteJobsForDriver.IncompleteJobForDriver;
type IncompleteShiftForMechanic = Workshop.Domain.Queries.ListIncompleteShiftsForMechanic.IncompleteShiftForMechanic;
type EmploymentStatusDto = Common.Dtos.EmploymentStatusDto;

export const KioskAccountModel = types
  .model('KioskAccountModel', {
    isAuthenticated: types.maybe(types.boolean),
    email: types.maybe(types.string),
    name: types.maybe(types.string),
    initials: types.maybe(types.string),
    whenSignedInUtc: types.maybe(types.frozen<DateTime>()),
    id: types.maybe(types.string),
    employmentStatus: types.maybe(types.frozen<EmploymentStatusDto>()),
    staffMemberDepot: types.maybe(types.number),
    canUseTimesheets: types.maybe(types.boolean),
    isMechanic: types.maybe(types.boolean),
    expiredLicences: types.array(types.frozen<LicenceExpirationForStaffMember>()),
    incompleteJobs: types.array(types.frozen<IncompleteJobForDriver>()),
    incompleteShifts: types.array(types.frozen<IncompleteShiftForMechanic>()),
    companyHasDriversApp: types.boolean,
    isAlcoReadingEnabledDevice: types.maybe(types.boolean),
  })
  .views(self => ({
    get checkedName() {
      return self.name || 'Guest';
    },
    get checkedInitials() {
      return self.initials || '';
    },
  }))
  .actions(self => {
    const ajax = getAjax(self);
    const log = logger;
    const root = getRoot(self) as IKioskRootStoreModel;

    const resetUserDetails = () => {
      self.isAuthenticated = false;
      self.email = undefined;
      self.name = undefined;
      self.initials = undefined;
      self.whenSignedInUtc = undefined;
      self.id = undefined;
      self.employmentStatus = undefined;
      self.staffMemberDepot = undefined;
      self.canUseTimesheets = undefined;
      self.isMechanic = undefined;
    };

    const loadExpiredLicences: () => Promise<void> = flow(function* listExpiredLicences() {
      self.expiredLicences = yield ajax.raw
        .get('/api/kiosk/licence-expirations')
        .toPromise()
        .then<LicenceExpirationForStaffMember[]>(r => r.response);
    });

    const loadIncompleteJobs: () => Promise<void> = flow(function* loadIncompleteJobs() {
      self.incompleteJobs = yield ajax.raw
        .get('/api/kiosk/incomplete-jobs')
        .toPromise()
        .then<IncompleteJobForDriver[]>(r => r.response);
    });

    const loadIncompleteShifts: () => Promise<void> = flow(function* loadIncompleteShifts() {
      self.incompleteShifts = yield ajax.raw
        .get('/api/kiosk/incomplete-shifts')
        .toPromise()
        .then<IncompleteShiftForMechanic[]>(r => r.response);
    });

    const authoriseDevice: (code: string) => Promise<void> = flow(function* authoriseDeviceFlow(
      code: string
    ) {
      try {
        const response: AjaxResponse = yield ajax.raw
          .send({
            url: '/api/kiosk/authorise-device',
            method: 'POST',
            headers: { authorization: `bearer ${code}` },
          })
          .toPromise();
        const responseContent: AuthoriseDeviceResponseDto = response.response;
        const deviceName = responseContent.name || undefined;
        root.notifications.addNotification(`This device has been authorised as "${deviceName}"`, {
          type: NotificationType.success,
        });

        goToLoginPage();
      } catch (e) {
        log.error(e as Error);
        root.notifications.addNotification(`This device could not be authorised using this code`, {
          type: NotificationType.error,
        });
      }
    });

    const goToLoginPage = () => {
      resetUserDetails();
      // This will always reload the page and reset the whole app
      window.location.assign(`${root.home}/login`);
    };

    const signOutKiosk = flow(function*() {
      yield ajax.user.signOutKiosk();
      goToLoginPage();
    });

    const handleKioskAuthError = (e: unknown) => {
      if (e instanceof AjaxError) {
        switch (e.status) {
          case 401:
          case 403: {
            const isAuthorisedDevice =
              e.xhr.getResponseHeader('authorised-fleet-device') === 'true';

            isAuthorisedDevice
              ? root.history.push(`${root.home}/login`)
              : root.history.push(`${root.home}/authorise-device`);
            break;
          }
          default: {
            break;
          }
        }
      }
    };

    const loadKioskProfile: () => Promise<void> = flow(function*() {
      try {
        resetUserDetails();
        const response: AjaxResponse = yield ajax.user.getKioskProfile();
        const profile: CopClaimsUser = response.response;

        if (!profile.oid) {
          throw new Error('Loaded profile has no id');
        }
        const details: StaffMemberDetails = yield ajax.user.getStaffMemberDetails(profile.oid);
        yield root.kiosk.clockOn.loadStaffMemberStatus(profile.oid);

        applySnapshot(self, {
          ...profile,
          ...details,
          id: profile.oid,
          isAuthenticated: true,
          whenSignedInUtc: DateTime.utc(),
          isMechanic: profile.isWorkshopDepartmentMember,
        });
        appInsights.context.user.id = profile.name;
        appInsights.context.user.authenticatedId = profile.name;
        appInsights.context.user.accountId = profile.email;
        appInsights.context.user.localId = profile.email;
        root.kiosk.updateDate(DateTime.local());
        self.isAlcoReadingEnabledDevice =
          response.xhr.getResponseHeader('authorised-alco-reading-device') === 'true';
      } catch (e) {
        handleKioskAuthError(e);
        log.error(e as Error);
        self.isAuthenticated = false;
      }
    });

    const signInKiosk = flow(function*(employeeId: string) {
      try {
        yield ajax.user.signInKiosk({ employeeId: employeeId });
        // automatically refresh the site if required while the user is signing in and they probably won't notice
        root.refreshSiteIfRequired();
        yield loadKioskProfile();
      } catch (e) {
        handleKioskAuthError(e);
        root.notifications.addNotification(`Invalid employee id`, {
          type: NotificationType.warn,
        });
        log.error(e as Error);
      }
    });

    return {
      authoriseDevice,
      loadKioskProfile,
      signInKiosk,
      signOutKiosk,
      loadExpiredLicences,
      loadIncompleteJobs,
      loadIncompleteShifts,
    };
  });

type IKioskAccountModelType = typeof KioskAccountModel.Type;
export interface IKioskAccountModel extends IKioskAccountModelType {}
