import { DateTime } from 'luxon';
import { Component } from 'react';
import {
  IJobItem,
  AllBoundedItemTypes,
} from 'src/domain/entities/operations/job/ListJobsForAllocationsModel';
import { SearchTechSpecValues } from 'src/domain/entities/workshop/asset/AssetsModel';
import { applyTechSpecRequirementsFilter } from 'src/domain/entities/workshop/techSpecs/TechSpecsHelpers';
import { NestedField } from 'src/views/components/Page/forms';
import { renderItem, getItemUiKey } from '../../AllocationsBoundedItemHelper';
import { getVehicleView } from '../../views';
import { IGanttView, IGanttCalendarDayItem, IGanttCalendarGroupItem } from '../baseTypes';
import GanttCalendar, { IViewGroupFilterValues } from '../GanttCalendar';
import { getVehicleFilterPillDefs } from '../staffAndVehiclePillFilterDefs';
import { DriverLicenceLicenceType } from 'src/views/components/LicenceTypesHelper';

type AssetItem = Common.Queries.Workshop.GetFleetAssetList.AssetItem;
type AssetSubcategory = Workshop.Domain.AggregatesModel.AssetAggregate.AssetSubcategory;
type AssetHousingLocation = Workshop.Domain.AggregatesModel.AssetAggregate.AssetHousingLocation;
type TechSpecItem = Common.Dtos.TechSpecItem;
type ExclusionItem = Operations.Domain.Queries.ListExclusions.ExclusionItem;
type TechSpecDropdownOption = Workshop.Domain.Queries.TechSpecs.TechSpecDropdownOptionsItem.TechSpecDropdownOption;
type VehicleType = Common.Dtos.VehicleTypeItem;
type StaffMemberDto = Common.Dtos.StaffMemberDto;
export interface IAssignVehicleFieldProps {
  job: IJobItem;
  showInZone: string;
  unfilteredItems: Array<AllBoundedItemTypes>;
  vehicles: Array<AssetItem>;
  exclusions: ExclusionItem[];
  assetSubcategories: AssetSubcategory[];
  assetHousingLocations: AssetHousingLocation[];
  techSpecs: TechSpecItem[];
  vehicleTypes: VehicleType[];
  searchTechSpecValues: SearchTechSpecValues;
  onLoadUnfilteredItems: (start: DateTime, end: DateTime) => Promise<void>;
  techSpecDropdownOptions: (techSpecId: number) => TechSpecDropdownOption[];
  viewFilters: { [key: string]: IViewGroupFilterValues };
  staffMembers: StaffMemberDto[];
}

interface IAssignVehicleFieldState {
  viewVehicleFilters: { [key: string]: any };
}

export class AssignVehicleField extends Component<
  IAssignVehicleFieldProps,
  IAssignVehicleFieldState
> {
  constructor(props: IAssignVehicleFieldProps) {
    super(props);
    this.state = {
      viewVehicleFilters: {
        vehicle: {
          search: props.viewFilters ? props.viewFilters.vehicle.search : '',
          filters: props.viewFilters ? props.viewFilters.vehicle.filters : {},
        },
      },
    };
  }

  componentDidMount() {
    const { job, onLoadUnfilteredItems } = this.props;
    onLoadUnfilteredItems(job.bounds.start.minus({ days: 1 }), job.bounds.end.plus({ days: 1 }));
  }

  private readonly handleFilter = (change: Partial<IViewGroupFilterValues>) => {
    const newFiltering = {
      ...this.state.viewVehicleFilters,
      vehicle: {
        filters: this.state.viewVehicleFilters ? this.state.viewVehicleFilters.vehicle.filters : {},
        search: this.state.viewVehicleFilters ? this.state.viewVehicleFilters.vehicle.search : '',
        ...change,
      },
    };
    this.setState({ viewVehicleFilters: newFiltering });
  };

  // tslint:disable-next-line:no-any
  private readonly handleValidate = (value: any) => {
    return !value || typeof value !== 'string' ? 'A Vehicle must be selected' : undefined;
  };

  render() {
    const {
      showInZone,
      job,
      unfilteredItems,
      vehicles,
      exclusions,
      assetSubcategories,
      assetHousingLocations,
      techSpecs,
      vehicleTypes,
      searchTechSpecValues,
      techSpecDropdownOptions,
      staffMembers,
    } = this.props;

    let filteredVehicles = vehicles;
    if (job.staffMemberId || job.secondStaffMemberId) {
      const staffMemberIds = [job.staffMemberId, job.secondStaffMemberId].filter(x => !!x);
      const assignedStaff = staffMembers
        .filter(s => staffMemberIds.includes(s.id))
        .map(sm => ({
          id: sm.id,
          licenceClass: sm.licences.filter(l => l.licenceTypeId === DriverLicenceLicenceType)[0],
        }));

      filteredVehicles = filteredVehicles.filter(
        x =>
          exclusions.findIndex(
            e => staffMemberIds.findIndex(s => s === e.staffMember.id) !== -1 && e.asset.id === x.id
          ) === -1 &&
          assignedStaff.findIndex(
            s => s.licenceClass?.licenceClass?.id < (x.licenceClassId ?? 0)
          ) === -1
      );
      if (job.techSpecRequirements && job.techSpecRequirements.length) {
        filteredVehicles = applyTechSpecRequirementsFilter(
          filteredVehicles,
          job.techSpecRequirements
        );
      }
    }
    return (
      <NestedField field="assetId" validate={this.handleValidate}>
        <GanttCalendar
          className="assign-asset-component"
          showInZone={showInZone}
          items={unfilteredItems}
          minRows={10}
          rowHeightPx={40}
          dayWidthPx={1500}
          minZoomFactor={1}
          maxZoomFactor={8}
          highlightBounds={job.bounds}
          renderItem={renderItem}
          getItemUiKey={getItemUiKey}
          renderItemPopup={() => null}
          ganttViews={{
            vehicle: (getVehicleView(
              filteredVehicles,
              [],
              assetSubcategories,
              assetHousingLocations,
              techSpecs,
              vehicleTypes,
              searchTechSpecValues,
              techSpecDropdownOptions,
              'excludeUngroupedItems',
              'selectable'
            ) as unknown) as IGanttView<IGanttCalendarDayItem, IGanttCalendarGroupItem>,
          }}
          excludeUngroupedView
          filterDefsForSubFilterPills={getVehicleFilterPillDefs(
            assetSubcategories,
            assetHousingLocations,
            techSpecs,
            vehicleTypes,
            searchTechSpecValues,
            techSpecDropdownOptions
          )}
          pillContainerId="vehicle-allocation-popover-pills"
          viewFilters={this.state.viewVehicleFilters}
          handleFilteringChange={this.handleFilter}
        />
      </NestedField>
    );
  }
}
