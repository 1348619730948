import { Instance, types } from 'mobx-state-tree';

type FatigueValidationFailureMessages = Operations.Domain.Services.Fatigue.FatigueValidationFailureMessages;
type FatigueWarning = Operations.Domain.Services.Fatigue.FatigueWarning;

export const FatigueValidationsModel = types
  .model('FatigueValidationsModel', {
    validationMessages: types.maybe(types.frozen<FatigueValidationFailureMessages>()),
    warningMessages: types.maybe(types.frozen<FatigueWarning[]>()),
  })
  .views(self => ({
    get hasValidationMessages() {
      return !!self.validationMessages?.staffMembers?.length;
    },
    get hasWarningMessages() {
      return !!self.warningMessages?.length;
    },
  }))
  .actions(self => {
    function setValidationMessages(validationMessages?: FatigueValidationFailureMessages) {
      self.validationMessages = validationMessages;
    }
    function clearValidationMessages() {
      setValidationMessages(undefined);
    }
    function setWarningMessages(warningMessages?: FatigueWarning[]) {
      self.warningMessages = warningMessages;
    }
    function clearWarningMessages() {
      setWarningMessages(undefined);
    }
    return {
      setValidationMessages,
      clearValidationMessages,
      setWarningMessages,
      clearWarningMessages,
    };
  });

type IFatigueValidationsModelType = Instance<typeof FatigueValidationsModel>;
export interface IFatigueValidationsModel extends IFatigueValidationsModelType {}
