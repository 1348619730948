import { DateTime } from 'luxon';
import { Link } from 'react-router-dom';
import { JobTaskStatus } from 'src/api/enums';
import {
  CancelAssetServiceArgs,
  ScheduleServiceArgs,
} from 'src/domain/entities/workshop/asset/AssetModel';
import { AddAssetAttachmentData } from 'src/domain/entities/workshop/attachment/WorkshopAttachmentModel';
import {
  BanIcon,
  CalendarIcon,
  ClipboardIcon,
  EditIcon,
  FilterIcon,
  PlusIcon,
  Tasks,
} from 'src/images/icons';
import ActionBar from 'src/views/components/Page/actions/ActionBar';
import {
  DownloadAttachmentQuery,
  LoadAttachmentsQuery,
} from 'src/views/components/Attachment/attachmentHelper';
import { DateFormat } from 'src/views/components/DateFormat';
import { IntervalFormat } from 'src/views/components/IntervalFormat';
import { TaskCardItem, toActionLinkDef } from 'src/views/components/TaskCard';
import {
  ActionType,
  FieldType,
  PaneType,
  SectionDefs,
  ShellModalSize,
} from 'src/views/definitionBuilders/types';
import getAddAttachmentPanelDef from '../../attachment/getAddAttachmentPanelDef';
import { DeleteAttachmentFormData } from '../../attachment/getDeleteAttachmentModalDef';
import { UpdateAttachmentFormData } from '../../attachment/getUpdateAttachmentModalDef';
import getCancelServiceModalDef from '../serviceHistory/getCancelServiceModalDef';
import getScheduleServiceModalDef from '../serviceHistory/getScheduleServiceModalDef';
import { getCreateFuelModalDef } from './getCreateFuelModalDef';
import { getUpdateFuelModalDef } from './getUpdateFuelModalDef';
import { ENABLE_PARTS_REGISTER } from 'src/appSettings';

type WorkshopAttachmentDetailsDto = Workshop.Domain.Queries.Attachment.WorkshopAttachmentDetailsDto;
type DefectListItem = Workshop.Domain.Queries.Defect.DefectListItem;
type JobsForAsset = Workshop.Domain.Queries.Job.GetJobsForAsset.JobsForAsset;
type JobTaskListItem = Workshop.Domain.Queries.JobTask.JobTaskListItem;
type JobSummaryWithTasksDto = Workshop.Domain.Queries.Job.GetJobsForAsset.JobSummaryWithTasksDto;
type JobTaskItem = Workshop.Domain.Queries.Job.GetJobsForAsset.JobTaskItem;
type AssetNextServiceDueItem = Workshop.Domain.Queries.AssetServices.GetAssetNextServiceDue.AssetNextServiceDueItem;
type AssetGroupDetails = Workshop.Domain.Queries.AssetGroup.AssetGroupDetails;
type AssetServiceHistoryItem = Workshop.Domain.Queries.AssetServices.GetAssetServicePlan.AssetServiceHistoryItem;
type JobSummaryDto = Workshop.Domain.Queries.Job.JobSummaryDto;
type AssetFuel = Workshop.Domain.Queries.GetAssetFuel.AssetFuelItem;
type StaffMemberDto = Common.Dtos.StaffMemberDto;
type CreateAssetAdHocFuelCommand = Workshop.Domain.Commands.Asset.CreateAssetAdHocFuel.CreateAssetAdHocFuelCommand;
type UpdateAssetAdHocFuelCommand = Workshop.Domain.Commands.Asset.UpdateAssetAdHocFuel.UpdateAssetAdHocFuelCommand;
type AssetFuelLocation = Workshop.Domain.Queries.ListAssetFuelLocations.AssetFuelLocationItem;
type WorkshopDepot = Common.Queries.Workshop.GetWorkshopDepots.WorkshopDepotDto;
type AssetDetails = Workshop.Domain.Queries.GetAsset.AssetDetails;
var enablePartsRegister = ENABLE_PARTS_REGISTER;

export const getAssetSecondarySectionDefs = (
  defects: DefectListItem[],
  jobsItem: JobsForAsset | undefined,
  tasks: JobTaskListItem[],
  assetId: string,
  nextService: AssetNextServiceDueItem | undefined,
  serviceHistoryItems: AssetServiceHistoryItem[],
  canManageAssets: boolean,
  onScheduleService: (cmd: ScheduleServiceArgs) => Promise<void>,
  futureJobs: JobSummaryDto[],
  workshopDepots: WorkshopDepot[],
  defaultWorkshopDepot: WorkshopDepot | undefined,
  onCancelService: (cmd: CancelAssetServiceArgs) => Promise<void>,
  showCubicRegisterTab: boolean,
  asset: AssetDetails | undefined,
  assetFuels: AssetFuel[],
  fuelEntries: number,
  updateAdHocFuel: (cmd: UpdateAssetAdHocFuelCommand, includeData: boolean) => Promise<void>,
  includeTabletData: boolean,
  staffMembers: StaffMemberDto[],
  assetFuelLocations: AssetFuelLocation[],
  createAdHocFuel: (cmd: CreateAssetAdHocFuelCommand, includeData: boolean) => Promise<void>,
  loadAssetFuels: (
    assetId: string,
    includeTabletData: boolean,
    maxResults: number,
    includeDataFuel?: boolean | undefined
  ) => Promise<void>,
  setIncludeTabletData: (val: boolean) => void,
  addAssetAttachment: (data: AddAssetAttachmentData) => Promise<void>,
  loadAttachmentsForAsset: (query: LoadAttachmentsQuery) => Promise<void>,
  downloadAttachmentForAsset: (query: DownloadAttachmentQuery) => Promise<void>,
  deleteAttachmentFromAsset: (data: DeleteAttachmentFormData) => Promise<void>,
  updateAttachmentForAsset: (data: UpdateAttachmentFormData) => Promise<void>,
  attachments: WorkshopAttachmentDetailsDto[],
  assetGroup: AssetGroupDetails | undefined
): SectionDefs[] => {
  return [
    {
      title: 'Work',
      isLogicalSubsection: false,
      explicitData: { defects, jobs: jobsItem && jobsItem.jobs, tasks },
      panels: [
        {
          panes: [
            {
              paneType: PaneType.tabPane,
              renderStyle: 'as-required',
              tabs: [
                {
                  title: 'Tasks',
                  panes: [
                    {
                      paneType: PaneType.tablePane,
                      neverEditable: true,
                      dataAddr: 'tasks',
                      fields: [
                        {
                          fieldType: FieldType.textField,
                          dataAddr: 'jobTaskNumber',
                          label: 'Number',
                          linkTo: d => `/workshop/tasks/${d.parentValue.id}`,
                        },
                        {
                          fieldType: FieldType.textField,
                          dataAddr: 'description',
                          label: 'Description',
                        },
                        {
                          fieldType: FieldType.selectField,
                          dataAddr: 'status',
                          label: 'Status',
                          valueKey: 'id',
                          descriptionKey: 'description',
                        },
                        {
                          fieldType: FieldType.customField,
                          dataAddr: 'status',
                          label: 'Scheduled',
                          columnWidth: '8em',
                          render: api => {
                            return (
                              <IntervalFormat
                                startValue={api.data.parentValue.scheduledStartDateTime}
                                endValue={api.data.parentValue.scheduledEndDateTime}
                                includeYear
                                excludeTime
                              />
                            );
                          },
                        },
                      ],
                    },
                    {
                      paneType: PaneType.actionListPane,
                      actionGroups: [
                        {
                          actions: [
                            {
                              actionType: ActionType.actionLink,
                              label: 'List All Tasks For Asset',
                              icon: <Tasks />,
                              to: `/workshop/tasks?assets=${assetId}`,
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
                {
                  title: 'Jobs',
                  panes: [
                    {
                      paneType: PaneType.repeatingPane,
                      dataAddr: 'jobs',
                      itemPanes: [
                        {
                          paneType: PaneType.customPane,
                          dataAddr: 'jobId',
                          render: api => {
                            const job = api.data.parentValue as JobSummaryWithTasksDto;
                            return (
                              <div className="maintain-asset-jobs-pane">
                                <div className="job-number">
                                  <span>Job </span>
                                  <Link to={`/workshop/jobs/${job.jobId}`}>{job.jobNumber}</Link>
                                </div>
                                <div className="job-duration">
                                  <IntervalFormat
                                    startValue={job.startDateTime}
                                    endValue={job.endDateTime}
                                    includeYear
                                  />
                                </div>
                                <ul className="job-tasks list-unstyled">
                                  {job.jobTasks.map((jobTask: JobTaskItem) => {
                                    return (
                                      <li className="job-task" key={jobTask.jobTaskId}>
                                        <div className="task-number">
                                          <span>Task </span>
                                          <Link to={`/workshop/tasks/${jobTask.jobTaskId}`}>
                                            {jobTask.jobTaskNumber}
                                          </Link>
                                        </div>
                                        <div className="task-description">
                                          {jobTask.description}
                                        </div>
                                        <div className="task-status">
                                          {jobTask.status.description}
                                        </div>
                                        <div className="task-completion-details">
                                          {jobTask.status.id === JobTaskStatus.Completed
                                            ? jobTask.completionDetails
                                            : null}
                                        </div>
                                      </li>
                                    );
                                  })}
                                </ul>
                              </div>
                            );
                          },
                        },
                      ],
                    },
                    {
                      paneType: PaneType.actionListPane,
                      actionGroups: [
                        {
                          actions: [
                            {
                              actionType: ActionType.actionLink,
                              label: 'List All Jobs For Asset',
                              icon: <ClipboardIcon />,
                              to: `/workshop/jobs?assets=${assetId}`,
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
                {
                  title: 'Defects',
                  panes: [
                    {
                      paneType: PaneType.tablePane,
                      neverEditable: true,
                      dataAddr: 'defects',
                      fields: [
                        {
                          fieldType: FieldType.textField,
                          dataAddr: 'defectNumber',
                          label: 'Defect Number',
                          linkTo: d => `/workshop/defects/${d.parentValue.id}`,
                        },
                        {
                          fieldType: FieldType.textField,
                          dataAddr: 'description',
                          label: 'Details',
                        },
                        {
                          fieldType: FieldType.selectField,
                          dataAddr: 'status',
                          label: 'Status',
                          valueKey: 'id',
                          descriptionKey: 'description',
                        },
                      ],
                    },
                    {
                      paneType: PaneType.actionListPane,
                      actionGroups: [
                        {
                          actions: [
                            {
                              actionType: ActionType.actionLink,
                              label: 'List All Defects For Asset',
                              icon: <ClipboardIcon />,
                              to: `/workshop/defects?assets=${assetId}`,
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
    {
      title: 'Servicing',
      explicitData: {
        assetGroup: assetGroup && assetGroup.serviceIntervals,
        nextService: nextService,
        serviceHistoryItems: serviceHistoryItems,
      },
      panels: [
        {
          panes: [
            {
              paneType: PaneType.navListPane,
              links: [TaskCardItem.serviceSchedule(assetId)].map(toActionLinkDef),
            },
            {
              paneType: PaneType.tablePane,
              dataAddr: 'assetGroup',
              title: 'Service Plan',

              neverEditable: true,
              fields: [
                {
                  fieldType: FieldType.textField,
                  dataAddr: 'jobTaskSubcategoryDescription',
                  label: 'Type',
                },
                {
                  fieldType: FieldType.textField,
                  dataAddr: 'serviceIntervalDays',
                  label: 'Days',
                },
                {
                  fieldType: FieldType.textField,
                  dataAddr: 'serviceIntervalKms',
                  label: 'Kms',
                },
              ],
            },
            {
              paneType: PaneType.customPane,
              dataAddr: 'nextService',
              render: api => {
                const nextServiceItem = api.data.paneValue as AssetNextServiceDueItem;
                if (!nextServiceItem) {
                  return (
                    <div className="maintain-asset-next-service-grid">
                      <div className="title">Next Upcoming Service</div>
                      <div className="type">
                        <em>There are no scheduled services for this asset</em>
                      </div>
                    </div>
                  );
                }
                return (
                  <div className="maintain-asset-next-service-grid">
                    <div className="title">Next Upcoming Service</div>
                    <div className="type">
                      <strong>Type</strong>{' '}
                      {nextServiceItem.serviceType && nextServiceItem.serviceType.description}
                    </div>
                    {nextServiceItem.jobTaskNumber && (
                      <div className="actions">
                        <strong>Number </strong>
                        <Link to={`/workshop/tasks/${nextServiceItem.jobTaskId}`}>
                          {nextServiceItem.jobTaskNumber}
                        </Link>
                      </div>
                    )}
                    {!nextServiceItem.jobTaskNumber && (
                      <div className="actions">
                        <ActionBar
                          actionGroupDefs={[
                            {
                              actions: [
                                {
                                  hidden: () => !canManageAssets,
                                  actionType: ActionType.modalActionButton,
                                  label: 'Schedule',
                                  icon: <CalendarIcon size="sm" />,
                                  modalSize: ShellModalSize.oneThird,
                                  modalDef: getScheduleServiceModalDef(
                                    onScheduleService,
                                    futureJobs,
                                    nextServiceItem.id,
                                    undefined,
                                    undefined,
                                    workshopDepots,
                                    defaultWorkshopDepot
                                  ),
                                },
                                {
                                  hidden: () => !canManageAssets,
                                  actionType: ActionType.modalActionButton,
                                  label: 'Cancel Service',
                                  icon: <BanIcon />,
                                  modalSize: ShellModalSize.oneThird,
                                  modalDef: getCancelServiceModalDef(
                                    onCancelService,
                                    nextServiceItem.id
                                  ),
                                },
                              ],
                            },
                          ]}
                          actionMeta={{
                            formSubmitting: false,
                            hideLabel: true,
                            borderless: true,
                          }}
                          actionData={{ ...api.data, actionValue: undefined }}
                        />
                      </div>
                    )}

                    <div className="date-due">
                      <strong>Date Due </strong>
                      {DateTime.fromISO(nextServiceItem.dueDate).toLocaleString(DateTime.DATE_MED)}
                    </div>
                    <div className="days-due-in">
                      <strong>Due In </strong> {nextServiceItem.dueInDays} days
                    </div>
                    {nextServiceItem.dueKms && (
                      <div className="kms-due">
                        <strong>Kms Due </strong> {nextServiceItem.dueKms}
                      </div>
                    )}
                    {nextServiceItem.dueInKms && (
                      <div className="kms-due-in">
                        <strong>Due In </strong> {nextServiceItem.dueInKms} Kms
                      </div>
                    )}
                    {nextServiceItem.lastOdometer && (
                      <div className="current-kms">
                        <strong>Current </strong> {nextServiceItem.lastOdometer} Kms
                      </div>
                    )}
                  </div>
                );
              },
            },
            {
              paneType: PaneType.tablePane,
              dataAddr: 'serviceHistoryItems',
              title: 'Service History',
              neverEditable: true,
              fields: [
                {
                  fieldType: FieldType.textField,
                  dataAddr: ['serviceType', 'description'],
                  label: 'Type',
                },
                {
                  fieldType: FieldType.dateField,
                  dataAddr: 'doneDate',
                  label: 'Date',
                },
                {
                  fieldType: FieldType.textField,
                  dataAddr: 'dueKms',
                  label: 'Kms Due',
                },
                {
                  fieldType: FieldType.textField,
                  dataAddr: 'doneKms',
                  label: 'Kms Done',
                },
                {
                  fieldType: FieldType.textField,
                  readonly: true,
                  dataAddr: 'jobTaskNumber',
                  linkTo: d => `/workshop/tasks/${d.parentValue.jobTaskId}`,
                  label: 'Task',
                },
              ],
            },
          ],
        },
      ],
    },
    {
      title: 'Parts',
      panels: [
        {
          panes: [
            {
              paneType: PaneType.navListPane,
              links: [
                TaskCardItem.assetComponentsRegister(assetId),
                ...(enablePartsRegister ? [TaskCardItem.assetPartsRegister(assetId)] : []),
                TaskCardItem.assetUsedParts(assetId, DateTime.local().minus({ months: 1 })),
              ]
                .concat(
                  showCubicRegisterTab && asset?.isTranslinkVehicle
                    ? [TaskCardItem.assetCubicRegister(assetId)]
                    : []
                )
                .map(toActionLinkDef),
            },
          ],
        },
      ],
    },
    {
      title: 'Fuel',
      explicitData: {
        assetFuels: assetFuels,
      },
      panels: [
        {
          panes: [
            {
              paneType: PaneType.tablePane,
              dataAddr: 'assetFuels',
              title: `Last ${fuelEntries} Fuel Entries`,
              neverEditable: true,
              fields: [
                {
                  fieldType: FieldType.textField,
                  dataAddr: ['occurredAt'],
                  label: 'Date',
                  formatReadonly: d => <DateFormat value={d.fieldValue} short />,
                },
                {
                  fieldType: FieldType.textField,
                  dataAddr: ['odometerReading'],
                  label: 'Odo',
                },
                {
                  fieldType: FieldType.textField,
                  dataAddr: ['mixTelematicsOdometerReading'],
                  label: 'Mix Odo',
                },
                {
                  fieldType: FieldType.textField,
                  dataAddr: 'fuel',
                  label: 'Fuel',
                },
                {
                  fieldType: FieldType.textField,
                  dataAddr: 'adblue',
                  label: 'Ad blue',
                },
                {
                  fieldType: FieldType.textField,
                  dataAddr: 'staffMemberName',
                  label: 'Staff Member',
                },
                {
                  fieldType: FieldType.textField,
                  dataAddr: ['assetFuelLocation', 'description'],
                  label: 'Location',
                  formatReadonly: d => (
                    <div>
                      {d.fieldValue}
                      {!d.parentValue.isDataFuel ? (
                        <span className={'circledT'} title="Tablet Record">
                          {' '}
                        </span>
                      ) : (
                        ''
                      )}
                    </div>
                  ),
                },
                {
                  fieldType: FieldType.actionListField,
                  actionGroups: [
                    {
                      actions: [
                        {
                          actionType: ActionType.modalActionButton,
                          icon: <EditIcon />,
                          modalSize: ShellModalSize.oneQuarter,
                          modalDef: api =>
                            getUpdateFuelModalDef(
                              api.actionData.parentValue.id,
                              command => updateAdHocFuel(command, includeTabletData),
                              assetId
                            )(api),
                          label: 'Edit Fuel Entry',
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
      secondaryActions: [
        {
          actions: [
            {
              actionType: ActionType.modalActionButton,
              label: 'Add Fuel',
              icon: <PlusIcon />,
              level: 'primary',
              modalSize: ShellModalSize.oneThird,
              modalDef: getCreateFuelModalDef(
                staffMembers,
                assetFuelLocations,
                command => createAdHocFuel(command, includeTabletData),
                assetId
              ),
            },
            {
              actionType: ActionType.actionButton,
              className: 'variable-size',
              label: `${includeTabletData ? 'Hide' : 'Show'} Depot Fuel From Tablet`,
              icon: <FilterIcon />,
              onClick: () => {
                loadAssetFuels(assetId, !includeTabletData, fuelEntries, true);
                setIncludeTabletData(!includeTabletData);
              },
              level: 'primary',
            },
          ],
        },
      ],
    },
    getAddAttachmentPanelDef(
      (asset && asset.id) || '',
      'asset',
      addAssetAttachment,
      loadAttachmentsForAsset,
      downloadAttachmentForAsset,
      deleteAttachmentFromAsset,
      updateAttachmentForAsset,
      attachments
    ),
  ];
};
