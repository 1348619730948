import { types, flow, getRoot } from 'mobx-state-tree';
import { DateTime, Interval } from 'luxon';
import { getAjax } from 'src/domain/services';
import { IRootStoreModel } from 'src/domain/entities/RootStoreModel';

type ScheduledJob = Workshop.Domain.Queries.Job.GetScheduledJobs.ScheduledJob;

export interface IScheduledJob extends ScheduledJob {
  jobInterval: Interval;
}

export const ScheduledJobsModel = types
  .model('ScheduledJobsModel', {
    jobsByWeek: types.map(types.array(types.frozen<IScheduledJob>())),
    loadingJobsByWeek: types.map(types.boolean),
  })
  .views(self => ({
    get jobs() {
      // Flatten the jobsByWeek into a map, as jobs may appear in multiple weeks (when the cross the week boundary)
      // Using Map removes any duplicates
      const flattenedJobs = Array.from(self.jobsByWeek.values()).reduce(
        (m, js) => js.reduce((m2, j) => m.set(j.jobId, j), m),
        new Map<string, IScheduledJob>()
      );
      return Array.from(flattenedJobs.values());
    },
    get jobsLoading() {
      return !!Array.from(self.loadingJobsByWeek.keys()).length;
    },
    jobsForWeekByDayLoading(weekYear: number, weekNumber: number) {
      const d = DateTime.fromObject({ weekYear, weekNumber, weekday: 1 });
      return !!self.loadingJobsByWeek.get(d.toISOWeekDate());
    },
  }))
  .actions(self => {
    const ajax = getAjax(self);
    const root = getRoot(self) as IRootStoreModel;

    const loadJobsForWeek = flow(function*(start: DateTime, depotId: number) {
      self.loadingJobsByWeek.set(start.toISOWeekDate(), true);

      const end = start.plus({ days: 7 });
      const domainWeekJobs: ScheduledJob[] = yield ajax.job.listScheduledJobs(start, end, depotId);
      const weekJobs = domainWeekJobs.map(
        j =>
          ({
            ...j,
            jobInterval: Interval.fromDateTimes(
              DateTime.fromISO(j.startDateTime),
              DateTime.fromISO(j.endDateTime)
            ),
          } as IScheduledJob)
      );

      // @ts-ignore - typing for the set function is not quite correct because we have a map of arrays
      self.jobsByWeek.set(start.toISOWeekDate(), weekJobs);
      self.loadingJobsByWeek.delete(start.toISOWeekDate());
    });

    const loadJobs = (weekYear: number, weekNumber: number, zone: string, depotId: number) => {
      const start = DateTime.fromObject({ weekYear, weekNumber, weekday: 1, zone });
      loadJobsForWeek(start, depotId);
    };

    const changeSelectedWorkshopDepotId = (
      selectedWorkshopDepotId: number,
      zonedWeekToDisplay: DateTime
    ) => {
      root.history.push(
        `/workshop/jobs/weekly-schedule?week=${zonedWeekToDisplay
          .set({ weekday: 1 })
          .toISOWeekDate()}&depotId=${selectedWorkshopDepotId}`
      );
    };

    return { loadJobs, changeSelectedWorkshopDepotId };
  });

type IModelType = typeof ScheduledJobsModel.Type;
export interface IScheduledJobsModel extends IModelType {}
