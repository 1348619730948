import styles from './CreateDefectForm.module.scss';
import Select from 'react-select';
import FormGroup from 'reactstrap/lib/FormGroup';
import Label from 'reactstrap/lib/Label';
import FormFeedback from 'reactstrap/lib/FormFeedback';
import Textarea from '../../presentational/Form/Textarea/Textarea';
import Button from 'reactstrap/lib/Button';
import { Component } from 'react';
import { DefectRaisedBy } from 'src/api/enums';

type AssetItem = Workshop.Domain.Queries.AssetItem;

export interface ICreateDefectFormProps {
  createDefect: (
    command: Workshop.Domain.Commands.Defect.CreateDefectFromDriver.CreateDefectFromDriverCommand
  ) => Promise<void>;
  assets: AssetItem[];
  loadAssets: () => Promise<void>;
  loadRelatedDefects: (assetId?: string) => Promise<void>;
  isWorkshopKiosk: boolean | null;
}

interface ICreateDefectFormState {
  asset?: AssetItem;
  description: string;
  // tslint:disable-next-line:no-any
  formErrors: any;
  submitting: boolean;
}

class CreateDefectForm extends Component<ICreateDefectFormProps, ICreateDefectFormState> {
  constructor(props: ICreateDefectFormProps) {
    super(props);
    this.state = {
      asset: undefined,
      description: '',
      formErrors: {},
      submitting: false,
    };
  }

  componentDidMount() {
    this.props.loadAssets();
    this.props.loadRelatedDefects(undefined);
  }

  validate() {
    const { asset, description } = this.state;
    return {
      asset: !asset || !asset.id ? 'Bus Number is required' : undefined,
      description: !description ? 'Description is required' : undefined,
    };
  }

  validateForm(updateFields?: string[]) {
    let errors = this.validate();
    if (updateFields && updateFields.length > 0) {
      // poor man's change tracking
      Object.keys(errors).forEach(key => {
        if (updateFields.indexOf(key) === -1) {
          errors[key] = undefined;
        }
      });
    }

    this.setState({ formErrors: errors });
    const msgs = Object.keys(errors)
      .map(key => {
        return errors[key];
      })
      .filter(val => !!val);
    return msgs.length === 0;
  }

  async handleSubmit(event: React.FormEvent<EventTarget>) {
    event.preventDefault();
    if (!this.validateForm()) {
      return;
    }

    const { asset, description } = this.state;
    this.setState({ submitting: true });
    var isWorkshopKiosk = this.props.isWorkshopKiosk;

    await this.props.createDefect({
      assetId: asset!.id,
      description: description,
      raisedBy: isWorkshopKiosk ? DefectRaisedBy.Workshop : DefectRaisedBy.Driver,
    });
    await this.props.loadRelatedDefects(asset!.id);
    this.setState({ description: '', submitting: false });
  }

  updateState(values: object) {
    this.setState(values, () => {
      this.validateForm(Object.keys(values));
    });
  }

  render() {
    return (
      <form
        className={styles.createDefectFormComponent}
        autoComplete="off"
        onSubmit={e => this.handleSubmit(e)}>
        <div className={styles.fields}>
          <div className={styles.asset}>
            <FormGroup>
              <Label for="asset">Bus Number*</Label>
              <Select
                clearable
                options={this.props.assets}
                valueKey="id"
                labelKey="name"
                value={this.state.asset}
                onChange={s => {
                  const type = s as AssetItem;
                  this.setState({ asset: type });
                  this.props.loadRelatedDefects(type && type.id);
                }}
                matchProp="label"
              />
              <FormFeedback>{this.state.formErrors.asset}</FormFeedback>
            </FormGroup>
          </div>

          <div className={styles.description}>
            <Textarea
              label="Description*"
              dataAddr="description"
              onChange={value => this.updateState({ description: value })}
              value={this.state.description}
              errors={this.state.formErrors.description}
            />
          </div>
        </div>

        <div className={styles.submit}>
          <Button type="submit" size="lg" disabled={this.state.submitting}>
            Submit
          </Button>
        </div>
      </form>
    );
  }
}
export default CreateDefectForm;
