import { AjaxResponse } from 'rxjs';
import { IFile } from '../Page/fields/MultiFilePageField';

export type AggregatesWithAttachments =
  | 'task'
  | 'asset'
  | 'shift'
  | 'job'
  | 'bulkdaoperation'
  | 'supplier'
  | 'staffmember'
  | 'railTemplate';

export type SwapAttachmentOrderData = {
  aggregateId: string;
  aggregateType: string;
  firstAttachmentDetailsId: number;
  secondAttachmentDetailsId: number;
};

export type DownloadAttachmentQuery = {
  attachmentDetailsId: number;
  aggregateId: string;
  aggregateType: AggregatesWithAttachments;
};

export type LoadAttachmentsQuery = {
  aggregateId: string;
  aggregateType: AggregatesWithAttachments;
};

export const AggregateTypeToUrlSegment: { [key in AggregatesWithAttachments]: string } = {
  task: 'job-tasks',
  asset: 'assets',
  shift: 'shifts',
  job: 'jobs',
  bulkdaoperation: 'bulk-driver-authorization',
  supplier: 'suppliers',
  staffmember: 'staff-members',
  railTemplate: 'rail-templates',
};

export const maxFileSizeInMegaBytes = 20;

export const isValidAttachment = (file: IFile) => {
  if (!file.file) {
    return false;
  }

  if (file.file.size > maxFileSizeInMegaBytes * 1024 * 1024) {
    return false;
  }

  const validAttachmentTypes = ['application/pdf'];
  return validAttachmentTypes.includes(file.file.type);
};

export const getFileNameFromResponse = (ajaxResponse: AjaxResponse): string | undefined => {
  const contentDispositionHeader = ajaxResponse.xhr.getResponseHeader('content-disposition');
  if (contentDispositionHeader) {
    const fileName = contentDispositionHeader
      .split(';')
      .filter(s => s.includes('filename*='))[0]
      .split("UTF-8''")[1]
      .trim();
    return decodeURIComponent(fileName.replace(/"/g, ''));
  }
  return undefined;
};
