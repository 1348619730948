import {
  ActionType,
  PaneType,
  SectionDefs,
  ShellModalSize,
} from 'src/views/definitionBuilders/types';
import { DownloadIcon, PlusIcon } from 'src/images/icons';
import getAddAttachmentModalDef from 'src/views/routes/people/attachment/getAddAttachmentModalDef';
import { AttachmentListItem } from 'src/views/routes/people/attachment/AttachmentListItem';
import { UpdateAttachmentFormData } from 'src/views/routes/people/attachment/getUpdateAttachmentModalDef';
import { AddAttachmentData } from 'src/domain/entities/people/attachment/PeopleAttachmentModel';
import {
  AggregatesWithAttachments,
  DownloadAttachmentQuery,
  LoadAttachmentsQuery,
  SwapAttachmentOrderData,
} from 'src/views/components/Attachment/attachmentHelper';
import { DeleteAttachmentFormData } from './getDeleteAttachmentModalDef';

type AttachmentDto = People.Domain.Queries.Attachment.ListStaffMemberAttachments.StaffMemberAttachmentDetailsDto;

export default function getAddAttachmentPanelDef(
  aggregateId: string,
  aggregateType: AggregatesWithAttachments,
  addAttachment: (data: AddAttachmentData) => Promise<void>,
  loadAttachments: (aggregateId: LoadAttachmentsQuery) => Promise<void>,
  downloadAttachment: (query: DownloadAttachmentQuery) => Promise<void>,
  deleteAttachment: (command: DeleteAttachmentFormData) => Promise<void>,
  updateAttachment: (command: UpdateAttachmentFormData) => Promise<void>,
  primarySectionEditing: boolean,
  attachments: Array<AttachmentDto>,
  swapAttachments?: (data: SwapAttachmentOrderData) => Promise<void>
): SectionDefs {
  return {
    title: 'Attachments',
    explicitData: { attachments: attachments },
    panels: [
      {
        panes: [
          {
            paneType: PaneType.repeatingPane,
            noItemsMessage: `There are no attachments for this staff member.`,
            dataAddr: 'attachments',
            itemPanes: [
              {
                paneType: PaneType.customPane,
                render: api => {
                  const attachmentDto = api.data.parentValue as AttachmentDto;
                  return (
                    <AttachmentListItem
                      attachmentDto={attachmentDto}
                      deleteAttachment={deleteAttachment}
                      aggregateType={aggregateType}
                      updateAttachment={updateAttachment}
                      loadAttachments={loadAttachments}
                      aggregateId={aggregateId}
                      attachments={api.data.sectionValue.attachments}
                      swapAttachments={swapAttachments}
                    />
                  );
                },
              },
              {
                paneType: PaneType.actionListPane,
                actionGroups: [
                  {
                    actions: [
                      {
                        actionType: ActionType.actionButton,
                        label: `Download`,
                        icon: <DownloadIcon />,
                        onClick: api => {
                          return downloadAttachment({
                            attachmentDetailsId: api.parentValue.id,
                            aggregateId: aggregateId,
                            aggregateType: aggregateType,
                          });
                        },
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
    secondaryActions: [
      {
        actions: [
          {
            hidden: () => primarySectionEditing,
            actionType: ActionType.modalActionButton,
            label: 'Add Attachment',
            icon: <PlusIcon />,
            modalSize: ShellModalSize.oneThird,
            modalDef: getAddAttachmentModalDef(
              aggregateId || '',
              aggregateType,
              addAttachment,
              loadAttachments
            ),
          },
        ],
      },
    ],
  };
}
