import { PaneType, FieldType, ActionType, PaneDefs } from 'src/views/definitionBuilders/types';
import { DateTime } from 'luxon';
import RepeatGenerationPane from 'src/views/components/RepeatGenerationPane';
import { PartialRepeat } from 'src/views/routes/operations/sales/quote/maintainQuote/utils/repeatDatesMaths';
import { Link } from 'react-router-dom';
import { IFormApi } from 'src/views/components/Page/forms/base';
import { formatDuration } from 'src/infrastructure/formattingUtils';

type LeaveItem = People.Domain.Queries.ViewLeave.LeaveItem;
type Repeat = People.Domain.Queries.ViewLeave.LeaveItem.Repeat;

const formatLeaveDuration = (
  startString: string | undefined,
  endString: string | undefined
): string => {
  const start = startString ? DateTime.fromISO(startString) : undefined;
  const end = endString ? DateTime.fromISO(endString) : undefined;
  if (start && start.isValid && end && end.isValid) {
    const duration = end.diff(start);
    return ' (' + formatDuration(duration) + ')';
  }
  return '';
};

export const recurPanelPane = (
  isUpdateMode: boolean,
  updating: boolean,
  isCreateMode: boolean,
  formApi: IFormApi,
  timezone: string,
  originalRepeats: Repeat[] | undefined
): PaneDefs[] => [
  {
    paneType: PaneType.customPane,
    hidden: d => (isUpdateMode && !updating) || d.panelValue.sourceLeaveId,
    render: api => {
      const leaveItem = api.data.sectionValue as LeaveItem;
      const startString = leaveItem && leaveItem.start;
      const endString = leaveItem && leaveItem.end;
      const firstTripStart = startString
        ? DateTime.fromISO(startString, { zone: timezone })
        : undefined;
      const firstTripEnd = endString ? DateTime.fromISO(endString, { zone: timezone }) : undefined;

      return (
        <RepeatGenerationPane
          paneData={api.data}
          readonly={api.meta.readonly}
          periodStart={firstTripStart}
          periodEnd={firstTripEnd}
          onDatesGenerated={dates => {
            formApi.setValue('repeats', dates);
            formApi.setValue('recurPanelChanged', false); // Reset flag as recurrences are now up-to-date
          }}
          disableWeekDayPicker
          useTimezoneAwareCalculations
          timezone={timezone}
          isRecurPanelChanged={isChanged => {
            formApi.setValue('recurPanelChanged', isChanged);
          }}
          onReset={() => {
            formApi.setValue('repeats', originalRepeats);
            formApi.setValue('recurPanelChanged', false);
          }}
        />
      );
    },
  },
  {
    paneType: PaneType.customPane,
    hidden: d => !!d.panelValue.sourceLeaveId === false,
    render: d => (
      <p>
        This is a recurrance. To change or delete all recurrences, please go to the original leave.
      </p>
    ),
  },
  {
    paneType: PaneType.formFieldsPane,
    hidden: d => !!d.panelValue.sourceLeaveId === false,
    fields: [
      {
        fieldType: FieldType.readonlyField,
        label: '',
        linkTo: d => `/people/leaves/${d.panelValue.sourceLeaveId}`,
        formatReadonly: d => <span>View Original Leave</span>,
      },
    ],
  },
  {
    paneType: PaneType.nestingPane,
    dataAddr: 'repeats',
    hidden: d => !!d.panelValue.sourceLeaveId === true && !(d.paneValue && d.paneValue.length),
    panes: [
      {
        paneType: PaneType.customPane,
        hidden: d => isCreateMode || (d.paneValue && d.paneValue.length),
        render: api => (
          <strong style={{ display: 'inline-block', marginBottom: '1em', marginTop: '1em' }}>
            Leave will not recur
          </strong>
        ),
      },
      {
        paneType: PaneType.customPane,
        hidden: d => !d.paneValue || !d.paneValue.length,
        render: () => (
          <strong style={{ display: 'inline-block', marginBottom: '1em' }}>Recurring On</strong>
        ),
      },
      {
        paneType: PaneType.repeatingPane,
        // dataRequiredForRows: 'paneValue',
        hidden: d => !d.paneValue || !d.paneValue.length,
        useHover: false,
        useDarkStyle: false,
        itemPanes: [
          {
            paneType: PaneType.formFieldsPane,
            fields: [
              {
                fieldType: FieldType.dateField,
                dataAddr: 'start',
                mandatory: true,
                timezone: timezone,
                label: d =>
                  !!(
                    (d.parentValue as PartialRepeat).id ||
                    (d.parentValue as PartialRepeat).generated
                  )
                    ? ''
                    : 'Recurring On',
                readonly: d =>
                  !!(
                    (d.parentValue as PartialRepeat).id ||
                    (d.parentValue as PartialRepeat).generated
                  ),
                formatReadonly: d => {
                  const repeat: Repeat = d.parentValue;
                  return d.fieldValue ? (
                    <div>
                      <div>
                        {repeat.linkedLeaveId ? (
                          <div>
                            <Link to={`/people/leaves/${repeat.linkedLeaveId}`}>
                              {DateTime.fromISO(d.fieldValue, { zone: timezone }).toLocaleString(
                                DateTime.DATE_HUGE
                              )}
                              {formatLeaveDuration(d.panelValue.start, d.panelValue.end)}
                            </Link>
                          </div>
                        ) : (
                          <div>
                            {DateTime.fromISO(d.fieldValue, { zone: timezone }).toLocaleString(
                              DateTime.DATE_HUGE
                            )}
                            {formatLeaveDuration(
                              (d.parentValue as PartialRepeat).start,
                              (d.parentValue as PartialRepeat).end
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  ) : null;
                },
              },
            ],
          },
          {
            paneType: PaneType.actionListPane,
            // columnWidth: '1px',
            hidden: fd => isUpdateMode && !updating,
            actionGroups: [
              {
                actions: [
                  {
                    actionType: ActionType.removeArrayItemActionButton,
                    label: 'Remove Date',
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
];
