import { formatTime } from 'src/views/kioskRoutes/operationsKiosk/home/PrintDailySummary/DailySummaryHelpers';
import {
  isJobTypeCharter,
  isJobTypeLinkedToQuote,
} from 'src/views/routes/operations/shared/jobTypeHelpers';
import styles from './JobsList.module.scss';
import { JobFieldFillOut } from 'src/views/kioskRoutes/operationsKiosk/home/PrintDailySummary/components/JobFieldFillOut';
import { JobFieldLabelTableCell } from 'src/views/kioskRoutes/operationsKiosk/home/PrintDailySummary/components/JobFieldLabelTableCell';
import EmptyJobsTable from 'src/views/kioskRoutes/operationsKiosk/home/PrintDailySummary/components/DailySummaryEmptyJobsTable';
import { TIMEZONE } from 'src/appSettings';

type DriverJob = Operations.Domain.Queries.ListDailySummarySheetDetails.DailySummarySheetJobDetailsDto;

export const JobsList: React.FC<{
  jobs: DriverJob[];
}> = ({ jobs }) => (
  <div className={styles.root}>
    {jobs.map((job: DriverJob) => (
      <div className={styles.job} key={job.jobId}>
        <table>
          <tbody>
            {job.trainerName ? (
              <tr>
                <td className={styles.trainingDescription} colSpan={6}>
                  <strong>{job.trainerName}</strong> is training you on this job
                </td>
              </tr>
            ) : null}
            {job.traineeNames && job.traineeNames.length ? (
              <tr>
                <td className={styles.trainingDescription} colSpan={6}>
                  <span>
                    You have {job.traineeNames.length}{' '}
                    {job.traineeNames.length === 1 ? 'trainee' : 'trainees'} on this job:{' '}
                  </span>
                  <strong>{job.traineeNames.join(', ')}</strong>
                </td>
              </tr>
            ) : null}
            <tr>
              {job.isContinuingFrom ? (
                <>
                  <JobFieldLabelTableCell label="Start" />
                  <td colSpan={3}>
                    {formatTime(job.continuationJobStart, job.firstRouteTimeZone)}
                    &emsp;(continuing from previous job)
                  </td>
                </>
              ) : job.isVehicleSwappedFrom ? (
                <>
                  <JobFieldLabelTableCell label="Start" />
                  <td colSpan={3}>
                    {formatTime(job.vehicleSwapStart, job.firstRouteTimeZone)}
                    &emsp;(vehicle swapped from previous job)
                  </td>
                </>
              ) : (
                <>
                  <JobFieldLabelTableCell label="Clock On" />
                  <td>{formatTime(job.clockOn, TIMEZONE) ?? null}</td>
                  <JobFieldLabelTableCell label="Depart Depot" />
                  <td>{formatTime(job.departDepot, TIMEZONE)}</td>
                </>
              )}
              <JobFieldLabelTableCell label="Job Type" />
              <td>{job.jobType.description}</td>
            </tr>
            <tr>
              {isJobTypeLinkedToQuote(job.jobType.id) && job.charterCustomerName ? (
                <>
                  <JobFieldLabelTableCell label="Customer" />
                  <td colSpan={3}>{job.charterCustomerName}</td>
                </>
              ) : (
                <>
                  <JobFieldLabelTableCell label="Shift Name" />
                  <td>{job.shiftName}</td>
                  <JobFieldLabelTableCell label="Route" />
                  <td>{job.description}</td>
                </>
              )}

              <JobFieldLabelTableCell label="Veh Num" />
              <td>{job.assetName}</td>
            </tr>
            <tr>
              <JobFieldFillOut label="Odom Start" />
              <JobFieldFillOut label="Odom Finish" />
              <JobFieldFillOut label="Total Kms" />
            </tr>
            <tr>
              <JobFieldFillOut label="Start Time" />
              <JobFieldFillOut label="Finish Time" />
              <JobFieldFillOut label="Total Time" />
            </tr>
            <tr>
              <JobFieldFillOut label="Fuel" />
              <JobFieldFillOut label="Adblue" />
              <JobFieldFillOut label="Total Breaks" />
            </tr>
            {!job.notes || isJobTypeCharter(job.jobType.id) ? null : (
              <tr>
                <JobFieldLabelTableCell label="Notes" />
                <td colSpan={5}>{job.notes}</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    ))}

    {jobs.length === 0 ? <EmptyJobsTable /> : null}
  </div>
);
