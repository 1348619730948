import inject from 'src/views/injectFromStore';
import MaintainJob, { IMaintainJobProps } from './MaintainJob';
import Omit from 'src/infrastructure/omit';

const Container = inject<Omit<IMaintainJobProps, 'mode'>, IMaintainJobProps>((allStores, p) => ({
  canManageJobs: allStores.rootStore.account.isWorkshopDepartmentMember,
  job: allStores.rootStore.job.job,
  assets: allStores.rootStore.assets.assetListItems.slice(),
  jobTaskCategories: allStores.rootStore.jobTask.jobTaskCategories.slice(),
  jobToPrint: allStores.rootStore.job.printJobItem,
  loadJobTaskCategories: allStores.rootStore.jobTask.loadJobTaskCategories,
  nextService: allStores.rootStore.asset.nextService,
  futureTasks: allStores.rootStore.asset.futureTasks.slice(),
  scheduledTasks: allStores.rootStore.asset.scheduledTasks.slice(),
  loadAssetListItems: allStores.rootStore.assets.loadAssetListItems,
  loadJob: allStores.rootStore.job.loadJob,
  createJob: allStores.rootStore.job.createJob,
  updateJob: allStores.rootStore.job.updateJob,
  loadPrintJobSheet: allStores.rootStore.job.loadPrintJobSheet,
  getNextService: allStores.rootStore.asset.getNextService,
  getFutureTasks: allStores.rootStore.asset.getFutureTasks,
  getScheduledTasks: allStores.rootStore.asset.getScheduledTasks,
  generateJobWorkOrderReport: allStores.rootStore.job.jobPdf.generateJobWorkOrderReport,
  onMarkAsInvoiced: allStores.rootStore.job.markAsInvoiced,
  getOwnerForAsset: allStores.rootStore.assets.getOwner,
  clearFutureTasks: allStores.rootStore.asset.clearFutureTasks,
  clearNextService: allStores.rootStore.asset.clearNextService,
  clearScheduledTasks: allStores.rootStore.asset.clearScheduledTasks,
  loadStaffMembers: allStores.rootStore.people.staffMembers.loadAllStaffMembers,
  staffMembers: allStores.rootStore.people.staffMembers.allStaffMembers.slice(),
  busUsages: allStores.rootStore.job.busUsages.slice(),
  loadBusUsages: allStores.rootStore.job.loadBusUsages,
  workshopDepots: allStores.rootStore.workshopStartup.workshopDepots.slice(),
  defaultWorkshopDepot: allStores.rootStore.workshopStartup.defaultWorkshopDepot,
}))(MaintainJob);

export default Container;
