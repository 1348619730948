import './FatigueDetailsRuleCard.scss';
import { fatigueRuleTypeDescription } from 'src/api/enums';
import { Card, CardTitle, CardBody, CardHeader, CardFooter, Collapse } from 'reactstrap';
import { BedIcon, CalendarIcon, ChevronDownIcon, ChevronUpIcon } from 'src/images/icons';
import { DateTime } from 'luxon';
import RuleWarningIcon from './RuleWarning';
import { useState } from 'react';
import FatigueDetailBreakDown from './FatigueDetailBreakDown';

type FatigueRuleReport = Operations.Domain.Queries.GetFatigueDetails.GetFatigueDetailsQueryResponse.FatigueRuleReport;
interface IFatigueDetailsRuleCardProps {
  ruleDetail: FatigueRuleReport;
}

export const fatigueDateTimeFormat = 'd LLL, H:mm';
const FatigueDetailsRuleCard: React.FC<IFatigueDetailsRuleCardProps> = (
  props: IFatigueDetailsRuleCardProps
) => {
  const { ruleDetail } = props;
  const hasNightRests = !!ruleDetail.items.find(x => x.label.toLowerCase().includes('night rest'));
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpanded = () => {
    setIsExpanded(!isExpanded);
  };
  return (
    <Card className="fatigue-details-rule-card bg-light mb-3 sm-4 border border-light rounded">
      <CardHeader>
        <CardTitle className="fatigue-details-rule-title">
          {hasNightRests ? <BedIcon color="brown" /> : <CalendarIcon color="skyblue" />}
          <span> {fatigueRuleTypeDescription(ruleDetail.fatigueRuleType)}</span>
        </CardTitle>
      </CardHeader>
      <CardBody>
        <table className="fatigue-details-rule-table table table-bordered">
          <thead>
            <tr>
              <th></th>
              <th>Required</th>
              <th>Actual</th>
            </tr>
          </thead>
          <tbody className={ruleDetail.ignored ? 'ignored' : ''}>
            {ruleDetail.items.map((item, i) => (
              <tr key={i}>
                <td>
                  {item.label} <RuleWarningIcon ruleReport={item} />
                </td>
                <td>{item.required}</td>
                <td>{item.actual}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </CardBody>
      <CardFooter className="text-muted">
        <div className="toggle-details">
          <div onClick={() => toggleExpanded()}>
            <span className="icon">{isExpanded ? <ChevronUpIcon /> : <ChevronDownIcon />}</span>{' '}
            Details
          </div>
        </div>
        {ruleDetail.ignored && <small>{ruleDetail.ignoredMessage}</small>}
        {!ruleDetail.ignored && (
          <small>
            {DateTime.fromISO(ruleDetail.start).toFormat(fatigueDateTimeFormat)} -{' '}
            {DateTime.fromISO(ruleDetail.end).toFormat(fatigueDateTimeFormat)}
          </small>
        )}
      </CardFooter>
      <Collapse isOpen={isExpanded}>
        <CardBody>
          <FatigueDetailBreakDown breakdown={ruleDetail.detailedBreakDowns} />
        </CardBody>
      </Collapse>
    </Card>
  );
};

export default FatigueDetailsRuleCard;
