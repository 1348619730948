import { FormattedDateTimeWithoutYear } from '../../../components/presentational/FormattedDateTimeWithoutYear/FormattedDateTimeWithoutYear';
import styles from '../AssetDetails.module.scss';
import { FormattedDateTime } from '../../../components/presentational/FormattedDateTime/FormattedDateTime';
import Spinner from 'src/views/components/Spinner';
import { useEffect, useState } from 'react';

type CompletedJobForAsset = Workshop.Domain.Queries.GetCompletedJobsForAssetForKiosk.CompletedJobForAssetForKioskItem;

export interface IAssetCompletedJobsDisplayProps {
  assetId: string;
  jobsForAsset?: CompletedJobForAsset[];
  loadCompletedJobs: (assetId: string) => Promise<void>;
}

export const AssetCompletedJobsDisplay: React.FC<IAssetCompletedJobsDisplayProps> = ({
  assetId,
  jobsForAsset,
  loadCompletedJobs,
}) => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    loadCompletedJobs(assetId).then(() => setIsLoading(false));
  }, [assetId]);

  return (
    <Spinner show={isLoading} className={styles.scrollableTabbedArea}>
      <div className={styles.jobs}>
        {isLoading ? (
          <div />
        ) : (
          <>
            {jobsForAsset && jobsForAsset.length > 0 ? (
              jobsForAsset.map((job, idx) => (
                <div key={idx} className={styles.jobItem}>
                  <div className={styles.jobDetails}>
                    <div className={styles.titles}>Job {job.jobNumber}</div>
                    <div className={styles.dates}>
                      <FormattedDateTimeWithoutYear dateTime={job.start} />
                      <span className={styles.separator}> - </span>
                      <FormattedDateTime dateTime={job.end} referenceDateTime={job.start} />
                    </div>
                  </div>

                  <div className={styles.tasks}>
                    {job.tasks.map((t, idx2) => (
                      <div key={idx2} className={styles.task}>
                        <div>
                          <div className={styles.titles}>Task {t.number}</div>
                          <div>{t.description}</div>
                        </div>
                        <div>{t.completionDetails}</div>
                      </div>
                    ))}
                  </div>
                </div>
              ))
            ) : (
              <span>No completed jobs were found for this asset.</span>
            )}
          </>
        )}
      </div>
    </Spinner>
  );
};
