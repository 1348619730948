import { types, flow, getRoot } from 'mobx-state-tree';
import { getAjax } from 'src/domain/services/storeEnvironment';
import { IKioskRootStoreModel } from '../../KioskRootStoreModel';
import { NotificationType } from 'src/domain';

type JobDetailsForKioskDto = Workshop.Domain.Queries.Job.GetJobDetailsForKiosk.JobDetailsForKioskDto;
type ExtendJobCommand = Workshop.Domain.Commands.Job.ExtendJobCommand;
type JobItem = Workshop.Domain.Queries.Job.PrintJobQuery.JobItem;

export const WorkshopJobItemModel = types
  .model('WorkshopJobItemModel', {
    job: types.maybe(types.frozen<JobDetailsForKioskDto>()),
    printJobItem: types.maybe(types.frozen<JobItem>()),
  })
  .actions(self => {
    const ajax = getAjax(self);
    const root = getRoot(self) as IKioskRootStoreModel;

    const loadJob = flow(function*(jobId: string) {
      self.job = yield ajax.kiosk.workshop.job.loadJob(jobId);
    });

    const loadPrintJobSheet = flow(function*(jobId: string) {
      self.printJobItem = undefined;
      self.printJobItem = yield ajax.kiosk.workshop.job.loadPrintJobSheet(jobId);
    });

    const scheduleTaskForJob = flow(function*(
      command: Workshop.Domain.Commands.JobTask.ScheduleFutureJobTaskCommand
    ) {
      yield ajax.raw.post(`/api/kiosk/job-tasks/schedule-future-task`, command).toPromise();
      if (command.jobId) {
        yield loadJob(command.jobId);
      }
    });

    const extendJob = flow(function*(command: ExtendJobCommand) {
      yield ajax.raw
        .post(`/api/kiosk/job/${command.jobId}`, command)
        .toPromise()
        .then<string>(r => r.response);
      root.notifications.addNotification('Job successfully extended', {
        type: NotificationType.info,
      });
      yield loadJob(command.jobId);
    });

    return {
      loadJob,
      loadPrintJobSheet,
      scheduleTaskForJob,
      extendJob,
    };
  });
