import styles from './WeeklySchedule.module.scss';
import cn from 'classnames';

import { DateTime } from 'luxon';
import { Link } from 'react-router-dom';
import { useState } from 'react';
import { Table } from 'reactstrap';
import ScheduleEvent, { IHolidayEvent, IScheduleEvent } from './ScheduleEvent';
import EventDetailsPopover from './EventDetailsPopover';
import { HolidayType } from 'src/api/enums';

interface IWeeklySchedule {
  now: DateTime;
  weekToDisplay: DateTime;
  timezone: string;
  getDayLink?: (date: string) => string;
  scheduleEvents: Map<string, IScheduleEvent[]>;
  holidayEvents?: Map<string, IHolidayEvent[]>;
  onEventSelected: (e: IScheduleEvent | undefined) => void;
}

interface IDayCellProps {
  day: DateTime;
  events: IScheduleEvent[];
  onEventSelected: (event: IScheduleEvent, targetId: string) => void;
}

const DayCell: React.FC<IDayCellProps> = ({ day, events, onEventSelected }) => {
  return (
    <div className={cn(styles['day-cell'])}>
      {events.map((e: IScheduleEvent) => (
        <ScheduleEvent day={day} event={e} key={e.eventId} onEventSelected={onEventSelected} />
      ))}
    </div>
  );
};

const WeeklySchedule: React.FC<IWeeklySchedule> = ({
  now,
  weekToDisplay,
  timezone,
  getDayLink,
  scheduleEvents,
  holidayEvents,
  onEventSelected,
}) => {
  const [selectedEvent, setSelectedEvent] = useState<IScheduleEvent | undefined>(undefined);
  const [selectedEventTargetId, setSelectedEventTargetId] = useState<string | undefined>(undefined);

  const weekStart = weekToDisplay.startOf('day').set({ weekday: 1 });
  const weekDays = Array.from(Array(7), (_, i) => weekStart.plus({ days: i }));
  const zonedNow = now.setZone(timezone);
  const today = zonedNow.startOf('day');

  const formattedDate = (d: DateTime) =>
    d.setLocale('local').toLocaleString({
      year: d.year !== zonedNow.year ? 'numeric' : undefined,
      weekday: 'short',
      month: 'short',
      day: 'numeric',
    });

  const handleEventSelected = (event: IScheduleEvent, targetElementId: string) => {
    setSelectedEvent(event);
    setSelectedEventTargetId(
      selectedEventTargetId === targetElementId ? undefined : targetElementId
    );
    onEventSelected(event);
  };

  const handleEventDeselected = () => {
    setSelectedEvent(undefined);
    setSelectedEventTargetId(undefined);
    onEventSelected(undefined);
  };

  const toggleSelectedEventPopover = () => {
    handleEventDeselected();
  };

  const getHolidayStyle = (holidayEvents: IHolidayEvent[] | undefined) => {
    if (!holidayEvents || holidayEvents.length === 0) return '';

    if (holidayEvents.some(h => h.holidayType === HolidayType.PublicHoliday)) {
      return 'public-holiday';
    }

    return '';
  };

  return (
    <div onClick={handleEventDeselected}>
      <Table className={cn(styles['calendar'])} borderless>
        <thead className={cn(styles['calendar-header'])}>
          <tr>
            {weekDays.map(d => (
              <th
                key={d.toISODate()}
                className={cn(styles[getHolidayStyle(holidayEvents?.get(d.toISODate()))])}>
                {!!getDayLink ? (
                  <Link to={getDayLink(d.toISODate())}>{formattedDate(d)}</Link>
                ) : (
                  formattedDate(d)
                )}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          <tr>
            {weekDays.map(d => (
              <td
                key={d.toISODate()}
                className={cn(
                  d.equals(today) ? styles['today'] : '',
                  styles[getHolidayStyle(holidayEvents?.get(d.toISODate()))]
                )}>
                <DayCell
                  day={d.setZone('local', { keepLocalTime: true })}
                  events={[
                    ...(holidayEvents?.get(d.toISODate()) || []),
                    ...(scheduleEvents.get(d.toISODate()) || []),
                  ]}
                  onEventSelected={handleEventSelected}
                />
              </td>
            ))}
          </tr>
        </tbody>
      </Table>
      {selectedEventTargetId && !!selectedEvent?.eventCalloutBody ? (
        <EventDetailsPopover
          key={selectedEventTargetId}
          event={selectedEvent}
          target={selectedEventTargetId}
          onClose={toggleSelectedEventPopover}
        />
      ) : null}
    </div>
  );
};

export default WeeklySchedule;
