import { Component } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import {
  PagePrimarySize,
  PaneType,
  FieldType,
  ActionType,
  ShellModalSize,
} from 'src/views/definitionBuilders/types';
import CrudPage, { CrudPageMode, ICrudPageDef } from 'src/views/components/Page/pages/CrudPage';
import { TrashIcon } from 'src/images/icons';
import { getSubmitCloseModalActionGroupDef } from 'src/views/definitionBuilders/common';
import { RolePermission } from 'src/api/enums';
import { getRolePermissionKeyValues, roleHasPermission } from '../roleHelpers';
import getStaffMembersSectionDef from './getStaffMembersSectionDef';

type RoleItem = People.Domain.Queries.Role.RoleItem;
type CreateRoleCommand = People.Domain.Commands.Role.CreateRole.CreateRoleCommand;
type UpdateRoleCommand = People.Domain.Commands.Role.UpdateRole.UpdateRoleCommand;
type AssignStaffMembersToRoleCommand = People.Domain.Commands.Role.AssignStaffMembersToRole.AssignStaffMembersToRoleCommand;
type StaffMemberNames = Common.Queries.GetStaffMemberNames.StaffMemberName[];
type RemoveStaffMemberFromRoleCommand = People.Domain.Commands.Role.RemoveRole.RemoveStaffMemberFromRoleCommand;
type StaffMemberDto = Common.Dtos.StaffMemberDto;

export interface IMaintainRoleProps {
  mode: CrudPageMode;
  canManageRoles: boolean;

  onLoadRole: (id: string) => Promise<void>;
  role: RoleItem | undefined;

  checkForUniqueRoleName: (name: string) => Promise<Common.Dtos.UniqueNameCheckResultDto>;
  onCreateRole: (command: CreateRoleCommand) => Promise<void>;
  onUpdateRole: (command: UpdateRoleCommand) => Promise<void>;
  onDeleteRole: (roleId: string) => Promise<void>;
  listStaffMembersForRole: (roleId: string) => Promise<void>;
  assignStaffMembersToRole: (command: AssignStaffMembersToRoleCommand) => Promise<void>;
  assignedStaffMembers: StaffMemberNames;
  removeStaffMemberFromRole: (command: RemoveStaffMemberFromRoleCommand) => Promise<void>;
  loadAllStaffMembers: () => Promise<void>;
  activeStaffMembers: StaffMemberDto[];
}

interface IUpdateRoleRouteParams {
  id: string;
}

type InternalProps = IMaintainRoleProps & RouteComponentProps<IUpdateRoleRouteParams>;

class MaintainRole extends Component<InternalProps> {
  private get isUpdateMode() {
    return this.props.mode === 'update';
  }

  private get isCreateMode() {
    return this.props.mode === 'create';
  }

  private get roleId() {
    return this.props.match.params.id;
  }

  private getPermissions = (role: IRoleWithIndividualPermissions) => {
    let permissions = 0;
    role.permissions
      .filter(p => p.hasPermission)
      .forEach(p => {
        permissions |= p.value;
      });
    return permissions;
  };

  private handlePreSubmitForCreate = (role: IRoleWithIndividualPermissions): CreateRoleCommand => {
    return {
      name: role.name,
      permissions: this.getPermissions(role),
    };
  };

  private handlePreSubmitForUpdate = (role: IRoleWithIndividualPermissions): UpdateRoleCommand => {
    return {
      id: this.roleId,
      name: role.name,
      permissions: this.getPermissions(role),
    };
  };

  private readonly getPageDef = (updating: boolean): ICrudPageDef => {
    const { checkForUniqueRoleName, role: loadedRole, canManageRoles } = this.props;
    return {
      primarySize: PagePrimarySize.half,
      primarySection: {
        title: this.isUpdateMode ? 'Manage Role' : 'Create a Role',
        primaryActions: [
          {
            actions: [
              {
                actionType: ActionType.actionCollection,
                hidden: updating || !this.isUpdateMode || !canManageRoles,
                actionGroups: [
                  {
                    actions: [
                      {
                        actionType: ActionType.modalActionButton,
                        label: 'Deactivate Role',
                        icon: <TrashIcon />,
                        modalSize: ShellModalSize.oneQuarter,
                        modalDef: modalDefApi => ({
                          title: 'Deactivate Role',
                          asForm: true,
                          panels: [
                            {
                              panes: [
                                {
                                  paneType: PaneType.customPane,
                                  render: () => (
                                    <span>Are you sure you want to deactivate this role ?</span>
                                  ),
                                },
                              ],
                            },
                          ],
                          secondaryActions: [getSubmitCloseModalActionGroupDef('Ok')],
                          onFormSubmit: () => this.props.onDeleteRole(this.roleId),
                        }),
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
        panels: [
          {
            panes: [
              {
                paneType: PaneType.formFieldsPane,
                columnCount: 1,
                fields: [
                  {
                    fieldType: FieldType.textField,
                    label: 'Name',
                    dataAddr: 'name',
                    maxLength: 255,
                    mandatory: true,
                    validateAsync: async d => {
                      if (
                        !d.fieldValue ||
                        (this.isUpdateMode &&
                          loadedRole &&
                          loadedRole.name.toUpperCase() === d.fieldValue.toUpperCase())
                      ) {
                        return undefined;
                      }
                      const result = await checkForUniqueRoleName(d.fieldValue);
                      return result.nameExists ? `Name is already in use` : undefined;
                    },
                  },
                ],
              },
              {
                paneType: PaneType.repeatingPane,
                dataAddr: 'permissions',
                useHover: false,
                itemPanes: [
                  {
                    paneType: PaneType.formFieldsPane,
                    columnCount: 1,
                    fields: [
                      {
                        fieldType: FieldType.yesNoField,
                        label: d => d.paneValue.description,
                        dataAddr: 'hasPermission',
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
        onFormPreSubmit: this.isUpdateMode
          ? this.handlePreSubmitForUpdate
          : this.handlePreSubmitForCreate,
        onFormSubmit: this.isUpdateMode ? this.props.onUpdateRole : this.props.onCreateRole,
      },
      secondarySections: this.isCreateMode ? [] : [getStaffMembersSectionDef.call(this)],
    };
  };

  private getFormData = (
    role: RoleItem | undefined,
    assignedStaffMembers: StaffMemberNames
  ): IRoleWithIndividualPermissions | undefined => {
    return role
      ? ({
          id: role.id,
          name: role.name,
          permissions: getRolePermissionKeyValues().map(p => {
            return {
              ...p,
              hasPermission: roleHasPermission(role as RoleItem, p.value as RolePermission),
            };
          }),
          staffMembers: assignedStaffMembers,
        } as IRoleWithIndividualPermissions)
      : undefined;
  };

  render() {
    const { mode, role, canManageRoles, assignedStaffMembers } = this.props;

    return (
      <CrudPage
        def={({ updating }) => this.getPageDef(updating)}
        mode={mode}
        isEditingForbidden={!canManageRoles}
        onLoadData={() => {
          let promise = new Promise<void>((resolve, reject) => {
            Promise.all([
              this.props.onLoadRole(this.roleId),
              this.props.listStaffMembersForRole(this.roleId),
            ])
              .then(() => {
                resolve();
              })
              .catch(() => reject());
          });

          return promise;
        }}
        data={this.getFormData(role, assignedStaffMembers)}
        createDefaultData={
          {
            name: '',
            permissions: getRolePermissionKeyValues().map(p => {
              return {
                ...p,
                hasPermission: false,
              };
            }),
          } as IRoleWithIndividualPermissions
        }
      />
    );
  }
}

interface IRoleWithIndividualPermissions {
  id: string | undefined;
  name: string;
  permissions: [
    { key: string; value: RolePermission; description: string; hasPermission: boolean }
  ];
  staffMembers: StaffMemberNames;
}

export default MaintainRole;
