function applyToAll<T>(func: (a: Array<T>, b: Array<T>) => Array<T>, source: Array<Array<T>>) {
  if (!source) {
    return [];
  }
  const copy = [...source];
  const last = copy.pop() || [];
  return copy.reduce(func, last);
}

export function intersect<T>(a: Array<T>, b: Array<T>) {
  let t;
  if (b.length > a.length) {
    t = b;
    b = a;
    a = t;
  } // indexOf to loop over shorter array
  return a.filter(e => b.indexOf(e) > -1);
}

export function intersectAll<T>(source: Array<Array<T>>) {
  return applyToAll<T>(intersect, source);
}

export function union<T>(a: Array<T>, b: Array<T>) {
  return distinct([...a, ...b]);
}

export function unionAll<T>(source: Array<Array<T>>) {
  return applyToAll<T>(union, source);
}

export function except<T>(a: Array<T>, b: Array<T>) {
  return a.filter(i => b.indexOf(i) < 0);
}

export function distinct<T>(a: Array<T>) {
  return Array.from(new Set(a));
}

export function hasSameItems<T>(a: Array<T>, b: Array<T>) {
  return a.length === b.length && intersect(a, b).length === a.length;
}

export interface IDeepArray<T> extends Array<T | IDeepArray<T>> {}

export function flatten<T>(a: IDeepArray<T>, result: Array<T> = []) {
  for (let i = 0, length = a.length; i < length; i++) {
    const value = a[i];
    if (Array.isArray(value)) {
      flatten(value, result);
    } else {
      result.push(value);
    }
  }
  return result;
}

export function groupBy<T, TKey>(source: Array<T>, grouper: (item: T) => TKey) {
  return source.reduce((acc, i) => {
    const key = grouper(i);
    const group = acc.get(key) || [];
    group.push(i);
    acc.set(key, group);
    return acc;
  }, new Map<TKey, T[]>());
}

export function groupByFlat<T, TKey>(source: Array<T>, grouper: (item: T) => TKey[]) {
  return source.reduce((acc, i) => {
    const keys = grouper(i);
    keys.forEach(k => {
      const group = acc.get(k) || [];
      group.push(i);
      acc.set(k, group);
    });
    return acc;
  }, new Map<TKey, T[]>());
}
