import ListPage, { IListPageDef } from 'src/views/components/Page/pages/ListPage';
import TaskCardItem from 'src/views/components/TaskCard/TaskCardItem';
import { FieldType, PagePrimarySize } from 'src/views/definitionBuilders/types';
import { getDisplayDays, workingShiftHours } from 'src/views/routes/operations/urban/urbanHelpers';
import { observer } from 'mobx-react';
import { useRootStore } from 'src/domain/entities/RootStoreModel';
import { Link } from 'react-router-dom';
import { SyncIcon } from 'src/images/icons';
import PrimaryTitle from 'src/views/components/Page/PrimaryTitle/PrimaryTitle';
import { getEditingFormattedTimeString } from 'src/views/components/Page/fields/subfields/TimeHelpers';
import { Duration } from 'luxon';

type RosterGroupListItem = Operations.Domain.Queries.ListRosters.RosterGroupListItem;
type ShiftItem = Operations.Domain.Queries.ListRosters.ShiftItem;

function getPageDef(canManageRosters: boolean, listRosters: () => Promise<void>): IListPageDef {
  return {
    primaryTitle: (
      <PrimaryTitle
        title="Rosters"
        link="https://central-ops.notion.site/Rosters-38e156ec20a84593b059951380355a9b"></PrimaryTitle>
    ),
    primarySize: PagePrimarySize.full,
    onLoadData: listRosters,
    externalSearch: false,
    createLink: canManageRosters ? TaskCardItem.urban.createRoster : undefined,
    primaryFields: [
      {
        fieldType: FieldType.textField,
        dataAddr: 'rosterNumber',
        label: 'Roster Number',
        linkTo: d => `/operations/rosters/${d.parentValue.id}`,
      },
      {
        fieldType: FieldType.readonlyField,
        dataAddr: 'shifts',
        label: 'Shifts',
        formatReadonly: data =>
          data.fieldValue.map((x: ShiftItem) => (
            <div key={x.id}>{`${x.shiftName} - ${x.shiftType.description} - ${
              x.description
            } - ${getDisplayDays(x).join(', ')}`}</div>
          )),
      },
      {
        fieldType: FieldType.timeField,
        dataAddr: ['shifts'],
        label: 'Total Working Hours',
        readonly: true,
        formatReadonly: (data: any) => {
          var totalHours = (data.fieldValue || [])
            .filter((shift: ShiftItem) => shift.clockOn && shift.clockOff && shift.unpaidBreaks)
            .map((shift: ShiftItem) => {
              return workingShiftHours(shift.clockOn, shift.clockOff, shift.unpaidBreaks, shift);
            })
            .filter((duration: Duration | undefined) => duration)
            .reduce((agg: Duration, val: Duration) => agg.plus(val), Duration.fromMillis(0));
          return getEditingFormattedTimeString(totalHours);
        },
      },
      {
        fieldType: FieldType.textField,
        dataAddr: ['staffMember', 'name'],
        label: 'Staff Member',
        linkTo: d =>
          `/people/staff-members/${d.parentValue.staffMember && d.parentValue.staffMember.id}`,
      },
      {
        fieldType: FieldType.readonlyField,
        label: 'Roster Group',
        formatReadonly: data => {
          const rosterGroup: RosterGroupListItem = data.parentValue.rosterGroup;
          if (rosterGroup) {
            return (
              <Link to={`/operations/roster-groups/${rosterGroup.id}`}>
                {rosterGroup.name}
                {rosterGroup.isRotating && (
                  <span>
                    &emsp; - &emsp;
                    <SyncIcon />
                  </span>
                )}
              </Link>
            );
          }
          return null;
        },
      },
    ],
  };
}

const ListRoster: React.FC = observer(() => {
  const rootStore = useRootStore();
  const canManageRosters = rootStore.account.isOperationsDepartmentMember;
  const listRosters = rootStore.operations.urban.rosters.listRosters;
  const rosters = rootStore.operations.urban.rosters.rosters.slice();

  return (
    <ListPage
      className="list-roster-component"
      data={rosters}
      def={getPageDef(canManageRosters, listRosters)}
    />
  );
});

export default ListRoster;
