import inject from 'src/views/injectFromStore';
import Omit from 'src/infrastructure/omit';
import MaintainNote, { IMaintainNoteProps } from './MaintainNote';

const Container = inject<Omit<IMaintainNoteProps, 'mode'>, IMaintainNoteProps>((allStores, p) => ({
  canManageStaffMemberNotes:
    allStores.rootStore.account.isAccountingDepartmentMember ||
    allStores.rootStore.account.isOperationsDepartmentMember ||
    allStores.rootStore.account.isPeopleDepartmentMember ||
    allStores.rootStore.account.isRailDepartmentMember ||
    allStores.rootStore.account.isSalesDepartmentMember ||
    allStores.rootStore.account.isTimesheetsDepartmentMember ||
    allStores.rootStore.account.isWorkshopDepartmentMember,
  staffMembers: allStores.rootStore.people.staffMembers.allStaffMembers.slice(),
  activeStaffMembers: allStores.rootStore.people.staffMembers.activeStaffMembers.slice(),
  allStaffWithDriverAuthorisation: allStores.rootStore.people.staffMembers.allStaffWithDriverAuthorisation.slice(),
  loadStaffMembers: allStores.rootStore.people.staffMembers.loadAllStaffMembers,
  createNote: allStores.rootStore.people.note.createNote,
  loadNote: allStores.rootStore.people.note.loadNote,
  note: allStores.rootStore.people.note.note,
  updateNote: allStores.rootStore.people.note.updateNote,
  deleteNote: allStores.rootStore.people.note.deleteNote,
  loadAllRoles: allStores.rootStore.people.roles.loadAllRoles,
  roles: allStores.rootStore.people.roles.roles.slice(),
}))(MaintainNote);

export default Container;
