import { types } from 'mobx-state-tree';
import { LostPropertyItemModel } from './LostPropertyItemModel';
import { ListLostPropertyModel } from './ListLostPropertyModel';

export const LostPropertyModel = types.model('LostPropertyModel', {
  list: types.optional(ListLostPropertyModel, {}),
  item: types.optional(LostPropertyItemModel, {}),
});
type ModelType = typeof LostPropertyModel.Type;
export interface ILostPropertyModel extends ModelType {}
