import { saveAs } from 'file-saver';
import { DateTime } from 'luxon';
import { observer } from 'mobx-react';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { PurchaseOrderStatus, allPurchaseOrderStatus } from 'src/api/enums';
import { useRootStore } from 'src/domain/entities/RootStoreModel';
import { IMatchingPart } from 'src/domain/entities/workshop/parts/PartsModel';
import { ExcelIcon } from 'src/images/icons';
import ListPage, { IListPageDef } from 'src/views/components/Page/pages/ListPage';
import TaskCardItem from 'src/views/components/TaskCard/TaskCardItem';
import { getSubmitCloseModalActionGroupDef } from 'src/views/definitionBuilders/common';
import {
  ActionType,
  ISectionDef,
  PagePrimarySize,
  ShellModalSize,
} from 'src/views/definitionBuilders/types';
import { FieldDefs, FieldType } from 'src/views/definitionBuilders/types/field';
import { PaneType } from 'src/views/definitionBuilders/types/pane';
import PrimaryTitle from 'src/views/components/Page/PrimaryTitle/PrimaryTitle';

type PurchaseOrderListItem = Workshop.Domain.Queries.Purchasing.PurchaseOrderListItem;
type PartItem = Workshop.Domain.Queries.Parts.PartItem;
type PurchaseOrderItemForDropdown = Workshop.Domain.Queries.Purchasing.ListPurchaseOrdersForDropdown.PurchaseOrderItemForDropdown;

const ListPurchaseOrders: React.FC = observer(() => {
  const rootStore = useRootStore();
  const purchaseOrderModel = rootStore.workshop.purchaseOrder;
  const listPurchaseOrdersModel = rootStore.workshop.listPurchaseOrders;
  const assetsModel = rootStore.assets;
  const supplierModel = rootStore.workshop.supplier;
  const partsModel = rootStore.workshop.parts;

  useEffect(() => {
    assetsModel.loadAssetListItems();
    purchaseOrderModel.loadDeliveryLocations();
  }, []);

  const downloadPurchaseOrderBudgetReport = (
    receivedStartDate: string,
    receivedEndDate: string,
    suppliers: string[],
    deliveryLocations: number[],
    assets: string[],
    parts: string[],
    purchaseOrders: string[],
    useFixedColumns: boolean,
    fileNamePrefix: string
  ) => {
    const fileName = `${fileNamePrefix}.xlsx`;

    return listPurchaseOrdersModel
      .generatePurchaseOrderBudgetReport({
        receivedStartDate: receivedStartDate,
        receivedEndDate: receivedEndDate,
        suppliers: suppliers,
        deliveryLocations: deliveryLocations,
        assets: assets,
        parts: parts,
        purchaseOrders: purchaseOrders || [],
        useFixedColumns: useFixedColumns,
      })
      .then(r => {
        saveAs(r, fileName);
      });
  };

  const getPageDef = (): IListPageDef => {
    const filterFieldDefs: FieldDefs[] = [
      {
        fieldType: FieldType.selectAsyncMultiField,
        label: 'Supplier',
        dataAddr: 'suppliers',
        useValueOnly: true,
        valueKey: 'id',
        descriptionKey: 'name',
        loadOptionItems: supplierModel.searchSuppliers,
        loadItems: ids => supplierModel.findSuppliers(ids),
        autoload: true,
      },
      {
        fieldType: FieldType.selectMultiField,
        label: 'Delivery To',
        dataAddr: 'deliveryLocations',
        useValueOnly: true,
        valueKey: 'id',
        descriptionKey: 'description',
        optionItems: purchaseOrderModel.deliveryLocations.slice(),
      },
      {
        fieldType: FieldType.selectMultiField,
        label: 'Asset',
        dataAddr: 'assets',
        useValueOnly: true,
        valueKey: 'id',
        descriptionKey: 'name',
        optionItems: assetsModel.assetListItems.slice(),
      },
      {
        fieldType: FieldType.selectMultiField,
        label: 'Status',
        dataAddr: 'statuses',
        useValueOnly: true,
        valueKey: 'value',
        descriptionKey: 'description',
        optionItems: allPurchaseOrderStatus,
      },
      {
        fieldType: FieldType.selectAsyncMultiField,
        dataAddr: 'parts',
        label: 'Part Number',
        valueKey: 'id',
        descriptionKey: 'partNumber',
        useValueOnly: true,
        loadOptionItems: (s, _) => partsModel.searchParts(s),
        loadItems: partsModel.findParts,
        optionRenderer: (o: IMatchingPart) => (
          <div style={{ whiteSpace: 'nowrap' }}>
            {o.partNumber} - {o.description}
          </div>
        ),
        valueRenderer: (o: PartItem) => <div style={{ whiteSpace: 'nowrap' }}>{o.partNumber}</div>,
      },
    ];

    const getModalDef = (
      modalTitle: string,
      fileNamePrefix: string,
      useFixedColumns: boolean,
      includePoNumberFilter: boolean
    ): ISectionDef => {
      return {
        title: modalTitle,
        asForm: true,
        panels: [
          {
            panes: [
              {
                paneType: PaneType.formFieldsPane,
                columnCount: 1,
                fields: [
                  {
                    fieldType: FieldType.dateField,
                    label: 'Start Date',
                    dataAddr: 'receivedStartDate',
                    mandatory: false,
                  },
                  {
                    fieldType: FieldType.dateField,
                    label: 'End Date',
                    dataAddr: 'receivedEndDate',
                    mandatory: false,
                    validate: d =>
                      DateTime.fromISO(d.parentValue.receivedEndDate) <
                      DateTime.fromISO(d.parentValue.receivedStartDate)
                        ? 'End must be after Start'
                        : undefined,
                  },
                  {
                    fieldType: FieldType.selectAsyncMultiField,
                    label: 'Supplier',
                    dataAddr: 'suppliers',
                    useValueOnly: true,
                    valueKey: 'id',
                    descriptionKey: 'name',
                    loadOptionItems: supplierModel.searchSuppliers,
                    loadItems: ids => supplierModel.findSuppliers(ids),
                    autoload: true,
                  },
                  {
                    fieldType: FieldType.selectMultiField,
                    label: 'Delivery To',
                    dataAddr: 'deliveryLocations',
                    useValueOnly: true,
                    valueKey: 'id',
                    descriptionKey: 'description',
                    optionItems: purchaseOrderModel.deliveryLocations.slice(),
                  },
                  {
                    fieldType: FieldType.selectMultiField,
                    label: 'Asset',
                    dataAddr: 'assets',
                    useValueOnly: true,
                    valueKey: 'id',
                    descriptionKey: 'name',
                    optionItems: assetsModel.assetListItems.slice(),
                  },
                  {
                    fieldType: FieldType.selectAsyncMultiField,
                    dataAddr: 'parts',
                    label: 'Part Numbers',
                    valueKey: 'id',
                    descriptionKey: 'partNumber',
                    useValueOnly: true,
                    loadOptionItems: (s, _) => partsModel.searchParts(s),
                    loadItems: partsModel.findParts,
                    optionRenderer: (o: IMatchingPart) => (
                      <div style={{ whiteSpace: 'nowrap' }}>
                        {o.partNumber} - {o.description}
                      </div>
                    ),
                    valueRenderer: (o: PartItem) => (
                      <div style={{ whiteSpace: 'nowrap' }}>{o.partNumber}</div>
                    ),
                  },
                  {
                    fieldType: FieldType.selectAsyncMultiField,
                    dataAddr: 'purchaseOrders',
                    label: 'PO Numbers',
                    valueKey: 'id',
                    descriptionKey: 'purchaseOrderNumber',
                    useValueOnly: true,
                    loadOptionItems: purchaseOrderModel.searchPurchaseOrders,
                    loadItems: purchaseOrderModel.findPurchaseOrders,
                    optionRenderer: (o: PurchaseOrderItemForDropdown) => (
                      <div style={{ whiteSpace: 'nowrap' }}>{o.purchaseOrderNumber}</div>
                    ),
                    valueRenderer: (o: PurchaseOrderItemForDropdown) => (
                      <div style={{ whiteSpace: 'nowrap' }}>{o.purchaseOrderNumber}</div>
                    ),
                    hidden: !includePoNumberFilter,
                  },
                ],
              },
            ],
          },
        ],
        secondaryActions: [getSubmitCloseModalActionGroupDef('Ok')],
        onFormSubmit: v => {
          return downloadPurchaseOrderBudgetReport(
            v.receivedStartDate,
            v.receivedEndDate,
            v.suppliers,
            v.deliveryLocations,
            v.assets,
            v.parts,
            v.purchaseOrders,
            useFixedColumns,
            fileNamePrefix
          );
        },
      };
    };

    return {
      primaryTitle: <PrimaryTitle title="Purchase Orders"></PrimaryTitle>,
      primarySize: PagePrimarySize.full,
      onLoadData: listPurchaseOrdersModel.listItems,
      externalSearch: true,
      createLink: rootStore.account.isWorkshopDepartmentMember
        ? TaskCardItem.workshop.createPurchaseOrder
        : undefined,
      hasMoreData: listPurchaseOrdersModel.hasMoreItems,
      primaryActions: [
        {
          actions: [
            {
              actionType: ActionType.actionCollection,
              actionGroups: [
                {
                  actions: [
                    {
                      actionType: ActionType.modalActionButton,
                      label: 'Generate Purchase Order Budget Report',
                      icon: <ExcelIcon fixedWidth />,
                      modalSize: ShellModalSize.oneQuarter,
                      modalDef: _ =>
                        getModalDef(
                          'Generate Purchase Order Budget Report',
                          'PurchaseOrderBudgetReport',
                          false,
                          false
                        ),
                    },
                    {
                      actionType: ActionType.modalActionButton,
                      label: 'Export To Excel',
                      icon: <ExcelIcon fixedWidth />,
                      modalSize: ShellModalSize.oneQuarter,
                      modalDef: _ =>
                        getModalDef('Export To Excel', 'PurchaseOrderExcelExport', true, true),
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
      primaryFields: [
        {
          fieldType: FieldType.textField,
          dataAddr: 'purchaseOrderNumber',
          label: 'PO Number',
          linkTo: d => `/workshop/purchase-orders/${d.parentValue.id}`,
        },
        {
          fieldType: FieldType.textField,
          dataAddr: 'supplierName',
          label: 'Supplier',
        },
        {
          fieldType: FieldType.readonlyField,
          label: 'Status',
          dataAddr: ['status', 'description'],
        },
        {
          fieldType: FieldType.readonlyField,
          dataAddr: 'assets',
          label: 'Asset',
          formatReadonly: data => {
            var purchaseOrder = data.parentValue as PurchaseOrderListItem;
            return (
              <div>
                {purchaseOrder.assets.map((a, i) => (
                  <div key={i}>
                    <Link to={`/workshop/assets/${a.id}`}>
                      {a.isDecommissioned ? `${a.name} (Decommissioned)` : a.name}
                    </Link>
                  </div>
                ))}
              </div>
            );
          },
        },
        {
          fieldType: FieldType.dateField,
          dataAddr: 'dateCreated',
          label: 'Created Date',
        },
        {
          fieldType: FieldType.readonlyField,
          dataAddr: 'invoiceNumbers',
          label: 'Invoices',
          formatReadonly: data => {
            var purchaseOrder = data.parentValue as PurchaseOrderListItem;
            return (
              <div>
                {purchaseOrder.invoiceNumbers.map((invoiceNumber, index) => (
                  <div key={index}>{invoiceNumber}</div>
                ))}
              </div>
            );
          },
        },
      ],
      filterAction: {
        defaultValues: {
          statuses: [
            PurchaseOrderStatus.New,
            PurchaseOrderStatus.Approved,
            PurchaseOrderStatus.GoodsReceived,
            PurchaseOrderStatus.AwaitingCredit,
          ],
        },
        filterFields: filterFieldDefs,
      },
    };
  };

  return (
    <ListPage
      className="list-purchase-orders-component"
      data={listPurchaseOrdersModel.items}
      def={getPageDef()}
    />
  );
});

export default ListPurchaseOrders;
