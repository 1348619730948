import Page from 'src/views/components/Page/Page';
import inject from 'src/views/injectFromStore';
import './PageLayout.scss';
import { IPageDef, PagePrimarySize, PaneType } from 'src/views/definitionBuilders/types';

export interface IPageLayoutProps {
  size: PagePrimarySize | undefined;
  enablePageSpinner: boolean | undefined;
  setPrimaryPageSize: (size: PagePrimarySize) => void;
  togglePageSpinner: () => void;
}

const getPageDef = (props: IPageLayoutProps) => {
  return {
    primarySection: {
      title: 'Primary Title',
      panels: [
        {
          title: 'Panel - Page Component',
          panes: [
            {
              render: () => {
                const options = [
                  { size: PagePrimarySize.half, label: 'PagePrimarySize.half' },
                  { size: PagePrimarySize.full, label: 'PagePrimarySize.full' },
                  { size: PagePrimarySize.twoThirds, label: 'PagePrimarySize.twoThirds' },
                  { size: PagePrimarySize.threeQuarters, label: 'PagePrimarySize.threeQuarters' },
                  { size: PagePrimarySize.sevenTwelves, label: 'PagePrimarySize.sevenTwelves' },
                ];
                return (
                  <div id="pagetop" className="panel1">
                    <h4>Page.showPrimarySectionSpinner</h4>
                    <p>Enable the primary section spinner</p>
                    <label>
                      <input
                        type="checkbox"
                        value="spinner"
                        checked={!!props.enablePageSpinner}
                        onChange={() => props.togglePageSpinner()}
                      />
                      &nbsp;Show Spinner
                    </label>
                    <h4>IPageDef.primarySize</h4>
                    <p>The size of the primary section is set by IPageDef.primarySize</p>
                    {options.map(item => (
                      <div className="radio">
                        <label>
                          <input
                            type="radio"
                            value={item.size}
                            checked={props.size === item.size}
                            onChange={evt =>
                              props.setPrimaryPageSize(evt.target.value as PagePrimarySize)
                            }
                          />
                          &nbsp;{item.label}
                        </label>
                      </div>
                    ))}
                  </div>
                );
              },
              paneType: PaneType.customPane,
            },
          ],
        },
        {
          panes: [
            {
              paneType: PaneType.customPane,
              render: () => (
                <div className="panel2">
                  <h1>Heading 1</h1>
                  <h2>Heading 2</h2>
                  <h3>Heading 3</h3>
                  <h4>Heading 4</h4>
                  <h5>Heading 5</h5>
                  <table>
                    <caption>Table Caption</caption>
                    <thead>
                      <tr>
                        <th>Heading 1</th>
                        <th>Heading 2</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Cell 1</td>
                        <td>Cell 2</td>
                      </tr>
                      <tr>
                        <td>Cell 1</td>
                        <td>Cell 2</td>
                      </tr>
                      <tr>
                        <td>Cell 1</td>
                        <td>Cell 2</td>
                      </tr>
                    </tbody>
                  </table>
                  <a href="#pagetop">Hyperlink</a>
                  <blockquote>
                    <p>
                      Blockquote{' '}
                      <small>
                        small <cite title="Cite">Cite</cite>
                      </small>
                    </p>
                  </blockquote>
                  <ul>
                    <li>List Item</li>
                    <li>
                      List Item
                      <ul>
                        <li>Nested List Item</li>
                        <li>Nested List Item</li>
                      </ul>
                    </li>
                    <li>List Item</li>
                  </ul>
                  <ol>
                    <li>List Item</li>
                    <li>
                      List Item
                      <ol>
                        <li>Nested List Item</li>
                        <li>Nested List Item</li>
                      </ol>
                    </li>
                    <li>List Item</li>
                  </ol>
                  <p>
                    Here's some code:{' '}
                    <code>
                      e = mc<sup>2</sup>
                    </code>
                  </p>
                  <pre>
                    <code>And Some Poetry</code>
                  </pre>
                </div>
              ),
            },
          ],
          title: 'Panel - Typography',
        },
      ],
    },
    primarySize: props.size,
    secondarySections: [
      { title: 'Secondary 1', panels: [] },
      { title: 'Secondary 2', panels: [] },
    ],
  } as IPageDef;
};

const PageLayout: React.FC<IPageLayoutProps> = (props: IPageLayoutProps) => (
  <Page
    className="documentation-layout-page"
    showPrimarySectionSpinner={!!props.enablePageSpinner}
    def={getPageDef(props)}
  />
);

const Container = inject<IPageLayoutProps>((allStores, p) => {
  const documentationModel = allStores.rootStore.documentation;
  return {
    size: documentationModel.primaryPageSize,
    enablePageSpinner: documentationModel.enablePageSpinner,
    setPrimaryPageSize: documentationModel.setPrimaryPageSize,
    togglePageSpinner: documentationModel.togglePageSpinner,
  };
})(PageLayout);

export default Container;
