import { Component } from 'react';
import { PagePrimarySize, IPageDef, PaneType } from 'src/views/definitionBuilders/types';
import Page from 'src/views/components/Page';
import TaskCardItem, { toActionLinkDef } from 'src/views/components/TaskCard/TaskCardItem';
import DashboardTileCollection from 'src/views/components/Dashboard/DashboardTileCollection';
import StandardDashboardTile from 'src/views/components/Dashboard/StandardDashboardTile';
import { DriverAuthorisationLicenceType } from 'src/views/components/LicenceTypesHelper';
import { LicenceExpiryPeriod } from 'src/api/enums';

export interface IPeopleProps {
  dashboardData?: People.Domain.Queries.GetPeopleDashboard.PeopleDashboardData;
  onLoadData: () => Promise<void>;
  canCreateStaffMember: boolean;
  canModifyStaffSkillSpecs: boolean;
}

class People extends Component<IPeopleProps> {
  async componentDidMount() {
    await this.props.onLoadData();
  }

  private readonly getData = () => {
    const { dashboardData } = this.props;
    return { dashboardData };
  };

  private readonly getPageDef = (): IPageDef => {
    const { dashboardData, canCreateStaffMember, canModifyStaffSkillSpecs } = this.props;
    const topTasksActions = [
      ...(canCreateStaffMember ? [TaskCardItem.people.createStaffMember] : []),
    ].map(toActionLinkDef);
    const configurationActions = [
      ...[TaskCardItem.people.equipmentTypes.listEquipmentTypes],
      ...[TaskCardItem.people.listExternalIdentities],
      ...[TaskCardItem.people.listRoles],
      ...(canModifyStaffSkillSpecs ? [TaskCardItem.people.skillSpecs] : []),
      ...[TaskCardItem.people.listTimesheetAllowances],
    ].map(toActionLinkDef);
    return {
      primarySize: PagePrimarySize.threeQuarters,
      primarySection: {
        title: 'People',
        panels: [
          {
            panes: [
              {
                paneType: PaneType.customPane,
                render: () => (
                  <>
                    <DashboardTileCollection isLoading={!dashboardData}>
                      {dashboardData ? (
                        <>
                          <StandardDashboardTile
                            title="Staff Members with DA Expiring Within 10 Days"
                            link={`/people/staff-members?active=true&licenceExpiryPeriod=${LicenceExpiryPeriod.Within10Days}&licenceTypeIds=${DriverAuthorisationLicenceType}`}
                            number={dashboardData.activeStaffMembersWithDAExpiringWithin10Days}
                          />
                        </>
                      ) : null}
                    </DashboardTileCollection>
                    <DashboardTileCollection title={'Admin'} isLoading={!dashboardData}>
                      {dashboardData ? (
                        <>
                          <StandardDashboardTile
                            title="Unmapped External Identities"
                            link="/people/external-identities?matched=false"
                            number={dashboardData.unmappedExternalIdentityCount}
                          />
                        </>
                      ) : null}
                    </DashboardTileCollection>
                  </>
                ),
              },
            ],
          },
        ],
      },
      secondarySections: [
        {
          title: 'Navigation',
          panels: [
            {
              title: 'Top Tasks',
              panes: [
                {
                  paneType: PaneType.navListPane,
                  links: topTasksActions,
                },
              ],
            },
            {
              title: 'Configuration',
              panes: [
                {
                  paneType: PaneType.navListPane,
                  links: configurationActions,
                },
              ],
            },
          ],
        },
      ],
    };
  };

  render() {
    return <Page data={this.getData()} def={this.getPageDef()} />;
  }
}

export default People;
