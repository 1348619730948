import { saveAs } from 'file-saver';
import { DateTime } from 'luxon';
import { observer } from 'mobx-react';
import { useEffect } from 'react';
import { allLostOrFound, allLostPropertyItemStatus, LostPropertyItemStatus } from 'src/api/enums';
import { useRootStore } from 'src/domain/entities/RootStoreModel';
import { ExcelIcon } from 'src/images/icons';
import memoizeOne from 'src/infrastructure/memoizeOne';
import ListPage, { IListPageDef } from 'src/views/components/Page/pages/ListPage';
import PrimaryTitle from 'src/views/components/Page/PrimaryTitle/PrimaryTitle';
import { TaskCardItem } from 'src/views/components/TaskCard';
import {
  ActionType,
  FieldDefs,
  FieldType,
  PagePrimarySize,
  PaneType,
} from 'src/views/definitionBuilders/types';
import withQueryParams, { IQueryParamsProps } from 'src/views/hocs/withQueryParams';

type ListLostPropertyItemQuery = Operations.Domain.Queries.ListLostPropertyItems.ListLostPropertyItemQuery;

const ListLostPropertyItem: React.FC<IQueryParamsProps<ListLostPropertyItemQuery>> = observer(
  (props: IQueryParamsProps<ListLostPropertyItemQuery>) => {
    const rootStore = useRootStore();
    const lostPropertyModel = rootStore.compliance.lostProperty.list;
    const assetsModel = rootStore.assets;

    useEffect(() => {
      assetsModel.loadFleetAssets();
    }, []);

    const saveSpreadsheet = () => {
      const fileName = `Lost_Property_${DateTime.local().toFormat('yyyyMMddHHmm')}.xlsx`;
      return lostPropertyModel
        .exportToExcel(props.getQueryParams())
        .then(blob => saveAs(blob, fileName));
    };

    const getFilterFieldDefs = () => {
      return {
        dateFrom: {
          fieldType: FieldType.dateField,
          dataAddr: 'dateFrom',
          label: 'Date From',
        } as FieldDefs,
        dateTo: {
          fieldType: FieldType.dateField,
          dataAddr: 'dateTo',
          label: 'Date To',
          validate: d => {
            if (!d.fieldValue || !d.parentValue.dateFrom) {
              return undefined;
            }
            const from = DateTime.fromISO(d.parentValue.dateFrom);
            const to = DateTime.fromISO(d.fieldValue);
            return from > to ? 'Date To cannot be earlier than Date From' : undefined;
          },
        } as FieldDefs,
        status: {
          fieldType: FieldType.selectMultiField,
          dataAddr: 'status',
          label: 'Status',
          valueKey: 'value',
          descriptionKey: 'description',
          optionItems: allLostPropertyItemStatus.slice(),
          useValueOnly: true,
        } as FieldDefs,
        lostOrFound: {
          fieldType: FieldType.selectMultiField,
          dataAddr: 'lostOrFound',
          label: 'Lost or Found',
          valueKey: 'value',
          descriptionKey: 'description',
          optionItems: allLostOrFound.slice(),
          useValueOnly: true,
        } as FieldDefs,
        toBeDisposed: {
          fieldType: FieldType.yesNoField,
          dataAddr: 'toBeDisposed',
          label: 'To Be Disposed',
        } as FieldDefs,
      };
    };
    const getPageDef = memoizeOne(
      (hasMoreData: boolean): IListPageDef => {
        const filterFieldDefsLookup = getFilterFieldDefs();
        return {
          primaryTitle: <PrimaryTitle title="Lost Property Items"></PrimaryTitle>,
          onLoadData: lostPropertyModel.listItems,
          externalSearch: true,
          createLink: rootStore.account.canCreateOrUpdateRecords
            ? TaskCardItem.operations.compliance.lostProperty.createLostPropertyItem
            : undefined,
          primarySize: PagePrimarySize.full,
          hasMoreData: hasMoreData,
          rowKey: d => {
            return d.itemValue.id;
          },
          primaryActions: [
            {
              actions: [
                {
                  actionType: ActionType.actionCollection,
                  actionGroups: [
                    {
                      actions: [
                        {
                          actionType: ActionType.actionButton,
                          label: 'Export to Excel',
                          icon: <ExcelIcon fixedWidth />,
                          onClick: () => saveSpreadsheet(),
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
          primaryFields: [
            {
              fieldType: FieldType.textField,
              dataAddr: 'lostPropertyItemNumber',
              label: 'Number',
              linkTo: d => `/compliance/lost-property-items/${d.parentValue.id}`,
              orderBy: 'LostPropertyItemNumber',
            },
            {
              fieldType: FieldType.dateField,
              dataAddr: 'foundOn',
              label: 'Entered On',
              orderBy: 'FoundOn',
            },
            {
              fieldType: FieldType.selectMultiField,
              dataAddr: 'status',
              label: 'Status',
              valueKey: 'value',
              descriptionKey: 'description',
              optionItems: allLostPropertyItemStatus,
              useValueOnly: true,
            },
            {
              fieldType: FieldType.selectMultiField,
              dataAddr: 'lostOrFound',
              label: 'Lost/Found',
              valueKey: 'value',
              descriptionKey: 'description',
              optionItems: allLostOrFound,
              useValueOnly: true,
            },
            {
              fieldType: FieldType.textField,
              dataAddr: 'description',
              label: 'Description',
            },
            {
              fieldType: FieldType.textField,
              dataAddr: 'ownerName',
              label: 'Owner Name',
            },
          ],
          filterAction: {
            defaultValues: {
              status: LostPropertyItemStatus.OnHand,
            },
            filterFields: Object.keys(filterFieldDefsLookup).map(k => filterFieldDefsLookup[k]),
            filterDef: _ => [
              {
                panes: [
                  {
                    paneType: PaneType.formFieldsPane,
                    columnCount: 2,
                    fields: [filterFieldDefsLookup.dateFrom, filterFieldDefsLookup.dateTo],
                  },
                  {
                    paneType: PaneType.formFieldsPane,
                    columnCount: 1,
                    fields: [
                      filterFieldDefsLookup.status,
                      filterFieldDefsLookup.lostOrFound,
                      filterFieldDefsLookup.toBeDisposed,
                    ],
                  },
                ],
              },
            ],
          },
        };
      }
    );

    const def = getPageDef(lostPropertyModel.hasMoreItems);
    return <ListPage data={lostPropertyModel.items} def={def} />;
  }
);

export default withQueryParams(ListLostPropertyItem);
