import { DateTime } from 'luxon';
import Scheduler from './Scheduler/Scheduler';
import { SidePanel } from 'src/views/components/SidePanel';
import InspectionsDue from './InspectionsDue/InspectionsDue';
import React from 'react';
import { IPageDef, PagePrimarySize, PaneType } from 'src/views/definitionBuilders/types';
import Page from 'src/views/components/Page';
import { observer } from 'mobx-react';
import { useRootStore } from 'src/domain/entities/RootStoreModel';
import { TIMEZONE } from 'src/appSettings';

function getSearch(query: URLSearchParams, newMonth: string, depotId: number) {
  query.set('month', newMonth);
  query.set('depotId', depotId.toString());
  return query.toString();
}

const MachineryJobSchedule: React.FC = observer(() => {
  const rootStore = useRootStore();
  const jobsModel = rootStore.scheduledMachineryJobs;
  const workshopModel = rootStore.workshopStartup;
  const isLoading = jobsModel.jobsLoading;
  const zone = TIMEZONE;

  const getPageDef = (): IPageDef => {
    const query = new URLSearchParams(location.search);
    const queryMonth = query.get('month') || undefined;
    const selectedWorkshopDepotId = Number(query.get('depotId')) || 0;

    return {
      primarySize: PagePrimarySize.full,
      primarySection: {
        title: 'Machinery Schedule',
        subtitle:
          queryMonth &&
          `${DateTime.fromISO(queryMonth).monthLong} ${DateTime.fromISO(queryMonth).year}`,
        panels: [
          {
            panes: [
              {
                paneType: PaneType.customPane,
                render: () => {
                  return (
                    workshopModel.defaultWorkshopDepot && (
                      <div className="machinery-job-schedule">
                        <SidePanel heading="HV Inspections Due">
                          <InspectionsDue selectedWorkshopDepotId={selectedWorkshopDepotId} />
                        </SidePanel>
                        <Scheduler
                          monthToDisplay={queryMonth}
                          getNavigationLink={(monthToDisplay, depotId) => ({
                            ...location,
                            search: getSearch(query, monthToDisplay, depotId),
                          })}
                          showInZone={zone}
                          onMonthChanged={(newYear, newMonth, newDepotId) => {
                            jobsModel.loadJobs(newYear, newMonth, zone, newDepotId);
                          }}
                          workshopDepots={workshopModel.workshopDepots.slice()}
                          defaultWorkshopDepot={workshopModel.defaultWorkshopDepot}
                          selectedWorkshopDepotId={selectedWorkshopDepotId}
                          nowUtc={DateTime.utc()}
                          jobsByZonedDay={new Map(jobsModel.jobsByZonedDay(zone))}
                          jobsLoading={isLoading}
                          changeSelectedWorkshopDepotId={jobsModel.changeSelectedWorkshopDepotId}
                        />
                      </div>
                    )
                  );
                },
              },
            ],
          },
        ],
      },
    };
  };

  return <Page def={getPageDef()} />;
});

export default MachineryJobSchedule;
