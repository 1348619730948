import { DateTime } from 'luxon';
import { TIME_24_SIMPLE_HOURCYCLE_23 } from 'src/infrastructure/dateUtils';
import { getTimezoneCommonName } from 'src/views/components/DateTimeFormat/TimezoneFormat';

export function formatTime(datetime: string | undefined, timezone: string) {
  if (!datetime) {
    return '';
  }
  const dt = DateTime.fromISO(datetime, { zone: timezone || 'local' });
  return `${dt.toLocaleString(TIME_24_SIMPLE_HOURCYCLE_23)} ${getTimezoneCommonName(dt, true)}`;
}
