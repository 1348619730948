import { types, flow } from 'mobx-state-tree';
import { getAjax } from 'src/domain/services/storeEnvironment';
type PrefilledTimesheetItem = Common.Dtos.PrefilledTimesheetItem;
type GetPrefilledTimesheetQuery = Common.Queries.Operations.GetPrefilledTimesheet.GetPrefilledTimesheetQuery;

export const PrefilledTimesheetItemModel = types
  .model('PrefilledTimesheetItemModel', {
    prefilledTimesheet: types.maybe(types.frozen<PrefilledTimesheetItem>()),
  })
  .actions(self => {
    const ajax = getAjax(self);

    const getPrefilledTimesheet = (query: GetPrefilledTimesheetQuery) =>
      flow(function*() {
        const timesheet: PrefilledTimesheetItem = yield ajax.operations.jobs.getPrefilledTimesheet(
          query
        );
        self.prefilledTimesheet = timesheet;
      })();

    return {
      getPrefilledTimesheet,
    };
  });
