import { IAjax } from 'src/infrastructure/ajax';
import { operationsUrls } from 'src/domain/services/apiUrls';

export default function init(ajax: IAjax) {
  function createRoster(
    command: Operations.Domain.Commands.Roster.CreateRoster.CreateRosterCommand
  ) {
    return ajax
      .post(operationsUrls.rosterUrls.createRoster(), command)
      .toPromise()
      .then<string>(r => r.response);
  }

  function checkForUniqueRosterNumber(rosterNumber: string) {
    return ajax
      .get(operationsUrls.rosterUrls.checkForUniqueRosterNumber(rosterNumber))
      .toPromise()
      .then<Common.Dtos.UniqueNameCheckResultDto>(r => r.response);
  }

  function updateRoster(
    command: Operations.Domain.Commands.Roster.UpdateRoster.UpdateRosterCommand
  ) {
    return ajax.put(operationsUrls.rosterUrls.updateRoster(command.id), command).toPromise();
  }

  function viewRoster(rosterId: string) {
    return ajax
      .get(operationsUrls.rosterUrls.viewRoster(rosterId))
      .toPromise()
      .then<Operations.Domain.Queries.ViewRoster.RosterItem>(r => r.response);
  }

  function listRosters() {
    return ajax
      .get(operationsUrls.rosterUrls.listRosters())
      .toPromise()
      .then(r => r.response as Operations.Domain.Queries.ListRosters.RosterListItem[]);
  }

  function deleteRoster(rosterId: string) {
    return ajax.httpDelete(operationsUrls.rosterUrls.deleteRoster(rosterId)).toPromise();
  }

  function listStaffMembersForRotatingRosters(startDate: string) {
    return ajax
      .get(operationsUrls.rosterUrls.listRotatingRosterStaffMembers(startDate))
      .toPromise()
      .then<Operations.Domain.Queries.ListRotatingRosterStaffMembers.RotatingRosterListItem>(
        r => r.response
      );
  }

  return {
    rosters: {
      createRoster,
      checkForUniqueRosterNumber,
      updateRoster,
      viewRoster,
      listRosters,
      deleteRoster,
      listStaffMembersForRotatingRosters,
    },
  };
}
