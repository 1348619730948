import { types, flow } from 'mobx-state-tree';
import { getAjax } from 'src/domain/services';
import { TechSpecsForRequirementsModel } from './TechSpecsForRequirementsModel';
import { EditableTechSpecDropdownOptionsModel } from './EditableTechSpecDropdownOptionsModel';

type TechSpecItem = Workshop.Domain.Queries.TechSpecs.TechSpecItem;
type UpdateTechSpecCommand = Workshop.Domain.Commands.TechSpecs.UpdateTechSpecsCommand;
type TechSpecDropdownOptionsItem = Workshop.Domain.Queries.TechSpecs.TechSpecDropdownOptionsItem;
type GetTechSpecDropdownOptionsQuery = Workshop.Domain.Queries.TechSpecs.GetTechSpecDropdownOptions.GetTechSpecDropdownOptionsQuery;
type UniqueNameCheckResultDto = Common.Dtos.UniqueNameCheckResultDto;

export const TechSpecsModel = types
  .model('TechSpecsModel', {
    items: types.array(types.frozen<TechSpecItem>()),
    forRequirements: types.optional(TechSpecsForRequirementsModel, {}),
    techSpecsDropdownOptions: types.array(types.frozen<TechSpecDropdownOptionsItem>()),
    editableDropdownOptions: types.optional(EditableTechSpecDropdownOptionsModel, {}),
  })
  .views(self => ({
    get forOpsItems() {
      return self.items.filter(i => i.isForOperations);
    },
  }))
  .actions(self => {
    const ajax = getAjax(self);

    const getAll = flow(function*() {
      self.items = yield ajax.workshop.techSpecs.getAll();
    });

    const updateAll = flow(function*(cmd: UpdateTechSpecCommand) {
      yield ajax.workshop.techSpecs.updateAll(cmd);
      yield getAll();
    });

    const clearTechSpecRequirements = flow(function*(techSpecId: number) {
      yield ajax.operations.requirements.clearTechSpecRequirements(techSpecId);
      yield getAll();
    });

    const checkForUniqueTechSpecName = flow(function*(techspecName: string) {
      const dto: UniqueNameCheckResultDto = yield ajax.workshop.techSpecs.checkForUniqueTechSpecName(
        techspecName
      );
      return dto;
    });

    return {
      getAll,
      updateAll,
      clearTechSpecRequirements,
      checkForUniqueTechSpecName,
    };
  })
  .actions(self => {
    const ajax = getAjax(self);

    const getTechSpecDropdownsOptions = flow(function*(
      query?: Partial<GetTechSpecDropdownOptionsQuery>
    ) {
      self.techSpecsDropdownOptions = yield ajax.workshop.techSpecs.getDropdownOptions(query || {});
    });

    return {
      getTechSpecDropdownsOptions,
    };
  })
  .views(self => ({
    techSpecDropdownOptions(techSpecId: number) {
      const techSpecDropdown = self.techSpecsDropdownOptions.find(x => x.techSpecId === techSpecId);
      if (techSpecDropdown === undefined) {
        return [];
      }
      return techSpecDropdown.options;
    },
  }));
