import { PaneType, ITabDef, PaneDefs, SectionDefs } from 'src/views/definitionBuilders/types';
import { Link } from 'react-router-dom';
import { PartType } from 'src/api/enums';

type PartUsage = Workshop.Domain.Queries.Parts.GetPartUsage.PartUsage;
type PartItem = Workshop.Domain.Queries.Parts.PartItem;

export default function getCurrentUsageTabDef(partUsage?: PartUsage, part?: PartItem): SectionDefs {
  const partOrService = part && part.partType.id === PartType.Service ? 'service' : 'part';
  return {
    title: 'Current Usage',
    explicitData: partUsage,
    panels: [
      {
        panes: [
          {
            paneType: PaneType.customPane,
            render: d =>
              partUsage && partUsage.canBeDeleted ? (
                <p>This {partOrService} has never been used and can be deleted.</p>
              ) : partUsage && partUsage.canBeMadeInactive ? (
                <p>
                  This {partOrService} has historic usage and so cannot be deleted, but it is not
                  currently in use and can be marked as inactive.
                </p>
              ) : (
                <p>
                  This {partOrService} is currently being used in the following areas across the
                  application.
                </p>
              ),
          } as PaneDefs,
          !partUsage || !partUsage.canBeMadeInactive
            ? {
                paneType: PaneType.tabPane,
                tabs: [
                  partUsage &&
                  partUsage.futureAndScheduledTasks &&
                  partUsage.futureAndScheduledTasks.length > 0
                    ? {
                        title: `Future & Scheduled Tasks`,
                        panes: [
                          {
                            paneType: PaneType.customPane,
                            render: api => (
                              <p>
                                This {partOrService} is currently being used in the following future
                                or scheduled tasks.
                              </p>
                            ),
                          },
                          {
                            paneType: PaneType.repeatingPane,
                            useHover: false,
                            dataAddr: 'futureAndScheduledTasks',
                            itemPanes: [
                              {
                                paneType: PaneType.customPane,
                                render: api => (
                                  <Link to={`/workshop/tasks/${api.data.paneValue.id}`}>
                                    {api.data.paneValue.description}
                                  </Link>
                                ),
                              },
                            ],
                          },
                        ] as PaneDefs[],
                      }
                    : undefined,
                  partUsage &&
                  partUsage.currentPurchaseOrders &&
                  partUsage.currentPurchaseOrders.length > 0
                    ? {
                        title: `Purchase Orders`,
                        panes: [
                          {
                            paneType: PaneType.customPane,
                            render: api => (
                              <p>
                                This {partOrService} is currently being referenced in the following
                                open purchase orders.
                              </p>
                            ),
                          },
                          {
                            paneType: PaneType.repeatingPane,
                            useHover: false,
                            dataAddr: 'currentPurchaseOrders',
                            itemPanes: [
                              {
                                paneType: PaneType.customPane,
                                render: api => (
                                  <Link to={`/workshop/purchase-orders/${api.data.paneValue.id}`}>
                                    {api.data.paneValue.description}
                                  </Link>
                                ),
                              },
                            ],
                          },
                        ] as PaneDefs[],
                      }
                    : undefined,
                  partUsage && partUsage.partsGroups && partUsage.partsGroups.length > 0
                    ? {
                        title: `Parts Groups`,
                        panes: [
                          {
                            paneType: PaneType.customPane,
                            render: api => (
                              <p>
                                This {partOrService} is currently being used in the following parts
                                groups.
                              </p>
                            ),
                          },
                          {
                            paneType: PaneType.repeatingPane,
                            useHover: false,
                            dataAddr: 'partsGroups',
                            itemPanes: [
                              {
                                paneType: PaneType.customPane,
                                render: api => (
                                  <Link to={`/workshop/parts-groups/${api.data.paneValue.id}`}>
                                    {api.data.paneValue.description}
                                  </Link>
                                ),
                              },
                            ],
                          },
                        ] as PaneDefs[],
                      }
                    : undefined,
                  partUsage && partUsage.checklists && partUsage.checklists.length > 0
                    ? {
                        title: `Checklists`,
                        panes: [
                          {
                            paneType: PaneType.customPane,
                            render: api => (
                              <p>
                                This {partOrService} is currently being used in the following
                                checklists/sub-checklists.
                              </p>
                            ),
                          },
                          {
                            paneType: PaneType.repeatingPane,
                            useHover: false,
                            dataAddr: 'checklists',
                            itemPanes: [
                              {
                                paneType: PaneType.customPane,
                                render: api => (
                                  <Link to={`/workshop/checklists/${api.data.paneValue.id}`}>
                                    {api.data.paneValue.description}
                                  </Link>
                                ),
                              },
                            ],
                          },
                        ] as PaneDefs[],
                      }
                    : undefined,
                  partUsage && partUsage.assetPartsAssets && partUsage.assetPartsAssets.length > 0
                    ? {
                        title: `Asset Part Registers`,
                        panes: [
                          {
                            paneType: PaneType.customPane,
                            render: api => (
                              <p>
                                This {partOrService} is currently being used in the following asset
                                part registers.
                              </p>
                            ),
                          },
                          {
                            paneType: PaneType.repeatingPane,
                            useHover: false,
                            dataAddr: 'assetPartsAssets',
                            itemPanes: [
                              {
                                paneType: PaneType.customPane,
                                render: api => (
                                  <Link to={`/workshop/assets/${api.data.paneValue.id}/parts`}>
                                    {api.data.paneValue.description}
                                  </Link>
                                ),
                              },
                            ],
                          },
                        ] as PaneDefs[],
                      }
                    : undefined,
                  partUsage &&
                  partUsage.assetComponentAssets &&
                  partUsage.assetComponentAssets.length > 0
                    ? {
                        title: `Asset Component Registers`,
                        panes: [
                          {
                            paneType: PaneType.customPane,
                            render: api => (
                              <p>
                                This {partOrService} is currently being used in the following asset
                                component registers.
                              </p>
                            ),
                          },
                          {
                            paneType: PaneType.repeatingPane,
                            useHover: false,
                            dataAddr: 'assetComponentAssets',
                            itemPanes: [
                              {
                                paneType: PaneType.customPane,
                                render: api => (
                                  <Link to={`/workshop/assets/${api.data.paneValue.id}/components`}>
                                    {api.data.paneValue.description}
                                  </Link>
                                ),
                              },
                            ],
                          },
                        ] as PaneDefs[],
                      }
                    : undefined,
                ].filter(d => d !== undefined) as ITabDef[],
              }
            : undefined,
        ].filter(d => d !== undefined) as PaneDefs[],
      },
    ],
  };
}
