import * as luxon from 'luxon';
import { ILogger } from './logging';

// Handle any configuration for Luxor
// Currently we only polyfill timezone support because we need it
// Intl can be added later if/when required

async function polyfillIfRequired(log: ILogger) {
  const luxonFeatures = luxon.Info.features();

  // polyfill timezones if required
  // @ts-ignore typings currently incorrect for "zones"
  if (!luxonFeatures.zones) {
    log.debug('Polyfilling timezones');
    await import('date-time-format-timezone');
    log.info('Polyfilled timezones');
  }
}

async function configureLuxon(log: ILogger) {
  // Not sure why this is required, but luxon is using en-GB without it?
  luxon.Settings.defaultLocale = window.navigator.language;

  await polyfillIfRequired(log);

  // @ts-ignore
  if (!luxon.Info.features().zones) {
    throw new Error(
      'The system was unable to install the required features to support timezones in this browser.'
    );
  }
}

export default configureLuxon;
