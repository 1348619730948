import { Component } from 'react';
import { History } from 'history';
import { RouteComponentProps } from 'react-router-dom';

interface IHistoryInjectorProps extends RouteComponentProps<{}> {
  setHistory: (h: History) => void;
}

class HistoryInjector extends Component<IHistoryInjectorProps> {
  componentDidMount() {
    this.props.setHistory(this.props.history);
  }
  render() {
    return null;
  }
}

export default HistoryInjector;
