import { IAjax } from 'src/infrastructure/ajax';
import { peopleUrls } from 'src/domain/services/apiUrls';

export default function init(ajax: IAjax) {
  function getEmploymentContractCodes() {
    return ajax
      .get(peopleUrls.staffMemberUrls.employmentContractCodes.getAll())
      .toPromise()
      .then<Common.Dtos.EmploymentContractCodeDto[]>(r => r.response);
  }

  return {
    employmentContractCodes: {
      getEmploymentContractCodes,
    },
  };
}
