import { useRef } from 'react';
import { UncontrolledTooltip } from 'reactstrap';
import { CircleQuestionIcon } from 'src/images/icons';
import styles from './FieldInfoTooltip.module.scss';

export const FieldInfoTooltip: React.FunctionComponent<{
  className?: string;
  icon?: React.ReactElement | undefined;
}> = ({ children, className, icon }) => {
  const ref = useRef(null);

  return (
    <span className={className}>
      <span ref={ref}>{icon || <CircleQuestionIcon />}</span>
      <UncontrolledTooltip
        autohide={false}
        target={ref}
        className={styles.tooltipMain}
        arrowClassName={styles.tooltipArrow}
        innerClassName={styles.tooltipInner}>
        {children}
      </UncontrolledTooltip>
    </span>
  );
};
