import { IRootStoreModel } from 'src/domain/entities/RootStoreModel';
import { UiRoute } from 'src/domain/services/buildUiRoutes';
import { UiRouteName } from 'src/domain/baseTypes';

export default function buildUiRoutes(store: IRootStoreModel) {
  const pageLayout = new UiRoute(
    UiRouteName.documentationPageLayout,
    '/documentation/page-layout',
    () => 'Page Layout',
    true
  );

  const listPage = new UiRoute(
    UiRouteName.documentationListPage,
    '/documentation/list-page',
    () => 'List Page',
    true
  );

  const crudPage = new UiRoute(
    UiRouteName.documentationCrudPage,
    '/documentation/crud-page',
    () => 'Crud Page',
    true
  );

  const forms = new UiRoute(
    UiRouteName.documentationForms,
    '/documentation/forms',
    () => 'Forms',
    true
  );

  const actions = new UiRoute(
    UiRouteName.documentationActions,
    '/documentation/actions',
    () => 'Actions',
    true
  );

  const modals = new UiRoute(
    UiRouteName.documentationModals,
    '/documentation/modals',
    () => 'Modals',
    true
  );

  return {
    [UiRouteName.documentationPageLayout]: pageLayout,
    [UiRouteName.documentationListPage]: listPage,
    [UiRouteName.documentationCrudPage]: crudPage,
    [UiRouteName.documentationForms]: forms,
    [UiRouteName.documentationActions]: actions,
    [UiRouteName.documentationModals]: modals,
  };
}
