import inject from 'src/views/injectFromStore';
import ProcessResultsFile, { IProcessResultsFileProps } from './ProcessResultsFile';

const Container = inject<IProcessResultsFileProps>((allStores, p) => ({
  createBulkDriverAuthorizationUpdateOperation:
    allStores.rootStore.operations.bulkDriverAuthorizationUpdateOperation
      .createBulkDriverAuthorizationUpdateOperation,
}))(ProcessResultsFile);

export default Container;
