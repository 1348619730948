import { FieldType, PagePrimarySize } from 'src/views/definitionBuilders/types';
import { ListPage } from 'src/views/components/Page';
import { TaskCardItem } from 'src/views/components/TaskCard';
import { IListPageDef } from 'src/views/components/Page/pages/ListPage';
import PrimaryTitle from 'src/views/components/Page/PrimaryTitle/PrimaryTitle';

export interface IListPartsGroupsProps {
  canManagePartsGroups: boolean;
  onListPartsGroups: (includeInactivePartsGroups: boolean) => Promise<void>;
  partsGroups: Workshop.Domain.Queries.GetPartsGroups.PartsGroupItem[];
}

function getPageDef(props: IListPartsGroupsProps): IListPageDef {
  return {
    primaryTitle: (
      <PrimaryTitle
        title="Parts Groups"
        link="https://www.notion.so/Parts-Groups-9fdb90e699fb495eb2e2b582b03cd1c3"></PrimaryTitle>
    ),
    primarySize: PagePrimarySize.full,
    onLoadData: () => props.onListPartsGroups(true),
    createLink: props.canManagePartsGroups ? TaskCardItem.createPartsGroup : undefined,
    primaryFields: [
      {
        fieldType: FieldType.textField,
        dataAddr: 'name',
        label: 'Name',
        linkTo: d => `/workshop/parts-groups/${d.parentValue.id}`,
      },
      {
        fieldType: FieldType.textField,
        dataAddr: 'description',
        label: 'Description',
      },
      {
        fieldType: FieldType.textField,
        dataAddr: 'active',
        label: 'Active',
        formatReadonly: data => (data.fieldValue ? 'Yes' : 'No'),
      },
    ],
  };
}

const ListPartsGroups: React.FC<IListPartsGroupsProps> = (props: IListPartsGroupsProps) => {
  return (
    <ListPage
      className="list-parts-groups-component"
      data={props.partsGroups}
      def={getPageDef(props)}
    />
  );
};

export default ListPartsGroups;
