import { types, flow } from 'mobx-state-tree';
import { getAjax } from 'src/domain/services';
import { workshopUrls } from 'src/domain/services/apiUrls';

type TechSpecItem = Common.Dtos.TechSpecItem;

export const TechSpecsForRequirementsModel = types
  .model('TechSpecsForRequirementsModel', {
    items: types.array(types.frozen<TechSpecItem>()),
  })
  .actions(self => {
    const ajax = getAjax(self);

    const getAll = flow(function*() {
      self.items = yield ajax.raw
        .get(workshopUrls.techSpecUrls.getAllForRequirements())
        .toPromise()
        .then(r => r.response);
    });

    return {
      getAll,
    };
  });
