import { allWeekdayItems } from 'src/domain';
import { IFieldApi } from 'src/views/components/Page/forms/Field';
import {
  IFieldMeta,
  IFieldData,
  FieldType,
  IToggleMultiButtonFieldDef,
  IDaysOfWeekFieldDef,
} from 'src/views/definitionBuilders/types';
import ToggleMultiButtonPageField from './ToggleMultiButtonPageField';

interface IDaysOfWeekFieldProps {
  fieldApi: IFieldApi;
  fieldDef: IDaysOfWeekFieldDef;
  fieldMeta: IFieldMeta;
  // tslint:disable-next-line:no-any
  fieldData: IFieldData<any[]>;
}

const DaysOfWeekField: React.FC<IDaysOfWeekFieldProps> = props => {
  const { fieldDef: def } = props;
  const toggleMultiButtonFieldDef: IToggleMultiButtonFieldDef = {
    ...def,
    fieldType: FieldType.toggleMultiButtonField,
    valueKey: 'day',
    descriptionKey: 'name',
    optionItems: allWeekdayItems,
  };

  return <ToggleMultiButtonPageField {...props} fieldDef={toggleMultiButtonFieldDef} />;
};

export default DaysOfWeekField;
