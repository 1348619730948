import { WarnIcon } from 'src/images/icons';

interface IWorkshopShiftConflictIconProps {
  hasConflicts: boolean;
}

const WorkshopShiftConflictIcon: React.FC<IWorkshopShiftConflictIconProps> = ({ hasConflicts }) =>
  hasConflicts ? <WarnIcon color="red" title={'Unaccepted conflict'} /> : <></>;

export default WorkshopShiftConflictIcon;
