import { Component } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { DateTime } from 'luxon';
import {
  PagePrimarySize,
  PaneType,
  FieldType,
  ActionType,
} from 'src/views/definitionBuilders/types';
import CrudPage, { CrudPageMode, ICrudPageDef } from 'src/views/components/Page/pages/CrudPage';
import { StaffMemberFilter } from 'src/views/components/Page/fields/StaffMemberField';
import {
  allIncidentSeen,
  allIncidentType,
  allBehaviourBreachCategory,
  IncidentType,
  ChangeState,
} from 'src/api/enums';
import { standardTextSanitisers } from 'src/views/componentBuilders';

type CodeOfConductItem = Operations.Domain.Queries.ViewCodeOfConduct.CodeOfConductItem;
type CreateCodeOfConductCommand = Operations.Domain.Commands.CodeOfConduct.CreateCodeOfConduct.CreateCodeOfConductCommand;
type UpdateCodeOfConductCommand = Operations.Domain.Commands.CodeOfConduct.UpdateCodeOfConduct.UpdateCodeOfConductCommand;
type AssetItem = Common.Queries.Workshop.GetSimpleFleetAssetList.SimpleAssetItem;

export interface IMaintainCodeOfConductProps {
  mode: CrudPageMode;
  canManageCodeOfConduct: boolean;

  loadCodeOfConduct: (id: string) => Promise<void>;
  codeOfConduct: CodeOfConductItem | undefined;
  createCodeOfConduct: (command: CreateCodeOfConductCommand) => Promise<void>;
  updateCodeOfConduct: (command: UpdateCodeOfConductCommand) => Promise<void>;
  loadStaffMembers: () => Promise<void>;
  assets: AssetItem[];
  loadAssetListItems: () => Promise<void>;
}

interface IMaintainCodeOfConductParams {
  id: string;
}

type InternalProps = IMaintainCodeOfConductProps &
  RouteComponentProps<IMaintainCodeOfConductParams>;

class MaintainCodeOfConduct extends Component<InternalProps> {
  componentDidMount() {
    this.props.loadStaffMembers();
    this.props.loadAssetListItems();
  }

  private get isUpdateMode() {
    return this.props.mode === 'update';
  }

  private get CodeOfConductId() {
    return this.props.match.params.id;
  }

  private handlePreSubmitForCreate = (item: CodeOfConductItem): CreateCodeOfConductCommand => {
    return {
      anybodyHarmed: item.anybodyHarmed,
      assetId: item.asset.id,
      behaviourAgreement: item.behaviourAgreement,
      behaviourBreachCategory: item.behaviourBreachCategory,
      cautionLetter: item.cautionLetter,
      cctvAvailable: item.cctvAvailable,
      cctvRequired: item.cctvRequired,
      contactedGuardian: item.contactedGuardian,
      contactedOperations: item.contactedOperations,
      driverActions: item.driverActions,
      driverFollowUp: item.driverFollowUp,
      emergencyServices: item.emergencyServices,
      guardianName: item.guardianName,
      guardianPhoneNumber: item.guardianPhoneNumber,
      incidentDateTime: item.incidentDateTime,
      incidentDetails: item.incidentDetails,
      incidentLocation: item.incidentLocation,
      incidentReportedToDriver: item.incidentReportedToDriver,
      incidentSeen: item.incidentSeen,
      incidentTypeOther: item.incidentTypeOther,
      incidentTypes: item.incidentTypes,
      investigation: item.investigation,
      issuedWarning: item.issuedWarning,
      notes: item.notes,
      notificationLetter: item.notificationLetter,
      refusedDays: item.refusedDays,
      refusedTravelDateFrom: item.refusedTravelDateFrom,
      refusedTravelDateTo: item.refusedTravelDateTo,
      refusedTravelLetter: item.refusedTravelLetter,
      returnedDate: item.returnedDate,
      schoolAdvised: item.schoolAdvised,
      schoolContactName: item.schoolContactName,
      schoolContactPhoneNumber: item.schoolContactPhoneNumber,
      staffMemberId: item.staffMember.id,
      students: item.students,
      witnesses: item.witnesses,
      reporters: item.reporters,
    };
  };

  private handlePreSubmitForUpdate = (item: CodeOfConductItem): UpdateCodeOfConductCommand => {
    return {
      id: this.CodeOfConductId,
      anybodyHarmed: item.anybodyHarmed,
      assetId: item.asset.id,
      behaviourAgreement: item.behaviourAgreement,
      behaviourBreachCategory: item.behaviourBreachCategory,
      cautionLetter: item.cautionLetter,
      cctvAvailable: item.cctvAvailable,
      cctvRequired: item.cctvRequired,
      contactedGuardian: item.contactedGuardian,
      contactedOperations: item.contactedOperations,
      driverActions: item.driverActions,
      driverFollowUp: item.driverFollowUp,
      emergencyServices: item.emergencyServices,
      guardianName: item.guardianName,
      guardianPhoneNumber: item.guardianPhoneNumber,
      incidentDateTime: item.incidentDateTime,
      incidentDetails: item.incidentDetails,
      incidentLocation: item.incidentLocation,
      incidentReportedToDriver: item.incidentReportedToDriver,
      incidentSeen: item.incidentSeen,
      incidentTypeOther: item.incidentTypeOther,
      incidentTypes: item.incidentTypes,
      investigation: item.investigation,
      issuedWarning: item.issuedWarning,
      notes: item.notes,
      notificationLetter: item.notificationLetter,
      refusedDays: item.refusedDays,
      refusedTravelDateFrom: item.refusedTravelDateFrom,
      refusedTravelDateTo: item.refusedTravelDateTo,
      refusedTravelLetter: item.refusedTravelLetter,
      returnedDate: item.returnedDate,
      schoolAdvised: item.schoolAdvised,
      schoolContactName: item.schoolContactName,
      schoolContactPhoneNumber: item.schoolContactPhoneNumber,
      staffMemberId: item.staffMember.id,
      students: item.students.filter(x => {
        return x.changeState !== ChangeState.Deleted;
      }),
      witnesses: item.witnesses.filter(x => {
        return x.changeState !== ChangeState.Deleted;
      }),
      reporters: item.reporters.filter(x => {
        return x.changeState !== ChangeState.Deleted;
      }),
      actionTaken: item.actionTaken,
      filedAs: item.filedAs,
    };
  };

  private readonly validateDateTimeIsNotLessThan = (
    first: string,
    firstLabel: string,
    second: string,
    secondLabel: string
  ) => {
    return !!first && !!second && DateTime.fromISO(first) < DateTime.fromISO(second)
      ? `${firstLabel} cannot be less than ${secondLabel}`
      : undefined;
  };

  private readonly getPageDef = (updating: boolean): ICrudPageDef => {
    return {
      primarySize: PagePrimarySize.threeQuarters,
      primarySection: {
        title: this.isUpdateMode
          ? `Code Of Conduct ${this.props.codeOfConduct &&
              this.props.codeOfConduct.codeOfConductNumber}`
          : 'Record a Code Of Conduct Incident',
        panels: [
          {
            panes: [
              {
                paneType: PaneType.formFieldsPane,
                columnCount: 2,
                fields: [
                  {
                    fieldType: FieldType.staffMemberField,
                    label: 'Staff Member',
                    dataAddr: 'staffMember',
                    mandatory: true,
                    staffMemberFilter: d => StaffMemberFilter.hasDriversAuthorisation,
                  },
                  {
                    fieldType: FieldType.assetSelectField,
                    label: 'Vehicle',
                    dataAddr: 'asset',
                    valueKey: 'id',
                    descriptionKey: 'name',
                    mandatory: true,
                    optionItems: this.props.assets,
                  },
                  {
                    fieldType: FieldType.dateTimeField,
                    label: 'Incident Date And Time',
                    dataAddr: 'incidentDateTime',
                    mandatory: true,
                  },
                  {
                    fieldType: FieldType.textField,
                    label: 'Location Of Incident',
                    dataAddr: 'incidentLocation',
                    mandatory: true,
                    maxLength: 200,
                  },
                ],
              },
            ],
          },
          {
            title: 'Students',
            dataAddr: 'students',
            panes: [
              {
                paneType: PaneType.tablePane,
                mandatory: true,
                dataRequiredForRows: 'sectionValue',
                fields: [
                  {
                    fieldType: FieldType.textField,
                    dataAddr: 'name',
                    label: 'Student Involved',
                    mandatory: true,
                    maxLength: 200,
                  },
                  {
                    fieldType: FieldType.textField,
                    dataAddr: 'school',
                    label: "Student's School",
                    mandatory: true,
                    maxLength: 200,
                  },
                  {
                    fieldType: FieldType.textField,
                    dataAddr: 'year',
                    label: 'Year',
                    maxLength: 20,
                  },
                  {
                    fieldType: FieldType.textField,
                    dataAddr: 'schoolService',
                    label: 'School Service',
                    mandatory: true,
                    maxLength: 20,
                  },
                  {
                    fieldType: FieldType.actionListField,
                    dataAddr: '',
                    columnWidth: '1px',
                    actionGroups: [
                      {
                        actions: [
                          {
                            hidden: d => this.isUpdateMode && !updating,
                            actionType: ActionType.removeArrayItemActionButton,
                            label: 'Remove Line',
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
              {
                paneType: PaneType.actionListPane,
                hidden: this.isUpdateMode && !updating,
                actionGroups: [
                  {
                    actions: [
                      {
                        actionType: ActionType.addArrayItemActionButton,
                        label: 'Add Student',
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            panes: [
              {
                paneType: PaneType.formFieldsPane,
                columnCount: 2,
                fields: [
                  {
                    fieldType: FieldType.selectField,
                    label: 'How Much Of The Incident Did You See?',
                    dataAddr: 'incidentSeen',
                    valueKey: 'value',
                    descriptionKey: 'description',
                    mandatory: true,
                    useValueOnly: true,
                    optionItems: allIncidentSeen,
                  },
                  {
                    fieldType: FieldType.yesNoField,
                    label: 'Was The Incident Reported To You?',
                    dataAddr: 'incidentReportedToDriver',
                    mandatory: true,
                  },
                ],
              },
            ],
          },
          {
            title: 'Reporters',
            dataAddr: 'reporters',
            hidden: d => {
              return !d.sectionValue || !d.sectionValue.incidentReportedToDriver;
            },
            panes: [
              {
                paneType: PaneType.tablePane,
                mandatory: true,
                dataRequiredForRows: 'sectionValue',
                fields: [
                  {
                    fieldType: FieldType.textField,
                    dataAddr: 'name',
                    label: 'Name Of Student',
                    mandatory: true,
                    maxLength: 200,
                  },
                  {
                    fieldType: FieldType.textField,
                    dataAddr: 'school',
                    label: "Student's School",
                    maxLength: 200,
                  },
                  {
                    fieldType: FieldType.actionListField,
                    dataAddr: '',
                    columnWidth: '1px',
                    actionGroups: [
                      {
                        actions: [
                          {
                            hidden: d => this.isUpdateMode && !updating,
                            actionType: ActionType.removeArrayItemActionButton,
                            label: 'Remove Line',
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
              {
                paneType: PaneType.actionListPane,
                hidden: this.isUpdateMode && !updating,
                actionGroups: [
                  {
                    actions: [
                      {
                        actionType: ActionType.addArrayItemActionButton,
                        label: 'Add Reporter',
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            title: 'Witnesses',
            dataAddr: 'witnesses',
            hidden: d => {
              return !d.sectionValue || !d.sectionValue.incidentReportedToDriver;
            },
            panes: [
              {
                paneType: PaneType.tablePane,
                dataRequiredForRows: 'sectionValue',
                fields: [
                  {
                    fieldType: FieldType.textField,
                    dataAddr: 'name',
                    label: 'Name Of Student',
                    mandatory: true,
                    maxLength: 200,
                  },
                  {
                    fieldType: FieldType.textField,
                    dataAddr: 'school',
                    label: "Student's School",
                    maxLength: 200,
                  },
                  {
                    fieldType: FieldType.actionListField,
                    dataAddr: '',
                    columnWidth: '1px',
                    actionGroups: [
                      {
                        actions: [
                          {
                            hidden: d => this.isUpdateMode && !updating,
                            actionType: ActionType.removeArrayItemActionButton,
                            label: 'Remove Line',
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
              {
                paneType: PaneType.actionListPane,
                hidden: this.isUpdateMode && !updating,
                actionGroups: [
                  {
                    actions: [
                      {
                        actionType: ActionType.addArrayItemActionButton,
                        label: 'Add Witness',
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            panes: [
              {
                paneType: PaneType.formFieldsPane,
                fields: [
                  {
                    fieldType: FieldType.selectMultiField,
                    label: 'Which Best Describes The Incident?',
                    dataAddr: 'incidentTypes',
                    valueKey: 'value',
                    descriptionKey: 'description',
                    optionItems: allIncidentType,
                    useValueOnly: true,
                    mandatory: true,
                  },
                  {
                    fieldType: FieldType.textField,
                    label: 'Other',
                    dataAddr: 'incidentTypeOther',
                    mandatory: true,
                    hidden: d =>
                      d.parentValue.incidentTypes &&
                      d.parentValue.incidentTypes.indexOf(IncidentType.Other) === -1,
                    maxLength: 200,
                  },
                ],
              },
              {
                paneType: PaneType.formFieldsPane,
                columnCount: 2,
                fields: [
                  {
                    fieldType: FieldType.yesNoField,
                    label: 'Did You Contact Operations Regarding This Matter?',
                    dataAddr: 'contactedOperations',
                    mandatory: true,
                  },
                  {
                    fieldType: FieldType.yesNoField,
                    label: 'Was Anybody Harmed?',
                    dataAddr: 'anybodyHarmed',
                    mandatory: true,
                  },
                  {
                    fieldType: FieldType.yesNoField,
                    label: 'Did You Report This Matter To Emergency Services?',
                    dataAddr: 'emergencyServices',
                    mandatory: true,
                  },
                  {
                    fieldType: FieldType.yesNoField,
                    label: 'Did You Issue A Warning To The Student?',
                    dataAddr: 'issuedWarning',
                    mandatory: true,
                  },
                ],
              },
              {
                paneType: PaneType.formFieldsPane,
                fields: [
                  {
                    fieldType: FieldType.textAreaField,
                    label: 'Details Of The Incident',
                    dataAddr: 'incidentDetails',
                    mandatory: true,
                  },
                  {
                    fieldType: FieldType.textAreaField,
                    label: "Driver's Actions",
                    dataAddr: 'driverActions',
                    mandatory: true,
                  },
                ],
              },
            ],
          },
          {
            title: 'Investigation Details',
            panes: [
              {
                paneType: PaneType.formFieldsPane,
                columnCount: 2,
                fields: [
                  {
                    fieldType: FieldType.yesNoField,
                    label: 'Was CCTV Footage Available?',
                    dataAddr: 'cctvAvailable',
                  },
                  {
                    fieldType: FieldType.yesNoField,
                    label: 'Was CCTV Footage Required?',
                    dataAddr: 'cctvRequired',
                  },
                  {
                    fieldType: FieldType.yesNoField,
                    label: 'Follow Up With Driver',
                    dataAddr: 'driverFollowUp',
                  },
                  {
                    fieldType: FieldType.yesNoField,
                    label: 'Contact Parents/Carer',
                    dataAddr: 'contactedGuardian',
                  },
                  {
                    fieldType: FieldType.yesNoField,
                    label: 'Investigation',
                    dataAddr: 'investigation',
                  },
                  {
                    fieldType: FieldType.yesNoField,
                    label: 'Caution Letter',
                    dataAddr: 'cautionLetter',
                  },
                  {
                    fieldType: FieldType.yesNoField,
                    label: 'Behaviour Agreement Developed',
                    dataAddr: 'behaviourAgreement',
                  },
                  {
                    fieldType: FieldType.yesNoField,
                    label: 'Refused Travel Letter',
                    dataAddr: 'refusedTravelLetter',
                  },
                  {
                    fieldType: FieldType.yesNoField,
                    label: 'Notification Letter To TMR',
                    dataAddr: 'notificationLetter',
                  },
                  {
                    fieldType: FieldType.yesNoField,
                    label: 'School Advised',
                    dataAddr: 'schoolAdvised',
                  },
                ],
              },
              {
                paneType: PaneType.formFieldsPane,
                columnCount: 2,
                fields: [
                  {
                    fieldType: FieldType.selectField,
                    label: 'Category Of Behaviour Breach',
                    dataAddr: 'behaviourBreachCategory',
                    valueKey: 'value',
                    descriptionKey: 'description',
                    useValueOnly: true,
                    optionItems: allBehaviourBreachCategory,
                  },
                ],
              },
              {
                paneType: PaneType.formFieldsPane,
                fields: [
                  {
                    fieldType: FieldType.textAreaField,
                    label: 'Notes',
                    dataAddr: 'notes',
                  },
                  {
                    fieldType: FieldType.textAreaField,
                    label: 'Details of Action Taken',
                    dataAddr: 'actionTaken',
                  },
                  {
                    fieldType: FieldType.textField,
                    label: 'Filed As',
                    dataAddr: 'filedAs',
                    placeholder: 'Location e.g. S:\\OPERATIONS\\Conduct\\5-4-2019\\Jim-Jones',
                    validate: d => {
                      const pattern = new RegExp('^[a-zA-Z]:[\\\\/]+.*[\\\\/]+.*');
                      const value = d.fieldValue;
                      return !value || pattern.test(value)
                        ? undefined
                        : 'Must enter a valid path e.g. S:/OPERATIONS/Conduct/5-4-2019/Jim-Jones';
                    },
                  },
                ],
              },
              {
                paneType: PaneType.formFieldsPane,
                columnCount: 2,
                fields: [
                  {
                    fieldType: FieldType.textField,
                    label: 'School Contact Name',
                    dataAddr: 'schoolContactName',
                    maxLength: 200,
                  },
                  {
                    fieldType: FieldType.textField,
                    label: 'School Contact Phone Number',
                    dataAddr: 'schoolContactPhoneNumber',
                    maxLength: 20,
                    onChange: api =>
                      api.setFormValue(
                        api.fieldDataAddr,
                        standardTextSanitisers.numericSanitiser(api.fieldData.fieldValue as string)
                      ),
                  },
                  {
                    fieldType: FieldType.textField,
                    label: 'Parent/Carer Name',
                    dataAddr: 'guardianName',
                    maxLength: 200,
                  },
                  {
                    fieldType: FieldType.textField,
                    label: 'Parent/Carer Phone Number',
                    dataAddr: 'guardianPhoneNumber',
                    maxLength: 20,
                    onChange: api =>
                      api.setFormValue(
                        api.fieldDataAddr,
                        standardTextSanitisers.numericSanitiser(api.fieldData.fieldValue as string)
                      ),
                  },
                  {
                    fieldType: FieldType.dateField,
                    label: 'Refused Travel Date From',
                    dataAddr: 'refusedTravelDateFrom',
                  },
                  {
                    fieldType: FieldType.dateField,
                    label: 'Refused Travel Date To',
                    dataAddr: 'refusedTravelDateTo',
                    validate: d =>
                      this.validateDateTimeIsNotLessThan(
                        d.parentValue.refusedTravelDateTo,
                        'Refused travel date to',
                        d.parentValue.refusedTravelDateFrom,
                        'Refused travel date from'
                      ),
                  },
                  {
                    fieldType: FieldType.textField,
                    label: 'Number Of Days',
                    dataAddr: 'refusedDays',
                    onChange: api =>
                      api.setFormValue(
                        api.fieldDataAddr,
                        standardTextSanitisers.numericSanitiser(api.fieldData.fieldValue as string)
                      ),
                  },
                  {
                    fieldType: FieldType.dateField,
                    label: 'Return Date On School Service',
                    dataAddr: 'returnedDate',
                    validate: d =>
                      this.validateDateTimeIsNotLessThan(
                        d.parentValue.returnedDate,
                        'Return date on school service',
                        d.parentValue.refusedTravelDateTo,
                        'Refused travel date to'
                      ),
                  },
                ],
              },
            ],
          },
        ],
        onFormPreSubmit: this.isUpdateMode
          ? this.handlePreSubmitForUpdate
          : this.handlePreSubmitForCreate,
        onFormSubmit: this.isUpdateMode
          ? this.props.updateCodeOfConduct
          : this.props.createCodeOfConduct,
      },
    };
  };

  render() {
    const { mode, loadCodeOfConduct, codeOfConduct, canManageCodeOfConduct } = this.props;
    return (
      <CrudPage
        def={({ updating }) => this.getPageDef(updating)}
        mode={mode}
        isEditingForbidden={!canManageCodeOfConduct}
        onLoadData={() => loadCodeOfConduct(this.CodeOfConductId)}
        data={codeOfConduct}
        createDefaultData={{}}
      />
    );
  }
}

export default MaintainCodeOfConduct;
