import './LabelledFormField.scss';

import LabelledField from 'src/views/components/LabelledField';
import { FormFeedback } from 'reactstrap';
import { ILabelledFieldProps } from 'src/views/components/LabelledField/LabelledField';

export interface ILabelledFormFieldProps extends ILabelledFieldProps {
  error?: React.ReactNode;
  showCounter?: boolean;
  maxLength?: number;
  currentLength?: number;
}

const LabelledFormField: React.FC<ILabelledFormFieldProps> = ({
  className,
  error,
  maxLength,
  showCounter,
  currentLength,
  children,
  ...remainingProps
}) => (
  <LabelledField
    className={`labelled-form-field-component ${className || ''} ${error ? 'invalid' : ''}`}
    {...remainingProps}>
    {children}
    <div className="form-meta-container">
      {error ? (
        <FormFeedback className="invalid-feedback-container">{error}</FormFeedback>
      ) : (
        <div />
      )}
      {showCounter ? (
        <div className="form-character-counter">
          {currentLength}
          {maxLength ? `/${maxLength}` : ''}
        </div>
      ) : null}
    </div>
  </LabelledField>
);

export default LabelledFormField;
