import { Component } from 'react';
import { RouteComponentProps } from 'react-router';
import { saveAs } from 'file-saver';
import { DateTime } from 'luxon';
import {
  ChangeState,
  JobTaskCategory,
  jobTaskCategoryDescription,
  InvoiceStatus,
  getJobTaskCategoryDescriptor,
} from 'src/api/enums';
import CrudPage, { CrudPageMode, ICrudPageDef } from 'src/views/components/Page/pages/CrudPage';
import { IFormApiWithoutState } from 'src/views/components/Page/forms/base';
import {
  ActionType,
  FieldType,
  PagePrimarySize,
  PaneType,
  ShellModalSize,
} from 'src/views/definitionBuilders/types';
import PrintJobSheet, {
  jobSheetPdfHeader,
} from 'src/views/routes/workshop/jobs/listJobs/PrintJobSheet/PrintJobSheet';
import OtherWork from './OtherWork';
import { FilePdfIcon, CheckIcon, ChevronUpIcon, ChevronDownIcon } from 'src/images/icons';
import getConfirmMarkAsInvoicedModalDef from './getConfirmMarkAsInvoicedModalDef';
import { Link } from 'react-router-dom';
import { IntervalFormat } from 'src/views/components/IntervalFormat';

type OwnerListItem = Workshop.Domain.Queries.Owners.OwnerListItem;
type AssetItem = Workshop.Domain.Queries.AssetItem;
type JobDetailsDto = Workshop.Domain.Queries.Job.JobDetailsDto;
type JobDetailsTaskDto = Workshop.Domain.Queries.Job.JobDetailsTaskDto;
type CreateJobCommand = Workshop.Domain.Commands.Job.CreateJobCommand;
type UpdateJobCommand = Workshop.Domain.Commands.Job.UpdateJobCommand;
type JobTaskCategoryListItem = Workshop.Domain.Queries.GetTaskCategories.JobTaskCategoryListItem;
type AssetNextServiceDueItem = Workshop.Domain.Queries.AssetServices.GetAssetNextServiceDue.AssetNextServiceDueItem;
type FutureTaskForAsset = Workshop.Domain.Queries.GetFutureTaskForAsset.FutureTaskForAsset;
type ScheduledTaskForAsset = Workshop.Domain.Queries.GetScheduledTaskForAsset.ScheduledTaskForAsset;
type StaffMemberDto = Common.Dtos.StaffMemberDto;
type JobBusUsage = Workshop.Domain.Queries.GetJobsProgressUsage.JobBusUsageDto;
type WorkshopDepot = Common.Queries.Workshop.GetWorkshopDepots.WorkshopDepotDto;

import styles from './MaintainJob.module.scss';
import getAuditHistoryPanelDef from './getAuditHistoryPanelDef';
import getProgressSectionDef from 'src/views/components/getProgressSectionDef';

export interface IMaintainJobProps {
  mode: CrudPageMode;
  canManageJobs: boolean;
  job: JobDetailsDto | undefined;
  assets: AssetItem[];
  jobTaskCategories: JobTaskCategoryListItem[];
  jobToPrint: Workshop.Domain.Queries.Job.PrintJobQuery.JobItem | undefined;
  nextService: AssetNextServiceDueItem | undefined;
  futureTasks: FutureTaskForAsset[];
  scheduledTasks: ScheduledTaskForAsset[];
  staffMembers: StaffMemberDto[];
  loadJobTaskCategories: () => Promise<void>;
  loadAssetListItems: () => Promise<void>;
  loadJob: (id: string) => Promise<void>;
  updateJob: (command: UpdateJobCommand) => Promise<void>;
  createJob: (command: CreateJobCommand) => Promise<void>;
  loadPrintJobSheet: (jobId: string) => Promise<void>;
  getNextService: (assetId: string) => Promise<void>;
  getFutureTasks: (assetId: string) => Promise<void>;
  getScheduledTasks: (assetId: string) => Promise<void>;
  getOwnerForAsset: (assetId: string) => Promise<OwnerListItem | undefined>;
  generateJobWorkOrderReport: (jobId: string, internalUse: boolean) => Promise<Blob>;
  onMarkAsInvoiced: (id: string) => Promise<void>;
  clearNextService: () => void;
  clearFutureTasks: () => void;
  clearScheduledTasks: () => void;
  loadStaffMembers: () => Promise<void>;

  busUsages: JobBusUsage[] | undefined;
  loadBusUsages: (jobId: string) => Promise<void>;

  workshopDepots: Array<WorkshopDepot>;
  defaultWorkshopDepot: WorkshopDepot | undefined;
}

interface IEditJobRouteParams {
  id: string;
}

type InternalProps = IMaintainJobProps & RouteComponentProps<IEditJobRouteParams>;

class MaintainJob extends Component<InternalProps> {
  private primarySectionFormApi: IFormApiWithoutState | undefined;

  componentDidMount() {
    this.props.loadAssetListItems();
    this.props.loadStaffMembers();
    this.props.loadJobTaskCategories();
  }

  private get isUpdateMode() {
    return this.props.mode === 'update';
  }

  private get isCreateMode() {
    return this.props.mode === 'create';
  }

  private get jobId() {
    return this.props.match.params.id;
  }

  private generateInternalJobWorkOrderReport() {
    const date = DateTime.local().toFormat('yyyyMMddHHmm');
    this.props
      .generateJobWorkOrderReport(this.jobId, true)
      .then(r => saveAs(r, `Internal_Job_Work_Order_${date}.pdf`));
  }

  private generateExternalJobWorkOrderReport() {
    const date = DateTime.local().toFormat('yyyyMMddHHmm');
    this.props
      .generateJobWorkOrderReport(this.jobId, false)
      .then(r => saveAs(r, `External_Job_Work_Order_${date}.pdf`));
  }

  private loadData = () => {
    return this.props.loadJob(this.jobId).then(() => {
      const assetId = this.props.job!.asset.id;
      return Promise.all([
        this.props.getNextService(assetId),
        this.props.getFutureTasks(assetId),
        this.props.getScheduledTasks(assetId),
        this.props.loadBusUsages(this.jobId),
      ]).then(() => Promise.resolve());
    });
  };

  private loadCreateDefaultData = (): Promise<void> => {
    this.props.clearFutureTasks();
    this.props.clearNextService();
    this.props.clearScheduledTasks();
    return Promise.resolve();
  };

  private handlePreSubmitForCreate = (job: JobDetailsDto): CreateJobCommand => {
    return {
      assetId: job.asset.id,
      startDateTime: job.startDateTime,
      endDateTime: job.endDateTime,
      depotId: job.depotId,
      jobTasks: job.jobTasks.map((item, idx) => ({
        jobTaskId: item.id,
        jobTaskCategoryId: item.category.id,
        serviceScheduleId: item.serviceScheduleId,
        description: item.description,
        orderIndex: idx,
      })),
    };
  };

  private handlePreSubmitForUpdate = (job: JobDetailsDto): UpdateJobCommand => {
    return {
      jobId: this.jobId,
      startDateTime: job.startDateTime,
      endDateTime: job.endDateTime,
      depotId: job.depotId,
      jobTasks: job.jobTasks.map((item, idx) => ({
        jobTaskId: item.id,
        jobTaskCategoryId: item.category.id,
        serviceScheduleId: item.serviceScheduleId,
        description: item.description,
        changeState: item.changeState,
        orderIndex: idx,
      })),
    };
  };

  private readonly getPageDef = (updating: boolean): ICrudPageDef => {
    const {
      futureTasks,
      nextService,
      job,
      scheduledTasks,
      canManageJobs,
      busUsages,
      workshopDepots,
    } = this.props;
    const jobNumber = this.props.job ? this.props.job.jobNumber : '';
    let badgeStatus =
      job && job.invoiceStatus && job.invoiceStatus.id !== InvoiceStatus.NoInvoiceRequired
        ? { label: job.invoiceStatus.description }
        : undefined;

    let hasBusUsages = busUsages && busUsages.length > 0;

    return {
      primarySize: PagePrimarySize.twoThirds,
      primarySection: {
        title: this.isUpdateMode ? `Job ${jobNumber}` : 'Create a Job',
        badge: badgeStatus,
        getApi: api => (this.primarySectionFormApi = api),
        panels: [
          {
            panes: [
              {
                paneType: PaneType.formFieldsPane,
                columnCount: 2,
                fields: [
                  {
                    fieldType: FieldType.assetSelectField,
                    label: 'Asset',
                    dataAddr: 'asset',
                    readonly: this.isUpdateMode,
                    valueKey: 'id',
                    descriptionKey: 'name',
                    mandatory: true,
                    optionItems: this.props.assets,
                    onChange: api => {
                      const newAssetId = api.newFieldValue && (api.newFieldValue as AssetItem).id;
                      if (newAssetId) {
                        this.props.getNextService(newAssetId);
                        this.props.getFutureTasks(newAssetId);
                        this.props.getScheduledTasks(newAssetId);
                        this.props.getOwnerForAsset(newAssetId).then(x => {
                          if (x) {
                            api.setFormValue(['assetOwnerName'], x.name);
                            api.setFormValue(['assetOwnerId'], x.id);
                          } else {
                            api.setFormValue(['assetOwnerName'], undefined);
                            api.setFormValue(['assetOwnerId'], undefined);
                          }
                        });
                      }
                    },
                  },
                  {
                    fieldType: FieldType.readonlyField,
                    label: 'Owner',
                    dataAddr: 'assetOwnerName',
                    linkTo: d => `/workshop/owners/${d.parentValue.assetOwnerId}`,
                  },
                  {
                    fieldType: FieldType.dateTimeField,
                    label: 'Start',
                    dataAddr: 'startDateTime',
                    readonly: hasBusUsages,
                    tooltip: hasBusUsages
                      ? 'Job has progress. Remove progresses to edit details.'
                      : undefined,
                    mandatory: true,
                  },
                  {
                    fieldType: FieldType.dateTimeField,
                    label: 'End',
                    dataAddr: 'endDateTime',
                    mandatory: true,
                    validate: d =>
                      DateTime.fromISO(d.parentValue.endDateTime) <=
                      DateTime.fromISO(d.parentValue.startDateTime)
                        ? 'End must be after Start'
                        : undefined,
                  },
                  {
                    fieldType: FieldType.selectField,
                    dataAddr: 'depotId',
                    label: 'Depot',
                    useValueOnly: true,
                    valueKey: 'id',
                    descriptionKey: 'description',
                    mandatory: true,
                    optionItems: workshopDepots,
                    readonly: workshopDepots.length < 2,
                  },
                ],
              },
            ],
          },
          {
            title: 'Tasks',
            dataAddr: 'jobTasks',
            panes: [
              {
                paneType: PaneType.tablePane,
                mandatory: true,
                dataRequiredForRows: 'paneValue',
                fields: [
                  {
                    fieldType: FieldType.readonlyField,
                    label: 'Number',
                    dataAddr: 'jobTaskNumber',
                    hidden: d => !d.parentValue || !d.parentValue.id,
                    linkTo: d => `/workshop/tasks/${d.parentValue.id}`,
                  },
                  {
                    fieldType: FieldType.readonlyField,
                    label: 'Category',
                    dataAddr: 'category.description',
                    formatReadonly: d => {
                      const value = d.parentValue.category;
                      return value.description;
                    },
                  },
                  {
                    fieldType: FieldType.textField,
                    label: 'Description',
                    dataAddr: 'description',
                    readonly: d =>
                      d.parentValue.id ||
                      (d.parentValue.changeState === ChangeState.Added &&
                        d.parentValue.serviceScheduleId),
                    mandatory: true,
                  },
                  {
                    fieldType: FieldType.readonlyField,
                    label: 'Status',
                    dataAddr: 'status.description',
                    hidden: d => !d.parentValue || !d.parentValue.status,
                  },
                  {
                    fieldType: FieldType.actionListField,
                    dataAddr: '',
                    columnWidth: '1px',
                    nowrap: true,
                    actionGroups: [
                      {
                        actions: [
                          {
                            actionType: ActionType.moveArrayItemActionButton,
                            label: 'Move up',
                            icon: <ChevronUpIcon />,
                            moveDirection: 'prev',
                            hidden: d =>
                              (this.isUpdateMode && !updating) ||
                              (d.paneValue as Array<{}>).indexOf(d.parentValue) === 0,
                          },
                          {
                            actionType: ActionType.moveArrayItemActionButton,
                            label: 'Move down',
                            icon: <ChevronDownIcon />,
                            moveDirection: 'next',
                            hidden: d =>
                              (this.isUpdateMode && !updating) ||
                              (d.paneValue as Array<{}>).indexOf(d.parentValue) ===
                                (d.paneValue as Array<{}>).length - 1,
                          },
                          {
                            hidden: d =>
                              (this.isUpdateMode && !updating) ||
                              (d.parentValue &&
                                d.parentValue.changeState === ChangeState.Unchanged),
                            actionType: ActionType.removeArrayItemActionButton,
                            label: 'Remove Task',
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
              {
                paneType: PaneType.actionListPane,
                hidden: this.isUpdateMode && !updating,
                actionGroups: [
                  {
                    actions: [
                      {
                        actionType: ActionType.addArrayItemActionButton,
                        label: 'Add Task',
                        newItemData: {
                          category: {
                            id: JobTaskCategory.General,
                            description: getJobTaskCategoryDescriptor(JobTaskCategory.General)
                              .description,
                          },
                        },
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
        onFormPreSubmit: this.isUpdateMode
          ? this.handlePreSubmitForUpdate
          : this.handlePreSubmitForCreate,
        onFormSubmit: this.isUpdateMode ? this.props.updateJob : this.props.createJob,
        primaryActions:
          !updating && !this.isCreateMode
            ? [
                {
                  actions: [
                    {
                      actionType: ActionType.printActionButton,
                      label: 'Print Job Sheet',
                      printTitle: this.props.jobToPrint && jobSheetPdfHeader(this.props.jobToPrint),
                      printContent: () =>
                        this.props.jobToPrint ? (
                          <PrintJobSheet job={this.props.jobToPrint} />
                        ) : null,
                      loadDataAsync: d => this.props.loadPrintJobSheet(this.jobId),
                    },
                    {
                      actionType: ActionType.actionCollection,
                      actionGroups: [
                        {
                          actions: [
                            {
                              actionType: ActionType.actionButton,
                              label: 'Generate External Job Work Order Report',
                              icon: <FilePdfIcon fixedWidth />,
                              onClick: () => this.generateExternalJobWorkOrderReport(),
                            },
                            {
                              actionType: ActionType.actionButton,
                              label: 'Generate Internal Job Work Order Report',
                              icon: <FilePdfIcon fixedWidth />,
                              onClick: () => this.generateInternalJobWorkOrderReport(),
                            },
                            {
                              actionType: ActionType.modalActionButton,
                              label: 'Mark as Invoiced',
                              icon: <CheckIcon fixedWidth />,
                              modalSize: ShellModalSize.oneQuarter,
                              modalDef: getConfirmMarkAsInvoicedModalDef(
                                this.props.onMarkAsInvoiced,
                                this.jobId
                              ),
                              hidden:
                                !this.props.job ||
                                !canManageJobs ||
                                this.props.job.invoiceStatus.id !== InvoiceStatus.NeedsInvoicing,
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ]
            : [],
      },
      secondarySections: [
        {
          title: 'Conflicts',
          hidden: () => !(job && job.jobConflicts && job.jobConflicts.length > 0),
          panels: [
            {
              panes: [
                {
                  paneType: PaneType.customPane,
                  render: () => {
                    return (
                      <div>
                        {job!.jobConflicts.map(c => (
                          <div key={c.opsJobId} className={styles.conflict}>
                            <div className={styles.row}>
                              <strong>Operations Job </strong>
                              <Link to={`/operations/jobs/${c.opsJobId}`}>{c.opsJobNumber}</Link>
                            </div>
                            <div className={styles.row}>
                              <strong>Conflicting Times </strong>
                              <IntervalFormat
                                startValue={c.conflictStart}
                                endValue={c.conflictEnd}
                              />
                            </div>
                            <strong>Conflict cannot be accepted</strong>
                          </div>
                        ))}
                      </div>
                    );
                  },
                },
              ],
            },
          ],
        },
        {
          title: 'Other Work',
          panels: [
            {
              panes: [
                {
                  paneType: PaneType.customPane,
                  dataAddr: 'assetId',
                  render: api => {
                    return (
                      <OtherWork
                        isUpdating={updating || !this.isUpdateMode}
                        nextService={nextService}
                        futureTasks={futureTasks}
                        scheduledTasks={scheduledTasks}
                        tasksInJob={(this.props.job && this.props.job.jobTasks) || []}
                        onSelectFutureTask={this.onSelectFutureTask}
                        onSelectServicePlan={this.onSelectServicePlan}
                      />
                    );
                  },
                },
              ],
            },
          ],
        },
        getAuditHistoryPanelDef(this.jobId, this.props.staffMembers),
        getProgressSectionDef(busUsages ?? [], this.jobId, job && job.asset && job.asset.id, true),
      ],
    };
  };

  onSelectFutureTask = (task: FutureTaskForAsset) => {
    if (!this.primarySectionFormApi) {
      return;
    }
    const oldData = this.primarySectionFormApi.getValue() as JobDetailsDto;
    const jobTasks = [...oldData.jobTasks];
    if (jobTasks.find(x => x.id === task.id)) {
      return;
    }
    jobTasks.push({
      ...(task as JobDetailsTaskDto),
      changeState: ChangeState.Added,
    });
    const newData = { ...oldData, jobTasks };
    this.primarySectionFormApi.setAllValues(newData);
  };

  onSelectServicePlan = (servicePlan: AssetNextServiceDueItem) => {
    if (!this.primarySectionFormApi) {
      return;
    }
    const oldData = this.primarySectionFormApi.getValue() as JobDetailsDto;
    const jobTasks = [...oldData.jobTasks];
    if (jobTasks.find(x => x.serviceScheduleId === servicePlan.id)) {
      return;
    }
    const serviceCategory = {
      id: JobTaskCategory.Service,
      description: jobTaskCategoryDescription(JobTaskCategory.Service),
    };
    jobTasks.push({
      category: serviceCategory,
      description: servicePlan.serviceType.description,
      serviceScheduleId: servicePlan.id,
      subcategory: servicePlan.serviceType,
      changeState: ChangeState.Added,
    } as JobDetailsTaskDto);
    const newData = { ...oldData, jobTasks };
    this.primarySectionFormApi.setAllValues(newData);
  };

  render() {
    const { mode, job, canManageJobs, defaultWorkshopDepot } = this.props;
    return (
      <CrudPage
        key={this.jobId} // As in the ops job page, jobId as key means it's recreated when jobId changes
        def={({ updating }) => this.getPageDef(updating)}
        mode={mode}
        isEditingForbidden={!canManageJobs}
        onLoadData={this.loadData}
        data={job}
        createDefaultData={{ jobTasks: [], depotId: defaultWorkshopDepot?.id }}
        onLoadCreateDefaultData={this.loadCreateDefaultData}
      />
    );
  }
}

export default MaintainJob;
