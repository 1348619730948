import { AjaxResponse, AjaxError } from 'rxjs/observable/dom/AjaxObservable';
import app from './app';
import workshopAssets from './workshop/workshopAssets';
import workshopJobs from './workshop/workshopJobs';
import workshopDailyNotes from './workshop/workshopDailyNotes';
import workshopDefects from './workshop/workshopDefects';
import workshopAssetGroups from './workshop/workshopAssetGroups';
import workshopEngines from './workshop/workshopEngines';
import workshopChecklists from './workshop/workshopChecklists';
import workshopJobTasks from './workshop/workshopJobTasks';
import labourPrices from './workshop/labourPrices';
import workshopAssetCubicRegister from './workshop/workshopAssetCubicRegister';
import customers from 'src/domain/services/domainAjax/operations/sales/customers';
import quotes from 'src/domain/services/domainAjax/operations/sales/quotes';
import railBookingsForAccounting from 'src/domain/services/domainAjax/operations/sales/railBookingsForAccounting';
import boardingPoints from 'src/domain/services/domainAjax/operations/sales/boardingPoints';
import suppliers from 'src/domain/services/domainAjax/workshop/suppliers';
import owners from 'src/domain/services/domainAjax/workshop/owners';
import partCategories from 'src/domain/services/domainAjax/workshop/partCategories';
import taskSubcategories from 'src/domain/services/domainAjax/workshop/taskSubcategories';
import assetPartSubCategories from 'src/domain/services/domainAjax/workshop/assetPartSubCategories';
import assetPartCategories from 'src/domain/services/domainAjax/workshop/assetPartCategories';
import parts from 'src/domain/services/domainAjax/workshop/parts';
import componentTypes from 'src/domain/services/domainAjax/workshop/componentTypes';
import techSpecs from 'src/domain/services/domainAjax/workshop/techSpecs';
import defects from 'src/domain/services/domainAjax/workshop/defects';
import holidays from 'src/domain/services/domainAjax/operations/holidays/holidays';
import operationsDiary from 'src/domain/services/domainAjax/operations/operationsDiary/operationsDiary';
import reportableEvent from 'src/domain/services/domainAjax/compliance/reportableEvent/reportableEvent';
import purchaseOrders from 'src/domain/services/domainAjax/workshop/purchaseOrders';
import activityLog from 'src/domain/services/domainAjax/workshop/workshopActivityLog';
import shift from 'src/domain/services/domainAjax/operations/urban/shift';
import roster from 'src/domain/services/domainAjax/operations/urban/roster';
import job from 'src/domain/services/domainAjax/operations/urban/job';
import generateJobsOperation from 'src/domain/services/domainAjax/operations/urban/generateJobsOperation';
import staffMember from 'src/domain/services/domainAjax/people/staffMember/staffMember';
import clockOn from 'src/domain/services/domainAjax/kiosk/clockOn/clockOn';
import depot from 'src/domain/services/domainAjax/operations/urban/depot';
import kioskJob from 'src/domain/services/domainAjax/kiosk/job/job';
import conflict from 'src/domain/services/domainAjax/operations/urban/conflict';
import railTemplate from 'src/domain/services/domainAjax/operations/rail/railTemplates';
import railBookings from 'src/domain/services/domainAjax/operations/rail/railBookings';
import extraTypes from 'src/domain/services/domainAjax/operations/extraTypes/extraTypes';
import vehicleTypes from 'src/domain/services/domainAjax/operations/vehicleTypes/vehicleTypes';
import note from 'src/domain/services/domainAjax/people/note/note';
import role from 'src/domain/services/domainAjax/people/role/role';
import timesheetAllowance from './people/timesheetAllowance/timesheetAllowance';
import sms from 'src/domain/services/domainAjax/people/sms/sms';
import leave from 'src/domain/services/domainAjax/people/leave/leave';
import kioskNote from 'src/domain/services/domainAjax/kiosk/note/note';
import fatigue from 'src/domain/services/domainAjax/compliance/fatigue/fatigue';
import fatigueNotes from 'src/domain/services/domainAjax/compliance/fatigue/fatigueNote';
import hourlyRates from 'src/domain/services/domainAjax/operations/sales/hourlyRates';
import timesheet from 'src/domain/services/domainAjax/people/timesheet/timesheet';
import equipmentTypes from 'src/domain/services/domainAjax/people/equipmentTypes/equipmentTypes';
import staffDepot from 'src/domain/services/domainAjax/people/staffDepot/staffDepot';
import employmentStatus from 'src/domain/services/domainAjax/people/employmentStatus/employmentStatus';
import employmentContractCode from 'src/domain/services/domainAjax/people/employmentContractCode/employmentContractCode';
import paidHours from 'src/domain/services/domainAjax/operations/paidHours/paidHours';
import kioskTimesheet from 'src/domain/services/domainAjax/kiosk/timesheet/timesheet';
import kmsByJobType from 'src/domain/services/domainAjax/accounting/reporting/reporting';
import requirements from 'src/domain/services/domainAjax/operations/requirements/requirements';
import shiftTemplates from 'src/domain/services/domainAjax/workshop/shiftTemplates';
import kioskWorkshopJob from 'src/domain/services/domainAjax/kiosk/workshop/job';
import rosterTemplates from 'src/domain/services/domainAjax/workshop/rosterTemplates';
import shifts from 'src/domain/services/domainAjax/workshop/shifts';
import rosters from 'src/domain/services/domainAjax/workshop/rosters';
import rosterGroups from 'src/domain/services/domainAjax/operations/urban/rosterGroup';
import { IRootStoreModel } from 'src/domain/entities/RootStoreModel';
import { IKioskRootStoreModel } from 'src/domain/entities/KioskRootStoreModel';
import { KBL_RELEASE_ID, KBL_RELEASE_NAME } from 'src/appSettings';
import conflicts from 'src/domain/services/domainAjax/workshop/conflicts';
import dataFuel from 'src/domain/services/domainAjax/workshop/dataFuel';
import machineryScheduleTemplate from 'src/domain/services/domainAjax/workshop/machineryScheduleTemplate';
import cubic from 'src/domain/services/domainAjax/kiosk/workshop/cubic';
import kioskPartSearch from 'src/domain/services/domainAjax/kiosk/workshop/parts';
import { createAjax } from 'src/infrastructure/ajax';
import attachments from './operations/attachments/attachments';
import workshopAttachments from './workshop/workshopAttachments';
import depots from './kiosk/workshop/depots';
import externalIdentity from 'src/domain/services/domainAjax/people/externalIdentity/externalIdentity';
import allocations from 'src/domain/services/domainAjax/operations/allocations/allocation';
import bulkDriverAuthorizationUpdateOperation from './operations/driverAuthorizations/bulkDriverAuthorizationUpdateOperation';
import fatigueBreachEvent from './compliance/fatigueBreachEvent/fatigueBreachEvent';
import charterContracts from 'src/domain/services/domainAjax/operations/charterContract/charterContract';
import contractCharterBookings from './operations/contractCharterBooking/contractCharterBookings';
import peopleAttachments from './people/Attachments/peopleAttachments';
import shiftDetail from './kiosk/workshop/shift';

export type IDomainAjax = ReturnType<typeof init>;

export default function init(
  getRoot: () => IRootStoreModel | IKioskRootStoreModel,
  responseSideEffect?: (response: AjaxResponse | AjaxError) => void
) {
  const ajax = createAjax(
    () => getRoot(),
    {
      async: true,
      withCredentials: true,
      headers: {
        'Content-Type': 'application/json',
        'kbl-csrf-protection': '1',
        'kbl-release-id': KBL_RELEASE_ID,
        'kbl-release-name': KBL_RELEASE_NAME,
      },
    },
    responseSideEffect
  );

  return {
    raw: ajax,
    ...app(ajax),
    ...workshopAssets(ajax),
    ...workshopJobs(ajax),
    ...workshopDailyNotes(ajax),
    ...workshopDefects(ajax),
    ...workshopAssetGroups(ajax),
    ...workshopEngines(ajax),
    ...workshopChecklists(ajax),
    ...workshopJobTasks(ajax),
    workshop: {
      ...activityLog(ajax),
      ...suppliers(ajax),
      ...owners(ajax),
      ...partCategories(ajax),
      ...parts(ajax),
      ...purchaseOrders(ajax),
      ...componentTypes(ajax),
      ...techSpecs(ajax),
      ...defects(ajax),
      ...shiftTemplates(ajax),
      ...rosterTemplates(ajax),
      ...shifts(ajax),
      ...rosters(ajax),
      ...conflicts(ajax),
      ...assetPartCategories(ajax),
      ...assetPartSubCategories(ajax),
      ...workshopAssetCubicRegister(ajax),
      ...dataFuel(ajax),
      ...workshopAttachments(ajax),
      ...taskSubcategories(ajax),
      ...labourPrices(ajax),
      ...machineryScheduleTemplate(ajax),
    },
    operations: {
      ...job(ajax),
      ...generateJobsOperation(ajax),
      ...depot(ajax),
      ...conflict(ajax),
      ...holidays(ajax),
      ...operationsDiary(ajax),
      ...attachments(ajax),
      ...charterContracts(ajax),
      ...contractCharterBookings(ajax),
      rail: { ...railTemplate(ajax), ...railBookings(ajax) },
      ...vehicleTypes(ajax),
      ...extraTypes(ajax),
      ...paidHours(ajax),
      ...requirements(ajax),
      allocations: { ...allocations(ajax) },
      ...bulkDriverAuthorizationUpdateOperation(ajax),
    },
    sales: {
      ...customers(ajax),
      ...quotes(ajax),
      ...boardingPoints(ajax),
      ...hourlyRates(ajax),
      ...railBookingsForAccounting(ajax),
    },
    urban: {
      ...shift(ajax),
      ...roster(ajax),
      ...rosterGroups(ajax),
    },
    people: {
      ...staffMember(ajax),
      ...note(ajax),
      ...role(ajax),
      ...timesheetAllowance(ajax),
      ...externalIdentity(ajax),
      ...sms(ajax),
      ...leave(ajax),
      ...timesheet(ajax),
      ...equipmentTypes(ajax),
      ...staffDepot(ajax),
      ...employmentStatus(ajax),
      ...employmentContractCode(ajax),
      ...peopleAttachments(ajax),
    },
    compliance: {
      ...fatigue(ajax),
      ...fatigueNotes(ajax),
      ...reportableEvent(ajax),
      ...fatigueBreachEvent(ajax),
    },
    kiosk: {
      ...clockOn(ajax),
      ...kioskJob(ajax),
      ...kioskNote(ajax),
      ...kioskTimesheet(ajax),
      ...cubic(ajax),
      ...kioskPartSearch(ajax),
      workshop: {
        ...kioskWorkshopJob(ajax),
        ...depots(ajax),
        ...shiftDetail(ajax),
      },
    },
    accounting: {
      ...kmsByJobType(ajax),
    },
  };
}
