import styles from './Runsheet.module.scss';
import Spinner from 'src/views/components/Spinner';
import Card from '../components/presentational/Card';
import { DateTime, Interval } from 'luxon';
import {
  RenderJobInterval,
  formatDateTimeRange,
} from 'src/views/routes/workshop/jobs/jobSchedule/Scheduler/PrintDailyRunSheet/PrintDailyRunSheet';
import { Link } from 'react-router-dom';
import cn from 'classnames';
import { CheckIcon, BanIcon } from 'src/images/icons';
import { useEffect, useState } from 'react';

type DailyRunSheetItem = Workshop.Domain.Queries.GetDailyRunSheet.DailyRunSheetItem;

export interface IRunsheetProps {
  runsheetItem?: DailyRunSheetItem;
  defaultKioskDepotId: number;
  loadRunsheet: (depotId: number, date?: DateTime) => Promise<void>;
  date?: DateTime;
}

export const Runsheet: React.FC<IRunsheetProps> = ({
  runsheetItem,
  defaultKioskDepotId,
  loadRunsheet,
  date,
}) => {
  const [isLoading, setIsLoading] = useState(!runsheetItem);

  // Render breaks when 'date' doesn't match the runsheetItem.date
  // useEffect runs AFTER the render, so we need to stop it rendering if the date changes
  // Also don't want to fetch data unless the date has changed
  function hasDateChanged() {
    return runsheetItem && date && runsheetItem.date !== date.toISODate();
  }

  function hasDepotChanged() {
    return runsheetItem && runsheetItem.depotId !== defaultKioskDepotId;
  }

  useEffect(() => {
    if (!runsheetItem || hasDateChanged() || hasDepotChanged()) {
      setIsLoading(true);
      loadRunsheet(defaultKioskDepotId, date).finally(() => {
        setIsLoading(false);
      });
    }
  });

  const currentDay = date || DateTime.local();

  const JobsSection = () => (
    <>
      {runsheetItem &&
        runsheetItem.jobs
          .filter(job =>
            Interval.fromDateTimes(
              DateTime.fromISO(job.startDateTime).toLocal(),
              DateTime.fromISO(job.endDateTime).toLocal()
            ).overlaps(Interval.fromDateTimes(currentDay.startOf('day'), currentDay.endOf('day')))
          )
          .map((j, idx) => {
            const hasStaff = j.staffMembers.length > 0;

            return (
              <Card key={idx} className={styles.job}>
                <div className={styles.bounds}>
                  {RenderJobInterval(j.startDateTime, j.endDateTime, currentDay)}
                </div>
                <Link to={`/wskiosk/job/${j.jobId}`} className={styles.jobDetails}>
                  <div className={cn(styles.assetAndTasks, hasStaff ? null : styles.noStaff)}>
                    <div className={styles.asset}>
                      {j.assetName}
                      {j.assetIsLowFloor ? ' (LF)' : ''}
                    </div>
                    <div className={styles.tasks}>
                      <ul>
                        {j.tasks.map(t => (
                          <li key={t.taskId}>
                            {t.fitForService !== null ? (
                              t.fitForService === true ? (
                                <span className={styles.fit}>
                                  <CheckIcon />
                                </span>
                              ) : (
                                <span className={styles.unfit}>
                                  <BanIcon />
                                </span>
                              )
                            ) : (
                              <span className={styles.notDone} />
                            )}
                            <span>{t.description}</span>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                  <div className={styles.staff}>
                    {j.staffMembers.map(s => (
                      <div key={s.id}>{s.name}</div>
                    ))}
                  </div>
                </Link>
              </Card>
            );
          })}
    </>
  );

  const CurrentDate: React.FC<{ currentDate?: DateTime }> = ({ currentDate }) => {
    if (!currentDate) {
      return null;
    }

    return <div className={styles.date}>{currentDate.toLocaleString(DateTime.DATE_HUGE)}</div>;
  };

  const Roster: React.FC<{ currentDate?: DateTime }> = ({ currentDate }) => {
    return (
      <div className={styles.roster}>
        <h3>Roster</h3>
        <table className={styles.rosterTable}>
          <tbody>
            {runsheetItem &&
              runsheetItem.roster.staffRoster.map((r, i) => {
                const period = formatDateTimeRange(r.start, r.end, currentDate);
                return (
                  <tr key={i}>
                    <td className={styles.shiftName}>{r.shiftName}</td>
                    <td className={styles.shiftPeriod}>{period.from}</td>
                    <td className={styles.shiftPeriod}>{period.to}</td>
                    <td className={styles.mechanicsNames}>
                      {r.shifts.map(s => s.staffMember.name).join(', ')}
                    </td>
                  </tr>
                );
              })}
            {runsheetItem &&
              runsheetItem.roster.staffMembersOnLeave &&
              runsheetItem.roster.staffMembersOnLeave.length > 0 && (
                <tr className={styles.leave}>
                  <td className={styles.shiftName}>On Leave</td>
                  <td colSpan={2} />
                  <td className={styles.mechanicsNames}>
                    {runsheetItem.roster.staffMembersOnLeave
                      .map(s => s.staffMember.name)
                      .join(', ')}
                  </td>
                </tr>
              )}
          </tbody>
        </table>
      </div>
    );
  };

  const notesToShow =
    runsheetItem &&
    runsheetItem.note &&
    runsheetItem.note.content &&
    runsheetItem.note.content.trim().length > 0;

  return (
    <Spinner show={isLoading || hasDateChanged()} className={styles.loadingSpinner}>
      <div className={styles.runsheet}>
        <CurrentDate currentDate={date} />
        <Roster currentDate={date} />
        {notesToShow && (
          <div className={styles.notes}>
            <h3>Notes</h3>
            {runsheetItem &&
              runsheetItem.note.content.split(/\r?\n/).map(line => <div key={line}>{line}</div>)}
          </div>
        )}
        <div className={styles.jobs}>
          <h3>Jobs</h3>
          <JobsSection />
        </div>
      </div>
    </Spinner>
  );
};
