import inject from 'src/views/injectFromStore';
import ListUnallocatedAsset, { IListUnallocatedAssetProps } from './ListUnallocatedAssets';

const Container = inject<IListUnallocatedAssetProps>((allStores, p) => ({
  canManageAssets: allStores.rootStore.account.isWorkshopDepartmentMember,
  listUnallocatedAssets: allStores.rootStore.listUnallocatedAssets.listItems,
  hasMoreData: allStores.rootStore.listUnallocatedAssets.hasMoreItems,
  loadAssetCategories: allStores.rootStore.asset.loadAssetCategories,
  loadSubcategories: allStores.rootStore.asset.loadAssetSubcategories,
  loadAssetGroups: allStores.rootStore.assetGroups.listAssetGroups,
  loadAssetHousedAtLocations: allStores.rootStore.asset.loadAssetHousedAtLocations,
  loadEngines: allStores.rootStore.workshop.engine.listEngines,

  assets: allStores.rootStore.listUnallocatedAssets.items.slice(),
  assetCategories: allStores.rootStore.asset.categories.slice(),
  assetSubcategories: allStores.rootStore.asset.subcategories.slice(),
  assetGroups: allStores.rootStore.assetGroups.assetGroupsListItems.slice(),
  assetHousingLocations: allStores.rootStore.asset.housedAtLocations.slice(),
  engineModels: allStores.rootStore.workshop.engine.engineListItems.slice(),
}))(ListUnallocatedAsset);

export default Container;
