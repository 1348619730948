import { flow, types } from 'mobx-state-tree';
import { getAjax } from 'src/domain/services';
import { IAutocompleteResult } from 'src/domain/baseTypes';

type PartListItemForDropdown = Workshop.Domain.Queries.Parts.ListPartsForDropdown.PartListItemForDropdown;

export interface IMatchingPart extends PartListItemForDropdown {
  searchText: string;
}

export const PartsModel = types.model('PartsModel').actions(self => {
  const ajax = getAjax(self);
  let lastSearch: string | undefined;
  let lastResult: IAutocompleteResult<IMatchingPart>;

  const searchParts = flow(function*(search: string) {
    if (search === lastSearch) {
      return lastResult;
    }

    const result: Common.Dtos.ListResult<PartListItemForDropdown> = yield ajax.kiosk.parts
      .listPartsForDropdown({ search: search, size: 10 })
      .toPromise();
    lastSearch = search;
    lastResult = {
      options: result.items.map(
        p =>
          ({
            ...p,
            searchText: `${p.partNumber} ${p.description}`,
          } as IMatchingPart)
      ),
    };
    return lastResult;
  });

  return { searchParts };
});
