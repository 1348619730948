import { UiRouteName } from 'src/domain/baseTypes';
import { IRootStoreModel } from 'src/domain/entities/RootStoreModel';
import UiRoute from 'src/domain/services/buildUiRoutes/UiRoute';

export default function buildUiRoutes(store: IRootStoreModel) {
  const createRosterTemplate = new UiRoute(
    UiRouteName.workshopRosterTemplateCreate,
    '/workshop/roster-templates/create',
    () => `Create Roster Template`,
    false
  );

  const manageRosterTemplate = new UiRoute(
    UiRouteName.workshopRosterTemplateManage,
    '/workshop/roster-templates/:id',
    () => `Manage Roster Template`,
    false
  );

  const rosterTemplatesList = new UiRoute(
    UiRouteName.workshopRosterTemplateList,
    '/workshop/roster-templates',
    () => 'Roster Templates',
    true,
    false,
    [createRosterTemplate, manageRosterTemplate]
  );

  return {
    [UiRouteName.workshopRosterTemplateList]: rosterTemplatesList,
    [UiRouteName.workshopRosterTemplateCreate]: createRosterTemplate,
    [UiRouteName.workshopRosterTemplateManage]: manageRosterTemplate,
  };
}
