import { types } from 'mobx-state-tree';
import { CodeOfConductItemModel } from './CodeOfConductItemModel';
import { ListCodeOfConductModel } from './ListCodeOfConductModel';

export const CodeOfConductModel = types.model('CodeOfConductModel', {
  list: types.optional(ListCodeOfConductModel, {}),
  item: types.optional(CodeOfConductItemModel, {}),
});
type ModelType = typeof CodeOfConductModel.Type;
export interface ICodeOfConductModel extends ModelType {}
