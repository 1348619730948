import { FieldType, IFieldData, PagePrimarySize } from 'src/views/definitionBuilders/types';
import { ListPage } from 'src/views/components/Page';
import { TaskCardItem } from 'src/views/components/TaskCard';
import { Link } from 'react-router-dom';
import { IListPageDef } from 'src/views/components/Page/pages/ListPage';
import PrimaryTitle from 'src/views/components/Page/PrimaryTitle/PrimaryTitle';

type AssetGroupDetails = Workshop.Domain.Queries.AssetGroup.AssetGroupDetails;
type ServiceIntervals = Workshop.Domain.Queries.AssetGroup.AssetGroupServiceIntervalDetails;

export interface IListAssetGroupsProps {
  canManageAssetGroups: boolean;
  listAssetGroups: (includeInactiveAssetGroups?: boolean) => Promise<void>;
  assetGroups: AssetGroupDetails[];
}

function getPageDef(props: IListAssetGroupsProps): IListPageDef {
  return {
    primaryTitle: <PrimaryTitle title="Assets Groups"></PrimaryTitle>,
    primarySize: PagePrimarySize.full,
    onLoadData: () => props.listAssetGroups(true),
    createLink: props.canManageAssetGroups ? TaskCardItem.createAssetGroup : undefined,
    primaryFields: [
      {
        fieldType: FieldType.textField,
        dataAddr: 'description',
        label: 'Name',
        linkTo: d => `/workshop/asset-groups/${d.parentValue.id}`,
      },
      {
        fieldType: FieldType.customField,
        dataAddr: 'serviceIntervals',
        label: 'Service',
        render: d => {
          const detail = d.data as IFieldData<ServiceIntervals[]>;

          return (
            <div>
              {detail &&
                detail.fieldValue &&
                detail.fieldValue.map((i, idx) => (
                  <div key={idx}>{i.jobTaskSubcategoryDescription}</div>
                ))}
            </div>
          );
        },
      },
      {
        fieldType: FieldType.customField,
        dataAddr: 'serviceIntervals',
        label: 'Interval (Days)',
        render: d => {
          const detail = d.data as IFieldData<ServiceIntervals[]>;

          return (
            <div>
              {detail &&
                detail.fieldValue &&
                detail.fieldValue.map((i, idx) => <div key={idx}>{i.serviceIntervalDays}</div>)}
            </div>
          );
        },
      },
      {
        fieldType: FieldType.customField,
        dataAddr: 'serviceIntervals',
        label: 'Interval (KMS)',
        render: d => {
          const detail = d.data as IFieldData<ServiceIntervals[]>;

          return (
            <div>
              {detail &&
                detail.fieldValue &&
                detail.fieldValue.map((i, idx) => <div key={idx}>{i.serviceIntervalKms}</div>)}
            </div>
          );
        },
      },
      {
        fieldType: FieldType.customField,
        dataAddr: 'serviceIntervals',
        label: 'Checklist',
        getSearchText: d => {
          const detail = d as IFieldData<ServiceIntervals[]>;
          return detail && detail.fieldValue && detail.fieldValue.map(y => y.checklistName).join();
        },
        render: d => {
          const detail = d.data as IFieldData<ServiceIntervals[]>;

          return (
            <div>
              {detail &&
                detail.fieldValue &&
                detail.fieldValue.map((i, idx) => (
                  <div key={idx}>
                    <Link to={`/workshop/checklists/${i.checklistId}`}>{i.checklistName}</Link>
                  </div>
                ))}
            </div>
          );
        },
      },
      {
        fieldType: FieldType.textField,
        dataAddr: 'isActive',
        label: 'Active',
        formatReadonly: data => (data.fieldValue ? 'Yes' : 'No'),
      },
    ],
  };
}

const ListAssetGroups: React.FC<IListAssetGroupsProps> = (props: IListAssetGroupsProps) => {
  return (
    <ListPage
      className="list-assetGroups-component"
      data={props.assetGroups}
      def={getPageDef(props)}
    />
  );
};

export default ListAssetGroups;
