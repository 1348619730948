import styles from './ConsolidatedRosterShifts.module.scss';
import { DurationFormat } from 'src/views/components/DurationFormat';
import { getDisplayDays } from 'src/views/routes/operations/urban/urbanHelpers';
type RosterConsolidatedShiftItem = Workshop.Domain.Queries.Roster.GetRoster.RosterConsolidatedShiftItem;

interface IConsolidatedRosterShiftsProps {
  consolidatedShifts: RosterConsolidatedShiftItem[];
}

const daysForDisplay = (shift: RosterConsolidatedShiftItem): string => {
  if (shift) {
    const displayDays = getDisplayDays(shift);
    return displayDays.join(', ');
  }
  return '';
};

const staffMembersForDisplay = (shift: RosterConsolidatedShiftItem): string => {
  if (shift && shift.staffMembers) {
    const displayMembers = shift.staffMembers.map(x => x.staffMemberFullName);
    return displayMembers.join(', ');
  }
  return '';
};

export const ConsolidatedRosterShifts: React.FC<IConsolidatedRosterShiftsProps> = ({
  consolidatedShifts,
}) => {
  if (!consolidatedShifts) {
    return null;
  }

  return (
    <div className={styles.consolidatedRosterShifts}>
      <table className={styles.table}>
        {consolidatedShifts.map((x, index) => {
          return (
            <tbody key={`${index}${x.shiftTemplateId}${x.shiftCommence}${x.shiftEnd}`}>
              <tr>
                <td className={styles.shiftName}>
                  <strong>{x.shiftName}</strong>
                </td>
                <td className={styles.times}>
                  <DurationFormat value={x.shiftCommence} /> to{' '}
                  <DurationFormat value={x.shiftEnd} />
                </td>
                <td className={styles.days}>{daysForDisplay(x)}</td>
                <td className={styles.staffMembers}>{staffMembersForDisplay(x)}</td>
              </tr>
            </tbody>
          );
        })}
      </table>
    </div>
  );
};
