import { Component } from 'react';
import { withFormApi } from 'src/views/components/Page/forms';
import { IFormApi } from 'src/views/components/Page/forms/base';
import {
  IResetActionButtonDef,
  IActionMeta,
  ActionType,
  IActionData,
  IActionDef,
} from 'src/views/definitionBuilders/types';
import ActionButton from './ActionButton';

interface IResetActionButtonProps {
  formApi: IFormApi;
  actionDef: IResetActionButtonDef;
  actionMeta: IActionMeta;
  actionData: IActionData;
}

class ResetActionButton extends Component<IResetActionButtonProps> {
  private readonly handleReset = () => {
    const { formApi, actionDef } = this.props;
    const data =
      actionDef.resetToData &&
      (typeof actionDef.resetToData === 'function'
        ? actionDef.resetToData()
        : actionDef.resetToData);
    formApi.resetAll(data);

    actionDef.onResetAction && actionDef.onResetAction();
  };

  render() {
    const { actionDef, actionMeta, actionData } = this.props;
    return (
      <ActionButton
        actionDef={{
          ...(actionDef as IActionDef),
          actionType: ActionType.actionButton,
          label: actionDef.label || 'Cancel',
          disabled: actionDef.disabled || actionMeta.formSubmitting,
          onClick: this.handleReset,
        }}
        actionMeta={actionMeta}
        actionData={actionData}
      />
    );
  }
}

export default withFormApi(ResetActionButton);
