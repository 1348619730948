import './Menu.scss';
import { Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TimesIcon } from 'src/images/icons';
import { library, IconProp } from '@fortawesome/fontawesome-svg-core';
import {
  faHome,
  faPlane,
  faExclamationCircle,
  faCalendar,
} from '@fortawesome/free-solid-svg-icons';
import { SingleClickAwareButton } from '../../components/presentational/SingleClickAwareButton/SingleClickAwareButton';

library.add(faHome, faPlane, faExclamationCircle, faCalendar);
interface IMenuLinkProps {
  to: string;
  label: string;
  menuIcon: IconProp;
}

const MenuLink: React.FC<IMenuLinkProps> = ({ to, label, menuIcon }) => {
  return (
    <div className="menu-link">
      <Link to={to}>
        <Button size="lg" type="button">
          <FontAwesomeIcon icon={menuIcon} />
          {label}
        </Button>
      </Link>
    </div>
  );
};

export interface IMenuProps {
  click: () => void;
  canUseTimesheets: boolean | undefined;
}

function Menu(props: IMenuProps) {
  return (
    <div className="menu-component">
      <SingleClickAwareButton className="close-btn" onClick={props.click}>
        <TimesIcon />
      </SingleClickAwareButton>
      <MenuLink to="/kiosk" label="Home" menuIcon="home" />
      {props.canUseTimesheets ? (
        <MenuLink to="/kiosk/timesheet" label="Timesheets" menuIcon="calendar" />
      ) : null}
      <MenuLink to="/kiosk/leave" label="Leave" menuIcon="plane" />
      <MenuLink to="/kiosk/defect/create" label="Raise Defect" menuIcon="exclamation-circle" />
    </div>
  );
}

export default Menu;
