import { RouteComponentProps } from 'react-router-dom';
import inject from 'src/views/injectFromStore';
import RecordStocktake, { IRecordStocktakeProps } from './recordStocktake';

type InjectedProps = IRecordStocktakeProps;
type FullProps = IRecordStocktakeProps & Partial<RouteComponentProps<{}>>;

const Container = inject<InjectedProps, FullProps>((allStores, p, c) => {
  const query = new URLSearchParams(location.search);
  const selectedWorkshopDepotId = Number(query.get('depotId')) || 0;

  return {
    listStocktakeParts: allStores.rootStore.workshop.inventory.stocktake.listItems,
    refreshStocktakeParts: allStores.rootStore.workshop.inventory.stocktake.refreshItems,
    totalResults: allStores.rootStore.workshop.inventory.stocktake.totalResults,
    size: allStores.rootStore.workshop.inventory.stocktake.size,
    page: allStores.rootStore.workshop.inventory.stocktake.page,
    parts: allStores.rootStore.workshop.inventory.stocktake.items.slice(),
    onRecordStocktake: allStores.rootStore.workshop.part.recordStocktake,
    loadPartCategories: allStores.rootStore.workshop.partCategories.loadPartCategoryListItems,
    partCategories: allStores.rootStore.workshop.partCategories.partCategoryListItems.slice(),
    onGeneratePdf: allStores.rootStore.workshop.part.generateStocktakePdf,
    onGenerateExcel: allStores.rootStore.workshop.part.generateStocktakeExcel,
    workshopDepots: allStores.rootStore.workshopStartup.workshopDepots.slice(),
    defaultWorkshopDepot: allStores.rootStore.workshopStartup.defaultWorkshopDepot,
    selectedWorkshopDepotId,
  };
})(RecordStocktake);

export default Container;
