import {
  INestingPaneDef,
  PaneType,
  FieldType,
  ActionType,
  ShellModalSize,
} from 'src/views/definitionBuilders/types';
import { allFatigueBreakSource, FatigueBreakSource } from 'src/api/enums';
import { totalJobHours } from '../../urban/urbanHelpers';
import { EditIcon, PlusIcon, WarnIcon } from 'src/images/icons';
import getMaintainFatigueBreakModalDef from './getMaintainFatigueBreakModalDef';
import { DateTime } from 'luxon';
import { FieldInfoTooltip } from 'src/views/components/FieldInfoTooltip';

type StaffMemberDto = Common.Dtos.StaffMemberDto;
type FatigueBreakItem = Operations.Domain.Queries.ViewJob.FatigueBreakItem;

export function isEditableOptions(
  options: IEditableOptions | INeverEditableOptions
): options is IEditableOptions {
  return !options.neverEditable;
}

export interface IEditableOptions {
  neverEditable?: false;
  editable: boolean;
  title?: string;
  hidden?: boolean;
  staffMembers: StaffMemberDto[];
  minDate: DateTime | undefined;
  maxDate: DateTime | undefined;
  isJobCompleted: boolean;
}

export interface INeverEditableOptions {
  neverEditable: true;
}

export default function getFatigueBreaksPaneDef(
  fatigueBreaksDataAddr: string,
  options: IEditableOptions | INeverEditableOptions
): INestingPaneDef {
  const editable = isEditableOptions(options) && options.editable;
  const title = isEditableOptions(options) ? options.title : undefined;
  const hidden = isEditableOptions(options) ? options.hidden : undefined;
  const staffMembers = isEditableOptions(options) ? options.staffMembers : [];
  const minDate = isEditableOptions(options) ? options.minDate : undefined;
  const maxDate = isEditableOptions(options) ? options.maxDate : undefined;
  const isJobCompleted = isEditableOptions(options) ? options.isJobCompleted : false;

  return {
    paneType: PaneType.nestingPane,
    hidden: hidden,
    dataAddr: fatigueBreaksDataAddr,
    panes: [
      {
        paneType: PaneType.tablePane,
        title: title,
        neverEditable: !isEditableOptions(options) && options.neverEditable,
        dataRequiredForRows: 'paneValue',
        fields: [
          {
            fieldType: FieldType.selectField,
            label: 'Staff Member',
            dataAddr: 'staffMemberId',
            columnWidth: '12em',
            optionItems: staffMembers,
            valueKey: 'id',
            descriptionKey: 'name',
            useValueOnly: true,
            mandatory: true,
            readonly: true,
            includeKey: 'active',
          },
          {
            fieldType: FieldType.dateTimeField,
            dataAddr: 'fatigueBreakStart',
            label: 'Start',
            columnWidth: '15em',
            readonly: true,
            mandatory: true,
          },
          {
            fieldType: FieldType.dateTimeField,
            dataAddr: 'fatigueBreakEnd',
            label: 'End',
            columnWidth: '15em',
            readonly: true,
            mandatory: true,
          },
          {
            fieldType: FieldType.readonlyField,
            dataAddr: 'calculatedDuration',
            label: 'Duration',
            columnWidth: '15em',
            formatReadonly: data => {
              const j = data.parentValue as FatigueBreakItem;
              const start = j.fatigueBreakStart;
              const finish = j.fatigueBreakEnd;

              return start && finish && totalJobHours(start, finish);
            },
          },
          {
            fieldType: FieldType.selectField,
            dataAddr: ['fatigueBreakSource', 'id'],
            valueKey: 'value',
            descriptionKey: 'description',
            optionItems: allFatigueBreakSource,
            columnWidth: '10em',
            useValueOnly: true,
            label: 'Source',
            readonly: true,
            mandatory: true,
          },
          {
            fieldType: FieldType.textField,
            dataAddr: 'notes',
            label: 'Notes',
            columnWidth: '20em',
            readonly: true,
            mandatory: d =>
              d.parentValue.fatigueBreakSource &&
              d.parentValue.fatigueBreakSource.id === FatigueBreakSource.Other,
          },
          {
            fieldType: FieldType.yesNoField,
            dataAddr: 'ignored',
            label: 'Ignored',
            readonly: true,
          },
          {
            dataAddr: 'outsideOfJobBounds',
            fieldType: FieldType.customField,
            columnWidth: '1px',
            hidden: d => !d.fieldValue,
            render: () => (
              <>
                <FieldInfoTooltip icon={<WarnIcon color="orange" />}>
                  Break is outside of the job bounds
                </FieldInfoTooltip>
              </>
            ),
          },
          {
            fieldType: FieldType.actionListField,
            columnWidth: '1px',
            hidden: !editable,
            nowrap: true,
            actionGroups: [
              {
                actions: [
                  {
                    actionType: ActionType.modalActionButton,
                    label: 'Edit Fatigue Break',
                    icon: <EditIcon />,
                    modalSize: ShellModalSize.half,
                    modalDef: getMaintainFatigueBreakModalDef(
                      'edit',
                      staffMembers,
                      minDate,
                      maxDate
                    ),
                  },
                  {
                    actionType: ActionType.removeArrayItemActionButton,
                    label: 'Remove Fatigue Break',
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        paneType: PaneType.actionListPane,
        hidden: !editable || !isJobCompleted,
        actionGroups: [
          {
            actions: [
              {
                actionType: ActionType.modalActionButton,
                label: 'Add Fatigue Break',
                icon: <PlusIcon />,
                modalSize: ShellModalSize.half,
                modalDef: getMaintainFatigueBreakModalDef('add', staffMembers, minDate, maxDate),
              },
            ],
          },
        ],
      },
    ],
  };
}
