import inject from 'src/views/injectFromKioskStore';
import PrintRailInstructions, {
  IPrintRailInstructionsProps,
} from 'src/views/kioskRoutes/operationsKiosk/home/PrintRailInstructions/PrintRailInstructions';

const Container = inject<IPrintRailInstructionsProps>((allStores, p) => ({
  date: allStores.rootStore.kiosk.date,
  railInstructions: allStores.rootStore.kiosk.jobs.railInstructions,
  loadRailInstructionsForDriver: allStores.rootStore.kiosk.jobs.loadRailInstructionsForDriver,
}))(PrintRailInstructions);

export default Container;
