import { ArrowLeftIcon } from 'src/images/icons';
import { Link } from 'react-router-dom';
import './BackLink.scss';

interface IBackLinkProps {
  to?: string;
}

const BackLink: React.FC<IBackLinkProps> = ({ to }) => {
  return (
    <Link to={to || '/kiosk'} className="back-link-component">
      <button className="back-btn">
        <ArrowLeftIcon />
      </button>
    </Link>
  );
};

export default BackLink;
