import { useEffect } from 'react';
import { IListPageLoadDataRequest } from 'src/domain/baseTypes';
import {
  PaneType,
  FieldType,
  FieldDefs,
  PagePrimarySize,
  ActionType,
} from 'src/views/definitionBuilders/types';
import ListPage, { IListPageDef } from 'src/views/components/Page/pages/ListPage';
import memoizeOne from 'src/infrastructure/memoizeOne';
import './ListExternalIdentities.scss';
import getAssignStaffMemberActionButtonDef from './getAssignStaffMemberActionButtonDef';
import getDeleteExternalIdentityActionButtonDef from './getDeleteExternalIdentityActionButtonDef';
import PrimaryTitle from 'src/views/components/Page/PrimaryTitle/PrimaryTitle';

type ListExternalIdentitiesQuery = People.Domain.Queries.ListExternalIdentities.ListExternalIdentitiesQuery;
type ListExternalIdentityItem = People.Domain.Queries.ListExternalIdentities.ListExternalIdentityItem;
type ExternalIdentityProvider = Common.AggregatesModel.People.ExternalIdentityAggregate.ExternalIdentityProvider;
type StaffMemberDto = Common.Dtos.StaffMemberDto;
type UpdateExternalIdentityCommand = People.Domain.Commands.ExternalIdentity.UpdateExternalIdentity.UpdateExternalIdentityCommand;

export interface IListExternalIdentitiesProps {
  canManageExternalIdentities: boolean;
  loadExternalIdentityProviders: () => Promise<void>;
  externalIdentityProviders: ExternalIdentityProvider[];
  staffMembers: StaffMemberDto[];
  loadStaffMembers: () => Promise<void>;
  loadExternalIdentities: (
    request: IListPageLoadDataRequest<ListExternalIdentitiesQuery>
  ) => Promise<void>;
  externalIdentities: ListExternalIdentityItem[];
  updateExternalIdentity: (command: UpdateExternalIdentityCommand) => Promise<void>;
  deleteExternalIdentity: (externalIdentityId: string) => Promise<void>;
  hasMoreData: boolean;
}

const ListExternalIdentities: React.FC<IListExternalIdentitiesProps> = (
  props: IListExternalIdentitiesProps
) => {
  useEffect(() => {
    Promise.all([props.loadExternalIdentityProviders(), props.loadStaffMembers()]);
  }, []);

  const getFilterFieldDefs = {
    identityProviders: {
      fieldType: FieldType.selectMultiField,
      dataAddr: 'identityProviders',
      label: 'Identity Provider',
      valueKey: 'id',
      descriptionKey: 'description',
      optionItems: props.externalIdentityProviders,
      useValueOnly: true,
    } as FieldDefs,
    isMapped: {
      fieldType: FieldType.yesNoField,
      dataAddr: 'matched',
      label: 'Assigned to Staff Member',
    } as FieldDefs,
    mappedBySystem: {
      fieldType: FieldType.yesNoField,
      dataAddr: 'mappedBySystem',
      label: 'System Assigned',
    } as FieldDefs,
  };

  const getPageDef = memoizeOne(
    (
      loadExternalIdentities: IListExternalIdentitiesProps['loadExternalIdentities'],
      canManageExternalIdentities: IListExternalIdentitiesProps['canManageExternalIdentities'],
      externalIdentityProviders: IListExternalIdentitiesProps['externalIdentityProviders'],
      deleteExternalIdentity: IListExternalIdentitiesProps['deleteExternalIdentity'],
      updateExternalIdentity: IListExternalIdentitiesProps['updateExternalIdentity'],
      hasMoreData: boolean
    ): IListPageDef => {
      const filterFieldDefsLookup = getFilterFieldDefs;
      return {
        primarySize: PagePrimarySize.full,
        primaryTitle: (
          <PrimaryTitle
            title="External Identities"
            link="https://www.notion.so/External-Identities-f984d4c5c0fe45f591f7f2f5e680dea0"></PrimaryTitle>
        ),
        onLoadData: loadExternalIdentities,
        externalSearch: true,
        hasMoreData: hasMoreData,
        primaryFields: [
          {
            fieldType: FieldType.textField,
            dataAddr: 'externalId',
            label: 'External Id',
            orderBy: 'ExternalIdentity.Id',
          },
          {
            fieldType: FieldType.textField,
            dataAddr: 'externalEmail',
            label: 'External Email',
            orderBy: 'ExternalIdentity.Email',
          },
          {
            fieldType: FieldType.selectField,
            dataAddr: 'identityProvider',
            label: 'Source',
            valueKey: 'id',
            descriptionKey: 'description',
            useValueOnly: false,
            optionItems: externalIdentityProviders,
            orderBy: 'Provider.Description',
          },
          {
            fieldType: FieldType.customField,
            label: 'Staff Member',
            dataAddr: 'staffMember',
            columnWidth: '15em',
            orderBy: 'FullName',
            render: api => {
              const item = api.data.parentValue as ListExternalIdentityItem;

              return item.staffMember ? <div>{item.staffMember.fullName}</div> : null;
            },
          },
          {
            fieldType: FieldType.readonlyField,
            dataAddr: 'mappedBySystem',
            label: 'System Assigned',
            formatReadonly: d =>
              d.fieldValue === true ? 'Yes' : d.fieldValue === false ? 'No' : null,
          },
          {
            fieldType: FieldType.readonlyField,
            dataAddr: 'modifiedBy',
            label: 'Modified By',
            orderBy: 'ExternalIdentity.LastModifiedBy',
          },
          {
            fieldType: FieldType.dateTimeField,
            dataAddr: 'modifiedOn',
            label: 'Modified On',
            orderBy: 'ExternalIdentity.LastModifiedOn',
          },
          {
            fieldType: FieldType.actionListField,
            dataAddr: '',
            columnWidth: '1px',
            label: 'Actions',
            hidden: !canManageExternalIdentities,
            actionGroups: [
              {
                actions: [
                  {
                    actionType: ActionType.actionCollection,
                    actionGroups: [
                      {
                        actions: [
                          getAssignStaffMemberActionButtonDef(
                            loadExternalIdentities,
                            updateExternalIdentity
                          ),
                          getDeleteExternalIdentityActionButtonDef(
                            loadExternalIdentities,
                            deleteExternalIdentity
                          ),
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
        filterAction: {
          defaultValues: {
            matched: false,
          },
          filterFields: Object.keys(filterFieldDefsLookup).map(k => filterFieldDefsLookup[k]),
          filterDef: _ => [
            {
              panes: [
                {
                  paneType: PaneType.formFieldsPane,
                  columnCount: 1,
                  fields: [
                    filterFieldDefsLookup.identityProviders,
                    filterFieldDefsLookup.isMapped,
                    filterFieldDefsLookup.mappedBySystem,
                  ],
                },
              ],
            },
          ],
        },
      };
    }
  );

  const {
    loadExternalIdentities,
    externalIdentityProviders,
    canManageExternalIdentities,
    deleteExternalIdentity,
    updateExternalIdentity,
    hasMoreData,
  } = props;

  const def = getPageDef(
    loadExternalIdentities,
    canManageExternalIdentities,
    externalIdentityProviders,
    deleteExternalIdentity,
    updateExternalIdentity,
    hasMoreData
  );
  return (
    <ListPage
      className="list-external-identities-component"
      data={props.externalIdentities}
      def={def}
    />
  );
};

export default ListExternalIdentities;
