import { PureComponent } from 'react';
import {
  IActionMeta,
  ActionType,
  IActionData,
  ICloseModalActionButtonDef,
} from 'src/views/definitionBuilders/types';
import ActionButton from './ActionButton';

interface ICloseModalActionButtonProps {
  actionDef: ICloseModalActionButtonDef;
  actionMeta: IActionMeta;
  actionData: IActionData;
}

class CloseModalActionButton extends PureComponent<ICloseModalActionButtonProps> {
  render() {
    const { actionDef, actionMeta, actionData } = this.props;
    return (
      <ActionButton
        actionDef={{
          ...actionDef,
          actionType: ActionType.actionButton,
          label: actionDef.label || 'Cancel', // Cancel probably wanted more often than "Close"
          disabled: actionDef.disabled || actionMeta.formSubmitting,
          closeModal: true,
          onClick: () => (actionDef.closeAction ? actionDef.closeAction() : Promise.resolve()),
        }}
        actionMeta={actionMeta}
        actionData={actionData}
      />
    );
  }
}

export default CloseModalActionButton;
