import './AllocationJob.scss';
import cn from 'classnames';
import { ConflictStatus, ConflictType, DriverMovement } from 'src/api/enums';
import { CheckIcon, ErrorIcon, CalendarCancelIcon } from 'src/images/icons';
import { IJobItem } from 'src/domain/entities/operations/job/ListJobsForAllocationsModel';
import { isJobTypeWithoutAsset } from 'src/views/routes/operations/shared/jobTypeHelpers';

interface IAllocationJobProps {
  job: IJobItem;
}

const AllocationJob: React.FC<IAllocationJobProps> = ({ job }) => {
  const hasConflict = (type: ConflictType, status: ConflictStatus) => {
    const allConflicts = [
      ...(job.conflicts.opsConflicts || []),
      ...(job.conflicts.workshopJobConflicts || []),
      ...(job.conflicts.peopleLeaveConflicts || []),
      ...(job.conflicts.licenceConflicts || []),
    ];
    return allConflicts.some(c => c.conflictType.id === type && c.conflictStatus.id === status);
  };

  const jobDuration = job.bounds.toDuration('minutes').as('minutes');
  const vehDuration = job.depotBounds.toDuration('minutes').as('minutes');
  const shiftDuration = job.shiftBounds.toDuration('minutes').as('minutes');

  const vehOffset = job.depotBounds.start.diff(job.bounds.start).as('minutes');
  const vehPercentage = (vehDuration / jobDuration) * 100;
  const vehOffsetPercentage = (vehOffset / jobDuration) * 100;

  const shiftOffset = job.shiftBounds.start.diff(job.bounds.start).as('minutes');
  const shiftPercentage = (shiftDuration / jobDuration) * 100;
  const shiftOffsetPercentage = (shiftOffset / jobDuration) * 100;

  const { icon: StaffIcon, level: iconLevel } = job.isCancelled
    ? { icon: CalendarCancelIcon, level: 'job-is-cancelled' }
    : job.staffMemberMovement.id === DriverMovement.ClockedOn
    ? { icon: CheckIcon, level: 'clocked-on' }
    : job.staffMemberMovement.id === DriverMovement.NotClockedOn
    ? { icon: ErrorIcon, level: 'not-clocked-on' }
    : { icon: undefined, level: undefined };

  const summary = [
    `${job.jobType.abbreviation}`,
    job.isCancelled ? 'CANCELLED' : undefined,
    job.shiftName,
    job.customer && job.customer.customerName,
    job.description,
  ]
    .filter(i => !!i)
    .join(' • ');

  const staffNames = [job.staffMemberName, job.secondStaffMemberName].filter(n => !!n).join(' & ');

  const allocations = [
    job.vehicleType && job.vehicleType.description,
    job.asset && job.asset.name,
    staffNames,
    job.subcontractor && job.subcontractor.name,
  ]
    .filter(i => !!i)
    .join(' • ');

  return (
    <div
      className={cn('allocation-job-component', {
        'without-asset': isJobTypeWithoutAsset(job.jobType.id),
        'unaccepted-staff-member-conflict-exists':
          hasConflict(ConflictType.StaffMember, ConflictStatus.Unaccepted) ||
          hasConflict(ConflictType.DriverLicence, ConflictStatus.Unaccepted) ||
          hasConflict(ConflictType.DriverAuthorisation, ConflictStatus.Unaccepted) ||
          hasConflict(ConflictType.PeopleLeave, ConflictStatus.Unaccepted),
        'accepted-staff-member-conflict-exists': hasConflict(
          ConflictType.StaffMember,
          ConflictStatus.Accepted
        ),
        'unaccepted-vehicle-conflict-exists':
          hasConflict(ConflictType.Vehicle, ConflictStatus.Unaccepted) ||
          hasConflict(ConflictType.WorkshopJobAsset, ConflictStatus.Unaccepted),
        'accepted-vehicle-conflict-exists': hasConflict(
          ConflictType.Vehicle,
          ConflictStatus.Accepted
        ),
      })}>
      <div
        className={cn('staff-member-time', {
          allocated:
            (!!job.staffMemberId && (!job.hasSecondStaffMember || !!job.secondStaffMemberId)) ||
            (!!job.subcontractor && !!job.subcontractor.id),
        })}
      />
      <div
        className={cn('vehicle-time', {
          allocated: !!(
            (job.asset && job.asset.name) ||
            (!!job.subcontractor && !!job.subcontractor.id)
          ),
        })}
        style={{ width: `${vehPercentage}%`, marginLeft: `${vehOffsetPercentage}%` }}
      />
      <div
        className={cn('shift-time', iconLevel)}
        title={[summary, allocations].filter(i => !!i).join(', ')}
        style={{ width: `${shiftPercentage}%`, marginLeft: `${shiftOffsetPercentage}%` }}>
        {StaffIcon ? <StaffIcon className="staff-member-icon" size="lg" /> : null}
        <div className="job-summary">
          <span className="hide-overflow">{summary}</span>
        </div>
        <div className="job-allocations">
          <span className="hide-overflow">{allocations}</span>
        </div>
      </div>
    </div>
  );
};

export default AllocationJob;
