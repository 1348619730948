import { skillSpecCategoryDescription } from 'src/api/enums';
import { EditIcon } from 'src/images/icons';
import { nonStrictEqual } from 'src/infrastructure/nonStrictEqual';
import {
  PaneType,
  FieldType,
  ActionType,
  ShellModalSize,
  ISectionDef,
} from 'src/views/definitionBuilders/types';
import getSkillsModalDef from './getSkillsModalDef';

type StaffMemberSkillSpecItem = People.Domain.Queries.ViewStaffMember.StaffMemberItem.StaffMemberSkillSpecItem;
type UpdateStaffMemberSkillSpecsCommand = People.Domain.Commands.StaffMembers.SkillSpecs.UpdateStaffMemberSkillSpecsCommand;

export default function getSkillsSectionDef(
  canSave: boolean,
  staffMemberId: string,
  skillSpecs: StaffMemberSkillSpecItem[] | undefined,
  updateSkillSpecs: (cmd: UpdateStaffMemberSkillSpecsCommand) => Promise<void>,
  primarySectionEditing: boolean
): ISectionDef {
  return {
    title: 'Skill Specs',
    panels: [
      {
        panes: [
          {
            paneType: PaneType.tablePane,
            dataAddr: 'skillSpecs',
            neverEditable: true,
            fields: [
              {
                fieldType: FieldType.readonlyField,
                dataAddr: 'skillSpecDescription',
                label: 'Description',
              },
              {
                fieldType: FieldType.readonlyField,
                dataAddr: 'category',
                label: 'Category',
                formatReadonly: c => skillSpecCategoryDescription(c.fieldValue),
              },
              {
                fieldType: FieldType.readonlyField,
                dataAddr: 'value',
                label: 'Value',
                formatReadonly: c => {
                  const value =
                    c.fieldValue === null ? '' : nonStrictEqual(c.fieldValue, true) ? 'Yes' : 'No';
                  return value;
                },
              },
            ],
          },
        ],
      },
    ],
    secondaryActions: [
      {
        actions: [
          {
            actionType: ActionType.modalActionButton,
            label: 'Edit Skill Specs',
            hidden: () => !canSave || primarySectionEditing,
            icon: <EditIcon />,
            modalSize: ShellModalSize.oneThird,
            modalDef: getSkillsModalDef(staffMemberId, skillSpecs, updateSkillSpecs),
          },
        ],
      },
    ],
  };
}
