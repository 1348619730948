import { Component } from 'react';
import { createPortal } from 'react-dom';

const containerElementId = 'full-page-loading-indicator-portal';

interface IFullPageLoadingIndicatorProps {
  isLoading: boolean;
}

class FullPageLoadingIndicator extends Component<IFullPageLoadingIndicatorProps> {
  private containerEl: Element;

  constructor(props: IFullPageLoadingIndicatorProps) {
    super(props);
    this.containerEl = this.getContainerElement();
  }

  render() {
    const { isLoading, children } = this.props;
    const getPortal = () =>
      createPortal(<div className="loading-indicator">Loading ...</div>, this.containerEl);
    return (
      <div className="loading-indicator-component">
        {isLoading && getPortal()}
        {children}
      </div>
    );
  }

  private getContainerElement(): Element {
    let el = document.getElementById(containerElementId) as Element;
    if (!el) {
      el = document.createElement('div');
      el.id = containerElementId;
      document.body.appendChild(el);
    }
    return el;
  }
}

export default FullPageLoadingIndicator;
