// tslint:disable-next-line:no-any
export function nonStrictEqual(a: any, b: any): boolean {
  return (
    // For filters, where data is held in the url, everything is a string
    // ESLInt disble below - we want the string version of numbers to work too
    // eslint-disable-next-line eqeqeq
    a == b ||
    (a === true && b === 'true') ||
    (a === false && b === 'false') ||
    (b === true && a === 'true') ||
    (b === false && a === 'false')
  );
}
