import { DateTime } from 'luxon';
import { PrintIcon } from 'src/images/icons';
import PrintCharterInstructionsContent from 'src/views/components/operations/PrintCharterInstructionsContent/PrintCharterInstructionsContent';
import PrintButton from 'src/views/components/PrintButton';
import './PrintCharterInstructions.scss';

export interface IPrintCharterInstructionsProps {
  date: DateTime;
  charterInstructions:
    | Operations.Domain.Queries.ListCharterInstructionsForDriver.CharterInstructions
    | undefined;
  loadCharterInstructionsForDriver: (date: DateTime) => Promise<void>;
}

const PrintCharterInstructions: React.FC<IPrintCharterInstructionsProps> = (
  props: IPrintCharterInstructionsProps
) => {
  return (
    <PrintButton
      className="print-button"
      size="lg"
      outline
      loadDataAsync={() => props.loadCharterInstructionsForDriver(props.date)}
      printContent={() => (
        <PrintCharterInstructionsContent charterInstructions={props.charterInstructions} />
      )}>
      <PrintIcon />
      Print Charter Instructions
    </PrintButton>
  );
};

export default PrintCharterInstructions;
