import { DateTime } from 'luxon';
import { useEffect } from 'react';
import { allUploadStatus, allUploadType, UploadStatus } from 'src/api/enums';
import { ListPageLoadCause } from 'src/domain';
import { IListPageLoadDataRequest } from 'src/domain/baseTypes';
import { ToolsIcon } from 'src/images/icons';
import ListPage, { IListPageDef } from 'src/views/components/Page/pages/ListPage';
import { getSubmitCloseModalActionGroupDef } from 'src/views/definitionBuilders/common';
import {
  ActionType,
  PagePrimarySize,
  PaneType,
  ShellModalSize,
} from 'src/views/definitionBuilders/types';
import { FieldDefs, FieldType } from 'src/views/definitionBuilders/types/field';
import withQueryParams, { IQueryParamsProps } from 'src/views/hocs/withQueryParams';
import PrimaryTitle from 'src/views/components/Page/PrimaryTitle/PrimaryTitle';

type ListDataFuelUploadQuery = Workshop.Domain.Queries.DataFuel.ListDataFuelUpload.ListDataFuelUploadQuery;
type DataFuelUploadListItem = Workshop.Domain.Queries.DataFuel.ListDataFuelUpload.DataFuelUploadListItem;
type AssetItem = Workshop.Domain.Queries.AssetItem;
type StaffMemberDto = Common.Dtos.StaffMemberDto;
type UpdateDataFuelEntryCommand = Workshop.Domain.Commands.DataFuel.UpdateDataFuelEntry.UpdateDataFuelEntryCommand;

export interface IListDataFuelUploadsProps {
  hasMoreData: boolean;
  uploadedFiles: DataFuelUploadListItem[];
  loadUploadedFiles: (query: IListPageLoadDataRequest<ListDataFuelUploadQuery>) => Promise<void>;
  uploadFile: (file: string | Blob) => Promise<void>;
  updateEntry: (command: UpdateDataFuelEntryCommand) => Promise<void>;
  assets: Array<AssetItem>;
  loadAssets: () => Promise<void>;
  staffMembers: StaffMemberDto[];
  loadAllStaffMembers: () => Promise<void>;
  markUploadAsFixed: (uploadId: string) => Promise<void>;
}

type InternalProps = IListDataFuelUploadsProps & IQueryParamsProps<ListDataFuelUploadQuery>;

const ListDataFuelUploads: React.FC<InternalProps> = (props: InternalProps) => {
  useEffect(() => {
    Promise.all([props.loadAllStaffMembers(), props.loadAssets()]);
  }, []);

  const getFilterFieldDefs = () => {
    return {
      dateFrom: {
        fieldType: FieldType.dateField,
        label: 'Date From',
        dataAddr: 'dateFrom',
        onBlur: api => {
          api.validateField(['dateTo']);
        },
      } as FieldDefs,
      dateTo: {
        fieldType: FieldType.dateField,
        label: 'Date To',
        dataAddr: 'dateTo',
        validate: d => {
          if (!d.fieldValue || !d.parentValue.dateFrom) {
            return undefined;
          }
          const from = DateTime.fromISO(d.parentValue.dateFrom);
          const to = DateTime.fromISO(d.fieldValue);
          return from > to ? 'Date To cannot be earlier than Date From' : undefined;
        },
      } as FieldDefs,
      status: {
        fieldType: FieldType.selectMultiField,
        dataAddr: 'statusIds',
        label: 'Status',
        valueKey: 'value',
        descriptionKey: 'description',
        optionItems: allUploadStatus,
        useValueOnly: true,
      } as FieldDefs,
      uploadType: {
        fieldType: FieldType.selectMultiField,
        dataAddr: 'uploadTypeIds',
        label: 'Upload Type',
        valueKey: 'value',
        descriptionKey: 'description',
        optionItems: allUploadType,
        useValueOnly: true,
      } as FieldDefs,
    };
  };

  const getPageDef = (): IListPageDef => {
    const filterFieldDefsLookup = getFilterFieldDefs();
    const today = DateTime.local().toISODate();

    return {
      primaryTitle: <PrimaryTitle title="Data Fuel Uploads"></PrimaryTitle>,
      onLoadData: props.loadUploadedFiles,
      externalSearch: true,
      hasMoreData: props.hasMoreData,
      primarySize: PagePrimarySize.twoThirds,
      rowKey: d => d.itemValue.uploadId,
      filterAction: {
        defaultValues: {
          dateFrom: today,
        },
        filterSize: ShellModalSize.oneQuarter,
        filterFields: Object.keys(filterFieldDefsLookup).map(k => filterFieldDefsLookup[k]),
        filterDef: filterDefApi => [
          {
            panes: [
              {
                paneType: PaneType.formFieldsPane,
                columnCount: 2,
                fields: [filterFieldDefsLookup.dateFrom, filterFieldDefsLookup.dateTo],
              },
              {
                paneType: PaneType.formFieldsPane,
                columnCount: 1,
                fields: [filterFieldDefsLookup.status],
              },
              {
                paneType: PaneType.formFieldsPane,
                columnCount: 1,
                fields: [filterFieldDefsLookup.uploadType],
              },
            ],
          },
        ],
      },
      primaryActions: [],
      primaryFields: [
        {
          fieldType: FieldType.textField,
          dataAddr: 'fileName',
          label: 'File',
        },
        {
          fieldType: FieldType.textField,
          dataAddr: 'loadedBy',
          label: 'Loaded By',
        },
        {
          fieldType: FieldType.dateTimeField,
          dataAddr: 'loadedAt',
          label: 'Loaded At',
        },
        {
          fieldType: FieldType.selectField,
          dataAddr: 'uploadType',
          label: 'Upload Type',
          valueKey: 'value',
          descriptionKey: 'description',
          optionItems: allUploadType,
          useValueOnly: true,
        },
        {
          fieldType: FieldType.selectField,
          dataAddr: 'status',
          label: 'Status',
          valueKey: 'value',
          descriptionKey: 'description',
          optionItems: allUploadStatus,
          useValueOnly: true,
        },
        {
          fieldType: FieldType.actionListField,
          columnWidth: '1px',
          nowrap: true,
          actionGroups: [
            {
              actions: [
                {
                  actionType: ActionType.modalActionButton,
                  modalSize: ShellModalSize.oneThird,
                  label: 'Fix',
                  icon: <ToolsIcon fixedWidth />,
                  hidden: d => d.parentValue.status !== UploadStatus.Failed,
                  modalDef: d => ({
                    title: 'Mark Data Fuel Upload as fixed',
                    asForm: true,
                    panels: [
                      {
                        panes: [
                          {
                            paneType: PaneType.customPane,
                            render: () => {
                              return (
                                <div>
                                  <p>Are you sure you want to mark this failed upload as fixed?</p>
                                </div>
                              );
                            },
                          },
                        ],
                      },
                    ],
                    secondaryActions: [getSubmitCloseModalActionGroupDef('Submit')],
                    onFormSubmit: v => {
                      return props.markUploadAsFixed(v.uploadId).then(r => {
                        props.loadUploadedFiles({
                          query: { ...props.getQueryParams() },
                          loadCause: ListPageLoadCause.refresh,
                        });
                      });
                    },
                  }),
                },
              ],
            },
          ],
        },
      ],
    };
  };

  return <ListPage data={props.uploadedFiles} def={getPageDef()} />;
};

export default withQueryParams(ListDataFuelUploads);
