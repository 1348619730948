import { types, flow, getRoot } from 'mobx-state-tree';
import { getAjax } from 'src/domain/services';
import { IRootStoreModel } from 'src/domain/entities/RootStoreModel';
import {
  ISetActiveCommand,
  ISetInactiveCommand,
} from 'src/views/components/ActivationAndDeletionActionsButtons/getActivationAndDeletionActionButtons';

type SetChecklistInactiveCommand = Workshop.Domain.Commands.Checklist.SetChecklistInactive.SetChecklistInactiveCommand;
type SetChecklistActiveCommand = Workshop.Domain.Commands.Checklist.SetChecklistActive.SetChecklistActiveCommand;
type AggregateUsageDto = Common.Dtos.AggregateUsageDto;
type ChecklistDetails = Workshop.Domain.Queries.GetChecklist.ChecklistDetails;
type CreateChecklistCommand = Workshop.Domain.Commands.Checklist.CreateChecklist.CreateChecklistCommand;
type UpdateChecklistCommand = Workshop.Domain.Commands.Checklist.UpdateChecklist.UpdateChecklistCommand;

export const ChecklistModel = types
  .model('ChecklistModel', {
    item: types.maybe(types.frozen<ChecklistDetails>()),
    checklistUsage: types.maybe(types.frozen<AggregateUsageDto>()),
  })
  .actions(self => {
    const ajax = getAjax(self);
    const root = getRoot(self) as IRootStoreModel;

    const load = flow(function*(checklistId: string) {
      self.item = undefined;
      self.item = yield ajax.checklist.getChecklist(checklistId);
    });

    const create = flow(function*(command: CreateChecklistCommand) {
      const id = yield ajax.checklist.createChecklist(command);
      self.item = undefined;
      root.history.push(`/workshop/checklists/${id}`);
    });

    const update = flow(function*(command: UpdateChecklistCommand) {
      yield ajax.checklist.updateChecklist(command);
      yield load(command.id);
    });

    const setChecklistInactive = flow(function*(command: ISetInactiveCommand) {
      const setChecklistInactiveCommand: SetChecklistInactiveCommand = {
        id: command.id,
      };
      yield ajax.checklist.updateChecklistSetInactive(setChecklistInactiveCommand);
    });

    const setChecklistActive = flow(function*(command: ISetActiveCommand) {
      const setChecklistActiveCommand: SetChecklistActiveCommand = {
        id: command.id,
      };
      yield ajax.checklist.updateChecklistSetActive(setChecklistActiveCommand);
    });

    const deleteChecklist = flow(function*(id: string) {
      yield ajax.checklist.deleteChecklist(id);

      self.checklistUsage = undefined;
      root.history.push('/workshop/checklists');
    });

    const getChecklistUsage = flow(function*(id: string) {
      self.checklistUsage = yield ajax.checklist.getCurrentUsage(id);
    });

    return {
      load,
      create,
      update,
      setChecklistInactive,
      setChecklistActive,
      deleteChecklist,
      getChecklistUsage,
    };
  });
