import { types, flow } from 'mobx-state-tree';
import { getAjax } from 'src/domain/services/storeEnvironment';
import { AssetJobsTasksModel } from './AssetJobsTasksModel';

type AssetItem = Workshop.Domain.Queries.AssetItem;
type AssetDetails = Workshop.Domain.Queries.GetAsset.AssetDetails;
type NextOpsJobForAsset = Common.Queries.Workshop.GetNextOpsJobForAsset.NextOpsJob;
type AssetPartItem = Workshop.Domain.Queries.AssetParts.AssetPartItem;
type AssetComponentItem = Workshop.Domain.Queries.AssetComponents.AssetComponentItem;
type AssetOdometerReadingItem = Workshop.Domain.Queries.GetLastAssetOdometerReadings.AssetOdometerReadingItem;
type PartTransactionItem = Workshop.Domain.Queries.GetPartTransactions.PartTransactionItem;
type AssetCubicSummary = Workshop.Domain.Queries.AssetCubic.AssetCubicSummary;

export const AssetModel = types
  .model('AssetModel', {
    assets: types.array(types.frozen<AssetItem>()),
    asset: types.maybe(types.frozen<AssetDetails>()),
    jobsTasks: types.optional(AssetJobsTasksModel, {}),
    nextOpsJobsForAsset: types.array(types.frozen<NextOpsJobForAsset>()),
    nextOpsJobForAllAssets: types.array(types.frozen<NextOpsJobForAsset>()),
    parts: types.array(types.frozen<AssetPartItem>()),
    partsUsed: types.array(types.frozen<PartTransactionItem>()),
    components: types.array(types.frozen<AssetComponentItem>()),
    cubicRegister: types.maybe(types.frozen<AssetCubicSummary>()),
    lastOdometerReading: types.maybe(types.frozen<AssetOdometerReadingItem>()),
  })
  .views(self => ({
    get sortedAssets() {
      return self.assets.slice().sort((a, b) => {
        return a.name.localeCompare(b.name, undefined, { numeric: true });
      });
    },
    get nextOpsJobForAsset() {
      return self.nextOpsJobsForAsset ? self.nextOpsJobsForAsset[0] : undefined;
    },
  }))
  .actions(self => {
    const ajax = getAjax(self);

    const loadAssets = flow(function*() {
      self.assets = yield ajax.raw
        .get(`/api/kiosk/assets`)
        .toPromise()
        .then<AssetItem[]>(r => r.response);
    });

    const loadAsset = flow(function*(id: string) {
      self.asset = yield ajax.raw
        .get(`/api/kiosk/assets/${id}`)
        .toPromise()
        .then<AssetDetails>(r => r.response);
    });

    const loadNextOpsJobsForAsset = flow(function*(id: string) {
      self.nextOpsJobsForAsset = yield ajax.raw
        .get(`/api/kiosk/workshop/asset/${id}/next-ops-jobs`)
        .toPromise()
        .then<NextOpsJobForAsset[]>(r => r.response);
    });

    const loadNextOpsJobForAllAssets = flow(function*() {
      self.nextOpsJobForAllAssets = yield ajax.raw
        .get(`/api/kiosk/workshop/assets/next-ops-job`)
        .toPromise()
        .then<NextOpsJobForAsset[]>(r => r.response);
    });

    const loadPartsRegisterForAsset = flow(function*(id: string) {
      self.parts = yield ajax.raw
        .get(`/api/kiosk/workshop/asset/${id}/parts`)
        .toPromise()
        .then<AssetPartItem[]>(r => r.response);
    });

    const loadPartsUsedForAsset = flow(function*(id: string) {
      self.partsUsed = yield ajax.raw
        .get(`/api/kiosk/workshop/asset/${id}/used-parts`)
        .toPromise()
        .then<PartTransactionItem[]>(r => r.response);
    });

    const loadComponentRegisterForAsset = flow(function*(id: string) {
      self.components = yield ajax.raw
        .get(`/api/kiosk/workshop/asset/${id}/components`)
        .toPromise()
        .then<AssetComponentItem[]>(r => r.response);
    });

    const loadCubicRegisterForAsset = flow(function*(id: string) {
      self.cubicRegister = yield ajax.raw
        .get(`/api/kiosk/workshop/asset/${id}/cubic-summary`)
        .toPromise()
        .then<AssetCubicSummary>(r => r.response);
    });

    const loadLastOdometerReading = flow(function*(id: string) {
      self.lastOdometerReading = yield ajax.raw
        .get(`/api/kiosk/workshop/asset/${id}/odometer-readings/last`)
        .toPromise()
        .then(r => r.response);
    });

    return {
      loadAssets,
      loadAsset,
      loadNextOpsJobsForAsset,
      loadNextOpsJobForAllAssets,
      loadPartsRegisterForAsset,
      loadComponentRegisterForAsset,
      loadLastOdometerReading,
      loadPartsUsedForAsset,
      loadCubicRegisterForAsset,
    };
  });
