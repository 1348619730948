import { PaneType, FieldType, ModalDefBuilder } from 'src/views/definitionBuilders/types';
import { getSubmitCloseModalActionGroupDef } from 'src/views/definitionBuilders/common';
import { IFormApiWithoutState } from 'src/views/components/Page/forms/base';

type StaffMemberDto = Common.Dtos.StaffMemberDto;
type RoleItem = People.Domain.Queries.Role.RoleItem;

export default function getAddStaffMembersByRoleModalDef(
  roles: RoleItem[],
  activeStaffMembers: StaffMemberDto[],
  formApi: IFormApiWithoutState | undefined
): ModalDefBuilder {
  return modalDefApi => ({
    title: 'Add Role',
    asForm: true,
    panels: [
      {
        panes: [
          {
            paneType: PaneType.formFieldsPane,
            fields: [
              {
                fieldType: FieldType.selectField,
                dataAddr: 'roleId',
                label: 'Role',
                mandatory: true,
                optionItems: roles,
                valueKey: 'id',
                descriptionKey: 'name',
                useValueOnly: true,
              },
            ],
          },
        ],
      },
    ],
    secondaryActions: [getSubmitCloseModalActionGroupDef('Ok')],
    onFormPreSubmit: v => v.roleId,
    onFormSubmit: (roleId: string) => {
      const staffMembersToAdd = activeStaffMembers.filter(a =>
        a.roles.some(role => role.roleId === roleId)
      );

      const staffMembersAddr = 'staffMembers';
      const staffMembersAlreadyAdded = modalDefApi.parentFormApi.getValue(staffMembersAddr) ?? [];
      let staffMembersAlreadyAddedDtos = staffMembersAlreadyAdded.map((id: string) => {
        return {
          id: id,
        };
      }) as StaffMemberDto[];

      staffMembersAlreadyAddedDtos = staffMembersAlreadyAddedDtos.concat(staffMembersToAdd);
      const targetIds =
        staffMembersAlreadyAddedDtos && new Set(staffMembersAlreadyAddedDtos.map(x => x.id));
      formApi!.setValue(['staffMembers'], Array.from(targetIds));
      return Promise.resolve();
    },
  });
}
