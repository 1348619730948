import styles from './Form.module.scss';

import { Field } from 'formik';
import cn from 'classnames';
import { ChangeState } from 'src/api/enums';
import { TrashIcon, UndoIcon } from 'src/images/icons';

interface IDeleteFieldProps {
  dataAddress: string;
  className?: string;
  readOnly?: boolean;
  deleteFunc?: () => void;
}

const DeleteField: React.FC<IDeleteFieldProps> = ({
  dataAddress,
  className,
  readOnly,
  deleteFunc,
}) => (
  <div className={cn(styles.fieldContainer, styles.autoWidthContainer, className)}>
    {readOnly ? (
      <div className={styles.deleteField} />
    ) : (
      <Field name={dataAddress}>
        {({
          field: { name, value },
          form,
        }: {
          field: { name: string; value: ChangeState };
          form: {
            setFieldValue: (name: string, value: ChangeState) => void;
          };
        }) => (
          <div
            className={styles.deleteField}
            onClick={() => {
              deleteFunc
                ? deleteFunc()
                : form.setFieldValue(
                    name,
                    value !== ChangeState.Deleted ? ChangeState.Deleted : ChangeState.Modified
                  );
            }}>
            {value !== ChangeState.Deleted ? <TrashIcon /> : <UndoIcon />}
          </div>
        )}
      </Field>
    )}
  </div>
);

export default DeleteField;
