import styles from './DepotSelector.module.scss';
import React from 'react';
import { Button } from 'reactstrap';
import { Formik, Form, FormikProps } from 'formik';
import * as Yup from 'yup';
import TextField from '../components/form/TextField';

export interface IDepotPinFormProps {
  onSubmit: (pin: string) => void;
  onCancel: () => void;
}

const parsePinInput = (pin: string) => {
  return pin.match(/\d+/g) ? pin.substring(0, 4) : '';
};

export const DepotPinForm: React.FC<IDepotPinFormProps> = ({ onSubmit, onCancel }) => {
  return (
    <Formik
      enableReinitialize
      validateOnChange
      validateOnBlur
      initialValues={{ pin: '' }}
      validationSchema={() => {
        return Yup.object().shape({
          pin: Yup.string()
            .trim()
            .matches(/[0-9]+/)
            .required(),
        });
      }}
      onSubmit={(v, { resetForm }) => {
        resetForm();
        onSubmit(parsePinInput(v.pin));
      }}>
      {(f: FormikProps<{ pin: string }>) => (
        <Form>
          <TextField
            dataAddress={`pin`}
            dataValue={parsePinInput(f.values.pin)}
            dataLabel={'Enter the Change Depot PIN'}
            readOnly={false}
            className={styles.pinInput}
            autoComplete="off"
          />
          <div className={styles.buttons}>
            <Button size="lg" outline disabled={f.isSubmitting} type="button" onClick={onCancel}>
              Cancel
            </Button>

            <Button
              type="submit"
              size="lg"
              disabled={!f.isValid || !f.values.pin || f.isSubmitting}>
              Next
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default DepotPinForm;
