import { DateTime } from 'luxon';
import {
  ChangeState,
  ContactedVia,
  ShiftTranslinkDirection,
  JobAttachmentSource,
  allContactedVia,
} from 'src/api/enums';
import { ListPageLoadCause } from 'src/domain';
import {
  ISplittedSkillSpecRequirements,
  consolidateSkillSpecRequirements,
  consolidateSkillSpecRequirementsIds,
  splitSkillSpecRequirements,
} from 'src/domain/entities/people/staffMember/SkillSpecsHelpers';
import {
  ISplittedTechSpecRequirements,
  consolidateTechSpecRequirements,
  splitTechSpecRequirements,
  splitTechSpecs,
} from 'src/domain/entities/workshop/techSpecs/TechSpecsHelpers';
import { CopyIcon, EditIcon, PlusIcon } from 'src/images/icons';
import DateTimeFormat, {
  DateTimeFormatSettings,
} from 'src/views/components/DateTimeFormat/DateTimeFormat';
import { StaffMemberFilter } from 'src/views/components/Page/fields/StaffMemberField';
import {
  getEditingFormattedTimeString,
  parseEditingFormattedTimeString,
} from 'src/views/components/Page/fields/subfields/TimeHelpers';
import CrudPage, { ICrudPageDef } from 'src/views/components/Page/pages/CrudPage';
import {
  ActionType,
  FieldType,
  IFieldOnChange,
  PagePrimarySize,
  PaneType,
  ShellModalSize,
} from 'src/views/definitionBuilders/types';
import getMaintainShiftModalDef from 'src/views/routes/operations/rail/createRailBooking/getMaintainShiftModalDef';
import {
  ISkipFatigueValidationSubmissionMeta,
  getSkipFatigueActionGroupDef,
} from 'src/views/routes/operations/shared/getSkipFatigueActionGroupDef';

import { observer } from 'mobx-react';
import { useRootStore } from 'src/domain/entities/RootStoreModel';
import './CreateRailBooking.scss';
import React from 'react';

type CreateRailBookingCommand = Operations.Domain.Commands.RailBooking.CreateRailBooking.CreateRailBookingCommand;
type AssetItem = Common.Queries.Workshop.GetFleetAssetList.AssetItem;
type RailTemplateListItem = Operations.Domain.Queries.ListRailTemplates.RailTemplateListItem;
type RailTemplateItem = Operations.Domain.Queries.ViewRailTemplate.RailTemplateItem;
type RailTemplateShiftItem = Operations.Domain.Queries.ViewRailTemplate.RailTemplateShiftItem;
type Depot = Common.Dtos.OperationsDepotDto;
type StaffMemberDto = Common.Dtos.StaffMemberDto;
type CustomerItem = Operations.Domain.Queries.SearchCustomers.CustomerItem;
type ContactItem = Operations.Domain.Queries.SearchContactsForCustomer.ContactItem;
type SubcontractorItem = Common.Dtos.SubcontractorItem;
type BoardingPointListItem = Operations.Domain.Queries.SearchBoardingPoint.BoardingPointListItem;
type CreateBoardingPointCommand = Operations.Domain.Commands.BoardingPoint.CreateBoardingPointCommand;
type RailTemplateShiftRouteItem = Operations.Domain.Commands.RailTemplate.CreateRailTemplate.RailTemplateShiftRouteItem;
type RailTemplateShiftRouteGroupItem = Operations.Domain.Commands.RailTemplate.CreateRailTemplate.RailTemplateShiftRouteGroupItem;
type JobForRailBookingDropdown = Operations.Domain.Queries.GetExistingJobsForRailBookingDropdown.JobForRailBookingDropdown;

type JobDropdownItem = { job: JobForRailBookingDropdown };
type AugmentedAvailableJob = JobForRailBookingDropdown & { dropdownDescription: string };
type RailBookingShiftAttachmentDetails = Operations.Domain.Commands.RailBooking.CreateRailBooking.RailBookingShiftAttachmentDetails;

type ITemplateShiftForm = ISplittedSkillSpecRequirements &
  ISplittedTechSpecRequirements & {
    railTemplateShiftId: number;
    shiftName: string;
    description: string;
    clockOn: string;
    departDepot: string;
    shiftCommence: string;
    shiftEnd: string;
    arriveDepot: string;
    clockOff: string;
    unpaidBreaks: string;
    departingFromDepot: Depot;
    departingFromDepotInCar?: boolean;
    arrivingAtDepot: Depot;
    arrivingAtDepotInCar?: boolean;
    asset: AssetItem;
    staffMember: StaffMemberDto;
    hasSubcontractor: boolean;
    subcontractor: SubcontractorItem;
    changeState: ChangeState;
    isRailTemplateShift: boolean;
    notes: string;
    runCount: number;
    routeGroups: IMaintainRailTemplateShiftRouteGroup[];
    attachmentDetails: RailBookingShiftAttachmentDetails[];
  };

type ISubmissionMeta = ISkipFatigueValidationSubmissionMeta & {};

interface IMaintainRailTemplateShiftRouteGroup {
  routes: IMaintainRailTemplateShiftRoute[];
  desto: string;
  name: string;
  changeState: ChangeState;
  translinkDirection: ShiftTranslinkDirection;
}

interface IMaintainRailTemplateShiftRoute {
  changeState: ChangeState;
  location: BoardingPointListItem;
  depart: string[];
}

interface ITemplateForm {
  date: string;
  time: string;
  railTemplate: RailTemplateListItem;
  changeState: ChangeState;
  shifts: ITemplateShiftForm[];
}

interface ICreateRailBookingForm {
  description: string;
  contactedVia?: ContactedVia;
  customer?: CustomerItem;
  contact?: ContactItem;
  templates: ITemplateForm[];
  existingJobsToLink: JobDropdownItem[];
  contractTypeId: number;
}

// We shouldn't usually get more than 5 days in a booking
const defaultTouchedFields = new Array(5).fill(null).map((_, i) => ['templates', i, 'shifts']);

const CreateRailBooking: React.FC = observer(() => {
  const rootStore = useRootStore();
  const canSkipFatigueValidation = rootStore.account.isAdminUser;
  const createRailBooking = rootStore.operations.rail.railBooking.createRailBooking;
  const listRailTemplates = rootStore.operations.rail.railTemplatesForDropdown.listItems;
  const railTemplates = rootStore.operations.rail.railTemplatesForDropdown.items.slice();
  const getRailTemplate = rootStore.operations.rail.railTemplate.getRailTemplate;
  const fleetAssets = rootStore.assets.fleetAssetListItems.slice();
  const loadFleetAssets = rootStore.assets.loadFleetAssets;
  const subcontractors = rootStore.operations.subcontractors.allSubcontractors.slice();
  const loadSubcontractors = rootStore.operations.subcontractors.loadAllSubcontractors;
  const loadDrivers = rootStore.people.staffMembers.loadDrivers;
  const loadExclusions = rootStore.operations.exclusionModel.loadExclusions;
  const depots = rootStore.operationsStartup.operationsDepots.slice();
  const searchCustomers = rootStore.operations.sales.customer.searchCustomers;
  const searchContactsForCustomer = rootStore.operations.sales.customer.searchContactsForCustomer;
  const loadStaffMembers = rootStore.people.staffMembers.loadAllStaffMembers;
  const states = rootStore.operations.sales.boardingPoint.states.slice();
  const searchBoardingPoints = rootStore.operations.sales.boardingPoint.searchBoardingPoints;
  const checkForUniqueBoardingPointName =
    rootStore.operations.sales.boardingPoint.checkForUniqueName;
  const onCreateBoardingPoint = (cmd: CreateBoardingPointCommand) =>
    rootStore.operations.sales.boardingPoint.createBoardingPoint(cmd, true);
  const skillSpecs = rootStore.people.skillSpecs.forRequirements.items.slice();
  const loadSkillSpecs = rootStore.people.skillSpecs.forRequirements.getAll;
  const techSpecs = rootStore.workshop.techSpecs.forRequirements.items.slice();
  const loadTechSpecs = rootStore.workshop.techSpecs.forRequirements.getAll;
  const searchTechSpecValues = rootStore.assets.searchTechSpecValues;
  const getTechSpecDropdownsOptions = rootStore.workshop.techSpecs.getTechSpecDropdownsOptions;
  const techSpecDropdownOptions = rootStore.workshop.techSpecs.techSpecDropdownOptions;
  const availableRailJobs = rootStore.operations.job.list.existingJobsForRailBookingDropdown.slice();
  const loadAvailableRailJobs =
    rootStore.operations.job.list.loadExistingJobsForRailBookingDropdown;
  const allRailBookingContractTypes = rootStore.operations.rail.railBookingContractTypes;
  const loadAllRailBookingContractTypes = rootStore.operations.rail.loadRailBookingContractTypes;
  const generateShiftAttachmentId =
    rootStore.operations.rail.railTemplate.generateShiftAttachmentId;
  const downloadAttachment = rootStore.operations.rail.railTemplate.downloadAttachment;

  const mapRoutesToCommand = (
    runCount: number,
    routeGroups: IMaintainRailTemplateShiftRouteGroup[]
  ): RailTemplateShiftRouteGroupItem[] => {
    if (!routeGroups) {
      return [];
    }
    return routeGroups
      .filter(x => x.changeState !== ChangeState.Deleted)
      .map(rg => {
        let routes: RailTemplateShiftRouteItem[] = [];
        rg.routes
          .filter(x => x.changeState !== ChangeState.Deleted)
          .forEach((route, routeNumber) => {
            for (let i = 0; i < runCount; i++) {
              const depart = i < route.depart.length ? route.depart[i] : undefined;
              routes.push({
                address: route.location.address,
                city: route.location.city,
                depart: depart,
                name: route.location.name,
                notes: route.location.notes,
                postcode: route.location.postcode,
                runNumber: i,
                routeNumber: routeNumber,
                state: route.location.state,
              });
            }
          });
        return {
          name: rg.name,
          desto: rg.desto,
          routes: routes,
          translinkDirection: rg.translinkDirection,
        };
      });
  };
  const handlePreSubmitForCreate = (
    railBooking: ICreateRailBookingForm,
    meta: ISubmissionMeta | undefined
  ): CreateRailBookingCommand => {
    return {
      description: railBooking.description,
      customerId: railBooking.customer && railBooking.customer.customerId,
      contactId: railBooking.contact && railBooking.contact.contactId,
      contactedVia: railBooking.contactedVia,
      existingJobsToLink:
        railBooking.existingJobsToLink && railBooking.existingJobsToLink.map(t => t.job.id),
      railDates: railBooking.templates
        .filter(t => t.changeState !== ChangeState.Deleted)
        .map(t => {
          return {
            date: t.date,
            railTemplateId: t.railTemplate && t.railTemplate.railTemplateId,
            additionalShifts: t.shifts
              .filter(x => x.changeState !== ChangeState.Deleted && !x.isRailTemplateShift)
              .map(s => {
                return {
                  arrivingAtDepotId: s.arrivingAtDepot.id,
                  arrivingAtDepotInCar: s.arrivingAtDepotInCar!,
                  assetId: s.asset && s.asset.id,
                  clockOff: s.clockOff,
                  clockOn: s.clockOn,
                  departingFromDepotId: s.departingFromDepot.id,
                  departingFromDepotInCar: s.departingFromDepotInCar!,
                  departDepot: s.departDepot,
                  arriveDepot: s.arriveDepot,
                  description: s.description,
                  shiftCommence: s.shiftCommence,
                  shiftEnd: s.shiftEnd,
                  shiftName: s.shiftName,
                  staffMemberId: s.staffMember && s.staffMember.id,
                  hasSubcontractor: s.hasSubcontractor,
                  subcontractorId: s.subcontractor && s.subcontractor.id,
                  unpaidBreaks: s.unpaidBreaks,
                  notes: s.notes,
                  routeGroups: mapRoutesToCommand(s.runCount, s.routeGroups),
                  skillSpecRequirements: consolidateSkillSpecRequirements(s),
                  techSpecRequirements: consolidateTechSpecRequirements(s, splittedTechSpecs),
                  attachmentDetails: (s.attachmentDetails?.map(a => ({
                    jobId: a.jobId,
                    attachmentId: a.attachmentId,
                    fileName: a.fileName,
                    availableOnTablet: a.availableOnTablet,
                    availableOnKiosk: a.availableOnKiosk,
                    changeState: a.changeState,
                    order: 0,
                    jobAttachmentSource: JobAttachmentSource.RailTemplateShift,
                    createdOn: DateTime.local(),
                    lastModifiedOn: DateTime.local(),
                  })) as unknown) as RailBookingShiftAttachmentDetails[],
                };
              }),
            railTemplateShifts: t.shifts
              .filter(x => x.changeState !== ChangeState.Deleted && x.isRailTemplateShift)
              .map(s => {
                return {
                  assetId: s.asset && s.asset.id,
                  id: s.railTemplateShiftId,
                  staffMemberId: s.staffMember && s.staffMember.id,
                  subcontractorId: s.subcontractor && s.subcontractor.id,
                  attachmentDetails: (s.attachmentDetails.map(a => ({
                    jobId: undefined,
                    attachmentId: a.attachmentId,
                    fileName: a.fileName,
                    availableOnTablet: a.availableOnTablet,
                    availableOnKiosk: a.availableOnKiosk,
                    changeState: a.changeState,
                    order: 0,
                    jobAttachmentSource: JobAttachmentSource.RailTemplateShift,
                    createdOn: DateTime.local(),
                    lastModifiedOn: DateTime.local(),
                  })) as unknown) as RailBookingShiftAttachmentDetails[],
                };
              }),
          };
        }),
      skipFatigueValidation: !!(meta && meta.skipFatigueValidation),
      contractTypeId: railBooking.contractTypeId,
    };
  };

  const setShifts = (
    fieldDataAddr: IFieldOnChange<{}>['fieldDataAddr'],
    setFormValue: IFieldOnChange<{}>['setFormValue'],
    railTemplateId: string | undefined,
    existingShifts: ITemplateShiftForm[]
  ) => {
    const lineAddr = [...fieldDataAddr];
    const lineAddrShifts = [...lineAddr.slice(0, lineAddr.length - 1), 'shifts'];

    // Lookup existing shift records where possible so that any selected vehicles/drivers are retained
    const existingLookup = new Map(
      existingShifts.map(s => [s.railTemplateShiftId, s] as [number, ITemplateShiftForm])
    );

    if (railTemplateId) {
      getRailTemplate(railTemplateId).then((r: RailTemplateItem) => {
        setFormValue(
          lineAddrShifts,
          r.shifts.map((s: RailTemplateShiftItem) => {
            return existingLookup.has(s.id)
              ? existingLookup.get(s.id)
              : {
                  railTemplateShiftId: s.id,
                  shiftName: s.shiftName,
                  clockOn: s.clockOn,
                  departDepot: s.departDepot,
                  shiftCommence: s.shiftCommence,
                  shiftEnd: s.shiftEnd,
                  arriveDepot: s.arriveDepot,
                  clockOff: s.clockOff,
                  unpaidBreaks: s.unpaidBreaks,
                  asset: s.asset,
                  isRailTemplateShift: true,
                  ...splitSkillSpecRequirements(s.skillSpecRequirements),
                  ...splitTechSpecRequirements(s.techSpecRequirements),
                  attachmentDetails: s.attachmentDetails,
                };
          }) as ITemplateShiftForm[]
        );
      });
    }
  };

  const getNextTemplateDate = (templateDates: Array<ITemplateForm>) => {
    const maxDate = templateDates.map(t => t.date).reduce((m, x) => (m > x ? m : x), '');
    return maxDate
      ? DateTime.fromISO(maxDate)
          .plus({ days: 1 })
          .toISODate()
      : undefined;
  };

  const splittedTechSpecs = splitTechSpecs(techSpecs);

  const validate = (
    existingJobsToLink: ICreateRailBookingForm['existingJobsToLink'],
    templates: ICreateRailBookingForm['templates']
  ): boolean => {
    const validExisting =
      existingJobsToLink && existingJobsToLink.length > 0 && existingJobsToLink.every(e => !!e.job);
    const validTemplates =
      templates && templates.every(t => t.shifts && t.shifts.length > 0 && !!t.date);

    return validExisting || validTemplates;
  };

  const getPageDef = (): ICrudPageDef => {
    const searchableAvailableRailJobs: AugmentedAvailableJob[] | undefined =
      availableRailJobs &&
      availableRailJobs.map(j => ({
        ...j,
        dropdownDescription: `${j.jobNumber} - ${j.assetRego} - ${DateTime.fromISO(j.clockOn, {
          zone: 'local',
        }).toLocaleString(DateTimeFormatSettings)} - ${j.staffMember} - ${j.description}`,
      }));

    return {
      primarySize: PagePrimarySize.full,
      primarySection: {
        title: 'Create a Rail Booking',
        defaultTouchedFields,
        clearStandardSecondaryActions: true,
        secondaryActions: [
          {
            actions: [
              {
                actionType: ActionType.submitActionButton,
                level: 'primary',
                subActionGroups: getSkipFatigueActionGroupDef<ISubmissionMeta>(
                  canSkipFatigueValidation,
                  {
                    customRender: () => {
                      return (
                        <div>
                          <p>
                            The fatigue validation for jobs from this rail booking will be skipped
                            for this submit. This skip will be recorded in job activity log.
                          </p>
                          <p>
                            <span>
                              Are you sure you want to submit and skip fatigue validation?
                            </span>
                          </p>
                        </div>
                      );
                    },
                  }
                ),
              },
              {
                actionType: ActionType.resetActionButton,
              },
            ],
          },
        ],
        panels: [
          {
            panes: [
              {
                paneType: PaneType.formFieldsPane,
                fields: [
                  {
                    fieldType: FieldType.textField,
                    label: 'Description',
                    dataAddr: 'description',
                    maxLength: 200,
                    mandatory: true,
                  },
                ],
              },
              {
                paneType: PaneType.formFieldsPane,
                columnCount: 3,
                fields: [
                  {
                    fieldType: FieldType.selectAsyncField,
                    dataAddr: 'customer',
                    label: 'Customer',
                    valueKey: 'customerId',
                    descriptionKey: 'customerName',
                    linkTo: d => `/sales/customers/${d.fieldValue.customerId}`,
                    loadOptionItems: searchCustomers,
                    onChange: api => {
                      const values = { ...api.formValues, contact: undefined };
                      api.setFormValues(values);
                    },
                    mandatory: true,
                  },
                  {
                    fieldType: FieldType.selectAsyncField,
                    dataAddr: 'contact',
                    label: 'Contact',
                    valueKey: 'contactId',
                    descriptionKey: 'contactName',
                    loadOptionItems: (s, d) =>
                      searchContactsForCustomer(
                        d.paneValue.customer && d.paneValue.customer.customerId,
                        s
                      ),
                    readonly: d => !d.parentValue.customer,
                    autoload: true,
                  },
                  {
                    fieldType: FieldType.selectField,
                    label: 'Contacted Via',
                    dataAddr: 'contactedVia',
                    valueKey: 'value',
                    descriptionKey: 'description',
                    useValueOnly: true,
                    optionItems: allContactedVia,
                    mandatory: true,
                  },
                ],
              },
              {
                paneType: PaneType.formFieldsPane,
                columnCount: 3,
                fields: [
                  {
                    fieldType: FieldType.selectField,
                    optionItems: allRailBookingContractTypes,
                    label: 'Contract Type',
                    dataAddr: 'contractTypeId',
                    valueKey: 'id',
                    descriptionKey: 'description',
                    useValueOnly: true,
                    mandatory: true,
                  },
                ],
              },
            ],
          },
          {
            title: 'Link Existing Jobs',
            dataAddr: 'existingJobsToLink',
            panes: [
              {
                paneType: PaneType.tablePane,
                dataRequiredForRows: 'paneValue',
                validate: d => {
                  const validateResult = validate(
                    d.sectionValue.existingJobsToLink,
                    d.sectionValue.templates
                  );
                  return validateResult ? undefined : 'Booking must contain at least one job';
                },
                fields: [
                  {
                    fieldType: FieldType.selectField,
                    dataAddr: 'job',
                    descriptionKey: 'dropdownDescription',
                    valueKey: 'id',
                    placeholder: 'Search by Driver, Vehicle, Job Number, or Description...',
                    label: 'Job',
                    mandatory: true,
                    optionItems: searchableAvailableRailJobs,
                    useOptionRendererAsValueRenderer: true,
                    optionRenderer: (d: AugmentedAvailableJob) => {
                      return (
                        <div className="existing-job-option">
                          <span>{d.jobNumber}</span>
                          <span>{d.assetRego}</span>
                          <span>{d.staffMember}</span>
                          <DateTimeFormat value={d.clockOn} />
                          <span>{d.description}</span>
                        </div>
                      );
                    },
                    valuesToExclude: d => {
                      const currentValues: JobForRailBookingDropdown[] | undefined =
                        d.paneValue &&
                        d.paneValue.map((entry: JobDropdownItem) => entry.job && entry.job.id);
                      return currentValues || [];
                    },
                  },
                  {
                    fieldType: FieldType.actionListField,
                    dataAddr: '',
                    columnWidth: '1px',
                    actionGroups: [
                      {
                        actions: [
                          {
                            actionType: ActionType.removeArrayItemActionButton,
                            label: 'Remove Line',
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
              {
                paneType: PaneType.actionListPane,
                actionGroups: [
                  {
                    actions: [
                      {
                        actionType: ActionType.addArrayItemActionButton,
                        label: 'Add Job',
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            title: 'Create New Jobs',
            dataAddr: 'templates',
            panes: [
              {
                paneType: PaneType.repeatingTabPane,
                getItemTitle: d => {
                  const date = d.itemValue.date
                    ? DateTime.fromISO(d.itemValue.date).toFormat('EEE, d MMM yyyy')
                    : '';
                  const template = d.itemValue.railTemplate
                    ? `: ${d.itemValue.railTemplate.name}`
                    : '';
                  return `${date}${template}`.trim();
                },
                deleteRemovedItems: true,
                itemPanes: [
                  {
                    paneType: PaneType.actionListPane,
                    actionGroups: [
                      {
                        actions: [
                          {
                            actionType: ActionType.addArrayItemActionButton,
                            label: 'Add next day',
                            getNewItemData: d => {
                              // When a date has been entered, new rows should always be "the next day" as adjacent days are required
                              const date = getNextTemplateDate(d.panelValue);
                              return { date, shifts: [] };
                            },
                          },
                          {
                            actionType: ActionType.addArrayItemActionButton,
                            label: 'Copy this day',
                            icon: <CopyIcon />,
                            getNewItemData: d => {
                              // When a date has been entered, new rows should always be "the next day" as adjacent days are required
                              const date = getNextTemplateDate(d.panelValue);
                              return { ...d.parentValue, date };
                            },
                          },
                          {
                            actionType: ActionType.removeArrayItemActionButton,
                            label: 'Remove this day',
                            hidden: d => {
                              const templateDates = d.panelValue as Array<ITemplateForm>;
                              const idx = templateDates.indexOf(d.parentValue);
                              return templateDates.length <= 1 || idx !== templateDates.length - 1;
                            },
                          },
                        ],
                      },
                    ],
                  },
                  {
                    paneType: PaneType.formFieldsPane,
                    columnCount: 4,
                    fields: [
                      {
                        fieldType: FieldType.dateField,
                        label: 'Date',
                        dataAddr: 'date',
                        validate: d => {
                          const validateResult = validate(
                            d.sectionValue.existingJobsToLink,
                            d.sectionValue.templates
                          );
                          const dateExists = !!d.fieldValue;
                          return validateResult
                            ? undefined
                            : dateExists
                            ? undefined
                            : 'Date is required if creating jobs';
                        },
                        readonly: d => {
                          const templateDates = d.panelValue as Array<ITemplateForm>;
                          const idx = templateDates.indexOf(d.parentValue);
                          return idx !== 0;
                        },
                        onChange: api => {
                          const templateDates = api.fieldData.panelValue as Array<ITemplateForm>;
                          const idx = templateDates.indexOf(api.fieldData.parentValue);
                          if (idx === 0 && templateDates.length > 1) {
                            const firstDate = api.newFieldValue as string | undefined;
                            const formValues: ICreateRailBookingForm = api.formValues;
                            const newFormValues: ICreateRailBookingForm = {
                              ...formValues,
                              templates: formValues.templates.map((t, i) => ({
                                ...t,
                                date: firstDate
                                  ? DateTime.fromISO(firstDate)
                                      .plus({ days: i })
                                      .toISODate()
                                  : '',
                              })),
                            };
                            api.setFormValues(newFormValues);
                          }
                        },
                      },
                    ],
                  },
                  {
                    paneType: PaneType.formFieldsPane,
                    columnCount: 4,
                    fields: [
                      {
                        fieldType: FieldType.selectField,
                        label: 'Template',
                        dataAddr: 'railTemplate',
                        valueKey: 'railTemplateId',
                        descriptionKey: 'name',
                        optionItems: railTemplates,
                        onChange: api => {
                          setShifts(
                            api.fieldDataAddr,
                            api.setFormValue,
                            api.newFieldValue && api.newFieldValue.railTemplateId,
                            api.fieldData.parentValue.shifts
                          );
                        },
                      },
                    ],
                  },
                  {
                    paneType: PaneType.tablePane,
                    title: 'Shifts',
                    dataAddr: 'shifts',
                    neverEditable: true,
                    validate: d => {
                      const validateResult = validate(
                        d.sectionValue.existingJobsToLink,
                        d.sectionValue.templates
                      );
                      return validateResult ? undefined : 'Booking must contain at least one job';
                    },
                    fields: [
                      {
                        fieldType: FieldType.readonlyField,
                        label: 'Shift Name',
                        dataAddr: 'shiftName',
                      },
                      {
                        fieldType: FieldType.timeField,
                        label: 'Clock On',
                        dataAddr: 'clockOn',
                        readonly: true,
                      },
                      {
                        fieldType: FieldType.timeField,
                        label: 'Depart Depot',
                        dataAddr: 'departDepot',
                        readonly: true,
                      },
                      {
                        fieldType: FieldType.timeField,
                        label: 'Shift Commence',
                        dataAddr: 'shiftCommence',
                        readonly: true,
                      },
                      {
                        fieldType: FieldType.timeField,
                        label: 'Shift End',
                        dataAddr: 'shiftEnd',
                        readonly: true,
                      },
                      {
                        fieldType: FieldType.timeField,
                        label: 'Arrive Depot',
                        dataAddr: 'arriveDepot',
                        readonly: true,
                      },
                      {
                        fieldType: FieldType.timeField,
                        label: 'Clock Off',
                        dataAddr: 'clockOff',
                        readonly: true,
                      },
                      {
                        fieldType: FieldType.durationField,
                        label: 'Unpaid Breaks',
                        dataAddr: 'unpaidBreaks',
                        readonly: true,
                        formatReadonly: d =>
                          getEditingFormattedTimeString(
                            parseEditingFormattedTimeString(d.fieldValue)
                          ),
                      },
                      {
                        fieldType: FieldType.assetSelectField,
                        readonly: true,
                        label: 'Vehicle',
                        dataAddr: 'asset',
                        optionItems: fleetAssets,
                        valueKey: 'id',
                        descriptionKey: 'name',
                        columnWidth: '10em',
                        hideAssetInfo: true,
                        hidden: d => d.parentValue.hasSubcontractor,
                        staffMemberId: d =>
                          d.parentValue.staffMember && d.parentValue.staffMember.id,
                        techSpecRequirements: d =>
                          consolidateTechSpecRequirements(d.parentValue, splittedTechSpecs),
                      },
                      {
                        fieldType: FieldType.staffMemberField,
                        readonly: true,
                        label: 'Staff Member',
                        dataAddr: 'staffMember',
                        columnWidth: '15em',
                        staffMemberFilter: StaffMemberFilter.hasDriversAuthorisation,
                        hidden: d => d.parentValue.hasSubcontractor,
                        assetId: d => d.parentValue.asset && d.parentValue.asset.id,
                        skillSpecRequirements: d =>
                          consolidateSkillSpecRequirementsIds(d.parentValue),
                        assetLicenceClassId: d => d.parentValue.asset?.licenceClassId,
                      },
                      {
                        fieldType: FieldType.selectField,
                        dataAddr: 'subcontractor',
                        optionItems: subcontractors,
                        valueKey: 'id',
                        descriptionKey: 'name',
                        label: 'Subcontractor',
                        readonly: true,
                        hidden: d => !d.parentValue.hasSubcontractor,
                      },
                      {
                        fieldType: FieldType.actionListField,
                        columnWidth: '1px',
                        actionGroups: [
                          {
                            actions: [
                              {
                                actionType: ActionType.modalActionButton,
                                label: 'Edit Shift',
                                icon: <EditIcon />,
                                hidden: d => d.actionValue.changeState === ChangeState.Deleted,
                                modalSize: ShellModalSize.twoThirds,
                                modalDef: getMaintainShiftModalDef(
                                  'edit',
                                  depots,
                                  fleetAssets,
                                  subcontractors,
                                  states,
                                  skillSpecs,
                                  splittedTechSpecs,
                                  searchBoardingPoints,
                                  checkForUniqueBoardingPointName,
                                  onCreateBoardingPoint,
                                  searchTechSpecValues,
                                  techSpecDropdownOptions,
                                  generateShiftAttachmentId,
                                  downloadAttachment
                                ),
                              },

                              {
                                actionType: ActionType.addArrayItemActionButton,
                                hidden: d => d.parentValue.isRailTemplateShift,
                                label: 'Copy',
                                icon: <CopyIcon />,
                                getNewItemData: d => {
                                  return {
                                    ...d.parentValue,
                                    staffMember: undefined,
                                    asset: undefined,
                                  };
                                },
                              },
                              {
                                actionType: ActionType.removeArrayItemActionButton,
                                label: 'Remove Line',
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    paneType: PaneType.actionListPane,
                    dataAddr: 'shifts',
                    actionGroups: [
                      {
                        actions: [
                          {
                            actionType: ActionType.modalActionButton,
                            label: 'Add Shift',
                            icon: <PlusIcon />,
                            modalSize: ShellModalSize.twoThirds,
                            modalDef: getMaintainShiftModalDef(
                              'add',
                              depots,
                              fleetAssets,
                              subcontractors,
                              states,
                              skillSpecs,
                              splittedTechSpecs,
                              searchBoardingPoints,
                              checkForUniqueBoardingPointName,
                              onCreateBoardingPoint,
                              searchTechSpecValues,
                              techSpecDropdownOptions,
                              generateShiftAttachmentId,
                              downloadAttachment
                            ),
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
        onFormPreSubmit: handlePreSubmitForCreate,
        onFormSubmit: createRailBooking,
      },
    };
  };

  const loadRequiredData = () => {
    const promises = [
      loadFleetAssets(),
      loadDrivers(),
      loadSubcontractors(),
      listRailTemplates({
        loadCause: ListPageLoadCause.mount,
        query: { deleted: false },
      }),
      loadStaffMembers(),
      loadExclusions(),
      loadSkillSpecs(),
      loadTechSpecs(),
      getTechSpecDropdownsOptions(),
      loadAvailableRailJobs(),
      loadAllRailBookingContractTypes(),
    ];
    return Promise.all(promises).then(() => undefined);
  };

  return (
    <CrudPage
      def={() => getPageDef()}
      mode="create"
      onLoadCreateDefaultData={loadRequiredData}
      createDefaultData={{ templates: [{ shifts: [] }] }}
    />
  );
});

export default CreateRailBooking;
