import { types, flow } from 'mobx-state-tree';
import { getAjax } from 'src/domain/services/storeEnvironment';
import { peopleUrls } from 'src/domain/services/apiUrls';

type PeopleDashboardData = People.Domain.Queries.GetPeopleDashboard.PeopleDashboardData;

export const PeopleDashboardModel = types
  .model('PeopleDashboardModel', {
    dashboardData: types.maybe(types.frozen<PeopleDashboardData>()),
  })
  .actions(self => {
    const ajax = getAjax(self);

    const loadDashboardData = flow(function*() {
      self.dashboardData = yield ajax.raw
        .get(peopleUrls.dashboardUrls.loadDashboard)
        .toPromise()
        .then<PeopleDashboardData>(r => r.response);
    });

    return {
      loadDashboardData,
    };
  });
