import './TimesheetTable.scss';

import Table from 'reactstrap/lib/Table';
import { DateFormat } from 'src/views/components/DateFormat';
import { DayOfWeekFormat } from 'src/views/components/DayOfWeekFormat';
import {
  jobTypeDescription,
  jobStatusDescription,
  leaveTypeDescription,
  workshopShiftTypeDescription,
  WorkshopShiftType,
  onCallPaymentStatusDescription,
  JobType,
} from 'src/api/enums';
import { DurationFormat } from 'src/views/components/DurationFormat';
import { Link } from 'react-router-dom';
import {
  ModalDefBuilder,
  IPaneData,
  IPaneMeta,
  FieldType,
  ActionType,
  ShellModalSize,
} from 'src/views/definitionBuilders/types';
import PageField from 'src/views/components/Page/fields/PageField';
import { EditIcon } from 'src/images/icons';
import { NestedField } from 'src/views/components/Page/forms';
import TimesheetDayTotals from '../TimesheetDayTotals';
import { AmendedHoursFormat } from 'src/views/components/AmendedHoursFormat';
import { MapTimesheetTotalsToRecordsTotals } from 'src/views/routes/people/timesheet/MaintainTimesheet/MaintainTimesheet';
import { ENABLE_SHOW_WAITING_TIME } from 'src/appSettings';

type TimesheetShiftAllowanceItem = Common.Dtos.TimesheetShiftAllowanceItem;
type TimesheetJobAllowanceItem = Common.Dtos.TimesheetJobAllowanceItem;
type TimesheetItem = Common.Dtos.TimesheetItem;
type TimesheetJobItem = Common.Dtos.TimesheetJobItem;
type TimesheetWorkshopShiftItem = Common.Dtos.TimesheetWorkshopShiftItem;
type TimesheetLeaveItem = Common.Dtos.TimesheetLeaveItem;
type TimesheetPublicHolidayItem = Common.Dtos.TimesheetPublicHolidayItem;

interface ITimesheetJobTableProps {
  jobs: TimesheetJobItem[];
  paneData: IPaneData;
  paneMeta: IPaneMeta;
  modalBuilder: () => ModalDefBuilder;
  editable: boolean;
  parentDataAddr: Array<string | number>;
  jobAllowances?: Array<TimesheetJobAllowanceItem>;
  day: string;
}

const TimesheetJobTable: React.FC<ITimesheetJobTableProps> = ({
  jobs,
  paneData,
  paneMeta,
  modalBuilder,
  editable,
  parentDataAddr,
  jobAllowances,
  day,
}) => {
  const dataAddr = [...parentDataAddr, 'jobs'];
  const showWaitingTime =
    ENABLE_SHOW_WAITING_TIME &&
    jobs.filter(
      job => job.jobTypeId === JobType.Charter || job.jobTypeId === JobType.ContractCharter
    ).length > 0;
  return (
    <div className="timesheet-job-table-component">
      {jobs && jobs.length ? (
        <Table borderless>
          <thead>
            <tr>
              <th className="small-cell">Job</th>
              <th className="small-cell">Type</th>
              <th className="small-cell">Status</th>
              <th>Description</th>
              <th className="small-cell">Start</th>
              <th className="small-cell">End</th>
              <th className="small-cell">Total Hours</th>
              <th className="small-cell">Unpaid Breaks</th>
              <th className="small-cell">Working Hours</th>
              <th className="small-cell">Overtime Hours</th>
              <th className="small-cell">Paid Hours</th>
              {showWaitingTime && <th className="small-cell">Waiting Time</th>}
              <th className="small-cell" />
            </tr>
          </thead>
          {jobs.map((record, recordIndex) => {
            return (
              <tbody key={record.jobId}>
                <NestedField field={[...dataAddr, recordIndex]}>
                  {fieldApi => {
                    const recordValue = fieldApi.formApi.getValue(
                      fieldApi.formApi.getFullField()
                    ) as TimesheetJobItem;
                    const jobAllowancesForRecord =
                      jobAllowances?.filter(a => a.jobId === record.jobId && a.date === day) || [];
                    // we merge paid hours cells so that additional rows are displayed straight under description
                    // instead of being pushed down by amendments - it's done to save the space on the page and printout
                    const rowSpanCount =
                      1 +
                      (recordValue.reasonForCompletionDetailsOverride ||
                      recordValue.overridePaidHoursReason
                        ? 1
                        : 0) +
                      (recordValue.amendedPaidHoursReason ? 1 : 0) +
                      (recordValue.calculationLog ? 1 : 0) +
                      (recordValue.reasonForDifference ? 1 : 0) +
                      (jobAllowancesForRecord.length > 0 ? 1 : 0);

                    return (
                      <>
                        <tr>
                          <td>
                            <Link to={`/operations/jobs/${record.jobId}`}>
                              {record.jobNumber}
                              {record.jobIsCancelled ? ' (Cancelled)' : ''}
                            </Link>
                          </td>
                          <td className="nowrap">{jobTypeDescription(record.jobTypeId)}</td>
                          <td className="nowrap">{jobStatusDescription(record.jobStatusId)}</td>
                          <td>{record.jobDescription}</td>
                          <td>
                            <AmendedHoursFormat
                              hours={recordValue.start}
                              amendedHours={recordValue.calculatedStart}
                            />
                          </td>
                          <td>
                            <AmendedHoursFormat
                              hours={recordValue.end}
                              amendedHours={recordValue.calculatedEnd}
                            />
                          </td>
                          <td>
                            <DurationFormat value={record.calculatedTotalHours} />
                          </td>
                          <td>
                            <AmendedHoursFormat
                              hours={recordValue.unpaidBreaks}
                              amendedHours={recordValue.calculatedUnpaidBreaks}
                            />
                          </td>
                          <td>
                            <DurationFormat value={record.calculatedWorkingHours} />
                          </td>
                          <td rowSpan={rowSpanCount}>
                            <PageField
                              fieldDef={{
                                fieldType: FieldType.customField,
                                dataAddr: 'amendedOvertimeHours',
                                label: 'Overtime Hours',
                                render: () => (
                                  <AmendedHoursFormat
                                    hours={recordValue.overtimeTotalHours}
                                    amendedHours={recordValue.amendedOvertimeHours}
                                  />
                                ),
                              }}
                              fieldMeta={{ formChange$: paneMeta.formChange$, hideLabel: true }}
                              paneData={paneData}
                              parentValue={recordValue}
                            />
                          </td>
                          <td rowSpan={rowSpanCount}>
                            <PageField
                              fieldDef={{
                                fieldType: FieldType.customField,
                                dataAddr: 'amendedPaidHours',
                                label: 'Paid Hours',
                                render: () => (
                                  <AmendedHoursFormat
                                    hours={recordValue.calculatedWorkingHours}
                                    defaultAmendedHours={recordValue.paidHours}
                                    amendedHours={recordValue.amendedPaidHours}
                                  />
                                ),
                              }}
                              fieldMeta={{ formChange$: paneMeta.formChange$, hideLabel: true }}
                              paneData={paneData}
                              parentValue={recordValue}
                            />
                          </td>
                          {ENABLE_SHOW_WAITING_TIME && record.jobTypeId === JobType.Charter && (
                            <td>
                              <DurationFormat value={record.waitingTime ?? '00:00'} />
                            </td>
                          )}
                          {showWaitingTime === true && record.jobTypeId !== JobType.Charter && (
                            <td>
                              <DurationFormat value={record.waitingTime ?? '00:00'} />
                            </td>
                          )}
                          <td>
                            <PageField
                              fieldDef={{
                                fieldType: FieldType.actionListField,
                                nowrap: true,
                                actionGroups: [
                                  {
                                    actions: [
                                      {
                                        actionType: ActionType.modalActionButton,
                                        label: 'Amend Paid Hours',
                                        icon: <EditIcon />,
                                        modalSize: ShellModalSize.oneQuarter,
                                        modalDef: modalBuilder(),
                                        hidden: !editable,
                                      },
                                    ],
                                  },
                                ],
                              }}
                              fieldMeta={{ formChange$: paneMeta.formChange$ }}
                              paneData={paneData}
                              parentValue={paneData.paneValue}
                            />
                          </td>
                        </tr>
                        {record.overridePaidHoursReason ||
                        record.reasonForCompletionDetailsOverride ? (
                          <tr>
                            <td />
                            <td colSpan={8} className="details-line">
                              <span>
                                <strong>Override reason:</strong>{' '}
                                {[
                                  record.overridePaidHoursReason,
                                  record.reasonForCompletionDetailsOverride,
                                ]
                                  .filter(x => !!x)
                                  .join(', ')}
                              </span>
                            </td>
                            <td />
                          </tr>
                        ) : null}
                        {recordValue.amendedPaidHoursReason ? (
                          <tr>
                            <td />
                            <td colSpan={8} className="details-line">
                              <strong>Amendment reason:</strong>{' '}
                              {recordValue.amendedPaidHoursReason}
                            </td>
                            <td />
                          </tr>
                        ) : null}
                        {recordValue.calculationLog ? (
                          <tr>
                            <td />
                            <td colSpan={8} className="details-line">
                              <strong>Calculation log:</strong> {recordValue.calculationLog}
                            </td>
                            <td />
                          </tr>
                        ) : null}
                        {recordValue.reasonForDifference ? (
                          <tr>
                            <td />
                            <td colSpan={8} className="details-line">
                              <strong>Reason for difference:</strong>{' '}
                              {recordValue.reasonForDifference}
                              {recordValue.approvalNumber && (
                                <>
                                  {' '}
                                  <strong className="approval-number">Approval number:</strong>{' '}
                                  {recordValue.approvalNumber}
                                </>
                              )}
                            </td>
                            <td />
                          </tr>
                        ) : null}
                        {jobAllowancesForRecord.length > 0 ? (
                          <tr>
                            <td />
                            <td colSpan={8} className="details-line">
                              <strong>Allowances: </strong>
                              {jobAllowancesForRecord
                                .map((item: TimesheetJobAllowanceItem) => {
                                  return item.allowanceName + ' x ' + item.quantity;
                                })
                                .join(', ')}
                            </td>
                            <td />
                          </tr>
                        ) : null}
                      </>
                    );
                  }}
                </NestedField>
              </tbody>
            );
          })}
        </Table>
      ) : null}
    </div>
  );
};

interface ITimesheetWorkshopShiftTableProps {
  shifts: TimesheetWorkshopShiftItem[];
  paneData: IPaneData;
  paneMeta: IPaneMeta;
  modalBuilder: () => ModalDefBuilder;
  editable: boolean;
  parentDataAddr: Array<string | number>;
  workshopShiftAllowances?: Array<TimesheetShiftAllowanceItem>;
  day: string;
}

const TimesheetWorkshopShiftTable: React.FC<ITimesheetWorkshopShiftTableProps> = ({
  shifts,
  paneData,
  paneMeta,
  modalBuilder,
  editable,
  parentDataAddr,
  workshopShiftAllowances,
  day,
}) => {
  const dataAddr = [...parentDataAddr, 'workshopShifts'];
  return (
    <div className="timesheet-workshop-shift-table-component">
      {shifts && shifts.length ? (
        <Table borderless>
          <thead>
            <tr>
              <th className="small-cell">Shift</th>
              <th className="small-cell">Type</th>
              <th className="small-cell nowrap">On Call</th>
              <th>ShiftName</th>
              <th className="small-cell">Start</th>
              <th className="small-cell">End</th>
              <th className="small-cell">Total Hours</th>
              <th className="small-cell">Unpaid Breaks</th>
              <th className="small-cell">Working Hours</th>
              <th className="small-cell">Overtime Hours</th>
              <th className="small-cell">Paid Hours</th>
              <th className="small-cell" />
            </tr>
          </thead>
          {shifts.map((record, recordIndex) => {
            return (
              <tbody key={`${record.shiftId}-${recordIndex}`}>
                <NestedField field={[...dataAddr, recordIndex]}>
                  {fieldApi => {
                    const recordValue = fieldApi.formApi.getValue(
                      fieldApi.formApi.getFullField()
                    ) as TimesheetWorkshopShiftItem;
                    const allowancesForShiftRecord =
                      workshopShiftAllowances?.filter(
                        a => a.shiftId === record.shiftId && a.date === day
                      ) || [];
                    // we merge paid hours cells so that additional rows are displayed straight under description
                    // instead of being pushed down by amendments - it's done to save the space on the page and printout
                    const rowSpanCount =
                      1 +
                      (recordValue.amendedPaidHoursReason ? 1 : 0) +
                      (recordValue.calculationLog ? 1 : 0) +
                      (recordValue.overtimeReason ? 1 : 0) +
                      (recordValue.boundsChangeReason ? 1 : 0) +
                      (allowancesForShiftRecord.length > 0 ? 1 : 0);

                    return (
                      <>
                        <tr>
                          <td>
                            {record.shiftDeleted ? (
                              <span title={`This shift has been deleted`}>Unknown</span>
                            ) : (
                              <Link to={`/workshop/shifts/${record.shiftId}`}>
                                {record.shiftNumber}
                              </Link>
                            )}
                          </td>
                          <td className="nowrap">
                            {workshopShiftTypeDescription(record.shiftType)}
                          </td>
                          <td>
                            {record.shiftType === WorkshopShiftType.OnCall &&
                              onCallPaymentStatusDescription(record.onCallPaymentStatus)}
                          </td>
                          <td className="nowrap">
                            {record.shiftName} <br />
                            <small>({record.shiftDepotName})</small>
                          </td>
                          <td>
                            <AmendedHoursFormat
                              hours={recordValue.start}
                              amendedHours={recordValue.calculatedStart}
                            />
                          </td>
                          <td>
                            <AmendedHoursFormat
                              hours={recordValue.end}
                              amendedHours={recordValue.calculatedEnd}
                            />
                          </td>
                          <td>
                            <DurationFormat value={record.calculatedTotalHours} />
                          </td>
                          <td>
                            <AmendedHoursFormat
                              hours={recordValue.unpaidBreaks}
                              amendedHours={recordValue.calculatedUnpaidBreaks}
                            />
                          </td>
                          <td>
                            <DurationFormat value={record.calculatedWorkingHours} />
                          </td>
                          <td rowSpan={rowSpanCount}>
                            <PageField
                              fieldDef={{
                                fieldType: FieldType.customField,
                                dataAddr: 'amendedOvertimeHours',
                                label: 'Overtime Hours',
                                render: () => (
                                  <AmendedHoursFormat
                                    hours={recordValue.overtimeTotalHours}
                                    amendedHours={recordValue.amendedOvertimeHours}
                                  />
                                ),
                              }}
                              fieldMeta={{ formChange$: paneMeta.formChange$, hideLabel: true }}
                              paneData={paneData}
                              parentValue={recordValue}
                            />
                          </td>
                          <td rowSpan={rowSpanCount}>
                            {record.shiftType !== WorkshopShiftType.OnCall ? (
                              <PageField
                                fieldDef={{
                                  fieldType: FieldType.customField,
                                  dataAddr: 'amendedPaidHours',
                                  label: 'Paid Hours',
                                  render: () => (
                                    <AmendedHoursFormat
                                      hours={recordValue.calculatedWorkingHours}
                                      defaultAmendedHours={recordValue.paidHours}
                                      amendedHours={recordValue.amendedPaidHours}
                                    />
                                  ),
                                }}
                                fieldMeta={{ formChange$: paneMeta.formChange$, hideLabel: true }}
                                paneData={paneData}
                                parentValue={recordValue}
                              />
                            ) : null}
                          </td>
                          <td>
                            {record.shiftType !== WorkshopShiftType.OnCall ? (
                              <PageField
                                fieldDef={{
                                  fieldType: FieldType.actionListField,
                                  nowrap: true,
                                  actionGroups: [
                                    {
                                      actions: [
                                        {
                                          actionType: ActionType.modalActionButton,
                                          label: 'Amend Paid Hours',
                                          icon: <EditIcon />,
                                          modalSize: ShellModalSize.oneQuarter,
                                          modalDef: modalBuilder(),
                                          hidden: !editable,
                                        },
                                      ],
                                    },
                                  ],
                                }}
                                fieldMeta={{ formChange$: paneMeta.formChange$ }}
                                paneData={paneData}
                                parentValue={paneData.paneValue}
                              />
                            ) : null}
                          </td>
                        </tr>
                        {recordValue.boundsChangeReason ? (
                          <tr>
                            <td />
                            <td colSpan={8} className="details-line">
                              <strong>Reason for changing shift bounds:</strong>{' '}
                              {recordValue.boundsChangeReason}
                            </td>
                            <td />
                          </tr>
                        ) : null}
                        {recordValue.overtimeReason ? (
                          <tr>
                            <td />
                            <td colSpan={8} className="details-line">
                              <strong>Overtime reason:</strong> {recordValue.overtimeReason}
                            </td>
                            <td />
                          </tr>
                        ) : null}
                        {recordValue.amendedPaidHoursReason ? (
                          <tr>
                            <td />
                            <td colSpan={8} className="details-line">
                              <strong>Amendment reason:</strong>{' '}
                              {recordValue.amendedPaidHoursReason}
                            </td>
                            <td />
                          </tr>
                        ) : null}
                        {recordValue.calculationLog ? (
                          <tr>
                            <td />
                            <td colSpan={8} className="details-line">
                              <strong>Calculation log:</strong> {recordValue.calculationLog}
                            </td>
                            <td />
                          </tr>
                        ) : null}
                        {allowancesForShiftRecord.length > 0 ? (
                          <tr>
                            <td />
                            <td colSpan={8} className="details-line">
                              <strong>Allowances: </strong>
                              {allowancesForShiftRecord
                                .map((item: TimesheetShiftAllowanceItem) => {
                                  return item.allowanceName + ' x ' + item.quantity;
                                })
                                .join(', ')}
                            </td>
                            <td />
                          </tr>
                        ) : null}
                      </>
                    );
                  }}
                </NestedField>
              </tbody>
            );
          })}
        </Table>
      ) : null}
    </div>
  );
};

interface ITimesheetLeaveTableProps {
  leaves: TimesheetLeaveItem[];
  paneData: IPaneData;
  paneMeta: IPaneMeta;
  modalBuilder: () => ModalDefBuilder;
  editable: boolean;
  parentDataAddr: Array<string | number>;
}

const TimesheetLeaveTable: React.FC<ITimesheetLeaveTableProps> = ({
  leaves,
  paneData,
  paneMeta,
  modalBuilder,
  editable,
  parentDataAddr,
}) => {
  const dataAddr = [...parentDataAddr, 'leaves'];
  return (
    <div className="timesheet-leave-table-component">
      {leaves && leaves.length ? (
        <Table borderless>
          <thead>
            <tr>
              <th>Leave Number</th>
              <th>Type</th>
              <th className="small-cell">Start</th>
              <th className="small-cell">End</th>
              <th className="small-cell">Leave Hours</th>
              <th className="small-cell">Paid Hours</th>
              <th className="small-cell" />
            </tr>
          </thead>
          {leaves.map((record, recordIndex) => {
            return (
              <tbody key={record.leaveId}>
                <NestedField field={[...dataAddr, recordIndex]}>
                  {fieldApi => {
                    const recordValue = fieldApi.formApi.getValue(
                      fieldApi.formApi.getFullField()
                    ) as TimesheetLeaveItem;
                    return (
                      <>
                        <tr>
                          <td>
                            {!record.leaveNumber || record.leaveNumber === 0 ? (
                              'Deleted'
                            ) : (
                              <Link to={`/people/leaves/${record.leaveId}`}>
                                {record.leaveNumber}
                              </Link>
                            )}
                          </td>
                          <td>{leaveTypeDescription(record.leaveType)}</td>
                          <td>
                            <DurationFormat value={record.start} />
                          </td>
                          <td>
                            <DurationFormat value={record.end} />
                          </td>
                          <td>
                            <DurationFormat value={record.leaveHours} />
                          </td>
                          <td rowSpan={recordValue.amendedPaidHoursReason ? 2 : 1}>
                            <PageField
                              fieldDef={{
                                fieldType: FieldType.customField,
                                dataAddr: 'amendedPaidHours',
                                label: 'Paid Hours',
                                render: () => (
                                  <AmendedHoursFormat
                                    hours={recordValue.paidHours}
                                    amendedHours={recordValue.amendedPaidHours}
                                  />
                                ),
                              }}
                              fieldMeta={{ formChange$: paneMeta.formChange$, hideLabel: true }}
                              paneData={paneData}
                              parentValue={recordValue}
                            />
                          </td>
                          <td>
                            <PageField
                              fieldDef={{
                                fieldType: FieldType.actionListField,
                                nowrap: true,
                                actionGroups: [
                                  {
                                    actions: [
                                      {
                                        actionType: ActionType.modalActionButton,
                                        label: 'Amend Paid Hours',
                                        icon: <EditIcon />,
                                        modalSize: ShellModalSize.oneQuarter,
                                        modalDef: modalBuilder(),
                                        hidden: !editable,
                                      },
                                    ],
                                  },
                                ],
                              }}
                              fieldMeta={{ formChange$: paneMeta.formChange$ }}
                              paneData={paneData}
                              parentValue={paneData.paneValue}
                            />
                          </td>
                        </tr>
                        {recordValue.amendedPaidHoursReason ? (
                          <tr>
                            <td colSpan={4} className="details-line">
                              <strong>Amendment reason:</strong>{' '}
                              {recordValue.amendedPaidHoursReason}
                            </td>
                            <td />
                          </tr>
                        ) : null}
                      </>
                    );
                  }}
                </NestedField>
              </tbody>
            );
          })}
        </Table>
      ) : null}
    </div>
  );
};

interface ITimesheetPublicHolidaysTableProps {
  publicHolidays: TimesheetPublicHolidayItem[];
  paneData: IPaneData;
  paneMeta: IPaneMeta;
  modalBuilder: () => ModalDefBuilder;
  editable: boolean;
  parentDataAddr: Array<string | number>;
}

const TimesheetPublicHolidayTable: React.FC<ITimesheetPublicHolidaysTableProps> = ({
  publicHolidays,
  paneData,
  paneMeta,
  modalBuilder,
  editable,
  parentDataAddr,
}) => {
  const dataAddr = [...parentDataAddr, 'publicHolidays'];
  return (
    <div className="timesheet-public-holiday-table-component">
      {publicHolidays && publicHolidays.length ? (
        <Table borderless>
          <thead>
            <tr>
              <th>Public Holiday</th>
              <th className="small-cell">Total Hours</th>
              <th className="small-cell">Paid Hours</th>
              <th className="small-cell" />
            </tr>
          </thead>
          {publicHolidays.map((record, recordIndex) => {
            return (
              <tbody key={record.holidayId}>
                <NestedField field={[...dataAddr, recordIndex]}>
                  {fieldApi => {
                    const recordValue = fieldApi.formApi.getValue(
                      fieldApi.formApi.getFullField()
                    ) as TimesheetPublicHolidayItem;
                    return (
                      <>
                        <tr>
                          <td>
                            <Link to={`/operations/holidays/${record.holidayId}`}>
                              {record.description}
                            </Link>
                          </td>
                          <td>
                            <DurationFormat value={record.publicHolidayHours} />
                          </td>
                          <td rowSpan={recordValue.amendedPaidHoursReason ? 2 : 1}>
                            <PageField
                              fieldDef={{
                                fieldType: FieldType.customField,
                                dataAddr: 'amendedPaidHours',
                                label: 'Paid Hours',
                                render: () => (
                                  <AmendedHoursFormat
                                    hours={recordValue.paidHours}
                                    amendedHours={recordValue.amendedPaidHours}
                                  />
                                ),
                              }}
                              fieldMeta={{ formChange$: paneMeta.formChange$, hideLabel: true }}
                              paneData={paneData}
                              parentValue={recordValue}
                            />
                          </td>
                          <td>
                            <PageField
                              fieldDef={{
                                fieldType: FieldType.actionListField,
                                nowrap: true,
                                actionGroups: [
                                  {
                                    actions: [
                                      {
                                        actionType: ActionType.modalActionButton,
                                        label: 'Amend Paid Hours',
                                        icon: <EditIcon />,
                                        modalSize: ShellModalSize.oneQuarter,
                                        modalDef: modalBuilder(),
                                        hidden: !editable,
                                      },
                                    ],
                                  },
                                ],
                              }}
                              fieldMeta={{ formChange$: paneMeta.formChange$ }}
                              paneData={paneData}
                              parentValue={paneData.paneValue}
                            />
                          </td>
                        </tr>
                        {recordValue.amendedPaidHoursReason ? (
                          <tr>
                            <td colSpan={2} className="details-line">
                              <strong>Amendment reason:</strong>{' '}
                              {recordValue.amendedPaidHoursReason}
                            </td>
                            <td />
                          </tr>
                        ) : null}
                      </>
                    );
                  }}
                </NestedField>
              </tbody>
            );
          })}
        </Table>
      ) : null}
    </div>
  );
};

interface ITimesheetTableProps {
  timesheet: TimesheetItem;
  modalBuilder: (day: string) => ModalDefBuilder;
  editable: boolean;
  paneData: IPaneData;
  paneMeta: IPaneMeta;
}

const TimesheetTable: React.FC<ITimesheetTableProps> = ({
  timesheet,
  modalBuilder,
  editable,
  paneData,
  paneMeta,
}) => {
  const dataAddr = ['records'];
  return (
    <div className="timesheet-table-component">
      {timesheet.records.map((record, recordIndex) => {
        const noRecords =
          (!record.jobs || record.jobs.length === 0) &&
          (!record.leaves || record.leaves.length === 0) &&
          (!record.publicHolidays || record.publicHolidays.length === 0) &&
          (!record.workshopShifts || record.workshopShifts.length === 0);
        const summary = noRecords ? undefined : MapTimesheetTotalsToRecordsTotals(record.totals);
        return (
          <div className="day-component dark-style" key={record.day}>
            <h4>
              <DayOfWeekFormat value={record.day} /> - <DateFormat value={record.day} />
            </h4>
            <TimesheetJobTable
              jobs={record.jobs}
              paneData={paneData}
              paneMeta={paneMeta}
              editable={editable}
              modalBuilder={() => modalBuilder(record.day)}
              parentDataAddr={[...dataAddr, recordIndex]}
              jobAllowances={timesheet.timesheetJobAllowanceItems}
              day={record.day}
            />
            <TimesheetWorkshopShiftTable
              shifts={record.workshopShifts}
              paneData={paneData}
              paneMeta={paneMeta}
              editable={editable}
              modalBuilder={() => modalBuilder(record.day)}
              parentDataAddr={[...dataAddr, recordIndex]}
              workshopShiftAllowances={timesheet.timesheetShiftAllowanceItems}
              day={record.day}
            />
            <TimesheetLeaveTable
              leaves={record.leaves}
              paneData={paneData}
              paneMeta={paneMeta}
              editable={editable}
              modalBuilder={() => modalBuilder(record.day)}
              parentDataAddr={[...dataAddr, recordIndex]}
            />
            <TimesheetPublicHolidayTable
              publicHolidays={record.publicHolidays}
              paneData={paneData}
              paneMeta={paneMeta}
              editable={editable}
              modalBuilder={() => modalBuilder(record.day)}
              parentDataAddr={[...dataAddr, recordIndex]}
            />
            {noRecords ? (
              <span>No records</span>
            ) : summary ? (
              <div className="day-summary">
                <TimesheetDayTotals summary={summary} />
              </div>
            ) : null}
          </div>
        );
      })}
    </div>
  );
};

export default TimesheetTable;
