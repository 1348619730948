import { ISchemaRecord } from 'src/views/components/AuditHistory/ISchemaRecord';
import { DateTimeFormat } from 'src/views/components/DateTimeFormat';
import { Link } from 'react-router-dom';
import { actionTypeDescription, busUsageStatusDescription } from 'src/api/enums';
import { getObject, isEmpty } from 'src/views/components/AuditHistory/AuditHistoryHelpers';
import { noValue } from 'src/views/components/AuditHistory/AuditHistoryField';

type StaffMemberDto = Common.Dtos.StaffMemberDto;

export default function getSchema(staffMembers: StaffMemberDto[]): ISchemaRecord[] {
  const isProgressSoftDeleted = (
    // tslint:disable-next-line:no-any
    changes: any,
    // tslint:disable-next-line:no-any
    address: any[]
  ) => {
    const progress = getObject(address.slice(0, address.length - 1), changes);
    const isDeleted = !!(progress.Deleted && progress.Deleted.c);
    return isDeleted;
  };

  return [
    {
      label: 'Start Date/Time',
      key: 'StartDateTime',
      format: value => <DateTimeFormat value={value} />,
    },
    {
      label: 'End Date/Time',
      key: 'EndDateTime',
      format: value => <DateTimeFormat value={value} />,
    },
    {
      label: 'Job',
      key: 'JobId',
      format: (value, changes) => {
        const taskNumber = changes.JobTaskOrder && changes.JobTaskOrder.c;
        return value ? `Scheduled ${taskNumber}` : 'Unscheduled';
      },
    },
    {
      label: 'Description',
      key: 'Description',
    },
    {
      label: 'Notes',
      key: 'Notes',
    },
    {
      label: 'OdometerReading',
      key: 'OdometerReading',
    },
    {
      label: 'Billable',
      key: 'Billable',
      format: d => (d ? 'Yes' : d === false ? 'No' : 'Not Set'),
    },
    {
      label: 'Breakdown',
      key: 'Breakdown',
      format: d => (d ? 'Yes' : d === false ? 'No' : 'Not Set'),
    },
    {
      label: 'Incident',
      key: 'Incident',
      format: d => (d ? 'Yes' : d === false ? 'No' : 'Not Set'),
    },
    {
      label: 'Fit for Service',
      key: 'FitForService',
      format: d => (d ? 'Yes' : d === false ? 'No' : 'Not Set'),
    },
    {
      label: 'Signed Defect Sheet',
      key: 'SignedDefectSheet',
      format: d => (d ? 'Yes' : d === false ? 'No' : 'Not Set'),
    },
    {
      label: 'Unfit Reason',
      key: 'UnfitReason',
    },
    {
      label: 'Accident',
      key: 'Accident',
      format: d => (d ? 'Yes' : d === false ? 'No' : 'Not Set'),
    },
    {
      label: 'Job Task Order',
      key: 'JobTaskOrder',
      format: d => d + 1,
    },
    {
      label: 'Parts',
      key: 'Parts',
      isArray: true,
      childRecords: [
        {
          key: 'PartId',
          label: 'Parts',
          format: value => {
            return <Link to={`/workshop/parts/${value}`}>{value ? 'Added' : 'Removed'}</Link>;
          },
        },
        {
          key: 'Description',
          label: 'Description',
        },
        {
          key: 'Quantity',
          label: 'Quantity',
        },
        {
          key: 'UnitPrice',
          label: 'UnitPrice',
        },
        {
          key: 'UsedDate',
          label: 'UsedDate',
          format: value => <DateTimeFormat value={value} />,
        },
      ],
    },
    {
      label: 'Details',
      key: 'CompletionDetails',
      isArray: true,
      childRecords: [
        {
          key: 'Id',
          label: 'Id',
          showUnchanged: true,
        },
        {
          key: 'Issue',
          label: 'Issue',
        },
        {
          key: 'WorkDone',
          label: 'WorkDone',
        },
        {
          key: 'WorkDoneBy',
          label: 'WorkDoneBy',
          format: value =>
            value && staffMembers ? (
              <Link to={`/people/staff-members/${value}`}>
                {staffMembers.find(x => x.id === value)!.name}
              </Link>
            ) : null,
        },
        {
          key: 'ActionType',
          label: 'Action',
          format: value => actionTypeDescription(value),
        },
        {
          key: 'SignedOffBy',
          label: 'SignedOffBy',
        },
        {
          key: 'CreatedBy',
          label: 'CreatedBy',
        },
      ],
    },
    {
      label: 'Staff Labour',
      key: 'LabourItems',
      isArray: true,
      childRecords: [
        {
          key: 'Date',
          label: 'Date',
          format: value => <DateTimeFormat value={value} />,
        },
        {
          key: 'Hours',
          label: 'Hours',
        },
        {
          key: 'StaffMemberId',
          label: 'Staff Member',
          format: value => {
            if (value && staffMembers) {
              const staffMember = staffMembers.find(x => x.id === value);
              return staffMember ? (
                <Link to={`/people/staff-members/${value}`}>{staffMember.name}</Link>
              ) : (
                <span>Unknown Staff Member</span>
              );
            }

            return null;
          },
        },
      ],
    },
    {
      label: 'Attachment',
      key: 'AttachmentDetails',
      isArray: true,
      childRecords: [
        {
          key: 'FileName',
          label: 'Attachment',
        },
      ],
    },
    {
      key: 'JobBusUsages',
      sectionLabel: (diff, changes, address) => {
        const option = getObject(address, changes);

        if (!option.BusUsageStatusId) {
          return `Bus Usage - Unknown`;
        }

        return `Bus Usage - ${
          isEmpty(option.BusUsageStatusId.c)
            ? busUsageStatusDescription(option.BusUsageStatusId.o)
            : busUsageStatusDescription(option.BusUsageStatusId.c)
        }`;
      },
      isArray: true,
      orderKey: 'OccurredAt',
      childRecords: [
        {
          label: 'Progress',
          key: 'BusUsageStatusId',
          showUnchanged: (changes, address) => isProgressSoftDeleted(changes, address),
          format: (value, changes, address, valueType) => {
            return isProgressSoftDeleted(changes, address) && valueType === 'after'
              ? noValue
              : busUsageStatusDescription(value);
          },
        },
        {
          label: 'Staff Member',
          key: 'StaffMemberId',
          showUnchanged: (changes, address) => isProgressSoftDeleted(changes, address),
          format: (value, changes, address, valueType) => {
            const staff = value && staffMembers && staffMembers.find(x => x.id === value);
            return isProgressSoftDeleted(changes, address) && valueType === 'after' ? (
              noValue
            ) : staff ? (
              <Link to={`/people/staff-members/${staff.id}`}>{staff.name}</Link>
            ) : null;
          },
        },
        {
          label: 'Occurred At',
          key: 'OccurredAt',
          showUnchanged: (changes, address) => isProgressSoftDeleted(changes, address),
          format: (value, changes, address, valueType) => {
            return isProgressSoftDeleted(changes, address) && valueType === 'after' ? (
              noValue
            ) : (
              <DateTimeFormat value={value} />
            );
          },
        },
      ],
    },
  ];
}
