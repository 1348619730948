import inject from 'src/views/injectFromStore';
import Omit from 'src/infrastructure/omit';
import MaintainReturnedPartCredit, {
  IMaintainReturnedPartCreditProps,
} from './MaintainReturnedPartCredit';

const Container = inject<
  Omit<IMaintainReturnedPartCreditProps, 'mode'>,
  IMaintainReturnedPartCreditProps
>(allStores => ({
  canManagePurchaseOrders: allStores.rootStore.account.isWorkshopDepartmentMember,
  purchaseOrder: allStores.rootStore.workshop.purchaseOrder.purchaseOrder,
  assets: allStores.rootStore.assets.assetListItems.slice(),
  loadPurchaseOrder: allStores.rootStore.workshop.purchaseOrder.loadPurchaseOrder,
  loadAssetListItems: allStores.rootStore.assets.loadAssetListItems,
  getJobTasksForAsset: allStores.rootStore.jobTask.getJobTasksForAsset,
  createReturnedPartCredit: allStores.rootStore.workshop.purchaseOrder.createReturnedPartCredit,
  updateReturnedPartCredit: allStores.rootStore.workshop.purchaseOrder.updateReturnedPartCredit,
  deleteReturnedPartCredit: allStores.rootStore.workshop.purchaseOrder.deleteReturnedPartCredit,
  onMarkCreditNoteAsExported: allStores.rootStore.workshop.purchaseOrder.markCreditNoteAsExported,
  onRemoveExportedIndicatorFromCreditNote:
    allStores.rootStore.workshop.purchaseOrder.removeExportedIndicatorFromCreditNote,
}))(MaintainReturnedPartCredit);

export default Container;
