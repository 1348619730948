import inject from 'src/views/injectFromStore';
import Omit from 'src/infrastructure/omit';
import MaintainLostPropertyItem, { IMaintainLostPropertyItemProps } from './MaintainLostProperty';

const Container = inject<
  Omit<IMaintainLostPropertyItemProps, 'mode'>,
  IMaintainLostPropertyItemProps
>((allStores, p) => ({
  canManageLostPropertyItem: allStores.rootStore.account.canCreateOrUpdateRecords,
  LostPropertyItem: Object.assign(
    {},
    allStores.rootStore.compliance.lostProperty.item.LostProperty
  ),
  loadLostPropertyItem: allStores.rootStore.compliance.lostProperty.item.loadLostPropertyItem,
  updateLostPropertyItem: allStores.rootStore.compliance.lostProperty.item.updateLostPropertyItem,
  createLostPropertyItem: allStores.rootStore.compliance.lostProperty.item.createLostPropertyItem,
  loadStaffMembers: allStores.rootStore.people.staffMembers.loadAllStaffMembers,
  assets: allStores.rootStore.assets.fleetAssetListItems.slice(),
  loadAssetListItems: allStores.rootStore.assets.loadFleetAssets,
}))(MaintainLostPropertyItem);

export default Container;
