import {
  ActionType,
  FieldType,
  INestingPaneDef,
  PaneType,
  IPaneData,
} from 'src/views/definitionBuilders/types';
import { isDefined } from 'src/infrastructure/typeUtils';
import { precisionRound } from 'src/infrastructure/mathUtil';

type ExtraType = Operations.Domain.Queries.ViewExtraType.ExtraTypeItem;
type Extra = {
  changeState: Common.ChangeState;
  extraType: ExtraType;
  id: number;
  overridePrice?: number;
  price: number;
  quantity: number;
  unitPrice?: number;
};

export default function getExtrasPane(
  extraTypes: ExtraType[],
  isContractPriceManuallyManaged: boolean,
  hidden: (data: IPaneData) => boolean,
  editable: boolean,
  aggregateType: 'quote' | 'shift'
): INestingPaneDef {
  const isQuote = aggregateType === 'quote';
  return {
    paneType: PaneType.nestingPane,
    dataAddr: 'extras',
    hidden: hidden,
    panes: [
      {
        paneType: PaneType.tablePane,
        title: 'Extras',
        dataRequiredForRows: 'paneValue',
        fields: [
          {
            fieldType: FieldType.selectField,
            dataAddr: 'extraType',
            label: 'Extra Type',
            valueKey: 'id',
            descriptionKey: 'description',
            optionItems: extraTypes,
            mandatory: true,
            valuesToDisable: d => {
              const extras = d.paneValue as Extra[];
              return (extras ? extras.map(v => v.extraType && v.extraType.id) : []).filter(
                isDefined
              );
            },
            valuesToExclude: extraTypes.filter(t => !t.isActive).map(t => t.id),
            onChange: api => {
              const extra = api.fieldData.parentValue as Extra;
              const priceDataAddr = api.fieldDataAddr;

              priceDataAddr.pop();
              api.setFormValue([...priceDataAddr, 'unitPrice'], api.fieldData.fieldValue.price);
              const price = calculateExtraPrice(
                extraTypes,
                api.newFieldValue && api.newFieldValue.id,
                api.fieldData.fieldValue.unitPrice ?? 0,
                extra.quantity
              );

              api.setFormValue([...priceDataAddr, 'price'], price);
            },
          },
          {
            fieldType: FieldType.numericField,
            dataAddr: 'quantity',
            numericConfig: { numericType: 'unsignedInt' },
            label: 'Quantity',
            columnWidth: '6em',
            mandatory: true,
            onChange: api => {
              const extra = api.fieldData.parentValue as Extra;
              if (!extra.extraType) {
                return;
              }

              if (api.newFieldValue) {
                const price = calculateExtraPrice(
                  extraTypes,
                  extra.extraType.id,
                  extra.unitPrice
                    ? extra.unitPrice
                    : api.fieldData.parentValue.extraType.price ?? 0,
                  api.newFieldValue
                );
                const priceDataAddr = api.fieldDataAddr;
                priceDataAddr.pop();

                api.setFormValue([...priceDataAddr, 'price'], price);
              }
            },
          },
          {
            fieldType: FieldType.numericField,
            dataAddr: 'unitPrice',
            numericConfig: { numericType: 'unsignedDecimal', maxPointDigits: 4 },
            label: 'Unit Price',
            columnWidth: '8em',
            readonly: isQuote,
            hidden: isContractPriceManuallyManaged,
            columnAutoHide: isContractPriceManuallyManaged,
            formatReadonly: d => {
              if (isQuote) {
                return d.parentValue.extraType
                  ? d.parentValue.extraType.price
                  : d.parentValue.quantity && d.parentValue.price
                  ? d.parentValue.price / d.parentValue.quantity
                  : 0;
              }

              return d.fieldValue;
            },

            onChange: api => {
              const extra = api.fieldData.parentValue as Extra;
              if (!extra.extraType) {
                return;
              }

              if (api.newFieldValue !== api.oldFieldValue) {
                const price = calculateExtraPrice(
                  extraTypes,
                  extra.extraType.id,
                  api.newFieldValue,
                  extra.quantity
                );

                const priceDataAddr = api.fieldDataAddr;
                priceDataAddr.pop();
                api.setFormValue([...priceDataAddr, 'price'], price);
              }
            },
          },
          {
            fieldType: FieldType.readonlyField,
            dataAddr: 'price',
            label: 'Price',
            columnWidth: '8em',
            hidden: isContractPriceManuallyManaged,
            columnAutoHide: isContractPriceManuallyManaged,
          },
          {
            fieldType: FieldType.numericField,
            dataAddr: 'overridePrice',
            numericConfig: { numericType: 'unsignedDecimal', maxPointDigits: 4 },
            label: 'Override Price',
            columnWidth: '8em',
            hidden: isContractPriceManuallyManaged,
            columnAutoHide: isContractPriceManuallyManaged,
          },
          {
            fieldType: FieldType.actionListField,
            hidden: !editable,
            columnWidth: '1px',
            actionGroups: [
              {
                actions: [
                  {
                    actionType: ActionType.removeArrayItemActionButton,
                    label: 'Remove Extra',
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        paneType: PaneType.actionListPane,
        hidden: !editable,
        actionGroups: [
          {
            actions: [
              {
                actionType: ActionType.addArrayItemActionButton,
                label: 'Add Extra',
              },
            ],
          },
        ],
      },
    ],
  };
}

function calculateExtraPrice(
  extraTypes: Array<ExtraType>,
  extraTypeId: string,
  unitPrice: number,
  quantity?: number
) {
  const extraType = extraTypes.find(e => e.id === extraTypeId);

  if (!!extraType) {
    const price = unitPrice * (quantity || 0);
    return precisionRound(price, 2);
  } else {
    return 0;
  }
}
