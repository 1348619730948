import { types, flow, addMiddleware, Instance } from 'mobx-state-tree';

import { HistoryModel } from './app/HistoryModel';
import { NotificationsModel } from './app/NotificationsModel';
import { KBL_RELEASE_ID } from 'src/appSettings';
import { KioskModel } from './kiosk/KioskModel';
import { IStoreEnvironment, createAjax, createBus, hubConnection } from 'src/domain/services';
import { globalErrorHandlerMiddleware } from 'src/domain/middlewares/globalErrorHandler';
import { KioskAccountModel } from './user/KioskAccountModel';
import { useContext } from 'react';
import { RootStoreContext } from 'src/views/App';

export const rootStoreInitialState = {};

const KioskRootStoreModel = types
  .model('KioskRootStoreModel', {
    releaseMismatch: types.optional(types.boolean, false),
    isAlcoReadingEnabledDevice: types.optional(types.boolean, false),
    history: types.optional(HistoryModel, {}),
    notifications: types.optional(NotificationsModel, {}),
    account: types.optional(KioskAccountModel, { companyHasDriversApp: false }),
    kiosk: types.optional(KioskModel, {}),
  })
  .views(self => ({
    get isWorkshopKiosk() {
      return (
        self.history &&
        self.history.currentLocation &&
        self.history.currentLocation.pathname.indexOf('/wskiosk') >= 0
      );
    },
    get home() {
      return this.isWorkshopKiosk ? '/wskiosk' : '/kiosk';
    },
  }))
  .actions(self => {
    const globalErrorHandlerDisposer = addMiddleware(self, globalErrorHandlerMiddleware);

    function beforeDestroy() {
      globalErrorHandlerDisposer();
    }

    const afterCreate = flow(function*() {
      if (self.account.isAuthenticated === undefined) {
        yield self.account.loadKioskProfile();
      }

      if (
        location.pathname.indexOf('/wskiosk') >= 0 &&
        !location.pathname.endsWith('/authorise-device')
      ) {
        yield self.kiosk.workshop.loadWorkshopDepots();
      }
    });

    const checkRelease = (serverReleaseId: string | null) => {
      self.releaseMismatch = !!serverReleaseId && KBL_RELEASE_ID !== serverReleaseId;
    };

    const refreshSite = () => {
      location.reload(true);
    };

    const refreshSiteIfRequired = () => {
      if (self.releaseMismatch) {
        refreshSite();
      }
    };

    return {
      afterCreate,
      beforeDestroy,
      checkRelease,
      refreshSite,
      refreshSiteIfRequired,
    };
  });

export interface IKioskRootStoreModel extends Instance<typeof KioskRootStoreModel> {}

export async function getKioskStore(initialStateMerge?: {}) {
  let store: IKioskRootStoreModel;
  const env: IStoreEnvironment = {
    ajax: createAjax(
      () => store,
      r => store.checkRelease(r.xhr.getResponseHeader('kbl-release-id'))
    ),
    bus: createBus(),
    signals: hubConnection(),
  };
  const initState = { ...rootStoreInitialState, ...(initialStateMerge || {}) };
  store = KioskRootStoreModel.create(initState, env);
  return store;
}
export function useKioskRootStore() {
  const store = useContext(RootStoreContext);
  if (store === null) {
    throw new Error('Store cannot be null, please add a context provider');
  }
  return store as IKioskRootStoreModel;
}
