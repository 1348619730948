import { IRootStoreModel } from 'src/domain/entities/RootStoreModel';
import { UiRoute } from 'src/domain/services/buildUiRoutes';
import { UiRouteName } from 'src/domain/baseTypes';

export default function buildUiRoutes(store: IRootStoreModel) {
  const codeOfConductCreate = new UiRoute(
    UiRouteName.complianceCodeOfConductCreate,
    '/compliance/code-of-conducts/create',
    () => 'Create Code Of Conduct',
    true
  );

  const codeOfConductUpdate = new UiRoute(
    UiRouteName.complianceCodeOfConductUpdate,
    '/compliance/code-of-conducts/:id',
    () => 'Manage Code Of Conduct',
    true
  );

  const codeOfConductList = new UiRoute(
    UiRouteName.complianceCodeOfConductList,
    '/compliance/code-of-conducts',
    () => 'Code Of Conducts',
    false,
    false,
    [codeOfConductCreate, codeOfConductUpdate]
  );

  return {
    [UiRouteName.complianceCodeOfConductList]: codeOfConductList,
    [UiRouteName.complianceCodeOfConductCreate]: codeOfConductCreate,
    [UiRouteName.complianceCodeOfConductUpdate]: codeOfConductUpdate,
  };
}
