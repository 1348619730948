import './PresetFilterCollection.scss';
import { PureComponent, Component } from 'react';
import cn from 'classnames';
import { Button } from 'reactstrap';
import { FilterIcon } from 'src/images/icons';
import withQueryParams, { IQueryParamsProps } from 'src/views/hocs/withQueryParams';
import {
  IActionMeta,
  IActionData,
  IPresetFilterCollectionDef,
  IPresetFilter,
} from 'src/views/definitionBuilders/types';

interface IPresetFilterItemProps extends IPresetFilter {
  onClick: (filter: object) => void;
}

class PresetFilterItem extends PureComponent<IPresetFilterItemProps> {
  private readonly handleClick = () => {
    this.props.onClick(this.props.filter);
  };

  render() {
    const { label, count } = this.props;
    return (
      <li className="preset-filter-item">
        <Button onClick={this.handleClick}>
          <span className="item-count">{count}</span>
          <span className="item-label">{label}</span>
          <FilterIcon />
        </Button>
      </li>
    );
  }
}

interface IPresetFilterCollectionProps {
  actionDef: IPresetFilterCollectionDef;
  actionMeta: IActionMeta;
  actionData: IActionData;
}

class PresetFilterCollection extends Component<IPresetFilterCollectionProps & IQueryParamsProps> {
  private readonly handleApply = (filter: object) => {
    // Clear all filter fields by default, so that specific ones can be set
    const defaultFilter = this.props.actionDef.filterDataAddrs.reduce((acc, d) => {
      acc[d] = undefined;
      return acc;
    }, {});
    this.props.updateQueryParams({ ...defaultFilter, ...filter });
  };

  render() {
    const { actionDef } = this.props;
    return (
      <div className={cn('preset-filter-collection-component', 'variable-size')}>
        <ul className="list-unstyled">
          {actionDef.presetFilters.map((p, i) => (
            <PresetFilterItem key={i} {...p} onClick={this.handleApply} />
          ))}
        </ul>
      </div>
    );
  }
}

export default withQueryParams(PresetFilterCollection);
