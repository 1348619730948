import { buildListPageApiSearchModelTypedQuery } from 'src/domain/modelBuilders/buildListPageApiSearchModel';
import { types, getRoot, flow } from 'mobx-state-tree';
import { getAjax, NotificationType } from 'src/domain/services';
import { IRootStoreModel } from 'src/domain/entities/RootStoreModel';

type ListAssetsQuery = Workshop.Domain.Queries.ListAssets.ListAssetsQuery;
type ExportAssetFuelToExcelQuery = Workshop.Domain.Queries.ExportAssetFuelToExcel.ExportAssetFuelToExcelQuery;
type ExportFuelConsumptionToExcelQuery = Workshop.Domain.Queries.ExportFuelConsumptionReportToExcel.ExportFuelConsumptionReportToExcelQuery;

const AssetsListModel = buildListPageApiSearchModelTypedQuery<ListAssetsQuery>()(
  'AssetsListModel',
  d => d.ajax.asset.listAssets(d.query)
);

const ExportAssetsModel = types.model('ExportAssetsModel').actions(self => {
  const ajax = getAjax(self);
  const root = getRoot(self) as IRootStoreModel;

  const exportToExcel = flow(function*(query: Partial<ListAssetsQuery>) {
    root.notifications.addNotification(`The file is being generated ...`, {
      type: NotificationType.info,
    });

    return yield ajax.asset.exportAssetsToExcel(query);
  });

  return {
    exportToExcel,
  };
});

const ExportAssetsComponentsModel = types.model('ExportAssetsComponentsModel').actions(self => {
  const ajax = getAjax(self);
  const root = getRoot(self) as IRootStoreModel;

  const exportComponentsToExcel = flow(function*(query: Partial<ListAssetsQuery>) {
    root.notifications.addNotification(`The file is being generated ...`, {
      type: NotificationType.info,
    });

    return yield ajax.asset.exportAssetsComponentsToExcel(query);
  });

  const exportFuelsToExcel = flow(function*(query: ExportAssetFuelToExcelQuery) {
    root.notifications.addNotification(`The file is being generated ...`, {
      type: NotificationType.info,
    });

    return yield ajax.asset.exportAssetsFuelsToExcel(query);
  });

  const exportFuelConsumptionsToExcel = flow(function*(query: ExportFuelConsumptionToExcelQuery) {
    root.notifications.addNotification(`The file is being generated ...`, {
      type: NotificationType.info,
    });

    return yield ajax.asset.exportAssetsConsumptionToExcel(query);
  });

  const exportServicesToExcel = flow(function*(query: Partial<ListAssetsQuery>) {
    root.notifications.addNotification(`The file is being generated ...`, {
      type: NotificationType.info,
    });

    return yield ajax.asset.exportAssetServicesToExcel(query);
  });

  const exportAssetPartRegisterToExcel = flow(function*(query: Partial<ListAssetsQuery>) {
    root.notifications.addNotification(`The file is being generated ...`, {
      type: NotificationType.info,
    });

    return yield ajax.asset.exportAssetPartRegisterToExcel(query);
  });

  const exportAssetMaintenanceCostsToExcelQuery = flow(function*(query: Partial<ListAssetsQuery>) {
    root.notifications.addNotification(`The file is being generated ...`, {
      type: NotificationType.info,
    });

    return yield ajax.asset.exportAssetMaintenanceCostsToExcel(query);
  });

  return {
    exportComponentsToExcel,
    exportServicesToExcel,
    exportAssetPartRegisterToExcel,
    exportFuelsToExcel,
    exportFuelConsumptionsToExcel,
    exportAssetMaintenanceCostsToExcelQuery,
  };
});

export const ListAssetsModel = types.compose(
  AssetsListModel,
  ExportAssetsModel,
  ExportAssetsComponentsModel
);
