import {
  IAllocationsBoundedItem,
  isOperationsJob,
  isWorkshopJob,
  isPeopleLeave,
  isMarkOutOfServiceItem,
  isWorkshopShift,
} from 'src/domain/entities/operations/job/ListJobsForAllocationsModel';
import AllocationJob from './AllocationJob';
import WorkshopJob from './WorkshopJob';
import MarkOutOfServiceJob from './MarkOutOfServiceJob';
import PeopleLeave from './PeopleLeave';
import WorkshopShift from './WorkshopShift';

export const getItemUiKey = (item: IAllocationsBoundedItem) => {
  if (isOperationsJob(item)) {
    return `job-${item.id}`;
  }
  if (isWorkshopJob(item)) {
    return `workshop-job-${item.id}`;
  }
  if (isPeopleLeave(item)) {
    return `leave-${item.id}`;
  }
  if (isMarkOutOfServiceItem(item)) {
    return `out-of-servcice-${item.id}`;
  }
  if (isWorkshopShift(item)) {
    return `workshop-shift-${item.id}`;
  }
  throw new Error('Allocations item is not currently supported');
};

export const renderItem = (item: IAllocationsBoundedItem) => {
  if (isOperationsJob(item)) {
    return <AllocationJob job={item} />;
  }
  if (isWorkshopJob(item)) {
    return <WorkshopJob job={item} />;
  }
  if (isMarkOutOfServiceItem(item)) {
    return <MarkOutOfServiceJob markOutOfService={item} />;
  }
  if (isPeopleLeave(item)) {
    return <PeopleLeave item={item} />;
  }
  if (isWorkshopShift(item)) {
    return <WorkshopShift shift={item} />;
  }

  throw new Error('Allocations item is not currently supported');
};
