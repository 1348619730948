import { types, getRoot, flow } from 'mobx-state-tree';
import { IRootStoreModel } from 'src/domain/entities/RootStoreModel';
import { getAjax, NotificationType } from 'src/domain/services';

type CreateHolidayCommand = Operations.Domain.Commands.Holiday.CreateHoliday.CreateHolidayCommand;
type UpdateHolidayCommand = Operations.Domain.Commands.Holiday.UpdateHoliday.UpdateHolidayCommand;
type HolidayItem = Operations.Domain.Queries.ViewHoliday.HolidayItem;
type HolidayType = Operations.Domain.AggregatesModel.HolidayAggregate.HolidayType;

export const HolidayModel = types
  .model('HolidayModel', {
    holiday: types.maybe(types.frozen<HolidayItem>()),
    holidayTypes: types.array(types.frozen<HolidayType>()),
  })
  .actions(self => {
    const ajax = getAjax(self);
    const root = getRoot(self) as IRootStoreModel;

    const createHoliday = flow(function*(command: CreateHolidayCommand) {
      const id = yield ajax.operations.holidays.createHoliday(command);
      root.notifications.addNotification(`Successfully created Holiday ${command.description}`, {
        type: NotificationType.success,
      });
      self.holiday = undefined;
      root.history.push(`/operations/holidays/${id}`);
    });

    const loadHoliday = flow(function*(holidayId: string) {
      self.holiday = yield ajax.operations.holidays.viewHoliday(holidayId);
    });

    const loadHolidayTypes = flow(function*() {
      self.holidayTypes = yield ajax.operations.holidays.loadHolidayTypes();
    });

    const updateHoliday = flow(function*(command: UpdateHolidayCommand) {
      yield ajax.operations.holidays.updateHoliday(command);

      root.notifications.addNotification(`Successfully updated Holiday ${command.description}`, {
        type: NotificationType.success,
      });
      yield loadHoliday(command.id);
    });

    const deleteHoliday = flow(function*(holidayId: string) {
      yield ajax.operations.holidays.deleteHoliday(holidayId);

      root.notifications.addNotification(`Successfully deleted Holiday`, {
        type: NotificationType.success,
      });
      self.holiday = undefined;
      root.history.push(`/operations/holidays?defaultFilter=true`);
    });

    return {
      createHoliday,
      loadHoliday,
      updateHoliday,
      loadHolidayTypes,
      deleteHoliday,
    };
  });
