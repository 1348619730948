import { UiRouteName } from 'src/domain/baseTypes';
import { IRootStoreModel } from 'src/domain/entities/RootStoreModel';
import UiRoute from 'src/domain/services/buildUiRoutes/UiRoute';

export default function buildUiRoutes(store: IRootStoreModel) {
  const assetGroupCreate = new UiRoute(
    UiRouteName.workshopAssetGroupCreate,
    '/workshop/asset-groups/create',
    () => 'Create Asset Group',
    false
  );

  const assetGroupEdit = new UiRoute(
    UiRouteName.workshopAssetGroupEdit,
    '/workshop/asset-groups/:id',
    () => 'Manage Asset Group',
    false
  );

  const assetGroupsList = new UiRoute(
    UiRouteName.workshopAssetGroupsList,
    '/workshop/asset-groups',
    () => 'Asset Groups',
    false,
    false,
    [assetGroupCreate, assetGroupEdit]
  );

  return {
    [UiRouteName.workshopAssetGroupCreate]: assetGroupCreate,
    [UiRouteName.workshopAssetGroupsList]: assetGroupsList,
    [UiRouteName.workshopAssetGroupEdit]: assetGroupEdit,
  };
}
