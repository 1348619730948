import { DateTime } from 'luxon';

export function getWeekStartDate(date: DateTime, weekStartDay: number): DateTime {
  let diff = date.weekday - weekStartDay;
  if (diff < 0) diff += 7;
  return date.minus({ days: diff }).startOf('day');
}

export function isInSameConfigWeek(
  startDate: DateTime,
  endDate: DateTime,
  weekStartDay: number
): boolean {
  const weekStartDate1 = getWeekStartDate(startDate, weekStartDay);
  const weekStartDate2 = getWeekStartDate(endDate, weekStartDay);
  return weekStartDate1.equals(weekStartDate2);
}

export function returnEndDay(weekStartDate: number) {
  return weekStartDate === 1 ? 7 : weekStartDate - 1;
}

export function shouldShowRecalc(
  oldDate: DateTime,
  newDate: DateTime,
  weekStartDay: number
): boolean {
  const oldWeekStart = getWeekStartDate(oldDate, weekStartDay);
  const newWeekStart = getWeekStartDate(newDate, weekStartDay);
  return !oldWeekStart.equals(newWeekStart);
}
