import { createContext } from 'react';
import { createPortal } from 'react-dom';

export type PartType = 'content' | 'primaryActions' | 'secondaryActions';

export class SubsectionContextValue {
  // Contains a map of keys to dom elements that logical renderings get "attached" to, via a portal
  private readonly nodeMap = new Map<string, Element>();

  private readonly getNodeKey = (key: string | undefined, partType: PartType) => {
    if (!key) {
      throw new Error('Logical subsections must define a unique key value');
    }
    return `${key}-${partType}`;
  };

  private readonly getNode = (nodeKey: string) => {
    const node = this.nodeMap.get(nodeKey) || document.createElement('div');
    this.nodeMap.set(nodeKey, node);
    return node;
  };

  renderLogicalPart = (key: string | undefined, partType: PartType, content: React.ReactNode) => {
    const nodeKey = this.getNodeKey(key, partType);
    // Attach the content to the dom element (which may or may not be in the actual dom)
    return createPortal(content, this.getNode(nodeKey));
  };

  renderPhysicalPart = (key: string | undefined, partType: PartType, className?: string) => {
    const nodeKey = this.getNodeKey(key, partType);
    // Return a div that will have the logical renderings added as a child when it mounts
    return (
      <div
        key={nodeKey}
        className={className}
        ref={e => e && e.appendChild(this.getNode(nodeKey))}
      />
    );
  };
}

const SubsectionContext = createContext<SubsectionContextValue>(new SubsectionContextValue());

export default SubsectionContext;
