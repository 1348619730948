import { UiRouteName } from 'src/domain/baseTypes';
import { IRootStoreModel } from 'src/domain/entities/RootStoreModel';
import UiRoute from 'src/domain/services/buildUiRoutes/UiRoute';

export default function buildUiRoutes(store: IRootStoreModel) {
  const maintainLabourPrices = new UiRoute(
    UiRouteName.workshopMaintainLabourPrices,
    '/workshop/labour-prices',
    () => 'Labour Prices',
    true
  );

  return {
    [UiRouteName.workshopMaintainLabourPrices]: maintainLabourPrices,
  };
}
