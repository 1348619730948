import { Component } from 'react';
import { IFieldApi } from 'src/views/components/Page/forms/Field';
import {
  IFieldMeta,
  IFieldData,
  FieldType,
  ISelectFieldDef,
  IStaffMemberFieldDef,
} from 'src/views/definitionBuilders/types';
import inject from 'src/views/injectFromStore';
import SelectPageField from './SelectPageField';
import { applySkillSpecRequirementsFilter } from 'src/domain/entities/people/staffMember/SkillSpecsHelpers';
import { IStores } from 'src/domain';

type StaffMemberDto = Common.Dtos.StaffMemberDto;
type ExclusionItem = Operations.Domain.Queries.ListExclusions.ExclusionItem;

export enum StaffMemberFilter {
  all,
  active,
  hasDriversAuthorisation,
  isWorkshopStaff,
}

export interface IStaffMemberFieldProps {
  fieldApi: IFieldApi;
  fieldDef: IStaffMemberFieldDef;
  fieldMeta: IFieldMeta;
  fieldData: IFieldData<StaffMemberDto>;
  staffMembers: StaffMemberDto[];
  exclusions: ExclusionItem[];
}

class StaffMemberField extends Component<IStaffMemberFieldProps> {
  shouldComponentUpdate(nextProps: Readonly<IStaffMemberFieldProps>) {
    const readonly = this.props.fieldMeta.readonly !== nextProps.fieldMeta.readonly;
    const id =
      (this.props.fieldData.fieldValue && this.props.fieldData.fieldValue.id) !==
      (nextProps.fieldData.fieldValue && nextProps.fieldData.fieldValue.id);
    const options = this.props.staffMembers !== nextProps.staffMembers;
    const exclusions = this.props.exclusions !== nextProps.exclusions;
    return id || readonly || options || exclusions;
  }

  applyStaffMembersFilter() {
    const { staffMembers, fieldDef, fieldData } = this.props;
    const filter =
      typeof fieldDef.staffMemberFilter === 'function'
        ? fieldDef.staffMemberFilter(fieldData)
        : fieldDef.staffMemberFilter;
    switch (filter) {
      case StaffMemberFilter.active:
        return staffMembers.filter(x => x.active);
      case StaffMemberFilter.hasDriversAuthorisation:
        return staffMembers.filter(x => x.active && x.hasDriversAuthorisation);
      case StaffMemberFilter.isWorkshopStaff:
        return staffMembers.filter(x => x.active && x.isWorkshopStaff);
      case StaffMemberFilter.all:
      default:
        return staffMembers;
    }
  }

  applyExclusionFilter(staffMembers: StaffMemberDto[]) {
    const { fieldDef, fieldData, exclusions } = this.props;

    const assetId =
      fieldDef.assetId && typeof fieldDef.assetId === 'function'
        ? fieldDef.assetId(fieldData)
        : fieldDef.assetId;

    const licenceClassId =
      fieldDef.assetLicenceClassId && typeof fieldDef.assetLicenceClassId === 'function'
        ? fieldDef.assetLicenceClassId(fieldData)
        : fieldDef.assetLicenceClassId;

    if (!!assetId) {
      return staffMembers.filter(
        x =>
          exclusions.findIndex(e => e.asset.id === assetId && e.staffMember.id === x.id) === -1 &&
          x.licences.findIndex(l => l.licenceClass?.id < (licenceClassId ?? 0)) === -1
      );
    }

    return staffMembers;
  }

  applySkillSpecRequirementsFilter(staffMembers: StaffMemberDto[]) {
    const { fieldDef, fieldData } = this.props;
    const skillSpecRequirements =
      (fieldDef.skillSpecRequirements && typeof fieldDef.skillSpecRequirements === 'function'
        ? fieldDef.skillSpecRequirements(fieldData)
        : fieldDef.skillSpecRequirements) || [];

    return applySkillSpecRequirementsFilter(staffMembers, skillSpecRequirements);
  }

  filterStaffMembers() {
    return this.applySkillSpecRequirementsFilter(
      this.applyExclusionFilter(this.applyStaffMembersFilter())
    );
  }

  render() {
    const { fieldDef: def, fieldApi } = this.props;
    const { staffMemberFilter, ...definition } = def;
    const staffMemberFieldDef: ISelectFieldDef = {
      ...definition,
      fieldType: FieldType.selectField,
      optionItems: this.filterStaffMembers(),
      valueKey: 'id',
      descriptionKey: 'name',
      linkTo: d => {
        const value = fieldApi.value as { id: string } | string | undefined;
        const staffId = typeof value === 'object' ? value.id : value;
        return staffId ? `/people/staff-members/${staffId}` : undefined;
      },
    };
    return <SelectPageField {...this.props} fieldDef={staffMemberFieldDef} />;
  }
}

interface IStaffMemberFieldContainerProps {
  staffMembers: StaffMemberDto[];
  exclusions: ExclusionItem[];
}

const Container = inject<IStaffMemberFieldContainerProps, IStaffMemberFieldProps>(
  (allStores: IStores, p) => ({
    staffMembers: allStores.rootStore.people.staffMembers.allStaffMembers.slice(),
    exclusions: allStores.rootStore.operations.exclusionModel.exclusions.slice(),
  })
)(StaffMemberField);

export default Container;
