import { types, flow, getRoot } from 'mobx-state-tree';
import { getAjax } from 'src/domain/services';
import { IKioskRootStoreModel } from 'src/domain/entities/KioskRootStoreModel';

type StaffMemberClockStatus = People.Domain.Queries.GetStaffMemberClockStatus.StaffMemberClockStatus;
type ClockOffCommand = People.Domain.Commands.Kiosk.ClockOn.ClockOff.ClockOffCommand;
type AlcolizerTestResult = Common.Dtos.AlcolizerTestResultDto;

export const ClockOnModel = types
  .model('ClockOnModel', {
    status: types.maybe(types.frozen<StaffMemberClockStatus>()),
    testResult: types.maybe(types.frozen<AlcolizerTestResult>()),
  })
  .views(self => ({
    get isClockedOn() {
      return !!(self.status && self.status.isClockedOn);
    },
  }))
  .actions(self => {
    const ajax = getAjax(self);
    const root = getRoot(self) as IKioskRootStoreModel;

    const loadStaffMemberStatus = flow(function*(staffMemberId: string) {
      self.status = yield ajax.kiosk.clockOn.loadStaffMemberStatus(staffMemberId);
    });

    const loadAlcolizerTestResult = flow(function*(staffMemberId: string) {
      self.testResult = yield ajax.kiosk.clockOn.loadStaffMemberTestResult(staffMemberId);
    });

    const clockOn = flow(function*(
      command: People.Domain.Commands.Kiosk.ClockOn.ClockOn.ClockOnCommand
    ) {
      yield ajax.kiosk.clockOn.clockOn(command);
      yield loadStaffMemberStatus(command.staffMemberId);
      root.history.push(root.home);
    });

    const clockOff: (command: ClockOffCommand) => Promise<void> = flow(function*(command) {
      yield ajax.kiosk.clockOn.clockOff(command);
      yield root.account.signOutKiosk();
    });

    return {
      loadStaffMemberStatus,
      clockOn,
      clockOff,
      loadAlcolizerTestResult,
    };
  });
