import {
  // @ts-ignore - defaultFilterOptions is missing from typescript definitions
  defaultFilterOptions,
  Options,
  ReactSelectProps,
} from 'react-select';
import { IFieldApi } from 'src/views/components/Page/forms/Field';
import {
  IFieldData,
  IFieldDef,
  ISelectFieldDefBase,
  IFieldMeta,
} from 'src/views/definitionBuilders/types';
import ListSyncFieldBase from './ListSyncFieldBase';

interface ISelectSyncFieldBaseProps {
  // tslint:disable-next-line:no-any
  fieldDef: IFieldDef & ISelectFieldDefBase<any>;
  // tslint:disable-next-line:no-any
  fieldData: IFieldData<any>;
  fieldMeta: IFieldMeta;
  fieldApi: IFieldApi;
}

abstract class SelectSyncFieldBase<
  TProps extends ISelectSyncFieldBaseProps,
  TState = {}
> extends ListSyncFieldBase<TProps, TState> {
  // @types for filter options are quite wrong - use any to allow us to use the correct signature
  // tslint:disable-next-line:no-any
  protected readonly handleFilterOptions: any = (
    // tslint:disable-next-line:no-any
    opts: Options<any>,
    filterValue: string,
    // tslint:disable-next-line:no-any
    excludeOptions: Options<any>,
    // tslint:disable-next-line:no-any
    props: Partial<ReactSelectProps<any>>
  ) => {
    const { fieldDef: def } = this.props;
    const valuesToExcludeSafe = this.getValuesToExclude();
    const optsToExclude = opts.filter(
      o =>
        valuesToExcludeSafe.some(v => o[def.valueKey] === v) ||
        (def.includeKey && !o[def.includeKey])
    );
    const exclude = [...(excludeOptions || []), ...optsToExclude];
    return defaultFilterOptions(opts, filterValue, exclude, props);
  };
}

export default SelectSyncFieldBase;
