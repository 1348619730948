import './PaginationPagePane.scss';
import { Component } from 'react';
import { IPaginationPaneDef, IPaneData, IPaneMeta } from 'src/views/definitionBuilders/types';
import Pagination from 'reactstrap/lib/Pagination';
import PaginationItem from 'reactstrap/lib/PaginationItem';
import { PaginationLink } from 'reactstrap';

interface IPaginationPagePaneProps {
  paneDef: IPaginationPaneDef;
  paneData: IPaneData;
  paneMeta: IPaneMeta;
}

class PaginationPagePane extends Component<IPaginationPagePaneProps> {
  currentPage() {
    return this.props.paneDef.page;
  }

  getPageSize() {
    return this.props.paneDef.size || 25;
  }

  getPageCount() {
    return Math.floor(this.props.paneDef.totalResults / this.getPageSize()) + 1;
  }

  changePage(page: number) {
    this.props.paneDef.loadPage(page);
  }

  previousPage() {
    const previous = this.currentPage() !== 1 ? this.currentPage() - 1 : 1;
    this.changePage(previous);
  }

  nextPage() {
    const next =
      this.currentPage() !== this.getPageCount() ? this.currentPage() + 1 : this.getPageCount();
    this.changePage(next);
  }

  render() {
    return (
      <div className="pagination-page-pane-component">
        <Pagination>
          <PaginationItem disabled={this.currentPage() === 1} onClick={() => this.previousPage()}>
            <PaginationLink previous />
          </PaginationItem>
          <PaginationItem disabled>
            <PaginationLink>
              Page {this.currentPage()} of {this.getPageCount()}
            </PaginationLink>
          </PaginationItem>
          <PaginationItem
            disabled={this.currentPage() === this.getPageCount()}
            onClick={() => this.nextPage()}>
            <PaginationLink next />
          </PaginationItem>
        </Pagination>
      </div>
    );
  }
}

export default PaginationPagePane;
