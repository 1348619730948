import styles from './ProgressWarningBadge.module.scss';
import { getProgressWarningReasonDescriptor } from 'src/api/enums';
import cn from 'classnames';

interface IProgressWarningBadgeProps {
  warningReason: Operations.Domain.AggregatesModel.JobAggregate.ProgressWarningReason;
}

const ProgressWarningBadge: React.FC<IProgressWarningBadgeProps> = ({ warningReason }) => {
  const reasonDescriptor = getProgressWarningReasonDescriptor(warningReason);
  return (
    <span className={cn(styles.progressWarningBadgeComponent)}>{reasonDescriptor.description}</span>
  );
};

export default ProgressWarningBadge;
