import { flow } from 'mobx-state-tree';
import * as queryString from 'query-string';
import { buildListPageApiSearchModelTypedQuery } from 'src/domain/modelBuilders/buildListPageApiSearchModel';
import { getAjax } from 'src/domain/services';

const ExtraTypeListModel = buildListPageApiSearchModelTypedQuery<
  Operations.Domain.Queries.ListExtraTypes.ListExtraTypesQuery
>()('ExtraTypeListModel', d =>
  d.ajax.raw
    .get(`/api/operations/extra-types?${queryString.stringify(d.query)}`)
    .map(
      r =>
        r.response as Common.Dtos.ListResult<Operations.Domain.Queries.ViewExtraType.ExtraTypeItem>
    )
)
  .views(self => ({
    get charterExtraTypes() {
      return self.items.filter(e => e.isCharter && e.isActive);
    },
    get railExtraTypes() {
      return self.items.filter(e => e.isRail && e.isActive);
    },
    get accountingAndQuoteExtraTypes() {
      return self.items.filter(e => e.showInAccounting && e.isCharter && e.isActive);
    },
  }))
  .actions(self => {
    const ajax = getAjax(self);

    const loadExtraTypes = flow(function*() {
      self.items = yield ajax.operations.extraTypes.loadExtraTypes();
    });

    return {
      loadExtraTypes: loadExtraTypes,
    };
  });

export const ListExtraTypeModel = ExtraTypeListModel;
