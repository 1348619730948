import { DateTime } from 'luxon';
import { allBatchOperationStatus, BatchOperationStatus } from 'src/api/enums';
import { ListPageLoadCause } from 'src/domain';
import { IListPageLoadDataRequest } from 'src/domain/baseTypes';
import { CheckIcon } from 'src/images/icons';
import memoizeOne, { shallowMemoize } from 'src/infrastructure/memoizeOne';
import ListPage, { IListPageDef } from 'src/views/components/Page/pages/ListPage';
import PrimaryTitle from 'src/views/components/Page/PrimaryTitle/PrimaryTitle';
import TaskCardItem, { toActionLinkDef } from 'src/views/components/TaskCard/TaskCardItem';
import {
  ActionType,
  FieldDefs,
  FieldType,
  PagePrimarySize,
  PaneType,
} from 'src/views/definitionBuilders/types';
import withQueryParams, { IQueryParamsProps } from 'src/views/hocs/withQueryParams';

type ListGenerateJobsOperationsQuery = Operations.Domain.Queries.ListGenerateJobsOperations.ListGenerateJobsOperationsQuery;
type GenerateJobsOperationListItem = Operations.Domain.Queries.ListGenerateJobsOperations.GenerateJobsOperationListItem;

const getPrimaryFields = (
  canManageUrbanJobs: boolean,
  acknowledgeFailedGenerateJobsOperation: IListGenerateJobsOperationProps['acknowledgeFailedGenerateJobsOperation'],
  listGenerateJobsOperations: IListGenerateJobsOperationProps['listGenerateJobsOperations']
): IListPageDef['primaryFields'] => {
  return [
    {
      fieldType: FieldType.textField,
      dataAddr: 'generateJobsOperationNumber',
      label: 'Number',
      columnWidth: '1em',
      linkTo: d => `/operations/generate-jobs-operations/${d.parentValue.id}`,
    },
    {
      fieldType: FieldType.dateField,
      dataAddr: 'startDate',
      label: 'Start Date',
    },
    {
      fieldType: FieldType.dateField,
      dataAddr: 'endDate',
      label: 'End Date',
    },
    {
      fieldType: FieldType.textField,
      label: 'Created by',
      dataAddr: ['createdBy'],
    },
    {
      fieldType: FieldType.dateTimeField,
      label: 'Created on',
      dataAddr: ['createdOn'],
    },
    {
      fieldType: FieldType.textField,
      label: 'Status',
      dataAddr: ['generateJobsStatus', 'description'],
    },
    {
      fieldType: FieldType.readonlyField,
      label: 'Generated Jobs',
      dataAddr: ['generatedJobsCount'],
      formatReadonly: d => {
        const generateJobsOperation = d.parentValue as GenerateJobsOperationListItem;
        if (
          generateJobsOperation.generateJobsStatus.id === BatchOperationStatus.Failed ||
          generateJobsOperation.generateJobsStatus.id === BatchOperationStatus.Succeeded
        ) {
          return <>{generateJobsOperation.generatedJobsCount}</>;
        }
        return undefined;
      },
    },
    {
      fieldType: FieldType.readonlyField,
      label: 'Fail Details',
      dataAddr: 'failReason',
      formatReadonly: d => {
        const generateJobsOperation = d.parentValue as GenerateJobsOperationListItem;
        return (
          <>
            {generateJobsOperation.failReason}
            {generateJobsOperation.failAcknowledged && (
              <>
                <br />
                Fail acknowledged by {generateJobsOperation.failAcknowledgedBy}
              </>
            )}
          </>
        );
      },
    },
    {
      fieldType: FieldType.actionListField,
      hidden: () => !canManageUrbanJobs,
      dataAddr: '',
      columnWidth: '1px',
      label: 'Actions',
      actionGroups: [
        {
          actions: [
            {
              actionType: ActionType.actionButton,
              label: 'Acknowledge Failed Jobs Operation',
              hidden: d =>
                d.parentValue.generateJobsStatus.id !== BatchOperationStatus.Failed ||
                !!d.parentValue.failAcknowledged,
              icon: <CheckIcon fixedWidth />,
              onClick: props => {
                if (props.parentValue.id) {
                  acknowledgeFailedGenerateJobsOperation(props.parentValue.id).then(x =>
                    listGenerateJobsOperations({ loadCause: ListPageLoadCause.refresh })
                  );
                }
              },
            },
          ],
        },
      ],
    },
  ];
};

export interface IListGenerateJobsOperationProps {
  canManageUrbanJobs: boolean;
  listGenerateJobsOperations: (
    request: IListPageLoadDataRequest<ListGenerateJobsOperationsQuery>
  ) => Promise<void>;
  acknowledgeFailedGenerateJobsOperation: (generateJobsOperationId: string) => Promise<void>;
  lastUpdated?: DateTime;
  generateJobsOperations: GenerateJobsOperationListItem[];
  hasMoreData: boolean;
}

type InternalProps = IListGenerateJobsOperationProps &
  IQueryParamsProps<ListGenerateJobsOperationsQuery>;

const ListGenerateJobsOperation: React.FC<InternalProps> = (props: InternalProps) => {
  const getFilterFieldDefs = shallowMemoize(() => {
    return {
      generateJobsStatus: {
        fieldType: FieldType.selectMultiField,
        dataAddr: 'generateJobsStatusIds',
        label: 'Status',
        valueKey: 'value',
        descriptionKey: 'description',
        optionItems: allBatchOperationStatus,
        useValueOnly: true,
      } as FieldDefs,
      failAcknowledged: {
        fieldType: FieldType.yesNoField,
        label: 'Fail Acknowledged',
        dataAddr: 'failAcknowledged',
        useValueOnly: true,
      } as FieldDefs,
    };
  });

  const getRowKey: IListPageDef['rowKey'] = data => {
    return data.itemValue.id;
  };

  const getPageDef = memoizeOne(
    (
      canManageUrbanJobs: boolean,
      listGenerateJobsOperations: IListGenerateJobsOperationProps['listGenerateJobsOperations'],
      hasMoreData: boolean,

      primaryFieldDefinitions: IListPageDef['primaryFields']
    ): IListPageDef => {
      const filterFieldDefsLookup = getFilterFieldDefs();

      const topTasksActions = [
        ...(canManageUrbanJobs ? [TaskCardItem.urban.createGenerateJobsOperation] : []),
      ].map(toActionLinkDef);
      return {
        primaryTitle: <PrimaryTitle title="Generate Jobs"></PrimaryTitle>,
        onLoadData: listGenerateJobsOperations,
        externalSearch: true,
        primarySize: PagePrimarySize.threeQuarters,
        hasMoreData: hasMoreData,
        rowKey: getRowKey,
        primaryActions: [],
        primaryFields: primaryFieldDefinitions,
        secondarySections: [
          {
            title: 'Navigation',
            panels: [
              {
                title: 'Top Tasks',
                panes: [
                  {
                    paneType: PaneType.navListPane,
                    links: topTasksActions,
                  },
                ],
              },
            ],
          },
        ],
        filterAction: {
          filterFields: Object.keys(filterFieldDefsLookup).map(k => filterFieldDefsLookup[k]),
          filterDef: filterDefApi => [
            {
              panes: [
                {
                  paneType: PaneType.formFieldsPane,
                  fields: [
                    filterFieldDefsLookup.generateJobsStatus,
                    filterFieldDefsLookup.failAcknowledged,
                  ],
                },
              ],
            },
          ],
        },
      };
    }
  );

  const {
    canManageUrbanJobs,
    generateJobsOperations,
    lastUpdated,
    listGenerateJobsOperations,
    hasMoreData,
    acknowledgeFailedGenerateJobsOperation,
  } = props;

  const def = getPageDef(
    canManageUrbanJobs,
    listGenerateJobsOperations,
    hasMoreData,

    getPrimaryFields(
      canManageUrbanJobs,
      acknowledgeFailedGenerateJobsOperation,
      listGenerateJobsOperations
    )
  );

  return (
    <ListPage
      className="list-generate-jobs-operations-component"
      data={generateJobsOperations}
      def={def}
      autoRefresh
      lastUpdated={lastUpdated}
    />
  );
};

export default withQueryParams(ListGenerateJobsOperation);
