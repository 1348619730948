import {
  SwapVehicleType,
  getProgressIdDescriptor,
  ProgressId,
  getJobTypeDescriptor,
  JobType,
} from 'src/api/enums';
import { IShortTechSpecRequirement } from 'src/domain/entities/workshop/techSpecs/TechSpecsHelpers';
import {
  PaneType,
  FieldType,
  ActionType,
  ModalDefBuilder,
  IModalActionButtonDef,
  ShellModalSize,
  IModalDefBuilderApi,
} from 'src/views/definitionBuilders/types';
import { DateTime } from 'luxon';
import { RetweetIcon } from 'src/images/icons';
import {
  parseEditingFormattedDurationString,
  getEditingFormattedTimeString,
} from 'src/views/components/Page/fields/subfields/TimeHelpers';

type SwapVehicleCommand = Operations.Domain.Commands.Job.SwapVehicleCommand;
type JobProgressItem = Operations.Domain.Queries.GetJobProgress.JobProgressItem;
type AssetItem = Common.Queries.Workshop.GetFleetAssetList.AssetItem;
type ContinuationDetailsForVehicleSwap = Operations.Domain.Queries.ViewJob.ContinuationsInfoForVehicleSwapItem;

import './getSwapVehicleModalDef.scss';
import { workingJobHours } from '../../../urban/urbanHelpers';

export interface IVehicleSwapModalProps {
  existingClockOn: string;
  existingClockOff: string;
  existingJobId: string;
  existingJobBreaks: string;
  onSubmit: (command: SwapVehicleCommand) => Promise<void>;
  fleetAssets: AssetItem[];
  getTechSpecRequirements: (modalApi: IModalDefBuilderApi) => IShortTechSpecRequirement[];
  staffMemberId: string | undefined;
  secondStaffMemberId: string | undefined;
  existingProgresses: JobProgressItem[] | undefined;

  // Only if needed
  loadExistingProgresses?: (jobId: string) => Promise<void>;
  currentAsset: string | undefined;

  continuationDetailsForVehicleSwap: ContinuationDetailsForVehicleSwap[] | undefined;
}

export type VehicleSwapForm = IVehicleSwapModalProps & {
  newAsset: string | undefined;
  firstJobBreaks: string | undefined;
  secondJobBreaks: string | undefined;
  skipPredepartureChecks: boolean | undefined;
  changeoverTime: string;
  swapType: SwapVehicleType | undefined;
};

export function getVehicleSwapModalDef(props: IVehicleSwapModalProps): ModalDefBuilder {
  const indexOfCurrentJob = props.continuationDetailsForVehicleSwap?.findIndex(
    t => t.jobId === props.existingJobId
  );

  // Remove all continuation jobs prior to this job, including this job.
  // We only want to show the user the jobs that are going to be changed by this vehicle swap.
  const continuationDetailsForVehicleSwap =
    indexOfCurrentJob !== undefined
      ? props.continuationDetailsForVehicleSwap?.slice(indexOfCurrentJob + 1)
      : props.continuationDetailsForVehicleSwap;

  const explicitData: VehicleSwapForm = {
    ...props,
    newAsset: undefined,
    firstJobBreaks: props.existingJobBreaks,
    secondJobBreaks: undefined,
    skipPredepartureChecks: false,
    changeoverTime: '',
    swapType: undefined,
    continuationDetailsForVehicleSwap,
  };

  return modalDefApi => ({
    title: 'Vehicle Swap In Progress',
    asForm: true,
    explicitData: explicitData,
    hidden: () => !props.existingProgresses, // This is necessary to re-render for some reason
    panels: [
      {
        panes: [
          {
            paneType: PaneType.formFieldsPane,
            fields: [
              {
                fieldType: FieldType.toggleButtonField,
                label: 'Select an Option',
                mandatory: true,
                dataAddr: 'swapType',
                optionItems: [
                  {
                    description: 'Delete Tablet Progress - The Vehicle has not been driven.',
                    value: SwapVehicleType.DeleteExistingProgress,
                  },
                  {
                    description: 'Create a Duplicate job - The Vehicle has been driven.',
                    value: SwapVehicleType.CreateNewJob,
                  },
                ],
                descriptionKey: 'description',
                valueKey: 'value',
                useValueOnly: true,
              },
              {
                fieldType: FieldType.customField,
                label: '',
                dataAddr: 'nothing',
                hidden: d => d.panelValue.swapType !== SwapVehicleType.DeleteExistingProgress,
                render: d => (
                  <span className="vehicle-swap-type-description">
                    This option will delete the current tablet progresses and should only be used if
                    the vehicle has travelled zero kilometers on this job.
                  </span>
                ),
              },
            ],
          },
        ],
      },
      {
        title: d =>
          d.panelValue.swapType === SwapVehicleType.CreateNewJob
            ? 'Create Replacement Job with a new vehicle'
            : 'Delete Progresses and change vehicle',
        hidden: d => d.panelValue.swapType === undefined,
        panes: [
          {
            paneType: PaneType.formFieldsPane,
            fields: [
              {
                fieldType: FieldType.assetSelectField,
                label: 'New Vehicle',
                dataAddr: 'newAsset',
                optionItems: props.fleetAssets,
                valueKey: 'id',
                mandatory: true,
                useValueOnly: true,
                descriptionKey: 'name',
                staffMemberId: props.staffMemberId,
                secondStaffMemberId: props.secondStaffMemberId,
                techSpecRequirements: props.getTechSpecRequirements(modalDefApi),
                valuesToExclude: [props.currentAsset],
              },
            ],
          },
        ],
      },
      {
        title: 'Enter a Changeover Time between jobs',
        hidden: d => d.panelValue.swapType !== SwapVehicleType.CreateNewJob,
        panes: [
          {
            paneType: PaneType.formFieldsPane,
            columnCount: 3,
            fields: [
              {
                fieldType: FieldType.dateTimeField,
                label: 'Existing job clock on',
                readonly: true,
                dataAddr: 'existingClockOn',
              },
              {
                fieldType: FieldType.dateTimeField,
                label: 'Changeover Time',
                tooltip: 'Changeover time must be within existing job bounds',
                dataAddr: 'changeoverTime',
                mandatory: true,
                validate: d => {
                  const clockOn = DateTime.fromISO(props.existingClockOn);
                  const clockOff = DateTime.fromISO(props.existingClockOff);
                  const changeover = DateTime.fromISO(d.fieldValue!);

                  return changeover <= clockOn
                    ? 'Changeover cannot be same/before existing clock on'
                    : changeover >= clockOff
                    ? 'Changeover cannot be same/after existing clock off'
                    : undefined;
                },
              },
              {
                fieldType: FieldType.dateTimeField,
                label: 'Existing job clock off',
                readonly: true,
                dataAddr: 'existingClockOff',
                hidden: d => {
                  return false;
                },
              },
            ],
          },
          {
            paneType: PaneType.formFieldsPane,
            columnCount: 3,
            fields: [
              {
                fieldType: FieldType.yesNoField,
                dataAddr: 'skipPredepartureChecks',
                label: 'Skip Predeparture checks for replacement vehicle',
                mandatory: true,
              },
            ],
          },
        ],
      },
      {
        title: 'Unpaid Breaks',
        hidden: d => d.panelValue.swapType !== SwapVehicleType.CreateNewJob,
        panes: [
          {
            paneType: PaneType.formFieldsPane,
            columnCount: 4,
            fields: [
              {
                fieldType: FieldType.durationField,
                dataAddr: 'existingJobBreaks',
                readonly: true,
                label: 'Original Break',
                formatReadonly: d =>
                  getEditingFormattedTimeString(
                    parseEditingFormattedDurationString(d.panelValue.existingJobBreaks)
                  ),
              },
              {
                fieldType: FieldType.durationField,
                dataAddr: 'firstJobBreaks',
                mandatory: true,
                label: 'Still Assigned to Original Job',
              },
              {
                fieldType: FieldType.durationField,
                dataAddr: 'secondJobBreaks',
                mandatory: true,
                label: 'Assign to New Job',
                validate: d => {
                  // MaintainJob break validation
                  const j = d.panelValue;
                  const first = parseEditingFormattedDurationString(d.panelValue.firstJobBreaks);
                  const second = parseEditingFormattedDurationString(d.panelValue.secondJobBreaks);

                  if (!first || !second) {
                    return 'Breaks must be specified for both jobs';
                  }

                  const total = first.plus(second);
                  const workingHours = workingJobHours(
                    j.existingClockOn,
                    j.existingClockOff,
                    getEditingFormattedTimeString(total)
                  );
                  return !workingHours || workingHours.valueOf() <= 0
                    ? 'Unpaid breaks must be less than total time'
                    : undefined;
                },
              },
              {
                fieldType: FieldType.durationField,
                dataAddr: 'total',
                readonly: true,
                label: 'Combined Total Breaks',
                formatReadonly: d => {
                  const first = parseEditingFormattedDurationString(d.panelValue.firstJobBreaks);
                  const second = parseEditingFormattedDurationString(d.panelValue.secondJobBreaks);
                  if (first === undefined || second === undefined) {
                    return '';
                  }

                  return getEditingFormattedTimeString(first.plus(second));
                },
              },
            ],
          },
          {
            paneType: PaneType.formFieldsPane,
            fields: [
              {
                fieldType: FieldType.customField,
                dataAddr: 'warn',
                label: '',
                render: d => {
                  const first = parseEditingFormattedDurationString(
                    d.data.panelValue.firstJobBreaks
                  );
                  const second = parseEditingFormattedDurationString(
                    d.data.panelValue.secondJobBreaks
                  );
                  const original = parseEditingFormattedDurationString(
                    d.data.panelValue.existingJobBreaks
                  );

                  return first !== undefined &&
                    second !== undefined &&
                    first.plus(second).equals(original!) ? null : (
                    <span className="breaks-mismatch-warning">
                      Combined Total Breaks do not match original breaks
                    </span>
                  );
                },
              },
            ],
          },
        ],
      },
      {
        title: 'Continuations to be updated',
        dataAddr: 'continuationDetailsForVehicleSwap',
        hidden: d => {
          const anyContinuations = (continuationDetailsForVehicleSwap || []).length > 0;
          const isOptionSelected = d.sectionValue.swapType !== undefined;
          return !(anyContinuations && isOptionSelected);
        },
        panes: [
          {
            paneType: PaneType.tablePane,
            neverEditable: true,
            tableDescription:
              'The vehicle will also be swapped on the following continuation jobs.',
            fields: [
              {
                fieldType: FieldType.textField,
                dataAddr: 'jobNumber',
                label: 'Job Number',
                linkTo: d => `/operations/jobs/${d.parentValue.jobId}`,
                readonly: true,
              },
              {
                fieldType: FieldType.textField,
                dataAddr: 'description',
                label: 'Description',
                readonly: true,
              },
              {
                fieldType: FieldType.textField,
                dataAddr: 'jobTypeId',
                label: 'Job Type',
                formatReadonly: d => {
                  // Yay Typescript :/
                  const jobType = (d.fieldValue as unknown) as JobType;
                  return getJobTypeDescriptor(jobType)?.description;
                },
                readonly: true,
              },
            ],
          },
        ],
      },
      {
        title: d =>
          d.panelValue.swapType === SwapVehicleType.CreateNewJob
            ? 'Existing Tablet Progresses'
            : 'Tablet progresses to be Deleted',
        hidden: d => d.panelValue.swapType === undefined,
        panes: [
          {
            paneType: PaneType.tablePane,
            dataAddr: 'existingProgresses',
            fields: [
              {
                fieldType: FieldType.readonlyField,
                dataAddr: 'progress',
                label: 'Progress',
                formatReadonly: d => (
                  <span>{getProgressIdDescriptor(d.fieldValue as ProgressId).description}</span>
                ),
              },
              {
                fieldType: FieldType.dateTimeField,
                dataAddr: 'occurredAt',
                label: 'Occurred At',
                readonly: true,
              },
              {
                fieldType: FieldType.numericField,
                dataAddr: 'odometer',
                label: 'Odometer',
                readonly: true,
              },
              {
                fieldType: FieldType.staffMemberField,
                dataAddr: 'staffMember',
                label: 'Staff Member',
                readonly: true,
              },
            ],
          },
        ],
      },
    ],
    onFormPreSubmit: (values: VehicleSwapForm) => {
      const command: SwapVehicleCommand = {
        changeoverTime: values.changeoverTime,
        existingJobId: values.existingJobId,
        swapType: values.swapType!,
        newAssetId: values.newAsset!,
        firstJobBreaks: values.firstJobBreaks,
        secondJobBreaks: values.secondJobBreaks,
        skipPredepartureChecks: values.skipPredepartureChecks,
      };

      return command;
    },
    onFormSubmit: props.onSubmit,
    secondaryActions: [
      {
        actions: [
          {
            actionType: ActionType.submitActionButton,
            label: 'Submit',
            level: 'primary',
            disabled: d => d.actionValue.swapType === undefined,
          },
          {
            actionType: ActionType.closeModalActionButton,
            label: 'Cancel',
          },
        ],
      },
    ],
  });
}

export type IVehicleSwapModalActionButtonProps = IVehicleSwapModalProps & {
  hidden: boolean;
  disabled: boolean;
  className?: string;
};

export function getSwapVehicleModalActionButtonDef(props: IVehicleSwapModalActionButtonProps) {
  const def: IModalActionButtonDef = {
    actionType: ActionType.modalActionButton,
    hidden: props.hidden,
    disabled: props.disabled,
    label: 'Swap Vehicle in progress',
    icon: <RetweetIcon />,
    className: props.className,
    onOpenModal: () =>
      props.loadExistingProgresses && props.loadExistingProgresses(props.existingJobId),
    modalSize: ShellModalSize.twoThirds,
    modalDef: getVehicleSwapModalDef({ ...props }),
  };
  return def;
}
