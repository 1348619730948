import { types, flow, getRoot } from 'mobx-state-tree';
import { getAjax, NotificationType } from 'src/domain/services';
import { IRootStoreModel } from 'src/domain/entities/RootStoreModel';

type ExtraType = Operations.Domain.Queries.ViewExtraType.ExtraTypeItem;
type UpdateExtraTypeCommand = Operations.Domain.Commands.ExtraType.UpdateExtraType.UpdateExtraTypeCommand;
type CreateExtraTypeCommand = Operations.Domain.Commands.ExtraType.CreateExtraType.CreateExtraTypeCommand;
type ExtraTypeItem = Operations.Domain.Queries.ViewExtraType.ExtraTypeItem;
type GetCanExtraTypeBeDeletedResult = Operations.Domain.Queries.ViewExtraType.GetCanExtraTypeBeDeletedResult;

const returnVoid = () => {};

export const ExtraTypeItemModel = types
  .model('ExtraTypeItemModel', {
    extraType: types.maybe(types.frozen<ExtraType>()),
    extraTypes: types.array(types.frozen<ExtraTypeItem>()),
    canDeleteExtraType: types.maybe(types.frozen<GetCanExtraTypeBeDeletedResult>()),
  })
  .views(self => ({
    get activeExtraTypes() {
      return self.extraTypes.filter(et => et.isActive);
    },
  }))
  .actions(self => {
    const ajax = getAjax(self);
    const root = getRoot(self) as IRootStoreModel;

    const loadExtraType = flow(function*(extraTypeId: string) {
      self.extraType = yield ajax.raw
        .get(`/api/operations/extra-types/${extraTypeId}`)
        .toPromise()
        .then(r => r.response);
    });

    const updateExtraType = flow(function*(command: UpdateExtraTypeCommand) {
      self.extraType = yield ajax.raw
        .put(`/api/operations/extra-types/${command.id}`, command)
        .toPromise();
      root.notifications.addNotification(`Successfully updated extra type ${command.description}`, {
        type: NotificationType.success,
      });
      yield loadExtraType(command.id);
    });

    const createExtraType = flow(function*(command: CreateExtraTypeCommand) {
      const extraTypeId = yield ajax.raw
        .post(`/api/operations/extra-types/create`, command)
        .toPromise()
        .then(r => r.response);
      root.history.push(`/sales/extra-types/${extraTypeId}`);
    });

    const checkForUniqueExtraTypeDescription = flow(function*(description: string) {
      return yield ajax.raw
        .get(`/api/operations/extra-types/check-for-unique-description?description=${description}`)
        .toPromise()
        .then(r => r.response);
    });

    const checkExtraCanBeDeleted = flow(function*(extraTypeId: string) {
      self.canDeleteExtraType = yield ajax.raw
        .get(`/api/operations/extra-types/${extraTypeId}/check`)
        .toPromise()
        .then(r => r.response);
    });

    const loadExtraTypes = flow(function*() {
      self.extraTypes = yield ajax.raw
        .get('/api/operations/extra-types/all')
        .toPromise()
        .then<Operations.Domain.Queries.ViewExtraType.ExtraTypeItem[]>(r => r.response);
    });

    const deleteExtraType = flow(function*(extraTypeId: string) {
      yield ajax.raw
        .httpDelete(`/api/operations/extra-types/${extraTypeId}`)
        .toPromise()
        .then(returnVoid);
      root.history.push('/sales/extra-types?defaultFilter=true');
    });

    const markExtraTypeInactive = flow(function*(extraTypeId: string) {
      yield ajax.raw
        .put(`/api/operations/extra-types/${extraTypeId}/mark-inactive`)
        .toPromise()
        .then(returnVoid);
      root.history.push('/sales/extra-types?defaultFilter=true');
    });

    return {
      loadExtraType,
      updateExtraType,
      createExtraType,
      checkForUniqueExtraTypeDescription,
      loadExtraTypes,
      checkExtraCanBeDeleted,
      deleteExtraType,
      markExtraTypeInactive,
    };
  });
