import { types, flow } from 'mobx-state-tree';
import { getAjax } from 'src/domain/services';
type StaffDepotDto = Common.Dtos.StaffDepotDto;

export const StaffDepotModel = types
  .model('StaffDepotModel', {
    staffDepots: types.array(types.frozen<StaffDepotDto>()),
  })
  .actions(self => {
    const ajax = getAjax(self);

    const loadStaffDepots = flow(function*() {
      self.staffDepots = yield ajax.people.staffDepots.getStaffDepots();
    });

    return {
      loadStaffDepots,
    };
  });

type IStaffDepotModelType = typeof StaffDepotModel.Type;
export interface IStaffDepotModel extends IStaffDepotModelType {}
