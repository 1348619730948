import { IRootStoreModel } from 'src/domain/entities/RootStoreModel';
import { UiRoute } from 'src/domain/services/buildUiRoutes';
import { UiRouteName } from 'src/domain/baseTypes';

export default function buildUiRoutes(store: IRootStoreModel) {
  const reporting = new UiRoute(
    UiRouteName.reporting,
    '/accounting/reporting',
    () => 'Reporting',
    true
  );

  const bookingsForAccounting = new UiRoute(
    UiRouteName.accountingBookings,
    '/accounting/bookings',
    () => 'Bookings',
    true
  );

  const railBookingsForAccounting = new UiRoute(
    UiRouteName.accountingRailBookings,
    '/accounting/rail-bookings',
    () => 'Rail Bookings',
    true
  );

  const createContractCharterBookings = new UiRoute(
    UiRouteName.accountingCreateContractCharterBookings,
    '/accounting/contract-charter-bookings/create',
    () => 'Create Contract Booking',
    false
  );

  const updateContractCharterBookings = new UiRoute(
    UiRouteName.accountingUpdateContractCharterBookings,
    '/accounting/contract-charter-bookings/:id',
    () => 'Manage Contract Booking',
    false
  );

  const contractCharterBookings = new UiRoute(
    UiRouteName.accountingContractCharterBookings,
    '/accounting/contract-charter-bookings',
    () => 'Contract Bookings',
    false,
    true,
    [createContractCharterBookings, updateContractCharterBookings]
  );

  return {
    [UiRouteName.accountingBookings]: bookingsForAccounting,
    [UiRouteName.accountingContractCharterBookings]: contractCharterBookings,
    [UiRouteName.accountingUpdateContractCharterBookings]: updateContractCharterBookings,
    [UiRouteName.accountingCreateContractCharterBookings]: createContractCharterBookings,
    [UiRouteName.accountingRailBookings]: railBookingsForAccounting,
    [UiRouteName.reporting]: reporting,
  };
}
