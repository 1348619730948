import { ChangeState } from 'src/api/enums';
import { getBase64 } from 'src/infrastructure/fileUtil';
import { maxFileSizeInMegaBytes } from 'src/views/components/Attachment/attachmentHelper';
import { IFile } from 'src/views/components/Page/fields/MultiFilePageField';
import { getSubmitCloseModalActionGroupDef } from 'src/views/definitionBuilders/common';
import {
  FieldType,
  IFieldData,
  ModalDefBuilder,
  PaneType,
} from 'src/views/definitionBuilders/types';

type GenerateShiftAttachmentIdCommand = Operations.Domain.Commands.RailTemplate.GenerateShiftAttachmentId.GenerateShiftAttachmentIdCommand;
type RailTemplateShiftAttachmentDto = Operations.Domain.Commands.RailTemplate.RailTemplateShiftAttachmentDto;

const tabletFileSizeMegaBytesWarningThreshold = 3;

export default function getAddAttachmentModalDef(
  onSubmit: (data: GenerateShiftAttachmentIdCommand) => Promise<string>
): ModalDefBuilder {
  return modelDefApi => ({
    title: 'Add Attachment',
    asForm: true,
    dataAddr: 'attachmentDetails',
    explicitData: {
      railTemplateShiftId: modelDefApi.actionData.actionValue.railTemplateShiftId,
      file: undefined,
      fileName: undefined,
      availableOnTablet: undefined,
      availableOnKiosk: undefined,
      updateCurrentJobs: undefined,
      createdOn: undefined,
      changeState: undefined,
    },
    panels: [
      {
        panes: [
          {
            paneType: PaneType.formFieldsPane,
            fields: [
              {
                fieldType: FieldType.fileField,
                label: 'Attachment',
                mandatory: true,
                dataAddr: 'file',
                validate: fapi => {
                  const fapiTyping = fapi as IFieldData<IFile>;
                  if (fapiTyping.fieldValue?.file) {
                    if (fapiTyping.fieldValue?.file.type !== 'application/pdf') {
                      return 'Invalid file type. Please select a pdf';
                    }
                    const maxSizeInBytes = maxFileSizeInMegaBytes * 1024 * 1024;
                    if (fapiTyping.fieldValue?.file.size > maxSizeInBytes) {
                      return `${(fapiTyping.fieldValue?.file.size / 1024 / 1024).toFixed(
                        2
                      )}MB attachment too large. Attachments must be under 20MB`;
                    }
                  }

                  return undefined;
                },
                onChange: api => {
                  if (api.fieldData.fieldValue) {
                    api.setFormValue(['fileName'], api.fieldData.fieldValue.name || '');
                  }
                },
              },
              {
                fieldType: FieldType.textField,
                label: 'File name',
                mandatory: true,
                dataAddr: 'fileName',
                hidden: fapi => !fapi.parentValue?.file,
                validate: fapi => {
                  if (!fapi.fieldValue?.endsWith('.pdf')) {
                    return 'Please ensure the filename ends with .pdf';
                  }
                  return undefined;
                },
              },
              {
                fieldType: FieldType.yesNoField,
                label: "Available on Driver's Tablet?",
                mandatory: true,
                dataAddr: 'availableOnTablet',
                hidden: fapi => !fapi.parentValue?.file,
              },
              {
                fieldType: FieldType.customField,
                label: '',
                dataAddr: 'fake',
                hidden: fapi =>
                  modelDefApi.actionData.parentValue.attachmentDetails?.map(
                    (a: RailTemplateShiftAttachmentDto) => a.availableOnTablet
                  ).length === 0 || !fapi.panelValue?.availableOnTablet,
                readonly: true,
                render: () => (
                  <p>
                    Multiple tablet PDFs can be a distraction for the driver and could be slow to
                    download if not optimised correctly. Please consider if this PDF is necessary
                    for display on the driver's tablet.
                  </p>
                ),
              },
              {
                fieldType: FieldType.customField,
                label: '',
                dataAddr: 'fake1',
                hidden: fapi =>
                  !fapi.panelValue ||
                  !fapi.panelValue.file ||
                  !fapi.panelValue.availableOnTablet ||
                  fapi.panelValue.file.file.size <
                    tabletFileSizeMegaBytesWarningThreshold * 1024 * 1024,
                readonly: true,
                render: fapi => (
                  <p>
                    This PDF is {(fapi.data.panelValue.file.file.size / 1024 / 1024).toFixed(2)}
                    Mb in size. Please consider if this PDF could be optimised to reduce its file
                    size.
                  </p>
                ),
              },
              {
                fieldType: FieldType.yesNoField,
                label: "Available on Driver's Kiosk & App?",
                mandatory: true,
                dataAddr: 'availableOnKiosk',
                hidden: fapi => !fapi.parentValue.file,
              },
              {
                fieldType: FieldType.yesNoField,
                label: 'Update current jobs?',
                mandatory: true,
                dataAddr: 'updateCurrentJobs',
                hidden: fapi => !fapi.parentValue.railTemplateShiftId && !fapi.parentValue.file,
              },
              {
                fieldType: FieldType.customField,
                label: '',
                dataAddr: 'fake2',
                hidden: fapi =>
                  !fapi.parentValue.railTemplateShiftId || !fapi.parentValue.updateCurrentJobs,
                readonly: true,
                render: () => <p>This will update all existing jobs.</p>,
              },
              {
                fieldType: FieldType.customField,
                label: '',
                dataAddr: 'fake3',
                hidden: fapi =>
                  !fapi.parentValue.railTemplateShiftId || fapi.parentValue.updateCurrentJobs,
                readonly: true,
                render: () => (
                  <p>This will update the shift and will not affect any existing jobs.</p>
                ),
              },
            ],
          },
        ],
      },
    ],
    secondaryActions: [getSubmitCloseModalActionGroupDef('Add')],
    onFormSubmit: async values => {
      const attachmentDetails = modelDefApi.parentFormApi.getValue('attachmentDetails') ?? [];

      onSubmit({
        fileName: values.file.name,
        mimeType: values.file.file.type,
        data: await getBase64(values.file.file!),
      }).then((res: string) => {
        const val = [
          ...attachmentDetails,
          {
            ...values,
            attachmentId: res,
            shiftId: modelDefApi.actionData.parentValue.id,
            changeState: ChangeState.Added,
          },
        ];

        modelDefApi.parentFormApi.setValue(['attachmentDetails'], val);
      });

      return Promise.resolve();
    },
  });
}
