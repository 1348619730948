import inject from 'src/views/injectFromStore';
import ListAssetOdometerReadings, {
  IListAssetOdometerReadingsProps,
} from './ListAssetOdometerReadings';

const Container = inject<IListAssetOdometerReadingsProps>((allStores, p) => {
  return {
    assetOdometerReadings: allStores.rootStore.assetOdometerReadings.assetOdometerReadings.slice(),
    hasMoreData: allStores.rootStore.assetOdometerReadings.hasMoreData,
    loadAssetOdometerReadings: allStores.rootStore.assetOdometerReadings.loadAssetOdometerReadings,
    onSubmit: allStores.rootStore.assetOdometerReadings.submitAssetOdometerReadings,
    assets: allStores.rootStore.assets.fleetAssetListItems.slice(),
    loadAssets: allStores.rootStore.assets.loadFleetAssets,
  };
})(ListAssetOdometerReadings);

export default Container;
