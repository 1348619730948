import { IRootStoreModel } from 'src/domain/entities/RootStoreModel';
import { UiRoute } from 'src/domain/services/buildUiRoutes';
import { UiRouteName } from 'src/domain/baseTypes';

export default function buildUiRoutes(store: IRootStoreModel) {
  const railTemplateCreate = new UiRoute(
    UiRouteName.operationsRailTemplateCreate,
    '/operations/rail/rail-templates/create',
    () => 'Create Rail Template',
    true
  );

  const railTemplateManage = new UiRoute(
    UiRouteName.operationsRailTemplateManage,
    '/operations/rail/rail-templates/:id',
    () => 'Manage Rail Template',
    true
  );

  const railTemplateList = new UiRoute(
    UiRouteName.operationsRailTemplateList,
    '/operations/rail/rail-templates',
    () => 'Rail Templates',
    false,
    false,
    [railTemplateCreate, railTemplateManage]
  );

  return {
    [UiRouteName.operationsRailTemplateList]: railTemplateList,
    [UiRouteName.operationsRailTemplateCreate]: railTemplateCreate,
    [UiRouteName.operationsRailTemplateManage]: railTemplateManage,
  };
}
