import { types, flow } from 'mobx-state-tree';
import { getAjax } from 'src/domain/services/storeEnvironment';

type QuoteDto = Operations.Domain.Queries.ListQuotesForCustomer.QuoteForCustomer;
type BookingDto = Operations.Domain.Queries.ListBookingsForCustomer.BookingForCustomer;
type RailBookingForCustomerDto = Operations.Domain.Queries.ListRailBookingsForCustomer.RailBookingForCustomer;

export const QuotesForCustomerModel = types
  .model('QuotesForCustomerModel', {
    quotesForCustomer: types.array(types.frozen<QuoteDto>()),
    bookingsForCustomer: types.array(types.frozen<BookingDto>()),
    railBookingsForCustomer: types.array(types.frozen<RailBookingForCustomerDto>()),
  })
  .actions(self => {
    const ajax = getAjax(self);

    const loadQuotesForCustomer = flow(function*(customerId: string) {
      self.quotesForCustomer = yield ajax.sales.quote.loadQuotesForCustomer(customerId);
    });

    const loadBookingsForCustomer = flow(function*(customerId: string) {
      self.bookingsForCustomer = yield ajax.sales.quote.loadBookingsForCustomer(customerId);
    });

    const loadRailBookingsForCustomer = flow(function*(customerId: string) {
      self.railBookingsForCustomer = yield ajax.sales.quote.loadRailBookingsForCustomer(customerId);
    });

    return {
      loadQuotesForCustomer,
      loadBookingsForCustomer,
      loadRailBookingsForCustomer,
    };
  });
