import { PopoverProps } from 'reactstrap/lib/Popover';
import PopoverBody from 'reactstrap/lib/PopoverBody';
import { IExtraItem } from 'src/views/components/operations/ExtraDetails/ExtraDetails';
import { RendersafePopover } from '../../RendersafePopover/RendersafePopover';

interface IExtrasPopoverProps {
  target: PopoverProps['target'];
  extras: IExtraItem[];
}

const ExtrasPopover: React.FC<IExtrasPopoverProps> = ({ target, extras }) => (
  <RendersafePopover placement="auto" isOpen target={target}>
    <PopoverBody>
      <ul className="list-unstyled">
        {extras.map((e, i) => (
          <li key={i}>
            {e.quantity} × {e.extraType.description}
          </li>
        ))}
      </ul>
    </PopoverBody>
  </RendersafePopover>
);

export default ExtrasPopover;
