import { ChangeState, skillSpecCategoryDescription } from 'src/api/enums';
import { getSubmitCloseModalActionGroupDef } from 'src/views/definitionBuilders/common';
import { PaneType, FieldType, ModalDefBuilder } from 'src/views/definitionBuilders/types';
import deepEqual from 'src/infrastructure/deepEqual';
type UpdateStaffMemberSkillSpecsCommand = People.Domain.Commands.StaffMembers.SkillSpecs.UpdateStaffMemberSkillSpecsCommand;
type StaffMemberSkillSpecItem = People.Domain.Commands.StaffMembers.SkillSpecs.StaffMemberSkillSpecItem;

export default function getSkillsModalDef(
  staffMemberId: string,
  skillSpecs: StaffMemberSkillSpecItem[] | undefined,
  updateSkills: (cmd: UpdateStaffMemberSkillSpecsCommand) => Promise<void>
): ModalDefBuilder {
  return () => {
    const getSkillSpecChangeState = (skillSpec: StaffMemberSkillSpecItem) => {
      if (skillSpec.changeState === ChangeState.Added) {
        return skillSpec.changeState;
      }
      const originalSpec = skillSpecs?.find(
        (sp: StaffMemberSkillSpecItem) => sp.id === skillSpec.id
      );

      return deepEqual(skillSpec, originalSpec) ? ChangeState.Unchanged : ChangeState.Modified;
    };
    return {
      title: 'Skill Specs',
      asForm: true,
      explicitData: skillSpecs,
      panels: [
        {
          panes: [
            {
              paneType: PaneType.tablePane,
              fields: [
                {
                  fieldType: FieldType.readonlyField,
                  label: 'Description',
                  dataAddr: 'skillSpecDescription',
                },
                {
                  fieldType: FieldType.readonlyField,
                  label: 'Category',
                  dataAddr: 'category',
                  formatReadonly: c => skillSpecCategoryDescription(c.fieldValue),
                },
                {
                  fieldType: FieldType.yesNoField,
                  dataAddr: 'value',
                  label: 'Value',
                },
              ],
            },
          ],
        },
      ],
      secondaryActions: [getSubmitCloseModalActionGroupDef('Ok')],
      onFormPreSubmit: (values: StaffMemberSkillSpecItem[]): UpdateStaffMemberSkillSpecsCommand => {
        const skillSpecs = values.map(s => {
          return {
            ...s,
            changeState: getSkillSpecChangeState(s),
          };
        });
        return {
          staffMemberId: staffMemberId,
          staffMemberSkillSpecs: skillSpecs,
        };
      },
      onFormSubmit: updateSkills,
    };
  };
}
