import { IAjax } from 'src/infrastructure/ajax';
import { workshopUrls } from 'src/domain/services/apiUrls';

export default function init(ajax: IAjax) {
  function listAssetPartSubCategories() {
    return ajax
      .get(workshopUrls.listAssetPartSubCategories.listAssetPartSubCategories())
      .toPromise()
      .then<Workshop.Domain.Queries.AssetPartCategory.AssetPartSubCategoryItem[]>(r => {
        return r.response;
      });
  }

  return {
    assetPartSubCategories: {
      listAssetPartSubCategories,
    },
  };
}
