import 'react-select/dist/react-select.css';
import './SelectPageField.scss';
import { Creatable, OnChangeHandler } from 'react-select';
import { nonStrictEqual } from 'src/infrastructure/nonStrictEqual';
import { LabelledFormField } from 'src/views/components/forms';
import { IFieldApi } from 'src/views/components/Page/forms/Field';
import {
  IFieldData,
  IFieldMeta,
  ISelectCreatableFieldDef,
} from 'src/views/definitionBuilders/types';
import SelectSyncFieldBase from './fieldBases/SelectSyncFieldBase';

interface ISelectCreatablePageFieldProps {
  fieldDef: ISelectCreatableFieldDef;
  fieldMeta: IFieldMeta;
  // tslint:disable-next-line:no-any
  fieldData: IFieldData<any>;
  fieldApi: IFieldApi;
}

class SelectCreatablePageField extends SelectSyncFieldBase<ISelectCreatablePageFieldProps> {
  private readonly getCurrentItem = () => {
    const { fieldDef: def, fieldData: data } = this.props;
    if (data.fieldValue === undefined || data.fieldValue === null) {
      return data.fieldValue;
    }

    // Make sure we don't try to pass an object as the value
    const safeFieldValue =
      typeof data.fieldValue === 'string' || typeof data.fieldValue === 'number'
        ? data.fieldValue
        : undefined;

    // default value is for if the control is passed just the value, rather than an object with value & description
    const getDefaultItem = () =>
      safeFieldValue === undefined
        ? undefined
        : {
            [def.valueKey]: safeFieldValue,
            [def.descriptionKey]: safeFieldValue,
          };

    // If the field is "value only", attempt to find a corresponding option item, and just use the value otherwise
    if (def.useValueOnly) {
      return (
        this.items().find(i => nonStrictEqual(i[def.valueKey], data.fieldValue)) || getDefaultItem()
      );
    }

    // If we have no value, just use the default value
    const value = data.fieldValue[def.valueKey];
    if (value === undefined || value === null) {
      return getDefaultItem();
    }

    // If the field has a description, then just use the field, otherwise look for the item in the options
    const description = data.fieldValue[def.descriptionKey];
    const item =
      description === undefined || description === null
        ? this.items().find(i => i[def.valueKey] === value)
        : data.fieldValue;

    // If no item was found, just use the default value
    return item || getDefaultItem();
  };

  private readonly handleChange: OnChangeHandler = newOption => {
    const { fieldDef: def, fieldApi } = this.props;
    const newValue = def.useValueOnly && newOption ? newOption[def.valueKey] : newOption;
    fieldApi.setValue(newValue === null ? undefined : newValue);
  };

  render() {
    const { fieldApi, fieldDef: def, fieldMeta: meta, fieldData: data } = this.props;
    const { error, touched } = fieldApi;
    const currentItem = this.getCurrentItem();
    const valueRenderer = def.useOptionRendererAsValueRenderer
      ? def.optionRenderer
      : def.valueRenderer;

    // As this field can have a value which is an object, then errors can be added to "lower" levels,
    // in which case `error` will be an object rather than a string
    const safeError = typeof error === 'string' ? error : undefined;
    const labelText = typeof def.label === 'function' ? def.label(data) : def.label;
    const tooltipText = typeof def.tooltip === 'function' ? def.tooltip(data) : def.tooltip;
    return (
      <LabelledFormField
        className="select-page-field-component"
        readonly={meta.readonly}
        readonlyValue={
          def.formatReadonly
            ? def.formatReadonly(data)
            : currentItem && valueRenderer
            ? valueRenderer(currentItem)
            : currentItem && currentItem[def.descriptionKey]
        }
        readonlyLinkTo={def.linkTo && currentItem ? def.linkTo(data) : undefined}
        mandatory={meta.mandatory}
        hideLabel={meta.hideLabel}
        labelValue={labelText}
        tooltipValue={tooltipText}
        noForm={meta.noForm}
        error={touched && safeError}>
        <Creatable
          className={!(touched && safeError) ? '' : 'is-invalid'}
          autoFocus={meta.autoFocus}
          clearable={!meta.mandatory}
          backspaceRemoves={!meta.mandatory}
          deleteRemoves={!meta.mandatory}
          options={this.items()}
          filterOptions={this.handleFilterOptions}
          valueKey={def.valueKey}
          labelKey={def.descriptionKey}
          value={currentItem}
          onChange={this.handleChange}
          matchProp={'label'}
          onBlur={this.handleBlur}
          valueRenderer={valueRenderer}
          optionRenderer={def.optionRenderer}
          placeholder={def.placeholder}
          menuContainerStyle={
            def.menuWidth
              ? def.menuWidth === 'fitContent'
                ? { minWidth: '100%', width: 'fit-content' }
                : { minWidth: '100%', width: def.menuWidth }
              : undefined
          }
          promptTextCreator={def.promptTextCreator}
        />
      </LabelledFormField>
    );
  }
}

export default SelectCreatablePageField;
