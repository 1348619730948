import CrudPage, { CrudPageMode, ICrudPageDef } from 'src/views/components/Page/pages/CrudPage';
import { RouteComponentProps } from 'react-router-dom';
import {
  PagePrimarySize,
  PaneType,
  FieldType,
  ActionType,
  ShellModalSize,
} from 'src/views/definitionBuilders/types';
import { observer } from 'mobx-react';
import { useRootStore } from 'src/domain/entities/RootStoreModel';
import { TrashIcon } from 'src/images/icons';
import { getSubmitCloseModalActionGroupDef } from 'src/views/definitionBuilders/common';
import { useEffect } from 'react';

type CreateExtraTypeCommand = Operations.Domain.Commands.ExtraType.CreateExtraType.CreateExtraTypeCommand;
type UpdateExtraTypeCommand = Operations.Domain.Commands.ExtraType.UpdateExtraType.UpdateExtraTypeCommand;
type ExtraTypeItem = Operations.Domain.Queries.ViewExtraType.ExtraTypeItem;

interface IMaintainExtraTypeProps {
  mode: CrudPageMode;
  route: RouteComponentProps<{ [x: string]: string | undefined }>;
}

const MaintainExtraType: React.FC<IMaintainExtraTypeProps> = observer(
  (props: IMaintainExtraTypeProps) => {
    const rootStore = useRootStore();
    const canManageExtraType = rootStore.account.isSalesDepartmentMember;
    const extraType = rootStore.operations.extraTypes.item.extraType;
    const loadExtraType = rootStore.operations.extraTypes.item.loadExtraType;
    const onCreateExtraType = rootStore.operations.extraTypes.item.createExtraType;
    const onUpdateExtraType = rootStore.operations.extraTypes.item.updateExtraType;
    const checkExtraCanBeDeleted = rootStore.operations.extraTypes.item.checkExtraCanBeDeleted;
    const canDeleteExtraType = rootStore.operations.extraTypes.item.canDeleteExtraType;
    const markExtraTypeInactive = rootStore.operations.extraTypes.item.markExtraTypeInactive;
    const deleteExtraType = rootStore.operations.extraTypes.item.deleteExtraType;
    const checkForUniqueExtraTypeDescription =
      rootStore.operations.extraTypes.item.checkForUniqueExtraTypeDescription;

    const isUpdateMode = props.mode === 'update';
    const isCreateMode = props.mode === 'create';
    const extraTypeId = props.route.match.params.id!;

    useEffect(() => {
      if (extraTypeId) {
        loadExtraType(extraTypeId);
      }
    }, [extraTypeId]);

    const getPageDef = (updating: boolean): ICrudPageDef => {
      return {
        primarySize: PagePrimarySize.half,
        primarySection: {
          title: !isCreateMode && extraType ? 'Manage Extra' : 'Create an Extra',
          badge: extraType && !extraType.isActive ? { label: 'Inactive' } : undefined,
          primaryActions: [
            {
              actions: [
                {
                  actionType: ActionType.actionCollection,
                  hidden: updating || !isUpdateMode,
                  actionGroups: [
                    {
                      actions: [
                        {
                          actionType: ActionType.modalActionButton,
                          label: 'Delete Extra Type',
                          icon: <TrashIcon />,
                          hidden: !extraType || !extraType.isActive,
                          modalSize: ShellModalSize.oneThird,
                          onOpenModal: () => checkExtraCanBeDeleted(extraTypeId),
                          modalDef: () => ({
                            title: 'Delete Extra Type',
                            asForm: true,
                            panels: [
                              {
                                title: '',
                                panes: [
                                  {
                                    paneType: PaneType.customPane,
                                    render: api => (
                                      <>
                                        <p>
                                          Are you sure you want to delete{' '}
                                          <strong>{api.data.parentValue.description}</strong> extra
                                          type?
                                          <br />
                                          Current jobs and bookings will not be affected.
                                        </p>
                                      </>
                                    ),
                                  },
                                ],
                              },
                            ],
                            secondaryActions: [getSubmitCloseModalActionGroupDef('Ok')],
                            onFormSubmit: () =>
                              canDeleteExtraType?.canBeDeleted
                                ? deleteExtraType(extraTypeId)
                                : markExtraTypeInactive(extraTypeId),
                          }),
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
          panels: [
            {
              panes: [
                {
                  paneType: PaneType.formFieldsPane,
                  columnCount: 1,
                  fields: [
                    {
                      fieldType: FieldType.textField,
                      mandatory: true,
                      label: 'Description',
                      dataAddr: 'description',
                      validateAsync: async d => {
                        if (
                          !d.fieldValue ||
                          (isUpdateMode &&
                            extraType &&
                            extraType.description &&
                            extraType.description.toUpperCase() === d.fieldValue.toUpperCase())
                        ) {
                          return undefined;
                        }
                        const result = await checkForUniqueExtraTypeDescription(d.fieldValue);
                        return result.nameExists ? `Description is already in use` : undefined;
                      },
                    },
                  ],
                },
                {
                  paneType: PaneType.formFieldsPane,
                  columnCount: 2,
                  fields: [
                    {
                      fieldType: FieldType.numericField,
                      numericConfig: { numericType: 'unsignedDecimal', maxPointDigits: 4 },
                      mandatory: true,
                      label: 'Price Ex GST',
                      formatReadonly: 'unitPrice',
                      dataAddr: 'price',
                    },
                    {
                      fieldType: FieldType.yesNoField,
                      mandatory: true,
                      label: 'GST Applicable',
                      dataAddr: 'isTaxable',
                    },
                  ],
                },
              ],
            },
            {
              panes: [
                {
                  paneType: PaneType.formFieldsPane,
                  columnCount: 2,
                  fields: [
                    {
                      fieldType: FieldType.yesNoField,
                      mandatory: true,
                      label: 'Available for Charters',
                      dataAddr: 'isCharter',
                    },
                    {
                      fieldType: FieldType.yesNoField,
                      mandatory: true,
                      label: 'Available for Rail',
                      dataAddr: 'isRail',
                    },
                    {
                      fieldType: FieldType.yesNoField,
                      mandatory: true,
                      label: 'Show on the Sales Quotes Page',
                      dataAddr: 'showInQuote',
                    },
                    {
                      fieldType: FieldType.yesNoField,
                      mandatory: true,
                      label: 'Show on the Accounting Bookings Page',
                      dataAddr: 'showInAccounting',
                    },
                    {
                      fieldType: FieldType.yesNoField,
                      mandatory: true,
                      label: 'Show a Task for Operations',
                      dataAddr: 'isTask',
                    },
                  ],
                },
              ],
            },
          ],
          onFormPreSubmit: isUpdateMode ? handlePreSubmitForUpdate : handlePreSubmitForCreate,
          onFormSubmit: isUpdateMode ? onUpdateExtraType : onCreateExtraType,
        },
      };
    };

    const handlePreSubmitForCreate = (e: ExtraTypeItem): CreateExtraTypeCommand => {
      return {
        description: e.description,
        showInAccounting: e.showInAccounting,
        showInQuote: e.showInQuote,
        isCharter: e.isCharter,
        isRail: e.isRail,
        isTask: e.isTask,
        isTaxable: e.isTaxable,
        price: e.price,
      };
    };
    const handlePreSubmitForUpdate = (e: ExtraTypeItem): UpdateExtraTypeCommand => {
      return {
        id: e.id,
        description: e.description,
        showInAccounting: e.showInAccounting,
        showInQuote: e.showInQuote,
        isCharter: e.isCharter,
        isRail: e.isRail,
        isTask: e.isTask,
        isTaxable: e.isTaxable,
        price: e.price,
      };
    };

    return (
      <CrudPage
        def={({ updating }) => getPageDef(updating)}
        mode={props.mode}
        isEditingForbidden={!canManageExtraType}
        data={extraType}
        createDefaultData={{}}
      />
    );
  }
);

export default MaintainExtraType;
