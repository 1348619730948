import { types, getRoot, flow } from 'mobx-state-tree';
import { getAjax } from 'src/domain/services';
import { IRootStoreModel } from 'src/domain/entities/RootStoreModel';

type CreateTimesheetAllowanceCommand = People.Domain.Commands.TimesheetAllowance.CreateTimesheetAllowance.CreateTimesheetAllowanceCommand;
type UpdateTimesheetAllowanceCommand = People.Domain.Commands.TimesheetAllowance.UpdateTimesheetAllowance.UpdateTimesheetAllowanceCommand;
type TimesheetAllowanceItem = People.Domain.Queries.TimesheetAllowance.TimesheetAllowanceItem;

export const TimesheetAllowanceModel = types
  .model('TimesheetAllowanceModel', {
    timesheetAllowance: types.maybe(types.frozen<TimesheetAllowanceItem>()),
  })
  .views(self => ({}))
  .actions(self => {
    const ajax = getAjax(self);
    const root = getRoot(self) as IRootStoreModel;

    const createTimesheetAllowance = flow(function*(command: CreateTimesheetAllowanceCommand) {
      const id = yield ajax.people.timesheetAllowance.createTimesheetAllowance(command);
      root.history.push(`/people/timesheet-allowances/${id}`);
    });

    const loadTimesheetAllowance = flow(function*(timesheetAllowanceId: string) {
      self.timesheetAllowance = yield ajax.people.timesheetAllowance.loadTimesheetAllowance(
        timesheetAllowanceId
      );
    });

    const updateTimesheetAllowance = flow(function*(command: UpdateTimesheetAllowanceCommand) {
      yield ajax.people.timesheetAllowance.updateTimesheetAllowance(command);
      yield loadTimesheetAllowance(command.id);
    });

    const deleteTimesheetAllowance = flow(function*(timesheetAllowanceId: string) {
      yield ajax.people.timesheetAllowance.deleteTimesheetAllowance(timesheetAllowanceId);
      root.history.push(`/people/timesheet-allowances?defaultFilter=true`);
    });

    const reactivateTimesheetAllowance = flow(function*(timesheetAllowanceId: string) {
      yield ajax.people.timesheetAllowance.reactivateTimesheetAllowance(timesheetAllowanceId);
      yield loadTimesheetAllowance(timesheetAllowanceId);
    });

    const checkForUniqueName = flow(function*(name: string) {
      return yield ajax.people.timesheetAllowance.checkForUniqueName(name);
    });

    return {
      createTimesheetAllowance,
      checkForUniqueName,
      loadTimesheetAllowance,
      updateTimesheetAllowance,
      deleteTimesheetAllowance,
      reactivateTimesheetAllowance,
    };
  });
