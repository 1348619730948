import { useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { ChangeState, ShiftType } from 'src/api/enums';
import { Weekday } from 'src/domain';
import { consolidateSkillSpecIds } from 'src/domain/entities/people/staffMember/SkillSpecsHelpers';
import { SyncIcon, TrashIcon } from 'src/images/icons';
import { isDefined } from 'src/infrastructure/typeUtils';
import { StaffMemberFilter } from 'src/views/components/Page/fields/StaffMemberField';
import { getEditingFormattedTimeString } from 'src/views/components/Page/fields/subfields/TimeHelpers';
import CrudPage, { CrudPageMode, ICrudPageDef } from 'src/views/components/Page/pages/CrudPage';
import { getSubmitCloseModalActionGroupDef } from 'src/views/definitionBuilders/common';
import {
  ActionType,
  FieldType,
  PagePrimarySize,
  PaneType,
  ShellModalSize,
} from 'src/views/definitionBuilders/types';
import {
  daySelected,
  getDays,
  getDaysToExclude,
  workingHours,
} from 'src/views/routes/operations/urban/urbanHelpers';

type RosterItem = Operations.Domain.Queries.ViewRoster.RosterItem;
type RosterGroupDto = Operations.Domain.Queries.GetRosterGroups.RosterGroupDto;
type CreateRosterCommand = Operations.Domain.Commands.Roster.CreateRoster.CreateRosterCommand;
type UpdateRosterCommand = Operations.Domain.Commands.Roster.UpdateRoster.UpdateRosterCommand;
type StaffMemberDto = Common.Dtos.StaffMemberDto;
type ShiftSkillSpecRequirementItem = Operations.Domain.Queries.ViewRoster.ShiftSkillSpecRequirementItem;
type ShiftItem = Operations.Domain.Queries.ViewRoster.ShiftItem;

export interface IMaintainRosterProps {
  mode: CrudPageMode;
  canManageRosters: boolean;
  loadRoster: (id: string) => Promise<void>;
  roster: RosterItem | undefined;

  createRoster: (command: CreateRosterCommand) => Promise<void>;
  updateRoster: (command: UpdateRosterCommand) => Promise<void>;
  deleteRoster: (rosterId: string) => Promise<void>;

  checkForUniqueRosterNumber: (
    rosterNumber: string
  ) => Promise<Common.Dtos.UniqueNameCheckResultDto>;
  listShifts: () => Promise<void>;
  shifts: Operations.Domain.Queries.ListShifts.ShiftListItem[];
  loadStaffMembers: () => Promise<void>;
  loadRosterGroups: () => Promise<void>;
  rosterGroups: RosterGroupDto[];
}

interface IUpdateRosterRouteParams {
  id: string;
}

type InternalProps = IMaintainRosterProps & RouteComponentProps<IUpdateRosterRouteParams>;

interface IMaintainRosterFormShiftItem {
  changeState: ChangeState;
  shift: {
    description: string;
    friday: boolean;
    id: string;
    monday: boolean;
    saturday: boolean;
    shiftName: string;
    shiftType: Operations.Domain.AggregatesModel.ShiftAggregate.ShiftType;
    clockOn: string;
    clockOff: string;
    unpaidBreaks: string;
    sunday: boolean;
    thursday: boolean;
    tuesday: boolean;
    wednesday: boolean;
    searchText: string;
    skillSpecRequirements: ShiftSkillSpecRequirementItem[];
  };
  days: Weekday[];
}

interface IMaintainRosterForm {
  rosterNumber: string;
  shifts: IMaintainRosterFormShiftItem[];
  staffMember?: StaffMemberDto;
  rosterGroupId?: string;
}

export const MaintainRoster: React.FC<InternalProps> = (props: InternalProps) => {
  const isUpdateMode = props.mode === 'update';
  const rosterId = props.match.params.id;

  useEffect(() => {
    props.listShifts();
    props.loadStaffMembers();
    props.loadRosterGroups();
  }, []);

  const rotatingRosterGroupIds = props.rosterGroups
    .filter(x => x.isRotating)
    .map(r => r.rosterGroupId);

  const isReadOnly = (rosterGroupId: string) => rotatingRosterGroupIds.includes(rosterGroupId);

  const handlePreSubmitForCreate = (roster: IMaintainRosterForm): CreateRosterCommand => {
    return {
      rosterNumber: roster.rosterNumber,
      shifts: roster.shifts
        .filter(x => x.changeState !== ChangeState.Deleted)
        .map(x => {
          return {
            shiftId: x.shift.id,
            monday: daySelected(x.days, Weekday.mon),
            tuesday: daySelected(x.days, Weekday.tue),
            wednesday: daySelected(x.days, Weekday.wed),
            thursday: daySelected(x.days, Weekday.thu),
            friday: daySelected(x.days, Weekday.fri),
            saturday: daySelected(x.days, Weekday.sat),
            sunday: daySelected(x.days, Weekday.sun),
          };
        }),
      staffMemberId: roster.staffMember && roster.staffMember.id,
      rosterGroupId: roster.rosterGroupId,
    };
  };

  const handlePreSubmitForUpdate = (roster: IMaintainRosterForm): UpdateRosterCommand => {
    return {
      id: rosterId,
      rosterNumber: roster.rosterNumber,
      shifts: roster.shifts
        .filter(x => x.changeState !== ChangeState.Deleted)
        .map(x => {
          return {
            shiftId: x.shift.id,
            monday: daySelected(x.days, Weekday.mon),
            tuesday: daySelected(x.days, Weekday.tue),
            wednesday: daySelected(x.days, Weekday.wed),
            thursday: daySelected(x.days, Weekday.thu),
            friday: daySelected(x.days, Weekday.fri),
            saturday: daySelected(x.days, Weekday.sat),
            sunday: daySelected(x.days, Weekday.sun),
          };
        }),
      staffMemberId: roster.staffMember && roster.staffMember.id,
      rosterGroupId: roster.rosterGroupId,
    };
  };

  const rosterData = (roster: RosterItem | undefined): IMaintainRosterForm => {
    if (!roster || !roster.id) {
      return {} as IMaintainRosterForm;
    }

    return {
      rosterNumber: roster.rosterNumber,
      shifts: roster.shifts.map(x => {
        return {
          changeState: ChangeState.Unchanged,
          shift: {
            description: x.description,
            friday: x.friday,
            id: x.id,
            monday: x.monday,
            saturday: x.saturday,
            shiftName: x.shiftName,
            shiftType: x.shiftType,
            clockOn: x.clockOn,
            clockOff: x.clockOff,
            unpaidBreaks: x.unpaidBreaks,
            sunday: x.sunday,
            thursday: x.thursday,
            tuesday: x.tuesday,
            wednesday: x.wednesday,
            searchText: `${x.shiftName} - ${x.shiftType.description} - ${x.description}`,
            skillSpecRequirements: x.skillSpecRequirements,
          },
          days: getDays(x.days),
        };
      }),
      staffMember: roster.staffMember,
      rosterGroupId: roster.rosterGroupId,
    };
  };

  const shiftsRequireDriver = (shifts: IMaintainRosterFormShiftItem[]) => {
    const allStaffShiftTypes = [ShiftType.Cleaning, ShiftType.Operations, ShiftType.Office];
    return !(
      shifts &&
      !!shifts.every &&
      shifts
        .filter(s => s.changeState !== ChangeState.Deleted)
        .every(
          s =>
            s.shift && s.shift.shiftType && allStaffShiftTypes.indexOf(s.shift.shiftType.id) !== -1
        )
    );
  };

  const getStaffMemberFilter = (shifts: IMaintainRosterFormShiftItem[]) => {
    return shiftsRequireDriver(shifts)
      ? StaffMemberFilter.hasDriversAuthorisation
      : StaffMemberFilter.active;
  };

  const getSkillSpecRequirements = (shifts: IMaintainRosterFormShiftItem[]) => {
    if (!shifts) {
      return [];
    }

    const uniqueSkillSpecIds = consolidateSkillSpecIds(
      shifts.filter(x => x.shift).map(x => x.shift.skillSpecRequirements)
    );

    return uniqueSkillSpecIds;
  };

  const getPageDef = (updating: boolean): ICrudPageDef => {
    const { canManageRosters, rosterGroups } = props;
    return {
      primarySize: PagePrimarySize.full,
      primarySection: {
        title: isUpdateMode ? 'Manage Roster' : 'Create a Roster',
        primaryActions: isUpdateMode
          ? [
              {
                actions: [
                  {
                    hidden: () => !canManageRosters,
                    actionType: ActionType.actionCollection,
                    actionGroups: [
                      {
                        actions: [
                          {
                            actionType: ActionType.modalActionButton,
                            label: 'Delete Roster',
                            icon: <TrashIcon />,
                            modalSize: ShellModalSize.oneQuarter,
                            modalDef: modalDefApi => ({
                              title: 'Delete Roster',
                              asForm: true,
                              panels: [
                                {
                                  panes: [
                                    {
                                      paneType: PaneType.customPane,
                                      render: () => (
                                        <span>Are you sure you want to delete roster?</span>
                                      ),
                                    },
                                  ],
                                },
                              ],
                              secondaryActions: [getSubmitCloseModalActionGroupDef('Ok')],
                              onFormSubmit: () => props.deleteRoster(rosterId),
                            }),
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ]
          : [],
        panels: [
          {
            panes: [
              {
                paneType: PaneType.formFieldsPane,
                columnCount: 2,
                fields: [
                  {
                    fieldType: FieldType.textField,
                    label: 'Roster Number',
                    dataAddr: 'rosterNumber',
                    maxLength: 200,
                    mandatory: true,
                    validateAsync: async d => {
                      if (
                        !d.fieldValue ||
                        (isUpdateMode &&
                          props.roster &&
                          props.roster.rosterNumber.toUpperCase() === d.fieldValue.toUpperCase())
                      ) {
                        return undefined;
                      }
                      const result = await props.checkForUniqueRosterNumber(d.fieldValue);
                      return result.nameExists ? `Roster number is already in use` : undefined;
                    },
                  },
                  {
                    fieldType: FieldType.selectField,
                    dataAddr: 'rosterGroupId',
                    label: 'Roster Group',
                    valueKey: 'rosterGroupId',
                    descriptionKey: 'name',
                    optionItems: rosterGroups,
                    useValueOnly: true,
                    readonly: d => isReadOnly(d.fieldValue),
                    linkTo: d => `/operations/roster-groups/${d.fieldValue}`,
                    optionRenderer: (d: RosterGroupDto) => (
                      <span>
                        {d.name}
                        {d.isRotating && (
                          <span>
                            &emsp; - &emsp;
                            <SyncIcon />
                          </span>
                        )}
                      </span>
                    ),
                    useOptionRendererAsValueRenderer: true,
                    valuesToDisable: d => {
                      return [...rotatingRosterGroupIds, d.fieldValue];
                    },
                  },
                ],
              },
            ],
          },
          {
            title: 'Allocations',
            panes: [
              {
                paneType: PaneType.formFieldsPane,
                columnCount: 2,
                fields: [
                  {
                    fieldType: FieldType.staffMemberField,
                    label: 'Staff Member',
                    dataAddr: 'staffMember',
                    readonly: d => isReadOnly(d.parentValue.rosterGroupId),
                    staffMemberFilter: d => getStaffMemberFilter(d.panelValue.shifts),
                    skillSpecRequirements: d => getSkillSpecRequirements(d.panelValue.shifts),
                  },
                ],
              },
            ],
          },
          {
            title: 'Shifts',
            dataAddr: 'shifts',
            panes: [
              {
                paneType: PaneType.tablePane,
                mandatory: true,
                dataRequiredForRows: 'sectionValue',
                fields: [
                  {
                    fieldType: FieldType.selectField,
                    label: 'Shift',
                    dataAddr: 'shift',
                    valueKey: 'id',
                    descriptionKey: 'searchText',
                    mandatory: true,
                    columnWidth: '400px',
                    optionItems: props.shifts,
                    valuesToDisable: d => {
                      return (d.panelValue as Array<Partial<IMaintainRosterFormShiftItem>>)
                        .map(s => s.shift && s.shift.id)
                        .filter(isDefined);
                    },
                    linkTo: d => `/operations/shifts/${d.parentValue.shift.id}`,
                    onChange: d => {
                      if (
                        shiftsRequireDriver(d.fieldData.panelValue) &&
                        d.fieldData.sectionValue.staffMember &&
                        !d.fieldData.sectionValue.staffMember.hasDriversAuthorisation
                      ) {
                        d.setFormValue(['staffMember'], undefined);
                      }
                    },
                  },
                  {
                    fieldType: FieldType.daysOfWeekField,
                    dataAddr: 'days',
                    label: 'Days of Week',
                    mandatory: true,
                    useValueOnly: true,
                    readonly: data => !data.parentValue || !data.parentValue.shift,
                    valuesToExclude: data => {
                      if (data.parentValue && data.parentValue.shift) {
                        return getDaysToExclude(data.parentValue.shift);
                      }
                      return [];
                    },
                    formatReadonly: data => {
                      if (data.parentValue && data.parentValue.days) {
                        let displayDays = [];
                        const days = data.parentValue.days;
                        if (days.indexOf(Weekday.mon) !== -1) {
                          displayDays.push('Mon');
                        }
                        if (days.indexOf(Weekday.tue) !== -1) {
                          displayDays.push('Tue');
                        }
                        if (days.indexOf(Weekday.wed) !== -1) {
                          displayDays.push('Wed');
                        }
                        if (days.indexOf(Weekday.thu) !== -1) {
                          displayDays.push('Thu');
                        }
                        if (days.indexOf(Weekday.fri) !== -1) {
                          displayDays.push('Fri');
                        }
                        if (days.indexOf(Weekday.sat) !== -1) {
                          displayDays.push('Sat');
                        }
                        if (days.indexOf(Weekday.sun) !== -1) {
                          displayDays.push('Sun');
                        }
                        return displayDays.join(', ');
                      }
                      return '';
                    },
                  },
                  {
                    fieldType: FieldType.timeField,
                    dataAddr: ['shift', 'clockOn'],
                    label: 'Clock On',
                    readonly: true,
                  },
                  {
                    fieldType: FieldType.timeField,
                    dataAddr: ['shift', 'clockOff'],
                    label: 'Clock Off',
                    readonly: true,
                  },
                  {
                    fieldType: FieldType.timeField,
                    dataAddr: ['totalWorkingHrs'],
                    label: 'Total Working Hours',
                    readonly: true,
                    formatReadonly: data => {
                      const j = data.parentValue?.shift as ShiftItem;
                      const days = data.parentValue?.days as Weekday[];
                      if (!j || !j.clockOn || !j.clockOff || !j.unpaidBreaks || !days) {
                        return undefined;
                      }
                      const hours = workingHours(j.clockOn, j.clockOff, j.unpaidBreaks);
                      const weekHours = hours?.mapUnits(hour => hour * days.length);
                      return getEditingFormattedTimeString(weekHours);
                    },
                  },
                  {
                    fieldType: FieldType.actionListField,
                    dataAddr: '',
                    columnWidth: '1px',
                    actionGroups: [
                      {
                        actions: [
                          {
                            hidden: d => isUpdateMode && !updating,
                            actionType: ActionType.removeArrayItemActionButton,
                            label: 'Remove Line',
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
              {
                paneType: PaneType.actionListPane,
                hidden: isUpdateMode && !updating,
                actionGroups: [
                  {
                    actions: [
                      {
                        actionType: ActionType.addArrayItemActionButton,
                        label: 'Add Shift',
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
        onFormPreSubmit: isUpdateMode ? handlePreSubmitForUpdate : handlePreSubmitForCreate,
        onFormSubmit: isUpdateMode ? props.updateRoster : props.createRoster,
      },
    };
  };

  const { mode, loadRoster, canManageRosters } = props;
  return (
    <CrudPage
      def={({ updating }) => getPageDef(updating)}
      mode={mode}
      isEditingForbidden={!canManageRosters}
      onLoadData={() => loadRoster(rosterId)}
      data={rosterData(props.roster)}
      createDefaultData={{}}
    />
  );
};
