import { Component } from 'react';
import { InputGroup } from 'reactstrap';
import { DateTime, Duration } from 'luxon';
import DateInput, {
  getEditingFormattedDateString,
  parseEditingFormattedDateString,
} from 'src/views/components/Page/fields/subfields/DateInput';
import TimeInput from 'src/views/components/Page/fields/subfields/TimeInput';
import {
  getEditingFormattedTimeString,
  parseEditingFormattedTimeString,
} from 'src/views/components/Page/fields/subfields/TimeHelpers';

interface IDateTimeInputProps {
  placeholder?: string;
  value: string;
  onChange: (newValue: string) => void;
  autofocus?: boolean;
  timezone?: string;
}

interface IDateTimeInputState {
  calendarOpen: boolean;
  prevFieldValue: string | undefined; // held in ISO datetime format
  externalValue: string | undefined; // held in ISO datetime format
  internalDateValue: string; // Held in local zone
  internalTimeValue: string; // Held in local zone
}

class DateTimeInput extends Component<IDateTimeInputProps, IDateTimeInputState> {
  static getDerivedStateFromProps(
    nextProps: Readonly<IDateTimeInputProps>,
    prevState: IDateTimeInputState
  ) {
    // If the external version of this field is different to what we have, then update using the external value
    // (the react-form value is king, so that external logic can update this field)
    if (nextProps.value !== prevState.prevFieldValue) {
      // If the external value changed to a different value that this control has, then reset
      // the internal state to sync up. Otherwise do nothing
      // (Eg: so that "2" isn't changed to a full date)
      const internalStateUpdate =
        prevState.externalValue === nextProps.value
          ? {}
          : DateTimeInput.getInternalStateFromExternal(nextProps.value, nextProps.timezone);

      return {
        prevFieldValue: nextProps.value,
        externalValue: nextProps.value,
        ...internalStateUpdate,
      };
    }
    return null;
  }

  private static getInternalStateFromExternal(
    fieldValue: string | undefined,
    timezone: string | undefined
  ) {
    // The fieldValue string should be an ISO date or datetime - assume utc unless specified in the string
    const date = fieldValue
      ? DateTime.fromISO(fieldValue, { zone: 'utc' }).setZone(timezone || 'local')
      : undefined;
    const internalDateValue =
      date && date.isValid ? getEditingFormattedDateString(date, timezone) : fieldValue || '';
    const internalTimeValue =
      date && date.isValid ? getEditingFormattedTimeString(date, timezone) : '';
    return {
      internalDateValue,
      internalTimeValue,
    };
  }

  constructor(props: IDateTimeInputProps) {
    super(props);
    const { value } = props;
    this.state = {
      calendarOpen: false,
      prevFieldValue: value,
      externalValue: value,
      ...DateTimeInput.getInternalStateFromExternal(value, props.timezone),
    };
  }

  private readonly handleInternalDateChange = (newDateValue: string) => {
    this.handleInternalChange(newDateValue, this.state.internalTimeValue);
  };

  private readonly handleInternalTimeChange = (newTimeValue: string) => {
    this.handleInternalChange(this.state.internalDateValue, newTimeValue);
  };

  private isTimeGreaterThan24Hours(time: Duration | undefined) {
    const minutesInDay = 1440;
    return !!time && time.as('minutes') >= minutesInDay;
  }

  private readonly handleInternalChange = (dateValue: string, timeValue: string) => {
    const date = parseEditingFormattedDateString(dateValue, this.props.timezone);
    const timeDuration = parseEditingFormattedTimeString(timeValue);
    const time = this.isTimeGreaterThan24Hours(timeDuration) ? undefined : timeDuration;
    const externalValue =
      date && time && date.isValid && time.isValid
        ? DateTime.fromObject({
            year: date.year,
            month: date.month,
            day: date.day,
            hour: time.hours,
            minute: time.minutes,
            zone: this.props.timezone || 'local',
          })
            .toUTC() // Make the external string be in UTC
            .toISO()
        : '';
    this.setState(
      { internalDateValue: dateValue, internalTimeValue: timeValue, externalValue },
      () => this.props.onChange(externalValue)
    );
  };

  render() {
    const { internalDateValue, internalTimeValue } = this.state;

    return (
      <InputGroup>
        <DateInput
          autoFocus={this.props.autofocus}
          displayAsInvalid={false}
          value={internalDateValue}
          placeholder={this.props.placeholder}
          onChange={this.handleInternalDateChange}
          timezone={this.props.timezone}
        />
        <TimeInput value={internalTimeValue} onChange={this.handleInternalTimeChange} />
      </InputGroup>
    );
  }
}

export default DateTimeInput;
