import { types, flow, getRoot } from 'mobx-state-tree';
import { getAjax } from 'src/domain/services/storeEnvironment';
import { IRootStoreModel } from 'src/domain/entities/RootStoreModel';

type LostPropertyItem = Operations.Domain.Queries.ViewLostPropertyItem.LostPropertyItem;

export const LostPropertyItemModel = types
  .model('LostPropertyItemModel', {
    LostProperty: types.maybe(types.frozen<LostPropertyItem>()),
  })
  .actions(self => {
    const ajax = getAjax(self);
    const root = getRoot(self) as IRootStoreModel;

    const loadLostPropertyItem = flow(function*(lostPropertyId: string) {
      self.LostProperty = yield ajax.raw
        .get(`/api/compliance/lost-property-items/${lostPropertyId}`)
        .toPromise()
        .then(r => r.response);
    });

    const createLostPropertyItem = flow(function*(
      command: Operations.Domain.Commands.LostProperty.CreateLostPropertyItem.CreateLostPropertyItemCommand
    ) {
      const lostPropertyId = yield ajax.raw
        .post(`/api/compliance/lost-property-items/create`, command)
        .toPromise()
        .then(r => r.response);
      root.history.push(`/compliance/lost-property-items/${lostPropertyId}`);
    });

    const updateLostPropertyItem = flow(function*(
      command: Operations.Domain.Commands.LostProperty.UpdateLostPropertyItem.UpdateLostPropertyItemCommand
    ) {
      yield ajax.raw.put(`/api/compliance/lost-property-items/${command.id}`, command).toPromise();
      yield loadLostPropertyItem(command.id);
    });
    return {
      loadLostPropertyItem,
      createLostPropertyItem,
      updateLostPropertyItem,
    };
  });
