import { DateTime, Duration } from 'luxon';

export const getAutoRefreshText = (refreshInterval: Duration, lastUpdated?: DateTime) => {
  const time = lastUpdated;

  if (!time || !time.isValid) {
    return 'Never';
  }

  const now = DateTime.local();
  if (time >= now.minus({ milliseconds: refreshInterval.as('milliseconds') / 2 })) {
    return 'Just now';
  }

  if (time >= now.minus(refreshInterval)) {
    return `Less than ${refreshInterval.as('seconds')} seconds ago`;
  }

  return `More than ${refreshInterval.as('seconds')} seconds ago`;
};
