import './GanttStriper.scss';
import { Component } from 'react';
import cn from 'classnames';
import { ISizedGroup, IGanttCalendarGroupItem } from '../baseTypes';

interface IGanttStriperProps {
  className?: string;
  rowHeightPx: number;
  sizedGroups: Array<IGanttCalendarGroupItem & ISizedGroup>;
}

class GanttStriper extends Component<IGanttStriperProps> {
  render() {
    const { className, rowHeightPx, sizedGroups } = this.props;
    return (
      <div
        className={cn('gantt-striper-component', className)}
        style={{ gridAutoRows: `${rowHeightPx}px` }}>
        {sizedGroups.map((g, i) =>
          i % 2 === 0 ? null : (
            <div
              key={g.id || ''}
              className="stripe"
              style={{
                gridArea: `${g.rowIndex + 1} / 1 / ${g.rowIndex + 1 + g.rowsUsed} / auto`,
              }}
            />
          )
        )}
      </div>
    );
  }
}

export default GanttStriper;
