import { DateTime } from 'luxon';
import styles from './PrintShifts.module.scss';
import { RenderJobInterval } from 'src/views/routes/workshop/jobs/jobSchedule/Scheduler/PrintDailyRunSheet/PrintDailyRunSheet';
import cn from 'classnames';

type ShiftListItem = Workshop.Domain.Queries.Shift.ListShifts.ShiftListItem;

export interface IPrintShiftsProps {
  date: DateTime;
  shifts: ShiftListItem[];
  defaultKioskDepotId: number;
  userName?: string;
}

const PrintShifts: React.FC<IPrintShiftsProps> = ({
  shifts,
  date,
  defaultKioskDepotId,
  userName,
}) => {
  return (
    <div className={styles.document}>
      <div className={styles.header}>
        Shifts for {userName} in {date.monthLong}
      </div>
      {shifts &&
        shifts.length > 0 &&
        shifts.map((s, i) => {
          return (
            <div
              key={i}
              className={cn(
                styles.shift,
                i > 0 &&
                  DateTime.fromISO(s.shiftCommence).day ===
                    DateTime.fromISO(shifts[i - 1].shiftCommence).day
                  ? styles.sameDay
                  : null
              )}>
              <span className={styles.shiftDate}>
                {i > 0 &&
                DateTime.fromISO(s.shiftCommence).day ===
                  DateTime.fromISO(shifts[i - 1].shiftCommence).day
                  ? ''
                  : DateTime.fromISO(s.shiftCommence).toLocaleString(DateTime.DATE_MED)}
              </span>
              <span className={styles.shiftInterval}>
                {RenderJobInterval(s.shiftCommence, s.shiftEnd, DateTime.fromISO(s.shiftCommence))}
              </span>
              <span className={styles.shiftName}>{s.shiftName}</span>
              {s.shiftDepot.id !== defaultKioskDepotId ? (
                <span>
                  &nbsp;<small>({s.shiftDepot.name})</small>
                </span>
              ) : null}
            </div>
          );
        })}
    </div>
  );
};

export default PrintShifts;
