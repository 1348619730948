import styles from '../AssetDetails.module.scss';
import Spinner from 'src/views/components/Spinner';
import { Fragment, useEffect, useState } from 'react';

type AssetPartItem = Workshop.Domain.Queries.AssetParts.AssetPartItem;

export interface IAssetPartsRegisterDisplayProps {
  parts?: AssetPartItem[];
  assetId: string;
  loadPartsRegisterForAsset: (assetId: string) => Promise<void>;
  deviceDepotId: number;
}

const CategoryParts: React.FC<{
  parts: AssetPartItem[];
  categoryDescription: string;
  deviceDepotId: number;
}> = ({ parts, categoryDescription, deviceDepotId }) => {
  var categoryParts = parts.filter(
    o =>
      (o.subCategory && o.subCategory.category.description === categoryDescription) ||
      (!o.subCategory && categoryDescription === 'Other')
  );
  return (
    <>
      {categoryParts.map(o => (
        <tr key={o.id}>
          <td>{o.subCategory ? o.subCategory.description : ''}</td>
          <td>{o.part.partNumber}</td>
          <td>{o.part.quantity}</td>
          <td>{o.part.description}</td>
          <td>{o.part.partLocations?.find(pl => pl.depotId === deviceDepotId)?.location}</td>
        </tr>
      ))}
    </>
  );
};

export const AssetPartsRegisterDisplay: React.FC<IAssetPartsRegisterDisplayProps> = ({
  assetId,
  parts,
  loadPartsRegisterForAsset,
  deviceDepotId,
}) => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    loadPartsRegisterForAsset(assetId).then(() => setIsLoading(false));
  }, [assetId]);

  let distinctCategoryDescriptions: Array<string> = [];

  if (parts && parts.length > 0) {
    distinctCategoryDescriptions = Array.from(
      new Set(
        parts
          .filter(o => o.subCategory && o.subCategory.category)
          .map(o => o.subCategory.category.description)
      )
    ).concat(['Other']);
  }

  return (
    <Spinner show={isLoading} className={styles.scrollableTabbedArea}>
      <div className={styles.partsRegister}>
        {isLoading ? (
          <div />
        ) : !parts || parts.length === 0 ? (
          <div>There are no parts configured for this asset.</div>
        ) : (
          <table>
            <tbody>
              <tr>
                <td />
                <td>Part No.</td>
                <td>Qty</td>
                <td>Description</td>
                <td>Location</td>
              </tr>
              {distinctCategoryDescriptions.map((categoryDescription, idx) => (
                <Fragment key={'categoryDescription' + idx}>
                  <tr>
                    <td colSpan={5} className={styles.categoryDescription}>
                      <span>{categoryDescription}</span>
                    </td>
                  </tr>

                  <CategoryParts
                    parts={parts}
                    categoryDescription={categoryDescription}
                    deviceDepotId={deviceDepotId}
                  />
                </Fragment>
              ))}
            </tbody>
          </table>
        )}
      </div>
    </Spinner>
  );
};
