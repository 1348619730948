import inject from 'src/views/injectFromStore';
import logger, { ILogger } from 'src/infrastructure/logging';
import { Component } from 'react';

interface IErrorBoundaryProps {
  log: ILogger;
}

interface IErrorBoundaryState {
  hasError: boolean;
}

class ErrorBoundary extends Component<IErrorBoundaryProps, IErrorBoundaryState> {
  constructor(props: IErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error: Error, info: React.ErrorInfo) {
    this.props.log.fatal(error, { detail: 'Rendering failed', info });
    this.setState({ hasError: true });
  }

  render() {
    if (this.state.hasError) {
      return (
        <div>
          <h1>:(</h1>
          <p>Unfortunately something went wrong. Please contact your system administrator.</p>
        </div>
      );
    }
    return this.props.children;
  }
}

const ErrorBoundaryContainer = inject<IErrorBoundaryProps>(() => ({
  log: logger,
}))(ErrorBoundary);

export default ErrorBoundaryContainer;
