import './Page.scss';
import { Component } from 'react';
import { IPageDef, PagePrimarySize, isSubsectionDef } from 'src/views/definitionBuilders/types';
import PageArea from './PageArea';
import SubsectionContext, { SubsectionContextValue } from './PageSubsectionContext';

interface IPageProps {
  className?: string;
  def: IPageDef;
  data?: unknown;
  showPrimarySectionSpinner?: boolean;
  onScroll?: (e: React.UIEvent<HTMLElement | UIEvent>) => void;
}

class Page extends Component<IPageProps> {
  render() {
    const { className, def, data, showPrimarySectionSpinner, onScroll } = this.props;
    const primarySubsections = def.secondarySections
      ? def.secondarySections.filter(isSubsectionDef)
      : [];
    return (
      <section
        onScroll={onScroll}
        className={`page-component ${className ? className : ''}`}
        style={getAreaColumnLayoutStyle(def.primarySize)}>
        <SubsectionContext.Provider value={new SubsectionContextValue()}>
          <PageArea
            className="secondary-area"
            sectionDefs={def.secondarySections || []}
            data={data}
          />
          <PageArea
            className="primary-area"
            sectionDefs={[def.primarySection]}
            logicalSubsectionDefs={primarySubsections}
            data={data}
            showSpinner={showPrimarySectionSpinner}
            autoFocus
          />
        </SubsectionContext.Provider>
      </section>
    );
  }
}

export default Page;

function getAreaColumnLayoutStyle(size?: PagePrimarySize) {
  const defaultStyle = { gridTemplateColumns: '1fr 1fr' };
  switch (size) {
    case PagePrimarySize.sevenTwelves:
      return { ...defaultStyle, gridTemplateColumns: '1.5fr minmax(12em, 1fr)' };
    case PagePrimarySize.twoThirds:
      return { ...defaultStyle, gridTemplateColumns: '2fr minmax(12em, 1fr)' };
    case PagePrimarySize.threeQuarters:
      return { ...defaultStyle, gridTemplateColumns: '3fr minmax(12em, 1fr)' };
    case PagePrimarySize.full:
      return { ...defaultStyle, gridTemplateColumns: '1fr 0' };
    default:
      return defaultStyle;
  }
}
