import inject from 'src/views/injectFromStore';
import MaintainEngine, { IMaintainEngineProps } from './MaintainEngine';
import Omit from 'src/infrastructure/omit';

const Container = inject<Omit<IMaintainEngineProps, 'mode'>, IMaintainEngineProps>(
  (allStores, p) => ({
    canManageEngines: allStores.rootStore.account.isWorkshopDepartmentMember,
    onLoadEngine: allStores.rootStore.workshop.engine.loadEngine,
    engine: allStores.rootStore.workshop.engine.engine,
    serviceTypes: allStores.rootStore.workshop.engine.serviceTypes.slice(),
    partsGroups: allStores.rootStore.workshop.partsGroups.partsGroups.slice(),
    onCreateEngine: allStores.rootStore.workshop.engine.createEngine,
    onUpdateEngine: allStores.rootStore.workshop.engine.updateEngine,
    onCheckForUniqueName: allStores.rootStore.workshop.engine.checkForUniqueModel,
    onLoadServiceTypes: allStores.rootStore.workshop.engine.loadServiceTypes,
    onLoadPartsGroups: allStores.rootStore.workshop.partsGroups.listPartsGroups,
    setEngineInactive: allStores.rootStore.workshop.engine.setEngineInactive,
    setEngineActive: allStores.rootStore.workshop.engine.setEngineActive,
    deleteEngine: allStores.rootStore.workshop.engine.deleteEngine,
    getEngineUsage: allStores.rootStore.workshop.engine.getEngineUsage,
    engineUsage: allStores.rootStore.workshop.engine.engineUsage,
  })
)(MaintainEngine);

export default Container;
