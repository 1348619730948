import { IRootStoreModel } from 'src/domain/entities/RootStoreModel';
import { UiRoute } from 'src/domain/services/buildUiRoutes';
import { UiRouteName } from 'src/domain/baseTypes';

export default function buildUiRoutes(store: IRootStoreModel) {
  const holidayCreate = new UiRoute(
    UiRouteName.operationsHolidayCreate,
    '/operations/holidays/create',
    () => 'Create Holiday',
    false
  );

  const holidayManage = new UiRoute(
    UiRouteName.operationsHolidayManage,
    '/operations/holidays/:id',
    () => 'Manage Holiday',
    false
  );

  const holidaysList = new UiRoute(
    UiRouteName.operationsHolidayList,
    '/operations/holidays',
    () => 'Holidays',
    false,
    true,
    [holidayCreate, holidayManage]
  );

  return {
    [UiRouteName.operationsHolidayList]: holidaysList,
    [UiRouteName.operationsHolidayCreate]: holidayCreate,
    [UiRouteName.operationsHolidayManage]: holidayManage,
  };
}
