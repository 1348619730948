import './DriverDeclaration.scss';
import { SingleClickAwareButton } from '../../components/presentational/SingleClickAwareButton/SingleClickAwareButton';
import { COMPANY_NAME } from 'src/appSettings';
import { observer } from 'mobx-react';
import { useKioskRootStore } from 'src/domain/entities/KioskRootStoreModel';
import { useEffect, useState } from 'react';
import { Spinner } from 'reactstrap/lib';
import { ENABLE_ALCO_READINGS } from 'src/appSettings';
const checkAlcoReading = ENABLE_ALCO_READINGS;

const DriverDeclaration: React.FC = observer(() => {
  const rootStore = useKioskRootStore();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const clockOn = rootStore.kiosk.clockOn.clockOn;

  const isClockedOn = rootStore.kiosk.clockOn.isClockedOn;
  const loadStaffMemberStatus = rootStore.kiosk.clockOn.loadStaffMemberStatus;

  const testResult = rootStore.kiosk.clockOn.testResult;
  const loadAlcolizerTestResult = rootStore.kiosk.clockOn.loadAlcolizerTestResult;
  const staffMemberId = rootStore.account.id;
  const isAlcoReadingEnabledDevice = rootStore.account.isAlcoReadingEnabledDevice;

  useEffect(() => {
    if (staffMemberId) {
      loadStaffMemberStatus(staffMemberId);

      if (!isClockedOn && checkAlcoReading && isAlcoReadingEnabledDevice) {
        setIsLoading(true);
        loadAlcolizerTestResult(staffMemberId).then(() => setIsLoading(false));
      }
    }
  }, []);

  return (
    <div className="driver-declaration-component">
      <div className="declaration">
        {checkAlcoReading && isAlcoReadingEnabledDevice ? (
          <>
            {isLoading ? (
              <div className="loading">
                <Spinner />
              </div>
            ) : (
              <>
                {checkAlcoReading &&
                  (testResult === null || testResult?.exception === null ? (
                    <h1>
                      No breath test results found within the last hour. Please conduct a breath
                      test before clocking on.
                    </h1>
                  ) : testResult?.exception ? (
                    <h1>A positive breath test result was returned. Please advise Operations.</h1>
                  ) : (
                    <h1>
                      {`By Clocking on I am declaring that I am FIT FOR WORK and that I will adhere to all
  ${COMPANY_NAME} Employee Policies. I also declare I will obey all relevant state road traffic
  rules.`}
                    </h1>
                  ))}
              </>
            )}
          </>
        ) : (
          <>
            <h1>
              {`By Clocking on I am declaring that I am FIT FOR WORK and that I will adhere to all
  ${COMPANY_NAME} Employee Policies. I also declare I will obey all relevant state road traffic
  rules.`}
            </h1>
          </>
        )}
        <div className="accept">
          <SingleClickAwareButton
            disabled={isLoading}
            useReactStrapButton
            type="button"
            size="lg"
            block
            onClick={() =>
              checkAlcoReading &&
              isAlcoReadingEnabledDevice &&
              (testResult?.exception === true ||
                testResult?.exception === null ||
                testResult === null)
                ? rootStore.account.signOutKiosk()
                : clockOn({
                    staffMemberId: staffMemberId as string,
                    logId: testResult?.logId,
                    timestamp: testResult?.timestamp,
                  })
            }>
            Ok
          </SingleClickAwareButton>
        </div>
      </div>
    </div>
  );
});

export default DriverDeclaration;
