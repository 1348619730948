import inject from 'src/views/injectFromStore';
import PartsRegister, { IPartsRegisterProps } from './PartsRegister';
import Omit from 'src/infrastructure/omit';

const Container = inject<Omit<IPartsRegisterProps, 'mode'>, IPartsRegisterProps>(
  (allStores, p) => ({
    canManagePartsRegister: allStores.rootStore.account.isWorkshopDepartmentMember,
    asset: allStores.rootStore.asset.asset,
    assetParts: allStores.rootStore.asset.assetParts,
    onLoadAsset: allStores.rootStore.asset.loadAsset,
    onLoadAssetParts: allStores.rootStore.asset.loadAssetParts,
    onUpdateAssetParts: allStores.rootStore.asset.updateAssetParts,
    searchParts: allStores.rootStore.workshop.parts.searchParts,

    categories: allStores.rootStore.workshop.assetPartCategories.assetPartCategoryListItems.slice(),
    subCategories: allStores.rootStore.workshop.assetPartSubCategories.assetPartSubCategoryListItems.slice(),
    onLoadAssetPartCategories:
      allStores.rootStore.workshop.assetPartCategories.loadAssetPartCategoryListItems,
    onLoadAssetPartSubCategories:
      allStores.rootStore.workshop.assetPartSubCategories.loadAssetPartSubCategoryListItems,
  })
)(PartsRegister);

export default Container;
