import styles from './AllowancesSummaryTable.module.scss';

type TimesheetAllowanceSummaryItem = Common.Dtos.TimesheetAllowanceSummaryItem;

interface IAllowancesSummaryTableProps {
  allowances: TimesheetAllowanceSummaryItem[];
}

export const AllowancesSummaryTable: React.FC<IAllowancesSummaryTableProps> = ({ allowances }) => {
  return (
    <table className={styles.allowancesSummaryTable}>
      <thead>
        <tr>
          <th>Description</th>
          <th className={styles.smallCell}>Quantity</th>
        </tr>
      </thead>
      <tbody>
        {allowances.map((a, i) => (
          <tr key={`allowance-summary-item-${i}`}>
            <td>{a.allowanceName}</td>
            <td>{a.quantity}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};
