import './IncidentDetails.scss';

interface IIncidentDetailsProps {
  incidentDetails: string;
}

const IncidentDetails: React.FC<IIncidentDetailsProps> = ({ incidentDetails }) => {
  return <div className="incident-details-component">{incidentDetails}</div>;
};

export default IncidentDetails;
