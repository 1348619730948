enum KnownEmploymentStatuses {
  SalaryId = 1,
  PermanentFullTime = 2,
  PermanentPartTime = 3,
  Casual = 4,
  Unknown = 999999,
}

export function isCasual(employmentStatusId: number): boolean {
  return employmentStatusId === KnownEmploymentStatuses.Casual;
}
