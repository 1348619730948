import { types, flow, getRoot } from 'mobx-state-tree';
import { getAjax } from 'src/domain/services/storeEnvironment';
import { IRootStoreModel } from 'src/domain/entities/RootStoreModel';

type CodeOfConductItem = Operations.Domain.Queries.ViewCodeOfConduct.CodeOfConductItem;

export const CodeOfConductItemModel = types
  .model('CodeOfConductItemModel', {
    codeOfConduct: types.maybe(types.frozen<CodeOfConductItem>()),
  })
  .actions(self => {
    const ajax = getAjax(self);
    const root = getRoot(self) as IRootStoreModel;

    const loadCodeOfConduct = flow(function*(codeOfConductId: string) {
      self.codeOfConduct = yield ajax.raw
        .get(`/api/compliance/code-of-conducts/${codeOfConductId}`)
        .toPromise()
        .then(r => r.response);
    });

    const createCodeOfConduct = flow(function*(
      command: Operations.Domain.Commands.CodeOfConduct.CreateCodeOfConduct.CreateCodeOfConductCommand
    ) {
      const codeOfConductId = yield ajax.raw
        .post(`/api/compliance/code-of-conducts/create`, command)
        .toPromise()
        .then(r => r.response);
      root.history.push(`/compliance/code-of-conducts/${codeOfConductId}`);
    });

    const updateCodeOfConduct = flow(function*(
      command: Operations.Domain.Commands.CodeOfConduct.UpdateCodeOfConduct.UpdateCodeOfConductCommand
    ) {
      yield ajax.raw.put(`/api/compliance/code-of-conducts/${command.id}`, command).toPromise();
      yield loadCodeOfConduct(command.id);
    });
    return {
      loadCodeOfConduct,
      createCodeOfConduct,
      updateCodeOfConduct,
    };
  });
