import { types, flow, getRoot } from 'mobx-state-tree';
import { getAjax } from 'src/domain/services/storeEnvironment';
import { IRootStoreModel } from 'src/domain/entities/RootStoreModel';

type SubcontractorItem = Operations.Domain.Queries.ViewSubcontractor.SubcontractorItem;

const enc = encodeURIComponent;

export const SubcontractorModel = types
  .model('SubcontractorModel', {
    subcontractor: types.maybe(types.frozen<SubcontractorItem>()),
  })
  .actions(self => {
    const ajax = getAjax(self);
    const root = getRoot(self) as IRootStoreModel;

    const createSubcontractor = flow(function*(
      command: Operations.Domain.Commands.Subcontractor.CreateSubcontractor.CreateSubcontractorCommand
    ) {
      const id = yield ajax.raw
        .post(`/api/operations/subcontractors/create`, command)
        .toPromise()
        .then<string>(r => r.response);
      self.subcontractor = undefined;
      root.history.push(`/operations/subcontractors/${id}`);
    });

    const checkForUniqueName = flow(function*(name: string) {
      return yield ajax.raw
        .get(`/api/operations/subcontractors/check-for-unique-name?name=${enc(name)}`)
        .toPromise()
        .then<Common.Dtos.UniqueNameCheckResultDto>(r => r.response);
    });

    const loadSubcontractor = flow(function*(subcontractorId: string) {
      self.subcontractor = yield ajax.raw
        .get(`/api/operations/subcontractors/${subcontractorId}`)
        .toPromise()
        .then<Operations.Domain.Queries.ViewSubcontractor.SubcontractorItem>(r => r.response);
    });

    const updateSubcontractor = flow(function*(
      command: Operations.Domain.Commands.Subcontractor.UpdateSubcontractor.UpdateSubcontractorCommand
    ) {
      yield ajax.raw.put(`/api/operations/subcontractors/${command.id}`, command).toPromise();
      yield loadSubcontractor(command.id);
    });

    const createContact = flow(function*(
      command: Operations.Domain.Commands.Subcontractor.CreateSubcontractorContact.CreateSubcontractorContactCommand
    ) {
      yield ajax.raw
        .post(`/api/operations/subcontractors/${command.subcontractorId}/contacts/create`, command)
        .toPromise();
      yield loadSubcontractor(command.subcontractorId);
    });

    const updateContact = flow(function*(
      command: Operations.Domain.Commands.Subcontractor.UpdateSubcontractorContact.UpdateSubcontractorContactCommand
    ) {
      yield ajax.raw
        .put(
          `/api/operations/subcontractors/${command.subcontractorId}/contacts/${command.contactId}`,
          command
        )
        .toPromise();
      yield loadSubcontractor(command.subcontractorId);
    });

    return {
      createSubcontractor,
      checkForUniqueName,
      loadSubcontractor,
      updateSubcontractor,
      createContact,
      updateContact,
    };
  });
