import { Component } from 'react';
import { saveAs } from 'file-saver';
import { Table } from 'reactstrap';
import { DateTime } from 'luxon';
import { RouteComponentProps, Link } from 'react-router-dom';
import { PrintIcon, ExcelIcon } from 'src/images/icons';
import { IPaginationPageLoadDataRequest } from 'src/domain/baseTypes';
import { IFormApiWithoutState } from 'src/views/components/Page/forms/base';
import {
  IPageDef,
  PagePrimarySize,
  PaneType,
  FieldType,
  ActionType,
  FieldDefs,
} from 'src/views/definitionBuilders/types';
import Page from 'src/views/components/Page/Page';
import StocktakeQuantity from 'src/views/routes/workshop/inventory/recordStocktake/StocktakeQuantity';
import withQueryParams, { IQueryParamsProps } from 'src/views/hocs/withQueryParams';

type ListStocktakePartsQuery = Workshop.Domain.Queries.Parts.GetStocktakePartsList.ListStocktakePartsQuery;
type RecordStocktakeCommand = Workshop.Domain.Commands.Part.RecordStocktake.RecordStocktakeCommand;
type StocktakePartListItem = Workshop.Domain.Queries.Parts.StocktakePartListItem;
type PartCategoryItem = Workshop.Domain.Queries.PartCategory.PartCategoryItem;
type WorkshopDepot = Common.Queries.Workshop.GetWorkshopDepots.WorkshopDepotDto;

export interface IRecordStocktakeProps {
  listStocktakeParts: (
    request: IPaginationPageLoadDataRequest<ListStocktakePartsQuery>
  ) => Promise<void>;
  refreshStocktakeParts: (
    request: IPaginationPageLoadDataRequest<ListStocktakePartsQuery>
  ) => Promise<void>;
  parts: StocktakePartListItem[];
  totalResults: number;
  size: number;
  page: number;
  onRecordStocktake: (command: RecordStocktakeCommand) => Promise<void>;
  loadPartCategories: () => Promise<void>;
  partCategories: PartCategoryItem[];
  onGeneratePdf: (query: Partial<ListStocktakePartsQuery>) => Promise<Blob>;
  onGenerateExcel: (query: Partial<ListStocktakePartsQuery>) => Promise<Blob>;
  workshopDepots: Array<WorkshopDepot>;
  defaultWorkshopDepot: WorkshopDepot | undefined;
  selectedWorkshopDepotId: number;
}

type InternalProps = IRecordStocktakeProps &
  RouteComponentProps<{}> & {
    getQueryParams: IQueryParamsProps<ListStocktakePartsQuery>['getQueryParams'];
  };

interface IRecordStocktakePageState {
  dataLoading: boolean;
  updating: boolean;
}

class RecordStocktake extends Component<InternalProps, IRecordStocktakePageState> {
  private primaryApi: IFormApiWithoutState | undefined;

  constructor(props: InternalProps) {
    super(props);
    this.state = {
      dataLoading: false,
      updating: false,
    };
  }

  componentDidMount() {
    if (this.props.selectedWorkshopDepotId) {
      this.loadData(this.props.page);
      this.props.loadPartCategories();
    } else {
      this.onSelectedDepotChange(this.props.defaultWorkshopDepot!.id);
    }
  }

  componentDidUpdate(prevProps: Readonly<InternalProps>) {
    if (this.props.location.search !== prevProps.location.search) {
      this.loadData(1);
    }
  }

  private readonly onSelectedDepotChange = (depotId: number) => {
    const { location, history } = this.props;
    const query = new URLSearchParams(location.search);
    query.set('depotId', depotId.toString());
    history.replace({ ...location, search: query.toString() });
  };

  private loadData = async (page: number) => {
    try {
      this.setState({ dataLoading: true });
      await this.props.listStocktakeParts({
        query: this.props.getQueryParams(),
        page: page,
      });
    } finally {
      this.setState({ dataLoading: false });
    }
  };

  private refreshData = async (partIds: string[]) => {
    try {
      this.setState({ dataLoading: true });
      await this.props.refreshStocktakeParts({
        query: { ...this.props.getQueryParams(), partIds: partIds },
        page: this.props.page,
      });
    } finally {
      this.setState({ dataLoading: false });
    }
  };

  private getStocktakeDate() {
    return this.primaryApi && this.primaryApi.getValue('stocktakeDate');
  }

  private readonly getData = () => {
    const { parts } = this.props;
    const stocktakeDate = this.getStocktakeDate() || DateTime.local().toISODate();
    return {
      stocktakeDate: stocktakeDate,
      parts,
      depotId: this.props.selectedWorkshopDepotId,
    };
  };

  recordStocktake = (values: RecordStocktakeCommand) => {
    const { onRecordStocktake } = this.props;
    return Promise.resolve(
      onRecordStocktake(values).then(() =>
        this.refreshData(values.stocktakeEntries.map(x => x.partId))
      )
    );
  };

  private readonly generatePdf = () => {
    const fileName = `Stocktake_${DateTime.local().toFormat('yyyyMMddHHmm')}.pdf`;
    return this.props
      .onGeneratePdf(this.props.getQueryParams())
      .then(blob => saveAs(blob, fileName));
  };

  private readonly generateExcel = () => {
    const fileName = `Stocktake_${DateTime.local().toFormat('yyyyMMddHHmm')}.xlsx`;
    return this.props
      .onGenerateExcel(this.props.getQueryParams())
      .then(blob => saveAs(blob, fileName));
  };

  private formatDate(date: string | undefined): string {
    const parsed = DateTime.fromISO(date as string);
    if (parsed.isValid) {
      return parsed.toLocaleString(DateTime.DATE_MED);
    }
    return '';
  }

  private readonly getFilterFieldDefs = () => {
    return {
      location: {
        fieldType: FieldType.textField,
        label: 'Location',
        dataAddr: 'location',
      } as FieldDefs,
      categories: {
        fieldType: FieldType.selectMultiField,
        label: 'Category',
        dataAddr: 'categories',
        useValueOnly: true,
        valueKey: 'id',
        descriptionKey: 'description',
        optionItems: this.props.partCategories,
      } as FieldDefs,
      lastStocktakeFrom: {
        fieldType: FieldType.dateField,
        dataAddr: 'lastStocktakeFrom',
        label: 'Last Stocktake From',
        onBlur: api => {
          if (!api.formValues.lastStocktakeTo) {
            api.setFormValue(['lastStocktakeTo'], api.fieldData.fieldValue);
          }
          api.validateField(['lastStocktakeTo']);
        },
      } as FieldDefs,
      lastStocktakeTo: {
        fieldType: FieldType.dateField,
        dataAddr: 'lastStocktakeTo',
        label: 'Last Stocktake To',
        validate: d => {
          if (!d.fieldValue || !d.parentValue.lastStocktakeFrom) {
            return undefined;
          }
          const from = DateTime.fromISO(d.parentValue.lastStocktakeFrom);
          const to = DateTime.fromISO(d.fieldValue);
          return from > to
            ? 'Last Stocktake To cannot be earlier than Last Stocktake From'
            : undefined;
        },
      } as FieldDefs,
    };
  };

  private readonly getPageDef = (): IPageDef => {
    const filterFieldDefsLookup = this.getFilterFieldDefs();
    const { selectedWorkshopDepotId } = this.props;
    return {
      primarySize: PagePrimarySize.threeQuarters,
      primarySection: {
        title: 'Stocktake',
        suppressLeavePrompt: true,
        asForm: true,
        getApi: api => (this.primaryApi = api),
        primaryActions: [
          {
            actions: [
              {
                actionType: ActionType.searchActionButton,
              },
              {
                actionType: ActionType.actionCollection,
                actionGroups: [
                  {
                    actions: [
                      {
                        actionType: ActionType.actionButton,
                        label: 'Print Stocktake',
                        icon: <PrintIcon fixedWidth />,
                        onClick: this.generatePdf,
                      },
                      {
                        actionType: ActionType.actionButton,
                        label: 'Export to Excel',
                        icon: <ExcelIcon fixedWidth />,
                        onClick: this.generateExcel,
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
        panels: [
          {
            panes: [
              {
                paneType: PaneType.formFieldsPane,
                columnCount: 3,
                fields: [
                  {
                    fieldType: FieldType.selectField,
                    dataAddr: 'depotId',
                    label: 'Depot',
                    useValueOnly: true,
                    valueKey: 'id',
                    descriptionKey: 'description',
                    mandatory: true,
                    optionItems: this.props.workshopDepots,
                    readonly: this.props.workshopDepots.length < 2,
                    onChange: field => this.onSelectedDepotChange(field.fieldData.fieldValue),
                  },
                  {
                    fieldType: FieldType.dateField,
                    dataAddr: 'stocktakeDate',
                    label: 'Stocktake Date',
                    mandatory: true,
                  },
                ],
              },
              {
                paneType: PaneType.paginationPane,
                size: this.props.size,
                page: this.props.page,
                totalResults: this.props.totalResults,
                loadPage: this.loadData,
              },
              {
                paneType: PaneType.customPane,
                render: d => {
                  const { parts } = this.props;
                  return (
                    <Table hover striped borderless>
                      <thead>
                        <tr>
                          <th>Part Number</th>
                          <th>Description</th>
                          <th>Category</th>
                          <th>Location</th>
                          <th>Last Stocktake Quantity</th>
                          <th>Last Stocktake Date</th>
                          <th>Stocktake Quantity</th>
                        </tr>
                      </thead>
                      <tbody>
                        {parts.map((p: StocktakePartListItem) => (
                          <tr key={p.id}>
                            <td>
                              <Link to={`/workshop/parts/${p.id}`}>{p.partNumber}</Link>
                            </td>
                            <td>{p.description}</td>
                            <td>{p.category}</td>
                            <td>{p.location}</td>
                            <td>{p.lastStocktakeQuantity}</td>
                            <td>{this.formatDate(p.lastStocktakeDate)}</td>
                            <td>
                              <StocktakeQuantity
                                onBlur={value => {
                                  const stocktakeDate = this.getStocktakeDate();
                                  if (stocktakeDate) {
                                    const data: RecordStocktakeCommand = {
                                      stocktakeDate: stocktakeDate,
                                      stocktakeEntries: [
                                        {
                                          partId: p.id,
                                          quantity: value,
                                        },
                                      ],
                                      depotId: selectedWorkshopDepotId,
                                    };
                                    return this.recordStocktake(data);
                                  }
                                  return Promise.reject();
                                }}
                              />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  );
                },
              },
              {
                paneType: PaneType.paginationPane,
                size: this.props.size,
                page: this.props.page,
                totalResults: this.props.totalResults,
                loadPage: this.loadData,
              },
            ],
          },
        ],
        secondaryActions: [
          {
            actions: [
              {
                actionType: ActionType.filterActionButton,
                filterFields: Object.keys(filterFieldDefsLookup).map(k => filterFieldDefsLookup[k]),
                filterDef: filterDefApi => [
                  {
                    panes: [
                      {
                        paneType: PaneType.formFieldsPane,
                        fields: [filterFieldDefsLookup.location, filterFieldDefsLookup.categories],
                      },
                      {
                        paneType: PaneType.formFieldsPane,
                        columnCount: 2,
                        fields: [
                          filterFieldDefsLookup.lastStocktakeFrom,
                          filterFieldDefsLookup.lastStocktakeTo,
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    };
  };
  render() {
    return this.props.selectedWorkshopDepotId ? (
      <Page className="stocktake-component" data={this.getData()} def={this.getPageDef()} />
    ) : null;
  }
}
export default withQueryParams(RecordStocktake);
