import { types, flow } from 'mobx-state-tree';
import { getAjax } from 'src/domain/services';

type UpdateTechSpecDropdownOptionsCommand = Workshop.Domain.Commands.TechSpecs.UpdateTechSpecDropdownOptionsCommand;
type GetEditableTechSpecDropdownOptionsQueryResponse = Workshop.Domain.Queries.TechSpecs.GetEditableTechSpecDropdownOptions.GetEditableTechSpecDropdownOptionsQueryResponse;

export const EditableTechSpecDropdownOptionsModel = types
  .model('EditableTechSpecDropdownOptionsModel', {
    response: types.maybe(types.frozen<GetEditableTechSpecDropdownOptionsQueryResponse>()),
  })
  .actions(self => {
    const ajax = getAjax(self);

    const getAll = flow(function*(techSpecId: number) {
      self.response = yield ajax.workshop.techSpecs.getEditableDropdownOptions(techSpecId);
    });

    const updateAll = flow(function*(
      techSpecId: number,
      cmd: UpdateTechSpecDropdownOptionsCommand
    ) {
      yield ajax.workshop.techSpecs.updateDropdownOptions(techSpecId, cmd);
      yield getAll(techSpecId);
    });

    return {
      getAll,
      updateAll,
    };
  });
