import { DateTime } from 'luxon';
import DailySummarySheet from 'src/views/kioskRoutes/operationsKiosk/home/PrintDailySummary/DailySummarySheet/DailySummarySheet';
import { COMPANY_ID } from 'src/appSettings';

export interface IPrintDailySummaryProps {
  userDisplayName: string;
  jobs: Operations.Domain.Queries.ListDailySummarySheetDetails.DailySummarySheetJobDetailsDto[];
  date: DateTime;
  checks: Operations.Domain.Queries.ListJobsForDriver.PredepartureCheckItem[];
  checkDraft: boolean;
  checkColumns: number;
  showChecks?: boolean;
  showAccident?: boolean;
}

const PrintDailySummary: React.FC<IPrintDailySummaryProps> = (props: IPrintDailySummaryProps) => {
  switch (COMPANY_ID.toLocaleLowerCase()) {
    case 'ps':
      return (
        <DailySummarySheet
          userDisplayName={props.userDisplayName}
          jobs={props.jobs}
          date={props.date}
          showChecks={false}
          checks={props.checks}
          checkDraft={props.checkDraft}
          checkColumns={3}
          showAccident={true}
        />
      );
    case 'cc':
      return (
        <DailySummarySheet
          userDisplayName={props.userDisplayName}
          jobs={props.jobs}
          date={props.date}
          showChecks={true}
          checks={props.checks}
          checkDraft={props.checkDraft}
          checkColumns={2}
          showAccident={true}
        />
      );
    case 'wbt':
      return (
        <DailySummarySheet
          userDisplayName={props.userDisplayName}
          jobs={props.jobs}
          date={props.date}
          checks={props.checks}
          showChecks={true}
          checkDraft={props.checkDraft}
          checkColumns={2}
          showAccident={false}
        />
      );
    case 'wbg':
      return (
        <DailySummarySheet
          userDisplayName={props.userDisplayName}
          jobs={props.jobs}
          date={props.date}
          checks={props.checks}
          checkDraft={props.checkDraft}
          showChecks={true}
          checkColumns={2}
          showAccident={false}
        />
      );
    default:
      return (
        <DailySummarySheet
          userDisplayName={props.userDisplayName}
          jobs={props.jobs}
          date={props.date}
          checks={props.checks}
          showChecks={true}
          checkDraft={props.checkDraft}
          checkColumns={3}
          showAccident={false}
        />
      );
  }
};

export default PrintDailySummary;
