import { observer } from 'mobx-react';
import { Link, RouteComponentProps } from 'react-router-dom';
import {
  ChangeState,
  JobAttachmentSource,
  JobType,
  RailBookingStatus,
  allContactedVia,
} from 'src/api/enums';
import { useRootStore } from 'src/domain/entities/RootStoreModel';
import { CheckIcon, EditIcon, PlusIcon, TimesIcon, TrashIcon } from 'src/images/icons';
import CrudPage, {
  ICrudPageApi,
  ICrudPageDef,
  CrudPageMode,
} from 'src/views/components/Page/pages/CrudPage';
import { getSubmitCloseModalActionGroupDef } from 'src/views/definitionBuilders/common';
import {
  ActionType,
  FieldType,
  IFieldData,
  IHasChangeState,
  IPaneData,
  PagePrimarySize,
  PaneType,
  ShellModalSize,
} from 'src/views/definitionBuilders/types';
import PriceTotals from 'src/views/routes/operations/rail/maintainRailBooking/PriceTotals';
import {
  ISkipFatigueValidationSubmissionMeta,
  getSkipFatigueActionGroupDef,
} from 'src/views/routes/operations/shared/getSkipFatigueActionGroupDef';
import getEditVehicleModalDef from './getMaintainVehicleModalDef';
import getMaintainVehicleModalDef from './getMaintainVehicleModalDef';
import getMaintainJobModalDef from './getMaintainJobModalDef';
import { DateTimeFormat } from 'src/views/components/DateTimeFormat';
import { getReadonlyJobNumberFieldDef } from '../../shared/commonFieldDefBuilders';
import getMaintainExtrasModalDef from './getMaintainExtrasModalDef';
import { getCancelRailBookingModalDef } from './getCancelRailBookingModalDef';

import getMaintainSubcontractorsModalDef from './getMaintainSubcontractorsModalDef';
import React from 'react';

type RailBookingItem = Operations.Domain.Queries.ViewRailBooking.RailBookingItem;
type RailBookingExtraItem = Operations.Domain.Queries.ViewRailBooking.RailBookingExtraItem;
type RailBookingVehicleItem = Operations.Domain.Queries.ViewRailBooking.RailBookingVehicleItem;
type RailBookingSubcontractorItem = Operations.Domain.Queries.ViewRailBooking.RailBookingSubcontractorItem;
type RailTemplateItem = Operations.Domain.Queries.ViewRailBooking.RailTemplateItem;
type UpdateRailBookingCommand = Operations.Domain.Commands.RailBooking.UpdateRailBooking.UpdateRailBookingCommand;
type RailBookingJobItem = Operations.Domain.Queries.ViewRailBooking.RailBookingJobItem;
type AssetItem = Common.Queries.Workshop.GetFleetAssetList.AssetItem;
type RailBookingShiftAttachmentDetails = Operations.Domain.Commands.RailBooking.CreateRailBooking.RailBookingShiftAttachmentDetails;

type ISubmissionMeta = ISkipFatigueValidationSubmissionMeta & {};
export interface IMaintainRailBookingRouteProps {
  mode: CrudPageMode;
  id: string;
}

type InternalProps = RouteComponentProps<IMaintainRailBookingRouteProps>;

const MaintainRailBooking: React.FC<InternalProps> = observer((props: InternalProps) => {
  const rootStore = useRootStore();

  const canManageRailBookings = rootStore.account.isRailDepartmentMember;
  const canSkipFatigueValidation = rootStore.account.isAdminUser;
  const railBooking = rootStore.operations.rail.railBooking.railBooking;
  const getRailBooking = rootStore.operations.rail.railBooking.loadRailBooking;
  const updateRailBooking = rootStore.operations.rail.railBooking.updateRailBooking;
  const fleetAssets = rootStore.assets.fleetAssetListItems.slice();
  const loadFleetAssets = rootStore.assets.loadFleetAssets;
  const rail = rootStore.operations.rail;
  const subcontractors = rootStore.operations.subcontractors.allSubcontractors.slice();
  const loadSubcontractors = rootStore.operations.subcontractors.loadAllSubcontractors;
  const vehicleTypes = rootStore.operations.vehicleTypes.vehicleTypes.slice();
  const loadVehicleTypes = rootStore.operations.vehicleTypes.loadVehicleTypes;
  const extraTypes = rootStore.operations.extraTypes.list.railExtraTypes.slice();
  const loadExtraTypes = rootStore.operations.extraTypes.list.loadExtraTypes;
  const deleteRailBooking = rootStore.operations.rail.railBooking.deleteRailBooking;
  const railBookingId: string = props.match.params.id ?? '';
  const noBookingFailure = () => Promise.reject('No Rail Booking Loaded');
  const onCancelRailBooking = (cancellationReason: string) =>
    railBooking
      ? rail.railBooking.cancelRailBooking({
          railBookingId: railBooking.id,
          cancellationReason,
        })
      : noBookingFailure();
  const jobsForCancellingRailBooking =
    rootStore.operations.rail.railBooking.jobsForCancellingRailBooking;
  const loadJobsForCancellingRailBooking =
    rootStore.operations.rail.railBooking.loadJobsForCancellingRailBooking;
  const completeRailBooking = rootStore.operations.rail.railBooking.completeRailBooking;
  const searchCustomers = rootStore.operations.sales.customer.searchCustomers;
  const searchContactsForCustomer = rootStore.operations.sales.customer.searchContactsForCustomer;
  const loadStaffMembers = rootStore.people.staffMembers.loadAllStaffMembers;
  const loadExclusions = rootStore.operations.exclusionModel.loadExclusions;
  const allRailBookingContractTypes = rootStore.operations.rail.railBookingContractTypes;
  const loadAllRailBookingContractTypes = rootStore.operations.rail.loadRailBookingContractTypes;
  const generateShiftAttachmentId =
    rootStore.operations.rail.railTemplate.generateShiftAttachmentId;
  const downloadAttachment = rootStore.operations.rail.railBooking.downloadAttachment;

  const loadedRailBooking =
    railBooking && railBooking.id === railBookingId ? railBooking : undefined;

  const currentRailBooking = rootStore.operations.rail.railBooking.railBooking;

  if (currentRailBooking === undefined) {
    noBookingFailure();
  }

  const vehicleSubtotal = (data: IPaneData) => {
    const vehicles: RailBookingVehicleItem[] = Array.isArray(data.parentValue.railBookingVehicles)
      ? data.parentValue.railBookingVehicles
      : [];
    const subtotal = vehicles.reduce((acc: number, cur) => {
      var parsed = Number(cur.totalPrice);
      return acc + (!Number.isNaN(parsed) ? parsed : 0);
    }, 0);
    return subtotal;
  };

  const subcontractorSubTotal = (data: IPaneData) => {
    const subcontractors: RailBookingSubcontractorItem[] = Array.isArray(
      data.parentValue.railBookingSubcontractors
    )
      ? data.parentValue.railBookingSubcontractors
      : [];
    const subtotal = subcontractors.reduce((acc: number, cur) => {
      var parsed = Number(cur.totalPrice);
      return acc + (!Number.isNaN(parsed) ? parsed : 0);
    }, 0);
    return subtotal;
  };

  const hasInProgressJobs = (railBooking: RailBookingItem) =>
    railBooking.railBookingJobs.some(j => j.hasJobProgress);

  const extraSubtotal = (data: IPaneData) => {
    const extras: RailBookingExtraItem[] = Array.isArray(data.parentValue.railBookingExtras)
      ? data.parentValue.railBookingExtras
      : [];
    const subtotal = extras.reduce((acc: number, cur) => {
      var parsed = Number(cur.totalPrice);
      return acc + (!Number.isNaN(parsed) ? parsed : 0);
    }, 0);
    return subtotal;
  };

  const extraGstTotal = (data: IPaneData) => {
    const extras: RailBookingExtraItem[] = Array.isArray(data.parentValue.railBookingExtras)
      ? data.parentValue.railBookingExtras
      : [];
    const gstTotal = extras.reduce((acc: number, cur) => {
      var parsed = Number(cur.totalGst);
      return acc + (!Number.isNaN(parsed) ? parsed : 0);
    }, 0);
    return gstTotal;
  };

  const handlePreSubmitForUpdate = (
    railBooking: RailBookingItem,
    meta: ISubmissionMeta | undefined
  ): UpdateRailBookingCommand => {
    return {
      id: railBooking.id,
      description: railBooking.description,
      customerId: railBooking.customer && railBooking.customer.customerId,
      contactId: railBooking.contact && railBooking.contact.contactId,
      contactedVia: railBooking.contactedVia,
      invoiceNumber: railBooking.invoiceNumber,
      railBookingVehicles: railBooking.railBookingVehicles
        .filter(v => (v as IHasChangeState).changeState !== ChangeState.Deleted)
        .map(v => {
          return {
            vehicleTypeId: v.vehicleTypeId,
            quantity: v.quantity,
            totalHours: v.totalHours,
            totalPrice: v.totalPrice,
          };
        }),
      railBookingJobs: railBooking.railBookingJobs
        .filter(j => (j as IHasChangeState).changeState !== ChangeState.Deleted)
        .map(job => {
          return {
            jobId: job.jobId,
            staffMemberId: job.staffMember && job.staffMember.id,
            assetId: job.asset && job.asset.id,
            subcontractorId: job.subcontractor && job.subcontractor.id,
            attachmentDetails: job.attachmentDetails.map(a => ({
              jobId: a.jobId,
              attachmentId: a.attachmentId,
              fileName: a.fileName,
              availableOnTablet: a.availableOnTablet,
              availableOnKiosk: a.availableOnKiosk,
              changeState: a.changeState,
              order: 0,
              jobAttachmentSource: JobAttachmentSource.RailTemplateShift,
              createdOn: a.createdOn,
              lastModifiedOn: a.lastModifiedOn,
            })) as RailBookingShiftAttachmentDetails[],
          };
        }),
      railBookingSubcontractors: railBooking.railBookingSubcontractors
        .filter(v => (v as IHasChangeState).changeState !== ChangeState.Deleted)
        .map(v => {
          return {
            subcontractorId: v.subcontractorId,
            quantity: v.quantity,
            totalHours: v.totalHours,
            totalPrice: v.totalPrice,
          };
        }),
      railBookingExtras: railBooking.railBookingExtras
        .filter(e => (e as IHasChangeState).changeState !== ChangeState.Deleted)
        .map(e => {
          return {
            extraTypeId: e.extraTypeId,
            quantity: e.quantity,
            totalHours: e.totalHours,
            totalPrice: e.totalPrice,
          };
        }),
      skipFatigueValidation: !!(meta && meta.skipFatigueValidation),
      contractTypeId: railBooking.contractTypeId,
    };
  };

  const getPageDef = (pageApi: ICrudPageApi): ICrudPageDef => {
    const railBooking = loadedRailBooking;

    return {
      primarySize: PagePrimarySize.full,
      primarySection: {
        title: `Rail Booking ${railBooking ? railBooking.bookingNumber : ''}`,
        badge:
          loadedRailBooking && loadedRailBooking.status
            ? { label: RailBookingStatus[loadedRailBooking.status] }
            : undefined,
        defaultTouchedFields: ['railBookingJobs'],
        primaryActions:
          pageApi.updating || !canManageRailBookings
            ? []
            : [
                {
                  actions: [
                    {
                      actionType: ActionType.actionCollection,
                      actionGroups: [
                        {
                          actions: [
                            {
                              actionType: ActionType.modalActionButton,
                              label: 'Complete Rail Booking',
                              icon: <CheckIcon />,
                              modalSize: ShellModalSize.oneQuarter,
                              modalDef: () => ({
                                title: 'Complete Rail Booking',
                                asForm: true,
                                panels: [
                                  {
                                    panes: [
                                      {
                                        paneType: PaneType.formFieldsPane,
                                        fields: [
                                          {
                                            fieldType: FieldType.customField,
                                            dataAddr: 'fake',
                                            render: () => (
                                              <span>
                                                Are you sure you wish to complete this booking?
                                              </span>
                                            ),
                                          },
                                        ],
                                      },
                                    ],
                                  },
                                ],
                                secondaryActions: [getSubmitCloseModalActionGroupDef('Ok')],
                                onFormSubmit: () => completeRailBooking(railBookingId),
                              }),
                            },
                            {
                              actionType: ActionType.modalActionButton,
                              label: 'Delete Rail Booking',
                              icon: <TrashIcon />,
                              modalSize: ShellModalSize.oneQuarter,
                              modalDef: () => ({
                                title: 'Delete Rail Booking',
                                asForm: true,
                                panels: [
                                  {
                                    panes: [
                                      {
                                        paneType: PaneType.formFieldsPane,
                                        fields: [
                                          {
                                            fieldType: FieldType.customField,
                                            dataAddr: 'fake',
                                            render: () => (
                                              <span>
                                                This booking will be deleted along with all the
                                                associated jobs. Are you sure?{' '}
                                              </span>
                                            ),
                                          },
                                        ],
                                      },
                                    ],
                                  },
                                ],
                                secondaryActions: [getSubmitCloseModalActionGroupDef('Ok')],
                                onFormSubmit: () => deleteRailBooking(railBookingId),
                              }),
                              hidden: loadedRailBooking?.status === RailBookingStatus.Cancelled,
                            },
                            {
                              actionType: ActionType.modalActionButton,
                              label: `Cancel${
                                loadedRailBooking && hasInProgressJobs(loadedRailBooking)
                                  ? ' in Progress'
                                  : ''
                              }`,
                              icon: <TimesIcon fixedWidth />,
                              modalSize: ShellModalSize.half,

                              onOpenModal: async () =>
                                await loadJobsForCancellingRailBooking(loadedRailBooking!.id),
                              modalDef: getCancelRailBookingModalDef(
                                loadedRailBooking!,
                                jobsForCancellingRailBooking,
                                onCancelRailBooking
                              ),
                              hidden:
                                !loadedRailBooking ||
                                loadedRailBooking.status !== RailBookingStatus.Booked ||
                                !canManageRailBookings,
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
        clearStandardSecondaryActions: true,
        secondaryActions:
          !pageApi.updating || !canManageRailBookings
            ? []
            : [
                {
                  actions: [
                    {
                      actionType: ActionType.submitActionButton,
                      level: 'primary',
                      subActionGroups: getSkipFatigueActionGroupDef<ISubmissionMeta>(
                        canSkipFatigueValidation,
                        {
                          customRender: () => {
                            return (
                              <div>
                                <p>
                                  The fatigue validation for jobs from this rail booking will be
                                  skipped for this submit. This skip will be recorded in job
                                  activity log.
                                </p>
                                <p>
                                  <span>
                                    Are you sure you want to submit and skip fatigue validation?
                                  </span>
                                </p>
                              </div>
                            );
                          },
                        }
                      ),
                    },
                    {
                      actionType: ActionType.resetActionButton,
                    },
                  ],
                },
              ],
        panels: [
          {
            panes: [
              {
                paneType: PaneType.formFieldsPane,
                fields: [
                  {
                    fieldType: FieldType.textField,
                    label: 'Description',
                    dataAddr: 'description',
                    maxLength: 200,
                    mandatory: true,
                  },
                ],
              },
              {
                paneType: PaneType.formFieldsPane,
                columnCount: 3,
                fields: [
                  {
                    fieldType: FieldType.selectAsyncField,
                    dataAddr: 'customer',
                    label: 'Customer',
                    valueKey: 'customerId',
                    descriptionKey: 'customerName',
                    linkTo: d => `/sales/customers/${d.fieldValue.customerId}`,
                    loadOptionItems: searchCustomers,
                    onChange: api => {
                      const values = { ...api.formValues, contact: undefined };
                      api.setFormValues(values);
                    },
                    mandatory: true,
                  },
                  {
                    fieldType: FieldType.selectAsyncField,
                    dataAddr: 'contact',
                    label: 'Contact',
                    valueKey: 'contactId',
                    descriptionKey: 'contactName',
                    loadOptionItems: (s, d) =>
                      searchContactsForCustomer(
                        d.paneValue.customer && d.paneValue.customer.customerId,
                        s
                      ),
                    readonly: d => !d.parentValue.customer,
                    autoload: true,
                  },
                  {
                    fieldType: FieldType.selectField,
                    label: 'Contacted Via',
                    dataAddr: 'contactedVia',
                    valueKey: 'value',
                    descriptionKey: 'description',
                    useValueOnly: true,
                    optionItems: allContactedVia,
                    mandatory: true,
                  },
                ],
              },
              {
                paneType: PaneType.formFieldsPane,
                columnCount: 3,
                fields: [
                  {
                    fieldType: FieldType.selectField,
                    optionItems: allRailBookingContractTypes,
                    label: 'Contract Type',
                    dataAddr: 'contractTypeId',
                    valueKey: 'id',
                    descriptionKey: 'description',
                    useValueOnly: true,
                    mandatory: true,
                  },
                  {
                    fieldType: FieldType.textField,
                    label: 'Invoice Number',
                    dataAddr: 'invoiceNumber',
                    maxLength: 200,
                  },
                ],
              },
              {
                paneType: PaneType.formFieldsPane,
                columnCount: 1,
                fields: [
                  {
                    fieldType: FieldType.readonlyField,
                    label: d => (d.fieldValue?.length > 1 ? 'Rail Templates' : 'Rail Template'),
                    dataAddr: 'railTemplates',
                    formatReadonly: d => (
                      <>
                        {d.fieldValue?.map((r: RailTemplateItem, i: number) => (
                          <Link to={`/operations/rail/rail-templates/${r.id}`}>
                            <span>{r.name}</span>
                            <br />
                          </Link>
                        ))}
                      </>
                    ),
                  },
                ],
              },
              {
                paneType: PaneType.nestingPane,
                dataAddr: 'railBookingJobs',
                panes: [
                  {
                    paneType: PaneType.tablePane,
                    title: 'Jobs',
                    neverEditable: true,
                    fields: [
                      getReadonlyJobNumberFieldDef<RailBookingJobItem>('jobId'),
                      {
                        fieldType: FieldType.readonlyField,
                        label: 'Shift Name',
                        dataAddr: 'shiftName',
                      },
                      {
                        fieldType: FieldType.readonlyField,
                        label: 'Staff Member',
                        dataAddr: ['staffMember', 'name'],
                        columnWidth: '15em',
                        hidden: d => d.parentValue.hasSubcontractor,
                      },
                      {
                        fieldType: FieldType.readonlyField,
                        label: 'Vehicle',
                        dataAddr: ['asset', 'name'],
                        hidden: (d: IFieldData<AssetItem>) =>
                          (d.parentValue.jobType &&
                            d.parentValue.jobType.id === JobType.RailCustomerService) ||
                          d.parentValue.hasSubcontractor,
                      },
                      {
                        fieldType: FieldType.readonlyField,
                        label: 'Subcontractor',
                        dataAddr: ['subcontractor', 'name'],
                        hidden: d => !d.parentValue.hasSubcontractor,
                        linkTo: (d: IFieldData<AssetItem>) =>
                          d.fieldValue
                            ? `/operations/subcontractors/${d.fieldValue.id}`
                            : undefined,
                      },
                      {
                        fieldType: FieldType.dateTimeField,
                        label: 'Clock On',
                        dataAddr: 'clockOn',
                        readonly: true,
                        formatReadonly: d => <DateTimeFormat value={d.fieldValue} />,
                        hidden: d => (d.parentValue as RailBookingJobItem).isContinuingFrom,
                      },
                      {
                        fieldType: FieldType.dateTimeField,
                        label: 'Depart Depot',
                        dataAddr: 'departDepot',
                        readonly: true,
                        formatReadonly: d => (
                          <DateTimeFormat
                            value={d.fieldValue}
                            previousValue={d.parentValue.clockOn}
                          />
                        ),
                        hidden: d => (d.parentValue as RailBookingJobItem).isContinuingFrom,
                      },
                      {
                        fieldType: FieldType.dateTimeField,
                        label: 'Shift Commence',
                        dataAddr: 'shiftCommence',
                        readonly: true,
                        formatReadonly: d => (
                          <DateTimeFormat
                            value={d.fieldValue}
                            previousValue={
                              (d.parentValue as RailBookingJobItem).isContinuingFrom
                                ? undefined
                                : d.parentValue.departDepot
                            }
                          />
                        ),
                      },
                      {
                        fieldType: FieldType.dateTimeField,
                        label: 'Shift End',
                        dataAddr: 'shiftEnd',
                        readonly: true,
                        formatReadonly: d => (
                          <DateTimeFormat
                            value={d.fieldValue}
                            previousValue={d.parentValue.shiftCommence}
                          />
                        ),
                      },
                      {
                        fieldType: FieldType.dateTimeField,
                        label: 'Arrive Depot',
                        dataAddr: 'arriveDepot',
                        readonly: true,
                        formatReadonly: d => (
                          <DateTimeFormat
                            value={d.fieldValue}
                            previousValue={d.parentValue.shiftEnd}
                          />
                        ),
                      },
                      {
                        fieldType: FieldType.dateTimeField,
                        label: 'Clock Off',
                        dataAddr: 'clockOff',
                        readonly: true,
                        formatReadonly: d => (
                          <DateTimeFormat
                            value={d.fieldValue}
                            previousValue={
                              d.parentValue.jobType.id === JobType.Rail
                                ? d.parentValue.arriveDepot
                                : d.parentValue.clockOn
                            }
                          />
                        ),
                      },
                      {
                        fieldType: FieldType.actionListField,
                        hidden: !pageApi.updating,
                        columnWidth: '100px',
                        actionGroups: [
                          {
                            actions: [
                              {
                                actionType: ActionType.modalActionButton,
                                label: 'Edit Job',
                                icon: <EditIcon fixedWidth />,
                                modalSize: ShellModalSize.twoThirds,
                                hidden: d => d.actionValue.changeState === ChangeState.Deleted,
                                modalDef: getMaintainJobModalDef(
                                  pageApi.updating,
                                  fleetAssets,
                                  subcontractors,
                                  generateShiftAttachmentId,
                                  downloadAttachment
                                ),
                              },
                              {
                                actionType: ActionType.removeArrayItemActionButton,
                                label: 'Remove Job',
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
              {
                paneType: PaneType.nestingPane,
                dataAddr: 'railBookingVehicles',
                panes: [
                  {
                    paneType: PaneType.tablePane,
                    title: 'Vehicles',
                    neverEditable: true,
                    fields: [
                      {
                        fieldType: FieldType.readonlyField,
                        dataAddr: 'vehicleTypeId',
                        label: 'Vehicle Type',
                        columnWidth: '20em',
                        formatReadonly: d =>
                          (d.fieldValue &&
                            vehicleTypes.find(v => v.id === d.fieldValue)?.description) ??
                          '',
                      },
                      {
                        fieldType: FieldType.numericField,
                        readonly: true,
                        dataAddr: 'quantity',
                        columnWidth: '6em',
                        numericConfig: {
                          numericType: 'unsignedDecimal',
                          maxPointDigits: 2,
                          maxValue: 99999,
                        },

                        label: 'Quantity',
                      },
                      {
                        fieldType: FieldType.numericField,
                        dataAddr: 'totalHours',
                        label: 'Total Hours',
                        columnWidth: '6em',
                        readonly: true,
                        numericConfig: {
                          numericType: 'unsignedDecimal',
                          maxPointDigits: 2,
                          maxValue: 99999,
                        },
                      },
                      {
                        fieldType: FieldType.numericField,
                        readonly: true,
                        dataAddr: 'totalPrice',
                        label: 'Total Price',
                        columnWidth: '7em',
                        numericConfig: { numericType: 'unsignedDecimal', maxPointDigits: 2 },
                      },
                      {
                        fieldType: FieldType.actionListField,
                        columnWidth: '1px',
                        hidden: !pageApi.updating,
                        actionGroups: [
                          {
                            actions: [
                              {
                                actionType: ActionType.modalActionButton,
                                label: 'Edit Vehicle',
                                icon: <EditIcon fixedWidth />,
                                modalSize: ShellModalSize.oneThird,
                                modalDef: getMaintainVehicleModalDef(vehicleTypes, true),
                              },
                              {
                                actionType: ActionType.removeArrayItemActionButton,
                                label: 'Remove Vehicle',
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    paneType: PaneType.actionListPane,
                    hidden: !pageApi.updating,
                    actionGroups: [
                      {
                        actions: [
                          {
                            actionType: ActionType.modalActionButton,
                            label: 'Add Vehicle',
                            icon: <PlusIcon />,
                            modalSize: ShellModalSize.oneThird,
                            modalDef: getEditVehicleModalDef(vehicleTypes, false),
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
              {
                paneType: PaneType.customPane,
                render: api => {
                  return <PriceTotals subtotal={vehicleSubtotal(api.data)} />;
                },
              },
              {
                paneType: PaneType.nestingPane,
                dataAddr: 'railBookingSubcontractors',
                panes: [
                  {
                    paneType: PaneType.tablePane,
                    title: 'Subcontractors',
                    neverEditable: true,
                    fields: [
                      {
                        fieldType: FieldType.readonlyField,
                        dataAddr: 'subcontractorId',
                        label: 'Subcontractor',
                        columnWidth: '20em',
                        formatReadonly: data =>
                          (data.fieldValue &&
                            subcontractors.find(
                              subcontractor => subcontractor.id === data.fieldValue
                            )?.name) ??
                          '',
                      },
                      {
                        fieldType: FieldType.numericField,
                        readonly: true,
                        dataAddr: 'quantity',
                        numericConfig: { numericType: 'unsignedInt', maxValue: 99999 },
                        label: 'Quantity',
                        columnWidth: '6em',
                      },
                      {
                        fieldType: FieldType.numericField,
                        readonly: true,
                        dataAddr: 'totalHours',
                        numericConfig: {
                          numericType: 'unsignedDecimal',
                          maxPointDigits: 2,
                          maxValue: 99999,
                        },
                        label: 'Total Hours',
                        columnWidth: '6em',
                      },
                      {
                        fieldType: FieldType.numericField,
                        dataAddr: 'totalPrice',
                        numericConfig: { numericType: 'unsignedDecimal', maxPointDigits: 2 },
                        label: 'Total Price',
                        columnWidth: '7em',
                        readonly: true,
                      },
                      {
                        fieldType: FieldType.actionListField,
                        columnWidth: '1px',
                        hidden: !pageApi.updating,
                        actionGroups: [
                          {
                            actions: [
                              {
                                actionType: ActionType.modalActionButton,
                                label: 'Edit Subcontractor',
                                icon: <EditIcon fixedWidth />,
                                modalSize: ShellModalSize.oneThird,
                                modalDef: getMaintainSubcontractorsModalDef(subcontractors, true),
                              },
                              {
                                actionType: ActionType.removeArrayItemActionButton,
                                label: 'Remove Subconractor',
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    paneType: PaneType.actionListPane,
                    hidden: !pageApi.updating,
                    actionGroups: [
                      {
                        actions: [
                          {
                            actionType: ActionType.modalActionButton,
                            label: 'Add Subcontractor',
                            icon: <PlusIcon fixedWidth />,
                            modalSize: ShellModalSize.oneThird,
                            modalDef: getMaintainSubcontractorsModalDef(subcontractors, false),
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
              {
                paneType: PaneType.customPane,
                render: api => {
                  return <PriceTotals subtotal={subcontractorSubTotal(api.data)} />;
                },
              },
              {
                paneType: PaneType.nestingPane,
                dataAddr: 'railBookingExtras',
                panes: [
                  {
                    paneType: PaneType.tablePane,
                    dataRequiredForRows: 'paneValue',
                    title: 'Extras',
                    neverEditable: true,
                    fields: [
                      {
                        fieldType: FieldType.readonlyField,
                        dataAddr: 'extraTypeId',
                        label: 'Extra Type',
                        columnWidth: '20em',
                        formatReadonly: d =>
                          (d.fieldValue &&
                            extraTypes.find(v => v.id === d.fieldValue)?.description) ??
                          '',
                      },
                      {
                        fieldType: FieldType.readonlyField,
                        dataAddr: 'quantity',
                        label: 'Quantity',
                        columnWidth: '6em',
                      },
                      {
                        fieldType: FieldType.readonlyField,
                        dataAddr: 'totalHours',
                        label: 'Total Hours',
                        columnWidth: '6em',
                      },
                      {
                        fieldType: FieldType.readonlyField,
                        dataAddr: 'totalPrice',
                        columnWidth: '7em',
                        label: 'Total Price',
                      },
                      {
                        fieldType: FieldType.actionListField,
                        columnWidth: '1px',
                        hidden: !pageApi.updating,
                        actionGroups: [
                          {
                            actions: [
                              {
                                actionType: ActionType.modalActionButton,
                                label: 'Edit Extra',
                                icon: <EditIcon fixedWidth />,
                                modalSize: ShellModalSize.oneThird,
                                modalDef: getMaintainExtrasModalDef(
                                  pageApi.updating,
                                  extraTypes,
                                  true
                                ),
                              },
                              {
                                actionType: ActionType.removeArrayItemActionButton,
                                label: 'Remove Extra',
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    paneType: PaneType.actionListPane,
                    hidden: !pageApi.updating,
                    actionGroups: [
                      {
                        actions: [
                          {
                            actionType: ActionType.modalActionButton,
                            label: 'Add Extra',
                            icon: <PlusIcon />,
                            modalSize: ShellModalSize.oneThird,
                            modalDef: getMaintainExtrasModalDef(
                              pageApi.updating,
                              extraTypes.filter(e => e.isActive),
                              false
                            ),
                          },
                        ],
                      },
                    ],
                  },
                  {
                    paneType: PaneType.customPane,
                    render: api => {
                      return (
                        <PriceTotals
                          subtotal={extraSubtotal(api.data)}
                          gstTotal={extraGstTotal(api.data)}
                        />
                      );
                    },
                  },
                ],
              },
            ],
          },
          {
            title: 'Total',
            panes: [
              {
                paneType: PaneType.customPane,
                render: api => {
                  return (
                    <PriceTotals
                      subtotal={
                        extraSubtotal(api.data) +
                        vehicleSubtotal(api.data) +
                        subcontractorSubTotal(api.data)
                      }
                      gstTotal={
                        extraGstTotal(api.data) +
                        vehicleSubtotal(api.data) * 0.1 +
                        subcontractorSubTotal(api.data) * 0.1
                      }
                    />
                  );
                },
              },
            ],
          },
        ],
        onFormPreSubmit: handlePreSubmitForUpdate,
        onFormSubmit: updateRailBooking,
      },
    };
  };

  const loadRailBooking = () => {
    const promises = [
      getRailBooking(railBookingId),
      loadFleetAssets(),
      loadSubcontractors(),
      loadVehicleTypes(),
      loadExtraTypes(),
      loadStaffMembers(),
      loadExclusions(),
      loadAllRailBookingContractTypes(),
    ];
    return Promise.all(promises).then(() => undefined);
  };

  return (
    <CrudPage
      def={getPageDef}
      mode="update"
      isEditingForbidden={!canManageRailBookings}
      onLoadData={loadRailBooking}
      data={loadedRailBooking}
    />
  );
});

export default MaintainRailBooking;
