import { DateTime, Interval } from 'luxon';
import { BusUsageStatus, ProgressId } from 'src/api/enums';
import { MappedProgress } from 'src/views/components/MaintainJobProgress';
type PreviousNextJobWithProgressDto = Common.Queries.GetPreviousAndNextJobProgress.PreviousNextJobWithProgressDto;
type PreviousNextJobProgressDto = Common.Queries.GetPreviousAndNextJobProgress.PreviousNextJobProgressDto;

export type Chunk = {
  interval: Interval;
};

export const chunkPrevNextJob = (job: PreviousNextJobWithProgressDto | undefined) => {
  if (!job) {
    return [];
  }

  return chunkJob(job.progresses);
};

export const chunkJob = (progresses: (MappedProgress | PreviousNextJobProgressDto)[]) => {
  let chunks: Chunk[] = [];
  let currentChunk: Chunk | undefined = undefined;

  const ordered = [...progresses].sort((a, b) =>
    DateTime.fromISO(a.occurredAt) < DateTime.fromISO(b.occurredAt)
      ? -1
      : DateTime.fromISO(a.occurredAt) > DateTime.fromISO(b.occurredAt)
      ? 1
      : 0
  );
  for (let i = 0; i < ordered.length; i++) {
    const item = ordered[i];
    const occurredAt = DateTime.fromISO(item.occurredAt);
    if (!occurredAt.isValid) {
      continue;
    }
    if (currentChunk === undefined) {
      currentChunk = { interval: Interval.fromDateTimes(occurredAt, occurredAt) };
      continue;
    }

    currentChunk.interval = Interval.fromDateTimes(currentChunk.interval.start, occurredAt);

    if (
      item.progress === ProgressId.OnBreak ||
      item.progress === ProgressId.Finished ||
      (item.isWorkshop ? item.progress === BusUsageStatus.Finished : false)
    ) {
      chunks.push(currentChunk);
      currentChunk = undefined;
      continue;
    }
  }

  if (currentChunk !== undefined) {
    chunks.push(currentChunk);
  }

  return chunks;
};

export const doChunksOverlap = (currentChunks: Chunk[], testingChunks: Chunk[]): Interval[] => {
  let overlappingIntervals: Interval[] = [];

  for (let i = 0; i < testingChunks.length; i++) {
    const test = testingChunks[i].interval;
    if (!test.isValid) {
      continue;
    }
    for (let j = 0; j < currentChunks.length; j++) {
      const thisInterval = currentChunks[j].interval;
      if (!thisInterval.isValid) {
        continue;
      }

      const intersect = test.intersection(thisInterval);
      const overlap = test.overlaps(thisInterval) || thisInterval.overlaps(test);
      const contains =
        (test.contains(thisInterval.start) && test.contains(thisInterval.end)) ||
        (thisInterval.contains(test.start) && thisInterval.contains(test.end));
      const abutsStart = thisInterval.abutsStart(test);
      const abutsEnd = thisInterval.abutsEnd(test);

      if (intersect || overlap || abutsStart || abutsEnd || contains) {
        overlappingIntervals.push(thisInterval);
      }
    }
  }

  return overlappingIntervals;
};

export const isDateInInterval = (interval: Interval, date: DateTime) =>
  interval.end.valueOf() === date.valueOf() || // Luxon equality didn't like === only :(
  interval.start.valueOf() === date.valueOf() ||
  interval.contains(date);
