import styles from './DepotSelector.module.scss';
import React from 'react';
import SlideInScreen from '../components/layout/SlideInScreen/SlideInScreen';
import { INotificationOptions, NotificationType } from 'src/domain/baseTypes';
import DepotPinForm from './DepotPinForm';
import { CHANGE_DEPOT_PIN } from 'src/appSettings';
import DepotSelectForm from './DepotSelectForm';
import DepotButton from './DepotButton';

type WorkshopDepotDto = Common.Queries.Workshop.GetWorkshopDepots.WorkshopDepotDto;

export interface IDepotSelectorProps {
  toggleSlider: () => void;
  isOpen: boolean;
  disabled: boolean;
  depot?: WorkshopDepotDto;
  depots: WorkshopDepotDto[];
  saveDeviceDepotToLocalStorage: (depotId: number) => void;
  addNotification: (message: string, options?: Partial<INotificationOptions>) => void;
}

export const DepotSelector: React.FC<IDepotSelectorProps> = ({
  toggleSlider,
  isOpen,
  depot,
  depots,
  disabled,
  saveDeviceDepotToLocalStorage,
  addNotification,
}) => {
  const [showKeyPad, setShowKeyPad] = React.useState(true);

  const toggleSliderAndShowPinInput = () => {
    setShowKeyPad(true);
    toggleSlider();
  };

  return depot ? (
    <>
      <DepotButton
        depotDescription={depot.description}
        disabled={disabled}
        onBeginChangeDepot={toggleSliderAndShowPinInput}
      />
      <SlideInScreen
        className={styles.depotSlider}
        click={toggleSliderAndShowPinInput}
        show={isOpen}
        isRightAligned
        width={50}>
        <div className={styles.main}>
          {showKeyPad && (
            <DepotPinForm
              onSubmit={pin => {
                if (pin === CHANGE_DEPOT_PIN) {
                  setShowKeyPad(false);
                } else {
                  addNotification(`Invalid depot pin`, {
                    type: NotificationType.warn,
                  });
                }
              }}
              onCancel={toggleSliderAndShowPinInput}
            />
          )}
          {!showKeyPad && (
            <DepotSelectForm
              currentDepotId={depot.id}
              depots={depots}
              onSubmit={depotId => {
                saveDeviceDepotToLocalStorage(depotId);
                toggleSliderAndShowPinInput();
                location.reload();
              }}
              onCancel={toggleSliderAndShowPinInput}
            />
          )}
        </div>
      </SlideInScreen>
    </>
  ) : (
    <div />
  );
};

export default DepotSelector;
