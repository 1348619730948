import memoizeOne from 'memoize-one';
import shallowEqual from 'shallowequal';
import deepEqual from './deepEqual';

export default memoizeOne;

function isShallowEqual(newItem: unknown, oldItem: unknown) {
  return shallowEqual(newItem, oldItem);
}

function isDeepEqual(newItem: unknown, oldItem: unknown) {
  return deepEqual(newItem, oldItem);
}

export function getShallowMemoizedObjectFunc<T extends {}>(): (obj: T) => T {
  return memoizeOne(o => o, isShallowEqual);
}

// tslint:disable-next-line: no-any
export function shallowMemoize<T extends (...args: any[]) => unknown>(func: T): T {
  // @ts-ignore
  return memoizeOne(func, isShallowEqual);
}

// tslint:disable-next-line: no-any
export function deepMemoize<T extends (...args: any[]) => unknown>(func: T): T {
  // @ts-ignore
  return memoizeOne(func, isDeepEqual);
}
