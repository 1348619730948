import { IRootStoreModel } from 'src/domain/entities/RootStoreModel';
import { UiRoute } from 'src/domain/services/buildUiRoutes';
import { UiRouteName } from 'src/domain/baseTypes';

export default function buildUiRoutes(store: IRootStoreModel) {
  const railBookingCreate = new UiRoute(
    UiRouteName.operationsRailBookingCreate,
    '/operations/rail/rail-bookings/create',
    () => 'Create Rail Booking',
    true
  );

  const railBookingManage = new UiRoute(
    UiRouteName.operationsRailBookingManage,
    '/operations/rail/rail-bookings/:id',
    () => 'Manage Rail Booking',
    true
  );

  const railBookingList = new UiRoute(
    UiRouteName.operationsRailBookingList,
    '/operations/rail/rail-bookings',
    () => 'Rail Bookings',
    false,
    false,
    [railBookingCreate, railBookingManage]
  );

  return {
    [UiRouteName.operationsRailBookingList]: railBookingList,
    [UiRouteName.operationsRailBookingCreate]: railBookingCreate,
    [UiRouteName.operationsRailBookingManage]: railBookingManage,
  };
}
