import Card from './Card';
import styles from './Card.module.scss';
import { Link } from 'react-router-dom';
import { MouseEventHandler } from 'react';

interface ILinkedCardProps {
  className?: string;
  to: string;
  onClick?: MouseEventHandler<HTMLAnchorElement>;
}

const LinkedCard: React.FC<ILinkedCardProps> = ({ children, className, to, onClick }) => (
  <Link to={to} onClick={onClick}>
    <Card className={className}>
      <div className={styles.linkedCard}>{children}</div>
    </Card>
  </Link>
);

export default LinkedCard;
