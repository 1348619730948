import { types } from 'mobx-state-tree';
import { DateTime } from 'luxon';
import { getAjax, getBus, NotificationType } from 'src/domain/services';
import { complianceUrls } from 'src/domain/services/apiUrls';
import { Observable, Subject } from 'rxjs';
import deepEqual from 'deep-equal';

type GetFatigueDetailsQueryResponse = Operations.Domain.Queries.GetFatigueDetails.GetFatigueDetailsQueryResponse;
type GetFatigueDetailsQuery = Operations.Domain.Queries.GetFatigueDetails.GetFatigueDetailsQuery;

export const FatigueDetailsModel = types
  .model('FatigueDetailsModel', {
    selectedDate: types.maybe(types.frozen<DateTime>()),
    staffMember: types.maybe(types.string),
    details: types.maybe(types.frozen<GetFatigueDetailsQueryResponse>()),
  })
  .volatile(self => ({
    isLoadingIds: [] as symbol[],
  }))
  .views(self => ({
    get isDataLoading() {
      return !!self.isLoadingIds.length;
    },
    get isFutureDateSelected() {
      return self.selectedDate && self.selectedDate > DateTime.now();
    },
  }))
  .actions(self => {
    function _setDetails(fatigueItem: GetFatigueDetailsQueryResponse | undefined) {
      self.details = fatigueItem;
    }
    return { _setDetails };
  })
  .actions(self => {
    const _addLoadId = (loadId: symbol) => {
      self.isLoadingIds = [...self.isLoadingIds, loadId];
    };

    const _clearLoadId = (loadId: symbol) => {
      self.isLoadingIds = self.isLoadingIds.filter(id => id !== loadId);
    };

    return { _addLoadId, _clearLoadId };
  })
  .actions(self => {
    const setSelectedDate = (day: DateTime | undefined) => {
      if (self.selectedDate?.toISODate() !== day?.toISODate()) self.selectedDate = day;
    };

    const setStaffMember = (staffMemberId: string | undefined) => {
      if (self.staffMember !== staffMemberId) {
        self.staffMember = staffMemberId;
      }
    };

    return { setSelectedDate, setStaffMember };
  })
  .actions(self => {
    const ajax = getAjax(self);
    const bus = getBus(self);
    const querySubject = new Subject<Partial<GetFatigueDetailsQuery>>();

    const loadFatigueDetails = (
      query: Partial<GetFatigueDetailsQuery>
    ): Observable<GetFatigueDetailsQueryResponse> => {
      const loadId = Symbol();
      self._addLoadId(loadId);
      return ajax.raw
        .get(complianceUrls.fatigueUrls.getFatigueDetails(query))
        .map(r => r.response as GetFatigueDetailsQueryResponse)
        .catch(e => {
          bus.showNotification({
            message: `The retrieval of fatigue details for ${query.day} failed`,
            options: { type: NotificationType.error },
          });
          return Observable.empty<GetFatigueDetailsQueryResponse>();
        })
        .finally(() => {
          self._clearLoadId(loadId);
        });
    };

    const subscription = querySubject
      .distinctUntilChanged<Partial<GetFatigueDetailsQuery>>(deepEqual)
      // Use switchMap to cancel/ignore any in-progress requests and to clear the caches when the
      // filter changes, as they're no longer relevant
      .switchMap(loadFatigueDetails)
      .subscribe(self._setDetails);

    const beforeDestroy = () => {
      subscription.unsubscribe();
    };

    const loadFatigue = (query: GetFatigueDetailsQuery) => {
      querySubject.next(query);
    };

    return {
      beforeDestroy,
      loadFatigue,
    };
  });
