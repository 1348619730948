import { buildListPageApiSearchModelTypedQuery } from 'src/domain/modelBuilders/buildListPageApiSearchModel';
import { flow, getRoot } from 'mobx-state-tree';
import { NotificationType } from 'src/domain';
import { getAjax } from 'src/domain/services';
import { IRootStoreModel } from 'src/domain/entities/RootStoreModel';

type ListTasksQuery = Workshop.Domain.Queries.JobTask.ListJobTasks.ListJobTasksQuery;

export const ListTasksModel = buildListPageApiSearchModelTypedQuery<
  ListTasksQuery
>()('ListTasksModel', d => d.ajax.jobTask.listTasks(d.query)).actions(self => {
  const ajax = getAjax(self);
  const root = getRoot(self) as IRootStoreModel;

  const exportToExcel = flow(function*(query: Partial<ListTasksQuery>) {
    root.notifications.addNotification(`The file is being generated ...`, {
      type: NotificationType.info,
    });

    return yield ajax.jobTask.exportTasksToExcel(query);
  });

  return { exportToExcel };
});
