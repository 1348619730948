import { DateTime } from 'luxon';
import { toJS } from 'mobx';
import { observer } from 'mobx-react';
import { memo, useEffect, useRef, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Subject } from 'rxjs';
import {
  ConflictCriteria,
  JobWarningType,
  allConflictCriteria,
  allJobStatus,
  jobWarningTypeDescription,
} from 'src/api/enums';
import { TIMEZONE } from 'src/appSettings';
import { ListPageLoadCause } from 'src/domain';
import { useRootStore } from 'src/domain/entities/RootStoreModel';
import {
  IAllocationsBoundedItem,
  isMarkOutOfServiceItem,
  isOperationsJob,
  isPeopleLeave,
  isWorkshopJob,
  isWorkshopShift,
} from 'src/domain/entities/operations/job/ListJobsForAllocationsModel';
import Omit from 'src/infrastructure/omit';
import Page from 'src/views/components/Page/Page';
import {
  ActionType,
  FieldDefs,
  FieldType,
  IPageDef,
  PagePrimarySize,
  PaneType,
  ShellModalSize,
} from 'src/views/definitionBuilders/types';
import withQueryParams, { IQueryParamsProps } from 'src/views/hocs/withQueryParams';
import withRouteProps from 'src/views/hocs/withRouteProps';
import { IViewGroupFilterValues } from 'src/views/routes/operations/job/allocations/ganttCalendar/GanttCalendar';
import {
  IGanttCalendarDayItem,
  IGanttCalendarGroupItem,
  IGanttView,
} from 'src/views/routes/operations/job/allocations/ganttCalendar/baseTypes';
import { getStaffAndVehiclePillFilterDefs } from 'src/views/routes/operations/job/allocations/ganttCalendar/staffAndVehiclePillFilterDefs';
import { SortedJobTypes } from 'src/views/routes/operations/shared/jobTypeHelpers';
import './Allocations.scss';
import { getItemUiKey, renderItem } from './AllocationsBoundedItemHelper';
import MarkOutServiceJobPopup from './MarkOutOfServiceJobPopup';
import PeopleLeavePopup from './PeopleLeavePopup';
import WorkshopJobPopup from './WorkshopJobPopup';
import WorkshopShiftPopup from './WorkshopShiftPopup';
import GanttCalendar from './ganttCalendar';
import { AllocationJobPopup } from './ganttCalendar/AllocationJobPopup/AllocationJobPopup';
import { getStaffView, getVehicleView } from './views';
import { getWeekStart } from 'src/domain/dateHelper';

const showInZone = TIMEZONE;
type JobWarning = Operations.Domain.Queries.GetJobWarnings.JobWarning;
type PaidHoursInPayPeriod = Operations.Domain.Queries.GetPaidHoursInPayPeriodForDay.PaidHoursInPayPeriod;
type QueryParams = Omit<
  Operations.Domain.Queries.ListJobsForAllocations.ListJobsForAllocationsQuery,
  'jobsFrom' | 'jobsTo'
> & { day: string; view: string };

type InternalProps = RouteComponentProps<{}> & {
  getQueryParams: IQueryParamsProps<QueryParams>['getQueryParams'];
};

const Allocations: React.FC<InternalProps> = observer((props: InternalProps) => {
  let _scrollBuffer = new Subject<any>();
  let _scrollDebounceTimeInMs = 500;
  const prevLocation = useRef(props.location);

  const rootStore = useRootStore();
  const listModel = rootStore.operations.job.listForAllocations;
  const urbanShiftsModel = rootStore.operations.urban.shifts;
  const assetModel = rootStore.asset;
  const assetsModel = rootStore.assets;
  const staffMembersModel = rootStore.people.staffMembers;
  const vehicleTypesModel = rootStore.operations.vehicleTypes;
  const subcontractorsModel = rootStore.operations.subcontractors;
  const techSpecsModel = rootStore.workshop.techSpecs;
  const skillSpecsModel = rootStore.people.skillSpecs;
  const exclusionModel = rootStore.operations.exclusionModel;

  const canManageJobs = rootStore.account.isOperationsDepartmentMember;
  const jobs = listModel.jobs.slice();
  const workshopItems = listModel.workshopItems.slice();
  const markOutOfServiceItems = listModel.MarkOutOfServiceJobItems.slice();
  const leaveItems = listModel.leaveItems.slice();
  const workshopShiftItems = listModel.workshopShiftItems.slice();
  const warningsByDay = toJS(listModel.warningsByDay);
  const paidHoursByPayPeriod = toJS(listModel.paidHoursByPayPeriod);
  const unfilteredItems = listModel.unfilteredItems.slice();
  const shifts = urbanShiftsModel.shifts.slice();
  const staff = staffMembersModel.allStaffMembers.slice();
  const vehicles = assetsModel.fleetAssetListItems.slice();
  const assetSubcategories = assetModel.fleetSubcategories.slice();
  const assetHousingLocations = rootStore.asset.housedAtLocations.slice();
  const techSpecs = techSpecsModel.forRequirements.items.slice();
  const skillSpecs = skillSpecsModel.forRequirements.items.slice();
  const subcontractors = subcontractorsModel.allSubcontractors.slice();
  const isDataLoading = listModel.isDataLoading;
  const onLoadItems = listModel.loadItems;
  const onListShifts = urbanShiftsModel.listShifts;
  const onLoadStaff = staffMembersModel.loadAllStaffMembers;
  const onLoadVehicles = assetsModel.loadFleetAssets;
  const loadExclusions = exclusionModel.loadExclusions;
  const exclusions = exclusionModel.exclusions.slice();
  const loadSubcategories = assetModel.loadAssetSubcategories;
  const loadAssetHousedAtLocations = rootStore.asset.loadAssetHousedAtLocations;
  const loadTechSpecs = techSpecsModel.forRequirements.getAll;
  const loadSkillSpecs = skillSpecsModel.forRequirements.getAll;
  const loadSubcontractors = subcontractorsModel.loadAllSubcontractors;
  const onAllocateStaffMember = listModel.allocateStaffMember;
  const onAllocateAsset = listModel.allocateAsset;
  const onAllocateSubcontractor = listModel.allocateSubcontractor;
  const onLoadUnfilteredItems = listModel.loadUnfilteredItems;
  const vehicleTypes = vehicleTypesModel.vehicleTypes.slice();
  const departingFromDepotId = rootStore.operationsStartup.operationsDepots.slice();
  const loadVehicleTypes = vehicleTypesModel.loadVehicleTypes;
  const listRailBookings = rootStore.operations.rail.railBookings.listItems;
  const railBookings = rootStore.operations.rail.railBookings.items.slice();
  const searchBookings = rootStore.operations.sales.quotes.bookingsList.searchBookings;
  const findQuotes = rootStore.operations.sales.quotes.bookingsList.findQuotes;
  const searchTechSpecValues = assetsModel.searchTechSpecValues;
  const getTechSpecDropdownsOptions = techSpecsModel.getTechSpecDropdownsOptions;
  const techSpecDropdownOptions = techSpecsModel.techSpecDropdownOptions;
  const onSwapVehicle = rootStore.operations.job.item.swapVehicle;
  const jobProgress = rootStore.operations.job.item.progress;
  const loadJobProgress = rootStore.operations.job.item.loadJobProgress;
  const allStaffDepots = rootStore.people.depots.staffDepots.slice();
  const loadStaffDepots = rootStore.people.depots.loadStaffDepots;
  const employmentStatuses = rootStore.people.employmentStatuses.employmentStatuses.slice();
  const roles = rootStore.people.roles.items.slice();
  const loadRoles = rootStore.people.roles.listItems;
  const loadEmploymentStatuses = rootStore.people.employmentStatuses.loadEmploymentStatuses;
  const loadAllLicenceTypes = rootStore.people.licences.loadAllLicenceTypes;
  const allLicenceTypes = rootStore.people.licences.allLicenceTypes.slice();
  const defaultRolesForDriverAllocations = rootStore.operationsStartup.defaultRolesForDriverAllocations.slice();
  //const loadStartUpData = rootStore.operationsStartup.loadStartUpData;
  const setViewFilters = rootStore.operations.allocations.setViewFilter;
  const viewFilters = rootStore.operations.allocations.viewFilters;

  const [, setIsUserInteracting] = useState<boolean>(false);
  const [isScrolling, setIsScrolling] = useState<boolean>(false);
  const [dataRequestedDuringScroll, setDataRequestedDuringScroll] = useState<boolean>(false);
  const [viewNewFilters, setViewNewFilters] = useState<{
    vehicle: {
      filters: {};
      search: string;
    };
    staffMember: {
      filters: {};
      search: string;
    };
  }>({
    vehicle: { filters: {}, search: '' },
    staffMember: {
      filters: {},
      search: '',
    },
  });

  useEffect(() => {
    setViewFilters({
      vehicle: { search: '', filters: {} },
      staffMember: {
        search: '',
        filters: { roles: defaultRolesForDriverAllocations.map(d => d.id) },
      },
    });

    // Add a buffer to scrolling i.e. when holding right arrow to scroll sideways, don't continuously try to load all that data.
    _scrollBuffer.debounceTime(_scrollDebounceTimeInMs).subscribe(() => {
      // Remember from before that we wanted data. Load it now (dates come from route params, no other data required.)
      if (dataRequestedDuringScroll) {
        loadData(false);
      }
      setIsScrolling(false);
    });

    loadData(true);
    loadEmploymentStatuses();
    onListShifts();
    onLoadStaff();
    onLoadVehicles();
    loadSubcategories();
    loadSubcontractors();
    loadAssetHousedAtLocations();
    loadTechSpecs();
    loadSkillSpecs();
    loadVehicleTypes();
    listRailBookings({ loadCause: ListPageLoadCause.mount });
    loadExclusions();
    getTechSpecDropdownsOptions({ forOperations: true });
    loadStaffDepots();
    loadAllLicenceTypes();
    loadRoles({
      loadCause: ListPageLoadCause.mount,
      query: { size: 9999 },
    });
  }, []);

  useEffect(() => {
    if (props.location.search !== prevLocation.current.search) {
      // If we're scrolling, we don't want to load data, so remember that we wanted data.
      if (isScrolling) {
        setDataRequestedDuringScroll(true);
      } else {
        loadData(false);
      }
    }
  }, [props.location]);

  // Called loads during scrolling
  // Essentially, if scrolling just note that we're still scrolling (other logic elsewhere to deal with this)
  const handleScroll = (e: React.UIEvent<HTMLElement | UIEvent>) => {
    if (!isScrolling) {
      setIsScrolling(true);
    }

    _scrollBuffer.next(e);
  };

  const handleFilteringChange = (change: Partial<IViewGroupFilterValues>) => {
    const view = props.getQueryParams().view;
    if (view) {
      const newFiltering = {
        ...viewNewFilters,
        [view]: {
          filters: viewFilters ? viewFilters[view].filters : {},
          search: viewFilters ? viewFilters[view].search : '',
          ...change,
        },
      };
      setViewFilters(newFiltering);
      setViewNewFilters(newFiltering);
    }
  };

  const getFilterFieldDefs = (): { [key: string]: FieldDefs & { dataAddr: string } } => {
    return {
      shiftNames: {
        fieldType: FieldType.selectMultiField,
        dataAddr: 'shiftNames',
        label: 'Shift',
        valueKey: 'shiftName',
        descriptionKey: 'searchText',
        optionItems: shifts,
        useValueOnly: true,
      },
      jobTypeId: {
        fieldType: FieldType.selectMultiField,
        label: 'Job Type',
        dataAddr: 'jobTypeIds',
        valueKey: 'value',
        descriptionKey: 'description',
        useValueOnly: true,
        optionItems: SortedJobTypes,
      },
      quotes: {
        fieldType: FieldType.selectAsyncMultiField,
        dataAddr: 'quotes',
        label: 'Charter Booking',
        valueKey: 'id',
        descriptionKey: 'quoteNumber',
        useValueOnly: true,
        loadOptionItems: searchBookings,
        loadItems: findQuotes,
        optionRenderer: d => (
          <span>
            {d.quoteNumber} - {d.description}
          </span>
        ),
        useOptionRendererAsValueRenderer: true,
      },
      railBookings: {
        fieldType: FieldType.selectMultiField,
        label: 'Rail Booking',
        dataAddr: 'railBookings',
        valueKey: 'railBookingId',
        descriptionKey: 'railBookingNumber',
        useOptionRendererAsValueRenderer: true,
        optionRenderer: d => (
          <span>
            {d.railBookingNumber} - {d.description}
          </span>
        ),
        optionItems: railBookings,
        useValueOnly: true,
      },
      hasSubcontractor: {
        fieldType: FieldType.yesNoField,
        dataAddr: 'hasSubcontractor',
        label: 'Subcontractor Allocated',
      },
      subcontractorIds: {
        fieldType: FieldType.selectMultiField,
        dataAddr: 'subcontractorIds',
        label: 'Subcontractor',
        valueKey: 'id',
        descriptionKey: 'name',
        optionItems: subcontractors,
        useValueOnly: true,
      },
      jobStatus: {
        fieldType: FieldType.selectMultiField,
        dataAddr: 'jobStatusIds',
        label: 'Job Status',
        valueKey: 'value',
        descriptionKey: 'description',
        optionItems: allJobStatus,
        useValueOnly: true,
      },
      conflicts: {
        fieldType: FieldType.selectMultiField,
        label: 'Conflict',
        dataAddr: 'conflicts',
        valueKey: 'value',
        descriptionKey: 'description',
        optionItems: allConflictCriteria,
        useValueOnly: true,
      },
      isCancelled: {
        fieldType: FieldType.yesNoField,
        dataAddr: 'isCancelled',
        label: 'Cancelled',
      },
      isTrainingJob: {
        fieldType: FieldType.yesNoField,
        dataAddr: 'IsTrainingJob',
        label: 'Training',
      },
      hasStaffMember: {
        fieldType: FieldType.yesNoField,
        dataAddr: 'hasStaffMember',
        label: 'All Staff Members Allocated',
      },
      hasVehicle: {
        fieldType: FieldType.yesNoField,
        dataAddr: 'hasVehicle',
        label: 'Vehicle Allocated',
      },
      vehicleTypes: {
        fieldType: FieldType.selectMultiField,
        dataAddr: 'vehicleTypes',
        label: 'Quoted Vehicle Type',
        valueKey: 'id',
        descriptionKey: 'description',
        optionItems: vehicleTypes,
        useValueOnly: true,
      },
      departingFromDepotId: {
        fieldType: FieldType.selectMultiField,
        dataAddr: 'departingFromDepotId',
        label: 'Departing From Depot',
        valueKey: 'id',
        descriptionKey: 'description',
        optionItems: departingFromDepotId,
        useValueOnly: true,
      },
    };
  };

  const filterUngroupedView = (item: IAllocationsBoundedItem) => {
    return isOperationsJob(item);
  };

  const getWarningFilter = (
    warning: JobWarning,
    jobTypeDataAddr: string,
    hasStaffMemberDataAddr: string,
    hasVehicleDataAddr: string,
    hasSubcontractorDataAddr: string,
    conflictsDataAddr: string
  ) => {
    return {
      [jobTypeDataAddr]: [warning.jobType.id],
      [hasStaffMemberDataAddr]:
        warning.warningType === JobWarningType.NoStaffMember ? false : undefined,
      [hasVehicleDataAddr]: warning.warningType === JobWarningType.NoVehicle ? false : undefined,
      [hasSubcontractorDataAddr]:
        warning.warningType === JobWarningType.NoSubcontractor ? false : undefined,
      [conflictsDataAddr]:
        warning.warningType === JobWarningType.ConflictedStaffMember
          ? ConflictCriteria.UnacceptedStaffMember
          : warning.warningType === JobWarningType.ConflictedVehicle
          ? ConflictCriteria.UnacceptedVehicle
          : undefined,
    };
  };

  const renderItemPopup = (item: IAllocationsBoundedItem) => {
    setIsUserInteracting(true);
    if (isOperationsJob(item)) {
      const params = props.getQueryParams();
      const paidHours = getPaidHours(
        params,
        paidHoursByPayPeriod as Map<string, PaidHoursInPayPeriod[]>
      );

      return (
        <AllocationJobPopup
          canManageJobs={canManageJobs}
          job={item}
          showInZone={showInZone}
          unfilteredItems={unfilteredItems}
          staff={staff}
          paidHours={paidHours}
          vehicles={vehicles}
          allStaffDepots={allStaffDepots}
          assetSubcategories={assetSubcategories}
          vehicleTypes={vehicleTypes}
          onAllocateStaffMember={onAllocateStaffMember}
          onAllocateAsset={onAllocateAsset}
          onLoadUnfilteredItems={onLoadUnfilteredItems}
          subcontractors={subcontractors}
          exclusions={exclusions}
          assetHousingLocations={assetHousingLocations}
          techSpecs={techSpecs}
          skillSpecs={skillSpecs}
          employmentStatuses={employmentStatuses}
          roles={roles}
          searchTechSpecValues={searchTechSpecValues}
          onAllocateSubcontractor={onAllocateSubcontractor}
          techSpecDropdownOptions={techSpecDropdownOptions}
          onSwapVehicle={command => onSwapVehicle(command).then(() => loadData(true))}
          loadJobProgress={loadJobProgress}
          jobProgresses={
            jobProgress && jobProgress.jobId === item.id ? jobProgress.progress : undefined
          }
          allLicenceTypes={allLicenceTypes}
          defaultRolesForDriverAllocations={defaultRolesForDriverAllocations}
          viewFilters={viewFilters}
          setViewFilters={setViewFilters}
        />
      );
    }
    if (isWorkshopJob(item)) {
      return <WorkshopJobPopup job={item} />;
    }
    if (isPeopleLeave(item)) {
      return <PeopleLeavePopup leave={item} />;
    }
    if (isMarkOutOfServiceItem(item)) {
      return <MarkOutServiceJobPopup markOutOfServiceJob={item} />;
    }
    if (isWorkshopShift(item)) {
      return <WorkshopShiftPopup shift={item} />;
    }
    setIsUserInteracting(false);
    return null;
  };

  const loadData = (clearLoadedJobs: boolean) => {
    const params = props.getQueryParams();
    const { day, view, ...filter } = params;

    if (!day) {
      return;
    }

    const currentDay = DateTime.fromISO(day, { zone: showInZone });
    onLoadItems(currentDay, 1, filter, clearLoadedJobs);

    setDataRequestedDuringScroll(false);
  };

  const getPaidHours = (
    params: Partial<QueryParams>,
    paidHoursByPayPeriod: Map<string, PaidHoursInPayPeriod[]>
  ) => {
    return (
      (params.day &&
        paidHoursByPayPeriod.get(getWeekStart(DateTime.fromISO(params.day)).toISODate())) ||
      []
    ).slice();
  };

  const getPageDef = (): IPageDef => {
    const filterFieldDefsLookup = getFilterFieldDefs();
    const params = props.getQueryParams();

    const allItems = [
      ...jobs,
      ...workshopItems,
      ...leaveItems,
      ...markOutOfServiceItems,
      ...workshopShiftItems,
    ];

    const warnings = (params.day && warningsByDay.get(params.day)) || [];
    const paidHours = getPaidHours(
      params,
      paidHoursByPayPeriod as Map<string, PaidHoursInPayPeriod[]>
    );
    const jobTypeDataAddr = filterFieldDefsLookup.jobTypeId.dataAddr;
    const hasStaffMemberDataAddr = filterFieldDefsLookup.hasStaffMember.dataAddr;
    const hasVehicleDataAddr = filterFieldDefsLookup.hasVehicle.dataAddr;
    const hasSubcontractorDataAddr = filterFieldDefsLookup.hasSubcontractor.dataAddr;
    const conflictsDataAddr = filterFieldDefsLookup.conflicts.dataAddr;

    const filtersForPills: FieldDefs[] = getStaffAndVehiclePillFilterDefs(
      assetSubcategories,
      assetHousingLocations,
      techSpecs,
      vehicleTypes,
      searchTechSpecValues,
      techSpecDropdownOptions,
      allStaffDepots,
      employmentStatuses,
      roles,
      allLicenceTypes,
      skillSpecs
    );

    return {
      primarySize: PagePrimarySize.full,
      primarySection: {
        title: 'Job Allocations',
        subtitle: params.day && DateTime.fromISO(params.day).toLocaleString(DateTime.DATE_HUGE),
        panels: [
          {
            panes: [
              {
                paneType: PaneType.customPane,
                render: () => (
                  <GanttCalendar
                    className="allocations-component"
                    hideDayTitle
                    showInZone={showInZone}
                    items={allItems}
                    minRows={10}
                    rowHeightPx={40}
                    dayWidthPx={1500}
                    minZoomFactor={1}
                    maxZoomFactor={8}
                    renderItem={renderItem}
                    renderItemPopup={renderItemPopup}
                    onClosePopup={() => setIsUserInteracting(false)}
                    getItemUiKey={getItemUiKey}
                    storeStateInUrl
                    filterItemsForUngroupedView={filterUngroupedView}
                    disableDayControls={isDataLoading}
                    ganttViews={{
                      staffMember: (getStaffView(
                        staff.filter(d => d.active),
                        allStaffDepots,
                        subcontractors,
                        paidHours,
                        skillSpecs,
                        employmentStatuses,
                        roles,
                        allLicenceTypes,
                        undefined,
                        undefined,
                        defaultRolesForDriverAllocations.map(o => o.id)
                      ) as unknown) as IGanttView<IGanttCalendarDayItem, IGanttCalendarGroupItem>,
                      vehicle: (getVehicleView(
                        vehicles,
                        subcontractors,
                        assetSubcategories,
                        assetHousingLocations,
                        techSpecs,
                        vehicleTypes,
                        searchTechSpecValues,
                        techSpecDropdownOptions
                      ) as unknown) as IGanttView<IGanttCalendarDayItem, IGanttCalendarGroupItem>,
                    }}
                    filterDefsForSubFilterPills={filtersForPills}
                    pillContainerId={'main-allocations-subfilter-pills'}
                    viewFilters={viewFilters}
                    handleFilteringChange={handleFilteringChange}
                  />
                ),
              },
            ],
          },
        ],
        secondaryActions: [
          {
            actions: [
              {
                actionType: ActionType.filterActionButton,
                filterSize: ShellModalSize.oneQuarter,
                filterFields: Object.keys(filterFieldDefsLookup).map(k => filterFieldDefsLookup[k]),
                onOpenModal: () => setIsUserInteracting(true),
                onCloseModal: () => setIsUserInteracting(false),
              },
            ],
          },
        ],
        tertiaryActions: [
          {
            actions: [
              {
                actionType: ActionType.presetFilterCollection,
                filterDataAddrs: Object.keys(filterFieldDefsLookup).map(
                  k => filterFieldDefsLookup[k].dataAddr
                ),
                presetFilters: warnings.map(w => ({
                  label: `${w.jobType.description} ${jobWarningTypeDescription(w.warningType)}`,
                  count: w.jobCount,
                  filter: getWarningFilter(
                    w,
                    jobTypeDataAddr,
                    hasStaffMemberDataAddr,
                    hasVehicleDataAddr,
                    hasSubcontractorDataAddr,
                    conflictsDataAddr
                  ),
                })),
              },
            ],
          },
        ],
      },
    };
  };

  return (
    <Page onScroll={handleScroll} def={getPageDef()} showPrimarySectionSpinner={isDataLoading} />
  );
});

export default withRouteProps(withQueryParams(memo(Allocations)));
