import inject from 'src/views/injectFromStore';
import ListVehicleType, { IListVehicleTypeProps } from './ListVehicleType';

const Container = inject<IListVehicleTypeProps>((allStores, p) => ({
  canManageVehicleTypes: allStores.rootStore.account.isSalesDepartmentMember,
  loadVehicleTypes: allStores.rootStore.operations.vehicleTypes.loadVehicleTypes,
  vehicleTypes: allStores.rootStore.operations.vehicleTypes.vehicleTypes.slice(),
}))(ListVehicleType);

export default Container;
