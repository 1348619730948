import { types, getRoot, flow } from 'mobx-state-tree';
import { buildListPageApiSearchModelTypedQuery } from 'src/domain/modelBuilders/buildListPageApiSearchModel';
import { getAjax, NotificationType } from 'src/domain/services';
import { IRootStoreModel } from 'src/domain/entities/RootStoreModel';

type GetPurchaseTransactionsListQuery = Workshop.Domain.Queries.Purchasing.GetPurchaseTransactionsList.GetPurchaseTransactionsListQuery;
type ExportPurchaseTransactionsToExcelQuery = Workshop.Domain.Queries.Purchasing.ExportPurchaseTransactions.ExportPurchaseTransactionsToExcelQuery;

const purchaseTransactionsListModel = buildListPageApiSearchModelTypedQuery<
  GetPurchaseTransactionsListQuery
>()('PurchaseTransactionsListModel', d =>
  d.ajax.workshop.purchaseOrders.listPurchaseTransactions(d.query)
);

const purchaseTransactionsReportModel = types
  .model('PurchaseTransactionsReportModel', {})
  .actions(self => {
    const ajax = getAjax(self);
    const root = getRoot(self) as IRootStoreModel;

    const exportPurchaseTransactionsToExcel: (
      query: Partial<ExportPurchaseTransactionsToExcelQuery>
    ) => Promise<Blob> = flow(function*(query) {
      root.notifications.addNotification(`The file is being generated ...`, {
        type: NotificationType.info,
      });

      return yield ajax.workshop.purchaseOrders.exportPurchaseTransactionsToExcel(query);
    });

    return {
      exportPurchaseTransactionsToExcel,
    };
  });

export const ListPurchaseTransactionsModel = types.compose(
  purchaseTransactionsListModel,
  purchaseTransactionsReportModel
);
