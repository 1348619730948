import inject from 'src/views/injectFromStore';
import Omit from 'src/infrastructure/omit';
import MaintainCodeOfConduct, {
  IMaintainCodeOfConductProps,
} from 'src/views/routes/compliance/codeOfConduct/maintainCodeOfConduct/MaintainCodeOfConduct';

const Container = inject<Omit<IMaintainCodeOfConductProps, 'mode'>, IMaintainCodeOfConductProps>(
  (allStores, p) => ({
    canManageCodeOfConduct: allStores.rootStore.account.isOperationsDepartmentMember,
    codeOfConduct: Object.assign(
      {},
      allStores.rootStore.compliance.codeOfConduct.item.codeOfConduct
    ),
    loadCodeOfConduct: allStores.rootStore.compliance.codeOfConduct.item.loadCodeOfConduct,
    updateCodeOfConduct: allStores.rootStore.compliance.codeOfConduct.item.updateCodeOfConduct,
    createCodeOfConduct: allStores.rootStore.compliance.codeOfConduct.item.createCodeOfConduct,
    loadStaffMembers: allStores.rootStore.people.staffMembers.loadAllStaffMembers,
    assets: allStores.rootStore.assets.fleetAssetListItems.slice(),
    loadAssetListItems: allStores.rootStore.assets.loadFleetAssets,
  })
)(MaintainCodeOfConduct);

export default Container;
