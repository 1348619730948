import { types, flow, applySnapshot } from 'mobx-state-tree';
import { getAjax } from 'src/domain/services/';
import { authUrls } from 'src/domain/services/apiUrls';
import { DateTime } from 'luxon';
import logger from 'src/infrastructure/logging';
import { appInsights } from 'src/appInsights';

type CopClaimsUser = Common.Authentication.CopClaimsUser;

export const AccountModel = types
  .model('AccountModel', {
    isAuthenticated: types.maybe(types.boolean),
    email: types.maybe(types.string),
    name: types.maybe(types.string),
    initials: types.maybe(types.string),
    whenSignedInUtc: types.maybe(types.frozen<DateTime>()),
    id: types.maybe(types.string),
    isOperationsDepartmentMember: types.optional(types.boolean, false),
    isRailDepartmentMember: types.optional(types.boolean, false),
    isPeopleDepartmentMember: types.optional(types.boolean, false),
    isSalesDepartmentMember: types.optional(types.boolean, false),
    isTimesheetsDepartmentMember: types.optional(types.boolean, false),
    isWorkshopDepartmentMember: types.optional(types.boolean, false),
    isAccountingDepartmentMember: types.optional(types.boolean, false),
    isReadonlyUser: types.optional(types.boolean, false),
    isAdminUser: types.optional(types.boolean, false),
  })
  .views(self => ({
    get checkedName() {
      return self.name || 'Guest';
    },
    get checkedInitials() {
      return self.initials || '';
    },
    get canAdministerExternalIdentities() {
      return !!self.isPeopleDepartmentMember;
    },
    get canCreateOrUpdateRecords() {
      return (
        self.isAccountingDepartmentMember ||
        self.isOperationsDepartmentMember ||
        self.isPeopleDepartmentMember ||
        self.isRailDepartmentMember ||
        self.isSalesDepartmentMember ||
        self.isTimesheetsDepartmentMember ||
        self.isWorkshopDepartmentMember
      );
    },
  }))
  .actions(self => {
    const ajax = getAjax(self);
    const log = logger;

    const signOut = () => {
      // Redirect the browser to sign-out via the auth flow
      window.location.assign(authUrls.signOutUrl());
    };

    const signIn = () => {
      const { pathname, search } = window.location;
      const returnUrl = pathname + search;
      // Redirect the browser to sign-in via the auth flow
      window.location.assign(authUrls.signInUrl(returnUrl));
    };

    const loadProfile = flow(function*() {
      try {
        const user: CopClaimsUser = yield ajax.user.getProfile();

        applySnapshot(self, {
          ...user,
          isAuthenticated: true,
          whenSignedInUtc: DateTime.utc(),
        });
        appInsights.context.user.id = user.name;
        appInsights.context.user.authenticatedId = user.name;
        appInsights.context.user.accountId = user.email;
        appInsights.context.user.localId = user.email;
      } catch (e) {
        if (e.status && e.status === 401) {
          signIn();
          return;
        }

        log.error(e as Error);
        self.isAuthenticated = false;
        throw e;
      }
    });

    return { loadProfile, signIn, signOut };
  });

type IAccountModelType = typeof AccountModel.Type;
export interface IAccountModel extends IAccountModelType {}
