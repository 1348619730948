import { useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { PagePrimarySize, PaneType, FieldType } from 'src/views/definitionBuilders/types';
import CrudPage, { CrudPageMode, ICrudPageDef } from 'src/views/components/Page/pages/CrudPage';
import { observer } from 'mobx-react';
import { useRootStore } from 'src/domain/entities/RootStoreModel';

type TaskSubcategoryItem = Workshop.Domain.Queries.JobTaskSubcategories.JobTaskSubcategoryItem;
type CreateTaskSubcategoryCommand = Workshop.Domain.Commands.JobTask.CreateTaskSubcategory.CreateTaskSubcategoryCommand;
type UpdateTaskSubcategoryCommand = Workshop.Domain.Commands.JobTask.UpdateTaskSubcategory.UpdateTaskSubcategoryCommand;

export interface IMaintainTaskSubcategoryProps {
  mode: CrudPageMode;
  route: RouteComponentProps<{ [x: string]: string | undefined }>;
}

const MaintainTaskSubcategory: React.FC<IMaintainTaskSubcategoryProps> = observer(
  (props: IMaintainTaskSubcategoryProps) => {
    const rootStore = useRootStore();
    const canManageTaskSubcategories = rootStore.account.isWorkshopDepartmentMember;
    const taskSubcategoryModel = rootStore.workshop.taskSubcategory;
    const jobTaskModel = rootStore.jobTask;

    const loadTaskSubcategory = taskSubcategoryModel.loadTaskSubcategory;
    const updateTaskSubcategory = taskSubcategoryModel.updateTaskSubcategory;
    const createTaskSubcategory = taskSubcategoryModel.createTaskSubcategory;
    const checkForUniqueName = taskSubcategoryModel.checkForUniqueName;

    const loadTaskCategories = jobTaskModel.loadJobTaskCategories;
    const taskCategories = jobTaskModel.jobTaskCategoryOptions.slice();

    const isUpdateMode = props.mode === 'update';

    const taskSubcategoryId = isUpdateMode ? parseInt(props.route.match.params.id!, 10) : 0;
    const taskSubcategory = Object.assign({}, taskSubcategoryModel.taskSubcategory);

    useEffect(() => {
      loadTaskCategories();
    }, []);

    const handlePreSubmitForCreate = async (
      item: TaskSubcategoryItem
    ): Promise<CreateTaskSubcategoryCommand> => {
      return {
        categoryId: item.jobTaskCategoryId,
        description: item.description,
      };
    };

    const handlePreSubmitForUpdate = async (
      item: TaskSubcategoryItem
    ): Promise<UpdateTaskSubcategoryCommand> => {
      return {
        id: taskSubcategoryId,
        description: item.description,
      };
    };

    const getPageDef = (): ICrudPageDef => {
      return {
        primarySize: PagePrimarySize.half,
        primarySection: {
          title: isUpdateMode ? 'Manage Task Subcategory' : 'Create a Task Subcategory',
          panels: [
            {
              panes: [
                {
                  paneType: PaneType.formFieldsPane,
                  columnCount: 1,
                  fields: [
                    {
                      fieldType: FieldType.selectField,
                      label: 'Category',
                      dataAddr: 'jobTaskCategoryId',
                      valueKey: 'value',
                      descriptionKey: 'label',
                      readonly: isUpdateMode,
                      mandatory: true,
                      useValueOnly: true,
                      optionItems: taskCategories,
                    },
                    {
                      fieldType: FieldType.textField,
                      label: 'Description',
                      dataAddr: 'description',
                      maxLength: 200,
                      mandatory: true,
                      validateAsync: async d => {
                        if (
                          !d.fieldValue ||
                          !d.panelValue.jobTaskCategoryId ||
                          (isUpdateMode &&
                            taskSubcategory &&
                            taskSubcategory.description.toUpperCase() ===
                              d.fieldValue.toUpperCase())
                        ) {
                          return undefined;
                        }
                        const result = await checkForUniqueName(
                          d.panelValue.jobTaskCategoryId,
                          d.fieldValue
                        );
                        return result.nameExists ? `Description is already in use` : undefined;
                      },
                    },
                  ],
                },
              ],
            },
          ],
          onFormPreSubmit: isUpdateMode ? handlePreSubmitForUpdate : handlePreSubmitForCreate,
          onFormSubmit: isUpdateMode ? updateTaskSubcategory : createTaskSubcategory,
        },
      };
    };

    const { mode } = props;
    return (
      <CrudPage
        def={getPageDef()}
        mode={mode}
        isEditingForbidden={!canManageTaskSubcategories || taskSubcategory?.isSystem}
        onLoadData={() => loadTaskSubcategory(taskSubcategoryId)}
        data={taskSubcategory}
        createDefaultData={{}}
      />
    );
  }
);

export default MaintainTaskSubcategory;
