interface IJoblike {
  isContinuingFrom: boolean;
  isContinuingTo: boolean;
}

interface IContinuationsIndicatorProps {
  job: IJoblike;
}

const ContinuationsIndicator: React.FC<IContinuationsIndicatorProps> = ({ job }) => {
  if (!job.isContinuingFrom && !job.isContinuingTo) {
    return null;
  }

  return (
    <span title={'Job is part of a continuation'}>
      {job.isContinuingFrom ? '◀' : null}
      {job.isContinuingTo ? '▶' : null}
    </span>
  );
};

export default ContinuationsIndicator;
