import { Component } from 'react';
import { FieldType } from 'src/views/definitionBuilders/types/field';
import CrudPage, { ICrudPageDef, CrudPageMode } from 'src/views/components/Page/pages/CrudPage';
import { PaneType, ActionType, PagePrimarySize } from 'src/views/definitionBuilders/types';
import { ChevronUpIcon, ChevronDownIcon } from 'src/images/icons';
import { ChangeState } from 'src/api/enums';
import deepEqual from 'src/infrastructure/deepEqual';
import memoizeOne from 'src/infrastructure/memoizeOne';
import { RouteComponentProps } from 'react-router-dom';

type UpdateTechSpecDropdownOptionsCommand = Workshop.Domain.Commands.TechSpecs.UpdateTechSpecDropdownOptionsCommand;
type EditableTechSpecDropdownOptionItem = Workshop.Domain.Queries.TechSpecs.EditableTechSpecDropdownOptionItem;
type GetEditableTechSpecDropdownOptionsQueryResponse = Workshop.Domain.Queries.TechSpecs.GetEditableTechSpecDropdownOptions.GetEditableTechSpecDropdownOptionsQueryResponse;
export interface IMaintainTechSpecDropdownOptionsProps {
  mode: CrudPageMode;
  canManageTechSpecs: boolean;
  techSpecDropdownOptions?: GetEditableTechSpecDropdownOptionsQueryResponse;
  getEditableTechSpecDropdownOptions: (techSpecId: number) => Promise<void>;
  updateTechSpecDropdownOptions: (
    techSpecId: number,
    cmd: UpdateTechSpecDropdownOptionsCommand
  ) => Promise<void>;
}

interface IMaintainTechSpecDropdownOptionsRouteParams {
  id: string;
}

type InternalProps = IMaintainTechSpecDropdownOptionsProps &
  RouteComponentProps<IMaintainTechSpecDropdownOptionsRouteParams>;

class MaintainTechSpecDropdownOptions extends Component<InternalProps> {
  private get isUpdateMode() {
    return this.props.mode === 'update';
  }

  private get techSpecId() {
    return Number.parseInt(this.props.match.params.id, 10);
  }

  private readonly handlePreSubmit = (
    values: GetEditableTechSpecDropdownOptionsQueryResponse
  ): UpdateTechSpecDropdownOptionsCommand => {
    const getChangeState = (
      dropdownOption: EditableTechSpecDropdownOptionItem,
      currentIndex: number
    ) => {
      if (
        dropdownOption.changeState === ChangeState.Added ||
        dropdownOption.changeState === ChangeState.Deleted
      ) {
        return dropdownOption.changeState;
      }
      const originalDropdownOptionIndex = values.items.findIndex(ts => ts.id === dropdownOption.id);
      if (originalDropdownOptionIndex < 0) {
        throw new Error('Cannot find original Tech Spec option');
      }
      const originalDropDownOption = values[originalDropdownOptionIndex];
      return currentIndex === originalDropdownOptionIndex &&
        deepEqual(dropdownOption, originalDropDownOption)
        ? ChangeState.Unchanged
        : ChangeState.Modified;
    };

    return {
      techSpecDropdownOptionItems: values.items.map((v, i) => {
        return {
          ...v,
          changeState: getChangeState(v, i),
        };
      }),
    } as UpdateTechSpecDropdownOptionsCommand;
  };

  private readonly getPageDef = memoizeOne(
    (updating: boolean): ICrudPageDef => {
      return {
        primarySize: PagePrimarySize.threeQuarters,
        primarySection: {
          title: d => `${d.sectionValue?.techSpecName ?? 'Tech Spec'} Dropdown Options`,
          panels: [
            {
              dataAddr: 'items',
              panes: [
                {
                  paneType: PaneType.tablePane,
                  dataRequiredForRows: 'paneValue',
                  fields: [
                    {
                      fieldType: FieldType.textField,
                      dataAddr: 'description',
                      label: 'Description',
                      mandatory: true,
                      validate: d => {
                        if (!d.fieldValue || !this.isUpdateMode) {
                          return undefined;
                        }
                        const dropdownOptions = d.paneValue as EditableTechSpecDropdownOptionItem[];
                        const descriptionCount = dropdownOptions.filter(
                          x => x.description === d.fieldValue
                        ).length;
                        return descriptionCount > 1 ? `Name is already in use` : undefined;
                      },
                    },
                    {
                      fieldType: FieldType.actionListField,
                      hidden: !updating,
                      actionGroups: [
                        {
                          actions: [
                            {
                              actionType: ActionType.moveArrayItemActionButton,
                              label: 'Move up',
                              icon: <ChevronUpIcon />,
                              moveDirection: 'prev',
                              hidden: d =>
                                (d.paneValue as EditableTechSpecDropdownOptionItem[]).indexOf(
                                  d.parentValue
                                ) === 0,
                            },
                            {
                              actionType: ActionType.moveArrayItemActionButton,
                              label: 'Move down',
                              icon: <ChevronDownIcon />,
                              moveDirection: 'next',
                              hidden: d =>
                                (d.paneValue as EditableTechSpecDropdownOptionItem[]).indexOf(
                                  d.parentValue
                                ) ===
                                (d.paneValue as EditableTechSpecDropdownOptionItem[]).length - 1,
                            },
                            {
                              actionType: ActionType.removeArrayItemActionButton,
                              label: 'Remove option',
                              hidden: d => {
                                const dropdownOption = d.parentValue as EditableTechSpecDropdownOptionItem;
                                return !dropdownOption.canBeDeleted;
                              },
                            },
                            {
                              actionType: ActionType.removeArrayItemActionButton,
                              label: "This tech spec dropdown option can't be deleted",
                              disabled: true,
                              hidden: d => {
                                const dropdownOption = d.parentValue as EditableTechSpecDropdownOptionItem;
                                return dropdownOption.canBeDeleted;
                              },
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
                {
                  paneType: PaneType.actionListPane,
                  actionGroups: [
                    {
                      actions: [
                        {
                          actionType: ActionType.addArrayItemActionButton,
                          hidden: !updating,
                          label: 'Add option',
                          newItemData: {
                            techSpecId: this.techSpecId,
                            canBeDeleted: true,
                            canChangeDescription: true,
                          },
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
          onFormPreSubmit: this.handlePreSubmit,
          onFormSubmit: values => this.props.updateTechSpecDropdownOptions(this.techSpecId, values),
        },
      };
    }
  );

  render() {
    const { canManageTechSpecs } = this.props;
    return (
      <CrudPage
        data={this.props.techSpecDropdownOptions}
        def={({ updating }) => this.getPageDef(updating)}
        mode={this.props.mode}
        isEditingForbidden={!canManageTechSpecs}
        onLoadData={() => this.props.getEditableTechSpecDropdownOptions(this.techSpecId)}
      />
    );
  }
}

export default MaintainTechSpecDropdownOptions;
