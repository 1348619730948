import './MaintainTask.scss';

import { DateFormat } from 'src/views/components/DateFormat';
import { Link, RouteComponentProps } from 'react-router-dom';
import {
  ActionType,
  FieldType,
  IActionData,
  IHasChangeState,
  IModalActionButtonDef,
  IPaneData,
  IPaneMeta,
  PagePrimarySize,
  PaneType,
  ShellModalSize,
} from 'src/views/definitionBuilders/types';
import CrudPage, { CrudPageMode, ICrudPageDef } from 'src/views/components/Page/pages/CrudPage';
import getAddPartsGroupModalDef from './getAddPartsGroupModalDef';
import getScheduleJobTaskModalDef from './getScheduleJobTaskModalDef';
import getUnscheduleJobTaskModalDef from './getUnscheduleJobTaskModalDef';
import getCompleteJobTaskModalDef from './getCompleteJobTaskModalDef';
import getCancelJobTaskModalDef from './getCancelJobTaskModalDef';
import { Option, OptionValues } from 'react-select';
import {
  allActionType,
  ChangeState,
  ComponentTypeServiceIntervalType,
  JobTaskCategory,
  jobTaskCategoryDescription,
  JobTaskCompletionStatus,
  JobTaskState,
  JobTaskStatus,
} from 'src/api/enums';
import { IAutocompleteResult } from 'src/domain/baseTypes';
import { IMatchingPart } from 'src/domain/entities/workshop/parts/PartsModel';
import deepEqual from 'src/infrastructure/deepEqual';
import {
  BanIcon,
  CalendarIcon,
  CheckIcon,
  OpenBoxIcon,
  SpinnerIcon,
  UnlinkIcon,
  PlayIcon,
} from 'src/images/icons';
import { distinct } from 'src/infrastructure/arrayUtils';
import { DateTime } from 'luxon';
import { IntervalFormat } from 'src/views/components/IntervalFormat';
import { formatCurrency } from 'src/infrastructure/formattingUtils';
import OtherWork from 'src/views/routes/workshop/jobs/maintainJob/OtherWork';
import Button from 'reactstrap/lib/Button';
import PrintJobSheet, {
  jobSheetPdfHeader,
} from 'src/views/routes/workshop/jobs/listJobs/PrintJobSheet/PrintJobSheet';
import ModalActionButton from 'src/views/components/Page/actions/ModalActionButton';
import getAuditHistoryPanelDef from '../../jobs/maintainJob/getAuditHistoryPanelDef';
import getComponentRegisterPanelDef from './getComponentRegisterPanelDef';
import { isDefined } from 'src/infrastructure/typeUtils';
import { AddAttachmentData } from 'src/domain/entities/workshop/attachment/WorkshopAttachmentModel';
import getAddAttachmentPanelDef from 'src/views/routes/workshop/attachment/getAddAttachmentPanelDef';
import { UpdateAttachmentFormData } from 'src/views/routes/workshop/attachment/getUpdateAttachmentModalDef';
import { Component } from 'react';
import { DeleteAttachmentFormData } from 'src/views/routes/workshop/attachment/getDeleteAttachmentModalDef';
import {
  DownloadAttachmentQuery,
  LoadAttachmentsQuery,
} from 'src/views/components/Attachment/attachmentHelper';
import { getSubmitCloseModalActionGroupDef } from 'src/views/definitionBuilders/common';
import { ENABLE_SHOW_CUBIC_REGISTER } from 'src/appSettings';

type AttachmentDto = Common.Dtos.AttachmentDetailsDto;
type JobTaskDetails = Workshop.Domain.Queries.GetJobTask.JobTaskDetails;
type CreateAdhocJobTaskCommand = Workshop.Domain.Commands.JobTask.CreateAdhocJobTaskCommand;
type UpdateJobTaskCommand = Workshop.Domain.Commands.JobTask.UpdateJobTaskCommand;
type ScheduleFutureJobTaskCommand = Workshop.Domain.Commands.JobTask.ScheduleFutureJobTaskCommand;
type CancelJobTaskCommand = Workshop.Domain.Commands.JobTask.CancelJobTaskCommand;
type ActivityLogTransaction = Workshop.Domain.Queries.ActivityLog.ActivityLogTransaction;
type ActivityLogDetails = Workshop.Domain.Queries.ActivityLog.ActivityLogDetails;
type AssetItem = Workshop.Domain.Queries.AssetItem;
type PartSelectListItem = Workshop.Domain.Queries.GetJobTask.JobTaskPartItem.PartSelectListItem;
type JobTaskPartItem = Workshop.Domain.Queries.GetJobTask.JobTaskPartItem;
type JobTaskLabourItem = Workshop.Domain.Queries.GetJobTask.JobTaskLabourItem;
type CompletionDetailsItem = Workshop.Domain.Queries.GetJobTask.CompletionDetailsItem;
type ChecklistDetails = Workshop.Domain.Queries.GetChecklist.ChecklistDetails;
type PartsGroupItem = Workshop.Domain.Queries.GetPartsGroups.PartsGroupItem;
type PartItem = Workshop.Domain.Queries.GetPartsGroupParts.PartItem;
type JobSummaryDto = Workshop.Domain.Queries.Job.JobSummaryDto;
type JobDetails = Workshop.Domain.Queries.GetJobTask.JobDetails;
type JobDetailsRelatedTask = Workshop.Domain.Queries.GetJobTask.JobDetailsRelatedTask;
type AssetNextServiceDueItem = Workshop.Domain.Queries.AssetServices.GetAssetNextServiceDue.AssetNextServiceDueItem;
type FutureTaskForAsset = Workshop.Domain.Queries.GetFutureTaskForAsset.FutureTaskForAsset;
type ScheduledTaskForAsset = Workshop.Domain.Queries.GetScheduledTaskForAsset.ScheduledTaskForAsset;
type ScheduleServiceJobTaskCommand = Workshop.Domain.Commands.JobTask.ScheduleServiceJobTaskCommand;
type CubicFaultDetail = Workshop.Domain.AggregatesModel.AssetAggregate.CubicFaultDetail;
type CubicItem = Workshop.Domain.AggregatesModel.AssetAggregate.CubicItem;
type AssetDetails = Workshop.Domain.Queries.GetAsset.AssetDetails;
type AssetComponentsItems = Workshop.Domain.Queries.AssetComponents.AssetComponentsItems;
type AssetComponentItem = Workshop.Domain.Queries.AssetComponents.AssetComponentItem;
type AssetOdometerReadingItem = Workshop.Domain.Queries.GetLastAssetOdometerReadings.AssetOdometerReadingItem;
type UpdateAssetComponentsCommand = Workshop.Domain.Commands.AssetComponents.UpdateAssetComponentsCommand;
type CloseJobTaskAndDefectWithoutFixingCommand = Workshop.Domain.Commands.JobTask.CloseJobTaskAndDefectWithoutFixingCommand;
type WorkshopDepot = Common.Queries.Workshop.GetWorkshopDepots.WorkshopDepotDto;
type ServiceCycleItem = Workshop.Domain.Queries.AssetGroup.GetAssetGroupFullServiceCycle.ServiceCycleItem;

const DServiceSubcategoryId = 16;
const GeneralJobTaskSubcategoryId = 12;

const machinerySeverityItems = [{ value: 'Self clearing' }, { value: 'Minor' }, { value: 'Major' }];
const machineryCompletedByItems = [
  { value: 'Qualified person' },
  { value: 'Approved inspection centre' },
  { value: 'Booked for re-inspection' },
];
export const allowedCategoriesForScheduled = [
  JobTaskCategory.Service,
  JobTaskCategory.PreMachineryInspection,
  JobTaskCategory.MachineryInspection,
  JobTaskCategory.PreventativeMaintenanceService,
];

export interface IMaintainJobTaskProps {
  mode: CrudPageMode;
  canManageTasks: boolean;
  jobTask: Workshop.Domain.Queries.GetJobTask.JobTaskDetails | undefined;
  jobToPrint: Workshop.Domain.Queries.Job.PrintJobQuery.JobItem | undefined;
  loadPrintJobSheet: (jobId: string) => Promise<void>;
  activityLogs: ActivityLogTransaction[];
  assets: AssetItem[];
  checklists: ChecklistDetails[];
  suppliers: Option<OptionValues>[];
  workshopStaff: Common.Dtos.StaffMemberDto[];
  allStaffMembers: Common.Dtos.StaffMemberDto[];
  partsGroups: PartsGroupItem[];
  activeJobs: Array<JobSummaryDto>;
  jobDetails: JobDetails | undefined;
  nextService: AssetNextServiceDueItem | undefined;
  futureTasks: FutureTaskForAsset[];
  scheduledTasks: ScheduledTaskForAsset[];
  loadJobTask: (id: string) => Promise<void>;
  loadActivityLog: (defectId: string) => Promise<void>;
  clearActivityLog: () => void;
  loadAssetListItems: () => Promise<void>;
  createAdhocJobTask: (command: CreateAdhocJobTaskCommand) => Promise<void>;
  updateJobTask: (command: UpdateJobTaskCommand) => Promise<void>;
  jobTaskSubcategories: (categoryId?: number | undefined) => Option<OptionValues>[];
  loadChecklists: (includeInactiveChecklists: boolean) => Promise<void>;
  loadSuppliers: () => Promise<void>;
  loadJobTaskCategories: () => Promise<void>;
  searchParts: (
    search: string,
    includeLatestPrice?: boolean
  ) => Promise<IAutocompleteResult<IMatchingPart>>;
  loadStaffMembers: () => Promise<void>;
  loadAllStaffMembers: () => Promise<void>;
  loadPartsGroups: (includeInactivePartsGroups: boolean) => Promise<void>;
  onScheduleJobTask: (command: ScheduleFutureJobTaskCommand) => Promise<void>;
  onScheduleJobTaskWithoutReload: (command: ScheduleFutureJobTaskCommand) => Promise<void>;
  onScheduleService: (command: ScheduleServiceJobTaskCommand) => Promise<void>;
  onCancelJobTask: (command: CancelJobTaskCommand) => Promise<void>;
  onCancelJobTaskWithoutReload: (command: CancelJobTaskCommand) => Promise<void>;
  onLoadActiveJobs: (id: string) => Promise<void>;
  onUnscheduleJobTask: (id: string) => Promise<void>;
  onUnscheduleJobTaskWithoutReload: (id: string) => Promise<void>;
  onCompleteJobTask: (
    id: string,
    resetServicePlan: boolean,
    startingDate: string,
    startingKms: number,
    serviceCycleItem: ServiceCycleItem | undefined
  ) => Promise<void>;
  onSetToInProgress: (id: string) => Promise<void>;
  getJobDetailsForTask: (id: string) => Promise<void>;
  getPartsGroupParts: (partsGroupId: string) => Promise<PartItem[]>;
  loadAssetWithAssociatedInfo: (assetId: string) => Promise<void>;
  clearJobTasks: () => void;
  clearAssetFutureTasks: () => void;
  clearNextService: () => void;
  cubicFaultDetails: Array<CubicFaultDetail> | undefined;
  activeCubicFaultDetails: Array<CubicFaultDetail> | undefined;
  onLoadCubicFaultDetails: () => Promise<void>;
  cubicItems: Array<CubicItem> | undefined;
  onLoadCubicItems: () => Promise<void>;
  asset: AssetDetails | undefined;
  assetComponents: AssetComponentsItems | undefined;
  onLoadAssetComponents: (id: string) => Promise<void>;
  loadLastOdometerReading: (id: string) => Promise<void>;
  lastOdometerReading: AssetOdometerReadingItem | undefined;
  onUpdateAssetComponents: (command: UpdateAssetComponentsCommand) => Promise<void>;

  attachments: Array<AttachmentDto>;
  clearAttachments: () => void;
  addAttachmentToTask: (data: AddAttachmentData) => Promise<void>;
  loadAttachmentsForTask: (query: LoadAttachmentsQuery) => Promise<void>;
  downloadAttachmentForTask: (query: DownloadAttachmentQuery) => Promise<void>;
  deleteAttachmentFromTask: (command: DeleteAttachmentFormData) => Promise<void>;
  updateAttachmentForTask: (command: UpdateAttachmentFormData) => Promise<void>;

  closeJobTaskWithoutFixing: (command: CloseJobTaskAndDefectWithoutFixingCommand) => Promise<void>;

  workshopDepots: Array<WorkshopDepot>;
  defaultWorkshopDepot: WorkshopDepot | undefined;

  fullServiceCycle: Array<ServiceCycleItem>;
  loadAssetGroupFullServiceCycleForAsset: (assetId: string) => Promise<void>;
}

interface IUpdateJobTaskRouteParams {
  id: string;
}

interface IMaintainJobTaskState {
  tasksBeingProcessed: ITaskProcessing[];
  selectedAssetName?: string;
  isCleaningJob: boolean;
  existingFitForService: boolean | undefined;
  loadingSecondaryData: boolean;
}

export interface ITaskProcessing {
  [taskId: string]: boolean;
}

type InternalProps = IMaintainJobTaskProps & RouteComponentProps<IUpdateJobTaskRouteParams>;

const CleaningSubcategoryId = 169;
const showCubicRegisterTab = ENABLE_SHOW_CUBIC_REGISTER;

class MaintainJobTask extends Component<InternalProps, IMaintainJobTaskState> {
  constructor(props: InternalProps) {
    super(props);
    this.state = {
      selectedAssetName: undefined,
      tasksBeingProcessed: [],
      isCleaningJob: false,
      existingFitForService: undefined,
      loadingSecondaryData: true,
    };
  }

  setTaskProcessingStatus(taskId: string, processing: boolean) {
    this.setState(state => {
      const statuses = state.tasksBeingProcessed.slice();
      statuses[taskId] = processing;

      return { ...state, tasksBeingProcessed: statuses };
    });
  }

  componentDidMount() {
    this.props.clearJobTasks();
    this.props.clearAssetFutureTasks();
    this.props.clearNextService();
    this.props.clearAttachments();
    this.props.loadAssetListItems();
    this.props.loadChecklists(false);
    this.props.loadSuppliers();
    this.props.loadJobTaskCategories();
    this.props.loadStaffMembers();
    this.props.loadAllStaffMembers();
    this.props.loadPartsGroups(false);
    this.props.onLoadCubicFaultDetails();
    this.props.onLoadCubicItems();
  }

  private get isUpdateMode() {
    return this.props.mode === 'update';
  }

  private get jobTaskId() {
    return this.props.match.params.id;
  }

  private get assetHasOdometer() {
    return !!(
      this.props.asset &&
      this.props.asset.subcategory &&
      this.props.asset.subcategory.hasOdometer
    );
  }

  private isSubcategoryEditable(
    category: Workshop.Domain.AggregatesModel.JobTaskAggregate.JobTaskCategory
  ) {
    const canJobTaskSubcategoryBeChanged = [JobTaskCategory.Defect, JobTaskCategory.General];
    return category && canJobTaskSubcategoryBeChanged.includes(category.id);
  }

  private loadData = () => {
    // Returns a promise for the primary data only.
    return this.loadPrimaryData().then(() => {
      return this.loadSecondaryData();
    });
  };

  private loadPrimaryData = () => {
    return this.props.loadJobTask(this.jobTaskId);
  };

  private loadSecondaryData = () => {
    // Returns a resolved promise when they are all complete.
    this.setState({ loadingSecondaryData: true });
    return Promise.all([
      this.props.onLoadActiveJobs(this.jobTaskId),
      this.props.loadActivityLog(this.jobTaskId),
      this.props.getJobDetailsForTask(this.jobTaskId),
      this.props.loadAssetWithAssociatedInfo(this.props.jobTask!.asset.id),
      this.props.onLoadAssetComponents(this.props.jobTask!.asset.id),
      this.props.loadLastOdometerReading(this.props.jobTask!.asset.id),
      this.props.loadAttachmentsForTask({ aggregateId: this.jobTaskId, aggregateType: 'task' }),
      this.props.loadAssetGroupFullServiceCycleForAsset(this.props.jobTask!.asset.id),
    ])
      .then(() => {
        if (this.props.jobTask) {
          this.setState({
            isCleaningJob: this.props.jobTask.subcategoryId === CleaningSubcategoryId,
            existingFitForService: this.props.jobTask.fitForService,
          });
        }
        return Promise.resolve();
      })
      .finally(() => this.setState({ loadingSecondaryData: false }));
  };

  private handleLoadCreateDefaultData = () => {
    this.props.clearActivityLog();
    return Promise.resolve();
  };

  private handlePreSubmitForCreate = (
    jobTask: JobTaskDetails & {
      servicedByKm: AssetComponentItem[];
      servicedByDate: AssetComponentItem[];
    }
  ): {
    createCommand: CreateAdhocJobTaskCommand;
    componentUpdate: UpdateAssetComponentsCommand;
  } => {
    if (this.state.loadingSecondaryData) {
      throw new Error('Waiting for component register to load');
    }

    return {
      componentUpdate: this.mapFormDataToComponentUpdateCommand(jobTask),
      createCommand: {
        assetId: jobTask.asset.id,
        description: jobTask.description,
        checklistId: jobTask.checklistId,
        supplierId: jobTask.supplierId,
        odometerReading: jobTask.odometerReading,
        billable: jobTask.billable,
        breakdown: jobTask.breakdown,
        incident: jobTask.incident,
        accident: jobTask.accident,
        forMachinerySchedule: jobTask.forMachinerySchedule,
        fitForService: jobTask.fitForService,
        parts: jobTask.parts.map(line => {
          return {
            changeState: ChangeState.Added,
            id: line.id,
            partId: line.part.id,
            description: line.description,
            usedDate: line.usedDate,
            unitPrice: line.unitPrice,
            quantity: line.quantity,
            latestUnitPrice: line.latestUnitPrice,
          };
        }),
        labour: jobTask.labour.map(line => {
          return {
            changeState: ChangeState.Added,
            id: line.id,
            staffMemberId: line.staffMember.id,
            hours: line.hours,
            date: line.date,
          };
        }),
      },
    };
  };

  private handleSubmit = (data: {
    taskUpdate?: UpdateJobTaskCommand;
    createCommand?: CreateAdhocJobTaskCommand;
    componentUpdate: UpdateAssetComponentsCommand;
  }) => {
    return this.isUpdateMode
      ? this.props
          .updateJobTask(data.taskUpdate!)
          .then(() => this.props.onUpdateAssetComponents(data.componentUpdate))
          .then(() => this.loadSecondaryData())
      : this.props
          .createAdhocJobTask(data.createCommand!)
          .then(() => this.props.onUpdateAssetComponents(data.componentUpdate));
  };

  private mapFormDataToComponentUpdateCommand(
    formData: JobTaskDetails & {
      servicedByKm: AssetComponentItem[];
      servicedByDate: AssetComponentItem[];
    }
  ) {
    const originalAssetComponents = this.props.assetComponents;
    const getChangeState = (i: AssetComponentItem & IHasChangeState) => {
      const originalItem =
        originalAssetComponents &&
        originalAssetComponents.components &&
        originalAssetComponents.components.find(x => x.id != null && x.id === i.id);

      return i.changeState === ChangeState.Added || originalItem === undefined
        ? ChangeState.Added
        : deepEqual(i, originalItem)
        ? ChangeState.Unchanged
        : ChangeState.Modified;
    };

    return {
      assetId: formData.asset.id,
      components: formData.servicedByDate.concat(formData.servicedByKm).map(x => ({
        id: x.id,
        componentTypeId: x.componentType.id!,
        partId: x.part && x.part.id,
        serialNumber: x.serialNumber,
        dueDate: x.dueDate,
        dueKms: x.dueKms,
        lastServiceDate: x.lastServiceDate,
        lastServiceKms: x.lastServiceKms,
        changeState: getChangeState(x),
      })),
    };
  }

  private handlePreSubmitForUpdate = (
    jobTask: JobTaskDetails & {
      servicedByKm: AssetComponentItem[];
      servicedByDate: AssetComponentItem[];
    }
  ): { taskUpdate: UpdateJobTaskCommand; componentUpdate: UpdateAssetComponentsCommand } => {
    if (this.state.loadingSecondaryData) {
      throw new Error('Waiting for component register to load');
    }
    const originalJobTask = this.props.jobTask;
    const getPartChangeState = (li: JobTaskPartItem) => {
      if (li.changeState === ChangeState.Added || li.changeState === ChangeState.Deleted) {
        return li.changeState;
      }
      const originalLineItem = originalJobTask && originalJobTask.parts.find(x => x.id === li.id);
      if (!originalLineItem) {
        throw new Error('Cannot find original Task Part');
      }
      return deepEqual(li, originalLineItem) ? ChangeState.Unchanged : ChangeState.Modified;
    };
    const getLabourChangeState = (li: JobTaskLabourItem) => {
      if (li.changeState === ChangeState.Added || li.changeState === ChangeState.Deleted) {
        return li.changeState;
      }
      const originalLineItem = originalJobTask && originalJobTask.labour.find(x => x.id === li.id);
      if (!originalLineItem) {
        throw new Error('Cannot find original Task Labour');
      }
      return deepEqual(li, originalLineItem) ? ChangeState.Unchanged : ChangeState.Modified;
    };
    const getCompletionDetailsChangeState = (ci: CompletionDetailsItem) => {
      if (ci.changeState === ChangeState.Added || ci.changeState === ChangeState.Deleted) {
        return ci.changeState;
      }
      const originalLineItem =
        originalJobTask && originalJobTask.completionDetails.find(x => x.id === ci.id);
      if (!originalLineItem) {
        throw new Error('Cannot find original Task Labour');
      }
      return deepEqual(ci, originalLineItem) ? ChangeState.Unchanged : ChangeState.Modified;
    };

    return {
      componentUpdate: this.mapFormDataToComponentUpdateCommand(jobTask),
      taskUpdate: {
        jobTaskId: this.jobTaskId,
        checklistId: jobTask.checklistId,
        description: jobTask.description,
        odometerReading: jobTask.odometerReading,
        supplierId: jobTask.supplierId,
        billable: jobTask.billable,
        breakdown: jobTask.breakdown,
        incident: jobTask.incident,
        accident: jobTask.accident,
        forMachinerySchedule: jobTask.forMachinerySchedule,
        subcategoryId: jobTask.subcategoryId,
        job: { state: JobTaskState.Unchanged },
        fitForService: jobTask.fitForService,
        roadTestDone: jobTask.roadTestDone,
        brakeTestDone: jobTask.brakeTestDone,
        servicedAsPerChecklist: jobTask.servicedAsPerChecklist,
        valveAdjustmentDone: jobTask.valveAdjustmentDone,
        foundDefects: jobTask.foundDefects
          .filter(defect => !defect.defectNumber)
          .map(defect => {
            return {
              description: defect.description,
              reportedBy: defect.reportedBy,
            };
          }),
        labour: jobTask.labour.map(line => {
          return {
            id: line.id,
            staffMemberId: line.staffMember.id,
            hours: line.hours,
            date: line.date,
            changeState: getLabourChangeState(line),
          };
        }),
        completionDetails: jobTask.completionDetails.map(c => ({
          ...c,
          changeState: getCompletionDetailsChangeState(c),
          workDoneBy: c.workDoneBy,
        })),
        machineryDefects: jobTask.machineryDefects
          .filter(defect => !defect.defectNumber)
          .map(defect => {
            return {
              description: defect.description,
              machineryCompletedBy: defect.machineryCompletedBy,
              machineryDefectNumber: defect.machineryDefectNumber,
              machinerySeverity: defect.machinerySeverity,
            };
          }),
        parts: jobTask.parts.map(line => {
          return {
            id: line.id,
            partId: line.part.id,
            description: line.description,
            usedDate: line.usedDate,
            unitPrice: line.unitPrice,
            quantity: line.quantity,
            changeState: getPartChangeState(line),
            latestlatestUnitPricePrice: line.latestUnitPrice,
          };
        }),
        assetCubicChanges:
          originalJobTask && originalJobTask.asset && originalJobTask.asset.assetCubicSummaryItems
            ? jobTask.asset.assetCubicSummaryItems
                .filter(
                  a =>
                    a.serialNumber !==
                      originalJobTask.asset.assetCubicSummaryItems.find(
                        o => o.cubicItemId === a.cubicItemId
                      )!.serialNumber ||
                    a.cubicFaultDetailId !==
                      originalJobTask.asset.assetCubicSummaryItems.find(
                        o => o.cubicItemId === a.cubicItemId
                      )!.cubicFaultDetailId
                )
                .map(x => ({ ...x, jobTaskId: this.jobTaskId }))
            : [],
      },
    };
  };

  private readonly getJobTasksPane = (api: {
    data: IPaneData;
    meta: IPaneMeta;
  }): React.ReactNode => {
    const jobDetails = this.props.jobDetails;
    if (!jobDetails || !jobDetails.jobNumber) {
      return (
        <div className="maintain-task-job-details-grid">
          <div className="job-number">
            <em>This task has yet to be scheduled</em>
          </div>
        </div>
      );
    }

    const cancelJobTask = (command: CancelJobTaskCommand) => {
      return this.props.onCancelJobTaskWithoutReload(command).then(this.loadData);
    };

    const getCancelTaskDef = (jobTaskId: string): IModalActionButtonDef => {
      return {
        label: 'Cancel This Task',
        icon: <BanIcon fixedWidth />,
        actionType: ActionType.modalActionButton,
        modalSize: ShellModalSize.oneQuarter,
        modalDef: getCancelJobTaskModalDef(cancelJobTask, jobTaskId, undefined, undefined),
      };
    };

    const buttonClickStillProcessing = (taskId: string) => {
      return this.state.tasksBeingProcessed[taskId] === true;
    };

    return (
      <div className="maintain-task-job-details-grid">
        <div className="job-number">
          <h3>
            <strong>Job </strong>
            <Link to={`/workshop/jobs/${jobDetails.jobId}`}>{jobDetails.jobNumber}</Link>
          </h3>
        </div>
        <div className="schedule">
          <IntervalFormat
            includeYear
            startValue={jobDetails.startDateTime}
            endValue={jobDetails.endDateTime}
          />
        </div>
        <ul className="job-tasks list-unstyled">
          {jobDetails.relatedTasks &&
            jobDetails.relatedTasks.map((relatedTask: JobDetailsRelatedTask) => {
              return (
                <li className="job-task" key={relatedTask.id}>
                  <div className="task-number">
                    <span>Task&nbsp;</span>
                    <Link to={`/workshop/tasks/${relatedTask.id}`}>
                      {relatedTask.jobTaskNumber}
                    </Link>
                  </div>
                  <div className="task-description">{relatedTask.description}</div>
                  <div className="task-status">{relatedTask.status.description}</div>
                  <div className="task-cancel">
                    {relatedTask.status.id === JobTaskStatus.Scheduled &&
                    (relatedTask.category.id === JobTaskCategory.Defect ||
                      relatedTask.category.id === JobTaskCategory.General) ? (
                      <Button
                        outline
                        className="scheduled-task-button"
                        title="Unschedule This Task"
                        disabled={buttonClickStillProcessing(relatedTask.id)}
                        onClick={() => this.props.onUnscheduleJobTaskWithoutReload(relatedTask.id)}>
                        {buttonClickStillProcessing(relatedTask.id) ? (
                          <SpinnerIcon fixedWidth />
                        ) : (
                          <UnlinkIcon fixedWidth />
                        )}
                      </Button>
                    ) : null}
                    {relatedTask.status.id === JobTaskStatus.Scheduled &&
                    relatedTask.category.id === JobTaskCategory.Service ? (
                      <ModalActionButton
                        className="scheduled-task-button"
                        actionDef={getCancelTaskDef(relatedTask.id)}
                        actionMeta={{ formSubmitting: false, hideLabel: true, borderless: true }}
                        actionData={{} as IActionData}
                      />
                    ) : null}
                  </div>
                </li>
              );
            })}
        </ul>
      </div>
    );
  };

  private readonly getPageDef = (updating: boolean): ICrudPageDef => {
    const {
      futureTasks,
      nextService,
      scheduledTasks,
      jobTask,
      canManageTasks,
      closeJobTaskWithoutFixing,
      workshopDepots,
      defaultWorkshopDepot,
      fullServiceCycle,
    } = this.props;
    const jobTaskNumber = jobTask && jobTask.jobTaskNumber;

    const getBadgeStatus = () => {
      if (!jobTask) {
        return undefined;
      }

      const taskCompletionStatus =
        jobTask.completionStatus && jobTask.completionStatus.id !== JobTaskCompletionStatus.Done
          ? jobTask.completionStatus.description
          : null;

      const aggregateStatus = [
        jobTask.status.description,
        jobTask.onHoldStatus,
        taskCompletionStatus,
      ]
        .filter(s => s)
        .join(' - ');

      return { label: aggregateStatus };
    };

    const isFromPurchaseOrder = (taskPart: JobTaskPartItem) =>
      taskPart && taskPart.purchaseOrder != null;

    const scheduleFutureTaskAndReloadAssetInfo = (cmd: ScheduleFutureJobTaskCommand) => {
      const assetId = this.props.jobTask!.asset.id;
      return this.props
        .onScheduleJobTask(cmd)
        .then(() => this.props.loadAssetWithAssociatedInfo(assetId));
    };

    const unscheduleFutureTaskAndReloadAssetInfo = (jobId: string) => {
      const assetId = this.props.jobTask!.asset.id;
      return this.props
        .onUnscheduleJobTask(jobId)
        .then(() => this.props.loadAssetWithAssociatedInfo(assetId));
    };

    // tslint:disable-next-line: no-any
    const onAssetChange = (api: any) => {
      const asset = api.newFieldValue as AssetItem;
      if (asset) {
        this.setState({ loadingSecondaryData: true });
        Promise.all([
          this.props.loadAssetWithAssociatedInfo(asset.id),
          this.props.onLoadAssetComponents(asset.id),
          this.props.loadLastOdometerReading(asset.id),
        ])
          .then(() => {
            api.setFormValue(
              'servicedByKm',
              (this.props.assetComponents &&
                this.props.assetComponents.components &&
                this.props.assetComponents.components.filter(
                  x => x.componentType.serviceIntervalType === ComponentTypeServiceIntervalType.Km
                )) ||
                []
            );
            api.setFormValue(
              'servicedByDate',
              (this.props.assetComponents &&
                this.props.assetComponents.components &&
                this.props.assetComponents.components.filter(
                  x => x.componentType.serviceIntervalType === ComponentTypeServiceIntervalType.Day
                )) ||
                []
            );
          })
          .finally(() => this.setState({ loadingSecondaryData: false }));

        this.setState({
          selectedAssetName: asset.name,
        });
      }
    };

    const canBeCancelled =
      this.props.jobTask &&
      this.props.jobTask.fitForService !== false &&
      ((this.props.jobTask.status.id === JobTaskStatus.Scheduled &&
        allowedCategoriesForScheduled.includes(this.props.jobTask.category.id)) ||
        (this.props.jobTask.category.id === JobTaskCategory.General &&
          (this.props.jobTask.status.id === JobTaskStatus.Future ||
            this.props.jobTask.status.id === JobTaskStatus.Scheduled)));

    const canTaskBeClosed =
      jobTask &&
      jobTask.category.id === JobTaskCategory.Defect &&
      (jobTask.status.id === JobTaskStatus.Future || jobTask.status.id === JobTaskStatus.Scheduled);
    return {
      primarySize: PagePrimarySize.sevenTwelves,
      primarySection: {
        title: this.isUpdateMode ? `Task ${jobTaskNumber || ''}` : 'Create Adhoc Task',
        badge: getBadgeStatus(),
        primaryActions: [
          {
            actions: [
              {
                actionType: ActionType.actionCollection,
                hidden: updating || !canManageTasks,
                actionGroups: [
                  {
                    actions: [
                      {
                        actionType: ActionType.printActionButton,
                        label: 'Print Job Sheet',
                        hidden: !this.isUpdateMode || updating || !this.props.jobDetails,
                        printTitle:
                          this.props.jobToPrint && jobSheetPdfHeader(this.props.jobToPrint),
                        printContent: () =>
                          this.props.jobToPrint ? (
                            <PrintJobSheet job={this.props.jobToPrint} />
                          ) : null,
                        loadDataAsync: d =>
                          this.props.loadPrintJobSheet(this.props.jobDetails!.jobId),
                      },
                      {
                        actionType: ActionType.modalActionButton,
                        label: 'Schedule This Task',
                        icon: <CalendarIcon fixedWidth />,
                        modalSize: ShellModalSize.oneThird,
                        modalDef: getScheduleJobTaskModalDef(
                          scheduleFutureTaskAndReloadAssetInfo,
                          this.props.activeJobs,
                          workshopDepots,
                          defaultWorkshopDepot,
                          this.jobTaskId,
                          undefined
                        ),
                        hidden:
                          !this.props.jobTask ||
                          this.props.jobTask.status.id !== JobTaskStatus.Future,
                      },
                      {
                        actionType: ActionType.modalActionButton,
                        label: 'Reschedule This Task',
                        icon: <CalendarIcon fixedWidth />,
                        modalSize: ShellModalSize.oneThird,
                        modalDef: getScheduleJobTaskModalDef(
                          scheduleFutureTaskAndReloadAssetInfo,
                          this.props.activeJobs,
                          workshopDepots,
                          defaultWorkshopDepot,
                          this.jobTaskId,
                          this.props.jobTask &&
                            this.props.jobTask.job &&
                            this.props.jobTask.job.jobId
                        ),
                        hidden:
                          !this.props.jobTask ||
                          (this.props.jobTask.status.id !== JobTaskStatus.Scheduled &&
                            this.props.jobTask.status.id !== JobTaskStatus.InProgress) ||
                          this.props.jobTask.fitForService === false,
                      },
                      {
                        actionType: ActionType.modalActionButton,
                        label: 'Unschedule This Task',
                        icon: <CalendarIcon fixedWidth />,
                        modalSize: ShellModalSize.oneThird,
                        modalDef: getUnscheduleJobTaskModalDef(
                          unscheduleFutureTaskAndReloadAssetInfo,
                          this.jobTaskId
                        ),
                        hidden:
                          !this.props.jobTask ||
                          this.props.jobTask.fitForService === false ||
                          (this.props.jobTask.status.id !== JobTaskStatus.Scheduled &&
                            this.props.jobTask.status.id !== JobTaskStatus.InProgress) ||
                          (this.props.jobTask.category.id !== JobTaskCategory.Defect &&
                            this.props.jobTask.category.id !== JobTaskCategory.General),
                      },
                      {
                        actionType: ActionType.actionButton,
                        label: 'Set To In Progress',
                        icon: <PlayIcon fixedWidth />,
                        onClick: () => this.props.onSetToInProgress(this.jobTaskId),
                        hidden:
                          !this.props.jobTask ||
                          this.props.jobTask.status.id !== JobTaskStatus.Scheduled ||
                          this.props.jobTask.labour.length === 0,
                      },
                      {
                        actionType: ActionType.modalActionButton,
                        label: 'Complete This Task',
                        icon: <CheckIcon fixedWidth />,
                        modalSize: ShellModalSize.oneQuarter,
                        modalDef: getCompleteJobTaskModalDef(
                          jobTask,
                          this.props.onCompleteJobTask,
                          this.jobTaskId,
                          fullServiceCycle
                        ),
                        hidden:
                          !this.props.jobTask ||
                          (this.props.jobTask.status.id !== JobTaskStatus.Scheduled &&
                            this.props.jobTask.status.id !== JobTaskStatus.InProgress),
                      },
                      {
                        actionType: ActionType.modalActionButton,
                        label: 'Cancel Task',
                        icon: <BanIcon fixedWidth />,
                        modalSize: ShellModalSize.oneQuarter,
                        modalDef: getCancelJobTaskModalDef(
                          this.props.onCancelJobTask,
                          this.jobTaskId,
                          this.props.jobTask,
                          this.props.jobDetails
                        ),
                        hidden: !canBeCancelled,
                      },
                    ],
                  },
                  {
                    actions: [
                      {
                        actionType: ActionType.modalActionButton,
                        label: 'Close Task and Defect without fixing',
                        modalSize: ShellModalSize.oneQuarter,
                        hidden: !canManageTasks || updating || !canTaskBeClosed,
                        icon: <BanIcon />,
                        modalDef: d => ({
                          title: 'Close Task and Defect',
                          asForm: true,
                          panels: [
                            {
                              panes: [
                                {
                                  paneType: PaneType.formFieldsPane,
                                  fields: [
                                    {
                                      fieldType: FieldType.textAreaField,
                                      dataAddr: 'reason',
                                      mandatory: true,
                                      label: 'Reason for closure',
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                          secondaryActions: [
                            getSubmitCloseModalActionGroupDef('Close Task and Defect'),
                          ],
                          onFormPreSubmit: f =>
                            ({
                              jobTaskId: this.jobTaskId,
                              reason: f.reason,
                            } as CloseJobTaskAndDefectWithoutFixingCommand),
                          onFormSubmit: closeJobTaskWithoutFixing,
                        }),
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
        panels: [
          {
            panes: [
              {
                paneType: PaneType.formFieldsPane,
                fields: [
                  {
                    fieldType: FieldType.assetSelectField,
                    label: 'Asset',
                    dataAddr: 'asset',
                    readonly: this.isUpdateMode,
                    valueKey: 'id',
                    descriptionKey: 'name',
                    mandatory: true,
                    optionItems: this.props.assets,
                    onChange: onAssetChange,
                  },
                ],
              },
              {
                paneType: PaneType.formFieldsPane,
                columnCount: 2,
                fields: [
                  {
                    fieldType: FieldType.textAreaField,
                    label: 'Category',
                    dataAddr: ['category', 'description'],
                    readonly: true,
                  },
                  {
                    fieldType: FieldType.selectField,
                    label: 'Subcategory',
                    dataAddr: 'subcategoryId',
                    valueKey: 'value',
                    descriptionKey: 'label',
                    readonly: d => {
                      return !this.isSubcategoryEditable(d.parentValue.category);
                    },
                    mandatory: true,
                    useValueOnly: true,
                    optionItems: d => {
                      return this.props.jobTaskSubcategories(
                        d.parentValue.category && d.parentValue.category.id
                      );
                    },
                    onChange: d => {
                      if (this.props.jobTask) {
                        this.setState({ isCleaningJob: d.newFieldValue === CleaningSubcategoryId });
                      }
                    },
                  },
                ],
              },
              {
                paneType: PaneType.formFieldsPane,
                fields: [
                  {
                    fieldType: FieldType.textAreaField,
                    label: 'Description',
                    dataAddr: 'description',
                    mandatory: true,
                    rows: 3,
                  },
                ],
              },
              {
                paneType: PaneType.formFieldsPane,
                columnCount: 2,
                fields: [
                  {
                    fieldType: FieldType.selectField,
                    label: 'Checklist',
                    dataAddr: 'checklistId',
                    valueKey: 'id',
                    descriptionKey: 'name',
                    useValueOnly: true,
                    optionItems: this.props.checklists,
                  },
                  {
                    fieldType: FieldType.selectField,
                    label: 'Contractor',
                    dataAddr: 'supplierId',
                    valueKey: 'value',
                    descriptionKey: 'label',
                    useValueOnly: true,
                    optionItems: this.props.suppliers,
                  },
                  {
                    fieldType: FieldType.numericField,
                    label: 'Odometer Reading',
                    dataAddr: 'odometerReading',
                    numericConfig: { numericType: 'unsignedInt', maxValue: 9999999 },
                    mandatory: d => d.parentValue.fitForService === true && this.assetHasOdometer,
                    hidden: !this.assetHasOdometer,
                  },
                  {
                    fieldType: FieldType.yesNoField,
                    label: 'Billable',
                    dataAddr: 'billable',
                    mandatory: true,
                  },
                  {
                    fieldType: FieldType.yesNoField,
                    label: 'Breakdown',
                    dataAddr: 'breakdown',
                    mandatory: true,
                  },
                  {
                    fieldType: FieldType.yesNoField,
                    label: 'Incident',
                    dataAddr: 'incident',
                    mandatory: true,
                  },
                  {
                    fieldType: FieldType.yesNoField,
                    label: 'Accident',
                    dataAddr: 'accident',
                    mandatory: true,
                  },
                  {
                    fieldType: FieldType.yesNoField,
                    label: 'For Machinery Schedule',
                    dataAddr: 'forMachinerySchedule',
                    mandatory: true,
                  },
                ],
              },
              {
                paneType: PaneType.formFieldsPane,
                fields: [
                  {
                    fieldType: FieldType.textAreaField,
                    label: 'Notes',
                    dataAddr: 'notes',
                    hidden: !(this.props.jobTask && this.props.jobTask.notes) || updating,
                    rows: 3,
                  },
                ],
              },
              {
                paneType: PaneType.formFieldsPane,
                columnCount: 2,
                fields: [
                  {
                    fieldType: FieldType.yesNoField,
                    label: 'Fit For Service',
                    dataAddr: 'fitForService',
                    hidden:
                      this.props.jobTask && this.props.jobTask.status.id === JobTaskStatus.Future,
                  },
                ],
              },
              {
                paneType: PaneType.formFieldsPane,
                columnCount: 4,

                fields: [
                  {
                    fieldType: FieldType.yesNoField,
                    label: 'Road Test',
                    dataAddr: 'roadTestDone',
                    hidden: d =>
                      !d.parentValue.category ||
                      (d.parentValue.category.id !== JobTaskCategory.Service &&
                        d.parentValue.category.id !== JobTaskCategory.PreMachineryInspection),
                  },
                  {
                    fieldType: FieldType.yesNoField,
                    label: 'Brake Test',
                    dataAddr: 'brakeTestDone',
                    hidden: d =>
                      !d.parentValue.category ||
                      (d.parentValue.category.id !== JobTaskCategory.Service &&
                        d.parentValue.category.id !== JobTaskCategory.PreMachineryInspection),
                  },
                  {
                    fieldType: FieldType.yesNoField,
                    label: 'Serviced As Per Checklist',
                    dataAddr: 'servicedAsPerChecklist',
                    hidden: d =>
                      !d.parentValue.category ||
                      (d.parentValue.category.id !== JobTaskCategory.Service &&
                        d.parentValue.category.id !== JobTaskCategory.MachineryInspection &&
                        d.parentValue.category.id !== JobTaskCategory.PreMachineryInspection) ||
                      !d.parentValue.checklistId,
                  },
                  {
                    fieldType: FieldType.yesNoField,
                    label: 'Valve Adjustment',
                    dataAddr: 'valveAdjustmentDone',
                    hidden: d =>
                      !d.parentValue.category ||
                      (d.parentValue.category.id !== JobTaskCategory.Service &&
                        d.parentValue.category.id !== JobTaskCategory.MachineryInspection) ||
                      d.parentValue.subcategoryId !== DServiceSubcategoryId,
                  },
                ],
              },
            ],
          },
          {
            title: 'Details',
            dataAddr: 'completionDetails',
            panes: [
              {
                paneType: PaneType.tablePane,
                mandatory: false,
                validate: d => {
                  const newFitForService = d.sectionValue.fitForService;

                  if (this.state.existingFitForService !== false || newFitForService !== true) {
                    return undefined;
                  }

                  const items =
                    d.panelValue &&
                    (d.panelValue as JobTaskLabourItem[]).filter(
                      i => i.changeState !== ChangeState.Deleted
                    );

                  if ((!items || items.length === 0) && isDefined(newFitForService)) {
                    return 'Details must be entered';
                  }
                  return undefined;
                },
                fields: [
                  {
                    fieldType: FieldType.textAreaField,
                    label: 'Issue',
                    dataAddr: 'issue',
                    mandatory: true,
                  },
                  {
                    fieldType: FieldType.textAreaField,
                    label: 'Work Done',
                    dataAddr: 'workDone',
                    mandatory: true,
                  },
                  {
                    fieldType: FieldType.selectField,
                    label: 'Done By',
                    dataAddr: 'workDoneBy',
                    mandatory: true,
                    valueKey: 'id',
                    descriptionKey: 'name',
                    useValueOnly: true,
                    optionItems: (this.state.isCleaningJob
                      ? this.props.allStaffMembers
                      : this.props.workshopStaff
                    ).filter(
                      o =>
                        o.active ||
                        (this.props.jobTask &&
                          this.props.jobTask.completionDetails &&
                          this.props.jobTask.completionDetails.some(c => c.workDoneBy === o.id))
                    ),
                    columnWidth: '8em',
                  },

                  {
                    fieldType: FieldType.selectField,
                    label: 'Action',
                    dataAddr: 'action',
                    valueKey: 'value',
                    descriptionKey: 'description',
                    mandatory: true,
                    useValueOnly: true,
                    optionItems: allActionType,
                    columnWidth: '6em',
                  },
                  {
                    fieldType: FieldType.yesNoField,
                    label: 'Signed Off',
                    dataAddr: 'signedOff',
                    mandatory: false,
                    columnWidth: '1em',
                  },
                  {
                    fieldType: FieldType.actionListField,
                    dataAddr: '',
                    columnWidth: '1px',
                    actionGroups: [
                      {
                        actions: [
                          {
                            hidden: d => this.isUpdateMode && !updating,
                            actionType: ActionType.removeArrayItemActionButton,
                            label: 'Remove Line',
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
              {
                paneType: PaneType.actionListPane,
                hidden: this.isUpdateMode && !updating,
                actionGroups: [
                  {
                    actions: [
                      {
                        actionType: ActionType.addArrayItemActionButton,
                        label: 'Add Details',
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            title: 'Defects',
            dataAddr: 'foundDefects',
            hidden: d =>
              !this.isUpdateMode ||
              (d.sectionValue.category &&
                d.sectionValue.category.id === JobTaskCategory.MachineryInspection),
            panes: [
              {
                paneType: PaneType.tablePane,
                mandatory: false,
                fields: [
                  {
                    fieldType: FieldType.textField,
                    label: 'Defect Number',
                    dataAddr: 'defectNumber',
                    columnWidth: '8em',
                    readonly: true,
                    hidden: d => !d.parentValue.defectNumber,
                    linkTo: d => {
                      return `/workshop/defects/${d.parentValue.id}`;
                    },
                  },
                  {
                    fieldType: FieldType.textField,
                    label: 'Description',
                    dataAddr: 'description',
                    readonly: d => d.parentValue.defectNumber,
                    mandatory: true,
                  },
                  {
                    fieldType: FieldType.selectField,
                    label: 'Reported By',
                    dataAddr: 'reportedBy',
                    valueKey: 'name',
                    descriptionKey: 'name',
                    readonly: d => d.parentValue.defectNumber,
                    mandatory: true,
                    useValueOnly: true,
                    optionItems: this.props.workshopStaff,
                    columnWidth: '12em',
                  },
                  {
                    fieldType: FieldType.actionListField,
                    dataAddr: '',
                    columnWidth: '1px',
                    actionGroups: [
                      {
                        actions: [
                          {
                            hidden: d =>
                              (this.isUpdateMode && !updating) || d.parentValue.defectNumber,
                            actionType: ActionType.removeArrayItemActionButton,
                            label: 'Remove Line',
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
              {
                paneType: PaneType.actionListPane,
                hidden: this.isUpdateMode && !updating,
                actionGroups: [
                  {
                    actions: [
                      {
                        actionType: ActionType.addArrayItemActionButton,
                        label: 'Add Defect',
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            title: 'Defects',
            dataAddr: 'machineryDefects',
            hidden: d =>
              !this.isUpdateMode ||
              (d.sectionValue.category &&
                d.sectionValue.category.id !== JobTaskCategory.MachineryInspection),
            panes: [
              {
                paneType: PaneType.tablePane,
                mandatory: false,
                fields: [
                  {
                    fieldType: FieldType.textField,
                    label: 'Defect Number',
                    dataAddr: 'defectNumber',
                    columnWidth: '7em',
                    readonly: true,
                    hidden: d => !d.parentValue.defectNumber,
                    linkTo: d => {
                      return `/workshop/defects/${d.parentValue.id}`;
                    },
                  },
                  {
                    fieldType: FieldType.textField,
                    label: 'HVI Machinery Defect',
                    dataAddr: 'machineryDefectNumber',
                    columnWidth: '7em',
                    readonly: d => d.parentValue.defectNumber,
                    mandatory: true,
                  },
                  {
                    fieldType: FieldType.textField,
                    label: 'Description',
                    dataAddr: 'description',
                    readonly: d => d.parentValue.defectNumber,
                    mandatory: true,
                  },

                  {
                    fieldType: FieldType.selectField,
                    label: 'Severity',
                    dataAddr: 'machinerySeverity',
                    valueKey: 'value',
                    descriptionKey: 'value',
                    readonly: d => d.parentValue.defectNumber,
                    mandatory: true,
                    useValueOnly: true,
                    optionItems: machinerySeverityItems,
                  },
                  {
                    fieldType: FieldType.selectField,
                    label: 'Completed By',
                    dataAddr: 'machineryCompletedBy',
                    valueKey: 'value',
                    descriptionKey: 'value',
                    readonly: d => d.parentValue.defectNumber,
                    mandatory: true,
                    useValueOnly: true,
                    optionItems: machineryCompletedByItems,
                  },
                  {
                    fieldType: FieldType.actionListField,
                    dataAddr: '',
                    columnWidth: '1px',
                    actionGroups: [
                      {
                        actions: [
                          {
                            hidden: d =>
                              (this.isUpdateMode && !updating) || d.parentValue.defectNumber,
                            actionType: ActionType.removeArrayItemActionButton,
                            label: 'Remove Line',
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
              {
                paneType: PaneType.actionListPane,
                hidden: this.isUpdateMode && !updating,
                actionGroups: [
                  {
                    actions: [
                      {
                        actionType: ActionType.addArrayItemActionButton,
                        label: 'Add Defect',
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            title: 'Parts',
            dataAddr: 'parts',
            panes: [
              {
                paneType: PaneType.tablePane,
                mandatory: false,
                dataRequiredForRows: 'paneValue',
                fields: [
                  {
                    fieldType: FieldType.readonlyField,
                    label: 'Origin',
                    columnWidth: '9em',
                    linkTo: d => {
                      const p = d.parentValue as JobTaskPartItem;
                      return isFromPurchaseOrder(p)
                        ? `/workshop/purchase-orders/${p.purchaseOrder.id}`
                        : '';
                    },
                    formatReadonly: d => {
                      const p = d.parentValue as JobTaskPartItem;
                      return isFromPurchaseOrder(p)
                        ? `PO ${p.purchaseOrder.purchaseOrderNumber}`
                        : 'Task';
                    },
                  },
                  {
                    fieldType: FieldType.selectAsyncField,
                    label: 'Part Number',
                    dataAddr: 'part',
                    valueKey: 'id',
                    descriptionKey: 'partNumber',
                    menuWidth: 'fitContent',
                    columnWidth: '12em',
                    linkTo: d => `/workshop/parts/${d.fieldValue.id}`,
                    mandatory: true,
                    readonly: d => isFromPurchaseOrder(d.parentValue),
                    loadOptionItems: search => this.props.searchParts(search, true),
                    optionRenderer: (o: IMatchingPart) => (
                      <div style={{ whiteSpace: 'nowrap' }}>
                        {o.partNumber} - {o.description}
                      </div>
                    ),
                    onChange: api => {
                      const part = api.newFieldValue as PartSelectListItem;

                      api.setFormValue(
                        [api.fieldDataAddr[0], api.fieldDataAddr[1], 'description'],
                        part && part.description
                      );

                      api.setFormValue(
                        [api.fieldDataAddr[0], api.fieldDataAddr[1], 'unitPrice'],
                        part && part.latestPrice
                      );
                    },
                  },
                  {
                    fieldType: FieldType.textField,
                    label: 'Description',
                    dataAddr: 'description',
                    maxLength: 200,
                    mandatory: true,
                  },
                  {
                    fieldType: FieldType.dateField,
                    label: 'Used Date',
                    dataAddr: 'usedDate',
                    mandatory: _ => !!jobTask && jobTask.status.id === JobTaskStatus.Completed,
                    columnWidth: '10em',
                  },
                  {
                    fieldType: FieldType.numericField,
                    label: 'Quantity',
                    dataAddr: 'quantity',
                    columnWidth: '6em',
                    numericConfig: {
                      numericType: 'unsignedDecimal',
                      maxPointDigits: 4,
                    },
                    readonly: d => isFromPurchaseOrder(d.parentValue),
                    mandatory: true,
                    validate: d =>
                      d.fieldValue && Number.parseFloat(d.fieldValue.toString()) === 0
                        ? 'Quantity cannot be zero'
                        : undefined,
                  },
                  {
                    fieldType: FieldType.numericField,
                    label: 'Unit Price',
                    dataAddr: 'unitPrice',
                    readonly: d => isFromPurchaseOrder(d.parentValue),
                    mandatory: d =>
                      !!jobTask &&
                      jobTask.status.id === JobTaskStatus.Completed &&
                      !isFromPurchaseOrder(d.parentValue),
                    columnWidth: '6em',
                    numericConfig: {
                      numericType: 'unsignedDecimal',
                      maxPointDigits: 4,
                    },
                    formatReadonly: d =>
                      d.parentValue.unitPrice === undefined || d.parentValue.unitPrice === null
                        ? formatCurrency(d.parentValue.latestUnitPrice)
                        : formatCurrency(d.parentValue.unitPrice),
                  },
                  {
                    fieldType: FieldType.actionListField,
                    dataAddr: '',
                    columnWidth: '1px',
                    actionGroups: [
                      {
                        actions: [
                          {
                            hidden: d =>
                              isFromPurchaseOrder(d.parentValue) ||
                              (this.isUpdateMode && !updating),
                            actionType: ActionType.removeArrayItemActionButton,
                            label: 'Remove Line',
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
              {
                paneType: PaneType.actionListPane,
                hidden: this.isUpdateMode && !updating,
                actionGroups: [
                  {
                    actions: [
                      {
                        actionType: ActionType.addArrayItemActionButton,
                        label: 'Add Part',
                        getNewItemData: _ => {
                          return {
                            usedDate: DateTime.local().toISODate(),
                          };
                        },
                      },
                      {
                        actionType: ActionType.modalActionButton,
                        label: 'Add Parts Group',
                        icon: <OpenBoxIcon />,
                        modalSize: ShellModalSize.oneQuarter,
                        modalDef: getAddPartsGroupModalDef(
                          this.props.partsGroups,
                          this.props.getPartsGroupParts
                        ),
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            title: 'Labour',
            dataAddr: 'labour',
            panes: [
              {
                paneType: PaneType.tablePane,
                validate: d => {
                  const newFitForService = d.sectionValue.fitForService;

                  if (this.state.existingFitForService !== false || newFitForService !== true) {
                    return undefined;
                  }

                  const items: JobTaskLabourItem[] | undefined =
                    d.panelValue &&
                    (d.panelValue as JobTaskLabourItem[]).filter(
                      i => i.changeState !== ChangeState.Deleted
                    );

                  if (!items || items.length === 0) {
                    if (isDefined(newFitForService)) {
                      return 'Labour must be entered';
                    }
                    return undefined;
                  }

                  const distinctLabourByDate = distinct(
                    items.map(i => (i.staffMember && i.staffMember.id) + i.date)
                  );
                  return distinctLabourByDate.length !== items.length
                    ? 'Staff members cannot be entered twice on the same day'
                    : undefined;
                },
                mandatory: false,
                fields: [
                  {
                    fieldType: FieldType.selectField,
                    label: 'Staff Member',
                    dataAddr: 'staffMember',
                    valueKey: 'id',
                    descriptionKey: 'name',
                    mandatory: true,
                    optionItems: d =>
                      (this.state.isCleaningJob
                        ? this.props.allStaffMembers
                        : this.props.workshopStaff
                      ).filter(
                        o =>
                          o.active ||
                          (this.props.jobTask &&
                            this.props.jobTask.labour &&
                            this.props.jobTask.labour.some(l => l.staffMemberId === o.id))
                      ),
                  },
                  {
                    fieldType: FieldType.dateField,
                    label: 'Date',
                    dataAddr: 'date',
                    columnWidth: '11em',
                  },
                  {
                    fieldType: FieldType.numericField,
                    label: 'Hours',
                    dataAddr: 'hours',
                    columnWidth: '11em',
                    numericConfig: { numericType: 'signedDecimal', maxPointDigits: 2 },
                  },
                  {
                    fieldType: FieldType.actionListField,
                    dataAddr: '',
                    columnWidth: '1px',
                    actionGroups: [
                      {
                        actions: [
                          {
                            hidden: d => this.isUpdateMode && !updating,
                            actionType: ActionType.removeArrayItemActionButton,
                            label: 'Remove Line',
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
              {
                paneType: PaneType.actionListPane,
                hidden: this.isUpdateMode && !updating,
                actionGroups: [
                  {
                    actions: [
                      {
                        actionType: ActionType.addArrayItemActionButton,
                        label: 'Add Labour',
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            title: 'Cubic Changes',
            dataAddr: ['asset'],
            hidden: !showCubicRegisterTab || !this.isUpdateMode,
            panes: [
              {
                paneType: PaneType.tablePane,
                dataAddr: ['assetCubicSummaryItems'],
                fields: [
                  {
                    fieldType: FieldType.selectField,
                    dataAddr: 'cubicItemId',
                    label: 'Name',
                    readonly: true,
                    valueKey: 'id',
                    descriptionKey: 'description',
                    optionItems: this.props.cubicItems,
                    useValueOnly: true,
                  },
                  {
                    fieldType: FieldType.textField,
                    dataAddr: 'serialNumber',
                    label: 'Serial Number',
                    mandatory: false,
                    maxLength: 200,
                    readonly: d => !d.parentValue.isActive || d.parentValue.formSent,
                    formatReadonly: d =>
                      d.parentValue.jobTaskId === jobTask!.id ? (
                        <span>
                          <b>{d.fieldValue}</b>
                        </span>
                      ) : (
                        <span>{d.fieldValue}</span>
                      ),
                  },
                  {
                    fieldType: FieldType.selectField,
                    label: 'Fault Details/Reason',
                    dataAddr: 'cubicFaultDetailId',
                    valueKey: 'id',
                    useValueOnly: true,
                    mandatory: d =>
                      d.parentValue.serialNumber && this.jobTaskId === d.parentValue.jobTaskId,
                    descriptionKey: 'description',
                    menuWidth: 'fitContent',
                    optionItems: this.props.activeCubicFaultDetails,
                    formatReadonly: d =>
                      this.props.cubicFaultDetails?.find(
                        cfd => cfd.id === d.parentValue.cubicFaultDetailId
                      )?.description ?? '',
                    readonly: d => {
                      const cubicItem = this.props.cubicItems?.find(
                        ci => ci.id === d.parentValue.cubicItemId
                      );

                      return !cubicItem?.isActive;
                    },
                  },
                  {
                    fieldType: FieldType.yesNoField,
                    dataAddr: 'formSent',
                    label: 'Form Sent',
                    columnWidth: '7em',
                    readonly: true,
                    formatReadonly: d => (
                      <span>{!!d.fieldValue ? <CheckIcon fixedWidth /> : null}</span>
                    ),
                  },
                ],
              },
            ],
          },
        ],
        submitButtonDisabled: () => this.state.loadingSecondaryData,
        onFormPreSubmit: this.isUpdateMode
          ? this.handlePreSubmitForUpdate
          : this.handlePreSubmitForCreate,
        onFormSubmit: this.handleSubmit,
      },
      secondarySections: [
        {
          title: 'Other Work',
          panels: [
            {
              panes: [
                {
                  paneType: PaneType.customPane,
                  render: this.getJobTasksPane,
                },
                {
                  paneType: PaneType.customPane,
                  render: api => {
                    const defect = this.props.jobTask && this.props.jobTask.defect;

                    if (!defect) {
                      return;
                    }
                    const isInternal = defect.machineryDefectNumber === '';
                    return (
                      <div className="maintain-task-defect-details-grid">
                        <h3 className="header">
                          Source Defect for Task {this.props.jobTask!.jobTaskNumber}
                        </h3>
                        <div className="defect-number">
                          <strong>Defect </strong>
                          <Link to={`/workshop/defects/${defect.id}`}>{defect.defectNumber}</Link>
                        </div>
                        <div className="reported-by-label">Reported By:</div>
                        <div className="reported-by">{defect.reportedBy}</div>
                        <div className="reported-date-label">Reported Date:</div>
                        <div className="reported-date">
                          <DateFormat value={defect.reportedDate} />
                        </div>
                        <div className="repair-number-label" hidden={!isInternal}>
                          Repair Number:
                        </div>
                        <div className="machinery-defect-number-label" hidden={isInternal}>
                          Repair Number:
                        </div>
                        <div className="machinery-defect-number" hidden={isInternal}>
                          {defect.machineryDefectNumber}
                        </div>
                        <div className="machinery-severity-label" hidden={isInternal}>
                          Severity:
                        </div>
                        <div className="machinery-severity" hidden={isInternal}>
                          {defect.machinerySeverity}
                        </div>
                        <div className="machinery-completed-by-label" hidden={isInternal}>
                          Completed By:
                        </div>
                        <div className="machinery-completed-by" hidden={isInternal}>
                          {defect.machineryCompletedBy}
                        </div>
                      </div>
                    );
                  },
                },
                {
                  paneType: PaneType.customPane,
                  dataAddr: 'assetId',
                  render: api => {
                    return (
                      <>
                        <hr />
                        <h3 className="header">
                          Other Tasks for Asset{' '}
                          {(this.props.jobTask && this.props.jobTask.asset!.name) ||
                            this.state.selectedAssetName}
                        </h3>
                        <OtherWork
                          isUpdating={
                            this.props.jobTask !== undefined && this.props.jobTask.job !== null
                          }
                          nextService={nextService}
                          futureTasks={futureTasks}
                          scheduledTasks={scheduledTasks}
                          tasksInJob={
                            (this.props.jobTask &&
                              this.props.jobTask.job &&
                              this.props.jobTask.job.relatedTasks) ||
                            []
                          }
                          onSelectFutureTask={this.onSelectFutureTask}
                          onSelectServicePlan={this.onSelectServicePlan}
                          taskUpdatesBeingProcessed={this.state.tasksBeingProcessed}
                        />
                      </>
                    );
                  },
                },
              ],
            },
          ],
        },
        {
          title: 'Activity',
          explicitData: this.props.activityLogs,
          panels: [
            {
              panes: [
                {
                  paneType: PaneType.repeatingPane,
                  itemPanes: [
                    {
                      paneType: PaneType.customPane,
                      dataAddr: 'transactionId',
                      render: api => {
                        const log = api.data.parentValue as ActivityLogTransaction;
                        const createdOnDateTime = DateTime.fromISO(log.createdOn).toLocaleString(
                          DateTime.DATETIME_MED_WITH_SECONDS
                        );
                        return (
                          <div className="activity-log-for-job-task-pane">
                            <div className="created-by">{log.createdBy}</div>
                            <div className="created-on">{createdOnDateTime}</div>
                            <ul className="log-items list-unstyled">
                              {log.items.map((item: ActivityLogDetails) => {
                                const thisTaskLog = item.aggregateId === this.jobTaskId;
                                const linkedTaskLog =
                                  item.aggregateType === 'JobTask' &&
                                  item.aggregateId !== this.jobTaskId;
                                const defectLog = item.aggregateType === 'Defect';
                                return (
                                  <li className="log-item" key={item.activityLogId}>
                                    <div className="description" hidden={!thisTaskLog}>
                                      {item.description}
                                    </div>
                                    <div className="description log-link" hidden={!defectLog}>
                                      <Link to={`/workshop/defects/${item.aggregateId}`}>
                                        {item.description}
                                      </Link>
                                    </div>
                                    <div className="description log-link" hidden={!linkedTaskLog}>
                                      <Link to={`/workshop/tasks/${item.aggregateId}`}>
                                        {item.description}
                                      </Link>
                                    </div>
                                    <div className="comments">{item.comment}</div>
                                  </li>
                                );
                              })}
                            </ul>
                          </div>
                        );
                      },
                    },
                  ],
                },
              ],
            },
          ],
        },
        getAuditHistoryPanelDef(
          this.props.jobTask && this.props.jobTask.id,
          this.props.workshopStaff
        ),
        getComponentRegisterPanelDef(this.props.lastOdometerReading, this.props.searchParts),
        getAddAttachmentPanelDef(
          (this.props.jobTask && this.props.jobTask.id) || '',
          'task',
          this.props.addAttachmentToTask,
          this.props.loadAttachmentsForTask,
          this.props.downloadAttachmentForTask,
          this.props.deleteAttachmentFromTask,
          this.props.updateAttachmentForTask,
          this.props.attachments
        ),
      ],
    };
  };

  onSelectFutureTask = (task: FutureTaskForAsset) => {
    const job = this.props.jobDetails;

    if (!job) {
      return;
    }

    let scheduleTaskCommand: ScheduleFutureJobTaskCommand = {
      jobId: job.jobId,
      jobTaskId: task.id,
      startDateTime: job.startDateTime,
      endDateTime: job.endDateTime,
      depotId: job.depotId,
    };

    this.processTaskAction(task.id, this.props.onScheduleJobTaskWithoutReload(scheduleTaskCommand));
  };

  onUnscheduleJobTaskWithoutReload = (relatedTaskId: string) => {
    this.processTaskAction(
      relatedTaskId,
      this.props.onUnscheduleJobTaskWithoutReload(relatedTaskId)
    );
  };

  onSelectServicePlan = (servicePlan: AssetNextServiceDueItem) => {
    let job = this.props.jobDetails;
    let jobTask = this.props.jobTask;

    if (!job || !jobTask || !jobTask.asset) {
      return;
    }

    let scheduleServiceCommand: ScheduleServiceJobTaskCommand = {
      assetId: jobTask.asset.id,
      assetServiceScheduleId: servicePlan.id,
      jobId: job.jobId,
      startDateTime: job.startDateTime,
      endDateTime: job.endDateTime,
      depotId: job.depotId,
    };

    this.processTaskAction(
      servicePlan.id.toString(),
      this.props.onScheduleService(scheduleServiceCommand)
    );
  };

  processTaskAction(taskReferenceId: string, action: Promise<void>) {
    if (this.state.tasksBeingProcessed[taskReferenceId] === true) {
      // Prevent clicking again whilst a request is already in flight.
      return;
    }

    // Mark this task as 'processing'.
    this.setTaskProcessingStatus(taskReferenceId, true);

    // Perform the 'action', then wait for all the data to reload, then mark task as not 'processing'.
    action
      .then(() => this.loadPrimaryData())
      .then(() => this.loadSecondaryData())
      .finally(() => {
        this.setTaskProcessingStatus(taskReferenceId, false);
      });
  }

  getData = () => {
    return {
      ...this.props.jobTask,
      servicedByKm:
        (this.props.assetComponents &&
          this.props.assetComponents.components &&
          this.props.assetComponents.components.filter(
            x => x.componentType.serviceIntervalType === ComponentTypeServiceIntervalType.Km
          )) ||
        [],
      servicedByDate:
        (this.props.assetComponents &&
          this.props.assetComponents.components &&
          this.props.assetComponents.components.filter(
            x => x.componentType.serviceIntervalType === ComponentTypeServiceIntervalType.Day
          )) ||
        [],
    };
  };

  render() {
    const { mode, canManageTasks } = this.props;

    return (
      <CrudPage
        key={this.jobTaskId}
        def={({ updating }) => this.getPageDef(updating)}
        mode={mode}
        isEditingForbidden={!canManageTasks}
        onLoadData={this.loadData}
        data={this.getData()}
        createDefaultData={{
          category: {
            id: JobTaskCategory.General,
            description: jobTaskCategoryDescription(JobTaskCategory.General),
          },
          subcategoryId: GeneralJobTaskSubcategoryId,
          labour: [],
          parts: [],
          breakdown: false,
          incident: false,
          accident: false,
        }}
        onLoadCreateDefaultData={this.handleLoadCreateDefaultData}
      />
    );
  }
}

export default MaintainJobTask;
