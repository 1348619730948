import { flow, getRoot, types } from 'mobx-state-tree';
import { IRootStoreModel } from 'src/domain/entities/RootStoreModel';
import { getAjax } from 'src/domain/services';

type CreateStaffMemberCommand = People.Domain.Commands.StaffMembers.CreateStaffMember.CreateStaffMemberCommand;
type UpdateStaffMemberCommand = People.Domain.Commands.StaffMembers.UpdateStaffMember.UpdateStaffMemberCommand;
type StaffMemberItem = People.Domain.Queries.ViewStaffMember.StaffMemberItem;
type CreateStaffMemberLicenceCommand = People.Domain.Commands.StaffMembers.CreateStaffMemberLicence.CreateStaffMemberLicenceCommand;
type UpdateStaffMemberLicenceCommand = People.Domain.Commands.StaffMembers.UpdateStaffMemberLicence.UpdateStaffMemberLicenceCommand;
type DeleteStaffMemberLicenceCommand = People.Domain.Commands.StaffMembers.DeleteStaffMemberLicence.DeleteStaffMemberLicenceCommand;
type CreateLeaveCommand = People.Domain.Commands.Leaves.CreateLeave.CreateLeaveCommand;
type UpdateLeaveCommand = People.Domain.Commands.Leaves.UpdateLeave.UpdateLeaveCommand;
type CreateStaffMemberFileNoteCommand = People.Domain.Commands.StaffMembers.CreateStaffMemberFileNote.CreateStaffMemberFileNoteCommand;
type UpdateStaffMemberFileNoteCommand = People.Domain.Commands.StaffMembers.UpdateStaffMemberFileNote.UpdateStaffMemberFileNoteCommand;
type RemoveStaffMemberFromRoleCommand = People.Domain.Commands.Role.RemoveRole.RemoveStaffMemberFromRoleCommand;
type AssignStaffMemberToRoleCommand = People.Domain.Commands.Role.AddRole.AssignStaffMemberToRoleCommand;
type CreateStaffMemberEquipmentCommand = People.Domain.Commands.StaffMembers.Equipment.CreateStaffMemberEquipmentCommand;
type UpdateStaffMemberEquipmentCommand = People.Domain.Commands.StaffMembers.Equipment.UpdateStaffMemberEquipmentCommand;
type DeleteStaffMemberEquipmentCommand = People.Domain.Commands.StaffMembers.Equipment.DeleteStaffMemberEquipmentCommand;
type UpdateStaffMemberSkillSpecsCommand = People.Domain.Commands.StaffMembers.SkillSpecs.UpdateStaffMemberSkillSpecsCommand;

export const StaffMemberModel = types
  .model('StaffMemberModel', {
    staffMember: types.maybe(types.frozen<StaffMemberItem>()),
  })
  .views(self => ({}))
  .actions(self => {
    const ajax = getAjax(self);
    const root = getRoot(self) as IRootStoreModel;

    const createStaffMember = flow(function*(command: CreateStaffMemberCommand) {
      const id = yield ajax.people.staffMember.createStaffMember(command);
      self.staffMember = undefined;
      root.history.push(`/people/staff-members/${id}`);
    });

    const loadStaffMember = flow(function*(staffMemberId: string) {
      self.staffMember = yield ajax.people.staffMember.viewStaffMember(staffMemberId);
    });

    const updateStaffMember = flow(function*(command: UpdateStaffMemberCommand) {
      yield ajax.people.staffMember.updateStaffMember(command);
      yield loadStaffMember(command.id);
    });

    const markStaffMemberInactive = flow(function*(staffMemberId: string) {
      yield ajax.people.staffMember.markStaffMemberInactive(staffMemberId);
      yield loadStaffMember(staffMemberId);
    });

    const markStaffMemberActive = flow(function*(staffMemberId: string) {
      yield ajax.people.staffMember.markStaffMemberActive(staffMemberId);
      yield loadStaffMember(staffMemberId);
    });

    const checkForUniqueEmployeeId = flow(function*(employeeId: string) {
      return yield ajax.people.staffMember.checkForUniqueEmployeeId(employeeId);
    });

    const checkForUniqueEmail = flow(function*(email: string) {
      return yield ajax.people.staffMember.checkForUniqueEmail(email);
    });

    const createLicence = flow(function*(cmd: CreateStaffMemberLicenceCommand) {
      yield ajax.people.staffMember.createStaffMemberLicence(cmd);
      yield loadStaffMember(cmd.staffMemberId);
    });

    const updateLicence = flow(function*(cmd: UpdateStaffMemberLicenceCommand) {
      yield ajax.people.staffMember.updateStaffMemberLicence(cmd);
      yield loadStaffMember(cmd.staffMemberId);
    });

    const deleteLicence = flow(function*(cmd: DeleteStaffMemberLicenceCommand) {
      yield ajax.people.staffMember.deleteStaffMemberLicence(cmd);
      yield loadStaffMember(cmd.staffMemberId);
    });

    const createFileNote = flow(function*(cmd: CreateStaffMemberFileNoteCommand) {
      yield ajax.people.staffMember.createStaffMemberFileNote(cmd);
      yield loadStaffMember(cmd.staffMemberId);
    });

    const updateFileNote = flow(function*(cmd: UpdateStaffMemberFileNoteCommand) {
      yield ajax.people.staffMember.updateStaffMemberFileNote(cmd);
      yield loadStaffMember(cmd.staffMemberId);
    });

    const createEquipment = flow(function*(cmd: CreateStaffMemberEquipmentCommand) {
      yield ajax.people.staffMember.createStaffMemberEquipment(cmd);
      yield loadStaffMember(cmd.staffMemberId);
    });

    const updateEquipment = flow(function*(cmd: UpdateStaffMemberEquipmentCommand) {
      yield ajax.people.staffMember.updateStaffMemberEquipment(cmd);
      yield loadStaffMember(cmd.staffMemberId);
    });

    const deleteEquipment = flow(function*(cmd: DeleteStaffMemberEquipmentCommand) {
      yield ajax.people.staffMember.deleteStaffMemberEquipment(cmd);
      yield loadStaffMember(cmd.staffMemberId);
    });

    const updateSkillSpecs = flow(function*(cmd: UpdateStaffMemberSkillSpecsCommand) {
      yield ajax.people.staffMember.updateStaffMemberSkillSpecs(cmd);
      yield loadStaffMember(cmd.staffMemberId);
    });

    /*
      when calling the leave model actions for the below leave stuff instead of the ajax calls directly,
      if any of those action calls fail, the error is seemingly not intercepted properly e.g.
      the loaing spinner on the modal keeps being visible. So doing the ajax calls directly here until
      we find a workaround for that.
    */
    const createLeave = flow(function*(command: CreateLeaveCommand) {
      yield ajax.people.leave.createLeave(command);
      yield loadStaffMember(self.staffMember!.id);
    });

    const updateLeave = flow(function*(command: UpdateLeaveCommand) {
      yield ajax.people.leave.updateLeave(command);
      yield loadStaffMember(self.staffMember!.id);
    });

    const deleteLeave = flow(function*(leaveId: string, deleteRecurrences: boolean) {
      yield ajax.people.leave.deleteLeave(leaveId, deleteRecurrences, false);
      yield loadStaffMember(self.staffMember!.id);
    });

    const assignStaffMemberToRole = flow(function*(command: AssignStaffMemberToRoleCommand) {
      yield ajax.people.role.assignRoleToStaffMember(command);
      yield loadStaffMember(self.staffMember!.id);
    });

    const removeStaffMemberFromRole = flow(function*(command: RemoveStaffMemberFromRoleCommand) {
      yield ajax.people.role.removeStaffMemberFromRole(command);
      if (self.staffMember) {
        yield loadStaffMember(self.staffMember.id);
      }
    });

    return {
      createStaffMember,
      loadStaffMember,
      updateStaffMember,
      checkForUniqueEmployeeId,
      checkForUniqueEmail,
      markStaffMemberInactive,
      markStaffMemberActive,
      createLicence,
      updateLicence,
      deleteLicence,
      createFileNote,
      updateFileNote,
      createLeave,
      updateLeave,
      deleteLeave,
      assignStaffMemberToRole,
      removeStaffMemberFromRole,
      createEquipment,
      updateEquipment,
      deleteEquipment,
      updateSkillSpecs,
    };
  });
