// tslint:disable:no-any - Allow any in this file as the api is not strongly typed

import { Observable } from 'rxjs/Observable';
import { IFormChange } from './panel';
import { DataAddr } from './page';
import { FieldDefs } from './field';
import { IActionLinkDef, IActionGroupDef } from './action';

export enum PaneType {
  customPane = 'customPane',
  tablePane = 'tablePane',
  paginationPane = 'paginationPane',
  repeatingPane = 'repeatingPane',
  repeatingTabPane = 'repeatingTabPane',
  tabPane = 'tabPane',
  nestingPane = 'nestingPane',
  navListPane = 'navListPane',
  actionListPane = 'actionListPane',
  formFieldsPane = 'formFieldsPane',
}

export type PaneDefs =
  | ICustomPaneDef
  | ITablePaneDef
  | IPaginationPaneDef
  | IRepeatingPaneDef
  | IRepeatingTabPaneDef
  | ITabPaneDef
  | INestingPaneDef
  | INavListPaneDef
  | IActionListPaneDef
  | IFormFieldsPaneDef;

interface IPaneDef {
  hidden?: boolean | ((data: IPaneData) => boolean);
  dataAddr?: DataAddr;
}

export interface IPaneMeta {
  readonly: boolean;
  autoFocus: boolean;
  formChange$: Observable<IFormChange>;
  removeArrayItem?: () => void;
}

export interface IPaneData {
  paneValue: any;
  parentValue: any;
  panelValue: any;
  sectionValue: any;
}

// -----------------------------------------------------

export interface ICustomPaneDef extends IPaneDef {
  paneType: PaneType.customPane;
  render: (api: { data: IPaneData; meta: IPaneMeta }) => React.ReactNode;
}

export function isCustomPaneDef(def: PaneDefs): def is ICustomPaneDef {
  return def.paneType === PaneType.customPane;
}

// -----------------------------------------------------

export interface IPaginationPaneDef extends IPaneDef {
  paneType: PaneType.paginationPane;
  size?: number;
  page: number;
  totalResults: number;
  loadPage: (page: number) => Promise<void>;
}

export function isPaginationPaneDef(def: PaneDefs): def is IPaginationPaneDef {
  return def.paneType === PaneType.paginationPane;
}

// -----------------------------------------------------

export interface ITablePaneFieldExtrasDef {
  columnWidth?: string;
  orderBy?: string;
  /** Sets if the column should be hidden if all of its fields are hidden */
  columnAutoHide?: boolean | ((data: IPaneData) => boolean);
  minColumnWidth?: string;
  labelAlignRight?: boolean;
}

export interface ITablePaneDef extends IPaneDef {
  paneType: PaneType.tablePane;
  fields: Array<FieldDefs & ITablePaneFieldExtrasDef>;
  fieldRowOverrides?: (data: IPaneData & { itemValue: any }) => Array<FieldDefs | null>;
  deleteRemovedItems?: boolean; // Actually delete, rather than use ChangeState.deleted
  mandatory?: boolean;
  validate?: (data: IPaneData) => string | undefined;
  tableLayout?: 'auto' | 'fixed';
  loading?: boolean;
  hideRowsWhileLoading?: boolean; // Appears like no rows, but auto-layout table column widths don't jump
  title?: string | ((data: IPaneData) => string | undefined);
  noRowsMessage?: string;
  neverEditable?: boolean; // tables that can never be edited (ie. search lists) style differently to normal tables
  rowKey?: (data: IPaneData & { itemValue: any }) => string | number;
  rowSelectedKey?: string; // the name of the field that contains the truthy/falsy value to indicate row selection
  rowSelectionMode?: 'single'; // maybe support 'multi' one day
  /** Allows the override of an optimisation that passes the minimum data to the child rows */
  dataRequiredForRows?: 'none' | 'paneValue' | 'panelValue' | 'sectionValue'; // default 'none'
  tableDescription?: string;
  hideRemovedRows?: boolean; // Hide removed rows from the display
}

export function isTablePaneDef(def: PaneDefs): def is ITablePaneDef {
  return def.paneType === PaneType.tablePane;
}

// -----------------------------------------------------

export interface IRepeatingPaneDef extends IPaneDef {
  paneType: PaneType.repeatingPane;
  itemPanes: Array<PaneDefs>;
  enableHorizontalScroll?: boolean;
  deleteRemovedItems?: boolean; // Actually delete, rather than use ChangeState.deleted
  mandatory?: boolean;
  validate?: (data: IPaneData) => string | undefined;
  loading?: boolean;
  noItemsMessage?: string | null;
  useHover?: boolean;
  useDarkStyle?: boolean;
}

export function isRepeatingPaneDef(def: PaneDefs): def is IRepeatingPaneDef {
  return def.paneType === PaneType.repeatingPane;
}

// -----------------------------------------------------

export interface IRepeatingTabPaneDef extends IPaneDef {
  paneType: PaneType.repeatingTabPane;
  getItemTitle: (data: IPaneData & { itemValue: any; itemIndex: number }) => string;
  itemPanes: Array<PaneDefs>;
  deleteRemovedItems?: boolean; // Actually delete, rather than use ChangeState.deleted
  mandatory?: boolean;
  validate?: (data: IPaneData) => string | undefined;
  renderStyle?: 'up-front' | 'as-required'; // default is 'up-front' which renders all tabs initially
  allowReordering?: boolean;
}

export function isRepeatingTabPaneDef(def: PaneDefs): def is IRepeatingTabPaneDef {
  return def.paneType === PaneType.repeatingTabPane;
}

// -----------------------------------------------------

export interface ITabDef {
  title: string;
  panes: Array<PaneDefs>;
}

export interface ITabPaneDef extends IPaneDef {
  paneType: PaneType.tabPane;
  renderStyle?: 'up-front' | 'as-required'; // default is 'up-front' which renders all tabs initially
  tabs: Array<ITabDef>;
}

export function isTabPaneDef(def: PaneDefs): def is ITabPaneDef {
  return def.paneType === PaneType.tabPane;
}

// -----------------------------------------------------

export interface INestingPaneDef extends IPaneDef {
  paneType: PaneType.nestingPane;
  enableHorizontalScroll?: boolean;
  panes: Array<PaneDefs>;
  spaceBetweenPanesHorizontally?: boolean;
  spaceBetweenPanesVertically?: boolean;
}

export function isNestingPaneDef(def: PaneDefs): def is INestingPaneDef {
  return def.paneType === PaneType.nestingPane;
}

// -----------------------------------------------------

export interface INavListPaneDef extends IPaneDef {
  paneType: PaneType.navListPane;
  links: Array<IActionLinkDef>;
}

export function isNavListPaneDef(def: PaneDefs): def is INavListPaneDef {
  return def.paneType === PaneType.navListPane;
}

// -----------------------------------------------------

export interface IActionListPaneDef extends IPaneDef {
  paneType: PaneType.actionListPane;
  actionGroups: Array<IActionGroupDef>;
}

export function isActionListPaneDef(def: PaneDefs): def is IActionListPaneDef {
  return def.paneType === PaneType.actionListPane;
}

// -----------------------------------------------------

export interface IFormFieldsPaneDef extends IPaneDef {
  paneType: PaneType.formFieldsPane;
  fields: Array<FieldDefs>;
  columnCount?: number;
}

export function isFormFieldsPaneDef(def: PaneDefs): def is IFormFieldsPaneDef {
  return def.paneType === PaneType.formFieldsPane;
}

// -----------------------------------------------------
