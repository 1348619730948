import { buildListPageApiSearchModelTypedQuery } from 'src/domain/modelBuilders/buildListPageApiSearchModel';
import * as queryString from 'query-string';
import { flow, getRoot, types } from 'mobx-state-tree';
import { getAjax, NotificationType } from 'src/domain/services';
import { IRootStoreModel } from 'src/domain/entities/RootStoreModel';

type GetAssetOdometerReadingsQuery = Workshop.Domain.Queries.GetAssetOdometerReadings.GetAssetOdometerReadingsQuery;
type AssetOdometerReadingItem = Workshop.Domain.Queries.GetAssetOdometerReadings.AssetOdometerReadingItem;

export const AssetOdometerReadingsListModel = buildListPageApiSearchModelTypedQuery<
  GetAssetOdometerReadingsQuery
>()(
  'AssetOdometerReadingsListModel',
  d =>
    d.ajax.raw
      .get(`/api/workshop/assets/odometer-readings?${queryString.stringify(d.query)}`)
      .map(r => r.response as Common.Dtos.ListResult<AssetOdometerReadingItem>),
  { size: 1000 }
);

const ExportOdometerReadingsModel = types.model('ExportOdometerReadingsModel').actions(self => {
  const ajax = getAjax(self);
  const root = getRoot(self) as IRootStoreModel;

  const exportToExcel = flow(function*(query: Partial<GetAssetOdometerReadingsQuery>) {
    root.notifications.addNotification(`The file is being generated ...`, {
      type: NotificationType.info,
    });

    return yield ajax.raw
      .getFile(`/api/workshop/assets/odometer-readings/excel?${queryString.stringify(query)}`)
      .toPromise()
      .then<Blob>(r => r.response);
  });

  return {
    exportToExcel,
  };
});

export const AssetOdometerReadingsModel = types.compose(
  ExportOdometerReadingsModel,
  AssetOdometerReadingsListModel
);
