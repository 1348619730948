import './MaintainReturnedPartCredit.scss';
import { Component } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import {
  PagePrimarySize,
  PaneType,
  FieldType,
  ActionType,
  ShellModalSize,
} from 'src/views/definitionBuilders/types';
import CrudPage, { ICrudPageDef, CrudPageMode } from 'src/views/components/Page/pages/CrudPage';
import { ChangeState } from 'src/api/enums';
import { isArray } from 'util';
import { multiplyUnitPriceByQuantityArithmeticallySafe } from 'src/infrastructure/mathUtil';
import { formatCurrency } from 'src/infrastructure/formattingUtils';
import deepEqual from 'src/infrastructure/deepEqual';
import { BanIcon, CheckIcon, TrashIcon } from 'src/images/icons';
import { calculateInvoiceCreditNoteLinesTotals } from '../shared/purchaseOrderHelpers';
import { getSubmitCloseModalActionGroupDef } from 'src/views/definitionBuilders/common';

type PurchaseOrderItem = Workshop.Domain.Queries.Purchasing.PurchaseOrderItem;
type AssetItem = Workshop.Domain.Queries.AssetItem;
type JobTaskListItem = Workshop.Domain.Queries.JobTask.JobTaskListItem;
type CreditNoteItem = Workshop.Domain.Queries.Purchasing.CreditNoteItem;
type CreditNoteLineItem = Workshop.Domain.Queries.Purchasing.CreditNoteLineItem;
type CreateReturnedPartCreditCommand = Workshop.Domain.Commands.Purchasing.CreateReturnedPartCreditCommand;
type UpdateReturnedPartCreditCommand = Workshop.Domain.Commands.Purchasing.UpdateReturnedPartCreditCommand;

export interface IMaintainReturnedPartCreditProps {
  mode: CrudPageMode;
  canManagePurchaseOrders: boolean;
  purchaseOrder: PurchaseOrderItem | undefined;
  assets: AssetItem[];
  loadPurchaseOrder: (id: string) => Promise<void>;
  loadAssetListItems: () => Promise<void>;
  getJobTasksForAsset: (assetId: string) => Promise<JobTaskListItem[]>;
  createReturnedPartCredit: (command: CreateReturnedPartCreditCommand) => Promise<void>;
  updateReturnedPartCredit: (command: UpdateReturnedPartCreditCommand) => Promise<void>;
  deleteReturnedPartCredit: (
    purchaseOrderId: string,
    goodsReceivedId: number,
    creditNoteId: number,
    deleteReason: string
  ) => Promise<void>;
  onMarkCreditNoteAsExported: (
    id: string,
    goodsReceivedId: number,
    creditNoteId: number
  ) => Promise<void>;
  onRemoveExportedIndicatorFromCreditNote: (
    id: string,
    goodsReceivedId: number,
    creditNoteId: number
  ) => Promise<void>;
}

interface IMaintainReturnedPartState {
  jobTasksForAssetMap: { [key: string]: JobTaskListItem[] };
}

interface IMaintainReturnedPartRouteParams {
  purchaseOrderId: string;
  goodsReceivedId: string;
  creditNoteId: string;
}

type InternalProps = IMaintainReturnedPartCreditProps &
  RouteComponentProps<IMaintainReturnedPartRouteParams>;

class MaintainReturnedPartCredit extends Component<InternalProps, IMaintainReturnedPartState> {
  constructor(props: InternalProps) {
    super(props);
    this.state = { jobTasksForAssetMap: {} };
  }

  private get isUpdateMode() {
    return this.props.mode === 'update';
  }

  private get purchaseOrderId() {
    return this.props.match.params.purchaseOrderId;
  }

  private get goodsReceivedId() {
    return parseInt(this.props.match.params.goodsReceivedId, 10);
  }

  private get creditNoteId() {
    return parseInt(this.props.match.params.creditNoteId, 10);
  }

  private loadData = () => {
    return Promise.all([
      this.props.loadPurchaseOrder(this.purchaseOrderId),
      this.props.loadAssetListItems(),
    ]).then(_ => Promise.resolve());
  };

  private handlePreSubmitForCreate = (cn: CreditNoteItem) => {
    return {
      purchaseOrderId: this.purchaseOrderId,
      goodsReceivedId: this.goodsReceivedId,
      receivedDate: cn.receivedDate,
      creditNoteNumber: cn.creditNoteNumber,
      lines: cn.lines
        .filter(l => l.changeState !== ChangeState.Deleted)
        .map(l => {
          return {
            changeState: ChangeState.Added,
            goodsReceivedLineId: l.goodsReceivedLineId,
            description: l.description,
            quantity: l.quantity,
            creditAmount: cn.creditNoteNumber ? l.creditAmount : undefined,
            applyGst: l.applyGst,
            jobTaskId: l.jobTask && l.jobTask.id,
          };
        }),
      creditNoteDate:
        cn.creditNoteNumber && cn.creditNoteNumber.length ? cn.creditNoteDate : undefined,
    } as CreateReturnedPartCreditCommand;
  };

  private handlePreSubmitForUpdate = (cn: CreditNoteItem) => {
    const originalCreditNote = this.props
      .purchaseOrder!.receivedGoods.find(
        rg => rg.id.toString() === this.goodsReceivedId.toString()
      )!
      .creditNotes.find(c => c.id.toString() === this.creditNoteId.toString());
    const getChangeState = (li: CreditNoteLineItem) => {
      if (li.changeState === ChangeState.Added || li.changeState === ChangeState.Deleted) {
        return li.changeState;
      }
      const originalLineItem =
        originalCreditNote && originalCreditNote.lines.find(l => l.id === li.id);
      return deepEqual(li, originalLineItem) ? ChangeState.Unchanged : ChangeState.Modified;
    };

    return {
      purchaseOrderId: this.purchaseOrderId,
      goodsReceivedId: this.goodsReceivedId,
      creditNoteId: this.creditNoteId,
      receivedDate: cn.receivedDate,
      creditNoteNumber: cn.creditNoteNumber,
      lines: cn.lines.map(l => {
        return {
          changeState: getChangeState(l),
          goodsReceivedLineId: l.goodsReceivedLineId,
          id: l.id,
          description: l.description,
          quantity: l.quantity,
          creditAmount: cn.creditNoteNumber ? l.creditAmount : undefined,
          applyGst: l.applyGst,
          jobTaskId: l.jobTask && l.jobTask.id,
        };
      }),
      creditNoteDate:
        cn.creditNoteNumber && cn.creditNoteNumber.length ? cn.creditNoteDate : undefined,
    } as UpdateReturnedPartCreditCommand;
  };

  private readonly markAsExported = () => {
    return this.props.onMarkCreditNoteAsExported(
      this.purchaseOrderId,
      this.goodsReceivedId,
      this.creditNoteId
    );
  };

  private readonly removeExportedIndicator = () => {
    return this.props.onRemoveExportedIndicatorFromCreditNote(
      this.purchaseOrderId,
      this.goodsReceivedId,
      this.creditNoteId
    );
  };

  private readonly getPageDef = (isInEditMode: boolean): ICrudPageDef => {
    const { purchaseOrder, canManagePurchaseOrders } = this.props;

    const hasCreditNoteNumber = (item: CreditNoteItem) => !!item.creditNoteNumber;

    const getBadge = () => {
      if (!purchaseOrder || !this.creditNoteId || purchaseOrder.id !== this.purchaseOrderId)
        return undefined;
      const po = this.props.purchaseOrder;
      const gr = po!.receivedGoods.find(rg => rg.id.toString() === this.goodsReceivedId.toString());
      const cn = gr!.creditNotes.find(c => c.id.toString() === this.creditNoteId.toString());
      return cn?.exported ? { label: 'Exported' } : undefined;
    };

    const hideMarkAsExported = () => {
      if (
        !purchaseOrder ||
        !this.goodsReceivedId ||
        !this.creditNoteId ||
        !canManagePurchaseOrders ||
        purchaseOrder.id !== this.purchaseOrderId
      )
        return true;
      const cn = this.props
        .purchaseOrder!.receivedGoods.find(
          rg => rg.id.toString() === this.goodsReceivedId.toString()
        )!
        .creditNotes.find(c => c.id.toString() === this.creditNoteId.toString());
      return !cn?.creditNoteNumber || cn?.exported;
    };

    const hideRemoveExportedIndicator = () => {
      if (
        !purchaseOrder ||
        !this.goodsReceivedId ||
        !this.creditNoteId ||
        !canManagePurchaseOrders ||
        purchaseOrder.id !== this.purchaseOrderId
      )
        return true;
      const cn = this.props
        .purchaseOrder!.receivedGoods.find(
          rg => rg.id.toString() === this.goodsReceivedId.toString()
        )!
        .creditNotes.find(c => c.id.toString() === this.creditNoteId.toString());
      return !cn?.exported;
    };

    return {
      primarySize: PagePrimarySize.full,
      primarySection: {
        title: !this.isUpdateMode ? 'Receive Credit' : 'Received Credit',
        badge: getBadge(),
        primaryActions: [
          {
            actions: [
              {
                actionType: ActionType.actionCollection,
                actionGroups: [
                  {
                    actions: [
                      {
                        actionType: ActionType.modalActionButton,
                        hidden: !this.isUpdateMode,
                        label: 'Delete Returned Part Credit',
                        icon: <TrashIcon />,
                        modalSize: ShellModalSize.oneQuarter,
                        modalDef: modalDefApi => ({
                          title: 'Delete Returned Part Credit',
                          asForm: true,
                          panels: [
                            {
                              panes: [
                                {
                                  paneType: PaneType.customPane,
                                  render: api => (
                                    <p>
                                      Please provide the reason why you want to delete the returned
                                      part credit.
                                    </p>
                                  ),
                                },
                                {
                                  paneType: PaneType.formFieldsPane,
                                  fields: [
                                    {
                                      fieldType: FieldType.textField,
                                      dataAddr: 'deleteReason',
                                      label: 'Reason',
                                      mandatory: true,
                                      maxLength: 200,
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                          secondaryActions: [getSubmitCloseModalActionGroupDef('Ok')],
                          onFormSubmit: (values: { deleteReason: string }) =>
                            this.props.deleteReturnedPartCredit(
                              this.purchaseOrderId,
                              this.goodsReceivedId,
                              this.creditNoteId,
                              values.deleteReason
                            ),
                        }),
                      },
                      {
                        actionType: ActionType.actionButton,
                        label: 'Mark as Exported',
                        icon: <CheckIcon fixedWidth />,
                        onClick: this.markAsExported,
                        hidden: hideMarkAsExported(),
                      },
                      {
                        actionType: ActionType.actionButton,
                        label: 'Remove Exported Indicator',
                        icon: <BanIcon fixedWidth />,
                        onClick: this.removeExportedIndicator,
                        hidden: hideRemoveExportedIndicator(),
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
        panels: [
          {
            panes: [
              {
                paneType: PaneType.formFieldsPane,
                columnCount: 3,
                fields: [
                  {
                    fieldType: FieldType.readonlyField,
                    label: 'Supplier',
                    dataAddr: 'supplierName',
                  },
                ],
              },
              {
                paneType: PaneType.formFieldsPane,
                columnCount: 3,
                fields: [
                  {
                    fieldType: FieldType.dateField,
                    label: 'Date Received',
                    dataAddr: 'receivedDate',
                    mandatory: true,
                  },
                  {
                    fieldType: FieldType.textField,
                    dataAddr: 'creditNoteNumber',
                    label: 'Credit Note Number',
                    maxLength: 50,
                    mandatory: _ => false,
                    onChange: d =>
                      !d.fieldData.fieldValue || !d.fieldData.fieldValue.length
                        ? d.setFormValue(['creditNoteDate'], undefined)
                        : {},
                  },
                  {
                    fieldType: FieldType.dateField,
                    label: 'Credit Note Date',
                    dataAddr: 'creditNoteDate',
                    hidden: d =>
                      !d.parentValue.creditNoteNumber || !d.parentValue.creditNoteNumber.length,
                  },
                ],
              },
            ],
          },
          {
            title: 'Lines',
            dataAddr: 'lines',
            panes: [
              {
                paneType: PaneType.tablePane,
                mandatory: true,
                dataRequiredForRows: 'sectionValue',
                fields: [
                  {
                    fieldType: FieldType.readonlyField,
                    label: 'Part Number',
                    dataAddr: ['part', 'partNumber'],
                    columnWidth: '12em',
                    linkTo: d => `/workshop/parts/${d.parentValue.part.id}`,
                  },
                  {
                    fieldType: FieldType.textField,
                    label: 'Description',
                    dataAddr: 'description',
                    mandatory: true,
                    maxLength: 200,
                  },
                  {
                    fieldType: FieldType.numericField,
                    label: 'Received Qty',
                    dataAddr: 'receivedQuantity',
                    columnWidth: '8em',
                    readonly: true,
                    numericConfig: { numericType: 'unsignedDecimal', maxPointDigits: 4 },
                  },
                  {
                    fieldType: FieldType.numericField,
                    label: 'Returned Qty',
                    dataAddr: 'quantity',
                    columnWidth: '8em',
                    numericConfig: { numericType: 'unsignedDecimal', maxPointDigits: 4 },
                    mandatory: true,
                    validate: d => {
                      const line = d.parentValue as CreditNoteLineItem;
                      if (!line.quantity) {
                        return undefined;
                      }
                      const returnedQuantity = Number.parseFloat(line.quantity.toString());
                      if (returnedQuantity === 0) {
                        return 'Returned quantity cannot be 0';
                      }
                      if (returnedQuantity > d.parentValue.receivedQuantity) {
                        return 'Cannot return more than received quantity';
                      }
                      return undefined;
                    },
                  },
                  {
                    fieldType: FieldType.numericField,
                    label: 'Unit Price',
                    dataAddr: 'unitPrice',
                    columnWidth: '9em',
                    numericConfig: { numericType: 'unsignedDecimal', maxPointDigits: 5 },
                    readonly: true,
                    formatReadonly: 'unitPrice',
                  },
                  {
                    fieldType: FieldType.yesNoField,
                    label: 'Apply GST',
                    dataAddr: 'applyGst',
                    columnWidth: '9em',
                    readonly: d => !hasCreditNoteNumber(d.sectionValue),
                    mandatory: d => hasCreditNoteNumber(d.sectionValue),
                  },
                  {
                    fieldType: FieldType.numericField,
                    label: 'Credit Amount',
                    dataAddr: 'creditAmount',
                    columnWidth: '9em',
                    numericConfig: { numericType: 'unsignedDecimal', maxPointDigits: 5 },
                    readonly: d => !hasCreditNoteNumber(d.sectionValue),
                    mandatory: d => hasCreditNoteNumber(d.sectionValue),
                    formatReadonly: 'unitPrice',
                  },
                  {
                    fieldType: FieldType.readonlyField,
                    label: 'Total',
                    columnWidth: '6em',
                    formatReadonly: d => {
                      const line = d.fieldValue as CreditNoteLineItem;
                      if (!line.quantity || !line.creditAmount) {
                        return undefined;
                      }
                      return multiplyUnitPriceByQuantityArithmeticallySafe(
                        line.quantity,
                        line.creditAmount
                      ).value;
                    },
                  },
                  {
                    fieldType: FieldType.assetSelectField,
                    label: 'Asset',
                    dataAddr: 'asset',
                    columnWidth: '8em',
                    valueKey: 'id',
                    descriptionKey: 'name',
                    optionItems: this.props.assets,
                    readonly: true,
                  },
                  {
                    fieldType: FieldType.selectField,
                    label: 'Task',
                    dataAddr: 'jobTask',
                    columnWidth: '8em',
                    valueKey: 'id',
                    descriptionKey: 'jobTaskNumber',
                    linkTo: d => `/workshop/tasks/${d.parentValue.jobTask.id}`,
                    optionItems: d => {
                      const line = d.parentValue as CreditNoteLineItem;
                      const assetId = line && line.asset && line.asset.id;
                      return this.state.jobTasksForAssetMap[assetId];
                    },
                    readonly: true,
                  },
                  {
                    fieldType: FieldType.actionListField,
                    columnWidth: '1px',
                    actionGroups: [
                      {
                        actions: [
                          {
                            actionType: ActionType.removeArrayItemActionButton,
                            label: 'Remove Line',
                            hidden: _ => this.isUpdateMode && !isInEditMode,
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
              {
                paneType: PaneType.customPane,
                render: api => {
                  const lines = api.data.panelValue as CreditNoteLineItem[];
                  if (!isArray(lines)) {
                    return undefined;
                  }

                  const { gst, subtotal, totalInclGst } = calculateInvoiceCreditNoteLinesTotals(
                    lines
                  );

                  return (
                    <table className="maintain-returned-part-credit-totals">
                      <tr>
                        <td>
                          <label>Subtotal:</label>
                        </td>
                        <td className="price">{formatCurrency(subtotal.value)}</td>
                      </tr>
                      <tr>
                        <td>
                          <label>GST:</label>
                        </td>
                        <td className="price">{formatCurrency(gst.value)}</td>
                      </tr>
                      <tr>
                        <td>
                          <label>Total:</label>
                        </td>
                        <td className="price">{formatCurrency(totalInclGst.value)}</td>
                      </tr>
                    </table>
                  );
                },
              },
            ],
          },
        ],
        onFormPreSubmit: this.isUpdateMode
          ? this.handlePreSubmitForUpdate
          : this.handlePreSubmitForCreate,
        onFormSubmit: this.isUpdateMode
          ? this.props.updateReturnedPartCredit
          : this.props.createReturnedPartCredit,
      },
    };
  };

  render() {
    const { purchaseOrder, mode, canManagePurchaseOrders } = this.props;
    const goodsReceived =
      purchaseOrder &&
      purchaseOrder.receivedGoods.find(g => g.id.toString() === this.goodsReceivedId.toString());

    const getEditData = () => {
      if (!goodsReceived || !this.creditNoteId) {
        return undefined;
      }

      const creditNote = goodsReceived.creditNotes.find(
        cn => cn.id.toString() === this.creditNoteId.toString()
      );
      if (!creditNote) {
        return undefined;
      }
      return {
        supplierName: purchaseOrder && purchaseOrder.supplier.name,
        receivedDate: creditNote.receivedDate,
        creditNoteNumber: creditNote.creditNoteNumber,
        lines: creditNote.lines.map(l => {
          return {
            id: l.id,
            goodsReceivedLineId: l.goodsReceivedLineId,
            part: l.part,
            description: l.description,
            receivedQuantity: goodsReceived.lines.find(grl => grl.id === l.goodsReceivedLineId)!
              .quantity,
            unitPrice: goodsReceived.lines.find(grl => grl.id === l.goodsReceivedLineId)!.unitPrice,
            quantity: l.quantity,
            creditAmount: l.creditAmount,
            applyGst: l.applyGst,
            asset: l.asset,
            jobTask: l.jobTask,
          };
        }),
        creditNoteDate: creditNote.creditNoteDate,
      };
    };

    const getCreateData = () => {
      if (!purchaseOrder || !goodsReceived) {
        return undefined;
      }
      return {
        supplierName: purchaseOrder.supplier.name,
        lines: goodsReceived.lines.map(l => {
          return {
            goodsReceivedLineId: l.id,
            part: l.part,
            description: l.description,
            receivedQuantity: l.quantity,
            unitPrice: l.unitPrice,
            applyGst: l.applyGst,
            asset: l.asset,
            jobTask: l.jobTask,
          };
        }),
      };
    };

    return (
      <CrudPage
        def={({ updating }) => this.getPageDef(updating)}
        mode={mode}
        isEditingForbidden={!canManagePurchaseOrders}
        onLoadData={this.loadData}
        data={getEditData()}
        onLoadCreateDefaultData={this.loadData}
        createDefaultData={getCreateData()}
      />
    );
  }
}

export default MaintainReturnedPartCredit;
