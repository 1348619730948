import { DateTime } from 'luxon';
import { useEffect, useRef } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { allWorkshopShiftType } from 'src/api/enums';
import { ListPageLoadCause } from 'src/domain/baseTypes';
import { TrashIcon } from 'src/images/icons';
import { getNextDateTime, validateDateTimeIsNotLessThan } from 'src/infrastructure/dateUtils';
import {
  getEditingFormattedTimeString,
  parseEditingFormattedTimeString,
} from 'src/views/components/Page/fields/subfields/TimeHelpers';
import CrudPage, { CrudPageMode, ICrudPageDef } from 'src/views/components/Page/pages/CrudPage';
import { getSubmitCloseModalActionGroupDef } from 'src/views/definitionBuilders/common';
import {
  ActionType,
  FieldType,
  IFieldData,
  IFieldOnChange,
  PagePrimarySize,
  PaneType,
  ShellModalSize,
} from 'src/views/definitionBuilders/types';
import {
  formattedWorkingJobHours,
  workingJobHours,
} from 'src/views/routes/operations/urban/urbanHelpers';
import getConflictsSectionDef from './getConflictsSectionDef';

import { observer } from 'mobx-react';
import { useRootStore } from 'src/domain/entities/RootStoreModel';
import './MaintainShift.scss';

type CreateShiftCommand = Workshop.Domain.Commands.Shift.CreateShift.CreateShiftCommand;
type UpdateShiftCommand = Workshop.Domain.Commands.Shift.UpdateShift.UpdateShiftCommand;
type ShiftItem = Workshop.Domain.Queries.Shift.GetShift.ShiftItem;
type RosterAutocompleteListItem = Workshop.Domain.Queries.Roster.AutocompleteListRosters.RosterAutocompleteListItem;

export interface IMaintainShiftProps {
  mode: CrudPageMode;
  route: RouteComponentProps<{ [x: string]: string | undefined }>;
}

type IMaintainShiftForm = ShiftItem & {
  staffMemberId: string;
  roster: RosterAutocompleteListItem;
  depotId: number;
  depotDescription: string;
};

const MaintainShift: React.FC<IMaintainShiftProps> = observer((props: IMaintainShiftProps) => {
  const rootStore = useRootStore();
  const canManageShifts = rootStore.account.isWorkshopDepartmentMember;

  const loadShift = rootStore.workshop.shift.loadShift;
  const shift = rootStore.workshop.shift.shift;
  const createShift = rootStore.workshop.shift.createShift;
  const updateShift = rootStore.workshop.shift.updateShift;
  const deleteShift = rootStore.workshop.shift.deleteShift;

  const mechanics = rootStore.people.staffMembers.mechanics.slice();
  const loadMechanics = rootStore.people.staffMembers.loadMechanics;

  const listShiftTemplates = rootStore.workshop.shiftTemplates.listItems;
  const shiftTemplates = rootStore.workshop.shiftTemplates.items.slice();

  const autocompleteRosters = rootStore.workshop.rosters.autocompleteRosters;

  const acceptConflict = rootStore.workshop.conflicts.acceptConflict;
  const cancelAcceptanceConflict = rootStore.workshop.conflicts.cancelAcceptanceConflict;

  const workshopDepots = rootStore.workshopStartup.workshopDepots.slice();
  const defaultWorkshopDepot = rootStore.workshopStartup.defaultWorkshopDepot;
  const prevShiftId = useRef(props.route.match.params.id);
  const isUpdateMode = props.mode === 'update';
  const isCreateMode = props.mode === 'create';
  const shiftId = props.route.match.params.id!;

  useEffect(() => {
    loadMechanics();
    listShiftTemplates({ loadCause: ListPageLoadCause.mount });
  }, []);

  useEffect(() => {
    if (isUpdateMode && prevShiftId.current !== shiftId) {
      loadShift(shiftId);
    }
  }, [shiftId]);

  const handlePreSubmitForCreate = (shiftForm: IMaintainShiftForm): CreateShiftCommand => {
    const command: CreateShiftCommand = {
      rosterId: shiftForm.roster.rosterId,
      shiftTemplateId: shiftForm.shiftTemplateId,
      shiftCommence: shiftForm.shiftCommence,
      shiftEnd: shiftForm.shiftEnd,
      unpaidBreaks: shiftForm.unpaidBreaks,
      staffMemberId: shiftForm.staffMemberId,
      shiftType: shiftForm.shiftType,
      depotId: shiftForm.depotId,
    };
    return command;
  };

  const handlePreSubmitForUpdate = (shiftForm: IMaintainShiftForm): UpdateShiftCommand => {
    const command: UpdateShiftCommand = {
      id: shiftForm.id,
      shiftTemplateId: shiftForm.shiftTemplateId,
      shiftCommence: shiftForm.shiftCommence,
      shiftEnd: shiftForm.shiftEnd,
      unpaidBreaks: shiftForm.unpaidBreaks,
      staffMemberId: shiftForm.staffMemberId,
      mechanicClockOn: shiftForm.mechanicClockOn,
      mechanicClockOff: shiftForm.mechanicClockOff,
      mechanicUnpaidBreaks: shiftForm.mechanicUnpaidBreaks,
      shiftType: shiftForm.shiftType,
      boundsChangeReason: shiftForm.boundsChangeReason,
      overtimeReason: shiftForm.overtimeReason,
    };
    return command;
  };

  const shiftData = (shift: ShiftItem | undefined): IMaintainShiftForm => {
    if (!shift) {
      return {} as IMaintainShiftForm;
    }

    return {
      ...shift,
      staffMemberId: shift.staffMember.staffMemberId,
      roster: {
        rosterId: shift.rosterId,
        rosterName: shift.rosterName,
        depotDescription: shift.depotDescription,
      },
      depotId: shift.depotId,
      depotDescription: shift.depotDescription,
    };
  };

  const onShiftTemplateChange = (api: IFieldOnChange<string | undefined>) => {
    if (!(api.newFieldValue && shiftTemplates)) {
      return;
    }
    const shiftTemplate = shiftTemplates.find(x => x.shiftTemplateId === api.newFieldValue);
    if (!shiftTemplate) {
      return;
    }
    let startDate = DateTime.local();
    if (api.formValues.shiftCommence) {
      startDate = DateTime.fromISO(api.formValues.shiftCommence);
    }
    const shiftCommence = getNextDateTime(startDate.startOf('day'), shiftTemplate.shiftCommence);
    const shiftEnd = getNextDateTime(shiftCommence, shiftTemplate.shiftEnd);
    const newValues: IMaintainShiftForm = {
      ...api.formValues,
      shiftCommence,
      shiftEnd,
      unpaidBreaks: shiftTemplate.unpaidBreaks,
      shiftType: shiftTemplate.shiftType,
    };
    api.setFormValues(newValues);
  };

  const getPageDef = (readonly: boolean): ICrudPageDef => {
    const isBoundsReasonHidden = (d: IFieldData<string>) => {
      if (!isUpdateMode) {
        return true;
      }

      if (!shift) {
        return true;
      }

      const existingValue = shift.boundsChangeReason;
      if (existingValue) {
        return false;
      }

      const currentShiftCommence = DateTime.fromISO(d.parentValue.shiftCommence);
      const currentShiftEnd = DateTime.fromISO(d.parentValue.shiftEnd);

      const originalCommence = DateTime.fromISO(shift.shiftCommence);
      const originalEnd = DateTime.fromISO(shift.shiftEnd);

      return currentShiftCommence.equals(originalCommence) && currentShiftEnd.equals(originalEnd);
    };
    const shiftNumber = shift?.shiftNumber;
    return {
      primarySize: PagePrimarySize.threeQuarters,
      primarySection: {
        title: isUpdateMode ? `Shift ${shiftNumber}` : 'Create a Shift ',
        primaryActions: [
          {
            actions: [
              {
                actionType: ActionType.actionCollection,
                hidden: !readonly || !canManageShifts,
                actionGroups: [
                  {
                    actions: [
                      {
                        actionType: ActionType.modalActionButton,
                        label: 'Delete Shift ',
                        icon: <TrashIcon />,
                        hidden: !shift,
                        modalSize: ShellModalSize.oneQuarter,
                        modalDef: () => ({
                          title: 'Delete Shift ',
                          asForm: true,
                          panels: [
                            {
                              panes: [
                                {
                                  paneType: PaneType.customPane,
                                  render: () => (
                                    <div>
                                      <p>Are you sure you want to delete this Shift ?</p>
                                    </div>
                                  ),
                                },
                              ],
                            },
                          ],
                          secondaryActions: [getSubmitCloseModalActionGroupDef('Delete')],
                          onFormSubmit: () => deleteShift(shiftId),
                        }),
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
        panels: [
          {
            panes: [
              {
                paneType: PaneType.formFieldsPane,
                columnCount: 2,
                fields: [
                  {
                    fieldType: FieldType.selectField,
                    label: 'Shift Name',
                    dataAddr: 'shiftTemplateId',
                    valueKey: 'shiftTemplateId',
                    descriptionKey: 'shiftName',
                    mandatory: true,
                    useValueOnly: true,
                    optionItems: shiftTemplates,
                    linkTo: d => `/workshop/shift-templates/${d.parentValue.shiftTemplateId}`,
                    onChange: api => onShiftTemplateChange(api),
                  },
                ],
              },
              {
                paneType: PaneType.formFieldsPane,
                columnCount: 2,
                fields: [
                  {
                    fieldType: FieldType.selectField,
                    label: 'Depot',
                    dataAddr: 'depotId',
                    valueKey: 'id',
                    descriptionKey: 'description',
                    mandatory: true,
                    useValueOnly: true,
                    optionItems: workshopDepots,
                    readonly: workshopDepots.length < 2 || !isCreateMode,
                    onChange: d => {
                      const values = { ...d.formValues, roster: undefined };
                      d.setFormValues(values);
                    },
                  },
                  {
                    fieldType: FieldType.selectAsyncField,
                    label: 'Roster',
                    dataAddr: 'roster',
                    valueKey: 'rosterId',
                    descriptionKey: 'rosterName',
                    mandatory: true,
                    useValueOnly: false,
                    getFieldKey: d => d.sectionValue.depotId,
                    loadOptionItems: async (s, d) =>
                      await autocompleteRosters(s, [d.parentValue.depotId]),
                    readonly: d => isUpdateMode || d.parentValue.depotId === undefined,
                    linkTo: d => `/workshop/rosters/${d.parentValue.rosterId}`,
                  },
                ],
              },
              {
                paneType: PaneType.formFieldsPane,
                columnCount: 2,
                fields: [
                  {
                    fieldType: FieldType.selectField,
                    label: 'Shift type',
                    dataAddr: 'shiftType',
                    valueKey: 'value',
                    descriptionKey: 'description',
                    optionItems: allWorkshopShiftType,
                    useValueOnly: true,
                    mandatory: true,
                  },
                ],
              },
              {
                paneType: PaneType.formFieldsPane,
                columnCount: 3,
                fields: [
                  {
                    fieldType: FieldType.dateTimeField,
                    label: 'Shift Commence',
                    dataAddr: 'shiftCommence',
                    mandatory: true,
                    validate: d =>
                      validateDateTimeIsNotLessThan(
                        d.parentValue.shiftEnd,
                        'Shift end',
                        d.parentValue.shiftCommence,
                        'shift commence'
                      ),
                  },
                  {
                    fieldType: FieldType.dateTimeField,
                    label: 'Shift End',
                    dataAddr: 'shiftEnd',
                    mandatory: true,
                    validate: d =>
                      validateDateTimeIsNotLessThan(
                        d.parentValue.shiftEnd,
                        'Shift end',
                        d.parentValue.shiftCommence,
                        'shift commence'
                      ),
                  },
                  {
                    fieldType: FieldType.durationField,
                    label: 'Unpaid Breaks',
                    dataAddr: 'unpaidBreaks',
                    mandatory: true,
                    validate: data => {
                      const shift = data.parentValue as ShiftItem;
                      const workingHours = workingJobHours(
                        shift.shiftCommence,
                        shift.shiftEnd,
                        shift.unpaidBreaks
                      );
                      return !workingHours || workingHours.valueOf() <= 0
                        ? 'Unpaid breaks must be less than total time'
                        : undefined;
                    },
                    formatReadonly: d =>
                      getEditingFormattedTimeString(parseEditingFormattedTimeString(d.fieldValue)),
                  },
                  {
                    fieldType: FieldType.readonlyField,
                    label: 'Working Time',
                    formatReadonly: data => {
                      const shift = data.parentValue as ShiftItem;
                      return formattedWorkingJobHours(
                        shift.shiftCommence,
                        shift.shiftEnd,
                        shift.unpaidBreaks
                      );
                    },
                  },
                ],
              },
              {
                paneType: PaneType.formFieldsPane,
                fields: [
                  {
                    fieldType: FieldType.textAreaField,
                    label: 'Bounds Change Reason',
                    dataAddr: 'boundsChangeReason',
                    mandatory: true,
                    hidden: d => isBoundsReasonHidden(d),
                  },
                  {
                    fieldType: FieldType.customField,
                    dataAddr: 'none',
                    label: ' ',
                    hidden: d => readonly || isBoundsReasonHidden(d),
                    render: () => (
                      <div className="maintain-shift-custom-message">
                        <label>Please Note: </label> This will be visible on mechanics' timesheets.
                      </div>
                    ),
                  },
                ],
              },
              {
                paneType: PaneType.formFieldsPane,
                columnCount: 2,
                fields: [
                  {
                    fieldType: FieldType.selectField,
                    label: 'Staff Member',
                    dataAddr: 'staffMemberId',
                    linkTo: d => `/people/staff-members/${d.parentValue.staffMemberId}`,
                    optionItems: mechanics.filter(x => x.active),
                    valueKey: 'id',
                    descriptionKey: 'name',
                    useValueOnly: true,
                    mandatory: true,
                    includeKey: 'active',
                  },
                ],
              },
            ],
          },
          {
            title: 'Completion Details',
            panes: [
              {
                paneType: PaneType.formFieldsPane,
                columnCount: 3,
                fields: [
                  {
                    fieldType: FieldType.dateTimeField,
                    label: 'Mechanic Clock On',
                    dataAddr: 'mechanicClockOn',
                    validate: d =>
                      validateDateTimeIsNotLessThan(
                        d.parentValue.mechanicClockOff,
                        'Mechanic Clock Off',
                        d.parentValue.mechanicClockOn,
                        'Mechanic Clock On'
                      ),
                    mandatory: d => {
                      if (
                        !!d.parentValue.mechanicClockOff ||
                        !!d.parentValue.mechanicUnpaidBreaks
                      ) {
                        return true;
                      }
                      return false;
                    },
                  },
                  {
                    fieldType: FieldType.dateTimeField,
                    label: 'Mechanic Clock Off',
                    dataAddr: 'mechanicClockOff',
                    validate: d =>
                      validateDateTimeIsNotLessThan(
                        d.parentValue.mechanicClockOff,
                        'Mechanic Clock Off',
                        d.parentValue.mechanicClockOn,
                        'Mechanic Clock On'
                      ),
                    mandatory: d => {
                      if (!!d.parentValue.mechanicClockOn || !!d.parentValue.mechanicUnpaidBreaks) {
                        return true;
                      }
                      return false;
                    },
                  },
                  {
                    fieldType: FieldType.durationField,
                    label: 'Mechanic Unpaid Breaks',
                    dataAddr: 'mechanicUnpaidBreaks',
                    validate: data => {
                      const shift = data.parentValue as ShiftItem;
                      const workingHours = workingJobHours(
                        shift.mechanicClockOn || shift.shiftCommence,
                        shift.mechanicClockOff || shift.shiftEnd,
                        shift.mechanicUnpaidBreaks || shift.unpaidBreaks
                      );
                      return !workingHours || workingHours.valueOf() <= 0
                        ? 'Mechanic Unpaid breaks must be less than total time'
                        : undefined;
                    },
                    formatReadonly: d =>
                      getEditingFormattedTimeString(parseEditingFormattedTimeString(d.fieldValue)),
                    mandatory: d => {
                      if (!!d.parentValue.mechanicClockOn || !!d.parentValue.mechanicClockOff) {
                        return true;
                      }
                      return false;
                    },
                  },
                  {
                    fieldType: FieldType.readonlyField,
                    label: 'Mechanic Working Time',
                    formatReadonly: data => {
                      const shift = data.parentValue as ShiftItem;
                      return formattedWorkingJobHours(
                        shift.mechanicClockOn || shift.shiftCommence,
                        shift.mechanicClockOff || shift.shiftEnd,
                        shift.mechanicUnpaidBreaks || shift.unpaidBreaks
                      );
                    },
                  },
                  {
                    fieldType: FieldType.textAreaField,
                    label: 'Overtime Reason',
                    dataAddr: 'overtimeReason',
                  },
                ],
              },
            ],
          },
        ],
        onFormPreSubmit: isUpdateMode ? handlePreSubmitForUpdate : handlePreSubmitForCreate,
        onFormSubmit: isUpdateMode ? updateShift : createShift,
      },
      secondarySections: [
        getConflictsSectionDef(
          canManageShifts,
          shiftId,
          acceptConflict,
          cancelAcceptanceConflict,
          () => loadShift(shiftId)
        ),
      ],
    };
  };

  return defaultWorkshopDepot ? (
    <CrudPage
      def={api => getPageDef(api.readonly)}
      mode={props.mode}
      isEditingForbidden={!canManageShifts}
      onLoadData={() => loadShift(shiftId)}
      data={shiftData(shift)}
      createDefaultData={{ depotId: defaultWorkshopDepot?.id, options: [] }}
    />
  ) : null;
});

export default MaintainShift;
