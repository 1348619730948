import { IRootStoreModel } from 'src/domain/entities/RootStoreModel';
import { UiRoute } from 'src/domain/services/buildUiRoutes';
import { UiRouteName } from 'src/domain/baseTypes';

export default function buildUiRoutes(store: IRootStoreModel) {
  const purchaseTransactionsList = new UiRoute(
    UiRouteName.workshopPurchaseTransactionsList,
    '/workshop/purchase-transactions',
    () => 'Purchase Transactions',
    false,
    false
  );

  return {
    [UiRouteName.workshopPurchaseTransactionsList]: purchaseTransactionsList,
  };
}
