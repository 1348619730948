import inject from 'src/views/injectFromStore';
import MaintainJobProgress, {
  IMaintainJobProgressProps,
} from '../../../../components/MaintainJobProgress';
import Omit from 'src/infrastructure/omit';
import { RouteComponentProps } from 'react-router';

type OpsJobProgressCommand = Operations.Domain.Commands.Job.UpdateJobProgress.UpdateJobProgressCommand;
type UpdateJobProgressCommand =
  | Operations.Domain.Commands.Job.UpdateJobProgress.UpdateJobProgressCommand
  | Workshop.Domain.Commands.Job.UpdateWorkshopJobProgressCommand;

const Container = inject<
  Omit<IMaintainJobProgressProps, 'mode'>,
  IMaintainJobProgressProps & RouteComponentProps<{ id: string }>
>((allStores, p) => {
  const jobModel = allStores.rootStore.operations.job;
  const assetsModel = allStores.rootStore.assets;

  return {
    canManageJobProgress: allStores.rootStore.account.isOperationsDepartmentMember,
    staffMembers: allStores.rootStore.people.staffMembers.allStaffMembers.slice(),
    loadStaffMembers: allStores.rootStore.people.staffMembers.loadAllStaffMembers,

    loadJobProgress: jobModel.item.loadJobProgress,
    loadJob: jobModel.item.loadJob,
    updateJobProgress: (command: UpdateJobProgressCommand) =>
      jobModel.item.updateJobProgress(command as OpsJobProgressCommand),

    jobProgresses: jobModel.item.progress && jobModel.item.progress.progress,
    fleetAssets: assetsModel.fleetAssetListItems.slice(),
    loadFleetAssets: assetsModel.loadFleetAssets,
    jobNumber: jobModel.item.job && jobModel.item.job.jobNumber,
    jobStatus: jobModel.item.job && jobModel.item.job.jobStatus.id,
    isWorkshop: false,
    loadPreviousAndNextJobProgress: jobModel.item.loadPreviousAndNextJobProgress,
    previousAndNextJobProgresses: jobModel.item.previousAndNextJobProgresses,
  };
})(MaintainJobProgress);

export default Container;
