import Omit from 'src/infrastructure/omit';

type ReportableEventCategory = Operations.Domain.Queries.ListReportableEvents.ReportableEventCategoryDto;

export const DisplayReportableEventCategory: React.FC<{
  category: Omit<ReportableEventCategory, 'explanation'>;
}> = props => {
  return (
    <div>
      <b>{props.category.code.toString().padStart(2, '0')} </b> - {props.category.description}
    </div>
  );
};
