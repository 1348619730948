import { DateTime } from 'luxon';
import { observer } from 'mobx-react-lite';
import { useEffect, useRef, useState } from 'react';
import { JobType } from 'src/api/enums';
import { useRootStore } from 'src/domain/entities/RootStoreModel';
import { AngleLeft, AngleRight, CheckIcon, ClockIcon, EditIcon } from 'src/images/icons';
import Page from 'src/views/components/Page';
import {
  ActionType,
  FieldType,
  IPageDef,
  PagePrimarySize,
  PaneType,
  ShellModalSize,
} from 'src/views/definitionBuilders/types';
import withQueryParams, { IQueryParamsProps } from 'src/views/hocs/withQueryParams';
import getUpdateTaskModalDef from './getUpdateTaskModalDef';
import './MaintainTasksForDay.scss';

type InternalProps = IQueryParamsProps<{ day: string }>;

const MaintainTasksForDay: React.FC<InternalProps> = observer((props: InternalProps) => {
  const rootStore = useRootStore();
  const canManageTasks = rootStore.account.isOperationsDepartmentMember;
  const jobTaskModel = rootStore.operations.jobTask;
  const tasksForDay = jobTaskModel.tasksForDay;
  const [dataLoading, setDataLoading] = useState<boolean>(false);
  const prevDay = useRef(tasksForDay?.day);
  const day = props.getQueryParams().day;
  const currentDay = day || DateTime.local().toISODate();

  useEffect(() => {
    if (!prevDay.current || (!dataLoading && tasksForDay && prevDay.current !== currentDay)) {
      loadData();
    }
  }, [tasksForDay?.day, currentDay]);

  const changeCurrentDay = (plusDays: number) => {
    const day = DateTime.fromISO(currentDay)
      .plus({ days: plusDays })
      .toISODate();
    props.updateQueryParams({ day });
  };

  const loadData = async () => {
    try {
      setDataLoading(true);
      await jobTaskModel.loadTasksForDay(currentDay);
    } finally {
      setDataLoading(false);
    }
  };

  const handleNextDay = () => changeCurrentDay(1);
  const handlePrevDay = () => changeCurrentDay(-1);

  const getPageDef = (): IPageDef => {
    const day = DateTime.fromISO(currentDay);
    return {
      primarySize: PagePrimarySize.full,
      primarySection: {
        title: `Tasks for ${day.toLocaleString(DateTime.DATE_HUGE)}`,
        primaryActions: [
          {
            actions: [
              {
                actionType: ActionType.actionButton,
                label: 'Previous Day',
                icon: <AngleLeft />,
                onClick: handlePrevDay,
              },
              {
                actionType: ActionType.actionButton,
                label: 'Next Day',
                icon: <AngleRight />,
                onClick: handleNextDay,
              },
            ],
          },
        ],
        panels: [
          {
            panes: [
              {
                paneType: PaneType.tablePane,
                dataAddr: 'tasks',
                neverEditable: true,
                loading: dataLoading,
                fields: [
                  {
                    fieldType: FieldType.readonlyField,
                    label: 'Booking',
                    dataAddr: 'quoteNumber',
                    linkTo: d => `/operations/bookings-for-ops/${d.parentValue.quoteId}`,
                    columnWidth: '6em',
                  },
                  {
                    fieldType: FieldType.readonlyField,
                    label: 'Trip',
                    dataAddr: 'tripNumber',
                    formatReadonly: d => {
                      return d.parentValue.jobType.id !== JobType.ContractCharter
                        ? d.fieldValue + 1
                        : undefined;
                    },
                    columnWidth: '5em',
                  },
                  {
                    fieldType: FieldType.readonlyField,
                    label: 'Job Number',
                    dataAddr: 'jobNumber',
                    linkTo: d => `/operations/jobs/${d.parentValue.jobId}`,
                    columnWidth: '8em',
                  },
                  {
                    fieldType: FieldType.readonlyField,
                    label: 'Task',
                    dataAddr: 'extraType.description',
                    formatReadonly: d => <span className="text-nowrap mr-2">{d.fieldValue}</span>,
                    columnWidth: '1px',
                  },
                  {
                    fieldType: FieldType.readonlyField,
                    label: 'Quantity',
                    dataAddr: 'totalQuantity',
                    formatReadonly: d => {
                      return d.parentValue.jobType.id !== JobType.ContractCharter
                        ? `${d.fieldValue}\t(${d.parentValue.quotedQuantity} quoted)`
                        : d.fieldValue;
                    },
                    columnWidth: '10em',
                  },
                  {
                    fieldType: FieldType.readonlyField,
                    label: 'Notes',
                    dataAddr: 'completedNotes',
                  },
                  {
                    fieldType: FieldType.readonlyField,
                    label: 'Complete',
                    dataAddr: 'isComplete',
                    formatReadonly: d =>
                      d.fieldValue ? (
                        <CheckIcon className="task-complete" />
                      ) : (
                        <ClockIcon className="task-incomplete" />
                      ),
                    columnWidth: '7em',
                  },
                  {
                    fieldType: FieldType.actionListField,
                    columnWidth: '1px',
                    hidden: d => !canManageTasks || !d.parentValue.isEditable,
                    actionGroups: [
                      {
                        actions: [
                          {
                            actionType: ActionType.modalActionButton,
                            label: 'Update Task',
                            icon: <EditIcon />,
                            modalSize: ShellModalSize.oneQuarter,
                            modalDef: getUpdateTaskModalDef(jobTaskModel.updateTask),
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    };
  };

  const data = tasksForDay?.day === currentDay ? tasksForDay : undefined;
  return <Page className="maintain-tasks-for-day-component" def={getPageDef()} data={data} />;
});

export default withQueryParams(MaintainTasksForDay);
