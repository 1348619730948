import { IRootStoreModel } from 'src/domain/entities/RootStoreModel';
import { UiRoute } from 'src/domain/services/buildUiRoutes';
import { UiRouteName } from 'src/domain/baseTypes';

export default function buildUiRoutes(store: IRootStoreModel) {
  const noteCreate = new UiRoute(
    UiRouteName.peopleNoteCreate,
    '/people/notes/create',
    () => 'Create Note',
    false
  );

  const noteManage = new UiRoute(
    UiRouteName.peopleNoteManage,
    '/people/notes/:id',
    () => 'Manage Note',
    false
  );

  const notesList = new UiRoute(
    UiRouteName.peopleNoteList,
    '/people/notes',
    () => 'Notes',
    false,
    true,
    [noteCreate, noteManage]
  );

  return {
    [UiRouteName.peopleNoteCreate]: noteCreate,
    [UiRouteName.peopleNoteList]: notesList,
    [UiRouteName.peopleNoteManage]: noteManage,
  };
}
