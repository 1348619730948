import ListPage, { IListPageDef } from 'src/views/components/Page/pages/ListPage';
import { FieldType } from 'src/views/definitionBuilders/types/field';
import withQueryParams, { IQueryParamsProps } from 'src/views/hocs/withQueryParams';
import TaskCardItem from 'src/views/components/TaskCard/TaskCardItem';
import { saveAs } from 'file-saver';
import { ActionType, PagePrimarySize } from 'src/views/definitionBuilders/types';
import { ExcelIcon } from 'src/images/icons';
import { observer } from 'mobx-react';
import { useRootStore } from 'src/domain/entities/RootStoreModel';
import { getTodaySortableDate } from 'src/domain/dateHelper';
import PrimaryTitle from 'src/views/components/Page/PrimaryTitle/PrimaryTitle';

type ListSuppliersQuery = Workshop.Domain.Queries.Suppliers.GetSuppliers.ListSuppliersQuery;

const ListSuppliers: React.FC<IQueryParamsProps<ListSuppliersQuery>> = observer(
  (props: IQueryParamsProps<ListSuppliersQuery>) => {
    const rootStore = useRootStore();
    const suppliersModel = rootStore.workshop.suppliers;

    const canManageSuppliers = rootStore.account.isWorkshopDepartmentMember;
    const listSuppliers = suppliersModel.listItems;
    const suppliers = suppliersModel.items.slice();
    const hasMoreData = suppliersModel.hasMoreItems;

    const getPageDef = (): IListPageDef => {
      return {
        primaryTitle: (
          <PrimaryTitle
            title="Suppliers"
            link="https://www.notion.so/central-ops/Suppliers-f535be21817048cf9eb0eaa1d07989d8"></PrimaryTitle>
        ),
        primarySize: PagePrimarySize.full,
        onLoadData: listSuppliers,
        externalSearch: true,
        createLink: canManageSuppliers ? TaskCardItem.workshop.createSupplier : undefined,
        hasMoreData: hasMoreData,
        primaryFields: [
          {
            fieldType: FieldType.textField,
            dataAddr: 'name',
            label: 'Name',
            linkTo: d => `/workshop/suppliers/${d.parentValue.id}`,
          },
          {
            fieldType: FieldType.textField,
            dataAddr: 'email',
            label: 'Email',
          },
          {
            fieldType: FieldType.textField,
            dataAddr: 'phone',
            label: 'Phone',
          },
        ],
        primaryActions: [
          {
            actions: [
              {
                actionType: ActionType.actionCollection,
                actionGroups: [
                  {
                    actions: [
                      {
                        actionType: ActionType.actionButton,
                        label: 'Export To Excel',
                        icon: <ExcelIcon fixedWidth />,
                        onClick: saveExcel,
                      },
                      {
                        actionType: ActionType.actionButton,
                        label: 'Export Attachment List To Excel',
                        icon: <ExcelIcon fixedWidth />,
                        onClick: exportAttachmentsToExcel,
                      },
                      {
                        actionType: ActionType.actionButton,
                        label: 'Export Notes To Excel',
                        icon: <ExcelIcon fixedWidth />,
                        onClick: exporNotesToExcel,
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      };
    };
    const saveExcel = () => {
      const fileName = `Suppliers_${getTodaySortableDate()}.xlsx`;
      return suppliersModel
        .exportToExcel(props.getQueryParams())
        .then(blob => saveAs(blob, fileName));
    };
    const exportAttachmentsToExcel = () => {
      const fileName = `Supplier_Attachments_${getTodaySortableDate()}.xlsx`;
      return suppliersModel.exportAttachmentsToExcel().then(blob => saveAs(blob, fileName));
    };

    const exporNotesToExcel = () => {
      const fileName = `Supplier_Notes_${getTodaySortableDate()}.xlsx`;
      return suppliersModel.exportNotesToExcel().then(blob => saveAs(blob, fileName));
    };

    return <ListPage className="list-suppliers-component" data={suppliers} def={getPageDef()} />;
  }
);

export default withQueryParams(ListSuppliers);
