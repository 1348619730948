import { ModalDefBuilder, PaneType, FieldType } from 'src/views/definitionBuilders/types';
import { getSubmitCloseModalActionGroupDef } from 'src/views/definitionBuilders/common';

type ExportConsumptionCommand = Workshop.Domain.Queries.ExportFuelConsumptionReportToExcel.ExportFuelConsumptionReportToExcelQuery;
type AssetItem = Workshop.Domain.Queries.AssetItem;

interface ExportFuelConsumptionReport {
  to: string;
  from: string;
  assetIds: string[];
}

export default function getFuelConsumptionReportModalDef(
  assets: AssetItem[],
  onSubmit: (command: ExportConsumptionCommand) => Promise<void>
): ModalDefBuilder {
  return () => {
    return {
      title: 'Export Fuel Consumption Report',
      asForm: true,
      panels: [
        {
          panes: [
            {
              paneType: PaneType.nestingPane,
              panes: [
                {
                  paneType: PaneType.formFieldsPane,
                  dataRequiredForRows: 'paneValue',
                  fields: [
                    {
                      fieldType: FieldType.dateField,
                      label: 'Start Date',
                      dataAddr: 'from',
                      mandatory: true,
                    },
                    {
                      fieldType: FieldType.dateField,
                      label: 'End Date',
                      dataAddr: 'to',
                      mandatory: true,
                    },
                    {
                      fieldType: FieldType.selectMultiField,
                      label: 'Assets',
                      dataAddr: 'assetIds',
                      useValueOnly: true,
                      valueKey: 'id',
                      descriptionKey: 'name',
                      optionItems: assets,
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
      secondaryActions: [getSubmitCloseModalActionGroupDef('Submit')],
      onFormSubmit: (values: ExportFuelConsumptionReport) => {
        const command: ExportConsumptionCommand = {
          to: values.to,
          from: values.from,
          assetIds: values.assetIds,
        };
        return onSubmit(command);
      },
    };
  };
}
