import {
  IActionGroupDef,
  IPaneData,
  IPaneMeta,
  ActionType,
  ShellModalSize,
  PaneType,
} from 'src/views/definitionBuilders/types';
import { getSubmitCloseModalActionGroupDef } from 'src/views/definitionBuilders/common';

export interface ISkipFatigueValidationSubmissionMeta {
  skipFatigueValidation?: boolean;
}

export const getSkipFatigueActionGroupDef = <
  TMetaType extends ISkipFatigueValidationSubmissionMeta
>(
  canSkipFatigueValidation: boolean,
  def?: {
    label?: string;
    customRender?: (api: { data: IPaneData; meta: IPaneMeta }) => React.ReactNode;
    meta?: TMetaType;
  }
): IActionGroupDef[] => {
  const safeLabel = !!def && !!def.label ? def.label : 'Submit and Skip Fatigue Validation';
  const safeRender =
    !!def && !!def.customRender
      ? def.customRender
      : () => {
          return (
            <div>
              <p>
                The fatigue validation will be skipped for this submit. This skip will be recorded
                in job activity log.
              </p>
              <p>
                <span>Are you sure you want to submit and skip fatigue validation?</span>
              </p>
            </div>
          );
        };
  const safeMeta = {
    ...(!!def && !!def.meta ? def.meta : {}),
    skipFatigueValidation: true,
  };
  return !canSkipFatigueValidation
    ? []
    : [
        {
          actions: [
            {
              actionType: ActionType.modalActionButton,
              label: safeLabel,
              modalSize: ShellModalSize.oneThird,
              modalDef: modalDefApi => ({
                title: safeLabel,
                asForm: true,
                panels: [
                  {
                    panes: [
                      {
                        paneType: PaneType.customPane,
                        render: safeRender,
                      },
                    ],
                  },
                ],
                secondaryActions: [getSubmitCloseModalActionGroupDef()],
                onFormSubmit: () => {
                  return modalDefApi.parentFormApi.submitForm(safeMeta);
                },
              }),
            },
          ],
        },
      ];
};
