import { Component } from 'react';
import shallowEqual from 'shallowequal';
import { shallowMemoize } from 'src/infrastructure/memoizeOne';
import { FieldName, RfsContext } from './base';

interface IFieldApiWithoutState {
  setValue: (value: unknown) => void;
  setTouched: (touched?: boolean) => void;
}

export interface IFieldApi extends IFieldApiWithoutState {
  fieldName: FieldName;
  value: unknown;
  touched: boolean;
  error: undefined | string;
}

interface IFieldProps {
  field: FieldName;

  validate?: (value: unknown) => undefined | string;
  asyncValidate?: (value: unknown) => undefined | Promise<undefined | string>;

  children: (props: IFieldApi) => React.ReactNode;
}

export class Field extends Component<IFieldProps> {
  static contextType = RfsContext;
  context!: React.ContextType<typeof RfsContext>;

  private readonly _id = Symbol();

  componentDidMount() {
    this.context.registerField(
      this._id,
      this.fullFieldName,
      this.props.validate,
      this.props.asyncValidate
    );
  }

  componentDidUpdate(prevProps: Readonly<IFieldProps>) {
    if (
      !shallowEqual(this.props.field, prevProps.field) ||
      this.props.validate !== prevProps.validate
    ) {
      this.context.registerField(
        this._id,
        this.fullFieldName,
        this.props.validate,
        this.props.asyncValidate
      );
    }
  }

  componentWillUnmount() {
    this.context.unregisterField(this._id);
  }

  private get fullFieldName() {
    return this.context.formApi.getFullField(this.props.field);
  }

  private readonly getFieldApiWithoutState = shallowMemoize(
    (field: Array<string | number>): IFieldApiWithoutState => {
      return {
        setValue: value => this.context.formApi.setValue(field, value),
        setTouched: touched => this.context.formApi.setTouched(field, touched),
      };
    }
  );

  private readonly getFieldApi = shallowMemoize(
    (
      field: Array<string | number>,
      value: unknown,
      touched: boolean,
      error: string | undefined
    ): IFieldApi => {
      return {
        ...this.getFieldApiWithoutState(field),
        fieldName: field,
        value,
        touched,
        error,
      };
    }
  );

  render() {
    const { children } = this.props;
    const formApi = this.context.formApi;
    const field = this.fullFieldName;
    const touched = formApi.getTouched(field);
    const fieldApi = this.getFieldApi(
      field,
      formApi.getValue(field),
      touched,
      touched ? formApi.getError(field) : undefined
    );
    return children(fieldApi);
  }
}
