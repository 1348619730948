import {
  IActionMeta,
  IActionData,
  ActionType,
  IActionDef,
  IModalActionButtonDef,
} from 'src/views/definitionBuilders/types';
import ActionButton from './ActionButton';
import ShellModal, { IShellModalTriggerApi } from 'src/views/components/Shell/ShellModal';
import PageArea from 'src/views/components/Page/PageArea';
import { withFormApi } from 'src/views/components/Page/forms';
import { IFormApi } from 'src/views/components/Page/forms/base';
import { Component } from 'react';

interface IModalActionButtonProps {
  className?: string;
  formApi: IFormApi;
  actionDef: IModalActionButtonDef;
  actionMeta: IActionMeta;
  actionData: IActionData;
  onBuildExternalTrigger?: (api: IShellModalTriggerApi) => void;
  showCloseButton?: boolean;
  suppressOpen?: () => Promise<boolean>;
}

class ModalActionButton extends Component<IModalActionButtonProps> {
  private readonly getDisabled = () => {
    const { actionDef, actionData } = this.props;

    if (typeof actionDef.disabled === 'function') {
      return actionDef.disabled(actionData);
    } else {
      return actionDef.disabled;
    }
  };

  private readonly getShowTrigger = (api: IShellModalTriggerApi): React.ReactNode => {
    const {
      className,
      actionDef,
      actionMeta,
      actionData,
      onBuildExternalTrigger,
      suppressOpen,
    } = this.props;
    if (onBuildExternalTrigger) {
      onBuildExternalTrigger(api);
      return null;
    } else {
      return (
        <ActionButton
          className={className}
          actionDef={{
            ...(actionDef as IActionDef),
            actionType: ActionType.actionButton,
            disabled: this.getDisabled(),
            onClick: async () => {
              if (actionDef.onOpenModal) {
                actionDef.onOpenModal(actionData.parentValue, actionData.actionValue);
              }
              if (suppressOpen) {
                const suppress = await suppressOpen();
                if (suppress) {
                  return;
                }
              }
              api.onShow();
            },
          }}
          actionMeta={actionMeta}
          actionData={actionData}
        />
      );
    }
  };

  render() {
    const { formApi, actionDef, actionData, showCloseButton } = this.props;
    const modalDef = actionDef.modalDef({ actionData, parentFormApi: formApi });

    return (
      <ShellModal
        size={actionDef.modalSize}
        renderShowTrigger={this.getShowTrigger}
        showCloseButton={showCloseButton}
        onCloseModal={actionDef.onCloseModal}>
        {(api: IShellModalTriggerApi) => (
          <>
            <div style={{ position: 'relative', height: '100%' }}>
              <PageArea
                className="modal-area"
                sectionDefs={[modalDef]}
                data={actionData.actionValue}
                modalMeta={{ closeModal: api.onClose }}
                autoFocus
              />
            </div>
          </>
        )}
      </ShellModal>
    );
  }
}

export default withFormApi(ModalActionButton);
