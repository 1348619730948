import PrintButton from 'src/views/components/PrintButton';
import { PrintIcon } from 'src/images/icons';
import PrintDailySummary, { IPrintDailySummaryProps } from './PrintDailySummary';

const PrintDailySummaryButton: React.FC<IPrintDailySummaryProps> = (
  props: IPrintDailySummaryProps
) => {
  return (
    <PrintButton
      className="print-button"
      size="lg"
      outline
      printContent={() => (
        <PrintDailySummary
          jobs={props.jobs}
          userDisplayName={props.userDisplayName}
          date={props.date}
          checks={props.checks}
          checkDraft={props.checkDraft}
          checkColumns={props.checkColumns}
        />
      )}>
      <PrintIcon />
      Print Daily Summary Sheet
    </PrintButton>
  );
};

export default PrintDailySummaryButton;
