import { types, flow } from 'mobx-state-tree';
import { getAjax } from 'src/domain/services';
import { IFile } from 'src/views/components/Page/fields/MultiFilePageField';
import { getBase64 } from 'src/infrastructure/fileUtil';
import { UpdateAttachmentFormData } from 'src/views/routes/people/attachment/getUpdateAttachmentModalDef';
import {
  AggregatesWithAttachments,
  DownloadAttachmentQuery,
  isValidAttachment,
  LoadAttachmentsQuery,
} from 'src/views/components/Attachment/attachmentHelper';
import { DeleteAttachmentFormData } from 'src/views/routes/people/attachment/getDeleteAttachmentModalDef';

type DeleteAttachmentCommand = Common.Attachments.DeleteAttachmentCommandBase;
type UpdateAttachmentCommand = People.Domain.Commands.Attachment.UpdateAttachment.UpdateStaffMemberAttachmentCommand;
type AttachmentDto = People.Domain.Queries.Attachment.ListStaffMemberAttachments.StaffMemberAttachmentDetailsDto;
type AddAttachmentCommand = People.Domain.Commands.Attachment.AddAttachment.AddStaffMemberAttachmentCommand;

export type AddAttachmentData = {
  aggregateId: string;
  fileName: string;
  aggregateType: AggregatesWithAttachments;
  file: IFile;
};

export const PeopleAttachmentModel = types
  .model('PeopleAttachmentModel', {
    attachments: types.optional(types.array(types.frozen<AttachmentDto>()), []),
  })
  .actions(self => {
    const ajax = getAjax(self);

    const addStaffMemberAttachment = flow(function*(data: AddAttachmentData) {
      if (!isValidAttachment(data.file)) {
        return;
      }

      const command: AddAttachmentCommand = {
        ...data,
        mimeType: data.file.file!.type,
        data: yield getBase64(data.file.file!),
      };

      yield ajax.people.attachments.addAttachment(command, data.aggregateType);
    });

    const loadStaffMemberAttachmentDetails = flow(function*(query: LoadAttachmentsQuery) {
      self.attachments = yield ajax.people.attachments.loadAttachments(
        query.aggregateId,
        query.aggregateType
      );
    });

    const downloadStaffMemberAttachment = flow(function*(query: DownloadAttachmentQuery) {
      yield ajax.people.attachments.downloadAttachment(
        query.attachmentDetailsId,
        query.aggregateId,
        query.aggregateType
      );
    });

    const deleteStaffMemberAttachment = flow(function*(data: DeleteAttachmentFormData) {
      const command: DeleteAttachmentCommand = {
        aggregateId: data.aggregateId,
        attachmentDetailsId: data.attachmentDetailsId,
      };
      yield ajax.people.attachments.deleteAttachment(command, data.aggregateType);
    });

    const updateStaffMemberAttachment = flow(function*(data: UpdateAttachmentFormData) {
      const command: UpdateAttachmentCommand = {
        attachmentDetailsId: data.attachmentDetailsId,
        aggregateId: data.aggregateId,
        fileName: data.fileName,
      };
      yield ajax.people.attachments.updateAttachment(command, data.aggregateType);
    });

    const clearStaffMemberAttachments = () => {
      self.attachments.clear();
    };
    return {
      addStaffMemberAttachment,
      loadStaffMemberAttachmentDetails,
      downloadStaffMemberAttachment,
      deleteStaffMemberAttachment,
      updateStaffMemberAttachment,
      clearStaffMemberAttachments,
    };
  });
