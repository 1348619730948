import { observer } from 'mobx-react';
import React, { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { useRootStore } from 'src/domain/entities/RootStoreModel';
import { CalendarIcon, WarnIcon } from 'src/images/icons';
import { DateFormat } from 'src/views/components/DateFormat';
import ModalActionButton from 'src/views/components/Page/actions/ModalActionButton';
import {
  ActionType,
  IActionData,
  ShellModalSize,
} from '../../../../../definitionBuilders/types/action';
import './InspectionsDue.scss';
import getScheduleMachineryJobTaskModalDef from './ScheduleMachineryJobTask/getScheduleMachineryJobTaskModalDef';
import { DateTime } from 'luxon';
import { TIMEZONE } from '../../../../../../appSettings';

type ScheduleMachineryJobTaskCommand = Workshop.Domain.Commands.JobTask.ScheduleMachineryJobTaskCommand;
type MachineryScheduleTemplateItem = Workshop.Domain.Queries.MachineryScheduleTemplate.MachineryScheduleTemplateItem;

export interface IInspectionsDueProps {
  selectedWorkshopDepotId: number;
}

const InspectionsDue: React.FC<IInspectionsDueProps> = observer((props: IInspectionsDueProps) => {
  const rootStore = useRootStore();
  const assets = rootStore.assets.assetListItemsWithInspectionsDue.slice();
  const loadAssetsWithInspectionsDue = rootStore.assets.loadAssetsWithInspectionsDue;
  const loadMachinerySchedule = rootStore.workshop.machineryScheduleTemplate.loadMachinerySchedule;
  const onLoadJobs = rootStore.scheduledMachineryJobs.loadJobs;
  const machinerySchedule = rootStore.workshop.machineryScheduleTemplate.item.slice();
  const loadChecklists = rootStore.workshop.checklists.load;
  const checklists = rootStore.workshop.checklists.checklists.slice();
  const loadJobTaskCategory = rootStore.jobTask.loadJobTaskCategories;
  const machineryScheduleTemplateCategoryOptions =
    rootStore.jobTask.machineryScheduleTemplateCategoryOptions;
  const workshopDepots = rootStore.workshopStartup.workshopDepots.slice();
  const selectedWorkshopDepotId = props.selectedWorkshopDepotId;
  const prevProps = useRef(selectedWorkshopDepotId);
  const housingLocationIdsForSelectedDepot = () => {
    const selectedDepot = workshopDepots.find(d => d.id === selectedWorkshopDepotId);
    const housingLocationIds = selectedDepot && selectedDepot.housingLocations.map(hl => hl.id);
    return housingLocationIds || [];
  };
  const scheduleMachineryJobTasks = rootStore.jobTask.scheduleMachineryJobTask;
  const modifiedMachinerySchedule = (machinerySchedule.map(items => ({
    ...items,
    depot: selectedWorkshopDepotId,
    changeState: 2,
  })) as unknown) as MachineryScheduleTemplateItem[];

  const nowUtc = DateTime.utc();
  const showInZone = TIMEZONE;

  const zonedNow = nowUtc.setZone(showInZone);
  const query = new URLSearchParams(location.search);
  const queryMonth = query.get('month') || undefined;

  const zonedMonthToDisplay = queryMonth
    ? DateTime.fromISO(queryMonth, { zone: showInZone })
    : zonedNow;

  const reloadJobs = () => {
    onLoadJobs(
      zonedMonthToDisplay.year,
      zonedMonthToDisplay.month,
      showInZone,
      selectedWorkshopDepotId
    );
  };

  useEffect(() => {
    loadAssetsWithInspectionsDue({
      housingLocationIds: housingLocationIdsForSelectedDepot(),
    });
    loadMachinerySchedule();
    loadChecklists();
    loadJobTaskCategory();
  }, []);

  useEffect(() => {
    if (prevProps.current !== selectedWorkshopDepotId) {
      loadAssetsWithInspectionsDue({
        housingLocationIds: housingLocationIdsForSelectedDepot(),
      });
    }
  }, [selectedWorkshopDepotId]);

  const onSubmit = async (command: ScheduleMachineryJobTaskCommand) => {
    await scheduleMachineryJobTasks(command).then(() => {
      loadAssetsWithInspectionsDue({
        housingLocationIds: housingLocationIdsForSelectedDepot(),
      });
      reloadJobs();
    });
  };

  return (
    <div className="inspections-due-component">
      <div className="card-container">
        {assets.length === 0
          ? 'No HV inspections due'
          : assets.map(asset => (
              <div className="card mb-3" key={asset.assetId}>
                <div className="card-body">
                  <h5 className="card-title">
                    <Link to={`/workshop/assets/${asset.assetId}`}>{asset.name}</Link>
                    <ModalActionButton
                      className="float-right"
                      actionDef={{
                        actionType: ActionType.modalActionButton,
                        modalSize: ShellModalSize.threeQuarters,
                        icon: <CalendarIcon />,
                        label: '',
                        modalDef: getScheduleMachineryJobTaskModalDef(
                          modifiedMachinerySchedule,
                          machineryScheduleTemplateCategoryOptions,
                          checklists,
                          onSubmit,
                          asset.assetId,
                          rootStore.workshopStartup.workshopDepots.slice(),
                          selectedWorkshopDepotId
                        ),
                      }}
                      actionMeta={{ formSubmitting: false, hideLabel: true }}
                      actionData={{} as IActionData}
                    />
                  </h5>
                  <div className="card-text">
                    HVI due: <DateFormat value={asset.certificateOfInspectionExpiryDate} />
                  </div>
                  <div className="card-text">
                    Rego due: <DateFormat value={asset.registrationDueDate} />
                  </div>
                  {asset.certificatePendingWarning && (
                    <div className="certificate-warning">
                      <WarnIcon /> Certificate Pending
                    </div>
                  )}
                  {asset.regoDueProximityWarning && (
                    <div className="rego-due-warning">
                      <WarnIcon /> Rego Due
                    </div>
                  )}
                </div>
              </div>
            ))}
      </div>
    </div>
  );
});

export default InspectionsDue;
