import { FieldType, PaneType, IFieldData } from 'src/views/definitionBuilders/types';
import { IPanelDef } from '../../../../../../views/definitionBuilders/types/panel';
import { TIMEZONE, JOB_CLOCK_ON_MINS_BUFFER } from 'src/appSettings';
import { DateTime, Duration } from 'luxon';
import {
  getEditingFormattedTimeString,
  parseEditingFormattedTimeString,
} from 'src/views/components/Page/fields/subfields/TimeHelpers';
import humanizeDuration from 'src/infrastructure/humanizeDuration';
import { validateTimeIsNotLessThan } from 'src/infrastructure/dateUtils';
import {
  formattedWorkingJobHours,
  totalJobHours,
  workingJobHours,
} from 'src/views/routes/operations/urban/urbanHelpers';
import { ShiftType } from 'src/api/enums';

type DummyType = {
  unpaidBreaks: string | undefined;
  clockOn: string;
  clockOff: string;
};

export default function getContractShiftSchedulePaneDef(): IPanelDef {
  const panel: IPanelDef = {
    title: 'Schedule',
    hidden: data => {
      return !(
        data.panelValue.shiftType && data.panelValue.shiftType.id === ShiftType.ContractCharter
      );
    },
    panes: [
      {
        paneType: PaneType.nestingPane,
        panes: [
          {
            paneType: PaneType.formFieldsPane,
            columnCount: 3,
            fields: [
              {
                fieldType: FieldType.timeField,
                label: 'Clock On',
                dataAddr: 'clockOn',
                mandatory: true,
                formatReadonly: d => scheduleDateTimeWithOriginal(d),
              },
              {
                fieldType: FieldType.timeField,
                label: 'Depart Depot',
                dataAddr: 'departDepot',
                mandatory: true,
                validate: d =>
                  validateTimeIsNotLessThan(
                    d.parentValue.departDepot,
                    'Depart depot',
                    d.parentValue.clockOn,
                    'clock on'
                  ),
                onChange: api => {
                  const date = DateTime.local().toISODate();
                  const departDepot = DateTime.fromISO(`${date}T${api.newFieldValue}`);
                  const shiftClockOn = api.formValues.shiftNumber?.clockOn;
                  if (departDepot.isValid && !shiftClockOn) {
                    var newDateTime = departDepot.minus(
                      Duration.fromObject({ minutes: JOB_CLOCK_ON_MINS_BUFFER })
                    );
                    const time = newDateTime.toFormat('HH:mm');
                    api.setFormValue(['clockOn'], time);
                  }
                },
                formatReadonly: d => scheduleDateTimeWithOriginal(d),
              },
              {
                fieldType: FieldType.dateTimeField,
                label: 'On Site',
                dataAddr: 'onSite',
                readonly: true,
                validate: d =>
                  validateTimeIsNotLessThan(
                    d.parentValue.onSite,
                    'On site',
                    d.parentValue.departDepot,
                    'depart depot'
                  ),
                formatReadonly: d => scheduleDateTimeWithOriginal(d, undefined, true),
              },
              {
                fieldType: FieldType.dateTimeField,
                label: 'Pick Up',
                dataAddr: 'shiftCommence',
                readonly: true,
                mandatory: true,
                validate: d =>
                  validateTimeIsNotLessThan(
                    d.parentValue.shiftCommence,
                    'Shift commence',
                    d.parentValue.onSite,
                    'on site'
                  ),

                formatReadonly: d => scheduleDateTimeWithOriginal(d, undefined, true),
              },
            ],
          },
          {
            paneType: PaneType.formFieldsPane,
            columnCount: 3,
            fields: [
              {
                fieldType: FieldType.dateTimeField,
                label: 'Drop Off',
                dataAddr: 'shiftEnd',
                mandatory: true,
                readonly: true,
                validate: d =>
                  validateTimeIsNotLessThan(
                    d.parentValue.shiftEnd,
                    'Shift end',
                    d.parentValue.shiftCommence,
                    'shift commence'
                  ),
                formatReadonly: d => scheduleDateTimeWithOriginal(d, undefined, true),
              },
              {
                fieldType: FieldType.timeField,
                label: 'Arrive Depot',
                dataAddr: 'arriveDepot',
                mandatory: true,
                validate: d =>
                  validateTimeIsNotLessThan(
                    d.parentValue.arriveDepot,
                    'Arrive depot',
                    d.parentValue.shiftEnd,
                    'shift end'
                  ),
                formatReadonly: d => scheduleDateTimeWithOriginal(d),
              },
              {
                fieldType: FieldType.timeField,
                label: 'Clock Off',
                dataAddr: 'clockOff',
                mandatory: true,
                validate: d => {
                  const validateOffTime = validateTimeIsNotLessThan(
                    d.parentValue.clockOff,
                    'Clock off',
                    d.parentValue.arriveDepot,
                    'Arrive depot'
                  );
                  if (validateOffTime) {
                    return validateOffTime;
                  }
                  return DateTime.fromISO(d.parentValue.clockOn).equals(
                    DateTime.fromISO(d.parentValue.clockOff)
                  )
                    ? 'Total time cannot be zero'
                    : undefined;
                },
                formatReadonly: d => scheduleDateTimeWithOriginal(d),
              },
            ],
          },
          {
            paneType: PaneType.formFieldsPane,
            columnCount: 3,
            fields: [
              {
                fieldType: FieldType.readonlyField,
                label: 'Total Time',
                formatReadonly: data => {
                  const j = data.parentValue as DummyType;
                  if (!j.clockOn || !j.clockOff) {
                    return undefined;
                  }

                  var date = DateTime.local().toISODate();
                  const start = `${date}T${j.clockOn}`;
                  const finish = `${date}T${j.clockOff}`;
                  return totalJobHours(start, finish);
                },
              },
              {
                fieldType: FieldType.durationField,
                label: 'Unpaid Breaks',
                dataAddr: 'unpaidBreaks',
                mandatory: true,
                formatReadonly: d =>
                  getEditingFormattedTimeString(parseEditingFormattedTimeString(d.fieldValue)),
                validate: data => {
                  const j = data.parentValue as DummyType;
                  if (!j.clockOn || !j.clockOff || !j.unpaidBreaks) {
                    return undefined;
                  }

                  var date = DateTime.local().toISODate();
                  const start = `${date}T${j.clockOn}`;
                  const finish = `${date}T${j.clockOff}`;
                  const workingHours = workingJobHours(start, finish, j.unpaidBreaks);
                  return !workingHours || workingHours.valueOf() <= 0
                    ? 'Unpaid breaks must be less than total time'
                    : undefined;
                },
              },
              {
                fieldType: FieldType.readonlyField,
                label: 'Working Time',
                formatReadonly: data => {
                  const j = data.parentValue as DummyType;
                  if (!j.clockOn || !j.clockOff || !j.unpaidBreaks) {
                    return undefined;
                  }

                  var date = DateTime.local().toISODate();
                  const start = `${date}T${j.clockOn}`;
                  const finish = `${date}T${j.clockOff}`;
                  return formattedWorkingJobHours(start, finish, j.unpaidBreaks);
                },
              },
            ],
          },
        ],
      },
    ],
  };

  return panel;
}

const scheduleDateTimeWithOriginal = (
  d: IFieldData<string>,
  zone?: string,
  relocation?: boolean
) => {
  const dataAddr = d.fieldDataAddr[0];

  if (!d.fieldValue) {
    return undefined;
  }

  const clockOnFormatted = DateTime.fromISO(d.fieldValue, {
    zone: zone ?? TIMEZONE,
  }).toFormat('HH:mm');

  const currentDateTime = d.fieldValue && DateTime.fromISO(d.fieldValue);

  const departDepotDateTime = DateTime.fromISO(d.paneValue.departDepot);
  const arriveDepotDateTime = DateTime.fromISO(d.paneValue.arriveDepot);

  if (relocation) {
    let relocationTime: string;
    switch (dataAddr) {
      case 'onSite':
        relocationTime =
          departDepotDateTime && currentDateTime
            ? humanizeDuration(currentDateTime.diff(departDepotDateTime).milliseconds)
            : '';
        break;
      case 'shiftEnd':
        relocationTime =
          arriveDepotDateTime && currentDateTime
            ? humanizeDuration(arriveDepotDateTime.diff(currentDateTime).milliseconds)
            : '';
        break;
      case 'shiftCommence':
        relocationTime =
          departDepotDateTime && currentDateTime
            ? humanizeDuration(currentDateTime.diff(departDepotDateTime).milliseconds)
            : '';
        break;
      default:
        relocationTime = '';
        break;
    }

    return (
      <div>
        {clockOnFormatted}
        <div>
          <small>
            <em>Relocation Time: </em>
            {relocationTime === '' || relocationTime === '0 seconds' ? ' - ' : relocationTime}
          </small>
        </div>
      </div>
    );
  } else {
    return clockOnFormatted;
  }
};
