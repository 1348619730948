import { Input } from 'reactstrap';
import { LabelledFormField } from 'src/views/components/forms';
import { IFieldMeta, IFieldData, IMultiFileFieldDef } from 'src/views/definitionBuilders/types';
import { IFieldApi } from 'src/views/components/Page/forms/Field';
import { FilePdfIcon, TrashIcon, UndoIcon } from 'src/images/icons';
import styles from './FilePageField.module.scss';
import { ChangeState } from 'src/api/enums';
import { Component } from 'react';

export interface IFile {
  id?: number;
  attachmentId?: string;
  file?: File;
  name: string;
  changeState: ChangeState;
}

interface IMultiFilePageFieldProps {
  fieldApi: IFieldApi;
  fieldDef: IMultiFileFieldDef;
  fieldMeta: IFieldMeta;
  fieldData: IFieldData<IFile[]>;
}

class MutliFilePageField extends Component<
  IMultiFilePageFieldProps,
  { existingMapping: Map<number, boolean> }
> {
  constructor(props: IMultiFilePageFieldProps) {
    super(props);

    this.state = {
      existingMapping: new Map<number, boolean>(),
    };
  }

  private readonly handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) {
      return;
    }

    let files: IFile[] = this.props.fieldData.fieldValue
      ? [...this.props.fieldData.fieldValue.filter(x => x.changeState !== ChangeState.Added)]
      : [];
    for (let i = 0; i < e.target.files.length; i++) {
      files.push({
        file: e.target.files[i],
        name: e.target.files[i].name,
        changeState: ChangeState.Added,
      });
    }
    this.props.fieldApi.setValue(files.length > 0 ? files : null);
  };

  private readonly handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    this.props.fieldApi.setTouched(true);
    this.props.fieldMeta.onBlur && this.props.fieldMeta.onBlur(this.props.fieldData.fieldValue);
  };

  render() {
    const { fieldApi, fieldDef: def, fieldMeta: meta, fieldData: data } = this.props;
    const { error, touched } = fieldApi;
    const safeError = touched && error;
    const labelText = typeof def.label === 'function' ? def.label(data) : def.label;
    const tooltipText = typeof def.tooltip === 'function' ? def.tooltip(data) : def.tooltip;
    const files = data && data.fieldValue && data.fieldValue ? data.fieldValue : undefined;

    const toggleExisting = (id: number) => {
      const current = this.state.existingMapping.get(id) || false;
      const newState = this.state.existingMapping.set(id, !current);
      this.setState({ existingMapping: newState });

      if (!files) {
        return;
      }

      const idx = files!.findIndex(t => t.id === id);
      const file = files[idx];
      file.changeState =
        file.changeState === ChangeState.Deleted ? ChangeState.Unchanged : ChangeState.Deleted;

      this.props.fieldApi.setValue(files);
    };

    const readOnlyValue =
      meta.readonly && files && files.length > 0
        ? files
            .filter(f => !!f.id)
            .map((f, idx) => {
              return (
                <button
                  type="button"
                  className="borderless show-label btn btn-outline-secondary"
                  style={{ margin: '0.2rem' }}
                  key={idx}
                  onClick={d => def.downloadOnClick(f.id!, f.name)}>
                  {f.name} <FilePdfIcon fixedWidth />
                </button>
              );
            })
        : undefined;

    return (
      <LabelledFormField
        className="file-page-field-component"
        readonly={meta.readonly || false}
        readonlyValue={readOnlyValue}
        mandatory={meta.mandatory}
        hideLabel={meta.hideLabel}
        labelValue={labelText}
        tooltipValue={tooltipText}
        noForm={meta.noForm}
        error={safeError}>
        <div className={styles.filePageFieldEditContainer}>
          {files && files.length > 0
            ? files.map((f, idx) => {
                return (
                  <div key={idx}>
                    <span>{f.name}</span>
                    {f.id !== undefined && (
                      <div onClick={() => toggleExisting(f.id!)}>
                        {this.state.existingMapping.get(f.id) || false ? (
                          <UndoIcon />
                        ) : (
                          <TrashIcon />
                        )}
                      </div>
                    )}
                  </div>
                );
              })
            : undefined}
          <Input
            type="file"
            multiple={def.multiple}
            className={!safeError ? '' : 'invalid'}
            autoFocus={meta.autoFocus}
            onChange={this.handleChange}
            onBlur={this.handleBlur}
            autoComplete="off"
            accept={'application/pdf'}
          />
        </div>
      </LabelledFormField>
    );
  }
}

export default MutliFilePageField;
