import { Component } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import {
  PagePrimarySize,
  PaneType,
  FieldType,
  ActionType,
  ShellModalSize,
} from 'src/views/definitionBuilders/types';
import CrudPage, { CrudPageMode, ICrudPageDef } from 'src/views/components/Page/pages/CrudPage';
import { PlusIcon, TrashIcon } from 'src/images/icons';
import { getSubmitCloseModalActionGroupDef } from 'src/views/definitionBuilders/common';

type TimesheetAllowanceItem = People.Domain.Queries.TimesheetAllowance.TimesheetAllowanceItem;
type CreateTimesheetAllowanceCommand = People.Domain.Commands.TimesheetAllowance.CreateTimesheetAllowance.CreateTimesheetAllowanceCommand;
type UpdateTimesheetAllowanceCommand = People.Domain.Commands.TimesheetAllowance.UpdateTimesheetAllowance.UpdateTimesheetAllowanceCommand;

export interface IMaintainTimesheetAllowanceProps {
  mode: CrudPageMode;
  canManageTimesheetAllowances: boolean;

  onLoadTimesheetAllowance: (id: string) => Promise<void>;
  timesheetAllowance: TimesheetAllowanceItem | undefined;

  checkForUniqueTimesheetAllowanceName: (
    name: string
  ) => Promise<Common.Dtos.UniqueNameCheckResultDto>;
  onCreateTimesheetAllowance: (command: CreateTimesheetAllowanceCommand) => Promise<void>;
  onUpdateTimesheetAllowance: (command: UpdateTimesheetAllowanceCommand) => Promise<void>;
  onDeleteTimesheetAllowance: (timesheetAllowanceId: string) => Promise<void>;
  onReactivateTimesheetAllowance: (timesheetAllowanceId: string) => Promise<void>;
}

interface IUpdateTimesheetAllowanceRouteParams {
  id: string;
}

type InternalProps = IMaintainTimesheetAllowanceProps &
  RouteComponentProps<IUpdateTimesheetAllowanceRouteParams>;

class MaintainTimesheetAllowance extends Component<InternalProps> {
  private get isUpdateMode() {
    return this.props.mode === 'update';
  }

  private get timesheetAllowanceId() {
    return this.props.match.params.id;
  }

  private handlePreSubmitForCreate = (
    timesheetAllowance: TimesheetAllowanceItem
  ): CreateTimesheetAllowanceCommand => {
    return {
      name: timesheetAllowance.name,
      payrollCode: timesheetAllowance.payrollCode,
    };
  };

  private handlePreSubmitForUpdate = (
    timesheetAllowance: TimesheetAllowanceItem
  ): UpdateTimesheetAllowanceCommand => {
    return {
      id: this.timesheetAllowanceId,
      name: timesheetAllowance.name,
      payrollCode: timesheetAllowance.payrollCode,
    };
  };

  private readonly getPageDef = (updating: boolean): ICrudPageDef => {
    const {
      checkForUniqueTimesheetAllowanceName,
      timesheetAllowance: loadedTimesheetAllowance,
      canManageTimesheetAllowances,
    } = this.props;
    return {
      primarySize: PagePrimarySize.half,
      primarySection: {
        title: this.isUpdateMode ? 'Manage Timesheet Allowance' : 'Create a Timesheet Allowance',
        badge: {
          label: this.isUpdateMode
            ? loadedTimesheetAllowance && loadedTimesheetAllowance.active
              ? 'Active'
              : 'Inactive'
            : '',
        },
        primaryActions: [
          {
            actions: [
              {
                actionType: ActionType.actionCollection,
                hidden: updating || !this.isUpdateMode || !canManageTimesheetAllowances,
                actionGroups: [
                  {
                    actions: [
                      {
                        actionType: ActionType.modalActionButton,
                        label: 'Delete Timesheet Allowance',
                        hidden: loadedTimesheetAllowance && !loadedTimesheetAllowance.active,
                        icon: <TrashIcon />,
                        modalSize: ShellModalSize.oneQuarter,
                        modalDef: modalDefApi => ({
                          title: 'Delete Timesheet Allowance',
                          asForm: true,
                          panels: [
                            {
                              panes: [
                                {
                                  paneType: PaneType.customPane,
                                  render: () => (
                                    <span>
                                      Are you sure you want to delete this timesheet allowance ?
                                    </span>
                                  ),
                                },
                              ],
                            },
                          ],
                          secondaryActions: [getSubmitCloseModalActionGroupDef('Ok')],
                          onFormSubmit: () =>
                            this.props.onDeleteTimesheetAllowance(this.timesheetAllowanceId),
                        }),
                      },
                      {
                        actionType: ActionType.actionButton,
                        label: 'Reactivate',
                        hidden: loadedTimesheetAllowance && loadedTimesheetAllowance.active,
                        onClick: () =>
                          this.props.onReactivateTimesheetAllowance(this.timesheetAllowanceId),
                        icon: <PlusIcon />,
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
        panels: [
          {
            panes: [
              {
                paneType: PaneType.formFieldsPane,
                columnCount: 1,
                fields: [
                  {
                    fieldType: FieldType.textField,
                    label: 'Name',
                    dataAddr: 'name',
                    maxLength: 200,
                    mandatory: true,
                    validateAsync: async d => {
                      if (
                        !d.fieldValue ||
                        (this.isUpdateMode &&
                          loadedTimesheetAllowance &&
                          loadedTimesheetAllowance.name.toUpperCase() ===
                            d.fieldValue.toUpperCase())
                      ) {
                        return undefined;
                      }
                      const result = await checkForUniqueTimesheetAllowanceName(d.fieldValue);
                      return result.nameExists ? `Name is already in use` : undefined;
                    },
                  },
                  {
                    fieldType: FieldType.textField,
                    label: 'Payroll Code',
                    dataAddr: 'payrollCode',
                    maxLength: 200,
                    mandatory: true,
                  },
                ],
              },
            ],
          },
        ],
        onFormPreSubmit: this.isUpdateMode
          ? this.handlePreSubmitForUpdate
          : this.handlePreSubmitForCreate,
        onFormSubmit: this.isUpdateMode
          ? this.props.onUpdateTimesheetAllowance
          : this.props.onCreateTimesheetAllowance,
      },
    };
  };

  render() {
    const { mode, timesheetAllowance, canManageTimesheetAllowances } = this.props;

    return (
      <CrudPage
        def={({ updating }) => this.getPageDef(updating)}
        mode={mode}
        isEditingForbidden={!canManageTimesheetAllowances}
        onLoadData={() => this.props.onLoadTimesheetAllowance(this.timesheetAllowanceId)}
        data={timesheetAllowance}
        createDefaultData={{}}
      />
    );
  }
}

export default MaintainTimesheetAllowance;
