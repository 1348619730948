import { types, flow } from 'mobx-state-tree';
import { Subject } from 'rxjs/Subject';
import { Observable } from 'rxjs/Observable';
import { getAjax } from 'src/domain/services';
import { IPaginationPageLoadDataRequest } from 'src/domain/baseTypes';
import { DateTime } from 'luxon';

const defaultPageSize = 25;

interface IPaginationRequestData<T> {
  ajax: ReturnType<typeof getAjax>;
  page: number;
  query: Partial<T>;
}

export function buildPaginationPageApiSearchModelTypeQuery<
  TQuery extends Common.BasicSearchQuery
>() {
  return <TItem>(
    modelName: string,
    getList: (data: IPaginationRequestData<TQuery>) => Observable<Common.Dtos.ListResult<TItem>>,
    equalityComparer: (value: TItem, value2: TItem) => boolean,
    options?: { size?: number }
  ) =>
    buildPaginationPageApiSearchModel<TQuery, TItem>(modelName, getList, equalityComparer, options);
}

export function buildPaginationPageApiSearchModel<TQuery extends Common.BasicSearchQuery, TItem>(
  modelName: string,
  getList: (data: IPaginationRequestData<TQuery>) => Observable<Common.Dtos.ListResult<TItem>>,
  equalityComparer: (value: TItem, value2: TItem) => boolean,
  options?: { size?: number }
) {
  const size = (options && options.size) || defaultPageSize;
  return types
    .model(modelName, {
      items: types.array(types.frozen<TItem>()),
      totalResults: types.optional(types.number, 0),
      size: types.optional(types.literal(size), size),
      page: types.optional(types.number, 1),
      query: types.optional(types.frozen<Partial<TQuery>>(), {}),
      lastUpdated: types.maybe(types.frozen<DateTime>()),
    })
    .actions(self => {
      const ajax = getAjax(self);
      const searchSubject = new Subject<{
        page: number;
        query: Partial<TQuery>;
      }>();
      const searchResult$ = searchSubject
        // Only keep waiting for the latest getList result (cancel older ones)
        .switchMap(r =>
          getList({
            ajax,
            page: r.page,
            query: Object.assign({}, r.query, { page: r.page, size }),
          }).map(response => ({
            hasMore: response.hasMore,
            totalResults: response.totalResults,
            results: response.items,
          }))
        )
        .share(); // we only want one copy of this

      const refreshItems = flow(function*(request: IPaginationPageLoadDataRequest<TQuery>) {
        const searchPromise = searchResult$.take(1).toPromise();
        searchSubject.next({
          page: 1,
          query: request.query,
        });
        const result: {
          results: TItem[];
          hasMore: boolean;
          totalResults: number;
        } = yield searchPromise;

        result.results.forEach(r => {
          const index = self.items.findIndex(f => equalityComparer(f, r));
          if (index > -1) {
            self.items[index] = r as Extract<TItem, object>;
          }
        });
        self.lastUpdated = DateTime.local();
      });

      const listItems = flow(function*(request: IPaginationPageLoadDataRequest<TQuery>) {
        self.page = request.page;
        // Typescript doesn't think we can assign to query. STNValue issues, not sure how to fix it.
        // @ts-ignore
        self.query = request.query;

        const searchPromise = searchResult$.take(1).toPromise();
        searchSubject.next({
          page: self.page,
          query: self.query,
        });
        const result: {
          results: TItem[];
          hasMore: boolean;
          totalResults: number;
        } = yield searchPromise;

        self.items.replace(result.results as Extract<TItem, object>[]);
        self.totalResults = result.totalResults;
        self.lastUpdated = DateTime.local();
      });

      return { listItems, refreshItems };
    });
}

export default buildPaginationPageApiSearchModel;
