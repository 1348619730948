import { IRootStoreModel } from 'src/domain/entities/RootStoreModel';
import { UiRoute } from 'src/domain/services/buildUiRoutes';
import { UiRouteName } from 'src/domain/baseTypes';

export default function buildUiRoutes(store: IRootStoreModel) {
  const hourlyRatesManage = new UiRoute(
    UiRouteName.salesHourlyRateManage,
    '/sales/hourly-rates',
    () => 'Manage Hourly Rates',
    false
  );

  return {
    [UiRouteName.salesHourlyRateManage]: hourlyRatesManage,
  };
}
