import {
  AggregatesWithAttachments,
  LoadAttachmentsQuery,
} from 'src/views/components/Attachment/attachmentHelper';
import { getSubmitCloseModalActionGroupDef } from 'src/views/definitionBuilders/common';
import { FieldType, ModalDefBuilder, PaneType } from 'src/views/definitionBuilders/types';

export type UpdateAttachmentFormData = {
  attachmentDetailsId: number;
  aggregateId: string;
  aggregateType: AggregatesWithAttachments;
  fileName: string;
};

export default function getUpdateAttachmentModalDef(
  attachmentData: UpdateAttachmentFormData,
  onSubmit: (data: UpdateAttachmentFormData) => Promise<void>,
  onSubmitSucceed: (query: LoadAttachmentsQuery) => Promise<void>,
  aggregateType: AggregatesWithAttachments
): ModalDefBuilder {
  return _ => ({
    title: `Edit Attachment`,
    asForm: true,
    panels: [
      {
        panes: [
          {
            paneType: PaneType.formFieldsPane,
            fields: [
              {
                fieldType: FieldType.textField,
                label: 'File name',
                mandatory: true,
                dataAddr: 'fileName',
                validate: data => {
                  if (data.fieldValue && !data.fieldValue.endsWith('.pdf')) {
                    return 'Please ensure the filename ends with .pdf';
                  }

                  if (
                    aggregateType &&
                    aggregateType !== 'staffmember' &&
                    data.fieldValue === attachmentData.fileName
                  ) {
                    return 'File name must be different from current name';
                  }

                  if (data.fieldValue && data.fieldValue.trim() === '.pdf') {
                    return '\'.pdf\' is not a valid file name';
                  }
                  return undefined;
                },
              },
            ],
          },
        ],
      },
    ],
    secondaryActions: [getSubmitCloseModalActionGroupDef('Ok')],
    onFormSubmit: (values: UpdateAttachmentFormData) => {
      return onSubmit({
        attachmentDetailsId: attachmentData.attachmentDetailsId,
        aggregateId: attachmentData.aggregateId,
        aggregateType: attachmentData.aggregateType,
        fileName: values.fileName,
      });
    },
    onSubmitSucceeded: () =>
      onSubmitSucceed({
        aggregateId: attachmentData.aggregateId,
        aggregateType: attachmentData.aggregateType,
      }),
  });
}
