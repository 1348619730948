import { Component } from 'react';
import './SidePanel.scss';
import { Button } from 'reactstrap';
import { AngleLeft, AngleRight } from 'src/images/icons';

export interface ISidePanelProps {
  heading: string;
  className?: string;
}

interface ISidePanelState {
  open: boolean;
}

class SidePanel extends Component<ISidePanelProps, ISidePanelState> {
  constructor(props: ISidePanelProps) {
    super(props);
    this.state = {
      open: false,
    };
  }

  toggle() {
    this.setState({ open: !this.state.open });
  }

  renderClosed() {
    return (
      <div className="closed">
        <div>
          <Button
            size="sm"
            color="secondary"
            outline
            className="expand"
            onClick={() => this.toggle()}>
            <AngleRight />
          </Button>
        </div>
        <div className="vertical-text">{this.props.heading}</div>
      </div>
    );
  }

  renderOpen() {
    return (
      <div className="open">
        <div className="heading">
          <span>{this.props.heading}</span>
          <Button
            size="sm"
            color="secondary"
            outline
            className="collapse"
            onClick={() => this.toggle()}>
            <AngleLeft />
          </Button>
        </div>
        <div className="children">{this.props.children}</div>
      </div>
    );
  }

  render() {
    return (
      <div
        className={`side-panel-component ${this.props.className} ${
          this.state.open ? 'open-panel' : 'closed-panel'
        }`}>
        {this.state.open ? this.renderOpen() : this.renderClosed()}
      </div>
    );
  }
}

export default SidePanel;
