import { types, flow } from 'mobx-state-tree';
import { getAjax, getBus } from 'src/domain/services/storeEnvironment';

type AcceptConflictCommand = Operations.Domain.Commands.Conflict.AcceptConflict.AcceptConflictCommand;

export const ConflictModel = types.model('ConflictModel', {}).actions(self => {
  const ajax = getAjax(self);
  const bus = getBus(self);

  const acceptConflict = flow(function*(command: AcceptConflictCommand) {
    yield ajax.operations.conflicts.acceptConflict(command);
    bus.conflictAccepted(command);
  });

  const cancelAcceptanceConflict = flow(function*(conflictId: string) {
    yield ajax.operations.conflicts.cancelAcceptanceConflict(conflictId);
    bus.conflictAcceptanceCancelled({ conflictId });
  });

  return {
    acceptConflict,
    cancelAcceptanceConflict,
  };
});
