import './JobStatusBadge.scss';
import { getJobStatusDescriptor } from 'src/api/enums';

interface IJobStatusBadgeProps {
  jobStatus: Operations.Domain.AggregatesModel.JobAggregate.JobStatus;
}

const JobStatusBadge = ({ jobStatus }: IJobStatusBadgeProps) => {
  return (
    <div className={'wrapper'}>
      {jobStatus && jobStatus.id ? (
        <span
          className={`job-status-badge-component ${getJobStatusDescriptor(jobStatus?.id).name}`}>
          {jobStatus.description}
        </span>
      ) : (
        <span />
      )}
    </div>
  );
};

export default JobStatusBadge;
