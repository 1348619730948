import { Input } from 'reactstrap';
import { LabelledFormField } from 'src/views/components/forms';
import { ITextAreaFieldDef, IFieldMeta, IFieldData } from 'src/views/definitionBuilders/types';
import { IFieldApi } from 'src/views/components/Page/forms/Field';
import { PureComponent } from 'react';

interface ITextAreaPageFieldProps {
  fieldApi: IFieldApi;
  fieldDef: ITextAreaFieldDef;
  fieldMeta: IFieldMeta;
  fieldData: IFieldData<string>;
}

class TextAreaPageField extends PureComponent<ITextAreaPageFieldProps> {
  private readonly handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { fieldApi } = this.props;
    fieldApi.setValue(e.target.value);
  };

  private readonly handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    this.props.fieldApi.setTouched(true);
    this.props.fieldMeta.onBlur && this.props.fieldMeta.onBlur(this.props.fieldData.fieldValue);
  };

  render() {
    const { fieldApi, fieldDef: def, fieldMeta: meta, fieldData: data } = this.props;
    const { error, touched } = fieldApi;
    const currentValue = (data.fieldValue || '') as string;
    const safeError = touched && error;
    const extraInputProps = { rows: def.rows || 3 };
    const labelText = typeof def.label === 'function' ? def.label(data) : def.label;
    const tooltipText = typeof def.tooltip === 'function' ? def.tooltip(data) : def.tooltip;
    return (
      <LabelledFormField
        className="text-area-page-field-component"
        readonly={meta.readonly || false}
        readonlyValue={def.formatReadonly ? def.formatReadonly(data) : currentValue}
        readonlyLinkTo={def.linkTo && currentValue ? def.linkTo(data) : undefined}
        mandatory={meta.mandatory}
        hideLabel={meta.hideLabel}
        labelValue={labelText}
        tooltipValue={tooltipText}
        noForm={meta.noForm}
        maxLength={def.maxLength}
        currentLength={currentValue.length}
        showCounter={def.showCounter}
        error={safeError}>
        <Input
          {...extraInputProps}
          autoComplete="off"
          type="textarea"
          className={!safeError ? '' : 'invalid'}
          // disabled={disabled}
          autoFocus={meta.autoFocus}
          value={currentValue}
          onChange={this.handleChange}
          maxLength={def.maxLength}
          onBlur={this.handleBlur}
        />
      </LabelledFormField>
    );
  }
}

export default TextAreaPageField;
