import styles from './HomeTile.module.scss';
import { Link } from 'react-router-dom';

type TileProps = {
  linkTo: string;
  tileTitle: string;
  tileCaption: string;
};

export const HomeTile = ({ linkTo, tileTitle, tileCaption }: TileProps) => (
  <div className={styles.tileContainer}>
    <Link to={linkTo}>
      <div className={styles.tileCards}>
        <li>
          <h2 className={styles.tileTitle}>{tileTitle}</h2>
          <p className={styles.tileCaption}>{tileCaption}</p>
        </li>
      </div>
    </Link>
  </div>
);

export default HomeTile;
