import styles from './KeyPad.module.scss';
import { Button, Input } from 'reactstrap';
import InlineSvg from 'react-inlinesvg';
import clearSymbolIcon from 'src/images/clear_symbol.svg';
import { unsignedIntegerSanitiser } from 'src/views/components/Page/fields/NumericPageField';
import { CheckIcon } from 'src/images/icons';
import cn from 'classnames';
import { SingleClickAwareButton } from '../SingleClickAwareButton/SingleClickAwareButton';
import { Component, createRef } from 'react';

interface IKeyPadProps {
  submit: (pin: string) => Promise<void>;
  pinLength: number;
}

interface IKeyPadState {
  pin: string;
}

class KeyPad extends Component<IKeyPadProps, IKeyPadState> {
  private pinInput: React.RefObject<HTMLInputElement>;

  constructor(props: IKeyPadProps) {
    super(props);
    this.state = {
      pin: '',
    };
    this.pinInput = createRef();
  }

  numberEnter(value: string) {
    if (this.state.pin.length >= this.props.pinLength) {
      return;
    }
    this.setState(
      { pin: this.state.pin + value },
      () => this.pinInput.current && this.pinInput.current.focus()
    );
  }

  back() {
    this.setState(
      { pin: this.state.pin.substring(0, this.state.pin.length - 1) },
      () => this.pinInput.current && this.pinInput.current.focus()
    );
  }

  handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    const pin = unsignedIntegerSanitiser(event.target.value, {
      maxValue: 9999,
    });
    this.setState({
      pin: pin ? pin.toString() : '',
    });
  }

  handleSubmit(event: React.FormEvent<EventTarget>) {
    if (this.state.pin.length > 0) {
      this.props.submit(this.state.pin);
    }
    event.preventDefault();
  }

  renderNumberButton(value: string) {
    return (
      <SingleClickAwareButton
        useReactStrapButton
        type="button"
        className={styles.btn}
        onClick={() => this.numberEnter(value)}>
        {value}
      </SingleClickAwareButton>
    );
  }

  render() {
    return (
      <div className={styles.loginComponent}>
        <form onSubmit={e => this.handleSubmit(e)} className={styles.keypad} autoComplete="off">
          <div className={styles.pinWrapper}>
            <Input
              autoComplete="off"
              type="text"
              name="pin"
              id="pin"
              className={styles.pin}
              maxLength={4}
              max={9999}
              placeholder="Enter Pin..."
              value={this.state.pin}
              onChange={e => this.handleChange(e)}
              autoFocus
              innerRef={this.pinInput}
            />
          </div>
          <div className={styles.one}>{this.renderNumberButton('1')}</div>
          <div className={styles.two}>{this.renderNumberButton('2')}</div>
          <div className={styles.three}>{this.renderNumberButton('3')}</div>
          <div className={styles.four}>{this.renderNumberButton('4')}</div>
          <div className={styles.five}>{this.renderNumberButton('5')}</div>
          <div className={styles.six}>{this.renderNumberButton('6')}</div>
          <div className={styles.seven}>{this.renderNumberButton('7')}</div>
          <div className={styles.eight}>{this.renderNumberButton('8')}</div>
          <div className={styles.nine}>{this.renderNumberButton('9')}</div>
          <div className={styles.back}>
            <SingleClickAwareButton
              useReactStrapButton
              type="button"
              onClick={() => this.back()}
              className={cn([styles.backButton, styles.btn])}>
              <InlineSvg className={styles.iconImage} src={clearSymbolIcon} />
            </SingleClickAwareButton>
          </div>
          <div className={styles.enter}>
            <Button type="submit" className={cn([styles.submitButton, styles.btn])}>
              <CheckIcon />
            </Button>
          </div>
          <div className={styles.zero}>{this.renderNumberButton('0')}</div>
        </form>
      </div>
    );
  }
}

export default KeyPad;
