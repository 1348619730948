import styles from './JobDetails.module.scss';
import SplitScreen from '../components/layout/SplitScreen/SplitScreen';
import { SignInIcon, CheckIcon, BanIcon, PrintIcon } from 'src/images/icons';
import { RouteComponentProps } from 'react-router-dom';
import cn from 'classnames';
import SlideInScreen from '../components/layout/SlideInScreen/SlideInScreen';
import Task from '../task/Task';
import ButtonCard from '../components/presentational/ButtonCard';
import AssetDetails from '../AssetDetails/AssetDetails';
import WithBackButton from '../menu/WithBackButton';
import { OnHoldIcon } from '../components/presentational/OnHoldIcon';
import { JobTaskStatus } from 'src/api/enums';
import { FormattedDateTime } from '../../components/presentational/FormattedDateTime/FormattedDateTime';
import { DateTime } from 'luxon';
import PrintJobSheet, {
  jobSheetPdfHeader,
} from 'src/views/routes/workshop/jobs/listJobs/PrintJobSheet/PrintJobSheet';
import PrintButton from 'src/views/components/PrintButton';
import { SingleClickAwareButton } from '../../components/presentational/SingleClickAwareButton/SingleClickAwareButton';
import { Component } from 'react';

const SplitWidth = 50;
type JobDetailsForKioskDto = Workshop.Domain.Queries.Job.GetJobDetailsForKiosk.JobDetailsForKioskDto;
type JobTaskDetailsForKioskDto = Workshop.Domain.Queries.Job.GetJobDetailsForKiosk.JobTaskDetailsForKioskDto;
type StaffMemberName = Common.Queries.GetStaffMemberNames.StaffMemberName;
type ExtendJobCommand = Workshop.Domain.Commands.Job.ExtendJobCommand;
type NextOpsJobForAsset = Common.Queries.Workshop.GetNextOpsJobForAsset.NextOpsJob;

export interface IJobDetailsProps {
  job?: JobDetailsForKioskDto;
  extendJob: (command: ExtendJobCommand) => Promise<void>;
  loadJob: (jobId: string) => Promise<void>;
  loggedInStaffMemberId?: string;
  goBack: () => void;
  nextOpsJobForAsset?: NextOpsJobForAsset;
  loadNextOpsJobsForAsset: (assetId: string) => Promise<void>;
  jobToPrint: Workshop.Domain.Queries.Job.PrintJobQuery.JobItem | undefined;
  loadPrintJobSheet: (jobId: string) => Promise<void>;

  loadFutureTasks: (assetId: string) => Promise<void>;
  loadScheduledTasks: (assetId: string) => Promise<void>;
  loadStartedTasks: (assetId: string) => Promise<void>;

  deviceDepotId: number;
}

interface IJobDetailsRouteParams {
  id: string;
}

interface IJobDetailsState {
  isOpened: boolean;
  currentTaskId?: string;
  showExtensionWarning: boolean;
  submitting: boolean;
}

type InternalProps = IJobDetailsProps & RouteComponentProps<IJobDetailsRouteParams>;

const JobTaskStaffMembers: React.FC<{
  staffMembers: StaffMemberName[];
  loggedInUserId?: string;
}> = ({ staffMembers, loggedInUserId }) => {
  if (staffMembers.length === 0) {
    return null;
  }

  return (
    <div className={styles.staffMembers}>
      {staffMembers.map(s => {
        if (s.id === loggedInUserId) {
          return (
            <div key={s.id} className={styles.you}>
              {s.name}
            </div>
          );
        } else {
          return <div key={s.id}>{s.name}</div>;
        }
      })}
    </div>
  );
};

const JobTask: React.FC<JobTaskDetailsForKioskDto & {
  onClick: () => void;
  loggedInUserId?: string;
}> = ({
  description,
  staffMembers,
  status,
  jobTaskNumber,
  loggedInUserId,
  onClick,
  onHoldStatus,
  fitForService,
}) => (
  <ButtonCard className={styles.card} onClick={onClick}>
    <div className={cn(styles.description, { [styles.hasStaff]: staffMembers.length > 0 })}>
      <div className={styles.jobTaskNumber}>
        <span>{jobTaskNumber}</span>
        <OnHoldIcon onHoldStatus={onHoldStatus} />
      </div>
      <div className={styles.text}>{description}</div>
    </div>
    <JobTaskStaffMembers staffMembers={staffMembers} loggedInUserId={loggedInUserId} />
    <div className={styles.status}>
      {fitForService !== null && status.id !== JobTaskStatus.Completed ? (
        fitForService === true ? (
          <div className={styles.fit} title="Fit for service">
            <span>
              Fit <CheckIcon />
            </span>
          </div>
        ) : (
          <div className={styles.unfit} title="Unfit for service">
            <span>
              Unfit <BanIcon />
            </span>
          </div>
        )
      ) : (
        <span>{status.description}</span>
      )}
      <SignInIcon flip={'horizontal'} title="Open task..." />
    </div>
  </ButtonCard>
);

export class JobDetails extends Component<InternalProps, IJobDetailsState> {
  constructor(props: InternalProps) {
    super(props);
    this.state = {
      isOpened: false,
      showExtensionWarning: false,
      submitting: false,
    };
    this.handleToggle = this.handleToggle.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleExtendJob = this.handleExtendJob.bind(this);
  }

  async componentDidMount() {
    await this.props.loadJob(this.jobId);
    if (this.props.job) {
      this.props.loadNextOpsJobsForAsset(this.props.job.asset.id);
    }
  }

  componentDidUpdate() {
    if (this.jobId !== this.props.job!.jobId) {
      this.props.loadJob(this.jobId);
      if (this.props.job) {
        this.props.loadNextOpsJobsForAsset(this.props.job.asset.id);
      }
    }
  }

  private get jobId() {
    return this.props.match.params.id;
  }

  handleToggle(id: string) {
    this.setState(prevState => {
      return { isOpened: !prevState.isOpened, currentTaskId: id };
    });
  }

  handleClose() {
    const { job, loadJob, loadFutureTasks, loadScheduledTasks, loadStartedTasks } = this.props;

    this.setState({ isOpened: false });
    loadJob(this.jobId);

    const assetId = job && job.asset && job.asset.id;
    if (assetId) {
      Promise.all([
        loadFutureTasks(assetId),
        loadScheduledTasks(assetId),
        loadStartedTasks(assetId),
      ]);
    }
  }

  handleExtendJob(): Promise<void> {
    const { job, extendJob, nextOpsJobForAsset } = this.props;

    if (!job) {
      return Promise.reject();
    }

    if (
      !this.state.showExtensionWarning &&
      nextOpsJobForAsset &&
      DateTime.fromISO(nextOpsJobForAsset.clockOn) > DateTime.fromISO(job.startDateTime) &&
      DateTime.fromISO(nextOpsJobForAsset.clockOn) <
        DateTime.fromISO(job.endDateTime).plus({ minutes: 15 })
    ) {
      this.setState({ showExtensionWarning: true });
    } else {
      this.setState({ submitting: true });
      return extendJob({
        jobId: job.jobId,
        endDateTime: DateTime.fromISO(job.endDateTime)
          .plus({ minutes: 15 })
          .toISO(),
      })
        .then(_ => {
          this.setState({ showExtensionWarning: false });
        })
        .finally(() => this.setState({ submitting: false }));
    }

    return Promise.resolve();
  }

  render() {
    const {
      job,
      jobToPrint,
      loadPrintJobSheet,
      loggedInStaffMemberId,
      goBack,
      deviceDepotId,
    } = this.props;

    if (!job) {
      return null;
    }

    const yourTasks =
      job.jobTasks &&
      job.jobTasks.filter(t => t.staffMembers.some(s => s.id === loggedInStaffMemberId));
    const otherTasks =
      job.jobTasks &&
      job.jobTasks.filter(t => t.staffMembers.every(s => s.id !== loggedInStaffMemberId));

    const left = (
      <div className={styles.left}>
        <WithBackButton goBack={goBack}>
          <AssetDetails assetId={job.asset.id} currentJob={job} />
        </WithBackButton>
      </div>
    );

    const right = (
      <div className={styles.right}>
        <SlideInScreen
          click={this.handleClose}
          show={this.state.isOpened}
          isRightAligned
          className={styles.taskDetails}
          width={50}>
          {this.state.currentTaskId && (
            <Task
              id={this.state.currentTaskId}
              afterSubmit={() => {
                this.handleClose();
              }}
            />
          )}
        </SlideInScreen>
        <div className={styles.jobInfo}>
          <div className={styles.jobNumber}>
            <h1>Job {job.jobNumber}</h1>
            <PrintButton
              className="print-button"
              size="sm"
              outline
              printTitle={jobToPrint && jobSheetPdfHeader(jobToPrint)}
              loadDataAsync={() => loadPrintJobSheet(job.jobId)}
              printContent={() =>
                jobToPrint && <PrintJobSheet job={jobToPrint} depotId={deviceDepotId} />
              }>
              <PrintIcon />
              Print Job Sheet
            </PrintButton>
          </div>
          <div className={styles.boundsContainer}>
            <div>
              <FormattedDateTime dateTime={job.startDateTime} />
              <span> - </span>
              <FormattedDateTime dateTime={job.endDateTime} referenceDateTime={job.startDateTime} />
            </div>
            {DateTime.fromISO(job.endDateTime) >
              DateTime.local().minus({
                hours: 3,
              }) &&
              DateTime.fromISO(job.startDateTime) && (
                <>
                  {this.state.showExtensionWarning ? (
                    <div className={styles.extensionWarning}>
                      <span>
                        Extending the job will conflict with an operations job. Contact Operations
                        if you decide to go ahead.
                      </span>
                      <div>
                        <SingleClickAwareButton onClick={this.handleExtendJob}>
                          + 15 mins
                        </SingleClickAwareButton>
                        <SingleClickAwareButton
                          onClick={() => this.setState({ showExtensionWarning: false })}>
                          Cancel
                        </SingleClickAwareButton>
                      </div>
                    </div>
                  ) : (
                    <SingleClickAwareButton onClick={this.handleExtendJob}>
                      + 15 mins
                    </SingleClickAwareButton>
                  )}
                </>
              )}
          </div>
        </div>
        <div className={styles.tasksContainer}>
          <div className={styles.taskbox}>
            <h2>Your Tasks</h2>
            {yourTasks.length > 0 ? (
              yourTasks.map(t => (
                <JobTask
                  key={t.id}
                  {...t}
                  loggedInUserId={loggedInStaffMemberId}
                  onClick={() => this.handleToggle(t.id)}
                />
              ))
            ) : (
              <div>There are no tasks assigned to you.</div>
            )}
          </div>
          <div className={styles.taskbox}>
            <h2>Other Tasks</h2>
            {otherTasks.length > 0 ? (
              otherTasks.map(t => (
                <JobTask
                  key={t.id}
                  {...t}
                  loggedInUserId={loggedInStaffMemberId}
                  onClick={() => this.handleToggle(t.id)}
                />
              ))
            ) : (
              <div>There are no other tasks.</div>
            )}
          </div>
        </div>
      </div>
    );

    return (
      <div className={styles.jobDetails}>
        <SplitScreen left={left} right={right} leftWidth={SplitWidth} />
      </div>
    );
  }
}
