import { IAjax } from 'src/infrastructure/ajax';
import { peopleUrls } from 'src/domain/services/apiUrls';
import * as queryString from 'query-string';

type CreateLeaveCommand = People.Domain.Commands.Leaves.CreateLeave.CreateLeaveCommand;
type UpdateLeaveCommand = People.Domain.Commands.Leaves.UpdateLeave.UpdateLeaveCommand;
type LeaveCommandResult = People.Domain.Commands.Leaves.LeaveCommandResult;
type ApproveLeaveCommand = People.Domain.Commands.Leaves.ApproveLeave.ApproveLeaveCommand;
type ScheduledLeave = People.Domain.Queries.ListScheduledLeave.ScheduledLeaveItem;
type ListScheduledLeaveQuery = People.Domain.Queries.ListScheduledLeave.ListScheduledLeaveQuery;

export default function init(ajax: IAjax) {
  function createLeave(command: CreateLeaveCommand) {
    return ajax
      .post(peopleUrls.staffMemberUrls.createLeave(), command)
      .toPromise()
      .then<LeaveCommandResult>(o => o.response);
  }

  function updateLeave(command: UpdateLeaveCommand) {
    return ajax
      .put(peopleUrls.staffMemberUrls.updateLeave(command.leaveId), command)
      .toPromise()
      .then<LeaveCommandResult>(o => o.response);
  }

  function deleteLeave(
    leaveId: string,
    deleteRecurrences: boolean,
    applyToFutureRecurringLeave: boolean
  ) {
    return ajax
      .httpDelete(
        peopleUrls.staffMemberUrls.deleteLeave(
          leaveId,
          deleteRecurrences,
          applyToFutureRecurringLeave
        )
      )
      .toPromise()
      .then<LeaveCommandResult>(o => o.response);
  }

  function approveLeave(command: ApproveLeaveCommand) {
    return ajax
      .put(`/api/people/leaves/${command.id}/approve`, command)
      .toPromise()
      .then<LeaveCommandResult>(o => o.response);
  }

  function declineLeave(command: People.Domain.Commands.Leaves.DeclineLeave.DeclineLeaveCommand) {
    return ajax
      .put(`/api/people/leaves/${command.id}/decline`, command)
      .toPromise()
      .then<LeaveCommandResult>(o => o.response);
  }

  function listScheduledLeave(query: ListScheduledLeaveQuery) {
    return ajax
      .get(`/api/people/scheduled-leave?${queryString.stringify(query)}`)
      .toPromise()
      .then<Common.Dtos.ListResult<ScheduledLeave>>(o => o.response);
  }

  return {
    leave: {
      createLeave,
      updateLeave,
      deleteLeave,
      approveLeave,
      declineLeave,
      listScheduledLeave,
    },
  };
}
