import inject from 'src/views/injectFromStore';
import Omit from 'src/infrastructure/omit';
import MaintainRosterTemplate, { IMaintainRosterTemplate } from './MaintainRosterTemplate';

const Container = inject<Omit<IMaintainRosterTemplate, 'mode'>, IMaintainRosterTemplate>(
  (allStores, p) => ({
    canManageRosterTemplates: allStores.rootStore.account.isWorkshopDepartmentMember,

    loadRosterTemplate: allStores.rootStore.workshop.rosterTemplate.loadRosterTemplate,
    rosterTemplate: allStores.rootStore.workshop.rosterTemplate.rosterTemplate,
    checkForUniqueRosterTemplateName:
      allStores.rootStore.workshop.rosterTemplate.checkForUniqueRosterTemplateName,
    createRosterTemplate: allStores.rootStore.workshop.rosterTemplate.createRosterTemplate,
    updateRosterTemplate: allStores.rootStore.workshop.rosterTemplate.updateRosterTemplate,
    deleteRosterTemplate: allStores.rootStore.workshop.rosterTemplate.deleteRosterTemplate,

    listShiftTemplates: allStores.rootStore.workshop.shiftTemplates.listItems,
    shiftTemplates: allStores.rootStore.workshop.shiftTemplates.items.slice(),

    mechanics: allStores.rootStore.people.staffMembers.mechanics.slice(),
    loadMechanics: allStores.rootStore.people.staffMembers.loadMechanics,

    workshopDepots: allStores.rootStore.workshopStartup.workshopDepots.slice(),
    defaultWorkshopDepot: allStores.rootStore.workshopStartup.defaultWorkshopDepot,
  })
)(MaintainRosterTemplate);

export default Container;
