import './Login.scss';
import KeyPad from '../../components/presentational/KeyPad/KeyPad';
import AnonymousHeader from 'src/views/kioskRoutes/components/presentational/AnonymousHeader/AnonymousHeader';

export interface ILoginProps {
  signIn: (employeeId: string) => Promise<void>;
}

const Login = ({ signIn }: ILoginProps) => (
  <div className={'login-component'}>
    <AnonymousHeader />
    <div className={'keypad'}>
      <KeyPad submit={signIn} pinLength={4} />
    </div>
  </div>
);

export default Login;
