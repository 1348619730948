import './RepeatingTabPagePane.scss';
import { FC, Fragment, useEffect, useState } from 'react';
import cn from 'classnames';
import { FormFeedback, Button } from 'reactstrap';
import { NestedField, withFormApi } from 'src/views/components/Page/forms';
import { IFormApi } from 'src/views/components/Page/forms/base';
import { ChangeState } from 'src/api/enums';
import {
  IPaneData,
  IPaneMeta,
  IHasChangeState,
  IRepeatingTabPaneDef,
} from 'src/views/definitionBuilders/types';
import PagePane from './PagePane';

interface IRepeatingTabPagePaneTabProps {
  paneDef: IRepeatingTabPaneDef;
  paneData: IPaneData;
  paneMeta: IPaneMeta;
  // tslint:disable-next-line:no-any
  item: any;
  itemIdx: number;
  currentTabIndex: number | null;
  onRemoveItem: () => void;
}

const RepeatingTabPagePaneTab: FC<IRepeatingTabPagePaneTabProps> = ({
  paneDef,
  paneData,
  paneMeta,
  item,
  itemIdx,
  onRemoveItem,
}) => (
  <NestedField field={itemIdx}>
    {paneDef.itemPanes.map((p, subPaneIdx) => {
      return (
        <PagePane
          key={`${itemIdx}-${subPaneIdx}`}
          paneDef={p}
          paneMeta={{
            ...paneMeta,
            removeArrayItem: onRemoveItem,
          }}
          panelData={paneData}
          parentValue={item}
        />
      );
    })}
  </NestedField>
);

interface IRepeatingTabPagePaneProps {
  formApi: IFormApi;
  paneDef: IRepeatingTabPaneDef;
  paneData: IPaneData;
  paneMeta: IPaneMeta;
}

interface IRepeatingTabPagePaneState {
  currentTabIndex: number | null;
  tabCount: number;
  currentTab: any;
}

const RepeatingTabPagePane: FC<IRepeatingTabPagePaneProps> = ({
  formApi,
  paneDef,
  paneMeta,
  paneData,
}) => {
  const [state, updateTheState] = useState<IRepeatingTabPagePaneState>({
    currentTabIndex: null,
    tabCount: 0,
    currentTab: undefined,
  });

  const findFirstValidRow = (rows: IHasChangeState[]) => {
    for (let i = 0; i < rows.length; i++) {
      if (rows[i].changeState !== ChangeState.Deleted) {
        return i;
      }
    }
    return null;
  };

  const findLastValidRow = (rows: IHasChangeState[]) => {
    for (let i = rows.length - 1; i >= 0; i--) {
      if (rows[i].changeState !== ChangeState.Deleted) {
        return i;
      }
    }
    return null;
  };

  useEffect(() => {
    const nextItems = Array.isArray(paneData.panelValue) ? paneData.panelValue : [];

    let currentTabIndex = state.currentTabIndex;

    // Try to handle if the order of items is changed (will only work if the instances are the same as they were)
    // This apparently works via value equality and not instance, BUT...
    // All empty/newly added tabs are considered equal, so it picks the first one.
    // In most cases, we don't do reordering, so don't try to support it.
    // Further, in cases where we do, the tabs aren't empty, and the detection should work fine.
    if (paneDef.allowReordering) {
      const idxByInstance = nextItems.indexOf(state.currentTab);
      currentTabIndex = idxByInstance < 0 ? state.currentTabIndex : idxByInstance;
    }

    // Make sure that an appropriate tab is selected if the props change
    const tabCount = nextItems.length;
    const tabCountExcludingDeleted = nextItems.filter(x => x.changeState !== ChangeState.Deleted)
      .length;

    if (tabCount === 0) {
      // No tabs to select
      currentTabIndex = null;
    } else if (currentTabIndex === null) {
      // Select first tab (when no tab was selected previously)
      currentTabIndex = findFirstValidRow(nextItems);
    } else if (
      state.tabCount < tabCountExcludingDeleted ||
      state.tabCount > tabCountExcludingDeleted ||
      currentTabIndex > tabCountExcludingDeleted - 1
    ) {
      // Select last tab (when a new tab has been added, OR if the current tab is no longer valid)
      currentTabIndex = findLastValidRow(nextItems);
    }

    updateTheState({
      tabCount: tabCountExcludingDeleted,
      currentTabIndex,
      currentTab: currentTabIndex === null ? null : nextItems[currentTabIndex],
    });
  }, [paneData]);

  const getDataItems = () => {
    if (Array.isArray(paneData.paneValue)) {
      return paneData.paneValue;
    }
    return [];
  };

  const removeFromArray = () => formApi.removeValue(formApi.getFullField(), state.currentTabIndex!);

  const handleRemoveItem = () => {
    if (paneDef.deleteRemovedItems) {
      removeFromArray();
      return;
    }

    if (state.currentTabIndex === null) {
      return;
    }

    const rows = getDataItems();
    const row = rows[state.currentTabIndex] as IHasChangeState;
    if (row.changeState === ChangeState.Added) {
      removeFromArray();
      return;
    }

    const updatedItems = [...rows];
    const updatedRow = {
      ...row,
      changeState:
        row.changeState === ChangeState.Deleted ? ChangeState.Modified : ChangeState.Deleted,
    };
    updatedItems.splice(state.currentTabIndex, 1, updatedRow);
    formApi.setValue(formApi.getFullField(), updatedItems);
  };

  // tslint:disable-next-line:no-any
  const handleValidate = (value: any[]) => {
    const mandatoryValidate = paneDef.mandatory
      ? (v: Array<IHasChangeState>) =>
          !v || !v.length || v.every(x => x.changeState === ChangeState.Deleted)
            ? `At least one item is required`
            : undefined
      : undefined;

    return paneDef.mandatory || paneDef.validate
      ? (mandatoryValidate && mandatoryValidate(value)) ||
          (paneDef.validate && paneDef.validate(paneData))
      : undefined;
  };

  const items = getDataItems();

  if (!items.length) {
    return null;
  }

  let actualIndex = -1;

  return (
    <div className="repeating-tab-page-pane-component">
      <NestedField validate={paneMeta.readonly ? undefined : handleValidate}>
        {fieldApi => (
          <Fragment>
            <div className="tab-titles">
              {items.length > 1 ? (
                items.map((item, itemIndex) => {
                  if (item.changeState === ChangeState.Deleted) {
                    return null;
                  }
                  actualIndex++;
                  return (
                    <Button
                      className="tab-title"
                      key={itemIndex}
                      outline
                      color="light"
                      onClick={() =>
                        updateTheState({ ...state, currentTab: item, currentTabIndex: itemIndex })
                      }>
                      <span className={cn('tab', { active: itemIndex === state.currentTabIndex })}>
                        {paneDef.getItemTitle({
                          ...paneData,
                          itemValue: item,
                          itemIndex: actualIndex,
                        }) || <em>New</em>}
                      </span>
                    </Button>
                  );
                })
              ) : (
                <h3>
                  {paneDef.getItemTitle({ ...paneData, itemValue: items[0], itemIndex: 0 }) || (
                    <em>New</em>
                  )}
                </h3>
              )}
            </div>
            <ul className="list-unstyled">
              {items.map((item, itemIdx) =>
                item.changeState === ChangeState.Deleted ||
                (paneDef.renderStyle === 'as-required' &&
                  itemIdx !== state.currentTabIndex) ? null : (
                  <li
                    key={itemIdx}
                    className={cn('tab-content', { active: itemIdx === state.currentTabIndex })}>
                    <RepeatingTabPagePaneTab
                      paneDef={paneDef}
                      paneData={paneData}
                      paneMeta={paneMeta}
                      item={item}
                      itemIdx={itemIdx}
                      currentTabIndex={state.currentTabIndex}
                      onRemoveItem={handleRemoveItem}
                    />
                  </li>
                )
              )}
            </ul>
            {!paneMeta.readonly &&
            formApi.submits > 0 &&
            fieldApi.error &&
            typeof fieldApi.error === 'string' ? (
              <FormFeedback className="repeating-tab-errors">{fieldApi.error}</FormFeedback>
            ) : null}
          </Fragment>
        )}
      </NestedField>
    </div>
  );
};

export default withFormApi(RepeatingTabPagePane);
