import styles from './TwoThirdsOneThirdPanel.module.scss';

export const TwoThirdsOneThirdPanel: React.FC<{
  twoThirdChild: React.ReactNode;
  oneThirdChild: React.ReactNode;
}> = ({ twoThirdChild, oneThirdChild }) => (
  <table className={styles.root}>
    <tbody>
      <tr>
        <td>{twoThirdChild}</td>
        <td className={styles.padding} />
        <td className={styles.oneThirdChild}>{oneThirdChild}</td>
      </tr>
    </tbody>
  </table>
);
