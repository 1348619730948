import { types, flow } from 'mobx-state-tree';
import { ListAuthorisedDevicesModel } from './ListAuthorisedDevicesModel';
import { getAjax } from 'src/domain/services/';
import { operationsUrls } from 'src/domain/services/apiUrls';
import * as queryString from 'query-string';

type CreateAuthorisedDeviceCommand = Operations.Domain.Commands.AuthorisedDevices.CreateAuthorisedDeviceCommand;
type AuthorisedDeviceItem = Operations.Domain.Queries.AuthorisedDevices.ListAuthorisedDevices.AuthorisedDeviceItem;
type ListAuthorisedDevicesQuery = Operations.Domain.Queries.AuthorisedDevices.ListAuthorisedDevices.ListAuthorisedDevicesQuery;

const { authorisedDevicesUrls: urls } = operationsUrls;

export const AuthorisedDevicesModel = types
  .model('AuthorisedDevicesModel', {
    list: types.optional(ListAuthorisedDevicesModel, {}),
    isAlcoTestEnabled: types.optional(types.boolean, false),
    allDevices: types.array(types.frozen<AuthorisedDeviceItem>()),
  })
  .actions(self => {
    const ajax = getAjax(self);

    const listAuthorisedDevices = flow(function*(query: Partial<ListAuthorisedDevicesQuery>) {
      self.allDevices = yield ajax.raw
        .get(`/api/operations/authorised-devices?${queryString.stringify(query)}`)
        .toPromise()
        .then(r =>
          r.response.items.map((r: AuthorisedDeviceItem) => ({
            id: r.id,
            name: r.name,
          }))
        );
    });

    const getNameExists = flow(function*(name: string) {
      const exists: boolean = yield ajax.raw
        .get(urls.getNameExists(name))
        .toPromise()
        .then(r => r.response.nameExists);
      return exists;
    });

    const createDevice = flow(function*(cmd: CreateAuthorisedDeviceCommand) {
      yield ajax.raw.post(urls.createDevice(), cmd).toPromise();
    });

    const deleteDevice = flow(function*(deviceId: string) {
      yield ajax.raw.httpDelete(urls.deleteDevice(deviceId)).toPromise();
    });

    const generateAuthorisationCode = flow(function*(deviceId: string) {
      const code = yield ajax.raw
        .post(urls.generateAuthorisationCode(deviceId))
        .toPromise()
        .then(r => r.response && r.response.code);
      return code;
    });

    const revokeAuthorisation = flow(function*(deviceId: string) {
      yield ajax.raw.post(urls.revokeAuthorisation(deviceId)).toPromise();
    });

    const enableAlcoReading = flow(function*(deviceId: string) {
      yield ajax.raw.post(urls.enableAlcoReadingDevice(deviceId)).toPromise();
    });

    const disableAlcoReading = flow(function*(deviceId: string) {
      yield ajax.raw.post(urls.disableAlcoReadingDevice(deviceId)).toPromise();
    });

    const checkAlcoReadingDevice = flow(function*(deviceId: string) {
      yield ajax.raw.post(urls.checkAlcoReadingDevice(deviceId)).toPromise();
    });

    return {
      getNameExists,
      createDevice,
      deleteDevice,
      generateAuthorisationCode,
      revokeAuthorisation,
      enableAlcoReading,
      disableAlcoReading,
      checkAlcoReadingDevice,
      listAuthorisedDevices,
    };
  });
