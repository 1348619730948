import { getSubmitCloseModalActionGroupDef } from 'src/views/definitionBuilders/common';
import { PaneType, ModalDefBuilder } from 'src/views/definitionBuilders/types';

export default function getConfirmMarkAsInvoicedModalDef(
  onMarkAsInvoiced: (jobId: string) => Promise<void>,
  jobId: string
): ModalDefBuilder {
  return modalDefApi => ({
    title: 'Mark as Invoiced',
    asForm: true,
    panels: [
      {
        panes: [
          {
            paneType: PaneType.customPane,
            render: api => <p>Are you sure you want to mark this job as invoiced?</p>,
          },
        ],
      },
    ],
    secondaryActions: [getSubmitCloseModalActionGroupDef('Ok')],
    onFormSubmit: (values: {}) => {
      return onMarkAsInvoiced(jobId);
    },
  });
}
