import { UiRouteName } from 'src/domain/baseTypes';
import { IRootStoreModel } from 'src/domain/entities/RootStoreModel';
import UiRoute from 'src/domain/services/buildUiRoutes/UiRoute';

export default function buildUiRoutes(store: IRootStoreModel) {
  const equipmentTypeCreate = new UiRoute(
    UiRouteName.peopleEquipmentTypeCreate,
    '/people/equipment-types/create',
    () => 'Create Equipment Type',
    false
  );

  const manageEquipmentType = new UiRoute(
    UiRouteName.peopleEquipmentTypeEdit,
    '/people/equipment-types/:id',
    () => 'Manage Equipment Type',
    false
  );

  const equipmentTypesList = new UiRoute(
    UiRouteName.peopleEquipmentTypesList,
    '/people/equipment-types',
    () => 'Equipment Types',
    false,
    false,
    [manageEquipmentType, equipmentTypeCreate]
  );

  return {
    [UiRouteName.peopleEquipmentTypesList]: equipmentTypesList,
    [UiRouteName.peopleEquipmentTypeCreate]: equipmentTypeCreate,
    [UiRouteName.peopleEquipmentTypeEdit]: manageEquipmentType,
  };
}
