import { Duration } from 'luxon';
import { parseTimeSpan } from 'src/infrastructure/dateUtils';
import {
  formatDurationAsHoursAndMinutes,
  formatDurationAsHoursAndDecimals,
} from 'src/infrastructure/formattingUtils';

export interface IDurationFormatProps {
  value?: Duration | string;
  showHoursAndMinutes?: boolean;
  showHoursAndDecimals?: boolean;
}

const DurationFormat: React.FC<IDurationFormatProps> = ({
  value,
  showHoursAndMinutes,
  showHoursAndDecimals,
}) => {
  if (value === null || value === undefined) {
    return null;
  }

  if (typeof value === 'string') {
    value = parseTimeSpan(value);
  }
  if (!value.isValid) {
    return null;
  }

  let shouldShowHoursAndMinutes = true;
  if (showHoursAndMinutes !== undefined && showHoursAndMinutes !== null) {
    shouldShowHoursAndMinutes = !!showHoursAndMinutes;
  }

  let shouldShowHoursAndDecimals = false;
  if (showHoursAndDecimals !== undefined && showHoursAndDecimals !== null) {
    shouldShowHoursAndDecimals = !!showHoursAndDecimals;
  }

  const shouldShowSeparator = shouldShowHoursAndMinutes && shouldShowHoursAndDecimals;

  return (
    <span>
      {shouldShowHoursAndMinutes ? formatDurationAsHoursAndMinutes(value) : ''}
      {shouldShowSeparator ? ' | ' : ''}
      {shouldShowHoursAndDecimals ? formatDurationAsHoursAndDecimals(value) : ''}
    </span>
  );
};

export default DurationFormat;
