import { ChangeState, CostType, TechSpecDataType, costTypeDescription } from 'src/api/enums';
import { ENABLE_SHOW_WAITING_TIME } from 'src/appSettings';
import { SearchTechSpecValues } from 'src/domain/entities/workshop/asset/AssetsModel';
import { ISplittedTechSpecs } from 'src/domain/entities/workshop/techSpecs/TechSpecsHelpers';
import { EditIcon, PlusIcon } from 'src/images/icons';
import {
  getAllUniqueVehicleTypeIdsFromTrips,
  getToAndFromDatesFromTrips,
} from 'src/infrastructure/allocationUtils';
import { formatToFixed } from 'src/infrastructure/mathUtil';
import { getSubmitCloseModalActionGroupDef } from 'src/views/definitionBuilders/common';
import {
  ActionType,
  FieldType,
  IModalDefBuilderApi,
  INestingPaneDef,
  ModalDefBuilder,
  PaneType,
  ShellModalSize,
} from 'src/views/definitionBuilders/types';
import getExtraPaneDef from 'src/views/routes/operations/shared/getExtraPaneDef';
import getMaintainVehicleTypeModalDef, {
  calculateVehiclePrice,
} from './getMaintainVehicleTypeModalDef';
import { formatDateShort } from 'src/domain/dateHelper';

type Option = Operations.Domain.Queries.ViewQuote.Option;
type Vehicle = Operations.Domain.Queries.ViewQuote.Vehicle;
type QuoteVehicleSkillSpecRequirementItem = Operations.Domain.Queries.ViewQuote.QuoteVehicleSkillSpecRequirementItem;
type QuoteVehicleTechSpecRequirementItem = Operations.Domain.Queries.ViewQuote.QuoteVehicleTechSpecRequirementItem;
type VehicleType = Common.Dtos.VehicleTypeItem;
type ExtraType = Operations.Domain.Queries.ViewExtraType.ExtraTypeItem;
type HourlyRateItem = Operations.Domain.Queries.ListHourlyRates.HourlyRateItem;
type Route = Operations.Domain.Queries.ViewQuote.Route;
type TechSpecDropdownOption = Workshop.Domain.Queries.TechSpecs.TechSpecDropdownOptionsItem.TechSpecDropdownOption;
type SkillSpecs = Common.Dtos.SkillSpecItem;
type TechSpec = Common.Dtos.TechSpecItem;

const costTypeItems = [
  { value: CostType.Kilometers, label: costTypeDescription(CostType.Kilometers) },
  { value: CostType.Contract, label: costTypeDescription(CostType.Contract) },
];

const showWaitingField = ENABLE_SHOW_WAITING_TIME;

function getVehiclesPane(
  vehicleTypes: VehicleType[],
  hourlyRates: HourlyRateItem[],
  isContractPriceManuallyManaged: boolean,
  splittedTechSpecs: ISplittedTechSpecs,
  searchTechSpecValues: SearchTechSpecValues,
  techSpecDropdownOptions: (techSpecId: number) => TechSpecDropdownOption[],
  techSpecs: TechSpec[],
  skillSpecRequirements: SkillSpecs[],
  route?: Route[]
): INestingPaneDef {
  return {
    paneType: PaneType.nestingPane,
    dataAddr: 'vehicles',
    hidden: d => !(d.sectionValue as Option).costTypeId,
    panes: [
      {
        paneType: PaneType.tablePane,
        title: 'Vehicles',
        mandatory: true,
        hidden: d => (d.sectionValue as Option).costTypeId !== CostType.Kilometers,
        fields: [
          {
            fieldType: FieldType.readonlyField,
            dataAddr: 'vehicleType',
            label: 'Vehicle Type',
            columnWidth: '7rem',
            formatReadonly: d => {
              const vehicle = d.fieldValue && vehicleTypes.find(v => v.id === d.fieldValue.id);
              return vehicle?.description ?? null;
            },
          },
          {
            fieldType: FieldType.numericField,
            dataAddr: 'quantity',
            numericConfig: { numericType: 'unsignedInt' },
            label: 'Quantity',
            columnWidth: '2rem',
            readonly: true,
          },
          {
            fieldType: FieldType.numericField,
            dataAddr: 'bufferPrice',
            numericConfig: { numericType: 'unsignedDecimal', maxPointDigits: 4 },
            label: 'Buffer Price',
            columnWidth: '2rem',
            readonly: true,
          },
          {
            fieldType: FieldType.readonlyField,
            dataAddr: 'price',
            label: 'Price',
            columnWidth: '2rem',
          },
          {
            fieldType: FieldType.numericField,
            dataAddr: 'overridePrice',
            numericConfig: { numericType: 'unsignedDecimal', maxPointDigits: 4 },
            label: 'Override Price',
            columnWidth: '2rem',
            readonly: true,
          },
          {
            fieldType: FieldType.readonlyField,
            dataAddr: 'overrideReason',
            label: 'Override Reason',
            columnWidth: '10rem',
          },
          {
            fieldType: FieldType.readonlyField,
            dataAddr: 'skillSpecRequirements',
            label: 'Requirements',
            columnWidth: '10rem',
            formatReadonly: d => {
              const techSkillSpecs = d.parentValue.techSpecRequirements
                ?.map((ts: QuoteVehicleTechSpecRequirementItem) => {
                  const techSpec = techSpecs?.find((t: TechSpec) => t.id === ts.techSpecId);

                  if (techSpec) {
                    switch (techSpec?.dataType) {
                      case TechSpecDataType.Bool:
                        return `${techSpec?.description} = ${ts.value === 'true' ? ' Y' : ' N'}`;
                      case TechSpecDataType.String:
                        return `${techSpec?.description} = ${ts.value}`;
                      case TechSpecDataType.Date:
                        return `${techSpec?.description} = ${formatDateShort(ts.value)}`;
                      case TechSpecDataType.Dropdown:
                        const techSpecOptions = techSpecDropdownOptions(ts.techSpecId);
                        const selectedOption = techSpecOptions.find(o => o.id === ts.value);
                        return `${techSpec?.description} = ${selectedOption?.description}`;
                      default:
                        return `${techSpec?.description} = ${ts.value}`;
                    }
                  }

                  return null;
                })
                .join('\n');
              const skillSpecs = d.parentValue.skillSpecRequirements
                ?.map((ss: QuoteVehicleSkillSpecRequirementItem) => {
                  const skill = skillSpecRequirements?.find(s => s.id === ss.skillSpecId);

                  return `${skill?.description}`;
                })
                .join('\n');

              return (
                <>
                  {techSkillSpecs}
                  {techSkillSpecs.length ? <br /> : null}
                  {skillSpecs}
                </>
              );
            },
          },
          {
            fieldType: FieldType.actionListField,
            columnWidth: '1px',
            nowrap: true,
            actionGroups: [
              {
                actions: [
                  {
                    actionType: ActionType.modalActionButton,
                    label: 'Edit Vehicle',
                    icon: <EditIcon />,
                    modalSize: ShellModalSize.twoThirds,
                    modalDef: getMaintainVehicleTypeModalDef(
                      true,
                      vehicleTypes,
                      hourlyRates,
                      isContractPriceManuallyManaged,
                      CostType.Kilometers,
                      splittedTechSpecs,
                      searchTechSpecValues,
                      techSpecDropdownOptions,
                      skillSpecRequirements,
                      route
                    ),
                  },
                  {
                    actionType: ActionType.removeArrayItemActionButton,
                    label: 'Remove Vehicle',
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        title: 'Vehicles',
        paneType: PaneType.tablePane,
        mandatory: true,
        hidden: d => (d.sectionValue as Option).costTypeId !== CostType.Contract,
        fields: [
          {
            fieldType: FieldType.readonlyField,
            dataAddr: 'vehicleType',
            label: 'Vehicle Type',
            columnWidth: '5em',
            formatReadonly: d => {
              const vehicle = d.fieldValue && vehicleTypes.find(v => v.id === d.fieldValue.id);
              return vehicle?.description ?? null;
            },
          },
          {
            fieldType: FieldType.numericField,
            dataAddr: 'quantity',
            numericConfig: { numericType: 'unsignedInt' },
            label: 'Quantity',
            columnWidth: '5em',
            readonly: true,
          },
          {
            fieldType: FieldType.numericField,
            dataAddr: 'pricePerVehicle',
            numericConfig: { numericType: 'unsignedDecimal', maxPointDigits: 4 },
            label: 'Price',
            columnWidth: '5em',
            readonly: true,
            hidden: isContractPriceManuallyManaged,
            columnAutoHide: isContractPriceManuallyManaged,
          },
          {
            fieldType: FieldType.numericField,
            dataAddr: 'price',
            label: 'Total Price',
            columnWidth: '5em',
            readonly: true,
            hidden: isContractPriceManuallyManaged,
            columnAutoHide: isContractPriceManuallyManaged,
          },
          {
            fieldType: FieldType.readonlyField,
            dataAddr: 'skillSpecRequirements',
            label: 'Requirements',
            columnWidth: '10rem',
            formatReadonly: d => {
              const techSkillSpecs = d.parentValue.techSpecRequirements
                ?.map((ts: QuoteVehicleTechSpecRequirementItem) => {
                  const techSpec = techSpecs?.find((t: TechSpec) => t.id === ts.techSpecId);

                  switch (techSpec?.dataType) {
                    case TechSpecDataType.Bool:
                      return `${techSpec?.description} = ${ts.value === 'true' ? ' Y' : ' N'}`;
                    case TechSpecDataType.String:
                      return `${techSpec?.description} = ${ts.value}`;
                    case TechSpecDataType.Date:
                      return `${techSpec?.description} = ${formatDateShort(ts.value)}`;
                    case TechSpecDataType.Dropdown:
                      const techSpecOptions = techSpecDropdownOptions(ts.techSpecId);
                      const selectedOption = techSpecOptions.find(o => o.id === ts.value);
                      return `${techSpec?.description} = ${selectedOption?.description}`;
                    default:
                      return `${techSpec?.description} = ${ts.value}`;
                  }
                })
                .join('\n');
              const skillSpecs = d.parentValue.skillSpecRequirements
                ?.map((ss: QuoteVehicleSkillSpecRequirementItem) => {
                  const skill = skillSpecRequirements?.find(s => s.id === ss.skillSpecId);

                  return `${skill?.description}`;
                })
                .join('\n');

              return (
                <>
                  {techSkillSpecs}
                  <br />
                  {skillSpecs}
                </>
              );
            },
          },
          {
            fieldType: FieldType.actionListField,
            columnWidth: '1px',
            nowrap: true,
            actionGroups: [
              {
                actions: [
                  {
                    actionType: ActionType.modalActionButton,
                    label: 'Edit',
                    icon: <EditIcon />,
                    modalSize: ShellModalSize.twoThirds,
                    modalDef: getMaintainVehicleTypeModalDef(
                      true,
                      vehicleTypes,
                      hourlyRates,
                      isContractPriceManuallyManaged,
                      CostType.Contract,
                      splittedTechSpecs,
                      searchTechSpecValues,
                      techSpecDropdownOptions,
                      skillSpecRequirements,
                      route
                    ),
                  },
                  {
                    actionType: ActionType.removeArrayItemActionButton,
                    label: 'Remove Vehicle',
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        paneType: PaneType.actionListPane,
        actionGroups: [
          {
            actions: [
              {
                actionType: ActionType.modalActionButton,
                icon: <PlusIcon />,
                label: 'Add Vehicle',
                modalSize: ShellModalSize.twoThirds,
                modalDef: api =>
                  getMaintainVehicleTypeModalDef(
                    false,
                    vehicleTypes,
                    hourlyRates,
                    isContractPriceManuallyManaged,
                    api.actionData.panelValue.costTypeId === CostType.Kilometers
                      ? CostType.Kilometers
                      : CostType.Contract,
                    splittedTechSpecs,
                    searchTechSpecValues,
                    techSpecDropdownOptions,
                    skillSpecRequirements,
                    route
                  )(api),
              },
            ],
          },
        ],
      },
    ],
  };
}

export default function getMaintainOptionModalDef(
  dataMode: 'add' | 'edit',
  vehicleTypes: VehicleType[],
  extraTypes: ExtraType[],
  hourlyRates: Array<HourlyRateItem>,
  hasBeenBooked: boolean,
  getIsContractPriceManuallyManaged: (quote: any) => boolean,
  getVehicleTypeAllocationData: (
    startDate: string,
    endDate: string,
    vehicleTypeIds: string[],
    quoteIdToIgnore: string | undefined,
    depotId: number
  ) => Promise<void>,
  depotId: number,
  splittedTechSpecs: ISplittedTechSpecs,
  searchTechSpecValues: SearchTechSpecValues,
  techSpecDropdownOptions: (techSpecId: number) => TechSpecDropdownOption[],
  techSpecs: TechSpec[],
  skillSpecRequirements: SkillSpecs[],
  route?: Route[],
  quoteId?: string
): ModalDefBuilder {
  return modalDefApi => {
    const isContractPriceManuallyManaged = getIsContractPriceManuallyManaged(
      modalDefApi.actionData.sectionValue
    );
    const options =
      modalDefApi.actionData.parentValue && modalDefApi.actionData.parentValue.options;

    return {
      title: dataMode === 'add' ? 'Add Option' : 'Edit Option',
      asForm: true,
      explicitData:
        dataMode === 'add'
          ? {
              costTypeId: isContractPriceManuallyManaged ? CostType.Contract : undefined,
              durationWeekday: 0,
              durationSaturday: 0,
              durationSunday: 0,
              durationPublicHoliday: 0,
              durationWeekdayWaiting: showWaitingField ? 0 : undefined,
              durationSaturdayWaiting: showWaitingField ? 0 : undefined,
              durationSundayWaiting: showWaitingField ? 0 : undefined,
              durationPublicHolidayWaiting: showWaitingField ? 0 : undefined,
              changeState: ChangeState.Added,
              selected: hasBeenBooked && options && options.length === 0,
            }
          : undefined,
      panels: api => [
        {
          panes: [
            {
              paneType: PaneType.formFieldsPane,
              columnCount: 3,
              fields: [
                {
                  fieldType: FieldType.toggleButtonField,
                  dataAddr: 'costTypeId',
                  label: 'Cost Type',
                  valueKey: 'value',
                  descriptionKey: 'label',
                  useValueOnly: true,
                  optionItems: costTypeItems,
                  mandatory: true,
                  readonly: isContractPriceManuallyManaged,
                  onChange: fapi => {
                    const values = fapi.formValues as Option;
                    const updatedVehicles = (Array.isArray(values.vehicles)
                      ? values.vehicles
                      : []
                    ).map(v => ({
                      ...v,
                      price: undefined,
                      bufferPrice: undefined,
                      overridePrice: undefined,
                      overrideReason: undefined,
                      pricePerVehicle: undefined,
                    }));
                    const updatedValues = { ...values, vehicles: updatedVehicles };
                    fapi.setFormValues(updatedValues);
                    updateAllVehiclePrices(fapi.formValues, hourlyRates, fapi.setFormValue, route);
                  },
                },
              ],
            },
            {
              paneType: PaneType.formFieldsPane,
              hidden: d => (d.parentValue as Option).costTypeId !== CostType.Kilometers,
              columnCount: 3,
              fields: [
                {
                  fieldType: FieldType.numericField,
                  dataAddr: 'distance',
                  numericConfig: { numericType: 'unsignedInt' },
                  label: 'Distance (kms)',
                  mandatory: true,
                  onChange: fapi =>
                    updateAllVehiclePrices(fapi.formValues, hourlyRates, fapi.setFormValue, route),
                },
              ],
            },
            {
              paneType: PaneType.formFieldsPane,
              columnCount: 4,
              hidden: d => (d.parentValue as Option).costTypeId !== CostType.Kilometers,
              fields: [
                {
                  fieldType: FieldType.numericField,
                  dataAddr: 'durationWeekday',
                  numericConfig: {
                    numericType: 'unsignedInt',
                    minValue: 0,
                  },
                  label: 'Duration Weekday (hours)',
                  mandatory: true,
                  onChange: fapi =>
                    updateAllVehiclePrices(fapi.formValues, hourlyRates, fapi.setFormValue, route),
                },
                {
                  fieldType: FieldType.numericField,
                  dataAddr: 'durationSaturday',
                  numericConfig: {
                    numericType: 'unsignedInt',
                    minValue: 0,
                  },
                  label: 'Duration Saturday (hours)',
                  mandatory: true,
                  onChange: fapi =>
                    updateAllVehiclePrices(fapi.formValues, hourlyRates, fapi.setFormValue, route),
                },
                {
                  fieldType: FieldType.numericField,
                  dataAddr: 'durationSunday',
                  numericConfig: {
                    numericType: 'unsignedInt',
                    minValue: 0,
                  },
                  label: 'Duration Sunday (hours)',
                  mandatory: true,
                  onChange: fapi =>
                    updateAllVehiclePrices(fapi.formValues, hourlyRates, fapi.setFormValue, route),
                },
                {
                  fieldType: FieldType.numericField,
                  dataAddr: 'durationPublicHoliday',
                  numericConfig: {
                    numericType: 'unsignedInt',
                    minValue: 0,
                  },
                  label: 'Duration Public Holiday (hours)',
                  mandatory: true,
                  onChange: fapi =>
                    updateAllVehiclePrices(fapi.formValues, hourlyRates, fapi.setFormValue, route),
                },
                {
                  fieldType: FieldType.numericField,
                  dataAddr: 'durationWeekdayWaiting',
                  numericConfig: {
                    numericType: 'unsignedInt',
                    minValue: 0,
                  },
                  hidden: !showWaitingField,
                  label: 'Duration Weekday Waiting (hours)',
                  mandatory: true,
                  onChange: fapi =>
                    updateAllVehiclePrices(fapi.formValues, hourlyRates, fapi.setFormValue, route),
                },
                {
                  fieldType: FieldType.numericField,
                  dataAddr: 'durationSaturdayWaiting',
                  numericConfig: {
                    numericType: 'unsignedInt',
                    minValue: 0,
                  },
                  hidden: !showWaitingField,
                  label: 'Duration Saturday Waiting (hours)',
                  mandatory: true,
                  onChange: fapi =>
                    updateAllVehiclePrices(fapi.formValues, hourlyRates, fapi.setFormValue, route),
                },
                {
                  fieldType: FieldType.numericField,
                  dataAddr: 'durationSundayWaiting',
                  numericConfig: {
                    numericType: 'unsignedInt',
                    minValue: 0,
                  },
                  hidden: !showWaitingField,
                  label: 'Duration Sunday Waiting (hours)',
                  mandatory: true,
                  onChange: fapi =>
                    updateAllVehiclePrices(fapi.formValues, hourlyRates, fapi.setFormValue, route),
                },
                {
                  fieldType: FieldType.numericField,
                  dataAddr: 'durationPublicHolidayWaiting',
                  numericConfig: {
                    numericType: 'unsignedInt',
                    minValue: 0,
                  },
                  hidden: !showWaitingField,
                  label: 'Duration Public Holiday Waiting (hours)',
                  mandatory: true,
                  onChange: fapi =>
                    updateAllVehiclePrices(fapi.formValues, hourlyRates, fapi.setFormValue, route),
                },
                {
                  fieldType: FieldType.errorField,
                  dataAddr: 'durationValidation',
                  validate: d => {
                    const option = d.sectionValue as Option;
                    if (showWaitingField) {
                      return (option.durationWeekday || 0) +
                        (option.durationSaturday || 0) +
                        (option.durationSunday || 0) +
                        (option.durationPublicHoliday || 0) +
                        (option.durationWeekdayWaiting || 0) +
                        (option.durationSaturdayWaiting || 0) +
                        (option.durationSundayWaiting || 0) +
                        (option.durationPublicHolidayWaiting || 0) <=
                        0
                        ? 'Total hours must be greater than zero'
                        : undefined;
                    } else {
                      return (option.durationWeekday || 0) +
                        (option.durationSaturday || 0) +
                        (option.durationSunday || 0) +
                        (option.durationPublicHoliday || 0) <=
                        0
                        ? 'Total hours must be greater than zero'
                        : undefined;
                    }
                  },
                },
              ],
            },
            getVehiclesPane(
              vehicleTypes,
              hourlyRates,
              isContractPriceManuallyManaged,
              splittedTechSpecs,
              searchTechSpecValues,
              techSpecDropdownOptions,
              techSpecs,
              skillSpecRequirements,
              route
            ),
            getExtraPaneDef(
              extraTypes,
              isContractPriceManuallyManaged,
              d => !(d.sectionValue as Option).costTypeId,
              true,
              'quote'
            ),
          ],
        },
        {
          title: 'Total Price',
          hidden: !isContractPriceManuallyManaged,
          panes: [
            {
              paneType: PaneType.formFieldsPane,
              columnCount: 4,
              fields: [
                {
                  fieldType: FieldType.numericField,
                  label: 'Total Price ex. GST',
                  dataAddr: 'overrideTotalPriceExGst',
                  numericConfig: { numericType: 'unsignedDecimal', maxPointDigits: 2 },
                },
                {
                  fieldType: FieldType.numericField,
                  label: 'Total GST',
                  dataAddr: 'overrideTotalGst',
                  numericConfig: { numericType: 'unsignedDecimal', maxPointDigits: 2 },
                },
                {
                  fieldType: FieldType.readonlyField,
                  label: 'Total Price',
                  formatReadonly: d => {
                    const opt = d.parentValue as Option;
                    return opt.overrideTotalPriceExGst
                      ? `$${formatToFixed(
                          Number(opt.overrideTotalPriceExGst) + (Number(opt.overrideTotalGst) || 0),
                          2
                        )}`
                      : '';
                  },
                },
              ],
            },
          ],
        },
      ],
      secondaryActions: [getSubmitCloseModalActionGroupDef('Ok')],
      onFormSubmit: v =>
        onSubmit(modalDefApi, dataMode, v, getVehicleTypeAllocationData, quoteId, depotId),
    };
  };
}

function onSubmit(
  modalDefApi: IModalDefBuilderApi,
  dataMode: string,
  value: any,
  getVehicleTypeAllocationData: (
    fromDate: string,
    toDate: string,
    vehicleTypeIds: string[],
    quoteIdToIgnore: string | undefined,
    depotId: number
  ) => Promise<void>,
  quoteId: string | undefined,
  depotId: number
) {
  const trips = modalDefApi.actionData.panelValue;
  const { toDate, fromDate } = getToAndFromDatesFromTrips(trips);

  if (toDate && fromDate) {
    const ids = getAllUniqueVehicleTypeIdsFromTrips(trips);
    value.vehicles.forEach((v: Vehicle) => {
      !ids.includes(v.vehicleType.id) && ids.push(v.vehicleType.id);
    });

    if (ids.length) {
      dataMode === 'add'
        ? modalDefApi.parentFormApi.addValue(modalDefApi.parentFormApi.getFullField(), {
            ...value,
          })
        : modalDefApi.parentFormApi.setValue(modalDefApi.parentFormApi.getFullField(), {
            ...value,
          });

      return Promise.resolve(getVehicleTypeAllocationData(fromDate, toDate, ids, quoteId, depotId));
    }
  }

  return dataMode === 'add'
    ? Promise.resolve(
        modalDefApi.parentFormApi.addValue(modalDefApi.parentFormApi.getFullField(), value)
      )
    : Promise.resolve(
        modalDefApi.parentFormApi.setValue(modalDefApi.parentFormApi.getFullField(), value)
      );
}

function updateAllVehiclePrices(
  option: Option,
  hourlyRates: Array<HourlyRateItem>,
  setFormValue: (field: Array<string | number>, value: any) => void,
  route?: Route[]
) {
  option.vehicles &&
    option.vehicles.length &&
    option.vehicles.forEach((v, i) => {
      const price = calculateVehiclePrice(
        v.vehicleType,
        v.quantity,
        v.bufferPrice,
        option.distance,
        option.durationWeekday,
        option.durationSaturday,
        option.durationSunday,
        option.durationPublicHoliday,
        hourlyRates,
        option.durationWeekdayWaiting,
        option.durationSaturdayWaiting,
        option.durationSundayWaiting,
        option.durationPublicHolidayWaiting,
        route
      );

      setFormValue(['vehicles', i, 'price'], price);
    });
}
