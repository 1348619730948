import {
  ModalDefBuilder,
  PaneType,
  FieldType,
  IFieldOnChange,
  ActionType,
  ShellModalSize,
} from 'src/views/definitionBuilders/types';
import { validateTime, workingHours } from 'src/views/routes/operations/urban/urbanHelpers';
import {
  parseEditingFormattedTimeString,
  getEditingFormattedTimeString,
} from 'src/views/components/Page/fields/subfields/TimeHelpers';
import { StaffMemberFilter } from 'src/views/components/Page/fields/StaffMemberField';
import getShiftRoutesPaneDef from 'src/views/routes/operations/shared/getShiftRoutesPaneDef';
import { IAutocompleteResult } from 'src/domain/baseTypes';
import { getSkillSpecRequirementFieldDefs } from 'src/views/routes/operations/shared/getSkillSpecRequirementFieldDefs';
import { consolidateSkillSpecRequirementsIds } from 'src/domain/entities/people/staffMember/SkillSpecsHelpers';
import {
  ISplittedTechSpecs,
  consolidateTechSpecRequirements,
} from 'src/domain/entities/workshop/techSpecs/TechSpecsHelpers';
import { SearchTechSpecValues } from 'src/domain/entities/workshop/asset/AssetsModel';
import { getTechSpecRequirementFieldDefs } from 'src/views/routes/operations/shared/getTechSpecRequirementFieldDefs';
import { getSubmitCloseModalActionGroupDef } from 'src/views/definitionBuilders/common';
import { DateTime } from 'luxon';
import { ChangeState } from 'src/api/enums';
import { formatDateTimeMed } from 'src/domain/dateHelper';
import { DownloadIcon, EditIcon, PlusIcon } from 'src/images/icons';
import getAddAttachmentModalDef from './getAddAttachmentModalDef';

type AssetItem = Common.Queries.Workshop.GetFleetAssetList.AssetItem;
type SubcontractorItem = Common.Dtos.SubcontractorItem;
type States = Operations.Domain.AggregatesModel.BoardingPointAggregate.States;
type BoardingPointListItem = Operations.Domain.Queries.SearchBoardingPoint.BoardingPointListItem;
type CreateBoardingPointCommand = Operations.Domain.Commands.BoardingPoint.CreateBoardingPointCommand;
type SkillSpecItem = Common.Dtos.SkillSpecItem;
type TechSpecDropdownOption = Workshop.Domain.Queries.TechSpecs.TechSpecDropdownOptionsItem.TechSpecDropdownOption;
type GenerateShiftAttachmentIdCommand = Operations.Domain.Commands.RailTemplate.GenerateShiftAttachmentId.GenerateShiftAttachmentIdCommand;

export default function getMaintainShiftModalDef(
  dataMode: 'add' | 'edit',
  depots: Common.Dtos.OperationsDepotDto[],
  fleetAssets: AssetItem[],
  subcontractors: SubcontractorItem[],
  states: States[],
  skillSpecs: SkillSpecItem[],
  splittedTechSpecs: ISplittedTechSpecs,
  searchBoardingPoints: (search: string) => Promise<IAutocompleteResult<BoardingPointListItem>>,
  checkForUniqueBoardingPointName: (name: string) => Promise<Common.Dtos.UniqueNameCheckResultDto>,
  onCreateBoardingPoint: (command: CreateBoardingPointCommand) => Promise<string>,
  searchTechSpecValues: SearchTechSpecValues,
  techSpecDropdownOptions: (techSpecId: number) => TechSpecDropdownOption[],
  getAttachmentId: (query: GenerateShiftAttachmentIdCommand) => Promise<string>,
  downloadAttachment: (attachmentId: string, fileName: string) => Promise<void>
): ModalDefBuilder {
  const isAddMode = dataMode === 'add';
  return modalDefApi => ({
    title: isAddMode ? 'Add Shift' : 'Edit Shift',
    asForm: true,
    explicitData: isAddMode ? { hasSubcontractor: false, runCount: 1 } : undefined,
    panels: formApi => [
      {
        title: 'Details',
        hidden: d => d.panelValue.isRailTemplateShift,
        panes: [
          {
            paneType: PaneType.formFieldsPane,
            columnCount: 2,
            fields: [
              {
                fieldType: FieldType.textField,
                label: 'Shift Name',
                dataAddr: 'shiftName',
                maxLength: 200,
                mandatory: true,
                readonly: d => d.panelValue.isRailTemplateShift,
              },
            ],
          },
          {
            paneType: PaneType.formFieldsPane,
            fields: [
              {
                fieldType: FieldType.textField,
                label: 'Description',
                dataAddr: 'description',
                mandatory: true,
                maxLength: 200,
                readonly: d => d.panelValue.isRailTemplateShift,
              },
            ],
          },
          {
            paneType: PaneType.formFieldsPane,
            fields: [
              {
                fieldType: FieldType.textAreaField,
                label: 'Notes',
                dataAddr: 'notes',
              },
            ],
          },
        ],
      },
      {
        title: 'Attachments',
        panes: [
          {
            paneType: PaneType.tablePane,
            dataAddr: 'attachmentDetails',
            fields: [
              {
                fieldType: FieldType.textField,
                dataAddr: ['fileName'],
                readonly: true,
                label: 'File Name',
              },
              {
                fieldType: FieldType.yesNoField,
                readonly: true,
                dataAddr: ['availableOnTablet'],
                label: 'Available On Tablet',
              },
              {
                fieldType: FieldType.yesNoField,
                dataAddr: ['availableOnKiosk'],
                label: 'Available On Kiosk',
                readonly: true,
              },

              {
                fieldType: FieldType.dateTimeField,
                dataAddr: ['createdOn'],
                label: 'Uploaded',
                readonly: true,
                formatReadonly: d => {
                  const date = d.fieldValue
                    ? formatDateTimeMed(d.fieldValue)
                    : formatDateTimeMed(DateTime.local().toString());

                  return `${date} ${
                    d.parentValue.createdBy ? `by ${d.parentValue.createdBy}` : ''
                  }`;
                },
              },
              {
                fieldType: FieldType.actionListField,
                columnWidth: '1px',
                nowrap: true,
                actionGroups: [
                  {
                    actions: [
                      {
                        actionType: ActionType.actionButton,
                        label: `Download`,
                        icon: <DownloadIcon />,
                        onClick: d => {
                          return downloadAttachment(
                            d.actionValue.attachmentId,
                            d.actionValue.fileName
                          );
                        },
                        hidden: d => !d.actionValue.attachmentId,
                      },
                      {
                        actionType: ActionType.modalActionButton,
                        label: 'Edit',
                        icon: <EditIcon />,
                        modalSize: ShellModalSize.oneThird,
                        hidden: d => d.actionValue.changeState === ChangeState.Deleted,
                        modalDef: api => ({
                          title: 'Edit Attachment',
                          asForm: true,
                          dataAddr: 'attachmentDetails',
                          explicitData: {
                            id: api.actionData.actionValue.id,
                            attachmentId: api.actionData.actionValue.attachmentId,
                            file: api.actionData.actionValue.file,
                            fileName: api.actionData.actionValue.fileName,
                            availableOnTablet: api.actionData.actionValue.availableOnTablet,
                            availableOnKiosk: api.actionData.actionValue.availableOnKiosk,
                            createdOn: api.actionData.actionValue.createdOn,
                            changeState: ChangeState.Unchanged,
                          },
                          panels: [
                            {
                              panes: [
                                {
                                  paneType: PaneType.formFieldsPane,
                                  fields: [
                                    {
                                      fieldType: FieldType.textField,
                                      label: 'File name',
                                      mandatory: true,
                                      dataAddr: 'fileName',
                                      validate: fapi => {
                                        if (fapi.fieldValue && !fapi.fieldValue.endsWith('.pdf')) {
                                          return 'Please ensure the filename ends with .pdf';
                                        }
                                        if (fapi.fieldValue && fapi.fieldValue.trim() === '.pdf') {
                                          return '\'.pdf\' is not a valid file name';
                                        }
                                        return undefined;
                                      },
                                    },
                                    {
                                      fieldType: FieldType.yesNoField,
                                      label: "Available on Driver's Tablet?",
                                      mandatory: true,
                                      dataAddr: 'availableOnTablet',
                                    },
                                    {
                                      fieldType: FieldType.customField,
                                      label: '',
                                      dataAddr: 'fake',
                                      hidden: fapi => !fapi.panelValue.availableOnTablet,
                                      readonly: true,
                                      render: () => (
                                        <p>
                                          Multiple tablet PDFs can be a distraction for the driver
                                          and could be slow to download if not optimised correctly.
                                          Please consider if this PDF is necessary for display on
                                          the driver's tablet.
                                        </p>
                                      ),
                                    },
                                    {
                                      fieldType: FieldType.yesNoField,
                                      label: "Available on Driver's Kiosk & App?",
                                      mandatory: true,
                                      dataAddr: 'availableOnKiosk',
                                    },
                                    {
                                      fieldType: FieldType.textField,
                                      dataAddr: ['attachmentId'],
                                      hidden: true,
                                      label: '',
                                      columnWidth: '1px',
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                          secondaryActions: [getSubmitCloseModalActionGroupDef('Ok')],
                          onFormSubmit: values => {
                            const dataAddr = api.actionData.fieldDataAddr;
                            const val = {
                              ...values,
                              attachmentId: values.attachmentId,
                              fileName: values.fileName,
                              file: values.file,
                              availableOnTablet: values.availableOnTablet,
                              availableOnKiosk: values.availableOnKiosk,
                              updateCurrentJobs: values.updateCurrentJobs,
                              changeState: ChangeState.Modified,
                            };
                            api.parentFormApi.setValue(dataAddr, val);
                          },
                        }),
                      },
                      {
                        actionType: ActionType.removeArrayItemActionButton,
                        label: 'Remove Attachment',
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            paneType: PaneType.actionListPane,
            actionGroups: [
              {
                actions: [
                  {
                    actionType: ActionType.modalActionButton,
                    icon: <PlusIcon />,
                    label: 'Add Attachment',
                    modalSize: ShellModalSize.oneThird,
                    modalDef: getAddAttachmentModalDef(getAttachmentId),
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        title: 'Schedule',
        hidden: d => d.panelValue.isRailTemplateShift,
        panes: [
          {
            paneType: PaneType.formFieldsPane,
            columnCount: 3,
            fields: [
              {
                fieldType: FieldType.timeField,
                label: 'Clock On',
                dataAddr: 'clockOn',
                mandatory: true,
                validate: d => validateTime(d.fieldValue),
              },
              {
                fieldType: FieldType.timeField,
                label: 'Depart Depot',
                dataAddr: 'departDepot',
                mandatory: true,
                validate: d => validateTime(d.fieldValue),
              },
              {
                fieldType: FieldType.timeField,
                label: 'Shift Commence',
                dataAddr: 'shiftCommence',
                mandatory: true,
                validate: d => validateTime(d.fieldValue),
              },
              {
                fieldType: FieldType.timeField,
                label: 'Shift End',
                dataAddr: 'shiftEnd',
                mandatory: true,
                validate: d => validateTime(d.fieldValue),
              },

              {
                fieldType: FieldType.timeField,
                label: 'Arrive Depot',
                dataAddr: 'arriveDepot',
                mandatory: true,
                validate: d => validateTime(d.fieldValue),
              },
              {
                fieldType: FieldType.timeField,
                label: 'Clock Off',
                dataAddr: 'clockOff',
                mandatory: true,
                validate: d => validateTime(d.fieldValue),
              },
              {
                fieldType: FieldType.durationField,
                label: 'Unpaid Breaks',
                dataAddr: 'unpaidBreaks',
                mandatory: true,
                validate: d => {
                  const timeValidation = validateTime(d.fieldValue);
                  if (!!timeValidation) {
                    return timeValidation;
                  }

                  const hoursValidation = workingHours(
                    d.parentValue.clockOn,
                    d.parentValue.clockOff,
                    d.parentValue.unpaidBreaks
                  );

                  if (!!hoursValidation && hoursValidation.as('minutes') < 0) {
                    return 'Paid time cannot be less than zero';
                  }

                  return undefined;
                },
                formatReadonly: d =>
                  getEditingFormattedTimeString(parseEditingFormattedTimeString(d.fieldValue)),
              },
              {
                fieldType: FieldType.readonlyField,
                label: 'Paid Time',
                formatReadonly: data => {
                  const hours = workingHours(
                    data.parentValue.clockOn,
                    data.parentValue.clockOff,
                    data.parentValue.unpaidBreaks
                  );
                  if (!!hours) {
                    return hours.toFormat('hh:mm');
                  }
                  return undefined;
                },
              },
            ],
          },
        ],
      },
      {
        title: 'Vehicle Movements',
        hidden: d => d.panelValue.isRailTemplateShift,
        panes: [
          {
            paneType: PaneType.formFieldsPane,
            columnCount: 4,
            fields: [
              {
                fieldType: FieldType.selectField,
                label: 'Departing From Depot',
                dataAddr: 'departingFromDepot',
                valueKey: 'id',
                descriptionKey: 'description',
                mandatory: true,
                optionItems: depots,
              },
              {
                fieldType: FieldType.yesNoField,
                label: 'Departing From Depot In Car',
                dataAddr: 'departingFromDepotInCar',
                mandatory: true,
              },
              {
                fieldType: FieldType.selectField,
                label: 'Arriving At Depot',
                dataAddr: 'arrivingAtDepot',
                valueKey: 'id',
                descriptionKey: 'description',
                mandatory: true,
                optionItems: depots,
              },
              {
                fieldType: FieldType.yesNoField,
                label: 'Arriving At Depot In Car',
                dataAddr: 'arrivingAtDepotInCar',
                mandatory: true,
              },
            ],
          },
        ],
      },
      {
        title: 'Allocations',
        panes: [
          {
            paneType: PaneType.formFieldsPane,
            hidden: d => d.panelValue.isRailTemplateShift,
            columnCount: 2,
            fields: [
              {
                fieldType: FieldType.yesNoField,
                label: 'Has Subcontractor?',
                dataAddr: 'hasSubcontractor',
                mandatory: true,
                onChange: (api: IFieldOnChange<boolean>) => {
                  if (api.newFieldValue) {
                    api.setFormValues({
                      ...api.formValues,
                      asset: undefined,
                      staffMember: undefined,
                    });
                  } else {
                    api.setFormValues({
                      ...api.formValues,
                      subcontractor: undefined,
                    });
                  }
                },
              },
              {
                fieldType: FieldType.selectField,
                label: 'Subcontractor',
                dataAddr: 'subcontractor',
                optionItems: subcontractors,
                valueKey: 'id',
                descriptionKey: 'name',
                hidden: d => !d.panelValue.hasSubcontractor,
              },
            ],
          },
          {
            paneType: PaneType.formFieldsPane,
            columnCount: 2,
            fields: [
              {
                fieldType: FieldType.assetSelectField,
                label: 'Vehicle',
                dataAddr: 'asset',
                optionItems: fleetAssets,
                valueKey: 'id',
                descriptionKey: 'name',
                hidden: d => d.panelValue.hasSubcontractor,
                staffMemberId: d => d.parentValue.staffMember && d.parentValue.staffMember.id,
                techSpecRequirements: d =>
                  consolidateTechSpecRequirements(d.parentValue, splittedTechSpecs),
              },
              {
                fieldType: FieldType.staffMemberField,
                label: 'Staff Member',
                dataAddr: 'staffMember',
                staffMemberFilter: StaffMemberFilter.hasDriversAuthorisation,
                hidden: d => d.panelValue.hasSubcontractor,
                assetId: d => d.parentValue.asset && d.parentValue.asset.id,
                skillSpecRequirements: d => consolidateSkillSpecRequirementsIds(d.parentValue),
                assetLicenceClassId: d => d.parentValue.asset?.licenceClassId,
              },
            ],
          },
        ],
      },
      {
        title: 'Shift Details',
        hidden: d => d.panelValue.isRailTemplateShift,
        panes: [
          getShiftRoutesPaneDef('routeGroups', {
            formApi,
            states,
            searchBoardingPoints,
            checkForUniqueBoardingPointName,
            onCreateBoardingPoint,
            depots: depots,
            updating: true,
          }),
        ],
      },
      {
        title: 'Staff Member Requirements',
        hidden: d => d.panelValue.isRailTemplateShift,
        panes: [
          {
            paneType: PaneType.formFieldsPane,
            columnCount: 2,
            fields: [...getSkillSpecRequirementFieldDefs(skillSpecs)],
          },
        ],
      },
      {
        title: 'Vehicle Requirements',
        hidden: d => d.panelValue.isRailTemplateShift,
        panes: [
          {
            paneType: PaneType.formFieldsPane,
            columnCount: 3,
            fields: getTechSpecRequirementFieldDefs(
              splittedTechSpecs,
              searchTechSpecValues,
              techSpecDropdownOptions
            ),
          },
        ],
      },
    ],
    secondaryActions: [getSubmitCloseModalActionGroupDef('Ok')],
    onFormSubmit: v => {
      const form = {
        ...v,
        isRailTemplateShift: modalDefApi.actionData.actionValue.isRailTemplateShift,
      };
      return isAddMode
        ? Promise.resolve(
            modalDefApi.parentFormApi.addValue(modalDefApi.parentFormApi.getFullField(), form)
          )
        : Promise.resolve(
            modalDefApi.parentFormApi.setValue(modalDefApi.parentFormApi.getFullField(), form)
          );
    },
  });
}
