import { types, flow, getRoot } from 'mobx-state-tree';
import { getAjax } from 'src/domain/services';
import { Option, OptionValues } from 'react-select';
import { NotificationType } from 'src/domain';
import { IRootStoreModel } from 'src/domain/entities/RootStoreModel';
import { DateTime } from 'luxon';
import { JobCategoryType } from 'src/domain/enums';
import { JobTaskCategory } from 'src/api/enums';

type JobTaskListItem = Workshop.Domain.Queries.JobTask.JobTaskListItem;
type JobTaskDetails = Workshop.Domain.Queries.GetJobTask.JobTaskDetails;
type JobTaskCategoryListItem = Workshop.Domain.Queries.GetTaskCategories.JobTaskCategoryListItem;
type SchedulableJob = Workshop.Domain.Queries.Job.GetSchedulableJobsForTask.SchedulableJob;
type SupplierItem = Workshop.Domain.Queries.Suppliers.SupplierItem;
type JobSummaryDto = Workshop.Domain.Queries.Job.JobSummaryDto;
type JobDetails = Workshop.Domain.Queries.GetJobTask.JobDetails;
type ActivityLogTransaction = Workshop.Domain.Queries.ActivityLog.ActivityLogTransaction;
type ScheduleServiceJobTaskCommand = Workshop.Domain.Commands.JobTask.ScheduleServiceJobTaskCommand;
type CloseJobTaskAndDefectWithoutFixingCommand = Workshop.Domain.Commands.JobTask.CloseJobTaskAndDefectWithoutFixingCommand;
type ServiceCycleItem = Workshop.Domain.Queries.AssetGroup.GetAssetGroupFullServiceCycle.ServiceCycleItem;

const editableJobCategories = [JobCategoryType.Defect, JobCategoryType.General];
export interface IMachineryScheduleOption {
  id: string;
  jobTaskId: number;
  description: string;
  jobTaskSubcategory?: {
    id?: number;
    description?: string;
  };
}

export const JobTaskModel = types
  .model('JobTaskModel', {
    activeJobs: types.array(types.frozen<JobSummaryDto>()),
    jobTaskCategories: types.array(types.frozen<JobTaskCategoryListItem>()),
    suppliers: types.array(types.frozen<SupplierItem>()),
    jobTaskDetails: types.maybe(types.frozen<JobTaskDetails>()),
    jobDetails: types.maybe(types.frozen<JobDetails>()),
    schedulableJobs: types.array(types.frozen<SchedulableJob>()),
    activityLogs: types.array(types.frozen<ActivityLogTransaction>()),
  })
  .views(self => ({
    get jobTaskCategoryOptions(): Option<OptionValues>[] {
      const editableCategories = self.jobTaskCategories.filter(
        c => editableJobCategories.indexOf(c.id) >= 0
      );
      return editableCategories.map(category => {
        return {
          value: category.id,
          label: category.description,
        };
      });
    },

    get machineryScheduleTemplateCategoryOptions(): IMachineryScheduleOption[] {
      const generalCategory = self.jobTaskCategories.filter(c => c.id === JobTaskCategory.General);
      const otherCategories = self.jobTaskCategories
        .filter(
          c =>
            c.id === JobTaskCategory.PreMachineryInspection ||
            c.id === JobTaskCategory.MachineryInspection
        )
        .map(c => ({
          id: `${c.id}_${c.description}`,
          jobTaskId: c.id,
          description: c.description,
          jobTaskSubcategory: {
            id: undefined,
            description: undefined,
          },
        }));

      const subcategories = generalCategory.map(c => c.subcategories);
      const generalOptions = subcategories.flatMap(s =>
        s.map(sc => ({
          id: `${sc.id}_${sc.description}`,
          jobTaskId: JobCategoryType.General,
          description: 'General',
          jobTaskSubcategory: {
            id: sc.id,
            description: sc.description,
          },
        }))
      );

      const excludeMachineryInspectionOptions = otherCategories.filter(
        o => o.jobTaskId === JobTaskCategory.PreMachineryInspection
      );

      return [...excludeMachineryInspectionOptions, ...generalOptions];
    },

    jobTaskSubcategoryOptions(categoryId?: number): Option<OptionValues>[] {
      if (categoryId === undefined || categoryId === null) {
        return [];
      }

      const category = self.jobTaskCategories.find(cat => cat.id === categoryId);
      if (!category) {
        return [];
      }

      return category.subcategories.map(subcategory => {
        return {
          value: subcategory.id,
          label: subcategory.description,
        };
      });
    },
    get supplierOptions(): Option<OptionValues>[] {
      return self.suppliers.map(s => {
        return {
          value: s.id,
          label: s.name,
        };
      });
    },
  }))
  .actions(self => {
    const ajax = getAjax(self);
    const root = getRoot(self) as IRootStoreModel;

    const getJobTask = flow(function*(id: string) {
      self.jobTaskDetails = yield ajax.jobTask.getJobTask(id);
    });

    const loadJobTaskCategories = flow(function*() {
      self.jobTaskCategories = yield ajax.jobTask.loadJobTaskCategories();
    });

    const loadSuppliers = flow(function*() {
      self.suppliers = yield ajax.jobTask.loadSuppliers();
    });

    const getSchedulableJobsForTask = flow(function*(id: string) {
      self.schedulableJobs = yield ajax.jobTask.getSchedulableJobsForTask(id);
    });

    const edit = flow(function*(command: Workshop.Domain.Commands.JobTask.UpdateJobTaskCommand) {
      yield ajax.jobTask.edit(command);
      yield getJobTask(command.jobTaskId);
      yield getSchedulableJobsForTask(command.jobTaskId);
    });

    const scheduleMachineryJobTask = flow(function*(
      command: Workshop.Domain.Commands.JobTask.ScheduleMachineryJobTaskCommand
    ) {
      yield ajax.jobTask.scheduleMachineryJobTask(command);
      root.notifications.addNotification(`Successfully scheduled machinery inspection task`, {
        type: NotificationType.success,
      });
    });

    const getActivityLog = flow(function*(jobTaskId: string) {
      self.activityLogs = yield ajax.workshop.activityLog.getActivityLogForJobTask(jobTaskId);
    });

    const getActiveJobs = flow(function*(jobTaskId: string) {
      self.activeJobs = yield ajax.jobTask.getActiveJobsForTask(jobTaskId);
    });

    const getAllActiveJobs = flow(function*(jobTaskId: string) {
      return yield ajax.jobTask.getActiveJobsForTask(jobTaskId);
    });

    const getJobDetailsForTask = flow(function*(jobTaskId: string) {
      self.jobDetails = yield ajax.jobTask.getJobDetailsForTask(jobTaskId);
    });

    const scheduleFutureJobTask = flow(function*(
      command: Workshop.Domain.Commands.JobTask.ScheduleFutureJobTaskCommand
    ) {
      yield ajax.jobTask.scheduleFutureJobTask(command);
      yield getJobTask(command.jobTaskId);
      yield getActiveJobs(command.jobTaskId);
      yield getActivityLog(command.jobTaskId);
      yield getJobDetailsForTask(command.jobTaskId);
    });

    const scheduleFutureJobTaskWithoutReload = flow(function*(
      command: Workshop.Domain.Commands.JobTask.ScheduleFutureJobTaskCommand
    ) {
      yield ajax.jobTask.scheduleFutureJobTask(command);
    });

    const scheduleServiceJobTaskWithoutReload = flow(function*(
      command: ScheduleServiceJobTaskCommand
    ) {
      yield ajax.jobTask.schedulueServiceJobTask(command);
    });

    const cancelJobTask = flow(function*(
      command: Workshop.Domain.Commands.JobTask.CancelJobTaskCommand
    ) {
      yield ajax.jobTask.cancelJobTask(command);
      yield getJobTask(command.jobTaskId);
      yield getActiveJobs(command.jobTaskId);
      yield getActivityLog(command.jobTaskId);
      yield getJobDetailsForTask(command.jobTaskId);
    });

    const cancelJobTaskWithoutReload = flow(function*(
      command: Workshop.Domain.Commands.JobTask.CancelJobTaskCommand
    ) {
      yield ajax.jobTask.cancelJobTask(command);
    });

    const deleteJobTaskWithoutReload = flow(function*(id: string) {
      yield ajax.jobTask.deleteJobTask({ jobTaskId: id });
    });

    const createAdhocJobTask = flow(function*(
      command: Workshop.Domain.Commands.JobTask.CreateAdhocJobTaskCommand
    ) {
      const id = yield ajax.jobTask.createAdhocJobTask(command);
      root.history.push(`/workshop/tasks/${id}`);
    });

    const unscheduleFutureJobTask = flow(function*(id: string) {
      yield ajax.jobTask.unscheduleFutureJobTask(id);
      yield getJobTask(id);
      yield getActiveJobs(id);
      yield getActivityLog(id);
      yield getJobDetailsForTask(id);
    });

    const unscheduleFutureJobTaskWithoutReload = flow(function*(id: string) {
      yield ajax.jobTask.unscheduleFutureJobTask(id);
    });

    const completeJobTask = flow(function*(
      id: string,
      resetServicePlan: boolean,
      jobDate: string,
      assetKms: number,
      serviceCycleItem: ServiceCycleItem | undefined
    ) {
      if (resetServicePlan) {
        const serviceCycle = serviceCycleItem
          ? {
              jobTaskSubcategoryId: serviceCycleItem.serviceType.id,
              dueDays: serviceCycleItem.dueDays,
              dueKms: serviceCycleItem.dueKms,
            }
          : undefined;
        yield ajax.jobTask.completeJobTaskAndResetServicePlan({
          jobTaskId: id,
          jobDate: DateTime.fromISO(jobDate).toISODate(),
          assetKms: assetKms,
          jobServiceCycleItem: serviceCycle,
        });
      } else {
        yield ajax.jobTask.completeJobTask(id);
      }
      yield getJobTask(id);
      yield getActivityLog(id);
    });

    const setToInProgress = flow(function*(id: string) {
      yield ajax.jobTask.setToInProgress(id);
      yield getJobTask(id);
      yield getActivityLog(id);
    });

    const closeJobTaskWithoutFixing = flow(function*(
      command: CloseJobTaskAndDefectWithoutFixingCommand
    ) {
      yield ajax.jobTask.closeJobTaskWithoutFixing(command);
      yield getJobTask(command.jobTaskId);
      yield getActivityLog(command.jobTaskId);
    });

    const closeJobTaskWithoutFixingWithoutReload = flow(function*(
      command: CloseJobTaskAndDefectWithoutFixingCommand
    ) {
      yield ajax.jobTask.closeJobTaskWithoutFixing(command);
    });

    const getJobTasksForAsset = flow(function*(assetId: string) {
      if (!assetId) {
        return [];
      } else {
        const result: JobTaskListItem[] = yield ajax.jobTask.listJobTasksForAsset(assetId);
        return result;
      }
    });

    const clearActivityLog = () => {
      self.activityLogs.clear();
    };

    const getPartsGroupParts = async (partsGroupId: string) => {
      return await ajax.workshop.part.getPartsGroupParts(partsGroupId);
    };

    const clear = () => {
      self.jobDetails = undefined;
      self.jobTaskDetails = undefined;
      self.activityLogs.clear();
      self.activeJobs.clear();
      self.schedulableJobs.clear();
    };

    return {
      getJobTask,
      getSchedulableJobsForTask,
      loadJobTaskCategories,
      loadSuppliers,
      edit,
      scheduleMachineryJobTask,
      getJobTasksForAsset,
      getActivityLog,
      clearActivityLog,
      scheduleFutureJobTask,
      scheduleFutureJobTaskWithoutReload,
      scheduleServiceJobTaskWithoutReload,
      cancelJobTask,
      cancelJobTaskWithoutReload,
      deleteJobTaskWithoutReload,
      unscheduleFutureJobTask,
      unscheduleFutureJobTaskWithoutReload,
      completeJobTask,
      setToInProgress,
      getActiveJobs,
      getAllActiveJobs,
      getJobDetailsForTask,
      getPartsGroupParts,
      createAdhocJobTask,
      clear,
      closeJobTaskWithoutFixing,
      closeJobTaskWithoutFixingWithoutReload,
    };
  });

type IJobTaskModelType = typeof JobTaskModel.Type;
export interface IJobTaskModel extends IJobTaskModelType {}
