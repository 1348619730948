import { UiRouteName } from 'src/domain/baseTypes';
import { IRootStoreModel } from 'src/domain/entities/RootStoreModel';
import UiRoute from 'src/domain/services/buildUiRoutes/UiRoute';

export default function buildUiRoutes(store: IRootStoreModel) {
  const defectCreate = new UiRoute(
    UiRouteName.workshopDefectCreate,
    '/workshop/defects/create',
    () => 'Create Defect',
    false
  );

  const defectEdit = new UiRoute(
    UiRouteName.workshopDefectEdit,
    '/workshop/defects/:id',
    () => 'Manage Defect',
    false
  );

  const defectsList = new UiRoute(
    UiRouteName.workshopDefectsList,
    '/workshop/defects',
    () => 'Defects',
    true,
    false,
    [defectCreate, defectEdit]
  );

  return {
    [UiRouteName.workshopDefectsList]: defectsList,
    [UiRouteName.workshopDefectCreate]: defectCreate,
    [UiRouteName.workshopDefectEdit]: defectEdit,
  };
}
