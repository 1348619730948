import inject from 'src/views/injectFromStore';
import { ComponentsRegister, IComponentsRegisterProps } from './ComponentsRegister';
import Omit from 'src/infrastructure/omit';

const Container = inject<Omit<IComponentsRegisterProps, 'mode'>, IComponentsRegisterProps>(
  (allStores, p) => ({
    canManageComponents: allStores.rootStore.account.isWorkshopDepartmentMember,
    asset: allStores.rootStore.asset.asset,
    assetComponents: allStores.rootStore.asset.assetComponents,
    componentTypes: allStores.rootStore.workshop.componentTypes.componentTypeListItems.slice(),
    lastOdometerReading: allStores.rootStore.workshop.assetOdometerReading.lastOdometerReading,
    onLoadAsset: allStores.rootStore.asset.loadAsset,
    onLoadAssetComponents: allStores.rootStore.asset.loadAssetComponents,
    onLoadComponentTypes: allStores.rootStore.workshop.componentTypes.loadComponentTypeListItems,
    onUpdateAssetComponents: allStores.rootStore.asset.updateAssetComponents,
    searchParts: allStores.rootStore.workshop.parts.searchParts,
    loadLastOdometerReading:
      allStores.rootStore.workshop.assetOdometerReading.loadLastOdometerReading,
    checklists: allStores.rootStore.assetGroup.checklists.slice(),
    loadChecklists: allStores.rootStore.assetGroup.loadChecklists,
    partsGroups: allStores.rootStore.workshop.partsGroups.partsGroups.slice(),
    loadPartsGroups: allStores.rootStore.workshop.partsGroups.listPartsGroups,
    onSchedulePreventativeMaintenanceService:
      allStores.rootStore.asset.schedulePreventativeMaintenanceService,
    workshopDepots: allStores.rootStore.workshopStartup.workshopDepots.slice(),
    defaultWorkshopDepot: allStores.rootStore.workshopStartup.defaultWorkshopDepot,
    futureJobs: allStores.rootStore.assets.futureJobs.slice(),
  })
)(ComponentsRegister);

export default Container;
