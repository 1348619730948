import { FieldType } from 'src/views/definitionBuilders/types/field';
import CrudPage, { ICrudPageDef, CrudPageMode } from 'src/views/components/Page/pages/CrudPage';
import {
  PaneType,
  ActionType,
  ShellModalSize,
  PagePrimarySize,
} from 'src/views/definitionBuilders/types';
import { ChangeState, allSkillSpecCategory } from 'src/api/enums';
import deepEqual from 'src/infrastructure/deepEqual';
import memoizeOne from 'src/infrastructure/memoizeOne';
import { BanIcon } from 'src/images/icons';
import { getSubmitCloseModalActionGroupDef } from 'src/views/definitionBuilders/common';
import PrimaryTitle from 'src/views/components/Page/PrimaryTitle/PrimaryTitle';

type UpdateSkillSpecsCommand = People.Domain.Commands.StaffMembers.SkillSpecs.UpdateSkillSpecsCommand;
type SkillSpecItem = People.Domain.Queries.GetSkillSpecs.SkillSpecItem;

export interface IMaintainSkillSpecsProps {
  mode: CrudPageMode;
  skillSpecs: People.Domain.Queries.GetSkillSpecs.SkillSpecItem[];
  getSkillSpecs: () => Promise<void>;
  updateSkillSpecs: (cmd: UpdateSkillSpecsCommand) => Promise<void>;
  clearSkillSpecRequirements: (skillSpecId: number) => Promise<void>;
  canClearSkillSpecRequirements: boolean;
}

const MaintainSkillSpecs: React.FC<IMaintainSkillSpecsProps> = (
  props: IMaintainSkillSpecsProps
) => {
  const handlePreSubmit = (values: SkillSpecItem[]): UpdateSkillSpecsCommand => {
    const getChangeState = (skillSpec: SkillSpecItem, currentIndex: number) => {
      if (
        skillSpec.changeState === ChangeState.Added ||
        skillSpec.changeState === ChangeState.Deleted
      ) {
        return skillSpec.changeState;
      }
      const originalSkillSpecIndex =
        props.skillSpecs && props.skillSpecs.findIndex(ts => ts.id === skillSpec.id);
      if (originalSkillSpecIndex < 0) {
        throw new Error('Cannot find original Skill Spec');
      }
      const originalSkillSpec = props.skillSpecs[originalSkillSpecIndex];

      return currentIndex === originalSkillSpecIndex && deepEqual(skillSpec, originalSkillSpec)
        ? ChangeState.Unchanged
        : ChangeState.Modified;
    };

    return {
      skillSpecItems: values.map((v, i) => {
        return {
          ...v,
          changeState: getChangeState(v, i),
        };
      }),
    } as UpdateSkillSpecsCommand;
  };

  const getPageDef = memoizeOne(
    (updating: boolean): ICrudPageDef => {
      return {
        primarySize: PagePrimarySize.full,
        primarySection: {
          title: (
            <PrimaryTitle
              title="Skill Specs"
              link="https://www.notion.so/Working-With-Skill-Specs-acab29d1070c446ebc32e0a248cd8661"></PrimaryTitle>
          ),

          panels: [
            {
              panes: [
                {
                  paneType: PaneType.tablePane,
                  dataRequiredForRows: 'paneValue',
                  fields: [
                    {
                      fieldType: FieldType.textField,
                      dataAddr: 'description',
                      label: 'Description',
                      mandatory: true,
                      validate: d => {
                        if (!d.fieldValue || !updating) {
                          return undefined;
                        }
                        const duplicated = (d.paneValue as SkillSpecItem[]).some(
                          x =>
                            x.id !== d.parentValue.id &&
                            x.changeState !== ChangeState.Deleted &&
                            x.description === d.parentValue.description
                        );
                        return duplicated ? `Description is already in use` : undefined;
                      },
                    },
                    {
                      fieldType: FieldType.selectField,
                      dataAddr: 'category',
                      label: 'Category',
                      optionItems: allSkillSpecCategory,
                      valueKey: 'value',
                      descriptionKey: 'description',
                      useValueOnly: true,
                      mandatory: true,
                    },
                    {
                      fieldType: FieldType.yesNoField,
                      dataAddr: 'isUsedInRequirements',
                      label: 'Used in requirements',
                      readonly: true,
                    },
                    {
                      fieldType: FieldType.actionListField,
                      columnWidth: '1px',
                      hidden: updating || !props.canClearSkillSpecRequirements,
                      actionGroups: [
                        {
                          actions: [
                            {
                              actionType: ActionType.modalActionButton,
                              label: 'Clear requirements',
                              icon: <BanIcon />,
                              hidden: d => {
                                const skillSpec = d.parentValue as SkillSpecItem;
                                return !skillSpec.isUsedInRequirements;
                              },
                              modalSize: ShellModalSize.oneQuarter,
                              modalDef: modalDefApi => ({
                                title: 'Clear requirements',
                                asForm: true,
                                panels: [
                                  {
                                    panes: [
                                      {
                                        paneType: PaneType.customPane,
                                        render: () => (
                                          <>
                                            <p>Are you sure you want to clear requirements?</p>
                                            <p>
                                              This will remove the requirement from all jobs, shifts
                                              and quotes.
                                            </p>
                                          </>
                                        ),
                                      },
                                    ],
                                  },
                                ],
                                secondaryActions: [getSubmitCloseModalActionGroupDef('Ok')],
                                onFormSubmit: values => {
                                  const skillSpec = values as SkillSpecItem;
                                  return props.clearSkillSpecRequirements(skillSpec.id!);
                                },
                              }),
                            },
                          ],
                        },
                      ],
                    },
                    {
                      fieldType: FieldType.actionListField,
                      columnWidth: '1px',
                      hidden: !updating,
                      actionGroups: [
                        {
                          actions: [
                            {
                              actionType: ActionType.removeArrayItemActionButton,
                              label: 'Remove Skill Spec',
                              hidden: d => {
                                const skillSpec = d.parentValue as SkillSpecItem;
                                return !skillSpec.canBeDeleted;
                              },
                            },
                            {
                              actionType: ActionType.removeArrayItemActionButton,
                              label:
                                "This skill spec can't be deleted because it's used as a requirement",
                              disabled: true,
                              hidden: d => {
                                const skillSpec = d.parentValue as SkillSpecItem;
                                return skillSpec.canBeDeleted;
                              },
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
                {
                  paneType: PaneType.actionListPane,
                  actionGroups: [
                    {
                      actions: [
                        {
                          actionType: ActionType.addArrayItemActionButton,
                          hidden: !updating,
                          label: 'Add Skill Spec',
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
          onFormPreSubmit: handlePreSubmit,
          onFormSubmit: props.updateSkillSpecs,
        },
      };
    }
  );

  return (
    <CrudPage
      data={props.skillSpecs}
      def={({ updating }) => getPageDef(updating)}
      mode={props.mode}
      onLoadData={() => props.getSkillSpecs()}
    />
  );
};

export default MaintainSkillSpecs;
