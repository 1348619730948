import { types, flow, getRoot } from 'mobx-state-tree';
import { getAjax } from 'src/domain/services/storeEnvironment';
import { IKioskRootStoreModel } from '../../KioskRootStoreModel';

type FutureJobTaskListItem = Workshop.Domain.Queries.GetFutureTasksListForDepot.FutureJobTaskListItem;
type JobTaskDetails = Workshop.Domain.Queries.GetJobTask.JobTaskDetails;
type KioskStartTaskNowCommand = Workshop.Domain.Commands.JobTask.KioskStartTaskNowCommand;

export const TasksModel = types
  .model('TasksModel', {
    futureTasks: types.array(types.frozen<FutureJobTaskListItem>()),
    taskDetails: types.maybe(types.frozen<JobTaskDetails>()),
  })
  .actions(self => {
    const ajax = getAjax(self);
    const root = getRoot<IKioskRootStoreModel>(self);

    const loadFutureTasks = flow(function*(max: number, depotId: number) {
      self.futureTasks.clear();
      self.futureTasks = yield ajax.raw
        .get(`/api/kiosk/job-tasks/future/${depotId}?maxResultsCount=${max}`)
        .toPromise()
        .then<FutureJobTaskListItem[]>(r => r.response.items);
    });

    const getTask = flow(function*(id: string) {
      self.taskDetails = yield ajax.raw
        .get(`/api/kiosk/job-tasks/${id}`)
        .toPromise()
        .then<JobTaskDetails>(r => r.response);
    });

    const updateTask = flow(function*(
      command: Workshop.Domain.Commands.JobTask.KioskUpdateJobTaskCommand
    ) {
      yield ajax.raw
        .put(`/api/kiosk/job-tasks/${command.jobTaskId}`, command)
        .toPromise()
        .then<JobTaskDetails>(r => r.response);
      yield getTask(command.jobTaskId);
    });

    const createTask = flow(function*(
      command: Workshop.Domain.Commands.JobTask.KioskCreateAdhocJobTaskCommand
    ) {
      const taskId = yield ajax.raw
        .post(`/api/kiosk/job-tasks`, command)
        .toPromise()
        .then<string>(r => r.response);
      yield getTask(taskId);
      root.history.push(`${root.home}/task/${taskId}`);
    });

    const startNow = flow(function*(jobTaskId: string, workshopDepotId: number) {
      const command: KioskStartTaskNowCommand = {
        jobTaskId: jobTaskId,
        workshopDepotId: workshopDepotId,
      };
      const newJobId = yield ajax.raw
        .post(`/api/kiosk/job-tasks/${jobTaskId}/start-now`, command)
        .toPromise()
        .then<string>(r => r.response);
      self.taskDetails = undefined;
      yield getTask(jobTaskId);
      root.history.push(`${root.home}/job/${newJobId}`);
    });

    const setToInProgress = flow(function*(jobTaskId: string) {
      yield ajax.raw
        .post(`/api/kiosk/job-tasks/${jobTaskId}/set-to-in-progress`)
        .toPromise()
        .then<string>(r => r.response);
      self.taskDetails = undefined;
      yield getTask(jobTaskId);
    });

    return {
      loadFutureTasks,
      getTask,
      updateTask,
      startNow,
      setToInProgress,
      createTask,
    };
  });
