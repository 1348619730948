import cn from 'classnames';

type ViewJobShiftRouteGroupItem = Operations.Domain.Queries.ViewJob.JobShiftRouteGroupItem;
type ListJobShiftRouteGroupItem = Operations.Domain.Queries.ListRailInstructionsForDriver.JobShiftRouteGroupItem;
type ViewJobShiftRouteItem = Operations.Domain.Queries.ViewJob.JobShiftRouteItem;
type ListJobShiftRouteItem = Operations.Domain.Queries.ListRailInstructionsForDriver.JobShiftRouteItem;
type JobShiftRouteGroupItem = ViewJobShiftRouteGroupItem | ListJobShiftRouteGroupItem;
type JobShiftRouteItem = ViewJobShiftRouteItem | ListJobShiftRouteItem;

import styles from './RailRouteGroups.module.scss';
import { Input, InputGroup } from 'reactstrap';
import FormGroup from 'reactstrap/lib/FormGroup';
import { unsignedIntegerSanitiser } from 'src/views/components/Page/fields/NumericPageField';
import {
  IPax,
  formatTime,
  sortRoutes,
  getMaxRuns,
  getMax,
} from 'src/views/components/operations/RouteGroups/RouteGroups';
import FormFeedback from 'reactstrap/lib/FormFeedback';

export interface IRailRouteGroupsProps {
  routeGroups: JobShiftRouteGroupItem[];
  pax: IPax[];
  updatePax: (newPax: IPax[]) => void;
  isReadOnly: boolean;
  validationWarnings:
    | {
        wheelchairPax: string | undefined;
        pax: { routeId: number; message: string | undefined }[];
      }
    | undefined;
}

const RailRouteGroups: React.FunctionComponent<IRailRouteGroupsProps> = ({
  routeGroups,
  pax,
  updatePax,
  isReadOnly,
  validationWarnings,
}) => {
  return (
    <div className={styles['route-groups-component']}>
      {routeGroups.map((rg, rgNumber) => {
        const maxRuns = getMaxRuns(routeGroups);
        const maxRoutes = getMax(rg.routes, r => r.routeNumber);
        const routes: Array<JobShiftRouteItem[]> = Array(maxRoutes).fill([]);
        const sortedRoutes = sortRoutes(rg.routes);
        sortedRoutes.forEach(val => {
          routes[val.routeNumber] = [...routes[val.routeNumber], val];
        });

        const firstRoute = routes[0];
        return (
          <div key={rg.id} className={styles['route-group']}>
            <div className={styles['route-info']}>
              <h4 className={styles.name}>{!!rg.name ? `Route - ${rg.name}` : ''}</h4>
              <h4 className={styles.desto}>{!!rg.desto ? `Desto - ${rg.desto}` : ''}</h4>
            </div>
            <div className={styles.locations}>
              <table className="table table-borderless table-striped table-sm">
                <thead>
                  <tr>
                    <th>Starting Point</th>
                    {Array(maxRuns)
                      .fill(0)
                      .map((r, i) => {
                        return (
                          <th key={i}>
                            {routes.filter(x => !!x[i] && !!x[i].depart).length > 0
                              ? i + 1 + rgNumber + (routeGroups.length - 1) * i
                              : null}
                          </th>
                        );
                      })}
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{firstRoute[0].name}</td>
                    {firstRoute.map((run, runNumber) => (
                      <td key={run.id}>{formatTime(run.depart)}</td>
                    ))}
                  </tr>
                  <tr>
                    {[undefined, ...pax.filter(x => x.routeGroup === rgNumber)].map((item, idx) => {
                      const warning =
                        item &&
                        validationWarnings &&
                        validationWarnings.pax.find(x => x.routeId === item.routeId);
                      if (item === undefined) {
                        return <td key={idx}>Passengers carried per run</td>;
                      } else {
                        const isBlankEntry = !item.depart;
                        return (
                          <td key={idx}>
                            {!isBlankEntry && (
                              <FormGroup className={styles.field}>
                                <InputGroup className={cn({ readonly: isReadOnly })}>
                                  <Input
                                    type="text"
                                    autoComplete="off"
                                    value={
                                      item.count === undefined || item.count === null
                                        ? ''
                                        : item.count
                                    }
                                    disabled={isReadOnly}
                                    onChange={event => {
                                      const newValue =
                                        unsignedIntegerSanitiser(event.target.value, {
                                          minValue: 0,
                                          maxValue: 9999999,
                                        }) || 0;

                                      const newState = [...pax];
                                      const matchingIdx = newState.findIndex(
                                        x => x.routeId === item.routeId
                                      );

                                      newState[matchingIdx].count = newValue;
                                      updatePax(newState);
                                    }}
                                  />
                                </InputGroup>
                                <FormFeedback className={styles.feedback}>
                                  {warning && warning.message}
                                </FormFeedback>
                              </FormGroup>
                            )}
                          </td>
                        );
                      }
                    })}
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default RailRouteGroups;
