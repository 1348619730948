import { types, flow } from 'mobx-state-tree';
import buildListPageApiSearchModel from 'src/domain/modelBuilders/buildListPageApiSearchModel';
import { getAjax } from 'src/domain/services';

type OwnerSelectListItem = Workshop.Domain.Queries.Owners.GetAllOwners.OwnerSelectListItem;

const OwnersListModel = buildListPageApiSearchModel('OwnersModel', d =>
  d.ajax.workshop.owner.listOwners(d.query)
);

const ListAllOwnersModel = types
  .model('ListAllOwnersModel', {
    allOwners: types.array(types.frozen<OwnerSelectListItem>()),
  })
  .actions(self => {
    const ajax = getAjax(self);

    const loadAllOwners = flow(function*() {
      self.allOwners = yield ajax.workshop.owner.listAllOwners().toPromise();
    });

    return {
      loadAllOwners,
    };
  });

export const OwnersModel = types.compose(OwnersListModel, ListAllOwnersModel);
