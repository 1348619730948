import { types, flow, getRoot } from 'mobx-state-tree';
import { DateTime } from 'luxon';
import { getAjax } from 'src/domain/services/storeEnvironment';
import { IKioskRootStoreModel } from '../../KioskRootStoreModel';
import { cast } from 'mobx-state-tree';

type DriverJob = Operations.Domain.Queries.ListJobsForDriver.DriverJob;
type CharterInstructions = Operations.Domain.Queries.ListCharterInstructionsForDriver.CharterInstructions;
type RailInstructions = Operations.Domain.Queries.ListRailInstructionsForDriver.RailInstructions;
type PredepartureCheckItem = Operations.Domain.Queries.ListJobsForDriver.PredepartureCheckItem;
type JobsForDriver = Operations.Domain.Queries.ListJobsForDriver.JobsForDriver;

export const JobsModel = types
  .model('JobsModel', {
    jobs: types.array(types.frozen<DriverJob>()),
    charterInstructions: types.maybe(types.frozen<CharterInstructions>()),
    railInstructions: types.maybe(types.frozen<RailInstructions>()),
    areWeeksJobsComplete: types.maybe(types.boolean),
    predepartureChecks: types.array(types.frozen<PredepartureCheckItem>()),
  })
  .views(self => ({
    get hasRailInstructions(): boolean {
      return !!(self.railInstructions && self.railInstructions.jobs.length > 0);
    },
  }))
  .actions(self => {
    const ajax = getAjax(self);
    const root = getRoot<IKioskRootStoreModel>(self);

    const loadJobsForDriver = flow(function*(date: DateTime) {
      const jobsForDriver: JobsForDriver = yield ajax.kiosk.jobs.loadJobsForDriver(
        date.toISODate()
      );
      self.jobs = cast(jobsForDriver.driverJobs);
      self.predepartureChecks = cast(jobsForDriver.predepartureChecks);
    });

    const loadCharterInstructionsForDriver = flow(function*(date: DateTime) {
      self.charterInstructions = yield ajax.kiosk.jobs.loadCharterInstructionsForDriver(
        date.toISODate()
      );
    });

    const loadRailInstructionsForDriver = flow(function*(date: DateTime) {
      self.railInstructions = yield ajax.raw
        .get(`/api/kiosk/jobs/${date.toISODate()}/rail`)
        .toPromise()
        .then<RailInstructions>(r => r.response);
    });

    const acknowledgeJob = flow(function*(jobId: string, date: DateTime) {
      yield ajax.kiosk.jobs.acknowledgeJob(jobId);
      yield loadJobsForDriver(date);
    });

    const completeJob = flow(function*(
      command: Operations.Domain.Commands.Kiosk.Job.CompleteJob.CompleteJobCommand
    ) {
      yield ajax.kiosk.jobs.completeJob(command);
      root.history.push(`/kiosk`);
    });

    return {
      loadJobsForDriver,
      loadCharterInstructionsForDriver,
      loadRailInstructionsForDriver,
      acknowledgeJob,
      completeJob,
    };
  });
