import './CompleteJob.scss';
import { RouteComponentProps } from 'react-router-dom';
import {
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Button,
  FormFeedback,
  FormGroup,
  Label,
} from 'reactstrap';
import {
  parseEditingFormattedDurationString,
  getEditingFormattedTimeString,
} from 'src/views/components/Page/fields/subfields/TimeHelpers';
import TimeInput from 'src/views/components/Page/fields/subfields/TimeInput';
import { unsignedIntegerSanitiser } from 'src/views/components/Page/fields/NumericPageField';
import { formattedWorkingJobHours } from 'src/views/routes/operations/urban/urbanHelpers';
import DateTimeInput from 'src/views/components/calendar/DateTimeInput';
import { DateTime, Duration } from 'luxon';
import { JobType } from 'src/api/enums';
import cn from 'classnames';
import YesNoToggleButton from 'src/views/kioskRoutes/components/presentational/YesNoToggleButton/YesNoToggleButton';
import {
  doesJobTypeHaveRailBooking,
  doesNotRequireAssetCompletionDetails,
  mayJobTypeHaveRailBooking,
  doesRailJobTypeRequirePax,
} from 'src/views/routes/operations/shared/jobTypeHelpers';
import { DateTimeFormatSettings } from 'src/views/components/DateTimeFormat/DateTimeFormat';
import Header from '../../components/presentational/Header/Header';
import JobFormatter from '../../components/presentational/JobFormatter/JobFormatter';
import { buildPax, IPax } from 'src/views/components/operations/RouteGroups/RouteGroups';
import { isDefined } from 'src/infrastructure/typeUtils';
import { useEffect, useState } from 'react';
import { PaxEntry } from './PaxEntry';
import { observer } from 'mobx-react-lite';
import { useKioskRootStore } from 'src/domain/entities/KioskRootStoreModel';
import { ENABLE_SHOW_WAITING_TIME } from 'src/appSettings';
type JobItem = Operations.Domain.Queries.ViewJob.JobItem;

export interface ICompleteJobProps {
  mode: string;
}

interface ICompleteJobRouteParams {
  id: string;
}

type InternalProps = ICompleteJobProps & RouteComponentProps<ICompleteJobRouteParams>;

interface ICompleteJobFormState {
  id: string;
  odometerStart?: number | '';
  odometerEnd?: number | '';
  distanceTravelled?: number | '';
  zeroKmsTravelled: boolean;
  clockOnOrStart: string;
  clockOffOrFinish: string;
  totalBreaks: string;
  workingTime?: string;
  approvalNumber: string;
  reasonForDifference: string;
  anythingToReport: string;
  tolls?: boolean;
  hasAnythingToReport: boolean;
  pax: IPax[];
  wheelchairPax: number | undefined;
  isContinuingFrom: boolean;
  isContinuingTo: boolean;
  waitingTime?: string;
  isVehicleSwappedFrom?: boolean;
  isVehicleSwappedTo?: boolean;
}

const initialCompleteJobFormState: ICompleteJobFormState = {
  id: '',
  odometerStart: '',
  odometerEnd: '',
  distanceTravelled: '',
  zeroKmsTravelled: false,
  clockOnOrStart: '',
  clockOffOrFinish: '',
  totalBreaks: '',
  workingTime: '',
  approvalNumber: '',
  reasonForDifference: '',
  anythingToReport: '',
  tolls: false,
  hasAnythingToReport: false,
  pax: [],
  wheelchairPax: undefined,
  isContinuingFrom: false,
  isContinuingTo: false,
  waitingTime: '',
  isVehicleSwappedFrom: false,
  isVehicleSwappedTo: false,
};

const CompleteJob: React.FC<InternalProps> = observer((props: InternalProps) => {
  const rootStore = useKioskRootStore();
  const jobModel = rootStore.kiosk.job;
  const staffMemberId = rootStore.account.id;
  const canUseTimesheets = rootStore.account.canUseTimesheets;
  const job = jobModel.job;
  const jobsModel = rootStore.kiosk.jobs;

  const [jobForm, setJobForm] = useState<ICompleteJobFormState>(initialCompleteJobFormState);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [noAssetDetailsRequired, setNoAssetDetailsRequired] = useState<boolean>(false);
  const [errors, setErrors] = useState<any>({});
  const isCurrentUserSecondStaffMember =
    !!staffMemberId &&
    !!job &&
    job.hasSecondStaffMember &&
    job.secondStaffMember &&
    job.secondStaffMember.id === staffMemberId;

  const calculateWorkingTime = (
    clockOn: string,
    clockOff: string,
    totalBreaks: string | undefined
  ) => {
    return formattedWorkingJobHours(clockOn, clockOff, totalBreaks || '00:00') || '';
  };

  const getClockOnOrStart = (job: JobItem) => {
    return (
      (!!job && job.continuation.jobStart) ||
      (!!job && job.vehicleSwapJobDetails?.start) ||
      job.clockOn
    );
  };

  const getClockOffOrFinish = (job: JobItem) => {
    return (
      (!!job && job.continuation.jobFinish) ||
      (!!job && job.vehicleSwapJobDetails?.finish) ||
      job.clockOff
    );
  };

  const areHoursChanged = () => {
    if (!job) {
      return false;
    }

    const clockOnOrStartValue = getClockOnOrStart(job);
    const clockOffOrFinishValue = getClockOffOrFinish(job);

    const plannedWorkingTime = parseEditingFormattedDurationString(
      calculateWorkingTime(clockOnOrStartValue, clockOffOrFinishValue, job.unpaidBreaks)
    );

    const currentWorkingTime = parseEditingFormattedDurationString(
      calculateWorkingTime(jobForm.clockOnOrStart, jobForm.clockOffOrFinish, jobForm.totalBreaks)
    );

    return !!plannedWorkingTime && !!currentWorkingTime && plannedWorkingTime < currentWorkingTime;
  };

  const isApprovalNumberNeeded =
    job && areHoursChanged() && !job.isTrainingJob && job.showApprovalNumber;

  const isNotRailJob =
    job &&
    !doesJobTypeHaveRailBooking(job.jobType.id) &&
    !mayJobTypeHaveRailBooking(job.jobType.id);

  const isRailJobThatRequiresPax = job && doesRailJobTypeRequirePax(job.jobType.id);
  const isCharter = job && job.jobType.id === JobType.Charter;
  const isCharterOrContractCharter = isCharter || job?.jobType.id === JobType.ContractCharter;
  const isCharterOrCharterStagedJob = isCharter || job?.jobType.id === JobType.CharterStaged;
  const showWaitingTime =
    ENABLE_SHOW_WAITING_TIME && isCharterOrContractCharter && job && !job.hasSecondStaffMember;

  const requiresOverrunReason = job && job.requireReasonForDifference;

  const isCreateMode = props.mode === 'create';
  const isReadOnly =
    !!job &&
    ((job.staffMemberId === staffMemberId && !!job.driverClockedOff) ||
      (isCurrentUserSecondStaffMember && !!job.secondDriverClockedOff));

  const getTime = (time: string | undefined) => {
    const duration = parseEditingFormattedDurationString(time);
    if (!duration || !duration.isValid) {
      return '';
    }
    return getEditingFormattedTimeString(duration);
  };

  const jobId = props.match.params.id;

  useEffect(() => {
    if (!jobId) return;

    jobModel.loadJob(jobId).then((currentJob: JobItem | undefined) => {
      setNoAssetDetailsRequired(
        currentJob
          ? doesNotRequireAssetCompletionDetails(currentJob.jobType.id, currentJob.isTrainingJob) ||
              isCurrentUserSecondStaffMember
          : false
      );
      if (currentJob) {
        const now = DateTime.local();
        const clockOnOrStartValue = getClockOnOrStart(currentJob);
        const clockOffOrFinishValue = getClockOffOrFinish(currentJob);
        const nowOrClockOffOrFinishValue =
          now < DateTime.fromISO(clockOffOrFinishValue) ? now.toISO() : clockOffOrFinishValue;

        if (isCreateMode) {
          setJobForm({
            ...initialCompleteJobFormState,
            id: currentJob.id,
            clockOnOrStart: clockOnOrStartValue,
            clockOffOrFinish: nowOrClockOffOrFinishValue,
            totalBreaks: getTime(currentJob.unpaidBreaks),
            workingTime: calculateWorkingTime(
              clockOnOrStartValue,
              nowOrClockOffOrFinishValue,
              getTime(currentJob.unpaidBreaks)
            ),
            pax: buildPax(currentJob.routeGroups),
            wheelchairPax: !isDefined(currentJob.wheelchairPax)
              ? undefined
              : currentJob.wheelchairPax,
            isContinuingFrom: !!currentJob.continuation?.jobStart,
            isContinuingTo: !!currentJob.continuation?.jobFinish,
            waitingTime: getTime(currentJob.driverWaitingTime),
            isVehicleSwappedFrom: !!currentJob.vehicleSwapJobDetails?.start,
            isVehicleSwappedTo: !!currentJob.vehicleSwapJobDetails?.finish,
          });
        } else {
          const isSecond = isCurrentUserSecondStaffMember;
          const totalBreaks = getTime(
            (isSecond ? currentJob.secondDriverUnpaidBreaks : currentJob.driverUnpaidBreaks) ||
              currentJob.unpaidBreaks
          );
          const clockedOn = isSecond
            ? currentJob.secondDriverClockedOn
            : currentJob.driverClockedOn;
          const clockedOff = isSecond
            ? currentJob.secondDriverClockedOff
            : currentJob.driverClockedOff;

          setJobForm({
            id: currentJob.id,
            odometerStart: currentJob.odometerStart,
            odometerEnd: currentJob.odometerEnd,
            distanceTravelled: calculateDistanceTravelled(
              currentJob.odometerStart,
              currentJob.odometerEnd
            ),
            zeroKmsTravelled: !noAssetDetailsRequired ? !!currentJob.zeroKmsTravelled : false,
            clockOnOrStart: clockedOn || clockOnOrStartValue,
            clockOffOrFinish: clockedOff || clockOffOrFinishValue,
            totalBreaks: totalBreaks,
            workingTime: calculateWorkingTime(
              clockedOn || clockOnOrStartValue,
              clockedOff || clockOffOrFinishValue,
              totalBreaks
            ),
            approvalNumber:
              (isSecond ? currentJob.secondDriverApprovalNumber : currentJob.approvalNumber) || '',
            reasonForDifference:
              (isSecond
                ? currentJob.secondDriverReasonForDifference
                : currentJob.reasonForDifference) || '',
            anythingToReport:
              (isSecond ? currentJob.secondDriverAnythingToReport : currentJob.anythingToReport) ||
              '',
            tolls: currentJob.tolls,
            hasAnythingToReport: isSecond
              ? !!currentJob.secondDriverAnythingToReport
              : !!currentJob.anythingToReport,
            pax: buildPax(currentJob.routeGroups),
            wheelchairPax: !isDefined(currentJob.wheelchairPax)
              ? undefined
              : currentJob.wheelchairPax,
            isContinuingFrom: !!currentJob.continuation?.jobStart,
            isContinuingTo: !!currentJob.continuation?.jobFinish,
            waitingTime: getTime(currentJob.driverWaitingTime),
            isVehicleSwappedFrom: !!currentJob.vehicleSwapJobDetails?.start,
            isVehicleSwappedTo: !!currentJob.vehicleSwapJobDetails?.finish,
          });
        }
      }
    });
  }, [jobId]);

  const calculateDistanceTravelled = (odometerStart?: number | '', odometerEnd?: number | '') => {
    const distance = (odometerEnd || 0) - (odometerStart || 0);
    if (distance <= 0) {
      return '';
    }
    return distance;
  };

  const validateWaitingTime = () => {
    const waitingTime = parseEditingFormattedDurationString(jobForm.waitingTime);
    if (waitingTime && !waitingTime.isValid) {
      return 'Invalid time';
    }

    if ((jobForm.waitingTime || '0') > (jobForm.workingTime || '0')) {
      return 'Waiting time cannot be more than working time';
    }

    return undefined;
  };

  const validateClockOff = () => {
    const clockOnDateTime = DateTime.fromISO(jobForm.clockOnOrStart);
    const clockOffDateTime = DateTime.fromISO(jobForm.clockOffOrFinish);
    if (!clockOffDateTime.isValid) {
      return 'Clock off is required';
    }

    if (clockOnDateTime.isValid && clockOffDateTime.isValid && clockOffDateTime < clockOnDateTime) {
      return 'Clock off cannot be less than clock on';
    }

    const nowPlusFiveMinutes = DateTime.local().plus({ minutes: 5 });
    if (clockOffDateTime > nowPlusFiveMinutes) {
      return 'Your clock off time must be within 5 minutes of the current time';
    }

    if (doesActualWorkingTimeExceed24HoursFromExpected()) {
      return 'Cannot exceed 24 hours from expected paid time';
    }
    return undefined;
  };

  const doesActualWorkingTimeExceed24HoursFromExpected = () => {
    if (!job) {
      return undefined;
    }
    var expectedWorkingTime = parseEditingFormattedDurationString(
      calculateWorkingTime(job.clockOn, job.clockOff, job.unpaidBreaks)
    );
    var actualWorkingTime = parseEditingFormattedDurationString(
      calculateWorkingTime(jobForm.clockOnOrStart, jobForm.clockOffOrFinish, jobForm.totalBreaks)
    );
    const maxDifferenceInWorkingTime = Duration.fromObject({ hours: 24 });
    if (
      !expectedWorkingTime ||
      !expectedWorkingTime.isValid ||
      !actualWorkingTime ||
      !actualWorkingTime.isValid
    ) {
      return false;
    }
    const difference = actualWorkingTime.minus(expectedWorkingTime);
    if (difference >= maxDifferenceInWorkingTime) {
      return true;
    }
    return false;
  };

  const validateTotalBreaks = () => {
    const clockOnDateTime = DateTime.fromISO(jobForm.clockOnOrStart);
    const clockOffDateTime = DateTime.fromISO(jobForm.clockOffOrFinish);
    const totalBreaksDuration = parseEditingFormattedDurationString(jobForm.totalBreaks);
    if (!totalBreaksDuration?.isValid) {
      return 'Total breaks is required';
    }

    if (
      totalBreaksDuration &&
      totalBreaksDuration.isValid &&
      clockOnDateTime.isValid &&
      clockOffDateTime.isValid &&
      clockOffDateTime.diff(clockOnDateTime).as('minutes') < totalBreaksDuration.as('minutes')
    ) {
      return 'Total breaks cannot be greater than total time';
    }

    return undefined;
  };

  const getNumberOrUndefined = (value?: number | '') => {
    if (!value) {
      return undefined;
    }
    if (isNaN(value)) {
      return undefined;
    }
    return Number(value);
  };

  const validate = () => {
    const jobHasAsset = !noAssetDetailsRequired;
    return {
      odometerStart:
        jobHasAsset && !jobForm.zeroKmsTravelled && !jobForm.odometerStart
          ? 'Odometer start is required'
          : undefined,
      odometerEnd:
        jobHasAsset && !jobForm.zeroKmsTravelled && !jobForm.odometerEnd
          ? 'Odometer end is required'
          : jobHasAsset &&
            !jobForm.zeroKmsTravelled &&
            jobForm.odometerEnd &&
            jobForm.odometerStart &&
            jobForm.odometerEnd < jobForm.odometerStart
          ? 'Odometer end cannot be less than odometer start'
          : undefined,
      tolls:
        isCharterOrCharterStagedJob && jobForm.tolls === undefined && !jobForm.zeroKmsTravelled
          ? 'Tolls is required'
          : undefined,
      zeroKmsTravelled:
        jobHasAsset && jobForm.zeroKmsTravelled === undefined
          ? 'Zero Kms Travelled is required'
          : undefined,
      clockOffOrFinish: validateClockOff(),
      totalBreaks: validateTotalBreaks(),
      approvalNumber:
        isNotRailJob && isApprovalNumberNeeded && !jobForm.approvalNumber
          ? 'Approval number is required'
          : undefined,
      reasonForDifference:
        requiresOverrunReason && areHoursChanged() && !jobForm.reasonForDifference
          ? 'Reason for difference is required'
          : undefined,
      anythingToReport:
        jobForm.hasAnythingToReport && !jobForm.anythingToReport
          ? 'Please enter something'
          : undefined,
      pax: {
        wheelchairPax:
          isRailJobThatRequiresPax && !isDefined(jobForm.wheelchairPax) ? 'Required' : undefined,
        pax: jobForm.pax.map(p => ({
          routeId: p.routeId,
          message:
            isRailJobThatRequiresPax && p.depart && !isDefined(p.count) ? 'Required' : undefined,
        })),
      },
      waitingTime: validateWaitingTime(),
    };
  };

  const validateForm = (updateFields?: string[]) => {
    let errors = validate();
    if (updateFields && updateFields.length > 0) {
      // poor man's change tracking
      Object.keys(errors).forEach(key => {
        if (updateFields.indexOf(key) === -1) {
          errors[key] = undefined;
        }
      });
    }

    setErrors(errors);

    const paxKey = 'pax';

    const msgs = Object.keys(errors)
      .filter(x => x !== 'pax')
      .map(key => {
        return errors[key];
      })
      .concat(
        errors[paxKey] && [errors[paxKey].wheelchairPax, ...errors[paxKey].pax.map(p => p.message)]
      )
      .filter(val => !!val);

    return msgs.length === 0;
  };

  const handleSubmit = (event: React.FormEvent<EventTarget>) => {
    event.preventDefault();
    if (!validateForm()) {
      return;
    }

    setIsSubmitting(true);
    const pax = jobForm.pax.map(x => ({ routeId: x.routeId, count: x.count }));

    const staffDetail = {
      jobId: jobId,
      clockOff: jobForm.clockOffOrFinish,
      unpaidBreaks: jobForm.totalBreaks ?? '00:00',
      approvalNumber: isApprovalNumberNeeded ? jobForm.approvalNumber : '',
      reasonForDifference: areHoursChanged() ? jobForm.reasonForDifference : '',
      anythingToReport: jobForm.anythingToReport,
      tolls: !!jobForm.tolls,
      pax: pax,
      wheelchairPax: jobForm.wheelchairPax,
      zeroKmsTravelled: !noAssetDetailsRequired ? !!jobForm.zeroKmsTravelled : false,
      waitingTime: showWaitingTime ? jobForm.waitingTime : '',
    };

    const assetDetail = {
      odometerEnd: jobForm.zeroKmsTravelled ? null : getNumberOrUndefined(jobForm.odometerEnd),
      odometerStart: jobForm.zeroKmsTravelled ? null : getNumberOrUndefined(jobForm.odometerStart),
    };

    const cmd = isCurrentUserSecondStaffMember ? staffDetail : { ...staffDetail, ...assetDetail };

    jobsModel.completeJob(cmd).finally(() => setIsSubmitting(false));
  };

  const reasonForDifferenceField = (isReadOnly: boolean) => {
    return (
      <FormGroup>
        <Label for="reasonForDifference">Reason For Difference*</Label>
        <Input
          className={cn({ readonly: isReadOnly })}
          type="text"
          name="reasonForDifference"
          id="reasonForDifference"
          onChange={e => {
            setJobForm({ ...jobForm, reasonForDifference: e.currentTarget.value });
          }}
          value={jobForm.reasonForDifference}
        />
        {errors.reasonForDifference && <FormFeedback>{errors.reasonForDifference}</FormFeedback>}
      </FormGroup>
    );
  };

  if (!job || !job.id) {
    return null;
  }

  return (
    <div className="complete-job-component">
      <Header title={<JobFormatter job={job} />} to="/kiosk" canUseTimesheets={canUseTimesheets} />
      <form
        autoComplete="off"
        className={cn('form', {
          'require-approval': isApprovalNumberNeeded,
          'require-reason-for-difference-only': areHoursChanged() && !isApprovalNumberNeeded,
          'no-asset': noAssetDetailsRequired,
        })}
        onSubmit={values => handleSubmit(values)}>
        {!noAssetDetailsRequired && (
          <div className="distance">
            <div className="distance-title">
              <h3>Distance</h3>
            </div>
            <div className="zero-kms">
              <FormGroup>
                <Label for="zero-kms">Zero Kms Travelled</Label>
                <InputGroup>
                  <YesNoToggleButton
                    value={jobForm.zeroKmsTravelled}
                    disabled={isReadOnly}
                    onChange={value => {
                      if (value) {
                        setJobForm({
                          ...jobForm,
                          zeroKmsTravelled: value,
                          odometerStart: '',
                          odometerEnd: '',
                          distanceTravelled: '',
                        });
                      } else {
                        setJobForm({
                          ...jobForm,
                          zeroKmsTravelled: value,
                        });
                      }
                    }}
                  />
                </InputGroup>
                <FormFeedback>{errors.tolls}</FormFeedback>
              </FormGroup>
            </div>
            <div className="odometer-start">
              <FormGroup>
                <Label for="odometerStart">Odometer Start*</Label>
                <InputGroup className={cn({ readonly: isReadOnly })}>
                  <Input
                    type="text"
                    name="odometerStart"
                    id="odometerStart"
                    onChange={e => {
                      setJobForm({
                        ...jobForm,
                        odometerStart:
                          unsignedIntegerSanitiser(e.target.value, {
                            maxValue: 9999999,
                          }) || 0,
                        distanceTravelled: calculateDistanceTravelled(
                          unsignedIntegerSanitiser(e.target.value, {
                            maxValue: 9999999,
                          }),
                          jobForm.odometerEnd
                        ),
                      });
                    }}
                    onBlur={() => validateForm(Object.keys(jobForm))}
                    value={jobForm.odometerStart}
                    disabled={isReadOnly || jobForm.zeroKmsTravelled}
                  />
                  <InputGroupAddon addonType="append">
                    <InputGroupText>KM</InputGroupText>
                  </InputGroupAddon>
                </InputGroup>
                <FormFeedback>{errors.odometerStart}</FormFeedback>
              </FormGroup>
            </div>
            <div className="odometer-end">
              <FormGroup>
                <Label for="odometerEnd">Odometer End*</Label>
                <InputGroup className={cn({ readonly: isReadOnly })}>
                  <Input
                    type="text"
                    name="odometerEnd"
                    id="odometerEnd"
                    onChange={e => {
                      setJobForm({
                        ...jobForm,
                        odometerEnd:
                          unsignedIntegerSanitiser(e.target.value, {
                            maxValue: 9999999,
                          }) || 0,
                        distanceTravelled: calculateDistanceTravelled(
                          jobForm.odometerStart,
                          unsignedIntegerSanitiser(e.target.value, {
                            maxValue: 9999999,
                          }) || 0
                        ),
                      });
                    }}
                    onBlur={() => validateForm(Object.keys(jobForm))}
                    value={jobForm.odometerEnd}
                    disabled={isReadOnly || jobForm.zeroKmsTravelled}
                  />
                  <InputGroupAddon addonType="append">
                    <InputGroupText>KM</InputGroupText>
                  </InputGroupAddon>
                </InputGroup>
                <FormFeedback>{errors.odometerEnd}</FormFeedback>
              </FormGroup>
            </div>
            <div className="odometer-distance">
              <FormGroup>
                <Label for="odometerDistance">Odometer Distance*</Label>
                <InputGroup className="readonly">
                  <Input
                    type="text"
                    name="odometerDistance"
                    id="odometerDistance"
                    value={jobForm.distanceTravelled}
                    disabled
                  />
                  <InputGroupAddon addonType="append">
                    <InputGroupText>KM</InputGroupText>
                  </InputGroupAddon>
                </InputGroup>
              </FormGroup>
            </div>
            {isCharterOrCharterStagedJob && (
              <div className="tolls">
                <FormGroup>
                  <Label for="tolls">Tolls*</Label>
                  <InputGroup>
                    <YesNoToggleButton
                      disabled={isReadOnly || jobForm.zeroKmsTravelled}
                      value={jobForm.tolls}
                      onChange={value =>
                        setJobForm({
                          ...jobForm,
                          tolls: value,
                        })
                      }
                    />
                  </InputGroup>
                  <FormFeedback>{errors.tolls}</FormFeedback>
                </FormGroup>
              </div>
            )}
          </div>
        )}

        {isRailJobThatRequiresPax && (
          <PaxEntry
            isReadOnly={isReadOnly}
            pax={jobForm.pax}
            wheelchairPax={jobForm.wheelchairPax}
            routeGroups={job.routeGroups}
            updatePax={(newPax, wheelchairPax) => {
              setJobForm({ ...jobForm, pax: newPax, wheelchairPax: wheelchairPax });
              validateForm();
            }}
            validationWarnings={errors.pax}
          />
        )}

        <div className="reporting">
          <div className="reporting-title">
            <h3>Further Info</h3>
          </div>
          <div className="anything-to-report">
            <FormGroup>
              <Label for="hasAnythingToReport">Anything to Report</Label>
              <InputGroup>
                <YesNoToggleButton
                  disabled={isReadOnly}
                  value={jobForm.hasAnythingToReport}
                  onChange={value => {
                    setJobForm({
                      ...jobForm,
                      hasAnythingToReport: value,
                    });
                  }}
                />
              </InputGroup>
              <Input
                type="textarea"
                name="anythingToReport"
                id="anythingToReport"
                onChange={e => {
                  setJobForm({
                    ...jobForm,
                    anythingToReport: e.target.value,
                  });
                }}
                value={jobForm.anythingToReport}
                hidden={!jobForm.hasAnythingToReport}
                disabled={isReadOnly}
                className={cn({ readonly: isReadOnly })}
              />
              <FormFeedback>{errors.anythingToReport}</FormFeedback>
            </FormGroup>
          </div>
        </div>

        <div className="time">
          <div className="time-title">
            <h3>Time</h3>
          </div>
          <div className="clock-on">
            <FormGroup>
              <Label for="clockOn">
                {jobForm.isContinuingFrom || jobForm.isVehicleSwappedFrom ? 'Start' : 'Clock On'}
              </Label>
              <Input
                disabled
                type="text"
                className="readonly"
                name="clockOn"
                id="clockOn"
                value={DateTime.fromISO(jobForm.clockOnOrStart).toLocaleString(
                  DateTimeFormatSettings
                )}
              />
            </FormGroup>
          </div>
          <div className="clock-off">
            <FormGroup>
              <Label for="clockOffOrFinish">
                {jobForm.isContinuingTo || jobForm.isVehicleSwappedTo ? 'Finish*' : 'Clock Off*'}
              </Label>
              {isReadOnly ? (
                <Input
                  disabled
                  type="text"
                  name="clockOffOrFinish"
                  id="clockOffOrFinish"
                  className="readonly"
                  value={DateTime.fromISO(jobForm.clockOffOrFinish).toLocaleString(
                    DateTimeFormatSettings
                  )}
                />
              ) : (
                <DateTimeInput
                  onChange={value => {
                    setJobForm({
                      ...jobForm,
                      clockOffOrFinish: value,
                      workingTime: calculateWorkingTime(
                        jobForm.clockOnOrStart,
                        value,
                        jobForm.totalBreaks
                      ),
                    });
                  }}
                  value={jobForm.clockOffOrFinish}
                />
              )}
              <FormFeedback>{errors.clockOffOrFinish}</FormFeedback>
            </FormGroup>
          </div>

          {job.quoteType?.dontRequireDriverWorkingHoursCompletionDetails ? null : (
            <>
              <div className="total-breaks">
                <FormGroup>
                  <Label for="totalBreaks">Total Breaks*</Label>
                  {isReadOnly ? (
                    <Input
                      disabled
                      type="text"
                      name="totalBreaks"
                      id="totalBreaks"
                      className="readonly"
                      value={jobForm.totalBreaks || '00:00'}
                    />
                  ) : (
                    <TimeInput
                      onChange={value => {
                        setJobForm({
                          ...jobForm,
                          totalBreaks: value,
                          workingTime: calculateWorkingTime(
                            jobForm.clockOnOrStart,
                            jobForm.clockOffOrFinish,
                            value
                          ),
                        });
                      }}
                      onBlur={() => validateForm(Object.keys(jobForm))}
                      value={jobForm.totalBreaks}
                    />
                  )}
                  <FormFeedback>{errors.totalBreaks}</FormFeedback>
                </FormGroup>
              </div>
              <div className="working-time">
                <FormGroup>
                  <Label for="workingTime">Working Time</Label>
                  <Input
                    type="text"
                    name="workingTime"
                    id="workingTime"
                    className="readonly"
                    value={jobForm.workingTime}
                    disabled
                  />
                </FormGroup>
              </div>
              {showWaitingTime && (
                <div className="waiting-time">
                  <FormGroup>
                    <Label for="waiting-time">Waiting Time</Label>
                    {isReadOnly ? (
                      <Input
                        disabled
                        type="text"
                        name="waiting-time"
                        id="waiting-time"
                        className="readonly"
                        value={jobForm.waitingTime || '00:00'}
                      />
                    ) : (
                      <TimeInput
                        name="waiting-time"
                        onChange={value => {
                          setJobForm({
                            ...jobForm,
                            waitingTime: value,
                          });
                        }}
                        value={jobForm.waitingTime || '00:00'}
                      />
                    )}
                    <FormFeedback>{errors.waitingTime}</FormFeedback>
                  </FormGroup>
                </div>
              )}
            </>
          )}
        </div>

        {isApprovalNumberNeeded && (
          <div className="approval">
            <div className="approval-title">
              <h3>Approval</h3>
            </div>
            <div className="approval-message">
              <p>
                Hours entered are greater than allocated hours. Please provide a reason and consult
                Operations staff to get an approval number.
              </p>
            </div>
            <div className="approval-number">
              <FormGroup>
                <Label for="approvalNumber">
                  Approval Number
                  {isNotRailJob ? '*' : ''}
                </Label>
                <Input
                  type="text"
                  name="approvalNumber"
                  id="approvalNumber"
                  onChange={e => {
                    setJobForm({
                      ...jobForm,
                      approvalNumber: e.currentTarget.value,
                    });
                  }}
                  value={jobForm.approvalNumber}
                  disabled={isReadOnly}
                  className={cn({ readonly: isReadOnly })}
                />
                <FormFeedback>{errors.approvalNumber}</FormFeedback>
              </FormGroup>
            </div>
            <div className="approval-reason-for-difference">
              {reasonForDifferenceField(isReadOnly)}
            </div>
          </div>
        )}

        {requiresOverrunReason && areHoursChanged() && !isApprovalNumberNeeded && (
          <div className="reason-for-difference">
            <div className="reason-for-difference-title">
              <h3>Reason for time difference</h3>
            </div>
            <div className="reason-for-difference-message">
              <p>
                Hours entered are greater than allocated hours. Please provide a reason for that
                difference.
              </p>
            </div>
            <div className="reason-for-difference-input">
              {reasonForDifferenceField(isReadOnly)}
            </div>
          </div>
        )}

        {!isReadOnly && (
          <div className="submit">
            <Button disabled={isSubmitting} type="submit" size="lg">
              Submit
            </Button>
          </div>
        )}
      </form>
    </div>
  );
});

export default CompleteJob;
