import { getSubmitCloseModalActionGroupDef } from 'src/views/definitionBuilders/common';
import { PaneType, ModalDefBuilder } from 'src/views/definitionBuilders/types';

export default function getUncheduleJobTaskModalDef(
  onUnscheduleJobTask: (jobTaskId: string) => Promise<void>,
  jobTaskId: string
): ModalDefBuilder {
  return modalDefApi => ({
    title: 'Unschedule Task',
    asForm: true,
    panels: [
      {
        panes: [
          {
            paneType: PaneType.customPane,
            render: api => <p>Are you sure you want to unschedule this task?</p>,
          },
        ],
      },
    ],
    secondaryActions: [getSubmitCloseModalActionGroupDef('Ok')],
    onFormSubmit: (values: {}) => {
      return onUnscheduleJobTask(jobTaskId);
    },
  });
}
