import { DateTime } from 'luxon';
import * as queryString from 'query-string';

type GetWeekWithoutTimesheetQuery = People.Domain.Queries.GetWeekWithoutTimesheet.GetWeekWithoutTimesheetQuery;
type GetWeekWithoutRosterQuery = Workshop.Domain.Queries.Roster.GetWeekWithoutRoster.GetWeekWithoutRosterQuery;
type ListTimesheetsQuery = People.Domain.Queries.ListTimesheets.ListTimesheetsQuery;
type ListDetailedTimesheetsQuery = People.Domain.Queries.ListDetailedTimesheets.ListDetailedTimesheetsQuery;
type ListTimesheetNotesQuery = People.Domain.Queries.ListTimesheetNotes.ListTimesheetNotesQuery;
type ListShiftTemplatesQuery = Workshop.Domain.Queries.ShiftTemplate.ListShiftTemplates.ListShiftTemplatesQuery;
type ListRosterTemplatesQuery = Workshop.Domain.Queries.RosterTemplate.ListRosterTemplates.ListRosterTemplatesQuery;
type ListRostersQuery = Workshop.Domain.Queries.Roster.ListRosters.ListRostersQuery;
type ListShiftsQuery = Workshop.Domain.Queries.Shift.ListShifts.ListShiftsQuery;
type AutocompleteListRostersQuery = Workshop.Domain.Queries.Roster.AutocompleteListRosters.AutocompleteListRostersQuery;
type ListRosterNamesQuery = Workshop.Domain.Queries.Roster.ListRosterNames.ListRosterNamesQuery;
type GetUsedPartsPdfReportQuery = Workshop.Domain.Queries.GetUsedPartsPdfReport.GetUsedPartsPdfReportQuery;
type ListPartsForDropdownQuery = Workshop.Domain.Queries.Parts.ListPartsForDropdown.ListPartsForDropdownQuery;
type GetTechSpecDropdownOptionsQuery = Workshop.Domain.Queries.TechSpecs.GetTechSpecDropdownOptions.GetTechSpecDropdownOptionsQuery;
type UpdateDataFuelAssetsIgnoredCommand = Workshop.Domain.Commands.DataFuel.IgnoredAssets.UpdateDataFuelAssetsIgnoredCommand;
type GetTimesheetReminderQuery = People.Domain.Queries.GetTimesheetReminder.GetTimesheetReminderQuery;
type GetAssetsWithInspectionsDueQuery = Workshop.Domain.Queries.GetAssetsWithInspectionsDue.GetAssetsWithInspectionsDueQuery;
type GetVehicleTypeAllocationDataQuery = Operations.Domain.Queries.GetVehicleTypeAllocationData.GetVehicleTypeAllocationDataQuery;
type ListPurchaseOrdersForDropdownQuery = Workshop.Domain.Queries.Purchasing.ListPurchaseOrdersForDropdown.ListPurchaseOrdersForDropdownQuery;
type FindPurchaseOrdersQuery = Workshop.Domain.Queries.Purchasing.FindPurchaseOrders.FindPurchaseOrdersQuery;
type DeleteSupplierNoteCommand = Workshop.Domain.Commands.Supplier.DeleteSupplierNoteCommand;
type ListJobTaskSubcategoriesQuery = Workshop.Domain.Queries.JobTaskSubcategories.List.ListJobTaskSubcategoriesQuery;

const enc = encodeURIComponent;

// Urls are exported as functions for consistency
// Some may allow different options, such as query string parameters in future

export const authUrls = {
  profileUrl: () => `/api/profile`,
  signInUrl: (returnUrl?: string) => `/sign-in?${queryString.stringify({ returnUrl })}`,
  signOutUrl: () => `/sign-out`,
  kioskSignOutUrl: () => `/api/kiosk/sign-out`,
  kioskSignInUrl: () => `/api/kiosk/sign-in`,
  kioskProfileUrl: () => `/api/kiosk/profile`,
  kioskStaffMemberDetailsUrl: (employeeId: string) =>
    `/api/kiosk/profile/${enc(employeeId)}/staff-member-details`,
};

export const jobUrls = {
  listJobs: (query: Partial<Workshop.Domain.Queries.Job.ListJobs.ListJobsQuery>) =>
    `/api/workshop/jobs/search?${queryString.stringify(query)}`,
  getJobsForAsset: (assetId: string) => `/api/workshop/jobs/asset/${assetId}`,
  listJobCategories: () => `/api/workshop/job-categories`,
  listScheduledJobs: (start: DateTime, end: DateTime, depotId: number) =>
    `/api/workshop/jobs/scheduled?StartDateTime=${start
      .toUTC()
      .toISO()}&EndDateTime=${end.toUTC().toISO()}&depotId=${depotId}`,
  listScheduledMachineryJobs: (start: DateTime, end: DateTime, depotId: number) =>
    `/api/workshop/jobs/machinery/scheduled?StartDateTime=${start
      .toUTC()
      .toISO()}&EndDateTime=${end.toUTC().toISO()}&depotId=${depotId}`,
  createJob: () => `/api/workshop/jobs/create`,
  loadPrintJobSheet: (jobId: string) => `/api/workshop/jobs/${jobId}/print`,
  loadDailyRunSheet: (date: DateTime, depotId: number) =>
    `/api/workshop/daily-run-sheet?startDate=${date.toISODate()}&depotId=${depotId}`,
  loadJob: (id: string) => `/api/workshop/jobs/${id}`,
  updateJob: (id: string) => `/api/workshop/jobs/${id}`,
  generateJobWorkOrderReport: (jobId: string, internalUse: boolean) =>
    `/api/workshop/jobs/${jobId}/pdf/${internalUse}`,
  markAsInvoiced: (id: string) => `/api/workshop/jobs/${id}/invoiced`,
  loadBusUsages: (id: string) => `/api/workshop/jobs/${id}/bus-usages`,
  updateBusUsages: (command: Workshop.Domain.Commands.Job.UpdateWorkshopJobProgressCommand) =>
    `/api/workshop/jobs/${command.jobId}/bus-usages`,
  getPreviousAndNextProgresses: (jobId: string) =>
    `/api/workshop/jobs/${jobId}/previous-next-progress`,
};

export const jobTaskUrls = {
  exportTasksToExcel: (
    query: Partial<Workshop.Domain.Queries.JobTask.ListJobTasks.ListJobTasksQuery>
  ) => `/api/workshop/job-tasks/search/excel?${queryString.stringify(query)}`,
  listTasks: (query: Partial<Workshop.Domain.Queries.JobTask.ListJobTasks.ListJobTasksQuery>) =>
    `/api/workshop/job-tasks/search?${queryString.stringify(query)}`,
  listJobTasksForAsset: (id: string) => `/api/workshop/job-tasks/?assetId=${id}`,
  listOutOfServiceJobTasks: (
    query: Partial<
      Workshop.Domain.Queries.JobTask.ListOutOfServiceJobTasks.ListOutOfServiceJobTasksQuery
    >
  ) => `/api/workshop/job-tasks/out-of-service?${queryString.stringify(query)}`,
  getJobTask: (id: string) => `/api/workshop/job-tasks/${id}`,
  getJobDetailsForTask: (id: string) => `/api/workshop/job-tasks/${id}/job-details`,
  getSchedulableJobsForTask: (id: string) => `/api/workshop/job-tasks/${id}/jobs`,
  getActiveJobsForTask: (id: string) => `/api/workshop/job-tasks/${id}/active-jobs`,
  loadJobTaskCategories: () => `/api/workshop/job-task-categories`,
  edit: (jobTaskId: string) => `/api/workshop/job-tasks/${jobTaskId}`,
  scheduleMachineryJobTask: () => `/api/workshop/job-tasks/schedule-machinery`,
  scheduleServiceJobTask: () => '/api/workshop/job-tasks/schedule-service',
  schedulePreventativeMaintenanceServiceJobTask: () =>
    '/api/workshop/job-tasks/schedule-preventative-maintenance-service',
  scheduleFutureJobTask: () => '/api/workshop/job-tasks/schedule-future-task',
  cancelJobTask: (id: string) => `/api/workshop/job-tasks/${id}/cancel`,
  deleteJobTask: (id: string) => `/api/workshop/job-tasks/${id}/delete`,
  createAdhocJobTask: () => `/api/workshop/job-tasks/create`,
  unscheduleFutureJobTask: (id: string) => `/api/workshop/job-tasks/${id}/unschedule`,
  completeJobTask: (id: string) => `/api/workshop/job-tasks/${id}/complete`,
  completeJobTaskAndResetSchedule: (id: string) =>
    `/api/workshop/job-tasks/${id}/complete-and-reset-schedule`,
  startJobTaskProgress: (id: string) => `/api/workshop/job-tasks/${id}/start-progress`,
  closeJobTaskWithoutFixing: (id: string) => `/api/workshop/job-tasks/${id}/close-task`,
};

export const assetUrls = {
  getAssetsList: () => `/api/workshop/assets`,
  listAssets: (query: Partial<Workshop.Domain.Queries.ListAssets.ListAssetsQuery>) =>
    `/api/workshop/assets/search?${queryString.stringify(query)}`,
  listUnallocatedAssets: (
    query: Partial<Workshop.Domain.Queries.ListUnallocatedAssets.ListUnallocatedAssetsQuery>
  ) => `/api/workshop/assets/unallocated?${queryString.stringify(query)}`,
  listAssetsWithInspectionsDue: (query: GetAssetsWithInspectionsDueQuery) =>
    `/api/workshop/assets/inspections-due?${queryString.stringify(query)}`,
  loadAssetOdometerReadings: (date: DateTime) =>
    `/api/workshop/assets/odometer-readings/${date.toISODate()}`,
  createAsset: () => '/api/workshop/assets/create',
  loadFleetAssets: (includeDecommissioned: boolean) =>
    `/api/workshop/assets/fleet?includeDecommissioned=${includeDecommissioned}`,
  loadAssetFuelLocations: () => `/api/workshop/asset-fuel-locations`,
  getAssetCategories: () => '/api/workshop/asset-categories',
  getAssetHousingLocations: () => '/api/workshop/asset-housing-locations',
  getAssetSubcategories: () => '/api/workshop/asset-subcategories',
  checkForUniqueName: (name: string) =>
    `/api/workshop/assets/check-for-unique-name?assetName=${enc(name)}`,
  checkForUniqueRegistrationNumber: (registrationNumber: string) =>
    `/api/workshop/assets/check-for-unique-registration-number?registrationNumber=${enc(
      registrationNumber
    )}`,
  getAssetServicePlan: (assetId: string) => `/api/workshop/assets/${assetId}/service-plan`,
  getAssetComponents: (assetId: string) => `/api/workshop/assets/${assetId}/components`,
  getAssetParts: (assetId: string) => `/api/workshop/assets/${assetId}/parts`,
  getAssetFuels: (
    assetId: string,
    includeTabletData: boolean,
    maxResults: number,
    includeDataFuel?: boolean
  ) =>
    `/api/workshop/assets/${assetId}/fuels?maxResults=${maxResults}&includeTabletData=${includeTabletData}${
      includeDataFuel !== undefined ? `&includeDataFuel=${includeDataFuel}` : ''
    }`,
  getOwner: (assetId: string) => `/api/workshop/assets/${assetId}/owner`,
  getServicesDue: (
    query: Partial<
      Workshop.Domain.Queries.AssetServices.GetAssetServiceDueList.GetAssetServiceDueListQuery
    >
  ) => `/api/workshop/assets/service-due?${queryString.stringify(query)}`,
  cancelAssetService: (id: string) => `/api/workshop/assets/${id}/service-schedule/cancel`,
  getAsset: (id: string) => `/api/workshop/assets/${enc(id)}`,
  getFutureJobsForAsset: (id: string) => `/api/workshop/assets/${id}/jobs`,
  getTasksForAsset: (id: string) => `/api/workshop/assets/${id}/tasks`,
  loadServiceHistory: (id: string) => `/api/workshop/assets/${id}/service-history`,
  updateAsset: (id: string) => `/api/workshop/assets/${enc(id)}`,
  resetAssetServiceSchedule: (assetId: string) =>
    `/api/workshop/assets/${assetId}/service-schedule/reset`,
  updateServicePlan: (assetId: string) => `/api/workshop/assets/${enc(assetId)}/service-schedule`,
  updateComponents: (assetId: string) => `/api/workshop/assets/${enc(assetId)}/components`,
  updateParts: (assetId: string) => `/api/workshop/assets/${enc(assetId)}/parts`,
  getDefectsForAsset: (defectId: string) => `/api/workshop/assets/${enc(defectId)}/defects`,
  getNextServiceForAsset: (assetId: string) => `/api/workshop/assets/${enc(assetId)}/next-service`,
  getFutureTasksForAsset: (assetId: string) => `/api/workshop/assets/${enc(assetId)}/future-tasks`,
  getScheduledTasksForAsset: (assetId: string) =>
    `/api/workshop/assets/${enc(assetId)}/scheduled-tasks`,
  generateAssetJobReport: (query: Workshop.Domain.Queries.AssetJobReport.AssetJobReportQuery) =>
    `/api/workshop/assets/${enc(query.assetId)}/jobs/pdf?${queryString.stringify(query)}`,
  generateAssetKmsReport: (assetId: string, from: string, to: string) =>
    `/api/workshop/assets/${enc(assetId)}/kms-jobtype?${queryString.stringify({
      from,
      to,
    })}`,
  exportAssetsToExcel: (query: Partial<Workshop.Domain.Queries.ListAssets.ListAssetsQuery>) =>
    `/api/workshop/assets/excel?${queryString.stringify(query)}`,
  exportAssetsFuelsToExcel: (
    query: Workshop.Domain.Queries.ExportAssetFuelToExcel.ExportAssetFuelToExcelQuery
  ) => `/api/workshop/assets/fuels/excel?${queryString.stringify(query)}`,
  exportFuelConsumptionReportToExcel: (
    query: Workshop.Domain.Queries.ExportFuelConsumptionReportToExcel.ExportFuelConsumptionReportToExcelQuery
  ) => `/api/workshop/assets/fuel-consumption/excel?${queryString.stringify(query)}`,
  exportAssetMaintenanceCostsToExcel: (
    query: Partial<Workshop.Domain.Queries.ListAssets.ListAssetsQuery>
  ) => `/api/workshop/assets/maintenance-costs/excel?${queryString.stringify(query)}`,
  exportAssetsComponentsToExcel: (
    query: Partial<Workshop.Domain.Queries.ListAssets.ListAssetsQuery>
  ) => `/api/workshop/assets/components/excel?${queryString.stringify(query)}`,
  exportAssetServicesToExcel: (
    query: Partial<Workshop.Domain.Queries.ListAssets.ListAssetsQuery>
  ) => `/api/workshop/assets/services?${queryString.stringify(query)}`,
  exportAssetPartRegisterToExcel: (
    query: Partial<Workshop.Domain.Queries.ListAssets.ListAssetsQuery>
  ) => `/api/workshop/assets/part-register/excel?${queryString.stringify(query)}`,
  decommission: (assetId: string) => `/api/workshop/assets/${assetId}/decommission`,
  searchTechSpecValues: (search: string, techSpecId: number) =>
    `/api/workshop/assets/search-tech-spec-values?searchQuery=${search}&techSpecId=${techSpecId}`,
  getLastOdometerReadings: (
    query: Workshop.Domain.Queries.GetLastAssetOdometerReadings.GetLastAssetOdometerReadingQuery
  ) => `/api/workshop/assets/odometer-readings/last?${queryString.stringify(query)}`,
  addFuel: (assetId: string) => `/api/workshop/assets/${assetId}/fuels`,
  updateFuel: (assetId: string) => `/api/workshop/assets/${assetId}/fuels`,
};

export const workshopUrls = {
  assetCubic: {
    list: (assetId: string) => `/api/workshop/assets/${assetId}/cubic`,
    summary: (assetId: string) => `/api/workshop/assets/${assetId}/cubic/summary`,
    generateExcel: (assetId: string) => `/api/workshop/assets/${assetId}/cubic/excel`,
    generatePdfForm: (assetId: string, assetCubicId: number) =>
      `/api/workshop/assets/${assetId}/cubic/${assetCubicId}/pdf`,
    generateExcelForAllAssets: () => `/api/workshop/assets/cubic-excel`,
    cubicFaultDetails: `/api/workshop/assets/cubic-fault-details`,
    cubicItems: '/api/workshop/assets/cubic-items',
    create: (assetId: string) => `/api/workshop/assets/${assetId}/cubic`,
    update: (assetId: string) => `/api/workshop/assets/${assetId}/cubic`,
  },
  dashboardUrls: {
    loadDashboard: (depotIds: number[]) =>
      `/api/workshop/dashboard?${queryString.stringify({ depotIds })}`,
  },
  dataFuel: {
    list: (query: Partial<Workshop.Domain.Queries.DataFuel.ListDataFuel.ListDataFuelQuery>) =>
      `/api/workshop/data-fuel/entries?${queryString.stringify(query)}`,
    listUploads: (
      query: Partial<Workshop.Domain.Queries.DataFuel.ListDataFuelUpload.ListDataFuelUploadQuery>
    ) => `/api/workshop/data-fuel/uploads?${queryString.stringify(query)}`,
    uploadFile: () => `/api/workshop/data-fuel/uploads`,
    updateDataFuel: (id: number) => `/api/workshop/data-fuel/${id}`,
    markUploadAsFixed: (id: string) => `/api/workshop/data-fuel/uploads/${id}/fix`,
    listIgnoredAssets: `/api/workshop/data-fuel/ignored-assets`,
    updateIgnoredAssets: (query: UpdateDataFuelAssetsIgnoredCommand) =>
      `/api/workshop/data-fuel/update-ignored`,
  },
  supplierUrls: {
    listSuppliers: (page: number, size: number, search: string) =>
      `/api/workshop/suppliers?page=${page}&size=${size}&search=${search}`,
    loadSuppliersAsOptions: () => '/api/workshop/suppliers/options',
    createSupplier: () => '/api/workshop/suppliers/create',
    checkForUniqueName: (name: string) =>
      `/api/workshop/suppliers/check-for-unique-name?name=${enc(name)}`,
    getSupplier: (id: string) => `/api/workshop/suppliers/${enc(id)}`,
    updateSupplier: (id: string) => `/api/workshop/suppliers/${enc(id)}`,
    searchSuppliers: (search: string) => `/api/workshop/suppliers/search?q=${enc(search)}`,
    findSuppliers: (ids: string[]) =>
      `/api/workshop/suppliers/find?${queryString.stringify({ ids })}`,
    createSupplierContact: (supplierId: string) =>
      `/api/workshop/suppliers/${supplierId}/contacts/create`,
    updateSupplierContact: (supplierId: string, contactId: number) =>
      `/api/workshop/suppliers/${supplierId}/contacts/${contactId}`,
    deleteSupplierContact: (supplierId: string, contactId: number) =>
      `/api/workshop/suppliers/${supplierId}/contacts/${contactId}`,
    listContactsForSupplier: (supplierId: string) =>
      `/api/workshop/suppliers/${supplierId}/contacts`,
    createSupplierNote: (supplierId: string) =>
      `/api/workshop/suppliers/${supplierId}/notes/create`,
    updateSupplierNote: (supplierId: string, noteId: number) =>
      `/api/workshop/suppliers/${supplierId}/notes/${noteId}`,
    deleteSupplierNote: (command: DeleteSupplierNoteCommand) =>
      `/api/workshop/suppliers/${command.supplierId}/notes/${command.noteId}`,
    exportSupplierNotes: () => '/api/workshop/suppliers/notes/excel',
  },
  partCategoryUrls: {
    listPartCategories: (
      query: Partial<
        Workshop.Domain.Queries.PartCategory.GetPartCategories.GetPartCategoryListQuery
      >
    ) => `/api/workshop/part-categories?${queryString.stringify(query)}`,
    listAllPartCategories: () => `/api/workshop/part-categories/all`,
    createPartCategory: () => '/api/workshop/part-categories/create',
    checkForUniqueName: (name: string) =>
      `/api/workshop/part-categories/check-for-unique-name?name=${enc(name)}`,
    getPartCategory: (id: number) => `/api/workshop/part-categories/${id}`,
    updatePartCategory: (id: number) => `/api/workshop/part-categories/${id}`,
    canDeletePartCategory: (id: number) => `/api/workshop/part-categories/${id}/can-be-deleted`,
    deletePartCategory: (id: number) => `/api/workshop/part-categories/${id}`,
    updatePartCategorySetActive: (id: number) => `/api/workshop/part-categories/${id}/set-active`,
    updatePartCategorySetInactive: (id: number) =>
      `/api/workshop/part-categories/${id}/set-inactive`,
  },
  taskSubcategoryUrls: {
    listJobTaskSubcategories: (query: Partial<ListJobTaskSubcategoriesQuery>) =>
      `/api/workshop/job-task-subcategories?${queryString.stringify(query)}`,
    createTaskSubcategory: () => '/api/workshop/job-task-subcategories/create',
    checkForUniqueName: (id: number, name: string) =>
      `/api/workshop/job-task-subcategories/check-for-unique-name?categoryId=${id}&name=${enc(
        name
      )}`,
    getTaskSubcategory: (id: number) => `/api/workshop/job-task-subcategories/${id}`,
    updateTaskSubcategory: (id: number) => `/api/workshop/job-task-subcategories/${id}`,
  },
  listAssetPartSubCategories: {
    listAssetPartSubCategories: () => `/api/workshop/assetparts/subcategories`,
  },
  listAssetPartCategories: {
    listAssetPartCategories: () => `/api/workshop/assetparts/categories`,
  },
  componentTypeUrls: {
    listComponentTypes: () => `/api/workshop/component-types`,
    updateComponentTypes: () => `/api/workshop/component-types/`,
  },
  techSpecUrls: {
    getAll: () => `/api/workshop/tech-specs`,
    getAllForRequirements: () => `/api/workshop/tech-specs/requirements`,
    updateAll: () => `/api/workshop/tech-specs`,
    getTechSpecsDropdownOptions: (query: Partial<GetTechSpecDropdownOptionsQuery>) =>
      `/api/workshop/tech-specs/dropdown-options?${queryString.stringify(query)}`,
    getEditableTechSpecDropdownOptions: (techSpecId: number) =>
      `/api/workshop/tech-specs/${techSpecId}/dropdown-options`,
    updateTechSpecDropdownOptions: (techSpecId: number) =>
      `/api/workshop/tech-specs/${techSpecId}/dropdown-options`,
    checkForUniqueTechSpecName: (techSpecName: string) =>
      `/api/workshop/tech-specs/check-for-unique-name?techSpecName=${enc(techSpecName)}`,
  },
  purchaseOrderUrls: {
    listPurchaseOrders: (
      query: Partial<
        Workshop.Domain.Queries.Purchasing.GetPurchaseOrderList.GetPurchaseOrderListQuery
      >
    ) => `/api/workshop/purchase-orders?${queryString.stringify(query)}`,
    createPurchaseOrder: () => '/api/workshop/purchase-orders/create',
    getPurchaseOrder: (id: string) => `/api/workshop/purchase-orders/${id}`,
    updatePurchaseOrder: (id: string) => `/api/workshop/purchase-orders/${id}`,
    approvePurchaseOrder: (id: string) => `/api/workshop/purchase-orders/${id}/approve`,
    reinstatePurchaseOrder: (id: string) => `/api/workshop/purchase-orders/${id}/reinstate`,
    cancelPurchaseOrder: (id: string) => `/api/workshop/purchase-orders/${id}/cancel`,
    completePurchaseOrder: (id: string) => `/api/workshop/purchase-orders/${id}/complete`,
    getPurchaseOrderInvoices: (id: string) => `/api/workshop/purchase-orders/${id}/invoices`,
    createGoodsReceived: (purchaseOrderId: string) =>
      `/api/workshop/purchase-orders/${purchaseOrderId}/goods-received/create`,
    updateGoodsReceived: (purchaseOrderId: string, goodsReceivedId: number) =>
      `/api/workshop/purchase-orders/${purchaseOrderId}/goods-received/${goodsReceivedId}`,
    completePurchaseOrderLine: (purchaseOrderId: string, purchaseOrderLineId: number) =>
      `/api/workshop/purchase-orders/${purchaseOrderId}/lines/${purchaseOrderLineId}/complete`,
    generatePdf: (purchaseOrderId: string) =>
      `/api/workshop/purchase-orders/${purchaseOrderId}/pdf`,
    createReturnedPartCredit: (purchaseOrderId: string, goodsReceivedId: number) =>
      `/api/workshop/purchase-orders/${purchaseOrderId}/goods-received/${goodsReceivedId}/returned-part-credits/create`,
    updateReturnedPartCredit: (
      purchaseOrderId: string,
      goodsReceivedId: number,
      creditNoteId: number
    ) =>
      `/api/workshop/purchase-orders/${purchaseOrderId}/goods-received/${goodsReceivedId}/returned-part-credits/${creditNoteId}`,
    deleteReturnedPartCredit: (
      purchaseOrderId: string,
      goodsReceivedId: number,
      creditNoteId: number
    ) =>
      `/api/workshop/purchase-orders/${purchaseOrderId}/goods-received/${goodsReceivedId}/returned-part-credits/${creditNoteId}`,
    generatePurchaseOrderBudgetReport: (
      query: Partial<
        Workshop.Domain.Queries.Purchasing.GeneratePurchaseOrderBudgetReportExcel.GeneratePurchaseOrderBudgetReportExcelQuery
      >
    ) => `/api/workshop/purchase-orders/budget-report/excel?${queryString.stringify(query)}`,
    listPurchaseOrdersForDropdown: (query: Partial<ListPurchaseOrdersForDropdownQuery>) =>
      `/api/workshop/purchase-orders/dropdown?${queryString.stringify(query)}`,
    findPurchaseOrders: (query: Partial<FindPurchaseOrdersQuery>) =>
      `/api/workshop/purchase-orders/find?${queryString.stringify(query)}`,
    listPurchaseTransactions: (
      query: Partial<
        Workshop.Domain.Queries.Purchasing.GetPurchaseTransactionsList.GetPurchaseTransactionsListQuery
      >
    ) => `/api/workshop/purchase-orders/transactions?${queryString.stringify(query)}`,
    exportPurchaseTransactionsToExcel: (
      query: Partial<
        Workshop.Domain.Queries.Purchasing.ExportPurchaseTransactions.ExportPurchaseTransactionsToExcelQuery
      >
    ) => `/api/workshop/purchase-transactions/excel?${queryString.stringify(query)}`,
    markGoodsReceivedAsExported: (purchaseOrderId: string, goodsReceivedId: number) =>
      `/api/workshop/purchase-orders/${purchaseOrderId}/goods-received/${goodsReceivedId}/mark-as-exported`,
    removeExportedIndicatorFromGoodsReceived: (purchaseOrderId: string, goodsReceivedId: number) =>
      `/api/workshop/purchase-orders/${purchaseOrderId}/goods-received/${goodsReceivedId}/remove-exported-indicator`,
    markCreditNoteAsExported: (
      purchaseOrderId: string,
      goodsReceivedId: number,
      creditNoteId: number
    ) =>
      `/api/workshop/purchase-orders/${purchaseOrderId}/goods-received/${goodsReceivedId}/returned-part-credits/${creditNoteId}/mark-as-exported`,
    removeExportedIndicatorFromCreditNote: (
      purchaseOrderId: string,
      goodsReceivedId: number,
      creditNoteId: number
    ) =>
      `/api/workshop/purchase-orders/${purchaseOrderId}/goods-received/${goodsReceivedId}/returned-part-credits/${creditNoteId}/remove-exported-indicator`,
  },
  ownerUrls: {
    listOwners: (query: Partial<Workshop.Domain.Queries.Owners.GetOwners.ListOwnersQuery>) =>
      `/api/workshop/owners?${queryString.stringify(query)}`,
    listAllOwners: () => `/api/workshop/owners/all`,
    createOwner: () => '/api/workshop/owners/create',
    checkForUniqueName: (name: string) =>
      `/api/workshop/owners/check-for-unique-name?name=${enc(name)}`,
    getOwner: (id: string) => `/api/workshop/owners/${enc(id)}`,
    canDeleteOwner: (id: string) => `/api/workshop/owners/${enc(id)}/can-be-deleted`,
    updateOwner: (id: string) => `/api/workshop/owners/${enc(id)}`,
    deleteOwner: (ownerId: string) => `/api/workshop/owners/${ownerId}`,
    createOwnerContact: (ownerId: string) => `/api/workshop/owners/${ownerId}/contacts/create`,
    updateOwnerContact: (ownerId: string, contactId: number) =>
      `/api/workshop/owners/${ownerId}/contacts/${contactId}`,
    listContactsForOwner: (ownerId: string) => `/api/workshop/owners/${ownerId}/contacts`,
    createOwnerNote: (ownerId: string) => `/api/workshop/owners/${ownerId}/notes/create`,
    updateOwnerNote: (ownerId: string, noteId: number) =>
      `/api/workshop/owners/${ownerId}/notes/${noteId}`,
    updateOwnerSetActive: (ownerId: string) => `/api/workshop/owners/${ownerId}/set-active`,
    updateOwnerSetInactive: (ownerId: string) => `/api/workshop/owners/${ownerId}/set-inactive`,
  },
  shiftTemplateUrls: {
    createShiftTemplate: () => '/api/workshop/shift-templates/create',
    checkForUniqueShiftTemplateName: (name: string) =>
      `/api/workshop/shift-templates/check-for-unique-name?name=${enc(name)}`,
    getShiftTemplate: (id: string) => `/api/workshop/shift-templates/${id}`,
    deleteShiftTemplate: (id: string) => `/api/workshop/shift-templates/${id}`,
    updateShiftTemplate: (id: string) => `/api/workshop/shift-templates/${id}`,
    listShiftTemplates: (query: Partial<ListShiftTemplatesQuery>) =>
      `/api/workshop/shift-templates?${queryString.stringify(query)}`,
  },
  rosterTemplateUrls: {
    createRosterTemplate: () => '/api/workshop/roster-templates/create',
    checkForUniqueRosterTemplateName: (name: string) =>
      `/api/workshop/roster-templates/check-for-unique-name?name=${enc(name)}`,
    getRosterTemplate: (id: string) => `/api/workshop/roster-templates/${id}`,
    deleteRosterTemplate: (id: string) => `/api/workshop/roster-templates/${id}`,
    updateRosterTemplate: (id: string) => `/api/workshop/roster-templates/${id}`,
    listRosterTemplates: (query: Partial<ListRosterTemplatesQuery>) =>
      `/api/workshop/roster-templates?${queryString.stringify(query)}`,
  },
  shiftUrls: {
    createShift: () => '/api/workshop/shifts/create',
    getShift: (id: string) => `/api/workshop/shifts/${id}`,
    deleteShift: (id: string) => `/api/workshop/shifts/${id}`,
    updateShift: (id: string) => `/api/workshop/shifts/${id}`,
    listShifts: (query: Partial<ListShiftsQuery>) =>
      `/api/workshop/shifts?${queryString.stringify(query)}`,
  },
  rosterUrls: {
    createRoster: () => '/api/workshop/rosters/create',
    getRoster: (id: string) => `/api/workshop/rosters/${id}`,
    getRosterChanges: (id: string) => `/api/workshop/rosters/${id}/changes`,
    deleteRoster: (id: string) => `/api/workshop/rosters/${id}`,
    updateRoster: (id: string) => `/api/workshop/rosters/${id}`,
    listRosters: (query: Partial<ListRostersQuery>) =>
      `/api/workshop/rosters?${queryString.stringify(query)}`,
    autocompleteListRosters: (query: Partial<AutocompleteListRostersQuery>) =>
      `/api/workshop/rosters/autocomplete?${queryString.stringify(query)}`,
    getWeekWithoutRoster: (query: Partial<GetWeekWithoutRosterQuery>) =>
      `/api/workshop/rosters/week-without-roster?${queryString.stringify(query)}`,
    listRosterNames: (query: ListRosterNamesQuery) =>
      `/api/workshop/rosters/names?${queryString.stringify(query)}`,
    exportRostersToExcel: (query: Partial<ListRostersQuery>) =>
      `/api/workshop/rosters/excel?${queryString.stringify(query)}`,
  },
  conflictUrls: {
    acceptConflict: (conflictId: string) => `/api/workshop/conflicts/${conflictId}/accept`,
    cancelAcceptanceConflict: (conflictId: string) =>
      `/api/workshop/conflicts/${conflictId}/cancel-acceptance`,
  },
  depotUrls: {
    getDefaultDepot: () => '/api/workshop/default-depot',
    getWorkshopDepots: () => `/api/workshop/depots`,
  },
  machineryScheduleUrls: {
    loadMachineryScheduleTemplate: () => '/api/workshop/machinery-schedule-template',
    updateMachineryScheduleTemplate: () => '/api/workshop/machinery-schedule-template',
  },
};

export const assetGroupUrls = {
  createAssetGroup: () => '/api/workshop/asset-groups/create',
  checkForUniqueName: (name: string) =>
    `/api/workshop/asset-groups/check-for-unique-name?assetGroupName=${enc(name)}`,
  loadAssetGroup: (id: string) => `/api/workshop/asset-groups/${id}`,
  loadAssetGroupForAsset: (assetId: string) => `/api/workshop/asset-groups/asset/${assetId}`,
  updateAssetGroup: (id: string) => `/api/workshop/asset-groups/${id}`,
  loadAssetGroupFullServiceCycle: (assetGroupId: string) =>
    `/api/workshop/asset-groups/${assetGroupId}/full-service-cycle`,
  getAssetGroupUsage: (id: string) => `/api/workshop/asset-groups/${id}/current-usage`,
  deactivateAssetGroup: (id: string) => `/api/workshop/asset-groups/${id}/set-inactive`,
  reactivateAssetGroup: (id: string) => `/api/workshop/asset-groups/${id}/set-active`,
  deleteAssetGroup: (id: string) => `/api/workshop/asset-groups/${id}`,
};

export const activityLogUrls = {
  getActivityLogForDefect: (defectId: string) =>
    `/api/workshop/activity-logs?aggregateType=Defect&aggregateId=${enc(
      defectId
    )}&includeSourceAggregateMatches=true`,
  getActivityLogForPurchaseOrder: (purchaseOrderId: string) =>
    `/api/workshop/activity-logs?aggregateType=PurchaseOrder&aggregateId=${enc(purchaseOrderId)}`,
  getActivityLogForJobTask: (jobTaskId: string) =>
    `/api/workshop/activity-logs?aggregateType=JobTask&aggregateId=${enc(jobTaskId)}`,
};

export const defectUrls = {
  createDefect: () => '/api/workshop/defects/create',
  markOutOfServiceDefect: () => '/api/workshop/defects/mark-out-of-service',
  getDefectStatuses: () => '/api/workshop/defects/status',
  listDefects: (query: Partial<Workshop.Domain.Queries.Defect.ListDefects.ListDefectsQuery>) =>
    `/api/workshop/defects/search?${queryString.stringify(query)}`,
  getDefect: (defectId: string) => `/api/workshop/defects/${enc(defectId)}`,
  getTasksForDefect: (defectId: string) => `/api/workshop/defects/${defectId}/tasks`,
  acknowledgeDefect: (defectId: string) => `/api/workshop/defect/${enc(defectId)}/acknowledge`,
  exportDefectsToExcel: (
    query: Partial<Workshop.Domain.Queries.Defect.ListDefects.ListDefectsQuery>
  ) => `/api/workshop/defects/search/excel?${queryString.stringify(query)}`,
  closeDefectWithoutFixing: (defectId: string) =>
    `/api/workshop/defect/${enc(defectId)}/close-without-fixing`,
};

export const engineUrls = {
  listEngines: () => '/api/workshop/engines',
  listEnginesDetails: () => '/api/workshop/engines/details',
  loadServiceTypes: () => '/api/workshop/service-types',
  createEngine: () => '/api/workshop/engines/create',
  checkForUniqueModel: (model: string) =>
    `/api/workshop/engines/check-for-unique-model?model=${enc(model)}`,
  getEngine: (id: string) => `/api/workshop/engines/${id}`,
  updateEngine: (id: string) => `/api/workshop/engines/${id}`,
  updateEngineSetActive: (id: string) => `/api/workshop/engines/${id}/set-active`,
  updateEngineSetInactive: (id: string) => `/api/workshop/engines/${id}/set-inactive`,
  deleteEngine: (id: string) => `/api/workshop/engines/${id}`,
  getCurrentUsage: (id: string) => `/api/workshop/engines/${id}/current-usage`,
};

export const checklistUrls = {
  listChecklists: (includeInactive: boolean) =>
    `/api/workshop/checklists?includeInactive=${includeInactive}`,
  getChecklist: (id: string) => `/api/workshop/checklists/${enc(id)}`,
  getChecklistAssetGroups: (id: string) => `/api/workshop/checklists/${enc(id)}/asset-groups`,
  createChecklist: () => '/api/workshop/checklists/create',
  updateChecklist: (id: string) => `/api/workshop/checklists/${enc(id)}`,
  checkForUniqueCheckListName: (checklistName: string) =>
    `/api/workshop/checklists/check-for-unique-name?checklistName=${enc(checklistName)}`,
  getSubchecklists: () => '/api/workshop/checklists/subchecklists',
  getSubchecklistItemsForASubchecklist: (subchecklistId: string) =>
    `/api/workshop/checklists/subchecklists/${subchecklistId}/items`,
  checkForUniqueSubchecklistName: (subchecklistName: string) =>
    `/api/workshop/checklists/subchecklists/check-for-unique-name?subchecklistName=${enc(
      subchecklistName
    )}`,
  searchSubchecklistItems: (searchText: string) =>
    `/api/workshop/checklists/subchecklists/items/search?searchtext=${enc(searchText)}`,
  updateChecklistSetActive: (id: string) => `/api/workshop/checklists/${id}/set-active`,
  updateChecklistSetInactive: (id: string) => `/api/workshop/checklists/${id}/set-inactive`,
  deleteChecklist: (id: string) => `/api/workshop/checklists/${id}`,
  getCurrentUsage: (id: string) => `/api/workshop/checklists/${id}/current-usage`,
};

export const assetGroupsUrls = {
  listAssetGroups: (includeInactiveAssetGroups: boolean) =>
    `/api/workshop/asset-groups?includeInactive=${includeInactiveAssetGroups}`,
};

export const labourPricesUrls = {
  getLabourPrices: () => '/api/workshop/labour-prices',
  updateLabourPrice: (command: Workshop.Domain.Commands.LabourPrice.UpdateLabourPriceCommand) =>
    `/api/workshop/labour-prices/${command.id}`,
  deleteLabourPrice: (id: string) => `/api/workshop/labour-prices/${id}`,
  createLabourPrice: () => '/api/workshop/labour-prices/create',
};

export const partsUrls = {
  searchPartsGroups: (search: string) => `/api/workshop/parts-groups/search?q=${enc(search)}`,
  partsGroupNameExists: (name: string) =>
    `/api/workshop/parts-groups/name-exists?name=${enc(name)}`,
  createPartsGroup: () => '/api/workshop/parts-groups/create',
  listPartsGroups: (includeInactivePartsGroups: boolean) =>
    `/api/workshop/parts-groups?includeInactive=${includeInactivePartsGroups}`,
  getPartsGroupParts: (partsGroupId: string) => `/api/workshop/parts-groups/${partsGroupId}/parts`,
  getPartsGroup: (id: string) => `/api/workshop/parts-groups/${enc(id)}`,
  getPartsGroupEngines: (id: string) => `/api/workshop/parts-groups/${enc(id)}/engines`,
  updatePartsGroup: (id: string) => `/api/workshop/parts-groups/${enc(id)}`,
  updatePartsGroupSetActive: (id: string) => `/api/workshop/parts-groups/${id}/set-active`,
  updatePartsGroupSetInactive: (id: string) => `/api/workshop/parts-groups/${id}/set-inactive`,
  deletePartsGroup: (id: string) => `/api/workshop/parts-groups/${id}`,
  getPartsGroupUsage: (id: string) => `/api/workshop/parts-groups/${id}/current-usage`,
  getCurrentUsage: (id: string) => `/api/workshop/engines/${id}/current-usage`,
  getPartsUsed: (start: DateTime, end: DateTime) =>
    `/api/workshop/parts/transactions?startDate=${enc(start.toISODate())}&endDate=${enc(
      end.toISODate()
    )}`,
  createPart: () => '/api/workshop/parts/create',
  updatePart: (partId: string) => `/api/workshop/parts/${partId}`,
  updatePartSetActive: (partId: string) => `/api/workshop/parts/${partId}/set-active`,
  updatePartSetInactive: (partId: string) => `/api/workshop/parts/${partId}/set-inactive`,
  getPart: (partId: string) => `/api/workshop/parts/${partId}`,
  getPartUsage: (partId: string) => `/api/workshop/parts/${partId}/current-usage`,
  checkForUniquePartNumber: (partNumber: string) =>
    `/api/workshop/parts/check-for-unique-partnumber?number=${enc(partNumber)}`,
  listParts: (query: Partial<Workshop.Domain.Queries.Parts.ListParts.ListPartsQuery>) =>
    `/api/workshop/parts?${queryString.stringify(query)}`,
  listPartsForDropdown: (
    query: Partial<Workshop.Domain.Queries.Parts.ListPartsForDropdown.ListPartsForDropdownQuery>
  ) => `/api/workshop/parts-dropdown?${queryString.stringify(query)}`,
  findParts: (query: Partial<Workshop.Domain.Queries.Parts.GetParts.GetPartsQuery>) =>
    `/api/workshop/parts/find?${queryString.stringify(query)}`,
  listStocktakeParts: (
    query: Partial<Workshop.Domain.Queries.Parts.GetStocktakePartsList.ListStocktakePartsQuery>
  ) => `/api/workshop/stocktake/parts?${queryString.stringify(query)}`,

  createStockAdjustment: (partId: string) =>
    `/api/workshop/parts/${partId}/stock/create-adjustment`,
  recordStocktake: () => `/api/workshop/stocktake/create`,
  generateStocktakePdf: (
    query: Partial<Workshop.Domain.Queries.Parts.GetStocktakePartsList.ListStocktakePartsQuery>
  ) => `/api/workshop/stocktake/parts/pdf?${queryString.stringify(query)}`,
  generateStocktakeExcel: (
    query: Partial<Workshop.Domain.Queries.Parts.GetStocktakePartsList.ListStocktakePartsQuery>
  ) => `/api/workshop/stocktake/parts/excel?${queryString.stringify(query)}`,
  generateStockOnHandExcel: (
    query: Partial<
      Workshop.Domain.Queries.Parts.GenerateStockOnHandExcel.GenerateStockOnHandExcelQuery
    >
  ) => `/api/workshop/parts/stock-on-hand/excel?${queryString.stringify(query)}`,
  deletePart: (partId: string) => `/api/workshop/parts/${partId}`,
  exportPartsGroupToPdf: (
    query: Partial<Workshop.Domain.Queries.Parts.GeneratePartsGroupPdf.ExportPartsGroupToPdfQuery>
  ) => `/api/workshop/parts-group/${query.partsGroupId}/export-pdf?${queryString.stringify(query)}`,
  loadChecklists: (partId: string) => `/api/workshop/parts/${partId}/checklists`,
};

export const partTransactionsUrls = {
  listPartTransactions: (
    query: Partial<Workshop.Domain.Queries.GetPartTransactions.GetPartTransactionsQuery>
  ) => `/api/workshop/parts/transactions?${queryString.stringify(query)}`,
  downloadUsedPartsExcel: (
    query: Partial<Workshop.Domain.Queries.GetPartTransactions.GetPartTransactionsQuery>
  ) => `/api/workshop/parts/used/excel?${queryString.stringify(query)}`,
  downloadUsedPartsPdf: (query: Partial<GetUsedPartsPdfReportQuery>) =>
    `/api/workshop/parts/used/pdf?${queryString.stringify(query)}`,
};

export const dailyNotesUrls = {
  getDailyNotes: (day: DateTime, depotId: number) =>
    `/api/workshop/daily-notes/${enc(day.toISODate())}/${depotId}`,
  createDailyNote: () => `/api/workshop/daily-notes`,
  updateDailyNote: (id: string) => `/api/workshop/daily-notes/${enc(id)}`,
};

export const accountingUrls = {
  dashboardUrls: {
    loadDashboard: '/api/accounting/dashboard',
  },
};

export const complianceUrls = {
  dashboardUrls: {
    loadDashboard: '/api/compliance/dashboard',
  },
  reportableEventUrls: {
    listReportableEvents: (
      query: Partial<Operations.Domain.Queries.ListReportableEvents.ListReportableEventsQuery>
    ) => `/api/compliance/reportable-events?${queryString.stringify(query)}`,
    loadCategoriesForContract: (id: string) =>
      `/api/compliance/reportable-events/categories/get-for-contract?reportableEventContractId=${id}`,
    loadAllContracts: `/api/compliance/reportable-events/contracts/all`,
    createReportableEvent: `/api/compliance/reportable-events/create`,
    updateReportableEvent: `/api/compliance/reportable-events/update`,
    updateReportableEventVehicleInformation: `/api/compliance/reportable-events/vehicle-information/update`,
    updateReportableEventInternalInformation: `/api/compliance/reportable-events/internal-information/update`,
    viewReportableEvent: (id: string) => `/api/compliance/reportable-events/${id}`,
  },
  fatigueUrls: {
    getPaidHoursInPayPeriodForDay: (date: DateTime) =>
      `/api/compliance/fatigue/paid-hours-in-pay-period?day=${date.toISODate()}`,
    getFatigueDetails: (
      query: Partial<Operations.Domain.Queries.GetFatigueDetails.GetFatigueDetailsQuery>
    ) => `/api/compliance/fatigue/details?${queryString.stringify(query)}`,
  },
  fatigueNoteUrls: {
    getFatigueNote: (id: string) => `/api/compliance/fatigue-note/${id}`,
    deleteFatigueNote: (id: string) => `/api/compliance/fatigue-note/${id}`,
    createFatigueNote: () => `/api/compliance/fatigue-note`,
    updateFatigueNote: () => `/api/compliance/fatigue-note`,
  },
  fatigueBreachEventUrls: {
    loadFatigueBreachEvents: (
      query: Partial<Operations.Domain.Queries.FatigueBreachEvent.ListFatigueBreachEventsQuery>
    ) => `/api/compliance/fatigue-breach-events?${queryString.stringify(query)}`,
    loadFatigueBreachEvent: (fatigueBreachEventId: string) =>
      `/api/compliance/fatigue-breach-events/${fatigueBreachEventId}`,
    verifyFatigueBreachEvent: (fatigueBreachEventId: string) =>
      `/api/compliance/fatigue-breach-events/${fatigueBreachEventId}/verify`,
    updateFatigueBreachEvent: (fatigueBreachEventId: string) =>
      `/api/compliance/fatigue-breach-events/${fatigueBreachEventId}`,
    closeFatigueBreachEvent: (fatigueBreachEventId: string) =>
      `/api/compliance/fatigue-breach-events/${fatigueBreachEventId}/close`,
    invalidateFatigueBreachEvent: (fatigueBreachEventId: string) =>
      `/api/compliance/fatigue-breach-events/${fatigueBreachEventId}/invalidate`,
    reactivateFatigueBreachEvent: (fatigueBreachEventId: string) =>
      `/api/compliance/fatigue-breach-events/${fatigueBreachEventId}/reactivate`,
    loadFatigueBreachEventActivityLogs: (fatigueBreachEventId: string) =>
      `/api/operations/activity-logs?aggregateType=FatigueBreachEvent&aggregateId=${fatigueBreachEventId}&includeSourceAggregateMatches=true`,
    exportFatigueBreachEventToPdf: (fatigueBreachEventId: string) =>
      `/api/compliance/fatigue-breach-events/${fatigueBreachEventId}/pdf`,
    exportAllFatigueBreachEventsToExcel: (
      query: Partial<Operations.Domain.Queries.FatigueBreachEvent.ListFatigueBreachEventsQuery>
    ) => `/api/compliance/fatigue-breach-events/all/excel?${queryString.stringify(query)}`,
  },
};

export const operationsUrls = {
  dashboardUrls: {
    loadDashboard: '/api/operations/dashboard',
  },

  salesDashboardUrls: {
    loadDashboard: '/api/operations/sales-dashboard',
  },

  allocationsUrls: {
    getVehicleTypeAllocationData: (query: GetVehicleTypeAllocationDataQuery) =>
      `/api/operations/vehicle-types/get-vehicle-type-allocation-data?${queryString.stringify(
        query
      )}`,
  },

  assetsWithUnknownKmsUrls: {
    loadAssetsWithUnknownKms: (
      query: Partial<
        Operations.Domain.Queries.ListAssetJobProgressWithUnknownKms.ListAssetJobProgressWithUnknownKmsQuery
      >
    ) => `/api/operations/assets-unknown-kms/list?${queryString.stringify(query)}`,
    dismissUnknownKms: (id: string) => `/api/operations/assets-unknown-kms/${id}/dismiss`,
  },

  customerUrls: {
    createCustomer: () => '/api/operations/customers/create',
    createContact: (customerId: string) =>
      `/api/operations/customers/${customerId}/contacts/create`,
    editContact: (customerId: string, contactId: number) =>
      `/api/operations/customers/${customerId}/contacts/${contactId}`,
    deleteContact: (customerId: string, contactId: number) =>
      `/api/operations/customers/${customerId}/contacts/${contactId}`,
    deleteCustomer: (customerId: string) => `/api/operations/customers/${customerId}`,
    checkForUniqueName: (name: string) =>
      `/api/operations/customers/check-for-unique-name?name=${enc(name)}`,
    listCustomer: (page: number, size: number, search: string) =>
      `/api/operations/customers?page=${page}&size=${size}&search=${search}`,
    listCustomers: () => '/api/operations/customers/all',
    viewCustomer: (customerId: string) => `/api/operations/customers/${customerId}`,
    editCustomer: (customerId: string) => `/api/operations/customers/${customerId}`,
    searchCustomers: (search: string) => `/api/operations/customers/search?q=${enc(search)}`,
    findCustomers: (ids: string[]) =>
      `/api/operations/customers/find?${queryString.stringify({ ids })}`,
    searchContactsForCustomer: (customerId: string, search: string) =>
      `/api/operations/customers/${customerId}/contacts/search?search=${enc(search)}`,
    findContactsForCustomer: (customerId: string, ids: number[]) =>
      `/api/operations/customers/${customerId}/contacts/find?${queryString.stringify({
        ids,
      })}`,
    generateBookingsAndQuotesReport: (
      query: Operations.Domain.Queries.GenerateUpcomingBookingsReportForCustomer.GenerateUpcomingBookingsReportForCustomerQuery
    ) =>
      `/api/operations/customers/${
        query.customerId
      }/generate-quotes-bookings?${queryString.stringify(query)}`,
    createNote: (customerId: string) => `/api/operations/customers/${customerId}/notes/create`,
    editNote: (customerId: string, noteId: number) =>
      `/api/operations/customers/${customerId}/notes/${noteId}`,
    deleteNote: (customerId: string, noteId: number) =>
      `/api/operations/customers/${customerId}/notes/${noteId}`,
  },
  vehicleTypesUrls: {
    loadVehicleTypes: () => '/api/operations/vehicle-types',
  },
  quoteUrls: {
    loadQuoteTypes: () => '/api/operations/quotes/quote-types',
    loadBookingStatuses: () => '/api/operations/bookings/status',
    createQuote: () => '/api/operations/quotes/create',
    generatePdf: (quoteId: string) => `/api/operations/quotes/${quoteId}/generate`,
    getLatestPdf: (quoteId: string) => `/api/operations/quotes/${quoteId}/pdf`,
    loadQuotePdfs: (quoteId: string) => `/api/operations/quotes/${quoteId}/list-pdfs`,
    getPdf: (quoteId: string, quotePdfId: number) =>
      `/api/operations/quotes/${quoteId}/pdf/${quotePdfId}`,
    viewQuote: (quoteId: string) => `/api/operations/quotes/${quoteId}`,
    listAllQuotes: (query: Partial<Operations.Domain.Queries.ListAllQuotes.ListAllQuotesQuery>) =>
      `/api/operations/quotes/all?${queryString.stringify(query)}`,
    exportAllQuotesToExcel: (
      query: Partial<Operations.Domain.Queries.ListAllQuotes.ListAllQuotesQuery>
    ) => `/api/operations/quotes/all/excel?${queryString.stringify(query)}`,
    listBookings: (query: Partial<Operations.Domain.Queries.ListBookings.ListBookingQuery>) =>
      `/api/operations/bookings?${queryString.stringify(query)}`,
    updateQuote: (quoteId: string) => `/api/operations/quotes/${quoteId}`,
    declineQuote: (quoteId: string) => `/api/operations/quotes/${quoteId}/decline`,
    bookQuote: (quoteId: string) => `/api/operations/quotes/${quoteId}/book`,
    verifyBooking: (quoteId: string) => `/api/operations/quotes/${quoteId}/verify`,
    cancelBooking: (quoteId: string) => `/api/operations/quotes/${quoteId}/cancel`,
    duplicateQuote: (quoteId: string) => `/api/operations/quotes/${quoteId}/duplicate`,
    listBookingsForAccounts: (
      query: Partial<Operations.Domain.Queries.ListBookingsForAccounts.ListBookingsForAccountsQuery>
    ) => `/api/operations/bookings/search?${queryString.stringify(query)}`,
    loadQuotesForCustomer: (customerId: string) => `/api/operations/quotes/customer/${customerId}`,
    loadBookingsForCustomer: (customerId: string) =>
      `/api/operations/bookings/customer/${customerId}`,
    viewBookingForOps: (quoteId: string) => `/api/operations/bookings-for-ops/${quoteId}`,
    findQuotes: (quoteIds: string[]) =>
      `/api/operations/quotes/find?${queryString.stringify({ quoteIds })}`,
    verifyJobs: (quoteId: string) => `/api/operations/quotes/${quoteId}/verify-jobs`,
    getNeedsVerificationStartDate: (quoteId: string) =>
      `/api/operations/quotes/${quoteId}/needs-verification-start`,
    regenerateJobs: (quoteId: string) => `/api/operations/quotes/${quoteId}/regenerate-jobs`,
    loadJobsForCancellingQuote: (quoteId: string, forceJobsToCancelInProgress: boolean) =>
      `/api/operations/quotes/${quoteId}/jobs-for-cancelling?forceJobsToCancelInProgress=${forceJobsToCancelInProgress}`,
    loadRailBookingsForCustomer: (customerId: string) =>
      `/api/operations/rail-bookings/customer/${customerId}`,
  },
  boardingPointUrls: {
    searchBoardingPoints: (search: string) =>
      `/api/operations/boarding-points/search?q=${enc(search)}`,
    createBoardingPoint: () => '/api/operations/boarding-points/create',
    checkForUniqueName: (name: string) =>
      `/api/operations/boarding-points/check-for-unique-name?name=${enc(name)}`,
    listBoardingPoints: (page: number, size: number, search: string) =>
      `/api/operations/boarding-points?page=${page}&size=${size}&search=${search}`,
    updateBoardingPoint: (boardingPointId: string) =>
      `/api/operations/boarding-points/${boardingPointId}`,
    viewBoardingPoint: (boardingPointId: string) =>
      `/api/operations/boarding-points/${boardingPointId}`,
    findBoardingPoints: (ids: string[]) =>
      `/api/operations/boarding-points/find?${queryString.stringify({ ids })}`,
    deleteBoardingPoint: (boardingPointId: string) =>
      `/api/operations/boarding-points/${boardingPointId}`,
    loadStates: () => '/api/operations/states',
  },
  holidayUrls: {
    listHolidays: (query: Partial<Operations.Domain.Queries.ListHolidays.ListHolidaysQuery>) =>
      `/api/operations/holidays?${queryString.stringify(query)}`,
    loadHolidayTypes: `/api/operations/holiday-types`,
    createHoliday: `/api/operations/holidays/create`,
    updateHoliday: (holidayId: string) => `/api/operations/holidays/${holidayId}`,
    viewHoliday: (holidayId: string) => `/api/operations/holidays/${holidayId}`,
    deleteHoliday: (holidayId: string) => `/api/operations/holidays/${holidayId}`,
  },
  operationsDiaryUrls: {
    listOperationsDiaryEntries: (
      query: Partial<
        Operations.Domain.Queries.ListOperationsDiaryEntries.ListOperationsDiaryEntriesQuery
      >
    ) => `/api/operations/daily-event-log?${queryString.stringify(query)}`,
    loadAllOperationsDiaryEntrySubjects: `/api/operations/daily-event-log/subjects/all`,
    updateAllOperationsDiaryEntrySubjects: `/api/operations/daily-event-log/subjects`,
    createOperationsDiaryEntry: `/api/operations/daily-event-log/create`,
    updateOperationsDiaryEntry: (id: string) => `/api/operations/daily-event-log/${id}`,
    viewOperationsDiaryEntry: (id: string) => `/api/operations/daily-event-log/${id}`,
    deleteOperationsDiaryEntry: (id: string) => `/api/operations/daily-event-log/${id}`,
    acknowledgeOperationsDiaryEntry: (id: string) =>
      `/api/operations/daily-event-log/${id}/acknowledge`,
  },
  shiftUrls: {
    createShift: () => '/api/operations/shifts/create',
    checkForUniqueShiftName: (shiftName: string) =>
      `/api/operations/shifts/check-for-unique-shift-name?shiftName=${enc(shiftName)}`,
    updateShift: (shiftId: string) => `/api/operations/shifts/${shiftId}`,
    viewShift: (shiftId: string) => `/api/operations/shifts/${shiftId}`,
    deleteShift: (shiftId: string) => `/api/operations/shifts/${shiftId}`,
    loadShiftTypes: () => '/api/operations/shift-types',
    listShifts: (query?: Operations.Domain.Queries.ListShifts.ListShiftsQuery) =>
      `/api/operations/shifts${query ? '?' + queryString.stringify(query) : ''}`,
    getShiftsByContract: (id: string) => `/api/operations/charter-contracts/${id}/shifts`,
  },
  depotUrls: {
    loadDepots: () => '/api/operations/depots',
  },
  rosterUrls: {
    createRoster: () => '/api/operations/rosters/create',
    checkForUniqueRosterNumber: (rosterNumber: string) =>
      `/api/operations/rosters/check-for-unique-roster-number?rosterNumber=${enc(rosterNumber)}`,
    updateRoster: (rosterId: string) => `/api/operations/rosters/${rosterId}`,
    viewRoster: (rosterId: string) => `/api/operations/rosters/${rosterId}`,
    deleteRoster: (rosterId: string) => `/api/operations/rosters/${rosterId}`,
    listRosters: () => `/api/operations/rosters`,
    createRosterGroup: () => '/api/operations/roster-groups/create',
    updateRosterGroup: (rosterGroupId: string) => `/api/operations/roster-groups/${rosterGroupId}`,
    viewRosterGroup: (rosterGroupId: string) => `/api/operations/roster-groups/${rosterGroupId}`,
    deleteRosterGroup: (rosterGroupId: string) => `/api/operations/roster-groups/${rosterGroupId}`,
    getRosterGroups: () => '/api/operations/roster-groups/all',
    checkForUniqueRosterGroup: (name: string) =>
      `/api/operations/roster-groups/check-name?name=${enc(name)}`,
    listRotatingRosterStaffMembers: (startDate: string) =>
      `/api/operations/roster-groups/staff-members?startDate=${startDate}`,
  },
  railTemplateUrls: {
    listRailTemplateShifts: () => `/api/operations/rail-templates/shifts`,
    listRailTemplates: (
      query: Partial<Operations.Domain.Queries.ListRailTemplates.ListRailTemplatesQuery>
    ) => `/api/operations/rail-templates?${queryString.stringify(query)}`,
    createRailTemplate: () => '/api/operations/rail-templates/create',
    checkForUnqiueRailTemplateName: (name: string) =>
      `/api/operations/rail-templates/check-for-unique-rail-template-name?name=${enc(name)}`,
    updateRailTemplate: (railTemplateId: string) =>
      `/api/operations/rail-templates/${railTemplateId}`,
    viewRailTemplate: (railTemplateId: string) =>
      `/api/operations/rail-templates/${railTemplateId}`,
    generateShiftAttachmentId: () => `/api/operations/rail-templates/attachments`,
    downloadAttachment: (attachmentId: string) =>
      `/api/operations/rail-templates/attachments/${attachmentId}`,
    deleteRailTemplate: (railTemplateId: string) =>
      `/api/operations/rail-templates/${railTemplateId}`,
    restoreRailTemplate: (railTemplateId: string) =>
      `/api/operations/rail-templates/${railTemplateId}/restore`,
  },
  contractCharterBookingUrls: {
    listContractCharterBookings: (
      query: Partial<
        Operations.Domain.Queries.ContractCharterBooking.ListContractCharterBookings.ListContractCharterBookingsQuery
      >
    ) => `/api/operations/contract-charter-bookings?${queryString.stringify(query)}`,
    exportToExcel: (
      query: Partial<
        Operations.Domain.Queries.ContractCharterBooking.ExportContractCharterBookingToExcel.ExportContractCharterBookingToExcelQuery
      >
    ) => `/api/operations/contract-charter-bookings/excel?${queryString.stringify(query)}`,
    getContractCharterBooking: (
      query: Operations.Domain.Queries.ContractCharterBooking.GetContractCharterBooking.GetContractCharterBookingQuery
    ) => `/api/operations/contract-charter-bookings/${query.contractCharterBookingId}`,
    completeContractCharterBooking: (
      command: Operations.Domain.Commands.ContractCharterBooking.CompleteContractCharterBooking.CompleteContractCharterBookingCommand
    ) => `/api/operations/contract-charter-bookings/${command.contractCharterBookingId}/complete`,
    createContractCharterBooking: () => '/api/operations/contract-charter-bookings',
    updateContractCharterBooking: (
      command: Operations.Domain.Commands.ContractCharterBooking.UpdateContractCharterBooking.UpdateContractCharterBookingCommand
    ) => `/api/operations/contract-charter-bookings/${command.contractCharterBookingId}`,
    getAvailableJobs: (
      query: Operations.Domain.Queries.ContractCharterBooking.GetContractCharterBookingAvailableJobs.GetContractCharterBookingAvailableJobsQuery
    ) => `/api/operations/contract-charter-bookings/available-jobs?${queryString.stringify(query)}`,
  },
  railBookingUrls: {
    loadRailBookingStatuses: () => `/api/operations/rail-bookings/status`,
    listRailBookingsForAccounts: (
      query: Partial<
        Operations.Domain.Queries.ListRailBookingsForAccounts.ListRailBookingsForAccountsQuery
      >
    ) => `/api/operations/rail-bookings/search?${queryString.stringify(query)}`,
    listRailBookings: (
      query: Partial<Operations.Domain.Queries.ListRailBookings.ListRailBookingsQuery>
    ) => `/api/operations/rail-bookings?${queryString.stringify(query)}`,
    createRailBooking: () => '/api/operations/rail-bookings/create',
    viewRailBooking: (railBookingId: string) => `/api/operations/rail-bookings/${railBookingId}`,
    updateRailBooking: (railBookingId: string) => `/api/operations/rail-bookings/${railBookingId}`,
    deleteRailBooking: (railBookingId: string) => `/api/operations/rail-bookings/${railBookingId}`,
    cancelRailBooking: (railBookingId: string) =>
      `/api/operations/rail-bookings/${railBookingId}/cancel`,
    completeRailBooking: (railBookingId: string) =>
      `/api/operations/rail-bookings/${railBookingId}/complete`,
    getRailBookingsForDropdown: () => `/api/operations/rail-bookings-dropdown`,
    getExistingJobsForRailBookingDropdown: () => `/api/operations/rail-bookings/dropdown-jobs`,
    getRailBookingContractTypes: () => `/api/operations/rail-booking/rail-booking-contract-types`,
    loadJobsForCancellingRailBooking: (railBookingId: string) =>
      `/api/operations/rail-bookings/${railBookingId}/jobs-for-cancelling`,
    downloadAttachment: (attachmentId: string) =>
      `/api/operations/rail-bookings/attachments/${attachmentId}`,
  },
  scheduleJobsOperationUrls: {
    createGenerateJobsOperation: () => '/api/operations/generate-jobs-operations/create',
    listGenerateJobsOperations: (
      query: Partial<
        Operations.Domain.Queries.ListGenerateJobsOperations.ListGenerateJobsOperationsQuery
      >
    ) => `/api/operations/generate-jobs-operations?${queryString.stringify(query)}`,
    acknowledgeFailedGenerateJobsOperation: (generateJobsOperationId: string) =>
      `/api/operations/generate-jobs-operations/${generateJobsOperationId}/acknowledge`,
    deleteGeneratedJobs: (generateJobsOperationId: string) =>
      `/api/operations/generate-jobs-operations/${generateJobsOperationId}/delete`,
    getGenerateJobsOperationDetails: (id: string) =>
      `/api/operations/generate-jobs-operations/${id}`,

    createBulkDriverAuthorizationUpdateOperation: () =>
      '/api/operations/bulk-driver-authorization/create',
    listBulkDriverAuthorizationUpdateOperations: (
      query: Partial<
        Operations.Domain.Queries.ListGenerateJobsOperations.ListGenerateJobsOperationsQuery
      >
    ) => `/api/operations/bulk-driver-authorization?${queryString.stringify(query)}`,
    getBulkDriverAuthorizationUpdateOperationDetails: (id: string) =>
      `/api/operations/bulk-driver-authorization/${id}`,
  },
  jobUrls: {
    exportJobsToExcel: (query: Partial<Operations.Domain.Queries.ListJobs.ListJobsQuery>) =>
      `/api/operations/jobs/excel?${queryString.stringify(query)}`,
    exportVehicleReallocationsToExcel: (
      query: Partial<Operations.Domain.Queries.ListJobs.ListJobsQuery>
    ) => `/api/operations/jobs/vehicle-reallocations-excel?${queryString.stringify(query)}`,
    exportJobHoursToExcel: (query: Partial<Operations.Domain.Queries.ListJobs.ListJobsQuery>) =>
      `/api/operations/jobs/job-hours-excel?${queryString.stringify(query)}`,
    exportTripsTakenToExcel: (
      query: Partial<Operations.Domain.Queries.ExportTripsTakenToExcel.TripsTakenQuery>
    ) => `/api/operations/jobs/trips-taken-to-excel?${queryString.stringify(query)}`,
    exportJobWithPaidHoursToExcel: (
      query: Partial<Operations.Domain.Queries.ListJobs.ListJobsQuery>
    ) => `/api/operations/jobs/paid-hours-excel?${queryString.stringify(query)}`,
    exportJobsToPdf: (query: Partial<Operations.Domain.Queries.ListJobs.ListJobsQuery>) =>
      `/api/operations/jobs/pdf?${queryString.stringify(query)}`,
    exportJobsByDriverToPdf: (query: Partial<Operations.Domain.Queries.ListJobs.ListJobsQuery>) =>
      `/api/operations/jobs-by-driver/pdf?${queryString.stringify(query)}`,
    exportCharterJobsToPdf: (
      query: Partial<Operations.Domain.Queries.ListJobsForCharter.ListJobsForCharterQuery>
    ) => `/api/operations/jobs-for-charter/pdf?${queryString.stringify(query)}`,
    listJobs: (query: Partial<Operations.Domain.Queries.ListJobs.ListJobsQuery>) =>
      `/api/operations/jobs?${queryString.stringify(query)}`,
    listJobsWithNoDriverClockedOn: (user?: string) =>
      `/api/operations/jobs/now/driver-not-clocked-on/${user}`,
    exportRailInstructions: (query: Partial<Operations.Domain.Queries.ListJobs.ListJobsQuery>) =>
      `/api/operations/jobs/rail-instructions?${queryString.stringify(query)}`,
    exportCharterInstructions: (query: Partial<Operations.Domain.Queries.ListJobs.ListJobsQuery>) =>
      `/api/operations/jobs/charter-instructions?${queryString.stringify(query)}`,
    exportCharterDailyDiaryReport: (
      query: Partial<Operations.Domain.Queries.ListJobs.ListJobsQuery>
    ) => `/api/operations/jobs/charter-daily-diary-details?${queryString.stringify(query)}`,
    exportCharterInstructionsForCharter: (
      query: Partial<Operations.Domain.Queries.ListJobsForCharter.ListJobsForCharterQuery>
    ) => `/api/operations/jobs/charter-instructions-for-charter?${queryString.stringify(query)}`,
    listJobsForCharter: (
      query: Partial<Operations.Domain.Queries.ListJobsForCharter.ListJobsForCharterQuery>
    ) => `/api/operations/jobs-for-charter?${queryString.stringify(query)}`,
    listJobsForAllocations: (
      query: Partial<Operations.Domain.Queries.ListJobsForAllocations.ListJobsForAllocationsQuery>
    ) => `/api/operations/jobs/allocations?${queryString.stringify(query)}`,
    getExternalItemsForAllocations: (
      query: Partial<
        Operations.Domain.Queries.GetExternalItemsForAllocations.GetExternalItemsForAllocationsQuery
      >
    ) => `/api/operations/jobs/allocations-external-items?${queryString.stringify(query)}`,
    createAdhocJob: () => '/api/operations/jobs/create',
    createRouteTrainingJob: () => '/api/operations/jobs/create-route-training',
    updateJob: (jobId: string) => `/api/operations/jobs/${jobId}`,
    splitJob: (jobId: string) => `/api/operations/jobs/${jobId}/split`,
    deleteJob: (jobId: string, forceDelete: boolean | undefined) =>
      `/api/operations/jobs/${jobId}${!!forceDelete ? '?forceDelete=true' : ''}`,
    viewJob: (jobId: string) => `/api/operations/jobs/${jobId}`,
    getProgress: (jobId: string) => `/api/operations/jobs/${jobId}/progress`,
    getScheduledBreaks: (jobId: string) => `/api/operations/jobs/${jobId}/scheduled-breaks`,
    getPreviousAndNextProgresses: (jobId: string) =>
      `/api/operations/jobs/${jobId}/previous-next-progress`,
    updateProgress: (jobId: string) => `/api/operations/jobs/${jobId}/progress`,
    updateScheduledBreaks: (jobId: string) => `/api/operations/jobs/${jobId}/scheduled-breaks`,
    duplicateJob: (jobId: string) => `/api/operations/jobs/${jobId}/duplicate`,
    cancelJob: (jobId: string) => `/api/operations/jobs/${jobId}/cancel`,
    dismissProgressWarning: (jobId: string) =>
      `/api/operations/jobs/${jobId}/dismiss-progress-warning`,
    allocateStaffMember: (jobId: string) => `/api/operations/jobs/${jobId}/allocate-staff-member`,
    allocateAsset: (jobId: string) => `/api/operations/jobs/${jobId}/allocate-asset`,
    getWarnings: (query: Operations.Domain.Queries.GetJobWarnings.GetJobWarningsQuery) =>
      `/api/operations/jobs/warnings?${queryString.stringify(query)}`,
    continueToNextJob: (jobId: string) => `/api/operations/jobs/${jobId}/continue-to`,
    stopContinueToNextJob: (jobId: string) => `/api/operations/jobs/${jobId}/stop-continue-to`,
    acknowledgeOnBehalf: (jobId: string, staffMemberId: string) =>
      `/api/operations/jobs/${jobId}/acknowledge/${staffMemberId}`,
    getPrefilledTimesheet: (
      query: Common.Queries.Operations.GetPrefilledTimesheet.GetPrefilledTimesheetQuery
    ) => `/api/operations/jobs/timesheets?${queryString.stringify(query)}`,
    listJobProgressWarnings: (
      query: Partial<Operations.Domain.Queries.ListJobProgressWarnings.ListJobProgressWarningsQuery>
    ) => `/api/operations/jobs/progress-warnings?${queryString.stringify(query)}`,
    exportJobProgressWarningsToExcel: (
      query: Partial<Operations.Domain.Queries.ListJobProgressWarnings.ListJobProgressWarningsQuery>
    ) => `/api/operations/jobs/progress-warnings/excel?${queryString.stringify(query)}`,
    generateJobFatigueBreaks: (jobId: string) =>
      `/api/operations/jobs/${jobId}/generate-fatigue-breaks`,
    getJobConflicts: (jobId: string) => `/api/operations/jobs/${jobId}/conflicts`,
    swapVehicle: (jobId: string) => `/api/operations/jobs/${jobId}/swap-vehicle`,
    listDriverJobs: (
      query: Partial<
        Operations.Domain.Queries.ListDailySummarySheetDetails.ListDailySummarySheetDetailsQuery
      >
    ) => `/api/operations/jobs/driver-jobs?${queryString.stringify(query)}`,
    restoreJob: (jobId: string) => `/api/operations/jobs/${jobId}/restore-cancelled`,
    loadJobSubTypes: () => `/api/operations/jobs/job-sub-types`,
  },
  conflictUrls: {
    acceptConflict: (conflictId: string) => `/api/operations/conflicts/${conflictId}/accept`,
    cancelAcceptanceConflict: (conflictId: string) =>
      `/api/operations/conflicts/${conflictId}/cancel-acceptance`,
  },
  jobTaskUrls: {
    getTasksForDay: (day: string) => `/api/operations/job-tasks?day=${day}`,
    updateTask: () => `/api/operations/job-tasks`,
  },
  paidHoursUrls: {
    getPaidHoursForJob: (jobId: string) => `/api/operations/paid-hours/paid-hours-for-job/${jobId}`,
  },
  hourlyRatesUrls: {
    listHourlyRates: () => `/api/operations/hourly-rates`,
    updateHourlyRates: (command: Operations.Domain.Commands.HourlyRate.UpdateHourlyRatesCommand) =>
      `/api/operations/hourly-rates/${command.id}`,
    createHourlyRates: () => `/api/operations/hourly-rates/create`,
    deleteHourlyRates: (id: string) => `/api/operations/hourly-rates/${id}`,
  },
  authorisedDevicesUrls: {
    getNameExists: (name: string) =>
      `/api/operations/authorised-devices/check-for-unique-name/${enc(name)}`,
    createDevice: () => `/api/operations/authorised-devices`,
    deleteDevice: (deviceId: string) => `/api/operations/authorised-devices/${deviceId}`,
    generateAuthorisationCode: (deviceId: string) =>
      `/api/operations/authorised-devices/${deviceId}/authorisation-code`,
    revokeAuthorisation: (deviceId: string) =>
      `/api/operations/authorised-devices/${deviceId}/revoke`,
    enableAlcoReadingDevice: (deviceId: string) =>
      `/api/operations/authorised-devices/${deviceId}/enable`,
    disableAlcoReadingDevice: (deviceId: string) =>
      `/api/operations/authorised-devices/${deviceId}/disable`,
    checkAlcoReadingDevice: (deviceId: string) =>
      `/api/operations/authorised-devices/${deviceId}/check`,
  },
  requirementsUrls: {
    clearTechSpecRequirements: (techSpecId: number) =>
      `/api/operations/requirements/tech-specs/${techSpecId}`,
    clearSkillSpecRequirements: (skillSpecId: number) =>
      `/api/operations/requirements/skill-specs/${skillSpecId}`,
  },

  assetOdometerReadingsUrls: {
    list: (assetId: string, from: string | undefined, to: string | undefined) =>
      `/api/operations/asset/${assetId}/odometer-readings?from=${from}&to=${to}`,
    override: (assetId: string) => `/api/operations/asset/${assetId}/odometer-readings/override`,
  },

  tradingCompaniesUrls: {
    list: '/api/operations/trading-companies',
  },

  startUpUrls: {
    getStartUpData: `/api/operations/start-up-data`,
  },
  charterContractUrls: {
    createCharterContract: () => '/api/operations/charter-contracts/create',
    updateCharterContract: (id: string) => `/api/operations/charter-contracts/${id}`,
    getCharterContract: (id: string) => `/api/operations/charter-contracts/${id}`,
    getCharterContractsByCustomer: (customerId: string) =>
      `/api/operations/charter-contracts/customer/${customerId}`,
    listCharterContracts: () => `/api/operations/charter-contracts`,
    findCharterContracts: (ids: string[], customerId?: string) =>
      `/api/operations/charter-contracts/find?${queryString.stringify({ ids, customerId })}`,
    searchCharterContracts: (search: string, customerId?: string) =>
      `/api/operations/charter-contracts/search?${queryString.stringify({
        query: search,
        customerId,
      })}`,
    checkForUniqueName: (name: string) =>
      `/api/operations/charter-contracts/check-for-unique-name?name=${enc(name)}`,
  },
};

export const peopleUrls = {
  dashboardUrls: {
    loadDashboard: '/api/people/dashboard',
  },
  staffMemberUrls: {
    loadDrivers: () => `/api/people/drivers`,
    loadMechanics: () => `/api/people/mechanics`,
    loadReportableEventContacts: () => `/api/people/reportable-event-contacts`,
    loadAllStaffMembers: () => '/api/people/staff-members/all',
    loadAllStaffMemberNames: (
      query: Partial<Common.Queries.GetStaffMemberNames.GetStaffMemberNamesQuery>
    ) => `/api/people/staff-members/all/names?${queryString.stringify(query)}`,
    loadStaffMembers: (
      query: Partial<People.Domain.Queries.ListStaffMembers.ListStaffMembersQuery>
    ) => `/api/people/staff-members?${queryString.stringify(query)}`,
    createStaffMember: () => `/api/people/staff-members`,
    updateStaffMember: (staffMemberId: string) => `/api/people/staff-members/${staffMemberId}`,
    viewStaffMember: (staffMemberId: string) => `/api/people/staff-members/${staffMemberId}`,
    markStaffMemberInactive: (staffMemberId: string) =>
      `/api/people/staff-members/${staffMemberId}/inactive`,
    markStaffMemberActive: (staffMemberId: string) =>
      `/api/people/staff-members/${staffMemberId}/active`,
    checkForUniqueEmployeeId: (employeeId: string) =>
      `/api/people/staff-members/check-for-unique-employee-id?employeeId=${enc(employeeId)}`,
    checkForUniqueEmail: (email: string) =>
      `/api/people/staff-members/email-exists?email=${enc(email)}`,
    createStaffMemberLicence: (staffMemberId: string) =>
      `/api/people/staff-members/${staffMemberId}/licences`,
    updateStaffMemberLicence: (staffMemberId: string, licenceId: string) =>
      `/api/people/staff-members/${staffMemberId}/licences/${licenceId}`,
    deleteStaffMemberLicence: (staffMemberId: string, licenceId: string) =>
      `/api/people/staff-members/${staffMemberId}/licences/${licenceId}`,
    createStaffMemberFileNote: (staffMemberId: string) =>
      `/api/people/staff-members/${staffMemberId}/file-notes`,
    updateStaffMemberFileNote: (staffMemberId: string, fileNoteId: number) =>
      `/api/people/staff-members/${staffMemberId}/file-notes/${fileNoteId}`,
    createStaffMemberEquipment: (staffMemberId: string) =>
      `/api/people/staff-members/${staffMemberId}/equipment`,
    updateStaffMemberEquipment: (staffMemberId: string, equipmentId: number) =>
      `/api/people/staff-members/${staffMemberId}/equipment/${equipmentId}`,
    deleteStaffMemberEquipment: (staffMemberId: string, equipmentId: number) =>
      `/api/people/staff-members/${staffMemberId}/equipment/${equipmentId}`,
    updateStaffMemberSkillSpecs: (staffMemberId: string) =>
      `/api/people/staff-members/${staffMemberId}/skill-specs`,

    createLeave: () => `/api/people/leaves`,
    updateLeave: (leaveId: string) => `/api/people/leaves/${leaveId}`,
    deleteLeave: (
      leaveId: string,
      deleteRecurrences: boolean,
      applyToFutureRecurringLeave: boolean
    ) =>
      `/api/people/leaves/${leaveId}?deleteRecurrences=${deleteRecurrences}&applyToFutureRecurringLeave=${applyToFutureRecurringLeave}`,

    skillSpecUrls: {
      getAll: () => `/api/people/skill-specs`,
      getAllForRequirements: () => `/api/people/skill-specs/requirements`,
      updateAll: () => `/api/people/skill-specs`,
    },

    equipmentTypes: {
      listEquipmentTypes: (
        query: Partial<People.Domain.Queries.EquipmentType.GetEquipmentTypeListQuery>
      ) => `/api/people/equipment-types?${queryString.stringify(query)}`,
      getAll: () => `/api/people/equipment-types/all`,
      createEquipmentType: () => '/api/people/equipment-types',
      getEquipmentType: (equipmentTypeId: number) =>
        `/api/people/equipment-types/${equipmentTypeId}`,
      updateEquipmentType: (equipmentTypeId: number) =>
        `/api/people/equipment-types/${equipmentTypeId}`,
      deleteEquipmentType: (equipmentTypeId: number) =>
        `/api/people/equipment-types/${equipmentTypeId}`,
      checkForUniqueDescription: (description: string) =>
        `/api/people/equipment-types/check-for-unique-description?description=${enc(description)}`,
    },

    staffDepots: {
      getAll: () => `/api/people/staff-depots`,
    },

    employmentStatuses: {
      getAll: () => `/api/people/employment-statuses`,
    },

    employmentContractCodes: {
      getAll: () => `/api/people/employment-contract-codes`,
    },
  },
  noteUrls: {
    createNote: () => '/api/people/notes',
    loadNotes: (query: Partial<People.Domain.Queries.ListNotes.ListNotesQuery>) =>
      `/api/people/notes?${queryString.stringify(query)}`,
    loadNote: (noteId: string) => `/api/people/notes/${noteId}`,
    updateNote: (noteId: string) => `/api/people/notes/${noteId}`,
    deleteNote: (noteId: string) => `/api/people/notes/${noteId}`,
  },
  roleUrls: {
    createRole: () => '/api/people/roles',
    loadRoles: (query: Partial<Common.Queries.People.ListRoles.ListRolesQuery>) =>
      `/api/people/roles?${queryString.stringify(query)}`,
    loadRole: (roleId: string) => `/api/people/roles/${roleId}`,
    getRoles: () => `/api/people/roles/all`,
    updateRole: (roleId: string) => `/api/people/roles/${roleId}`,
    deleteRole: (roleId: string) => `/api/people/roles/${roleId}`,
    checkForUniqueName: (name: string) =>
      `/api/people/roles/check-for-unique-name?name=${enc(name)}`,
    assignStaffMember: (staffMemberId: string) =>
      `/api/people/staff-members/${staffMemberId}/roles/assign`,
    assignStaffMembers: (roleId: string) => `/api/people/roles/${roleId}/staff-members`,
    listStaffMembers: (roleId: string) => `/api/people/roles/${roleId}/staff-members`,
    removeRoleFromStaffMember: (staffMemberId: string) =>
      `/api/people/staff-members/${staffMemberId}/roles/remove`,
  },
  timesheetAllowanceUrls: {
    createTimesheetAllowance: () => '/api/people/timesheet-allowances',
    loadTimesheetAllowances: (
      query: Partial<Common.Queries.People.TimesheetAllowances.ListTimesheetAllowancesQuery>
    ) => `/api/people/timesheet-allowances?${queryString.stringify(query)}`,
    loadTimesheetAllowance: (timesheetAllowanceId: string) =>
      `/api/people/timesheet-allowances/${timesheetAllowanceId}`,
    updateTimesheetAllowance: (timesheetAllowanceId: string) =>
      `/api/people/timesheet-allowances/${timesheetAllowanceId}`,
    deleteTimesheetAllowance: (timesheetAllowanceId: string) =>
      `/api/people/timesheet-allowances/${timesheetAllowanceId}`,
    reactivateTimesheetAllowance: (timesheetAllowanceId: string) =>
      `/api/people/timesheet-allowances/${timesheetAllowanceId}/reactivate`,
    checkForUniqueName: (name: string) =>
      `/api/people/timesheet-allowances/check-for-unique-name?name=${enc(name)}`,
  },
  externalIdentityUrls: {
    loadExternalIdentities: (
      query: Partial<People.Domain.Queries.ListExternalIdentities.ListExternalIdentitiesQuery>
    ) => `/api/people/external-identity?${queryString.stringify(query)}`,
    loadExternalIdentityProviders: () => `/api/people/external-identity/providers`,
    updateExternalIdentity: (externalIdentityId: string) =>
      `/api/people/external-identity/${externalIdentityId}`,
    deleteExternalIdentity: (externalIdentityId: string) =>
      `/api/people/external-identity/${externalIdentityId}`,
  },
  smsUrls: {
    createSms: () => '/api/people/sms',
    loadListSms: (query: Partial<Common.Sms.ListSmsQuery>) =>
      `/api/people/sms?${queryString.stringify(query)}`,
    loadSms: (noteId: string) => `/api/people/sms/${noteId}`,
    dismissSms: (smsId: string, smsStaffMemberId: string) =>
      `/api/people/sms/${smsId}/${smsStaffMemberId}/dismiss`,
  },
  timesheetsUrls: {
    getWeekWithoutTimesheet: (query: Partial<GetWeekWithoutTimesheetQuery>) =>
      `/api/people/timesheets/week-without-timesheet?${queryString.stringify(query)}`,
    createTimesheet: () => `/api/people/timesheets`,
    viewTimesheet: (timesheetId: string) => `/api/people/timesheets/${timesheetId}`,
    updateTimesheet: (timesheetId: string) => `/api/people/timesheets/${timesheetId}`,
    amendTimesheet: (timesheetId: string) => `/api/people/timesheets/${timesheetId}/amend`,
    approveTimesheet: (timesheetId: string) => `/api/people/timesheets/${timesheetId}/approve`,
    processTimesheet: (timesheetId: string) => `/api/people/timesheets/${timesheetId}/process`,
    processTimesheets: (query: Partial<ListTimesheetsQuery>) =>
      `/api/people/timesheets/process?${queryString.stringify(query)}`,
    refreshTimesheet: (timesheetId: string) => `/api/people/timesheets/${timesheetId}/refresh`,
    printTimesheet: (timesheetId: string) => `/api/people/timesheets/${timesheetId}/print`,
    listTimesheets: (query: Partial<ListTimesheetsQuery>) =>
      `/api/people/timesheets?${queryString.stringify(query)}`,
    printTimesheets: (query: Partial<ListTimesheetsQuery>) =>
      `/api/people/timesheets/all?${queryString.stringify(query)}`,
    exportTimesheets: (query: Partial<ListTimesheetsQuery>) =>
      `/api/people/timesheets/export?${queryString.stringify(query)}`,
    exportDetailedTimesheets: (query: Partial<ListDetailedTimesheetsQuery>) =>
      `/api/people/timesheets/export-detailed?${queryString.stringify(query)}`,
    exportTimesheetNotes: (query: Partial<ListTimesheetNotesQuery>) =>
      `/api/people/timesheets/export-notes?${queryString.stringify(query)}`,
  },
};

export const kioskUrls = {
  clockUrls: {
    clockOn: () => '/api/kiosk/clock/on',
    clockOff: () => '/api/kiosk/clock/off',
    loadStaffMemberStatus: (staffMemberId: string) => `/api/kiosk/clock/${staffMemberId}`,
    loadStaffMemberTestResult: (staffMemberId: string) =>
      `/api/kiosk/clock/${staffMemberId}/test-result`,
  },
  assetCubic: {
    cubicFaultDetails: '/api/kiosk/workshop/assets/cubic-fault-details',
    cubicItems: '/api/kiosk/workshop/assets/cubic-items',
  },
  jobUrls: {
    loadCharterInstructionsForDriver: (date: string) => `/api/kiosk/jobs/${date}/charter`,
    loadJobsForDriver: (date: string) => `/api/kiosk/jobs/${date}`,
    loadLeavesForDriver: (from: DateTime, to: DateTime) =>
      `/api/kiosk/leaves?from=${enc(from.toUTC().toISO())}&to=${enc(to.toUTC().toISO())}`,
    acknowledgeJob: (jobId: string) => `/api/kiosk/jobs/${enc(jobId)}/acknowledge`,
    completeJob: (jobId: string) => `/api/kiosk/jobs/${enc(jobId)}/complete`,
    loadJob: (jobId: string) => `/api/kiosk/jobs/${enc(jobId)}`,
    loadDriverPdfs: (jobId: string) => `/api/kiosk/jobs/${jobId}/pdfs`,
    getDriverJobAttachment: (attachmentId: string) => `/api/kiosk/jobs/attachment/${attachmentId}`,
  },
  noteUrls: {
    loadNotesForDriver: (date: string) => `/api/kiosk/notes/${date}`,
    markNotesReadForDriverOnDay: (date: string) => `/api/kiosk/notes/${date}/mark-read`,
  },
  timesheetUrls: {
    getTimesheetsForDriver: (fromDate: DateTime, toDate: DateTime) =>
      `/api/kiosk/timesheets?fromDate=${fromDate.toISODate()}&toDate=${toDate.toISODate()}`,
    getTimesheetForDriver: (timesheetId: string) => `/api/kiosk/timesheets/${enc(timesheetId)}`,
    getPrefilledTimesheetForDriver: (date: DateTime) =>
      `/api/kiosk/timesheets/prefilled/${date.toISODate()}`,
    createTimesheetForDriver: () => `/api/kiosk/timesheets`,
    refreshTimesheetForDriver: (timesheetId: string) =>
      `/api/kiosk/timesheets/${enc(timesheetId)}/refresh`,
    updateTimesheetForDriver: (timesheetId: string) => `/api/kiosk/timesheets/${enc(timesheetId)}`,
    printTimesheetForDriver: (timesheetId: string) =>
      `/api/kiosk/timesheets/${enc(timesheetId)}/print`,
    getTimesheetReminder: (query: Partial<GetTimesheetReminderQuery>) =>
      `/api/kiosk/timesheets/reminder?${queryString.stringify(query)}`,
    logTimesheetReminderWasDisplayed: (date: DateTime) =>
      `/api/kiosk/timesheets/reminder/${date.toISODate()}/displayed`,
    logTimesheetReminderWasViewed: (date: DateTime) =>
      `/api/kiosk/timesheets/reminder/${date.toISODate()}/viewed`,
  },
  workshopUrls: {
    loadJobs: () => `/api/kiosk/workshop/jobs`,
    loadJob: (jobId: string) => `/api/kiosk/workshop/job/${enc(jobId)}`,
    loadPrintJobSheet: (jobId: string) => `/api/kiosk/workshop/job/${enc(jobId)}/print`,
    loadJobsForMechanic: (date: string, depotId: number) => `/api/kiosk/ws-jobs/${date}/${depotId}`,
    loadOverdueJobsForMechanic: (depotId: number) => `/api/kiosk/ws-overdue-jobs/${depotId}`,
    shifts: {
      loadShift: (shiftId: string) => `/api/kiosk/workshop/shift-details/${enc(shiftId)}`,
      completeShift: (shiftId: string) =>
        `/api/kiosk/workshop/shift-details/${enc(shiftId)}/complete`,
    },
    parts: {
      listPartsForDropdown: (query: Partial<ListPartsForDropdownQuery>) =>
        `/api/kiosk/workshop/parts-dropdown?${queryString.stringify(query)}`,
    },
    loadAssetHousingLocations: () => '/api/kiosk/workshop/asset-housing-locations',
    loadDepots: () => '/api/kiosk/workshop/depots',
  },
};

export const workshopAttachmentUrls = {
  addAttachment: (aggregateId: string, aggregateType: string) =>
    `/api/workshop/${aggregateType}/${aggregateId}/add-attachment`,
  loadAttachments: (aggregateId: string, aggregateType: string) =>
    `/api/workshop/${aggregateType}/${aggregateId}/attachments`,
  downloadAttachment: (attachmentDetailsId: number, aggregateId: string, aggregateType: string) =>
    `/api/workshop/${aggregateType}/${aggregateId}/attachments/${attachmentDetailsId}/download`,
  deleteAttachment: (attachmentDetailsId: number, aggregateId: string, aggregateType: string) =>
    `/api/workshop/${aggregateType}/${aggregateId}/attachments/${attachmentDetailsId}`,
  updateAttachment: (attachmentDetailsId: number, aggregateId: string, aggregateType: string) =>
    `/api/workshop/${aggregateType}/${aggregateId}/attachments/${attachmentDetailsId}`,
  swapAttachments: (aggregateId: string, aggregateType: string) =>
    `/api/workshop/${aggregateType}/${aggregateId}/swap-attachment-order`,
};

export const peopleAttachmentUrls = {
  addAttachment: (aggregateId: string, aggregateType: string) =>
    `/api/people/${aggregateType}/${aggregateId}/add-attachment`,
  loadAttachments: (aggregateId: string, aggregateType: string) =>
    `/api/people/${aggregateType}/${aggregateId}/attachments`,
  downloadAttachment: (attachmentDetailsId: number, aggregateId: string, aggregateType: string) =>
    `/api/people/${aggregateType}/${aggregateId}/attachments/${attachmentDetailsId}/download`,
  deleteAttachment: (attachmentDetailsId: number, aggregateId: string, aggregateType: string) =>
    `/api/people/${aggregateType}/${aggregateId}/attachments/${attachmentDetailsId}`,
  updateAttachment: (attachmentDetailsId: number, aggregateId: string, aggregateType: string) =>
    `/api/people/${aggregateType}/${aggregateId}/attachments/${attachmentDetailsId}`,
  swapAttachments: (aggregateId: string, aggregateType: string) =>
    `/api/people/${aggregateType}/${aggregateId}/swap-attachment-order`,
};

export const operationsAttachmentUrls = {
  addAttachment: (aggregateId: string, aggregateType: string) =>
    `/api/operations/${aggregateType}/${aggregateId}/add-attachment`,
  loadAttachmentDetails: (aggregateId: string, aggregateType: string) =>
    `/api/operations/${aggregateType}/${aggregateId}/attachments`,
  downloadAttachment: (attachmentDetailsId: number, aggregateId: string, aggregateType: string) =>
    `/api/operations/${aggregateType}/${aggregateId}/attachments/${attachmentDetailsId}/download`,
  deleteAttachment: (attachmentDetailsId: number, aggregateId: string, aggregateType: string) =>
    `/api/operations/${aggregateType}/${aggregateId}/attachments/${attachmentDetailsId}`,
  updateAttachment: (attachmentDetailsId: number, aggregateId: string, aggregateType: string) =>
    `/api/operations/${aggregateType}/${aggregateId}/attachments/${attachmentDetailsId}`,
  swapAttachments: (aggregateId: string, aggregateType: string) =>
    `/api/operations/${aggregateType}/${aggregateId}/swap-attachment-order`,
};
