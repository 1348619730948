import { types, flow, getRoot } from 'mobx-state-tree';
import { getAjax } from 'src/domain/services/storeEnvironment';
import { IRootStoreModel } from 'src/domain/entities/RootStoreModel';
import { ListCharterContractsModel } from './ListCharterContractsModel';

type ListResult<T> = Common.Dtos.ListResult<T>;
type GetCharterContractItem = Operations.Domain.Queries.GetCharterContract.CharterContractItem;
type FindCharterContractItem = Operations.Domain.Queries.FindCharterContracts.CharterContractItem;
type SearchCharterContractItem = Operations.Domain.Queries.SearchCharterContracts.CharterContractItem;
type CharterContractByCustomerItem = Operations.Domain.Queries.GetCharterContractByCustomer.CharterContractItem;
type CreateCharterContractCommand = Operations.Domain.Commands.CharterContract.CreateCharterContract.CreateCharterContractCommand;
type UpdateCharterContractCommand = Operations.Domain.Commands.CharterContract.UpdateCharterContract.UpdateCharterContractCommand;
export const CharterContractModel = types
  .model('CharterContractModel', {
    charterContract: types.maybe(types.frozen<GetCharterContractItem>()),
    charterContracts: types.array(types.frozen<GetCharterContractItem>()),
    charterContractsByCustomer: types.array(types.frozen<CharterContractByCustomerItem>()),
    list: types.optional(ListCharterContractsModel, {}),
  })
  .actions(self => {
    const ajax = getAjax(self);
    const root = getRoot(self) as IRootStoreModel;

    const createCharterContract = flow(function*(command: CreateCharterContractCommand) {
      const id = yield ajax.operations.charterContract.createCharterContract(command);
      self.charterContract = undefined;
      root.history.push(`/operations/charter-contracts/${id}`);
    });

    const loadCharterContract = flow(function*(id: string) {
      self.charterContract = yield ajax.operations.charterContract.viewCharterContract(id);
    });

    const updateCharterContract = flow(function*(command: UpdateCharterContractCommand) {
      yield ajax.operations.charterContract.updateCharterContract(command);
      yield loadCharterContract(command.id);
    });

    const listCharterContracts = flow(function*() {
      self.charterContracts = yield ajax.operations.charterContract.listCharterContracts();
    });

    const getCharterContractsByCustomer = flow(function*(customerId: string) {
      self.charterContractsByCustomer = yield ajax.operations.charterContract.getCharterContractsByCustomer(
        customerId
      );
    });

    const findCharterContracts = flow(function*(ids: string[], customerId?: string) {
      const contracts: ListResult<FindCharterContractItem> = yield ajax.operations.charterContract.findCharterContracts(
        ids,
        customerId
      );
      return { options: contracts.items };
    });

    const searchCharterContracts = flow(function*(search: string, customerId?: string) {
      const contracts: ListResult<SearchCharterContractItem> = yield ajax.operations.charterContract.searchCharterContracts(
        search,
        customerId
      );
      return { options: contracts.items };
    });

    const checkForUniqueName = flow(function*(name: string) {
      return yield ajax.operations.charterContract.checkForUniqueName(name);
    });

    return {
      createCharterContract,
      loadCharterContract,
      updateCharterContract,
      listCharterContracts,
      getCharterContractsByCustomer,
      findCharterContracts,
      searchCharterContracts,
      checkForUniqueName,
    };
  });
