import { DateTime } from 'luxon';
import { ChangeState } from 'src/api/enums';
import { IAutocompleteResult } from 'src/domain/baseTypes';
import { getStateTimezone } from 'src/domain/entities/operations/sales/boardingPoint/StateTimezones';
import { ChevronDownIcon, ChevronUpIcon, CutIcon, PlusIcon, WarnIcon } from 'src/images/icons';
import {
  getAllUniqueVehicleTypeIdsFromTrips,
  getToAndFromDatesFromTrips,
} from 'src/infrastructure/allocationUtils';
import TimezoneFormat from 'src/views/components/DateTimeFormat/TimezoneFormat';
import { FieldInfoTooltip } from 'src/views/components/FieldInfoTooltip';
import {
  getEditingFormattedTimeString,
  parseEditingFormattedTimeString,
} from 'src/views/components/Page/fields/subfields/TimeHelpers';
import {
  ActionType,
  FieldDefs,
  FieldType,
  IDateFieldDef,
  IFieldData,
  IHasChangeState,
  INestingPaneDef,
  ITablePaneFieldExtrasDef,
  ITimeFieldDef,
  PaneType,
  ShellModalSize,
} from 'src/views/definitionBuilders/types';
import getAddBoardingPointModalDef from 'src/views/routes/operations/shared/getAddBoardingPointModalDef';
import { getSplitJobModalDef } from 'src/views/routes/operations/shared/getSplitJobModalDef';
import {
  IPublicHolidayOverlapItem,
  IPublicHolidayOverlapTripItem,
} from '../sales/quote/maintainQuote/MaintainQuote';
import styles from './getTripRoutesPaneDef.module.scss';
import { dateRangeOverlaps } from 'src/domain/dateHelper';

type Trip = Operations.Domain.Commands.Quote.Trip;
type Route = Operations.Domain.Queries.ViewQuote.Route;
type States = Operations.Domain.AggregatesModel.BoardingPointAggregate.States;
type BoardingPointListItem = Operations.Domain.Queries.SearchBoardingPoint.BoardingPointListItem;
type CreateBoardingPointCommand = Operations.Domain.Commands.BoardingPoint.CreateBoardingPointCommand;
type SplitJobCommand = Operations.Domain.Commands.Job.SplitJob.SplitJobCommand;
type HolidayListItem = Operations.Domain.Queries.ListHolidays.HolidayListItem;

let dateChangeInterval: number;
const timeoutInterval = 200;

function getArriveFieldDef(
  editable: boolean,
  initialArrivalIsMandatory: boolean,
  timeOnly: boolean,
  onArriveValidate?: (
    d: IFieldData<string>,
    value: DateTime | undefined,
    row: 'first' | 'last'
  ) => string | undefined,
  onArriveChange?: (value: DateTime | undefined, row: 'first' | 'last') => void
): ITimeFieldDef & ITablePaneFieldExtrasDef {
  return {
    fieldType: FieldType.timeField,
    dataAddr: 'arrive',
    label: 'Arrive',
    columnWidth: '7em',
    readonly: !editable,
    onChange: api => {
      if (onArriveChange) {
        const arriveTime = api.fieldData.fieldValue;
        const route = api.fieldData.parentValue as Route;
        const nonDelRoutes = (
          (api.fieldData.paneValue as Array<Route & IHasChangeState>) || []
        ).filter(r => r.changeState !== ChangeState.Deleted);
        const routeIdx = nonDelRoutes.indexOf(route);

        const rowPlacement =
          routeIdx === 0
            ? 'first'
            : nonDelRoutes.length > 1 && routeIdx === nonDelRoutes.length - 1
            ? 'last'
            : undefined;

        if (rowPlacement) {
          const dateDataAddr = [...api.fieldDataAddr];
          dateDataAddr.splice(-1, 1, 'date');
          let date = api.getFormValue(dateDataAddr);
          date = timeOnly ? DateTime.local().toISODate() : date;
          if (date && arriveTime) {
            const dateTime = DateTime.fromISO(`${date}T${arriveTime}`, {
              zone: getStateTimezone(route.location),
            });
            onArriveChange(dateTime, rowPlacement);
          } else {
            onArriveChange(undefined, rowPlacement);
          }
        }
      }
    },
    validate: d => {
      const arrive = d.fieldValue as string;
      const route = d.parentValue as Route;
      const nonDelRoutes = ((d.paneValue as Array<Route & IHasChangeState>) || []).filter(
        r => r.changeState !== ChangeState.Deleted
      );
      const routeIdx = nonDelRoutes.indexOf(route);

      if (initialArrivalIsMandatory && routeIdx === 0 && !arrive) {
        return 'Arrival time is required for the first Route of a Trip';
      }

      if (nonDelRoutes.length > 1 && routeIdx === nonDelRoutes.length - 1 && !arrive) {
        return 'Arrival time is required for the last Route of a Trip';
      }

      if (!arrive) {
        return undefined;
      }

      // It's possible this rule can trigger incorrectly if it's across a DST boundary where
      // the clock goes backwards, but luxon doesn't handle ambiguous times consistently:
      // https://moment.github.io/luxon/#/zones?id=ambiguous-times. As such, seeing as this
      // only happens once per year don't add any special handling for it.
      const depart = route.depart;
      const date = timeOnly ? DateTime.local().toISODate() : route.date;
      const routeError =
        depart && arrive > depart ? 'Arrival time cannot be after departure time' : undefined;
      if (routeError || !date) {
        return routeError;
      }

      const previousRoutes = getPreviousRoutes(nonDelRoutes, route, timeOnly);

      const previousMaxDeparture = getMaxDeparture(previousRoutes, timeOnly);

      const previousMaxArrival = getMaxArrival(previousRoutes, timeOnly);

      const arrivalDateTime = DateTime.fromISO(`${date}T${arrive}`, {
        zone: getStateTimezone(route.location),
      });

      if (
        !!previousMaxDeparture &&
        (!previousMaxArrival || previousMaxDeparture > previousMaxArrival)
      ) {
        return checkForOverlap(
          arrivalDateTime,
          previousMaxDeparture,
          'Arrival time cannot be before the departure time of a previous route'
        );
      }

      if (!!previousMaxArrival) {
        return checkForOverlap(
          arrivalDateTime,
          previousMaxArrival,
          'Arrival time cannot be before the arrival time of a previous route'
        );
      }

      if (onArriveValidate) {
        const rowPlacement =
          routeIdx === 0
            ? 'first'
            : nonDelRoutes.length > 1 && routeIdx === nonDelRoutes.length - 1
            ? 'last'
            : undefined;

        if (rowPlacement) {
          const dt = DateTime.fromISO(`${date}T${arrive}`, {
            zone: getStateTimezone(route.location),
          });
          return onArriveValidate(d, dt.isValid ? dt : undefined, rowPlacement);
        }
      }

      return undefined;
    },
  };
}

function getDepartFieldDef(
  editable: boolean,
  timeOnly: boolean,
  onDepartChange?: (value: DateTime | undefined, row: 'first' | 'last') => void
): ITimeFieldDef & ITablePaneFieldExtrasDef {
  return {
    fieldType: FieldType.timeField,
    dataAddr: 'depart',
    label: 'Depart',
    columnWidth: '7em',
    readonly: !editable,
    onChange: api => {
      const arriveDataAddr = [...api.fieldDataAddr];
      arriveDataAddr.splice(-1, 1, 'arrive');
      api.validateField(arriveDataAddr);

      if (onDepartChange) {
        const departTime = api.fieldData.fieldValue;
        const route = api.fieldData.parentValue as Route;
        const nonDelRoutes = (
          (api.fieldData.paneValue as Array<Route & IHasChangeState>) || []
        ).filter(r => r.changeState !== ChangeState.Deleted);
        const routeIdx = nonDelRoutes.indexOf(route);

        const rowPlacement =
          routeIdx === 0
            ? 'first'
            : nonDelRoutes.length > 1 && routeIdx === nonDelRoutes.length - 1
            ? 'last'
            : undefined;

        if (rowPlacement) {
          const dateDataAddr = [...api.fieldDataAddr];
          dateDataAddr.splice(-1, 1, 'date');
          let date = api.getFormValue(dateDataAddr);
          date = timeOnly ? DateTime.local().toISODate() : date;
          if (date && departTime) {
            if (rowPlacement === 'first') {
              const departTimeValue = parseEditingFormattedTimeString(departTime);
              if (departTimeValue && departTimeValue.isValid) {
                const arriveTimeValue = getEditingFormattedTimeString(
                  departTimeValue.minus({ minutes: 10 })
                );
                api.setFormValue(arriveDataAddr, arriveTimeValue);
              }
            }
            const dateTime = DateTime.fromISO(`${date}T${departTime}`, {
              zone: getStateTimezone(route.location),
            });
            onDepartChange(dateTime, rowPlacement);
          } else {
            onDepartChange(undefined, rowPlacement);
          }
        }
      }
    },
    validate: d => {
      const depart = d.fieldValue as string;
      const route = d.parentValue as Route;
      const nonDelRoutes = ((d.paneValue as Array<Route & IHasChangeState>) || []).filter(
        r => r.changeState !== ChangeState.Deleted
      );
      const routeIdx = nonDelRoutes.indexOf(route);

      if (routeIdx === 0 && !depart) {
        return 'Departure time is required for the first Route of a Trip';
      }

      if (!depart) {
        return undefined;
      }

      const previousRoutes = getPreviousRoutes(nonDelRoutes, route, timeOnly);

      const previousMaxDeparture = getMaxDeparture(previousRoutes, timeOnly);

      const previousMaxArrival = getMaxArrival(previousRoutes, timeOnly);

      const date = timeOnly ? DateTime.local().toISODate() : route.date;
      const departureDateTime = DateTime.fromISO(`${date}T${depart}`, {
        zone: getStateTimezone(route.location),
      });

      if (
        !!previousMaxDeparture &&
        (!previousMaxArrival || previousMaxDeparture > previousMaxArrival)
      ) {
        return checkForOverlap(
          departureDateTime,
          previousMaxDeparture,
          'Departure time cannot be before the departure time of a previous route'
        );
      }

      if (!!previousMaxArrival) {
        return checkForOverlap(
          departureDateTime,
          previousMaxArrival,
          'Departure time cannot be before the arrival time of a previous route'
        );
      }

      return undefined;
    },
  };
}

function getPreviousRoutes(
  nonDelRoutes: (Route & IHasChangeState)[],
  currentRoute: Route,
  timeOnly: boolean
) {
  let filter = (r: Route, i: number) =>
    i < nonDelRoutes.indexOf(currentRoute) && !!r && !!r.date && !!r.location;

  if (timeOnly) {
    filter = (r: Route, i: number) => i < nonDelRoutes.indexOf(currentRoute) && !!r && !!r.location;
  }
  return nonDelRoutes.filter(filter);
}

function getMaxDeparture(previousRoutes: (Route & IHasChangeState)[], timeOnly: boolean) {
  const getDate = (route: Route): string => {
    if (timeOnly) return `${DateTime.local().toISODate()}T${route.depart}`;

    return `${route.date}T${route.depart}`;
  };

  return previousRoutes
    .filter(r => !!r.depart)
    .map(r =>
      DateTime.fromISO(getDate(r), {
        zone: getStateTimezone(r.location),
      })
    )
    .sort((a, b) => b.diff(a).milliseconds)[0];
}

function getMaxArrival(previousRoutes: (Route & IHasChangeState)[], timeOnly: boolean) {
  const getDate = (route: Route): string => {
    if (timeOnly) return `${DateTime.local().toISODate()}T${route.arrive}`;

    return `${route.date}T${route.arrive}`;
  };

  return previousRoutes
    .filter(r => !!r.arrive)
    .map(r =>
      DateTime.fromISO(getDate(r), {
        zone: getStateTimezone(r.location),
      })
    )
    .sort((a, b) => b.diff(a).milliseconds)[0];
}

function checkForOverlap(
  currentDateTime: DateTime,
  previousMaxDateTime: DateTime,
  validationMessage: string
) {
  return currentDateTime < previousMaxDateTime ? validationMessage : undefined;
}

function getDateFieldDef(
  editable: boolean,
  onArriveChange?: (value: DateTime | undefined, row: 'first' | 'last') => void,
  onDepartChange?: (value: DateTime | undefined, row: 'first' | 'last') => void,
  getVehicleTypeAllocationData?: (
    startDate: string,
    endDate: string,
    vehicleTypeIds: string[],
    quoteIdToIgnore: string | undefined,
    depotId: number
  ) => Promise<void>,
  quoteId?: string,
  setTripRoute?: (routes: Route[] | undefined) => void,
  depotId?: number,
  publicHolidays?: HolidayListItem[],
  setPublicHolidayOverlaps?: (dates: IPublicHolidayOverlapItem[]) => void,
  setTripHasPublicHoliday?: (hasHoliday: boolean) => void,
  getPublicHolidays?: (start: DateTime, end: DateTime) => Promise<HolidayListItem[]>,
  overlappingPublicHolidays?: IPublicHolidayOverlapItem[]
): IDateFieldDef & ITablePaneFieldExtrasDef {
  const today = DateTime.local();

  getPublicHolidays &&
    publicHolidays?.length === 0 &&
    getPublicHolidays(today.minus({ years: 1 }), today.plus({ years: 1 })).then(r => {
      publicHolidays = r;
    });

  return {
    fieldType: FieldType.dateField,
    dataAddr: 'date',
    label: 'Date',
    columnWidth: '12em',
    mandatory: true,
    readonly: !editable,
    onChange: api => {
      if (dateChangeInterval) {
        clearTimeout(dateChangeInterval);
      }
      dateChangeInterval = window.setTimeout(async () => {
        const trips = api.formValues.trips;
        const routes = api.fieldData.paneValue;
        const tripFrom = DateTime.fromISO(routes[0].date);
        const tripTo = DateTime.fromISO(routes[routes.length - 1].date);

        setTripRoute && setTripRoute(routes);

        if (publicHolidays && publicHolidays.length > 1 && routes.length >= 1) {
          let overlappedDates: IPublicHolidayOverlapItem[] = [];

          const tripRoutes = api.formValues.trips.map((t: Trip, i: number) => ({
            tripNumber: i,
            dates: t.routes.map(r => r.date),
          })) as IPublicHolidayOverlapTripItem[];

          tripRoutes?.forEach(trip => {
            const distinctDates = [...new Set(trip.dates)] as string[];
            const holidayDates = [...new Set(publicHolidays?.map(h => h.startDate))];
            const overlapDates = distinctDates.filter(d => holidayDates.includes(d));

            const item = {
              tripNumber: trip.tripNumber,
              holidays: publicHolidays
                ?.filter(h => overlapDates.includes(h.startDate))
                .map(h => ({
                  date: publicHolidays?.find(ph => ph.id === h.id)?.startDate,
                  name: h.description,
                })),
            } as IPublicHolidayOverlapItem;

            if (overlapDates.length) overlappedDates.push(item);

            holidayDates.forEach(date => {
              const dateOverlaps = dateRangeOverlaps(
                DateTime.fromISO(trip.dates[0]),
                DateTime.fromISO(trip.dates[trip.dates.length - 1] ?? trip.dates[0]),
                DateTime.fromISO(date),
                DateTime.fromISO(date)
              );

              if (dateOverlaps && !overlapDates.includes(date)) {
                const holidayDetail = publicHolidays?.find(ph => ph.startDate === date);

                const item = {
                  tripNumber: trip.tripNumber,
                  holidays: [
                    {
                      date: holidayDetail?.startDate,
                      name: holidayDetail?.description,
                    },
                  ],
                } as IPublicHolidayOverlapItem;
                overlappedDates.push(item);
              }
            });

            if (overlappedDates.length > 0) {
              setTripHasPublicHoliday && setTripHasPublicHoliday(true);
              setPublicHolidayOverlaps && setPublicHolidayOverlaps([...new Set(overlappedDates)]);
            } else {
              setTripHasPublicHoliday && setTripHasPublicHoliday(false);
              setPublicHolidayOverlaps && setPublicHolidayOverlaps([]);
            }
          });
        }

        if (
          routes.length > 1 &&
          trips &&
          trips.length > 1 &&
          tripFrom.isValid &&
          tripTo.isValid &&
          trips.some((t: Trip) => t.options && t.options.length > 0)
        ) {
          const { toDate, fromDate } = getToAndFromDatesFromTrips(trips);
          if (toDate && fromDate && toDate >= fromDate) {
            const vehicleTypeIds: Array<string> = getAllUniqueVehicleTypeIdsFromTrips(trips);

            getVehicleTypeAllocationData &&
              vehicleTypeIds.length &&
              (await getVehicleTypeAllocationData(
                fromDate,
                toDate,
                vehicleTypeIds,
                quoteId,
                depotId!
              ));
          }
        }
      }, timeoutInterval);

      if (onArriveChange || onDepartChange) {
        const date = api.fieldData.fieldValue;
        const route = api.fieldData.parentValue as Route;
        const nonDelRoutes = (
          (api.fieldData.paneValue as Array<Route & IHasChangeState>) || []
        ).filter(r => r.changeState !== ChangeState.Deleted);
        const routeIdx = nonDelRoutes.indexOf(route);

        const rowPlacement =
          routeIdx === 0
            ? 'first'
            : nonDelRoutes.length > 1 && routeIdx === nonDelRoutes.length - 1
            ? 'last'
            : undefined;

        if (rowPlacement) {
          if (onArriveChange) {
            const arriveDataAddr = [...api.fieldDataAddr];
            arriveDataAddr.splice(-1, 1, 'arrive');
            const arrive = api.getFormValue(arriveDataAddr);
            if (date && arrive) {
              const dateTime = DateTime.fromISO(`${date}T${arrive}`, {
                zone: getStateTimezone(route.location),
              });
              onArriveChange(dateTime, rowPlacement);
            } else {
              onArriveChange(undefined, rowPlacement);
            }
          }
          if (onDepartChange) {
            const departDataAddr = [...api.fieldDataAddr];
            departDataAddr.splice(-1, 1, 'depart');
            const depart = api.getFormValue(departDataAddr);
            if (date && depart) {
              const dateTime = DateTime.fromISO(`${date}T${depart}`, {
                zone: getStateTimezone(route.location),
              });
              onDepartChange(dateTime, rowPlacement);
            } else {
              onDepartChange(undefined, rowPlacement);
            }
          }
        }
      }
    },
    validate: d => {
      const routes = (d.paneValue as Array<Route>) || [];
      const idx = routes.indexOf(d.parentValue);
      if (idx > 0) {
        const prevIdx = idx - 1;
        const prevRoute = routes[prevIdx];
        if (
          prevRoute.date &&
          d.fieldValue &&
          DateTime.fromISO(d.fieldValue) < DateTime.fromISO(prevRoute.date) // only consider date part, zone not needed
        ) {
          return 'Date must be after or same as previous route date';
        }
        const diff =
          prevRoute.date &&
          d.fieldValue &&
          DateTime.fromISO(d.fieldValue).diff(DateTime.fromISO(prevRoute.date), 'years').years;

        if (
          d.fieldValue &&
          diff &&
          diff >= 5 //Setting limit otherwise it allocationUtils.ts crashes when it needs to loop through so many days
        ) {
          return 'Date is invalid';
        }
      }
      return undefined;
    },
  };
}

export function isEditableOptions(
  options: IEditableOptions | INeverEditableOptions
): options is IEditableOptions {
  return !options.neverEditable;
}

export interface IEditableOptions {
  neverEditable?: false;
  editable: boolean;
  states: Array<States>;
  initialArrival: 'initialArrivalMandatory' | 'initialArrivalOptional';
  searchBoardingPoints: (search: string) => Promise<IAutocompleteResult<BoardingPointListItem>>;
  boardingPoints: BoardingPointListItem[];
  getVehicleTypeAllocationData?: (
    startDate: string,
    endDate: string,
    vehicleTypeIds: string[],
    quoteIdToIgnore: string | undefined,
    depotId: number
  ) => Promise<void>;
  checkForUniqueBoardingPointName?: (name: string) => Promise<Common.Dtos.UniqueNameCheckResultDto>;
  onCreateBoardingPoint?: (command: CreateBoardingPointCommand) => Promise<string>;
  onArriveChange?: (value: DateTime | undefined, row: 'first' | 'last') => void;
  onDepartChange?: (value: DateTime | undefined, row: 'first' | 'last') => void;
  onArriveValidate?: (
    d: IFieldData<string>,
    value: DateTime | undefined,
    row: 'first' | 'last'
  ) => string | undefined;
  splitJob?: (command: SplitJobCommand) => Promise<void>;
  title?: string;
  depots?: Common.Dtos.OperationsDepotDto[];
  hidden?: boolean;
  quoteId?: string;
  isTrainingJob?: boolean;
  timeOnly?: boolean;
  setTripRoute?: (routes: Route[] | undefined) => void;
  depotId?: number;
  publicHolidays?: HolidayListItem[];
  overlappingPublicHolidays?: IPublicHolidayOverlapItem[];
  setPublicHolidayOverlaps?: (overlaps: IPublicHolidayOverlapItem[]) => void;
  tripHasPublicHoliday?: boolean;
  setTripHasPublicHoliday?: (hasPublicHoliday: boolean) => void;
  getPublicHolidays?: (start: DateTime, end: DateTime) => Promise<HolidayListItem[]>;
}

export interface INeverEditableOptions {
  neverEditable: true;
}

export default function getTripRoutesPaneDef(
  routesDataAddr: string,
  options: IEditableOptions | INeverEditableOptions
): INestingPaneDef {
  const editable = isEditableOptions(options) && options.editable;
  const states = (isEditableOptions(options) && options.states) || [];
  const initialArrival = isEditableOptions(options) && options.initialArrival;
  const searchBoardingPoints = isEditableOptions(options)
    ? options.searchBoardingPoints
    : () => Promise.resolve({ options: [] });
  const checkForUniqueBoardingPointName = isEditableOptions(options)
    ? options.checkForUniqueBoardingPointName
    : undefined;
  const onCreateBoardingPoint = isEditableOptions(options)
    ? options.onCreateBoardingPoint
    : undefined;
  const onArriveChange = isEditableOptions(options) ? options.onArriveChange : undefined;
  const onDepartChange = isEditableOptions(options) ? options.onDepartChange : undefined;
  const onArriveValidate = isEditableOptions(options) ? options.onArriveValidate : undefined;
  const depots = isEditableOptions(options) ? options.depots : undefined;
  const depotId = isEditableOptions(options)
    ? options.depotId
    : (depots && depots[0].id) ?? undefined;
  const hidden = isEditableOptions(options) ? options.hidden : undefined;
  const getVehicleTypeAllocationData =
    isEditableOptions(options) && options.getVehicleTypeAllocationData
      ? options.getVehicleTypeAllocationData
      : undefined;
  const quoteId = isEditableOptions(options) ? options.quoteId : undefined;
  const timeOnly = isEditableOptions(options) ? options.timeOnly ?? false : false;
  const setTripRoute = isEditableOptions(options) ? options.setTripRoute : undefined;
  const publicHolidays = (isEditableOptions(options) && options.publicHolidays) || [];

  const tripHasPublicHoliday = isEditableOptions(options)
    ? options.tripHasPublicHoliday
    : undefined;
  const setTripHasPublicHoliday = isEditableOptions(options)
    ? options.setTripHasPublicHoliday
    : undefined;
  const overlappingPublicHolidays = isEditableOptions(options)
    ? options.overlappingPublicHolidays
    : undefined;
  const setPublicHolidayOverlaps = isEditableOptions(options)
    ? options.setPublicHolidayOverlaps
    : undefined;
  const getPublicHolidays = isEditableOptions(options) ? options.getPublicHolidays : undefined;
  const handleRoutesChange = (
    routes: Array<Route & IHasChangeState>,
    timeOnly: boolean,
    tripNumber?: number
  ) => {
    const activeRoutes = routes.filter(r => r.changeState !== ChangeState.Deleted);
    const firstRoute = activeRoutes[0];
    const lastRoute = activeRoutes[activeRoutes.length - 1] ?? null;

    // Always firing the onArriveChange/onDepartChange is easier than working out IF they should fire
    if (onArriveChange) {
      if (firstRoute) {
        const date = timeOnly ? DateTime.local().toISODate() : firstRoute.date;
        const dateTime = DateTime.fromISO(`${date}T${firstRoute.arrive}`, {
          zone: getStateTimezone(firstRoute.location),
        });
        onArriveChange(dateTime, 'first');
      }
      if (lastRoute) {
        const date = timeOnly ? DateTime.local().toISODate() : lastRoute.date;
        const dateTime = DateTime.fromISO(`${date}T${lastRoute.arrive}`, {
          zone: getStateTimezone(lastRoute.location),
        });
        onArriveChange(dateTime, 'last');
      }
    }
    if (onDepartChange && firstRoute) {
      const date = timeOnly ? DateTime.local().toISODate() : firstRoute.date;
      const dateTime = DateTime.fromISO(`${date}T${firstRoute.depart}`, {
        zone: getStateTimezone(firstRoute.location),
      });
      onDepartChange(dateTime, 'first');
    }

    if (activeRoutes.length >= 1) {
      let overlappedDates: IPublicHolidayOverlapItem[] = [];

      const distinctDates = [...new Set(activeRoutes.map(d => d.date))] as string[];
      const holidayDates = [...new Set(publicHolidays?.map(h => h.startDate))];
      const overlapDates = distinctDates.filter(d => holidayDates.includes(d));

      const item = {
        tripNumber: tripNumber,
        holidays: publicHolidays
          ?.filter(h => overlapDates.includes(h.startDate))
          .map(h => ({
            date: publicHolidays?.find(ph => ph.id === h.id)?.startDate,
            name: h.description,
          })),
      } as IPublicHolidayOverlapItem;

      const otherTripHolidays = overlappingPublicHolidays?.filter(o => o.tripNumber !== tripNumber);
      if (otherTripHolidays?.length) otherTripHolidays.forEach(h => overlappedDates.push(h));

      if (overlapDates.length) overlappedDates.push(item);

      holidayDates.forEach(date => {
        const dateOverlaps = dateRangeOverlaps(
          DateTime.fromISO(firstRoute.date),
          DateTime.fromISO(lastRoute.date ?? firstRoute.date),
          DateTime.fromISO(date),
          DateTime.fromISO(date)
        );

        if (dateOverlaps && !overlapDates.includes(date)) {
          const holidayDetail = publicHolidays?.find(ph => ph.startDate === date);

          const item = {
            tripNumber: tripNumber,
            holidays: [
              {
                date: holidayDetail?.startDate,
                name: holidayDetail?.description,
              },
            ],
          } as IPublicHolidayOverlapItem;
          overlappedDates.push(item);
        }
      });

      if (overlappedDates.length > 0) {
        setTripHasPublicHoliday && setTripHasPublicHoliday(true);
        setPublicHolidayOverlaps && setPublicHolidayOverlaps([...new Set(overlappedDates)]);
      } else {
        setTripHasPublicHoliday && setTripHasPublicHoliday(false);
        setPublicHolidayOverlaps && setPublicHolidayOverlaps([]);
      }
    } else {
      setTripHasPublicHoliday && setTripHasPublicHoliday(false);
      setPublicHolidayOverlaps && setPublicHolidayOverlaps([]);
    }
  };

  const splitJob =
    isEditableOptions(options) && !options.isTrainingJob ? options.splitJob : undefined;
  const title = isEditableOptions(options) ? options.title : undefined;

  const standardFields = [
    {
      fieldType: FieldType.selectAsyncField,
      dataAddr: 'location',
      label: 'Location',
      valueKey: 'name',
      descriptionKey: 'name',
      loadOptionItems: searchBoardingPoints,
      mandatory: true,
      useOptionRendererAsValueRenderer: true,
      readonly: !editable,
      optionRenderer: o => (
        <span>
          <span>{o.name}</span>
          <small>
            &emsp;
            {o.address} {o.city}
          </small>
        </span>
      ),
      formatReadonly: d =>
        d.fieldValue ? (
          <span>
            <span>{d.fieldValue.name}</span>
            <small>
              &emsp;
              {d.fieldValue.address} {d.fieldValue.city}
            </small>
          </span>
        ) : null,
    },
    {
      fieldType: FieldType.actionListField,
      columnWidth: '1px',
      hidden: !editable || !onCreateBoardingPoint || !checkForUniqueBoardingPointName,
      actionGroups: [
        {
          actions: [
            {
              actionType: ActionType.modalActionButton,
              label: 'Add Location',
              icon: <PlusIcon />,
              modalSize: ShellModalSize.oneThird,
              modalDef: getAddBoardingPointModalDef(
                states,
                checkForUniqueBoardingPointName,
                onCreateBoardingPoint
              ),
            },
          ],
        },
      ],
    },
    getArriveFieldDef(
      editable,
      initialArrival === 'initialArrivalMandatory',
      timeOnly,
      onArriveValidate,
      onArriveChange
    ),
    getDepartFieldDef(editable, timeOnly, onDepartChange),
    {
      fieldType: FieldType.customField,
      columnWidth: '1px',
      render: d => {
        const boardingPointId = d.data.fieldValue?.boardingPointId;
        const boardingPoint =
          isEditableOptions(options) && boardingPointId
            ? options.boardingPoints.find(bp => bp.id === boardingPointId)
            : d.data.fieldValue;
        const timezoneName = getStateTimezone(boardingPoint);
        return (
          <div className={styles.timezoneValue}>
            <TimezoneFormat
              value={DateTime.fromISO(
                `${d.data.parentValue.date}T${d.data.parentValue.depart ||
                  d.data.parentValue.arrive ||
                  '00:00'}`,
                { zone: timezoneName }
              )}
              blankForDefaultZone
            />
          </div>
        );
      },
      dataAddr: 'location',
    },
    {
      fieldType: FieldType.customField,
      columnWidth: '1px',
      render: d => {
        const boardingPointId = d.data.fieldValue?.boardingPointId;
        const boardingPoint =
          isEditableOptions(options) && boardingPointId
            ? options.boardingPoints.find(bp => bp.id === boardingPointId)
            : null;
        const value = d.data.fieldValue;

        const newBoardingPointSelected = value?.boardingPointId === undefined;
        const noBoardingPointId = value && !value.boardingPointId;
        const outOfDate =
          boardingPoint &&
          boardingPoint.id === value.boardingPointId &&
          (boardingPoint.name !== value.name ||
            boardingPoint.address !== value.address ||
            (boardingPoint.city ?? '') !== (value.city ?? '') ||
            (boardingPoint.state ?? '') !== (value.state ?? '') ||
            (boardingPoint.postcode ?? '') !== (value.postcode ?? '') ||
            (boardingPoint.notes ?? '') !== (value.notes ?? ''));

        const warningMessage = newBoardingPointSelected
          ? null
          : noBoardingPointId
          ? 'This boarding point is no longer valid, please update it.'
          : outOfDate
          ? 'This boarding point is out of date, please check it.'
          : null;

        return warningMessage ? (
          <div className={styles.tripRouteWarning}>
            <FieldInfoTooltip icon={<WarnIcon color="orange" />}>{warningMessage}</FieldInfoTooltip>
          </div>
        ) : null;
      },
      dataAddr: 'location',
    },
    {
      fieldType: FieldType.actionListField,
      columnWidth: '1px',
      nowrap: true,
      actionGroups: [
        {
          actions: [
            {
              actionType: ActionType.moveArrayItemActionButton,
              label: 'Move up',
              icon: <ChevronUpIcon />,
              moveDirection: 'prev',
              hidden: d => !editable || (d.paneValue as Array<{}>).indexOf(d.parentValue) === 0,
              postClick: d => handleRoutesChange(d.paneValue, timeOnly, d.fieldDataAddr?.[1]),
            },
            {
              actionType: ActionType.moveArrayItemActionButton,
              label: 'Move down',
              icon: <ChevronDownIcon />,
              moveDirection: 'next',
              hidden: d =>
                !editable ||
                (d.paneValue as Array<{}>).indexOf(d.parentValue) ===
                  (d.paneValue as Array<{}>).length - 1,
              postClick: d => handleRoutesChange(d.paneValue, timeOnly, d.fieldDataAddr?.[1]),
            },
            {
              actionType: ActionType.modalActionButton,
              label: 'Split',
              icon: <CutIcon />,
              modalSize: ShellModalSize.twoThirds,
              modalDef: modalDefApi =>
                getSplitJobModalDef(
                  modalDefApi,
                  (modalDefApi.actionData.paneValue as Array<{}>).indexOf(
                    modalDefApi.actionData.parentValue
                  ),
                  options
                ),
              hidden: d => {
                const routes = d.paneValue as Array<{}>;
                return (
                  !splitJob ||
                  editable ||
                  routes.indexOf(d.parentValue) === routes.length - 1 ||
                  routes.indexOf(d.parentValue) === 0
                );
              },
            },
            {
              actionType: ActionType.removeArrayItemActionButton,
              label: 'Remove Route',
              postClick: d => {
                const item = d.actionValue as Route & IHasChangeState;
                // Exclude the item from the list if it was manually added by the user,
                // as it will have been actually deleted and the list has not been refreshed
                // as the row no longer exists.
                const routes = (d.paneValue as Route[]).filter(
                  r => r !== item || item.changeState !== ChangeState.Added
                );
                handleRoutesChange(routes, timeOnly, d.fieldDataAddr?.[1]);
              },
              hidden: !editable,
            },
          ],
        },
      ],
    },
  ] as Array<FieldDefs & ITablePaneFieldExtrasDef>;

  let fields: Array<FieldDefs & ITablePaneFieldExtrasDef> = [];

  if (!timeOnly)
    fields.unshift(
      getDateFieldDef(
        editable,
        onArriveChange,
        onDepartChange,
        getVehicleTypeAllocationData,
        quoteId,
        setTripRoute,
        depotId,
        publicHolidays,
        setPublicHolidayOverlaps,
        setTripHasPublicHoliday,
        getPublicHolidays,
        overlappingPublicHolidays
      )
    );

  standardFields.map(a => fields.push(a));

  return {
    paneType: PaneType.nestingPane,
    hidden: hidden,
    dataAddr: routesDataAddr,
    panes: [
      {
        paneType: PaneType.customPane,
        render: () => (
          <>
            <h3 className={styles.routeTitle}>
              {title || 'Routes'}{' '}
              {tripHasPublicHoliday && (
                <span className={styles.overlappingDateWarning}>
                  {' '}
                  <WarnIcon color="orange" /> This quote includes a public holiday
                </span>
              )}
            </h3>
          </>
        ),
      },
      {
        paneType: PaneType.tablePane,
        neverEditable: !isEditableOptions(options) && options.neverEditable,
        dataRequiredForRows: 'paneValue',
        validate: d => {
          const routes = ((d.paneValue as Array<IHasChangeState>) || []).filter(
            r => r.changeState !== ChangeState.Deleted
          );
          return routes.length < 2 ? 'A trip must have at least two Routes' : undefined;
        },
        fields: fields,
      },
      {
        paneType: PaneType.actionListPane,
        hidden: !editable,
        actionGroups: [
          {
            actions: [
              {
                actionType: ActionType.addArrayItemActionButton,
                label: 'Add Route',
                postClick: d => handleRoutesChange(d.paneValue, timeOnly, d.fieldDataAddr?.[1]),
              },
            ],
          },
        ],
      },
    ],
  };
}
