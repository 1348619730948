import { types, flow } from 'mobx-state-tree';
import { DateTime } from 'luxon';
import { getAjax } from 'src/domain/services';

type DailyNoteItem = Workshop.Domain.Queries.DailyNote.GetDailyNotes.DailyNoteItem;
type AddDailyNoteCommand = Workshop.Domain.Commands.DailyNote.AddDailyNote.AddDailyNoteCommand;
type UpdateDailyNoteCommand = Workshop.Domain.Commands.DailyNote.UpdateDailyNote.UpdateDailyNoteCommand;

export const DailyNotesModel = types
  .model('DailyNotesModel', {
    dailyNote: types.maybe(types.frozen<DailyNoteItem>()),
    dailyNoteFetching: types.maybe(types.boolean),
    dailyNoteAdding: types.maybe(types.boolean),
    dailyNoteUpdating: types.maybe(types.boolean),
  })
  .views(self => ({
    get dailyNoteLoading() {
      return (
        self.dailyNoteFetching === true ||
        self.dailyNoteAdding === true ||
        self.dailyNoteUpdating === true
      );
    },
  }))
  .actions(self => {
    const ajax = getAjax(self);

    const loadDailyNotesForDay = flow(function*(day: DateTime, depotId: number) {
      try {
        self.dailyNoteFetching = true;
        self.dailyNote = yield ajax.workshopDailyNotes.getDailyNotes(day, depotId);
      } finally {
        self.dailyNoteFetching = false;
      }
    });

    const addDailyNote = flow(function*(cmd: AddDailyNoteCommand) {
      try {
        self.dailyNoteAdding = true;
        const note: DailyNoteItem = yield ajax.workshopDailyNotes.createDailyNote(cmd);
        self.dailyNote = note;
      } finally {
        self.dailyNoteAdding = false;
      }
    });

    return { loadDailyNotesForDay, addDailyNote };
  })
  .actions(self => {
    const ajax = getAjax(self);

    const updateDailyNote = flow(function*(cmd: UpdateDailyNoteCommand) {
      if (self.dailyNote && self.dailyNote.id !== cmd.id) {
        throw new Error(`Note with id '${cmd.id}' was not found`);
      }

      try {
        self.dailyNoteUpdating = true;
        yield ajax.workshopDailyNotes.updateDailyNote(cmd);
        yield self.loadDailyNotesForDay(DateTime.fromISO(self.dailyNote!.day), cmd.depotId);
      } finally {
        self.dailyNoteUpdating = false;
      }
    });

    return { updateDailyNote };
  });

type DailyNotesModelType = typeof DailyNotesModel.Type;
export interface IDailyNotesModel extends DailyNotesModelType {}
