import { DateTime } from 'luxon';
import { Link } from 'react-router-dom';
import memoizeOne from 'src/infrastructure/memoizeOne';
import ListPage, { IListPageDef } from 'src/views/components/Page/pages/ListPage';
import TaskCardItem from 'src/views/components/TaskCard/TaskCardItem';
import {
  FieldDefs,
  FieldType,
  PagePrimarySize,
  PaneType,
} from 'src/views/definitionBuilders/types';
import { observer } from 'mobx-react';
import { useRootStore } from 'src/domain/entities/RootStoreModel';
import './ListNotes.scss';
import PrimaryTitle from 'src/views/components/Page/PrimaryTitle/PrimaryTitle';

type ListNoteItem = People.Domain.Queries.ListNotes.ListNoteItem;

const ListNotes: React.FC = observer(() => {
  const rootStore = useRootStore();
  const accountModel = rootStore.account;
  const notesModel = rootStore.people.notes;

  const getPageDef = memoizeOne(
    (): IListPageDef => {
      const today = DateTime.local().toISODate();
      const filterFieldDefsLookup = {
        dateFrom: {
          fieldType: FieldType.dateField,
          label: 'Date From',
          dataAddr: 'dateFrom',
          onBlur: api => {
            if (!api.formValues.dateTo) {
              api.setFormValue(['dateTo'], api.fieldData.fieldValue);
            }
            api.validateField(['dateTo']);
          },
        } as FieldDefs,
        dateTo: {
          fieldType: FieldType.dateField,
          label: 'Date To',
          dataAddr: 'dateTo',
          validate: d => {
            if (!d.fieldValue || !d.parentValue.dateFrom) {
              return undefined;
            }
            const from = DateTime.fromISO(d.parentValue.dateFrom);
            const to = DateTime.fromISO(d.fieldValue);
            return from > to ? 'Date To cannot be earlier than Date From' : undefined;
          },
        } as FieldDefs,
      };

      return {
        primaryTitle: <PrimaryTitle title="Notes"></PrimaryTitle>,
        primarySize: PagePrimarySize.full,
        onLoadData: notesModel.listItems,
        externalSearch: true,
        createLink: accountModel.canCreateOrUpdateRecords
          ? TaskCardItem.people.createNote
          : undefined,
        hasMoreData: notesModel.hasMoreItems,
        primaryFields: [
          {
            fieldType: FieldType.dateField,
            dataAddr: 'day',
            label: 'Date',
            linkTo: d => `/people/notes/${d.parentValue.id}`,
            columnWidth: '10em',
          },
          {
            fieldType: FieldType.textAreaField,
            dataAddr: 'content',
            label: 'Content',
          },
          {
            fieldType: FieldType.readonlyField,
            dataAddr: 'staffMembers',
            label: 'Staff members',
            formatReadonly: data => {
              var notes = data.parentValue as ListNoteItem;
              return (
                <div>
                  {notes.staffMembers.map((a, i) => (
                    <Link className="staff-link" key={a.id} to={`/people/staff-members/${a.id}`}>
                      {a.fullName}{' '}
                    </Link>
                  ))}
                </div>
              );
            },
            columnWidth: '30em',
          },
          {
            fieldType: FieldType.readonlyField,
            dataAddr: 'createdBy',
            label: 'Added by',
            columnWidth: '12em',
          },
        ],
        filterAction: {
          defaultValues: {
            dateFrom: today,
          },
          filterFields: Object.keys(filterFieldDefsLookup).map(k => filterFieldDefsLookup[k]),
          filterDef: _ => [
            {
              panes: [
                {
                  paneType: PaneType.formFieldsPane,
                  columnCount: 2,
                  fields: [filterFieldDefsLookup.dateFrom, filterFieldDefsLookup.dateTo],
                },
              ],
            },
          ],
        },
      };
    }
  );

  return <ListPage className="list-notes-component" data={notesModel.items} def={getPageDef()} />;
});

export default ListNotes;
