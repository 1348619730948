import { saveAs } from 'file-saver';
import { DateTime } from 'luxon';
import { observer } from 'mobx-react';
import { useEffect } from 'react';
import { useRootStore } from 'src/domain/entities/RootStoreModel';
import Page from 'src/views/components/Page';
import { IPageDef, PagePrimarySize, PaneType } from 'src/views/definitionBuilders/types';
import ReportEntry from './ReportEntry/ReportEntry';
import styles from './Reporting.module.scss';

type AssetItem = Common.Queries.Workshop.GetFleetAssetList.AssetItem;
type CustomerItem = Common.Dtos.CustomerItem;

const Reporting: React.FC = observer(() => {
  const rootStore = useRootStore();
  const assetsModel = rootStore.assets;
  const quotesModel = rootStore.operations.sales.quotes;
  const accountingModel = quotesModel.accounting;
  const fleetAssets = assetsModel.fleetAssetListItems;
  const decommissionedFleetAssets = assetsModel.fleetAssetIncludingDecommissionedListItems;
  const customersModel = rootStore.operations.sales.customer;
  const customers = rootStore.operations.sales.customer.customers;

  useEffect(() => {
    assetsModel.loadFleetAssets();
    customersModel.loadCustomers();
  }, []);

  const exportKmByJobTypeReportTablets = (
    from: string,
    to: string,
    vehicles: AssetItem[],
    isDetailed: boolean
  ) => {
    let fromDate = DateTime.fromFormat(from, 'dd/MM/yyyy');
    let toDate = DateTime.fromFormat(to, 'dd/MM/yyyy');
    let vehiclesIds = vehicles && vehicles.map(x => x.id);

    const fileName = `KMs-by-JobType_tablets_${DateTime.local().toFormat('yyyyMMddHHmm')}.xlsx`;
    return accountingModel
      .exportToExcel(
        fromDate.isValid ? fromDate : undefined,
        toDate.isValid ? toDate : undefined,
        vehiclesIds,
        isDetailed
      )
      .then(blob => saveAs(blob, fileName));
  };

  const exportKmByJobTypeReportKiosks = (
    from: string,
    to: string,
    vehicles: AssetItem[],
    isDetailed: boolean
  ) => {
    let fromDate = DateTime.fromFormat(from, 'dd/MM/yyyy');
    let toDate = DateTime.fromFormat(to, 'dd/MM/yyyy');
    let vehiclesIds = vehicles && vehicles.map(x => x.id);

    const fileName = `KMs-by-JobType_kiosks_${DateTime.local().toFormat('yyyyMMddHHmm')}.xlsx`;
    return accountingModel
      .exportToExcel(
        fromDate.isValid ? fromDate : undefined,
        toDate.isValid ? toDate : undefined,
        vehiclesIds,
        isDetailed,
        true
      )
      .then(blob => saveAs(blob, fileName));
  };

  const exportCentsPerKmReport = (
    from: string,
    to: string,
    vehicles: AssetItem[],
    customers: CustomerItem[]
  ) => {
    const fromDate = DateTime.fromString(from, 'dd/MM/yyyy');
    const toDate = DateTime.fromString(to, 'dd/MM/yyyy');
    const vehiclesIds = vehicles && vehicles.map(x => x.id);
    const customerIds = customers && customers.map(customer => customer.customerId);

    const fileName = `Cents-Per-KM_${DateTime.local().toFormat('yyyyMMddHHmm')}.xlsx`;
    return accountingModel
      .exportCentsPerKmType(
        fromDate.isValid ? fromDate : undefined,
        toDate.isValid ? toDate : undefined,
        vehiclesIds,
        customerIds
      )
      .then(blob => saveAs(blob, fileName));
  };

  const getPageDef = (): IPageDef => {
    return {
      primarySize: PagePrimarySize.twoThirds,
      primarySection: {
        title: 'Reporting',
        panels: [
          {
            panes: [
              {
                paneType: PaneType.customPane,
                render: () => {
                  return (
                    <>
                      <ul>
                        <ReportEntry
                          exportAction={s =>
                            exportKmByJobTypeReportTablets(s.from, s.to, s.vehicles, s.isDetailed)
                          }
                          title="Export Kms By Job Type - Tablets"
                          fleetAssets={decommissionedFleetAssets}
                          type="kms"
                        />
                      </ul>
                      <ul>
                        <ReportEntry
                          exportAction={s =>
                            exportKmByJobTypeReportKiosks(s.from, s.to, s.vehicles, s.isDetailed)
                          }
                          title="Export Kms By Job Type - Kiosks"
                          fleetAssets={fleetAssets}
                          type="kms"
                        />
                      </ul>
                      <ul>
                        <ReportEntry
                          exportAction={s =>
                            exportCentsPerKmReport(s.from, s.to, s.vehicles, s.customers)
                          }
                          title="Export Cents Per KM"
                          fleetAssets={fleetAssets}
                          customers={customers}
                          type="cents"
                        />
                      </ul>
                    </>
                  );
                },
              },
            ],
          },
        ],
      },
    };
  };

  return <Page className={styles.reporting} def={getPageDef()} />;
});

export default Reporting;
