import { IAjax } from 'src/infrastructure/ajax';
import { kioskUrls } from 'src/domain/services/apiUrls';

type DriverNote = People.Domain.Queries.ListNotesForDriver.DriverNote;

export default function init(ajax: IAjax) {
  function loadNotesForDriver(date: string) {
    return ajax
      .get(kioskUrls.noteUrls.loadNotesForDriver(date))
      .toPromise()
      .then<DriverNote[]>(r => r.response);
  }

  function markNotesReadForDriverOnDay(date: string) {
    return ajax
      .put(kioskUrls.noteUrls.markNotesReadForDriverOnDay(date))
      .toPromise()
      .then(r => r.response);
  }

  return {
    notes: {
      loadNotesForDriver,
      markNotesReadForDriverOnDay,
    },
  };
}
