import { Component } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { PagePrimarySize, PaneType, FieldType } from 'src/views/definitionBuilders/types';
import CrudPage, { ICrudPageDef } from 'src/views/components/Page/pages/CrudPage';
import { IWeekBounds } from 'src/views/components/Page/fields/WeekSelectField';
import Omit from 'src/infrastructure/omit';
import { getDays } from 'src/views/routes/operations/urban/urbanHelpers';
import { Weekday } from 'src/domain';
import getRosterDiffPaneDef from 'src/views/routes/workshop/rosters/shared/getRosterDiffPaneDef';

type RosterChangesItem = Workshop.Domain.Queries.Roster.GetRosterChanges.RosterChangesItem;
type RosterChangesConsolidatedShiftItem = Workshop.Domain.Queries.Roster.GetRosterChanges.RosterChangesItem.RosterChangesConsolidatedShiftItem;

export interface IRosterChanges {
  loadRosterChanges: (id: string) => Promise<void>;
  rosterChanges: RosterChangesItem | undefined;
}

interface IRosterChangesRouteParams {
  id: string;
}

type IMaintainRosterData = Omit<
  RosterChangesItem,
  'templateVsOriginalRoster' | 'templateVsCurrentRoster | originalVsCurrentRoster'
> & {
  rosterWeek: IWeekBounds;
  templateVsOriginalRoster: (RosterChangesConsolidatedShiftItem & {
    days: Weekday[];
  })[];
  originalVsCurrentRoster: (RosterChangesConsolidatedShiftItem & {
    days: Weekday[];
  })[];
  templateVsCurrentRoster: (RosterChangesConsolidatedShiftItem & {
    days: Weekday[];
  })[];
};

type InternalProps = IRosterChanges & RouteComponentProps<IRosterChangesRouteParams>;

class RosterChanges extends Component<InternalProps> {
  private get RosterId() {
    return this.props.match.params.id;
  }

  private rosterChangesData(roster: RosterChangesItem | undefined): IMaintainRosterData {
    if (!roster) {
      return {} as IMaintainRosterData;
    }

    return {
      ...roster,
      rosterWeek: {
        weekStart: roster.weekStart,
        weekEnd: roster.weekEnd,
      },
      templateVsOriginalRoster: roster.templateVsOriginalRoster.map(x => {
        return {
          ...x,
          days: getDays(x),
        };
      }),
      originalVsCurrentRoster: roster.originalVsCurrentRoster.map(x => {
        return {
          ...x,
          days: getDays(x),
        };
      }),
      templateVsCurrentRoster: roster.templateVsCurrentRoster.map(x => {
        return {
          ...x,
          days: getDays(x),
        };
      }),
    };
  }

  private readonly getPageDef = (): ICrudPageDef => {
    return {
      primarySize: PagePrimarySize.threeQuarters,
      primarySection: {
        title: 'Roster Changes',
        panels: [
          {
            panes: [
              {
                paneType: PaneType.formFieldsPane,
                columnCount: 2,
                fields: [
                  {
                    fieldType: FieldType.weekSelectField,
                    label: 'Week',
                    dataAddr: 'rosterWeek',
                    onNext: () => undefined,
                    onPrevious: () => undefined,
                    readonly: true,
                  },
                  {
                    fieldType: FieldType.textField,
                    label: 'Roster Template',
                    dataAddr: 'rosterTemplateName',
                    readonly: true,
                  },
                ],
              },
            ],
          },
          {
            title: 'Template vs Original Roster',
            panes: [getRosterDiffPaneDef('templateVsOriginalRoster')],
          },
          {
            title: 'Original vs Current Roster',
            panes: [getRosterDiffPaneDef('originalVsCurrentRoster')],
          },
          {
            title: 'Template vs Current Roster',
            panes: [getRosterDiffPaneDef('templateVsCurrentRoster')],
          },
        ],
      },
    };
  };

  render() {
    const { loadRosterChanges, rosterChanges } = this.props;
    return (
      <CrudPage
        def={api => this.getPageDef()}
        mode="view"
        onLoadData={() => loadRosterChanges(this.RosterId)}
        data={this.rosterChangesData(rosterChanges)}
      />
    );
  }
}

export default RosterChanges;
