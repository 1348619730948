import { ShiftType } from 'src/api/enums';
import { IAutocompleteResult } from 'src/domain/baseTypes';
import { IPanelDef } from '../../../../../../views/definitionBuilders/types/panel';
import getTripRoutesPaneDef from 'src/views/routes/operations/shared/getTripRoutesPaneDef';
import { IFormApiWithoutState } from 'src/views/components/Page/forms/base';
import { validateTimeIsNotLessThan } from 'src/infrastructure/dateUtils';

type States = Operations.Domain.AggregatesModel.BoardingPointAggregate.States;
type BoardingPointItem = Operations.Domain.Queries.GetBoardingPoint.BoardingPointItem;
type BoardingPointListItem = Operations.Domain.Queries.SearchBoardingPoint.BoardingPointListItem;
type UniqueNameCheckResultDto = Common.Dtos.UniqueNameCheckResultDto;
type CreateBoardingPointCommand = Operations.Domain.Commands.BoardingPoint.CreateBoardingPointCommand;

export default function getRoutesPanel(
  editable: boolean,
  states: States[],
  searchBoardingPoints: (search: string) => Promise<IAutocompleteResult<BoardingPointListItem>>,
  boardingPoints: BoardingPointItem[],
  getVehicleTypeAllocationData: (
    fromDate: string,
    toDate: string,
    vehicleTypeIds: string[],
    quoteIdToIgnore: string | undefined,
    depotId: number
  ) => Promise<void>,
  checkForUniqueBoardingPointName: (name: string) => Promise<UniqueNameCheckResultDto>,
  onCreateBoardingPoint: (command: CreateBoardingPointCommand) => Promise<string>,
  primaryFormApi: IFormApiWithoutState | undefined,
  depotId: number
): IPanelDef {
  const panel: IPanelDef = {
    hidden: data => {
      return !(
        data.panelValue.shiftType && data.panelValue.shiftType.id === ShiftType.ContractCharter
      );
    },
    panes: [
      getTripRoutesPaneDef('routes', {
        editable: editable,
        states,
        initialArrival: 'initialArrivalOptional',
        searchBoardingPoints,
        boardingPoints,
        getVehicleTypeAllocationData,
        checkForUniqueBoardingPointName,
        onCreateBoardingPoint,
        onArriveChange: (value, row) => {
          var addr = row === 'first' ? 'onSite' : 'shiftEnd';
          primaryFormApi && primaryFormApi.setValue(addr, value && value.toFormat('HH:mm'));
        },
        onDepartChange: (value, row) => {
          if (row === 'first') {
            primaryFormApi &&
              primaryFormApi.setValue('shiftCommence', value && value.toFormat('HH:mm'));
          }
        },
        onArriveValidate: (data, datetime, row) => {
          const departDepot = primaryFormApi && primaryFormApi.getValue('departDepot');
          if (row === 'first' && datetime && departDepot) {
            return validateTimeIsNotLessThan(
              datetime.toFormat('HH:mm'),
              'On site',
              departDepot,
              'depart depot'
            );
          }
          return undefined;
        },
        timeOnly: true,
        depotId: depotId,
      }),
    ],
  };

  return panel;
}
