import { IAjax } from 'src/infrastructure/ajax';
import { operationsUrls } from 'src/domain/services/apiUrls';

type DailySummarySheetDetailsDto = Operations.Domain.Queries.ListDailySummarySheetDetails.DailySummarySheetDetailsDto;
type AllocateStaffMemberCommand = Operations.Domain.Commands.Job.AllocateStaffMember.AllocateStaffMemberCommand;
type AllocateStaffMemberResult = Operations.Domain.Commands.Job.AllocateStaffMember.AllocateStaffMemberResult;
type CreateAdhocJobCommand = Operations.Domain.Commands.Job.CreateAdhocJob.CreateAdhocJobCommand;
type CreateAdhocJobResult = Operations.Domain.Commands.Job.CreateAdhocJob.CreateAdhocJobResult;
type UpdateJobCommand = Operations.Domain.Commands.Job.UpdateJob.UpdateJobCommand;
type UpdateJobResult = Operations.Domain.Commands.Job.UpdateJob.UpdateJobResult;
type GetPrefilledTimesheetQuery = Common.Queries.Operations.GetPrefilledTimesheet.GetPrefilledTimesheetQuery;
type PrefilledTimesheetItem = Common.Dtos.PrefilledTimesheetItem;
type JobConflicts = Operations.Domain.Queries.ViewJob.JobConflicts;
type SwapVehicleCommand = Operations.Domain.Commands.Job.SwapVehicleCommand;
type JobForRailBookingDropdown = Operations.Domain.Queries.GetExistingJobsForRailBookingDropdown.JobForRailBookingDropdown;
type UpdateScheduledBreakResult = Operations.Domain.Commands.Job.UpdateJobScheduledBreak.UpdateScheduledBreakResult;

export default function init(ajax: IAjax) {
  function listJobs(query: Partial<Operations.Domain.Queries.ListJobs.ListJobsQuery>) {
    return ajax
      .get(operationsUrls.jobUrls.listJobs(query))
      .map(
        r => r.response as Common.Dtos.ListResult<Operations.Domain.Queries.ListJobs.ListJobItem>
      );
  }

  function listJobsForCharter(
    query: Partial<Operations.Domain.Queries.ListJobsForCharter.ListJobsForCharterQuery>
  ) {
    return ajax
      .get(operationsUrls.jobUrls.listJobsForCharter(query))
      .map(
        r =>
          r.response as Common.Dtos.ListResult<
            Operations.Domain.Queries.ListJobsForCharter.ListJobItem
          >
      );
  }

  function listJobsForAllocations(
    query: Partial<Operations.Domain.Queries.ListJobsForAllocations.ListJobsForAllocationsQuery>
  ) {
    return ajax
      .get(operationsUrls.jobUrls.listJobsForAllocations(query))
      .map(
        r =>
          (r.response as Common.Dtos.ListResult<
            Operations.Domain.Queries.ListJobsForAllocations.JobItem
          >).items
      );
  }

  function getExternalItemsForAllocations(
    query: Partial<
      Operations.Domain.Queries.GetExternalItemsForAllocations.GetExternalItemsForAllocationsQuery
    >
  ) {
    return ajax
      .get(operationsUrls.jobUrls.getExternalItemsForAllocations(query))
      .map(
        r => r.response as Operations.Domain.Queries.GetExternalItemsForAllocations.ExternalItems
      );
  }

  function getWarnings(query: Operations.Domain.Queries.GetJobWarnings.GetJobWarningsQuery) {
    return ajax
      .get(operationsUrls.jobUrls.getWarnings(query))
      .map(
        r => (r.response as Operations.Domain.Queries.GetJobWarnings.GetJobWarningsResult).warnings
      );
  }

  function createAdhocJob(command: CreateAdhocJobCommand) {
    return ajax
      .post(operationsUrls.jobUrls.createAdhocJob(), command)
      .toPromise()
      .then<CreateAdhocJobResult>(r => r.response);
  }

  function updateJob(command: UpdateJobCommand) {
    return ajax
      .put(operationsUrls.jobUrls.updateJob(command.id), command)
      .toPromise()
      .then<UpdateJobResult>(r => r.response);
  }

  function splitJob(command: Operations.Domain.Commands.Job.SplitJob.SplitJobCommand) {
    return ajax
      .post(operationsUrls.jobUrls.splitJob(command.jobId), command)
      .toPromise()
      .then(r => r.response);
  }

  function deleteJob(jobId: string, forceDelete: boolean | undefined) {
    return ajax.httpDelete(operationsUrls.jobUrls.deleteJob(jobId, forceDelete)).toPromise();
  }

  function duplicateJob(command: Operations.Domain.Commands.Job.DuplicateJob.DuplicateJobCommand) {
    return ajax
      .post(operationsUrls.jobUrls.duplicateJob(command.jobId), command)
      .toPromise()
      .then<string>(r => r.response);
  }

  function cancelJob(jobId: string) {
    return ajax.post(operationsUrls.jobUrls.cancelJob(jobId)).toPromise();
  }

  function dismissProgressWarning(jobId: string) {
    return ajax.post(operationsUrls.jobUrls.dismissProgressWarning(jobId)).toPromise();
  }

  function viewJob(jobId: string) {
    return ajax
      .get(operationsUrls.jobUrls.viewJob(jobId))
      .toPromise()
      .then<Operations.Domain.Queries.ViewJob.JobItem>(r => r.response);
  }

  function updateProgress(
    command: Operations.Domain.Commands.Job.UpdateJobProgress.UpdateJobProgressCommand
  ) {
    return ajax.put(operationsUrls.jobUrls.updateProgress(command.jobId), command).toPromise();
  }

  function updateScheduledBreaks(
    command: Operations.Domain.Commands.Job.UpdateJobScheduledBreak.UpdateJobScheduledBreakCommand
  ) {
    return ajax
      .put(operationsUrls.jobUrls.updateScheduledBreaks(command.jobId), command)
      .toPromise()
      .then<UpdateScheduledBreakResult>(r => r.response);
  }

  function getPreviousAndNextProgresses(jobId: string) {
    return ajax
      .get(operationsUrls.jobUrls.getPreviousAndNextProgresses(jobId))
      .toPromise()
      .then<Common.Queries.GetPreviousAndNextJobProgress.PreviousAndNextJobProgressResult>(
        r => r.response
      );
  }

  function getProgress(jobId: string) {
    return ajax
      .get(operationsUrls.jobUrls.getProgress(jobId))
      .toPromise()
      .then<Operations.Domain.Queries.GetJobProgress.JobProgressResult[]>(r => r.response);
  }

  function getScheduledBreaks(jobId: string) {
    return ajax
      .get(operationsUrls.jobUrls.getScheduledBreaks(jobId))
      .toPromise()
      .then<Operations.Domain.Queries.GetJobScheduledBreaks.JobScheduledBreaksResult>(
        r => r.response
      );
  }

  function allocateStaffMember(command: AllocateStaffMemberCommand) {
    return ajax
      .post(operationsUrls.jobUrls.allocateStaffMember(command.jobId), command)
      .toPromise()
      .then<AllocateStaffMemberResult>(r => r.response);
  }

  function allocateAsset(
    command: Operations.Domain.Commands.Job.AllocateAsset.AllocateAssetCommand
  ) {
    return ajax.post(operationsUrls.jobUrls.allocateAsset(command.jobId), command).toPromise();
  }

  function exportJobsToExcel(query: Partial<Operations.Domain.Queries.ListJobs.ListJobsQuery>) {
    return ajax
      .getFile(operationsUrls.jobUrls.exportJobsToExcel(query))
      .toPromise()
      .then<Blob>(r => r.response);
  }

  function exportVehicleReallocationsToExcel(
    query: Partial<Operations.Domain.Queries.ListJobs.ListJobsQuery>
  ) {
    return ajax
      .getFile(operationsUrls.jobUrls.exportVehicleReallocationsToExcel(query))
      .toPromise()
      .then<Blob>(r => r.response);
  }

  function exportJobHoursToExcel(query: Partial<Operations.Domain.Queries.ListJobs.ListJobsQuery>) {
    return ajax
      .getFile(operationsUrls.jobUrls.exportJobHoursToExcel(query))
      .toPromise()
      .then<Blob>(r => r.response);
  }

  function exportTripsTakenToExcel(
    query: Partial<Operations.Domain.Queries.ExportTripsTakenToExcel.TripsTakenQuery>
  ) {
    return ajax
      .getFile(operationsUrls.jobUrls.exportTripsTakenToExcel(query))
      .toPromise()
      .then<Blob>(r => r.response);
  }

  function exportJobWithPaidHoursToExcel(
    query: Partial<Operations.Domain.Queries.ListJobs.ListJobsQuery>
  ) {
    return ajax
      .getFile(operationsUrls.jobUrls.exportJobWithPaidHoursToExcel(query))
      .toPromise()
      .then<Blob>(r => r.response);
  }

  function exportJobsToPdf(
    query: Partial<Operations.Domain.Queries.ListJobs.ListJobsQuery>,
    byDate: Boolean
  ) {
    if (byDate)
      return ajax
        .getFile(operationsUrls.jobUrls.exportJobsToPdf(query))
        .toPromise()
        .then<Blob>(r => r.response);
    else var foo: Partial<Operations.Domain.Queries.ListJobs.ListJobsByDriverQuery>;
    foo = query;
    return ajax
      .getFile(operationsUrls.jobUrls.exportJobsByDriverToPdf(foo))
      .toPromise()
      .then<Blob>(r => r.response);
  }

  function exportCharterJobsToPdf(
    query: Partial<Operations.Domain.Queries.ListJobsForCharter.ListJobsForCharterQuery>
  ) {
    return ajax
      .getFile(operationsUrls.jobUrls.exportCharterJobsToPdf(query))
      .toPromise()
      .then<Blob>(r => r.response);
  }

  function getPrefilledTimesheet(query: GetPrefilledTimesheetQuery) {
    return ajax
      .get(operationsUrls.jobUrls.getPrefilledTimesheet(query))
      .toPromise()
      .then<PrefilledTimesheetItem>(r => r.response);
  }

  function listJobProgressWarnings(
    query: Partial<Operations.Domain.Queries.ListJobProgressWarnings.ListJobProgressWarningsQuery>
  ) {
    return ajax
      .get(operationsUrls.jobUrls.listJobProgressWarnings(query))
      .map(
        r =>
          r.response as Common.Dtos.ListResult<
            Operations.Domain.Queries.ListJobProgressWarnings.JobProgressWarningItem
          >
      );
  }

  function exportJobProgressWarningsToExcel(
    query: Partial<Operations.Domain.Queries.ListJobProgressWarnings.ListJobProgressWarningsQuery>
  ) {
    return ajax
      .getFile(operationsUrls.jobUrls.exportJobProgressWarningsToExcel(query))
      .toPromise()
      .then<Blob>(r => r.response);
  }

  function generateJobFatigueBreaks(jobId: string) {
    return ajax
      .post(operationsUrls.jobUrls.generateJobFatigueBreaks(jobId))
      .toPromise()
      .then<void>(r => r.response);
  }

  function getJobConflicts(jobId: string) {
    return ajax
      .get(operationsUrls.jobUrls.getJobConflicts(jobId))
      .toPromise()
      .then<JobConflicts>(r => r.response);
  }

  function getExistingJobsForRailBookingDropdown() {
    return ajax
      .get(operationsUrls.railBookingUrls.getExistingJobsForRailBookingDropdown())
      .toPromise()
      .then<JobForRailBookingDropdown>(r => r.response);
  }

  function swapVehicle(command: SwapVehicleCommand) {
    return ajax
      .post(operationsUrls.jobUrls.swapVehicle(command.existingJobId), command)
      .toPromise()
      .then<string>(r => r.response);
  }

  function listDriverJobs(
    query: Partial<
      Operations.Domain.Queries.ListDailySummarySheetDetails.ListDailySummarySheetDetailsQuery
    >
  ) {
    return ajax
      .get(operationsUrls.jobUrls.listDriverJobs(query))
      .toPromise()
      .then<DailySummarySheetDetailsDto>(r => r.response);
  }

  function restoreJob(jobId: string) {
    return ajax
      .post(operationsUrls.jobUrls.restoreJob(jobId))
      .toPromise()
      .then<string>(r => r.response);
  }

  function loadJobSubTypes() {
    return ajax
      .get(operationsUrls.jobUrls.loadJobSubTypes())
      .toPromise()
      .then<Operations.Domain.AggregatesModel.JobAggregate.JobSubType[]>(r => r.response);
  }
  return {
    jobs: {
      listJobs,
      listJobsForCharter,
      listJobsForAllocations,
      getExternalItemsForAllocations,
      getWarnings,
      getProgress,
      getPreviousAndNextProgresses,
      updateProgress,
      createAdhocJob,
      updateJob,
      splitJob,
      duplicateJob,
      cancelJob,
      viewJob,
      deleteJob,
      allocateStaffMember,
      allocateAsset,
      exportJobsToExcel,
      exportVehicleReallocationsToExcel,
      exportJobHoursToExcel,
      exportJobWithPaidHoursToExcel,
      exportJobsToPdf,
      exportCharterJobsToPdf,
      getPrefilledTimesheet,
      exportTripsTakenToExcel,
      listJobProgressWarnings,
      exportJobProgressWarningsToExcel,
      dismissProgressWarning,
      generateJobFatigueBreaks,
      getJobConflicts,
      swapVehicle,
      getExistingJobsForRailBookingDropdown,
      listDriverJobs,
      restoreJob,
      getScheduledBreaks,
      updateScheduledBreaks,
      loadJobSubTypes,
    },
  };
}
