import { PureComponent } from 'react';
import { InputGroup } from 'reactstrap';
import { DateTime, Duration } from 'luxon';
import { IFieldApi } from 'src/views/components/Page/forms/Field';
import { IFieldMeta, IFieldData, IDateTimeFieldDef } from 'src/views/definitionBuilders/types';
import { LabelledFormField } from 'src/views/components/forms';
import FocusWrapper from 'src/views/components/FocusWrapper';
import DateInput, {
  getEditingFormattedDateString,
  parseEditingFormattedDateString,
} from './subfields/DateInput';
import TimeInput from './subfields/TimeInput';
import {
  parseEditingFormattedTimeString,
  getEditingFormattedTimeString,
} from './subfields/TimeHelpers';
import { getTimezoneCommonName } from 'src/views/components/DateTimeFormat/TimezoneFormat';

interface IDateTimePageFieldProps {
  fieldDef: IDateTimeFieldDef;
  fieldMeta: IFieldMeta;
  fieldData: IFieldData<string>;
  fieldApi: IFieldApi;
}

interface IDateTimePageFieldState {
  prevFieldValue: string | undefined; // held in ISO datetime format
  externalValue: string | undefined; // held in ISO datetime format
  internalDateValue: string; // Held in local zone
  internalTimeValue: string; // Held in local zone
}

class DateTimePageField extends PureComponent<IDateTimePageFieldProps, IDateTimePageFieldState> {
  static getDerivedStateFromProps(
    nextProps: Readonly<IDateTimePageFieldProps>,
    prevState: IDateTimePageFieldState
  ) {
    const { fieldData } = nextProps;
    // If the external version of this field is different to what we have, then update using the external value
    // (the react-form value is king, so that external logic can update this field)
    if (fieldData.fieldValue !== prevState.prevFieldValue) {
      // If the external value changed to a different value that this control has, then reset
      // the internal state to sync up. Otherwise do nothing
      // (Eg: so that "2" isn't changed to a full date)
      const internalStateUpdate =
        prevState.externalValue === fieldData.fieldValue
          ? {}
          : DateTimePageField.getInternalStateFromExternal(
              fieldData.fieldValue,
              nextProps.fieldDef.timezone,
              nextProps.fieldDef.showSeconds
            );

      return {
        prevFieldValue: fieldData.fieldValue,
        externalValue: fieldData.fieldValue,
        ...internalStateUpdate,
      };
    }
    return null;
  }

  private static getInternalStateFromExternal(
    fieldValue: string | undefined,
    timezone?: string,
    showSeconds?: boolean
  ) {
    // The fieldValue string should be an ISO date or datetime - assume utc unless specified in the string
    const date = fieldValue
      ? DateTime.fromISO(fieldValue, { zone: 'utc' }).setZone(timezone || 'local')
      : undefined;
    const internalDateValue =
      date && date.isValid ? getEditingFormattedDateString(date, timezone) : fieldValue || '';
    const internalTimeValue =
      date && date.isValid ? getEditingFormattedTimeString(date, timezone, showSeconds) : '';
    return {
      internalDateValue,
      internalTimeValue,
    };
  }

  constructor(props: IDateTimePageFieldProps) {
    super(props);
    const { fieldData } = props;
    this.state = {
      prevFieldValue: fieldData.fieldValue,
      externalValue: fieldData.fieldValue,
      ...DateTimePageField.getInternalStateFromExternal(
        fieldData.fieldValue,
        props.fieldDef.timezone,
        props.fieldDef.showSeconds
      ),
    };
  }

  private readonly handleFieldBlur = () => {
    this.props.fieldApi.setTouched(true);
    this.props.fieldMeta.onBlur && this.props.fieldMeta.onBlur(this.state.externalValue);
  };

  private readonly handleInternalDateChange = (newDateValue: string) => {
    this.handleInternalChange(newDateValue, this.state.internalTimeValue);
  };

  private readonly handleInternalTimeChange = (newTimeValue: string) => {
    this.handleInternalChange(this.state.internalDateValue, newTimeValue);
  };

  private isTimeGreaterThan24Hours(time: Duration | undefined) {
    const minutesInDay = 1440;
    return !!time && time.as('minutes') >= minutesInDay;
  }

  private readonly handleInternalChange = (dateValue: string, timeValue: string) => {
    const date = parseEditingFormattedDateString(dateValue, this.props.fieldDef.timezone);
    const timeDuration = parseEditingFormattedTimeString(
      timeValue,
      this.props.fieldDef.showSeconds
    );
    const time = this.isTimeGreaterThan24Hours(timeDuration) ? undefined : timeDuration;
    const externalValue =
      date && time && date.isValid && time.isValid
        ? DateTime.fromObject({
            year: date.year,
            month: date.month,
            day: date.day,
            hour: time.hours,
            minute: time.minutes,
            second: time.seconds,
            zone: this.props.fieldDef.timezone || 'local',
          }).toISO()
        : '';
    this.setState(
      {
        internalDateValue: dateValue,
        internalTimeValue: timeValue,
        externalValue,
      },
      () => this.props.fieldApi.setValue(externalValue)
    );
  };

  private readonly getFieldValueAsDateTime = (
    date: DateTime | undefined,
    time: Duration | undefined
  ) => {
    return date && time && date.isValid && time.isValid
      ? DateTime.fromObject({
          year: date.year,
          month: date.month,
          day: date.day,
          hour: time.hours,
          minute: time.minutes,
          second: time.seconds,
          zone: this.props.fieldDef.timezone || 'local',
        })
      : undefined;
  };

  private readonly getDefaultReadonlyValue = (
    date: DateTime | undefined,
    time: Duration | undefined
  ) => {
    const datetime = this.getFieldValueAsDateTime(date, time);
    const datetimeInZone = datetime?.isValid
      ? datetime.setZone(this.props.fieldDef.timezone || 'local')
      : null;

    const format = this.props.fieldDef.showSeconds
      ? DateTime.DATETIME_MED_WITH_SECONDS
      : DateTime.DATETIME_MED;

    return datetimeInZone
      ? `${datetimeInZone.toLocaleString(format)} ${getTimezoneCommonName(datetimeInZone, true)}`
      : '';
  };

  render() {
    const { fieldApi, fieldDef: def, fieldMeta: meta, fieldData: data } = this.props;
    const { internalDateValue, internalTimeValue } = this.state;
    const { error, touched } = fieldApi;

    const date = parseEditingFormattedDateString(internalDateValue, def.timezone);
    const time = parseEditingFormattedTimeString(internalTimeValue);
    const internalIsInvalid = !!(
      date &&
      time &&
      (!date.isValid || !time.isValid || this.isTimeGreaterThan24Hours(time))
    );
    const errorText = internalIsInvalid ? 'The date and time is invalid' : error;
    const labelText = typeof def.label === 'function' ? def.label(data) : def.label;
    const tooltipText = typeof def.tooltip === 'function' ? def.tooltip(data) : def.tooltip;

    return (
      <LabelledFormField
        className="date-page-field-component"
        readonly={meta.readonly}
        readonlyValue={
          def.formatReadonly ? def.formatReadonly(data) : this.getDefaultReadonlyValue(date, time)
        }
        readonlyLinkTo={def.linkTo && !internalIsInvalid ? def.linkTo(data) : undefined}
        mandatory={meta.mandatory}
        hideLabel={meta.hideLabel}
        labelValue={labelText}
        tooltipValue={tooltipText}
        noForm={meta.noForm}
        error={touched && errorText}>
        <FocusWrapper onBlur={this.handleFieldBlur}>
          <InputGroup>
            <DateInput
              autoFocus={meta.autoFocus}
              displayAsInvalid={touched && !!errorText}
              value={internalDateValue}
              onChange={this.handleInternalDateChange}
              timezone={def.timezone}
            />
            <TimeInput
              value={internalTimeValue}
              onChange={this.handleInternalTimeChange}
              allowSeconds={def.showSeconds}
            />
          </InputGroup>
        </FocusWrapper>
      </LabelledFormField>
    );
  }
}

export default DateTimePageField;
