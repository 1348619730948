import { types, getRoot, flow } from 'mobx-state-tree';
import { IRootStoreModel } from 'src/domain/entities/RootStoreModel';
import { getAjax, NotificationType } from 'src/domain/services';

type CreateVehicleTypeCommand = Operations.Domain.Commands.VehicleType.CreateVehicleTypeCommand;
type UpdateVehicleTypeCommand = Operations.Domain.Commands.VehicleType.UpdateVehicleTypeCommand;
type VehicleTypeItem = Common.Dtos.VehicleTypeItem;

export const VehicleTypeModel = types
  .model('VehicleTypeModel', {
    vehicleType: types.maybe(types.frozen<VehicleTypeItem>()),
  })
  .actions(self => {
    const ajax = getAjax(self);
    const root = getRoot(self) as IRootStoreModel;

    const createVehicleType = flow(function*(command: CreateVehicleTypeCommand) {
      const id = yield ajax.operations.vehicleTypes.createVehicleType(command);
      root.notifications.addNotification(
        `Successfully created vehicle type ${command.description}`,
        { type: NotificationType.success }
      );
      self.vehicleType = undefined;
      root.history.push(`/sales/vehicle-types/${id}`);
    });

    const loadVehicleType = flow(function*(vehicleTypeId: string) {
      self.vehicleType = yield ajax.operations.vehicleTypes.viewVehicleType(vehicleTypeId);
    });

    const updateVehicleType = flow(function*(command: UpdateVehicleTypeCommand) {
      yield ajax.operations.vehicleTypes.updateVehicleType(command);
      yield loadVehicleType(command.vehicleTypeId);
      root.history.push(`/sales/vehicle-types`);
    });

    const checkForUniqueVehicleTypeDescription = flow(function*(
      description: string,
      isUpdating: boolean
    ) {
      return yield ajax.operations.vehicleTypes.checkForUniqueVehicleTypeDescription(
        description,
        isUpdating
      );
    });

    return {
      createVehicleType,
      loadVehicleType,
      updateVehicleType,
      checkForUniqueVehicleTypeDescription,
    };
  });
