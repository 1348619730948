import { IRootStoreModel } from 'src/domain/entities/RootStoreModel';
import { UiRoute } from 'src/domain/services/buildUiRoutes';
import { UiRouteName } from 'src/domain/baseTypes';

export default function buildUiRoutes(store: IRootStoreModel) {
  const authorisedDevicesList = new UiRoute(
    UiRouteName.operationsAuthorisedDevicesList,
    '/operations/authorised-devices',
    () => 'Authorised Devices',
    false,
    false,
    []
  );

  return {
    [UiRouteName.operationsAuthorisedDevicesList]: authorisedDevicesList,
  };
}
