import { WarnIcon } from 'src/images/icons';

type FatigueRuleReportItem = Operations.Domain.Queries.GetFatigueDetails.GetFatigueDetailsQueryResponse.FatigueRuleReportItem;

export const RuleNotSatisfied = (ruleReport: FatigueRuleReportItem) => {
  return ruleReport.actual < ruleReport.required;
};

const RuleWarningIcon: React.FC<{ ruleReport: FatigueRuleReportItem; title?: string }> = ({
  ruleReport,
  title,
}) => {
  return (
    <>
      {RuleNotSatisfied(ruleReport) ? (
        <WarnIcon color="orange" title={title || 'Rule not satisfied'} />
      ) : null}{' '}
    </>
  );
};

export default RuleWarningIcon;
