import ListSubcontractor, { IListSubcontractorProps } from './ListSubcontractor';
import inject from 'src/views/injectFromStore';

const Container = inject<IListSubcontractorProps>((allStores, p) => ({
  canManageSubcontractors: allStores.rootStore.account.isOperationsDepartmentMember,
  listSubcontractors: allStores.rootStore.operations.subcontractors.listItems,
  subcontractors: allStores.rootStore.operations.subcontractors.items.slice(),
  hasMoreData: allStores.rootStore.operations.subcontractors.hasMoreItems,
}))(ListSubcontractor);

export default Container;
