import { types, flow } from 'mobx-state-tree';
import { getAjax } from 'src/domain/services/storeEnvironment';

type AuditLogItem = Workshop.Domain.Queries.ListAuditLog.AuditLogItem;

export const AuditLogsModel = types
  .model('AuditLogsModel', {
    auditLogs: types.array(types.frozen<AuditLogItem>()),
  })
  .actions(self => {
    const ajax = getAjax(self);

    const loadAuditLogs = flow(function*(aggregateId: string, minimumVersion: number) {
      self.auditLogs = yield ajax.raw
        .get(`/api/workshop/audit-logs/${aggregateId}/${minimumVersion}`)
        .toPromise()
        .then<AuditLogItem[]>(r => r.response);
    });

    return {
      loadAuditLogs,
    };
  });
