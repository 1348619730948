import { IAjax } from 'src/infrastructure/ajax';
import { complianceUrls } from 'src/domain/services/apiUrls';

type ListReportableEventsQuery = Operations.Domain.Queries.ListReportableEvents.ListReportableEventsQuery;
type ReportableEvent = Operations.Domain.Queries.ListReportableEvents.ReportableEventDto;
type ReportableEventCategory = Operations.Domain.Queries.ListReportableEvents.ReportableEventCategoryDto;
type ReportableEventContract = Operations.Domain.Queries.ListAllReportableEventContracts.ReportableEventContractDto;
type CreateReportableEventCommand = Operations.Domain.Commands.ReportableEvent.CreateReportableEvent.CreateReportableEventCommand;
type UpdateReportableEventCommand = Operations.Domain.Commands.ReportableEvent.UpdateReportableEvent.UpdateReportableEventCommand;
type UpdateReportableEventVehicleInformationCommand = Operations.Domain.Commands.ReportableEvent.UpdateReportableEventVehicleInformation.UpdateReportableEventVehicleInformationCommand;
type UpdateReportableEventInternalInformationCommand = Operations.Domain.Commands.ReportableEvent.UpdateReportableEventInternalInformation.UpdateReportableEventInternalInformationCommand;

export default function init(ajax: IAjax) {
  function listReportableEvents(query: Partial<ListReportableEventsQuery>) {
    return ajax
      .get(complianceUrls.reportableEventUrls.listReportableEvents(query))
      .map(r => r.response as Common.Dtos.ListResult<ReportableEvent>);
  }

  function createReportableEvent(command: CreateReportableEventCommand) {
    return ajax
      .post(complianceUrls.reportableEventUrls.createReportableEvent, command)
      .toPromise()
      .then<string>(r => r.response);
  }

  function updateReportableEvent(command: UpdateReportableEventCommand) {
    return ajax
      .put(complianceUrls.reportableEventUrls.updateReportableEvent, command)
      .toPromise()
      .then<string>(r => r.response);
  }

  function updateReportableEventVehicleInformation(
    command: UpdateReportableEventVehicleInformationCommand
  ) {
    return ajax
      .put(complianceUrls.reportableEventUrls.updateReportableEventVehicleInformation, command)
      .toPromise()
      .then<string>(r => r.response);
  }

  function updateReportableEventInternalInformation(
    command: UpdateReportableEventInternalInformationCommand
  ) {
    return ajax
      .put(complianceUrls.reportableEventUrls.updateReportableEventInternalInformation, command)
      .toPromise()
      .then<string>(r => r.response);
  }

  function viewReportableEvent(id: string) {
    return ajax
      .get(complianceUrls.reportableEventUrls.viewReportableEvent(id))
      .toPromise()
      .then<ReportableEvent>(r => r.response);
  }

  function loadCategoriesForContract(reportableEventContractId: string) {
    return ajax
      .get(complianceUrls.reportableEventUrls.loadCategoriesForContract(reportableEventContractId))
      .toPromise()
      .then<ReportableEventCategory[]>(r => r.response);
  }

  function loadAllContracts() {
    return ajax
      .get(complianceUrls.reportableEventUrls.loadAllContracts)
      .toPromise()
      .then<ReportableEventContract[]>(r => r.response);
  }

  return {
    reportableEvent: {
      listReportableEvents,
      createReportableEvent,
      updateReportableEvent,
      updateReportableEventVehicleInformation,
      updateReportableEventInternalInformation,
      viewReportableEvent,
      loadAllContracts,
      loadCategoriesForContract,
    },
  };
}
