import { types, flow } from 'mobx-state-tree';
import { getAjax } from 'src/domain/services/storeEnvironment';
import { DateTime } from 'luxon';
import * as queryString from 'query-string';

type DailyRunSheetItem = Workshop.Domain.Queries.GetDailyRunSheet.DailyRunSheetItem;

export const DailyRunSheetModel = types
  .model('KioskDailyRunSheetModel', {
    runSheetItem: types.maybe(types.frozen<DailyRunSheetItem>()),
  })
  .actions(self => {
    const ajax = getAjax(self);

    const loadDailyRunSheet = flow(function*(depotId: number, date?: DateTime) {
      self.runSheetItem = yield ajax.raw
        .get(
          `/api/kiosk/workshop/daily-runsheet?${queryString.stringify({
            depotId,
            date: date?.toISODate(),
          })}`
        )
        .toPromise()
        .then<DailyRunSheetItem>(r => r.response);
    });

    return {
      loadDailyRunSheet,
    };
  });
