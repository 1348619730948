import { DateTime } from 'luxon';
import { allBatchOperationStatus, BatchOperationStatus } from 'src/api/enums';
import { IListPageLoadDataRequest } from 'src/domain/baseTypes';
import { DownloadIcon } from 'src/images/icons';
import memoizeOne, { shallowMemoize } from 'src/infrastructure/memoizeOne';
import { DownloadAttachmentQuery } from 'src/views/components/Attachment/attachmentHelper';
import { ListPage } from 'src/views/components/Page';
import { IListPageDef } from 'src/views/components/Page/pages/ListPage';
import PrimaryTitle from 'src/views/components/Page/PrimaryTitle/PrimaryTitle';
import { TaskCardItem, toActionLinkDef } from 'src/views/components/TaskCard';
import {
  ActionType,
  FieldDefs,
  FieldType,
  PagePrimarySize,
  PaneType,
} from 'src/views/definitionBuilders/types';
import withQueryParams, { IQueryParamsProps } from 'src/views/hocs/withQueryParams';

type ListBulkDriverAuthorizationUpdateOperationsQuery = Operations.Domain.Queries.ListBulkDriverAuthorizationUpdateOperations.ListBulkDriverAuthorizationUpdateOperationsQuery;
type BulkDriverAuthorizationUpdateOperationListItem = Operations.Domain.Queries.ListBulkDriverAuthorizationUpdateOperations.BulkDriverAuthorizationUpdateOperationListItem;

const getPrimaryFields = (
  downloadAttachmentForBatch: (query: DownloadAttachmentQuery) => Promise<void>
): IListPageDef['primaryFields'] => {
  return [
    {
      fieldType: FieldType.textField,
      dataAddr: 'bulkDriverAuthorizationUpdateOperationNumber',
      label: 'Number',
      columnWidth: '1em',
    },
    {
      fieldType: FieldType.textField,
      label: 'Created by',
      dataAddr: ['createdBy'],
    },
    {
      fieldType: FieldType.dateTimeField,
      label: 'Created on',
      dataAddr: ['createdOn'],
    },
    {
      fieldType: FieldType.textField,
      label: 'Status',
      dataAddr: ['batchOperationStatus', 'description'],
    },
    {
      fieldType: FieldType.readonlyField,
      label: 'Non Current Drivers',
      dataAddr: ['nonCurrentDriverAuthorizations'],
      formatReadonly: d => {
        const bulkDriverAuthorizationUpdateOperation = d.parentValue as BulkDriverAuthorizationUpdateOperationListItem;
        if (
          bulkDriverAuthorizationUpdateOperation.batchOperationStatus.id ===
          BatchOperationStatus.Succeeded
        ) {
          return <>{bulkDriverAuthorizationUpdateOperation.nonCurrentDriverAuthorizations}</>;
        }
        return undefined;
      },
    },
    {
      fieldType: FieldType.readonlyField,
      label: 'Fail Details',
      dataAddr: 'failReason',
      formatReadonly: d => {
        const bulkDaUpdateOperation = d.parentValue as BulkDriverAuthorizationUpdateOperationListItem;
        return <>{bulkDaUpdateOperation.failReason}</>;
      },
    },
    {
      fieldType: FieldType.actionListField,
      dataAddr: '',
      columnWidth: '1px',
      label: 'Actions',
      actionGroups: [
        {
          actions: [
            {
              actionType: ActionType.actionButton,
              label: `Download`,
              icon: <DownloadIcon />,
              onClick: api =>
                downloadAttachmentForBatch({
                  attachmentDetailsId: api.parentValue.attachmentId,
                  aggregateId: api.parentValue.id,
                  aggregateType: 'bulkdaoperation',
                }),
            },
          ],
        },
      ],
    },
  ];
};

export interface IListProcessDriverAuthorizationProps {
  listProcessDriverAuthorizations: (
    request: IListPageLoadDataRequest<ListBulkDriverAuthorizationUpdateOperationsQuery>
  ) => Promise<void>;
  downloadAttachmentForBatch: (query: DownloadAttachmentQuery) => Promise<void>;
  lastUpdated?: DateTime;
  bulkDriverAuthorizationUpdateOperations: BulkDriverAuthorizationUpdateOperationListItem[];
  hasMoreData: boolean;
}

type InternalProps = IListProcessDriverAuthorizationProps &
  IQueryParamsProps<ListBulkDriverAuthorizationUpdateOperationsQuery>;

const ListProcessDriverAuthorization: React.FC<InternalProps> = (props: InternalProps) => {
  const getFilterFieldDefs = shallowMemoize(() => {
    return {
      batchOperationStatus: {
        fieldType: FieldType.selectMultiField,
        dataAddr: 'statusIds',
        label: 'Status',
        valueKey: 'value',
        descriptionKey: 'description',
        optionItems: allBatchOperationStatus,
        useValueOnly: true,
      } as FieldDefs,
    };
  });

  const getRowKey: IListPageDef['rowKey'] = data => {
    return data.itemValue.id;
  };

  const getPageDef = memoizeOne(
    (
      listBulkDriverAuthorizationUpdateOperations: IListProcessDriverAuthorizationProps['listProcessDriverAuthorizations'],
      hasMoreData: boolean,
      primaryFieldDefinitions: IListPageDef['primaryFields']
    ): IListPageDef => {
      const filterFieldDefsLookup = getFilterFieldDefs();
      const topTasksActions = [TaskCardItem.operations.processResultsFile].map(toActionLinkDef);
      return {
        primaryTitle: <PrimaryTitle title="Process DA Results"></PrimaryTitle>,
        onLoadData: listBulkDriverAuthorizationUpdateOperations,
        externalSearch: true,
        primarySize: PagePrimarySize.threeQuarters,
        hasMoreData: hasMoreData,
        rowKey: getRowKey,
        primaryActions: [],
        primaryFields: primaryFieldDefinitions,
        secondarySections: [
          {
            title: 'Navigation',
            panels: [
              {
                title: 'Top Tasks',
                panes: [
                  {
                    paneType: PaneType.navListPane,
                    links: topTasksActions,
                  },
                ],
              },
            ],
          },
        ],
        filterAction: {
          filterFields: Object.keys(filterFieldDefsLookup).map(k => filterFieldDefsLookup[k]),
          filterDef: filterDefApi => [
            {
              panes: [
                {
                  paneType: PaneType.formFieldsPane,
                  fields: [filterFieldDefsLookup.batchOperationStatus],
                },
              ],
            },
          ],
        },
      };
    }
  );

  const {
    bulkDriverAuthorizationUpdateOperations,
    lastUpdated,
    listProcessDriverAuthorizations,
    hasMoreData,
    downloadAttachmentForBatch,
  } = props;

  const def = getPageDef(
    listProcessDriverAuthorizations,
    hasMoreData,
    getPrimaryFields(downloadAttachmentForBatch)
  );

  return (
    <ListPage
      className="list-generate-jobs-operations-component"
      data={bulkDriverAuthorizationUpdateOperations}
      def={def}
      autoRefresh
      lastUpdated={lastUpdated}
    />
  );
};

export default withQueryParams(ListProcessDriverAuthorization);
