import { PaneType, FieldType, ModalDefBuilder } from 'src/views/definitionBuilders/types';
import { IntervalFormat } from 'src/views/components/IntervalFormat';
import { DateTime } from 'luxon';
import { getSubmitCloseModalActionGroupDef } from 'src/views/definitionBuilders/common';

type JobSummaryDto = Workshop.Domain.Queries.Job.JobSummaryDto;
type ScheduleFutureJobTaskCommand = Workshop.Domain.Commands.JobTask.ScheduleFutureJobTaskCommand;
type WorkshopDepot = Common.Queries.Workshop.GetWorkshopDepots.WorkshopDepotDto;

const scheduleTypeItems = [
  { value: 'attach', label: 'Attach to Existing Job' },
  { value: 'create', label: 'Create New Job' },
];

interface IDisplayableJob {
  title: string;
}

export default function getScheduleJobTaskModalDef(
  onSchedule: (args: ScheduleFutureJobTaskCommand) => Promise<void>,
  assetJobs: Array<JobSummaryDto>,
  workshopDepots: Array<WorkshopDepot>,
  defaultWorkshopDepot: WorkshopDepot | undefined,
  jobTaskId: string,
  jobId?: string
): ModalDefBuilder {
  const filteredJobs = assetJobs.filter(j => j.jobId !== jobId);

  return () => ({
    title: 'Schedule Task',
    asForm: true,
    explicitData: {
      scheduleToggle: 'attach',
      depotId: defaultWorkshopDepot?.id,
    },
    panels: [
      {
        panes: [
          {
            paneType: PaneType.formFieldsPane,
            fields: [
              {
                fieldType: FieldType.toggleButtonField,
                dataAddr: 'scheduleToggle',
                optionItems: scheduleTypeItems,
                label: 'Choose an option',
                valueKey: 'value',
                descriptionKey: 'label',
                useValueOnly: true,
                mandatory: true,
              },
            ],
          },
          {
            paneType: PaneType.formFieldsPane,
            hidden: d => d.parentValue.scheduleToggle !== 'attach',
            fields: [
              {
                fieldType: FieldType.selectField,
                label: 'Selected Job',
                dataAddr: 'selectedJobId',
                valueKey: 'jobId',
                descriptionKey: 'jobNumber',
                useValueOnly: true,
                mandatory: true,
                optionItems: () =>
                  filteredJobs.map((x, _) => {
                    return {
                      ...x,
                      title: `Job Number: ${x.jobNumber}\u2003•\u2003${x.depotDescription}`,
                    };
                  }),
                optionRenderer: (j: JobSummaryDto & IDisplayableJob) => (
                  <div>
                    <div>
                      <strong>#{j.jobNumber}</strong>
                      <span>&emsp;•&emsp;{j.depotDescription}&emsp;•&emsp;</span>
                      <span>
                        <IntervalFormat startValue={j.startDateTime} endValue={j.endDateTime} />
                      </span>
                    </div>
                    <div style={{ whiteSpace: 'pre-wrap' }}>{j.jobDetails}</div>
                  </div>
                ),
                valueRenderer: (j: JobSummaryDto & IDisplayableJob) => (
                  <span>
                    <strong>#{j.jobNumber}</strong>
                    <span>&emsp;•&emsp;{j.depotDescription}&emsp;•&emsp;</span>
                    <span>
                      <IntervalFormat startValue={j.startDateTime} endValue={j.endDateTime} />
                    </span>
                  </span>
                ),
              },
            ],
          },
          {
            paneType: PaneType.formFieldsPane,
            hidden: d => d.parentValue.scheduleToggle !== 'create',
            columnCount: 2,
            fields: [
              {
                fieldType: FieldType.dateTimeField,
                label: 'Start',
                dataAddr: 'startDateTime',
                mandatory: true,
              },
              {
                fieldType: FieldType.dateTimeField,
                label: 'End',
                dataAddr: 'endDateTime',
                mandatory: true,
                validate: d =>
                  DateTime.fromISO(d.parentValue.endDateTime) <=
                  DateTime.fromISO(d.parentValue.startDateTime)
                    ? 'End must be after Start'
                    : undefined,
              },
            ],
          },
          {
            paneType: PaneType.formFieldsPane,
            hidden: d => d.parentValue.scheduleToggle !== 'create',
            columnCount: 1,
            fields: [
              {
                fieldType: FieldType.selectField,
                dataAddr: 'depotId',
                label: 'Depot',
                useValueOnly: true,
                valueKey: 'id',
                descriptionKey: 'description',
                mandatory: true,
                optionItems: workshopDepots,
                readonly: workshopDepots.length < 2,
              },
            ],
          },
        ],
      },
    ],
    secondaryActions: [getSubmitCloseModalActionGroupDef('Schedule')],
    onFormSubmit: (values: {
      startDateTime: string;
      endDateTime: string;
      selectedJobId: string;
      depotId: number;
    }) => {
      const args: ScheduleFutureJobTaskCommand = {
        jobTaskId: jobTaskId,
        jobId: values.selectedJobId,
        startDateTime: values.startDateTime,
        endDateTime: values.endDateTime,
        depotId: values.depotId,
      };
      return onSchedule(args);
    },
  });
}
