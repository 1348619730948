export function getFieldHtmlId(
  id: string | undefined,
  getFieldName: () => string | Array<string | number>
) {
  if (id) {
    return id;
  }
  const fieldName = getFieldName();
  return `field:${Array.isArray(fieldName) ? fieldName.join('.') : fieldName}`;
}

// tslint:disable-next-line:no-any
export function convertToString(x: any) {
  return typeof x === 'undefined' || x === null ? '' : (x.toString() as string);
}

export function getDefaultableBoolValue(value?: boolean, defaultValue?: boolean) {
  return value === true || value === false ? value : defaultValue;
}
