import { OpenBoxIcon, PauseIcon } from 'src/images/icons';
import { OnHoldStatus } from 'src/api/enums';

type TaskOnHoldStatus = Workshop.Domain.AggregatesModel.JobTaskAggregate.OnHoldStatus;

export const OnHoldIcon = ({
  onHoldStatus,
  className,
}: {
  onHoldStatus: TaskOnHoldStatus | number;
  className?: string;
}) => {
  const onHoldStatusId =
    typeof onHoldStatus === 'number'
      ? (onHoldStatus as number)
      : (onHoldStatus as TaskOnHoldStatus).id;

  return onHoldStatusId === OnHoldStatus.PartsBox ? (
    <div className={className}>
      <OpenBoxIcon />
    </div>
  ) : onHoldStatusId === OnHoldStatus.AwaitingParts ? (
    <div className={className}>
      <PauseIcon />
    </div>
  ) : null;
};
