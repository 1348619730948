import { IAjax } from 'src/infrastructure/ajax';
import {
  assetGroupsUrls,
  jobTaskUrls,
  checklistUrls,
  assetGroupUrls,
} from 'src/domain/services/apiUrls';

type AggregateUsageDto = Common.Dtos.AggregateUsageDto;
type SetAssetGroupActiveCommand = Workshop.Domain.Commands.AssetGroup.SetAssetGroupActiveCommand;
type SetAssetGroupInactiveCommand = Workshop.Domain.Commands.AssetGroup.SetAssetGroupInactiveCommand;
type ServiceCycleItem = Workshop.Domain.Queries.AssetGroup.GetAssetGroupFullServiceCycle.ServiceCycleItem;

// tslint:disable-next-line:no-empty
const returnVoid = () => {};

export default function init(ajax: IAjax) {
  function getJobTaskCategories() {
    return ajax
      .get(jobTaskUrls.loadJobTaskCategories())
      .toPromise()
      .then<Workshop.Domain.Queries.GetTaskCategories.JobTaskCategoryListItem[]>(r => r.response);
  }

  function getChecklists(includeInactive: boolean) {
    return ajax
      .get(checklistUrls.listChecklists(includeInactive))
      .toPromise()
      .then<Common.Dtos.ListResult<Workshop.Domain.Queries.GetChecklist.ChecklistDetails>>(
        r => r.response
      );
  }

  function createAssetGroup(command: Workshop.Domain.Commands.AssetGroup.CreateAssetGroupCommand) {
    return ajax
      .post(assetGroupUrls.createAssetGroup(), command)
      .toPromise()
      .then<string>(r => r.response);
  }

  function checkForUniqueName(name: string) {
    return ajax
      .get(assetGroupUrls.checkForUniqueName(name))
      .toPromise()
      .then<Common.Dtos.UniqueNameCheckResultDto>(r => r.response);
  }

  function listAssetGroups(includeInactiveAssetGroups: boolean) {
    return ajax
      .get(assetGroupsUrls.listAssetGroups(includeInactiveAssetGroups))
      .toPromise()
      .then<Workshop.Domain.Queries.AssetGroup.AssetGroupItem[]>(r => r.response);
  }

  function loadAssetGroup(id: string) {
    return ajax
      .get(assetGroupUrls.loadAssetGroup(id))
      .toPromise()
      .then<Workshop.Domain.Queries.AssetGroup.AssetGroupDetails>(r => r.response);
  }

  function loadAssetGroupForAsset(assetId: string) {
    return ajax
      .get(assetGroupUrls.loadAssetGroupForAsset(assetId))
      .toPromise()
      .then<Workshop.Domain.Queries.AssetGroup.AssetGroupDetails>(r => r.response);
  }

  function updateAssetGroup(command: Workshop.Domain.Commands.AssetGroup.UpdateAssetGroupCommand) {
    return ajax
      .put(assetGroupUrls.updateAssetGroup(command.assetGroupId), command)
      .toPromise()
      .then(r => returnVoid());
  }

  function loadAssetGroupFullServiceCycle(assetGroupId: string) {
    return ajax
      .get(assetGroupUrls.loadAssetGroupFullServiceCycle(assetGroupId))
      .toPromise()
      .then<ServiceCycleItem[]>(r => r.response);
  }

  function updateAssetGroupSetInactive(command: SetAssetGroupInactiveCommand) {
    return ajax
      .put(assetGroupUrls.deactivateAssetGroup(command.id), command)
      .toPromise()
      .then(r => r.response);
  }

  function updateAssetGroupSetActive(command: SetAssetGroupActiveCommand) {
    return ajax
      .put(assetGroupUrls.reactivateAssetGroup(command.id), command)
      .toPromise()
      .then(r => r.response);
  }

  function getAssetGroupUsage(assetGroupId: string) {
    return ajax
      .get(assetGroupUrls.getAssetGroupUsage(assetGroupId))
      .toPromise()
      .then<AggregateUsageDto>(r => r.response);
  }

  function deleteAssetGroup(assetGroupId: string) {
    return ajax
      .httpDelete(assetGroupUrls.deleteAssetGroup(assetGroupId))
      .toPromise()
      .then(r => r.response);
  }

  return {
    assetGroup: {
      getJobTaskCategories,
      getChecklists,
      createAssetGroup,
      checkForUniqueName,
      loadAssetGroup,
      loadAssetGroupForAsset,
      updateAssetGroup,
      loadAssetGroupFullServiceCycle,
      getAssetGroupUsage,
      updateAssetGroupSetInactive,
      updateAssetGroupSetActive,
      deleteAssetGroup,
    },

    assetGroups: {
      listAssetGroups,
    },
  };
}
