import { types, flow } from 'mobx-state-tree';
import { getAjax } from 'src/domain/services';
import { peopleUrls } from 'src/domain/services/apiUrls';
import { SkillSpecsForRequirementsModel } from './SkillSpecsForRequirementsModel';

type SkillSpecItem = People.Domain.Queries.GetSkillSpecs.SkillSpecItem;
type UpdateSkillSpecsCommand = People.Domain.Commands.StaffMembers.SkillSpecs.UpdateSkillSpecsCommand;

export const SkillSpecsModel = types
  .model('SkillSpecsModel', {
    items: types.array(types.frozen<SkillSpecItem>()),
    forRequirements: types.optional(SkillSpecsForRequirementsModel, {}),
  })
  .actions(self => {
    const ajax = getAjax(self);

    const getAll = flow(function*() {
      self.items = yield ajax.raw
        .get(peopleUrls.staffMemberUrls.skillSpecUrls.getAll())
        .toPromise()
        .then(r => r.response);
    });

    const updateAll = flow(function*(command: UpdateSkillSpecsCommand) {
      yield ajax.raw.put(peopleUrls.staffMemberUrls.skillSpecUrls.updateAll(), command).toPromise();
      yield getAll();
    });

    const clearSkillSpecRequirements = flow(function*(skillSpecId: number) {
      yield ajax.operations.requirements.clearSkillSpecRequirements(skillSpecId);
      yield getAll();
    });

    return {
      getAll,
      updateAll,
      clearSkillSpecRequirements,
    };
  });
