import styles from '../AssetDetails.module.scss';
import { AssetField } from './AssetField';
import { TechSpecDataType } from 'src/api/enums';
import { AssetDateField } from './AssetDateField';
import Spinner from 'src/views/components/Spinner';
import { useEffect, useState } from 'react';
import { nonStrictEqual } from 'src/infrastructure/nonStrictEqual';

type AssetDetailsData = Workshop.Domain.Queries.GetAsset.AssetDetails;
type TechSpecDropdownOptionsItem = Workshop.Domain.Queries.TechSpecs.TechSpecDropdownOptionsItem;

export interface IAssetTechSpecsDisplayProps {
  asset: AssetDetailsData | undefined;
  techSpecsDropdownOptions: TechSpecDropdownOptionsItem[] | undefined;
  loadTechSpecsDropdownOptions: () => Promise<void>;
}

export const AssetTechSpecsDisplay: React.FC<IAssetTechSpecsDisplayProps> = ({
  asset,
  techSpecsDropdownOptions,
  loadTechSpecsDropdownOptions,
}) => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    loadTechSpecsDropdownOptions().then(() => setIsLoading(false));
  }, []);

  if (!asset) {
    return <div>The asset details are not loaded yet.</div>;
  }

  const techSpecs = asset.techSpecs.filter(t => t.value !== null);

  return (
    <Spinner show={isLoading} className={styles.scrollableTabbedArea}>
      <div className={styles.techSpecs}>
        {isLoading ? (
          <div />
        ) : (
          <>
            {techSpecs && techSpecs.length > 0 ? (
              techSpecs.map(t => (
                <div className={styles.techSpec} key={t.id}>
                  {t.techSpecDataType === TechSpecDataType.Bool ? (
                    <AssetField
                      label={t.techSpecDescription}
                      text={nonStrictEqual(t.value, true) ? 'Yes' : 'No'}
                    />
                  ) : t.techSpecDataType === TechSpecDataType.Date ? (
                    <AssetDateField label={t.techSpecDescription} dateValue={t.value} />
                  ) : t.techSpecDataType === TechSpecDataType.Dropdown ? (
                    <AssetField
                      label={t.techSpecDescription}
                      text={
                        t.value === null ||
                        t.value === undefined ||
                        techSpecsDropdownOptions === undefined
                          ? 'Not set'
                          : techSpecsDropdownOptions
                              .find(x => x.techSpecId === t.techSpecId)!
                              .options.find(x => x.id === t.value)!.description
                      }
                    />
                  ) : (
                    <AssetField label={t.techSpecDescription} text={t.value} />
                  )}
                </div>
              ))
            ) : (
              <div>There are no tech specs defined for this asset.</div>
            )}
          </>
        )}
      </div>
    </Spinner>
  );
};
