import { DateTime } from 'luxon';
import { Interval } from 'luxon';
import { DateTimeFormatSettings } from '../DateTimeFormat/DateTimeFormat';

export interface IIntervalFormatProps {
  className?: string;
  startValue?: DateTime | string;
  endValue?: DateTime | string;
  includeYear?: boolean;
  excludeTime?: boolean;
  timezone?: string;
}

const IntervalFormat: React.FC<IIntervalFormatProps> = ({
  className,
  startValue,
  endValue,
  includeYear,
  excludeTime,
  timezone,
}) => {
  if (!startValue || !endValue) {
    return null;
  }

  if (typeof startValue === 'string') {
    startValue = DateTime.fromISO(startValue, { zone: timezone || 'local' });
  }

  if (typeof endValue === 'string') {
    endValue = DateTime.fromISO(endValue, { zone: timezone || 'local' });
  }

  const i = Interval.fromDateTimes(startValue, endValue);
  const sameDay = i.start.startOf('day').equals(i.end.startOf('day'));
  const startFormat: typeof DateTimeFormatSettings = {
    year: includeYear ? 'numeric' : undefined,
    month: 'short',
    day: 'numeric',
    hour: excludeTime ? undefined : 'numeric',
    minute: excludeTime ? undefined : '2-digit',
  };
  const format: typeof DateTimeFormatSettings = {
    year: !sameDay && includeYear ? 'numeric' : undefined,
    month: sameDay ? undefined : 'short',
    day: sameDay ? undefined : 'numeric',
    hour: excludeTime ? undefined : 'numeric',
    minute: excludeTime ? undefined : '2-digit',
  };
  return (
    <span className={className}>
      {i.start.toLocaleString(startFormat)}
      {sameDay && excludeTime ? null : ` - ${i.end.toLocaleString(format)}`}
    </span>
  );
};

export default IntervalFormat;
