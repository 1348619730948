import { IAjax } from 'src/infrastructure/ajax';
import { peopleUrls } from 'src/domain/services/apiUrls';

export default function init(ajax: IAjax) {
  function loadDrivers() {
    return ajax
      .get(peopleUrls.staffMemberUrls.loadDrivers())
      .toPromise()
      .then<Common.Dtos.StaffMemberDto[]>(r => {
        return r.response;
      });
  }

  function loadMechanics() {
    return ajax
      .get(peopleUrls.staffMemberUrls.loadMechanics())
      .toPromise()
      .then<Common.Dtos.StaffMemberDto[]>(r => {
        return r.response;
      });
  }

  function loadReportableEventContacts() {
    return ajax
      .get(peopleUrls.staffMemberUrls.loadReportableEventContacts())
      .toPromise()
      .then<Common.Dtos.StaffMemberNameDto[]>(r => {
        return r.response;
      });
  }

  function loadAllStaffMembers() {
    return ajax
      .get(peopleUrls.staffMemberUrls.loadAllStaffMembers())
      .toPromise()
      .then<Common.Dtos.StaffMemberDto[]>(r => {
        return r.response;
      });
  }

  function loadAllStaffMemberNames(
    query: Partial<Common.Queries.GetStaffMemberNames.GetStaffMemberNamesQuery>
  ) {
    return ajax
      .get(peopleUrls.staffMemberUrls.loadAllStaffMemberNames(query))
      .toPromise()
      .then<Common.Queries.GetStaffMemberNames.StaffMemberName[]>(r => {
        return r.response;
      });
  }

  function loadStaffMembers(
    query: Partial<People.Domain.Queries.ListStaffMembers.ListStaffMembersQuery>
  ) {
    return ajax
      .get(peopleUrls.staffMemberUrls.loadStaffMembers(query))
      .map(
        r =>
          r.response as Common.Dtos.ListResult<
            People.Domain.Queries.ListStaffMembers.StaffMemberItem
          >
      );
  }

  function createStaffMember(
    command: People.Domain.Commands.StaffMembers.CreateStaffMember.CreateStaffMemberCommand
  ) {
    return ajax
      .post(peopleUrls.staffMemberUrls.createStaffMember(), command)
      .toPromise()
      .then<string>(r => r.response);
  }

  function updateStaffMember(
    command: People.Domain.Commands.StaffMembers.UpdateStaffMember.UpdateStaffMemberCommand
  ) {
    return ajax.put(peopleUrls.staffMemberUrls.updateStaffMember(command.id), command).toPromise();
  }

  function markStaffMemberInactive(staffMemberId: string) {
    return ajax.put(peopleUrls.staffMemberUrls.markStaffMemberInactive(staffMemberId)).toPromise();
  }

  function markStaffMemberActive(staffMemberId: string) {
    return ajax.put(peopleUrls.staffMemberUrls.markStaffMemberActive(staffMemberId)).toPromise();
  }

  function viewStaffMember(holidayId: string) {
    return ajax
      .get(peopleUrls.staffMemberUrls.viewStaffMember(holidayId))
      .toPromise()
      .then<People.Domain.Queries.ViewStaffMember.StaffMemberItem>(r => r.response);
  }

  function checkForUniqueEmployeeId(employeeId: string) {
    return ajax
      .get(peopleUrls.staffMemberUrls.checkForUniqueEmployeeId(employeeId))
      .toPromise()
      .then<Common.Dtos.UniqueNameCheckResultDto>(r => r.response);
  }

  function checkForUniqueEmail(email: string) {
    return ajax
      .get(peopleUrls.staffMemberUrls.checkForUniqueEmail(email))
      .toPromise()
      .then<WebApi.Controllers.People.StaffMember.UniqueEmailCheckResultDto>(r => r.response)
      .then<boolean>(x => x.emailExists);
  }

  function createStaffMemberLicence(
    command: People.Domain.Commands.StaffMembers.CreateStaffMemberLicence.CreateStaffMemberLicenceCommand
  ) {
    return ajax
      .post(peopleUrls.staffMemberUrls.createStaffMemberLicence(command.staffMemberId), command)
      .toPromise()
      .then<void>(r => r.response);
  }

  function updateStaffMemberLicence(
    command: People.Domain.Commands.StaffMembers.UpdateStaffMemberLicence.UpdateStaffMemberLicenceCommand
  ) {
    return ajax
      .put(
        peopleUrls.staffMemberUrls.updateStaffMemberLicence(
          command.staffMemberId,
          command.licenceId
        ),
        command
      )
      .toPromise()
      .then<void>(r => r.response);
  }

  function deleteStaffMemberLicence(
    command: People.Domain.Commands.StaffMembers.DeleteStaffMemberLicence.DeleteStaffMemberLicenceCommand
  ) {
    return ajax
      .httpDelete(
        peopleUrls.staffMemberUrls.updateStaffMemberLicence(
          command.staffMemberId,
          command.licenceId
        )
      )
      .toPromise()
      .then<void>(r => r.response);
  }

  function createStaffMemberFileNote(
    command: People.Domain.Commands.StaffMembers.CreateStaffMemberFileNote.CreateStaffMemberFileNoteCommand
  ) {
    return ajax
      .post(peopleUrls.staffMemberUrls.createStaffMemberFileNote(command.staffMemberId), command)
      .toPromise();
  }

  function updateStaffMemberFileNote(
    command: People.Domain.Commands.StaffMembers.UpdateStaffMemberFileNote.UpdateStaffMemberFileNoteCommand
  ) {
    return ajax
      .put(
        peopleUrls.staffMemberUrls.updateStaffMemberFileNote(
          command.staffMemberId,
          command.fileNoteId
        ),
        command
      )
      .toPromise();
  }

  function createStaffMemberEquipment(
    command: People.Domain.Commands.StaffMembers.Equipment.CreateStaffMemberEquipmentCommand
  ) {
    return ajax
      .post(peopleUrls.staffMemberUrls.createStaffMemberEquipment(command.staffMemberId), command)
      .toPromise()
      .then<void>(r => r.response);
  }

  function updateStaffMemberEquipment(
    command: People.Domain.Commands.StaffMembers.Equipment.UpdateStaffMemberEquipmentCommand
  ) {
    return ajax
      .put(
        peopleUrls.staffMemberUrls.updateStaffMemberEquipment(
          command.staffMemberId,
          command.equipmentId
        ),
        command
      )
      .toPromise()
      .then<void>(r => r.response);
  }

  function deleteStaffMemberEquipment(
    command: People.Domain.Commands.StaffMembers.Equipment.DeleteStaffMemberEquipmentCommand
  ) {
    return ajax
      .httpDelete(
        peopleUrls.staffMemberUrls.deleteStaffMemberEquipment(
          command.staffMemberId,
          command.equipmentId
        )
      )
      .toPromise()
      .then<void>(r => r.response);
  }

  function updateStaffMemberSkillSpecs(
    command: People.Domain.Commands.StaffMembers.SkillSpecs.UpdateStaffMemberSkillSpecsCommand
  ) {
    return ajax
      .put(peopleUrls.staffMemberUrls.updateStaffMemberSkillSpecs(command.staffMemberId), command)
      .toPromise()
      .then<void>(r => r.response);
  }

  return {
    staffMember: {
      loadDrivers,
      loadMechanics,
      loadReportableEventContacts,
      loadStaffMembers,
      createStaffMember,
      updateStaffMember,
      viewStaffMember,
      checkForUniqueEmployeeId,
      checkForUniqueEmail,
      markStaffMemberInactive,
      markStaffMemberActive,
      loadAllStaffMembers,
      loadAllStaffMemberNames,
      createStaffMemberLicence,
      updateStaffMemberLicence,
      deleteStaffMemberLicence,
      createStaffMemberFileNote,
      updateStaffMemberFileNote,
      createStaffMemberEquipment,
      updateStaffMemberEquipment,
      deleteStaffMemberEquipment,
      updateStaffMemberSkillSpecs,
    },
  };
}
