import { types } from 'mobx-state-tree';
import { CustomerComplaintItemModel } from './CusomterComplaintItemModel';
import { ListCustomerComplaintModel } from './ListCustomerComplaintModel';

export const CustomerComplaintModel = types.model('CustomerComplaintModel', {
  list: types.optional(ListCustomerComplaintModel, {}),
  item: types.optional(CustomerComplaintItemModel, {}),
});
type ModelType = typeof CustomerComplaintModel.Type;
export interface ICustomerComplaintModel extends ModelType {}
