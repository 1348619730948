import { DateTime } from 'luxon';

export const FormattedDateTimeWithoutYear: React.FC<{
  className?: string;
  referenceDateTime?: DateTime | string;
  dateTime: DateTime | string;
}> = ({ className, referenceDateTime, dateTime }) => {
  const givenDate =
    typeof dateTime === typeof DateTime
      ? (dateTime as DateTime)
      : DateTime.fromISO(dateTime as string);

  const refDate = referenceDateTime
    ? typeof referenceDateTime === typeof DateTime
      ? (referenceDateTime as DateTime)
      : DateTime.fromISO(referenceDateTime as string)
    : DateTime.local();

  const showYear = !(refDate.year === givenDate.year);

  return (
    <span className={className}>
      {showYear
        ? givenDate.toLocaleString(DateTime.DATETIME_MED)
        : givenDate.toFormat('dd MMM HH:mm')}
    </span>
  );
};
