import { getSubmitCloseModalActionGroupDef } from 'src/views/definitionBuilders/common';
import { ModalDefBuilder, PaneType, FieldType } from 'src/views/definitionBuilders/types';

type UpdateAssetAdHocFuelCommand = Workshop.Domain.Commands.Asset.UpdateAssetAdHocFuel.UpdateAssetAdHocFuelCommand;

export const getUpdateFuelModalDef = (
  id: number,
  updateAdHocFuel: (command: UpdateAssetAdHocFuelCommand) => Promise<void>,
  assetId: string
): ModalDefBuilder => {
  return () => {
    return {
      title: 'Edit Fuel',
      asForm: true,
      onFormPreSubmit: (form: UpdateAssetAdHocFuelCommand): UpdateAssetAdHocFuelCommand => {
        return {
          ...form,
          assetFuelId: id,
          assetId: assetId,
        };
      },
      onFormSubmit: updateAdHocFuel,
      panels: [
        {
          panes: [
            {
              paneType: PaneType.formFieldsPane,
              fields: [
                {
                  fieldType: FieldType.numericField,
                  label: 'Odometer Reading',
                  dataAddr: 'odometerReading',
                  mandatory: true,
                },
                {
                  fieldType: FieldType.numericField,
                  label: 'Fuel Added (L)',
                  dataAddr: 'fuel',
                  numericConfig: { maxPointDigits: 4, numericType: 'signedDecimal' },
                  validate: api => {
                    if (api.fieldValue && api.fieldValue <= 0) {
                      return 'Fuel must be a positive number';
                    }

                    return api.fieldValue || api.parentValue.adblue
                      ? undefined
                      : 'Fuel or AdBlue is required';
                  },
                },
                {
                  fieldType: FieldType.numericField,
                  label: 'AdBlue Added (L)',
                  dataAddr: 'adblue',
                  numericConfig: { maxPointDigits: 4, numericType: 'signedDecimal' },
                  validate: api => {
                    if (api.fieldValue && api.fieldValue <= 0) {
                      return 'AdBlue must be a positive number';
                    }

                    return api.fieldValue || api.parentValue.fuel
                      ? undefined
                      : 'Fuel or AdBlue is required';
                  },
                },
              ],
            },
          ],
        },
      ],
      secondaryActions: [getSubmitCloseModalActionGroupDef('Submit')],
    };
  };
};
