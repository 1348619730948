import { DateTime } from 'luxon';

export interface IDayOfWeekFormatProps {
  value?: DateTime | string;
}

const DayOfWeekFormat: React.FC<IDayOfWeekFormatProps> = ({ value }) => {
  if (value === null || value === undefined) {
    return null;
  }

  if (typeof value === 'string') {
    value = DateTime.fromISO(value);
  }

  return <span>{value.toLocaleString({ weekday: 'long' })}</span>;
};

export default DayOfWeekFormat;
