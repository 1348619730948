import { getSubmitCloseModalActionGroupDef } from 'src/views/definitionBuilders/common';
import { ModalDefBuilder, PaneType, FieldType } from 'src/views/definitionBuilders/types';

export default function getCloseDefectWithoutFixingModalDef(
  closeDefectWithoutFixing: (
    command: Workshop.Domain.Commands.Defect.SetDefectClosedWithoutBeingFixedCommand
  ) => Promise<void>,
  defectId?: string
): ModalDefBuilder {
  return modalDefApi => ({
    title: 'Close Defect Without Fixing',
    asForm: true,
    panels: [
      {
        panes: [
          {
            paneType: PaneType.formFieldsPane,
            fields: [
              {
                fieldType: FieldType.textAreaField,
                dataAddr: 'closeWithoutFixingReason',
                label: 'Please provide a reason:',
                mandatory: true,
                rows: 6,
              },
            ],
          },
        ],
      },
    ],
    secondaryActions: [getSubmitCloseModalActionGroupDef('Ok')],
    onFormSubmit: v => {
      return closeDefectWithoutFixing({
        defectId: defectId || modalDefApi.actionData.parentValue.defectId,
        reason: v.closeWithoutFixingReason,
      });
    },
  });
}
