import { useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import {
  PagePrimarySize,
  PaneType,
  FieldType,
  ActionType,
  ShellModalSize,
} from 'src/views/definitionBuilders/types';
import CrudPage, { CrudPageMode, ICrudPageDef } from 'src/views/components/Page/pages/CrudPage';
import { TrashIcon } from 'src/images/icons';
import { getSubmitCloseModalActionGroupDef } from 'src/views/definitionBuilders/common';
import { observer } from 'mobx-react';
import { useRootStore } from 'src/domain/entities/RootStoreModel';

type HolidayItem = Operations.Domain.Queries.ViewHoliday.HolidayItem;
type CreateHolidayCommand = Operations.Domain.Commands.Holiday.CreateHoliday.CreateHolidayCommand;
type UpdateHolidayCommand = Operations.Domain.Commands.Holiday.UpdateHoliday.UpdateHolidayCommand;

export interface IMaintainHolidayProps {
  mode: CrudPageMode;
  route: RouteComponentProps<{ [x: string]: string | undefined }>;
}

const MaintainHoliday: React.FC<IMaintainHolidayProps> = observer(
  (props: IMaintainHolidayProps) => {
    const rootStore = useRootStore();
    const canManageHoliday =
      rootStore.account.isOperationsDepartmentMember || rootStore.account.isSalesDepartmentMember;
    const holiday = Object.assign({}, rootStore.operations.holiday.holiday);
    const holidayTypes = rootStore.operations.holiday.holidayTypes.slice();
    const loadHoliday = rootStore.operations.holiday.loadHoliday;
    const updateHoliday = rootStore.operations.holiday.updateHoliday;
    const deleteHoliday = rootStore.operations.holiday.deleteHoliday;
    const createHoliday = rootStore.operations.holiday.createHoliday;
    const loadHolidayTypes = rootStore.operations.holiday.loadHolidayTypes;
    const depots = rootStore.operationsStartup.operationsDepots.slice();

    useEffect(() => {
      loadHolidayTypes();
    }, []);

    const isUpdateMode = props.mode === 'update';
    const holidayId = props.route.match.params.id!;

    const handlePreSubmitForCreate = (part: HolidayItem): CreateHolidayCommand => {
      return {
        description: part.description,
        endDate: part.endDate,
        holidayTypeId: part.holidayType.id,
        startDate: part.startDate,
        holidayDepots: part.holidayDepots.map(x => {
          return {
            depotId: x.id,
          };
        }),
      };
    };

    const handlePreSubmitForUpdate = (part: HolidayItem): UpdateHolidayCommand => {
      return {
        id: part.id,
        description: part.description,
        endDate: part.endDate,
        holidayTypeId: part.holidayType.id,
        startDate: part.startDate,
        holidayDepots: part.holidayDepots.map(x => {
          return {
            depotId: x.id,
          };
        }),
      };
    };

    const getPageDef = (mode: CrudPageMode): ICrudPageDef => {
      return {
        primarySize: PagePrimarySize.half,
        primarySection: {
          title: isUpdateMode ? 'Manage Holiday' : 'Create a Holiday',
          primaryActions: isUpdateMode
            ? [
                {
                  actions: [
                    {
                      hidden: () => !canManageHoliday,
                      actionType: ActionType.actionCollection,
                      actionGroups: [
                        {
                          actions: [
                            {
                              actionType: ActionType.modalActionButton,
                              label: 'Delete Holiday',
                              icon: <TrashIcon />,
                              modalSize: ShellModalSize.oneQuarter,
                              modalDef: modalDefApi => ({
                                title: 'Delete Holiday',
                                asForm: true,
                                panels: [
                                  {
                                    panes: [
                                      {
                                        paneType: PaneType.formFieldsPane,
                                        fields: [
                                          {
                                            fieldType: FieldType.customField,
                                            dataAddr: 'fake',
                                            render: () => (
                                              <span>Are you sure you want to delete holiday?</span>
                                            ),
                                          },
                                        ],
                                      },
                                    ],
                                  },
                                ],
                                secondaryActions: [getSubmitCloseModalActionGroupDef('Ok')],
                                onFormSubmit: () => deleteHoliday(holidayId),
                              }),
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ]
            : [],
          panels: [
            {
              panes: [
                {
                  paneType: PaneType.formFieldsPane,
                  fields: [
                    {
                      fieldType: FieldType.textField,
                      label: 'Description',
                      dataAddr: 'description',
                      maxLength: 200,
                      mandatory: true,
                    },
                    {
                      fieldType: FieldType.selectField,
                      label: 'Holiday Type',
                      dataAddr: ['holidayType', 'id'],
                      valueKey: 'id',
                      descriptionKey: 'description',
                      mandatory: true,
                      optionItems: holidayTypes,
                      useValueOnly: true,
                    },
                    {
                      fieldType: FieldType.selectMultiField,
                      label: 'Depots',
                      dataAddr: 'holidayDepots',
                      valueKey: 'id',
                      descriptionKey: 'description',
                      mandatory: true,
                      optionItems: depots,
                    },
                  ],
                },
                {
                  paneType: PaneType.formFieldsPane,
                  columnCount: 2,
                  fields: [
                    {
                      fieldType: FieldType.dateField,
                      label: 'Start Date',
                      dataAddr: 'startDate',
                      mandatory: true,
                    },
                    {
                      fieldType: FieldType.dateField,
                      label: 'End Date',
                      tooltip: 'For a single day holiday, specify the same start and end date',
                      dataAddr: 'endDate',
                      mandatory: true,
                      validate: d =>
                        !!d.parentValue.startDate &&
                        !!d.parentValue.endDate &&
                        d.parentValue.endDate < d.parentValue.startDate
                          ? 'End date cannot be before start date'
                          : undefined,
                    },
                  ],
                },
              ],
            },
          ],
          onFormPreSubmit: isUpdateMode ? handlePreSubmitForUpdate : handlePreSubmitForCreate,
          onFormSubmit: isUpdateMode ? updateHoliday : createHoliday,
        },
        secondarySections: isUpdateMode ? [] : [],
      };
    };

    return (
      <CrudPage
        def={getPageDef(props.mode)}
        mode={props.mode}
        isEditingForbidden={!canManageHoliday}
        onLoadData={() => loadHoliday(holidayId)}
        data={holiday}
        createDefaultData={{
          holidayDepots: depots,
        }}
      />
    );
  }
);

export default MaintainHoliday;
