import { Component } from 'react';
import Menu from './Menu';
import IconButton from '../../components/presentational/IconButton/IconButton';
import { BarsIcon, ArrowLeftIcon } from 'src/images/icons';
import styles from './Menu.module.scss';
import { SingleClickAwareButton } from '../../components/presentational/SingleClickAwareButton/SingleClickAwareButton';
import cn from 'classnames';

interface IMenuProps {
  width?: number;
  goBack?: () => void;
  color?: 'light' | 'dark';
}

interface IMenuState {
  isLoading: boolean;
  isOpened: boolean;
}

class WithMenu extends Component<IMenuProps, IMenuState> {
  constructor(props: IMenuProps) {
    super(props);
    this.state = {
      isLoading: false,
      isOpened: false,
    };
    this.handleToggle = this.handleToggle.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  handleToggle() {
    this.setState(prevState => {
      return { isOpened: !prevState.isOpened };
    });
  }

  handleClose() {
    this.setState({ isOpened: false });
  }

  render() {
    return (
      <>
        <Menu
          click={this.handleClose}
          canUseTimesheets
          show={this.state.isOpened}
          width={this.props.width}
        />
        <div className={cn(styles.menuButton, this.props.color && styles[this.props.color])}>
          {this.props.goBack !== undefined && (
            <SingleClickAwareButton className={styles.back} onClick={this.props.goBack}>
              <ArrowLeftIcon />
              <span>Back</span>
            </SingleClickAwareButton>
          )}
          <IconButton
            color={this.props.color}
            icon={<BarsIcon />}
            onClick={this.handleToggle}
            label={'Menu'}
          />
        </div>
        {this.props.children}
      </>
    );
  }
}

export default WithMenu;
