import './ListJobForCharter.scss';

import { DateTime } from 'luxon';
import Select from 'react-select';
import { Link } from 'react-router-dom';
import memoizeOne from 'src/infrastructure/memoizeOne';
import { saveAs } from 'file-saver';
import {
  allJobStatus,
  allConflictCriteria,
  JobStatus,
  JobType,
  allProgressId,
} from 'src/api/enums';
import { FilePdfIcon, ClockIcon, ExcelIcon } from 'src/images/icons';
import { ListPageLoadCause } from 'src/domain';
import {
  FieldType,
  FieldDefs,
  PaneType,
  PagePrimarySize,
  ActionType,
} from 'src/views/definitionBuilders/types';
import withQueryParams, { IQueryParamsProps } from 'src/views/hocs/withQueryParams';
import ListPage, { IListPageDef } from 'src/views/components/Page/pages/ListPage';
import { DateTimeFormat } from 'src/views/components/DateTimeFormat';
import FleetAssetRenderer from 'src/views/components/operations/FleetAssetRenderer/FleetAssetRenderer';
import ExtraDetails from 'src/views/components/operations/ExtraDetails/ExtraDetails';
import { getReadonlyJobNumberFieldDef } from 'src/views/routes/operations/shared/commonFieldDefBuilders';
import {
  doesJobTypeHaveRailBooking,
  mayJobTypeHaveRailBooking,
  CharterDiaryJobTypes,
  isJobTypeCharterOrContractCharter,
} from 'src/views/routes/operations/shared/jobTypeHelpers';
import PrintCharterInstructionsContent from 'src/views/components/operations/PrintCharterInstructionsContent/PrintCharterInstructionsContent';
import ReactDOM from 'react-dom';
import {
  mapSkillSpecIds,
  applySkillSpecRequirementsFilter,
} from 'src/domain/entities/people/staffMember/SkillSpecsHelpers';
import { applyTechSpecRequirementsFilter } from 'src/domain/entities/workshop/techSpecs/TechSpecsHelpers';
import PrintRailInstructionsContent from 'src/views/components/operations/PrintRailInstructionsContent/PrintRailInstructionsContent';
import { IAutocompleteResult, IListPageLoadDataRequest } from 'src/domain/baseTypes';
import { useEffect, useRef } from 'react';
import PageField from 'src/views/components/Page/fields/PageField';
import { getSwapVehicleModalActionButtonDef } from '../maintainJob/panelDefs/getSwapVehicleModalDef';
import { PrintCharterDailyDiaries } from 'src/views/components/operations/PrintCharterDailyDiaries/PrintCharterDailyDiaries';
import { Observable, Subscription } from 'rxjs';
import { IOperationsDomainEvent } from 'src/domain/services/storeBus';
import React from 'react';
import PrimaryTitle from 'src/views/components/Page/PrimaryTitle/PrimaryTitle';
import { useRootStore } from 'src/domain/entities/RootStoreModel';

type CharterDailyDiaryDetails = Operations.Domain.Queries.ListCharterDailyDiaryDetails.CharterDailyDiaryDetails;
type ListDailySummarySheetDetailsQuery = Operations.Domain.Queries.ListDailySummarySheetDetails.ListDailySummarySheetDetailsQuery;
type ListJobsQuery = Operations.Domain.Queries.ListJobs.ListJobsQuery;
type ListJobsForCharterQuery = Operations.Domain.Queries.ListJobsForCharter.ListJobsForCharterQuery;
type ListJobItem = Operations.Domain.Queries.ListJobsForCharter.ListJobItem;
type JobExtraItem = Operations.Domain.Queries.ListJobsForCharter.JobExtraItem;
type StaffMemberDto = Common.Dtos.StaffMemberDto;
type AssetItem = Common.Queries.Workshop.GetFleetAssetList.AssetItem;
type CustomerItem = Operations.Domain.Queries.SearchCustomers.CustomerItem;
type ContactItem = Operations.Domain.Queries.SearchContactsForCustomer.ContactItem;
type VehicleType = Common.Dtos.VehicleTypeItem;
type ListBookingItem = Operations.Domain.Queries.ListBookings.ListBookingItem;
type QuoteItem = Operations.Domain.Queries.GetQuotes.QuoteItem;
type AllocateStaffMemberCommand = Operations.Domain.Commands.Job.AllocateStaffMember.AllocateStaffMemberCommand;
type AllocateAssetCommand = Operations.Domain.Commands.Job.AllocateAsset.AllocateAssetCommand;
type AllocateSubcontractorCommand = Operations.Domain.Commands.Job.AllocateSubcontractor.AllocateSubcontractorCommand;
type SubcontractorItem = Common.Dtos.SubcontractorItem;
type CharterInstructions = Operations.Domain.Queries.ListCharterInstructionsForDriver.CharterInstructions;
type ExclusionItem = Operations.Domain.Queries.ListExclusions.ExclusionItem;
type JobSkillSpecRequirementItem = Operations.Domain.Queries.ListJobsForCharter.JobSkillSpecRequirementItem;
type JobTechSpecRequirementItem = Operations.Domain.Queries.ListJobsForCharter.JobTechSpecRequirementItem;
type QuoteTypeDto = Common.Dtos.QuoteTypeDto;
type AssetHousingLocation = Common.Queries.Workshop.GetAssetHousingLocations.AssetHousingLocationItem;
type RailBookingListItem = Operations.Domain.Queries.ListRailBookings.RailBookingListItem;
type RailTemplateShiftListItem = Operations.Domain.Queries.ListRailTemplateShifts.RailTemplateShiftListItem;
type RailInstructions = Operations.Domain.Queries.ListRailInstructionsForDriver.RailInstructions;
type SwapVehicleCommand = Operations.Domain.Commands.Job.SwapVehicleCommand;
type JobProgressResult = Operations.Domain.Queries.GetJobProgress.JobProgressResult;
type OperationsJobConflictsUpdatedEvent = Operations.Domain.Events.ConflictsUpdated.OperationsJobConflictsUpdatedEvent;
type TradingCompanyDto = Common.Dtos.TradingCompanyDto;
type StaffDepotDto = Common.Dtos.StaffDepotDto;
type FatigueWarning = Operations.Domain.Services.Fatigue.FatigueWarning;

export interface IListJobForCharterProps {
  canManageJobs: boolean;
  listJobs: (request: IListPageLoadDataRequest<ListJobsForCharterQuery>) => Promise<void>;
  refreshJobs: (
    request: IListPageLoadDataRequest<ListJobsForCharterQuery>,
    objectsToRemove?: any[]
  ) => Promise<void>;
  warnAboutConflicts: (jobId: string) => void;
  jobs: ListJobItem[];
  hasMoreData: boolean;
  fleetAssets: AssetItem[];
  loadFleetAssets: () => Promise<void>;
  subcontractors: SubcontractorItem[];
  loadSubcontractors: () => Promise<void>;

  staffMembers: StaffMemberDto[];
  loadStaffMembers: () => Promise<void>;
  loadExclusions: () => Promise<void>;
  exclusions: ExclusionItem[];
  searchCustomers: (search: string) => Promise<IAutocompleteResult<CustomerItem>>;
  findCustomers: (ids: string[]) => Promise<IAutocompleteResult<CustomerItem>>;
  searchContactsForCustomer: (
    customerId: string | undefined,
    search: string
  ) => Promise<IAutocompleteResult<ContactItem>>;
  findContactsForCustomer: (
    customerId: string | undefined,
    ids: number[]
  ) => Promise<IAutocompleteResult<ContactItem>>;
  vehicleTypes: VehicleType[];
  loadVehicleTypes: () => Promise<void>;
  searchBookings: (search: string) => Promise<IAutocompleteResult<ListBookingItem>>;
  findQuotes: (ids: string[]) => Promise<IAutocompleteResult<QuoteItem>>;
  exportToExcel: (query: Partial<ListJobsQuery>) => Promise<Blob>;
  exportCharterJobsToPdf: (request: Partial<ListJobsForCharterQuery>) => Promise<Blob>;
  onAllocateStaffMember: (command: AllocateStaffMemberCommand) => Promise<FatigueWarning[]>;
  onAllocateAsset: (command: AllocateAssetCommand) => Promise<void>;
  onAllocateSubcontractor: (command: AllocateSubcontractorCommand) => Promise<void>;
  loadCharterInstructions: (query: Partial<ListJobsForCharterQuery>) => Promise<void>;
  charterInstructions?: CharterInstructions;
  jobWithClockOnClosestToNow?: ListJobItem;
  quoteTypesForDropdown: QuoteTypeDto[];
  loadQuoteTypes: () => Promise<void>;
  assetHousingLocations: AssetHousingLocation[];
  loadAssetHousedAtLocations: () => Promise<void>;
  listRailBookings: (
    request: IListPageLoadDataRequest<
      Operations.Domain.Queries.ListRailBookings.ListRailBookingsQuery
    >
  ) => Promise<void>;
  railBookings: RailBookingListItem[];
  listRailShifts: () => Promise<void>;
  railShifts: RailTemplateShiftListItem[];
  loadRailInstructions: (query: Partial<ListJobsQuery>) => Promise<void>;
  railInstructions?: RailInstructions;
  swapVehicle: (command: SwapVehicleCommand) => Promise<void>;
  jobProgress: JobProgressResult | undefined;
  loadJobProgress: (jobId: string) => Promise<void>;
  loadCharterDailyDiaryDetails: (
    query: Partial<ListDailySummarySheetDetailsQuery>
  ) => Promise<void>;
  charterDailyDiaryDetails?: Common.Dtos.ListResult<CharterDailyDiaryDetails>;
  domainEvents: Observable<IOperationsDomainEvent>;
  tradingCompanies: Array<TradingCompanyDto>;
  loadTradingCompanies: () => Promise<void>;
  staffDepots: StaffDepotDto[];
  loadStaffDepots: () => Promise<void>;
}

type InternalProps = IListJobForCharterProps & IQueryParamsProps<ListJobsForCharterQuery>;

const ListJobForCharter: React.FC<InternalProps> = (props: InternalProps) => {
  let domainEventSubscription: Subscription | undefined;
  let rememberEditedJobs: string[] = [];

  const rootStore = useRootStore();
  const jobWithClockOnClosestToCurrentTimeRef = useRef<DateTimeFormat>(null);

  useEffect(() => {
    props.loadStaffMembers();
    props.loadSubcontractors();
    props.loadFleetAssets();
    props.loadVehicleTypes();
    props.loadExclusions();
    props.loadQuoteTypes();
    props.loadAssetHousedAtLocations();
    props.listRailBookings({ loadCause: ListPageLoadCause.mount });
    props.listRailShifts();
    props.loadTradingCompanies();
    props.loadStaffDepots();

    return () => {
      domainEventSubscription?.unsubscribe();
    };
  }, []);

  const addJobToEditedJobs = (jobId: string) => {
    if (rememberEditedJobs.indexOf(jobId) === -1) rememberEditedJobs.push(jobId);
  };

  const savePdf = () => {
    const fileName = `Jobs_${DateTime.local().toFormat('yyyyMMddHHmm')}.pdf`;
    return props
      .exportCharterJobsToPdf(props.getQueryParams())
      .then(blob => saveAs(blob, fileName));
  };

  const saveSpreadsheet = () => {
    const fileName = `Jobs_${DateTime.local().toFormat('yyyyMMddHHmm')}.xlsx`;
    let params = props.getQueryParams();

    return props
      .exportToExcel({
        ...params,
        jobTypeIds: [JobType.Charter, JobType.CharterStaged, JobType.ContractCharter],
      })
      .then(blob => saveAs(blob, fileName));
  };

  const filterStaffMembers = (
    assetId: string,
    skillSpecRequirements: JobSkillSpecRequirementItem[]
  ) => {
    const { staffMembers, exclusions } = props;
    let filteredStaffMembers = staffMembers;
    filteredStaffMembers = filterStaffMembersByExclusion(filteredStaffMembers, exclusions, assetId);
    filteredStaffMembers = filterStaffMembersBySkillSpecRequirements(
      filteredStaffMembers,
      skillSpecRequirements
    );
    return filteredStaffMembers;
  };

  const filterStaffMembersByExclusion = (
    staffMembers: StaffMemberDto[],
    exclusions: ExclusionItem[],
    assetId: string
  ) => {
    if (!!assetId) {
      return staffMembers.filter(
        x => exclusions.findIndex(e => e.asset.id === assetId && e.staffMember.id === x.id) === -1
      );
    }

    return staffMembers;
  };

  const filterStaffMembersBySkillSpecRequirements = (
    staffMembers: StaffMemberDto[],
    skillSpecRequirements: JobSkillSpecRequirementItem[]
  ) => {
    if (!!skillSpecRequirements && skillSpecRequirements.length) {
      const skillSpecRequirementsIds = mapSkillSpecIds(skillSpecRequirements);
      return applySkillSpecRequirementsFilter(staffMembers, skillSpecRequirementsIds);
    }

    return staffMembers;
  };

  const filterAssets = (
    staffMemberId: string,
    secondStaffMemberId: string,
    techSpecRequirements: JobTechSpecRequirementItem[]
  ) => {
    const { fleetAssets, exclusions } = props;
    const staffMemberIds = [staffMemberId, secondStaffMemberId].filter(x => !!x);

    let filteredAssets = fleetAssets;
    filteredAssets = filterAssetsByExclusion(filteredAssets, exclusions, staffMemberIds);
    filteredAssets = filterVehiclesByTechSpecRequirements(filteredAssets, techSpecRequirements);
    return filteredAssets;
  };

  const filterAssetsByExclusion = (
    fleetAssets: AssetItem[],
    exclusions: ExclusionItem[],
    staffMemberIds: string[]
  ) => {
    if (!!staffMemberIds) {
      return fleetAssets.filter(
        x =>
          exclusions.findIndex(
            e => staffMemberIds.findIndex(s => s === e.staffMember.id) !== -1 && e.asset.id === x.id
          ) === -1
      );
    }

    return fleetAssets;
  };

  const filterVehiclesByTechSpecRequirements = (
    fleetAssets: AssetItem[],
    techSpecRequirements: JobTechSpecRequirementItem[]
  ) => {
    if (!!techSpecRequirements && techSpecRequirements.length) {
      return applyTechSpecRequirementsFilter(fleetAssets, techSpecRequirements);
    }

    return fleetAssets;
  };

  const scrollToJobClosestToCurrentTime = () => {
    const element = ReactDOM.findDOMNode(
      jobWithClockOnClosestToCurrentTimeRef.current
    ) as HTMLElement;

    if (element != null) {
      element.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'center',
      });
    }
  };

  const jobIdsToRemoveObject = (ids: string[]) => {
    let jobIds: any[] = [];
    ids.forEach(id => jobIds.push({ id: id }));
    return jobIds;
  };

  const getFilterFieldDefs = () => {
    const {
      staffMembers,
      fleetAssets,
      subcontractors,
      vehicleTypes,
      searchCustomers,
      findCustomers,
      searchContactsForCustomer,
      findContactsForCustomer,
      searchBookings,
      findQuotes,
      quoteTypesForDropdown,
      railBookings,
      railShifts,
      assetHousingLocations,
      tradingCompanies,
      staffDepots,
    } = props;
    return {
      jobStatus: {
        fieldType: FieldType.selectMultiField,
        dataAddr: 'jobStatusIds',
        label: 'Job Status',
        valueKey: 'value',
        descriptionKey: 'description',
        optionItems: allJobStatus,
        useValueOnly: true,
      } as FieldDefs,
      jobProgress: {
        fieldType: FieldType.selectMultiField,
        dataAddr: 'jobProgressIds',
        label: 'Job Progress',
        valueKey: 'value',
        descriptionKey: 'description',
        optionItems: allProgressId,
        useValueOnly: true,
      } as FieldDefs,
      dateFrom: {
        fieldType: FieldType.dateField,
        dataAddr: 'dateFrom',
        label: 'Date From',
        onBlur: api => {
          if (!api.formValues.dateTo) {
            api.setFormValue(['dateTo'], api.fieldData.fieldValue);
          }
          api.validateField(['dateTo']);
        },
      } as FieldDefs,
      dateTo: {
        fieldType: FieldType.dateField,
        dataAddr: 'dateTo',
        label: 'Date To',
        validate: d => {
          if (!d.fieldValue || !d.parentValue.dateFrom) {
            return undefined;
          }
          const from = DateTime.fromISO(d.parentValue.dateFrom);
          const to = DateTime.fromISO(d.fieldValue);
          return from > to ? 'Date To cannot be earlier than Date From' : undefined;
        },
      } as FieldDefs,
      isDriverManaged: {
        fieldType: FieldType.yesNoField,
        dataAddr: 'isDriverManaged',
        label: 'Driver Managed Fatigue',
      } as FieldDefs,
      needsVerification: {
        fieldType: FieldType.yesNoField,
        label: 'Needs Verification',
        dataAddr: 'needsVerification',
        useValueOnly: true,
      } as FieldDefs,
      isCancelled: {
        fieldType: FieldType.yesNoField,
        label: 'Is Cancelled',
        dataAddr: 'isCancelled',
        useValueOnly: true,
      } as FieldDefs,
      adhoc: {
        fieldType: FieldType.yesNoField,
        dataAddr: 'adhoc',
        label: 'Adhoc',
        useValueOnly: true,
      } as FieldDefs,
      isTrainingJob: {
        fieldType: FieldType.yesNoField,
        dataAddr: 'isTrainingJob',
        label: 'Training',
        useValueOnly: true,
      } as FieldDefs,
      staffMembers: {
        fieldType: FieldType.selectMultiField,
        dataAddr: 'staffMembers',
        label: 'Staff Member',
        valueKey: 'id',
        descriptionKey: 'name',
        optionItems: staffMembers,
        useValueOnly: true,
      } as FieldDefs,
      staffMemberDepots: {
        fieldType: FieldType.selectMultiField,
        dataAddr: 'staffMemberDepots',
        label: 'Staff Member Depot',
        valueKey: 'id',
        descriptionKey: 'description',
        optionItems: staffDepots,
        useValueOnly: true,
      } as FieldDefs,
      vehicles: {
        fieldType: FieldType.selectMultiField,
        dataAddr: 'vehicles',
        label: 'Vehicle',
        valueKey: 'id',
        descriptionKey: 'name',
        optionItems: fleetAssets,
        useValueOnly: true,
      } as FieldDefs,
      garagedAt: {
        fieldType: FieldType.selectMultiField,
        label: 'Garaged At',
        dataAddr: 'housingLocations',
        useValueOnly: true,
        valueKey: 'id',
        descriptionKey: 'description',
        optionItems: assetHousingLocations,
      } as FieldDefs,
      subcontractors: {
        fieldType: FieldType.selectMultiField,
        dataAddr: 'subcontractors',
        label: 'Subcontractor',
        valueKey: 'id',
        descriptionKey: 'name',
        optionItems: subcontractors,
        useValueOnly: true,
      } as FieldDefs,
      vehicleTypes: {
        fieldType: FieldType.selectMultiField,
        dataAddr: 'vehicleTypes',
        label: 'Quoted Vehicle Type',
        valueKey: 'id',
        descriptionKey: 'description',
        optionItems: vehicleTypes,
        useValueOnly: true,
      } as FieldDefs,
      conflicts: {
        fieldType: FieldType.selectMultiField,
        label: 'Conflict',
        dataAddr: 'conflicts',
        valueKey: 'value',
        descriptionKey: 'description',
        optionItems: allConflictCriteria,
        useValueOnly: true,
      } as FieldDefs,
      customerId: {
        fieldType: FieldType.selectAsyncField,
        dataAddr: 'customerId',
        label: 'Customer',
        valueKey: 'customerId',
        descriptionKey: 'customerName',
        useValueOnly: true,
        loadOptionItems: searchCustomers,
        loadItems: findCustomers,
        onChange: api => {
          if (api.oldFieldValue) {
            const values = { ...api.formValues, contactIds: undefined };
            api.setFormValues(values);
          }
        },
      } as FieldDefs,
      contactIds: {
        fieldType: FieldType.selectAsyncMultiField,
        dataAddr: 'contactIds',
        getFieldKey: d => d.sectionValue.customerId,
        label: 'Contact',
        valueKey: 'contactId',
        descriptionKey: 'contactName',
        useValueOnly: true,
        loadOptionItems: (s, d) => searchContactsForCustomer(d.sectionValue.customerId, s),
        loadItems: (ids, d) => findContactsForCustomer(d.sectionValue.customerId, ids),
        readonly: d => !d.parentValue.customerId,
        autoload: true,
      } as FieldDefs,
      quotes: {
        fieldType: FieldType.selectAsyncMultiField,
        dataAddr: 'quotes',
        label: 'Charter Booking',
        valueKey: 'id',
        descriptionKey: 'quoteNumber',
        useValueOnly: true,
        loadOptionItems: searchBookings,
        loadItems: findQuotes,
        optionRenderer: d =>
          d.quoteNumber && d.description ? (
            <span>
              {d.quoteNumber} - {d.description}
            </span>
          ) : null,
        useOptionRendererAsValueRenderer: true,
        readonly: d => d.parentValue.quoteTypeIds && d.paneValue.quoteTypeIds.length > 0,
        onChange: api => {
          const values = { ...api.formValues, quoteTypeIds: undefined };
          api.setFormValues(values);
        },
      } as FieldDefs,
      quoteTypes: {
        fieldType: FieldType.selectMultiField,
        dataAddr: 'quoteTypeIds',
        label: 'Quote Type',
        valueKey: 'id',
        descriptionKey: 'description',
        optionItems: quoteTypesForDropdown,
        useValueOnly: true,
        readonly: d => d.parentValue.quotes && d.paneValue.quotes.length > 0,
        onChange: api => {
          const values = { ...api.formValues, quotes: undefined };
          api.setFormValues(values);
        },
        optionRenderer: (d: QuoteTypeDto) => {
          const tradingCompany = tradingCompanies.find(tc => tc.id === d.tradingCompanyId);
          if (tradingCompanies.length >= 2) {
            return (
              <span>
                {d.description}
                <small>
                  &emsp; - &emsp;
                  {tradingCompany?.code}
                </small>
              </span>
            );
          } else return <span>{d.description}</span>;
        },
      } as FieldDefs,
      railBookings: {
        fieldType: FieldType.selectMultiField,
        label: 'Rail Booking',
        dataAddr: 'railBookings',
        valueKey: 'railBookingId',
        descriptionKey: 'railBookingNumber',
        useOptionRendererAsValueRenderer: true,
        optionRenderer: d => (
          <span>
            {d.railBookingNumber} - {d.description}
          </span>
        ),
        optionItems: railBookings,
        useValueOnly: true,
      } as FieldDefs,
      shiftNames: {
        fieldType: FieldType.selectMultiField,
        dataAddr: 'shiftNames',
        label: 'Rail Shift',
        valueKey: 'shiftName',
        descriptionKey: 'shiftName',
        optionItems: railShifts,
        useValueOnly: true,
      } as FieldDefs,
      jobType: {
        fieldType: FieldType.selectMultiField,
        dataAddr: 'jobTypeIds',
        label: 'Job Type',
        valueKey: 'value',
        descriptionKey: 'description',
        optionItems: CharterDiaryJobTypes,
        useValueOnly: true,
      } as FieldDefs,
      tradingCompanyId: {
        fieldType: FieldType.selectMultiField,
        dataAddr: 'tradingCompanyIds',
        label: 'Trading Company',
        valueKey: 'id',
        descriptionKey: 'name',
        optionItems: tradingCompanies,
        useValueOnly: true,
        hidden: () => tradingCompanies.length === 1,
      } as FieldDefs,
    };
  };

  const getPageDef = memoizeOne(
    (
      listJobs: IListJobForCharterProps['listJobs'],
      hasMoreData: boolean,
      filterFieldDefsLookup: ReturnType<typeof getFilterFieldDefs>,
      charterInstructions,
      railInstructions,
      jobWithClockOnClosestToCurrentTimeRef,
      jobWithClockOnClosestToCurrentTime,
      swapVehicle: IListJobForCharterProps['swapVehicle'],
      jobProgress: IListJobForCharterProps['jobProgress'],
      loadJobProgress: IListJobForCharterProps['loadJobProgress'],
      charterDailyDiaryDetails?: Common.Dtos.ListResult<CharterDailyDiaryDetails>
    ): IListPageDef => {
      const today = DateTime.local().toISODate();
      const { canManageJobs } = props;
      domainEventSubscription?.unsubscribe();
      domainEventSubscription = props.domainEvents
        .filter(e => e.eventName === 'OperationsJobConflictsUpdatedEvent')
        .subscribe(e => {
          const changedJobIds = (e.payload as OperationsJobConflictsUpdatedEvent).jobIds.filter(
            item => rememberEditedJobs.some(rem => item === rem)
          );
          changedJobIds.forEach(jId => props.warnAboutConflicts(jId));
        });

      return {
        primaryTitle: <PrimaryTitle title="Charter Diary"></PrimaryTitle>,
        onLoadData: listJobs,
        externalSearch: true,
        primarySize: PagePrimarySize.full,
        hasMoreData: hasMoreData,
        rowKey: d => {
          return d.itemValue.id;
        },
        primaryActions: [
          {
            actions: [
              {
                actionType: ActionType.actionButton,
                label: 'Scroll to job closest to current time',
                icon: <ClockIcon fixedWidth />,
                onClick: scrollToJobClosestToCurrentTime,
              },
              {
                actionType: ActionType.actionCollection,
                actionGroups: [
                  {
                    actions: [
                      {
                        actionType: ActionType.actionButton,
                        label: 'Export all data to Excel',
                        icon: <ExcelIcon fixedWidth />,
                        onClick: saveSpreadsheet,
                      },
                      {
                        actionType: ActionType.actionButton,
                        label: 'Export to Pdf',
                        icon: <FilePdfIcon fixedWidth />,
                        onClick: () => savePdf(),
                      },
                    ],
                  },
                  {
                    actions: [
                      {
                        actionType: ActionType.printActionButton,
                        label: 'Export Charter Instructions',
                        loadDataAsync: () => props.loadCharterInstructions(props.getQueryParams()),
                        printContent: () => (
                          <PrintCharterInstructionsContent
                            charterInstructions={charterInstructions}
                          />
                        ),
                      },
                      {
                        actionType: ActionType.printActionButton,
                        label: 'Print Rail Instructions Sheets',
                        loadDataAsync: () => props.loadRailInstructions(props.getQueryParams()),
                        printContent: () => (
                          <PrintRailInstructionsContent railInstructions={railInstructions} />
                        ),
                      },
                      {
                        actionType: ActionType.printActionButton,
                        label: 'Print Charter Daily Diary Report',
                        loadDataAsync: _ =>
                          props.loadCharterDailyDiaryDetails(props.getQueryParams()),
                        printContent: () =>
                          charterDailyDiaryDetails && (
                            <PrintCharterDailyDiaries jobs={charterDailyDiaryDetails.items} />
                          ),
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
        primaryFields: [
          {
            fieldType: FieldType.readonlyField,
            label: 'Booking',
            columnWidth: '1px',
            formatReadonly: data => {
              const jobTypeId = data.parentValue.jobType.id;
              if (
                doesJobTypeHaveRailBooking(jobTypeId) ||
                (mayJobTypeHaveRailBooking(jobTypeId) && !!data.parentValue.railBookingId)
              ) {
                return (
                  <Link to={`/operations/rail/rail-bookings/${data.parentValue.railBookingId}`}>
                    {data.parentValue.railBookingNumber}
                  </Link>
                );
              } else {
                return (
                  <Link to={`/operations/bookings-for-ops/${data.parentValue.quoteId}`}>
                    {data.parentValue.quoteNumber}
                  </Link>
                );
              }
            },
          },
          getReadonlyJobNumberFieldDef<ListJobItem>('id'),
          {
            fieldType: FieldType.textField,
            dataAddr: 'customerName',
            label: 'Customer / Description',
            formatReadonly: d => (
              <div>
                <div title={d.fieldValue} className="customer">
                  <Link to={`/sales/customers/${d.parentValue.customerId}`}>{d.fieldValue}</Link>
                </div>
                <div title={d.parentValue.description} className="description">
                  {d.parentValue.charterContract
                    ? d.parentValue.description
                    : `${d.parentValue.description}  ${d.parentValue.shiftName}`}
                </div>
              </div>
            ),
          },
          {
            fieldType: FieldType.dateTimeField,
            dataAddr: 'departDepot',
            label: 'Depart Depot',
            formatReadonly: d => {
              const thisJob = d.parentValue as ListJobItem;
              if (isJobTypeCharterOrContractCharter(thisJob.jobType.id)) {
                if (
                  jobWithClockOnClosestToCurrentTime &&
                  jobWithClockOnClosestToCurrentTime.id === thisJob.id
                ) {
                  return (
                    <DateTimeFormat
                      value={d.fieldValue}
                      ref={jobWithClockOnClosestToCurrentTimeRef}
                    />
                  );
                }
                return <DateTimeFormat value={d.fieldValue} />;
              } else if (
                doesJobTypeHaveRailBooking(thisJob.jobType.id) ||
                mayJobTypeHaveRailBooking(thisJob.jobType.id)
              ) {
                return <DateTimeFormat value={d.fieldValue} />;
              }

              return 'Charter Staged';
            },
            hidden: d => (d.parentValue as ListJobItem).isContinuingFrom,
          },
          {
            fieldType: FieldType.dateTimeField,
            dataAddr: 'shiftCommence',
            label: 'Pick Up',
            formatReadonly: d => (
              <DateTimeFormat
                value={d.fieldValue}
                previousValue={
                  (d.parentValue as ListJobItem).isContinuingFrom
                    ? undefined
                    : d.parentValue.departDepot
                }
                timezone={(d.parentValue as ListJobItem).firstRouteTimeZone}
              />
            ),
          },
          {
            fieldType: FieldType.dateTimeField,
            dataAddr: 'shiftEnd',
            label: 'Drop Off',
            formatReadonly: d => (
              <DateTimeFormat
                value={d.fieldValue}
                previousValue={d.parentValue.shiftCommence}
                timezone={(d.parentValue as ListJobItem).lastRouteTimeZone}
              />
            ),
          },
          {
            fieldType: FieldType.selectField,
            label: 'Quoted Vehicle Type',
            dataAddr: 'vehicleType',
            optionItems: [],
            valueKey: 'id',
            descriptionKey: 'description',
          },
          {
            fieldType: FieldType.customField,
            label: 'Facilities',
            dataAddr: 'jobExtras',
            render: d => {
              const extras = d.data.fieldValue as JobExtraItem[];
              const jobId = d.data.parentValue.id;
              return <ExtraDetails extras={extras} id={jobId} />;
            },
          },
          {
            fieldType: FieldType.customField,
            label: 'Vehicle',
            dataAddr: 'assetId',
            columnWidth: '10em',
            render: d => {
              const jobItem = d.data.parentValue as ListJobItem;
              if (jobItem.jobType.id === JobType.Rail && jobItem.isTrainingJob) {
                return <div>{jobItem.asset ? jobItem.asset.name : ''}</div>;
              }
              if (jobItem.jobType.id === JobType.RailCustomerService) {
                return <div />;
              }
              if (jobItem.hasSubcontractor) {
                if (jobItem.subcontractor && jobItem.subcontractor.id) {
                  return (
                    <Link to={`/operations/subcontractors/${jobItem.subcontractor.id}`}>
                      {jobItem.subcontractor.name}
                    </Link>
                  );
                }
                return 'Subcontractor';
              } else {
                if (!canManageJobs) {
                  return jobItem.asset && jobItem.asset.name;
                }
                return (
                  <div className="field-combo">
                    <Select
                      placeholder="Incomplete"
                      clearable
                      disabled={jobItem.hasProgress}
                      options={filterAssets(
                        jobItem.staffMember && jobItem.staffMember.id,
                        jobItem.secondStaffMember && jobItem.secondStaffMember.id,
                        jobItem.techSpecRequirements
                      )}
                      valueKey="id"
                      labelKey="name"
                      value={jobItem.asset}
                      menuContainerStyle={{ minWidth: '100%', width: 'fit-content' }}
                      valueRenderer={a => {
                        const asset = a as AssetItem;
                        return <FleetAssetRenderer asset={asset} hideAssetInfo />;
                      }}
                      optionRenderer={a => {
                        const asset = a as AssetItem;
                        return <FleetAssetRenderer asset={asset} />;
                      }}
                      onChange={s => {
                        const jobId = jobItem.id;
                        const asset = s as AssetItem;
                        props
                          .onAllocateAsset({
                            jobId: jobId,
                            assetId: asset && asset.id,
                            keepAcknowledgement: true,
                          })
                          .then(r => {
                            let jobIds = [jobId, jobItem.linkedJob || ''];

                            props.refreshJobs(
                              {
                                query: {
                                  ...props.getQueryParams(),
                                  jobIds: jobIds,
                                },
                                loadCause: ListPageLoadCause.refresh,
                              },
                              jobIdsToRemoveObject(jobIds)
                            );
                          })
                          .then(() => addJobToEditedJobs(jobId));
                      }}
                      matchProp="label"
                    />
                    <PageField
                      fieldDef={{
                        fieldType: FieldType.actionListField,
                        hidden:
                          (!jobItem.hasProgress &&
                            jobItem.vehicleSwapJobDetails &&
                            jobItem.vehicleSwapJobDetails?.vehicleSwapToJobId !== null) ??
                          false,
                        actionGroups: [
                          {
                            actions: [
                              getSwapVehicleModalActionButtonDef({
                                hidden:
                                  jobItem.vehicleSwapJobDetails?.vehicleSwapToJobId !== null ||
                                  false,
                                disabled: false,
                                existingClockOn:
                                  jobItem.continuationJobStart ||
                                  jobItem.vehicleSwapJobDetails?.start ||
                                  jobItem.clockOn,
                                existingClockOff:
                                  jobItem.continuationJobFinish ||
                                  jobItem.vehicleSwapJobDetails?.finish ||
                                  jobItem.clockOff,
                                existingJobId: jobItem.id,
                                existingJobBreaks: jobItem.unpaidBreaks || '0',
                                onSubmit: p =>
                                  swapVehicle(p)
                                    .then(r => {
                                      let jobIds = [jobItem.id, jobItem.linkedJob || ''];

                                      props.refreshJobs(
                                        {
                                          query: {
                                            ...props.getQueryParams(),
                                            jobIds: jobIds,
                                          },
                                          loadCause: ListPageLoadCause.refresh,
                                        },
                                        jobIdsToRemoveObject(jobIds)
                                      );
                                    })
                                    .then(() => {
                                      addJobToEditedJobs(jobItem.id);
                                      if (jobItem.linkedJob) addJobToEditedJobs(jobItem.linkedJob);
                                    }),
                                fleetAssets: props.fleetAssets,
                                staffMemberId: jobItem.staffMember && jobItem.staffMember.id,
                                secondStaffMemberId:
                                  jobItem.secondStaffMember && jobItem.secondStaffMember.id,
                                getTechSpecRequirements: api => jobItem.techSpecRequirements,
                                existingProgresses:
                                  jobProgress && jobProgress.jobId === jobItem.id
                                    ? jobProgress.progress
                                    : undefined,
                                currentAsset: jobItem.asset && jobItem.asset.id,
                                loadExistingProgresses: loadJobProgress,
                                continuationDetailsForVehicleSwap:
                                  jobItem.continuationInfoForVehicleSwap,
                              }),
                            ],
                          },
                        ],
                      }}
                      fieldMeta={d.meta}
                      paneData={d.data}
                      parentValue={jobItem}
                    />
                  </div>
                );
              }
            },
          },
          {
            fieldType: FieldType.customField,
            label: 'Staff Member',
            dataAddr: 'staffMember',
            columnWidth: '15em',
            hidden: d => d.parentValue.hasSubcontractor,
            render: api => {
              const job = api.data.parentValue as ListJobItem;
              if (job.jobStatus.id === JobStatus.Incomplete) {
                return 'Incomplete';
              }

              return (
                <>
                  {canManageJobs ? (
                    <Select
                      clearable
                      options={filterStaffMembers(
                        api.data.parentValue.asset && api.data.parentValue.asset.id,
                        job.skillSpecRequirements
                      )}
                      valueKey="id"
                      labelKey="name"
                      matchProp="label"
                      value={job.staffMember}
                      onChange={async s => {
                        const jobId = job.id;
                        const staffMember = s as StaffMemberDto;
                        const warnings = await props.onAllocateStaffMember({
                          jobId: jobId,
                          staffMemberId: staffMember && staffMember.id,
                        });

                        rootStore.compliance.fatigueValidations.setWarningMessages(warnings);

                        let jobIds = job.linkedJob ? [jobId, job.linkedJob] : [jobId];
                        await props.refreshJobs(
                          {
                            query: {
                              ...props.getQueryParams(),
                              jobIds: jobIds,
                            },
                            loadCause: ListPageLoadCause.refresh,
                          },
                          jobIdsToRemoveObject(jobIds)
                        );

                        await addJobToEditedJobs(jobId);
                      }}
                    />
                  ) : (
                    <div>{job.staffMember && job.staffMember.name}</div>
                  )}
                  {job.hasSecondStaffMember ? (
                    canManageJobs ? (
                      <Select
                        clearable
                        options={filterStaffMembers(
                          api.data.parentValue.asset && api.data.parentValue.asset.id,
                          job.skillSpecRequirements
                        )}
                        valueKey="id"
                        labelKey="name"
                        matchProp="label"
                        value={job.secondStaffMember}
                        onChange={s => {
                          const jobId = job.id;
                          const staffMember = s as StaffMemberDto;
                          props
                            .onAllocateStaffMember({
                              jobId: jobId,
                              staffMemberId: staffMember && staffMember.id,
                              asSecondStaffMember: true,
                            })
                            .then(r => {
                              let jobIds = [jobId];

                              props.refreshJobs(
                                {
                                  query: { ...props.getQueryParams(), jobIds: jobIds },
                                  loadCause: ListPageLoadCause.refresh,
                                },
                                jobIdsToRemoveObject(jobIds)
                              );
                            });
                        }}
                      />
                    ) : (
                      <div>{job.secondStaffMember && job.secondStaffMember.name}</div>
                    )
                  ) : null}
                  {job.trainer && (
                    <div>
                      <small>(Trainer: {job.trainer})</small>
                    </div>
                  )}
                  {job.trainee && (
                    <div>
                      <small>(Trainee: {job.trainee})</small>
                    </div>
                  )}
                </>
              );
            },
          },
        ],
        filterAction: {
          defaultValues: {
            dateFrom: today,
            dateTo: today,
          },
          filterFields: Object.keys(filterFieldDefsLookup).map(k => filterFieldDefsLookup[k]),
          filterDef: () => [
            {
              panes: [
                {
                  paneType: PaneType.formFieldsPane,
                  columnCount: 2,
                  fields: [filterFieldDefsLookup.dateFrom, filterFieldDefsLookup.dateTo],
                },
                {
                  paneType: PaneType.formFieldsPane,
                  fields: [
                    filterFieldDefsLookup.staffMembers,
                    filterFieldDefsLookup.staffMemberDepots,
                  ],
                },
                {
                  paneType: PaneType.formFieldsPane,
                  fields: [filterFieldDefsLookup.vehicles, filterFieldDefsLookup.garagedAt],
                },
                {
                  paneType: PaneType.formFieldsPane,
                  fields: [filterFieldDefsLookup.jobStatus, filterFieldDefsLookup.jobProgress],
                },
                {
                  paneType: PaneType.formFieldsPane,
                  fields: [
                    filterFieldDefsLookup.jobType,
                    filterFieldDefsLookup.isDriverManaged,
                    filterFieldDefsLookup.needsVerification,
                    filterFieldDefsLookup.isCancelled,
                    filterFieldDefsLookup.adhoc,
                    filterFieldDefsLookup.isTrainingJob,
                    filterFieldDefsLookup.subcontractors,
                    filterFieldDefsLookup.vehicleTypes,
                    filterFieldDefsLookup.customerId,
                    filterFieldDefsLookup.contactIds,
                    filterFieldDefsLookup.conflicts,
                    filterFieldDefsLookup.quoteTypes,
                    filterFieldDefsLookup.quotes,
                    filterFieldDefsLookup.railBookings,
                    filterFieldDefsLookup.shiftNames,
                    filterFieldDefsLookup.tradingCompanyId,
                  ],
                },
              ],
            },
          ],
        },
      };
    }
  );

  const {
    jobs,
    listJobs,
    hasMoreData,
    charterInstructions,
    jobWithClockOnClosestToNow,
    railInstructions,
    swapVehicle,
    jobProgress,
    loadJobProgress,
    charterDailyDiaryDetails,
  } = props;
  const filterFieldDefsLookup = getFilterFieldDefs();
  const def = getPageDef(
    listJobs,
    hasMoreData,
    filterFieldDefsLookup,
    charterInstructions,
    railInstructions,
    jobWithClockOnClosestToCurrentTimeRef,
    jobWithClockOnClosestToNow,
    swapVehicle,
    jobProgress,
    loadJobProgress,
    charterDailyDiaryDetails
  );
  return <ListPage className="list-job-for-charter-component" data={jobs} def={def} />;
};

export default withQueryParams(ListJobForCharter);
