import { IPanelDef } from './panel';
import { IActionGroupDef, IActionDef } from './action';
import { DataAddr } from './page';
import { IFormApi, IFormApiWithoutState, FieldName } from 'src/views/components/Page/forms/base';

export interface ISectionBadgeDef {
  label: string;
}

export interface ISectionData {
  // tslint:disable-next-line:no-any
  sectionValue: any;
}

interface ISectionDefBase {
  key?: string; // optional key for when multiple sections are together in the same area
  title: string | ((data: ISectionData) => string) | React.ReactNode;
  subtitle?: string;
  dataAddr?: DataAddr;
  // tslint:disable-next-line:no-any
  explicitData?: any; // explicitly set the data for the section - this will override the data from the page
  panels: Array<IPanelDef> | ((api: IFormApi) => Array<IPanelDef>);
  badge?: ISectionBadgeDef;
  primaryActions?: Array<IActionGroupDef>;
  secondaryActions?: Array<IActionGroupDef>;
  tertiaryActions?: Array<IActionGroupDef>;
  hidden?: boolean | ((data: ISectionData) => boolean);
  isTab?: boolean;
}

interface ISectionFormDef {
  asForm?: boolean;
  submitButtonDisabled?: IActionDef['disabled'];
  suppressLeavePrompt?: boolean;
  onFormResetRequested?: (api: IFormApi, cancelReset: () => void) => void;
  // tslint:disable-next-line:no-any
  onFormPreSubmit?: (values: any, submissionMeta: {} | undefined) => any;
  // tslint:disable-next-line:no-any
  onFormSubmit?: (values: any) => Promise<void>;
  onSubmitSucceeded?: () => Promise<void>;
  // tslint:disable-next-line:no-any
  onSubmitFailure?: (formErrors: string[], submitError: any) => void;
  getApi?: (api: IFormApiWithoutState) => void;
}

export type SectionDefs = ISectionDef | ISubsectionDef;

export interface ISectionDef extends ISectionDefBase, ISectionFormDef {
  isLogicalSubsection?: false;
  defaultTouchedFields?: Array<FieldName>;
}

export function isSectionDef(sectionDef: SectionDefs): sectionDef is ISectionDef {
  return !sectionDef.isLogicalSubsection;
}

export interface ISubsectionDef extends ISectionDefBase {
  isLogicalSubsection: true;
  key: string;
}

export function isSubsectionDef(sectionDef: SectionDefs): sectionDef is ISubsectionDef {
  return !!sectionDef.isLogicalSubsection;
}
