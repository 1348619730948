import styles from './Asset.module.scss';
import AssetDetails from '../AssetDetails/AssetDetails';
import { RouteComponentProps } from 'react-router';
import SplitScreen from '../components/layout/SplitScreen/SplitScreen';
import WithBackButton from '../menu/WithBackButton';

export interface IAssetProps {
  goBack: () => void;
}

type InternalProps = IAssetProps & RouteComponentProps<{ id: string }>;

export const Asset: React.FC<InternalProps> = ({ match, goBack }) => {
  const assetId = match.params.id;

  const left = (
    <div className={styles.left}>
      <WithBackButton goBack={goBack}>
        <AssetDetails assetId={assetId} />
      </WithBackButton>
    </div>
  );

  return <SplitScreen left={left} right={null} />;
};

export default Asset;
