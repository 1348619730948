import { IRootStoreModel } from 'src/domain/entities/RootStoreModel';
import { UiRoute } from 'src/domain/services/buildUiRoutes';
import { UiRouteName } from 'src/domain/baseTypes';

export default function buildUiRoutes(store: IRootStoreModel) {
  const smsCreate = new UiRoute(
    UiRouteName.peopleSmsCreate,
    '/people/sms/create',
    () => 'Create SMS',
    false
  );

  const smsManage = new UiRoute(
    UiRouteName.peopleSmsManage,
    '/people/sms/:id',
    () => 'View SMS',
    false
  );

  const smsList = new UiRoute(UiRouteName.peopleSmsList, '/people/sms', () => 'SMS', false, true, [
    smsCreate,
    smsManage,
  ]);

  return {
    [UiRouteName.peopleSmsCreate]: smsCreate,
    [UiRouteName.peopleSmsList]: smsList,
    [UiRouteName.peopleSmsManage]: smsManage,
  };
}
