import { Component } from 'react';
import { IFieldApi } from 'src/views/components/Page/forms/Field';
import { ICustomFieldDef, IFieldMeta, IFieldData } from 'src/views/definitionBuilders/types';
import { IFormApi } from '../forms/base';

interface ICustomPageFieldProps {
  fieldApi: IFieldApi;
  fieldDef: ICustomFieldDef;
  fieldMeta: IFieldMeta;
  // tslint:disable-next-line:no-any
  fieldData: IFieldData<any>;
  formApi: IFormApi;
}

class CustomPageField extends Component<ICustomPageFieldProps> {
  render() {
    const { fieldApi, fieldDef: def, fieldMeta: meta, fieldData: data, formApi } = this.props;
    return def.render({ meta, data, fieldApi, formApi });
  }
}

export default CustomPageField;
