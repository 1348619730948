import { getSubmitCloseModalActionGroupDef } from 'src/views/definitionBuilders/common';
import {
  PaneType,
  ModalDefBuilder,
  IActionGroupDef,
  ActionType,
  FieldType,
} from 'src/views/definitionBuilders/types';
import { ReactElement } from 'react';
import { CheckIcon } from 'src/images/icons';
import { Link } from 'react-router-dom';

type CanDeletePartCategory = Workshop.Domain.Queries.GetCanPartCategoryBeDeleted.GetCanPartCategoryBeDeletedResult;
type LinkedPart = Workshop.Domain.Queries.GetCanPartCategoryBeDeleted.LinkedPart;

const getModalText = (canDeletePartCategory: CanDeletePartCategory): ReactElement => {
  if (canDeletePartCategory.canBeDeleted) {
    return (
      <>
        <p>Are you sure you want to delete this part category?</p>
      </>
    );
  } else if (canDeletePartCategory.canBeInactive) {
    return (
      <>
        <p>This part category cannot be deleted, as it is linked to the parts listed below.</p>
        <p>
          As these parts have all been inactived, you can however mark the part category as
          inactive. Marking the part category as inactive will mean it won't be able to be linked to
          active parts.
        </p>
        <p>Please click Mark as Inactive below to confirm.</p>
      </>
    );
  }

  return (
    <>
      <p>This part category cannot be deleted as it is linked to the following parts:</p>
    </>
  );
};

const getSecondaryActions = (canDeletePartCategory: CanDeletePartCategory): IActionGroupDef[] => {
  if (canDeletePartCategory.canBeInactive) {
    return [getSubmitCloseModalActionGroupDef('Mark as Inactive')];
  } else if (canDeletePartCategory.canBeDeleted) {
    return [getSubmitCloseModalActionGroupDef('Delete')];
  }

  return [
    {
      actions: [
        {
          actionType: ActionType.closeModalActionButton,
          label: 'Cancel',
        },
      ],
    },
  ];
};

const getOnFormSubmitFunc = (
  partCategoryId: number,
  canDeletePartCategory: CanDeletePartCategory,
  deleteOwner: (partCategoryId: number) => Promise<void>,
  setOwnerInactive: (partCategoryId: number) => Promise<void>
): Promise<void> => {
  return canDeletePartCategory.canBeInactive
    ? setOwnerInactive(partCategoryId)
    : deleteOwner(partCategoryId);
};

export default function GetCanOwnerBeDeletedModalDef(
  partCategoryId: number,
  canDeletePartCategory: CanDeletePartCategory,
  deleteOwner: (partCategoryId: number) => Promise<void>,
  setOwnerInactive: (partCategoryId: number) => Promise<void>
): ModalDefBuilder {
  return modalDefApi => ({
    title: canDeletePartCategory.canBeInactive ? 'Mark as Inactive' : 'Delete Part Category',
    asForm: true,
    explicitData: !canDeletePartCategory.linkedParts ? [] : canDeletePartCategory,
    panels: [
      {
        panes: [
          {
            paneType: PaneType.customPane,
            render: api => getModalText(canDeletePartCategory),
          },
          {
            paneType: PaneType.tablePane,
            hidden: _ => canDeletePartCategory.canBeDeleted,
            dataAddr: 'linkedParts',
            title: `Linked Parts`,
            neverEditable: true,
            fields: [
              {
                fieldType: FieldType.readonlyField,
                dataAddr: ['name'],
                label: 'Name',
                formatReadonly: d => {
                  if (!d.parentValue) {
                    return undefined;
                  }

                  const data = d.parentValue as LinkedPart;
                  return <Link to={`/workshop/parts/${data.id}`}>{data.name}</Link>;
                },
              },

              {
                fieldType: FieldType.readonlyField,
                dataAddr: ['isActive'],
                label: 'Active',
                formatReadonly: d => (d.fieldValue ? <CheckIcon /> : undefined),
              },
            ],
          },
        ],
      },
    ],
    secondaryActions: getSecondaryActions(canDeletePartCategory),
    onFormSubmit: _ =>
      getOnFormSubmitFunc(partCategoryId, canDeletePartCategory, deleteOwner, setOwnerInactive),
  });
}
