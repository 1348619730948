import { IRootStoreModel } from 'src/domain/entities/RootStoreModel';
import { UiRoute } from 'src/domain/services/buildUiRoutes';
import { UiRouteName } from 'src/domain/baseTypes';

export default function buildUiRoutes(store: IRootStoreModel) {
  const fatigueBreachEventManage = new UiRoute(
    UiRouteName.complianceFatigueBreachEventManage,
    '/compliance/fatigue-breach-events/:id',
    () => 'Manage Fatigue Breach Event',
    true
  );

  const fatigueBreachEventsList = new UiRoute(
    UiRouteName.complianceFatigueBreachEventList,
    '/compliance/fatigue-breach-events',
    () => 'Fatigue Breach Events',
    false,
    false,
    [fatigueBreachEventManage]
  );

  return {
    [UiRouteName.complianceFatigueBreachEventList]: fatigueBreachEventsList,
    [UiRouteName.complianceFatigueBreachEventManage]: fatigueBreachEventManage,
  };
}
