import { TIMEZONE } from 'src/appSettings';

export const getStateTimezone = (boardingPoint?: { state: string }): string => {
  switch (boardingPoint?.state) {
    case 'QLD':
      return 'Australia/Brisbane';
    case 'NSW':
    case 'ACT':
      return 'Australia/Sydney';
    case 'VIC':
      return 'Australia/Melbourne';
    case 'SA':
      return 'Australia/Adelaide';
    case 'NT':
      return 'Australia/Darwin';
    case 'WA':
      return 'Australia/Perth';
    case 'TAS':
      return 'Australia/Hobart';
    default:
      return TIMEZONE;
  }
};
