import './WorkshopJobPopup.scss';

import { IWorkshopJobItem } from 'src/domain/entities/operations/job/ListJobsForAllocationsModel';
import { IntervalFormat } from 'src/views/components/IntervalFormat';
import PopupField from './PopupField';

interface IWorkshopJobPopupProps {
  job: IWorkshopJobItem;
}

const WorkshopJobPopup: React.FC<IWorkshopJobPopupProps> = (props: IWorkshopJobPopupProps) => {
  const { job } = props;
  return (
    <section className={`workshop-job-popup-component allocations-item-popup`}>
      <PopupField className="job-number" label="Workshop Job" value={job.jobNumber} />
      <PopupField
        className="job-timing"
        label="Scheduled"
        value={<IntervalFormat startValue={job.begin} endValue={job.end} />}
      />
      <PopupField
        className="tasks"
        label="Tasks"
        value={
          <ul>
            {job.tasks?.map(t => (
              <li key={t.id}>
                <span>{t.jobTaskNumber}</span>
                <span> - </span>
                {t.description}
              </li>
            ))}
          </ul>
        }
      />
    </section>
  );
};

export default WorkshopJobPopup;
