import { IAjax } from 'src/infrastructure/ajax';
import { workshopUrls } from '../../apiUrls';

type CreateRosterCommand = Workshop.Domain.Commands.Roster.CreateRoster.CreateRosterCommand;
type UpdateRosterCommand = Workshop.Domain.Commands.Roster.UpdateRoster.UpdateRosterCommand;
type RosterItem = Workshop.Domain.Queries.Roster.GetRoster.RosterItem;
type ListRostersQuery = Workshop.Domain.Queries.Roster.ListRosters.ListRostersQuery;
type RosterListItem = Workshop.Domain.Queries.Roster.ListRosters.RosterListItem;
type AutocompleteListRostersQuery = Workshop.Domain.Queries.Roster.AutocompleteListRosters.AutocompleteListRostersQuery;
type RosterAutocompleteListItem = Workshop.Domain.Queries.Roster.AutocompleteListRosters.RosterAutocompleteListItem;
type GetWeekWithoutRosterQuery = Workshop.Domain.Queries.Roster.GetWeekWithoutRoster.GetWeekWithoutRosterQuery;
type RosterWeek = Workshop.Domain.Queries.Roster.GetWeekWithoutRoster.RosterWeek;
type ListRosterNamesQuery = Workshop.Domain.Queries.Roster.ListRosterNames.ListRosterNamesQuery;
type RosterNameListItem = Workshop.Domain.Queries.Roster.ListRosterNames.RosterNameListItem;
type RosterChangesItem = Workshop.Domain.Queries.Roster.GetRosterChanges.RosterChangesItem;

export default function init(ajax: IAjax) {
  function createRoster(command: CreateRosterCommand) {
    return ajax
      .post(workshopUrls.rosterUrls.createRoster(), command)
      .toPromise()
      .then<string>(r => r.response);
  }

  function updateRoster(command: UpdateRosterCommand) {
    return ajax.put(workshopUrls.rosterUrls.updateRoster(command.rosterId), command).toPromise();
  }

  function getRoster(rosterId: string) {
    return ajax
      .get(workshopUrls.rosterUrls.getRoster(rosterId))
      .toPromise()
      .then<RosterItem>(r => r.response);
  }

  function getRosterChanges(rosterId: string) {
    return ajax
      .get(workshopUrls.rosterUrls.getRosterChanges(rosterId))
      .toPromise()
      .then<RosterChangesItem>(r => r.response);
  }

  function deleteRoster(rosterId: string) {
    return ajax.httpDelete(workshopUrls.rosterUrls.getRoster(rosterId)).toPromise();
  }

  function listRosters(query: Partial<ListRostersQuery>) {
    return ajax
      .get(workshopUrls.rosterUrls.listRosters(query))
      .map(r => r.response as Common.Dtos.ListResult<RosterListItem>);
  }

  function autocompleteListRosters(query: Partial<AutocompleteListRostersQuery>) {
    return ajax
      .get(workshopUrls.rosterUrls.autocompleteListRosters(query))
      .map(r => r.response as Common.Dtos.ListResult<RosterAutocompleteListItem>);
  }

  function getWeekWithoutRoster(query: Partial<GetWeekWithoutRosterQuery>) {
    return ajax
      .get(workshopUrls.rosterUrls.getWeekWithoutRoster(query))
      .toPromise()
      .then<RosterWeek>(r => r.response);
  }

  function listRosterNames(query: ListRosterNamesQuery) {
    return ajax
      .get(workshopUrls.rosterUrls.listRosterNames(query))
      .map(r => r.response as RosterNameListItem[]);
  }

  function exportRostersToExcel(query: Partial<ListRostersQuery>) {
    return ajax
      .getFile(workshopUrls.rosterUrls.exportRostersToExcel(query))
      .toPromise()
      .then<Blob>(r => r.response);
  }

  return {
    roster: {
      createRoster,
      updateRoster,
      getRoster,
      getRosterChanges,
      deleteRoster,
      listRosters,
      autocompleteListRosters,
      getWeekWithoutRoster,
      listRosterNames,
      exportRostersToExcel,
    },
  };
}
