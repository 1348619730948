import { DateTime } from 'luxon';
import { ChevronLeftIcon, ChevronRightIcon } from 'src/images/icons';
import ActionBar from 'src/views/components/ActionBar';
import { ILinkAction } from 'src/views/components/ActionBar/ActionBar';

interface ILeaveCalendarActionBar {
  zonedNow: DateTime;
  zonedWeekToDisplay: DateTime;
  getNavigationLink: (week: string) => {};
  isLoading: boolean;
}

const LeaveCalendarActionBar: React.FC<ILeaveCalendarActionBar> = ({
  zonedNow,
  zonedWeekToDisplay,
  getNavigationLink,
  isLoading,
}) => {
  const prev = zonedWeekToDisplay.minus({ weeks: 1 });
  const next = zonedWeekToDisplay.plus({ weeks: 1 });

  const alreadyOnThisWeek = zonedWeekToDisplay.weekNumber === zonedNow.weekNumber;

  const prevAction: ILinkAction = {
    label: <ChevronLeftIcon size="sm" />,
    size: 'sm',
    to: getNavigationLink(prev.toISOWeekDate()),
    disabled: isLoading,
  };
  const todayAction: ILinkAction = {
    label: 'Go to This Week',
    size: 'sm',
    to: getNavigationLink(zonedNow.set({ weekday: 1 }).toISOWeekDate()),
    disabled: alreadyOnThisWeek || isLoading,
  };
  const nextAction: ILinkAction = {
    label: <ChevronRightIcon size="sm" />,
    size: 'sm',
    to: getNavigationLink(next.toISOWeekDate()),
    disabled: isLoading,
  };
  return <ActionBar groups={[[prevAction, todayAction, nextAction]]} />;
};

export default LeaveCalendarActionBar;
