import { Component } from 'react';
import { FieldType, IFieldOnChange } from 'src/views/definitionBuilders/types/field';
import { PaneType } from 'src/views/definitionBuilders/types/pane';
import CrudPage, { CrudPageMode, ICrudPageDef } from 'src/views/components/Page/pages/CrudPage';
import memoizeOne from 'memoize-one';
import deepEqual from 'src/infrastructure/deepEqual';
import { ChevronUpIcon, ChevronDownIcon } from 'src/images/icons';
import { ActionType, PagePrimarySize } from 'src/views/definitionBuilders/types';
import {
  ChangeState,
  allComponentTypeServiceIntervalType,
  ComponentTypeServiceIntervalType,
} from 'src/api/enums';
import PrimaryTitle from 'src/views/components/Page/PrimaryTitle/PrimaryTitle';

type ComponentTypeItem = Workshop.Domain.Queries.ComponentType.ComponentTypeItem;
type UpdateComponentTypesCommand = Workshop.Domain.Commands.ComponentType.UpdateComponentTypesCommand;

export interface IMaintainComponentTypesProps {
  mode: CrudPageMode;
  canManageComponentTypes: boolean;
  listComponentTypes: () => Promise<void>;
  componentTypes: ComponentTypeItem[];
  updateComponentTypes: (cmd: UpdateComponentTypesCommand) => Promise<void>;
}

interface IFormData {
  componentTypes: ComponentTypeItem[];
}

class MaintainComponentTypes extends Component<IMaintainComponentTypesProps> {
  private readonly handlePreSubmit = (values: IFormData): UpdateComponentTypesCommand => {
    const getChangeState = (componentType: ComponentTypeItem, currentIndex: number) => {
      if (
        componentType.changeState === ChangeState.Added ||
        componentType.changeState === ChangeState.Deleted
      ) {
        return componentType.changeState;
      }
      const originalComponentTypeIndex =
        this.props.componentTypes &&
        this.props.componentTypes.findIndex(ct => ct.id === componentType.id);
      if (originalComponentTypeIndex < 0) {
        throw new Error('Cannot find original Tech Spec');
      }
      const originalComponentType = this.props.componentTypes[originalComponentTypeIndex];
      return currentIndex === originalComponentTypeIndex &&
        deepEqual(componentType, originalComponentType)
        ? ChangeState.Unchanged
        : ChangeState.Modified;
    };

    return {
      componentTypeItems: values.componentTypes.map((v, i) => {
        return {
          ...v,
          changeState: getChangeState(v, i),
        };
      }),
    } as UpdateComponentTypesCommand;
  };

  private getData(): IFormData {
    return {
      componentTypes: this.props.componentTypes || [],
    };
  }

  private readonly getPageDef = memoizeOne(
    (updating: boolean, componentTypes: ComponentTypeItem[]): ICrudPageDef => {
      return {
        primarySize: PagePrimarySize.twoThirds,
        primarySection: {
          title: (
            <PrimaryTitle
              title="Component Types"
              link="https://www.notion.so/central-ops/Components-Types-dc88f09fc0604deb8153c5936f83e937"></PrimaryTitle>
          ),
          panels: [
            {
              dataAddr: 'componentTypes',
              panes: [
                {
                  paneType: PaneType.tablePane,
                  dataRequiredForRows: 'paneValue',
                  hidden: d => {
                    return false;
                  },
                  fields: [
                    {
                      fieldType: FieldType.textField,
                      dataAddr: 'description',
                      label: 'Description',
                      maxLength: 200,
                      mandatory: true,
                      validate: d => {
                        if (!d.fieldValue || !updating) {
                          return undefined;
                        }
                        const duplicated = (d.paneValue as ComponentTypeItem[]).some(
                          x =>
                            x.id !== d.parentValue.id &&
                            x.changeState !== ChangeState.Deleted &&
                            x.description === d.parentValue.description
                        );
                        return duplicated ? `Description is already in use` : undefined;
                      },
                    },
                    {
                      fieldType: FieldType.selectField,
                      dataAddr: 'serviceIntervalType',
                      label: 'Interval Type',
                      columnWidth: '10em',
                      valueKey: 'value',
                      descriptionKey: 'description',
                      optionItems: allComponentTypeServiceIntervalType,
                      useValueOnly: true,
                      onChange: (api: IFieldOnChange<ComponentTypeServiceIntervalType>) => {
                        const addr = api.fieldDataAddr;
                        addr.pop();
                        if (api.newFieldValue === ComponentTypeServiceIntervalType.Day) {
                          api.setFormValue([...addr, 'serviceIntervalKms'], undefined);
                        } else if (api.newFieldValue === ComponentTypeServiceIntervalType.Km) {
                          api.setFormValue([...addr, 'serviceIntervalDays'], undefined);
                        } else {
                          api.setFormValue([...addr, 'serviceIntervalKms'], undefined);
                          api.setFormValue([...addr, 'serviceIntervalDays'], undefined);
                        }
                      },
                    },
                    {
                      fieldType: FieldType.numericField,
                      dataAddr: 'serviceIntervalDays',
                      label: 'Every x Days',
                      numericConfig: { numericType: 'unsignedInt', maxTotalDigits: 4 },
                      columnWidth: '10em',
                      mandatory: d =>
                        (d.parentValue as ComponentTypeItem).serviceIntervalType ===
                        ComponentTypeServiceIntervalType.Day,
                      validate: d => {
                        const comp = d.parentValue as ComponentTypeItem;

                        return comp.serviceIntervalType === ComponentTypeServiceIntervalType.Day &&
                          (!comp.serviceIntervalDays || comp.serviceIntervalDays < 0)
                          ? 'Non negative number of days should be provided'
                          : undefined;
                      },
                      readonly: d =>
                        (d.parentValue as ComponentTypeItem).serviceIntervalType !==
                        ComponentTypeServiceIntervalType.Day,
                    },
                    {
                      fieldType: FieldType.numericField,
                      dataAddr: 'serviceIntervalKms',
                      label: 'Every x Kms',
                      columnWidth: '10em',
                      numericConfig: { numericType: 'unsignedInt', maxTotalDigits: 7 },
                      mandatory: d =>
                        (d.parentValue as ComponentTypeItem).serviceIntervalType ===
                        ComponentTypeServiceIntervalType.Km,
                      validate: d => {
                        const comp = d.parentValue as ComponentTypeItem;

                        return comp.serviceIntervalType === ComponentTypeServiceIntervalType.Km &&
                          (!comp.serviceIntervalKms || comp.serviceIntervalKms < 0)
                          ? 'Non negative number of kms should be provided'
                          : undefined;
                      },
                      readonly: d =>
                        (d.parentValue as ComponentTypeItem).serviceIntervalType !==
                        ComponentTypeServiceIntervalType.Km,
                    },
                    {
                      fieldType: FieldType.actionListField,
                      columnWidth: '1px',
                      actionGroups: [
                        {
                          actions: [
                            {
                              actionType: ActionType.moveArrayItemActionButton,
                              label: 'Move up',
                              icon: <ChevronUpIcon />,
                              moveDirection: 'prev',
                              hidden: d =>
                                !updating ||
                                (d.paneValue as ComponentTypeItem[]).indexOf(d.parentValue) === 0,
                            },
                            {
                              actionType: ActionType.moveArrayItemActionButton,
                              label: 'Move down',
                              icon: <ChevronDownIcon />,
                              moveDirection: 'next',
                              hidden: d =>
                                !updating ||
                                (d.paneValue as ComponentTypeItem[]).indexOf(d.parentValue) ===
                                  (d.paneValue as ComponentTypeItem[]).length - 1,
                            },
                            {
                              actionType: ActionType.removeArrayItemActionButton,
                              label: 'Remove Component Type',
                              hidden: !updating,
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
                {
                  paneType: PaneType.actionListPane,
                  actionGroups: [
                    {
                      actions: [
                        {
                          actionType: ActionType.addArrayItemActionButton,
                          hidden: !updating,
                          label: 'Add Component Type',
                          newItemData: {},
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
          onFormPreSubmit: this.handlePreSubmit,
          onFormSubmit: this.props.updateComponentTypes,
        },
      };
    }
  );

  render() {
    const { canManageComponentTypes } = this.props;
    return (
      <CrudPage
        data={this.getData()}
        def={({ updating }) => this.getPageDef(updating, this.props.componentTypes)}
        className="list-ComponentTypes-component"
        mode={this.props.mode}
        isEditingForbidden={!canManageComponentTypes}
        onLoadData={() => this.props.listComponentTypes()}
      />
    );
  }
}

export default MaintainComponentTypes;
