import { IRootStoreModel } from 'src/domain/entities/RootStoreModel';
import { UiRoute } from 'src/domain/services/buildUiRoutes';
import { UiRouteName } from 'src/domain/baseTypes';

export default function buildUiRoutes(store: IRootStoreModel) {
  const boardingPointsCreate = new UiRoute(
    UiRouteName.salesBoardingPointCreate,
    '/sales/boarding-points/create',
    () => 'Create Boarding Point',
    false
  );

  const boardingPointsManage = new UiRoute(
    UiRouteName.salesBoardingPointManage,
    '/sales/boarding-points/:id',
    () => 'Manage Boarding Point',
    false
  );

  const boardingPointsList = new UiRoute(
    UiRouteName.salesBoardingPointList,
    '/sales/boarding-points',
    () => 'Boarding Points',
    false,
    true,
    [boardingPointsCreate, boardingPointsManage]
  );

  return {
    [UiRouteName.salesBoardingPointList]: boardingPointsList,
    [UiRouteName.salesBoardingPointManage]: boardingPointsManage,
    [UiRouteName.salesBoardingPointCreate]: boardingPointsCreate,
  };
}
