import { IRootStoreModel } from 'src/domain/entities/RootStoreModel';
import { UiRoute } from 'src/domain/services/buildUiRoutes';
import { UiRouteName } from 'src/domain/baseTypes';

export default function buildUiRoutes(store: IRootStoreModel) {
  const staffMemberCreate = new UiRoute(
    UiRouteName.peopleStaffMemberCreate,
    '/people/staff-members/create',
    () => 'Create Staff Member',
    false
  );

  const staffMemberManage = new UiRoute(
    UiRouteName.peopleStaffMemberManage,
    '/people/staff-members/:id',
    () => 'Manage Staff Member',
    false
  );

  const staffMembersList = new UiRoute(
    UiRouteName.peopleStaffMemberList,
    '/people/staff-members',
    () => 'Staff Members',
    false,
    true,
    [staffMemberCreate, staffMemberManage]
  );

  return {
    [UiRouteName.peopleStaffMemberList]: staffMembersList,
    [UiRouteName.peopleStaffMemberCreate]: staffMemberCreate,
    [UiRouteName.peopleStaffMemberManage]: staffMemberManage,
  };
}
