import styles from './LabelledField.module.scss';
import cn from 'classnames';

export interface ILabelledFieldProps {
  label: string;
  text?: string | number;
  className?: string;
}

export const LabelledField: React.FC<ILabelledFieldProps> = ({ label, text, className }) => {
  return (
    <div className={cn(styles.container, className)}>
      <div className={styles.label}>{label}</div>
      <div className={styles.text}>{text}</div>
    </div>
  );
};
