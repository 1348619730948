import inject from 'src/views/injectFromStore';
import MaintainReportableEvent, { IMaintainReportableEventProps } from './MaintainReportableEvent';
import Omit from 'src/infrastructure/omit';

const Container = inject<
  Omit<IMaintainReportableEventProps, 'mode'>,
  IMaintainReportableEventProps
>((allStores, p) => ({
  canManageReportableEvents: allStores.rootStore.account.isOperationsDepartmentMember,
  loadReportableEvent: allStores.rootStore.compliance.reportableEvent.item.loadReportableEvent,
  reportableEvent: allStores.rootStore.compliance.reportableEvent.item.reportableEvent,
  createReportableEvent: allStores.rootStore.compliance.reportableEvent.item.createReportableEvent,
  updateReportableEvent: allStores.rootStore.compliance.reportableEvent.item.updateReportableEvent,
  updateReportableEventVehicleInformation:
    allStores.rootStore.compliance.reportableEvent.item.updateReportableEventVehicleInformation,
  updateReportableEventInternalInformation:
    allStores.rootStore.compliance.reportableEvent.item.updateReportableEventInternalInformation,
  loadCategoriesForContract:
    allStores.rootStore.compliance.reportableEvent.item.loadCategoriesForContract,
  loadAllContracts: allStores.rootStore.compliance.reportableEvent.item.loadAllContracts,
  categories: allStores.rootStore.compliance.reportableEvent.item.categories.slice(),
  contracts: allStores.rootStore.compliance.reportableEvent.item.contracts.slice(),
  reportableEventContacts: allStores.rootStore.people.staffMembers.reportableEventContacts.slice(),
  loadReportableEventContacts: allStores.rootStore.people.staffMembers.loadReportableEventContacts,
  loadStaffMembers: allStores.rootStore.people.staffMembers.loadAllStaffMembers,
  staffMembers: allStores.rootStore.people.staffMembers.allStaffMembers.slice(),
  assets: allStores.rootStore.assets.fleetAssetListItems.slice(),
  loadAssetListItems: allStores.rootStore.assets.loadFleetAssets,
  clearReportableEvent: allStores.rootStore.compliance.reportableEvent.item.clearReportableEvent,
}))(MaintainReportableEvent);

export default Container;
