import { Component, Fragment } from 'react';
import { NestedField } from 'src/views/components/Page/forms';
import { DataAddr } from 'src/views/definitionBuilders/types';
import { IFormApi } from 'src/views/components/Page/forms/base';

interface IPageNestedItemProps {
  formApi: IFormApi;
  dataAddr: DataAddr | undefined;
}

class PageNestedItem extends Component<IPageNestedItemProps> {
  shouldComponentUpdate(nextProps: Readonly<IPageNestedItemProps>) {
    // Don't waste time rendering children when the form is submitting
    return !nextProps.formApi.submitting;
  }

  render() {
    const { dataAddr, children } = this.props;
    // If we have a dataAddr, then use it as a key as currently NestedField does not update well
    // (key change forces unmount/remount)
    const flatDataAddr = Array.isArray(dataAddr) ? dataAddr.join('.') : dataAddr;
    if (flatDataAddr) {
      return (
        <NestedField key={flatDataAddr} field={flatDataAddr}>
          {children}
        </NestedField>
      );
    } else {
      return <Fragment>{children}</Fragment>;
    }
  }
}

export default PageNestedItem;
