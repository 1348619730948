import { IRootStoreModel } from 'src/domain/entities/RootStoreModel';
import { UiRoute } from 'src/domain/services/buildUiRoutes';
import { UiRouteName } from 'src/domain/baseTypes';

export default function buildUiRoutes(store: IRootStoreModel) {
  const jobAllocations = new UiRoute(
    UiRouteName.operationsJobAllocations,
    '/operations/jobs/allocations',
    () => 'Job Allocations',
    true
  );

  const jobCreate = new UiRoute(
    UiRouteName.operationsJobCreate,
    '/operations/jobs/create',
    () => 'Create Adhoc Job',
    true
  );

  const jobUpdate = new UiRoute(
    UiRouteName.operationsJobUpdate,
    '/operations/jobs/:id',
    () => 'Manage Job',
    true
  );

  const jobList = new UiRoute(
    UiRouteName.operationsJobList,
    '/operations/jobs',
    () => 'Jobs',
    false,
    false,
    [jobCreate, jobAllocations, jobUpdate]
  );

  const jobForCharterList = new UiRoute(
    UiRouteName.operationsJobForCharterList,
    '/operations/charter-diary',
    () => 'Charter Diary',
    false
  );

  const jobProgressWarningsList = new UiRoute(
    UiRouteName.operationsJobProgressWarningsList,
    '/operations/progress-warnings',
    () => 'Job Progress Warnings',
    false
  );

  const jobProgressUpdate = new UiRoute(
    UiRouteName.operationsJobUpdate,
    '/operations/jobs/:id/progress',
    () => 'Manage Job Progress',
    true
  );

  return {
    [UiRouteName.operationsJobList]: jobList,
    [UiRouteName.operationsJobCreate]: jobCreate,
    [UiRouteName.operationsJobAllocations]: jobAllocations,
    [UiRouteName.operationsJobUpdate]: jobUpdate,
    [UiRouteName.operationsJobProgressUpdate]: jobProgressUpdate,
    [UiRouteName.operationsJobProgressCreate]: jobProgressUpdate,
    [UiRouteName.operationsJobForCharterList]: jobForCharterList,
    [UiRouteName.operationsJobProgressWarningsList]: jobProgressWarningsList,
  };
}
