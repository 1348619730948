import { types, getRoot, flow } from 'mobx-state-tree';
import { IRootStoreModel } from 'src/domain/entities/RootStoreModel';
import { getAjax } from 'src/domain/services';

type CreateDefectCommand = Workshop.Domain.Commands.Defect.CreateDefectCommand;
type MarkOutOfServiceDefectCommand = Workshop.Domain.Commands.Defect.MarkOutOfServiceDefectCommand;
type ActivityLogTransaction = Workshop.Domain.Queries.ActivityLog.ActivityLogTransaction;
type DefectItem = Workshop.Domain.Queries.Defect.GetDefectItem.DefectItem;
type DefectStatus = Workshop.Domain.AggregatesModel.DefectAggregate.DefectStatus;
type JobTaskListItem = Workshop.Domain.Queries.JobTask.JobTaskListItem;
type SetDefectClosedWithoutBeingFixedCommand = Workshop.Domain.Commands.Defect.SetDefectClosedWithoutBeingFixedCommand;

export const DefectModel = types
  .model('DefectModel', {
    defect: types.maybe(types.frozen<DefectItem>()),
    statuses: types.array(types.frozen<DefectStatus>()),
    activityLogs: types.array(types.frozen<ActivityLogTransaction>()),
    tasks: types.array(types.frozen<JobTaskListItem>()),
  })
  .actions(self => {
    const ajax = getAjax(self);
    const root = getRoot(self) as IRootStoreModel;

    const loadDefect = flow(function*(defectId: string) {
      self.defect = yield ajax.defect.getDefect(defectId);
    });

    const createDefect = flow(function*(command: CreateDefectCommand) {
      const id = yield ajax.defect.createDefect(command);
      self.defect = undefined;
      root.history.push(`/workshop/defects/${id}`);
    });

    const markOutOfServiceDefect = flow(function*(command: MarkOutOfServiceDefectCommand) {
      const id = yield ajax.defect.markOutOfServiceDefect(command);
      yield loadDefect(id);
      root.history.push(`/workshop/defects/${id}`);
    });

    const loadDefectStatuses = flow(function*() {
      self.statuses = yield ajax.defect.getDefectStatuses().then(statuses =>
        statuses.map(status => {
          return { value: status.id, label: status.description };
        })
      );
    });

    const getActivityLogForDefect = flow(function*(defectId: string) {
      self.activityLogs = yield ajax.workshop.activityLog.getActivityLogForDefect(defectId);
    });

    const getTasksForDefect = flow(function*(defectId: string) {
      self.tasks = yield ajax.defect.getTasksForDefect(defectId);
    });

    const clearActivityLogForDefect = () => {
      self.activityLogs.clear();
    };

    const viewDefect = flow(function*(defectId: string) {
      self.defect = yield ajax.defect.getDefect(defectId);
    });

    const acknowledgeDefect = flow(function*(defectId: string) {
      yield ajax.defect.acknowledgeDefect(defectId);
      yield loadDefect(defectId);
      clearActivityLogForDefect();
      yield getActivityLogForDefect(defectId);
      root.history.push(`/workshop/defects/${defectId}`);
    });

    const setDefectClosedWithoutBeingFixed = flow(function*(
      command: SetDefectClosedWithoutBeingFixedCommand
    ) {
      yield ajax.defect.setDefectClosedWithoutBeingFixed(command);
      self.defect = yield ajax.defect.getDefect(command.defectId);
    });

    const clearTasksForDefect = () => {
      self.tasks.clear();
    };

    return {
      createDefect,
      markOutOfServiceDefect,
      loadDefect,
      loadDefectStatuses,
      viewDefect,
      getActivityLogForDefect,
      getTasksForDefect,
      clearActivityLogForDefect,
      acknowledgeDefect,
      setDefectClosedWithoutBeingFixed,
      clearTasksForDefect,
    };
  });
