import { CalendarCancelIcon } from 'src/images/icons';
import { getSubmitCloseModalActionGroupDef } from 'src/views/definitionBuilders/common';
import {
  ActionType,
  ShellModalSize,
  PaneType,
  IModalActionButtonDef,
} from 'src/views/definitionBuilders/types';

export default function getCancelJobButtonDef(
  childJobCount: number,
  continuesToNextJob: boolean,
  cancelJob: () => Promise<void>
): IModalActionButtonDef {
  return {
    actionType: ActionType.modalActionButton,
    label: 'Cancel Job In Progress',
    icon: <CalendarCancelIcon />,
    modalSize: ShellModalSize.oneQuarter,
    modalDef: modalDefApi => ({
      title: 'Cancel Job In Progress',
      asForm: true,
      panels: [
        {
          panes: [
            {
              paneType: PaneType.customPane,
              render: () => (
                <div>
                  {continuesToNextJob ? (
                    <p>
                      <strong>Note: </strong>
                      <span>This will also cancel the continuation to next job.</span>
                    </p>
                  ) : null}
                  {childJobCount ? (
                    <p>
                      <strong>Note: </strong>
                      <span>
                        This will also cancel the {childJobCount}{' '}
                        {childJobCount === 1 ? 'linked job' : 'linked jobs'}.
                      </span>
                    </p>
                  ) : null}
                  <p>Are you sure you want to cancel this job?</p>
                </div>
              ),
            },
          ],
        },
      ],
      secondaryActions: [getSubmitCloseModalActionGroupDef('Submit')],
      onFormSubmit: cancelJob,
    }),
  };
}
