import { IAjax } from 'src/infrastructure/ajax';
import { complianceUrls } from 'src/domain/services/apiUrls';
import { DateTime } from 'luxon';

type PaidHoursInPayPeriod = Operations.Domain.Queries.GetPaidHoursInPayPeriodForDay.PaidHoursInPayPeriod;

export default function init(ajax: IAjax) {
  function getPaidHoursInPayPeriodForDay(day: DateTime) {
    return ajax
      .get(complianceUrls.fatigueUrls.getPaidHoursInPayPeriodForDay(day))
      .map(r => r.response as PaidHoursInPayPeriod[]);
  }

  function getFatigueDetails(
    query: Partial<Operations.Domain.Queries.GetFatigueDetails.GetFatigueDetailsQuery>
  ) {
    return ajax.get(complianceUrls.fatigueUrls.getFatigueDetails(query));
  }

  return {
    fatigue: {
      getPaidHoursInPayPeriodForDay,
      getFatigueDetails,
    },
  };
}
