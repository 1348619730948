import { Component } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { ChangeState } from 'src/api/enums';
import deepEqual from 'src/infrastructure/deepEqual';
import {
  ActionType,
  FieldType,
  PagePrimarySize,
  PaneType,
} from 'src/views/definitionBuilders/types';
import CrudPage, { CrudPageMode, ICrudPageDef } from 'src/views/components/Page/pages/CrudPage';
import {
  getActivationAndDeletionActionButtons,
  ISetActiveCommand,
  ISetInactiveCommand,
} from 'src/views/components/ActivationAndDeletionActionsButtons/getActivationAndDeletionActionButtons';

type AggregateUsageDto = Common.Dtos.AggregateUsageDto;
type UniqueNameCheckResultDto = Common.Dtos.UniqueNameCheckResultDto;
type EngineDetails = Workshop.Domain.Queries.Engine.GetEngine.EngineDetails;
type CreateEngineCommand = Workshop.Domain.Commands.Engine.CreateEngineCommand;
type UpdateEngineCommand = Workshop.Domain.Commands.Engine.UpdateEngineCommand;
type JobTaskSubcategory = Workshop.Domain.Queries.GetTaskCategories.JobTaskSubcategoryListItem;
type ServicePartsGroup = Workshop.Domain.Commands.Engine.CreateEngineCommand.ServicePartsGroup;
type EnginePartsGroupsDetails = Workshop.Domain.Queries.Engine.GetEngine.EnginePartsGroupsDetails;
type PartsGroupItem = Workshop.Domain.Queries.GetPartsGroups.PartsGroupItem;

const defaultEngine = {
  servicePartGroups: [],
};

export interface IMaintainEngineProps {
  mode: CrudPageMode;
  canManageEngines: boolean;
  onLoadEngine: (id: string) => Promise<void>;
  engine: EngineDetails | undefined;
  serviceTypes: Array<JobTaskSubcategory>;
  partsGroups: Array<PartsGroupItem>;
  onCheckForUniqueName: (name: string) => Promise<UniqueNameCheckResultDto>;
  onCreateEngine: (command: CreateEngineCommand) => Promise<void>;
  onUpdateEngine: (command: UpdateEngineCommand) => Promise<void>;
  onLoadServiceTypes: () => Promise<void>;
  onLoadPartsGroups: (includeInactivePartsGroups: boolean) => Promise<void>;
  setEngineInactive: (command: ISetInactiveCommand) => Promise<void>;
  setEngineActive: (command: ISetActiveCommand) => Promise<void>;
  deleteEngine: (id: string) => Promise<void>;
  getEngineUsage: (id: string) => Promise<void>;
  engineUsage: AggregateUsageDto | undefined;
}

interface IUpdateEngineRouteParams {
  id: string;
}

type InternalProps = IMaintainEngineProps & RouteComponentProps<IUpdateEngineRouteParams>;

class MaintainEngine extends Component<InternalProps> {
  componentDidMount() {
    this.props.onLoadServiceTypes();
    this.props.onLoadPartsGroups(true);
    this.props.getEngineUsage(this.engineId);
  }

  private get isUpdateMode() {
    return this.props.mode === 'update';
  }

  private get isCreateMode() {
    return this.props.mode === 'create';
  }

  private get engineId() {
    return this.props.match.params.id;
  }

  private handlePreSubmitForCreate = (values: EngineDetails): CreateEngineCommand => {
    return {
      model: values.model,
      servicePartsGroups: values.partsGroups.map(item => {
        return {
          jobTaskSubcategoryId: item.jobTaskSubcategoryId,
          partsGroupId: item.partsGroupId,
        } as ServicePartsGroup;
      }),
    } as Workshop.Domain.Commands.Engine.CreateEngineCommand;
  };

  private handlePreSubmitForUpdate = (values: EngineDetails): UpdateEngineCommand => {
    const originalEngine = this.props.engine;
    const getChangeState = (pg: EnginePartsGroupsDetails) => {
      if (pg.changeState === ChangeState.Added || pg.changeState === ChangeState.Deleted) {
        return pg.changeState;
      }
      const originalPartsGroup =
        originalEngine && originalEngine.partsGroups.find(x => x.id === pg.id);
      if (!originalPartsGroup) {
        throw new Error('Cannot find original Parts Group');
      }
      return deepEqual(pg, originalPartsGroup) ? ChangeState.Unchanged : ChangeState.Modified;
    };

    return {
      engineId: values.id,
      model: values.model,
      servicePartsGroups: values.partsGroups.map(x => ({
        id: x.id,
        jobTaskSubcategoryId: x.jobTaskSubcategoryId,
        partsGroupId: x.partsGroupId,
        changeState: getChangeState(x),
      })),
    };
  };

  private readonly getPageDef = (mode: CrudPageMode, updating: boolean): ICrudPageDef => {
    const {
      engine,
      serviceTypes,
      partsGroups,
      onCheckForUniqueName,
      onCreateEngine,
      onUpdateEngine,
    } = this.props;

    return {
      primarySize: PagePrimarySize.twoThirds,
      primarySection: {
        title: this.isUpdateMode ? `Manage Engine` : 'Create an Engine',
        badge:
          this.isUpdateMode && !this.props.engine?.isActive ? { label: 'Inactive' } : undefined,
        primaryActions:
          !updating && !this.isCreateMode
            ? [
                {
                  actions: [
                    {
                      actionType: ActionType.actionCollection,
                      actionGroups: [
                        {
                          actions: getActivationAndDeletionActionButtons(
                            this.engineId,
                            'Engine',
                            this.props.engine?.isActive,
                            this.props.setEngineActive,
                            this.props.setEngineInactive,
                            this.props.deleteEngine,
                            this.props.engineUsage,
                            this.props.onLoadEngine
                          ),
                        },
                      ],
                    },
                  ],
                },
              ]
            : [],
        panels: [
          {
            panes: [
              {
                paneType: PaneType.formFieldsPane,
                fields: [
                  {
                    fieldType: FieldType.textField,
                    label: 'Model',
                    dataAddr: 'model',
                    maxLength: 255,
                    mandatory: true,
                    validateAsync: async d => {
                      if (
                        !d.fieldValue ||
                        (this.isUpdateMode &&
                          engine &&
                          engine.model.toUpperCase() === d.fieldValue.toUpperCase())
                      ) {
                        return undefined;
                      }
                      const result = await onCheckForUniqueName(d.fieldValue);
                      return result.nameExists ? `Model is already in use` : undefined;
                    },
                  },
                ],
              },
            ],
          },
          {
            title: 'Parts Groups',
            dataAddr: 'partsGroups',
            panes: [
              {
                paneType: PaneType.tablePane,
                mandatory: true,
                dataRequiredForRows: 'panelValue',
                validate: d => {
                  const items = (d.panelValue as EnginePartsGroupsDetails[]).filter(
                    i => i.changeState !== ChangeState.Deleted
                  );
                  const distinctServiceTypes = new Set(items.map(i => i.jobTaskSubcategoryId));
                  return distinctServiceTypes.size !== items.length
                    ? 'Each service type can only be defined once'
                    : undefined;
                },
                fields: [
                  {
                    fieldType: FieldType.selectField,
                    label: 'Service Type',
                    dataAddr: 'jobTaskSubcategoryId',
                    useValueOnly: true,
                    valueKey: 'id',
                    descriptionKey: 'description',
                    mandatory: true,
                    optionItems: serviceTypes,
                    valuesToDisable: d => {
                      const items = (d.panelValue as EnginePartsGroupsDetails[])
                        .filter(x => x.changeState !== ChangeState.Deleted)
                        .map(x => x.jobTaskSubcategoryId);
                      return items;
                    },
                    readonly: d => {
                      const item = d.parentValue as EnginePartsGroupsDetails;
                      return item.changeState !== ChangeState.Added;
                    },
                    columnWidth: '12em',
                  },
                  {
                    fieldType: FieldType.selectField,
                    label: 'Parts Group',
                    dataAddr: 'partsGroupId',
                    useValueOnly: true,
                    valueKey: 'id',
                    descriptionKey: 'name',
                    mandatory: true,
                    optionItems: d => partsGroups.filter(o => o.active || o.id === d.fieldValue),
                    linkTo: d => `/workshop/parts-groups/${d.fieldValue}`,
                  },
                  {
                    fieldType: FieldType.actionListField,
                    dataAddr: '',
                    columnWidth: '1px',
                    actionGroups: [
                      {
                        actions: [
                          {
                            hidden: this.isUpdateMode && !updating,
                            actionType: ActionType.removeArrayItemActionButton,
                            label: 'Remove Parts Group',
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
              {
                paneType: PaneType.actionListPane,
                hidden: this.isUpdateMode && !updating,
                actionGroups: [
                  {
                    actions: [
                      {
                        actionType: ActionType.addArrayItemActionButton,
                        label: 'Add Parts Group',
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
        onFormPreSubmit: this.isUpdateMode
          ? this.handlePreSubmitForUpdate
          : this.handlePreSubmitForCreate,
        onFormSubmit: this.isUpdateMode ? onUpdateEngine : onCreateEngine,
      },
    };
  };

  render() {
    const { mode, engine, onLoadEngine, canManageEngines } = this.props;
    return (
      <CrudPage
        def={({ updating }) => this.getPageDef(mode, updating)}
        mode={mode}
        isEditingForbidden={!canManageEngines}
        onLoadData={() => onLoadEngine(this.engineId)}
        data={engine}
        createDefaultData={defaultEngine}
      />
    );
  }
}

export default MaintainEngine;
