import { Link } from 'react-router-dom';
import { CheckIcon, ExcelIcon } from 'src/images/icons';
import { useRootStore } from 'src/domain/entities/RootStoreModel';
import ExtraDetails from 'src/views/components/operations/ExtraDetails/ExtraDetails';
import ListPage, { IListPageDef } from 'src/views/components/Page/pages/ListPage';
import { ActionType, PagePrimarySize, PaneType } from 'src/views/definitionBuilders/types';
import { FieldDefs, FieldType } from 'src/views/definitionBuilders/types/field';
import styles from './ListContractCharterBookings.module.scss';
import { observer } from 'mobx-react';
import TaskCardItem from 'src/views/components/TaskCard/TaskCardItem';
import { DateTime } from 'luxon';
import saveAs from 'file-saver';
import PrimaryTitle from 'src/views/components/Page/PrimaryTitle/PrimaryTitle';

type ListContractCharterBookingsQuery = Operations.Domain.Queries.ContractCharterBooking.ListContractCharterBookings.ListContractCharterBookingsQuery;
type ContractCharterBookingItem = Operations.Domain.Queries.ContractCharterBooking.ListContractCharterBookings.ContractCharterBookingItem;
type ExportFilters = Partial<
  Operations.Domain.Queries.ContractCharterBooking.ExportContractCharterBookingToExcel.ExportContractCharterBookingToExcelQuery
>;

const ContractCharterBookings: React.FC<ExportFilters> = observer((filters: ExportFilters) => {
  const rootStore = useRootStore();

  const saveExcel = async () => {
    const fileName = `Contract_Charter_Bookings_${DateTime.local().toFormat('yyyyMMddHHmm')}.xlsx`;
    const blob = await rootStore.operations.contractCharterBookings.exportToExcel(filters);
    saveAs(blob, fileName);
  };

  const getFilterFieldDefs = () => {
    return {
      customers: {
        fieldType: FieldType.selectAsyncMultiField,
        dataAddr: 'customerIds',
        label: 'Customer',
        valueKey: 'customerId',
        descriptionKey: 'customerName',
        useValueOnly: true,
        loadOptionItems: rootStore.operations.sales.customer.searchCustomers,
        loadItems: rootStore.operations.sales.customer.findCustomers,
      } as FieldDefs,
      contracts: {
        fieldType: FieldType.selectAsyncMultiField,
        dataAddr: 'contractIds',
        label: 'Contract',
        valueKey: 'id',
        descriptionKey: 'name',
        useValueOnly: true,
        loadOptionItems: s => rootStore.operations.charterContracts.searchCharterContracts(s),
        loadItems: ids => rootStore.operations.charterContracts.findCharterContracts(ids),
      } as FieldDefs,
      hasInvoiceNumber: {
        fieldType: FieldType.yesNoField,
        label: 'Has Invoice Number',
        dataAddr: 'hasInvoiceNumber',
        useValueOnly: true,
      } as FieldDefs,
      isCompleted: {
        fieldType: FieldType.yesNoField,
        label: 'Completed',
        dataAddr: 'isCompleted',
        useValueOnly: true,
      } as FieldDefs,
    };
  };

  const getPageDef = (): IListPageDef<ListContractCharterBookingsQuery> => {
    const filterFieldDefsLookup = getFilterFieldDefs();

    return {
      primaryTitle: <PrimaryTitle title="Contract Bookings"></PrimaryTitle>,
      onLoadData: rootStore.operations.contractCharterBookings.listItems,
      externalSearch: true,
      hasMoreData: rootStore.operations.contractCharterBookings.hasMoreItems,
      createLink: TaskCardItem.operations.contractCharterBookings.createContractCharterBooking,
      primarySize: PagePrimarySize.full,
      primaryActions: [
        {
          actions: [
            {
              actionType: ActionType.actionCollection,
              actionGroups: [
                {
                  actions: [
                    {
                      actionType: ActionType.actionButton,
                      label: 'Export To Excel',
                      icon: <ExcelIcon fixedWidth />,
                      onClick: saveExcel,
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
      primaryFields: [
        {
          fieldType: FieldType.textField,
          dataAddr: 'bookingNumber',
          label: 'Booking Number',
          linkTo: d => `/accounting/contract-charter-bookings/${d.parentValue.id}`,
          columnWidth: '8em',
          orderBy: 'BookingNumber',
        },
        {
          fieldType: FieldType.textField,
          dataAddr: ['customer', 'name'],
          label: 'Customer',
          formatReadonly: d =>
            d.parentValue?.customer?.id && (
              <Link to={`/sales/customers/${d.parentValue.customer.id}`}>{d.fieldValue}</Link>
            ),
          orderBy: 'Customer.Name',
        },
        {
          fieldType: FieldType.textField,
          dataAddr: ['contract', 'name'],
          label: 'Contract',
          formatReadonly: d =>
            d.parentValue?.contract?.id && (
              <Link to={`/operations/charter-contracts/${d.parentValue.contract.id}`}>
                {d.fieldValue}
              </Link>
            ),
          orderBy: 'Contract.Name',
        },
        {
          fieldType: FieldType.readonlyField,
          dataAddr: ['isComplete'],
          label: 'Completed',
          formatReadonly: data => (data.parentValue.isComplete ? <CheckIcon /> : null),
          orderBy: 'IsComplete',
        },
        {
          fieldType: FieldType.textField,
          dataAddr: 'purchaseOrderNumber',
          label: 'PO',
          orderBy: 'PurchaseOrderNumber',
        },
        {
          fieldType: FieldType.textField,
          dataAddr: 'invoiceNumber',
          label: 'Invoice',
          orderBy: 'InvoiceNumber',
        },
        {
          fieldType: FieldType.textField,
          dataAddr: 'description',
          label: 'Description',
          orderBy: 'Description',
        },
        {
          fieldType: FieldType.customField,
          dataAddr: 'extras',
          label: 'Facilities',
          render: d => (
            <ExtraDetails
              extras={d.data.fieldValue?.filter(
                (extra: { showInAccounting: boolean }) => extra.showInAccounting
              )}
              id={d.data.parentValue.id}
            />
          ),
        },
        {
          fieldType: FieldType.readonlyField,
          label: 'Summary',
          columnWidth: '18em',
          formatReadonly: data => {
            const booking = data.parentValue as ContractCharterBookingItem;
            return (
              <div>
                {!booking.hasJobs && !booking.isComplete ? (
                  <span className={styles.noJobsWarning}>No Jobs</span>
                ) : null}
                {booking.vehicles.map((v, i) => (
                  <div key={i}>
                    {v.quantity} × {v.description}
                  </div>
                ))}
                <div>
                  <small>Total Price: </small>
                  <strong>${booking.totalPrice.toFixed(2)}</strong>
                  <small> (inc. GST)</small>
                </div>
              </div>
            );
          },
        },
      ],
      filterAction: {
        defaultValues: {
          isCompleted: false,
          orderBy: 'BookingNumber~asc',
        },
        filterFields: Object.keys(filterFieldDefsLookup).map(k => filterFieldDefsLookup[k]),
        filterDef: () => [
          {
            panes: [
              {
                paneType: PaneType.formFieldsPane,
                fields: [
                  filterFieldDefsLookup.customers,
                  filterFieldDefsLookup.contracts,
                  filterFieldDefsLookup.hasInvoiceNumber,
                  filterFieldDefsLookup.isCompleted,
                ],
              },
            ],
          },
        ],
      },
    };
  };

  return (
    <ListPage
      className="accounting-component"
      data={rootStore.operations.contractCharterBookings.items}
      def={getPageDef()}
    />
  );
});

export default ContractCharterBookings;
