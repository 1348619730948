import { IAjax } from 'src/infrastructure/ajax';
import { peopleUrls } from 'src/domain/services/apiUrls';

type SmsItem = People.Domain.Queries.Sms.ViewSms.SmsItem;

export default function init(ajax: IAjax) {
  function createSms(command: People.Domain.Commands.Sms.CreateSms.CreateSmsCommand) {
    return ajax
      .post(peopleUrls.smsUrls.createSms(), command)
      .toPromise()
      .then<string>(r => r.response);
  }

  function loadListSms(query: Partial<Common.Sms.ListSmsQuery>) {
    return ajax
      .get(peopleUrls.smsUrls.loadListSms(query))
      .map(r => r.response as Common.Dtos.ListResult<Common.Sms.ListSmsItem>);
  }

  function loadSms(SmsId: string) {
    return ajax
      .get(peopleUrls.smsUrls.loadSms(SmsId))
      .toPromise()
      .then<SmsItem>(r => r.response);
  }

  function dismissSms(smsId: string, smsStaffMemberId: string) {
    return ajax.post(peopleUrls.smsUrls.dismissSms(smsId, smsStaffMemberId)).toPromise();
  }

  return {
    Sms: {
      createSms,
      loadListSms,
      loadSms,
      dismissSms,
    },
  };
}
