import styles from './ScheduleEvent.module.scss';
import cn from 'classnames';

import { DateTime, Interval } from 'luxon';
import { Button } from 'reactstrap';
import { getDaysBetweenCount } from 'src/infrastructure/dateUtils';
import { HolidayType } from 'src/api/enums';

export interface IScheduleEvent {
  eventId: string;
  eventTitle: string;
  eventStartDateTime: DateTime;
  eventEndDateTime: DateTime;
  eventInterval: Interval;
  eventBody: React.ReactNode;
  eventCalloutBody?: React.ReactNode;
  eventCssClass?: string;
}

export interface IHolidayEvent extends IScheduleEvent {
  holidayType: HolidayType;
}

export interface IScheduleEventProps {
  event: IScheduleEvent;
  day: DateTime;
  onEventSelected: (event: IScheduleEvent, targetId: string) => void;
}

const ScheduleEvent: React.FC<IScheduleEventProps> = ({ event, day, onEventSelected }) => {
  const dayIndex = getDaysBetweenCount(day, event.eventInterval.start);

  const id = `event-${event.eventId}-${dayIndex}`;

  return (
    <div id={id} className={cn(styles['schedule-event'])} title={event.eventTitle}>
      <Button
        className={cn(styles['schedule-event-button'])}
        color="dark"
        onClickCapture={e => {
          e.preventDefault();
          e.stopPropagation();
          onEventSelected(event, id);
        }}>
        {event.eventBody}
      </Button>
    </div>
  );
};

export default ScheduleEvent;
