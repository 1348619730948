import { UiRouteName } from 'src/domain/baseTypes';
import { IRootStoreModel } from 'src/domain/entities/RootStoreModel';
import UiRoute from 'src/domain/services/buildUiRoutes/UiRoute';

export default function buildUiRoutes(store: IRootStoreModel) {
  const machineryScheduleTemplate = new UiRoute(
    UiRouteName.workshopTaskSubcategoryCreate,
    '/workshop/machinery-schedule-template',
    () => 'Machinery Template',
    false
  );

  return {
    [UiRouteName.workshopMachineryScheduleTemplate]: machineryScheduleTemplate,
  };
}
