import { IRootStoreModel } from 'src/domain/entities/RootStoreModel';
import { UiRoute } from 'src/domain/services/buildUiRoutes';
import { UiRouteName } from 'src/domain/baseTypes';

export default function buildUiRoutes(store: IRootStoreModel) {
  const customerComplaintCreate = new UiRoute(
    UiRouteName.complianceCustomerComplaintCreate,
    '/compliance/customer-complaints/create',
    () => 'Create Customer Complaint',
    true
  );

  const customerComplaintUpdate = new UiRoute(
    UiRouteName.complianceCustomerComplaintUpdate,
    '/compliance/customer-complaints/:id',
    () => 'Manage Customer Complaint',
    true
  );

  const customerComplaintList = new UiRoute(
    UiRouteName.complianceCustomerComplaintList,
    '/compliance/customer-complaints',
    () => 'Customer Complaints',
    false,
    false,
    [customerComplaintCreate, customerComplaintUpdate]
  );

  return {
    [UiRouteName.complianceCustomerComplaintList]: customerComplaintList,
    [UiRouteName.complianceCustomerComplaintCreate]: customerComplaintCreate,
    [UiRouteName.complianceCustomerComplaintUpdate]: customerComplaintUpdate,
  };
}
