import { DateTime } from 'luxon';
import { JobType, ChangeState } from 'src/api/enums';
import { IPanelDef, PaneType, FieldType, IFieldOnChange } from 'src/views/definitionBuilders/types';
import { IAutocompleteResult } from 'src/domain/baseTypes';
import {
  doesJobTypeHaveRailBooking,
  mayJobTypeHaveRailBooking,
  SortedJobTypes,
  isJobTypeLinkedToQuote,
  isJobTypeCharter,
  isRailJobType,
  isJobTypeContractCharter,
  isJobTypeCharterOrCharterStaged,
  isJobTypeWithoutAsset,
} from 'src/views/routes/operations/shared/jobTypeHelpers';
import { IMaintainJobShiftRouteGroup } from 'src/views/routes/operations/job/maintainJob/MaintainJob';
import { splitSkillSpecRequirements } from 'src/domain/entities/people/staffMember/SkillSpecsHelpers';
import { splitTechSpecRequirements } from 'src/domain/entities/workshop/techSpecs/TechSpecsHelpers';
import { getNextDateTime } from 'src/infrastructure/dateUtils';
import { ISelectFilterableByDifferentKey } from 'src/domain/baseTypes';
import { UpdateType } from 'src/views/components/Page/pages/CrudPage';
import { MaintainJobCustomUpdateModes } from 'src/views/routes/operations/job/maintainJob/MaintainJobCustomUpdateModes';
import { isDefined } from 'src/infrastructure/typeUtils';
import { getTodayISODate } from 'src/domain/dateHelper';
type JobItem = Operations.Domain.Queries.ViewJob.JobItem;
type JobTypeEnumeration = Operations.Domain.AggregatesModel.JobAggregate.JobType;
type ListShiftsQuery = Operations.Domain.Queries.ListShifts.ListShiftsQuery;
type ShiftTypeEnumeration = Operations.Domain.AggregatesModel.ShiftAggregate.ShiftType;
type ShiftListItem = Operations.Domain.Queries.ListShifts.ShiftListItem;
type ShiftItem = Operations.Domain.Queries.ViewShift.ShiftItem;
type RailTemplateItem = Operations.Domain.Queries.ViewRailTemplate.RailTemplateItem;
type RailTemplateShiftItem = Operations.Domain.Queries.ViewRailTemplate.RailTemplateShiftItem;
type RailTemplateListItem = Operations.Domain.Queries.ListRailTemplates.RailTemplateListItem;
type RailBookingForDropdownItem = Operations.Domain.Queries.GetRailBookingsForDropdown.RailBookingForDropdownItem;
type ListBookingItem = Operations.Domain.Queries.ListBookings.ListBookingItem;
type QuoteItem = Operations.Domain.Queries.ViewQuote.QuoteItem;
type Trip = Operations.Domain.Queries.ViewQuote.Trip;
type CharterContractItem = Operations.Domain.Queries.ListCharterContracts.CharterContractItem;
type ShiftSelectListItem = Operations.Domain.Queries.GetShiftsByCharterContract.ShiftSelectListItem;
type JobSubType = Operations.Domain.Queries.ListJobSubType.JobSubTypeItem;

function isJobTypeWithShift(jobType: JobTypeEnumeration) {
  const jobTypesWithShifts = [
    JobType.AsRequired,
    JobType.Operations,
    JobType.Office,
    JobType.Cleaning,
    JobType.SchoolService,
    JobType.Urban,
    JobType.Rail,
    JobType.Yard,
  ];
  return jobType && jobTypesWithShifts.indexOf(jobType.id) !== -1;
}

function isShiftNameRequiredForJobType(jobType: JobTypeEnumeration, isTrainingJob: boolean) {
  const jobTypesRequiringMandatoryShiftName = [
    JobType.AsRequired,
    JobType.SchoolService,
    JobType.Urban,
    JobType.Rail,
  ];
  return (
    !isTrainingJob && jobType && jobTypesRequiringMandatoryShiftName.indexOf(jobType.id) !== -1
  );
}

interface IShiftTimes {
  clockOn: string;
  clockOff: string;
  departDepot?: string;
  arriveDepot?: string;
  shiftCommence?: string;
  shiftEnd?: string;
}

function getJobDateTimes(shiftRequiresClockOnOffTimesOnly: boolean, shift: IShiftTimes) {
  const clockOn = getNextDateTime(DateTime.local().startOf('day'), shift.clockOn);
  const departDepot = getNextDateTime(clockOn, shift.departDepot);
  const shiftCommence = getNextDateTime(departDepot, shift.shiftCommence);
  const shiftEnd = getNextDateTime(shiftCommence, shift.shiftEnd);
  const arriveDepot = getNextDateTime(shiftEnd, shift.arriveDepot);
  const clockOff = getNextDateTime(
    shiftRequiresClockOnOffTimesOnly ? clockOn : arriveDepot,
    shift.clockOff
  );

  return {
    clockOn,
    departDepot,
    shiftCommence,
    shiftEnd,
    arriveDepot,
    clockOff,
  };
}

const excludeProps = (src: object, prefix: string) => {
  const clone = { ...src };
  const specKeys = Object.keys(src).filter(key => key.startsWith(prefix));
  for (let index = 0; index < specKeys.length; index++) {
    const key = specKeys[index];
    delete clone[key];
  }
  return clone;
};

function onRailShiftChange(api: IFieldOnChange<RailTemplateShiftItem | undefined>) {
  if (api.newFieldValue) {
    const shift = api.newFieldValue;
    const formValuesWithoutSpecs = excludeProps(api.formValues, 'spec-') as any;
    const newValues: JobItem = {
      ...formValuesWithoutSpecs,
      ...splitSkillSpecRequirements(shift.skillSpecRequirements),
      ...splitTechSpecRequirements(shift.techSpecRequirements),
      ...getJobDateTimes(false, shift),
      asset: shift.asset,
      description: shift.description,
      departingFromDepot: shift.departingFromDepot,
      departingFromDepotInCar: shift.departingFromDepotInCar,
      arrivingAtDepot: shift.arrivingAtDepot,
      arrivingAtDepotInCar: shift.arrivingAtDepotInCar,
      notes: shift.notes,
      unpaidBreaks: shift.unpaidBreaks,
      runCount: getMaxValue(shift.routeGroups, r => r.runNumber),
      routeGroups: mapRoutesToData(shift.routeGroups),
    };
    api.setFormValues(newValues);
  }
}

function getMaxValue(
  routesGroups: Operations.Domain.Queries.ViewRailTemplate.RailTemplateShiftRouteGroupItem[],
  valueFunction: (
    route: Operations.Domain.Queries.ViewRailTemplate.RailTemplateShiftRouteItem
  ) => number
) {
  const routes = routesGroups.reduce(
    (acc: Operations.Domain.Queries.ViewRailTemplate.RailTemplateShiftRouteItem[], val) => {
      return acc.concat(val.routes);
    },
    []
  );
  return getMax(routes, valueFunction);
}

function getMax(
  routes: Operations.Domain.Queries.ViewRailTemplate.RailTemplateShiftRouteItem[],
  valueFunction: (
    route: Operations.Domain.Queries.ViewRailTemplate.RailTemplateShiftRouteItem
  ) => number
) {
  const routeValues = routes.length > 0 ? routes.map(valueFunction) : [0];
  return Math.max(...routeValues) + 1;
}

function mapRoutesToData(
  routeGroups: Operations.Domain.Queries.ViewRailTemplate.RailTemplateShiftRouteGroupItem[]
): IMaintainJobShiftRouteGroup[] {
  const maxRunNumber = getMaxValue(routeGroups, r => r.runNumber);
  return routeGroups.map(rg => {
    const routeGroup = {
      ...rg,
      changeState: ChangeState.Unchanged,
      routes: Array.from({ length: getMax(rg.routes, r => r.routeNumber) }, () => {
        return {
          location: {
            address: '',
            name: '',
            city: '',
            postcode: '',
            state: '',
            notes: '',
            id: '',
          },
          depart: Array.from({ length: maxRunNumber }, () => '') as string[],
          changeState: ChangeState.Unchanged,
        };
      }),
    };
    rg.routes.forEach(r => {
      const departArray = routeGroup.routes[r.routeNumber].depart;
      departArray[r.runNumber] = r.depart as string;
      const routeData = {
        location: {
          address: r.address,
          name: r.name,
          city: r.city,
          postcode: r.postcode,
          state: r.state,
          notes: r.notes,
          id: '',
        },
        depart: [...departArray],
        changeState: ChangeState.Unchanged,
      };
      routeGroup.routes[r.routeNumber] = routeData;
    });
    return routeGroup;
  });
}

function onShiftChange(
  requiresClockOnOffTimesOnly: (jobType: ShiftTypeEnumeration) => boolean,
  getShift: (shiftId: string) => Promise<ShiftItem>,
  api: IFieldOnChange<ShiftListItem | undefined>
) {
  if (api.newFieldValue && api.newFieldValue.id) {
    getShift(api.newFieldValue.id).then((shift: ShiftItem) => {
      const newValues: JobItem = {
        ...api.formValues,
        ...getJobDateTimes(requiresClockOnOffTimesOnly(shift.shiftType), shift),
        ...splitSkillSpecRequirements(shift.skillSpecRequirements),
        ...splitTechSpecRequirements(shift.techSpecRequirements),
        asset: shift.asset,
        description: shift.description,
        departingFromDepot: shift.departingFromDepot,
        departingFromDepotInCar: shift.departingFromDepotInCar,
        arrivingAtDepot: shift.arrivingAtDepot,
        arrivingAtDepotInCar: shift.arrivingAtDepotInCar,
        notes: shift.notes,
        skipPredepartureChecks: shift.skipPredepartureChecks,
        unpaidBreaks: shift.unpaidBreaks,
        jobSubType: shift.jobSubType,
      };
      api.setFormValues(newValues);
    });
  }
}

function parseRouteDateTime(date?: string, time?: string) {
  const dateTime = DateTime.fromISO(`${date}T${time}`);
  if (!dateTime.isValid) {
    return undefined;
  }
  return dateTime.toISO();
}

// tslint:disable-next-line:no-any
function onTripNumberChange(api: IFieldOnChange<any>) {
  if (
    !isJobTypeCharterOrCharterStaged(
      api.fieldData.parentValue.jobType && api.fieldData.parentValue.jobType.id
    )
  ) {
    return;
  }
  if (api.fieldData.fieldValue) {
    const trip = api.fieldData.fieldValue as Trip;
    const tripNumber = trip.tripNumber - 1;
    const selectedOption = trip.options.find(x => !!x.selected);
    const firstRoute = trip.routes[0];
    const lastRoute = trip.routes[trip.routes.length - 1];
    const onSite = parseRouteDateTime(firstRoute.date, firstRoute.arrive);
    const commence = parseRouteDateTime(firstRoute.date, firstRoute.depart);
    const end = parseRouteDateTime(lastRoute.date, lastRoute.arrive);
    const newValues = {
      ...api.formValues,
      tripPassengerCount: trip.passengers,
      tripNumber: tripNumber,
      jobRoutes: trip.routes.map((r: Operations.Domain.Queries.ViewQuote.Route) => {
        return {
          ...r,
        };
      }),
      jobExtras:
        selectedOption && isJobTypeCharter(api.fieldData.parentValue.jobType?.id)
          ? selectedOption.extras
          : [],
      onSite: onSite,
      shiftCommence: commence,
      shiftEnd: end,
      selectedOptionSummary: {
        title: selectedOption && `Option ${selectedOption.optionNumber}`,
        vehicleSummary:
          selectedOption &&
          selectedOption.vehicles
            .map(x => `${x.quantity} x ${x.vehicleType.description}`)
            .join(', '),
        extraSummary:
          selectedOption &&
          selectedOption.extras.map(x => `${x.quantity} x ${x.extraType.description}`).join(', '),
      },
    };
    api.setFormValues(newValues);
  }
}

function onQuoteChange(
  // tslint:disable-next-line:no-any
  api: IFieldOnChange<any>,
  getQuote: (quoteId: string) => Promise<QuoteItem>
) {
  if (api.fieldData.fieldValue && api.fieldData.fieldValue.id) {
    getQuote(api.fieldData.fieldValue.id).then((q: QuoteItem) => {
      const tripCount = q.trips.length;
      const newValues: JobItem = {
        ...api.formValues,
        description: q.description,
        notes: q.driverNote,
        quoteId: q.id,
        quoteNumber: q.quoteNumber,
        quoteType: q.quoteType,
        charterCustomer: {
          id: q.customer.customerId,
          name: q.customer.customerName,
          contactName: q.contact && q.contact.contactName,
        },
        tripCount: tripCount,
        tripNumber: tripCount === 1 ? q.trips[0].tripNumber : null,
      };
      api.setFormValues(newValues);
    });
  } else {
    const newValues: JobItem = {
      ...api.formValues,
      description: null,
      notes: null,
      quoteId: null,
      quoteNumber: null,
      quoteType: null,
      charterCustomer: null,
      tripCount: null,
      tripNumber: null,
    };
    api.setFormValues(newValues);
  }
}
const onShiftNameChange = async (
  api: IFieldOnChange<any>,
  loadContractShift: (contractShiftId: string) => Promise<ShiftItem>,
  setOnShiftChanged: () => void
) => {
  if (api.fieldData.fieldValue) {
    setOnShiftChanged();
    const formValuesWithoutSpecs = excludeProps(api.formValues, 'spec-') as any;

    let shift = await loadContractShift(api.fieldData.fieldValue);
    const newValues: JobItem = {
      ...formValuesWithoutSpecs,
      ...splitSkillSpecRequirements(shift.skillSpecRequirements),
      ...splitTechSpecRequirements(shift.techSpecRequirements),
      ...getJobDateTimes(false, shift),
      description: shift.description,
      notes: shift.notes,
      skipPredepartureChecks: shift.skipPredepartureChecks,
      clockOn: DateTime.fromISO(shift.clockOn),
      clockOff: DateTime.fromISO(shift.clockOff),
      vehicleType: shift.vehicleType,
      departDepot: DateTime.fromISO(shift.departDepot!),
      departingFromDepot: shift.departingFromDepot,
      departingFromDepotInCar: shift.departingFromDepotInCar,
      arriveDepot: DateTime.fromISO(shift.arriveDepot!),
      arrivingAtDepotInCar: shift.arrivingAtDepotInCar,
      arrivingAtDepot: shift.arrivingAtDepot,
      jobExtras: shift.extras,
      jobRoutes: shift.routes.map(route => ({
        ...route,
        date: getTodayISODate(),
      })),
      unpaidBreaks: shift.unpaidBreaks,
      shiftName: shift.shiftName,
    };

    api.setFormValues(newValues);
  }
};

export default function getJobDetailPanelDef(
  isUpdateMode: boolean,
  isCreateMode: boolean,
  getUpdateType: () => UpdateType,
  requiresClockOnOffTimesOnly: (jobType: ShiftTypeEnumeration) => boolean,
  railTemplates: RailTemplateListItem[],
  loadRailTemplate: (id: string) => Promise<void>,
  railTemplate: RailTemplateItem | undefined,
  bookedRailBookings: ISelectFilterableByDifferentKey<RailBookingForDropdownItem>[],
  allRailBookings: ISelectFilterableByDifferentKey<RailBookingForDropdownItem>[],
  getShift: (shiftId: string) => Promise<ShiftItem>,
  searchBookings: (search: string) => Promise<IAutocompleteResult<ListBookingItem>>,
  quote: QuoteItem | undefined,
  getQuote: (quoteId: string) => Promise<QuoteItem>,
  originalRailBookingId: string | undefined,
  downloadDriverPdf: (pdfId: number, name: string) => Promise<void>,
  charterContracts: ISelectFilterableByDifferentKey<CharterContractItem[]>,
  getShiftsByContract: (id: string) => Promise<ShiftSelectListItem[]>,
  getShifts: (query?: ListShiftsQuery) => Promise<ShiftListItem[]>,
  shiftOptions: ShiftSelectListItem[] | undefined,
  setShiftOptions: (options: ShiftSelectListItem[]) => void,
  shiftListOptions: ShiftListItem[] | undefined,
  setShiftListOptions: (options: ShiftListItem[]) => void,
  setOnShiftChanged: () => void,
  jobSubTypes: JobSubType[]
): IPanelDef {
  const tripNumbers = quote && quote.trips.map(t => ({ ...t, tripNumber: t.tripNumber + 1 }));
  return {
    panes: [
      {
        paneType: PaneType.formFieldsPane,
        columnCount: 3,
        fields: [
          {
            fieldType: FieldType.selectField,
            dataAddr: ['jobType', 'id'],
            label: 'Job Type',
            valueKey: 'value',
            descriptionKey: 'description',
            optionItems: SortedJobTypes,
            useValueOnly: true,
            readonly: _ => isUpdateMode,
            mandatory: true,
            onChange: api => {
              api.setFormValues({
                jobType: {
                  id: api.newFieldValue,
                },
                jobRoutes:
                  api.newFieldValue === JobType.CharterStaged
                    ? [{ changeState: ChangeState.Unknown }, { changeState: ChangeState.Unknown }]
                    : [],
                skipPredepartureChecks: false,
                hasSubcontractor: false,
                hasSecondStaffMember: false,
                runCount: api.newFieldValue === JobType.Rail ? 1 : undefined,
                routeGroups: api.newFieldValue === JobType.Rail ? [{ routes: [] }] : undefined,
              });

              api.newFieldValue &&
                getShifts({ shiftTypeId: api.newFieldValue }).then(res => setShiftListOptions(res));
            },
            formatReadonly: d => d.parentValue?.jobType?.description,
          },
          {
            fieldType: FieldType.selectField,
            label: 'Rail Template',
            dataAddr: 'railTemplate',
            valueKey: 'railTemplateId',
            descriptionKey: 'name',
            optionItems: railTemplates,
            readonly: isUpdateMode,
            hidden: d => !isRailJobType(d.parentValue.jobType) || isUpdateMode,
            onChange: api => {
              const template = api.newFieldValue as RailTemplateListItem | undefined;
              api.setFormValue(['shiftName'], undefined);
              api.setFormValue(['railShiftName'], undefined);
              if (template && template.railTemplateId) {
                loadRailTemplate(template.railTemplateId);
              }
            },
          },
          {
            fieldType: FieldType.selectField,
            label: 'Charter Contract',
            dataAddr: 'charterContract',
            valueKey: 'id',
            descriptionKey: 'searchable',
            readonly: isUpdateMode,
            valueRenderer: d => d.name,
            hidden: d =>
              !isJobTypeContractCharter(d.parentValue.jobType && d.parentValue.jobType.id),
            mandatory: d =>
              isJobTypeContractCharter(d.parentValue.jobType && d.parentValue.jobType.id),
            optionItems: charterContracts,
            optionRenderer: (d: CharterContractItem) => (
              <span>
                {d.name} - <small>{d.customer.customerName}</small>
              </span>
            ),
            onChange: d => {
              d.setFormValue(['charterContract'], d.fieldData.fieldValue);
              if (d.getFormValue(['contractShiftName'])) {
                d.setFormValue(['contractShiftName'], undefined);
              }

              if (d.newFieldValue && d.newFieldValue.id) {
                getShiftsByContract(d.fieldData.fieldValue.id).then(res => {
                  const mapped = res.map(shift => ({
                    ...shift,
                    searchable: `${shift.name} - ${shift.description}`,
                  }));

                  setShiftOptions([...mapped]);
                });
              }
            },
          },
          {
            fieldType: FieldType.selectField,
            label: 'Shift Name',
            dataAddr: 'shiftName',
            valueKey: 'shiftName',
            descriptionKey: 'shiftName',
            mandatory: d =>
              isShiftNameRequiredForJobType(d.parentValue.jobType, d.parentValue.isTrainingJob)
                ? true
                : false,
            optionItems: d =>
              isRailJobType(d.parentValue.jobType) && !d.parentValue.isTrainingJob
                ? (((railTemplate && railTemplate.shifts) || []) as any[])
                : (shiftListOptions as any[]),
            hidden: d =>
              !isJobTypeWithShift(d.parentValue.jobType) ||
              (isRailJobType(d.parentValue.jobType) &&
                !d.parentValue.railTemplate &&
                !isUpdateMode &&
                !d.parentValue.isTrainingJob),
            readonly: d =>
              isUpdateMode ||
              (isRailJobType(d.sectionValue.jobType) &&
                !d.parentValue.railTemplate &&
                !d.sectionValue.isTrainingJob),
            onChange: api =>
              isRailJobType(api.fieldData.parentValue.jobType) &&
              !api.fieldData.parentValue.isTrainingJob
                ? onRailShiftChange(api)
                : onShiftChange(requiresClockOnOffTimesOnly, getShift, api),
          },
          {
            fieldType: FieldType.selectField,
            label: 'Shift Name',
            readonly: isUpdateMode,
            hidden: d =>
              !isJobTypeContractCharter(d.parentValue.jobType && d.parentValue.jobType.id),
            dataAddr: 'contractShiftName',
            optionRenderer: (d: ShiftSelectListItem) => (
              <span>
                {d.name} - <small>{d.description}</small>
              </span>
            ),
            useValueOnly: true,
            valueKey: 'id',
            descriptionKey: 'searchable',
            mandatory: true,
            optionItems: shiftOptions,
            onChange: api => {
              onShiftNameChange(api, getShift, setOnShiftChanged);
            },
          },
          {
            fieldType: FieldType.selectAsyncField,
            dataAddr: 'quote',
            label: 'Charter Booking',
            valueKey: 'id',
            descriptionKey: 'quoteNumber',
            mandatory: d => isJobTypeCharter(d.parentValue.jobType.id),
            hidden: d =>
              !isJobTypeLinkedToQuote(d.parentValue.jobType && d.parentValue.jobType.id) ||
              isUpdateMode,
            loadOptionItems: searchBookings,
            readonly:
              isUpdateMode && getUpdateType() === MaintainJobCustomUpdateModes.RecurringUpdateMode,
            optionRenderer: d => (
              <span>
                {d.quoteNumber} - {d.description}
              </span>
            ),
            useOptionRendererAsValueRenderer: true,
            onChange: api => onQuoteChange(api, getQuote),
            validate: d =>
              d.fieldValue && !!d.panelValue.railBookingId
                ? 'Cannot have charter and rail bookings'
                : undefined,
          },
          {
            fieldType: FieldType.selectField,
            label: 'Trip Number',
            dataAddr: 'trip',
            valueKey: 'id',
            descriptionKey: 'tripNumber',
            optionItems: tripNumbers,
            mandatory: d =>
              isJobTypeCharter(d.parentValue.jobType.id) ||
              (isJobTypeLinkedToQuote(d.parentValue.jobType.id) && d.parentValue.quoteNumber),
            optionRenderer: d => d.tripNumber,
            readonly: d => isUpdateMode,
            hidden: d =>
              !isJobTypeLinkedToQuote(d.parentValue.jobType && d.parentValue.jobType.id) ||
              isUpdateMode,
            onChange: onTripNumberChange,
            validate: d => {
              const railBooking = d.parentValue.railBookingId;
              const railShift = d.parentValue.railShiftName;
              const charter = d.parentValue.quoteId;

              if (!d.fieldValue) {
                return undefined;
              }

              return !charter
                ? 'Charter booking must be set'
                : railBooking || railShift
                ? 'Cannot have charter and rail bookings'
                : undefined;
            },
          },
          {
            fieldType: FieldType.selectField,
            dataAddr: 'jobSubType',
            label: 'Job Subtype',
            valueKey: 'id',
            descriptionKey: 'description',
            optionItems: d =>
              jobSubTypes.filter(subtype => subtype.jobTypeId === d.parentValue.jobType?.id),
            hidden: d =>
              jobSubTypes.filter(subtype => subtype.jobTypeId === d.parentValue.jobType?.id)
                .length === 0,
          },
        ],
      },
      {
        paneType: PaneType.formFieldsPane,
        columnCount: 2,
        fields: [
          {
            fieldType: FieldType.selectField,
            label: 'Rail Booking',
            dataAddr: 'railBookingId',
            valueKey: 'railBookingId',
            useValueOnly: true,
            descriptionKey: 'searchable',
            mandatory: d =>
              d.parentValue.jobType && doesJobTypeHaveRailBooking(d.parentValue.jobType.id),
            optionItems: () => {
              if (!originalRailBookingId) {
                return bookedRailBookings;
              }

              return allRailBookings;
            },
            optionRenderer: d => <span>{`${d.railBookingNumber} - ${d.description}`}</span>,
            useOptionRendererAsValueRenderer: true,
            readonly: d =>
              isUpdateMode &&
              !(
                d.parentValue.jobType &&
                mayJobTypeHaveRailBooking(d.parentValue.jobType.id) &&
                !originalRailBookingId
              ),

            hidden: d =>
              d.parentValue.jobType &&
              !doesJobTypeHaveRailBooking(d.parentValue.jobType.id) &&
              !mayJobTypeHaveRailBooking(d.parentValue.jobType.id),
            linkTo: d => `/operations/rail/rail-bookings/${d.parentValue.railBookingId}`,
            validate: d =>
              d.fieldValue && !!d.panelValue.quote
                ? 'Cannot have charter and rail bookings'
                : undefined,
          },
          {
            fieldType: FieldType.textField,
            label: 'Shift Name',
            dataAddr: 'railShiftName',
            mandatory: d => {
              const isRailBookingJob =
                d.parentValue.jobType &&
                (doesJobTypeHaveRailBooking(d.parentValue.jobType.id) ||
                  mayJobTypeHaveRailBooking(d.parentValue.jobType.id));
              const isRailBookingSpecified = isDefined(d.parentValue.railBookingId);
              return isRailBookingJob ? isRailBookingSpecified : true;
            },
            validate: d => {
              const charterBooking = d.parentValue.quoteId;
              const trip = d.parentValue.trip;
              const railBooking = d.parentValue.railBookingId;

              if (!d.fieldValue) {
                return undefined;
              }

              return !railBooking
                ? 'Rail booking must be set'
                : charterBooking || trip
                ? 'Cannot have charter and rail bookings'
                : undefined;
            },
            hidden: d =>
              !(
                d.parentValue.jobType?.id && doesJobTypeHaveRailBooking(d.parentValue.jobType.id)
              ) || isUpdateMode,
          },
        ],
      },
      {
        paneType: PaneType.formFieldsPane,
        fields: [
          {
            fieldType: FieldType.textField,
            label: 'Description',
            dataAddr: 'description',
            readonly: () =>
              isUpdateMode && getUpdateType() === MaintainJobCustomUpdateModes.RecurringUpdateMode,
            mandatory: true,
          },
        ],
      },
      {
        paneType: PaneType.formFieldsPane,
        columnCount: 3,
        fields: [
          {
            fieldType: FieldType.yesNoField,
            label: 'Skip Predeparture Checks',
            dataAddr: 'skipPredepartureChecks',
            mandatory: true,
            hidden: d => isJobTypeWithoutAsset(d.panelValue.jobType && d.panelValue.jobType.id),
          },
        ],
      },
      {
        paneType: PaneType.formFieldsPane,
        fields: [
          {
            fieldType: FieldType.textAreaField,
            readonly:
              isUpdateMode && getUpdateType() === MaintainJobCustomUpdateModes.RecurringUpdateMode,
            label: 'Notes',
            dataAddr: 'notes',
          },
        ],
      },
      {
        paneType: PaneType.formFieldsPane,
        columnCount: 2,
        fields: [
          {
            fieldType: FieldType.multiFileField,
            hidden: isCreateMode,
            label: d =>
              `${
                isJobTypeCharter(d.panelValue.jobType && d.panelValue.jobType.id)
                  ? 'Attachments (go to Booking to add/remove)'
                  : 'Attachments (go to the shift to add/remove/update)'
              }`,
            dataAddr: 'driverPdfs',
            readonly: true,
            downloadOnClick: downloadDriverPdf,
          },
        ],
      },
    ],
  };
}
