import { ArrowLeftIcon, HomeIcon } from 'src/images/icons';
import styles from './Menu.module.scss';
import { Link } from 'react-router-dom';
import { SingleClickAwareButton } from '../../components/presentational/SingleClickAwareButton/SingleClickAwareButton';

interface IBackButtonProps {
  goBack: () => void;
}

const WithBackButton: React.FC<IBackButtonProps> = ({ goBack, children }) => (
  <>
    <div className={styles.backMenu}>
      <SingleClickAwareButton className={styles.back} onClick={goBack}>
        <ArrowLeftIcon />
        <span>Back</span>
      </SingleClickAwareButton>
      <Link to={'/'}>
        <HomeIcon />
      </Link>
    </div>
    {children}
  </>
);

export default WithBackButton;
