import './ToggleMultiButtonPageField.scss';
import { ButtonGroup, Button } from 'reactstrap';
import { nonStrictEqual } from 'src/infrastructure/nonStrictEqual';
import { IFieldApi } from 'src/views/components/Page/forms/Field';
import { LabelledFormField } from 'src/views/components/forms';
import {
  IFieldMeta,
  IFieldData,
  IToggleMultiButtonFieldDef,
} from 'src/views/definitionBuilders/types';
import FocusWrapper from 'src/views/components/FocusWrapper';
import { isDefined } from 'src/infrastructure/typeUtils';
import ListSyncFieldBase from './fieldBases/ListSyncFieldBase';

interface IToggleMultiButtonPageFieldProps {
  fieldDef: IToggleMultiButtonFieldDef;
  fieldMeta: IFieldMeta;
  // tslint:disable-next-line:no-any
  fieldData: IFieldData<Array<any>>;
  fieldApi: IFieldApi;
}

class ToggleMultiButtonPageField extends ListSyncFieldBase<IToggleMultiButtonPageFieldProps> {
  // tslint:disable-next-line:no-any
  private readonly handleChange = (toggleOption: any) => {
    const { fieldApi, fieldDef: def } = this.props;
    const { setValue } = fieldApi;
    const currentItems = this.getCurrentItems();
    const currentItem = currentItems.find(
      item => item[def.valueKey] === toggleOption[def.valueKey]
    );
    const updatedItems = [...currentItems];
    if (currentItem) {
      const idx = updatedItems.indexOf(currentItem);
      updatedItems.splice(idx, 1);
    } else {
      updatedItems.push(toggleOption);
    }

    const newValue =
      def.useValueOnly && updatedItems.length
        ? updatedItems.map(o => o[def.valueKey])
        : updatedItems;

    setValue(newValue.length ? newValue : undefined);
  };

  private readonly getCurrentItems = () => {
    const { fieldDef: def, fieldData: data } = this.props;

    // Any falsie value except zero is considered nothing
    if (!data.fieldValue && data.fieldValue !== 0) {
      return [];
    }

    // Be nice and accept single values not in an array - this is helpful for things like page filters
    const fieldValue = Array.isArray(data.fieldValue) ? data.fieldValue : [data.fieldValue];

    // If the field is "value only", attempt to find a corresponding option items
    if (def.useValueOnly) {
      return fieldValue
        .map(v => this.items().find(i => nonStrictEqual(i[def.valueKey], v)) || undefined)
        .filter(isDefined);
    }

    return fieldValue;
  };

  render() {
    const { fieldApi, fieldDef: def, fieldMeta: meta, fieldData: data } = this.props;
    const { error, touched } = fieldApi;
    const currentItems = this.getCurrentItems();

    // As this field can have a value which is an object, then errors can be added to "lower" levels,
    // in which case `error` will be an object rather than a string
    const safeError = typeof error === 'string' ? error : undefined;
    const labelText = typeof def.label === 'function' ? def.label(data) : def.label;
    const tooltipText = typeof def.tooltip === 'function' ? def.tooltip(data) : def.tooltip;
    return (
      <LabelledFormField
        className="toggle-multi-button-page-field-component"
        readonly={meta.readonly}
        readonlyValue={
          def.formatReadonly
            ? def.formatReadonly(data)
            : currentItems.map(item => item[def.descriptionKey]).join(', ')
        }
        mandatory={meta.mandatory}
        hideLabel={meta.hideLabel}
        labelValue={labelText}
        tooltipValue={tooltipText}
        error={touched && safeError}>
        <FocusWrapper onBlur={this.handleBlur}>
          <ButtonGroup className="toggle-button-group">
            {this.items().map((option, idx) => (
              <Button
                key={idx}
                className="toggle-button"
                color="secondary"
                outline
                onClick={() => this.handleChange(option)}
                disabled={!!option.disabled}
                active={currentItems.some(item => item[def.valueKey] === option[def.valueKey])}>
                {`${option[def.descriptionKey]}`}
              </Button>
            ))}
          </ButtonGroup>
        </FocusWrapper>
      </LabelledFormField>
    );
  }
}

export default ToggleMultiButtonPageField;
