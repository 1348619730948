import { IListPageDef } from 'src/views/components/Page/pages/ListPage';
import { ListPage } from 'src/views/components/Page';
import {
  FieldType,
  PaneType,
  FieldDefs,
  ShellModalSize,
  ActionType,
  PagePrimarySize,
} from 'src/views/definitionBuilders/types';
import { toActionLinkDef, TaskCardItem } from 'src/views/components/TaskCard';
import { DateFormat } from 'src/views/components/DateFormat';
import { ConsolidatedRosterShifts } from '../shared/ConsolidatedRosterShifts';
import { DateTime } from 'luxon';
import { ExcelIcon } from 'src/images/icons';
import withQueryParams, { IQueryParamsProps } from 'src/views/hocs/withQueryParams';
import styles from './ListRosters.module.scss';
import ActionListPageField from 'src/views/components/Page/fields/ActionListPageField';
import saveAs from 'file-saver';
import { useRootStore } from 'src/domain/entities/RootStoreModel';
import { observer } from 'mobx-react';
import PrimaryTitle from 'src/views/components/Page/PrimaryTitle/PrimaryTitle';

type ListRostersQuery = Workshop.Domain.Queries.Roster.ListRosters.ListRostersQuery;
type RosterListItem = Workshop.Domain.Queries.Roster.ListRosters.RosterListItem;

const ListRosters: React.FC<IQueryParamsProps<ListRostersQuery>> = observer(
  (props: IQueryParamsProps<ListRostersQuery>) => {
    const rootStore = useRootStore();
    const workshopModel = rootStore.workshop;
    const rostersModel = workshopModel.rosters;
    const workshopStartUpModel = rootStore.workshopStartup;

    const getFilterFieldDefs = () => {
      return {
        dateFrom: {
          fieldType: FieldType.dateField,
          label: 'Date From',
          dataAddr: 'dateFrom',
          onBlur: api => {
            api.validateField(['dateTo']);
          },
        } as FieldDefs,
        dateTo: {
          fieldType: FieldType.dateField,
          label: 'Date To',
          dataAddr: 'dateTo',
          validate: d => {
            if (!d.fieldValue || !d.parentValue.dateFrom) {
              return undefined;
            }
            const from = DateTime.fromISO(d.parentValue.dateFrom);
            const to = DateTime.fromISO(d.fieldValue);
            return from > to ? 'Date To cannot be earlier than Date From' : undefined;
          },
        } as FieldDefs,
        workshopDepots: {
          fieldType: FieldType.selectMultiField,
          label: 'Depot',
          dataAddr: 'workshopDepots',
          optionItems: workshopStartUpModel.workshopDepots.slice(),
          useValueOnly: true,
          valueKey: 'id',
          descriptionKey: 'description',
          hidden: workshopStartUpModel.workshopDepots?.length < 2,
        } as FieldDefs,
      };
    };

    const getPageDef = (): IListPageDef => {
      const filterFieldDefsLookup = getFilterFieldDefs();

      const today = DateTime.local().toISODate();
      return {
        primaryTitle: <PrimaryTitle title="Rosters"></PrimaryTitle>,
        onLoadData: rostersModel.listItems,
        primarySize: PagePrimarySize.full,
        externalSearch: true,
        createLink: rootStore.account.isWorkshopDepartmentMember
          ? TaskCardItem.workshop.createWorkshopRoster
          : undefined,
        hasMoreData: rostersModel.hasMoreItems,
        primaryActions: [
          {
            actions: [
              {
                actionType: ActionType.actionCollection,
                actionGroups: [
                  {
                    actions: [
                      {
                        actionType: ActionType.actionButton,
                        label: 'Export Rosters to Excel',
                        icon: <ExcelIcon fixedWidth />,
                        onClick: () => exportRostersToExcel(),
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
        filterAction: {
          defaultValues: {
            dateFrom: today,
          },
          filterSize: ShellModalSize.oneQuarter,
          filterFields: Object.keys(filterFieldDefsLookup).map(k => filterFieldDefsLookup[k]),
          filterDef: () => [
            {
              panes: [
                {
                  paneType: PaneType.formFieldsPane,
                  columnCount: 2,
                  fields: [filterFieldDefsLookup.dateFrom, filterFieldDefsLookup.dateTo],
                },
                {
                  paneType: PaneType.formFieldsPane,
                  fields: [filterFieldDefsLookup.workshopDepots],
                },
              ],
            },
          ],
        },
        primaryFields: [
          {
            fieldType: FieldType.textField,
            dataAddr: 'weekStart',
            label: 'Week',
            columnWidth: '220px',
            formatReadonly: data => {
              var rosterListItem = data.parentValue as RosterListItem;
              return (
                <>
                  <DateFormat value={rosterListItem.weekStart} /> -{' '}
                  <DateFormat value={rosterListItem.weekEnd} />
                  {rosterListItem.rosterTemplateName && (
                    <div className={styles.rosterTemplateName}>
                      ({rosterListItem.rosterTemplateName})
                    </div>
                  )}
                </>
              );
            },
            linkTo: d => `/workshop/rosters/${d.parentValue.rosterId}`,
          },
          {
            fieldType: FieldType.textField,
            dataAddr: 'depotDescription',
            label: 'Depot',
          },
          {
            fieldType: FieldType.readonlyField,
            label: 'Shifts',
            formatReadonly: data => {
              const roster = data.parentValue as RosterListItem;
              return <ConsolidatedRosterShifts consolidatedShifts={roster.consolidatedShifts} />;
            },
          },
          {
            fieldType: FieldType.customField,
            label: '',
            dataAddr: 'none',
            render: d => {
              const roster = d.data.parentValue as RosterListItem;
              return (
                <ActionListPageField
                  fieldDef={{
                    fieldType: FieldType.actionListField,
                    nowrap: true,
                    actionGroups: [
                      {
                        actions: [
                          toActionLinkDef(
                            TaskCardItem.workshop.viewWorkshopRosterChanges(roster.rosterId)
                          ),
                        ],
                      },
                    ],
                  }}
                  fieldMeta={d.meta}
                  fieldData={d.data}
                />
              );
            },
          },
        ],
      };
    };

    const exportRostersToExcel = () => {
      const date = DateTime.local().toFormat('yyyyMMddHHmm');
      return rostersModel.exportToExcel(props.getQueryParams()).then(r => {
        saveAs(r, `Roster_Export_${date}.xlsx`);
      });
    };

    return <ListPage data={rostersModel.items} def={getPageDef()} />;
  }
);

export default withQueryParams(ListRosters);
