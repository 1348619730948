import { UiRouteName } from 'src/domain/baseTypes';
import { IRootStoreModel } from 'src/domain/entities/RootStoreModel';
import UiRoute from 'src/domain/services/buildUiRoutes/UiRoute';

export default function buildUiRoutes(store: IRootStoreModel) {
  const checklistCreate = new UiRoute(
    UiRouteName.workshopChecklistCreate,
    '/workshop/checklists/create',
    () => 'Create Checklist',
    false
  );

  const checklistEdit = new UiRoute(
    UiRouteName.workshopChecklistEdit,
    '/workshop/checklists/:id',
    () => 'Manage Checklist',
    false
  );

  const checklistsView = new UiRoute(
    UiRouteName.workshopChecklistsView,
    '/workshop/checklists',
    () => 'Checklists',
    false,
    false,
    [checklistCreate, checklistEdit]
  );

  return {
    [UiRouteName.workshopChecklistCreate]: checklistCreate,
    [UiRouteName.workshopChecklistEdit]: checklistEdit,
    [UiRouteName.workshopChecklistsView]: checklistsView,
  };
}
