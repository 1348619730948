import styles from '../AssetDetails.module.scss';
import Spinner from 'src/views/components/Spinner';
import { ComponentTypeServiceIntervalType } from 'src/api/enums';
import { DateTime } from 'luxon';
import { AssetField } from './AssetField';
import {
  getHumanizedDueTextByKms,
  getHumanizedDueTextByDate,
} from 'src/views/routes/workshop/assets/componentsRegister/componentRegisterHelpers';
import { Fragment, useEffect, useState } from 'react';

type AssetComponentItem = Workshop.Domain.Queries.AssetComponents.AssetComponentItem;
type AssetOdometerReadingItem = Workshop.Domain.Queries.GetLastAssetOdometerReadings.AssetOdometerReadingItem;

export interface IAssetComponentRegisterDisplayProps {
  components?: AssetComponentItem[];
  lastOdometerReading?: AssetOdometerReadingItem;
  assetId: string;
  loadComponentRegisterForAsset: (assetId: string) => Promise<void>;
  loadLastOdometerReading: (assetId: string) => Promise<void>;
}

const AssetComponentByDateRow: React.FC<{
  component: AssetComponentItem;
}> = ({ component }) => {
  const statusText = getHumanizedDueTextByDate(component);

  return (
    <Fragment key={component.id}>
      <tr>
        <td>
          <div>
            <AssetField label={'Component'} text={component.componentType.description} />
            <AssetField
              label={'Part No.'}
              text={component.part ? component.part.partNumber : '--'}
            />
            <AssetField
              label={'Serial No.'}
              text={component.serialNumber ? component.serialNumber : '--'}
            />
            <AssetField
              label={'Service Interval'}
              text={
                component.componentType.serviceIntervalDays
                  ? component.componentType.serviceIntervalDays
                  : 'Not set'
              }
            />
          </div>
          <div>
            <AssetField
              label={'Last Service'}
              text={
                component.lastServiceDate
                  ? DateTime.fromISO(component.lastServiceDate).toLocaleString(DateTime.DATE_MED)
                  : '--'
              }
            />
            <AssetField
              label={"Recom'd Service"}
              text={
                component.lastServiceDate
                  ? DateTime.fromISO(component.lastServiceDate)
                      .plus({
                        days: component.componentType.serviceIntervalDays,
                      })
                      .toLocaleString(DateTime.DATE_MED)
                  : '--'
              }
            />
            <AssetField
              label={'Service Booked'}
              text={
                component.dueDate
                  ? DateTime.fromISO(component.dueDate).toLocaleString(DateTime.DATE_MED)
                  : '--'
              }
            />
            <AssetField label={'Status'} text={statusText ? statusText : '--'} />
          </div>
        </td>
      </tr>
    </Fragment>
  );
};

const AssetComponentByKmsRow: React.FC<{
  component: AssetComponentItem;
  lastOdometerReading?: AssetOdometerReadingItem;
}> = ({ component, lastOdometerReading }) => {
  const statusText = getHumanizedDueTextByKms(component, lastOdometerReading);

  return (
    <Fragment key={component.id}>
      <tr>
        <td>
          <div>
            <AssetField label={'Component'} text={component.componentType.description} />
            <AssetField
              label={'Part No.'}
              text={component.part ? component.part.partNumber : '--'}
            />
            <AssetField
              label={'Serial No.'}
              text={component.serialNumber ? component.serialNumber : '--'}
            />
            <AssetField
              label={'Service Interval'}
              text={
                component.componentType.serviceIntervalKms
                  ? component.componentType.serviceIntervalKms
                  : 'Not set'
              }
            />
          </div>
          <div>
            <AssetField
              label={'Last Service'}
              text={component.lastServiceKms ? component.lastServiceKms : '--'}
            />
            <AssetField
              label={"Recom'd Service"}
              text={
                component.lastServiceKms && component.componentType.serviceIntervalKms
                  ? component.lastServiceKms + component.componentType.serviceIntervalKms
                  : '--'
              }
            />
            <AssetField
              label={'Service Booked'}
              text={component.dueKms ? component.dueKms : '--'}
            />
            <AssetField label={'Status'} text={statusText ? statusText : '--'} />
          </div>
        </td>
      </tr>
    </Fragment>
  );
};

export const AssetComponentRegisterDisplay: React.FC<IAssetComponentRegisterDisplayProps> = ({
  assetId,
  components,
  lastOdometerReading,
  loadComponentRegisterForAsset,
  loadLastOdometerReading,
}) => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    Promise.all([
      loadLastOdometerReading(assetId),
      loadComponentRegisterForAsset(assetId),
    ]).then(() => setIsLoading(false));
  }, [assetId]);

  const componentsServicedByDate = components
    ? components.filter(
        c => c.part && c.componentType.serviceIntervalType === ComponentTypeServiceIntervalType.Day
      )
    : [];

  const componentsServicedByKms = components
    ? components.filter(
        c => c.part && c.componentType.serviceIntervalType === ComponentTypeServiceIntervalType.Km
      )
    : [];

  return (
    <Spinner show={isLoading} className={styles.scrollableTabbedArea}>
      <div className={styles.componentRegister}>
        {isLoading ? (
          <div />
        ) : !components || components.length === 0 ? (
          <div>There are no components configured for this asset.</div>
        ) : (
          <table>
            <tbody>
              <tr className={styles.categoryDescription}>
                <td>Serviced by Date</td>
              </tr>
              {componentsServicedByDate && componentsServicedByDate.length > 0 ? (
                componentsServicedByDate.map(c => (
                  <AssetComponentByDateRow key={c.id} component={c} />
                ))
              ) : (
                <tr>
                  <td>There are no components of this type defined for this asset.</td>
                </tr>
              )}
              <tr className={styles.categoryDescription}>
                <td>Serviced by Kms</td>
              </tr>
              {componentsServicedByKms && componentsServicedByKms.length > 0 ? (
                componentsServicedByKms.map(c => (
                  <AssetComponentByKmsRow
                    key={c.id}
                    component={c}
                    lastOdometerReading={lastOdometerReading}
                  />
                ))
              ) : (
                <tr>
                  <td>There are no components of this type defined for this asset.</td>
                </tr>
              )}
            </tbody>
          </table>
        )}
      </div>
    </Spinner>
  );
};
