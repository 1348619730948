import styles from './DisplayTransLinkCategoryWithExplanation.module.scss';
import { DisplayReportableEventCategory } from 'src/views/routes/compliance/reportableEvent/DisplayReportableEventCategory';

type ReportableEventCategory = Operations.Domain.Queries.ListReportableEvents.ReportableEventCategoryDto;

export const DisplayTransLinkCategoryWithExplanation: React.FC<{
  category: ReportableEventCategory;
}> = props => {
  return (
    <div className={styles.displayTransLinkCategoryWithExplanation}>
      <DisplayReportableEventCategory category={props.category} />
      <div className={styles.explanation}>{props.category.explanation}</div>
    </div>
  );
};
