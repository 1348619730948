import { DateTime } from 'luxon';
import { AssetField } from './AssetField';

export const AssetDateField: React.FC<{ label: string; dateValue: string | undefined }> = ({
  label,
  dateValue,
}) => {
  let displayDate = '';
  if (dateValue) {
    const date = DateTime.fromISO(dateValue); // expects ISO date string
    displayDate = date && date.isValid ? date.toLocaleString(DateTime.DATE_MED) : '';
  }
  return <AssetField label={label} text={displayDate} />;
};
