import { buildListPageApiSearchModelTypedQuery } from 'src/domain/modelBuilders/buildListPageApiSearchModel';
import { NotificationType } from 'src/domain';
import { IRootStoreModel } from 'src/domain/entities/RootStoreModel';
import { getAjax } from 'src/domain/services';
import { getRoot, flow } from 'mobx-state-tree';

type ListDefectsQuery = Workshop.Domain.Queries.Defect.ListDefects.ListDefectsQuery;

export const ListDefectsModel = buildListPageApiSearchModelTypedQuery<
  ListDefectsQuery
>()('ListDefectsModel', d => d.ajax.defect.listDefects(d.query)).actions(self => {
  const ajax = getAjax(self);
  const root = getRoot(self) as IRootStoreModel;

  const exportToExcel = flow(function*(
    query: Partial<Workshop.Domain.Queries.Defect.ListDefects.ListDefectsQuery>
  ) {
    root.notifications.addNotification(`The file is being generated ...`, {
      type: NotificationType.info,
    });

    return yield ajax.workshop.defects.exportDefectsToExcel(query);
  });

  return {
    exportToExcel,
  };
});
