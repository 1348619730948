import { buildListPageApiSearchModelTypedQuery } from 'src/domain/modelBuilders/buildListPageApiSearchModel';
import { getAjax, NotificationType } from 'src/domain/services';
import { getRoot, flow } from 'mobx-state-tree';
import { IRootStoreModel } from 'src/domain/entities/RootStoreModel';
import { DateTime } from 'luxon';

type ListBookingsForAccountsQuery = Operations.Domain.Queries.ListBookingsForAccounts.ListBookingsForAccountsQuery;

export const AccountingModel = buildListPageApiSearchModelTypedQuery<
  ListBookingsForAccountsQuery
>()('AccountingModel', d => d.ajax.sales.quote.listBookingsForAccounts(d.query)).actions(self => {
  const ajax = getAjax(self);
  const root = getRoot(self) as IRootStoreModel;

  const exportToExcel = flow(function*(
    to?: DateTime,
    from?: DateTime,
    assetIds?: string[],
    isDetailed?: boolean,
    useKioskInsteadOfTablet?: boolean
  ) {
    root.notifications.addNotification(`The file is being generated ...`, {
      type: NotificationType.info,
    });

    return yield ajax.accounting.exportKmsByJobTypeToExcel(
      to,
      from,
      assetIds,
      isDetailed,
      useKioskInsteadOfTablet
    );
  });

  const exportBookingsToExcel = flow(function*(query: Partial<ListBookingsForAccountsQuery>) {
    root.notifications.addNotification(`The file is being generated ...`, {
      type: NotificationType.info,
    });

    return yield ajax.accounting.exportBookingsToExcel(query);
  });

  const exportCentsPerKmType = flow(function*(
    to?: DateTime,
    from?: DateTime,
    assetIds?: string[],
    customerIds?: string[]
  ) {
    root.notifications.addNotification(`The file is being generated ...`, {
      type: NotificationType.info,
    });

    return yield ajax.accounting.exportCentsPerKmType(to, from, assetIds, customerIds);
  });
  return {
    exportToExcel,
    exportCentsPerKmType,
    exportBookingsToExcel,
  };
});
