import { buildListPageApiSearchModelTypedQuery } from 'src/domain/modelBuilders/buildListPageApiSearchModel';
import { types, getRoot, flow } from 'mobx-state-tree';
import { getAjax, NotificationType } from 'src/domain/services';
import { IRootStoreModel } from 'src/domain/entities/RootStoreModel';

type GetPurchaseOrderListQuery = Workshop.Domain.Queries.Purchasing.GetPurchaseOrderList.GetPurchaseOrderListQuery;
type GeneratePurchaseOrderBudgetReportExcelQuery = Workshop.Domain.Queries.Purchasing.GeneratePurchaseOrderBudgetReportExcel.GeneratePurchaseOrderBudgetReportExcelQuery;

const purchaseOrdersListModel = buildListPageApiSearchModelTypedQuery<
  GetPurchaseOrderListQuery
>()('PurchaseOrdersListModel', d => d.ajax.workshop.purchaseOrders.listPurchaseOrders(d.query));

const purchaseOrdersReportModel = types.model('PurchaseOrdersReportModel', {}).actions(self => {
  const ajax = getAjax(self);
  const root = getRoot(self) as IRootStoreModel;

  const generatePurchaseOrderBudgetReport: (
    query: GeneratePurchaseOrderBudgetReportExcelQuery
  ) => Promise<Blob> = flow(function*(query: GeneratePurchaseOrderBudgetReportExcelQuery) {
    root.notifications.addNotification(`The file is being generated ...`, {
      type: NotificationType.info,
    });

    return yield ajax.workshop.purchaseOrders.generatePurchaseOrderBudgetReport(query);
  });

  return {
    generatePurchaseOrderBudgetReport,
  };
});

export const ListPurchaseOrdersModel = types.compose(
  purchaseOrdersListModel,
  purchaseOrdersReportModel
);
