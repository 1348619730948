import { FormGroup, Label, Input, FormFeedback } from 'reactstrap';
import { IPax } from 'src/views/components/operations/RouteGroups/RouteGroups';
import { unsignedIntegerSanitiser } from 'src/views/components/Page/fields/NumericPageField';
import RailRouteGroups, { IRailRouteGroupsProps } from './RailRouteGroups';
import cn from 'classnames';

export interface IPaxEntryProps extends Omit<IRailRouteGroupsProps, 'updatePax'> {
  wheelchairPax: number | undefined;
  updatePax: (newPax: IPax[], wheelchairPax: number | undefined) => void;
}

export const PaxEntry: React.FC<IPaxEntryProps> = ({
  pax,
  wheelchairPax,
  updatePax,
  isReadOnly,
  routeGroups,
  validationWarnings,
}) => {
  return (
    <div className="routes">
      <RailRouteGroups
        routeGroups={routeGroups}
        pax={pax}
        updatePax={newPax => updatePax(newPax, wheelchairPax)}
        isReadOnly={isReadOnly}
        validationWarnings={validationWarnings}
      />
      <FormGroup className="wheelchair-pax">
        <Label for="wheelchairPax">How many Wheelchair passengers were carried?</Label>
        <div>
          <Input
            id="wheelchairPax"
            name="wheelchairPax"
            type="text"
            autoComplete="off"
            value={wheelchairPax === undefined ? '' : wheelchairPax}
            onChange={event => {
              const newValue =
                unsignedIntegerSanitiser(event.target.value, {
                  minValue: 0,
                  maxValue: 9999999,
                }) || 0;

              updatePax(pax, newValue);
            }}
            disabled={isReadOnly}
            className={cn({ readonly: isReadOnly })}
          />
          <FormFeedback>{validationWarnings && validationWarnings.wheelchairPax}</FormFeedback>
        </div>
      </FormGroup>
    </div>
  );
};
