import DashboardTile from 'src/views/components/Dashboard/DashboardTile';
import styles from './StandardDashboardTile.module.scss';
import cn from 'classnames';

export interface IStandardDashboardTileProps {
  number: number;
  title: string;
  link?: string;
  numberPrefix?: string;
  numberSuffix?: string;
}

const StandardDashboardTile = (props: IStandardDashboardTileProps) => {
  const numberPrefix = props.numberPrefix && <span>{props.numberPrefix}</span>;
  const numberSuffix = props.numberSuffix && <span>{props.numberSuffix}</span>;

  const tileContents = (
    <div>
      <p className={cn([styles.number], { [styles.numberZero]: props.number === 0 })}>
        {numberPrefix}
        {props.number}
        {numberSuffix}
      </p>
    </div>
  );

  return <DashboardTile title={props.title} content={tileContents} linkTo={props.link} />;
};

export default StandardDashboardTile;
