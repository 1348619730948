import { getSubmitCloseModalActionGroupDef } from 'src/views/definitionBuilders/common';
import { FieldType, ModalDefBuilder, PaneType } from 'src/views/definitionBuilders/types';

type RailBookingSubcontractorItem = Operations.Domain.Queries.ViewRailBooking.RailBookingSubcontractorItem;
type SubcontractorItem = Common.Dtos.SubcontractorItem;

export default function getMaintainSubcontractorsModalDef(
  subcontractors: SubcontractorItem[],
  isEditing: boolean
): ModalDefBuilder {
  return modalDefApi => {
    return {
      title: isEditing ? 'Edit Subcontractor' : 'Add Subcontractor',
      asForm: true,
      panels: [
        {
          panes: [
            {
              paneType: PaneType.formFieldsPane,
              fields: [
                {
                  fieldType: FieldType.selectField,
                  dataAddr: 'subcontractorId',
                  label: 'Subcontractor',
                  valueKey: 'id',
                  descriptionKey: 'name',
                  optionItems: subcontractors,
                  columnWidth: '20em',
                  mandatory: true,
                  useValueOnly: true,
                  valuesToDisable: () =>
                    modalDefApi.parentFormApi.values.railBookingSubcontractors.map(
                      (subcontractor: RailBookingSubcontractorItem) => subcontractor.subcontractorId
                    ),
                },
                {
                  fieldType: FieldType.numericField,
                  dataAddr: 'quantity',
                  numericConfig: { numericType: 'unsignedInt' },
                  label: 'Quantity',
                  columnWidth: '6em',
                  mandatory: true,
                },
                {
                  fieldType: FieldType.numericField,
                  dataAddr: 'totalHours',
                  numericConfig: {
                    numericType: 'unsignedDecimal',
                    maxPointDigits: 2,
                    maxValue: 99999,
                  },
                  label: 'Total Hours',
                  columnWidth: '6em',
                },
                {
                  fieldType: FieldType.numericField,
                  dataAddr: 'totalPrice',
                  numericConfig: {
                    numericType: 'unsignedDecimal',
                    maxPointDigits: 2,
                  },
                  label: 'Total Price',
                  columnWidth: '7em',
                  mandatory: true,
                },
              ],
            },
          ],
        },
      ],
      secondaryActions: [getSubmitCloseModalActionGroupDef()],
      onFormSubmit: values => {
        const subcontractorItems = modalDefApi.parentFormApi.values.railBookingSubcontractors;
        const dataAddr = modalDefApi.actionData.fieldDataAddr;

        return !isEditing
          ? Promise.resolve(
              modalDefApi.parentFormApi.setValue('railBookingSubcontractors', [
                ...subcontractorItems,
                values,
              ])
            )
          : Promise.resolve(modalDefApi.parentFormApi.setValue(dataAddr, { ...values }));
      },
    };
  };
}
