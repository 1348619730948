import { LocationDescriptor } from 'history';
import { Link, LinkProps } from 'react-router-dom';
import { UiRouteName, isUiRouteName, IStores } from 'src/domain';
import inject from 'src/views/injectFromStore';
import { UiRoutes } from 'src/domain/entities/app/HistoryModel';

export interface ILinkButtonProps {
  uiRoutes: UiRoutes;
  to: UiRouteName | LocationDescriptor;
  outline?: boolean;
  active?: boolean;
  block?: boolean;
  color?: 'primary' | 'secondary' | 'success' | 'danger' | 'warning' | 'info' | 'light' | 'dark';
  disabled?: boolean;
  size?: 'lg' | 'sm';
  id?: string;
  className?: string;
  onClick?: LinkProps['onClick'];
}

const LinkButton: React.FC<ILinkButtonProps> = ({
  uiRoutes,
  to,
  className,
  color,
  outline,
  size,
  block,
  active,
  disabled,
  onClick,
  children,
}) => {
  const toPath = isUiRouteName(to) ? uiRoutes[to as UiRouteName].buildPath() : to;
  const classes = `${className || ''} btn btn-${outline ? 'outline-' : ''}${color} ${
    size ? 'btn-' + size : ''
  } ${block ? 'btn-block' : ''} ${active ? 'active' : ''} ${disabled ? 'disabled' : ''}`;
  return (
    <Link to={toPath} className={classes} onClick={onClick}>
      {children}
    </Link>
  );
};

interface IInjectedProps {
  uiRoutes: ILinkButtonProps['uiRoutes'];
}

const Container = inject<IInjectedProps, ILinkButtonProps>((allStores: IStores, p) => ({
  uiRoutes: allStores.rootStore.history.uiRoutes,
}))(LinkButton);

export default Container;
