import { CSSProperties } from 'react';

interface IAppCssProperties extends CSSProperties {
  '--primary-background': string;
  '--primary-label': string;
  '--primary-text': string;
  '--primary-highlight-color': string;

  '--secondary-background': string;
  '--secondary-darker-background': string;
  '--secondary-border-color': string;
  '--secondary-text': string;
  '--secondary-label': string;

  '--tertiary-background': string;
  '--tertiary-text': string;
  '--tertiary-label': string;
  '--tertiary-highlight-color': string;

  '--error': string;
  '--success': string;

  '--font-family': string;
  '--font-size-xs': string;
  '--font-size-smaller': string;
  '--font-size-s': string;
  '--font-size-m': string;
  '--font-size-l': string;
  '--font-weight-normal': string;
  '--font-weight-bold': string;

  '--margin-s': string;
  '--margin-m': string;
  '--margin-l': string;
}

export const siteDefault: IAppCssProperties = {
  '--primary-background': '#fff',
  '--primary-label': 'rgba(23, 43, 76, 0.6)',
  '--primary-text': '#172b4d',
  '--primary-highlight-color': '#00ae35',

  '--secondary-background': '#f4f5f6',
  '--secondary-darker-background': '#e1e6f1',
  '--secondary-border-color': '#bec9e1',
  '--secondary-text': '#172b4d',
  '--secondary-label': 'rgba(23, 43, 76)',

  '--tertiary-background': '#007bff',
  '--tertiary-text': '#fff',
  '--tertiary-label': '#fff',
  '--tertiary-highlight-color': '#ff5d8d',

  '--error': '#fe0000',
  '--success': '#00ae35',

  '--font-family': 'Roboto',
  '--font-size-xs': '1.1rem',
  '--font-size-smaller': '1.3rem',
  '--font-size-s': '1.5rem',
  '--font-size-m': '2rem',
  '--font-size-l': '2.7rem',
  '--font-weight-normal': '300',
  '--font-weight-bold': '600',

  '--margin-s': '1.2rem',
  '--margin-m': '1.5rem',
  '--margin-l': '3rem',
};
