import { ListPageLoadCause } from 'src/domain';
import { UserIcon } from 'src/images/icons';
import { StaffMemberFilter } from 'src/views/components/Page/fields/StaffMemberField';
import { getSubmitCloseModalActionGroupDef } from 'src/views/definitionBuilders/common';
import {
  IModalActionButtonDef,
  ActionType,
  ShellModalSize,
  PaneType,
  FieldType,
} from 'src/views/definitionBuilders/types';
import { IListExternalIdentitiesProps } from './ListExternalIdentities';

type ListExternalIdentityItem = People.Domain.Queries.ListExternalIdentities.ListExternalIdentityItem;

export default function getAssignStaffMemberActionButtonDef(
  loadExternalIdentities: IListExternalIdentitiesProps['loadExternalIdentities'],
  updateExternalIdentity: IListExternalIdentitiesProps['updateExternalIdentity']
): IModalActionButtonDef {
  return {
    actionType: ActionType.modalActionButton,
    label: 'Assign Staff Member',
    icon: <UserIcon />,
    modalSize: ShellModalSize.oneThird,
    hidden: d => {
      const item = d.parentValue as ListExternalIdentityItem;
      return item.mappedBySystem === true;
    },
    modalDef: modalDefApi => {
      const item = modalDefApi.actionData.parentValue as ListExternalIdentityItem;

      return {
        explicitData: {
          assignedStaffMember: item.staffMember,
        },
        title: d => `Assign Staff Member`,
        asForm: true,
        panels: [
          {
            panes: [
              {
                paneType: PaneType.customPane,
                render: api => (
                  <>
                    <p>
                      Please select the correct staff member from the list that corresponds to this
                      external identity (<i>{item.externalEmail}</i>).
                    </p>
                    <p>Note: Any changes made may not take effect for up to one hour.</p>
                  </>
                ),
              },
              {
                paneType: PaneType.formFieldsPane,
                fields: [
                  {
                    fieldType: FieldType.staffMemberField,
                    label: 'Staff Member',
                    dataAddr: 'assignedStaffMember',
                    mandatory: false,
                    staffMemberFilter: StaffMemberFilter.active,
                  },
                ],
              },
            ],
          },
        ],
        secondaryActions: [getSubmitCloseModalActionGroupDef('Ok')],
        onFormSubmit: d =>
          updateExternalIdentity({
            id: item.externalId,
            staffMemberId: d.assignedStaffMember?.id,
          }).then(r => {
            loadExternalIdentities({
              query: {},
              loadCause: ListPageLoadCause.refresh,
            });
          }),
      };
    },
  };
}
