import { ListPage } from 'src/views/components/Page';
import { useState } from 'react';
import { ActionType, FieldType } from 'src/views/definitionBuilders/types';
import { CheckIcon } from 'src/images/icons';
import { DateTimeFormat } from 'src/views/components/DateTimeFormat';

type AssetJobProgressWithUnknownKmsDto = Operations.Domain.Queries.ListAssetJobProgressWithUnknownKms.AssetJobProgressWithUnknownKmsDto;

type ListAssetJobProgressWithUnknownKmsQuery = Operations.Domain.Queries.ListAssetJobProgressWithUnknownKms.ListAssetJobProgressWithUnknownKmsQuery;

import styles from './ListAssetsWithUnknownKms.module.scss';
import { getJobTypeDescriptor, getProgressIdDescriptor, RowType } from 'src/api/enums';
import { isDefined } from 'src/infrastructure/typeUtils';
import { Link } from 'react-router-dom';
import { ListPageLoadCause } from 'src/domain';
import withQueryParams, { IQueryParamsProps } from 'src/views/hocs/withQueryParams';
import { IListPageLoadDataRequest } from 'src/domain/baseTypes';
import { IListPageDef } from 'src/views/components/Page/pages/ListPage';
import PrimaryTitle from 'src/views/components/Page/PrimaryTitle/PrimaryTitle';

export interface IListAssetsWithUnknownKmsProps {
  assetsWithUnknownKms: AssetJobProgressWithUnknownKmsDto[];
  hasMoreData: boolean;
  loadAssetsWithUnknownKms: (
    request: IListPageLoadDataRequest<ListAssetJobProgressWithUnknownKmsQuery>
  ) => Promise<void>;
  dismissUnknownKms: (id: string) => Promise<void>;
}

type InternalProps = IListAssetsWithUnknownKmsProps &
  IQueryParamsProps<ListAssetJobProgressWithUnknownKmsQuery>;

const ListAssetsWithUnknownKms: React.FC<InternalProps> = (props: InternalProps) => {
  const [recordsBeingDismissed, setRecordsBeingDismissed] = useState<{ [key: string]: boolean }>(
    {}
  );

  const getPageDef = (): IListPageDef => {
    return {
      primaryTitle: (
        <PrimaryTitle
          title="Assets With Unknown Kms"
          link="https://central-ops.notion.site/Unknown-Kilometres-442e4a47c954441eb8260cdeba402524"></PrimaryTitle>
      ),
      onLoadData: props.loadAssetsWithUnknownKms,
      externalSearch: true,
      hasMoreData: props.hasMoreData,
      filterAction: {
        defaultValues: {
          showDismissed: false,
        },
        filterFields: [
          {
            fieldType: FieldType.yesNoField,
            label: 'Dismissed?',
            dataAddr: 'showDismissed',
          },
        ],
      },
      primaryFields: [
        {
          fieldType: FieldType.readonlyField,
          linkTo: x => `/workshop/assets/${x.parentValue.assetId}`,
          label: 'Asset',
          dataAddr: 'assetRego',
          columnWidth: '7rem',
        },
        {
          fieldType: FieldType.customField,
          dataAddr: 'info',
          label: 'Job Details',
          labelAlignRight: true,
          columnWidth: '10rem',
          render: d => {
            return (
              <div className={styles.labels}>
                <span>Job Number:</span>
                <span>Job Type:</span>
                <span>Tablet Status:</span>
                <span>Occurred At:</span>
                <span>Odometer Reading:</span>
              </div>
            );
          },
        },
        {
          fieldType: FieldType.customField,
          dataAddr: 'first',
          label: 'First Job',
          labelAlignRight: true,
          render: d => {
            const item = d.data.parentValue as AssetJobProgressWithUnknownKmsDto;
            const first = item.details.first;

            return (
              <div className={styles.labels}>
                <Link
                  to={`/${first.progressTypeId === RowType.Ops ? 'operations' : 'workshop'}/jobs/${
                    first.jobId
                  }`}>
                  {first.jobNumber}
                </Link>
                <span>
                  {isDefined(first.jobTypeId)
                    ? getJobTypeDescriptor(first.jobTypeId).description
                    : 'Workshop Job'}
                </span>
                <strong>{getProgressIdDescriptor(first.progressId).description}</strong>
                <DateTimeFormat value={first.occurredAt} />
                <span>{first.odometerReading}</span>
              </div>
            );
          },
        },
        {
          fieldType: FieldType.customField,
          dataAddr: 'second',
          label: 'Second Job',
          render: d => {
            const item = d.data.parentValue as AssetJobProgressWithUnknownKmsDto;
            const second = item.details.second;
            return (
              <div className={styles.griddy}>
                <Link
                  to={`/${second.progressTypeId === RowType.Ops ? 'operations' : 'workshop'}/jobs/${
                    second.jobId
                  }`}>
                  {second.jobNumber}
                </Link>
                <span>
                  {isDefined(second.jobTypeId)
                    ? getJobTypeDescriptor(second.jobTypeId).description
                    : 'Workshop Job'}
                </span>
                <strong>{getProgressIdDescriptor(second.progressId).description}</strong>
                <DateTimeFormat value={second.occurredAt} />
                <span>{second.odometerReading}</span>
              </div>
            );
          },
        },
        {
          fieldType: FieldType.customField,
          dataAddr: 'calc',
          label: 'UnKnown Kms',
          columnWidth: '10rem',
          render: d => {
            const item = d.data.parentValue as AssetJobProgressWithUnknownKmsDto;
            const first = item.details.first;
            const second = item.details.second;

            const odoDiff = second.odometerReading - first.odometerReading;
            return (
              <div className={styles.griddy}>
                <span>&nbsp;</span>
                <span>&nbsp;</span>
                <span>&nbsp;</span>
                <span>&nbsp;</span>
                <span>{odoDiff}kms</span>
              </div>
            );
          },
        },
        {
          fieldType: FieldType.actionListField,
          dataAddr: '',
          columnWidth: '1px',
          label: 'Confirm Fixed',
          actionGroups: [
            {
              actions: [
                {
                  actionType: ActionType.actionButton,
                  label: 'Confirm Fixed',
                  hidden: d => d.parentValue.isDismissed,
                  icon: <CheckIcon fixedWidth />,
                  disabled: d => recordsBeingDismissed[d.parentValue.id],
                  onClick: p => {
                    dismiss(p.parentValue.id);
                  },
                },
              ],
            },
          ],
        },
      ],
    };
  };

  const dismiss = (id: string) => {
    setRecordsBeingDismissed({ ...recordsBeingDismissed, [id]: true });

    return props.dismissUnknownKms(id).then(r => {
      props
        .loadAssetsWithUnknownKms({
          query: { ...props.getQueryParams() },
          loadCause: ListPageLoadCause.refresh,
        })
        .finally(() => setRecordsBeingDismissed({ ...recordsBeingDismissed, [id]: false }));
    });
  };

  return (
    <ListPage
      className="list-assets-with-unknown-kms"
      data={props.assetsWithUnknownKms}
      def={getPageDef()}
    />
  );
};

export default withQueryParams(ListAssetsWithUnknownKms);
