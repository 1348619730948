import { types, flow } from 'mobx-state-tree';
import { getAjax } from 'src/domain/services';

type HourlyRateItem = Operations.Domain.Queries.ListHourlyRates.HourlyRateItem;
type UpdateHourlyRatesCommand = Operations.Domain.Commands.HourlyRate.UpdateHourlyRatesCommand;
type CreateHourlyRatesCommand = Operations.Domain.Commands.HourlyRate.CreateHourlyRatesCommand;

export const HourlyRatesModel = types
  .model('HourlyRatesModel', {
    hourlyRates: types.array(types.frozen<HourlyRateItem>()),
  })
  .actions(self => {
    const ajax = getAjax(self);

    const listHourlyRates = flow(function*() {
      self.hourlyRates = yield ajax.sales.hourlyRates.listHourlyRates();
    });

    const updateHourlyRates = flow(function*(command: UpdateHourlyRatesCommand) {
      yield ajax.sales.hourlyRates.updateHourlyRates(command);
      yield listHourlyRates();
    });

    const createHourlyRates = flow(function*(command: CreateHourlyRatesCommand) {
      yield ajax.sales.hourlyRates.createHourlyRates(command);
      yield listHourlyRates();
    });

    const deleteHourlyRates = flow(function*(id: string) {
      yield ajax.sales.hourlyRates.deleteHourlyRates(id);
      yield listHourlyRates();
    });
    return {
      listHourlyRates,
      updateHourlyRates,
      createHourlyRates,
      deleteHourlyRates,
    };
  });

type IHourlyRatesModelType = typeof HourlyRatesModel.Type;
export interface IHourlyRatesModel extends IHourlyRatesModelType {}
