import { types, flow } from 'mobx-state-tree';
import { getAjax } from 'src/domain/services';
import { peopleUrls } from 'src/domain/services/apiUrls';

type SkillSpecItem = Common.Dtos.SkillSpecItem;

export const SkillSpecsForRequirementsModel = types
  .model('SkillSpecsForRequirementsModel', {
    items: types.array(types.frozen<SkillSpecItem>()),
  })
  .actions(self => {
    const ajax = getAjax(self);

    const getAll = flow(function*() {
      self.items = yield ajax.raw
        .get(peopleUrls.staffMemberUrls.skillSpecUrls.getAllForRequirements())
        .toPromise()
        .then(r => r.response);
    });

    return {
      getAll,
    };
  });
