import { IAjax } from 'src/infrastructure/ajax';
import { operationsUrls } from 'src/domain/services/apiUrls';

export default function init(ajax: IAjax) {
  async function createShift(
    commandPromise: Promise<Operations.Domain.Commands.Shift.CreateShift.CreateShiftCommand>
  ) {
    const command = await commandPromise;

    return ajax
      .post(operationsUrls.shiftUrls.createShift(), command)
      .toPromise()
      .then<string>(r => r.response);
  }

  function checkForUniqueShiftName(shiftName: string) {
    return ajax
      .get(operationsUrls.shiftUrls.checkForUniqueShiftName(shiftName))
      .toPromise()
      .then<Common.Dtos.UniqueNameCheckResultDto>(r => r.response);
  }

  function updateShift(command: Operations.Domain.Commands.Shift.UpdateShift.UpdateShiftCommand) {
    return ajax.put(operationsUrls.shiftUrls.updateShift(command.id), command).toPromise();
  }

  function viewShift(shiftId: string) {
    return ajax
      .get(operationsUrls.shiftUrls.viewShift(shiftId))
      .toPromise()
      .then<Operations.Domain.Queries.ViewShift.ShiftItem>(r => r.response);
  }

  function loadShiftTypes() {
    return ajax
      .get(operationsUrls.shiftUrls.loadShiftTypes())
      .toPromise()
      .then<Operations.Domain.AggregatesModel.ShiftAggregate.ShiftType[]>(r => r.response);
  }

  function listShifts(query?: Operations.Domain.Queries.ListShifts.ListShiftsQuery) {
    return ajax
      .get(operationsUrls.shiftUrls.listShifts(query))
      .toPromise()
      .then<Operations.Domain.Queries.ListShifts.ShiftListItem>(r => r.response);
  }

  function deleteShift(shiftId: string) {
    return ajax.httpDelete(operationsUrls.shiftUrls.deleteShift(shiftId)).toPromise();
  }

  function getShiftsByContracts(id: string) {
    return ajax
      .get(operationsUrls.shiftUrls.getShiftsByContract(id))
      .toPromise()
      .then(r => {
        return r.response as Operations.Domain.Queries.GetShiftsByCharterContract.ShiftSelectListItem[];
      });
  }

  return {
    shifts: {
      createShift,
      checkForUniqueShiftName,
      updateShift,
      viewShift,
      loadShiftTypes,
      listShifts,
      deleteShift,
      getShiftsByContracts,
    },
  };
}
