import { buildListPageApiSearchModelTypedQuery } from 'src/domain/modelBuilders/buildListPageApiSearchModel';
import * as queryString from 'query-string';

type ListHolidaysQuery = Operations.Domain.Queries.ListHolidays.ListHolidaysQuery;
type HolidayListItem = Operations.Domain.Queries.ListHolidays.HolidayListItem;

export const HolidaysListPageModel = buildListPageApiSearchModelTypedQuery<
  ListHolidaysQuery
>()('HolidaysListPageModel', d =>
  d.ajax.raw
    .get(`/api/operations/holidays?${queryString.stringify(d.query)}`)
    .map(r => r.response as Common.Dtos.ListResult<HolidayListItem>)
);
