import inject from 'src/views/injectFromStore';
import { MaintainSupplier, IMaintainSupplierProps } from './MaintainSupplier';
import Omit from 'src/infrastructure/omit';

const Container = inject<Omit<IMaintainSupplierProps, 'mode'>, IMaintainSupplierProps>(
  (allStores, p) => ({
    canManageSuppliers: allStores.rootStore.account.isWorkshopDepartmentMember,
    supplier: allStores.rootStore.workshop.supplier.supplier,
    onLoadSupplier: allStores.rootStore.workshop.supplier.loadSupplier,
    onUpdateSupplier: allStores.rootStore.workshop.supplier.updateSupplier,
    onCreateSupplier: allStores.rootStore.workshop.supplier.createSupplier,
    checkForUniqueSupplierName: allStores.rootStore.workshop.supplier.checkForUniqueName,
    onCreateSupplierContact: allStores.rootStore.workshop.supplier.createSupplierContact,
    onUpdateSupplierContact: allStores.rootStore.workshop.supplier.updateSupplierContact,
    onCreateSupplierNote: allStores.rootStore.workshop.supplier.createSupplierNote,
    onUpdateSupplierNote: allStores.rootStore.workshop.supplier.updateSupplierNote,
    onDeleteSupplierNote: allStores.rootStore.workshop.supplier.deleteSupplierNote,
    purchaseOrdersForSupplier: allStores.rootStore.workshop.supplier.purchaseOrdersForSupplier,
    listPurchaseOrdersForSupplier:
      allStores.rootStore.workshop.supplier.listPurchaseOrdersForSupplier,
    attachments: allStores.rootStore.workshopAttachments.attachments.slice(),
    addAttachment: allStores.rootStore.workshopAttachments.addWorkshopAttachment,
    loadAttachmentsForSupplier:
      allStores.rootStore.workshopAttachments.loadWorkshopAttachmentDetails,
    downloadAttachmentForSupplier:
      allStores.rootStore.workshopAttachments.downloadWorkshopAttachment,
    deleteAttachmentFromSupplier: allStores.rootStore.workshopAttachments.deleteWorkshopAttachment,
    updateAttachmentForSupplier: allStores.rootStore.workshopAttachments.updateWorkshopAttachment,
    deleteSupplierContactCommand: allStores.rootStore.workshop.supplier.deleteSupplierContact,
  })
)(MaintainSupplier);

export default Container;
