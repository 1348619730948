import { Component } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import {
  PagePrimarySize,
  PaneType,
  FieldType,
  ActionType,
  ShellModalSize,
  ISectionDef,
  IModalDefBuilderApi,
  ITablePaneDef,
} from 'src/views/definitionBuilders/types';
import CrudPage, { CrudPageMode, ICrudPageDef } from 'src/views/components/Page/pages/CrudPage';
import * as EmailValidator from 'email-validator';
import { PlusIcon, EditIcon } from 'src/images/icons';
import { standardTextSanitisers } from 'src/views/componentBuilders/buildLabelledText';
import { getSubmitCloseModalActionGroupDef } from 'src/views/definitionBuilders/common';

type SubcontractorItem = Operations.Domain.Queries.ViewSubcontractor.SubcontractorItem;
type CreateSubcontractorCommand = Operations.Domain.Commands.Subcontractor.CreateSubcontractor.CreateSubcontractorCommand;
type UpdateSubcontractorCommand = Operations.Domain.Commands.Subcontractor.UpdateSubcontractor.UpdateSubcontractorCommand;

export interface IMaintainSubcontractorProps {
  mode: CrudPageMode;
  canManageSubcontractors: boolean;
  createSubcontractor: (command: CreateSubcontractorCommand) => Promise<void>;
  updateSubcontractor: (command: UpdateSubcontractorCommand) => Promise<void>;
  checkForUniqueName: (name: string) => Promise<Common.Dtos.UniqueNameCheckResultDto>;
  loadSubcontractor: (subcontractorId: string) => Promise<void>;
  subcontractor: SubcontractorItem | undefined;
  createContact: (
    command: Operations.Domain.Commands.Subcontractor.CreateSubcontractorContact.CreateSubcontractorContactCommand
  ) => Promise<void>;
  updateContact: (
    command: Operations.Domain.Commands.Subcontractor.UpdateSubcontractorContact.UpdateSubcontractorContactCommand
  ) => Promise<void>;
}

interface IMaintainSubcontractorRouteParams {
  id: string;
}

type InternalProps = IMaintainSubcontractorProps &
  RouteComponentProps<IMaintainSubcontractorRouteParams>;

class MaintainSubcontractor extends Component<InternalProps> {
  private get isUpdateMode() {
    return this.props.mode === 'update';
  }

  private get SubcontractorId() {
    return this.props.match.params.id;
  }

  private handlePreSubmitForCreate = (
    subcontractor: SubcontractorItem
  ): CreateSubcontractorCommand => {
    return {
      address: subcontractor.address,
      city: subcontractor.city,
      name: subcontractor.name,
      operatorAccreditationExpiryDate: subcontractor.operatorAccreditationExpiryDate,
      operatorAccreditationNumber: subcontractor.operatorAccreditationNumber,
      phoneNumber: subcontractor.phoneNumber,
      postcode: subcontractor.postcode,
      state: subcontractor.state,
    };
  };

  private handlePreSubmitForUpdate = (
    subcontractor: SubcontractorItem
  ): UpdateSubcontractorCommand => {
    return {
      id: subcontractor.id,
      address: subcontractor.address,
      city: subcontractor.city,
      name: subcontractor.name,
      operatorAccreditationExpiryDate: subcontractor.operatorAccreditationExpiryDate,
      operatorAccreditationNumber: subcontractor.operatorAccreditationNumber,
      phoneNumber: subcontractor.phoneNumber,
      postcode: subcontractor.postcode,
      state: subcontractor.state,
    };
  };

  private getSubcontractorForm(): ISectionDef {
    return {
      dataAddr: 'subcontractor',
      title: this.isUpdateMode ? 'Manage Subcontractor' : 'Create a Subcontractor',
      panels: [
        {
          panes: [
            {
              paneType: PaneType.formFieldsPane,
              fields: [
                {
                  fieldType: FieldType.textField,
                  label: 'Name',
                  dataAddr: 'name',
                  maxLength: 200,
                  mandatory: true,
                  validateAsync: async d => {
                    if (
                      !d.fieldValue ||
                      (this.isUpdateMode &&
                        this.props.subcontractor &&
                        this.props.subcontractor.name.toUpperCase() === d.fieldValue.toUpperCase())
                    ) {
                      return undefined;
                    }
                    const result = await this.props.checkForUniqueName(d.fieldValue);
                    return result.nameExists ? `Name is already in use` : undefined;
                  },
                },
                {
                  fieldType: FieldType.textField,
                  label: 'Phone Number',
                  dataAddr: 'phoneNumber',
                  maxLength: 20,
                  mandatory: true,
                  onChange: api =>
                    api.setFormValue(
                      api.fieldDataAddr,
                      standardTextSanitisers.numericSanitiser(api.fieldData.fieldValue as string)
                    ),
                },
              ],
            },
          ],
        },
        {
          panes: [
            {
              paneType: PaneType.formFieldsPane,
              fields: [
                {
                  fieldType: FieldType.textField,
                  label: 'Address',
                  dataAddr: 'address',
                  maxLength: 200,
                  mandatory: true,
                },
              ],
            },
            {
              paneType: PaneType.formFieldsPane,
              columnCount: 3,
              fields: [
                {
                  fieldType: FieldType.textField,
                  label: 'City',
                  dataAddr: 'city',
                  maxLength: 200,
                  mandatory: true,
                },
                {
                  fieldType: FieldType.textField,
                  label: 'State',
                  dataAddr: 'state',
                  maxLength: 200,
                  mandatory: true,
                },
                {
                  fieldType: FieldType.textField,
                  label: 'Postcode',
                  dataAddr: 'postcode',
                  maxLength: 4,
                  mandatory: true,
                  onChange: api =>
                    api.setFormValue(
                      api.fieldDataAddr,
                      standardTextSanitisers.numericSanitiser(api.fieldData.fieldValue as string)
                    ),
                },
              ],
            },
          ],
        },
        {
          panes: [
            {
              paneType: PaneType.formFieldsPane,
              columnCount: 2,
              fields: [
                {
                  fieldType: FieldType.textField,
                  label: 'Operator Accreditation Number',
                  dataAddr: 'operatorAccreditationNumber',
                  maxLength: 20,
                  mandatory: true,
                },
                {
                  fieldType: FieldType.dateField,
                  label: 'Operator Accreditation Expiry Date',
                  dataAddr: 'operatorAccreditationExpiryDate',
                  mandatory: true,
                },
              ],
            },
          ],
        },
      ],
      onFormPreSubmit: this.isUpdateMode
        ? this.handlePreSubmitForUpdate
        : this.handlePreSubmitForCreate,
      onFormSubmit: this.isUpdateMode
        ? this.props.updateSubcontractor
        : this.props.createSubcontractor,
    };
  }

  private readonly getCreateContactModal = (): ((
    modalDefApi: IModalDefBuilderApi
  ) => ISectionDef) => {
    return modalDefApi => ({
      title: 'Create Contact',
      asForm: true,
      panels: [
        {
          panes: [
            {
              paneType: PaneType.formFieldsPane,
              fields: [
                {
                  fieldType: FieldType.textField,
                  label: 'Name',
                  dataAddr: 'contactName',
                  maxLength: 200,
                  mandatory: true,
                },
                {
                  fieldType: FieldType.textField,
                  label: 'Phone Number',
                  dataAddr: 'contactPhoneNumber',
                  maxLength: 20,
                  mandatory: true,
                  onChange: api =>
                    api.setFormValue(
                      api.fieldDataAddr,
                      standardTextSanitisers.numericSanitiser(api.fieldData.fieldValue as string)
                    ),
                },
                {
                  fieldType: FieldType.textField,
                  label: 'Email',
                  dataAddr: 'contactEmail',
                  maxLength: 200,
                  mandatory: true,
                  validate: email =>
                    !!email.fieldValue && !EmailValidator.validate(email.fieldValue)
                      ? `Email must be a valid email`
                      : undefined,
                },
              ],
            },
          ],
        },
      ],
      secondaryActions: [getSubmitCloseModalActionGroupDef('Ok')],
      onFormSubmit: v =>
        this.props.createContact({
          subcontractorId: this.SubcontractorId,
          email: v.contactEmail,
          name: v.contactName,
          phoneNumber: v.contactPhoneNumber,
        } as Operations.Domain.Commands.Subcontractor.CreateSubcontractorContact.CreateSubcontractorContactCommand),
    });
  };

  private getUpdateContactModalData(modalDefApi: IModalDefBuilderApi) {
    return {
      contactId: modalDefApi.actionData.actionValue.id,
      contactName: modalDefApi.actionData.actionValue.name,
      contactPhoneNumber: modalDefApi.actionData.actionValue.phoneNumber,
      contactEmail: modalDefApi.actionData.actionValue.email,
    };
  }

  private getContactsTableDef(): ITablePaneDef {
    const { canManageSubcontractors } = this.props;
    return {
      paneType: PaneType.tablePane,
      dataAddr: ['subcontractor', 'contacts'],
      neverEditable: true,
      fields: [
        {
          fieldType: FieldType.textField,
          label: 'Name',
          dataAddr: 'name',
        },
        {
          fieldType: FieldType.textField,
          label: 'Phone Number',
          dataAddr: 'phoneNumber',
        },
        {
          fieldType: FieldType.textField,
          label: 'Email',
          dataAddr: 'email',
        },
        {
          fieldType: FieldType.actionListField,
          hidden: () => !canManageSubcontractors,
          label: 'Update',
          actionGroups: [
            {
              actions: [
                {
                  actionType: ActionType.modalActionButton,
                  label: 'Update Contact',
                  icon: <EditIcon />,
                  modalSize: ShellModalSize.half,
                  modalDef: modalDefApi => ({
                    title: 'Update Contact',
                    asForm: true,
                    explicitData: this.getUpdateContactModalData(modalDefApi),
                    panels: [
                      {
                        panes: [
                          {
                            paneType: PaneType.formFieldsPane,
                            fields: [
                              {
                                fieldType: FieldType.textField,
                                label: 'Name',
                                dataAddr: 'contactName',
                                maxLength: 200,
                                mandatory: true,
                              },
                              {
                                fieldType: FieldType.textField,
                                label: 'Phone Number',
                                dataAddr: 'contactPhoneNumber',
                                maxLength: 20,
                                mandatory: true,
                                onChange: api =>
                                  api.setFormValue(
                                    api.fieldDataAddr,
                                    standardTextSanitisers.numericSanitiser(
                                      api.fieldData.fieldValue as string
                                    )
                                  ),
                              },
                              {
                                fieldType: FieldType.textField,
                                label: 'Email',
                                dataAddr: 'contactEmail',
                                maxLength: 200,
                                mandatory: true,
                                validate: email =>
                                  !!email.fieldValue && !EmailValidator.validate(email.fieldValue)
                                    ? `Email must be a valid email`
                                    : undefined,
                              },
                            ],
                          },
                        ],
                      },
                    ],
                    secondaryActions: [getSubmitCloseModalActionGroupDef('Ok')],
                    onFormSubmit: v => {
                      return this.props.updateContact({
                        subcontractorId: this.SubcontractorId,
                        contactId: v.contactId,
                        email: v.contactEmail,
                        name: v.contactName,
                        phoneNumber: v.contactPhoneNumber,
                      } as Operations.Domain.Commands.Subcontractor.UpdateSubcontractorContact.UpdateSubcontractorContactCommand);
                    },
                  }),
                },
              ],
            },
          ],
        },
      ],
    };
  }

  private readonly getPageDef = (mode: CrudPageMode): ICrudPageDef => {
    const { canManageSubcontractors } = this.props;
    return {
      primarySize: PagePrimarySize.half,
      primarySection: this.getSubcontractorForm(),
      secondarySections: this.isUpdateMode
        ? [
            {
              title: 'Contacts',
              isTab: true,
              panels: [
                {
                  panes: [this.getContactsTableDef()],
                },
              ],
              secondaryActions: canManageSubcontractors
                ? [
                    {
                      actions: [
                        {
                          actionType: ActionType.modalActionButton,
                          label: 'Add Contact',
                          icon: <PlusIcon />,
                          modalSize: ShellModalSize.half,
                          modalDef: this.getCreateContactModal(),
                        },
                      ],
                    },
                  ]
                : [],
            },
          ]
        : [],
    };
  };

  render() {
    const { mode, subcontractor, loadSubcontractor, canManageSubcontractors } = this.props;
    return (
      <CrudPage
        def={this.getPageDef(mode)}
        mode={mode}
        isEditingForbidden={!canManageSubcontractors}
        onLoadData={() => loadSubcontractor(this.SubcontractorId)}
        data={{ subcontractor }}
        createDefaultData={{}}
      />
    );
  }
}

export default MaintainSubcontractor;
