import { types, getRoot, flow } from 'mobx-state-tree';
import { IRootStoreModel } from 'src/domain/entities/RootStoreModel';
import { getAjax } from 'src/domain/services';

type CreateRosterCommand = Workshop.Domain.Commands.Roster.CreateRoster.CreateRosterCommand;
type UpdateRosterCommand = Workshop.Domain.Commands.Roster.UpdateRoster.UpdateRosterCommand;
type RosterItem = Workshop.Domain.Queries.Roster.GetRoster.RosterItem;
type RosterChangesItem = Workshop.Domain.Queries.Roster.GetRosterChanges.RosterChangesItem;

export const RosterModel = types
  .model('RosterModel', {
    roster: types.maybe(types.frozen<RosterItem>()),
    rosterChanges: types.maybe(types.frozen<RosterChangesItem>()),
  })
  .actions(self => {
    const ajax = getAjax(self);
    const root = getRoot(self) as IRootStoreModel;

    const createRoster = flow(function*(command: CreateRosterCommand) {
      const id = yield ajax.workshop.roster.createRoster(command);
      self.roster = undefined;
      root.history.push(`/workshop/rosters/${id}`);
    });

    const loadRoster = flow(function*(rosterId: string) {
      self.roster = yield ajax.workshop.roster.getRoster(rosterId);
    });

    const loadRosterChanges = flow(function*(rosterId: string) {
      self.rosterChanges = yield ajax.workshop.roster.getRosterChanges(rosterId);
    });

    const updateRoster = flow(function*(command: UpdateRosterCommand) {
      yield ajax.workshop.roster.updateRoster(command);
      yield loadRoster(command.rosterId);
    });

    const deleteRoster = flow(function*(rosterId: string) {
      yield ajax.workshop.roster.deleteRoster(rosterId);
      self.roster = undefined;
      root.history.replace('/workshop/rosters');
    });

    return {
      createRoster,
      loadRoster,
      loadRosterChanges,
      updateRoster,
      deleteRoster,
    };
  });
