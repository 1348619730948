import { IAjax } from 'src/infrastructure/ajax';
import { partsUrls, partTransactionsUrls } from 'src/domain/services/apiUrls';

type AggregateUsageDto = Common.Dtos.AggregateUsageDto;
type SetPartsGroupInactiveCommand = Workshop.Domain.Commands.Part.SetPartsGroupInactive.SetPartsGroupInactiveCommand;
type SetPartsGroupActiveCommand = Workshop.Domain.Commands.Part.SetPartsGroupActive.SetPartsGroupActiveCommand;
type CreatePartsGroupCommand = Workshop.Domain.Commands.Part.CreatePartsGroup.CreatePartsGroupCommand;
type UpdatePartsGroupCommand = Workshop.Domain.Commands.Part.UpdatePartsGroup.UpdatePartsGroupCommand;
type CreatePartCommand = Workshop.Domain.Commands.Part.CreatePart.CreatePartCommand;
type UpdatePartCommand = Workshop.Domain.Commands.Part.UpdatePart.UpdatePartCommand;
type UpdatePartSetActiveCommand = Workshop.Domain.Commands.Part.SetPartActive.SetPartActiveCommand;
type UpdatePartSetInactiveCommand = Workshop.Domain.Commands.Part.SetPartInactive.SetPartInactiveCommand;
type CreateStockAdjustmentCommand = Workshop.Domain.Commands.Part.CreateStockAdjustment.CreateStockAdjustmentCommand;
type RecordStocktakeCommand = Workshop.Domain.Commands.Part.RecordStocktake.RecordStocktakeCommand;
type ListStocktakePartsQuery = Workshop.Domain.Queries.Parts.GetStocktakePartsList.ListStocktakePartsQuery;
type ListPartsQuery = Workshop.Domain.Queries.Parts.ListParts.ListPartsQuery;
type PartItem = Workshop.Domain.Queries.Parts.PartItem;
type PartUsage = Workshop.Domain.Queries.Parts.GetPartUsage.PartUsage;
type PartListItem = Workshop.Domain.Queries.Parts.PartListItem;

type PartTransactionItem = Workshop.Domain.Queries.GetPartTransactions.PartTransactionItem;
type GetPartTransactionsListQuery = Workshop.Domain.Queries.GetPartTransactions.GetPartTransactionsQuery;
type GetUsedPartsPdfReportQuery = Workshop.Domain.Queries.GetUsedPartsPdfReport.GetUsedPartsPdfReportQuery;
type EngineItem = Workshop.Domain.Queries.GetPartsGroupEngines.EngineItem;

type ListPartsForDropdownQuery = Workshop.Domain.Queries.Parts.ListPartsForDropdown.ListPartsForDropdownQuery;
type PartListItemForDropdown = Workshop.Domain.Queries.Parts.ListPartsForDropdown.PartListItemForDropdown;

type ExportPartsGroupToPdfQuery = Workshop.Domain.Queries.Parts.GeneratePartsGroupPdf.ExportPartsGroupToPdfQuery;

type GenerateStockOnHandExcelQuery = Workshop.Domain.Queries.Parts.GenerateStockOnHandExcel.GenerateStockOnHandExcelQuery;

// tslint:disable-next-line:no-empty
const returnVoid = () => {};

export default function init(ajax: IAjax) {
  function listParts(query: Partial<ListPartsQuery>) {
    return ajax
      .get(partsUrls.listParts(query))
      .map(r => r.response as Common.Dtos.ListResult<PartListItem>);
  }

  function listPartsForDropdown(query: Partial<ListPartsForDropdownQuery>) {
    return ajax
      .get(partsUrls.listPartsForDropdown(query))
      .map(r => r.response as Common.Dtos.ListResult<PartListItemForDropdown>);
  }

  function findParts(partIds: string[]) {
    return ajax
      .get(partsUrls.findParts({ partIds }))
      .toPromise()
      .then<PartItem[]>(r => r.response);
  }

  function createPart(command: CreatePartCommand) {
    return ajax
      .post(partsUrls.createPart(), command)
      .toPromise()
      .then<string>(r => r.response);
  }

  function updatePart(command: UpdatePartCommand) {
    return ajax.put(partsUrls.updatePart(command.id), command).toPromise();
  }

  function updatePartSetActive(command: UpdatePartSetActiveCommand) {
    return ajax
      .put(partsUrls.updatePartSetActive(command.id), command)
      .toPromise()
      .then(r => returnVoid());
  }

  function updatePartSetInactive(command: UpdatePartSetInactiveCommand) {
    return ajax
      .put(partsUrls.updatePartSetInactive(command.id), command)
      .toPromise()
      .then(r => returnVoid());
  }

  function getPart(partId: string) {
    return ajax
      .get(partsUrls.getPart(partId))
      .toPromise()
      .then<PartItem>(r => r.response);
  }

  function getPartUsage(partId: string) {
    return ajax
      .get(partsUrls.getPartUsage(partId))
      .toPromise()
      .then<PartUsage>(r => r.response);
  }

  function checkForUniquePartNumber(partNumber: string) {
    return ajax
      .get(partsUrls.checkForUniquePartNumber(partNumber))
      .toPromise()
      .then<Common.Dtos.UniqueNameCheckResultDto>(r => r.response);
  }

  function searchPartsGroups(search: string) {
    return ajax
      .get(partsUrls.searchPartsGroups(search))
      .toPromise()
      .then<Workshop.Domain.Queries.SearchPartGroups.MatchingPartsGroup[]>(r => r.response);
  }

  function partsGroupNameExists(name: string) {
    return ajax
      .get(partsUrls.partsGroupNameExists(name))
      .toPromise()
      .then<Common.Dtos.UniqueNameCheckResultDto>(r => r.response)
      .then(r => r.nameExists);
  }

  function createPartsGroup(command: CreatePartsGroupCommand) {
    return ajax
      .post(partsUrls.createPartsGroup(), command)
      .toPromise()
      .then<string>(r => r.response);
  }

  function listPartsGroups(includeInactivePartsGroups: boolean) {
    return ajax
      .get(partsUrls.listPartsGroups(includeInactivePartsGroups))
      .toPromise()
      .then<Workshop.Domain.Queries.GetPartsGroups.PartsGroupItem[]>(r => r.response);
  }

  function getPartsGroup(id: string) {
    return ajax
      .get(partsUrls.getPartsGroup(id))
      .toPromise()
      .then<Workshop.Domain.Queries.GetPartsGroup.PartsGroupItem>(r => r.response);
  }

  function getPartsGroupEngines(id: string) {
    return ajax
      .get(partsUrls.getPartsGroupEngines(id))
      .toPromise()
      .then<EngineItem>(r => r.response);
  }

  function updatePartsGroup(command: UpdatePartsGroupCommand) {
    return ajax
      .put(partsUrls.updatePartsGroup(command.id), command)
      .toPromise()
      .then<Workshop.Domain.Queries.GetPartsGroup.PartsGroupItem>(r => r.response);
  }

  function getPartsGroupParts(partsGroupId: string) {
    return ajax
      .get(partsUrls.getPartsGroupParts(partsGroupId))
      .toPromise()
      .then<Workshop.Domain.Queries.GetPartsGroupParts.PartItem[]>(r => r.response);
  }

  function createStockAdjustment(cmd: CreateStockAdjustmentCommand) {
    return ajax
      .post(partsUrls.createStockAdjustment(cmd.partId), cmd)
      .toPromise()
      .then(r => returnVoid());
  }

  function recordStocktake(cmd: RecordStocktakeCommand) {
    return ajax
      .post(partsUrls.recordStocktake(), cmd)
      .toPromise()
      .then(r => returnVoid());
  }

  function generateStocktakePdf(query: Partial<ListStocktakePartsQuery>) {
    return ajax
      .getFile(partsUrls.generateStocktakePdf(query))
      .toPromise()
      .then<Blob>(r => r.response);
  }

  function generateStocktakeExcel(query: Partial<ListStocktakePartsQuery>) {
    return ajax
      .getFile(partsUrls.generateStocktakeExcel(query))
      .toPromise()
      .then<Blob>(r => r.response);
  }

  function generateStockOnHandExcel(query: Partial<GenerateStockOnHandExcelQuery>) {
    return ajax
      .getFile(partsUrls.generateStockOnHandExcel(query))
      .toPromise()
      .then<Blob>(r => r.response);
  }

  function listStocktakeParts(query: Partial<ListStocktakePartsQuery>) {
    return ajax
      .get(partsUrls.listStocktakeParts(query))
      .map(
        r =>
          r.response as Common.Dtos.ListResult<Workshop.Domain.Queries.Parts.StocktakePartListItem>
      );
  }

  function exportPartsGroupToPdf(query: Partial<ExportPartsGroupToPdfQuery>) {
    return ajax
      .getFile(partsUrls.exportPartsGroupToPdf(query))
      .toPromise()
      .then<Blob>(r => r.response);
  }

  function listPartTransactions(query: Partial<GetPartTransactionsListQuery>) {
    return ajax
      .get(partTransactionsUrls.listPartTransactions(query))
      .map(r => r.response as Common.Dtos.ListResult<PartTransactionItem>);
  }

  function downloadUsedPartsExcel(query: Partial<GetPartTransactionsListQuery>) {
    return ajax
      .getFile(partTransactionsUrls.downloadUsedPartsExcel(query))
      .toPromise()
      .then<Blob>(r => r.response);
  }

  function downloadUsedPartsPdf(query: Partial<GetUsedPartsPdfReportQuery>) {
    return ajax
      .getFile(partTransactionsUrls.downloadUsedPartsPdf(query))
      .toPromise()
      .then<Blob>(r => r.response);
  }

  function updatePartsGroupSetInactive(command: SetPartsGroupInactiveCommand) {
    return ajax
      .put(partsUrls.updatePartsGroupSetInactive(command.id), command)
      .toPromise()
      .then(r => r.response);
  }

  function updatePartsGroupSetActive(command: SetPartsGroupActiveCommand) {
    return ajax
      .put(partsUrls.updatePartsGroupSetActive(command.id), command)
      .toPromise()
      .then(r => r.response);
  }

  function deletePartsGroup(id: string) {
    return ajax
      .httpDelete(partsUrls.deletePartsGroup(id))
      .toPromise()
      .then(r => r.response);
  }

  function getPartsGroupUsage(id: string) {
    return ajax
      .get(partsUrls.getPartsGroupUsage(id))
      .toPromise()
      .then<AggregateUsageDto>(r => r.response);
  }

  return {
    part: {
      listParts,
      listPartsForDropdown,
      findParts,
      createPart,
      updatePart,
      updatePartSetActive,
      updatePartSetInactive,
      getPart,
      getPartUsage,
      checkForUniquePartNumber,
      searchPartsGroups,
      partsGroupNameExists,
      createPartsGroup,
      listPartsGroups,
      getPartsGroup,
      updatePartsGroup,
      getPartsGroupParts,
      createStockAdjustment,
      listStocktakeParts,
      recordStocktake,
      generateStocktakePdf,
      generateStocktakeExcel,
      generateStockOnHandExcel,
      exportPartsGroupToPdf,
      getPartsGroupEngines,
      updatePartsGroupSetInactive,
      updatePartsGroupSetActive,
      deletePartsGroup,
      getPartsGroupUsage,
    },
    partTransactions: {
      listPartTransactions,
      downloadUsedPartsExcel,
      downloadUsedPartsPdf,
    },
  };
}
