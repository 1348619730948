import { useState } from 'react';
import './ExtraDetails.scss';
import ExtrasPopover from 'src/views/components/operations/ExtraDetails/ExtrasPopover';

export interface IExtraItem {
  extraType: Operations.Domain.Queries.ViewExtraType.ExtraTypeItem;
  quantity: number;
}

export interface IExtraDetailsProps {
  id: string;
  extras: IExtraItem[];
}

const ExtraDetails: React.FC<IExtraDetailsProps> = (props: IExtraDetailsProps) => {
  const [selectedExtras, setSelectedExtras] = useState<IExtraItem[] | undefined>(undefined);
  const [selectedId, setSelectedId] = useState<string | undefined>(undefined);

  const handleMouseEnter = (id: string, jobExtras: IExtraItem[]) => {
    setSelectedExtras(jobExtras);
    setSelectedId(id);
  };

  const handleMountLeave = () => {
    setSelectedExtras(undefined);
    setSelectedId(undefined);
  };

  if (!props.extras || !props.extras.length) {
    return null;
  }

  const extraId = `extra_${props.id}`;

  return (
    <>
      {selectedId && selectedExtras ? (
        <ExtrasPopover target={selectedId} extras={selectedExtras} />
      ) : null}
      <span
        className="extras-detail"
        id={extraId}
        onMouseEnter={() => handleMouseEnter(extraId, props.extras)}
        onMouseLeave={() => handleMountLeave()}>
        Yes
      </span>
    </>
  );
};

export default ExtraDetails;
