import { COMPANY_ID } from 'src/appSettings';
import styles from './CompanyLogo.module.scss';

interface ICompanyLogoProps {
  mode: 'full' | 'kiosk';
  className?: string;
}

const CompanyLogo: React.FC<ICompanyLogoProps> = ({ mode, className }: ICompanyLogoProps) => {
  let logoName = '';
  switch (mode) {
    case 'kiosk':
      logoName = 'logo-kiosk.svg';
      break;
    case 'full':
    default:
      logoName = 'logo-full.png';
      break;
  }

  return (
    <img
      src={`/images/${COMPANY_ID.toLowerCase()}/${logoName}`}
      className={className ? className : styles.logoImg}
      data-company-id={COMPANY_ID.toLowerCase()}
      alt="company logo"
    />
  );
};

export default CompanyLogo;
