import './YesNoToggleButton.scss';
import { ButtonGroup } from 'reactstrap';
import { SingleClickAwareButton } from '../SingleClickAwareButton/SingleClickAwareButton';

type ButtonSize = 'lg' | 'sm';

interface IYesNoToggleButtonProps {
  value?: boolean;
  onChange: (value: boolean) => void;
  disabled?: boolean;
  buttonSize?: ButtonSize;
}

const YesNoToggleButton: React.FC<IYesNoToggleButtonProps> = ({
  value,
  onChange,
  disabled,
  buttonSize,
}) => {
  return (
    <ButtonGroup className="yes-no-toggle-button-component">
      <SingleClickAwareButton
        useReactStrapButton
        size={buttonSize || 'sm'}
        active={value === true}
        disabled={disabled}
        onClick={() => onChange(true)}>
        Yes
      </SingleClickAwareButton>
      <SingleClickAwareButton
        useReactStrapButton
        size={buttonSize || 'sm'}
        active={value === false}
        disabled={disabled}
        onClick={() => onChange(false)}>
        No
      </SingleClickAwareButton>
    </ButtonGroup>
  );
};

export default YesNoToggleButton;
