// tslint:disable:no-empty
import { IAjax } from 'src/infrastructure/ajax';
import { workshopUrls } from 'src/domain/services/apiUrls';

type TechSpecItem = Workshop.Domain.Queries.TechSpecs.TechSpecItem;
type UpdateTechSpecCommand = Workshop.Domain.Commands.TechSpecs.UpdateTechSpecsCommand;
type TechSpecDropdownOptionsItem = Workshop.Domain.Queries.TechSpecs.TechSpecDropdownOptionsItem;
type UpdateTechSpecDropdownOptionsCommand = Workshop.Domain.Commands.TechSpecs.UpdateTechSpecDropdownOptionsCommand;
type GetTechSpecDropdownOptionsQuery = Workshop.Domain.Queries.TechSpecs.GetTechSpecDropdownOptions.GetTechSpecDropdownOptionsQuery;
type GetEditableTechSpecDropdownOptionsQueryResponse = Workshop.Domain.Queries.TechSpecs.GetEditableTechSpecDropdownOptions.GetEditableTechSpecDropdownOptionsQueryResponse;

export default function init(ajax: IAjax) {
  function getAll() {
    return ajax
      .get(workshopUrls.techSpecUrls.getAll())
      .toPromise()
      .then<TechSpecItem[]>(r => r.response);
  }

  function updateAll(cmd: UpdateTechSpecCommand) {
    return ajax
      .put(workshopUrls.techSpecUrls.updateAll(), cmd)
      .toPromise()
      .then(_ => {});
  }

  function getDropdownOptions(query: Partial<GetTechSpecDropdownOptionsQuery>) {
    return ajax
      .get(workshopUrls.techSpecUrls.getTechSpecsDropdownOptions(query))
      .toPromise()
      .then<TechSpecDropdownOptionsItem[]>(r => r.response);
  }

  function getEditableDropdownOptions(techSpecId: number) {
    return ajax
      .get(workshopUrls.techSpecUrls.getEditableTechSpecDropdownOptions(techSpecId))
      .toPromise()
      .then<GetEditableTechSpecDropdownOptionsQueryResponse>(r => r.response);
  }

  function updateDropdownOptions(techSpecId: number, cmd: UpdateTechSpecDropdownOptionsCommand) {
    return ajax
      .put(workshopUrls.techSpecUrls.getEditableTechSpecDropdownOptions(techSpecId), cmd)
      .toPromise()
      .then<void>(_ => {});
  }

  function checkForUniqueTechSpecName(techSpecName: string) {
    return ajax
      .get(workshopUrls.techSpecUrls.checkForUniqueTechSpecName(techSpecName))
      .toPromise()
      .then<Common.Dtos.UniqueNameCheckResultDto>(r => r.response);
  }

  return {
    techSpecs: {
      getAll,
      updateAll,
      getDropdownOptions,
      getEditableDropdownOptions,
      updateDropdownOptions,
      checkForUniqueTechSpecName,
    },
  };
}
