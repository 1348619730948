import { observer } from 'mobx-react';
import styles from './FatigueViolationModal.module.scss';
import { useEffect } from 'react';
import { useRootStore } from 'src/domain/entities/RootStoreModel';
import ShellModal, {
  IShellModalTriggerApi,
  ShellModalSize,
} from 'src/views/components/Shell/ShellModal';
import { WarningType } from 'src/api/enums';
import { DateTime } from 'luxon';

const FatigueWarningModal: React.FC = observer(() => {
  const rootStore = useRootStore();
  const model = (rootStore.compliance && rootStore.compliance?.fatigueValidations) || {};

  const getDate = (date: string, interval: number) =>
    DateTime.fromISO(date)
      .plus({ days: interval })
      .toFormat('yyyy-MM-dd');

  const driverManagedWarnings =
    model.warningMessages?.filter(warning => warning.type === WarningType.DriverManagedFatigue) ??
    [];

  var api: IShellModalTriggerApi;

  useEffect(() => {
    if (model.hasWarningMessages) api.onShow();
  }, [model.hasWarningMessages]);

  return (
    <ShellModal
      size={ShellModalSize.oneThird}
      renderShowTrigger={renderApi => {
        api = renderApi;
        return <div />;
      }}
      showCloseButton={true}
      onCloseModal={() => model.clearWarningMessages()}>
      {() => (
        <div className={styles.fatigueViolationModal}>
          <h3>Driver Managed Fatigue Warning</h3>
          <div className={styles.messageCard}>
            <p>Please add scheduled breaks to Driver Managed Fatigue jobs.</p>
            <p>
              There are jobs within the time range being checked, that don't have sufficient
              scheduled breaks added to clear their Driver Managed Fatigue status.
            </p>
            <p>
              Without sufficient breaks added, the system doesn't have enough information to ensure
              compliance.
            </p>
            {driverManagedWarnings?.map(
              warning =>
                warning.staffMemberId &&
                warning.jobBounds && (
                  <>
                    <p>
                      Driver {warning.isSecondStaffMember ? `2` : `1`}:&nbsp;
                      {warning.data?.staffMemberName}
                    </p>
                    <p>
                      <a
                        href={`/operations/jobs?IsDriverManaged=true&dateFrom=${getDate(
                          warning.jobBounds.start,
                          -28
                        )}&dateTo=${getDate(warning.jobBounds.end, 28)}&staffMembers=${
                          warning?.staffMemberId
                        }`}
                        target="_blank"
                        rel="noreferrer">
                        View Driver Managed Fatigue Jobs
                      </a>
                    </p>
                    <p>
                      <a
                        href={`/compliance/fatigueDetails?day=${getDate(
                          warning.jobBounds.end,
                          1
                        )}&staffMember=${warning.staffMemberId}`}
                        target="_blank"
                        rel="noreferrer">
                        View Current Fatigue Details
                      </a>
                    </p>
                  </>
                )
            )}
          </div>
        </div>
      )}
    </ShellModal>
  );
});

export default FatigueWarningModal;
