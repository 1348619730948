import './CostCentre.scss';
import { DurationFormat } from 'src/views/components/DurationFormat';
import {
  CostGroupDayType,
  costGroupDayTypeDescription,
  CostGroupEntryTotalType,
  CostGroupEntryType,
  OvertimeTimeType,
  overtimeTimeTypeDescription,
} from 'src/api/enums';

type TimesheetItem = Common.Dtos.TimesheetItem;
type PrefilledTimesheetItem = Common.Dtos.PrefilledTimesheetItem;

export interface ICostCentreProps {
  timesheet: Partial<TimesheetItem> | Partial<PrefilledTimesheetItem>;
}

const CostCentre: React.FC<ICostCentreProps> = ({ timesheet }) => {
  const weekdayCostGroups = timesheet.weekdayCostGroups;
  const weekdayTotals = timesheet.weekdayTotals;

  const weekendCostGroups = timesheet.weekendCostGroups;
  const weekendTotals = timesheet.weekendTotals;
  const allowancesSummary = timesheet.timesheetAllowanceSummary;
  return (
    <div className="cost-centre-component">
      <div>
        <h4>Weekdays</h4>
        {weekdayCostGroups && weekdayCostGroups.length > 0 ? (
          <table>
            <thead>
              <tr>
                <th>Description</th>
                <th>Pay Item</th>
                <th>Paid Hours</th>
              </tr>
            </thead>
            <tbody>
              {weekdayCostGroups?.map(x => (
                <tr
                  key={`${x.typeDescription}:${x.isOvertime}:${x.typeId}:${x.dayType}:${x.overtimeTimeType}`}>
                  <td>
                    {x.typeDescription ===
                      costGroupDayTypeDescription(CostGroupDayType.PublicHoliday) ||
                    x.entryType === CostGroupEntryType.Leave
                      ? ''
                      : x.typeDescription}
                  </td>
                  <td>
                    {x.dayType && x.entryType !== CostGroupEntryType.Leave
                      ? `${costGroupDayTypeDescription(x.dayType)}${
                          x.isOvertime && x.overtimeTimeType
                            ? ` - ${overtimeTimeTypeDescription(x.overtimeTimeType)}`
                            : ''
                        }${x.isWaiting ? ' - Waiting' : ''}${
                          x.overtimeTimeType === OvertimeTimeType.EarlyOrLate
                            ? ' - Early Or Late'
                            : ''
                        }`
                      : null}
                    {x.typeDescription ===
                      costGroupDayTypeDescription(CostGroupDayType.PublicHoliday) ||
                    x.entryType === CostGroupEntryType.Leave
                      ? x.typeDescription
                      : null}
                  </td>
                  <td>
                    {x.totalType === CostGroupEntryTotalType.Duration && (
                      <DurationFormat value={x.duration} showHoursAndDecimals />
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
            <tfoot>
              <tr>
                <th />
                <th />
                <th>
                  <DurationFormat value={weekdayTotals?.totalPaidHrs} showHoursAndDecimals />
                </th>
              </tr>
            </tfoot>
          </table>
        ) : (
          'No items to display'
        )}
        {weekdayTotals && weekdayTotals.totalOnCall > 0 && (
          <div>
            <strong>Days on Call: </strong>
            <span>{weekdayTotals.totalOnCall}</span>
          </div>
        )}
      </div>
      <div>
        <h4>Weekend</h4>
        {weekendCostGroups && weekendCostGroups.length > 0 ? (
          <table>
            <thead>
              <tr>
                <th>Description</th>
                <th>Pay Item</th>
                <th>Paid Hours</th>
              </tr>
            </thead>
            <tbody>
              {weekendCostGroups.map(x => (
                <tr key={`${x.typeDescription}:${x.isOvertime}:${x.typeId}:${x.dayType}`}>
                  <td>{x.typeDescription}</td>
                  <td>
                    {x.dayType && x.entryType !== CostGroupEntryType.Leave
                      ? `${costGroupDayTypeDescription(x.dayType)}${
                          x.isOvertime && x.overtimeTimeType
                            ? ` - ${overtimeTimeTypeDescription(x.overtimeTimeType)}`
                            : ''
                        }${x.isWaiting ? ' - Waiting' : ''}`
                      : null}
                    {x.typeDescription ===
                      costGroupDayTypeDescription(CostGroupDayType.PublicHoliday) ||
                    x.entryType === CostGroupEntryType.Leave
                      ? x.typeDescription
                      : null}
                  </td>
                  <td>
                    {x.totalType === CostGroupEntryTotalType.Duration && (
                      <DurationFormat value={x.duration} showHoursAndDecimals />
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
            <tfoot>
              <tr>
                <th />
                <th />
                <th>
                  <DurationFormat value={weekendTotals?.totalPaidHrs} showHoursAndDecimals />
                </th>
              </tr>
            </tfoot>
          </table>
        ) : (
          'No items to display'
        )}
        {weekendTotals && weekendTotals.totalOnCall > 0 && (
          <div>
            <strong>Days on Call: </strong>
            <span>{weekendTotals.totalOnCall}</span>
          </div>
        )}
      </div>
      <div>
        <h4>Allowances</h4>
        {allowancesSummary && allowancesSummary.length > 0 ? (
          <table>
            <thead>
              <tr>
                <th>Description</th>
                <th>Quantity</th>
              </tr>
            </thead>
            <tbody>
              {allowancesSummary?.map(x => (
                <tr key={x.allowanceName}>
                  <td>{x.allowanceName}</td>
                  <td>{x.quantity}</td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          'No items to display'
        )}
      </div>
    </div>
  );
};

export default CostCentre;
