import { types, flow, getRoot } from 'mobx-state-tree';
import { getAjax, NotificationType, getBus } from 'src/domain/services';
import { buildListPageApiSearchModelTypedQuery } from 'src/domain/modelBuilders/buildListPageApiSearchModel';
import { IRootStoreModel } from 'src/domain/entities/RootStoreModel';
import * as queryString from 'query-string';
import { DriverLicenceLicenceType } from 'src/views/components/LicenceTypesHelper';

type StaffMemberDto = Common.Dtos.StaffMemberDto;
type StaffMemberName = Common.Dtos.StaffMemberNameDto;
type SendLicenceExpiryWarningEmailResult = People.Domain.Commands.StaffMembers.SendLicenceExpiryWarningEmail.SendLicenceExpiryWarningEmailResult;
type StaffMemberLicenceItem = Common.Dtos.StaffMemberDto.StaffMemberLicenceItem;
export interface StaffMemberLicence {
  id: string;
  licenceClass: StaffMemberLicenceItem;
}

const StaffMembersListModel = buildListPageApiSearchModelTypedQuery<
  Operations.Domain.Queries.ListJobs.ListJobsQuery
>()('StaffMembersListModel', d => d.ajax.people.staffMember.loadStaffMembers(d.query));

const ListStaffMembersModel = types
  .model('StaffMembersModel', {
    drivers: types.array(types.frozen<StaffMemberDto>()),
    mechanics: types.array(types.frozen<StaffMemberDto>()),
    reportableEventContacts: types.array(types.frozen<StaffMemberName>()),
    allStaffMembers: types.array(types.frozen<StaffMemberDto>()),
    allStaffMemberNames: types.array(types.frozen<StaffMemberName>()),
  })
  .views(self => ({
    get activeStaffMembers() {
      return self.allStaffMembers.filter(x => x.active);
    },
    get workshopStaffMembers() {
      return this.activeStaffMembers.filter(x => x.isWorkshopStaff);
    },
    get allStaffWithDriverAuthorisation() {
      return this.activeStaffMembers.filter(x => x.hasDriversAuthorisation);
    },

    get staffMemberLicences() {
      return this.activeStaffMembers.map(x => ({
        id: x.id,
        licenceClass: x.licences.filter(l => l.licenceTypeId === DriverLicenceLicenceType)[0],
      })) as StaffMemberLicence[];
    },
  }))
  .actions(self => {
    const ajax = getAjax(self);
    const root = getRoot(self) as IRootStoreModel;
    const bus = getBus(self);

    const loadDrivers = flow(function*() {
      self.drivers = yield ajax.people.staffMember.loadDrivers();
    });

    const loadMechanics = flow(function*() {
      self.mechanics = yield ajax.people.staffMember.loadMechanics();
    });

    const loadReportableEventContacts = flow(function*() {
      const contacts = yield ajax.people.staffMember.loadReportableEventContacts();
      self.reportableEventContacts = contacts;
    });

    const loadAllStaffMembers = flow(function*() {
      self.allStaffMembers = yield ajax.people.staffMember.loadAllStaffMembers();
    });

    const loadAllStaffMemberNames = flow(function*(
      query: Partial<Common.Queries.GetStaffMemberNames.GetStaffMemberNamesQuery>
    ) {
      self.allStaffMemberNames = yield ajax.people.staffMember.loadAllStaffMemberNames(query);
    });

    const generateLicenceExpiryWarning = flow(function*(
      query: Partial<People.Domain.Queries.ListStaffMembers.ListStaffMembersQuery>
    ) {
      root.notifications.addNotification(`The file is being generated ...`, {
        type: NotificationType.info,
      });

      return yield ajax.raw
        .getFile(`/api/people/generate-licence-expiry-warning/pdf?${queryString.stringify(query)}`)
        .toPromise()
        .then<Blob>(r => r.response);
    });

    const exportFileNotesToExcel = flow(function*(
      query: Partial<People.Domain.Queries.ListStaffMembers.ListStaffMembersQuery>
    ) {
      root.notifications.addNotification(`The file is being generated ...`, {
        type: NotificationType.info,
      });

      return yield ajax.raw
        .getFile(`/api/people/staff-members/file-notes/excel?${queryString.stringify(query)}`)
        .toPromise()
        .then<Blob>(r => r.response);
    });

    const exportPredepartureChecksToExcel = flow(function*(
      query: Partial<Operations.Domain.Queries.ListPredepartureChecks.ListPredepartureChecksQuery>
    ) {
      root.notifications.addNotification(`The file is being generated ...`, {
        type: NotificationType.info,
      });

      return yield ajax.raw
        .getFile(`/api/operations/predeparturechecks/excel?${queryString.stringify(query)}`)
        .toPromise()
        .then<Blob>(r => r.response);
    });

    const exportToExcel = flow(function*(
      query: Partial<People.Domain.Queries.ListStaffMembers.ListStaffMembersQuery>
    ) {
      root.notifications.addNotification(`The file is being generated ...`, {
        type: NotificationType.info,
      });

      return yield ajax.raw
        .getFile(`/api/people/staff-members/excel?${queryString.stringify(query)}`)
        .toPromise()
        .then<Blob>(r => r.response);
    });

    const exportDAsToCSV = flow(function*() {
      root.notifications.addNotification(`The file is being generated ...`, {
        type: NotificationType.info,
      });

      return yield ajax.raw
        .getFile(`/api/people/current-drivers/csv`)
        .toPromise()
        .then<Blob>(r => r.response);
    });

    const exportLicencesToExcel = flow(function*(
      query: Partial<People.Domain.Queries.ListStaffMembers.ListStaffMembersQuery>
    ) {
      root.notifications.addNotification(`The file is being generated ...`, {
        type: NotificationType.info,
      });

      return yield ajax.raw
        .getFile(`/api/people/staff-members/licences/excel?${queryString.stringify(query)}`)
        .toPromise()
        .then<Blob>(r => r.response);
    });

    const exportClockOnsToExcel = flow(function*(
      query: Partial<People.Domain.Queries.ExportStaffClockOnsToExcel.ListStaffMembersClockOnsQuery>
    ) {
      root.notifications.addNotification(`The file is being generated ...`, {
        type: NotificationType.info,
      });

      return yield ajax.raw
        .getFile(`/api/people/staff-members/clock-ons/excel?${queryString.stringify(query)}`)
        .toPromise()
        .then<Blob>(r => r.response);
    });

    const exportEquipmentToExcel = flow(function*(
      query: Partial<People.Domain.Queries.ListStaffMembers.ListStaffMembersQuery>
    ) {
      root.notifications.addNotification(`The file is being generated ...`, {
        type: NotificationType.info,
      });

      return yield ajax.raw
        .getFile(`/api/people/staff-members/equipment-to-excel?${queryString.stringify(query)}`)
        .toPromise()
        .then<Blob>(r => r.response);
    });

    const exportEquipmentCountsToExcel = flow(function*(
      query: Partial<
        People.Domain.Queries.ExportEquipmentCountsToExcel.ExportEquipmentCountsToExcelQuery
      >
    ) {
      root.notifications.addNotification(`The file is being generated ...`, {
        type: NotificationType.info,
      });

      return yield ajax.raw
        .getFile(
          `/api/people/staff-members/equipment-counts-to-excel?${queryString.stringify(query)}`
        )
        .toPromise()
        .then<Blob>(r => r.response);
    });

    const sendLicenceExpiryWarningEmails = flow(function*(
      query: Partial<People.Domain.Queries.ListStaffMembers.ListStaffMembersQuery>
    ) {
      root.notifications.addNotification(`The emails are being sent out ...`, {
        type: NotificationType.info,
      });

      const emailResult: SendLicenceExpiryWarningEmailResult = yield ajax.raw
        .post(`/api/people/send-licence-expiry-warning/email?${queryString.stringify(query)}`)
        .toPromise()
        .then<SendLicenceExpiryWarningEmailResult>(r => r.response);

      root.notifications.addNotification(
        `${emailResult.successCount}/${emailResult.totalCount} emails sent out successfully`,
        {
          type: NotificationType.success,
        }
      );
      bus.showWarnings(emailResult.warnings);
    });

    return {
      loadDrivers,
      loadMechanics,
      loadReportableEventContacts,
      loadAllStaffMembers,
      loadAllStaffMemberNames,
      generateLicenceExpiryWarning,
      exportToExcel,
      exportDAsToCSV,
      exportLicencesToExcel,
      exportEquipmentToExcel,
      exportEquipmentCountsToExcel,
      exportFileNotesToExcel,
      exportClockOnsToExcel,
      exportPredepartureChecksToExcel,
      sendLicenceExpiryWarningEmails,
    };
  });
export const StaffMembersModel = types.compose(ListStaffMembersModel, StaffMembersListModel);
