import { IAjax } from 'src/infrastructure/ajax';
import { peopleUrls } from 'src/domain/services/apiUrls';

type TimesheetAllowanceItem = People.Domain.Queries.TimesheetAllowance.TimesheetAllowanceItem;
type UpdateTimesheetAllowanceCommand = People.Domain.Commands.TimesheetAllowance.UpdateTimesheetAllowance.UpdateTimesheetAllowanceCommand;

export default function init(ajax: IAjax) {
  function createTimesheetAllowance(
    command: People.Domain.Commands.TimesheetAllowance.CreateTimesheetAllowance.CreateTimesheetAllowanceCommand
  ) {
    return ajax
      .post(peopleUrls.timesheetAllowanceUrls.createTimesheetAllowance(), command)
      .toPromise()
      .then<string>(r => r.response);
  }

  function checkForUniqueName(name: string) {
    return ajax
      .get(peopleUrls.timesheetAllowanceUrls.checkForUniqueName(name))
      .toPromise()
      .then<Common.Dtos.UniqueNameCheckResultDto>(r => r.response);
  }

  function loadTimesheetAllowances(
    query: Partial<Common.Queries.People.TimesheetAllowances.ListTimesheetAllowancesQuery>
  ) {
    return ajax
      .get(peopleUrls.timesheetAllowanceUrls.loadTimesheetAllowances(query))
      .map(
        r =>
          r.response as Common.Dtos.ListResult<
            Common.Queries.People.TimesheetAllowances.ListTimesheetAllowanceItem
          >
      );
  }

  function loadTimesheetAllowance(timesheetAllowanceId: string) {
    return ajax
      .get(peopleUrls.timesheetAllowanceUrls.loadTimesheetAllowance(timesheetAllowanceId))
      .toPromise()
      .then<TimesheetAllowanceItem>(r => r.response);
  }

  function updateTimesheetAllowance(command: UpdateTimesheetAllowanceCommand) {
    return ajax
      .put(peopleUrls.timesheetAllowanceUrls.updateTimesheetAllowance(command.id), command)
      .toPromise();
  }

  function deleteTimesheetAllowance(timesheetAllowanceId: string) {
    return ajax
      .httpDelete(peopleUrls.timesheetAllowanceUrls.deleteTimesheetAllowance(timesheetAllowanceId))
      .toPromise();
  }

  function reactivateTimesheetAllowance(timesheetAllowanceId: string) {
    return ajax
      .put(peopleUrls.timesheetAllowanceUrls.reactivateTimesheetAllowance(timesheetAllowanceId))
      .toPromise();
  }

  return {
    timesheetAllowance: {
      createTimesheetAllowance,
      checkForUniqueName,
      loadTimesheetAllowances,
      loadTimesheetAllowance,
      updateTimesheetAllowance,
      deleteTimesheetAllowance,
      reactivateTimesheetAllowance,
    },
  };
}
