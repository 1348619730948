import PopupField from '../../PopupField';
import UnacceptableConflictFieldItem from '../../UnacceptableConflictFieldItem';
import { ConflictFieldItem } from './ConflictFieldItem';

type JobConflicts = Operations.Domain.Queries.ListJobsForAllocations.JobItem.JobConflicts;

interface IConflictsPopupFieldProps {
  conflicts: JobConflicts;
}

export const ConflictsPopupField: React.FC<IConflictsPopupFieldProps> = (
  props: IConflictsPopupFieldProps
) => {
  const { conflicts } = props;

  const content = conflicts.conflictsCount && (
    <ul className="list-unstyled">
      {conflicts.opsConflicts.map(c => (
        <ConflictFieldItem key={c.conflictId} conflict={c} />
      ))}
      {conflicts.workshopJobConflicts.map(c => (
        <UnacceptableConflictFieldItem key={c.conflictId} conflict={c} />
      ))}
      {conflicts.vehicleMarkedOutOfServiceConflicts.map(c => (
        <UnacceptableConflictFieldItem key={c.conflictId} conflict={c} />
      ))}
      {conflicts.peopleLeaveConflicts.map(c => (
        <UnacceptableConflictFieldItem key={c.conflictId} conflict={c} />
      ))}
      {conflicts.licenceConflicts.map(c => (
        <UnacceptableConflictFieldItem key={c.conflictId} conflict={c} />
      ))}
    </ul>
  );

  return (
    <PopupField
      className="conflicts"
      label="Conflicts"
      value={content}
      hidden={!conflicts.conflictsCount}
    />
  );
};
