import { IAjax } from 'src/infrastructure/ajax';
import { jobTaskUrls, workshopUrls } from 'src/domain/services/apiUrls';

type OutOfServiceJobTaskListItem = Workshop.Domain.Queries.JobTask.ListOutOfServiceJobTasks.OutOfServiceJobTaskListItem;
type CloseJobTaskAndDefectWithoutFixingCommand = Workshop.Domain.Commands.JobTask.CloseJobTaskAndDefectWithoutFixingCommand;
type ServiceCycleItem = Workshop.Domain.Services.ServiceCycleItem;
type CompleteServiceJobTaskAndResetScheduleCommand = Workshop.Domain.Commands.JobTask.CompleteServiceJobTaskAndResetScheduleCommand;

// tslint:disable-next-line:no-empty
const returnVoid = () => {};

export default function init(ajax: IAjax) {
  function listTasks(
    query: Partial<Workshop.Domain.Queries.JobTask.ListJobTasks.ListJobTasksQuery>
  ) {
    return ajax
      .get(jobTaskUrls.listTasks(query))
      .map(
        r => r.response as Common.Dtos.ListResult<Workshop.Domain.Queries.JobTask.JobTaskListItem>
      );
  }

  function listJobTasksForAsset(id: string) {
    return ajax
      .get(jobTaskUrls.listJobTasksForAsset(id))
      .toPromise()
      .then<Workshop.Domain.Queries.JobTask.JobTaskListItem[]>(r => {
        return r.response;
      });
  }

  function listOutOfServiceJobTasks(
    query: Partial<
      Workshop.Domain.Queries.JobTask.ListOutOfServiceJobTasks.ListOutOfServiceJobTasksQuery
    >
  ) {
    return ajax
      .get(jobTaskUrls.listOutOfServiceJobTasks(query))
      .map(r => r.response as Common.Dtos.ListResult<OutOfServiceJobTaskListItem>);
  }

  function getJobTask(id: string) {
    return ajax
      .get(jobTaskUrls.getJobTask(id))
      .toPromise()
      .then<Workshop.Domain.Queries.GetJobTask.JobTaskDetails>(r => {
        return r.response;
      });
  }

  function getJobDetailsForTask(id: string) {
    return ajax
      .get(jobTaskUrls.getJobDetailsForTask(id))
      .toPromise()
      .then<Workshop.Domain.Queries.GetJobTask.JobDetails>(r => {
        return r.response;
      });
  }

  function getSchedulableJobsForTask(id: string) {
    return ajax
      .get(jobTaskUrls.getSchedulableJobsForTask(id))
      .toPromise()
      .then<Workshop.Domain.Queries.Job.GetSchedulableJobsForTask.SchedulableJob>(r => {
        return r.response;
      });
  }

  function getActiveJobsForTask(id: string) {
    return ajax
      .get(jobTaskUrls.getActiveJobsForTask(id))
      .toPromise()
      .then<Workshop.Domain.Queries.Job.GetSchedulableJobsForTask.SchedulableJob>(r => {
        return r.response;
      });
  }

  function loadJobTaskCategories() {
    return ajax
      .get(jobTaskUrls.loadJobTaskCategories())
      .toPromise()
      .then<Workshop.Domain.Queries.GetTaskCategories.JobTaskCategoryListItem[]>(r => {
        return r.response;
      });
  }

  function loadSuppliers() {
    return ajax
      .get(workshopUrls.supplierUrls.loadSuppliersAsOptions())
      .toPromise()
      .then<Workshop.Domain.Queries.Suppliers.GetSuppliersAsOptions.SupplierOptionItem>(r => {
        return r.response;
      });
  }

  function edit(command: Workshop.Domain.Commands.JobTask.UpdateJobTaskCommand) {
    return ajax
      .put(jobTaskUrls.edit(command.jobTaskId), command)
      .toPromise()
      .then(r => returnVoid());
  }

  function scheduleMachineryJobTask(
    command: Workshop.Domain.Commands.JobTask.ScheduleMachineryJobTaskCommand
  ) {
    return ajax
      .post(jobTaskUrls.scheduleMachineryJobTask(), command)
      .toPromise()
      .then(r => returnVoid());
  }

  function scheduleFutureJobTask(
    command: Workshop.Domain.Commands.JobTask.ScheduleFutureJobTaskCommand
  ) {
    return ajax
      .post(jobTaskUrls.scheduleFutureJobTask(), command)
      .toPromise()
      .then(r => returnVoid());
  }

  function cancelJobTask(command: Workshop.Domain.Commands.JobTask.CancelJobTaskCommand) {
    return ajax
      .post(jobTaskUrls.cancelJobTask(command.jobTaskId), command)
      .toPromise()
      .then(r => returnVoid());
  }

  function deleteJobTask(command: Workshop.Domain.Commands.JobTask.DeleteJobTaskCommand) {
    return ajax
      .post(jobTaskUrls.deleteJobTask(command.jobTaskId), command)
      .toPromise()
      .then(r => returnVoid());
  }

  function createAdhocJobTask(command: Workshop.Domain.Commands.JobTask.CreateAdhocJobTaskCommand) {
    return ajax
      .post(jobTaskUrls.createAdhocJobTask(), command)
      .toPromise()
      .then<string>(r => r.response);
  }

  function unscheduleFutureJobTask(id: string) {
    return ajax
      .post(jobTaskUrls.unscheduleFutureJobTask(id))
      .toPromise()
      .then(r => returnVoid());
  }

  function completeJobTask(id: string) {
    return ajax
      .post(jobTaskUrls.completeJobTask(id))
      .toPromise()
      .then(r => returnVoid());
  }

  function completeJobTaskAndResetServicePlan(
    command: Omit<CompleteServiceJobTaskAndResetScheduleCommand, 'jobServiceCycleItem'> & {
      jobServiceCycleItem: ServiceCycleItem | undefined;
    }
  ) {
    return ajax
      .post(jobTaskUrls.completeJobTaskAndResetSchedule(command.jobTaskId), command)
      .toPromise()
      .then(r => returnVoid());
  }

  function setToInProgress(id: string) {
    return ajax
      .post(jobTaskUrls.startJobTaskProgress(id))
      .toPromise()
      .then(r => returnVoid());
  }

  function closeJobTaskWithoutFixing(command: CloseJobTaskAndDefectWithoutFixingCommand) {
    return ajax
      .post(jobTaskUrls.closeJobTaskWithoutFixing(command.jobTaskId), command)
      .toPromise()
      .then(r => returnVoid());
  }

  function schedulePreventativeMaintenanceServiceJobTask(
    command: Workshop.Domain.Commands.JobTask.SchedulePreventativeMaintenanceServiceJobTaskCommand
  ): Promise<
    Workshop.Domain.Commands.JobTask.SchedulePreventativeMaintenanceServiceJobTaskResponse
  > {
    return ajax
      .post(jobTaskUrls.schedulePreventativeMaintenanceServiceJobTask(), command)
      .toPromise()
      .then<Workshop.Domain.Commands.JobTask.SchedulePreventativeMaintenanceServiceJobTaskResponse>(
        r => r.response
      );
  }

  function schedulueServiceJobTask(
    command: Workshop.Domain.Commands.JobTask.ScheduleServiceJobTaskCommand
  ): Promise<string> {
    return ajax
      .post(jobTaskUrls.scheduleServiceJobTask(), command)
      .toPromise()
      .then(r => r.response);
  }

  function exportTasksToExcel(
    query: Partial<Workshop.Domain.Queries.JobTask.ListJobTasks.ListJobTasksQuery>
  ) {
    return ajax
      .getFile(jobTaskUrls.exportTasksToExcel(query))
      .toPromise()
      .then<Blob>(r => r.response);
  }

  return {
    jobTask: {
      listTasks,
      listJobTasksForAsset,
      listOutOfServiceJobTasks,
      getJobTask,
      getSchedulableJobsForTask,
      loadJobTaskCategories,
      loadSuppliers,
      edit,
      scheduleMachineryJobTask,
      schedulePreventativeMaintenanceServiceJobTask,
      schedulueServiceJobTask,
      scheduleFutureJobTask,
      cancelJobTask,
      deleteJobTask,
      createAdhocJobTask,
      unscheduleFutureJobTask,
      completeJobTask,
      completeJobTaskAndResetServicePlan,
      setToInProgress,
      getActiveJobsForTask,
      getJobDetailsForTask,
      exportTasksToExcel,
      closeJobTaskWithoutFixing,
    },
  };
}
