import { UiRouteName } from 'src/domain/baseTypes';
import { IRootStoreModel } from 'src/domain/entities/RootStoreModel';
import UiRoute from 'src/domain/services/buildUiRoutes/UiRoute';

export default function buildUiRoutes(store: IRootStoreModel) {
  const createRoster = new UiRoute(
    UiRouteName.workshopRosterCreate,
    '/workshop/rosters/create',
    () => `Create Roster`,
    false
  );

  const viewRosterChanges = new UiRoute(
    UiRouteName.workshopRosterViewChanges,
    '/workshop/rosters/:id/changes',
    () => `Roster Changes`,
    false
  );

  const manageRoster = new UiRoute(
    UiRouteName.workshopRosterManage,
    '/workshop/rosters/:id',
    () => `Manage Roster`,
    false,
    false,
    [viewRosterChanges]
  );

  const rostersList = new UiRoute(
    UiRouteName.workshopRosterList,
    '/workshop/rosters',
    () => 'Rosters',
    true,
    false,
    [createRoster, manageRoster]
  );

  return {
    [UiRouteName.workshopRosterList]: rostersList,
    [UiRouteName.workshopRosterCreate]: createRoster,
    [UiRouteName.workshopRosterManage]: manageRoster,
    [UiRouteName.workshopRosterViewChanges]: viewRosterChanges,
  };
}
