import { types, flow, getRoot } from 'mobx-state-tree';
import { getAjax } from 'src/domain/services';
import { ListBulkDriverAuthorizationUpdateOperationModel } from './ListBulkDriverAuthorizationUpdateOperationModel';
import { IRootStoreModel } from 'src/domain/entities/RootStoreModel';

type BulkDriverAuthorizationUpdateOperationDetails = Operations.Domain.Queries.GetBulkDriverAuthorizationUpdateOperation.BulkDriverAuthorizationUpdateOperationDetails;

export const BulkDriverAuthorizationUpdateOperationModel = types
  .model('BulkDriverAuthorizationUpdateOperationModel', {
    list: types.optional(ListBulkDriverAuthorizationUpdateOperationModel, {}),
    item: types.maybe(types.frozen<BulkDriverAuthorizationUpdateOperationDetails>()),
  })
  .actions(self => {
    const ajax = getAjax(self);
    const root = getRoot(self) as IRootStoreModel;

    const createBulkDriverAuthorizationUpdateOperation = flow(function*(file: string | Blob) {
      yield ajax.operations.bulkDriverAuthorizationUpdateOperation.createBulkDriverAuthorizationUpdateOperation(
        file
      );
      root.history.push(`/operations/process-driver-authorization`);
    });

    const getBulkDriverAuthorizationUpdateOperationDetails = flow(function*(id: string) {
      self.item = yield ajax.operations.bulkDriverAuthorizationUpdateOperation.getBulkDriverAuthorizationUpdateOperationDetails(
        id
      );
    });

    return {
      createBulkDriverAuthorizationUpdateOperation,
      getBulkDriverAuthorizationUpdateOperationDetails,
    };
  });
