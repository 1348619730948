import { buildListPageApiSearchModelTypedQuery } from 'src/domain/modelBuilders/buildListPageApiSearchModel';
type ListJobTaskSubcategoriesQuery = Workshop.Domain.Queries.JobTaskSubcategories.List.ListJobTaskSubcategoriesQuery;

const ListTaskSubcategoriesModel = buildListPageApiSearchModelTypedQuery<
  ListJobTaskSubcategoriesQuery
>()('ListTaskSubcategoriesModel', d =>
  d.ajax.workshop.taskSubcategories.listJobTaskSubcategories(d.query)
);

export const TaskSubcategoriesModel = ListTaskSubcategoriesModel;
