import { Component } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import {
  PagePrimarySize,
  PaneType,
  FieldType,
  ActionType,
  ShellModalSize,
} from 'src/views/definitionBuilders/types';
import CrudPage, { CrudPageMode, ICrudPageDef } from 'src/views/components/Page/pages/CrudPage';
import { TrashIcon } from 'src/images/icons';
import { standardTextSanitisers } from 'src/views/componentBuilders';
import { allStates, IStatesDescriptor } from 'src/api/enums';
import { getSubmitCloseModalActionGroupDef } from 'src/views/definitionBuilders/common';

type BoardingPointItem = Operations.Domain.Queries.GetBoardingPoint.BoardingPointItem;
type CreateBoardingPointCommand = Operations.Domain.Commands.BoardingPoint.CreateBoardingPointCommand;
type UpdateBoardingPointCommand = Operations.Domain.Commands.BoardingPoint.UpdateBoardingPointCommand;

interface IBoardingPointForm {
  address: string;
  city: string;
  id: string;
  name: string;
  notes: string;
  postcode: string;
  state: Operations.Domain.AggregatesModel.BoardingPointAggregate.States;
}

export interface IMaintainBoardingPointProps {
  mode: CrudPageMode;
  canManageBoardingPoints: boolean;

  loadBoardingPoint: (id: string) => Promise<void>;
  boardingPoint: BoardingPointItem | undefined;

  createBoardingPoint: (command: CreateBoardingPointCommand) => Promise<string>;
  updateBoardingPoint: (command: UpdateBoardingPointCommand) => Promise<void>;
  deleteBoardingPoint: (boardingPointId: string) => Promise<void>;

  checkForUniqueName: (name: string) => Promise<Common.Dtos.UniqueNameCheckResultDto>;

  states: Operations.Domain.AggregatesModel.BoardingPointAggregate.States[];
  loadStates: () => Promise<void>;
}

interface IUpdateBoardingPointRouteParams {
  id: string;
}

type InternalProps = IMaintainBoardingPointProps &
  RouteComponentProps<IUpdateBoardingPointRouteParams>;

class MaintainBoardingPoint extends Component<InternalProps> {
  componentDidMount() {
    this.props.loadStates();
  }

  private get isUpdateMode() {
    return this.props.mode === 'update';
  }

  private get boardingPointId() {
    return this.props.match.params.id;
  }

  private handlePreSubmitForCreate = (bp: IBoardingPointForm): CreateBoardingPointCommand => {
    return {
      address: bp.address,
      city: bp.city,
      name: bp.name,
      notes: bp.notes,
      postcode: bp.postcode,
      state: !!bp.state ? bp.state.description : '',
    };
  };

  private handlePreSubmitForUpdate = (bp: IBoardingPointForm): UpdateBoardingPointCommand => {
    return {
      id: bp.id,
      address: bp.address,
      city: bp.city,
      name: bp.name,
      notes: bp.notes,
      postcode: bp.postcode,
      state: !!bp.state ? bp.state.description : '',
    };
  };

  private handleSubmitForCreate = async (
    command: Operations.Domain.Commands.BoardingPoint.CreateBoardingPointCommand
  ): Promise<void> => {
    await this.props.createBoardingPoint(command);
  };

  private readonly getPageDef = (canManageBoardingPoints: boolean): ICrudPageDef => {
    return {
      primarySize: PagePrimarySize.half,
      primarySection: {
        title: this.isUpdateMode ? 'Manage Boarding Point' : 'Create a Boarding Point',
        primaryActions: this.isUpdateMode
          ? [
              {
                actions: [
                  {
                    actionType: ActionType.actionCollection,
                    hidden: () => !canManageBoardingPoints,
                    actionGroups: [
                      {
                        actions: [
                          {
                            actionType: ActionType.modalActionButton,
                            label: 'Delete Boarding Point',
                            icon: <TrashIcon />,
                            modalSize: ShellModalSize.oneQuarter,
                            modalDef: modalDefApi => ({
                              title: 'Delete Boarding Point',
                              asForm: true,
                              panels: [
                                {
                                  panes: [
                                    {
                                      paneType: PaneType.formFieldsPane,
                                      fields: [
                                        {
                                          fieldType: FieldType.customField,
                                          dataAddr: 'fake',
                                          render: () => (
                                            <span>
                                              Are you sure you want to delete boarding point?
                                            </span>
                                          ),
                                        },
                                      ],
                                    },
                                  ],
                                },
                              ],
                              secondaryActions: [getSubmitCloseModalActionGroupDef('Ok')],
                              onFormSubmit: () =>
                                this.props.deleteBoardingPoint(this.boardingPointId),
                            }),
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ]
          : [],
        panels: [
          {
            panes: [
              {
                paneType: PaneType.formFieldsPane,
                fields: [
                  {
                    fieldType: FieldType.textField,
                    label: 'Name',
                    dataAddr: 'name',
                    maxLength: 200,
                    mandatory: true,
                    validateAsync: async d => {
                      if (
                        !d.fieldValue ||
                        (this.isUpdateMode &&
                          this.props.boardingPoint &&
                          this.props.boardingPoint.name.toUpperCase() ===
                            d.fieldValue.toUpperCase())
                      ) {
                        return undefined;
                      }
                      const result = await this.props.checkForUniqueName(d.fieldValue);
                      return result.nameExists ? `Name is already in use` : undefined;
                    },
                  },
                  {
                    fieldType: FieldType.textField,
                    label: 'Address',
                    dataAddr: 'address',
                    maxLength: 200,
                  },
                  {
                    fieldType: FieldType.textField,
                    label: 'City',
                    dataAddr: 'city',
                    maxLength: 200,
                  },
                ],
              },
              {
                paneType: PaneType.formFieldsPane,
                columnCount: 2,
                fields: [
                  {
                    fieldType: FieldType.selectField,
                    dataAddr: 'state',
                    label: 'State',
                    valueKey: 'id',
                    descriptionKey: 'description',
                    optionItems: this.props.states,
                  },
                  {
                    fieldType: FieldType.textField,
                    label: 'Postcode',
                    dataAddr: 'postcode',
                    maxLength: 4,
                    onChange: api =>
                      api.setFormValue(
                        api.fieldDataAddr,
                        standardTextSanitisers.numericSanitiser(api.fieldData.fieldValue as string)
                      ),
                  },
                ],
              },
              {
                paneType: PaneType.formFieldsPane,
                fields: [
                  {
                    fieldType: FieldType.textAreaField,
                    label: 'Notes',
                    dataAddr: 'notes',
                  },
                ],
              },
            ],
          },
        ],
        onFormPreSubmit: this.isUpdateMode
          ? this.handlePreSubmitForUpdate
          : this.handlePreSubmitForCreate,
        onFormSubmit: this.isUpdateMode
          ? this.props.updateBoardingPoint
          : this.handleSubmitForCreate,
      },
      secondarySections: this.isUpdateMode ? [] : [],
    };
  };

  getData() {
    const { boardingPoint } = this.props;

    if (!boardingPoint) {
      return undefined;
    }

    const data = {
      address: boardingPoint.address,
      city: boardingPoint.city,
      id: boardingPoint.id,
      name: boardingPoint.name,
      notes: boardingPoint.notes,
      postcode: boardingPoint.postcode,
      state: this.getState(boardingPoint.state),
    };
    return data;
  }

  getState(state: string | undefined) {
    if (!state) {
      return undefined;
    }

    const stateEnum = allStates.find(x => x.description === state) as IStatesDescriptor;
    return {
      id: stateEnum.value,
      description: stateEnum.description,
    };
  }

  render() {
    const { mode, loadBoardingPoint, canManageBoardingPoints } = this.props;
    return (
      <CrudPage
        def={this.getPageDef(canManageBoardingPoints)}
        mode={mode}
        isEditingForbidden={!canManageBoardingPoints}
        onLoadData={() => loadBoardingPoint(this.boardingPointId)}
        data={this.getData()}
        createDefaultData={{}}
      />
    );
  }
}

export default MaintainBoardingPoint;
