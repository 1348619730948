import './ShellModal.scss';

import { createPortal } from 'react-dom';
import { Button } from 'reactstrap';
import { TimesIcon } from 'src/images/icons';
import { Component } from 'react';

export enum ShellModalSize {
  oneQuarter = 'oneQuarter',
  oneThird = 'oneThird',
  half = 'half',
  twoThirds = 'twoThirds',
  threeQuarters = 'threeQuarters',
}

interface IShellModalContentProps {
  size: ShellModalSize;
}

class ShellModalContent extends Component<IShellModalContentProps> {
  render() {
    const { size, children } = this.props;
    return (
      <section className="shell-modal-content-component" style={getAreaColumnLayoutStyle(size)}>
        <div className="shell-modal-backdrop" />
        <div className="shell-modal-content">{children}</div>
      </section>
    );
  }
}

interface IShellModalCloseButtonProps {
  onClick: () => void;
}

class ShellModalCloseButton extends Component<IShellModalCloseButtonProps> {
  render() {
    return (
      <Button
        className="shell-modal-close-button"
        title="Close"
        onClick={this.props.onClick}
        color="secondary">
        <TimesIcon />
      </Button>
    );
  }
}

export interface IShellModalTriggerApi {
  onShow: () => void;
  onClose: () => void;
}

interface IShellModalProps {
  size: ShellModalSize;
  renderShowTrigger: (api: IShellModalTriggerApi) => React.ReactNode;
  children: (api: IShellModalTriggerApi) => React.ReactNode;
  showCloseButton?: boolean;
  onCloseModal?: () => void;
}

interface IShellModalState {
  showModal: boolean;
}

class ShellModal extends Component<IShellModalProps, IShellModalState> {
  private unmounted: boolean = false;
  private readonly triggerApi: IShellModalTriggerApi;
  private modalContentElement: Element | undefined;

  constructor(props: IShellModalProps) {
    super(props);
    this.triggerApi = {
      onShow: this.handleShow,
      onClose: this.handleClose,
    };
    this.state = {
      showModal: false,
    };
  }

  componentDidMount() {
    // TODO: Consider retrieval by react context instead
    this.modalContentElement = document.getElementById('shell-modal-dom-location') as Element;
  }

  componentWillUnmount() {
    this.unmounted = true;
    this.manageDocumentListeners(false);
  }

  private readonly handleShow = () => {
    if (this.unmounted) {
      return;
    }
    this.setState({ showModal: true });
    this.manageDocumentListeners(true);
  };

  private readonly handleClose = () => {
    if (this.unmounted) {
      return;
    }
    this.setState({ showModal: false });
    this.manageDocumentListeners(false);

    if (this.props.onCloseModal) {
      this.props.onCloseModal();
    }
  };

  private readonly manageDocumentListeners = (attach: boolean) => {
    const listener = attach ? document.addEventListener : document.removeEventListener;
    listener('keydown', this.handleEscPress);
  };

  private readonly handleEscPress = (e: KeyboardEvent) => {
    if (e.key === 'Escape') {
      this.handleClose();
    }
  };

  render() {
    const { size, renderShowTrigger, showCloseButton, children } = this.props;
    const { showModal } = this.state;

    const showTrigger = renderShowTrigger(this.triggerApi);

    // TODO: Perhaps use context to get a function to call, which would render the modal content into a portal
    // Important that the modal portal doesn't actually render anything to the dom!
    const modalPortal =
      showModal && this.modalContentElement
        ? createPortal(
            <ShellModalContent size={size}>
              {showCloseButton ? <ShellModalCloseButton onClick={this.handleClose} /> : null}
              {children(this.triggerApi)}
            </ShellModalContent>,
            this.modalContentElement
          )
        : null;

    return (
      <>
        {showTrigger}
        {modalPortal}
      </>
    );
  }
}

export default ShellModal;

function getAreaColumnLayoutStyle(size?: ShellModalSize) {
  const defaultStyle = { gridTemplateColumns: '1fr 1fr' };
  switch (size) {
    case ShellModalSize.oneQuarter:
      return { ...defaultStyle, gridTemplateColumns: '3fr minmax(12em, 1fr)' };
    case ShellModalSize.oneThird:
      return { ...defaultStyle, gridTemplateColumns: '2fr minmax(12em, 1fr)' };
    case ShellModalSize.twoThirds:
      return { ...defaultStyle, gridTemplateColumns: '1fr minmax(12em, 2fr)' };
    case ShellModalSize.threeQuarters:
      return { ...defaultStyle, gridTemplateColumns: '1fr minmax(12em, 3fr)' };
    default:
      return defaultStyle;
  }
}
