import memoizeOne from 'memoize-one';
import { observer } from 'mobx-react';
import { useRootStore } from 'src/domain/entities/RootStoreModel';
import { CheckIcon } from 'src/images/icons';
import ListPage, { IListPageDef } from 'src/views/components/Page/pages/ListPage';
import PrimaryTitle from 'src/views/components/Page/PrimaryTitle/PrimaryTitle';
import TaskCardItem from 'src/views/components/TaskCard/TaskCardItem';
import { PagePrimarySize } from 'src/views/definitionBuilders/types';
import { FieldType } from 'src/views/definitionBuilders/types/field';

const ListExtraType: React.FC = observer(() => {
  const rootStore = useRootStore();
  const extraTypesModel = rootStore.operations.extraTypes.list;
  const getPageDef = memoizeOne(
    (): IListPageDef => {
      return {
        primaryTitle: (
          <PrimaryTitle
            title="Extras"
            link="https://www.notion.so/Extras-5f9abca66f27491b9219d71b7c37dad2"></PrimaryTitle>
        ),
        primarySize: PagePrimarySize.full,
        onLoadData: extraTypesModel.listItems,
        externalSearch: true,
        hasMoreData: extraTypesModel.hasMoreItems,
        createLink: rootStore.account.isSalesDepartmentMember
          ? TaskCardItem.sales.createExtraType
          : undefined,
        primaryFields: [
          {
            fieldType: FieldType.textField,
            dataAddr: 'description',
            label: 'Description',
            linkTo: d => `/sales/extra-types/${d.parentValue.id}`,
          },
          {
            fieldType: FieldType.numericField,
            numericConfig: { numericType: 'signedDecimal', maxPointDigits: 4 },
            dataAddr: 'price',
            formatReadonly: 'unitPrice',
            label: 'Price Ex GST',
          },
          {
            fieldType: FieldType.customField,
            dataAddr: 'showInQuote',
            label: 'Accounting',
            render: api => (api.data.parentValue.showInAccounting ? <CheckIcon /> : null),
          },
          {
            fieldType: FieldType.customField,
            dataAddr: 'showInQuote',
            label: 'Sales',
            render: api => (api.data.parentValue.showInQuote ? <CheckIcon /> : null),
          },
          {
            fieldType: FieldType.customField,
            dataAddr: 'isCharter',
            label: 'Charter',
            render: api => (api.data.parentValue.isCharter ? <CheckIcon /> : null),
          },
          {
            fieldType: FieldType.customField,
            dataAddr: 'isRail',
            label: 'Rail',
            render: api => (api.data.parentValue.isRail ? <CheckIcon /> : null),
          },
          {
            fieldType: FieldType.customField,
            dataAddr: 'isTask',
            label: 'Task',
            render: api => (api.data.parentValue.isTask ? <CheckIcon /> : null),
          },
          {
            fieldType: FieldType.customField,
            dataAddr: 'isTaxable',
            label: 'GST Applicable',
            render: api => (api.data.parentValue.isTaxable ? <CheckIcon /> : null),
          },
        ],
      };
    }
  );

  return (
    <ListPage
      className="list-extra-types-component"
      data={extraTypesModel.items.slice()}
      def={getPageDef()}
    />
  );
});

export default ListExtraType;
