import { buildPaginationPageApiSearchModelTypeQuery } from 'src/domain/modelBuilders/buildPaginationPageApiSearchModel';

type ListStocktakePartsQuery = Workshop.Domain.Queries.Parts.GetStocktakePartsList.ListStocktakePartsQuery;

export const StocktakeModel = buildPaginationPageApiSearchModelTypeQuery<ListStocktakePartsQuery>()(
  'StocktakeModel',
  d => d.ajax.workshop.part.listStocktakeParts(d.query),
  (v1, v2) => v1.id === v2.id,
  { size: 100 }
);
