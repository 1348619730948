import { Fragment } from 'react';
import { getSubmitCloseModalActionGroupDef } from 'src/views/definitionBuilders/common';
import { PaneType, ModalDefBuilder } from 'src/views/definitionBuilders/types';

export default function getDuplicateQuoteModalDef(
  isBooking: boolean,
  onDuplicate: () => Promise<void>
): ModalDefBuilder {
  const label = isBooking ? 'Booking' : 'Quote';
  return modalDefApi => ({
    title: `Duplicate ${label}`,
    asForm: true,
    panels: [
      {
        panes: [
          {
            paneType: PaneType.customPane,
            render: api => (
              <Fragment>
                <p>{`A new Quote will be created by duplicating the current ${label}.`}</p>
                <p>
                  Please note that any recurrances will <em>not</em> be included.
                </p>
              </Fragment>
            ),
          },
        ],
      },
    ],
    secondaryActions: [getSubmitCloseModalActionGroupDef('Duplicate')],
    onFormSubmit: onDuplicate,
  });
}
