import deepEqual from 'deep-equal';
import { observer } from 'mobx-react';
import React, { useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { ChangeState } from 'src/api/enums';
import { useRootStore } from 'src/domain/entities/RootStoreModel';
import CrudPage, { CrudPageMode, ICrudPageDef } from 'src/views/components/Page/pages/CrudPage';
import {
  ActionType,
  FieldType,
  PagePrimarySize,
  PaneType,
  ShellModalSize,
} from 'src/views/definitionBuilders/types';
import { ChevronUpIcon, ChevronDownIcon, TrashIcon } from 'src/images/icons';
import { DateTime } from 'luxon';
import { getSubmitCloseModalActionGroupDef } from 'src/views/definitionBuilders/common';
import { WORKING_WEEK_START_DAY } from 'src/appSettings';
import { formatDayOfWeekLong } from 'src/domain/dateHelper';

interface IMaintainRosterGroupProps {
  mode: CrudPageMode;
  route: RouteComponentProps<{ [x: string]: string | undefined }>;
}

type RosterGroupItem = Operations.Domain.Queries.ViewRosterGroup.RosterGroupItem;
type RosterItem = Operations.Domain.Queries.ViewRosterGroup.RosterItem;
type CreateRosterGroupCommand = Operations.Domain.Commands.Roster.CreateRosterGroup.CreateRosterGroupCommand;
type UpdateRosterGroupCommand = Operations.Domain.Commands.Roster.UpdateRosterGroup.UpdateRosterGroupCommand;
type RosterGroupRosterItem = Operations.Domain.Commands.Roster.RosterGroupRosterItem;

export const MaintainRosterGroup: React.FC<IMaintainRosterGroupProps> = observer(
  (props: IMaintainRosterGroupProps) => {
    const { mode } = props;
    const rosterGroupId = props.route.match.params.id;
    const rootStore = useRootStore();
    const isUpdateMode = props.mode === 'update';
    const canManageRosterGroups = rootStore.account.isOperationsDepartmentMember;

    const staffMemberModel = rootStore.people.staffMembers;
    const rosterGroupsModel = rootStore.operations.urban.rosterGroups;
    const rostersModel = rootStore.operations.urban.rosters;
    const rosterGroup = rosterGroupsModel.rosterGroup;
    const rosterOptions = rostersModel.rosters && rostersModel.rosters;
    const valuesToBeDisabled = rosterOptions.filter(x => x.rosterGroup).map(r => r.id);

    useEffect(() => {
      rostersModel.listRosters();
      staffMemberModel.loadAllStaffMemberNames({ active: true });
      if (rosterGroupId) {
        rosterGroupsModel.loadRosterGroup(rosterGroupId);
      }
    }, [rosterGroupId]);

    const handlePreSubmitForCreate = (values: RosterGroupItem): CreateRosterGroupCommand => {
      return {
        rosterGroupName: values.name,
        isRotating: values.isRotating,
        rotatingCommencementDate: values.isRotating ? values.rotatingCommencementDate : undefined,
        rosters: values.rosters.map(
          (item, index) =>
            ({
              rosterId: item.id,
              order: index,
              staffMemberId: item.staffMemberId,
            } as RosterGroupRosterItem)
        ),
      };
    };

    const handlePreSubmitForUpdate = (values: RosterGroupItem): UpdateRosterGroupCommand => {
      const originalRosterGroup = rosterGroupsModel.rosterGroup;
      const getChangeState = (ri: RosterItem) => {
        if (ri.changeState === ChangeState.Added || ri.changeState === ChangeState.Deleted) {
          return ri.changeState;
        }

        const originalRosterItem =
          originalRosterGroup && originalRosterGroup.rosters.find(x => x.id === ri.id);
        return deepEqual(ri, originalRosterItem) ? ChangeState.Unchanged : ChangeState.Modified;
      };

      return {
        id: values.id,
        rosterGroupName: values.name,
        isRotating: values.isRotating,
        rotatingCommencementDate: values.isRotating ? values.rotatingCommencementDate : undefined,
        rosters: values.rosters
          .map((item, index) => ({
            id: item.id,
            changeState: getChangeState(item),
            order: index,
            staffMemberId: item.staffMemberId,
          }))
          .filter(x => x.changeState !== ChangeState.Deleted)
          .map(
            item =>
              ({
                rosterId: item.id,
                order: item.order,
                staffMemberId: item.staffMemberId,
              } as RosterGroupRosterItem)
          ),
      };
    };

    const getPageDef = (updating: boolean): ICrudPageDef => {
      return {
        primarySize: PagePrimarySize.twoThirds,
        primarySection: {
          title: isUpdateMode ? 'Manage Roster Group' : 'Create a Roster Group',
          primaryActions: [
            {
              actions: [
                {
                  actionType: ActionType.actionCollection,
                  hidden: !canManageRosterGroups || !isUpdateMode,
                  actionGroups: [
                    {
                      actions: [
                        {
                          actionType: ActionType.modalActionButton,
                          label: 'Delete Roster Group',
                          icon: <TrashIcon />,
                          hidden: !rosterGroup,
                          modalSize: ShellModalSize.oneQuarter,
                          modalDef: _ => ({
                            title: 'Delete Roster Group',
                            asForm: true,
                            panels: [
                              {
                                panes: [
                                  {
                                    paneType: PaneType.customPane,
                                    render: () => (
                                      <div>
                                        <p>
                                          Are you sure you want to delete Roster Group '
                                          {rosterGroup?.name}'?
                                        </p>
                                      </div>
                                    ),
                                  },
                                ],
                              },
                            ],
                            secondaryActions: [getSubmitCloseModalActionGroupDef('Delete')],
                            onFormSubmit: () => rosterGroupsModel.deleteRosterGroup(rosterGroupId!),
                          }),
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
          panels: [
            {
              panes: [
                {
                  paneType: PaneType.formFieldsPane,
                  columnCount: 2,
                  fields: [
                    {
                      fieldType: FieldType.textField,
                      label: 'Roster Group Name',
                      dataAddr: 'name',
                      maxLength: 200,
                      mandatory: true,
                      validateAsync: async d => {
                        if (
                          !d.fieldValue ||
                          (isUpdateMode &&
                            rosterGroup &&
                            rosterGroup.name.toUpperCase() === d.fieldValue.toUpperCase())
                        ) {
                          return undefined;
                        }
                        const result = await rosterGroupsModel.checkForUniqueRosterGroupName(
                          d.fieldValue
                        );
                        return result.nameExists
                          ? `Roster Group name is already in use`
                          : undefined;
                      },
                    },
                    {
                      fieldType: FieldType.yesNoField,
                      label: 'Rotating',
                      dataAddr: 'isRotating',
                      mandatory: true,
                    },
                    {
                      fieldType: FieldType.dateField,
                      label: 'Rotation 1 starts week commencing',
                      dataAddr: 'rotatingCommencementDate',
                      mandatory: true,
                      formatReadonly: d =>
                        DateTime.fromISO(d.fieldValue!).toLocaleString(
                          DateTime.DATE_MED_WITH_WEEKDAY
                        ),
                      hidden: a => !a.paneValue.isRotating,
                      validate: d => {
                        if (!d.fieldValue) {
                          return undefined;
                        }
                        const rotatingCommencementDate = DateTime.fromISO(d.fieldValue);

                        if (rotatingCommencementDate.weekday !== WORKING_WEEK_START_DAY) {
                          const startDayName = formatDayOfWeekLong(WORKING_WEEK_START_DAY);

                          return `Please select a start date that falls on a ${startDayName} to align with your working week's start.`;
                        } else return undefined;
                      },
                    },
                  ],
                },
              ],
            },
            {
              title: 'Rosters',
              dataAddr: 'rosters',
              panes: [
                {
                  paneType: PaneType.tablePane,
                  dataRequiredForRows: 'sectionValue',
                  mandatory: true,
                  validate: d => {
                    const items = (d.panelValue as RosterItem[]).filter(
                      i => i.changeState !== ChangeState.Deleted
                    );
                    const distinctRosters = new Set(items.map(i => i.id));
                    return distinctRosters.size !== items.length
                      ? 'Each roster can only be defined once'
                      : undefined;
                  },
                  fields: [
                    {
                      fieldType: FieldType.selectField,
                      label: 'Roster',
                      dataAddr: 'id',
                      useValueOnly: true,
                      valueKey: 'id',
                      descriptionKey: 'rosterNumber',
                      mandatory: true,
                      optionItems: rosterOptions,
                      columnWidth: '12em',
                      linkTo: d => `/operations/rosters/${d.fieldValue}`,
                      valuesToDisable: d => {
                        const selected = (d.panelValue as RosterItem[])
                          .filter(x => x.changeState !== ChangeState.Deleted)
                          .map(x => x.id);
                        return [...selected, ...valuesToBeDisabled];
                      },
                      onChange: api => {
                        let rowIndex = api.fieldDataAddr[1];
                        const lineStaffMemberIdAddr = [
                          ...api.fieldDataAddr.slice(0, api.fieldDataAddr.length - 1),
                          'staffMemberId',
                        ];
                        if (
                          api.formValues.rosters[rowIndex] &&
                          api.formValues.rosters[rowIndex].staffMemberId
                        ) {
                          api.setFormValue(
                            lineStaffMemberIdAddr,
                            api.formValues.rosters[rowIndex].staffMemberId
                          );
                        } else {
                          var selectedRoster = rosterOptions.find(a => a.id === api.newFieldValue);
                          api.setFormValue(
                            lineStaffMemberIdAddr,
                            selectedRoster &&
                              selectedRoster.staffMember &&
                              selectedRoster.staffMember.id
                          );
                        }
                      },
                    },
                    {
                      fieldType: FieldType.selectField,
                      label: 'Staff Member',
                      dataAddr: 'staffMemberId',
                      linkTo: d => `/people/staff-members/${d.parentValue.staffMemberId}`,
                      useValueOnly: true,
                      valueKey: 'id',
                      descriptionKey: 'name',
                      optionItems: staffMemberModel.allStaffMemberNames,
                    },
                    {
                      fieldType: FieldType.actionListField,
                      dataAddr: '',
                      columnWidth: '1px',
                      hidden: _ => isUpdateMode && !updating,
                      columnAutoHide: true,
                      actionGroups: [
                        {
                          actions: [
                            {
                              actionType: ActionType.moveArrayItemActionButton,
                              label: 'Move up',
                              icon: <ChevronUpIcon />,
                              moveDirection: 'prev',
                              hidden: d =>
                                (isUpdateMode && !updating) ||
                                (d.paneValue as Array<{}>).indexOf(d.parentValue) === 0,
                            },
                            {
                              actionType: ActionType.moveArrayItemActionButton,
                              label: 'Move down',
                              icon: <ChevronDownIcon />,
                              moveDirection: 'next',
                              hidden: d =>
                                (isUpdateMode && !updating) ||
                                (d.paneValue as Array<{}>).indexOf(d.parentValue) ===
                                  (d.paneValue as Array<{}>).length - 1,
                            },
                            {
                              hidden: _ => isUpdateMode && !updating,
                              actionType: ActionType.removeArrayItemActionButton,
                              label: 'Remove Roster',
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
                {
                  paneType: PaneType.actionListPane,
                  hidden: isUpdateMode && !updating,
                  actionGroups: [
                    {
                      actions: [
                        {
                          actionType: ActionType.addArrayItemActionButton,
                          label: 'Add Roster',
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
          onFormPreSubmit: isUpdateMode ? handlePreSubmitForUpdate : handlePreSubmitForCreate,
          onFormSubmit: isUpdateMode
            ? rosterGroupsModel.updateRosterGroup
            : rosterGroupsModel.createRosterGroup,
        },
      };
    };

    return (
      <CrudPage
        def={({ updating }) => getPageDef(updating)}
        mode={mode}
        data={rosterGroup}
        createDefaultData={{}}
      />
    );
  }
);
