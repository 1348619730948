import { types, flow } from 'mobx-state-tree';
import { getAjax } from 'src/domain/services/storeEnvironment';
import { DateTime } from 'luxon';

type DailyNote = Workshop.Domain.Queries.DailyNote.GetDailyNotes.DailyNoteItem;

export const DailyNoteModel = types
  .model('DailyNoteModel', {
    dailyNote: types.maybe(types.frozen<DailyNote>()),
  })
  .actions(self => {
    const ajax = getAjax(self);

    const loadDailyNote = flow(function*(date: DateTime, depotId: number) {
      self.dailyNote = undefined;
      self.dailyNote = yield ajax.raw
        .get(`/api/kiosk/workshop/daily-notes/${date.toISODate()}/${depotId}`)
        .toPromise()
        .then(r => r.response);
    });

    return {
      loadDailyNote,
    };
  });
