import {
  AggregatesWithAttachments,
  LoadAttachmentsQuery,
} from 'src/views/components/Attachment/attachmentHelper';
import { getSubmitCloseModalActionGroupDef } from 'src/views/definitionBuilders/common';
import { FieldType, ModalDefBuilder, PaneType } from 'src/views/definitionBuilders/types';
import { updateCurrentJobsDef } from './getAddAttachmentModalDef';

export type UpdateAttachmentFormData = {
  attachmentDetailsId: number;
  aggregateId: string;
  aggregateType: AggregatesWithAttachments;
  fileName: string;
  availableOnTablet: boolean;
  availableOnKiosk: boolean;
  updateCurrentJobs: boolean;
};

export default function getUpdateAttachmentModalDef(
  onSubmit: (data: UpdateAttachmentFormData) => Promise<void>,
  onSubmitSucceed: (query: LoadAttachmentsQuery) => Promise<void>
): ModalDefBuilder {
  return api => {
    const aggregateData = api.actionData.actionValue as UpdateAttachmentFormData;

    return {
      title: `Edit Attachment`,
      asForm: true,
      panels: [
        {
          panes: [
            {
              paneType: PaneType.formFieldsPane,
              fields: [
                {
                  fieldType: FieldType.textField,
                  label: 'File name',
                  mandatory: true,
                  dataAddr: 'fileName',
                  validate: fapi => {
                    if (fapi.fieldValue && !fapi.fieldValue.endsWith('.pdf')) {
                      return 'Please ensure the filename ends with .pdf';
                    }
                    if (fapi.fieldValue && fapi.fieldValue.trim() === '.pdf') {
                      return '\'.pdf\' is not a valid file name';
                    }
                    return undefined;
                  },
                },
                {
                  fieldType: FieldType.yesNoField,
                  label: "Available on Driver's Tablet?",
                  mandatory: true,
                  dataAddr: 'availableOnTablet',
                },
                {
                  fieldType: FieldType.yesNoField,
                  label: "Available on Driver's Kiosk & App?",
                  mandatory: true,
                  dataAddr: 'availableOnKiosk',
                },
                ...updateCurrentJobsDef(false),
              ],
            },
          ],
        },
      ],
      secondaryActions: [getSubmitCloseModalActionGroupDef('Update')],
      onFormSubmit: (values: UpdateAttachmentFormData) => {
        return onSubmit({
          attachmentDetailsId: aggregateData.attachmentDetailsId,
          aggregateId: aggregateData.aggregateId,
          aggregateType: aggregateData.aggregateType,
          fileName: values.fileName,
          availableOnTablet: values.availableOnTablet,
          availableOnKiosk: values.availableOnKiosk,
          updateCurrentJobs: values.updateCurrentJobs,
        });
      },
      onSubmitSucceeded: () =>
        onSubmitSucceed({
          aggregateId: aggregateData.aggregateId,
          aggregateType: aggregateData.aggregateType,
        }),
    };
  };
}
