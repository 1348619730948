import { flow, types, getRoot } from 'mobx-state-tree';
import { getAjax } from 'src/domain/services';
import { IRootStoreModel } from 'src/domain/entities/RootStoreModel';
import { assetUrls } from 'src/domain/services/apiUrls';

export const AssetOdometerReadingModel = types
  .model('AssetOdometerReadingModel', {
    lastOdometerReadings: types.maybe(
      types.frozen<Workshop.Domain.Queries.GetLastAssetOdometerReadings.AssetOdometerReadings>()
    ),
    lastOdometerReading: types.maybe(
      types.frozen<Workshop.Domain.Queries.GetLastAssetOdometerReadings.AssetOdometerReadingItem>()
    ),
  })
  .actions(self => {
    const ajax = getAjax(self);
    const root = getRoot(self) as IRootStoreModel;

    const loadLastOdometerReadings = flow(function*() {
      self.lastOdometerReadings = yield ajax.raw
        .get(assetUrls.getLastOdometerReadings({ assetIds: [] }))
        .toPromise()
        .then(r => r.response);
    });

    const loadLastOdometerReading = flow(function*(assetId) {
      const readings: Workshop.Domain.Queries.GetLastAssetOdometerReadings.AssetOdometerReadings = yield ajax.raw
        .get(assetUrls.getLastOdometerReadings({ assetIds: [assetId] }))
        .toPromise()
        .then(r => r.response);

      self.lastOdometerReading =
        readings && readings.assetOdometerReadingItems.find(x => x.assetId === assetId);
    });

    const createOdometerReadings = flow(function*(
      command: Workshop.Domain.Commands.Asset.CreateAssetOdometerReading.CreateAssetOdometerReadingCommand
    ) {
      yield ajax.raw.post('/api/workshop/assets/odometer-readings/create', command).toPromise();
      self.lastOdometerReadings = undefined;
      root.history.push(`/workshop/assets/odometer-readings?defaultFilter=true`);
    });

    return {
      loadLastOdometerReadings,
      createOdometerReadings,
      loadLastOdometerReading,
    };
  });
