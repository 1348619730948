import { ajax as rxAjax } from 'rxjs/observable/dom/ajax';
import { AjaxRequest, AjaxResponse, AjaxError } from 'rxjs/observable/dom/AjaxObservable';
import { Observable } from 'rxjs/Observable';
import { noop } from 'src/infrastructure/typeUtils';
import { IRootStoreModel } from 'src/domain/entities/RootStoreModel';
import { IKioskRootStoreModel } from 'src/domain/entities/KioskRootStoreModel';

export interface IAjax {
  get: (url: string) => Observable<AjaxResponse>;
  getFile: (url: string) => Observable<AjaxResponse>;
  getWithoutAppHeaders: (url: string) => Observable<AjaxResponse>;
  post: <T>(url: string, body?: T) => Observable<AjaxResponse>;
  postFile: <T>(url: string, body?: T) => Observable<AjaxResponse>;
  put: <T>(url: string, body?: T) => Observable<AjaxResponse>;
  patch: <T>(url: string, body?: T) => Observable<AjaxResponse>;
  httpDelete: <T>(url: string, body?: T) => Observable<AjaxResponse>;
  send: (req: AjaxRequest) => Observable<AjaxResponse>;
}

export function createAjax(
  getRoot: () => IRootStoreModel | IKioskRootStoreModel,
  defaults: Omit<AjaxRequest, 'headers'> & { headers: NonNullable<AjaxRequest['headers']> },
  responseSideEffect?: (response: AjaxResponse | AjaxError) => void
): IAjax {
  const sideEffect = responseSideEffect || noop;

  const getAppHeaders = () => {
    const appHeaders = {
      ...getKioskUserHeaders(),
    };

    return appHeaders;
  };

  function getKioskUserHeaders() {
    const root = getRoot();
    if (root && 'kiosk' in root) {
      const userId = root && root.account && root.account.id;
      const userName = root && root.account && root.account.name;

      return {
        'kbl-kiosk-user-id': userId || '',
        'kbl-kiosk-user-name': userName || '',
      };
    }
    return {};
  }

  function getWithoutAppHeaders(url: string) {
    const req = {
      ...defaults,
      url,
      method: 'GET',
      headers: { ...defaults.headers },
    };
    return rxAjax(req).do<AjaxResponse>(sideEffect, sideEffect);
  }

  function get(url: string) {
    const req = {
      ...defaults,
      url,
      method: 'GET',
      headers: { ...defaults.headers, ...getAppHeaders() },
    };
    return rxAjax(req).do<AjaxResponse>(sideEffect, sideEffect);
  }

  function getFile(url: string) {
    const req = {
      ...defaults,
      url,
      method: 'GET',
      responseType: 'blob',
      headers: { ...defaults.headers, ...getAppHeaders() },
    };
    return rxAjax(req).do<AjaxResponse>(sideEffect, sideEffect);
  }

  function post<T>(url: string, body?: T) {
    const req = {
      ...defaults,
      url,
      body,
      method: 'POST',
      timeout: 1000 * 60 * 10, // 10 minutes
      headers: { ...defaults.headers, ...getAppHeaders() },
    };
    return rxAjax(req).do<AjaxResponse>(sideEffect, sideEffect);
  }

  function postFile<T>(url: string, body?: T) {
    const headers = { ...defaults.headers };
    delete headers['Content-Type'];

    const req = {
      ...(defaults || {}),
      url,
      body,
      method: 'POST',
      timeout: 1000 * 60 * 10, // 10 minutes
      headers: { ...headers, ...getAppHeaders() },
    };
    return rxAjax(req).do<AjaxResponse>(sideEffect, sideEffect);
  }

  function put<T>(url: string, body?: T) {
    const req = {
      ...defaults,
      url,
      body,
      method: 'PUT',
      headers: { ...defaults.headers, ...getAppHeaders() },
    };
    return rxAjax(req).do<AjaxResponse>(sideEffect, sideEffect);
  }

  function patch<T>(url: string, body?: T) {
    const req = {
      ...defaults,
      url,
      body,
      method: 'PATCH',
      headers: { ...defaults.headers, ...getAppHeaders() },
    };
    return rxAjax(req).do<AjaxResponse>(sideEffect, sideEffect);
  }

  function httpDelete<T>(url: string, body?: T) {
    const req = {
      ...defaults,
      url,
      body,
      method: 'DELETE',
      headers: { ...defaults.headers, ...getAppHeaders() },
    };
    return rxAjax(req).do<AjaxResponse>(sideEffect, sideEffect);
  }

  function send(req: AjaxRequest) {
    const defs = defaults;
    const defHeaders = defs.headers || {};
    const reqHeaders = req.headers || {};
    return rxAjax({ ...defs, ...req, headers: { ...defHeaders, ...reqHeaders } }).do<AjaxResponse>(
      sideEffect,
      sideEffect
    );
  }

  return {
    get,
    getFile,
    getWithoutAppHeaders,
    post,
    put,
    httpDelete,
    send,
    postFile,
    patch,
  };
}
