import inject from 'src/views/injectFromStore';
import AuditHistory, { IAuditHistoryProps } from 'src/views/components/AuditHistory/AuditHistory';
import { IStores } from 'src/domain';

type AuditLogItem = Operations.Domain.Queries.ListAuditLog.AuditLogItem;

interface IContainerProps {
  auditLogs: AuditLogItem[];
  loadAuditLogs: (aggregateId: string, minimumVersion: number) => Promise<void>;
}

const Container = inject<IContainerProps, IAuditHistoryProps>((allStores: IStores, p) => ({
  auditLogs: allStores.rootStore.operations.auditLogs.auditLogs.slice(),
  loadAuditLogs: allStores.rootStore.operations.auditLogs.loadAuditLogs,
}))(AuditHistory);

export default Container;
