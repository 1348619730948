import './buildLabelledTextarea.scss';

import { FormField as makeFormField, FieldApi } from 'react-form';
import { Input } from 'reactstrap';
import { ILabelledFormField } from './types';
import { LabelledFormField } from 'src/views/components/forms';
import { convertToString, getFieldHtmlId, getDefaultableBoolValue } from './utils';

interface ILabelledTextareaProps {
  id?: string;
  readonly?: boolean;
  hideLabel?: boolean;
  autofocus?: boolean;
  disabled?: boolean;
  mandatory?: boolean;
}

interface ILabelledTextareaBuilderOptions {
  placeholder?: string;
  maxLength?: number;
  defaultReadonly?: boolean;
  decorateReadonlyValue?: (readonlyValue: React.ReactNode) => React.ReactNode;
  defaultMandatory?: boolean;
  sanitiseNewValue?: (newValue: string) => string;
  formatCurrentValue?: (value: string) => string | null | undefined;
}

type LabelledTextareaField = React.ComponentType<ILabelledTextareaProps> & ILabelledFormField;

function buildLabelledTextarea(
  fieldId: string,
  labelValue: React.ReactNode,
  tooltipValue?: React.ReactNode,
  options: ILabelledTextareaBuilderOptions = {}
) {
  // Build the labelled text field component
  const LabelledTextareaInternal: React.FC<ILabelledTextareaProps & { fieldApi: FieldApi }> = ({
    fieldApi,
    id,
    readonly,
    hideLabel,
    autofocus,
    disabled,
    mandatory,
  }) => {
    const { getValue, getError, setValue, setTouched, getTouched, getFieldName } = fieldApi;
    const htmlId = getFieldHtmlId(id, getFieldName);
    const currentValue = getValue();
    const touched = getTouched();
    const formattedCurrentValue = convertToString(
      options.formatCurrentValue ? options.formatCurrentValue(currentValue) : currentValue
    );
    const error = getError();
    const isMandatory = getDefaultableBoolValue(mandatory, options.defaultMandatory);

    function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
      const value = options.sanitiseNewValue
        ? options.sanitiseNewValue(e.target.value)
        : e.target.value;
      setValue(value);
    }

    return (
      <LabelledFormField
        className="labelled-textarea-component"
        readonly={readonly || options.defaultReadonly || false}
        readonlyValue={
          options.decorateReadonlyValue ? options.decorateReadonlyValue(currentValue) : currentValue
        }
        mandatory={isMandatory}
        hideLabel={hideLabel}
        labelValue={labelValue}
        error={touched && error}
        tooltipValue={tooltipValue}
        for={htmlId}>
        <Input
          autoComplete="off"
          type="textarea"
          id={htmlId}
          className={!(touched && error) ? '' : 'invalid'}
          disabled={disabled}
          autoFocus={autofocus}
          placeholder={options.placeholder}
          value={formattedCurrentValue}
          onChange={handleChange}
          maxLength={options.maxLength}
          onBlur={e => setTouched(true)}
        />
      </LabelledFormField>
    );
  };

  // Make the react-form fieldApi available to the component
  const LabelledTextareaInternalFormField = makeFormField(LabelledTextareaInternal);

  // Create a wrapper component so the field's fieldId is already set
  const WrappedField: React.FC<ILabelledTextareaProps> = props => (
    <LabelledTextareaInternalFormField {...props} field={fieldId} />
  );

  // Capture the fieldId and labelValue onto the exported component
  const LabelledFormTextareaField = Object.assign(WrappedField, {
    fieldId,
    labelValue,
  }) as LabelledTextareaField;

  return LabelledFormTextareaField;
}

export default buildLabelledTextarea;
