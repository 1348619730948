import './KblForm.scss';
import { Form, FormValues, FormApi, FormErrors } from 'react-form';
import { Prompt } from 'react-router';
import { FormFunctionProps, FormProps } from 'react-form';
import { Form as ReactStrapForm, Row, Col } from 'reactstrap';
import deepEqual from 'src/infrastructure/deepEqual';
import Spinner from 'src/views/components/Spinner';
import { Component } from 'react';

export interface IKblFormProps {
  heading?: React.ReactNode;
  showSpinner?: boolean;
  toolbar?: (formApi: KblFormFunctionProps) => React.ReactNode;
  defaultValues?: FormProps['defaultValues'];
  errorValidator?: FormProps['validateError'];
  asyncValidators?: FormProps['asyncValidators'];
  onPreSubmit?: FormProps['preSubmit'];
  onSubmit?: (
    values: FormValues,
    // tslint:disable-next-line:no-any
    submissionEvent: React.SyntheticEvent<any>,
    formApi: FormApi
  ) => // tslint:disable-next-line:no-any
  Promise<any>;
  onPostSuccessfulSubmit?: () => void;
  children: ((formApi: KblFormFunctionProps) => React.ReactNode) | React.ReactNode;
}

interface IKblFormState {
  submitting: boolean;
  submitted: boolean;
  resetCount: number;
}

interface IAdditionalFormFunctionProps {
  valuesChanged: boolean;
  formInvalid: boolean;
  isSubmitting: boolean;
  submitted: boolean; // This shadows the default react-form `submitted`
}
export type KblFormFunctionProps = FormFunctionProps & IAdditionalFormFunctionProps;

export const KblFormTestSpy: React.FC<{ formApi: KblFormFunctionProps }> = ({ formApi }) => null;

class KblForm extends Component<IKblFormProps, IKblFormState> {
  private unmounted = false;

  constructor(props: IKblFormProps) {
    super(props);
    this.state = {
      submitting: false,
      submitted: false, // keep our own submitted to handle async onSubmit
      resetCount: 0,
    };
  }

  componentWillUnmount() {
    setWindowUnloadHandler(false);
    this.unmounted = true;
  }

  setStateP = <K extends keyof IKblFormState>(state: Pick<IKblFormState, K> | IKblFormState) => {
    // @ts-ignore: this is an obsolete module; just make the compiler happy
    return new Promise(resolve => this.setState(state as any, resolve));
  };

  handleSubmit: FormProps['onSubmit'] = (values, submissionEvent, formApi) => {
    if (!this.props.onSubmit) {
      return;
    }
    // Only remove the handler if submit promise succeeds so that potential ajax failures are catered for
    const onSubmit = this.props.onSubmit;
    this.setStateP({ submitting: true })
      .then(() => onSubmit(values, submissionEvent, formApi))
      .then(() => setWindowUnloadHandler(false))
      .then(() => !this.unmounted && this.setStateP({ submitting: false, submitted: true }))
      .then(() => this.props.onPostSuccessfulSubmit && this.props.onPostSuccessfulSubmit())
      .then(() => {
        // Reset the form to defaultValues - unfortunately we need to use resetCount to render a new
        // form instance, as currently updating the defaultValues is not supported by react-form
        formApi.resetAll();
        !this.unmounted &&
          this.setStateP({ resetCount: this.state.resetCount + 1, submitted: false });
      })
      .catch(() => !this.unmounted && this.setStateP({ submitting: false }));
  };

  render() {
    return (
      <div className="kbl-form-component">
        <Form
          key={this.state.resetCount}
          defaultValues={this.props.defaultValues}
          validateError={this.props.errorValidator}
          asyncValidators={this.props.asyncValidators}
          preSubmit={this.props.onPreSubmit}
          onSubmit={this.handleSubmit}>
          {formApi => {
            const formInvalid = isInvalid(formApi.errors);
            const valuesChanged = !deepEqual(this.props.defaultValues || {}, formApi.values);
            const { submitting: isSubmitting, submitted } = this.state;
            const api = { ...formApi, valuesChanged, formInvalid, isSubmitting, submitted };
            setWindowUnloadHandler(valuesChanged);
            return (
              <ReactStrapForm onSubmit={formApi.submitForm} noValidate autoComplete="off">
                <KblFormTestSpy formApi={api} />
                <div className="heading-bar">
                  <div className="container-fluid">
                    <Row>
                      <Col md="8">{this.props.heading}</Col>
                      <Col md="4">{this.props.toolbar && this.props.toolbar(api)}</Col>
                    </Row>
                  </div>
                </div>
                <Prompt
                  when={valuesChanged && !this.state.submitted}
                  message="You are about to navigate away from the page. You will lose any changes you have made. Are you sure you want to leave?"
                />
                <Spinner show={this.props.showSpinner || isSubmitting}>
                  {this.props.children && typeof this.props.children === 'function'
                    ? (this.props.children as Function)(api)
                    : this.props.children}
                </Spinner>
              </ReactStrapForm>
            );
          }}
        </Form>
      </div>
    );
  }
}

export default KblForm;

function promptBeforeUnload(e: BeforeUnloadEvent) {
  e.returnValue = true;
  return true;
}

function setWindowUnloadHandler(prompt: boolean) {
  // this is to make sure that browser will prompt if user tries to close the browser or the tab when editing the form
  prompt
    ? window.addEventListener('beforeunload', promptBeforeUnload)
    : window.removeEventListener('beforeunload', promptBeforeUnload);
}

function isInvalid(errors: FormErrors | Array<FormErrors> | string | null | undefined): boolean {
  if (Array.isArray(errors)) {
    return errors.some(k => isInvalid(k));
  } else if (errors !== null && typeof errors === 'object') {
    return Object.keys(errors).some(k => isInvalid(errors[k]));
  }
  return !!errors;
}
