import { IAjax } from 'src/infrastructure/ajax';
import { kioskUrls } from 'src/domain/services/apiUrls';

type JobDetailsDto = Workshop.Domain.Queries.Job.JobDetailsDto;

export default function init(ajax: IAjax) {
  function loadJobs() {
    return ajax
      .get(kioskUrls.workshopUrls.loadJobs())
      .toPromise()
      .then<JobDetailsDto[]>(r => r.response);
  }

  function loadJob(jobId: string) {
    return ajax
      .get(kioskUrls.workshopUrls.loadJob(jobId))
      .toPromise()
      .then<JobDetailsDto>(r => r.response);
  }

  function loadOverdueJobsForMechanic(depotId: number) {
    return ajax
      .get(kioskUrls.workshopUrls.loadOverdueJobsForMechanic(depotId))
      .toPromise()
      .then<Workshop.Domain.Queries.Job.GetJobsForMechanicAndDate.JobSummary[]>(r => r.response);
  }

  function loadJobsForMechanic(date: string, depotId: number) {
    return ajax
      .get(kioskUrls.workshopUrls.loadJobsForMechanic(date, depotId))
      .toPromise()
      .then<Workshop.Domain.Queries.Job.GetJobsForMechanicAndDate.JobSummary[]>(r => r.response);
  }

  function loadPrintJobSheet(jobId: string) {
    return ajax
      .get(kioskUrls.workshopUrls.loadPrintJobSheet(jobId))
      .toPromise()
      .then<JobDetailsDto>(r => r.response);
  }

  return {
    job: {
      loadJobs,
      loadJob,
      loadPrintJobSheet,
      loadJobsForMechanic,
      loadOverdueJobsForMechanic,
    },
  };
}
