import { types, flow } from 'mobx-state-tree';
import { Subject } from 'rxjs/Subject';
import { getAjax } from 'src/domain/services';
import { IAutocompleteResult } from 'src/domain/baseTypes';

type SubchecklistItem = Workshop.Domain.Queries.GetSubchecklistItems.SubchecklistItem;
type SearchSubchecklistItemsResult = Workshop.Domain.Queries.SearchSubchecklistItems.SearchSubchecklistItemsResult;

// Don't cache too long so that new items that are saved can be found
const searchCacheTimeMs = 1000;

interface ISearchItem {
  search: string;
  result: Promise<SearchSubchecklistItemsResult>;
}

export const SubchecklistItemsModel = types.model('SubchecklistItemsModel', {}).actions(self => {
  const ajax = getAjax(self);
  const searchSubject = new Subject<string>();
  const searchResultSubject = new Subject<ISearchItem>();
  const searchEndedSubject = new Subject<string>();
  searchSubject
    // Group searches so that the number of api calls can be controlled
    .groupBy(
      s => s,
      s => s,
      // Groups will be removed when this observable emits (ie. the cache will be cleared for that search)
      g => searchEndedSubject.debounceTime(searchCacheTimeMs).first(s => s === g.key)
    )
    .mergeMap(g =>
      g.scan((x, search) => {
        // For each search term, only kick off an api call if there isn't already one
        const item = !!x ? x : { search, result: ajax.checklist.searchSubchecklistItems(search) };
        // When the search completes, push into the ended subject to allow the group to be removed
        !x && item.result.then(() => searchEndedSubject.next(search));
        return item;
      }, null as ISearchItem | null)
    )
    // Use subscribe to keep the state of this pipeline running between calls to search
    .subscribe(x => x && searchResultSubject.next(x));

  const getSubchecklistItemsForASubchecklist = flow(function*(subchecklistId: string) {
    const resp: SubchecklistItem[] = yield ajax.checklist.getSubchecklistItemsForASubchecklist(
      subchecklistId
    );
    return resp;
  });

  const searchSubchecklistItems = flow(function*(search: string) {
    const p = searchResultSubject.first(x => !!x && x.search === search).toPromise();
    searchSubject.next(search);
    const item: ISearchItem = yield p;
    const result: SearchSubchecklistItemsResult = yield item.result;
    return {
      options: result.items,
    } as IAutocompleteResult<SubchecklistItem>;
  });

  return {
    getSubchecklistItemsForASubchecklist,
    searchSubchecklistItems,
  };
});
