import { types, flow } from 'mobx-state-tree';
import { getAjax } from 'src/domain/services/storeEnvironment';

type OperationsDepotDto = Common.Dtos.OperationsDepotDto;

export const DepotModel = types
  .model('DepotModel', {
    depots: types.array(types.frozen<OperationsDepotDto>()),
  })
  .actions(self => {
    const ajax = getAjax(self);

    const loadDepots = flow(function*() {
      self.depots = yield ajax.operations.depots.loadDepots();
    });

    return {
      loadDepots,
    };
  });
