import styles from './AuthoriseDevice.module.scss';
import { Input, Label, Button } from 'reactstrap';
import AnonymousHeader from 'src/views/kioskRoutes/components/presentational/AnonymousHeader/AnonymousHeader';
import { useRef } from 'react';

interface IAuthoriseDeviceProps {
  authoriseDevice: (code: string) => Promise<void>;
}

const AuthoriseDevice: React.FC<IAuthoriseDeviceProps> = ({ authoriseDevice }) => {
  const inputEl = useRef<HTMLInputElement | null>(null);
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const code = inputEl.current && inputEl.current.value;
    code && authoriseDevice(code);
  };

  return (
    <div className={styles.root}>
      <AnonymousHeader />
      <div className={styles.content}>
        <form onSubmit={handleSubmit}>
          <Label className={styles['labelled-field']}>
            <span>Device Authorisation Code</span>
            <Input innerRef={inputEl} autoFocus />
          </Label>
          <Button className={`${styles['submit-button']} btn btn-lg`} type="submit">
            Submit
          </Button>
        </form>
      </div>
    </div>
  );
};

export default AuthoriseDevice;
