import { ChevronDownIcon, ChevronUpIcon, EditIcon, FilePdfIcon, TrashIcon } from 'src/images/icons';
import { DateTime } from 'luxon';
import styles from './AttachmentListItem.module.scss';
import ModalActionButton from 'src/views/components/Page/actions/ModalActionButton';
import { ActionType, IActionData, ShellModalSize } from 'src/views/definitionBuilders/types';
import Button from 'reactstrap/lib/Button';
import cn from 'classnames';
import React from 'react';
import {
  AggregatesWithAttachments,
  LoadAttachmentsQuery,
  SwapAttachmentOrderData,
} from 'src/views/components/Attachment/attachmentHelper';
import getDeleteAttachmentModalDef, {
  DeleteAttachmentFormData,
} from './getDeleteAttachmentModalDef';
import getUpdateAttachmentModalDef, {
  UpdateAttachmentFormData,
} from './getUpdateAttachmentModalDef';

type AttachmentDto = People.Domain.Queries.Attachment.ListStaffMemberAttachments.StaffMemberAttachmentDetailsDto;

interface IAttachmentListItems {
  attachmentDto: AttachmentDto;
  aggregateId: string;
  aggregateType: AggregatesWithAttachments;
  deleteAttachment: (command: DeleteAttachmentFormData) => Promise<void>;
  updateAttachment: (command: UpdateAttachmentFormData) => Promise<void>;
  loadAttachments: (query: LoadAttachmentsQuery) => Promise<void>;
  attachments: Array<AttachmentDto>;
  swapAttachments?: (data: SwapAttachmentOrderData) => Promise<void>;
}

export const AttachmentListItem: React.FC<IAttachmentListItems> = ({
  attachmentDto,
  aggregateId,
  aggregateType,
  deleteAttachment,
  updateAttachment,
  loadAttachments,
  attachments,
  swapAttachments,
}) => {
  const createdOn = DateTime.fromISO(attachmentDto.createdOn).toLocaleString(DateTime.DATETIME_MED);
  const lastModifiedOn = DateTime.fromISO(attachmentDto.lastModifiedOn).toLocaleString(
    DateTime.DATETIME_MED
  );
  const hasBeenModified =
    DateTime.fromISO(attachmentDto.lastModifiedOn).toLocaleString(
      DateTime.DATETIME_FULL_WITH_SECONDS
    ) !==
    DateTime.fromISO(attachmentDto.createdOn).toLocaleString(DateTime.DATETIME_FULL_WITH_SECONDS);

  const orderingEnabled = false;
  const isFirstAttachment = attachments.indexOf(attachmentDto) === 0;
  const isLastAttachment = attachments.indexOf(attachmentDto) === attachments.length - 1;
  const previousAttachment = isFirstAttachment
    ? undefined
    : attachments[attachments.indexOf(attachmentDto) - 1];
  const nextAttachment = isLastAttachment
    ? undefined
    : attachments[attachments.indexOf(attachmentDto) + 1];

  return (
    <div className={styles.root}>
      <div className={styles.fileDetailsContainer}>
        <div className={styles.fileNameContainer}>
          <span className={cn(styles.bold, styles.fileName)}>
            <FilePdfIcon /> {attachmentDto.fileName}
          </span>
        </div>
        <div>
          <span>Uploaded </span>
          <span className={styles.bold}>{createdOn}</span>
          <span> by </span>
          <span className={styles.bold}> {attachmentDto.createdBy} </span>
        </div>
        {aggregateType === 'staffmember'}
        {hasBeenModified && (
          <div className={styles.lastModified}>
            <span>Last modified </span>
            <span className={styles.bold}>{lastModifiedOn}</span>
            <span> by </span>
            <span className={styles.bold}> {attachmentDto.lastModifiedBy} </span>
          </div>
        )}
      </div>
      <div className={cn(styles.actionButtons, { [styles.withOrdering]: orderingEnabled })}>
        <ModalActionButton
          className={styles.editButton}
          actionDef={{
            label: 'Edit Attachment',
            icon: <EditIcon />,
            actionType: ActionType.modalActionButton,
            modalSize: ShellModalSize.oneQuarter,
            modalDef: getUpdateAttachmentModalDef(
              {
                attachmentDetailsId: attachmentDto.id,
                aggregateId: aggregateId,
                fileName: attachmentDto.fileName,
                aggregateType: aggregateType,
              },
              updateAttachment,
              loadAttachments,
              aggregateType
            ),
          }}
          actionMeta={{
            formSubmitting: false,
            hideLabel: true,
            borderless: true,
            size: 'sm',
          }}
          actionData={
            {
              actionValue: {
                attachmentDetailsId: attachmentDto.id,
                fileName: attachmentDto.fileName,
              },
            } as IActionData
          }
        />

        <ModalActionButton
          className={styles.deleteButton}
          actionDef={{
            label: 'Delete Attachment',
            icon: <TrashIcon />,
            actionType: ActionType.modalActionButton,
            modalSize: ShellModalSize.oneQuarter,
            modalDef: getDeleteAttachmentModalDef(
              {
                attachmentDetailsId: attachmentDto.id,
                fileName: attachmentDto.fileName,
                aggregateType: aggregateType,
                aggregateId: aggregateId,
              },
              deleteAttachment,
              loadAttachments
            ),
          }}
          actionMeta={{
            formSubmitting: false,
            hideLabel: true,
            borderless: true,
            size: 'sm',
          }}
          actionData={
            {
              actionValue: {
                attachmentDetailsId: attachmentDto.id,
                fileName: attachmentDto.fileName,
              },
            } as IActionData
          }
        />
        {orderingEnabled && swapAttachments && (
          <>
            {!isFirstAttachment && (
              <Button
                color={'light'}
                className={styles.increaseOrderButton}
                onClick={async () => {
                  await swapAttachments({
                    aggregateId: aggregateId,
                    aggregateType: aggregateType,
                    firstAttachmentDetailsId: attachmentDto.id,
                    secondAttachmentDetailsId: previousAttachment!.id,
                  });
                  await loadAttachments({ aggregateId, aggregateType });
                }}>
                <ChevronUpIcon />
              </Button>
            )}
            {!isLastAttachment && (
              <Button
                color={'light'}
                className={styles.decreaseOrderButton}
                onClick={async () => {
                  await swapAttachments({
                    aggregateId: aggregateId,
                    aggregateType: aggregateType,
                    firstAttachmentDetailsId: attachmentDto.id,
                    secondAttachmentDetailsId: nextAttachment!.id,
                  });
                  await loadAttachments({ aggregateId, aggregateType });
                }}>
                <ChevronDownIcon />
              </Button>
            )}
          </>
        )}
      </div>
    </div>
  );
};
