import { ChangeState, FatigueBreakSource, allFatigueBreakSource } from 'src/api/enums';
import { PaneType, FieldType, ModalDefBuilder } from 'src/views/definitionBuilders/types';
import { totalJobHours } from '../../urban/urbanHelpers';
import { validateDateTimeIsNotLessThan } from 'src/infrastructure/dateUtils';
import { DateTime, Interval } from 'luxon';
import { getSubmitCloseModalActionGroupDef } from 'src/views/definitionBuilders/common';
type StaffMemberDto = Common.Dtos.StaffMemberDto;
type FatigueBreakItem = Operations.Domain.Queries.ViewJob.FatigueBreakItem;

export default function getMaintainFatigueBreakModalDef(
  dataMode: 'add' | 'edit',
  staffMembers: StaffMemberDto[],
  minDate: DateTime | undefined,
  maxDate: DateTime | undefined
): ModalDefBuilder {
  return modalDefApi => {
    return {
      title: dataMode === 'add' ? 'Add Fatigue Break' : 'Edit Fatigue Break',
      asForm: true,
      explicitData:
        dataMode === 'add'
          ? {
              changeState: ChangeState.Added,
            }
          : undefined,
      panels: [
        {
          panes: [
            {
              paneType: PaneType.formFieldsPane,
              columnCount: 3,
              fields: [
                {
                  fieldType: FieldType.selectField,
                  label: 'Staff Member',
                  dataAddr: 'staffMemberId',
                  columnWidth: '12em',
                  optionItems: staffMembers,
                  valueKey: 'id',
                  descriptionKey: 'name',
                  useValueOnly: true,
                  mandatory: true,
                },
              ],
            },
            {
              paneType: PaneType.formFieldsPane,
              columnCount: 3,
              fields: [
                {
                  fieldType: FieldType.dateTimeField,
                  dataAddr: 'fatigueBreakStart',
                  label: 'Start',
                  columnWidth: '15em',
                  mandatory: true,
                  onBlur: api => api.validateField(['fatigueBreakEnd']),
                  validate: d => {
                    if (minDate && d.fieldValue && DateTime.fromISO(d.fieldValue) < minDate) {
                      return 'Break Start exceeds job bounds';
                    }
                    return undefined;
                  },
                },
                {
                  fieldType: FieldType.dateTimeField,
                  dataAddr: 'fatigueBreakEnd',
                  label: 'End',
                  columnWidth: '15em',
                  mandatory: true,
                  validate: d => {
                    const lessThanValidation = validateDateTimeIsNotLessThan(
                      d.parentValue.fatigueBreakEnd,
                      'Break End',
                      d.parentValue.fatigueBreakStart,
                      'Break Start'
                    );
                    if (lessThanValidation) {
                      return lessThanValidation;
                    }
                    if (maxDate && d.fieldValue && maxDate < DateTime.fromISO(d.fieldValue)) {
                      return 'Break End exceeds job bounds';
                    }
                    const currentBreak = d.parentValue as FatigueBreakItem;
                    if (
                      currentBreak &&
                      currentBreak.fatigueBreakStart &&
                      currentBreak.fatigueBreakEnd
                    ) {
                      const otherBreaks = (modalDefApi.actionData
                        .paneValue as FatigueBreakItem[]).filter(
                        x => x.id !== currentBreak.id && x.changeState !== ChangeState.Deleted
                      );
                      const currentInterval = Interval.fromDateTimes(
                        DateTime.fromISO(currentBreak.fatigueBreakStart),
                        DateTime.fromISO(currentBreak.fatigueBreakEnd)
                      );
                      const overlap = otherBreaks.find(x => {
                        const otherInterval = Interval.fromDateTimes(
                          DateTime.fromISO(x.fatigueBreakStart),
                          DateTime.fromISO(x.fatigueBreakEnd)
                        );

                        const intersection = currentInterval.intersection(otherInterval);
                        return !!intersection && intersection.toDuration().valueOf() !== 0;
                      });
                      if (overlap) {
                        return 'Break overlaps with some other break';
                      }
                    }
                    return undefined;
                  },
                },
                {
                  fieldType: FieldType.readonlyField,
                  dataAddr: 'calculatedDuration',
                  label: 'Duration',
                  columnWidth: '15em',
                  formatReadonly: data => {
                    const j = data.parentValue as FatigueBreakItem;
                    const start = j.fatigueBreakStart;
                    const finish = j.fatigueBreakEnd;

                    return start && finish && totalJobHours(start, finish);
                  },
                },
                {
                  fieldType: FieldType.selectField,
                  dataAddr: ['fatigueBreakSource', 'id'],
                  valueKey: 'value',
                  descriptionKey: 'description',
                  optionItems: allFatigueBreakSource,
                  columnWidth: '10em',
                  useValueOnly: true,
                  label: 'Source',
                  mandatory: true,
                },
                {
                  fieldType: FieldType.textAreaField,
                  dataAddr: 'notes',
                  label: 'Notes',
                  columnWidth: '20em',
                  mandatory: d =>
                    d.parentValue.fatigueBreakSource &&
                    d.parentValue.fatigueBreakSource.id === FatigueBreakSource.Other,
                },
                {
                  fieldType: FieldType.yesNoField,
                  dataAddr: 'ignored',
                  label: 'Ignored',
                },
              ],
            },
          ],
        },
      ],
      secondaryActions: [getSubmitCloseModalActionGroupDef('Ok')],
      onFormSubmit: v =>
        dataMode === 'add'
          ? Promise.resolve(
              modalDefApi.parentFormApi.addValue(modalDefApi.parentFormApi.getFullField(), v)
            )
          : Promise.resolve(
              modalDefApi.parentFormApi.setValue(modalDefApi.parentFormApi.getFullField(), v)
            ),
    };
  };
}
