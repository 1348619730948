import inject from 'src/views/injectFromStore';
import OdometerReadings, { IOdometerReadingsProps } from './OdometerReadings';

const Container = inject<IOdometerReadingsProps>((allStores, p) => ({
  canManageOdometerReadings: allStores.rootStore.account.isWorkshopDepartmentMember,
  odometerReadings: allStores.rootStore.workshop.assetOdometerReadings.items,
  listOdometerReadings: allStores.rootStore.workshop.assetOdometerReadings.listItems,
  exportOdometerReadings: allStores.rootStore.workshop.assetOdometerReadings.exportToExcel,
  hasMoreData: allStores.rootStore.workshop.assetOdometerReadings.hasMoreItems,
  loadAssetCategories: allStores.rootStore.asset.loadAssetCategories,
  loadAssetHousedAtLocations: allStores.rootStore.asset.loadAssetHousedAtLocations,
  loadSubcategories: allStores.rootStore.asset.loadAssetSubcategories,
  assetCategories: allStores.rootStore.asset.categories.slice(),
  assetSubcategories: allStores.rootStore.asset.subcategories.slice(),
  assetHousingLocations: allStores.rootStore.asset.housedAtLocations.slice(),
}))(OdometerReadings);

export default Container;
