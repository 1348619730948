type DailyNoteItem = Workshop.Domain.Queries.DailyNote.GetDailyNotes.DailyNoteItem;

interface IDailyNote {
  dailyNote: DailyNoteItem | undefined;
  styles: { [key: string]: string };
}

export const DailyNote = ({ dailyNote, styles }: IDailyNote) => {
  if (!dailyNote || dailyNote.content.length === 0) {
    return null;
  }
  return (
    <div className={styles.dailyNote}>
      <div>
        <span>Daily Note</span>
      </div>
      <div className={styles.dailyNoteArea}>
        <p>{dailyNote.content}</p>
      </div>
    </div>
  );
};
