import saveAs from 'file-saver';
import { DateTime } from 'luxon';
import { observer } from 'mobx-react';
import { useEffect } from 'react';
import { allQuoteStatus } from 'src/api/enums';
import { useRootStore } from 'src/domain/entities/RootStoreModel';
import { ExcelIcon } from 'src/images/icons';
import { ListPage } from 'src/views/components/Page';
import { IListPageDef } from 'src/views/components/Page/pages/ListPage';
import TaskCardItem from 'src/views/components/TaskCard/TaskCardItem';
import {
  ActionType,
  FieldDefs,
  FieldType,
  PagePrimarySize,
  PaneType,
} from 'src/views/definitionBuilders/types';
import withQueryParams, { IQueryParamsProps } from 'src/views/hocs/withQueryParams';
import PrimaryTitle from 'src/views/components/Page/PrimaryTitle/PrimaryTitle';
import ExtraDetails from 'src/views/components/operations/ExtraDetails/ExtraDetails';

type ListAllQuotesQuery = Operations.Domain.Queries.ListAllQuotes.ListAllQuotesQuery;

const ListQuotes: React.FC<IQueryParamsProps<ListAllQuotesQuery>> = observer(
  (props: IQueryParamsProps<ListAllQuotesQuery>) => {
    const rootStore = useRootStore();
    const salesModel = rootStore.operations.sales;
    const quotesModel = salesModel.quotes;
    const operationsModel = rootStore.operations;
    const customerModel = salesModel.customer;

    useEffect(() => {
      quotesModel.loadQuoteTypes();
      operationsModel.vehicleTypes.loadVehicleTypes();
      quotesModel.loadTradingCompanies();
      props.updateQueryParams({ ignoreFilters: false });
    }, []);

    const ignoreFilters = props.getQueryParams().ignoreFilters;

    const saveExcel = () => {
      const fileName = `Quotes_and_Bookings_${DateTime.local().toFormat('yyyyMMddHHmm')}.xlsx`;
      return quotesModel.allQuotesList
        .exportToExcel(props.getQueryParams())
        .then(blob => saveAs(blob, fileName));
    };

    const getFilterFieldDefs = () => {
      return {
        tripFrom: {
          fieldType: FieldType.dateField,
          dataAddr: 'tripFrom',
          label: 'Trip From',
          onBlur: api => {
            if (!api.formValues.tripTo) {
              api.setFormValue(['tripTo'], api.fieldData.fieldValue);
            }
            api.validateField(['tripTo']);
          },
        } as FieldDefs,
        tripTo: {
          fieldType: FieldType.dateField,
          dataAddr: 'tripTo',
          label: 'Trip To',
          validate: d => {
            if (!d.fieldValue || !d.parentValue.tripFrom) {
              return undefined;
            }
            const from = DateTime.fromISO(d.parentValue.tripFrom);
            const to = DateTime.fromISO(d.fieldValue);
            return from > to ? 'First Trip To cannot be earlier than First Trip From' : undefined;
          },
        } as FieldDefs,
        quotedDateFrom: {
          fieldType: FieldType.dateField,
          dataAddr: 'quotedDateFrom',
          label: 'Quoted Date From',
          onBlur: api => {
            if (!api.formValues.quotedDateTo) {
              api.setFormValue(['quotedDateTo'], api.fieldData.fieldValue);
            }
            api.validateField(['quotedDateTo']);
          },
        } as FieldDefs,
        quotedDateTo: {
          fieldType: FieldType.dateField,
          dataAddr: 'quotedDateTo',
          label: 'Quoted Date To',
          validate: d => {
            if (!d.fieldValue || !d.parentValue.quotedDateFrom) {
              return undefined;
            }
            const from = DateTime.fromISO(d.parentValue.quotedDateFrom);
            const to = DateTime.fromISO(d.fieldValue);
            return from > to ? 'Quoted Date To cannot be earlier than Quoted Date From' : undefined;
          },
        } as FieldDefs,
        bookedDateFrom: {
          fieldType: FieldType.dateField,
          dataAddr: 'bookedDateFrom',
          label: 'Booked Date From',
          onBlur: api => {
            if (!api.formValues.bookedDateTo) {
              api.setFormValue(['bookedDateTo'], api.fieldData.fieldValue);
            }
            api.validateField(['bookedDateTo']);
          },
        } as FieldDefs,
        bookedDateTo: {
          fieldType: FieldType.dateField,
          dataAddr: 'bookedDateTo',
          label: 'Booked Date To',
          validate: d => {
            if (!d.fieldValue || !d.parentValue.bookedDateFrom) {
              return undefined;
            }
            const from = DateTime.fromISO(d.parentValue.bookedDateFrom);
            const to = DateTime.fromISO(d.fieldValue);
            return from > to ? 'Booked Date To cannot be earlier than Booked Date From' : undefined;
          },
        } as FieldDefs,
        cancelledDateFrom: {
          fieldType: FieldType.dateField,
          dataAddr: 'cancelledDateFrom',
          label: 'Cancelled Date From',
          onBlur: api => {
            if (!api.formValues.cancelledDateTo) {
              api.setFormValue(['cancelledDateTo'], api.fieldData.fieldValue);
            }
            api.validateField(['cancelledDateTo']);
          },
        } as FieldDefs,
        cancelledDateTo: {
          fieldType: FieldType.dateField,
          dataAddr: 'cancelledDateTo',
          label: 'Cancelled Date To',
          validate: d => {
            if (!d.fieldValue || !d.parentValue.cancelledDateFrom) {
              return undefined;
            }
            const from = DateTime.fromISO(d.parentValue.cancelledDateFrom);
            const to = DateTime.fromISO(d.fieldValue);
            return from > to
              ? 'Cancelled Date To cannot be earlier than Cancelled Date From'
              : undefined;
          },
        } as FieldDefs,
        customerId: {
          fieldType: FieldType.selectAsyncField,
          dataAddr: 'customerId',
          label: 'Customer',
          valueKey: 'customerId',
          descriptionKey: 'customerName',
          useValueOnly: true,
          loadOptionItems: customerModel.searchCustomers,
          loadItems: customerModel.findCustomers,
          onChange: api => {
            if (api.oldFieldValue) {
              const values = { ...api.formValues, contactIds: undefined };
              api.setFormValues(values);
            }
          },
        } as FieldDefs,
        contactIds: {
          fieldType: FieldType.selectAsyncMultiField,
          dataAddr: 'contactIds',
          getFieldKey: d => d.sectionValue.customerId,
          label: 'Contact',
          valueKey: 'contactId',
          descriptionKey: 'contactName',
          useValueOnly: true,
          loadOptionItems: (s, d) =>
            customerModel.searchContactsForCustomer(d.sectionValue.customerId, s),
          loadItems: (ids, d) =>
            customerModel.findContactsForCustomer(d.sectionValue.customerId, ids),
          readonly: d => !d.parentValue.customerId,
          autoload: true,
        } as FieldDefs,
        status: {
          fieldType: FieldType.selectMultiField,
          dataAddr: 'status',
          label: 'Status',
          valueKey: 'value',
          descriptionKey: 'description',
          optionItems: allQuoteStatus,
          useValueOnly: true,
        } as FieldDefs,
        cancellationReason: {
          fieldType: FieldType.textField,
          label: 'Cancellation Reason',
          dataAddr: 'cancellationReason',
        } as FieldDefs,
        quoteType: {
          fieldType: FieldType.selectMultiField,
          dataAddr: 'quoteType',
          label: 'Quote/Booking Type',
          valueKey: 'id',
          descriptionKey: 'description',
          optionItems: quotesModel.quoteTypes.slice(),
          useValueOnly: true,
          valuesToExclude: _ => {
            return quotesModel.quoteTypes.filter(t => !t.isActive).map(t => t.id);
          },
          optionRenderer: d => {
            const tradingCompany = quotesModel.tradingCompanies.find(
              tc => tc.id === d.tradingCompanyId
            );
            if (quotesModel.tradingCompanies.length >= 2) {
              return (
                <span>
                  {d.description}
                  <small>
                    &emsp; - &emsp;
                    {tradingCompany?.code}
                  </small>
                </span>
              );
            } else return <span>{d.description}</span>;
          },
        } as FieldDefs,
        vehicleType: {
          fieldType: FieldType.selectMultiField,
          dataAddr: 'vehicleType',
          label: 'Quoted Vehicle Type',
          valueKey: 'id',
          descriptionKey: 'description',
          optionItems: operationsModel.vehicleTypes.vehicleTypes.slice(),
          useValueOnly: true,
        } as FieldDefs,
        salesRepresentativeId: {
          fieldType: FieldType.selectMultiField,
          dataAddr: 'salesRepresentativeIds',
          label: 'Sales Person',
          valueKey: 'id',
          descriptionKey: 'name',
          optionItems: rootStore.operationsStartup.salesPeople.slice(),
          useValueOnly: true,
        } as FieldDefs,
        depots: {
          fieldType: FieldType.selectMultiField,
          dataAddr: 'depotIds',
          label: 'Depot',
          valueKey: 'id',
          descriptionKey: 'description',
          optionItems: rootStore.operationsStartup.operationsDepots.slice(),
          useValueOnly: true,
        } as FieldDefs,
        reviewed: {
          fieldType: FieldType.yesNoField,
          label: 'Reviewed',
          dataAddr: 'reviewed',
          useValueOnly: true,
        } as FieldDefs,
        tradingCompanyId: {
          fieldType: FieldType.selectMultiField,
          dataAddr: 'tradingCompanyIds',
          label: 'Trading Company',
          valueKey: 'id',
          descriptionKey: 'name',
          useValueOnly: true,
          optionItems: quotesModel.tradingCompanies.slice(),
          hidden: () => quotesModel.tradingCompanies.length === 1,
        } as FieldDefs,
        ignoreFilters: {
          fieldType: FieldType.yesNoField,
          dataAddr: 'ignoreFilters',
          hidden: true,
          label: '',
          useValueOnly: true,
        } as FieldDefs,
      };
    };

    const getPageDef = (): IListPageDef<ListAllQuotesQuery> => {
      const filterFieldDefsLookup = getFilterFieldDefs();
      const today = DateTime.local().toISODate();

      return {
        primarySize: PagePrimarySize.full,
        primaryTitle: <PrimaryTitle title="Quotes"></PrimaryTitle>,
        externalSearch: true,
        hasMoreData: quotesModel.allQuotesList.hasMoreItems,
        createLink: rootStore.account.isSalesDepartmentMember
          ? TaskCardItem.sales.createQuote
          : undefined,
        onLoadData: quotesModel.allQuotesList.listItems,
        minChar: 3,
        ignoreFilters,
        primaryActions: [
          {
            actions: [
              {
                actionType: ActionType.actionCollection,
                actionGroups: [
                  {
                    actions: [
                      {
                        actionType: ActionType.actionButton,
                        label: 'Export to Excel',
                        icon: <ExcelIcon fixedWidth />,
                        onClick: () => saveExcel(),
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
        primaryFields: [
          {
            fieldType: FieldType.textField,
            dataAddr: 'quoteNumber',
            label: 'Quote Number',
            linkTo: d => `/sales/quotes/${d.parentValue.id}`,
            columnWidth: '8em',
          },
          {
            fieldType: FieldType.textField,
            dataAddr: ['customer', 'customerName'],
            label: 'Customer Name',
            linkTo: d => `/sales/customers/${d.parentValue.customer.customerId}`,
            columnWidth: '8em',
          },
          {
            fieldType: FieldType.textField,
            dataAddr: ['customer', 'contactName'],
            label: 'Contact',
            columnWidth: '8em',
          },
          {
            fieldType: FieldType.textField,
            dataAddr: 'description',
            label: 'Description',
          },
          {
            fieldType: FieldType.textField,
            dataAddr: ['quoteType', 'description'],
            label: 'Quote Type',
          },
          {
            fieldType: FieldType.dateField,
            dataAddr: 'firstTrip',
            label: 'Trip Start',
            columnWidth: '8em',
          },
          {
            fieldType: FieldType.dateField,
            dataAddr: 'lastTrip',
            label: 'Trip End',
            columnWidth: '8em',
          },
          {
            fieldType: FieldType.customField,
            label: 'Facilities',
            dataAddr: 'extras',
            render: d => {
              const extras = d.data.fieldValue;
              const id = d.data.parentValue.id;
              return <ExtraDetails extras={extras} id={id} />;
            },
          },
          {
            fieldType: FieldType.selectField,
            dataAddr: 'status',
            label: 'Status',
            valueKey: 'id',
            descriptionKey: 'description',
            columnWidth: '7em',
          },
        ],
        filterAction: {
          defaultValues: {
            tripFrom: today,
            tripTo: today,
            ignoreFilters: false,
          },
          filterFields: Object.keys(filterFieldDefsLookup).map(k => filterFieldDefsLookup[k]),
          filterDef: () => [
            {
              panes: [
                {
                  paneType: PaneType.formFieldsPane,
                  columnCount: 2,
                  fields: [
                    filterFieldDefsLookup.tripFrom,
                    filterFieldDefsLookup.tripTo,
                    filterFieldDefsLookup.quotedDateFrom,
                    filterFieldDefsLookup.quotedDateTo,
                    filterFieldDefsLookup.bookedDateFrom,
                    filterFieldDefsLookup.bookedDateTo,
                    filterFieldDefsLookup.cancelledDateFrom,
                    filterFieldDefsLookup.cancelledDateTo,
                  ],
                },
                {
                  paneType: PaneType.formFieldsPane,
                  fields: [
                    filterFieldDefsLookup.customerId,
                    filterFieldDefsLookup.contactIds,
                    filterFieldDefsLookup.salesRepresentativeId,
                    filterFieldDefsLookup.depots,
                    filterFieldDefsLookup.status,
                    filterFieldDefsLookup.reviewed,
                    filterFieldDefsLookup.cancellationReason,
                    filterFieldDefsLookup.quoteType,
                    filterFieldDefsLookup.vehicleType,
                    filterFieldDefsLookup.tradingCompanyId,
                    filterFieldDefsLookup.ignoreFilters,
                  ],
                },
              ],
            },
          ],
        },
      };
    };

    return (
      <ListPage
        className="list-quotes-component"
        data={quotesModel.allQuotesList.items}
        def={getPageDef()}
      />
    );
  }
);

export default withQueryParams(ListQuotes);
