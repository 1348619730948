import { Component } from 'react';
import { FieldType } from 'src/views/definitionBuilders/types/field';
import CrudPage, { ICrudPageDef, CrudPageMode } from 'src/views/components/Page/pages/CrudPage';
import {
  PaneType,
  ActionType,
  PagePrimarySize,
  ShellModalSize,
} from 'src/views/definitionBuilders/types';
import { ChevronUpIcon, ChevronDownIcon, BanIcon, EditIcon } from 'src/images/icons';
import { allTechSpecDataType, TechSpecDataType, ChangeState } from 'src/api/enums';
import deepEqual from 'src/infrastructure/deepEqual';
import memoizeOne from 'src/infrastructure/memoizeOne';
import { getSubmitCloseModalActionGroupDef } from 'src/views/definitionBuilders/common';
import PrimaryTitle from 'src/views/components/Page/PrimaryTitle/PrimaryTitle';

type UpdateTechSpecsCommand = Workshop.Domain.Commands.TechSpecs.UpdateTechSpecsCommand;
type TechSpecItem = Workshop.Domain.Queries.TechSpecs.TechSpecItem;
type UniqueNameCheckResultDto = Common.Dtos.UniqueNameCheckResultDto;

export interface IMaintainTechSpecsProps {
  mode: CrudPageMode;
  canManageTechSpecs: boolean;
  techSpecs: TechSpecItem[];
  getTechSpecs: () => Promise<void>;
  updateTechSpecs: (cmd: UpdateTechSpecsCommand) => Promise<void>;
  clearTechSpecRequirements: (techSpecId: number) => Promise<void>;
  canClearTechSpecRequirements: boolean;
  onCheckForUniqueTechSpecName: (techSpecName: string) => Promise<UniqueNameCheckResultDto>;
}

class MaintainTechSpecs extends Component<IMaintainTechSpecsProps> {
  private readonly handlePreSubmit = (values: TechSpecItem[]): UpdateTechSpecsCommand => {
    const getChangeState = (techSpec: TechSpecItem, currentIndex: number) => {
      if (
        techSpec.changeState === ChangeState.Added ||
        techSpec.changeState === ChangeState.Deleted
      ) {
        return techSpec.changeState;
      }
      const originalTechSpecIndex =
        this.props.techSpecs && this.props.techSpecs.findIndex(ts => ts.id === techSpec.id);
      if (originalTechSpecIndex < 0) {
        throw new Error('Cannot find original Tech Spec');
      }
      const originalTechSpec = this.props.techSpecs[originalTechSpecIndex];

      return currentIndex === originalTechSpecIndex && deepEqual(techSpec, originalTechSpec)
        ? ChangeState.Unchanged
        : ChangeState.Modified;
    };

    return {
      techSpecItems: values.map((v, i) => {
        return {
          ...v,
          changeState: getChangeState(v, i),
        };
      }),
    } as UpdateTechSpecsCommand;
  };

  private readonly getPageDef = memoizeOne(
    (updating: boolean): ICrudPageDef => {
      return {
        primarySize: PagePrimarySize.threeQuarters,
        primarySection: {
          title: (
            <PrimaryTitle
              title="Tech Specs"
              link="https://www.notion.so/Tech-Specs-791bbc836edf43a9b4235978a03c8697"></PrimaryTitle>
          ),
          panels: [
            {
              panes: [
                {
                  paneType: PaneType.tablePane,
                  dataRequiredForRows: 'paneValue',
                  fields: [
                    {
                      fieldType: FieldType.textField,
                      dataAddr: 'description',
                      label: 'Description',
                      mandatory: true,
                      validateAsync: async d => {
                        const techSpec = d.parentValue as TechSpecItem;
                        if (
                          !d.fieldValue ||
                          (!!techSpec.id &&
                            this.props.techSpecs.find(x => x.id === techSpec.id) &&
                            this.props.techSpecs
                              .find(x => x.id === techSpec.id)!
                              .description.toUpperCase() === d.fieldValue.toUpperCase())
                        ) {
                          return undefined;
                        }
                        const techSpecs = d.paneValue as TechSpecItem[];
                        const descriptionCount = techSpecs.filter(
                          x => x.description === d.fieldValue
                        ).length;
                        if (descriptionCount > 1) {
                          return `Description is already in use`;
                        }
                        const result = await this.props.onCheckForUniqueTechSpecName(d.fieldValue);
                        return result.nameExists ? `Description is already in use` : undefined;
                      },
                    },
                    {
                      fieldType: FieldType.selectField,
                      dataAddr: 'dataType',
                      label: 'Type',
                      optionItems: allTechSpecDataType,
                      valueKey: 'value',
                      descriptionKey: 'description',
                      useValueOnly: true,
                      mandatory: true,
                      readonly: d => {
                        const techSpec = d.parentValue as TechSpecItem;
                        return !techSpec.canChangeDataType;
                      },
                    },
                    {
                      fieldType: FieldType.actionListField,
                      hidden: d => updating,
                      actionGroups: [
                        {
                          actions: [
                            {
                              actionType: ActionType.actionLink,
                              label: 'Edit dropdown options',
                              icon: <EditIcon />,
                              hidden: d => {
                                const techSpec = d.parentValue as TechSpecItem;
                                return techSpec.dataType !== TechSpecDataType.Dropdown;
                              },
                              to: d => `/workshop/tech-specs/${d.parentValue.id}/dropdown-options`,
                            },
                          ],
                        },
                      ],
                    },
                    {
                      fieldType: FieldType.yesNoField,
                      dataAddr: 'isForOperations',
                      label: 'For Operations',
                      mandatory: true,
                      readonly: d => {
                        const techSpec = d.parentValue as TechSpecItem;
                        return techSpec.isUsedInRequirements;
                      },
                      validate: d => {
                        const techSpec = d.parentValue as TechSpecItem;
                        return techSpec.dataType === TechSpecDataType.Date &&
                          techSpec.isForOperations
                          ? 'The date tech spec type cannot be used by operations'
                          : undefined;
                      },
                    },
                    {
                      fieldType: FieldType.yesNoField,
                      dataAddr: 'isForDriverTablet',
                      label: 'For Tablet',
                      mandatory: true,
                    },
                    {
                      fieldType: FieldType.yesNoField,
                      dataAddr: 'isForReporting',
                      label: 'For Reporting',
                      readonly: true,
                    },
                    {
                      fieldType: FieldType.yesNoField,
                      dataAddr: 'isUsedInRequirements',
                      label: 'Used in requirements',
                      readonly: true,
                    },
                    {
                      fieldType: FieldType.actionListField,
                      hidden: updating || !this.props.canClearTechSpecRequirements,
                      actionGroups: [
                        {
                          actions: [
                            {
                              actionType: ActionType.modalActionButton,
                              label: 'Clear requirements',
                              icon: <BanIcon />,
                              hidden: d => {
                                const techSpec = d.parentValue as TechSpecItem;
                                return !techSpec.isUsedInRequirements;
                              },
                              modalSize: ShellModalSize.oneQuarter,
                              modalDef: modalDefApi => ({
                                title: 'Clear requirements',
                                asForm: true,
                                panels: [
                                  {
                                    panes: [
                                      {
                                        paneType: PaneType.customPane,
                                        render: () => (
                                          <>
                                            <p>Are you sure you want to clear requirements?</p>
                                            <p>
                                              This will remove the requirement from all jobs, shifts
                                              and quotes.
                                            </p>
                                          </>
                                        ),
                                      },
                                    ],
                                  },
                                ],
                                secondaryActions: [getSubmitCloseModalActionGroupDef('Ok')],
                                onFormSubmit: values => {
                                  const techSpec = values as TechSpecItem;
                                  return this.props.clearTechSpecRequirements(techSpec.id!);
                                },
                              }),
                            },
                          ],
                        },
                      ],
                    },
                    {
                      fieldType: FieldType.actionListField,
                      hidden: !updating,
                      actionGroups: [
                        {
                          actions: [
                            {
                              actionType: ActionType.moveArrayItemActionButton,
                              label: 'Move up',
                              icon: <ChevronUpIcon />,
                              moveDirection: 'prev',
                              hidden: d =>
                                (d.paneValue as TechSpecItem[]).indexOf(d.parentValue) === 0,
                            },
                            {
                              actionType: ActionType.moveArrayItemActionButton,
                              label: 'Move down',
                              icon: <ChevronDownIcon />,
                              moveDirection: 'next',
                              hidden: d =>
                                (d.paneValue as TechSpecItem[]).indexOf(d.parentValue) ===
                                (d.paneValue as TechSpecItem[]).length - 1,
                            },
                            {
                              actionType: ActionType.removeArrayItemActionButton,
                              label: 'Remove Tech Spec',
                              hidden: d => {
                                const techSpec = d.parentValue as TechSpecItem;
                                return !techSpec.canBeDeleted;
                              },
                            },
                            {
                              actionType: ActionType.removeArrayItemActionButton,
                              label:
                                "This tech spec can't be deleted because it's used in reporting, as a requirement, or within one of the assets tech specs",
                              disabled: true,
                              hidden: d => {
                                const techSpec = d.parentValue as TechSpecItem;
                                return techSpec.canBeDeleted;
                              },
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
                {
                  paneType: PaneType.actionListPane,
                  actionGroups: [
                    {
                      actions: [
                        {
                          actionType: ActionType.addArrayItemActionButton,
                          hidden: !updating,
                          label: 'Add Tech Spec',
                          newItemData: {
                            isForOperations: false,
                            isForDriverTablet: false,
                            canChangeDataType: true,
                            canBeDeleted: true,
                            dataType: TechSpecDataType.String,
                          },
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
          onFormPreSubmit: this.handlePreSubmit,
          onFormSubmit: this.props.updateTechSpecs,
        },
      };
    }
  );

  render() {
    const { canManageTechSpecs } = this.props;
    return (
      <CrudPage
        data={this.props.techSpecs}
        def={({ updating }) => this.getPageDef(updating)}
        mode={this.props.mode}
        isEditingForbidden={!canManageTechSpecs}
        onLoadData={() => this.props.getTechSpecs()}
      />
    );
  }
}

export default MaintainTechSpecs;
