import { IRootStoreModel } from 'src/domain/entities/RootStoreModel';
import { UiRoute } from 'src/domain/services/buildUiRoutes';
import { UiRouteName } from 'src/domain/baseTypes';

export default function buildUiRoutes(store: IRootStoreModel) {
  const complianceFatigueDetails = new UiRoute(
    UiRouteName.complianceFatigueDetails,
    '/compliance/fatigueDetails/',
    () => 'Fatigue Details',
    false
  );

  return {
    [UiRouteName.complianceFatigueDetails]: complianceFatigueDetails,
  };
}
