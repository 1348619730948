import { formatCurrency } from 'src/infrastructure/formattingUtils';
import ListPage, { IListPageDef } from 'src/views/components/Page/pages/ListPage';
import TaskCardItem from 'src/views/components/TaskCard/TaskCardItem';
import { PagePrimarySize } from 'src/views/definitionBuilders/types';
import { FieldType } from 'src/views/definitionBuilders/types/field';
import PrimaryTitle from 'src/views/components/Page/PrimaryTitle/PrimaryTitle';

type VehicleTypeRate = Common.AggregatesModel.VehicleTypeAggregate.VehicleTypeRate;

export interface IListVehicleTypeProps {
  canManageVehicleTypes: boolean;
  loadVehicleTypes: () => Promise<void>;
  vehicleTypes: Common.Dtos.VehicleTypeItem[];
}

function getPageDef(props: IListVehicleTypeProps): IListPageDef {
  return {
    primaryTitle: (
      <PrimaryTitle
        title="Vehicle Types"
        link="https://www.notion.so/Vehicle-types-49d7e50044554c5d9949ad2f6554a166"></PrimaryTitle>
    ),
    primarySize: PagePrimarySize.full,
    onLoadData: props.loadVehicleTypes,
    externalSearch: false,
    createLink: props.canManageVehicleTypes ? TaskCardItem.sales.createVehicleType : undefined,
    primaryFields: [
      {
        fieldType: FieldType.textField,
        dataAddr: 'description',
        label: 'Vehicle type',
        linkTo: d => `/sales/vehicle-types/${d.parentValue.id}`,
      },
      {
        fieldType: FieldType.textAreaField,
        dataAddr: 'notes',
        label: 'Notes',
      },
      {
        fieldType: FieldType.numericField,
        dataAddr: 'price',
        formatReadonly: api => {
          if (api.parentValue.vehicleTypeRates.length === 1) {
            return <span>{formatCurrency(api.parentValue.vehicleTypeRates.at(0).price)}</span>;
          }

          const sortedPrice = api.parentValue.vehicleTypeRates
            .slice()
            .sort((a: VehicleTypeRate, b: VehicleTypeRate) => b.price - a.price);
          return (
            <span>
              {formatCurrency(sortedPrice.at(-1).price)} - {formatCurrency(sortedPrice.at(0).price)}
            </span>
          );
        },
        label: 'Rate per Km',
      },
    ],
  };
}

const ListVehicleType: React.FC<IListVehicleTypeProps> = (props: IListVehicleTypeProps) => {
  return (
    <ListPage
      className="list-vehicle-type-component"
      data={props.vehicleTypes}
      def={getPageDef(props)}
    />
  );
};

export default ListVehicleType;
