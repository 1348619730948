import styles from './PrintCharterDailyDiaries.module.scss';
import React from 'react';
import CompanyLogo from 'src/views/components/CompanyLogo/CompanyLogo';
import { DateTime } from 'luxon';

type CharterDailyDiaryDetails = Operations.Domain.Queries.ListCharterDailyDiaryDetails.CharterDailyDiaryDetails;

interface IPrintCharterDailyDiariesProps {
  jobs: CharterDailyDiaryDetails[];
}

export const PrintCharterDailyDiaries: React.FC<IPrintCharterDailyDiariesProps> = ({ jobs }) => {
  return jobs && jobs.length > 0 ? (
    <div className={styles.root}>
      <h1>Charter Daily Diaries</h1>
      <CompanyLogo mode={'full'} className={styles.logo} />
      {jobs.map((job, i) => {
        return (
          <>
            <table className={styles.jobContainer}>
              <tbody>
                <tr className={styles.padding}>
                  <td> </td>
                </tr>
                <tr className={styles.jobRow}>
                  <td className={styles.firstColumnLabels}>
                    <strong>Booking </strong>
                  </td>
                  <td className={styles.firstColumnDetails}>{job.bookingNumber}</td>
                  <td className={styles.secondColumnLabels}>
                    <strong>Description </strong>
                  </td>
                  <td colSpan={3}>{job.description}</td>
                </tr>

                <tr>
                  <td>
                    <strong>Job </strong>
                  </td>
                  <td>{job.jobNumber}</td>

                  <td>
                    <strong>Quoted Vehicle </strong>
                  </td>
                  <td>{job.quotedVehicleType}</td>

                  {!job.subcontractor ? (
                    <>
                      <td className={styles.thirdColumnLabels}>
                        <strong>Assigned Vehicle </strong>
                      </td>
                      <td className={styles.thirdColumnDetails}>{job.assetName || ` - `}</td>
                    </>
                  ) : (
                    <>
                      <td className={styles.thirdColumnLabels}>
                        <strong>Subcontractor </strong>
                      </td>
                      <td className={styles.thirdColumnDetails}>{job.subcontractor || ` - `}</td>
                    </>
                  )}
                </tr>

                <tr>
                  <td>
                    <strong>Trip </strong>
                  </td>
                  <td>
                    {job.tripNumber !== undefined &&
                      job.bookingNumber !== undefined &&
                      `${job.tripNumber + 1} of ${
                        job.totalTrips !== undefined ? job.totalTrips + 1 : ` - `
                      }`}
                  </td>
                  <td>
                    <strong>Customer </strong>
                  </td>
                  <td>{job.customerName}</td>
                  {!job.subcontractor ? (
                    <>
                      <td>
                        <strong>Driver </strong>
                      </td>
                      <td>{job.driverName || ` - `}</td>
                    </>
                  ) : (
                    <td colSpan={2}></td>
                  )}
                </tr>

                <tr>
                  <td>
                    <strong>PAX </strong>
                  </td>
                  <td>{job.passengers || ` - `}</td>
                  <td>
                    <strong>Clock on </strong>
                  </td>
                  <td>{DateTime.fromISO(job.clockOn).toLocaleString(DateTime.DATETIME_MED)}</td>
                  <td>
                    <strong>Depart depot </strong>
                  </td>
                  <td>
                    {(job.departDepot &&
                      DateTime.fromISO(job.departDepot).toLocaleString(DateTime.DATETIME_MED)) ||
                      ` - `}
                  </td>
                </tr>

                {job.notes && (
                  <tr>
                    <td>
                      <strong>Notes </strong>
                    </td>
                    <td colSpan={5}>{job.notes}</td>
                  </tr>
                )}
                <tr className={styles.padding} />
                {job.routes && job.routes.length > 0 && (
                  <tr className={styles.routesRow}>
                    <td colSpan={6}>
                      <strong>Routes</strong>
                      <table>
                        <tbody>
                          <tr>
                            <th>Date</th>
                            <th>Arrive</th>
                            <th>Depart</th>
                            <th>Location</th>
                          </tr>
                          {job.routes.map(route => {
                            return (
                              <tr>
                                <td className={styles.dateColumn}>{formatDate(route.date)}</td>
                                <td className={styles.arriveColumn}>
                                  {(route.arrive && formatTime(route.arrive)) || `---`}
                                </td>
                                <td className={styles.departColumn}>
                                  {(route.depart && formatTime(route.depart)) || `---`}
                                </td>
                                <td>{route.name}</td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
            <hr />
          </>
        );
      })}
    </div>
  ) : (
    <h1>No Jobs to Export</h1>
  );
};

const formatDate = (date: string) => {
  return DateTime.fromISO(date).toLocaleString(DateTime.DATE_MED);
};

const formatTime = (date: string) => {
  return DateTime.fromISO(date).toLocaleString(DateTime.TIME_24_SIMPLE);
};
