import inject from 'src/views/injectFromStore';
import { Minus } from 'src/infrastructure/minusTypeGenerator';
import { Component } from 'react';

export interface IQueryParamsProps<TParams extends {} = {}> {
  getQueryParams: () => Partial<TParams>;
  updateQueryParams: (params: Partial<TParams>) => void;
  urlSearch: string | undefined; // This prop ensures wrapped pure components will rerender when the url search changes
}

// Inject the standard route props into the wrapped component
function withQueryParams<P extends Partial<IQueryParamsProps>>(
  WrappedComponent: React.ComponentType<P>
) {
  const Container = inject<IQueryParamsProps, P>((allStores, p) => {
    const historyModel = allStores.rootStore.history;
    return {
      getQueryParams: historyModel.getQueryParams,
      updateQueryParams: historyModel.updateQueryParams,
      urlSearch: historyModel.currentLocation ? historyModel.currentLocation.search : undefined,
    };
  })(WrappedComponent);

  return class extends Component<Minus<P, IQueryParamsProps>> {
    render() {
      return <Container {...this.props} />;
    }
  };
}

export default withQueryParams;
