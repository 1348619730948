import inject from 'src/views/injectFromStore';
import People, { IPeopleProps } from './People';

const Container = inject<IPeopleProps>((allStores, p) => ({
  dashboardData: allStores.rootStore.people.peopleDashboard.dashboardData,
  onLoadData: allStores.rootStore.people.peopleDashboard.loadDashboardData,
  canCreateStaffMember: allStores.rootStore.account.isPeopleDepartmentMember,
  canModifyStaffSkillSpecs: allStores.rootStore.account.isPeopleDepartmentMember,
}))(People);

export default Container;
