import saveAs from 'file-saver';
import { operationsUrls } from 'src/domain/services/apiUrls';
import { IAjax } from 'src/infrastructure/ajax';

type UpdateRailBookingCommand = Operations.Domain.Commands.RailBooking.UpdateRailBooking.UpdateRailBookingCommand;
type UpdateRailBookingResult = Operations.Domain.Commands.RailBooking.UpdateRailBooking.UpdateRailBookingResult;
type CreateRailBookingCommand = Operations.Domain.Commands.RailBooking.CreateRailBooking.CreateRailBookingCommand;
type CreateRailBookingResult = Operations.Domain.Commands.RailBooking.CreateRailBooking.CreateRailBookingResult;
type CancelRailBookingCommand = Operations.Domain.Commands.RailBooking.CancelRailBooking.CancelRailBookingCommand;
type RailBookingForDropdownItem = Operations.Domain.Queries.GetRailBookingsForDropdown.RailBookingForDropdownItem;
type JobsForCancellingRailBookingDto = Operations.Domain.Queries.GetJobsForCancellingRailBooking.JobsForCancellingRailBookingDto;

// tslint:disable-next-line:no-empty
const returnVoid = () => {};

export default function init(ajax: IAjax) {
  function createRailBooking(command: CreateRailBookingCommand) {
    return ajax
      .post(operationsUrls.railBookingUrls.createRailBooking(), command)
      .toPromise()
      .then<CreateRailBookingResult>(r => r.response);
  }

  function viewRailBooking(railBookingId: string) {
    return ajax
      .get(operationsUrls.railBookingUrls.viewRailBooking(railBookingId))
      .toPromise()
      .then<Operations.Domain.Queries.ViewRailBooking.RailBookingItem>(r => r.response);
  }

  function listRailBookings(
    query: Partial<Operations.Domain.Queries.ListRailBookings.ListRailBookingsQuery>
  ) {
    return ajax
      .get(operationsUrls.railBookingUrls.listRailBookings(query))
      .map(
        r =>
          r.response as Common.Dtos.ListResult<
            Operations.Domain.Queries.ListRailBookings.RailBookingListItem
          >
      );
  }

  function getRailBookingsForDropdown() {
    return ajax
      .get(operationsUrls.railBookingUrls.getRailBookingsForDropdown())
      .toPromise()
      .then<RailBookingForDropdownItem[]>(r => r.response);
  }

  function updateRailBooking(command: UpdateRailBookingCommand) {
    return ajax
      .put(operationsUrls.railBookingUrls.updateRailBooking(command.id), command)
      .toPromise()
      .then<UpdateRailBookingResult>(r => r.response);
  }

  function deleteRailBooking(railBookingId: string) {
    return ajax
      .httpDelete(operationsUrls.railBookingUrls.deleteRailBooking(railBookingId))
      .toPromise();
  }

  function completeRailBooking(railBookingId: string) {
    return ajax.post(operationsUrls.railBookingUrls.completeRailBooking(railBookingId)).toPromise();
  }

  function cancelRailBooking(command: CancelRailBookingCommand) {
    return ajax
      .post(operationsUrls.railBookingUrls.cancelRailBooking(command.railBookingId), command)
      .toPromise()
      .then(r => returnVoid());
  }

  function loadRailBookingContractTypes() {
    return ajax
      .get(operationsUrls.railBookingUrls.getRailBookingContractTypes())
      .toPromise()
      .then<Common.Dtos.RailBookingContractTypeDto[]>(r => r.response);
  }

  function loadJobsForCancellingRailBooking(railBookingId: string) {
    return ajax
      .get(operationsUrls.railBookingUrls.loadJobsForCancellingRailBooking(railBookingId))
      .toPromise()
      .then<JobsForCancellingRailBookingDto>(r => r.response);
  }

  function downloadAttachment(attachmentId: string, fileName: string) {
    return ajax
      .getFile(operationsUrls.railBookingUrls.downloadAttachment(attachmentId))
      .toPromise()
      .then(r => {
        saveAs(r.response, fileName);
      });
  }

  return {
    railBookings: {
      listRailBookings,
      createRailBooking,
      viewRailBooking,
      updateRailBooking,
      deleteRailBooking,
      completeRailBooking,
      cancelRailBooking,
      getRailBookingsForDropdown,
      loadRailBookingContractTypes,
      loadJobsForCancellingRailBooking,
      downloadAttachment,
    },
  };
}
