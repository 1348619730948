import { IRootStoreModel } from 'src/domain/entities/RootStoreModel';
import { UiRoute } from 'src/domain/services/buildUiRoutes';
import { UiRouteName } from 'src/domain/baseTypes';

export default function buildUiRoutes(store: IRootStoreModel) {
  const leaveCreate = new UiRoute(
    UiRouteName.peopleLeaveCreate,
    '/people/leaves/create',
    () => 'Create Leave',
    false
  );

  const leaveCalendar = new UiRoute(
    UiRouteName.peopleLeaveCalendar,
    '/people/leaves/calendar',
    () => 'Leave Calendar',
    false
  );

  const leaveManage = new UiRoute(
    UiRouteName.peopleLeaveManage,
    '/people/leaves/:id',
    () => 'Manage Leave',
    false
  );

  const leaveList = new UiRoute(
    UiRouteName.peopleLeaveList,
    '/people/leaves',
    () => 'Leave',
    false,
    true,
    [leaveCreate, leaveCalendar, leaveManage]
  );

  return {
    [UiRouteName.peopleLeaveList]: leaveList,
    [UiRouteName.peopleLeaveCreate]: leaveCreate,
    [UiRouteName.peopleLeaveCalendar]: leaveCalendar,
    [UiRouteName.peopleLeaveManage]: leaveManage,
  };
}
