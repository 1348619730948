import { Component } from 'react';
import { DateTime, LocaleOptions } from 'luxon';
import TimezoneFormat from './TimezoneFormat';

export const DateTimeFormatSettings: LocaleOptions & Intl.DateTimeFormatOptions = {
  weekday: 'short',
  month: 'short',
  day: '2-digit',
  year: 'numeric',
  hour: '2-digit',
  minute: '2-digit',
};

export interface IDateTimeFormatProps {
  value: DateTime | string | undefined;
  previousValue?: DateTime | string;
  timezone?: string;
  alwaysShowTimezone?: boolean;
}

class DateTimeFormat extends Component<IDateTimeFormatProps> {
  shouldComponentUpdate(nextProps: Readonly<IDateTimeFormatProps>) {
    const { value, previousValue } = this.props;
    return (
      !datesConsideredEqual(value, nextProps.value) ||
      !datesConsideredEqual(previousValue, nextProps.previousValue)
    );
  }

  render() {
    const { value, previousValue, timezone, alwaysShowTimezone } = this.props;
    if (!value) {
      return null;
    }

    const dtValue =
      typeof value === 'string'
        ? DateTime.fromISO(value, { zone: timezone || 'local' })
        : value.setZone(timezone || 'local');

    if (!dtValue.isValid) {
      return null;
    }

    const dtPreviousValue =
      typeof previousValue === 'string'
        ? DateTime.fromISO(previousValue, { zone: timezone || 'local' })
        : previousValue
        ? previousValue.setZone(timezone || 'local')
        : undefined;

    const formattedValue =
      dtPreviousValue && dtPreviousValue.isValid && dtValue.hasSame(dtPreviousValue, 'day')
        ? dtValue.toLocaleString(DateTime.TIME_SIMPLE)
        : dtValue.toLocaleString(DateTimeFormatSettings);

    return (
      <span>
        {formattedValue}{' '}
        <TimezoneFormat value={dtValue} blankForDefaultZone={!(alwaysShowTimezone ?? false)} />
      </span>
    );
  }
}
export default DateTimeFormat;

function datesConsideredEqual(a: DateTime | string | undefined, b: DateTime | string | undefined) {
  if (a === b || (!a && !b)) {
    return true;
  }
  if (!a || !b || isString(a)) {
    return false;
  }
  return !isString(b) && a.equals(b);
}

function isString(x: unknown): x is string {
  return typeof x === 'string';
}
