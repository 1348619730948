import { IAjax } from 'src/infrastructure/ajax';
import { peopleUrls } from 'src/domain/services/apiUrls';

type RoleItem = People.Domain.Queries.Role.RoleItem;
type StaffMemberNames = Common.Queries.GetStaffMemberNames.StaffMemberName[];
type UpdateRoleCommand = People.Domain.Commands.Role.UpdateRole.UpdateRoleCommand;
type RemoveStaffMemberFromRoleCommand = People.Domain.Commands.Role.RemoveRole.RemoveStaffMemberFromRoleCommand;
type AssignStaffMemberToRoleCommand = People.Domain.Commands.Role.AddRole.AssignStaffMemberToRoleCommand;
type AssignStaffMembersToRoleCommand = People.Domain.Commands.Role.AssignStaffMembersToRole.AssignStaffMembersToRoleCommand;

export default function init(ajax: IAjax) {
  function createRole(command: People.Domain.Commands.Role.CreateRole.CreateRoleCommand) {
    return ajax
      .post(peopleUrls.roleUrls.createRole(), command)
      .toPromise()
      .then<string>(r => r.response);
  }

  function checkForUniqueName(name: string) {
    return ajax
      .get(peopleUrls.roleUrls.checkForUniqueName(name))
      .toPromise()
      .then<Common.Dtos.UniqueNameCheckResultDto>(r => r.response);
  }

  function loadRoles(query: Partial<Common.Queries.People.ListRoles.ListRolesQuery>) {
    return ajax
      .get(peopleUrls.roleUrls.loadRoles(query))
      .map(r => r.response as Common.Dtos.ListResult<Common.Dtos.ListRoleItem>);
  }

  function getRoles() {
    return ajax
      .get(peopleUrls.roleUrls.getRoles())
      .toPromise()
      .then<Common.Dtos.ListRoleItem[]>(r => r.response);
  }

  function loadRole(roleId: string) {
    return ajax
      .get(peopleUrls.roleUrls.loadRole(roleId))
      .toPromise()
      .then<RoleItem>(r => r.response);
  }

  function listStaffMembers(roleId: string) {
    return ajax
      .get(peopleUrls.roleUrls.listStaffMembers(roleId))
      .toPromise()
      .then<StaffMemberNames>(r => r.response);
  }

  function updateRole(command: UpdateRoleCommand) {
    return ajax.put(peopleUrls.roleUrls.updateRole(command.id), command).toPromise();
  }

  function assignStaffMembersToRole(command: AssignStaffMembersToRoleCommand) {
    return ajax.put(peopleUrls.roleUrls.assignStaffMembers(command.roleId), command).toPromise();
  }

  function deleteRole(roleId: string) {
    return ajax.httpDelete(peopleUrls.roleUrls.deleteRole(roleId)).toPromise();
  }

  function assignRoleToStaffMember(command: AssignStaffMemberToRoleCommand) {
    return ajax
      .post(peopleUrls.roleUrls.assignStaffMember(command.staffMemberId), command)
      .toPromise();
  }

  function removeStaffMemberFromRole(command: RemoveStaffMemberFromRoleCommand) {
    return ajax
      .httpDelete(peopleUrls.roleUrls.removeRoleFromStaffMember(command.staffMemberId), command)
      .toPromise();
  }

  return {
    role: {
      createRole,
      checkForUniqueName,
      loadRoles,
      loadRole,
      updateRole,
      deleteRole,
      assignRoleToStaffMember,
      removeStaffMemberFromRole,
      listStaffMembers,
      assignStaffMembersToRole,
      getRoles,
    },
  };
}
