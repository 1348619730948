import { DateTime } from 'luxon';
import { ArrowDownIcon } from 'src/images/icons';
import styles from './ContinuationIndicator.module.scss';

export interface IContinuationIndicatorProps {
  isContinuation: boolean;
  jobStartDate: DateTime;
  currentDate: DateTime;
}

export const ContinuationIndicator = ({
  isContinuation,
  jobStartDate,
  currentDate,
}: IContinuationIndicatorProps) => {
  return (
    <>
      {isContinuation || jobStartDate < currentDate ? (
        <div className={styles.continuationIndicator}>
          <ArrowDownIcon></ArrowDownIcon>
          <p>{jobStartDate < currentDate ? 'Continues from yesterday' : 'Continuation'}</p>
          <ArrowDownIcon></ArrowDownIcon>
        </div>
      ) : null}
    </>
  );
};
