import { ShiftType } from 'src/api/enums';

export function isShiftTypeWithoutAsset(shiftTypeId: number): boolean {
  return (
    [
      ShiftType.AsRequired,
      ShiftType.Cleaning,
      ShiftType.Operations,
      ShiftType.Yard,
      ShiftType.Office,
    ].indexOf(shiftTypeId) !== -1
  );
}
