import { ICustomProperties, SeverityLevel } from '@microsoft/applicationinsights-web';
import { appInsights } from 'src/appInsights';
import { APP_INSIGHTS_INSTRUMENTATION_KEY } from 'src/appSettings';

// tslint:disable:no-any
export interface ILogger {
  fatal(error: Error, properties?: ICustomProperties | undefined): void;
  error(error: Error, properties?: ICustomProperties | undefined): void;
  warn(messageTemplate: string, properties?: ICustomProperties | undefined): void;
  info(messageTemplate: string, properties?: ICustomProperties | undefined): void;
  debug(messageTemplate: string, properties?: ICustomProperties | undefined): void;
  verbose(messageTemplate: string, properties?: ICustomProperties | undefined): void;
}
// tslint:enable:no-any
const appInsightsNotConfigured = !!!APP_INSIGHTS_INSTRUMENTATION_KEY;

const logger = {
  debug: (messageTemplate, properties) => {
    if (appInsightsNotConfigured) console.log(messageTemplate, properties);
    appInsights.trackTrace(
      { message: messageTemplate, severityLevel: SeverityLevel.Verbose },
      properties
    );
  },
  info: (messageTemplate, properties) => {
    if (appInsightsNotConfigured) console.log(messageTemplate, properties);
    return appInsights.trackTrace(
      { message: messageTemplate, severityLevel: SeverityLevel.Information },
      properties
    );
  },
  warn: (messageTemplate, properties) => {
    if (appInsightsNotConfigured) console.warn(messageTemplate, properties);
    return appInsights.trackTrace(
      { message: messageTemplate, severityLevel: SeverityLevel.Warning },
      properties
    );
  },
  error: (error, properties) => {
    if (appInsightsNotConfigured) console.error(error, properties);
    return appInsights.trackException(
      { exception: error, severityLevel: SeverityLevel.Error },
      properties
    );
  },
  fatal: (error, properties) => {
    if (appInsightsNotConfigured) console.error(error, properties);
    return appInsights.trackException(
      { exception: error, severityLevel: SeverityLevel.Critical },
      properties
    );
  },
} as ILogger;

export default logger;
