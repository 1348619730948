import { observer } from 'mobx-react';
import { useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { ChangeState } from 'src/api/enums';
import { useRootStore } from 'src/domain/entities/RootStoreModel';
import { IMatchingPart } from 'src/domain/entities/workshop/parts/PartsModel';
import {
  ChevronDownIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  ChevronUpIcon,
  EditIcon,
} from 'src/images/icons';
import { getActivationAndDeletionActionButtons } from 'src/views/components/ActivationAndDeletionActionsButtons/getActivationAndDeletionActionButtons';
import CrudPage, { CrudPageMode, ICrudPageDef } from 'src/views/components/Page/pages/CrudPage';
import { getSubmitCloseModalActionGroupDef } from 'src/views/definitionBuilders/common';
import {
  ActionType,
  FieldType,
  IHasChangeState,
  PagePrimarySize,
  PaneType,
  ShellModalSize,
} from 'src/views/definitionBuilders/types';
import PrintJobTaskChecklist from '../../jobs/listJobs/PrintJobSheet/PrintJobTaskChecklist/PrintJobTaskChecklist';

type ChecklistDetails = Workshop.Domain.Queries.GetChecklist.ChecklistDetails;
type SubchecklistSubchecklistItemDto = Workshop.Domain.Queries.GetChecklist.ChecklistDetails.SubchecklistSubchecklistItemDto;
type CreateChecklistCommand = Workshop.Domain.Commands.Checklist.CreateChecklist.CreateChecklistCommand;
type UpdateChecklistCommand = Workshop.Domain.Commands.Checklist.UpdateChecklist.UpdateChecklistCommand;
type SubchecklistDto = Workshop.Domain.Commands.Checklist.SubchecklistDto;
type PartListItem = Workshop.Domain.Queries.Parts.PartListItem;
type ChecklistAssetGroup = Workshop.Domain.Queries.GetChecklistAssetGroups.ChecklistAssetGroup;
type JobTaskItemSubchecklist = Workshop.Domain.Queries.Job.PrintJobQuery.JobTaskItemSubchecklist;
type JobTaskItemSubchecklistItem = Workshop.Domain.Queries.Job.PrintJobQuery.JobTaskItemSubchecklistItem;
enum SubchecklistType {
  Attach,
  Create,
}

enum SubchecklistItemType {
  Attach,
  Create,
}

export interface IMaintainChecklistProps {
  mode: CrudPageMode;
  route: RouteComponentProps<{ [x: string]: string | undefined }>;
}

const MaintainChecklist: React.FC<IMaintainChecklistProps> = observer(
  (props: IMaintainChecklistProps) => {
    const rootStore = useRootStore();
    const workshopModel = rootStore.workshop;
    const checklistModel = workshopModel.checklists;
    const checklist = checklistModel.checklist.item;
    useEffect(() => {
      checklistModel.subchecklists.load();
    }, []);

    const isUpdateMode = props.mode === 'update';
    const isCreateMode = props.mode === 'create';
    const checklistId = props.route.match.params.id!;

    const getCommandSubchecklists = (values: ChecklistDetails) => {
      const subchecklists: SubchecklistDto[] = values.subchecklists.map((list, listIdx) => ({
        id: list.subchecklist.id,
        name: list.subchecklist.name,
        subchecklistItems: list.subchecklist.subchecklistItems.map((item, idx) => ({
          id: item.subchecklistItem.id,
          description: item.subchecklistItem.description,
          quantity: item.subchecklistItem.quantity,
          partId: item.subchecklistItem.part ? item.subchecklistItem.part.id : undefined,
          order: idx,
          changeState: (item as IHasChangeState).changeState || ChangeState.Unknown,
        })),
        order: listIdx,
        changeState: (list as IHasChangeState).changeState || ChangeState.Unknown,
      }));
      return subchecklists;
    };

    const handlePreSubmitForCreate = (values: ChecklistDetails): CreateChecklistCommand => {
      return {
        name: values.name,
        subchecklists: getCommandSubchecklists(values),
      };
    };

    const handlePreSubmitForUpdate = (values: ChecklistDetails): UpdateChecklistCommand => {
      return {
        id: values.id,
        name: values.name,
        subchecklists: getCommandSubchecklists(values),
      };
    };

    const loadData = () => {
      const promises = [
        checklistModel.checklist.load(checklistId),
        checklistModel.checklistAssetGroups.load(checklistId),
        checklistModel.checklist.getChecklistUsage(checklistId),
      ];

      return Promise.all(promises).then(() => Promise.resolve());
    };

    const getPageDef = (updating: boolean): ICrudPageDef => {
      const notEditable = isUpdateMode && !updating;

      return {
        primarySize: PagePrimarySize.twoThirds,
        primarySection: {
          title: isUpdateMode ? 'Manage Checklist' : 'Create a Checklist',
          primaryActions:
            !updating && !isCreateMode
              ? [
                  {
                    actions: [
                      {
                        actionType: ActionType.actionCollection,
                        actionGroups: [
                          {
                            actions: [
                              {
                                actionType: ActionType.printActionButton,
                                label: 'Print Checklist',
                                hidden: !isUpdateMode || updating || !checklist,
                                printTitle: checklist && checklist.name,
                                printContent: () => {
                                  const jobCheckList = {
                                    id: checklist!.id,
                                    name: checklist!.name,
                                    subchecklists: checklist!.subchecklists.map(
                                      s =>
                                        ({
                                          id: s.subchecklist.id,
                                          name: s.subchecklist.name,
                                          items: s.subchecklist.subchecklistItems.map(
                                            r =>
                                              ({
                                                id: r.subchecklistItem.id,
                                                description: r.subchecklistItem.description,
                                                partNumber: r.subchecklistItem.part?.partNumber,
                                                quantity: r.subchecklistItem.quantity,
                                              } as JobTaskItemSubchecklistItem)
                                          ),
                                        } as JobTaskItemSubchecklist)
                                    ),
                                  };

                                  return (
                                    checklist && <PrintJobTaskChecklist checklist={jobCheckList} />
                                  );
                                },
                              },
                              ...getActivationAndDeletionActionButtons(
                                checklistId,
                                'Checklist',
                                checklist?.active,
                                checklistModel.checklist.setChecklistActive,
                                checklistModel.checklist.setChecklistInactive,
                                checklistModel.checklist.deleteChecklist,
                                checklistModel.checklist.checklistUsage,
                                checklistModel.checklist.load
                              ),
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ]
              : [],
          panels: [
            {
              panes: [
                {
                  paneType: PaneType.formFieldsPane,
                  fields: [
                    {
                      fieldType: FieldType.textField,
                      label: 'Name',
                      dataAddr: 'name',
                      maxLength: 255,
                      mandatory: true,
                      validateAsync: async d => {
                        if (
                          !d.fieldValue ||
                          (isUpdateMode &&
                            checklist &&
                            checklist.name.toUpperCase() === d.fieldValue.toUpperCase())
                        ) {
                          return undefined;
                        }
                        const result = await checklistModel.checkForUniqueChecklistName(
                          d.fieldValue
                        );
                        return result.nameExists ? `Name is already in use` : undefined;
                      },
                    },
                  ],
                },
              ],
            },
            {
              title: 'Subchecklists',
              dataAddr: 'subchecklists',
              panes: [
                {
                  paneType: PaneType.actionListPane,
                  hidden: notEditable,
                  actionGroups: [
                    {
                      actions: [
                        {
                          actionType: ActionType.addArrayItemActionButton,
                          label: 'Attach Subchecklist',
                          newItemData: { subchecklistType: SubchecklistType.Attach },
                        },
                        {
                          actionType: ActionType.addArrayItemActionButton,
                          label: 'Create Subchecklist',
                          newItemData: {
                            subchecklistType: SubchecklistType.Create,
                            subchecklist: { subchecklistItems: [] },
                          },
                        },
                      ],
                    },
                  ],
                },
                {
                  paneType: PaneType.repeatingTabPane,
                  getItemTitle: d => d.itemValue.subchecklist && d.itemValue.subchecklist.name,
                  mandatory: true,
                  deleteRemovedItems: true,
                  renderStyle: 'as-required',
                  allowReordering: true,
                  itemPanes: [
                    {
                      paneType: PaneType.actionListPane,
                      hidden: notEditable,
                      actionGroups: [
                        {
                          actions: [
                            {
                              actionType: ActionType.moveArrayItemActionButton,
                              label: 'Move up',
                              icon: <ChevronLeftIcon />,
                              moveDirection: 'prev',
                              hidden: d => (d.panelValue as Array<{}>).indexOf(d.parentValue) === 0,
                            },
                            {
                              actionType: ActionType.moveArrayItemActionButton,
                              label: 'Move down',
                              icon: <ChevronRightIcon />,
                              moveDirection: 'next',
                              hidden: d =>
                                (d.panelValue as Array<{}>).indexOf(d.parentValue) ===
                                (d.panelValue as Array<{}>).length - 1,
                            },
                            {
                              actionType: ActionType.modalActionButton,
                              label: 'Rename Subchecklist',
                              icon: <EditIcon />,
                              hidden: d =>
                                !d.actionValue.subchecklist ||
                                d.actionValue.subchecklistType === SubchecklistType.Create,
                              modalSize: ShellModalSize.oneThird,
                              modalDef: modalDefApi => ({
                                title: 'Rename Subchecklist',
                                asForm: true,
                                panels: [
                                  {
                                    panes: [
                                      {
                                        paneType: PaneType.formFieldsPane,
                                        fields: [
                                          {
                                            fieldType: FieldType.textField,
                                            label: 'Subchecklist Name',
                                            dataAddr: ['subchecklist', 'name'],
                                            maxLength: 255,
                                            mandatory: true,
                                            validateAsync: async d => {
                                              if (
                                                !d.fieldValue ||
                                                modalDefApi.actionData.actionValue.subchecklist.name.toUpperCase() ===
                                                  d.fieldValue.toUpperCase()
                                              ) {
                                                return undefined;
                                              }
                                              const result = await checklistModel.checkForUniqueChecklistName(
                                                d.fieldValue
                                              );
                                              return result.nameExists
                                                ? `Subchecklist Name is already in use`
                                                : undefined;
                                            },
                                          },
                                        ],
                                      },
                                    ],
                                  },
                                ],
                                secondaryActions: [getSubmitCloseModalActionGroupDef('Ok')],
                                onFormSubmit: v => {
                                  modalDefApi.parentFormApi.setValue(
                                    modalDefApi.parentFormApi.getFullField(),
                                    v
                                  );
                                  return Promise.resolve();
                                },
                              }),
                            },
                            {
                              actionType: ActionType.removeArrayItemActionButton,
                              label: 'Remove Subchecklist',
                            },
                          ],
                        },
                      ],
                    },
                    {
                      paneType: PaneType.formFieldsPane,
                      fields: [
                        {
                          fieldType: FieldType.textField,
                          label: 'Subchecklist Name',
                          dataAddr: ['subchecklist', 'name'],
                          maxLength: 255,
                          mandatory: true,
                          hidden: d => d.parentValue.subchecklistType !== SubchecklistType.Create,
                          validateAsync: async d => {
                            if (!d.fieldValue) {
                              return undefined;
                            }
                            const result = await checklistModel.checkForUniqueChecklistName(
                              d.fieldValue
                            );
                            return result.nameExists
                              ? `Subchecklist Name is already in use`
                              : undefined;
                          },
                        },
                        {
                          fieldType: FieldType.selectField,
                          label: 'Subchecklist Name',
                          dataAddr: 'subchecklist',
                          valueKey: 'id',
                          descriptionKey: 'name',
                          mandatory: true,
                          optionItems: checklistModel.subchecklists.items,
                          valuesToDisable: d =>
                            d.panelValue
                              // tslint:disable-next-line:no-any
                              .map((s: any) => s.subchecklist && s.subchecklist.id),
                          hidden: d => d.parentValue.subchecklistType !== SubchecklistType.Attach,
                          onChange: async api => {
                            const id = api.newFieldValue && api.newFieldValue.id;
                            if (id) {
                              // Load the which items are needed for this subchecklist
                              const checklistValue = api.formValues as ChecklistDetails;
                              const sclists = checklistValue.subchecklists;
                              const sclist = sclists.find(
                                l => l.subchecklist && l.subchecklist.id === id
                              );
                              if (!sclist) {
                                throw new Error('Cannot find current subchecklist');
                              }
                              const sclistIdx = sclists.indexOf(sclist);

                              const items = await checklistModel.subchecklistItems.getSubchecklistItemsForASubchecklist(
                                id
                              );

                              const newSubchecklist = {
                                ...sclist,
                                subchecklist: {
                                  ...sclist.subchecklist,
                                  subchecklistItems: items.map(item => ({
                                    subchecklistItem: item,
                                    order: 0,
                                  })),
                                },
                              };

                              const newSubchecklists = [...sclists];
                              newSubchecklists.splice(sclistIdx, 1, newSubchecklist);

                              const newValues = {
                                ...api.formValues,
                                subchecklists: newSubchecklists,
                              };

                              api.setFormValues(newValues);
                            }
                          },
                        },
                      ],
                    },
                    {
                      paneType: PaneType.nestingPane,
                      dataAddr: ['subchecklist', 'subchecklistItems'],
                      hidden: d =>
                        // Don't allow the items to be affected until the subchecklist to attach is picked
                        d.parentValue.subchecklistType === SubchecklistType.Attach &&
                        (!d.parentValue.subchecklist || !d.parentValue.subchecklist.id),
                      panes: [
                        {
                          paneType: PaneType.tablePane,
                          mandatory: true,
                          dataRequiredForRows: 'paneValue',
                          fields: [
                            {
                              fieldType: FieldType.selectAsyncField,
                              label: 'Subchecklist Item',
                              dataAddr: 'subchecklistItem',
                              mandatory: true,
                              valueKey: 'id',
                              descriptionKey: 'description',
                              loadOptionItems:
                                checklistModel.subchecklistItems.searchSubchecklistItems,
                              valuesToDisable: d =>
                                (d.paneValue as Array<SubchecklistSubchecklistItemDto>).map(
                                  s => s.subchecklistItem && s.subchecklistItem.id
                                ),
                            },
                            {
                              fieldType: FieldType.selectAsyncField,
                              label: 'Part Number',
                              dataAddr: ['subchecklistItem', 'part'],
                              valueKey: 'id',
                              descriptionKey: 'partNumber',
                              menuWidth: 'fitContent',
                              columnWidth: '12em',
                              mandatory: false,
                              loadOptionItems: (s, n: any) => workshopModel.parts.searchParts(s, n),
                              optionRenderer: (o: IMatchingPart) => (
                                <div style={{ whiteSpace: 'nowrap' }}>
                                  {o.partNumber} - {o.description}
                                </div>
                              ),
                              linkTo: d => `/workshop/parts/${d.fieldValue.id}`,
                              onChange: api => {
                                const selectedPart = api.newFieldValue as PartListItem;
                                if (
                                  (!api.fieldData.parentValue.subchecklistItem ||
                                    !api.fieldData.parentValue.subchecklistItem.description) &&
                                  selectedPart &&
                                  selectedPart.description
                                ) {
                                  const lineAddr = [...api.fieldDataAddr];
                                  lineAddr.pop();
                                  lineAddr.push('description');
                                  api.setFormValue(lineAddr, selectedPart.description);
                                }
                              },
                            },
                            {
                              fieldType: FieldType.numericField,
                              label: 'Quantity',
                              dataAddr: ['subchecklistItem', 'quantity'],
                              mandatory: false,
                              columnWidth: '7rem',
                            },
                            {
                              fieldType: FieldType.actionListField,
                              columnWidth: '1px',
                              nowrap: true,
                              actionGroups: [
                                {
                                  actions: [
                                    {
                                      actionType: ActionType.moveArrayItemActionButton,
                                      label: 'Move up',
                                      icon: <ChevronUpIcon />,
                                      moveDirection: 'prev',
                                      hidden: d =>
                                        notEditable ||
                                        (d.paneValue as Array<{}>).indexOf(d.parentValue) === 0,
                                    },
                                    {
                                      actionType: ActionType.moveArrayItemActionButton,
                                      label: 'Move down',
                                      icon: <ChevronDownIcon />,
                                      moveDirection: 'next',
                                      hidden: d =>
                                        notEditable ||
                                        (d.paneValue as Array<{}>).indexOf(d.parentValue) ===
                                          (d.paneValue as Array<{}>).length - 1,
                                    },
                                    {
                                      actionType: ActionType.actionCollection,
                                      hidden: notEditable,
                                      actionGroups: [
                                        {
                                          actions: [
                                            {
                                              actionType: ActionType.modalActionButton,
                                              label: 'Rename Item',
                                              icon: <EditIcon />,
                                              hidden: d =>
                                                !d.actionValue.subchecklistItem ||
                                                d.actionValue.subchecklistItemType ===
                                                  SubchecklistItemType.Create,
                                              modalSize: ShellModalSize.oneThird,
                                              modalDef: modalDefApi => ({
                                                title: 'Rename Item',
                                                asForm: true,
                                                panels: [
                                                  {
                                                    panes: [
                                                      {
                                                        paneType: PaneType.formFieldsPane,
                                                        fields: [
                                                          {
                                                            fieldType: FieldType.textField,
                                                            label: 'Subchecklist Item',
                                                            dataAddr: [
                                                              'subchecklistItem',
                                                              'description',
                                                            ],
                                                            maxLength: 255,
                                                            mandatory: true,
                                                          },
                                                        ],
                                                      },
                                                    ],
                                                  },
                                                ],
                                                secondaryActions: [
                                                  getSubmitCloseModalActionGroupDef('Ok'),
                                                ],
                                                onFormSubmit: v => {
                                                  modalDefApi.parentFormApi.setValue(
                                                    modalDefApi.parentFormApi.getFullField(),
                                                    v
                                                  );
                                                  return Promise.resolve();
                                                },
                                              }),
                                            },
                                          ],
                                        },
                                        {
                                          actions: [
                                            {
                                              actionType: ActionType.removeArrayItemActionButton,
                                              label: 'Remove Item',
                                            },
                                          ],
                                        },
                                      ],
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                          fieldRowOverrides: d => [
                            d.itemValue.subchecklistItemType === SubchecklistItemType.Create
                              ? {
                                  fieldType: FieldType.textField,
                                  label: 'Subchecklist Item',
                                  dataAddr: ['subchecklistItem', 'description'],
                                  maxLength: 255,
                                  mandatory: true,
                                }
                              : null,
                            null,
                          ],
                        },
                        {
                          paneType: PaneType.actionListPane,
                          hidden: notEditable,
                          actionGroups: [
                            {
                              actions: [
                                {
                                  actionType: ActionType.addArrayItemActionButton,
                                  label: 'Attach Item',
                                  newItemData: {
                                    subchecklistItemType: SubchecklistItemType.Attach,
                                  },
                                },
                                {
                                  actionType: ActionType.addArrayItemActionButton,
                                  label: 'Create Item',
                                  newItemData: {
                                    subchecklistItemType: SubchecklistItemType.Create,
                                  },
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
          onFormPreSubmit: isUpdateMode ? handlePreSubmitForUpdate : handlePreSubmitForCreate,
          onFormSubmit: isUpdateMode
            ? checklistModel.checklist.update
            : checklistModel.checklist.create,
        },
        secondarySections: [
          {
            title: 'Asset Groups',
            explicitData: getAssetGroupsData(),
            hidden: props.mode === 'create',
            isTab: true,
            panels: [
              {
                panes: [
                  {
                    paneType: PaneType.tablePane,
                    dataRequiredForRows: 'sectionValue',
                    neverEditable: true,
                    fields: [
                      {
                        fieldType: FieldType.textField,
                        dataAddr: 'description',
                        label: 'Name',
                        linkTo: d => {
                          const data = d.parentValue as ChecklistAssetGroup;
                          return `/workshop/asset-groups/${data.assetGroupId}`;
                        },
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      };
    };

    const getData = (): ChecklistDetails | undefined => {
      return !isUpdateMode || (checklist && checklist.id === checklistId) ? checklist : undefined;
    };

    const getAssetGroupsData = (): ChecklistAssetGroup[] | undefined => {
      return checklist && checklist.id === checklistId
        ? checklistModel.checklistAssetGroups.items
        : undefined;
    };

    const { mode } = props;
    return (
      <CrudPage
        def={({ updating }) => getPageDef(updating)}
        mode={mode}
        isEditingForbidden={!rootStore.account.isWorkshopDepartmentMember}
        data={getData()}
        onLoadData={loadData}
        createDefaultData={{ subchecklists: [] }}
      />
    );
  }
);

export default MaintainChecklist;
