import saveAs from 'file-saver';
import { DateTime } from 'luxon';
import { observer } from 'mobx-react';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { allTimesheetStatus, TimesheetStatus, timesheetStatusDescription } from 'src/api/enums';
import { useRootStore } from 'src/domain/entities/RootStoreModel';
import { CheckIcon, ExcelIcon } from 'src/images/icons';
import { DateFormat } from 'src/views/components/DateFormat';
import { DurationFormat } from 'src/views/components/DurationFormat';
import ListPage, { IListPageDef } from 'src/views/components/Page/pages/ListPage';
import TaskCardItem from 'src/views/components/TaskCard/TaskCardItem';
import { getSubmitCloseModalActionGroupDef } from 'src/views/definitionBuilders/common';
import {
  ActionType,
  FieldDefs,
  FieldType,
  PagePrimarySize,
  PaneType,
  ShellModalSize,
} from 'src/views/definitionBuilders/types';
import withQueryParams, { IQueryParamsProps } from 'src/views/hocs/withQueryParams';
import PrimaryTitle from 'src/views/components/Page/PrimaryTitle/PrimaryTitle';

type ListTimesheetsQuery = People.Domain.Queries.ListTimesheets.ListTimesheetsQuery;
type ListTimesheetItem = People.Domain.Queries.ListTimesheets.ListTimesheetItem;
type StaffMemberDto = Common.Dtos.StaffMemberDto;

const ListTimesheets: React.FC<IQueryParamsProps<ListTimesheetsQuery>> = observer(
  (props: IQueryParamsProps<ListTimesheetsQuery>) => {
    const rootStore = useRootStore();
    const peopleModel = rootStore.people;
    const timesheetModel = peopleModel.timesheets;
    const staffMembersModel = peopleModel.staffMembers;

    useEffect(() => {
      staffMembersModel.loadAllStaffMemberNames({});
      peopleModel.employmentStatuses.loadEmploymentStatuses();
      peopleModel.employmentContractCodes.loadEmploymentContractCodes();
      peopleModel.depots.loadStaffDepots();
      peopleModel.roles.loadAllRoles();
    }, []);

    const getFilterFieldDefs = () => {
      return {
        dateFrom: {
          fieldType: FieldType.dateField,
          label: 'Date From',
          dataAddr: 'dateFrom',
          onBlur: api => {
            api.validateField(['dateTo']);
          },
        } as FieldDefs,
        dateTo: {
          fieldType: FieldType.dateField,
          label: 'Date To',
          dataAddr: 'dateTo',
          validate: d => {
            if (!d.fieldValue || !d.parentValue.dateFrom) {
              return undefined;
            }
            const from = DateTime.fromISO(d.parentValue.dateFrom);
            const to = DateTime.fromISO(d.fieldValue);
            return from > to ? 'Date To cannot be earlier than Date From' : undefined;
          },
        } as FieldDefs,
        staffMemberIds: {
          fieldType: FieldType.selectMultiField,
          dataAddr: 'staffMemberIds',
          label: 'Staff Member',
          valueKey: 'id',
          descriptionKey: 'name',
          optionItems: staffMembersModel.allStaffMemberNames.slice(),
          useValueOnly: true,
        } as FieldDefs,
        timesheetStatuses: {
          fieldType: FieldType.selectMultiField,
          dataAddr: 'timesheetStatuses',
          label: 'Timesheet Status',
          valueKey: 'value',
          descriptionKey: 'description',
          optionItems: allTimesheetStatus.slice(),
          useValueOnly: true,
        } as FieldDefs,
        employmentStatuses: {
          fieldType: FieldType.selectMultiField,
          dataAddr: 'employmentStatuses',
          label: 'Employment Status',
          valueKey: 'id',
          descriptionKey: 'description',
          optionItems: peopleModel.employmentStatuses.employmentStatuses.slice(),
          useValueOnly: true,
        } as FieldDefs,
        isWorkshopStaff: {
          fieldType: FieldType.yesNoField,
          dataAddr: 'isWorkshopStaff',
          label: 'Workshop Staff',
          useValueOnly: true,
        } as FieldDefs,
        depots: {
          fieldType: FieldType.selectMultiField,
          dataAddr: 'depotIds',
          label: 'Depot',
          valueKey: 'id',
          descriptionKey: 'description',
          optionItems: peopleModel.depots.staffDepots.slice(),
          useValueOnly: true,
        } as FieldDefs,
        employmentContractCodes: {
          fieldType: FieldType.selectMultiField,
          dataAddr: 'employmentContractCodes',
          label: 'Employment Contract Code',
          valueKey: 'id',
          descriptionKey: 'description',
          optionItems: peopleModel.employmentContractCodes.employmentContractCodes.slice(),
          useValueOnly: true,
        } as FieldDefs,
        roles: {
          fieldType: FieldType.selectMultiField,
          dataAddr: 'roleIds',
          label: 'Roles',
          valueKey: 'id',
          descriptionKey: 'name',
          optionItems: peopleModel.roles.roles.slice(),
          useValueOnly: true,
        } as FieldDefs,
      };
    };

    const getPageDef = (): IListPageDef => {
      const filterFieldDefsLookup = getFilterFieldDefs();
      return {
        primaryTitle: <PrimaryTitle title="Timesheets"></PrimaryTitle>,
        primarySize: PagePrimarySize.full,
        onLoadData: timesheetModel.listItems,
        hasMoreData: timesheetModel.hasMoreItems,
        externalSearch: true,
        createLink: TaskCardItem.people.createTimesheet,
        rowKey: data => {
          return data.itemValue.id;
        },
        primaryFields: [
          {
            fieldType: FieldType.readonlyField,
            dataAddr: 'weekStart',
            label: 'Week',
            linkTo: d => `/people/timesheets/${d.parentValue.id}`,
            columnWidth: '10em',
            formatReadonly: data => {
              var timesheetItem = data.parentValue as ListTimesheetItem;
              return (
                <>
                  <DateFormat value={timesheetItem.weekStart} /> -{' '}
                  <DateFormat value={timesheetItem.weekEnd} />
                </>
              );
            },
          },
          {
            fieldType: FieldType.readonlyField,
            dataAddr: 'staffMember',
            label: 'Staff member',
            formatReadonly: data => {
              var staffMember = data.fieldValue as StaffMemberDto;
              return (
                <div>
                  <Link to={`/people/staff-members/${staffMember.id}`}>{staffMember.name}</Link>
                </div>
              );
            },
            columnWidth: '10em',
          },
          {
            fieldType: FieldType.readonlyField,
            dataAddr: 'status',
            label: 'Status',
            formatReadonly: data => timesheetStatusDescription(data.fieldValue),
            columnWidth: '1em',
          },
          {
            fieldType: FieldType.readonlyField,
            dataAddr: 'totalWorkedHours',
            label: 'Total worked hours',
            formatReadonly: d => <DurationFormat value={d.fieldValue} />,
            columnWidth: '6em',
          },
          {
            fieldType: FieldType.readonlyField,
            dataAddr: 'totalLeaveHours',
            label: 'Total leave hours',
            formatReadonly: d => <DurationFormat value={d.fieldValue} />,
            columnWidth: '6em',
          },
          {
            fieldType: FieldType.readonlyField,
            dataAddr: 'totalPaidHours',
            label: 'Total paid hours',
            formatReadonly: d => <DurationFormat value={d.fieldValue} />,
            columnWidth: '6em',
          },
        ],
        filterAction: {
          defaultValues: {
            timesheetStatuses: [TimesheetStatus.Submitted],
          },
          filterSize: ShellModalSize.oneQuarter,
          filterFields: Object.keys(filterFieldDefsLookup).map(k => filterFieldDefsLookup[k]),
          filterDef: filterDefApi => [
            {
              panes: [
                {
                  paneType: PaneType.formFieldsPane,
                  columnCount: 2,
                  fields: [filterFieldDefsLookup.dateFrom, filterFieldDefsLookup.dateTo],
                },
                {
                  paneType: PaneType.formFieldsPane,
                  columnCount: 1,
                  fields: [
                    filterFieldDefsLookup.staffMemberIds,
                    filterFieldDefsLookup.employmentStatuses,
                    filterFieldDefsLookup.timesheetStatuses,
                    filterFieldDefsLookup.isWorkshopStaff,
                    filterFieldDefsLookup.depots,
                    filterFieldDefsLookup.employmentContractCodes,
                    filterFieldDefsLookup.roles,
                  ],
                },
              ],
            },
          ],
        },
        primaryActions: [
          {
            actions: [
              {
                actionType: ActionType.actionCollection,
                actionGroups: [
                  {
                    actions: [
                      {
                        actionType: ActionType.printActionButton,
                        label: 'Print Timesheets',
                        loadDataAsync: () =>
                          timesheetModel.getTimesheetsForPrinting(props.getQueryParams()),
                        printContent: timesheetModel.timesheetsForPrinting,
                      },
                      {
                        actionType: ActionType.modalActionButton,
                        label: 'Process Timesheets',
                        icon: <CheckIcon />,
                        modalSize: ShellModalSize.oneQuarter,
                        modalDef: modalDefApi => ({
                          title: 'Process Timesheets',
                          asForm: true,
                          panels: [
                            {
                              panes: [
                                {
                                  paneType: PaneType.customPane,
                                  render: () => (
                                    <span>
                                      Are you sure you want to process selected timesheets?
                                    </span>
                                  ),
                                },
                              ],
                            },
                          ],
                          secondaryActions: [getSubmitCloseModalActionGroupDef('Ok')],
                          onFormSubmit: () =>
                            timesheetModel.processTimesheets(props.getQueryParams()),
                        }),
                      },
                    ],
                  },
                  {
                    actions: [
                      {
                        actionType: ActionType.actionButton,
                        label: 'Export Timesheets to Excel',
                        icon: <ExcelIcon />,
                        onClick: data => {
                          const fileName = `Timesheets_${DateTime.local().toFormat(
                            'yyyyMMddHHmm'
                          )}.xlsx`;
                          const query = props.getQueryParams();
                          return timesheetModel
                            .exportTimesheets(query)
                            .then(blob => saveAs(blob, fileName));
                        },
                      },
                      {
                        actionType: ActionType.actionButton,
                        label: 'Export Detailed Timesheets to Excel',
                        icon: <ExcelIcon />,
                        onClick: data => {
                          const fileName = `Detailed_Timesheets_${DateTime.local().toFormat(
                            'yyyyMMddHHmm'
                          )}.xlsx`;
                          const query = props.getQueryParams();
                          return timesheetModel
                            .exportDetailedTimesheets(query)
                            .then(blob => saveAs(blob, fileName));
                        },
                      },
                      {
                        actionType: ActionType.actionButton,
                        label: 'Export Timesheet Notes to Excel',
                        icon: <ExcelIcon />,
                        onClick: data => {
                          const fileName = `TimesheetNotes_${DateTime.local().toFormat(
                            'yyyyMMddHHmm'
                          )}.xlsx`;
                          const query = props.getQueryParams();
                          return timesheetModel
                            .exportTimesheetNotes(query)
                            .then(blob => saveAs(blob, fileName));
                        },
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      };
    };

    return (
      <ListPage
        className="list-timesheets-component"
        data={timesheetModel.items}
        def={getPageDef()}
      />
    );
  }
);

export default withQueryParams(ListTimesheets);
