import { IAjax } from 'src/infrastructure/ajax';
import { operationsUrls } from 'src/domain/services/apiUrls';

export default function init(ajax: IAjax) {
  async function createCharterContract(
    command: Operations.Domain.Commands.CharterContract.CreateCharterContract.CreateCharterContractCommand
  ) {
    const result = await ajax
      .post(operationsUrls.charterContractUrls.createCharterContract(), command)
      .toPromise();

    return result.response as string;
  }

  function updateCharterContract(
    command: Operations.Domain.Commands.CharterContract.UpdateCharterContract.UpdateCharterContractCommand
  ) {
    return ajax
      .put(operationsUrls.charterContractUrls.updateCharterContract(command.id), command)
      .toPromise();
  }

  async function viewCharterContract(id: string) {
    const result = await ajax
      .get(operationsUrls.charterContractUrls.getCharterContract(id))
      .toPromise();

    return result.response as Operations.Domain.Queries.GetCharterContract.CharterContractItem;
  }

  async function listCharterContracts() {
    const result = await ajax
      .get(operationsUrls.charterContractUrls.listCharterContracts())
      .toPromise();

    return result.response as Operations.Domain.Queries.ListCharterContracts.CharterContractItem[];
  }

  function getCharterContractsByCustomer(customerId: string) {
    return ajax
      .get(operationsUrls.charterContractUrls.getCharterContractsByCustomer(customerId))
      .toPromise()
      .then(
        r =>
          r.response as Operations.Domain.Queries.GetCharterContractByCustomer.CharterContractItem[]
      );
  }

  async function findCharterContracts(ids: string[], customerId?: string) {
    const result = await ajax
      .get(operationsUrls.charterContractUrls.findCharterContracts(ids, customerId))
      .toPromise();

    return result.response as Common.Dtos.ListResult<
      Operations.Domain.Queries.FindCharterContracts.CharterContractItem
    >;
  }

  async function searchCharterContracts(search: string, customerId?: string) {
    const result = await ajax
      .get(operationsUrls.charterContractUrls.searchCharterContracts(search, customerId))
      .toPromise();

    return result.response as Common.Dtos.ListResult<
      Operations.Domain.Queries.SearchCharterContracts.CharterContractItem
    >;
  }

  function checkForUniqueName(name: string) {
    return ajax
      .get(operationsUrls.charterContractUrls.checkForUniqueName(name))
      .toPromise()
      .then<Common.Dtos.UniqueNameCheckResultDto>(r => r.response);
  }

  return {
    charterContract: {
      createCharterContract,
      updateCharterContract,
      viewCharterContract,
      listCharterContracts,
      getCharterContractsByCustomer,
      findCharterContracts,
      searchCharterContracts,
      checkForUniqueName,
    },
  };
}
