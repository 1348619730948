import saveAs from 'file-saver';
import { DateTime } from 'luxon';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { useRootStore } from 'src/domain/entities/RootStoreModel';
import { CheckIcon, EmailIcon, ExcelIcon, FilePdfIcon } from 'src/images/icons';
import deepEqual from 'src/infrastructure/deepEqual';
import ActionListPageField from 'src/views/components/Page/fields/ActionListPageField';
import CrudPage, { CrudPageMode, ICrudPageDef } from 'src/views/components/Page/pages/CrudPage';
import {
  ActionType,
  FieldType,
  PagePrimarySize,
  PaneType,
  ShellModalSize,
} from 'src/views/definitionBuilders/types';
import getProcessModalDef from './getProcessModalDef';

type CreateCubicCommand = Workshop.Domain.Commands.AssetCubic.CreateAssetCubicCommand;
type AssetCubicSummary = Workshop.Domain.Queries.AssetCubic.AssetCubicSummary;
type JobTaskListItem = Workshop.Domain.Queries.JobTask.JobTaskListItem;

export interface ICubicRegisterProps {
  mode: CrudPageMode;
  route: RouteComponentProps<{ [x: string]: string | undefined }>;
}

const CubicRegister: React.FC<ICubicRegisterProps> = observer((props: ICubicRegisterProps) => {
  const rootStore = useRootStore();
  const canManageCubicRegister = rootStore.account.isWorkshopDepartmentMember;
  const asset = rootStore.asset.asset;
  const assetCubicItems = rootStore.assetCubicRegister.assetCubicItems.slice();
  const assetCubicSummary = rootStore.assetCubicRegister.assetCubicSummary;
  const onLoadAsset = rootStore.asset.loadAsset;
  const onLoadAssetCubicItems = rootStore.assetCubicRegister.loadAssetCubicItems;
  const onLoadAssetCubicSummary = rootStore.assetCubicRegister.loadAssetCubicSummary;
  const onCreateAssetCubicItem = rootStore.assetCubicRegister.createAssetCubicItem;
  const onUpdateAssetCubicItem = rootStore.assetCubicRegister.updateAssetCubicItem;
  const getJobTasksForAsset = rootStore.jobTask.getJobTasksForAsset;
  const generateExcel = rootStore.assetCubicRegister.generateExcel;
  const generatePdfForm = rootStore.assetCubicRegister.generatePdfForm;
  const cubicFaultDetails = rootStore.assetCubicRegister.cubicFaultDetails.slice();
  const activeCubicFaultDetails = rootStore.assetCubicRegister.activeCubicFaultDetails.slice();
  const onLoadCubicFaultDetails = rootStore.assetCubicRegister.loadCubicFaultDetails;
  const cubicItems = rootStore.assetCubicRegister.cubicItems.slice();
  const onLoadCubicItems = rootStore.assetCubicRegister.loadCubicItems;

  const [jobTasks, setJobTasks] = useState<JobTaskListItem[]>([]);

  const assetId = props.route.match.params.id!;

  useEffect(() => {
    onLoadAsset(assetId);
    onLoadAssetCubicItems(assetId);
    onLoadAssetCubicSummary(assetId);
    onLoadCubicFaultDetails();
    onLoadCubicItems();
  }, []);

  const getData = () => {
    return { asset, assetCubicItems, assetCubicSummary };
  };

  const handlePreSubmit = (a: { assetCubicSummary: AssetCubicSummary }): CreateCubicCommand => {
    const originalItems = assetCubicSummary ? assetCubicSummary.items : [];

    return {
      assetId: assetId,
      changes: a.assetCubicSummary.items
        .filter(i => !deepEqual(originalItems.filter(o => o.cubicItemId === i.cubicItemId)[0], i))
        .map(i => ({
          ...i,
        })),
    };
  };

  const downloadExcel = () => {
    const date = DateTime.local().toFormat('yyyyMMddHHmm');
    return generateExcel(assetId).then(r => {
      saveAs(r, `Asset_${asset ? asset.name : ''}_Cubic_Report_${date}.xlsx`);
    });
  };

  const downloadPdf = (assetCubicId: number) => {
    const date = DateTime.local().toFormat('yyyyMMddHHmm');
    return generatePdfForm(assetId, assetCubicId).then(r => {
      saveAs(r, `Asset_${asset ? asset.name : ''}_Translink_Form_${date}.pdf`);
    });
  };

  const getPageDef = (mode: CrudPageMode, updating: boolean): ICrudPageDef => {
    const assetName = asset && asset.name;
    return {
      primarySize: PagePrimarySize.twoThirds,
      primarySection: {
        title: `${assetName || ''} - Cubic Register`,
        onFormPreSubmit: handlePreSubmit,
        onFormSubmit: onCreateAssetCubicItem,
        primaryActions: [
          {
            actions: [
              {
                actionType: ActionType.actionCollection,
                hidden: !assetCubicSummary,
                actionGroups: [
                  {
                    actions: [
                      {
                        actionType: ActionType.actionButton,
                        label: 'Export To Excel',
                        icon: <ExcelIcon fixedWidth />,
                        onClick: () => downloadExcel(),
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
        panels: [
          {
            panes: [
              {
                paneType: PaneType.tablePane,
                dataAddr: ['assetCubicSummary', 'items'],
                fields: [
                  {
                    fieldType: FieldType.selectField,
                    dataAddr: 'cubicItemId',
                    label: 'Name',
                    readonly: true,
                    valueKey: 'id',
                    descriptionKey: 'description',
                    optionItems: cubicItems,
                    useValueOnly: true,
                  },
                  {
                    fieldType: FieldType.textField,
                    dataAddr: 'serialNumber',
                    label: 'Serial Number',
                    mandatory: false,
                    maxLength: 200,
                  },
                  {
                    fieldType: FieldType.selectField,
                    label: 'Fault Details/Reason',
                    dataAddr: 'cubicFaultDetailId',
                    valueKey: 'id',
                    useValueOnly: true,
                    mandatory: d => d.parentValue.serialNumber,
                    descriptionKey: 'description',
                    menuWidth: 'fitContent',
                    optionItems: activeCubicFaultDetails,
                  },
                  {
                    fieldType: FieldType.selectField,
                    label: 'Task',
                    dataAddr: 'jobTaskId',
                    valueKey: 'id',
                    useValueOnly: true,
                    mandatory: false,
                    descriptionKey: 'jobTaskNumber',
                    linkTo: d => `/workshop/tasks/${d.parentValue.jobTaskId}`,
                    menuWidth: 'fitContent',
                    optionItems: jobTasks,
                    optionRenderer: (o: JobTaskListItem) => (
                      <div style={{ whiteSpace: 'nowrap' }}>
                        <strong>{o.jobTaskNumber}</strong>
                        <small>
                          &nbsp; • {o.category.description} • {o.status.description}
                        </small>
                      </div>
                    ),
                  },
                  {
                    fieldType: FieldType.actionListField,
                    columnWidth: '85px',
                    label: 'Form',
                    actionGroups: [
                      {
                        actions: [
                          {
                            actionType: ActionType.actionButton,
                            icon: (
                              <span>
                                PDF <FilePdfIcon />
                              </span>
                            ),
                            label: 'Translink PDF Form',
                            hidden: d => !d.parentValue.serialNumber,
                            onClick: d => downloadPdf(d.parentValue.assetCubicId),
                          },
                        ],
                      },
                    ],
                  },
                  {
                    fieldType: FieldType.customField,
                    label: 'Process',
                    dataAddr: 'formSent',
                    columnWidth: '100px',
                    render: d => {
                      return !d.data.parentValue.serialNumber ? null : !!d.data.fieldValue ? (
                        <span>
                          Sent <CheckIcon />
                        </span>
                      ) : (
                        <ActionListPageField
                          fieldDef={{
                            fieldType: FieldType.actionListField,
                            nowrap: true,
                            actionGroups: [
                              {
                                actions: [
                                  {
                                    actionType: ActionType.modalActionButton,
                                    label: 'Process',
                                    icon: (
                                      <span>
                                        <EmailIcon />
                                      </span>
                                    ),

                                    modalSize: ShellModalSize.oneThird,
                                    modalDef: getProcessModalDef(
                                      d.data.parentValue,
                                      cubicItems,
                                      assetCubicSummary!.assetName,
                                      () =>
                                        onUpdateAssetCubicItem({
                                          assetId: assetId,
                                          cubicChangeToBeProcessed: d.data.parentValue
                                            .assetCubicId as number,
                                        })
                                    ),
                                  },
                                ],
                              },
                            ],
                          }}
                          fieldMeta={d.meta}
                          fieldData={d.data}
                        />
                      );
                    },
                  },
                ],
              },
            ],
          },
          {
            panes: [
              {
                paneType: PaneType.tablePane,
                dataAddr: 'assetCubicItems',
                title: 'Update History',
                neverEditable: true,
                fields: [
                  {
                    fieldType: FieldType.selectField,
                    dataAddr: 'cubicItemId',
                    label: 'Name',
                    readonly: true,
                    valueKey: 'id',
                    descriptionKey: 'description',
                    optionItems: cubicItems,
                    useValueOnly: true,
                  },
                  {
                    fieldType: FieldType.textField,
                    dataAddr: 'serialNumber',
                    label: 'Serial Number',
                    mandatory: false,
                    maxLength: 200,
                    readonly: true,
                  },
                  {
                    fieldType: FieldType.selectField,
                    label: 'Fault Details/Reason',
                    dataAddr: 'cubicFaultDetailId',
                    valueKey: 'id',
                    useValueOnly: true,
                    mandatory: false,
                    descriptionKey: 'description',
                    menuWidth: 'fitContent',
                    optionItems: cubicFaultDetails,
                    readonly: true,
                  },
                  {
                    fieldType: FieldType.selectField,
                    label: 'Task',
                    dataAddr: 'jobTask',
                    valueKey: 'id',
                    mandatory: false,
                    descriptionKey: 'jobTaskNumber',
                    linkTo: d => `/workshop/tasks/${d.parentValue.jobTask.id}`,
                    menuWidth: 'fitContent',
                    optionItems: jobTasks,
                    readonly: true,
                    optionRenderer: (o: JobTaskListItem) => (
                      <div style={{ whiteSpace: 'nowrap' }}>
                        <strong>{o.jobTaskNumber}</strong>
                        <small>
                          &nbsp; • {o.category.description} • {o.status.description}
                        </small>
                      </div>
                    ),
                  },
                  {
                    fieldType: FieldType.textField,
                    dataAddr: 'reportedBy',
                    label: 'Reported By',
                    mandatory: false,
                    maxLength: 200,
                    readonly: true,
                  },
                  {
                    fieldType: FieldType.dateField,
                    dataAddr: 'formSentOn',
                    label: 'Form Sent On',
                    mandatory: false,
                    readonly: true,
                  },
                  {
                    fieldType: FieldType.textField,
                    dataAddr: 'formSentBy',
                    label: 'Form Sent By',
                    mandatory: false,
                    maxLength: 200,
                    readonly: true,
                  },
                ],
              },
            ],
          },
        ],
      },
    };
  };

  const loadData = () => {
    return Promise.all([
      onLoadAsset(assetId),
      getJobTasksForAsset(assetId).then(r => {
        setJobTasks(r);
      }),
    ]).then(r => undefined);
  };

  const mode = 'update';
  return (
    <CrudPage
      def={({ updating }) => getPageDef(mode, updating)}
      mode={mode}
      isEditingForbidden={!canManageCubicRegister}
      onLoadData={loadData}
      data={getData()}
    />
  );
});

export default CubicRegister;
