import { types, getRoot, flow } from 'mobx-state-tree';
import { getAjax } from 'src/domain/services';
import { IRootStoreModel } from 'src/domain/entities/RootStoreModel';

type CreateEquipmentTypeCommand = People.Domain.Commands.EquipmentType.CreateEquipmentType.CreateEquipmentTypeCommand;
type UpdateEquipmentTypeCommand = People.Domain.Commands.EquipmentType.UpdateEquipmentType.UpdateEquipmentTypeCommand;
type EquipmentTypeItem = People.Domain.Queries.EquipmentType.EquipmentTypeItem;

export const EquipmentTypeModel = types
  .model('EquipmentTypeModel', {
    equipmentType: types.maybe(types.frozen<EquipmentTypeItem>()),
  })
  .actions(self => {
    const ajax = getAjax(self);
    const root = getRoot(self) as IRootStoreModel;

    const createEquipmentType = flow(function*(command: CreateEquipmentTypeCommand) {
      const id = yield ajax.people.createEquipmentType(command);
      root.history.push(`/people/equipment-types/${id}`);
    });

    const loadEquipmentType = flow(function*(equipmentTypeId: number) {
      self.equipmentType = yield ajax.people.getEquipmentType(equipmentTypeId);
    });

    const updateEquipmentType = flow(function*(command: UpdateEquipmentTypeCommand) {
      yield ajax.people.updateEquipmentType(command);
      yield loadEquipmentType(command.id);
    });

    const deleteEquipmentType = flow(function*(equipmentTypeId: number) {
      yield ajax.people.deleteEquipmentType(equipmentTypeId);
      root.history.push(`/people/equipment-types?defaultFilter=true`);
    });

    const checkForUniqueDescription = flow(function*(description: string) {
      return yield ajax.people.checkForUniqueDescription(description);
    });

    return {
      createEquipmentType,
      checkForUniqueDescription,
      loadEquipmentType,
      updateEquipmentType,
      deleteEquipmentType,
    };
  });
