import SlideInScreen from '../components/layout/SlideInScreen/SlideInScreen';
import RunSheet from '../Runsheet/RunsheetContainer';
import { SignInIcon } from 'src/images/icons';
import styles from './RunsheetSlider.module.scss';
import { DateTime } from 'luxon';
import { SingleClickAwareButton } from '../../components/presentational/SingleClickAwareButton/SingleClickAwareButton';

type WorkshopDepotDto = Common.Queries.Workshop.GetWorkshopDepots.WorkshopDepotDto;

export interface IRunsheetSliderProps {
  deviceDepot?: WorkshopDepotDto;
  toggleSlider: () => void;
  isOpen: boolean;
  width: number;
  date?: DateTime;
}

export const RunsheetSlider: React.FC<IRunsheetSliderProps> = ({
  deviceDepot,
  toggleSlider,
  isOpen,
  width,
  date,
}) =>
  deviceDepot ? (
    <>
      <SlideInScreen
        className={styles.runsheetSlider}
        click={toggleSlider}
        show={isOpen}
        isRightAligned
        width={width}>
        <RunSheet date={date} defaultKioskDepotId={deviceDepot.id} />
      </SlideInScreen>
      <SingleClickAwareButton className={styles.runsheet} onClick={toggleSlider}>
        <span>Runsheet</span>
        <SignInIcon flip={'horizontal'} />
      </SingleClickAwareButton>
    </>
  ) : (
    <div />
  );

export default RunsheetSlider;
