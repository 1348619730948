import { buildListPageApiSearchModelTypedQuery } from 'src/domain/modelBuilders/buildListPageApiSearchModel';
import * as queryString from 'query-string';
import { types, getRoot, flow } from 'mobx-state-tree';
import { getAjax } from 'src/domain/services';
import { NotificationType } from 'src/domain';
import { IRootStoreModel } from 'src/domain/entities/RootStoreModel';

const CctvRequestListModel = buildListPageApiSearchModelTypedQuery<
  Operations.Domain.Queries.ListCctvRequests.ListCctvRequestsQuery
>()('CctvRequestListModel', d =>
  d.ajax.raw
    .get(`/api/compliance/cctv-requests?${queryString.stringify(d.query)}`)
    .map(
      r =>
        r.response as Common.Dtos.ListResult<
          Operations.Domain.Queries.ListCctvRequests.CctvRequestListItem
        >
    )
);

const ExportCctvRequestModel = types.model('ExportCctvRequestModel').actions(self => {
  const ajax = getAjax(self);
  const root = getRoot(self) as IRootStoreModel;

  const exportToExcel = flow(function*(
    query: Partial<Operations.Domain.Queries.ListCctvRequests.ListCctvRequestsQuery>
  ) {
    root.notifications.addNotification(`The file is being generated ...`, {
      type: NotificationType.info,
    });

    return yield ajax.raw
      .getFile(`/api/compliance/cctv-requests/excel?${queryString.stringify(query)}`)
      .toPromise()
      .then<Blob>(r => r.response);
  });

  return {
    exportToExcel,
  };
});

export const ListCctvRequestModel = types.compose(CctvRequestListModel, ExportCctvRequestModel);
