import * as signalR from '@microsoft/signalr';

function createHubConnection() {
  let hubConnection = new signalR.HubConnectionBuilder()
    .withUrl('/api/notifications')
    .withHubProtocol(new signalR.JsonHubProtocol())
    .configureLogging(signalR.LogLevel.Debug)
    .withAutomaticReconnect()
    .build();

  return hubConnection;
}
export default createHubConnection;
