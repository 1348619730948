import './LeaveLegend.scss';
import { allLeaveType } from 'src/api/enums';
import cn from 'classnames';

interface ILeaveLegendProps {}

const LeaveLegend: React.FC<ILeaveLegendProps> = () => {
  return (
    <div className="leave-legend-component">
      <div className="title">Leave Legend</div>
      <div className="types">
        {allLeaveType.map(leaveType => {
          return (
            <div key={leaveType.value} className="leave-type">
              <span className={cn('leave-box', leaveType.name)} /> {leaveType.description}
            </div>
          );
        })}
        <div className="leave-type">
          <span className={cn('leave-box', 'pending')} /> Pending
        </div>
      </div>
    </div>
  );
};

export default LeaveLegend;
