import { FieldName } from './base';
import { flatten } from 'src/infrastructure/arrayUtils';

export function fieldNameAsKey(field: FieldName) {
  if (!Array.isArray(field)) {
    return field.toString();
  }
  return field.map(i => i.toString()).reduce((acc, i) => (acc ? acc + '.' + i : i), '');
}

const defaultTouched = Symbol();

export interface ITouchTree {
  [key: string]: true | ITouchTree | undefined;
  [defaultTouched]?: boolean;
}

export function getTouched(tree: true | ITouchTree, path: Array<string | number>): boolean {
  if (!path.length) {
    return tree === true || !!tree[defaultTouched];
  }
  if (tree === true) {
    return true;
  }
  const pathItem = path[0].toString();
  return getTouched(tree[pathItem] || {}, path.slice(1));
}

export function setTouched(
  tree: true | ITouchTree,
  path: Array<string | number>,
  touched: boolean
): true | ITouchTree {
  if (!path.length) {
    if (touched === true) {
      return true;
    } else {
      return {};
    }
  }

  const pathItem = path[0].toString();

  if (tree === true) {
    if (touched === true) {
      return tree;
    } else {
      return {
        [pathItem]: setTouched(tree[pathItem], path.slice(1), touched),
        [defaultTouched]: true,
      };
    }
  }

  return {
    ...tree,
    [pathItem]: setTouched(tree[pathItem] || {}, path.slice(1), touched),
  };
}

// -- FROM REACT-FORM --

function isObject(a: unknown) {
  return !Array.isArray(a) && typeof a === 'object' && a !== null;
}

export function makePathArray(obj: unknown) {
  let path: Array<string | number> = [];
  const flat = Array.isArray(obj) ? flatten(obj) : [obj];
  flat.forEach(part => {
    if (typeof part === 'string') {
      path = path.concat(
        part
          .replace(/\[(\d*)\]/gm, '.__int__$1')
          .replace('[', '.')
          .replace(']', '')
          .split('.')
          .map(d => {
            if (d.indexOf('__int__') === 0) {
              return parseInt(d.substring(7), 10);
            }
            return d;
          })
      );
    } else {
      path.push(part);
    }
  });
  return path.filter(d => d !== undefined);
}

export function set(
  // tslint:disable-next-line:no-any
  obj: any = {},
  path: FieldName | undefined,
  value: unknown,
  deleteWhenFalsey?: boolean
) {
  if (!path) {
    return value;
  }
  const keys = makePathArray(path);

  let cursor = obj;

  while (keys.length > 1) {
    const key = keys[0];
    const nextKey = keys[1];
    if (typeof nextKey === 'number' && !Array.isArray(cursor[key])) {
      cursor[key] = [];
    }
    if (typeof nextKey !== 'number' && !isObject(cursor[key])) {
      cursor[key] = {};
    }
    cursor = cursor[key];
    keys.shift();
  }

  if (!value && deleteWhenFalsey) {
    delete cursor[keys[0]];
  } else {
    cursor[keys[0]] = value;
  }

  return obj;
}

// tslint:disable-next-line:no-any
export function get(obj: any, path: FieldName | undefined, def?: unknown) {
  if (!path) {
    return obj;
  }
  const pathArray = makePathArray(path);
  const pathObj = pathArray;
  const val = pathObj.reduce((current, pathPart) => {
    if (typeof current !== 'undefined' && current !== null) {
      return current[pathPart];
    }
    return undefined;
  }, obj);
  return val !== undefined ? val : def;
}
