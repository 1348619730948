import { types, getRoot, flow } from 'mobx-state-tree';
import Omit from 'src/infrastructure/omit';
import { IRootStoreModel } from 'src/domain/entities/RootStoreModel';
import { JobTaskStatus } from 'src/api/enums';
import { getAjax } from 'src/domain/services';
import { AssetReportsModel } from './AssetReportsModel';
import { VehicleTypesModel } from 'src/domain/entities/workshop/vehicleType/VehicleTypesModel';
import { AssetSubcategoryType } from 'src/domain/enums';

type AssetCategory = Workshop.Domain.AggregatesModel.AssetAggregate.AssetCategory;
type AssetHousingLocation = Workshop.Domain.AggregatesModel.AssetAggregate.AssetHousingLocation;
type AssetSubcategory = Workshop.Domain.AggregatesModel.AssetAggregate.AssetSubcategory;
type EngineItem = Workshop.Domain.Queries.GetAsset.EngineItem;
type AssetPlanItem = Workshop.Domain.Queries.AssetServices.GetAssetServicePlan.AssetPlanItem;
type DefectListItem = Workshop.Domain.Queries.Defect.DefectListItem;
type FutureTaskForAsset = Workshop.Domain.Queries.GetFutureTaskForAsset.FutureTaskForAsset;
type ScheduledTaskForAsset = Workshop.Domain.Queries.GetScheduledTaskForAsset.ScheduledTaskForAsset;
type CreateAssetCommand = Workshop.Domain.Commands.Asset.CreateAssetCommand;
type ScheduleServiceJobTaskCommand = Workshop.Domain.Commands.JobTask.ScheduleServiceJobTaskCommand;
type ScheduleServiceJobTaskResponse = Workshop.Domain.Commands.JobTask.ScheduleServiceJobTaskResponse;
type SchedulePreventativeMaintenanceServiceJobTaskCommand = Workshop.Domain.Commands.JobTask.SchedulePreventativeMaintenanceServiceJobTaskCommand;
type SchedulePreventativeMaintenanceServiceJobTaskResponse = Workshop.Domain.Commands.JobTask.SchedulePreventativeMaintenanceServiceJobTaskResponse;
type CancelAssetServiceCommand = Workshop.Domain.Commands.AssetServiceSchedule.CancelAssetServiceCommand;
type UpdateAssetCommand = Workshop.Domain.Commands.Asset.UpdateAssetCommand;
type AssetServicePlan = Workshop.Domain.Queries.AssetServices.GetAssetServicePlan.AssetServicePlan;
type AssetDetails = Workshop.Domain.Queries.GetAsset.AssetDetails;
type AssetNextServiceDueItem = Workshop.Domain.Queries.AssetServices.GetAssetNextServiceDue.AssetNextServiceDueItem;
type AssetComponentsItems = Workshop.Domain.Queries.AssetComponents.AssetComponentsItems;
type AssetParts = Workshop.Domain.Queries.AssetParts.AssetParts;
type AssetFuel = Workshop.Domain.Queries.GetAssetFuel.AssetFuelItem;
type CreateAssetAdHocFuelCommand = Workshop.Domain.Commands.Asset.CreateAssetAdHocFuel.CreateAssetAdHocFuelCommand;
type UpdateAssetAdHocFuelCommand = Workshop.Domain.Commands.Asset.UpdateAssetAdHocFuel.UpdateAssetAdHocFuelCommand;
type ServiceCycleItem = Workshop.Domain.Queries.AssetGroup.GetAssetGroupFullServiceCycle.ServiceCycleItem;

export type CancelAssetServiceArgs = Omit<CancelAssetServiceCommand, 'assetId'>;
export type ScheduleServiceArgs = ScheduleServiceJobTaskCommand &
  Pick<SchedulePreventativeMaintenanceServiceJobTaskCommand, 'assetComponentId'>;

const FLEET_SUBCATEGORY_IDS = [
  AssetSubcategoryType.Bus,
  AssetSubcategoryType.Car,
  AssetSubcategoryType.Trailer,
];

export const AssetModel = types
  .model('AssetModel', {
    categories: types.array(types.frozen<AssetCategory>()),
    housedAtLocations: types.array(types.frozen<AssetHousingLocation>()),
    subcategories: types.array(types.frozen<AssetSubcategory>()),
    engines: types.array(types.frozen<EngineItem>()),
    assetServiceItems: types.array(types.frozen<AssetPlanItem>()),
    assetServicePlan: types.maybe(types.frozen<AssetServicePlan>()),
    asset: types.maybe(types.frozen<AssetDetails>()),
    defects: types.array(types.frozen<DefectListItem>()),
    nextService: types.maybe(types.frozen<AssetNextServiceDueItem>()),
    futureTasks: types.array(types.frozen<FutureTaskForAsset>()),
    scheduledTasks: types.array(types.frozen<ScheduledTaskForAsset>()),
    assetReports: types.optional(AssetReportsModel, {}),
    assetComponents: types.maybe(types.frozen<AssetComponentsItems>()),
    assetParts: types.maybe(types.frozen<AssetParts>()),
    assetFuels: types.array(types.frozen<AssetFuel>()),
    vehicleTypes: types.optional(VehicleTypesModel, {}),
  })
  .views(self => ({
    get upcomingServices() {
      var services = self.assetServiceItems.filter(
        s => !s.taskStatus || s.taskStatus.id !== JobTaskStatus.Completed
      );
      return services;
    },
    get completedServices() {
      var services = self.assetServiceItems.filter(
        s => s.taskStatus && s.taskStatus.id === JobTaskStatus.Completed
      );
      return services;
    },
    get fleetSubcategories() {
      return self.subcategories.filter(c => FLEET_SUBCATEGORY_IDS.indexOf(c.id) >= 0);
    },
  }))
  .actions(self => {
    const ajax = getAjax(self);
    const root = getRoot(self) as IRootStoreModel;

    const createAsset = flow(function*(command: CreateAssetCommand) {
      const id = yield ajax.asset.createAsset(command);
      self.asset = undefined;
      root.history.push(`/workshop/assets/${id}`);
    });

    const loadAssetCategories = flow(function*() {
      self.categories = yield ajax.asset.getAssetCategories();
    });

    const loadAssetHousedAtLocations = flow(function*() {
      self.housedAtLocations = yield ajax.asset.getAssetHousingLocations();
    });

    const loadAssetSubcategories = flow(function*() {
      self.subcategories = yield ajax.asset.getAssetSubcategories();
    });

    const checkForUniqueName = flow(function*(name: string) {
      return yield ajax.asset.checkForUniqueName(name);
    });

    const checkForUniqueRegistrationNumber = flow(function*(registrationNumber: string) {
      return yield ajax.asset.checkForUniqueRegistrationNumber(registrationNumber);
    });

    const loadAssetServicePlan = flow(function*(assetId: string) {
      self.assetServicePlan = yield ajax.asset.getAssetServicePlan(assetId);
    });

    const loadAssetComponents = flow(function*(assetId: string) {
      self.assetComponents = yield ajax.asset.getAssetComponents(assetId);
    });

    const loadAssetParts = flow(function*(assetId: string) {
      self.assetParts = yield ajax.asset.getAssetParts(assetId);
    });

    const loadAssetFuels = flow(function*(
      assetId: string,
      includeTabletData: boolean,
      maxResults: number,
      includeDataFuel?: boolean
    ) {
      self.assetFuels = yield ajax.asset.getAssetFuels(
        assetId,
        includeTabletData,
        maxResults,
        includeDataFuel
      );
    });

    const loadAsset = flow(function*(assetId: string) {
      self.asset = yield ajax.asset.getAsset(assetId);
    });

    const getNextService = flow(function*(assetId: string) {
      self.nextService = yield ajax.asset.getNextServiceForAsset(assetId);
    });

    const clearNextService = () => {
      self.nextService = undefined;
    };

    const cancelAssetService = flow(function*(args: CancelAssetServiceArgs) {
      const assetId = self.asset && self.asset.id ? self.asset.id : ''; // 404 will be returned if asset id is undefined
      const command: CancelAssetServiceCommand = { ...args, assetId: assetId };
      yield ajax.asset.cancelAssetService(command);
      yield loadAssetServicePlan(assetId);
      yield getNextService(assetId);
    });

    const scheduleService = flow(function*(args: ScheduleServiceArgs) {
      const assetId = self.asset && self.asset.id ? self.asset.id : ''; // 404 will be returned if asset id is undefined
      const command: ScheduleServiceJobTaskCommand = { ...args, assetId: assetId };
      const response: ScheduleServiceJobTaskResponse = yield ajax.jobTask.schedulueServiceJobTask(
        command
      );
      root.history.push(`/workshop/tasks/${response.jobTaskId}`);
    });

    const schedulePreventativeMaintenanceService = flow(function*(args: ScheduleServiceArgs) {
      const command: SchedulePreventativeMaintenanceServiceJobTaskCommand = { ...args };
      const response: SchedulePreventativeMaintenanceServiceJobTaskResponse = yield ajax.jobTask.schedulePreventativeMaintenanceServiceJobTask(
        command
      );
      root.history.push(`/workshop/tasks/${response.jobTaskId}`);
    });

    const updateAsset = flow(function*(command: UpdateAssetCommand) {
      yield ajax.asset.updateAsset(command);
      yield loadAsset(command.assetId);
    });

    const resetAssetServiceSchedule = flow(function*(
      startingDate: string,
      startingKms: number,
      lastServiceCycle: ServiceCycleItem | undefined
    ) {
      const assetId = self.asset && self.asset.id ? self.asset.id : ''; // 404 will be returned if asset id is undefined
      const serviceCycle = lastServiceCycle
        ? {
            jobTaskSubcategoryId: lastServiceCycle.serviceType.id,
            dueDays: lastServiceCycle.dueDays,
            dueKms: lastServiceCycle.dueKms,
          }
        : undefined;
      yield ajax.asset.resetAssetServiceSchedule({
        assetId: assetId,
        startingDate,
        startingKms,
        lastServiceCycle: serviceCycle,
      });
      yield loadAssetServicePlan(assetId);
    });

    const updateServicePlan = flow(function*(
      command: Workshop.Domain.Commands.AssetServicePlan.UpdateAssetServicePlanCommand
    ) {
      yield ajax.asset.updateServicePlan(command);
      yield loadAssetServicePlan(command.assetId);
    });

    const updateAssetComponents = flow(function*(
      command: Workshop.Domain.Commands.AssetComponents.UpdateAssetComponentsCommand
    ) {
      yield ajax.asset.updateComponents(command);
      yield loadAssetComponents(command.assetId);
    });

    const updateAssetParts = flow(function*(
      command: Workshop.Domain.Commands.AssetPart.UpdateAssetPartCommand
    ) {
      yield ajax.asset.updateParts(command);
      yield loadAssetParts(command.assetId);
    });

    const getDefectsForAsset = flow(function*(assetId: string) {
      self.defects = yield ajax.asset.getDefectsForAsset(assetId);
    });

    const clearDefectsForAsset = () => {
      self.defects.clear();
    };

    const getFutureTasks = flow(function*(assetId: string) {
      self.futureTasks = yield ajax.asset.getFutureTasksForAsset(assetId);
    });

    const clearFutureTasks = () => {
      self.futureTasks.clear();
    };

    const getScheduledTasks = flow(function*(assetId: string) {
      self.scheduledTasks = yield ajax.asset.getScheduledTasksForAsset(assetId);
    });

    const clearScheduledTasks = () => {
      self.scheduledTasks.clear();
    };

    const decommission = flow(function*(assetId: string) {
      yield ajax.asset.decommission(assetId);
      yield loadAsset(assetId);
      yield getNextService(assetId);
    });

    const loadAssetWithAssociatedInfo = flow(function*(assetId: string) {
      yield loadAsset(assetId);
      yield getNextService(assetId);
      yield getFutureTasks(assetId);
      yield getScheduledTasks(assetId);
    });

    const clearAll = () => {
      self.scheduledTasks.clear();
      self.futureTasks.clear();
      self.nextService = undefined;
      self.defects.clear();
      self.assetServicePlan = undefined;
      self.assetServiceItems.clear();
    };

    return {
      createAsset,
      loadAssetCategories,
      loadAssetHousedAtLocations,
      loadAssetSubcategories,
      checkForUniqueName,
      checkForUniqueRegistrationNumber,
      loadAssetServicePlan,
      loadAssetComponents,
      loadAssetParts,
      loadAssetFuels,
      loadAsset,
      updateAsset,
      scheduleService,
      schedulePreventativeMaintenanceService,
      resetAssetServiceSchedule,
      cancelAssetService,
      updateServicePlan,
      updateAssetComponents,
      updateAssetParts,
      getDefectsForAsset,
      clearDefectsForAsset,
      getFutureTasks,
      getNextService,
      decommission,
      clearFutureTasks,
      clearNextService,
      getScheduledTasks,
      clearScheduledTasks,
      loadAssetWithAssociatedInfo,
      clearAll,
    };
  })
  .actions(self => {
    const ajax = getAjax(self);

    const createAdHocFuel = flow(function*(
      command: CreateAssetAdHocFuelCommand,
      includeTabletData: boolean
    ) {
      yield ajax.asset.createAdHocFuel(command);
      yield self.loadAssetFuels(command.assetId, includeTabletData, 100, true);
    });

    const updateAdHocFuel = flow(function*(
      command: UpdateAssetAdHocFuelCommand,
      includeTabletData: boolean
    ) {
      yield ajax.asset.updateAdHocFuel(command);
      yield self.loadAssetFuels(command.assetId, includeTabletData, 100, true);
    });

    return { createAdHocFuel, updateAdHocFuel };
  });
