import { types, flow } from 'mobx-state-tree';
import { getAjax } from 'src/domain/services';
import buildListPageApiSearchModel from 'src/domain/modelBuilders/buildListPageApiSearchModel';

type PartCategoryItem = Workshop.Domain.Queries.PartCategory.PartCategoryItem;

const PartCategoryListModel = buildListPageApiSearchModel('OwnersModel', d =>
  d.ajax.workshop.partCategories.listPartCategories(d.query)
);

const ListAllPartCategoriesModel = types
  .model('PartCategoriesModel', {
    partCategoryListItems: types.array(types.frozen<PartCategoryItem>()),
  })
  .actions(self => {
    const ajax = getAjax(self);

    const loadPartCategoryListItems = flow(function*() {
      self.partCategoryListItems = yield ajax.workshop.partCategories.listAllPartCategories();
    });

    return {
      loadPartCategoryListItems,
    };
  });

type IPartCategoriesModelType = typeof PartCategoriesModel.Type;
export interface IPartCategoriesModel extends IPartCategoriesModelType {}

export const PartCategoriesModel = types.compose(PartCategoryListModel, ListAllPartCategoriesModel);
