import { types, flow } from 'mobx-state-tree';
import { getAjax } from 'src/domain/services/storeEnvironment';

type JobItem = Operations.Domain.Queries.ViewJob.JobItem;
type QuoteDriverPdfDto = Operations.Domain.Commands.Quote.QuoteDriverPdfDto;
type JobAttachmentPdfDto = Common.Dtos.JobAttachmentPdfDto;

export const JobItemModel = types
  .model('JobItemModel', {
    job: types.maybe(types.frozen<JobItem>()),
    driverPdfs: types.array(types.frozen<QuoteDriverPdfDto>()),
    attachment: types.maybe(types.frozen<JobAttachmentPdfDto>()),
  })
  .actions(self => {
    const ajax = getAjax(self);

    const loadJob = flow(function*(jobId: string) {
      self.job = yield ajax.kiosk.jobs.loadJob(jobId);
      return self.job;
    });

    const loadDriverPdfs = flow(function*(jobId: string) {
      self.driverPdfs = yield ajax.kiosk.jobs.loadDriverPdfs(jobId);
    });

    const getJobAttachment = flow(function*(attachmentId: string) {
      self.attachment = yield ajax.kiosk.jobs.getDriverJobAttachment(attachmentId);
    });

    return {
      loadJob,
      loadDriverPdfs,
      getJobAttachment,
    };
  });
