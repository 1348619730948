import inject from 'src/views/injectFromStore';
import ListShiftTemplates, { IListShiftTemplatesProps } from './ListShiftTemplates';

const Container = inject<IListShiftTemplatesProps>((allStores, p) => ({
  canManageShiftTemplates: allStores.rootStore.account.isWorkshopDepartmentMember,
  listShiftTemplates: allStores.rootStore.workshop.shiftTemplates.listItems,
  shiftTemplates: allStores.rootStore.workshop.shiftTemplates.items.slice(),
  hasMoreData: allStores.rootStore.workshop.shiftTemplates.hasMoreItems,
}))(ListShiftTemplates);

export default Container;
