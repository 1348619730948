import { Component } from 'react';
import { FieldType } from 'src/views/definitionBuilders/types/field';
import CrudPage, { ICrudPageDef, CrudPageMode } from 'src/views/components/Page/pages/CrudPage';
import { PaneType, ActionType, PagePrimarySize } from 'src/views/definitionBuilders/types';
import { ChevronUpIcon, ChevronDownIcon } from 'src/images/icons';
import { ChangeState } from 'src/api/enums';
import deepEqual from 'src/infrastructure/deepEqual';
import memoizeOne from 'src/infrastructure/memoizeOne';

type OperationsDiaryEntrySubject = Operations.Domain.Queries.ListOperationsDiaryEntries.OperationsDiaryEntrySubjectDto;
type UpdateOperationsDiaryEntrySubjectCommand = Operations.Domain.Commands.OperationsDiaryEntrySubject.UpdateOperationsDiaryEntrySubjectCommand;
type UpdateOperationsDiaryEntrySubject = Operations.Domain.Commands.OperationsDiaryEntrySubject.UpdateOperationsDiaryEntrySubjectDto;

export interface IMaintainSubjectsProps {
  mode: CrudPageMode;
  canManageOpsDiary: boolean;
  loadAllOperationsDiaryEntrySubjects: () => Promise<void>;
  updateAllOperationsDiaryEntrySubjects: (
    cmd: UpdateOperationsDiaryEntrySubjectCommand
  ) => Promise<void>;
  operationsDiaryEntrySubjects: OperationsDiaryEntrySubject[];
}

class MaintainSubjects extends Component<IMaintainSubjectsProps> {
  private readonly handlePreSubmit = (
    values: UpdateOperationsDiaryEntrySubject[]
  ): UpdateOperationsDiaryEntrySubjectCommand => {
    const getChangeState = (item: UpdateOperationsDiaryEntrySubject, currentIndex: number) => {
      if (item.changeState === ChangeState.Added || item.changeState === ChangeState.Deleted) {
        return item.changeState;
      }
      const originalIndex =
        this.props.operationsDiaryEntrySubjects &&
        this.props.operationsDiaryEntrySubjects.findIndex(ts => ts.id === item.id);
      if (originalIndex < 0) {
        throw new Error('Cannot find original subject');
      }
      const originalItem = this.props.operationsDiaryEntrySubjects[originalIndex];

      return currentIndex === originalIndex && deepEqual(item, originalItem)
        ? ChangeState.Unchanged
        : ChangeState.Modified;
    };

    return {
      items: values.map((v, i) => {
        return {
          ...v,
          changeState: getChangeState(v, i),
        };
      }),
    } as UpdateOperationsDiaryEntrySubjectCommand;
  };

  private readonly getPageDef = memoizeOne(
    (updating: boolean): ICrudPageDef => {
      return {
        primarySize: PagePrimarySize.threeQuarters,
        primarySection: {
          title: 'Daily Event Log Subjects',
          panels: [
            {
              panes: [
                {
                  paneType: PaneType.tablePane,
                  dataRequiredForRows: 'paneValue',
                  fields: [
                    {
                      fieldType: FieldType.textField,
                      dataAddr: 'description',
                      label: 'Description',
                      mandatory: true,
                      readonly: d => d.parentValue.isSystem,
                    },
                    {
                      fieldType: FieldType.actionListField,
                      hidden: !updating,
                      actionGroups: [
                        {
                          actions: [
                            {
                              actionType: ActionType.moveArrayItemActionButton,
                              label: 'Move up',
                              icon: <ChevronUpIcon />,
                              moveDirection: 'prev',
                              disabled: d =>
                                (d.paneValue as OperationsDiaryEntrySubject[]).indexOf(
                                  d.parentValue
                                ) === 0,
                            },
                            {
                              actionType: ActionType.moveArrayItemActionButton,
                              label: 'Move down',
                              icon: <ChevronDownIcon />,
                              moveDirection: 'next',
                              disabled: d =>
                                (d.paneValue as OperationsDiaryEntrySubject[]).indexOf(
                                  d.parentValue
                                ) ===
                                (d.paneValue as OperationsDiaryEntrySubject[]).length - 1,
                            },
                            {
                              actionType: ActionType.removeArrayItemActionButton,
                              label: 'Remove Subject',
                              disabled: d => {
                                const item = d.parentValue as OperationsDiaryEntrySubject;
                                return !item.canBeDeleted || d.parentValue.isSystem;
                              },
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
                {
                  paneType: PaneType.actionListPane,
                  actionGroups: [
                    {
                      actions: [
                        {
                          actionType: ActionType.addArrayItemActionButton,
                          hidden: !updating,
                          label: 'Add Subject',
                          newItemData: { canBeDeleted: true },
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
          onFormPreSubmit: this.handlePreSubmit,
          onFormSubmit: this.props.updateAllOperationsDiaryEntrySubjects,
        },
      };
    }
  );

  render() {
    const {
      mode,
      canManageOpsDiary,
      loadAllOperationsDiaryEntrySubjects,
      operationsDiaryEntrySubjects,
    } = this.props;
    return (
      <CrudPage
        data={operationsDiaryEntrySubjects}
        def={({ updating }) => this.getPageDef(updating)}
        mode={mode}
        isEditingForbidden={!canManageOpsDiary}
        onLoadData={() => loadAllOperationsDiaryEntrySubjects()}
      />
    );
  }
}

export default MaintainSubjects;
