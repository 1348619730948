import * as History from 'history';
import {
  FieldDefs,
  isSelectFieldDef,
  isDateFieldDef,
  isDateTimeFieldDef,
  isActionListFieldDef,
  IFieldData,
  DataAddr,
} from 'src/views/definitionBuilders/types';

// tslint:disable-next-line:no-any
export function getDataFromAddr(addr: DataAddr | undefined, data: any) {
  if (!addr) {
    return data;
  }
  if (Array.isArray(addr)) {
    return addr.reduce((d, id) => (d ? d[id] : undefined), data);
  } else {
    return data ? data[addr] : undefined;
  }
}

// tslint:disable-next-line:no-any
export function getFieldSearchText(d: FieldDefs, data: IFieldData<any>): string | undefined {
  if (isSelectFieldDef(d)) {
    return d.getSearchText ? d.getSearchText(data) : data.fieldValue[d.descriptionKey];
  } else if (isDateFieldDef(d) || isDateTimeFieldDef(d)) {
    return d.getSearchText ? d.getSearchText(data) : undefined; // don't search by default
  } else if (isActionListFieldDef(d)) {
    return d.getSearchText ? d.getSearchText(data) : undefined; // don't search by default
  }
  // @ts-ignore - Ignore rather than writing out checks for each remaining field def type
  return d.getSearchText ? d.getSearchText(data) : `${data.fieldValue}`;
}

// tslint:disable-next-line:no-any
export function getFieldLinkTo(d: FieldDefs, data: IFieldData<any>): History.LocationDescriptor {
  // @ts-ignore - Ignore rather than writing out checks for each field def type
  return d.linkTo ? d.linkTo(data) : undefined;
}
