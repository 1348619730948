import { types, flow } from 'mobx-state-tree';
import { getAjax } from 'src/domain/services/storeEnvironment';

type OpsBookingItem = Operations.Domain.Queries.ViewBookingForOps.OpsBookingItem;
type NeedsVerificationStartDateItem = Operations.Domain.Queries.GetNeedsVerificationStartDate.NeedsVerificationStartDateItem;

export const BookingForOpsItemModel = types
  .model('BookingForOpsItemModel', {
    item: types.maybe(types.frozen<OpsBookingItem>()),
    needsVerificationStartDateItem: types.maybe(types.frozen<NeedsVerificationStartDateItem>()),
  })
  .actions(self => {
    const ajax = getAjax(self);

    const loadBookingForOps = flow(function*(quoteId: string) {
      self.item = yield ajax.sales.quote.viewBookingForOps(quoteId);
    });

    const regenerateJobs = flow(function*(
      quoteId: string,
      tripNumbers: number[] | undefined,
      regenerateFutureBookings: boolean
    ) {
      yield ajax.sales.quote.regenerateJobs(quoteId, tripNumbers, regenerateFutureBookings);
      yield loadBookingForOps(quoteId);
    });

    const verifyJobs = flow(function*(quoteId: string) {
      yield ajax.sales.quote.verifyJobs(quoteId);
      yield loadBookingForOps(quoteId);
    });

    const getNeedsVerificationStartDate = flow(function*(quoteId: string) {
      self.needsVerificationStartDateItem = yield ajax.sales.quote.getNeedsVerificationStartDate(
        quoteId
      );
    });

    return {
      loadBookingForOps,
      verifyJobs,
      regenerateJobs,
      getNeedsVerificationStartDate,
    };
  });
