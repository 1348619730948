import { PaneType, SectionDefs } from 'src/views/definitionBuilders/types';
import { DateTime } from 'luxon';

type ActivityLogTransaction = Operations.Domain.Queries.GetActivityLog.ActivityLogTransaction;
type ActivityLogDetails = Operations.Domain.Queries.GetActivityLog.ActivityLogDetails;

export default function getActivityLogPanelDef(
  leaveId: string,
  activityLogs: ActivityLogTransaction[]
): SectionDefs {
  return {
    title: 'Activity',
    hidden: !leaveId,
    explicitData: activityLogs.filter(x => x.items.some(y => y.aggregateId === leaveId)),
    panels: [
      {
        panes: [
          {
            paneType: PaneType.repeatingPane,
            itemPanes: [
              {
                paneType: PaneType.customPane,
                dataAddr: 'transactionId',
                render: api => {
                  const log = api.data.parentValue as ActivityLogTransaction;
                  const createdOnDateTime = DateTime.fromISO(log.createdOn).toLocaleString(
                    DateTime.DATETIME_MED_WITH_SECONDS
                  );
                  return (
                    <div className="activity-log-for-leave-pane">
                      <div className="created-by">{log.createdBy}</div>
                      <div className="created-on">{createdOnDateTime}</div>
                      <ul className="log-items list-unstyled">
                        {log.items.map((item: ActivityLogDetails) => {
                          return (
                            <li key={item.activityLogId}>
                              <div>
                                <strong>{item.description}</strong>
                              </div>
                              <div>
                                <em>{item.comment}</em>
                              </div>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  );
                },
              },
            ],
          },
        ],
      },
    ],
  };
}
