import './PrintJobSheet.scss';
import { Component } from 'react';
import PrintJobSheetHeader from './PrintJobSheetHeader/PrintJobSheetHeader';
import PrintJobTaskParts from './PrintJobTaskParts/PrintJobTaskParts';
import PrintJobTaskDetails from './PrintJobTaskDetails/PrintJobTaskDetails';
import PrintJobTaskLabour from './PrintJobTaskLabour/PrintJobTaskLabour';
import PrintJobTaskChecklist from './PrintJobTaskChecklist/PrintJobTaskChecklist';

export interface IPrintJobSheetProps {
  job: Workshop.Domain.Queries.Job.PrintJobQuery.JobItem;
  depotId?: number;
}

export const jobSheetPdfHeader = (job: Workshop.Domain.Queries.Job.PrintJobQuery.JobItem) => {
  const taskText = job.jobTasks.length > 1 ? 'Tasks' : 'Task';
  return `Job: ${job.jobNumber}, ${taskText}: ${job.jobTasks.map(o => o.taskNumber).join(', ')}`;
};

class PrintJobSheet extends Component<IPrintJobSheetProps> {
  render() {
    const { job, depotId } = this.props;

    const specificDepotId = depotId ?? job.depotId;

    if (job.jobTasks.length === 0) {
      return <h1>No tasks for this job</h1>;
    }
    return (
      <div className="print-job-sheet-component">
        {job.jobTasks.map(task => {
          return (
            <div key={task.jobTaskId}>
              <PrintJobSheetHeader job={job} task={task} />
              <PrintJobTaskDetails />
              <PrintJobSheetHeader job={this.props.job} task={task} />
              <PrintJobTaskParts parts={task.parts} depotId={specificDepotId} />
              <PrintJobTaskLabour labour={task.labour} />
              {!!task.checklist ? (
                <div>
                  <PrintJobSheetHeader job={job} task={task} />
                  <PrintJobTaskChecklist checklist={task.checklist} />
                </div>
              ) : null}
            </div>
          );
        })}
      </div>
    );
  }
}

export default PrintJobSheet;
