import './GanttViewSwitcher.scss';
import { Component } from 'react';
import cn from 'classnames';
import { Button, ButtonGroup } from 'reactstrap';
import { StreamIcon } from 'src/images/icons';
import { IGanttView, IGanttCalendarGroupItem, IGanttCalendarDayItem } from '../baseTypes';

export interface IGanttViewSwitcherProps<T extends IGanttCalendarDayItem> {
  className?: string;
  ganttViews: { [key: string]: IGanttView<T, IGanttCalendarGroupItem> } | undefined;
  excludeUngroupedView: boolean;
  currentView: string | undefined;
  onViewChange: (view: string | undefined) => void;
}

class GanttViewSwitcher<T extends IGanttCalendarDayItem> extends Component<
  IGanttViewSwitcherProps<T>
> {
  render() {
    const { className, ganttViews, excludeUngroupedView, currentView, onViewChange } = this.props;

    if (!ganttViews) {
      return null;
    }

    const views = Object.keys(ganttViews);
    const viewCount = views.length + (excludeUngroupedView ? -1 : 0);
    if (viewCount <= 1) {
      return null;
    }

    return (
      <ButtonGroup className={cn('gantt-view-switcher-component', className)}>
        {excludeUngroupedView ? null : (
          <Button
            outline
            active={currentView === undefined}
            title="Ungrouped"
            onClick={() => onViewChange(undefined)}>
            <StreamIcon />
          </Button>
        )}
        {views.map(view => {
          const ganttView = ganttViews[view];
          return (
            <Button
              key={view}
              outline
              active={currentView === view}
              title={ganttView.label}
              onClick={() => onViewChange(view)}>
              {ganttView.icon}
            </Button>
          );
        })}
      </ButtonGroup>
    );
  }
}

export default GanttViewSwitcher;
