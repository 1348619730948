import { Subject } from 'rxjs/Subject';
import Omit from 'src/infrastructure/omit';
import { INotificationOptions, NotificationType } from 'src/domain/baseTypes';

type IWarning = Common.IWarning;

interface IShowNotification {
  eventType: 'showNotification';
  message: string;
  options?: Partial<INotificationOptions>;
}

interface IConflictAccepted {
  eventType: 'conflictAccepted';
  conflictId: string;
}

interface IConflictAcceptanceCancelled {
  eventType: 'acceptanceCancelled';
  conflictId: string;
}

export interface IOperationsDomainEvent {
  eventType: 'operationsDomainEvent';
  eventName: string;
  payload: any;
}

type BusEventType =
  | IShowNotification
  | IConflictAccepted
  | IConflictAcceptanceCancelled
  | IOperationsDomainEvent;
type EventArgType<T> = Omit<T, 'eventType'>;

function init() {
  const busSubject = new Subject<BusEventType>();

  function showNotification(event: EventArgType<IShowNotification>) {
    busSubject.next({ ...event, eventType: 'showNotification' });
  }

  function showWarnings(warnings?: IWarning[]) {
    if (warnings && warnings.length) {
      warnings.forEach(warning =>
        showNotification({
          message: warning.message,
          options: { type: NotificationType.warn, autoClose: false },
        })
      );
    }
  }

  function conflictAccepted(event: EventArgType<IConflictAccepted>) {
    busSubject.next({ ...event, eventType: 'conflictAccepted' });
  }

  function conflictAcceptanceCancelled(event: EventArgType<IConflictAcceptanceCancelled>) {
    busSubject.next({ ...event, eventType: 'acceptanceCancelled' });
  }

  function operationsDomainEvent(event: EventArgType<IOperationsDomainEvent>) {
    busSubject.next({ ...event, eventType: 'operationsDomainEvent' });
  }

  return {
    showWarnings,
    showNotification,
    showNotification$: busSubject.filter(
      (e): e is IShowNotification => e.eventType === 'showNotification'
    ),
    conflictAccepted,
    conflictAccepted$: busSubject.filter(
      (e): e is IConflictAccepted => e.eventType === 'conflictAccepted'
    ),
    conflictAcceptanceCancelled,
    conflictAcceptanceCancelled$: busSubject.filter(
      (e): e is IConflictAcceptanceCancelled => e.eventType === 'acceptanceCancelled'
    ),
    operationsDomainEvent,
    operationsDomainEvent$: busSubject.filter(
      (e): e is IOperationsDomainEvent => e.eventType === 'operationsDomainEvent'
    ),
  };
}

export type IStoreBus = ReturnType<typeof init>;
export default init;
