import { ClipboardCheckIcon, HourglassEndIcon } from 'src/images/icons';
import { ShiftProgressStatus } from 'src/api/enums';

interface IWorkshopShiftProgressStatusIconProps {
  status: ShiftProgressStatus;
  showWarningsOnly?: boolean;
}

function getIcon(status: ShiftProgressStatus, showWarningsOnly: boolean | undefined): JSX.Element {
  switch (status) {
    case ShiftProgressStatus.ClockOffInTheFuture:
      return <></>;
    case ShiftProgressStatus.IncompleteShift:
      return <HourglassEndIcon color="red" />;
    case ShiftProgressStatus.CompleteShift:
      return !showWarningsOnly ? <ClipboardCheckIcon /> : <></>;
    default:
      return <></>;
  }
}

const WorkshopShiftProgressStatusIcon: React.FC<IWorkshopShiftProgressStatusIconProps> = ({
  status,
  showWarningsOnly,
}) => getIcon(status, showWarningsOnly);

export default WorkshopShiftProgressStatusIcon;
