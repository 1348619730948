import { buildListPageApiSearchModelTypedQuery } from 'src/domain/modelBuilders/buildListPageApiSearchModel';

type ListBulkDriverAuthorizationUpdateOperationQuery = Operations.Domain.Queries.ListBulkDriverAuthorizationUpdateOperations.ListBulkDriverAuthorizationUpdateOperationsQuery;

export const ListBulkDriverAuthorizationUpdateOperationModel = buildListPageApiSearchModelTypedQuery<
  ListBulkDriverAuthorizationUpdateOperationQuery
>()(
  'ListBulkDriverAuthorizationUpdateOperationModel',
  d =>
    d.ajax.operations.bulkDriverAuthorizationUpdateOperation.listBulkDriverAuthorizationUpdateOperations(
      d.query
    ),
  { size: 25 }
);
