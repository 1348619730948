import currency from 'currency.js';

export function precisionRound(value: number, precision: number): number {
  if (precision < 0) {
    const factor = Math.pow(10, -1 * precision);
    let money = currency(value);
    money = money.divide(factor);
    money = currency(money, { precision: 0 });
    money = money.multiply(factor);
    return money.value;
  } else {
    let money = currency(value, { precision: precision });
    return money.value;
  }
}

export function formatToFixed(value: number, fractionDigits: number) {
  return value.toLocaleString(undefined, {
    minimumFractionDigits: fractionDigits,
    maximumFractionDigits: fractionDigits,
  });
}

export function formatToFixedWithMinMax(value: number, minFractions: number, maxFractions: number) {
  return value.toLocaleString(undefined, {
    minimumFractionDigits: minFractions,
    maximumFractionDigits: maxFractions,
  });
}

/**
 * Scales to integers to avoid floating point arithmetic errors.
 * @param quantity Up to 4 decimal points supported.
 * @param unitPriceInDollars Up to 5 decimal points supported.
 */
export function multiplyUnitPriceByQuantityArithmeticallySafe(
  quantity: number,
  unitPriceInDollars: number
): currency {
  const safePrice = currency(unitPriceInDollars, { precision: 5 });
  const safeTotal = safePrice.multiply(quantity);
  const totalInDollars = currency(safeTotal, { precision: 2 });
  return totalInDollars;
}
