import styles from './Task.module.scss';

import SplitScreen from '../components/layout/SplitScreen/SplitScreen';
import Task from './Task';
import { RouteComponentProps } from 'react-router-dom';
import { DateTime } from 'luxon';
import cn from 'classnames';
import WithBackButton from '../menu/WithBackButton';
import AssetDetails from '../AssetDetails/AssetDetails';
import { observer } from 'mobx-react';
import { useKioskRootStore } from 'src/domain/entities/KioskRootStoreModel';

interface ITaskRouteParams {
  id: string;
}

type InternalProps = RouteComponentProps<ITaskRouteParams>;

const TaskPage: React.FC<InternalProps> = observer((props: InternalProps) => {
  const rootStore = useKioskRootStore();
  const task = rootStore.kiosk.workshop.task.taskDetails;
  const goBack = rootStore.history.history!.goBack;

  const left = (
    <div className={cn(styles.panel, styles.leftScreen)}>
      <WithBackButton goBack={goBack}>
        {task && <AssetDetails assetId={task.asset.id} currentTaskId={task.id} />}
      </WithBackButton>
    </div>
  );

  const right = (
    <div className={styles.panel}>
      <div className={styles.taskPageBar}>
        {task
          ? `Last updated on ${DateTime.fromISO(task.lastModifiedOn).toLocaleString(
              DateTime.DATETIME_MED
            )}`
          : 'No task'}{' '}
      </div>
      <div className={styles.taskPageContent}>
        <Task id={props.match.params.id} readonly />
      </div>
    </div>
  );

  return (
    <div className={styles.taskPage}>
      <SplitScreen left={left} right={right} />
    </div>
  );
});

export default TaskPage;
