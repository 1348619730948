import { UiRouteName } from 'src/domain/baseTypes';
import { IRootStoreModel } from 'src/domain/entities/RootStoreModel';
import UiRoute from 'src/domain/services/buildUiRoutes/UiRoute';

export default function buildUiRoutes(store: IRootStoreModel) {
  const engineCreate = new UiRoute(
    UiRouteName.workshopEngineCreate,
    '/workshop/engines/create',
    () => 'Create Engine',
    false
  );

  const engineEdit = new UiRoute(
    UiRouteName.workshopEngineEdit,
    '/workshop/engines/:id',
    () => 'Manage Engine',
    false
  );

  const enginesList = new UiRoute(
    UiRouteName.workshopEngineList,
    '/workshop/engines',
    () => 'Engines',
    false,
    false,
    [engineCreate, engineEdit]
  );

  return {
    [UiRouteName.workshopEngineList]: enginesList,
    [UiRouteName.workshopEngineCreate]: engineCreate,
    [UiRouteName.workshopEngineEdit]: engineEdit,
  };
}
