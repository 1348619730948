import inject from 'src/views/injectFromStore';
import MaintainTimesheetAllowance, {
  IMaintainTimesheetAllowanceProps,
} from './MaintainTimesheetAllowance';
import Omit from 'src/infrastructure/omit';

const Container = inject<
  Omit<IMaintainTimesheetAllowanceProps, 'mode'>,
  IMaintainTimesheetAllowanceProps
>((allStores, p) => ({
  canManageTimesheetAllowances: allStores.rootStore.account.isPeopleDepartmentMember,
  timesheetAllowance: allStores.rootStore.people.timesheetAllowance.timesheetAllowance,
  onLoadTimesheetAllowance: allStores.rootStore.people.timesheetAllowance.loadTimesheetAllowance,
  onUpdateTimesheetAllowance:
    allStores.rootStore.people.timesheetAllowance.updateTimesheetAllowance,
  onCreateTimesheetAllowance:
    allStores.rootStore.people.timesheetAllowance.createTimesheetAllowance,
  onDeleteTimesheetAllowance:
    allStores.rootStore.people.timesheetAllowance.deleteTimesheetAllowance,
  onReactivateTimesheetAllowance:
    allStores.rootStore.people.timesheetAllowance.reactivateTimesheetAllowance,
  checkForUniqueTimesheetAllowanceName:
    allStores.rootStore.people.timesheetAllowance.checkForUniqueName,
}))(MaintainTimesheetAllowance);

export default Container;
