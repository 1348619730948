import { IAjax } from 'src/infrastructure/ajax';
import { workshopUrls } from 'src/domain/services/apiUrls';

type CubicFaultDetail = Workshop.Domain.AggregatesModel.AssetAggregate.CubicFaultDetail;
type CubicItem = Workshop.Domain.AggregatesModel.AssetAggregate.CubicItem;

export default function init(ajax: IAjax) {
  function loadCubicFaultDetails() {
    return ajax
      .get(workshopUrls.assetCubic.cubicFaultDetails)
      .toPromise()
      .then<CubicFaultDetail[]>(r => r.response);
  }

  function loadCubicItemDetails() {
    return ajax
      .get(workshopUrls.assetCubic.cubicItems)
      .toPromise()
      .then<CubicItem[]>(r => r.response);
  }

  function list(assetId: string) {
    return ajax
      .get(workshopUrls.assetCubic.list(assetId))
      .toPromise()
      .then<Workshop.Domain.Queries.AssetCubic.AssetCubicItem[]>(r => r.response);
  }

  function summarise(assetId: string) {
    return ajax
      .get(workshopUrls.assetCubic.summary(assetId))
      .toPromise()
      .then<Workshop.Domain.Queries.AssetCubic.AssetCubicSummary>(r => r.response);
  }
  function createAssetCubic(command: Workshop.Domain.Commands.AssetCubic.CreateAssetCubicCommand) {
    return ajax.post(workshopUrls.assetCubic.create(command.assetId), command).toPromise();
  }

  function updateAssetCubic(
    command: Workshop.Domain.Commands.AssetCubic.MarkPendingCubicChangesForAssetAsSentCommand
  ) {
    return ajax.put(workshopUrls.assetCubic.update(command.assetId), command).toPromise();
  }
  function generateExcel(assetId: string) {
    return ajax
      .getFile(workshopUrls.assetCubic.generateExcel(assetId))
      .toPromise()
      .then<Blob>(r => r.response);
  }
  function generatePdfForm(assetId: string, assetCubicId: number) {
    return ajax
      .getFile(workshopUrls.assetCubic.generatePdfForm(assetId, assetCubicId))
      .toPromise()
      .then<Blob>(r => r.response);
  }
  function generateExcelForAllAssets() {
    return ajax
      .getFile(workshopUrls.assetCubic.generateExcelForAllAssets())
      .toPromise()
      .then<Blob>(r => r.response);
  }
  return {
    assetCubicRegister: {
      list,
      summarise,
      updateAssetCubic,
      createAssetCubic,
      generateExcel,
      generateExcelForAllAssets,
      loadCubicFaultDetails,
      generatePdfForm,
      loadCubicItemDetails,
    },
  };
}
