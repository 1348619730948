import { Fragment } from 'react';
import { PaneType, ModalDefBuilder } from 'src/views/definitionBuilders/types';
import { getSubmitCloseModalActionGroupDef } from 'src/views/definitionBuilders/common';

type AssetCubicSummaryItem = Workshop.Domain.Commands.AssetCubic.AssetCubicSummaryItem;
type CubicItem = Workshop.Domain.AggregatesModel.AssetAggregate.CubicItem;
export default function getProcessModalDef(
  summaryItem: AssetCubicSummaryItem,
  cubicItems: CubicItem[],
  assetName: string,
  onProcess: () => Promise<void>
): ModalDefBuilder {
  const cubicItem = cubicItems.find(ci => ci.id === summaryItem.cubicItemId);
  return () => ({
    title: `Translink Form Confirmation`,
    asForm: true,
    panels: [
      {
        panes: [
          {
            paneType: PaneType.customPane,
            render: api => (
              <Fragment>
                <h3>{`Please confirm that the following changes have been sent to Translink:`}</h3>
                <div>
                  <b>{cubicItem?.description}</b> on <b>KBL-{assetName}</b>
                </div>
                <div>
                  Previous Serial Number: <b>{summaryItem.previousSerialNumber}</b>
                </div>
                <div>
                  New Serial Number: <b>{summaryItem.serialNumber}</b>
                </div>
                <div>
                  Reported By: <b>{summaryItem.reportedBy || 'N/A'}</b>
                </div>
              </Fragment>
            ),
          },
        ],
      },
    ],
    secondaryActions: [getSubmitCloseModalActionGroupDef('Confirm')],
    onFormSubmit: onProcess,
  });
}
