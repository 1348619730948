import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { createBrowserHistory } from 'history';
import { APP_INSIGHTS_INSTRUMENTATION_KEY, APP_INSIGHTS_ROLE_NAME } from './appSettings';

const browserHistory = createBrowserHistory({ basename: '' });
var reactPlugin = new ReactPlugin();
var appInsights = new ApplicationInsights({
  config: {
    instrumentationKey: APP_INSIGHTS_INSTRUMENTATION_KEY,
    extensions: [reactPlugin],
    extensionConfig: {
      [reactPlugin.identifier]: { history: browserHistory },
    },
  },
});
appInsights.loadAppInsights();

var telemetryInitializer = (envelope: any) => {
  const roleName = APP_INSIGHTS_ROLE_NAME.toLocaleLowerCase();
  envelope.tags['ai.cloud.role'] = roleName;
  envelope.tags['ai.cloud.roleInstance'] = roleName;
};
appInsights.addTelemetryInitializer(telemetryInitializer);

export { reactPlugin, appInsights };
