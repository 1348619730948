import inject from 'src/views/injectFromStore';
import Omit from 'src/infrastructure/omit';
import MaintainBoardingPoint, { IMaintainBoardingPointProps } from './maintainBoardingPoint';

const Container = inject<Omit<IMaintainBoardingPointProps, 'mode'>, IMaintainBoardingPointProps>(
  (allStores, p) => ({
    canManageBoardingPoints: allStores.rootStore.account.isSalesDepartmentMember,
    boardingPoint: Object.assign(
      {},
      allStores.rootStore.operations.sales.boardingPoint.boardingPoint
    ),
    loadBoardingPoint: allStores.rootStore.operations.sales.boardingPoint.loadBoardingPoint,
    updateBoardingPoint: allStores.rootStore.operations.sales.boardingPoint.updateBoardingPoint,
    deleteBoardingPoint: allStores.rootStore.operations.sales.boardingPoint.deleteBoardingPoint,
    createBoardingPoint: cmd =>
      allStores.rootStore.operations.sales.boardingPoint.createBoardingPoint(cmd, false),
    checkForUniqueName: allStores.rootStore.operations.sales.boardingPoint.checkForUniqueName,
    states: allStores.rootStore.operations.sales.boardingPoint.states.slice(),
    loadStates: allStores.rootStore.operations.sales.boardingPoint.loadStates,
  })
)(MaintainBoardingPoint);

export default Container;
