import { IAjax } from 'src/infrastructure/ajax';
import { peopleUrls } from 'src/domain/services/apiUrls';

export default function init(ajax: IAjax) {
  function getEmploymentStatuses() {
    return ajax
      .get(peopleUrls.staffMemberUrls.employmentStatuses.getAll())
      .toPromise()
      .then<Common.Dtos.EmploymentStatusDto[]>(r => r.response);
  }

  return {
    employmentStatuses: {
      getEmploymentStatuses,
    },
  };
}
