import styles from './WorkshopShiftPopup.module.scss';

import { Component } from 'react';
import { IWorkshopShiftItem } from 'src/domain/entities/operations/job/ListJobsForAllocationsModel';
import { IntervalFormat } from 'src/views/components/IntervalFormat';
import PopupField from './PopupField';
import cn from 'classnames';

interface IWorkshopShiftPopupProps {
  shift: IWorkshopShiftItem;
}

class WorkshopShiftPopup extends Component<IWorkshopShiftPopupProps> {
  render() {
    const { shift } = this.props;
    return (
      <section className={cn(styles.workshopShiftPopupComponent, 'allocations-item-popup')}>
        <PopupField
          className={styles.shiftNumber}
          label="Workshop Shift"
          value={`${shift.shiftNumber} • ${shift.shiftName}`}
          valueLinkTo={`/workshop/shifts/${shift.id}`}
        />
        <PopupField
          className={styles.shiftTiming}
          label="Scheduled"
          value={<IntervalFormat startValue={shift.begin} endValue={shift.end} />}
        />
      </section>
    );
  }
}

export default WorkshopShiftPopup;
