import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { formatDateShort } from 'src/domain/dateHelper';
import { useRootStore } from 'src/domain/entities/RootStoreModel';
import { EditIcon, TrashIcon, PlusIcon } from 'src/images/icons';
import Page from 'src/views/components/Page';
import PrimaryTitle from 'src/views/components/Page/PrimaryTitle/PrimaryTitle';
import { getSubmitCloseModalActionGroupDef } from 'src/views/definitionBuilders/common';
import {
  ActionType,
  FieldType,
  IModalDefBuilderApi,
  IPageDef,
  ISectionDef,
  PagePrimarySize,
  PaneType,
  ShellModalSize,
} from 'src/views/definitionBuilders/types';
import React from 'react';
import { ENABLE_SHOW_WAITING_TIME } from 'src/appSettings';

type HourlyRateItem = Operations.Domain.Queries.ListHourlyRates.HourlyRateItem;
type UpdateHourlyRatesCommand = Operations.Domain.Commands.HourlyRate.UpdateHourlyRatesCommand;
type CreateHourlyRatesCommand = Operations.Domain.Commands.HourlyRate.CreateHourlyRatesCommand;

const MaintainHourlyRates: React.FC = observer(() => {
  const rootStore = useRootStore();
  const hourlyRates = rootStore.operations.sales.hourlyRates.hourlyRates.slice();
  const loadHourlyRates = rootStore.operations.sales.hourlyRates.listHourlyRates;
  const updateHourlyRates = rootStore.operations.sales.hourlyRates.updateHourlyRates;
  const createHourlyRate = rootStore.operations.sales.hourlyRates.createHourlyRates;
  const deleteHourlyRate = rootStore.operations.sales.hourlyRates.deleteHourlyRates;
  const showWaitingField = ENABLE_SHOW_WAITING_TIME;
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const _beginningOfTime = '0001-01-01';
  const _endOfTime = '9999-12-31';

  useEffect(() => {
    setIsLoading(true);
    loadHourlyRates().then(() => setIsLoading(false));
  }, []);

  const getHourlyRateModalDef = (
    mode: 'add' | 'edit'
  ): ((modalDefApi: IModalDefBuilderApi) => ISectionDef) => {
    return modalDefApi => ({
      title: mode === 'add' ? 'Add Hourly Rates' : 'Edit Hourly Rates',
      asForm: true,
      explicitData: mode === 'add' ? {} : modalDefApi.actionData.parentValue,
      panels: [
        {
          panes: [
            {
              paneType: PaneType.formFieldsPane,
              fields: [
                {
                  fieldType: FieldType.dateField,
                  mandatory: true,
                  label: 'Start Date',
                  dataAddr: 'startDate',
                  readonly: d => d.fieldValue === _beginningOfTime,
                  formatReadonly: () => 'Beginning of Time',
                },
                {
                  fieldType: FieldType.numericField,
                  dataAddr: 'weekdayRate',
                  numericConfig: { numericType: 'unsignedDecimal', maxPointDigits: 2 },
                  label: 'Weekday',
                  formatReadonly: 'currency',
                  mandatory: true,
                },
                {
                  fieldType: FieldType.numericField,
                  dataAddr: 'saturdayRate',
                  numericConfig: { numericType: 'unsignedDecimal', maxPointDigits: 2 },
                  label: 'Saturday',
                  formatReadonly: 'currency',
                  mandatory: true,
                },
                {
                  fieldType: FieldType.numericField,
                  dataAddr: 'sundayRate',
                  numericConfig: { numericType: 'unsignedDecimal', maxPointDigits: 2 },
                  label: 'Sunday',
                  formatReadonly: 'currency',
                  mandatory: true,
                },
                {
                  fieldType: FieldType.numericField,
                  dataAddr: 'publicHolidayRate',
                  numericConfig: { numericType: 'unsignedDecimal', maxPointDigits: 2 },
                  label: 'Public Holiday',
                  formatReadonly: 'currency',
                  mandatory: true,
                },
                {
                  fieldType: FieldType.numericField,
                  dataAddr: 'weekdayWaitingRate',
                  numericConfig: { numericType: 'unsignedDecimal', maxPointDigits: 2 },
                  label: 'Weekday - Waiting',
                  formatReadonly: 'currency',
                  hidden: !showWaitingField,
                },
                {
                  fieldType: FieldType.numericField,
                  dataAddr: 'saturdayWaitingRate',
                  numericConfig: { numericType: 'unsignedDecimal', maxPointDigits: 2 },
                  label: 'Saturday - Waiting',
                  formatReadonly: 'currency',
                  hidden: !showWaitingField,
                },
                {
                  fieldType: FieldType.numericField,
                  dataAddr: 'sundayWaitingRate',
                  numericConfig: { numericType: 'unsignedDecimal', maxPointDigits: 2 },
                  label: 'Sunday - Waiting',
                  formatReadonly: 'currency',
                  hidden: !showWaitingField,
                },
                {
                  fieldType: FieldType.numericField,
                  dataAddr: 'publicHolidayWaitingRate',
                  numericConfig: { numericType: 'unsignedDecimal', maxPointDigits: 2 },
                  label: 'Public Holiday - Waiting',
                  formatReadonly: 'currency',
                  hidden: !showWaitingField,
                },
              ],
            },
          ],
        },
      ],
      secondaryActions: [getSubmitCloseModalActionGroupDef('Ok')],
      onFormPreSubmit: (
        hourlyRate: HourlyRateItem
      ): CreateHourlyRatesCommand | UpdateHourlyRatesCommand => {
        let cmd = {
          startDate: hourlyRate.startDate,
          weekdayRate: hourlyRate.weekdayRate,
          saturdayRate: hourlyRate.saturdayRate,
          sundayRate: hourlyRate.sundayRate,
          publicHolidayRate: hourlyRate.publicHolidayRate,
          weekdayWaitingRate: hourlyRate.weekdayWaitingRate,
          saturdayWaitingRate: hourlyRate.saturdayWaitingRate,
          sundayWaitingRate: hourlyRate.sundayWaitingRate,
          publicHolidayWaitingRate: hourlyRate.publicHolidayWaitingRate,
        };

        return mode === 'add'
          ? cmd
          : {
              ...cmd,
              id: hourlyRate.id,
            };
      },
      onFormSubmit: mode === 'add' ? createHourlyRate : updateHourlyRates,
    });
  };

  const getPageDef = (): IPageDef => {
    return {
      primarySize: PagePrimarySize.full,
      primarySection: {
        title: (
          <PrimaryTitle
            title="Manage Hourly Rates"
            link="https://www.notion.so/Defining-hourly-rates-946853521eeb455db7e640f8e7ed3075"></PrimaryTitle>
        ),
        panels: [
          {
            panes: [
              {
                paneType: PaneType.tablePane,
                dataAddr: 'hourlyRates',
                fields: [
                  {
                    fieldType: FieldType.textField,
                    dataAddr: 'startDate',
                    label: 'Start Date',
                    formatReadonly: d =>
                      d.fieldValue && d.fieldValue !== _beginningOfTime
                        ? formatDateShort(d.fieldValue)
                        : 'Beginning of Time',
                  },
                  {
                    fieldType: FieldType.textField,
                    dataAddr: 'endDate',
                    label: 'End Date',
                    formatReadonly: d =>
                      d.fieldValue && d.fieldValue !== _endOfTime
                        ? formatDateShort(d.fieldValue)
                        : '∞',
                  },
                  {
                    fieldType: FieldType.numericField,
                    dataAddr: 'weekdayRate',
                    numericConfig: { numericType: 'unsignedDecimal', maxPointDigits: 2 },
                    label: 'Weekday',
                    formatReadonly: 'currency',
                  },
                  {
                    fieldType: FieldType.numericField,
                    dataAddr: 'saturdayRate',
                    numericConfig: { numericType: 'unsignedDecimal', maxPointDigits: 2 },
                    label: 'Saturday',
                    formatReadonly: 'currency',
                  },
                  {
                    fieldType: FieldType.numericField,
                    dataAddr: 'sundayRate',
                    numericConfig: { numericType: 'unsignedDecimal', maxPointDigits: 2 },
                    label: 'Sunday',
                    formatReadonly: 'currency',
                  },
                  {
                    fieldType: FieldType.numericField,
                    dataAddr: 'publicHolidayRate',
                    numericConfig: { numericType: 'unsignedDecimal', maxPointDigits: 2 },
                    label: 'Public Holiday',
                    formatReadonly: 'currency',
                  },
                  {
                    fieldType: FieldType.numericField,
                    dataAddr: 'weekdayWaitingRate',
                    numericConfig: { numericType: 'unsignedDecimal', maxPointDigits: 2 },
                    label: showWaitingField ? 'Weekday - Waiting' : '',
                    formatReadonly: 'currency',
                    hidden: !showWaitingField,
                  },
                  {
                    fieldType: FieldType.numericField,
                    dataAddr: 'saturdayWaitingRate',
                    numericConfig: { numericType: 'unsignedDecimal', maxPointDigits: 2 },
                    label: showWaitingField ? 'Saturday - Waiting' : '',
                    formatReadonly: 'currency',
                    hidden: !showWaitingField,
                  },
                  {
                    fieldType: FieldType.numericField,
                    dataAddr: 'sundayWaitingRate',
                    numericConfig: { numericType: 'unsignedDecimal', maxPointDigits: 2 },
                    label: showWaitingField ? 'Sunday - Waiting' : '',
                    formatReadonly: 'currency',
                    hidden: !showWaitingField,
                  },
                  {
                    fieldType: FieldType.numericField,
                    dataAddr: 'publicHolidayWaitingRate',
                    numericConfig: { numericType: 'unsignedDecimal', maxPointDigits: 2 },
                    label: showWaitingField ? 'Public Holiday - Waiting' : '',
                    formatReadonly: 'currency',
                    hidden: !showWaitingField,
                  },
                  {
                    fieldType: FieldType.actionListField,
                    columnWidth: '1px',
                    nowrap: true,
                    actionGroups: [
                      {
                        actions: [
                          {
                            actionType: ActionType.modalActionButton,
                            label: 'Edit',
                            icon: <EditIcon />,
                            modalSize: ShellModalSize.oneThird,
                            modalDef: getHourlyRateModalDef('edit'),
                          },
                          {
                            actionType: ActionType.modalActionButton,
                            label: 'Delete',
                            hidden: _ => hourlyRates.length === 1,
                            icon: <TrashIcon size="sm" />,
                            modalSize: ShellModalSize.oneThird,
                            modalDef: _ => ({
                              title: () => 'Delete Hourly Rate',
                              asForm: true,
                              panels: [
                                {
                                  panes: [
                                    {
                                      paneType: PaneType.customPane,
                                      render: () => (
                                        <p>Are you sure you want to delete this hourly rate?</p>
                                      ),
                                    },
                                  ],
                                },
                              ],
                              secondaryActions: [getSubmitCloseModalActionGroupDef('Ok')],
                              onFormSubmit: d => deleteHourlyRate(d.id),
                            }),
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
              {
                paneType: PaneType.actionListPane,
                actionGroups: [
                  {
                    actions: [
                      {
                        actionType: ActionType.modalActionButton,
                        label: 'Add Rates',
                        icon: <PlusIcon />,
                        modalSize: ShellModalSize.oneThird,
                        modalDef: getHourlyRateModalDef('add'),
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
        onFormSubmit: updateHourlyRates,
      },
      secondarySections: [],
    };
  };

  return <Page data={{ hourlyRates }} showPrimarySectionSpinner={isLoading} def={getPageDef()} />;
});

export default MaintainHourlyRates;
