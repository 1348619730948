import { IAutocompleteResult, IListPageLoadDataRequest } from 'src/domain/baseTypes';
import { IListPageDef } from 'src/views/components/Page/pages/ListPage';
import { ListPage } from 'src/views/components/Page';
import {
  FieldType,
  ActionType,
  FieldDefs,
  PaneType,
  PagePrimarySize,
} from 'src/views/definitionBuilders/types';
import { useEffect } from 'react';
import { ExcelIcon, FilePdfIcon } from 'src/images/icons';
import { saveAs } from 'file-saver';
import { DateTime } from 'luxon';
import withQueryParams, { IQueryParamsProps } from 'src/views/hocs/withQueryParams';
import {
  allPartType,
  allTransactionType,
  PartType,
  TransactionType,
  transactionTypeDescription,
} from 'src/api/enums';
import { IMatchingPart } from 'src/domain/entities/workshop/parts/PartsModel';
import { formatNumber } from 'src/infrastructure/formattingUtils';
import PrimaryTitle from 'src/views/components/Page/PrimaryTitle/PrimaryTitle';

type GetPartTransactionsListQuery = Workshop.Domain.Queries.GetPartTransactions.GetPartTransactionsQuery;
type GetUsedPartsPdfReportQuery = Workshop.Domain.Queries.GetUsedPartsPdfReport.GetUsedPartsPdfReportQuery;
type PartTransactionItem = Workshop.Domain.Queries.GetPartTransactions.PartTransactionItem;
type AssetItem = Workshop.Domain.Queries.AssetItem;
type WorkshopDepotDto = Common.Queries.Workshop.GetWorkshopDepots.WorkshopDepotDto;
type PartItem = Workshop.Domain.Queries.Parts.PartItem;

export interface IListPartTransactionsProps {
  canManageParts: boolean;
  parts: PartTransactionItem[];
  hasMoreData: boolean;

  listPartTransactions: (
    request: IListPageLoadDataRequest<GetPartTransactionsListQuery>
  ) => Promise<void>;
  downloadUsedPartsExcel: (query: Partial<GetPartTransactionsListQuery>) => Promise<Blob>;
  downloadUsedPartsPdf: (query: Partial<GetUsedPartsPdfReportQuery>) => Promise<Blob>;
  optionAssets: Array<AssetItem>;
  loadAssets: () => Promise<void>;
  workshopDepots: Array<WorkshopDepotDto>;
  defaultWorkshopDepot: WorkshopDepotDto | undefined;
  searchParts: (search: string) => Promise<IAutocompleteResult<IMatchingPart>>;
  findParts: (partIds: string[]) => Promise<IAutocompleteResult<PartItem>>;
}

type InternalProps = IListPartTransactionsProps & IQueryParamsProps<GetPartTransactionsListQuery>;

const ListPartTransactions: React.FC<InternalProps> = (props: InternalProps) => {
  useEffect(() => {
    props.loadAssets();
  }, []);

  const getPageDef = (): IListPageDef => {
    const filterFieldDefsLookup = {
      startDate: {
        fieldType: FieldType.dateField,
        dataAddr: 'dateFrom',
        label: 'Date Used From',
        onBlur: api => {
          if (!api.formValues.dateTo) {
            api.setFormValue(['dateTo'], api.fieldData.fieldValue);
          }
          api.validateField(['dateTo']);
        },
      } as FieldDefs,
      endDate: {
        fieldType: FieldType.dateField,
        dataAddr: 'dateTo',
        label: 'Date Used To',
        validate: d => {
          if (!d.fieldValue || !d.parentValue.dateFrom) {
            return undefined;
          }
          const from = DateTime.fromISO(d.parentValue.dateFrom);
          const to = DateTime.fromISO(d.fieldValue);
          return from > to ? 'Date To cannot be earlier than Date Used From' : undefined;
        },
      } as FieldDefs,
      asset: {
        fieldType: FieldType.selectMultiField,
        label: 'Asset',
        dataAddr: 'assets',
        useValueOnly: true,
        valueKey: 'id',
        descriptionKey: 'name',
        optionItems: props.optionAssets,
      } as FieldDefs,
      partType: {
        fieldType: FieldType.selectMultiField,
        label: 'Part Type',
        dataAddr: 'partTypeIds',
        useValueOnly: true,
        valueKey: 'value',
        descriptionKey: 'description',
        optionItems: allPartType,
      } as FieldDefs,
      transactionType: {
        fieldType: FieldType.selectMultiField,
        label: 'Transaction Type',
        dataAddr: 'transactionTypes',
        useValueOnly: true,
        valueKey: 'value',
        descriptionKey: 'description',
        optionItems: allTransactionType,
      } as FieldDefs,
      workshopDepots: {
        fieldType: FieldType.selectMultiField,
        label: 'Depot',
        dataAddr: 'depotIds',
        optionItems: props.workshopDepots,
        useValueOnly: true,
        valueKey: 'id',
        descriptionKey: 'description',
      } as FieldDefs,
      parts: {
        fieldType: FieldType.selectAsyncMultiField,
        dataAddr: 'partIds',
        label: 'Part Numbers',
        valueKey: 'id',
        descriptionKey: 'partNumber',
        useValueOnly: true,
        loadOptionItems: props.searchParts,
        loadItems: props.findParts,
        optionRenderer: (o: IMatchingPart) => (
          <div style={{ whiteSpace: 'nowrap' }}>
            {o.partNumber} - {o.description}
          </div>
        ),
        valueRenderer: (o: PartItem) => <div style={{ whiteSpace: 'nowrap' }}>{o.partNumber}</div>,
      } as FieldDefs,
    };

    return {
      primaryTitle: <PrimaryTitle title="Part Transactions"></PrimaryTitle>,
      primarySize: PagePrimarySize.full,
      onLoadData: props.listPartTransactions,
      externalSearch: true,
      hasMoreData: props.hasMoreData,
      primaryActions: [
        {
          actions: [
            {
              actionType: ActionType.actionCollection,
              actionGroups: [
                {
                  actions: [
                    {
                      actionType: ActionType.actionButton,
                      label: 'Export Used Parts to Excel',
                      icon: <ExcelIcon fixedWidth />,
                      onClick: () => downloadUsedPartsExcel(),
                    },
                    {
                      actionType: ActionType.actionButton,
                      label: 'Export Used Parts to Pdf',
                      icon: <FilePdfIcon fixedWidth />,
                      onClick: () => downloadUsedPartsPdf(),
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
      primaryFields: [
        {
          fieldType: FieldType.textField,
          dataAddr: 'partNumber',
          label: 'Part Number',
          orderBy: 'partNumber',
          linkTo: d => `/workshop/parts/${d.parentValue.partId}`,
          columnWidth: '10rem',
        },
        {
          fieldType: FieldType.textField,
          dataAddr: 'description',
          label: 'Description',
          orderBy: 'description',
          columnWidth: 'auto',
        },
        {
          fieldType: FieldType.textField,
          dataAddr: 'partType.description',
          label: 'Type',
          orderBy: 'partType',
          columnWidth: 'auto',
        },
        {
          fieldType: FieldType.customField,
          dataAddr: 'transactionType',
          label: 'Transaction',
          columnWidth: 'auto',
          render: d => transactionTypeDescription(d.data.fieldValue),
        },
        {
          fieldType: FieldType.dateField,
          dataAddr: 'date',
          label: 'Date',
          orderBy: 'Date',
        },
        {
          fieldType: FieldType.customField,
          dataAddr: 'fakeDataAddr1',
          label: 'Record',
          render: d => {
            const transaction = d.data.parentValue as PartTransactionItem;
            if (transaction.transactionType === TransactionType.Used && transaction.taskId) {
              return (
                <span>
                  Task:{' '}
                  <a href={`/workshop/tasks/${transaction.taskId}`}>{transaction.taskNumber}</a>
                </span>
              );
            } else if (
              transaction.purchaseData &&
              (transaction.transactionType === TransactionType.Purchased ||
                transaction.transactionType === TransactionType.Returned)
            ) {
              return (
                <span>
                  PO:{' '}
                  <a href={`/workshop/purchase-orders/${transaction.purchaseData.purchaseOrderId}`}>
                    {transaction.purchaseData.purchaseOrderNumber}
                  </a>
                </span>
              );
            }

            return null;
          },
        },
        {
          fieldType: FieldType.customField,
          dataAddr: 'fakeDataAddr2',
          label: 'Reference',
          render: d => {
            const transaction = d.data.parentValue as PartTransactionItem;
            const purchaseData = transaction.purchaseData;
            const transactionType = transaction.transactionType;
            if (transactionType === TransactionType.Used && transaction.assetId) {
              return (
                <a href={`/workshop/assets/${transaction.assetId}`}>{transaction.assetName}</a>
              );
            } else if (transactionType === TransactionType.Purchased && purchaseData) {
              return (
                <span>
                  Invoice:{' '}
                  <a
                    href={`/workshop/purchase-orders/${purchaseData.purchaseOrderId}/goods-received/${purchaseData.goodsReceivedId}`}>
                    {purchaseData.goodsReceivedInvoiceNumber
                      ? purchaseData.goodsReceivedInvoiceNumber
                      : purchaseData.goodsReceivedId}
                  </a>
                </span>
              );
            } else if (transactionType === TransactionType.Adjustment) {
              return <span>{transaction.adjustmentReason}</span>;
            } else if (transactionType === TransactionType.Returned) {
              return (
                <span>
                  CNN:{' '}
                  <a
                    href={`/workshop/purchase-orders/${purchaseData.purchaseOrderId}/goods-received/${purchaseData.goodsReceivedId}/returned-part-credits/${purchaseData.creditNoteId}`}>
                    {purchaseData.creditNoteNumber
                      ? purchaseData.creditNoteNumber
                      : purchaseData.creditNoteId}
                  </a>
                </span>
              );
            }

            return null;
          },
        },
        {
          fieldType: FieldType.numericField,
          dataAddr: 'quantity',
          label: 'Quantity',
          orderBy: 'Quantity',
          columnWidth: '10rem',
        },
        {
          fieldType: FieldType.readonlyField,
          hidden: d => d.fieldValue === null,
          dataAddr: 'price',
          label: 'Price',
          formatReadonly: d => formatNumber(Number.parseFloat(d.fieldValue), 'currency'),
        },
        {
          fieldType: FieldType.textField,
          dataAddr: 'location.depotName',
          label: 'Depot',
        },
        {
          fieldType: FieldType.readonlyField,
          dataAddr: 'location.location',
          label: 'Location',
          columnWidth: '10rem',
          hidden: d => (d.parentValue as PartTransactionItem).partType.id === PartType.Service,
        },
        {
          fieldType: FieldType.numericField,
          dataAddr: 'minStockOnHand',
          label: 'Min Stock Level',
          hidden: d => (d.parentValue as PartTransactionItem).partType.id === PartType.Service,
        },
        {
          fieldType: FieldType.numericField,
          dataAddr: 'currentStockOnHand',
          label: 'Current Stock',
          hidden: d => (d.parentValue as PartTransactionItem).partType.id === PartType.Service,
        },
      ],
      filterAction: {
        filterFields: Object.keys(filterFieldDefsLookup).map(k => filterFieldDefsLookup[k]),
        filterDef: filterDefApi => [
          {
            panes: [
              {
                paneType: PaneType.formFieldsPane,
                fields: [filterFieldDefsLookup.asset],
              },
              {
                paneType: PaneType.formFieldsPane,
                columnCount: 2,
                fields: [filterFieldDefsLookup.startDate, filterFieldDefsLookup.endDate],
              },
              {
                paneType: PaneType.formFieldsPane,
                fields: [filterFieldDefsLookup.partType],
              },
              {
                paneType: PaneType.formFieldsPane,
                fields: [filterFieldDefsLookup.transactionType],
              },
              {
                paneType: PaneType.formFieldsPane,
                fields: [filterFieldDefsLookup.workshopDepots],
              },
              {
                paneType: PaneType.formFieldsPane,
                fields: [filterFieldDefsLookup.parts],
              },
            ],
          },
        ],
      },
    };
  };

  const downloadUsedPartsExcel = () => {
    const date = DateTime.local().toFormat('yyyyMMddHHmm');
    return props
      .downloadUsedPartsExcel({
        ...props.getQueryParams(),
        transactionTypes: [TransactionType.Used],
      })
      .then(r => {
        saveAs(r, `Used_Parts_Report_${date}.xlsx`);
      });
  };
  const downloadUsedPartsPdf = () => {
    const date = DateTime.local().toFormat('yyyyMMddHHmm');
    return props
      .downloadUsedPartsPdf({
        ...props.getQueryParams(),
      })
      .then(r => {
        saveAs(r, `Used_Parts_Report_${date}.pdf`);
      });
  };

  return <ListPage className="list-parts-component" data={props.parts} def={getPageDef()} />;
};

export default withQueryParams(ListPartTransactions);
