import { useEffect } from 'react';
import { FieldType, FieldDefs } from 'src/views/definitionBuilders/types/field';
import ListPage, { IListPageDef } from 'src/views/components/Page/pages/ListPage';
import {
  PaneType,
  ActionType,
  IModalDefBuilderApi,
  ISectionDef,
  ShellModalSize,
  PagePrimarySize,
} from 'src/views/definitionBuilders/types';
import { CheckIcon, FilePdfIcon, ExcelIcon, CsvIcon, EmailIcon } from 'src/images/icons';
import { allLicenceExpiryPeriod } from 'src/api/enums';
import { saveAs } from 'file-saver';
import { DateTime } from 'luxon';
import withQueryParams, { IQueryParamsProps } from 'src/views/hocs/withQueryParams';
import { getSubmitCloseModalActionGroupDef } from 'src/views/definitionBuilders/common';
import { TaskCardItem } from 'src/views/components/TaskCard';
import { useRootStore } from 'src/domain/entities/RootStoreModel';
import { observer } from 'mobx-react';
import PrimaryTitle from 'src/views/components/Page/PrimaryTitle/PrimaryTitle';

type ListStaffMembersQuery = People.Domain.Queries.ListStaffMembers.ListStaffMembersQuery;
type LicenceType = Common.AggregatesModel.People.StaffMemberAggregate.LicenceType;

const ListStaffMembers: React.FC<IQueryParamsProps<ListStaffMembersQuery>> = observer(
  (props: IQueryParamsProps<ListStaffMembersQuery>) => {
    const rootStore = useRootStore();
    const peopleModel = rootStore.people;
    const staffMembersModel = peopleModel.staffMembers;
    const accountModel = rootStore.account;
    const canCreateStaffMember = accountModel.isPeopleDepartmentMember;
    const canSendLicenceExpiryWarningEmails =
      accountModel.isAccountingDepartmentMember ||
      accountModel.isOperationsDepartmentMember ||
      accountModel.isPeopleDepartmentMember ||
      accountModel.isRailDepartmentMember ||
      accountModel.isSalesDepartmentMember ||
      accountModel.isTimesheetsDepartmentMember ||
      accountModel.isWorkshopDepartmentMember;

    const depots = peopleModel.depots.staffDepots;

    useEffect(() => {
      peopleModel.licences.loadAllLicenceTypes();
      peopleModel.depots.loadStaffDepots();
      peopleModel.roles.loadAllRoles();
    }, []);

    const getFilterFieldDefs = (
      allLicenceTypes: LicenceType[],
      depots: Common.Dtos.StaffDepotDto[]
    ) => {
      return {
        hasDriverAuthorization: {
          fieldType: FieldType.yesNoField,
          label: 'Driver Authorisation',
          dataAddr: 'hasDriverAuthorization',
          useValueOnly: true,
        } as FieldDefs,
        isWorkshopStaff: {
          fieldType: FieldType.yesNoField,
          label: 'Workshop Staff',
          dataAddr: 'isWorkshopStaff',
          useValueOnly: true,
        } as FieldDefs,
        active: {
          fieldType: FieldType.yesNoField,
          label: 'Active',
          dataAddr: 'active',
          useValueOnly: true,
        } as FieldDefs,
        licenceExpiryPeriod: {
          fieldType: FieldType.selectField,
          label: 'Licence Expiry Period',
          dataAddr: 'licenceExpiryPeriod',
          optionItems: allLicenceExpiryPeriod.slice(),
          valueKey: 'value',
          descriptionKey: 'description',
          useValueOnly: true,
        } as FieldDefs,
        licenceTypes: {
          fieldType: FieldType.selectMultiField,
          dataAddr: 'licenceTypeIds',
          label: 'Licence Type',
          valueKey: 'id',
          descriptionKey: 'description',
          optionItems: allLicenceTypes.slice(),
          useValueOnly: true,
        } as FieldDefs,
        depots: {
          fieldType: FieldType.selectMultiField,
          dataAddr: 'depotIds',
          label: 'Depot',
          valueKey: 'id',
          descriptionKey: 'description',
          optionItems: depots.slice(),
          useValueOnly: true,
        } as FieldDefs,
        roles: {
          fieldType: FieldType.selectMultiField,
          dataAddr: 'roleIds',
          label: 'Roles',
          valueKey: 'id',
          descriptionKey: 'name',
          optionItems: peopleModel.roles.roles.slice(),
          useValueOnly: true,
        } as FieldDefs,
      };
    };

    const savePdf = () => {
      const fileName = `Licence_Expiry_Warning_${DateTime.local().toFormat('yyyyMMddHHmm')}.pdf`;
      return staffMembersModel
        .generateLicenceExpiryWarning(props.getQueryParams())
        .then(blob => saveAs(blob, fileName));
    };

    const sendLicenceExpiryEmails = () => {
      return staffMembersModel.sendLicenceExpiryWarningEmails(props.getQueryParams());
    };

    const saveExcel = () => {
      const fileName = `Staff_Members_${DateTime.local().toFormat('yyyyMMddHHmm')}.xlsx`;
      return staffMembersModel
        .exportToExcel(props.getQueryParams())
        .then(blob => saveAs(blob, fileName));
    };

    const saveCurrentDAsToCSV = () => {
      const fileName = `Drivers_${DateTime.local().toFormat('yyyyMMddHHmm')}.csv`;
      return staffMembersModel.exportDAsToCSV().then(blob => saveAs(blob, fileName));
    };

    const saveLicencesExcel = () => {
      const fileName = `Staff_Members_Licences_${DateTime.local().toFormat('yyyyMMddHHmm')}.xlsx`;
      return staffMembersModel
        .exportLicencesToExcel(props.getQueryParams())
        .then(blob => saveAs(blob, fileName));
    };

    const saveEquipmentExcel = () => {
      const fileName = `Staff_Members_Equipment_${DateTime.local().toFormat('yyyyMMddHHmm')}.xlsx`;
      return staffMembersModel
        .exportEquipmentToExcel(props.getQueryParams())
        .then(blob => saveAs(blob, fileName));
    };

    const saveEquipmentCountsExcel = () => {
      const fileName = `Equipment_Counts_${DateTime.local().toFormat('yyyyMMddHHmm')}.xlsx`;
      return staffMembersModel
        .exportEquipmentCountsToExcel(props.getQueryParams())
        .then(blob => saveAs(blob, fileName));
    };

    const saveFileNotesExcel = () => {
      const fileName = `Staff_Member_File_Notes_${DateTime.local().toFormat('yyyyMMddHHmm')}.xlsx`;
      return staffMembersModel
        .exportFileNotesToExcel(props.getQueryParams())
        .then(blob => saveAs(blob, fileName));
    };

    const getPageDef = (): IListPageDef => {
      const filterFieldDefsLookup = getFilterFieldDefs(
        peopleModel.licences.allLicenceTypes,
        depots
      );
      return {
        primaryTitle: <PrimaryTitle title="Staff Members"></PrimaryTitle>,
        onLoadData: staffMembersModel.listItems,
        externalSearch: true,
        createLink: canCreateStaffMember ? TaskCardItem.people.createStaffMember : undefined,
        primarySize: PagePrimarySize.full,
        hasMoreData: staffMembersModel.hasMoreItems,
        primaryActions: [
          {
            actions: [
              {
                actionType: ActionType.actionCollection,
                actionGroups: [
                  {
                    actions: [
                      {
                        actionType: ActionType.actionButton,
                        label: 'Print Licence Expiry Warning Letters',
                        icon: <FilePdfIcon fixedWidth />,
                        onClick: () => savePdf(),
                      },
                      {
                        actionType: ActionType.modalActionButton,
                        label: 'Send Licence Expiry Warning Emails',
                        hidden: () => !canSendLicenceExpiryWarningEmails,
                        icon: <EmailIcon fixedWidth />,
                        modalSize: ShellModalSize.oneQuarter,
                        modalDef: _ => ({
                          title: 'Send Licence Expiry Warning Emails',
                          asForm: true,
                          panels: [
                            {
                              panes: [
                                {
                                  paneType: PaneType.customPane,
                                  render: () => (
                                    <>
                                      <p>
                                        Are you sure you want to send Licence Expiry Warning emails?
                                      </p>
                                    </>
                                  ),
                                },
                              ],
                            },
                          ],
                          secondaryActions: [getSubmitCloseModalActionGroupDef('Ok')],
                          onFormSubmit: () => {
                            return sendLicenceExpiryEmails();
                          },
                        }),
                      },
                      {
                        actionType: ActionType.actionButton,
                        label: 'Export all data To Excel',
                        icon: <ExcelIcon fixedWidth />,
                        onClick: () => saveExcel(),
                      },
                      {
                        actionType: ActionType.actionButton,
                        label: 'Export Current DAs To CSV',
                        icon: <CsvIcon fixedWidth />,
                        onClick: () => saveCurrentDAsToCSV(),
                      },
                      {
                        actionType: ActionType.actionButton,
                        label: 'Export Licences To Excel',
                        icon: <ExcelIcon fixedWidth />,
                        onClick: () => saveLicencesExcel(),
                      },
                      {
                        actionType: ActionType.actionButton,
                        label: 'Export Equipment To Excel',
                        icon: <ExcelIcon fixedWidth />,
                        onClick: () => saveEquipmentExcel(),
                      },
                      {
                        actionType: ActionType.actionButton,
                        label: 'Export Equipment Counts To Excel',
                        icon: <ExcelIcon fixedWidth />,
                        onClick: () => saveEquipmentCountsExcel(),
                      },
                      {
                        actionType: ActionType.actionButton,
                        label: 'Export File Notes To Excel',
                        icon: <ExcelIcon fixedWidth />,
                        onClick: () => saveFileNotesExcel(),
                      },
                      {
                        actionType: ActionType.modalActionButton,
                        label: 'Export Predeparture Checks to Excel',
                        icon: <ExcelIcon fixedWidth />,
                        modalDef: getPredepartureChecksModal(),
                        modalSize: ShellModalSize.oneThird,
                      },
                      {
                        actionType: ActionType.modalActionButton,
                        label: 'Export Clock Ons to Excel',
                        icon: <ExcelIcon fixedWidth />,
                        modalDef: getClockOnsModal(),
                        modalSize: ShellModalSize.oneThird,
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
        primaryFields: [
          {
            fieldType: FieldType.textField,
            dataAddr: 'name',
            label: 'Name',
            linkTo: d => `/people/staff-members/${d.parentValue.id}`,
          },
          {
            fieldType: FieldType.readonlyField,
            dataAddr: 'hasDriverAuthorisation',
            label: 'Driver Authorisation',
            formatReadonly: data =>
              data.parentValue.hasDriverAuthorisation ? <CheckIcon /> : null,
          },
          {
            fieldType: FieldType.readonlyField,
            dataAddr: 'isWorkshopStaff',
            label: 'Workshop Staff',
            formatReadonly: data => (data.parentValue.isWorkshopStaff ? <CheckIcon /> : null),
          },
          {
            fieldType: FieldType.readonlyField,
            dataAddr: 'employmentStatus',
            label: 'Employment status',
            formatReadonly: d => d.parentValue.employmentStatus.description,
          },
          {
            fieldType: FieldType.readonlyField,
            dataAddr: 'employeeId',
            label: 'Employee Id',
          },
          {
            fieldType: FieldType.readonlyField,
            dataAddr: 'mobile',
            label: 'Mobile',
          },
          {
            fieldType: FieldType.readonlyField,
            dataAddr: 'depotId',
            label: 'Depot',
            formatReadonly: fieldValue => {
              const depot = fieldValue?.fieldValue as number | undefined;
              return depots.find(d => d.id === depot)?.description;
            },
          },
        ],
        filterAction: {
          defaultValues: {
            active: true,
          },
          filterFields: Object.keys(filterFieldDefsLookup).map(k => filterFieldDefsLookup[k]),
        },
      };
    };

    const getPredepartureChecksModal = (): ((modalDefApi: IModalDefBuilderApi) => ISectionDef) => {
      return _ => ({
        title: 'Export Predeparture Checks',
        asForm: true,
        panels: [
          {
            panes: [
              {
                paneType: PaneType.formFieldsPane,
                fields: [
                  {
                    fieldType: FieldType.dateField,
                    label: 'From',
                    dataAddr: 'from',
                  },
                  {
                    fieldType: FieldType.dateField,
                    label: 'To',
                    dataAddr: 'to',
                    validate: d => {
                      if (!d.fieldValue || !d.parentValue.from) {
                        return undefined;
                      }
                      const from = DateTime.fromISO(d.parentValue.from);
                      const to = DateTime.fromISO(d.fieldValue);
                      return from > to ? 'Date To cannot be earlier than Date From' : undefined;
                    },
                  },
                ],
              },
            ],
          },
        ],
        secondaryActions: [getSubmitCloseModalActionGroupDef('Ok')],
        onFormSubmit: v => {
          const fileName = `PredepartureChecks_${DateTime.local().toFormat('yyyyMMddHHmm')}.xlsx`;
          return staffMembersModel
            .exportPredepartureChecksToExcel({
              ...props.getQueryParams(),
              DateFrom: v.from,
              DateTo: v.to,
            } as Operations.Domain.Queries.ListPredepartureChecks.ListPredepartureChecksQuery)
            .then(blob => saveAs(blob, fileName));
        },
      });
    };

    const getClockOnsModal = (): ((modalDefApi: IModalDefBuilderApi) => ISectionDef) => {
      return _ => ({
        title: 'Export Clock Ons',
        asForm: true,
        panels: [
          {
            panes: [
              {
                paneType: PaneType.formFieldsPane,
                fields: [
                  {
                    fieldType: FieldType.dateField,
                    label: 'From',
                    dataAddr: 'from',
                    mandatory: true,
                  },
                  {
                    fieldType: FieldType.dateField,
                    label: 'To',
                    dataAddr: 'to',
                    mandatory: true,
                    validate: d => {
                      if (!d.fieldValue || !d.parentValue.from) {
                        return undefined;
                      }
                      const from = DateTime.fromISO(d.parentValue.from);
                      const to = DateTime.fromISO(d.fieldValue);
                      return from > to ? 'Date To cannot be earlier than Date From' : undefined;
                    },
                  },
                ],
              },
            ],
          },
        ],
        secondaryActions: [getSubmitCloseModalActionGroupDef('Ok')],
        onFormSubmit: v => {
          const fileName = `ClockOns_${DateTime.local().toFormat('yyyyMMddHHmm')}.xlsx`;

          return staffMembersModel
            .exportClockOnsToExcel({
              ...props.getQueryParams(),
              dateFrom: v.from,
              dateTo: v.to,
            } as People.Domain.Queries.ExportStaffClockOnsToExcel.ListStaffMembersClockOnsQuery)
            .then(blob => saveAs(blob, fileName));
        },
      });
    };

    return (
      <ListPage
        className="list-staff-members-component"
        data={staffMembersModel.items.slice()}
        def={getPageDef()}
      />
    );
  }
);

export default withQueryParams(ListStaffMembers);
