import { useState } from 'react';
import Button, { ButtonProps } from 'reactstrap/lib/Button';

export interface IAutoDisablingButtonProps extends ButtonProps {
  onClick: () => void | Promise<unknown>;
  disabled?: boolean;
  className?: string;
  useReactStrapButton?: boolean;
}

export const SingleClickAwareButton: React.FC<IAutoDisablingButtonProps> = props => {
  const [isRunning, setIsRunning] = useState(false);
  const { onClick, disabled, className, useReactStrapButton, children, ...rest } = props;

  const handleOnClick = () => {
    setIsRunning(true);

    const result = onClick();
    if (result) {
      result.finally(() => setIsRunning(false));
    } else {
      setIsRunning(false);
    }
  };

  return useReactStrapButton ? (
    <Button
      className={className}
      disabled={disabled || isRunning}
      onClick={handleOnClick}
      {...rest}>
      {children}
    </Button>
  ) : (
    <button className={className} disabled={disabled || isRunning} onClick={handleOnClick}>
      {children}
    </button>
  );
};
