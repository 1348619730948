import './SignInFailed.scss';

const SignInFailed: React.FC = () => (
  <div className="sign-in-failed-component">
    <section className="error-section">
      <h1>:(</h1>
      <p>
        The system is currently unable to sign you in. Please contact your system administrator.
      </p>
    </section>
  </div>
);

export default SignInFailed;
