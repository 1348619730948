type JobTaskListItem = Workshop.Domain.Queries.JobTask.JobTaskListItem;
export function sortJobTaskListItemsForPurchaseOrders(items: JobTaskListItem[]): JobTaskListItem[] {
  if (!items || items.length === 0) {
    return items;
  }

  // Sort by status id (so that future and scheduled come first), then by task number.
  return items.sort((a, b) => {
    const statusDiff = a.status.id - b.status.id;

    if (statusDiff) {
      return statusDiff;
    }

    return a.jobTaskNumber - b.jobTaskNumber;
  });
}
