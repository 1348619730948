import './RepeatingPagePane.scss';
import { Component, Fragment } from 'react';
import { FormFeedback } from 'reactstrap';
import { ChangeState } from 'src/api/enums';
import { NestedField, withFormApi } from 'src/views/components/Page/forms';
import { IFormApi } from 'src/views/components/Page/forms/base';
import Spinner from 'src/views/components/Spinner';
import {
  IRepeatingPaneDef,
  IPaneData,
  IPaneMeta,
  IHasChangeState,
} from 'src/views/definitionBuilders/types';
import PagePane from './PagePane';
import cn from 'classnames';

interface IRepeatingPagePaneProps {
  formApi: IFormApi;
  paneDef: IRepeatingPaneDef;
  paneData: IPaneData;
  paneMeta: IPaneMeta;
}

class RepeatingPagePane extends Component<IRepeatingPagePaneProps> {
  private _isMounted = false;

  componentDidMount() {
    this._isMounted = true;
  }

  private readonly getDataItems = () => {
    const { paneValue } = this.props.paneData;
    if (Array.isArray(paneValue)) {
      return paneValue;
    }
    return [];
  };

  private readonly handleRemoveItem = (rowIdx: number) => {
    const { formApi, paneDef } = this.props;
    const removeFromArray = () => formApi.removeValue(formApi.getFullField(), rowIdx);

    if (paneDef.deleteRemovedItems) {
      removeFromArray();
      return;
    }

    const rows = this.getDataItems();
    const row = rows[rowIdx] as IHasChangeState;
    if (row.changeState === ChangeState.Added) {
      removeFromArray();
      return;
    }

    const updatedItems = [...rows];
    const updatedRow = {
      ...row,
      changeState:
        row.changeState === ChangeState.Deleted ? ChangeState.Modified : ChangeState.Deleted,
    };
    updatedItems.splice(rowIdx, 1, updatedRow);
    formApi.setValue(formApi.getFullField(), updatedItems);
  };

  // tslint:disable-next-line:no-any
  private readonly handleValidate = (value: any[]) => {
    const { paneDef: def, paneData } = this.props;

    const mandatoryValidate = def.mandatory
      ? (v: Array<IHasChangeState>) =>
          !v || !v.length || v.every(x => x.changeState === ChangeState.Deleted)
            ? `At least one item is required`
            : undefined
      : undefined;

    return def.mandatory || def.validate
      ? (mandatoryValidate && mandatoryValidate(value)) || (def.validate && def.validate(paneData))
      : undefined;
  };

  render() {
    const { formApi, paneDef, paneData, paneMeta } = this.props;
    const items = this.getDataItems();
    const useHover = paneDef.useHover !== undefined ? paneDef.useHover : true;
    const useDarkStyle = paneDef.useDarkStyle !== undefined ? paneDef.useDarkStyle : false;
    return (
      <div className="repeating-page-pane-component">
        <NestedField validate={paneMeta.readonly ? undefined : this.handleValidate}>
          {fieldApi => (
            <Fragment>
              <ul className="list-unstyled">
                {items.map((data, itemIdx) => {
                  const focusableItem =
                    this._isMounted && // Default rows should not be focused, even when they're status "added"
                    data.changeState &&
                    data.changeState === ChangeState.Added &&
                    itemIdx === items.length - 1;
                  return (
                    <NestedField key={itemIdx} field={itemIdx}>
                      <li
                        className={cn('repeated-panes', {
                          'dark-style': useDarkStyle,
                          'horizontal-scroll': paneDef.enableHorizontalScroll,
                        })}>
                        <div className={cn('repeated-panes-content', { hover: useHover })}>
                          {paneDef.itemPanes.map((p, subPaneIdx) => (
                            <PagePane
                              key={`${itemIdx}-${subPaneIdx}`}
                              paneDef={p}
                              paneMeta={{
                                ...paneMeta,
                                autoFocus: focusableItem && subPaneIdx === 0,
                                removeArrayItem: () => this.handleRemoveItem(itemIdx),
                              }}
                              panelData={paneData}
                              parentValue={data}
                            />
                          ))}
                        </div>
                      </li>
                    </NestedField>
                  );
                })}
              </ul>
              {!paneMeta.readonly &&
              formApi.submits > 0 &&
              fieldApi.error &&
              typeof fieldApi.error === 'string' ? (
                <FormFeedback className="repeating-errors">{fieldApi.error}</FormFeedback>
              ) : null}
              {paneDef.loading ? (
                <Spinner show>
                  <div style={{ height: '3em' }} />
                </Spinner>
              ) : null}
              {paneMeta.readonly &&
              !paneDef.loading &&
              !items.length &&
              paneDef.noItemsMessage !== null ? (
                <p className="no-rows-message">
                  <em>{paneDef.noItemsMessage || 'There are no items to display'}</em>
                </p>
              ) : null}
            </Fragment>
          )}
        </NestedField>
      </div>
    );
  }
}

export default withFormApi(RepeatingPagePane);
