import { ISectionDef, PaneType, FieldType } from 'src/views/definitionBuilders/types';
type ServiceCycleItem = Workshop.Domain.Queries.AssetGroup.GetAssetGroupFullServiceCycle.ServiceCycleItem;

export default function getFullServiceCycleSectionDef(
  fullServiceCycle: ServiceCycleItem[]
): ISectionDef {
  return {
    title: 'Full Service Cycle',
    hidden: !fullServiceCycle || !fullServiceCycle.length,
    explicitData: { fullServiceCycle },
    isTab: true,
    panels: [
      {
        hidden: d => !d.panelValue.length,
        dataAddr: 'fullServiceCycle',
        panes: [
          {
            paneType: PaneType.tablePane,
            neverEditable: true,
            mandatory: false,
            fields: [
              {
                fieldType: FieldType.textField,
                label: 'Service Type',
                dataAddr: ['serviceType', 'description'],
                columnWidth: '12em',
                readonly: true,
              },
              {
                fieldType: FieldType.numericField,
                label: 'Days Due',
                dataAddr: 'dueDays',
                columnWidth: '9em',
                readonly: true,
              },
              {
                fieldType: FieldType.numericField,
                label: 'Kms Due',
                dataAddr: 'dueKms',
                columnWidth: '9em',
                readonly: true,
              },
            ],
          },
        ],
      },
    ],
  };
}
