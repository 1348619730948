import { UiRouteName } from 'src/domain/baseTypes';
import { IRootStoreModel } from 'src/domain/entities/RootStoreModel';
import UiRoute from 'src/domain/services/buildUiRoutes/UiRoute';

export default function buildUiRoutes(store: IRootStoreModel) {
  const partsGroupCreate = new UiRoute(
    UiRouteName.workshopPartsGroupCreate,
    '/workshop/parts-groups/create',
    () => 'Create Parts Group',
    false
  );

  const partsGroupEdit = new UiRoute(
    UiRouteName.workshopPartsGroupEdit,
    '/workshop/parts-groups/:id',
    () => 'Manage Parts Group',
    false
  );

  const partsGroupsList = new UiRoute(
    UiRouteName.workshopPartsGroupsList,
    '/workshop/parts-groups',
    () => 'Parts Groups',
    false,
    false,
    [partsGroupCreate, partsGroupEdit]
  );

  return {
    [UiRouteName.workshopPartsGroupCreate]: partsGroupCreate,
    [UiRouteName.workshopPartsGroupEdit]: partsGroupEdit,
    [UiRouteName.workshopPartsGroupsList]: partsGroupsList,
  };
}
