import inject from 'src/views/injectFromStore';
import MaintainPurchaseOrderInvoices, {
  IMaintainPurchaseOrderInvoicesProps,
} from './MaintainPurchaseOrderInvoices';
import Omit from 'src/infrastructure/omit';

const Container = inject<
  Omit<IMaintainPurchaseOrderInvoicesProps, 'mode'>,
  IMaintainPurchaseOrderInvoicesProps
>(allStores => ({
  purchaseOrder: allStores.rootStore.workshop.purchaseOrder.purchaseOrder,
  loadPurchaseOrder: allStores.rootStore.workshop.purchaseOrder.loadPurchaseOrder,
  searchParts: allStores.rootStore.workshop.parts.searchParts,
  loadPurchaseOrderInvoices: allStores.rootStore.workshop.purchaseOrder.loadPurchaseOrderInvoices,
  purchaseOrderInvoices: allStores.rootStore.workshop.purchaseOrder.purchaseOrderInvoices.slice(),
}))(MaintainPurchaseOrderInvoices);

export default Container;
