import { DateTime } from 'luxon';
import { useEffect, useState } from 'react';
import InlineSvg from 'react-inlinesvg';
import { Link } from 'react-router-dom';
import Button from 'reactstrap/lib/Button';
import { Subject } from 'rxjs/Subject';
import { JobType } from 'src/api/enums';
import logoutRoundedUpIcon from 'src/images/logout_rounded_up.svg';
import memoizeOne from 'src/infrastructure/memoizeOne';
import Spinner from 'src/views/components/Spinner';
import DayNavigation from 'src/views/kioskRoutes/components/presentational/DayNavigation/DayNavigation';
import { JobItem } from 'src/views/kioskRoutes/operationsKiosk/home/JobItem/JobItem';
import Leaves from 'src/views/kioskRoutes/operationsKiosk/home/Leaves/Leaves';
import PrintCharterInstructions from 'src/views/kioskRoutes/operationsKiosk/home/PrintCharterInstructionsContainer';
import PrintRailInstructions from 'src/views/kioskRoutes/operationsKiosk/home/PrintRailInstructions/PrintRailInstructionsContainer';
import { isJobTypeAnyCharter } from 'src/views/routes/operations/shared/jobTypeHelpers';
import { ExpiredLicences } from '../../components/presentational/ExpiredLicences/ExpiredLicences';
import MenuButton from '../../components/presentational/MenuButton/MenuButton';
import { SingleClickAwareButton } from '../../components/presentational/SingleClickAwareButton/SingleClickAwareButton';
import Menu from '../Menu/Menu';
import './Home.scss';
import PrintDailySummaryButton from './PrintDailySummary/PrintDailySummaryButton';
import { useRootStore } from 'src/domain/entities/RootStoreModel';
import { useKioskRootStore } from 'src/domain/entities/KioskRootStoreModel';
import { TIMEZONE } from 'src/appSettings';
import { observer } from 'mobx-react';
import { AlertCard } from '../../components/presentational/Alerts/AlertCard';
import { faWarning } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Notes from '../../components/presentational/Notes/Notes';

type DriverJob = Operations.Domain.Queries.ListJobsForDriver.DriverJob;

const timezone = TIMEZONE;

const Home: React.FC = observer(() => {
  const rootStore = useRootStore();
  const rootKioskStore = useKioskRootStore();
  const kioskModel = rootKioskStore.kiosk;
  const notesModel = kioskModel.notes;
  const leavesModel = kioskModel.leaves;
  const accountModel = rootKioskStore.account;
  const timesheetModel = kioskModel.timesheet;
  const staffMemberId = rootStore.account.id;
  const jobsModel = kioskModel.jobs;
  const jobModel = kioskModel.job;
  const status = Object.assign({}, kioskModel.clockOn.status);
  const jobs = kioskModel.jobs.jobs.slice();
  const checks = kioskModel.jobs.predepartureChecks.slice();
  const date = kioskModel.date;
  const updateDate = kioskModel.updateDate;
  const notes = notesModel.notes.slice();
  const approvedLeaves = kioskModel.leaves.approvedLeaves.slice();
  const expiredLicences = rootKioskStore.account.expiredLicences.slice();
  const incompleteJobs = rootKioskStore.account.incompleteJobs.slice();
  const clockOff = kioskModel.clockOn.clockOff;

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isOpened, setIsOpened] = useState<boolean>(false);
  const valueSubject = new Subject<DateTime>();

  useEffect(() => {
    loadWhenDateChanges(date);
    const today = DateTime.local().startOf('day');
    const weekAway = today.plus({ days: 8 });

    leavesModel.loadWeeksLeavesForDriver(today, weekAway);
    valueSubject
      .debounceTime(500)
      .distinctUntilChanged()
      .subscribe(e => {
        loadWhenDateChanges(e);
      });
  }, [date]);

  const onDateChanged = (date: DateTime) => {
    updateDate(date);
    setIsLoading(true);
    valueSubject.next(date);
  };

  const loadWhenDateChanges = (date: DateTime) => {
    setIsLoading(true);
    const from = date.startOf('day').plus({ minutes: 1 });
    const to = date.endOf('day');
    Promise.all([
      timesheetModel.getTimesheetReminder({ day: date.toISODate() }),
      jobsModel.loadJobsForDriver(date),
      notesModel.loadNotesForDriver(date),
      leavesModel.loadLeavesForDriver(from, to),
      jobsModel.loadRailInstructionsForDriver(date),
      accountModel.loadExpiredLicences(),
      accountModel.loadIncompleteJobs(),
    ]).then(() => setIsLoading(false));
  };

  const formatDateTime = (datetime: string) => {
    return DateTime.fromISO(datetime).toLocaleString(DateTime.DATETIME_MED);
  };

  const jobStartsOnCurrentDay = (j: DriverJob) => {
    const pageDate = date;
    return pageDate.hasSame(DateTime.fromISO(j.clockOn), 'day');
  };

  const hasCharterJobs = (jobs || []).some(
    j => isJobTypeAnyCharter(j.jobType.id) && jobStartsOnCurrentDay(j)
  );

  const isRailJobs =
    jobs && jobs.filter(x => x.jobType.id === JobType.Rail) && jobsModel.hasRailInstructions;

  const getTimesheetReminder = memoizeOne(
    (date: DateTime, needToCompleteTimesheet: boolean | undefined) => {
      return (
        <AlertCard
          showAlert={needToCompleteTimesheet}
          heading="Timesheet"
          message="Please view and submit your timesheet"
          link="/kiosk/timesheet"
          linkText="View Timesheet"
          icon={<FontAwesomeIcon icon={faWarning} />}
          iconColor="#ffa02b"
        />
      );
    }
  );

  const getApprovedLeaveAlert = memoizeOne((approvedLeaves: Common.Dtos.LeaveDto[]) => {
    return <Leaves leaves={approvedLeaves} timezone={timezone} link="/kiosk/leave" />;
  });

  const getNotesAlert = memoizeOne(
    (notes: People.Domain.Queries.ListNotesForDriver.DriverNotes[]) => {
      return (
        <Notes
          notes={notes}
          markNotesReadForDriverOnDayHandler={notesModel.markNotesReadForDriverOnDay}
          date={date}
        />
      );
    }
  );

  const getIncompleteJobsAlert = memoizeOne(
    (
      incompleteJobs: Operations.Domain.Queries.ListIncompleteJobsForDriver.IncompleteJobForDriver[]
    ) => {
      return (
        <AlertCard
          showAlert={incompleteJobs.length > 0}
          heading="Incomplete Jobs"
          message="You have incomplete jobs in the last 7 days. Please complete the jobs by entering your completion details."
          icon={<FontAwesomeIcon icon={faWarning} />}
          iconColor="#ffa02b"
        />
      );
    }
  );

  return (
    <div className="home-component">
      {isOpened && (
        <Menu click={() => setIsOpened(false)} canUseTimesheets={accountModel.canUseTimesheets} />
      )}
      <Spinner show={isLoading} className="home-spinner" />
      <div className="header">
        <div className="menu">
          <div className="date-scroller">
            <DayNavigation
              onDateChanged={onDateChanged}
              maxDate={DateTime.local().plus({ days: 7 })}
              date={date}
              size="lg"
              todayText="View Today's Jobs"
            />
          </div>
          <MenuButton click={() => setIsOpened(!isOpened)} />
        </div>
        <div className="name">
          <h3>Welcome, {accountModel.checkedName}</h3>
          <h4>
            You have {jobs.length} jobs on{' '}
            <span className="date-for-name">{date.toLocaleString(DateTime.DATE_HUGE)}</span>
          </h4>
        </div>
      </div>
      {!isLoading && (
        <>
          <div className="alerts">
            {expiredLicences.length > 0 && (
              <ExpiredLicences licences={expiredLicences} timezone={timezone} />
            )}
            {getIncompleteJobsAlert(incompleteJobs)}
            {getNotesAlert(notes)}
            {getApprovedLeaveAlert(approvedLeaves)}
            {accountModel.canUseTimesheets &&
              getTimesheetReminder(date, timesheetModel.showReminder)}
          </div>
          <div className="clock-status">
            {status.isClockedOn
              ? `Clocked on at ${formatDateTime(status.clockOn as string)}`
              : 'You are not clocked on'}
          </div>

          <div className="print">
            <div className="daily-summary">
              {jobs.length > 0 && jobs.filter(jobStartsOnCurrentDay).length > 0 ? (
                <PrintDailySummaryButton
                  jobs={jobs.filter(jobStartsOnCurrentDay).map(j => {
                    return {
                      charterCustomerName: j.charterCustomer?.name,
                      jobId: j.id,
                      trainerName: j.parentJob?.staffMemberName,
                      traineeNames: j.childJobs?.map(cj => cj.staffMemberName),
                      isVehicleSwappedFrom: !!j.vehicleSwapJobDetails?.start,
                      ...j,
                    };
                  })}
                  userDisplayName={accountModel.checkedName}
                  date={date}
                  checks={checks}
                  checkDraft
                  checkColumns={3}
                />
              ) : null}
            </div>
            {hasCharterJobs && (
              <div className="charter-instructions">
                <PrintCharterInstructions />
              </div>
            )}
            {isRailJobs && (
              <div className="rail-instructions">
                <PrintRailInstructions />
              </div>
            )}
          </div>
          {jobs && jobs.length > 0 ? (
            <ul className="jobs list-unstyled">
              {jobs.map(job => (
                <JobItem
                  key={job.id}
                  date={date}
                  job={job}
                  acknowledgeJob={jobsModel.acknowledgeJob}
                  isSecondaryDriver={job.secondaryStaffMemberId === staffMemberId}
                  companyHasDriversApp={accountModel.companyHasDriversApp}
                  getJobAttachment={jobModel.getJobAttachment}
                  attachment={jobModel.attachment}
                />
              ))}
            </ul>
          ) : (
            <div className="jobs">
              <h4>You have no jobs on this day.</h4>
            </div>
          )}
        </>
      )}
      <div className="action-buttons">
        <div className="sign-out">
          <SingleClickAwareButton
            useReactStrapButton
            size="lg"
            type="button"
            onClick={() => accountModel.signOutKiosk()}>
            <InlineSvg className="icon-image" src={logoutRoundedUpIcon} />
            Close
          </SingleClickAwareButton>
        </div>
        <div className="clock">
          {status.isClockedOn ? (
            <Button
              size="lg"
              type="button"
              onClick={() => clockOff({ id: status.clockId as string })}>
              Clock Off
            </Button>
          ) : (
            <Link to="/kiosk/driver-declaration">
              <Button size="lg" type="button">
                Clock On
              </Button>
            </Link>
          )}
        </div>
      </div>
    </div>
  );
});

export default Home;
