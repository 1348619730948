import { AlertCard } from 'src/views/kioskRoutes/components/presentational/Alerts/AlertCard';
import styles from './ExpiredLicences.module.scss';
import { DateTime } from 'luxon';
import { faIdCard } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

type Licence = People.Domain.Queries.ListUpcomingLicenceExpirationsForStaffMember.LicenceExpirationForStaffMember;

export const formatMessageText = (licences: Licence[], timezone: string) => {
  if (licences.length === 0) {
    return '';
  }

  return (
    <>
      <div className={styles.licenceContainer}>
        The following items are due to expire soon. Once renewed, please provide an updated copy.
      </div>
      {licences.map(licence => {
        const expiry = DateTime.fromISO(licence.expiry!, { zone: timezone });
        const now = DateTime.local()
          .setZone(timezone)
          .startOf('day');
        const dueInDays = expiry.diff(now, 'days');
        return (
          <ul key={licence.licenceNumber} className={styles.licences}>
            <li>{licence.licenceType.description}</li>
            <ul>
              <li>
                <span>Expires: </span>
                {expiry.toLocaleString(DateTime.DATE_MED)} (in {dueInDays.days} days)
              </li>
            </ul>
          </ul>
        );
      })}
    </>
  );
};

export const ExpiredLicences = ({
  licences,
  timezone,
}: {
  licences: Licence[];
  timezone: string;
}) => (
  <AlertCard
    showAlert={licences.length > 0}
    heading="Expiring Licences"
    message={formatMessageText(licences, timezone)}
    icon={<FontAwesomeIcon icon={faIdCard} />}
    iconColor="#d22323"
  />
);
