import { IAjax } from 'src/infrastructure/ajax';
import { operationsUrls } from 'src/domain/services/apiUrls';

export default function init(ajax: IAjax) {
  function createRosterGroup(
    command: Operations.Domain.Commands.Roster.CreateRosterGroup.CreateRosterGroupCommand
  ) {
    return ajax
      .post(operationsUrls.rosterUrls.createRosterGroup(), command)
      .toPromise()
      .then<string>(r => r.response);
  }

  function updateRosterGroup(
    command: Operations.Domain.Commands.Roster.UpdateRosterGroup.UpdateRosterGroupCommand
  ) {
    return ajax.put(operationsUrls.rosterUrls.updateRosterGroup(command.id), command).toPromise();
  }

  function viewRosterGroup(rosterGroupId: string) {
    return ajax
      .get(operationsUrls.rosterUrls.viewRosterGroup(rosterGroupId))
      .toPromise()
      .then<Operations.Domain.Queries.ViewRosterGroup.RosterGroupItem>(r => r.response);
  }

  function deleteRosterGroup(rosterGroupId: string) {
    return ajax.httpDelete(operationsUrls.rosterUrls.deleteRosterGroup(rosterGroupId)).toPromise();
  }
  function listRosterGroups() {
    return ajax
      .get(operationsUrls.rosterUrls.getRosterGroups())
      .toPromise()
      .then(r => r.response as Operations.Domain.Queries.GetRosterGroups.RosterGroupDto[]);
  }

  function checkForUniqueRosterGroupName(name: string) {
    return ajax
      .get(operationsUrls.rosterUrls.checkForUniqueRosterGroup(name))
      .toPromise()
      .then<Common.Dtos.UniqueNameCheckResultDto>(r => r.response);
  }

  return {
    rosterGroups: {
      createRosterGroup,
      updateRosterGroup,
      deleteRosterGroup,
      viewRosterGroup,
      listRosterGroups,
      checkForUniqueRosterGroupName,
    },
  };
}
