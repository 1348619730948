import { getSubmitCloseModalActionGroupDef } from 'src/views/definitionBuilders/common';
import {
  PaneType,
  ModalDefBuilder,
  IActionGroupDef,
  ActionType,
  FieldType,
} from 'src/views/definitionBuilders/types';
import { ReactElement } from 'react';
import { CheckIcon } from 'src/images/icons';
import { Link } from 'react-router-dom';

type CanDeleteOwner = Workshop.Domain.Queries.Owners.GetCanOwnerBeDeleted.GetCanOwnerBeDeletedQueryResult;
type LinkedAsset = Workshop.Domain.Queries.Owners.GetCanOwnerBeDeleted.LinkedAsset;

const getModalText = (canDeleteOwner: CanDeleteOwner): ReactElement => {
  if (canDeleteOwner.canBeDeleted) {
    return (
      <>
        <p>Are you sure you want to delete this owner?</p>
      </>
    );
  } else if (canDeleteOwner.canBeInactive) {
    return (
      <>
        <p>This owner cannot be deleted, as it is linked to the assets listed below.</p>
        <p>
          As these assets have all been decommissioned, you can however mark the owner as inactive.
          Marking the owner as inactive will mean it won't be able to be linked to active assets.
        </p>
        <p>Please click Mark as Inactive below to confirm.</p>
      </>
    );
  }

  return (
    <>
      <p>This owner cannot be deleted as it is linked to the following assets:</p>
    </>
  );
};

const getSecondaryActions = (canDeleteOwner: CanDeleteOwner): IActionGroupDef[] => {
  if (canDeleteOwner.canBeInactive) {
    return [getSubmitCloseModalActionGroupDef('Mark as Inactive')];
  } else if (canDeleteOwner.canBeDeleted) {
    return [getSubmitCloseModalActionGroupDef('Delete')];
  }

  return [
    {
      actions: [
        {
          actionType: ActionType.closeModalActionButton,
          label: 'Cancel',
        },
      ],
    },
  ];
};

const getOnFormSubmitFunc = (
  ownerId: string,
  canDeleteOwner: CanDeleteOwner,
  deleteOwner: (ownerId: string) => Promise<void>,
  setOwnerInactive: (ownerId: string) => Promise<void>
): Promise<void> => {
  return canDeleteOwner.canBeInactive ? setOwnerInactive(ownerId) : deleteOwner(ownerId);
};

export default function GetCanOwnerBeDeletedModalDef(
  ownerId: string,
  canDeleteOwner: CanDeleteOwner,
  deleteOwner: (ownerId: string) => Promise<void>,
  setOwnerInactive: (ownerId: string) => Promise<void>
): ModalDefBuilder {
  return modalDefApi => ({
    title: canDeleteOwner.canBeInactive ? 'Mark as Inactive' : 'Delete Owner',
    asForm: true,
    explicitData: !canDeleteOwner.linkedAssets ? [] : canDeleteOwner,
    panels: [
      {
        panes: [
          {
            paneType: PaneType.customPane,
            render: api => getModalText(canDeleteOwner),
          },
          {
            paneType: PaneType.tablePane,
            hidden: _ => canDeleteOwner.canBeDeleted,
            dataAddr: 'linkedAssets',
            title: `Linked Assets`,
            neverEditable: true,
            fields: [
              {
                fieldType: FieldType.readonlyField,
                dataAddr: ['name'],
                label: 'Name',
                formatReadonly: d => {
                  if (!d.parentValue) {
                    return undefined;
                  }

                  const data = d.parentValue as LinkedAsset;
                  return <Link to={`/workshop/assets/${data.id}`}>{data.name}</Link>;
                },
              },
              {
                fieldType: FieldType.readonlyField,
                dataAddr: ['isDecommissioned'],
                label: 'Decommissioned',
                formatReadonly: d => (d.fieldValue ? <CheckIcon /> : undefined),
              },
            ],
          },
        ],
      },
    ],
    secondaryActions: getSecondaryActions(canDeleteOwner),
    onFormSubmit: _ => getOnFormSubmitFunc(ownerId, canDeleteOwner, deleteOwner, setOwnerInactive),
  });
}
