import './ToggleButtonPageField.scss';
import { ButtonGroup, Button } from 'reactstrap';
import { nonStrictEqual } from 'src/infrastructure/nonStrictEqual';
import { TimesIcon } from 'src/images/icons';
import { IFieldApi } from 'src/views/components/Page/forms/Field';
import { LabelledFormField } from 'src/views/components/forms';
import { IFieldMeta, IFieldData, IToggleButtonFieldDef } from 'src/views/definitionBuilders/types';
import FocusWrapper from 'src/views/components/FocusWrapper';
import ListSyncFieldBase from './fieldBases/ListSyncFieldBase';

interface IToggleButtonPageFieldProps {
  fieldDef: IToggleButtonFieldDef;
  fieldMeta: IFieldMeta;
  // tslint:disable-next-line:no-any
  fieldData: IFieldData<any>;
  fieldApi: IFieldApi;
}

class ToggleButtonPageField extends ListSyncFieldBase<IToggleButtonPageFieldProps> {
  // tslint:disable-next-line:no-any
  private readonly handleChange = (newOption: any) => {
    const { fieldApi, fieldDef: def } = this.props;
    const { setValue } = fieldApi;
    const newValue = def.useValueOnly ? newOption[def.valueKey] : newOption;
    setValue(newValue);
  };

  private readonly clearValue = () => {
    this.props.fieldApi.setValue(undefined);
  };

  private readonly getCurrentItem = () => {
    const { fieldDef: def, fieldData: data } = this.props;

    // But default, falsie values (except zero & false) return undefined, otherwise show the current value
    const getDefaultItem = () =>
      data.fieldValue || data.fieldValue === 0 || data.fieldValue === false
        ? {
            [def.valueKey]: data.fieldValue,
            [def.descriptionKey]: data.fieldValue,
          }
        : undefined;

    // If the field is "value only", attempt to find a corresponding option item, and just use the value otherwise
    if (def.useValueOnly) {
      return (
        this.items().find(i => nonStrictEqual(i[def.valueKey], data.fieldValue)) || getDefaultItem()
      );
    }

    // If the field has a description, then just use the field, otherwise look for the item
    const description = data.fieldValue[def.descriptionKey];
    const item =
      description === undefined || description === null
        ? this.items().find(i => i[def.valueKey] === data.fieldValue[def.valueKey])
        : data.fieldValue;

    // If no item was found, just use the value;
    return item || getDefaultItem();
  };

  render() {
    const { fieldApi, fieldDef: def, fieldMeta: meta, fieldData: data } = this.props;
    const { error, touched } = fieldApi;
    const currentItem = this.getCurrentItem();

    // As this field can have a value which is an object, then errors can be added to "lower" levels,
    // in which case `error` will be an object rather than a string
    const safeError = typeof error === 'string' ? error : undefined;
    const labelText = typeof def.label === 'function' ? def.label(data) : def.label;
    const tooltipText = typeof def.tooltip === 'function' ? def.tooltip(data) : def.tooltip;
    return (
      <LabelledFormField
        className="toggle-button-page-field-component"
        readonly={meta.readonly}
        readonlyValue={
          def.formatReadonly
            ? def.formatReadonly(data)
            : currentItem && currentItem[def.descriptionKey]
        }
        mandatory={meta.mandatory}
        hideLabel={meta.hideLabel}
        labelValue={labelText}
        tooltipValue={tooltipText}
        error={touched && safeError}>
        <FocusWrapper onBlur={this.handleBlur}>
          <div style={{ display: 'flex' }}>
            <ButtonGroup className="toggle-button-group">
              {this.items().map((option, idx) => (
                <Button
                  // autoFocus={autofocus && idx === 0}
                  key={idx}
                  className="toggle-button"
                  color="secondary"
                  outline
                  onClick={() => this.handleChange(option)}
                  active={currentItem && currentItem[def.valueKey] === option[def.valueKey]}
                  disabled={option.isDisabled ?? false}>
                  {`${option[def.descriptionKey]}`}
                </Button>
              ))}
            </ButtonGroup>
            {meta.mandatory ? null : (
              <Button
                title="Clear"
                outline
                style={{ border: 'none', marginLeft: '2px' }}
                onClick={this.clearValue}>
                <TimesIcon />
              </Button>
            )}
          </div>
        </FocusWrapper>
      </LabelledFormField>
    );
  }
}

export default ToggleButtonPageField;
