import { types, flow } from 'mobx-state-tree';
import { getAjax } from 'src/domain/services/storeEnvironment';

type KioskNoteDto = Workshop.Domain.Queries.GetKioskNote.KioskNoteDto;
type CreateOrUpdateKioskNoteCommand = Workshop.Domain.Commands.KioskNote.CreateOrUpdateKioskNoteCommand;

export const KioskNoteModel = types
  .model('KioskNoteModel', {
    kioskNote: types.maybe(types.frozen<KioskNoteDto>()),
  })
  .actions(self => {
    const ajax = getAjax(self);

    const loadKioskNote = flow(function*(depotId: number) {
      self.kioskNote = yield ajax.raw
        .get(`/api/kiosk/workshop/note?depotId=${depotId}`)
        .toPromise()
        .then<KioskNoteDto>(r => r.response);
    });

    const createOrUpdateKioskNote = flow(function*(command: CreateOrUpdateKioskNoteCommand) {
      yield ajax.raw.post(`/api/kiosk/workshop/note`, command).toPromise();
    });

    return {
      loadKioskNote,
      createOrUpdateKioskNote,
    };
  });
