import './getContinuationsSectionDef.scss';

import { Link } from 'react-router-dom';
import { Interval, DateTime } from 'luxon';
import {
  ISectionDef,
  PaneType,
  ActionType,
  IActionData,
  ShellModalSize,
  FieldType,
  ModalDefBuilder,
} from 'src/views/definitionBuilders/types';
import { LinkIcon, EditIcon, UnlinkIcon } from 'src/images/icons';
import { getSubmitCloseModalActionGroupDef } from 'src/views/definitionBuilders/common';
import { parseEditingFormattedTimeString } from 'src/views/components/Page/fields/subfields/TimeHelpers';
import { IntervalFormat } from 'src/views/components/IntervalFormat';
import ModalActionButton from 'src/views/components/Page/actions/ModalActionButton';
import { isJobTypeCharter } from 'src/views/routes/operations/shared/jobTypeHelpers';
import { DateTimeFormatSettings } from 'src/views/components/DateTimeFormat/DateTimeFormat';
import { TIMEZONE } from 'src/appSettings';

type JobItem = Operations.Domain.Queries.ViewJob.JobItem;
type Continuation = Operations.Domain.Queries.ViewJob.Continuation;
type ContinuationJob = Operations.Domain.Queries.ViewJob.ContinuationJob;

const titleText = 'Continue';
const timeFormat: typeof DateTimeFormatSettings = {
  hour: 'numeric',
  minute: '2-digit',
};

function getLinkModalDefBuilder(
  firstJobDesc: string,
  secondJobDesc: string,
  changeoverBounds: Interval,
  changeover: string | undefined,
  onLink: IJobActionsProps['onLink']
): ModalDefBuilder {
  const endEqualsStart = changeoverBounds.start.equals(changeoverBounds.end);
  return _ => ({
    title: 'Continue to Job',
    asForm: true,
    explicitData: { changeover },
    panels: [
      {
        panes: [
          {
            paneType: PaneType.customPane,
            render: () => {
              if (endEqualsStart) {
                return (
                  <p>
                    As <strong>{firstJobDesc}</strong> ends at the time that{' '}
                    <strong>{secondJobDesc}</strong> starts, the changeover time will be{' '}
                    <strong>{changeoverBounds.start.toLocaleString(timeFormat)}</strong>.
                  </p>
                );
              }

              return (
                <>
                  <p>
                    Choose the time at which <strong>{firstJobDesc}</strong> is considered to have
                    ended and <strong>{secondJobDesc}</strong> has started.
                  </p>
                  <p>
                    <span>It must be between </span>
                    <strong>{changeoverBounds.start.toLocaleString(timeFormat)}</strong>
                    <span> and </span>
                    <strong>{changeoverBounds.end.toLocaleString(timeFormat)}</strong>
                    <span>.</span>
                  </p>
                </>
              );
            },
          },
          {
            paneType: PaneType.formFieldsPane,
            fields: [
              {
                fieldType: FieldType.dateTimeField,
                dataAddr: 'changeover',
                label: 'Changeover Time',
                mandatory: true,
                readonly: () => endEqualsStart,
                validate: d => {
                  const value = d.fieldValue && DateTime.fromISO(d.fieldValue);
                  if (!value || !value.isValid) {
                    return undefined;
                  }
                  return changeoverBounds.contains(value) || changeoverBounds.end.equals(value)
                    ? undefined
                    : 'Changeover Time is invalid';
                },
              },
            ],
          },
        ],
      },
    ],
    secondaryActions: [getSubmitCloseModalActionGroupDef('Link')],
    onFormSubmit: d => onLink(d.changeover),
  });
}

function getUnlinkModalDefBuilder(
  firstJobDesc: string,
  secondJobDesc: string,
  onUnlink: IJobActionsProps['onUnlink']
): ModalDefBuilder {
  return api => ({
    title: 'Do not Continue to Job',
    asForm: true,
    panels: [
      {
        panes: [
          {
            paneType: PaneType.customPane,
            render: () => {
              return (
                <p>
                  Please confirm that you do not want <strong>{firstJobDesc}</strong> and{' '}
                  <strong>{secondJobDesc}</strong> to be linked.
                </p>
              );
            },
          },
        ],
      },
    ],
    secondaryActions: [getSubmitCloseModalActionGroupDef('Unlink')],
    onFormSubmit: onUnlink,
  });
}

interface IJobActionsProps {
  mainJob: JobItem;
  job: ContinuationJob;
  linked: boolean;
  changeover: string | undefined;
  onLink: (changeover: string) => Promise<void>;
  onUnlink: () => Promise<void>;
}

const calculateChangeOverBounds = (
  mainJobIsFirst: boolean,
  mainJob: JobItem,
  job: ContinuationJob,
  mainJobRun: Interval,
  jobRun: Interval
) => {
  if (mainJobIsFirst && mainJob.isCancelled) {
    return Interval.fromDateTimes(mainJobRun.start, jobRun.start);
  }
  if (!mainJobIsFirst && job.isCancelled) {
    return Interval.fromDateTimes(jobRun.start, mainJobRun.start);
  }
  if (mainJobRun.overlaps(jobRun)) {
    return mainJobRun.intersection(jobRun);
  }
  if (mainJobIsFirst) {
    return Interval.fromDateTimes(mainJobRun.end, jobRun.start);
  }
  return Interval.fromDateTimes(jobRun.end, mainJobRun.start);
};

const JobActions: React.FC<IJobActionsProps> = ({
  mainJob,
  job,
  linked,
  changeover,
  onLink,
  onUnlink,
}) => {
  const mainRunStart =
    (isJobTypeCharter(mainJob.jobType.id) && mainJob.onSite) || mainJob.shiftCommence;
  const mainJobRun = Interval.fromDateTimes(
    DateTime.fromISO(mainRunStart || mainJob.clockOn),
    DateTime.fromISO(mainJob.shiftEnd || mainJob.clockOff)
  );
  const runStart = (isJobTypeCharter(job.jobType.id) && job.onSite) || job.commence;
  const jobRun = Interval.fromDateTimes(DateTime.fromISO(runStart), DateTime.fromISO(job.end));

  const mainJobIsFirst = mainJobRun.start < jobRun.start;

  const changeoverBounds = calculateChangeOverBounds(
    mainJobIsFirst,
    mainJob,
    job,
    mainJobRun,
    jobRun
  );
  if (!changeoverBounds) {
    throw new Error('Could not determine changeoverBounds');
  }

  const defaultChangeover = changeoverBounds.start.plus({
    minutes: changeoverBounds.toDuration('minutes').as('minutes') / 2,
  });

  const mainJobDesc = `${mainJob.jobNumber}: ${mainJob.description}`;
  const jobDesc = `${job.jobNumber}: ${job.description}`;
  const [firstJobDesc, secondJobDesc] = mainJobIsFirst
    ? [mainJobDesc, jobDesc]
    : [jobDesc, mainJobDesc];

  return (
    <div className="actions">
      {linked ? (
        <>
          <ModalActionButton
            actionDef={{
              actionType: ActionType.modalActionButton,
              modalSize: ShellModalSize.oneQuarter,
              icon: <EditIcon />,
              label: 'Edit Continuation',
              modalDef: getLinkModalDefBuilder(
                firstJobDesc,
                secondJobDesc,
                changeoverBounds,
                changeover,
                onLink
              ),
            }}
            actionMeta={{ formSubmitting: false, hideLabel: true }}
            actionData={{} as IActionData}
          />
          <ModalActionButton
            actionDef={{
              actionType: ActionType.modalActionButton,
              modalSize: ShellModalSize.oneQuarter,
              icon: <UnlinkIcon />,
              label: 'Do not Continue to Job',
              modalDef: getUnlinkModalDefBuilder(firstJobDesc, secondJobDesc, onUnlink),
            }}
            actionMeta={{ formSubmitting: false, hideLabel: true }}
            actionData={{} as IActionData}
          />
        </>
      ) : (
        <ModalActionButton
          actionDef={{
            actionType: ActionType.modalActionButton,
            modalSize: ShellModalSize.oneQuarter,
            icon: <LinkIcon />,
            label: 'Continue to Job',
            modalDef: getLinkModalDefBuilder(
              firstJobDesc,
              secondJobDesc,
              changeoverBounds,
              defaultChangeover.toISO(),
              onLink
            ),
          }}
          actionMeta={{ formSubmitting: false, hideLabel: true }}
          actionData={{} as IActionData}
        />
      )}
    </div>
  );
};

interface IJobSectionProps {
  mainJob: JobItem;
  editingJob: boolean;
  job: ContinuationJob | undefined;
  title: string;
  noJobText: string;
  linked: boolean;
  changeover: string | undefined;
  onLink: IJobActionsProps['onLink'];
  onUnlink: IJobActionsProps['onUnlink'];
}

const JobSection: React.FC<IJobSectionProps> = ({
  mainJob,
  editingJob,
  job,
  linked,
  changeover,
  title,
  noJobText,
  onLink,
  onUnlink,
}) => {
  if (!job) {
    return (
      <section>
        <h4>{title}</h4>
        <em>{noJobText}</em>
      </section>
    );
  }

  return (
    <section>
      <h4>{title}</h4>
      <div className="job-detail">
        <div className="job">
          <div>
            <span>{job.jobType.description}: </span>
            <strong>
              <Link to={`/operations/jobs/${job.jobId}`}>{`${
                job.isCancelled ? '(Cancelled) ' : ''
              }${job.description}`}</Link>
            </strong>
          </div>
          <div>
            <span>Run: </span>
            <strong>
              <IntervalFormat startValue={job.commence} endValue={job.end} timezone={TIMEZONE} />
            </strong>
          </div>
        </div>
        {editingJob ? null : (
          <JobActions
            mainJob={mainJob}
            job={job}
            linked={linked}
            changeover={changeover}
            onLink={onLink}
            onUnlink={onUnlink}
          />
        )}
      </div>
    </section>
  );
};

const SummarySection: React.FC<{ continuation: Continuation }> = ({ continuation }) => {
  if (!continuation.continuationId) {
    return (
      <section>
        <em>Job is not currently in a continuation.</em>
      </section>
    );
  }

  const breaks = parseEditingFormattedTimeString(continuation.totalUnpaidBreaks);
  return (
    <section>
      <span>
        Continuation contains <strong>{continuation.jobCount}</strong> jobs
      </span>
      <ul>
        <li>
          <span>Scheduled: </span>
          <strong>
            <IntervalFormat
              startValue={continuation.clockOn}
              endValue={continuation.clockOff}
              timezone={TIMEZONE}
            />
          </strong>
        </li>
        <li>
          Total Unpaid breaks: <strong>{breaks ? breaks.toFormat('hh:mm') : '00:00'}</strong>
        </li>
      </ul>
    </section>
  );
};

export default function getContinuationsSectionDef(
  job: JobItem | undefined,
  props: {
    continueToNextJob: (changeover: string) => Promise<void>;
    continueFromPrevJob: (changeover: string) => Promise<void>;
    stopContinueToNextJob: () => Promise<void>;
    stopContinueFromPrevJob: () => Promise<void>;
  },
  editingJob: boolean,
  isCreateMode: boolean
): ISectionDef {
  if (!job || !job.continuation) {
    return {
      title: titleText,
      hidden: true,
      panels: [],
    };
  }

  const continuation = job.continuation;
  const linkedFrom = !!continuation.jobStart;
  const linkedTo = !!continuation.jobFinish;

  return {
    title: titleText,
    subtitle: getSubtitle(continuation),
    hidden: isCreateMode,
    panels: [
      {
        panes: [
          {
            paneType: PaneType.customPane,
            render: () => (
              <div className="continuations-section">
                <SummarySection continuation={continuation} />
                <JobSection
                  mainJob={job}
                  editingJob={editingJob}
                  job={continuation.fromJob}
                  linked={linkedFrom}
                  changeover={continuation.jobStart}
                  title={linkedFrom ? 'Continuing From' : 'Can Continue From'}
                  noJobText="There is no valid Job to continue from"
                  onLink={props.continueFromPrevJob}
                  onUnlink={props.stopContinueFromPrevJob}
                />
                <JobSection
                  mainJob={job}
                  editingJob={editingJob}
                  job={continuation.toJob}
                  linked={linkedTo}
                  changeover={continuation.jobFinish}
                  title={linkedTo ? 'Continuing To' : 'Can Continue To'}
                  noJobText="There is no valid Job to continue to"
                  onLink={props.continueToNextJob}
                  onUnlink={props.stopContinueToNextJob}
                />
              </div>
            ),
          },
        ],
      },
    ],
  };
}

function getSubtitle(continuation: Continuation): string | undefined {
  if (!continuation.fromJob && !continuation.toJob) {
    return undefined;
  }

  const linkedFrom = !!continuation.jobStart;
  const linkedTo = !!continuation.jobFinish;

  const from = continuation.fromJob ? (linkedFrom ? '◀' : '◁') : '';
  const to = continuation.toJob ? (linkedTo ? '▶' : '▷') : '';

  return from + to;
}
