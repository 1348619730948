import { IRootStoreModel } from 'src/domain/entities/RootStoreModel';
import { UiRoute } from 'src/domain/services/buildUiRoutes';
import { UiRouteName } from 'src/domain/baseTypes';

export default function buildUiRoutes(store: IRootStoreModel) {
  const timesheetAllowanceCreate = new UiRoute(
    UiRouteName.peopleTimesheetAllowanceCreate,
    '/people/timesheet-allowances/create',
    () => 'Create Timesheet Allowance',
    false
  );

  const timesheetAllowanceManage = new UiRoute(
    UiRouteName.peopleTimesheetAllowanceManage,
    '/people/timesheet-allowances/:id',
    () => 'Manage Timesheet Allowance',
    false
  );

  const timesheetAllowancesList = new UiRoute(
    UiRouteName.peopleTimesheetAllowanceList,
    '/people/timesheet-allowances',
    () => 'Timesheet Allowances',
    false,
    true,
    [timesheetAllowanceCreate, timesheetAllowanceManage]
  );

  return {
    [UiRouteName.peopleTimesheetAllowanceCreate]: timesheetAllowanceCreate,
    [UiRouteName.peopleTimesheetAllowanceList]: timesheetAllowancesList,
    [UiRouteName.peopleTimesheetAllowanceManage]: timesheetAllowanceManage,
  };
}
