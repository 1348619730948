import { Input } from 'reactstrap';
import { LabelledFormField } from 'src/views/components/forms';
import { IFieldMeta, IFieldData, IFileFieldDef } from 'src/views/definitionBuilders/types';
import { IFieldApi } from 'src/views/components/Page/forms/Field';
import { FilePdfIcon, TrashIcon } from 'src/images/icons';
import styles from './FilePageField.module.scss';
import { PureComponent } from 'react';

interface IFilePageFieldProps {
  fieldApi: IFieldApi;
  fieldDef: IFileFieldDef;
  fieldMeta: IFieldMeta;
  fieldData: IFieldData<{
    id?: string | number;
    file?: File;
    name: string;
    fileContent?: string;
    deleted?: string | number;
  }>;
}

class FilePageField extends PureComponent<IFilePageFieldProps> {
  private readonly handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = this.props.fieldApi.value as { deleted: boolean } | undefined;
    const existingDelete = value && value.deleted;
    this.props.fieldApi.setValue(
      e.target.files && e.target.files.length > 0
        ? {
            file: e.target.files[0],
            name: e.target.files[0].name,
            deleted: !!existingDelete
              ? existingDelete
              : this.props.fieldData && this.props.fieldData.fieldValue
              ? this.props.fieldData.fieldValue.id
              : null,
          }
        : !!existingDelete
        ? { deleted: existingDelete }
        : null
    );
  };

  private readonly handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    this.props.fieldApi.setTouched(true);
    this.props.fieldMeta.onBlur && this.props.fieldMeta.onBlur(this.props.fieldData.fieldValue);
  };

  render() {
    const { fieldApi, fieldDef: def, fieldMeta: meta, fieldData: data } = this.props;
    const { error, touched } = fieldApi;
    const currentValue = data && data.fieldValue ? data.fieldValue.name : undefined;
    const safeError = touched && error;
    const labelText = typeof def.label === 'function' ? def.label(data) : def.label;
    const tooltipText = typeof def.tooltip === 'function' ? def.tooltip(data) : def.tooltip;
    const fileContent =
      data && data.fieldValue && data.fieldValue.fileContent
        ? data.fieldValue.fileContent
        : undefined;

    const clearPdf = () => {
      this.props.fieldApi.setValue({
        deleted: data && data.fieldValue ? data.fieldValue.id : undefined,
      });
    };

    const readOnlyValue =
      meta.readonly && fileContent && currentValue ? (
        <a
          download={currentValue}
          href={`data:application/pdf;base64,${fileContent}`}
          title="Download pdf document">
          {currentValue} <FilePdfIcon fixedWidth />
        </a>
      ) : (
        currentValue
      );

    return (
      <LabelledFormField
        className="file-page-field-component"
        readonly={meta.readonly || false}
        readonlyValue={readOnlyValue}
        mandatory={meta.mandatory}
        hideLabel={meta.hideLabel}
        labelValue={labelText}
        tooltipValue={tooltipText}
        noForm={meta.noForm}
        error={safeError}>
        <div className={styles.filePageFieldEditContainer}>
          {fileContent && (
            <div>
              <span>
                Current File: <b>{currentValue}</b>
              </span>
              <div onClick={clearPdf}>
                <TrashIcon />
              </div>
            </div>
          )}
          <Input
            type="file"
            className={!safeError ? '' : 'invalid'}
            autoFocus={meta.autoFocus}
            onChange={this.handleChange}
            maxLength={def.maxLength}
            onBlur={this.handleBlur}
            autoComplete="off"
            accept={'application/pdf'}
          />
        </div>
      </LabelledFormField>
    );
  }
}

export default FilePageField;
