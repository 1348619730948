import ListPage, { IListPageDef } from 'src/views/components/Page/pages/ListPage';
import { FieldType } from 'src/views/definitionBuilders/types/field';
import TaskCardItem from 'src/views/components/TaskCard/TaskCardItem';
import { PagePrimarySize } from 'src/views/definitionBuilders/types';
import withQueryParams, { IQueryParamsProps } from 'src/views/hocs/withQueryParams';
import { observer } from 'mobx-react';
import { useRootStore } from 'src/domain/entities/RootStoreModel';
import PrimaryTitle from 'src/views/components/Page/PrimaryTitle/PrimaryTitle';

type ListQuery = Workshop.Domain.Queries.JobTaskSubcategories.List.ListJobTaskSubcategoriesQuery;

const ListTaskSubcategories: React.FC<IQueryParamsProps<ListQuery>> = observer(
  (props: IQueryParamsProps<ListQuery>) => {
    const rootStore = useRootStore();
    const taskSubcategoriesModel = rootStore.workshop.taskSubcategories;
    const listTaskSubcategories = taskSubcategoriesModel.listItems;
    const canManageTaskSubcategories = rootStore.account.isWorkshopDepartmentMember;
    const values = taskSubcategoriesModel.items.slice();

    const hasMoreData = taskSubcategoriesModel.hasMoreItems;
    const getPageDef = (): IListPageDef => {
      return {
        primaryTitle: (
          <PrimaryTitle
            title="Task Subcategories"
            link="https://www.notion.so/Task-Subcategories-70fbbfe57d1141b3a13255ffc621585c"></PrimaryTitle>
        ),
        primarySize: PagePrimarySize.full,
        onLoadData: listTaskSubcategories,
        createLink: canManageTaskSubcategories
          ? TaskCardItem.workshop.createTaskSubcategory
          : undefined,
        hasMoreData: hasMoreData,
        primaryFields: [
          {
            fieldType: FieldType.textField,
            dataAddr: 'jobTaskCategoryDescription',
            label: 'Category',
          },
          {
            fieldType: FieldType.textField,
            dataAddr: 'description',
            label: 'Description',
            linkTo: d => `/workshop/task-subcategories/${d.parentValue.jobTaskSubcategoryId}`,
          },
        ],
      };
    };
    return (
      <ListPage className="list-TaskSubcategories-component" data={values} def={getPageDef()} />
    );
  }
);
export default withQueryParams(ListTaskSubcategories);
