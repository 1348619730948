import { IListPageLoadDataRequest } from 'src/domain/baseTypes';
import { IListPageDef } from 'src/views/components/Page/pages/ListPage';
import { ListPage } from 'src/views/components/Page';
import { FieldType, PagePrimarySize } from 'src/views/definitionBuilders/types';
import { TaskCardItem } from 'src/views/components/TaskCard';
import { getDisplayDays } from 'src/views/routes/operations/urban/urbanHelpers';
import { DurationFormat } from 'src/views/components/DurationFormat';
import styles from './ListRosterTemplates.module.scss';
import PrimaryTitle from 'src/views/components/Page/PrimaryTitle/PrimaryTitle';

type ListRosterTemplatesQuery = Workshop.Domain.Queries.RosterTemplate.ListRosterTemplates.ListRosterTemplatesQuery;
type RosterTemplateListItem = Workshop.Domain.Queries.RosterTemplate.ListRosterTemplates.RosterTemplateListItem;
type RosterTemplateShiftItem = Workshop.Domain.Queries.RosterTemplate.ListRosterTemplates.RosterTemplateShiftItem;

export interface IListRosterTemplatesProps {
  canManageRosterTemplates: boolean;

  listRosterTemplates: (
    request: IListPageLoadDataRequest<ListRosterTemplatesQuery>
  ) => Promise<void>;
  rosterTemplates: RosterTemplateListItem[];
  hasMoreData: boolean;
}
const ListRosterTemplates: React.FC<IListRosterTemplatesProps> = (
  props: IListRosterTemplatesProps
) => {
  const getPageDef = (): IListPageDef => {
    return {
      primaryTitle: <PrimaryTitle title="Roster Templates"></PrimaryTitle>,
      onLoadData: props.listRosterTemplates,
      primarySize: PagePrimarySize.full,
      externalSearch: true,
      createLink: props.canManageRosterTemplates
        ? TaskCardItem.workshop.createRosterTemplate
        : undefined,
      hasMoreData: props.hasMoreData,
      primaryFields: [
        {
          fieldType: FieldType.textField,
          dataAddr: 'rosterTemplateName',
          label: 'Template Name',
          linkTo: d => `/workshop/roster-templates/${d.parentValue.rosterTemplateId}`,
        },
        {
          fieldType: FieldType.readonlyField,
          label: 'Details',
          formatReadonly: data => {
            const rosterTemplate = data.parentValue as RosterTemplateListItem;
            return (
              <div className={styles.listRosterTemplatesDetailsContainer}>
                <table className={styles.table}>
                  {rosterTemplate.shifts.map(x => {
                    return (
                      <tbody key={`${rosterTemplate.rosterTemplateId}${x.shiftTemplateId}`}>
                        <tr>
                          <td className={styles.shiftName}>
                            <strong>{x.shiftName}</strong>
                          </td>
                          <td className={styles.times}>
                            <DurationFormat value={x.shiftCommence} /> to{' '}
                            <DurationFormat value={x.shiftEnd} />
                          </td>
                          <td className={styles.days}>{daysForDisplay(x)}</td>
                          <td className={styles.staffMembers}>{staffMembersForDisplay(x)}</td>
                        </tr>
                      </tbody>
                    );
                  })}
                </table>
              </div>
            );
          },
        },
        {
          fieldType: FieldType.textField,
          dataAddr: 'depotDescription',
          label: 'Depot',
        },
      ],
    };
  };

  const daysForDisplay = (shift: RosterTemplateShiftItem): string => {
    if (shift) {
      const displayDays = getDisplayDays(shift);
      return displayDays.join(', ');
    }
    return '';
  };

  const staffMembersForDisplay = (shift: RosterTemplateShiftItem): string => {
    if (shift && shift.staffMembers) {
      const displayMembers = shift.staffMembers.map(x => x.staffMemberFullName);
      return displayMembers.join(', ');
    }
    return '';
  };

  return <ListPage data={props.rosterTemplates} def={getPageDef()} />;
};

export default ListRosterTemplates;
