import { types } from 'mobx-state-tree';
import { SalesModel } from './sales/SalesModel';
import { UrbanModel } from './urban/UrbanModel';
import { JobModel } from './job/JobModel';
import { DepotModel } from './depot/DepotModel';
import { ConflictModel } from './conflict/ConflictModel';
import { RailModel } from './rail/RailModel';
import { VehicleTypesModel } from './vehicleType/VehicleTypesModel';
import { VehicleTypeModel } from './vehicleType/VehicleTypeModel';
import { ExtraTypesModel } from './extraType/ExtraTypesModel';
import { JobTaskModel } from './jobTask/JobTaskModel';
import { OperationsDashboardModel } from './dashboard/OperationsDashboardModel';
import { AuditLogsModel } from './auditLogs/AuditLogsModel';
import { HolidaysListPageModel } from './holiday/HolidaysListPageModel';
import { HolidaysModel } from './holiday/HolidaysModel';
import { HolidayModel } from './holiday/HolidayModel';
import { SubcontractorsModel } from './subcontractor/SubcontractorsModel';
import { SubcontractorModel } from './subcontractor/SubcontractorModel';
import { ExclusionModel } from './exclusion/ExclusionModel';
import { AuthorisedDevicesModel } from './authorisedDevices/AuthorisedDevicesModel';
import { OperationsDiaryEntriesModel } from './opsDiary/OperationsDiaryEntriesModel';
import { OperationsDiaryEntryModel } from './opsDiary/OperationsDiaryEntryModel';
import { GenerateJobsOperationModel } from './generateJobOperations/GenerateJobsOperationModel';
import { AssetsWithUnknownKmsModel } from './assetsWithUnknownKms/AssetsWithUnknownKmsModel';
import { AllocationsModel } from 'src/domain/entities/operations/allocations/AllocationsModel';
import { BulkDriverAuthorizationUpdateOperationModel } from './processDriverAuthorization/BulkDriverAuthorizationUpdateOperationModel';
import { CharterContractModel } from './charterContract/CharterContractModel';
import { ListCharterContractsModel } from './charterContract/ListCharterContractsModel';
import { ContractCharterBookingsModel } from './sales/quote/ContractCharterBookingsModel';

export const OperationsModel = types.model('OperationsModel', {
  authorisedDevices: types.optional(AuthorisedDevicesModel, {}),
  dashboard: types.optional(OperationsDashboardModel, {}),
  auditLogs: types.optional(AuditLogsModel, {}),
  sales: types.optional(SalesModel, {}),
  urban: types.optional(UrbanModel, {}),
  job: types.optional(JobModel, {}),
  jobTask: types.optional(JobTaskModel, {}),
  conflict: types.optional(ConflictModel, {}),
  depot: types.optional(DepotModel, {}),
  rail: types.optional(RailModel, {}),
  extraTypes: types.optional(ExtraTypesModel, {}),
  vehicleTypes: types.optional(VehicleTypesModel, {}),
  vehicleType: types.optional(VehicleTypeModel, {}),
  holidaysListPage: types.optional(HolidaysListPageModel, {}),
  holidays: types.optional(HolidaysModel, {}),
  holiday: types.optional(HolidayModel, {}),
  operationsDiaryEntries: types.optional(OperationsDiaryEntriesModel, {}),
  operationsDiaryEntry: types.optional(OperationsDiaryEntryModel, {}),
  subcontractors: types.optional(SubcontractorsModel, {}),
  subcontractor: types.optional(SubcontractorModel, {}),
  exclusionModel: types.optional(ExclusionModel, {}),
  generateJobsOperation: types.optional(GenerateJobsOperationModel, {}),
  assetsWithUnknownKms: types.optional(AssetsWithUnknownKmsModel, {}),
  allocations: types.optional(AllocationsModel, {}),
  charterContracts: types.optional(CharterContractModel, {}),
  charterContractsList: types.optional(ListCharterContractsModel, {}),
  contractCharterBookings: types.optional(ContractCharterBookingsModel, {}),
  bulkDriverAuthorizationUpdateOperation: types.optional(
    BulkDriverAuthorizationUpdateOperationModel,
    {}
  ),
});

type IOperationsModelType = typeof OperationsModel.Type;
export interface IOperationsModel extends IOperationsModelType {}
