interface IJobFormatterProps {
  job: IJob;
}

interface ICharterCustomer {
  contactName: string;
  id: string;
  name: string;
}

interface IJob {
  jobType: Operations.Domain.AggregatesModel.JobAggregate.JobType;
  shiftName?: string;
  description: string;
  charterCustomer: ICharterCustomer;
  isCancelled: boolean;
}

const JobFormatter: React.FC<IJobFormatterProps> = ({ job }) => {
  const jobName = job.shiftName || (job.charterCustomer && job.charterCustomer.name);
  const isCancelled = job.isCancelled;
  const jobFormat = jobName ? ` - ${jobName}` : '';
  return (
    <div>
      <p className="job-name">
        {isCancelled ? `(Cancelled) ` : ''}
        {job.jobType.description}
        {jobFormat}
      </p>
      <p className="job-description">{job.description}</p>
    </div>
  );
};

export default JobFormatter;
