import { IAjax } from 'src/infrastructure/ajax';
import { workshopUrls } from 'src/domain/services/apiUrls';

export default function init(ajax: IAjax) {
  function listAssetPartCategories() {
    return ajax
      .get(workshopUrls.listAssetPartCategories.listAssetPartCategories())
      .toPromise()
      .then<Workshop.Domain.Queries.AssetPartCategory.AssetPartCategoryItem[]>(r => {
        return r.response;
      });
  }

  return {
    assetPartCategories: {
      listAssetPartCategories,
    },
  };
}
