import saveAs from 'file-saver';
import { DateTime } from 'luxon';
import { observer } from 'mobx-react';
import React, { Fragment, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { RailBookingStatus, allRailBookingStatus } from 'src/api/enums';
import { useRootStore } from 'src/domain/entities/RootStoreModel';
import { CheckIcon, ExcelIcon } from 'src/images/icons';
import PrimaryTitle from 'src/views/components/Page/PrimaryTitle/PrimaryTitle';
import ListPage, { IListPageDef } from 'src/views/components/Page/pages/ListPage';
import ExtraDetails from 'src/views/components/operations/ExtraDetails/ExtraDetails';
import { ActionType } from 'src/views/definitionBuilders/types';
import { FieldDefs, FieldType } from 'src/views/definitionBuilders/types/field';
import { PagePrimarySize } from 'src/views/definitionBuilders/types/page';
import { PaneType } from 'src/views/definitionBuilders/types/pane';
import withQueryParams, { IQueryParamsProps } from 'src/views/hocs/withQueryParams';

type ListRailBookingsForAccountsQuery = Operations.Domain.Queries.ListRailBookingsForAccounts.ListRailBookingsForAccountsQuery;
type RailBookingItemForAccounts = Operations.Domain.Queries.ListRailBookingsForAccounts.RailBookingItemForAccounts;

type InternalProps = IQueryParamsProps<ListRailBookingsForAccountsQuery>;

const RailBookingsForAccounting: React.FC<InternalProps> = observer((props: InternalProps) => {
  const rootStore = useRootStore();
  const listRailBookings = rootStore.operations.sales.railBookings.listItems;
  const railBookings = rootStore.operations.sales.railBookings.items.slice();
  const hasMoreData = rootStore.operations.sales.railBookings.hasMoreItems;
  const extraTypes = rootStore.operations.extraTypes.list.railExtraTypes.slice();
  const loadExtraTypes = rootStore.operations.extraTypes.list.loadExtraTypes;
  const assetHousingLocations = rootStore.asset.housedAtLocations.slice();
  const loadAssetHousedAtLocations = rootStore.asset.loadAssetHousedAtLocations;
  const exportToExcel = rootStore.operations.sales.railBookings.exportRailBookingsToExcel;
  const allRailBookingContractTypes = rootStore.operations.rail.railBookingContractTypes;
  const loadAllRailBookingContractTypes = rootStore.operations.rail.loadRailBookingContractTypes;

  useEffect(() => {
    loadExtraTypes();
    loadAssetHousedAtLocations();
    loadAllRailBookingContractTypes();
  }, []);

  const getFilterFieldDefs = () => {
    return {
      firstTripFrom: {
        fieldType: FieldType.dateField,
        dataAddr: 'firstTripFrom',
        label: 'First Trip From',
      } as FieldDefs,
      firstTripTo: {
        fieldType: FieldType.dateField,
        dataAddr: 'firstTripTo',
        label: 'First Trip To',
        validate: d => {
          if (!d.fieldValue || !d.parentValue.firstTripFrom) {
            return undefined;
          }
          const from = DateTime.fromISO(d.parentValue.firstTripFrom);
          const to = DateTime.fromISO(d.fieldValue);
          return from > to ? 'First Trip To cannot be earlier than First Trip From' : undefined;
        },
      } as FieldDefs,
      lastTrip: {
        fieldType: FieldType.dateField,
        label: 'Booking Ending On',
        dataAddr: 'lastTrip',
      } as FieldDefs,
      hasInvoiceNumber: {
        fieldType: FieldType.yesNoField,
        label: 'Has Invoice Number',
        dataAddr: 'hasInvoiceNumber',
        useValueOnly: true,
      } as FieldDefs,
      hasTradingAccount: {
        fieldType: FieldType.yesNoField,
        label: 'Has Trading Account',
        dataAddr: 'hasTradingAccount',
        useValueOnly: true,
      } as FieldDefs,
      hasContractTypes: {
        fieldType: FieldType.selectMultiField,
        label: 'Contract Type',
        dataAddr: 'contractTypes',
        useValueOnly: true,
        valueKey: 'id',
        descriptionKey: 'description',
        optionItems: allRailBookingContractTypes,
      } as FieldDefs,
      statuses: {
        fieldType: FieldType.selectMultiField,
        label: 'Status',
        dataAddr: 'statuses',
        useValueOnly: true,
        valueKey: 'value',
        descriptionKey: 'description',
        optionItems: allRailBookingStatus,
      } as FieldDefs,
      extraTypeIds: {
        fieldType: FieldType.selectMultiField,
        label: 'Facility',
        dataAddr: 'extraTypeIds',
        useValueOnly: true,
        valueKey: 'id',
        descriptionKey: 'description',
        optionItems: extraTypes.filter(x => x.showInAccounting),
        valuesToExclude: extraTypes.filter(t => !t.isActive).map(t => t.id),
      } as FieldDefs,
      garagedAt: {
        fieldType: FieldType.selectField,
        label: 'Garaged At',
        dataAddr: 'housingLocations',
        useValueOnly: true,
        valueKey: 'id',
        descriptionKey: 'description',
        optionItems: assetHousingLocations,
      } as FieldDefs,
    };
  };

  const getPageDef = (): IListPageDef => {
    const filterFieldDefsLookup = getFilterFieldDefs();
    return {
      primaryTitle: <PrimaryTitle title="Rail Bookings"></PrimaryTitle>,
      onLoadData: listRailBookings,
      externalSearch: true,
      hasMoreData,
      primarySize: PagePrimarySize.full,
      primaryActions: [
        {
          actions: [
            {
              actionType: ActionType.actionCollection,
              actionGroups: [
                {
                  actions: [
                    {
                      actionType: ActionType.actionButton,
                      label: 'Export To Excel',
                      icon: <ExcelIcon fixedWidth />,
                      onClick: () => saveExcel(),
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
      primaryFields: [
        {
          fieldType: FieldType.textField,
          dataAddr: 'bookingNumber',
          label: 'Booking Number',
          linkTo: d => `/operations/rail/rail-bookings/${d.parentValue.id}`,
          columnWidth: '6em',
          orderBy: 'BookingNumber',
        },
        {
          fieldType: FieldType.textField,
          dataAddr: ['customerId', 'customerName'],
          label: 'Customer',
          columnWidth: '10em',
          formatReadonly: d => {
            if (!d.parentValue.customerId) {
              return null;
            }

            return (
              <Fragment>
                <div title={d.parentValue.customerName} className="customer">
                  <Link to={`/sales/customers/${d.parentValue.customerId}`}>
                    {d.parentValue.customerName}
                  </Link>
                </div>
                <div>{d.parentValue.contactName}</div>
                {d.parentValue.hasTradingAccount && (
                  <div>
                    <CheckIcon /> Trading Account{' '}
                  </div>
                )}
              </Fragment>
            );
          },
          orderBy: 'CustomerName',
        },
        {
          fieldType: FieldType.dateField,
          dataAddr: 'firstTrip',
          label: 'First Trip',
          orderBy: 'StartJobClockOn',
        },
        {
          fieldType: FieldType.readonlyField,
          dataAddr: ['status', 'description'],
          label: 'Completed',
          formatReadonly: data =>
            data.parentValue.status === RailBookingStatus.Completed ? <CheckIcon /> : null,
          orderBy: 'Status',
        },
        {
          fieldType: FieldType.textField,
          dataAddr: 'invoiceNumber',
          label: 'Invoice Number',
          orderBy: 'InvoiceNumber',
          formatReadonly: d => d.parentValue.invoiceNumber,
        },
        {
          fieldType: FieldType.textField,
          dataAddr: 'description',
          label: 'Description',
        },
        {
          fieldType: FieldType.customField,
          label: 'Facilities',
          dataAddr: 'extras',
          render: d => {
            const extras = d.data.parentValue.extras;
            const id = d.data.parentValue.id;
            return <ExtraDetails extras={extras} id={id} />;
          },
        },
        {
          fieldType: FieldType.readonlyField,
          label: 'Summary ',
          columnWidth: '18em',
          formatReadonly: data => {
            var booking = data.parentValue as RailBookingItemForAccounts;
            return (
              <div>
                {booking.vehicles.map((v, i) => (
                  <div key={i}>
                    {v.quantity} × {v.description}
                  </div>
                ))}
                <div>
                  <small>Total Price: </small>
                  <strong>${booking.totalPrice.toFixed(2)}</strong>
                  <small> (inc. GST)</small>
                </div>
              </div>
            );
          },
        },
      ],
      filterAction: {
        defaultValues: {
          firstTripTo: DateTime.local()
            .plus({ days: 30 })
            .toISODate(),
          statuses: [RailBookingStatus.Booked],
          orderBy: 'StartJobClockOn~asc',
        },
        filterFields: Object.keys(filterFieldDefsLookup).map(k => filterFieldDefsLookup[k]),
        filterDef: () => [
          {
            panes: [
              {
                paneType: PaneType.formFieldsPane,
                columnCount: 2,
                fields: [
                  filterFieldDefsLookup.firstTripFrom,
                  filterFieldDefsLookup.firstTripTo,
                  filterFieldDefsLookup.lastTrip,
                ],
              },
              {
                paneType: PaneType.formFieldsPane,
                fields: [
                  filterFieldDefsLookup.hasInvoiceNumber,
                  filterFieldDefsLookup.hasTradingAccount,
                  filterFieldDefsLookup.hasContractTypes,
                  filterFieldDefsLookup.statuses,
                  filterFieldDefsLookup.extraTypeIds,
                  filterFieldDefsLookup.garagedAt,
                ],
              },
            ],
          },
        ],
      },
    };
  };

  const saveExcel = () => {
    const fileName = `Rail_Bookings_For_Accounting_${DateTime.local().toFormat(
      'yyyyMMddHHmm'
    )}.xlsx`;
    return exportToExcel(props.getQueryParams()).then(blob => saveAs(blob, fileName));
  };

  return <ListPage className="accounting-component" data={railBookings} def={getPageDef()} />;
});

export default withQueryParams(RailBookingsForAccounting);
