import { allFileNoteType } from 'src/api/enums';
import { getSubmitCloseModalActionGroupDef } from 'src/views/definitionBuilders/common';
import { PaneType, FieldType, ModalDefBuilder } from 'src/views/definitionBuilders/types';

type CreateStaffMemberFileNoteCommand = People.Domain.Commands.StaffMembers.CreateStaffMemberFileNote.CreateStaffMemberFileNoteCommand;
type UpdateStaffMemberFileNoteCommand = People.Domain.Commands.StaffMembers.UpdateStaffMemberFileNote.UpdateStaffMemberFileNoteCommand;
type StaffMemberFileNoteItem = People.Domain.Queries.ViewStaffMember.StaffMemberItem.StaffMemberFileNoteItem;

export default function getFileNoteModalDef(
  mode: 'add' | 'edit',
  staffMemberId: string,
  createFileNote: (cmd: CreateStaffMemberFileNoteCommand) => Promise<void>,
  updateFileNote: (cmd: UpdateStaffMemberFileNoteCommand) => Promise<void>
): ModalDefBuilder {
  return modalDefApi => ({
    title: `${mode === 'add' ? 'Add' : 'Edit'} File Note`,
    asForm: true,
    explicitData: mode === 'add' ? {} : modalDefApi.actionData.paneValue,
    panels: [
      {
        panes: [
          {
            paneType: PaneType.formFieldsPane,
            fields: [
              {
                fieldType: FieldType.selectField,
                label: 'File Note Type',
                dataAddr: 'fileNoteType',
                optionItems: allFileNoteType,
                valueKey: 'value',
                descriptionKey: 'description',
                useValueOnly: true,
                mandatory: true,
              },
              {
                fieldType: FieldType.textAreaField,
                label: 'Content',
                dataAddr: 'content',
                mandatory: true,
              },
            ],
          },
        ],
      },
    ],
    secondaryActions: [getSubmitCloseModalActionGroupDef('Ok')],
    onFormPreSubmit: (
      fileNote: StaffMemberFileNoteItem
    ): CreateStaffMemberFileNoteCommand | UpdateStaffMemberFileNoteCommand => {
      return {
        staffMemberId: staffMemberId,
        fileNoteType: fileNote.fileNoteType,
        content: fileNote.content,
        fileNoteId: fileNote.id,
      };
    },
    onFormSubmit: mode === 'add' ? createFileNote : updateFileNote,
  });
}
