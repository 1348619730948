import { types, flow, getRoot } from 'mobx-state-tree';
import { getAjax } from 'src/domain/services/storeEnvironment';
import { IRootStoreModel } from 'src/domain/entities/RootStoreModel';
import { NotificationType } from 'src/domain';

type ShiftItem = Operations.Domain.Queries.ViewShift.ShiftItem;
type ShiftType = Operations.Domain.AggregatesModel.ShiftAggregate.ShiftType;
type CreateShiftCommand = Operations.Domain.Commands.Shift.CreateShift.CreateShiftCommand;

export const ShiftModel = types
  .model('ShiftModel', {
    shift: types.maybe(types.frozen<ShiftItem>()),
    shiftTypes: types.array(types.frozen<ShiftType>()),
  })
  .actions(self => {
    const ajax = getAjax(self);
    const root = getRoot(self) as IRootStoreModel;

    const createShift = flow(function*(command: Promise<CreateShiftCommand>) {
      const id = yield ajax.urban.shifts.createShift(command);
      const shiftName = (yield command).shiftName;
      root.notifications.addNotification(`Successfully created shift ${shiftName}`, {
        type: NotificationType.success,
      });
      self.shift = undefined;
      root.history.push(`/operations/shifts/${id}`);
    });

    const checkForUniqueShiftName = flow(function*(shiftName: string) {
      return yield ajax.urban.shifts.checkForUniqueShiftName(shiftName);
    });

    const loadShift = flow(function*(shiftId: string) {
      self.shift = yield ajax.urban.shifts.viewShift(shiftId);
    });

    const getShift = flow(function*(shiftId: string) {
      return yield ajax.urban.shifts.viewShift(shiftId);
    });

    const updateShift = flow(function*(
      commandP: Promise<Operations.Domain.Commands.Shift.UpdateShift.UpdateShiftCommand>
    ) {
      const command = yield commandP;
      yield ajax.urban.shifts.updateShift(command);

      root.notifications.addNotification(`Successfully updated Shift ${command.shiftName}`, {
        type: NotificationType.success,
      });
      yield loadShift(command.id);
    });

    const loadShiftTypes = flow(function*() {
      self.shiftTypes = yield ajax.urban.shifts.loadShiftTypes();
    });

    const deleteShift = flow(function*(shiftId: string) {
      yield ajax.urban.shifts.deleteShift(shiftId);

      self.shift = undefined;
      root.history.push(`/operations/shifts`);
    });

    return {
      createShift,
      checkForUniqueShiftName,
      loadShift,
      updateShift,
      loadShiftTypes,
      deleteShift,
      getShift,
    };
  });
