import {
  PaneType,
  FieldType,
  ActionType,
  IModalDefBuilderApi,
  ISectionDef,
} from 'src/views/definitionBuilders/types';
import { CancelAssetServiceArgs } from 'src/domain/entities/workshop/asset/AssetModel';

type AssetPlanItem = Workshop.Domain.Queries.AssetServices.GetAssetServicePlan.AssetPlanItem;

export default function getCancelServiceModalDef(
  onCancel: (command: CancelAssetServiceArgs) => Promise<void>,
  serviceScheduleId: number | undefined
): (modalDefApi: IModalDefBuilderApi) => ISectionDef {
  return modalDefApi => ({
    title: 'Cancel Service',
    asForm: true,
    explicitData: {},
    panels: [
      {
        panes: [
          {
            paneType: PaneType.customPane,
            render: api => <p>Please provide the reason that you want to cancel this service</p>,
          },
          {
            paneType: PaneType.formFieldsPane,
            fields: [
              {
                fieldType: FieldType.textField,
                dataAddr: 'cancelReason',
                label: 'Reason',
                mandatory: true,
                maxLength: 200,
              },
            ],
          },
        ],
      },
    ],
    secondaryActions: [
      {
        actions: [
          {
            actionType: ActionType.submitActionButton,
            label: 'Cancel Service',
            level: 'primary',
          },
          {
            actionType: ActionType.closeModalActionButton,
            label: `Don't Cancel`,
          },
        ],
      },
    ],

    onFormSubmit: (values: { cancelReason: string }) => {
      const item: AssetPlanItem = modalDefApi.actionData.parentValue;
      const id = serviceScheduleId || item.id;
      const args = {
        id: id,
        reason: values.cancelReason,
      };
      return onCancel(args);
    },
  });
}
