import './TabPagePane.scss';
import { Component } from 'react';
import cn from 'classnames';
import { IPaneData, IPaneMeta, ITabPaneDef, ITabDef } from 'src/views/definitionBuilders/types';
import PagePane from './PagePane';
import { Button } from 'reactstrap';

interface ITabPagePaneProps {
  paneDef: ITabPaneDef;
  paneData: IPaneData;
  paneMeta: IPaneMeta;
}

interface ITabPagePaneState {
  currentTabIndex: number;
}

class TabPagePane extends Component<ITabPagePaneProps, ITabPagePaneState> {
  constructor(props: ITabPagePaneProps) {
    super(props);
    this.state = {
      currentTabIndex: 0,
    };
  }

  private readonly renderTabItem = (tab: ITabDef, tabIndex: number) => {
    if (!tab) {
      return null;
    }

    const { paneMeta, paneData } = this.props;
    const { currentTabIndex } = this.state;
    return (
      <li key={tabIndex} className={cn('tab-content', { active: tabIndex === currentTabIndex })}>
        {tab.panes.map((p, paneIndex) => (
          <PagePane
            key={`${tabIndex}-${paneIndex}`}
            paneDef={p}
            paneMeta={paneMeta}
            panelData={paneData}
            parentValue={undefined}
          />
        ))}
      </li>
    );
  };

  render() {
    const { paneDef } = this.props;
    const { currentTabIndex } = this.state;

    return (
      <div className="tab-page-pane-component">
        <div className="tab-titles">
          {paneDef.tabs.map((tab, tabIndex) => (
            <Button
              className="tab-title"
              key={tabIndex}
              outline
              color="light"
              onClick={() => this.setState({ currentTabIndex: tabIndex })}>
              <span className={cn('tab', { active: tabIndex === currentTabIndex })}>
                {tab.title}
              </span>
            </Button>
          ))}
        </div>
        <ul className="list-unstyled">
          {paneDef.renderStyle === 'as-required'
            ? this.renderTabItem(paneDef.tabs[currentTabIndex], currentTabIndex)
            : paneDef.tabs.map((tab, tabIndex) => this.renderTabItem(tab, tabIndex))}
        </ul>
      </div>
    );
  }
}

export default TabPagePane;
