import { types, flow, getRoot } from 'mobx-state-tree';
import { getAjax } from 'src/domain/services/storeEnvironment';
import { IRootStoreModel } from 'src/domain/entities/RootStoreModel';

type CustomerComplaintItem = Operations.Domain.Queries.ViewCustomerComplaint.CustomerComplaintItem;

export const CustomerComplaintItemModel = types
  .model('CustomerComplaintItemModel', {
    customerComplaint: types.maybe(types.frozen<CustomerComplaintItem>()),
  })
  .actions(self => {
    const ajax = getAjax(self);
    const root = getRoot(self) as IRootStoreModel;

    const loadCustomerComplaint = flow(function*(customerComplaintId: string) {
      self.customerComplaint = yield ajax.raw
        .get(`/api/compliance/customer-complaints/${customerComplaintId}`)
        .toPromise()
        .then(r => r.response);
    });

    const createCustomerComplaint = flow(function*(
      command: Operations.Domain.Commands.CustomerComplaint.CreateCustomerComplaint.CreateCustomerComplaintCommand
    ) {
      const customerComplaintId = yield ajax.raw
        .post(`/api/compliance/customer-complaints/create`, command)
        .toPromise()
        .then(r => r.response);
      root.history.push(`/compliance/customer-complaints/${customerComplaintId}`);
    });

    const updateCustomerComplaint = flow(function*(
      command: Operations.Domain.Commands.CustomerComplaint.UpdateCustomerComplaint.UpdateCustomerComplaintCommand
    ) {
      yield ajax.raw.put(`/api/compliance/customer-complaints/${command.id}`, command).toPromise();
      yield loadCustomerComplaint(command.id);
    });

    const closeCustomerComplaint = flow(function*(
      command: Operations.Domain.Commands.CustomerComplaint.CloseCustomerComplaint.CloseCustomerComplaintCommand
    ) {
      yield ajax.raw
        .httpDelete(`/api/compliance/customer-complaints/${command.id}`, command)
        .toPromise();
      yield loadCustomerComplaint(command.id);
    });

    return {
      loadCustomerComplaint,
      createCustomerComplaint,
      updateCustomerComplaint,
      closeCustomerComplaint,
    };
  });
