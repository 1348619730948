import { IPrintDailySummaryProps } from 'src/views/kioskRoutes/operationsKiosk/home/PrintDailySummary/PrintDailySummary';
import { DateTime } from 'luxon';
import DraftWatermark from 'src/views/components/operations/DraftWatermark/DraftWatermark';
import { TwoThirdsOneThirdPanel } from 'src/views/kioskRoutes/operationsKiosk/home/PrintDailySummary/components/TwoThirdsOneThirdPanel';
import { DriverSignatureTable } from 'src/views/kioskRoutes/operationsKiosk/home/PrintDailySummary/components/DriverSignatureTable';
import { JobsList } from 'src/views/kioskRoutes/operationsKiosk/home/PrintDailySummary/components/JobsList';
import { DriverDailyJobSummaryPdfHeader } from 'src/views/kioskRoutes/operationsKiosk/home/PrintDailySummary/components/DriverDailyJobSummaryPdfHeader';
import { VehicleScratchesTable } from 'src/views/kioskRoutes/operationsKiosk/home/PrintDailySummary/components/VehicleScratchesTable';
import { CommentsTable } from 'src/views/kioskRoutes/operationsKiosk/home/PrintDailySummary/components/CommentsTable';
import { CheckField } from 'src/views/kioskRoutes/operationsKiosk/home/PrintDailySummary/components/CheckField';
import styles from './DailySummarySheet.module.scss';
import { PrintedDateTimeDisplay } from 'src/views/kioskRoutes/operationsKiosk/home/PrintDailySummary/components/PrintedDateTimeDisplay';
import QrCode from 'src/images/incident-qr-code.png';
import cn from 'classnames';

const DailySummarySheet: React.FC<IPrintDailySummaryProps> = (props: IPrintDailySummaryProps) => {
  let checkItemRows = [];
  const columnCount = props.checkColumns;
  for (let i = 0; i < props.checks.length; i += columnCount) {
    checkItemRows.push(props.checks.slice(i, i + columnCount));
  }

  const commentTable = (className: string) => (
    <table>
      <tbody>
        <tr>
          <td>Comments</td>
          <td>Accident/Incident</td>
        </tr>
        <tr className={cn(styles.commentsTable, styles[className])}>
          <td className={styles.comments} />
          <td>
            <img src={QrCode} alt="QR Code for incident/accident reporting" />
          </td>
        </tr>
      </tbody>
    </table>
  );

  return (
    <div className={styles.root}>
      <DraftWatermark
        shouldBeVisible={
          props.checkDraft && props.date.startOf('day') > DateTime.local().startOf('day')
        }
      />
      <DriverDailyJobSummaryPdfHeader date={props.date} userDisplayName={props.userDisplayName} />
      <JobsList jobs={props.jobs} />

      <div className={styles.dailyChecks}>
        {props.showChecks && (
          <table>
            <tbody>
              <tr>
                <td className={styles.subtitle}>Daily Checks By Driver</td>
                <td className={styles.driverName}>{props.userDisplayName}</td>
              </tr>
            </tbody>
          </table>
        )}

        {(props.showChecks && props.showAccident) || (props.showChecks && !props.showAccident) ? (
          <TwoThirdsOneThirdPanel
            twoThirdChild={
              <table className={styles.checksTable}>
                <tbody>
                  {checkItemRows.map((row, i) => (
                    <tr key={i}>
                      {row.map(item => (
                        <CheckField key={item.id} label={item.description} />
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            }
            oneThirdChild={<VehicleScratchesTable />}
          />
        ) : !props.showAccident ? (
          <table className={styles.checksTable}>
            <tbody>
              {checkItemRows.map((row, i) => (
                <tr key={i}>
                  {row.map(item => (
                    <CheckField key={item.id} label={item.description} />
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        ) : null}
      </div>

      {props.showAccident ? (
        props.showChecks ? (
          commentTable('withChecks')
        ) : (
          <>
            <TwoThirdsOneThirdPanel
              twoThirdChild={commentTable('default')}
              oneThirdChild={<VehicleScratchesTable />}
            />
          </>
        )
      ) : (
        <CommentsTable heightInCm={3.5} />
      )}

      <DriverSignatureTable />
      <PrintedDateTimeDisplay />
    </div>
  );
};

export default DailySummarySheet;
