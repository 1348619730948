import { Card, CardBody, CardHeader, CardText } from 'reactstrap';
import { Link } from 'react-router-dom';
import { cloneElement } from 'react';
import styles from './AlertCard.module.scss';

interface IAlertCardProps {
  showAlert: boolean | undefined;
  heading: string;
  message: string | JSX.Element;
  icon: JSX.Element;
  iconColor: string;
  link?: string;
  linkText?: string;
  action?: (_: any) => Promise<void>;
  actionText?: string;
}
export const AlertCard: React.FC<IAlertCardProps> = ({
  showAlert,
  heading,
  message,
  icon,
  iconColor,
  link,
  linkText,
  action,
  actionText,
}) => {
  if (!showAlert) {
    return null;
  }

  return (
    <Card className={styles.card}>
      <CardHeader className={styles.cardHeader}>
        <div className={styles.title}>
          {cloneElement(icon, { className: styles.cardIcon, style: { color: iconColor } })}
          <span className={styles.cardHeading}>{heading}</span>
          <div style={{ marginLeft: 'auto' }}>
            {link && (
              <Link className={styles.cardLink} to={link}>
                {linkText}
              </Link>
            )}
            {action && (
              <button className={styles.cardAction} onClick={action}>
                {actionText}
              </button>
            )}
          </div>
        </div>
      </CardHeader>
      <CardBody className={styles.body}>
        <CardText className={styles.text}>{message}</CardText>
      </CardBody>
    </Card>
  );
};
