import { Component } from 'react';
import {
  IFieldMeta,
  IFieldData,
  IAssetSelectFieldDef,
  FieldType,
  ISelectFieldDef,
  FieldCallback,
} from 'src/views/definitionBuilders/types';
import { IFieldApi } from 'src/views/components/Page/forms/Field';
import SelectPageField from './SelectPageField';
import FleetAssetRenderer from 'src/views/components/operations/FleetAssetRenderer/FleetAssetRenderer';
import Omit from 'src/infrastructure/omit';
import inject from 'src/views/injectFromStore';
import { applyTechSpecRequirementsFilter } from 'src/domain/entities/workshop/techSpecs/TechSpecsHelpers';
import { getPropertyName } from 'src/infrastructure/typeUtils';
import { IStores } from 'src/domain';
import { StaffMemberLicence } from 'src/domain/entities/people/staffMember/StaffMembersModel';

type AssetItem = Common.Queries.Workshop.GetFleetAssetList.AssetItem;
type AssetNameItem = Common.Queries.Workshop.GetFleetAssetNamesList.AssetNameItem;
type ExclusionItem = Operations.Domain.Queries.ListExclusions.ExclusionItem;

interface IAssetSelectPageFieldProps {
  fieldApi: IFieldApi;
  fieldDef: IAssetSelectFieldDef;
  fieldMeta: IFieldMeta;
  fieldData: IFieldData<AssetItem>;
  exclusions: ExclusionItem[];
  staffMemberLicences: StaffMemberLicence[];
}

class AssetSelectPageField extends Component<IAssetSelectPageFieldProps> {
  private readonly formatDefault = (asset: AssetItem | AssetNameItem | undefined) => {
    return asset ? (
      <FleetAssetRenderer asset={asset} hideAssetInfo={this.props.fieldDef.hideAssetInfo} />
    ) : (
      ''
    );
  };

  shouldComponentUpdate(nextProps: Readonly<IAssetSelectPageFieldProps>) {
    const readonly = this.props.fieldMeta.readonly !== nextProps.fieldMeta.readonly;
    const id =
      (this.props.fieldData.fieldValue && this.props.fieldData.fieldValue.id) !==
      (nextProps.fieldData.fieldValue && nextProps.fieldData.fieldValue.id);
    const options = this.props.fieldDef.optionItems !== nextProps.fieldDef.optionItems;
    const exclusions = this.props.exclusions !== nextProps.exclusions;
    return id || readonly || options || exclusions;
  }

  applyExclusionFilter(
    assets:
      | Array<AssetItem | AssetNameItem>
      // tslint:disable-next-line:no-any
      | FieldCallback<any, Array<AssetItem | AssetNameItem>>
      | undefined
  ): Array<AssetItem | AssetNameItem> {
    const { fieldDef, fieldData, exclusions, staffMemberLicences } = this.props;
    const optionItems = typeof assets === 'function' ? assets(fieldData) : assets || [];
    const staffMemberId =
      fieldDef.staffMemberId && typeof fieldDef.staffMemberId === 'function'
        ? fieldDef.staffMemberId(fieldData)
        : fieldDef.staffMemberId;
    const secondStaffMemberId =
      fieldDef.secondStaffMemberId && typeof fieldDef.secondStaffMemberId === 'function'
        ? fieldDef.secondStaffMemberId(fieldData)
        : fieldDef.secondStaffMemberId;
    const staffMemberIds = [staffMemberId, secondStaffMemberId].filter(x => !!x);
    const assignedStaff = staffMemberLicences.filter(s => staffMemberIds.includes(s.id));
    if (staffMemberIds.length > 0) {
      return optionItems.filter(
        x =>
          exclusions.findIndex(
            e => staffMemberIds.findIndex(s => s === e.staffMember.id) !== -1 && e.asset.id === x.id
          ) === -1 &&
          assignedStaff.findIndex(
            s => s.licenceClass?.licenceClass?.id < (x.licenceClassId ?? 0)
          ) === -1
      );
    }

    return optionItems;
  }

  isAssetItem(asset: AssetItem | AssetNameItem): asset is AssetItem {
    return getPropertyName<AssetItem>('opsTechSpecs') in asset;
  }

  isAssetItemArray(assets: Array<AssetItem | AssetNameItem>): assets is Array<AssetItem> {
    return assets.every(this.isAssetItem);
  }

  applyTechSpecRequirementsFilter(assets: Array<AssetItem | AssetNameItem>) {
    const { fieldDef, fieldData } = this.props;

    if (!this.isAssetItemArray(assets)) {
      return assets;
    }

    const techSpecRequirements =
      (fieldDef.techSpecRequirements && typeof fieldDef.techSpecRequirements === 'function'
        ? fieldDef.techSpecRequirements(fieldData)
        : fieldDef.techSpecRequirements) || [];

    return applyTechSpecRequirementsFilter(assets, techSpecRequirements);
  }

  filterAssets(
    assets:
      | Array<AssetItem | AssetNameItem>
      // tslint:disable-next-line:no-any
      | FieldCallback<any, Array<AssetItem | AssetNameItem>>
      | undefined
  ) {
    return this.applyTechSpecRequirementsFilter(this.applyExclusionFilter(assets));
  }

  private readonly getFieldDef = (def: IAssetSelectFieldDef): ISelectFieldDef => {
    const selectDef: Omit<IAssetSelectFieldDef, 'hideAssetInfo'> = def;
    return {
      ...selectDef,
      fieldType: FieldType.selectField,
      optionItems: this.filterAssets(selectDef.optionItems),
      linkTo: (d: IFieldData<AssetItem>) =>
        def.linkTo
          ? def.linkTo(d)
          : d.fieldValue
          ? `/workshop/assets/${d.fieldValue.id}`
          : undefined,
      formatReadonly: (d: IFieldData<AssetItem | AssetNameItem>) =>
        def.formatReadonly ? def.formatReadonly(d) : this.formatDefault(d.fieldValue),
      optionRenderer: (a: AssetItem) => {
        return def.optionRenderer ? (
          def.optionRenderer(a)
        ) : (
          <FleetAssetRenderer asset={a} hideAssetInfo={def.hideAssetInfo} />
        );
      },
      useOptionRendererAsValueRenderer: true,
    };
  };

  render() {
    const { fieldDef: def } = this.props;
    const assetFieldDef = this.getFieldDef(def);
    return <SelectPageField {...this.props} fieldDef={assetFieldDef} />;
  }
}

interface IAssetSelectPageFieldContainerProps {
  exclusions: ExclusionItem[];
  staffMemberLicences: StaffMemberLicence[];
}

const Container = inject<IAssetSelectPageFieldContainerProps, IAssetSelectPageFieldProps>(
  (allStores: IStores, p) => ({
    exclusions: allStores.rootStore.operations.exclusionModel.exclusions.slice(),
    staffMemberLicences: allStores.rootStore.people.staffMembers.staffMemberLicences.slice(),
  })
)(AssetSelectPageField);

export default Container;
