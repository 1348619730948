type InvoiceCreditNoteLine = Workshop.Domain.Queries.Purchasing.GetPurchaseOrderInvoices.InvoiceCreditNoteLine;
type CreditNoteLineItem = Workshop.Domain.Queries.Purchasing.CreditNoteLineItem;
type InvoiceLine = Workshop.Domain.Queries.Purchasing.GetPurchaseOrderInvoices.InvoiceLine;
type GoodsReceivedLineItem = Workshop.Domain.Queries.Purchasing.GoodsReceivedLineItem;
import { multiplyUnitPriceByQuantityArithmeticallySafe } from 'src/infrastructure/mathUtil';
import currency from 'currency.js';

type CreditNoteItem = InvoiceCreditNoteLine | CreditNoteLineItem;
type GoodsReceivedItem = InvoiceLine | GoodsReceivedLineItem;

function calculateGst(amountWithoutGst: currency) {
  return amountWithoutGst
    .multiply(11)
    .divide(10)
    .subtract(amountWithoutGst);
}

export function calculateInvoiceCreditNoteLinesTotals(lines: CreditNoteItem[]) {
  const calculateSubtotal = (relevantLines: CreditNoteItem[], applyGst: boolean) =>
    relevantLines
      .filter(l => l.quantity && l.creditAmount && l.applyGst === applyGst)
      .map(l => multiplyUnitPriceByQuantityArithmeticallySafe(l.quantity, l.creditAmount!))
      .reduce((t1, t2) => t1.add(t2), currency(0));

  const subtotalForGSTcalc = calculateSubtotal(lines, true);
  const subtotalNotForGSTcalc = calculateSubtotal(lines, false);
  const subtotal = subtotalNotForGSTcalc.add(subtotalForGSTcalc);
  const gst = calculateGst(subtotalForGSTcalc);
  const totalInclGst = subtotal.add(gst);

  return {
    subtotal: subtotal,
    gst: gst,
    totalInclGst: totalInclGst,
  };
}

export function calculateInvoiceLinesTotals(lines: GoodsReceivedItem[]) {
  const calculateSubtotal = (relevantLines: GoodsReceivedItem[], applyGst: boolean) =>
    relevantLines
      .filter(l => l.quantity && l.unitPrice && l.applyGst === applyGst)
      .map(l => multiplyUnitPriceByQuantityArithmeticallySafe(l.quantity, l.unitPrice!))
      .reduce((t1, t2) => t1.add(t2), currency(0));

  const subtotalForGSTcalc = calculateSubtotal(lines, true);
  const subtotalNotForGSTcalc = calculateSubtotal(lines, false);
  const subtotal = subtotalNotForGSTcalc.add(subtotalForGSTcalc);
  const gst = calculateGst(subtotalForGSTcalc);
  const totalInclGst = subtotal.add(gst);

  return {
    subtotal: subtotal,
    gst: gst,
    totalInclGst: totalInclGst,
  };
}
