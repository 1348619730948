import './AmendedHoursFormat.scss';

import { DurationFormat } from '../DurationFormat';
import cn from 'classnames';
import { parseTimeSpan } from 'src/infrastructure/dateUtils';

interface IAmendedHoursFormatProps {
  hours: string;
  amendedHours: string | undefined;
  defaultAmendedHours?: string | undefined;
}

const AmendedHoursFormat: React.FC<IAmendedHoursFormatProps> = ({
  hours,
  amendedHours,
  defaultAmendedHours,
}) => {
  const finalAmendment = amendedHours || defaultAmendedHours;
  const hasAmendment =
    !!finalAmendment && parseTimeSpan(hours).valueOf() !== parseTimeSpan(finalAmendment).valueOf();
  return (
    <div className="amended-hours-format">
      <span className={cn('block', { amended: hasAmendment })}>
        <DurationFormat value={hours} />
      </span>
      {hasAmendment ? (
        <span className={'block'}>
          <DurationFormat value={finalAmendment} />
        </span>
      ) : null}
    </div>
  );
};

export default AmendedHoursFormat;
