import { IRootStoreModel } from 'src/domain/entities/RootStoreModel';
import { UiRoute } from 'src/domain/services/buildUiRoutes';
import { UiRouteName } from 'src/domain/baseTypes';

export default function buildUiRoutes(store: IRootStoreModel) {
  const listAssetOdometerReadings = new UiRoute(
    UiRouteName.operationsListAssetOdometerReadings,
    '/operations/asset/odometer-readings',
    () => 'Asset Odometer Readings',
    false
  );

  return {
    [UiRouteName.operationsListAssetOdometerReadings]: listAssetOdometerReadings,
  };
}
