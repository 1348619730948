import shallowEqual from 'shallowequal';
import memoizeOne from 'src/infrastructure/memoizeOne';
import { Subject } from 'rxjs/Subject';
import { Observable } from 'rxjs/Observable';
import { Subscription } from 'rxjs/Subscription';
import {
  IFormApi,
  IFormApiWithoutState,
  RfsContext,
  FieldName,
  IRfsContext,
  ValidateFunc,
  ValidateAsyncFunc,
} from './base';
import {
  fieldNameAsKey,
  ITouchTree,
  setTouched,
  getTouched,
  get,
  set,
  makePathArray,
} from './utils';
import { Component } from 'react';
import produce from 'immer';

const ASYNC_ERROR_REFRESH_BUFFER_MS = 25;

interface IRegisteredField {
  id: symbol;
  field: FieldName;
  fieldKey: string;
  validate: undefined | ValidateFunc;
  validateAsync: undefined | ValidateAsyncFunc;
}

interface IAsyncErrorDetail {
  field: FieldName;
  fieldKey: string;
  fieldValue: unknown;
  error: string;
}

type WrappedValidateAsyncFuncResult = {
  promise: Promise<string | undefined>;
  result?: string;
};

type WrappedValidateAsyncFunc = ((value: unknown) => WrappedValidateAsyncFuncResult | undefined) & {
  originalFunc: ValidateAsyncFunc;
};

export interface IFormProps {
  children: (formApi: IFormApi) => React.ReactNode;

  getApi?: (formApi: IFormApiWithoutState) => void;
  onChange?: (formState: { values: {} }, formApi: IFormApiWithoutState) => void;

  readonly?: boolean;
  defaultValues?: {};
  values?: {};

  defaultTouchedFields?: Array<FieldName>;
  onCompareValues?: (defaultValues: {}, values: {}) => boolean;

  // tslint:disable-next-line: no-any
  preSubmit?: (values: any, submissionMeta: {} | undefined) => {};
  // tslint:disable-next-line: no-any
  onSubmit?: (values: any, formApi: IFormApi) => Promise<void>;
  onSubmitFailure?: (validationErrors: string[], submitError: unknown, formApi: IFormApi) => void;
  onResetAll?: (formApi: IFormApi, cancelReset: () => void) => void;
  setInitialFormValuesOnEdit?: () => void;
}

interface IFormState {
  formReadonly: boolean;
  values: {};
  touched: true | ITouchTree;
  submitting: boolean;
  submits: number;
}

export class Form extends Component<IFormProps, IFormState> {
  static getDerivedStateFromProps(nextProps: Readonly<IFormProps>, prevState: IFormState) {
    // Refresh the state values when readonly flag changes.
    // This component is not fully controlled OR fully uncontrolled - we want it to
    // be controlled when readonly, but uncontrolled otherwise.
    if (prevState.formReadonly !== !!nextProps.readonly) {
      Form.setInitialFormValuesOnEdit(nextProps);

      return Form.getDefaultState(nextProps);
    }

    return null;
  }

  static setInitialFormValuesOnEdit(props: Readonly<IFormProps>) {
    if (!props.readonly) {
      // Set initial form values when switching from readonly to edit mode
      props.setInitialFormValuesOnEdit && props.setInitialFormValuesOnEdit();
    }
  }

  private static getDefaultState(props: IFormProps): IFormState {
    const touched =
      !props.readonly && props.defaultTouchedFields
        ? props.defaultTouchedFields
            .map(p => makePathArray(p))
            .reduce((acc, p) => setTouched(acc, p, true), {})
        : {};
    return {
      formReadonly: !!props.readonly,
      values: props.defaultValues || {},
      touched,
      submitting: false,
      submits: 0,
    };
  }

  private _mounted: boolean = false;
  private _unmounted: boolean = false;
  private readonly _subscriptions = new Subscription();
  private readonly _validateAllSubject = new Subject<undefined>();
  private readonly _asyncErrorsSubject = new Subject<IAsyncErrorDetail>();
  private readonly _registeredFields = new Map<symbol, IRegisteredField>();
  private readonly _validatesByField = new Map<string, Array<ValidateFunc>>();
  private readonly _validateAsyncsByField = new Map<string, Array<WrappedValidateAsyncFunc>>();
  private readonly _errorsByField = new Map<string, string>();

  constructor(props: IFormProps) {
    super(props);
    Form.setInitialFormValuesOnEdit(props);
    this.state = Form.getDefaultState(props);
  }

  componentDidMount() {
    this._mounted = true;

    const asyncErrorSub = this._asyncErrorsSubject
      .buffer(this._asyncErrorsSubject.debounceTime(ASYNC_ERROR_REFRESH_BUFFER_MS))
      .subscribe(ds => {
        let errorChanged = false;

        ds.forEach(d => {
          const currentError = this._errorsByField.get(d.fieldKey);
          if (currentError) {
            // Already an error for this field, so ignore
            return;
          }

          const fieldValue = get(this.values, d.field);
          if (fieldValue !== d.fieldValue) {
            // Field value has changed, so this is no longer relevant
            return;
          }

          this._errorsByField.set(d.fieldKey, d.error);
          errorChanged = true;
        });

        // As we're using mutable structures instead of state, force a render
        errorChanged && this.forceUpdate();
      });
    this._subscriptions.add(asyncErrorSub);

    const valSub = this._validateAllSubject
      .debounceTime(100)
      .subscribe(() => this.validateAll(this.values));
    this._subscriptions.add(valSub);

    // Call this before validation, as some validation routines need it
    this.props.getApi && this.props.getApi(this.getFormApiWithoutState());

    // Validate all fields that were registered before mount
    this.validateAll(this.values);
  }

  componentWillUnmount() {
    this._unmounted = true;
    this._subscriptions.unsubscribe();
  }

  private get values() {
    return (this.props.readonly && this.props.values) || this.state.values;
  }

  private readonly setValues = (newValues: {}, resetting?: 'resetting') => {
    const { readonly, onChange } = this.props;

    if (readonly) {
      return;
    }

    if (resetting) {
      this.setState({ touched: {}, submitting: false, submits: 0 });
    }

    if (!this._unmounted && this.state.values !== newValues) {
      this._validateAllSubject.next();

      this.setState({ values: newValues }, () => {
        onChange &&
          !this._unmounted &&
          onChange({ values: this.state.values }, this.getFormApiWithoutState());
      });
    }
  };

  private readonly registerField: IRfsContext['registerField'] = (
    id,
    field,
    validate,
    validateAsync
  ) => {
    const fieldKey = fieldNameAsKey(field);
    const newRegistration: IRegisteredField = { id, field, fieldKey, validate, validateAsync };

    const prevRegistration = this._registeredFields.get(id);
    if (prevRegistration && shallowEqual(prevRegistration, newRegistration)) {
      return;
    }

    prevRegistration && this.unregisterField(id);

    this._registeredFields.set(id, newRegistration);

    if (validate) {
      const validates = this._validatesByField.get(fieldKey) || [];
      validates.push(validate);
      this._validatesByField.set(fieldKey, validates);
    }

    if (validateAsync) {
      // memoize (cache) the async validation as we only want to revalidate when the field value changes for performance
      // We also want to be able to synchronously get the result from cached promises
      const wrapped = ((v: unknown) => {
        const promise = validateAsync(v);
        if (!promise) {
          return;
        }
        const result: WrappedValidateAsyncFuncResult = { promise };
        promise.then(r => {
          result.result = r;
          return r;
        });
        return result;
      }) as WrappedValidateAsyncFunc;
      wrapped.originalFunc = validateAsync;
      const memoizedValidateAsync = memoizeOne(wrapped);

      const validates = this._validateAsyncsByField.get(fieldKey) || [];
      validates.push(memoizedValidateAsync);
      this._validateAsyncsByField.set(fieldKey, validates);
    }

    if (this._mounted && (validate || validateAsync)) {
      this.validateField(this.values, field);
    }
  };

  private readonly unregisterField = (id: symbol) => {
    const fieldData = this._registeredFields.get(id);
    if (fieldData) {
      this._registeredFields.delete(id);
      this._errorsByField.delete(fieldData.fieldKey);

      if (fieldData.validate) {
        const validates = this._validatesByField.get(fieldData.fieldKey);
        const newValidates = validates && validates.filter(v => v !== fieldData.validate);
        if (newValidates && newValidates.length) {
          this._validatesByField.set(fieldData.fieldKey, newValidates);
        } else {
          this._validatesByField.delete(fieldData.fieldKey);
        }
      }

      if (fieldData.validateAsync) {
        const validates = this._validateAsyncsByField.get(fieldData.fieldKey);
        const newValidates =
          validates && validates.filter(v => v.originalFunc !== fieldData.validateAsync);
        if (newValidates && newValidates.length) {
          this._validateAsyncsByField.set(fieldData.fieldKey, newValidates);
        } else {
          this._validateAsyncsByField.delete(fieldData.fieldKey);
        }
      }
    }
  };

  // tslint:disable-next-line:no-any
  private readonly validateAll = (values: any) => {
    var ob$s = Array.from(this._validatesByField.keys()).map(f => this.validateField(values, f));
    return Observable.merge(...ob$s);
  };

  private readonly validateField = (values: {}, field: FieldName): Observable<string> => {
    const fieldKey = fieldNameAsKey(field);
    const validates = this._validatesByField.get(fieldKey);
    const validateAsyncs = this._validateAsyncsByField.get(fieldKey);

    if ((!validates || !validates.length) && (!validateAsyncs || !validateAsyncs.length)) {
      return Observable.empty();
    }

    const fieldValue = get(values, field);
    const originalError = this._errorsByField.get(fieldKey);
    let currentError = originalError;
    let newError: string | undefined;

    if (validates && validates.length) {
      newError = validates.reduce(
        (res, v) => (res ? res : v(fieldValue)),
        undefined as string | undefined
      );
      if (newError !== currentError) {
        currentError = newError;
        if (newError) {
          this._errorsByField.set(fieldKey, newError);
        } else {
          this._errorsByField.delete(fieldKey);
        }
      }
    }

    let asyncOb$: Observable<string> | undefined;
    if (validateAsyncs && validateAsyncs.length && !newError && fieldValue !== undefined) {
      const results = validateAsyncs
        .map(v => v(fieldValue))
        .filter((r): r is WrappedValidateAsyncFuncResult => !!r);

      // Check if we already know the field is in error due to caching
      // If we have an error already, so we can ignore the others
      newError = results.map(r => r.result).find(e => !!e);
      if (newError) {
        if (newError !== currentError) {
          this._errorsByField.set(fieldKey, newError);
        }
      } else {
        // As multiple async validations could run at once, they'll only set the error value
        // (not clear it) so clear it out first
        this._errorsByField.delete(fieldKey);

        // Let the promises do their thing and capture the results
        const promises = results.map(r => r.promise);
        asyncOb$ = Observable.merge(...promises.map(p => Observable.from(p))).filter(
          (e): e is string => !!e
        );

        asyncOb$
          .map(error => ({ field, fieldKey, fieldValue, error }))
          .subscribe(d => this._asyncErrorsSubject.next(d));
      }
    }

    // As we're using mutable structures instead of state, force a render
    newError !== originalError && this.forceUpdate();

    return asyncOb$ || (newError && Observable.of(newError)) || Observable.empty();
  };

  private readonly submit = async (submissionMeta?: {}) => {
    const { preSubmit, onSubmit, onSubmitFailure } = this.props;

    if (!onSubmit) {
      return;
    }

    try {
      !this._unmounted && this.setState(s => ({ submitting: true, submits: s.submits + 1 }));

      this.setState({ touched: true });

      const validationErrors = await this.validateAll(this.values)
        .toArray()
        .toPromise();

      if (validationErrors.length) {
        onSubmitFailure && onSubmitFailure(validationErrors, undefined, this.getFormApi());
        return;
      }

      const values = preSubmit ? preSubmit(this.values, submissionMeta) : this.values;

      const formApi = this.getFormApi();
      try {
        await onSubmit(values, formApi);
      } catch (error) {
        if (onSubmitFailure) {
          onSubmitFailure(validationErrors, error, formApi);
        } else {
          throw error;
        }
      }
    } finally {
      !this._unmounted && this.setState({ submitting: false });
    }
  };

  private readonly updateValues = (mutator: (values: {}) => void) => {
    const newValues = produce(this.values, mutator);
    this.setValues(newValues);
  };

  private readonly getFormApiWithoutState = memoizeOne(
    (): IFormApiWithoutState => {
      return {
        getValue: field => get(this.values, field),
        setValue: (field, value) => {
          var currentValue = get(this.values, field);
          if (currentValue === value) {
            return;
          }
          this.updateValues(v => set(v, field, value));
        },
        addValue: (field, value) => {
          this.updateValues(v => {
            const currentValue = get(v, field);
            const safeValue =
              currentValue === undefined || currentValue === null ? [] : currentValue;
            if (!Array.isArray(safeValue)) {
              throw new Error('Cannot call addValue on a non-array field');
            }
            safeValue.push(value);
            set(v, field, safeValue);
          });
        },
        removeValue: (field, index) => {
          this.updateValues(v => {
            const currentValue = get(v, field);
            if (!Array.isArray(currentValue)) {
              throw new Error('Cannot call removeValue on a non-array field');
            }
            currentValue.splice(index, 1);
            set(v, field, currentValue);
          });
        },
        swapValues: (field, index, destIndex) => {
          if (index === destIndex) {
            return;
          }
          this.updateValues(v => {
            const currentValue = get(v, field);
            if (!Array.isArray(currentValue)) {
              throw new Error('Cannot call swapValues on a non-array field');
            }
            const items = currentValue.splice(index, 1);
            currentValue.splice(destIndex, 0, ...items);
            set(v, field, currentValue);
          });
        },
        valuesChanged: () =>
          this.props.onCompareValues
            ? !this.props.onCompareValues(this.props.defaultValues || {}, this.values)
            : this.props.defaultValues !== this.values,
        getError: field => this._errorsByField.get(fieldNameAsKey(field)),
        clearAllErrors: () => this._errorsByField.clear(),
        hasAnyErrors: () => !!this._errorsByField.size,
        getAllErrors: () => Array.from(this._errorsByField.values()),
        validate: field => this.validateField(this.values, field),
        validateAll: () =>
          this.validateAll(this.values)
            .toArray()
            .map(es => !es.length)
            .toPromise(),
        resetAll: newValues => {
          let cancelled = false;
          if (this.props.onResetAll) {
            this.props.onResetAll(this.getFormApi(), () => (cancelled = true));
          }
          if (!cancelled) {
            this.setValues(newValues || this.props.defaultValues || {}, 'resetting');
          }
        },
        setAllValues: values => this.setValues(values),
        submitForm: this.submit,
        getTouched: field => getTouched(this.state.touched, makePathArray(field)),
        setTouched: (field, touched) => {
          const path = makePathArray(field);
          const currentTouchedValue = getTouched(this.state.touched, path);
          if (currentTouchedValue !== touched) {
            this.setState({ touched: setTouched(this.state.touched, path, !!touched) });
          }
        },
      };
    }
  );

  private readonly buildFormApi = memoizeOne(
    (values: {}, submits: number, submitting: boolean, submitted: boolean): IFormApi => {
      return {
        ...this.getFormApiWithoutState(),
        getFullField: field => makePathArray(field || 0 || []),
        values,
        submits,
        submitting,
        submitted,
      };
    }
  );

  private readonly getFormApi = (): IFormApi => {
    return this.buildFormApi(
      this.values,
      this.state.submits,
      this.state.submitting,
      this.state.submits > 0
    );
  };

  render() {
    const { children } = this.props;
    const formApi = this.getFormApi();
    return (
      <RfsContext.Provider
        value={{
          formApi,
          parentPath: [],
          errors: this._errorsByField,
          registerField: this.registerField,
          unregisterField: this.unregisterField,
        }}>
        {children(formApi)}
      </RfsContext.Provider>
    );
  }
}
