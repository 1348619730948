import { observer } from 'mobx-react';
import { useRootStore } from 'src/domain/entities/RootStoreModel';
import ListPage, { IListPageDef } from 'src/views/components/Page/pages/ListPage';
import PrimaryTitle from 'src/views/components/Page/PrimaryTitle/PrimaryTitle';
import TaskCardItem from 'src/views/components/TaskCard/TaskCardItem';
import { FieldType, PagePrimarySize } from 'src/views/definitionBuilders/types';
import { getDisplayDays, totalHours } from 'src/views/routes/operations/urban/urbanHelpers';

const ListShift: React.FC = observer(() => {
  const rootStore = useRootStore();
  const operationsModel = rootStore.operations;
  const getPageDef = (): IListPageDef => {
    return {
      primaryTitle: (
        <PrimaryTitle
          title="Shifts"
          link="https://central-ops.notion.site/Shifts-5d16ab7ababa40ef9e14702338070a9a"></PrimaryTitle>
      ),
      primarySize: PagePrimarySize.full,
      createLink: rootStore.account.isOperationsDepartmentMember
        ? TaskCardItem.urban.createShift
        : undefined,
      onLoadData: () => operationsModel.urban.shifts.listShifts(),
      primaryFields: [
        {
          fieldType: FieldType.textField,
          dataAddr: 'shiftName',
          label: 'Shift Name',
          linkTo: d => `/operations/shifts/${d.parentValue.id}`,
        },
        {
          fieldType: FieldType.textField,
          dataAddr: 'description',
          label: 'Description',
        },
        {
          fieldType: FieldType.textField,
          dataAddr: ['shiftType', 'description'],
          label: 'Shift Type',
        },
        {
          fieldType: FieldType.readonlyField,
          label: 'Days Selected',
          formatReadonly: data => getDisplayDays(data.parentValue).join(', '),
        },
        {
          fieldType: FieldType.timeField,
          dataAddr: 'clockOn',
          label: 'Clock On',
        },
        {
          fieldType: FieldType.timeField,
          dataAddr: 'clockOff',
          label: 'Clock Off',
        },
        {
          fieldType: FieldType.readonlyField,
          label: 'Total Shift Hours',
          formatReadonly: data => totalHours(data.parentValue.clockOn, data.parentValue.clockOff),
        },
        {
          fieldType: FieldType.assetSelectField,
          label: 'Vehicle',
          dataAddr: 'asset',
          valueKey: 'id',
          descriptionKey: 'name',
          columnWidth: '1px',
          linkTo: d => `/workshop/assets/${d.parentValue.asset?.id}`,
          formatReadonly: d => d.parentValue.asset?.name,
        },
      ],
    };
  };
  return (
    <ListPage
      className="list-shift-component"
      data={operationsModel.urban.shifts.shifts}
      def={getPageDef()}
    />
  );
});

export default ListShift;
