// tslint:disable:no-any

import * as History from 'history';
import Omit from 'src/infrastructure/omit';
import { ShellModalSize } from 'src/views/components/Shell/ShellModal';
import { IFormApi } from 'src/views/components/Page/forms/base';
import { ISectionDef } from './section';
import { IPanelDef } from './panel';
import { FieldDefs } from './field';
import { DateTime } from 'luxon';

export { ShellModalSize };

export interface IActionGroupDef {
  actions: Array<ActionDefs>;
}

// -------------------------------------------------

export enum ActionType {
  actionButton = 'actionButton',
  actionLink = 'actionLink',
  actionCollection = 'actionCollection',
  calendarButton = 'calendarButton',
  submitActionButton = 'submitActionButton',
  resetActionButton = 'resetActionButton',
  searchActionButton = 'searchActionButton',
  addActionLink = 'addActionLink',
  printActionButton = 'printActionButton',
  addArrayItemActionButton = 'addArrayItemActionButton',
  removeArrayItemActionButton = 'removeArrayItemActionButton',
  moveArrayItemActionButton = 'moveArrayItemActionButton',
  cancelArrayItemActionButton = 'cancelArrayItemActionButton',
  modalActionButton = 'modalActionButton',
  closeModalActionButton = 'closeModalActionButton',
  filterActionButton = 'filterActionButton',
  simpleFilterActionButton = 'simpleFilterActionButton',
  presetFilterCollection = 'presetFilterCollection',
}

export type ActionDefs =
  | IActionButtonDef
  | IActionLinkDef
  | IActionCollectionDef
  | ICalendarButtonDef
  | ISubmitActionButtonDef
  | IResetActionButtonDef
  | ISearchActionButtonDef
  | IAddActionLinkDef
  | IPrintActionButtonDef
  | IAddArrayItemActionButtonDef
  | IRemoveArrayItemActionButtonDef
  | IMoveArrayItemActionButtonDef
  | IModalActionButtonDef
  | ICloseModalActionButtonDef
  | IFilterActionButtonDef
  | ISimpleFilterActionButtonDef
  | IPresetFilterCollectionDef;

export interface IActionDef {
  icon?: React.ReactNode;
  label: string;
  disabled?: boolean | ((data: IActionData) => boolean);
  hidden?: boolean | ((data: IActionData) => boolean);
  level?: 'default' | 'primary';
}

interface IModalAffectingAction {
  closeModal?: boolean;
}

export interface IActionMeta {
  formSubmitting: boolean;
  hideLabel?: boolean;
  size?: 'sm' | 'lg';
  wide?: boolean;
  borderless?: boolean;
  circular?: boolean;
  danger?: boolean;
  removeArrayItem?: () => void;
}

export interface IActionData {
  actionValue: any;
  parentValue: any;
  paneValue: any;
  panelValue: any;
  sectionValue: any;
  fieldDataAddr?: any;
}

// -------------------------------------------------

export interface IActionButtonDef extends IActionDef, IModalAffectingAction {
  actionType: ActionType.actionButton;
  onClick: (data: IActionData) => void | Promise<void>;
  className?: string;
}

export function isActionButtonDef(def: ActionDefs): def is IActionButtonDef {
  return def.actionType === ActionType.actionButton;
}

// -------------------------------------------------

export interface IActionLinkDef extends IActionDef {
  actionType: ActionType.actionLink;
  to: History.LocationDescriptor | ((data: IActionData) => History.LocationDescriptor);
  onClick?: (data: IActionData) => void;
}

export function isActionLinkDef(def: ActionDefs): def is IActionLinkDef {
  return def.actionType === ActionType.actionLink;
}

// -------------------------------------------------

export interface IAddActionLinkDef extends IActionDef {
  actionType: ActionType.addActionLink;
  to: History.LocationDescriptor | ((data: IActionData) => History.LocationDescriptor);
  onClick?: (data: IActionData) => void;
}

export function isAddActionLinkDef(def: ActionDefs): def is IAddActionLinkDef {
  return def.actionType === ActionType.addActionLink;
}

// -------------------------------------------------

export interface IActionCollectionDef extends Partial<IActionDef> {
  actionType: ActionType.actionCollection;
  actionGroups: Array<IActionGroupDef>;
}

export function isActionCollectionDef(def: ActionDefs): def is IActionCollectionDef {
  return def.actionType === ActionType.actionCollection;
}

// -------------------------------------------------

export interface ISubmitActionButtonDef extends Omit<IActionDef, 'label'>, IModalAffectingAction {
  actionType: ActionType.submitActionButton;
  label?: string;
  subActionGroups?: Array<IActionGroupDef> | ((formApi: IFormApi) => Array<IActionGroupDef>);
  confirmationModalDef?: ModalDefBuilder;
  confirmationModalSize?: ShellModalSize;
  suppressConfirmationModal?: boolean | ((data: IActionData) => boolean);
  submissionMeta?: {}; // arbitary metadata that is passed to form presubmit
}

export function isSubmitActionButtonDef(def: ActionDefs): def is ISubmitActionButtonDef {
  return def.actionType === ActionType.submitActionButton;
}

// -------------------------------------------------

export interface IResetActionButtonDef extends Omit<IActionDef, 'label'>, IModalAffectingAction {
  actionType: ActionType.resetActionButton;
  label?: string;
  resetToData?: Object | (() => Object);
  onResetAction?: () => void;
}

export function isResetActionButtonDef(def: ActionDefs): def is IResetActionButtonDef {
  return def.actionType === ActionType.resetActionButton;
}

// -------------------------------------------------

export interface ISearchActionButtonDef extends Pick<IActionDef, 'disabled' | 'hidden'> {
  actionType: ActionType.searchActionButton;
  minChar?: number;
  ignoreFilters?: boolean;
}

export function isSearchActionButtonDef(def: ActionDefs): def is ISearchActionButtonDef {
  return def.actionType === ActionType.searchActionButton;
}

// -------------------------------------------------

export interface IPrintActionButtonDef extends Pick<IActionDef, 'disabled' | 'hidden' | 'level'> {
  actionType: ActionType.printActionButton;
  label?: string;
  loadDataAsync?: (actionData: IActionData) => Promise<void>;
  printContent: ((actionData: IActionData) => React.ReactNode) | Blob | undefined;
  printTitle?: string;
}

export function isPrintActionButtonDef(def: ActionDefs): def is IPrintActionButtonDef {
  return def.actionType === ActionType.printActionButton;
}

// -------------------------------------------------

export interface IAddArrayItemActionButtonDef extends IActionDef {
  actionType: ActionType.addArrayItemActionButton;
  newItemData?: any;
  getNewItemData?: (actionData: IActionData) => any;
  postClick?: (data: IActionData) => void;
}

export function isAddArrayItemActionButtonDef(
  def: ActionDefs
): def is IAddArrayItemActionButtonDef {
  return def.actionType === ActionType.addArrayItemActionButton;
}

// -------------------------------------------------

export interface IRemoveArrayItemActionButtonDef extends IActionDef {
  actionType: ActionType.removeArrayItemActionButton;
  postClick?: (data: IActionData) => void;
}

export function isRemoveArrayItemActionButtonDef(
  def: ActionDefs
): def is IRemoveArrayItemActionButtonDef {
  return def.actionType === ActionType.removeArrayItemActionButton;
}

// -------------------------------------------------

export interface IMoveArrayItemActionButtonDef extends IActionDef {
  actionType: ActionType.moveArrayItemActionButton;
  moveDirection: 'next' | 'prev';
  postClick?: (data: IActionData) => void;
}

export function isMoveArrayItemActionButtonDef(
  def: ActionDefs
): def is IMoveArrayItemActionButtonDef {
  return def.actionType === ActionType.moveArrayItemActionButton;
}

// -------------------------------------------------

export type ModalDefBuilder = (api: IModalDefBuilderApi) => ISectionDef;

export interface IModalDefBuilderApi {
  actionData: IActionData;
  parentFormApi: IFormApi;
}

export interface IModalActionButtonDef extends Omit<IActionButtonDef, 'actionType' | 'onClick'> {
  actionType: ActionType.modalActionButton;
  modalDef: ModalDefBuilder;
  modalSize: ShellModalSize;
  onOpenModal?: (parent: any, actionValue: any) => Promise<void> | void;
  onCloseModal?: () => void;
}

export function isModalActionButtonDef(def: ActionDefs): def is IModalActionButtonDef {
  return def.actionType === ActionType.modalActionButton;
}

// -------------------------------------------------

export interface ICloseModalActionButtonDef extends Omit<IActionDef, 'label'> {
  actionType: ActionType.closeModalActionButton;
  label?: string;
  closeAction?: () => void;
}

export function isCloseModalActionButtonDef(def: ActionDefs): def is ICloseModalActionButtonDef {
  return def.actionType === ActionType.closeModalActionButton;
}

// -------------------------------------------------

export type FilterDefBuilder = (api: IFilterDefBuilderApi) => Array<IPanelDef>;

export interface IFilterDefBuilderApi {
  actionData: IActionData;
  parentFormApi: IFormApi;
}

export interface IFilterActionButtonDef extends Omit<IActionDef, 'label'> {
  actionType: ActionType.filterActionButton;
  filterFields: Array<FieldDefs>;
  filterDef?: FilterDefBuilder;
  filterSize?: ShellModalSize;
  defaultValues?: any;
  onOpenModal?: IModalActionButtonDef['onOpenModal'];
  onCloseModal?: IModalActionButtonDef['onCloseModal'];
}

export function isFilterActionButtonDef(def: ActionDefs): def is IFilterActionButtonDef {
  return def.actionType === ActionType.filterActionButton;
}

// -------------------------------------------------

export interface ISimpleFilterActionButtonDef extends Omit<IActionDef, 'label'> {
  actionType: ActionType.simpleFilterActionButton;
  filterDef?: FilterDefBuilder;
  filterFields?: Array<FieldDefs>;
  filterSize?: ShellModalSize;
  onFormSubmit: (values: any) => Promise<void>;
  onFormReset: () => Object;
  label?: string;
}

export function isSimpleFilterActionButtonDef(
  def: ActionDefs
): def is ISimpleFilterActionButtonDef {
  return def.actionType === ActionType.simpleFilterActionButton;
}

// -------------------------------------------------

export interface IPresetFilter {
  label: string;
  count: number;
  filter: object;
}

export interface IPresetFilterCollectionDef extends Pick<IActionDef, 'hidden'> {
  actionType: ActionType.presetFilterCollection;
  presetFilters: Array<IPresetFilter>;
  filterDataAddrs: Array<string>;
}

export function isPresetFilterCollectionDef(def: ActionDefs): def is IPresetFilterCollectionDef {
  return def.actionType === ActionType.presetFilterCollection;
}

// -------------------------------------------------

export interface ICalendarButtonDef extends Pick<IActionDef, 'hidden'> {
  actionType: ActionType.calendarButton;
  onChange: (newValue: DateTime) => void;
  today?: DateTime;
  value?: DateTime;
}

export function isCalendarButtonDef(def: ActionDefs): def is ICalendarButtonDef {
  return def.actionType === ActionType.calendarButton;
}

// -------------------------------------------------
