import './LabelledField.scss';

import * as History from 'history';
import { Link } from 'react-router-dom';
import { FormGroup } from 'reactstrap';
import deepEqual from 'src/infrastructure/deepEqual';
import FieldLabel from 'src/views/components/FieldLabel';
import { FieldInfoTooltip } from '../FieldInfoTooltip';
import { shallowMemoize } from 'src/infrastructure/memoizeOne';
import { Component } from 'react';

const FieldGroup: React.FC<{ noForm: boolean; className?: string }> = ({ noForm, ...rest }) =>
  noForm ? <div {...rest} /> : <FormGroup {...rest} />;

const LINK_SCHEMES = ['http', 'https', 'mailto', 'tel'];

export interface ILabelledFieldProps {
  labelValue?: React.ReactNode;
  tooltipValue?: React.ReactNode;
  readonly?: boolean;
  readonlyValue?: React.ReactNode;
  readonlyLinkTo?: History.LocationDescriptor;
  mandatory?: boolean;
  hideLabel?: boolean;
  noForm?: boolean;
  className?: string;
  for?: string;
}

class LabelledField extends Component<ILabelledFieldProps> {
  shouldComponentUpdate(nextProps: Readonly<ILabelledFieldProps>) {
    if (!nextProps.readonly) {
      // We don't know what's in the children, so have to rerender
      return true;
    }

    return (
      this.props.labelValue !== nextProps.labelValue ||
      this.props.tooltipValue !== nextProps.tooltipValue ||
      this.props.readonly !== nextProps.readonly ||
      this.props.readonlyValue !== nextProps.readonlyValue ||
      !deepEqual(this.props.readonlyLinkTo, nextProps.readonlyLinkTo) ||
      this.props.mandatory !== nextProps.mandatory ||
      this.props.hideLabel !== nextProps.hideLabel ||
      this.props.noForm !== nextProps.noForm ||
      this.props.className !== nextProps.className ||
      this.props.for !== nextProps.for
    );
  }

  tooltip = shallowMemoize(
    val => val && <FieldInfoTooltip className="field-tooltip">{val}</FieldInfoTooltip>
  );

  render() {
    const {
      labelValue,
      tooltipValue,
      readonly,
      readonlyValue,
      readonlyLinkTo,
      mandatory,
      hideLabel,
      noForm,
      className,
      for: htmlFor,
      children,
    } = this.props;

    const buildLink = (linkTo: History.LocationDescriptor) =>
      typeof linkTo === 'string' && LINK_SCHEMES.some(s => linkTo.startsWith(`${s}:`)) ? (
        <a href={linkTo}>{readonlyValue}</a>
      ) : (
        <Link to={linkTo}>{readonlyValue}</Link>
      );

    return (
      <FieldGroup
        noForm={noForm || false}
        className={`labelled-field-component ${className || ''} ${
          noForm ? 'no-form-field' : 'form-field'
        }`}>
        {hideLabel ? null : (
          <>
            <FieldLabel className="field-label" for={htmlFor} mandatory={mandatory}>
              {labelValue}
            </FieldLabel>
            {this.tooltip(tooltipValue)}
          </>
        )}
        {readonly ? (
          <div className="field-content">
            {readonlyLinkTo ? buildLink(readonlyLinkTo) : readonlyValue}
          </div>
        ) : (
          children
        )}
      </FieldGroup>
    );
  }
}

export default LabelledField;
