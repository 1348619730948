import './MaintainGoodsReceived.scss';
import { Component } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import {
  PagePrimarySize,
  PaneType,
  FieldType,
  ActionType,
} from 'src/views/definitionBuilders/types';
import CrudPage, { ICrudPageDef, CrudPageMode } from 'src/views/components/Page/pages/CrudPage';
import { IMatchingPart } from 'src/domain/entities/workshop/parts/PartsModel';
import { IAutocompleteResult } from 'src/domain/baseTypes';
import { ChangeState, JobTaskStatus, PurchaseOrderStatus } from 'src/api/enums';
import deepEqual from 'src/infrastructure/deepEqual';
import { isArray } from 'util';
import { multiplyUnitPriceByQuantityArithmeticallySafe } from 'src/infrastructure/mathUtil';
import { formatCurrency } from 'src/infrastructure/formattingUtils';
import { CreditNoteIcon, CheckIcon, BanIcon } from 'src/images/icons';
import { DateTime } from 'luxon';
import { sortJobTaskListItemsForPurchaseOrders } from 'src/views/components/workshop/tasks/sortJobTaskListItemsForPurchaseOrders';
import { calculateInvoiceLinesTotals } from '../shared/purchaseOrderHelpers';

type PurchaseOrderItem = Workshop.Domain.Queries.Purchasing.PurchaseOrderItem;
type PartListItemForPO = Workshop.Domain.Queries.Parts.PartListItemForPO;
type AssetItem = Workshop.Domain.Queries.AssetItem;
type JobTaskListItem = Workshop.Domain.Queries.JobTask.JobTaskListItem;
type GoodsReceivedItem = Workshop.Domain.Queries.Purchasing.GoodsReceivedItem;
type GoodsReceivedLineItem = Workshop.Domain.Queries.Purchasing.GoodsReceivedLineItem;
type CreateGoodsReceivedCommand = Workshop.Domain.Commands.Purchasing.CreateGoodsReceivedCommand;
type UpdateGoodsReceivedCommand = Workshop.Domain.Commands.Purchasing.UpdateGoodsReceivedCommand;

export interface IGoodsReceivedProps {
  mode: CrudPageMode;
  canManagePurchaseOrders: boolean;
  purchaseOrder: PurchaseOrderItem | undefined;
  assets: AssetItem[];
  loadPurchaseOrder: (id: string) => Promise<void>;
  loadAssetListItems: () => Promise<void>;
  createGoodsReceived: (command: CreateGoodsReceivedCommand) => Promise<void>;
  updateGoodsReceived: (command: UpdateGoodsReceivedCommand) => Promise<void>;
  searchParts: (
    search: string,
    includeLatestPrice?: boolean
  ) => Promise<IAutocompleteResult<IMatchingPart>>;
  getJobTasksForAsset: (assetId: string) => Promise<JobTaskListItem[]>;
  onMarkAsExported: (id: string, goodsReceivedId: number) => Promise<void>;
  onRemoveExportedIndicator: (id: string, goodsReceivedId: number) => Promise<void>;
}

interface IMaintainGoodsReceivedState {
  jobTasksForAssetMap: { [key: string]: JobTaskListItem[] };
}

interface IMaintainGoodsReceivedRouteParams {
  purchaseOrderId: string;
  goodsReceivedId: string;
}

type InternalProps = IGoodsReceivedProps & RouteComponentProps<IMaintainGoodsReceivedRouteParams>;

class MaintainGoodsReceived extends Component<InternalProps, IMaintainGoodsReceivedState> {
  constructor(props: InternalProps) {
    super(props);
    this.state = { jobTasksForAssetMap: {} };
  }

  private get isUpdateMode() {
    return this.props.mode === 'update';
  }

  private get purchaseOrderId() {
    return this.props.match.params.purchaseOrderId;
  }

  private get goodsReceivedId() {
    return parseInt(this.props.match.params.goodsReceivedId, 10);
  }

  private loadData = () => {
    return Promise.all([
      this.props.loadPurchaseOrder(this.purchaseOrderId),
      this.props.loadAssetListItems(),
    ]).then(_ => Promise.resolve());
  };

  private handlePreSubmitForCreate = (gr: GoodsReceivedItem): CreateGoodsReceivedCommand => {
    return {
      purchaseOrderId: this.purchaseOrderId,
      invoiceNumber: gr.invoiceNumber,
      receivedDate: DateTime.fromISO(gr.receivedDate).toISODate(),
      lines: gr.lines
        .filter(l => l.changeState !== ChangeState.Deleted)
        .map(l => {
          return {
            changeState: ChangeState.Added,
            description: l.description,
            quantity: l.quantity,
            unitPrice: gr.invoiceNumber ? l.unitPrice : undefined,
            applyGst: l.applyGst,
            purchaseOrderLineId: l.purchaseOrderLineId,
            partId: l.part && l.part.id,
            jobTaskId: l.jobTask && l.jobTask.id,
          };
        }),
      invoiceDate:
        gr.invoiceDate && gr.invoiceNumber.length
          ? DateTime.fromISO(gr.invoiceDate).toISODate()
          : undefined,
    };
  };

  private handlePreSubmitForUpdate = (gr: GoodsReceivedItem): UpdateGoodsReceivedCommand => {
    const originalGoodsReceived = this.props.purchaseOrder!.receivedGoods.find(
      rg => rg.id.toString() === this.goodsReceivedId.toString()
    );
    const getChangeState = (li: GoodsReceivedLineItem) => {
      if (li.changeState === ChangeState.Added || li.changeState === ChangeState.Deleted) {
        return li.changeState;
      }
      const originalLineItem =
        originalGoodsReceived && originalGoodsReceived.lines.find(l => l.id === li.id);
      if (!originalLineItem) {
        throw new Error('Cannot find original Goods Received Line');
      }
      return deepEqual(li, originalLineItem) ? ChangeState.Unchanged : ChangeState.Modified;
    };

    return {
      purchaseOrderId: this.purchaseOrderId,
      goodsReceivedId: this.goodsReceivedId,
      invoiceNumber: gr.invoiceNumber,
      receivedDate: DateTime.fromISO(gr.receivedDate).toISODate(),
      lines: gr.lines.map(l => {
        return {
          changeState: getChangeState(l),
          id: l.id,
          description: l.description,
          quantity: l.quantity,
          unitPrice: gr.invoiceNumber ? l.unitPrice : undefined,
          applyGst: l.applyGst,
          purchaseOrderLineId: l.purchaseOrderLineId,
          partId: l.part && l.part.id,
          jobTaskId: l.jobTask && l.jobTask.id,
        };
      }),
      invoiceDate:
        gr.invoiceDate && gr.invoiceNumber.length
          ? DateTime.fromISO(gr.invoiceDate).toISODate()
          : undefined,
    };
  };

  private readonly markAsExported = () => {
    return this.props.onMarkAsExported(this.purchaseOrderId, this.goodsReceivedId);
  };

  private readonly removeExportedIndicator = () => {
    return this.props.onRemoveExportedIndicator(this.purchaseOrderId, this.goodsReceivedId);
  };

  private readonly getPageDef = (isInEditMode: boolean): ICrudPageDef => {
    const { purchaseOrder, canManagePurchaseOrders } = this.props;

    const hasInvoiceNumber = (item: GoodsReceivedItem) => !!item.invoiceNumber;

    const isLineFromPurchaseOrder = (line: GoodsReceivedLineItem) =>
      line.purchaseOrderLineId != null;

    const hasZeroQuantity = (line: GoodsReceivedLineItem) =>
      line.quantity != null && line.quantity.toString() === '0';

    const getTitle = () => {
      const poPart = `Purchase Order ${(purchaseOrder && purchaseOrder.purchaseOrderNumber) || ''}`;
      const grPart = !this.isUpdateMode ? 'Receive Goods' : `Goods Received`;
      return `${poPart}: ${grPart}`;
    };

    const getBadge = () => {
      if (!this.goodsReceivedId) return undefined;
      const gr = purchaseOrder?.receivedGoods.find(
        rg => rg.id.toString() === this.goodsReceivedId.toString()
      );
      return gr && gr.exported ? { label: 'Exported' } : undefined;
    };

    const hideMarkAsExported = () => {
      if (!purchaseOrder || !this.goodsReceivedId || !canManagePurchaseOrders) return true;
      const gr = purchaseOrder?.receivedGoods.find(
        rg => rg.id.toString() === this.goodsReceivedId.toString()
      );
      return !gr?.invoiceNumber || gr.exported;
    };

    const hideRemoveExportedIndicator = () => {
      if (!purchaseOrder || !this.goodsReceivedId || !canManagePurchaseOrders) return true;
      const gr = purchaseOrder?.receivedGoods.find(
        rg => rg.id.toString() === this.goodsReceivedId.toString()
      );
      return !gr?.exported;
    };

    return {
      primarySize: PagePrimarySize.full,
      primarySection: {
        title: getTitle(),
        badge: getBadge(),
        primaryActions: [
          {
            actions: [
              {
                actionType: ActionType.actionCollection,
                hidden: !this.isUpdateMode || isInEditMode || !canManagePurchaseOrders,
                actionGroups: [
                  {
                    actions: [
                      {
                        actionType: ActionType.actionLink,
                        label: 'Receive Returned Part Credit',
                        icon: <CreditNoteIcon fixedWidth />,
                        to: `/workshop/purchase-orders/${this.purchaseOrderId}/goods-received/${this.goodsReceivedId}/returned-part-credits/create`,
                        hidden: !this.isUpdateMode || isInEditMode || !canManagePurchaseOrders,
                      },
                      {
                        actionType: ActionType.actionButton,
                        label: 'Mark as Exported',
                        icon: <CheckIcon fixedWidth />,
                        onClick: this.markAsExported,
                        hidden: hideMarkAsExported(),
                      },
                      {
                        actionType: ActionType.actionButton,
                        label: 'Remove Exported Indicator',
                        icon: <BanIcon fixedWidth />,
                        onClick: this.removeExportedIndicator,
                        hidden: hideRemoveExportedIndicator(),
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
        panels: [
          {
            panes: [
              {
                paneType: PaneType.formFieldsPane,
                columnCount: 3,
                fields: [
                  {
                    fieldType: FieldType.textField,
                    label: 'Supplier',
                    dataAddr: 'supplierName',
                    readonly: true,
                  },
                  {
                    fieldType: FieldType.textField,
                    label: 'Contact',
                    dataAddr: 'supplierContactName',
                    readonly: true,
                  },
                ],
              },
              {
                paneType: PaneType.formFieldsPane,
                columnCount: 3,
                fields: [
                  {
                    fieldType: FieldType.dateField,
                    label: 'Date Received',
                    dataAddr: 'receivedDate',
                    mandatory: true,
                  },
                  {
                    fieldType: FieldType.textField,
                    dataAddr: 'invoiceNumber',
                    label: 'Invoice Number',
                    maxLength: 50,
                    mandatory: _ =>
                      !!purchaseOrder && purchaseOrder.status.id === PurchaseOrderStatus.Complete,
                    onChange: d =>
                      !d.fieldData.fieldValue || !d.fieldData.fieldValue.length
                        ? d.setFormValue(['invoiceDate'], undefined)
                        : {},
                  },
                  {
                    fieldType: FieldType.dateField,
                    label: 'Invoice Date',
                    dataAddr: 'invoiceDate',
                    hidden: d =>
                      !d.parentValue.invoiceNumber || !d.parentValue.invoiceNumber.length,
                  },
                ],
              },
            ],
          },
          {
            title: 'Parts',
            dataAddr: 'lines',
            panes: [
              {
                paneType: PaneType.tablePane,
                mandatory: true,
                dataRequiredForRows: 'sectionValue',
                fields: [
                  {
                    fieldType: FieldType.selectAsyncField,
                    label: 'Part Number',
                    dataAddr: 'part',
                    valueKey: 'id',
                    descriptionKey: 'partNumber',
                    menuWidth: 'fitContent',
                    columnWidth: '12em',
                    mandatory: false,
                    loadOptionItems: search => this.props.searchParts(search, true),
                    optionRenderer: (o: IMatchingPart) => (
                      <div style={{ whiteSpace: 'nowrap' }}>
                        {o.partNumber} - {o.description}
                      </div>
                    ),
                    readonly: d => isLineFromPurchaseOrder(d.parentValue),
                    linkTo: d => `/workshop/parts/${d.parentValue.part.id}`,
                    onChange: api => {
                      const selectedPart = api.newFieldValue as PartListItemForPO;
                      api.setFormValue(
                        [api.fieldDataAddr[0], api.fieldDataAddr[1], 'unitPrice'],
                        selectedPart && selectedPart.latestPrice
                      );
                      api.setFormValue(
                        [api.fieldDataAddr[0], api.fieldDataAddr[1], 'description'],
                        selectedPart && selectedPart.description
                      );

                      if (!selectedPart?.id) {
                        api.setFormValue(
                          [api.fieldDataAddr[0], api.fieldDataAddr[1], 'asset'],
                          undefined
                        );
                        api.setFormValue(
                          [api.fieldDataAddr[0], api.fieldDataAddr[1], 'jobTask'],
                          undefined
                        );
                      }
                    },
                  },
                  {
                    fieldType: FieldType.textField,
                    label: 'Description',
                    dataAddr: 'description',
                    mandatory: true,
                    maxLength: 200,
                    readonly: d => isLineFromPurchaseOrder(d.parentValue),
                  },
                  {
                    fieldType: FieldType.numericField,
                    label: 'Ordered Qty',
                    dataAddr: 'orderedQuantity',
                    columnWidth: '8em',
                    readonly: true,
                    numericConfig: { numericType: 'unsignedDecimal', maxPointDigits: 4 },
                    formatReadonly: d => {
                      const poLineId = d.parentValue.purchaseOrderLineId;
                      if (!poLineId) {
                        return 0;
                      }
                      const poLine =
                        purchaseOrder &&
                        purchaseOrder.purchaseOrderLines.find(pol => pol.id === poLineId);
                      return poLine!.quantity;
                    },
                  },
                  {
                    fieldType: FieldType.numericField,
                    label: 'Returned Qty',
                    dataAddr: 'returnedQuantity',
                    columnWidth: '8em',
                    readonly: true,
                    numericConfig: { numericType: 'unsignedDecimal', maxPointDigits: 4 },
                  },
                  {
                    fieldType: FieldType.numericField,
                    label: 'Received Qty',
                    dataAddr: 'quantity',
                    columnWidth: '8em',
                    numericConfig: { numericType: 'unsignedDecimal', maxPointDigits: 4 },
                    mandatory: true,
                    readonly: d => (d.parentValue as GoodsReceivedLineItem).hasCreditLines,
                    validate: d =>
                      hasZeroQuantity(d.parentValue) ? 'Received quantity cannot be 0' : undefined,
                  },
                  {
                    fieldType: FieldType.numericField,
                    label: 'Unit Price',
                    dataAddr: 'unitPrice',
                    columnWidth: '7em',
                    numericConfig: { numericType: 'unsignedDecimal', maxPointDigits: 5 },
                    readonly: d => !hasInvoiceNumber(d.sectionValue),
                    mandatory: d => hasInvoiceNumber(d.sectionValue),
                    formatReadonly: 'unitPrice',
                  },
                  {
                    fieldType: FieldType.yesNoField,
                    label: 'Apply GST',
                    dataAddr: 'applyGst',
                    columnWidth: '7em',
                    readonly: d => !hasInvoiceNumber(d.sectionValue),
                    mandatory: d => hasInvoiceNumber(d.sectionValue),
                  },
                  {
                    fieldType: FieldType.readonlyField,
                    label: 'Total',
                    columnWidth: '6em',
                    formatReadonly: d => {
                      const line = d.fieldValue as GoodsReceivedLineItem;
                      if (!line.quantity || !line.unitPrice) {
                        return undefined;
                      }
                      return formatCurrency(
                        multiplyUnitPriceByQuantityArithmeticallySafe(line.quantity, line.unitPrice)
                          .value
                      );
                    },
                  },
                  {
                    fieldType: FieldType.assetSelectField,
                    label: 'Asset',
                    dataAddr: 'asset',
                    columnWidth: '8em',
                    valueKey: 'id',
                    descriptionKey: 'name',
                    optionItems: this.props.assets,
                    mandatory: false,
                    readonly: d => !d.parentValue.part || isLineFromPurchaseOrder(d.parentValue),
                    onChange: api => {
                      const assetId = api.newFieldValue && (api.newFieldValue as AssetItem).id;
                      this.props.getJobTasksForAsset(assetId).then(r => {
                        const jobTasksForAssetMap = {
                          ...this.state.jobTasksForAssetMap,
                          [assetId]: r,
                        };
                        this.setState({ jobTasksForAssetMap });
                      });
                      api.setFormValue(
                        [api.fieldDataAddr[0], api.fieldDataAddr[1], 'jobTask'],
                        undefined
                      );
                    },
                  },
                  {
                    fieldType: FieldType.selectField,
                    label: 'Task',
                    dataAddr: 'jobTask',
                    columnWidth: '8em',
                    valueKey: 'id',
                    descriptionKey: 'jobTaskNumber',
                    menuWidth: 'fitContent',
                    readonly: d => !d.parentValue.asset || isLineFromPurchaseOrder(d.parentValue),
                    linkTo: d => `/workshop/tasks/${d.parentValue.jobTask.id}`,
                    optionItems: d => {
                      const line = d.parentValue as GoodsReceivedLineItem;
                      const assetId = line && line.asset && line.asset.id;
                      return sortJobTaskListItemsForPurchaseOrders(
                        this.state.jobTasksForAssetMap[assetId]
                      );
                    },
                    valuesToDisable: d => {
                      const item = d.parentValue as GoodsReceivedLineItem;
                      const assetId = item && item.asset && item.asset.id;
                      if (!this.state.jobTasksForAssetMap[assetId]) {
                        return [];
                      }
                      return this.state.jobTasksForAssetMap[assetId]
                        .filter(a => a.status.id === JobTaskStatus.Cancelled)
                        .map(a => a.id);
                    },
                    mandatory: false,
                    validate: d => {
                      const line = d.parentValue as GoodsReceivedLineItem;
                      const asset = line && line.asset;
                      return !line.jobTask && asset ? 'Task must be selected' : undefined;
                    },
                    optionRenderer: (o: JobTaskListItem) => (
                      <div style={{ whiteSpace: 'nowrap' }}>
                        <strong>{o.jobTaskNumber}</strong>
                        <small>
                          &nbsp; • {o.category.description} • {o.status.description}
                        </small>
                      </div>
                    ),
                  },
                  {
                    fieldType: FieldType.actionListField,
                    columnWidth: '1px',
                    actionGroups: [
                      {
                        actions: [
                          {
                            actionType: ActionType.removeArrayItemActionButton,
                            label: 'Remove Line',
                            hidden: d =>
                              (this.isUpdateMode && !isInEditMode) ||
                              (!!purchaseOrder &&
                                purchaseOrder.status.id === PurchaseOrderStatus.Complete) ||
                              (d.parentValue as GoodsReceivedLineItem).hasCreditLines,
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
              {
                paneType: PaneType.actionListPane,
                actionGroups: [
                  {
                    actions: [
                      {
                        actionType: ActionType.addArrayItemActionButton,
                        hidden: this.isUpdateMode && !isInEditMode,
                        label: 'Add Part/Service Provider',
                        newItemData: { applyGst: true },
                      },
                    ],
                  },
                ],
              },
              {
                paneType: PaneType.customPane,
                render: api => {
                  const lines = api.data.panelValue as GoodsReceivedLineItem[];
                  if (!isArray(lines)) {
                    return undefined;
                  }

                  const { gst, subtotal, totalInclGst } = calculateInvoiceLinesTotals(lines);

                  return (
                    <table className="maintain-goods-received-totals">
                      <tbody>
                        <tr>
                          <td>
                            <label>Subtotal:</label>
                          </td>
                          <td className="price">{formatCurrency(subtotal.value)}</td>
                        </tr>
                        <tr>
                          <td>
                            <label>GST:</label>
                          </td>
                          <td className="price">{formatCurrency(gst.value)}</td>
                        </tr>
                        <tr>
                          <td>
                            <label>Total:</label>
                          </td>
                          <td className="price">{formatCurrency(totalInclGst.value)}</td>
                        </tr>
                      </tbody>
                    </table>
                  );
                },
              },
            ],
          },
        ],
        onFormPreSubmit: this.isUpdateMode
          ? this.handlePreSubmitForUpdate
          : this.handlePreSubmitForCreate,
        onFormSubmit: this.isUpdateMode
          ? this.props.updateGoodsReceived
          : this.props.createGoodsReceived,
      },
    };
  };

  render() {
    const { purchaseOrder, mode, canManagePurchaseOrders } = this.props;

    const getEditData = (gr: GoodsReceivedItem | undefined) => {
      if (!gr) {
        return undefined;
      }
      return {
        supplierName: purchaseOrder && purchaseOrder.supplier.name,
        supplierContactName:
          purchaseOrder && purchaseOrder.contact && purchaseOrder.contact.contactName,
        ...gr,
      };
    };

    return (
      <CrudPage
        def={({ updating }) => this.getPageDef(updating)}
        mode={mode}
        isEditingForbidden={!canManagePurchaseOrders}
        onLoadData={this.loadData}
        data={
          this.goodsReceivedId &&
          getEditData(
            purchaseOrder &&
              purchaseOrder.receivedGoods.find(
                rg => rg.id.toString() === this.goodsReceivedId.toString()
              )
          )
        }
        onLoadCreateDefaultData={this.loadData}
        createDefaultData={{
          lines:
            purchaseOrder &&
            purchaseOrder.purchaseOrderLines
              .filter(l => !l.complete)
              .map(l => {
                return {
                  part: l.part,
                  description: l.description,
                  asset: l.asset,
                  jobTask: l.jobTask,
                  purchaseOrderLineId: l.id,
                  unitPrice: l.part && l.part.latestPrice,
                  applyGst: true,
                };
              }),
          supplierName: purchaseOrder && purchaseOrder.supplier.name,
          supplierContactName:
            purchaseOrder && purchaseOrder.contact && purchaseOrder.contact.contactName,
        }}
      />
    );
  }
}

export default MaintainGoodsReceived;
