import './NavListPagePane.scss';

import { Link } from 'react-router-dom';
import { INavListPaneDef, IActionLinkDef } from 'src/views/definitionBuilders/types';

interface INavListPagePaneProps {
  paneDef: INavListPaneDef;
}

const NavListPagePane: React.FC<INavListPagePaneProps> = ({ paneDef }) => {
  const getTo = (actionDef: IActionLinkDef) => {
    if (typeof actionDef.to === 'function') {
      throw new Error("NavListPagePane doesn't support dynamic links");
    } else {
      return actionDef.to;
    }
  };
  return (
    <ul className="nav-list-page-pane-component list-unstyled">
      {paneDef.links.map((l, i) => (
        <li key={i}>
          <Link className="nav-link" to={getTo(l)}>
            <span>{l.label}</span>
            <span>{l.icon}</span>
          </Link>
        </li>
      ))}
    </ul>
  );
};

export default NavListPagePane;
