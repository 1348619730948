import './views.scss';

import { Link } from 'react-router-dom';
import { UserIcon, BusIcon } from 'src/images/icons';
import { parseTimeSpan } from 'src/infrastructure/dateUtils';
import { formatDurationAsHoursAndMinutes } from 'src/infrastructure/formattingUtils';
import { distinct } from 'src/infrastructure/arrayUtils';
import { FieldType, PaneType } from 'src/views/definitionBuilders/types';
import { withFormApi } from 'src/views/components/Page/forms';
import { IFormApi } from 'src/views/components/Page/forms/base';
import {
  AllBoundedItemTypes,
  isWorkshopJob,
  isOperationsJob,
  isPeopleLeave,
  isMarkOutOfServiceItem,
  isWorkshopShift,
} from 'src/domain/entities/operations/job/ListJobsForAllocationsModel';
import { SearchTechSpecValues } from 'src/domain/entities/workshop/asset/AssetsModel';
import { isJobTypeWithoutAsset } from 'src/views/routes/operations/shared/jobTypeHelpers';
import {
  IGanttCalendarGroupItem,
  IGanttView,
} from 'src/views/routes/operations/job/allocations/ganttCalendar/baseTypes';
import {
  consolidateSkillSpecRequirementsIds,
  ISplittedSkillSpecRequirements,
  doesMemberHaveRequiredSkillSpecs,
} from 'src/domain/entities/people/staffMember/SkillSpecsHelpers';
import { getSkillSpecRequirementFieldDefs } from 'src/views/routes/operations/shared/getSkillSpecRequirementFieldDefs';
import { getTechSpecRequirementFieldDefs } from 'src/views/routes/operations/shared/getTechSpecRequirementFieldDefs';
import {
  doesAssetHaveRequiredBoolTechSpecs,
  getTextTechSpecValues,
  doesAssetHaveRequiredTextTechSpecs,
  splitTechSpecs,
  getDropdownTechSpecValues,
  doesAssetHaveRequiredDropdownTechSpecs,
  getBoolTechSpecValues,
} from 'src/domain/entities/workshop/techSpecs/TechSpecsHelpers';
import React, { ReactNode } from 'react';
import { getLicenceClasses } from 'src/views/routes/people/staffMembers/MaintainStaffMember/getLicenceModalDef';

type StaffMemberDto = Common.Dtos.StaffMemberDto;
type AssetItem = Common.Queries.Workshop.GetFleetAssetList.AssetItem;
type PaidHoursInPayPeriod = Operations.Domain.Queries.GetPaidHoursInPayPeriodForDay.PaidHoursInPayPeriod;
type AssetSubcategory = Workshop.Domain.AggregatesModel.AssetAggregate.AssetSubcategory;
type AssetHousingLocation = Workshop.Domain.AggregatesModel.AssetAggregate.AssetHousingLocation;
type TechSpecItem = Common.Dtos.TechSpecItem;
type LicenceClass = Common.AggregatesModel.People.StaffMemberAggregate.LicenceClass;
type SkillSpecItem = Common.Dtos.SkillSpecItem;
type SubcontractorItem = Common.Dtos.SubcontractorItem;
type TechSpecDropdownOption = Workshop.Domain.Queries.TechSpecs.TechSpecDropdownOptionsItem.TechSpecDropdownOption;
type StaffDepotDto = Common.Dtos.StaffDepotDto;
type EmploymentStatusDto = Common.Dtos.EmploymentStatusDto;
type LicenceType = Common.AggregatesModel.People.StaffMemberAggregate.LicenceType;
type VehicleType = Common.Dtos.VehicleTypeItem;
type ListRoleItem = Common.Dtos.ListRoleItem;

const UnallocatedGroup = (
  <div>
    <em>Unallocated</em>
  </div>
);

interface IUnallocatedGroup extends IGanttCalendarGroupItem {
  id: undefined;
}

const SelectableField: React.FC<{ formApi: IFormApi; id: string }> = ({
  formApi,
  id,
  children,
}) => {
  return (
    <div className="selectable-field-component">
      {children}
      <label className="radio-selector" title="Select this item">
        <input
          autoComplete="off"
          type="radio"
          id={id}
          value={id}
          checked={formApi.getValue(formApi.getFullField()) === id}
          onChange={e => {
            if (e.target.checked) {
              formApi.setValue(formApi.getFullField(), id);
            }
          }}
          onBlur={e => {
            formApi.setTouched(formApi.getFullField());
          }}
        />
      </label>
    </div>
  );
};

const ConnectedSelectableField = withFormApi(SelectableField);

function addRadioField<TProps>(
  Component: React.ComponentType<TProps>,
  getId: (props: TProps) => string
): React.FC<TProps> {
  return (props: TProps) => (
    <ConnectedSelectableField id={getId(props)}>
      <Component {...props} />
    </ConnectedSelectableField>
  );
}
function isStaffMemberGroup(
  item: IStaffWithPaidHours | SubcontractorItem | IUnallocatedGroup
): item is IStaffWithPaidHours {
  return (item as IStaffWithPaidHours).employmentStatus !== undefined;
}
function isUnallocatedGroup(
  item: IStaffWithPaidHours | SubcontractorItem | IUnallocatedGroup
): item is IUnallocatedGroup {
  return item.id === undefined;
}
const StaffGroup: React.FC<{ group: IStaffWithPaidHours | SubcontractorItem }> = ({ group }) => {
  if (isStaffMemberGroup(group)) {
    return (
      <div>
        <div>
          <Link to={`/people/staff-members/${group.id}`}>
            <strong>
              {group.employmentStatus.abbreviation
                ? `${group.name} (${group.employmentStatus.abbreviation})`
                : group.name}
            </strong>
          </Link>
        </div>
        <div>{group.paidHoursElement}</div>
      </div>
    );
  } else {
    return (
      <div>
        <div>
          <Link to={`/operations/subcontractors/${group.id}`}>
            <strong>{group.name}</strong>
          </Link>
        </div>
      </div>
    );
  }
};

const SelectableStaffGroup = addRadioField(StaffGroup, p => p.group.id);

function isAssetItem(item: AssetItem | SubcontractorItem | IUnallocatedGroup): item is AssetItem {
  return (item as AssetItem).registrationNumber !== undefined;
}

const VehicleGroup: React.FC<{ group: AssetItem | SubcontractorItem }> = ({ group }) => {
  if (isAssetItem(group)) {
    return (
      <div className="allocations-vehicle-group-component">
        <div>
          <Link to={`/workshop/assets/${group.id}`}>
            <strong>{group.name}</strong>
          </Link>
        </div>
        <div className="additional-info">
          <small>{group.vehicleTypeDescription}</small>
        </div>
      </div>
    );
  } else {
    return (
      <div className="allocations-vehicle-group-component">
        <div>
          <Link to={`/operations/subcontractors/${group.id}`}>
            <strong>{group.name}</strong>
          </Link>
        </div>
      </div>
    );
  }
};

const SelectableVehicleGroup = addRadioField(VehicleGroup, p => p.group.id);

interface IStaffWithPaidHours extends StaffMemberDto {
  paidHoursElement: ReactNode;
}

function getAllGroups<T extends IGanttCalendarGroupItem>(
  groups: T[],
  ungrouped?: 'includeUngroupedItems' | 'excludeUngroupedItems'
) {
  if (ungrouped !== 'excludeUngroupedItems') {
    // Include a group for ungrouped items at the beginning so that it shows first
    return [{ id: undefined } as T].concat(groups);
  }
  return groups;
}

export function getStaffView(
  staff: StaffMemberDto[],
  allStaffDepots: StaffDepotDto[],
  subcontractors: SubcontractorItem[],
  paidHours: PaidHoursInPayPeriod[],
  skillSpecs: SkillSpecItem[],
  employmentStatuses: EmploymentStatusDto[],
  roles: ListRoleItem[],
  allLicenceTypes: LicenceType[],
  ungrouped?: 'includeUngroupedItems' | 'excludeUngroupedItems',
  selectable?: 'unselectable' | 'selectable',
  defaultFilterRoles?: string[]
) {
  const drivers: Array<IStaffWithPaidHours | SubcontractorItem> = [
    ...staff.map(staffMember => {
      const staffMemberPaidHours = paidHours.find(item => item.staffMemberId === staffMember.id);
      let paidHoursElement: ReactNode;

      if (staffMemberPaidHours) {
        const parsedPaidHoursWeekDays = parseTimeSpan(staffMemberPaidHours.weekDayHours);
        const parsedPaidHoursPaidWeek = parseTimeSpan(staffMemberPaidHours.paidPeriodHours);

        const hasValidWeekDayHours =
          parsedPaidHoursWeekDays.isValid && parsedPaidHoursWeekDays.valueOf() !== 0;
        const hasValidPaidWeekHours =
          parsedPaidHoursPaidWeek.isValid && parsedPaidHoursPaidWeek.valueOf() !== 0;

        let weekdayHours: ReactNode = null;
        if (hasValidWeekDayHours) {
          weekdayHours = (
            <>
              Paid Hrs (Mon - Fri):{' '}
              <span title="Week Days">
                {formatDurationAsHoursAndMinutes(parsedPaidHoursWeekDays)}
              </span>
            </>
          );
        }

        let paidWeekHours: ReactNode = null;
        if (hasValidPaidWeekHours) {
          paidWeekHours = (
            <>
              {!hasValidWeekDayHours ? 'Paid Hrs: ' : ''}
              <span title="Paid Hours Week">
                {formatDurationAsHoursAndMinutes(parsedPaidHoursPaidWeek)}
              </span>
            </>
          );
        }

        paidHoursElement = (
          <small>
            {weekdayHours}
            {paidWeekHours}
          </small>
        );
      }
      return {
        ...staffMember,
        paidHoursElement,
      };
    }),
    ...subcontractors,
  ];

  const Group = selectable === 'selectable' ? SelectableStaffGroup : StaffGroup;
  const allClasses: Array<LicenceClass> = getLicenceClasses(allLicenceTypes);

  return {
    label: 'Staff Members',
    icon: <UserIcon />,
    groups: getAllGroups(drivers, ungrouped),
    getItemGroup: (item, groups) => {
      if (isOperationsJob(item)) {
        if (item.hasSubcontractor && item.subcontractor) {
          const subcontractor = groups
            .filter(g => !isStaffMemberGroup(g))
            .find(g => g.id === item.subcontractor.id);
          return [subcontractor];
        }
        const staffMember = groups.find(g => g.id === item.staffMemberId);
        return item.hasSecondStaffMember
          ? distinct([staffMember, groups.find(g => g.id === item.secondStaffMemberId)])
          : [staffMember];
      }
      if (isPeopleLeave(item) || isWorkshopShift(item)) {
        return [groups.find(g => g.id === item.staffMemberId)];
      }
      return [undefined];
    },
    renderGroup: g => (g && !isUnallocatedGroup(g) ? <Group group={g} /> : UnallocatedGroup),
    search: (s, groups) => {
      const searchRe = new RegExp(s, 'i');
      return groups.filter(g => isUnallocatedGroup(g) || (g.name && !!g.name.match(searchRe)));
    },
    filterItemsForView: i => isOperationsJob(i) || isPeopleLeave(i) || isWorkshopShift(i),
    defaultFilters: { roles: defaultFilterRoles },
    filterDef: () => [
      {
        panes: [
          {
            paneType: PaneType.formFieldsPane,
            columnCount: 2,
            fields: [
              {
                fieldType: FieldType.yesNoField,
                label: 'Driver Authorisation',
                dataAddr: 'hasDriverAuthorisation',
              },
              {
                fieldType: FieldType.yesNoField,
                label: 'Workshop Staff',
                dataAddr: 'isWorkshopStaff',
              },
            ],
          },
          {
            paneType: PaneType.formFieldsPane,
            fields: [
              {
                fieldType: FieldType.selectMultiField,
                label: 'Employment Status',
                dataAddr: 'employmentStatus',
                valueKey: 'id',
                descriptionKey: 'description',
                optionItems: employmentStatuses,
                useValueOnly: true,
              },
              {
                fieldType: FieldType.selectMultiField,
                label: 'Role',
                dataAddr: 'roles',
                valueKey: 'id',
                descriptionKey: 'name',
                optionItems: roles,
                useValueOnly: true,
              },
              {
                fieldType: FieldType.selectMultiField,
                label: 'Depot',
                dataAddr: 'depot',
                valueKey: 'id',
                descriptionKey: 'description',
                optionItems: allStaffDepots,
                useValueOnly: true,
              },
            ],
          },
          {
            paneType: PaneType.formFieldsPane,
            columnCount: 2,
            fields: [
              {
                fieldType: FieldType.selectField,
                label: 'Licence Type',
                dataAddr: 'licenceTypeId',
                optionItems: allLicenceTypes,
                valueKey: 'id',
                descriptionKey: 'description',
                useValueOnly: true,
                onChange: cApi => {
                  const parentPath = cApi.fieldDataAddr.slice(0, -1);
                  cApi.setFormValue([...parentPath, 'licenceClass'], undefined);
                  cApi.setFormValue([...parentPath, 'conditions'], []);
                },
              },
              {
                fieldType: FieldType.selectMultiField,
                label: 'Licence Class',
                dataAddr: 'licenceClassIds',
                optionItems: d =>
                  allClasses.filter(c => c.licenceTypeId === d.parentValue.licenceTypeId),
                valueKey: 'id',
                descriptionKey: 'description',
                useValueOnly: true,
                readonly: d =>
                  !allClasses.filter(c => c.licenceTypeId === d.parentValue.licenceTypeId).length,
              },
            ],
          },
        ],
      },
      {
        panes: [
          {
            paneType: PaneType.formFieldsPane,
            fields: [...getSkillSpecRequirementFieldDefs(skillSpecs)],
          },
        ],
      },
    ],
    applyFilters: (values, groups) => {
      const hasDriverAuthorisation = values.hasDriverAuthorisation;
      const isWorkshopStaff = values.isWorkshopStaff;
      const employmentStatus = (values.employmentStatus || []) as number[];
      const depot = (values.depot || []) as number[];
      const licenceTypeId = values.licenceTypeId;
      const licenceClassIds = (values.licenceClassIds || []) as number[];
      const allSkillSpecIds = consolidateSkillSpecRequirementsIds(
        // TODO: IFilterValues is woefully incorrect here
        // @ts-ignore
        values as ISplittedSkillSpecRequirements
      );
      const roleIds = (values.roles || []) as string[];

      return groups
        .filter(
          g =>
            isUnallocatedGroup(g) ||
            hasDriverAuthorisation === undefined ||
            (isStaffMemberGroup(g) && g.hasDriversAuthorisation === hasDriverAuthorisation)
        )
        .filter(
          g =>
            isUnallocatedGroup(g) ||
            isWorkshopStaff === undefined ||
            (isStaffMemberGroup(g) && g.isWorkshopStaff === isWorkshopStaff)
        )
        .filter(
          g =>
            isUnallocatedGroup(g) ||
            !employmentStatus.length ||
            (isStaffMemberGroup(g) && employmentStatus.indexOf(g.employmentStatus.id) >= 0)
        )
        .filter(
          g =>
            isUnallocatedGroup(g) ||
            !depot.length ||
            (isStaffMemberGroup(g) && depot.indexOf(g.depot.id) >= 0)
        )
        .filter(
          g =>
            isUnallocatedGroup(g) ||
            licenceTypeId === undefined ||
            (isStaffMemberGroup(g) &&
              g.licences &&
              g.licences.length &&
              g.licences.some(
                l =>
                  l.licenceTypeId === licenceTypeId &&
                  (!licenceClassIds.length ||
                    (l.licenceClass && licenceClassIds.indexOf(l.licenceClass.id) >= 0))
              ))
        )
        .filter(
          g =>
            isUnallocatedGroup(g) ||
            !roleIds.length ||
            (isStaffMemberGroup(g) &&
              g.roles &&
              g.roles.length &&
              g.roles.some(r => roleIds.indexOf(r.roleId) >= 0))
        )
        .filter(
          g =>
            isUnallocatedGroup(g) ||
            !allSkillSpecIds.length ||
            (isStaffMemberGroup(g) && doesMemberHaveRequiredSkillSpecs(g, allSkillSpecIds))
        );
    },
  } as IGanttView<AllBoundedItemTypes, IStaffWithPaidHours | SubcontractorItem | IUnallocatedGroup>;
}

export function getVehicleView(
  vehicles: AssetItem[],
  subcontractors: SubcontractorItem[],
  subcategories: AssetSubcategory[],
  assetHousingLocations: AssetHousingLocation[],
  techSpecs: TechSpecItem[],
  vehicleTypes: VehicleType[],
  searchTechSpecValues: SearchTechSpecValues,
  techSpecDropdownOptions: (techSpecId: number) => TechSpecDropdownOption[],
  ungrouped?: 'includeUngroupedItems' | 'excludeUngroupedItems',
  selectable?: 'unselectable' | 'selectable'
) {
  const Group = selectable === 'selectable' ? SelectableVehicleGroup : VehicleGroup;
  const splittedTechSpecs = splitTechSpecs(techSpecs);
  const vehiclesWithSubcontractors: Array<AssetItem | SubcontractorItem> = [
    ...vehicles,
    ...subcontractors,
  ];
  return {
    label: 'Vehicles',
    icon: <BusIcon />,
    groups: getAllGroups(vehiclesWithSubcontractors, ungrouped),
    getItemGroup: (item, groups) => {
      if (isOperationsJob(item) && item.hasSubcontractor && item.subcontractor) {
        const subcontractor = groups.find(g => g.id === item.subcontractor.id);
        return [subcontractor];
      }
      return isOperationsJob(item) || isWorkshopJob(item) || isMarkOutOfServiceItem(item)
        ? [groups.find(g => g.id === (item.asset && item.asset.id))]
        : [undefined];
    },
    renderGroup: g => (g && !isUnallocatedGroup(g) ? <Group group={g} /> : UnallocatedGroup),
    filterItemsForView: i =>
      (isOperationsJob(i) && !isJobTypeWithoutAsset(i.jobType.id)) ||
      isWorkshopJob(i) ||
      isMarkOutOfServiceItem(i),
    search: (s, groups) => {
      const searchRe = new RegExp(s, 'i');
      return groups.filter(g => isUnallocatedGroup(g) || (g.name && !!g.name.match(searchRe)));
    },
    filterDef: api => [
      {
        panes: [
          {
            paneType: PaneType.formFieldsPane,
            columnCount: 2,
            fields: [
              {
                fieldType: FieldType.selectMultiField,
                label: 'Asset Subcategory',
                dataAddr: 'subcategoryIds',
                valueKey: 'id',
                descriptionKey: 'description',
                useValueOnly: true,
                optionItems: subcategories,
              },
              {
                fieldType: FieldType.selectMultiField,
                label: 'Garaged At',
                dataAddr: 'housingLocationIds',
                useValueOnly: true,
                valueKey: 'id',
                descriptionKey: 'description',
                optionItems: assetHousingLocations,
              },
              {
                fieldType: FieldType.selectMultiField,
                label: 'Vehicle Type',
                dataAddr: 'vehicleTypeIds',
                valueKey: 'id',
                descriptionKey: 'description',
                useValueOnly: true,
                optionItems: vehicleTypes,
              },
            ],
          },
        ],
      },
      {
        panes: [
          {
            paneType: PaneType.formFieldsPane,
            columnCount: 2,
            fields: getTechSpecRequirementFieldDefs(
              splittedTechSpecs,
              searchTechSpecValues,
              techSpecDropdownOptions
            ),
          },
        ],
      },
    ],
    applyFilters: (values, groups) => {
      const subcats = (values.subcategoryIds || []) as number[];
      const housed = (values.housingLocationIds || []) as number[];
      const vehicleTypes = (values.vehicleTypeIds || []) as string[];
      const boolSpecValues = getBoolTechSpecValues(splittedTechSpecs.boolTechSpecs, values);
      const textSpecValues = getTextTechSpecValues(splittedTechSpecs.textTechSpecs, values);
      const dropdownSpecValues = getDropdownTechSpecValues(
        splittedTechSpecs.dropdownTechSpecs,
        values
      );

      return groups
        .filter(
          g =>
            isUnallocatedGroup(g) ||
            !subcats.length ||
            (isAssetItem(g) && subcats.indexOf(g.subcategoryId) >= 0)
        )
        .filter(
          g =>
            isUnallocatedGroup(g) ||
            !vehicleTypes.length ||
            (isAssetItem(g) &&
              g.vehicleTypeId !== undefined &&
              vehicleTypes.indexOf(g.vehicleTypeId) >= 0)
        )
        .filter(
          g =>
            isUnallocatedGroup(g) ||
            !housed.length ||
            (isAssetItem(g) && housed.indexOf(g.housingLocationId) >= 0)
        )
        .filter(
          g =>
            isUnallocatedGroup(g) ||
            !boolSpecValues.length ||
            (isAssetItem(g) && doesAssetHaveRequiredBoolTechSpecs(g, boolSpecValues))
        )
        .filter(
          g =>
            isUnallocatedGroup(g) ||
            !textSpecValues.length ||
            (isAssetItem(g) && doesAssetHaveRequiredTextTechSpecs(g, textSpecValues))
        )
        .filter(
          g =>
            isUnallocatedGroup(g) ||
            !dropdownSpecValues.length ||
            (isAssetItem(g) && doesAssetHaveRequiredDropdownTechSpecs(g, dropdownSpecValues))
        );
    },
  } as IGanttView<AllBoundedItemTypes, AssetItem | SubcontractorItem | IUnallocatedGroup>;
}
