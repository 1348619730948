import inject from 'src/views/injectFromStore';
import MaintainDefect, { IMaintainDefectProps } from './MaintainDefect';
import Omit from 'src/infrastructure/omit';

const Container = inject<Omit<IMaintainDefectProps, 'mode'>, IMaintainDefectProps>(
  (allStores, p) => ({
    canManageDefects:
      allStores.rootStore.account.isWorkshopDepartmentMember ||
      allStores.rootStore.account.isOperationsDepartmentMember,
    defect: allStores.rootStore.defect.defect,
    onLoadDefect: allStores.rootStore.defect.loadDefect,
    onCreateDefect: allStores.rootStore.defect.createDefect,
    onMarkOutOfServiceDefect: allStores.rootStore.defect.markOutOfServiceDefect,
    assets: allStores.rootStore.assets.assetListItems.slice(),
    loadAssetListItems: allStores.rootStore.assets.loadAssetListItems,
    defectsForAsset: allStores.rootStore.asset.defects.slice(),
    tasksForAsset: allStores.rootStore.assets.tasks.slice(),
    clearTasksForAsset: allStores.rootStore.assets.clearTasksForAsset,
    activityLogs: allStores.rootStore.defect.activityLogs.slice(),
    getDefectsForAsset: allStores.rootStore.asset.getDefectsForAsset,
    getActivityLogForDefect: allStores.rootStore.defect.getActivityLogForDefect,
    getTasksForAsset: allStores.rootStore.assets.getTasksForAsset,
    clearDefectsForAsset: allStores.rootStore.asset.clearDefectsForAsset,
    clearActivityLogForDefect: allStores.rootStore.defect.clearActivityLogForDefect,
    userDisplayName: allStores.rootStore.account.checkedName,
    tasks: allStores.rootStore.defect.tasks.slice(),
    clearTasksForDefect: allStores.rootStore.defect.clearTasksForDefect,
    getTasksForDefect: allStores.rootStore.defect.getTasksForDefect,
    setDefectClosedWithoutFixing: allStores.rootStore.defect.setDefectClosedWithoutBeingFixed,
    acknowledgeDefect: allStores.rootStore.defect.acknowledgeDefect,
  })
)(MaintainDefect);

export default Container;
