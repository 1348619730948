import styles from './AssetDetails.module.scss';
import TabHolder, { ITabProps } from '../../components/presentational/TabHolder/TabHolder';
import { useState, useEffect } from 'react';
import Spinner from 'src/views/components/Spinner';

import { AssetInfoBanner } from './AssetInfoBanner';
import { getTabProps } from './Tabs/getTabProps';
import { observer } from 'mobx-react';
import { useKioskRootStore } from 'src/domain/entities/KioskRootStoreModel';
import { DateTime } from 'luxon';

type JobDetailsForKioskDto = Workshop.Domain.Queries.Job.GetJobDetailsForKiosk.JobDetailsForKioskDto;

export interface IAssetDetailsProps {
  assetId: string;
  currentTaskId?: string;
  currentJob?: JobDetailsForKioskDto;
}

const AssetDetails: React.FC<IAssetDetailsProps> = observer(
  ({ assetId, currentJob, currentTaskId }) => {
    const rootStore = useKioskRootStore();
    const [isLoading, setIsLoading] = useState(true);
    const asset = rootStore.kiosk.asset.asset;
    const loadAsset = rootStore.kiosk.asset.loadAsset;
    const futureTasksForAsset = rootStore.kiosk.asset.jobsTasks.futureTasks.slice();
    const jobsForAsset = rootStore.kiosk.asset.jobsTasks.jobs.slice();
    const scheduledTasksForAsset = rootStore.kiosk.asset.jobsTasks.scheduledTasks.slice();
    const startedTasksForAsset = rootStore.kiosk.asset.jobsTasks.startedTasks.slice();
    const nextOpsJobsForAsset = rootStore.kiosk.asset.nextOpsJobsForAsset.slice();
    const loadFutureAndScheduledAndStartedTasks =
      rootStore.kiosk.asset.jobsTasks.loadFutureAndScheduledAndStartedTasks;
    const loadCompletedJobs = rootStore.kiosk.asset.jobsTasks.loadCompletedJobs;
    const loadNextOpsJobsForAsset = rootStore.kiosk.asset.loadNextOpsJobsForAsset;
    const loadPartsRegisterForAsset = rootStore.kiosk.asset.loadPartsRegisterForAsset;
    const loadComponentRegisterForAsset = rootStore.kiosk.asset.loadComponentRegisterForAsset;
    const loadLastOdometerReading = rootStore.kiosk.asset.loadLastOdometerReading;
    const parts = rootStore.kiosk.asset.parts.slice();
    const components = rootStore.kiosk.asset.components.slice();
    const lastOdometerReading = rootStore.kiosk.asset.lastOdometerReading;
    const addToCurrentJob =
      currentJob && DateTime.fromISO(currentJob.endDateTime) > DateTime.local()
        ? (jobTaskId: string) =>
            rootStore.kiosk.workshop.job.scheduleTaskForJob({
              jobId: currentJob!.jobId,
              jobTaskId,
              depotId: currentJob!.depotId,
            })
        : undefined;
    const loadPartsUsedForAsset = rootStore.kiosk.asset.loadPartsUsedForAsset;
    const partsUsed = rootStore.kiosk.asset.partsUsed.slice();
    const cubicSummary = rootStore.kiosk.asset.cubicRegister;
    const loadCubicRegister = rootStore.kiosk.asset.loadCubicRegisterForAsset;
    const techSpecsDropdownOptions = rootStore.kiosk.workshop.techSpecs.techSpecsDropdownOptions.slice();
    const loadTechSpecsDropdownOptions =
      rootStore.kiosk.workshop.techSpecs.loadTechSpecsDropdownOptions;
    const deviceDepotId = rootStore.kiosk.workshop.defaultKioskDepotId;
    const cubicItems = rootStore.kiosk.cubicRegister.cubicItems.slice();
    const loadCubicItems = rootStore.kiosk.cubicRegister.loadCubicItems;

    useEffect(() => {
      async function loadingSideEffects() {
        await Promise.all([loadAsset(assetId), loadNextOpsJobsForAsset(assetId)]).then(() =>
          setIsLoading(false)
        );
      }

      loadingSideEffects();
    }, [assetId]);

    const tabs: ITabProps[] = getTabProps(
      loadFutureAndScheduledAndStartedTasks,
      loadCompletedJobs,
      loadPartsRegisterForAsset,
      loadPartsUsedForAsset,
      loadComponentRegisterForAsset,
      loadLastOdometerReading,
      assetId,
      futureTasksForAsset,
      scheduledTasksForAsset,
      startedTasksForAsset,
      addToCurrentJob,
      currentTaskId,
      currentJob,
      jobsForAsset,
      asset,
      parts,
      partsUsed,
      components,
      cubicSummary,
      loadCubicRegister,
      techSpecsDropdownOptions,
      loadTechSpecsDropdownOptions,
      deviceDepotId,
      cubicItems,
      loadCubicItems,
      lastOdometerReading
    );

    return (
      <div className={styles.assetDetailsRoot}>
        <Spinner show={isLoading} className={styles.headerArea}>
          <AssetInfoBanner asset={asset} nextOpsJobsForAsset={nextOpsJobsForAsset} />
        </Spinner>
        <div className={styles.scrollableTabbedArea}>
          <TabHolder givenTabs={tabs} />
        </div>
      </div>
    );
  }
);

export default AssetDetails;
