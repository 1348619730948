import { cast, flow, types } from 'mobx-state-tree';
import { getAjax } from 'src/domain/services';
import { workshopUrls } from 'src/domain/services/apiUrls';

type AssetHousingLocation = Workshop.Domain.AggregatesModel.AssetAggregate.AssetHousingLocation;
type WorkshopDepotDto = Common.Queries.Workshop.GetWorkshopDepots.WorkshopDepotDto;

export const WorkshopStartUpModel = types
  .model('WorkshopStartUpModel', {
    workshopDepots: types.array(types.frozen<WorkshopDepotDto>()),
    housingLocations: types.array(types.frozen<AssetHousingLocation>()),
    defaultWorkshopDepot: types.maybe(types.frozen<WorkshopDepotDto>()),
  })
  .actions(self => {
    const ajax = getAjax(self);

    const loadWorkshopDepots = flow(function*() {
      // workshopDepots it's based on pseudo-constant data
      // so there is no need to refresh once it has been loaded
      if (!self.workshopDepots.length) {
        self.workshopDepots = yield ajax.raw
          .get(workshopUrls.depotUrls.getWorkshopDepots())
          .toPromise()
          .then<WorkshopDepotDto[]>(r => r.response);

        const allHousingLocations = self.workshopDepots.reduce(
          (acc: AssetHousingLocation[], d) =>
            acc.concat(d.housingLocations.map(hl => ({ ...hl } as AssetHousingLocation))),
          []
        );
        self.housingLocations = cast(allHousingLocations);
      }
    });

    const loadDefaultDepotForLoggedInUser = flow(function*() {
      self.defaultWorkshopDepot = yield ajax.raw
        .get(workshopUrls.depotUrls.getDefaultDepot())
        .toPromise()
        .then(r => r.response);
    });

    const init = flow(function*() {
      yield loadDefaultDepotForLoggedInUser();
      yield loadWorkshopDepots();
    });

    return {
      init,
    };
  });
