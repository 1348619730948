import { PlusIcon, ChevronUpIcon, ChevronDownIcon, TrashIcon } from 'src/images/icons';
import { IAutocompleteResult } from 'src/domain/baseTypes';
import {
  INestingPaneDef,
  PaneType,
  FieldType,
  ActionType,
  ShellModalSize,
  FieldDefs,
  IHasChangeState,
  IFieldData,
} from 'src/views/definitionBuilders/types';
import getAddBoardingPointModalDef from 'src/views/routes/operations/shared/getAddBoardingPointModalDef';
import { IFormApi } from 'src/views/components/Page/forms/base';
import { ChangeState, allShiftTranslinkDirection, ShiftTranslinkDirection } from 'src/api/enums';

type States = Operations.Domain.AggregatesModel.BoardingPointAggregate.States;
type BoardingPointListItem = Operations.Domain.Queries.SearchBoardingPoint.BoardingPointListItem;
type CreateBoardingPointCommand = Operations.Domain.Commands.BoardingPoint.CreateBoardingPointCommand;

function getLocationFormField(
  states: Array<States>,
  searchBoardingPoints: (search: string) => Promise<IAutocompleteResult<BoardingPointListItem>>,
  checkForUniqueBoardingPointName: (name: string) => Promise<Common.Dtos.UniqueNameCheckResultDto>,
  onCreateBoardingPoint: (command: CreateBoardingPointCommand) => Promise<string>,
  updating: boolean
) {
  const fields = [
    {
      fieldType: FieldType.selectAsyncField,
      dataAddr: 'location',
      label: 'Location',
      valueKey: 'name',
      minColumnWidth: '12rem',
      descriptionKey: 'name',
      loadOptionItems: searchBoardingPoints,
      mandatory: true,
      readonly: !updating,
      useOptionRendererAsValueRenderer: true,
      optionRenderer: (d: BoardingPointListItem) => {
        return <span title={d.name}>{d.name}</span>;
      },
    },
    {
      fieldType: FieldType.actionListField,
      columnWidth: '1px',
      hidden: !onCreateBoardingPoint || !checkForUniqueBoardingPointName || !updating,
      actionGroups: [
        {
          actions: [
            {
              actionType: ActionType.modalActionButton,
              label: 'Add Location',
              icon: <PlusIcon />,
              modalSize: ShellModalSize.oneThird,
              modalDef: getAddBoardingPointModalDef(
                states,
                checkForUniqueBoardingPointName,
                onCreateBoardingPoint
              ),
            },
          ],
        },
      ],
    },
  ];
  return fields;
}

function getRunFieldDefs(formApi: IFormApi, updating: boolean) {
  const runCount = (formApi && formApi.values && formApi.values.runCount) || 1;
  const runs = [];
  for (let i = 0; i < runCount; i++) {
    runs.push({
      fieldType: FieldType.timeField,
      dataAddr: ['depart', i],
      columnWidth: '5em',
      minColumnWidth: '4rem',
      readonly: !updating,
      // tslint:disable-next-line: no-any
      label: (d: any) => {
        // tslint:disable-next-line: no-any
        const currentRoutes = d.paneValue as Array<any>;
        // tslint:disable-next-line: no-any
        const routeGroups = (d.panelValue && d.panelValue.routeGroups) as Array<any> | undefined;
        if (!currentRoutes || !routeGroups) {
          return '';
        }

        const routeGroupIndex = routeGroups.findIndex(t => t.routes === currentRoutes);
        if (routeGroupIndex < 0) {
          return '';
        }

        return (i * routeGroups.length + 1 + routeGroupIndex).toString();
      },
    });
  }
  return runs;
}

function getActionFields(updating: boolean) {
  return {
    fieldType: FieldType.actionListField,
    columnWidth: 'auto',
    actionGroups: [
      {
        actions: [
          {
            actionType: ActionType.moveArrayItemActionButton,
            label: 'Move up',
            icon: <ChevronUpIcon />,
            moveDirection: 'prev',
            // tslint:disable-next-line:no-any
            hidden: (d: any) => {
              return (d.paneValue as Array<{}>).indexOf(d.parentValue) === 0 || !updating;
            },
          },
          {
            actionType: ActionType.moveArrayItemActionButton,
            label: 'Move down',
            icon: <ChevronDownIcon />,
            moveDirection: 'next',
            // tslint:disable-next-line:no-any
            hidden: (d: any) => {
              return (
                (d.paneValue as Array<{}>).indexOf(d.parentValue) ===
                  (d.paneValue as Array<{}>).length - 1 || !updating
              );
            },
          },
          {
            actionType: ActionType.removeArrayItemActionButton,
            hidden: !updating,
            label: 'Remove Route',
          },
        ],
      },
    ],
  };
}

function getFormFields(
  states: Array<States>,
  searchBoardingPoints: (search: string) => Promise<IAutocompleteResult<BoardingPointListItem>>,
  checkForUniqueBoardingPointName: (name: string) => Promise<Common.Dtos.UniqueNameCheckResultDto>,
  onCreateBoardingPoint: (command: CreateBoardingPointCommand) => Promise<string>,
  formApi: IFormApi,
  updating: boolean
) {
  return [
    ...getLocationFormField(
      states,
      searchBoardingPoints,
      checkForUniqueBoardingPointName,
      onCreateBoardingPoint,
      updating
    ),
    ...getRunFieldDefs(formApi, updating),
    getActionFields(updating),
  ] as FieldDefs[];
}

export interface IEditableOptions {
  formApi: IFormApi;
  states: Array<States>;
  searchBoardingPoints: (search: string) => Promise<IAutocompleteResult<BoardingPointListItem>>;
  checkForUniqueBoardingPointName: (name: string) => Promise<Common.Dtos.UniqueNameCheckResultDto>;
  onCreateBoardingPoint: (command: CreateBoardingPointCommand) => Promise<string>;
  depots: Common.Dtos.OperationsDepotDto[];
  updating: boolean;
}

export default function getShiftRoutesPaneDef(
  routesDataAddr: string,
  options: IEditableOptions
): INestingPaneDef {
  return {
    paneType: PaneType.nestingPane,
    enableHorizontalScroll: true,
    dataAddr: routesDataAddr,
    panes: [
      {
        paneType: PaneType.repeatingPane,
        enableHorizontalScroll: true,
        useHover: false,
        itemPanes: [
          {
            paneType: PaneType.formFieldsPane,
            columnCount: 3,
            fields: [
              {
                fieldType: FieldType.textField,
                dataAddr: 'name',
                label: 'Route Name',
                readonly: !options.updating,
                maxLength: 200,
              },
              {
                fieldType: FieldType.textField,
                dataAddr: 'desto',
                label: 'Desto',
                readonly: !options.updating,
                maxLength: 20,
              },
              {
                fieldType: FieldType.selectField,
                dataAddr: 'translinkDirection',
                label: 'Direction of Travel',
                optionItems: allShiftTranslinkDirection,
                descriptionKey: 'description',
                valueKey: 'value',
                useValueOnly: true,
                mandatory: true,
                validate: (d: IFieldData<number>) => {
                  const value = d.fieldValue;
                  return value === ShiftTranslinkDirection.Unknown
                    ? 'Unknown is not allowed'
                    : undefined;
                },
              },
            ],
          },
          getShiftRoutePaneDef(options),
        ],
      },
      {
        paneType: PaneType.actionListPane,
        hidden: d => !options.updating,
        actionGroups: [
          {
            actions: [
              {
                actionType: ActionType.addArrayItemActionButton,
                label: 'Add Shift Detail',
                newItemData: { routes: [] },
              },
              {
                actionType: ActionType.actionButton,
                label: 'Add First Run',
                icon: <PlusIcon />,
                hidden: d => {
                  return !d.parentValue.routeGroups || d.parentValue.routeGroups.length === 0;
                },
                onClick: d => {
                  const data = options.formApi.getValue();
                  const updatedData = {
                    ...data,
                    // tslint:disable-next-line:no-any
                    routeGroups: data.routeGroups.map((x: any) => {
                      return {
                        ...x,
                        // tslint:disable-next-line:no-any
                        routes: x.routes.map((y: any) => {
                          return {
                            ...y,
                            depart: [undefined, ...y.depart],
                          };
                        }),
                      };
                    }),
                    runCount: (data.runCount || 0) + 1,
                  };
                  options.formApi.setAllValues(updatedData);
                },
              },
              {
                actionType: ActionType.actionButton,
                label: 'Add Last Run',
                icon: <PlusIcon />,
                hidden: d => {
                  return !d.parentValue.routeGroups || d.parentValue.routeGroups.length === 0;
                },
                onClick: d => {
                  const data = options.formApi.getValue();
                  const updatedData = {
                    ...data,
                    runCount: (data.runCount || 0) + 1,
                  };
                  options.formApi.setAllValues(updatedData);
                },
              },
              {
                actionType: ActionType.actionButton,
                label: 'Remove first Run',
                icon: <TrashIcon />,
                hidden: d => {
                  return !d.parentValue.routeGroups || d.parentValue.routeGroups.length === 0;
                },
                onClick: d => {
                  const data = options.formApi.getValue();
                  const updatedData = {
                    ...data,
                    // tslint:disable-next-line:no-any
                    routeGroups: data.routeGroups.map((x: any) => {
                      return {
                        ...x,
                        // tslint:disable-next-line:no-any
                        routes: x.routes.map((y: any) => {
                          return {
                            ...y,
                            depart: y.depart.slice(1),
                          };
                        }),
                      };
                    }),
                    runCount: data.runCount - 1 || 1,
                  };
                  options.formApi.setAllValues(updatedData);
                },
              },
              {
                actionType: ActionType.actionButton,
                label: 'Remove Last Run',
                icon: <TrashIcon />,
                hidden: d => {
                  return !d.parentValue.routeGroups || d.parentValue.routeGroups.length === 0;
                },
                onClick: d => {
                  const data = options.formApi.getValue();
                  const updatedData = {
                    ...data,
                    runCount: data.runCount - 1 || 1,
                  };
                  options.formApi.setAllValues(updatedData);
                },
              },
            ],
          },
        ],
      },
    ],
  };
}

function getShiftRoutePaneDef(options: IEditableOptions): INestingPaneDef {
  const states = options.states;
  const searchBoardingPoints = options.searchBoardingPoints;
  const checkForUniqueBoardingPointName = options.checkForUniqueBoardingPointName;
  const onCreateBoardingPoint = options.onCreateBoardingPoint;
  const formApi = options.formApi;
  const updating = options.updating;

  const reverseDirection = (currentDirection: ShiftTranslinkDirection): ShiftTranslinkDirection => {
    switch (currentDirection) {
      case ShiftTranslinkDirection.AsRequired:
      case ShiftTranslinkDirection.Unknown:
        return currentDirection;
      case ShiftTranslinkDirection.Inbound:
        return ShiftTranslinkDirection.Outbound;
      case ShiftTranslinkDirection.Outbound:
        return ShiftTranslinkDirection.Inbound;
      default:
        return ShiftTranslinkDirection.Unknown;
    }
  };

  return {
    paneType: PaneType.nestingPane,
    enableHorizontalScroll: true,
    dataAddr: 'routes',
    panes: [
      {
        paneType: PaneType.tablePane,
        // tslint:disable-next-line:no-any
        validate: (d: any) => {
          const routes = ((d.paneValue as Array<IHasChangeState>) || []).filter(
            r => r.changeState !== ChangeState.Deleted
          );
          return routes.length === 0 ? 'A shift detail must have at least one route' : undefined;
        },
        dataRequiredForRows: 'paneValue',
        fields: getFormFields(
          states,
          searchBoardingPoints,
          checkForUniqueBoardingPointName,
          onCreateBoardingPoint,
          formApi,
          updating
        ),
      },
      {
        paneType: PaneType.actionListPane,
        hidden: d => !options.updating,
        actionGroups: [
          {
            actions: [
              {
                actionType: ActionType.addArrayItemActionButton,
                label: 'Add Route',
              },
              {
                actionType: ActionType.removeArrayItemActionButton,
                label: 'Remove Shift Detail',
              },
              {
                actionType: ActionType.actionButton,
                label: 'Duplicate and Reverse Routes',
                onClick: d => {
                  const data = options.formApi.getValue();
                  const routeDetails = {
                    ...d.parentValue,
                    translinkDirection: reverseDirection(d.parentValue.translinkDirection),
                  };
                  const updatedData = {
                    ...data,
                    routeGroups: [
                      ...data.routeGroups,
                      {
                        ...routeDetails,
                        routes: [...d.parentValue.routes]
                          .map(r => {
                            return {
                              ...r,
                              depart: [],
                            };
                          })
                          .reverse(),
                      },
                    ],
                  };
                  options.formApi.setAllValues(updatedData);
                },
              },
              {
                actionType: ActionType.moveArrayItemActionButton,
                label: 'Move Up',
                moveDirection: 'prev',
                icon: <ChevronUpIcon />,
                hidden: d => {
                  return (d.panelValue.routeGroups as Array<{}>).indexOf(d.parentValue) === 0;
                },
              },
              {
                actionType: ActionType.moveArrayItemActionButton,
                label: 'Move Down',
                moveDirection: 'next',
                icon: <ChevronDownIcon />,
                hidden: d => {
                  return (
                    (d.panelValue.routeGroups as Array<{}>).indexOf(d.parentValue) ===
                    (d.panelValue.routeGroups as Array<{}>).length - 1
                  );
                },
              },
            ],
          },
        ],
      },
    ],
  };
}
