import { types, flow, getRoot } from 'mobx-state-tree';
import { getAjax, NotificationType } from 'src/domain/services';
import { IRootStoreModel } from 'src/domain/entities/RootStoreModel';

type AssetCubicItem = Workshop.Domain.Queries.AssetCubic.AssetCubicItem;
type CubicFaultDetail = Workshop.Domain.AggregatesModel.AssetAggregate.CubicFaultDetail;
type CubicItem = Workshop.Domain.AggregatesModel.AssetAggregate.CubicItem;
type AssetCubicSummary = Workshop.Domain.Queries.AssetCubic.AssetCubicSummary;

export const AssetCubicRegisterModel = types
  .model('AssetCubicRegisterModel', {
    assetCubicItems: types.array(types.frozen<AssetCubicItem>()),
    assetCubicSummary: types.maybe(types.frozen<AssetCubicSummary>()),
    cubicFaultDetails: types.array(types.frozen<CubicFaultDetail>()),
    cubicItems: types.array(types.frozen<CubicItem>()),
  })
  .views(self => ({
    get activeCubicItems() {
      return self.cubicItems.filter(c => c.isActive);
    },

    get activeCubicFaultDetails() {
      return self.cubicFaultDetails.filter(c => c.isActive);
    },
  }))
  .actions(self => {
    const ajax = getAjax(self);
    const root = getRoot(self) as IRootStoreModel;

    const loadAssetCubicItems = flow(function*(assetId: string) {
      const items: Common.Dtos.ListResult<AssetCubicItem> = yield ajax.workshop.assetCubicRegister.list(
        assetId
      );
      if (items) {
        self.assetCubicItems.replace(items.items);
      }
    });

    const loadAssetCubicSummary = flow(function*(assetId: string) {
      const summary: AssetCubicSummary = yield ajax.workshop.assetCubicRegister.summarise(assetId);
      self.assetCubicSummary = summary;
    });

    const loadCubicFaultDetails = flow(function*() {
      self.cubicFaultDetails = yield ajax.workshop.assetCubicRegister.loadCubicFaultDetails();
    });

    const loadCubicItems = flow(function*() {
      self.cubicItems = yield ajax.workshop.assetCubicRegister.loadCubicItemDetails();
    });

    const createAssetCubicItem = flow(function*(
      command: Workshop.Domain.Commands.AssetCubic.CreateAssetCubicCommand
    ) {
      yield ajax.workshop.assetCubicRegister.createAssetCubic(command);
      yield loadAssetCubicItems(command.assetId);
      yield loadAssetCubicSummary(command.assetId);
    });

    const updateAssetCubicItem = flow(function*(
      command: Workshop.Domain.Commands.AssetCubic.MarkPendingCubicChangesForAssetAsSentCommand
    ) {
      yield ajax.workshop.assetCubicRegister.updateAssetCubic(command);
      yield loadAssetCubicItems(command.assetId);
      yield loadAssetCubicSummary(command.assetId);
    });
    const generateExcel: (assetId: string) => Promise<Blob> = flow(function*(assetId: string) {
      root.notifications.addNotification(`The file is being generated ...`, {
        type: NotificationType.info,
      });

      return yield ajax.workshop.assetCubicRegister.generateExcel(assetId);
    });
    const generatePdfForm = flow(function*(assetId: string, assetCubicId: number) {
      root.notifications.addNotification(`The file is being generated ...`, {
        type: NotificationType.info,
      });

      return yield ajax.workshop.assetCubicRegister.generatePdfForm(assetId, assetCubicId);
    });
    const generateExcelForAllAssets: () => Promise<Blob> = flow(function*() {
      root.notifications.addNotification(`The file is being generated ...`, {
        type: NotificationType.info,
      });

      return yield ajax.workshop.assetCubicRegister.generateExcelForAllAssets();
    });

    return {
      loadAssetCubicItems,
      loadAssetCubicSummary,
      createAssetCubicItem,
      updateAssetCubicItem,
      generateExcel,
      generateExcelForAllAssets,
      loadCubicFaultDetails,
      generatePdfForm,
      loadCubicItems,
    };
  });
