import inject from 'src/views/injectFromStore';
import MaintainAssetGroup, { IMaintainAssetGroupProps } from './MaintainAssetGroup';
import Omit from 'src/infrastructure/omit';

const Container = inject<Omit<IMaintainAssetGroupProps, 'mode'>, IMaintainAssetGroupProps>(
  (allStores, p) => ({
    canManageAssetGroups: allStores.rootStore.account.isWorkshopDepartmentMember,
    onLoadAssetGroup: allStores.rootStore.assetGroup.loadAssetGroup,
    assetGroup: allStores.rootStore.assetGroup.assetGroup,
    serviceTypes: allStores.rootStore.assetGroup.serviceTypes.slice(),
    checklists: allStores.rootStore.assetGroup.checklists.slice(),
    onCreateAssetGroup: allStores.rootStore.assetGroup.createAssetGroup,
    onUpdateAssetGroup: allStores.rootStore.assetGroup.updateAssetGroup,
    onCheckForUniqueName: allStores.rootStore.assetGroup.checkForUniqueName,
    onLoadServiceTypes: allStores.rootStore.assetGroup.loadServiceTypes,
    onLoadChecklists: allStores.rootStore.assetGroup.loadChecklists,
    fullServiceCycle: allStores.rootStore.assetGroup.fullServiceCycle.slice(),
    loadAssetGroupFullServiceCycle: allStores.rootStore.assetGroup.loadAssetGroupFullServiceCycle,
    getAssetGroupUsage: allStores.rootStore.assetGroup.getAssetGroupUsage,
    assetGroupUsage: allStores.rootStore.assetGroup.assetGroupUsage,
    setAssetGroupInactive: allStores.rootStore.assetGroup.setAssetGroupInactive,
    setAssetGroupActive: allStores.rootStore.assetGroup.setAssetGroupActive,
    deleteAssetGroup: allStores.rootStore.assetGroup.deleteAssetGroup,
  })
)(MaintainAssetGroup);

export default Container;
