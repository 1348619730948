import { Minus } from 'src/infrastructure/minusTypeGenerator';
import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import {
  faAddressCard,
  faEnvelope,
  faHourglass,
  faIdCard,
  faTired,
  faLightbulb,
  faCircleQuestion,
} from '@fortawesome/free-regular-svg-icons';
import { faCalendarAlt as faCalendar } from '@fortawesome/free-regular-svg-icons/faCalendarAlt';
import { faCalendarPlus } from '@fortawesome/free-regular-svg-icons/faCalendarPlus';
import { faCalendarTimes } from '@fortawesome/free-regular-svg-icons/faCalendarTimes';
import { faClipboard } from '@fortawesome/free-regular-svg-icons/faClipboard';
import { faCopy } from '@fortawesome/free-regular-svg-icons/faCopy';
import { faObjectGroup } from '@fortawesome/free-regular-svg-icons/faObjectGroup';
import {
  faArrowDown,
  faFileCsv,
  faHistory,
  faHourglass as faHourglassSolid,
  faHourglassEnd,
  faHourglassHalf,
  faHourglassStart,
  faKey,
  faLayerGroup,
  faPauseCircle,
  faPlay,
  faRetweet,
  faSms,
  faTabletAlt,
  faThermometer,
  faClipboardCheck,
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faRefresh,
  faShirt,
} from '@fortawesome/free-solid-svg-icons';
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons/faAngleLeft';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons/faAngleRight';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons/faArrowLeft';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons/faArrowRight';
import { faBan } from '@fortawesome/free-solid-svg-icons/faBan';
import { faBars } from '@fortawesome/free-solid-svg-icons/faBars';
import { faBook } from '@fortawesome/free-solid-svg-icons/faBook';
import { faBed } from '@fortawesome/free-solid-svg-icons/faBed';
import { faBoxOpen } from '@fortawesome/free-solid-svg-icons/faBoxOpen';
import { faBus } from '@fortawesome/free-solid-svg-icons/faBus';
import { faCar } from '@fortawesome/free-solid-svg-icons/faCar';
import { faChalkboardTeacher } from '@fortawesome/free-solid-svg-icons/faChalkboardTeacher';
import { faCheck } from '@fortawesome/free-solid-svg-icons/faCheck';
import { faCheckSquare } from '@fortawesome/free-solid-svg-icons/faCheckSquare';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons/faChevronDown';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons/faChevronLeft';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons/faChevronRight';
import { faChevronUp } from '@fortawesome/free-solid-svg-icons/faChevronUp';
import { faClock } from '@fortawesome/free-solid-svg-icons/faClock';
import { faCog } from '@fortawesome/free-solid-svg-icons/faCog';
import { faCut } from '@fortawesome/free-solid-svg-icons/faCut';
import { faDownload } from '@fortawesome/free-solid-svg-icons/faDownload';
import { faEdit } from '@fortawesome/free-solid-svg-icons/faEdit';
import { faEllipsisH } from '@fortawesome/free-solid-svg-icons/faEllipsisH';
import { faEquals } from '@fortawesome/free-solid-svg-icons/faEquals';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons/faExclamationCircle';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons/faExclamationTriangle';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons/faExternalLinkAlt';
import { faFileAlt as faFile } from '@fortawesome/free-solid-svg-icons/faFileAlt';
import { faFileCode } from '@fortawesome/free-solid-svg-icons/faFileCode';
import { faFileExcel } from '@fortawesome/free-solid-svg-icons/faFileExcel';
import { faFilePdf } from '@fortawesome/free-solid-svg-icons/faFilePdf';
import { faFilter } from '@fortawesome/free-solid-svg-icons/faFilter';
import { faFlagCheckered } from '@fortawesome/free-solid-svg-icons/faFlagCheckered';
import { faHandHoldingUsd } from '@fortawesome/free-solid-svg-icons/faHandHoldingUsd';
import { faHome } from '@fortawesome/free-solid-svg-icons/faHome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons/faInfoCircle';
import { faLink } from '@fortawesome/free-solid-svg-icons/faLink';
import { faList } from '@fortawesome/free-solid-svg-icons/faList';
import { faMinus } from '@fortawesome/free-solid-svg-icons/faMinus';
import { faMoneyCheckAlt } from '@fortawesome/free-solid-svg-icons/faMoneyCheckAlt';
import { faNotEqual } from '@fortawesome/free-solid-svg-icons/faNotEqual';
import { faPaperclip } from '@fortawesome/free-solid-svg-icons/faPaperclip';
import { faPlane } from '@fortawesome/free-solid-svg-icons/faPlane';
import { faPlus } from '@fortawesome/free-solid-svg-icons/faPlus';
import { faPrint } from '@fortawesome/free-solid-svg-icons/faPrint';
import { faSearch } from '@fortawesome/free-solid-svg-icons/faSearch';
import { faShoppingCart as faPurchase } from '@fortawesome/free-solid-svg-icons/faShoppingCart';
import { faSignInAlt } from '@fortawesome/free-solid-svg-icons/faSignInAlt';
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons/faSignOutAlt';
import { faSpinner } from '@fortawesome/free-solid-svg-icons/faSpinner';
import { faStream } from '@fortawesome/free-solid-svg-icons/faStream';
import { faSync } from '@fortawesome/free-solid-svg-icons/faSync';
import { faTasks } from '@fortawesome/free-solid-svg-icons/faTasks';
import { faTh } from '@fortawesome/free-solid-svg-icons/faTh';
import { faTimes } from '@fortawesome/free-solid-svg-icons/faTimes';
import { faTools } from '@fortawesome/free-solid-svg-icons/faTools';
import { faTrain } from '@fortawesome/free-solid-svg-icons/faTrain';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons/faTrashAlt';
import { faUmbrellaBeach } from '@fortawesome/free-solid-svg-icons/faUmbrellaBeach';
import { faUndo } from '@fortawesome/free-solid-svg-icons/faUndo';
import { faUnlink } from '@fortawesome/free-solid-svg-icons/faUnlink';
import { faUser } from '@fortawesome/free-solid-svg-icons/faUser';
import { faVideo } from '@fortawesome/free-solid-svg-icons/faVideo';
import { faWarehouse } from '@fortawesome/free-solid-svg-icons/faWarehouse';
import { faWrench } from '@fortawesome/free-solid-svg-icons/faWrench';
import { faDollarSign } from '@fortawesome/free-solid-svg-icons/faDollarSign';
import { faUserGroup } from '@fortawesome/free-solid-svg-icons/faUserGroup';
import { faCalculator } from '@fortawesome/free-solid-svg-icons/faCalculator';
import { faTerminal } from '@fortawesome/free-solid-svg-icons/faTerminal';
import { FontAwesomeIcon, Props } from '@fortawesome/react-fontawesome';

export type IIconProps = Minus<Props, { icon: void }>;

function buildIcon(icon: IconDefinition, defaultProps?: Partial<Props>): React.FC<IIconProps> {
  return props => <FontAwesomeIcon {...defaultProps} {...props} icon={icon} />;
}

export const BarsIcon = buildIcon(faBars);
export const BedIcon = buildIcon(faBed);
export const ChevronUpIcon = buildIcon(faChevronUp);
export const ChevronDownIcon = buildIcon(faChevronDown);
export const ChevronLeftIcon = buildIcon(faChevronLeft);
export const ChevronRightIcon = buildIcon(faChevronRight);
export const ArrowRightIcon = buildIcon(faArrowRight);
export const ArrowLeftIcon = buildIcon(faArrowLeft);
export const ArrowDownIcon = buildIcon(faArrowDown);
export const HomeIcon = buildIcon(faHome);
export const ClipboardIcon = buildIcon(faClipboard);
export const CogIcon = buildIcon(faCog);
export const MoreHorizontalIcon = buildIcon(faEllipsisH);
export const WrenchIcon = buildIcon(faWrench);
export const ToolsIcon = buildIcon(faTools);
export const ListIcon = buildIcon(faList);
export const StreamIcon = buildIcon(faStream);
export const ObjectGroupIcon = buildIcon(faObjectGroup);
export const FlagCheckered = buildIcon(faFlagCheckered);
export const Tasks = buildIcon(faTasks);
export const ShirtIcon = buildIcon(faShirt);

export const SuccessIcon = buildIcon(faCheckSquare);
export const InfoIcon = buildIcon(faInfoCircle);
export const WarnIcon = buildIcon(faExclamationTriangle);
export const ErrorIcon = buildIcon(faExclamationCircle);

export const CalendarIcon = buildIcon(faCalendar);
export const SignOutIcon = buildIcon(faSignOutAlt);
export const SignInIcon = buildIcon(faSignInAlt);
export const WarehouseIcon = buildIcon(faWarehouse);
export const SpinnerIcon = buildIcon(faSpinner, { spin: true });
export const PlusIcon = buildIcon(faPlus);
export const MinusIcon = buildIcon(faMinus);
export const EditIcon = buildIcon(faEdit);
export const EqualsIcon = buildIcon(faEquals);
export const NotEqualsIcon = buildIcon(faNotEqual);
export const SearchIcon = buildIcon(faSearch);
export const PaperclipIcon = buildIcon(faPaperclip);
export const TrashIcon = buildIcon(faTrashAlt);
export const AngleRight = buildIcon(faAngleRight);
export const AngleLeft = buildIcon(faAngleLeft);
export const PrintIcon = buildIcon(faPrint);
export const ExcelIcon = buildIcon(faFileExcel);
export const CsvIcon = buildIcon(faFileCsv);
export const UndoIcon = buildIcon(faUndo);
export const UserIcon = buildIcon(faUser);
export const FileIcon = buildIcon(faFile);
export const FilePdfIcon = buildIcon(faFilePdf);
export const FilterIcon = buildIcon(faFilter);
export const TimesIcon = buildIcon(faTimes);
export const LinkIcon = buildIcon(faLink);
export const UnlinkIcon = buildIcon(faUnlink);
export const CopyIcon = buildIcon(faCopy);
export const BanIcon = buildIcon(faBan);
export const CheckIcon = buildIcon(faCheck);
export const PlaneIcon = buildIcon(faPlane);
export const PurchaseIcon = buildIcon(faPurchase);
export const ClockIcon = buildIcon(faClock);
export const BusIcon = buildIcon(faBus);
export const CarIcon = buildIcon(faCar);
export const OpenBoxIcon = buildIcon(faBoxOpen);
export const MultipleBoxesIcon = buildIcon(faTh);
export const TrainIcon = buildIcon(faTrain);
export const CreditNoteIcon = buildIcon(faMoneyCheckAlt);
export const CutIcon = buildIcon(faCut);
export const BookIcon = buildIcon(faBook);
export const TeacherIcon = buildIcon(faChalkboardTeacher);
export const VideoIcon = buildIcon(faVideo);
export const HandHoldingDollarIcon = buildIcon(faHandHoldingUsd);
export const UmbrellaBeach = buildIcon(faUmbrellaBeach);
export const SyncIcon = buildIcon(faSync);
export const RetweetIcon = buildIcon(faRetweet);
export const EmailIcon = buildIcon(faEnvelope);
export const SmsIcon = buildIcon(faSms);
export const DeviceIcon = buildIcon(faTabletAlt);
export const KeyIcon = buildIcon(faKey);
export const CalendarPlusIcon = buildIcon(faCalendarPlus);
export const CalendarCancelIcon = buildIcon(faCalendarTimes);
export const PauseIcon = buildIcon(faPauseCircle);
export const HourglassEmptyIcon = buildIcon(faHourglass);
export const HourglassFullIcon = buildIcon(faHourglassSolid);
export const HourglassHalfIcon = buildIcon(faHourglassHalf);
export const HourglassStartIcon = buildIcon(faHourglassStart);
export const HourglassEndIcon = buildIcon(faHourglassEnd);
export const IdCardIcon = buildIcon(faIdCard);
export const HistoryIcon = buildIcon(faHistory);
export const AddressCardIcon = buildIcon(faAddressCard);
export const DownloadIcon = buildIcon(faDownload);
export const JsonIcon = buildIcon(faFileCode);
export const ExternalLinkIcon = buildIcon(faExternalLinkAlt);
export const PlayIcon = buildIcon(faPlay);
export const PeopleGroupIcon = buildIcon(faLayerGroup);
export const ThermometerIcon = buildIcon(faThermometer);
export const TiredIcon = buildIcon(faTired);
export const VerifyIcon = buildIcon(faClipboardCheck);
export const ChevronDoubleLeft = buildIcon(faAngleDoubleLeft);
export const ChevronDoubleRight = buildIcon(faAngleDoubleRight);
export const DollarSignIcon = buildIcon(faDollarSign);
export const CalculatorIcon = buildIcon(faCalculator);
export const ClipboardCheckIcon = buildIcon(faClipboardCheck);
export const UsersIcon = buildIcon(faUserGroup);
export const CodeIcon = buildIcon(faTerminal);
export const LightBulbIcon = buildIcon(faLightbulb);
export const CircleQuestionIcon = buildIcon(faCircleQuestion);
export const RefreshIcon = buildIcon(faRefresh);
