import memoizeOne, { getShallowMemoizedObjectFunc } from 'src/infrastructure/memoizeOne';
import { IFormApi } from 'src/views/components/Page/forms/base';
import { Field, withFormApi, get } from 'src/views/components/Page/forms';
import {
  FieldDefs,
  IFieldMeta,
  isCustomFieldDef,
  isActionListFieldDef,
  isReadonlyFieldDef,
  IFieldData,
  isTextFieldDef,
  isSelectFieldDef,
  isSelectCreatableFieldDef,
  IPaneData,
  isNumericFieldDef,
  isDateTimeFieldDef,
  IFieldFunctionsDef,
  isYesNoFieldDef,
  isToggleButtonFieldDef,
  isDateFieldDef,
  isTimeFieldDef,
  isSelectAsyncFieldDef,
  isSelectCreatableAsyncFieldDef,
  isTextAreaFieldDef,
  isSelectMultiFieldDef,
  isToggleMultiButtonFieldDef,
  isErrorFieldDef,
  isDaysOfWeekFieldDef,
  isSelectAsyncMultiFieldDef,
  isStaffMemberFieldDef,
  isAssetFieldDef,
  isDurationFieldDef,
  isWeekSelectFieldDef,
  isFileFieldDef,
  isMultiFileFieldDef,
  IMultiFileFieldDef,
} from 'src/views/definitionBuilders/types';
import CustomPageField from './CustomPageField';
import ActionListPageField from './ActionListPageField';
import ReadonlyPageField from './ReadonlyPageField';
import TextPageField from './TextPageField';
import SelectPageField from './SelectPageField';
import SelectCreatablePageField from './SelectCreatablePageField';
import NumericPageField from './NumericPageField';
import DatetimePageField from './DateTimePageField';
import YesNoPageField from './YesNoPageField';
import ToggleButtonPageField from './ToggleButtonPageField';
import DatePageField from './DatePageField';
import deepEqual from 'src/infrastructure/deepEqual';
import { Subscription } from 'rxjs/Subscription';
import TimePageField from './TimePageField';
import SelectAsyncPageField from './SelectAsyncPageField';
import SelectCreatableAsyncPageField from './SelectCreatableAsyncPageField';
import TextAreaPageField from 'src/views/components/Page/fields/TextAreaPageField';
import SelectMultiPageField from './SelectMultiPageField';
import ToggleMultiButtonPageField from './ToggleMultiButtonPageField';
import ErrorPageField from './ErrorPageField';
import DaysOfWeekField from './DaysOfWeekPageField';
import SelectAsyncMultiPageField from './SelectAsyncMultiPageField';
import StaffMemberField from './StaffMemberField';
import AssetSelectPageField from 'src/views/components/Page/fields/AssetSelectPageField';
import DurationPageField from './DurationPageField';
import WeekSelectField from './WeekSelectField';
import FilePageField from './FilePageField';
import MultiFilePageField from './MultiFilePageField';
import { IFieldApi } from '../forms/Field';
import { Component } from 'react';

interface IValueKeyType {
  valueKey?: string;
  useValueOnly?: boolean;
}

interface IPageFieldProps {
  formApi: IFormApi;
  fieldDef: FieldDefs;
  fieldMeta: IFieldMeta;
  paneData: IPaneData;
  // tslint:disable-next-line:no-any
  parentValue: any;
}

class PageField extends Component<IPageFieldProps> {
  private unmounted = false;
  private readonly rxSubs = new Subscription();

  private readonly memoizeFieldData = getShallowMemoizedObjectFunc<IFieldData<unknown>>();
  private readonly memoizeFieldDataAddr = getShallowMemoizedObjectFunc<Array<string | number>>();

  private readonly getFieldData = (explicitFieldValue?: unknown) => {
    const { paneData, parentValue } = this.props;
    const { fieldValue, fieldDataAddr } = this.getFieldValueAndAddr();
    return this.memoizeFieldData(
      getFieldData(paneData, parentValue, explicitFieldValue || fieldValue, fieldDataAddr)
    );
  };

  private readonly getFieldValueAndAddr = () => {
    const { formApi, fieldDef: def } = this.props;
    const fieldDataAddr = this.memoizeFieldDataAddr(formApi.getFullField(def.dataAddr));
    const fieldValue = formApi.getValue(fieldDataAddr);
    return { fieldValue, fieldDataAddr };
  };

  private get isHidden() {
    const { fieldDef: def, paneData, parentValue } = this.props;
    const { fieldValue, fieldDataAddr } = this.getFieldValueAndAddr();
    const fieldData = getFieldData(paneData, parentValue, fieldValue, fieldDataAddr);
    return isHidden((def as IFieldFunctionsDef<unknown>).hidden, fieldData);
  }

  private get isReadOnly() {
    const { fieldDef: def, paneData, parentValue, fieldMeta } = this.props;
    const { fieldValue, fieldDataAddr } = this.getFieldValueAndAddr();
    return (
      fieldMeta.readonly ||
      isReadOnly(
        (def as IFieldFunctionsDef<unknown>).readonly,
        getFieldData(paneData, parentValue, fieldValue, fieldDataAddr)
      )
    );
  }

  private get isMandatory() {
    const { formApi, fieldDef: def, paneData, parentValue } = this.props;
    const fieldDataAddr = formApi.getFullField(def.dataAddr);
    const fieldValue = formApi.getValue(fieldDataAddr);
    const defFuncs = def as IFieldFunctionsDef<unknown>;
    return isMandatory(
      defFuncs.mandatory,
      defFuncs.readonly,
      getFieldData(paneData, parentValue, fieldValue, fieldDataAddr)
    );
  }

  private readonly getUpdatedFieldMeta = memoizeOne(
    (
      fieldMeta: IFieldMeta,
      readonly: boolean,
      mandatory: boolean,
      onBlur: (fieldValue: unknown) => void
    ) => {
      return {
        ...fieldMeta,
        readonly,
        mandatory,
        onBlur,
      };
    }
  );

  private readonly getFieldNode = (
    // tslint:disable-next-line:no-any
    data: IFieldData<any>,
    fieldApi: IFieldApi | undefined
  ): React.ReactNode => {
    const { formApi, fieldDef: def, fieldMeta } = this.props;
    // Update meta for any props that can be explicitly set in the field definition
    const meta = this.getUpdatedFieldMeta(
      fieldMeta,
      this.isReadOnly,
      this.isMandatory,
      this.handleFieldBlur
    );

    if (isActionListFieldDef(def)) {
      return <ActionListPageField fieldDef={def} fieldMeta={meta} fieldData={data} />;
    } else if (isReadonlyFieldDef(def)) {
      return <ReadonlyPageField fieldDef={def} fieldMeta={meta} fieldData={data} />;
    }

    if (!fieldApi) {
      throw new Error(
        'Field type is not supported without a fieldApi - ensure dataAddr is defined: ' +
          // tslint:disable-next-line:no-any
          (def as any).fieldType
      );
    }

    if (isCustomFieldDef(def)) {
      return (
        <CustomPageField
          fieldDef={def}
          fieldMeta={meta}
          fieldData={data}
          fieldApi={fieldApi}
          formApi={formApi}
        />
      );
    } else if (isErrorFieldDef(def)) {
      return <ErrorPageField formApi={formApi} fieldApi={fieldApi} />;
    } else if (isWeekSelectFieldDef(def)) {
      return <WeekSelectField fieldDef={def} fieldMeta={meta} fieldData={data} formApi={formApi} />;
    } else if (isTextFieldDef(def)) {
      return <TextPageField fieldDef={def} fieldMeta={meta} fieldData={data} fieldApi={fieldApi} />;
    } else if (isTextAreaFieldDef(def)) {
      return (
        <TextAreaPageField fieldDef={def} fieldMeta={meta} fieldData={data} fieldApi={fieldApi} />
      );
    } else if (isSelectFieldDef(def)) {
      return (
        <SelectPageField fieldDef={def} fieldMeta={meta} fieldData={data} fieldApi={fieldApi} />
      );
    } else if (isSelectCreatableFieldDef(def)) {
      return (
        <SelectCreatablePageField
          fieldDef={def}
          fieldMeta={meta}
          fieldData={data}
          fieldApi={fieldApi}
        />
      );
    } else if (isSelectMultiFieldDef(def)) {
      return (
        <SelectMultiPageField
          fieldDef={def}
          fieldMeta={meta}
          fieldData={data}
          fieldApi={fieldApi}
        />
      );
    } else if (isSelectAsyncFieldDef(def)) {
      return (
        <SelectAsyncPageField
          fieldDef={def}
          fieldMeta={meta}
          fieldData={data}
          fieldApi={fieldApi}
        />
      );
    } else if (isSelectCreatableAsyncFieldDef(def)) {
      return (
        <SelectCreatableAsyncPageField
          fieldDef={def}
          fieldMeta={meta}
          fieldData={data}
          fieldApi={fieldApi}
        />
      );
    } else if (isSelectAsyncMultiFieldDef(def)) {
      return (
        <SelectAsyncMultiPageField
          fieldDef={def}
          fieldMeta={meta}
          fieldData={data}
          fieldApi={fieldApi}
        />
      );
    } else if (isNumericFieldDef(def)) {
      return (
        <NumericPageField fieldDef={def} fieldMeta={meta} fieldData={data} fieldApi={fieldApi} />
      );
    } else if (isDateFieldDef(def)) {
      return <DatePageField fieldDef={def} fieldMeta={meta} fieldData={data} fieldApi={fieldApi} />;
    } else if (isDateTimeFieldDef(def)) {
      return (
        <DatetimePageField fieldDef={def} fieldMeta={meta} fieldData={data} fieldApi={fieldApi} />
      );
    } else if (isTimeFieldDef(def)) {
      return <TimePageField fieldDef={def} fieldMeta={meta} fieldData={data} fieldApi={fieldApi} />;
    } else if (isDurationFieldDef(def)) {
      return (
        <DurationPageField fieldDef={def} fieldMeta={meta} fieldData={data} fieldApi={fieldApi} />
      );
    } else if (isYesNoFieldDef(def)) {
      return (
        <YesNoPageField fieldDef={def} fieldMeta={meta} fieldData={data} fieldApi={fieldApi} />
      );
    } else if (isDaysOfWeekFieldDef(def)) {
      return (
        <DaysOfWeekField fieldDef={def} fieldMeta={meta} fieldData={data} fieldApi={fieldApi} />
      );
    } else if (isToggleButtonFieldDef(def)) {
      return (
        <ToggleButtonPageField
          fieldDef={def}
          fieldMeta={meta}
          fieldData={data}
          fieldApi={fieldApi}
        />
      );
    } else if (isToggleMultiButtonFieldDef(def)) {
      return (
        <ToggleMultiButtonPageField
          fieldDef={def}
          fieldMeta={meta}
          fieldData={data}
          fieldApi={fieldApi}
        />
      );
    } else if (isStaffMemberFieldDef(def)) {
      return (
        <StaffMemberField fieldDef={def} fieldMeta={meta} fieldData={data} fieldApi={fieldApi} />
      );
    } else if (isAssetFieldDef(def)) {
      return (
        <AssetSelectPageField
          fieldDef={def}
          fieldMeta={meta}
          fieldData={data}
          fieldApi={fieldApi}
        />
      );
    } else if (isFileFieldDef(def)) {
      return <FilePageField fieldDef={def} fieldMeta={meta} fieldData={data} fieldApi={fieldApi} />;
    } else if (isMultiFileFieldDef(def)) {
      return (
        <MultiFilePageField
          fieldDef={def as IMultiFileFieldDef}
          fieldMeta={meta}
          fieldData={data}
          fieldApi={fieldApi}
        />
      );
    } else {
      // tslint:disable-next-line:no-any
      throw new Error('Field type not yet supported: ' + (def as any).fieldType);
    }
  };

  // tslint:disable-next-line:no-any
  private readonly isValueEmpty = (v: any) => v === undefined || v === null || v === '';

  private readonly handleValidateAsync = (
    // tslint:disable-next-line:no-any
    value: any
  ): undefined | Promise<undefined | string> => {
    const { fieldDef: def, fieldMeta: meta } = this.props;

    // No errors or validation for fields within a deleted array item
    if (meta.arrayItemRemoved) {
      return;
    }

    const defFuncs = def as IFieldFunctionsDef<unknown>;
    return defFuncs.validateAsync && defFuncs.validateAsync(this.getFieldData(value));
  };

  // tslint:disable-next-line:no-any
  private readonly handleValidate = (value: any) => {
    const { fieldDef: def, fieldMeta: meta } = this.props;

    // No errors or validation for fields within a deleted array item
    if (meta.arrayItemRemoved) {
      return undefined;
    }

    // tslint:disable-next-line:no-any
    const defFuncs = def as IFieldFunctionsDef<any>;

    const mandatoryValidate = this.isMandatory
      ? // tslint:disable-next-line:no-any
        (v: any) => {
          const isNotSet = () => {
            if (this.isValueEmpty(v)) {
              return true;
            }
            // handle fields that hold arrays
            if (isSelectMultiFieldDef(def) || isToggleMultiButtonFieldDef(def)) {
              return Array.isArray(v) && !v.length;
            }

            // handle fields with a valueKey (selects, etc) that have an object set, but no id
            const valueKey = (def as IValueKeyType).valueKey;
            const useValueOnly = (def as IValueKeyType).useValueOnly;
            return valueKey && !useValueOnly && this.isValueEmpty(v[valueKey]);
          };

          const { paneData, parentValue } = this.props;
          const { fieldValue, fieldDataAddr } = this.getFieldValueAndAddr();
          const fieldData = getFieldData(paneData, parentValue, fieldValue, fieldDataAddr);
          const labelText = typeof def.label === 'function' ? def.label(fieldData) : def.label;
          return isNotSet() ? `${labelText} must be specified` : undefined;
        }
      : undefined;

    const customValidate = defFuncs.validate
      ? // tslint:disable-next-line:no-any
        (v: any) => defFuncs.validate && defFuncs.validate(this.getFieldData(v))
      : undefined;

    return (
      (mandatoryValidate && mandatoryValidate(value)) || (customValidate && customValidate(value))
    );
  };

  // tslint:disable-next-line:no-any
  private readonly handleFieldBlur = (fieldValue: any) => {
    const { formApi, fieldDef } = this.props;
    // tslint:disable-next-line:no-any
    const funcs = fieldDef as IFieldFunctionsDef<any>;
    if (funcs.onBlur) {
      funcs.onBlur({
        fieldData: this.getFieldData(fieldValue),
        formValues: formApi.values,
        setFormValues: formApi.setAllValues,
        setFormValue: formApi.setValue,
        validateField: formApi.validate,
      });
    }
  };

  componentDidMount() {
    const { formApi, fieldDef: def, fieldMeta: meta } = this.props;
    // tslint:disable-next-line:no-any
    const defFuncs = def as IFieldFunctionsDef<any>;
    if (defFuncs.onChange) {
      const sub = meta.formChange$
        .filter(_ => !this.isReadOnly && !this.isHidden)
        .filter(x => {
          const pFieldValue = get(x.oldValues, formApi.getFullField(def.dataAddr));
          const nFieldValue = get(x.newValues, formApi.getFullField(def.dataAddr));

          // If the field has a valueKey, we only care when the key changes
          const valueKey = (def as IValueKeyType).valueKey;
          const useValueOnly = (def as IValueKeyType).useValueOnly;
          const pValue =
            valueKey && !useValueOnly ? pFieldValue && pFieldValue[valueKey] : pFieldValue;
          const nValue =
            valueKey && !useValueOnly ? nFieldValue && nFieldValue[valueKey] : nFieldValue;

          return !deepEqual(pValue, nValue, { strict: true });
        })
        .subscribe(x => {
          const oldFieldValue = get(x.oldValues, formApi.getFullField(def.dataAddr));
          const newFieldValue = get(x.newValues, formApi.getFullField(def.dataAddr));

          // This fires before the changes have propagated via the props, so give it a chance to do so
          // before calling onChange
          setTimeout(() => {
            if (this.unmounted) {
              // Control has been unmounted, so do not trigger change - this happens when a table row is removed
              return;
            }
            defFuncs.onChange &&
              defFuncs.onChange({
                oldFieldValue,
                newFieldValue,
                fieldData: this.getFieldData(),
                formValues: x.newValues,
                fieldDataAddr: formApi.getFullField(def.dataAddr),
                setFormValues: formApi.setAllValues,
                setFormValue: (field, value) =>
                  formApi.getValue(field) !== value && formApi.setValue(field, value),
                getFormValue: formApi.getValue,
                validateField: formApi.validate,
              });
          }, 0);
        });
      this.rxSubs.add(sub);
    }
  }

  componentWillUnmount() {
    this.unmounted = true;
    this.rxSubs.unsubscribe();
  }

  render() {
    const { fieldDef: def } = this.props;

    if (this.isHidden) {
      return null;
    }

    const defFuncs = def as IFieldFunctionsDef<unknown>;

    // Without a dataAddr, react-form Fields don't work
    if (!def.dataAddr || (Array.isArray(def.dataAddr) && !def.dataAddr.length)) {
      // Simple check to help developers that try to set validation without a dataAddr
      if (this.isMandatory || defFuncs.validate || defFuncs.validateAsync) {
        throw new Error('Validation cannot be defined unless a dataAddr is also defined');
      }

      return <>{this.getFieldNode(this.getFieldData(undefined), undefined)}</>;
    } else {
      const readonly = this.isReadOnly;
      return (
        <Field
          field={def.dataAddr}
          validate={
            !readonly && (defFuncs.validate || this.isMandatory) ? this.handleValidate : undefined
          }
          asyncValidate={
            !readonly && defFuncs.validateAsync ? this.handleValidateAsync : undefined
          }>
          {api => this.getFieldNode(this.getFieldData(api.value), api)}
        </Field>
      );
    }
  }
}

export default withFormApi(PageField);

export function getFieldData(
  paneData: IPaneData,
  parentValue: unknown,
  fieldValue: unknown,
  fieldDataAddr: Array<string | number>
): IFieldData<unknown> {
  return {
    fieldDataAddr,
    fieldValue: fieldValue,
    parentValue:
      parentValue === undefined || parentValue === null ? paneData.paneValue : parentValue,
    paneValue: paneData.paneValue,
    panelValue: paneData.panelValue,
    sectionValue: paneData.sectionValue,
  };
}

export function fieldCanBeFocused(
  readonly: IFieldFunctionsDef<unknown>['readonly'],
  hidden: IFieldFunctionsDef<unknown>['hidden'],
  fieldData: IFieldData<unknown>
) {
  return !isHidden(hidden, fieldData) && !isReadOnly(readonly, fieldData);
}

export function isMandatory(
  mandatory: IFieldFunctionsDef<unknown>['mandatory'],
  readonly: IFieldFunctionsDef<unknown>['readonly'],
  fieldData: IFieldData<unknown>
) {
  if (!mandatory) {
    return false;
  }

  if (isReadOnly(readonly, fieldData)) {
    return false;
  }

  if (typeof mandatory === 'function') {
    return mandatory(fieldData);
  } else {
    return !!mandatory;
  }
}

export function isHidden(
  // tslint:disable-next-line: no-any
  hidden: IFieldFunctionsDef<any>['hidden'],
  fieldData: IFieldData<unknown>
) {
  if (typeof hidden === 'function') {
    return hidden(fieldData);
  } else {
    return !!hidden;
  }
}

export function isReadOnly(
  readonly: IFieldFunctionsDef<unknown>['readonly'],
  fieldData: IFieldData<unknown>
) {
  if (typeof readonly === 'function') {
    return readonly(fieldData);
  } else {
    return !!readonly;
  }
}
