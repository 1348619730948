import { Input } from 'reactstrap';
import { LabelledFormField } from 'src/views/components/forms';
import { IFieldMeta, IFieldData, INumericFieldDef } from 'src/views/definitionBuilders/types';
import { IFieldApi } from 'src/views/components/Page/forms/Field';
import { formatNumber } from 'src/infrastructure/formattingUtils';
import { Component } from 'react';

interface INumericPageFieldProps {
  fieldDef: INumericFieldDef;
  fieldMeta: IFieldMeta;
  // tslint:disable-next-line:no-any
  fieldData: IFieldData<any>;
  fieldApi: IFieldApi;
}

class NumericPageField extends Component<INumericPageFieldProps> {
  private readonly handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { fieldApi, fieldDef: def } = this.props;
    let value;
    const numericType = (def.numericConfig && def.numericConfig.numericType) || 'unsignedInt';
    switch (numericType) {
      case 'unsignedInt':
        value = unsignedIntegerSanitiser(e.target.value, def.numericConfig);
        break;
      case 'signedInt':
        value = signedIntegerSanitiser(e.target.value, def.numericConfig);
        break;
      case 'unsignedDecimal':
        value = unsignedDecimalSanitiser(e.target.value, def.numericConfig);
        break;
      case 'signedDecimal':
        value = signedDecimalSanitiser(e.target.value, def.numericConfig);
        break;
      default:
        throw new Error('Numeric Type is not currently supported: ' + numericType);
    }
    fieldApi.setValue(value);
  };

  private readonly handleBlur = () => {
    this.props.fieldApi.setTouched(true);
    this.props.fieldMeta.onBlur && this.props.fieldMeta.onBlur(this.props.fieldData.fieldValue);
  };

  render() {
    const { fieldApi, fieldDef: def, fieldMeta: meta, fieldData: data } = this.props;
    const { error, touched } = fieldApi;
    const currentValue = data.fieldValue === 0 || data.fieldValue ? `${data.fieldValue}` : '';
    const labelText = typeof def.label === 'function' ? def.label(data) : def.label;
    const tooltipText = typeof def.tooltip === 'function' ? def.tooltip(data) : def.tooltip;
    return (
      <LabelledFormField
        className="numeric-page-field-component"
        readonly={meta.readonly || false}
        readonlyValue={
          def.formatReadonly && typeof def.formatReadonly === 'function'
            ? def.formatReadonly(data)
            : `${currentValue && formatNumber(Number.parseFloat(currentValue), def.formatReadonly)}`
        }
        readonlyLinkTo={def.linkTo && currentValue ? def.linkTo(data) : undefined}
        mandatory={meta.mandatory}
        hideLabel={meta.hideLabel}
        labelValue={labelText}
        tooltipValue={tooltipText}
        noForm={meta.noForm}
        error={touched && error}>
        <Input
          autoComplete="off"
          type="text"
          className={!(touched && error) ? '' : 'invalid'}
          // disabled={disabled}
          autoFocus={meta.autoFocus}
          value={currentValue}
          onChange={this.handleChange}
          onBlur={this.handleBlur}
          maxLength={def.numericConfig?.maxTotalDigits}
        />
      </LabelledFormField>
    );
  }
}

export default NumericPageField;

// -- Sanitisers --
const maxInt32Str = '2147483647';
const maxInt32 = Number.parseInt(maxInt32Str, 10);
const minInt32Str = '-2147483648';
const minInt32 = Number.parseInt(minInt32Str, 10);
const maxDecimal = Number.MAX_SAFE_INTEGER;

const unsignedIntegerSanitiserOptionsDefaults = {
  maxValue: maxInt32,
  minValue: 1, // Most of the time zero should not be allowed, but this can be set to zero when it is desired
  maxTotalDigits: undefined as number | undefined,
};
type UnsignedIntegerSanitiserOptions = typeof unsignedIntegerSanitiserOptionsDefaults;

export function unsignedIntegerSanitiser(
  value: string,
  options: Partial<UnsignedIntegerSanitiserOptions> = {}
): number | undefined {
  // Only zero or positive integers, capped at a valid int32
  const opts = { ...unsignedIntegerSanitiserOptionsDefaults, ...options };
  const filtered = value.replace(/\D/, '').substr(0, maxInt32Str.length);
  const num = value && Number.parseInt(filtered, 10);
  if (num === '' || Number.isNaN(num)) {
    return undefined;
  }
  if (num > opts.maxValue) {
    return opts.maxValue;
  } else if (num < opts.minValue) {
    return opts.minValue;
  } else {
    return num;
  }
}

// This sanitiser can return a string type, because when typing a negative number, we need to at some point
// have a value of "-" - converting this to a number type removes the hyphen.
const signedIntegerSanitiserOptionsDefaults = {
  maxValue: maxInt32,
  minValue: minInt32,
  maxTotalDigits: undefined as number | undefined,
};
type SignedIntegerSanitiserOptions = typeof signedIntegerSanitiserOptionsDefaults;

export function signedIntegerSanitiser(
  value: string,
  options: Partial<SignedIntegerSanitiserOptions> = {}
): number | string | undefined {
  // Only integers, capped at a valid int32
  const opts = { ...signedIntegerSanitiserOptionsDefaults, ...options };
  const filteredCoarse = value.replace(/[^\d-]/, '').substr(0, minInt32Str.length);
  const firstHyphenMatch = filteredCoarse.match(/^-/);
  const firstHyphenIndex = firstHyphenMatch ? firstHyphenMatch.index : -1;
  const filtered = filteredCoarse.replace(/-/g, (match, offset: number) =>
    offset === firstHyphenIndex ? match : ''
  );

  const num = Number.parseInt(filtered, 10);
  if (num > opts.maxValue) {
    return opts.maxValue;
  } else if (num < opts.minValue) {
    return opts.minValue;
  } else {
    return filtered;
  }
}

// This sanitiser can return a string type, because when typing a decimal number, we need to at some point
// have a value of "3." or "3.00"  - converting this to a number type strips decimal points and trailing zeros.
const decimalSanitiserOptionsDefaults = {
  maxValue: maxDecimal,
  maxPointDigits: undefined as number | undefined,
  maxTotalDigits: undefined as number | undefined,
};
type DecimalSanitiserOptions = typeof decimalSanitiserOptionsDefaults;

export function unsignedDecimalSanitiser(
  value: string,
  options: Partial<DecimalSanitiserOptions> = {}
): number | string | undefined {
  const signedSanitised = signedDecimalSanitiser(value, options);
  if (typeof signedSanitised === 'number') {
    return Math.abs(signedSanitised);
  }
  if (typeof signedSanitised === 'string') {
    return signedSanitised.replace('-', '');
  }
  return signedSanitised;
}

export function signedDecimalSanitiser(
  value: string,
  options: Partial<DecimalSanitiserOptions> = {}
): string | undefined {
  const opts = { ...decimalSanitiserOptionsDefaults, ...options };
  // Strip out all except digits, starting minus and only one dot
  const filteredCoarse = value.replace(/[^\d.-]/g, '');
  const firstDotMatch = filteredCoarse.match(/\./);
  const firstDotIndex = firstDotMatch ? firstDotMatch.index : -1;
  const allDotsExceptFirstRemoved = filteredCoarse.replace(/\./g, (match, offset: number) =>
    offset === firstDotIndex ? match : ''
  );
  const allDashesExceptAtTheStartRemoved = allDotsExceptFirstRemoved.replace(
    /-/g,
    (match, matchIndex: number) => (matchIndex === 0 ? match : '')
  );
  // Ensure that we don't have too many digits after the decimal point
  const resultPointsChecked = allDashesExceptAtTheStartRemoved.replace(/\.\d*/, match => {
    return opts.maxPointDigits && match.length > opts.maxPointDigits + 1
      ? match.substr(0, opts.maxPointDigits + 1)
      : match;
  });

  // Ensure that we don't have too many digits total
  // (parseFloat can have trouble after 15 because floats, so use that as a max limit)
  const maxTotalDigits = Math.min(opts.maxTotalDigits || 15, 15);
  const totalDigitCount = resultPointsChecked.replace(/[^\d]/, '').length;
  const digitsToRemove = totalDigitCount - maxTotalDigits;
  const result =
    digitsToRemove > 0 ? resultPointsChecked.slice(0, -digitsToRemove) : resultPointsChecked;

  const numberResult = Number(result);

  if (isNaN(numberResult)) {
    return result;
  }

  // Ensure our number is not too big
  if (numberResult > opts.maxValue) {
    return opts.maxValue.toString();
  }
  return result;
}
