import './MenuButton.scss';
import Button from 'reactstrap/lib/Button';
import { BarsIcon } from 'src/images/icons';

interface IMenuButtonProps {
  click: () => void;
}

function MenuButton(props: IMenuButtonProps) {
  return (
    <div onClick={props.click} className="menu-button-component">
      <Button size="lg" type="button">
        <BarsIcon />
      </Button>
    </div>
  );
}

export default MenuButton;
