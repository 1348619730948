import { IAjax } from 'src/infrastructure/ajax';
import { workshopUrls } from '../../apiUrls';

type CreateShiftCommand = Workshop.Domain.Commands.Shift.CreateShift.CreateShiftCommand;
type UpdateShiftCommand = Workshop.Domain.Commands.Shift.UpdateShift.UpdateShiftCommand;
type ShiftItem = Workshop.Domain.Queries.Shift.GetShift.ShiftItem;
type ListShiftsQuery = Workshop.Domain.Queries.Shift.ListShifts.ListShiftsQuery;
type ShiftListItem = Workshop.Domain.Queries.Shift.ListShifts.ShiftListItem;

export default function init(ajax: IAjax) {
  function createShift(command: CreateShiftCommand) {
    return ajax
      .post(workshopUrls.shiftUrls.createShift(), command)
      .toPromise()
      .then<string>(r => r.response);
  }

  function updateShift(command: UpdateShiftCommand) {
    return ajax.put(workshopUrls.shiftUrls.updateShift(command.id), command).toPromise();
  }

  function getShift(shiftId: string) {
    return ajax
      .get(workshopUrls.shiftUrls.getShift(shiftId))
      .toPromise()
      .then<ShiftItem>(r => r.response);
  }

  function deleteShift(shiftId: string) {
    return ajax.httpDelete(workshopUrls.shiftUrls.getShift(shiftId)).toPromise();
  }

  function listShifts(query: Partial<ListShiftsQuery>) {
    return ajax
      .get(workshopUrls.shiftUrls.listShifts(query))
      .map(r => r.response as Common.Dtos.ListResult<ShiftListItem>);
  }

  return {
    shift: {
      createShift,
      updateShift,
      getShift,
      deleteShift,
      listShifts,
    },
  };
}
