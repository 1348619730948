import './PopupField.scss';

import { Link } from 'react-router-dom';
import cn from 'classnames';
import { Component } from 'react';

interface IPopupFieldProps {
  className?: string;
  label: string;
  placeholderValue?: string;
  value: React.ReactNode;
  valueLinkTo?: string;
  valueSuffix?: string;
  hidden?: boolean;
  renderActions?: () => React.ReactNode;
}

class PopupField extends Component<IPopupFieldProps> {
  render() {
    const {
      className,
      label,
      placeholderValue,
      value,
      valueLinkTo,
      valueSuffix,
      hidden,
      renderActions,
    } = this.props;

    if (hidden) {
      return null;
    }

    const placeholder = placeholderValue ? (
      <span className="placeholder">{placeholderValue}</span>
    ) : (
      undefined
    );

    return (
      <div className={cn('popup-field-component', className)}>
        <label className="field-label">{label}</label>
        <div className="field-value">
          <>
            {value && valueLinkTo ? (
              <Link to={valueLinkTo}>{value}</Link>
            ) : (
              value || placeholder || ''
            )}
            {valueSuffix ? (
              <span>
                &ensp;
                {valueSuffix}
              </span>
            ) : null}
            {renderActions ? <span>{renderActions()}</span> : null}
          </>
        </div>
      </div>
    );
  }
}

export default PopupField;
