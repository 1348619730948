import styles from './HighlightBadge.module.scss';

interface IHighlightBadgeProps {
  highlight: boolean;
  value: string | undefined;
}

const HighlightBadge: React.FC<IHighlightBadgeProps> = ({ highlight, value }) => {
  return (
    <span className={styles.badge + ' ' + (highlight ? styles.highlight : styles.noHighlight)}>
      {value}
    </span>
  );
};

export default HighlightBadge;
