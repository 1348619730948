import styles from './Menu.module.scss';
import { Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library, IconProp } from '@fortawesome/fontawesome-svg-core';
import {
  faHome,
  faPlane,
  faExclamationCircle,
  faCalendar,
  faUserClock,
  faTasks,
} from '@fortawesome/free-solid-svg-icons';
import SlideInScreen from '../components/layout/SlideInScreen/SlideInScreen';

library.add(faHome, faPlane, faExclamationCircle, faCalendar, faUserClock, faTasks);
interface IMenuLinkProps {
  to: string;
  label: string;
  menuIcon: IconProp;
  click: () => void;
}

const MenuLink: React.FC<IMenuLinkProps> = ({ to, label, menuIcon, click }) => {
  return (
    <div className={styles.menuLink}>
      <Link to={to} onClick={click}>
        <Button size="lg" type="button">
          <FontAwesomeIcon icon={menuIcon} />
          {label}
        </Button>
      </Link>
    </div>
  );
};

export interface IMenuProps {
  click: () => void;
  canUseTimesheets: boolean | undefined;
  show: boolean;
  width?: number;
}

const Menu: React.FunctionComponent<IMenuProps> = props => {
  return (
    <SlideInScreen click={props.click} show={props.show} width={props.width}>
      <MenuLink to="/wskiosk" label="Home" menuIcon="home" click={props.click} />
      {props.canUseTimesheets ? (
        <MenuLink
          to="/wskiosk/timesheet"
          label="Timesheets"
          menuIcon="calendar"
          click={props.click}
        />
      ) : null}
      <MenuLink to="/wskiosk/leave" label="Leave" menuIcon="plane" click={props.click} />
      <MenuLink to="/wskiosk/shifts" label="Shifts" menuIcon="user-clock" click={props.click} />
      <MenuLink
        to="/wskiosk/defect/create"
        label="Raise Defect"
        menuIcon="exclamation-circle"
        click={props.click}
      />
      <MenuLink
        to="/wskiosk/task/create"
        label="Create Task"
        menuIcon="tasks"
        click={props.click}
      />
    </SlideInScreen>
  );
};

export default Menu;
