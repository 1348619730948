import inject from 'src/views/injectFromStore';
import Omit from 'src/infrastructure/omit';
import MaintainSubcontractor, {
  IMaintainSubcontractorProps,
} from 'src/views/routes/operations/subcontractor/MaintainSubcontractor/MaintainSubcontractor';

const Container = inject<Omit<IMaintainSubcontractorProps, 'mode'>, IMaintainSubcontractorProps>(
  (allStores, p) => ({
    canManageSubcontractors: allStores.rootStore.account.isOperationsDepartmentMember,
    createSubcontractor: allStores.rootStore.operations.subcontractor.createSubcontractor,
    updateSubcontractor: allStores.rootStore.operations.subcontractor.updateSubcontractor,
    checkForUniqueName: allStores.rootStore.operations.subcontractor.checkForUniqueName,
    loadSubcontractor: allStores.rootStore.operations.subcontractor.loadSubcontractor,
    subcontractor: allStores.rootStore.operations.subcontractor.subcontractor,
    createContact: allStores.rootStore.operations.subcontractor.createContact,
    updateContact: allStores.rootStore.operations.subcontractor.updateContact,
  })
)(MaintainSubcontractor);

export default Container;
