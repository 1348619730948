import { DateTime } from 'luxon';
import humanizeDuration from 'src/infrastructure/humanizeDuration';

type AssetComponentItem = Workshop.Domain.Queries.AssetComponents.AssetComponentItem;
type AssetOdometerReadingItem = Workshop.Domain.Queries.GetLastAssetOdometerReadings.AssetOdometerReadingItem;

export const getHumanizedDueTextByDate = (component: AssetComponentItem) => {
  if (!component.dueDate) {
    return undefined;
  }
  const dueDate = DateTime.fromISO(component.dueDate);
  if (!dueDate.isValid) {
    return undefined;
  }
  const today = DateTime.local().startOf('day');
  const diff = dueDate.diff(today).milliseconds;
  const humanized = humanizeDuration(diff);
  let finalText = 'Due today';
  if (diff < 0) {
    finalText = `Overdue by ${humanized}`;
  } else if (diff > 0) {
    finalText = `Due in ${humanized}`;
  }
  return finalText;
};

export const getHumanizedDueTextByKms = (
  component: AssetComponentItem,
  lastOdometerReading?: AssetOdometerReadingItem
) => {
  const dueKms = component.dueKms;
  if (!dueKms) {
    return undefined;
  }
  const currentKms = lastOdometerReading;
  if (!currentKms || !currentKms.lastOdometer) {
    return undefined;
  }
  const diff = dueKms - currentKms.lastOdometer;
  let finalText = '';
  if (diff < 0) {
    finalText = `Overdue by ${Math.abs(diff).toFixed(0)} km`;
  } else if (diff > 0) {
    finalText = `Due in ${diff.toFixed(0)} km`;
  }
  return finalText;
};
