import './GanttCalendarDay.scss';

import cn from 'classnames';
import { DateTime, Interval } from 'luxon';
import deepEqual from 'src/infrastructure/deepEqual';
import { IPlacedItem, IGanttCalendarDayItem } from '../baseTypes';
import { Component, PureComponent } from 'react';

const dayHours = Array.from(Array(24), (_, i) => i).map(hour => DateTime.fromObject({ hour }));

interface ITimeRailProps {
  day: DateTime;
}

class TimeRail extends PureComponent<ITimeRailProps> {
  render() {
    const { day } = this.props;
    return (
      <div className="time-rail-component">
        {dayHours.map((h, i) => (
          <div key={i} className="hour">
            {i === 0 ? (
              <strong className="time-rail-day">
                {day.toLocaleString({ weekday: 'short', day: 'numeric' })}
              </strong>
            ) : (
              h.toFormat('HHmm')
            )}
          </div>
        ))}
      </div>
    );
  }
}

interface IDayContentProps<T extends IGanttCalendarDayItem> {
  className?: string;
  showInZone: string;
  rowHeightPx: number;
  totalRows: number;
  day: DateTime;
  highlightBounds?: Interval;
  placedItems: Array<T & IPlacedItem>;
  renderItem: (item: T) => React.ReactNode;
  onItemClick: (item: T, referenceElement: HTMLElement) => void;
}

class DayContent<T extends IGanttCalendarDayItem> extends PureComponent<IDayContentProps<T>> {
  private readonly getItemStyling = (itemBounds: Interval, rowZeroBase: number) => {
    const { showInZone } = this.props;
    const columnZeroBase = itemBounds.start.setZone(showInZone).hour;
    // Place item in the correct hour grid cell, and let it overflow if required
    // This means that items that span midnight can appear over the next day
    return { gridArea: `${rowZeroBase + 1} / ${columnZeroBase + 1}` };
  };

  private readonly getItemContentStyling = (itemBounds: Interval) => {
    const { showInZone, day } = this.props;
    const zonedStart = itemBounds.start.setZone(showInZone);
    const zonedEnd = itemBounds.end.setZone(showInZone);

    // Set the width: Parent div is 1 hour, so 100% width means span one hour
    const durationHours = zonedEnd.diff(zonedStart).as('hours');
    const width = `${durationHours * 100}%`;

    // Set the offset when start is a different day
    const offsetHoursForDays = zonedStart
      .startOf('day')
      .diff(day.startOf('day'))
      .as('hours');

    // Set the offset from the start of the hour
    const offsetHours = zonedStart.diff(zonedStart.startOf('hour')).as('hours');
    const marginLeft = `${(offsetHoursForDays + offsetHours) * 100}%`;

    return { width, marginLeft };
  };

  private readonly getHighlightStyling = (bounds: Interval) => {
    const { showInZone, totalRows } = this.props;
    const columnZeroBase = bounds.start.setZone(showInZone).hour;

    const durationHours = bounds.end.diff(bounds.start).as('hours');
    const width = `${durationHours * 100}%`;

    // Set the offset from the start of the hour
    const offsetHours = bounds.start.diff(bounds.start.startOf('hour')).as('hours');
    const marginLeft = `${offsetHours * 100}%`;

    return {
      gridArea: `1 / ${columnZeroBase + 1} / ${totalRows + 1} / ${columnZeroBase + 2}`,
      width,
      marginLeft,
    };
  };

  private readonly handleItemClick = (e: React.MouseEvent<HTMLButtonElement>, item: T) => {
    e.preventDefault();
    e.stopPropagation();

    this.props.onItemClick(
      item,
      (e.target as Element).closest('button.day-item-content') as HTMLElement
    );
  };

  render() {
    const {
      className,
      rowHeightPx,
      totalRows,
      placedItems,
      day,
      highlightBounds,
      renderItem,
    } = this.props;
    const dayInterval = Interval.fromDateTimes(day.startOf('day'), day.endOf('day'));
    const highlight = highlightBounds && highlightBounds.intersection(dayInterval);

    // Sort the items so that they are placed in the dom in order
    // When the view (ungrouped/driver/vehicle) changes, the order of the of the items shouldn't change
    // (though some may be create/destroyed in place - eg. two drivers will have two items in driver view only)
    const items = [...placedItems];
    items.sort((a, b) =>
      a.placementKey < b.placementKey ? -1 : a.placementKey > b.placementKey ? 1 : 0
    );

    return (
      <div
        className={cn('day-content-component', className)}
        style={{ gridAutoRows: `${rowHeightPx}px` }}>
        <div
          key={totalRows} // Recreate this element when rows change, to avoid occasional rendering issue
          // where the grid element does not grow to fit its items (specifically, this invisible-spacer)
          className="invisible-spacer"
          style={{ gridArea: `${totalRows} / 1` }}
        />
        {highlight ? (
          <div className="highlight-zone" style={this.getHighlightStyling(highlight)} />
        ) : null}
        {items.map(item => (
          <div
            key={item.placementKey}
            className="day-item"
            style={this.getItemStyling(item.bounds, item.rowIndex)}>
            <button
              className="day-item-content"
              style={this.getItemContentStyling(item.bounds)}
              onClick={e => this.handleItemClick(e, item)}>
              {renderItem(item)}
            </button>
          </div>
        ))}
      </div>
    );
  }
}

interface ICalendarDayProps<T extends IGanttCalendarDayItem> {
  className?: string;
  showInZone: string;
  dayWidthPx: number;
  rowHeightPx: number;
  totalRows: number;
  day: DateTime;
  highlightBounds?: Interval;
  placedItems: IDayContentProps<T>['placedItems'];
  renderItem: IDayContentProps<T>['renderItem'];
  onItemClick: IDayContentProps<T>['onItemClick'];
}

class GanttCalendarDay<T extends IGanttCalendarDayItem> extends Component<ICalendarDayProps<T>> {
  shouldComponentUpdate(nextProps: Readonly<ICalendarDayProps<T>>) {
    return Object.keys(nextProps).some(key => {
      if (key === 'day') {
        return !nextProps.day.hasSame(this.props.day, 'day');
      }
      if (key === 'placedItems') {
        return !deepEqual(nextProps.placedItems, this.props.placedItems);
      }
      return nextProps[key] !== this.props[key];
    });
  }

  render() {
    const {
      className,
      showInZone,
      dayWidthPx,
      rowHeightPx,
      totalRows,
      day,
      highlightBounds,
      placedItems,
      renderItem,
      onItemClick,
    } = this.props;
    return (
      <div className={cn('gantt-calendar-day-component', className)} style={{ width: dayWidthPx }}>
        <DayContent
          showInZone={showInZone}
          rowHeightPx={rowHeightPx}
          totalRows={totalRows}
          day={day}
          highlightBounds={highlightBounds}
          placedItems={placedItems}
          renderItem={renderItem}
          onItemClick={onItemClick}
        />
        <TimeRail day={day} />
      </div>
    );
  }
}

export default GanttCalendarDay;
