import { FieldType, ModalDefBuilder, PaneType } from 'src/views/definitionBuilders/types';
import { Link } from 'react-router-dom';
import { QuoteStatus } from 'src/api/enums';
import { DateTimeFormat } from 'src/views/components/DateTimeFormat';
import Spinner from 'src/views/components/Spinner';
import { getSubmitCloseModalActionGroupDef } from 'src/views/definitionBuilders/common';

type Repeat = Operations.Domain.Queries.ViewQuote.Repeat;
type QuoteItem = Operations.Domain.Queries.ViewQuote.QuoteItem;
type JobForCancellingQuote = Operations.Domain.Queries.GetJobsForCancellingQuote.JobsForCancellingQuoteDto.JobForCancellingQuote;
type JobsForCancellingQuoteDto = Operations.Domain.Queries.GetJobsForCancellingQuote.JobsForCancellingQuoteDto;

const getNumberOfBookedQuotes = (quote: QuoteItem) => {
  const numberOfBookedRepeats = quote.repeats
    ? quote.repeats.filter(
        r => r.linkedQuoteStatus && r.linkedQuoteStatus.id === QuoteStatus.Booked
      ).length
    : 0;
  const numberOfBookedOriginalQuotes =
    getOriginalQuote(quote).status.id === QuoteStatus.Booked ? 1 : 0;

  return numberOfBookedRepeats + numberOfBookedOriginalQuotes;
};

const getOriginalQuote = (quote: QuoteItem) => (quote.sourceQuote ? quote.sourceQuote : quote);

export const getCancelBookingModalDef = (
  quote: QuoteItem | undefined,
  jobsForCancellingQuote: JobsForCancellingQuoteDto | undefined,
  onCancelBooking: (
    cancellationReason: string,
    cancelFutureBookingRecurrences: boolean,
    forceInProgressCancel: boolean
  ) => Promise<void>,
  forceInProgressCancel: boolean = false
): ModalDefBuilder => {
  if (!quote || !jobsForCancellingQuote || quote.id !== jobsForCancellingQuote.quoteId) {
    return api => ({
      title: 'Loading',
      panels: [
        {
          panes: [
            {
              paneType: PaneType.customPane,
              render: d => <Spinner show />,
            },
          ],
        },
      ],
    });
  }

  const ignoredJobs = jobsForCancellingQuote.jobsToIgnore;
  const deletingJobs = jobsForCancellingQuote.jobsToDelete;
  const cancelInProgress = jobsForCancellingQuote.jobsToCancelInProgress;

  const enhancedQuote: QuoteItem & {
    ignoredJobs: JobForCancellingQuote[];
    cancelInProgress: JobForCancellingQuote[];
    deletingJobs: JobForCancellingQuote[];
  } = {
    ...quote,
    ignoredJobs,
    cancelInProgress,
    deletingJobs,
  };

  const inProgressBooking =
    (ignoredJobs && ignoredJobs.length > 0) || (cancelInProgress && cancelInProgress.length > 0);

  return modalDefApi => ({
    title: inProgressBooking ? 'Cancel Booking In Progress' : 'Cancel Booking',
    explicitData: { ...enhancedQuote, cancelFutureBookingRecurrences: false },
    asForm: true,
    panels: [
      {
        panes: [
          {
            paneType: PaneType.customPane,
            render: api => inProgressBooking || <span>This Booking will be Cancelled.</span>,
          },
          {
            paneType: PaneType.formFieldsPane,
            fields: [
              {
                fieldType: FieldType.textAreaField,
                label: 'Reason for Cancellation',
                dataAddr: 'cancellationReason',
                mandatory: true,
                maxLength: 256,
              },
            ],
          },
          {
            paneType: PaneType.formFieldsPane,
            fields: [
              {
                fieldType: FieldType.yesNoField,
                label: 'Cancel all future recurred bookings?',
                dataAddr: 'cancelFutureBookingRecurrences',
                mandatory: true,
                hidden: d => {
                  const booking = d.parentValue as QuoteItem;
                  let hidden = booking && booking.repeats.length < 1;
                  // if this is the last booking or the last non-cancelled booking, don't render
                  for (let i = booking.repeats.length - 1; i >= 0; i--) {
                    const thisBooking = booking.repeats[i];
                    if (thisBooking.linkedQuoteStatus.id === QuoteStatus.Booked) {
                      hidden = thisBooking.linkedQuoteId === d.paneValue.id;
                      break;
                    }
                  }

                  return hidden;
                },
              },
              {
                fieldType: FieldType.customField,
                dataAddr: 'required',
                render: d => {
                  const cancelFutureBookingRecurrences =
                    d.data.parentValue.cancelFutureBookingRecurrences;
                  return cancelFutureBookingRecurrences ? (
                    <div>All future bookings listed below will be cancelled</div>
                  ) : null;
                },
              },
            ],
          },
          {
            paneType: PaneType.nestingPane,
            dataAddr: 'repeats',
            hidden: () => getNumberOfBookedQuotes(quote) <= 1,
            panes: [
              {
                paneType: PaneType.formFieldsPane,
                fields: [
                  {
                    fieldType: FieldType.readonlyField,
                    label: 'Original Booking',
                    hidden: () => !quote.sourceQuote || getNumberOfBookedQuotes(quote) <= 1,
                    formatReadonly: d => {
                      const sourceQuote = quote.sourceQuote;
                      return sourceQuote ? (
                        <div>
                          <div>
                            <DateTimeFormat value={sourceQuote.firstTrip} />
                          </div>
                          <div>
                            <small>
                              <Link to={`/sales/bookings/${sourceQuote.id}`}>
                                {`Booking ${sourceQuote.quoteNumber} (${sourceQuote.status.description})`}
                              </Link>
                            </small>
                          </div>
                        </div>
                      ) : null;
                    },
                  },
                ],
              },
              {
                paneType: PaneType.tablePane,
                dataRequiredForRows: 'paneValue',
                hidden: d => !d.paneValue || !d.paneValue.length,
                fields: [
                  {
                    fieldType: FieldType.dateField,
                    dataAddr: 'repeatDate',
                    label: 'Associated Recurring Bookings',
                    mandatory: true,
                    readonly: d => true,
                    formatReadonly: d => {
                      const repeat: Repeat = d.parentValue;
                      const status = repeat.linkedQuoteStatus
                        ? repeat.linkedQuoteStatus.description
                        : undefined;
                      return d.fieldValue ? (
                        <div>
                          <div>
                            <DateTimeFormat value={d.fieldValue} />
                          </div>
                          <div>
                            {quote && repeat.linkedQuoteId === quote.id ? (
                              <div>
                                <small>(This booking)</small>
                              </div>
                            ) : repeat.linkedQuoteId ? (
                              <small>
                                <Link to={`/sales/quotes/${repeat.linkedQuoteId}`}>
                                  {`Booking ${repeat.linkedQuoteNumber} (${status})`}
                                </Link>
                              </small>
                            ) : null}
                          </div>
                        </div>
                      ) : null;
                    },
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        panes: [
          {
            paneType: PaneType.nestingPane,
            spaceBetweenPanesVertically: true,
            panes: [
              {
                paneType: PaneType.tablePane,
                title: 'Jobs being Cancelled In Progress',
                dataAddr: 'cancelInProgress',
                noRowsMessage: 'No jobs will be cancelled',
                neverEditable: true,
                tableDescription: 'These jobs have been started and will be Cancelled In Progress.',
                fields: [
                  {
                    fieldType: FieldType.readonlyField,
                    dataAddr: 'jobNumber',
                    label: 'Job Number',
                    readonly: true,
                    linkTo: d => `/operations/jobs/${d.parentValue.id}`,
                  },
                  {
                    fieldType: FieldType.textField,
                    dataAddr: 'staffMemberName',
                    label: 'Staff Member',
                    readonly: true,
                  },
                  {
                    fieldType: FieldType.textField,
                    dataAddr: 'secondStaffMemberName',
                    label: 'Second Staff Member',
                    readonly: true,
                    formatReadonly: d => <span>{d.fieldValue || 'None Allocated'}</span>,
                  },
                  {
                    fieldType: FieldType.dateTimeField,
                    dataAddr: 'clockOn',
                    label: 'Clock On',
                    readonly: true,
                  },
                ],
              },
              {
                paneType: PaneType.tablePane,
                title: 'Jobs being Deleted',
                dataAddr: 'deletingJobs',
                noRowsMessage: 'No jobs will be deleted ',
                neverEditable: true,
                tableDescription: 'These jobs have not been started and will be deleted.',
                fields: [
                  {
                    fieldType: FieldType.readonlyField,
                    dataAddr: 'jobNumber',
                    label: 'Job Number',
                    linkTo: d => `/operations/jobs/${d.parentValue.id}`,
                  },
                  {
                    fieldType: FieldType.textField,
                    dataAddr: 'staffMemberName',
                    label: 'Staff Member',
                    readonly: true,
                    formatReadonly: d => <span>{d.fieldValue || 'None Allocated'}</span>,
                  },
                  {
                    fieldType: FieldType.textField,
                    dataAddr: 'secondStaffMemberName',
                    label: 'Second Staff Member',
                    readonly: true,
                    formatReadonly: d => <span>{d.fieldValue || 'None Allocated'}</span>,
                  },
                  {
                    fieldType: FieldType.dateTimeField,
                    dataAddr: 'clockOn',
                    label: 'Clock On',
                    readonly: true,
                  },
                ],
              },
              {
                paneType: PaneType.tablePane,
                title: 'Jobs being Ignored',
                dataAddr: 'ignoredJobs',
                noRowsMessage: 'No jobs',
                neverEditable: true,
                tableDescription:
                  'These jobs have been Completed or already cancelled and will not be modified.',
                fields: [
                  {
                    fieldType: FieldType.readonlyField,
                    dataAddr: 'jobNumber',
                    label: 'Job Number',
                    readonly: true,
                    linkTo: d => `/operations/jobs/${d.parentValue.jobId}`,
                  },
                  {
                    fieldType: FieldType.textField,
                    dataAddr: 'staffMemberName',
                    label: 'Staff Member',
                    readonly: true,
                    formatReadonly: d => <span>{d.fieldValue || 'None Allocated'}</span>,
                  },
                  {
                    fieldType: FieldType.dateTimeField,
                    dataAddr: 'clockOn',
                    label: 'Clock On',
                    readonly: true,
                  },
                  {
                    fieldType: FieldType.readonlyField,
                    dataAddr: 'isCancelled',
                    label: 'Status',
                    formatReadonly: d => (
                      <span>{d.fieldValue === true ? 'Cancelled' : 'Completed'}</span>
                    ),
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
    secondaryActions: [getSubmitCloseModalActionGroupDef('Ok')],
    onFormSubmit: d =>
      onCancelBooking(
        d.cancellationReason,
        d.cancelFutureBookingRecurrences,
        forceInProgressCancel
      ),
  });
};
