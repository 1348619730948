import { IAjax } from 'src/infrastructure/ajax';
import { operationsUrls } from 'src/domain/services/apiUrls';

export default function init(ajax: IAjax) {
  function clearTechSpecRequirements(techSpecId: number) {
    return ajax
      .httpDelete(operationsUrls.requirementsUrls.clearTechSpecRequirements(techSpecId))
      .toPromise();
  }

  function clearSkillSpecRequirements(skillSpecId: number) {
    return ajax
      .httpDelete(operationsUrls.requirementsUrls.clearSkillSpecRequirements(skillSpecId))
      .toPromise();
  }

  return {
    requirements: {
      clearTechSpecRequirements,
      clearSkillSpecRequirements,
    },
  };
}
