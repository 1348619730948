import { useEffect } from 'react';
import withQueryParams, { IQueryParamsProps } from 'src/views/hocs/withQueryParams';
import { ListPage } from 'src/views/components/Page';
import memoizeOne from 'memoize-one';
import {
  PagePrimarySize,
  PaneType,
  FieldType,
  FieldDefs,
  ActionType,
  IFieldData,
} from 'src/views/definitionBuilders/types';
import { TaskCardItem } from 'src/views/components/TaskCard';
import { allCctvRequestStatus, CctvRequestStatus } from 'src/api/enums';
import { DateTime, Duration } from 'luxon';
import Status from './Status';
import { ExcelIcon, WarnIcon } from 'src/images/icons';
import { saveAs } from 'file-saver';
import IncidentDetails from './IncidentDetails';
import { IListPageDef } from 'src/views/components/Page/pages/ListPage';
import { observer } from 'mobx-react';
import { useRootStore } from 'src/domain/entities/RootStoreModel';
import PrimaryTitle from 'src/views/components/Page/PrimaryTitle/PrimaryTitle';

type CctvRequestListItem = Operations.Domain.Queries.ListCctvRequests.CctvRequestListItem;
type ListCctvRequestsQuery = Operations.Domain.Queries.ListCctvRequests.ListCctvRequestsQuery;
type AssetItem = Common.Queries.Workshop.GetFleetAssetList.AssetItem;

const ListCctvRequest: React.FC<IQueryParamsProps<ListCctvRequestsQuery>> = observer(
  (props: IQueryParamsProps<ListCctvRequestsQuery>) => {
    const rootStore = useRootStore();
    const cctvModel = rootStore.compliance.cctvRequest.list;
    const assetsModel = rootStore.assets;

    useEffect(() => {
      assetsModel.loadFleetAssets();
    }, []);

    const saveSpreadsheet = () => {
      const fileName = `CCTV_Request_${DateTime.local().toFormat('yyyyMMddHHmm')}.xlsx`;
      return cctvModel.exportToExcel(props.getQueryParams()).then(blob => saveAs(blob, fileName));
    };

    const getFilterFieldDefs = (fleetAssets: AssetItem[]) => {
      return {
        dateFrom: {
          fieldType: FieldType.dateField,
          dataAddr: 'dateFrom',
          label: 'Date From',
        } as FieldDefs,
        dateTo: {
          fieldType: FieldType.dateField,
          dataAddr: 'dateTo',
          label: 'Date To',
          validate: d => {
            if (!d.fieldValue || !d.parentValue.dateFrom) {
              return undefined;
            }
            const from = DateTime.fromISO(d.parentValue.dateFrom);
            const to = DateTime.fromISO(d.fieldValue);
            return from > to ? 'Date To cannot be earlier than Date From' : undefined;
          },
        } as FieldDefs,
        vehicles: {
          fieldType: FieldType.selectMultiField,
          dataAddr: 'assets',
          label: 'Vehicle',
          valueKey: 'id',
          descriptionKey: 'name',
          optionItems: fleetAssets.slice(),
          useValueOnly: true,
        } as FieldDefs,
        statuses: {
          fieldType: FieldType.selectMultiField,
          dataAddr: 'statuses',
          label: 'Status',
          valueKey: 'value',
          descriptionKey: 'description',
          optionItems: allCctvRequestStatus.slice(),
          useValueOnly: true,
        } as FieldDefs,
        isOverdue: {
          fieldType: FieldType.yesNoField,
          dataAddr: 'isOverdue',
          label: 'Overdue',
        } as FieldDefs,
        cctvSystemInWorkingOrder: {
          fieldType: FieldType.yesNoField,
          dataAddr: 'cctvSystemInWorkingOrder',
          label: 'CCTV System in Working Order',
        } as FieldDefs,
      };
    };

    const isOverdue = (request: CctvRequestListItem) => {
      if (request.cctvRequestStatus === CctvRequestStatus.Closed) {
        return false;
      }
      var incidentOn = DateTime.fromISO(request.createdOn);
      const now = DateTime.local();
      const twentyFourHours = Duration.fromObject({ hours: 24 }).as('hours');
      return now.diff(incidentOn).as('hours') > twentyFourHours;
    };

    const getPageDef = memoizeOne(
      (hasMoreData: boolean, fleetAssets: AssetItem[]): IListPageDef => {
        const filterFieldDefsLookup = getFilterFieldDefs(fleetAssets);
        return {
          primaryTitle: (
            <PrimaryTitle
              title="CCTV Requests"
              link="https://www.notion.so/CCTV-Requests-8cae25d3f3fb4fb4a0ca92205d3d55b0"></PrimaryTitle>
          ),
          onLoadData: cctvModel.listItems,
          externalSearch: true,
          primarySize: PagePrimarySize.full,
          hasMoreData: hasMoreData,
          createLink: rootStore.account.isOperationsDepartmentMember
            ? TaskCardItem.operations.compliance.cctvRequest.createCctvRequest
            : undefined,
          rowKey: d => {
            return d.itemValue.id;
          },
          primaryActions: [
            {
              actions: [
                {
                  actionType: ActionType.actionCollection,
                  actionGroups: [
                    {
                      actions: [
                        {
                          actionType: ActionType.actionButton,
                          label: 'Export to Excel',
                          icon: <ExcelIcon fixedWidth />,
                          onClick: () => saveSpreadsheet(),
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
          primaryFields: [
            {
              fieldType: FieldType.textField,
              dataAddr: 'cctvRequestNumber',
              label: 'Number',
              linkTo: (d: IFieldData<string>) =>
                `/compliance/cctv-requests/${(d.parentValue as CctvRequestListItem).id}`,
              formatReadonly: (d: IFieldData<string>) => (
                <span>
                  {isOverdue(d.parentValue as CctvRequestListItem) ? (
                    <WarnIcon color="red" />
                  ) : (
                    undefined
                  )}
                  {d.fieldValue}
                </span>
              ),
              orderBy: 'CctvRequestNumber',
            },
            {
              fieldType: FieldType.dateTimeField,
              dataAddr: 'incidentOn',
              label: 'Incident On',
              orderBy: 'IncidentOn',
            },
            {
              fieldType: FieldType.assetSelectField,
              valueKey: 'id',
              descriptionKey: 'name',
              dataAddr: 'asset',
              label: 'Vehicle',
            },
            {
              fieldType: FieldType.readonlyField,
              dataAddr: 'incidentDetails',
              label: 'Incident Details',
              formatReadonly: d => (
                <IncidentDetails incidentDetails={d.parentValue.incidentDetails} />
              ),
            },
            {
              fieldType: FieldType.dateTimeField,
              dataAddr: 'createdOn',
              label: 'Created On',
              orderBy: 'CreatedOn',
            },
            {
              fieldType: FieldType.readonlyField,
              dataAddr: 'cctvRequestStatus',
              label: 'Status',
              orderBy: 'CctvRequestStatus',
              formatReadonly: d => <Status cctvRequestStatus={d.parentValue.cctvRequestStatus} />,
            },
            {
              fieldType: FieldType.readonlyField,
              dataAddr: 'cctvSystemInWorkingOrder',
              label: 'CCTV Working',
              formatReadonly: d => {
                if (d.fieldValue == null) {
                  return '';
                }

                return d.fieldValue ? 'Yes' : 'No';
              },
            },
          ],
          filterAction: {
            defaultValues: {
              statuses: [CctvRequestStatus.Open],
            },
            filterFields: Object.keys(filterFieldDefsLookup).map(k => filterFieldDefsLookup[k]),
            filterDef: filterDefApi => [
              {
                panes: [
                  {
                    paneType: PaneType.formFieldsPane,
                    columnCount: 2,
                    fields: [filterFieldDefsLookup.dateFrom, filterFieldDefsLookup.dateTo],
                  },
                  {
                    paneType: PaneType.formFieldsPane,
                    fields: [
                      filterFieldDefsLookup.vehicles,
                      filterFieldDefsLookup.statuses,
                      filterFieldDefsLookup.isOverdue,
                      filterFieldDefsLookup.cctvSystemInWorkingOrder,
                    ],
                  },
                ],
              },
            ],
          },
        };
      }
    );

    const def = getPageDef(cctvModel.hasMoreItems, assetsModel.fleetAssetListItems);
    return <ListPage data={cctvModel.items} def={def} />;
  }
);

export default withQueryParams(ListCctvRequest);
