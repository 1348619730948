import inject from 'src/views/injectFromStore';
import ListGenerateJobsOperation, {
  IListGenerateJobsOperationProps,
} from './ListGenerateJobsOperation';

const Container = inject<IListGenerateJobsOperationProps>((allStores, p) => ({
  canManageUrbanJobs: allStores.rootStore.account.isOperationsDepartmentMember,
  listGenerateJobsOperations: allStores.rootStore.operations.generateJobsOperation.list.listItems,
  lastUpdated: allStores.rootStore.operations.generateJobsOperation.list.lastUpdated,
  generateJobsOperations: allStores.rootStore.operations.generateJobsOperation.list.items.slice(),
  hasMoreData: allStores.rootStore.operations.job.list.hasMoreItems,
  acknowledgeFailedGenerateJobsOperation:
    allStores.rootStore.operations.generateJobsOperation.acknowledgeFailedGenerateJobsOperation,
}))(ListGenerateJobsOperation);

export default Container;
