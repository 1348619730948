import { WarehouseIcon } from 'src/images/icons';
import styles from './DepotBadge.module.scss';
import React from 'react';

export interface IDepotBadgeProps {
  depotDescription: string;
}

const DepotBadge: React.FC<IDepotBadgeProps> = ({ depotDescription }) => {
  return (
    <div className={styles.depotBadge}>
      <span>{depotDescription}</span>
      <WarehouseIcon className={styles.icon} />
    </div>
  );
};

export default DepotBadge;
