import { types, flow } from 'mobx-state-tree';
import { DateTime } from 'luxon';
import { getAjax } from 'src/domain/services/storeEnvironment';

type MechanicJob = Workshop.Domain.Queries.Job.GetJobsForMechanicAndDate.JobSummary;

export const JobsModel = types
  .model('JobsModel', {
    jobs: types.array(types.frozen<MechanicJob>()),
    overdueJobs: types.array(types.frozen<MechanicJob>()),
  })
  .views(self => ({}))
  .actions(self => {
    const ajax = getAjax(self);

    const loadJobsForMechanic = flow(function*(date: DateTime, depotId: number) {
      self.jobs.clear();
      self.jobs = yield ajax.kiosk.workshop.job.loadJobsForMechanic(date.toISODate(), depotId);
    });

    const loadOverdueJobsForMechanic = flow(function*(depotId: number) {
      self.overdueJobs.clear();
      self.overdueJobs = yield ajax.kiosk.workshop.job.loadOverdueJobsForMechanic(depotId);
    });

    return {
      loadJobsForMechanic,
      loadOverdueJobsForMechanic,
    };
  });
