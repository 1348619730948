import { IAjax } from 'src/infrastructure/ajax';
import { labourPricesUrls } from 'src/domain/services/apiUrls';

type LabourPriceDetails = Workshop.Domain.Queries.GetLabourPrices.LabourPriceDetails;

const returnVoid = () => {};

export default function init(ajax: IAjax) {
  function listLabourPrices() {
    return ajax
      .get(labourPricesUrls.getLabourPrices())
      .toPromise()
      .then<LabourPriceDetails[]>(r => r.response);
  }

  function createLabourPrice(
    command: Workshop.Domain.Commands.LabourPrice.CreateLabourPriceCommand
  ) {
    return ajax
      .post(labourPricesUrls.createLabourPrice(), command)
      .toPromise()
      .then(r => returnVoid());
  }

  function updateLabourPrice(
    command: Workshop.Domain.Commands.LabourPrice.UpdateLabourPriceCommand
  ) {
    return ajax
      .put(labourPricesUrls.updateLabourPrice(command), command)
      .toPromise()
      .then(r => returnVoid());
  }

  function deleteLabourPrice(id: string) {
    return ajax.httpDelete(labourPricesUrls.deleteLabourPrice(id)).toPromise();
  }

  return {
    labourPrices: {
      listLabourPrices,
      updateLabourPrice,
      deleteLabourPrice,
      createLabourPrice,
    },
  };
}
