import './LeaveCalendar.scss';

import Calendar from 'react-calendar';
import { DateTime } from 'luxon';
import { getLeaveTypeDescriptor, LeaveStatus } from 'src/api/enums';
import { Detail } from 'react-calendar/dist/cjs/shared/types';

type LeaveDto = Common.Dtos.LeaveDto;

export interface CalendarTileProperties {
  date: Date;
  view: Detail;
}
interface ILeaveCalendarProps {
  leaves: LeaveDto[];
  activeStartDateChanged: (date: DateTime) => void;
  timezone: string;
}

function getTileClassName(
  tileProperties: CalendarTileProperties,
  leaves: LeaveDto[],
  timezone: string
) {
  const classNames: string[] = ['tile'];
  if (!leaves || leaves.length === 0) {
    return classNames;
  }
  if (tileProperties.view !== 'month') {
    return classNames;
  }
  const localDate = DateTime.fromJSDate(tileProperties.date);
  const date = DateTime.fromObject({
    year: localDate.year,
    month: localDate.month,
    day: localDate.day,
    zone: timezone,
  });
  const endDate = date.endOf('day');

  if (date.hasSame(DateTime.local().setZone(timezone), 'day')) {
    classNames.push('today-tile');
  }

  const leaveForDate = leaves
    .filter(x => x.status !== LeaveStatus.Declined)
    .filter(
      x =>
        DateTime.fromISO(x.start, { zone: timezone }) <= endDate &&
        DateTime.fromISO(x.end, { zone: timezone }) > date
    );
  if (leaveForDate.length > 0) {
    if (leaveForDate[0].status === LeaveStatus.Pending) {
      classNames.push('pending');
    }
    classNames.push(getLeaveTypeDescriptor(leaveForDate[0].type).name);
  }
  return classNames;
}

const LeaveCalendar: React.FC<ILeaveCalendarProps> = ({
  leaves,
  activeStartDateChanged,
  timezone,
}) => {
  return (
    <Calendar
      className="leave-calendar-component"
      showNeighboringMonth={false}
      tileClassName={tileProperties => getTileClassName(tileProperties, leaves, timezone)}
      onClickMonth={date => {
        const localDate = DateTime.fromJSDate(date);
        const zonedDate = DateTime.fromObject({
          year: localDate.year,
          month: localDate.month,
          day: localDate.day,
          zone: timezone,
        });
        activeStartDateChanged(zonedDate);
      }}
      onActiveStartDateChange={props => {
        const localDate = DateTime.fromJSDate(props.activeStartDate as Date);
        const zonedDate = DateTime.fromObject({
          year: localDate.year,
          month: localDate.month,
          day: localDate.day,
          zone: timezone,
        });
        activeStartDateChanged(zonedDate);
      }}
    />
  );
};

export default LeaveCalendar;
