export const getBase64 = (file: File) => {
  return new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () =>
      resolve(
        reader.result ? reader.result.toString().replace(/^data:[a-z]+\/[a-z]+;base64,/, '') : ''
      );
    reader.onerror = error => reject(error);
  });
};
