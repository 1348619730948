import { RouteComponentProps } from 'react-router-dom';
import {
  PagePrimarySize,
  PaneType,
  FieldType,
  ActionType,
  ShellModalSize,
  ISectionDef,
  IModalDefBuilderApi,
  ITablePaneDef,
} from 'src/views/definitionBuilders/types';
import CrudPage, { CrudPageMode, ICrudPageDef } from 'src/views/components/Page/pages/CrudPage';
import * as EmailValidator from 'email-validator';
import {
  PlusIcon,
  EditIcon,
  TrashIcon,
  FilePdfIcon,
  ExternalLinkIcon,
  CheckIcon,
} from 'src/images/icons';
import { standardTextSanitisers } from 'src/views/componentBuilders/buildLabelledText';
import {
  allQuoteStatus,
  getRailBookingStatusDescriptor,
  QuoteStatus,
  RailBookingStatus,
} from 'src/api/enums';
import { saveAs } from 'file-saver';
import { getSubmitCloseModalActionGroupDef } from 'src/views/definitionBuilders/common';
import ActionBar from 'src/views/components/Page/actions/ActionBar';
import styles from './MaintainCustomer.module.scss';
import { formatDateTimeMed, getTodayISODate, getTodaySortableDate } from 'src/domain/dateHelper';
import { observer } from 'mobx-react';
import { useRootStore } from 'src/domain/entities/RootStoreModel';
import { useEffect } from 'react';

type Customer = Operations.Domain.Queries.ViewCustomer.CustomerItem;
type CreateCustomerCommand = Operations.Domain.Commands.Customer.CreateCustomerCommand;
type UpdateCustomerCommand = Operations.Domain.Commands.Customer.UpdateCustomerCommand;
type GenerateUpcomingBookingsReportForCustomerQuery = Operations.Domain.Queries.GenerateUpcomingBookingsReportForCustomer.GenerateUpcomingBookingsReportForCustomerQuery;
type CreateCustomerNoteCommand = Operations.Domain.Commands.Customer.CreateCustomerNoteCommand;
type UpdateCustomerNoteCommand = Operations.Domain.Commands.Customer.UpdateCustomerNoteCommand;
type CustomerNote = Operations.Domain.AggregatesModel.CustomerAggregate.CustomerNote;

export interface IMaintainCustomerProps {
  mode: CrudPageMode;
  route: RouteComponentProps<{ [x: string]: string | undefined }>;
}

export const MaintainCustomer: React.FC<IMaintainCustomerProps> = observer(
  (props: IMaintainCustomerProps) => {
    const rootStore = useRootStore();
    const salesModel = rootStore.operations.sales;
    const customerModel = salesModel.customer;
    const quotesModel = salesModel.quotes.quotesForCustomer;
    const contractsModel = rootStore.operations.charterContracts;
    const contractBookingsModel = rootStore.operations.contractCharterBookings;
    const canManageCustomers = rootStore.account.isSalesDepartmentMember;
    const isUpdateMode = props.mode === 'update';
    const customerId = props.route.match.params.id!;

    useEffect(() => {
      if (customerId) {
        customerModel.loadCustomer(customerId);
        quotesModel.loadBookingsForCustomer(customerId);
        quotesModel.loadQuotesForCustomer(customerId);
        quotesModel.loadRailBookingsForCustomer(customerId);
        contractsModel.getCharterContractsByCustomer(customerId);
        contractBookingsModel.getRecentCharterContractBookingsForCustomer(customerId);
      }
    }, [customerId]);

    const handlePreSubmitForCreate = (customer: Customer): CreateCustomerCommand => {
      return {
        billingAddress: customer.billingAddress,
        billingCity: customer.billingCity,
        billingPostcode: customer.billingPostcode,
        billingState: customer.billingState,
        email: customer.email,
        fax: customer.fax,
        hasTradingAccount: customer.hasTradingAccount,
        name: customer.name,
        otherAddress: customer.otherAddress,
        otherCity: customer.otherCity,
        otherPostcode: customer.otherPostcode,
        otherState: customer.otherState,
        phone: customer.phone,
        website: customer.website,
      };
    };

    const handleSubmitForCreate = async (command: CreateCustomerCommand): Promise<void> => {
      await customerModel.createCustomer(command);
    };

    const handlePreSubmitForUpdate = (customer: Customer): UpdateCustomerCommand => {
      return {
        billingAddress: customer.billingAddress,
        billingCity: customer.billingCity,
        billingPostcode: customer.billingPostcode,
        billingState: customer.billingState,
        email: customer.email,
        fax: customer.fax,
        hasTradingAccount: customer.hasTradingAccount,
        id: customer.id,
        name: customer.name,
        otherAddress: customer.otherAddress,
        otherCity: customer.otherCity,
        otherPostcode: customer.otherPostcode,
        otherState: customer.otherState,
        phone: customer.phone,
        website: customer.website,
      };
    };

    const getCustomerBookingsAndQuotesReport = (
      query: GenerateUpcomingBookingsReportForCustomerQuery
    ) => {
      const filename = `${
        customerModel.customer!.name
      }_BookingsAndQuotesReport_${getTodaySortableDate()}.pdf`;
      return customerModel
        .generateBookingsAndQuotesReport(query)
        .then(blob => saveAs(blob, filename));
    };

    const getCustomerForm = (readonly: boolean): ISectionDef => {
      return {
        dataAddr: 'customer',
        title: isUpdateMode ? 'Manage Customer' : 'Create a Customer',
        panels: [
          {
            panes: [
              {
                paneType: PaneType.formFieldsPane,
                fields: [
                  {
                    fieldType: FieldType.textField,
                    label: 'Name',
                    dataAddr: 'name',
                    maxLength: 200,
                    mandatory: true,
                    validateAsync: async d => {
                      if (
                        !d.fieldValue ||
                        (isUpdateMode &&
                          customerModel.customer &&
                          customerModel.customer.name.toUpperCase() === d.fieldValue.toUpperCase())
                      ) {
                        return undefined;
                      }
                      const result = await customerModel.checkForUniqueName(d.fieldValue);
                      return result.nameExists ? `Name is already in use` : undefined;
                    },
                  },
                ],
              },
              {
                paneType: PaneType.formFieldsPane,
                columnCount: 2,
                fields: [
                  {
                    fieldType: FieldType.yesNoField,
                    label: 'Does this customer have a trading account?',
                    dataAddr: 'hasTradingAccount',
                    mandatory: true,
                  },
                ],
              },
            ],
          },
          {
            title: 'Organisation Contact Details',
            panes: [
              {
                paneType: PaneType.formFieldsPane,
                columnCount: 2,
                fields: [
                  {
                    fieldType: FieldType.textField,
                    label: 'Phone',
                    dataAddr: 'phone',
                    maxLength: 20,
                    mandatory: true,
                    onChange: api =>
                      api.setFormValue(
                        api.fieldDataAddr,
                        standardTextSanitisers.numericSanitiser(api.fieldData.fieldValue as string)
                      ),
                  },
                  {
                    fieldType: FieldType.textField,
                    label: 'Fax',
                    dataAddr: 'fax',
                    maxLength: 20,
                    onChange: api =>
                      api.setFormValue(
                        api.fieldDataAddr,
                        standardTextSanitisers.numericSanitiser(api.fieldData.fieldValue as string)
                      ),
                  },
                ],
              },
              {
                paneType: PaneType.formFieldsPane,
                fields: [
                  {
                    fieldType: FieldType.textField,
                    label: 'Email',
                    dataAddr: 'email',
                    maxLength: 200,
                    mandatory: true,
                    validate: email => {
                      const value = email.fieldValue;
                      if (!value) {
                        return undefined;
                      }
                      const isSingleAddress = value.indexOf(';') < 0;
                      if (isSingleAddress && !EmailValidator.validate(value)) {
                        return `Email must be a valid email`;
                      }
                      if (
                        !isSingleAddress &&
                        !value.split(';').every(v => EmailValidator.validate(v))
                      ) {
                        return `All addresses must be a valid email`;
                      }
                      return undefined;
                    },
                  },
                  {
                    fieldType: FieldType.textField,
                    label: 'Website',
                    dataAddr: 'website',
                    maxLength: 200,
                  },
                ],
              },
            ],
          },
          {
            title: 'Billing address',
            panes: [
              {
                paneType: PaneType.formFieldsPane,
                fields: [
                  {
                    fieldType: FieldType.textField,
                    label: 'Address',
                    dataAddr: 'billingAddress',
                    maxLength: 200,
                    mandatory: d => d.parentValue.hasTradingAccount,
                  },
                ],
              },
              {
                paneType: PaneType.formFieldsPane,
                columnCount: 3,
                fields: [
                  {
                    fieldType: FieldType.textField,
                    label: 'City',
                    dataAddr: 'billingCity',
                    maxLength: 200,
                    mandatory: d => d.parentValue.hasTradingAccount,
                  },
                  {
                    fieldType: FieldType.textField,
                    label: 'State',
                    dataAddr: 'billingState',
                    maxLength: 200,
                    mandatory: d => d.parentValue.hasTradingAccount,
                  },
                  {
                    fieldType: FieldType.textField,
                    label: 'Postcode',
                    dataAddr: 'billingPostcode',
                    maxLength: 4,
                    mandatory: d => d.parentValue.hasTradingAccount,
                    onChange: api =>
                      api.setFormValue(
                        api.fieldDataAddr,
                        standardTextSanitisers.numericSanitiser(api.fieldData.fieldValue as string)
                      ),
                  },
                ],
              },
            ],
          },
          {
            title: 'Other address',
            panes: [
              {
                paneType: PaneType.formFieldsPane,
                fields: [
                  {
                    fieldType: FieldType.textField,
                    label: 'Address',
                    dataAddr: 'otherAddress',
                    maxLength: 200,
                  },
                ],
              },
              {
                paneType: PaneType.formFieldsPane,
                columnCount: 3,
                fields: [
                  {
                    fieldType: FieldType.textField,
                    label: 'City',
                    dataAddr: 'otherCity',
                    maxLength: 200,
                  },
                  {
                    fieldType: FieldType.textField,
                    label: 'State',
                    dataAddr: 'otherState',
                    maxLength: 200,
                  },
                  {
                    fieldType: FieldType.textField,
                    label: 'Postcode',
                    dataAddr: 'otherPostcode',
                    maxLength: 4,
                    onChange: api =>
                      api.setFormValue(
                        api.fieldDataAddr,
                        standardTextSanitisers.numericSanitiser(api.fieldData.fieldValue as string)
                      ),
                  },
                ],
              },
            ],
          },
        ],
        primaryActions: [
          {
            actions: [
              {
                actionType: ActionType.actionCollection,
                hidden: !readonly,
                actionGroups: [
                  {
                    actions: [
                      {
                        actionType: ActionType.modalActionButton,
                        label: 'Generate Bookings and Quotes Report',
                        modalSize: ShellModalSize.oneQuarter,
                        icon: <FilePdfIcon />,
                        modalDef: _ => ({
                          title: 'Generate Bookings and Quotes Report',
                          asForm: true,
                          explicitData: {
                            customerId: customerId,
                            from: undefined,
                            to: undefined,
                            status: [],
                          },
                          onFormSubmit: getCustomerBookingsAndQuotesReport,
                          secondaryActions: [getSubmitCloseModalActionGroupDef('Generate')],
                          panels: [
                            {
                              panes: [
                                {
                                  paneType: PaneType.formFieldsPane,
                                  columnCount: 2,
                                  fields: [
                                    {
                                      fieldType: FieldType.dateField,
                                      dataAddr: 'from',
                                      label: 'From',
                                      mandatory: true,
                                    },
                                    {
                                      fieldType: FieldType.dateField,
                                      dataAddr: 'to',
                                      label: 'To',
                                      mandatory: true,
                                    },
                                  ],
                                },
                                {
                                  paneType: PaneType.formFieldsPane,
                                  fields: [
                                    {
                                      fieldType: FieldType.selectMultiField,
                                      dataAddr: 'status',
                                      label: 'Status',
                                      optionItems: allQuoteStatus,
                                      valueKey: 'value',
                                      descriptionKey: 'description',
                                      mandatory: true,
                                      useValueOnly: true,
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        }),
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            actions: [
              {
                actionType: ActionType.modalActionButton,
                label: 'Delete customer',
                icon: <TrashIcon />,
                hidden: !(
                  customerModel.customer &&
                  customerModel.customer.canBeDeleted &&
                  isUpdateMode
                ),
                modalSize: ShellModalSize.oneThird,
                modalDef: _ => ({
                  title: 'Delete Customer',
                  asForm: true,
                  panels: [
                    {
                      panes: [
                        {
                          paneType: PaneType.customPane,
                          render: _ => <span>Are you sure you want to delete this customer?</span>,
                        },
                      ],
                    },
                  ],
                  secondaryActions: [getSubmitCloseModalActionGroupDef('Ok')],
                  onFormSubmit: _ =>
                    customerModel.deleteCustomer({
                      customerId,
                    }),
                }),
              },
            ],
          },
        ],
        onFormPreSubmit: isUpdateMode ? handlePreSubmitForUpdate : handlePreSubmitForCreate,
        onFormSubmit: isUpdateMode ? customerModel.editCustomer : handleSubmitForCreate,
      };
    };

    const getCreateContactModal = (): ((modalDefApi: IModalDefBuilderApi) => ISectionDef) => {
      return _ => ({
        title: 'Create Contact',
        asForm: true,
        panels: [
          {
            panes: [
              {
                paneType: PaneType.formFieldsPane,
                fields: [
                  {
                    fieldType: FieldType.textField,
                    label: 'Title',
                    dataAddr: 'contactTitle',
                    maxLength: 20,
                  },
                  {
                    fieldType: FieldType.textField,
                    label: 'First Name',
                    dataAddr: 'contactFirstName',
                    maxLength: 200,
                    mandatory: true,
                  },
                  {
                    fieldType: FieldType.textField,
                    label: 'Last Name',
                    dataAddr: 'contactLastName',
                    maxLength: 200,
                  },
                  {
                    fieldType: FieldType.textField,
                    label: 'Phone',
                    dataAddr: 'contactPhone',
                    maxLength: 20,
                    onChange: api =>
                      api.setFormValue(
                        api.fieldDataAddr,
                        standardTextSanitisers.numericSanitiser(api.fieldData.fieldValue as string)
                      ),
                  },
                  {
                    fieldType: FieldType.textField,
                    label: 'Fax',
                    dataAddr: 'contactFax',
                    maxLength: 20,
                    onChange: api =>
                      api.setFormValue(
                        api.fieldDataAddr,
                        standardTextSanitisers.numericSanitiser(api.fieldData.fieldValue as string)
                      ),
                  },
                  {
                    fieldType: FieldType.textField,
                    label: 'Mobile',
                    dataAddr: 'contactMobile',
                    maxLength: 20,
                    onChange: api =>
                      api.setFormValue(
                        api.fieldDataAddr,
                        standardTextSanitisers.numericSanitiser(api.fieldData.fieldValue as string)
                      ),
                  },
                  {
                    fieldType: FieldType.textField,
                    label: 'Email',
                    dataAddr: 'contactEmail',
                    maxLength: 200,
                    validate: email =>
                      !!email.fieldValue && !EmailValidator.validate(email.fieldValue)
                        ? `Email must be a valid email`
                        : undefined,
                  },
                ],
              },
            ],
          },
        ],
        secondaryActions: [getSubmitCloseModalActionGroupDef('Ok')],
        onFormSubmit: v =>
          customerModel
            .createContact({
              customerId: customerId,
              email: v.contactEmail,
              fax: v.contactFax,
              firstName: v.contactFirstName,
              lastName: v.contactLastName,
              mobile: v.contactMobile,
              phone: v.contactPhone,
              title: v.contactTitle,
            } as Operations.Domain.Commands.Customer.CreateCustomerContactCommand)
            .then(() => Promise.resolve()),
      });
    };

    const getUpdateContactModalData = (modalDefApi: IModalDefBuilderApi) => {
      return {
        contactId: modalDefApi.actionData.actionValue.id,
        contactTitle: modalDefApi.actionData.actionValue.title,
        contactFirstName: modalDefApi.actionData.actionValue.firstName,
        contactLastName: modalDefApi.actionData.actionValue.lastName,
        contactPhone: modalDefApi.actionData.actionValue.phone,
        contactFax: modalDefApi.actionData.actionValue.fax,
        contactMobile: modalDefApi.actionData.actionValue.mobile,
        contactEmail: modalDefApi.actionData.actionValue.email,
      };
    };

    const getContactsTableDef = (canManageCustomers: boolean): ITablePaneDef => {
      return {
        paneType: PaneType.tablePane,
        dataAddr: ['customer', 'contacts'],
        neverEditable: true,
        fields: [
          {
            fieldType: FieldType.textField,
            label: 'First Name',
            dataAddr: 'firstName',
          },
          {
            fieldType: FieldType.textField,
            label: 'Last Name',
            dataAddr: 'lastName',
          },
          {
            fieldType: FieldType.textField,
            label: 'Email',
            dataAddr: 'email',
          },
          {
            fieldType: FieldType.textField,
            label: 'Mobile',
            dataAddr: 'mobile',
          },
          {
            fieldType: FieldType.textField,
            label: 'Phone',
            dataAddr: 'phone',
          },
          {
            hidden: () => !canManageCustomers,
            fieldType: FieldType.actionListField,
            label: 'Actions',
            actionGroups: [
              {
                actions: [
                  {
                    hidden: d => !d.parentValue.canBeDeleted,
                    actionType: ActionType.modalActionButton,
                    label: 'Delete Contact',
                    icon: <TrashIcon />,
                    modalSize: ShellModalSize.oneThird,
                    modalDef: modalDefApi => ({
                      title: d =>
                        `Delete Contact for ${d.sectionValue.firstName} ${d.sectionValue.lastName}`,
                      asForm: true,
                      panels: [
                        {
                          panes: [
                            {
                              paneType: PaneType.customPane,
                              render: api => <p>Are you sure you want to delete this contact?</p>,
                            },
                          ],
                        },
                      ],
                      secondaryActions: [getSubmitCloseModalActionGroupDef('Ok')],
                      onFormSubmit: d =>
                        customerModel.deleteContact({
                          customerId: customerId,
                          contactId: d.id,
                        }),
                    }),
                  },
                  {
                    actionType: ActionType.modalActionButton,
                    label: 'Update Contact',
                    icon: <EditIcon />,
                    modalSize: ShellModalSize.half,
                    modalDef: modalDefApi => ({
                      title: 'Update Contact',
                      asForm: true,
                      explicitData: getUpdateContactModalData(modalDefApi),
                      panels: [
                        {
                          panes: [
                            {
                              paneType: PaneType.formFieldsPane,
                              fields: [
                                {
                                  fieldType: FieldType.textField,
                                  label: 'Title',
                                  dataAddr: 'contactTitle',
                                  maxLength: 20,
                                },
                                {
                                  fieldType: FieldType.textField,
                                  label: 'First Name',
                                  dataAddr: 'contactFirstName',
                                  maxLength: 200,
                                  mandatory: true,
                                },
                                {
                                  fieldType: FieldType.textField,
                                  label: 'Last Name',
                                  dataAddr: 'contactLastName',
                                  maxLength: 200,
                                },
                                {
                                  fieldType: FieldType.textField,
                                  label: 'Phone',
                                  dataAddr: 'contactPhone',
                                  maxLength: 20,
                                  onChange: api =>
                                    api.setFormValue(
                                      api.fieldDataAddr,
                                      standardTextSanitisers.numericSanitiser(
                                        api.fieldData.fieldValue as string
                                      )
                                    ),
                                },
                                {
                                  fieldType: FieldType.textField,
                                  label: 'Fax',
                                  dataAddr: 'contactFax',
                                  maxLength: 20,
                                  onChange: api =>
                                    api.setFormValue(
                                      api.fieldDataAddr,
                                      standardTextSanitisers.numericSanitiser(
                                        api.fieldData.fieldValue as string
                                      )
                                    ),
                                },
                                {
                                  fieldType: FieldType.textField,
                                  label: 'Mobile',
                                  dataAddr: 'contactMobile',
                                  maxLength: 20,
                                  onChange: api =>
                                    api.setFormValue(
                                      api.fieldDataAddr,
                                      standardTextSanitisers.numericSanitiser(
                                        api.fieldData.fieldValue as string
                                      )
                                    ),
                                },
                                {
                                  fieldType: FieldType.textField,
                                  label: 'Email',
                                  dataAddr: 'contactEmail',
                                  maxLength: 200,
                                  validate: email =>
                                    !!email.fieldValue && !EmailValidator.validate(email.fieldValue)
                                      ? `Email must be a valid email`
                                      : undefined,
                                },
                              ],
                            },
                          ],
                        },
                      ],
                      secondaryActions: [getSubmitCloseModalActionGroupDef('Ok')],
                      onFormSubmit: v => {
                        return customerModel.editContact({
                          customerId: customerId,
                          contactId: v.contactId,
                          email: v.contactEmail,
                          fax: v.contactFax,
                          firstName: v.contactFirstName,
                          lastName: v.contactLastName,
                          mobile: v.contactMobile,
                          phone: v.contactPhone,
                          title: v.contactTitle,
                        } as Operations.Domain.Commands.Customer.UpdateCustomerContactCommand);
                      },
                    }),
                  },
                ],
              },
            ],
          },
        ],
      };
    };

    const getQuotesForCustomerPane = (): ITablePaneDef => {
      return {
        paneType: PaneType.tablePane,
        dataAddr: 'quotesForCustomer',
        neverEditable: true,
        fields: [
          {
            fieldType: FieldType.textField,
            label: 'Quote Number',
            dataAddr: 'quoteNumber',
            linkTo: d => `/sales/quotes/${d.parentValue.quoteId}`,
            minColumnWidth: '10rem',
          },
          {
            fieldType: FieldType.dateField,
            label: 'First Trip',
            dataAddr: 'firstTrip',
            minColumnWidth: '10rem',
          },
          {
            fieldType: FieldType.textField,
            label: 'Description',
            dataAddr: 'description',
          },
          {
            fieldType: FieldType.textField,
            label: 'Status',
            dataAddr: ['quoteStatus', 'description'],
          },
        ],
      };
    };

    const getRailBookingsForCustomerPane = (): ITablePaneDef => {
      return {
        paneType: PaneType.tablePane,
        dataAddr: 'railBookingsForCustomer',
        neverEditable: true,
        fields: [
          {
            fieldType: FieldType.textField,
            label: 'Booking Number',
            dataAddr: 'bookingNumber',
            linkTo: d => `/operations/rail/rail-bookings/${d.parentValue.id}`,
            minColumnWidth: '10rem',
          },
          {
            fieldType: FieldType.dateField,
            label: 'First Job',
            dataAddr: 'firstJobDate',
            minColumnWidth: '10rem',
          },
          {
            fieldType: FieldType.textField,
            label: 'Description',
            dataAddr: 'description',
          },
          {
            fieldType: FieldType.textField,
            label: 'Status',
            dataAddr: 'status',
            formatReadonly: d =>
              d.fieldValue
                ? getRailBookingStatusDescriptor((d.fieldValue as unknown) as RailBookingStatus)
                    .description
                : '',
          },
        ],
      };
    };

    const getContractBookingsForCustomerPane = (): ITablePaneDef => {
      return {
        paneType: PaneType.tablePane,
        dataAddr: 'recentContractBookingsForCustomer',
        neverEditable: true,
        fields: [
          {
            fieldType: FieldType.textField,
            label: 'Booking Number',
            dataAddr: 'bookingNumber',
            linkTo: d => `/accounting/contract-charter-bookings/${d.parentValue.id}`,
            minColumnWidth: '10rem',
          },
          {
            fieldType: FieldType.dateField,
            label: 'First Job',
            dataAddr: 'firstJobDate',
            minColumnWidth: '10rem',
          },
          {
            fieldType: FieldType.textField,
            label: 'Description',
            dataAddr: 'description',
          },
          {
            fieldType: FieldType.textField,
            label: 'Completed',
            dataAddr: 'isComplete',
            formatReadonly: d => d.fieldValue && <CheckIcon />,
          },
        ],
      };
    };

    const getBookingsForCustomerPane = (): ITablePaneDef => {
      return {
        paneType: PaneType.tablePane,
        dataAddr: 'bookingsForCustomer',
        neverEditable: true,
        fields: [
          {
            fieldType: FieldType.textField,
            label: 'Booking Number',
            dataAddr: 'quoteNumber',
            linkTo: d => `/sales/bookings/${d.parentValue.quoteId}`,
            minColumnWidth: '10rem',
          },
          {
            fieldType: FieldType.dateField,
            label: 'First Trip',
            dataAddr: 'firstTrip',
            minColumnWidth: '10rem',
          },
          {
            fieldType: FieldType.textField,
            label: 'Description',
            dataAddr: 'description',
          },
          {
            fieldType: FieldType.textField,
            label: 'Status',
            dataAddr: ['quoteStatus', 'description'],
          },
        ],
      };
    };

    const getNoteModalDef = (
      mode: 'add' | 'edit'
    ): ((modalDefApi: IModalDefBuilderApi) => ISectionDef) => {
      return modalDefApi => ({
        title: mode === 'add' ? 'Add Note' : 'Edit Note',
        asForm: true,
        explicitData: mode === 'add' ? {} : modalDefApi.actionData.paneValue,
        panels: [
          {
            panes: [
              {
                paneType: PaneType.formFieldsPane,
                fields: [
                  {
                    fieldType: FieldType.textAreaField,
                    mandatory: true,
                    label: 'Details',
                    dataAddr: 'details',
                  },
                ],
              },
            ],
          },
        ],
        secondaryActions: [getSubmitCloseModalActionGroupDef('Ok')],
        onFormPreSubmit: (
          note: CustomerNote
        ): CreateCustomerNoteCommand | UpdateCustomerNoteCommand => {
          return mode === 'add'
            ? {
                customerId: customerId,
                details: note.details,
              }
            : {
                noteId: note.id,
                customerId: customerId,
                details: note.details,
              };
        },
        onFormSubmit:
          mode === 'add' ? customerModel.createCustomerNote : customerModel.updateCustomerNote,
      });
    };

    const getContractsForCustomerPane = (): ITablePaneDef => {
      return {
        paneType: PaneType.tablePane,
        dataAddr: 'charterContractsByCustomer',
        neverEditable: true,
        fields: [
          {
            fieldType: FieldType.textField,
            label: 'Name',
            dataAddr: 'name',
            linkTo: d => `/operations/charter-contracts/${d.parentValue.id}`,
          },
          {
            fieldType: FieldType.textField,
            label: 'Description',
            dataAddr: 'description',
          },
        ],
      };
    };

    const getPageDef = (canManageCustomers: boolean, readonly: boolean): ICrudPageDef => {
      return {
        primarySize: PagePrimarySize.half,
        primarySection: getCustomerForm(readonly),
        secondarySections: isUpdateMode
          ? [
              {
                title: 'Contacts',
                panels: [
                  {
                    panes: [getContactsTableDef(canManageCustomers)],
                  },
                ],
                secondaryActions: [
                  {
                    actions: [
                      {
                        hidden: () => !canManageCustomers,
                        actionType: ActionType.modalActionButton,
                        label: 'Add Contact',
                        icon: <PlusIcon />,
                        modalSize: ShellModalSize.half,
                        modalDef: getCreateContactModal(),
                      },
                    ],
                  },
                ],
              },
              {
                title: 'Quotes',
                panels: [
                  {
                    panes: [getQuotesForCustomerPane()],
                  },
                ],
              },
              {
                title: 'Bookings',
                panels: [
                  {
                    panes: [
                      getBookingsForCustomerPane(),
                      {
                        paneType: PaneType.actionListPane,
                        actionGroups: [
                          {
                            actions: [
                              {
                                hidden: () => !canManageCustomers,
                                actionType: ActionType.actionLink,
                                label: 'View all Bookings',
                                icon: <ExternalLinkIcon />,
                                to: `/sales/quotes?customerId=${customerId}&status=${
                                  QuoteStatus.Booked
                                }&status=${QuoteStatus.Cancelled}&status=${
                                  QuoteStatus.Completed
                                }&status=${
                                  QuoteStatus.CancelledInProgress
                                }&tripTo=${getTodayISODate()}`,
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
              {
                title: 'Rail Bookings',
                hidden: !railBookingsForCustomer || railBookingsForCustomer.length === 0,
                panels: [
                  {
                    panes: [
                      getRailBookingsForCustomerPane(),
                      {
                        paneType: PaneType.actionListPane,
                        actionGroups: [
                          {
                            actions: [
                              {
                                hidden: () => !canManageCustomers,
                                actionType: ActionType.actionLink,
                                label: 'View all Rail Bookings',
                                icon: <ExternalLinkIcon />,
                                to: `/operations/rail/rail-bookings?customerId=${customerId}&statuses=${
                                  RailBookingStatus.Booked
                                }&to=${getTodayISODate()}`,
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
              {
                title: 'Notes',
                panels: [
                  {
                    panes: [
                      {
                        paneType: PaneType.repeatingPane,
                        dataAddr: ['customer', 'notes'],
                        itemPanes: [
                          {
                            paneType: PaneType.customPane,
                            render: api => {
                              const note = api.data.paneValue as CustomerNote;
                              return (
                                <div className={styles.customerNotes}>
                                  <div className={styles.author}>{note.lastModifiedBy}</div>
                                  <div className={styles.date}>
                                    {formatDateTimeMed(note.lastModifiedOn)}
                                  </div>
                                  {canManageCustomers && (
                                    <div className={styles.edit}>
                                      <ActionBar
                                        actionGroupDefs={[
                                          {
                                            actions: [
                                              {
                                                actionType: ActionType.modalActionButton,
                                                label: 'Edit',
                                                hidden: !readonly,
                                                icon: <EditIcon size="sm" />,
                                                modalSize: ShellModalSize.oneThird,
                                                modalDef: getNoteModalDef('edit'),
                                              },
                                              {
                                                actionType: ActionType.modalActionButton,
                                                label: 'Delete',
                                                hidden: !readonly,
                                                icon: <TrashIcon size="sm" />,
                                                modalSize: ShellModalSize.oneThird,
                                                modalDef: modalDefApi => ({
                                                  title: _ => 'Delete Note',
                                                  asForm: true,
                                                  panels: [
                                                    {
                                                      panes: [
                                                        {
                                                          paneType: PaneType.customPane,
                                                          render: _ => (
                                                            <p>
                                                              Are you sure you want to delete this
                                                              note?
                                                            </p>
                                                          ),
                                                        },
                                                      ],
                                                    },
                                                  ],
                                                  secondaryActions: [
                                                    getSubmitCloseModalActionGroupDef('Ok'),
                                                  ],
                                                  onFormSubmit: _ =>
                                                    customerModel.deleteCustomerNote(
                                                      modalDefApi.actionData.parentValue.customerId,
                                                      modalDefApi.actionData.parentValue.id
                                                    ),
                                                }),
                                              },
                                            ],
                                          },
                                        ]}
                                        actionMeta={{
                                          formSubmitting: false,
                                          hideLabel: true,
                                          borderless: true,
                                        }}
                                        actionData={{ ...api.data, actionValue: undefined }}
                                      />
                                    </div>
                                  )}
                                  <div className={styles.details}>{note.details}</div>
                                </div>
                              );
                            },
                          },
                        ],
                      },
                    ],
                  },
                ],
                secondaryActions: canManageCustomers
                  ? [
                      {
                        actions: [
                          {
                            actionType: ActionType.modalActionButton,
                            label: 'Add Note',
                            hidden: !readonly,
                            icon: <PlusIcon />,
                            modalSize: ShellModalSize.oneThird,
                            modalDef: getNoteModalDef('add'),
                          },
                        ],
                      },
                    ]
                  : [],
              },
              {
                title: 'Contracts',
                hidden: !charterContractsByCustomer || charterContractsByCustomer.length === 0,
                panels: [
                  {
                    panes: [getContractsForCustomerPane()],
                  },
                ],
              },
              {
                title: 'Contract Bookings',
                hidden:
                  !recentContractBookingsForCustomer ||
                  recentContractBookingsForCustomer.length === 0,
                panels: [
                  {
                    panes: [
                      getContractBookingsForCustomerPane(),
                      {
                        paneType: PaneType.actionListPane,
                        actionGroups: [
                          {
                            actions: [
                              {
                                hidden: () => !canManageCustomers,
                                actionType: ActionType.actionLink,
                                label: 'View all Contract Bookings',
                                icon: <ExternalLinkIcon />,
                                to: `/accounting/contract-charter-bookings?customerIds=${customerId}&orderBy=BookingNumber~desc`,
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ]
          : [],
      };
    };

    const customer = customerModel.customer;
    const quotesForCustomer = quotesModel.quotesForCustomer;
    const bookingsForCustomer = quotesModel.bookingsForCustomer;
    const railBookingsForCustomer = quotesModel.railBookingsForCustomer;
    const charterContractsByCustomer = contractsModel.charterContractsByCustomer
      .slice()
      .sort((a, b) => a.name.localeCompare(b.name));
    const recentContractBookingsForCustomer = contractBookingsModel.recentContractCharterBookingsForCustomer.slice();

    return (
      <CrudPage
        def={api => getPageDef(canManageCustomers, api.readonly)}
        mode={props.mode}
        isEditingForbidden={!canManageCustomers}
        data={{
          customer,
          quotesForCustomer,
          bookingsForCustomer,
          railBookingsForCustomer,
          charterContractsByCustomer,
          recentContractBookingsForCustomer,
        }}
        createDefaultData={{}}
      />
    );
  }
);

export default MaintainCustomer;
