import { IAjax } from 'src/infrastructure/ajax';
import { operationsUrls } from 'src/domain/services/apiUrls';

type ListOperationsDiaryEntriesQuery = Operations.Domain.Queries.ListOperationsDiaryEntries.ListOperationsDiaryEntriesQuery;
type OperationsDiaryEntry = Operations.Domain.Queries.ListOperationsDiaryEntries.OperationsDiaryEntryDto;
type OperationsDiaryEntrySubject = Operations.Domain.Queries.ListOperationsDiaryEntries.OperationsDiaryEntrySubjectDto;
type CreateOperationsDiaryEntryCommand = Operations.Domain.Commands.OperationsDiaryEntry.CreateOperationsDiaryEntry.CreateOperationsDiaryEntryCommand;
type UpdateOperationsDiaryEntryCommand = Operations.Domain.Commands.OperationsDiaryEntry.UpdateOperationsDiaryEntry.UpdateOperationsDiaryEntryCommand;
type UpdateOperationsDiaryEntrySubjectCommand = Operations.Domain.Commands.OperationsDiaryEntrySubject.UpdateOperationsDiaryEntrySubjectCommand;

export default function init(ajax: IAjax) {
  function listOperationsDiaryEntries(query: Partial<ListOperationsDiaryEntriesQuery>) {
    return ajax
      .get(operationsUrls.operationsDiaryUrls.listOperationsDiaryEntries(query))
      .map(r => r.response as Common.Dtos.ListResult<OperationsDiaryEntry>);
  }

  function createOperationsDiaryEntry(command: CreateOperationsDiaryEntryCommand) {
    return ajax
      .post(operationsUrls.operationsDiaryUrls.createOperationsDiaryEntry, command)
      .toPromise()
      .then<string>(r => r.response);
  }

  function updateOperationsDiaryEntry(command: UpdateOperationsDiaryEntryCommand) {
    return ajax
      .put(operationsUrls.operationsDiaryUrls.updateOperationsDiaryEntry(command.id), command)
      .toPromise();
  }

  function deleteOperationsDiaryEntry(id: string) {
    return ajax
      .httpDelete(operationsUrls.operationsDiaryUrls.deleteOperationsDiaryEntry(id))
      .toPromise();
  }

  function viewOperationsDiaryEntry(id: string) {
    return ajax
      .get(operationsUrls.operationsDiaryUrls.viewOperationsDiaryEntry(id))
      .toPromise()
      .then<OperationsDiaryEntry>(r => r.response);
  }

  function loadAllOperationsDiaryEntrySubjects() {
    return ajax
      .get(operationsUrls.operationsDiaryUrls.loadAllOperationsDiaryEntrySubjects)
      .toPromise()
      .then<OperationsDiaryEntrySubject[]>(r => r.response);
  }

  function updateAllOperationsDiaryEntrySubjects(
    command: UpdateOperationsDiaryEntrySubjectCommand
  ) {
    return ajax
      .put(operationsUrls.operationsDiaryUrls.updateAllOperationsDiaryEntrySubjects, command)
      .toPromise();
  }

  function acknowledgeOperationsDiaryEntry(id: string) {
    return ajax
      .post(operationsUrls.operationsDiaryUrls.acknowledgeOperationsDiaryEntry(id))
      .toPromise();
  }

  return {
    operationsDiary: {
      listOperationsDiaryEntries,
      createOperationsDiaryEntry,
      updateOperationsDiaryEntry,
      deleteOperationsDiaryEntry,
      viewOperationsDiaryEntry,
      loadAllOperationsDiaryEntrySubjects,
      updateAllOperationsDiaryEntrySubjects,
      acknowledgeOperationsDiaryEntry,
    },
  };
}
