import './ShellNav.scss';
import { NavLink, Link, RouteComponentProps, withRouter, matchPath } from 'react-router-dom';
import { IShellNavItem } from 'src/views/shellNavItems';
import {
  CardBody,
  Card,
  CardTitle,
  Button,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from 'reactstrap';
import Collapse from 'reactstrap/lib/Collapse';
import { ChevronDoubleLeft, ChevronDownIcon } from 'src/images/icons';
import CompanyLogo from '../CompanyLogo/CompanyLogo';
import { useEffect, useRef, useState } from 'react';
import ShellProfile from './ShellProfile';
import { IAccountModel } from 'src/domain/entities/user/AccountModel';
import { IS_PRODUCTION } from 'src/appSettings';

interface IShellNavProps {
  className?: string;
  items?: Array<IShellNavItem>;
  isAuthenticated: boolean | undefined;
  userAccount: IAccountModel;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  onSignOut: () => void;
}

type ShellNavRouteComponentProps = IShellNavProps & RouteComponentProps<{}>;

const determineIfActive = (navItem: IShellNavItem, level: number): boolean => {
  if (!navItem || !navItem.to) {
    return false;
  }
  const toAsString = navItem.to!.toString();
  const pathNameOnlyMatch = matchPath(location.pathname, {
    path: toAsString,
    exact: false,
    strict: false,
  });

  const noSearch = toAsString.replace('?defaultFilter=true', '');
  const isCollapsableSublink = navItem.navItems && navItem.navItems.length > 0 && level > 1;
  const noSearchMatch = isCollapsableSublink
    ? null
    : matchPath(location.pathname, {
        path: noSearch,
        exact: false,
        strict: false,
      });

  const searchMatch = matchPath(location.pathname + location.search, {
    path: toAsString,
    exact: false,
    strict: false,
  });

  return !!pathNameOnlyMatch || !!noSearchMatch || !!searchMatch;
};

function getNavList(level: number, items?: Array<IShellNavItem>): React.ReactNode {
  if (!items) {
    return null;
  }
  return (
    <ul className={`nav-level-${level}`}>
      {items.map((ni, i) => (
        <li key={i}>
          {ni.to ? (
            <NavLink
              className="nav-item"
              isActive={() => determineIfActive(ni, level)}
              to={ni.to}
              target={ni.newWindow ? '_blank' : ''}>
              {ni.label}
            </NavLink>
          ) : (
            <span className="nav-item no-link">{ni.label}</span>
          )}
          {getNavList(level + 1, ni.navItems)}
        </li>
      ))}
    </ul>
  );
}

function cardCollapser(items: Array<IShellNavItem>, collapse: boolean): React.ReactNode {
  return (
    <Collapse isOpen={collapse}>
      <nav>{getNavList(1, items)}</nav>
    </Collapse>
  );
}

const ShellNav: React.FC<ShellNavRouteComponentProps> = (props: ShellNavRouteComponentProps) => {
  const [cards, setCards] = useState({});
  const prevLocation = useRef(props.location);

  const toggle = (key: number) => {
    let status = !cards[key];
    setCards({ ...cards, [key]: status });
  };

  const isCurrentLocationPartOfSection = (ni: IShellNavItem): boolean => {
    const currentPathname = location.pathname;
    const navItemPathname = ni.to && (ni.to as string).split(/[?#]/)[0];
    if (currentPathname === navItemPathname) {
      return true;
    }
    if (ni.navItems && ni.navItems.length) {
      return ni.navItems.some(subItem => isCurrentLocationPartOfSection(subItem));
    }
    return false;
  };

  const shouldSectionBeExpanded = (ni: IShellNavItem) => {
    const urlParams = new URLSearchParams(location.search);
    const expandCurrentSection = urlParams.get('expand') === 'true';
    if (!expandCurrentSection) {
      return false;
    }
    return isCurrentLocationPartOfSection(ni);
  };

  const onRouteChanged = () => {
    if (props.items) {
      let cards: { [key: number]: boolean } = {};
      let refreshMenu = false;
      props.items.forEach((ni: IShellNavItem, key: number) => {
        const shouldBeExpanded = shouldSectionBeExpanded(ni);
        if (shouldBeExpanded && cards[key] !== shouldBeExpanded) {
          refreshMenu = true;
          cards[key] = true;
        }
      });
      if (refreshMenu) {
        setCards({ ...cards, cards });
      }
    }
  };

  useEffect(() => {
    if (props.items) {
      let cards: { [key: number]: boolean } = {};
      props.items.forEach((ni: IShellNavItem, key: number) => {
        cards[key] = shouldSectionBeExpanded(ni);
      });
      setCards(cards);
    }
  }, []);

  useEffect(() => {
    if (props.location === prevLocation.current) return;

    onRouteChanged();
  }, [props.location]);

  return (
    <section
      className={`shell-nav-component nav-section ${props.className} ${!IS_PRODUCTION &&
        'non-production'} ${props.isOpen ? 'open' : 'closed'}`}>
      <div className="main-navigation">
        <div className="logo-header">
          <Link to="/">
            <CompanyLogo mode="kiosk" className="logo" />
          </Link>
        </div>
        {props.items &&
          props.items.map((ni: IShellNavItem, key: number) => (
            <ul key={key}>
              <li className={determineIfActive(ni, 1) ? 'active' : ''}>
                {ni.to ? (
                  <Card>
                    {props.isOpen ? (
                      <>
                        <CardTitle className={determineIfActive(ni, 1) ? 'active' : ''}>
                          <NavLink
                            className="nav-item"
                            to={ni.to}
                            target={ni.newWindow ? '_blank' : ''}>
                            {ni.icon}
                            <span>{ni.label}</span>
                          </NavLink>
                          <div className="individual-card">
                            {ni.navItems && (
                              <span className={`${cards[key] === true ? 'open' : 'closed'}`}>
                                <div className="chev-icon" onClick={() => toggle(key)}>
                                  <ChevronDownIcon />
                                </div>
                              </span>
                            )}
                          </div>
                        </CardTitle>

                        <CardBody>{ni.navItems && cardCollapser(ni.navItems, cards[key])}</CardBody>
                      </>
                    ) : (
                      <>
                        <UncontrolledDropdown className="nav-dropdown" direction="right">
                          <CardTitle className={determineIfActive(ni, 1) ? 'active' : ''}>
                            <DropdownToggle className="nav-item" color="primary">
                              {ni.icon}
                            </DropdownToggle>
                            <DropdownMenu positionFixed={true}>
                              <DropdownItem className="main-nav-item">
                                <NavLink
                                  className="nav-item"
                                  to={ni.to}
                                  target={ni.newWindow ? '_blank' : ''}>
                                  {ni.label}
                                </NavLink>
                              </DropdownItem>
                              {ni.navItems &&
                                ni.navItems.map((item: IShellNavItem) => (
                                  <div className="nav-level-1">
                                    <DropdownItem>
                                      {item.to ? (
                                        <NavLink
                                          className="nav-item"
                                          isActive={() => determineIfActive(item, 1)}
                                          to={item.to}
                                          target={item.newWindow ? '_blank' : ''}>
                                          {item.label}
                                        </NavLink>
                                      ) : (
                                        <span className="nav-item">{ni.label}</span>
                                      )}
                                    </DropdownItem>

                                    {item.navItems && (
                                      <div className="nav-level-2">
                                        {item.navItems.map((item: IShellNavItem) => (
                                          <DropdownItem>
                                            {item.to ? (
                                              <NavLink
                                                className="nav-item"
                                                isActive={() => determineIfActive(item, 2)}
                                                to={item.to}
                                                target={item.newWindow ? '_blank' : ''}>
                                                {item.label}
                                              </NavLink>
                                            ) : (
                                              <span className="nav-item">{ni.label}</span>
                                            )}
                                          </DropdownItem>
                                        ))}
                                      </div>
                                    )}
                                  </div>
                                ))}
                            </DropdownMenu>
                          </CardTitle>
                        </UncontrolledDropdown>
                      </>
                    )}
                  </Card>
                ) : (
                  <Card>
                    <CardTitle>
                      {ni.label}
                      <div className="individual-card">
                        <span className={`${cards[key]}`}>
                          <div className="chev-icon" onClick={() => toggle(key)}>
                            <ChevronDownIcon />
                          </div>
                        </span>
                      </div>
                    </CardTitle>

                    <CardBody>{ni.navItems && cardCollapser(ni.navItems, cards[key])}</CardBody>
                  </Card>
                )}
              </li>
            </ul>
          ))}
      </div>
      <div className="nav-actions">
        {props.isAuthenticated && (
          <ShellProfile
            className={`shell-profile ${props.isOpen ? 'open' : 'closed'}`}
            userDisplayName={props.userAccount.checkedName}
            userEmail={props.userAccount.email}
            onSignOut={props.onSignOut}
            isNavOpen={props.isOpen}
          />
        )}
      </div>
      <div className="collapse-nav">
        <Button
          color="light"
          onClick={() => props.setIsOpen && props.setIsOpen(!props.isOpen)}
          title="Collapse Nav Bar">
          <ChevronDoubleLeft />
        </Button>
      </div>
    </section>
  );
};

export default withRouter(ShellNav);
