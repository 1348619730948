import styles from './DepotSelector.module.scss';
import cn from 'classnames';
import React from 'react';
import { Button } from 'reactstrap';
import DepotBadge from './DepotBadge';

export interface IDepotButtonProps {
  disabled: boolean;
  depotDescription: string;
  onBeginChangeDepot: () => void;
}

export const DepotButton: React.FC<IDepotButtonProps> = ({
  depotDescription,
  disabled,
  onBeginChangeDepot,
}) => {
  const [changeDepotButtonOpen, setChangeDepotButtonOpen] = React.useState(false);

  const toggleShowChangeDepotButton = (e: React.MouseEvent) => {
    // Close it after a short time
    if (!changeDepotButtonOpen) {
      setTimeout(() => setChangeDepotButtonOpen(false), 3000);
    }

    setChangeDepotButtonOpen(!changeDepotButtonOpen);
  };

  return (
    <div
      className={cn(styles.depotSelector, { [styles.clickable]: !disabled })}
      onClick={disabled ? undefined : toggleShowChangeDepotButton}>
      <DepotBadge depotDescription={depotDescription} />
      <Button
        level="primary"
        className={cn(styles.selectDepot, changeDepotButtonOpen ? styles.open : '')}
        onClick={onBeginChangeDepot}>
        Change Depot
      </Button>
    </div>
  );
};

export default DepotButton;
