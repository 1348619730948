import { IAjax } from 'src/infrastructure/ajax';
import { workshopUrls } from 'src/domain/services/apiUrls';

// tslint:disable-next-line:no-empty
const returnVoid = () => {};

export default function init(ajax: IAjax) {
  function listSuppliers(page: number, size: number, search: string) {
    return ajax
      .get(workshopUrls.supplierUrls.listSuppliers(page, size, search))
      .map(
        r =>
          r.response as Common.Dtos.ListResult<Workshop.Domain.Queries.Suppliers.SupplierListItem>
      );
  }

  function checkForUniqueName(name: string) {
    return ajax
      .get(workshopUrls.supplierUrls.checkForUniqueName(name))
      .toPromise()
      .then<Common.Dtos.UniqueNameCheckResultDto>(r => r.response);
  }

  function createSupplier(command: Workshop.Domain.Commands.Supplier.CreateSupplierCommand) {
    return ajax
      .post(workshopUrls.supplierUrls.createSupplier(), command)
      .toPromise()
      .then<string>(r => r.response);
  }

  function getSupplier(id: string) {
    return ajax
      .get(workshopUrls.supplierUrls.getSupplier(id))
      .toPromise()
      .then<Workshop.Domain.Queries.GetAsset.AssetDetails>(r => r.response);
  }

  function updateSupplier(command: Workshop.Domain.Commands.Supplier.UpdateSupplierCommand) {
    return ajax
      .put(workshopUrls.supplierUrls.updateSupplier(command.id), command)
      .toPromise()
      .then(r => returnVoid());
  }

  function searchSuppliers(search: string) {
    return ajax
      .get(workshopUrls.supplierUrls.searchSuppliers(search))
      .toPromise()
      .then<Common.Dtos.ListResult<Workshop.Domain.Queries.Suppliers.SupplierListItem>>(
        r => r.response
      );
  }

  function findSuppliers(ids: string[]) {
    return ajax
      .get(workshopUrls.supplierUrls.findSuppliers(ids))
      .toPromise()
      .then<Workshop.Domain.Queries.Suppliers.SupplierListItem>(r => r.response);
  }

  function createSupplierContact(
    command: Workshop.Domain.Commands.Supplier.CreateSupplierContactCommand
  ) {
    return ajax
      .post(workshopUrls.supplierUrls.createSupplierContact(command.supplierId), command)
      .toPromise()
      .then(r => returnVoid());
  }

  function updateSupplierContact(
    command: Workshop.Domain.Commands.Supplier.UpdateSupplierContactCommand
  ) {
    return ajax
      .put(
        workshopUrls.supplierUrls.updateSupplierContact(command.supplierId, command.contactId),
        command
      )
      .toPromise()
      .then(r => returnVoid());
  }

  function deleteSupplierContact(
    command: Workshop.Domain.Commands.Supplier.DeleteSupplierContactCommand
  ) {
    return ajax
      .httpDelete(
        workshopUrls.supplierUrls.deleteSupplierContact(command.supplierId, command.contactId),
        command
      )
      .toPromise();
  }

  function listContactsForSupplier(supplierId: string) {
    return ajax
      .get(workshopUrls.supplierUrls.listContactsForSupplier(supplierId))
      .toPromise()
      .then<Workshop.Domain.Queries.Suppliers.GetContactsForSupplier.ContactItem>(r => r.response);
  }

  function createSupplierNote(
    command: Workshop.Domain.Commands.Supplier.CreateSupplierNoteCommand
  ) {
    return ajax
      .post(workshopUrls.supplierUrls.createSupplierNote(command.supplierId), command)
      .toPromise()
      .then(r => returnVoid());
  }

  function updateSupplierNote(
    command: Workshop.Domain.Commands.Supplier.UpdateSupplierNoteCommand
  ) {
    return ajax
      .put(
        workshopUrls.supplierUrls.updateSupplierNote(command.supplierId, command.noteId),
        command
      )
      .toPromise()
      .then(r => returnVoid());
  }

  function deleteSupplierNote(
    command: Workshop.Domain.Commands.Supplier.DeleteSupplierNoteCommand
  ) {
    return ajax
      .httpDelete(workshopUrls.supplierUrls.deleteSupplierNote(command))
      .toPromise()
      .then(r => returnVoid());
  }

  return {
    supplier: {
      listSuppliers,
      checkForUniqueName,
      createSupplier,
      getSupplier,
      updateSupplier,
      searchSuppliers,
      findSuppliers,
      createSupplierContact,
      updateSupplierContact,
      listContactsForSupplier,
      createSupplierNote,
      updateSupplierNote,
      deleteSupplierContact,
      deleteSupplierNote,
    },
  };
}
