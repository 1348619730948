import { IAjax } from 'src/infrastructure/ajax';
import * as queryString from 'query-string';
import { DateTime } from 'luxon';

type ListBookingsForAccountsQuery = Operations.Domain.Queries.ListBookingsForAccounts.ListBookingsForAccountsQuery;
type ListRailBookingsForAccountsQuery = Operations.Domain.Queries.ListRailBookingsForAccounts.ListRailBookingsForAccountsQuery;

export default function init(ajax: IAjax) {
  function exportKmsByJobTypeToExcel(
    from?: DateTime,
    to?: DateTime,
    assetIds?: string[],
    isDetailed?: boolean,
    useKioskInsteadOfTablet?: boolean
  ) {
    var query = queryString.stringify({
      from: from && from.toISODate(),
      to: to && to.toISODate(),
      assetIds,
      isDetailed,
      useKioskInsteadOfTablet,
    });

    return ajax
      .getFile(`/api/accounting/reports/kms-jobtype?${query}`)
      .toPromise()
      .then<Blob>(r => r.response);
  }

  function exportCentsPerKmType(
    from?: DateTime,
    to?: DateTime,
    assetIds?: string[],
    customerIds?: string[]
  ) {
    var query = queryString.stringify({
      fromDate: from && from.toISODate(),
      toDate: to && to.toISODate(),
      assetIds: assetIds,
      customerIds,
    });

    return ajax
      .getFile(`/api/accounting/reports/cents-per-km?${query}`)
      .toPromise()
      .then<Blob>(r => r.response);
  }
  function exportBookingsToExcel(query: Partial<ListBookingsForAccountsQuery>) {
    return ajax
      .getFile(`/api/operations/bookings/search/excel?${queryString.stringify(query)}`)
      .toPromise()
      .then<Blob>(r => r.response);
  }

  function exportRailBookingsToExcel(query: Partial<ListRailBookingsForAccountsQuery>) {
    return ajax
      .getFile(`/api/accounting/rail-bookings/excel?${queryString.stringify(query)}`)
      .toPromise()
      .then<Blob>(r => r.response);
  }

  return {
    exportKmsByJobTypeToExcel,
    exportCentsPerKmType,
    exportBookingsToExcel,
    exportRailBookingsToExcel,
  };
}
