import { getRolePermissionDescriptor, RolePermission } from 'src/api/enums';

export function getRolePermissionKeyValues() {
  return Object.entries(RolePermission)
    .filter(([key, value]) => isNaN(Number(key)) && value !== RolePermission.None)
    .map(([key, value]) => ({
      key: key as string,
      value: value as RolePermission,
      description: getRolePermissionDescriptor(value as RolePermission).description,
    }));
}

export function roleHasPermission(
  role: Common.Dtos.ListRoleItem | People.Domain.Queries.Role.RoleItem,
  permission: RolePermission
) {
  return (permission & role.permissions) === permission;
}
