import { getSubmitCloseModalActionGroupDef } from 'src/views/definitionBuilders/common';
import { PaneType, ModalDefBuilder } from 'src/views/definitionBuilders/types';

export default function getDuplicateRailTemplateModalDef(
  onDuplicate: () => Promise<void>
): ModalDefBuilder {
  return modalDefApi => ({
    title: `Duplicate Rail Template`,
    asForm: true,
    panels: [
      {
        panes: [
          {
            paneType: PaneType.customPane,
            render: api => (
              <p>A new rail template will be created by duplicating the current rail template.</p>
            ),
          },
        ],
      },
    ],
    secondaryActions: [getSubmitCloseModalActionGroupDef('Duplicate')],
    onFormSubmit: onDuplicate,
  });
}
