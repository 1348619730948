import './ShellHeader.scss';

import { Link, matchPath } from 'react-router-dom';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import { UiRoute } from 'src/domain';
import inject from 'src/views/injectFromStore';
import { Component } from 'react';

interface IHeaderBreadcrumbProps {
  currentUiRoutes: Array<UiRoute>;
}

const HeaderBreadcrumb: React.FC<IHeaderBreadcrumbProps> = ({ currentUiRoutes }) => (
  <Breadcrumb className="header-breadcrumb small">
    {currentUiRoutes.map((r, i) => {
      const isLastItem = currentUiRoutes.length - 1 === i;
      let url = r.buildPath();

      // correct links for parent breadcrumb items that have path parameters
      const isNotLastButHasParam = !isLastItem && url.indexOf(':') > 0;
      if (isNotLastButHasParam) {
        const match = matchPath(window.location.pathname, { path: url, exact: false });
        if (match) {
          url = match.url;
        }
      }

      return (
        <BreadcrumbItem key={r.name} active={isLastItem}>
          {isLastItem ? (
            r.title()
          ) : (
            <Link to={r.addDefaultFilterToLinkUrl ? `${url}?defaultFilter=true` : url}>
              {r.title()}
            </Link>
          )}
        </BreadcrumbItem>
      );
    })}
  </Breadcrumb>
);

interface IShellHeaderProps {
  id?: string;
  className?: string;
  currentUiRoutes: IHeaderBreadcrumbProps['currentUiRoutes'];
}

class ShellHeader extends Component<IShellHeaderProps> {
  render() {
    const { id, currentUiRoutes } = this.props;
    return (
      <header id={id} className={`shell-header-component ${this.props.className}`}>
        <HeaderBreadcrumb currentUiRoutes={currentUiRoutes} />
      </header>
    );
  }
}

const ShellHeaderContainer = inject<Pick<IShellHeaderProps, 'currentUiRoutes'>, IShellHeaderProps>(
  (allStores, p) => ({
    currentUiRoutes: allStores.rootStore.history.currentUiRoutes.slice(),
  })
)(ShellHeader);

export default ShellHeaderContainer;
