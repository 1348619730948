import { UiRouteName } from 'src/domain/baseTypes';
import { IRootStoreModel } from 'src/domain/entities/RootStoreModel';
import UiRoute from 'src/domain/services/buildUiRoutes/UiRoute';

export default function buildUiRoutes(store: IRootStoreModel) {
  const assetCreate = new UiRoute(
    UiRouteName.workshopAssetCreate,
    '/workshop/assets/create',
    () => 'Create Asset',
    false
  );

  const assetServiceSchedule = new UiRoute(
    UiRouteName.workshopAssetServiceSchedule,
    '/workshop/assets/:id/service-schedule',
    () => 'Service History',
    false
  );

  const assetComponentsRegister = new UiRoute(
    UiRouteName.workshopAssetComponentsRegister,
    '/workshop/assets/:id/components',
    () => 'Components Register',
    false
  );

  const assetCubicRegister = new UiRoute(
    UiRouteName.workshopAssetComponents,
    '/workshop/assets/:id/cubic',
    () => 'Cubic Register',
    false
  );

  const assetPartsRegister = new UiRoute(
    UiRouteName.workshopAssetComponents,
    '/workshop/assets/:id/parts',
    () => 'Parts Register',
    false
  );

  const manageAsset = new UiRoute(
    UiRouteName.workshopAssetEdit,
    '/workshop/assets/:id',
    () => 'Manage Asset',
    true,
    false,
    [assetServiceSchedule, assetPartsRegister, assetCubicRegister, assetComponentsRegister]
  );

  const assetsOdometerReadingsCreate = new UiRoute(
    UiRouteName.workshopAssetsOdometerReadingsCreate,
    '/workshop/assets/odometer-readings/create',
    () => 'Create Odometer Readings',
    false
  );

  const assetsOdometerReadings = new UiRoute(
    UiRouteName.workshopAssetsOdometerReadings,
    '/workshop/assets/odometer-readings',
    () => 'Daily Odometer Readings',
    false,
    false,
    [assetsOdometerReadingsCreate]
  );

  const unallocatedAssets = new UiRoute(
    UiRouteName.workshopUnallocatedAssets,
    '/workshop/assets/unallocated',
    () => 'Unallocated Assets By Day',
    false,
    true
  );

  const workshopDataFuelEntriesList = new UiRoute(
    UiRouteName.workshopDataFuelEntriesList,
    '/workshop/assets/datafuel/entries',
    () => 'Data Fuel',
    true,
    false,
    []
  );

  const workshopDataFuelUploadsList = new UiRoute(
    UiRouteName.workshopDataFuelUploadsList,
    '/workshop/assets/datafuel/uploads',
    () => 'Data Fuel Uploads',
    true,
    false,
    []
  );

  const assetsView = new UiRoute(
    UiRouteName.workshopAssetsView,
    '/workshop/assets',
    () => 'Assets',
    true,
    true,
    [
      assetsOdometerReadings,
      unallocatedAssets,
      workshopDataFuelEntriesList,
      workshopDataFuelUploadsList,
      assetCreate,
      manageAsset,
    ]
  );

  const assetServiceDueByDate = new UiRoute(
    UiRouteName.workshopAssetServicesDueByDate,
    '/workshop/assets/services-due-by-date',
    () => 'Services Due by Date',
    false
  );

  const assetServiceDueByKms = new UiRoute(
    UiRouteName.workshopAssetServicesDueByKms,
    '/workshop/assets/services-due-by-kms',
    () => 'Services Due by Kms',
    false
  );

  const maintainJobProgress = new UiRoute(
    UiRouteName.workshopMaintainJobProgress,
    '/workshop/jobs/:id/progress',
    () => 'Manage Job Progress',
    true
  );

  return {
    [UiRouteName.workshopAssetsView]: assetsView,
    [UiRouteName.workshopUnallocatedAssets]: unallocatedAssets,
    [UiRouteName.workshopAssetsOdometerReadings]: assetsOdometerReadings,
    [UiRouteName.workshopAssetsOdometerReadingsCreate]: assetsOdometerReadingsCreate,
    [UiRouteName.workshopAssetCreate]: assetCreate,
    [UiRouteName.workshopAssetServiceSchedule]: assetServiceSchedule,
    [UiRouteName.workshopAssetComponentsRegister]: assetComponentsRegister,
    [UiRouteName.workshopAssetPartsRegister]: assetPartsRegister,
    [UiRouteName.workshopAssetCubicRegister]: assetCubicRegister,
    [UiRouteName.workshopAssetEdit]: manageAsset,
    [UiRouteName.workshopAssetServicesDueByDate]: assetServiceDueByDate,
    [UiRouteName.workshopAssetServicesDueByKms]: assetServiceDueByKms,
    [UiRouteName.workshopMaintainJobProgress]: maintainJobProgress,
    [UiRouteName.workshopDataFuelEntriesList]: workshopDataFuelEntriesList,
    [UiRouteName.workshopDataFuelUploadsList]: workshopDataFuelUploadsList,
  };
}
