import ListPartCategories, { IListPartCategoriesProps } from './ListPartCategories';
import inject from 'src/views/injectFromStore';

const Container = inject<IListPartCategoriesProps>((allStores, p) => ({
  canManagePartCategories: allStores.rootStore.account.isWorkshopDepartmentMember,
  listPartCategories: allStores.rootStore.workshop.partCategories.listItems,
  partCategories: allStores.rootStore.workshop.partCategories.items.slice(),
  hasMoreData: allStores.rootStore.workshop.partCategories.hasMoreItems,
}))(ListPartCategories);

export default Container;
