import { DateTime } from 'luxon';

export enum Weekday {
  mon = 1,
  tue,
  wed,
  thu,
  fri,
  sat,
  sun,
}

export interface IWeekdayItem {
  day: Weekday;
  name: string;
}

export const allWeekdayItems: Array<IWeekdayItem> = Array.from(Array(7), (_, i) => i + 1).map(
  day => ({
    day,
    name: DateTime.fromObject({ weekday: day }).weekdayShort,
  })
);

export enum AssetCategoryType {
  Fleet = 1,
  Plant,
}

export enum AssetSubcategoryType {
  Bus = 1,
  Building,
  Car,
  Forklift,
  GolfBuggy,
  WorkshopEquipment,
  Trailer,
}

export enum JobCategoryType {
  Defect = 1,
  General,
  Service,
  PreMachineryInspection,
  MachineryInspection,
  PreventativeMaintenanceService,
}

export enum StaffGroup {
  All = 1,
  Office = 2,
  Workshop = 3,
  Drivers = 4,
}

export const allStaffGroups = [
  { id: StaffGroup.All, value: StaffGroup.All, description: 'All' },
  { id: StaffGroup.Office, value: StaffGroup.Office, description: 'Office' },
  { id: StaffGroup.Workshop, value: StaffGroup.Workshop, description: 'Workshop' },
  { id: StaffGroup.Drivers, value: StaffGroup.Drivers, description: 'Drivers' },
];

export enum CompanyId {
  KBL = 'KBL',
  PS = 'PS',
  DEMO = 'DEMO',
}

export enum FatigueBreaks {
  FullDay = 1,
  NightRest = 2,
  FiveHoursRest = 3,
}
