import styles from './LeaveCalendarLegend.module.scss';
import cn from 'classnames';

export const LeaveCalendarLegend: React.FC = () => (
  <div className={cn(styles['leave-calendar-legend'])}>
    <div className={cn(styles['legend-item'])}>
      <div className={cn(styles['legend-box'], styles['status-pending'])} />
      <span className={cn(styles['legend-key'])}>Pending</span>
    </div>
    <div className={cn(styles['legend-item'])}>
      <div className={cn(styles['legend-box'], styles['status-approved'])} />
      <span className={cn(styles['legend-key'])}>Approved</span>
    </div>
    <div className={cn(styles['legend-item'])}>
      <div className={cn(styles['legend-box'], styles['status-declined'])} />
      <span className={cn(styles['legend-key'])}>Declined</span>
    </div>
    <div className={cn(styles['legend-item'])}>
      <div className={cn(styles['legend-box'], styles['public-holiday'])} />
      <span className={cn(styles['legend-key'])}>Public Holiday</span>
    </div>
    <div className={cn(styles['legend-item'])}>
      <div className={cn(styles['legend-box'], styles['school-holiday'])} />
      <span className={cn(styles['legend-key'])}>School Holiday</span>
    </div>
  </div>
);
