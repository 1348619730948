import styles from './DepotSelector.module.scss';
import React from 'react';
import { Button } from 'reactstrap';
import { Formik, Form, FormikProps } from 'formik';
import * as Yup from 'yup';
import SelectField from '../components/form/SelectField';

type WorkshopDepotDto = Common.Queries.Workshop.GetWorkshopDepots.WorkshopDepotDto;

export interface IDepotSelectFormProps {
  currentDepotId: number;
  depots: WorkshopDepotDto[];
  onSubmit: (depotId: number) => void;
  onCancel: () => void;
}

export const DepotSelectForm: React.FC<IDepotSelectFormProps> = ({
  currentDepotId,
  depots,
  onSubmit,
  onCancel,
}) => {
  return (
    <Formik
      enableReinitialize
      validateOnChange
      initialValues={{ depotId: currentDepotId }}
      validationSchema={() => {
        return Yup.object().shape({
          depotId: Yup.number().required(),
        });
      }}
      validateOnBlur
      onSubmit={v => {
        onSubmit(v.depotId);
      }}>
      {(f: FormikProps<{ depotId: number }>) => (
        <Form>
          <SelectField
            className={styles.selectDepot}
            dataLabel={'Select default depot for this kiosk'}
            options={depots.map(o => ({ id: o.id, name: o.description }))}
            dataAddress={'depotId'}
            clearable={false}
            searchable={false}
          />
          <div className={styles.buttons}>
            <Button size="lg" outline type="button" disabled={f.isSubmitting} onClick={onCancel}>
              Cancel
            </Button>

            <Button size="lg" type="submit" disabled={!f.isValid || !f.dirty || f.isSubmitting}>
              Submit
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default DepotSelectForm;
