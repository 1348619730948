import './MarkOutOfService.scss';

interface IMarkOutOfServiceProps {
  markOutOfService: boolean;
}

const MarkOutOfService = ({ markOutOfService }: IMarkOutOfServiceProps) => {
  return (
    <div className={`markoutofservice-component ${markOutOfService ? 'yes' : ''}`}>
      {markOutOfService ? 'YES' : ''}
    </div>
  );
};

export default MarkOutOfService;
