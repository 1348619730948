import 'react-select/dist/react-select.css';
import './SelectPageField.scss';
import { ReplaySubject } from 'rxjs/ReplaySubject';
import { Subscription } from 'rxjs/Subscription';
import { IAutocompleteResult } from 'src/domain/baseTypes';
import { Async, OnChangeHandler } from 'react-select';
import { IFieldApi } from 'src/views/components/Page/forms/Field';
import { LabelledFormField } from 'src/views/components/forms';
import { IFieldMeta, IFieldData, ISelectAsyncFieldDef } from 'src/views/definitionBuilders/types';
import SelectAsyncFieldBase from './fieldBases/SelectAsyncFieldBase';

const loadOptionsDebounceMs = 500;

interface ISelectAsyncPageFieldProps {
  fieldDef: ISelectAsyncFieldDef;
  fieldMeta: IFieldMeta;
  // tslint:disable-next-line:no-any
  fieldData: IFieldData<any>;
  fieldApi: IFieldApi;
}

interface ISelectAsyncPageFieldState {
  isLoadingItem: boolean;
  // tslint:disable-next-line:no-any
  item: any;
}

class SelectAsyncPageField extends SelectAsyncFieldBase<
  ISelectAsyncPageFieldProps,
  ISelectAsyncPageFieldState
> {
  private readonly load$ = new ReplaySubject<() => Promise<void>>(1);
  private readonly rxSubscriptions = new Subscription();

  constructor(props: ISelectAsyncPageFieldProps) {
    super(props);
    this.state = {
      isLoadingItem: false,
      item: undefined,
    };
  }

  componentDidMount() {
    this.rxSubscriptions.add(this.load$.debounceTime(loadOptionsDebounceMs).subscribe(f => f()));
    this.checkForItemRequiringLoad();
  }

  componentWillUnmount() {
    this.rxSubscriptions.unsubscribe();
  }

  componentDidUpdate(
    prevProps: Readonly<ISelectAsyncPageFieldProps>,
    prevState: Readonly<ISelectAsyncPageFieldState>
  ) {
    this.checkForItemRequiringLoad();
  }

  private readonly checkForItemRequiringLoad = () => {
    const { fieldDef: def, fieldData } = this.props;
    const { isLoadingItem } = this.state;
    const currentItem = this.getCurrentItem();

    if (currentItem && currentItem.requiresLoad && !isLoadingItem && def.loadItems) {
      // Need to async load the item to get the description
      const itemValue = currentItem[def.valueKey];
      def
        .loadItems([itemValue], fieldData)
        .catch(e => ({ options: [] }))
        .then(r => r.options[0] || { [def.valueKey]: itemValue, [def.descriptionKey]: itemValue })
        .then(i => this.setState({ isLoadingItem: false, item: i }));

      this.setState({ isLoadingItem: true, item: undefined });
    }
  };

  private readonly loadOptions = (search: string) => {
    const { fieldDef, fieldData } = this.props;
    const valuesToDisable = this.getValuesToDisable();
    // tslint:disable-next-line:no-any
    const setDisabledItems = (opts: any) =>
      valuesToDisable && valuesToDisable.length
        ? opts.map((o: {}) => ({
            ...o,
            disabled: valuesToDisable.some(v => o[fieldDef.valueKey] === v),
          }))
        : opts;
    // tslint:disable-next-line:no-any
    return new Promise<IAutocompleteResult<any>>((resolve, reject) => {
      const f = () =>
        fieldDef
          .loadOptionItems(search, fieldData)
          .then(result => {
            const newResult = {
              ...result,
              options: setDisabledItems(result.options),
            };
            resolve(newResult);
          })
          .catch(reject);
      this.load$.next(f);
    });
  };

  private readonly getCurrentItem = () => {
    const { fieldDef: def, fieldData: data } = this.props;

    // fieldValue might be nothing
    if (data.fieldValue === undefined || data.fieldValue === null) {
      return data.fieldValue;
    }

    // tslint:disable-next-line:no-any
    let itemValue: any;
    let hasItemValue: boolean;

    if (typeof data.fieldValue === 'object') {
      itemValue = data.fieldValue[def.valueKey];
      hasItemValue = itemValue || itemValue === 0 || itemValue === false;

      // Try to avoid making a new object if the passed one is correct
      const itemDescription = data.fieldValue[def.descriptionKey];
      if (hasItemValue && itemDescription) {
        return data.fieldValue;
      }
    } else {
      itemValue = data.fieldValue;
      hasItemValue = itemValue || itemValue === 0 || itemValue === false;
    }

    if (!hasItemValue) {
      return undefined;
    }

    if (def.loadItems) {
      const { item } = this.state;
      if (item && item[def.valueKey] === itemValue) {
        return item;
      }

      return {
        [def.valueKey]: itemValue,
        [def.descriptionKey]: 'Loading ...',
        requiresLoad: true,
      };
    } else {
      // No way to load the needed description, so just use the value as the description
      return {
        [def.valueKey]: itemValue,
        [def.descriptionKey]: itemValue,
      };
    }
  };

  private readonly handleChange: OnChangeHandler = newOption => {
    const { fieldDef: def, fieldApi } = this.props;
    const newValue = def.useValueOnly && newOption ? newOption[def.valueKey] : newOption;
    fieldApi.setValue(newValue);
  };

  render() {
    const { fieldApi, fieldDef: def, fieldMeta: meta, fieldData: data } = this.props;
    const { error, touched } = fieldApi;
    const currentItem = this.getCurrentItem();
    const valueRenderer = def.useOptionRendererAsValueRenderer
      ? def.optionRenderer
      : def.valueRenderer;

    // As this field can have a value which is an object, then errors can be added to "lower" levels,
    // in which case `error` will be an object rather than a string
    const safeError = typeof error === 'string' ? error : undefined;
    const labelText = typeof def.label === 'function' ? def.label(data) : def.label;
    const tooltipText = typeof def.tooltip === 'function' ? def.tooltip(data) : def.tooltip;
    return (
      <LabelledFormField
        key={(def.getFieldKey && def.getFieldKey(data)) || undefined}
        className="select-async-page-field-component"
        readonly={meta.readonly}
        readonlyValue={
          def.formatReadonly
            ? def.formatReadonly(data)
            : currentItem && valueRenderer
            ? valueRenderer(currentItem)
            : currentItem && currentItem[def.descriptionKey]
        }
        readonlyLinkTo={def.linkTo && currentItem ? def.linkTo(data) : undefined}
        mandatory={meta.mandatory}
        hideLabel={meta.hideLabel}
        labelValue={labelText}
        tooltipValue={tooltipText}
        noForm={meta.noForm}
        error={touched && safeError}>
        <Async
          className={!(touched && safeError) ? '' : 'is-invalid'}
          // disabled={disabled}
          autoFocus={meta.autoFocus}
          clearable={!meta.mandatory}
          autoload={!!def.autoload}
          loadOptions={this.loadOptions}
          filterOptions={this.handleFilterOptions}
          valueKey={def.valueKey}
          labelKey={def.descriptionKey}
          value={currentItem}
          onChange={this.handleChange}
          matchProp="label"
          onBlur={this.handleBlur}
          valueRenderer={valueRenderer}
          optionRenderer={def.optionRenderer}
          placeholder={def.placeholder}
          menuContainerStyle={
            def.menuWidth && def.menuWidth === 'fitContent'
              ? { minWidth: '100%', width: 'fit-content' }
              : undefined
          }
        />
      </LabelledFormField>
    );
  }
}

export default SelectAsyncPageField;
