import './ActionBar.scss';

import { LocationDescriptor } from 'history';
import { ButtonGroup, ButtonToolbar, Button, ButtonProps } from 'reactstrap';
import { UiRouteName } from 'src/domain';
import LinkButton, { ILinkButtonProps } from 'src/views/components/LinkButton';

type ActionGroupItem =
  | Array<IButtonAction | ILinkAction | ICustomAction>
  | IButtonAction
  | ILinkAction
  | ICustomAction;

interface IAction {
  label: React.ReactNode;
  actionLevel?:
    | 'primary'
    | 'secondary'
    | 'success'
    | 'danger'
    | 'warning'
    | 'info'
    | 'light'
    | 'dark';
  outline?: boolean;
  disabled?: boolean;
  size?: 'sm' | 'lg';
}

export interface ILinkAction extends IAction {
  to: UiRouteName | LocationDescriptor;
  onClick?: ILinkButtonProps['onClick'];
}

export interface IButtonAction extends IAction {
  type?: 'submit' | 'reset' | 'button';
  onClick?: ButtonProps['onClick'];
}

export interface ICustomAction {
  content: React.ReactNode;
}

function isLink(action: ILinkAction | IButtonAction | ICustomAction): action is ILinkAction {
  return (action as ILinkAction).to !== undefined;
}

function isCustom(action: ILinkAction | IButtonAction | ICustomAction): action is ICustomAction {
  return (action as ICustomAction).content !== undefined;
}

interface IActionButtonProps {
  action: IButtonAction | ILinkAction | ICustomAction;
}

const Action: React.FC<IActionButtonProps> = ({ action }) => {
  if (isCustom(action)) {
    return <>{action.content}</>;
  }
  return isLink(action) ? (
    <LinkButton
      to={action.to}
      disabled={action.disabled}
      onClick={action.onClick || undefined}
      size={action.size || undefined}
      color={action.actionLevel || 'secondary'}
      outline={action.outline === undefined ? true : action.outline}>
      {action.label}
    </LinkButton>
  ) : (
    <Button
      type={action.type || 'button'}
      disabled={action.disabled}
      onClick={action.onClick || undefined}
      size={action.size || undefined}
      color={action.actionLevel || 'secondary'}
      outline={action.outline === undefined ? true : action.outline}>
      {action.label}
    </Button>
  );
};

interface IActionGroupProps {
  group: ActionGroupItem;
}

const ActionGroup: React.FC<IActionGroupProps> = ({ group }) => (
  <ButtonGroup className="ml-1">
    {Array.isArray(group) ? (
      group.map((a, i) => <Action key={i} action={a} />)
    ) : (
      <Action action={group} />
    )}
  </ButtonGroup>
);

interface IActionBarProps {
  groups: Array<ActionGroupItem>;
}

const ActionBar: React.FC<IActionBarProps> = ({ groups }) => (
  <ButtonToolbar className="action-bar-component">
    {groups.map((g, i) => (
      <ActionGroup key={i} group={g} />
    ))}
  </ButtonToolbar>
);

export default ActionBar;
