import inject from 'src/views/injectFromKioskStore';
import { Runsheet, IRunsheetProps } from './Runsheet';

const Container = inject<Pick<IRunsheetProps, 'runsheetItem' | 'loadRunsheet'>, IRunsheetProps>(
  (allStores, p) => ({
    runsheetItem: allStores.rootStore.kiosk.workshop.dailyRunSheet.runSheetItem,
    defaultKioskDepotId: allStores.rootStore.kiosk.workshop.defaultKioskDepotId,
    loadRunsheet: allStores.rootStore.kiosk.workshop.dailyRunSheet.loadDailyRunSheet,
    date: p.date,
  })
)(Runsheet);

export default Container;
