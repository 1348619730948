import * as queryString from 'query-string';
import { buildListPageApiSearchModelTypedQuery } from 'src/domain/modelBuilders/buildListPageApiSearchModel';

type CharterContractItem = Operations.Domain.Queries.ListCharterContracts.CharterContractItem;
type ListCharterContractsQuery = Operations.Domain.Queries.ListCharterContracts.ListCharterContractsQuery;

export const ListCharterContractsModel = buildListPageApiSearchModelTypedQuery<
  ListCharterContractsQuery
>()('ListCharterContractsModel', d =>
  d.ajax.raw
    .get(`/api/operations/charter-contracts?${queryString.stringify(d.query)}`)
    .map(r => r.response as Common.Dtos.ListResult<CharterContractItem>)
);
