import { ListPageLoadCause } from 'src/domain';
import { TrashIcon } from 'src/images/icons';
import { getSubmitCloseModalActionGroupDef } from 'src/views/definitionBuilders/common';
import {
  IModalActionButtonDef,
  ActionType,
  ShellModalSize,
  PaneType,
} from 'src/views/definitionBuilders/types';
import { IListExternalIdentitiesProps } from './ListExternalIdentities';

type ListExternalIdentityItem = People.Domain.Queries.ListExternalIdentities.ListExternalIdentityItem;

export default function getDeleteExternalIdentityActionButtonDef(
  loadExternalIdentities: IListExternalIdentitiesProps['loadExternalIdentities'],
  deleteExternalIdentity: IListExternalIdentitiesProps['deleteExternalIdentity']
): IModalActionButtonDef {
  return {
    actionType: ActionType.modalActionButton,
    label: 'Delete External Identity',
    icon: <TrashIcon />,
    modalSize: ShellModalSize.oneThird,
    modalDef: modalDefApi => {
      const item = modalDefApi.actionData.parentValue as ListExternalIdentityItem;

      return {
        title: d => `Delete External Identity`,
        asForm: true,
        panels: [
          {
            panes: [
              {
                paneType: PaneType.customPane,
                render: api => (
                  <>
                    <p>
                      Are you sure you want to delete the external identity for email address{' '}
                      <i>{item.externalEmail}</i>?
                    </p>
                    <p>Note: Any changes made may not take effect for up to one hour.</p>
                  </>
                ),
              },
            ],
          },
        ],
        secondaryActions: [getSubmitCloseModalActionGroupDef('Ok')],
        onFormSubmit: d =>
          deleteExternalIdentity(item.externalId).then(r => {
            loadExternalIdentities({
              query: {},
              loadCause: ListPageLoadCause.refresh,
            });
          }),
      };
    },
  };
}
