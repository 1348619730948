import { DateTime } from 'luxon';
import { FieldType, PaneType, SectionDefs } from 'src/views/definitionBuilders/types';

type FatigueBreachJobHistoryItem = Operations.Domain.Queries.FatigueBreachEvent.FatigueBreachJobHistoryItem;

export default function getJobsPanelDef(
  fatigueBreachEventId: string,
  historyItems?: FatigueBreachJobHistoryItem[],
  jobsDeletedCount?: number
): SectionDefs {
  return {
    title: 'Jobs',
    hidden: !fatigueBreachEventId,
    explicitData: historyItems,
    panels: [
      {
        panes: [
          {
            paneType: PaneType.tablePane,
            neverEditable: true,
            fields: [
              {
                fieldType: FieldType.textField,
                dataAddr: ['jobNumber'],
                label: 'Job Number',
                formatReadonly: d => (
                  <a href={`/operations/jobs/${d.parentValue.jobId}`}>{d.fieldValue}</a>
                ),
                columnWidth: '4rem',
              },
              {
                fieldType: FieldType.dateTimeField,
                dataAddr: ['scheduledClockOn'],
                label: 'Scheduled Clock On',
                formatReadonly: d =>
                  DateTime.fromISO(d.fieldValue!)
                    .toLocal()
                    .toLocaleString(DateTime.DATETIME_SHORT),
                columnWidth: '5rem',
              },
              {
                fieldType: FieldType.dateTimeField,
                dataAddr: ['scheduledClockOff'],
                label: 'Scheduled Clock Off',
                formatReadonly: d =>
                  DateTime.fromISO(d.fieldValue!)
                    .toLocal()
                    .toLocaleString(DateTime.DATETIME_SHORT),
                columnWidth: '5rem',
              },
              {
                fieldType: FieldType.dateTimeField,
                dataAddr: ['actualClockOn'],
                label: 'Actual Clock On',
                formatReadonly: d =>
                  !!d.fieldValue
                    ? DateTime.fromISO(d.fieldValue!)
                        .toLocal()
                        .toLocaleString(DateTime.DATETIME_SHORT)
                    : '-',
                columnWidth: '5rem',
              },
              {
                fieldType: FieldType.dateTimeField,
                dataAddr: ['actualClockOff'],
                label: 'Actual Clock Off',
                formatReadonly: d =>
                  !!d.fieldValue
                    ? DateTime.fromISO(d.fieldValue!)
                        .toLocal()
                        .toLocaleString(DateTime.DATETIME_SHORT)
                    : '-',
                columnWidth: '5rem',
              },
              {
                fieldType: FieldType.textAreaField,
                dataAddr: ['reasonForDifference'],
                label: 'Reason for Difference',
                formatReadonly: d => (
                  <span className={'reason-for-difference'}>{d.fieldValue}</span>
                ),
              },
            ],
          },
          {
            paneType: PaneType.customPane,
            hidden: jobsDeletedCount === 0,
            render: _ => `${jobsDeletedCount} related jobs have been deleted`,
          },
        ],
      },
    ],
  };
}
