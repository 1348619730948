import buildListPageApiSearchModel from 'src/domain/modelBuilders/buildListPageApiSearchModel';
import { types, flow } from 'mobx-state-tree';
import { getAjax } from 'src/domain/services/storeEnvironment';
import { QuoteStatus } from 'src/api/enums';
import { IAutocompleteResult } from 'src/domain/baseTypes';

type ListResult<T> = Common.Dtos.ListResult<T>;
type ListBookingItem = Operations.Domain.Queries.ListBookings.ListBookingItem;
type QuoteItem = Operations.Domain.Queries.GetQuotes.QuoteItem;

const BookingsListModel = buildListPageApiSearchModel('BookingsListModel', d =>
  d.ajax.sales.quote.listBookings(d.page, 20, d.query.search || '', [])
);

const ValidQuoteSearchStatuses = [QuoteStatus.Booked, QuoteStatus.Completed, QuoteStatus.Quoted];

const BookingSearchModel = types.model('CustomerModel', {}).actions(self => {
  const ajax = getAjax(self);

  let lastBookingSearch: string | undefined;
  let lastBookingSearchResult: IAutocompleteResult<ListBookingItem> | undefined;

  const searchBookings = flow(function*(search: string) {
    if (search === lastBookingSearch) {
      return lastBookingSearchResult || { options: [] };
    }
    const bookings: ListResult<ListBookingItem> = yield ajax.sales.quote
      .listBookings(1, 50, search, ValidQuoteSearchStatuses)
      .toPromise();
    lastBookingSearch = search;
    lastBookingSearchResult = {
      options: bookings.items,
    };
    return lastBookingSearchResult;
  });

  const findQuotes = flow(function*(quoteIds: string[]) {
    if (!quoteIds || !quoteIds.length) {
      return { options: [] };
    }
    const quotes: QuoteItem[] = yield ajax.sales.quote.findQuotes(quoteIds);
    return { options: quotes };
  });

  return {
    searchBookings,
    findQuotes,
  };
});

export const ListBookingsModel = types.compose(BookingsListModel, BookingSearchModel);
