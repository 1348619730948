import './MaintainDefect.scss';
import { Component } from 'react';
import { RouteComponentProps, Link } from 'react-router-dom';
import {
  PagePrimarySize,
  PaneType,
  FieldType,
  ShellModalSize,
  ActionType,
} from 'src/views/definitionBuilders/types';
import CrudPage, { CrudPageMode, ICrudPageDef } from 'src/views/components/Page/pages/CrudPage';
import { DateTime } from 'luxon';
import { DefectRaisedBy, defectRaisedByDescription, DefectStatus } from 'src/api/enums';
import { TimesIcon, Tasks, ClipboardIcon, CheckIcon } from 'src/images/icons';
import getCloseDefectWithoutFixingModalDef from './getCloseDefectWithoutFixingModalDef';

type AssetItem = Workshop.Domain.Queries.AssetItem;
type DefectItem = Workshop.Domain.Queries.Defect.GetDefectItem.DefectItem;
type CreateDefectCommand = Workshop.Domain.Commands.Defect.CreateDefectCommand;
type MarkOutOfServiceDefectCommand = Workshop.Domain.Commands.Defect.MarkOutOfServiceDefectCommand;
type DefectListItem = Workshop.Domain.Queries.Defect.DefectListItem;
type JobTaskListItem = Workshop.Domain.Queries.JobTask.JobTaskListItem;
type ActivityLogTransaction = Workshop.Domain.Queries.ActivityLog.ActivityLogTransaction;
type ActivityLogDetails = Workshop.Domain.Queries.ActivityLog.ActivityLogDetails;
type DefectRaisedByItem = Workshop.Domain.AggregatesModel.DefectAggregate.DefectRaisedBy;
type CloseDefectWithoutFixingCommand = Workshop.Domain.Commands.Defect.SetDefectClosedWithoutBeingFixedCommand;

export interface IMaintainDefectProps {
  mode: CrudPageMode;
  canManageDefects: boolean;
  defect: Workshop.Domain.Queries.Defect.GetDefectItem.DefectItem | undefined;
  assets: AssetItem[];
  defectsForAsset: DefectListItem[];
  tasksForAsset: JobTaskListItem[];
  clearTasksForAsset: () => void;
  activityLogs: ActivityLogTransaction[];
  onLoadDefect: (id: string) => Promise<void>;
  onCreateDefect: (command: CreateDefectCommand) => Promise<void>;
  onMarkOutOfServiceDefect: (command: MarkOutOfServiceDefectCommand) => Promise<void>;
  loadAssetListItems: () => Promise<void>;
  getDefectsForAsset: (assetId: string) => Promise<void>;
  getActivityLogForDefect: (defectId: string) => Promise<void>;
  getTasksForAsset: (assetId: string) => Promise<void>;
  clearDefectsForAsset: () => void;
  clearActivityLogForDefect: () => void;
  userDisplayName: string;
  tasks: JobTaskListItem[];
  clearTasksForDefect: () => void;
  getTasksForDefect: (defectId: string) => Promise<void>;
  setDefectClosedWithoutFixing: (command: CloseDefectWithoutFixingCommand) => Promise<void>;
  acknowledgeDefect: (defectId: string) => Promise<void>;
}

interface IUpdateDefectRouteParams {
  id: string;
}

type InternalProps = IMaintainDefectProps & RouteComponentProps<IUpdateDefectRouteParams>;

class MaintainDefect extends Component<InternalProps> {
  componentDidMount() {
    this.props.clearTasksForDefect();
    this.props.clearTasksForAsset();
    this.props.clearDefectsForAsset();
    this.props.clearActivityLogForDefect();
    this.props.loadAssetListItems();
  }

  private get isUpdateMode() {
    return this.props.mode === 'update';
  }

  private get isCreateMode() {
    return this.props.mode === 'create';
  }

  private get defectId() {
    return this.props.match.params.id;
  }

  private get getRaisedByFromRoute(): DefectRaisedByItem {
    if (this.props.match.path.includes('operations/defects')) {
      return {
        id: DefectRaisedBy.Operations,
        description: defectRaisedByDescription(DefectRaisedBy.Operations),
      };
    } else {
      return {
        id: DefectRaisedBy.Workshop,
        description: defectRaisedByDescription(DefectRaisedBy.Workshop),
      };
    }
  }

  private loadData = () => {
    return this.props.onLoadDefect(this.defectId).then(() => {
      const assetId = this.props.defect!.asset.id;
      // don't return the following to stop the spinner waiting
      this.props.getDefectsForAsset(assetId);
      this.props.getActivityLogForDefect(this.defectId);
      this.props.getTasksForAsset(assetId);
      this.props.getTasksForDefect(this.defectId);
    });
  };

  private handleLoadCreateDefaultData = () => {
    this.props.clearDefectsForAsset();
    this.props.clearActivityLogForDefect();
    return Promise.resolve();
  };

  private handlePreSubmitForCreate = (defect: DefectItem): CreateDefectCommand => {
    return {
      assetId: defect.asset.id,
      description: defect.description,
      reportedBy: defect.reportedBy,
      reportedDate: defect.reportedDate,
      raisedById: defect.raisedBy.id,
      markOutOfService: defect.markOutOfService,
    };
  };

  private handlePreSubmitForMarkOutOfService = (
    defect: DefectItem
  ): MarkOutOfServiceDefectCommand => {
    return {
      id: defect.id,
      markOutOfService: defect.markOutOfService,
    };
  };

  private readonly getPageDef = (): ICrudPageDef => {
    const { defect } = this.props;
    const defectNumber = defect && defect.defectNumber;
    const defectStatus = defect && defect.status.description;
    const isMachinery = defect && defect.machineryDefectNumber;

    return {
      primarySize: PagePrimarySize.half,
      primarySection: {
        title: this.isUpdateMode ? `Defect ${defectNumber || ''}` : 'Raise a Defect',
        badge: this.defectId === undefined ? undefined : { label: defectStatus || '' },
        primaryActions: [
          {
            actions: [
              {
                actionType: ActionType.actionCollection,
                hidden: this.isCreateMode,
                actionGroups: [
                  {
                    actions: [
                      {
                        actionType: ActionType.actionButton,
                        label: 'Acknowledge Defect',
                        hidden: !defect || defect.status.id !== DefectStatus.DefectCreated,
                        icon: <CheckIcon fixedWidth />,
                        onClick: () => this.props.acknowledgeDefect(defect ? defect.id : ''),
                      },
                      {
                        actionType: ActionType.modalActionButton,
                        hidden:
                          !defect ||
                          defect.status.id === DefectStatus.Closed ||
                          defect.markOutOfService,
                        label: 'Close Defect Without Fixing',
                        icon: <TimesIcon fixedWidth />,
                        modalSize: ShellModalSize.oneQuarter,
                        modalDef: getCloseDefectWithoutFixingModalDef(
                          this.props.setDefectClosedWithoutFixing,
                          defect ? defect.id : undefined
                        ),
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
        panels: [
          {
            panes: [
              {
                paneType: PaneType.formFieldsPane,
                fields: [
                  {
                    fieldType: FieldType.assetSelectField,
                    label: 'Asset',
                    dataAddr: 'asset',
                    readonly: this.isUpdateMode,
                    valueKey: 'id',
                    descriptionKey: 'name',
                    mandatory: true,
                    optionItems: this.props.assets,
                    onChange: api => {
                      const newAssetId = api.newFieldValue && api.newFieldValue.id;
                      if (newAssetId) {
                        this.props.getDefectsForAsset(newAssetId);
                        this.props.getTasksForAsset(newAssetId);
                      }
                    },
                  },
                  {
                    fieldType: FieldType.textAreaField,
                    label: 'Description',
                    dataAddr: 'description',
                    readonly: this.isUpdateMode,
                    mandatory: true,
                    rows: 3,
                  },
                  {
                    fieldType: FieldType.yesNoField,
                    label: 'Mark Vehicle Out Of Service',
                    dataAddr: 'markOutOfService',
                    tooltip:
                      ' Indicates that the defect requires the vehicle to be taken off the road and cannot be used until it has been repaired.',
                    mandatory: true,
                  },
                  {
                    fieldType: FieldType.readonlyField,
                    label: 'Raised By',
                    dataAddr: 'raisedBy.description',
                    hidden: this.defectId === undefined,
                  },
                  {
                    fieldType: FieldType.textField,
                    label: 'Reported By',
                    dataAddr: 'reportedBy',
                    readonly: this.isUpdateMode,
                    mandatory: true,
                    maxLength: 200,
                  },
                  {
                    fieldType: FieldType.dateField,
                    label: 'Reported Date',
                    dataAddr: 'reportedDate',
                    readonly: this.isUpdateMode,
                    mandatory: true,
                  },
                  {
                    fieldType: FieldType.dateField,
                    label: 'Fixed On',
                    dataAddr: 'fixedOn',
                    readonly: true,
                    hidden: this.defectId === undefined,
                  },
                ],
              },
            ],
          },
          {
            hidden: d => !isMachinery,
            panes: [
              {
                paneType: PaneType.formFieldsPane,
                columnCount: 2,
                fields: [
                  {
                    fieldType: FieldType.textField,
                    label: 'SD #',
                    dataAddr: 'machineryDefectNumber',
                    readonly: true,
                  },
                  {
                    fieldType: FieldType.textField,
                    label: 'Severity',
                    dataAddr: 'machinerySeverity',
                    readonly: true,
                  },
                  {
                    fieldType: FieldType.textField,
                    label: 'Completed By',
                    dataAddr: 'machineryCompletedBy',
                    readonly: true,
                  },
                ],
              },
            ],
          },
        ],
        onFormPreSubmit: this.isUpdateMode
          ? this.handlePreSubmitForMarkOutOfService
          : this.handlePreSubmitForCreate,
        onFormSubmit: this.isUpdateMode
          ? this.props.onMarkOutOfServiceDefect
          : this.props.onCreateDefect,
      },
      secondarySections: [
        {
          title: 'Related Tasks',
          explicitData: this.props.tasksForAsset,
          panels: [
            {
              panes: [
                {
                  paneType: PaneType.tablePane,
                  neverEditable: true,
                  fields: [
                    {
                      fieldType: FieldType.textField,
                      dataAddr: 'jobTaskNumber',
                      label: 'Number',
                      linkTo: d => `/workshop/tasks/${d.parentValue.id}`,
                    },
                    {
                      fieldType: FieldType.textField,
                      dataAddr: 'statusDisplay',
                      label: 'Status',
                    },
                    {
                      fieldType: FieldType.textField,
                      dataAddr: 'description',
                      label: 'Description',
                    },
                  ],
                },
                {
                  paneType: PaneType.actionListPane,
                  actionGroups: [
                    {
                      actions: [
                        {
                          actionType: ActionType.actionLink,
                          label: 'List All Tasks For Asset',
                          icon: <Tasks />,
                          to: d => {
                            const tasks = d.parentValue as JobTaskListItem[];
                            const assetId = tasks && tasks[0] && tasks[0].asset.id;
                            return `/workshop/tasks?assets=${assetId}`;
                          },
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          title: 'Related Defects',
          explicitData: this.props.defectsForAsset.filter(d => d.id !== this.defectId),
          panels: [
            {
              panes: [
                {
                  paneType: PaneType.tablePane,
                  neverEditable: true,
                  fields: [
                    {
                      fieldType: FieldType.textField,
                      dataAddr: 'defectNumber',
                      label: 'Defect Number',
                      linkTo: d => `/workshop/defects/${d.parentValue.id}`,
                    },
                    {
                      fieldType: FieldType.textField,
                      dataAddr: 'description',
                      label: 'Details',
                    },
                    {
                      fieldType: FieldType.selectField,
                      dataAddr: 'status',
                      label: 'Status',
                      valueKey: 'id',
                      descriptionKey: 'description',
                    },
                  ],
                },
                {
                  paneType: PaneType.actionListPane,
                  actionGroups: [
                    {
                      actions: [
                        {
                          actionType: ActionType.actionLink,
                          label: 'List All Defects For Asset',
                          icon: <ClipboardIcon />,
                          to: d => {
                            const tasks = d.parentValue as DefectListItem[];
                            const assetId = tasks && tasks[0] && tasks[0].asset.id;
                            return `/workshop/defects?assets=${assetId}`;
                          },
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          title: 'Linked Tasks',
          explicitData: this.props.tasks,
          panels: [
            {
              panes: [
                {
                  paneType: PaneType.tablePane,
                  neverEditable: true,
                  fields: [
                    {
                      fieldType: FieldType.textField,
                      dataAddr: 'jobTaskNumber',
                      label: 'Task Number',
                      linkTo: d => `/workshop/tasks/${d.parentValue.id}`,
                    },
                    {
                      fieldType: FieldType.textField,
                      dataAddr: 'statusDisplay',
                      label: 'Status',
                    },
                    {
                      fieldType: FieldType.textField,
                      dataAddr: 'description',
                      label: 'Description',
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          title: 'Activity',
          explicitData: this.props.activityLogs,
          panels: [
            {
              panes: [
                {
                  paneType: PaneType.repeatingPane,
                  itemPanes: [
                    {
                      paneType: PaneType.customPane,
                      dataAddr: 'transactionId',
                      render: api => {
                        const log = api.data.parentValue as ActivityLogTransaction;
                        const createdOnDateTime = DateTime.fromISO(log.createdOn).toLocaleString(
                          DateTime.DATETIME_MED_WITH_SECONDS
                        );
                        return (
                          <div className="activity-log-for-defects-pane">
                            <div className="created-by">{log.createdBy}</div>
                            <div className="created-on">{createdOnDateTime}</div>
                            <ul className="log-items list-unstyled">
                              {log.items.map((item: ActivityLogDetails) => {
                                const taskLink = item.aggregateType === 'JobTask';
                                return (
                                  <li className="log-item" key={item.activityLogId}>
                                    <div className="description" hidden={taskLink}>
                                      {item.description}
                                    </div>
                                    <div className="description log-link" hidden={!taskLink}>
                                      <Link to={`/workshop/tasks/${item.aggregateId}`}>
                                        {item.description}
                                      </Link>
                                    </div>
                                    <div className="comments">{item.comment}</div>
                                  </li>
                                );
                              })}
                            </ul>
                          </div>
                        );
                      },
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    };
  };

  render() {
    const { mode, defect, userDisplayName, canManageDefects } = this.props;
    return (
      <CrudPage
        className="maintain-defect"
        key={this.defectId}
        def={this.getPageDef()}
        mode={mode}
        isEditingForbidden={!canManageDefects}
        onLoadData={this.loadData}
        data={defect}
        createDefaultData={{
          raisedBy: this.getRaisedByFromRoute,
          markOutOfService: false,
          reportedBy: userDisplayName,
          reportedDate: DateTime.local().toISODate(),
        }}
        onLoadCreateDefaultData={this.handleLoadCreateDefaultData}
      />
    );
  }
}

export default MaintainDefect;
