import saveAs from 'file-saver';
import { DateTime } from 'luxon';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import {
  allJobTaskStatus,
  allOnHoldStatus,
  JobTaskCategory,
  JobTaskStatus,
  OnHoldStatus,
} from 'src/api/enums';
import { ListPageLoadCause } from 'src/domain/baseTypes';
import { useRootStore } from 'src/domain/entities/RootStoreModel';
import { BanIcon, CalendarIcon, ExcelIcon } from 'src/images/icons';
import IntervalFormat from 'src/views/components/IntervalFormat/IntervalFormat';
import { ListPage } from 'src/views/components/Page';
import { IListPageDef } from 'src/views/components/Page/pages/ListPage';
import { TaskCardItem } from 'src/views/components/TaskCard';
import { getSubmitCloseModalActionGroupDef } from 'src/views/definitionBuilders/common';
import {
  ActionType,
  FieldDefs,
  FieldType,
  PagePrimarySize,
  PaneType,
  ShellModalSize,
} from 'src/views/definitionBuilders/types';
import withQueryParams, { IQueryParamsProps } from 'src/views/hocs/withQueryParams';
import PrintJobSheet, {
  jobSheetPdfHeader,
} from 'src/views/routes/workshop/jobs/listJobs/PrintJobSheet/PrintJobSheet';
import getCancelJobTaskModalDef from 'src/views/routes/workshop/tasks/maintainTask/getCancelJobTaskModalDef';
import getScheduleJobTaskModalDef from 'src/views/routes/workshop/tasks/maintainTask/getScheduleJobTaskModalDef';
import getUnscheduleJobTaskModalDef from 'src/views/routes/workshop/tasks/maintainTask/getUnscheduleJobTaskModalDef';
import { allowedCategoriesForScheduled } from 'src/views/routes/workshop/tasks/maintainTask/MaintainTask';
import MarkOutOfService from '../../defects/ListDefects/MarkOutOfService';
import PrimaryTitle from 'src/views/components/Page/PrimaryTitle/PrimaryTitle';

type CancelJobTaskCommand = Workshop.Domain.Commands.JobTask.CancelJobTaskCommand;
type ScheduleFutureJobTaskCommand = Workshop.Domain.Commands.JobTask.ScheduleFutureJobTaskCommand;
type ListJobTasksQuery = Workshop.Domain.Queries.JobTask.ListJobTasks.ListJobTasksQuery;
type CloseJobTaskAndDefectWithoutFixingCommand = Workshop.Domain.Commands.JobTask.CloseJobTaskAndDefectWithoutFixingCommand;
type JobSummaryDto = Workshop.Domain.Queries.Job.JobSummaryDto;

const ListTasks: React.FC<IQueryParamsProps<ListJobTasksQuery>> = observer(
  (props: IQueryParamsProps<ListJobTasksQuery>) => {
    const rootStore = useRootStore();
    const workshopModel = rootStore.workshop;
    const listTaskModel = workshopModel.listTasks;
    const jobTaskModel = rootStore.jobTask;
    const jobModel = rootStore.job;
    const assetModel = rootStore.asset;
    const assetsModel = rootStore.assets;
    const [isLoadingFilters, setIsLoadingFilters] = useState<boolean>(true);
    const [activeJobs, setActiveJobs] = useState<JobSummaryDto[]>([]);

    useEffect(() => {
      Promise.all([
        assetsModel.loadAssetListItems(),
        jobTaskModel.loadJobTaskCategories(),
        assetModel.loadAssetHousedAtLocations(),
      ]).then(() => setIsLoadingFilters(false));
    }, []);

    const getPrimaryFields = (): IListPageDef['primaryFields'] => {
      return [
        {
          fieldType: FieldType.textField,
          dataAddr: ['asset', 'name'],
          label: 'Asset',
          orderBy: 'Asset.Name',
          linkTo: d => `/workshop/assets/${d.parentValue.asset.id}`,
        },
        {
          fieldType: FieldType.textField,
          dataAddr: 'jobTaskNumber',
          label: 'Number',
          orderBy: 'JobTask.JobTaskNumber',
          linkTo: d => `/workshop/tasks/${d.parentValue.id}`,
        },
        {
          fieldType: FieldType.textField,
          dataAddr: ['category', 'description'],
          label: 'Category',
          orderBy: 'JobTask.CategoryId',
        },
        {
          fieldType: FieldType.textField,
          dataAddr: 'description',
          label: 'Description',
        },
        {
          fieldType: FieldType.textField,
          dataAddr: 'completionDetails',
          label: 'Details',
        },
        {
          fieldType: FieldType.textField,
          dataAddr: ['status', 'description'],
          label: 'Status',
          orderBy: 'JobTask.StatusId',
        },
        {
          fieldType: FieldType.textField,
          dataAddr: ['onHoldStatus', 'description'],
          label: 'On Hold',
          orderBy: 'JobTask.OnHoldStatusId',
          hidden: d => d.parentValue.onHoldStatus.id === OnHoldStatus.NotAwaitingParts,
        },
        {
          fieldType: FieldType.customField,
          dataAddr: 'status',
          label: 'Scheduled',
          orderBy: 'Job.StartDateTime',
          columnWidth: '10rem',
          render: api => {
            return (
              <IntervalFormat
                startValue={api.data.parentValue.scheduledStartDateTime}
                endValue={api.data.parentValue.scheduledEndDateTime}
                includeYear
              />
            );
          },
        },
        {
          fieldType: FieldType.readonlyField,
          dataAddr: 'outOfService',
          label: 'Out of Service',
          formatReadonly: d => <MarkOutOfService markOutOfService={d.parentValue.outOfService} />,
        },
        {
          fieldType: FieldType.actionListField,
          label: 'Actions',
          actionGroups: [
            {
              actions: [
                {
                  actionType: ActionType.actionCollection,
                  actionGroups: [
                    {
                      actions: [
                        {
                          actionType: ActionType.printActionButton,
                          label: 'Print Job Sheet',
                          hidden: d => !d.parentValue.jobId,
                          printTitle:
                            jobModel.printJobItem && jobSheetPdfHeader(jobModel.printJobItem),
                          printContent: _ =>
                            jobModel.printJobItem && <PrintJobSheet job={jobModel.printJobItem} />,
                          loadDataAsync: d => jobModel.loadPrintJobSheet(d.parentValue.jobId),
                        },
                        {
                          actionType: ActionType.modalActionButton,
                          label: 'Schedule this task',
                          icon: <CalendarIcon fixedWidth />,
                          modalSize: ShellModalSize.oneThird,
                          onOpenModal: (parentValue: { id: string }) => {
                            return jobTaskModel
                              .getAllActiveJobs(parentValue.id)
                              .then((res: JobSummaryDto[]) => setActiveJobs(res));
                          },
                          modalDef: api => {
                            return getScheduleJobTaskModalDef(
                              (command: ScheduleFutureJobTaskCommand) => {
                                return jobTaskModel
                                  .scheduleFutureJobTaskWithoutReload(command)
                                  .then(_ =>
                                    listTaskModel.listItems({
                                      query: props.getQueryParams(),
                                      loadCause: ListPageLoadCause.refresh,
                                    })
                                  );
                              },
                              activeJobs,
                              rootStore.workshopStartup.workshopDepots.slice(),
                              rootStore.workshopStartup.defaultWorkshopDepot,
                              api.actionData.parentValue.id,
                              undefined
                            )(api);
                          },
                          hidden: d => d.parentValue.status.id !== JobTaskStatus.Future,
                        },
                        {
                          actionType: ActionType.modalActionButton,
                          label: 'Reschedule This Task',
                          icon: <CalendarIcon fixedWidth />,
                          modalSize: ShellModalSize.oneThird,
                          onOpenModal: (parentValue: { id: string }) => {
                            return jobTaskModel
                              .getAllActiveJobs(parentValue.id)
                              .then((res: JobSummaryDto[]) => setActiveJobs(res));
                          },
                          modalDef: api => {
                            return getScheduleJobTaskModalDef(
                              (command: ScheduleFutureJobTaskCommand) => {
                                return jobTaskModel
                                  .scheduleFutureJobTaskWithoutReload(command)
                                  .then(_ =>
                                    listTaskModel.listItems({
                                      query: props.getQueryParams(),
                                      loadCause: ListPageLoadCause.refresh,
                                    })
                                  );
                              },
                              activeJobs,
                              rootStore.workshopStartup.workshopDepots.slice(),
                              rootStore.workshopStartup.defaultWorkshopDepot,
                              api.actionData.parentValue.id,
                              api.actionData.parentValue.jobId
                            )(api);
                          },
                          hidden: d =>
                            (d.parentValue.status.id !== JobTaskStatus.Scheduled &&
                              d.parentValue.status.id !== JobTaskStatus.InProgress) ||
                            d.parentValue.outOfService,
                        },
                        {
                          actionType: ActionType.modalActionButton,
                          label: 'Unschedule This Task',
                          icon: <CalendarIcon fixedWidth />,
                          modalSize: ShellModalSize.oneThird,
                          modalDef: api => {
                            return getUnscheduleJobTaskModalDef((id: string) => {
                              return jobTaskModel.unscheduleFutureJobTaskWithoutReload(id).then(_ =>
                                listTaskModel.listItems({
                                  query: props.getQueryParams(),
                                  loadCause: ListPageLoadCause.refresh,
                                })
                              );
                            }, api.actionData.parentValue.id)(api);
                          },
                          hidden: d =>
                            d.parentValue.outOfService ||
                            (d.parentValue.status.id !== JobTaskStatus.Scheduled &&
                              d.parentValue.status.id !== JobTaskStatus.InProgress) ||
                            (d.parentValue.category.id !== JobTaskCategory.Defect &&
                              d.parentValue.category.id !== JobTaskCategory.General),
                        },
                        {
                          actionType: ActionType.modalActionButton,
                          label: 'Cancel Task',
                          icon: <BanIcon fixedWidth />,
                          modalSize: ShellModalSize.oneQuarter,
                          modalDef: api => {
                            return getCancelJobTaskModalDef(
                              (command: CancelJobTaskCommand) =>
                                jobTaskModel.cancelJobTaskWithoutReload(command).then(_ =>
                                  listTaskModel.listItems({
                                    query: props.getQueryParams(),
                                    loadCause: ListPageLoadCause.refresh,
                                  })
                                ),
                              api.actionData.parentValue.id,
                              api.actionData.parentValue.category && {
                                category: api.actionData.parentValue.category,
                              },
                              api.actionData.parentValue.scheduledStartDateTime && {
                                startDateTime: api.actionData.parentValue.scheduledStartDateTime,
                              }
                            )(api);
                          },
                          hidden: d => {
                            const canBeCancelled =
                              !d.parentValue.outOfService &&
                              ((d.parentValue.status.id === JobTaskStatus.Scheduled &&
                                allowedCategoriesForScheduled.includes(
                                  d.parentValue.category.id
                                )) ||
                                (d.parentValue.category.id === JobTaskCategory.General &&
                                  (d.parentValue.status.id === JobTaskStatus.Future ||
                                    d.parentValue.status.id === JobTaskStatus.Scheduled)));
                            return !canBeCancelled;
                          },
                        },
                      ],
                    },
                    {
                      actions: [
                        {
                          actionType: ActionType.modalActionButton,
                          label: 'Close Task and Defect without fixing',
                          modalSize: ShellModalSize.oneQuarter,
                          hidden: d => {
                            const canTaskBeClosed =
                              !d.parentValue.outOfService &&
                              d.parentValue.category.id === JobTaskCategory.Defect &&
                              (d.parentValue.status.id === JobTaskStatus.Future ||
                                d.parentValue.status.id === JobTaskStatus.Scheduled);
                            return (
                              !rootStore.account.isWorkshopDepartmentMember || !canTaskBeClosed
                            );
                          },
                          icon: <BanIcon fixedWidth />,
                          modalDef: _ => ({
                            title: 'Close Task and Defect',
                            asForm: true,
                            panels: [
                              {
                                panes: [
                                  {
                                    paneType: PaneType.formFieldsPane,
                                    fields: [
                                      {
                                        fieldType: FieldType.textAreaField,
                                        dataAddr: 'reason',
                                        mandatory: true,
                                        label: 'Reason for closure',
                                      },
                                    ],
                                  },
                                ],
                              },
                            ],
                            secondaryActions: [
                              getSubmitCloseModalActionGroupDef('Close Task and Defect'),
                            ],
                            onFormPreSubmit: f => {
                              return {
                                jobTaskId: f.id,
                                reason: f.reason,
                              } as CloseJobTaskAndDefectWithoutFixingCommand;
                            },
                            onFormSubmit: (command: CloseJobTaskAndDefectWithoutFixingCommand) => {
                              return jobTaskModel
                                .closeJobTaskWithoutFixingWithoutReload(command)
                                .then(_ =>
                                  listTaskModel.listItems({
                                    query: props.getQueryParams(),
                                    loadCause: ListPageLoadCause.refresh,
                                  })
                                );
                            },
                          }),
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
      ];
    };

    const getRowKey: IListPageDef['rowKey'] = data => {
      return data.itemValue.id;
    };

    const getPageDef = (): IListPageDef => {
      const filterFieldDefsLookup = {
        asset: {
          fieldType: FieldType.selectMultiField,
          label: 'Asset',
          dataAddr: 'assets',
          useValueOnly: true,
          valueKey: 'id',
          descriptionKey: 'name',
          optionItems: assetsModel.assetListItems.slice(),
        } as FieldDefs,
        lowFloor: {
          fieldType: FieldType.yesNoField,
          dataAddr: 'isLowFloor',
          label: 'Low Floor',
        } as FieldDefs,
        category: {
          fieldType: FieldType.selectMultiField,
          label: 'Category',
          dataAddr: 'categories',
          useValueOnly: true,
          valueKey: 'id',
          descriptionKey: 'description',
          optionItems: jobTaskModel.jobTaskCategories.slice(),
        } as FieldDefs,
        status: {
          fieldType: FieldType.selectMultiField,
          label: 'Status',
          dataAddr: 'statuses',
          useValueOnly: true,
          valueKey: 'value',
          descriptionKey: 'description',
          optionItems: allJobTaskStatus.slice(),
        } as FieldDefs,
        onHold: {
          fieldType: FieldType.selectMultiField,
          label: 'On Hold Status',
          dataAddr: 'onHoldStatuses',
          useValueOnly: true,
          valueKey: 'value',
          descriptionKey: 'description',
          optionItems: allOnHoldStatus.slice(),
        } as FieldDefs,
        dateCreatedFrom: {
          fieldType: FieldType.dateField,
          dataAddr: 'dateCreatedFrom',
          label: 'Date Created From',
          onBlur: api => {
            if (!api.formValues.dateTo) {
              api.setFormValue(['dateTo'], api.fieldData.fieldValue);
            }
            api.validateField(['dateTo']);
          },
        } as FieldDefs,
        dateCreatedTo: {
          fieldType: FieldType.dateField,
          dataAddr: 'dateCreatedTo',
          label: 'Date Created To',
          validate: d => {
            if (!d.fieldValue || !d.parentValue.dateFrom) {
              return undefined;
            }
            const from = DateTime.fromISO(d.parentValue.dateFrom);
            const to = DateTime.fromISO(d.fieldValue);
            return from > to ? 'Date To cannot be earlier than Date From' : undefined;
          },
        } as FieldDefs,
        dateScheduledFrom: {
          fieldType: FieldType.dateField,
          dataAddr: 'dateScheduledFrom',
          label: 'Date Scheduled From',
          onBlur: api => {
            if (!api.formValues.dateTo) {
              api.setFormValue(['dateTo'], api.fieldData.fieldValue);
            }
            api.validateField(['dateTo']);
          },
        } as FieldDefs,
        dateScheduledTo: {
          fieldType: FieldType.dateField,
          dataAddr: 'dateScheduledTo',
          label: 'Date Scheduled To',
          validate: d => {
            if (!d.fieldValue || !d.parentValue.dateFrom) {
              return undefined;
            }
            const from = DateTime.fromISO(d.parentValue.dateFrom);
            const to = DateTime.fromISO(d.fieldValue);
            return from > to ? 'Date To cannot be earlier than Date From' : undefined;
          },
        } as FieldDefs,
        breakdown: {
          fieldType: FieldType.yesNoField,
          dataAddr: 'breakdown',
          label: 'Breakdown',
        } as FieldDefs,
        incident: {
          fieldType: FieldType.yesNoField,
          dataAddr: 'incident',
          label: 'Incident',
        } as FieldDefs,
        accident: {
          fieldType: FieldType.yesNoField,
          dataAddr: 'accident',
          label: 'Accident',
        } as FieldDefs,
        requiresSignOff: {
          fieldType: FieldType.yesNoField,
          dataAddr: 'requiresSignOff',
          label: 'Requires Sign Off',
        } as FieldDefs,
        completionDetails: {
          fieldType: FieldType.textField,
          dataAddr: 'completionDetails',
          label: 'Completion Details',
        } as FieldDefs,
        hasCubicChanges: {
          fieldType: FieldType.yesNoField,
          dataAddr: 'hasCubicChanges',
          label: 'CUBIC Changes',
        } as FieldDefs,
        rebookPartsRequired: {
          fieldType: FieldType.yesNoField,
          dataAddr: 'rebookPartsRequired',
          label: 'Rebook - Parts Required',
        } as FieldDefs,
        isOutOfService: {
          fieldType: FieldType.yesNoField,
          dataAddr: 'isOutOfService',
          label: 'Is Out of Service',
        } as FieldDefs,
        garagedAt: {
          fieldType: FieldType.selectMultiField,
          label: 'Garaged At',
          dataAddr: 'assetHousingLocations',
          optionItems: assetModel.housedAtLocations.slice(),
          useValueOnly: true,
          valueKey: 'id',
          descriptionKey: 'description',
        } as FieldDefs,
      };

      return {
        primaryTitle: <PrimaryTitle title="Tasks"></PrimaryTitle>,
        onLoadData: listTaskModel.listItems,
        externalSearch: true,
        createLink: rootStore.account.isWorkshopDepartmentMember
          ? TaskCardItem.workshop.createTask
          : undefined,
        hasMoreData: listTaskModel.hasMoreItems,
        primaryFields: getPrimaryFields(),
        rowKey: getRowKey,
        primarySize: PagePrimarySize.full,
        primaryActions: [
          {
            actions: [
              {
                actionType: ActionType.actionCollection,
                actionGroups: [
                  {
                    actions: [
                      {
                        actionType: ActionType.actionButton,
                        label: 'Export all data to Excel',
                        icon: <ExcelIcon fixedWidth />,
                        onClick: () => {
                          const fileName = `Tasks_${DateTime.local().toFormat(
                            'yyyyMMddHHmm'
                          )}.xlsx`;
                          return listTaskModel
                            .exportToExcel({
                              ...props.getQueryParams(),
                            })
                            .then(blob => saveAs(blob, fileName));
                        },
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
        filterAction: {
          disabled: isLoadingFilters,
          defaultValues: {
            statuses: [1, 2, 3],
          },
          filterFields: Object.keys(filterFieldDefsLookup).map(
            k => filterFieldDefsLookup[k]
          ) as FieldDefs[],
          filterDef: () => [
            {
              panes: [
                {
                  paneType: PaneType.formFieldsPane,
                  fields: [filterFieldDefsLookup.asset],
                },
                {
                  paneType: PaneType.formFieldsPane,
                  fields: [filterFieldDefsLookup.lowFloor, filterFieldDefsLookup.category],
                  columnCount: 2,
                },
                {
                  paneType: PaneType.formFieldsPane,
                  fields: [filterFieldDefsLookup.status, filterFieldDefsLookup.onHold],
                  columnCount: 2,
                },
                {
                  paneType: PaneType.formFieldsPane,
                  fields: [filterFieldDefsLookup.requiresSignOff, filterFieldDefsLookup.garagedAt],
                  columnCount: 2,
                },
                {
                  paneType: PaneType.formFieldsPane,
                  fields: [filterFieldDefsLookup.completionDetails],
                },
                {
                  paneType: PaneType.formFieldsPane,
                  fields: [
                    filterFieldDefsLookup.dateCreatedFrom,
                    filterFieldDefsLookup.dateCreatedTo,
                  ],
                  columnCount: 2,
                },
                {
                  paneType: PaneType.formFieldsPane,
                  fields: [
                    filterFieldDefsLookup.dateScheduledFrom,
                    filterFieldDefsLookup.dateScheduledTo,
                  ],
                  columnCount: 2,
                },
                {
                  paneType: PaneType.formFieldsPane,
                  fields: [
                    filterFieldDefsLookup.breakdown,
                    filterFieldDefsLookup.incident,
                    filterFieldDefsLookup.accident,
                    filterFieldDefsLookup.hasCubicChanges,
                  ],
                  columnCount: 4,
                },
                {
                  paneType: PaneType.formFieldsPane,
                  fields: [
                    filterFieldDefsLookup.rebookPartsRequired,
                    filterFieldDefsLookup.isOutOfService,
                  ],
                  columnCount: 2,
                },
              ],
            },
          ],
        },
      };
    };

    return (
      <ListPage
        className="list-tasks-component"
        data={listTaskModel.items.slice()}
        def={getPageDef()}
      />
    );
  }
);

export default withQueryParams(ListTasks);
