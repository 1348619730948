import './FilterActionButton.scss';
import { Component } from 'react';
import queryString from 'query-string';
import { Button } from 'reactstrap';
import { RouteComponentProps } from 'react-router-dom';
import { DateTime } from 'luxon';
import { shallowMemoize } from 'src/infrastructure/memoizeOne';
import deepEqual from 'src/infrastructure/deepEqual';
import Omit from 'src/infrastructure/omit';
import { isDefined, isPromise } from 'src/infrastructure/typeUtils';
import { flatten } from 'src/infrastructure/arrayUtils';
import { nonStrictEqual } from 'src/infrastructure/nonStrictEqual';
import { FilterIcon, TimesIcon } from 'src/images/icons';
import withRouteProps from 'src/views/hocs/withRouteProps';
import { IShellModalTriggerApi, ShellModalSize } from 'src/views/components/Shell/ShellModal';
import { set } from 'src/views/components/Page/forms';
import {
  ActionType,
  FieldDefs,
  IActionData,
  IActionDef,
  IActionMeta,
  IFieldFunctionsDef,
  IFilterActionButtonDef,
  IPanelDef,
  isDateFieldDef,
  isSelectAsyncFieldDef,
  isSelectAsyncMultiFieldDef,
  isSelectFieldDef,
  isSelectMultiFieldDef,
  isToggleButtonFieldDef,
  isToggleMultiButtonFieldDef,
  isYesNoFieldDef,
  PaneType,
} from 'src/views/definitionBuilders/types';
import ModalActionButton from './ModalActionButton';
import { isMandatory } from 'src/views/components/Page/fields/PageField';

interface IDefaultableQueryParams {
  defaultFilter?: boolean;
  ignoreFilters?: string | boolean;
}

interface IFilterListItemProps {
  queryParams: queryString.ParsedQuery;
  paramKey: string;
  paramValue: string;
  fieldDef: FieldDefs & IFieldFunctionsDef<any>;
  onRemove: () => void;
  className: string;
  disabled?: boolean;
}

interface IFilterListItemState {
  prevProps: Partial<IFilterListItemProps>;
  textPromise: Promise<string> | undefined;
  textResolved: string | undefined;
  mandatory: boolean | undefined;
}

class FilterListItem extends Component<IFilterListItemProps, IFilterListItemState> {
  static getDerivedStateFromProps(
    nextProps: Readonly<IFilterListItemProps>,
    prevState: IFilterListItemState
  ) {
    // Deep compare the props (except the remove function) and don't update the state if nothing has changed
    // This is to reduce the API calls for select async fields in the queryParams
    const { onRemove: pr, ...pp } = prevState.prevProps;
    const { onRemove: nr, ...np } = nextProps;
    if (deepEqual(pp, np)) {
      return null;
    }

    const { fieldDef, paramValue, queryParams, onRemove } = nextProps;

    // Whenever a function is in these props/defs, it's a new func every time, so we get rerenders.
    // This is to fix: 4950, but could potentially break things if somehow the text function gets different data.
    let text: string | Promise<string> = '';
    //@ts-ignore
    if (!!fieldDef.loadItems) {
      text =
        prevState.prevProps.paramValue === nextProps.paramValue
          ? prevState.textResolved ??
            prevState.textPromise ??
            FilterListItem.getItemText(fieldDef, paramValue, queryParams, onRemove)
          : FilterListItem.getItemText(fieldDef, paramValue, queryParams, onRemove);
    } else {
      text = FilterListItem.getItemText(fieldDef, paramValue, queryParams, onRemove);
    }
    const mandatory = isMandatory(
      fieldDef.mandatory,
      fieldDef.readonly,
      FilterListItem.getFieldData(fieldDef, paramValue, queryParams, onRemove)
    );
    if (isPromise(text) && prevState.textPromise !== text) {
      return {
        textPromise: text,
        textResolved: undefined,
        mandatory: mandatory,
        prevProps: nextProps,
      };
    } else if (!isPromise(text) && prevState.textResolved !== text) {
      return {
        textPromise: undefined,
        textResolved: text,
        mandatory: mandatory,
        prevProps: nextProps,
      };
    }
    return { prevProps: nextProps };
  }

  private static getFieldData = (
    fieldDef: FieldDefs,
    value: string,
    queryParams: queryString.ParsedQuery,
    // tslint:disable-next-line:no-any
    pageValue: any
  ) => ({
    pageValue,
    sectionValue: queryParams,
    panelValue: queryParams,
    paneValue: queryParams,
    parentValue: queryParams,
    fieldValue: value,
    fieldDataAddr:
      ((fieldDef.dataAddr && flatten([fieldDef.dataAddr])) as Array<string | number>) || [],
  });

  private static getItemText = (
    fieldDef: FieldDefs,
    value: string,
    queryParams: queryString.ParsedQuery,
    // tslint:disable-next-line:no-any
    pageValue: any
  ): string | Promise<string> => {
    if (isDateFieldDef(fieldDef)) {
      return DateTime.fromISO(value).toLocaleString(DateTime.DATE_MED);
    }
    if (isYesNoFieldDef(fieldDef)) {
      return nonStrictEqual(value, true) ? 'Yes' : 'No';
    }
    if (
      isSelectFieldDef(fieldDef) ||
      isSelectMultiFieldDef(fieldDef) ||
      isToggleButtonFieldDef(fieldDef) ||
      isToggleMultiButtonFieldDef(fieldDef)
    ) {
      const optionItems =
        typeof fieldDef.optionItems === 'function'
          ? fieldDef.optionItems(
              FilterListItem.getFieldData(fieldDef, value, queryParams, pageValue)
            )
          : fieldDef.optionItems;
      const item = (optionItems || []).find(i => nonStrictEqual(i[fieldDef.valueKey], value));
      return (item && item[fieldDef.descriptionKey]) || value;
    }
    if (
      (isSelectAsyncFieldDef(fieldDef) || isSelectAsyncMultiFieldDef(fieldDef)) &&
      fieldDef.loadItems
    ) {
      return fieldDef
        .loadItems([value], FilterListItem.getFieldData(fieldDef, value, queryParams, pageValue))
        .then(r => r.options[0][fieldDef.descriptionKey] as string)
        .catch(e => value);
    }

    return value;
  };

  constructor(props: IFilterListItemProps) {
    super(props);
    this.state = {
      prevProps: {},
      textPromise: undefined,
      textResolved: undefined,
      mandatory: undefined,
    };
  }

  componentDidMount() {
    this.resolveTextPromise();
  }

  componentDidUpdate(
    prevProps: Readonly<IFilterListItemProps>,
    prevState: Readonly<IFilterListItemState>
  ) {
    if (this.state.textPromise !== prevState.textPromise) {
      this.resolveTextPromise();
    }
  }

  private readonly resolveTextPromise = () => {
    const { textPromise, textResolved } = this.state;
    if (textPromise && !textResolved) {
      textPromise
        .catch(e => undefined)
        .then(r => this.setState({ textResolved: r || '<unknown>' }));
    }
  };

  render() {
    const { paramKey, fieldDef, onRemove } = this.props;
    const { textResolved, textPromise, mandatory } = this.state;
    const title = fieldDef.label || paramKey;
    const formattedText = textResolved || (textPromise ? 'Loading ...' : '');
    const fullDescription = `${title}: ${formattedText}`;
    return (
      <li className={`filter-list-item ${this.props.className}`}>
        <div className="text-container">
          <span className="name">{title}</span>
          <span title={fullDescription}>{formattedText}</span>
        </div>
        {mandatory ? null : (
          <Button
            className="remove-item"
            disabled={this.props.disabled ?? false}
            title={`Remove ${fullDescription}`}
            onClick={onRemove}>
            <TimesIcon />
          </Button>
        )}
      </li>
    );
  }
}

interface IFilterListProps {
  actionDef: IFilterActionButtonDef;
  actionData: IActionData;
  queryParams: queryString.ParsedQuery;
  onSetQueryParams: (params: {}) => void;
}

interface IFilterListState {
  items: Array<{
    key: string;
    paramKey: string;
    paramValue: string;
    // tslint:disable-next-line:no-any
    fieldDef: FieldDefs & IFieldFunctionsDef<any>;
    onRemove: () => void;
  }>;
}

export class FilterList extends Component<IFilterListProps, IFilterListState> {
  static getDerivedStateFromProps(
    nextProps: Readonly<IFilterListProps>,
    prevState: IFilterListState
  ) {
    const { actionDef, queryParams, onSetQueryParams } = nextProps;
    const items = Object.keys(queryParams)
      .map((paramKey, i) => {
        const fieldDef = actionDef.filterFields.find(f => f.dataAddr === paramKey);
        if (!fieldDef) {
          return undefined;
        }
        const paramValue = queryParams[paramKey];
        return {
          paramKey,
          paramValue,
          fieldDef,
        };
      })
      .filter(isDefined)
      .filter(item => item.paramValue !== undefined)
      .filter(item => item.fieldDef.hidden === false || item.fieldDef.hidden === undefined)
      .reduce(
        (acc, item) => {
          const value = item.paramValue;
          if (Array.isArray(value)) {
            const subItems = value.map((v, idx) => {
              const removeArrayItem = () =>
                onSetQueryParams({
                  ...queryParams,
                  [item.paramKey]: value.filter((_, i) => idx !== i),
                });
              return {
                ...item,
                key: `${item.paramKey}-${idx}`,
                paramValue: v,
                onRemove: removeArrayItem,
              };
            });
            return [...acc, ...subItems];
          } else {
            const onRemove = () => onSetQueryParams({ ...queryParams, [item.paramKey]: undefined });
            return [...acc, { ...item, key: item.paramKey, paramValue: value, onRemove }];
          }
        },
        [] as Array<{
          paramKey: string;
          paramValue: string | string[] | undefined | null;
          fieldDef: FieldDefs;
        }>
      );

    return { items };
  }

  constructor(props: IFilterListProps) {
    super(props);
    this.state = { items: [] };
  }

  render() {
    const { queryParams } = this.props;
    const { items } = this.state;

    return (
      <ul className="filter-items list-unstyled">
        {items.map(item => {
          return (
            <FilterListItem
              className={
                queryParams.ignoreFilters === undefined || queryParams.ignoreFilters === 'false'
                  ? ''
                  : 'unused-filter'
              }
              disabled={
                queryParams.ignoreFilters !== undefined && queryParams.ignoreFilters === 'true'
              }
              queryParams={queryParams}
              {...item}
            />
          );
        })}
      </ul>
    );
  }
}

interface IFilterActionButtonProps {
  actionDef: IFilterActionButtonDef;
  actionMeta: IActionMeta;
  actionData: IActionData;
  onBuildExternalTrigger?: (api: IShellModalTriggerApi) => void;
}

type InternalProps = IFilterActionButtonProps & RouteComponentProps<{}>;

class FilterActionButton extends Component<InternalProps> {
  componentDidMount() {
    this.checkForWildcardQueryParams();
  }

  componentDidUpdate() {
    this.checkForWildcardQueryParams();
  }

  private readonly checkForWildcardQueryParams = () => {
    const params = this.getQueryParams();
    if (params.defaultFilter) {
      this.setQueryParams(this.props.actionDef.defaultValues);
    }
  };

  private readonly getQueryParams = (): queryString.ParsedQuery & IDefaultableQueryParams => {
    return queryString.parse(this.props.location.search);
  };

  private readonly setQueryParams = (params: {}) => {
    // Convert any falsie values (except zero or explicit false) to undefined so they don't show in the url
    const updatedParams = {};
    Object.keys(params || {})
      .map(k => ({ k, v: params[k] }))
      .forEach(x => (updatedParams[x.k] = !!x.v || x.v === 0 || x.v === false ? x.v : undefined));
    this.props.history.replace({ search: queryString.stringify(updatedParams) });
  };

  private readonly getResetData = () => {
    const { actionDef } = this.props;
    const currentParams = this.getQueryParams();
    // Clear out the values of any defined filter fields, but leave the rest
    actionDef.filterFields.forEach(f => set(currentParams, f.dataAddr, undefined, true));
    // Include the defaults
    return { ...currentParams, ...actionDef.defaultValues };
  };

  private readonly getDefaultFilterDef = shallowMemoize(
    (filterFields: FieldDefs[]): IPanelDef[] => {
      return [
        {
          panes: [
            {
              paneType: PaneType.formFieldsPane,
              fields: filterFields,
            },
          ],
        },
      ];
    }
  );

  render() {
    const { actionDef, actionMeta, actionData, onBuildExternalTrigger } = this.props;
    const queryParams = this.getQueryParams();
    return (
      <div className="filter-action-button-component variable-size">
        <ModalActionButton
          actionDef={{
            ...(actionDef as Omit<IActionDef, 'label'>),
            actionType: ActionType.modalActionButton,
            label: 'Filter',
            icon: <FilterIcon />,
            level: 'primary',
            onOpenModal: actionDef.onOpenModal,
            onCloseModal: actionDef.onCloseModal,
            disabled:
              queryParams.ignoreFilters !== undefined && queryParams.ignoreFilters === 'true',
            modalSize: actionDef.filterSize || ShellModalSize.oneThird,
            modalDef: defApi => ({
              title: 'Filter',
              panels: actionDef.filterDef
                ? actionDef.filterDef(defApi)
                : this.getDefaultFilterDef(actionDef.filterFields),
              asForm: true,
              suppressLeavePrompt: true,
              explicitData: this.getQueryParams(),
              secondaryActions: [
                {
                  actions: [
                    {
                      actionType: ActionType.submitActionButton,
                      label: 'Apply',
                      level: 'primary',
                    },
                    {
                      actionType: ActionType.resetActionButton,
                      label: actionDef.defaultValues ? 'Default' : 'Clear',
                      resetToData: this.getResetData,
                    },
                  ],
                },
              ],
              onFormSubmit: values => Promise.resolve(this.setQueryParams(values)),
            }),
          }}
          actionMeta={{ ...actionMeta, circular: true }}
          actionData={actionData}
          onBuildExternalTrigger={onBuildExternalTrigger}
          showCloseButton
        />
        <FilterList
          actionDef={actionDef}
          actionData={actionData}
          queryParams={queryParams}
          onSetQueryParams={this.setQueryParams}
        />
      </div>
    );
  }
}

export default withRouteProps(FilterActionButton);
