import { JobTaskCategory } from 'src/api/enums';
import { getSubmitCloseModalActionGroupDef } from 'src/views/definitionBuilders/common';
import { PaneType, ModalDefBuilder, FieldType } from 'src/views/definitionBuilders/types';
import { isDefined } from 'src/infrastructure/typeUtils';

type ServiceCycleItem = Workshop.Domain.Queries.AssetGroup.GetAssetGroupFullServiceCycle.ServiceCycleItem;
type JobTaskDetails = Workshop.Domain.Queries.GetJobTask.JobTaskDetails;

const getHideResetSchedule = (jobTask: JobTaskDetails | undefined) => {
  if (
    !jobTask ||
    !jobTask.servicePlanItem ||
    (jobTask.asset.subcategory.hasOdometer &&
      !jobTask.odometerReading &&
      !jobTask.servicePlanItem.dueKms)
  ) {
    return true;
  }
  if (jobTask && jobTask.category.id !== JobTaskCategory.Service) {
    return true;
  }
  if (
    !jobTask.servicePlanItem.dueKms &&
    jobTask.servicePlanItem.dueDate &&
    jobTask.job.endDateTime >= jobTask.servicePlanItem.dueDate
  )
    return true;
  if (
    jobTask.servicePlanItem.dueKms &&
    jobTask.odometerReading &&
    jobTask.odometerReading >= jobTask.servicePlanItem.dueKms &&
    jobTask.servicePlanItem.dueDate &&
    jobTask.job.endDateTime >= jobTask.servicePlanItem.dueDate
  ) {
    return true;
  }
  return false;
};

export default function getCompleteJobTaskModalDef(
  jobTask: JobTaskDetails | undefined,
  onCompleteJobTask: (
    jobTaskId: string,
    resetServicePlan: boolean,
    lastServiceDate: string,
    lastServiceKms: number,
    serviceCycleItem: ServiceCycleItem | undefined
  ) => Promise<void>,
  jobTaskId: string,
  fullServiceCycle: Array<ServiceCycleItem>
): ModalDefBuilder {
  const fullServiceCycleForSelect = fullServiceCycle.map(x => {
    return {
      ...x,
      key: `${x.serviceType.description}-${x.dueDays || 0}-${x.dueKms || 0}`,
    };
  });
  return modalDefApi => ({
    title: 'Complete Task',
    explicitData: {
      fullServiceCycle,
      jobTask,
      startingKms: jobTask?.odometerReading,
      startingDate: jobTask?.job.endDateTime,
      dueKms: jobTask?.servicePlanItem?.dueKms,
      dueDate: jobTask?.servicePlanItem?.dueDate,
    },
    asForm: true,
    panels: [
      {
        panes: [
          {
            paneType: PaneType.customPane,
            render: api => <p>Please confirm that you want to mark this task as completed?</p>,
          },
        ],
      },
      {
        panes: [
          {
            paneType: PaneType.customPane,
            hidden: getHideResetSchedule(jobTask),
            render: api => (
              <p>
                You are about to complete this service earlier than planned, do you want to bring
                the remaining scheduled services forward?
                <br />
                Please note, this will clear the remaining upcoming services and will re-generate
                the service schedule for this asset.
              </p>
            ),
          },
          {
            paneType: PaneType.formFieldsPane,
            hidden: getHideResetSchedule(jobTask),
            fields: [
              {
                fieldType: FieldType.yesNoField,
                label: 'Bring Remaining Services Forward?',
                dataAddr: 'resetServicePlan',
              },
              {
                fieldType: FieldType.selectField,
                label: 'This Service',
                dataAddr: 'serviceCycleItem',
                valueKey: 'key',
                descriptionKey: 'none',
                mandatory: true,
                tooltip:
                  'Please indicate which service from the full service cycle (shown below) this service corresponds to',
                hidden: api => !api.panelValue.resetServicePlan,
                optionItems: fullServiceCycleForSelect,
                optionRenderer: (o: ServiceCycleItem) => (
                  <div>
                    <span>{`${o.serviceType.description}`}</span>
                    {(isDefined(o.dueDays) || isDefined(o.dueKms)) && (
                      <>
                        &emsp;
                        <small>
                          {`${isDefined(o.dueDays) ? `${o.dueDays} days` : ''}${
                            isDefined(o.dueDays) && isDefined(o.dueKms) ? ' - ' : ''
                          }${isDefined(o.dueKms) ? `${o.dueKms} kms` : ''}`}
                        </small>
                      </>
                    )}
                  </div>
                ),
                useOptionRendererAsValueRenderer: true,
              },
              {
                fieldType: FieldType.dateField,
                dataAddr: 'startingDate',
                label: 'This Service Date',
                mandatory: true,
                hidden: true,
              },
              {
                fieldType: FieldType.dateField,
                dataAddr: 'dueDate',
                label: 'Due Service Date',
                mandatory: true,
                hidden: true,
              },
              {
                fieldType: FieldType.numericField,
                dataAddr: 'startingKms',
                formatReadonly: d => {
                  return d.paneValue.jobTask.odometerReading;
                },
                label: 'This Service Kms',
                maxLength: 7,
                numericConfig: { numericType: 'unsignedInt', minValue: 0 },
                hidden: true,
              },
              {
                fieldType: FieldType.numericField,
                dataAddr: 'dueKms',
                formatReadonly: d => {
                  return d.paneValue.jobTask.odometerReading;
                },
                label: 'Due Service Kms',
                maxLength: 7,
                numericConfig: { numericType: 'unsignedInt', minValue: 0 },
                hidden: true,
              },
            ],
          },
        ],
      },
      {
        hidden: api => getHideResetSchedule(jobTask) || !api.panelValue.length,
        dataAddr: 'fullServiceCycle',
        panes: [
          {
            paneType: PaneType.customPane,
            render: api => {
              return (
                <>
                  <hr />
                  <h3 className="header">Full Service Cycle</h3>
                </>
              );
            },
          },
          {
            paneType: PaneType.tablePane,
            neverEditable: true,
            mandatory: false,
            fields: [
              {
                fieldType: FieldType.textField,
                label: 'Service Type',
                dataAddr: ['serviceType', 'description'],
                columnWidth: '12em',
                readonly: true,
              },
              {
                fieldType: FieldType.numericField,
                label: 'Days Due',
                dataAddr: 'dueDays',
                columnWidth: '9em',
                readonly: true,
              },
              {
                fieldType: FieldType.numericField,
                label: 'Kms Due',
                dataAddr: 'dueKms',
                columnWidth: '9em',
                readonly: true,
              },
            ],
          },
        ],
      },
    ],
    secondaryActions: [getSubmitCloseModalActionGroupDef('Ok')],
    onFormSubmit: (values: {
      resetServicePlan: boolean;
      startingDate: string;
      startingKms: number;
      serviceCycleItem: ServiceCycleItem;
    }) => {
      return onCompleteJobTask(
        jobTaskId,
        values.resetServicePlan,
        values.startingDate,
        values.startingKms,
        values.serviceCycleItem
      );
    },
  });
}
