import { IRootStoreModel } from 'src/domain/entities/RootStoreModel';
import { UiRoute } from 'src/domain/services/buildUiRoutes';
import { UiRouteName } from 'src/domain/baseTypes';

export default function buildUiRoutes(store: IRootStoreModel) {
  const quoteCreate = new UiRoute(
    UiRouteName.salesQuoteCreate,
    '/sales/quotes/create',
    () => 'Create Quote',
    false
  );

  const quoteUpdate = new UiRoute(
    UiRouteName.salesQuoteUpdate,
    '/sales/quotes/:id',
    () => 'Manage Quote',
    false
  );

  const bookingUpdate = new UiRoute(
    UiRouteName.salesBookingUpdate,
    '/sales/bookings/:id',
    () => 'Manage Booking',
    false
  );

  const quoteList = new UiRoute(
    UiRouteName.salesQuoteList,
    '/sales/quotes',
    () => 'Quotes',
    true,
    false,
    [quoteCreate, quoteUpdate, bookingUpdate]
  );
  return {
    [UiRouteName.salesQuoteList]: quoteList,
    [UiRouteName.salesQuoteCreate]: quoteCreate,
    [UiRouteName.salesQuoteUpdate]: quoteUpdate,
    [UiRouteName.salesBookingUpdate]: bookingUpdate,
  };
}
