import { useState } from 'react';
import { DateTime } from 'luxon';
import {
  BatchOperationStatus,
  batchOperationStatusDescription,
  ChangeState,
  ShiftType,
} from 'src/api/enums';
import {
  PagePrimarySize,
  PaneType,
  FieldType,
  ActionType,
  ShellModalSize,
} from 'src/views/definitionBuilders/types';
import CrudPage, { CrudPageMode, ICrudPageDef } from 'src/views/components/Page/pages/CrudPage';
import { StaffMemberFilter } from 'src/views/components/Page/fields/StaffMemberField';
import { IFormApiWithoutState } from 'src/views/components/Page/forms/base';
import { RouteComponentProps } from 'react-router-dom';
import { CheckIcon, SyncIcon, TrashIcon } from 'src/images/icons';
import {
  getSkipFatigueActionGroupDef,
  ISkipFatigueValidationSubmissionMeta,
} from 'src/views/routes/operations/shared/getSkipFatigueActionGroupDef';
import { PlusIcon } from 'src/images/icons';
import getAddRosterGroupModalDef from 'src/views/routes/operations/job/generateJobs/getAddRosterGroupModalDef';
import { formatDateShort, formatDayOfWeekLong } from 'src/domain/dateHelper';
import { observer } from 'mobx-react';
import { useRootStore } from 'src/domain/entities/RootStoreModel';
import { Button } from 'reactstrap';
import { FieldInfoTooltip } from 'src/views/components/FieldInfoTooltip';
import styles from './GenerateJobs.module.scss';
import { Link } from 'react-router-dom';
import { WORKING_WEEK_START_DAY } from 'src/appSettings';
import { isInSameConfigWeek, shouldShowRecalc, returnEndDay } from './CalculateRosterPeriods';

type GenerateJobsCommand = Operations.Domain.Commands.Job.GenerateJobs.GenerateJobsCommand;
type RosterListItem = Operations.Domain.Queries.ListRosters.RosterListItem;
type GenerateJobsOperationDetails = Operations.Domain.Queries.GetGenerateJobsOperation.GenerateJobsOperationDetails;
type StaffMemberName = Common.Queries.GetStaffMemberNames.StaffMemberName;

export interface IGenerateJobsFormRosterItem {
  roster: RosterListItem;
  staffMember: StaffMemberName | undefined;
  changeState: ChangeState;
}

interface IGenerateJobsForm {
  startDate: string;
  endDate: string;
  rosters: IGenerateJobsFormRosterItem[];
}

export interface IGenerateJobsProps {
  mode: CrudPageMode;
  route: RouteComponentProps<{ [x: string]: string | undefined }>;
}

export const GenerateJobs: React.FC<IGenerateJobsProps> = observer((props: IGenerateJobsProps) => {
  const [formApi, setFormApi] = useState<IFormApiWithoutState | undefined>(undefined);
  const [showRotatingRosters, setShowRotatingRosters] = useState<boolean>(true);
  const [hasRotatingRoster, setHasRotatingRoster] = useState<boolean>(false);
  const [showRecalc, setShowRecalc] = useState<boolean>(false);

  const rootStore = useRootStore();
  const allStaffShiftTypes = [ShiftType.Cleaning, ShiftType.Operations, ShiftType.Office];
  const rosters = rootStore.operations.urban.rosters.rosters.slice().map(r => {
    return {
      ...r,
      isAllStaffShifts: r.shifts.every(
        (s: Operations.Domain.Queries.ListRosters.ShiftItem) =>
          allStaffShiftTypes.indexOf(s.shiftType.id) !== -1
      ),
    };
  });
  const rosterGroups = rootStore.operations.urban.rosterGroups.rosterGroups.slice();
  const rotatingRosters = rootStore.operations.urban.rosters.rotatingRosters.slice();
  const allStaffMemberNames = rootStore.people.staffMembers.allStaffMembers.slice();
  const listRosters = rootStore.operations.urban.rosters.listRosters;
  const loadRosterGroups = rootStore.operations.urban.rosterGroups.listRosterGroups;
  const loadAllStaffMemberNames = rootStore.people.staffMembers.loadAllStaffMembers;
  const listStaffMembersForRotatingRosters =
    rootStore.operations.urban.rosters.listStaffMembersForRotatingRosters;
  const acknowledgeFailedGenerateJobsOperation =
    rootStore.operations.generateJobsOperation.acknowledgeFailedGenerateJobsOperation;
  const generateJobs = rootStore.operations.generateJobsOperation.createGenerateJobsOperation;
  const getGenerateJobsOperationDetails =
    rootStore.operations.generateJobsOperation.getGenerateJobsOperationDetails;
  const generateJobsOperationDetails = rootStore.operations.generateJobsOperation.item;
  const canSkipFatigueValidation = rootStore.account.isAdminUser;
  const deleteGeneratedJobs = rootStore.operations.generateJobsOperation.deleteGeneratedJobs;

  const operationId = props.route.match.params.id;

  const rotatingRosterIds = rosters
    .filter(r => r.rosterGroup && r.rosterGroup.isRotating)
    .map(r => r.id);

  const isRotatingRoster = (rosterGroupId: string) =>
    rosterGroups.find(x => x.isRotating && x.rosterGroupId === rosterGroupId);

  const rotatingRosterStartDay = WORKING_WEEK_START_DAY;
  const rotatingRosterEndDay = returnEndDay(rotatingRosterStartDay);
  const startDayName = formatDayOfWeekLong(rotatingRosterStartDay);
  const endDayName = formatDayOfWeekLong(rotatingRosterEndDay);

  const handlePreSubmitForCreate = (
    form: IGenerateJobsForm,
    meta: ISkipFatigueValidationSubmissionMeta | undefined
  ): GenerateJobsCommand => {
    return {
      startDate: form.startDate,
      endDate: form.endDate,
      jobTemplates: form.rosters
        .filter(r => r.changeState !== ChangeState.Deleted)
        .map(x => {
          return {
            rosterId: x.roster.id,
            staffMemberId: x.staffMember && x.staffMember.id,
          };
        }),
      skipFatigueValidation: meta?.skipFatigueValidation || false,
    };
  };

  const addAllRosters = () => {
    let allRosters: RosterListItem[];
    allRosters = showRotatingRosters ? rosters : rosters.filter(r => !r.rosterGroup);

    //Use a promise to show a spinner, even though this is synchronous
    return new Promise<void>((resolve, reject) => {
      setTimeout(() => {
        if (!formApi) {
          reject('Cannot add all rosters as formApi is undefined');
          return;
        }
        formApi.setValue(
          'rosters',
          allRosters.map(r => {
            const isRotatingRosterGroup = r && r.rosterGroup && r.rosterGroup.isRotating;
            return {
              roster: r,
              staffMember: isRotatingRosterGroup
                ? rotatingRosters.find(rr => rr.rosterId === r.id)?.staffMember
                : r.staffMember,
              changeState: ChangeState.Added,
            };
          })
        );
        resolve();
      }, 0);
    });
  };

  const isUpdateMode = props.mode === 'update';
  const isCreateMode = props.mode === 'create';

  const getPageDef = (
    rosters: RosterListItem[],
    item: GenerateJobsOperationDetails | undefined,
    updating: boolean
  ): ICrudPageDef => {
    const isEditing = isCreateMode || (isUpdateMode && updating);
    return {
      primarySize: PagePrimarySize.full,
      primarySection: {
        badge: {
          label:
            item?.generateJobsStatusId === undefined
              ? ''
              : batchOperationStatusDescription(item?.generateJobsStatusId),
        },
        title: d =>
          isCreateMode
            ? 'Generate New Jobs'
            : `Maintain Generate Jobs Operation ${d.sectionValue.generateJobsOperationNumber}`,
        defaultTouchedFields: ['rosters'],
        getApi: api => setFormApi(api),
        panels: [
          {
            panes: [
              {
                paneType: PaneType.formFieldsPane,
                columnCount: 5,
                fields: [
                  {
                    fieldType: FieldType.dateField,
                    label: 'Start',
                    dataAddr: 'startDate',
                    mandatory: true,
                    onChange: d => {
                      if (d.fieldData.fieldValue) {
                        return listStaffMembersForRotatingRosters(d.fieldData.fieldValue);
                      }

                      return d.fieldData.fieldValue;
                    },
                  },
                  {
                    fieldType: FieldType.dateField,
                    label: 'End',
                    dataAddr: 'endDate',
                    mandatory: true,
                    onChange: d => {
                      if (d.fieldData.fieldValue) {
                        if (d.oldFieldValue && d.oldFieldValue !== d.newFieldValue) {
                          const oldDate = DateTime.fromISO(d.oldFieldValue);
                          const newDate = DateTime.fromISO(d.newFieldValue);
                          const recal = shouldShowRecalc(oldDate, newDate, rotatingRosterStartDay);
                          setShowRecalc(recal);
                        }
                      }
                    },
                    validate: d => {
                      const hasDates = !!d.parentValue.startDate && !!d.parentValue.endDate;
                      const startDate = DateTime.fromISO(d.parentValue.startDate);
                      const endDate = DateTime.fromISO(d.parentValue.endDate);
                      const dateDiff = endDate.diff(startDate, 'days');
                      const isInSameWeek = isInSameConfigWeek(
                        startDate,
                        endDate,
                        rotatingRosterStartDay
                      );

                      if (hasDates) {
                        if (startDate > endDate) {
                          setShowRecalc(false);
                          return 'End date has to be after start date';
                        }

                        if (hasRotatingRoster) {
                          if (dateDiff.days > 6 || !isInSameWeek) {
                            setShowRecalc(false);
                            return `Start and End dates must be within the same working week ${startDayName} - ${endDayName} to generate rotating rosters`;
                          }
                        }
                      }

                      return undefined;
                    },
                  },
                  {
                    fieldType: FieldType.customField,
                    label: '',
                    dataAddr: 'fake',
                    hidden: _ =>
                      !showRotatingRosters || !hasRotatingRoster || !isEditing || !showRecalc,
                    render: () => (
                      <div className={styles.recalculateButton}>
                        <Button color="primary" outline={true} onClick={resetRotatingRosters}>
                          <SyncIcon /> Recalculate
                        </Button>
                        <FieldInfoTooltip className={styles.tooltip}>
                          The date range has been changed to a new week. The driver positions below
                          are not correct for the new dates. Recalculate to assign the drivers to
                          the correct rosters
                        </FieldInfoTooltip>
                      </div>
                    ),
                  },
                  {
                    fieldType: FieldType.yesNoField,
                    dataAddr: 'skipFatigueValidation',
                    label: 'Fatigue Validation Skipped?',
                    hidden: isCreateMode,
                    readonly: true,
                  },
                ],
              },
              {
                paneType: PaneType.formFieldsPane,
                hidden: isCreateMode,
                columnCount: 2,
                fields: [
                  {
                    fieldType: FieldType.dateTimeField,
                    dataAddr: 'createdOn',
                    readonly: true,
                    label: 'Created On',
                  },
                  {
                    fieldType: FieldType.textField,
                    dataAddr: 'createdBy',
                    readonly: true,
                    label: 'Created By',
                  },
                ],
              },
              {
                paneType: PaneType.formFieldsPane,
                hidden: d => d.parentValue.generateJobsStatusId !== BatchOperationStatus.Failed,
                fields: [
                  {
                    fieldType: FieldType.textField,
                    dataAddr: 'failReason',
                    readonly: true,
                    label: 'Failure Reason',
                  },
                ],
              },
              {
                paneType: PaneType.formFieldsPane,
                hidden: d => d.parentValue.generateJobsStatusId !== BatchOperationStatus.Failed,
                columnCount: 4,
                fields: [
                  {
                    fieldType: FieldType.textField,
                    dataAddr: 'failAcknowledgedBy',
                    readonly: true,
                    label: 'Failure Acknowledged By',
                  },
                ],
              },
              {
                paneType: PaneType.formFieldsPane,
                hidden: d => d.parentValue.generateJobsStatusId !== BatchOperationStatus.Succeeded,
                columnCount: 4,
                fields: [
                  {
                    fieldType: FieldType.readonlyField,
                    dataAddr: 'generatedJobsCount',
                    label: 'Number of jobs Generated',
                  },
                ],
              },
            ],
          },
          {
            title: 'Rosters',
            dataAddr: 'rosters',
            panes: [
              {
                paneType: PaneType.tablePane,
                mandatory: true,
                dataRequiredForRows: 'sectionValue',
                validate: () =>
                  hasRotatingRoster && showRecalc ? 'Rosters must be recalculated' : undefined,
                fields: [
                  {
                    fieldType: FieldType.selectField,
                    label: 'Roster',
                    dataAddr: 'roster',
                    valueKey: 'id',
                    descriptionKey: 'rosterNumber',
                    mandatory: true,
                    columnWidth: '12rem',
                    optionItems: rosters,
                    optionRenderer: (d: RosterListItem) => (
                      <span>
                        {d.rosterNumber}
                        {d.rosterGroup && isRotatingRoster(d.rosterGroup.id) && (
                          <>
                            &emsp; -
                            <small>
                              &emsp; <SyncIcon /> &emsp;{d.rosterGroup.name} &emsp;
                              {d.rosterGroup.rotatingCommencementDate &&
                                formatDateShort(d.rosterGroup.rotatingCommencementDate)}
                            </small>
                          </>
                        )}
                      </span>
                    ),
                    valuesToDisable: d => {
                      const defined = (d.paneValue as IGenerateJobsFormRosterItem[]).map(
                        r => r.roster && r.roster.id
                      );

                      return showRotatingRosters ? defined : [defined, ...rotatingRosterIds];
                    },
                    onChange: api => {
                      let rowIndex = api.fieldDataAddr[1];
                      const lineStaffMemberIdAddr = [
                        ...api.fieldDataAddr.slice(0, api.fieldDataAddr.length - 1),
                        'staffMember',
                      ];

                      const rosters = (api.formValues
                        .rosters as IGenerateJobsFormRosterItem[]).filter(
                        r => r.changeState !== ChangeState.Deleted
                      );
                      const rotatingRostersSet = rosters
                        .map(r => r?.roster)
                        .filter(r => r && r.rosterGroup?.isRotating);

                      rotatingRostersSet.length >= 1
                        ? setHasRotatingRoster(true)
                        : setHasRotatingRoster(false);

                      if (rosters.length === 0) {
                        setShowRecalc(false);
                        setHasRotatingRoster(false);
                      }

                      const roster = api.fieldData.parentValue.roster as RosterListItem;
                      const rosterFromForm = api.formValues.rosters[rowIndex] as
                        | RosterListItem
                        | undefined;
                      // This logic is to prevent a staff member being populated wrong when deleting a roster row
                      // if when deleteing roster and is not apart of rotating roster, reset the row to be the selected staff member
                      // else if rotating roster get staff member from rotating list staff memmbers
                      // else set the staff member of the selected roster
                      if (rosterFromForm && rosterFromForm.staffMember) {
                        api.setFormValue(lineStaffMemberIdAddr, rosterFromForm.staffMember);
                      } else if (roster.rosterGroup && !roster.rosterGroup.isRotating) {
                        const selectedRoster = api.newFieldValue as RosterListItem;
                        api.setFormValue(
                          lineStaffMemberIdAddr,
                          selectedRoster && selectedRoster.staffMember
                        );
                      } else if (roster.rosterGroup && roster.rosterGroup.isRotating) {
                        const staffMember = rotatingRosters.find(
                          r => r.rosterId === api.fieldData.fieldValue.id
                        )?.staffMember;

                        api.setFormValue(lineStaffMemberIdAddr, staffMember);
                      } else {
                        const selectedRoster = api.newFieldValue as RosterListItem;
                        api.setFormValue(
                          lineStaffMemberIdAddr,
                          selectedRoster && selectedRoster.staffMember
                        );
                      }
                    },
                  },
                  {
                    fieldType: FieldType.staffMemberField,
                    label: 'Staff Member',
                    dataAddr: 'staffMember',
                    columnWidth: '15rem',
                    staffMemberFilter: d => {
                      if (d.parentValue.roster && d.parentValue.roster.isAllStaffShifts) {
                        return StaffMemberFilter.active;
                      }

                      return StaffMemberFilter.hasDriversAuthorisation;
                    },
                  },
                  {
                    fieldType: FieldType.readonlyField,
                    label: 'Roster Group',
                    dataAddr: 'rosterGroup',
                    columnWidth: '10rem',
                    formatReadonly: d => {
                      const roster = d.parentValue.roster;
                      if (roster && roster.rosterGroup) {
                        return (
                          <Link to={`/operations/roster-groups/${roster.rosterGroup.id}`}>
                            {roster.rosterGroup.name}{' '}
                            {roster.rosterGroup.isRotating && (
                              <span>
                                &emsp; - &emsp;
                                <SyncIcon />
                              </span>
                            )}
                          </Link>
                        );
                      }

                      return null;
                    },
                  },
                  {
                    fieldType: FieldType.readonlyField,
                    label: 'Rotation Commencement',
                    dataAddr: 'rosterGroup',
                    columnWidth: '5rem',
                    formatReadonly: d => {
                      const roster = d.parentValue.roster;
                      if (roster && roster.rosterGroup) {
                        return (
                          roster.rosterGroup.isRotating &&
                          formatDateShort(roster.rosterGroup.rotatingCommencementDate)
                        );
                      }

                      return null;
                    },
                  },
                  {
                    fieldType: FieldType.actionListField,
                    dataAddr: '',
                    columnWidth: '1px',
                    hidden: () => !isEditing,
                    actionGroups: [
                      {
                        actions: [
                          {
                            actionType: ActionType.removeArrayItemActionButton,
                            label: 'Remove Line',
                            postClick: d => {
                              const rosters = (d.paneValue as IGenerateJobsFormRosterItem[])
                                .filter(r => r.changeState !== ChangeState.Deleted)
                                .map(r => r.roster);

                              const rotatingRosters = rosters.filter(
                                r => r && r.rosterGroup?.isRotating
                              );

                              rotatingRosters.length >= 1
                                ? setHasRotatingRoster(true)
                                : setHasRotatingRoster(false);

                              if (rosters.length === 0) {
                                setShowRecalc(false);
                                setHasRotatingRoster(false);
                              }
                            },
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
              {
                paneType: PaneType.actionListPane,
                hidden: !isEditing,
                actionGroups: [
                  {
                    actions: [
                      {
                        actionType: ActionType.addArrayItemActionButton,
                        label: 'Add Roster',
                        disabled: d => !(!!d.sectionValue.startDate && !!d.sectionValue.endDate),
                      },
                      {
                        actionType: ActionType.modalActionButton,
                        label: 'Add Roster Group',
                        level: 'primary',
                        icon: <PlusIcon />,
                        modalSize: ShellModalSize.oneQuarter,
                        modalDef: getAddRosterGroupModalDef(
                          rosterGroups,
                          rosters,
                          showRotatingRosters,
                          rotatingRosters
                        ),
                        disabled: d => !(!!d.sectionValue.startDate && !!d.sectionValue.endDate),
                      },
                      {
                        actionType: ActionType.actionButton,
                        level: 'primary',
                        icon: <PlusIcon />,
                        label: showRotatingRosters
                          ? 'Add All Rosters'
                          : 'Add All Non Rotating Rosters',
                        hidden: !isCreateMode,
                        onClick: addAllRosters,
                        disabled: d => !(!!d.sectionValue.startDate && !!d.sectionValue.endDate),
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
        onFormPreSubmit: handlePreSubmitForCreate,
        onFormSubmit: d => {
          // Note that I want this check to stay without ?.
          // FailAcknowledged can also be undefined, but I want it to behave the same as if it were false.
          // So I want the check to be checking whether the details are loaded (updating) AND if it hasn't been acknowledged.
          let promise = Promise.resolve();
          if (
            isUpdateMode &&
            operationId &&
            generateJobsOperationDetails &&
            !generateJobsOperationDetails.failAcknowledged
          ) {
            promise.then(() => acknowledgeFailedGenerateJobsOperation(operationId));
          }
          return promise.then(() => generateJobs(d));
        },
        primaryActions: [
          {
            actions: [
              {
                actionType: ActionType.actionButton,
                label: 'Acknowledge Generate Jobs Failure',
                icon: <CheckIcon />,
                onClick: _ =>
                  acknowledgeFailedGenerateJobsOperation(operationId!).then(() =>
                    getGenerateJobsOperationDetails!(operationId!)
                  ),
                hidden: d => {
                  const isVisible =
                    d.actionValue?.generateJobsStatusId === BatchOperationStatus.Failed &&
                    !d.actionValue?.failAcknowledgedBy;

                  return !isVisible;
                },
              },
              {
                actionType: ActionType.actionCollection,
                actionGroups: [
                  {
                    actions: [
                      {
                        actionType: ActionType.actionButton,
                        label: 'Delete Generated Jobs',
                        icon: <TrashIcon />,
                        onClick: d => {
                          operationId &&
                            deleteGeneratedJobs!(
                              operationId,
                              d.sectionValue.generateJobsOperationNumber
                            );
                        },
                        hidden: d =>
                          d.actionValue?.generateJobsStatusId !== BatchOperationStatus.Succeeded,
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
        clearStandardSecondaryActions: true,
        secondaryActions: isEditing
          ? [
              {
                actions: [
                  {
                    actionType: ActionType.submitActionButton,
                    label: 'Submit',
                    level: 'primary',
                    subActionGroups: getSkipFatigueActionGroupDef(canSkipFatigueValidation),
                  },
                  {
                    actionType: ActionType.resetActionButton,
                  },
                ],
              },
            ]
          : [],
      },
    };
  };

  const mapRostersForUpdate = (details: GenerateJobsOperationDetails | undefined) => {
    const mapped = {
      ...details,
      rosters: details?.jobTemplates.map(x => {
        const roster = rosters.find(r => r.id === x.rosterId) || {
          id: '',
          rosterNumber: 'MISSING - No longer exists',
          shifts: [],
          staffMember: undefined,
        };
        return {
          roster: roster,
          staffMember: allStaffMemberNames?.find(t => t.id === x.staffMemberId),
        };
      }),
    };
    return mapped;
  };

  const resetRotatingRosters = () => {
    const formData = formApi?.getValue() as IGenerateJobsForm;

    let requiresUpdate = false;
    const updatedData = {
      ...formData,
      rosters: formData.rosters.map(roster => {
        if (roster.roster && roster.roster.rosterGroup && roster.roster.rosterGroup.isRotating) {
          const staffMember = rotatingRosters.find(r => r.rosterId === roster.roster.id)
            ?.staffMember;

          if (staffMember?.id !== roster.staffMember?.id) {
            requiresUpdate = true;
            const updatedRoster = {
              ...roster,
              staffMember: staffMember as StaffMemberName,
              changeState: ChangeState.Modified,
            };
            return updatedRoster;
          }
        }
        return roster;
      }),
    };

    if (requiresUpdate) formApi?.setAllValues(updatedData);
    setShowRecalc(false);
  };
  return (
    <CrudPage
      key={operationId}
      def={({ updating }) => {
        return getPageDef(rosters, generateJobsOperationDetails, updating);
      }}
      mode={props.mode}
      data={mapRostersForUpdate(generateJobsOperationDetails)}
      isEditingForbidden={
        generateJobsOperationDetails?.generateJobsStatusId !== BatchOperationStatus.Failed
      }
      onLoadData={() => {
        let promises: Array<Promise<void>> = [];
        promises.push(listRosters());
        promises.push(loadRosterGroups());
        promises.push(loadAllStaffMemberNames());

        if (getGenerateJobsOperationDetails && operationId) {
          promises.push(getGenerateJobsOperationDetails!(operationId));
        }
        let promise = new Promise<void>((resolve, reject) => {
          Promise.all(promises)
            .then(() => {
              resolve();
            })
            .catch(() => reject());
        });

        return promise;
      }}
      onLoadCreateDefaultData={() => {
        let promise = new Promise<void>((resolve, reject) => {
          Promise.all([listRosters(), loadRosterGroups(), loadAllStaffMemberNames()])
            .then(() => {
              resolve();
            })
            .catch(() => reject());
        });

        return promise;
      }}
      createDefaultData={{ rosters: [] }}
      onCancel={() => {
        setHasRotatingRoster(false);
        setShowRotatingRosters(true);
        setShowRecalc(false);
      }}
    />
  );
});

export default GenerateJobs;
