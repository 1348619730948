import { types, flow } from 'mobx-state-tree';
import { getAjax } from 'src/domain/services';
type EmploymentStatusDto = Common.Dtos.EmploymentStatusDto;

export const EmploymentStatusModel = types
  .model('EmploymentStatusModel', {
    employmentStatuses: types.array(types.frozen<EmploymentStatusDto>()),
  })
  .actions(self => {
    const ajax = getAjax(self);

    const loadEmploymentStatuses = flow(function*() {
      self.employmentStatuses = yield ajax.people.employmentStatuses.getEmploymentStatuses();
    });

    return {
      loadEmploymentStatuses,
    };
  });

type IEmploymentStatusModelType = typeof EmploymentStatusModel.Type;
export interface IEmploymentStatusModel extends IEmploymentStatusModelType {}
