import { types, flow } from 'mobx-state-tree';
import { getAjax } from 'src/domain/services';

type RailTemplateShiftListItem = Operations.Domain.Queries.ListRailTemplateShifts.RailTemplateShiftListItem;

export const RailTemplateShiftsModel = types
  .model('RailTemplateShiftsModel', {
    railTemplateShifts: types.array(types.frozen<RailTemplateShiftListItem>()),
  })
  .actions(self => {
    const ajax = getAjax(self);

    const listRailTemplateShifts = flow(function*() {
      self.railTemplateShifts = yield ajax.operations.rail.railTemplates.listRailTemplateShifts();
    });

    return {
      listRailTemplateShifts,
    };
  });
