import styles from './Home.module.scss';

import cn from 'classnames';
import HomeTile from './components/HomeTile';
import CompanyLogo from 'src/views/components/CompanyLogo/CompanyLogo';
import { isDevelopment } from '../../../appSettings';

export interface IHomeProps {
  uiRouteWorkshop: string;
  uiRouteSales: string;
  uiRouteOperations: string;
  uiRoutePeople: string;
  uiRouteAccounting: string;
  uiRouteCompliance: string;
  uiRouteDocumentation: string;
  workshopDefaultId: number | undefined;
}

const Home: React.FC<IHomeProps> = (props: IHomeProps) => (
  <div className={styles.mainContainer}>
    <div className={styles.logo}>
      <CompanyLogo mode="full" />
    </div>
    <nav>
      <ol className={cn('list-unstyled', styles.tiles)}>
        <HomeTile
          linkTo={`${props.uiRouteWorkshop}?workshopDepots=${props.workshopDefaultId}&expand=true`}
          tileTitle="Workshop"
          tileCaption="Assets, defects, parts and workshop tasks"
        />
        <HomeTile
          linkTo={`${props.uiRouteSales}?expand=true`}
          tileTitle="Sales"
          tileCaption="Customer information, quotes and bookings"
        />
        <HomeTile
          linkTo={`${props.uiRouteOperations}?expand=true`}
          tileTitle="Operations"
          tileCaption="Jobs, shifts, rosters and operations tasks"
        />
        <HomeTile
          linkTo={`${props.uiRoutePeople}?expand=true&defaultFilter=true`}
          tileTitle="People"
          tileCaption="Staff member information and notes"
        />
        <HomeTile
          linkTo={`${props.uiRouteAccounting}?expand=true&defaultFilter=true`}
          tileTitle="Accounting"
          tileCaption="Customer billing"
        />
        <HomeTile
          linkTo={`${props.uiRouteCompliance}?expand=true&defaultFilter=true`}
          tileTitle="Compliance"
          tileCaption="Compliance information"
        />
        {isDevelopment() && (
          <HomeTile
            linkTo={`${props.uiRouteDocumentation}`}
            tileTitle="Documentation"
            tileCaption="UI Framework Documentation"
          />
        )}
      </ol>
    </nav>
  </div>
);

export default Home;
