import './CalendarPicker.scss';
import { PureComponent } from 'react';
import cn from 'classnames';
import { Button } from 'reactstrap';
import { DateTime } from 'luxon';
import Calendar from 'react-calendar';
import { createPortal } from 'react-dom';
import { Popper, Target, Manager } from 'react-popper';
import { CalendarIcon } from 'src/images/icons';

const REACT_CALENDAR_ACTIVE_DAY_CLASS = 'react-calendar__tile--active';

interface ICalendarPickerProps {
  className?: string;
  buttonClassName?: string;
  today?: DateTime;
  value?: DateTime;
  onChange: (newValue: DateTime) => void;
  timezone?: string;
}

interface ICalendarPickerState {
  calendarOpen: boolean;
}

class CalendarPicker extends PureComponent<ICalendarPickerProps, ICalendarPickerState> {
  private calendarTriggerRef: HTMLButtonElement | null = null;
  private calendarDropdownRef: Element | null = null;
  private calendarRef: HTMLDivElement | null = null;

  constructor(props: ICalendarPickerProps) {
    super(props);
    this.state = {
      calendarOpen: false,
    };
  }

  private readonly handleCalendarRef = (e: HTMLDivElement | null) => {
    this.calendarRef = e;

    if (this.state.calendarOpen) {
      const activeDayButton =
        this.calendarRef &&
        (this.calendarRef
          .getElementsByClassName(REACT_CALENDAR_ACTIVE_DAY_CLASS)
          .item(0) as HTMLButtonElement | null);
      activeDayButton && activeDayButton.focus && activeDayButton.focus();
    }
  };

  private readonly handleCalendarToggle = () => {
    this.setState({ calendarOpen: !this.state.calendarOpen });
    this.manageDocumentListeners(!this.state.calendarOpen);
  };

  private readonly closeCalendar = () => {
    this.setState({ calendarOpen: false });
    this.manageDocumentListeners(false);
    this.calendarTriggerRef && this.calendarTriggerRef.focus();
  };

  private readonly manageDocumentListeners = (attach: boolean) => {
    const listener = attach ? document.addEventListener : document.removeEventListener;
    listener('click', this.handleDocumentClick, { capture: true });
    listener('keyup', this.handleEscPress);
  };

  private readonly handleDocumentClick = (e: MouseEvent) => {
    if (
      e.target &&
      !(
        (this.calendarDropdownRef && this.calendarDropdownRef.contains(e.target as Node)) ||
        (this.calendarRef && this.calendarRef.contains(e.target as Node))
      )
    ) {
      this.closeCalendar();
    }
  };

  private readonly handleEscPress = (e: KeyboardEvent) => {
    if (e.key === 'Escape') {
      this.closeCalendar();
    }
  };

  private readonly handleCalendarChange = (value: Date | Date[]) => {
    this.closeCalendar();
    const localDate = DateTime.fromJSDate(Array.isArray(value) ? value[0] : value);
    const zonedDate = DateTime.fromObject({
      year: localDate.year,
      month: localDate.month,
      day: localDate.day,
      zone: this.props.timezone || 'local',
    });

    this.props.onChange(zonedDate);
  };

  render() {
    const { className, buttonClassName, today, value, timezone } = this.props;
    const { calendarOpen } = this.state;
    const currentCalendarZonedDateTime = (value || DateTime.local()).setZone(timezone || 'local');
    const currentCalendarLocalDate = DateTime.fromObject({
      year: currentCalendarZonedDateTime.year,
      month: currentCalendarZonedDateTime.month,
      day: currentCalendarZonedDateTime.day,
    });
    const todayZonedDateTime = (today || DateTime.local()).setZone(timezone || 'local');
    const todayLocalDate = DateTime.fromObject({
      year: todayZonedDateTime.year,
      month: todayZonedDateTime.month,
      day: todayZonedDateTime.day,
    });

    return (
      <div
        className={cn('calendar-picker-component', className)}
        ref={r => (this.calendarDropdownRef = r)}>
        <Manager tag={false}>
          <Target>
            {({ targetProps }) => (
              <span {...targetProps}>
                <Button
                  innerRef={e => (this.calendarTriggerRef = e)}
                  className={cn('target-button', buttonClassName)}
                  title="Choose date"
                  outline
                  onClick={this.handleCalendarToggle}>
                  <CalendarIcon />
                </Button>
              </span>
            )}
          </Target>
          <div>
            {calendarOpen &&
              createPortal(
                <Popper eventsEnabled className="popper">
                  <div ref={this.handleCalendarRef}>
                    <Calendar
                      className="calendar"
                      value={currentCalendarLocalDate.toJSDate()}
                      onChange={e => this.handleCalendarChange(e as Date | Date[])}
                      tileClassName={({ date, view }) => {
                        return view === 'month' &&
                          DateTime.fromJSDate(date).hasSame(todayLocalDate, 'day')
                          ? 'today-tile'
                          : null;
                      }}
                    />
                  </div>
                </Popper>,
                document.getElementById('root') as HTMLElement
              )}
          </div>
        </Manager>
      </div>
    );
  }
}

export default CalendarPicker;
