import { types, getRoot, flow } from 'mobx-state-tree';
import { IRootStoreModel } from 'src/domain/entities/RootStoreModel';
import { getAjax } from 'src/domain/services';

type CreateShiftTemplateCommand = Workshop.Domain.Commands.ShiftTemplate.CreateShiftTemplate.CreateShiftTemplateCommand;
type UpdateShiftTemplateCommand = Workshop.Domain.Commands.ShiftTemplate.UpdateShiftTemplate.UpdateShiftTemplateCommand;
type ShiftTemplateItem = Workshop.Domain.Queries.ShiftTemplate.GetShiftTemplate.ShiftTemplateItem;

export const ShiftTemplateModel = types
  .model('ShiftTemplateModel', {
    shiftTemplate: types.maybe(types.frozen<ShiftTemplateItem>()),
  })
  .actions(self => {
    const ajax = getAjax(self);
    const root = getRoot(self) as IRootStoreModel;

    const createShiftTemplate = flow(function*(command: CreateShiftTemplateCommand) {
      const id = yield ajax.workshop.shiftTemplate.createShiftTemplate(command);
      self.shiftTemplate = undefined;
      root.history.push(`/workshop/shift-templates/${id}`);
    });

    const checkForUniqueShiftTemplateName = flow(function*(shiftName: string) {
      return yield ajax.workshop.shiftTemplate.checkForUniqueShiftTemplateName(shiftName);
    });

    const loadShiftTemplate = flow(function*(shiftTemplateId: string) {
      self.shiftTemplate = yield ajax.workshop.shiftTemplate.getShiftTemplate(shiftTemplateId);
    });

    const updateShiftTemplate = flow(function*(command: UpdateShiftTemplateCommand) {
      yield ajax.workshop.shiftTemplate.updateShiftTemplate(command);
      yield loadShiftTemplate(command.id);
    });

    const deleteShiftTemplate = flow(function*(shiftTemplateId: string) {
      yield ajax.workshop.shiftTemplate.deleteShiftTemplate(shiftTemplateId);
      self.shiftTemplate = undefined;
      root.history.replace('/workshop/shift-templates');
    });

    return {
      createShiftTemplate,
      checkForUniqueShiftTemplateName,
      loadShiftTemplate,
      updateShiftTemplate,
      deleteShiftTemplate,
    };
  });
