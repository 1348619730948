import { types } from 'mobx-state-tree';
import { CustomersModel } from './customer/CustomersModel';
import { QuotesModel } from './quote/QuotesModel';
import { BoardingPointModel } from './boardingPoint/BoardingPointModel';
import { BoardingPointsModel } from 'src/domain/entities/operations/sales/boardingPoint/BoardingPointsModel';
import { CustomerModel } from './customer/CustomerModel';
import { HourlyRatesModel } from './hourlyRate/HourlyRatesModel';
import { RailBookingsForAccountsModel } from './quote/RailBookingsForAccountsModel';
import { AccountingDashboardModel } from './dashboard/AccountingDashboardModel';
import { SalesDashboardModel } from 'src/domain/entities/operations/sales/salesDashboard/SalesDashboardModel';
import { ContractCharterBookingsModel } from './quote/ContractCharterBookingsModel';

export const SalesModel = types.model('SalesModel', {
  salesDashboard: types.optional(SalesDashboardModel, {}),
  customers: types.optional(CustomersModel, {}),
  customer: types.optional(CustomerModel, {}),
  quotes: types.optional(QuotesModel, {}),
  railBookings: types.optional(RailBookingsForAccountsModel, {}),
  contractCharterBookings: types.optional(ContractCharterBookingsModel, {}),
  boardingPoint: types.optional(BoardingPointModel, {}),
  boardingPoints: types.optional(BoardingPointsModel, {}),
  hourlyRates: types.optional(HourlyRatesModel, {}),
  dashboard: types.optional(AccountingDashboardModel, {}),
});

type ISalesModelType = typeof SalesModel.Type;
export interface ISalesModel extends ISalesModelType {}
