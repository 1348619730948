import { Component } from 'react';
import { Input } from 'reactstrap';
import { unsignedDecimalSanitiser } from 'src/views/components/Page/fields/NumericPageField';

interface IStocktakeQuantityProps {
  onBlur: (value: number) => Promise<void>;
}

interface IStocktakeQuantityState {
  quantity: number | string | undefined;
}

class StocktakeQuantity extends Component<IStocktakeQuantityProps, IStocktakeQuantityState> {
  constructor(props: IStocktakeQuantityProps) {
    super(props);
    this.state = {
      quantity: '',
    };
  }

  render() {
    return (
      <Input
        autoComplete="off"
        type="text"
        onChange={e =>
          this.setState({
            quantity: unsignedDecimalSanitiser(e.target.value, {
              maxPointDigits: 4,
            }),
          })
        }
        value={this.state.quantity}
        onBlur={e => {
          const qty = Number(e.target.value);
          if (e.target.value.length > 0 && !Number.isNaN(qty) && qty >= 0) {
            this.props
              .onBlur(qty)
              .then(r => this.setState({ quantity: '' }))
              // tslint:disable-next-line:no-empty
              .catch(() => {});
          }
        }}
      />
    );
  }
}

export default StocktakeQuantity;
