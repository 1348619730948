import { PureComponent } from 'react';
import { InputGroup } from 'reactstrap';
import { DateTime, Duration } from 'luxon';
import { LabelledFormField } from 'src/views/components/forms';
import { IFieldMeta, IFieldData, ITimeFieldDef } from 'src/views/definitionBuilders/types';
import { IFieldApi } from 'src/views/components/Page/forms/Field';
import FocusWrapper from 'src/views/components/FocusWrapper';
import TimeInput from './subfields/TimeInput';
import {
  parseEditingFormattedTimeString,
  getEditingFormattedTimeString,
} from './subfields/TimeHelpers';

interface ITimePageFieldProps {
  fieldDef: ITimeFieldDef;
  fieldMeta: IFieldMeta;
  fieldData: IFieldData<string>;
  fieldApi: IFieldApi;
}

interface ITimePageFieldState {
  prevFieldValue: string | undefined;
  externalValue: string | undefined;
  internalTimeValue: string;
}

class TimePageField extends PureComponent<ITimePageFieldProps, ITimePageFieldState> {
  static getDerivedStateFromProps(
    nextProps: Readonly<ITimePageFieldProps>,
    prevState: ITimePageFieldState
  ) {
    const { fieldData } = nextProps;
    // If the external version of this field is different to what we have, then update using the external value
    // (the react-form value is king, so that external logic can update this field)
    if (fieldData.fieldValue !== prevState.prevFieldValue) {
      // If the external value changed to a different value that this control has, then reset
      // the internal state to sync up. Otherwise do nothing
      // (Eg: so that "2" isn't changed to a full time)
      const internalStateUpdate =
        prevState.externalValue === fieldData.fieldValue
          ? {}
          : TimePageField.getInternalStateFromExternal(fieldData.fieldValue);

      return {
        prevFieldValue: fieldData.fieldValue,
        externalValue: fieldData.fieldValue,
        ...internalStateUpdate,
      };
    }
    return null;
  }

  private static getInternalStateFromExternal(fieldValue: string | undefined) {
    const time = parseEditingFormattedTimeString(fieldValue);
    const internalTimeValue =
      time && time.isValid ? getEditingFormattedTimeString(time) : fieldValue || '';
    return { internalTimeValue };
  }

  constructor(props: ITimePageFieldProps) {
    super(props);
    const { fieldData } = props;
    this.state = {
      prevFieldValue: fieldData.fieldValue,
      externalValue: fieldData.fieldValue,
      ...TimePageField.getInternalStateFromExternal(fieldData.fieldValue),
    };
  }

  private readonly handleFieldBlur = () => {
    this.props.fieldApi.setTouched(true);
    this.props.fieldMeta.onBlur && this.props.fieldMeta.onBlur(this.state.externalValue);
  };

  private readonly handleInternalTimeChange = (newTimeValue: string) => {
    const time = parseEditingFormattedTimeString(newTimeValue);
    const externalValue =
      time && time.isValid && time.hours < 24 && time.days === 0 ? time.toFormat('hh:mm') : '';
    this.setState({ internalTimeValue: newTimeValue, externalValue }, () =>
      this.props.fieldApi.setValue(externalValue)
    );
  };

  private readonly getDefaultReadonlyValue = (time: Duration | undefined) => {
    return time && time.isValid
      ? DateTime.fromObject({ hour: time.hours, minute: time.minutes }).toLocaleString(
          DateTime.TIME_SIMPLE
        )
      : '';
  };

  render() {
    const { fieldApi, fieldDef: def, fieldMeta: meta, fieldData: data } = this.props;
    const { internalTimeValue } = this.state;
    const { error, touched } = fieldApi;

    const time = parseEditingFormattedTimeString(internalTimeValue);
    const internalIsInvalid = !!(time && (!time.isValid || time.hours > 23 || time.days > 0));
    const errorText = internalIsInvalid ? 'The time is invalid' : error;
    const labelText = typeof def.label === 'function' ? def.label(data) : def.label;
    const tooltipText = typeof def.tooltip === 'function' ? def.tooltip(data) : def.tooltip;

    return (
      <LabelledFormField
        className="date-page-field-component"
        readonly={meta.readonly}
        readonlyValue={
          def.formatReadonly ? def.formatReadonly(data) : this.getDefaultReadonlyValue(time)
        }
        readonlyLinkTo={def.linkTo && time ? def.linkTo(data) : undefined}
        mandatory={meta.mandatory}
        hideLabel={meta.hideLabel}
        labelValue={labelText}
        tooltipValue={tooltipText}
        noForm={meta.noForm}
        error={touched && errorText}>
        <FocusWrapper onBlur={this.handleFieldBlur}>
          <InputGroup>
            <TimeInput value={internalTimeValue} onChange={this.handleInternalTimeChange} />
          </InputGroup>
        </FocusWrapper>
      </LabelledFormField>
    );
  }
}

export default TimePageField;
