import { Component } from 'react';
import { Button, Table } from 'reactstrap';
import { DateTime } from 'luxon';
import { Link } from 'react-router-dom';
import { LinkIcon, PlusIcon, SpinnerIcon } from 'src/images/icons';
import { DateFormat } from 'src/views/components/DateFormat';
import Omit from 'src/infrastructure/omit';
import { ITaskProcessing } from 'src/views/routes/workshop/tasks/maintainTask/MaintainTask';

type AssetNextServiceDueItem = Workshop.Domain.Queries.AssetServices.GetAssetNextServiceDue.AssetNextServiceDueItem;
type FutureTaskForAsset = Workshop.Domain.Queries.GetFutureTaskForAsset.FutureTaskForAsset;
type JobDetailsTask = Workshop.Domain.Queries.Job.JobDetailsTaskDto;
type ScheduledTaskForAsset = Workshop.Domain.Queries.GetScheduledTaskForAsset.ScheduledTaskForAsset;
type JobDetailsTaskWithOptionalChangeState = Omit<JobDetailsTask, 'changeState'> &
  Partial<Pick<JobDetailsTask, 'changeState'>>;

interface IOtherWorkProps {
  isUpdating: boolean;
  nextService?: AssetNextServiceDueItem;
  futureTasks: FutureTaskForAsset[];
  scheduledTasks: ScheduledTaskForAsset[];
  tasksInJob: JobDetailsTaskWithOptionalChangeState[];
  onSelectFutureTask: (task: FutureTaskForAsset) => void;
  onSelectServicePlan: (service: AssetNextServiceDueItem) => void;
  taskUpdatesBeingProcessed?: ITaskProcessing[];
}

class OtherWork extends Component<IOtherWorkProps> {
  constructor(props: IOtherWorkProps) {
    super(props);
    this.state = {
      selectedFutureTasks: [],
      selectedServiceDue: undefined,
    };
  }

  isTaskInJob = (id: string) => {
    return this.props.tasksInJob.find(x => x.id === id);
  };

  render() {
    const serviceDue = this.props.nextService;
    const serviceType =
      serviceDue && serviceDue.serviceType && serviceDue.serviceType.description
        ? serviceDue.serviceType.description
        : '';
    const serviceDueOnDate =
      serviceDue && serviceDue.dueDate
        ? DateTime.fromISO(serviceDue.dueDate).toLocaleString(DateTime.DATE_MED)
        : undefined;
    const serviceDueAtKms = serviceDue && serviceDue.dueInKms;
    const serviceDueDisplay =
      serviceDueOnDate && serviceDueAtKms
        ? `${serviceType} - due in ${serviceDueAtKms} kms or ${serviceDueOnDate}`
        : serviceDueOnDate
        ? `${serviceType} - due at ${serviceDueOnDate}`
        : `${serviceType} - due in ${serviceDueAtKms} kms`;
    const serviceDueTaskId = serviceDue && serviceDue.jobTaskId;
    const serviceDueTaskNumber = serviceDue && serviceDue.jobTaskNumber;
    const futureTasks = this.props.futureTasks;
    const scheduledTasks = (this.props.scheduledTasks || []).filter(x => !this.isTaskInJob(x.id));

    const buttonClickStillProcessing = (taskId: string) => {
      return (
        this.props.taskUpdatesBeingProcessed &&
        this.props.taskUpdatesBeingProcessed[taskId] === true
      );
    };

    return (
      <div className="manage-job-other-work-component">
        <Table className="service-due" borderless>
          <thead>
            <tr>
              <th colSpan={2}>Service Plan for Asset</th>
            </tr>
          </thead>
          <tbody>
            {serviceDue && serviceDue.serviceType ? (
              <tr>
                <td>{serviceDueDisplay}</td>
                <td>
                  {this.props.isUpdating && !serviceDueTaskId && (
                    <Button
                      color="light"
                      disabled={buttonClickStillProcessing(serviceDue.id.toString())}
                      onClick={() => this.props.onSelectServicePlan(serviceDue)}>
                      {buttonClickStillProcessing(serviceDue.id.toString()) ? (
                        <SpinnerIcon fixedWidth />
                      ) : (
                        <PlusIcon fixedWidth />
                      )}
                    </Button>
                  )}

                  {serviceDueTaskId && (
                    <Link to={`/workshop/tasks/${serviceDueTaskId}`}>{serviceDueTaskNumber}</Link>
                  )}
                </td>
              </tr>
            ) : (
              <tr>
                <td>None</td>
              </tr>
            )}
          </tbody>
        </Table>

        <Table className="future-tasks" borderless>
          <thead>
            <tr>
              <th colSpan={2}>Future Tasks for Asset</th>
            </tr>
          </thead>
          <tbody>
            {futureTasks && futureTasks.length > 0 ? (
              futureTasks.map(futureTask => {
                return (
                  <tr key={futureTask.id}>
                    <td>
                      <Link to={`/workshop/tasks/${futureTask.id}`}>
                        {futureTask.jobTaskNumber}
                      </Link>
                      &nbsp;&#8210;&nbsp;
                      {futureTask.description}
                    </td>
                    <td>
                      {this.props.isUpdating && !this.isTaskInJob(futureTask.id) && (
                        <Button
                          color="light"
                          disabled={buttonClickStillProcessing(futureTask.id)}
                          onClick={() => this.props.onSelectFutureTask(futureTask)}>
                          {buttonClickStillProcessing(futureTask.id) ? (
                            <SpinnerIcon fixedWidth />
                          ) : (
                            <LinkIcon fixedWidth />
                          )}
                        </Button>
                      )}
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td>None</td>
              </tr>
            )}
          </tbody>
        </Table>
        <Table className="scheduled-tasks" borderless>
          <thead>
            <tr>
              <th colSpan={2}>Scheduled Tasks for Asset</th>
            </tr>
          </thead>
          <tbody>
            {scheduledTasks && scheduledTasks.length > 0 ? (
              scheduledTasks.map(scheduledTask => {
                return (
                  <tr key={scheduledTask.id}>
                    <td>
                      <Link to={`/workshop/tasks/${scheduledTask.id}`}>
                        {scheduledTask.jobTaskNumber}
                      </Link>
                      &nbsp;&#8210;&nbsp;
                      {scheduledTask.description}
                      <br /> part of job{' '}
                      <Link to={`/workshop/jobs/${scheduledTask.jobId}`}>
                        {scheduledTask.jobNumber}
                      </Link>{' '}
                      scheduled for <DateFormat value={scheduledTask.scheduledStartDateTime} />
                    </td>
                    <td />
                  </tr>
                );
              })
            ) : (
              <tr>
                <td>None</td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>
    );
  }
}

export default OtherWork;
