import './Leave.scss';
import { Component } from 'react';
import { DateTime } from 'luxon';
import LeaveCalendar from 'src/views/kioskRoutes/components/presentational/Leave/LeaveCalendar/LeaveCalendar';
import LeaveLegend from 'src/views/kioskRoutes/components/presentational/Leave/LeaveLegend/LeaveLegend';
import LeaveTable from 'src/views/kioskRoutes/components/presentational/Leave/LeaveTable/LeaveTable';
import { Link } from 'react-router-dom';
import Button from 'reactstrap/lib/Button';
import Header from '../../components/presentational/Header/Header';

type LeaveDto = Common.Dtos.LeaveDto;

export interface ILeaveProps {
  leaves: LeaveDto[];
  loadLeavesForDriver: (from: DateTime, to: DateTime) => Promise<void>;
  userDisplayName: string;
  canUseTimesheets: boolean | undefined;
  timezone: string;
}

export interface ILeaveState {
  isOpened: boolean;
}
class Leave extends Component<ILeaveProps, ILeaveState> {
  componentDidMount() {
    this.loadLeave(DateTime.local().setZone(this.props.timezone));
  }

  loadLeave(date: DateTime) {
    const from = date.startOf('month');
    const to = date.endOf('month');
    this.props.loadLeavesForDriver(from, to);
  }

  render() {
    const { leaves, timezone, canUseTimesheets, userDisplayName } = this.props;

    return (
      <div className="leave-component">
        <Header
          title={`Leave for ${userDisplayName}`}
          to="/kiosk"
          canUseTimesheets={canUseTimesheets}
        />
        <div className="calendar">
          <div className="leave-calendar">
            <LeaveCalendar
              leaves={leaves}
              activeStartDateChanged={date => this.loadLeave(date)}
              timezone={timezone}
            />
          </div>

          <div className="leave-legend">
            <LeaveLegend />
          </div>

          <div className="leave-table">
            {leaves && leaves.length > 0 ? (
              <LeaveTable leaves={leaves} timezone={timezone} />
            ) : (
              <span className="noLeave">You don't have any leave entered this month</span>
            )}
          </div>
        </div>

        <div className="leave-links">
          <Link to="/kiosk/leave/create" className="create-leave">
            <Button size="m" type="button">
              Request Leave
            </Button>
          </Link>
        </div>
      </div>
    );
  }
}

export default Leave;
