import { PaneType, FieldType, ModalDefBuilder } from 'src/views/definitionBuilders/types';
import { standardTextSanitisers } from 'src/views/componentBuilders';
import { getSubmitCloseModalActionGroupDef } from 'src/views/definitionBuilders/common';

type CreateBoardingPointCommand = Operations.Domain.Commands.BoardingPoint.CreateBoardingPointCommand;
type States = Operations.Domain.AggregatesModel.BoardingPointAggregate.States;

export default function getAddBoardingPointModalDef(
  states: States[],
  checkForUniqueBoardingPointName?: (name: string) => Promise<Common.Dtos.UniqueNameCheckResultDto>,
  onCreateBoardingPoint?: (command: CreateBoardingPointCommand) => Promise<string>
): ModalDefBuilder {
  return modalDefApi => ({
    title: 'Create Boarding Point',
    asForm: true,
    explicitData: {},
    panels: [
      {
        panes: [
          {
            paneType: PaneType.formFieldsPane,
            fields: [
              {
                fieldType: FieldType.textField,
                dataAddr: 'name',
                label: 'Name',
                mandatory: true,
                maxLength: 200,
                validateAsync: async d => {
                  if (!d.fieldValue) {
                    return undefined;
                  }
                  if (!checkForUniqueBoardingPointName) {
                    return 'checkForUniqueBoardingPointName not set';
                  }
                  const result = await checkForUniqueBoardingPointName(d.fieldValue);
                  return result.nameExists ? `Name is already in use` : undefined;
                },
              },
              {
                fieldType: FieldType.textField,
                dataAddr: 'address',
                label: 'Address',
                maxLength: 200,
              },
              {
                fieldType: FieldType.textField,
                dataAddr: 'city',
                label: 'City',
                maxLength: 200,
              },
            ],
          },
          {
            paneType: PaneType.formFieldsPane,
            columnCount: 2,
            fields: [
              {
                fieldType: FieldType.selectField,
                dataAddr: 'state',
                label: 'State',
                valueKey: 'id',
                descriptionKey: 'description',
                optionItems: states,
              },
              {
                fieldType: FieldType.textField,
                dataAddr: 'postcode',
                label: 'Postcode',
                maxLength: 4,
                onChange: api =>
                  api.setFormValue(
                    api.fieldDataAddr,
                    standardTextSanitisers.numericSanitiser(api.fieldData.fieldValue || '')
                  ),
              },
            ],
          },
          {
            paneType: PaneType.formFieldsPane,
            fields: [
              {
                fieldType: FieldType.textAreaField,
                dataAddr: 'notes',
                label: 'Notes',
              },
            ],
          },
        ],
      },
    ],
    secondaryActions: [getSubmitCloseModalActionGroupDef('Create')],
    onFormPreSubmit: (values: { state: States }) => {
      return {
        ...values,
        state: values.state && values.state.description,
      };
    },
    onFormSubmit: (values: CreateBoardingPointCommand) => {
      if (!onCreateBoardingPoint) {
        return Promise.reject('onCreateBoardingPoint not set');
      }
      return onCreateBoardingPoint(values).then(id =>
        modalDefApi.parentFormApi.setValue(modalDefApi.parentFormApi.getFullField('location'), {
          ...values,
          id: id,
        })
      );
    },
  });
}
