import { useEffect } from 'react';
import { FieldType, FieldDefs } from 'src/views/definitionBuilders/types/field';
import ListPage, { IListPageDef } from 'src/views/components/Page/pages/ListPage';
import { PaneType, ActionType, PagePrimarySize } from 'src/views/definitionBuilders/types';
import TaskCardItem from 'src/views/components/TaskCard/TaskCardItem';
import {
  leaveTypeDescription,
  leaveStatusDescription,
  allLeaveType,
  allLeaveStatus,
  LeaveStatus,
} from 'src/api/enums';
import withQueryParams, { IQueryParamsProps } from 'src/views/hocs/withQueryParams';
import { DateTime } from 'luxon';
import { ExcelIcon } from 'src/images/icons';
import { observer } from 'mobx-react';
import { useRootStore } from 'src/domain/entities/RootStoreModel';
import { TIMEZONE, ENABLE_SHOW_OVERSEAS_TRAVEL_FIELD } from 'src/appSettings';
import saveAs from 'file-saver';
import PrimaryTitle from 'src/views/components/Page/PrimaryTitle/PrimaryTitle';

type ListLeaveQuery = People.Domain.Queries.ListLeave.ListLeaveQuery;

const ListLeave: React.FC<IQueryParamsProps<ListLeaveQuery>> = observer(
  (props: IQueryParamsProps<ListLeaveQuery>) => {
    const showOverseasTravelField = ENABLE_SHOW_OVERSEAS_TRAVEL_FIELD;

    const rootStore = useRootStore();
    const peopleModel = rootStore.people;
    const leaveModel = peopleModel.leaves;
    const staffMembersModel = peopleModel.staffMembers;
    const canCreateLeave =
      rootStore.account.isPeopleDepartmentMember ||
      rootStore.account.isOperationsDepartmentMember ||
      rootStore.account.isWorkshopDepartmentMember;

    useEffect(() => {
      staffMembersModel.loadAllStaffMembers();
      peopleModel.roles.loadAllRoles();
    }, []);

    const getFilterFieldDefs = () => {
      return {
        staffMemberIds: {
          fieldType: FieldType.selectMultiField,
          dataAddr: 'staffMemberIds',
          label: 'Staff Member',
          valueKey: 'id',
          descriptionKey: 'name',
          optionItems: staffMembersModel.activeStaffMembers,
          useValueOnly: true,
        } as FieldDefs,
        workshopStaff: {
          fieldType: FieldType.yesNoField,
          dataAddr: 'workshopStaff',
          label: 'Workshop Staff',
        } as FieldDefs,
        overseasTravel: {
          fieldType: FieldType.yesNoField,
          dataAddr: 'overseasTravel',
          label: 'Overseas Travel',
          hidden: !showOverseasTravelField,
        } as FieldDefs,
        leaveTypes: {
          fieldType: FieldType.selectMultiField,
          dataAddr: 'leaveTypes',
          label: 'Leave Type',
          valueKey: 'value',
          descriptionKey: 'description',
          optionItems: allLeaveType.slice(),
          useValueOnly: true,
        } as FieldDefs,
        leaveStatuses: {
          fieldType: FieldType.selectMultiField,
          dataAddr: 'leaveStatuses',
          label: 'Leave Status',
          valueKey: 'value',
          descriptionKey: 'description',
          optionItems: allLeaveStatus.slice(),
          useValueOnly: true,
        } as FieldDefs,
        leaveFrom: {
          fieldType: FieldType.dateField,
          dataAddr: 'leaveFrom',
          label: 'Leave From',
          timezone: TIMEZONE,
          onBlur: api => {
            if (!api.formValues.dateTo) {
              api.setFormValue(['leaveFrom'], api.fieldData.fieldValue);
            }
            api.validateField(['leaveFrom']);
          },
        } as FieldDefs,
        leaveTo: {
          fieldType: FieldType.dateField,
          dataAddr: 'leaveTo',
          label: 'Leave To',
          timezone: TIMEZONE,
          validate: d => {
            if (!d.fieldValue || !d.parentValue.dateFrom) {
              return undefined;
            }
            const from = DateTime.fromISO(d.parentValue.leaveFrom, { zone: TIMEZONE });
            const to = DateTime.fromISO(d.fieldValue, { zone: TIMEZONE });
            return from > to ? 'Leave To cannot be earlier than Leave From' : undefined;
          },
        } as FieldDefs,
        roles: {
          fieldType: FieldType.selectMultiField,
          dataAddr: 'roleIds',
          label: 'Roles',
          valueKey: 'id',
          descriptionKey: 'name',
          optionItems: peopleModel.roles.roles.slice(),
          useValueOnly: true,
        } as FieldDefs,
      };
    };

    const saveExcel = () => {
      const fileName = `Leaves_${DateTime.local().toFormat('yyyyMMddHHmm')}.xlsx`;
      return leaveModel.exportToExcel(props.getQueryParams()).then(blob => saveAs(blob, fileName));
    };

    const getPageDef = (): IListPageDef => {
      const filterFieldDefsLookup = getFilterFieldDefs();
      return {
        primaryTitle: <PrimaryTitle title="Leave"></PrimaryTitle>,
        primarySize: PagePrimarySize.full,
        onLoadData: leaveModel.listItems,
        externalSearch: true,
        createLink: canCreateLeave ? TaskCardItem.people.createLeave : undefined,
        hasMoreData: leaveModel.hasMoreItems,
        primaryActions: [
          {
            actions: [
              {
                actionType: ActionType.actionCollection,
                actionGroups: [
                  {
                    actions: [
                      {
                        actionType: ActionType.actionButton,
                        label: 'Export To Excel',
                        icon: <ExcelIcon fixedWidth />,
                        onClick: () => saveExcel(),
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],

        primaryFields: [
          {
            fieldType: FieldType.readonlyField,
            dataAddr: 'leaveNumber',
            label: 'Leave Number',
            linkTo: d => `/people/leaves/${d.parentValue.id}`,
          },
          {
            fieldType: FieldType.readonlyField,
            dataAddr: 'type',
            label: 'Type',
            formatReadonly: data => leaveTypeDescription(data.parentValue.type),
          },
          {
            fieldType: FieldType.textField,
            dataAddr: 'staffMember.name',
            label: 'Staff Member',
            linkTo: d => `/people/staff-members/${d.parentValue.staffMember.id}`,
          },
          {
            fieldType: FieldType.readonlyField,
            dataAddr: 'status',
            label: 'Status',
            formatReadonly: data => leaveStatusDescription(data.parentValue.status),
            orderBy: 'Leave.Status',
          },
          {
            fieldType: FieldType.dateTimeField,
            dataAddr: 'start',
            label: 'Start',
            orderBy: 'Leave.Start',
            timezone: TIMEZONE,
          },
          {
            fieldType: FieldType.dateTimeField,
            dataAddr: 'end',
            label: 'End',
            orderBy: 'Leave.End',
            timezone: TIMEZONE,
          },
          {
            fieldType: FieldType.readonlyField,
            dataAddr: 'overseasTravel',
            label: showOverseasTravelField ? 'Overseas' : '', //Otherwise it'll show the arrow for sorting
            orderBy: showOverseasTravelField ? 'Leave.OverseasTravel' : undefined, //Otherwise it'll show the arrow for sorting
            columnWidth: '3em',
            hidden: !showOverseasTravelField,
            formatReadonly: d => <div>{d.fieldValue ? 'Yes' : 'No'}</div>,
          },
        ],
        filterAction: {
          defaultValues: {
            leaveStatuses: [LeaveStatus.Pending],
          },
          filterFields: Object.keys(filterFieldDefsLookup).map(k => filterFieldDefsLookup[k]),
          filterDef: _ => [
            {
              panes: [
                {
                  paneType: PaneType.formFieldsPane,
                  fields: [
                    filterFieldDefsLookup.staffMemberIds,
                    filterFieldDefsLookup.workshopStaff,
                    filterFieldDefsLookup.leaveTypes,
                    filterFieldDefsLookup.leaveStatuses,
                    filterFieldDefsLookup.overseasTravel,
                    filterFieldDefsLookup.roles,
                  ],
                },
                {
                  paneType: PaneType.formFieldsPane,
                  columnCount: 2,
                  fields: [filterFieldDefsLookup.leaveFrom, filterFieldDefsLookup.leaveTo],
                },
              ],
            },
          ],
        },
      };
    };

    return (
      <ListPage
        className="list-leave-component"
        data={leaveModel.items.slice()}
        def={getPageDef()}
      />
    );
  }
);

export default withQueryParams(ListLeave);
