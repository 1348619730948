import { Component } from 'react';
import styles from './Header.module.scss';
import BackLink from 'src/views/kioskRoutes/components/presentational/BackLink/BackLink';
import MenuButton from '../MenuButton/MenuButton';
import Menu from '../../../operationsKiosk/Menu/Menu';

export interface IHeaderProps {
  title: string | JSX.Element;
  to?: string;
  canUseTimesheets?: boolean;
  hideMenu?: boolean;
}

interface IHeaderState {
  isOpened: boolean;
}
class Header extends Component<IHeaderProps, IHeaderState> {
  constructor(props: IHeaderProps) {
    super(props);
    this.state = {
      isOpened: false,
    };
    this.handleToggle = this.handleToggle.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  handleToggle() {
    this.setState(prevState => {
      return { isOpened: !prevState.isOpened };
    });
  }

  handleClose() {
    this.setState({ isOpened: false });
  }

  render() {
    return (
      <div className={styles.headerContainer}>
        <div className={styles.headerDetails}>
          <div className={styles.back}>
            {this.props.to ? <BackLink to={this.props.to} /> : null}
            <h2>{this.props.title}</h2>
          </div>
          {this.props.hideMenu ? null : (
            <div className={styles.menu}>
              <MenuButton click={this.handleToggle} />
            </div>
          )}
        </div>
        {this.state.isOpened ? (
          <Menu click={this.handleClose} canUseTimesheets={this.props.canUseTimesheets} />
        ) : null}
      </div>
    );
  }
}

export default Header;
