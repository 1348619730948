import { types, flow } from 'mobx-state-tree';
import { getAjax } from 'src/domain/services';
import { IFile } from 'src/views/components/Page/fields/MultiFilePageField';
import { getBase64 } from 'src/infrastructure/fileUtil';
import { UpdateAttachmentFormData } from 'src/views/routes/operations/attachment/getUpdateAttachmentModalDef';
import {
  AggregatesWithAttachments,
  DownloadAttachmentQuery,
  isValidAttachment,
  LoadAttachmentsQuery,
  SwapAttachmentOrderData,
} from 'src/views/components/Attachment/attachmentHelper';
import { DeleteAttachmentFormData } from 'src/views/routes/operations/attachment/getDeleteAttachmentModalDef';

type DeleteAttachmentCommand = Operations.Domain.Commands.Attachment.DeleteAttachment.DeleteShiftAttachmentCommand;
type UpdateAttachmentCommand = Operations.Domain.Commands.Attachment.UpdateAttachment.UpdateShiftAttachmentCommand;
type AttachmentDto = Operations.Domain.Queries.Attachment.OperationsAttachmentDetailsDto;
type AddAttachmentCommand = Operations.Domain.Commands.Attachment.AddAttachment.AddShiftAttachmentCommand;

export type AddAttachmentData = {
  aggregateId: string;
  fileName: string;
  aggregateType: AggregatesWithAttachments;
  file: IFile;
  availableOnTablet: boolean;
  availableOnKiosk: boolean;
  updateCurrentJobs: boolean;
};

export const OperationsAttachmentModel = types
  .model('OperationsAttachmentModel', {
    attachments: types.optional(types.array(types.frozen<AttachmentDto>()), []),
  })
  .actions(self => {
    const ajax = getAjax(self);

    const addOperationsAttachment = flow(function*(data: AddAttachmentData) {
      if (!isValidAttachment(data.file)) {
        return;
      }

      const command: AddAttachmentCommand = {
        ...data,
        mimeType: data.file.file!.type,
        data: yield getBase64(data.file.file!),
        availableOnTablet: data.availableOnTablet,
        availableOnKiosk: data.availableOnKiosk,
        updateCurrentJobs: data.updateCurrentJobs,
      };

      yield ajax.operations.attachments.addAttachment(command, data.aggregateType);
    });

    const loadOperationsAttachmentDetails = flow(function*(query: LoadAttachmentsQuery) {
      self.attachments = yield ajax.operations.attachments.loadAttachments(
        query.aggregateId,
        query.aggregateType
      );
    });

    const downloadOperationsAttachment = flow(function*(query: DownloadAttachmentQuery) {
      yield ajax.operations.attachments.downloadAttachment(
        query.attachmentDetailsId,
        query.aggregateId,
        query.aggregateType
      );
    });

    const deleteOperationsAttachment = flow(function*(data: DeleteAttachmentFormData) {
      const command: DeleteAttachmentCommand = {
        aggregateId: data.aggregateId,
        attachmentDetailsId: data.attachmentDetailsId,
        updateCurrentJobs: data.updateCurrentJobs,
      };
      yield ajax.operations.attachments.deleteAttachment(command, data.aggregateType);
    });

    const updateOperationsAttachment = flow(function*(data: UpdateAttachmentFormData) {
      const command: UpdateAttachmentCommand = {
        attachmentDetailsId: data.attachmentDetailsId,
        aggregateId: data.aggregateId,
        fileName: data.fileName,
        availableOnTablet: data.availableOnTablet,
        availableOnKiosk: data.availableOnKiosk,
        updateCurrentJobs: data.updateCurrentJobs,
      };
      yield ajax.operations.attachments.updateAttachment(command, data.aggregateType);
    });

    const swapOperationsAttachment = flow(function*(data: SwapAttachmentOrderData) {
      yield ajax.operations.attachments.swapAttachmentOrder(data);
    });

    return {
      addOperationsAttachment,
      loadOperationsAttachmentDetails,
      downloadOperationsAttachment,
      deleteOperationsAttachment,
      updateOperationsAttachment,
      swapOperationsAttachment,
    };
  });
