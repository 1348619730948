import {
  ActionType,
  FieldType,
  ISectionDef,
  PaneType,
  ShellModalSize,
} from 'src/views/definitionBuilders/types';
import { PlusIcon, TrashIcon } from 'src/images/icons';
import { getSubmitCloseModalActionGroupDef } from 'src/views/definitionBuilders/common';

type AssignStaffMemberToRoleCommand = People.Domain.Commands.Role.AddRole.AssignStaffMemberToRoleCommand;
type RemoveStaffMemberFromRoleCommand = People.Domain.Commands.Role.RemoveRole.RemoveStaffMemberFromRoleCommand;
type ListRoleItem = Common.Dtos.ListRoleItem;
type StaffMemberRole = People.Domain.AggregatesModel.StaffMemberAggregate.StaffMemberRole;
export default function getRolesSectionDef(
  canSave: boolean,
  allRoles: ListRoleItem[],
  primarySectionEditing: boolean,
  roles: StaffMemberRole[] | undefined,
  staffMemberId: string,
  removeStaffMemberFromRole: (cmd: RemoveStaffMemberFromRoleCommand) => Promise<void>,
  assignStaffMemberToRole: (cmd: AssignStaffMemberToRoleCommand) => Promise<void>
): ISectionDef {
  return {
    title: 'Roles',
    panels: [
      {
        panes: [
          {
            paneType: PaneType.tablePane,
            dataAddr: 'roles',
            neverEditable: true,
            fields: [
              {
                fieldType: FieldType.readonlyField,
                label: 'Name',
                dataAddr: 'name',
                formatReadonly: d => allRoles.find(r => r.id === d.parentValue.roleId)?.name,
              },
              {
                fieldType: FieldType.actionListField,
                columnWidth: '1px',
                hidden: () => !canSave || primarySectionEditing,
                actionGroups: [
                  {
                    actions: [
                      {
                        actionType: ActionType.modalActionButton,
                        icon: <TrashIcon />,
                        label: 'Remove role from staff member',
                        modalSize: ShellModalSize.oneQuarter,
                        modalDef: modalDefApi => ({
                          title: 'Remove Role',
                          asForm: true,
                          panels: [
                            {
                              panes: [
                                {
                                  paneType: PaneType.customPane,
                                  render: _ => <p>Are you sure you want to remove this role? </p>,
                                },
                              ],
                            },
                          ],
                          secondaryActions: [getSubmitCloseModalActionGroupDef('Ok')],
                          onFormSubmit: () => {
                            const command = {
                              staffMemberId: staffMemberId,
                              roleId: modalDefApi.actionData.parentValue.roleId,
                            } as RemoveStaffMemberFromRoleCommand;

                            return removeStaffMemberFromRole(command);
                          },
                        }),
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
    secondaryActions: [
      {
        actions: [
          {
            hidden: () => !canSave || primarySectionEditing,
            actionType: ActionType.modalActionButton,
            label: 'Add a Role',
            icon: <PlusIcon />,
            level: 'primary',
            modalSize: ShellModalSize.oneQuarter,
            modalDef: _ => ({
              title: 'Add a Role',
              asForm: true,
              panels: [
                {
                  panes: [
                    {
                      paneType: PaneType.formFieldsPane,
                      fields: [
                        {
                          fieldType: FieldType.selectField,
                          label: 'Role',
                          dataAddr: 'newRole',
                          optionItems: allRoles,
                          mandatory: true,
                          valueKey: 'id',
                          descriptionKey: 'name',
                          valuesToDisable: roles ? roles?.map(x => x.roleId) : [],
                        },
                      ],
                    },
                  ],
                },
              ],
              secondaryActions: [getSubmitCloseModalActionGroupDef('Ok')],
              onFormSubmit: values => {
                const command = {
                  staffMemberId: staffMemberId,
                  roleId: values.newRole.id,
                } as AssignStaffMemberToRoleCommand;

                return assignStaffMemberToRole(command);
              },
            }),
          },
        ],
      },
    ],
  };
}
