import './Scheduler.scss';

import { Link } from 'react-router-dom';
import { LocationDescriptorObject } from 'history';
import { DateTime } from 'luxon';
import { Table, Button, Popover, PopoverBody } from 'reactstrap';
import { ChevronLeftIcon, ChevronRightIcon, SpinnerIcon } from 'src/images/icons';
import ScheduledJob, { IScheduledJobProps } from './ScheduledJob';
import ActionBar from 'src/views/components/ActionBar';
import { IScheduledMachineryJob } from 'src/domain/entities/workshop/job/ScheduledMachineryJobsModel';
import JobDetailsPopover from 'src/views/routes/workshop/jobs/components/JobDetailsPopover';
import { ScheduleLegend } from '../../ScheduleCommon';
import { ILinkAction } from 'src/views/components/ActionBar/ActionBar';
import { useEffect, useState } from 'react';
import { getWorkshopDepotSelectAction } from 'src/views/routes/workshop/jobs/jobSchedule/Scheduler/WorkshopDepotSelect';
import { observer } from 'mobx-react';

type WorkshopDepot = Common.Queries.Workshop.GetWorkshopDepots.WorkshopDepotDto;

const monthQueryFormat = 'yyyy-MM';

function getWeekdayLabels() {
  return Array.from(Array(7), (_, i) => DateTime.fromObject({ weekday: i + 1 }).weekdayLong);
}

function chunkArray<T>(array: Array<T>, size: number) {
  const result = [];
  for (let i = 0; i < array.length; i += size) {
    result.push(array.slice(i, i + size));
  }
  return result;
}

function getMonthTableDaysByWeek(d: DateTime) {
  const start = d.startOf('month').set({ weekday: 1 });
  const end = d.endOf('month').set({ weekday: 7 });
  const dayCount = Math.round(end.diff(start, 'days').days);
  const days = Array.from(Array(dayCount), (_, i) => start.plus({ days: i }));
  const daysByWeek = chunkArray(days, 7);
  return daysByWeek.map(w => ({ isoWeek: w[0].toISOWeekDate(), days: w }));
}

interface IControlPanelProps {
  zonedNow: DateTime;
  zonedMonthToDisplay: DateTime;
  getNavigationLink: (monthToDisplay: string, depotId: number) => LocationDescriptorObject;
  onMonthChanged: (year: number, month: number, depotId: number) => void;
  workshopDepots: Array<WorkshopDepot>;
  selectedWorkshopDepotId: number;
  changeSelectedWorkshopDepotId: (
    selectedWorkshopDepotId: number,
    zonedMonthToDisplay: DateTime
  ) => void;
}

const ControlPanel: React.FC<IControlPanelProps> = ({
  zonedNow,
  zonedMonthToDisplay,
  getNavigationLink,
  onMonthChanged,
  workshopDepots,
  selectedWorkshopDepotId,
  changeSelectedWorkshopDepotId,
}) => {
  const prev = zonedMonthToDisplay.minus({ months: 1 });
  const next = zonedMonthToDisplay.plus({ months: 1 });
  const alreadyOnThisMonth = zonedMonthToDisplay.month === zonedNow.month;
  const prevAction: ILinkAction = {
    label: <ChevronLeftIcon size="sm" />,
    size: 'sm',
    to: getNavigationLink(prev.toFormat(monthQueryFormat), selectedWorkshopDepotId),
    onClick: () => onMonthChanged(prev.year, prev.month, selectedWorkshopDepotId),
  };
  const dayViewLink: ILinkAction = {
    label: 'View Daily Schedule',
    size: 'sm',
    to: `/workshop/jobs/schedule?depotId=${selectedWorkshopDepotId}`,
  };
  const weekViewLink: ILinkAction = {
    label: 'View Weekly Schedule',
    size: 'sm',
    to: `/workshop/jobs/weekly-schedule?week=${zonedNow
      .set({ weekday: 1 })
      .toISOWeekDate()}&depotId=${selectedWorkshopDepotId}`,
  };
  const monthViewLink: ILinkAction = {
    label: 'HV Machinery Schedule',
    size: 'sm',
    outline: true,
    disabled: true,
    to: `/workshop/jobs/machinery-schedule?month=${zonedNow.toFormat(
      'yyyy-MM'
    )}&depotId=${selectedWorkshopDepotId}`,
  };
  const todayAction: ILinkAction = {
    label: 'Go to This Month',
    size: 'sm',
    to: getNavigationLink(zonedNow.toFormat(monthQueryFormat), selectedWorkshopDepotId),
    onClick: () => onMonthChanged(zonedNow.year, zonedNow.month, selectedWorkshopDepotId),
    disabled: alreadyOnThisMonth,
  };
  const nextAction: ILinkAction = {
    label: <ChevronRightIcon size="sm" />,
    size: 'sm',
    to: getNavigationLink(next.toFormat(monthQueryFormat), selectedWorkshopDepotId),
    onClick: () => onMonthChanged(next.year, next.month, selectedWorkshopDepotId),
  };
  const depotDropdown = getWorkshopDepotSelectAction(
    workshopDepots,
    selectedWorkshopDepotId,
    (selectedDepot: WorkshopDepot) => {
      if (selectedDepot) {
        changeSelectedWorkshopDepotId(selectedDepot.id, zonedMonthToDisplay);
        onMonthChanged(zonedMonthToDisplay.year, zonedMonthToDisplay.month, selectedDepot.id);
      }
    }
  );
  return (
    <ActionBar
      groups={[
        [prevAction, todayAction, nextAction],
        [depotDropdown],
        [dayViewLink, weekViewLink, monthViewLink],
      ]}
    />
  );
};

interface IDayMoreContentProps {
  day?: DateTime;
  jobs: IScheduledMachineryJob[];
  selectedJobId?: string;
  onJobSelected: IScheduledJobProps['onJobSelected'];
}

const DayMoreContent: React.FC<IDayMoreContentProps> = ({
  day,
  jobs,
  selectedJobId,
  onJobSelected,
}) => {
  if (!day) {
    return null;
  }
  return (
    <div className="machinery-job-scheduler-component-day-more-content" style={{ width: '16em' }}>
      {jobs.map(j => (
        <ScheduledJob
          key={j.jobId}
          isInMorePopup
          job={j}
          day={day}
          isSelected={j.jobId === selectedJobId}
          onJobSelected={onJobSelected}
        />
      ))}
    </div>
  );
};

interface IDayCellProps {
  day: DateTime;
  jobs: IScheduledMachineryJob[];
  selectedJobId?: string;
  onMoreJobsClick: (id: string, day: DateTime) => void;
  onJobSelected: IScheduledJobProps['onJobSelected'];
  selectedWorkshopDepotId: number;
}

const DayCell: React.FC<IDayCellProps> = ({
  day,
  jobs,
  selectedJobId,
  onMoreJobsClick,
  onJobSelected,
  selectedWorkshopDepotId,
}) => {
  const id = `day-${day.toISODate()}`;
  const maxJobsShown = 10;
  const manyJobs = jobs.length > maxJobsShown;
  const items = manyJobs ? [...jobs.filter((o, i) => i < maxJobsShown)] : jobs;
  return (
    <div className="day-cell">
      <Link
        to={`/workshop/jobs/schedule?day=${day.toISODate()}&depotId=${selectedWorkshopDepotId}`}>
        <small>
          <strong>{day.day}</strong>
        </small>
      </Link>
      {items.map(j => (
        <ScheduledJob
          key={j.jobId}
          job={j}
          day={day}
          isSelected={j.jobId === selectedJobId}
          onJobSelected={onJobSelected}
        />
      ))}
      {manyJobs && (
        <Button
          id={id}
          className="more-jobs-btn"
          outline
          size="sm"
          onClick={() => onMoreJobsClick(id, day)}>
          {jobs.length - maxJobsShown} more jobs
        </Button>
      )}
    </div>
  );
};

export interface ISchedulerProps {
  showInZone: string;
  nowUtc: DateTime;
  jobsByZonedDay: Map<string, IScheduledMachineryJob[]>;
  jobsLoading: boolean;
  monthToDisplay?: string;
  getNavigationLink: (monthToDisplay: string, depotId: number) => LocationDescriptorObject;
  onMonthChanged: (year: number, month: number, depotId: number) => void;
  workshopDepots: Array<WorkshopDepot>;
  defaultWorkshopDepot: WorkshopDepot;
  selectedWorkshopDepotId: number;
  changeSelectedWorkshopDepotId: (
    selectedWorkshopDepotId: number,
    zonedMonthToDisplay: DateTime
  ) => void;
}

const Scheduler: React.FC<ISchedulerProps> = observer((props: ISchedulerProps) => {
  const [isMorePopoverOpen, setIsMorePopoverOpen] = useState<boolean>(false);
  const [morePopoverTargetId, setMorePopoverTargetId] = useState<string | undefined>(undefined);
  const [morePopoverTargetDay, setMorePopoverTargetDay] = useState<DateTime | undefined>(undefined);
  const [selectedJobId, setSelectedJobId] = useState<string | undefined>(undefined);
  const [selectedJobTargetId, setSelectedJobTargetId] = useState<string | undefined>(undefined);
  const zonedNow = props.nowUtc.setZone(props.showInZone);
  const zonedMonthToDisplay = props.monthToDisplay
    ? DateTime.fromISO(props.monthToDisplay, { zone: props.showInZone })
    : zonedNow;
  const allJobs = Array.from(props.jobsByZonedDay.values()).reduce((x, a) => x.concat(a), []);

  useEffect(() => {
    props.onMonthChanged(
      zonedMonthToDisplay.year,
      zonedMonthToDisplay.month,
      props.selectedWorkshopDepotId || props.defaultWorkshopDepot.id
    );
  }, []);

  const showMorePopover = (id: string, day: DateTime) => {
    setIsMorePopoverOpen(morePopoverTargetId === id ? !isMorePopoverOpen : true);
    setMorePopoverTargetId(id);
    setMorePopoverTargetDay(day);
  };

  const handleJobSelected = (job: IScheduledMachineryJob, targetId: string) => {
    setSelectedJobId(job.jobId);
    setSelectedJobTargetId(selectedJobTargetId === targetId ? undefined : targetId);
  };

  const handleJobDeselected = () => {
    setSelectedJobId(undefined);
    setSelectedJobTargetId(undefined);
  };

  const toggleSelectedJobPopover = () => handleJobDeselected();

  const {
    jobsByZonedDay,
    getNavigationLink,
    onMonthChanged,
    jobsLoading,
    workshopDepots,
    selectedWorkshopDepotId,
    changeSelectedWorkshopDepotId,
  } = props;

  const currentMonth = zonedMonthToDisplay.month;
  const currentDay = zonedNow.startOf('day');
  const selectedJob = allJobs.find(j => j.jobId === selectedJobId);
  const morePopoverDayKey = morePopoverTargetDay && morePopoverTargetDay.toISODate();
  const morePopoverJobs = jobsByZonedDay.get(morePopoverDayKey || '') || [];

  return (
    <div className="machinery-job-scheduler-component" onClick={handleJobDeselected}>
      <div>
        <ControlPanel
          zonedNow={zonedNow}
          zonedMonthToDisplay={zonedMonthToDisplay}
          getNavigationLink={getNavigationLink}
          onMonthChanged={onMonthChanged}
          workshopDepots={workshopDepots}
          selectedWorkshopDepotId={selectedWorkshopDepotId}
          changeSelectedWorkshopDepotId={changeSelectedWorkshopDepotId}
        />
        <span className={`scheduler-loading ${jobsLoading ? 'visible' : ''}`}>
          <SpinnerIcon fixedWidth />
          <small className="loading-text">Loading ...</small>
        </span>
        <ScheduleLegend full={false} />
      </div>
      <hr />
      <Table className="calendar" borderless>
        <thead className="calendar-header">
          <tr>
            {getWeekdayLabels().map(w => (
              <th key={w}>{w}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {getMonthTableDaysByWeek(zonedMonthToDisplay).map(w => (
            <tr key={w.isoWeek}>
              {w.days.map(d => (
                <td
                  key={d.toISODate()}
                  className={`${d.equals(currentDay) ? 'today' : ''} ${
                    d.month !== currentMonth ? 'padding-day' : ''
                  }`}>
                  <DayCell
                    day={d}
                    jobs={jobsByZonedDay.get(d.toISODate()) || []}
                    selectedJobId={selectedJobId}
                    onMoreJobsClick={showMorePopover}
                    onJobSelected={handleJobSelected}
                    selectedWorkshopDepotId={selectedWorkshopDepotId}
                  />
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </Table>
      {morePopoverTargetId && (
        <Popover
          key={morePopoverTargetId}
          placement="auto"
          flip={false}
          fade={false}
          isOpen={isMorePopoverOpen}
          target={morePopoverTargetId}
          toggle={() => setIsMorePopoverOpen(!isMorePopoverOpen)}>
          <PopoverBody>
            <DayMoreContent
              day={morePopoverTargetDay}
              jobs={morePopoverJobs}
              selectedJobId={selectedJobId}
              onJobSelected={handleJobSelected}
            />
          </PopoverBody>
        </Popover>
      )}
      {selectedJobTargetId && selectedJob && (
        <JobDetailsPopover
          key={selectedJobTargetId}
          job={selectedJob}
          target={selectedJobTargetId}
          onClose={toggleSelectedJobPopover}
        />
      )}
    </div>
  );
});

export default Scheduler;
