import './TimesheetTotals.scss';
import { DurationFormat } from 'src/views/components/DurationFormat';
import { ITimesheetRecordsTotals } from 'src/views/routes/people/timesheet/MaintainTimesheet/MaintainTimesheet';

export interface ITimesheetTotalsProps {
  summary: ITimesheetRecordsTotals;
}

const TimesheetTotals: React.FC<ITimesheetTotalsProps> = ({ summary }) => {
  return (
    <div className="timesheet-totals-component">
      <table className="timesheet-totals-table full-width bordered text-center">
        <thead>
          <tr>
            <th className="summary-cell">Total Worked Hrs</th>
            <th className="summary-cell">Total Leave Hrs</th>
            <th className="summary-cell">Total Public Holiday Hrs</th>
            <th className="summary-cell">Total Paid Hrs</th>
            <th className="summary-cell">Total On Call Days</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              {summary.totalWorkedHrs.valueOf() > 0 ? (
                <DurationFormat
                  value={summary.totalWorkedHrs}
                  showHoursAndDecimals
                  showHoursAndMinutes
                />
              ) : (
                undefined
              )}
            </td>
            <td>
              {summary.totalLeaveHrs.valueOf() > 0 ? (
                <DurationFormat
                  value={summary.totalLeaveHrs}
                  showHoursAndDecimals
                  showHoursAndMinutes
                />
              ) : (
                undefined
              )}
            </td>
            <td>
              {summary.totalPublicHolidayHrs.valueOf() > 0 ? (
                <DurationFormat
                  value={summary.totalPublicHolidayHrs}
                  showHoursAndDecimals
                  showHoursAndMinutes
                />
              ) : (
                undefined
              )}
            </td>
            <td>
              <DurationFormat
                value={summary.totalPaidHrs}
                showHoursAndDecimals
                showHoursAndMinutes
              />
            </td>
            <td>{summary.totalOnCall > 0 && <>{summary.totalOnCall}</>}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default TimesheetTotals;
