import { types } from 'mobx-state-tree';
import { ListExtraTypeModel } from './ListExtraTypeModel';
import { ExtraTypeItemModel } from './ExtraTypeItemModel';

export const ExtraTypesModel = types.model('ExtraTypesModel', {
  item: types.optional(ExtraTypeItemModel, {}),
  list: types.optional(ListExtraTypeModel, {}),
});
type ModelType = typeof ExtraTypesModel.Type;
export interface IExtraTypesModel extends ModelType {}
