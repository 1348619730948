import { types, flow, getRoot } from 'mobx-state-tree';
import { getAjax } from 'src/domain/services/storeEnvironment';
import { IRootStoreModel } from 'src/domain/entities/RootStoreModel';
import { NotificationType } from 'src/domain';
import { IAutocompleteResult } from 'src/domain/baseTypes';

type CustomerItem = Operations.Domain.Queries.ViewCustomer.CustomerItem;
type SearchCustomerItem = Operations.Domain.Queries.SearchCustomers.CustomerItem;
type ContactItem = Operations.Domain.Queries.SearchContactsForCustomer.ContactItem;
type CustomerListItem = Common.Dtos.CustomerItem;
type ListResult<T> = Common.Dtos.ListResult<T>;

export const CustomerModel = types
  .model('CustomerModel', {
    customer: types.maybe(types.frozen<CustomerItem>()),
    customers: types.array(types.frozen<CustomerListItem>()),
  })
  .actions(self => {
    const ajax = getAjax(self);
    const root = getRoot(self) as IRootStoreModel;

    let lastCustomerSearch: string | undefined;
    let lastCustomerSearchResult: IAutocompleteResult<SearchCustomerItem> | undefined;

    const createCustomer = flow(function*(
      command: Operations.Domain.Commands.Customer.CreateCustomerCommand,
      suppressNavigate?: boolean
    ) {
      const id: string = yield ajax.sales.customer.createCustomer(command);
      root.notifications.addNotification(`Successfully created customer ${command.name}`, {
        type: NotificationType.success,
      });
      self.customer = undefined;
      lastCustomerSearch = undefined;
      lastCustomerSearchResult = undefined;

      if (!suppressNavigate) {
        root.history.push(`/sales/customers/${id}`);
      }

      return id;
    });

    const checkForUniqueName = flow(function*(name: string) {
      return yield ajax.sales.customer.checkForUniqueName(name);
    });

    const loadCustomer = flow(function*(customerId: string) {
      self.customer = yield ajax.sales.customer.viewCustomer(customerId);
    });

    const editCustomer = flow(function*(
      command: Operations.Domain.Commands.Customer.UpdateCustomerCommand
    ) {
      yield ajax.sales.customer.editCustomer(command);
      root.notifications.addNotification(`Successfully edited customer ${command.name}`, {
        type: NotificationType.success,
      });
      yield loadCustomer(command.id);
    });

    const createContact = flow(function*(
      command: Operations.Domain.Commands.Customer.CreateCustomerContactCommand
    ) {
      const id: number = yield ajax.sales.customer.createContact(command);
      root.notifications.addNotification(
        `Successfully created contact ${command.firstName} ${command.lastName}`,
        {
          type: NotificationType.success,
        }
      );
      yield loadCustomer(command.customerId);

      return id;
    });

    const searchCustomers = flow(function*(search: string) {
      if (search === lastCustomerSearch) {
        return lastCustomerSearchResult || { options: [] };
      }
      const customers: ListResult<SearchCustomerItem> = yield ajax.sales.customer.searchCustomers(
        search
      );
      lastCustomerSearch = search;
      lastCustomerSearchResult = {
        options: customers.items,
      };
      return lastCustomerSearchResult;
    });

    const findCustomers = flow(function*(ids: string[]) {
      if (!ids || !ids.length) {
        return { options: [] };
      }
      const customers: ListResult<SearchCustomerItem> = yield ajax.sales.customer.findCustomers(
        ids
      );
      return { options: customers.items };
    });

    const searchContactsForCustomer = flow(function*(
      customerId: string | undefined,
      search: string
    ) {
      if (!customerId) {
        return { options: [] };
      } else {
        const contacts: ContactItem[] = yield ajax.sales.customer.searchContactsForCustomer(
          customerId,
          search
        );
        return { options: contacts };
      }
    });

    const findContactsForCustomer = flow(function*(customerId: string | undefined, ids: number[]) {
      if (!customerId) {
        return { options: [] };
      } else {
        const contacts: ContactItem[] = yield ajax.sales.customer.findContactsForCustomer(
          customerId,
          ids
        );
        return { options: contacts };
      }
    });

    const editContact = flow(function*(
      command: Operations.Domain.Commands.Customer.UpdateCustomerContactCommand
    ) {
      yield ajax.sales.customer.editContact(command);
      root.notifications.addNotification(
        `Successfully edited contact ${command.firstName} ${command.lastName}`,
        {
          type: NotificationType.success,
        }
      );
      yield loadCustomer(command.customerId);
    });

    const deleteContact = flow(function*(
      command: Operations.Domain.Commands.Customer.DeleteCustomerContactCommand
    ) {
      yield ajax.sales.customer.deleteContact(command);
      root.notifications.addNotification(`Successfully deleted contact`, {
        type: NotificationType.success,
      });
      yield loadCustomer(command.customerId);
    });

    const deleteCustomer = flow(function*(
      command: Operations.Domain.Commands.Customer.DeleteCustomerCommand
    ) {
      yield ajax.sales.customer.deleteCustomer(command);
      root.notifications.addNotification(`Successfully deleted customer`, {
        type: NotificationType.success,
      });

      self.customer = undefined;
      root.history.push('/sales/customers');
    });

    const generateBookingsAndQuotesReport = flow(function*(
      query: Operations.Domain.Queries.GenerateUpcomingBookingsReportForCustomer.GenerateUpcomingBookingsReportForCustomerQuery
    ) {
      root.notifications.addNotification(`Generating report...`, { type: NotificationType.info });
      return yield ajax.sales.customer.generateBookingsAndQuotesReport(query);
    });

    const createCustomerNote = flow(function*(
      command: Operations.Domain.Commands.Customer.CreateCustomerNoteCommand
    ) {
      yield ajax.sales.customer.createCustomerNote(command);
      loadCustomer(command.customerId);
    });

    const updateCustomerNote = flow(function*(
      command: Operations.Domain.Commands.Customer.UpdateCustomerNoteCommand
    ) {
      yield ajax.sales.customer.updateCustomerNote(command);
      loadCustomer(command.customerId);
    });

    const deleteCustomerNote = flow(function*(customerId: string, noteId: number) {
      yield ajax.sales.customer.deleteCustomerNote(customerId, noteId);
      loadCustomer(customerId);
    });

    const loadCustomers = flow(function*() {
      self.customers = yield ajax.sales.customer.loadCustomers();
    });

    return {
      searchCustomers,
      findCustomers,
      createCustomer,
      checkForUniqueName,
      loadCustomer,
      editCustomer,
      createContact,
      editContact,
      deleteContact,
      deleteCustomer,
      searchContactsForCustomer,
      findContactsForCustomer,
      generateBookingsAndQuotesReport,
      createCustomerNote,
      updateCustomerNote,
      deleteCustomerNote,
      loadCustomers,
    };
  });
