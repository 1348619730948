import styles from './Shifts.module.scss';

import SplitScreen from '../components/layout/SplitScreen/SplitScreen';
import ShiftsCalendar from './ShiftsCalendar';
import { DateTime } from 'luxon';
import { RenderJobInterval } from 'src/views/routes/workshop/jobs/jobSchedule/Scheduler/PrintDailyRunSheet/PrintDailyRunSheet';
import { useEffect, useState } from 'react';
import PrintButton from 'src/views/components/PrintButton';
import { PrintIcon } from 'src/images/icons';
import PrintShifts from './PrintShifts';
import WithMenu from '../menu/WithMenu';
import { TIME_24_SIMPLE_HOURCYCLE_23 } from 'src/infrastructure/dateUtils';
import { AlternateDepotBadge } from '../home/Home';
import React from 'react';
import deepEqual from 'src/infrastructure/deepEqual';

type ShiftListItem = Workshop.Domain.Queries.Shift.ListShifts.ShiftListItem;
type ListShiftsQuery = Workshop.Domain.Queries.Shift.ListShifts.ListShiftsQuery;

export interface IShiftsProps {
  userId?: string;
  userName?: string;
  shifts: ShiftListItem[];
  loadShifts: (query: Partial<ListShiftsQuery>) => Promise<void>;
  defaultKioskDepotId: number;
}

const Shifts: React.FC<IShiftsProps> = ({
  shifts,
  loadShifts,
  userId,
  userName,
  defaultKioskDepotId,
}: IShiftsProps) => {
  const [currentDate, setCurrentDate] = useState(DateTime.local());
  const [currentMonth, setCurrentMonth] = useState({
    monthStart: currentDate.startOf('month').toISODate(),
    monthEnd: currentDate.endOf('month').toISODate(),
  });

  useEffect(() => {
    loadShifts({
      dateFrom: currentMonth.monthStart,
      dateTo: currentMonth.monthEnd,
      size: 9999,
    });
  }, [currentMonth, userId]);

  const changeDate = (date: DateTime) => {
    if (currentDate.toISODate() !== date.toISODate()) {
      setCurrentDate(date);

      const newMonth = {
        monthStart: date.startOf('month').toISODate(),
        monthEnd: date.endOf('month').toISODate(),
      };

      if (!deepEqual(currentMonth, newMonth)) {
        setCurrentMonth({
          ...newMonth,
        });
      }
    }
  };

  const shiftSummaries: {
    [key: string]: {
      name: string;
      staff: Set<string>;
      staffIds: Set<string>;
      start: string;
      end: string;
      depotId: number;
      depotName: string;
    };
  } = shifts
    .filter(
      s =>
        DateTime.fromISO(s.shiftCommence) < currentDate.endOf('day') &&
        DateTime.fromISO(s.shiftEnd) >= currentDate.startOf('day')
    )
    .reduce((m, s) => {
      const key = `${s.shiftName}-${DateTime.fromISO(s.shiftCommence).toLocaleString(
        TIME_24_SIMPLE_HOURCYCLE_23
      )}-${DateTime.fromISO(s.shiftEnd).toLocaleString(TIME_24_SIMPLE_HOURCYCLE_23)}-${
        s.shiftDepot.id
      }`;
      return m[key]
        ? {
            ...m,
            [key]: {
              ...m[key],
              staff: m[key].staff.add(s.staffMember.staffMemberFullName),
              staffIds: m[key].staffIds.add(s.staffMember.staffMemberId),
            },
          }
        : {
            ...m,
            [key]: {
              name: s.shiftName,
              staff: new Set([s.staffMember.staffMemberFullName]),
              staffIds: new Set([s.staffMember.staffMemberId]),
              start: s.shiftCommence,
              end: s.shiftEnd,
              depotId: s.shiftDepot.id,
              depotName: s.shiftDepot.name,
            },
          };
    }, {});

  const shiftsForMe = shifts.filter(s => s.staffMember.staffMemberId === userId);

  const left = (
    <div className={styles.left}>
      <WithMenu width={30}>
        <ShiftsCalendar
          shifts={shifts.filter(s => s.staffMember.staffMemberId === userId)}
          activeStartDateChanged={(date: DateTime) => changeDate(date)}
          defaultKioskDepotId={defaultKioskDepotId}
        />
      </WithMenu>
    </div>
  );
  const right = (
    <div className={styles.right}>
      <div className={styles.header}>
        <div className={styles.currentDate}>{currentDate.toLocaleString(DateTime.DATE_SHORT)}</div>
        <PrintButton
          className="print-button"
          size="sm"
          outline
          printContent={() =>
            shiftsForMe && (
              <PrintShifts
                shifts={shiftsForMe}
                date={currentDate}
                defaultKioskDepotId={defaultKioskDepotId}
                userName={userName}
              />
            )
          }>
          <PrintIcon />
          Print Shifts for {currentDate.monthLong}
        </PrintButton>
      </div>
      <div className={styles.shiftsContainer}>
        {shifts &&
          shifts.length > 0 &&
          Object.keys(shiftSummaries).map(s => {
            const shift = shiftSummaries[s];
            const isForMeOrKioskDepot =
              shift.depotId === defaultKioskDepotId ||
              (userId && Array.from(shift.staffIds).includes(userId));
            return isForMeOrKioskDepot ? (
              <div key={s} className={styles.shift}>
                <div>
                  <span className={styles.shiftName}>{shift.name} </span>
                  <span className={styles.shiftTime}>
                    {RenderJobInterval(shift.start, shift.end, currentDate)}
                  </span>
                  <AlternateDepotBadge
                    targetDepotId={shift.depotId}
                    targetDepotName={shift.depotName}
                    defaultKioskDepotId={defaultKioskDepotId}
                    className={styles.shiftDepot}
                  />
                </div>
                <div className={styles.shiftStaff}>
                  {Array.from(shift.staff).map((sum, idx) => (
                    <span key={idx}>
                      {idx > 0 ? ', ' : ''}
                      <span key={s} className={sum === userName ? styles.user : undefined}>
                        {sum}
                      </span>
                    </span>
                  ))}
                </div>
              </div>
            ) : null;
          })}
      </div>
    </div>
  );

  return (
    <div className={styles.shifts}>
      <SplitScreen left={left} right={right} leftWidth={30} />
    </div>
  );
};

export default Shifts;
