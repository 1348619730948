import inject from 'src/views/injectFromStore';
import ListDataFuelUploads, { IListDataFuelUploadsProps } from './ListDataFuelUploads';

const Container = inject<IListDataFuelUploadsProps>((allStores, p) => ({
  hasMoreData: allStores.rootStore.workshop.dataFuelUploads.hasMoreItems,
  uploadedFiles: allStores.rootStore.workshop.dataFuelUploads.items.slice(),
  loadUploadedFiles: allStores.rootStore.workshop.dataFuelUploads.listItems,
  uploadFile: allStores.rootStore.workshop.dataFuel.uploadFile,
  updateEntry: allStores.rootStore.workshop.dataFuel.updateEntry,
  assets: allStores.rootStore.assets.assetListItems.slice(),
  loadAssets: allStores.rootStore.assets.loadAssetListItems,
  staffMembers: allStores.rootStore.people.staffMembers.allStaffMembers.slice(),
  loadAllStaffMembers: allStores.rootStore.people.staffMembers.loadAllStaffMembers,
  markUploadAsFixed: allStores.rootStore.workshop.dataFuel.markUploadAsFixed,
}))(ListDataFuelUploads);

export default Container;
