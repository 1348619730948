import { buildListPageApiSearchModelTypedQuery } from 'src/domain/modelBuilders/buildListPageApiSearchModel';
import { types, getRoot, flow } from 'mobx-state-tree';
import { getAjax } from 'src/domain/services';
import { IRootStoreModel } from 'src/domain/entities/RootStoreModel';
import { NotificationType } from 'src/domain';

type ListAllQuotesQuery = Operations.Domain.Queries.ListAllQuotes.ListAllQuotesQuery;

const ListAllQuotesModel = buildListPageApiSearchModelTypedQuery<
  ListAllQuotesQuery
>()('ListAllQuotesModel', d => d.ajax.sales.quote.listAllQuotes(d.query));

const ExportAllQuotesModel = types.model('ExportAllQuotesModel').actions(self => {
  const ajax = getAjax(self);
  const root = getRoot(self) as IRootStoreModel;

  const exportToExcel = flow(function*(
    query: Partial<Operations.Domain.Queries.ListAllQuotes.ListAllQuotesQuery>
  ) {
    root.notifications.addNotification(`The file is being generated ...`, {
      type: NotificationType.info,
    });

    return yield ajax.sales.quote.exportAllQuotesToExcel(query);
  });

  return {
    exportToExcel,
  };
});

export const AllQuotesListModel = types.compose(ListAllQuotesModel, ExportAllQuotesModel);
