import { Interval, DateTime, Duration, LocaleOptions, DateTimeFormatOptions } from 'luxon';
import { parseEditingFormattedTimeString } from 'src/views/components/Page/fields/subfields/TimeHelpers';

// https://github.com/moment/luxon/issues/675
export const TIME_24_SIMPLE_HOURCYCLE_23: LocaleOptions & DateTimeFormatOptions = {
  hour: 'numeric',
  minute: 'numeric',
  // @ts-ignore
  hourCycle: 'h23',
};

export function getZonedDaysFromInterval(interval: Interval, zone: string) {
  const zonedStartDay = interval.start.setZone(zone).startOf('day');
  const zonedEndDay = interval.end.setZone(zone).startOf('day');
  // If this interval finishes at midnight, do not allow it to flow into the next day.
  const offset = interval.end.setZone(zone).get('hour') === 0 ? 0 : 1;

  const days = zonedEndDay.diff(zonedStartDay, 'days').days + offset;
  return Array.from(Array(days), (_, i) => zonedStartDay.plus({ days: i }));
}

export function intervalCompare(a: Interval, b: Interval) {
  if (a.start < b.start) {
    return -1;
  }
  if (a.start > b.start) {
    return 1;
  }
  if (a.end < b.end) {
    return -1;
  }
  if (a.end > b.end) {
    return 1;
  }
  return 0;
}

// Absolute number of whole days between two datetimes
export function getDaysBetweenCount(a: DateTime, b: DateTime) {
  return Math.abs(a.startOf('day').diff(b.startOf('day'), 'day').days);
}

// Enumerate out an array of datetimes
export function getDaysArray(firstDay: DateTime, daysCount: number) {
  return Array.from(Array(Math.ceil(daysCount)), (_, i) => firstDay.plus({ days: i }));
}

// Get an array of the datetimes in an interval (inclusive)
export function getDaysInInterval(interval: Interval) {
  return getDaysArray(interval.start, getDaysBetweenCount(interval.start, interval.end) + 1);
}

export function intervalsEqual(a: Interval | null | undefined, b: Interval | null | undefined) {
  if (a === b) {
    return true;
  }
  if (a && b && a.equals(b)) {
    return true;
  }
  return false;
}

export function intervalMiddle(a: Interval) {
  return a.divideEqually(2)[1].start;
}

export function getStartOfWeek(date: DateTime): DateTime {
  let result = date;
  while (result.weekday !== 1) {
    result = result.plus({ days: -1 });
  }
  return result;
}

export function parseTimeSpan(originalValue: string | undefined) {
  const fixedValue = appendSecondsIfNeeded(originalValue);
  if (fixedValue === undefined) {
    return Duration.invalid('undefined value');
  }
  // Use regexr.com to look at this regex if needed
  // Extracts out days, hours and minutes, seconds and miliseconds as groups
  // Support for '[d.]hh:mm:ss[.fffffff]' and '[d:]hh:mm:ss[.fffffff]'
  const re = /^((\d+)[:.])?(\d{2}):(\d{2}):(\d{2})([.](\d+))?$/;
  const matches = re.exec(fixedValue);
  if (!matches) {
    return Duration.invalid('Failed parsing');
  }

  const days = Number.parseInt(matches[2] || '0', 10);
  const hours = Number.parseInt(matches[3], 10);
  const minutes = Number.parseInt(matches[4], 10);
  const seconds = Number.parseInt(matches[5], 10);
  let millisecondsString = matches[7] || '0';
  if (millisecondsString.length > 3) {
    millisecondsString = millisecondsString.substring(0, 3);
  }
  const milliseconds = Number.parseInt(millisecondsString, 10);

  return Duration.fromObject({ days, hours, minutes, seconds, milliseconds });
}

function appendSecondsIfNeeded(value: string | undefined) {
  const re = /^(\d*):(\d*)$/;
  if (value === undefined || !re.exec(value)) {
    return value;
  }
  return `${value}:00`;
}

export const validateDateTimeIsNotLessThan = (
  first: string | undefined,
  firstLabel: string,
  second: string | undefined,
  secondLabel: string
) => {
  return !!first && !!second && DateTime.fromISO(first) < DateTime.fromISO(second)
    ? `${firstLabel} cannot be less than ${secondLabel}`
    : undefined;
};

export const validateTimeIsNotLessThan = (
  first: string | undefined,
  firstLabel: string,
  second: string | undefined,
  secondLabel: string
) => {
  var date = DateTime.local().toISODate();
  return !!first &&
    !!second &&
    DateTime.fromISO(`${date}T${first}`) < DateTime.fromISO(`${date}T${second}`)
    ? `${firstLabel} cannot be less than ${secondLabel}`
    : undefined;
};

export const getNextDateTime = (current: DateTime | undefined, nextTime: string | undefined) => {
  const next = nextTime && parseEditingFormattedTimeString(nextTime);
  if (!current || !next || !next.isValid) {
    return undefined;
  }

  const currentDay = current.startOf('day');
  const currentTime = current.diff(currentDay);
  if (currentTime.as('milliseconds') <= next.as('milliseconds')) {
    return currentDay.plus(next);
  } else {
    return currentDay.plus({ days: 1 }).plus(next);
  }
};

export function dateIsAfter(x: string | undefined, y: string | undefined) {
  return x && y && DateTime.fromISO(y) < DateTime.fromISO(x);
}
