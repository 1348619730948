import { PlusIcon, TrashIcon } from 'src/images/icons';
import { getSubmitCloseModalActionGroupDef } from 'src/views/definitionBuilders/common';
import {
  ActionType,
  FieldType,
  ISectionDef,
  PaneType,
  ShellModalSize,
} from 'src/views/definitionBuilders/types';

type CreateExclusionCommand = Operations.Domain.Commands.Exclusion.CreateExclusion.CreateExclusionCommand;
type ExclusionItem = Operations.Domain.Queries.ListExclusions.ExclusionItem;
type FleetAssetItem = Common.Queries.Workshop.GetFleetAssetList.AssetItem;

export default function getExclusionSectionDef(
  canSave: boolean,
  fleetAssets: FleetAssetItem[],
  exclusionsForStaffMember: ExclusionItem[],
  staffMemberId: string,
  createExclusion: (cmd: CreateExclusionCommand) => Promise<void>,
  deleteExclusion: (exclusionId: string, staffMemberId: string) => Promise<void>,
  primarySectionEditing: boolean
): ISectionDef {
  return {
    title: 'Vehicle Restrictions',
    panels: [
      {
        panes: [
          {
            paneType: PaneType.customPane,
            render: () =>
              'Vehicles on this list will not be available for this driver. This driver cannot be allocated to vehicles on this list.',
          },
          {
            paneType: PaneType.tablePane,
            dataAddr: 'exclusions',
            title: 'Vehicle Restriction List',
            neverEditable: true,
            fields: [
              {
                fieldType: FieldType.readonlyField,
                dataAddr: ['asset', 'name'],
                label: 'Asset',
                linkTo: d => `/workshop/assets/${d.parentValue.asset.assetId}`,
              },
              {
                fieldType: FieldType.actionListField,
                columnWidth: '1px',
                hidden: () => !canSave || primarySectionEditing,
                actionGroups: [
                  {
                    actions: [
                      {
                        actionType: ActionType.modalActionButton,
                        icon: <TrashIcon />,
                        label: 'Delete Vehicle Restriction',
                        modalSize: ShellModalSize.oneQuarter,
                        modalDef: modalDefApi => ({
                          title: 'Delete Vehicle Restriction',
                          asForm: true,
                          panels: [
                            {
                              panes: [
                                {
                                  paneType: PaneType.customPane,
                                  render: () => (
                                    <p>Are you sure you want to delete this vehicle restriction?</p>
                                  ),
                                },
                              ],
                            },
                          ],
                          secondaryActions: [getSubmitCloseModalActionGroupDef('Ok')],
                          onFormSubmit: () => {
                            const exclusion = modalDefApi.actionData.parentValue as ExclusionItem;
                            return deleteExclusion(exclusion.id, staffMemberId);
                          },
                        }),
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
    secondaryActions: [
      {
        actions: [
          {
            hidden: () => !canSave || primarySectionEditing,
            actionType: ActionType.modalActionButton,
            label: 'Add a Vehicle',
            icon: <PlusIcon />,
            level: 'primary',
            modalSize: ShellModalSize.oneThird,
            modalDef: () => ({
              title: 'Add a Vehicle',
              asForm: true,
              panels: formApi => [
                {
                  panes: [
                    {
                      paneType: PaneType.customPane,
                      render: () => (
                        <p>
                          Select the vehicle(s) you want to restrict the driver from being allocated
                          to
                        </p>
                      ),
                    },
                    {
                      paneType: PaneType.formFieldsPane,
                      fields: [
                        {
                          fieldType: FieldType.selectMultiField,
                          label: 'Vehicle',
                          dataAddr: 'assets',
                          optionItems: fleetAssets,
                          valueKey: 'id',
                          descriptionKey: 'name',
                          mandatory: true,
                          valuesToExclude: exclusionsForStaffMember.map(x => x.asset.id),
                        },
                      ],
                    },
                    {
                      paneType: PaneType.actionListPane,
                      actionGroups: [
                        {
                          actions: [
                            {
                              actionType: ActionType.actionButton,
                              label: 'Add All Vehicles',
                              level: 'primary',
                              icon: <PlusIcon />,
                              onClick: data => {
                                let selectedAssets = data.panelValue?.assets ?? [];
                                let selectedPlusAlreadyRestricted = exclusionsForStaffMember
                                  .map(x => x.asset.id)
                                  .concat(selectedAssets.map((x: { id: any }) => x.id));
                                let newlySelectedAssets = selectedAssets.concat(
                                  fleetAssets.filter(
                                    asset =>
                                      !selectedPlusAlreadyRestricted.find(a => a === asset.id)
                                  )
                                );
                                formApi.setValue('assets', Array.from(newlySelectedAssets));
                              },
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
              secondaryActions: [getSubmitCloseModalActionGroupDef('Ok')],
              onFormSubmit: values => {
                const assets = values.assets as FleetAssetItem[];
                const selectedAssets = assets.map(x => x.id);
                const command: CreateExclusionCommand = {
                  staffMemberId: staffMemberId,
                  assetIds: selectedAssets,
                };
                return createExclusion(command);
              },
            }),
          },
        ],
      },
    ],
  };
}
